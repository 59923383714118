export const AUTOMATION_LIST_LOAD = 'AUTOMATION_LIST_LOAD'
export const AUTOMATION_LIST_LOAD_PENDING = 'AUTOMATION_LIST_LOAD_PENDING'
export const AUTOMATION_LIST_LOAD_FULFILLED = 'AUTOMATION_LIST_LOAD_FULFILLED'
export const AUTOMATION_LIST_LOAD_REJECTED = 'AUTOMATION_LIST_LOAD_REJECTED'

export const AUTOMATION_CREATE = 'AUTOMATION_CREATE'
export const AUTOMATION_CREATE_PENDING = 'AUTOMATION_CREATE_PENDING'
export const AUTOMATION_CREATE_FULFILLED = 'AUTOMATION_CREATE_FULFILLED'
export const AUTOMATION_CREATE_REJECTED = 'AUTOMATION_CREATE_REJECTED'

export const AUTOMATION_UPDATE = 'AUTOMATION_UPDATE'
export const AUTOMATION_UPDATE_PENDING = 'AUTOMATION_UPDATE_PENDING'
export const AUTOMATION_UPDATE_FULFILLED = 'AUTOMATION_UPDATE_FULFILLED'
export const AUTOMATION_UPDATE_REJECTED = 'AUTOMATION_UPDATE_REJECTED'

export const AUTOMATION_DELETE = 'AUTOMATION_DELETE'
export const AUTOMATION_DELETE_PENDING = 'AUTOMATION_DELETE_PENDING'
export const AUTOMATION_DELETE_FULFILLED = 'AUTOMATION_DELETE_FULFILLED'
export const AUTOMATION_DELETE_REJECTED = 'AUTOMATION_DELETE_REJECTED'

export const AUTOMATION_SHOW = 'AUTOMATION_SHOW'
export const AUTOMATION_SHOW_PENDING = 'AUTOMATION_SHOW_PENDING'
export const AUTOMATION_SHOW_FULFILLED = 'AUTOMATION_SHOW_FULFILLED'
export const AUTOMATION_SHOW_REJECTED = 'AUTOMATION_SHOW_REJECTED'

export const AUTOMATION_BULK_UPSERT = 'AUTOMATION_BULK_UPSERT'
export const AUTOMATION_BULK_UPSERT_PENDING = 'AUTOMATION_BULK_UPSERT_PENDING'
export const AUTOMATION_BULK_UPSERT_FULFILLED = 'AUTOMATION_BULK_UPSERT_FULFILLED'
export const AUTOMATION_BULK_UPSERT_REJECTED = 'AUTOMATION_BULK_UPSERT_REJECTED'

export const AUTOMATION_ACTIONS_VARIABLES_LOAD = 'AUTOMATION_ACTIONS_VARIABLES_LOAD'
export const AUTOMATION_ACTIONS_VARIABLES_LOAD_PENDING = 'AUTOMATION_ACTIONS_VARIABLES_LOAD_PENDING'
export const AUTOMATION_ACTIONS_VARIABLES_LOAD_FULFILLED =
  'AUTOMATION_ACTIONS_VARIABLES_LOAD_FULFILLED'
export const AUTOMATION_ACTIONS_VARIABLES_LOAD_REJECTED =
  'AUTOMATION_ACTIONS_VARIABLES_LOAD_REJECTED'

export const AUTOMATION_POST_ATTACHMENT = 'AUTOMATION_POST_ATTACHMENT'
export const AUTOMATION_POST_ATTACHMENT_PENDING = 'AUTOMATION_POST_ATTACHMENT_PENDING'
export const AUTOMATION_POST_ATTACHMENT_FULFILLED = 'AUTOMATION_POST_ATTACHMENT_FULFILLED'
export const AUTOMATION_POST_ATTACHMENT_REJECTED = 'AUTOMATION_POST_ATTACHMENT_REJECTED'
