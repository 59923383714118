import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import lodash from 'lodash'
import * as actions from './actions'

const category_vals = {
  Allowances: 1,
  'Absenteeism transactions': 2,
  Accruals: 2,
  'Loan Types': 3,
  'Other PayTypes': 4,
  'Other Deductions': 5,
}

const initialState = {
  fetching: false,
  loading: false,
  transaction_types: [],
  netsuit_accounts: [],
}

function reducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case actions.NETSUIT_ACCOUNTS_LOAD_PENDING:
    case actions.NETSUIT_ACCOUNT_TRANSACTION_SYNC_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.NETSUIT_ACCOUNT_BULK_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.NETSUIT_ACCOUNT_BULK_UPDATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.NETSUIT_ACCOUNT_BULK_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.NETSUIT_ACCOUNT_TRANSACTION_SYNC_FULFILLED: {
      let is_default_mapped = false
      const types = payload.data?.data?.gl_transaction_types || []
      let category = lodash.groupBy(types, 'gl_transaction_category') || {}

      Object.entries(category).forEach(([key, value]) => {
        const ordered = lodash.sortBy(value, (v) => v?.gl_transaction_name?.toLowerCase())
        category = { ...category, [key]: ordered }
      })

      const category_list = Object.keys(category)?.map((label) => ({
        label,
        value: category_vals[label],
      }))
      const transaction_types_cats = category_list.sort((a, b) => a.value - b.value)

      const counts = transaction_types_cats?.map((item) => {
        const list = category[item.label]?.filter(
          (it) => !it.external_accounting_infos?.internal_id && !it.map_to_earnings,
        )
        return { label: item.label, count: list.length }
      })

      if (!payload?.sent_data?.subsidiary_id && counts.every((e) => e.count === 0)) {
        is_default_mapped = true
      } else {
        is_default_mapped = state.is_all_default_mapped
      }

      return {
        ...state,
        fetching: false,
        transaction_types_cats,
        transaction_types: category,
        transaction_types_list: types,
        is_all_default_mapped: is_default_mapped,
        current_mapped_items: counts,
      }
    }
    case actions.NETSUIT_ACCOUNTS_LOAD_FULFILLED: {
      const netsuit_accounts = payload.data?.data || []
      return {
        ...state,
        netsuit_accounts,
        fetching: false,
      }
    }
    case actions.NETSUIT_SPECIFIC_ACCOUNT_LOAD_FULFILLED: {
      const netsuit_account_types = payload.data?.data?.gl_account_transaction_types || []
      return {
        ...state,
        netsuit_account_types,
        fetching: false,
      }
    }

    case actions.NETSUIT_ACCOUNTS_LOAD_REJECTED:
    case actions.NETSUIT_ACCOUNT_TRANSACTION_SYNC_REJECTED: {
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
