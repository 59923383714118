import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import { Typography, Button } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { CloseIcon } from 'components/global/icons'
import JisrLoader from 'components/global/JisrLoader'
import AlertIcon from './Alert.svg'

const AlertPopup = ({ onClose, handleClose, open, messages, children, fetching, testId }) => (
  <div className="alert-popup">
    <Dialog
      data-testid={testId}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {handleClose && (
        <IconButton
          data-testid={`${testId}-close-button`}
          className="alert-popup--cancel-btn"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon
            style={{
              width: 15,
              height: 15,
            }}
          />
        </IconButton>
      )}
      <DialogContent data-testid={`${testId}-content`}>
        <div className="alert-popup-content">
          {fetching && <JisrLoader absolute />}
          <div>
            <img
              alt="Jisr"
              src={AlertIcon}
            />
            {children || (
              <>
                <Typography
                  text={messages.headding}
                  testId={`${testId}-content-heading`}
                  variant="interface/strong/m"
                />
                <Typography
                  text={messages.description}
                  testId={`${testId}-content-description`}
                  variant="subtitle-2"
                />
                <Button
                  testId={`${testId}-content-button`}
                  onClick={onClose}
                  label={I18n.t('got_it')}
                  size="small"
                  style={{ margin: 'auto' }}
                />
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  </div>
)

AlertPopup.defaultProps = {
  testId: 'alert-popup',
}

AlertPopup.propTypes = {
  testId: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  fetching: PropTypes.bool,
  messages: PropTypes.shape(),
  handleClose: PropTypes.func,
  children: PropTypes.node,
}

export default AlertPopup
