import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function invitationsStatisticsLoad(): Action {
  const url = `/${API_VERSION_TWO}/overview/employee_invites/statistics`
  const method = 'GET'

  return {
    type: actions.INVITATIONS_STATISTICS_LOAD,
    payload: handleAPI(url, {}, method),
  }
}

export function employeeInvitationsLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/overview/employee_invites`
  const method = 'GET'

  return {
    type: actions.EMPLOYEE_INVITATIONS_LOAD,
    payload: handleAPI(url, params, method),
  }
}
export function employeeInvitationsReset(): Action {
  return {
    type: actions.EMPLOYEE_INVITATIONS_RESET,
  }
}
