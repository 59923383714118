import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Employee } from 'redux/authUser/types'
import * as actions from './actions'

type TInitState = {
  fetching: boolean
  employee: Partial<
    Employee & {
      document_number: string
      basic_salary: number
      housing_benefit: number
      commissions: number
      other_allownce: number
      is_muslim: boolean
      is_employee_details_submitted: boolean
      is_terminated_at_gosi: boolean
      is_employee_added_in_gosi: boolean
      gosi_employee_details?: {
        email: string
        street: string
        zip_code: string
        po_box: string
        city: string
        village_code: string
        education: string
        last_name: string
        telephone: string
        first_name: string
        commissions: number
        basic_salary: number
        contact_type: string
        country_code: string
        joining_date: string
        location_code: string
        mobile_number: string
        marital_status: string
        other_allownce: string
        specialization: string
        student_status: string
        building_number: string
        employment_type: string
        housing_benefit: number
        occupation_code: string
        prisoner_status: string
        contributor_type: string
        establishment_id: string
        additional_number: string
        insurance_coverage: string
        company_worker_number: number
        inland_abroad_indicator: string
        employee_document_number: string
        establishment_mol_branch: string
      }
    }
  >
}

const initialState: TInitState = {
  fetching: false,
  employee: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.GOSI_EMPLOYEE_DETAILS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GOSI_EMPLOYEE_DETAILS_LOAD_FULFILLED:
      return {
        ...state,
        employee: payload.data.data,
        fetching: false,
      }

    case actions.GOSI_EMPLOYEE_DETAILS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
