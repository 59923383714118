const en = {
  if_you_want_to_write_decimal_just_type:
    'If you want to write decimal numbers just type the number and then add the decimal point in beteen',

  _1st: '1st',
  _2nd: '2nd',
  _3rd: '3rd',
  _4th: '4th',

  _1st_half_round: '1st Half Review Round',
  _2nd_half_round: '2nd Half Review Round',

  _1st_quarter_round: '1st Quarter Review Round',
  _2nd_quarter_round: '2nd Quarter Review Round',
  _3rd_quarter_round: '3rd Quarter Review Round',
  _4th_quarter_round: '4th Quarter Review Round',

  _1st_half: '1st Half',
  _2nd_half: '2nd Half',

  _1st_quarter: '1st Quarter',
  _2nd_quarter: '2nd Quarter',
  _3rd_quarter: '3rd Quarter',
  _4th_quarter: '4th Quarter',

  _1st_half_full: 'First half',
  _2nd_half_full: 'Second half',

  _1st_quarter_full: 'First quarter',
  _2nd_quarter_full: 'Second quarter',
  _3rd_quarter_full: 'Third quarter',
  _4th_quarter_full: 'Fourth quarter',

  // A
  add_objective: 'Add Objective',
  add_remove_employees: 'Add remove employees',
  add_edit_competencies: 'Add edit competencies',
  add_range: 'Add Range',
  assign_new_objective: 'Assign New Objective',
  assign_objective: 'Assign Objective',
  assigned_objectives: 'Assigned objectives',
  adding_rating: 'Adding rating',
  all_competencies_set: 'All competencies set',
  and_add_a_weight_for_it: 'and add a weight for it.',
  a_competency_can_not_have_0_weight: 'A competency can’t have 0% weight.',
  an_objective_can_not_have_0_weight: 'An objective can’t have 0% weight.',
  are_you_sure_you_want_to_delete_the_following_objective_cycle:
    'Are you sure you want to delete the following objective cycle?',
  annual_objective_cycle_for_the_sales_team: '“Annual objective cycle for the sales team”',
  are_you_sure_you_want_to_close_the_following_objective_cycle:
    'Are you sure you want to close the following objective cycle?',
  annually: 'Annually',
  are_you_sure_you_want_to_delete_the_following_competency:
    'Are you sure you want to delete the following competency?',
  are_you_sure_you_want_to_delete_the_following_competency_set:
    'Are you sure you want to delete the following competency set?',
  are_you_sure_you_want_to_delete_the_following_review_cycle:
    'Are you sure you want to delete the following review cycle?',
  are_you_sure_you_want_to_delete_the_following_objective:
    'Are you sure you want to delete the following objective?',
  adjust_objective_weight: 'Adjust Objective Weight',
  add_employees: 'Add Employees',
  add_rating: 'Add Rating',
  are_you_sure_you_want_to_mark_the_following_cycle_completed:
    'Are you sure you want to mark the following cycle completed?',
  are_you_sure_you_want_to_mark_the_following_run_completed:
    'Are you sure you want to mark the following run completed?',
  are_you_sure_you_want_to_start_the_following_review_cycle:
    'Are you sure you want to start the following review cycle?',
  are_you_sure_you_want_to_leave_the_current_page_You_have_changes_that_wont_be_saved:
    "Are you sure you want to leave the current page? You have changes that won't be saved.",
  all_objectives_cycle: 'All Objectives Cycle',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete the following review cycle?',
  are_you_sure_you_want_to_close: 'Are you sure you want to close the following review cycle?',
  all_statuses: 'All Statuses',
  all_review_cycles: 'All review cycles',
  area_of_strength: 'Area of strength',
  area_of_development: 'Area of development',
  general_feedback: 'General feedback',
  assign_employees: 'Assign Employees',
  assign_employees_subtitle:
    'Who would you like to receive an evaluation? The reviewer will be automatically selected based on the organizational structure.',
  assign_review_cycle: 'Assign Review Cycle',
  after_the_probation_period: 'After the probation period',
  after_op: 'After',
  assign_a_line_manager:
    'Assign a line manager for the employee in order to review and evaluate the employee’s performance',
  accumulative: 'Accumulative',
  assigned_employees: 'Assigned employees',
  all_objectives_cycles: 'All objectives cycles',
  assign_line_manager: 'Assign Line Manager',
  assign_line_manager_drawer_message:
    'The selected line manager will be added to future evaluation forms by default and automatically reflected in the organizational chart.',
  add_here_any_area_of_strength_area_of_development_or_general_feedback:
    'Add here any area of strength, area of development, or general feedback',
  all_completed_reviews_are_shared: 'All completed reviews are shared',
  a_criteria_with_the_same_name_is_already_exist: 'A criteria with the same name is already exist',
  assign_group_to_employee: 'Assign a group to employee',
  assignee: 'Assignee',
  assigned_to_employees: 'Assigned to employees',
  assigned_to_others: 'Assigned to others',
  assigned_to_emp: 'Assigned to {{employee}} {{data}}',

  // B
  before_the_probation_period_ends: 'before the probation period ends',
  before_the_contract_ends: 'before the contract ends',
  before_deleting_this_objective:
    'Before deleting this objective, please allocate its {{weightage}}% weight to the remaining objectives to match 100% total weight.',
  before_end_of_probation: 'before end of probation',
  back_to_forms: 'Back to Forms',
  fixed_duration_description: 'Based on a custom timeframe to help with one-time evaluations',
  // C
  competencies: 'Competencies',
  create_new_objectives_cycle: 'Create new objectives cycle',
  creating_new_objective: 'Creating new objective',
  create_new_objective: 'Create New Objective',
  competencies_and_skills: 'Competencies & Skills',
  competencies_set_name: 'Competencies set name',
  competencies_set_name_ar: 'Competencies set name (Arabic)',
  competencies_and_interpersonal_skills: 'Competencies & Interpersonal Skills',
  competency_name: 'Competency name',
  clearly_define_criteria: 'Clearly defined criteria',
  closing: 'Closing',
  close_objective_cycle: 'Close',
  cycle_frequency: 'Cycle Frequency',
  choose_from: 'Choose from',
  closing_date: 'Closing date',
  competencies_weight: 'Competencies weight',
  complete_cycle: 'Complete Cycle',
  choose_a_run: 'Choose a Run',
  competencies_set_board: 'Competencies Set Board',
  create_new_review_cycle: 'Create New Review Cycle',
  current_review_cycle: 'Current Review Cycle',
  competencies_rating: 'Competencies Rating',
  bad: 'Bad',
  cycle_period: 'Cycle Period',
  complete_run: 'Complete {{runNumber}} Run',
  choose: 'Choose',
  confirm_weight_of_objectives: 'Confirm weight of objectives',
  can_not_edited_cycle_progress: 'Can not be edited while the review cycle is in progress',
  to_give_weight_to_the_newly_added_objective__description:
    'To give weight to the newly added objective adjust the weight of the previously added objectives manually',
  closed: 'Closed',
  competency_set: 'Competency set',
  created_on: 'Created on',
  create_evaluation_form: 'Create Evaluation Form',
  criteria_collaborator: 'Criteria Collaborator',
  criteria_collaborator_manager_message:
    'Allow line manager to collaborate on evaluation criteria setting',
  criteria_collaborators_subtitle:
    'The line manager will be able to define evaluation criteria for the employees you select in the next step. They can start adding criteria once you share the review with them.',
  change_competency: 'Change competency',
  change_competency_set: 'Change Competency Set',
  changing_the_competency_set_will_remove_all_competencies_that_are_rated:
    'Changing the competency set will remove all competencies that are rated',
  change: 'Change',
  competency: 'Competency',
  create_task: 'Create task',
  current_year: 'Current year',
  cancel_task: 'Cancel Task',
  continue_anyway: 'Continue anyway',
  cansle_task: 'Cancel {{task}} task?',
  completed_by_employee: 'Completed',
  last_active_contract_type: 'Contract type',

  // D
  distribute_weight_equally: 'Distribute weight equally',
  define_new_competency_set: 'Define New Competencies Set',
  define_a: 'Define a ',
  delete_competency: 'Delete competency',
  define_a_new_competency: 'Define a new competency',
  delete_objective: 'Delete Objective',
  do_you_want_to_include_competencies: 'Do you want to include competencies?',
  description_optional: 'Description (Optional)',
  description_ar: 'Description (Arabic)',
  does_not_seem_to_have_any_review_cycles: "doesn't seem to have any review cycles.",
  does_not_seem_to_have_any_objectives: 'doesn’t seem to have any objectives',
  does_not_seem_to_have_any_competencies_can_edit:
    'doesn’t seem to have any competencies.Go to competencies set page, and assign him one.',
  does_not_seem_to_have_any_competencies: 'doesn’t seem to have any competencies.',
  deleting_objective: 'Deleting objective',
  define_a_new_competency_and_add_a_weight_for_it:
    'Define a New competency and add a weight for it',
  deleting_a_form_permanently_deletes_the_data_of_all_associated_evaluations:
    'Deleting a form permanently deletes the data of all associated evaluations.',
  due_date: 'Due Date',

  // E
  edit_objectives_cycle: 'Edit Objectives Cycle',
  editting_objective: 'Edit objective',
  employee_added: 'Employee Added',
  employee_not_added: 'Employee Not Added',
  employee_search_placeholder: 'Search by employee name or ID',
  edit_details: 'Edit details',
  edit_review_cycle: 'Edit Review Cycle',
  edit_comment: 'Edit Comment',
  employee_comments: 'Employee comment',
  excellent: 'Excellent',
  end_label: 'Ends',
  ends_a_year_after_the_starting_date: 'Ends a year after the starting date',
  employees_assigned_to_this_cycle: 'Employees assigned to this cycle',
  employees_assigned: 'Employees assigned',
  employees_without_manager: '{{count}} employee has not been assigned a line manager.',
  employees_without_manager_message:
    'Make sure to assign all employees a line manager for maximum efficiency.',
  evaluation_process_is_completed_ask_your_line_manager_to_share_your_performance_evaluation_result:
    'Evaluation process is completed, ask your line manager to share your performance evaluation result',
  employee_is_offboarded_can_not_unmark_his_review:
    'Employee is offboarded, can not complete or update his review.',
  employee_rating: 'Employee Rating',
  employee_board: 'Employee Board',
  employee_evaluated: 'Employees to be evaluated',
  export_review_cycle_as_xls: 'Export review cycle as xls',
  evaluate_criteria: 'Evaluation Criteria',
  evaluate_criteria_subtitle:
    'Align employee evaluation criteria with organizational objectives directly through the evaluation for enhanced productivity and growth',
  evaluate_start_time: 'Evaluation Start Time',
  evaluate_submission_time: 'Evaluation Submission Time',
  evaluationـforms: 'Evaluation Forms',
  new_evaluation_form: 'New Evaluation Form',
  evaluation_groups_pending_submission: 'Evaluation groups pending submission',

  // F
  first_run_timeline: 'First run timeline',
  fourth_run_timeline: 'Fourth run timeline',
  finalization: 'Finalization',
  finalizing_date: 'Finalization date',
  first_run: 'First Run',
  form_type: 'Form Type',
  form_subtitle: 'A few details about the evaluation',
  form_details: 'Form Details',
  form_details_ar: 'Form Name (AR)',
  form_details_en: 'Form Name (EN)',
  fourth_run: 'Fourth Run',
  frequency: 'Frequency',
  form_name: 'Form Name',

  // G
  general_information: 'General Information',
  good: 'Good',
  groups: 'Groups',
  groups_message: 'Use groups for more structured goals, with a minimum of one goal for each group',
  group_new: 'New Group',
  group_clone: 'Clone Group',
  group_search_placeholder: 'Search by group name',
  group_create: 'Create New Group',
  group_name_en: 'Group Name (EN)',
  group_name_ar: 'Group Name (AR)',
  group_name_placeholder: 'e.g. Growth & development',
  group_rating: 'Rating Method',
  group_rating_placeholder: 'Select rating method',
  group_weight: 'Group Weight',
  groups_create: 'Create Group',
  group_mark_as_general: 'Mark as general group',
  group_mark_as_general_subtitle: 'The group will be added to the clone list',
  group_already_exist: 'A group with the same name is already exist',
  group_delete_message:
    'Are you sure you want to delete this group? Its goals will be lost if you proceed',
  group_delete_heading: 'Delete “{{group}}” group?',
  form_delete_heading: 'Delete “{{form}}” form?',
  group_weight_equal_message: 'The total weight of group criteria must equal {{weight}}%',
  group_weight_not_exceed_message:
    'The total weight of group criteria should be less than or equal to {{weight}}%',

  // H
  // I
  its_the_total_duration_of_the_review_cycle: 'It’s the total duration of the review cycle',
  incomplete: 'Incomplete',
  // Jpast_review_cycleanager comments',
  // J
  joining_dates: 'Joining Date',
  job_title_table: 'Job Title',
  // K
  kick_off: 'Kickoff',
  kick_off_date: 'Kick-off date',

  // L
  line_manager_comments: 'Line manager comments',
  line_manager_offboarded: 'The line manager is not part of this company anymore',
  label: 'Label',
  label_ar: 'Label (Arabic)',
  list_of_competencies: 'List of competencies',
  live: 'Live',

  // M
  measurement_criteria: 'Measurement criteria',
  mark_as_completed: 'Mark as completed',
  marked_as_completed: 'Marked as completed',
  manager_feedback: 'Manager feedback',
  monthly_op: 'Monthly',
  manage_all_your_evaluation_forms_in_one_place: 'Manage all your evaluation forms in one place',
  mark_as_completed_form: 'Mark “{{form}}” as completed?',

  // N
  name_en: 'Name',
  name_ar: 'Name (Arabic)',
  new_objectives_cycle: 'New Objectives Cycle',
  new_competencies_set: 'New competencies set',
  new_competency: 'New Competency',
  never_op: 'Never',
  no_not_yet: 'No. Not Yet',
  not_started: 'Not started',
  normal_period_review_cycle: 'Normal Period Review Cycle',
  normal_review_cycles: 'Normal Review Cycle',
  normal_review_overall_rating: 'Normal Review Overall Rating',
  no_reviewer_selected: 'No reviewer selected',
  no_result_found: 'No results found',
  no_result_found_message:
    'We couldn’t find any results with the selected filters. Try to select other filters',
  no_search_result_found: 'We couldn’t find {{value}}. Check the spelling or try another search.',
  no_assigned_reviewer: 'No assigned reviewer',
  no_available_data: 'No available data',
  no_employee_reviews_added_yet: 'No employee reviews added yet!',
  none_method: 'None',
  new: 'New',
  name_is_already_added_to_the_list: 'Name is already added to the list',
  no_reviewer_assigned_for_review_cycle_assign_line_manager:
    'No reviewer assigned for review cycle, Assign line manager',
  not_found: 'Not found',
  new_criteria: 'New Criteria',
  no_evaluation_forms_yet: 'No Evaluation forms yet',
  start_adding_evaluation:
    'Start adding evaluation forms to reflect on past work as well as your future goals',
  task_note: 'Note: {{task_note}}',

  // O
  objective: 'Objective',
  objectives: 'Objectives',
  objectives_cycle_period: 'Objectives cycle period',
  objectives_cycle_name: 'Objectives Cycle Name',
  objectives_cycle_name_ar: 'Objectives Cycle Name (Arabic)',
  objective_period: 'Objective period',
  objectives_cycle: 'Objectives cycle',
  objective_name: 'Objective name',
  objectives_weight: 'Objectives weight',
  objective_status: 'Objective status',
  objective_rating: 'Objective Rating',
  overall_rating: 'Overall rating',
  on_track: 'On Track',
  off_track: 'Off Track',
  on_track_objectives: 'On-track objectives',
  off_track_objectives: 'Off-track objectives',
  objective_cycles: 'Objective Cycles',
  objective_progress: 'Objective progress',
  objective_weight: 'Objective weight',
  only_numbers_accepted: 'Only numbers can be accepted',
  out_of: '(Out of {{of}})',
  objective_can_not_be_deleted_when_the_review_is_completed:
    "Objective can't be deleted when the review is completed",
  objective_progress_can_not_be_edited_when_the_review_is_completed:
    "Objective progress can't be editted when the review is completed",
  occurrences_regular: 'occurrences',
  one_time_evaluation: 'One-time evaluation',
  occurrence_message: 'The last occurrence will be conducted from {{startDate}} to {{endDate}}',
  occurrence_invalid_message: 'Evaluation duration cannot exceed its repetition frequency',

  // P
  performance: 'Performance',
  performance_cycles: 'Performance Cycles',
  prioritize_your_objectives_by_giving_them_weight:
    'Prioritize your objectives by giving them weight.',
  progress: 'Progress',
  previous_step: 'Previous step',
  print: 'Print',
  probation_period: 'Probation period',
  probation_period_review_cycle: 'Probation Period Review Cycle',
  probation_review_cycle: 'Probation Review Cycle',
  probation_start_date: 'Probation start date',
  probation_end_date: 'Probation end date',
  product_evaluation: 'Product team evaluation 2024',
  past_review_cycle: 'Past Review Cycle',
  probation_review_overall_rating: 'Probation Review Overall Rating',
  percentage_should_not_exceed_hundred: 'Percentage should not exceed one hundred!',
  progress_method: 'Progress method',
  performance_objectives: 'Performance-objectives',
  performance_evaluation: 'Performance evaluation',
  publish_share: 'Publish & Share',
  publish_Share: 'Publish & Share',
  publish_and_share: 'Publish and share “{{reviewName}}” form?',
  prodct_name_placeholder: 'evaluation {{placeholder}}',
  product_placeholder: 'e.g. Quality of work',
  performance_goal_name_placehoder: 'e.g. Work Performance',
  passed_submission_date: 'Passed Submission Date',
  please_submit_all_evaluation_groups_before_publishing:
    'Publishing before assignee submit pending groups automatically cancels tasks assigned to others.',

  // Q
  quarterly: 'Quarterly',

  // R
  rating: 'Rating',
  regular: 'Fixed Duration',
  regular_submission_date: 'Submission Date',
  regular_select_date: 'Select date',
  regular_recurrence: 'Recurrence',
  rating_method: 'Rating Method',
  review_cycles_results: 'Review cycles results',
  review_cycle_name: 'Review cycle name',
  review_cycle_name_ar: 'Review cycle name (Arabic)',
  review_period: 'Review period',
  review_status: 'Review status',
  reviewer: 'Reviewer',
  reviewer_ids: 'Reviewer',
  running: 'Running',
  review_cycles: 'Review Cycles',
  review_name: 'Review name',
  review_starting_date: 'Review starting date',
  review_starting_time: 'Review starting time',
  review_sharing_time: 'Review sharing time',
  reviewing_time: 'Reviewing time',
  review_form: 'Review Form',
  review_progress: 'Review Progress',
  results_shared_with_employees: 'Results shared with employees',
  rating_out_of_5: 'Rating (Out of 5)',
  review_start: 'Review start',
  reviewer_feedback: 'Reviewer feedback',
  reviewee: 'Reviewee',
  review_status_is_completed_if_you_want_to_change_competency_set_click_update_review_first:
    'Review status is completed, if you want to change competency set click “Update review” first',
  review_run_status_is_completed: 'Review run status is completed',
  review_status_is_completed_if_you_want_to_assign_objective_click_update_review_first:
    'Review status is completed, if you want to assign new objective click “Update review” first',
  review_status_is_incompleted_if_you_want_to_print_save_review_first:
    'Review status is incompleted if you want to print, save review first',
  running_review_cycles: 'Running Review Cycles',
  removing_employees_from_a_running_review_cycle:
    'Removing employees from a running review cycle will result in the removal of all evaluations associated with those employees. This action cannot be undone.',
  Recurrence: 'recurrence',
  are_you_sure_you_want_to_proceed: 'Are you sure you want to proceed?',
  return_for_correction: 'Return {{group_name}} for correction',
  returned_for_correction: 'Returned for correction',

  // S
  set_objective_weight: 'Set objective weight',
  search_objective: 'Search objective',
  search_employees_by_name_or_id: 'search employees by name or id.',
  search_employee_or_reviewer: 'Search employee or reviewer',
  select_form_type: 'Select form type',
  semi_annually: 'Semi annually',
  second_run_timeline: 'Second run timeline',
  save_and_finish: 'Save & Finish',
  save_changes: 'Save Changes',
  set_weight: 'Set weight',
  second_run: 'Second Run',
  start_cycle: 'Start Cycle',
  shared_result: 'Shared results',
  send_line_manager_a_reminder: 'Send Line Manager a Reminder',
  send_reminder: 'Send Reminder',
  select_line_manager: 'Select line manager',
  share_with_employee: 'Share with employee',
  share_results_with_employee: 'Share results with employee',
  share_review: 'Share review',
  regular_start_date: 'Start Date',
  scale: 'Scale',
  starting_time: 'Starting time',
  sharing_time: 'Sharing time',
  starting_from: 'Starting from',
  share_remaining: 'Share Remaining',
  select_competency_set: 'Select competency set',
  semi_annual_op: 'Semi-annual',
  share_1st_quarter_review: 'Share 1st quarter review',
  share_2nd_quarter_review: 'Share 2nd quarter review',
  share_3rd_quarter_review: 'Share 3rd quarter review',
  share_4th_quarter_review: 'Share 4th quarter review',
  share_1st_half_review: 'Share 1st half review',
  share_2nd_half_review: 'Share 2nd half review',
  share_with_manager: 'Share with Manager',

  save_rating: 'Save Rating',
  sharing_results_with_employee: 'Sharing results with employee',
  show_all_goals: 'Show all goals',
  search_by_employee_id_name_competencies_set_name:
    'Search by Employee ID, Name, competencies set name',
  search_by_employee_id_name_objective_name_pm: 'Search by Employee ID, Name, Objective name',
  save_review: 'Save review',
  save_continue: 'Save and Continue',
  search_by_form_name: 'Search by form name',
  send_task_to_manager:
    'Send a task to an employee to fill a specific group with the required skills and weights',
  submission_task_date: 'Submission date: {{date}}',
  range_title: 'start and submission dates',

  // T
  target: 'Target',
  total_weight: 'Total weight',
  the_total_weight_is_fine_you_are_good_to_go: 'The total weight is fine. You’re good to go!',
  the_total_weight_should_be_100: 'The total weight should be 100%.',
  the_total_weight_is_100: 'Perfect, the total weight is equall 100%.',
  this_employee_doesnt_seem_to_have_any_competencies:
    'This employee doesn’t seem to have any competencies.',
  this_employee_doesnt_seem_to_have_any_objectives:
    'This employee doesn’t seem to have any objectives.',
  this_employee_doesnt_seem_to_have_any_review_cycles:
    'This employee doesnt seem to have any review cycles',
  third_run_timeline: 'Third run timeline',
  third_run: 'Third Run',
  the_rating_for_some_employees_either_have_not_started_or_in_progress:
    'The rating for some employees either have not started or in progress.',
  to_be_given: 'To be given',
  these_changes_will_be_reflected:
    'These changes will be reflected on the newly created and not started yet review cycles only',
  this_review_cycle_is_to_measure_the_performance_of_engineers:
    'This review cycle is to measure the performance of Engineers & Technical Experts. This will help right people get the bonus.',
  the_overall_weight_is_exceeding_100: 'The overall weight is more than 100%.',
  the_overall_weight_is_less_than_100: 'The overall weight is less than 100%.',
  this_step_cant_be_reversed: "This step can't be reversed",
  the_scale: 'Scale',
  the_objective_will_be_deleted_from_employee_profile_and_objective_page:
    'The objective "{{name}}" will be deleted from employee profile and objective page',
  the_review_is_already_shared: 'The review is already shared',
  there_is_no_objective_cycle_within_this_review_run_date:
    'There is no objective cycle within this review run date',
  the_total_weight_of_group_goals_must_equal:
    'The total weight of group goals must equal {{weigh}} %',
  the_form_has:
    'The form has {{evaluationsnumber}} incomplete evaluations. Are you sure you want to mark it as completed without completing these evaluations?',

  this_means_that_this_form_will_be_live:
    'This means that this form will be live. The selected employees and their managers will be able to view it',
  criterion_with_the_same_name_already_exists: 'A criterion with the same name already exists',
  criteria_name_ar: 'Criteria Name (AR)',
  criteria_name_en: 'Criteria Name (EN)',
  blank_groups: 'Groups cannot be blank',

  total_weight_should_be: 'Total weight should be 100%',
  the_group_weight_can: "The group weight can't go over {{weigh}} %",
  template_name_ar_placeholder: 'أضف اسم القالب',
  adjust_group_weights_to_meet_the_required_total_weight:
    'Adjust group weights to meet the required total weight',
  // U
  updating_objective_progress: 'Updating objective progress',
  update_progress_method: 'Update progress method',
  update_progress: 'Update Progress',
  update_objectives_cycle: 'Edit Objectives Cycle',
  update_review: 'Update review',
  upcoming: 'Upcoming',
  // V
  very_bad: 'Very Bad',
  very_good: 'Very Good',

  // W
  weight: 'Weight',
  search_not_found: 'We couldn’t find “{{search}}” Check the spelling or try another search',

  // X
  // Y
  you_have_not_added_any_employees_yet: "You haven't added any employees yet.",
  yes_delete_objective_cycle: 'Yes. Delete Objective Cycle',
  you_do_not_seem_to_have_any_objective_cycles_defined_yet:
    'You don’t seem to have any objective cycles defined yet.',
  you_do_not_seem_to_have_any_competencies_set_defined_yet:
    'You don’t seem to have any competencies set defined yet.',
  yes_close_objective_cycle: 'Yes. Close Objective Cycle',
  yes_delete_it: 'Yes. Delete It',
  yes_mark_it_completed: 'yes. Mark it completed',
  yes_let_us_start_it: 'Yes. Let’s start it.',
  yes_delete_review_cycle: 'Yes. Delete Review Cycle',
  you_do_not_seem_to_have_any_review_cycles_defined_yet:
    'You do not seem to have any review cycles defined yet.',
  you_do_not_have_permission_to_view_the_performance_description:
    'You do not have permission to view the performance . To evaluate employee performance, ask for permission from the Admin',
  you_dont_have_permission_to_view_employee_rating:
    "You don't have permission to view {{name}}  Rating",
  you_do_not_seem_to_have_any_objectives_defined_yet:
    'You do not seem to have any objectives defined yet',
  you_can_share_the_review_with_employee_after_saving_review:
    'You can share the review with employee after saving review',
  you_havent_added_any_groups_to_the_clone_list_yet:
    "You haven't added any groups to the clone list yet",
  you_can_no_longer_edit_the_form_once_its_been_marked_as_completed:
    "You can no longer edit the form once it's been marked as completed.",

  // Z
}

export default en
