import { useEffect, useState } from 'react'
import { useDispatch } from 'utils/hooks'
import { Spacer, PageHeader } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { useGetOnboardingOverview } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview'
import { OnboardingOverviewContractRes } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview.type'
import { useSelector } from 'react-redux'
import { approvalsLoad } from 'redux/requests/newRequestsApproval/actionCreators'
import { Loader } from 'components/global/JisrLoader/JisrLoader'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { EmpInfoTemplate } from 'components/authorised/employee/EmpInfoTemplate'
import { OnboardingForm } from './components/forms/OnboardingForm'
import { ActionRequiredAccordion } from './components/ActionRequiredAccordion'
import { OnboardingFormSuccess } from './components/OnboardingFormSuccess'

const OnboardingEmployee = (): JSX.Element => {
  const dispatch = useDispatch()

  const { data, isLoading } = useGetOnboardingOverview()
  const [state, setState] = useState<'overview' | 'form' | 're-edit'>('overview')
  const { employee: currentUser } = useSelector(({ auth }) => auth)
  const overviewDetails: OnboardingOverviewContractRes | undefined = data?.data?.data
  const onboardingEmpStatus = currentUser?.onboarding_employee_status

  useEffect(() => {
    mixPanelAnalytics({
      event_name: 'Employee Onboarding Form Viewed',
    })
  }, [])

  const handleGetStarted = () => {
    setState('form')
  }

  useEffect(() => {
    if (onboardingEmpStatus === 'returned_for_correction') {
      dispatch(
        approvalsLoad({
          type: 'employee_requests',
          filter: { status: ['Pending for Correction'] },
        }),
      )
    }
  }, [onboardingEmpStatus])

  if (state === 'form' || state === 're-edit') {
    return <OnboardingForm clarification={state === 're-edit'} />
  }

  return (
    <div>
      <Spacer height={12} />
      {onboardingEmpStatus !== 'returned_for_correction' && (
        <PageHeader
          title={i18n.t('label.hi_yasmin', { employee_name: currentUser?.first_name })}
          {...((onboardingEmpStatus === 'pending' || !onboardingEmpStatus) && {
            subTitleList: [
              {
                text: i18n.t('description.content_overview'),
              },
            ],
          })}
          {...((onboardingEmpStatus === 'pending' || !onboardingEmpStatus) && {
            actionPrimaryButton: {
              label: i18n.t('get_started'),
              onClick: handleGetStarted,
            },
          })}
        />
      )}

      <Spacer height={32} />
      {isLoading && <Loader fixed />}
      {(onboardingEmpStatus === 'pending' || !onboardingEmpStatus) && (
        <EmpInfoTemplate overviewDetails={overviewDetails} />
      )}
      {onboardingEmpStatus === 'returned_for_correction' && (
        <ActionRequiredAccordion overviewDetails={overviewDetails} />
      )}
      {(onboardingEmpStatus === 'approved' || onboardingEmpStatus === 'ready_for_review') && (
        <OnboardingFormSuccess />
      )}
    </div>
  )
}

export default OnboardingEmployee
