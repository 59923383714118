import { CSSProperties } from 'react'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import { ReactComponent as InfoIcon } from '@jisr-hr/design-system/dist/assets/icons/info-blue.svg'
import styles from './styles.module.css'

type TTipComponents = {
  tip: string
  style?: CSSProperties
}

const TipComponents = ({ tip, style }: TTipComponents): JSX.Element => (
  <Flex
    className={styles.content}
    style={style}
  >
    <InfoIcon />
    <Spacer width="10px" />
    <Typography
      variant="subtitle-2"
      style={{ color: 'var(--color-base-colors-black-600)' }}
      text={tip}
    />
  </Flex>
)

export default TipComponents
