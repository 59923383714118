import { Lens } from '@dhmk/zustand-lens'
import { EmployeeSalaryFinancial } from 'redux/employees/employeeSalaryFinancial/types'
import { TerminateEmployeeVals } from 'redux/gosi/types'
import { Employee } from 'redux/authUser/types'

type OpenGosiModal = 'terminate' | 'add_saudi' | 'add_non_saudi' | null

export interface GosiType {
  employee?: Employee | EmployeeSalaryFinancial
  open: boolean
  openGosiModal: OpenGosiModal
  serviceType: string
  successContent: TerminateEmployeeVals | Record<string, unknown>
  setEmployee: (employee: Employee | EmployeeSalaryFinancial) => void
  setOpen: (open: boolean) => void
  setOpenGosiModal: (open: OpenGosiModal) => void
  setServiceType: (type: string) => void
  setSuccessContent: (vals: TerminateEmployeeVals | Record<string, unknown>) => void
}

const GosiInitialValues: GosiType = {
  open: false,
  openGosiModal: null,
  serviceType: '',
  employee: undefined,
  successContent: {},
  setOpen: () => null,
  setOpenGosiModal: () => null,
  setServiceType: () => null,
  setEmployee: () => null,
  setSuccessContent: () => null,
}

export const createGosiSlice: Lens<GosiType> = (set) => ({
  ...GosiInitialValues,
  setOpen: (open): void => set(() => ({ open })),
  setOpenGosiModal: (openGosiModal): void => set(() => ({ openGosiModal })),
  setServiceType: (serviceType: string): void => set(() => ({ serviceType })),
  setEmployee: (employee): void => set(() => ({ employee })),
  setSuccessContent: (successContent): void => set(() => ({ successContent })),
})
