import { isEmpty, keys, omitBy } from 'lodash'
import i18n from 'i18next'

export const hasPendingChanges = (key, obj) => {
  if (!obj) return ''

  const listKeys = keys(obj)

  return listKeys.includes(key)
}

export const returnCleanValues = (values) => {
  if (values.telephone) {
    values.country_phone_code = values?.country_phone_code ?? '+966'
  }
  return omitBy(
    values,
    (value) =>
      // Keep non-empty values and all numbers (including 0)
      value === null || value === undefined || (typeof value !== 'number' && isEmpty(value)),
  )
}

export const translationKeysMapping = {
  personal_info: i18n.t('label.personal_info'),
  passport: i18n.t('label.passport_info'),
  id_iqama: i18n.t('label.id_iqama'),
  address: i18n.t('label.address_in_saudi'),
  address_in_home: i18n.t('label.address_in_home_country'),
  emergency_contact: i18n.t('label.emergency_contact'),
}

export const contentTranslationKeysMapping = {
  photo_attachment: i18n.t('label.personal_photo'),
  full_name_en: i18n.t('label.full_name_english'),
  full_name_ar: i18n.t('label.full_name_arabic'),
  nationality_id: i18n.t('label.nationality'),
  personal_email: i18n.t('label.personal_email'),
  telephone: i18n.t('label.phone_number'),
  gender: i18n.t('label.gender'),
  marital_status: i18n.t('label.marital_status'),
  religion: i18n.t('label.religion'),
  passport_number: i18n.t('label.passport_number'),
  passport_issue_place: i18n.t('label.passport_issue_place'),
  passport_issue_date: i18n.t('label.passport_issue_date_gregorian'),
  passport_expiry_date: i18n.t('label.passport_expiry_date_gregorian'),
  passport_attachments: i18n.t('label.passport_attachment'),
  document_number: i18n.t('label.idiqama_number'),
  iqama_issue_place: i18n.t('label.idiqama_issue_place'),
  iqama_issue_date: i18n.t('label.id_or_iqama_issue_date_gregorian'),
  iqama_expiry_date: i18n.t('label.id_or_iqama_expiry_gregorian'),
  id_iqama_attachments: i18n.t('label.id.iqama_attachment'),
  name: i18n.t('label.name'),
  relationship: i18n.t('label.relationship'),
  address: i18n.t('label.address'),
  home_address1: i18n.t('label.address'),
  home_city: i18n.t('label.city'),
  home_country: i18n.t('label.country'),
  building_number: i18n.t('label.building_number'),
  street_name: i18n.t('label.street_name'),
  district: i18n.t('label.district'),
  saudi_city: i18n.t('label.city'),
  saudi_postal_code: i18n.t('label.postal_code'),
  national_short_address: i18n.t('label.short_address'),
  proof_attachment: i18n.t('label.proof_of_address'),
}
