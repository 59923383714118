export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD = 'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_PENDING =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_PENDING'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_FULFILLED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_FULFILLED'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_REJECTED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_REJECTED'

export const EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD = 'EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_PENDING =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_PENDING'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_FULFILLED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_FULFILLED'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_REJECTED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_REJECTED'

export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_PENDING =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_PENDING'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_FULFILLED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_FULFILLED'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_REJECTED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_REJECTED'

export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_PENDING =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_PENDING'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_FULFILLED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_FULFILLED'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_REJECTED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_REJECTED'

export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_PENDING =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_PENDING'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_FULFILLED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_FULFILLED'
export const EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_REJECTED =
  'EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_REJECTED'
