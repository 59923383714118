import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import classNames from 'classnames'
import { statusBadge } from 'containers/authorised/requests/helper'
import ProfileAndTime from '../../../ds/ProfileAndTime'
import Styles from '../Workflow.module.css'

const ApprovalState = ({
  approvalId,
  employeeName,
  jobTitle,
  code,
  time,
  comment,
  date,
  avatar,
  status,
  status_i18n,
  isDeleted,
  isVisible,
  hasCancellationRequestInfo,
  is_action_by_automation,
  canBypass,
  canNotify,
}) => (
  <>
    <ProfileAndTime
      name={employeeName}
      code={code}
      jobTitle={jobTitle}
      time={time}
      date={date}
      avatar={avatar}
      isDeleted={isDeleted}
      approvalId={approvalId}
      canBypass={canBypass}
      canNotify={canNotify}
    />
    <Flex
      flexCol
      className={classNames(
        Styles.detailContainer,
        hasCancellationRequestInfo && Styles.cancellationAskedBy,
      )}
    >
      <Flex
        itemsCenter
        style={{ gap: 4 }}
      >
        {isVisible &&
          statusBadge({
            key: status?.toLowerCase(),
            label: status_i18n,
            isAutomated: is_action_by_automation,
          })}
      </Flex>
      {comment && (
        <Typography
          text={comment}
          textColor="--color-base-colors-grey-600"
          variant="subheading"
        />
      )}
    </Flex>
  </>
)

ApprovalState.propTypes = {
  approvalId: PropTypes.number,
  code: PropTypes.string,
  employeeName: PropTypes.string,
  time: PropTypes.string,
  avatar: PropTypes.string,
  comment: PropTypes.string,
  jobTitle: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  status: PropTypes.string,
  status_i18n: PropTypes.string,
  isDeleted: PropTypes.bool,
  isVisible: PropTypes.bool,
  is_action_by_automation: PropTypes.bool,
  hasCancellationRequestInfo: PropTypes.bool,
  canBypass: PropTypes.bool,
  canNotify: PropTypes.bool,
}

export default ApprovalState
