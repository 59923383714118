import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  leave_summary: {
    annual_leave: {},
    upcomming_leaves: [],
    running_leaves: [],
  },
  leave_history: [],
  leave_types: [],
  employee_years: [],
  applied_days_total: null,
  balance_deducted_total: null,
  errMsg: null,
  succMsg: null,
  fetching: false,
  leave_history_fetching: false,
  current_record_id: null,
}

let changes = null
export default function employeeLeavesReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'EMPLOYEE_LEAVE_DELETE_PENDING':
      return {
        ...state,
        current_record_id: action.payload.leave_id,
        fetching: true,
      }

    case 'EMPLOYEE_LEAVES_LOAD_PENDING':
    case 'EMPLOYEE_LEAVES_HISTORY_TYPES_LOAD_PENDING':
    case 'EMPLOYEE_YEARS_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'EMPLOYEE_LEAVES_HISTORY_LOAD_PENDING':
      return {
        ...state,
        leave_history_fetching: true,
      }

    case 'EMPLOYEE_LEAVES_LOAD_FULFILLED':
      changes = {
        leave_summary: action.payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVES_HISTORY_LOAD_FULFILLED':
      changes = {
        leave_history: action.payload.data.data.leave_history,
        applied_days_total:
          action.payload.data.data.applied_days_total &&
          action.payload.data.data.applied_days_total,
        balance_deducted_total:
          action.payload.data.data.balance_deducted_total &&
          action.payload.data.data.balance_deducted_total,
        // fetching: false,
        leave_history_fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVES_HISTORY_TYPES_LOAD_FULFILLED':
      changes = {
        leave_types: action.payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_YEARS_LOAD_FULFILLED':
      changes = {
        employee_years: action.payload.data.data.employee_years,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        leave_history: removeOneListOfRecords(state.leave_history, state.current_record_id),
      }

    default:
      return state
  }
}
