import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useSelector } from 'utils/hooks'
import {
  required,
  composeValidators,
  number,
  positive,
} from 'components/global/form/FormValidations'
import I18n from 'translations/i18n'
import { Button, Flex, Spacer } from '@jisr-hr/ds'
import { DatePickerDSField, TextInputField, FileUploadField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import Styles from './style.module.css'
import { getSizeFromKB } from '../../../helper'

const ExpenseClaimItem = ({ onCancelClick, onSaveClick, formPrefix, disableSave, item }) => {
  const { change } = useForm()
  const { pristine, values } = useFormState()
  const [initFile, setInitFile] = React.useState()
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency?.currency_type_i18n,
  )
  const file = {
    file:
      item?.ecr_attachments_attributes?.attachment ?? item?.ecr_attachments?.[0]?.attachment_url,
    name:
      item?.ecr_attachments_attributes?.attachment_file_name ??
      item?.ecr_attachments?.[0]?.attachment_file_name,
    size: `${(
      item?.ecr_attachments_attributes?.attachment_file_size ??
      item?.ecr_attachments?.[0]?.attachment_file_size / 1024
    ).toFixed()} KB`,
    id: item?.ecr_attachments_attributes?.id ?? item?.ecr_attachments?.[0]?.id,
  }

  React.useEffect(() => {
    if (file.name) {
      setInitFile([file])
    }
  }, [file.name])

  return (
    <div className={Styles.itemContainer}>
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={4}
          >
            <TextInputField
              name={`${formPrefix}.name`}
              label={I18n.t('item_name')}
              validate={required}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <DatePickerDSField
              name={`${formPrefix}.date`}
              validate={required}
              label={I18n.t('date')}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <TextInputField
              data-private
              name={`${formPrefix}.amount`}
              suffix={currency}
              label={I18n.t('amount')}
              validate={composeValidators(required, number, positive)}
            />
          </Grid>
        </Grid>
        <TextInputField
          label={I18n.t('description')}
          placeholder={I18n.t('enter_your_description')}
          name={`${formPrefix}.description`}
          validate={required}
        />
        <Flex
          flexCol
          style={{ gap: '16px' }}
        >
          <FileUploadField
            initFiles={initFile}
            variant="version2"
            name="attachment_file_name"
            onLoad={(val) => {
              if (typeof val?.id === 'string') {
                const deletedAttachments = item?.ecr_attachments?.map((att) => ({
                  attachment_file_name: att?.attachment_file_name,
                  attachment_file_size: att?.attachment_file_size,
                  attachment: att?.attachment_url,
                  _destroy: true,
                  id: att?.id,
                }))
                change(`${formPrefix}.ecr_attachments_attributes`, [
                  ...(deletedAttachments ?? []),
                  {
                    attachment: val?.file,
                    attachment_file_name: val?.name,
                    attachment_file_size: val?.origin_file?.size,
                  },
                ])
                change(`${formPrefix}.ecr_attachments`, [
                  ...(deletedAttachments ?? []),
                  {
                    attachment: val?.file,
                    attachment_file_name: val?.name,
                    attachment_file_size: val?.origin_file?.size,
                  },
                ])
              } else {
                change(`${formPrefix}.ecr_attachments`, item?.ecr_attachments)
              }
            }}
            onRemove={(val) => {
              change(`${formPrefix}.ecr_attachments_attributes`, null)
              change(`${formPrefix}.ecr_attachments`, null)
              change('ecr_items[0].ecr_attachments', null)
              change(
                'deletedAttachments',
                [
                  ...values?.deletedAttachments,
                  typeof val?.id === 'number' && {
                    attachment: val?.file,
                    attachment_file_name: val?.name,
                    attachment_file_size: getSizeFromKB(val?.size),
                    id: val?.id,
                    _destroy: true,
                    itemId: item?.id,
                  },
                ].filter(Boolean),
              )
            }}
            errorMessage={I18n.t('unable_to_upload_file')}
            loadingMessage={I18n.t('uploading_file')}
            message={I18n.t('add_attatchment')}
            successMessage={I18n.t('file_selected')}
            uploadingMessage={I18n.t('uploading_in_progress')}
            className={Styles.fileUpload}
            hasDeleteButton
          />
        </Flex>
        <Spacer height={16} />
        <Flex
          justifyItemsEnd
          contentEnd
          itemsEnd
          justifyEnd
        >
          <Button
            size="small"
            label={I18n.t('cancel')}
            variant="outlined"
            color="neutral"
            onClick={onCancelClick}
          />
          <Spacer width={8} />
          <Button
            label={I18n.t('save')}
            disabled={disableSave || pristine}
            size="small"
            onClick={onSaveClick}
          />
        </Flex>
      </Flex>
    </div>
  )
}

ExpenseClaimItem.defaultProps = {
  disableSave: false,
}

ExpenseClaimItem.propTypes = {
  item: PropTypes.shape(),
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  formPrefix: PropTypes.string,
  disableSave: PropTypes.bool,
}

export default ExpenseClaimItem
