import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { ObjectivesCycleProps } from 'types/talentRetention/objectiveCycles'
import { PaginationTypes } from 'types/sharedResponse'
import { Action } from 'types/redux'
import * as actions from './actions'

type TInitState = {
  fetching: boolean
  deleting: boolean
  submitting: boolean
  fetching_share: boolean
  objective_sets: ObjectivesCycleProps[]
  objective_sets_brief: ObjectivesCycleProps[]
  objective_set: Partial<ObjectivesCycleProps>
  pagination: Partial<PaginationTypes>
}

export const initialState: TInitState = {
  fetching: false,
  deleting: false,
  submitting: false,
  fetching_share: false,
  objective_sets: [],
  objective_sets_brief: [],
  objective_set: {},
  pagination: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  switch (action.type) {
    case actions.OBJECTIVE_SETS_LOAD_PENDING:
    case actions.OBJECTIVE_SETS_BRIEF_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.OBJECTIVE_SETS_DELETE_PENDING:
      return {
        ...state,
        deleting: true,
      }

    case actions.OBJECTIVE_SETS_SHOW_PENDING:
    case actions.OBJECTIVE_SETS_UPDATE_PENDING:
    case actions.OBJECTIVE_SETS_CREATE_PENDING:
    case actions.OBJECTIVE_SETS_WEIGHTAGE_UPDATE_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.OBJECTIVE_SETS_LOAD_FULFILLED: {
      const { previous_page } = action.payload.data.pagination
      return {
        ...state,
        objective_sets: [
          ...(previous_page ? state.objective_sets : []),
          ...action.payload.data.data,
        ],
        pagination: action.payload.data.pagination,
        fetching: false,
      }
    }

    case actions.OBJECTIVE_SETS_BRIEF_LOAD_FULFILLED:
      return {
        ...state,
        objective_sets_brief: action.payload.data.data,
        fetching: false,
      }

    case actions.OBJECTIVE_SETS_SHOW_FULFILLED:
      return {
        ...state,
        objective_set: action.payload.data.data,
        submitting: false,
      }

    case actions.OBJECTIVE_SETS_UPDATE_FULFILLED:
    case actions.OBJECTIVE_SETS_CREATE_FULFILLED:
    case actions.OBJECTIVE_SETS_WEIGHTAGE_UPDATE_FULFILLED:
    case actions.OBJECTIVE_SETS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        objective_sets: [],
        pagination: { page: 1 },
        submitting: false,
        deleting: false,
      }

    case actions.OBJECTIVE_SETS_LOAD_REJECTED:
    case actions.OBJECTIVE_SETS_BRIEF_LOAD_REJECTED:
    case actions.OBJECTIVE_SETS_DELETE_REJECTED:
    case actions.OBJECTIVE_SETS_WEIGHTAGE_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }

    case actions.OBJECTIVE_SETS_SHOW_REJECTED:
    case actions.OBJECTIVE_SETS_UPDATE_REJECTED:
    case actions.OBJECTIVE_SETS_CREATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
        fetching_share: false,
      }

    default:
      return state
  }
}
