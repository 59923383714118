import { RootState } from 'types/redux'
import { AuthState } from './types'

type AuthSelector = Pick<AuthState, 'last_payrun' | 'employeeDate' | 'employee' | 'company'>

export const authSelector = (state: RootState): AuthSelector => ({
  last_payrun: state.auth.last_payrun,
  employeeDate: state.auth.employeeDate,
  employee: state.auth.employee,
  company: state.auth.company,
})

export const fetchingSelector = (state: RootState): boolean => state.auth.fetching
export const fetchingChangeLanguageSelector = (state: RootState): boolean =>
  state.auth.fetching_change_language

export const redirectAfterLoginSelector = (state: RootState): string | null =>
  state.auth.redirectAfterLogin
