import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { TInitStateAction } from './employees.type'

export const initialState: TInitStateAction = {
  loading: false,
  fetching: false,
  employees: [],
  adminEmployees: [],
  all_employees: [],
  total_employees: 0,
  grades: [],
  errorMessage: null,
  pagination: {
    current_page: 0,
    next_page: 0,
    per_page: 0,
    previous_page: 0,
    total_entries: 0,
    total_pages: 0,
  },
}

export default function reducer(state = initialState, action: Action): TInitStateAction {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_EMPLOYEES_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.ONBOARDING_ALL_EMPLOYEES_LOAD_PENDING:
    case actions.ONBOARDING_EMPLOYEES_BULK_DELETE_PENDING:
    case actions.ONBOARDING_GRADES_GET_ALL_LOAD_PENDING:
    case actions.ONBOARDING_ADMEN_EMPLOYEES_LOAD_PENDING:
    case actions.ONBOARDING_EMPLOYEES_POST_PENDING:
    case actions.ONBOARDING_EMPLOYEES_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_EMPLOYEES_PUT_FULFILLED:
    case actions.ONBOARDING_EMPLOYEES_POST_FULFILLED:
      return {
        ...state,
        errorMessage: null,
        fetching: false,
      }
    case actions.ONBOARDING_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        employees: payload.data.data.employees,
        total_employees: payload.data.data.total_employees,
        pagination: payload.data.data.pagination,
        loading: false,
      }

    case actions.ONBOARDING_ADMEN_EMPLOYEES_LOAD_FULFILLED:
      const adminEmployees = payload.data.data.employees
      delete payload.data.data?.employees
      return {
        ...state,
        adminEmployees,
        total_employees: payload.data.data.total_employees,
        pagination: payload.data.data.pagination,
        fetching: false,
      }
    case actions.ONBOARDING_GRADES_GET_ALL_LOAD_FULFILLED:
      return {
        ...state,
        grades: payload.data.data.grades,
        fetching: false,
      }
    case actions.ONBOARDING_EMPLOYEES_BULK_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    case actions.ONBOARDING_ALL_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        all_employees: payload.data.data.employees,
        fetching: false,
      }
    case actions.ONBOARDING_EMPLOYEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }

    case actions.ONBOARDING_ALL_EMPLOYEES_LOAD_REJECTED:
    case actions.ONBOARDING_EMPLOYEES_BULK_DELETE_REJECTED:
    case actions.ONBOARDING_GRADES_GET_ALL_LOAD_REJECTED:
    case actions.ONBOARDING_ADMEN_EMPLOYEES_LOAD_REJECTED:
    case actions.ONBOARDING_EMPLOYEES_POST_REJECTED:
    case actions.ONBOARDING_EMPLOYEES_PUT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
