import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  loan_requests: [],
  loan_request: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function loanRequestReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'LOAN_REQUEST_CREATE_PENDING':
    case 'LOAN_REQUEST_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'LOAN_REQUEST_CREATE_REJECTED':
    case 'LOAN_REQUEST_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOAN_REQUEST_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        loan_request: payload.data.data.loan_requests,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOAN_REQUEST_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
