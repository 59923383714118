import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  total_pending_requests: 0,
  total_pending_employee_requests: 0,
  total_pending_team_requests: 0,
  total_pending_tasks: 0,
  total_employees_without_email: 0,
  fetching: false,
  requests_sataistics: {
    pending_tasks_count: {},
    actions_count: {},
  },
}

export default function pendingTasksRequestsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case actions.SATAISTICS_LOAD_PENDING:
    case actions.REQUESTS_SATAISTICS_LOAD_PENDING:
    case actions.PENDING_REQUESTS_AND_TASKS_COUNT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SATAISTICS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
        requests_sataistics: {
          ...payload.data.data,
        },
      }

    case actions.PENDING_REQUESTS_AND_TASKS_COUNT_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.REQUESTS_SATAISTICS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        requests_sataistics: {
          ...payload.data.data,
        },
      }

    case actions.SATAISTICS_LOAD_REJECTED:
    case actions.REQUESTS_SATAISTICS_LOAD_REJECTED:
    case actions.PENDING_REQUESTS_AND_TASKS_COUNT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
