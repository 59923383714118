import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'
import { TAutomationHistory } from './types'

type StateTypes = {
  fetching: boolean
  automation_events: TAutomationHistory[]
  pagination?: PaginationTypes
}

const DEFAULT_STATE: StateTypes = {
  fetching: false,
  automation_events: [],
}

type TDefualtState = typeof DEFAULT_STATE

export default function reducer(state = DEFAULT_STATE, action: Action): TDefualtState {
  switch (action.type) {
    case actions.AUTOMATION_HISTORIES_LOAD_PENDING:
    case actions.AUTOMATION_HISTORY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.AUTOMATION_HISTORY_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }
    case actions.AUTOMATION_HISTORIES_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        automation_events: [
          ...(action.payload.data.data?.pagination?.previous_page ? state.automation_events : []),
          ...action.payload.data.data?.automation_events,
        ],
        fetching: false,
      }
    }
    case actions.AUTOMATION_HISTORIES_LOAD_REJECTED:
    case actions.AUTOMATION_HISTORY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
