import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { OrganizationState } from 'redux/setting/v2/organizationProfileReducer'

const DEFAULT_STATE: Omit<
  OrganizationState,
  'fetchingLogo' | 'fetchingContactInfo' | 'fetchingProfile'
> = {
  organization: { logo: {}, contact_information: {}, bank_info: {} },
  errMsg: null,
  succMsg: null,
  fetching: false,
  organization_currency: {
    currency_type_i18n: '',
  },
}

let changes = null
export default function organizationProfileReducer(
  state = DEFAULT_STATE,
  action: Action,
): Omit<OrganizationState, 'fetchingLogo' | 'fetchingContactInfo' | 'fetchingProfile'> {
  switch (action.type) {
    case 'ORGANIZATION_LOAD_PENDING':
    case 'ORGANIZATION_UPDATE_PENDING':
    case 'ORGANIZATION_CONTACT_INFO_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'ORGANIZATION_LOAD_REJECTED':
    case 'ORGANIZATION_UPDATE_REJECTED':
    case 'ORGANIZATION_CONTACT_INFO_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ORGANIZATION_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'GET_ORGANIZATION_CURRENCY_TYPE_LOAD_FULFILLED':
      const { currency_type_i18n, currency_type } = action.payload.data.data?.organization
      return {
        ...state,
        organization_currency: {
          ...state.organization_currency,
          ...action.payload.data.data.organization,
          currency_type_i18n:
            currency_type?.toLowerCase() === 'sar' || currency_type_i18n?.toLowerCase() === 'sar'
              ? '񥀠'
              : currency_type_i18n,
        },
      }

    case 'ORGANIZATION_UPDATE_FULFILLED':
    case 'ORGANIZATION_CONTACT_INFO_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
