export const IS_AUTO_RESUMPTION_ENABLED_LOAD = 'IS_AUTO_RESUMPTION_ENABLED_LOAD'
export const IS_AUTO_RESUMPTION_ENABLED_LOAD_FULFILLED = 'IS_AUTO_RESUMPTION_ENABLED_LOAD_FULFILLED'
export const IS_AUTO_RESUMPTION_ENABLED_LOAD_REJECTED = 'IS_AUTO_RESUMPTION_ENABLED_LOAD_REJECTED'

export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD = 'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD'
export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_FULFILLED =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_FULFILLED'
export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_REJECTED =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_REJECTED'

export const IS_AUTO_RESUMPTION_ENABLED_UPDATE = 'IS_AUTO_RESUMPTION_ENABLED_UPDATE'
export const IS_AUTO_RESUMPTION_ENABLED_UPDATE_PENDING = 'IS_AUTO_RESUMPTION_ENABLED_UPDATE_PENDING'
export const IS_AUTO_RESUMPTION_ENABLED_UPDATE_FULFILLED =
  'IS_AUTO_RESUMPTION_ENABLED_UPDATE_FULFILLED'
export const IS_AUTO_RESUMPTION_ENABLED_UPDATE_REJECTED =
  'IS_AUTO_RESUMPTION_ENABLED_UPDATE_REJECTED'

export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE'
export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_PENDING =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_PENDING'
export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_FULFILLED =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_FULFILLED'
export const IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_REJECTED =
  'IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_REJECTED'

export const RESUMPTION_PERIOD_LOAD = 'RESUMPTION_PERIOD_LOAD'
export const RESUMPTION_PERIOD_LOAD_PENDING = 'RESUMPTION_PERIOD_LOAD_PENDING'
export const RESUMPTION_PERIOD_LOAD_FULFILLED = 'RESUMPTION_PERIOD_LOAD_FULFILLED'
export const RESUMPTION_PERIOD_LOAD_REJECTED = 'RESUMPTION_PERIOD_LOAD_REJECTED'

export const RESUMPTION_PERIOD_UPDATE = 'RESUMPTION_PERIOD_UPDATE'
export const RESUMPTION_PERIOD_UPDATE_PENDING = 'RESUMPTION_PERIOD_UPDATE_PENDING'
export const RESUMPTION_PERIOD_UPDATE_FULFILLED = 'RESUMPTION_PERIOD_UPDATE_FULFILLED'
export const RESUMPTION_PERIOD_UPDATE_REJECTED = 'RESUMPTION_PERIOD_UPDATE_REJECTED'
