import React from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { FileUpload } from '@jisr-hr/ds-beta'
import { useSelector } from 'utils/hooks'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { ReactComponent as HeartIcon } from 'assets/images/Heart.svg'
import { useRequest } from '../../RequestProvider'
import { requestDateFormat } from '../../helper'
import { RequestType } from '../../components/FlightTicketDetails/helper'
import useTaskFooter from '../../Footer/useTaskFooter'

const TicketInfoTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { onAttachTaskFile } = useTaskFooter()
  const { fetching_submit } = useSelector((state) => state.approvals)
  const { leaving_date, returning_date, number_of_tickets, family_details } =
    request.task_info?.ticket_info

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          type_i18n: request.name,
          created_at: request?.request?.created_time,
          requested_on: request?.request?.created_time,
          items: [
            {
              label: i18n.t('ticket_count'),
              value: number_of_tickets,
            },
            {
              label: i18n.t('leaving_date'),
              value: requestDateFormat(leaving_date),
            },
            {
              label: i18n.t('returning_date'),
              value: requestDateFormat(returning_date),
            },
            {
              icon: <HeartIcon />,
              value: <RequestType items={family_details.map((data) => data.name)} />,
              label: i18n.t('family_members'),
            },
          ],
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default TicketInfoTask
