import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import i18n from 'translations/i18n'
import { isValid } from 'date-fns'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  header: [],
  records: [],
  totals: {},
  labels: {},
  view_type: 'table_view',
  extra_columns: [],
  pdf_options: {},
  attendance_details_summary: 0,
  filters: {
    business_unit_ids: [],
    department_ids: [],
    location_ids: [],
    employment_type_ids: [],
    job_title_ids: [],
    grade_ids: [],
    nationalities: [],
    area_ids: [],
    include_exempted_employees: false,
    status_employee: [],
  },
  is_available: false,
}
const calendarExcludedKeys = [
  'working_hours',
  'late_arrival',
  'excused_late_arrival',
  'early_departure',
  'excused_early_departure',
  'overtime',
  'approved_overtime',
]

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ATTENDANCE_DETAILS_REPORT_LOAD_PENDING: {
      return {
        ...state,
        ...action.payload,
        fetching: true,
      }
    }

    case actions.ATTENDANCE_DETAILS_REPORT_SHARE_PENDING: {
      return {
        ...state,
        fetching_share: true,
      }
    }
    case actions.ATTENDANCE_DETAILS_REPORT_LOAD_FULFILLED: {
      const { data: payload } = action.payload.data
      if (state.view_type === 'calendar_view') {
        const keys = payload?.header_keys?.filter((key) => !isValid(new Date(key))) || []
        payload.header_keys = [...keys, 'dates']
        payload.header_keys =
          payload?.header_keys.filter((k) => !calendarExcludedKeys.includes(k)) ?? []
      }
      if (state.view_type === 'table_view') {
        payload?.header_keys?.splice(4, 0, ...['shift_timing', 'in_time', 'out_time'])
        payload?.header?.splice(
          4,
          0,
          ...[i18n.t('shift_timing'), i18n.t('in_time'), i18n.t('out_time')],
        )
      }

      return {
        ...state,
        ...payload,
        fetching: false,
      }
    }

    case actions.ATTENDANCE_DETAILS_REPORT_SHARE_FULFILLED: {
      Toastr.success(i18n.t('email_sent_successfully'))
      return {
        ...state,
        fetching_share: false,
      }
    }
    case actions.ATTENDANCE_DETAILS_REPORT_SHARE_REJECTED:
    case actions.ATTENDANCE_DETAILS_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }
    }

    default:
      return state
  }
}
