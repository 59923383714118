export const ONBOARDINGـLOCATIONS_LOAD = 'ONBOARDINGـLOCATIONS_LOAD'
export const ONBOARDINGـLOCATIONS_LOAD_PENDING = 'ONBOARDINGـLOCATIONS_LOAD_PENDING'
export const ONBOARDINGـLOCATIONS_LOAD_FULFILLED = 'ONBOARDINGـLOCATIONS_LOAD_FULFILLED'
export const ONBOARDINGـLOCATIONS_LOAD_REJECTED = 'ONBOARDINGـLOCATIONS_LOAD_REJECTED'

export const ONBOARDING_LOCATIONS_UPDATE = 'ONBOARDING_LOCATIONS_UPDATE'
export const ONBOARDING_LOCATIONS_UPDATE_PENDING = 'ONBOARDING_LOCATIONS_UPDATE_PENDING'
export const ONBOARDING_LOCATIONS_UPDATE_FULFILLED = 'ONBOARDING_LOCATIONS_UPDATE_FULFILLED'
export const ONBOARDING_LOCATIONS_UPDATE_REJECTED = 'ONBOARDING_LOCATIONS_UPDATE_REJECTED'

export const ONBOARDING_LOCATIONS_CREATE = 'ONBOARDING_LOCATIONS_CREATE'
export const ONBOARDING_LOCATIONS_CREATE_PENDING = 'ONBOARDING_LOCATIONS_CREATE_PENDING'
export const ONBOARDING_LOCATIONS_CREATE_FULFILLED = 'ONBOARDING_LOCATIONS_CREATE_FULFILLED'
export const ONBOARDING_LOCATIONS_CREATE_REJECTED = 'ONBOARDING_LOCATIONS_CREATE_REJECTED'

export const ONBOARDING_LOCATIONS_DELETE = 'ONBOARDING_LOCATIONS_DELETE'
export const ONBOARDING_LOCATIONS_DELETE_PENDING = 'ONBOARDING_LOCATIONS_DELETE_PENDING'
export const ONBOARDING_LOCATIONS_DELETE_FULFILLED = 'ONBOARDING_LOCATIONS_DELETE_FULFILLED'
export const ONBOARDING_LOCATIONS_DELETE_REJECTED = 'ONBOARDING_LOCATIONS_DELETE_REJECTED'

export const ONBOARDING_CITIES_GET_ALL_LOAD = 'ONBOARDING_CITIES_GET_ALL_LOAD'
export const ONBOARDING_CITIES_GET_ALL_LOAD_PENDING = 'ONBOARDING_CITIES_GET_ALL_LOAD_PENDING'
export const ONBOARDING_CITIES_GET_ALL_LOAD_FULFILLED = 'ONBOARDING_CITIES_GET_ALL_LOAD_FULFILLED'
export const ONBOARDING_CITIES_GET_ALL_LOAD_REJECTED = 'ONBOARDING_CITIES_GET_ALL_LOAD_REJECTED'

export const ONBOARDING_REGIONS_GET_ALL_LOAD = 'ONBOARDING_REGIONS_GET_ALL_LOAD'
export const ONBOARDING_REGIONS_GET_ALL_LOAD_PENDING = 'ONBOARDING_REGIONS_GET_ALL_LOAD_PENDING'
export const ONBOARDING_REGIONS_GET_ALL_LOAD_FULFILLED = 'ONBOARDING_REGIONS_GET_ALL_LOAD_FULFILLED'
export const ONBOARDING_REGIONS_GET_ALL_LOAD_REJECTED = 'ONBOARDING_REGIONS_GET_ALL_LOAD_REJECTED'
