import { CSSProperties, FC, ReactNode } from 'react'
import { Flex, TextField, TextFieldProps, Typography } from '@jisr-hr/ds'
import { Field, FieldRenderProps } from 'react-final-form'
import { sanitizeInput } from './form.helper'

type NewTextInputFieldProps = {
  name: string
  label?: string
  size?: TextFieldProps['size']
  type?: TextFieldProps['type']
  placeholder?: string
  labelStyles?: CSSProperties
  status?: string
  statusMessage?: string
  className?: string
  disabled?: boolean
  iconTrailing?: ReactNode
  actionButton?: ReactNode
  showErrorMessage?: boolean
  dropdownLeading?: ReactNode
  dropdownTrailing?: ReactNode
  testId?: string
  validate?: (_: any, values: any) => string | undefined
  parse?: <V, R>(value: V, name: string) => R
  onChange?: (val: string) => void
  helperText?: string
  maxLength?: number
}

const NewTextInputField: FC<NewTextInputFieldProps> = (props): JSX.Element => {
  const {
    name,
    validate,
    parse,
    onChange,
    type,
    showErrorMessage = true,
    status,
    statusMessage,
    label,
    maxLength,
    ...rest
  } = props
  return (
    <Field
      name={name}
      parse={parse}
      validate={validate}
    >
      {({ input, meta }: FieldRenderProps<string, HTMLElement>): JSX.Element => (
        <Flex
          flexCol
          className="gap-2"
        >
          {label && (
            <Typography
              text={label}
              variant="interface/medium/sm"
            />
          )}
          <TextField
            id={name}
            {...input}
            type={type}
            destructive={(!!meta.error && !!meta.touched) || status === 'error'}
            helperText={(!!meta.touched && showErrorMessage && meta.error) || statusMessage}
            onChange={(value: string): void => {
              const sanitized = sanitizeInput(value)
              if (onChange) onChange(sanitized)
              input.onChange(sanitized)
            }}
            maxLength={maxLength}
            {...rest}
          />
        </Flex>
      )}
    </Field>
  )
}

export default NewTextInputField
