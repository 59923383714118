import { useActionTaskRequest } from 'apis/OrgManagement/requests/useRequests'
import QueryString from 'qs'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import {
  approvalDetailReset,
  taskDetailLoad,
  updateTask,
} from 'redux/requests/newRequestsApproval/actionCreators'
import { usePerformanceStore } from 'store/performance/usePerformanceStore'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { useRequest } from '../RequestProvider'

const useTaskFooter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openATSModal, setOpenATSModal] = React.useState(false)
  const [showRejectReason, setShowRejectReason] = React.useState(false)
  const { company, employee } = useSelector(({ auth }) => auth)
  const {
    vars: { request },
    drawerToggle,
    actionCallback,
    showPaymentDate,
    setShowPaymentDate,
  } = useRequest()
  const status = request?.status
  const { fetching_submit } = useSelector((state) => state.approvals)
  const { values } = useFormState()
  const setStatus = usePerformanceStore((s) => s.EvaluationSteps.setStatus)
  const onClose = () => dispatch(approvalDetailReset())

  const showPayment =
    [
      'loan_payment_task',
      'advance_vacation_salary_payment_task',
      'final_settlement_payment_task',
      'expense_claim_payment_task',
      'business_trip_payment_task',
    ].includes(request?.category) && request?.can_mark_as_completed

  const { mutateAsync: onActionTask } = useActionTaskRequest()

  const onTaskLoad = () => {
    dispatch(taskDetailLoad(request?.id)).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
      onClose()
    })
  }
  const reloadAfterAction = (action?: () => Promise<void>) => {
    if (action) {
      dispatch(action)
    } else {
      onTaskLoad()
    }
  }
  const handlPaymentDate = async () => {
    await onActionTask({ taskId: request.id, status: 'complete', paid_at: values?.paid_at })
    reloadAfterAction()
  }

  const handlemarkAsCompleted = async () => {
    if (showPayment) {
      setShowPaymentDate(true)
    } else {
      await onActionTask({ taskId: request.id, status: 'complete' })
      reloadAfterAction()
    }
  }

  const onAttachTaskFile = (file: { file_file_name?: string; name?: string; file: string }) => {
    const fileData = {
      filename: file?.name ?? file?.file_file_name,
      data: file?.file,
    }
    reloadAfterAction(async () => {
      await dispatch(updateTask(request.id, { task: { file: fileData } }))
    })
  }

  const handleCreateJob = async () => {
    if (!company?.subscribed_features?.includes('applicant_tracking_system')) {
      setOpenATSModal(true)
      return
    }
    await drawerToggle()
    const params = QueryString.stringify({
      requestId: request.id,
    })
    history.push(`/ats/recruitment/job_openings?${params}`)
  }

  const handelRejectionTask = async (comment: string) => {
    await onActionTask({ taskId: request?.id, status: 'reject', rejectionReason: comment })
    await onTaskLoad()
    setShowRejectReason(false)
  }

  const handelTaskLinkBtn = () => {
    if (status === 'Pending' && !showPaymentDate && request?.can_mark_as_completed) {
      return {
        label: i18n.t('label.reject_task'),
        variant: 'danger',
        onClick: () => setShowRejectReason(true),
      }
    }
    return null
  }

  const handleTaskSubmitBtn = () => {
    if (status === 'Pending' && !showPaymentDate && request?.can_mark_as_completed) {
      return {
        label: i18n.t('label.mark_as_completed'),
        leadingIcon: 'check',
        type: 'submit',
        form: 'task-id',
        onClick: () => (request?.group_details ? setStatus('completed') : handlemarkAsCompleted()),
        loading: fetching_submit,
        testId: 'task_complete',
      }
    } else if (
      status === 'Pending' &&
      !showPaymentDate &&
      request?.can_mark_as_completed &&
      request?.request_type === 'HiringRequestTask'
    ) {
      return {
        label: i18n.t('label.mark_as_completed'),
        leadingIcon: 'check',
        onClick: handlemarkAsCompleted,
      }
    }
    return null
  }
  const handleTaskCancelBtn = () => {
    if (
      status === 'Pending' &&
      !showPaymentDate &&
      request?.can_mark_as_completed &&
      request?.request_type === 'HiringRequestTask'
    ) {
      return {
        label: i18n.t('label.new_job'),
        disabled: !employee?.permission_scopes?.edit_create_jobs,
        onClick: () => handleCreateJob(),
      }
    } else if (request.can_mark_as_completed && request?.group_details && status === 'Pending') {
      return {
        label: i18n.t('save_changes'),
        type: 'submit',
        form: 'task-id',
        onClick: () => setStatus(null),
      }
    }
    return null
  }
  return {
    showRejectReason,
    openATSModal,
    setOpenATSModal,
    setShowRejectReason,
    handelRejectionTask,
    handleTaskCancelBtn,
    handleTaskSubmitBtn,
    handelTaskLinkBtn,
    handlPaymentDate,
    onAttachTaskFile,
  }
}

export default useTaskFooter
