import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  Tabs,
  DetailsAttachments,
  Workflow,
  Comments,
} from 'components/global/templates/RequestDrawer/components'
import { isRtl } from 'utils/uiHelpers'
import { format } from 'utils/date'
import DSIcon from 'components/DSIcon'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const CustomRequestIcons = {
  text: (
    <DSIcon
      name="type-01"
      size="md"
      color="var(--color-natural-gray-dark-new)"
    />
  ),
  number: (
    <DSIcon
      name="calculator"
      size="md"
      color="var(--color-natural-gray-dark-new)"
    />
  ),
  multiple_choice: (
    <DSIcon
      name="check-done-02"
      size="md"
      color="var(--color-natural-gray-dark-new)"
    />
  ),
  date: (
    <DSIcon
      name="calendar"
      color="var(--color-natural-gray-dark-new)"
    />
  ),
}

const CustomRequest = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState('request_details')
  const { loading, request, reloadRequest } = useRequestDetail()

  const items = request.metadata?.fields_value
    ?.filter(Boolean)
    ?.map((item: { type: string; name_en: string; name_ar: string; value: Array<string> }) => ({
      icon: CustomRequestIcons[item.type],
      label: isRtl ? item.name_ar : item.name_en,
      value:
        item.type === 'date'
          ? item.value?.map((v: string) => format(v, 'EEEE dd MMMM yyyy')).join(' - ')
          : item.value?.map((v: string) => v).join(', '),
    }))

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab): void => setActiveTab(tab)}
      />

      {/* request details tab */}
      {activeTab === 'request_details' && (
        <>
          <Info details={{ ...request, items }} />
          {!isEmpty(request.attachments) && (
            <DetailsAttachments attachments={request.attachments} />
          )}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          {request.approvals?.length > 0 && (
            <Workflow
              lessDetail
              approvals={request.approvals}
            />
          )}
        </>
      )}

      {/* comments & workflow tab */}
      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.id,
              request_type: 'Request',
            }}
            can_add_comment={request?.can_add_comment}
          />

          {request.approvals?.length > 0 && <Workflow approvals={request.approvals} />}
        </>
      )}
    </Flex>
  )
}

export default CustomRequest
