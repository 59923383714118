import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  employee_contract: {},
  employee_contracts: [],
  fetching: false,
  starting_date: '',
  contractEvaluationExists: false,
}

type EmployeeContractState = typeof initialState

export default function reducer(state = initialState, action: Action): EmployeeContractState {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_CONTRACTS_CREATE_PENDING:
    case actions.EMPLOYEE_CONTRACTS_DELETE_PENDING:
    case actions.EMPLOYEE_CONTRACTS_UPDATE_PENDING:
    case actions.EMPLOYEE_CONTRACTS_LOAD_PENDING:
    case actions.CHECK_CONTRACT_PERIOD_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEE_CONTRACTS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEE_CONTRACTS_CREATE_FULFILLED:
    case actions.EMPLOYEE_CONTRACTS_DELETE_FULFILLED:
    case actions.EMPLOYEE_CONTRACTS_UPDATE_FULFILLED:
    case actions.CHECK_CONTRACT_PERIOD_LOAD_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        contractEvaluationExists: payload?.data?.data?.contract_evaluation_exists,
        fetching: false,
      }

    case actions.EMPLOYEE_CONTRACTS_CREATE_REJECTED:
    case actions.EMPLOYEE_CONTRACTS_DELETE_REJECTED:
    case actions.EMPLOYEE_CONTRACTS_LOAD_REJECTED:
    case actions.EMPLOYEE_CONTRACTS_UPDATE_REJECTED:
    case actions.CHECK_CONTRACT_PERIOD_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
