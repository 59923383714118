export const mapTaskDetail = (task) => {
  return {
    ...task,
    title: task.name,
    isTask: true,
    request_type: `${task.category ? task.task_info_type : 'Custom'}Task`,
    approvals: [
      {
        assignee: task.assignee,
        status: task.status,
        received_at: task.created_at,
      },
    ],
    attachments: [
      task?.file_url && {
        url: task.file_url,
        name: task.file_file_name,
      },
    ].filter(Boolean),
  }
}
