import { useEffect, useState } from 'react'
import { SearchBar, Modal } from '@jisr-hr/design-system'
import { Typography, Button, Modal as NewModal, Flex, Spacer } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { pick } from 'lodash'
import JisrLoader from 'components/global/JisrLoader'
import { TablePagination } from 'components/global/atoms'
import { TParams, StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'

import {
  bulkDeleteEmployees,
  employeesLoad,
  getAllGradesLoad,
  onboardingGetAllEmployeesLoad,
} from 'redux/organizationOnborading/employees/actionCreators'
import { loadOnboardingNationalities } from 'redux/organizationOnborading/nationalities/actionCreators'
import { listDepartmentsLoad } from 'redux/newDepartments/actionCreators'
import { locationLoad } from 'redux/organizationOnborading/locations/actionCreators'
import { indexJobTitles } from 'redux/organizationOnborading/jobTitles/actionCreators'
import { indexCompanies } from 'redux/organizationOnborading/outsourcingCompanies/actionCreators'
import { businessUnitLoad } from 'redux/organizationOnborading/businessUnits/actionCreators'
import { employmentTypesList } from 'redux/organizationOnborading/employmentTypes/actionCreators'
import { TExtendsEmployee } from 'redux/organizationOnborading/employees/employees.type'

import StepTemplate from '../../../components/StepTemplate'
import { Body, TableEmployeeData } from './Content'
import styles from './style.module.css'

export type TLocation = {
  id: number
  name: string
}
export type TAjeer = {
  id: null | number
  name: string
  name_ar: string
  name_i18n: string
}

export type TDeleteValues = {
  checkboxValues: number[]
  confirmModaValues: number[]
}

const EmployeeData = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const [initialValues, setInitialValues] = useState<Partial<TExtendsEmployee> | null>(null)
  const [params, setParams] = useState<TParams>({
    search: '',
    page: 1,
    rpp: 10,
  })
  const [deleteValues, setDeleteValues] = useState<TDeleteValues>({
    checkboxValues: [],
    confirmModaValues: [],
  })

  const { grades, employees, fetching, pagination, all_employees, loading } = useSelector(
    (s) => s.reviewingEmployee,
  )
  const nationalities = useSelector((s) => s.onboardingNationalities.nationalities)
  const departments = useSelector((s) => s.newDepartments.list_departments)
  const employmentTypes = useSelector((s) => s.employmentTypes.employment_types)
  const { outsourcingCompanies } = useSelector((s) => s.outsourcingCompanies)
  const jobTitles = useSelector((s) => s.jobTitles.job_titles)
  const businessUnits = useSelector((s) => s.businessUnits.business_units)
  const locations = useSelector((s) => s.locations.locations)

  const handleFiltering = (key: string, val: number): void => {
    setParams((prevState) => ({ ...prevState, [key]: val }))
  }

  const handleCheckbox = ({ isChecked, id }: { isChecked: boolean; id: number }): void => {
    if (isChecked) {
      setDeleteValues((prev) => ({
        ...prev,
        checkboxValues: [...prev.checkboxValues, id],
      }))
    } else {
      setDeleteValues((prev) => ({
        ...prev,
        checkboxValues: [...prev.checkboxValues.filter((v) => v !== id)],
      }))
    }
  }

  const handleDelete = (): void => {
    dispatch(bulkDeleteEmployees(deleteValues.confirmModaValues))
      .finally(() => {
        setDeleteValues(() => ({ checkboxValues: [], confirmModaValues: [] }))
      })
      .then(() => {
        dispatch(employeesLoad(params))
      })
      .catch(() => {
        if (deleteValues.confirmModaValues.length > 1) {
          dispatch(employeesLoad(params))
        }
      })
  }

  useEffect(() => {
    dispatch(employeesLoad(params))
  }, [params])

  useEffect(() => {
    dispatch(locationLoad())
    dispatch(listDepartmentsLoad())
    dispatch(loadOnboardingNationalities())
    dispatch(indexJobTitles())
    dispatch(indexCompanies())
    dispatch(businessUnitLoad())
    dispatch(employmentTypesList())
    dispatch(getAllGradesLoad())
    dispatch(onboardingGetAllEmployeesLoad())
  }, [])

  return (
    <>
      <StepTemplate
        goNextStep={goNextStep}
        goPreviousStep={goPreviousStep}
        nextStep={nextStep}
        previousStep={previousStep}
        fetching={fetching}
      >
        {fetching || (loading && <JisrLoader absolute />)}
        <Typography
          variant="interface/default/m"
          text={I18n.t('reviewing_employee_data')}
        />
        <Spacer height="8px" />
        <Flex style={{ gap: 6 }}>
          <SearchBar
            placeholder={I18n.t('search_employees_by_id_or_name')}
            className={styles.searchInput}
            onChange={(_: string, search: string): void =>
              setParams((prevState) => ({ ...prevState, search, page: 1 }))
            }
            value={params.search}
          />
          <Button
            variant="outlined"
            color="neutral"
            label={I18n.t('add_employee')}
            size="medium"
            disabled={fetching}
            onClick={(): void => setInitialValues({})}
          />
          {!!deleteValues.checkboxValues.length && (
            <Button
              variant="outlined"
              color="neutral"
              label={`${I18n.t('delete')} (${deleteValues.checkboxValues.length})`}
              size="medium"
              disabled={fetching}
              onClick={(): void =>
                setDeleteValues((prev) => ({
                  ...prev,
                  confirmModaValues: prev.checkboxValues,
                }))
              }
            />
          )}
        </Flex>
        <Spacer height="16px" />
        <div className={styles.tableTh}>
          <TableEmployeeData
            employees={employees}
            listChecked={deleteValues.checkboxValues}
            setInitialValues={setInitialValues}
            setDeleteValues={setDeleteValues}
            handleCheckbox={handleCheckbox}
          />
        </div>
        {!!employees.length && (
          <>
            <Spacer height="16px" />
            <TablePagination
              fetching={fetching}
              currentPage={params.page}
              totalEntries={pagination.total_entries}
              rowsPerPage={params.rpp}
              handleChange={(val: number): void => handleFiltering('page', val)}
              handleChangeRPP={(val: number): void =>
                setParams((prevState) => ({ ...prevState, rpp: val, page: 1 }))
              }
            />
          </>
        )}
      </StepTemplate>
      <Modal
        header
        disableCloseOut
        isOpen={initialValues}
        onClose={(): void => setInitialValues(null)}
        content={
          <Body
            params={params}
            line_manager={all_employees}
            openModal={setInitialValues}
            locations={locations}
            departments={departments}
            nationalities={nationalities}
            jobTitles={jobTitles}
            outsourcingCompanies={outsourcingCompanies}
            businessUnits={businessUnits}
            employmentTypes={employmentTypes}
            grades={grades}
            fetching={fetching}
            initialValues={{
              id: initialValues?.id,
              code: initialValues?.code,
              email: initialValues?.email,
              gender: initialValues?.gender,
              // grade_id: initialValues?.grade?.id,
              telephone: initialValues?.telephone,
              location_id: initialValues?.location?.id,
              joining_date: initialValues?.joining_date,
              full_name_en: initialValues?.full_name_en,
              full_name_ar: initialValues?.full_name_ar,
              job_title_id: initialValues?.job_title?.id || null,
              date_of_birth: initialValues?.date_of_birth,
              department_id: initialValues?.department?.id,
              marital_status: initialValues?.marital_status,
              line_manager_id: initialValues?.line_manager?.id || null,
              iqama_profession: initialValues?.iqama_profession,
              business_unit_id: initialValues?.business_unit?.id || null,
              employment_type_id: initialValues?.employment_type?.id,
              iqama_profession_ar: initialValues?.iqama_profession_ar,
              employee_outsourcing_company_attributes: {
                outsourcing_company_id: initialValues?.outsourcing_company?.id,
              },
              employee_contracts_attributes: [
                {
                  contract_period: initialValues?.employee_contract?.contract_period,
                  contract_type: initialValues?.employee_contract?.contract_type,
                  id: initialValues?.employee_contract?.id,
                  end_date: initialValues?.employee_contract?.end_date,
                  start_date: initialValues?.joining_date,
                },
              ],
              identification_info_attributes: {
                ...pick(initialValues?.identification_info, [
                  'religion',
                  'iqama_expiry_date_ar',
                  'passport_number',
                  'passport_expiry_date',
                  'iqama_issue_date',
                  'iqama_expiry_date',
                  'employer_number',
                ]),
                document_number: initialValues?.document_number,
                nationality_id: initialValues?.nationality?.id,
                not_in_saudi: initialValues?.identification_info?.not_in_saudi?.toString(),
              },
            }}
          />
        }
      />
      <NewModal
        variant="danger"
        header={{ title: I18n.t('delete_confirmation'), withClose: true }}
        disableCloseOut={false}
        footer={{
          cancel: {
            label: I18n.t('cancel'),
            onClick: (): void => setDeleteValues((prev) => ({ ...prev, confirmModaValues: [] })),
          },
          submit: { label: I18n.t('delete'), onClick: handleDelete, loading: fetching },
        }}
        open={!!deleteValues.confirmModaValues.length}
      >
        <Typography
          variant="body-new/regular"
          style={{ color: 'var(--color-base-colors-black-700)' }}
          text={I18n.t(
            deleteValues.confirmModaValues.length > 1
              ? 'would_you_like_to_delete_employees_description'
              : 'would_you_like_to_delete_description',
          )}
          testId="message"
        />
      </NewModal>
    </>
  )
}

export default EmployeeData
