import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  fetching: false,
  offboarding_tasks: [],
}

type TasksState = typeof initialState

export default function reducer(state = initialState, action: Action): TasksState {
  const { payload } = action
  switch (action.type) {
    case actions.TASKS_LOAD_PENDING:
    case actions.TASK_REASSIGN_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.TASKS_LOAD_FULFILLED:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.TASK_REASSIGN_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.TASKS_LOAD_REJECTED:
    case actions.TASK_REASSIGN_PUT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
