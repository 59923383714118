import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function requestCommentsPost(comment) {
  const url = `/${API_VERSION_TWO}/comments`
  return {
    type: actions.REQUEST_COMMENTS_POST,
    payload: handleAPI(url, {}, 'POST', { comment }),
  }
}
