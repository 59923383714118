import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

const Shifts = (props) => {
  const { shifts } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={shifts.map((shift) => ({
        ...shift,
        name_i18n: shift.open_shift_type
          ? `${shift.name_i18n} - ${i18n.t('open_shift')}`
          : shift.name_i18n,
      }))}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

Shifts.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default Shifts
