import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const MenuPopup = ({ trigger, handleOpen, content, active, startRight }) => (
  <div
    className="jisr-menu-popup"
    onFocus={handleOpen}
    tabIndex="0"
  >
    <div className="jisr-menu-popup__trigger">{trigger}</div>
    <div
      className={classNames(
        'jisr-menu-popup__content',
        active && 'jisr-menu-popup__content--active',
        startRight && 'jisr-menu-popup__content--startRight',
      )}
    >
      {content}
    </div>
  </div>
)

MenuPopup.propTypes = {
  trigger: PropTypes.node,
  handleOpen: PropTypes.func,
  // handleClose: PropTypes.func,
  active: PropTypes.bool,
  // hideInOnClick: PropTypes.bool,
  startRight: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default MenuPopup
