import i18n from 'translations/i18n'

export const runsOptions = {
  semi_annually: [
    { value: null, label: i18n.t('all') },
    { value: '1', label: i18n.t('_1st_half') },
    { value: '2', label: i18n.t('_2nd_half') },
  ],
  quarterly: [
    { value: null, label: i18n.t('all') },
    { value: '1', label: i18n.t('_1st_quarter') },
    { value: '2', label: i18n.t('_2nd_quarter') },
    { value: '3', label: i18n.t('_3rd_quarter') },
    { value: '4', label: i18n.t('_4th_quarter') },
  ],
}

export const runsTabs = {
  semi_annually: [
    { value: 1, label: i18n.t('_1st_half') },
    { value: 2, label: i18n.t('_2nd_half') },
  ],
  quarterly: [
    { value: 1, label: i18n.t('_1st_quarter') },
    { value: 2, label: i18n.t('_2nd_quarter') },
    { value: 3, label: i18n.t('_3rd_quarter') },
    { value: 4, label: i18n.t('_4th_quarter') },
  ],
}

export const tabTitles = {
  quarterly: ['_1st_quarter', '_2nd_quarter', '_3rd_quarter', '_4th_quarter'],
  semi_annually: ['_1st_half', '_2nd_half'],
  annually: [''],
  probation: [''],
}

export const runTitles = {
  quarterly: ['_1st_quarter', '_2nd_quarter', '_3rd_quarter', '_4th_quarter'],
  semi_annually: ['_1st_half', '_2nd_half'],
  annually: [''],
  probation: [''],
}

export const ratingStatus = {
  primary: 'primary',
  not_started: 'gray',
  to_be_given: 'gray',
  running: 'yellow',
  off_track: 'yellow',
  in_progress: 'yellow',
  completed: 'green',
  shared: 'green',
  on_track: 'green',
}

export const symbols = {
  percentage: '%',
  amount: '$',
}

export type targetValueData = {
  progress: string
  progress_method: string
  target: string
}

export const tabKeysByIndex = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
}

export const tabKeysToIndex = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
}
