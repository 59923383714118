import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { dateWithOutTimeZone, format } from 'utils/date'
import _ from 'lodash'
import { TimePickerField } from 'components/final-form'
import { durationByMinutes, replaceAmPm, timeToNumbers } from 'components/global/form/formNormalize'
import { Flex } from '@jisr-hr/ds'
import { Select, Table } from '@jisr-hr/design-system'
import { Grid } from 'components/global/atoms'
import { useEmployeeAttendanceShifts } from 'components/global/templates/RequestDrawer/hooks'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import IncreaseDecreaseField from 'components/global/templates/RequestDrawer/ds/IncreaseDecreaseField'
import { useSelector } from 'react-redux'
import { uniqId } from 'utils/uiHelpers'

import { ReactComponent as CalendarIcon } from '../icons/calendar.svg'

import { entryMethodTypes, canSubmitOverTime, headers } from '../helper'
import Total from './Total'
import TextParagraph from './TextParagraph'
import Styles from '../OvertimeDuration.module.css'
import AddDay from './AddDay'

const MultipleDays = (props) => {
  const { change } = useForm()
  const { values } = useFormState()
  const { vars, handleDisableSubmit } = useRequest()
  const { shiftsDetail, resetShifts, loadShifts } = useEmployeeAttendanceShifts()

  const employee_id = useSelector(({ requestDetails }) => requestDetails.request?.employee?.id)
  const empId = vars.id ?? employee_id

  const [entryMethod, setEntryMethod] = React.useState('different_durations')

  const durationMins = timeToNumbers(values.duration).totalMinutes
  const overtimeAttribute = _.groupBy(values?.multiple_shifts, 'date')
  const entryVal = entryMethodTypes.find((option) => option.value === entryMethod)
  const distributedEquallyMaxDuration = durationByMinutes(values?.multiple_shifts?.length * 1440)

  const totalDuration = React.useMemo(
    () =>
      values?.multiple_shifts?.reduce((result, record) => {
        const minutes = timeToNumbers(record?.duration)?.totalMinutes || 0

        return result + minutes
      }, 0),
    [values?.multiple_shifts],
  )

  const handleEntryMethod = (v) => {
    setEntryMethod(v.value)

    // Set duration to total of shifts duration
    if (v.value === 'distributed_equally') {
      change('duration', durationByMinutes(totalDuration))
    } else {
      change('duration', null) // Reset duration to avoid not recalculate when duration is same
    }
  }

  const handleRecords = () => {
    let records = []

    values?.overtime_dates?.forEach((date) => {
      let newRecord = []

      // Previous Record
      const existRec = values?.multiple_shifts?.filter(({ date: shiftDate }) => shiftDate === date)

      // New Record => Set initial Record Values for future days
      const newRec = shiftsDetail?.find(({ date: shiftDate }) => shiftDate === date) || {
        date,
        employee_shifts: [],
      }

      // Calculate new Record when there is no exists Records
      // Generate uniq Id for find record index
      if (_.isEmpty(existRec)) {
        newRec?.employee_shifts?.forEach((r) => {
          newRecord = [
            ...newRecord,
            {
              date: newRec?.date,
              duration: '00:00',
              detail: {
                start: r?.shift_start_time,
                end: r?.shift_end_time,
                employee_shift_id: r?.id,
                indexId: uniqId(),
                ...newRec,
              },
            },
          ]
        })

        if (_.isEmpty(newRec?.employee_shifts)) {
          newRecord = [{ date, duration: '00:00', detail: { ...newRec, indexId: uniqId() } }]
        }
      }

      records = [...records, ...existRec, ...newRecord]
    })

    return records
  }

  const checkDuration = (val) => {
    const shiftsCount = values?.multiple_shifts?.length
    val = timeToNumbers(val).totalMinutes || 0

    if (shiftsCount > val)
      return I18n.t('must_be_or_greater_than', { days: durationByMinutes(shiftsCount) })

    return undefined
  }

  // pass multipleShifts for Calculate initial value when entry is distributed_equally
  const handleOverTimeDurations = (multipleShifts) => {
    const items = multipleShifts || values?.multiple_shifts
    const duration = timeToNumbers(values.duration).totalMinutes || 0

    const quotient = Math.floor(duration / items?.length)
    let remainder = duration % items?.length

    let newShifts = []
    items?.forEach((r) => {
      let minutes = quotient

      if (remainder > 0) {
        minutes += 1
        remainder -= 1
      }

      minutes = minutes > 1440 ? 1440 : minutes // maximum duration 24 hours

      newShifts = [
        ...newShifts,
        {
          ...r,
          duration: durationByMinutes(minutes) || '00:00',
        },
      ]
    })

    change('multiple_shifts', newShifts)
  }

  const handleLabel = (d = {}) => {
    if (d.start) return `${replaceAmPm(d.start)} — ${replaceAmPm(d.end)}`
    else if (d.is_day_off) return I18n.t('off_day')
    return I18n.t('unscheduled')
  }

  React.useEffect(() => {
    if (entryMethod === 'distributed_equally') {
      handleOverTimeDurations()
    }
  }, [values.duration])

  React.useEffect(() => {
    const overtimeDates =
      values?.overtime_type !== 'bulk_overtime'
        ? values?.overtime_dates?.slice(-1)
        : values?.overtime_dates
    if (overtimeDates?.length > 0 && empId) {
      loadShifts(empId, overtimeDates)
    }
  }, [values?.overtime_dates])

  React.useEffect(() => {
    if (values?.overtime_dates?.length > 0) {
      const records = handleRecords()

      // Calculate overtime duration for init val when entry is distributed_equally
      if (entryMethod === 'distributed_equally') {
        handleOverTimeDurations(records)
      } else {
        change('multiple_shifts', values.initialMultipleShifts || records)
      }
    }
  }, [shiftsDetail])

  React.useEffect(() => {
    props?.handleDate(
      vars?.date
        ? {
            start: new Date(vars?.date),
          }
        : {},
    )
  }, [values.overtime_type])

  React.useEffect(() => {
    const canSubmit = canSubmitOverTime(values?.multiple_shifts)
    handleDisableSubmit(!canSubmit)
  }, [values?.multiple_shifts])

  React.useEffect(() => {
    resetShifts()

    return () => {
      handleDisableSubmit(false)
    }
  }, [])

  return (
    <Flex
      flexCol
      className={Styles.container}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={entryMethod === 'different_durations' ? 12 : 6}
        >
          <Select
            label={I18n.t('entry_method')}
            options={entryMethodTypes}
            onChange={handleEntryMethod}
            value={entryVal}
          />
        </Grid>

        {entryMethod !== 'different_durations' && (
          <Grid
            item
            xs={6}
          >
            <IncreaseDecreaseField
              label={I18n.t('overtime')}
              maxTime={distributedEquallyMaxDuration}
              name="duration"
              disabled={values?.multiple_shifts < 1}
              validate={checkDuration}
              touched={!!durationMins}
              placeholder={I18n.t('hm')}
              noLimit
            />
          </Grid>
        )}
      </Grid>
      <Table
        headers={headers}
        variant="rounded"
        className={classNames(
          Styles.overtimeTable,
          entryMethod !== 'different_durations' ? Styles.overtimeSameDuration : undefined,
        )}
      >
        {Object.entries(overtimeAttribute).map(([date, records]) =>
          records?.map((record, subI) => {
            const { detail, duration } = record || {}
            const label = handleLabel(detail)
            const index = values?.multiple_shifts?.findIndex(
              (st) => st.detail.indexId === detail.indexId,
            )

            return (
              <Table.Row key={date}>
                {subI === 0 && (
                  <Table.Cell rowSpan={records?.length}>
                    <TextParagraph label={format(dateWithOutTimeZone(date), 'EEEE, d MMM yyyy')} />
                    <CalendarIcon />
                  </Table.Cell>
                )}

                <Table.Cell>
                  <Flex
                    flexCol
                    className={Styles.shiftDetail}
                  >
                    <TextParagraph label={label} />
                  </Flex>
                </Table.Cell>

                <Table.Cell>
                  <Flex
                    flexCol
                    className={Styles.shiftDetail}
                  >
                    {entryMethod === 'different_durations' ? (
                      <TimePickerField
                        timeOnly
                        hideIcon
                        placeholder={I18n.t('hm')}
                        maxTime="24:00"
                        name={`multiple_shifts[${index}].duration`}
                        withResetTime
                      />
                    ) : (
                      <TextParagraph
                        label={duration || '00:00'}
                        align="end"
                      />
                    )}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            )
          }),
        )}

        {values.overtime_type === 'multiple_days' && <AddDay />}
        <Total totalDuration={totalDuration} />
      </Table>
    </Flex>
  )
}

MultipleDays.propTypes = {
  handleDate: PropTypes.func,
}

export default MultipleDays
