import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { City } from 'redux/reducers/businessTripCitiesReducer'

export function businessTripCitiesLoad(params: { template?: 'public' } = {}): Action {
  const url = `/${API_VERSION_TWO}/business_trip_cities`
  const method = 'GET'
  return {
    type: 'BUSINESS_TRIP_CITIES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function businessTripCityCreate(data: City): Action {
  const url = `/${API_VERSION_TWO}/business_trip_cities/`
  const method = 'POST'
  return {
    type: 'BUSINESS_TRIP_CITY_CREATE',
    payload: handleAPI(url, {}, method, { business_trip_city: data }),
  }
}

export function businessTripCityUpdate(data: City): Action {
  const url = `/${API_VERSION_TWO}/business_trip_cities/${data.id}/`
  const method = 'PUT'
  return {
    type: 'BUSINESS_TRIP_CITY_UPDATE',
    payload: handleAPI(url, {}, method, { business_trip_city: data }),
  }
}

export function businessTripCityDelete(id: number | string): Action {
  const url = `/${API_VERSION_TWO}/business_trip_cities/${id}/`
  const method = 'DELETE'

  return {
    type: 'BUSINESS_TRIP_CITY_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, {}, method)!
        .then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        })
    }),
  }
}
