import { useState } from 'react'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { useDispatch, useSelector } from 'utils/hooks'
import CloneOrgDetail from 'containers/public/Onboarding/components/CloneOrgDetail'
import {
  onboardingAnnualLeaveEntitlementsLoad,
  updateCloneLeaveSettings,
} from 'redux/leavesOnboarding/annualLeaveEntitlements/actionCreators'
import { onboardingCountingDaysLoad } from 'redux/leavesOnboarding/countingDays/actionCreators'

import AnnualLeaveEntitlement from './AnnualLeaveEntitlement'
import AnnualLeaveSettings from './AnnualLeaveSettings'

const CountingDays = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'annual_leave_onboarding' })

  const dispatch = useDispatch()

  const fetching = useSelector((s) => s.onboardingAnnualLeaveEntitlements.fetching)

  const [addNew, setAddNew] = useState(false)

  const handleCloneLeaveSettings = (): void => {
    dispatch(updateCloneLeaveSettings('onboarding_annual_leave_policies')).then(() => {
      dispatch(onboardingAnnualLeaveEntitlementsLoad())
      dispatch(onboardingCountingDaysLoad())
    })
    setAddNew(false)
  }

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Flex justifyBetween>
        <Typography
          variant="heading"
          text={I18n.t('annual_leave_policies')}
        />
        <CloneOrgDetail
          fetching={fetching}
          handleClick={handleCloneLeaveSettings}
        />
      </Flex>
      <Spacer height="32px" />

      <AnnualLeaveEntitlement
        addNew={addNew}
        setAddNew={setAddNew}
      />
      <Spacer height="32px" />

      <AnnualLeaveSettings />
      <Spacer height="20px" />
    </StepTemplate>
  )
}

export default CountingDays
