import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  netsuite_subsidiaries: [],
  mapped_subsidiaries: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING:
    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING:
    case actions.NETSUITE_SUBSIDIARY_DELETE_PENDING:
    case actions.NETSUITE_SUBSIDIARY_UPDATE_PENDING:
    case actions.NETSUITE_MAPPED_SUBSIDIARIES_PENDING:
    case actions.NETSUITE_SUBSIDIARY_CREATE_PENDING:
    case actions.NETSUIT_SUBSIDARIES_LOAD_PENDING:
      // case actions.EXPORT_NETSUITE_JOUNALS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    // case actions.NETSUIT_LOGIN_LOAD_FULFILLED:
    // case actions.NETSUIT_LOGIN_TEST_LOAD_FULFILLED:
    // case actions.NETSUIT_ACCOUNTS_LOAD_FULFILLED:
    // case actions.NETSUIT_LOGIN_FULFILLED:
    // case actions.NETSUIT_TRACKING_LOAD_FULFILLED:
    // case actions.NETSUIT_SUBSIDARIES_SYNC_FULFILLED:
    // case actions.NETSUIT_ACCOUNTS_SYNC_FULFILLED:
    // case actions.NETSUIT_TRACKING_ASYNC_FULFILLED:
    // if (payload.data.message) Toastr.success(payload.data.message);
    // return {
    //   ...state,
    //   ...payload.data.data,
    //   fetching: false,
    // };

    // case actions.NETSUIT_TRACKING_CENTERS_UPDATE_FULFILLED:
    //   Toastr.success(payload.data.message);
    //   return {
    //     ...state,
    //     ...payload.data.data,
    //     fetching: false,
    //   };

    // case actions.NETSUIT_PAYROLL_ELEMENTS_LOAD_FULFILLED:
    // case actions.NETSUIT_PAYROLL_ELEMENTS_UPDATE_FULFILLED:
    // case actions.MAPPED_SUBSIDIARIES_FULFILLED:
    case actions.NETSUIT_SUBSIDARIES_LOAD_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        netsuite_subsidiaries: payload.data.data,
        fetching: false,
      }
    }
    case actions.NETSUITE_MAPPED_SUBSIDIARIES_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        mapped_subsidiaries: payload.data.data.subsidiaries,
        fetching: false,
      }
    }

    case actions.NETSUITE_SUBSIDIARY_CREATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.NETSUITE_SUBSIDIARY_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.NETSUITE_SUBSIDIARY_UPDATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED:
    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED:
    case actions.NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED:
    case actions.NETSUITE_SUBSIDIARY_UPDATE_REJECTED:
    case actions.NETSUITE_SUBSIDIARY_DELETE_REJECTED:
    case actions.NETSUITE_SUBSIDIARY_CREATE_REJECTED:
    case actions.NETSUITE_MAPPED_SUBSIDIARIES_REJECTED:
    case actions.NETSUIT_SUBSIDARIES_LOAD_REJECTED: {
      // case actions.NETSUIT_TRACKING_CENTERS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    }
    default:
      return state
  }
}
