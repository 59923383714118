import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { CommercialRegistrationType } from 'containers/public/Onboarding/OrganizationSetup/steps/CRInformationBoard/CRInfromationAddEdit/CRInfromationAddEdit'
import { Action } from 'types/redux'
import * as actions from './actions'

export function newCommercialCreate(body = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/commercial_registrations`

  return {
    type: actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_POST,
    payload: handleAPI(url, {}, 'POST', { commercial_registration: body }),
  }
}

export function commercialUpdate(body = {} as CommercialRegistrationType): Action {
  const id = body?.id
  const url = `/${API_VERSION_TWO}/onboarding/payroll/commercial_registrations/${id}`

  return {
    type: actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT,
    payload: handleAPI(url, {}, 'PUT', { commercial_registration: body }),
  }
}

export function commercialDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/commercial_registrations/${id}`

  return {
    type: actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function commercialShow(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/commercial_registrations/${id}`

  return {
    type: actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function commercialLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/commercial_registrations`

  return {
    type: actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
