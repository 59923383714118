import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function assetsRequestLoad() {
  const url = `/${API_VERSION_TWO}/asset_requests`
  return {
    type: actions.ASSET_REQUESTS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function assetsRequestCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/asset_requests`
  return {
    type: actions.ASSET_REQUESTS_CREATE,
    payload: handleAPI(url, {}, 'POST', { asset_request: data }),
  }
}

export function assetsRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/asset_requests/${id}`
  return {
    type: actions.ASSET_REQUESTS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { asset_request: data }),
  }
}

export function employeeAssetClearRequestsNewLoad(employee_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/asset_clear_requests/new`
  return {
    type: actions.EMPLOYEE_ASSET_CLEAR_REQUESTS_NEW_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function assetClearRequestsCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/asset_clear_requests`
  return {
    type: actions.ASSET_CLEAR_REQUESTS_CREATE,
    payload: handleAPI(url, {}, 'POST', { asset_clear_request: data }),
  }
}

export function assetClearRequestsUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/asset_clear_requests/${id}`
  return {
    type: actions.ASSET_CLEAR_REQUESTS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { asset_clear_request: data }),
  }
}

export function disabledAssetField(status) {
  return {
    type: actions.DISABLED_ASSET_FIELD,
    payload: { status },
  }
}
