import i18n from 'translations/i18n'
import { CompactButton, Flex, Typography } from '@jisr-hr/ds-beta'
import { ChatHeaderProps } from '../types'
import JIcon from '../icons/JIcon'

const ChatHeader = ({ onClose }: ChatHeaderProps) => (
  <Flex
    itemsCenter
    justifyBetween
    className="p-3 rounded-t-lg h-16 bg-base-colors-white-1000 border-b border-solid border-t-0 border-l-0 border-r-0 border-[var(--color-base-colors-grey-400)]"
  >
    <Flex
      flexRow
      itemsCenter
      className="gap-2"
    >
      <Flex
        itemsCenter
        justifyCenter
        className="h-8 w-8 rounded-full bg-[var(--color-base-colors-blue-600)]"
      >
        <JIcon />
      </Flex>
      <Typography
        text={i18n.t('label.jisrai')}
        variant="subheading"
        className="text-black"
      />
    </Flex>
    <CompactButton
      onClick={onClose}
      icon="x-close"
      variant="ghost"
    />
  </Flex>
)

export default ChatHeader
