import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ children, style, className, testId }) => (
  <div
    data-testid={testId}
    className={`jisr-cards__card ${className}`}
    style={style}
  >
    {children}
  </div>
)

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  style: PropTypes.shape(),
  testId: PropTypes.string,
  className: PropTypes.string,
}

Card.defaultProps = {
  style: {},
  className: '',
}

export default Card
