export const SUBSCRIPTION_CANCELLATIONS_POST = 'SUBSCRIPTION_CANCELLATIONS_POST'
export const SUBSCRIPTION_CANCELLATIONS_POST_PENDING = 'SUBSCRIPTION_CANCELLATIONS_POST_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_POST_FULFILLED = 'SUBSCRIPTION_CANCELLATIONS_POST_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_POST_REJECTED = 'SUBSCRIPTION_CANCELLATIONS_POST_REJECTED'

export const SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST =
  'SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST'
export const SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_PENDING =
  'SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_FULFILLED =
  'SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_REJECTED =
  'SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_REJECTED'

export const CANCELATION_CONFIRMATION_POST = 'CANCELATION_CONFIRMATION_POST'
export const CANCELATION_CONFIRMATION_POST_PENDING = 'CANCELATION_CONFIRMATION_POST_PENDING'
export const CANCELATION_CONFIRMATION_POST_FULFILLED = 'CANCELATION_CONFIRMATION_POST_FULFILLED'
export const CANCELATION_CONFIRMATION_POST_REJECTED = 'CANCELATION_CONFIRMATION_POST_REJECTED'

export const SUBSCRIPTION_CANCELLATIONS_REVERT_POST = 'SUBSCRIPTION_CANCELLATIONS_REVERT_POST'
export const SUBSCRIPTION_CANCELLATIONS_REVERT_POST_PENDING =
  'SUBSCRIPTION_CANCELLATIONS_REVERT_POST_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_REVERT_POST_FULFILLED =
  'SUBSCRIPTION_CANCELLATIONS_REVERT_POST_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_REVERT_POST_REJECTED =
  'SUBSCRIPTION_CANCELLATIONS_REVERT_POST_REJECTED'

export const SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD = 'SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD'
export const SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_PENDING =
  'SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_FULFILLED =
  'SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_REJECTED =
  'SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_REJECTED'

export const CANCELATION_ANSWERS_POST = 'CANCELATION_ANSWERS_POST'
export const CANCELATION_ANSWERS_POST_PENDING = 'CANCELATION_ANSWERS_POST_PENDING'
export const CANCELATION_ANSWERS_POST_FULFILLED = 'CANCELATION_ANSWERS_POST_FULFILLED'
export const CANCELATION_ANSWERS_POST_REJECTED = 'CANCELATION_ANSWERS_POST_REJECTED'

export const SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD =
  'SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD'
export const SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_PENDING =
  'SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_FULFILLED =
  'SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_REJECTED =
  'SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_REJECTED'

export const SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST =
  'SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST'
export const SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_PENDING =
  'SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_PENDING'
export const SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_FULFILLED =
  'SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_FULFILLED'
export const SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_REJECTED =
  'SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_REJECTED'

export const ACCEPT_TERMS_POST = 'ACCEPT_TERMS_POST'
export const ACCEPT_TERMS_POST_PENDING = 'ACCEPT_TERMS_POST_PENDING'
export const ACCEPT_TERMS_POST_FULFILLED = 'ACCEPT_TERMS_POST_FULFILLED'
export const ACCEPT_TERMS_POST_REJECTED = 'ACCEPT_TERMS_POST_REJECTED'

export const CANCELLATIONS_QUESTIONS_RESET = 'CANCELLATIONS_QUESTIONS_RESET'
