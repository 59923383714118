import { Toastr } from 'components/global/Toastr'
import { PaginationTypes } from 'types/sharedResponse'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { handlePayrunClasses } from 'components/global/helperReducer'
import * as actions from './actions'
import { PayrunType } from '../payrun/reducer'

type EmployeePaymentMethods = {
  Cash: number
  Bank: number
  Cheque: number
  'International Transfer': number
}

type PayrunConfirmType = PayrunType & {
  payrun_approval_cycle: {
    employee_id: number
    followup_needed: boolean
    review_request_id: number
  }
  total_paid_amount: number
  employees_count_by_payment_type: EmployeePaymentMethods
  last_month_stats: {
    total_paid_amount: number
    employees_count: number
    employees_count_by_payment_type: EmployeePaymentMethods
  }
}

export type AdditionDeductionType = {
  type: string
  value: number
  attachment_name?: string | null
  description?: string | null
  employee_id?: number
  formatted_value?: string
  is_manual?: true
  name?: string
  payroll_transaction_type_id?: string | number
  remarks?: {
    body: string
  }
  remarks_type?: string
  transaction_ids?: number[]
}

export type AdditionsDeductionInfoType = {
  header: string[] | null
  main_header: { label: string; col_span: number }[] | null
  records: AdditionDeductionType[][]
  total: string[] | null
  tags: ('new_joiner' | 'moved_employee')[]
  employees_ids: number[] | null
  pagination: PaginationTypes | Record<string, never> | null
  currency_type: number | null
  status_code: number | null
  cellClasses: string[]
  inactive_headers: number[] | null
}

export type GosiFileType = {
  gosi_number: string
  id: number
  name: string
}

export type GosiIssueType = {
  code: string | null
  comments: string
  document_number: string
  employee_id: number | null
  has_other_payment_types: boolean
  has_unmatched_amounts: boolean
  in_gosi_file: boolean
  in_jisr: boolean
  name: string
  task_exists: boolean
  gosi_number: string
  avatar: string
}

export type InitialStateType = {
  fetching: boolean
  gosi_fetching: boolean
  issue_fetching: boolean
  testingFetching: boolean
  gosiFiles: GosiFileType[]
  gosiIssues: GosiIssueType[] | null
  gosiIssueCount: Record<number, { total_employees: number; matched_employees: number }>
  gosiIssuePagination: PaginationTypes | null
  testPayroll: PayrunType | null
  additions_deduction_info: AdditionsDeductionInfoType | null
  payrun_all_info: AdditionsDeductionInfoType | null
  confirmedPayrun: PayrunConfirmType | null
  indexUpdating: number | null
  downloading: string | null
}
export const initialState: InitialStateType = {
  fetching: false,
  gosi_fetching: false,
  gosiIssuePagination: null,
  issue_fetching: false,
  gosiFiles: [],
  gosiIssues: null,
  gosiIssueCount: {},
  testingFetching: false,
  testPayroll: null,
  additions_deduction_info: null,
  payrun_all_info: null,
  confirmedPayrun: null,
  indexUpdating: null,
  downloading: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYRUN_CONFIRM_PENDING:
    case actions.PAYRUN_ALL_INFO_LOAD_PENDING:
    case actions.PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYRUN_GOSI_FILES_LOAD_PENDING: {
      return {
        ...state,
        gosi_fetching: true,
      }
    }
    case actions.PAYRUN_GOSI_FILES_LOAD_FULFILLED: {
      return {
        ...state,
        gosi_fetching: false,
        gosiFiles: action.payload.data.data,
      }
    }
    case actions.PAYRUN_GOSI_FILES_LOAD_REJECTED: {
      return {
        ...state,
        gosi_fetching: false,
      }
    }
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_PENDING:
      return {
        ...state,
        issue_fetching: false,
      }
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_PENDING: {
      return {
        ...state,
        issue_fetching: true,
      }
    }
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_FULFILLED:
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_FULFILLED: {
      const res = action.payload.data.data
      return {
        ...state,
        issue_fetching: false,
        gosiIssues:
          res.pagination.current_page === 1
            ? res.gosi_file_issues
            : [...(state.gosiIssues || []), ...res.gosi_file_issues],
        gosiIssuePagination: res.pagination,
        gosiIssueCount: res.count,
      }
    }
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_REJECTED:
    case actions.PAYRUN_GOSI_FILE_ISSUES_LOAD_REJECTED: {
      return {
        ...state,
        issue_fetching: false,
      }
    }

    case actions.PAYRUN_PRINT_REPORT_DOWNLOAD_PENDING:
      return {
        ...state,
        downloading: 'pdf',
      }

    case actions.PAYRUN_EXECL_REPORT_DOWNLOAD_PENDING:
      return {
        ...state,
        downloading: 'xls',
      }

    case actions.PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED: {
      const originData = action.payload.data.data as AdditionsDeductionInfoType
      // ? remove the first tow element with mutate the origin object
      originData?.total?.splice(0, 2)

      return {
        ...state,
        additions_deduction_info: {
          ...action.payload.data.data,
          main_header: originData?.main_header?.filter((item) => item.col_span) || [],
          header: originData?.header?.slice(1) || [],
          total: originData?.total || [],
          cellClasses: handlePayrunClasses(action.payload.data.data?.main_header),
        },
        fetching: false,
      }
    }

    case actions.PAYRUN_CONFIRM_FULFILLED: {
      return {
        ...state,
        confirmedPayrun: action?.payload?.data?.data?.payrun,
        fetching: false,
      }
    }

    case actions.PAYRUN_ALL_INFO_LOAD_FULFILLED: {
      return {
        ...state,
        payrun_all_info: {
          ...action.payload.data.data,
          cellClasses: handlePayrunClasses(action.payload.data.data.main_header),
        },
        fetching: false,
      }
    }
    case actions.PAYRUN_GOSI_SALARY_UPDATION_FULFILLED:
    case actions.PAYRUN_GOSI_EMPLOYEE_REGISTRATION_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYRUN_GOSI_SALARY_UPDATION_PENDING:
    case actions.PAYRUN_GOSI_EMPLOYEE_REGISTRATION_PENDING: {
      const { issueIndex } = action.payload

      return {
        ...state,
        fetching: true,
        indexUpdating: issueIndex,
      }
    }

    case actions.UPTO_DATE_GOSI_FILES_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.UPTO_DATE_GOSI_FILES_FULFILLED:
      return {
        ...state,
        fetching: false,
      }
    case actions.PAYROLL_TESTING_LOAD_PENDING:
    case actions.PAYROLL_TESTING_CREATE_PENDING:
    case actions.PAYROLL_TESTING_DELETE_PENDING:
      return {
        ...state,
        testingFetching: true,
      }
    case actions.PAYROLL_TESTING_LOAD_REJECTED:
    case actions.PAYROLL_TESTING_DELETE_REJECTED:
      return {
        ...state,
        testingFetching: false,
      }
    case actions.PAYROLL_TESTING_CREATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        testingFetching: false,
      }
    }

    case actions.PAYROLL_TESTING_LOAD_FULFILLED:
      return {
        ...state,
        testingFetching: false,
        testPayroll: action.payload.data.data?.testing_payroll,
      }

    case actions.PAYROLL_TESTING_CREATE_FULFILLED:
      return {
        ...state,
        testingFetching: false,
        testPayroll: action.payload.data.data,
      }

    case actions.PAYROLL_TESTING_DELETE_FULFILLED: {
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        testingFetching: false,
        testPayroll: null,
      }
    }

    case actions.PAYRUN_PRINT_REPORT_DOWNLOAD_FULFILLED:
    case actions.PAYRUN_PRINT_REPORT_DOWNLOAD_REJECTED:
    case actions.PAYRUN_EXECL_REPORT_DOWNLOAD_FULFILLED:
    case actions.PAYRUN_EXECL_REPORT_DOWNLOAD_REJECTED:
      return {
        ...state,
        downloading: null,
      }

    case actions.PAYRUN_CONFIRM_REJECTED:
    case actions.PAYRUN_ALL_INFO_LOAD_REJECTED:
    case actions.PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_REJECTED:
    case actions.PAYRUN_GOSI_SALARY_UPDATION_REJECTED:
    case actions.PAYRUN_GOSI_EMPLOYEE_REGISTRATION_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.RESET_PAYRUN: {
      return {
        ...initialState,
        testPayroll: state.testPayroll,
      }
    }

    default:
      return state
  }
}
