import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  first_fetching_locations: false,
  fetching: false,
  locations: [],
  regions: [],
  cities: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDINGـLOCATIONS_LOAD_PENDING:
    case actions.ONBOARDING_LOCATIONS_DELETE_PENDING:
    case actions.ONBOARDING_LOCATIONS_CREATE_PENDING:
    case actions.ONBOARDING_LOCATIONS_UPDATE_PENDING:
    case actions.ONBOARDING_CITIES_GET_ALL_LOAD_PENDING:
    case actions.ONBOARDING_REGIONS_GET_ALL_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDINGـLOCATIONS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        first_fetching_locations: true,
        locations: payload.data.data.locations ?? [],
      }

    case actions.ONBOARDING_CITIES_GET_ALL_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        cities: action?.payload?.data?.data ?? [],
      }

    case actions.ONBOARDING_REGIONS_GET_ALL_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        regions: action?.payload?.data?.data ?? [],
      }

    case actions.ONBOARDING_LOCATIONS_UPDATE_FULFILLED:
    case actions.ONBOARDING_LOCATIONS_CREATE_FULFILLED:
    case actions.ONBOARDING_LOCATIONS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDINGـLOCATIONS_LOAD_REJECTED:
    case actions.ONBOARDING_LOCATIONS_UPDATE_REJECTED:
    case actions.ONBOARDING_LOCATIONS_CREATE_REJECTED:
    case actions.ONBOARDING_LOCATIONS_DELETE_REJECTED:
    case actions.ONBOARDING_CITIES_GET_ALL_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
