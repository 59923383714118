import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'

export type Employee = {
  id: number
  name: string
  code: string
  avatar_thumb: string
  name_i18n: string
  full_name_i18n: string
  job_title: string
  is_exempted: boolean
}

export type BusinessTripCity = {
  id: number
  name: string
}

export type Configuration = {
  id: number
  travel_days: number
  accommodation: number
  food: number
  transportation: number
  business_trip_city: BusinessTripCity
  business_trip_city_id: number
}

export type BusinessTripPolicy = {
  id: number
  name: string
  is_active: boolean
  employees: Employee[]
  employee_ids: number[]
  calculation_basis: 'per_night' | 'per_day'
  calculation_basis_i18n: string
  configurations: Configuration[]
  number_of_employees: number
  is_all_employee_selected: boolean
}

type BusinessTripPolicyState = {
  business_trip_policies: BusinessTripPolicy[]
  business_trip_policy: BusinessTripPolicy
  editing: { New?: boolean; [key: number]: boolean }
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
  loading: boolean
}

const DEFAULT_STATE: BusinessTripPolicyState = {
  business_trip_policies: [],
  business_trip_policy: {
    id: 0,
    name: '',
    is_active: false,
    employees: [],
    employee_ids: [],
    calculation_basis: 'per_day',
    calculation_basis_i18n: '',
    configurations: [],
    number_of_employees: 0,
    is_all_employee_selected: false,
  },
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}

export default function businessTripPolicyReducer(
  state = DEFAULT_STATE,
  action: Action,
): BusinessTripPolicyState {
  const { payload } = action
  switch (action.type) {
    case 'BUSINESS_TRIP_POLICIES_LOAD_PENDING':
    case 'BUSINESS_TRIP_POLICY_LOAD_PENDING':
    case 'BUSINESS_TRIP_POLICY_CREATE_PENDING':
    case 'BUSINESS_TRIP_POLICY_UPDATE_PENDING':
    case 'BUSINESS_TRIP_POLICY_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.business_trip_policies.length > 0),
      }

    case 'BUSINESS_TRIP_POLICIES_LOAD_REJECTED':
    case 'BUSINESS_TRIP_POLICY_LOAD_REJECTED':
    case 'BUSINESS_TRIP_POLICY_CREATE_REJECTED':
    case 'BUSINESS_TRIP_POLICY_UPDATE_REJECTED':
    case 'BUSINESS_TRIP_POLICY_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, errMsg: handleResponseErr(payload), fetching: false, loading: false }

    case 'BUSINESS_TRIP_POLICIES_LOAD_FULFILLED':
      return {
        ...state,
        business_trip_policies: [...payload.data.data.business_trip_policies],
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
    case 'BUSINESS_TRIP_POLICY_LOAD_FULFILLED':
    case 'BUSINESS_TRIP_POLICY_RESET_FULFILLED':
      return {
        ...state,
        business_trip_policy: payload.data.data.business_trip_policy,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }

    case 'BUSINESS_TRIP_POLICY_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      const business_trip_policies = [...state.business_trip_policies]
      const newRecord = payload.data.data.business_trip_policy
      return {
        ...state,
        fetching: false,
        business_trip_policies: [...business_trip_policies, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }

    case 'BUSINESS_TRIP_POLICY_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      const updatedRecord = payload.data.data.business_trip_policy

      return {
        ...state,
        fetching: false,
        business_trip_policies: updateOneListOfRecords(state.business_trip_policies, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }

    case 'BUSINESS_TRIP_POLICY_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        business_trip_policies: removeOneListOfRecords(state.business_trip_policies, payload.id),
        errMsg: null,
        fetching: false,
      }
    default:
      return state
  }
}
