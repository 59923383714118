export const NETSUIT_COST_CENTER_LIST_LOAD = 'NETSUIT_COST_CENTER_LIST_LOAD'
export const NETSUIT_COST_CENTER_LIST_LOAD_FULFILLED = 'NETSUIT_COST_CENTER_LIST_LOAD_FULFILLED'
export const NETSUIT_COST_CENTER_LIST_LOAD_REJECTED = 'NETSUIT_COST_CENTER_LIST_LOAD_REJECTED'
export const NETSUIT_COST_CENTER_LIST_LOAD_PENDING = 'NETSUIT_COST_CENTER_LIST_LOAD_PENDING'

export const NETSUIT_LOCATIONS_LOAD = 'NETSUIT_LOCATIONS_LOAD'
export const NETSUIT_LOCATIONS_LOAD_FULFILLED = 'NETSUIT_LOCATIONS_LOAD_FULFILLED'
export const NETSUIT_LOCATIONS_LOAD_REJECTED = 'NETSUIT_LOCATIONS_LOAD_REJECTED'
export const NETSUIT_LOCATIONS_LOAD_PENDING = 'NETSUIT_LOCATIONS_LOAD_PENDING'

export const NETSUIT_DEPARTMENT_LOAD = 'NETSUIT_DEPARTMENT_LOAD'
export const NETSUIT_DEPARTMENT_LOAD_FULFILLED = 'NETSUIT_DEPARTMENT_LOAD_FULFILLED'
export const NETSUIT_DEPARTMENT_LOAD_REJECTED = 'NETSUIT_DEPARTMENT_LOAD_REJECTED'
export const NETSUIT_DEPARTMENT_LOAD_PENDING = 'NETSUIT_DEPARTMENT_LOAD_PENDING'

export const NETSUIT_CLASSES_LOAD = 'NETSUIT_CLASSES_LOAD'
export const NETSUIT_CLASSES_LOAD_FULFILLED = 'NETSUIT_CLASSES_LOAD_FULFILLED'
export const NETSUIT_CLASSES_LOAD_REJECTED = 'NETSUIT_CLASSES_LOAD_REJECTED'
export const NETSUIT_CLASSES_LOAD_PENDING = 'NETSUIT_CLASSES_LOAD_PENDING'

export const NETSUIT_COST_CENTER_CREATE = 'NETSUIT_COST_CENTER_CREATE'
export const NETSUIT_COST_CENTER_CREATE_FULFILLED = 'NETSUIT_COST_CENTER_CREATE_FULFILLED'
export const NETSUIT_COST_CENTER_CREATE_REJECTED = 'NETSUIT_COST_CENTER_CREATE_REJECTED'
export const NETSUIT_COST_CENTER_CREATE_PENDING = 'NETSUIT_COST_CENTER_CREATE_PENDING'

export const NETSUIT_COST_CENTER_UPDATE = 'NETSUIT_COST_CENTER_UPDATE'
export const NETSUIT_COST_CENTER_UPDATE_FULFILLED = 'NETSUIT_COST_CENTER_UPDATE_FULFILLED'
export const NETSUIT_COST_CENTER_UPDATE_REJECTED = 'NETSUIT_COST_CENTER_UPDATE_REJECTED'
export const NETSUIT_COST_CENTER_UPDATE_PENDING = 'NETSUIT_COST_CENTER_UPDATE_PENDING'

export const NETSUIT_COST_CENTER_DELETE = 'NETSUIT_COST_CENTER_DELETE'
export const NETSUIT_COST_CENTER_DELETE_FULFILLED = 'NETSUIT_COST_CENTER_DELETE_FULFILLED'
export const NETSUIT_COST_CENTER_DELETE_REJECTED = 'NETSUIT_COST_CENTER_DELETE_REJECTED'
export const NETSUIT_COST_CENTER_DELETE_PENDING = 'NETSUIT_COST_CENTER_DELETE_PENDING'
