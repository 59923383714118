import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Dictionary, groupBy, uniq } from 'lodash'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

export type EmployeeExpiringDocumentType = {
  employee_id: number | string
  job_title: string
  code: string
  name_i18n: string
  avatar: string
  expiry_date: string
  expiry_date_hijri: string
  document_status: string
  expiry_status: string
  document_type: string
  document_type_i18n: string
  document_details: {
    document_number: number
    file: string
    description: string
    name: string
  }
}

type StateType = {
  employee_expiring_documents: EmployeeExpiringDocumentType[]
  organization_expiring_documents: EmployeeExpiringDocumentType[]
  expiring_document_counts: {
    employee_expiring_documents: number
    organization_expiring_documents: number
  }
  pagination: PaginationTypes | Record<string, unknown>
  fetching: boolean
  employee_expiring_documents_grouped_by?: Dictionary<EmployeeExpiringDocumentType[]>
  errMsg?: string
}

const initialState: StateType = {
  employee_expiring_documents: [],
  organization_expiring_documents: [],
  expiring_document_counts: {
    employee_expiring_documents: 0,
    organization_expiring_documents: 0,
  },
  pagination: {},
  fetching: false,
}

type RequestsState = typeof initialState

export default function reducer(state = initialState, action: Action): RequestsState {
  const {
    pagination = {},
    expiring_document_counts = {},
    expiring_documents = [],
  } = action?.payload?.data?.data ?? {}
  switch (action.type) {
    case actions.EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_FULFILLED: {
      const newData = uniq([
        ...(pagination?.previous_page ? state.employee_expiring_documents : []),
        ...expiring_documents,
      ])
      return {
        ...state,
        employee_expiring_documents: newData,
        employee_expiring_documents_grouped_by: groupBy(newData, 'document_type'),
        expiring_document_counts,
        pagination,
        fetching: false,
      }
    }
    case actions.COMPANY_EXPIRING_DOCUMENTS_LOAD_FULFILLED:
      return {
        ...state,
        organization_expiring_documents: [
          ...(pagination?.previous_page ? state.organization_expiring_documents : []),
          ...expiring_documents,
        ],
        expiring_document_counts,
        pagination,
        fetching: false,
      }
    case actions.EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_PENDING:
    case actions.COMPANY_EXPIRING_DOCUMENTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_REJECTED:
    case actions.COMPANY_EXPIRING_DOCUMENTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
