import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export const getPublicTemplates = <T extends Record<string, unknown>>(params: T): Action => {
  const url = `/${API_VERSION_TWO}/public_templates`
  return {
    type: actions.PUBLIC_TEMPLATES,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const getPublicTemplatesWithDifferentRootAPI = <T extends Record<string, unknown>>(
  params: T,
  rootAPI: string,
): Action => {
  const url = `/public_templates`
  return {
    type: actions.PUBLIC_TEMPLATES,
    payload: handleAPI(url, params, 'GET', null, false, undefined, undefined, rootAPI),
  }
}
