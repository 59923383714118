import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type BanksTypes = {
  filterKey: string
  filters: string[]
}

const Banks = (props: BanksTypes): JSX.Element => {
  const { banks } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<('all' | number)[]>([])
  const optionList = returnBadgeList(banks)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        banks: [],
      })
    } else {
      handleFilter('banks', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.banks)) {
      setSelectedIds(filter.banks)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.banks])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      valueKey="id"
      labelKey="name_i18n"
      {...props}
      title={i18n.t('label.banks')}
      componentType="badges"
    />
  )
}

export default Banks
