import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'
import { TNamePageGroups, TourCompleteDataType } from './type'

type TPropAction = {
  page_group: TNamePageGroups
  onboarding?: boolean
}

const handleUrl = (): string => {
  const isNewOnboarding = window.location.hash.includes('#/new_onboarding')
  return isNewOnboarding ? '/' : '/onboarding/'
}

export const listOnboardingStepsFetchGroups = (
  page_group_name: TNamePageGroups[] | TNamePageGroups,
): Action => {
  const url = `/${API_VERSION_TWO}/steps/fetch_by_groups`

  return {
    type: actions.ONBOARDING_FETCH_BY_GROUPS_LIST,
    payload: handleAPI(url, { page_group_name }, 'GET'),
  }
}

export const listOnboardingInitialSteps = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/steps?page_group=initial_onboarding`

  return {
    type: actions.ONBOARDING_STEPS_LIST,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const onboardingCompleteStep = ({
  id,
  page_group,
  onboarding = false,
}: TPropAction & { id: number }): Action => {
  const url = `/${API_VERSION_TWO}${onboarding ? '/onboarding/' : '/'}steps/${id}/mark_as_completed`

  return {
    type: actions.ONBOARDING_COMPLETE_STEP_POST,
    payload: handleAPI(url, { page_group }, 'PUT'),
  }
}

export const onboardingCompletedTour = (data: TourCompleteDataType): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins/completed_tour`

  return {
    type: actions.ONBOARDING_ADMINS_COMPLETED_TOUR_PUT,
    payload: handleAPI(url, {}, 'PUT', { completed_tour: data }),
  }
}

export const onboardingMarkStepVisited = (id: number): Action => {
  const url = `/${API_VERSION_TWO}${handleUrl()}steps/${id}/mark_as_visited`

  return {
    type: actions.ONBOARDING_MARK_AS_VISITED_PUT,
    payload: handleAPI(url, {}, 'PUT'),
  }
}
