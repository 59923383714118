import { FC, useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { useDispatch, useSelector } from 'utils/hooks'
import { createBrowserHistory } from 'history'

// Others
import { Toast } from '@jisr-hr/ds-beta'
import PrintLetterTemplate from 'components/global/templates/PrintLetterTemplate'
import UndoAlerts from 'components/global/ds/Undo'
import LoaderComponent from 'components/LoaderComponent'
import { AppConfirmation } from 'components/Finance/useConfirmation'

import { getOrganizationCurrencyTypeLoad } from './redux/actions/organizationProfileActions'

import RootRouter from './routes/rootRouter'
import Helmet from './components/Helmet'
import { mixPanelAnalytics, humanize, blockPages } from './utils/mixpanel'
// import useCSPDetected from './hooks/useCSPDetected'

const history = createBrowserHistory()
history.listen(({ location }) => {
  const pageNames = location.hash.split('/')
  let pageName = ''
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < pageNames.length; i++) {
    if (!Number(pageNames[i])) {
      pageName += `${humanize(pageNames[i])} ${i !== pageNames.length - 1 ? '- ' : ''}`
    }
  }
  if (!blockPages.includes(pageNames[1])) {
    const mixPanelData = {
      event_name: 'page_click_all_pages_web',
      properties: {
        page_name: pageName === ' ' || !pageName ? 'Home' : pageName,
        page_url: location.hash,
      },
    }
    mixPanelAnalytics(mixPanelData)
  }
})

const App: FC = () => {
  const dispatch = useDispatch()
  // useCSPDetected()

  // ? --------> selectors <--------
  const fetching = useSelector((s) => s.auth.fetching_change_language)
  const employee = useSelector((s) => s.auth.employee)

  // ? --------> lifecycle <--------
  useEffect(() => {
    if (employee?.id) {
      dispatch(getOrganizationCurrencyTypeLoad())
    }
  }, [employee?.id])

  return (
    <>
      <Helmet />
      {fetching ? (
        <LoaderComponent />
      ) : (
        <Router>
          <RootRouter />
        </Router>
      )}
      <PrintLetterTemplate />
      {/* ? temp solution for confirmation */}
      <Toast />
      <AppConfirmation />
      <UndoAlerts />
    </>
  )
}

export default App
