import { useState, useEffect } from 'react'
import { Grid } from 'components/global/atoms'
import { ReactComponent as Location } from '@jisr-hr/design-system/dist/assets/icons/Mics/Location.svg'
import { SelectField, TextInputField, Form } from 'components/final-form'
import I18n from 'translations/i18n'
import { Typography, Button, Spacer } from '@jisr-hr/ds'
import { required } from 'components/global/form/FormValidations'
import Flex from 'components/global/atoms/Flex'
import { LocationsType } from 'redux/organizationOnborading/locations/reducer'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  countriesLoad,
  getCountryCities,
  listRegions,
} from 'redux/organizationOnborading/countries/actionCreators'
import { FormApi } from 'final-form'
import {
  locationCreate,
  locationLoad,
  locationUpdate,
} from 'redux/organizationOnborading/locations/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import styles from './WorkLocation.module.css'
import StepTemplate from '../../../../components/StepTemplate'

type WorkLocationType = {
  checkAddOrEdit: boolean
  locationData: LocationsType
  onClickChangeUi: () => void
  defaultLocation: number | null
}

type ArgType = FormApi<
  Record<string, any>,
  | {
      country_id: number | null
      area_id?: undefined
      address?: undefined
      city_id?: undefined
    }
  | {
      area_id: any
      address: string
      city_id: number
      country_id: number
    }
>

const WorkLocation = ({
  checkAddOrEdit,
  locationData,
  onClickChangeUi,
  defaultLocation,
}: WorkLocationType): JSX.Element => {
  const dispatch = useDispatch()
  const { countries, regions, cities } = useSelector(
    ({ onboradingCountries }) => onboradingCountries,
  )

  const [country, setCountry] = useState<WorkLocationType['defaultLocation']>(null)
  const [newLocation, setNewLocation] = useState(false)

  const area = (regions &&
    regions.find((co) => co.name_i18n === (locationData.area && locationData.area.name_i18n))) || {
    id: null,
  }
  function onSubmit(values: Record<string, any>, arg: ArgType): void {
    const regArea =
      (regions && regions.find((co) => co.id === values.area_id)) || values.area_id || ''
    const data = {
      location: {
        address_en: values.address,
        address_ar: values.address,
        city_id: values?.city_id,
        area_attributes: {
          name: regArea.name_en || regArea,
          name_ar: regArea.name_ar || regArea,
          country_id: values.country_id,
        },
      },
    }

    if (checkAddOrEdit) {
      if (newLocation) {
        dispatch(locationCreate(data)).then(() => {
          dispatch(locationLoad()).then(() => {
            setNewLocation(false)
            setTimeout(
              () =>
                arg.reset({
                  address: '',
                  area_id: values.area_id,
                  city_id: values.city_id,
                  country_id: values.country_id,
                }),
              50,
            )
          })
          dispatch(organizationInfo())
        })
      } else {
        dispatch(locationCreate(data)).then(() => {
          onClickChangeUi()
          dispatch(organizationInfo())
        })
      }
    } else {
      dispatch(locationUpdate(locationData.id, data)).then(onClickChangeUi)
    }
  }

  useEffect(() => {
    dispatch(locationLoad())
    dispatch(countriesLoad())
  }, [])

  useEffect(() => {
    if (checkAddOrEdit && defaultLocation) {
      setCountry(defaultLocation)
      dispatch(listRegions(defaultLocation))
      dispatch(getCountryCities(defaultLocation))
    }
  }, [checkAddOrEdit])

  useEffect(() => {
    const countryId = locationData?.area?.country?.id
    if (locationData.area) {
      dispatch(listRegions(countryId))
      if (countryId) dispatch(getCountryCities(countryId))
    }
  }, [locationData])

  return (
    <StepTemplate hideFooter>
      <div className={styles.contentHeader}>
        <Location />
        <Typography
          className={styles.text}
          variant="title-1"
          text={I18n.t('work_location')}
        />
      </div>
      <Spacer height="16px" />
      <Form
        onSubmit={onSubmit}
        initialValues={
          checkAddOrEdit
            ? { country_id: defaultLocation || null }
            : {
                area_id: regions.length !== 0 ? area.id : locationData.area.name_i18n,
                address: locationData.address_i18n,
                city_id: locationData?.city?.id,
                country_id: country || (locationData.area.country && locationData.area.country.id),
              }
        }
        render={({ pristine, submitting, handleSubmit, values, form }): JSX.Element => (
          <>
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              name="address"
              validate={required}
              placeholder={I18n.t('location_name')}
              style={{ maxWidth: '512px' }}
              autoFocus={checkAddOrEdit}
            />
            <Spacer height="5px" />
            <Grid
              container
              spacing={1}
              style={{ maxWidth: '512px' }}
            >
              <Grid
                item
                sm={6}
              >
                <SelectField
                  // @ts-expect-error Need to migrate SelectField.js to TypeScript
                  name="country_id"
                  label={I18n.t('country')}
                  options={countries}
                  labelKey="name_i18n"
                  valueKey="id"
                  onChange={(val: number): void => {
                    setCountry(val)
                    dispatch(listRegions(val))
                    dispatch(getCountryCities(val))
                    form.change('area_id', null)
                    form.change('city_id', null)
                  }}
                />
              </Grid>
              {regions && regions.length !== 0 ? (
                <Grid
                  item
                  sm={6}
                >
                  <SelectField
                    // @ts-expect-error Need to migrate SelectField.js to TypeScript
                    name="area_id"
                    label={I18n.t('area')}
                    options={regions}
                    labelKey="name_i18n"
                    valueKey="id"
                    disabled={!values.country_id}
                    validate={required}
                    style={{ width: '200px' }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  sm={6}
                >
                  {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                  <TextInputField
                    validate={required}
                    style={{ width: '200px' }}
                    name="area_id"
                    label={I18n.t('area')}
                    labelKey="name_i18n"
                    valueKey="id"
                    disabled={!values.country_id}
                  />
                </Grid>
              )}
            </Grid>
            <Spacer height="10px" />
            <SelectField
              // @ts-expect-error Need to migrate SelectField.js to TypeScript
              style={{ width: '200px' }}
              options={cities}
              name="city_id"
              label={I18n.t('city')}
              labelKey="name_i18n"
              valueKey="id"
              disabled={!values.area_id}
              validate={required}
            />
            <Spacer height="24px" />

            {checkAddOrEdit && (
              //  @ts-expect-error Need to migrate Flex.js to TypeScript
              <Flex className={styles.button}>
                <Button
                  label={I18n.t('add_new_location')}
                  size="medium"
                  variant="ghost"
                  // className={styles.button}
                  // style={{ padding: 0, height: 'auto' }}
                  onClick={(): void => {
                    setNewLocation(true)
                    setTimeout(() => handleSubmit(), 10)
                  }}
                  disabled={submitting || pristine}
                />
              </Flex>
            )}

            <Spacer height="20px" />
            {/* @ts-expect-error Need to migrate Flex.js to TypeScript */}
            <Flex itemsCenter>
              <Button
                variant="outlined"
                color="neutral"
                onClick={onClickChangeUi}
                size="medium"
                label={I18n.t('back')}
                type="button"
              />
              <Spacer width="8px" />
              <Button
                label={I18n.t('continue')}
                onClick={(): void => {
                  handleSubmit()
                }}
                disabled={submitting || pristine}
                size="medium"
              />
            </Flex>
          </>
        )}
      />
    </StepTemplate>
  )
}

export default WorkLocation
