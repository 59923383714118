import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type InitialStateType = {
  fetching: boolean
  expense_claim_report: {
    title: string
    ecr_items_title: string
    ecr_items_header: string[]
    employee_info: {
      name: string
      value: string | number | null
    }[]
    request_info: {
      name: string
      value: string | number | null
    }[]
    request_items: (string | number | { file_name: string; url: string }[])[][]
  } | null
}

export const initialState: InitialStateType = {
  fetching: false,
  expense_claim_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.EXPENSE_CLAIM_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EXPENSE_CLAIM_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        expense_claim_report: action.payload.data.data.expense_claim_report,
        fetching: false,
      }
    }

    case actions.EXPENSE_CLAIM_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
