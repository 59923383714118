import { useSelector } from 'utils/hooks'

// need to use this in any component that needs to check if the user has permissions to manage health insurance or annual flight tickets
// TODO - need to use this hook in all places, this needs test so i delay it
const useBenefitsManagementPermissions = (): {
  isHealthInsuranceEnabled: boolean
  isAnnualFlightTicketsEnabled: boolean
  isRequestForQuotationsEnabled: boolean
  isFlightBookingEnabled: boolean
} => {
  const { employee } = useSelector(({ auth }) => auth)
  const { permission_scopes } = employee!

  const isHealthInsuranceEnabled = !!(
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.health_insurances_enabled && permission_scopes?.manage_health_insurance
  )

  const isAnnualFlightTicketsEnabled = !!(
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.flight_tickets_enabled && permission_scopes?.manage_annual_flight_tickets
  )

  const isRequestForQuotationsEnabled = !!(
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.request_for_quotation_enabled && permission_scopes?.manage_health_insurance
  )

  const isFlightBookingEnabled = !!(
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.flight_ticket_bookings_enabled && permission_scopes?.view_flight_bookings
  )

  return {
    isHealthInsuranceEnabled,
    isAnnualFlightTicketsEnabled,
    isRequestForQuotationsEnabled,
    isFlightBookingEnabled,
  }
}

export default useBenefitsManagementPermissions
