export const EMPLOYEE_DEVICES_MANAGEMENT_LOAD = 'EMPLOYEE_DEVICES_MANAGEMENT_LOAD'
export const EMPLOYEE_DEVICES_MANAGEMENT_LOAD_PENDING = 'EMPLOYEE_DEVICES_MANAGEMENT_LOAD_PENDING'
export const EMPLOYEE_DEVICES_MANAGEMENT_LOAD_FULFILLED =
  'EMPLOYEE_DEVICES_MANAGEMENT_LOAD_FULFILLED'
export const EMPLOYEE_DEVICES_MANAGEMENT_LOAD_REJECTED = 'EMPLOYEE_DEVICES_MANAGEMENT_LOAD_REJECTED'

export const UPDATE_EMPLOYEE_DEVICES_MANAGEMENT = 'UPDATE_EMPLOYEE_DEVICES_MANAGEMENT'
export const UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_PENDING =
  'UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_PENDING'
export const UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_FULFILLED =
  'UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_FULFILLED'
export const UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_REJECTED =
  'UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_REJECTED'
