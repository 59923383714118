import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function createLeaveRequest(employee_id, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_requests`
  const method = 'POST'
  return {
    type: 'CREATE_LEAVE_REQUEST',
    payload: handleAPI(url, {}, method, { leave_request: params }),
  }
}

export function leaveRequestsDetail(employee_id, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_requests/new`
  return {
    type: 'LEAVE_REQUESTS_DETAIL',
    payload: handleAPI(url, { leave_request: params }, 'GET'),
  }
}

export function leaveRequestsReset() {
  return {
    type: 'EMPLOYEE_LEAVE_RESET',
  }
}

export function updateLeaveRequest(employee_id, params = {}, req_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_requests/${req_id}`
  const method = 'PUT'
  return {
    type: 'UPDATE_LEAVE_REQUEST',
    payload: handleAPI(url, {}, method, { leave_request: params }),
  }
}
