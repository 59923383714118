import cls from 'classnames'
import { Typography, Spacer, Radio } from '@jisr-hr/ds'
import TipComponents from '../TipComponents'
import styles from './styles.module.css'

export type TRadioWithContentOption = {
  label: string
  value: string | number | boolean
  testId?: string
  description?: string
  tip?: string
  withChildren?: boolean
  name?: string
}

type TRadioWithContent = {
  options: TRadioWithContentOption[]
  value: string | number | boolean
  handleChange: (text: string) => void
  children: JSX.Element | boolean
  disabled: boolean
  outContent: boolean
  name?: string
}

function RadioWithContent({
  options,
  value,
  handleChange,
  children,
  disabled,
  outContent,
}: TRadioWithContent): JSX.Element[] {
  return options.map((opt, index) => {
    const checked = opt.value?.toString() === value?.toString()
    return (
      <>
        <div
          key={index}
          className={cls(styles.radioWithContent, {
            [styles.active]: checked,
            [styles.disabled]: disabled,
          })}
        >
          <Radio
            label={opt.label}
            value={opt.value?.toString()}
            disabled={disabled}
            active={checked}
            data-testid={opt.testId}
            onChange={(v): void => handleChange(v.target.value)}
          />
          {opt.description && (
            <Typography
              variant="interface/default/m"
              style={{ color: 'var(--color-base-colors-grey-500)' }}
              className={styles.description}
              text={opt.description}
            />
          )}
          {!outContent && opt.withChildren && children}
          {checked && opt.tip && (
            <>
              <Spacer height="16px" />
              <TipComponents tip={opt.tip} />
            </>
          )}
        </div>
        {outContent && opt.withChildren && checked && children}
      </>
    )
  })
}

export default RadioWithContent
