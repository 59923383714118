export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_PENDING =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_PENDING'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_FULFILLED =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_FULFILLED'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_REJECTED =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_REJECTED'

export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_PENDING =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_PENDING'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED'
export const ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_REJECTED =
  'ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_REJECTED'

export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_PENDING =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_PENDING'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_FULFILLED =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_FULFILLED'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_REJECTED =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD_REJECTED'

export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_PENDING =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_PENDING'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED'
export const ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_REJECTED =
  'ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT_REJECTED'
