import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex, Button, Spacer } from '@jisr-hr/ds'
import { Textarea } from '@jisr-hr/ds-beta'

const AddComment = ({
  onSubmit,
  onCancel,
  fieldLabel,
  fieldPlaceholder,
  confirmLabel,
  disabled = false,
}) => {
  const [comment, setComment] = React.useState()

  const handleSubmit = () => {
    onSubmit(comment)
  }

  return (
    <div className="pt-[16px]">
      <Textarea
        autoFocus
        testId="add-comment"
        label={fieldLabel ?? I18n.t('add_comment')}
        placeholder={fieldPlaceholder ?? `${I18n.t('type_your_comment')}...`}
        onChange={(value) => setComment(value)}
        maxLength={300}
      />
      <Spacer height="30px" />
      <Flex style={{ gap: '8px' }}>
        <Button
          label={confirmLabel ?? I18n.t('submit')}
          disabled={!comment?.replace(/ /g, '') || disabled}
          size="small"
          variant="filled"
          onClick={handleSubmit}
          testId="reject-comment-btn"
        />
        <Button
          label={I18n.t('cancel')}
          size="small"
          variant="outlined"
          color="neutral"
          onClick={onCancel}
          testId="cancel-comment-btn"
        />
      </Flex>
    </div>
  )
}

AddComment.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default AddComment
