import { useState } from 'react'
import { Spacer, Flex, Button, Typography } from '@jisr-hr/ds'
import { Popper } from '@jisr-hr/design-system'
import I18n from 'translations/i18n'
import {
  DownloadBox,
  UploadingBox,
} from 'containers/public/Onboarding/components/DownloadUploadBoxes'
import { ReactComponent as CloseIcon } from 'assets/figma-icons/cancel.svg'
import { useDispatch } from 'utils/hooks'
import PropTypes from 'prop-types'
import { exportOnboardingOrganizationData } from 'redux/organizationOnborading/bulkUpload/actionCreators'
import sidebarStyles from 'containers/public/Onboarding/components/Sidebar/styles.module.css'

type TBulkUpdate = {
  type: string
  action: () => void
}

function BulkUpdate(props: TBulkUpdate): JSX.Element {
  const { type, action } = props
  const [popperOpen, setPopperOpen] = useState(false)
  const dispatch = useDispatch()
  const handelDownload = (): void => {
    dispatch(exportOnboardingOrganizationData({ type }))
  }
  return (
    <Popper
      open={popperOpen}
      trigger={
        <Button
          label={I18n.t('bulk_update')}
          onClick={(): void => setPopperOpen(true)}
          size="small"
        />
      }
      style={{ margin: 5, minWidth: 500 }}
      maxHeight={341}
    >
      <Flex
        justifyBetween
        itemsCenter
      >
        <Typography
          variant="title-1"
          text={I18n.t('update_bulk_employees_salaries')}
        />

        <div
          className={sidebarStyles.closeIconContainer}
          onClick={(): void => setPopperOpen(false)}
        >
          <CloseIcon className={sidebarStyles.closeIcon} />
        </div>
      </Flex>
      <Spacer height={32} />
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        {[
          {
            component: (
              <DownloadBox
                handelDownload={handelDownload}
                title={I18n.t('download_salaries_details_sheet')}
              />
            ),
          },
          {
            component: (
              <UploadingBox
                type={type}
                action={action}
              />
            ),
          },
        ].map(({ component }, i) => (
          <Flex
            key={i}
            itemsCenter
            style={{ gap: 24 }}
          >
            <Flex
              justifyCenter
              itemsCenter
              style={{
                border: '1px solid var(--color-natural-black-light-new)',
                borderRadius: '50%',
                width: 24,
                height: 24,
              }}
            >
              {i + 1}
            </Flex>
            {component}
          </Flex>
        ))}
      </Flex>
      <Spacer height={32} />
    </Popper>
  )
}

BulkUpdate.propTypes = {
  type: PropTypes.string,
  action: PropTypes.func,
}

export default BulkUpdate
