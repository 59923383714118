import { FC } from 'react'
import { Containers, Flex, Button, Typography, Spacer } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'
import useOnlinePayment from 'containers/authorised/setting/FinanceSetting/OnlinePayment/useOnlinePayment'
import styles from './JisrPayWallet.module.css'

type AddMoneyPopupProps = {
  balance: string
  iban: string
}

const AddMoneyPopup: FC<AddMoneyPopupProps> = (props) => {
  const handleArticle = (): string => {
    if (isRtl)
      return 'https://jisr.zendesk.com/hc/ar-sa/articles/24627390486417-%D8%AE%D8%B7%D9%88%D8%A7%D8%AA-%D8%AA%D8%B9%D8%A8%D8%A6%D8%A9-%D8%A7%D9%84%D8%B1%D8%B5%D9%8A%D8%AF-%D9%81%D9%8A-%D8%AE%D8%AF%D9%85%D8%A9-%D8%A7%D9%84%D8%AF%D9%81%D8%B9-%D8%B9%D8%A8%D8%B1-%D8%AC%D8%B3%D8%B1'
    return 'https://jisr.zendesk.com/hc/en-sa/articles/24627390486417-Top-up-the-balance-of-Jisr-payment-service'
  }

  const { isCopied, handleCopy } = useOnlinePayment()
  const { balance, iban } = props
  return (
    <Containers
      color="white"
      borderRadius="lg"
      className={styles.addMoneyContainer}
    >
      <Flex
        flexCol
        className={styles.balanceContainer}
      >
        <Typography
          text={i18n.t('labale.wallet_balance_')}
          variant="body-new/medium"
        />
        <Typography
          className={styles.amount}
          text={balance}
          variant="title-1"
        />
      </Flex>
      <Spacer height={32} />
      <Flex justifyBetween>
        <Flex flexCol>
          <Typography
            text={i18n.t('label.your_iban')}
            variant="title-1"
          />
          <Typography
            text={iban}
            variant="body-new/semibold"
          />
        </Flex>
        <Button
          variant="ghost"
          color="neutral"
          leadingIcon={isCopied ? 'check' : 'copy-03'}
          onClick={(): Promise<void> => handleCopy(iban || '')}
        >
          <Typography
            text={i18n.t('label.copy_iban')}
            variant="interface/medium/s"
          />
        </Button>
      </Flex>
      <Spacer height={24} />
      <Flex flexCol>
        <Spacer height={8} />
        <Button
          variant="tinted"
          color="neutral"
          label={i18n.t('label.how_to_top_up')}
          size="small"
          onClick={(): void => {
            window.open(handleArticle(), '_blank')
          }}
        />
      </Flex>
    </Containers>
  )
}

export default AddMoneyPopup
