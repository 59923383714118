import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { BankAccountType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import * as actions from './actions'
import { JisrPayStateUpdateType } from './actionCreators'

export type CommercialRegistrationOwnerType = {
  id: number
  name: string
  otp_status: 'pending' | 'sent' | 'verified'
  commercial_registration_info_id: number
  identity_id: string
  birth_date: string
  updated_at: string
  avatar: string
}

export type CommercialRegistrationWithWalletType = {
  id: number
  name_ar: string
  registration_number: string
  registration_id: number
  main_id: number | null
  mol_registration: {
    id: number
    name: string
    gosi_number: string
    mol_prefix: string
    mol_number: string
    mol_registration_number: string
  }
  commercial_registration_owners: CommercialRegistrationOwnerType[]
  status: 'pending' | 'verified'
  active_payment_method: 'mudad' | 'offline'
  bank_accounts: BankAccountType[]
  entity_number: string | null
}

export type CRVerificationBodyType = {
  commercial_registration: {
    registration_number: string
    mol_registration_attributes: {
      mol_prefix: string
      mol_number: string
      gosi_number: string
    }
  }
}

type WalletType = {
  id: number
  balance: string
  cr_number: string
  created_by: string
  description: string | null
  name: string
  org_identifier: number
  status: string
  updated_by: string
  updated_at: string
  viban: string
}

type PaginationType = {
  current_page: number
  per_page: number
  previous_page: number | null
  next_page: number | null
  total_pages: number
  total_entries: number
}

type InitialStateType = {
  // ? Data
  wallets: WalletType[] | null
  walletsPagination: PaginationType | null
  commercial_registrations: CommercialRegistrationWithWalletType[]
  linked_commercial_registrations: CommercialRegistrationWithWalletType[]
  mudad_commercial_registrations: CommercialRegistrationWithWalletType[]
  unlinked_commercial_registrations: CommercialRegistrationWithWalletType[]
  // ? Locals
  selected_cr: CommercialRegistrationWithWalletType | null
  selected_owner: CommercialRegistrationOwnerType | null
  current_form_step: 'owners_list' | 'CRs_list' | 'registration_form' // ? this will handle the modal view and we have to assign the value based on data like owner list
  errorMsg: string | null
  // download_bank_file_type: 'wps' | 'direct_transfer' | 'payrun' | 'salary_card' | null
  // ? Fetching
  commercial_registrations_fetching: boolean
  verify_commercial_registration_loading: boolean
  send_owner_otp_loading: boolean
  verify_otp_loading: boolean
  wallet_fetching: boolean
  // ? Modals
  open_register_modal: boolean
  open_otp_modal: boolean
  open_wallet_details_modal: boolean
}

const initialState: InitialStateType = {
  // ? Data
  commercial_registrations: [],
  linked_commercial_registrations: [],
  mudad_commercial_registrations: [],
  unlinked_commercial_registrations: [],
  wallets: null,
  walletsPagination: null,
  // ? Locals
  selected_cr: null,
  selected_owner: null,
  current_form_step: 'registration_form',
  errorMsg: null,
  // ? Fetching
  wallet_fetching: false,
  commercial_registrations_fetching: false,
  verify_commercial_registration_loading: false,
  send_owner_otp_loading: false,
  verify_otp_loading: false,
  // ? Modals
  open_register_modal: false,
  open_otp_modal: false,
  open_wallet_details_modal: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    // ? set new data to reducer
    case actions.SELECT_CURRENT_ACTIVE_CR: {
      const data = action.payload as JisrPayStateUpdateType
      return {
        ...state,
        ...data,
      }
    }

    // ? Load Commercial Registrations
    case actions.JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_PENDING: {
      return {
        ...state,
        commercial_registrations_fetching: true,
      }
    }
    case actions.JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        commercial_registrations_fetching: false,
      }
    }
    case actions.JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED: {
      const commercialRegistrations = action.payload.data.data
        .commercial_registrations as CommercialRegistrationWithWalletType[]
      const groupedCRPayment: Record<string, CommercialRegistrationWithWalletType[]> =
        commercialRegistrations.reduce(
          (acc, curr) => {
            const key = curr.active_payment_method === 'mudad' ? 'mudad' : curr.status
            return {
              ...acc,
              [key]: [...acc[key], curr],
            }
          },
          {
            mudad: [],
            pending: [],
            verified: [],
          },
        )

      return {
        ...state,
        commercial_registrations: commercialRegistrations,
        linked_commercial_registrations: groupedCRPayment?.verified || [],
        mudad_commercial_registrations: groupedCRPayment?.mudad || [],
        unlinked_commercial_registrations:
          groupedCRPayment?.pending.filter((it) => !it.main_id) || [],
        commercial_registrations_fetching: false,
      }
    }
    // ? verify Commercial Registration
    case actions.VERIFY_COMMERCIAL_REGISTRATIONS_PENDING: {
      return {
        ...state,
        verify_commercial_registration_loading: true,
      }
    }
    case actions.VERIFY_COMMERCIAL_REGISTRATIONS_REJECTED: {
      // Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        verify_commercial_registration_loading: false,
        errorMsg: handleResponseErr(action.payload),
      }
    }
    case actions.VERIFY_COMMERCIAL_REGISTRATIONS_FULFILLED: {
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        verify_commercial_registration_loading: false,
      }
    }

    // ? send OTP & verify Owners
    case actions.SEND_OWNER_OTP_PENDING: {
      return {
        ...state,
        send_owner_otp_loading: true,
      }
    }
    case actions.VERIFY_OWNER_OTP_PENDING: {
      return {
        ...state,
        verify_otp_loading: true,
      }
    }
    case actions.SEND_OWNER_OTP_REJECTED:
    case actions.VERIFY_OWNER_OTP_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        send_owner_otp_loading: false,
        verify_otp_loading: false,
      }
    }

    case actions.VERIFY_OWNER_OTP_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        verify_otp_loading: false,
      }
    }
    case actions.SEND_OWNER_OTP_FULFILLED: {
      Toastr.success(action.payload.data.message)
      const updated_cr = action.payload.data.data as CommercialRegistrationWithWalletType
      const updated_selected_owner = updated_cr.commercial_registration_owners.find(
        (o) => o.id === state.selected_owner?.id,
      )
      return {
        ...state,
        selected_cr: updated_cr,
        selected_owner: updated_selected_owner || state.selected_owner,
        send_owner_otp_loading: false,
      }
    }
    // ? Wallet
    case actions.WALLET_LOAD_PENDING: {
      return {
        ...state,
        wallet_fetching: true,
      }
    }
    case actions.WALLET_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        wallet_fetching: false,
      }
    }
    case actions.WALLET_LOAD_FULFILLED: {
      const { payload } = action
      return {
        ...state,
        wallets: payload.data.data?.data.wallets,
        walletsPagination: payload.data.data?.pagination,
        wallet_fetching: false,
      }
    }
    default:
      return state
  }
}
