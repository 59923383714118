import * as actions from './actions'

const initialState = {
  downloading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PAYMENT_PROCESSING_DOWNLOAD_PENDING: {
      return {
        ...state,
        downloading: true,
      }
    }

    case actions.PAYMENT_PROCESSING_DOWNLOAD_FULFILLED:
    case actions.PAYMENT_PROCESSING_DOWNLOAD_REJECTED:
      return {
        ...state,
        downloading: false,
      }

    default:
      return state
  }
}
