import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Plus = ({ color, rotate = 0, style, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    style={{ ...style, transform: `rotate(${rotate}deg)` }}
    {...props}
  >
    <path
      fill={getColor(color)}
      fillRule="evenodd"
      d="M6,4 L10,4 L10,6 L6,6 L6,10 L4,10 L4,6 L0,6 L0,4 L4,4 L4,0 L6,0 L6,4 Z"
    />
  </svg>
)

Plus.propTypes = {
  color: PropTypes.string,
  style: PropTypes.shape(),
  rotate: PropTypes.number,
}

Plus.defaultProps = {
  color: 'dark-grey',
}

export default Plus
