import { useContext } from 'react'
import { Button, Flex, Modal } from '@jisr-hr/ds'
import PropTypes from 'prop-types'
import { Form, TextInputField } from 'components/final-form'
import { useDispatch, useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { isEmpty } from 'lodash'

import {
  onBoardingBankAccountsCreate,
  onBoardingBankAccountsDelete,
  onBoardingBankAccountsUpdate,
} from 'redux/payrollOnboarding/BankAccounts/actionsCreators'
import { commercialLoad } from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { required } from 'components/global/form/FormValidations'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { OnboardingContext } from '../../../../context'

type TPayrollBankForm = {
  isOpen: boolean
  onClose: () => void
}

const PayrollBankForm = ({ onClose, isOpen }: TPayrollBankForm): JSX.Element => {
  const { editBank } = useContext(OnboardingContext)
  const { loading } = useSelector((state) => state.onboardingBankAccounts)
  const bankId = !isEmpty(editBank.bank_accounts) ? editBank?.bank_accounts[0].id : 0

  const dispatch = useDispatch()
  const onSubmit = (values: Record<string, unknown>): void => {
    if (editBank?.bank_accounts?.length > 0) {
      dispatch(
        onBoardingBankAccountsUpdate(
          {
            ...values,
            commercial_registration_id: editBank.id,
          },
          Number(bankId),
        ),
      ).then(() => {
        dispatch(commercialLoad({ with_bank_accounts: true }))
        onClose()
      })
    } else {
      dispatch(
        onBoardingBankAccountsCreate({
          ...values,
          commercial_registration_id: editBank.id,
        }),
      ).then(() => {
        dispatch(commercialLoad({ with_bank_accounts: true }))
        onClose()
        dispatch(organizationInfo())
      })
    }
  }
  const handelDelete = (): void => {
    dispatch(onBoardingBankAccountsDelete(Number(bankId))).then(() => {
      dispatch(commercialLoad({ with_bank_accounts: true }))
      onClose()
      dispatch(organizationInfo())
    })
  }
  return (
    <Modal
      open={isOpen}
      size="small"
      onClose={onClose}
      header={{ title: I18n.t(bankId ? 'edit_iban' : 'add_iban'), withClose: true }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          iban: editBank?.bank_accounts?.length > 0 ? editBank?.bank_accounts[0].iban : '',
        }}
        render={({ handleSubmit, pristine, invalid }): JSX.Element => (
          <div>
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('enter_iban')}
              name="iban"
              autoFocus={!bankId}
              placeholder={I18n.t('enter_iban')}
              validate={required}
              className="!mb-[24px]"
            />
            {/* <Spacer height={108} /> */}
            <Flex
              justifyBetween={!!bankId}
              justifyEnd={!bankId}
              className="py-[24px]"
            >
              {!!bankId && (
                <Button
                  label={I18n.t('delete')}
                  onClick={handelDelete}
                  disabled={loading}
                  variant="outlined"
                  color="neutral"
                  size="medium"
                />
              )}
              <Button
                label={I18n.t(bankId ? 'edit' : 'add')}
                disabled={loading || pristine || invalid}
                onClick={handleSubmit}
                size="medium"
              />
            </Flex>
          </div>
        )}
      />
    </Modal>
  )
}

PayrollBankForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PayrollBankForm
