export const ZOHO_BOOKS_COST_CENTER_LIST_LOAD = 'ZOHO_BOOKS_COST_CENTER_LIST_LOAD'
export const ZOHO_BOOKS_COST_CENTER_LIST_LOAD_FULFILLED =
  'ZOHO_BOOKS_COST_CENTER_LIST_LOAD_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_LIST_LOAD_REJECTED = 'ZOHO_BOOKS_COST_CENTER_LIST_LOAD_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_LIST_LOAD_PENDING = 'ZOHO_BOOKS_COST_CENTER_LIST_LOAD_PENDING'

export const ZOHO_BOOKS_COST_CENTER_CREATE = 'ZOHO_BOOKS_COST_CENTER_CREATE'
export const ZOHO_BOOKS_COST_CENTER_CREATE_FULFILLED = 'ZOHO_BOOKS_COST_CENTER_CREATE_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_CREATE_REJECTED = 'ZOHO_BOOKS_COST_CENTER_CREATE_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_CREATE_PENDING = 'ZOHO_BOOKS_COST_CENTER_CREATE_PENDING'

export const ZOHO_BOOKS_COST_CENTER_UPDATE = 'ZOHO_BOOKS_COST_CENTER_UPDATE'
export const ZOHO_BOOKS_COST_CENTER_UPDATE_FULFILLED = 'ZOHO_BOOKS_COST_CENTER_UPDATE_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_UPDATE_REJECTED = 'ZOHO_BOOKS_COST_CENTER_UPDATE_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_UPDATE_PENDING = 'ZOHO_BOOKS_COST_CENTER_UPDATE_PENDING'

export const ZOHO_BOOKS_COST_CENTER_DELETE = 'ZOHO_BOOKS_COST_CENTER_DELETE'
export const ZOHO_BOOKS_COST_CENTER_DELETE_FULFILLED = 'ZOHO_BOOKS_COST_CENTER_DELETE_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_DELETE_REJECTED = 'ZOHO_BOOKS_COST_CENTER_DELETE_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_DELETE_PENDING = 'ZOHO_BOOKS_COST_CENTER_DELETE_PENDING'

export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS = 'ZOHO_BOOKS_COST_CENTER_DIMENSIONS'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_FULFILLED =
  'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_REJECTED =
  'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_PENDING = 'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_PENDING'

export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES = 'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_FULFILLED =
  'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_REJECTED =
  'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_PENDING =
  'ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_PENDING'

export const ZOHO_BOOKS_COST_CENTER_ACCOUNTS = 'ZOHO_BOOKS_COST_CENTER_ACCOUNTS'
export const ZOHO_BOOKS_COST_CENTER_ACCOUNTS_FULFILLED = 'ZOHO_BOOKS_COST_CENTER_ACCOUNTS_FULFILLED'
export const ZOHO_BOOKS_COST_CENTER_ACCOUNTS_REJECTED = 'ZOHO_BOOKS_COST_CENTER_ACCOUNTS_REJECTED'
export const ZOHO_BOOKS_COST_CENTER_ACCOUNTS_PENDING = 'ZOHO_BOOKS_COST_CENTER_ACCOUNTS_PENDING'

export const ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD = 'ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD'
export const ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_FULFILLED =
  'ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_FULFILLED'
export const ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_REJECTED = 'ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_REJECTED'
export const ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_PENDING = 'ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_PENDING'
