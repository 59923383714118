export const ATS_PUBLIC_TEMPLATES = 'ATS_PUBLIC_TEMPLATES'
export const ATS_PUBLIC_TEMPLATES_PENDING = 'ATS_PUBLIC_TEMPLATES_PENDING'
export const ATS_PUBLIC_TEMPLATES_FULFILLED = 'ATS_PUBLIC_TEMPLATES_FULFILLED'
export const ATS_PUBLIC_TEMPLATES_REJECTED = 'ATS_PUBLIC_TEMPLATES_REJECTED'

export type CompanyAboutInfo = {
  info_detail: string
  is_default: boolean
}

export type ATSPublicTemplateItem = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
}
