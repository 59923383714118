/** **************
 *  expenseClaimRequestActions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function expenseClaimRequestCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/expense_claim_requests/`
  const method = 'POST'
  return {
    type: 'EXPENSE_CLAIM_REQUEST_CREATE',
    payload: handleAPI(url, {}, method, { expense_claim_request: data }),
  }
}

export function expenseClaimRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/expense_claim_requests/${id}`
  const method = 'PUT'
  return {
    type: 'EXPENSE_CLAIM_REQUEST_UPDATE',
    payload: handleAPI(url, {}, method, { expense_claim_request: data }),
  }
}
