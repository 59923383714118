import { FC } from 'react'
import { Typography, Flex, Badge, Button, Containers, FeaturedIcon } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { jisrPayStateUpdate } from 'redux/payroll/JisrPay/actionCreators'
import { ReactComponent as OnlinePayment } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/OnlinePayment.svg'
import { usePaymentMethod } from '../../components/usePaymentMethods'

const onLineList = [
  {
    title: i18n.t('label.hassle_free_compiance_'),
  },
  {
    title: i18n.t('label.its_free_zero_subscription_fee'),
  },
  {
    title: i18n.t('label.save_money_transaction_fee_is_lower'),
  },
  {
    title: i18n.t('label.processing_and_transferring_payrolls_in_few_clicks'),
  },
]

const OnlinePaymentMethodCard: FC = () => {
  const dispatch = useDispatch()
  const paymentCtx = usePaymentMethod()
  const listCR = useSelector((s) => s.jisrPay.commercial_registrations)
  const selected_cr = useSelector((s) => s.jisrPay.selected_cr)
  const minCR = listCR?.find((cr) => cr.id === selected_cr?.main_id)

  return (
    <Containers
      color="gray"
      borderRadius="md"
      height="auto"
      width="100%"
      styles={{
        padding: 20,
        marginBottom: 24,
      }}
    >
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        <Flex justifyBetween>
          <OnlinePayment />
          <Button
            label={i18n.t('label.activate_jisr_payment')}
            variant="filled"
            color="primary"
            onClick={(): void => {
              paymentCtx.handleSetupMethodOpen(null)
              dispatch(
                jisrPayStateUpdate({
                  selected_cr: minCR,
                  open_register_modal: true,
                  current_form_step: 'CRs_list',
                }),
              )
            }}
          />
        </Flex>
        <Flex flexCol>
          <Flex style={{ gap: 8 }}>
            <Typography
              text={i18n.t('label.online_payment')}
              variant="title-1"
            />
            <Badge
              color="green"
              label={i18n.t('label.recommended')}
              size="small"
              leadingIcon="star-06"
            />
          </Flex>
          <Typography
            text={i18n.t('communication.jisr_now_integrated_with_wps_system')}
            variant="body-new/regular"
            textColor="color/fg/lighter"
          />
        </Flex>
        <Flex
          flexWrap
          style={{ backgroundColor: 'white', padding: 24, gap: 12, borderRadius: 8 }}
        >
          {onLineList.map((item, i) => {
            return (
              <Flex
                style={{ gap: 8 }}
                itemsStart={i === onLineList.length - 1}
                itemsCenter={i !== onLineList.length - 1}
                key={i}
              >
                <Flex
                  itemsBaseline
                  style={{ gap: 8, marginInlineEnd: 40 }}
                >
                  <FeaturedIcon
                    iconName="check"
                    variant="tinted"
                    rounded
                    size="xs"
                  />
                  <Typography
                    text={item.title}
                    variant="caption"
                  />
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Containers>
  )
}

export default OnlinePaymentMethodCard
