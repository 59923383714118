import { Field } from 'react-final-form'
import { Switch } from '@jisr-hr/ds'
import { FieldValidator } from 'final-form'
import { InputHTMLAttributes } from 'react'

type SwitchFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  active?: boolean
  'data-testid'?: string
  label?: string
  description?: string
  name: string
  position?: string
  size?: string
  testId?: string
  validate?: FieldValidator<any>
  onChange?: (e: boolean) => void
}

const SwitchField = ({
  name,
  label,
  validate,
  onChange,
  active,
  testId,
  ...rest
}: SwitchFieldProps): JSX.Element => (
  <Field
    name={name}
    validate={validate}
  >
    {({ input }): JSX.Element => (
      <Switch
        {...rest}
        {...input}
        label={label}
        active={input.checked || active || input.value}
        onChange={(_, isChecked: boolean): void => {
          if (onChange) onChange(isChecked)
          input.onChange(isChecked)
        }}
        testId={testId}
      />
    )}
  </Field>
)

export default SwitchField
