// Import translations
import OffCyclePayroll from './modules/ar/OffCyclePayroll'
import Analysis from './modules/ar/analysis'
import Assets from './modules/ar/assets'
import atsRecruitment from './modules/ar/ats_recruitment'
import Auth from './modules/ar/auth'
import Common from './modules/ar/common'
import Constants from './modules/ar/constants'
import Elevatus from './modules/ar/elevatus'
import Employee from './modules/ar/employee'
import Errors from './modules/ar/errors'
import gl_mapping from './modules/ar/gl_mapping'
import LeaveManagement from './modules/ar/leaveManagement'
import muqeem from './modules/ar/muqeem'
import onboarding from './modules/ar/onboarding'
import Payroll from './modules/ar/payroll'
import performance from './modules/ar/performance'
import Report from './modules/ar/report'
import Request from './modules/ar/request'
import Scheduler from './modules/ar/scheduler'
import Setting from './modules/ar/setting'
import TimeAttendance from './modules/ar/timeAttendance'
import Validations from './modules/ar/validations'
import Webhooks from './modules/ar/webhooks'
import Workable from './modules/ar/workable'
import Automations from './modules/ar/automations'
import BenefitsManagement from './modules/ar/BenefitsManagement'
import Tawuniya from './modules/ar/tawuniya'
import Marketplace from './modules/ar/marketplace'
import SSO from './modules/ar/sso'
import dittoAR from './ditto-ar.json'

const AR = {
  ...Auth,
  ...Analysis,
  ...Constants,
  ...Common,
  ...onboarding,
  ...Request,
  ...Employee,
  ...LeaveManagement,
  ...TimeAttendance,
  ...Scheduler,
  ...Report,
  ...Setting,
  ...Payroll,
  ...gl_mapping,
  ...muqeem,
  ...OffCyclePayroll,
  ...performance,
  ...Assets,
  ...Validations,
  ...Errors,
  ...Elevatus,
  ...Workable,
  ...Webhooks,
  ...atsRecruitment,
  ...Automations,
  ...BenefitsManagement,
  ...Tawuniya,
  ...Marketplace,
  ...SSO,
  ...dittoAR,
}

export default AR
