import { Form, TextInputField } from 'components/final-form'
import { Table } from '@jisr-hr/design-system'
import { ReactComponent as CheckIcon } from 'assets/images/icon-check-002.svg'
import { ReactComponent as Deletecon } from 'assets/images/icon-delete-003.svg'
import { difference } from 'lodash'
import { required, minValue, composeValidators } from 'components/global/form/FormValidations'
import {
  salariesPackagesUpdate,
  salariesPackagesLoad,
} from 'redux/payrollOnboarding/SalarySetup/actionsCreators'
import { SubmitValuesType, TPackageSalaryObject } from 'redux/payrollOnboarding/SalarySetup/type'
import { Dispatch, useMemo } from 'react'
import { useDispatch } from 'utils/hooks'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'

type FormTableProps = {
  editRecord: TPackageSalaryObject[]
  onClose: Dispatch<React.SetStateAction<Record<string, string | number>>>
}

const FormTable = (props: FormTableProps): JSX.Element => {
  const { editRecord } = props
  const dispatch = useDispatch()
  const employeeInfo = editRecord[0]
  const basicSalary = editRecord[1]
  const ajeer_fees = editRecord[2]
  const fullPackage = editRecord[editRecord.length - 1]
  const benefits = useMemo(() => editRecord.slice(3, editRecord.length - 1), [editRecord])

  const onSubmit = (values: SubmitValuesType): void => {
    const updatePackages = difference(values.benefits, benefits)
    const data: SubmitValuesType = {
      benefits: updatePackages,
      ajeer_fees: values.ajeer_fees,
      basic_salary: values.basic_salary,
    }
    if (+data.ajeer_fees! === +ajeer_fees?.ajeer_fees) {
      delete data.ajeer_fees
    }

    dispatch(salariesPackagesUpdate(data, { employee_id: employeeInfo.employee_id })).then(() => {
      dispatch(salariesPackagesLoad())
      props.onClose({})
    })
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Flex>
          <Flex flexCol>
            <Typography
              variant="interface/default/sm"
              text={employeeInfo.name_i18n}
            />
            <Typography
              style={{ color: 'var(--color-base-colors-grey-400)' }}
              variant="interface/default/sm"
              text={employeeInfo.code}
            />
          </Flex>
        </Flex>
      </Table.Cell>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          basic_salary: basicSalary.basic_salary,
          ajeer_fees: ajeer_fees.ajeer_fees,
          benefits,
        }}
      >
        {({ handleSubmit }): JSX.Element => (
          <>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="basic_salary"
                validate={composeValidators(required, minValue(1))}
              />
            </Table.Cell>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField name="ajeer_fees" />
            </Table.Cell>
            {benefits?.map((_, i) => (
              <Table.Cell key={i}>
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  key={i}
                  name={`benefits[${i}].company_contribution`}
                />
              </Table.Cell>
            ))}
            <Table.Cell>{fullPackage.full_package_salary}</Table.Cell>
            <Table.Cell>
              <CheckIcon
                onClick={handleSubmit}
                style={{ cursor: 'pointer' }}
              />
              <Spacer width="16px" />
              <Deletecon
                onClick={(): void => props.onClose({})}
                style={{ cursor: 'pointer' }}
              />
            </Table.Cell>
          </>
        )}
      </Form>
    </Table.Row>
  )
}

export default FormTable
