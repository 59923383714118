import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  unread: false,
}

type TInitState = typeof initialState

export default function reducer(state = initialState, action: Action): TInitState {
  const { type, payload } = action

  switch (type) {
    case actions.GET_IS_UNREAD_PENDING:
    case actions.MARK_ALL_AS_READ_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_IS_UNREAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.MARK_ALL_AS_READ_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.GET_IS_UNREAD_REJECTED:
    case actions.MARK_ALL_AS_READ_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
