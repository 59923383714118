import React from 'react'
import I18n from 'translations/i18n'
import PropTypes from 'prop-types'
import { Tooltip } from '@jisr-hr/ds-beta'
import { minuteToHumanReadable } from 'components/global/form/formNormalize'
import { Typography, Flex } from '@jisr-hr/ds'

import { ReactComponent as InfoIcon } from 'assets/images/icons/info-gray.svg'
import { ReactComponent as CalendarIcon } from './icons/calendar.svg'
import { ReactComponent as ClockTimerIcon } from './icons/clock-timer.svg'

import HourlyPopper from './HourlyPopper'

const TotalRequested = ({ details }) => {
  if (!details?.expected_hours) {
    return (
      <Typography
        text="--"
        variant="interface/strong/m"
        style={{ color: 'var(--color-base-colors-black-500)' }}
      />
    )
  }

  return (
    <Flex
      style={{ gap: 8 }}
      itemsCenter
    >
      {details?.requested_more_than_actual && (
        <Tooltip
          placement="top"
          description={I18n.t('the_requested_overtime_is_more_than')}
          trigger={<InfoIcon />}
        />
      )}
      <Typography
        text={details?.expected_hours}
        variant="interface/strong/m"
        style={{ color: 'var(--color-base-colors-black-500)' }}
      />
    </Flex>
  )
}

TotalRequested.propTypes = {
  details: PropTypes.shape(),
}

export const requestedList = (details, overtimeDays, reloadRequest) => [
  {
    label: I18n.t('total_requested'),
    key: 'total_requested',
    icon: <CalendarIcon />,
    value: minuteToHumanReadable(details?.total_requested_overtime) || '--',
    color: 'var(--color-natural-gray-darker-2)',
    subList: overtimeDays,
  },
  {
    label: I18n.t('hourly_rate'),
    key: 'hourly_rate',
    icon: <ClockTimerIcon />,
    value: (
      <HourlyPopper
        details={details}
        reloadRequest={reloadRequest}
      />
    ),
  },
  {
    label: I18n.t('cost'),
    key: 'cost',
    icon: <CalendarIcon />,
    value: `${details?.total_cost_of_hours || 0} ${details?.currency}`,
    color: 'var(--color-natural-gray-darker-2)',
  },
  {
    label: I18n.t('total_extra_worked_hrs'),
    key: 'total_work',
    icon: <CalendarIcon />,
    value: minuteToHumanReadable(details?.extra_hours) || '--',
    color: 'var(--color-natural-gray-darker-2)',
  },
]
