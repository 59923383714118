export const EASY_CLOCKING_LOGIN_LOAD = 'EASY_CLOCKING_LOGIN_LOAD'
export const EASY_CLOCKING_LOGIN_LOAD_FULFILLED = 'EASY_CLOCKING_LOGIN_LOAD_FULFILLED'
export const EASY_CLOCKING_LOGIN_LOAD_REJECTED = 'EASY_CLOCKING_LOGIN_LOAD_REJECTED'

export const EASY_CLOCKING_LOGIN_POST = 'EASY_CLOCKING_LOGIN_POST'
export const EASY_CLOCKING_LOGIN_POST_FULFILLED = 'EASY_CLOCKING_LOGIN_POST_FULFILLED'
export const EASY_CLOCKING_LOGIN_POST_REJECTED = 'EASY_CLOCKING_LOGIN_POST_REJECTED'

export const EASY_CLOCKING_LOGIN_POST_UPDATE = 'EASY_CLOCKING_LOGIN_POST_UPDATE'
export const EASY_CLOCKING_LOGIN_POST_UPDATE_FULFILLED = 'EASY_CLOCKING_LOGIN_POST_UPDATE_FULFILLED'
export const EASY_CLOCKING_LOGIN_POST_UPDATE_REJECTED = 'EASY_CLOCKING_LOGIN_POST_UPDATE_REJECTED'

export const EASY_CLOCKING_CHECK_CONNECTION = 'EASY_CLOCKING_CHECK_CONNECTION'
export const EASY_CLOCKING_CHECK_CONNECTION_PENDING = 'EASY_CLOCKING_CHECK_CONNECTION_PENDING'
export const EASY_CLOCKING_CHECK_CONNECTION_FULFILLED = 'EASY_CLOCKING_CHECK_CONNECTION_FULFILLED'
export const EASY_CLOCKING_CHECK_CONNECTION_REJECTED = 'EASY_CLOCKING_CHECK_CONNECTION_REJECTED'

export const EASY_CLOCKING_SYNC = 'EASY_CLOCKING_SYNC'
export const EASY_CLOCKING_SYNC_PENDING = 'EASY_CLOCKING_SYNC_PENDING'
export const EASY_CLOCKING_SYNC_FULFILLED = 'EASY_CLOCKING_SYNC_FULFILLED'
export const EASY_CLOCKING_SYNC_REJECTED = 'EASY_CLOCKING_SYNC_REJECTED'

export const EASY_CLOCKING_GET_DEVICES = 'EASY_CLOCKING_GET_DEVICES'
export const EASY_CLOCKING_GET_DEVICES_PENDING = 'EASY_CLOCKING_GET_DEVICES_PENDING'
export const EASY_CLOCKING_GET_DEVICES_FULFILLED = 'EASY_CLOCKING_GET_DEVICES_FULFILLED'
export const EASY_CLOCKING_GET_DEVICES_REJECTED = 'EASY_CLOCKING_GET_DEVICES_REJECTED'

export const EASY_CLOCKING_GET_DEVICE_DETAILS = 'EASY_CLOCKING_GET_DEVICE_DETAILS'
export const EASY_CLOCKING_GET_DEVICE_DETAILS_PENDING = 'EASY_CLOCKING_GET_DEVICE_DETAILS_PENDING'
export const EASY_CLOCKING_GET_DEVICE_DETAILS_FULFILLED =
  'EASY_CLOCKING_GET_DEVICE_DETAILS_FULFILLED'
export const EASY_CLOCKING_GET_DEVICE_DETAILS_REJECTED = 'EASY_CLOCKING_GET_DEVICE_DETAILS_REJECTED'

export const EASY_CLOCKING_ASSIGN_USERS = 'EASY_CLOCKING_ASSIGN_USERS'
export const EASY_CLOCKING_ASSIGN_USERS_PENDING = 'EASY_CLOCKING_ASSIGN_USERS_PENDING'
export const EASY_CLOCKING_ASSIGN_USERS_FULFILLED = 'EASY_CLOCKING_ASSIGN_USERS_FULFILLED'
export const EASY_CLOCKING_ASSIGN_USERS_REJECTED = 'EASY_CLOCKING_ASSIGN_USERS_REJECTED'

export const EASY_CLOCKING_UNASSIGN_USER = 'EASY_CLOCKING_UNASSIGN_USER'
export const EASY_CLOCKING_UNASSIGN_USER_PENDING = 'EASY_CLOCKING_UNASSIGN_USER_PENDING'
export const EASY_CLOCKING_UNASSIGN_USER_FULFILLED = 'EASY_CLOCKING_UNASSIGN_USER_FULFILLED'
export const EASY_CLOCKING_UNASSIGN_USER_REJECTED = 'EASY_CLOCKING_UNASSIGN_USER_REJECTED'

export const EASY_CLOCKING_GET_LOCATIONS = 'EASY_CLOCKING_GET_LOCATIONS'
export const EASY_CLOCKING_GET_LOCATIONS_PENDING = 'EASY_CLOCKING_GET_LOCATIONS_PENDING'
export const EASY_CLOCKING_GET_LOCATIONS_FULFILLED = 'EASY_CLOCKING_GET_LOCATIONS_FULFILLED'
export const EASY_CLOCKING_GET_LOCATIONS_REJECTED = 'EASY_CLOCKING_GET_LOCATIONS_REJECTED'

export const EASY_CLOCKING_GET_USERS = 'EASY_CLOCKING_GET_USERS'
export const EASY_CLOCKING_GET_USERS_PENDING = 'EASY_CLOCKING_GET_USERS_PENDING'
export const EASY_CLOCKING_GET_USERS_FULFILLED = 'EASY_CLOCKING_GET_USERS_FULFILLED'
export const EASY_CLOCKING_GET_USERS_REJECTED = 'EASY_CLOCKING_GET_USERS_REJECTED'

export const EASY_CLOCKING_UPDATE_DEVICE = 'EASY_CLOCKING_UPDATE_DEVICE'
export const EASY_CLOCKING_UPDATE_DEVICE_PENDING = 'EASY_CLOCKING_UPDATE_DEVICE_PENDING'
export const EASY_CLOCKING_UPDATE_DEVICE_FULFILLED = 'EASY_CLOCKING_UPDATE_DEVICE_FULFILLED'
export const EASY_CLOCKING_UPDATE_DEVICE_REJECTED = 'EASY_CLOCKING_UPDATE_DEVICE_REJECTED'

export const EASY_CLOCKING_SET_EDIT_ID = 'EASY_CLOCKING_SET_EDIT_ID'
