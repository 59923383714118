import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { Table } from '@jisr-hr/design-system'
import { minuteToHumanReadable } from 'components/global/form/formNormalize'

import TextParagraph from './TextParagraph'
import TextCaption from './TextCaption'
import Styles from '../OvertimeDuration.module.css'

const Total = ({ totalDuration }) => (
  <Table.Row>
    <td
      colSpan="100%"
      className={Styles.addAndTotal}
    >
      <Flex>
        <TextCaption
          label={I18n.t('total')}
          color="var(--color-base-colors-grey-600)"
        />
        <TextParagraph
          label={minuteToHumanReadable(totalDuration) || '--'}
          weight="bold"
        />
      </Flex>
    </td>
  </Table.Row>
)

Total.propTypes = {
  totalDuration: PropTypes.number,
}

export default Total
