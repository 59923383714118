import { useState, MouseEvent } from 'react'
import { DatePicker } from '@jisr-hr/ds-beta'
import { Flex, Divider } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

type FundDateT = { filters: string[]; title: string; filterKey: string }

const FundDate = (props: FundDateT): JSX.Element => {
  const { filters, title, filterKey } = props
  const { filter, setFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onChange = (val: string, field: string): void => {
    setFilter({
      ...filter,
      fund_dates: {
        ...(filter?.fund_dates || {}),
        [field]: val,
      },
    })
  }

  const handleClear = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setFilter({
      ...filter,
      fund_dates: undefined,
    })
  }

  return (
    <div>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={filter?.fund_dates?.from || filter?.fund_dates?.to}
          handleClearFilter={handleClear}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title={i18n.t('label.fund_date')}
        />
        {!isCollapsed && (
          <Flex
            style={{
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DatePicker
              label={i18n.t('from')}
              value={filter?.fund_dates?.from && new Date(filter?.fund_dates?.from)}
              onChange={(val: string | string[]): void => onChange(val as string, 'from')}
              triggerType="input"
              textFieldProps={{
                showClearIcon: false,
                testId: 'from-date',
              }}
              calenderProps={{
                type: 'single',
                maxDate: filter?.fund_dates?.to ? new Date(filter?.fund_dates?.to) : undefined,
              }}
            />
            <DatePicker
              label={i18n.t('to')}
              triggerType="input"
              value={filter?.fund_dates?.to && new Date(filter?.fund_dates?.to)}
              onChange={(val: string | string[]): void => onChange(val as string, 'to')}
              textFieldProps={{
                testId: 'to-date',
                showClearIcon: false,
              }}
              calenderProps={{
                type: 'single',
                minDate: filter?.fund_dates?.from ? new Date(filter?.fund_dates?.from) : undefined,
              }}
            />
          </Flex>
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

export default FundDate
