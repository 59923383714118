import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  HealthDeclarationLookupType,
  EndorsementType,
  NotInsuredEmployeesType,
  InitiateEndorsementResponseType,
  EndorsementDetailsType,
  ExternalBillDetailsType,
  EndorsementDetailIndividualType,
  submitEndorsementResponseType,
  DeletionReasonType,
  EndorsementDetailsHDResponseType,
} from 'containers/authorised/BenefitsManagement/Insurance/types'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

type StateType = {
  fetching: boolean
  syncing: boolean
  endorsementsList: EndorsementType[]
  healthDeclarationLookups: Record<string, HealthDeclarationLookupType[]>
  employeesList: NotInsuredEmployeesType[]
  VerifiedEmployeeInfoResponse: EndorsementDetailIndividualType[]
  notInsuredEmployeesPagination: PaginationTypes | Record<string, never>
  endorsementsPagination: PaginationTypes | Record<string, never>
  initiateEndorsement: InitiateEndorsementResponseType
  insurancePolicyEndorsementMembers: EndorsementDetailIndividualType[]
  endorsementDetails: EndorsementDetailsType | null
  fetchingEndorsementDetails: boolean
  bill_details?: ExternalBillDetailsType
  paymenting: boolean
  verifiedDependentsResponse: EndorsementDetailIndividualType[]
  saveDependentsRes: EndorsementDetailIndividualType[]
  savingHD: boolean
  healthDeclarationResponse: EndorsementDetailsHDResponseType
  changeClassHealthDeclaration: EndorsementDetailsHDResponseType[]
  dependentDeleted: boolean
  submitEndorsementResponse: submitEndorsementResponseType
  saveGccAndBedoonDependentsRes: EndorsementDetailIndividualType[]
  deletionReasons: DeletionReasonType[]
}

export const initialState: StateType = {
  fetching: false,
  syncing: false,
  endorsementsList: [],
  healthDeclarationLookups: {},
  employeesList: [],
  notInsuredEmployeesPagination: {},
  VerifiedEmployeeInfoResponse: [],
  initiateEndorsement: {} as InitiateEndorsementResponseType,
  endorsementsPagination: {},
  insurancePolicyEndorsementMembers: [],
  endorsementDetails: null,
  fetchingEndorsementDetails: false,
  paymenting: false,
  verifiedDependentsResponse: [],
  saveDependentsRes: [],
  savingHD: false,
  healthDeclarationResponse: {} as EndorsementDetailsHDResponseType,
  changeClassHealthDeclaration: [],
  dependentDeleted: false,
  submitEndorsementResponse: {} as submitEndorsementResponseType,
  saveGccAndBedoonDependentsRes: [],
  deletionReasons: [],
}

const groupByCategory = (
  arr: HealthDeclarationLookupType[],
): Record<string, HealthDeclarationLookupType[]> => {
  return arr.reduce((acc, item) => {
    const { category } = item
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push({ ...item, type: category === 'obesity' ? 'radio' : 'switch' })
    return acc
  }, {} as Record<string, HealthDeclarationLookupType[]>)
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.RESET_DEPENDENT_STATE:
      return {
        ...state,
        saveDependentsRes: [],
        saveGccAndBedoonDependentsRes: [],
        verifiedDependentsResponse: [],
        insurancePolicyEndorsementMembers: [],
        VerifiedEmployeeInfoResponse: [],
      }
    case actions.LOAD_ENDORSEMENTS_PENDING:
    case actions.GET_EMPLOYEES_List_PENDING:
    case actions.VERIFICATION_PENDING:
    case actions.GET_HEALTH_DECLARATIONS_LOOK_UPS_PENDING:
    case actions.DELETE_ENDORSEMENT_PENDING:
    case actions.INITIATE_ENDORSEMENT_PENDING:
    case actions.SAVE_EMPLOYEE_INFO_PENDING:
    case actions.VERIFY_DEPENDENTS_PENDING:
    case actions.SAVE_DEPENDENTS_PENDING:
    case actions.SUBMIT_ENDORSEMENT_PENDING:
    case actions.SAVE_GCC_AND_BEDOON_DEPENDENTS_PENDING:
    case actions.RESUBMIT_ENDORSEMENT_PENDING:
    case actions.CHANGE_CLASS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DELETE_DEPENDENTS_PENDING:
      return {
        ...state,
        fetching: true,
        dependentDeleted: false,
      }

    case actions.SAVE_HEALTH_DECLARATION_ANSWERS_PENDING:
    case actions.REUPLOAD_REQUIRED_DOCUMENTS_PENDING:
      return {
        ...state,
        savingHD: true,
      }

    case actions.VERIFY_EMPLOYEE_INFO_PENDING:
      return {
        ...state,
        fetching: true,
        VerifiedEmployeeInfoResponse: [],
        insurancePolicyEndorsementMembers: [],
      }

    case actions.SYNC_ENDORSEMENTS_PENDING:
      return {
        ...state,
        syncing: true,
      }

    case actions.GET_BILL_DETAILS_PENDING:
    case actions.GENERATE_BILL_PENDING:
      return {
        ...state,
        paymenting: true,
      }

    case actions.LOAD_ENDORSEMENT_DETAILS_PENDING:
      return {
        ...state,
        fetchingEndorsementDetails: true,
      }

    case actions.LOAD_ENDORSEMENTS_FULFILLED:
      return {
        ...state,
        fetching: false,
        endorsementsList: payload?.data?.data?.insurance_policy_endorsements,
        endorsementsPagination: payload?.data?.data?.pagination,
      }

    case actions.GET_EMPLOYEES_List_FULFILLED:
      return {
        ...state,
        fetching: false,
        employeesList:
          payload?.data?.data?.pagination.current_page === 1
            ? payload?.data?.data?.employees
            : [...state.employeesList, ...payload?.data?.data?.employees],
        notInsuredEmployeesPagination: payload?.data?.data?.pagination,
      }
    case actions.DELETE_ENDORSEMENT_FULFILLED:
      Toastr.success(payload?.data?.message)

      return {
        ...state,
        fetching: false,
        insurancePolicyEndorsementMembers: [],
        VerifiedEmployeeInfoResponse: [],
        // verifiedDependentsResponse: []
      }

    case actions.SAVE_EMPLOYEE_INFO_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        insurancePolicyEndorsementMembers:
          payload?.data?.data?.insurance_policy_endorsement?.insurance_policy_endorsement_members,
      }

    case actions.GET_HEALTH_DECLARATIONS_LOOK_UPS_FULFILLED:
      const data = groupByCategory(payload?.data?.data?.insurance_declaration_questions)
      return {
        ...state,
        fetching: false,
        healthDeclarationLookups: data ?? {},
      }

    case actions.VERIFY_EMPLOYEE_INFO_FULFILLED:
      return {
        ...state,
        fetching: false,
        VerifiedEmployeeInfoResponse:
          payload?.data?.data?.insurance_policy_endorsement?.insurance_policy_endorsement_members,
      }

    case actions.INITIATE_ENDORSEMENT_FULFILLED:
      return {
        ...state,
        fetching: false,
        initiateEndorsement: payload?.data?.data?.insurance_policy_endorsement,
      }

    case actions.SAVE_HEALTH_DECLARATION_ANSWERS_FULFILLED:
      const is_in_change_class = window.location.href
        .split('?')?.[1]
        ?.includes('endorsement_type=changeClass')

      Toastr.success(payload?.data?.message)
      if (payload?.data?.data?.insurance_declaration_answer.answer === false) {
        state.changeClassHealthDeclaration.filter(
          (ans) => ans.id === payload?.data?.data?.insurance_declaration_answer.id,
        )
      }
      return {
        ...state,
        savingHD: false,
        healthDeclarationResponse: payload?.data?.data?.insurance_declaration_answer,
        changeClassHealthDeclaration: is_in_change_class
          ? [
              ...state.changeClassHealthDeclaration,
              payload?.data?.data?.insurance_declaration_answer,
            ]
          : state.changeClassHealthDeclaration,
      }

    case actions.SYNC_ENDORSEMENTS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        syncing: false,
      }

    case actions.VERIFY_DEPENDENTS_FULFILLED:
      return {
        ...state,
        fetching: false,
        verifiedDependentsResponse:
          payload?.data?.data?.insurance_policy_endorsement?.insurance_policy_endorsement_members,
      }

    case actions.REUPLOAD_REQUIRED_DOCUMENTS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        savingHD: false,
      }

    case actions.LOAD_ENDORSEMENT_DETAILS_FULFILLED:
      return {
        ...state,
        fetchingEndorsementDetails: false,
        endorsementDetails: payload?.data?.data?.insurance_policy_endorsement,
      }

    case actions.GET_BILL_DETAILS_FULFILLED:
      return {
        ...state,
        paymenting: false,
        bill_details: payload?.data?.data,
      }

    case actions.GENERATE_BILL_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        paymenting: false,
      }

    case actions.SAVE_DEPENDENTS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        saveGccAndBedoonDependentsRes: [],
        saveDependentsRes:
          payload?.data?.data?.insurance_policy_endorsement?.insurance_policy_endorsement_members,
      }

    case actions.SAVE_GCC_AND_BEDOON_DEPENDENTS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        saveDependentsRes: [],
        saveGccAndBedoonDependentsRes:
          payload?.data?.data?.insurance_policy_endorsement?.insurance_policy_endorsement_members,
      }

    case actions.DELETE_DEPENDENTS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        dependentDeleted: true,
        verifiedDependentsResponse: [],
        saveDependentsRes: [],
        saveGccAndBedoonDependentsRes: [],
      }

    case actions.SUBMIT_ENDORSEMENT_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        submitEndorsementResponse: payload?.data?.data,
      }

    case actions.RESUBMIT_ENDORSEMENT_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.CHANGE_CLASS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
        initiateEndorsement: payload?.data?.data?.insurance_policy_endorsement,
      }

    case actions.LOAD_ENDORSEMENTS_REJECTED:
    case actions.GET_EMPLOYEES_List_REJECTED:
    case actions.VERIFICATION_REJECTED:
    case actions.GET_HEALTH_DECLARATIONS_LOOK_UPS_REJECTED:
    case actions.VERIFY_EMPLOYEE_INFO_REJECTED:
    case actions.DELETE_ENDORSEMENT_REJECTED:
    case actions.INITIATE_ENDORSEMENT_REJECTED:
    case actions.SAVE_EMPLOYEE_INFO_REJECTED:
    case actions.VERIFY_DEPENDENTS_REJECTED:
    case actions.SAVE_DEPENDENTS_REJECTED:
    case actions.DELETE_DEPENDENTS_REJECTED:
    case actions.SUBMIT_ENDORSEMENT_REJECTED:
    case actions.SAVE_GCC_AND_BEDOON_DEPENDENTS_REJECTED:
    case actions.RESUBMIT_ENDORSEMENT_REJECTED:
    case actions.CHANGE_CLASS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.SAVE_HEALTH_DECLARATION_ANSWERS_REJECTED:
    case actions.REUPLOAD_REQUIRED_DOCUMENTS_REJECTED:
      Toastr.error(handleResponseErr(payload))

      return {
        ...state,
        savingHD: false,
      }

    case actions.GET_BILL_DETAILS_REJECTED:
    case actions.GENERATE_BILL_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        paymenting: false,
      }

    case actions.SYNC_ENDORSEMENTS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        syncing: false,
      }

    case actions.LOAD_ENDORSEMENT_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetchingEndorsementDetails: false,
      }

    case actions.RESET_CHANGE_CLASS_HEALTH_DECLARATION:
      return {
        ...state,
        changeClassHealthDeclaration: [],
      }

    default:
      return state
  }
}
