import { ReactNode } from 'react'
import { Widget } from 'components/global/atoms'
import { Button, Typography, Flex, Spacer } from '@jisr-hr/ds'
import { Form } from 'react-final-form'
import { SwitchField } from 'components/final-form'
import I18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  useGetOnboardingPayrunConfigurations,
  useUpdateOnboardingPayrunConfigurations,
} from 'apis/Finance/payrun_configurations/usePayrunConfigurations'
import { PayrunConfigurationType } from 'apis/Finance/payrun_configurations/usePayrunConfigurations.types'
import styles from './../style.module.css'

const CalculationConfiguration = (): JSX.Element => {
  const dispatch = useDispatch()
  const { data: onboardingConfigs, isFetching } = useGetOnboardingPayrunConfigurations({
    query: {
      select: (d) => d.data.data,
    },
  })
  const { mutateAsync: onboardingPayrunConfigUpdate } = useUpdateOnboardingPayrunConfigurations()

  const initialValues = {
    value: onboardingConfigs?.is_attendance_review_required_for_vacation_settlement.value,
  }
  const handleSubmitConfiguration = (values: { value: boolean }): void => {
    const data: PayrunConfigurationType[] = [
      { name: 'is_attendance_review_required_for_vacation_settlement', value: values.value },
    ]
    onboardingPayrunConfigUpdate({ configurations: data }).then(() => dispatch(organizationInfo()))
  }

  return (
    <Widget fetching={isFetching}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmitConfiguration}
      >
        {({ handleSubmit, pristine }): ReactNode => (
          <form onSubmit={handleSubmit}>
            <Flex justifyBetween>
              <Typography
                variant="title-1"
                text={I18n.t('vacation_settlement_payment_processing')}
              />

              <SwitchField name="value" />
            </Flex>
            <div className={styles.divider} />
            <Spacer height={20} />
            <Flex justifyEnd>
              <Button
                disabled={pristine}
                label={I18n.t('save')}
                type="submit"
                size="medium"
                data-testid="save"
              />
            </Flex>
          </form>
        )}
      </Form>
    </Widget>
  )
}

export default CalculationConfiguration
