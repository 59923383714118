import React from 'react'
import PropTypes from 'prop-types'
import { Widget } from 'components/global/atoms'
import { Typography, Flex, Spacer, Button } from '@jisr-hr/ds'
import I18n from 'translations/i18n'

import styles from './styles.module.css'

const EmptyState = ({
  icon,
  title,
  labelBtn,
  onClick,
  style,
  fetching,
  testId,
  btnVariant = '',
  btnSize = '',
}) => {
  return (
    <Widget
      className={styles.emptyState}
      style={style}
      fetching={fetching}
    >
      <div className={title ? styles.includesText : styles.headerEmptyState}>
        <Typography
          variant="subtitle-1"
          text={title}
        />
      </div>
      <Flex
        flexCol
        itemsCenter
        style={{ paddingBlock: title ? 44 : 20 }}
      >
        {icon}
        <Spacer height={16} />
        {onClick ? (
          <Button
            testId={testId}
            label={I18n.t(labelBtn)}
            variant={btnVariant || 'outlined'}
            color="neutral"
            size={btnSize || 'small'}
            leadingIcon="plus"
            onClick={onClick}
          />
        ) : (
          <Typography
            variant="interface/default/sm"
            text={I18n.t(labelBtn)}
          />
        )}
      </Flex>
    </Widget>
  )
}

EmptyState.propTypes = {
  icon: PropTypes.node,
  fetching: PropTypes.bool,
  title: PropTypes.string,
  labelBtn: PropTypes.string,
  style: PropTypes.oneOfType(PropTypes.string, PropTypes.shape()),
  onClick: PropTypes.func,
  testId: PropTypes.string,
}

export default EmptyState
