const Marketplace = {
  // A
  active_integrations: 'ar',
  all_apps: 'كل التطبيقات',
  are_you_sure_disconnect: 'هل أنت متأكد أنك تريد فك ارتباط هذا التطبيق؟',
  app_not_in_plan: 'باقتك الحالية لا تشتمل على هذا التطبيق',
  app_not_in_plan_desc: 'احصل على المزيد من التطبيقات والمزايا وغيرها الكثير مع الباقة الاحترافية.',

  // B
  back_to_marketplace: 'العودة إلى المتجر',

  // C
  category_marketplace: 'فئة',
  categories: 'الاقسام',
  categories_subtitle: 'ar',
  contact_us_help: 'إذا كنت بحاجة إلى مساعدة، من فضلك',
  contact_us: 'تواصل معنا',
  connect_marketplace: 'ربط',

  // D
  disconnect_integration: 'فك ارتباط التطبيق',
  disconnect_apps: 'إيقاف الربط',
  disable_marketplace: 'إلغاء التفعيل',
  disabled_marketplace: 'غير مفعل',
  developer_documents: 'دليل الربط مع جسر',

  // E
  explore_all_apps: 'استكشف جميع التطبيقات',

  // F
  filters_apps: 'المرشحات',

  // H
  highlight: 'الرئيسية',

  // I
  it_look: 'يبدو أنك لم تقم بتوصيل أي تطبيقات حتى الآن.',

  // K
  knowledge_base: 'المدونة',
  key_features: 'مزايا الربط',

  // M
  manage_apps: 'إدارة التطبيقات',
  manage_connected_apps: 'إدارة التطبيقات المتصلة',

  // N
  need_help: 'تحتاج إلى مساعدة؟',
  no_apps: 'لا توجد تطبيقات متصلة',
  no_apps_to_show: 'لا يوجد تطبيقات في الوقت الحالي',

  // O
  or_check_our: 'او تصفح',
  overview_app: 'نظرة عامة',

  // P
  pro: 'PRO',
  pricing_plan: 'الباقة',
  popular_apps: 'التطبيقات الشائعة',
  poster_title: 'مرحبا بك في المتجر الجديد!',
  poster_subtitle:
    'اكتشف التطبيقات بشكل أسرع وأسهل من خلال منصتنا المعاد تصميمها. لقد أضفنا أيضًا المزيد من التطبيقات لتعزيز إنتاجيتك.',
  package_compatibility: '{{app_name}} الباقة المدعومة',

  // R
  request_upgrade: 'طلب ترقية الباقة',

  // S
  search_for_apps: 'البحث عن تطبيقات',

  // T
  time_to_upgrade: 'هذا التطبيق غير مدعوم في باقتك الحالية. يرجى ترقية باقتك لمواصلة استخدامه.',

  // V
  version: 'الإصدار',
}

export default Marketplace
