import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { TextInput } from '@jisr-hr/design-system'
import { Label, TextField } from '@jisr-hr/ds'
import { sanitizeInput } from './form.helper'

const TextInputField = ({
  name,
  validate,
  parse,
  testId,
  onBlur,
  subscription,
  style,
  onChange,
  status,
  statusMessage,
  ...props
}) => (
  <Field
    name={name}
    validate={validate}
    parse={parse}
    subscription={subscription}
  >
    {({ input, meta }) => (
      <TextInput
        style={{ marginBottom: 8, ...style }}
        {...props}
        {...input}
        status={(meta.error && meta.touched && 'error') || status}
        statusMessage={meta.error || statusMessage}
        onChange={({ target }) => {
          const sanitized = sanitizeInput(target.value)
          if (onChange) onChange(sanitized)
          input.onChange(sanitized)
        }}
        onBlur={(e) => {
          if (onBlur) onBlur(e)
          input.onBlur(e)
        }}
        data-testid={testId}
      />
    )}
  </Field>
)

export const TextInputDSField = ({
  name,
  validate,
  parse,
  testId,
  onBlur,
  subscription,
  style,
  onChange,
  status,
  statusMessage,
  onFocus,
  ...props
}) => (
  <Field
    name={name}
    validate={validate}
    parse={parse}
    subscription={subscription}
  >
    {({ input, meta }) => (
      <Label
        name={props.label}
        htmlFor={name}
        disabled={props.disabled}
        style={style}
      >
        <TextField
          {...props}
          {...input}
          id={name}
          status={(meta.error && meta.touched && 'error') || status}
          statusMessage={meta.error || statusMessage}
          destructive={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          testId={testId}
          onChange={(value) => {
            const sanitized = sanitizeInput(value)
            if (onChange) onChange(sanitized)
            input.onChange(sanitized)
          }}
          onFocus={({ target }) => {
            if (onFocus) onFocus(target.value)
            input.onFocus(target.value)
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e)
            input.onBlur(e)
          }}
        />
      </Label>
    )}
  </Field>
)

TextInputField.propTypes = {
  name: PropTypes.string,
  testId: PropTypes.string,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  mb: PropTypes.number,
  validate: PropTypes.func,
  parse: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  subscription: PropTypes.shape(),
  style: PropTypes.shape(),
}

TextInputDSField.propTypes = {
  name: PropTypes.string,
  testId: PropTypes.string,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  mb: PropTypes.number,
  validate: PropTypes.func,
  parse: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  subscription: PropTypes.shape(),
  style: PropTypes.shape(),
}

export default TextInputField
