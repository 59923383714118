import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function notificationsLoad(params: Record<string, unknown> = {}): Action {
  const url = `/${API_VERSION_TWO}/notifications`

  return {
    type: actions.GET_NOTIFICATIONS,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function toggleNotificationsPanel(value: boolean): Action {
  return {
    type: actions.TOGGLE_NOTIFICATIONS_PANEL,
    payload: { isOpen: value },
  }
}

export function resetNotifications(): Action {
  return {
    type: actions.RESET_NOTIFICATIONS,
  }
}

export function eventnotificationsLoad(params: Record<string, unknown> = {}): Action {
  const url = `/${API_VERSION_TWO}/notifications`

  return {
    type: actions.GET_EVENT_NOTIFICATIONS,
    payload: handleAPI(url, params, 'GET'),
  }
}
