import { memo } from 'react'
import { Flex, Typography } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import { ReactComponent as InfoLightImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/lightbulb.svg'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { PartialPath } from 'history'
import { useHistory } from 'react-router-dom'
import i18n from 'translations/i18n'
import { usePaymentMethod } from '../../../components/usePaymentMethods'

type propsType = {
  type: string
}

const InvoiceAlert = (props: propsType): JSX.Element => {
  const history = useHistory()
  const { type } = props
  const paymentCtx = usePaymentMethod()
  const { isOnBoarding, selectedPaymentMethod } = paymentCtx
  const handleInstructionClick = (): void => {
    if (type === 'success') {
      paymentCtx.handleRevokeMudad()
    } else {
      history.push({
        pathname: '/setting_v2/organization/org_structure/legal',
        state: {
          legal_structure: selectedPaymentMethod?.id,
        },
      } as PartialPath)
    }
  }
  return (
    <Flex
      className={mainStyles.primary_alert_box}
      style={{ marginBottom: 20, gap: 12 }}
    >
      <InfoLightImg style={{ flexShrink: 0 }} />
      <div>
        <Typography
          text={i18n.t('your_subscription_will_start_after_completing_registration')}
          style={{ lineHeight: '20px' }}
        />
        <ul>
          <li>
            <Typography
              text={i18n.t('important_notes_before_setup_mudad')}
              style={{ lineHeight: '20px' }}
            />
            <ul style={{ paddingInlineStart: 12 }}>
              <li>
                <Typography
                  text={i18n.t('make_sure_that_your_bank_account_is_linked_to_your_commercial')}
                  style={{ lineHeight: '20px' }}
                />
              </li>
              <li>
                <Typography
                  text={i18n.t('that_is_you_have_your_company_legal_structure_in_Jisr_is_correct')}
                  style={{ lineHeight: '20px' }}
                />
              </li>
              <li>
                <Typography
                  text={i18n.t('make_sure_to_contact_your_organization_note')}
                  style={{ lineHeight: '20px' }}
                />
              </li>
              <li>
                <Typography
                  text={i18n.t('We_will_send_you_invoice')}
                  style={{ lineHeight: '20px' }}
                />
              </li>
            </ul>
          </li>
          <li>
            <Typography
              text={i18n.t('make_sure_that_is_the_information')}
              style={{ lineHeight: '20px' }}
            />
          </li>
        </ul>
        {type === 'info' && !isOnBoarding && (
          <Flex
            itemsCenter
            style={{
              gap: 8,
              cursor: 'pointer',
              width: 'fit-content',
              marginTop: 16,
            }}
            onClick={handleInstructionClick}
          >
            <DSIcon
              size="sm"
              name={type === 'info' ? 'edit-02' : 'tool-02'}
              color="var(--color-brand-primary-default-new)"
            />
            <Typography
              text={i18n.t(type === 'info' ? 'edit_legal_structure' : 'revoke_mudad')}
              style={{ lineHeight: '20px' }}
              textColor="color/fg/brand/default"
            />
          </Flex>
        )}
      </div>
    </Flex>
  )
}

export default memo(InvoiceAlert)
