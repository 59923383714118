import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function leaveExtensionsPost(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_extensions`
  return {
    type: actions.LEAVE_EXTENSIONS_POST,
    payload: handleAPI(url, {}, 'POST', { leave_extension: data }),
  }
}
