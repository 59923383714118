import { Containers, Skeleton, Spacer } from '@jisr-hr/ds'

import styles from './styles.module.css'

const GetHelpSkeleton = (): JSX.Element => {
  return (
    <>
      <Containers
        color="white"
        borderRadius="sm"
        border
        className={styles.section}
      >
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={200}
            height={20}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={250}
            height={40}
          />
        </>
      </Containers>
      <Containers
        color="white"
        borderRadius="sm"
        border
        className={styles.section}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={250}
          height={40}
        />
      </Containers>

      <Containers
        color="white"
        borderRadius="sm"
        border
        className={styles.section}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={250}
          height={40}
        />
        <Spacer height={20} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
      </Containers>
      <Containers
        color="white"
        borderRadius="sm"
        border
        className={styles.section}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={200}
          height={20}
        />
      </Containers>
    </>
  )
}

export default GetHelpSkeleton
