export const ONBOARDING_HOLIDAYS_LOAD = 'ONBOARDING_HOLIDAYS_LOAD'
export const ONBOARDING_HOLIDAYS_LOAD_PENDING = 'ONBOARDING_HOLIDAYS_LOAD_PENDING'
export const ONBOARDING_HOLIDAYS_LOAD_FULFILLED = 'ONBOARDING_HOLIDAYS_LOAD_FULFILLED'
export const ONBOARDING_HOLIDAYS_LOAD_REJECTED = 'ONBOARDING_HOLIDAYS_LOAD_REJECTED'

export const ONBOARDING_HOLIDAYS_DEFAULT_LOAD = 'ONBOARDING_HOLIDAYS_DEFAULT_LOAD'
export const ONBOARDING_HOLIDAYS_DEFAULT_LOAD_PENDING = 'ONBOARDING_HOLIDAYS_DEFAULT_LOAD_PENDING'
export const ONBOARDING_HOLIDAYS_DEFAULT_LOAD_FULFILLED =
  'ONBOARDING_HOLIDAYS_DEFAULT_LOAD_FULFILLED'
export const ONBOARDING_HOLIDAYS_DEFAULT_LOAD_REJECTED = 'ONBOARDING_HOLIDAYS_DEFAULT_LOAD_REJECTED'

export const ONBOARDING_HOLIDAYS_DEFAULT_UPDATE = 'ONBOARDING_HOLIDAYS_DEFAULT_UPDATE'
export const ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_PENDING =
  'ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_PENDING'
export const ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_FULFILLED =
  'ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_FULFILLED'
export const ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_REJECTED =
  'ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_REJECTED'

export const ONBOARDING_HOLIDAYS_CREATE = 'ONBOARDING_HOLIDAYS_CREATE'
export const ONBOARDING_HOLIDAYS_CREATE_PENDING = 'ONBOARDING_HOLIDAYS_CREATE_PENDING'
export const ONBOARDING_HOLIDAYS_CREATE_FULFILLED = 'ONBOARDING_HOLIDAYS_CREATE_FULFILLED'
export const ONBOARDING_HOLIDAYS_CREATE_REJECTED = 'ONBOARDING_HOLIDAYS_CREATE_REJECTED'

export const ONBOARDING_HOLIDAYS_UPDATE = 'ONBOARDING_HOLIDAYS_UPDATE'
export const ONBOARDING_HOLIDAYS_UPDATE_PENDING = 'ONBOARDING_HOLIDAYS_UPDATE_PENDING'
export const ONBOARDING_HOLIDAYS_UPDATE_FULFILLED = 'ONBOARDING_HOLIDAYS_UPDATE_FULFILLED'
export const ONBOARDING_HOLIDAYS_UPDATE_REJECTED = 'ONBOARDING_HOLIDAYS_UPDATE_REJECTED'

export const ONBOARDING_HOLIDAYS_DELETE = 'ONBOARDING_HOLIDAYS_DELETE'
export const ONBOARDING_HOLIDAYS_DELETE_PENDING = 'ONBOARDING_HOLIDAYS_DELETE_PENDING'
export const ONBOARDING_HOLIDAYS_DELETE_FULFILLED = 'ONBOARDING_HOLIDAYS_DELETE_FULFILLED'
export const ONBOARDING_HOLIDAYS_DELETE_REJECTED = 'ONBOARDING_HOLIDAYS_DELETE_REJECTED'
