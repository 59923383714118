import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetchingNotification: false,
  notifications: {
    admin: [],
    reviewer: [],
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PERFORMANCE_NOTIFICATION_LOAD_PENDING:
    case actions.PERFORMANCE_NOTIFICATION_MARK_AS_READ_PENDING:
      return {
        ...state,
        fetchingNotification: true,
      }

    case actions.PERFORMANCE_NOTIFICATION_MARK_AS_READ_FULFILLED:
      return {
        ...state,
        fetchingNotification: false,
      }

    case actions.PERFORMANCE_NOTIFICATION_LOAD_FULFILLED:
      return {
        ...state,
        notifications: action.payload.data.data,
        fetchingNotification: false,
      }

    case actions.PERFORMANCE_NOTIFICATION_LOAD_REJECTED:
    case actions.PERFORMANCE_NOTIFICATION_MARK_AS_READ_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingNotification: false,
      }

    default:
      return state
  }
}
