import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cns from 'classnames'
import I18n from 'translations/i18n'
import { removeUndo } from 'redux/undoAlert/actionCreators'

import { ReactComponent as MarkIcon } from './icons/mark.svg'

import Styles from './Undo.module.css'

const UndoItem = React.memo(({ id, label, testId, callback, onUndo }) => {
  const [timer, setTimer] = React.useState(0)
  const timerRef = React.useRef(null)

  const dispatch = useDispatch()

  const handleUndo = () => {
    onUndo?.()
    dispatch(removeUndo(id))
  }

  const onUndoClick = () => {
    clearTimeout(timerRef.current)
    setTimer(101)
    setTimeout(() => handleUndo(), 200)
  }

  React.useEffect(() => {
    if (timer <= 100) {
      timerRef.current = setTimeout(() => setTimer(timer + 1), 60)
    }
  })

  React.useEffect(() => {
    if (timer === 100) {
      callback?.()
      setTimeout(() => dispatch(removeUndo(id)), 200)
    }
  }, [timer])

  return (
    <div
      className={cns(Styles.undo, { [Styles.goOut]: timer >= 100 })}
      data-testid={testId}
    >
      <MarkIcon data-testid={`${testId}-icon`} />
      <span
        data-testid={`${testId}-label`}
        className={Styles.label}
      >
        {label}
      </span>
      <button
        data-testid={`${testId}-button`}
        className={Styles.undoBtn}
        onClick={onUndoClick}
      >
        {I18n.t('undo')}
      </button>
      <div
        data-testid={`${testId}-progress`}
        className={Styles.progressBar}
      >
        <div
          className={Styles.progress}
          style={{ width: `${timer}%` }}
        />
      </div>
    </div>
  )
})

UndoItem.propTypes = {
  callback: PropTypes.func,
  onUndo: PropTypes.func,
  id: PropTypes.number,
  label: PropTypes.string,
  testId: PropTypes.string,
}

export default UndoItem
