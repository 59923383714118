import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlaneIcon } from 'assets/figma-icons/Plane.svg'
import { Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { format } from 'utils/date'
import { isEmpty } from 'lodash'

import { ReactComponent as CalendarIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/calendar.svg'
import { ReactComponent as FamilyHeartIcon } from 'assets/images/Heart.svg'

import styles from './styles.module.css'
import InfoList from '../../ds/InfoList'
import InfoBadge from '../Info/components/InfoBadge'
import { formatMonth } from '../helper'

const InfoItem = ({ items }) => {
  return (
    <Flex
      itemsCenter
      className={styles.badge}
    >
      {items?.map((item, i) => (
        <InfoBadge
          key={i}
          label={item?.name}
          type="gray"
        />
      ))}
    </Flex>
  )
}
InfoItem.propTypes = {
  items: PropTypes.shape(),
}

const FlightTicketDetails = ({ details }) => {
  const leaveingDate = details?.leave_date
  const returningDate = details?.return_date
  const familyDetails = details?.family_details
  const leaveing = leaveingDate && format(new Date(leaveingDate), `dd ${formatMonth}, yyyy`)
  const returning = returningDate && format(new Date(returningDate), `dd ${formatMonth}, yyyy`)

  const items = [
    { icon: <CalendarIcon />, value: leaveing, label: I18n.t('departure_date') },
    { icon: <CalendarIcon />, value: returning, label: I18n.t('return_date') },
    !isEmpty(familyDetails) && {
      icon: <FamilyHeartIcon />,
      value: <InfoItem items={familyDetails} />,
      label: I18n.t('count_family_members', { number: familyDetails?.length || 0 }),
    },
  ].filter(Boolean)

  return (
    <Frame
      customStyle={styles.frame}
      title={I18n.t('requested_flight_ticket')}
      icon={<PlaneIcon />}
    >
      <InfoList items={items} />
    </Frame>
  )
}

FlightTicketDetails.propTypes = {
  details: PropTypes.shape(),
}

export default FlightTicketDetails
