import React from 'react'
import I18n from 'translations/i18n'

const CreateStep = () => (
  <div
    data-testid="workflow-steps-create"
    className="workflow-steps__create_step"
  >
    <div className="workflow-steps__step__create_btn">
      <div className="workflow-steps__step__plus">+</div>
      <p>{I18n.t('new_workflow_step')}</p>
    </div>
  </div>
)

export default CreateStep
