import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  WebhookLookUpsType,
  WebhookDetailsType,
  WebhooksSummaryType,
  ActionType,
  CustomHeaderType,
} from 'containers/authorised/setting/v2/integration/Webhooks/types'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationExtendedTypes } from 'types/sharedResponse'
import { isEmpty } from 'lodash'
import * as actions from './actions'

type InitStateType = {
  responseMessage: { message: string; type: string }
  fetching: boolean
  webhookLookUps: WebhookLookUpsType[]
  pagination: PaginationExtendedTypes
  selectedWebhookDetails: WebhookDetailsType
  webhooksList: WebhooksSummaryType[]
  resetPageData: boolean
  drawerOpen: boolean
  testWebhookResponse: { message: string; type: string; statusCode: null | string }
}

const initialState: InitStateType = {
  fetching: false,
  responseMessage: { message: '', type: '' },
  webhookLookUps: [],
  pagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
  selectedWebhookDetails: {
    id: '',
    name: '',
    auth_data: {},
    auth_position: '',
    auth_type: '',
    request_format: '',
    custom_header: [],
    description: '',
    endpoint: '',
    http_method: '',
    status: false,
    created_at: '',
    updated_at: '',
    actions: [],
  },
  resetPageData: false,
  webhooksList: [],
  drawerOpen: false,
  testWebhookResponse: { message: '', type: '', statusCode: null },
}

const parseWebhookData = (customHeaderObj: Record<string, string>): CustomHeaderType[] => {
  const customHeaderArr = !isEmpty(customHeaderObj)
    ? Object.entries(customHeaderObj).map(([headerName, value]) => ({
        headerName,
        value,
      }))
    : []
  return customHeaderArr
}

const updateSelectAll = (lookupsGroupData: WebhookLookUpsType[]): WebhookLookUpsType[] => {
  return lookupsGroupData.map((group: WebhookLookUpsType) => {
    return {
      ...group,
      select_all: group.actions.every((a) => a.status),
    }
  })
}

const updateLookups = (
  lookupsGroupData: WebhookLookUpsType[],
  selectedActions?: ActionType[],
): WebhookLookUpsType[] => {
  const data = lookupsGroupData.map((group: WebhookLookUpsType) => {
    return {
      ...group,
      actions: group.actions.map((action) => {
        const isSelected = selectedActions
          ? selectedActions.some((a) => a.id === action.id)
          : action.status === 'active'
        return { ...action, status: isSelected }
      }),
    }
  })
  return updateSelectAll(data)
}

const setWebhookData = (action: Action, state: InitStateType): WebhookDetailsType => {
  const {
    actions: webhookActions,
    status,
    custom_header,
    ...webhookDetails
  } = action.payload.data.data.subscription

  const webhook = {
    ...webhookDetails,
    status: status === 'active',
    custom_header: parseWebhookData(custom_header),
    actions: updateLookups(state.webhookLookUps, webhookActions),
  }
  return webhook as WebhookDetailsType
}

const updateList = (action: Action, state: InitStateType): WebhooksSummaryType[] => {
  const webhook: WebhooksSummaryType = action.payload.data.data.subscription
  const list: WebhooksSummaryType[] =
    action.type === actions.CREATE_WEBHOOK_FULFILLED
      ? [webhook, ...state.webhooksList]
      : [webhook, ...state.webhooksList.filter((item) => item.id !== webhook.id)]
  return list
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_WEBHOOKS_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        resetPageData: false,
      }

    case actions.GET_WEBHOOK_LOOK_UPS_LIST_PENDING:
    case actions.CREATE_WEBHOOK_PENDING:
    case actions.VIEW_WEBHOOK_PENDING:
    case actions.UPDATE_WEBHOOK_PENDING:
    case actions.DELETE_WEBHOOK_PENDING:
    case actions.TEST_WEBHOOK_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CREATE_WEBHOOK_FULFILLED: {
      const { total_entries, next_page, per_page, current_page } = state.pagination
      const newTotalPages = (total_entries + 1) / per_page
      const newNextPage = next_page === null && newTotalPages > 1 ? 2 : next_page
      return {
        ...state,
        selectedWebhookDetails: setWebhookData(action, state) as WebhookDetailsType,
        webhooksList: current_page === 1 ? updateList(action, state) : state.webhooksList,
        responseMessage: { message: payload.data.message, type: 'success' },
        fetching: false,
        pagination:
          current_page === 1
            ? {
                ...state.pagination,
                total_entries: total_entries + 1,
                total_pages: newTotalPages,
                next_page: newNextPage,
              }
            : state.pagination,
        testWebhookResponse: { message: '', type: '', statusCode: null },
      }
    }

    case actions.UPDATE_WEBHOOK_FULFILLED: {
      if (!state.drawerOpen && payload.data.message) Toastr.success(payload.data.message)

      return {
        ...state,
        webhooksList: updateList(action, state),
        selectedWebhookDetails: setWebhookData(action, state) as WebhookDetailsType,
        responseMessage: { message: payload.data.message, type: 'success' },
        fetching: false,
      }
    }

    case actions.VIEW_WEBHOOK_FULFILLED: {
      return {
        ...state,
        selectedWebhookDetails: setWebhookData(action, state),
        fetching: false,
        testWebhookResponse: { message: '', type: '', statusCode: null },
      }
    }

    case actions.GET_WEBHOOKS_LIST_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      const { data } = payload.data
      return {
        ...state,
        fetching: false,
        webhooksList: data.subscriptions ?? [],
        pagination: data.pagination ?? {},
        resetPageData: false,
      }
    }

    case actions.DELETE_WEBHOOK_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.GET_WEBHOOK_LOOK_UPS_LIST_FULFILLED: {
      let lookupsData: WebhookLookUpsType[] = payload.data.data.lookups.groups
      lookupsData = updateLookups(lookupsData)
      return {
        ...state,
        webhookLookUps: lookupsData,
        fetching: false,
      }
    }

    case actions.TEST_WEBHOOK_FULFILLED: {
      const type = payload.data.data.success ? 'success' : 'error'
      const message = payload.data.data.success
        ? payload?.data?.data?.data?.data || 'OK'
        : handleResponseErr(payload.data.data.error.message)
      const statusCode = payload.data.data.status_code
      return {
        ...state,
        fetching: false,
        testWebhookResponse: { message, type, statusCode },
      }
    }

    case actions.GET_WEBHOOKS_LIST_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        resetPageData: true,
      }
    }

    case actions.VIEW_WEBHOOK_REJECTED:
    case actions.GET_WEBHOOK_LOOK_UPS_LIST_REJECTED:
    case actions.DELETE_WEBHOOK_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.CREATE_WEBHOOK_REJECTED:
    case actions.UPDATE_WEBHOOK_REJECTED: {
      return {
        ...state,
        fetching: false,
        responseMessage: { message: handleResponseErr(payload), type: 'error' },
      }
    }

    case actions.TEST_WEBHOOK_REJECTED: {
      return {
        ...state,
        fetching: false,
        testWebhookResponse: {
          message: handleResponseErr(payload),
          type: 'error',
          statusCode: null,
        },
      }
    }

    case actions.SET_DRAWER_OPEN: {
      return {
        ...state,
        drawerOpen: payload.data,
      }
    }

    default:
      return state
  }
}
