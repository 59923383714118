import { FC } from 'react'
import { Grid } from 'components/global/atoms'
import { Spacer } from '@jisr-hr/ds'
import { required } from 'components/global/form/FormValidations'
import { Form } from 'react-final-form'
import { DatePickerDSField, TextAreaField } from 'components/final-form'
import i18n from 'translations/i18n'

export type ResumptionDate = { reason: string; return_date: string }

type ResumptionFormProps = {
  onSubmit: (values: ResumptionDate) => void
}

const ResumptionForm: FC<ResumptionFormProps> = ({ onSubmit }: ResumptionFormProps) => (
  <Form<ResumptionDate>
    onSubmit={(val) => {
      onSubmit?.(val)
    }}
    render={({ handleSubmit }) => (
      <form
        id="resumption_form"
        onSubmit={handleSubmit}
      >
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid
            item
            md={12}
          >
            <DatePickerDSField
              name="return_date"
              label={i18n.t('return_date')}
              validate={required}
            />
          </Grid>
          <Spacer height="18px" />
          <Grid
            item
            md={12}
          >
            <TextAreaField
              name="reason"
              label={i18n.t('reason')}
              height={120}
            />
          </Grid>
        </Grid>
      </form>
    )}
  />
)

export default ResumptionForm
