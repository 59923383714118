import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type OldRatingRange = {
  label_ar: string
  label_en: string
  level_index: number
  description_ar: string
  description_en: string
}
export type Metadata = {
  rating_range: OldRatingRange[]
}
export type Competency = {
  id: number
  organization_id: number
  level: number
  rateable_type: string
  rateable_type_i18n: string
  rating_type: string
  rating_type_i18n: string
  status: string
  status_i18n: string
  metadata: Metadata
  created_at: Date
  updated_at: Date
}
export type OldRatingMethodType = {
  competency: Competency
  objective: Competency
  normal_review_cycle: Competency
  probation_period_review_cycle: Competency
}

export type OnboardingSteps = {
  id: number
  name: string
  step_number: string
  is_completed: boolean
  completed_at: null | number
}

type State = {
  fetching: boolean
  onboardingSteps: OnboardingSteps[] | null
  applicableForMigration?: boolean
  oldRatingMethods?: OldRatingMethodType
}

export interface RatingRange {
  label_ar: string
  label_en: string
  level_index: number
  description_ar: string
  description_en: string
}

export const initialState: State = {
  fetching: false,
  onboardingSteps: null,
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.PM_ONBOARDING_LOAD_PENDING:
    case actions.PM_ONBOARDING_UPDATE_STEPS_PENDING:
    case actions.PM_ONBOARDING_COMPLETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PM_ONBOARDING_LOAD_FULFILLED:
      return {
        ...state,
        onboardingSteps: action.payload.data.data,
        applicableForMigration: action.payload.data.applicable_for_migration,
        oldRatingMethods: action.payload.data.old_rating_methods,
        fetching: false,
      }

    case actions.PM_ONBOARDING_UPDATE_STEPS_FULFILLED:
      return {
        ...state,
        onboardingSteps: [action.payload.data.data.onboarding_step],
        fetching: false,
      }

    case actions.PM_ONBOARDING_COMPLETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.PM_ONBOARDING_LOAD_REJECTED:
    case actions.PM_ONBOARDING_UPDATE_STEPS_REJECTED:
    case actions.PM_ONBOARDING_COMPLETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
