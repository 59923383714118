export const GOSI_INTEGRATION_LIST_LOAD = 'GOSI_INTEGRATION_LIST_LOAD'
export const GOSI_INTEGRATION_LIST_LOAD_PENDING = 'GOSI_INTEGRATION_LIST_LOAD_PENDING'
export const GOSI_INTEGRATION_LIST_LOAD_FULFILLED = 'GOSI_INTEGRATION_LIST_LOAD_FULFILLED'
export const GOSI_INTEGRATION_LIST_LOAD_REJECTED = 'GOSI_INTEGRATION_LIST_LOAD_REJECTED'

export const GOSI_INTEGRATION_CR_CONNECT = 'GOSI_INTEGRATION_CR_CONNECT'
export const GOSI_INTEGRATION_CR_CONNECT_PENDING = 'GOSI_INTEGRATION_CR_CONNECT_PENDING'
export const GOSI_INTEGRATION_CR_CONNECT_FULFILLED = 'GOSI_INTEGRATION_CR_CONNECT_FULFILLED'
export const GOSI_INTEGRATION_CR_CONNECT_REJECTED = 'GOSI_INTEGRATION_CR_CONNECT_REJECTED'

export const GOSI_INTEGRATION_CR_DISCONNECT = 'GOSI_INTEGRATION_CR_DISCONNECT'
export const GOSI_INTEGRATION_CR_DISCONNECT_PENDING = 'GOSI_INTEGRATION_CR_DISCONNECT_PENDING'
export const GOSI_INTEGRATION_CR_DISCONNECT_FULFILLED = 'GOSI_INTEGRATION_CR_DISCONNECT_FULFILLED'
export const GOSI_INTEGRATION_CR_DISCONNECT_REJECTED = 'GOSI_INTEGRATION_CR_DISCONNECT_REJECTED'

export const GOSI_INTEGRATION_UPDATE_SALARY = 'GOSI_INTEGRATION_UPDATE_SALARY'
export const GOSI_INTEGRATION_UPDATE_SALARY_PENDING = 'GOSI_INTEGRATION_UPDATE_SALARY_PENDING'
export const GOSI_INTEGRATION_UPDATE_SALARY_FULFILLED = 'GOSI_INTEGRATION_UPDATE_SALARY_FULFILLED'
export const GOSI_INTEGRATION_UPDATE_SALARY_REJECTED = 'GOSI_INTEGRATION_UPDATE_SALARY_REJECTED'

export const GOSI_INTEGRATION_UPDATE_SALARY_DISMISS = 'GOSI_INTEGRATION_UPDATE_SALARY_DISMISS'
