import React from 'react'
import i18n from 'translations/i18n'
import { handleTicketTables } from './helper'
import Frame from '../../../ds/Frame'
import { useRequest } from '../../../RequestProvider'

const TicketInformation = () => {
  const {
    vars: { request },
  } = useRequest()
  return <Frame title={i18n.t('ticket_info')}>{handleTicketTables(request)}</Frame>
}

export default TicketInformation
