import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  loading: false,
  attendance_logs: [],
  employees_record: {
    days: [],
    attendance: [],
    pagination: {},
  },
  employee_record: {
    employee: {},
    attendance_records: [],
  },
  filters: {
    page: 1,
    rpp: 10,
    view_type: 'daily',
    search: '',
    date: new Date(),
    filter: {
      mol_registration_ids: [],
      department_ids: [],
      area_ids: [],
      job_title_ids: [],
      record_types: [],
      shift_types: [],
      location_ids: [],
      shift_ids: [],
      status_employee: [],
      include_exempted_employees: false,
    },
  },
  attendanceDetails: {},
  fetchingDetails: false,

  attendanceRecord: [],
  attendanceDays: [],
  attendanceRecords: {},
  overtime_logs: {
    attendance_record: {},
    overtime_logs: [],
    fetching: false,
  },
}

export default function timeAndAttendanceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        attendanceRecord: [],
        employees_record: {
          ...state.employees_record,
          attendance: [],
          days: [],
        },
        fetching: true,
      }

    case actions.TIME_AND_ATTENDANCE_LOAD_PENDING:
    case actions.TIME_AND_ATTENDANCE_RECORDS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_RECORD_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.OVERTIME_LOGS_LOAD_PENDING:
    case actions.DELETE_OVERTIME_LOG_PENDING:
    case actions.ADD_OVERTIME_LOG_PENDING:
      return {
        ...state,
        overtime_logs: {
          ...state.overtime_logs,
          fetching: true,
        },
      }

    case actions.OVERTIME_LOGS_LOAD_FULFILLED:
      return {
        ...state,
        overtime_logs: {
          ...action.payload.data.data,
          fetching: false,
        },
      }

    case actions.ATTENDANCE_RECORD_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        loading: false,
      }

    case actions.DELETE_OVERTIME_LOG_FULFILLED:
    case actions.ADD_OVERTIME_LOG_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        overtime_logs: {
          ...state.overtime_logs,
          fetching: false,
        },
      }

    case actions.OVERTIME_LOGS_LOAD_REJECTED:
    case actions.DELETE_OVERTIME_LOG_REJECTED:
    case actions.ADD_OVERTIME_LOG_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        overtime_logs: {
          ...state.overtime_logs,
          fetching: false,
        },
      }

    case actions.TIME_AND_ATTENDANCE_LOAD_FULFILLED:
      return {
        ...state,
        employees_record: {
          ...action.payload.data.data,
        },
        attendanceRecord: [...action.payload.data.data.attendance],
        attendanceDays: [...action.payload.data.data.days],
        fetching: false,
        fetchingDetails: false,
      }

    case actions.TIME_AND_ATTENDANCE_DETAILS_PENDING:
      return {
        ...state,
        fetchingDetails: true,
      }

    case actions.TIME_AND_ATTENDANCE_DETAILS_FULFILLED:
      return {
        ...state,
        attendanceDetails: {
          ...action.payload.data.data.details,
        },
        fetchingDetails: false,
        fetching: false,
      }

    // Time and attendance records
    case actions.TIME_AND_ATTENDANCE_RECORDS_FULFILLED:
      return {
        ...state,
        employee_record: action.payload.data.data,
        fetching: false,
      }

    case actions.TIME_AND_ATTENDANCE_LOAD_REJECTED:
    case actions.TIME_AND_ATTENDANCE_RECORDS_REJECTED:
    case actions.ATTENDANCE_RECORD_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        loading: false,
      }

    case actions.SET_ATTENDANCE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }
    case actions.CLEAR_ATTENDANCE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          filter: initialState.filters.filter,
        },
      }

    default:
      return state
  }
}
