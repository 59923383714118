const firebaseConfig = new URLSearchParams({
  apiKey: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FQSV9LRVk,
  authDomain: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FVVEhfRE9NQUlO,
  databaseURL: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0RBVEFCQVNFX1VSTAo,
  projectId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX1BST0pFQ1RfSUQK,
  storageBucket: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX1NUT1JBR0VfQlVDS0VU,
  messagingSenderId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX01FU1NBR0lOR19TRU5ERVJfSUQK,
  appId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FQUF9JRA,
  measurementId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX01FQVNVUkVNRU5UX0lECg,
}).toString()

export function register(): void {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${window.location.origin}/firebase-messaging-sw.js?${firebaseConfig}`
      navigator.serviceWorker.register(swUrl).catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Service worker registration failed:', e)
      })
    })
  }
}

export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message)
      })
  }
}
