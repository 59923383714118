/*
  Heading: React Component that using in make a title

  usage: use this component for make a title.
  @props:
    - title: <String>, the text that will display.
    - as: <String>, html tag name.
    - style: <Object>, customaize the style.
    - className: <String>, add aditional class.
*/
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Heading = ({
  title,
  as,
  style,
  className,
  color,
  fontSize: fontsize,
  size,
  inline,
  onClick,
  typeface,
  bold,
  disabled,
  testId,
}) => {
  const Tag = as
  return (
    <Tag
      onClick={onClick}
      style={{
        fontSize: fontsize,
        color,
        ...style,
      }}
      data-testid={testId}
      className={classNames(
        'jisr-heading',
        size && `jisr-heading--${size}`,
        typeface && `jisr-heading--typeface-${typeface}`,
        inline && 'jisr-heading--inline',
        bold && 'jisr-heading--bold',
        disabled && 'jisr-heading--disabled',
        className,
      )}
    >
      {title}
    </Tag>
  )
}

Heading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.shape(),
  className: PropTypes.string,
  typeface: PropTypes.string,
  testId: PropTypes.string,
  as: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

Heading.defaultProps = {
  as: 'h2',
}
export default Heading
