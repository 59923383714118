import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type BankAccount = {
  bank_id: number
  bank_logo: string
  bank_name: string
  iban: string
  id: number
  is_direct_bank_integrated: boolean
  mudad_supported: boolean
}

type CRType = {
  bank_accounts: BankAccount[]
  id: number
  mol_registration: {
    name: string
    gosi_name_ar: string
    gosi_name_en: string
    gosi_number: string
    id: number
    mol_name_ar: string
    mol_name_en: string
    mol_number: string
    mol_prefix: string
    mol_registration_number: string
  }
  name: string
  name_ar: string
  name_en: string
  name_i18n: string

  registration_id: number
  registration_number: string
}

export type BankType = {
  bank_account_id: number
  commercial_registration: CRType
  mudad_bank_id: number
  payment_method: 'mudad' | 'offline'
}

type RequestsState = {
  bank_auth_permission: boolean
  bank_authorizations: BankType[]
  fetching: boolean
  loading: boolean
  offboard_steps_restrictions: {
    has_contract: boolean
    is_workflow_step_approver: boolean
    is_tasks_assignee: boolean
    pending_tasks_count: number
    count_attendance_reviewer_paygroups: number
    subordinates_count: number
    area_manager_count: number
    location_manager_count: number
    department_manager_count: number
    business_unit_manager_count: number
    manged_groups_count: number
    assigned_pending_requests_count: number
    is_bank_authorizer: boolean
    is_attendance_reviewer: boolean
    has_active_insurance_policy: boolean
  }
}

const initialState: RequestsState = {
  fetching: false,
  bank_auth_permission: false,
  offboard_steps_restrictions: {
    has_contract: false,
    is_workflow_step_approver: false,
    is_tasks_assignee: false,
    pending_tasks_count: 0,
    subordinates_count: 0,
    count_attendance_reviewer_paygroups: 0,
    area_manager_count: 0,
    location_manager_count: 0,
    department_manager_count: 0,
    business_unit_manager_count: 0,
    manged_groups_count: 0,
    assigned_pending_requests_count: 0,
    is_bank_authorizer: false,
    is_attendance_reviewer: false,
    has_active_insurance_policy: false,
  },
  bank_authorizations: [],
  loading: false,
}

export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.OFFBOARDING_DYNAMIC_STEPS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.OFFBOARDING_DYNAMIC_STEPS_LOAD_FULFILLED:
      return {
        ...state,
        offboard_steps_restrictions: payload.data.data,
        fetching: false,
      }
    case actions.OFFBOARDING_DYNAMIC_STEPS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    case actions.OFFBOARDING_BANK_AUTHORIZATION_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.OFFBOARDING_BANK_AUTHORIZATION_LOAD_FULFILLED:
      return {
        ...state,
        bank_authorizations: payload.data.data?.bank_authorizations,
        bank_auth_permission: payload.data.data?.has_permission,
        loading: false,
      }
    case actions.OFFBOARDING_BANK_AUTHORIZATION_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
