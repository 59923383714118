import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import PopoverMui from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'

import Styles from './Popover.module.css'

const useStyles = makeStyles(() => ({
  root: {
    // overflow: 'auto',
  },
  paper: {
    overflow: 'visible',
  },
}))

const Popover = ({ children, trigger, hovering, onClose, testId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  let timer
  const handleClick = (event) => {
    const target = event.currentTarget
    clearTimeout(timer)
    const ms = hovering ? 600 : 0
    timer = setTimeout(() => {
      setAnchorEl(target)
    }, ms)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }

  const handleOnMouseLeave = () => {
    clearTimeout(timer)
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <div className={Styles.popoverCover}>
      {React.cloneElement(trigger, {
        onClick: handleClick,
        onMouseLeave: () => clearTimeout(timer),
        ...(hovering && { onMouseEnter: handleClick }),
      })}

      <PopoverMui
        data-testid={testId}
        className={classnames(Styles.popoverContainer)}
        classes={classes}
        open={open}
        anchorEl={anchorEl}
        anchorReference={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {hovering && (
          <span
            onMouseLeave={handleOnMouseLeave}
            className={Styles.mouseTrigger}
          />
        )}

        <div
          className={classnames(Styles.popoverBox)}
          onMouseLeave={hovering && handleClose}
        >
          {children}
        </div>
      </PopoverMui>
    </div>
  )
}

Popover.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.node,
  hovering: PropTypes.bool,
  onClose: PropTypes.func,
  testId: PropTypes.string,
}

Popover.defaultProps = {
  onClose: () => {},
  testId: 'ds-popover',
}

export default Popover
