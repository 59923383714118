import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  value: true,
  payrun_configuration: {},
  fetching: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GL_SETUP_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GL_SETUP_LOAD_FULFILLED:
      return {
        ...state,
        value: action.payload.data.data.payrun_configuration.value === 'done',
        fetching: false,
      }

    case actions.GL_SETUP_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.GL_SETUP_CREATE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GL_SETUP_CREATE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.GL_SETUP_CREATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
