import { Suspense } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'utils/hooks'
import AuthorisedRoutes from 'routes/authorised/AuthorisedRoutes'
import OnboardingEmployee from 'containers/authorised/OnboardingEmployee'
import NavigationLoader from './global/Loaders/NavigationLoader'
import LoaderComponent from './LoaderComponent'
import NewNavigation from './NewNavigation'
import OnboardingEmpNavigation from './OnboardingEmpNavigation'

type NavigationHandlerProps = {
  isOnboardingEmp?: boolean
}

const NavigationHandler = ({ isOnboardingEmp }: NavigationHandlerProps): JSX.Element => {
  const currentUser = useSelector((s) => s.auth.employee)
  if (isEmpty(currentUser)) {
    return <NavigationLoader />
  }

  return isOnboardingEmp ? (
    <OnboardingEmpNavigation>
      <Suspense fallback={<LoaderComponent />}>
        <OnboardingEmployee />
      </Suspense>
    </OnboardingEmpNavigation>
  ) : (
    <NewNavigation>
      <Suspense fallback={<LoaderComponent />}>
        <AuthorisedRoutes />
      </Suspense>
    </NewNavigation>
  )
}

export default NavigationHandler
