import { FC, useState } from 'react'
import i18n from 'translations/i18n'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import { Select } from '@jisr-hr/ds-beta'
import { Typography, Modal } from '@jisr-hr/ds'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'utils/hooks'
import { usePaymentMethod } from '../../components/usePaymentMethods'

const AddNewPaymentConfigModal: FC = () => {
  const paymentCtx = usePaymentMethod()
  const { addNewConfigOpen } = paymentCtx

  const unlinked_list = useSelector((s) => s.paymentMethods.pending_payment_methods, shallowEqual)
  const fetching = useSelector((s) => s.paymentMethods.fetchingShowMethod)
  const [selectedCR, selectCR] = useState<PaymentConfigurationType | null>(null)

  const handleClose = (): void => {
    paymentCtx.setAddNewConfigOpen(false)
    selectCR(null)
  }

  const commercial_registrations_options = unlinked_list.reduce(
    (CRs: PaymentConfigurationType[], currentCR): PaymentConfigurationType[] => {
      CRs.push({
        ...currentCR,
        name: `${currentCR.name} - ${currentCR.registration_number}`,
      })
      return CRs
    },
    [],
  )
  return (
    <div>
      <Modal
        open={addNewConfigOpen}
        header={{ title: i18n.t('commercial_registration'), withClose: true }}
        onClose={handleClose}
        size="medium"
        footer={{
          submit: {
            label: i18n.t('start_setup_payment_method'),
            disabled: fetching || !selectedCR,
            onClick: (): void => {
              paymentCtx.handleSetupMethodOpen(selectedCR)
            },
          },
          cancel: { label: i18n.t('cancel'), onClick: handleClose },
        }}
      >
        <div style={{ padding: '24px 0' }}>
          <Typography
            variant="interface/default/sm"
            style={{ marginBottom: 32 }}
            text={i18n.t('choose_the_commercial_registration')}
          />
          <Select
            label={i18n.t('your_commercial_registration')}
            onChange={(value): void => selectCR(value)}
            value={selectedCR}
            placeholder={i18n.t('commercial_registration')}
            labelKey="name"
            valueKey="id"
            options={commercial_registrations_options}
          />
        </div>
      </Modal>
    </div>
  )
}

export default AddNewPaymentConfigModal
