export const MUQEEM_LOGIN_LOAD = 'MUQEEM_LOGIN_LOAD'
export const MUQEEM_LOGIN_LOAD_PENDING = 'MUQEEM_LOGIN_LOAD_PENDING'
export const MUQEEM_LOGIN_LOAD_FULFILLED = 'MUQEEM_LOGIN_LOAD_FULFILLED'
export const MUQEEM_LOGIN_LOAD_REJECTED = 'MUQEEM_LOGIN_LOAD_REJECTED'

export const GET_MUQEEM_EMPLOYEES_LOAD = 'GET_MUQEEM_EMPLOYEES_LOAD'
export const GET_MUQEEM_EMPLOYEES_LOAD_PENDING = 'GET_MUQEEM_EMPLOYEES_LOAD_PENDING'
export const GET_MUQEEM_EMPLOYEES_LOAD_FULFILLED = 'GET_MUQEEM_EMPLOYEES_LOAD_FULFILLED'
export const GET_MUQEEM_EMPLOYEES_LOAD_REJECTED = 'GET_MUQEEM_EMPLOYEES_LOAD_REJECTED'

export const GET_MUQEEM_VISAS = 'GET_MUQEEM_VISAS'
export const GET_MUQEEM_VISAS_PENDING = 'GET_MUQEEM_VISAS_PENDING'
export const GET_MUQEEM_VISAS_FULFILLED = 'GET_MUQEEM_VISAS_FULFILLED'
export const GET_MUQEEM_VISAS_REJECTED = 'GET_MUQEEM_VISAS_REJECTED'

export const GET_MUQEEM_LAST_VISAS = 'GET_MUQEEM_LAST_VISAS'
export const GET_MUQEEM_LAST_VISAS_PENDING = 'GET_MUQEEM_LAST_VISAS_PENDING'
export const GET_MUQEEM_LAST_VISAS_FULFILLED = 'GET_MUQEEM_LAST_VISAS_FULFILLED'
export const GET_MUQEEM_LAST_VISAS_REJECTED = 'GET_MUQEEM_LAST_VISAS_REJECTED'

export const GET_MUQEEM_REPORT = 'GET_MUQEEM_REPORT'
export const GET_MUQEEM_REPORT_PENDING = 'GET_MUQEEM_REPORT_PENDING'
export const GET_MUQEEM_REPORT_FULFILLED = 'GET_MUQEEM_REPORT_FULFILLED'
export const GET_MUQEEM_REPORT_REJECTED = 'GET_MUQEEM_REPORT_REJECTED'

export const GET_MUQEEM_OPERATION_REPORT = 'GET_MUQEEM_OPERATION_REPORT'
export const GET_MUQEEM_OPERATION_REPORT_PENDING = 'GET_MUQEEM_OPERATION_REPORT_PENDING'
export const GET_MUQEEM_OPERATION_REPORT_FULFILLED = 'GET_MUQEEM_OPERATION_REPORT_FULFILLED'
export const GET_MUQEEM_OPERATION_REPORT_REJECTED = 'GET_MUQEEM_OPERATION_REPORT_REJECTED'

export const MUQEEM_RENEW_IQAMA = 'MUQEEM_RENEW_IQAMA'
export const MUQEEM_RENEW_IQAMA_PENDING = 'MUQEEM_RENEW_IQAMA_PENDING'
export const MUQEEM_RENEW_IQAMA_FULFILLED = 'MUQEEM_RENEW_IQAMA_FULFILLED'
export const MUQEEM_RENEW_IQAMA_REJECTED = 'MUQEEM_RENEW_IQAMA_REJECTED'

export const MUQEEM_ISSUE_IQAMA = 'MUQEEM_ISSUE_IQAMA'
export const MUQEEM_ISSUE_IQAMA_PENDING = 'MUQEEM_ISSUE_IQAMA_PENDING'
export const MUQEEM_ISSUE_IQAMA_FULFILLED = 'MUQEEM_ISSUE_IQAMA_FULFILLED'
export const MUQEEM_ISSUE_IQAMA_REJECTED = 'MUQEEM_ISSUE_IQAMA_REJECTED'

export const MUQEEM_TRANSFER_IQAMA = 'MUQEEM_TRANSFER_IQAMA'
export const MUQEEM_TRANSFER_IQAMA_PENDING = 'MUQEEM_TRANSFER_IQAMA_PENDING'
export const MUQEEM_TRANSFER_IQAMA_FULFILLED = 'MUQEEM_TRANSFER_IQAMA_FULFILLED'
export const MUQEEM_TRANSFER_IQAMA_REJECTED = 'MUQEEM_TRANSFER_IQAMA_REJECTED'

export const MUQEEM_RENEW_PASSPORT = 'MUQEEM_RENEW_PASSPORT'
export const MUQEEM_RENEW_PASSPORT_PENDING = 'MUQEEM_RENEW_PASSPORT_PENDING'
export const MUQEEM_RENEW_PASSPORT_FULFILLED = 'MUQEEM_RENEW_PASSPORT_FULFILLED'
export const MUQEEM_RENEW_PASSPORT_REJECTED = 'MUQEEM_RENEW_PASSPORT_REJECTED'

export const MUQEEM_EXTEND_PASSPORT = 'MUQEEM_EXTEND_PASSPORT'
export const MUQEEM_EXTEND_PASSPORT_PENDING = 'MUQEEM_EXTEND_PASSPORT_PENDING'
export const MUQEEM_EXTEND_PASSPORT_FULFILLED = 'MUQEEM_EXTEND_PASSPORT_FULFILLED'
export const MUQEEM_EXTEND_PASSPORT_REJECTED = 'MUQEEM_EXTEND_PASSPORT_REJECTED'

export const MUQEEM_EXIT_REENTRY_VISA_ISSUE = 'MUQEEM_EXIT_REENTRY_VISA_ISSUE'
export const MUQEEM_EXIT_REENTRY_VISA_ISSUE_PENDING = 'MUQEEM_EXIT_REENTRY_VISA_ISSUE_PENDING'
export const MUQEEM_EXIT_REENTRY_VISA_ISSUE_FULFILLED = 'MUQEEM_EXIT_REENTRY_VISA_ISSUE_FULFILLED'
export const MUQEEM_EXIT_REENTRY_VISA_ISSUE_REJECTED = 'MUQEEM_EXIT_REENTRY_VISA_ISSUE_REJECTED'

export const MUQEEM_EXIT_REENTRY_VISA_EXTEND = 'MUQEEM_EXIT_REENTRY_VISA_EXTEND'
export const MUQEEM_EXIT_REENTRY_VISA_EXTEND_PENDING = 'MUQEEM_EXIT_REENTRY_VISA_EXTEND_PENDING'
export const MUQEEM_EXIT_REENTRY_VISA_EXTEND_FULFILLED = 'MUQEEM_EXIT_REENTRY_VISA_EXTEND_FULFILLED'
export const MUQEEM_EXIT_REENTRY_VISA_EXTEND_REJECTED = 'MUQEEM_EXIT_REENTRY_VISA_EXTEND_REJECTED'

export const MUQEEM_EXIT_REENTRY_VISA_CANCEL = 'MUQEEM_EXIT_REENTRY_VISA_CANCEL'
export const MUQEEM_EXIT_REENTRY_VISA_CANCEL_PENDING = 'MUQEEM_EXIT_REENTRY_VISA_CANCEL_PENDING'
export const MUQEEM_EXIT_REENTRY_VISA_CANCEL_FULFILLED = 'MUQEEM_EXIT_REENTRY_VISA_CANCEL_FULFILLED'
export const MUQEEM_EXIT_REENTRY_VISA_CANCEL_REJECTED = 'MUQEEM_EXIT_REENTRY_VISA_CANCEL_REJECTED'

export const MUQEEM_EXIT_REENTRY_VISA_PRINT = 'MUQEEM_EXIT_REENTRY_VISA_PRINT'
export const MUQEEM_EXIT_REENTRY_VISA_PRINT_PENDING = 'MUQEEM_EXIT_REENTRY_VISA_PRINT_PENDING'
export const MUQEEM_EXIT_REENTRY_VISA_PRINT_FULFILLED = 'MUQEEM_EXIT_REENTRY_VISA_PRINT_FULFILLED'
export const MUQEEM_EXIT_REENTRY_VISA_PRINT_REJECTED = 'MUQEEM_EXIT_REENTRY_VISA_PRINT_REJECTED'

export const MUQEEM_FINAL_EXIT_VISA_ISSUE = 'MUQEEM_FINAL_EXIT_VISA_ISSUE'
export const MUQEEM_FINAL_EXIT_VISA_ISSUE_PENDING = 'MUQEEM_FINAL_EXIT_VISA_ISSUE_PENDING'
export const MUQEEM_FINAL_EXIT_VISA_ISSUE_FULFILLED = 'MUQEEM_FINAL_EXIT_VISA_ISSUE_FULFILLED'
export const MUQEEM_FINAL_EXIT_VISA_ISSUE_REJECTED = 'MUQEEM_FINAL_EXIT_VISA_ISSUE_REJECTED'

export const MUQEEM_FINAL_EXIT_VISA_CANCEL = 'MUQEEM_FINAL_EXIT_VISA_CANCEL'
export const MUQEEM_FINAL_EXIT_VISA_CANCEL_PENDING = 'MUQEEM_FINAL_EXIT_VISA_CANCEL_PENDING'
export const MUQEEM_FINAL_EXIT_VISA_CANCEL_FULFILLED = 'MUQEEM_FINAL_EXIT_VISA_CANCEL_FULFILLED'
export const MUQEEM_FINAL_EXIT_VISA_CANCEL_REJECTED = 'MUQEEM_FINAL_EXIT_VISA_CANCEL_REJECTED'

export const MUQEEM_LOOKUP = 'MUQEEM_LOOKUP'
export const MUQEEM_LOOKUP_PENDING = 'MUQEEM_LOOKUP_PENDING'
export const MUQEEM_LOOKUP_FULFILLED = 'MUQEEM_LOOKUP_FULFILLED'
export const MUQEEM_LOOKUP_REJECTED = 'MUQEEM_LOOKUP_REJECTED'

export const MUQEEM_LOOKUP_COUNTRIES = 'MUQEEM_LOOKUP_COUNTRIES'
export const MUQEEM_LOOKUP_COUNTRIES_PENDING = 'MUQEEM_LOOKUP_COUNTRIES_PENDING'
export const MUQEEM_LOOKUP_COUNTRIES_FULFILLED = 'MUQEEM_LOOKUP_COUNTRIES_FULFILLED'
export const MUQEEM_LOOKUP_COUNTRIES_REJECTED = 'MUQEEM_LOOKUP_COUNTRIES_REJECTED'

export const MUQEEM_LOOKUP_MARITAL_STATUSES = 'MUQEEM_LOOKUP_MARITAL_STATUSES'
export const MUQEEM_LOOKUP_MARITAL_STATUSES_PENDING = 'MUQEEM_LOOKUP_MARITAL_STATUSES_PENDING'
export const MUQEEM_LOOKUP_MARITAL_STATUSES_FULFILLED = 'MUQEEM_LOOKUP_MARITAL_STATUSES_FULFILLED'
export const MUQEEM_LOOKUP_MARITAL_STATUSES_REJECTED = 'MUQEEM_LOOKUP_MARITAL_STATUSES_REJECTED'

export const RESET_MUQEEMS_SERVICE = 'RESET_MUQEEMS_SERVICE'
