export const CUSTOM_REPORTS_LOAD = 'CUSTOM_REPORTS_LOAD'
export const CUSTOM_REPORTS_LOAD_PENDING = 'CUSTOM_REPORTS_LOAD_PENDING'
export const CUSTOM_REPORTS_LOAD_FULFILLED = 'CUSTOM_REPORTS_LOAD_FULFILLED'
export const CUSTOM_REPORTS_LOAD_REJECTED = 'CUSTOM_REPORTS_LOAD_REJECTED'

export const CUSTOM_REPORTS_EXPORT = 'CUSTOM_REPORTS_EXPORT'
export const CUSTOM_REPORTS_EXPORT_PENDING = 'CUSTOM_REPORTS_EXPORT_PENDING'
export const CUSTOM_REPORTS_EXPORT_FULFILLED = 'CUSTOM_REPORTS_EXPORT_FULFILLED'
export const CUSTOM_REPORTS_EXPORT_REJECTED = 'CUSTOM_REPORTS_EXPORT_REJECTED'

export const CUSTOM_REPORTS_CONSTANTS_LOAD = 'CUSTOM_REPORTS_CONSTANTS_LOAD'
export const CUSTOM_REPORTS_CONSTANTS_LOAD_PENDING = 'CUSTOM_REPORTS_CONSTANTS_LOAD_PENDING'
export const CUSTOM_REPORTS_CONSTANTS_LOAD_FULFILLED = 'CUSTOM_REPORTS_CONSTANTS_LOAD_FULFILLED'
export const CUSTOM_REPORTS_CONSTANTS_LOAD_REJECTED = 'CUSTOM_REPORTS_CONSTANTS_LOAD_REJECTED'

export const CUSTOM_REPORTS_SHAW = 'CUSTOM_REPORTS_SHAW'
export const CUSTOM_REPORTS_SHAW_PENDING = 'CUSTOM_REPORTS_SHAW_PENDING'
export const CUSTOM_REPORTS_SHAW_FULFILLED = 'CUSTOM_REPORTS_SHAW_FULFILLED'
export const CUSTOM_REPORTS_SHAW_REJECTED = 'CUSTOM_REPORTS_SHAW_REJECTED'

export const CUSTOM_REPORTS_CREATE = 'CUSTOM_REPORTS_CREATE'
export const CUSTOM_REPORTS_CREATE_PENDING = 'CUSTOM_REPORTS_CREATE_PENDING'
export const CUSTOM_REPORTS_CREATE_FULFILLED = 'CUSTOM_REPORTS_CREATE_FULFILLED'
export const CUSTOM_REPORTS_CREATE_REJECTED = 'CUSTOM_REPORTS_CREATE_REJECTED'

export const CUSTOM_REPORTS_DELETE = 'CUSTOM_REPORTS_DELETE'
export const CUSTOM_REPORTS_DELETE_PENDING = 'CUSTOM_REPORTS_DELETE_PENDING'
export const CUSTOM_REPORTS_DELETE_FULFILLED = 'CUSTOM_REPORTS_DELETE_FULFILLED'
export const CUSTOM_REPORTS_DELETE_REJECTED = 'CUSTOM_REPORTS_DELETE_REJECTED'

export const CUSTOM_REPORTS_UPDATE = 'CUSTOM_REPORTS_UPDATE'
export const CUSTOM_REPORTS_UPDATE_PENDING = 'CUSTOM_REPORTS_UPDATE_PENDING'
export const CUSTOM_REPORTS_UPDATE_FULFILLED = 'CUSTOM_REPORTS_UPDATE_FULFILLED'
export const CUSTOM_REPORTS_UPDATE_REJECTED = 'CUSTOM_REPORTS_UPDATE_REJECTED'

export const CUSTOM_REPORTS_GENERATE = 'CUSTOM_REPORTS_GENERATE'
export const CUSTOM_REPORTS_GENERATE_PENDING = 'CUSTOM_REPORTS_GENERATE_PENDING'
export const CUSTOM_REPORTS_GENERATE_FULFILLED = 'CUSTOM_REPORTS_GENERATE_FULFILLED'
export const CUSTOM_REPORTS_GENERATE_REJECTED = 'CUSTOM_REPORTS_GENERATE_REJECTED'

export const SET_SELECTED_REPORT_ID = 'SET_SELECTED_REPORT_ID'
