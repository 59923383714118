const Elevatus = {
  // E
  elevatus: 'Elevatus',
  elevatus_integration_card_note:
    'Seamlessly manage employee data and streamline the recruitment process.',
  elevatus_activation: 'Elevatus Activation',
  elevatus_how_to_title: 'How to set up an account-level integration with {{app_name}} HR:',
  elevatus_step_1: ' In the sidebar of your Elevatus dashboard, click on the "Connections" page.',
  elevatus_step_2:
    'On the Connections page, find the "{{app_name}} HR Integration" card and click the “Connect” button to enable the integration.',
  elevatus_step_3:
    'When prompted, supply an Organization Slug <copyButton/> and an <a>API Key.<a/>',
  elevatus_step_4: 'Click Save, and it will report a success message if everything is working.',
  elevatus_step_5:
    'Finally, you will be prompted to Synchronize the {{app_name}} Lookups, click Confirm, and the integration is fully set up.',
  elevatus_note_org_slug:
    'The Organization Slug is a unique identifier for your organization in {{app_name}} HR. You can set it up through your {{app_name}} HR account dashboard or by contacting {{app_name}} HR support.',
  elevatus_note_api_token:
    'The API Key is a secret token that allows your integration to access your {{app_name}} HR account data. You can generate a new API key in your {{app_name}} HR account settings or by contacting {{app_name}} HR support.',
  elevatus_note_help_contact:
    "Once you've supplied the Organization Slug and API Key, your integration should be set up and ready to use. For more information, you can contact either Elevatus or {{app_name}} HR support.",
  enable_elevatus: 'Enable Elevatus',
  disable_elevatus: 'Disable Elevatus',
}

export default Elevatus
