import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'

export type ConfigType<T> = {
  id: number
  name: string
  value: T
}

type IsAbsenceDeductionApplicableType = {
  id: number
  organization_id: number
  deducted_from: string
  penalty: unknown | null
  is_default: boolean
  is_active: boolean
}

export type ConfigWithBenefitType = (ConfigType<number> & { benefit_type_ids: number[] }) | null

type ConfigurationsWithBenefitType = Record<'absence_calculation', ConfigWithBenefitType>

// type BooleanConfigs = Record<'show_decimal_points', ConfigType<boolean> | null>

type InitStateType = {
  is_absence_deduction_applicable: IsAbsenceDeductionApplicableType | null
  loan_limit: ConfigType<Record<string, unknown>> | null
  absence_calculation_fetching: boolean
  fetching: boolean
  loading: boolean
  errMsg: string | null
  succMsg: string | null
} & ConfigurationsWithBenefitType

const DEFAULT_STATE: InitStateType = {
  absence_calculation: null,
  is_absence_deduction_applicable: null,
  loan_limit: null,
  fetching: false,
  loading: false,
  absence_calculation_fetching: false,
  errMsg: null,
  succMsg: null,
}

let changes = null
export default function configurationReducer(state = DEFAULT_STATE, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case 'ABSENCE_CALCULATION_UPDATE_PENDING':
    case 'ABSENCE_CALCULATION_LOAD_PENDING':
      return {
        ...state,
        absence_calculation_fetching: true,
      }

    case 'SHOW_DECIMAL_POINTS_UPDAT_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: true,
      }
    case 'IS_ABSENCE_DEDUCTION_APPLICABLE_UPDATE_PENDING':
      return { ...state, fetching: true }

    case 'ABSENCE_CALCULATION_LOAD_REJECTED':
    case 'ABSENCE_CALCULATION_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
        delay_calculation_fetching: false,
        absence_calculation_fetching: false,
      }
      return { ...state, ...changes }

    case 'IS_ABSENCE_DEDUCTION_APPLICABLE_LOAD_FULFILLED':
    case 'IS_ABSENCE_DEDUCTION_APPLICABLE_UPDATE_FULFILLED':
      changes = {
        is_absence_deduction_applicable: payload.data.data.absence_deduction_policy,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'ABSENCE_CALCULATION_LOAD_FULFILLED':
    case 'ABSENCE_CALCULATION_UPDATE_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        absence_calculation: payload.data.data.payrun_configuration,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
        absence_calculation_fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
