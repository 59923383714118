import { ReactNode } from 'react'
import clsx from 'classnames'
import styles from './styles.module.css'

type WidgetProps = {
  children: ReactNode
  className?: string
  fetching?: boolean
  height?: string
  style?: Record<string, unknown>
}

const Widget = ({ children, fetching, height, style, className, ...rest }: WidgetProps) => (
  <div
    className={clsx(styles.widget, className)}
    style={{ minHeight: height, ...style }}
    {...rest}
  >
    {fetching && <div className={styles.fetching} />}
    {children}
  </div>
)

export default Widget
