import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Badge } from '@jisr-hr/ds'

import { statusTypes } from 'utils/uiHelpers'

import Record from '../Record'

const statusIcon = {
  Incomplete: 'alert-circle',
}

const Status = ({ data }) => (
  <Record
    title={I18n.t('status')}
    label={<StatusBadge value={data.from} />}
    value={<StatusBadge value={data.to} />}
  />
)

const StatusBadge = ({ value }) => (
  <Badge
    label={value}
    size="small"
    color={statusTypes[value?.toLowerCase()] || 'gray'}
    variant="tinted"
    leadingIcon={statusIcon[value]}
  />
)
StatusBadge.propTypes = {
  value: PropTypes.string,
}

Status.propTypes = {
  data: PropTypes.shape(),
}

export default Status
