export const GL_MAPPING_EMPLOYEES_LOAD = 'GL_MAPPING_EMPLOYEES_LOAD'
export const GL_MAPPING_EMPLOYEES_LOAD_PENDING = 'GL_MAPPING_EMPLOYEES_LOAD_PENDING'
export const GL_MAPPING_EMPLOYEES_LOAD_FULFILLED = 'GL_MAPPING_EMPLOYEES_LOAD_FULFILLED'
export const GL_MAPPING_EMPLOYEES_LOAD_REJECTED = 'GL_MAPPING_EMPLOYEES_LOAD_REJECTED'

export const GL_MAPPING_EMPLOYEES_FILTER_LOAD = 'GL_MAPPING_EMPLOYEES_FILTER_LOAD'
export const GL_MAPPING_EMPLOYEES_FILTER_LOAD_PENDING = 'GL_MAPPING_EMPLOYEES_FILTER_LOAD_PENDING'
export const GL_MAPPING_EMPLOYEES_FILTER_LOAD_FULFILLED =
  'GL_MAPPING_EMPLOYEES_FILTER_LOAD_FULFILLED'
export const GL_MAPPING_EMPLOYEES_FILTER_LOAD_REJECTED = 'GL_MAPPING_EMPLOYEES_FILTER_LOAD_REJECTED'

export const NETSUITE_EMPLOYEES_LOAD = 'NETSUITE_EMPLOYEES_LOAD'
export const NETSUITE_EMPLOYEES_LOAD_PENDING = 'NETSUITE_EMPLOYEES_LOAD_PENDING'
export const NETSUITE_EMPLOYEES_LOAD_FULFILLED = 'NETSUITE_EMPLOYEES_LOAD_FULFILLED'
export const NETSUITE_EMPLOYEES_LOAD_REJECTED = 'NETSUITE_EMPLOYEES_LOAD_REJECTED'

export const ACCOUNTING_BULK_SYNC_EMPLOYEE = 'ACCOUNTING_BULK_SYNC_EMPLOYEE'
export const ACCOUNTING_BULK_SYNC_EMPLOYEE_PENDING = 'ACCOUNTING_BULK_SYNC_EMPLOYEE_PENDING'
export const ACCOUNTING_BULK_SYNC_EMPLOYEE_FULFILLED = 'ACCOUNTING_BULK_SYNC_EMPLOYEE_FULFILLED'
export const ACCOUNTING_BULK_SYNC_EMPLOYEE_REJECTED = 'ACCOUNTING_BULK_SYNC_EMPLOYEE_REJECTED'
