import { Dispatch, SetStateAction, useState } from 'react'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Button, Spacer, Flex, Modal } from '@jisr-hr/ds'
import { CheckboxField, Form, TextInputField } from 'components/final-form'
import I18n from 'translations/i18n'
import { required } from 'components/global/form/FormValidations'
import { ReactComponent as InfoIcon } from '@jisr-hr/design-system/dist/assets/icons/info-blue.svg'
import { LeaveType, LeavesFormDataTypes } from 'redux/leavesOnboarding/leaveTypes/type'
import BalanceChangeWarning from 'containers/authorised/setting/v2/LeaveManagement/LeaveTypes/components/BalanceChangeWarning'
import ConfirmResetLaborLaw from 'containers/authorised/setting/v2/LeaveManagement/LeaveTypes/components/ConfirmResetLaborLaw'
import styles from './style.module.css'

type FormTypes = {
  fetching: boolean
  initialValues: { leave_types?: LeaveType }
  isEmpty: boolean
  isOpen: boolean
  oddDefCustomLeaves: string[]
  onClose: Dispatch<SetStateAction<boolean>>
  onSubmit: (val: { leave_types: LeavesFormDataTypes }) => void
}

const FormLeavesTypes = ({
  onClose,
  initialValues,
  onSubmit,
  fetching,
  isEmpty,
  oddDefCustomLeaves,
  isOpen,
}: FormTypes): JSX.Element => {
  const [confirmReset, setConfirmReset] = useState(false)

  const disableResetBth = (values: { leave_types: LeavesFormDataTypes }) => {
    const hasDefaultDaysPerYear =
      (initialValues?.leave_types?.default_values?.days_per_year ?? 0) > 0

    const selectedVals = JSON.stringify({
      ...(hasDefaultDaysPerYear && { days_per_year: +(values.leave_types?.days_per_year ?? 0) }),
      extend_probation: values.leave_types?.extend_probation,
      is_attachment_required: !!values.leave_types?.is_attachment_required,
    })

    const defaultVals = JSON.stringify({
      ...(hasDefaultDaysPerYear && {
        days_per_year: initialValues?.leave_types?.default_values?.days_per_year,
      }),
      extend_probation: initialValues?.leave_types?.default_values?.extend_probation,
      is_attachment_required: initialValues?.leave_types?.default_values?.is_attachment_required,
    })

    return selectedVals === defaultVals
  }

  return (
    <Modal
      open={isOpen}
      onClose={(): void => onClose(false)}
      header={{
        title: isEmpty ? I18n.t('add_new_custom_leave') : I18n.t('update_custom_leave_onboarding'),
        withClose: true,
      }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, values, form }): JSX.Element => (
          <div className={styles.contentModal}>
            {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('leave_name_onboardin')}
              name="leave_types.name"
              placeholder={I18n.t('leave_name_onboardin')}
              validate={required}
              autoFocus={!initialValues.leave_types?.id}
              disabled={
                initialValues.leave_types?.is_default ||
                oddDefCustomLeaves.includes(initialValues.leave_types?.name ?? '')
              }
            />
            <Spacer height="8px" />
            {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('leave_name_ar_onboardin')}
              name="leave_types.name_ar"
              placeholder={I18n.t('leave_name_ar_onboardin')}
              validate={required}
              disabled={
                initialValues.leave_types?.is_default ||
                oddDefCustomLeaves.includes(initialValues.leave_types?.name ?? '')
              }
            />
            <Spacer height="8px" />
            {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('dayse_per_year')}
              name="leave_types.days_per_year"
              placeholder={I18n.t('dayse_per_year')}
              validate={required}
            />
            {initialValues.leave_types?.is_default && (
              <>
                <Spacer height="8px" />
                <BalanceChangeWarning
                  defaultBalance={initialValues.leave_types?.default_values?.days_per_year ?? 0}
                  currentBalance={+(values.leave_types?.days_per_year ?? 0)}
                />
              </>
            )}

            {['Sick'].includes(initialValues.leave_types?.name ?? '') && (
              <>
                <Spacer height="8px" />
                <Flex
                  justifyBetween
                  itemsCenter
                >
                  <CheckboxField
                    name="leave_types.extend_probation"
                    label={I18n.t('extend_for_probation')}
                  />
                  <Tooltip
                    description={I18n.t('activate_this_option')}
                    placement="top"
                    trigger={<InfoIcon />}
                  />
                </Flex>
              </>
            )}
            {initialValues.leave_types?.is_default && (
              <>
                <Spacer height={16} />
                <CheckboxField
                  name="leave_types.is_attachment_required"
                  label={I18n.t('label.adding_attachment_mandatory')}
                />
              </>
            )}
            <Spacer height="16px" />
            <div className="flex justify-end gap-2">
              {initialValues.leave_types?.is_default && (
                <Button
                  size="medium"
                  color="neutral"
                  label={I18n.t('cta.reset_to_defualt')}
                  variant="outlined"
                  type="button"
                  onClick={() => setConfirmReset(true)}
                  disabled={disableResetBth(values)}
                />
              )}
              <Button
                label={isEmpty ? I18n.t('add') : I18n.t('edit')}
                disabled={fetching || pristine}
                onClick={(): void => {
                  handleSubmit()
                }}
                size="medium"
                style={{ minWidth: 110, justifyContent: 'center' }}
              />
            </div>
            <ConfirmResetLaborLaw
              isOpen={confirmReset}
              leaveType={initialValues.leave_types?.name_i18n ?? ''}
              onClose={() => setConfirmReset(false)}
              onConfirm={() => {
                if ((initialValues.leave_types?.default_values?.days_per_year ?? 0) > 0) {
                  form.change(
                    // ? using any due to final form issue with nested keys
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    'leave_types.days_per_year' as any,
                    initialValues.leave_types?.default_values?.days_per_year,
                  )
                }
                form.change(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  'leave_types.extend_probation' as any,
                  initialValues.leave_types?.default_values?.extend_probation,
                )
                form.change(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  'leave_types.is_attachment_required' as any,
                  initialValues.leave_types?.default_values?.is_attachment_required,
                )

                setConfirmReset(false)
              }}
            />
          </div>
        )}
      />
    </Modal>
  )
}

export default FormLeavesTypes
