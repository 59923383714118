import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type EnrollmentEligibilityTypes = {
  filterKey: string
  filters: string[]
}

const EnrollmentEligibility = (props: EnrollmentEligibilityTypes): JSX.Element => {
  const { enrollment_eligibility_data } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(enrollment_eligibility_data)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (typeof val === 'boolean') {
      handleFilter('is_eligible_to_enroll', [val])
    } else {
      handleFilter('is_eligible_to_enroll', [])
    }
  }

  const checkSelectedIds = (): string[] => {
    if (fltr && typeof fltr[0] === 'boolean') {
      return fltr as string[]
    }
    return ['all']
  }

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={checkSelectedIds() as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
      title={i18n.t('label.policy_eligibility')}
    />
  )
}

export default EnrollmentEligibility
