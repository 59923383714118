import I18n from 'translations/i18n'
import Sidebar from '../components/Sidebar'
import CountingDays from './steps/CountingDays'
import ApplyingLeaves from './steps/ApplyingLeaves'
import CarryForward from './steps/CarryForward'
import Holidays from './steps/Holidays'
import LeavesTypes from './steps/LeavesTypes'
import CustomizingLeaves from './steps/CustomizingLeaves'
import ReviewLeaveDetails from './steps/ReviewLeaveDetails'
import ImportLeaveDetails from './steps/ImportLeaveDetails'
import { SetupStepTypes } from '../types/onboardingTypes'

export const leaveSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    sectionTitle: I18n.t('leaves_setup'),
    title: 'annual_leave_onboarding',
    component: CountingDays,
    sidebar: (
      <Sidebar
        videoKey="annual_leave_onboarding"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'starting_balance',
    component: ApplyingLeaves,
    sidebar: (
      <Sidebar
        videoKey="starting_balance"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'remaining_balance_onboarding',
    component: CarryForward,
    sidebar: (
      <Sidebar
        videoKey="remaining_balance_onboarding"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 4,
    title: 'labor_law_leaves',
    component: LeavesTypes,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 5,
    title: 'customizing_leaves',
    component: CustomizingLeaves,
    sidebar: (
      <Sidebar
        videoKey="customizing_leaves"
        infoSet={[
          {
            title: I18n.t('return_confirmation'),
            description: I18n.t('return_confirmation_description_tip', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 6,
    title: 'public_holidays',
    component: Holidays,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 7,
    sectionTitle: I18n.t('employees_data'),
    title: 'upload_annual_leave_files',
    component: ImportLeaveDetails,
    sidebar: (
      <Sidebar
        videoKey="upload_annual_leave_files"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 8,
    title: 'review_page',
    component: ReviewLeaveDetails,
    sidebar: <Sidebar infoSet={[]} />,
  },
]
