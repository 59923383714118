import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  isUpdating: false,
  pagination: { page: 1 },
  data: [],
}

export default function employeeTasksReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_TASKS_RESET:
      return {
        ...state,
        data: [],
      }

    case actions.EMPLOYEE_TASKS_LOAD_PENDING:
    case actions.EMPLOYEE_SECTION_TASKS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEE_TASKS_LOAD_FULFILLED:
    case actions.EMPLOYEE_SECTION_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        pagination: payload.data.data.pagination,
        data: state.data.concat(payload.data.data.tasks),
        total: payload.data.data.total_tasks,
        fetching: false,
      }

    case actions.EMPLOYEE_TASKS_LOAD_REJECTED:
    case actions.EMPLOYEE_SECTION_TASKS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.EMPLOYEE_TASKS_PUT_UPDATE_PENDING:
    case actions.EMPLOYEE_SECTION_TASKS_PUT_UPDATE_PENDING:
      return {
        ...state,
        isUpdating: true,
      }

    case actions.EPMLOYEE_TASKS_REASSIGN_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
      }

    case actions.EMPLOYEE_TASKS_PUT_UPDATE_FULFILLED:
    case actions.EMPLOYEE_SECTION_TASKS_PUT_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      const updatedTask = payload.data.data.task
      return {
        ...state,
        isUpdating: false,
        data: state.data.map((task) => (task.id === updatedTask.id ? updatedTask : task)),
      }
    }

    case actions.EMPLOYEE_TASKS_PUT_UPDATE_REJECTED:
    case actions.EMPLOYEE_SECTION_TASKS_PUT_UPDATE_REJECTED:
    case actions.EPMLOYEE_TASKS_REASSIGN_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        isUpdating: false,
      }

    default:
      return state
  }
}
