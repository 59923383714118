import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  leaving_information: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  firstStepDone: false,
  financeDone: false,
  pending_requests: [],
  vacation_settlement: {},
  salary_settlement: { elements: [], total_salary_settlement: 0 },
  loan_settlement: { loans: [] },
  gosi_settlement: {},
  retroactive_settlement: {},
  final_net_settlement: {},
  eos_settlement: {
    working_experience: '',
    reason_of_termination: '',
    eos_award: '',
  },
  exit_interview_questions_answers: [],
  isEosCreated: false,
  isConfirmed: false,
}

let changes = null
export default function leavingInfoReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'LEAVING_INFO_LOAD_PENDING':
    case 'LEAVING_INFO_UPDATE_PENDING':
    case 'EOS_SETTLEMENT_LOAD_PENDING':
    case 'FINAL_SETTLEMENT_LOAD_PENDING':
    case 'EMPLOYEE_EOS_FINAL_SETTLEMENT_LOAD_PENDING':
    case 'EMPLOYEE_EOS_SETTLEMENT_CREATE_PENDING':
    case 'VACATION_SETTLEMENT_LOAD_PENDING':
    case 'EMPLOYEES_PENDING_REQUESTS_PUT_PENDING':
    case 'EMPLOYEES_ORGANIZATION_STRUCTURE_SUBORDINATES_PUT_PENDING':
    case 'SALARY_SETTLEMENT_LOAD_PENDING':
    case 'EMPLOYEE_EOS_CONFIRM_CREATE_PENDING':
    case 'SEND_TO_FINANCE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'LEAVING_INFO_LOAD_REJECTED':
    case 'EOS_SETTLEMENT_LOAD_REJECTED':
    case 'FINAL_SETTLEMENT_LOAD_REJECTED':
    case 'EMPLOYEE_EOS_FINAL_SETTLEMENT_LOAD_REJECTED':
    case 'EMPLOYEE_EOS_SETTLEMENT_CREATE_REJECTED':
    case 'VACATION_SETTLEMENT_LOAD_REJECTED':
    case 'EMPLOYEES_PENDING_REQUESTS_PUT_REJECTED':
    case 'EMPLOYEES_ORGANIZATION_STRUCTURE_SUBORDINATES_PUT_REJECTED':
    case 'SALARY_SETTLEMENT_LOAD_REJECTED':
    case 'SEND_TO_FINANCE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case 'EMPLOYEE_EOS_CONFIRM_CREATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case 'LEAVING_INFO_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LEAVING_INFO_LOAD_FULFILLED':
    case 'FINAL_SETTLEMENT_LOAD_FULFILLED':
    case 'EOS_SETTLEMENT_LOAD_FULFILLED':
    case 'EXIT_INTERVIEW_QUESTIONS_LOAD_FULFILLED':
    case 'EMPLOYEE_EOS_FINAL_SETTLEMENT_LOAD_FULFILLED':
    case 'VACATION_SETTLEMENT_LOAD_FULFILLED':
    case 'EMPLOYEE_PENDING_REQUESTS_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        // errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_PENDING_REQUESTS_PUT_FULFILLED':
    case 'EMPLOYEES_ORGANIZATION_STRUCTURE_SUBORDINATES_PUT_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SALARY_SETTLEMENT_LOAD_FULFILLED':
      changes = {
        salary_settlement: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOAN_SETTLEMENT_LOAD_FULFILLED':
      changes = {
        loan_settlement: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'GOSI_SETTLEMENT_LOAD_FULFILLED':
      changes = {
        gosi_settlement: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'RETROACTIVE_SETTLEMENT_LOAD_FULFILLED':
      changes = {
        retroactive_settlement: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_EOS_SETTLEMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        isEosCreated: true,
      }
      return { ...state, ...changes }

    case 'LEAVING_INFO_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        firstStepDone: true,
        fetching: false,
        editing: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_EOS_SETTLEMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        editing: false,
      }
      return { ...state, ...changes }
    case 'EMPLOYEE_EOS_CONFIRM_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        isConfirmed: true,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'SEND_TO_FINANCE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        financeDone: true,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
