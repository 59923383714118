import { ReactNode, useMemo } from 'react'
import { Form, TextInputField, SelectField } from 'components/final-form'
import { Table } from '@jisr-hr/design-system'
import lodash from 'lodash'
import I18n from 'translations/i18n'
import { ReactComponent as CheckIcon } from 'assets/images/icon-check-002.svg'
import { ReactComponent as Deletecon } from 'assets/images/icon-delete-003.svg'
import { required } from 'components/global/form/FormValidations'
import {
  onBoardingPaymentDetailsUpdate,
  onBoardingPaymentDetailsLoad,
} from 'redux/payrollOnboarding/paymentReview/actionsCreators'
import { useOnBoardingBanksSelector } from 'redux/payrollOnboarding/paymentReview/selectors'
import { useDispatch } from 'utils/hooks'
import { ValidationErrors } from 'final-form'
import { TDataSubmit, TPaymentDetails } from 'redux/payrollOnboarding/paymentReview/type'
import { Typography, Flex } from '@jisr-hr/ds'

const paymentList = [
  { label: I18n.t('bank'), value: 'Bank' },
  { label: I18n.t('cheque'), value: 'Cheque' },
  { label: I18n.t('cash'), value: 'Cash' },
  { label: I18n.t('international_transfer'), value: 'International Transfer' },
]

const dataFormShape = {
  salary_type: '',
  bank_id: '',
  bank_name: '',
  iban: '',
  swift_code: '',
  country: '',
}

type TFormTable = {
  item: TPaymentDetails
  handleClose: () => void
}

const FormTable = ({ handleClose, item }: TFormTable): JSX.Element => {
  const dispatch = useDispatch()
  const { banks } = useOnBoardingBanksSelector()

  const initialValues = useMemo<TDataSubmit>(
    () => ({
      payment_type: item?.payment_type,
      bank_detail: {
        salary_type: item?.salary_type,
        bank_id: item?.bank_id,
        bank_name: item?.bank_name,
        iban: item?.iban,
        swift_code: item?.swift_code,
        country: item?.country,
        account_number: item?.account_number,
      },
    }),
    [],
  )

  const handleValidation = (
    v: TDataSubmit,
  ): ValidationErrors | Promise<ValidationErrors> | undefined => {
    const isTransferMethod = v.payment_type === 'International Transfer'
    const formSchema = {
      bank_detail: {
        salary_type: v?.payment_type === 'bank' ? required(v.bank_detail?.salary_type) : null,
        bank_name: isTransferMethod ? required(v.bank_detail?.bank_name) : null,
        country: isTransferMethod ? required(v.bank_detail?.country) : null,
        iban:
          isTransferMethod || v.bank_detail?.salary_type === 'Bank Account'
            ? required(v.bank_detail?.iban)
            : null,
        account_number:
          v.bank_detail?.salary_type === 'Salary Card'
            ? required(v.bank_detail?.account_number)
            : null,
      },
    }
    const omitNilVals = lodash.omitBy(formSchema.bank_detail, lodash.isNil)
    if (lodash.isEmpty(omitNilVals)) return undefined
    return { bank_detail: omitNilVals }
  }

  const onSubmit = (values: TDataSubmit): void => {
    if (item?.id) {
      dispatch(onBoardingPaymentDetailsUpdate(item?.id, values)).then(() => {
        dispatch(onBoardingPaymentDetailsLoad())
        handleClose()
      })
    }
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Flex>
          <Flex flexCol>
            <Typography
              variant="subtitle-2"
              text={item?.name_i18n}
            />
            <Typography
              variant="subtitle-2"
              text={item?.code}
            />
          </Flex>
        </Flex>
      </Table.Cell>
      <Table.Cell>{item?.employment_type || '-'}</Table.Cell>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={handleValidation}
      >
        {({ handleSubmit, values, form }): ReactNode => (
          <>
            <Table.Cell>
              <SelectField
                // @ts-expect-error Need to migrate SelectField.js to TypeScript
                name="payment_type"
                valueKey="value"
                labelKey="label"
                options={paymentList}
                style={{ width: 130, marginTop: -5 }}
                onChange={(v: string): void => {
                  if (['Cheque', 'Cash'].includes(v)) {
                    form.change('bank_detail', dataFormShape)
                  } else if (v === 'International Transfer') {
                    form.change('bank_detail.salary_type' as keyof TDataSubmit, '')
                  }
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <SelectField
                // @ts-expect-error Need to migrate SelectField.js to TypeScript
                name="bank_detail.salary_type"
                valueKey="value"
                labelKey="label"
                options={[
                  { label: I18n.t('bank_account'), value: 'Bank Account' },
                  { label: I18n.t('salary_card'), value: 'Salary Card' },
                ]}
                onChange={(val: string): void => {
                  form.change('bank_detail.bank_name' as keyof TDataSubmit, '')
                  if (val === 'Bank Account') {
                    form.change('bank_detail.bank_id' as keyof TDataSubmit, '')
                    form.change('bank_detail.account_number' as keyof TDataSubmit, '')
                  } else if (val === 'Salary Card') {
                    form.change('bank_detail.iban' as keyof TDataSubmit, '')
                  }
                }}
                style={{ width: 130, marginTop: -5 }}
                disabled={!(values?.payment_type === 'Bank')}
              />
            </Table.Cell>
            <Table.Cell>
              {values?.payment_type === 'Bank' ? (
                <SelectField
                  // @ts-expect-error Need to migrate SelectField.js to TypeScript
                  name="bank_detail.bank_id"
                  valueKey="id"
                  labelKey="name_i18n"
                  options={banks}
                  maxMenuHeight={190}
                  style={{ width: 190, marginTop: -5 }}
                  disabled={!(values?.bank_detail.salary_type === 'Salary Card')}
                />
              ) : (
                // @ts-expect-error need to migrate TextInputField.jsx to TS
                <TextInputField
                  name="bank_detail.bank_name"
                  disabled={!(values?.payment_type === 'International Transfer')}
                  style={{ width: 190 }}
                />
              )}
            </Table.Cell>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="bank_detail.iban"
                style={{ width: 150 }}
                disabled={
                  !(
                    ['International Transfer'].includes(values?.payment_type) ||
                    values?.bank_detail.salary_type === 'Bank Account'
                  )
                }
              />
            </Table.Cell>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="bank_detail.account_number"
                disabled={!(values?.bank_detail.salary_type === 'Salary Card')}
                style={{ width: 150 }}
              />
            </Table.Cell>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="bank_detail.swift_code"
                disabled={!(values?.payment_type === 'International Transfer')}
                style={{ width: 150 }}
              />
            </Table.Cell>
            <Table.Cell>
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="bank_detail.country"
                disabled={!(values?.payment_type === 'International Transfer')}
                style={{ width: 150 }}
              />
            </Table.Cell>

            <Table.Cell>
              <Flex style={{ gap: 10 }}>
                <CheckIcon
                  onClick={handleSubmit}
                  style={{ cursor: 'pointer' }}
                />
                <Deletecon
                  onClick={handleClose}
                  style={{ cursor: 'pointer' }}
                />
              </Flex>
            </Table.Cell>
          </>
        )}
      </Form>
    </Table.Row>
  )
}

export default FormTable
