const Analysis = {
  // A
  all_filters: 'All Organization',
  attendance_statistics: 'Attendance Statistics',
  assets_statistics: 'Assets Statistics',
  // B
  // C
  // D
  // E
  employees_statistics: 'Employees Statistics',
  export_title_data: 'Export {{title}} data',
  // F
  finance_analysis: 'Finance',
  // G
  // H
  // I
  // J
  // K
  // L
  // M
  // N
  // O
  // P
  payroll_statistics: 'Payroll Statistics',
  // Q
  // R
  // S
  statistics: 'Statistics',
  search_by_keywords: 'Search by keywords…',
  reports_statistics: 'Reports & Statistics',
  // T
  title_breakdown: '{{title}} breakdown',
  there_is_no_data: 'There is no data in the selected period, select a different period',
  // U
  // V
  // W
  // X
  xls_analysis: 'Download Excel',
  // Y
  year_analysis: 'Year',
  // Z
}

export default Analysis
