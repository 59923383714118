import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import * as actions from './actions'
import { GeofencingLocation, GeofencingType } from './geofencingTypes'

const DEFAULT_STATE: GeofencingType = {
  locations: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  pagination: {},
  location: {},
  locationsNotLinked: [],
}

let changes = null
export default function geofencingReducer(state = DEFAULT_STATE, action: Action): GeofencingType {
  const { payload } = action
  let geofencing = []
  switch (action.type) {
    case actions.RESET_GEOFENCY_DETAIL:
      return {
        ...state,
        fetching: true,
        location: {},
      }

    case actions.GEOFENCING_LOAD_PENDING:
    case actions.GEOFENCE_UPDATE_PENDING:
    case actions.GEOFENCE_DELETE_PENDING:
    case actions.GEOFENCING_NOT_LINKED_LOAD:
    case actions.GEOFENCY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case actions.GEOFENCING_LOAD_REJECTED:
    case actions.GEOFENCE_UPDATE_REJECTED:
    case actions.GEOFENCE_DELETE_REJECTED:
    case actions.GEOFENCY_LOAD_REJECTED:
    case actions.GEOFENCING_NOT_LINKED_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.GEOFENCY_LOAD_FULFILLED:
      changes = {
        location: payload.data.data.location,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.GEOFENCING_LOAD_FULFILLED:
      const geofencingLoad: Pick<GeofencingType, 'locations' | 'pagination'> = payload.data.data
      changes = {
        ...geofencingLoad,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.GEOFENCING_NOT_LINKED_LOAD_FULFILLED:
      changes = {
        locationsNotLinked: payload.data.data.locations,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.GEOFENCE_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      geofencing = [...state.locations]
      const updatedRecord: GeofencingLocation = payload.data.data.location
      changes = {
        fetching: false,
        locations: updateOneListOfRecords(geofencing, updatedRecord),

        editing: {
          ...state.editing,
          [updatedRecord?.id]: false,
        },
      }
      return { ...state, ...changes }

    case actions.GEOFENCE_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      geofencing = [...state.locations]
      return {
        ...state,
        locations: removeOneListOfRecords(geofencing, payload.id),
        error: null,
      }

    default:
      return state
  }
}
