const Assets = {
  // A
  add_asset: 'اضف العهدة',
  all_images_and_pdf: 'SVG، PNG، JPG، GIF أو PDF',
  allocate: 'إسناد',
  allocate_asset: 'إسناد عُهدة',
  asset_allocation_task: 'مهمة إسناد العُهدة',
  allocation_date: 'تاريخ الاستلام',
  allocation_details: 'تفاصيل إسناد العُهدة',
  allocation_task_disclaimer:
    'قبل الضغط على " إكمال المراجعة " يتوجب عليك إسناد العهد المطلوبة إلى الموظف عن طريق الذهاب إلى ملف الموظف الشخصي (تبويب العهد) أو من صفحة إدارة العهد، لكي تضاف إلى ملفه الشخصي',
  archive: 'مؤرشفة',
  archived: 'مؤرشفة',
  asset_added_successfully: 'تمت اضافة العهدة',
  available_assets: 'العُهد المتاحة',
  // B
  brand: 'العلامة التجارية',
  bulk_import_for_assets: 'رفع بيانات العُهد',
  // C
  change_the_status_to_be_available_to_be_able_to_allocate:
    'حالة العهدة في الصيانة، يجب أن تكون حالتها متاحة لإسنادها لموظف',
  // D
  damaged: 'تالفة',
  deallocate: 'إخلاء',
  deallocate_asset: 'إخلاء العٌهدة',
  asset_deallocation_task: 'مهمة إخلاء العُهدة',
  deallocation_date: 'تاريخ الإخلاء',
  deallocation_details: 'تفاصيل إخلاء العٌهدة',
  deallocation_task_disclaimer:
    'قبل الضغط على " إكمال المراجعة " يتوجب عليك إخلاء العهد المطلوبة من الموظف عن طريق الذهاب إلى ملف الموظف الشخصي (تبويب العهد) أو من صفحة إدارة العهد، لكي تنعكس على ملفه الشخصي',
  delete_allocated_asset_desc: '.العهدة مسندة لموظف، يجب إخلاء العهدة أولاً ثم حذفها',
  delete_asset: 'حذف العهدة',
  delete_asset_desc:
    'عند حذف [{{asset}}] سوف تفقد جميع سجلات العهدة، ولن تستطيع استرجاعها أو استخدامها نهائيًا',
  // E
  // F
  // G
  go_to_assets: 'الانتقال إلى العٌهد',
  // H
  history_logs: 'سجل العهدة',
  // I
  inrepair: 'في الصيانة',
  // J
  // K
  // L
  // M
  mark_as_available: 'متاحة',
  mark_as_damaged: 'تالفة',
  mark_as_inrepair: 'في الصيانة',
  model_name_ar: 'موديل (الاسم بالعربي)',
  model_name_en: 'موديل (الاسم بالإنجليزي)',
  // N
  new_asset: 'عٌهدة جديدة',
  no_assets_available: 'لا توجد عُهد متاحة لهذا النوع <b>({{name}})</b>',
  not_now_later: 'لاحقًا',
  // O
  // P
  purchase_cost: 'تكلفة الشراء',
  purchase_date: 'تاريخ الشراء',
  purchase_invoice_or_any_other_relevant_document: 'فاتورة الشراء، أو اي مرفق يخص العهدة',
  // Q
  // R
  // S
  // T
  // U
  unallocation_date: 'Unallocation Date:AR',
  // V
  view_asset: 'عرض العهدة',
  // W
  warranty_end_date: 'تاريخ إنتهاء الضمان',
  would_you_like_to_allocate_this_asset_to_an_employee: 'هل تود إسناد العهدة لموظف ؟',
  // X
  // Y
  you_dont_have_assets: 'You don’t have assets',

  // Z
}

export default Assets
