import { useEffect } from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'utils/hooks'
import { PendingChangesType } from 'containers/authorised/employee/EmployeeDetails/Tabs/Personal/components/types'
import { updateInfoChangeRequest } from 'redux/employees/editProfile/actionCreator'
import { InfoChangeRequestForm, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const InfoChangeRequest = (): JSX.Element => {
  const dispatch = useDispatch()
  const { setOnSubmit, setInitialValues, editRequest, setEditRequest } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = request?.employee?.id

  const onSubmit = async ({
    id,
    ...changeset
  }: PendingChangesType['changeset'] & { id: number }): Promise<void> => {
    await dispatch(
      updateInfoChangeRequest(empId, id, {
        changeset,
        info_change_request_type: request?.metadata?.info_change_request_type,
      }),
    )
    setEditRequest(false)
  }

  useEffect(() => {
    if (editRequest) {
      setInitialValues({ id: request.id, ...request.changeset })
    }
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(undefined)
      setInitialValues({})
    }
  }, [editRequest])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={request?.employee} />
      <InfoChangeRequestForm />
    </Flex>
  )
}

export default InfoChangeRequest
