export const ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD = 'ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD'
export const ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_PENDING =
  'ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_PENDING'
export const ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_FULFILLED =
  'ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_FULFILLED'
export const ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_REJECTED =
  'ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_REJECTED'

export const UPLOAD_ONBOARDING_GOSI_FILE = 'UPLOAD_ONBOARDING_GOSI_FILE'
export const UPLOAD_ONBOARDING_GOSI_FILE_PENDING = 'UPLOAD_ONBOARDING_GOSI_FILE_PENDING'
export const UPLOAD_ONBOARDING_GOSI_FILE_FULFILLED = 'UPLOAD_ONBOARDING_GOSI_FILE_FULFILLED'
export const UPLOAD_ONBOARDING_GOSI_FILE_REJECTED = 'UPLOAD_ONBOARDING_GOSI_FILE_REJECTED'

export const EMPLOYEES_BULK_DELETE = 'EMPLOYEES_BULK_DELETE'
export const EMPLOYEES_BULK_DELETE_PENDING = 'EMPLOYEES_BULK_DELETE_PENDING'
export const EMPLOYEES_BULK_DELETE_FULFILLED = 'EMPLOYEES_BULK_DELETE_FULFILLED'
export const EMPLOYEES_BULK_DELETE_REJECTED = 'EMPLOYEES_BULK_DELETE_REJECTED'

export const RESET_ALL_EMPLOYEES_EXPECT_ADMINS = 'RESET_ALL_EMPLOYEES_EXPECT_ADMINS'
export const RESET_ALL_EMPLOYEES_EXPECT_ADMINS_PENDING = 'RESET_ALL_EMPLOYEES_EXPECT_ADMINS_PENDING'
export const RESET_ALL_EMPLOYEES_EXPECT_ADMINS_FULFILLED =
  'RESET_ALL_EMPLOYEES_EXPECT_ADMINS_FULFILLED'
export const RESET_ALL_EMPLOYEES_EXPECT_ADMINS_REJECTED =
  'RESET_ALL_EMPLOYEES_EXPECT_ADMINS_REJECTED'

export const EXPORT_ONBOARDING_ORGANIZATION_DATA = 'EXPORT_ONBOARDING_ORGANIZATION_DATA'
export const EXPORT_ONBOARDING_ORGANIZATION_DATA_PENDING =
  'EXPORT_ONBOARDING_ORGANIZATION_DATA_PENDING'
export const EXPORT_ONBOARDING_ORGANIZATION_DATA_FULFILLED =
  'EXPORT_ONBOARDING_ORGANIZATION_DATA_FULFILLED'
export const EXPORT_ONBOARDING_ORGANIZATION_DATA_REJECTED =
  'EXPORT_ONBOARDING_ORGANIZATION_DATA_REJECTED'

export const ONBOARDING_BULK_UPLOAD_CATEGORIES_RESET = 'ONBOARDING_BULK_UPLOAD_CATEGORIES_RESET'
