export const ONBOARDING_INDUSTRIRIES_LOAD = 'ONBOARDING_INDUSTRIRIES_LOAD'
export const ONBOARDING_INDUSTRIRIES_LOAD_PENDING = 'ONBOARDING_INDUSTRIRIES_LOAD_PENDING'
export const ONBOARDING_INDUSTRIRIES_LOAD_FULFILLED = 'ONBOARDING_INDUSTRIRIES_LOAD_FULFILLED'
export const ONBOARDING_INDUSTRIRIES_LOAD_REJECTED = 'ONBOARDING_INDUSTRIRIES_LOAD_REJECTED'

export const ONBOARDING_PROVIDERS_LOAD = 'ONBOARDING_PROVIDERS_LOAD'
export const ONBOARDING_PROVIDERS_LOAD_PENDING = 'ONBOARDING_PROVIDERS_LOAD_PENDING'
export const ONBOARDING_PROVIDERS_LOAD_FULFILLED = 'ONBOARDING_PROVIDERS_LOAD_FULFILLED'
export const ONBOARDING_PROVIDERS_LOAD_REJECTED = 'ONBOARDING_PROVIDERS_LOAD_REJECTED'

export const ONBOARDING_PROVIDERS_POST = 'ONBOARDING_PROVIDERS_POST'
export const ONBOARDING_PROVIDERS_POST_PENDING = 'ONBOARDING_PROVIDERS_POST_PENDING'
export const ONBOARDING_PROVIDERS_POST_FULFILLED = 'ONBOARDING_PROVIDERS_POST_FULFILLED'
export const ONBOARDING_PROVIDERS_POST_REJECTED = 'ONBOARDING_PROVIDERS_POST_REJECTED'
