export const ONBOARDING_LOAN_TYPES_LOAD = 'ONBOARDING_LOAN_TYPES_LOAD'
export const ONBOARDING_LOAN_TYPES_LOAD_PENDING = 'ONBOARDING_LOAN_TYPES_LOAD_PENDING'
export const ONBOARDING_LOAN_TYPES_LOAD_FULFILLED = 'ONBOARDING_LOAN_TYPES_LOAD_FULFILLED'
export const ONBOARDING_LOAN_TYPES_LOAD_REJECTED = 'ONBOARDING_LOAN_TYPES_LOAD_REJECTED'

export const ONBOARDING_LOAN_TYPE_CREATE = 'ONBOARDING_LOAN_TYPE_CREATE'
export const ONBOARDING_LOAN_TYPE_CREATE_PENDING = 'ONBOARDING_LOAN_TYPE_CREATE_PENDING'
export const ONBOARDING_LOAN_TYPE_CREATE_FULFILLED = 'ONBOARDING_LOAN_TYPE_CREATE_FULFILLED'
export const ONBOARDING_LOAN_TYPE_CREATE_REJECTED = 'ONBOARDING_LOAN_TYPE_CREATE_REJECTED'

export const ONBOARDING_LOAN_TYPE_UPDATE = 'ONBOARDING_LOAN_TYPE_UPDATE'
export const ONBOARDING_LOAN_TYPE_UPDATE_PENDING = 'ONBOARDING_LOAN_TYPE_UPDATE_PENDING'
export const ONBOARDING_LOAN_TYPE_UPDATE_FULFILLED = 'ONBOARDING_LOAN_TYPE_UPDATE_FULFILLED'
export const ONBOARDING_LOAN_TYPE_UPDATE_REJECTED = 'ONBOARDING_LOAN_TYPE_UPDATE_REJECTED'

export const ONBOARDING_LOAN_TYPE_DELETE = 'ONBOARDING_LOAN_TYPE_DELETE'
export const ONBOARDING_LOAN_TYPE_DELETE_PENDING = 'ONBOARDING_LOAN_TYPE_DELETE_PENDING'
export const ONBOARDING_LOAN_TYPE_DELETE_FULFILLED = 'ONBOARDING_LOAN_TYPE_DELETE_FULFILLED'
export const ONBOARDING_LOAN_TYPE_DELETE_REJECTED = 'ONBOARDING_LOAN_TYPE_DELETE_REJECTED'

export const ONBOARDING_LOAN_LIMIT_BULK_UPDATE = 'ONBOARDING_LOAN_LIMIT_BULK_UPDATE'
export const ONBOARDING_LOAN_LIMIT_BULK_UPDATE_PENDING = 'ONBOARDING_LOAN_LIMIT_BULK_UPDATE_PENDING'
export const ONBOARDING_LOAN_LIMIT_BULK_UPDATE_FULFILLED =
  'ONBOARDING_LOAN_LIMIT_BULK_UPDATE_FULFILLED'
export const ONBOARDING_LOAN_LIMIT_BULK_UPDATE_REJECTED =
  'ONBOARDING_LOAN_LIMIT_BULK_UPDATE_REJECTED'

export const ONBOARDING_LOAN_LIMIT_LOAD = 'ONBOARDING_LOAN_LIMIT_LOAD'
export const ONBOARDING_LOAN_LIMIT_LOAD_PENDING = 'ONBOARDING_LOAN_LIMIT_LOAD_PENDING'
export const ONBOARDING_LOAN_LIMIT_LOAD_FULFILLED = 'ONBOARDING_LOAN_LIMIT_LOAD_FULFILLED'
export const ONBOARDING_LOAN_LIMIT_LOAD_REJECTED = 'ONBOARDING_LOAN_LIMIT_LOAD_REJECTED'

export const ONBOARDING_LOAN_FULL_OR_BASIC_LOAD = 'ONBOARDING_LOAN_FULL_OR_BASIC_LOAD'
export const ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_PENDING =
  'ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_PENDING'
export const ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_FULFILLED =
  'ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_FULFILLED'
export const ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_REJECTED =
  'ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_REJECTED'
