import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query'
import { api } from 'apis/axiosInstance'
import { CHATBOT_URL } from 'env'
import { HTTPValidationError } from 'types/httpTypes'

// types
type sendJisriResponseFeedbackParams = {
  data: {
    message_id: string | undefined
    feedback_signal: string
  }
}

type sendJisriResponseFeedbackResponseType = {
  status: string
}

// function
const sendJisriResponseFeedback = ({
  data,
}: sendJisriResponseFeedbackParams): Promise<sendJisriResponseFeedbackResponseType> => {
  return api.post(`${CHATBOT_URL}/api/v1/submit_response_feedback`, data)
}

/**
 * @endpoint: POST: /api/v1/submit_response_feedback
 * @summary This hook used send feedback of Jisri chatbot response
 */
export const useSendJisrResponseFeedback = (opts?: {
  mutation?: UseMutationOptions<
    sendJisriResponseFeedbackResponseType,
    HTTPValidationError,
    sendJisriResponseFeedbackParams
  >
}): UseMutationResult<
  sendJisriResponseFeedbackResponseType,
  HTTPValidationError,
  sendJisriResponseFeedbackParams
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => sendJisriResponseFeedback(payload),
    onError: (error, variables, context) => {
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
