import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { removeObject } from 'components/global/HelperFunctions'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Grid } from 'components/global/atoms'
import { Button, Radio, Flex, Typography, Checkbox, Spacer } from '@jisr-hr/ds'

const Header = ({ title }) => (
  <div className="customize--columns-header">
    <Typography
      text={I18n.t(title)}
      variant="title-1"
      style={{ margin: 0 }}
    />
  </div>
)

Header.propTypes = {
  title: PropTypes.string,
}

const Footer = ({ onSubmit }) => (
  <Flex
    className="customize--columns-submitting_box"
    justifyEnd
  >
    <Button
      onClick={onSubmit}
      label={I18n.t('apply')}
      size="medium"
      color="primary"
      variant="filled"
      testId="customize-columns-submitting-btn"
    />
  </Flex>
)

Footer.propTypes = {
  onSubmit: PropTypes.func,
}

const CustomizeColumns = ({
  trigger,
  btnClassName,
  wrapperClassName,
  btnIcon,
  title,
  data,
  placement,
  hasSubmitting,
  onChange,
  noOfColumns,
  defaultSelected,
  modalTitle,
  hideSelectAll,
  style,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selected, setSelected] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const placment = 'bottom-end'

  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget)

  const submitSelected = (val) => {
    setSelected(val)
    if (!hasSubmitting && onChange) onChange(val)
  }

  const handleChecked = (option, checked, isParent) => {
    if (checked) {
      if (isParent) return submitSelected([...selected, option.label, option.subList[0]])
      return submitSelected([...selected, option])
    }
    if (isParent) {
      const currentVal = selected.find((ite) => option.subList.includes(ite))
      return submitSelected(removeObject(removeObject(selected, option.label), currentVal))
    }
    return submitSelected(removeObject(selected, option))
  }

  function handleSelectAll() {
    if (allChecked) {
      setAllChecked(false)
      submitSelected([])
      return
    }
    setAllChecked(true)
    const checkSection = (section) =>
      section &&
      section.list &&
      Array.isArray(section.list) &&
      section.list.map((option) => {
        if (typeof option !== 'object') return option
        if (Object.keys(option).includes('value')) return option.value
        return [option.label, option.subList[0]]
      })
    const newData = Array.isArray(data)
      ? data.map((section) => checkSection(section))
      : checkSection(data)
    submitSelected([...newData.flat()])
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  useEffect(() => {
    setSelected(defaultSelected)
  }, [defaultSelected.length])

  const renderSubList = (option) => {
    if (!Array.isArray(option.subList) || !selected.includes(option.label)) return null
    const currentVal = selected.find((ite) => option.subList.includes(ite))
    const newList = removeObject(selected, currentVal)

    return option.subList.map((item, i) => (
      <Radio
        key={i}
        active={currentVal === item}
        onChange={() => setSelected([...newList, item])}
        label={I18n.t(item)}
        name="cols-custom-sub-cols"
        value={item}
      />
    ))
  }

  const renderSection = (section, index) => (
    <div key={index}>
      {section && section.title && (
        <div className="customize--columns-header">
          <Typography
            text={I18n.t(section.title)}
            variant="title-1"
            style={{ margin: 0 }}
          />
        </div>
      )}
      <div className="customize--columns-options_box">
        <Grid container>
          {section &&
            section.list &&
            Array.isArray(section.list) &&
            section.list.map((option, idx) => {
              const isParent = typeof option === 'object' && !Object.keys(option).includes('value')
              const label = option?.label || option
              const value = option?.value || option
              const checked = selected.includes(isParent ? label : value)
              const parentChecked = isParent && checked
              return (
                <Grid
                  key={idx}
                  item
                  xs={parseInt(12 / noOfColumns, 10)}
                >
                  <div className={classNames(parentChecked && 'parent--item-selected')}>
                    <Checkbox
                      label={I18n.t(label)}
                      active={checked}
                      onChange={(val) =>
                        handleChecked(isParent ? option : value, val.target.checked, isParent)
                      }
                      testId={`customize-columns-options-${value
                        ?.replace?.(/\s/g, '_')
                        .replace('/', '_')}`}
                    />
                    {isParent && renderSubList(option)}
                  </div>
                </Grid>
              )
            })}
        </Grid>
      </div>
    </div>
  )

  return (
    <div
      className="customize-columns"
      style={style}
    >
      <div onClick={handleClick}>
        {trigger || (
          <button
            className={classNames('extracolumn-btn', btnClassName)}
            type="button"
          >
            {btnIcon && (
              <img
                src={btnIcon}
                alt=""
              />
            )}
            {I18n.t(title)}
            <strong className="count">{selected.length}</strong>
          </button>
        )}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className="customize-columns__poper"
        placement={placement || placment}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div className={classNames('customize-columns__wrapper', wrapperClassName)}>
            <Header title={modalTitle} />
            <div className="customize-columns__content">
              {!hideSelectAll && (
                <div
                  className="customize--columns-options_box"
                  style={{ paddingBottom: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Checkbox
                      label={I18n.t('select_all')}
                      active={allChecked}
                      onChange={() => handleSelectAll()}
                      testId="select-all"
                    />
                  </Grid>
                  <Spacer height={5} />
                </div>
              )}
              {Array.isArray(data)
                ? data.map((section, i) => renderSection(section, i))
                : renderSection(data, 0)}
            </div>
            {hasSubmitting && (
              <Footer
                onSubmit={() => {
                  setAnchorEl(null)
                  onChange(selected)
                }}
              />
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

CustomizeColumns.propTypes = {
  trigger: PropTypes.node,
  onChange: PropTypes.func,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
    }),
  ),
  defaultSelected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),

  title: PropTypes.string,
  btnClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  btnIcon: PropTypes.string,
  modalTitle: PropTypes.string,
  placement: PropTypes.string,

  hasSubmitting: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  noOfColumns: PropTypes.oneOf([1, 2, 3, 4, 6]),
  style: PropTypes.shape(),
}

CustomizeColumns.defaultProps = {
  noOfColumns: 2,
  modalTitle: 'customize_columns',
}

export default CustomizeColumns
