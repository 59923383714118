export const PERFORMANCE_NOTIFICATION_LOAD = 'PERFORMANCE_NOTIFICATION_LOAD'
export const PERFORMANCE_NOTIFICATION_LOAD_PENDING = 'PERFORMANCE_NOTIFICATION_LOAD_PENDING'
export const PERFORMANCE_NOTIFICATION_LOAD_FULFILLED = 'PERFORMANCE_NOTIFICATION_LOAD_FULFILLED'
export const PERFORMANCE_NOTIFICATION_LOAD_REJECTED = 'PERFORMANCE_NOTIFICATION_LOAD_REJECTED'

export const PERFORMANCE_NOTIFICATION_MARK_AS_READ = 'PERFORMANCE_NOTIFICATION_MARK_AS_READ'
export const PERFORMANCE_NOTIFICATION_MARK_AS_READ_PENDING =
  'PERFORMANCE_NOTIFICATION_MARK_AS_READ_PENDING'
export const PERFORMANCE_NOTIFICATION_MARK_AS_READ_FULFILLED =
  'PERFORMANCE_NOTIFICATION_MARK_AS_READ_FULFILLED'
export const PERFORMANCE_NOTIFICATION_MARK_AS_READ_REJECTED =
  'PERFORMANCE_NOTIFICATION_MARK_AS_READ_REJECTED'
