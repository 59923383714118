import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: true,
  employees: [],
  payrun_review_steps: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_PENDING:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_PENDING:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_FULFILLED:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_FULFILLED:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_FULFILLED: {
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_REJECTED:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_REJECTED:
    case actions.FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
