import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Styles from './Signpost.module.css'

const Signpost = ({ children, size }) => (
  <div className={classNames(Styles.signpost, Styles[size])}>{children}</div>
)

Signpost.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
}

export default Signpost
