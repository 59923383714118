import React from 'react'
import ContentLoader from 'react-content-loader'
import { isRtl } from 'utils/uiHelpers'

const Loader = (props) => (
  <div
    style={{
      width: 400,
      height: 120,
    }}
  >
    <ContentLoader
      rtl={isRtl}
      speed={2}
      width={500}
      height={120}
      viewBox="0 0 400 120"
      backgroundColor="#f50"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle
        cx="32"
        cy="41"
        r="30"
      />
      <rect
        x="77"
        y="23"
        rx="5"
        ry="5"
        width="200"
        height="16"
      />
      <rect
        x="285"
        y="23"
        rx="5"
        ry="5"
        width="50"
        height="16"
      />
      <rect
        x="340"
        y="23"
        rx="5"
        ry="5"
        width="50"
        height="16"
      />
      <rect
        x="77"
        y="55"
        rx="8"
        ry="8"
        width="100"
        height="10"
      />
      <rect
        x="77"
        y="70"
        rx="8"
        ry="8"
        width="140"
        height="10"
      />
      <rect
        x="77"
        y="85"
        rx="8"
        ry="8"
        width="170"
        height="10"
      />
    </ContentLoader>
  </div>
)

export default Loader
