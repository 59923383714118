import { useSelector } from 'utils/hooks'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Department from 'containers/authorised/setting/OrganizationStructure/Departments'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import StepTemplate from '../../../components/StepTemplate'
import { StepsTypes } from '../../../types/onboardingTypes'
import styles from './Departments.module.css'

const Departments = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const { fetching } = useSelector(({ newDepartments }) => newDepartments)
  const { name_i18n } = useSelector((s) => s.organization?.organization)

  useTrackingSteps({ step_name: 'organization_info', level_name: 'department' })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      fetching={fetching}
      className={styles.depContent}
    >
      <Flex flexCol>
        <Typography
          variant="interface/default/m"
          text={I18n.t('Add_departments_to_Savola_Groups', {
            name_i18n,
          })}
        />

        <Spacer height="16px" />
        <Department />
      </Flex>
    </StepTemplate>
  )
}

export default Departments
