import { useSelector } from 'utils/hooks'
import { TInitialStatePaymentReview } from './type'

export const usePaymentListSelector = (): TInitialStatePaymentReview =>
  useSelector((s) => ({
    payment_details: s.onboardingPaymentDetails.payment_details || [],
    pagination: s.onboardingPaymentDetails.pagination,
    statistics: s.onboardingPaymentDetails.statistics,
    fetching: s.onboardingPaymentDetails.fetching,
    loading: s.onboardingPaymentDetails.loading,
  }))

export const useOnBoardingBanksSelector = (): Pick<
  TInitialStatePaymentReview,
  'banks' | 'fetching'
> =>
  useSelector((s) => ({
    banks: s.onboardingPaymentDetails.banks || [],
    fetching: s.onboardingPaymentDetails.fetching,
  }))
