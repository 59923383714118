import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Mail = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
  >
    <path
      fill={color ? getColor(color) : '#FFA254'}
      d="M11.5,1 C11.8,1 12,1.21818182 12,1.54545455 L12,2.18181818 C12,2.4 11.9,2.56363636 11.75,
      2.67272727 L6.25,5.94545455 C6.15,6 6.1,6 6,6 C5.9,6 5.85,6 5.75,5.94545455 L0.25,2.67272727 C0.1,
      2.56363636 0,2.4 0,2.18181818 L0,1.54545455 C0,1.21818182 0.2,1 0.5,1 L11.5,1 Z M6.7,7.04580153 L12,
      4 L12,10.4656489 C12,10.7862595 11.8,11 11.5,11 L0.5,11 C0.2,11 0,10.7862595 0,10.4656489 L0,
      4 L5.3,7.04580153 C5.5,7.20610687 5.75,7.25954198 6,7.25954198 C6.25,7.25954198 6.5,7.20610687 6.7,
      7.04580153 Z"
      transform="translate(0 -1)"
    />
  </svg>
)

Mail.propTypes = {
  color: PropTypes.string,
}

export default Mail
