import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import { Action } from 'types/redux'
import * as actions from './actions'

export function onboardingCountingDaysLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/leave_grant_type',
  )}`

  return {
    type: actions.ONBOARDING_COUNTING_DAYS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingAnnualLeaveEntitlementLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/annual_leave_entitlement_auto_convert',
  )}`

  return {
    type: actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingCountingDaysUpdate(data: { value: string }): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/leave_grant_type',
  )}`

  return {
    type: actions.ONBOARDING_COUNTING_DAYS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { organization_configuration: data }),
  }
}
