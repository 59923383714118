import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type GosiLinkedTypes = {
  filterKey: string
  filters: string[]
}

const GosiLinked = (props: GosiLinkedTypes): JSX.Element => {
  const { gosi_linked_data } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(gosi_linked_data)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (typeof val === 'boolean') {
      handleFilter('is_gosi_linked', [val])
    } else {
      handleFilter('is_gosi_linked', [])
    }
  }

  const checkSelectedIds = (): string[] => {
    if (fltr && typeof fltr[0] === 'boolean') {
      return fltr as string[]
    }
    return ['all']
  }

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={checkSelectedIds() as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

export default GosiLinked
