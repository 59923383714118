import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PayrunTypesTypes = {
  filterKey: string
  filters: string[]
}

const FinalVacationStatus = (props: PayrunTypesTypes): JSX.Element => {
  const { filter_payrun_status } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])

  const optionList = returnBadgeList(filter_payrun_status)
  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        filter_payrun_status: [],
      })
    } else {
      handleFilter('filter_payrun_status', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.filter_payrun_status)) {
      setSelectedIds(filter.filter_payrun_status)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.filter_payrun_status])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      labelKey="name"
      {...props}
    />
  )
}

export default FinalVacationStatus
