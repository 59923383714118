import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type FormTypeProps = {
  filterKey: string
  filters: Array<string>
}

const FormType = (props: FormTypeProps): JSX.Element => {
  const { status_flight_booking } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={status_flight_booking}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('label.status')}
    />
  )
}

export default FormType
