import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Employee } from 'redux/authUser/types'
import * as actions from './actions'

export type OrganizationHistoryAndCompensationHistory = {
  id: number
  edit_date: string
  is_changer_deleted: boolean
  changed_by: string
  effective_date: string
  reason: string
  is_retroactive: boolean
  type_change_i18n: string
  file?: string
  file_name?: string
  changes: [
    {
      edit_item: string
      current: string
      previous: string
      is_attachment?: boolean
    },
  ]
}

type StateEmployeeInfoChange = {
  organization_history: OrganizationHistoryAndCompensationHistory[]
  compensation_history: OrganizationHistoryAndCompensationHistory[]
  fetching: boolean
  employee: Employee | Record<string, unknown>
}

const initialState: StateEmployeeInfoChange = {
  employee: {},
  organization_history: [],
  compensation_history: [],
  fetching: false,
}

export default function reducer(state = initialState, action: Action): StateEmployeeInfoChange {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_INFORMATION_CHANGES_POST_PENDING:
    case actions.ORGANIZATION_HISTORY_LOAD_PENDING:
    case actions.EMPLOYEE_INFORMATION_CHANGES_DELETE_PENDING:
    case actions.COMPENSATION_HISTORY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEE_INFORMATION_CHANGES_POST_FULFILLED:
    case actions.EMPLOYEE_INFORMATION_CHANGES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_HISTORY_LOAD_FULFILLED:
      return {
        ...state,
        organization_history: payload.data.data,
        fetching: false,
      }

    case actions.COMPENSATION_HISTORY_LOAD_FULFILLED:
      return {
        ...state,
        compensation_history: payload.data.data,
        fetching: false,
      }

    case actions.COMPENSATION_HISTORY_LOAD_REJECTED:
    case actions.EMPLOYEE_INFORMATION_CHANGES_DELETE_REJECTED:
    case actions.EMPLOYEE_INFORMATION_CHANGES_POST_REJECTED:
    case actions.ORGANIZATION_HISTORY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
