import React from 'react'
import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

const JobTitles = (props) => {
  const { job_titles } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      filterKey="job_title_ids"
      handleFilter={handleFilter}
      options={job_titles}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

JobTitles.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default JobTitles
