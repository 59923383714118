import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  grades: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}

let changes = null
export default function gradeReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let grades = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'GRADES_LOAD_PENDING':
    case 'GRADE_CREATE_PENDING':
    case 'GRADE_UPDATE_PENDING':
    case 'GRADE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.grades.length > 0),
      }

    case 'GRADES_LOAD_REJECTED':
    case 'GRADE_CREATE_REJECTED':
    case 'GRADE_UPDATE_REJECTED':
    case 'GRADE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        loading: false,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'GRADES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'GRADE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      grades = [...state.grades]
      newRecord = payload.data.data.grade
      changes = {
        grades: [...grades, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        loading: false,
      }
      return { ...state, ...changes }

    case 'GRADE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      grades = [...state.grades]
      updatedRecord = payload.data.data.grade
      changes = {
        grades: updateOneListOfRecords(grades, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        loading: false,
      }
      return { ...state, ...changes }

    case 'GRADE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      grades = [...state.grades]
      return {
        ...state,
        grades: removeOneListOfRecords(grades, payload.id),
        error: null,
        loading: false,
      }

    case 'GRADE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'GRADE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
