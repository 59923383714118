import React from 'react'
import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

const Departments = (props) => {
  const { departments } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={departments}
      selectedIds={filter[props.filterKey]}
      {...props}
      componentType="multiLevel"
      title="departments"
      childKey="departments"
      labelKey="name_i18n"
      valueKey="id"
    />
  )
}

Departments.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default Departments
