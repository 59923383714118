export const RATING_CONFIGURATIONS_V2_LOAD = 'RATING_CONFIGURATIONS_V2_LOAD'
export const RATING_CONFIGURATIONS_V2_LOAD_PENDING = 'RATING_CONFIGURATIONS_V2_LOAD_PENDING'
export const RATING_CONFIGURATIONS_V2_LOAD_FULFILLED = 'RATING_CONFIGURATIONS_V2_LOAD_FULFILLED'
export const RATING_CONFIGURATIONS_V2_LOAD_REJECTED = 'RATING_CONFIGURATIONS_V2_LOAD_REJECTED'

export const RATING_CONFIGURATIONS_V2_CREATE = 'RATING_CONFIGURATIONS_V2_CREATE'
export const RATING_CONFIGURATIONS_V2_CREATE_PENDING = 'RATING_CONFIGURATIONS_V2_CREATE_PENDING'
export const RATING_CONFIGURATIONS_V2_CREATE_FULFILLED = 'RATING_CONFIGURATIONS_V2_CREATE_FULFILLED'
export const RATING_CONFIGURATIONS_V2_CREATE_REJECTED = 'RATING_CONFIGURATIONS_V2_CREATE_REJECTED'

export const RATING_CONFIGURATIONS_V2_UPDATE = 'RATING_CONFIGURATIONS_V2_UPDATE'
export const RATING_CONFIGURATIONS_V2_UPDATE_PENDING = 'RATING_CONFIGURATIONS_V2_UPDATE_PENDING'
export const RATING_CONFIGURATIONS_V2_UPDATE_FULFILLED = 'RATING_CONFIGURATIONS_V2_UPDATE_FULFILLED'
export const RATING_CONFIGURATIONS_V2_UPDATE_REJECTED = 'RATING_CONFIGURATIONS_V2_UPDATE_REJECTED'
