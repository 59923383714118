export const PAYROLL_SINGLE_REMARK_LOAD = 'PAYROLL_SINGLE_REMARK_LOAD'
export const PAYROLL_SINGLE_REMARK_LOAD_PENDING = 'PAYROLL_SINGLE_REMARK_LOAD_PENDING'
export const PAYROLL_SINGLE_REMARK_LOAD_FULFILLED = 'PAYROLL_SINGLE_REMARK_LOAD_FULFILLED'
export const PAYROLL_SINGLE_REMARK_LOAD_REJECTED = 'PAYROLL_SINGLE_REMARK_LOAD_REJECTED'

export const PAYROLL_REMARKS_LOAD = 'PAYROLL_REMARKS_LOAD'
export const PAYROLL_REMARKS_LOAD_PENDING = 'PAYROLL_REMARKS_LOAD_PENDING'
export const PAYROLL_REMARKS_LOAD_FULFILLED = 'PAYROLL_REMARKS_LOAD_FULFILLED'
export const PAYROLL_REMARKS_LOAD_REJECTED = 'PAYROLL_REMARKS_LOAD_REJECTED'

export const PAYROLL_MANUAL_REMARKS_LOAD = 'PAYROLL_MANUAL_REMARKS_LOAD'
export const PAYROLL_MANUAL_REMARKS_LOAD_PENDING = 'PAYROLL_MANUAL_REMARKS_LOAD_PENDING'
export const PAYROLL_MANUAL_REMARKS_LOAD_FULFILLED = 'PAYROLL_MANUAL_REMARKS_LOAD_FULFILLED'
export const PAYROLL_MANUAL_REMARKS_LOAD_REJECTED = 'PAYROLL_MANUAL_REMARKS_LOAD_REJECTED'

export const PAYROLL_REMARKS_CREATE = 'PAYROLL_REMARKS_CREATE'
export const PAYROLL_REMARKS_CREATE_PENDING = 'PAYROLL_REMARKS_CREATE_PENDING'
export const PAYROLL_REMARKS_CREATE_FULFILLED = 'PAYROLL_REMARKS_CREATE_FULFILLED'
export const PAYROLL_REMARKS_CREATE_REJECTED = 'PAYROLL_REMARKS_CREATE_REJECTED'

export const PAYROLL_REMARKS_UPDATE = 'PAYROLL_REMARKS_UPDATE'
export const PAYROLL_REMARKS_UPDATE_PENDING = 'PAYROLL_REMARKS_UPDATE_PENDING'
export const PAYROLL_REMARKS_UPDATE_FULFILLED = 'PAYROLL_REMARKS_UPDATE_FULFILLED'
export const PAYROLL_REMARKS_UPDATE_REJECTED = 'PAYROLL_REMARKS_UPDATE_REJECTED'

export const AUTOMATIC_REMARKS = 'AUTOMATIC_REMARKS'
export const AUTOMATIC_REMARKS_PENDING = 'AUTOMATIC_REMARKS_PENDING'
export const AUTOMATIC_REMARKS_FULFILLED = 'AUTOMATIC_REMARKS_FULFILLED'
export const AUTOMATIC_REMARKS_REJECTED = 'AUTOMATIC_REMARKS_REJECTED'

export const PAYROLL_REMARKS_DELETE = 'PAYROLL_REMARKS_DELETE'
export const PAYROLL_REMARKS_DELETE_PENDING = 'PAYROLL_REMARKS_DELETE_PENDING'
export const PAYROLL_REMARKS_DELETE_FULFILLED = 'PAYROLL_REMARKS_DELETE_FULFILLED'
export const PAYROLL_REMARKS_DELETE_REJECTED = 'PAYROLL_REMARKS_DELETE_REJECTED'

export const PAYROLL_REMARKS_RESET = 'PAYROLL_REMARKS_RESET'
