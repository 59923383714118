import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import {
  AttendanceConfig,
  LocationRestrictionsT,
  LocationRestrictionStateT,
  RestrictionsEmployeesT,
} from './attendanceConfigTypes'

const initialState: AttendanceConfig = {
  fetching: false,
  is_biotime_enabled: false,
  is_jisr_clocking_enabled: false,
  punch_devices: [],
  location_restrictions: [],
  restrictions_employees: [],
  punch_device: {},
  restriction_stats: null,
  loading: false,
  status: {
    is_all_active: false,
    total: 0,
    online: 0,
  },
}

export default function reducer(state = initialState, action: Action): AttendanceConfig {
  switch (action.type) {
    case actions.ATTENDANCE_CONFIGURATION_LOAD_PENDING:
    case actions.ATTENDANCE_LINK_LOCATION_PENDING:
    case actions.ORGANIZATION_DEVICE_ROLE_CREATE_PENDING:
    case actions.ORGANIZATION_PUNCH_DEVICE_LOAD_PENDING:
    case actions.ORGANIZATION_DEVICE_ROLE_DELETE_PENDING:
    case actions.REFRESH_PUNCH_DEVICE_STATUS_PENDING:
    case actions.PUNCH_DEVICE_STATUS_PENDING:
    case actions.CREATE_PUNCH_DEVICE_PENDING:
    case actions.DELETE_PUNCH_DEVICE_PENDING:
      return {
        ...state,
        fetching: true,
        loading: true,
      }

    case actions.GET_PUNCHES_RESTRICTION_PENDING:
    case actions.PUNCHES_RESTRICTION_STATS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_RESTRICTION_EMPLOYEES_PENDING:
    case actions.UPDATE_DEFAULT_RESTRICTION_PENDING:
    case actions.UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_PENDING:
    case actions.DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.ATTENDANCE_CONFIGURATION_LOAD_FULFILLED:
      const actionData: Pick<
        AttendanceConfig,
        'is_biotime_enabled' | 'is_jisr_clocking_enabled' | 'punch_devices' | 'pagination'
      > = action.payload.data.data
      return {
        ...state,
        ...actionData,
        fetching: false,
        loading: false,
      }

    case actions.PUNCHES_RESTRICTION_STATS_FULFILLED:
      const restriction_stats: LocationRestrictionStateT = action.payload.data.data
      return {
        ...state,
        fetching: false,
        restriction_stats,
      }

    case actions.DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_FULFILLED:
      return {
        ...state,
        loading: false,
      }

    case actions.UPDATE_DEFAULT_RESTRICTION_FULFILLED:
    case actions.UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.GET_PUNCHES_RESTRICTION_FULFILLED:
      const location_restrictions: LocationRestrictionsT[] = action.payload.data.data
      return {
        ...state,
        location_restrictions,
        fetching: false,
      }

    case actions.GET_RESTRICTION_EMPLOYEES_FULFILLED:
      const restrictions_employees: RestrictionsEmployeesT[] = action.payload.data.data
      return {
        ...state,
        restrictions_employees,
        loading: false,
      }

    case actions.PUNCH_DEVICE_STATUS_FULFILLED: {
      const { data = {} } = action.payload.data.data
      return {
        ...state,
        status: {
          ...data,
          is_all_active: data.online === data.total,
        },
        fetching: false,
        loading: false,
      }
    }

    case actions.ORGANIZATION_PUNCH_DEVICE_LOAD_FULFILLED:
      return {
        ...state,
        punch_device: action.payload.data.data.punch_device,
        fetching: false,
        loading: false,
      }
    case actions.ATTENDANCE_LINK_LOCATION_FULFILLED:
    case actions.ORGANIZATION_DEVICE_ROLE_CREATE_FULFILLED:
    case actions.ORGANIZATION_DEVICE_ROLE_DELETE_FULFILLED:
    case actions.REFRESH_PUNCH_DEVICE_STATUS_FULFILLED:
    case actions.CREATE_PUNCH_DEVICE_FULFILLED:
    case actions.DELETE_PUNCH_DEVICE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    case actions.GET_PUNCHES_RESTRICTION_REJECTED:
    case actions.PUNCHES_RESTRICTION_STATS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_RESTRICTION_EMPLOYEES_REJECTED:
    case actions.UPDATE_DEFAULT_RESTRICTION_REJECTED:
    case actions.UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_REJECTED:
    case actions.DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    case actions.ATTENDANCE_CONFIGURATION_LOAD_REJECTED:
    case actions.ATTENDANCE_LINK_LOCATION_REJECTED:
    case actions.ORGANIZATION_DEVICE_ROLE_CREATE_REJECTED:
    case actions.ORGANIZATION_PUNCH_DEVICE_LOAD_REJECTED:
    case actions.ORGANIZATION_DEVICE_ROLE_DELETE_REJECTED:
    case actions.REFRESH_PUNCH_DEVICE_STATUS_REJECTED:
    case actions.PUNCH_DEVICE_STATUS_REJECTED:
    case actions.CREATE_PUNCH_DEVICE_REJECTED:
    case actions.DELETE_PUNCH_DEVICE_REJECTED:
      const msg = action.payload.response ? action.payload.response.data.error : action.payload
      Toastr.error(msg)
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
