import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  objectives_all: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.OBJECTIVES_LOAD_ALL_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.OBJECTIVES_LOAD_ALL_FULFILLED:
      return {
        ...state,
        objectives_all: action.payload.data.data.objectives,
        fetching: false,
      }

    case actions.OBJECTIVES_LOAD_ALL_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
