import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type Metadata = {
  form_id: number
  employee_form_review_id: number
}
export type PMnotificationsType = {
  id: number
  message_en: string
  message_i18n: string
  message_type: 'employee_form_review_submitted' | 'assign_employee_in_active_form'
  action_type: string
  action_text_en: string
  action_text_i18n: string
  metadata: Metadata
}

type State = {
  fetching: boolean
  notifications: {
    assign_employee_in_active_form?: PMnotificationsType
    employee_form_review_submitted?: PMnotificationsType
  }
}

export const initialState: State = {
  fetching: false,
  notifications: {},
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.PM_NOTIFICATION_LOAD_PENDING:
    case actions.PM_NOTIFICATION_MARK_AS_READ_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PM_NOTIFICATION_MARK_AS_READ_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.PM_NOTIFICATION_LOAD_FULFILLED:
      return {
        ...state,
        notifications: action.payload.data.data,
        fetching: false,
      }

    case actions.PM_NOTIFICATION_LOAD_REJECTED:
    case actions.PM_NOTIFICATION_MARK_AS_READ_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
