import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  tracking_dimensions: [],
  tracking_dimension: {},
  fetching: false,
  // ? values
  items_loading: false,
  tracking_items: [],
  init_trackingDimensions_loading: true,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET_VALUES:
      return {
        ...state,
        tracking_dimension: {},
      }

    case actions.TRACKING_DIMENSIONS_LOAD_PENDING:
    case actions.TRACKING_DIMENSION_LOAD_PENDING:
      return {
        ...state,
        init_trackingDimensions_loading: true,
        fetching: true,
      }

    case actions.TRACKING_DIMENSIONS_LOAD_FULFILLED:
      return {
        ...state,
        init_trackingDimensions_loading: false,
        tracking_dimensions: action.payload?.data?.data?.tracking_dimensions,
        fetching: false,
      }

    case actions.TRACKING_DIMENSION_LOAD_FULFILLED:
      return {
        ...state,
        tracking_dimension: {
          ...action.payload?.data?.data?.tracking_dimension,
          tracking_dimension_values_attributes:
            action.payload?.data?.data?.tracking_dimension?.tracking_dimension_values.map(
              (item, i) => ({
                ...item,
                prime_id: i,
                employee_mappings: item.mapped_employee_ids,
              }),
            ),
        },
        fetching: false,
      }

    case actions.TRACKING_DIMENSIONS_LOAD_REJECTED:
    case actions.TRACKING_DIMENSION_LOAD_REJECTED:
      return {
        ...state,
        init_trackingDimensions_loading: false,
        fetching: false,
      }

    case actions.TRACKING_DIMENSION_DELETE_PENDING:
    case actions.TRACKING_DIMENSION_CREATE_PENDING:
    case actions.TRACKING_DIMENSION_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.TRACKING_DIMENSION_DELETE_FULFILLED:
    case actions.TRACKING_DIMENSION_CREATE_FULFILLED:
    case actions.TRACKING_DIMENSION_UPDATE_FULFILLED:
      Toastr.success(action?.payload?.data?.message)
      return {
        ...state,
        loading: false,
      }

    case actions.TRACKING_DIMENSION_DELETE_REJECTED:
    case actions.TRACKING_DIMENSION_CREATE_REJECTED:
    case actions.TRACKING_DIMENSION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action?.payload))
      return {
        ...state,
        loading: false,
      }

    // ? values or items
    case actions.TRACKING_DIMENSION_VALUES_LOAD_PENDING:
      return {
        ...state,
        items_loading: true,
      }

    case actions.TRACKING_DIMENSION_VALUES_LOAD_FULFILLED:
      return {
        ...state,
        items_loading: false,
        tracking_items: action.payload.data.data?.tracking_dimension_values,
      }

    case actions.TRACKING_DIMENSION_VALUES_LOAD_REJECTED:
      return {
        ...state,
        items_loading: false,
      }

    default:
      return state
  }
}
