import { FC } from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import MudadConfigHeader from './MudadConfigHeader'
import MudadConfigFooter from './MudadConfigFooter'
import { usePaymentMethod } from '../../../components/usePaymentMethods'
import { MudadStepsNumber } from '../../../components/helper'

type MudadStepsTemplateType = {
  onNextClick: () => void
  nextLabel?: string
  disableNextBtn: boolean
  onBackClick?: () => void
  disableBackBtn: boolean
}

const MudadStepsTemplate: FC<MudadStepsTemplateType> = (props) => {
  const { nextLabel, disableBackBtn, disableNextBtn } = props
  const paymentCtx = usePaymentMethod()

  return (
    <Flex
      flexCol
      style={{ height: '100%' }}
    >
      <MudadConfigHeader />
      <div
        className="scroll-y"
        style={{
          flex: 1,
          // maxHeight: '65vh',
          // minHeight: 500,
          paddingTop: 24,
        }}
      >
        {props.children}
      </div>
      <MudadConfigFooter
        nextLabel={nextLabel || i18n.t('next')}
        disableBackBtn={disableBackBtn}
        disableNextBtn={disableNextBtn}
        onNextClick={props.onNextClick}
        onBackClick={(): void => {
          if (props.onBackClick) {
            props.onBackClick()
          } else {
            paymentCtx.setMudadCurrentStep((pre) => (pre! - 1) as MudadStepsNumber)
          }
        }}
      />
    </Flex>
  )
}

export default MudadStepsTemplate
