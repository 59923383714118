export default {
  '500_title': 'We couldn’t load all data',
  '500_description':
    'We couldn’t transfer all data due to a technical issue. Please try again later or reach out to our support team if the issue persists',
  '403_title': 'You don’t have permission to view this page',
  '403_description':
    'You can’t access this page based on the granted permissions, contact the admin for more information.',
  '404_title': 'Page not found',
  '404_description':
    'Sorry, There is no page with current URL ot it maybe replace onto another URL',
}
