export const OVERTIME_POLICIES_LOAD = 'OVERTIME_POLICIES_LOAD'
export const OVERTIME_POLICIES_LOAD_PENDING = 'OVERTIME_POLICIES_LOAD_PENDING'
export const OVERTIME_POLICIES_LOAD_FULFILLED = 'OVERTIME_POLICIES_LOAD_FULFILLED'
export const OVERTIME_POLICIES_LOAD_REJECTED = 'OVERTIME_POLICIES_LOAD_REJECTED'

export const OVERTIME_POLICY_LOAD = 'OVERTIME_POLICY_LOAD'
export const OVERTIME_POLICY_LOAD_PENDING = 'OVERTIME_POLICY_LOAD_PENDING'
export const OVERTIME_POLICY_LOAD_FULFILLED = 'OVERTIME_POLICY_LOAD_FULFILLED'
export const OVERTIME_POLICY_LOAD_REJECTED = 'OVERTIME_POLICY_LOAD_REJECTED'

export const OVERTIME_POLICY_CREATE = 'OVERTIME_POLICY_CREATE'
export const OVERTIME_POLICY_CREATE_PENDING = 'OVERTIME_POLICY_CREATE_PENDING'
export const OVERTIME_POLICY_CREATE_FULFILLED = 'OVERTIME_POLICY_CREATE_FULFILLED'
export const OVERTIME_POLICY_CREATE_REJECTED = 'OVERTIME_POLICY_CREATE_REJECTED'

export const OVERTIME_POLICY_UPDATE = 'OVERTIME_POLICY_UPDATE'
export const OVERTIME_POLICY_UPDATE_PENDING = 'OVERTIME_POLICY_UPDATE_PENDING'
export const OVERTIME_POLICY_UPDATE_FULFILLED = 'OVERTIME_POLICY_UPDATE_FULFILLED'
export const OVERTIME_POLICY_UPDATE_REJECTED = 'OVERTIME_POLICY_UPDATE_REJECTED'

export const OVERTIME_POLICY_DELETE = 'OVERTIME_POLICY_DELETE'
export const OVERTIME_POLICY_DELETE_FULFILLED = 'OVERTIME_POLICY_DELETE_FULFILLED'
export const OVERTIME_POLICY_DELETE_REJECTED = 'OVERTIME_POLICY_DELETE_REJECTED'

export const RESET_OVERTIME_POLICY = 'RESET_OVERTIME_POLICY'
