import { FC, Fragment } from 'react'
import { Typography } from '@jisr-hr/ds'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'

import styles from '../styles.module.css'
import { MudadStepsNumber } from '../../../components/helper'
import { usePaymentMethod } from '../../../components/usePaymentMethods'

type StepType = {
  name: string
  key: string
  value: MudadStepsNumber
}

const colors = {
  completed: '#05751F',
  active: 'var(--color-brand-primary-default-new)',
}

const MudadConfigHeader: FC = () => {
  const paymentCtx = usePaymentMethod()
  const { mudadCurrentStep } = paymentCtx

  const paymentMethod = useSelector((s) => s.paymentMethods.current_payment_method)
  const saved_steps = useSelector((s) => s.mudadIntegration.flow_steps)
  const is_mudad_completed = !!paymentMethod?.mudad_establishment?.is_completed

  const steps: StepType[] = [
    ...(!is_mudad_completed
      ? [{ name: i18n.t('review_org_structure'), value: 1, key: 'register_establishment' } as const]
      : []),
    ...(!is_mudad_completed
      ? [{ name: i18n.t('authorizer_verification'), value: 2, key: 'owner_verification' } as const]
      : []),
    { name: i18n.t('verify_payment_admin'), value: 3, key: 'verify_employees' },
    { name: i18n.t('authorize_payment_admins'), value: 4, key: 'authorize_mudad' },
  ]

  // ? functions
  const isClickable = (step: StepType): boolean => {
    const status = saved_steps ? saved_steps[step.key] : null
    return status === 'completed' || saved_steps?.current_step === step.key
  }

  return (
    <div
      className={styles.header_container}
      style={{ justifyContent: is_mudad_completed ? 'flex-start' : 'space-between' }}
    >
      {mudadCurrentStep &&
        steps.map((step) => {
          const isCurrent = step.value === mudadCurrentStep
          const passed = step.value < mudadCurrentStep
          return (
            <Fragment key={step.name}>
              <div
                className={styles.header_step_box}
                onClick={(): void => {
                  if (isClickable(step)) paymentCtx.setMudadCurrentStep(step.value)
                }}
                style={{ cursor: isClickable(step) ? 'pointer' : 'not-allowed' }}
              >
                {step.value === mudadCurrentStep && (
                  <DSIcon
                    name="placeholder"
                    size="md"
                    color="var(--color-brand-primary-default-new)"
                  />
                )}
                {step.value < mudadCurrentStep && (
                  <DSIcon
                    name="check-circle"
                    size="md"
                    color="#05751F"
                  />
                )}

                <Typography
                  variant="interface/default/sm"
                  style={{
                    lineHeight: '20px',
                    // eslint-disable-next-line no-nested-ternary
                    color: isCurrent
                      ? colors.active
                      : passed
                      ? colors.completed
                      : 'var(--color-base-colors-black-700)',
                  }}
                  text={step.name}
                />
              </div>
              {step.value !== steps.length && <div className={styles.divider} />}
            </Fragment>
          )
        })}
    </div>
  )
}

export default MudadConfigHeader
