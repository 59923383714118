import { Grid } from '@material-ui/core'
import { DatePickerDSField, SelectField, TextInputField } from 'components/final-form'
import {
  required,
  composeValidators,
  positive,
  number,
} from 'components/global/form/FormValidations'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loanTypesLoad } from 'redux/actions/loanTypeActions'
import I18n from 'translations/i18n'
import Frame from '../../../ds/Frame'

const LoanDetails = () => {
  const dispatch = useDispatch()
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency?.currency_type_i18n,
  )
  const loan_types =
    useSelector(({ loanType }) =>
      loanType?.loan_types?.filter((item) => item?.status === 'active'),
    ) || []

  React.useEffect(() => {
    dispatch(
      loanTypesLoad({
        filters: {
          status: 'active',
        },
      }),
    )
  }, [])

  return (
    <Frame title={I18n.t('loan_details')}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={4}
        >
          <SelectField
            name="loan_type_id"
            valueKey="id"
            labelKey="name_i18n"
            label={I18n.t('type')}
            options={loan_types}
            validate={required}
            maxMenuHeight={300}
            testId="select-loan-type"
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextInputField
            data-private
            name="amount"
            suffix={currency}
            label={I18n.t('amount')}
            validate={composeValidators(required, number, positive)}
            testId="loan-amount"
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <DatePickerDSField
            width="148px"
            name="issue_date"
            label={I18n.t('deduction_starting_month')}
            validate={required}
            calenderProps={{
              showMonthYearPicker: true,
            }}
            textFieldProps={{
              testId: 'loan-deduction',
            }}
          />
        </Grid>
      </Grid>
    </Frame>
  )
}

export default LoanDetails
