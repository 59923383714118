import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export const getOnboardingUrl = (url: string): string => {
  const hashValues = ['#/new_onboarding']
  const isNewOnboarding = hashValues.some((hash) => window.location.hash.includes(hash))

  return isNewOnboarding ? url : `onboarding/${url}`
}

const handleUrl = (_isNewOnboarding?: boolean): string => {
  const isNewOnboarding = _isNewOnboarding ?? window.location.hash.includes('#/new_onboarding')
  return isNewOnboarding ? 'organizations' : 'onboarding/organization'
}

export const organizationInfo = (params = {}, _isNewOnboarding?: boolean): Action => {
  const url = `/${API_VERSION_TWO}/${handleUrl(_isNewOnboarding)}`

  return {
    type: actions.ONBOARDING_ORGANIZATION_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const updateOrganization = (body = {}): Action => {
  const url = `/${API_VERSION_TWO}/${handleUrl()}`

  return {
    type: actions.ONBOARDING_ORGANIZATION_PUT,
    payload: handleAPI(url, {}, 'PUT', { organization: body }),
  }
}

export const slugAvaliable = (data = {}): Action => {
  const url = '/v2/onboarding/organization/slug_available'
  return {
    type: actions.SLUG_AVALIABLE,
    payload: handleAPI(url, data, 'GET'),
  }
}

// export const validateAdminsEmail = (params = {}) => {
//   const url = `/${API_VERSION_TWO}/onboarding/organization/validate_admins_email`;

//   return {
//     type: actions.VALIDATE_ADMINS_EMAIL,
//     payload: handleAPI(url, params, 'GET'),
//   };
// };

export const organizationConfirmUpdate = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/organization/confirm`

  return {
    type: actions.ONBOARDING_ORGANIZATION_CONFIRM_PUT,
    payload: handleAPI(url, params, 'PUT'),
  }
}

export const checkSlugAvailability = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/organization/slug_availability`

  return {
    type: actions.ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT,
    payload: handleAPI(url, { slug: params }, 'GET'),
  }
}

export const onboardingStepUpdate = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/step`

  return {
    type: actions.ONBOARDING_STEP_UPDATE,
    payload: handleAPI(url, params, 'PUT'),
  }
}

export const validatePinCodeCreate = (pincode: string): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/organization/validate_pincode`

  return {
    type: actions.VALIDATE_PIN_CODE_CREATE,
    payload: handleAPI(url, {}, 'POST', { pincode }),
  }
}

export const onboardingAdminsResetToken = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/admins/reset_token`

  return {
    type: actions.ONBOARDING_ADMINS_RESET_TOKEN,
    payload: handleAPI(url, {}, 'PUT'),
  }
}

export const onboardingCompleteStep = <T extends object>(data: T, type: string): Action => {
  return {
    type: actions.ONBOARDING_COMPLETE_STEP,
    payload: { data, type },
  }
}

export const onboardingResetToken = (): Action => {
  return {
    type: actions.ONBOARDING_RESET_TOKEN,
  }
}

export const onboardingSlugRest = (): Action => {
  return {
    type: actions.ONBOARDING_SLUG_RESET,
  }
}

export const loginFromOnboarding = (): Action => {
  return {
    type: actions.LOGIN_FROM_ONBOARDING,
  }
}
