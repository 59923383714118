import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const initialState = {
  info_change_request_workflows: [],
  info_change_request_workflow: {},
  loading: false,
  fetching: false,
}

export default function editProfileWorkflowReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHANGE_REQUEST_WORKFLOWS_LOAD_PENDING:
      return {
        ...state,
        loading: !(state.info_change_request_workflows.length > 0),
      }

    case actions.CHANGE_REQUEST_WORKFLOW_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        info_change_request_workflow: {},
      }

    case actions.CHANGE_REQUEST_WORKFLOW_DELETE_PENDING:
    case actions.CHANGE_REQUEST_WORKFLOW_CREATE_PENDING:
    case actions.CHANGE_REQUEST_WORKFLOW_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CHANGE_REQUEST_WORKFLOWS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        loading: false,
      }

    case actions.CHANGE_REQUEST_WORKFLOW_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.CHANGE_REQUEST_WORKFLOW_DELETE_FULFILLED:
      return {
        info_change_request_workflows: removeOneListOfRecords(
          state.info_change_request_workflows,
          action.payload.id,
        ),
      }

    case actions.CHANGE_REQUEST_WORKFLOW_CREATE_FULFILLED:
    case actions.CHANGE_REQUEST_WORKFLOW_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        succMsg: action.payload.data.message,
        fetching: false,
        loading: false,
      }

    case actions.CHANGE_REQUEST_WORKFLOWS_LOAD_REJECTED:
    case actions.CHANGE_REQUEST_WORKFLOW_LOAD_REJECTED:
    case actions.CHANGE_REQUEST_WORKFLOW_CREATE_REJECTED:
    case actions.CHANGE_REQUEST_WORKFLOW_UPDATE_REJECTED:
    case actions.CHANGE_REQUEST_WORKFLOW_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }
    default:
      return state
  }
}
