import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'
// import { Helmet } from 'react-helmet';
import JisrLoader from 'components/global/JisrLoader'

import PDFPage from './PDFPage'

const PrintLetterTemplate = ({
  header,
  footer,
  template,
  content,
  // title,
  fetching,
  letter_attachments,
}) => {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const formatContent = (value) => {
    if (letter_attachments && value) {
      Object.keys(letter_attachments)?.forEach((key) => {
        value = value?.replaceAll(`{{{${key}}}}`, letter_attachments[key])
      })
    }
    return value
  }

  React.useEffect(() => {
    if (template || content) handlePrint()
  }, [template, content])

  const text = template || content || ''
  const pages = text.split('<!-- pagebreak -->')

  return (
    <React.Fragment>
      {fetching && <JisrLoader fixed />}
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          {pages.map((page, i) => (
            <PDFPage
              key={`page-${i}`}
              header={formatContent(header)}
              footer={formatContent(footer)}
              content={formatContent(page)}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

PrintLetterTemplate.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.string,
  template: PropTypes.string,
  content: PropTypes.string,
  // title: PropTypes.string,
  fetching: PropTypes.bool,
  letter_attachments: PropTypes.objectOf(PropTypes.string),
}

export default PrintLetterTemplate
