import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { withLenses, lens } from '@dhmk/zustand-lens'
import { createEvaluationSlice, EvaluationSliceType } from './createEvaluationSlice'
import { createTemplateSlice, TemplateSliceType } from './createTemplateSlice'
import { createModalSlice, ModalSliceType } from './createModalSlice'

export type PerformanceStoreType = {
  EvaluationSteps: EvaluationSliceType
  Templates: TemplateSliceType
  PerformanceModal: ModalSliceType
}

export const usePerformanceStore = create<PerformanceStoreType>()(
  devtools(
    withLenses(() => ({
      EvaluationSteps: lens(createEvaluationSlice),
      Templates: lens(createTemplateSlice),
      PerformanceModal: lens(createModalSlice),
    })),
    {
      name: 'PerformanceStore',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
)
