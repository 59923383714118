import { Action } from 'types/redux'
import { IdTypes } from 'types/sharedResponse'
import { WorkableConnectFormValues } from 'containers/authorised/HiredCandidates/types'
import { API_VERSION_TWO, API_VERSION_ONE, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function connect(data: WorkableConnectFormValues): Action {
  return {
    type: actions.WORKABLE_CONNECT,
    payload: handleAPI(`/openapi/outbound/${API_VERSION_ONE}/workable/sessions`, {}, 'PUT', data),
  }
}

export function show(): Action {
  return {
    type: actions.SHOW,
    payload: handleAPI(`/openapi/outbound/${API_VERSION_ONE}/workable/sessions`, {}, 'GET'),
  }
}
export function syncCandidateList(): Action {
  return {
    type: actions.SYNC_CANDIDATE_LIST,
    payload: handleAPI(`/openapi/outbound/${API_VERSION_ONE}/candidates/sync`, {}, 'POST'),
  }
}

export function getCandidateList(
  params: {
    rpp: number
    page: number
    search?: string
    sort_by?: { [key: string]: string }
  },
  sort_by_key?: string,
  sort_order?: string,
): Action {
  if (sort_by_key && sort_order) {
    params.sort_by = {
      [sort_by_key]: sort_order,
    }
  }
  return {
    type: actions.GET_CANDIDATE_LIST,
    payload: handleAPI(`/${API_VERSION_TWO}/integration/candidates`, params, 'GET'),
  }
}

export function getCandidateImportData(external_id: IdTypes): Action {
  return {
    type: actions.GET_CANDIDATE_IMPORT_DATA,
    payload: handleAPI(`/${API_VERSION_TWO}/integration/candidates/${external_id}`, {}, 'GET'),
  }
}

export function clearCandidateData(): Action {
  return {
    type: actions.CLEAR_CANDIDATE_DATA,
    payload: {},
  }
}

export function resetForm(): Action {
  return {
    type: actions.WORKABLE_RESET_FORM_DATA,
  }
}
