import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  PolicyType,
  PolicyMember,
  EligibleEmployeePoliciesType,
  MemberDataFromElm,
  EndorsementType,
} from 'containers/authorised/BenefitsManagement/Insurance/types'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

type StateType = {
  fetching: boolean
  fetchingMembers: boolean
  syncing: boolean
  verifying: boolean
  submitting: boolean
  policiesList: PolicyType[]
  eligibleEmployeePolicies: EligibleEmployeePoliciesType[]
  policyDetails: PolicyType
  policyMembers: PolicyMember[]
  memberDetails: PolicyMember | null
  policyMembersPagination: PaginationTypes | Record<string, never>
  memberDataFromElm: MemberDataFromElm | Record<string, never>
  offboardInsuranceInfo: {
    insurance_policy_members: PolicyMember
    insurance_policy_endorsements: EndorsementType[]
    has_manage_health_insurance_permission: boolean
  } | null
}

export const initialState: StateType = {
  fetching: false,
  fetchingMembers: false,
  syncing: false,
  verifying: false,
  submitting: false,
  policiesList: [],
  eligibleEmployeePolicies: [],
  policyDetails: {
    id: '',
    policy_number: 0,
    unified_national_number: 0,
    name_i18n: '',
    total_members: 0,
    description: '',
    start_date: '',
    expiry_date: '',
    policy_url: '',
    insurance_type: {
      id: 0,
      name_i18n: '',
      code: '',
    },
    premium: 0,
    total_dependents: 0,
    total_employees: 0,
    total_not_linked_employees: 0,
    commercial_registration: {
      id: 0,
      registration_number: '',
      mol_registration_id: 0,
    },
    is_connected: false,
    has_connected_policy: false,
    not_connected_message: '',
  },
  policyMembers: [],
  memberDetails: null,
  policyMembersPagination: {},
  memberDataFromElm: {},
  offboardInsuranceInfo: null,
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.LOAD_POLICIES_LIST_PENDING:
    case actions.GET_ELIGIBLE_EMPLOYEE_POLICIES_PENDING:
    case actions.GET_POLICY_DETAILS_PENDING:
    case actions.GET_MEMBER_DETAILS_PENDING:
    case actions.GET_INSURANCE_INFO_IN_OFFBOARD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_POLICY_MEMBERS_PENDING:
      return {
        ...state,
        fetchingMembers: true,
      }

    case actions.VERIFY_MEMBER_REASON_PENDING:
    case actions.GET_MEMBER_DATA_FROM_ELM_PENDING:
      return {
        ...state,
        verifying: true,
      }

    case actions.SYNC_POLICIES_PENDING:
    case actions.SYNC_MEMBERS_PENDING:
      return {
        ...state,
        syncing: true,
      }

    case actions.EDIT_MEMBER_PENDING:
    case actions.CHANGE_MEMBER_ID_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.LOAD_POLICIES_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        policiesList: payload?.data?.data?.insurance_policies,
      }

    case actions.GET_POLICY_DETAILS_FULFILLED:
      return {
        ...state,
        fetching: false,
        policyDetails: payload?.data?.data?.insurance_policy,
      }

    case actions.GET_POLICY_MEMBERS_FULFILLED:
      return {
        ...state,
        fetchingMembers: false,
        policyMembers: payload?.data?.data?.insurance_policy_members,
        policyMembersPagination: payload?.data?.data?.pagination,
      }

    case actions.GET_ELIGIBLE_EMPLOYEE_POLICIES_FULFILLED:
      return {
        ...state,
        fetching: false,
        eligibleEmployeePolicies: payload?.data?.data?.insurance_policies,
      }

    case actions.GET_MEMBER_DETAILS_FULFILLED:
      return {
        ...state,
        fetching: false,
        memberDetails: payload?.data?.data?.health_insurance,
      }

    case actions.RESET_MEMBER_DETAILS:
      return {
        ...state,
        memberDetails: null,
      }
    case actions.RESET_MEMBER_OF_ELM:
      return {
        ...state,
        memberDataFromElm: {},
      }

    case actions.SYNC_POLICIES_FULFILLED:
    case actions.SYNC_MEMBERS_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        syncing: false,
      }

    case actions.GET_MEMBER_DATA_FROM_ELM_FULFILLED:
      return {
        ...state,
        verifying: false,
        memberDataFromElm: payload?.data?.data,
      }
    case actions.EDIT_MEMBER_FULFILLED:
    case actions.CHANGE_MEMBER_ID_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        submitting: false,
      }

    case actions.GET_INSURANCE_INFO_IN_OFFBOARD_FULFILLED:
      return {
        ...state,
        offboardInsuranceInfo: payload?.data?.data,
        fetching: false,
      }

    case actions.LOAD_POLICIES_LIST_REJECTED:
    case actions.GET_ELIGIBLE_EMPLOYEE_POLICIES_REJECTED:
    case actions.GET_POLICY_DETAILS_REJECTED:
    case actions.GET_INSURANCE_INFO_IN_OFFBOARD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_MEMBER_DATA_FROM_ELM_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        verifying: false,
      }

    case actions.GET_POLICY_MEMBERS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetchingMembers: false,
      }

    case actions.GET_MEMBER_DETAILS_REJECTED:
      return {
        ...state,
        fetching: false,
        memberDetails: null,
      }

    case actions.SYNC_POLICIES_REJECTED:
    case actions.SYNC_MEMBERS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        syncing: false,
      }

    case actions.EDIT_MEMBER_REJECTED:
    case actions.CHANGE_MEMBER_ID_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        submitting: false,
      }

    case actions.VERIFY_MEMBER_REASON_REJECTED:
    case actions.VERIFY_MEMBER_REASON_FULFILLED:
      return {
        ...state,
        verifying: false,
      }

    default:
      return state
  }
}
