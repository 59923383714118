import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CandidateResumeProps = {
  filterKey: string
  filters: Array<string>
}

const CandidateResume = (props: CandidateResumeProps): JSX.Element => {
  const { candidate_resume } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(candidate_resume)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (val !== 'all') {
      setFilter({ ...filter, candidate_resume: [val] })
    } else {
      setFilter({ ...filter, candidate_resume: ['all'] })
    }
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      isClearable={false}
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('label.resume')}
    />
  )
}

export default CandidateResume
