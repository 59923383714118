export const PM_ONBOARDING_LOAD = 'PM_ONBOARDING_LOAD'
export const PM_ONBOARDING_LOAD_PENDING = 'PM_ONBOARDING_LOAD_PENDING'
export const PM_ONBOARDING_LOAD_FULFILLED = 'PM_ONBOARDING_LOAD_FULFILLED'
export const PM_ONBOARDING_LOAD_REJECTED = 'PM_ONBOARDING_LOAD_REJECTED'

export const PM_ONBOARDING_UPDATE_STEPS = 'PM_ONBOARDING_UPDATE_STEPS'
export const PM_ONBOARDING_UPDATE_STEPS_PENDING = 'PM_ONBOARDING_UPDATE_STEPS_PENDING'
export const PM_ONBOARDING_UPDATE_STEPS_FULFILLED = 'PM_ONBOARDING_UPDATE_STEPS_FULFILLED'
export const PM_ONBOARDING_UPDATE_STEPS_REJECTED = 'PM_ONBOARDING_UPDATE_STEPS_REJECTED'

export const PM_ONBOARDING_COMPLETE = 'PM_ONBOARDING_COMPLETE'
export const PM_ONBOARDING_COMPLETE_PENDING = 'PM_ONBOARDING_COMPLETE_PENDING'
export const PM_ONBOARDING_COMPLETE_FULFILLED = 'PM_ONBOARDING_COMPLETE_FULFILLED'
export const PM_ONBOARDING_COMPLETE_REJECTED = 'PM_ONBOARDING_COMPLETE_REJECTED'
