import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const initialState = {
  asset_request_workflows: [],
  asset_request_workflow: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  isApplicableToAll: false,
  new: false,
}

let changes = null
export default function assetsRequestWorkflowReducer(state = initialState, action) {
  const { payload } = action
  let asset_request_workflows = []
  let newRecord = {}
  let updatedRecord = {}
  let is_applicable_to_all = ''
  switch (action.type) {
    case actions.ASSETS_REQUEST_WORKFLOWS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.asset_request_workflows.length > 0),
      }

    case actions.ASSETS_REQUEST_WORKFLOW_UPDATE_PENDING:
    case actions.ASSETS_REQUEST_WORKFLOW_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: true,
      }

    case actions.ASSETS_REQUEST_WORKFLOW_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        loading: true,
        asset_request_workflow: {},
      }

    case actions.ASSETS_REQUEST_WORKFLOWS_LOAD_FULFILLED:
    case actions.ASSETS_REQUEST_WORKFLOW_LOAD_FULFILLED:
    case actions.ASSETS_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED:
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case actions.ASSETS_REQUEST_WORKFLOW_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      asset_request_workflows = [...state.asset_request_workflows]
      newRecord = payload.data.data.asset_request_workflow
      changes = {
        loading: false,
        asset_request_workflow: {},
        asset_request_workflows: [...asset_request_workflows, newRecord],
        editing: {
          ...state.editing,
          new: false,
        },
      }
      return { ...state, ...changes }

    case actions.ASSETS_REQUEST_WORKFLOW_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      asset_request_workflows = [...state.asset_request_workflows]
      updatedRecord = payload.data.data.asset_request_workflow
      if (updatedRecord.is_default) {
        is_applicable_to_all = updatedRecord.is_applicable_to_all || false
      }
      return {
        ...state,
        loading: false,
        is_applicable_to_all,
        asset_request_workflows: updateOneListOfRecords(asset_request_workflows, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }

    case actions.ASSETS_REQUEST_WORKFLOWS_LOAD_REJECTED:
    case actions.ASSETS_REQUEST_WORKFLOW_LOAD_REJECTED:
    case actions.ASSETS_REQUEST_WORKFLOW_CREATE_REJECTED:
    case actions.ASSETS_REQUEST_WORKFLOW_DELETE_REJECTED:
    case actions.ASSETS_REQUEST_WORKFLOW_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case actions.ASSETS_REQUEST_WORKFLOW_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      asset_request_workflows = [...state.asset_request_workflows]
      return {
        ...state,
        // asset_request_workflows: removeOneListOfRecords(asset_request_workflows, id),
        error: null,
      }

    case actions.ASSETS_REQUEST_WORKFLOW_EDIT:
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case actions.ASSETS_REQUEST_WORKFLOW_EDIT_CANCEL:
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    case actions.RESET:
      changes = {
        overtime_request_workflow: {},
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
