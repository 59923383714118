import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { PaymentConfigurationType } from './reducer'

const isOnboarding = window.location.hash.includes('/onboarding')

const urlPrefix = isOnboarding ? 'onboarding/payroll/' : 'finance/'

export function paymentMethodsLoad(): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}commercial_registrations/payment_methods`
  return {
    type: actions.PAYMENT_METHODS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function paymentMethodLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}commercial_registrations/${id}/payment_method_details`
  return {
    type: actions.PAYMENT_METHOD_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

// ? offline endpoints
export function mudadIntegrationEnabled(): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}payrun_configurations/is_mudad_integration_enabled`
  return {
    type: actions.MUDAD_INTEGRATION_ENABLED,
    payload: handleAPI(url, {}, 'GET'),
  }
}

// ? local actions
export function updateSinglePaymentOfList(data: PaymentConfigurationType): Action {
  return {
    type: actions.PAYMENT_METHODS_UPDATE_ITEM_OF_LIST,
    payload: data,
  }
}
