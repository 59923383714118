import { KeyboardEvent, useState } from 'react'
import { TextField, Flex, Typography } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { sanitizeInput } from 'components/final-form/form.helper'
import type { ChatInputProps } from '../types'

const ChatInput = ({
  message,
  setMessage,
  sendMessage,
  handleWebSocketSetup,
  wsConnection,
}: ChatInputProps) => {
  const [handleSetupCalled, setHandleSetupCalled] = useState(false)

  const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage()
    }
  }

  const onMessageChange = (e: string) => {
    if ((wsConnection?.readyState === 0 || wsConnection?.readyState === 3) && !handleSetupCalled) {
      handleWebSocketSetup()
      setHandleSetupCalled(true)
    }
    if (handleSetupCalled && wsConnection?.readyState === 1) {
      setHandleSetupCalled(false)
    }
    const messageValue = sanitizeInput(e)
    setMessage(messageValue)
  }

  return (
    <Flex
      itemsCenter
      flexCol
      className="p-3 border-t  bg-base-colors-white-1000 shadow-[0_-8px_10px_-6px_rgba(0,0,0,0.08)]"
    >
      <TextField
        placeholder={i18n.t('label.message...')}
        onChange={onMessageChange}
        value={message}
        onKeyDown={onPressEnter}
        size="regular"
        trailingButton={{
          iconOnly: true,
          leadingIcon: 'send-03',
          onClick: sendMessage,
        }}
        type="text"
        testId="message-input"
      />
      <Typography
        textColor="color/fg/lighter"
        text={i18n.t('label.jisri_disclaimer')}
        variant="caption"
        className="mt-2"
      />
    </Flex>
  )
}

export default ChatInput
