import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  data: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.SESSIONS_VALIDATE_LINK_SHOW_PENDING:
    case actions.SESSIONS_REQUEST_NEW_LINK_POT_PENDING:
    case actions.REQUEST_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SESSIONS_VALIDATE_LINK_SHOW_FULFILLED:
      return {
        ...state,
        error: null,
        fetching: false,
        data: payload.data?.data,
      }

    case actions.SESSIONS_REQUEST_NEW_LINK_POT_FULFILLED:
    case actions.REQUEST_CHANGE_PASSWORD_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.SESSIONS_VALIDATE_LINK_SHOW_REJECTED:
      return {
        ...state,
        fetching: false,
        error: handleResponseErr(action.payload),
      }

    case actions.SESSIONS_REQUEST_NEW_LINK_POT_REJECTED:
    case actions.REQUEST_CHANGE_PASSWORD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
