import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Button } from '@jisr-hr/ds'
import { useFormState } from 'react-final-form'
import I18n from 'translations/i18n'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { isLogNextDay } from 'containers/authorised/attendance/helper'
import { parse } from 'date-fns'

import Text16 from './Text16'
import CorrectionBadge from './CorrectionBadge'
import { reportMethodIcons } from '../helper'

import Styles from '../ProposedCorrectionTime.module.css'

const DisplayPunch = ({ type, handleEdit }) => {
  const {
    values: { shift, correction_date, ...rest },
  } = useFormState()

  const correctionDetail = rest?.[type] || {}

  const isNext = isLogNextDay(
    parse(`${correctionDetail.date} ${correctionDetail.time}`, 'yyyy-MM-dd hh:mm a', new Date()),
    new Date(correction_date),
  )

  return (
    <React.Fragment>
      <Flex
        itemsCenter
        style={{ gap: 8 }}
      >
        <Text16 label={replaceAmPm(correctionDetail?.punch_time)} />

        <Flex
          flexWrap
          itemsCenter
          className={Styles.punchDetail}
        >
          {correctionDetail?.reporting_method_i18n && (
            <CorrectionBadge
              type="gray"
              label={correctionDetail?.reporting_method_i18n}
              icon={reportMethodIcons[correctionDetail?.reporting_method]}
            />
          )}

          {isNext && type === 'out_punch' && (
            <CorrectionBadge
              type="primary"
              label={I18n.t('next_day')}
            />
          )}

          <Button
            variant="ghost"
            size="small"
            label={I18n.t('edit')}
            leadingIcon="edit-02"
            onClick={handleEdit}
          />
        </Flex>
      </Flex>
    </React.Fragment>
  )
}

DisplayPunch.propTypes = {
  type: PropTypes.string,
  handleEdit: PropTypes.func,
}

export default DisplayPunch
