import _ from 'lodash'
import { ListDataTypes } from 'types/sharedComponents'

type GroupListTypes = Array<ListDataTypes>

type GroupedListTypes = Record<string, Array<ListDataTypes>>

export const getSortedListByGroup = (list: GroupListTypes, key: string): GroupedListTypes => {
  return _.mapValues(_.groupBy(list, key))
}
