import { AccordionGroup, Typography } from '@jisr-hr/ds-beta'
import { OnboardingOverviewContractRes } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview.type'
import {
  contentTranslationKeysMapping,
  translationKeysMapping,
} from 'components/authorised/employee/helper'
import { useState } from 'react'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { activeForm, OnboardingStepsEnum } from '../constants'

import { OnboardingForm } from './forms/OnboardingForm'
import { OnboardingFormSuccess } from './OnboardingFormSuccess'

type ApprovalType = {
  id: number
  request_type?: string
  can_cancel?: boolean
  request_type_i18n: string
  request_name_i18n: string
  assignee: {
    id: number
    name: string
    name_ar: string
    job_title_i18n: string
    avatar: string
    avatar_thumb: string
  }
  request: {
    id: number
    request_name_i18n: string
    status: string
    status_i18n: string
    type: string
    created_at: string
    onboarding_step_name: string
  }
}

export const ActionRequiredAccordion = ({
  overviewDetails,
}: {
  overviewDetails: OnboardingOverviewContractRes | undefined
}) => {
  const [activeStep, setActiveStep] = useState<OnboardingStepsEnum>(
    OnboardingStepsEnum.PERSONAL_INFO,
  )
  const [isEditing, setIsEditing] = useState(false)
  const [requestId, setRequestId] = useState<number | undefined>()
  const formSections = overviewDetails
    ? (Object.keys(overviewDetails) as Array<keyof OnboardingOverviewContractRes>)
    : []

  const { requests }: { requests: Array<ApprovalType> } = useSelector((state) => state.approvals)
  const hasRequests = requests?.length > 0

  const formSectionsRequired = formSections.filter((item) =>
    requests.map((request) => request.request.onboarding_step_name).includes(item),
  )

  if (isEditing) {
    return (
      <OnboardingForm
        clarification
        activeForm={activeStep}
        requestId={requestId}
      />
    )
  }

  return hasRequests ? (
    <div className="max-w-[800px] mx-auto">
      <AccordionGroup
        accordionList={formSectionsRequired.map((section) => {
          const sectionDetails =
            formSectionsRequired.length && overviewDetails ? overviewDetails[section] : []
          return {
            title: translationKeysMapping[section],
            badgeProps: {
              label: i18n.t('label.action_required'),
              color: 'red',
            },
            linkProps: {
              label: i18n.t('label.view'),
              onClick: () => {
                setActiveStep(activeForm[section])
                setIsEditing(true)
                const reqId = requests.find(
                  (request) => request.request.onboarding_step_name === section,
                )?.request?.id
                if (reqId) {
                  setRequestId(reqId)
                }
                mixPanelAnalytics({
                  event_name: 'Employee Onboarding Form Editing Started',
                  properties: {
                    flow_type: `${section}`,
                  },
                })
              },
            },
            children: (
              <div className="px-5">
                <ul className="list-disc px-2 space-y-1 custom-bullet-color">
                  {sectionDetails?.map((item, index) => (
                    <li key={index}>
                      <Typography
                        text={contentTranslationKeysMapping[item]}
                        textColor="color/fg/lighter"
                        variant="subtitle-2"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ),
          }
        })}
        color="white"
        isRounded
        size="medium"
        spacing="md"
      />
    </div>
  ) : (
    <OnboardingFormSuccess />
  )
}
