import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  configuration: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function vacationSalaryReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'VACATION_SALARY_LOAD_PENDING':
    case 'VACATION_SALARY_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'VACATION_SALARY_REJECTED':
      Toastr.error(payload)
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case 'VACATION_SALARY_LOAD_FULFILLED':
    case 'VACATION_SALARY_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        configuration: payload.data.data.configuration,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
