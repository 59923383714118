/** **************
 *   Auth Reducer
 /*************** */

// import { handleResponseErr } from 'utils/apiHelperUtils';
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  employees: [],
  employee_insurance: {},
  fetching: false,
}

let changes = null

export default function employeeInsuranceReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let updatedRecord = {}
  switch (action.type) {
    /*
     * On Success
     * Action: put success message and run onSuccess function from actions
     */
    case 'INSURANCE_LOAD_PENDING':
    case 'INSURANCE_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'INSURANCE_LOAD_REJECTED':
    case 'INSURANCE_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'INSURANCE_LOAD_FULFILLED':
      changes = {
        employee_insurance: action.payload.data.data.employee_insurance,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'INSURANCE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.employee_insurance
      changes = {
        employee_insurance: updatedRecord,
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
