import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import { updateOneListOfRecordsByFindIndex } from 'containers/authorised/setting/v2/payroll/Payables/components/helper'

export type City = {
  id: number
  name: string
}

type BusinessTripCityState = {
  cities: City[]
  editing: { New?: boolean; [key: number]: boolean }
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
  loading: boolean
  error?: null
}

const DEFAULT_STATE: BusinessTripCityState = {
  cities: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}

export default function cityReducer(state = DEFAULT_STATE, action: Action): BusinessTripCityState {
  const { payload } = action
  switch (action.type) {
    case 'BUSINESS_TRIP_CITIES_LOAD_PENDING':
    case 'BUSINESS_TRIP_CITY_CREATE_PENDING':
    case 'BUSINESS_TRIP_CITY_UPDATE_PENDING':
    case 'BUSINESS_TRIP_CITY_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.cities.length > 0),
      }

    case 'BUSINESS_TRIP_CITIES_LOAD_REJECTED':
    case 'BUSINESS_TRIP_CITY_CREATE_REJECTED':
    case 'BUSINESS_TRIP_CITY_UPDATE_REJECTED':
    case 'BUSINESS_TRIP_CITY_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, errMsg: handleResponseErr(payload), fetching: false, loading: false }

    case 'BUSINESS_TRIP_CITIES_LOAD_FULFILLED':
      return {
        ...state,
        cities: payload.data.data.cities,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }

    case 'BUSINESS_TRIP_CITY_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      const newRecord = payload.data.data.city
      return {
        ...state,
        fetching: false,
        cities: [...state.cities, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }

    case 'BUSINESS_TRIP_CITY_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      const cities = [...state.cities]
      const updatedRecord = payload.data.data.city
      updateOneListOfRecordsByFindIndex(cities, updatedRecord)
      return {
        ...state,
        cities,
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }

    case 'BUSINESS_TRIP_CITY_DELETE_FULFILLED':
      Toastr.success(payload.data.message)
      return {
        ...state,
        cities: removeOneListOfRecords(state.cities, payload.id),
        error: null,
        fetching: false,
      }

    default:
      return state
  }
}
