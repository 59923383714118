import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type FormStatusProps = {
  filterKey: string
  filters: Array<string>
}

const FormStatus = (props: FormStatusProps): JSX.Element => {
  const { form_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={form_status}
      selectedIds={filter[props.filterKey]}
      {...props}
      title="label.status"
    />
  )
}

export default FormStatus
