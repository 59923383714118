import { Typography } from '@jisr-hr/ds'
import { ListDataTypes } from 'types/sharedComponents'
import CheckItem from './CheckItem'

type GroupCheckListProps = {
  labelKey?: string
  valueKey?: string
  title: string
  list: Array<ListDataTypes>
  selected: Array<number>
  onChange: (data: { checked: boolean; value: number }) => void
}

const GroupCheckList = (props: GroupCheckListProps): JSX.Element => {
  const { list, selected, labelKey, valueKey, onChange, title } = props
  return (
    <>
      {title && (
        <Typography
          text={title}
          variant="body-new/regular"
        />
      )}
      <ul>
        {list.map((d, i) => (
          <CheckItem
            key={i}
            data={d}
            onChange={onChange}
            selected={selected}
            labelKey={labelKey}
            valueKey={valueKey}
          />
        ))}
      </ul>
    </>
  )
}

export default GroupCheckList
