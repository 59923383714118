import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function announcementsCreate(data) {
  const url = `/${API_VERSION_TWO}/notifications`
  const method = 'POST'
  return {
    type: 'ANNOUNCEMENTS_CREATE',
    payload: handleAPI(url, {}, method, data),
  }
}
