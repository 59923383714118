import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Grid } from 'components/global/atoms'
import { Spacer, Flex, Button, Typography } from '@jisr-hr/ds'
import cns from 'classnames'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import {
  onboardingHolidaysLoad,
  onboardingHolidaysDefaultLoad,
  createOnboardingHolidays,
  deleteOnboardingHolidays,
  UpdateOnboardingHolidays,
} from 'redux/leavesOnboarding/holidays/actionCreators'
import { DefaultHolidays, THolidaysData } from 'redux/leavesOnboarding/holidays/type'
import I18n from 'translations/i18n'
import FormCustomLeave from '../../Holidays/FormCustomLeave'
import styles from '../style.module.css'

type SubmitCheckbox = {
  id?: number
  status?: boolean
  value?: DefaultHolidays
}

const Holidays = (): JSX.Element => {
  const lang = I18n.language
  const dispatch = useDispatch()
  const { holidays, fetching, default_holidays } = useSelector(
    ({ onboardingHolidays }) => onboardingHolidays,
  )

  const [openOthers, setOpenOthers] = useState<boolean>(false)
  const [dataEdit, setDataEdit] = useState<THolidaysData | Record<string, never>>({})
  const defaultData = default_holidays.map((e) => e.name)
  const checkedDefaultData = holidays.map((e) => e.name)

  useEffect(() => {
    dispatch(onboardingHolidaysLoad())
    dispatch(onboardingHolidaysDefaultLoad())
  }, [dispatch])

  const onSubmit = (value: THolidaysData): void => {
    if (value?.id) {
      dispatch(UpdateOnboardingHolidays(value)).then(() => {
        dispatch(onboardingHolidaysLoad())
        setOpenOthers(false)
      })
      return
    }
    dispatch(createOnboardingHolidays(value)).then(() => {
      dispatch(onboardingHolidaysLoad())
      setOpenOthers(false)
    })
  }

  const onSubmitCheckbox = ({ status, value, id }: SubmitCheckbox): void => {
    if (status && value) {
      dispatch(createOnboardingHolidays(value)).then(() => dispatch(onboardingHolidaysLoad()))
      return
    }
    if (id) {
      dispatch(deleteOnboardingHolidays(id)).then(() => dispatch(onboardingHolidaysLoad()))
      return
    }
    const holiday = holidays.find((e) => e.name === value?.name)
    if (holiday?.id) {
      dispatch(deleteOnboardingHolidays(holiday?.id)).then(() => dispatch(onboardingHolidaysLoad()))
    }
  }

  return (
    <>
      <Flex
        justifyBetween
        itemsCenter
      >
        <Typography
          variant="heading"
          text={I18n.t('public_holidays')}
        />

        <Button
          label={I18n.t('new_leave')}
          size="medium"
          onClick={(): void => {
            setDataEdit({
              name: '',
              name_ar: '',
              to_date: '',
              from_date: '',
              extend_probation: true,
              group_type: 'all_company',
            })
            setOpenOthers(true)
          }}
          trailingIcon="plus"
        />
      </Flex>

      <Spacer height="8px" />
      <div className={styles.divider} />
      <Spacer height={16} />
      <Flex flexCol>
        <Flex
          flexCol
          className={cns(styles.cardsContainer, 'scroll-y')}
        >
          <Grid
            container
            spacing={2}
          >
            {default_holidays.map((holiday, i) => (
              <Grid
                item
                xs={6}
                key={i}
                style={{ paddingInline: 4 }}
              >
                <SwitchCard
                  name={lang === 'en' ? holiday.name : holiday.name_ar}
                  isActive={checkedDefaultData.includes(holiday.name)}
                  handleOnEdit={(): void => {
                    const data = holidays.find((e) => e.name === holiday.name)
                    if (data) {
                      setDataEdit({
                        id: data.id,
                        name: data.name,
                        name_ar: data.name_ar,
                        to_date: data.to_date,
                        from_date: data.from_date,
                        group_type: data.group_type,
                        extend_probation: data.extend_probation,
                      })
                      setOpenOthers(true)
                    }
                  }}
                  handleOnChange={(status: boolean): void => {
                    const dataCheckbox = {
                      status,
                      value: holiday,
                    }
                    onSubmitCheckbox(dataCheckbox)
                  }}
                />
              </Grid>
            ))}
            {holidays?.map((item) => {
              if (!defaultData.includes(item.name)) {
                return (
                  <Grid
                    item
                    xs={6}
                    key={item.id}
                    style={{ paddingInline: 4 }}
                  >
                    <SwitchCard
                      name={item.name_i18n}
                      isActive
                      handleOnEdit={(): void => {
                        setDataEdit(item)
                        setOpenOthers(true)
                      }}
                      handleOnChange={(): void => onSubmitCheckbox({ id: item.id })}
                    />
                  </Grid>
                )
              }
              return null
            })}
          </Grid>
        </Flex>
      </Flex>
      <FormCustomLeave
        open={openOthers}
        onSubmit={onSubmit}
        fetching={fetching}
        onClose={setOpenOthers}
        initialValues={dataEdit}
        listDisabled={defaultData}
      />
    </>
  )
}

export default Holidays
