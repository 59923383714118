import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { SubdomainInput } from '@jisr-hr/design-system'

const SubdomainInputField = ({ name, validate, parse, testId, ...props }) => (
  <Field
    name={name}
    validate={validate}
    parse={parse}
  >
    {({ input, meta }) => (
      <SubdomainInput
        status={meta.error && meta.touched && 'error'}
        statusMessage={meta.error}
        {...props}
        {...input}
        data-testid={testId}
      />
    )}
  </Field>
)

SubdomainInputField.propTypes = {
  name: PropTypes.string,
  testId: PropTypes.string,
  validate: PropTypes.func,
  parse: PropTypes.func,
}

export default SubdomainInputField
