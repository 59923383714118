import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationExtendedTypes } from 'types/sharedResponse'
import * as actions from './actions'

type StateType = {
  authorizeUsers: {
    avatar_thumb: string
    code: string
    full_name_i18n: string
    id: string
    identification_info: {
      document_number: string
      document_type: string
      passport_number: string
    }
    is_super_admin: boolean
    job_title: string
  }[]
  eligiablUsers: {
    avatar_thumb: string
    code: string
    full_name_i18n: string
    id: string
    identification_info: {
      document_number: string
      document_type: string
      passport_number: string
    }
    is_super_admin: boolean
    job_title: string
  }[]
  employees: {
    avatar_thumb: string
    code: string
    full_name_i18n: string
    id: string
    identification_info: {
      document_number: string
      document_type: string
      passport_number: string
    }
    is_super_admin: boolean
    job_title: string
  }[]
  errorMsg: string
  fetching: boolean
  is_connected: boolean
  loading: boolean
  lookupsCities: {
    code: string
    nameAr: string
    nameEn: string
  }[]
  lookupsCountries: {
    code: string
    nameAr: string
    nameEn: string
  }[]
  lookupsMaritalStatuses: {
    code: string
    nameAr: string
    nameEn: string
  }[]
  message: string
  operationReport: {
    company: string
    date: string
    description: string
    errorMessage: string
    iqamaNumber: string
    requestNumber: string
    type: string
    user: string
  }[]
  successResponse: unknown
  visa: {
    duration: string
    entry_type: string
    issue_date: Date
    number: string
    return_date: string
    return_date_hijri: string
    visa_status: string
    visa_status_i18n: string
    visa_type: string
    visa_type_i18n: string
  }

  visas: {
    duration: string
    employee: {
      avatar_thumb: string
      code: string
      full_name_i18n: string
      id: string
      identification_info: {
        document_number: string
        document_type: string
        passport_number: string
      }
      job_title: string
    }
    entry_type: string
    issue_date: string
    number: string
    return_date: string
    return_date_hijri: string
    visa_status: string
    visa_status_i18n: string
    visa_type: string
    visa_type_i18n: string
  }[]
  pagination: PaginationExtendedTypes
}

const DEFAULT_STATE: StateType = {
  fetching: false,
  loading: false,
  errorMsg: '',
  message: '',
  employees: [],
  authorizeUsers: [],
  eligiablUsers: [],
  successResponse: {},
  lookupsCities: [],
  lookupsCountries: [],
  lookupsMaritalStatuses: [],
  operationReport: [],
  visa: {
    duration: '',
    entry_type: '',
    issue_date: new Date(),
    number: '',
    return_date: '',
    return_date_hijri: '',
    visa_status: '',
    visa_type: '',
    visa_status_i18n: '',
    visa_type_i18n: '',
  },
  visas: [],
  is_connected: true,
  pagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
}

export default function reducer(state = DEFAULT_STATE, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.MUQEEM_RENEW_PASSPORT_PENDING:
    case actions.MUQEEM_EXTEND_PASSPORT_PENDING:
    case actions.MUQEEM_EXIT_REENTRY_VISA_ISSUE_PENDING:
    case actions.MUQEEM_EXIT_REENTRY_VISA_EXTEND_PENDING:
    case actions.MUQEEM_EXIT_REENTRY_VISA_CANCEL_PENDING:
    case actions.MUQEEM_LOOKUP_PENDING:
    case actions.MUQEEM_LOOKUP_COUNTRIES_PENDING:
    case actions.MUQEEM_LOOKUP_MARITAL_STATUSES_PENDING:
    case actions.GET_MUQEEM_REPORT_PENDING:
    case actions.MUQEEM_FINAL_EXIT_VISA_ISSUE_PENDING:
    case actions.MUQEEM_FINAL_EXIT_VISA_CANCEL_PENDING:
    case actions.GET_MUQEEM_OPERATION_REPORT_PENDING:
    case actions.GET_MUQEEM_VISAS_PENDING:
    case actions.GET_MUQEEM_LAST_VISAS_PENDING:
    case actions.MUQEEM_EXIT_REENTRY_VISA_PRINT_PENDING:
    case actions.MUQEEM_RENEW_IQAMA_PENDING:
    case actions.MUQEEM_ISSUE_IQAMA_PENDING:
    case actions.MUQEEM_TRANSFER_IQAMA_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.MUQEEM_LOGIN_LOAD_PENDING:
    case actions.GET_MUQEEM_EMPLOYEES_LOAD_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.MUQEEM_LOGIN_LOAD_FULFILLED: {
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      if (action?.payload?.data?.error) Toastr.error(action?.payload?.data?.error)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.GET_MUQEEM_EMPLOYEES_LOAD_FULFILLED: {
      return {
        ...state,
        loading: false,
        employees:
          payload.data.data.pagination.current_page !== 1
            ? [...state.employees, ...payload?.data?.data?.employees]
            : payload?.data?.data?.employees,
        pagination: payload?.data?.data?.pagination,
      }
    }

    case actions.GET_MUQEEM_VISAS_FULFILLED:
    case actions.GET_MUQEEM_LAST_VISAS_FULFILLED: {
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      return {
        ...state,
        ...payload?.data?.data,
        fetching: false,
      }
    }
    case actions.MUQEEM_LOOKUP_FULFILLED: {
      return {
        ...state,
        lookupsCities: payload?.data?.data,
        fetching: false,
      }
    }
    case actions.MUQEEM_LOOKUP_COUNTRIES_FULFILLED: {
      return {
        ...state,
        lookupsCountries: payload?.data?.data,
        fetching: false,
      }
    }
    case actions.MUQEEM_LOOKUP_MARITAL_STATUSES_FULFILLED: {
      return {
        ...state,
        lookupsMaritalStatuses: payload?.data?.data,
        fetching: false,
      }
    }
    case actions.MUQEEM_RENEW_IQAMA_FULFILLED:
    case actions.MUQEEM_ISSUE_IQAMA_FULFILLED:
    case actions.MUQEEM_TRANSFER_IQAMA_FULFILLED:
    case actions.MUQEEM_RENEW_PASSPORT_FULFILLED:
    case actions.MUQEEM_EXTEND_PASSPORT_FULFILLED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_ISSUE_FULFILLED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_CANCEL_FULFILLED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_EXTEND_FULFILLED: {
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      return {
        ...state,
        fetching: false,
        successResponse: payload?.data?.data,
        errorMsg: action?.payload?.data?.error,
        message: action?.payload?.data?.message,
      }
    }
    case actions.MUQEEM_FINAL_EXIT_VISA_CANCEL_FULFILLED:
    case actions.MUQEEM_FINAL_EXIT_VISA_ISSUE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        successResponse: payload?.data?.data?.mainResident,
        message: action?.payload?.data?.message,
      }
    }
    case actions.MUQEEM_EXIT_REENTRY_VISA_PRINT_FULFILLED:
    case actions.GET_MUQEEM_REPORT_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.GET_MUQEEM_OPERATION_REPORT_FULFILLED: {
      return {
        ...state,
        fetching: false,
        operationReport: payload?.data?.data,
      }
    }
    case actions.MUQEEM_RENEW_PASSPORT_REJECTED:
    case actions.MUQEEM_RENEW_IQAMA_REJECTED:
    case actions.MUQEEM_ISSUE_IQAMA_REJECTED:
    case actions.MUQEEM_TRANSFER_IQAMA_REJECTED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_ISSUE_REJECTED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_CANCEL_REJECTED:
    case actions.MUQEEM_EXIT_REENTRY_VISA_EXTEND_REJECTED:
    case actions.MUQEEM_LOOKUP_REJECTED:
    case actions.MUQEEM_LOOKUP_COUNTRIES_REJECTED:
    case actions.MUQEEM_LOOKUP_MARITAL_STATUSES_REJECTED:
    case actions.MUQEEM_FINAL_EXIT_VISA_ISSUE_REJECTED:
    case actions.MUQEEM_FINAL_EXIT_VISA_CANCEL_REJECTED:
    case actions.MUQEEM_EXTEND_PASSPORT_REJECTED:
    case actions.GET_MUQEEM_LAST_VISAS_REJECTED:
    case actions.GET_MUQEEM_VISAS_REJECTED: {
      const isConnected = action.payload?.response?.data?.data?.is_connected
      return {
        ...state,
        fetching: false,
        errorMsg: action?.payload?.response?.data?.error,
        is_connected: typeof isConnected === 'boolean' ? isConnected : true,
      }
    }
    case actions.GET_MUQEEM_OPERATION_REPORT_REJECTED: {
      if (action?.payload?.response) Toastr.error(action?.payload?.response.data.error)
      const isConnected = action.payload?.response?.data?.data?.is_connected
      return {
        ...state,
        fetching: false,
        is_connected: typeof isConnected === 'boolean' ? isConnected : true,
      }
    }
    case actions.MUQEEM_EXIT_REENTRY_VISA_PRINT_REJECTED:
    case actions.GET_MUQEEM_REPORT_REJECTED: {
      const isConnected = action?.payload?.data?.is_connected
      return {
        ...state,
        fetching: false,
        errorMsg: action?.payload?.error,
        is_connected: typeof isConnected === 'boolean' ? isConnected : true,
      }
    }
    case actions.MUQEEM_LOGIN_LOAD_REJECTED:
    case actions.GET_MUQEEM_EMPLOYEES_LOAD_REJECTED: {
      if (action?.payload?.response) Toastr.error(action?.payload?.response.data.error)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.RESET_MUQEEMS_SERVICE: {
      return {
        ...DEFAULT_STATE,
        visas: state.visas,
      }
    }

    default:
      return state
  }
}
