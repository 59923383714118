export const ONBOARDING_OUTSOURCING_COMPANIES_LOAD = 'ONBOARDING_OUTSOURCING_COMPANIES_LOAD'
export const ONBOARDING_OUTSOURCING_COMPANIES_LOAD_PENDING =
  'ONBOARDING_OUTSOURCING_COMPANIES_LOAD_PENDING'
export const ONBOARDING_OUTSOURCING_COMPANIES_LOAD_FULFILLED =
  'ONBOARDING_OUTSOURCING_COMPANIES_LOAD_FULFILLED'
export const ONBOARDING_OUTSOURCING_COMPANIES_LOAD_REJECTED =
  'ONBOARDING_OUTSOURCING_COMPANIES_LOAD_REJECTED'

export const ONBOARDING_OUTSOURCING_COMPANIES_SHOW = 'ONBOARDING_OUTSOURCING_COMPANIES_SHOW'
export const ONBOARDING_OUTSOURCING_COMPANIES_SHOW_PENDING =
  'ONBOARDING_OUTSOURCING_COMPANIES_SHOW_PENDING'
export const ONBOARDING_OUTSOURCING_COMPANIES_SHOW_FULFILLED =
  'ONBOARDING_OUTSOURCING_COMPANIES_SHOW_FULFILLED'
export const ONBOARDING_OUTSOURCING_COMPANIES_SHOW_REJECTED =
  'ONBOARDING_OUTSOURCING_COMPANIES_SHOW_REJECTED'

export const ONBOARDING_OUTSOURCING_COMPANIES_POST = 'ONBOARDING_OUTSOURCING_COMPANIES_POST'
export const ONBOARDING_OUTSOURCING_COMPANIES_POST_PENDING =
  'ONBOARDING_OUTSOURCING_COMPANIES_POST_PENDING'
export const ONBOARDING_OUTSOURCING_COMPANIES_POST_FULFILLED =
  'ONBOARDING_OUTSOURCING_COMPANIES_POST_FULFILLED'
export const ONBOARDING_OUTSOURCING_COMPANIES_POST_REJECTED =
  'ONBOARDING_OUTSOURCING_COMPANIES_POST_REJECTED'

export const ONBOARDING_OUTSOURCING_COMPANIES_PUT = 'ONBOARDING_OUTSOURCING_COMPANIES_PUT'
export const ONBOARDING_OUTSOURCING_COMPANIES_PUT_PENDING =
  'ONBOARDING_OUTSOURCING_COMPANIES_PUT_PENDING'
export const ONBOARDING_OUTSOURCING_COMPANIES_PUT_FULFILLED =
  'ONBOARDING_OUTSOURCING_COMPANIES_PUT_FULFILLED'
export const ONBOARDING_OUTSOURCING_COMPANIES_PUT_REJECTED =
  'ONBOARDING_OUTSOURCING_COMPANIES_PUT_REJECTED'

export const ONBOARDING_OUTSOURCING_COMPANIES_DELETE = 'ONBOARDING_OUTSOURCING_COMPANIES_DELETE'
export const ONBOARDING_OUTSOURCING_COMPANIES_DELETE_PENDING =
  'ONBOARDING_OUTSOURCING_COMPANIES_DELETE_PENDING'
export const ONBOARDING_OUTSOURCING_COMPANIES_DELETE_FULFILLED =
  'ONBOARDING_OUTSOURCING_COMPANIES_DELETE_FULFILLED'
export const ONBOARDING_OUTSOURCING_COMPANIES_DELETE_REJECTED =
  'ONBOARDING_OUTSOURCING_COMPANIES_DELETE_REJECTED'
