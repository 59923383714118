import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { handleResponce } from './helper'

export const initialState = {
  fetching: false,
  report: {
    main_header: [],
    header: [],
    records: [],
  },
  api_pagination: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_INFORMATION_CHANGES_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EMPLOYEE_INFORMATION_CHANGES_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        report: handleResponce(action.payload.data.data),
        api_pagination: action.payload.data.data.pagination,
        fetching: false,
      }
    }

    case actions.EMPLOYEE_INFORMATION_CHANGES_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
