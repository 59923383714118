import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'translations/i18n'
import {
  requestDetailsLoad,
  resetRequestDetail,
} from 'redux/attendance/timesheets/requestDetails/actionCreators'
import { useHistory } from 'react-router-dom'
import {
  approveRequest,
  cancellationRequestsCreate,
  rejectRequest,
  requestCancel,
  superAdminCancelingRequest,
} from 'redux/requests/actionCreators'
import { removeRequest } from 'redux/requests/newRequestsApproval/actionCreators'
import { clearCandidateData } from 'redux/setting/integration/Workable/actionCreators'
import { useUsersnapApi } from 'components/UserSnap/UsersnapContext'
import { Toastr } from 'components/global/Toastr'
import { requestsWithCancellationFlow } from '../helper'
import { useRequest } from '../RequestProvider'

const useRequestDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { fetching, request: details } = useSelector(({ requestDetails }) => requestDetails)
  const { loading } = useSelector(({ requests }) => requests)

  const usersnapApi = useUsersnapApi()

  const {
    vars: { employee_id, request_id, sequential_id, request_type, request: req },
    setOnReject,
    setOnSubmit,
    drawerToggle,
    actionCallback,
    setOnCancel,
    setOnSuperAdminCancel,
    setCanApprove,
    editRequest,
    enableHittingRequestListener,
  } = useRequest()

  const request = {
    ...(req?.organization_task?.id && { isTask: true }),
    ...req,
    ...req?.request,
    ...details,
  }

  const approvalId = request?.approval?.id || request?.id

  const loadRequestDetail = () => {
    dispatch(
      requestDetailsLoad(
        employee_id || request?.employee?.id,
        sequential_id ? { sequential_id } : { request_id: request_id || request?.request?.id },
      ),
    )
  }

  const successCallBack = () => {
    drawerToggle(false)
    actionCallback()
  }

  const onSuccess = () => {
    dispatch(removeRequest(approvalId))
    successCallBack()
  }

  const handleApprove = () => {
    dispatch(approveRequest(request?.approval.id, {}, true)).then((res) => {
      const isValidEmployee = res?.value?.data?.data?.request?.request?.is_valid_employee
      const employeeId = res?.value?.data?.data?.request?.request?.creator?.id
      successCallBack()
      if (request?.is_onboarding_employee && isValidEmployee) {
        dispatch(clearCandidateData())
        // @ts-expect-error Need to useUsersnapApi.js to TypeScript
        usersnapApi?.logEvent('added_via_onboarding')
        Toastr.success('communication.employee_profile_created', '', {
          actions: true,
          dismissLabel: '',
          undoLabel: i18n.t('label.view'),
          onCloseUndo: () => history.push(`/employees/${employeeId}`),
        })
      }
    })
  }

  const handleReject = (comments) => {
    dispatch(rejectRequest(request?.approval?.id, { comments }, true)).then(successCallBack)
  }

  const handleCancel = (comments) => {
    if (
      request?.status?.toLowerCase() === 'approved' &&
      requestsWithCancellationFlow.includes(request.request_type)
    ) {
      return dispatch(
        cancellationRequestsCreate(request?.employee?.id, {
          request_id: request?.request?.id || request_id,
          request_type: request?.request_type,
          reason: comments,
        }),
      ).then(onSuccess)
    }
    return dispatch(requestCancel(approvalId, {}, request?.request_type)).then(onSuccess)
  }

  const handleCancellation = (comment) => {
    const params = {
      reason: comment,
      request_id: request?.id || request_id,
      request_type: request?.request_type,
    }

    dispatch(cancellationRequestsCreate(request?.employee?.id, params)).then(successCallBack)
  }

  const actionsValidate = () => {
    if (!request.status) return false
    if (request?.status?.toLowerCase() !== 'pending' || request?.view_only) {
      setCanApprove(false)
    }

    if (!editRequest) {
      setOnSubmit(handleApprove)
    }
    setOnReject(request?.status?.toLowerCase() === 'approved' ? handleCancellation : handleReject)
    setOnCancel(handleCancel)
    return true
  }

  const handleSuperAdminCancel = () => {
    return dispatch(
      superAdminCancelingRequest(request?.employee?.id, {
        request_id: request?.id || request?.request?.id,
        request_type: request?.request_type || request_type,
      }),
    ).then(onSuccess)
  }

  React.useEffect(() => {
    actionsValidate()
    setOnSuperAdminCancel(handleSuperAdminCancel)
  }, [details, req])

  React.useEffect(() => {
    setOnCancel(handleCancel)
    if (
      enableHittingRequestListener &&
      !request?.organization_task?.id &&
      (request?.id || request_id || sequential_id)
    ) {
      loadRequestDetail()
    }

    return () => {
      dispatch(resetRequestDetail())
    }
  }, [])

  return {
    request: {
      ...(request?.is_cancellation_request && { ...request?.request }),
      ...(request?.leave_request && { ...request?.leave_request }), // this for leave Resumption
      ...request,
    },
    reloadRequest: loadRequestDetail,
    loading: fetching || loading,
  }
}

export default useRequestDetail
