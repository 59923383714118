import { Button, Modal } from '@jisr-hr/ds'
import { DatePicker } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { useState } from 'react'
import AddComment from '../../Footer/AddComment'

type PropsType = {
  confirm: {
    open: string
    task_id: number
  } | null
  onClose: () => void
  handelPaymentDate: (paid_at: string | null) => void
  handelRejectionTask: (comment: string) => Promise<void>
  disabled: boolean
}

const TaskConfirmModal = (props: PropsType): JSX.Element => {
  const { confirm, disabled } = props
  const [paidAt, setPaidAt] = useState<null | string>(null)

  return (
    <Modal
      open={confirm?.open ? ['paid_at', 'reject'].includes(confirm?.open) : false}
      size="small"
      onClose={props.onClose}
    >
      {confirm?.open === 'paid_at' ? (
        <>
          <DatePicker
            width="250px"
            label={i18n.t('payment_date')}
            textFieldProps={{
              label: i18n.t('payment_date'),
            }}
            triggerType="input"
            value={paidAt ? new Date(paidAt || '') : undefined}
            onChange={(date: string | string[]): void => setPaidAt(date as string)}
          />
          <div className="flex gap-2 mt-[30px]">
            <Button
              label={i18n.t('submit')}
              size="small"
              variant="filled"
              disabled={!paidAt || disabled}
              onClick={(): void => props.handelPaymentDate(paidAt)}
              testId="reject-comment-btn"
            />
            <Button
              label={i18n.t('cancel')}
              size="small"
              variant="outlined"
              color="neutral"
              onClick={(): void => {
                props.onClose()
                setPaidAt(null)
              }}
              testId="cancel-comment-btn"
            />
          </div>
        </>
      ) : (
        <AddComment
          onSubmit={props.handelRejectionTask}
          onCancel={props.onClose}
          fieldLabel={i18n.t('label.rejection_reason')}
          fieldPlaceholder={i18n.t('placeholder.enter_the_rejection_reason')}
          confirmLabel={undefined}
          disabled={disabled}
        />
      )}
    </Modal>
  )
}

export default TaskConfirmModal
