export const ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW =
  'ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW'
export const ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_PENDING =
  'ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_PENDING'
export const ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_FULFILLED =
  'ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_FULFILLED'
export const ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_REJECTED =
  'ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW_REJECTED'

export const ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST = 'ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST'
export const ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_PENDING =
  'ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_PENDING'
export const ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_FULFILLED =
  'ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_FULFILLED'
export const ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_REJECTED =
  'ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST_REJECTED'

export const ONBOARDING_QUESTIONS_RESET = 'ONBOARDING_QUESTIONS_RESET'
