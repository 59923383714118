import { showToast, toastProps } from '@jisr-hr/ds-beta'
import { isString } from 'lodash'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'

type OptionsT = Omit<toastProps, 'label' | 'type' | 'description'>

const handleType = (type: string): 'danger' | 'info' | 'warning' | 'success' | undefined => {
  if (type === 'error') {
    return 'danger'
  }
  if (['success', 'warning', 'info'].includes(type)) {
    return type as 'success' | 'warning' | 'info'
  }
  return undefined
}

const handleMessage = (message?: string | null): string => {
  if (!isString(message)) {
    return i18n.t('label.404_title')
  }
  return message ?? ''
}

const handleShowToastr =
  (message?: string | null, title?: string, options?: OptionsT) => (type: string) => {
    showToast({
      type: handleType(type),
      label: title ?? '',
      description: handleMessage(message),
      autoClose: 5000,
      rtl: isRtl,
      ...options,
    })
  }

const Toastr = ['success', 'error', 'warning', 'info'].reduce((acc, type) => {
  acc[type] = (message?: string | null, title?: string, options?: OptionsT) =>
    handleShowToastr(message, title, options)(type)
  return acc
}, {} as Record<string, (message?: string | null, title?: string, options?: OptionsT) => any>)

export { Toastr }
