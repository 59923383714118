import { Action } from 'types/redux'
import * as actions from './actions'

type AttendanceSummaryType = {
  fetching: boolean
  records: []
  stats: {
    total_actual_delay: number
    total_actual_shortage: number
    day_off: number
    full_day_excuse: number
    holiday: number
    business_trip: number
    leave: number
    not_required: number
    present: number
    absent: number
    incomplete: number
    published: number
    unpublished: number
    unscheduled: number
    late_in: number
    early_out: number
    missing_in_time: number
    missing_out_time: number
    total_missed_shifts: number
    total_overtime: number
    total_shifts_duration: number
    total_scheduled_duration: number
    total_work_duration: number
    total_deductions: number
    total_differences: number
    total_no_recorded: number
    total_delay: number
    total_shortage: number
  }
}

const initialState: AttendanceSummaryType = {
  fetching: false,
  records: [],
  stats: {
    total_actual_delay: 0,
    total_actual_shortage: 0,
    day_off: 0,
    full_day_excuse: 0,
    holiday: 0,
    business_trip: 0,
    leave: 0,
    not_required: 0,
    present: 0,
    absent: 0,
    incomplete: 0,
    published: 0,
    unpublished: 0,
    unscheduled: 0,
    late_in: 0,
    early_out: 0,
    missing_in_time: 0,
    missing_out_time: 0,
    total_missed_shifts: 0,
    total_overtime: 0,
    total_shifts_duration: 0,
    total_scheduled_duration: 0,
    total_work_duration: 0,
    total_deductions: 0,
    total_differences: 0,
    total_no_recorded: 0,
    total_delay: 0,
    total_shortage: 0,
  },
}

export default function AttendanceSummaryReducer(
  state = initialState,
  action: Action,
): AttendanceSummaryType {
  const { payload } = action
  switch (action.type) {
    case actions.ATTENDANCE_SUMMARY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_SUMMARY_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ATTENDANCE_SUMMARY_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        stats: payload.data.data?.stats ?? {},
      }
    default:
      return state
  }
}
