import { Helmet as ReactHelmet } from 'react-helmet'
import I18n from 'translations/i18n'

const Helmet = (): JSX.Element => {
  const { language } = I18n
  const rtl = language === 'ar' ? 'rtl' : 'ltr'

  return (
    <ReactHelmet>
      <html
        lang={language}
        dir={rtl}
        translate="no"
      />
      <title>{I18n.t('jisr_translation_app_name')}</title>
      <body
        dir={rtl}
        className="ds-jisr-theme"
      />
    </ReactHelmet>
  )
}

export default Helmet
