import { useEffect } from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'utils/hooks'
import { ResumptionDataType, resumptionUpdate } from 'redux/requests/actionCreators'
import { required } from 'components/global/form/FormValidations'
import { DatePickerDSField } from 'components/final-form'
import i18n from 'translations/i18n'
import { Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'
import Frame from '../../ds/Frame'

const LeaveResumptionRequest = (): JSX.Element => {
  const dispatch = useDispatch()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { employee },
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = request?.employee?.id

  const onSubmit = (val: ResumptionDataType & { id: number }): Promise<void> => {
    const data = {
      reason: val?.reason ?? null,
      return_date: val.return_date,
    }

    return dispatch(resumptionUpdate(empId, data, val.id)).then(() => {
      setEditRequest(false)
    })
  }

  useEffect(() => {
    if (editRequest) {
      setInitialValues(request)
    }
  }, [editRequest])

  useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(undefined)
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <Frame>
        <DatePickerDSField
          name="return_date"
          label={i18n.t('return_date')}
          validate={required}
        />
      </Frame>

      <Reason hideFileUpload />
    </Flex>
  )
}

export default LeaveResumptionRequest
