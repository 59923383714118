import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Typography, Badge, Flex, Spacer } from '@jisr-hr/ds'
import { format } from 'utils/date'
import Avatar from '../Avatar'

const EmployeeProfile = ({
  name,
  avatar,
  jobTitle,
  code,
  date,
  typographyTitle,
  typographySubTitle,
  avatarStyle,
  onProfileClick,
  isDeleted,
}) => (
  <Flex
    itemsCenter
    onClick={onProfileClick}
    style={{ ...(!!onProfileClick && { cursor: 'pointer' }) }}
  >
    {typeof avatar === 'string' ? (
      <Avatar
        avatarStyle={avatarStyle}
        src={avatar}
        name={name}
      />
    ) : (
      avatar
    )}
    <Spacer width={8} />
    <Flex flexCol>
      <Flex
        itemsCenter
        style={{ gap: 8 }}
      >
        <Typography
          text={name}
          variant={typographyTitle}
          textColor="color/fg/light"
        />
        {isDeleted && (
          <Badge
            label={I18n.t('deleted')}
            size="small"
            variant="tinted"
            color="red"
          />
        )}
      </Flex>
      {(date || code || jobTitle) && (
        <>
          <Typography
            text={
              date ? (
                format(date, 'MMM d, yyyy, hh:mma')
              ) : (
                <>
                  {jobTitle} {jobTitle && code && '-'} {code}
                </>
              )
            }
            variant={typographySubTitle}
            textColor="color/fg/lighter"
          />
        </>
      )}
    </Flex>
  </Flex>
)

EmployeeProfile.propTypes = {
  date: PropTypes.instanceOf(Date),
  code: PropTypes.string,
  isDeleted: PropTypes.bool,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  avatar: PropTypes.string,
  typographyTitle: PropTypes.string,
  typographySubTitle: PropTypes.string,
  avatarStyle: PropTypes.shape(),
  onProfileClick: PropTypes.func,
}

EmployeeProfile.defaultProps = {
  typographyTitle: 'interface/medium/sm',
  typographySubTitle: 'subtitle-1',
}

export default EmployeeProfile
