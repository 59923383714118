import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ExitVisaIcon } from 'assets/figma-icons/ExitVisa.svg'
import I18n from 'translations/i18n'
// import { useFormState } from 'react-final-form';
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'

import { ReactComponent as CalendarIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/calendar.svg'
import { ReactComponent as SmallExitVisaIcon } from 'assets/images/SmallExitVisaIcon.svg'
import { format } from 'utils/date'

import styles from './styles.module.css'
import InfoList from '../../ds/InfoList'
import { period } from '../forms/ExitReEntryVisa/helper'
import { formatMonth } from '../helper'

const ExitReEntryVisaDetails = ({ details }) => {
  const applyDate =
    details?.apply_date && format(new Date(details?.apply_date), `dd ${formatMonth}, yyyy`)

  const periodLabel = period.find((i) => i.id === details?.period)?.label
  const items = [
    { icon: <CalendarIcon />, value: details?.entry_type_i18n, label: I18n.t('type') },
    { icon: <CalendarIcon />, value: periodLabel, label: I18n.t('visa_period') },
    { icon: <SmallExitVisaIcon />, value: applyDate, label: I18n.t('needed_before') },
  ]

  return (
    <Frame
      customStyle={styles.frame}
      title={I18n.t('exit_re_entry_visa')}
      icon={<ExitVisaIcon />}
    >
      <InfoList items={items} />
    </Frame>
  )
}

ExitReEntryVisaDetails.propTypes = {
  details: PropTypes.shape(),
}

export default ExitReEntryVisaDetails
