import React from 'react'
import PropTypes from 'prop-types'
import { Spacer } from '@jisr-hr/ds'
import { SelectField, DatePickerDSField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import I18n from 'translations/i18n'
import { resignationReasonsLoad } from 'redux/requests/resignationRequests/actionCreators'
import { useDispatch, useSelector } from 'react-redux'

import Frame from '../../../ds/Frame'

const ResignationDetails = ({ employeeId }) => {
  const dispatch = useDispatch()
  const { reasons: resignationReasons } = useSelector(
    ({ resignationRequests }) => resignationRequests,
  )

  React.useEffect(() => {
    dispatch(resignationReasonsLoad({ employee_id: employeeId }))
  }, [])

  return (
    <Frame title={I18n.t('resignation_details')}>
      <DatePickerDSField
        name="date"
        label={I18n.t('last_working_day')}
        validate={required}
      />
      <Spacer height={16} />
      <SelectField
        name="resignation_reason"
        label={I18n.t('resignation_reason')}
        options={Object.entries(resignationReasons).map(([key, value]) => {
          return { key, value }
        })}
        validate={required}
        valueKey="key"
        labelKey="value"
        maxMenuHeight={250}
        menuPlacement="bottom"
      />
    </Frame>
  )
}

ResignationDetails.propTypes = {
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ResignationDetails
