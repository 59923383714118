import { FC } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { retryImport } from 'utils/retry'

const Login = retryImport(() => import('../../containers/public/Login'))

const ForgotPassword = retryImport(() => import('../../containers/public/ForgetPassword'))

const ChangePassword = retryImport(() => import('../../containers/public/ChangePassword'))

const Onboarding = retryImport(() => import('../../containers/public/Onboarding'))

const registerCompany = retryImport(
  () => import(/* webpackChunkName: "registerCompany" */ '../../containers/public/RegisterCompany'),
)

const Print = retryImport(
  () => import(/* webpackChunkName: "Print" */ '../../containers/public/Print'),
)

const BillingDetails = retryImport(() => import('../../containers/public/BillingDetails'))

const EmailUnsubscribe = retryImport(() => import('../../containers/public/EmailUnsubscribe'))

const AuthRoutes: FC = () => {
  const location = useLocation()
  return (
    <Switch>
      <Route
        path="/login"
        exact
        component={Login}
      />
      <Route
        path="/forgot_password"
        component={ForgotPassword}
      />
      <Route
        path="/change_password"
        component={ChangePassword}
      />
      <Route
        path="/onboarding"
        component={Onboarding}
      />
      <Route
        path="/new_user"
        component={registerCompany}
      />
      <Route
        path="/print"
        component={Print}
      />
      <Route
        path="/billing_details"
        component={BillingDetails}
      />
      <Route
        path="/unsubscribe"
        component={EmailUnsubscribe}
      />
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    </Switch>
  )
}

export default AuthRoutes
