import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateTransaction, pushNewTransaction } from 'components/global/helperReducer'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'

export type ExpenseType = {
  id: number
  amount: number
  is_posted: boolean
  apply_date: string
  notes: string
  expense_type_id: number
  attachments_count: number
  remarks: Record<string, string>
}

export type TExpensesTypes = {
  id: number
  name: string
  name_ar: string
  name_i18n: string
  expenses: ExpenseType[]
}

export type AttachmentType = {
  id: number
  name: string
  file_name: string
  file_url: string
  description: string
}

type InitStateType = {
  expense_types: TExpensesTypes[]
  attachments: AttachmentType[]
  attachment: AttachmentType | null
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
  fetchingAttachments: boolean
  expenses: ExpenseType[]
}

const DEFAULT_STATE: InitStateType = {
  expense_types: [],
  attachments: [],
  attachment: null,
  errMsg: null,
  succMsg: null,
  fetching: false,
  fetchingAttachments: false,
  expenses: [],
}

export default function expenseReducer(state = DEFAULT_STATE, action: Action): InitStateType {
  const { payload } = action
  let expense_types = []
  let expenses = []
  let newRecord
  let attachments = []
  let updatedRecord
  switch (action.type) {
    case 'EXPENSES_LOAD_PENDING':
    case 'EXPENSE_CREATE_PENDING':
    case 'EXPENSE_UPDATE_PENDING':
    case 'EXPENSE_DELETE_PENDING':
    case 'EXPENSE_ATTACHMENT_CREATE_PENDING':
    case 'EXPENSE_ATTACHMENT_UPDATE_PENDING':
    case 'EMPLOYEE_EXPENSES_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'EXPENSE_ATTACHMENTS_LOAD_PENDING':
      return {
        ...state,
        fetchingAttachments: true,
      }

    case 'EXPENSES_LOAD_REJECTED':
    case 'EXPENSE_CREATE_REJECTED':
    case 'EXPENSE_UPDATE_REJECTED':
    case 'EXPENSE_DELETE_REJECTED':
    case 'EXPENSE_ATTACHMENT_CREATE_REJECTED':
    case 'EXPENSE_ATTACHMENT_UPDATE_REJECTED':
    case 'EMPLOYEE_EXPENSES_LOAD_REJECTED':
    case 'EXPENSE_ATTACHMENTS_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
        fetchingAttachments: false,
      }

    case 'EXPENSES_LOAD_FULFILLED':
      return {
        ...state,
        expense_types: payload.data.data.expense_types,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }

    case 'EMPLOYEE_EXPENSES_LOAD_FULFILLED':
      return {
        ...state,
        expenses: payload.data.data.expenses,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }

    case 'EXPENSE_ATTACHMENTS_LOAD_FULFILLED':
      return { ...state, attachments: payload.data.data.attachments, fetchingAttachments: false }

    case 'EXPENSE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        expense_types: pushNewTransaction(
          state.expense_types,
          payload.data.data.expense,
          'expenses',
          'expense_type_id',
        ),
        expenses: [...state.expenses, payload.data.data.expense],
      }

    case 'EXPENSE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      expenses = [...state.expenses]
      return {
        ...state,
        fetching: false,
        expense_types: updateTransaction(
          state.expense_types,
          payload.data.data.expense,
          'expenses',
          'expense_type_id',
        ),
        expenses: updateOneListOfRecords(expenses, payload.data.data.expense),
      }

    case 'EXPENSE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      expense_types = [...state.expense_types]
      expenses = [...state.expenses]
      return {
        ...state,
        expense_types: removeOneListOfRecords(expense_types, payload.expense_id),
        expenses: removeOneListOfRecords(expenses, payload.expense_id),
        errMsg: null,
        fetching: false,
      }

    // Expense Attachments
    case 'EXPENSE_ATTACHMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      attachments = [...state.attachments]
      newRecord = payload.data.data.attachment as AttachmentType
      return {
        ...state,
        attachments: [...attachments, newRecord],
        attachment: null,
        fetching: false,
      }

    case 'EXPENSE_ATTACHMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      attachments = [...state.attachments]
      updatedRecord = payload.data.data.attachment as AttachmentType

      return {
        ...state,
        attachments: updateOneListOfRecords(attachments, updatedRecord),
        attachment: null,
        fetching: false,
      }

    case 'EXPENSE_ATTACHMENT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      attachments = [...state.attachments]
      return {
        ...state,
        attachments: removeOneListOfRecords(attachments, payload.attachment_id),
        errMsg: null,
      }

    default:
      return state
  }
}
