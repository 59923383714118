import { Containers, FeaturedIcon, Flex, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'

export default function CorrectionAlert(): JSX.Element | null {
  const { request } = useSelector(({ requestDetails }) => requestDetails)

  const lastReturnObj = request?.request_corrections?.length
    ? request.request_corrections[request.request_corrections.length - 1]
    : null

  if (request?.status !== 'pending_for_correction') return null

  return (
    <Containers
      border
      color="warning"
      borderRadius="sm"
      width="100%"
      height="100%"
      styles={{
        display: 'flex',
        padding: 16,
        gap: 16,
      }}
    >
      <FeaturedIcon
        size="md"
        iconName="alert-triangle"
        colorType="warning"
        variant="tinted"
      />
      <Flex
        flexCol
        style={{ gap: 4 }}
      >
        <Typography
          text={i18n.t('communication.returned_for_correction')}
          variant="interface/strong/m"
        />
        <Typography
          text={lastReturnObj?.reason}
          variant="body-new/regular"
          textColor="color/fg/lighter"
        />
      </Flex>
    </Containers>
  )
}
