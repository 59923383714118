import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  salary_appraisals: [],
  header: [],
  records: [],
  appraisal_ids: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function salaryAppraisalsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let salaryAppraisals = []
  let newRecord = {}
  switch (action.type) {
    case 'ORGANIZATION_TRACKERS_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'SALARY_APPRAISALS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SALARY_APPRAISALS_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      salaryAppraisals = [...state.salary_appraisals]
      newRecord = payload.data.data.salary_appraisals
      changes = {
        salary_appraisals: [...salaryAppraisals, newRecord],
      }
      return { ...state, ...changes }

    case 'SALARY_APPRAISALS_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SALARY_APPRAISALS_DELETE_REJECTED':
    case 'SALARY_APPRAISALS_LOAD_REJECTED':
    case 'SALARY_APPRAISALS_CREATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
