export const IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD = 'IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_PENDING =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_PENDING'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_FULFILLED =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_FULFILLED'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_REJECTED =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_REJECTED'

export const IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE = 'IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_PENDING =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_PENDING'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_FULFILLED =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_FULFILLED'
export const IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_REJECTED =
  'IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_REJECTED'

export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD = 'IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_PENDING =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_PENDING'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_FULFILLED =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_FULFILLED'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_REJECTED =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_REJECTED'

export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_PENDING =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_PENDING'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_FULFILLED =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_FULFILLED'
export const IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_REJECTED =
  'IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_REJECTED'

export const NEW_JOINING_NOTIFICATION_ENABLED_LOAD = 'NEW_JOINING_NOTIFICATION_ENABLED_LOAD'
export const NEW_JOINING_NOTIFICATION_ENABLED_LOAD_PENDING =
  'NEW_JOINING_NOTIFICATION_ENABLED_LOAD_PENDING'
export const NEW_JOINING_NOTIFICATION_ENABLED_LOAD_FULFILLED =
  'NEW_JOINING_NOTIFICATION_ENABLED_LOAD_FULFILLED'
export const NEW_JOINING_NOTIFICATION_ENABLED_LOAD_REJECTED =
  'NEW_JOINING_NOTIFICATION_ENABLED_LOAD_REJECTED'

export const NEW_JOINING_NOTIFICATION_ENABLED_UPDATE = 'NEW_JOINING_NOTIFICATION_ENABLED_UPDATE'
export const NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_PENDING =
  'NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_PENDING'
export const NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_FULFILLED =
  'NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_FULFILLED'
export const NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_REJECTED =
  'NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_REJECTED'

export const ATTENDANCE_REMINDERS_LOAD = 'ATTENDANCE_REMINDERS_LOAD'
export const ATTENDANCE_REMINDERS_LOAD_PENDING = 'ATTENDANCE_REMINDERS_LOAD_PENDING'
export const ATTENDANCE_REMINDERS_LOAD_FULFILLED = 'ATTENDANCE_REMINDERS_LOAD_FULFILLED'
export const ATTENDANCE_REMINDERS_LOAD_REJECTED = 'ATTENDANCE_REMINDERS_LOAD_REJECTED'

export const ATTENDANCE_REMINDERS_UPDATE = 'ATTENDANCE_REMINDERS_UPDATE'
export const ATTENDANCE_REMINDERS_UPDATE_PENDING = 'ATTENDANCE_REMINDERS_UPDATE_PENDING'
export const ATTENDANCE_REMINDERS_UPDATE_FULFILLED = 'ATTENDANCE_REMINDERS_UPDATE_FULFILLED'
export const ATTENDANCE_REMINDERS_UPDATE_REJECTED = 'ATTENDANCE_REMINDERS_UPDATE_REJECTED'
