import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import { PayrunParams, PayrunsResponse } from './usePayruns.type'

// ? functions
const payrunsLoad = (params: PayrunParams): Promise<PayrunsResponse> => {
  return api({
    url: `/${API_VERSION_TWO}/finance/payruns`,
    method: 'GET',
    params,
  })
}

export const payrunsKey = (params?: PayrunParams): QueryKey => ['payruns_load', params]
// ? hooks
/**
 * @endpoint: GET: /v2/finance/payruns
 * @summary This hook used to load all Payruns
 */
export const usePayrunsLoad = <TData = PayrunsResponse, TError = HTTPValidationError>(
  params: PayrunParams,
  opts?: {
    query?: UseQueryOptions<PayrunsResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? payrunsKey(params),
    queryFn: () => payrunsLoad(params),
  })
}
