import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PaymentMethodsTypes = {
  filterKey: string
  filters: string[]
}

const PaymentMethods = (props: PaymentMethodsTypes): JSX.Element => {
  const { payment_methods } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(payment_methods)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default PaymentMethods
