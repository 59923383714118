import React from 'react'
import PropTypes from 'prop-types'

const HOC = (WrappedComponent) => {
  class Select extends React.Component {
    handleOptions = () => {
      const { options = [], labelKey, valueKey, imagekey } = this.props

      if (!Array.isArray(options)) {
        return []
      }

      if (!valueKey) {
        return options.map((option) => ({
          ...option,
          value: option,
          label: option,
        }))
      }

      return options.map((option) => ({
        ...option,
        value: option[valueKey],
        label: option[labelKey],
        ...(imagekey && { image: option[imagekey] }),
      }))
    }

    handleValue = () => {
      const { handleValue, value } = this.props

      if (!handleValue || !value) return value

      const options = this.handleOptions()
      const v = options.find((opt) => opt.value === value)

      return v
    }

    handleOnChange = (value) => {
      const { onChange } = this.props
      const getValue = (value && value.value) || null

      onChange(getValue, value)
      return value
    }

    render() {
      const value = this.handleValue()
      const valueProp = {
        ...(value && { value }),
      }

      return (
        <WrappedComponent
          {...this.props}
          options={this.handleOptions()}
          {...valueProp}
          onChange={this.handleOnChange}
        />
      )
    }
  }

  Select.propTypes = {
    options: PropTypes.arrayOf(Object),
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    imagekey: PropTypes.string,
    handleValue: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  }

  return Select
}

export default HOC
