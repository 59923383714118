import { SwitchField } from 'components/final-form'
import DSIcon from 'components/DSIcon'
import i18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { Tooltip, Typography } from '@jisr-hr/ds'
import { isRtl } from 'utils/uiHelpers'
import { useSelector } from 'utils/hooks'
import { useMemo, memo } from 'react'
import Frame from '../../../ds/Frame'

export type NewFlightTicketProps = {
  disableMessage?: string
  from_request: 'LeaveRequest' | 'BusinessTripRequest' | 'ExitReentryRequest'
}
const NewFlightTicket = (props: NewFlightTicketProps): JSX.Element => {
  const { disableMessage, from_request } = props
  const request_types = useSelector(
    ({ applicableRequests }) => applicableRequests.request_types,
  ) as Record<string, unknown>[]

  const { change } = useForm()
  const {
    values: { require_flight_ticket },
  } = useFormState()

  const canApplyFlightTicketRequest = useMemo(
    () => request_types?.some((requestType) => requestType.type === 'FlightTicketRequest'),
    [request_types],
  )

  const message = !canApplyFlightTicketRequest
    ? i18n.t(
        'communication.you_dont_have_the_required_permission_for_this_feature_please_contact_your_administrator.',
      )
    : undefined

  return (
    <Frame
      title={i18n.t('label.flight_ticket')}
      icon={<DSIcon name="plane" />}
      titleSuffix={
        <Tooltip
          title={disableMessage ?? message}
          arrow={isRtl ? 'topLeft' : 'topRight'}
        >
          <div>
            <SwitchField
              name="require_flight_ticket"
              active={require_flight_ticket}
              disabled={!!disableMessage || !!message}
              onChange={(checked: boolean): void => {
                change('show_flight_ticket_request', checked)
                change('from_request', from_request)
              }}
            />
          </div>
        </Tooltip>
      }
    >
      {require_flight_ticket && (
        <div className="py-[8px] px-[16px] bg-[var(--ds-core-color-primary-50)] rounded-lg flex justify-center">
          <Typography
            variant="subtitle-2"
            text={i18n.t('communication.flight_ticket_details_will_be_defined_in_a_next_step')}
          />
        </div>
      )}
    </Frame>
  )
}

export default memo(NewFlightTicket)
