import React from 'react'
import PropTypes from 'prop-types'

const TwoColCards = ({ children, testId }) => (
  <div
    data-testid={testId}
    className="jisr-cards jisr-cards--2-col"
  >
    {children}
  </div>
)

TwoColCards.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  testId: PropTypes.string,
}

export default TwoColCards
