export const SAP_BUSINESSES_LOAD_ACCOUNT_STATUS = 'SAP_BUSINESSES_LOAD_ACCOUNT_STATUS'
export const SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_PENDING =
  'SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_PENDING'
export const SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_FULFILLED =
  'SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_FULFILLED'
export const SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_REJECTED =
  'SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_REJECTED'
export const SAP_BUSINESSES_DISCONNECT_ACCOUNT = 'SAP_BUSINESSES_DISCONNECT_ACCOUNT'
export const SAP_BUSINESSES_DISCONNECT_ACCOUNT_PENDING = 'SAP_BUSINESSES_DISCONNECT_ACCOUNT_PENDING'
export const SAP_BUSINESSES_DISCONNECT_ACCOUNT_FULFILLED =
  'SAP_BUSINESSES_DISCONNECT_ACCOUNT_FULFILLED'
export const SAP_BUSINESSES_DISCONNECT_ACCOUNT_REJECTED =
  'SAP_BUSINESSES_DISCONNECT_ACCOUNT_REJECTED'

export const SAP_BUSINESSES_LOAD_ACCOUNT_INFO = 'SAP_BUSINESSES_LOAD_ACCOUNT_INFO'
export const SAP_BUSINESSES_LOAD_ACCOUNT_INFO_PENDING = 'SAP_BUSINESSES_LOAD_ACCOUNT_INFO_PENDING'
export const SAP_BUSINESSES_LOAD_ACCOUNT_INFO_FULFILLED =
  'SAP_BUSINESSES_LOAD_ACCOUNT_INFO_FULFILLED'
export const SAP_BUSINESSES_LOAD_ACCOUNT_INFO_REJECTED = 'SAP_BUSINESSES_LOAD_ACCOUNT_INFO_REJECTED'

export const SAP_BUSINESSES_CONNECT_ACCOUNT = 'SAP_BUSINESSES_CONNECT_ACCOUNT'
export const SAP_BUSINESSES_CONNECT_ACCOUNT_PENDING = 'SAP_BUSINESSES_CONNECT_ACCOUNT_PENDING'
export const SAP_BUSINESSES_CONNECT_ACCOUNT_FULFILLED = 'SAP_BUSINESSES_CONNECT_ACCOUNT_FULFILLED'
export const SAP_BUSINESSES_CONNECT_ACCOUNT_REJECTED = 'SAP_BUSINESSES_CONNECT_ACCOUNT_REJECTED'

export const SYNC_SAP_BUSINESSES_EMPLOYEE = 'SYNC_SAP_BUSINESSES_EMPLOYEE'
export const SYNC_SAP_BUSINESSES_EMPLOYEE_PENDING = 'SYNC_SAP_BUSINESSES_EMPLOYEE_PENDING'
export const SYNC_SAP_BUSINESSES_EMPLOYEE_FULFILLED = 'SYNC_SAP_BUSINESSES_EMPLOYEE_FULFILLED'
export const SYNC_SAP_BUSINESSES_EMPLOYEE_REJECTED = 'SYNC_SAP_BUSINESSES_EMPLOYEE_REJECTED'

export const SYNC_SAP_BUSINESSES_DIMENSION = 'SYNC_SAP_BUSINESSES_DIMENSION'
export const SYNC_SAP_BUSINESSES_DIMENSION_PENDING = 'SYNC_SAP_BUSINESSES_DIMENSION_PENDING'
export const SYNC_SAP_BUSINESSES_DIMENSION_FULFILLED = 'SYNC_SAP_BUSINESSES_DIMENSION_FULFILLED'
export const SYNC_SAP_BUSINESSES_DIMENSION_REJECTED = 'SYNC_SAP_BUSINESSES_DIMENSION_REJECTED'
