export const PUBLISH_SINGLE_SHIFT = 'PUBLISH_SINGLE_SHIFT'
export const PUBLISH_SINGLE_SHIFT_PENDING = 'PUBLISH_SINGLE_SHIFT_PENDING'
export const PUBLISH_SINGLE_SHIFT_FULFILLED = 'PUBLISH_SINGLE_SHIFT_FULFILLED'
export const PUBLISH_SINGLE_SHIFT_REJECTED = 'PUBLISH_SINGLE_SHIFT_REJECTED'

export const UNPUBLISH_SINGLE_SHIFT = 'UNPUBLISH_SINGLE_SHIFT'
export const UNPUBLISH_SINGLE_SHIFT_PENDING = 'UNPUBLISH_SINGLE_SHIFT_PENDING'
export const UNPUBLISH_SINGLE_SHIFT_FULFILLED = 'UNPUBLISH_SINGLE_SHIFT_FULFILLED'
export const UNPUBLISH_SINGLE_SHIFT_REJECTED = 'UNPUBLISH_SINGLE_SHIFT_REJECTED'

export const UNPUBLISH_MULTI_SHIFT = 'UNPUBLISH_MULTI_SHIFT'
export const UNPUBLISH_MULTI_SHIFT_PENDING = 'UNPUBLISH_MULTI_SHIFT_PENDING'
export const UNPUBLISH_MULTI_SHIFT_FULFILLED = 'UNPUBLISH_MULTI_SHIFT_FULFILLED'
export const UNPUBLISH_MULTI_SHIFT_REJECTED = 'UNPUBLISH_MULTI_SHIFT_REJECTED'

export const PUBLISH_MULTI_SHIFT = 'PUBLISH_MULTI_SHIFT'
export const PUBLISH_MULTI_SHIFT_PENDING = 'PUBLISH_MULTI_SHIFT_PENDING'
export const PUBLISH_MULTI_SHIFT_FULFILLED = 'PUBLISH_MULTI_SHIFT_FULFILLED'
export const PUBLISH_MULTI_SHIFT_REJECTED = 'PUBLISH_MULTI_SHIFT_REJECTED'

export const EMPLOYEE_SHIFT_STATE = 'EMPLOYEE_SHIFT_STATE'
export const EMPLOYEE_SHIFT_STATE_PENDING = 'EMPLOYEE_SHIFT_STATE_PENDING'
export const EMPLOYEE_SHIFT_STATE_FULFILLED = 'EMPLOYEE_SHIFT_STATE_FULFILLED'
export const EMPLOYEE_SHIFT_STATE_REJECTED = 'EMPLOYEE_SHIFT_STATE_REJECTED'

export const RESET_SHIFT_STATE = 'RESET_SHIFT_STATE'
