import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  fetching: false,
  offboarding_reasons: [],
}

type RequestsState = typeof initialState

export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.TERMINATION_DETAILS_REASONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.TERMINATION_DETAILS_REASONS_LOAD_FULFILLED:
      return {
        ...state,
        offboarding_reasons: payload.data.data.offboarding_reasons,
        fetching: false,
      }
    case actions.TERMINATION_DETAILS_REASONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
