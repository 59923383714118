/* eslint-disable */
/* eslint-disable radix */
import _ from 'lodash'
import I18n from 'translations/i18n'
import { addDays, addHours, addMonths, isAfter } from 'date-fns'
import { format } from 'utils/date'
import { ORGANISATION_NAME, isDevelopmentStage } from 'env'

export function handleNoData(data) {
  const output = data === '' || data == null ? '-' : data
  return output
}

export const getDataByKey = (object, key = null) => {
  if (!key) return object
  const keys = key.split('.')
  if (keys.length === 0) return object
  let result = object
  keys.map((item) => {
    if (result) {
      result = result[item]
    }
    return null
  })
  return result
}

export function handleNullObjectName(data) {
  const output = data === undefined || data == null ? '' : data.name
  return output
}

export function dateFormat(date) {
  const outputDate = date === '' || date == null ? '-' : format(date, 'dd-MM-yyyy')
  return outputDate
}

export function getNameFromHash(arr = [], selected_id = '') {
  const id = selected_id.id || selected_id
  let output = ''
  arr.map((data) => {
    if (id === data.id) {
      output = data.name
    }
    return false
  })
  return output
}

export function removeObject(dataArray, selectedId) {
  let arr = []
  let id = ''
  dataArray.map((obj) => {
    id = obj.id || obj
    if (id !== undefined && id !== selectedId) arr = [...arr, obj]
    return false
  })
  return arr
}

const ranges = [
  { divider: 1e18, suffix: 'P' },
  { divider: 1e15, suffix: 'E' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' },
]

export function numberWithCommas(inputNumber) {
  return new Intl.NumberFormat('en-US').format(inputNumber)
}

export function calculateHoursToMinutes(time) {
  const toNumsValue = time.replace(/[^\d]/g, '')
  const hours = time.slice(0, 2)
  const minutes = toNumsValue.slice(2, 4)
  // eslint-disable-next-line radix
  const totalMinutes = parseInt(hours) * 60 + parseInt(minutes)

  return totalMinutes
}

export function updateOneListOfRecords(records, updatedRecord) {
  return records.map((record) =>
    record.id === updatedRecord.id ? { ...record, ...updatedRecord } : record,
  )
}

// to remove one record in array
export function removeOneListOfRecords(records, id) {
  return records.filter((record) => record.id !== id)
}

export function getAttributeFromObject(object, attribute) {
  if (object) {
    return _.get(object, attribute)
  }
  return '' // return empty data
}

export function targetElement(id) {
  const elementFile = document.getElementById(id)
  elementFile.click()
}

/**
 * spliting router into array with filters of empty or number '..somthing/last'
 * @param {String} url;
 * @returns {Array}
 */
export function getArrayFromUrl(url) {
  if (!url) return []
  const urlArray = url.split('/')
  const filteredArray = []
  urlArray.forEach((item) => {
    const isInteger = !!parseInt(item)
    if (item === '' || isInteger) return false
    return filteredArray.push(item)
  })
  return filteredArray
}

/**
 * get current url this method return the url; from string
 * @param {String} url full url from react router;
 * @param {String} current string of sub url you want to split what is after
 * @returns {String} new url;
 */
export function getCurrentFromUrl(url, current) {
  if (!url || !current) return ''
  const afterCurrentIndex = url.indexOf(current) + current.length
  return url.slice(0, afterCurrentIndex)
}

/**
 * ckeck if an argument is object
 * @param {Any} obj
 * @returns {Boolean}
 */
export const checkIfObject = (obj) =>
  !!(obj && typeof obj === 'object' && Object.keys(obj).length > 0)

export function filesCount(attachments) {
  const arr = attachments.map((obj) => !obj.id)
  if (arr.length > 0) {
    return `${arr.length} ${I18n.t('files_uploaded')}`
  }
  return I18n.t('upload_your_attachment')
}

// get diff data/values

export function diff(a, b) {
  // const a = values;
  // const b = props.initialValues;
  const r = {}

  _.each(a, (v, k) => {
    if (b[k] === v) return
    if (_.isObject(v)) return
    // r[k] = _.isObject(v) ? dataDiff(v) : v;
    r[k] = v
  })
  return r
}

export const getPermission = (permissions, permission) =>
  permissions.find((p) => p.module === permission)

export const getSingleRecord = (id, records) => records?.find((r) => r?.id === id) || {}

export const calculationReflection = () => {
  const rangeTime = [1, 3, 6, 9, 12, 15, 18, 21, 24].map(
    (h) => new Date(new Date().setHours(h, 0, 0)),
  )
  const reflectionIn = rangeTime.find((h) => isAfter(h, new Date()))

  return format(reflectionIn, 'hh:mm a')
}

export const reflectionOneHour = () => format(addHours(new Date(), 1), 'h:00 a')

export function meladeToHijri(date) {
  const newDate = new Date(date) || new Date()
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const convertDate = newDate.toLocaleString('en-AU-u-ca-islamic', options).split(' ')[0]

  return convertDate.split('/').reverse().join('-')
}

export const sorting = (list, key) => {
  if (list.length !== 0) {
    return list.sort((first, second) => {
      if (typeof list[0] === 'object') {
        const first1 = first[key].toUpperCase()
        const second1 = second[key].toUpperCase()
        if (first1 < second1) {
          return -1
        } else if (first1 > second1) {
          return 1
        }
        return 0
      } else if (typeof key === 'string') {
        const first1 = first.toUpperCase()
        const second1 = second.toUpperCase()
        if (first1 < second1) {
          return -1
        } else if (first1 > second1) {
          return 1
        }
        return 0
      } else if (first < second) {
        return -1
      } else if (first > second) {
        return 1
      }
      return 0
    })
  }
  return []
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function objectDiff(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export function convertToFormData(data) {
  const formData = new FormData()
  Object.keys(data).map((key) => formData.append(key, data[key]))

  return formData
}

export function toBase64(file) {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  return promise
}

export const substr = (text = '', length) => {
  const trimmedString = text?.slice(0, length) || ''
  return text?.length > length ? `${trimmedString} ...` : trimmedString
}

export function deepDiff(object, base) {
  function changes(object, base) {
    const accumulator = {}
    Object.keys(base).forEach((key) => {
      if (object[key] === undefined) {
        accumulator[key] = null
      }
    })
    return _.transform(
      object,
      (accumulator, value, key) => {
        if (!_.isEqual(value, base[key])) {
          accumulator[key] =
            _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
        }
      },
      accumulator,
    )
  }
  return changes(object, base)
}

export const hasPermission = (currentEmployee, targetEmployeeId, permission) => {
  if (!permission || !currentEmployee) return false
  const permissionScopes = Array.isArray(permission) ? permission : [permission]

  if (permissionScopes?.some((it) => it === 'all')) return true
  let permittedIds = []

  permissionScopes.forEach((perm) => {
    switch (perm) {
      case 'myself':
        permittedIds = [...permittedIds, currentEmployee.id]
        break
      case 'subordinates':
        permittedIds = [...permittedIds, ...currentEmployee.subordinate_ids]
        break
      case 'group':
        permittedIds = [...permittedIds, ...currentEmployee.managed_groups_employees_ids]
        break
      default:
        permittedIds = []
    }
  })

  return permittedIds.includes(+targetEmployeeId)
}

export const hasEmpPaygroupScopePerm = (targetEmployeePaygroupID, permission) => {
  if (!permission || !targetEmployeePaygroupID) return false
  const perm = permission[targetEmployeePaygroupID]?.Paygroup

  return !!perm
}

export const getOtherTimeZoneTime = (date, zone) => {
  const timeOnTargetZone = new Date(
    date.toLocaleString('en-US', {
      timeZone: zone,
    }),
  )

  const offset = +date - +timeOnTargetZone
  return new Date(+date - offset)
}

export const handleSwitchSubsidiary = (slug) => {
  const firstLevelRoute = window.location.hash.split('/')[1]
  if (!isDevelopmentStage) {
    let url = window.location.href
    url = url.replace(`${ORGANISATION_NAME}.`, `${slug}.`)
    url = url.replace(`/${window.location.hash}`, `/#/${firstLevelRoute}?switchedAccount=true`)
    window.location.href = url
  } else {
    const baseUrl = window.location.origin
    localStorage.setItem('slug', slug)
    window.open(`${baseUrl}/#/${firstLevelRoute}`, '_blank')
  }
}
