import { Editor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { automationPostAttachment } from 'redux/automationWorkflow/automations/actionCreator'

const TextEditor = ({
  init,
  onChange,
  value,
  direction = 'ltr',
  renderValue = false,
  attachmentName,
  change,
  ...props
}) => {
  const [content, setContent] = React.useState('')
  const editorRef = useRef(null)
  const dispatch = useDispatch()
  const apiKey = import.meta.env.VklURV9USU5ZTUNFX0FQSV9LRVk
  const images = {}

  const handleOnChange = (html) => {
    setContent(html)
    onChange(html)
  }

  const init_instance_callback = (editor) => {
    editor.on('OpenWindow', () => {
      const dialogTabs = document.querySelectorAll('.tox-dialog__body-nav-item.tox-tab')
      if (dialogTabs.length === 2) {
        dialogTabs[0].style.display = 'none'
        dialogTabs[1].click()
      }
      if (init.init_instance_callback) {
        init.init_instance_callback(editor)
      }
    })
  }

  React.useEffect(() => {
    if (renderValue) {
      setContent(value)
    } else if (!content.length) {
      setContent(value)
    }
  }, [value])

  const images_upload_handler = (blobInfo) => {
    return new Promise((resolve, reject) => {
      const blob = blobInfo.blob()
      const base64 = blobInfo.base64()
      const fileName = blobInfo.filename()

      dispatch(
        automationPostAttachment({
          attachment: {
            data: `data:${blob.type};base64,${base64}`,
            filename: fileName,
          },
        }),
      )
        // eslint-disable-next-line no-shadow
        .then(({ value }) => {
          const { data } = value?.data
          const url = data?.attachment?.url
          const id = data?.attachment?.id
          images[`attachment_${id}`] = url
          const updatedImages = { ...images }
          handleOnChange(updatedImages)
          change(`${attachmentName}.inline_attachments`, updatedImages)
          resolve(url)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <Editor
      {...props}
      apiKey={apiKey}
      onEditorChange={handleOnChange}
      onInit={(_evt, editor) => {
        editorRef.current = editor
        props.onInit?.(editor)
      }}
      value={content}
      init={{
        link_assume_external_targets: 'https',
        toolbar_mode: 'sliding',
        directionality: direction,
        image_description: false,
        image_dimensions: false,
        images_upload_handler,
        init_instance_callback,
        plugins: [
          'pagebreak',
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'insertdatetime',
          'media',
          'table',
          'help',
          'wordcount',
          'directionality',
        ],
        toolbar:
          'bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons fullscreen insertfile image pageembed template link codesample a11ycheck ltr rtl showcomments addcomment',
        language: localStorage.getItem('Locale'),
        font_family_formats:
          'Arial=arial,helvetica,sans-serif; Amiri=Amiri; Cairo=Cairo; Crimson Text=CrimsonText; Lato=Lato; Montserrat=Montserrat; Open Sans=OpenSans; Oxygen=Oxygen; Roboto=Roboto; Tanseek Modern Pro Arabic=TanseekModernProArabic; HS=HungerStation;',
        content_style:
          "@import url('https://tinyfont.s3.me-south-1.amazonaws.com/tiny_font_new.css');",
        ...init,
      }}
    />
  )
}

TextEditor.propTypes = {
  init: PropTypes.shape(),
  onChange: PropTypes.func,
  value: PropTypes.string,
  renderValue: PropTypes.bool,
}

TextEditor.defaultProps = {
  onChange: () => {},
}

export default TextEditor
