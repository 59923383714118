import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const Text16 = ({ label }) => (
  <Typography
    text={label}
    variant="caption"
    style={{
      color: 'var(--color-base-colors-black-500)',
    }}
  />
)

Text16.propTypes = {
  label: PropTypes.string,
}

export default Text16
