export const GET_ASSETS = 'GET_ASSETS'
export const GET_ASSETS_PENDING = 'GET_ASSETS_PENDING'
export const GET_ASSETS_FULFILLED = 'GET_ASSETS_FULFILLED'
export const GET_ASSETS_REJECTED = 'GET_ASSETS_REJECTED'

export const GET_ASSET = 'GET_ASSET'
export const GET_ASSET_PENDING = 'GET_ASSET_PENDING'
export const GET_ASSET_FULFILLED = 'GET_ASSET_FULFILLED'
export const GET_ASSET_REJECTED = 'GET_ASSET_REJECTED'

export const CREATE_ASSET = 'CREATE_ASSET'
export const CREATE_ASSET_PENDING = 'CREATE_ASSET_PENDING'
export const CREATE_ASSET_FULFILLED = 'CREATE_ASSET_FULFILLED'
export const CREATE_ASSET_REJECTED = 'CREATE_ASSET_REJECTED'

export const UPDATE_ASSET = 'UPDATE_ASSET'
export const UPDATE_ASSET_PENDING = 'UPDATE_ASSET_PENDING'
export const UPDATE_ASSET_FULFILLED = 'UPDATE_ASSET_FULFILLED'
export const UPDATE_ASSET_REJECTED = 'UPDATE_ASSET_REJECTED'

export const DELETE_ASSET = 'DELETE_ASSET'
export const DELETE_ASSET_PENDING = 'DELETE_ASSET_PENDING'
export const DELETE_ASSET_FULFILLED = 'DELETE_ASSET_FULFILLED'
export const DELETE_ASSET_REJECTED = 'DELETE_ASSET_REJECTED'

export const SET_ASSETS_FILTERS = 'SET_ASSETS_FILTERS'
