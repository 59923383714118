import { Containers, Tag, Typography } from '@jisr-hr/ds'
import { TOrgTaskLinked } from 'apis/Setting/Workflow/LinkedTasks/useLinkedTasks.type'

type PropsType = {
  linkedTasksList: TOrgTaskLinked[]
}

const AllTasks = (props: PropsType): JSX.Element => {
  const { linkedTasksList = [] } = props
  return (
    <Containers
      borderRadius="sm"
      color="gray-dark"
      className="p-[16px]"
    >
      {linkedTasksList.map((i) => (
        <div
          className="flex justify-between items-center mb-2"
          key={i.id}
        >
          <Typography
            text={i.name_i18n}
            variant="body-new/medium"
            textColor="color/fg/default"
          />
          {i.employee && (
            <div>
              <Tag
                closeIcon={false}
                label={`${i.employee.name_i18n}`}
                type="stroke"
              />
            </div>
          )}
        </div>
      ))}
    </Containers>
  )
}

export default AllTasks
