const CHATBOT_SAVED_MESSAGES = 'CHATBOT_SAVED_MESSAGES'
const CHATBOT_START_TIMESTAMP = 'CHATBOT_START_TIMESTAMP'
const JISRI_SELECTED_LANGUAGE = 'JISRI_SELECTED_LANGUAGE'

const MAX_RECONNECT_ATTEMPTS = 5

const SCRIPT_REGEX = /<\/?script>/i
const HTML_REGEX = /<\/?([a-z][a-z0-9]*)\b[^>]*>(?:[^<]*<\/\1>)?/i

const HelpTextPoints = [
  'label.your_work_related_data',
  'label.company_policies',
  'label.company_directory',
  'label.app_usage',
  'label.saudi_labor_law-1',
]

export {
  CHATBOT_SAVED_MESSAGES,
  CHATBOT_START_TIMESTAMP,
  JISRI_SELECTED_LANGUAGE,
  MAX_RECONNECT_ATTEMPTS,
  SCRIPT_REGEX,
  HTML_REGEX,
  HelpTextPoints,
}
