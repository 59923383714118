import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  leave_requests_detail: {},
  employee_leave_details: {},
  errorMessage: '',
  fetching: false,
}

export default function leaveRequestsReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'EMPLOYEE_LEAVE_DETAILS_PENDING':
    case 'CREATE_LEAVE_REQUEST_PENDING':
    case 'UPDATE_LEAVE_REQUEST_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'LEAVE_REQUESTS_DETAIL_PENDING':
      return {
        ...state,
        fetching: true,
        errorMessage: '',
      }

    case 'CREATE_LEAVE_REQUEST_FULFILLED':
    case 'UPDATE_LEAVE_REQUEST_FULFILLED':
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        fetching: false,
      }

    case 'LEAVE_REQUESTS_DETAIL_FULFILLED':
      return {
        ...state,
        leave_requests_detail: action.payload.data.data,
        fetching: false,
      }

    case 'EMPLOYEE_LEAVE_DETAILS_FULFILLED':
      return {
        ...state,
        employee_leave_details: action.payload.data.data.leave_request,
        fetching: false,
      }

    case 'CREATE_LEAVE_REQUEST_REJECTED':
    case 'UPDATE_LEAVE_REQUEST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        fetching: false,
      }

    case 'LEAVE_REQUESTS_DETAIL_REJECTED':
      if (!action.payload?.response?.data?.data?.overlapped_payrun_id)
        Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        leave_requests_detail: action.payload?.response?.data?.data,
        errorMessage: action.payload.response?.data?.error,
      }

    case 'EMPLOYEE_LEAVE_RESET': {
      return DEFAULT_STATE
    }

    default:
      return state
  }
}
