import { Select } from '@jisr-hr/ds-beta'
import { useEffect } from 'react'
import { listDepartmentsLoad } from 'redux/newDepartments/actionCreators'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { mixPanelAnalytics } from 'utils/mixpanel'

import Styles from '../style.module.css'
import { SearchTypes, useDepartmentsContext } from './context'

type filterOptionProps = {
  data: {
    department_path: number[]
    id: number
    label: string
    name: string
    name_ar: string
    name_i18n: string
    subtree_ids: number[] | null
    value: number
  }
  label: string
  value: number | string
}

const SearchBox = (): JSX.Element => {
  const dispatch = useDispatch()
  const { list_departments } = useSelector((s) => s.newDepartments)
  const { setSearch, search } = useDepartmentsContext()

  const onChange = (data: SearchTypes | null): void => {
    setSearch(
      data
        ? {
            value: data.value,
            department_path: data.department_path,
          }
        : {},
    )
    mixPanelAnalytics({
      event_name: 'OrgStructure_Search_Performed',
      properties: { Search_Term: data?.label ?? '' },
    })
  }

  const filterOption = (option: filterOptionProps, inputValue: string): boolean => {
    const inValue = inputValue.toLowerCase()
    return (
      option.data.name_ar?.toLowerCase().includes(inValue) ||
      option.data.name?.toLowerCase().includes(inValue)
    )
  }

  useEffect(() => {
    dispatch(listDepartmentsLoad({ template: 'public' }))
  }, [])

  return (
    <div className={Styles.searchBox}>
      <Select
        isClearable
        maxMenuHeight={400}
        onChange={(val) => onChange(val as unknown as SearchTypes)}
        filterOption={filterOption}
        options={list_departments.map((d) => ({
          ...d,
          label: d.name_i18n,
          value: d.id,
        }))}
        placeholder={i18n.t('search')}
        valueKey="id"
        labelKey="name"
        {...(search?.deleted_id === search?.value && { value: undefined })}
      />
    </div>
  )
}

export default SearchBox
