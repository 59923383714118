import { useEffect, useState } from 'react'
import { Drawer } from '@jisr-hr/design-system'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Button, Containers, Spacer, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { isEmpty } from 'lodash'
import { Employee } from 'redux/authUser/types'
import { getHelpCentreInfo } from 'redux/organizationOnborading/onboardingHelpCentre/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import { isRtl } from 'utils/uiHelpers'

import styles from './styles.module.css'
import PlayVideo from './PlayVideo'
import GetHelpSkeleton from './GetHelpSkeleton'

type GetHelpDrawerProps = {
  onClose: () => void
  isOpenDrawer: boolean
}

const GetHelp = (props: GetHelpDrawerProps): JSX.Element => {
  const dispatch = useDispatch()
  const { isOpenDrawer, onClose } = props
  const { employee } = useSelector((state) => state.auth)
  const { chat_support_config } = employee as Employee
  const { key } = useSelector((state) => state.onboardingHelpCentre)
  const { generic_faqs, custom_faqs, training_video, fetching } = useSelector(
    (state) => state.onboardingHelpCentre,
  )
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isOpenDrawer && key) dispatch(getHelpCentreInfo(key))
  }, [isOpenDrawer])

  return (
    <Drawer
      onClose={onClose}
      active={isOpenDrawer}
      title={I18n.t('label.get_help')}
      content={
        fetching ? (
          <GetHelpSkeleton />
        ) : (
          <>
            {!isEmpty(generic_faqs) && (
              <>
                <Containers
                  color="white"
                  borderRadius="sm"
                  border
                  className={styles.section}
                >
                  {generic_faqs?.map((item) => (
                    <>
                      <Typography
                        textColor="color/fg/bold"
                        text={item.question}
                        variant="interface/medium/sm"
                      />
                      <Typography
                        textColor="color/fg/light"
                        text={item.answer}
                        variant="body-new/regular"
                      />
                    </>
                  ))}
                </Containers>
              </>
            )}
            {training_video && (
              <Containers
                color="white"
                borderRadius="sm"
                border
                className={styles.section}
              >
                <PlayVideo step_name={key} />
              </Containers>
            )}

            {custom_faqs?.map((item) => (
              <Containers
                key={item.id}
                color="white"
                borderRadius="sm"
                border
                className={styles.section}
              >
                <Typography
                  textColor="color/fg/bold"
                  text={item.question}
                  variant="interface/medium/sm"
                />
                <Typography
                  textColor="color/fg/light"
                  text={item.answer}
                  variant="body-new/regular"
                />
              </Containers>
            ))}

            <Containers
              color="white"
              borderRadius="sm"
              border
              className={styles.section}
            >
              <Typography
                textColor="color/fg/bold"
                text={I18n.t('label.need_further_assistance')}
                variant="interface/medium/sm"
              />
              <Typography
                textColor="color/fg/light"
                text={I18n.t('description.get_onboarding_assistance_or_chat_with_us_on_whatsapp')}
                variant="body-new/regular"
              />
              <Spacer height={20} />
              <Tooltip
                behavior="popover"
                placement={isRtl ? 'left' : 'right'}
                open={open}
                onClose={(): void => setOpen(false)}
                trigger={
                  <Button
                    label={I18n.t('label.call_us_now')}
                    size="small"
                    variant="ghost"
                    leadingIcon="phone"
                    onClick={(): void => setOpen(!open)}
                  />
                }
              >
                <Typography text="00966-115200881" />
              </Tooltip>
              <div>
                <Button
                  label={I18n.t('label.contact_us_on_whatsapp')}
                  size="small"
                  variant="ghost"
                  leadingIcon="message-notification-circle"
                  onClick={(): Window | null => window.open(`https://wa.me/966581537870/`)}
                />
              </div>
            </Containers>
            <Containers
              color="white"
              borderRadius="sm"
              border
              className={styles.section}
            >
              <div>
                <Button
                  label={I18n.t('lable.jisr_academy')}
                  size="small"
                  variant="ghost"
                  leadingIcon="graduation-hat-01"
                  onClick={(): Window | null => window.open(`https://academy.jisr.net/dashboard/`)}
                />
              </div>
              <div>
                <Button
                  label={I18n.t('label.help_center')}
                  size="small"
                  variant="ghost"
                  leadingIcon="help-circle"
                  onClick={(): Window | null =>
                    window.open(
                      chat_support_config?.url || 'https://intercom.help/jisr/en',
                      '_blank',
                    )
                  }
                />
              </div>
            </Containers>
          </>
        )
      }
      contentClassName={styles.contentPadding}
      footer={
        <Button
          label={I18n.t('label.close')}
          size="medium"
          color="neutral"
          variant="outlined"
          onClick={onClose}
        />
      }
    />
  )
}

export default GetHelp
