import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TOrganizationConfiguration } from '../ApplyingLeaves/reducer'

type TOrganizationConfigurationCustom = {
  [key in keyof TOrganizationConfiguration]: key extends 'value'
    ? string
    : TOrganizationConfiguration[key]
}

type TinitialState = {
  fetching: boolean
  organization_configuration: TOrganizationConfigurationCustom
  organizationLimit: TOrganizationConfigurationCustom
  organizationType: TOrganizationConfigurationCustom
}

export const initialState: TinitialState = {
  fetching: false,
  organization_configuration: {},
  organizationLimit: {},
  organizationType: {},
}

export default function reducer(state = initialState, { type, payload }: Action): TinitialState {
  switch (type) {
    case actions.ONBOARDING_CARRYFORWARDTYPE_LOAD_PENDING:
    case actions.ONBOARDING_CARRYFORWARD_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_CARRYFORWARDTYPE_LOAD_FULFILLED:
      return {
        ...state,
        organizationType: payload.data.data?.organization_configuration,
        fetching: false,
      }

    case actions.ONBOARDING_CARRYFORWARD_LOAD_FULFILLED:
      return {
        ...state,
        organizationLimit: payload.data.data?.organization_configuration,
        fetching: false,
      }

    case actions.ONBOARDING_CARRYFORWARD_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_CARRYFORWARD_UPDATE_REJECTED:
    case actions.ONBOARDING_CARRYFORWARDTYPE_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
