import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function excuseRequestCreate(employee_id, params = {}) {
  const url = `/${API_VERSION_TWO}/attendance/employees/${employee_id}/excuse_requests`
  const method = 'POST'

  return {
    type: 'EXCUSE_REQUEST',
    payload: handleAPI(url, {}, method, { excuse_request: params }),
  }
}

export function excuseRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/attendance/employees/${employee_id}/excuse_requests/${id}`
  const method = 'PUT'

  return {
    type: 'UPDATE_EXCUSE_REQUEST',
    payload: handleAPI(url, {}, method, { excuse_request: data }),
  }
}
