import I18n from 'translations/i18n'
import { replaceAmPm } from 'components/global/form/formNormalize'
import {
  addDays,
  addMonths,
  differenceInDays,
  isSameDay,
  isValid,
  subDays,
  format as formatFns,
  parse,
} from 'date-fns'
import { isEmpty } from 'lodash'
import { format } from 'utils/date'

type GetLogTimeAndDateReturnT = Record<'log' | 'time' | 'date', string | null>

export const recordsDetail = (
  inTime: string | null | undefined,
  outTime: string | null | undefined,
): {
  in_time: string
  out_time: string
  punch_type_i18n: string
} => {
  let in_time = ''
  let out_time = ''
  let punch_type_i18n = ''

  if (inTime && outTime) punch_type_i18n = `${I18n.t('in')} + ${I18n.t('out')}`
  else if (inTime) punch_type_i18n = I18n.t('in')
  else if (outTime) punch_type_i18n = I18n.t('out')

  if (inTime) {
    in_time = outTime
      ? `${replaceAmPm(inTime)} (${I18n.t('in')?.toUpperCase()})`
      : replaceAmPm(inTime)
  }

  if (outTime) {
    out_time = inTime
      ? `${replaceAmPm(outTime)} (${I18n.t('out')?.toUpperCase()})`
      : replaceAmPm(outTime)
  }

  return {
    in_time,
    out_time,
    punch_type_i18n: punch_type_i18n?.toUpperCase(),
  }
}

export const handleSourceType = (type?: string, name?: string): string => {
  const types: Record<string, string> = {
    request: I18n.t('via_request'),
    manual: `${I18n.t('by')} ${name}`,
  }

  return types?.[type ?? '']
}

export const resetDateTime = (d: Date): number => {
  // ? Create a new date to avoid changing the passed Date
  const newDateRef = new Date(d).setHours(0, 0, 0, 0)

  return newDateRef
}

export const handleShowRequest = (
  d: Date,
): {
  showCorrection: boolean
  showOvertime: boolean
  showExcuse: boolean
  showLeave: boolean
} => {
  // Min date is 59 days in past
  // Limit Correction to current day
  // Limit Overtime Up to 3 Month

  const isMoreThen60Days =
    differenceInDays(
      // Min date
      resetDateTime(d),
      resetDateTime(subDays(new Date(), 59)),
    ) >= 0 // when difference days is more than or equal 0 this mean request after 59 in past

  const showCorrection = isMoreThen60Days && resetDateTime(new Date()) - resetDateTime(d) >= 0
  const showOvertime =
    isMoreThen60Days && addMonths(new Date(), 3).getTime() - resetDateTime(d) >= 0

  return {
    showCorrection,
    showOvertime,
    showExcuse: isMoreThen60Days,
    showLeave: isMoreThen60Days,
  }
}

export const handleAttendanceStatusEmptyArray = <T>(
  filters: T,
): {
  omitSelected: string | number[]
  emptyArrays: Record<string, null[]>
} => {
  let omitSelected: string | number[] = []
  let emptyArrays = {}

  ;['attendance_status', 'punch_in_status', 'punch_out_status'].forEach((key) => {
    const filtersList = filters?.[key] ?? []

    omitSelected = [...omitSelected, filtersList?.includes('all') && key].filter(Boolean)

    // ? set empty array to [null] due to stringify ignore empty array
    emptyArrays = {
      ...emptyArrays,
      ...(isEmpty(filtersList) && { [key]: [null] }),
    }
  })

  return {
    omitSelected,
    emptyArrays,
  }
}

export const getManualPunchValidationMessage = (
  time: Date | null,
  min: Date | null,
  max: Date | null,
):
  | {
      message: string
      values: {
        max?: string
        min?: string
      }
    }
  | undefined => {
  if ((!min && !max) || !time) return undefined

  if (min && max && (time > max || time < min)) {
    return {
      message: 'the_proposed_time_overlaps_between',
      values: {
        max: replaceAmPm(format(max, 'hh:mm a')),
        min: replaceAmPm(format(min, 'hh:mm a')),
      },
    }
  } else if (min && time <= min) {
    return {
      message: 'the_proposed_time_overlaps_after',
      values: {
        min: replaceAmPm(format(min, 'hh:mm a')),
      },
    }
  } else if (max && time >= max) {
    return {
      message: 'the_proposed_time_overlaps_before',
      values: {
        max: replaceAmPm(format(max, 'hh:mm a')),
      },
    }
  }

  return undefined
}

export const getLogTimeAndDate = (log?: string | null): GetLogTimeAndDateReturnT => {
  if (!log || !isValid(parse(log, 'yyyy-MM-dd hh:mm a', new Date())))
    return { log: null, time: null, date: null }
  const logDate = parse(log, 'yyyy-MM-dd hh:mm a', new Date())

  return {
    log,
    time: formatFns(logDate, 'hh:mm aa'),
    date: formatFns(logDate, 'yyyy-MM-dd'),
  }
}

export const isLogNextDay = (date: Date, currentDate: Date): boolean => {
  if (!date || !currentDate) return false

  return isSameDay(addDays(currentDate, 1), date)
}

export const isLogPreviousDay = (logDate: Date, date: Date): boolean => {
  if (!logDate || !date) return false

  return isSameDay(subDays(date, 1), logDate)
}
