import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type OutsourcingCompaniesTypes = {
  filterKey: string
  filters: string[]
}

const OutsourcingCompanies = (props: OutsourcingCompaniesTypes): JSX.Element => {
  const { outsourcing_companies } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(outsourcing_companies)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default OutsourcingCompanies
