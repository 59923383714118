import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  data: {
    total_leaves: null,
    leaves: [],
  },
  leave_balance: {},
  filters: {
    page: 1,
    rpp: 20,
    search: '',
    statuses: [1],
    mol_registration_ids: [],
    department_ids: [],
    parent_department_ids: [],
    location_ids: [],
    business_unit_ids: [],
    status_employee: [],
    area_ids: [],
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LEAVE_BALANCES_FULFILLED:
      return {
        ...state,
        leave_balance: action.payload.leave_balance,
      }

    case actions.LEAVE_BALANCES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.LEAVE_BALANCES_LOAD_FULFILLED: {
      return {
        ...state,
        data: action.payload.data.data,
        fetching: false,
      }
    }

    case actions.LEAVE_BALANCES_UPDATE_REJECTED:
    case actions.LEAVE_BALANCES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SET_LEAVES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }

    default:
      return state
  }
}
