export const ALLOWANCES_LOAD = 'ALLOWANCES_LOAD'
export const ALLOWANCES_LOAD_PENDING = 'ALLOWANCES_LOAD_PENDING'
export const ALLOWANCES_LOAD_FULFILLED = 'ALLOWANCES_LOAD_FULFILLED'
export const ALLOWANCES_LOAD_REJECTED = 'ALLOWANCES_LOAD_REJECTED'

export const ALLOWANCES_CREATE = 'ALLOWANCES_CREATE'
export const ALLOWANCES_CREATE_PENDING = 'ALLOWANCES_CREATE_PENDING'
export const ALLOWANCES_CREATE_FULFILLED = 'ALLOWANCES_CREATE_FULFILLED'
export const ALLOWANCES_CREATE_REJECTED = 'ALLOWANCES_CREATE_REJECTED'

export const ALLOWANCES_UPDATE = 'ALLOWANCES_UPDATE'
export const ALLOWANCES_UPDATE_PENDING = 'ALLOWANCES_UPDATE_PENDING'
export const ALLOWANCES_UPDATE_FULFILLED = 'ALLOWANCES_UPDATE_FULFILLED'
export const ALLOWANCES_UPDATE_REJECTED = 'ALLOWANCES_UPDATE_REJECTED'

export const ALLOWANCES_DELETE = 'ALLOWANCES_DELETE'
export const ALLOWANCES_DELETE_PENDING = 'ALLOWANCES_DELETE_PENDING'
export const ALLOWANCES_DELETE_FULFILLED = 'ALLOWANCES_DELETE_FULFILLED'
export const ALLOWANCES_DELETE_REJECTED = 'ALLOWANCES_DELETE_REJECTED'

export const ALLOWANCES_IMPORT = 'ALLOWANCES_IMPORT'
export const ALLOWANCES_IMPORT_PENDING = 'ALLOWANCES_IMPORT_PENDING'
export const ALLOWANCES_IMPORT_FULFILLED = 'ALLOWANCES_IMPORT_FULFILLED'
export const ALLOWANCES_IMPORT_REJECTED = 'ALLOWANCES_IMPORT_REJECTED'
