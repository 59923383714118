const TimeAttendance = {
  // A
  a: 'أ',
  absent: 'غائب',
  add_device: 'أضافة جهاز جديد',
  add_another_device: 'إضافة جهاز آخر',
  adding_device: 'أضافة جهاز بصمة',
  actual_overtime: 'ساعات العمل الاضافية الفعلية',
  i_want_to_add_admins_now: 'أريد تحديد مديرين هذا الجهاز الآن',
  am_or_pm: 'صباحاً أو مساءً',
  any_fingerprint_after_this_time_will_not_be_considered:
    'اي بصمة بعد هذا الوقت لن يتم إعبتارها بصمة دخول بشكل تلقائي من النظام ويتعين علي الموظف تقديم طلب تصحيح بصمة لكي تسجل كبصمة دخول',
  any_fingerprint_after_this_time_will_not_be_automatically:
    'اي بصمة بعد هذا الوقت لن يتم إعبتارها بصمة خروج بشكل تلقائي من النظام ويتعين علي الموظف تقديم طلب تصحيح بصمة لكي تسجل كبصمة خروج',
  article_link: 'رابط المقالة',
  absent_tip: 'غياب',
  approve_and_submit: 'موافقه وتأكيد',
  approved_overtime: 'عمل إضافي معتمد',
  attendance_status: 'حالة الحضور',
  actual_delay: 'التأخير الفعلي',
  approved_on: 'التأكيد في',
  attendance_change: 'تغيير الحضور',
  attendance_sheet_submitted_successfully: '{{month}} {{year}} تم قبول ملف الحضور والغياب بنجاح',
  attendance_sheet_dismissed_successfully: '{{month}} {{year}} تم استبعاد ملف الحضور والغياب بنجاح',
  attendance_sheet_reopened_successfully:
    '{{month}} {{year}} تم إلغاء تأكيد ملف الحضور والغياب بنجاح',
  are_you_sure_you_want_to_dismiss_the_attendance:
    'هل أنت متأكد أنك تريد رفض الحضور لـ {{month}} {{year}}؟',
  actual_shortage: 'النقص الفعلي',
  actual_early_departure: 'الخروج المبكر الفعلي',
  actual_late_arrival: 'الدخول المتأخر الفعلي',
  any_attendance_incident_that_would_normally:
    'سيتم الغاء اي خصمية حضور او انصراف كخصميات الغياب أو الوصول المتأخر أو المغادرة المبكرة ولن تسري الخصومات على جدول الرواتب هذا.',
  approved_requests_that_were_raised:
    'سيتم إلغاء الطلبات المعتمدة التي تم رفعها لتجنب خصم محتمل مثل الوصول المتأخر والمغادرة المبكرة وطلبات الأعذار ليوم كامل أو طلبات  التصحيح نظرًا لعدم سريان أي خصم من المقام الأول.',
  additions_from_approved_overtime:
    '.لن يتم دفع الإضافات من طلبات العمل الإضافي الموافق عليها في كشوف المرتبات هذه ، وسيتم إخطار الموظفين المتأثرين.',
  are_you_sure_approve_pending_request:
    'مازال لديك  {{pending_requests}} طلبات معلقة, هل أنت متأكد أنك تريد الإرسال ؟ ',
  and_it_ends_once_the_payroll_is_confirmed:
    'وتنتهي بمجرد تأكيد كشوف المرتبات أو عند التقديم الناجح.',
  automatically_add_by_shift: 'تمت الإضافة تلقائياً من فترة الدوام',
  at: 'الساعة',
  At: 'في',
  approve_and_submit_anyway: 'موافقة، وارسال على اي حال',
  assign_now: 'أضف الأن',
  abnormal: 'غير طبيعي',
  approved_ot: 'عمل إضافي معتمد',
  allowed: 'المسموح',
  actual: 'الفعلي',
  assigned_locations: 'المواقع المعينة',
  allow_to_clock_offsite: 'السماح بتسجيل الحضور والخروج من أي موقع (دون التقيّد بالموقع المحدد)',
  allowed_to_clock_offsite: 'يمكن تسجيل الحضور والخروج من أي موقع (دون التقيّد بالموقع المحدد)',
  automatically_added: 'تم أضافتها تلقائيا',
  approve_sheet: 'إعتماد الكشف',
  Attendance_sheet_might_not_accurate: 'كشف الحضور والانصراف قد لايكون دقيق',
  attendance_day: 'يوم الدوام',
  attendance_duration: 'ايام الدوام',
  add_attatchment: 'إضافة مرفق',
  attendance_record: 'سجل الحضور',
  add_day: 'أضافة يوم',
  a_shift_need_to_assigned: 'يجب تعيين دوام من أجل حساب وتسجيل ساعات العمل',
  attendance_sheets: 'كشوفات الحضور',
  asked_for_cancellation_by: 'طلب الإلغاء مرسل من',
  all_schedule: 'عرض الكل',
  allow_punch_in_with_penalties_till: 'حدد اخر ميعاد لتلقي البصمات بعد بدء الدوام',
  add_your_bio_devices_here_so_that_your_employees:
    'أضف اجهزة البصمة التي ستكون متوفرة في هذا الموقع حتى يتمكن الموظفين من استخدامها في تسجيل دخولهم وخروجهم من الدوام',
  arevamped_design_for_better_navigation:
    'واجهات محسّنة بتصميمات جديدة لأسهل تنقل وأفضل تجربة للمستخدم',
  assign_staff_to_schedules: 'إسناد الجداول للموظفين',
  assign_different_schedules_to_your_employee_groups:
    'ضع جداول مختلفة لمجموعات الموظفين لديك على حدا للمساعدة على توفير مرونة أكبر وإدارة أفضل',
  assign_shifts_to_employees: 'حدد جداول عمل الموظفين ',
  assign_employees_to_schedules:
    'اضف الموظفين للدوامات حتى يتمكنوا من معرفة خطط احتياج العمل لهم على مدار اليوم او الاسبوع او أكثر',
  assign_staff_to_schedule: 'أضف الموظفين لجدول العمل',
  away_to_help_you_save_time_and_effort_for_overtime:
    'طريقة لمساعدتك في حفظ وقتك ومجهودك لحساب الوقت الأضافي',
  are_you_sure_you_want_to_leave: 'تريد الخروج من الصفحة؟',
  automatically_remind_employees_to_clock_in_out:
    'ترسل تنبيهات تلقائية للتذكير بتسجيل الحضور والأنصراف قبل بداية الدوام ونهايته وتصل للموظفين عن طريق تطبيق {{app_name}}',
  all_employees_will_get_notified: 'ترسل التنبيهات للجميع',
  all_employees_will_receive_anotification_on_their_mobile_app:
    'تصل التنبيهات لكل الموظفين عن طريق تطبيق {{app_name}} بحسب إعداداتكم',
  // B
  b: 'ب',
  bulk_days: 'ايام متعددة',
  bio_device: 'أجهزة البصمة',
  business_unit: 'وحده العمل',
  bio_metric_devices: 'اجهزة البصمة',
  both_geo_and_bio: 'نطاق جغرافي وأجهزة بصمة',
  bio_devices_maintenance: 'أجهزة البصمة قد تحتاج مجهودات لصيانتها',
  by_shift: 'من خلال الدوام',
  back_step: 'تراجع',
  based_on_you_have_allowed_your_employees_to_punch_out:
    'بهذه الاعدادت قد سمحت للموظفين على هذا الدوام بالخروج المبكر {{allowed}} بدون اي جزاءات',
  by_changing_this_form_name_type:
    'بتغيير نوع {{form_name}} ستفقد البيانات المدخلة وسيتعين عليك كتابتها مجددًا',
  by_leaving_this_page_without_saving: 'الخروج بدون حفظ البيانات الموجودة سيتسبب في حذفها.',
  // C
  c: 'ج',
  check_in: 'تسجيل الدخول',
  check_out: 'تسجيل الخروج',
  comment_placeholder: 'اكتب تعليقك هنا',
  connect_your_devices_to_our_server: 'أربط أجهزتك بخوادمنا',
  clocked_in: 'سجلت في',
  clocked_out: 'تم تسجيله',
  clock_out_time: 'وقت تسجيل الانصراف',
  clear_all_deductions: 'إزالة جميع الخصومات',
  columns: 'الأعمدة',
  days_off: 'أيام الراحة',
  Delay: 'التأخير',
  correction_requests: 'طلبات التصحيح',
  cancellation: 'الغاء',
  choose_another_person_decide: 'اختر شخصًا آخر لاتخاذ القرار نيابة عنك',
  choose_shift_replacement: 'اختر الفترة البديلة',
  correction_date_and_type: 'تاريخ التصحيح',
  correction: 'تصحيح',
  clock_in_time: 'وقت تسجيل الحضور',
  cancel_submission: 'إلغاء الاعتماد',
  cancel_attendance_sheet_submission: 'التراجع عن اعتماد كشف الحضور',
  cant_select_time_before_shift: 'لايمكن اختيار وقت قبل وقت بدء الدوام لطلب إستئذان منتصف اليوم',
  cant_select_time_after_shift: 'لايمكن اختيار وقت بعد وقت انتهاء الدوام لطلب إستئذان منتصف اليوم',
  cant_select_time_before_after_shift:
    'لايمكن اختيار وقت قبل وقت بدء الدوام او وقت بعد وقت انتهاء الدوام لطلب إستئذان منتصف اليوم',
  cant_select_time_before_from_time: 'لايمكن اختيار وقت قبل او يساوي وقت بدء الإستئذان',
  cutoff_time_for_punching_out_is: 'حدد اخر ميعاد لتلقي البصمات بعد أنتهاء الدوام',
  clock_out_is_not_required: 'تسجيل الخروج غير مطلوب',
  customize_shifts: 'فترات عمل مفصّلة',
  cancel_and_dont_delete: 'إلغاء وعدم الحذف',
  configure_the_method: 'تفعيل {{method_name}}',
  choose_from_a_number_of_options:
    'اختر من بين خيارات متعددة تتيح لك اختيار الأسلوب الأنسب لمتابعة عدد الساعات التي يسجلها كل موظف خلال فترة الدوام',
  check_created_shifts: 'الدوامات المعرفة حتى الآن',
  check_the_schedule: 'جدول الدوامات',
  choose_number_of_shifts_and_the_shifts: 'حدد واختر الدوامات',
  continue_setup: 'أكمال التعريفات',
  changing_step_type: 'تغيير نوع {{form_name}}',
  check_the_settings: 'تحقق من الإعدادات',
  contact_support_dashboard: 'تواصل مع الدعم',
  // D
  day: 'يوم',
  define: 'تعريف',
  delete_this_device: 'حذف الجهاز',
  define_geolocation: 'عرف النطاق الجغرافي',
  define_bio: 'عرف أجهزة البصمة',
  defining_punching_method: 'حدد طريقة التسجيل',
  defining_punching_method_for: 'حدد طريقة تسجيل الحضور لـ({{location_name}})',
  defining_punching_method_help_your_teams: 'حدد طريقة تسجيل دخول وخروج الموظفين من الدوام',
  device_online: '{{number}} أجهزة بصمة متصلة',
  device_pending: '{{number}} أجهزة بصمة معلقة',
  device_offline: '{{number}} أجهزة بصمة غير متصلة',
  Date: 'التاريخ (ميلادي)',
  delay_tip: 'الوقت الذي سجل على الموظف بسبب تأخره في الحضور عن وقت بداية الدوام المطلوب منه.',
  daily_view: 'العرض اليومي',
  Department: 'قسم',
  dismiss_attendance: 'استبعاد الحضور والانصراف',
  dismissed: 'مستبعد',
  document_ID: 'معرف المستند',
  days_with_overtime: 'الأيام مع العمل الإضافي',
  hours_with_overtime: 'الساعات مع العمل الإضافي',
  dismissing_attendance_means_that_employees:
    'رفض ملف الحضور والإنصراف يعني أن رواتب الموظفين لن تتأثر بسجلات الحضور الخاصة بهم ، وسيتم دفع أجور الموظفين من غير اضافات او خصومات قادمة من حضورهم وأنصرافهم.',
  do_you_really_want_to_delete_it: 'هل تريد فعلاً أن تحذف؟',
  difference: 'الفرق',
  device_info: 'معلومات الجهاز',
  dismiss_sheet: 'الإرسال دون احتساب الإضافات والخصميات',
  days: 'أيام',
  delay_duration: 'مدة التأخير',
  delegate_decision: 'قرار المندوب',
  decision_will_passed: 'سيتم تمرير القرار إلى الموافق التالي',
  deductable: 'خصمية',
  different_durations: 'مدة مختلفة لكل يوم',
  duration_period: 'المدة المجدولة',
  delay_deduction: 'خصمية التأخير',
  define_schedule_name: 'حدد اسم الجدول',
  define_shift_name_and_color: 'اختر اسم ولون الدوام',
  define_working_hours: 'حدد عدد ساعات العمل',
  define_clocking_rules: 'حدد آلية تسجيل الحضور',
  define_overtime: 'عرف آلية العمل الاضافي',
  device_serial_number: 'رقم مسلسل',
  define_what_employees_on_this_shift_should_do: 'حدد عدد ساعات العمل بهذا الدوام',
  define_who_are_employees_on_this_schedule: 'اختر الموظفين الذي تريد أضافتهم لهذا الجدول',
  define_the_time_allowed_for_employees_to_punch_in:
    'حدد اخر ميعاد لتلقي البصمات بعد بدء الدوام بدون توقيع اي جزاءات على الموظفين',
  define_the_time_allowed_for_employees_to_punch_out:
    'حدد ابدر ميعاد لتلقي البصمات لأنهاء الدوام بدون توقيع اي جزاءات على الموظفين',
  define_number_of_working_hours: 'حدد عدد ساعات العمل',
  do_more_configuration: 'الإستمرار في الإعدادات',
  device_will_be_deleted: 'سوف يتم حذف جهاز البصمة من {{app_name}}',
  device_status: 'حالة الجهاز',
  define_the_shifts: 'عرف الدوامات فترات العمل',
  done_for_now: 'انتهيت من التعريفات',
  define_shifts_for_each_employee_group: 'حدد عدد وانواع الدوامات التي ترغب في وجودها بالمنشأة',
  define_shifts: 'تحديد الدوامات',
  define_all_locations_reporting_methods:
    'تأكد من تحديد آليات تسجيل الحضور لكل المواقع الفعالة حتى يتمكن الموظفين من تسجيل حضورهم',
  define_shifts_criteria_that_fits_your_work_needs:
    'تأكد من تحديد الدوامات وفترات العمل التي تناسب احتياجات منشأتك',
  define_the_clocking_rules: 'عرف آلية تسجيل الحضور',
  define_how_employees_on_this_shift_should_punch_in: 'حدد كيفية تسجيل الموظفين لحضورهم',
  define_how_employees_on_this_schedule_will_work:
    'حدد عدد الدوامات لكل يوم عمل واختر اي فترة تعريفية يجب على الموظفين المسند لهم هذا الجدول ان يعملوها',
  define_the_schedule: 'حدد وإختر الدوامات',
  device_limit_reached: 'وصلت للحد الأقصى',
  // E
  employee_attendance_details: 'تفاصيل حضور وانصراف الموظف',
  enter_reason_detail: 'تفاصيل السبب',
  employees_will_be_able_to_clock_in:
    'الموظفون سيستطيعون تسجيل الدخول في اي وقت من الساعة {{from}} إلي الساعة {{to}}',
  earliest_time_possible_to_start_the_shift:
    'ادخل أبكر وقت ممكن لبدء الدوام بحيث يقوم النظام بتسجيل البصمة كبصمة دخول لهذا الدوام بشكل تلقائي',
  edit_device_admins: 'تعديل المديرين',
  employment_type: 'نوع الوظيفة',
  edit_previous_step: 'تعديل الخطوة السابقة',
  excused_time: 'تأخير مسموح',
  extra_hours: 'الوقت الإضافي',
  excused_delay_tip: 'الوقت الذي تم السماح للموظف بالتأخير فيه',
  excused_shortage_tip: 'الوقت الذي تم السماح للموظف بالخروج المبكر فيه',
  extra_time_tip:
    ' فترة زمنية إضافية أمضاها الموظف في العمل زيادة عن ساعات العمل المطلوبة منه  أو خارجها؛ وذلك إما بقدومه مبكرًا قبل بداية  الدوام، أو بخروجه متأخرا بعد نهاية الدوام.',
  extra_time: 'ساعات اضافية',
  excuse_time_field_valedation_1: 'دقائق الإستئذان يجب ان تكون أكتر من ٥ دقائق ',
  excuse_time_field_valedation_2: 'يجب ان لا يزيد وقت الإستئذان عن 6 ساعات',
  ex_personal: 'شخصي',
  ex_business: 'العمل',
  excuse_time: 'مدة الإستئذان',
  expected_hours: 'عدد الساعات',
  excused_shortage: 'خروج مبكر بإذن',
  excused_delay: 'تأخير بإذن',
  early_departure: 'المغادرة مبكراً',
  excused_late_arrival: 'تأخير بإذن',
  excused_early_departure: 'خروج مبكر بإذن',
  edit_information: 'تعديل المعلومات',
  edit_time: 'تعديل المعلومات',
  edit_logs_hint_message:
    'اذا قمت بتعديل وقت الدخول/وقت الخروج, فأن البصمة القادمة من طرق رفع سجلات الحضور لن تقوم بااستبدالها.',
  excuse_date_cant_be_before_days: 'تاريخ طلب الإستئذان لايمكن ان يكون قبل 60 يوم في الماضي',
  early_out: 'انصراف مبكّر',
  early_in: 'حضور مبكر',
  employee_attendance: 'تفاصيل الموظف',
  early_outs: 'خروج مبكر',
  exempted: 'معفى',
  employees_review_period: 'الموظفين في فترة المراجعة',
  excuse_requests: 'طلبات الأستئذان',
  estimated_delay_duration: 'مدة التأخير المقدرة: (<b>{{minutes}}</b>)',
  estimated_shortage_duration: 'مدة النقص المقدرة: (<b>{{minutes}}</b>)',
  estimated_clock_in_time: 'وقت الحضور المتوقع',
  estimated_clock_out_time: 'وقت الانصراف المتوقع',
  enter_time_manually: 'أدخل الوقت يدويًا',
  early_out_excuse: 'إستئذان انصراف مبكر',
  entry_method: 'توزيع الساعات',
  edited: 'تم تعديله',
  enter_your_reason: 'ادخل السبب',
  excuse_date_and_type: 'يوم الإستئذان ونوعه',
  excuse_duration: 'مدة الإستئذان',
  exempted_from_attendance_deductions: 'معفي من خصميات الحضور والانصراف',
  exclude_exempted_employees: 'اخفاء المعفيين من الحضور والإنصراف',
  employee_in_this_sheet: 'الموظفين في هذا الكشف',
  estimated_excused_time: 'الوقت المقدر للإستئذان',
  estimated_excused_duration: 'المدة المقدرة للإستئذان',
  employees_on_this_schedule: 'الموظفين',
  employees_on_this_schedule_will_work_one_shift:
    'هذا الخيار في حالة ان الموظفين بهذا الجدول عليهم العمل لدوام واحد فقط',
  employees_on_this_schedule_will_work_two_shifts:
    'هذا الخيار في حالة ان الموظفين بهذا الجدول عليهم العمل لدوامين',
  enter_the_schedules_name: 'أختر إسم جدول الدوامات حتى يكون سهل التعرف عليه لاحقًا',
  enter_the_shifts_name: 'أختر اسم ولون للدوام حتى يكون سهل التعرف عليه لاحقًا',
  enable_automatic_overtime_calculation: 'قم بتفعيل العمل الإضافي التلقائي',
  enable_flexibility: 'تفعيل المرونة',
  earliest_punch_in: 'أبكر بصمة من الساعة:',
  earliest_punch_out_from: 'أبكر بصمة خروج من الساعة:',
  earliest_time_possible_to_finish_the_shift: 'ادخل ابدر ميعاد ممكن لأنهاء الدوام',
  earliest_punch_out_without_penalties_from: 'بصمة خروج بدون جزاءات من الساعة:',

  employee_will_work_per_day: 'عدد ساعات العمل ليوم واحد هو:',
  employees_are_assigned:
    '{{countOfEmployeesAssignedToShifts}} من {{employeeCount}} موظف تم تحديد جدول عملهم',
  enabled_by_default: 'مُفعلة تلقائيًا',
  // F
  first_shift_in_time: 'وقت بداية الفترة الأولى من الدوام',
  first_shift_out_time: 'وقت نهاية الفترة الأولى من الدوام',
  full_day_excuse: 'إستئذان يوم كامل',
  full_day: 'يوم كامل',
  from_you: 'انت',
  from_other_approvals: 'من الأخرين',
  first_in: 'أول حضور',
  facility: 'المنشأة',
  fixed_schedule_with_one_shift_preset: 'جدول ثابت؛ بمواعيد محددة (دوام واحد)',
  for_example_if_the_shift_is:
    'كمثال: إن كانت مواعيد الدوام من{{start_time}} إلي {{end_time}} ، لو قمت بتفعيل المرونة لمده ١٢٠ دقيقة، سيكون بإمكانهم بدء العمل من {{new_start_time}} حتى {{new_end_time}} مع تطبيق نفس قواعد الحضور والإنصراف',
  // G
  grade: 'الدرجة',
  go_back: 'رجوع',
  geofencing_live: 'النطاق الجغرافي مفعل',
  grant_us_access_to_your_location: 'ساعدنا بالوصول إلي موقعك',
  geo: 'النطاق الجغرافي',
  geo_only: 'نطاق جغرافي',
  go_back_recommended: 'رجوع (يفضل)',
  go_to_settings: 'الذهاب إلى الإعدادت',
  go_to_template_settings: 'الذهاب لإعدادات دوام العمل',
  got_approved_overtime: 'لديهم عمل إضافي معتمد',
  got_deductables: 'لديهم خصومات',
  got_retroactives: 'لديهم أثر رجعي',
  got_pending_requests: 'لديهم طلبات معلقة',
  got_unscheduled_days: 'لديهم أيام غير مجدولة',
  got_incomplete_records: 'لديهم سجلات غير مكتملة',
  got_incomplete_schedule: 'دون دوام مجدول',
  got_incomplete_schedule_summary: 'لديهم جدول دوام غير مكتمل',
  got_deductions: 'لديهم خصميات',
  got_retroactive_transactions: 'لديهم معاملات بأثر رجعي',
  // H
  hide_comments: 'اخفاء التعليقات',
  highly_recommended: 'مُفضل',
  how_connect_your_devices: 'كيف تقوم بربط أجهزتك بخوادم {{app_name}}',
  holiday: 'عطلة',
  hours: 'ساعة / ساعات',
  how_does_it_affect_payroll: 'كيف تؤثر على كشوف المرتبات ؟',
  hide_shift_rules: 'إخفاء آلية تسجيل الحضور والانصراف',
  hr_1: 'ساعة',
  hr_2: 'ساعتين',
  hr: 'ساعات',
  hide_map: 'إخفاء الخريطة',
  high_to_low: 'من الأعلى الى الأقل',
  highlights: 'معلومات عامة',
  highest_positive_difference: 'الفرق الايجابي الاعلى قيمة',
  highest_negative_difference: 'الفرق السلبي الاعلى قيمة',
  has_no_off_days: 'لا يوجد أيام راحة',
  // I
  in_time: 'وقت الدخول',
  i_use_bio_devices_only: 'أجهزة البصمة فقط',
  i_will_add_admins_to_this_device_later: 'سوف أضيف مديرين هذا الجهاز لاحقًا',
  instant: 'لحظي',
  in: 'الحضور',
  include_exempted_employees: 'إظهار المعفيين من الحضور والإنصراف',
  incident_date: 'تاريخ الحالة',
  if_an_employee_was_on_leave_period:
    'إذا كان الموظف في فترة إجازة لم تتم الموافقة عليها ، فسيتم اعتباره غائبًا.',
  if_excuse_requests_werent_approved:
    'إذا لم تتم الموافقة على طلبات العذر ، فقد يتم خصم ساعات التأخير او الخروج المبكر بناء على السجلات الفعلية.',
  if_overtime_requests_werent_approved:
    'إذا لم تتم الموافقة على طلبات العمل الإضافي ، فلن يتم دفع ساعات العمل الإضافي.',
  it_seems_no_overview_requests: 'يبدو أنه لا توجد معلومات',
  it_seems_no_retroactives_requests: 'يبدو أنه لا يوجد اي أثر رجعي في سجل الحضور والانصراف هذا',
  it_seems_no_pending_requests: 'يبدو أنه لا توجد طلبات معلقة في سجل الحضور والانصراف هذا',
  if_an_employee_was_on_a_leave:
    'إذا كان الموظف في فترة إجازة لم تتم الموافقة عليها ، فسيتم دفع أجره بشكل طبيعي ولن يتأثر رصيد إجازته.',
  if_missing_punch_requests_werent_approved:
    'في حال عدم الموافقة على طلب تصحيح فقدان البصمة، الموظف سيظل في حالة سجل غير مكتمل.',
  incomplete_records: 'سجل غير مكتمل',
  incomplete_record: 'سجل غير مكتمل',
  incomplete_schedule: 'دون دوام مجدول',
  incomplete_records_days: 'أيام بسجلات غير مكتملة',
  i_want_to_the_teams_on_assigned_to_this_location_using_both_methods:
    'استخدم كل من النطاق الجغرافي وأجهزة البصمة لتسجيل دخول وخروج الموظفين من الدوام',

  i_am_sure_delete_this_device: 'تأكيد حذف الجهاز',
  i_wll_be_using_method_only: 'سوف أستخدم {{method_name}} فقط.',
  // J
  job_Title: 'المسمى الوظيفي',
  joining_date: 'تاريخ الانضمام (ميلادي)',
  // K
  keep: 'إبقاء',
  // L
  leave: 'إجازة',
  leaving_early: 'المغادرة مبكراً',
  late_arrival: 'الوصول المتأخر',
  locations_punch: 'تحديد آليات تسجيل الحضور للمواقع',
  last_edited_on: 'تم التعديل في',
  location: 'الموقع',
  late_in: 'حضور متأخر',
  less_details: 'تفاصيل اقل',
  leave_and_overtime_requests: '.سيتم ترحيل طلبات الإجازة والعمل الإضافي وتظل معلقة',
  late_arrival_excuse: 'عذر الوصول المتأخر',
  late_out: 'خروج متأخر',
  last_out: 'آخر انصراف',
  late_ins: 'حضور متأخر',
  low_to_high: 'من الأٌقل الى الأعلى',
  late_in_excuse: 'إستئذان حضور متاخر',
  last_action_by: 'آخر إجراء بواسطة',
  leave_dashboard: 'خروج',
  locations_methods_done:
    '{{locationsWithReportingMethodsCount}} من {{locationsCount}} موقع تم تحديد آليات الحضور بها',
  // M
  missed_punch: 'البصمة المفقودة',
  missing_punch: 'السجل الغير مكتمل',
  missing: 'نسيان بصمة',
  meters: 'متر',
  missing_punches_tip: 'نسيان بصمه',
  monthly_view: 'العرض الشهري',
  missing_punch_date: 'تاريخ السجل الغير مكتمل',
  missing_punch_date_cant_be_before_days: 'تاريخ طلب التصحيح لايمكن ان يكون قبل 60 يوم في الماضي',
  multiple_shifts_were_scheduled: 'تم جدولة دوام عمل او عدة دوامات ولكن لم يتم نشرها بعد',
  missed_shift: 'غياب جزئي',
  monthly: 'شهري',
  min_1: 'دقيقه',
  min_2: 'دقيقتين',
  min: 'دقائق',
  m: 'م',
  missed_shifts_duration: 'مدة الغياب الجزئي',
  missed_shifts: 'غياب جزئي',
  missed_shift_deduction: 'خصمية الغياب الجزئي',
  manual: 'يدوي',
  min_delay: 'تاخير',
  multiple_days: 'أيام محددة',
  min_shortage: 'انصراف مبكّر',
  monthly_payroll: 'مراجعة شهرية',
  missing_punch_date_and_type: 'نوع و وقت البصمة المفقودة',
  month_attendance: 'حضور شهر {{month}}',
  middle_of_day_excuse: 'منتصف اليوم',
  make_sure_you_assign_all_employees:
    'تأكد من إضافة جميع الموظفين إلي جداول أعمالهم حتى يتمكنوا من تسجيل دخولهم وخروجهم من الدوامات وتتمكن من متابعة حضورهم',
  // N
  not_joined_yet: 'لم يلتحق بالشركة بعد',
  nationality: 'الجنسية',
  not_set_yet: 'لم تتم الإضافة بعد',
  no_maintenance_needed: 'لا يحتاج صيانات او مجهودات',
  now_set_how_far_around_location:
    'اكتب هنا مدى النطاق الذي يمكن للموظفين تسجيل الدخول والخروج من الدوام اثناء تواجدهم فيه',
  not_taking_action_on_the_pending_requests:
    'عدم اتخاذ إجراء بشأن الطلبات المعلقة سيؤثر على جدول رواتب موظفيك.',
  no_employees_found: 'لم يتم العثور على موظفين',
  no_attendance_sheets_available: 'لا توجد ملفات حضور وإنصراف لهذا الشهر ',
  no_record: 'لا يوجد سجل',
  no_shift_was_scheduled: 'لا يوجد دوام مجدول',
  need_attention: 'حالات تحتاج الى انتباه',
  not_required: 'حضور غير مطلوب',
  no_of_days: 'عدد الأيام',
  no_delay_was_recorded_for_this_shift:
    'لم يتم تسجيل أي تأخير لهذا الدوام. ليست هناك حاجة لطلب إستئذان.',
  no_attendance_required_on_this_day:
    ' لا حاجة لتقديم طلب إستئذان لأن الحضور غير مطلوب لهذا اليوم، وقد يكون هذا اليوم هو: <ul> <li> يوم راحة </li> <li> يوم عطلة </li> <li> يوم إجازة موافق عليها </li> </ul>',
  no_shift_assigned: 'لا يوجد دوامات مخصصة',
  no_attendance_required: 'حضور غير مطلوب',
  number_of_shifts_on_each_day: 'عدد الدوامات لكل يوم عمل',
  new_schedule: 'جدول دوامات جديد',
  not_now: 'الإعداد لاحقًا',
  // O
  out_time: 'وقت الخروج',
  out: 'الانصراف',
  out_of_clocking_windows: 'خارج النوافذ الزمنية',
  Overtime: 'ساعات العمل الاضافي',
  overtime_hours: 'ساعات الأوفرتايم',
  overtime_ta_tip:
    'ساعات عمل إضافية تم تكليف الموظف بها أو الموافقة علي طلب قيمة هذه الساعات الإضافية, وتؤثر مباشرة في مسير الرواتب',
  overtime_type: 'نوع الوقت الإضافي',
  overtime_date: 'تاريخ الوقت الإضافي',
  overtime_date_cant_be_before_days: 'تاريخ طلب العمل الإضافي لايمكن ان يكون قبل 60 يوم في الماضي',
  overtimes: 'ساعات إضافية',
  once_you_approve_the_attendance_report_it_will_be_sent:
    'بمجرد الموافقة على تقرير الحضور ، سيتم إرساله لمعالجة كشوف المرتبات. أي انعكاسات حضور أخرى أو طلبات تمت الموافقة عليها بعد هذه النقطة لن تسري إلا في الشهر المقبل. <b>هل أنت جاهز للإرسال؟</b>',
  once_you_submit_the_attendance: '.لايمكن اعادة فتح سجل الحضور والانصراف بعد رفعه',
  overtime_one_total_distributed_equally: 'مدة مقسمة بالتساوي',
  off_day: 'يوم عطله',
  open_st: 'دوام مفتوح',
  one_shift: 'دوام واحد في اليوم',
  on_this_schedule_employees_will_work: 'الموظفين في هذا الجدول عليهم ان يعملوا لـ',
  off: 'يوم راحة',
  open_shifts_are_for_people:
    'الدوامات المفتوحة مثالية للأعمال التي تتطلب مرونة في ساعات العمل خلال اليوم (على سبيل المثال الفرق التقنية المطلوب منها العمل لمدة 8 ساعات في اي وقت بين 08:00 صباحًا لـ09:00 مساءًا أو خلال اليوم)',
  // P
  pending_on: 'معلق من قبل',
  presen: 'حاضر',
  please_follow_these_steps: 'إتبع الخطوات في هذا الرابط',
  present: 'حاضر',
  public_holiday: 'عطلة رسمية',
  performed_by: 'بواسطة',
  payroll_reflection: 'التأثير على مسيّر الرواتب',
  payroll_cycle: 'فترة الرواتب',
  punches: 'البصمات',
  punctuality_deductables: 'خصميات الانضباط',
  proposed_correction_time: 'وقت التصحيح المقترح',
  proposed_correction: 'التصحيح المقترح',
  processing: 'تحت المعالجه',
  punch_method: 'حالة آلية تسجيل الحضور',
  proceed_to_cancel_submission: 'تود الاستمرار في إلغاء اعتماد هذا الكشف؟',
  punch_in_without_penalities_till: 'بصمة بدون جزاءات حتى الساعة:',
  punches_after_will_be_pending_admin_actions:
    'البصمات بعد الساعة {{punchingTime}}، ستتطلب تدخل من الشخص المسئول',
  // Q
  // R
  reason_type: 'سبب الإستئذان',
  retroactives: 'الاثر الرجعي',
  request: 'طلب',
  radius: 'نطاق',
  requested: 'القيمة المطلوبة',
  requested_days: 'الأيام المطلوبة',
  review_and_submission: 'المراجعة والاعتماد',
  requested_on: 'يوم الطلب',
  requested_excuse_period: 'فترة العذر المطلوبة',
  records_need_to_be_recalculated: '{{count}} سجل بحاجة الى اعادة احتساب',
  recalculate_now: 'إعادة الحساب الآن',
  requested_period: 'الفترة المطلوبة',
  requested_overtime_more_than_actual_overtime:
    'ساعات العمل الاضافية المطلوبة اكثر من ساعات العمل الاضافية الفعلية',
  requests_that_were_raised_to_recover:
    'سيتم إلغاء الطلبات التي تم تقديمها للتعافي من خصم محتمل مثل طلبات الوصول المتأخر والمغادرة المبكرة وطلبات الأعذار ليوم كامل أو طلبات التصحيح.',
  retroactives_that_appear:
    '.لن يؤثر الاثر الرجعي الذي يظهر في الدورة الحالية على كشوف مرتبات الموظفين.',
  review_now: 'راجع الان',
  reason_of_reject: 'سبب الرفض',
  record: 'السجل',
  reflection: 'التأثير على الراتب',
  roaster_rotating: 'دوام متغيّر',
  reporting_methods: 'آلية تسجيل الحضور',
  request_approved_at: 'تمت الموافقة على الطلب في',
  review_approval: 'المراجعة والتأكيد',
  recorded: 'المسجل',
  reporting_methods_settings: 'اعدادات طرق رفع السجلات',
  remind_approvers: 'تذكير المسؤولين',
  requested_duration: 'المدة المطلوبة',
  recorded_clock_in: 'وقت الحضور المسجل',
  recorded_clock_out: 'وقت الانصراف المسجل',
  recorded_clock_in_time: 'وقت الحضور المسجل',
  recorded_clock_out_time: 'وقت الانصراف المسجل',
  requested_by: 'أنشئ الطلب من',
  record_changes: 'التغيّر في السجل',
  remove_day_off: 'إزالة يوم راحة',
  remove_all_deductibles: 'إزالة جميع الخصميات',
  remove_all_deductables_modal_message:
    'إزالة الخصميات ستكون بزيادة المدة المسموح بها لتعادل المدة المسجلة للحضور المتأخر او الانصراف المبكر',
  repeat_forever: 'تكرار للأبد',
  reporting: 'اختر آليات تسجيل الحضور',
  // S
  shortage: 'التقصير',
  save_device_settings: 'حفظ إعدادات جهاز البصمة',
  select_admins: 'اختر المديرين من القائمة',
  second_shift_in_time: 'وقت بداية الفترة الثانية من الدوام',
  second_shift_out_time: 'وقت نهاية الفترة الثانية من الدوام',
  single_day: 'يوم واحد',
  save_and_proceed_bio: 'حفظ ومتابعة لإعدادات جهاز البصمة',
  save_and_proceed: 'حفظ ومتابعة',
  search_location: 'ابحث عن موقع',
  select_current_location: 'أختر موقعك الحالي',
  shortage_tip:
    'الوقت الذي سجل على الموظف بسبب عدم اكماله ساعات العمل الى وقت انتهاء الدوام المطلوب منه',
  shift_name_tip: 'اسم الدوام',
  sync_now: 'Sync now',
  shift_info: 'معلومات الدوام',
  split_first_shift: 'الفترة الأولى: {{first}} - {{last}}',
  split_second_shift: 'الفترة الثانية: {{first}} - {{last}}',
  split_first_in_out: 'الفترة الأولى: {{time}}',
  split_second_in_out: 'الفترة الثانية: {{time}}',
  split_time: 'الوقت الفاصل',
  select_time: 'اختر الوقت',
  shift_one: 'الفترة الأولى',
  shift_two: 'فترة ثانية',
  shift_name: 'اسم الدوام',
  shift_type: 'نوع الدوام',
  punch_in: 'punch_in AR',
  punch_out: 'punch_out AR',
  shift_location: 'موقع الدوام',
  shift_start_time: 'وقت بداية الدوام',
  shift_end_time: 'وقت نهاية الدوام',
  second_shift_start_time: 'وقت بدء الدوام الثاني',
  second_shift_end_time: 'وقت انتهاء الدوام الثاني',
  second_shift_in: 'بداية الفترة الثانية',
  second_shift_out: 'نهاية الفترة الثانية',
  show_comments: 'عرض التعليقات',
  show_records_in_days: 'عرض السجلات بالأيام',
  show_records_in_hours: 'عرض السجلات بالساعات',
  search_by_employee_id_or_name: 'البحث عن طريق معرف الموظف أو الاسم',
  search_by_employee_id_name_objective_name: 'البحث عن طريق معرف الموظف أو اسمه او اسم الهدف',
  second_shift_clock_in: 'وقت دخول الدوام الثاني',
  second_shift_clock_out: 'وقت خروج الدوام الثاني',
  submitted: 'قدمت',
  sort_a_to_z: 'ترتيب تصاعدي من أ ← الى ي',
  sort_z_to_a: 'ترتيب تنازلي من ي ← الى أ',
  submission_window_is_the_time_where:
    'نافذة التقديم هي الوقت الذي ستتمكن فيه من إرسال كشف / تقرير الحضور إلى مسؤول كشوف المرتبات لمعالجته ، و <b> يبدأ بعد تاريخ إنهاء كشوف المرتبات </ b> الذي تم تعيينه في كشوف المرتبات التكوين ، وهو في حالتك هو:',
  source: 'مصدر',
  summary_sheet: 'ورقة الملخص',
  shift_ended_without_any_clock: 'انتهى الدوام دون تسجيل للحضور والانصراف',
  show_shift_rules: 'إظهار آلية تسجيل الحضور والانصراف',
  scheduled_hrs: 'ساعات مجدولة',
  show_map: 'عرض الخريطه',
  sort_by_extra_time: 'ترتيب حسب الوقت الإضافي',
  sort_by_shortage: 'ترتيب حسب الخروج المبكر',
  shift_schedule: 'جدول الدوامات',
  show_on_scheduler: 'عرض على جدول الدوامات',
  sheet: 'الكشف',
  some_employees_have_unscheduled_days:
    'بعض الموظفين لديهم أيام غير مجدولة أو سجلات غير مكتملة مما يؤثر على دقة كشوفات حضورهم. ننصح بمعالجة الحالات التي تم إبرازها لتجنب تأثيرات غير مرغوب فيها على مسيّر الرواتب',
  shift_timing: 'وقت الدوام',
  Shortage: 'التقصير',
  scheduled_duration: 'المدة المجدولة',
  see_what_would_change_on_record: 'تأثير قبول الطلب على سجل الحضور',
  set_as_day_off: 'يوم راحة',
  skip_decision: 'Skip Decision:ar',
  shortage_duration: 'مدة التقصير',
  select_from_existing_punch: 'تحديد من البصمات الموجوده',
  select_punch: 'تحديد بصمه',
  shift_settings: 'إعدادات الدوام ',
  show_geo_location: 'عرض الموقع الجغرافي',
  scheduled_att: 'المجدول',
  shift_time: 'وقت الدوام',
  shift_duration: 'المدة',
  shortage_deduction: 'خصمية التقصير',
  schedule: 'جدول الدوام',
  set_up_now: 'إنشاء الإعدادات',
  my_schedule: 'جدول الدوام',
  schedules: 'جداول الدوامات',
  search_by_schedule_name: 'إبحث بإسم جدول الدوامات',
  schedule_type: 'حدد طبيعة جدول العمل',
  select_a_shift: 'حدد الدوامات على هذا الجدول',
  select_employees_who_will_work_on_it: 'حدد الموظفين',
  schedule_with_different_shift_presets: 'جدول مرن',
  schedule_details: 'تفاصيل الجدول',
  save_and_proceed_to_shift_selection: 'حفظ ومتابعة إلي اختيار الدوامات',
  select_the_shift: 'اختر الفترات التعريفية/الدوام',
  search_in_shifts: 'البحث في الدوامات',
  save_and_proceed_to_repetition: 'حفظ ومتابعة إلي تحديد التكرار',
  select_case_the_start_and_end_times_are_different:
    'مثالي في حالة ان العمل بهذا الجدول يمكن ان يبدأ في اوقات مختلفة خلال الايام (كمثال ان يكون يوم الاحد فترة صباحية ويوم الاثنين فترة مسائية او اي نوع من تلك المرونة)',
  select_case_the_start_and_end_times_are_fixed:
    'مثالي في حالة ان مطلبات العمل بهذا الجدول هي ان يبدأ العمل وينتهي خلال فترات محددة لأيام ثابتة (كمثال مواعيد العمل من  09:00 صباحًا لـ05:00 مساءًا من الاحد إلي الخميس)',
  shift_preset: 'فترة معرفة',
  shift_details: 'تفاصيل الدوام',
  save_and_proceed_to_working_hours: 'حفظ و إلي تحديد ساعات العمل',
  save_and_proceed_to_clocking_rules: 'حفظ و إلى تحديد آلية تسجيل الحضور',
  start_work_at: 'يبدأ الدوام من:',
  should_work_for: 'عدد ساعات العمل:',
  select_working_days: 'أختر ايام العمل',
  save_and_publish_schedule: 'الحفظ والمتابعة إلي تعيين الموظفين',
  select_what_days_should_unselected:
    'اختر اي من هذه الايام سيكون بها دوامات، الايام الغير مختارة سيتم اعتبارها أيام راحة',
  select_employees_that_you_want_assign_this_fixed: 'اختر الموظفين',
  save_and_proceed_to_overtime: 'حفظ و إلى تحديد آلية الوقت الأضافي',
  schedule_with_fixed_shift_preset: 'جدول ثابت بمواعيد محددة',
  start_using_the_attendance: 'بدأ إستخدام الحضور',
  shift_presets_are_for_people:
    'قالب الدوام مثالي لأعمال ذات المواعيد الثابتة للبدء والانتهاء (على سبيل المثال الوظائف التي تبدأ 08:00 صباحًا وتنتهي 05:00 مساءًا)',
  set_reporting_methods: 'ضع القواعد لدوام موظفيك',
  select_reporting_methods: 'اختر طريقة التسجيل',
  setup_attendance: 'إعدادت الحضور',
  set_reporting_methods_for_your_locations: 'حدد آليات تسجيل الحضور للمواقع الـ {{locationCount}}',
  set_reporting_method_for_your_locations_to_be_able_to_track:
    'حدد آلية تسجيل الحضور في المواقع حتى تتمكن من متابعة حضور الموظفين',
  set_methods: 'حدد الآليات',
  staff_working_hours: 'إعداد دوامات الموظفين',
  set_the_rules_of_which_if_happened_an_overtime:
    'قم بتعريف الشروط التي اذا تحققت يتم احتساب وقت عمل إضافي تلقائي دون حاجة الموظفين لإرسال طلبات عمل إضافي',
  select_working_days_time_and_offdays:
    'حدد عدد الدوامات لكل يوم عمل واختر اي فترة تعريفية يجب على الموظفين المسند لهم هذا الجدول ان يعملوها',
  stay_on_page: 'إبق',
  search_by_location_name: 'البحث بأسم الموقع',
  save_time_on_correction_requests_with_jisr:
    'وفر وقت العمل على طلبات تصيح البصمات، فعل تنبيهات الحضور والأنصراف',
  set_when_to_remind_your_employees: 'حدد متى يتم تنبيه الموظفين',
  // T
  attendance_leaves: 'الحضور & الإجازات',
  time_main_navigation: 'الحضور والإنصراف',
  time: 'الوقت',
  timesheet_type: 'نوع ملف الحضور والانصراف',
  total_number_of_approved_business: 'إجمالي طلبات الإستئذان (للعمل)',
  total_number_of_approved_personal: 'إجمالي طلبات العمل (شخصي)',
  total_worked_hours: 'إجمالي ساعات العمل',
  total_worked_days: 'إجمالي ايام العمل',
  type_your_comment_here: 'اكتب تعليقك هنا',
  this_happen_when_an_attendance:
    'يحدث هذا عندما يكون هناك تغير في سجلات الحضور والانصراف - كتغير ناجم عن قبول طلب استأذان - وبالتالي سجل حضور وانصراف الموظف الناجم عن التغير الجديد سيتم اعادة احتسابه بشكل تلقائي من قبل النظام، وهذا قد يستغرق بعض من الدقائق، مع ذلك يمكن للمدير تفعيل اعادة الاحتساب بشكل لحظي ويدوي لكل 50 سجل.',
  the_employee: 'موظف',
  the_requested_overtime_is_more_than:
    'الوقت الإضافي المطلوب هو أكثر من العمل الإضافي الفعلي المسجل.',
  tracking: 'المتابعة',
  times_late_in: 'مرات الحضور المتأخر',
  times_early_out: 'مرات الانصراف المبكر',
  total_shortage: 'إجمالي التقصير',
  total_delay: 'إجمالي التأخير',
  the_full_recorded_duration: 'إجمالي المدة المسجلة (<b>{{minutes}} دقائق</b>)',
  total_extra_worked_hrs: 'إجمالي العمل الإضافي',
  this_will_withdraw_all_submitted_attendance_calculations:
    'سيلغى إعتماد كشف الحضور وتأثيره على مسيّر الرواتب. وستتمكن من مراجعة الكشف واعتماده مرة أخرى. ',
  total_working_hours: 'اجمالي ساعات العمل',
  total_working_hours_inside_the_shifts: 'إجمالي ساعات العمل داخل الدوام',
  two_shifts: 'دوامين في اليوم',
  till: 'حتى',
  type_when_should_the_shift_start: 'اكتب متى يجب ان يبدأ الدوام',
  type_how_many_hours_per_day: 'اكتب عدد ساعات العمل التي يجب اتمامها خلال هذا الدوام لكل يوم',
  this_shift_will_end_by:
    'هذا الدوام ينتهي في الساعة {{endTime}}، اذا كنت تريد تغيير ذلك يكمنك اما تعديل متى يبدأ الدوام او عدد ساعات العمل',
  the_duration_can_come_late_duration_must_stay_late:
    'المده التي يمكن للموظف تعويضها بالبقاء متأخراً.',
  tailor_your_shifts_to_your_business_requirements:
    'حدد فترات العمل بما يتوافق مع احتياجات شركتك وأعمالك',
  // U
  unavailable: 'غير متاح',
  undo: 'تراجع',
  unregistered: 'غير مسجل',
  upload_bulk_overtime: 'رفع ساعات العمل الإضافي',
  unscheduled_days: 'أيام غير مجدولة',
  unscheduled_unpublished_days: 'الأيام غير المجدولة / غير المنشورة',
  using_geofencing_would_help_your_employees:
    'حدد نطاق جغرافي يكمن للموظفين المتواجدين فيه تسجيل دخولهم وخروجهم من الدوام عن طريق تطبيق {{app_name}}, يمكنك البحث عن موقع او اختيار موقعك الحالي ثم اضافة النطاق بالامتار',
  using_geofencing_would_help_your_employees_clock_in_and_out:
    'حدد نطاق جغرافي يكمن للموظفين المتواجدين فيه تسجيل دخولهم وخروجهم من الدوام عن طريق تطبيق {{app_name}}',
  // V
  view_employee_attendance_details_page: 'عرض صفحة تفاصيل الحضور والغياب للموظف',
  valid_range: 'المجال المسموح به',
  view_on_scheduler: 'عرض على جدول الدوامات',
  view_in_scheduler: 'الرؤية في جدولة الدوامات',
  via_request: 'من خلال طلب',
  view_locations: 'عرف آليات الحضور للمواقع',
  view_settings: 'تحقق الإعدادات',
  // W
  what_happens_to_pending_requests: 'ماذا يحدث للطلبات المعلقة؟',
  will_contact_you_later_to_add_your_biometric_devices:
    'فريق {{app_name}} سيتواصل معك لأضافة أجهزة البصمة',
  who_are_the_admins: 'من سيكون مديري هذا الجهاز',
  what_happens_to_approved_requests: 'ماذا يحدث للطلبات المعتمدة؟',
  What_happens_to_retroactives_from_past_months: 'ماذا يحدث للأثر الرجعي القادم من الشهر الماضي؟',
  what_is_a_retroactive: 'ما هو الأثر الرجعي',
  what_is_submission_window: 'ما هي نافذة التقديم',
  what_to_add_admins: 'هل تريد تحديد مدير/ مديرين الجهاز؟',
  worked: 'مدة العمل',
  worked_hrs: 'ساعات العمل',
  worked_duration: 'مدة العمل',
  worked_days: 'أيام عمل',
  working_hours_inside_the_shift: 'ساعات العمل داخل الدوام',
  working_hrs_inside_the_shift: 'ساعات العمل داخل الدوام',
  when_should_shift_start_and_end: 'متى يجب ان يبدأ الدوام ولكم ساعة؟',
  who_will_work_on_this_schedule: 'من ترغب في اسنادهم لهذا الجدول',
  when_enabled_the_system_automatically_start_calculate:
    'عند التفعيل سوف يبدأ احتساب  العمل الإضافي بشكل تلقائي، فقط بعد أن يكون الموظف بقي لمدة تقوم بتحديدها بعد انتهاء الدوام',
  if_you_want_you_can_enable_the_teams_who_will_work:
    'يمكنك منح بعض الموظفين بهذا الدوام إمكانية البدء متأخراً.',
  when_enabled_the_system_will_automatically_record:
    'في حال التفعيل، سيتم احتساب الساعة {{recordTime}} كتوقيت خروج تلقائياً',
  welcome_to_jisr_attendance: ' أهلًا بك في {{app_name}} للدوامات 👋🏼',
  weekly_shift_detail: 'تفاصيل الدوام الإسبوعي',
  we_eenabled_it_by_default: 'We enabled it by defualt :ar',
  we_noticed_that_you_have_Correction_request:
    'لاحظنا وجود {{numberOfCorrectionRequests}} طلبات تصحيح خلال 3 أشهر الأخيرة، قمنا بتصميم تنبيهات الحضور والأنصراف من أجل تقليل عدد تلك الطلبات. هذه الخاصية تحت الاختبار و قد تتغير في المستقبل.',

  // X
  // Y
  yes_approve_and_submit: 'نعم، تأكيد الموافقة',
  yes_dismiss_attendance: 'نعم ، رفض الحضور',
  you_can_select_from_shifts: 'اختر من الدوامات الاتية',
  your_browser_should_ask_you:
    'متصفحك سيسألك ان كنت تسمح ل{{app_name}} بالوصول لموقعك، مما سيساعدنا في تحديده ومن ثم اضافة النطاق الجغرافي القريب من موقعك الحالي',
  you_are_about_to_approve_and_submit_the_attendance:
    'أنت على وشك الموافقة على تقرير الحضور وإرساله لـ {{month}} {{year}}',
  you_select_punch_already_selected:
    'إخترتَ بصمة سبق وسُجلّت كبصمة إنصراف. في هذه الحالة تحتاج لإقتراح بديل لها.',
  you_have_allowed_your_employees_to_punch_in:
    'بهذه الاعدادت قد سمحت للموظفين على هذا الدوام بتأخير {{allowedTime}}  بدون اي جزاءات',
  you_have_assigned_all_employees: 'لقد قمت بتحديد جداول دوامات لكل الموظفين 👏🏼 🎉',
  you_are_about_to_delete_working_device: 'أنت علي وشك حذف جهاز بصمة متصّل',
  you_configured_method_only: 'قمت بتفعيل {{method_name}} فقط',
  you_have_created_shifts: '{{shiftsCount}} دوامات تم تعريفهم حتى الآن',
  you_need_to_defin_punch_methods_or_shift_rules_first:
    'تحتاج إلي تعريف آلية تسجيل حضور او فترة تعريفية أولاً',
  you_will_not_able_to_assign_staff_to_shifts:
    'لا يمكنك تحديد جدوال دوامات الموظفين قبل إنهاء تعريف آلية تسجيل حضور او دوام أولاً',
  you_choose_both_geofencing_and_bio:
    'بالرغم من إختيارك للنطاق الجغرافي وأجهزة البصمة كآليات لتسجيل الحضور، قمت بتفعيل {{method_name}} فقط.',
  you_can_remind_them_before_after_their_shifts:
    'يمكنك تنبيههم قبل وبعد دوامهم لتسجيل حضورهم وأنصرافهم',
  you_can_only_connect_maximum_devices:
    '10  أجهزة هو أقصى عدد أجهزة يمكن أضافته، يمكنك أما التواصل مع الدعم لمزيد من الخيارات او حذف جهاز قديم وأضافة أخر جديد',
  // Z
}

export default TimeAttendance
