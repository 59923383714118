import I18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'

type FilterBoxFooterProps = {
  optionListLength: number
  isMultiLevel: boolean
  searchKey: string
  isShownMore: boolean
  handleShowMore: () => void
}

const FilterBoxFooter = (props: FilterBoxFooterProps): JSX.Element => {
  const { isMultiLevel, optionListLength, searchKey, isShownMore, handleShowMore } = props

  return (
    <>
      {optionListLength >= (isMultiLevel ? 5 : 10) && !searchKey && (
        <>
          {!isShownMore ? (
            <Typography
              text={I18n.t('show_more')}
              style={{ cursor: 'pointer' }}
              textColor="color/fg/brand/default"
              onClick={handleShowMore}
              variant="subheading"
              testId="filterBoxShowMore"
            />
          ) : (
            <Typography
              text={I18n.t('show_less')}
              style={{ cursor: 'pointer' }}
              textColor="color/fg/brand/default"
              onClick={handleShowMore}
              variant="subheading"
            />
          )}
        </>
      )}
    </>
  )
}

export default FilterBoxFooter
