import { FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type ViolationCategoryProps = {
  title: string
  filterKey: string
  extra_title: string
  filters: string[]
}

const ViolationCategory: FC<ViolationCategoryProps> = (props) => {
  const { violation_category } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const optionList = returnBadgeList(violation_category)
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        violation_category: [],
      })
    } else {
      handleFilter('violation_category', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.violation_category)) {
      setSelectedIds(filter.violation_category)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.violation_category])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('label.category')}
    />
  )
}

export default ViolationCategory
