const ar = {
  if_you_want_to_write_decimal_just_type:
    'إذا كنت تريد كتابة أرقام عشرية اكتب فقط الرقم مع الكسر وضع بينهم الفاصلة العشرية',

  _1st: 'الأولى',
  _2nd: 'الثانية',
  _3rd: 'الثالثة',
  _4th: 'الرابعة',

  _1st_half_round: 'جولة مراجعة النصف الاول',
  _2nd_half_round: 'جولة مراجعة النصف الثاني',

  _1st_quarter_round: 'جولة مراجعة الربع الاول',
  _2nd_quarter_round: 'جولة مراجعة الربع الثاني',
  _3rd_quarter_round: 'جولة مراجعة الربع الثالث',
  _4th_quarter_round: 'جولة مراجعة الربع الرابع',

  _1st_half: 'النصف الأول من السنة',
  _2nd_half: 'النصف الثاني من السنة',

  _1st_quarter: 'الربع الأول',
  _2nd_quarter: 'الربع الثاني',
  _3rd_quarter: 'الربع الثالث',
  _4th_quarter: 'الربع الرابع',

  _1st_half_full: 'النصف الأول',
  _2nd_half_full: 'النصف الثاني',

  _1st_quarter_full: 'الربع الأول',
  _2nd_quarter_full: 'الربع الثاني',
  _3rd_quarter_full: 'الربع الثالث',
  _4th_quarter_full: 'الربع الرابع',

  // A
  add_objective: 'اضافة هدف',
  add_range: 'إضافة مدى',
  add_remove_employees: 'إضافة / حذف موظفين',
  add_edit_competencies: 'اضافة / تعديل كفاءات',
  assign_new_objective: 'تعيين هدف جديد',
  assign_objective: 'تعيين هدف',
  assigned_objectives: 'الأهداف التي تم تعيينها',
  assign_review_cycle: 'تعيين دورة مراجعة جديدة',
  after_the_probation_period: 'بعد الفترة التحضيرية',
  after_op: 'بعد',
  adding_rating: 'إضافة تقييم',
  all_competencies_set: 'جميع الكفاءات',
  and_add_a_weight_for_it: 'وإضافة وزن لها.',
  a_competency_can_not_have_0_weight: 'لا يمكن أن يكون وزن الكفاءة مساوياً ٠٪.',
  an_objective_can_not_have_0_weight: 'لا يمكن أن يكون وزن الهدف مساوياً ٠٪.',
  are_you_sure_you_want_to_close_the_following_objective_cycle:
    'هل أنت متأكد أنك تريد إغلاق دورة الأهداف التالية؟',
  annually: 'سنويا',
  are_you_sure_you_want_to_delete_the_following_competency:
    'هل أنت متأكد أنك تريد حذف  الكفاءة التالية؟',
  are_you_sure_you_want_to_delete_the_following_competency_set:
    'هل أنت متأكد أنك تريد حذف مجموعة الكفاءات التالية؟',
  are_you_sure_you_want_to_delete_the_following_review_cycle:
    'هل أنت متأكد أنك تريد حذف مجموعة دورة المراجعة؟',
  are_you_sure_you_want_to_delete_the_following_objective:
    'هل أنت متأكد أنك تريد حذف الهدف التالي؟',
  adjust_objective_weight: 'ضبط وزن الهدف',
  add_employees: 'إضافة موظف',
  add_rating: 'إضافة تقييم',
  are_you_sure_you_want_to_mark_the_following_cycle_completed:
    'هل انت متاكد من اكمال دورة المراجعة؟',
  are_you_sure_you_want_to_mark_the_following_run_completed: 'هل انت متاكد من اكمال جولة المراجعة؟',
  are_you_sure_you_want_to_start_the_following_review_cycle:
    'هل أن متأكد من بدأ دورة المراجعة التالية؟?',
  all_objectives_cycle: 'جميع دورات الأهداف',
  are_you_sure_you_want_to_delete: 'هل انت متاكد من قيامك بحذف دورة الاهداف التالية؟',
  are_you_sure_you_want_to_close: 'هل أنت متأكد أنك تريد إغلاق دورة المراجعة التالية؟',
  are_you_sure_you_want_to_delete_the_following_objective_cycle:
    'هل انت متاكد من قيامك بحذف دورة الاهداف التالية ؟',
  all_statuses: 'جميع الحالات',
  all_review_cycles: 'جميع دورات المراجعة',
  area_of_strength: 'نقاط القوة',
  area_of_development: 'نقاط يجب تحسينها',
  assign_a_line_manager:
    'قم بتعيين مدير مباشر للموظف حتى يتمكن من القيام بمراجعة وتقييم اداء الموظف',
  accumulative: 'تراكمي',
  assigned_employees: 'موظفين مسندين',
  all_objectives_cycles: 'جميع دورات الاهداف',
  assign_employees: 'اختيار الموظفين',
  assign_employees_subtitle:
    'من ترغب في تلقي تقييم؟ سيتم اختيار المراجع تلقائيًا بناءً على الهيكل التنظيمي',
  assign_line_manager: 'تعيين مدير مباشر',
  assign_line_manager_drawer_message: `سيتم إضافة من يتم اختياره كمدير مباشر إلى نماذج التقييم المستقبلية وتحديثه تلقائيًا بالهيكل التنظيمي.`,
  add_here_any_area_of_strength_area_of_development_or_general_feedback:
    'أضف هنا أي مجال من مجالات القوة للتطوير أو اي تعليق عام',
  all_completed_reviews_are_shared: 'جميع المراجعات المكتملة تمت مشاركتها',
  a_criteria_with_the_same_name_is_already_exist: 'هناك معيار بنفس الاسم موجود بالفعل',
  assign_group_to_employee: 'إسناد مجموعة لموظف',
  assignee: 'المسند إليه',
  assigned_to_employees: 'مُسند إلى موظف',
  assigned_to_others: 'مسند إلى موظف',
  assigned_to_emp: 'مسندة إلى {{employee}} {{data}}',

  // B
  before_the_probation_period_ends: 'قبل انتهاء الفترة التجريبية',
  before_the_contract_ends: 'قبل نهاية العقد',
  bad: 'سيئ',
  before_deleting_this_objective:
    'قبل حذف هذا الهدف، فضلاً قم بتوزيع الأوزان ٪{{weightage}} للأهداف الأخرى حتي يكون مجموعها يساوي ٪١٠٠',
  before_end_of_probation: 'قبل انتهاء فترة التجربه',
  back_to_forms: 'Back to Forms AR',
  blank_groups: 'لا يمكن أن تكون المجموعات فارغة',
  fixed_duration_description: 'بناءً على إطار زمني مخصص  يسمح بتحديد فترة تقييم واحدة',
  // C
  competencies: 'الكفاءات',
  create_new_objectives_cycle: 'انشاء دورة اهداف جديدة',
  creating_new_objective: 'انشاء هدف جديد',
  create_new_objective: 'انشاء هدف جديد',
  competencies_and_skills: 'الكفاءات والمهارات',
  competencies_set_name: 'اسم مجموعة الكفاءات',
  competencies_set_name_ar: 'اسم مجموعة الكفاءات (عربي)',
  competencies_and_interpersonal_skills: 'الكفاءات ومهارات التعامل',
  competency_name: 'اسم الكفائة',
  closing: 'الاغلاق',
  clearly_define_criteria: 'معايير محددة بوضوح',
  close_objective_cycle: 'اغلاق دورة الأهداف',
  cycle_frequency: 'جولات المراجعة',
  choose_from: 'اختيار من',
  closing_date: 'تاريخ الإغلاق',
  competencies_weight: 'وزن الكفاءات',
  complete_cycle: 'إكمال الدورة',
  choose_a_run: 'اختر جولة المراجعة',
  competencies_set_board: 'صفحة مجموعات الكفائات',
  create_new_review_cycle: 'انشاء دورة مراجعة جديدة',
  current_review_cycle: 'دورة المراجعة الحالية',
  competencies_rating: 'تقييم الكفائات',
  cycle_period: 'فترة الدورة',
  complete_run: 'إكمال الدورة {{runNumber}}',
  choose: 'اختر',
  confirm_weight_of_objectives: 'قم بتأكيد وزن الأهداف',
  can_not_edited_cycle_progress: 'لايمكن التعديل في حال ان دورة المرجعة قيد العمل',
  to_give_weight_to_the_newly_added_objective__description:
    'لإعطاء وزن للهدف المضاف حديثًا ، اضبط وزن الأهداف المضافة سابقًا يدويًا',
  closed: 'مغلقة',
  competency_set: 'مجموعة الكفاءات',
  created_on: 'تاريخ الإنشاء',
  create_evaluation_form: 'إنشاء نموذج تقييم',
  criteria_collaborator: 'التعاون على تحديد الأهداف',
  criteria_collaborator_manager_message: 'السماح للمدير المباشر بالتعاون في تحديد المعايير',
  criteria_collaborators_subtitle:
    'سيكون بإمكان المدير المباشر تحديد معايير للموظفين الذي سيتم اختيارهم في الخطوة التالية. سيتمكن المدير المباشر من إضافة المعايير بمجرد مشاركة النموذج معه.',
  change_competency: 'تغيير الكفاءة',
  change_competency_set: 'تغيير مجموعة الكفاءات',
  changing_the_competency_set_will_remove_all_competencies_that_are_rated:
    'سيؤدي تغيير مجموعة الكفاءة إلى إزالة جميع الكفاءات التي تم تقييمها',
  change: 'تغيير',
  competency: 'الكفاءة',
  current_year: 'السنة الحالية',
  create_task: 'إنشاء مهمة',
  criteria_name_ar: 'اسم المعيار (عربي)',
  criteria_name_en: 'اسم المعيار (الإنجليزية)',
  cancel_task: 'إلغاء المهمة',
  continue_anyway: 'تأكيد المتابعة',
  cansle_task: 'إلغاء مهمة {{task}}؟',
  completed_by_employee: 'مكتمل',
  last_active_contract_type: 'نوع العقد',

  // D
  distribute_weight_equally: 'توزيع الوزن بالتساوي',
  define_new_competency_set: 'انشاء مجموعة كفاءات ',
  define_a: 'تعيين ',
  delete_competency: 'حذف الكفاءة',
  define_a_new_competency: 'تعيين كفاءة جديدة',
  delete_objective: 'حذف الهدف',
  do_you_want_to_include_competencies: 'هل تريد تضمين الكفاءات؟',
  description_optional: 'الوصف (اختياري)',
  description_ar: 'وصف (عربي)',
  does_not_seem_to_have_any_review_cycles: 'يبدوا أنه ليس لديه أي دورات مراجعة.',
  does_not_seem_to_have_any_objectives: 'يبدوا أنه ليس لديه أي أهداف.',
  does_not_seem_to_have_any_competencies_can_edit:
    'لا يبدو أن لديه أي كفاءات. انتقل إلى صفحة مجموعة الكفاءات ، وعيّن له واحدة.',
  does_not_seem_to_have_any_competencies: 'لا يبدو أن لديه أي كفاءات.',
  define_a_new_competency_and_add_a_weight_for_it: 'حدد كفاءة جديدة وأضف لها أهمية',
  deleting_a_form_permanently_deletes_the_data_of_all_associated_evaluations:
    'Deleting a form permanently deletes the data of all associated evaluations. AR',
  due_date: 'تاريخ التسليم',

  // E
  edit_objectives_cycle: 'تعديل دورة الاهداف',
  editting_objective: 'تحديث الهدف',
  employee_added: 'موظفون أضيفوا',
  employee_not_added: 'موظفون لم يضافوا',
  employee_search_placeholder: 'البحث بواسطة اسم الموظف أو الرقم الوظيفي',
  edit_details: 'تعديل التفاصيل',
  edit_review_cycle: 'تعديل دورة المراجعة',
  edit_comment: 'تعديل التعليق',
  employee_comments: 'تعليقات الموظفين',
  excellent: 'ممتاز',
  end_label: 'الانتهاء',
  ends_a_year_after_the_starting_date: 'ينتهي بعد عام من تاريخ البدء',
  employees_assigned_to_this_cycle: 'الموظفين المعينين لهذه الدورة',
  employees_assigned: 'الموظفين المعينين',
  employees_without_manager: 'لم يتم إسناد مدير لعدد {{count}} موظف.',
  employees_without_manager_message: 'تأكد من تعيين مدير لجميع الموظفين لتحقيق أقصى كفاءة',
  evaluation_process_is_completed_ask_your_line_manager_to_share_your_performance_evaluation_result:
    'اكتملت عملية التقييم ، اطلب من مديرك المباشر مشاركة نتيجة تقييم الأداء',
  deleting_objective: 'حذف الهدف',
  employee_is_offboarded_can_not_unmark_his_review:
    'تم انهاء عقد الموظف، لايمكن التراجع عن تقييم الموظف',
  employee_rating: 'تقييم الموظف',
  employee_board: 'ملف الموظف',
  employee_evaluated: 'الموظفين الذين سيتم تقييمهم',
  export_review_cycle_as_xls: 'تصدير دورات الاهداف بصيغة xls',
  evaluate_criteria: 'المعايير',
  evaluate_criteria_subtitle:
    'أضف معايير للموظفين تتماشي مع أهداف المؤسسة مباشرة لتعزيز الإنتاجية والنمو.',
  evaluate_start_time: 'وقت بداية التقييم',
  evaluate_submission_time: 'وقت تقديم التقييم',
  evaluationـforms: 'Evaluation Forms AR',
  evaluation_groups_pending_submission: 'لديك مجموعات تقييم قيد التسليم',

  // F
  first_run_timeline: 'فترة جولة المراجعة الأولى',
  fourth_run_timeline: 'فترة جولة المراجعة الرابعة',
  finalization: 'الانتهاء',
  finalizing_date: 'تاريخ الإنتهاء',
  first_run: 'جولة المراجعة الأولى',
  form_type: 'نوع النموذج',
  form_subtitle: 'بعض تفاصيل حول التقييم',
  form_details: 'تفاصيل النموذج',
  form_details_ar: 'اسم النموذج (العربية)',
  form_details_en: 'اسم النموذج (الإنجليزية)',
  fourth_run: 'جولة المراجعة الرابعة',
  frequency: 'التكرار',
  form_name: 'اسم النموذج',

  // G
  general_information: 'معلومات عامة',
  good: 'جيد',
  general_feedback: 'تعليق عام',
  groups: 'المجموعات',
  groups_message: 'استخدم المجموعات لأهداف أكثر تنظيمًا، بحد أدنى هدف واحد لكل مجموعة',
  group_new: 'مجموعة جديدة',
  group_clone: 'نسخ مجموعة',
  group_search_placeholder: 'البحث بواسطة اسم المجموعة',
  group_create: 'إنشاء مجموعة جديدة',
  group_name_en: 'اسم المجموعة (الإنجليزية)',
  group_name_ar: 'اسم المجموعة (العربية)',
  group_name_placeholder: 'مثال: نمو وتطوير',
  group_rating: 'طريقة التقييم',
  group_rating_placeholder: 'حدد طريقة التقييم',
  group_weight: 'وزن المجموعة',
  groups_create: 'إنشاء مجموعة',
  group_mark_as_general: 'تحديد كمجموعة عامة',
  group_mark_as_general_subtitle: 'ستتم إضافة المجموعة إلى قائمة النسخ',
  group_already_exist: 'هناك مجموعة بنفس الاسم موجوده بالفعل',
  group_delete_message:
    'هل أنت متأكد من رغبتك في حذف هذه المجموعة؟ ستفقد المعاييرالمرتبطة بها إذا قمت بالمتابعة',
  group_delete_heading: 'تأكيد حذف مجموعة «{{group}}»؟',
  form_delete_heading: 'Delete “{{form}}” form? :AR',
  group_weight_equal_message:
    'يجب أن يكون إجمالي أوزان المعايير داخل المجموعة مساويًا لـ {{weight}}%',
  group_weight_not_exceed_message:
    'يجب أن يكون إجمالي أوزان المعاييرداخل المجموعة أقل من آو يساوي{{weight}}%',

  // H
  // I
  its_the_total_duration_of_the_review_cycle: 'المدة الإجمالية لدورة المراجعة.',
  incomplete: 'غير مكتملة',

  // J
  joining_dates: 'تاريخ الانضمام',
  job_title_table: 'الوظيفة',
  // K
  kick_off: 'الانطلاق',
  kick_off_date: 'تاريخ الاطلاق',

  // L
  line_manager_comments: 'تعليقات المدير المباشر',
  line_manager_offboarded: 'المدير المباشر لم يعد جزءًا من هذه الشركة',
  label: 'المسمى',
  label_ar: 'المسمى (عربي)',
  list_of_competencies: 'مجموعة الكفاءات',
  live: 'Live AR',

  // M
  measurement_criteria: 'معايير القياس',
  mark_as_completed: 'اكمال المراجعة',
  marked_as_completed: 'تم استكمال المراجعة',
  manager_feedback: 'ملاحظات المدير',
  monthly_op: 'شهريًا',
  manage_all_your_evaluation_forms_in_one_place: 'Manage all your evaluation forms in one place AR',
  mark_as_completed_form: 'Mark “{{form}}” as completed?:AR',

  // N
  name_en: 'الاسم',
  name_ar: 'الاسم باللغة العربية',
  new_objectives_cycle: 'دورة اهداف جديدة',
  new_competencies_set: 'اضافة مجموعة كفاءات جديده',
  new_competency: 'كفاءة جديدة',
  never_op: 'لا ينتهي',
  not_started: 'لم تبدء بعد',
  normal_period_review_cycle: 'دورة مراجعة اعتيادية',
  normal_review_cycles: 'دورة مراجعة اعتيادية',
  normal_review_overall_rating: 'التقييم العام للفترة الإعتياديه',
  no_reviewer_selected: 'لم يتم اختيار مراجع',
  no_assigned_reviewer: 'لايوجد مراجع',
  no_result_found: 'لا توجد نتائج',
  no_result_found_message:
    'لم نتمكن من العثور على أي نتائج للمرشحات المحددة. جرب تحديد مرشحات أخرى',
  no_search_result_found: 'لم نتمكن من العثور على {{value}}. تحقق من التهجئة أو جرب بحثًا آخر',
  no_available_data: 'لا توجد بيانات لعرضها',
  no_employee_reviews_added_yet: 'لم تتم إضافة مراجعات الموظفين بعد!',
  none_method: 'بدون',
  new: 'الجديد',
  name_is_already_added_to_the_list: 'تم إضافة الاسم مسبقاً',
  no_reviewer_assigned_for_review_cycle_assign_line_manager:
    'لا يوجد مراجع لدورة المراجعة , اختر مدير مباشر',
  not_found: 'لا توجد صفحة',
  new_evaluation_form: 'New Evaluation Form AR',
  new_criteria: 'إضافة معيار جديد',
  no_evaluation_forms_yet: 'No Evaluation forms yet AR',
  task_note: 'ملاحظة: {{task_note}}',

  // O
  objective: 'الهدف',
  objectives: 'الأهداف',
  objectives_cycle_period: 'فترة دورة الاهداف',
  objectives_cycle_name: 'اسم دورة الاهداف',
  objectives_cycle_name_ar: 'اسم دورة الاهداف (عربي)',
  objective_period: 'فترة الهدف',
  objectives_cycle: 'دورة الأهداف',
  objective_name: 'اسم الهدف',
  objectives_weight: 'وزن الأهداف',
  objective_status: 'حالة الهدف',
  objective_rating: 'تقييم الهدف',
  overall_rating: 'التقييم الإجمالي',
  on_track: 'على المسار',
  off_track: 'خارج المسار',
  on_track_objectives: 'أهداف على المسار',
  off_track_objectives: 'أهداف خارج المسار',
  objective_cycles: 'دورات الاهداف',
  objective_progress: 'تقدم الهدف',
  objective_weight: 'وزن الهدف',
  only_numbers_accepted: 'يمكن قبول أرقام فقط',
  out_of: '(من {{of}})',
  objective_can_not_be_deleted_when_the_review_is_completed:
    'لا يمكن إلغاء الهدف بعد اكمال المراجعة',
  objective_progress_can_not_be_edited_when_the_review_is_completed:
    'لا يمكن تحديث تقدم الهدف بعد اكمال المراجعة',
  occurrences_regular: 'مرة تكرار',
  one_time_evaluation: 'التقييم مرة واحدة',
  occurrence_message: ' سيتم إجراء آخر تقييم في الفترة  من  {{startDate}} إلى {{endDate}}',
  occurrence_invalid_message: 'فترة التقييم تتعدى معدل تكراره',

  // P
  performance: 'الاداء',
  performance_cycles: 'دورات الأداء',
  prioritize_your_objectives_by_giving_them_weight: 'حدد أولويات أهدافك من خلال إعطائها وزنًا.',
  progress: 'التقدم',
  previous_step: 'الخطوة السابقة',
  print: 'طباعة',
  probation_period: 'فترة التجربة',
  probation_period_review_cycle: 'دورة مراجعة فترة تحضيرية',
  probation_review_cycle: 'دورة مراجعة فترة تحضيرية',
  probation_start_date: 'تاريخ بدء الفترة التحضيرية',
  probation_end_date: 'تاريخ انتهاء الفترة التحضيرية',
  product_evaluation: 'تقييم فريق المنتج ٢٠٢٤',
  past_review_cycle: 'دورات المراجعة السابقة',
  probation_review_overall_rating: 'التقييم العام لفترة التجربة',
  percentage_should_not_exceed_hundred: 'النسبة المئوية يجب أن لا تتجاوز 100',
  progress_method: 'طريقة التقدم',
  performance_objectives: 'أهداف الأداء',
  performance_evaluation: 'تقييم الاداء',
  publish_share: 'نشر ومشاركة',
  publish_Share: 'نشر ومشاركة',
  publish_and_share: 'هل ترغب في نشر ومشاركة نموذج "{{reviewName}}"؟',
  passed_submission_date: 'اجتاز تاريخ التقديم',
  performance_goal_name_placehoder: 'مثال: اداء العمل',
  prodct_name_placeholder: 'تقييم {{placeholder}} ',
  product_placeholder: 'مثال: جودة العمل',
  please_submit_all_evaluation_groups_before_publishing:
    'يؤدي نشر النموذج في وجود مجموعات قيد التسليم إلى الإلغاء الفوري لأي مهام مسندة.',

  // Q
  quarterly: 'ربع سنوي',

  // R
  rating: 'التقييم',
  regular: 'محدد المدة',
  regular_submission_date: 'تاريخ انتهاء التقييم',
  regular_select_date: 'حدد التاريخ',
  regular_recurrence: 'تكرار التقييم',
  rating_method: 'طريقة التقييم',
  review_cycles_results: 'مراجعة نتائج الدورات',
  review_cycle_name: 'اسم دورة المراجعة',
  review_cycle_name_ar: 'اسم دورة المراجعة (عربي)',
  review_period: 'فترة المراجعة',
  review_status: 'حالة المراجعة',
  reviewer: 'المراجع',
  reviewer_ids: 'المراجع',
  running: 'جاري',
  review_cycles: 'دورات المراجعة',
  review_starting_date: 'تاريخ بدء المراجعة',
  review_name: 'اسم المراجعة',
  review_starting_time: 'وقت بدء المراجعة',
  review_sharing_time: 'وقت مشاركة المراجعة',
  reviewing_time: 'وقت المراجعة',
  review_form: 'استمارة المراجعة',
  review_progress: 'تقدم المراجعة',
  results_shared_with_employees: 'النتائج التي تمت مشاركتها مع الموظفين',
  rating_out_of_5: 'التقييم (من أصل 5)',
  review_start: 'بدء المراجعة',
  reviewer_feedback: 'ملاحظات المراجع',
  reviewee: 'الموظف',
  review_status_is_completed_if_you_want_to_change_competency_set_click_update_review_first:
    'اكتملت حالة المراجعة ، إذا كنت تريد تغيير مجموعة الكفاءات ، فانقر فوق "تحديث المراجعة" أولاً',
  review_run_status_is_completed: 'اكتملت حالة دورة المراجعة',
  review_status_is_completed_if_you_want_to_assign_objective_click_update_review_first:
    'اكتملت حالة المراجعة ، إذا كنت تريد اضافة هدف، فانقر فوق "تحديث المراجعة" أولاً',
  review_status_is_incompleted_if_you_want_to_print_save_review_first:
    'حالة المراجعة غير مكتملة إذا أردت الطباعة ، احفظ المراجعة أولاً',
  running_review_cycles: 'دورات المراجعة الجارية',
  removing_employees_from_a_running_review_cycle:
    'سيؤدي إزالة الموظفين من دورة التقييم الجارية إلى إزالة جميع التقييمات المرتبطة بهؤلاء الموظفين. لا يمكن التراجع عن هذا الإجراء.',
  recurrence: 'تكرار التقييم',
  are_you_sure_you_want_to_proceed: 'هل أنت متأكد من رغبتك في المتابعة؟',
  return_for_correction: 'إعادة {{group_name}} للتصحيح',
  returned_for_correction: 'تم إعادة الطلب للتصحيح',

  // S
  set_objective_weight: 'تحديد وزن الهدف',
  search_objective: 'بحث عن الأهداف',
  search_employees_by_name_or_id: 'بحث عن الموظفين بالاسم أو الهوية.',
  search_employee_or_reviewer: 'بحث عن موظف أو مراجع',
  select_form_type: 'اختر نوع النموذج',
  select_line_manager: 'اختر مدير مباشر',
  semi_annually: 'نصف سنوى',
  second_run_timeline: 'فترة جولة المراجعة الثانية',
  save_and_finish: 'حفظ و إنهاء',
  save_changes: 'حفظ التغييرات',
  set_weight: 'إعداد الوزن',
  second_run: 'جولة المراجعة الثانية',
  start_cycle: 'بدأ الدورة',
  shared_result: 'تمت مشاركتها',
  send_line_manager_a_reminder: 'إرسال تذكير الى المدير المباشر',
  send_reminder: 'ارسال تنبيه',
  share_with_employee: 'مشاركة النتيجة مع الموظف',
  share_results_with_employee: 'مشاركة النتائج مع الموظف',
  share_review: 'مشاركة المراجعة',
  regular_start_date: 'تاريخ بدء التقييم',
  share_1st_quarter_review: 'مشاركة مراجعة الربع الاول',
  share_2nd_quarter_review: 'مشاركة مراجعة الربع الثاني',
  share_3rd_quarter_review: 'مشاركة مراجعة الربع الثالث',
  share_4th_quarter_review: 'مشاركة مراجعة الربع الرابع',
  share_1st_half_review: 'مشاركة مراجعة النصف الاول',
  share_2nd_half_review: 'مشاركة مراجعة النصف الثاني',
  select_competency_set: 'اختيار مجوعة كفاءات',
  semi_annual_op: 'نصف سنوي',
  scale: 'مستوى',
  starting_time: 'وقت بدء المراجعة',
  sharing_time: 'وقت مشاركة المراجعة',
  starting_from: 'بدءا من',
  share_remaining: 'مشاركة البقية',
  save_rating: 'حفظ التقييم',
  sharing_results_with_employee: 'مشاركة النتائج مع الموظف',
  show_all_goals: 'عرض كل المعايير',
  search_by_employee_id_name_competencies_set_name:
    'البحث عن طريق الرقم الوظيفي، اسم الموظف او اسم مجموعة الكفاءات',
  search_by_employee_id_name_objective_name_pm:
    'البحث عن طريق الرقم الوظيفي، اسم الموظف او اسم الهدف',
  save_review: 'حفظ المراجعة',
  save_continue: 'حفظ ومتابعة',
  search_by_form_name: 'Search by form name AR',
  share_with_manager: 'Share with Manager AR',
  start_adding_evaluation:
    'Start adding evaluation forms to reflect on past work as well as your future goals AR',
  show_more: 'عرض كل المعايير',
  show_less: 'عرض أقل',
  send_task_to_manager: 'إرسال مهمة إلى أحد الموظفين لملء مجموعة محددة بالمهارات والأوزان المطلوبة',
  submission_task_date: 'تاريخ التسليم: {{date}}',
  range_title: 'تواريخ بدء ونهاية كل فترة تقييم',
  // T
  target: 'استهداف',
  total_weight: 'اجمالي الأوزان',
  the_total_weight_is_fine_you_are_good_to_go: 'الوزن الإجمالي جيد. أنت على ما يرام!',
  the_total_weight_should_be_100: 'يجب أن يكون الوزن الإجمالي مساوياً ١٠٠.',
  the_total_weight_is_100: 'ممتاز، اجمالي اوزان الاهداف مساويةً لـ١٠٠٪',
  this_employee_doesnt_seem_to_have_any_competencies: 'لا يبدو أن هذا الموظف لديه أي كفاءات.',
  this_employee_doesnt_seem_to_have_any_objectives: 'لا يبدو أن هذا الموظف لديه أي أهداف.',
  this_employee_doesnt_seem_to_have_any_review_cycles:
    'لا يبدو أن هذا الموظف لديه أي دورات مراجعة.',
  third_run_timeline: 'فترة جولة المراجعة الثالثة',
  third_run: 'جولة المراجعة الثالثة',
  the_rating_for_some_employees_either_have_not_started_or_in_progress:
    'عملية التقييم لبعض الموظفون اما تحت المراجعة او لم تبدء بعد',
  to_be_given: 'لم تُعطَى بعد',
  these_changes_will_be_reflected:
    'هذه التغيرات ستنعكس على دورات المراجعة الجديدة والتي لم تبدء بعد فقط',
  this_review_cycle_is_to_measure_the_performance_of_engineers:
    'تهدف دورة المراجعة هذه إلى قياس أداء المهندسين والخبراء التقنيين. هذا سوف يساعد الأشخاص المناسبين في الحصول على المكافأة.',
  the_overall_weight_is_exceeding_100: 'الوزن الإجمالي يتجاوز 100٪.',
  the_overall_weight_is_less_than_100: 'الوزن الإجمالي أقل من 100٪.',
  this_step_cant_be_reversed: 'لا يمكن التراجع عن هذه الخطوة',
  the_scale: 'المستوى',
  the_objective_will_be_deleted_from_employee_profile_and_objective_page:
    'سيتم حذف الهدف"{{name}}" من ملف الموظف وصفحة الأهداف',
  the_review_is_already_shared: 'تمت مشاركة المراجعة من قبل',
  there_is_no_objective_cycle_within_this_review_run_date:
    'لا توجد دورة أهداف ضمن فترة تاريخ دورة المراجعة هذه',
  this_means_that_this_form_will_be_live:
    'بمجرد نشر النموذج، سيتمكن الموظفين المحددين ومديريهم من رؤيته.',
  the_total_weight_of_group_goals_must_equal:
    ' يجب أن يكون إجمالي أوزان المعاييرداخل المجموعة مساويًا لـ %{{weigh}}',
  the_group_weight_can: 'لا يمكن أن يتجاوز وزن المجموعة %{{weigh}}',
  the_form_has:
    'The form has {{evaluationsnumber}} incomplete evaluations. Are you sure you want to mark it as completed without completing these evaluations? AR',

  total_weight_should_be: 'يجب أن يكون إجمالي الأوزان 100٪',
  template_name_ar_placeholder: 'أضف اسم القالب',
  adjust_group_weights_to_meet_the_required_total_weight:
    'قم بتعديل أوزان المجموعة لتحقيق الوزن الإجمالي المطلوب',
  criterion_with_the_same_name_already_exists: '  هناك معيار بنفس الاسم موجود بالفعل',
  // U
  updating_objective_progress: 'تحديث تقدم الهدف',
  update_progress_method: 'تحديث طريقة التقدم',
  update_progress: 'تحديث التقدم',
  update_objectives_cycle: 'تعديل دورة الاهداف',
  update_review: 'تحديث المراجعة',
  upcoming: 'Upcoming AR',
  // V
  very_bad: 'سيئ جدا',
  very_good: 'جيد جدا',

  // W
  weight: 'الوزن',
  search_not_found: '  لم نتمكن من العثور على {{search}}. تحقق من التهجئة أو جرب بحثًا آخر',

  // X
  // Y
  you_have_not_added_any_employees_yet: 'لم تقم بإضافة أي موظفين حتى الآن.',
  yes_delete_objective_cycle: 'نعم قم بحذف دورة الاهداف',
  you_do_not_seem_to_have_any_objective_cycles_defined_yet:
    'لا يبدو أن لديك أي دورات أهداف محددة حتى الآن.',
  you_do_not_seem_to_have_any_competencies_set_defined_yet: 'لا يبدو أن لديك أي كفاءات محددة بعد.',
  yes_close_objective_cycle: 'نعم. أغلق دورة الأهداف',
  yes_delete_it: 'نعم. احذفها',
  yes_mark_it_completed: 'نعم.  أكملها',
  yes_let_us_start_it: 'نعم. دعنا نبدأها',
  yes_delete_review_cycle: 'نعم قم بحذف دورة الاهداف',
  you_do_not_seem_to_have_any_review_cycles_defined_yet:
    'يبدو أنه لم يتم تحديد أي دورات مراجعة بعد.',
  you_do_not_have_permission_to_view_the_performance_description:
    'ليس لديك صلاحية لعرض أداء  الموظف. لمراجعة أداء الموظف وتقييمه أطلب صلاحية عرض الاداء من المشرف',
  you_dont_have_permission_to_view_employee_rating: 'لا تملك صلاحية عرض تقييم {{name}}',
  you_do_not_seem_to_have_any_objectives_defined_yet:
    'يبدو أنه لا يوجد لديك أي أهداف محددة حتى الآن',
  you_can_share_the_review_with_employee_after_saving_review:
    'يمكنك مشاركة المراجعة مع الموظف بعد حفظ المراجعة',
  you_havent_added_any_groups_to_the_clone_list_yet: 'لم تقم بإضافة أي مجموعات إلى قائمة النسخ بعد',

  // Z
}

export default ar
