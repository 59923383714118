import { Containers, Tag, Popover } from '@jisr-hr/ds'
import { Calender } from '@jisr-hr/ds-beta'
import { useState } from 'react'
import { format } from 'utils/date'
import { useDispatch, useSelector } from 'utils/hooks'
import { updateTask } from 'redux/requests/newRequestsApproval/actionCreators'
import i18n from 'translations/i18n'
import { useRequestDetail } from '../hooks'

type DueDatePorps = {
  dateValue?: string | Date
  showAction?: boolean
  isNewTask?: boolean
  runAction: (key: string | Date | null) => void
}

type DateObjType = {
  label: string
  value: string | Date
}

const DueDate = ({
  dateValue,
  showAction = false,
  isNewTask = false,
  runAction,
}: DueDatePorps): JSX.Element => {
  const dispatch = useDispatch()

  const isTagDisabled = (): boolean => {
    if (!isNewTask) {
      const { request } = useRequestDetail()
      const isSuperAdmin = useSelector((state) => state?.auth.employee?.is_super_admin)
      const currentUserId = useSelector((state) => state?.auth.employee?.id)
      const isCreator = currentUserId ? +request?.employee?.id === +currentUserId : false

      return !(isSuperAdmin || isCreator) || request.status !== 'Pending'
    }
    return false
  }

  const [dateObj, setDateObj] = useState<DateObjType>({
    label: dateValue ? format(dateValue, 'dd MMM, yyyy').toString() : '',
    value: dateValue || '',
  })
  const [showDate, setShowDate] = useState(false)

  const onApply = (): void => {
    if (!dateObj.value) {
      const date = new Date()
      setDateObj({
        label: format(date, 'dd MMM, yyyy').toString(),
        value: date,
      })
      runAction(date)
    } else {
      runAction(dateObj.value)
    }
    setShowDate(false)
  }

  const handelSetDate = (e: Date | Date[]): void => {
    setDateObj({
      label: format(e as Date, 'dd MMM, yyyy').toString(),
      value: format(e as Date, 'yyyy-MM-dd'),
    })
    if (!showAction) {
      setShowDate(false)
      runAction(e as Date)
    }
  }

  let clearDueDate
  if (!isNewTask) {
    const { request } = useRequestDetail()
    clearDueDate = async (): Promise<void> => {
      await dispatch(
        updateTask(request.id, {
          task: {
            due_date: null,
          },
        }),
      )
      setDateObj({
        label: i18n.t('label.set_due_date'),
        value: '',
      })
    }
  } else {
    clearDueDate = async (): Promise<void> => {
      runAction(null)
      setDateObj({
        label: i18n.t('label.set_due_date'),
        value: '',
      })
    }
  }

  return (
    <div>
      <Popover
        open={showDate}
        onClose={(): void => setShowDate(false)}
        position="bottom-end"
        trigger={
          <Tag
            prefix="icon"
            iconName="calendar"
            label={dateObj.label || i18n.t('label.set_due_date')}
            disabled={isTagDisabled()}
            closeIcon={dateObj.label !== i18n.t('label.set_due_date') && dateObj.label !== ''}
            onClick={(): void => setShowDate(true)}
            onClose={clearDueDate}
          />
        }
        className="z-[2000] mt-6"
      >
        <Containers
          color="white"
          border
          borderRadius="sm"
          zIndex={3}
          className="-m-9"
        >
          <Calender
            type="single"
            onChange={handelSetDate}
            value={dateObj.value ? new Date(dateObj.value) : new Date()}
            minDate={new Date()}
            footerActions={
              showAction
                ? {
                    onClickApply: onApply,
                    onCancel: (): void => setShowDate(false),
                  }
                : undefined
            }
          />
        </Containers>
      </Popover>
    </div>
  )
}

export default DueDate
