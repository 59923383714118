import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  sesstion_status_data: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function integrationReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'XERO_SESSION_PENDING':
    case 'XERO_SESSION_NEW_PENDING':
    case 'XERO_SESSION_DESTROY_PENDING':
    case 'SYNC_XERO_ACCOUNTS_PENDING':
    case 'XERO_ACCOUNTS_LOAD_PENDING':
    case 'INTEGRATABLE_COST_CENTERS_LOAD_PENDING':
    case 'INTEGRATABLE_COST_CENTERS_UPDATE_PENDING':
    case 'SYNC_XERO_TRACKINGS_PENDING':
    case 'XERO_TRACKINGS_LOAD_PENDING':
    case 'INTEGRATABLE_TRACKINGS_LOAD_PENDING':
    case 'INTEGRATABLE_TRACKINGS_UPDATE_PENDING':
    case 'XERO_EXPORT_ACCOUNT_PAYABLE_PENDING':
    case 'XERO_SESSION_STATUS_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'XERO_SESSION_REJECTED':
    case 'XERO_SESSION_NEW_REJECTED':
    case 'XERO_SESSION_DESTROY_REJECTED':
    case 'SYNC_XERO_ACCOUNTS_REJECTED':
    case 'XERO_ACCOUNTS_LOAD_REJECTED':
    case 'INTEGRATABLE_COST_CENTERS_LOAD_REJECTED':
    case 'INTEGRATABLE_COST_CENTERS_UPDATE_REJECTED':
    case 'SYNC_XERO_TRACKINGS_REJECTED':
    case 'XERO_TRACKINGS_LOAD_REJECTED':
    case 'INTEGRATABLE_TRACKINGS_LOAD_REJECTED':
    case 'INTEGRATABLE_TRACKINGS_UPDATE_REJECTED':
    case 'XERO_EXPORT_ACCOUNT_PAYABLE_REJECTED':
    case 'XERO_SESSION_STATUS_REJECTED': {
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }
    }

    case 'XERO_SESSION_FULFILLED':
    case 'XERO_SESSION_NEW_FULFILLED':
    case 'XERO_SESSION_DESTROY_FULFILLED':
    case 'SYNC_XERO_ACCOUNTS_FULFILLED':
    case 'INTEGRATABLE_COST_CENTERS_UPDATE_FULFILLED':
    case 'SYNC_XERO_TRACKINGS_FULFILLED':
    case 'INTEGRATABLE_TRACKINGS_UPDATE_FULFILLED':
    case 'XERO_EXPORT_ACCOUNT_PAYABLE_FULFILLED': {
      Toastr.success(action.payload.data.message)
      changes = {
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }
    }

    case 'XERO_ACCOUNTS_LOAD_FULFILLED':
      changes = {
        xeroAccounts: action.payload.data.data.accounts,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'INTEGRATABLE_COST_CENTERS_LOAD_FULFILLED':
      changes = {
        integratableAccounts: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'XERO_TRACKINGS_LOAD_FULFILLED':
      changes = {
        xeroTrackings: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'INTEGRATABLE_TRACKINGS_LOAD_FULFILLED':
      changes = {
        integratableTrackings: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'XERO_SESSION_STATUS_FULFILLED':
      changes = {
        sesstion_status_data: action.payload.data.data,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
