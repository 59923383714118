/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

export type AtsHiringManagerProps = {
  filterKey: string
  filters: string[]
}
const AtsHiringManager = (props: AtsHiringManagerProps): JSX.Element | null => {
  const { ats_hiring_manager } = useFilterAPI()
  const { handleFilter, filter } = useFilters()
  if (!ats_hiring_manager?.length) return null

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={ats_hiring_manager}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('label.hiring_manager')}
    />
  )
}
export default memo(AtsHiringManager)
