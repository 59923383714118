import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import classNames from 'classnames'
import Signpost from '../../../ds/Signpost'
import Styles from '../Workflow.module.css'

const CancellationBy = ({ cancellationLabel, label, dateAndTime, hasCancelledBy, isCanceled }) => (
  <Signpost>
    <Flex
      itemsCenter
      justifyBetween
      className={classNames(
        hasCancelledBy && Styles.cancelledBy,
        isCanceled && Styles.cancelledRequest,
      )}
    >
      <Flex
        itemsCenter
        style={{ gap: 2 }}
      >
        {cancellationLabel}
        <Typography
          text={label}
          className={Styles.label}
          variant="subtitle-2"
        />
      </Flex>
      <Typography
        text={dateAndTime}
        textColor="--color-base-colors-grey-700"
        variant="subtitle-2"
      />
    </Flex>
  </Signpost>
)

CancellationBy.propTypes = {
  cancellationLabel: PropTypes.string,
  label: PropTypes.node,
  dateAndTime: PropTypes.string,
  hasCancelledBy: PropTypes.bool,
  isCanceled: PropTypes.bool,
}

export default CancellationBy
