import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const account = {
  account_id: null,
  api_version: null,
  consumer_key: null,
  secret_key: null,
  sync_employees_information: false,
  token_id: null,
  token_secret: null,
}

const initialState = {
  fetching: false,
  account,
  connected: false,
  err_msg: '',
  statusFetching: false,
  onboarding_status: true,
  activating: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    // case actions.NETSUITE_ONBOARDING_STATUS_PENDING:
    case actions.SYNC_NETSUITE_EMPLOYEE_PENDING:
    case actions.EXPORT_NETSUITE_JOURNALS_PENDING:
    case actions.NETSUIT_LOAD_ACCOUNT_INFO_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.NETSUIT_LOAD_ACCOUNT_STATUS_PENDING: {
      return {
        ...state,
        statusFetching: true,
      }
    }

    case actions.ACTIVATE_JOURNAL_ACCOUNT_PENDING:
      return {
        ...state,
        activating: true,
      }

    case actions.ACTIVATE_JOURNAL_ACCOUNT_FULFILLED:
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        activating: false,
      }

    case actions.ACTIVATE_JOURNAL_ACCOUNT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        activating: false,
      }

    case actions.NETSUIT_CONNECT_ACCOUNT_PENDING:
    case actions.NETSUIT_DISCONNECT_ACCOUNT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.NETSUIT_CONNECT_ACCOUNT_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.NETSUIT_DISCONNECT_ACCOUNT_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        connected: false,
        fetching: false,
      }
    }

    // ? info loaded
    case actions.NETSUIT_LOAD_ACCOUNT_INFO_FULFILLED: {
      return {
        ...state,
        account: payload.data?.data?.account,
        fetching: false,
      }
    }

    // ? status loaded
    case actions.NETSUIT_LOAD_ACCOUNT_STATUS_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        connected: payload.data?.data?.connected,
        fetching: false,
        statusFetching: false,
      }
    }

    case actions.SYNC_NETSUITE_EMPLOYEE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SYNC_NETSUITE_EMPLOYEE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.NETSUIT_LOAD_ACCOUNT_INFO_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        statusFetching: false,
      }
    }
    case actions.NETSUIT_LOAD_ACCOUNT_STATUS_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        statusFetching: false,
      }
    }

    case actions.NETSUIT_DISCONNECT_ACCOUNT_REJECTED:
    case actions.NETSUIT_CONNECT_ACCOUNT_REJECTED: {
      return {
        ...state,
        err_msg: handleResponseErr(payload),
        fetching: false,
      }
    }

    case actions.EXPORT_NETSUITE_JOURNALS_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }
    }

    case actions.EXPORT_NETSUITE_JOURNALS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return { ...state, fetching: false }
    }

    default:
      return state
  }
}
