export default {
  // A
  alpha_numeric_msg: 'Only alphanumeric characters',
  aol_msg: 'Really? You still use AOL for your email?',
  // B
  // C
  // D
  // E
  email_msg: 'Invalid email address',
  // F
  fixed_length_validation_msg: 'Must be {{len}} characters',
  // G
  // H
  has_blank_spaces: 'Must have no blank spaces',

  // I
  invalid_date_msg: 'Invalid date',
  invalid_hijri_date_msg: 'Invalid hijri date',
  invalid_time: 'Invalid time',
  invalid_url: 'Invalid URL',
  invalid_https_url: "Enter valid URL that starts with 'https://'",
  // J
  // K
  // L
  // M
  max_length: 'The number of characters must not exceed {{max}}',
  min_length_msg: 'Must be {{min}} characters or more',
  min_value_msg: 'Must be at least {{min}}',
  must_be_or_greater_than: 'Must be or greater than {{days}}',
  // N
  number_msg: 'Must be a number',
  // O
  // P
  phone_number_msg: 'Invalid phone number, must be 10 digits',
  positive_msg: 'Must be a positive number',
  // Q
  // R
  // S
  start_with_msg: "Should starts with '{{start_with_digits}}'",
  // T
  too_old_msg: 'You might be too old for this',
  // U
  // V
  value_should_have_at_most: 'Value should have at most {{count}} decimal places.',
  // W
  // X
  // Y
  // Z
  space_not_allowed: 'Must be without any space',
  employee_name_length: 'Employee name should not exceed 35 characters',
}
