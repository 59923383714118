import { FC } from 'react'
import classNames from 'classnames'
import { Typography, Flex } from '@jisr-hr/ds'
import { ReactComponent as InfoIcon } from 'assets/images/info_icon_black.svg'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'

import InvoiceAlert from './InvoiceAlert'

type EstablishmentAlertsType = {
  type: 'info' | 'error' | 'success'
}

const EstablishmentAlerts: FC<EstablishmentAlertsType> = (props) => {
  const { type } = props
  const establishment_err = useSelector((s) => s.mudadIntegration.establishment_err)

  return (
    <>
      <InvoiceAlert type={type} />
      <Flex
        itemsStart
        style={{ gap: 8, marginBottom: 32 }}
        className={classNames(
          type === 'error' && mainStyles.warning_alert_box,
          type === 'success' && mainStyles.info_alert_box,
        )}
      >
        {type === 'success' && (
          <DSIcon
            name="check-verified-01"
            size="md"
            styles={{ flexShrink: 0 }}
          />
        )}

        {type === 'error' && <InfoIcon style={{ width: 20, height: 20, flexShrink: 0 }} />}
        <div>
          <Typography
            variant="interface/default/sm"
            style={{ lineHeight: '20px', color: '#14171F' }}
            text={
              (type === 'error' && establishment_err) ||
              (type === 'success' &&
                i18n.t('the_establishment_with_Mudad_has_been_registered_successfully'))
            }
          />
        </div>
      </Flex>
    </>
  )
}

export default EstablishmentAlerts
