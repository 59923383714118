import React from 'react'
import PropTypes from 'prop-types'
import { required } from 'components/global/form/FormValidations'
import { Field, useForm, useFormState } from 'react-final-form'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { TextInputField, NewSelectField } from 'components/final-form'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { Toastr } from 'components/global/Toastr'
import { FileUpload } from '@jisr-hr/ds-beta'

const reasonTypes = [
  { value: 'Personal', label: I18n.t('ex_personal') },
  { value: 'Business', label: I18n.t('ex_business') },
]

const punchReasonTypes = [
  { value: 'forgot_to_punch', label: I18n.t('forgot_to_punch') },
  { value: 'problem_with_mobile_app', label: I18n.t('problem_with_mobile_app') },
  { value: 'problem_with_biometric_device', label: I18n.t('problem_with_biometric_device') },
  { value: 'internet_issue', label: I18n.t('internet_issue') },
  { value: 'worked_remotely', label: I18n.t('worked_remotely') },
  {
    value: 'switched_shifts_with_another_employee',
    label: I18n.t('switched_shifts_with_another_employee'),
  },
  {
    value: 'worked_overtime_past_clock_out_time',
    label: I18n.t('worked_overtime_past_clock_out_time'),
  },
  { value: 'visited_an_external_site', label: I18n.t('visited_an_external_site') },
  { value: 'other', label: I18n.t('other') },
]

const minAttachmentsValidate = (min) => (value, values) => {
  if (!min) return undefined

  // Excludes attachments edit mode
  const deletedIds = values?.deletedAttachments?.map((item) => item.id) || []
  const attachments = value?.filter((attachment) => !deletedIds.includes(attachment.id)) || []

  return attachments.length < min ? I18n.t('min_length_msg', { min }) : undefined
}

const Reason = ({
  showReasonType,
  showPunchReasonTypes,
  hideReason,
  hideFileUpload,
  isRequired,
  title,
  fieldPlaceholder,
  minAttachments,
}) => {
  const { change } = useForm()
  const { values } = useFormState()

  const [showErrors, setErrors] = React.useState()
  const [initFile, setInitFile] = React.useState()

  const handleError = (errorsList) => {
    errorsList.forEach(({ errors }) => {
      errors?.forEach((e) =>
        e.code === 'file-too-small'
          ? Toastr.error(I18n.t('empty_file_error_msg'))
          : Toastr.error(e.message),
      )
    })
  }

  React.useEffect(() => {
    if (values?.attachments?.length) {
      const files = values.attachments?.map((file) => ({
        id: file.id,
        name: file.name ?? file.filename,
        file: file.url ?? file.data,
        size: file.size,
      }))
      setInitFile(files)
    }
  }, [values.attachments])

  React.useEffect(() => {
    if (showErrors) {
      handleError(showErrors)
    }
  }, [showErrors])

  return (
    <Frame title={title || I18n.t('reason')}>
      <Flex
        flexCol
        style={{ gap: '16px' }}
      >
        {showReasonType && (
          <NewSelectField
            name="reason_type"
            options={reasonTypes}
            validate={required}
            testId="reason-type"
          />
        )}
        {showPunchReasonTypes && (
          <NewSelectField
            name="reason"
            options={punchReasonTypes}
            validate={required}
            placeholder={I18n.t('reason')}
            testId="punch-reason-type"
          />
        )}
        {!hideReason && (
          <>
            <TextInputField
              testId="requests-reason"
              placeholder={I18n.t(fieldPlaceholder) || I18n.t('enter_your_reason')}
              name={showPunchReasonTypes ? 'justification' : 'reason'}
              parse={(val) => val}
              validate={(v) => (isRequired ? required(v) : undefined)}
            />
            {!hideFileUpload && (
              <Field
                name="attachments"
                validate={minAttachmentsValidate(minAttachments)}
              >
                {() => (
                  <FileUpload
                    compact
                    multiple
                    initFiles={initFile || []}
                    onLoad={(files) => {
                      setInitFile(files)

                      const attachments = files?.map((val) => ({
                        data: val?.file,
                        filename: val?.name,
                        id: val?.id,
                      }))
                      change('attachments', attachments)
                    }}
                    onRemove={(file) => {
                      if (typeof file?.id === 'number') {
                        change('deletedAttachments', [
                          ...values?.deletedAttachments,
                          {
                            data: file?.file,
                            filename: file.name,
                            id: file.id,
                            delete: true,
                          },
                        ])
                      } else {
                        change(
                          'attachments',
                          values?.attachments?.filter((it) => it.id !== file.id),
                        )
                      }
                    }}
                    message={I18n.t('add_attatchment')}
                    successMessage={I18n.t('file_selected')}
                    errorMessage={I18n.t('unable_to_upload_file')}
                    loadingMessage={I18n.t('uploading_file')}
                    uploadingMessage={I18n.t('uploading_in_progress')}
                    onError={(errors) => setErrors(errors)}
                  />
                )}
              </Field>
            )}
          </>
        )}
      </Flex>
    </Frame>
  )
}

Reason.defaultProps = {
  isRequired: false,
}

Reason.propTypes = {
  hideReason: PropTypes.bool,
  minAttachments: PropTypes.number,
  hideFileUpload: PropTypes.bool,
  showReasonType: PropTypes.bool,
  showPunchReasonTypes: PropTypes.bool,
  isRequired: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fieldPlaceholder: PropTypes.string,
}

export default Reason
