import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { HTTPValidationError } from 'types/httpTypes'
import { TModuleStatusAxiosResponse } from './useOrganizationsModuleStatus.type'

// ? query keys
const organizationsModuleKey: QueryKey = ['get_organizations_module_status']

// ? functions
const getOrganizationsModuleStatus = (): Promise<TModuleStatusAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/organizations/module_status`)
}
// ? hooks

/**
 * @endpoint: GET: /v2/organizations/module_status
 * @summary This hook used to get module status on onboarding
 */
export const useOrganizationsModuleStatus = <
  TError extends HTTPValidationError,
  TData = TModuleStatusAxiosResponse,
>(opts?: {
  query?: UseQueryOptions<TModuleStatusAxiosResponse, TError, TData>
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: organizationsModuleKey,
    queryFn: () => getOrganizationsModuleStatus(),
    onError: (err) => {
      Toastr.error(err?.response?.data.error)
    },
  })
}
