const Workable = {
  // A
  access_token: 'رمز الوصول',
  add_candidate_confirm_title: 'هل تريد إضافة {{emp_name}} الى شركتك؟',
  add_candidate_confirm_text: 'ستحتاج إلى بعض المعلومات الأساسية لتُضيف هذا الموظف إلى مؤسستك',

  // C
  connect_your_workable_account: 'ربط حساب Workable الخاص بك',
  candidates: 'الموظفين المرشحين',
  candidate_name: 'اسم المُرشح',
  candidate_source: 'المصدر',

  // H
  how_to_connect_workable_to_jisr: 'كيفية توصيل workable مع {{app_name}}',
  helper_text_access_token: 'يستخدم رمز الوصول لإذن العميل على الواجهات البرمجية المحددة',
  helper_text_subdomain:
    'البادئة (البريفيكس) الخاصة بنطاق العمل الذي يحدد طلبك بشكل فريد على ووركابل.',
  hired_candidates: 'مرشحين تم توظيفهم',
  hired_at: 'تم التوظيف في',

  // S
  search_add_candidates: 'ابحث بإسم الموظف او المسمى الوظيفي',
  sync_from: 'مزامنة من',

  // W
  workable: 'Workable',
  workable_description:
    'نظام تتبع المرشحين، برنامج اكتساب المواهب من Workable يساعد الفرق في العثور على المرشحين، تقييم المتقدمين واتخاذ القرار الصحيح في التوظيف',
  workable_permission_text:
    'من خلال ربط حسابك في workable مع {{app_name}}, أنت تسمح {{app_name}} بالقيام بالعمليات التالية:',
  workable_help_note: 'إذا كنت بحاجة لمساعدة يرجى<span> التواصل معنا</span>',
  workable_info_note: 'يمكنك معرفة المزيد حول التكامل مع Workable من خلال هذا الرابط.',
  workable_read_link: 'اقرأ هذه المقالة لمعرفة المزيد.',
  workable_view_action: 'رؤية قائمة الموظفين الذي تم توظيفهم',
  workable_add_action: 'أضف الموظف الى {{app_name}}',
}

export default Workable
