import { useState } from 'react'
import { Badge, Divider, Spacer } from '@jisr-hr/ds'
import { ViewedComponent } from 'components/authorised/Performace'
import { subDays } from 'date-fns'
import i18n from 'translations/i18n'
import { DatePicker } from '@jisr-hr/ds-beta'

import FilterBoxHeader from '../FilterBox/FilterBoxHeader'
import { useFilters } from './context'

const dateRangeList = [
  {
    value: 7,
    label: i18n.t('label.last_7_days'),
  },
  {
    value: 30,
    label: i18n.t('label.last_30_days'),
  },
  {
    value: 90,
    label: i18n.t('label.last_90_days'),
  },
]

type FiltersDateRangeProps = {
  title: string
  filterKey: string
}

const FiltersDateRange = (props: FiltersDateRangeProps) => {
  const { title, filterKey } = props
  const { filter, handleFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const from = filter?.[filterKey]?.from
  const to = filter?.[filterKey]?.to

  const handleDateRange = (value: number): void => {
    const date = new Date()
    const startDate = subDays(date, value)

    handleFilter(filterKey, { from: startDate, to: date })
  }

  return (
    <div className="p-4">
      <FilterBoxHeader
        canShowClear={false}
        handleClearFilter={(): void => undefined}
        handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
        title={title}
      />
      <Spacer height={8} />
      <ViewedComponent available={!isCollapsed}>
        <div className="flex gap-2 w-full">
          <DatePicker
            label={i18n.t('label.from-1')}
            triggerType="input"
            value={from ? new Date(from) : undefined}
            onChange={(_, date): void => handleFilter(filterKey, { from: date })}
            onRemoveDate={(): void => handleFilter(filterKey, { from: undefined })}
          />
          <DatePicker
            label={i18n.t('label.to')}
            value={to ? new Date(to) : undefined}
            onChange={(_, date): void => handleFilter(filterKey, { from, to: date })}
            triggerType="input"
            textFieldProps={{
              disabled: !from,
            }}
            calenderProps={{
              type: 'single',
              minDate: from,
            }}
          />
        </div>
      </ViewedComponent>
      <Spacer height={16} />
      <div className="flex gap-2">
        {dateRangeList.map((item) => (
          <div
            key={item.value}
            className="cursor-pointer"
            onClick={(): void => handleDateRange(item.value)}
          >
            <Badge
              size="small"
              label={item.label}
            />
          </div>
        ))}
      </div>
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />
    </div>
  )
}

export default FiltersDateRange
