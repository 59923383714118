import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.INTEGRATIONS_STATISTICS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.INTEGRATIONS_STATISTICS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.INTEGRATIONS_STATISTICS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }
    default:
      return state
  }
}
