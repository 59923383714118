import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { TParams as TDefaultParams } from 'containers/public/Onboarding/types/onboardingTypes'
import * as actions from './actions'
import { SubmitValuesType, TParams } from './type'

export function salariesPackagesLoad(params?: Partial<TDefaultParams>): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/salaries_packages`

  return {
    type: actions.SALARIES_PACKAGES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function salariesPackagesUpdate(data: SubmitValuesType, params: TParams): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/salaries_packages/:`

  return {
    type: actions.SALARIES_PACKAGES_UPDATE,
    payload: handleAPI(url, params, 'PUT', { salaries_packages: data }),
  }
}
