import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationTypes } from 'types/sharedResponse'
import { Employee } from 'redux/authUser/types'
import { TEmployeeFormReview } from 'apis/Performance/employee_form_reviews/useEmployeeFormReviews.type'
import { Action } from 'types/redux'
import * as actions from './actions'

export type AssignedEmployeesType = {
  id: number
  employee_id: number
  reviewer_id: number
  status: 'in_progress' | 'submitted' | 'draft' | 'unsubmitted'
  status_i18n: string
  start_date: string
  end_date: string
  is_offboarded?: boolean | null
  is_contract_eligible?: boolean
  contract: {
    deleted_at?: string
    updated_at?: string
  }
  evaluation_start_date: string
  evaluation_submission_date: string
  form?: TEmployeeFormReview
  reminder_sent_at?: string
  is_reopened: boolean
}

type initialStateType = {
  fetching: boolean
  eligible_employee_ids?: number[]
  employeesAll?: Employee[]
  reviewer_ids: number[]
  primary_reviewer?: { id: number; name: string; value: string; value_i18n: string } | null
  employee?: Employee[]
  assignedEmployees: AssignedEmployeesType[]
  assignedEmployeesPagination?: PaginationTypes | Record<string, never>
  assignedEmployeesLoading: boolean
  reviewer?: Employee[]
}

export const initialState: initialStateType = {
  fetching: false,
  eligible_employee_ids: [],
  primary_reviewer: null,
  employeesAll: [],
  reviewer_ids: [],
  assignedEmployees: [],
  assignedEmployeesPagination: {},
  assignedEmployeesLoading: false,
}

export default function reducer(state = initialState, action: Action): initialStateType {
  switch (action.type) {
    case actions.ASSIGN_LINE_MANAGER_PENDING:
    case actions.GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_PENDING:
    case actions.REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_ASSIGNED_EMPLOYEES_PENDING:
      return {
        ...state,
        assignedEmployeesLoading: true,
      }

    case actions.GET_ASSIGNED_EMPLOYEES_FULFILLED:
      return {
        ...state,
        assignedEmployees:
          action?.payload?.data?.pagination?.current_page === 1
            ? action?.payload?.data?.data
            : [...state.assignedEmployees, ...action?.payload?.data?.data],
        assignedEmployeesPagination: action?.payload?.data?.pagination,
        assignedEmployeesLoading: false,
      }

    case actions.REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ASSIGN_LINE_MANAGER_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.RESET_REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES:
      return {
        ...state,
        eligible_employee_ids: [],
        fetching: false,
      }

    case actions.GET_V2_PERFORMANCE_ALL_EMPLOYEES_CLEANUP:
      return {
        ...state,
        employeesAll: [],
        fetching: false,
      }

    case actions.GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_FULFILLED:
      const { response, onlyId, empType } = action.payload
      return {
        ...state,
        employeesAll: response?.data?.data?.employees,
        reviewer_ids: onlyId ? response?.data?.data?.employees : state.reviewer_ids,
        [empType]: response?.data?.data?.employees,
        fetching: false,
      }

    case actions.GET_ASSIGNED_EMPLOYEES_REJECTED:
    case actions.ASSIGN_LINE_MANAGER_REJECTED:
    case actions.GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        assignedEmployeesLoading: false,
      }

    default:
      return state
  }
}
