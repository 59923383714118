import { FC } from 'react'
import i18n from 'translations/i18n'
import { Button } from '@jisr-hr/ds'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { Link } from 'react-router-dom'
import DSIcon from 'components/DSIcon'
import { isRtl } from 'utils/uiHelpers'
import qs from 'qs'
import { violationActionTranslate } from 'containers/authorised/ReportedViolations/helper'

import { useRequest } from '../../RequestProvider'

const ViolationRequestTask: FC = () => {
  const {
    vars: { request },
  } = useRequest()

  return (
    <div className="flex flex-col gap-[16px]">
      <Info
        details={{
          id: request.id,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          created_at: request?.request?.created_time,
          requested_on: request?.request?.created_time,
          type_i18n: request.name,
          payment_date: request?.task_info?.paid_at,
          metadata: request?.metadata,
          due_date: request.due_date,
          items: [
            {
              icon: <DSIcon name="bookmark" />,
              label: i18n.t('label.violation'),
              value: (
                <Link
                  target="_blank"
                  to={{
                    pathname: `/reported_violations?${qs.stringify({
                      id: request?.task_info?.id,
                    })}`,
                  }}
                >
                  <Button
                    size="small"
                    variant="ghost"
                    label={String(request?.task_info?.violation_number)}
                    leadingIcon={!isRtl ? 'arrow-up-right' : 'arrow-up-left'}
                  />
                </Link>
              ),
            },
            {
              icon: <DSIcon name="bookmark" />,
              label: i18n.t('status.action'),
              value: violationActionTranslate(
                request?.task_info?.action,
                request?.task_info?.value,
              ),
            },
            {
              icon: <DSIcon name="bookmark" />,
              label: i18n.t('label.employee-1'),
              value: request?.task_info?.employee?.full_name_i18n,
            },
          ],
        }}
      />

      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {request?.status === 'Pending' && <ReassignTask />}

      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </div>
  )
}

export default ViolationRequestTask
