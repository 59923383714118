import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'
import { familyDetailsLoad } from 'redux/actions/employeeFamilyDetailActions'
import {
  createExitReentryVisaRequests,
  updateExitReentryVisaRequests,
} from 'redux/leaves/ExitReentryVisaRequest/actionCreators'
import { Reason, Profile } from '../../components/forms'
import FlightTicket from '../../components/forms/FlightTicket'
import ExitReEntryVisa from '../../components/forms/ExitReEntryVisa'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const ExitReentryVisaRequest = () => {
  const dispatch = useDispatch()

  const { fetching } = useSelector(({ businessTripCities }) => businessTripCities)
  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const onSubmit = (val) => {
    const data = {
      ...val,
      family_detail_ids: val?.ticket_info_attributes?.family_detail_ids || [],
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }

    if (data.id) {
      delete data.include_employee

      return dispatch(
        updateExitReentryVisaRequests(empId, data.id, {
          ...data,
          attachments: data.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }

    return dispatch(
      createExitReentryVisaRequests(empId, {
        ...data,
        attachments: data.attachments?.map((it) => omit(it, 'id')),
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        ...(request.require_ticket && {
          ticket_info_attributes: {
            ...request.ticket_info,
            family_detail_ids: request?.metadata?.family_detail_ids,
          },
        }),
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        require_ticket: false,
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    dispatch(familyDetailsLoad(empId, { template: 'public' }))

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(fetching || loading) && <JisrLoader absolute />}
      <Profile employee={employee} />
      <ExitReEntryVisa isExitReentryVisaRequest />
      <FlightTicket />
      <Reason />
    </Flex>
  )
}

export default ExitReentryVisaRequest
