import { handleAPI, API_VERSION_TWO, API_VERSION_ONE } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { assetmanagerAPI } from 'utils/assetmanagerUtils'
import { PayrollActivationStepNameType, PayschedulesBodyType } from './reducer'
import * as actions from './actions'

type PayrunLoadOptions = { reset: boolean }

export function payrunsLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns`
  const method = 'GET'

  return {
    type: actions.PAYRUNS_LOAD,
    payload: handleAPI(url, params, method),
  }
}

export function payrunLoad(id: string | number, opt?: PayrunLoadOptions): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/${id}`
  const method = 'GET'

  return {
    type: opt?.reset ? actions.PAYRUN_DETAIL_LOAD_WITH_RESET : actions.PAYRUN_DETAIL_LOAD,
    payload: handleAPI(url, {}, method),
  }
}

export function payrunSendReminder(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/${id}/reminder`
  return {
    type: actions.PAYRUN_SEND_REMINDER,
    payload: handleAPI(url, {}, 'POST'),
  }
}

export function payrollPayrunCancel(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/${id}/cancel`

  return {
    type: actions.PAYROLL_PAYRUN_CANCEL,
    payload: handleAPI(url, {}, 'PUT'),
  }
}
export function previousPayRuns(): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/list`

  return {
    type: actions.PREVIOUS_PAYRUNS,
    payload: handleAPI(url, {}, 'GET'),
  }
}
export function startPayRuns(data = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns`
  return {
    type: actions.START_PAYRUNS,
    payload: handleAPI(url, {}, 'POST', data),
  }
}
export function skipPayRuns(data = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/skip`
  return {
    type: actions.SKIP_PAYRUNS,
    payload: handleAPI(url, {}, 'POST', data),
  }
}
export function closePreviousPeriodsPayRuns(data = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/closing_previous_periods`
  return {
    type: actions.PREVIOUS_PERIODS_PAYRUNS,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function payrunReopen(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/${id}/reopen`
  return {
    type: actions.REOPEN_PAYRUNS,
    payload: handleAPI(url, {}, 'PUT'),
  }
}

export function payrunActivationStatus(): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_startup_configurations/show_steps`

  return {
    type: actions.PAYRUN_ACTIVATION_STATUS,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function setPayrunActivationStatusToCompleted(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_startup_configurations/${id}`
  const activationCompletedObj = {
    payroll_startup_configuration: {
      status: 'completed',
    },
  }
  return {
    type: actions.PAYRUN_SET_ACTIVATION_COMPLETED,
    payload: handleAPI(url, {}, 'PUT', activationCompletedObj),
  }
}

export function payrunUpdateActivationStepStatus(
  stepName: PayrollActivationStepNameType,
  action: 'set_as_complete' | 'remind_me_latter',
): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_startup_configurations/${action}`
  const stepObj = {
    step: {
      name: stepName,
    },
  }
  return {
    type: actions.PAYRUN_UPDATE_ACTIVATION_STEP_STATUS,
    payload: handleAPI(url, {}, 'PUT', stepObj),
  }
}

export function lastImportLoad(params = {}): Action {
  const url = `/${API_VERSION_ONE}/bulk_upload/last_import`

  return {
    type: actions.LAST_IMPORT_LOAD,
    payload: assetmanagerAPI(url, params, 'GET'),
  }
}
export function resetSpecificLastImport(
  key: 'wps_gosi_infos' | 'payroll_startup_payment_details' | 'payroll_adjustments',
): Action {
  return {
    type: actions.RESET_SPECIFIC_LAST_IMPORT,
    payload: { key },
  }
}

export function paySchedulesLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payschedules`

  return {
    type: actions.PAYSCHEDULES_LOAD,
    payload: handleAPI(url, { params }, 'GET'),
  }
}

export function paySchedulesUpdate(id: number, data: PayschedulesBodyType, params = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payschedules/${id}`

  return {
    type: actions.PAYSCHEDULES_UPDATE,
    payload: handleAPI(url, { params }, 'PUT', { payschedule: data }),
  }
}
export function paySchedulesCreate(data: PayschedulesBodyType): Action {
  const url = `/${API_VERSION_TWO}/finance/payschedules/`

  return {
    type: actions.PAYSCHEDULES_CREATE,
    payload: handleAPI(url, {}, 'POST', { payschedule: data }),
  }
}

export function employeesWithoutCRUpdate(id: number, crID: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payrun_employees/${id}`

  return {
    type: actions.EMPLOYEES_WITHOUT_CR_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { payrun_employee: { commercial_registration_id: crID } }),
  }
}
