export const XERO_ACCOUNT_TRANSACTION_SYNC = 'XERO_ACCOUNT_TRANSACTION_SYNC'
export const XERO_ACCOUNT_TRANSACTION_SYNC_FULFILLED = 'XERO_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const XERO_ACCOUNT_TRANSACTION_SYNC_REJECTED = 'XERO_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const XERO_ACCOUNT_TRANSACTION_SYNC_PENDING = 'XERO_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const XERO_ACCOUNT_BULK_UPDATE = 'XERO_ACCOUNT_BULK_UPDATE'
export const XERO_ACCOUNT_BULK_UPDATE_FULFILLED = 'XERO_ACCOUNT_BULK_UPDATE_FULFILLED'
export const XERO_ACCOUNT_BULK_UPDATE_REJECTED = 'XERO_ACCOUNT_BULK_UPDATE_REJECTED'
export const XERO_ACCOUNT_BULK_UPDATE_PENDING = 'XERO_ACCOUNT_BULK_UPDATE_PENDING'

export const XERO_ACCOUNTS_LOAD = 'XERO_ACCOUNTS_LOAD'
export const XERO_ACCOUNTS_LOAD_FULFILLED = 'XERO_ACCOUNTS_LOAD_FULFILLED'
export const XERO_ACCOUNTS_LOAD_REJECTED = 'XERO_ACCOUNTS_LOAD_REJECTED'
export const XERO_ACCOUNTS_LOAD_PENDING = 'XERO_ACCOUNTS_LOAD_PENDING'

export const XERO_SPECIFIC_ACCOUNT_LOAD = 'XERO_SPECIFIC_ACCOUNT_LOAD'
export const XERO_SPECIFIC_ACCOUNT_LOAD_FULFILLED = 'XERO_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const XERO_SPECIFIC_ACCOUNT_LOAD_REJECTED = 'XERO_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const XERO_SPECIFIC_ACCOUNT_LOAD_PENDING = 'XERO_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const XERO_SPECIFIC_ACCOUNT_DELETE = 'XERO_SPECIFIC_ACCOUNT_DELETE'
export const XERO_SPECIFIC_ACCOUNT_DELETE_FULFILLED = 'XERO_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const XERO_SPECIFIC_ACCOUNT_DELETE_REJECTED = 'XERO_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const XERO_SPECIFIC_ACCOUNT_DELETE_PENDING = 'XERO_SPECIFIC_ACCOUNT_DELETE_PENDING'
