import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import I18n from 'translations/i18n'
import { TicketProvisionFilterType } from 'containers/authorised/report/TicketProvisionReport/useTicketProvisionReport'
import * as actions from './actions'

export type InitialStateType = {
  fetching: boolean
  fetching_share: boolean
  months: []
  header: []
  records: []
  total: []
  summary: {
    total: string
  }
  filters: TicketProvisionFilterType
  is_available: boolean
}

export const initialState: InitialStateType = {
  fetching: false,
  fetching_share: false,
  months: [],
  header: [],
  records: [],
  total: [],
  summary: { total: '' },
  filters: {
    area_ids: [],
    business_unit_ids: [],
    department_ids: [],
    location_ids: [],
    employment_type_ids: [],
    job_title_ids: [],
    grade_ids: [],
    nationalities: [],
  },
  is_available: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.CHECK_TICKET_POLECY_LOAD_PENDING:
    case actions.TICKET_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.TICKET_REPORT_SHARE_PENDING: {
      return {
        ...state,
        fetching_share: true,
      }
    }
    case actions.CHECK_TICKET_POLECY_LOAD_FULFILLED:
    case actions.TICKET_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.TICKET_REPORT_SHARE_FULFILLED: {
      Toastr.success(I18n.t('email_sent_successfully'))
      return {
        ...state,
        fetching_share: false,
      }
    }
    case actions.CHECK_TICKET_POLECY_LOAD_REJECTED:
    case actions.TICKET_REPORT_SHARE_REJECTED:
    case actions.TICKET_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }
    }

    default:
      return state
  }
}
