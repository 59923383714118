import { Action } from 'types/redux'
import { handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export type TEmployeeContractsAttributes = {
  contract_period: string | null
  contract_type: string
  start_date?: string
  end_date?: string
  id: number
}

export type TEmployeeForm = {
  full_name_ar: string
  identification_info_attributes: {
    document_number: number
    nationality_id: number
    religion: string
    not_in_saudi: boolean
    employer_number: number
    iqama_issue_date: string
    iqama_expiry_date: string
    iqama_expiry_date_ar: string
    passport_number: number
    passport_expiry_date: string
  }
  joining_date: string
  employment_type_id: number | null | undefined
  location_id: number
  department_id: number
  employee_contracts_attributes: TEmployeeContractsAttributes[]
  gender: string
  marital_status: string
  date_of_birth: string
  email: string
  telephone: number
  job_title_id: number
  grade_id: number
  line_manager_id: number
  iqama_profession: string
  iqama_profession_ar: string
  code: string
  full_name_en: string
}
export type TEmployeeFormOptional = Partial<TEmployeeForm> & {
  id: number
  employee_outsourcing_company_attributes?: {
    outsourcing_company_id: number
  }
}

export const employeesCreate = (body: TEmployeeForm | TEmployeeFormOptional): Action => {
  const url = '/v2/onboarding/employees'
  return {
    type: actions.ONBOARDING_EMPLOYEES_POST,
    payload: handleAPI(url, {}, 'POST', { employee: body }),
  }
}

export function employeesLoad(params = {}): Action {
  const url = '/v2/onboarding/employees'

  return {
    type: actions.ONBOARDING_EMPLOYEES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function onboardingGetAllEmployeesLoad(): Action {
  const url = '/v2/onboarding/employees'

  return {
    type: actions.ONBOARDING_ALL_EMPLOYEES_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function admenEmployeesLoad(params = {}): Action {
  const url = '/v2/onboarding/employees/with_email'

  return {
    type: actions.ONBOARDING_ADMEN_EMPLOYEES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function getAllGradesLoad(params = {}): Action {
  const url = '/v2/onboarding/grades'

  return {
    type: actions.ONBOARDING_GRADES_GET_ALL_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function employeesUpdate(id: number, data: TEmployeeForm | TEmployeeFormOptional): Action {
  const url = `/v2/onboarding/employees/${id}`
  return {
    type: actions.ONBOARDING_EMPLOYEES_PUT,
    payload: handleAPI(url, {}, 'PUT', { employee: data }),
  }
}

export function bulkDeleteEmployees(data: number[]): Action {
  const url = `/v2/onboarding/employees/bulk_delete`

  return {
    type: actions.ONBOARDING_EMPLOYEES_BULK_DELETE,
    payload: handleAPI(url, { employee_ids: data }, 'DELETE'),
  }
}
