export const CHANGE_REQUEST_WORKFLOWS_LOAD = 'CHANGE_REQUEST_WORKFLOWS_LOAD'
export const CHANGE_REQUEST_WORKFLOWS_LOAD_PENDING = 'CHANGE_REQUEST_WORKFLOWS_LOAD_PENDING'
export const CHANGE_REQUEST_WORKFLOWS_LOAD_FULFILLED = 'CHANGE_REQUEST_WORKFLOWS_LOAD_FULFILLED'
export const CHANGE_REQUEST_WORKFLOWS_LOAD_REJECTED = 'CHANGE_REQUEST_WORKFLOWS_LOAD_REJECTED'

export const CHANGE_REQUEST_WORKFLOW_LOAD = 'CHANGE_REQUEST_WORKFLOW_LOAD'
export const CHANGE_REQUEST_WORKFLOW_LOAD_PENDING = 'CHANGE_REQUEST_WORKFLOW_LOAD_PENDING'
export const CHANGE_REQUEST_WORKFLOW_LOAD_FULFILLED = 'CHANGE_REQUEST_WORKFLOW_LOAD_FULFILLED'
export const CHANGE_REQUEST_WORKFLOW_LOAD_REJECTED = 'CHANGE_REQUEST_WORKFLOW_LOAD_REJECTED'

export const CHANGE_REQUEST_WORKFLOW_CREATE = 'CHANGE_REQUEST_WORKFLOW_CREATE'
export const CHANGE_REQUEST_WORKFLOW_CREATE_PENDING = 'CHANGE_REQUEST_WORKFLOW_CREATE_PENDING'
export const CHANGE_REQUEST_WORKFLOW_CREATE_FULFILLED = 'CHANGE_REQUEST_WORKFLOW_CREATE_FULFILLED'
export const CHANGE_REQUEST_WORKFLOW_CREATE_REJECTED = 'CHANGE_REQUEST_WORKFLOW_CREATE_REJECTED'

export const CHANGE_REQUEST_WORKFLOW_UPDATE = 'CHANGE_REQUEST_WORKFLOW_UPDATE'
export const CHANGE_REQUEST_WORKFLOW_UPDATE_PENDING = 'CHANGE_REQUEST_WORKFLOW_UPDATE_PENDING'
export const CHANGE_REQUEST_WORKFLOW_UPDATE_FULFILLED = 'CHANGE_REQUEST_WORKFLOW_UPDATE_FULFILLED'
export const CHANGE_REQUEST_WORKFLOW_UPDATE_REJECTED = 'CHANGE_REQUEST_WORKFLOW_UPDATE_REJECTED'

export const CHANGE_REQUEST_WORKFLOW_DELETE = 'CHANGE_REQUEST_WORKFLOW_DELETE'
export const CHANGE_REQUEST_WORKFLOW_DELETE_PENDING = 'CHANGE_REQUEST_WORKFLOW_DELETE_PENDING'
export const CHANGE_REQUEST_WORKFLOW_DELETE_FULFILLED = 'CHANGE_REQUEST_WORKFLOW_DELETE_FULFILLED'
export const CHANGE_REQUEST_WORKFLOW_DELETE_REJECTED = 'CHANGE_REQUEST_WORKFLOW_DELETE_REJECTED'
