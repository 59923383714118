import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function hiringRequestsLoad() {
  const url = `/${API_VERSION_TWO}/hiring_requests`
  return {
    type: actions.HIRING_REQUESTS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function hiringRequestCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/hiring_requests`
  return {
    type: actions.HIRING_REQUEST_CREATE,
    payload: handleAPI(url, {}, 'POST', { hiring_request: data }),
  }
}

export function hiringRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/hiring_requests/${id}`
  return {
    type: actions.HIRING_REQUEST_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { hiring_request: data }),
  }
}
