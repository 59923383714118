const Common = {
  // A
  attendance: 'Attendance',
  actions: 'Actions',
  assign: 'Assign',
  ascending: 'Ascending',
  add: 'Add',
  add_new: 'Add New',
  add_new_object: 'Add new {{object}}',
  attachment: 'Attachment',
  attachments: 'Attachments',
  apply_date: 'Apply Date',
  apply_month: 'Apply Month',
  amount: 'Amount',
  announcement: 'Announcement',
  announcements: 'Announcements',
  add_attachments: 'Add Attachments',
  add_attachment: 'Add Attachment',
  add_new_attachment: 'Add New Attachment',
  attachment_file_name: 'Attachment File Name',
  all: 'All',
  added: 'Added',
  arabic_only: 'Please enter only Arabic characters.',
  area: 'Area',
  are_you_sure: 'Are you sure?',
  apply_filters: 'Apply filters',
  active: 'Active',
  all_employees: 'All employees',
  add_new_location_branch: 'Add New',
  add_new_region: 'Create New',
  amount_currency: '{{currency}} {{amount}}',
  and: 'and',
  ahad: 'Ahad',
  ahad_HR: 'Ahad HR',
  assigned_tasks: 'Assigned Tasks',
  assing_attendance_reviewer: 'Assign attendance reviewer',
  ask_for_cancellation: 'Ask for cancellation',
  // B
  browser_notifications: 'Browser Notifications',
  browser_notifications_disabled_alert_desc:
    'To receive desktop notifications, you need to enable <span>Browser Notifications</span>.',
  browser_notifications_disabled_alert_title: 'Browser Notifications Disabled',
  bulk_import_sheets: 'Bulk import/Export',
  business_unit: 'Business Unit',
  beta: 'Beta',
  enable_notifications: 'Enable notifications',
  back_to_home: 'Back to Home',
  // C
  cancellation: 'Cancellation',
  company: 'Company',
  company_assets: 'Company Assets',
  clear: 'Clear',
  create: 'Create',
  cancel: 'Cancel',
  coming_late: 'Coming late',
  create_new: 'Create New',
  correct_data: 'Correct data',
  clear_all: 'Clear all',
  clickordrag: 'Drag your file here',
  choose_file: 'Choose file',
  confirmed_successfully: 'Confirmed successfully',
  coming_soon: 'Coming Soon',
  soon: 'Soon',
  clear_filters: 'Clear Filters',
  clear_all_filters: 'Clear all filters',
  contact_support_intercom: 'Contact Support',
  cancel_excuse_request: 'Cancel Excuse Request',
  custom_range: 'Custom range',
  cancel_immediately: 'Cancel Immediately',
  cancel_request_immediately: 'Cancel request immediately without workflow approval',
  chrome_notifications_guide:
    '1. At the top right, click on <Dots /> <b>More -> Settings.</b><br />2. Click on <b>Privacy and security -> Site Settings -> Notifications</b><br />3. At the top right type <b><whiteLabel /></b> to search<br />4. Click on the <b>More icon</b><br />5. Click on <b>Allow</b>',
  check_search_keywords: 'Check the spelling, number, or try using a different keyword.',
  // D
  description: 'Description',
  date_allocated: 'Allocated Date (Gregorian)',
  department: 'Department',
  date: 'Date (Gregorian)',
  DATE: 'DATE',
  delete: 'Delete',
  descending: 'Descending',
  complete: 'Complete',
  divisions: 'Divisions',
  download_template_file: 'Download template file',
  discard: 'Discard',
  details: 'Details',
  due_requests: 'Due requests',
  document_added: 'Document added',
  document_updated: 'Document updated',
  // E
  earlier: 'Earlier',
  end_date: 'End Date (Gregorian)',
  end_date_hijri: 'End Date (HIJRI)',
  english_only: 'Please enter only English characters.',
  error: 'Error',
  errors: 'Errors',
  employee_avatar_size_should_be_less_than_or_equal_to_megabyte:
    'employee avatar size should be less than or equal to 2 Megabyte',
  menu__expenses: 'Expenses',
  error_attachment: 'Error attachment',
  export_journal: 'Export journal',
  enter_your_description: 'Enter your description',
  edge_notifications_guide:
    '1. At the top right, click on <Dots /> <b> More -> Settings.</b><br>2. Click on <b>Cookies and Site Permissions</b><br>3. Click on <b>Notifications</b><br>4. Look for <b> <whiteLabel /> </b> then click on <b>Allow</b>',
  events: 'Events',
  empty_file_error_msg: 'Empty file. Please attach the right document and try again',
  // F
  fetching: 'Fetching',
  file_type: 'File Type',
  from_date: 'From date',
  from_month: 'From month',
  from_year: 'From year',
  from: 'From',
  from_person: 'From person',
  filter_by: 'Filter by',
  filter: 'Filter',
  field_name: 'Field Name',
  failed: 'Field',
  faild: 'Failed',
  filters: 'Filters',
  file_is_required: 'File is required',
  food: 'food',
  finish: 'Finish',
  firefox_notifications_guide:
    '1. At the top right, click on <Bars /> <b>More -> Settings</b><br>.2. Click on <b>Privacy and security</b><br>3. Under <b>Permissions</b> look for <b>Notifications</b> then click on <b>Settings</b><br>4. Search for <b><whiteLabel /></b> then <b>change status to Allow</b>',
  // G
  grade: 'Grade',
  gender: 'Gender',
  group: 'Group',
  // H
  help: 'Help',
  help_support: 'Help & Support',
  home: 'Home',
  hiring_employees_details: 'Hiring employees details',
  help_center: 'Help Center',
  help__support: 'Help & Support',
  how_to_receive_notifications: 'How to receive {{app_name}} notifications',
  // I
  importing: 'Importing...',
  import: 'Import',
  it_will_take_few_seconds: 'It will take few seconds...',
  in_active: 'Inactive',
  internet_error_message:
    "Can't send request to the server, please check your internet connection!",
  invite: 'invite',
  must_equal: 'it must be {{val}}',
  include_in_employees_profile: 'Include in employees profile',
  inbox: 'Inbox',
  item_name: 'Item name',
  inbox_and_reminders: 'Inbox & Reminders',
  // J
  jisr: 'Jisr',
  jisr_HR: 'Jisr HR',
  jisr_translation_name: 'Jisr',
  jisr_translation_app_name: 'Jisr HR',
  jisr_academynav: 'Jisr Academy',
  // K
  // L
  location: 'Location',
  locations: 'Locations',
  line_manager: 'Line Manager',
  leaves: 'Leaves',
  leave_types: 'Leave types',
  leave_status: 'Leave Status',
  last_updated: 'Last updated',
  letters_managment: 'Letters managment',
  leave_resumption: 'Leave Resumption',
  leave_planning: 'Leave Planning',
  location_branch_name_in_english: 'name',
  location_branch_name_in_arabic: 'Name in Arabic',
  last_payslips: 'Last Payslip',
  lets_be_productive_today: 'Let’s be productive today 🚀',
  // M
  mark_all_as_read: 'Mark all as read',
  message: 'Message',
  month: 'Month',
  MONTH: 'MONTH',
  MONTHLY: 'MONTHLY',
  more_filters: 'More filters',
  misc: 'MISC',
  minutes: 'Minutes',
  // N
  no: 'No',
  no_new_notifications: 'Nothing New!',
  next_day: 'Next day',
  notes: 'Notes',
  no_data: 'No Data',
  number: 'Number',
  name: 'Name',
  name_in_arabic: 'Name in Arabic',
  name_in_english: 'Name in English',
  no_image_note_drag: 'No image, click or drag file to upload',
  nationality: 'Nationality',
  nationalities: 'Nationalities',
  no_employee_selected: 'No employee selected',
  no_education: 'No education',
  no_promotion_history: 'No promotion history',
  no_benefits: 'No benefits',
  no_salary_appraisals_history: 'No salary appraisals history',
  no_loans: 'No loans',
  no_expenses: 'No expenses',
  no_employee_files: 'No employee files',
  no_assets: 'No assets',
  no_warning_noticies: 'No warning noticies',
  no_employees: 'No employees',
  no_leaves: 'No leaves',
  no_attendance: 'No attendance',
  no_attachment: 'No attachment',
  no_scheduler: 'No scheduler',
  no_manual_additions: 'No manual additions',
  no_manual_deductions: 'No manual deductions',
  no_company_documents: 'No company documents',
  not_updated: 'Not Updated',
  not_registered_gosi: 'Not Registered In GOSI',
  no_result_found: 'No result found',
  next_month: 'Next month',
  n_days: '{{days}} Days',
  n_months: '{{months}} Months',
  n_years: '{{years}} Years',
  n_months_days: '{{months}} Months, {{days}} Days',
  no_requests_are_pending_on_you: 'No requests are pending on you',
  no_documents_are_expiring_in_this_period: 'No documents are expiring in this period',
  // O
  of: 'of',
  ok: 'Ok',
  okay: 'Ok',
  overview: 'Overview',
  others_2: 'Others',
  organisation_chart: 'Organisation Chart',
  off_cycle_payroll: 'Off Cycle Payroll',
  overtime_alt_translation: 'Overtime',
  code_validation_msg: 'Only numbers, letters, and "-" are allowed!',
  // P
  please_confirm_form_first: 'Please Confirm Form Firstly',
  payroll: 'Payroll',
  pending_from_person: 'Pending from person',
  post_type: 'Post Type',
  payment_outside_payroll: 'Payment Outside Payroll',
  payroll_table: 'Payroll Table',
  Required: 'Required',
  process_payments: 'Process Payments',
  payroll_has_successfully_been_confirmed: '{{date}} payroll has successfully been confirmed',
  payment_processing: 'Payment Processing',
  pay: 'Pay',
  processing_these_requests:
    'Processing these requests will ensure that their effect takes place on time',
  previous_day: 'Previous day',
  // Q
  // R
  region: 'Region',
  resumed_late: 'Resumed late',
  return_date: 'Return Date (Gregorian)',
  reports: 'Reports',
  record_types: 'Record types',
  requests_and_comments: 'Requests & Comments',
  requests_and_tasks: 'Requests & Tasks',
  request_cancel_note_multi:
    'This action will cancel the leave request ( {{type}} ) from {{from}} to {{to}}.',
  request_cancel_note_single: 'This action will cancel the leave request ( {{type}} ) for {{from}}',
  registered_gosi: 'Registered In GOSI',
  rs: '񥀠',
  reminders: 'Reminders',
  renew: 'Renew',
  requested_on: 'Requested On',
  request_on: 'Request on',
  resignation_details: 'Resignation details',
  run: 'Run',
  review: 'Review',
  // S
  start_date: 'Start Date (Gregorian)',
  start_date_hijri: 'Start Date (HIJRI)',
  status: 'Status',
  STATUS: 'STATUS',
  sar: '񥀠',
  showing: 'Showing',
  save: 'Save',
  save_change: 'Save changes',
  save_next: 'Save & Next',
  sponsor_id: 'Sponsor ID',
  submit: 'Submit',
  setting: 'Settings',
  scheduler: 'Scheduler',
  settings: 'Settings',
  select_all: 'Select All',
  statuses: 'Statuses',
  shift_types: 'Shift Types',
  search: 'Search',
  shifts: 'Shifts',
  size: 'Size',
  save_workflow: 'Save Workflow',
  sponsor_note: 'Please choose Sponsor for Muqeem employees',
  select: 'Select',
  selected_employees: 'Selected Employees',
  success: 'Sucess',
  successfully: 'Successfully',
  search_placeholder: 'Search by Employee ID, Name, Iqama or Job title',
  search_placeholder2: 'Search by Employee ID, Name',
  search_placeholder3: 'Search by Employee Name',
  shift_scheduler: 'Scheduler',
  search_placeholder4: 'Search by employee name, ID, or Iqama',
  search_placeholder5: 'Search by Employee ID, Name, Iqama',
  show_documents_that_will_expire: 'Show documents that will expire in',
  search_for_requests: 'Search for requests',
  search_for_tasks: 'Search for tasks',
  specified_monthly_amount: 'Specified monthly amount',
  specified_number_of_months: 'Specified number of months',
  share_your_idea: 'Share your ideas',
  send_cancellation_request: 'Send cancellation request to the workflow approval',
  show_more: 'Show more',
  show_less: 'Show less',
  safari_notifications_guide:
    '1. At the top left corner, next to <Apple /> icon, <b>Safari</b> > <b>Settings</b> > <b>Websites</b>.<br>2. From the side bar, scroll down to <b>“Notifications”</b>.<br>3. Go to <whiteLabel />, and change the permission from “Deny” to <b>“Allow”</b>',
  scheduled_interview_on: 'Scheduled interview on {{date_time}}',
  // T
  title: 'Title',
  to: 'To',
  type: 'Type',
  TYPE: 'TYPE',
  type_to_start_searching: 'Type to start searching...',
  total: 'Total',
  total_salary: 'Total salary',
  to_date: 'To date',
  to_month: 'To month',
  to_year: 'To year',
  transactions: 'Transactions',
  the_locations: 'Locations',
  the_departments: 'Departments',
  this_week: 'This week',
  transportation: 'transportation',
  tomorrow: 'Tomorrow',
  // U
  username: 'User name',
  upload_your_attachment: 'Upload your attachment',
  upload_template_file: 'Upload template file',
  updated: 'Updated',
  updates: 'Updates',
  uncorrect_data: 'Wrong data',
  untitled: 'Untitled',
  // V
  // W
  want_to_delete: 'Want to Delete?',
  want_to_remove: 'Want to Remove?',
  warning: 'Warning',
  where_it_would_be_paid: 'Where it would be paid?',
  what_new: "What's New",
  welcome_back: 'Welcome Back,',
  // X
  xls: 'XLS',
  // Y
  year: 'Year',
  year_is_so_big_as_hijri: 'Unexpected year for hijri calendar',
  yes: 'Yes',
  you_may_now_process_the_payments: 'you may now process the payments',
  you: 'You',
  you_did_not_send_any_requests_yet: 'You didn’t send any requests yet',
  you_need_enable_notifications:
    'To get the file upload status, you need to enable the browser notifications',

  // Z
}

export default Common
