import { useEffect } from 'react'

function useOnClickOutside(ref, handler) {
  const dialogEvent = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler(event)
    }
  }

  useEffect(() => {
    window.addEventListener('click', dialogEvent)

    return () => {
      window.removeEventListener('click', dialogEvent)
    }
  })
}

export default useOnClickOutside
