import { useContext, useMemo, useEffect, ReactNode } from 'react'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import MudadImg from 'assets/figma-icons/onboarding-mudad-icon.svg'
import OfflineImg from 'assets/figma-icons/onboarding-offline-icon.svg'
// import BankImg from 'assets/figma-icons/new-bank-icon.svg';

import { allowancesSelector } from 'redux/payrollOnboarding/allowances/selectors'
import Avatar from '@material-ui/core/Avatar'
import { onboardingBenefitLoad } from 'redux/payrollOnboarding/allowances/actionsCreators'
import { paySchedulesLoad } from 'redux/payrollOnboarding/PaySchedules/actionsCreators'
import { payschedulesSelector } from 'redux/payrollOnboarding/PaySchedules/selectors'
import { format } from 'utils/date'
import { useDispatch, useSelector } from 'utils/hooks'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import SummaryCard from './components/SummaryCard'
import SalariesPackagesSummary from './SalariesPackagesSummary'
import EmployeePaymentDetailsSummary from './EmployeePaymentDetailsSummary'
import PayrollBankSummary from './PayrollBankSummary'

export type THandleEditStep = {
  handleEditStep: (toStep: number) => void
}
export type ViewMoreModalProps = {
  isOpen: boolean
  onClose: () => void
}

const SummaryReview = ({
  goPreviousStep,
  previousStep,
  setCurrentStep,
  goNextStep,
  nextStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { require_pincode } = useSelector(({ organization }) => organization?.organization)
  const { allowances } = useSelector(allowancesSelector)
  const { cut_off_date } = useSelector(payschedulesSelector)
  const { commercial_registrations } = useSelector((s) => s.commercialRegistrationsOnborading)
  const activeBank = commercial_registrations?.filter((item) => item.payment_mode !== null)
  const { org, setPayrollCurrentStep } = useContext(OnboardingContext)

  const allowancesList = useMemo(
    () => [...(allowances || [])].filter((item) => item.enabled),
    [allowances?.length],
  )

  const isThisMonth = format(cut_off_date, 'M') === format(new Date(), 'M')

  const handleEditStep = (toStep: number): void => {
    localStorage.setItem(`payroll_setup_step_${org.id}`, toStep.toString())
    setCurrentStep?.(toStep)
    setPayrollCurrentStep(toStep)
  }

  useEffect(() => {
    dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
    dispatch(paySchedulesLoad())
  }, [])
  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('summary_review')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('summary_review_desc')}
      />

      <Spacer height={32} />

      <SummaryCard
        title="pay_schedules"
        contentItems={[
          {
            label: 'payroll_month',
            value: I18n.t(isThisMonth ? 'this_month_date' : 'next_month_date', {
              date: format(cut_off_date, 'MMMM yyyy'),
            }),
          },
          { label: 'cutoff_date', value: cut_off_date },
        ]}
        onEditClick={(): void => handleEditStep(1)}
      />

      <Spacer height={32} />

      <SummaryCard
        title="allowances"
        contentItems={[
          {
            label: 'types',
            value: allowancesList?.map(
              (item): ReactNode => (
                <Typography
                  key={item.name}
                  style={{ marginBottom: 4 }}
                  variant="interface/default/m"
                  text={item?.name_i18n}
                />
              ),
            ),
          },
        ]}
        onEditClick={(): void => handleEditStep(2)}
      />

      <Spacer height={32} />

      <SalariesPackagesSummary handleEditStep={handleEditStep} />

      <Spacer height={32} />

      <EmployeePaymentDetailsSummary handleEditStep={handleEditStep} />

      <Spacer height={32} />

      <PayrollBankSummary handleEditStep={handleEditStep} />

      <Spacer height={32} />

      {activeBank?.length !== 0 && (
        <SummaryCard
          title="payment_methods"
          contentItems={activeBank?.map((item) => ({
            label: 'org_name',
            value: (
              <Flex
                itemsCenter
                justifyBetween
                style={{ width: 305 }}
              >
                <Typography
                  variant="interface/default/m"
                  text={item?.name}
                />
                <Avatar
                  style={{ width: 32, height: 32 }}
                  src={item?.payment_mode === 'mudad' ? MudadImg : OfflineImg}
                  alt=""
                />
              </Flex>
            ),
          }))}
          onEditClick={(): void => handleEditStep(require_pincode ? 7 : 9)}
        />
      )}

      <Spacer height={32} />
    </StepTemplate>
  )
}
export default SummaryReview
