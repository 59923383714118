import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type RequestsPendingOnProps = {
  filterKey: string
  filters: Array<string>
}

const RequestsPendingOn = (props: RequestsPendingOnProps): JSX.Element => {
  const [selectedIds, setSelectedIds] = useState<Array<string>>([])
  const { pending_on, tasks_pending_on } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const isTasks = props.filters.includes('tasks_request_type')
  const optionList = returnBadgeList(isTasks ? tasks_pending_on : pending_on)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (val !== 'all') {
      setFilter({ ...filter, pending_on: [val] })
    } else {
      setFilter({ ...filter, pending_on: [] })
    }
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
      title={i18n.t(isTasks ? 'assignee_2' : 'approver')}
    />
  )
}

export default RequestsPendingOn
