import { Spacer } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import AdvancedConfiguration from './components/AdvancedConfiguration'
import CalcualtionOfVacationSalary from './components/CalcualtionOfVacationSalary'
import CalculationConfiguration from './components/CalculationConfiguration'
import styles from './style.module.css'

const VacationSettlement = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'vacation_settlement' })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      className={styles.stepTemplate}
    >
      <AdvancedConfiguration />
      <Spacer height={24} />
      <CalculationConfiguration />
      <Spacer height={24} />
      <CalcualtionOfVacationSalary />
    </StepTemplate>
  )
}

export default VacationSettlement
