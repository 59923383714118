import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { FileInfo } from '@jisr-hr/ds-beta'
import { FileType } from 'containers/authorised/employee/EmployeeDetails/types'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import { RequestTypeField } from 'redux/reports/customRequests/requestTypes/types'
import {
  DatePickerDSField,
  MultiSelectField,
  NewFileUploadField,
  BetaTextInputField as TextInputField,
  SelectField,
} from 'components/final-form'
import { composeValidators, hasBlankSpaces, required } from 'components/global/form/FormValidations'
import InfoIcon from 'components/authorised/setting/workflow/Icons/Info'
import { isRtl } from 'utils/uiHelpers'

import styles from '../../Request.module.css'

type UpdatedFile = { id?: number; data?: string; filename?: string; delete?: boolean }

type CustomRequestFieldsProps = {
  activeFields: Array<RequestTypeField>
  messageWhenNoFieldsActive?: string
  isUpdate?: boolean
}

const CustomRequestFields = (props: CustomRequestFieldsProps): JSX.Element => {
  const {
    isUpdate,
    activeFields,
    messageWhenNoFieldsActive = i18n.t('this_request_doesnt_require_additional_info'),
  } = props
  const { change } = useForm()
  const { values, initialValues } = useFormState()

  const [deletedAttachments, setDeletedAttachments] = useState<{ id: number; delete: boolean }[]>(
    [],
  )

  function processFiles(files: FileInfo[], initFiles: FileType[]): UpdatedFile[] {
    const initFileIds = new Set(initFiles.map((file) => String(file.id)))
    return files.reduce<UpdatedFile[]>((acc, file) => {
      if (!initFileIds.has(String(file.id))) {
        acc.push({ data: file.file, filename: file.name })
      }
      return acc
    }, [])
  }
  const hasNoData =
    !activeFields.length || (activeFields.length === 1 && !activeFields[0]?.is_enabled)

  let textCounter = 0
  let numberCounter = 0
  let dateCounter = 0
  let multipleChoiceCounter = 0

  useEffect(() => {
    if (deletedAttachments?.length) {
      change('updatedAttachments', deletedAttachments)
    }
  }, [deletedAttachments?.length])

  const onRemove = (val: { id: number; name: string } | FileInfo): void => {
    if (isUpdate) {
      if (typeof val.id === 'number') {
        if (values.updatedAttachments) {
          const uniqueAttachments = new Set(
            [...deletedAttachments, ...values.updatedAttachments, { id: val.id, delete: true }].map(
              (item) => JSON.stringify(item),
            ),
          )
          setDeletedAttachments([...Array.from(uniqueAttachments).map((item) => JSON.parse(item))])
        } else {
          setDeletedAttachments([...deletedAttachments, { id: val.id, delete: true }])
        }
      } else {
        // Filter out the attachment to be removed
        const updatedFiles = values.updatedAttachments?.filter(
          (value: { filename: string }) => value.filename !== val.name,
        )
        if (updatedFiles) {
          const uniqueAttachments = new Set(
            [...deletedAttachments, ...updatedFiles].map((item) => JSON.stringify(item)),
          )
          setDeletedAttachments(Array.from(uniqueAttachments).map((item) => JSON.parse(item)))
        }
      }
    }
  }

  if (hasNoData)
    return (
      <Flex
        flexCol
        itemsCenter
        className={styles.groupField}
      >
        <InfoIcon />
        <Spacer height={16} />
        <Typography
          text={messageWhenNoFieldsActive}
          style={{
            textAlign: 'center',
            padding: '10px',
            color: 'var(--color-base-colors-grey-1000)',
          }}
          variant="subheading"
        />
      </Flex>
    )
  return (
    <div>
      <Flex flexCol>
        <Flex
          flexCol
          style={{ gap: 16 }}
          className={styles.groupField}
        >
          <Typography
            text={i18n.t('request_details')}
            variant="title-1"
          />

          {activeFields.map((obj, i) => {
            switch (obj.type) {
              case 'text': {
                const index = textCounter
                textCounter += 1
                return (
                  <TextInputField
                    key={i}
                    name={`text.${index}`}
                    {...(!obj.is_optional && {
                      validate: composeValidators(hasBlankSpaces, required),
                    })}
                    label={`${isRtl ? obj.name_ar : obj.name_en} ${
                      obj.is_optional ? ` (${i18n.t('optional')})` : ''
                    }`}
                  />
                )
              }
              case 'number': {
                const index = numberCounter
                numberCounter += 1
                return (
                  <TextInputField
                    key={i}
                    name={`number.${index}`}
                    {...(!obj.is_optional && {
                      validate: composeValidators(hasBlankSpaces, required),
                    })}
                    label={`${isRtl ? obj.name_ar : obj.name_en} ${
                      obj.is_optional ? ` (${i18n.t('optional')})` : ''
                    }`}
                    type="number"
                  />
                )
              }
              case 'date': {
                const index = dateCounter
                dateCounter += 1
                return (
                  <Flex
                    key={i}
                    style={{ gap: 8 }}
                    className={styles.datePicker}
                  >
                    <DatePickerDSField
                      name={`date.${index}.start`}
                      label={`${isRtl ? obj.start_date_name_ar : obj.start_date_name_en} ${
                        obj.is_optional ? ` (${i18n.t('optional')})` : ''
                      }`}
                      {...(!obj?.is_optional && { validate: required })}
                      onChange={(): void => {
                        if (obj?.is_range) {
                          change(`date.${index}.end`, null)
                        }
                      }}
                    />
                    {obj?.is_range && (
                      <DatePickerDSField
                        name={`date.${index}.end`}
                        textFieldProps={{
                          disabled: !values?.date?.[index]?.start,
                        }}
                        calenderProps={{
                          minDate: new Date(values?.date?.[index]?.start),
                        }}
                        label={`${isRtl ? obj.end_date_name_ar : obj.end_date_name_en} ${
                          obj.is_optional ? ` (${i18n.t('optional')})` : ''
                        }`}
                        {...(!obj?.is_optional && { validate: required })}
                      />
                    )}
                  </Flex>
                )
              }
              case 'multiple_choice': {
                const index = multipleChoiceCounter
                multipleChoiceCounter += 1
                return obj.is_multiple ? (
                  <MultiSelectField
                    key={i}
                    options={obj.possible_values?.map((val) => ({ label: val, value: val })) ?? []}
                    label={`${isRtl ? obj.name_ar : obj.name_en} ${
                      obj.is_optional ? ` (${i18n.t('optional')})` : ''
                    }`}
                    name={`multiple_choice.${index}`}
                    placeholder={i18n.t('select')}
                    {...(!obj?.is_optional && {
                      validate: required,
                    })}
                  />
                ) : (
                  <SelectField
                    key={i}
                    // @ts-expect-error // select field needs to be migrated to TS
                    options={obj.possible_values?.map((val) => ({ label: val, value: val }))}
                    label={`${isRtl ? obj.name_ar : obj.name_en} ${
                      obj.is_optional ? ` (${i18n.t('optional')})` : ''
                    }`}
                    name={`multiple_choice.${index}`}
                    {...(!obj?.is_optional && {
                      validate: required,
                    })}
                  />
                )
              }
              case 'attachment':
                return (
                  obj.is_enabled && (
                    <div>
                      <Typography
                        text={
                          <>
                            {i18n.t('attachment')}
                            {obj.is_optional && ` (${i18n.t('optional')})`}
                          </>
                        }
                        variant="subtitle-2"
                      />
                      <Spacer height={16} />
                      <NewFileUploadField
                        compact
                        initFiles={initialValues?.attachments ?? []}
                        name="attachments"
                        accept=".doc, .docx, .xls, .xlsx, .csv, .pdf, image/*"
                        multiple
                        isActiveStorage
                        onRemove={(val: FileInfo): void => {
                          onRemove(val)
                        }}
                        onLoad={(files): void => {
                          if (files) {
                            if (isUpdate) {
                              const updatedFiles = processFiles(
                                files as FileInfo[],
                                initialValues?.attachments,
                              )
                              const allUpdatedFiles = (values.updatedAttachments || []).concat(
                                updatedFiles,
                              )
                              change('updatedAttachments', allUpdatedFiles)
                            }
                          }
                        }}
                        {...(!obj.is_optional && { validate: required })}
                      />
                    </div>
                  )
                )
              default:
                return null
            }
          })}
        </Flex>
      </Flex>
    </div>
  )
}

export default CustomRequestFields
