import ReactTooltip from 'react-tooltip'
import { Typography } from '@jisr-hr/ds'
import styles from '../style.module.css'

type BadgeWrapperProps = {
  children: JSX.Element
  data_tip: string
  id: string | number
}

const BadgeWrapper = (props: BadgeWrapperProps): JSX.Element => {
  const { data_tip, id } = props

  return (
    <div
      data-tip={data_tip}
      data-for={`upcoming-reason-${id}`}
      style={{ width: 'fit-content' }}
    >
      {props.children}
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={`upcoming-reason-${id}`}
        className={styles.tool_tip_pay_type}
      >
        <Typography
          style={{ color: 'var(--color-base-colors-white-700)' }}
          variant="caption"
          text={data_tip}
        />
      </ReactTooltip>
    </div>
  )
}

export default BadgeWrapper
