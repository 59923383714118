import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type OffCycleReportCellType = {
  employee_id: number
  key: 'final_settlement' | 'vacation_settlement'
  payrun_id: number
  request_id: number
}

type InitialStateType = {
  fetching: boolean
  off_cycle_report: {
    header: string[]
    data: (OffCycleReportCellType | string)[][]
    title: string
  } | null
}

export const initialState: InitialStateType = {
  fetching: false,
  off_cycle_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.OFF_CYCLE_PAYRUN_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.OFF_CYCLE_PAYRUN_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        off_cycle_report: action.payload.data.data.off_cycle_payrun_report,
        fetching: false,
      }
    }

    case actions.OFF_CYCLE_PAYRUN_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
