export const HR_COUNTRIES_LOAD = 'HR_COUNTRIES_LOAD'
export const HR_COUNTRIES_LOAD_PENDING = 'HR_COUNTRIES_LOAD_PENDING'
export const HR_COUNTRIES_LOAD_FULFILLED = 'HR_COUNTRIES_LOAD_FULFILLED'
export const HR_COUNTRIES_LOAD_REJECTED = 'HR_COUNTRIES_LOAD_REJECTED'

export const REGIONS_LOAD = 'REGIONS_LOAD'
export const REGIONS_LOAD_PENDING = 'REGIONS_LOAD_PENDING'
export const REGIONS_LOAD_FULFILLED = 'REGIONS_LOAD_FULFILLED'
export const REGIONS_LOAD_REJECTED = 'REGIONS_LOAD_REJECTED'

export const CITIES_LOAD = 'CITIES_LOAD'
export const CITIES_LOAD_PENDING = 'CITIES_LOAD_PENDING'
export const CITIES_LOAD_FULFILLED = 'CITIES_LOAD_FULFILLED'
export const CITIES_LOAD_REJECTED = 'CITIES_LOAD_REJECTED'
