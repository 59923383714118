import { TextField, TextFieldProps } from '@jisr-hr/ds-beta'
import { debounce } from 'lodash'
import { twMerge } from 'tailwind-merge'

type SearchBarProps = TextFieldProps & {
  className?: string
}

const SearchBar = ({ value, className, onChange, size = 'compact', ...rest }: SearchBarProps) => {
  const handleChange = debounce((textVal: string): void => {
    if (onChange) {
      onChange(textVal)
    }
  }, 1000)

  return (
    <div className={twMerge('w-[353px]', className)}>
      <TextField
        {...rest}
        size={size}
        leadingIcon="search-sm"
        defaultValue={value}
        onChange={handleChange}
      />
    </div>
  )
}

export default SearchBar
