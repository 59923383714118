import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TInitialSalaryPackages } from './type'

const initialState: TInitialSalaryPackages = {
  fetching: false,
  loading: false,
  header: [],
  records: [],
  pagination: {},
  statistics: {},
}

export default function reducer(state = initialState, action: Action): TInitialSalaryPackages {
  const { payload, type } = action
  switch (type) {
    case actions.SALARIES_PACKAGES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.SALARIES_PACKAGES_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.SALARIES_PACKAGES_LOAD_FULFILLED: {
      const { records, pagination, statistics } = payload.data.data
      return {
        ...state,
        header: payload.data.data.header,
        records: [
          ...new Set([...(pagination?.previous_page ? Array.from(state.records) : []), ...records]),
        ],
        pagination,
        statistics,
        fetching: false,
      }
    }

    case actions.SALARIES_PACKAGES_UPDATE_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.SALARIES_PACKAGES_UPDATE_REJECTED:
    case actions.SALARIES_PACKAGES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }
    }

    default:
      return state
  }
}
