import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from '@jisr-hr/ds-beta'
import I18n from 'translations/i18n'
import { requestsCardOptions, requestsReset, resumptionPost } from 'redux/requests/actionCreators'
import { employeeLeaveTypesLoad } from 'redux/actions/employeeLeaveTypesAction'

import LeaveExtensionsRequest from 'components/authorised/requestForms/LeaveExtensionsRequest'
import ResumptionForm from 'components/authorised/Home/ResumptionForm'

const ResumptionActions = ({
  comment,
  filters,
  request,
  fetching,
  isOpen,
  showExtendingModal,
  setIsOpen,
  ...props
}) => {
  const handleResumptionSubmit = (data) => {
    const employee_id = request.employee && request.employee.id
    const requestId = request.id

    props
      .resumptionPost(
        employee_id,
        {
          leave_request_id: requestId,
          is_early_return: isOpen === 'early_return',
          ...data,
        },
        filters,
      )
      .then(() => {
        props.handleCardOptions({ show_modal: false })
        setIsOpen(null)
      })
  }

  return (
    <>
      <LeaveExtensionsRequest
        isOpen={showExtendingModal}
        onCloseModal={() => props.setShowExtendingModal(false)}
        employeeId={request?.employee?.id}
        requestId={request?.id}
        onDone={() => {
          props.handleCardOptions({ show_modal: false })
        }}
      />
      <Modal
        open={!!isOpen}
        onClose={() => setIsOpen(null)}
        size="medium"
        disableCloseOut={false}
        footer={{
          submit: {
            label: I18n.t('submit'),
            disabled: fetching,
            type: 'submit',
            formId: 'resumption_form',
          },
        }}
      >
        <div className="pt-6">
          <ResumptionForm onSubmit={(values) => handleResumptionSubmit(values)} />
        </div>
      </Modal>
    </>
  )
}

ResumptionActions.propTypes = {
  employeeLeaveTypesLoad: PropTypes.func,
  handleCardOptions: PropTypes.func,
  resumptionPost: PropTypes.func,
  comment: PropTypes.shape(),
  filters: PropTypes.shape(),
  request: PropTypes.shape(),
  fetching: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { requests } = state
  return {
    filters: requests.filters,
    comment: requests.card_options.comment,
    fetching: requests.fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resumptionPost: (employee_id, data) =>
    dispatch(resumptionPost(employee_id, data)).then(() => {
      dispatch(requestsReset())
    }),
  employeeLeaveTypesLoad: (id) => dispatch(employeeLeaveTypesLoad(id)),
  handleCardOptions: (options, request) => dispatch(requestsCardOptions(options, request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResumptionActions)
