import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'
import { TRating } from './type'

export const showTrainingVideos = (step_name: string): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/training_videos/by_step_name`

  return {
    type: actions.ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW,
    payload: handleAPI(url, { step_name }, 'GET'),
  }
}

export function ratingTrainingVideos(id: number, answer: TRating): Action {
  const url = `/${API_VERSION_TWO}/onboarding/training_videos/${id}/rating`
  return {
    type: actions.ONBOARDING_TRAINING_VIDEOS_RATING_PUT,
    payload: handleAPI(url, {}, 'PUT', { answer }),
  }
}

export function trainingVideosReset(): Action {
  return {
    type: actions.TRAINING_VIDEOS_RESET,
  }
}
