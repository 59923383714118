import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { FixedShiftState } from './fixedShiftTypes'

const initialState: FixedShiftState = {
  fetching: false,
  loading: false,
  loadingState: false,
  templates: [],
  employees: [],
  weekly_shift_template: {},
  currentTimesheet: null,
  details: {},
}

export default function reducer(state = initialState, action: Action): FixedShiftState {
  switch (action.type) {
    case actions.RESET_FIXED_SHIFT:
      return {
        ...state,
        weekly_shift_template: {},
      }

    case actions.FIXED_SHIFTS_LISTING_LOAD_PENDING:
    case actions.GET_CURRENT_TIMESHEET_PENDING:
    case actions.FIXED_SHIFT_LOAD_PENDING:
    case actions.GET_EMPLOYEES_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.REQUESTED_EMPLOYEE_SHIFTS_PENDING:
      return {
        ...state,
        loadingState: true,
      }

    case actions.FIXED_SHIFT_CREATE_PENDING:
    case actions.FIXED_SHIFT_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.FIXED_SHIFT_CREATE_FULFILLED:
    case actions.FIXED_SHIFT_UPDATE_FULFILLED:
    case actions.FIXED_SHIFT_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.FIXED_SHIFTS_LISTING_LOAD_FULFILLED:
    case actions.FIXED_SHIFT_LOAD_FULFILLED:
      const fixedShiftData: Pick<FixedShiftState, 'templates' | 'weekly_shift_template'> =
        action.payload.data.data

      return {
        ...state,
        ...fixedShiftData,
        fetching: false,
      }

    case actions.REQUESTED_EMPLOYEE_SHIFTS_FULFILLED:
      return {
        ...state,
        details: action.payload.data.data,
        loadingState: false,
      }

    case actions.GET_CURRENT_TIMESHEET_FULFILLED:
      return {
        ...state,
        currentTimesheet: action.payload.data.data,
        fetching: false,
      }

    case actions.GET_EMPLOYEES_FULFILLED:
      return {
        ...state,
        employees: action.payload.data.data,
        fetching: false,
      }

    case actions.FIXED_SHIFTS_LISTING_LOAD_REJECTED:
    case actions.FIXED_SHIFT_LOAD_REJECTED:
    case actions.FIXED_SHIFT_CREATE_REJECTED:
    case actions.FIXED_SHIFT_UPDATE_REJECTED:
    case actions.FIXED_SHIFT_DELETE_REJECTED:
    case actions.GET_EMPLOYEES_REJECTED:
    case actions.GET_CURRENT_TIMESHEET_REJECTED:
    case actions.REQUESTED_EMPLOYEE_SHIFTS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        loadingState: false,
      }

    default:
      return state
  }
}
