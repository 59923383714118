import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import { OnboardingOverviewContractRes } from './useGetOnboardingOverview.type'

// ? functions

const getOnboardingOverview = (): Promise<OnboardingOverviewContractRes> => {
  // /v2/hr/onboarding_employee_templates/default_template
  return api.get(`/${API_VERSION_TWO}/hr/onboarding_employee_templates/default_template`)
}

// ? hooks

/**
 * @endpoint:  GET /v2/hr/onboarding_employee_templates/default_template
 * @summary This hook used to get onboaring form overview
 */

export function useGetOnboardingOverview<
  TData extends { data: { data: OnboardingOverviewContractRes } },
  TError extends HTTPValidationError,
>(opts?: { query?: UseQueryOptions<OnboardingOverviewContractRes, TError, TData> }) {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? ['onboarding_overview'],
    queryFn: () => getOnboardingOverview(),
  })
}
