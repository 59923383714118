export const MICROSOFT_SUBSIDIARIES_LOAD = 'MICROSOFT_SUBSIDIARIES_LOAD'
export const MICROSOFT_SUBSIDIARIES_LOAD_FULFILLED = 'MICROSOFT_SUBSIDIARIES_LOAD_FULFILLED'
export const MICROSOFT_SUBSIDIARIES_LOAD_REJECTED = 'MICROSOFT_SUBSIDIARIES_LOAD_REJECTED'
export const MICROSOFT_SUBSIDIARIES_LOAD_PENDING = 'MICROSOFT_SUBSIDIARIES_LOAD_PENDING'

export const MICROSOFT_MAPPED_SUBSIDIARIES = 'MICROSOFT_MAPPED_SUBSIDIARIES'
export const MICROSOFT_MAPPED_SUBSIDIARIES_FULFILLED = 'MICROSOFT_MAPPED_SUBSIDIARIES_FULFILLED'
export const MICROSOFT_MAPPED_SUBSIDIARIES_REJECTED = 'MICROSOFT_MAPPED_SUBSIDIARIES_REJECTED'
export const MICROSOFT_MAPPED_SUBSIDIARIES_PENDING = 'MICROSOFT_MAPPED_SUBSIDIARIES_PENDING'

export const MICROSOFT_SUBSIDIARY_CREATE = 'MICROSOFT_SUBSIDIARY_CREATE'
export const MICROSOFT_SUBSIDIARY_CREATE_FULFILLED = 'MICROSOFT_SUBSIDIARY_CREATE_FULFILLED'
export const MICROSOFT_SUBSIDIARY_CREATE_REJECTED = 'MICROSOFT_SUBSIDIARY_CREATE_REJECTED'
export const MICROSOFT_SUBSIDIARY_CREATE_PENDING = 'MICROSOFT_SUBSIDIARY_CREATE_PENDING'

export const MICROSOFT_SUBSIDIARY_DELETE = 'MICROSOFT_SUBSIDIARY_DELETE'
export const MICROSOFT_SUBSIDIARY_DELETE_FULFILLED = 'MICROSOFT_SUBSIDIARY_DELETE_FULFILLED'
export const MICROSOFT_SUBSIDIARY_DELETE_REJECTED = 'MICROSOFT_SUBSIDIARY_DELETE_REJECTED'
export const MICROSOFT_SUBSIDIARY_DELETE_PENDING = 'MICROSOFT_SUBSIDIARY_DELETE_PENDING'

export const MICROSOFT_SUBSIDIARY_UPDATE = 'MICROSOFT_SUBSIDIARY_UPDATE'
export const MICROSOFT_SUBSIDIARY_UPDATE_FULFILLED = 'MICROSOFT_SUBSIDIARY_UPDATE_FULFILLED'
export const MICROSOFT_SUBSIDIARY_UPDATE_REJECTED = 'MICROSOFT_SUBSIDIARY_UPDATE_REJECTED'
export const MICROSOFT_SUBSIDIARY_UPDATE_PENDING = 'MICROSOFT_SUBSIDIARY_UPDATE_PENDING'

export const MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN = 'MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'

export const MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN = 'MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED'
export const MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING =
  'MICROSOFT_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING'
