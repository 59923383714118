import { useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { Typography, Spacer, Flex, Radio } from '@jisr-hr/ds'
import { ReactComponent as LargePlus } from 'assets/figma-icons/largePlus.svg'
import { useDispatch, useSelector } from 'utils/hooks'
import JisrLoader from 'components/global/JisrLoader'

import {
  commercialDelete,
  commercialLoad,
} from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { haveMudadInvoice } from 'redux/setting/payrollManagement/paymentMethods/invoiceGenerated/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import CRInfromationAddEdit from './CRInfromationAddEdit'
import StepTemplate from '../../../components/StepTemplate'

import styles from './style.module.css'
import { CommercialRegistration, StepsTypes } from '../../../types/onboardingTypes'
import ConfirmCR from './components/ConfirmCR'

const init: CommercialRegistration = {
  id: 0,
  invoice_already_generated: false,
  name_en: '',
  name: '',
  archived_at: '',
  name_ar: '',
  name_i18n: '',
  payment_mode: null,
  registration_number: '',
  registration_id: 0,
  mol_registration: {
    id: 0,
    gosi_number: '',
    mol_prefix: '',
    mol_number: '',
    mol_registration_number: '',
    name: '',
  },
  bank_accounts: [],
  gosi_integration: null,
  number_of_employees: 0,
  main_commercial_registration: null,
  bank_account_id: 0,
}

const CRInformationBoard = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
  children,
  disableNextBtn,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { commercial_registrations, fetching } = useSelector(
    ({ commercialRegistrationsOnborading }) => commercialRegistrationsOnborading,
  )

  const { invoice_already_generated: invoiceAlreadyGenerated, fetching: fetchingInvoice } =
    useSelector((s) => s.invoiceGenerated)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [changeUi, setChangeUi] = useState(true)
  const [radioBox, setradioBox] = useState(true)
  const [checkAddOrEdit, setCheckAddOrEdit] = useState(true)
  const [commercialRegistration, setCommercialRegistration] = useState<CommercialRegistration>(init)

  const handleDelete = (data: CommercialRegistration): void => {
    dispatch(haveMudadInvoice({ commercial_registration_id: data?.id }))
    setIsConfirmOpen(true)
    setCommercialRegistration(data)
  }

  const handleConfirmDelete = (id: number): void => {
    dispatch(commercialDelete(id)).then(() => {
      dispatch(commercialLoad())
      dispatch(organizationInfo())
      setIsConfirmOpen(false)
    })
  }

  const handleEdit = (values: CommercialRegistration): void => {
    setCommercialRegistration(values)
    setCheckAddOrEdit(false)
    setChangeUi(false)
  }

  const handleChange = (): void => {
    setChangeUi(true)
    dispatch(commercialLoad())
  }

  const handleCreateNewCR = (): void => {
    setCheckAddOrEdit(true)
    setChangeUi(false)
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'organization_details' })

  useEffect(() => {
    dispatch(commercialLoad())
  }, [])
  return (
    <>
      {fetching && <JisrLoader absolute />}
      {changeUi ? (
        <StepTemplate
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={nextStep}
          previousStep={previousStep}
          disableNextBtn={!commercial_registrations.length || disableNextBtn}
        >
          {!children && (
            <Flex flexCol>
              <Typography
                variant="interface/default/m"
                text={I18n.t('do_you_have_one_multi')}
              />
              <Spacer height="8px" />
              <Flex
                flexCol
                justifyCenter
              >
                {commercial_registrations?.length <= 1 && (
                  <Radio
                    label={I18n.t('one_cr')}
                    active={radioBox === false}
                    onChange={(): void => setradioBox(false)}
                    disabled={commercial_registrations?.length > 1}
                  />
                )}
                <Radio
                  label={I18n.t('multi_crs')}
                  active={radioBox === true}
                  onChange={(): void => setradioBox(true)}
                />
              </Flex>
            </Flex>
          )}
          <Spacer height="24px" />
          <Typography
            variant="interface/default/m"
            text={I18n.t('organization_details_onboarding')}
          />
          <Spacer height="16px" />
          <Flex
            flexWrap
            style={{ gap: '16px' }}
          >
            {commercial_registrations &&
              commercial_registrations.map((commercial_registration: CommercialRegistration) => (
                <Flex
                  className={styles.items}
                  key={commercial_registration.id}
                >
                  <Flex
                    justifyEnd
                    style={{ width: '100%' }}
                  >
                    {commercial_registration?.main_commercial_registration ? (
                      <Typography
                        variant="interface/default/sm"
                        text={I18n.t('sub_org')}
                      />
                    ) : (
                      <Typography
                        variant="interface/default/sm"
                        text={I18n.t('main_org')}
                      />
                    )}
                  </Flex>
                  <div style={{ width: '100%' }}>
                    <Typography
                      variant="body-new/regular"
                      text={commercial_registration.registration_number}
                    />
                    <Typography
                      variant="body-new/regular"
                      text={commercial_registration.name}
                    />
                    <Flex style={{ width: '100%', gap: 16 }}>
                      <Typography
                        variant="body-new/regular"
                        text={I18n.t('gosi_number')}
                      />
                      <Typography
                        variant="body-new/regular"
                        text={commercial_registration.mol_registration.gosi_number}
                      />
                    </Flex>
                    <Flex style={{ width: '100%', gap: 16 }}>
                      <Typography
                        variant="body-new/regular"
                        text={I18n.t('mol_no')}
                      />
                      <Typography
                        variant="body-new/regular"
                        text={commercial_registration?.mol_registration?.mol_registration_number}
                      />
                    </Flex>
                    {commercial_registration?.main_commercial_registration && (
                      <Flex style={{ width: '100%', gap: 16 }}>
                        <Typography
                          variant="body-new/regular"
                          text={I18n.t('unified_mol_no')}
                        />
                        <Typography
                          variant="body-new/regular"
                          text={
                            commercial_registration?.main_commercial_registration?.mol_registration
                              ?.mol_registration_number
                          }
                        />
                      </Flex>
                    )}
                  </div>

                  <Flex style={{ gap: 10 }}>
                    <Typography
                      onClick={(): void => {
                        handleEdit(commercial_registration)
                      }}
                      style={{ color: 'var(--color-base-colors-grey-700)', cursor: 'pointer' }}
                      variant="interface/medium/s"
                      text={I18n.t('edit')}
                    />

                    <Typography
                      onClick={(): void => {
                        handleDelete(commercial_registration)
                      }}
                      style={{ color: 'var(--color-base-colors-grey-700)', cursor: 'pointer' }}
                      variant="interface/medium/s"
                      text={I18n.t('delete')}
                    />
                  </Flex>
                </Flex>
              ))}
            {(commercial_registrations?.length === 0 || radioBox) && (
              <Flex
                style={{ cursor: 'pointer' }}
                className={styles.formCard}
              >
                <div
                  onClick={(): void => {
                    handleCreateNewCR()
                  }}
                  className={styles.item}
                >
                  <LargePlus />
                  <Spacer height="8px" />
                  <Typography
                    style={{ color: 'var(--color-base-colors-grey-800)', textAlign: 'center' }}
                    variant="interface/default/m"
                    text={I18n.t('add_new')}
                  />
                </div>
              </Flex>
            )}
          </Flex>
          <ConfirmCR
            fetching={fetchingInvoice}
            isOpen={isConfirmOpen}
            onClose={setIsConfirmOpen}
            item={commercialRegistration}
            handleConfirmDelete={handleConfirmDelete}
            invoiceAlreadyGenerated={invoiceAlreadyGenerated}
          />
          {children}
        </StepTemplate>
      ) : (
        <CRInfromationAddEdit
          onClickChangeUi={handleChange}
          commercialRegistration={commercialRegistration}
          checkAddOrEdit={checkAddOrEdit}
          checkMultiCRs={radioBox}
        />
      )}
    </>
  )
}

export default CRInformationBoard
