import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { getLogTimeAndDate, isLogNextDay } from 'containers/authorised/attendance/helper'
import { useFormState } from 'react-final-form'
import { parse } from 'date-fns'

import ProposedPunch from './components/ProposedPunch'
import Frame from '../../../ds/Frame'

import Styles from './ProposedCorrectionTime.module.css'

const ProposedCorrectionTime = ({ shift, employee_shifts }) => {
  const shiftIndex = employee_shifts?.findIndex(({ id }) => shift.id === id)
  const shiftType = shiftIndex <= 0 ? 'first' : 'second'
  const { values } = useFormState()

  const outLog = parse(
    shift?.out_time_log?.punch_time
      ? `${shift?.out_time_log?.date} ${shift?.out_time_log?.punch_time}`
      : shift?.out_time,
    'yyyy-MM-dd hh:mm a',
    new Date(),
  )

  return (
    <Frame
      title={I18n.t('proposed_correction_time')}
      description={I18n.t('select_the_recorded_punches')}
    >
      <Flex
        flexCol
        className={Styles.punchesContainer}
      >
        <ProposedPunch
          type="in_punch"
          punchType={`${shiftType}_in_punch`}
          label={I18n.t('recorded_clock_in_time')}
          detail={{
            ...shift,
            is_shift_got_ended: true,
            punchTime: getLogTimeAndDate(shift?.in_time).time,
            ...(shift?.delay > 0 && {
              status: I18n.t('late_in'),
            }),
            reportMethod: shift?.in_time_log?.reporting_method,
            reportMethodI18n: shift?.in_time_log?.reporting_method_i18n,
            is_existing: shift?.is_in_time_existing,
          }}
        />

        <ProposedPunch
          type="out_punch"
          punchType={`${shiftType}_out_punch`}
          label={I18n.t('recorded_clock_out_time')}
          detail={{
            ...shift,
            ...(shift?.shortage > 0 && {
              status: I18n.t('early_out'),
            }),
            punchTime: getLogTimeAndDate(shift?.out_time).time,
            reportMethod: shift?.out_time_log?.reporting_method,
            reportMethodI18n: shift?.out_time_log?.reporting_method_i18n,
            is_existing: shift?.is_out_time_existing,
            next_day: isLogNextDay(
              parse(outLog, 'yyyy-MM-dd hh:mm a', new Date()),
              new Date(values.correction_date),
            ),
          }}
        />
      </Flex>
    </Frame>
  )
}

ProposedCorrectionTime.propTypes = {
  shift: PropTypes.shape(),
  employee_shifts: PropTypes.arrayOf(Object),
}

export default ProposedCorrectionTime
