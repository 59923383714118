import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Spacer, Tab } from '@jisr-hr/ds'

import Styles from './Tabs.module.css'
import CorrectionAlert from '../CorrectionAlert'

const items = [
  {
    id: 'request_details',
    label: I18n.t('request_details'),
    value: 0,
  },
  {
    id: 'workflow_and_comments',
    label: I18n.t('workflow_and_comments'),
    value: 1,
  },
]

const Tabs = ({ onChange, active }) => {
  const handleOnChange = (i) => {
    const tabID = items[i].id
    onChange(tabID)
  }

  const activeIndes = items.findIndex((t) => t.id === active)
  return (
    <>
      <div className={Styles.requestTabs}>
        <Spacer height={16} />
        <Tab
          tabs={items}
          onTabClick={handleOnChange}
          activeTab={activeIndes}
        />
      </div>
      <CorrectionAlert />
    </>
  )
}

Tabs.propTypes = {
  onChange: PropTypes.func,
  active: PropTypes.string,
}

export default Tabs
