import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type mudadType =
  | 'issuing_invoice'
  | 'subscription_about_to_disable'
  | 'disabled_due_to_late_payment'
  | 'subscription_about_to_expire'
  | 'subscription_is_expired'

type dunningType = 'settle_prorated_invoice' | 'issuing_prorated_invoice'

type Notification = {
  id: number
  message: {
    sub_title: string
    title: string
  }
  notification_type: mudadType
}

type DunningNotification = {
  id: number
  message: {
    sub_title: string
    title: string
  }
  message_type: dunningType
}

type RequestsState = {
  fetching: boolean
  dunningFetching: boolean
  loading: boolean
  employeeIssued: Notification[]
  notifications: Notification[]
  dunningNotification: DunningNotification[]
}

const initialState: RequestsState = {
  loading: false,
  fetching: false,
  dunningFetching: false,
  notifications: [],
  employeeIssued: [],
  dunningNotification: [],
}
export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_PENDING:
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_PENDING:
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DUNNING_NOTIFICATIONS_LOAD_PENDING:
    case actions.DUNNING_NOTIFICATIONS_UPDATE_PENDING:
      return {
        ...state,
        dunningFetching: true,
      }
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_FULFILLED:
      return {
        ...state,
        loading: false,
        employeeIssued: payload.employeeIssued.employeeIssued,
      }
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_FULFILLED:
      return {
        ...state,
        notifications: payload.data.data ?? [],
        fetching: false,
      }
    case actions.DUNNING_NOTIFICATIONS_LOAD_FULFILLED:
      return {
        ...state,
        dunningNotification: payload.data.data?.prorated_invoice_notifications ?? [],
        dunningFetching: false,
      }

    case actions.DUNNING_NOTIFICATIONS_UPDATE_FULFILLED:
      Toastr.success(payload.data.massage)
      return {
        ...state,
        dunningFetching: false,
      }
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_FULFILLED:
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
      }
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_REJECTED:
    case actions.NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_REJECTED:
      return {
        ...state,
        loading: false,
      }
    case actions.DUNNING_NOTIFICATIONS_UPDATE_REJECTED:
    case actions.DUNNING_NOTIFICATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        dunningFetching: false,
      }
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_REJECTED:
    case actions.MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
