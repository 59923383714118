import { MultiGroupCheckList } from 'components/global'
import { ListDataTypes } from 'types/sharedComponents'

type FilterBoxGroupedListProps = {
  list: Array<ListDataTypes>
  selected: Array<number>
  groupKey: string
  labelKey: string
  valueKey: string
  filterKey: string
  onChange: (key: string, selected: number | string) => void
}

const FilterBoxGroupedList = (props: FilterBoxGroupedListProps): JSX.Element => {
  const { list, selected, groupKey, labelKey, valueKey, filterKey, onChange } = props

  const handleOnChange = (data: { checked: boolean; value: number | string }): void => {
    onChange(filterKey, data.value)
  }

  return (
    <MultiGroupCheckList
      list={list}
      onChange={handleOnChange}
      selected={selected}
      groupKey={groupKey}
      labelKey={labelKey}
      valueKey={valueKey}
    />
  )
}

export default FilterBoxGroupedList
