import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type TransactionTypesType = {
  filterKey: string
  filters: string[]
}

const TransactionTypes = (props: TransactionTypesType): JSX.Element => {
  const { transaction_types } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(transaction_types)

  const onHandleFilter = (_: string, val: string): void => {
    setFilter({
      ...filter,
      transaction_types: val,
    })
  }

  useEffect(() => {
    if (filter.transaction_types) {
      setSelectedIds(filter.transaction_types)
    }
  }, [filter.transaction_types])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds}
      valueKey="id"
      labelKey="name"
      componentType="badges"
      {...props}
      title={i18n.t('label.transaction_type')}
    />
  )
}

export default TransactionTypes
