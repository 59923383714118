import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  accounting_info: {},
  fetching: false,
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_GL_ACCOUNT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EMPLOYEE_GL_ACCOUNT_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.EMPLOYEE_GL_ACCOUNT_UPDATE_FULFILLED: {
      if (action.payload.data?.message) Toastr.success(action.payload.data?.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EMPLOYEE_GL_ACCOUNT_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EMPLOYEE_GL_ACCOUNT_LOAD_FULFILLED:
      return {
        ...state,
        accounting_info: action.payload.data.data.accounting_info,
        fetching: false,
      }

    case actions.EMPLOYEE_GL_ACCOUNT_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
