import { FC, ReactNode } from 'react'
import ConfirmModal from 'containers/authorised/setting/v2/components/ConfirmModal'
import { useGlobalStore } from 'store/global/useGlobalStore'

export type ModalOptionsType = {
  onSuccess?: () => void
  onCancel?: () => void
  title?: string
  message?: string | ReactNode
  fetching: boolean
  subMessage?: string | ReactNode
  hideCancel?: boolean
  manualClose?: boolean
  labelCancel?: string
  labelConfirm?: string
  variant?: 'neutral' | 'danger' | 'info' | 'success' | 'warning'
  icon?: string
  size?: string
  fullBtnWidth?: boolean
  withIcon?: boolean
  fancy?: boolean
}
const AppConfirmation: FC = () => {
  const modalOptions = useGlobalStore((s) => s.notification.modalOptions)
  const setModalOptions = useGlobalStore((s) => s.notification.setModalOptions)

  return (
    <ConfirmModal
      isOpen={Boolean(modalOptions)}
      variant={modalOptions?.variant || 'danger'}
      fetching={modalOptions?.fetching}
      showLoader={false}
      size={modalOptions?.size}
      message={modalOptions?.message}
      title={modalOptions?.title}
      onClose={(): void => setModalOptions(null)}
      onCancel={(): void => {
        if (modalOptions?.onCancel) modalOptions?.onCancel()
        if (!modalOptions?.manualClose) setModalOptions(null)
      }}
      subMessage={modalOptions?.subMessage}
      customContent={modalOptions?.customContent}
      labelCancel={modalOptions?.labelCancel}
      labelConfirm={modalOptions?.labelConfirm}
      hideConfirm={!modalOptions?.onSuccess}
      onConfirm={(): void => modalOptions?.onSuccess?.()}
      fullBtnWidth={modalOptions?.fullBtnWidth ?? true}
      hideCancel={modalOptions?.hideCancel}
      icon={modalOptions?.icon}
      fancy={modalOptions?.fancy}
      withIcon={modalOptions?.withIcon}
    />
  )
}

export { AppConfirmation }
