export const ZOHO_BOOKS_SUBSIDIARIES_LOAD = 'ZOHO_BOOKS_SUBSIDIARIES_LOAD'
export const ZOHO_BOOKS_SUBSIDIARIES_LOAD_FULFILLED = 'ZOHO_BOOKS_SUBSIDIARIES_LOAD_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARIES_LOAD_REJECTED = 'ZOHO_BOOKS_SUBSIDIARIES_LOAD_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARIES_LOAD_PENDING = 'ZOHO_BOOKS_SUBSIDIARIES_LOAD_PENDING'

export const ZOHO_BOOKS_MAPPED_SUBSIDIARIES = 'ZOHO_BOOKS_MAPPED_SUBSIDIARIES'
export const ZOHO_BOOKS_MAPPED_SUBSIDIARIES_FULFILLED = 'ZOHO_BOOKS_MAPPED_SUBSIDIARIES_FULFILLED'
export const ZOHO_BOOKS_MAPPED_SUBSIDIARIES_REJECTED = 'ZOHO_BOOKS_MAPPED_SUBSIDIARIES_REJECTED'
export const ZOHO_BOOKS_MAPPED_SUBSIDIARIES_PENDING = 'ZOHO_BOOKS_MAPPED_SUBSIDIARIES_PENDING'

export const ZOHO_BOOKS_SUBSIDIARY_CREATE = 'ZOHO_BOOKS_SUBSIDIARY_CREATE'
export const ZOHO_BOOKS_SUBSIDIARY_CREATE_FULFILLED = 'ZOHO_BOOKS_SUBSIDIARY_CREATE_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARY_CREATE_REJECTED = 'ZOHO_BOOKS_SUBSIDIARY_CREATE_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARY_CREATE_PENDING = 'ZOHO_BOOKS_SUBSIDIARY_CREATE_PENDING'

export const ZOHO_BOOKS_SUBSIDIARY_DELETE = 'ZOHO_BOOKS_SUBSIDIARY_DELETE'
export const ZOHO_BOOKS_SUBSIDIARY_DELETE_FULFILLED = 'ZOHO_BOOKS_SUBSIDIARY_DELETE_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARY_DELETE_REJECTED = 'ZOHO_BOOKS_SUBSIDIARY_DELETE_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARY_DELETE_PENDING = 'ZOHO_BOOKS_SUBSIDIARY_DELETE_PENDING'

export const ZOHO_BOOKS_SUBSIDIARY_UPDATE = 'ZOHO_BOOKS_SUBSIDIARY_UPDATE'
export const ZOHO_BOOKS_SUBSIDIARY_UPDATE_FULFILLED = 'ZOHO_BOOKS_SUBSIDIARY_UPDATE_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARY_UPDATE_REJECTED = 'ZOHO_BOOKS_SUBSIDIARY_UPDATE_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARY_UPDATE_PENDING = 'ZOHO_BOOKS_SUBSIDIARY_UPDATE_PENDING'

export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN = 'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'

export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN = 'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED'
export const ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING =
  'ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING'
