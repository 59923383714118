import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { TQuestion } from 'components/GetFields/type'
import { Action } from 'types/redux'
import * as actions from './actions'

type InitialStateType = {
  fetching: boolean
  offers_applicable?: boolean
  questions: TQuestion[] | []
  subscription_cancellation:
    | {
        id: number
        status: string
      }
    | Record<string, never>
  offers:
    | {
        id: number
        name_i18n: string
        description_i18n: string
      }[]
    | []
}

const initialState: InitialStateType = {
  fetching: false,
  offers: [],
  questions: [],
  subscription_cancellation: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.CANCELATION_ANSWERS_POST_PENDING:
    case actions.CANCELATION_CONFIRMATION_POST_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_POST_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_REVERT_POST_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_PENDING:
    case actions.SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_PENDING:
    case actions.ACCEPT_TERMS_POST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SUBSCRIPTION_CANCELLATIONS_POST_FULFILLED:
      return {
        ...state,
        fetching: false,
        subscription_cancellation: payload.data.data?.subscription_cancellation,
      }

    case actions.SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        questions: payload.data.data?.questions,
      }

    case actions.SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        offers: payload.data.data?.offers,
        offers_applicable: payload.data.data?.offers_applicable,
      }

    case actions.CANCELLATIONS_QUESTIONS_RESET:
      return {
        ...state,
        fetching: false,
        questions: [],
      }

    case actions.CANCELATION_ANSWERS_POST_FULFILLED:
    case actions.CANCELATION_CONFIRMATION_POST_FULFILLED:
    case actions.SUBSCRIPTION_CANCELLATIONS_REVERT_POST_FULFILLED:
    case actions.SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_FULFILLED:
    case actions.SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_FULFILLED:
    case actions.ACCEPT_TERMS_POST_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.CANCELATION_ANSWERS_POST_REJECTED:
    case actions.CANCELATION_CONFIRMATION_POST_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_POST_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_REVERT_POST_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_QUESTIONS_LOAD_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_LIST_OFFERS_LOAD_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_ACCEPT_OFFE_POST_REJECTED:
    case actions.SUBSCRIPTION_CANCELLATIONS_CONTACT_SUPPORT_POST_REJECTED:
    case actions.ACCEPT_TERMS_POST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
