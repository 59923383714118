import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'
import { OnboardingStatsT } from './dashboardTypes'

export const loadDashboardLocations = <T extends Record<string, unknown>>(params: T): Action => {
  const url = `/${API_VERSION_TWO}/locations`
  return {
    type: actions.LOAD_DASHBOARD_LOCATION,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const loadAttendanceDashboardStats = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_steps/attendance_stats`
  return {
    type: actions.LOAD_ATTENDANCE_DASHBOARD_STATS,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const loadAttendanceDashboardSteps = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_steps`
  return {
    type: actions.LOAD_ATTENDANCE_DASHBOARD_STEPS,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const createAttendanceDashboardSteps = (stepName: string): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_steps`
  return {
    type: actions.CREATE_ATTENDANCE_DASHBOARD_STEPS,
    payload: handleAPI(url, {}, 'POST', {
      name: stepName,
      module_name: 'Attendance',
    }),
  }
}

export const completeAttendanceDashboardSteps = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_steps/mark_completed`
  return {
    type: actions.COMPLETE_ATTENDANCE_DASHBOARD_STEPS,
    payload: handleAPI(url, {}, 'PUT', {
      module_name: 'Attendance',
    }),
  }
}

export const attendanceDashboardReplaceBulkDevices = <T extends Record<string, unknown>>(
  data: T,
): Action => {
  const url = `/${API_VERSION_TWO}/attendance/punch_devices/bulk_delete`
  return {
    type: actions.ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export const updateAttendanceDashboardStats = (data: Partial<OnboardingStatsT>): Action => {
  return {
    type: actions.UPDATE_ATTENDANCE_DASHBOARD_STATS,
    payload: {
      data,
    },
  }
}
