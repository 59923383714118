export const GET_MUQEEM_ELIGIABLE_USERS_LOAD = 'GET_MUQEEM_ELIGIABLE_USERS_LOAD'
export const GET_MUQEEM_ELIGIABLE_USERS_LOAD_PENDING = 'GET_MUQEEM_ELIGIABLE_USERS_LOAD_PENDING'
export const GET_MUQEEM_ELIGIABLE_USERS_LOAD_FULFILLED = 'GET_MUQEEM_ELIGIABLE_USERS_LOAD_FULFILLED'
export const GET_MUQEEM_ELIGIABLE_USERS_LOAD_REJECTED = 'GET_MUQEEM_ELIGIABLE_USERS_LOAD_REJECTED'

export const GET_AUTHORIZED_USERS = 'GET_AUTHORIZED_USERS'
export const GET_AUTHORIZED_USERS_PENDING = 'GET_AUTHORIZED_USERS_PENDING'
export const GET_AUTHORIZED_USERS_FULFILLED = 'GET_AUTHORIZED_USERS_FULFILLED'
export const GET_AUTHORIZED_USERS_REJECTED = 'GET_AUTHORIZED_USERS_REJECTED'

export const REMOVE_AUTHORIZED_USER = 'REMOVE_AUTHORIZED_USER'
export const REMOVE_AUTHORIZED_USER_PENDING = 'REMOVE_AUTHORIZED_USER_PENDING'
export const REMOVE_AUTHORIZED_USER_FULFILLED = 'REMOVE_AUTHORIZED_USER_FULFILLED'
export const REMOVE_AUTHORIZED_USER_REJECTED = 'REMOVE_AUTHORIZED_USER_REJECTED'

export const ADD_AUTHORIZED_USER = 'ADD_AUTHORIZED_USER'
export const ADD_AUTHORIZED_USER_PENDING = 'ADD_AUTHORIZED_USER_PENDING'
export const ADD_AUTHORIZED_USER_FULFILLED = 'ADD_AUTHORIZED_USER_FULFILLED'
export const ADD_AUTHORIZED_USER_REJECTED = 'ADD_AUTHORIZED_USER_REJECTED'
