export const EMPLOYEE_EDIT_LOAD = 'EMPLOYEE_EDIT_LOAD'
export const EMPLOYEE_EDIT_LOAD_PENDING = 'EMPLOYEE_EDIT_LOAD_PENDING'
export const EMPLOYEE_EDIT_LOAD_FULFILLED = 'EMPLOYEE_EDIT_LOAD_FULFILLED'
export const EMPLOYEE_EDIT_LOAD_REJECTED = 'EMPLOYEE_EDIT_LOAD_REJECTED'

export const EMPLOYEE_GOSI_DEDUCTION = 'EMPLOYEE_GOSI_DEDUCTION'
export const EMPLOYEE_GOSI_DEDUCTION_PENDING = 'EMPLOYEE_GOSI_DEDUCTION_PENDING'
export const EMPLOYEE_GOSI_DEDUCTION_FULFILLED = 'EMPLOYEE_GOSI_DEDUCTION_FULFILLED'
export const EMPLOYEE_GOSI_DEDUCTION_REJECTED = 'EMPLOYEE_GOSI_DEDUCTION_REJECTED'

export const EMPLOYEE_EARNING_POST = 'EMPLOYEE_EARNING_POST'
export const EMPLOYEE_EARNING_POST_PENDING = 'EMPLOYEE_EARNING_POST_PENDING'
export const EMPLOYEE_EARNING_POST_FULFILLED = 'EMPLOYEE_EARNING_POST_FULFILLED'
export const EMPLOYEE_EARNING_POST_REJECTED = 'EMPLOYEE_EARNING_POST_REJECTED'

export const GOSI_ERROR_DISMISS_CALL = 'GOSI_ERROR_DISMISS_CALL'
