import { useEffect, useState } from 'react'
import {
  businessUnitDelete,
  businessUnitLoad,
} from 'redux/organizationOnborading/businessUnits/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import StepTemplate from '../../../components/StepTemplate'
import TodoList from './components/TodoList'
import BusinessUnitsForm from './components/BusinessUnitsForm'
import { StepsTypes } from '../../../types/onboardingTypes'

type dataType = { id: number } | null

const BusinessUnits = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { business_units, fetching } = useSelector(({ businessUnits }) => businessUnits)
  const [data, setData] = useState<dataType>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = (values: { id: number }): void => {
    dispatch(businessUnitDelete(values.id)).then(() => {
      dispatch(businessUnitLoad())
      dispatch(organizationInfo())
    })
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'business_units' })

  useEffect(() => {
    dispatch(businessUnitLoad())
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      fetching={fetching}
    >
      <TodoList
        headerTitle={I18n.t(data?.id ? 'edit_business_unit' : 'add_business_unit')}
        title={I18n.t('add_business_unit')}
        list={business_units}
        onEdit={(v): void => {
          setData({ ...v, id: v.id })
        }}
        onDelete={handleDelete}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        form={
          <BusinessUnitsForm
            onClose={(): void => {
              setIsOpen(false)
            }}
            value={data}
          />
        }
        fetching={fetching}
      />
    </StepTemplate>
  )
}

export default BusinessUnits
