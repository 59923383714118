import { useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import {
  BetaTextInputField as TextInputField,
  DatePickerDSField,
  NewSelectField,
} from 'components/final-form'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  composeValidators,
  maxLength,
  minLength,
  naturalNumber,
} from 'components/global/form/FormValidations'
import { listCountries } from 'redux/setting/v2/countries/actionCreators'
import { GENDER, MARITAL_STATUS, RELIGION } from 'components/global/constants'

import Frame from '../../../ds/Frame'

import styles from './InfoChangeRequestForm.module.css'
import { useRequestDetail } from '../../../hooks'

const InfoChangeRequestForm = (): JSX.Element => {
  const { change } = useForm()
  const { values, initialValues } = useFormState()

  const dispatch = useDispatch()

  const countries = useSelector((s) => s.countriesv2.countries)
  const { nationalities } = useSelector(({ staticRecord }) => staticRecord)
  const { relationships } = useSelector(({ constants }) => ({
    relationships: constants.relationship,
  }))

  const { request } = useRequestDetail()

  const isFamilyDetailsType = request?.metadata?.info_change_request_type === 'family_detail'
  const isEmergencyContactType = request?.metadata?.info_change_request_type === 'emergency_contact'

  const showEmergencyContact =
    initialValues?.address ||
    initialValues?.country_phone_code ||
    initialValues?.name ||
    initialValues?.relationship ||
    initialValues?.telephone
  const showAddressInHomeCountry =
    initialValues?.home_address1 || initialValues?.home_city || initialValues?.home_country
  const showPhoneNumber = initialValues?.telephone || initialValues?.country_phone_code

  const showBasicInfoSection =
    showPhoneNumber ||
    initialValues?.date_of_birth ||
    initialValues?.marital_status ||
    initialValues?.religion ||
    initialValues?.personal_email ||
    initialValues?.nationality_id

  const showBasicsInformationSection =
    showBasicInfoSection && !isFamilyDetailsType && !isEmergencyContactType

  const showSaudiAddressSection =
    initialValues?.building_number ||
    initialValues?.street_name ||
    initialValues?.district ||
    initialValues?.saudi_city ||
    initialValues?.saudi_postal_code ||
    initialValues?.national_short_address ||
    initialValues?.proof_attachment

  const showFamilyDetailsSection =
    initialValues?.date_of_birth ||
    initialValues?.marital_status ||
    initialValues?.gender ||
    initialValues?.medical_insurance_number ||
    initialValues?.medical_insurance_expiry_date ||
    initialValues?.id_number ||
    initialValues?.id_expiry_date ||
    initialValues?.relationship ||
    initialValues?.name ||
    initialValues?.name_ar ||
    initialValues?.passport_expiry_date ||
    initialValues?.passport_number ||
    initialValues?.nationality_id

  const showEducationSection =
    initialValues?.degree ||
    initialValues?.graduation_date ||
    initialValues?.institute_name ||
    initialValues?.location ||
    initialValues?.major ||
    initialValues?.study_length

  useEffect(() => {
    if (showPhoneNumber) {
      dispatch(listCountries({ template: 'public' }))
    }
  }, [showPhoneNumber])

  return (
    <>
      {showBasicsInformationSection && (
        <Frame
          title={i18n.t('basic_information')}
          titleSuffix
        >
          <Flex
            flexWrap
            style={{ gap: 16 }}
          >
            {showPhoneNumber && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  testId="telephone"
                  name="telephone"
                  validate={composeValidators(naturalNumber, minLength(7), maxLength(10))}
                  label={i18n.t('phone')}
                  listItems={countries.map((country: { name_i18n: string; id: string }) => ({
                    text: country.name_i18n,
                    value: country.id,
                  }))}
                  dropdownValue={values?.country_phone_code}
                  onDropdownChange={(value: string, dropdownValue): void => {
                    change('telephone', value)
                    change('country_phone_code', dropdownValue)
                  }}
                />
                <Field name="country_phone_code">{(): null => null}</Field>
              </div>
            )}
            {initialValues?.date_of_birth && (
              <div className={styles.itemWrapper}>
                <DatePickerDSField
                  name="date_of_birth"
                  label={i18n.t('date_of_birth')}
                />
              </div>
            )}
            {initialValues?.personal_email && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="personal_email"
                  label={i18n.t('personal_email')}
                />
              </div>
            )}
            {initialValues?.religion && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="religion"
                  label={i18n.t('religion')}
                  valueKey="id"
                  labelKey="name"
                  options={RELIGION}
                />
              </div>
            )}
            {initialValues?.gender && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="gender"
                  label={i18n.t('gender')}
                  valueKey="id"
                  labelKey="name"
                  options={GENDER}
                />
              </div>
            )}
            {initialValues?.nationality_id && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="nationality_id"
                  label={i18n.t('nationality')}
                  valueKey="id"
                  labelKey="name_i18n"
                  options={nationalities}
                />
              </div>
            )}
            {initialValues?.marital_status && (
              <div className={styles.itemWrapper}>
                <NewSelectField
                  name="marital_status"
                  label={i18n.t('marital_status')}
                  valueKey="id"
                  labelKey="name"
                  options={MARITAL_STATUS}
                />
              </div>
            )}
          </Flex>
        </Frame>
      )}
      {showSaudiAddressSection && (
        <Frame
          title={i18n.t('address_in_saudi')}
          titleSuffix
        >
          <Flex
            flexWrap
            style={{ gap: 16 }}
          >
            {initialValues?.building_number && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="building_number"
                  label={i18n.t('building_number')}
                />
              </div>
            )}
            {initialValues?.street_name && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="street_name"
                  label={i18n.t('street_name')}
                />
              </div>
            )}
            {initialValues?.district && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="district"
                  label={i18n.t('district')}
                />
              </div>
            )}
            {initialValues?.saudi_city && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="saudi_city"
                  label={i18n.t('city')}
                />
              </div>
            )}
            {initialValues?.saudi_postal_code && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="saudi_postal_code"
                  label={i18n.t('postal_code')}
                />
              </div>
            )}
            {initialValues?.national_short_address && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="national_short_address"
                  label={i18n.t('label.short_address')}
                />
              </div>
            )}
            {initialValues?.proof_attachment && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="proof_attachment"
                  label={i18n.t('label.address_proof')}
                />
              </div>
            )}
          </Flex>
        </Frame>
      )}
      {/* Family Details Section */}
      {showFamilyDetailsSection && isFamilyDetailsType && (
        <Frame
          title={i18n.t('label.family_members')}
          titleSuffix
        >
          <div className="flex flex-wrap gap-4">
            {initialValues?.name && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="name"
                  label={i18n.t('name_in_english')}
                />
              </div>
            )}
            {initialValues?.name && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="name_ar"
                  label={i18n.t('name_in_arabic')}
                />
              </div>
            )}
            {initialValues?.gender && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="gender"
                  label={i18n.t('gender')}
                  valueKey="id"
                  labelKey="name"
                  options={GENDER}
                />
              </div>
            )}
            {initialValues?.relationship && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="relationship"
                  label={i18n.t('relationship')}
                  valueKey="id"
                  labelKey="name_i18n"
                  options={relationships}
                />
              </div>
            )}
            {initialValues?.nationality_id && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="nationality_id"
                  label={i18n.t('nationality')}
                  valueKey="id"
                  labelKey="name_i18n"
                  options={nationalities}
                />
              </div>
            )}
            {initialValues?.marital_status && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <NewSelectField
                  name="marital_status"
                  label={i18n.t('marital_status')}
                  valueKey="id"
                  labelKey="name"
                  options={MARITAL_STATUS}
                />
              </div>
            )}
            {initialValues?.date_of_birth && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <DatePickerDSField
                  name="date_of_birth"
                  label={i18n.t('date_of_birth')}
                />
              </div>
            )}
            {initialValues?.id_number && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="id_number"
                  label={i18n.t('id_number')}
                />
              </div>
            )}
            {initialValues?.passport_number && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="passport_number"
                  label={i18n.t('passport_number')}
                />
              </div>
            )}
            {initialValues?.passport_expiry_date && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <DatePickerDSField
                  name="passport_expiry_date"
                  label={i18n.t('passport_expiry_date')}
                />
              </div>
            )}
            {initialValues?.medical_insurance_expiry_date && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <DatePickerDSField
                  name="medical_insurance_expiry_date"
                  label={i18n.t('medical_insurance_expiry_date')}
                />
              </div>
            )}
            {initialValues?.medical_insurance_number && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="medical_insurance_number"
                  label={i18n.t('medical_insurance_number')}
                  type="number"
                />
              </div>
            )}
          </div>
        </Frame>
      )}
      {/* Education Section */}
      {showEducationSection && (
        <Frame
          title={i18n.t('label.education')}
          titleSuffix
        >
          <div className="flex flex-wrap gap-4">
            {initialValues?.major && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="major"
                  label={i18n.t('major')}
                />
              </div>
            )}
            {initialValues?.degree && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="degree"
                  label={i18n.t('degree')}
                />
              </div>
            )}
            {initialValues?.graduation_date && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <DatePickerDSField
                  name="graduation_date"
                  label={i18n.t('graduation_date')}
                />
              </div>
            )}
            {initialValues?.study_length && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="study_length"
                  label={i18n.t('study_length')}
                />
              </div>
            )}
            {initialValues?.institute_name && (
              <div className="flex-grow-1 flex-basis-200 w-full">
                <TextInputField
                  name="institute_name"
                  label={i18n.t('institute_name')}
                />
              </div>
            )}
            {initialValues?.location && (
              <div className="flex-grow-1 flex-basis-[200px] w-full">
                <TextInputField
                  name="location"
                  label={i18n.t('location')}
                />
              </div>
            )}
          </div>
        </Frame>
      )}
      {/* Address In Home Country */}
      {showAddressInHomeCountry && (
        <Frame
          title={i18n.t('label.address_in_home_country')}
          titleSuffix
        >
          <Flex
            flexWrap
            style={{ gap: 16 }}
          >
            {initialValues?.home_address1 && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="home_address1"
                  label={i18n.t('address')}
                />
              </div>
            )}
            {initialValues?.home_city && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="home_city"
                  label={i18n.t('town_city')}
                />
              </div>
            )}
            {initialValues?.home_country && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="home_country"
                  label={i18n.t('country')}
                />
              </div>
            )}
          </Flex>
        </Frame>
      )}

      {/* Emergency Contact */}
      {showEmergencyContact && isEmergencyContactType && (
        <Frame
          title={i18n.t('label.emergency_contact')}
          titleSuffix
        >
          <Flex
            flexWrap
            style={{ gap: 16 }}
          >
            {initialValues?.address && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="address"
                  label={i18n.t('address')}
                />
              </div>
            )}
            {initialValues?.country_phone_code && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="country_phone_code"
                  // label={i18n.t('relationship')}
                />
              </div>
            )}
            {initialValues?.name && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="name"
                  label={i18n.t('name_in_english')}
                />
              </div>
            )}
            {initialValues?.relationship && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="relationship"
                  label={i18n.t('relationship')}
                />
              </div>
            )}
            {initialValues?.telephone && (
              <div className={styles.itemWrapper}>
                <TextInputField
                  name="telephone"
                  label={i18n.t('telephone')}
                />
              </div>
            )}
          </Flex>
        </Frame>
      )}
    </>
  )
}

export default InfoChangeRequestForm
