import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { AssetFiltersTypes, TAsset } from 'containers/authorised/Assets/types'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

export type TInitState = {
  asset: TAsset | Record<string, never>
  assets: TAsset[]
  fetching: boolean
  filters: AssetFiltersTypes
  pagination: PaginationTypes | Record<string, never>
  counts: {
    Archived: number
    Available: number
    Allocated: number
    'In-Repair': number
    Damaged: number
    All: number
  }
  duplicate_serial_numbers: {
    serial: string | null
    list: {
      id: number
      created_at: string
      asset_type: string
      assigned: string
    }[]
  }[]
}

export const assetFiltersInitState = {
  search: '',
  filter: {
    asset_status: [],
    asset_types: [],
    location_id: [],
  },
  page: 1,
  rpp: 10,
}

const initialState: TInitState = {
  fetching: false,
  assets: [],
  asset: {},
  filters: assetFiltersInitState,
  pagination: {},
  counts: {
    Archived: 0,
    Available: 0,
    Allocated: 0,
    'In-Repair': 0,
    Damaged: 0,
    All: 0,
  },
  duplicate_serial_numbers: [],
}

export default function reducer(
  state = initialState,
  action: Action & { filters: AssetFiltersTypes },
): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.GET_ASSETS_PENDING:
    case actions.GET_ASSET_PENDING:
    case actions.CREATE_ASSET_PENDING:
    case actions.UPDATE_ASSET_PENDING:
    case actions.DELETE_ASSET_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_ASSETS_FULFILLED:
      return {
        ...state,
        assets: [
          ...new Set([
            ...(payload.data.data.pagination?.previous_page ? state.assets : []),
            ...payload.data.data.assets,
          ]),
        ],
        pagination: payload.data.data.pagination,
        counts: payload.data.data.counts,
        duplicate_serial_numbers: payload.data.data.duplicate_serial_numbers,
        fetching: false,
      }

    case actions.GET_ASSET_FULFILLED:
    case actions.CREATE_ASSET_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.UPDATE_ASSET_FULFILLED:
    case actions.DELETE_ASSET_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.GET_ASSETS_REJECTED:
    case actions.GET_ASSET_REJECTED:
    case actions.CREATE_ASSET_REJECTED:
    case actions.UPDATE_ASSET_REJECTED:
    case actions.DELETE_ASSET_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.SET_ASSETS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }

    default:
      return state
  }
}
