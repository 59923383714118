import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export type TOnboardingRecords = {
  title: string
  type: string
  id: number
  employee_id: null
  name: string
  s3_url: string
  error_file_url: null
  category: string
  category_value: string
  status: string
  completed_time: null
  uploaded_time: string
  updated_at: string
  metadata: null
  status_value: number
  details: string
}

type TErrors = {
  failed: boolean
  errors: { error: string }[]
}

export const initialState = {
  fetching: false,
  onboarding_records: [] as TOnboardingRecords[],
  errors: [] as TErrors[],
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_PENDING:
    case actions.UPLOAD_ONBOARDING_GOSI_FILE_PENDING:
    case actions.EXPORT_ONBOARDING_ORGANIZATION_DATA_PENDING:
    case actions.EMPLOYEES_BULK_DELETE_PENDING:
    case actions.RESET_ALL_EMPLOYEES_EXPECT_ADMINS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.UPLOAD_ONBOARDING_GOSI_FILE_FULFILLED:
      if (
        payload.data.data &&
        payload.data.data.failed &&
        payload.data.data.errors &&
        payload.data.data.errors.length
      ) {
        Toastr.error(payload.data.data.errors[0].error)
      } else if (payload.data && payload.data.message) {
        Toastr.success(payload.data.message)
      }
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEES_BULK_DELETE_FULFILLED:
    case actions.RESET_ALL_EMPLOYEES_EXPECT_ADMINS_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.EXPORT_ONBOARDING_ORGANIZATION_DATA_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD_REJECTED:
    case actions.UPLOAD_ONBOARDING_GOSI_FILE_REJECTED:
    case actions.EXPORT_ONBOARDING_ORGANIZATION_DATA_REJECTED:
    case actions.EMPLOYEES_BULK_DELETE_REJECTED:
    case actions.RESET_ALL_EMPLOYEES_EXPECT_ADMINS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_RESET:
      return {
        ...state,
        onboarding_records: [],
      }
    default:
      return state
  }
}
