import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Phone = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill={color ? getColor(color) : '#A97BFF'}
      d="M8.50060325,6.93553331 L6.78253497,8.65360159 L3.34639841,5.21746503 L5.06446669,
      3.49939675 C5.26846447,3.29539896 5.26846447,2.96514168 5.06446669,2.76166563 L2.45579939,
      0.152998337 C2.25180161,-0.0509994457 1.92154433,-0.0509994457 1.71806828,
      0.152998337 L0,1.87106662 C0,7.64509081 4.35386572,12 10.1289334,12 L11.8470017,10.2819317 C12.0509994,
      10.0779339 12.0509994,9.74767666 11.8470017,9.54420061 L9.23833437,6.93553331 C9.03485832,
      6.73205726 8.7040793,6.73205726 8.50060325,6.93553331 Z"
      transform="matrix(-1 0 0 1 12 0)"
    />
  </svg>
)

Phone.propTypes = {
  color: PropTypes.string,
}

export default Phone
