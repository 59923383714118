import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { BankPayload, OrgBankAccountType } from 'types/PaymentMethods/banks'
import { Action } from 'types/redux'
import * as actions from './actions'

export function bankIbanLoad(id: string | number): Action {
  const url = `/${API_VERSION_TWO}/finance/iban/${id}`

  return {
    type: actions.BANK_IBAN_CHECKING_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function defaultBankAccountsLoad(params = { template: 'public' }): Action {
  const url = `/${API_VERSION_TWO}/finance/commercial_registrations`

  return {
    type: actions.DEFAULT_BANK_ACCOUNTS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const organizationBanksLoad = (): Action => {
  const url = `/${API_VERSION_TWO}/finance/bank_accounts`

  return {
    type: actions.ORGANIZATION_BANKS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const organizationBanksCreate = (data: BankPayload): Action => {
  const url = `/${API_VERSION_TWO}/finance/bank_accounts`

  return {
    type: actions.ORGANIZATION_BANKS_CREATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export const organizationBanksDel = (id: number): Action => {
  const url = `/${API_VERSION_TWO}/finance/bank_accounts/${id}`

  return {
    type: actions.ORGANIZATION_BANKS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export const organizationBanksRemove = (id: number): Action => {
  return {
    type: actions.ORGANIZATION_BANKS_REMOVE_FROM_LIST,
    payload: { id },
  }
}

export const organizationBanksAdd = (banks: OrgBankAccountType[]): Action => {
  return {
    type: actions.ORGANIZATION_BANKS_ADD_TO_LIST,
    payload: { banks },
  }
}
