import { useEffect, useState } from 'react'
import { Typography, Spacer, Flex, Checkbox } from '@jisr-hr/ds'
import cns from 'classnames'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import I18n from 'translations/i18n'
import {
  onboardingLeaveTypesLoad,
  onboardingLeaveTypesCreate,
  onboardingLeaveTypesUpdate,
  onboardingLeaveTypesLaborLawUpdate,
} from 'redux/leavesOnboarding/leaveTypes/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { LeaveType, LeavesFormDataTypes } from 'redux/leavesOnboarding/leaveTypes/type'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import FormLeavesTypes from './FormLeavesTypes'
import styles from './style.module.css'

const LeavesTypes = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [editData, setEditData] = useState<{ leave_types?: LeaveType }>({})
  const oddDefCustomLeaves = ['Casual', 'Emergency']

  const isEmpty = Object.keys(editData).length === 0

  const dispatch = useDispatch()
  const { leave_types, fetching } = useSelector(({ onboardingLeaveTypes }) => onboardingLeaveTypes)

  const defaultLeaves = leave_types.filter((item) => item.is_default)
  const isCheckAll = defaultLeaves.every((item) => item.is_active)

  const handleChange = (data: LeaveType): void => {
    dispatch(onboardingLeaveTypesUpdate({ ...data, is_active: !data.is_active })).then(() =>
      dispatch(onboardingLeaveTypesLoad()),
    )
  }

  const checkAll = (use_labor_law: boolean): void => {
    dispatch(onboardingLeaveTypesLaborLawUpdate(use_labor_law)).then(() =>
      dispatch(onboardingLeaveTypesLoad()),
    )
  }

  const onSubmit = (data: { leave_types: LeavesFormDataTypes }): void => {
    if (isEmpty) {
      dispatch(onboardingLeaveTypesCreate({ ...data.leave_types, is_active: true })).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    } else {
      dispatch(onboardingLeaveTypesUpdate(data.leave_types)).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    }
  }

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'labor_law_leaves' })

  useEffect(() => {
    dispatch(onboardingLeaveTypesLoad())
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        text={I18n.t('labor_law_leaves')}
        variant="heading"
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-400)' }}
        text={I18n.t('labor_law_leaves_description')}
      />

      <Spacer height="24px" />

      <Flex
        flexCol
        className={styles.contentLeaves}
      >
        <Typography
          variant="title-1"
          text={I18n.t('special_cases_of_leaves')}
        />

        <Spacer height="6px" />
        <Checkbox
          active={isCheckAll}
          label={I18n.t('use_the_labor_low_leaves')}
          onChange={(event): void => checkAll(event.target.checked)}
        />
        <Spacer height="16px" />
        <Flex
          flexCol
          className={cns(styles.cardsContainer, 'scroll-y')}
        >
          {defaultLeaves?.map((item, i) => (
            <SwitchCard
              key={i}
              name={item.name_i18n}
              isActive={item.is_active}
              days={item.days_per_year}
              handleOnEdit={(): void => {
                setEditData({ leave_types: item })
                setModalOpen(true)
              }}
              handleOnChange={(): void => handleChange(item)}
            />
          ))}
        </Flex>
      </Flex>

      <FormLeavesTypes
        isOpen={modalOpen}
        onSubmit={onSubmit}
        onClose={setModalOpen}
        initialValues={editData}
        fetching={fetching}
        isEmpty={isEmpty}
        oddDefCustomLeaves={oddDefCustomLeaves}
      />
    </StepTemplate>
  )
}

export default LeavesTypes
