import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'
import { mapTaskDetail } from './helper'

const initialState = {
  request: {},
  requests: [],
  approvedRequestRes: {},
  tasks: [],
  pagination: {},
  fetching: false,
  fetching_reassign: false,
  fetching_submit: false,
  status: {},
  team_requests: [],
  team_requests_pagination: {},
  fetching_team_request: false,
}
let index
let newRequests

export default function reducer(state = initialState, action) {
  const { pagination, requests, tasks, task, ...request } = action?.payload?.data?.data ?? {}

  switch (action.type) {
    case actions.APPROVAL_DETAIL_RESET:
      return {
        ...state,
        request: {},
      }

    case actions.APPROVALS_LOAD_PENDING:
    case actions.STATUS_STATISTICS_LOAD:
    case actions.APPROVAL_DETAIL_LOAD_PENDING:
    case actions.REQUESTS_TASKS_LOAD_PENDING:
    case actions.TASK_DETAIL_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.TEAM_REQUESTS_APPROVALS_LOAD_PENDING:
      return {
        ...state,
        fetching_team_request: true,
      }
    case actions.TASK_REASSIGN_PENDING:
      return {
        ...state,
        fetching_reassign: true,
      }
    case actions.APPROVE_REQUEST_PENDING:
    case actions.REJECT_REQUEST_PENDING:
    case actions.CANCEL_REQUEST_PENDING:
    case actions.UPDATE_TASK_PENDING:
    case actions.REQUESTS_CUSTOM_TASKS_CREATE_PENDING:
      return {
        ...state,
        fetching_submit: true,
        approvedRequestRes: {},
      }
    case actions.TASK_REASSIGN_FULFILLED:
      return {
        ...state,
        fetching_reassign: false,
      }

    case actions.APPROVALS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        pagination,
        requests: [...(pagination?.previous_page ? state.requests : []), ...(requests ?? [])],
      }
    }

    case actions.TEAM_REQUESTS_APPROVALS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching_team_request: false,
        team_requests_pagination: pagination,
        team_requests: [...(pagination?.previous_page ? state.requests : []), ...(requests ?? [])],
      }
    }

    case actions.STATUS_STATISTICS_LOAD_FULFILLED:
      return {
        ...state,
        status: action.payload.data.data,
        fetching: false,
      }

    case actions.APPROVAL_DETAIL_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        request: {
          ...request,
          title: request?.request_type_i18n || request?.request?.request_type_i18n,
        },
      }
    case actions.TASK_DETAIL_LOAD_FULFILLED:
      index = state.tasks.findIndex((t) => t.id === task.id)
      return {
        ...state,
        fetching: false,
        request: mapTaskDetail(task),
        ...(index >= 0 && {
          tasks: [...state.tasks.slice(0, index), task, ...state.tasks.slice(index + 1)],
        }),
      }
    case actions.UPDATE_TASK_FULFILLED:
    case actions.REQUESTS_CUSTOM_TASKS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching_submit: false,
      }
    case actions.APPROVE_REQUEST_FULFILLED:
    case actions.REJECT_REQUEST_FULFILLED:
    case actions.CANCEL_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      index = state.requests?.findIndex(
        (item) => item.id === action?.payload?.data?.data?.request?.id,
      )
      if (index < 0) {
        return {
          ...state,
          fetching_submit: false,
          approvedRequestRes: action?.payload?.data?.data?.request,
        }
      }
      newRequests = [...state.requests]
      newRequests.splice(index, 1)
      return {
        ...state,
        requests: newRequests,
        fetching_submit: false,
        approvedRequestRes: action?.payload?.data?.data?.request,
      }
    case actions.REQUESTS_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        pagination,
        tasks: [...(pagination?.previous_page ? state?.tasks : []), ...(tasks ?? [])],
      }

    case actions.APPROVALS_LOAD_REJECTED:
    case actions.REQUESTS_TASKS_LOAD_REJECTED:
    case actions.APPROVAL_DETAIL_LOAD_REJECTED:
    case actions.TASK_REASSIGN_REJECTED:
    case actions.UPDATE_TASK_REJECTED:
    case actions.APPROVE_REQUEST_REJECTED:
    case actions.REJECT_REQUEST_REJECTED:
    case actions.STATUS_STATISTICS_LOAD_REJECTED:
    case actions.CANCEL_REQUEST_REJECTED:
    case actions.TEAM_REQUESTS_APPROVALS_LOAD_REJECTED:
    case actions.REQUESTS_CUSTOM_TASKS_CREATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        fetching_reassign: false,
        fetching_submit: false,
        fetching_team_request: false,
        approvedRequestRes: {},
      }
    case actions.REMOVE_REQUEST:
      index = state.requests?.findIndex((item) => item.id === action?.payload?.id)
      if (index < 0) return { ...state }
      newRequests = [...state.requests]
      newRequests.splice(index, 1)
      return {
        ...state,
        requests: newRequests,
      }
    case actions.REMOVE_REQUEST_PAYRUN:
      index = state.requests?.findIndex(
        (item) => item?.review_request?.payrun_id === action?.payload?.id,
      )
      if (index < 0) return { ...state }
      newRequests = [...state.requests]
      newRequests.splice(index, 1)
      return {
        ...state,
        requests: newRequests,
      }
    case actions.REMOVE_REQUEST_LIST:
      return {
        ...state,
        fetching: false,
        requests: [],
      }

    case actions.CLEAR_APPROVE_REQ_RES:
      return {
        ...state,
        approvedRequestRes: {},
      }
    default:
      return state
  }
}
