export const EMPLOYEE_GL_ACCOUNT_LOAD = 'EMPLOYEE_GL_ACCOUNT_LOAD'
export const EMPLOYEE_GL_ACCOUNT_LOAD_PENDING = 'EMPLOYEE_GL_ACCOUNT_LOAD_PENDING'
export const EMPLOYEE_GL_ACCOUNT_LOAD_FULFILLED = 'EMPLOYEE_GL_ACCOUNT_LOAD_FULFILLED'
export const EMPLOYEE_GL_ACCOUNT_LOAD_REJECTED = 'EMPLOYEE_GL_ACCOUNT_LOAD_REJECTED'

export const EMPLOYEE_GL_ACCOUNT_UPDATE = 'EMPLOYEE_GL_ACCOUNT_UPDATE'
export const EMPLOYEE_GL_ACCOUNT_UPDATE_PENDING = 'EMPLOYEE_GL_ACCOUNT_UPDATE_PENDING'
export const EMPLOYEE_GL_ACCOUNT_UPDATE_FULFILLED = 'EMPLOYEE_GL_ACCOUNT_UPDATE_FULFILLED'
export const EMPLOYEE_GL_ACCOUNT_UPDATE_REJECTED = 'EMPLOYEE_GL_ACCOUNT_UPDATE_REJECTED'

export const EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD = 'EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD'
export const EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_PENDING = 'EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_PENDING'
export const EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_FULFILLED = 'EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_FULFILLED'
export const EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_REJECTED = 'EMPLOYEE_GL_ACCOUNT_ITEMS_LOAD_REJECTED'
