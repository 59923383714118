export const ONBOARDING_BUSINESS_UNITS_POST = 'ONBOARDING_BUSINESS_UNITS_POST'
export const ONBOARDING_BUSINESS_UNITS_POST_PENDING = 'ONBOARDING_BUSINESS_UNITS_POST_PENDING'
export const ONBOARDING_BUSINESS_UNITS_POST_FULFILLED = 'ONBOARDING_BUSINESS_UNITS_POST_FULFILLED'
export const ONBOARDING_BUSINESS_UNITS_POST_REJECTED = 'ONBOARDING_BUSINESS_UNITS_POST_REJECTED'

export const ONBOARDING_BUSINESS_UNITS_PUT = 'ONBOARDING_BUSINESS_UNITS_PUT'
export const ONBOARDING_BUSINESS_UNITS_PUT_PENDING = 'ONBOARDING_BUSINESS_UNITS_PUT_PENDING'
export const ONBOARDING_BUSINESS_UNITS_PUT_FULFILLED = 'ONBOARDING_BUSINESS_UNITS_PUT_FULFILLED'
export const ONBOARDING_BUSINESS_UNITS_PUT_REJECTED = 'ONBOARDING_BUSINESS_UNITS_PUT_REJECTED'

export const ONBOARDING_BUSINESS_UNITS_LOAD = 'ONBOARDING_BUSINESS_UNITS_LOAD'
export const ONBOARDING_BUSINESS_UNITS_LOAD_PENDING = 'ONBOARDING_BUSINESS_UNITS_LOAD_PENDING'
export const ONBOARDING_BUSINESS_UNITS_LOAD_FULFILLED = 'ONBOARDING_BUSINESS_UNITS_LOAD_FULFILLED'
export const ONBOARDING_BUSINESS_UNITS_LOAD_REJECTED = 'ONBOARDING_BUSINESS_UNITS_LOAD_REJECTED'

export const ONBOARDING_BUSINESS_UNITS_SHOW = 'ONBOARDING_BUSINESS_UNITS_SHOW'
export const ONBOARDING_BUSINESS_UNITS_SHOW_PENDING = 'ONBOARDING_BUSINESS_UNITS_SHOW_PENDING'
export const ONBOARDING_BUSINESS_UNITS_SHOW_FULFILLED = 'ONBOARDING_BUSINESS_UNITS_SHOW_FULFILLED'
export const ONBOARDING_BUSINESS_UNITS_SHOW_REJECTED = 'ONBOARDING_BUSINESS_UNITS_SHOW_REJECTED'

export const ONBOARDING_BUSINESS_UNITS_DELETE = 'ONBOARDING_BUSINESS_UNITS_DELETE'
export const ONBOARDING_BUSINESS_UNITS_DELETE_PENDING = 'ONBOARDING_BUSINESS_UNITS_DELETE_PENDING'
export const ONBOARDING_BUSINESS_UNITS_DELETE_FULFILLED =
  'ONBOARDING_BUSINESS_UNITS_DELETE_FULFILLED'
export const ONBOARDING_BUSINESS_UNITS_DELETE_REJECTED = 'ONBOARDING_BUSINESS_UNITS_DELETE_REJECTED'
