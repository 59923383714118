export const COMMERCIAL_REGISTRATIONS_LOAD = 'COMMERCIAL_REGISTRATIONS_LOAD'
export const COMMERCIAL_REGISTRATIONS_LOAD_PENDING = 'COMMERCIAL_REGISTRATIONS_LOAD_PENDING'
export const COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED = 'COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_LOAD_REJECTED = 'COMMERCIAL_REGISTRATIONS_LOAD_REJECTED'

export const COMMERCIAL_REGISTRATIONS_CREATE = 'COMMERCIAL_REGISTRATIONS_CREATE'
export const COMMERCIAL_REGISTRATIONS_CREATE_PENDING = 'COMMERCIAL_REGISTRATIONS_CREATE_PENDING'
export const COMMERCIAL_REGISTRATIONS_CREATE_FULFILLED = 'COMMERCIAL_REGISTRATIONS_CREATE_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_CREATE_REJECTED = 'COMMERCIAL_REGISTRATIONS_CREATE_REJECTED'

export const COMMERCIAL_REGISTRATIONS_UPDATE = 'COMMERCIAL_REGISTRATIONS_UPDATE'
export const COMMERCIAL_REGISTRATIONS_UPDATE_PENDING = 'COMMERCIAL_REGISTRATIONS_UPDATE_PENDING'
export const COMMERCIAL_REGISTRATIONS_UPDATE_FULFILLED = 'COMMERCIAL_REGISTRATIONS_UPDATE_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_UPDATE_REJECTED = 'COMMERCIAL_REGISTRATIONS_UPDATE_REJECTED'

export const COMMERCIAL_REGISTRATIONS_DELETE = 'COMMERCIAL_REGISTRATIONS_DELETE'
export const COMMERCIAL_REGISTRATIONS_DELETE_PENDING = 'COMMERCIAL_REGISTRATIONS_DELETE_PENDING'
export const COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED = 'COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_DELETE_REJECTED = 'COMMERCIAL_REGISTRATIONS_DELETE_REJECTED'

export const COMMERCIAL_REGISTRATIONS_ARCHIVE = 'COMMERCIAL_REGISTRATIONS_ARCHIVE'
export const COMMERCIAL_REGISTRATIONS_ARCHIVE_PENDING = 'COMMERCIAL_REGISTRATIONS_ARCHIVE_PENDING'
export const COMMERCIAL_REGISTRATIONS_ARCHIVE_FULFILLED =
  'COMMERCIAL_REGISTRATIONS_ARCHIVE_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_ARCHIVE_REJECTED = 'COMMERCIAL_REGISTRATIONS_ARCHIVE_REJECTED'

export const COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES = 'COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES'
export const COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_PENDING =
  'COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_PENDING'
export const COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_FULFILLED =
  'COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_FULFILLED'
export const COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_REJECTED =
  'COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_REJECTED'
