export const TIMESHHET_OVERVIEW_STATE = 'TIMESHHET_OVERVIEW_STATE'
export const TIMESHHET_OVERVIEW_STATE_PENDING = 'TIMESHHET_OVERVIEW_STATE_PENDING'
export const TIMESHHET_OVERVIEW_STATE_FULFILLED = 'TIMESHHET_OVERVIEW_STATE_FULFILLED'
export const TIMESHHET_OVERVIEW_STATE_REJECTED = 'TIMESHHET_OVERVIEW_STATE_REJECTED'

export const TIMESHHET_OVERVIEW_EMPLOYEES = 'TIMESHHET_OVERVIEW_EMPLOYEES'
export const TIMESHHET_OVERVIEW_EMPLOYEES_PENDING = 'TIMESHHET_OVERVIEW_EMPLOYEES_PENDING'
export const TIMESHHET_OVERVIEW_EMPLOYEES_FULFILLED = 'TIMESHHET_OVERVIEW_EMPLOYEES_FULFILLED'
export const TIMESHHET_OVERVIEW_EMPLOYEES_REJECTED = 'TIMESHHET_OVERVIEW_EMPLOYEES_REJECTED'

export const RESET_TIMESHHET_OVERVIEW_EMPLOYEES = 'RESET_TIMESHHET_OVERVIEW_EMPLOYEES'

export const RESET_TIMESHEETS_LISTING = 'RESET_TIMESHEETS_LISTING'

export const RESET_TIMESHHET_OVERVIEW_STATE = 'RESET_TIMESHHET_OVERVIEW_STATE'

export const ATTENDANCE_RECALCULATE = 'ATTENDANCE_RECALCULATE'
export const ATTENDANCE_RECALCULATE_PENDING = 'ATTENDANCE_RECALCULATE_PENDING'
export const ATTENDANCE_RECALCULATE_FULFILLED = 'ATTENDANCE_RECALCULATE_FULFILLED'
export const ATTENDANCE_RECALCULATE_REJECTED = 'ATTENDANCE_RECALCULATE_REJECTED'
