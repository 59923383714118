import { Radio, Flex } from '@jisr-hr/ds'
import { useFormState, Field } from 'react-final-form'
import i18n from 'translations/i18n'
import { required } from 'components/global/form/FormValidations'

import Frame from '../../../ds/Frame'
import HalfDayLeave from './HalfDayLeave'
import SelectRange from './SelectRange'

const AnnualLeaveRoles = [
  {
    value: 'halfDayLeave',
    title: i18n.t('half_day_leave'),
    description: i18n.t('from_annual_leave_balance'),
  },
  {
    value: 'selectRange',
    title: i18n.t('select_a_range'),
    description: i18n.t('for_a_Day_or_more_annual_leaves'),
  },
]

const annualLeaveComponents = {
  halfDayLeave: HalfDayLeave,
  selectRange: SelectRange,
}

const AnnualLeave = (): JSX.Element => {
  const {
    values: { AnnualLeaveRole },
  } = useFormState()
  const AnnualLeaveComponents = annualLeaveComponents[AnnualLeaveRole]

  return (
    <Frame
      titleSuffix
      title={i18n.t('annual_leave')}
    >
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        <Field
          name="AnnualLeaveRole"
          validate={required}
        >
          {({ input }): JSX.Element => (
            <Flex style={{ gap: '16px' }}>
              {AnnualLeaveRoles.map((role) => {
                return (
                  <Radio
                    key={role.value}
                    label={role.title}
                    description={role.description}
                    id={role.value}
                    value={role.value}
                    active={input.value === role.value}
                    onChange={(): void => {
                      input.onChange(role.value)
                    }}
                  />
                )
              })}
            </Flex>
          )}
        </Field>
        {AnnualLeaveRole && <AnnualLeaveComponents />}
      </Flex>
    </Frame>
  )
}

export default AnnualLeave
