import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import stoc from 'string-to-color'
import { Link } from 'react-router-dom'
import { Popper } from '@jisr-hr/design-system'
import { Typography, Button, Flex, Spacer, Avatars } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { LazyAvatar } from 'components/global/atoms'
import useOnClickOutside from 'components/authorised/setting/requestWorkflow/WorkflowSteps/hooks/useOnClickOutside'
import { ReactComponent as Clear } from 'assets/images/icons/clear-24px.svg'

import { checkPermission } from './helper'
import ExemptedEmployee from '../ExemptedEmployee'

const EmployeeTdInfo = ({
  id,
  code,
  name,
  jobTitle,
  avatar,
  avatarsize,
  style,
  scrollPosition,
  showJobAndCode,
  typography,
  color,
  nameColor,
  isExempted,
  onViewProfile,
  onClosePopup,
  onClickURL,
  showAvatarBackground,
}) => {
  let additionalInfo = ''
  if (jobTitle) additionalInfo = jobTitle
  if (jobTitle && code) additionalInfo += ' - '
  if (code) additionalInfo += code
  const dialogRef = useRef(null)
  const [open, setOpen] = React.useState(false)

  useOnClickOutside(dialogRef, () => setOpen(false))
  const currentEmployee = useSelector(({ auth }) => auth.employee)
  const canViewProfile = checkPermission(currentEmployee, id)

  if (id) {
    return (
      <Popper
        open={open}
        maxHeight={208}
        style={{ borderRadius: 8 }}
        className="employee-popper"
        trigger={
          <div
            onClick={(e) => {
              e.stopPropagation()
              setOpen(!open)
              onClickURL?.()
            }}
            className="employee--td__box"
            style={{ ...style }}
          >
            <Flex
              itemsCenter
              style={{ cursor: 'pointer' }}
            >
              <LazyAvatar
                style={{
                  width: avatarsize,
                  height: avatarsize,
                  backgroundColor: showAvatarBackground ? stoc(name) : undefined,
                }}
                src={avatar}
                alt={name}
                scrollPosition={scrollPosition}
              />
              <div className="emp-td_content">
                <Typography
                  text={name}
                  variant="body-new/regular"
                />
                {showJobAndCode && (
                  <Typography
                    text={additionalInfo}
                    variant="subtitle-2"
                    textColor="color/fg/light"
                  />
                )}
              </div>
            </Flex>
          </div>
        }
      >
        <div
          ref={dialogRef}
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{ minWidth: 254, minHeight: 126 }}
        >
          <Flex
            justifyBetween
            style={{ width: '100%' }}
          >
            <Avatars
              imageSrc={avatar}
              imageAlt={name}
              size="xl"
            />
            <Clear
              className="icons-clear"
              onClick={() => {
                setOpen(false)
                onClosePopup()
              }}
            />
          </Flex>
          <Spacer height="8px" />
          <Typography
            text={name}
            variant="subheading"
          />
          {jobTitle && (
            <Typography
              text={jobTitle}
              variant="body-new/regular"
            />
          )}
          {code && (
            <Typography
              text={`#${code}`}
              variant="body-new/regular"
            />
          )}

          {canViewProfile && (
            <>
              <Spacer height="16px" />
              <Link to={`/employees/${id}`}>
                <Button
                  className="btn-view-full-profile"
                  label={I18n.t('view_profile')}
                  size="small"
                  variant="outlined"
                  color="neutral"
                  onClick={onViewProfile}
                />
              </Link>
            </>
          )}
        </div>
      </Popper>
    )
  }

  return (
    <div
      className="employee--td__box"
      style={{ ...style }}
    >
      <Flex itemsCenter>
        <LazyAvatar
          style={{
            width: avatarsize,
            height: avatarsize,
          }}
          src={avatar}
          alt={name}
          scrollPosition={scrollPosition}
        />
        <div className="emp-td_content">
          <Flex
            itemsCenter
            flexWrap
            style={{ gap: 8 }}
          >
            <Typography
              text={name}
              variant="body-new/regular"
              textColor={nameColor}
            />
            <ExemptedEmployee isExempted={isExempted} />
          </Flex>
          {showJobAndCode && (
            <Typography
              text={additionalInfo}
              variant={typography || 'body-new/regular'}
              style={{ color }}
            />
          )}
        </div>
      </Flex>
    </div>
  )
}
EmployeeTdInfo.propTypes = {
  id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  typography: PropTypes.string,
  color: PropTypes.string,
  nameColor: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  avatar: PropTypes.node,
  avatarsize: PropTypes.number,
  style: PropTypes.shape(),
  scrollPosition: PropTypes.shape(),
  showJobAndCode: PropTypes.bool,
  isExempted: PropTypes.bool,
  onViewProfile: PropTypes.func,
  onClosePopup: PropTypes.func,
  onClickURL: PropTypes.func,
  showAvatarBackground: PropTypes.bool,
}

EmployeeTdInfo.defaultProps = {
  showAvatarBackground: true,
  avatarsize: 40,
  id: '',
  onViewProfile: () => {},
  onClosePopup: () => {},
}

export default React.memo(EmployeeTdInfo)
