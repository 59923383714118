import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid } from 'components/global/atoms'
import { Flex, Spacer, Typography, Button } from '@jisr-hr/ds'
import { FileUpload, FileInfo } from '@jisr-hr/ds-beta'
import { useDispatch, useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { assetmanagerBulkUploadImportPost } from 'redux/setting/organizationSetting/assetmanagerBulkUpload/actionsCreators'
import { handleRecordStatus } from 'containers/authorised/setting/organizationSetting/BulkImportSheets/helper'
import { loadOnboardingBulkUploadCategories } from 'redux/organizationOnborading/bulkUpload/actionCreators'
import { commercialLoad } from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { TOnboardingRecords } from 'redux/organizationOnborading/bulkUpload/reducer'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { convertToFormData } from 'components/global/HelperFunctions'
import StepTemplate from '../../../components/StepTemplate'

type TState = {
  onboarding_mol: { status: boolean | string; message?: string }
  onboarding_muqeem: { status: boolean | string; message?: string }
}

const UploadGovt = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { fetching: assetManagerBulkFetching } = useSelector(
    ({ assetmanagerBulk }) => assetmanagerBulk,
  )

  const { molCat, muqeemCat, fetching } = useSelector(({ onboardingBulkUpload }) => ({
    fetching: onboardingBulkUpload.fetching || assetManagerBulkFetching,
    molCat:
      onboardingBulkUpload.onboarding_records.find((cat) => cat.type === 'onboarding_mol') ||
      ({} as TOnboardingRecords),
    muqeemCat:
      onboardingBulkUpload.onboarding_records.find((cat) => cat.type === 'onboarding_muqeem') ||
      ({} as TOnboardingRecords),
  }))

  const [molFiles, setMolFiles] = useState<FileInfo[]>([])
  const [muqeemFiles, setMuqeemFiles] = useState<FileInfo[]>([])
  const [state, setState] = useState<TState>({
    onboarding_mol: { status: true },
    onboarding_muqeem: { status: true },
  })

  const listImportFiles = [
    {
      title: 'mol_files',
      record: handleRecordStatus(
        molCat.status_value,
        molCat.metadata || {},
        molCat.error_file_url,
        '',
        '',
        molCat.s3_url,
      ),
      state: molFiles,
      setState: setMolFiles,
      amplitudeNameImport: 'mol_files_clicked',
      amplitudeNameUpload: 'upload_files_clicked',
      disabledImport: molCat.status_value === 1 || fetching,
      successMessage: state.onboarding_mol?.message,
      status: state.onboarding_mol?.status,
      type: 'onboarding_mol',
      disabledUpload: molCat.status_value === 1 || state.onboarding_mol.status === 'loading',
    },
    {
      title: 'muqeem_files',
      record: handleRecordStatus(
        muqeemCat.status_value,
        muqeemCat.metadata || {},
        muqeemCat.error_file_url,
        '',
        '',
        muqeemCat.s3_url,
      ),
      state: muqeemFiles,
      setState: setMuqeemFiles,
      amplitudeNameImport: 'muqeem_files_clicked',
      amplitudeNameUpload: 'upload_files_clicked',
      disabledImport: muqeemCat.status_value === 1 || fetching,
      successMessage: state.onboarding_muqeem?.message,
      status: state.onboarding_muqeem?.status,
      type: 'onboarding_muqeem',
      disabledUpload: muqeemCat.status_value === 1 || state.onboarding_muqeem.status === 'loading',
    },
  ]

  const uploadFiles = (type: string): Promise<void> | undefined => {
    const files = type === 'onboarding_mol' ? molFiles : muqeemFiles
    const isOneFile = files.length < 2
    if (files === undefined) return undefined
    setState((prev) => ({ ...prev, [type]: { status: 'loading' } }))
    let values
    if (isOneFile) {
      values = convertToFormData({ file: files[0].origin_file, type })
    } else {
      values = {
        files: files.map((file) => ({
          file: file.file,
          filename: file.name,
          original_filename: file.name,
        })),
        type,
      }
    }

    return dispatch(assetmanagerBulkUploadImportPost(values))
      .then(({ action }) => {
        setState((prev) => ({
          ...prev,
          [type]: {
            status: 'success',
            message: action.payload.data.data?.message || '',
          },
        }))
        dispatch(loadOnboardingBulkUploadCategories())
      })
      .catch(() => setState((prev) => ({ ...prev, [type]: { status: 'error' } })))
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'upload_govt_files' })

  useEffect(() => {
    dispatch(loadOnboardingBulkUploadCategories())
    dispatch(commercialLoad())
  }, [])

  useEffect(() => {
    const fetchCats = setInterval(() => {
      if (molCat.status_value === 1 || muqeemCat.status_value === 1) {
        return dispatch(loadOnboardingBulkUploadCategories())
      }
      return {}
    }, 10000)
    return () => {
      clearInterval(fetchCats)
    }
  })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="body-new/regular"
        text={I18n.t('upload_govt_files')}
      />
      <Typography
        variant="body-new/regular"
        text={I18n.t('Please_upload_mol_muqeem_and_gosl_files_for_your_crs')}
      />

      <Spacer height="20px" />
      <Grid
        container
        alignItems="baseline"
        spacing={2}
      >
        {listImportFiles.map((obj, i) => (
          <Grid
            item
            md={12}
            lg={6}
            key={i}
          >
            <Flex
              itemsCenter
              justifyBetween
            >
              <Typography
                variant="interface/default/m"
                text={I18n.t(obj.title)}
              />
              {obj.record}
            </Flex>
            <Spacer height="8px" />
            <FileUpload
              multiple
              compact={false}
              errorMessage={I18n.t('import_failed')}
              disabled={obj.disabledImport}
              onLoad={(file: FileInfo | FileInfo[] | null): void => {
                if (file) {
                  obj.setState(Array.isArray(file) ? file : [file])
                }
              }}
              accept={{
                acceptedFiles: ['.xls, .xlsx, .csv'],
              }}
            />
            {!isEmpty(obj.state) && (
              <>
                <Spacer height={12} />
                <Flex justifyEnd>
                  <Button
                    label={I18n.t('upload_files')}
                    size="small"
                    onClick={(): Promise<void> | undefined => uploadFiles(obj.type)}
                    disabled={obj.disabledUpload}
                  />
                </Flex>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </StepTemplate>
  )
}

export default UploadGovt
