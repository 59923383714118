import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './Table.module.css'

import Row from './components/Row'
import Cell from './components/Cell'
import Thead from './components/Thead'
import Tbody from './components/Tbody'
import ThCell from './ThCell'

const switchSortDirection = (sortDir) => (sortDir === 'asc' ? 'desc' : 'asc')

const Table = ({
  headers,
  subHeaders,
  withActions,
  hoverActions,
  children,
  blank,
  sortable,
  sortBy,
  sortDir,
  sortExclude,
  onSorting,
  className,
  noDataText,
  stopSorting,
  testId,
}) => {
  const [theaders, setTheaders] = React.useState([])
  const [sort, setSort] = React.useState({
    sortBy,
    sortDir: sortDir || 'asc',
  })

  // handle on th click if the table sortable,
  const handleOnSorting = (key) => {
    const srt = {
      sortBy: key,
      sortDir: sort.sortBy === key ? switchSortDirection(sort.sortDir) : 'asc',
    }

    onSorting(srt)
    setSort(srt)
  }

  // make the headers as object
  // if user add heder as string array recreate the header
  // as object
  const handleHeaders = () => {
    let ths = headers
    if (typeof headers[0] === 'string') {
      ths = headers.map((h, i) => ({
        label: h,
        key: i,
      }))
    }
    setTheaders(ths)
  }

  React.useEffect(() => {
    handleHeaders()
  }, [headers])

  React.useEffect(() => {
    setSort({
      sortBy,
      sortDir,
    })
  }, [sortBy, sortDir])

  return (
    <div className={classnames(Styles.tableContainer, className)}>
      <table
        className={classnames(
          Styles.table,
          hoverActions && Styles.hoverActions,
          blank && Styles.tableBlank,
        )}
        data-testid={testId}
      >
        {headers && (
          <Thead>
            <Row>
              {theaders.map((th, i) => (
                <ThCell
                  key={`table-th-${i}`}
                  data={th}
                  sortable={!!(sortable && !sortExclude.includes(th.key) && !stopSorting)}
                  sortActive={sort.sortBy === th.key}
                  sortDir={sort.sortDir}
                  onClick={(value) => sortable && handleOnSorting(value)}
                  th
                />
              ))}
              {(withActions || hoverActions) && <Cell th />}
            </Row>
            {subHeaders && (
              <Row>
                {subHeaders.map((th, i) => (
                  <ThCell
                    key={`table-th-${i}`}
                    data={th}
                    sortable={!!(sortable && !sortExclude.includes(th.key))}
                    sortActive={sort.sortBy === th.key}
                    sortDir={sort.sortDir}
                    onClick={(value) => sortable && handleOnSorting(value)}
                    subCell
                    th
                  />
                ))}
                {(withActions || hoverActions) && <Cell th />}
              </Row>
            )}
          </Thead>
        )}
        <Tbody>
          {children}
          {!children.length && noDataText && (
            <Row className="noData">
              <Cell
                className={Styles.noDataCell}
                alignCenter
                colspan="100%"
              >
                {noDataText}
              </Cell>
            </Row>
          )}
        </Tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  headers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  subHeaders: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.object])),
  withActions: PropTypes.bool,
  hoverActions: PropTypes.bool,
  children: PropTypes.node,
  blank: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  sortable: PropTypes.bool,
  onSorting: PropTypes.func,
  sortExclude: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.array),
  ]),
  className: PropTypes.string,
  noDataText: PropTypes.string,
  stopSorting: PropTypes.bool,
  testId: PropTypes.string,
}

Table.defaultProps = {
  onSorting: () => {},
  testId: 'ds-table',
}

export default Table
