import store from 'redux/store'
import { removeAuthUser } from 'redux/authUser/actionCreators'
import { unsubscribeFirebase } from 'redux/actions/firebaseActions'
import { getTokenFromDB } from 'firebaseUtils'
import { AxiosError, AxiosResponse } from 'axios'
// import { isDevelopmentStage } from '../env';
/*
  this function for handle global error response from axios
  we can implemnt more cases like show error messges as its bad performance we used
*/

// const forceRefresh403 = 'force-refresh-403';

function errorResponseHandler(error: { response: AxiosResponse }): Promise<AxiosError> {
  const { response } = error
  const isForceRefreshed = window.location.href.includes('#reloaded')
  const isLoggedIn = localStorage.getItem('LoggedIn')
  const path = window.location.hash?.split?.('#/')?.[1] || ''
  const isHomeLogin = !path || path.includes('login')

  if (response) {
    switch (response.status) {
      case 401:
        if (isLoggedIn) {
          store.dispatch(removeAuthUser())
          getTokenFromDB({ clear: true }).then((token) => {
            if (token) store.dispatch(unsubscribeFirebase({ token }))
          })
          window.location.reload() // reload page to redirect user to login
        } else if (path.includes('onboarding')) {
          let url = window.location.href
          url = url.replace('onboarding.', 'signin.')
          url = url.replace('/#/onboarding', '/')
          window.location.href = url
        }
        break

      case 403:
        if (isLoggedIn && !isHomeLogin) {
          if (isForceRefreshed) {
            window.location.href = '/'
          } else {
            window.location.href += '#reloaded'
            window.location.reload()
          }
        }
        break

      default:
        break
    }
  }

  return Promise.reject(error)
}

export default errorResponseHandler
