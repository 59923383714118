/* eslint-disable prefer-template */
import { Toastr } from 'components/global/Toastr'
import lodash from 'lodash'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { isRtl } from 'utils/uiHelpers'
import * as actions from './actions'

const category_vals = {
  Allowances: 1,
  'Absenteeism transactions': 2,
  Accruals: 2,
  'Loan Types': 3,
  'Other PayTypes': 4,
  'Other Deductions': 5,
}

const initialState = {
  gl_accounts: [],
  gl_account: {},
  gl_transaction_types: [],
  gl_transaction_category: {},
  unmapped_count: null,
  list_fetching: true,
  fetching: false,
  types_loading: false,
  loading: false,
  uploading: false,
  alertInfo: {
    failed: 0,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ? gl load
    case actions.GL_ACCOUNTS_LIST_LOAD_PENDING:
    case actions.GL_ACCOUNT_LOAD_PENDING:
    case actions.GL_ACCOUNT_CREATE_PENDING:
    case actions.GL_ACCOUNT_DELETE_PENDING:
    case actions.UNMAPPED_COUNT_PENDING:
    case actions.GL_MAPPING_BULK_IMPORT_SAMPLE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.UNMAPPED_COUNT_FULFILLED:
      return {
        ...state,
        unmapped_count: action.payload.data?.data?.unmapped_count,
        fetching: false,
      }
    case actions.GL_ACCOUNTS_LIST_LOAD_FULFILLED:
      return {
        ...state,
        gl_accounts: action.payload.data?.data?.gl_accounts,
        fetching: false,
        list_fetching: false,
      }

    case actions.GL_ACCOUNT_LOAD_FULFILLED:
      return {
        ...state,
        gl_account: action.payload.data?.data?.gl_account,
        fetching: false,
      }

    case actions.GL_ACCOUNT_CREATE_FULFILLED:
    case actions.GL_ACCOUNT_DELETE_FULFILLED:
    case actions.GL_MAPPING_BULK_IMPORT_SAMPLE_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.GL_ACCOUNT_LOAD_REJECTED:
    case actions.UNMAPPED_COUNT_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.GL_ACCOUNT_CREATE_REJECTED:
    case actions.GL_ACCOUNT_DELETE_REJECTED:
    case actions.GL_MAPPING_BULK_IMPORT_SAMPLE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.GL_ACCOUNTS_LIST_LOAD_REJECTED:
      return {
        ...state,
        list_fetching: false,
        fetching: false,
      }

    // ? types sync load
    case actions.GL_ACCOUNT_TRANSACTION_SYNC_PENDING:
      return {
        ...state,
        types_loading: true,
      }

    case actions.GL_ACCOUNT_TRANSACTION_SYNC_FULFILLED: {
      const types = action.payload.data?.data?.gl_transaction_types || []
      const category = lodash.groupBy(types, 'gl_transaction_category') || {}

      const category_list = Object.keys(category)?.map((label) => ({
        label,
        value: category_vals[label],
      }))
      return {
        ...state,
        gl_transaction_types: types?.map((item) => {
          const name_en =
            item?.gl_transaction_category === 'Loan Types'
              ? item?.gl_transaction_name + ' (Loan)'
              : item?.gl_transaction_name
          const name_ar =
            item.gl_transaction_category === 'Loan Types'
              ? item.gl_transaction_name_ar + ' (قرض)'
              : item.gl_transaction_name_ar
          return {
            ...item,
            transaction_name_i18n: isRtl && item.gl_transaction_name_ar ? name_ar : name_en,
          }
        }),
        gl_transaction_category: category,
        gl_transaction_category_list: category_list.sort((a, b) => a.value - b.value),
        types_loading: false,
      }
    }

    case actions.GL_ACCOUNT_TRANSACTION_SYNC_REJECTED:
      return {
        ...state,
        types_loading: false,
      }

    // ? bulk updates
    // ? gl updates
    case actions.GL_ACCOUNT_BULK_UPDATE_PENDING:
    case actions.GL_ACCOUNT_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.GL_ACCOUNT_BULK_UPDATE_FULFILLED:
    case actions.GL_ACCOUNT_UPDATE_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        loading: false,
      }

    case actions.GL_ACCOUNT_BULK_UPDATE_REJECTED:
    case actions.GL_ACCOUNT_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    // ? bulk upload
    case actions.GL_MAPPING_BULK_UPLOAD_PENDING:
      return {
        ...state,
        uploading: true,
      }

    case actions.GL_MAPPING_BULK_UPLOAD_FULFILLED: {
      const alertInfo = action.payload.data.data
      if (alertInfo.failed === 0) {
        Toastr.success(action.payload.data?.message)
      } else {
        Toastr.error(action.payload.data?.message)
      }
      return {
        ...state,
        alertInfo,
        uploading: false,
      }
    }

    case actions.GL_MAPPING_BULK_UPLOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        uploading: false,
      }

    default:
      return state
  }
}
