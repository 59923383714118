import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function subscribeFirebase(data) {
  const url = `/${API_VERSION_TWO}/firebase_messages/subscribe/`
  const method = 'POST'
  return {
    type: 'SUBSCRIBE_FIREBASE',
    payload: handleAPI(url, {}, method, { firebase_messages: data }),
  }
}

export function unsubscribeFirebase(data) {
  const url = `/${API_VERSION_TWO}/firebase_messages/unsubscribe/`
  const method = 'POST'
  return {
    type: 'UNSUBSCRIBE_FIREBASE',
    payload: handleAPI(url, {}, method, { firebase_messages: data }),
  }
}

export function registrationToken(data) {
  const url = `/${API_VERSION_TWO}/employee_registration_tokens`
  const method = 'POST'
  return {
    type: 'REGISTRATION_TOKEN',
    payload: handleAPI(url, {}, method, { registration_token: data }),
  }
}

export function firebaseTopicsLoad(params) {
  const url = `/${API_VERSION_TWO}/firebase_messages/topics`
  const method = 'GET'
  return {
    type: 'FIREBASE_TOPICS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function unRegistrationToken(id, serviceName) {
  const url = `/${API_VERSION_TWO}/employee_registration_tokens/${id}/`
  const method = 'DELETE'
  return {
    type: 'UNREGISTRATION_TOKEN',
    payload: handleAPI(url, {}, method, { service_name: serviceName }),
  }
}
