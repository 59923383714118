import React from 'react'
import PropTypes from 'prop-types'
import { isValid } from 'date-fns'
import { format } from 'utils/date'
import { Typography, Flex } from '@jisr-hr/ds'

import EmployeeProfile from '../EmployeeProfile'

const ProfileAndTime = ({ name, jobTitle, code, avatar, date, time, status, status_i18n }) => (
  <Flex
    justifyBetween
    itemsStart
  >
    <EmployeeProfile
      name={name}
      jobTitle={jobTitle}
      code={code}
      avatar={avatar}
      status={status}
      status_i18n={status_i18n}
    />

    <Typography
      text={
        <>
          {isValid(new Date(date)) ? format(new Date(date), 'MMM dd, yyyy') : date} <br />
          {time}
        </>
      }
      style={{ color: 'var(--color-base-colors-grey-1000)' }}
      variant="body-new/regular"
    />
  </Flex>
)

ProfileAndTime.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  avatar: PropTypes.string,
  time: PropTypes.string,
  status: PropTypes.string,
  status_i18n: PropTypes.string,
  date: PropTypes.instanceOf(Date),
}

export default ProfileAndTime
