import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type EmpAuditLogActionsTypes = {
  filterKey: string
  filters: string[]
}

const EmpAuditLogActions = (props: EmpAuditLogActionsTypes): JSX.Element => {
  const { action_type } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(action_type)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        action_type: [],
        item_type: [],
      })
    } else {
      handleFilter('action_type', val, {
        key: 'item_type',
        ids: val === 'contracts' ? ['Hr::EmployeeContract'] : ['Employee'],
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.action_type)) {
      setSelectedIds(filter.action_type)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.action_type])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
    />
  )
}

export default EmpAuditLogActions
