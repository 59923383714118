import { Typography, Flex, Spacer, Button, FeaturedIcon } from '@jisr-hr/ds'
import { shallowEqual } from 'react-redux'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { organizationConfirmUpdate } from 'redux/organizationOnborading/organization/actionCreators'
import jisrLogo from 'assets/images/JisrLogo.svg'

import styles from './styles.module.css'

type StepsToLoginTypes = {
  describe: string
  icon: string
  title: string
}

const stepsToLogin: StepsToLoginTypes[] = [
  {
    icon: 'mail-03',
    title: i18n.t('check_email'),
    describe: i18n.t('des_check_email'),
  },
  {
    icon: 'lock-03',
    title: i18n.t('create_password'),
    describe: i18n.t('des_create_password'),
  },
  {
    icon: 'log-out-01',
    title: i18n.t('login_to_jisr', {
      app_name: i18n.t('jisr_translation_name'),
    }),
    describe: i18n.t('des_login_to_jisr', {
      app_name: i18n.t('jisr_translation_name'),
    }),
  },
]

const ActivateAccount = (): JSX.Element => {
  const dispatch = useDispatch()
  const organization = useSelector((s) => s.organization.organization, shallowEqual)

  const data = useSelector((s) => s.organization)

  const handleSubmit = async (): Promise<void> => {
    let url = window.location.href.toString()
    await dispatch(organizationConfirmUpdate())
    url = url.replace('onboarding.', `${data?.organization?.slug}.`)
    url = url.replace('/#/onboarding', '/')
    window.location.href = url
  }

  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <Flex
        className={styles.content}
        justifyCenter
        flexCol
        itemsCenter
      >
        <Flex
          className={styles.contentImgJisr}
          justifyCenter
          itemsCenter
        >
          <img
            src={jisrLogo}
            alt="Jisr"
          />
        </Flex>
        <div className={styles.contentTitel}>
          <Typography
            variant="heading"
            text={organization?.name_i18n}
          />
          <Typography
            variant="subtitle-1"
            style={{ color: 'var(--color-base-colors-grey-700)' }}
            text={i18n.t('des_for_name')}
          />
        </div>
        <Spacer height={48} />

        {stepsToLogin.map((step, index) => (
          <>
            <Flex
              key={index}
              itemsCenter
              className={styles.flexContent}
            >
              <FeaturedIcon
                iconName={step.icon}
                colorType="brand"
              />
              <div>
                <Typography
                  variant="subtitle-1"
                  text={`${index + 1}.
                  ${step.title}`}
                />
                <Typography
                  variant="interface/default/m"
                  style={{ color: 'var(--color-base-colors-grey-700)' }}
                  text={
                    <>
                      {step.describe}{' '}
                      {index === 0 && (
                        <Typography
                          className={styles.styleEmail}
                          variant="interface/default/m"
                          style={{ color: 'var(--color-base-colors-grey-700)' }}
                          text={organization?.metadata?.first_admin_details?.email}
                        />
                      )}
                    </>
                  }
                />
              </div>
            </Flex>
            <Spacer height={40} />
          </>
        ))}
        <Spacer height={48} />
        <Button
          label={i18n.t('login')}
          style={{ justifyContent: 'center', width: '277px', height: '40px' }}
          onClick={handleSubmit}
        />
      </Flex>
    </div>
  )
}

export default ActivateAccount
