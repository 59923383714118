import i18n from 'translations/i18n'
import { Flex, Badge, Banner } from '@jisr-hr/ds'
import {
  Info,
  Reason,
  ReassignTask,
  Assignee,
} from 'components/global/templates/RequestDrawer/components'
import DSIcon from 'components/DSIcon'
import { useRequest } from '../../RequestProvider'

type AttachmentType = { data: string; filename: string }

type AssetAllocationDeallocationData = {
  vars: {
    request: {
      readonly id: number
      assignee: string
      attachments: AttachmentType[]
      category: string
      employee: unknown
      name: string
      request: { created_time: string }
      status: string
      status_i18n: string
      task_info: {
        asset_items: {
          id: number
          name_i18n: string
        }[]
        asset_types: {
          id: number
          name_i18n: string
        }[]
        attachments: AttachmentType[]
        reason?: string
      }
      time: string
      due_date: string | Date
    }
  }
}

const AssetAllocationDeallocationTask = (): JSX.Element => {
  const {
    vars: { request },
  } = useRequest()! as AssetAllocationDeallocationData

  const isAllocationTask = request.category === 'asset_allocation_task'

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          type_i18n: request.name,
          created_at: request?.request?.created_time,
          requested_on: request?.request?.created_time,
          due_date: request.due_date,
          alert: (
            <Banner
              color="warning"
              variant="tinted"
              leadingIcon="alert-circle"
              title={i18n.t('disclaimer')}
              description={
                isAllocationTask
                  ? i18n.t('allocation_task_disclaimer')
                  : i18n.t('deallocation_task_disclaimer')
              }
            />
          ),
          items: [
            !!request.task_info?.asset_types?.length && {
              icon: (
                <DSIcon
                  name="check-done-02"
                  size="md"
                />
              ),
              label: i18n.t('asset_types'),
              value: (
                <Flex
                  itemsCenter
                  style={{ gap: 8 }}
                >
                  {request.task_info?.asset_types.map((type) => (
                    <Badge
                      key={type.id}
                      label={type.name_i18n}
                      variant="tinted"
                      color="gray"
                      size="medium"
                    />
                  ))}
                </Flex>
              ),
            },
            !!request.task_info?.asset_items?.length && {
              icon: (
                <DSIcon
                  name="check-done-02"
                  size="md"
                />
              ),
              label: i18n.t('asset_items'),
              value: (
                <Flex
                  itemsCenter
                  style={{ gap: 8 }}
                >
                  {request.task_info?.asset_items.map((type) => (
                    <Badge
                      key={type.id}
                      label={type.name_i18n}
                      variant="tinted"
                      color="gray"
                      size="medium"
                    />
                  ))}
                </Flex>
              ),
            },
          ].filter(Boolean),
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request?.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default AssetAllocationDeallocationTask
