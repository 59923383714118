export const APPROVALS_LOAD = 'APPROVALS_LOAD'
export const APPROVALS_LOAD_PENDING = 'APPROVALS_LOAD_PENDING'
export const APPROVALS_LOAD_FULFILLED = 'APPROVALS_LOAD_FULFILLED'
export const APPROVALS_LOAD_REJECTED = 'APPROVALS_LOAD_REJECTED'

export const APPROVAL_DETAIL_LOAD = 'APPROVAL_DETAIL_LOAD'
export const APPROVAL_DETAIL_LOAD_PENDING = 'APPROVAL_DETAIL_LOAD_PENDING'
export const APPROVAL_DETAIL_LOAD_FULFILLED = 'APPROVAL_DETAIL_LOAD_FULFILLED'
export const APPROVAL_DETAIL_LOAD_REJECTED = 'APPROVAL_DETAIL_LOAD_REJECTED'

export const TASK_DETAIL_LOAD = 'TASK_DETAIL_LOAD'
export const TASK_DETAIL_LOAD_PENDING = 'TASK_DETAIL_LOAD_PENDING'
export const TASK_DETAIL_LOAD_FULFILLED = 'TASK_DETAIL_LOAD_FULFILLED'
export const TASK_DETAIL_LOAD_REJECTED = 'TASK_DETAIL_LOAD_REJECTED'

export const TASK_REASSIGN = 'TASK_REASSIGN'
export const TASK_REASSIGN_PENDING = 'TASK_REASSIGN_PENDING'
export const TASK_REASSIGN_FULFILLED = 'TASK_REASSIGN_FULFILLED'
export const TASK_REASSIGN_REJECTED = 'TASK_REASSIGN_REJECTED'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_PENDING = 'UPDATE_TASK_PENDING'
export const UPDATE_TASK_FULFILLED = 'UPDATE_TASK_FULFILLED'
export const UPDATE_TASK_REJECTED = 'UPDATE_TASK_REJECTED'

export const APPROVAL_DETAIL_RESET = 'APPROVAL_DETAIL_RESET'

export const REQUESTS_TASKS_LOAD = 'REQUESTS_TASKS_LOAD'
export const REQUESTS_TASKS_LOAD_PENDING = 'REQUESTS_TASKS_LOAD_PENDING'
export const REQUESTS_TASKS_LOAD_FULFILLED = 'REQUESTS_TASKS_LOAD_FULFILLED'
export const REQUESTS_TASKS_LOAD_REJECTED = 'REQUESTS_TASKS_LOAD_REJECTED'

export const APPROVE_REQUEST = 'APPROVE_REQUEST'
export const APPROVE_REQUEST_PENDING = 'APPROVE_REQUEST_PENDING'
export const APPROVE_REQUEST_FULFILLED = 'APPROVE_REQUEST_FULFILLED'
export const APPROVE_REQUEST_REJECTED = 'APPROVE_REQUEST_REJECTED'
export const CLEAR_APPROVE_REQ_RES = 'CLEAR_APPROVE_REQ_RES'

export const REJECT_REQUEST = 'REJECT_REQUEST'
export const REJECT_REQUEST_PENDING = 'REJECT_REQUEST_PENDING'
export const REJECT_REQUEST_FULFILLED = 'REJECT_REQUEST_FULFILLED'
export const REJECT_REQUEST_REJECTED = 'REJECT_REQUEST_REJECTED'

export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const CANCEL_REQUEST_PENDING = 'CANCEL_REQUEST_PENDING'
export const CANCEL_REQUEST_FULFILLED = 'CANCEL_REQUEST_FULFILLED'
export const CANCEL_REQUEST_REJECTED = 'CANCEL_REQUEST_REJECTED'

export const REMOVE_REQUEST = 'REMOVE_REQUEST'
export const REMOVE_REQUEST_PAYRUN = 'REMOVE_REQUEST_PAYRUN'

export const STATUS_STATISTICS_LOAD = 'STATUS_STATISTICS_LOAD'
export const STATUS_STATISTICS_LOAD_PENDING = 'STATUS_STATISTICS_LOAD_PENDING'
export const STATUS_STATISTICS_LOAD_FULFILLED = 'STATUS_STATISTICS_LOAD_FULFILLED'
export const STATUS_STATISTICS_LOAD_REJECTED = 'STATUS_STATISTICS_LOAD_REJECTED'

export const TEAM_REQUESTS_APPROVALS_LOAD = 'TEAM_REQUESTS_APPROVALS_LOAD'
export const TEAM_REQUESTS_APPROVALS_LOAD_PENDING = 'TEAM_REQUESTS_APPROVALS_LOAD_PENDING'
export const TEAM_REQUESTS_APPROVALS_LOAD_FULFILLED = 'TEAM_REQUESTS_APPROVALS_LOAD_FULFILLED'
export const TEAM_REQUESTS_APPROVALS_LOAD_REJECTED = 'TEAM_REQUESTS_APPROVALS_LOAD_REJECTED'
export const REMOVE_REQUEST_LIST = 'REMOVE_REQUEST_LIST'

export const REQUESTS_CUSTOM_TASKS_CREATE = 'REQUESTS_CUSTOM_TASKS_CREATE'
export const REQUESTS_CUSTOM_TASKS_CREATE_PENDING = 'REQUESTS_CUSTOM_TASKS_CREATE_PENDING'
export const REQUESTS_CUSTOM_TASKS_CREATE_FULFILLED = 'REQUESTS_CUSTOM_TASKS_CREATE_FULFILLED'
export const REQUESTS_CUSTOM_TASKS_CREATE_REJECTED = 'REQUESTS_CUSTOM_TASKS_CREATE_REJECTED'
