export const LEAVE_BALANCES_LOAD = 'LEAVE_BALANCES_LOAD'
export const LEAVE_BALANCES_LOAD_FULFILLED = 'LEAVE_BALANCES_LOAD_FULFILLED'
export const LEAVE_BALANCES_LOAD_PENDING = 'LEAVE_BALANCES_LOAD_PENDING'
export const LEAVE_BALANCES_LOAD_REJECTED = 'LEAVE_BALANCES_LOAD_REJECTED'

export const SET_LEAVE_BALANCES = 'SET_LEAVE_BALANCES'
export const SET_LEAVE_BALANCES_FULFILLED = 'SET_LEAVE_BALANCES_FULFILLED'
export const SET_LEAVE_BALANCES_REJECTED = 'SET_LEAVE_BALANCES_REJECTED'

export const LEAVE_BALANCES_UPDATE = 'LEAVE_BALANCES_UPDATE'
export const LEAVE_BALANCES_UPDATE_FULFILLED = 'LEAVE_BALANCES_UPDATE_FULFILLED'
export const LEAVE_BALANCES_UPDATE_REJECTED = 'LEAVE_BALANCES_UPDATE_REJECTED'

export const SET_LEAVES_FILTERS = 'SET_LEAVES_FILTERS'
