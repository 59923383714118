import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  TawuniysReducerStateType,
  policyAccountDetailsServer,
} from 'containers/authorised/setting/v2/integration/Tawuniya/types'
import * as actions from './actions'

const initialValues: TawuniysReducerStateType = {
  fetching: false,
  responseMessage: { message: '', type: '' },
  policyAccounts: [],
  totalRecords: 0,
  tawuniyaLegalNoticeAcceptance: false,
}

export default function reducer(state = initialValues, action: Action): TawuniysReducerStateType {
  const { payload } = action
  switch (action.type) {
    case actions.TAWUNIYA_DELETE_POLICY_PENDING:
    case actions.TAWUNIYA_DISCONNECT_POLICY_PENDING:
    case actions.GET_TAWUNIYA_POLICY_ACCOUNTS_PENDING:
    case actions.TAWUNIYA_LEGAL_NOTICE_PENDING:
    case actions.TAWUNIYA_CONNECT_POLICY_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GET_TAWUNIYA_POLICY_ACCOUNTS_FULFILLED: {
      const data = payload?.data?.data?.insurance_provider_organizations
      const updatedData = data
        ? data.map((policyAccount: policyAccountDetailsServer) => ({
            ...policyAccount,
            status: policyAccount.status === 'active',
          }))
        : []
      return {
        ...state,
        fetching: false,
        policyAccounts: updatedData,
        totalRecords: payload?.data?.data?.insurance_provider_organizations?.length,
        tawuniyaLegalNoticeAcceptance: payload?.data?.data?.tawuniya_legal_notice_acceptance,
      }
    }

    case actions.TAWUNIYA_LEGAL_NOTICE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        tawuniyaLegalNoticeAcceptance: payload?.data?.data?.employee_configuration?.value,
      }
    }

    case actions.TAWUNIYA_DELETE_POLICY_FULFILLED:
    case actions.TAWUNIYA_DISCONNECT_POLICY_FULFILLED:
    case actions.TAWUNIYA_CONNECT_POLICY_FULFILLED: {
      if (payload.data?.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
        responseMessage: { message: '', type: '' },
      }
    }

    case actions.TAWUNIYA_CONNECT_POLICY_REJECTED: {
      return {
        ...state,
        fetching: false,
        responseMessage: { message: handleResponseErr(payload), type: 'error' },
      }
    }

    case actions.TAWUNIYA_DELETE_POLICY_REJECTED:
    case actions.TAWUNIYA_DISCONNECT_POLICY_REJECTED:
    case actions.TAWUNIYA_LEGAL_NOTICE_REJECTED:
    case actions.GET_TAWUNIYA_POLICY_ACCOUNTS_REJECTED: {
      if (payload?.data?.message) Toastr.error(handleResponseErr(payload.data.message))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.TAWUINYA_RESET_FORM_DATA: {
      return {
        ...state,
        responseMessage: { message: '', type: '' },
      }
    }

    default:
      return state
  }
}
