import { useState } from 'react'
import cns from 'classnames'
import { Typography, Spacer, Flex, Switch } from '@jisr-hr/ds'
import { ReactComponent as EditIcon } from '@jisr-hr/design-system/dist/assets/icons/edit.svg'
import styles from './style.module.css'

type TSwitchCard = {
  name?: string
  days?: number
  isActive?: boolean
  handleOnEdit?: () => void
  handleOnChange?: (s: boolean) => void
  disabled?: boolean
  unEditable?: boolean
}

const SwitchCard = ({
  name,
  days,
  isActive,
  handleOnEdit,
  handleOnChange,
  disabled,
  unEditable,
}: TSwitchCard): JSX.Element => {
  const [hovered, setHovered] = useState(false)

  return (
    <Flex
      flexCol
      className={cns(styles.radioContent, { [styles.disabled]: disabled })}
      onMouseEnter={(): void => setHovered(true)}
      onMouseLeave={(): void => setHovered(false)}
    >
      <Flex justifyBetween>
        <Typography
          text={name}
          variant="interface/default/m"
        />
        <Flex itemsCenter>
          {!unEditable && isActive && hovered && (
            <EditIcon
              onClick={handleOnEdit}
              style={{ cursor: 'pointer' }}
            />
          )}
          <Spacer width="16px" />
          <Switch
            active={isActive}
            disabled={disabled}
            onChange={(_, v): void => handleOnChange?.(v)}
          />
        </Flex>
      </Flex>
      <Spacer height="4px" />
      <Typography
        variant="interface/default/sm"
        text={days}
      />
    </Flex>
  )
}

export default SwitchCard
