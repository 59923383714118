import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PaymentDetailsTypes = {
  filterKey: string
  filters: string[]
}

const PaymentDetails = (props: PaymentDetailsTypes): JSX.Element => {
  const [fltrList, setFltrList] = useState<Array<string>>([])
  const { uncorrect_data } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(uncorrect_data)

  const onHandleFilter = (__: string, val: string): void => {
    const value = val.toLowerCase() === 'all' ? [] : [val === 'correct_data']
    handleFilter('is_data_correct', value)
  }

  useEffect(() => {
    const fltr = filter[props.filterKey]
    if (!isEmpty(fltr)) {
      const dataFltr = fltr[0] ? 'correct_data' : 'uncorrect_data'
      setFltrList([dataFltr])
    } else {
      setFltrList(['all'])
    }
  }, [filter.is_data_correct])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={fltrList as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

export default PaymentDetails
