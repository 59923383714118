import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  employee: {},
  error: '',
  guess_slug: '',
  account_type: '',
  onboarding_flow: '',
  is_account_activated: false,
  onboarding_admin_roles: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_ADMINS_REGISTER_POST_PENDING:
    case actions.ONBOARDING_ADMINS_AUTH_SHOW_PENDING:
      return {
        ...state,
        error: '',
        fetching: true,
      }

    case actions.ONBOARDING_ADMINS_AUTH_SHOW_FULFILLED:
      return {
        ...state,
        fetching: false,
        account_type: payload.data.data?.account_type,
        guess_slug: payload.data.data?.guess_slug,
        is_account_activated: payload.data.data?.is_account_activated,
        onboarding_admin_roles: payload.data.data?.onboarding_admin_roles,
        is_first_admin: payload.data.data?.is_first_admin,
        employee: payload.data.data?.employee,
        onboarding_flow: payload.data.data?.onboarding_flow,
      }

    case actions.ONBOARDING_ADMINS_REGISTER_POST_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ADMINS_AUTH_SHOW_REJECTED:
    case actions.ONBOARDING_ADMINS_REGISTER_POST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        error: handleResponseErr(action.payload),
      }

    default:
      return state
  }
}
