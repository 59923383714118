import { assetmanagerAPI } from 'utils/assetmanagerUtils'
import { handleAPI, downloadAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function loadOnboardingBulkUploadCategories(params = {}): Action {
  const url = '/v1/bulk_upload/onboarding_categories'

  return {
    type: actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_LOAD,
    payload: assetmanagerAPI(url, params, 'GET'),
  }
}

export function uploadOnboardingGosiFile(data = {}, params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization/import/`
  const method = 'POST'

  return {
    type: actions.UPLOAD_ONBOARDING_GOSI_FILE,
    payload: handleAPI(url, params, method, data),
  }
}

export function employeesBulkDelete(data = {}, params = {}): Action {
  const url = `/${API_VERSION_TWO}/employees/bulk_delete`
  const method = 'DELETE'

  return {
    type: actions.EMPLOYEES_BULK_DELETE,
    payload: handleAPI(url, params, method, data),
  }
}

export function resetAllEmployeesExpectAdmins(): Action {
  const url = `/${API_VERSION_TWO}/employees/reset`
  const method = 'DELETE'

  return {
    type: actions.RESET_ALL_EMPLOYEES_EXPECT_ADMINS,
    payload: handleAPI(url, {}, method),
  }
}

export function exportOnboardingOrganizationData(params = {} as { type: string }): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization/export.xls`

  return {
    type: actions.EXPORT_ONBOARDING_ORGANIZATION_DATA,
    payload: downloadAPI(url, 'application/xls', `organization-${params.type}`, params),
  }
}

export function loadOnboardingBulkUploadReset(): Action {
  return {
    type: actions.ONBOARDING_BULK_UPLOAD_CATEGORIES_RESET,
  }
}
