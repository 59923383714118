import { Accordion } from '@jisr-hr/ds-beta'
import { Typography } from '@jisr-hr/ds'
import { useSelector } from 'utils/hooks'

export const OnboardingClarificationCard = () => {
  const { request } = useSelector(({ requestDetails }) => requestDetails)
  return (
    <div className="flex-1 max-w-[600px]">
      <Accordion
        isRounded
        size="medium"
        spacing="md"
        title="HR needs you to edit some info"
        iconName="edit-03"
        color="gray"
      >
        <div className="px-5">
          <ul className="list-disc px-2 space-y-1">
            {request?.request_corrections?.map((correction, index) => (
              <li key={index}>
                <Typography text={correction.reason} />
              </li>
            ))}
          </ul>
        </div>
      </Accordion>
    </div>
  )
}
