export const ODOO_COST_CENTER_LIST_LOAD = 'ODOO_COST_CENTER_LIST_LOAD'
export const ODOO_COST_CENTER_LIST_LOAD_FULFILLED = 'ODOO_COST_CENTER_LIST_LOAD_FULFILLED'
export const ODOO_COST_CENTER_LIST_LOAD_REJECTED = 'ODOO_COST_CENTER_LIST_LOAD_REJECTED'
export const ODOO_COST_CENTER_LIST_LOAD_PENDING = 'ODOO_COST_CENTER_LIST_LOAD_PENDING'

export const ODOO_COST_CENTER_CREATE = 'ODOO_COST_CENTER_CREATE'
export const ODOO_COST_CENTER_CREATE_FULFILLED = 'ODOO_COST_CENTER_CREATE_FULFILLED'
export const ODOO_COST_CENTER_CREATE_REJECTED = 'ODOO_COST_CENTER_CREATE_REJECTED'
export const ODOO_COST_CENTER_CREATE_PENDING = 'ODOO_COST_CENTER_CREATE_PENDING'

export const ODOO_COST_CENTER_UPDATE = 'ODOO_COST_CENTER_UPDATE'
export const ODOO_COST_CENTER_UPDATE_FULFILLED = 'ODOO_COST_CENTER_UPDATE_FULFILLED'
export const ODOO_COST_CENTER_UPDATE_REJECTED = 'ODOO_COST_CENTER_UPDATE_REJECTED'
export const ODOO_COST_CENTER_UPDATE_PENDING = 'ODOO_COST_CENTER_UPDATE_PENDING'

export const ODOO_COST_CENTER_DELETE = 'ODOO_COST_CENTER_DELETE'
export const ODOO_COST_CENTER_DELETE_FULFILLED = 'ODOO_COST_CENTER_DELETE_FULFILLED'
export const ODOO_COST_CENTER_DELETE_REJECTED = 'ODOO_COST_CENTER_DELETE_REJECTED'
export const ODOO_COST_CENTER_DELETE_PENDING = 'ODOO_COST_CENTER_DELETE_PENDING'

export const ODOO_COST_CENTER_CATAGORIES = 'ODOO_COST_CENTER_CATAGORIES'
export const ODOO_COST_CENTER_CATAGORIES_FULFILLED = 'ODOO_COST_CENTER_CATAGORIES_FULFILLED'
export const ODOO_COST_CENTER_CATAGORIES_REJECTED = 'ODOO_COST_CENTER_CATAGORIES_REJECTED'
export const ODOO_COST_CENTER_CATAGORIES_PENDING = 'ODOO_COST_CENTER_CATAGORIES_PENDING'

export const ODOO_COST_CENTER_ACCOUNTS = 'ODOO_COST_CENTER_ACCOUNTS'
export const ODOO_COST_CENTER_ACCOUNTS_FULFILLED = 'ODOO_COST_CENTER_ACCOUNTS_FULFILLED'
export const ODOO_COST_CENTER_ACCOUNTS_REJECTED = 'ODOO_COST_CENTER_ACCOUNTS_REJECTED'
export const ODOO_COST_CENTER_ACCOUNTS_PENDING = 'ODOO_COST_CENTER_ACCOUNTS_PENDING'

export const ODD_JOURNAL_TEMPLATE_LOAD = 'ODD_JOURNAL_TEMPLATE_LOAD'
export const ODD_JOURNAL_TEMPLATE_LOAD_FULFILLED = 'ODD_JOURNAL_TEMPLATE_LOAD_FULFILLED'
export const ODD_JOURNAL_TEMPLATE_LOAD_REJECTED = 'ODD_JOURNAL_TEMPLATE_LOAD_REJECTED'
export const ODD_JOURNAL_TEMPLATE_LOAD_PENDING = 'ODD_JOURNAL_TEMPLATE_LOAD_PENDING'

export const TRACKING_DIMENSION_PAIR_SUBSIDIARY = 'TRACKING_DIMENSION_PAIR_SUBSIDIARY'
export const TRACKING_DIMENSION_PAIR_SUBSIDIARY_FULFILLED =
  'TRACKING_DIMENSION_PAIR_SUBSIDIARY_FULFILLED'
export const TRACKING_DIMENSION_PAIR_SUBSIDIARY_REJECTED =
  'TRACKING_DIMENSION_PAIR_SUBSIDIARY_REJECTED'
export const TRACKING_DIMENSION_PAIR_SUBSIDIARY_PENDING =
  'TRACKING_DIMENSION_PAIR_SUBSIDIARY_PENDING'
