const LeaveManagement = {
  // A
  annual: 'Annual',
  annual_entitlement: 'Annual entitlement',
  annual_leave_details: 'Annual Leave details',
  add_new_request: 'Add new request',
  available_balance: 'Available balance',
  annual_leave_balance: 'Annual leave balance',
  attachment_url: 'Attachment url',
  approved: 'Approved',
  accommodation: 'Accommodation',
  applied_for: 'Applied for',
  applying_for_request: 'Applying for a request',
  advanced_vacation_salary: 'Advanced vacation salary',
  advanced_vacation_salary_v2: 'Advanced vacation salary',
  applicable_days_values: 'Applicable days {{days}} days employee balance {{pending_balance}}',
  annual_leave_entitlement: 'Annual leave entitlement',
  annual_leave_entitlement_description:
    'This is to define the number of entitlment days per year for the annual leave, which will appear when adding employee process',
  applied_days: 'Applied days',
  add_extensions_leave: 'Add extensions leave',
  all_years: 'All years',
  // B
  balance: 'Balance',
  balance_up_to_date: 'Balance up to date',
  business_trip_request: 'Business trip request',
  business_trip_details: 'Business trip details',
  balance_deducted: 'Balance deducted',
  booked_balance: 'Booked balance',
  // C
  can_be_used: 'Can be used',
  casual: 'Casual',
  carried_forward: 'Carried forward',
  confirm_deleting_leave: 'This will DELETE the leave and will not appears any more in the system',
  // D
  days_used: 'Days used',
  death_of_relative: 'Death of a relative',
  difference_in_days: 'Difference in days',
  delegation_request: 'Delegation request',
  delegation: 'Delegation',
  delegat_to: 'Delegat To',
  deducted_days: 'Deducted days',
  deducted_balance: 'Deducted balance',
  // E
  employee_leaves_details: 'Employee leaves details',
  employee_from: '{{employee_count}} out of {{count}}',
  exit_re_entry_request: 'Exit re-entry request',
  exit_re_entry_visa_request: 'Exit reentry visa request',
  exit_re_entry_visa: 'Exit reentry visa',
  exam: 'Exam',
  emergency: 'Emergency',
  exit_re_entry_type: 'Exit re-entry type',
  expected_return: 'Expected return',
  expected_return_date: 'Expected return date',
  extending_leave: 'Extending leave',
  extensions_leave_request: 'Extensions leave request',
  extend: 'Extend',
  early_return: 'Early return',
  // F
  from: 'From',
  file: 'File',
  // G
  // H
  // I
  ID: 'ID',
  include_user: 'Include user',
  is_re_entry_exit: 'Is re-entry exit',
  include: 'Include',
  // J
  // K
  // L
  leaves_management: 'Leaves management',
  leaves_planning: 'Leaves Planning',
  leaves_history: 'Leaves History',
  lorem_ipsum: 'Lorem ipsum dolor sit amet, consectetur',
  leave_type: 'Leave Type',
  departure_date: 'Departure Date',
  leave_date: 'Leave date',
  leave_request: 'Leave request',
  leave_duration: 'Leave duration',
  leave_request_details: 'Leave request details',
  leaves_used: 'Leaves used',
  leave_start_date: 'Leave start date (Gregorian)',
  leave_resumption: 'Leave resumption',
  leave_end_date: 'End date (Gregorian)',
  update_employee_balance: 'Update employee annual leave entitlement',
  // M
  maternity: 'Maternity',
  marriage: 'Marriage',
  multiple_leave: 'Multiple',
  manual_entry: 'Manual Entry',
  manual_entry_tooltip:
    'You can modify the employee up to date  balance by add or deduct from the defined manual entry value and that will effect on employee up to date balance directly.',
  manual_entry_tooltip_examp_add:
    'Examp :<br />Add : Employee up to date balance is  10 days.The manual entry is 0.The expected (Up to date the balance is 12).<br /> So, the manual entry value will be as a modification for 2 days.',
  manual_entry_tooltip_examp_deduct:
    'Deduct : Employee up to date balance is  10 days.The manual entry is 0.The expected (Up to date the balance is 8). So, the manual entry value will be as modification -2.',
  // N
  number_of_days: 'Number Of Days',
  new_business_trip_request: 'Business trip request',
  no_leave_history: 'You don’t seem to have any leave history.',
  no_scheduled_leaves: 'You don’t seem to have any scheduled leaves',
  // O
  other_leaves: 'Other Leaves',
  on_leave_for_this_month: 'On leave for this month',
  // P
  planning: 'Leaves Planning',
  pending: 'Pending',
  paternity: 'Paternity',
  // Q
  // R
  remaining_balance: 'Remaining Balance',
  returning_date: 'Returning date',
  reason: 'Reason',
  require_ticket: 'Require Ticket',
  resumption_date: 'Resumption date',
  resum: 'RESUME',
  // S
  scheduled_leaves: 'Scheduled Leave',
  sick_leave: 'Sick leave',
  sick: 'Sick',
  single_leave: 'Single',
  started_on: 'Started on',
  // T
  ticket_request: 'Ticket request',
  total_balance_up_to_end_of_year: 'Total expected balance up to end of this year',
  total_booked: 'Total booked balance',
  total_booked_tip:
    'The total booked balance is all applied annual leave days in the future and not deducted yet from employee balance.',
  total_days: 'Total Days',
  there_is_no_other_leaves_requested: 'There is no other leaves requested',
  total_type_leaves: 'Total {{type}} Leaves',
  // U
  used: 'Used',
  used_balance: 'Used balance',
  up_to_date_balance: 'Up to date Balance',
  unpaid: 'Unpaid',
  unpaid_leave_deduction: 'Unpaid leave deduction',
  // V
  // W
  weekend: 'Weekend',
  week: 'Week',
  // X
  xls: 'XLS',
  // Y
  YEARLY: 'YEARLY',
  year_end_balance: 'Year End Balance',
  // Z
}

export default LeaveManagement
