import React from 'react'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import PropTypes from 'prop-types'
import { ReactComponent as CalendarIcon } from '@jisr-hr/design-system/dist/assets/icons/Files/Documents/File/Text.svg'
import styles from './style.module.css'

const DocumentAvatar = ({ name, number, url, ...rest }) => (
  <div {...rest}>
    <Flex itemsCenter>
      <CalendarIcon className={styles.noShrink} />
      <Spacer width="8px" />
      <div>
        {name &&
          (url ? (
            <Typography
              text={
                <a
                  download
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {name}
                </a>
              }
              variant="body-new/regular"
            />
          ) : (
            <Typography
              text={name}
              variant="body-new/regular"
            />
          ))}
        {number && (
          <Typography
            text={number}
            variant="subtitle-2"
            style={{ color: 'var(--color-base-colors-black-600)' }}
          />
        )}
      </div>
    </Flex>
  </div>
)

DocumentAvatar.propTypes = {
  type: PropTypes.string,
  number: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
}

export default React.memo(DocumentAvatar)
