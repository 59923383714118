import { isEmpty, isNaN } from 'lodash'
import I18n from 'translations/i18n'
import {
  CHATBOT_SAVED_MESSAGES,
  CHATBOT_START_TIMESTAMP,
  HTML_REGEX,
  JISRI_SELECTED_LANGUAGE,
  SCRIPT_REGEX,
} from './constants'
import { MessageType } from './types'

const saveJisriChat = (messages: MessageType[], initialChatTimeStamp: string): void => {
  if (!messages) return

  localStorage.setItem(CHATBOT_SAVED_MESSAGES, JSON.stringify({ messages, initialChatTimeStamp }))
}

const removeJisriSavedChat = (): void => {
  localStorage.removeItem(CHATBOT_SAVED_MESSAGES)
}

const getJisriSavedChat = () => {
  const savedMessages = localStorage.getItem(CHATBOT_SAVED_MESSAGES)
  return savedMessages ? JSON.parse(savedMessages) : []
}

const saveChatStartTimestamp = (): void => {
  localStorage.setItem(CHATBOT_START_TIMESTAMP, new Date().toISOString())
}

const getChatStartTimestamp = (): string | null => {
  const timestamp = localStorage.getItem(CHATBOT_START_TIMESTAMP)
  if (timestamp) {
    return timestamp
  }
  return null
}

const isTimestampDifferenceGreaterThan24Hours = (
  timestamp1: string | number | Date,
  timestamp2: string | number | Date,
): boolean => {
  if (!timestamp1 || !timestamp2) return false

  const date1 = new Date(timestamp1)
  const date2 = new Date(timestamp2)

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
    return false
  }

  const diff = Math.abs(date1.getTime() - date2.getTime())
  const hours = Math.floor(diff / 36e5)

  return hours > 24
}

const getCurrentTimestamp = (): string => new Date().toISOString()

const getSelectedLanguage = (): string => {
  const savedLanguage = localStorage.getItem(JISRI_SELECTED_LANGUAGE)

  if (savedLanguage === 'ar' || savedLanguage === 'en') {
    return savedLanguage
  } else if (I18n.language === 'ar' || I18n.language === 'en') {
    return I18n.language
  }
  return 'ar'
}

const isValidMessage = (message: string): boolean => {
  if (isEmpty(message?.trim())) {
    return false
  }

  if (SCRIPT_REGEX.test(message) || HTML_REGEX.test(message)) {
    alert(I18n.t('communcation.script_not_allowed'))
    return false
  }
  return true
}

export {
  removeJisriSavedChat,
  saveJisriChat,
  getJisriSavedChat,
  getCurrentTimestamp,
  saveChatStartTimestamp,
  getChatStartTimestamp,
  getSelectedLanguage,
  isTimestampDifferenceGreaterThan24Hours,
  isValidMessage,
}
