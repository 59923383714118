import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { InitialValuesType } from 'containers/authorised/setting/OrganizationStructure/Departments/components/DepartmentForm'
import { isRtl } from 'utils/uiHelpers'
import * as actions from './actions'
import { Department, TSuggetionsDepartment } from './types'

type State = {
  employees_department: {
    avatar_thumb: string
    code: string
    id: number
    jobTitle: string
    name: string
  }[]
  isAutomatically: boolean
  fetching: boolean
  cloning: boolean
  first_loading?: boolean
  hierarchical_departments: Department[]
  list_departments: Department[]
  organization: {
    name_i18n?: string
    departments_count?: string
    active_employees_count?: string
  }
  initialDepartmentValues: InitialValuesType
  industry_templates: TSuggetionsDepartment[]
  basicListDepartments: Department[]
  build_type: 'hide_auto' | 'show_manual' | 'show_auto'
}

export const initialState: State = {
  initialDepartmentValues: {},
  first_loading: false,
  fetching: false,
  cloning: false,
  hierarchical_departments: [],
  list_departments: [],
  employees_department: [],
  organization: {},
  isAutomatically: false,
  industry_templates: [],
  basicListDepartments: [],
  build_type: 'hide_auto',
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.SET_INITIAL_VALUES:
      return {
        ...state,
        initialDepartmentValues: action.payload,
      }
    case actions.HIERARCHICAL_DEPARTMENTS_NEW_LOAD_PENDING:
    case actions.LIST_DEPARTMENTS_LOAD_PENDING:
    case actions.DEPARTMENTS_POST_PENDING:
    case actions.DEPARTMENTS_PUT_PENDING:
    case actions.DEPARTMENTS_DELETE_PENDING:
    case actions.BUILD_AUTOMATICALLY_PENDING:
    case actions.RESET_ALL_DEPARTMENT_PENDING:
    case actions.SUGGESTIONS_DEPARTMENT_LOAD_PENDING:
    case actions.BUILD_STATUS_LOAD_PENDING:
    case actions.ASSIGN_EMPLOYEES_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CLONE_PARENT_ORG_DEPARTMENTS_PENDING:
      return {
        ...state,
        cloning: true,
      }

    case actions.EMPLOYEES_DEPARTMENT_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        employees_department: [],
      }

    case actions.HIERARCHICAL_DEPARTMENTS_NEW_LOAD_FULFILLED:
      return {
        ...state,
        hierarchical_departments: action.payload.data.data.departments,
        organization: action.payload.data.data.organization,
        first_loading: true,
        fetching: false,
      }

    case actions.BASIC_LIST_DEPARTMENTS_LOAD_FULFILLED:
      return {
        ...state,
        basicListDepartments: action.payload.data.data.departments,
        fetching: false,
      }

    case actions.LIST_DEPARTMENTS_LOAD_FULFILLED:
      return {
        ...state,
        list_departments: action.payload.data.data.departments,
        fetching: false,
      }

    case actions.BUILD_STATUS_LOAD_FULFILLED:
      return {
        ...state,
        build_type: action.payload.data.data.build_type,
        fetching: false,
      }

    case actions.EMPLOYEES_DEPARTMENT_LOAD_FULFILLED:
      return {
        ...state,
        employees_department: action.payload.data.data.employees,
        fetching: false,
      }

    case actions.DEPARTMENTS_POST_FULFILLED:
    case actions.DEPARTMENTS_PUT_FULFILLED:
    case actions.DEPARTMENTS_DELETE_FULFILLED:
    case actions.ASSIGN_EMPLOYEES_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.CLONE_PARENT_ORG_DEPARTMENTS_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        cloning: false,
      }

    case actions.BUILD_AUTOMATICALLY_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SUGGESTIONS_DEPARTMENT_LOAD_FULFILLED:
      const industryTemplates: TSuggetionsDepartment[] = action.payload.data.data.industry_templates
      return {
        ...state,
        industry_templates: industryTemplates.map((i) => ({
          ...i,
          name_i18n: isRtl ? i.name_ar : i.name_en,
          name: i.name_en,
        })),
        fetching: false,
      }

    case actions.RESET_ALL_DEPARTMENT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.HIERARCHICAL_DEPARTMENTS_NEW_LOAD_REJECTED:
    case actions.LIST_DEPARTMENTS_LOAD_REJECTED:
    case actions.DEPARTMENTS_POST_REJECTED:
    case actions.DEPARTMENTS_PUT_REJECTED:
    case actions.DEPARTMENTS_DELETE_REJECTED:
    case actions.EMPLOYEES_DEPARTMENT_LOAD_REJECTED:
    case actions.ASSIGN_EMPLOYEES_PUT_REJECTED:
    case actions.BUILD_AUTOMATICALLY_REJECTED:
    case actions.RESET_ALL_DEPARTMENT_REJECTED:
    case actions.SUGGESTIONS_DEPARTMENT_LOAD_REJECTED:
    case actions.BASIC_LIST_DEPARTMENTS_LOAD_REJECTED:
    case actions.BUILD_STATUS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.CLONE_PARENT_ORG_DEPARTMENTS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        cloning: false,
      }

    default:
      return state
  }
}
