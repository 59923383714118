export function salaryDays() {
  const result = []
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 21; i++) {
    result.push({
      name: i,
      id: i,
    })
  }

  return result
}

export function minimumDays() {
  const result = []
  // eslint-disable-next-line no-plusplus
  for (let i = 5; i < 30; i++) {
    result.push({
      name: String(i),
      id: String(i),
    })
  }

  return result
}
