export const LOAD_DASHBOARD_LOCATION = 'LOAD_DASHBOARD_LOCATION'
export const LOAD_DASHBOARD_LOCATION_PENDING = 'LOAD_DASHBOARD_LOCATION_PENDING'
export const LOAD_DASHBOARD_LOCATION_FULFILLED = 'LOAD_DASHBOARD_LOCATION_FULFILLED'
export const LOAD_DASHBOARD_LOCATION_REJECTED = 'LOAD_DASHBOARD_LOCATION_REJECTED'

export const LOAD_ATTENDANCE_DASHBOARD_STEPS = 'LOAD_ATTENDANCE_DASHBOARD_STEPS'
export const LOAD_ATTENDANCE_DASHBOARD_STEPS_PENDING = 'LOAD_ATTENDANCE_DASHBOARD_STEPS_PENDING'
export const LOAD_ATTENDANCE_DASHBOARD_STEPS_FULFILLED = 'LOAD_ATTENDANCE_DASHBOARD_STEPS_FULFILLED'
export const LOAD_ATTENDANCE_DASHBOARD_STEPS_REJECTED = 'LOAD_ATTENDANCE_DASHBOARD_STEPS_REJECTED'

export const LOAD_ATTENDANCE_DASHBOARD_STATS = 'LOAD_ATTENDANCE_DASHBOARD_STATS'
export const LOAD_ATTENDANCE_DASHBOARD_STATS_PENDING = 'LOAD_ATTENDANCE_DASHBOARD_STATS_PENDING'
export const LOAD_ATTENDANCE_DASHBOARD_STATS_FULFILLED = 'LOAD_ATTENDANCE_DASHBOARD_STATS_FULFILLED'
export const LOAD_ATTENDANCE_DASHBOARD_STATS_REJECTED = 'LOAD_ATTENDANCE_DASHBOARD_STATS_REJECTED'

export const UPDATE_ATTENDANCE_DASHBOARD_STATS = 'UPDATE_ATTENDANCE_DASHBOARD_STATS'

export const COMPLETE_ATTENDANCE_DASHBOARD_STEPS = 'COMPLETE_ATTENDANCE_DASHBOARD_STEPS'
export const COMPLETE_ATTENDANCE_DASHBOARD_STEPS_PENDING =
  'COMPLETE_ATTENDANCE_DASHBOARD_STEPS_PENDING'
export const COMPLETE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED =
  'COMPLETE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED'
export const COMPLETE_ATTENDANCE_DASHBOARD_STEPS_REJECTED =
  'COMPLETE_ATTENDANCE_DASHBOARD_STEPS_REJECTED'

export const CREATE_ATTENDANCE_DASHBOARD_STEPS = 'CREATE_ATTENDANCE_DASHBOARD_STEPS'
export const CREATE_ATTENDANCE_DASHBOARD_STEPS_PENDING = 'CREATE_ATTENDANCE_DASHBOARD_STEPS_PENDING'
export const CREATE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED =
  'CREATE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED'
export const CREATE_ATTENDANCE_DASHBOARD_STEPS_REJECTED =
  'CREATE_ATTENDANCE_DASHBOARD_STEPS_REJECTED'

export const ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES = 'ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES'
export const ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_PENDING =
  'ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_PENDING'
export const ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_FULFILLED =
  'ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_FULFILLED'
export const ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_REJECTED =
  'ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_REJECTED'
