import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Button, Spacer } from '@jisr-hr/ds'
import { Form } from 'react-final-form'
import { TextInputField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'

const ChangePasswordForm = ({ onSubmit, isResetPassword, fetching }) => (
  <Form onSubmit={onSubmit}>
    {({ handleSubmit, invalid }) => (
      <form
        onSubmit={handleSubmit}
        style={{ padding: '20px 0 32px' }}
      >
        {!isResetPassword && (
          <TextInputField
            name="current_password"
            type="password"
            style={{ width: '100%' }}
            label={I18n.t('current_password')}
            validate={required}
          />
        )}

        <TextInputField
          name="password"
          type="password"
          style={{ width: '100%' }}
          label={I18n.t('new_password')}
          validate={required}
        />

        <TextInputField
          name="password_confirmation"
          type="password"
          label={I18n.t('confirm_password')}
          style={{ width: '100%' }}
          validate={required}
        />

        <Spacer height="10px" />

        <Button
          label={I18n.t('save')}
          onclick={handleSubmit}
          disabled={fetching || invalid}
          type="submit"
          style={{ width: '100%', justifyContent: 'center' }}
          className="blocked--centred-btn"
        />
      </form>
    )}
  </Form>
)

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  isResetPassword: PropTypes.bool,
}

export default ChangePasswordForm
