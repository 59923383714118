import { Lens } from '@dhmk/zustand-lens'
import { CategoryViolationRules } from 'containers/authorised/setting/v2/Organization/ViolationRules/ViolationRulesType'

type ViolationDrawerType =
  | 'record_violation'
  | 'report_violation'
  | 'respond_violation_case'
  | 'violations_and_penalties'
  | null

type EnableRulesModalT = CategoryViolationRules | null

export type ViolationStateT = {
  openRecordAndReportViolationDrawer: ViolationDrawerType
  activeViolationId: number
  enableRulesModal: EnableRulesModalT

  setOpenRecordAndReportViolationDrawer: (val: ViolationDrawerType) => void
  setActiveViolationId: (id: number) => void
  setEnableRulesModal: (val: EnableRulesModalT) => void
}

export const createViolationsSlice: Lens<ViolationStateT> = (set): ViolationStateT => {
  return {
    enableRulesModal: null,
    openRecordAndReportViolationDrawer: null,
    activeViolationId: NaN,

    setOpenRecordAndReportViolationDrawer: (val) =>
      set({ openRecordAndReportViolationDrawer: val }),
    setActiveViolationId: (val) => set({ activeViolationId: val }),
    setEnableRulesModal: (v) => set({ enableRulesModal: v }),
  }
}
