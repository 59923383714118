import { Typography, Flex } from '@jisr-hr/ds'
import styles from './styles.module.css'

type TBoxWithSwitch = {
  switchInput?: JSX.Element
  title: string
  content: JSX.Element
}

const BoxWithSwitch = ({ switchInput, title, content }: TBoxWithSwitch): JSX.Element => (
  <Flex
    flexCol
    className={styles.boxWithSwitch}
  >
    <Flex
      justifyBetween
      itemsCenter
      className={styles.header}
    >
      <Typography
        variant="title-1"
        text={title}
      />

      {switchInput && switchInput}
    </Flex>
    <div className={styles.content}>{content}</div>
  </Flex>
)

export default BoxWithSwitch
