import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type FileTypeExportHistoryTypes = {
  filterKey: string
  filters: string[]
}

const FileTypeExportHistory = (props: FileTypeExportHistoryTypes): JSX.Element => {
  const { file_type_export_history } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(file_type_export_history)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        export_format: [],
      })
    } else {
      handleFilter('export_format', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.export_format)) {
      setSelectedIds(filter.export_format)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.export_format])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
    />
  )
}

export default FileTypeExportHistory
