const Request = {
  // A
  active_loans: 'Active loans',
  add_resumption: 'Add resumption',
  advanced: 'Advanced',
  approve: 'Approve',
  approve_a: 'Approve',
  add_comment: 'Add comment',
  approve_request: 'Approve Request',
  approved_from: 'Approved from',
  archives: 'Archives',
  all_requests: 'All Requests',
  asset_request: 'Asset request',
  asset_item: 'Asset Item',
  asset_items: 'Asset Items',
  asset_clear_request: 'Asset clear request',
  asset_cancellation_request: 'Asset cancellation request',
  asset_clear_cancellation_request: 'Asset clear cancellation request',
  asset_clear: 'Asset clear',
  approval_process: 'Approval Process',
  approver: 'Approver',
  approved_by: 'Approved by',
  approved_business_hours: 'Approved business hours',
  approved_personal_hours: 'Approved personal hours',
  attendance: 'Attendance',
  ATTENDANCE: 'ATTENDANCE',
  approved_date: 'Approved date',
  accept: 'Accept',
  attachment_file: 'Attachment file',
  attendance_extra_hours: 'Attendance Extra hours',
  attendance_overview: 'Attendance overview',
  all_Documents: 'All Documents ({{doc}})',
  all_Requests: 'All Requests ({{request}})',
  all_expiring_documents: 'All Expiring Documents',
  are_you_really_sure: 'Are you really sure ?',
  attendance_is_not_yet_approved_this_employee: 'Attendance is not yet approved for this employee',
  ask_for_attendance_approval: 'Ask for attendance approval',
  attendance_period_to_be_approved: 'Attendance period to be approved:',
  are_you_sure_that_you_want_confirm_vacation_settlement:
    'Are you sure that you want to confirm the vacation settlement?',
  are_you_sure_that_you_want_confirm_final_settlement:
    'Are you sure that you want to confirm the final settlement?',
  are_you_sure_that_you_want_cancel_vacation_settlement:
    'Are you sure that you want to cancel the vacation settlement ?',
  are_you_sure_that_you_want_cancel_final_settlement:
    'Are you sure that you want to cancel the final settlement ?',
  available_to_use: 'Available to use',
  additional_services: 'Additional services',
  additional_needs: 'Additional needs',
  available_to_use_up: 'Available to use = Up to date balance',
  asset_clear_type: 'Asset clear type',
  asset_clear_details: 'Asset clear details',
  approved_request: 'Approved',
  all_announcement_will_be_sent_to_mobile_app: 'All announcement will be sent to the mobile app',
  approvers_platform: 'Approvers',
  add_approver_platform: 'Add Approver',
  add_field: 'Add Field',
  arabic_field_title: 'Arabic field title',
  arabic_start_date_title: 'Arabic Start Date Title',
  arabic_end_date_title: 'Arabic End Date Title',
  already_added_cannot_be_added_again: 'Already added, cannot be added again',
  assignee_details: 'Assignee Details',

  // B
  business_trip_cancellation_request: 'Business trip cancellation request',
  business_trip_duration: 'Business Trip duration',
  business_trip_policy: 'Business Trip policy',
  bank_iban: 'Bank IBAN',
  browse_templates: 'Browse Templates',

  // C
  cancel_request: 'Cancel Request',
  can_not_submit_leave_on_an_off_day:
    'Can’t submit a leave on an off day, either select another day or cancel and go back.',
  can_not_submit_leave_on_an_unscheduled_day:
    'Can’t submit a leave on an unscheduled day, either select another day or cancel and go back.',
  cancelltion_request: 'Cancel Request',
  cancellation_request: 'Cancellation request',
  cancelled_by: 'Cancelled by',
  comments_required: 'Comments required',
  created_at: 'Created at',
  cancel_subscription: 'Cancel Subscription',
  contact_support: 'Contact with customer Success',
  customer_success_manager_details: 'Customer Success Manager Details',
  check_device_connectivity: 'Check device connectivity',
  contracts_and_documents_about_to_expire: 'Contracts and Documents about to expire',
  clock_in: 'Clock in',
  clock_out: 'Clock out',
  confirm_return: 'Confirm Return',
  cancelation_requested_by: 'Cancelation requested by',
  cancel_request_msg: 'Are you sure to cancel this request?',
  completed_by: 'Completed by',
  copy_request_link: 'Copy request link',
  cut_off_from_date: 'Cut off from date',
  current_month_overtime_hours: 'Current month overtime hours',
  cut_off_to_date: 'Cut off to date',
  contract_no: 'Contract',
  configure_bank_file: 'Configure Bank File',
  configure_general_ledger: 'Configure General Ledger',
  carry_forward_balance_notification:
    'Please be sure about the "Carry forward balance" Policy before end of year  to avoid the balances issues.',
  confirm_the_final_settlement: 'Confirm The Final Settlement ',
  confirm_the_vacation_settlement: 'Confirm The Vacation Settlement ',
  confirm_return_description: 'Confirm return from leave to send a request to the management',
  count_family_members: 'Family members ({{number}})',
  changes_on_attendance_record_if_request_got_approved:
    'Changes on attendance record if request got approved',
  cancelled_request: 'Cancelled',
  corrections_can_requested_for_scheduled_shifts:
    'Correction can only be requested for scheduled shifts. Select a different date or contact your admin for assistance.',
  choose_which_fields_will_appear_description:
    'Choose which fields will appear to employees when sending the request',
  create_approval_cycles_for_this_request_in_order:
    'Create approval cycles for this request in order for employees to be able to use it.',
  create_workflow: 'Create',
  custom_requests_is_not_in_your_plan: 'Custom Requests is not in your plan',
  clear_date: 'Clear Date',
  custom_requests: 'Custom Requests',
  cancel_immediately_one_request: 'Cancel Immediately 1 Request',
  cancel_immediately_two_requests: 'Cancel Immediately 2 Requests',
  cancel_immediately_selected_requests_plural_ar: 'Cancel Immediately {{count}} Requests',
  cancel_immediately_selected_requests_singular_ar: 'Cancel Immediately {{count}} Requests',
  can_select_more_than_one: 'Can select more than 1',
  custom_tasks: 'Custom Tasks',
  create_custom_task: 'Create Custom Task',
  creation_date: 'Request date',
  custom_tasks_is_not_in_your_plan: 'Custom tasks is not in your plan',
  communicate_recorded_violation_task: 'Communicate recorded violation',

  // D
  document_request: 'Document request',
  days_deducted: 'Days deducted',
  download_file: 'Download File',
  directed_to_ar: 'Subjected to (AR)',
  directed_to_en: 'Subjected to (EN)',
  days_left: 'Days left',
  DAYS_LEFT: 'DAYS LEFT',
  dont_miss_it: 'Don’t miss it',
  destination: 'Destination',
  device_name: 'Device name',
  document_type: 'Document type',
  deduction_starting_month: 'Deduction starting month',
  date_of_missing_punch: 'Date of Incomplete record',
  date_expected: 'Date And Huors worked ',
  delegate_to: 'Delegate to',
  delegator: 'Delegator',
  date_of_last_request: 'Date of last request',
  doc_days: '{{days}} days',
  download_invoice: 'Download Invoice',
  do_you_want_include_attendance_transactions: 'Do you want to include attendance transactions?',
  do_you_want_include_attendance_transactions_final:
    'Do you want to include attendance transactions?',
  departure_date: 'Departure Date',
  dec: 'Dec',
  daily_allowance: 'Daily allowance',
  delegation_to: 'Delegation to',
  days_number: '{{number}} Days',
  days: 'days',
  delegator_to: 'Delegator to',
  delegation_duration: 'Delegation duration',
  delegation_details: 'Delegation details',
  delegation_alert:
    'All requests will be delegated except payroll review, final settlement review, and vacation settlement review',
  drag_and_drop_file: 'Drag-and-drop file, or ',
  disable: 'Disable',
  des_delete_request: 'Are you sure you want to delete this request? This action cannot be undone.',
  des_delete_task: 'Are you sure you want to delete this task? This action cannot be undone.',
  des_disable_request:
    'Are you sure you want to disable {{name}}?Employees will not be able to submit this request',
  disable_request: 'Disable Request',
  des_delete_workflow: 'Are you sure you want to delete {{name}}?',
  deleted_requests: 'Deleted requests',
  do_you_want_to: 'Do you want to',
  disabled_requests: 'Disabled Requests',
  disabled_tasks: 'Disabled Tasks',
  default_assignee: 'Default Assignee',
  description_details: 'Details',

  // E
  empty: 'Empty',
  excuse_cancellation_request: 'Excuse cancellation request',
  exit_reentry_visa_request: 'Exit reentry visa request',
  exit_reentry_visa_cancellation_request: 'Exit reentry visa cancellation request',
  expense_claim_cancellation_request: 'Expense claim cancellation request',
  exit_re_enter_payment: 'Exit Re-enter Payment',
  excuse_date: 'Excuse date',
  final_settlement: 'Final Settlement',
  excuse_hours: 'Excuse hours',
  excuse_type: 'Excuse type',
  expens_claim_items: 'Expense claim items',
  entry_type: 'Entry Type',
  employees_with_cash: 'Employees with cash',
  employees_with_bank: 'Employees with bank',
  employees_with_international_transfer: 'Employees with international transfer',
  employees_with_cheque: 'Employees with cheque',
  employees_with_direct_transfer: 'Employees with direct transfer',
  expiring_contracts___documents: 'Expiring contracts & Documents',
  employees_without_email: 'Employees without Email',
  employees_have_never_logged_in_to_jisr: 'Employees have never logged in to {{app_name}}',
  expired_by: 'Expired by',
  employees_from_the_same_team_have_leaves: 'Employees from the same team have leaves',
  exit_reentry_type: 'Exit Re-entry Type',
  employees_number: 'Employees number',
  ending_date: 'Termination Date',
  employees_getting_paid: 'Employees Getting Paid',
  employee_no_email_msg: '{{count}} Employees without Email',
  end_date_not_defined_yet: 'End date not defined yet  ',
  employee_joining_date: 'Employee Joining date',
  enter_time_manually: 'Enter time manually',
  probation_period: 'Probation Period',
  exit_re_entry_visa: 'Exit-re-entry visa',
  employee_assets_allocated: 'Employee assets',
  end: 'End',
  edit_time: 'Edit Information',
  expired_request: 'Expired',
  employees_will_be_able_to_sendـdescription:
    'Employees will be able to send this request with empty content',
  employees_reasonـdescription: 'Employees will be able to add a reason to this request.',
  employees_dateـdescription: 'Employees will be able to specify a date for the request.',
  employees_attachmentsـdescription: 'Employees will be able to add attachments to the request',
  end_date_request: 'End Date {{optional}}',
  employees_cant_submit_this_type_of_requests: 'Employees Can’t Submit This Type of Requests',
  eligible_employees: 'Eligible Employees',
  employees_will_be_moved_from_description:
    'Employees will be moved from other workflows to this workflow',
  enable: 'Enable',
  english_field_title: 'English field title',
  english_start_date_title: 'English Start Date Title',
  english_end_date_title: 'English End Date Title',
  employee_beneficiary: 'Employee Beneficiary',

  // F
  family_members: 'Family members',
  first_half: '1st half ({{from}}-{{to}})',
  first_half_approval: 'first half',
  first_day_off: 'First day off',
  first_day_to_work: 'First day to work:',
  from_annual_leave_balance: 'from annual leave balance',
  for_a_Day_or_more_annual_leaves: 'For a Day or more annual leaves',
  fingerprint_machines_are_offline: 'Fingerprint machines are offline',
  finance_requests: 'Finance Requests',
  finance_filter: 'Finance',
  finance: 'Finance',
  from_time: 'From time',
  family_members__ticket: 'Family Members (Ticket)',
  family_members__exit_reentry: 'Family Members (Exit Re-entry)',
  food: 'Food',
  for_one_employee: 'for 1 employee?',
  for_two_employees: 'for 2 employees?',
  for_employees_count_plural_ar: 'for {{count}} employees?',
  for_employees_count_singular_ar: 'for {{count}} employees?',
  flight_ticket_request: 'Flight Ticket Request',
  flight_ticket_cancellation_request: 'Flight Ticket Cancellation Request',

  // G
  gosi_amount: 'Gosi amount',
  go_to_request: 'Go to request',
  // H
  hiring_month: 'Hiring month',
  half_day_leave: 'Half day leave',
  half_day_leave_is_not_available_on_days_with_more_than_one_shift:
    'half day leave is not available on days with more than one shift, select another day with only one shift or cancel and go back.',
  having_trouble: 'HAVING TROUBLE?',
  hrs: ' Hrs',
  date_and_expected_worked_hours: 'Date and expected worked hours',
  hiring_cancellation_request: 'Hiring cancellation request',
  hiring_details: 'Hiring details',
  hm: 'h:m',
  // I
  is_require_vacation_salary: 'Is require vacation salary',
  in_out: 'In & Out',
  in_city: 'In',
  items: 'Items',
  info_change: 'Info Change',
  immediately: 'Immediately',

  // eslint-disable-next-line max-len
  in_order_include_attendance_transactions_attendance_hould_reviewd_approved_first_for_this_employee:
    'In order to include attendance transactions. Attendance Should be reviewed & approved first for this employee.',
  installments_ends: 'Installments Ends',
  installment: 'Installment',
  information_changes: 'Information changes',
  installment_calculation: 'Installment Calculation',
  item_name: 'Item name',
  // J
  joined_this_month: 'Joined This Month',
  jan: 'Jan',
  // K
  keep_request: 'Keep Request',

  // L
  loading: 'Loading',
  load_more: 'Load More',
  leave_days: 'Leave days',
  leave_from_same_team: 'Leave from same team',
  leaves_remaining: 'Leaves remaining',
  leaving_day: 'Leaving day',
  leave_resumption_overdue: 'Leave resumption overdue',
  leave_overview: 'Annual leaves overview',
  leave_overview_description: 'Those employees are not resumed from leave yet',
  last_connectivity: 'Last connectivity',
  last_overtime_on: 'Last overtime on',
  last_overtime_expected_hours: 'Last overtime expected hours',
  last_request: 'Last request',
  loan_active: 'Active loan',
  last_applied_loan: 'Last applied loan',
  letter_type: 'Letter type',
  last_request_expected_hours: 'Last request expected hours',
  leave_from_same_team_with_value: 'Leave from same team ({{number}})',
  letter_details: 'Letter details',
  loan_details: 'Loan details',
  loan_type_with_number: 'Loan Type ({{number}})',
  leave_from_same_team_with_number: 'Leave from same team ({{number}})',
  leaves_on: 'Leaves on',
  leave_cancellation_request: 'Leave cancellation request',
  leave_resumption_cancellation_request: 'Leave resumption cancellation request',
  leave_resumption_request: 'Leave resumption request',
  loan_cancellation_request: 'Loan cancellation request',
  leave_to_date: 'Leave to date',
  last_day_off: 'Last day off',
  leaves_summary: '{{employee_name}} leaves summary',
  // M
  mark_as_completed: 'Mark as Completed',
  missed_time: 'Missed time',
  months: 'Months',
  my_requests: 'My Requests',
  my_tasks: 'My Tasks',
  month_excuse_hours: 'Month excuse hours',
  month_overtime_hours: 'Month overtime hours',
  month_for__months: 'Month for {{noi}} Months',
  month_overtime_cost: 'Month overtime cost',
  missing_punches: 'Incomplete records',
  more_details: 'More Details',
  more_approvers: '{{count}} more approvers',
  multiple_days: 'Multiple days',
  multiple_days_range: 'Multiple days (range)',
  more_people: '{{count}} more people',
  missing_punch_cancellation_request: 'Punch Correction cancellation request',
  multiple_choice: 'Multiple choice',
  multiple_choices: 'Multiple choices',
  multiple_choices_title: 'Multiple Choices Title',

  // N
  no_comments: 'No comments',
  no_shifts_scheduled: 'No shifts scheduled on this day',
  no_tasks: 'No tasks',
  next_payroll_in: 'Next payroll in',
  no_requests: 'It seems no requests for you today .You can check the status of requests.',
  no_tasks_msg: 'It seems no tasks for you today .You can check the status of requests.',
  no_summary: 'No summary',
  number_of_tickets: 'Number of tickets',
  number_of_installments: 'Number of installments',
  notifiction: 'Notification',
  not_received_yet: 'Not received yet',
  not_reviewed_yet: 'Not reviewed yet',
  not_using_jisr: 'Not Using {{app_name}}',
  no_go_back: 'No. Go Back',
  net_end_of_service: 'Net End Of Service',
  note_once_the_final_settlement_confirmed_you_not_able_undo_offboarding_employee:
    'Make sure you`ve reviewed the final settlement. You will not be able to make any changes Or Undo the offboarding for the employee once it`s confirmed!',
  note_once_the_vacation_settlement_confirmed_you_not_able_update_or_cancel_it:
    'Make sure you`ve reviewed the vacation settlement. You will not be able to make any changes once it`s confirmed!',
  note_the_system_will_un_do_offboarding_employee:
    'Note: the system will un-do offboarding the employee',
  note_related_leave_request_wouldn_cancelled_due_this:
    'Note: the related leave request wouldn`t be cancelled due to this',
  needed_before: 'Needed before',
  new_request: 'New request',
  no_shift_scheduled_on_selected_date: 'No shift scheduled on the selected date.',
  new_workflow_request: 'New Workflow',
  new_request_platform: 'New Request',
  new_approvers_cycle: 'New Approvers Cycle',
  new_approver: 'New Approver',
  new_task: 'New Task',

  // O
  out_of: 'out of',
  on_what_day: 'On what day:',
  on_what_time: 'On what time:',
  offline_fingerprint_machines: '{{num}} Offline fingerprint machines',
  offline: 'Bank',
  offline_method: 'Bank Processing',
  on_trip: 'On Trip',
  off_boarded_this_month: 'Off-boarded This Month',
  other_documents: 'Other documents',
  ok_request_attendance_approval: 'Ok,Request attendance Approval',
  overtime_duration: 'Overtime duration',
  other_request: 'Other request',
  overtime_cancellation_request: 'Overtime cancellation request',
  original_request: 'Original request',
  one_request: '1 Request',
  overlapped_period: 'Overlapped period',
  overlapped_period_msg:
    'The salary for leave from {{from}} to {{to}}, is already included in an existing vacation settlement. For any additional days, request advance vacation salary separately. ',
  // P
  period: 'period',
  pending_from: 'Pending from',
  pending_on_me: 'Pending on',
  pending_on_others: 'Pending on others',
  pending_for_correction: 'Pending correction',
  please_type_comment: 'please type comment',
  policy_configuration: 'Policy configuration',
  policy_configuration_setting: 'Policy configuration',
  position: 'Position',
  pending_by: 'Pending by',
  previous: 'Previous',
  per_diem_total: 'Per diem total',
  punch_type: 'Punch Type',
  processed_absent: 'Processed Absent',
  processed_weekend: 'Processed Weekend',
  processed_holiday: 'Processed Holiday',
  processed_on_trip: 'Processed On Trip',
  profile_picture: 'Profile picture',
  payroll_due_date: 'Payroll Due Date',
  play_video: 'Play Video',
  phone: 'Phone',
  reassign_tasks: 'Reassign Tasks',
  proceed_anyway: 'Proceed anyway!',
  policy_for: 'policy for',
  passed_through: 'Passed through {{count}} others',
  proposed_time: 'Proposed time',
  period_of_deduction: 'Period of deduction',
  passed_through_others: 'passed through {{count}} others',
  payment_info: 'Payment Info',
  propose_clock_in_time_first: 'Propose clock-in time first',
  // Q
  // R
  return_on: 'Return on',
  request_actions: 'Actions',
  request_approved_successfully: 'Request approved successfully',
  request_id: 'ID',
  reject: 'Reject',
  reject_request: 'Reject Request',
  rejected_from: 'Rejected from',
  request_rejected_successfully: 'Request rejected Successfully',
  request_cancellation_successfully: 'Request Cancelled Successfully',
  request_on_descending: 'Sort by: Request on descending',
  received_on_descending: ' Sort by:Received on descending ',
  request_on_ascending: 'Sort by: Request on ascending',
  received_on_ascending: ' Sort by:Received on ascending ',
  resignation_request: 'Resignation request',
  resignation: 'Resignation',
  resignation_requests: 'Resignation requests',
  request_attendance_approval: 'Request Attendance Approval',
  resignation_reason: 'Resignation reason',
  requests_home: 'Requests',
  rejected_reason: 'Rejected reason',
  request_link_copied: 'Request Link Copied!',
  request_status: 'Request status',
  request_date: 'Request date',
  request_details: 'Request details',
  rejected_by: 'Rejected by',
  received_at: 'Received at {{date}}',
  require_vacation_salary: 'Require Vacation Salary',
  require_exit_re_entry: 'Require Exit Re-entry',
  run_payroll: 'Run Payroll',
  reach_out: 'Reach Out',
  requests_menu: 'Requests',
  received: 'Received',
  received_on: 'Received on',
  received_on_2: 'Received on',
  reviewed_on: 'Reviewed on',
  required: 'Required',
  renew_subscription: 'Renew Subscription',
  payroll_review_request: 'Payroll Review Request',
  resume_working: 'Resume Working',
  resume_working_description: 'Resuming work will add remaining leave days to annual leave balance',
  return_date: 'Return Date',
  select_destination: 'Select destination',
  select_services: 'Select services',
  requested_flight_ticket: 'Requested flight ticket',
  requested_overtime: 'Requested overtime',
  recent_loans: 'Recent Loans',
  request_for: 'Request for',
  resignation_cancellation_request: 'Resignation cancellation request',
  reassign_task: 'Reassign task',
  rejected_request: 'Rejected',
  request_name: 'Request Name',
  request_name_english: 'Name in English',
  request_upgrade: 'Request Upgrade!',
  results: 'Results',
  requests_approved_for_employees:
    '{{request_count}} Requests approved for {{employee_count}} employees',
  requests_rejected_for_employees:
    '{{request_count}} Requests rejected for {{employee_count}} employees',
  request_is_approved_and_cant_be_changed: 'Request is Approved and can’t be changed',
  request_is_expired_and_cant_be_changed: 'Request is Expired and can’t be changed ',
  request_is_canceled_and_cant_be_changed: 'Request is Canceled and can’t be changed ',
  request_is_rejected_and_cant_be_changed: 'Request is Rejected and can’t be changed',
  request_statuses: 'Status',
  request_types: 'Request Type',

  // S
  share: 'Share',
  second_half: '2nd half({{from}}-{{to}})',
  second_half_approval: 'second half',
  share_announcement: 'Share Announcement',
  select_a_range: 'Select a range',
  summery: 'Summery',
  saudi_city: 'City',
  saudi_postal_code: 'Postal code',
  say_welcome_to_your_new_employees: 'Say welcome to your new employees',
  services_requested: 'Services requested',
  sorry_attendance_approval_required_confirm_vacation_settlement:
    'Sorry ! attendance approval is required to confirm the vacation Settlement.',
  sorry_attendance_approval_required_confirm_final_settlement:
    'Sorry ! attendance approval is required to confirm the final Settlement.',
  select_members: 'Select members',
  subjected_to_en: 'Subjected to (EN)',
  subjected_to_ar: 'Subjected to (AR)',
  selected_duration_exceeds_your_balance:
    'Selected duration exceeds your balance “Available to use”',
  select_delegator: 'Select delegator',
  select_type: 'Select type',
  sent: 'Sent',
  select_the_recorded_punches: 'Select the recorded punches that you want to correct',
  shift_not_yet_ended: 'Shift not yet ended',
  start_date_request: 'Start Date {{optional}}',
  select_date: 'Select date',
  specific_employees: 'Specific Employees',
  singleـdate: 'Single Date',
  selected_requests_singular_ar: '{{count}} Requests',
  selected_requests_plural_ar: '{{count}} Requests',
  select_only_limit_requests_at_once: "You can't select more than 50 requests",
  something_went_wrong_please_try_again: 'Something went wrong, please try again',
  something_went_wrong: 'Something went wrong',
  something_went_wrong_with_some_requests_please_try_again:
    'Something went wrong with some requests, please try again',
  self: 'Self',
  start_end_dates: 'Start & End Dates',
  select_employee_beneficiary: 'Select employee beneficiary',
  select_task_type: 'Select task type',
  select_default_assignee: 'Select default assignee',

  // T
  total_expected_hours: 'Total expected hours',
  this_will_be_deducted_from_your_annual_leave_balance:
    'This will be deducted from your annual leave balance',
  total_cost_of_hours: 'Total cost of hours',
  total_pending_tasks: 'Total pending tasks',
  total_pending_requests: 'Total pending requests',
  total_cost_of_requested_hours: 'Total cost of requested hours',
  total_requested_hours: 'Total requested hours',
  Tasks: 'Tasks',
  tasks_categories: 'Tasks Categories',
  task_updated_successfully: 'Task Updated Successfully',
  trip_date: 'Trip date',
  ticket_count: 'Ticket count',
  tickets: 'Tickets',
  ticket_issue: 'Ticket Issue',
  ticket_payment: 'Ticket Payment',
  travellers: 'Travellers',
  total_per_diem: 'Total per diem',
  total_bill: 'Total bill',
  total_amount: 'Total amount',
  total_this_month: 'Total this month',
  total_this_year: 'Total this year',
  total_per_month: 'Total per month',
  total_per_year: 'Total per year',
  time_requested: 'Time requested',
  total_excused_time_this_month: 'Total excused early departure time this month',
  total_approved_excuse_personal: 'Total approved excuse Personal',
  total_approved_excuse_business: 'Total approved excuse Business',
  to_time: 'To time',
  today: 'Today',
  total_delay_this_month: 'Total delay this month',
  total_shortage_this_month: 'Total early departure this month',
  total_delay_recorded: 'Total delay recorded',
  total_shortage_recorded: 'Total early departure recorded',
  total_expense_claim: 'Total Expense Claim',
  total_overview_pending: '{{num}} requests waiting for your action',
  total_employees: 'Total Employees',
  termination_date: 'Termination Date',
  termination_reason: 'Termination Reason',
  transportation: 'Transportation',
  total_requested: 'Total requested',
  team_requests: 'Team Requests',
  this_balance_is_what_will_be:
    'This balance is what WILL be “Available to use” by the end of the requested leave period <Text>({{date}}).</Text>',
  the_proposed_time_overlaps_before:
    'The proposed time overlaps with other recordes. You may propose any time before <span>{{max}}</span>',
  the_proposed_time_overlaps_after:
    'The proposed time overlaps with other recordes. You may propose any time after <span>{{min}}</span>',
  the_proposed_time_overlaps_between:
    'The proposed time overlaps with other recordes. You may propose any time Between <span>{{min}}</span> and <span>{{max}}</span>',
  the_proposed_time_overlaps:
    'The proposed {{field}} overlaps with other recordes. You may propose any time {{before_after}} <span>{{max}}</span>',
  traveler: 'Travelers',
  this_is_what_your_employeesـdescription:
    'This is what your employees will see when creating this request',
  this_request_doesnt_require_additional_info: "This request doesn't require additional info",
  two_requests: '2 Requests',
  the_request: '1 Request',
  text_field_title: 'Text Field Title',
  task_type: 'Task Type',

  // U
  days_used_this_year: 'Days used this year',
  used_this_year: 'Used Leave this Year',
  unprocessed_absent: 'Unprocessed Absent',
  unprocessed_weekend: 'Unprocessed Weekend',
  unprocessed_holiday: 'Unprocessed Holiday',
  unprocessed_on_trip: 'Unprocessed On Trip',
  unscheduled_days_within_selected_period:
    '{{count}} unscheduled days within the selected leave period will be deducted from the leave balance.',
  // V
  vaction_salary_payment: 'Vaction Salary Payment',
  view_summary: 'View summary',
  view_settlement_report: 'View Settlement Report',
  view_travellers: 'View Travellers',
  visa_type: 'Visa type',
  view_last_report: 'View last report',
  view_profile: 'View profile',
  view_attendance: 'View Attendance',
  view_attendance_details: 'View attendance details',
  view_employees: 'View Employees',
  video_guide: 'Here’s a quick video guide to help you learn Payroll',
  visa_period: 'Visa period',
  view_details_over: 'View details',
  // W
  without_email: 'Without email',
  wait_for_approval: 'Wait for approval',
  workflow_and_comments: 'Approvals and Comments',
  workflow_arabic: 'Arabic',
  workflow_english: 'English',
  workflow_details: 'Workflow Details',
  workflow_title: 'Request with the same name in {{language}} already exists',
  workflow_title_des: 'Choose another name for your new request',
  // X
  // Y
  you_dont_have_any_request: "You don't have any request",
  you_have_a_shift_on_this_day: 'You have a shift on this day',
  you_have_two_shifts: 'You have Two shifts on this day',
  you_are_off_on_this_day: 'You’re off on this day',
  you_will_be_off_for: 'You’ll be off for',
  your_shift_is_from: 'Your shift is from {{from}} to {{to}}',
  you_have_no_tasks: 'You have no tasks',
  you_have_no_pending_requests: 'You have no pending requests',
  you_have_no_pending_tasks: 'You have no pending tasks',
  year_excuse_hours: 'Year excuse hours',
  year_overtime_hours: 'Year overtime hours',
  yesterday: 'Yesterday',
  year_overtime_cost: 'Year overtime cost',
  yes_cancel_our_subscription: 'Yes, cancel our subscription',
  you_have_askedto_approve_the_attendance_employee_yet_approved:
    'You have asked to approve the attendance for this employee,but it is still not yet approved',
  you_cant_confirm_vacation_settlement_until_the_attendance_period_approved:
    'You can`t confirm the vacation settlement until the attendance for this period <b>{{month}}</b> is approved',
  you_cant_confirm_final_settlement_until_the_attendance_period_approved:
    'You can`t confirm the final settlement until the attendance for this period <b>{{month}}</b>  is approved',
  you_can_upgrade_your_plan_to_get_this_feature_and_many_more:
    'You can upgrade your plan to get this feature and many more!',
  you_cant_take_action_on_this_request: "You can't take action on this request",
  you_arent_one_of_requests_approvers: 'You aren’t one of request’s approvers',
  you_dont_have_pending_requests: "You don't have any pending requests",
  your_requests: 'Your Requests',
  you_are_not_eligible_to_apply_for_requests: 'You are not eligible to apply for requests',
  you_cannot_request_type_for_half_leave: 'You cannot request a {{type}} for a half day leave',
  // Z
  hr_requests: 'HR Requests',
  attendance_requests: 'Attendance Requests',
}

export default Request
