import React from 'react'
import PropTypes from 'prop-types'
import stoc from 'string-to-color'
import MuiAvatar from '@material-ui/core/Avatar'

const Avatar = ({ src, name, avatarStyle, className }) => {
  const firstLetter = `${name?.split(' ')[0]?.[0]?.toUpperCase()}`
  return (
    <MuiAvatar
      style={{ ...avatarStyle, backgroundColor: stoc(name) }}
      src={src}
      className={className}
      alt={name}
    >
      {firstLetter}
    </MuiAvatar>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  avatarStyle: PropTypes.shape(),
}

export default Avatar
