import React from 'react'
import { useSelector } from 'react-redux'
import { SelectField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import I18n from 'translations/i18n'
import { replaceAmPm } from 'components/global/form/formNormalize'

import { useRequest } from '../../RequestProvider'

const SelectShifts = () => {
  const { vars } = useRequest()
  const { attendance_record } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )
  const { change } = useForm()
  const { values } = useFormState()
  const record = attendance_record?.[0]

  const shifts = record?.employee_shifts?.map((shift) => ({
    value: shift.id,
    label: (
      <>
        {replaceAmPm(shift.shift_start_time)} - {replaceAmPm(shift.shift_end_time)}
      </>
    ),
  }))

  React.useEffect(() => {
    change('shift', values.initialShiftId || vars?.shiftId || shifts?.[0]?.value)
    change('attendance_record_id', record?.id)
  }, [record])

  return shifts?.length > 0 ? (
    <SelectField
      name="shift"
      label={I18n.t('shift')}
      disabled={shifts?.length < 2 || !shifts || vars?.shiftView}
      options={shifts}
    />
  ) : (
    <></>
  )
}

export default SelectShifts
