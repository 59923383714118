import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function getEnablerData(): Action {
  return {
    type: actions.GET_STATUS_ALLOWED,
    payload: handleAPI(`/${API_VERSION_TWO}/integrations/enablement`, {}, 'GET'),
  }
}
