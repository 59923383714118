import { Spacer } from '@jisr-hr/ds'
import { Table } from '@jisr-hr/design-system'
import { Form, TextInputField, DatePickerDSField, SelectField } from 'components/final-form'
import { TLeaves } from 'redux/leavesOnboarding/leave/reducer'
import { TEmployeeLeaveDays } from 'redux/leavesOnboarding/annualLeaveEntitlements/reducer'

import I18n from 'translations/i18n'

import { ReactComponent as CheckIcon } from 'assets/images/icon-check-002.svg'
import { ReactComponent as Deletecon } from 'assets/images/icon-delete-003.svg'
import { required } from 'components/global/form/FormValidations'

import styles from '../style.module.css'

type TFormTable = {
  onSubmit: (data: TLeaves) => void
  initialValues: TLeaves
  onClose: (t: null) => void
  employee_leave_days: TEmployeeLeaveDays[]
}

const FormTable = ({
  onSubmit,
  initialValues,
  onClose,
  employee_leave_days,
}: TFormTable): JSX.Element => {
  const upToWhichDate = initialValues.up_to_which_date
  const date = upToWhichDate?.split('-')

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        leave_id: initialValues.leave_id,
        annual_leave_entitlement: initialValues.annual_leave_entitlement,
        opening_balance: initialValues.opening_balance,
        // date[0]:Day - date[1]:Month - date[2]:Year
        up_to_which_date: date?.length ? `${date[2]}-${date[1]}-${date[0]}` : null,
      }}
      render={({ handleSubmit }): JSX.Element => (
        <>
          <Table.Cell className={styles.borderTableCell}>
            <SelectField
              // @ts-expect-error // select field needs to be migrated to TS
              name="annual_leave_entitlement"
              valueKey="days_per_year"
              labelKey="days_per_year"
              options={employee_leave_days}
              handleValue
              size="xsmall"
              style={{ zIndex: 22, minWidth: '100px', marginBottom: 6 }}
              maxMenuHeight={120}
            />
          </Table.Cell>
          <Table.Cell className={styles.borderTableCell}>
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              name="opening_balance"
              placeholder={I18n.t('opening_balance')}
              type="number"
              size="small"
            />
          </Table.Cell>
          <Table.Cell
            className={styles.borderTableCell}
            style={{ position: 'unset' }}
          >
            <DatePickerDSField
              textFieldProps={{
                placeholder: I18n.t('date_form_dd_mm_yyyy'),
              }}
              name="up_to_which_date"
              validate={(v, vals): undefined | string => {
                const newVals = vals as unknown as Partial<TLeaves>
                if ('opening_balance' in newVals && newVals?.opening_balance) {
                  return required(v)
                }
                return undefined
              }}
            />
          </Table.Cell>
          <Table.Cell
            className={styles.borderTableCell}
            width="75px"
          >
            <CheckIcon
              onClick={(): void => {
                handleSubmit()
              }}
              style={{ cursor: 'pointer' }}
            />
            <Spacer width="16px" />
            <Deletecon
              onClick={(): void => {
                onClose(null)
              }}
              style={{ cursor: 'pointer' }}
            />
          </Table.Cell>
        </>
      )}
    />
  )
}

export default FormTable
