const Workable = {
  // A
  access_token: 'Access Token',
  add_candidate_confirm_title: 'Would you like to add {{emp_name}} to your company?',
  add_candidate_confirm_text:
    'You will need to fill some mandatory information to add this employee to your organization',

  // C
  connect_your_workable_account: 'Connect your Workable account',
  candidates: 'Candidates',
  candidate_name: 'Candidate Name',
  candidate_source: 'Source',

  // H
  how_to_connect_workable_to_jisr: 'How to Connect Workable with {{app_name}}',
  helper_text_access_token: 'Access Token is used to authorize the client for the selected APIs',
  helper_text_subdomain:
    'Workable domain prefix that uniquely identifies your request on workable.',
  hired_candidates: 'Hired Candidates',
  hired_at: 'Hired at',

  // S
  search_add_candidates: 'Search by Employee Name or Job title',
  sync_from: 'Sync from',

  // W
  workable: 'Workable',
  workable_description:
    "An applicant tracking system, Workable's talent acquisition software helps teams find candidates, evaluate applicants and make the right hire",
  workable_permission_text:
    'By connecting your workable account with {{app_name}}. {{app_name}} will have the permission to do following actions:',
  workable_help_note: 'IF you need any help please <span>Contact us</span>',
  workable_info_note: 'You can learn more about Workable integration.',
  workable_read_link: 'Read this article to know more.',
  workable_view_action: 'View the list of hired employees',
  workable_add_action: 'Add hired candidates to {{app_name}}',
}

export default Workable
