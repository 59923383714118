import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'
import { AttendanceLogsLoadTypes, TypeRequestState } from './typeReducer'

export const initialState: TypeRequestState = {
  fetching: false,
  loading: false,
  request: {},
  comments: [],
  attendance_logs: [],
}

export default function reducer(state = initialState, action: Action): TypeRequestState {
  switch (action.type) {
    case actions.RESET_REQUEST_DETAIL:
      return initialState

    case actions.ATTENDANCE_LOGS_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.REQUEST_DETAILS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_LOGS_LOAD_FULFILLED:
      const attendanceLogsData: AttendanceLogsLoadTypes = action.payload.data.data
      return {
        ...state,
        ...attendanceLogsData,
        loading: false,
      }

    case actions.REQUEST_DETAILS_LOAD_FULFILLED:
      return {
        ...state,
        request: action.payload.data.data,
        comments: action.payload.data.data.comments,
        fetching: false,
      }

    case actions.REQUEST_DETAILS_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload.comment],
      }

    case actions.REQUEST_DETAILS_LOAD_REJECTED:
    case actions.ATTENDANCE_LOGS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
