import { Banner } from '@jisr-hr/ds'
import i18n from 'translations/i18n'

type BalanceChangeWarningProps = {
  defaultBalance: number
  currentBalance: number
}

const BalanceChangeWarning = (props: BalanceChangeWarningProps) => {
  const { defaultBalance, currentBalance } = props

  const isMoreThanDefault = currentBalance > defaultBalance
  const isEqualsDefault = currentBalance === defaultBalance

  if (isEqualsDefault || !defaultBalance) {
    return null
  }

  return (
    <Banner
      color={isMoreThanDefault ? 'info' : 'danger'}
      title={i18n.t('label.alert.not_compliant_title')}
      description={
        isMoreThanDefault
          ? i18n.t('label.alert.not_compliant_more_than_description', {
              days_count: defaultBalance,
            })
          : i18n.t('label.alert.not_compliant_less_than_description', {
              days_count: defaultBalance,
            })
      }
      variant="filled"
      leadingIcon="alert-triangle-filled"
      size="extended"
    />
  )
}

export default BalanceChangeWarning
