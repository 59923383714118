export const ORG_SUBSIDIARIES_LOAD = 'ORG_SUBSIDIARIES_LOAD'
export const ORG_SUBSIDIARIES_LOAD_PENDING = 'ORG_SUBSIDIARIES_LOAD_PENDING'
export const ORG_SUBSIDIARIES_LOAD_FULFILLED = 'ORG_SUBSIDIARIES_LOAD_FULFILLED'
export const ORG_SUBSIDIARIES_LOAD_REJECTED = 'ORG_SUBSIDIARIES_LOAD_REJECTED'

export const ORG_SUBSIDIARY_LOAD = 'ORG_SUBSIDIARY_LOAD'
export const ORG_SUBSIDIARY_LOAD_PENDING = 'ORG_SUBSIDIARY_LOAD_PENDING'
export const ORG_SUBSIDIARY_LOAD_FULFILLED = 'ORG_SUBSIDIARY_LOAD_FULFILLED'
export const ORG_SUBSIDIARY_LOAD_REJECTED = 'ORG_SUBSIDIARY_LOAD_REJECTED'

export const ORG_SUBSIDIARY_CREATE = 'ORG_SUBSIDIARY_CREATE'
export const ORG_SUBSIDIARY_CREATE_PENDING = 'ORG_SUBSIDIARY_CREATE_PENDING'
export const ORG_SUBSIDIARY_CREATE_FULFILLED = 'ORG_SUBSIDIARY_CREATE_FULFILLED'
export const ORG_SUBSIDIARY_CREATE_REJECTED = 'ORG_SUBSIDIARY_CREATE_REJECTED'
