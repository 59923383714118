import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'

import Record from '../Record'

const AllowedShortageAndDelay = ({ title, data }) => {
  const label = data.from ? `${data.from} ${I18n.t('min')}` : null
  const value = data.to ? `${data.to} ${I18n.t('min')}` : null

  return (
    <Record
      title={I18n.t(title)}
      label={label}
      value={value}
    />
  )
}

AllowedShortageAndDelay.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape(),
}

export default AllowedShortageAndDelay
