import React from 'react'
import PropTypes from 'prop-types'

const HOC = (WrappedComponent) => {
  class Modal extends React.Component {
    state = { open: true }

    handleClose = () => {
      this.setState({ open: false })
      if (this.props.backOnClose) {
        window.history.back()
      }
    }

    render() {
      return (
        <WrappedComponent
          handleClose={this.handleClose}
          open={this.state.open}
          {...this.props}
        />
      )
    }
  }

  Modal.propTypes = {
    backOnClose: PropTypes.bool,
  }

  return Modal
}

export default HOC
