import { handleResponseErr } from 'utils/apiHelperUtils'
// import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { ShowBankType, OrgBankAccountType } from 'types/PaymentMethods/banks'
import * as actions from './actions'

type DefaultBankAccountType = Omit<OrgBankAccountType, 'commercial_registration' | 'bank_logo'>

type InitialStateType = {
  loading: boolean
  fetching: boolean
  bank_accounts: OrgBankAccountType[]
  default_bank_accounts: DefaultBankAccountType[]
  iban_data: ShowBankType | null
}

const initialState: InitialStateType = {
  bank_accounts: [],
  default_bank_accounts: [],
  iban_data: null,
  loading: false,
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action

  switch (action.type) {
    case actions.ORGANIZATION_BANKS_LOAD_PENDING:
    case actions.DEFAULT_BANK_ACCOUNTS_LOAD_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.ORGANIZATION_BANKS_CREATE_PENDING:
    case actions.ORGANIZATION_BANKS_DELETE_PENDING:
    case actions.BANK_IBAN_CHECKING_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ORGANIZATION_BANKS_LOAD_FULFILLED: {
      const bank_accounts = (payload.data.data?.bank_accounts || []) as OrgBankAccountType[]
      return {
        ...state,
        bank_accounts,
        loading: false,
      }
    }

    case actions.DEFAULT_BANK_ACCOUNTS_LOAD_FULFILLED: {
      const data = (payload.data.data?.commercial_registrations || []) as DefaultBankAccountType[]
      return {
        ...state,
        default_bank_accounts: data,
        loading: false,
      }
    }

    case actions.BANK_IBAN_CHECKING_LOAD_FULFILLED:
      return {
        ...state,
        iban_data: payload.data.data.bank as ShowBankType,
        fetching: false,
      }

    case actions.ORGANIZATION_BANKS_CREATE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_BANKS_CREATE_REJECTED:
    case actions.ORGANIZATION_BANKS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_BANKS_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.BANK_IBAN_CHECKING_LOAD_REJECTED:
    case actions.ORGANIZATION_BANKS_LOAD_REJECTED:
    case actions.DEFAULT_BANK_ACCOUNTS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
        fetching: false,
      }
    }

    case actions.ORGANIZATION_BANKS_REMOVE_FROM_LIST: {
      const { id } = payload

      return {
        ...state,
        bank_accounts: state.bank_accounts.filter((account) => account.id !== id),
      }
    }
    case actions.ORGANIZATION_BANKS_ADD_TO_LIST: {
      const banks = payload.banks as OrgBankAccountType[]

      return {
        ...state,
        bank_accounts: [...banks, ...state.bank_accounts],
      }
    }
    default:
      return state
  }
}
