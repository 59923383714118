import React from 'react'
import PropTypes from 'prop-types'

import RequestProvider from './RequestProvider'
import RequestDrawer from './RequestDrawer'

const Container = ({ active, vars, actionCallback, requestType, ...props }) => (
  <RequestProvider
    active={active}
    vars={vars}
    actionCallback={actionCallback}
    requestType={requestType}
  >
    <RequestDrawer {...props} />
  </RequestProvider>
)

Container.propTypes = {
  active: PropTypes.bool,
  vars: PropTypes.shape(),
  actionCallback: PropTypes.func,
  requestType: PropTypes.string,
}

export default Container
