import _ from 'lodash'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { CreateDataPros, UpdateDataPros } from './types'

const url = `/${API_VERSION_TWO}/request_types`

export function requestTypesList(params = {}): Action {
  return {
    type: 'REQUEST_TYPES_LOAD',
    payload: handleAPI(url, params, 'GET'),
  }
}

export function requestTypesShow(id: string | number, params = {}): Action {
  const ShowUrl = `${url}/${id}`
  return {
    type: 'REQUEST_TYPES_SHOW',
    payload: handleAPI(ShowUrl, params, 'GET'),
  }
}

export function requestTypesCreate(data: CreateDataPros): Action {
  return {
    type: 'REQUEST_TYPES_POST',
    payload: handleAPI(url, {}, 'POST', { request_type: data }),
  }
}

export function requestTypesUpdate(data: UpdateDataPros): Action {
  const PutUrl = `${url}/${data.id}`
  const nweData = _.omit(data, ['id'])
  return {
    type: 'REQUEST_TYPES_PUT',
    payload: handleAPI(PutUrl, {}, 'PUT', { request_type: nweData }),
  }
}

export function requestTypesDelete(id: number): Action {
  const DeleteUrl = `${url}/${id}`
  return {
    type: 'REQUEST_TYPES_DELETE',
    payload: handleAPI(DeleteUrl, {}, 'DELETE'),
  }
}
