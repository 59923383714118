import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function onBoardingBankAccountsCreate(data = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/bank_accounts`

  return {
    type: actions.ONBOARDING_BANK_ACCOUNTS_CREATE,
    payload: handleAPI(url, {}, 'POST', { bank_account: data }),
  }
}

export function onBoardingBankAccountsUpdate(data = {}, id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/bank_accounts/${id}`

  return {
    type: actions.ONBOARDING_BANK_ACCOUNTS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { bank_account: data }),
  }
}

export function onBoardingBankAccountsDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/bank_accounts/${id}`

  return {
    type: actions.ONBOARDING_BANK_ACCOUNTS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}
