import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function minVacationSalaryDaysLoad(): Action {
  const url = `/${API_VERSION_TWO}/organization_configurations/min_vacation_salary_days`
  return {
    type: actions.MIN_VACATION_SALARY_DAYS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function minVacationSalaryDaysUpdate(data: { value: number }): Action {
  const url = `/${API_VERSION_TWO}/organization_configurations/min_vacation_salary_days`
  return {
    type: actions.MIN_VACATION_SALARY_DAYS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { organization_configuration: data }),
  }
}

export function isAdvanceVacationSalaryEnabledLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/organization_configurations/is_advance_vacation_salary_enabled`
  return {
    type: actions.IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function isAdvanceVacationSalaryEnabledUpdate(data: { value: boolean }): Action {
  const url = `/${API_VERSION_TWO}/organization_configurations/is_advance_vacation_salary_enabled`
  return {
    type: actions.IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { organization_configuration: data }),
  }
}
