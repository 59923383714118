import { retryImport } from 'utils/retry'

const RequestsRoutes = retryImport(
  () => import(/* webpackChunkName: "requestsRoutes" */ '../requestsRoutes'),
)
const OverviewRoutes = retryImport(
  () => import(/* webpackChunkName: "overviewRoutes" */ '../overviewRoutes'),
)
const EmployeeRoutes = retryImport(
  () => import(/* webpackChunkName: "employeeRoutes" */ '../employeeRoutes'),
)
const OnboardingEmployeesRoutes = retryImport(
  () => import(/* webpackChunkName: "onboardingEmployeesRoutes" */ '../OnboardingEmployeesRoutes'),
)
const AttendanceRoutes = retryImport(
  () => import(/* webpackChunkName: "attendance" */ '../attendance'),
)
const LeavesManagementRoutes = retryImport(
  () => import(/* webpackChunkName: "leavesManagementRoutes" */ '../leavesManagementRoutes'),
)
const SchedulerRoutes = retryImport(
  () => import(/* webpackChunkName: "schedulerRoutes" */ '../schedulerRoutes'),
)
const AttendanceDashboardRoutes = retryImport(
  () => import(/* webpackChunkName: "AttendanceDashboardRoutes" */ '../AttendanceDashboardRoutes'),
)
const ReportedViolationsRoutes = retryImport(
  () => import(/* webpackChunkName: "ReportedViolationsRoutes" */ '../ReportedViolationsRoutes'),
)
const ReportRoutes = retryImport(
  () => import(/* webpackChunkName: "reportRoutes" */ '../reportRoutes'),
)
const ExportHistoryRoute = retryImport(
  () => import(/* webpackChunkName: "ExportHistoryRoute" */ '../ExportHistoryRoute'),
)
const PayrollRoutes = retryImport(
  () => import(/* webpackChunkName: "payrollRoutes" */ '../payrollRoutes'),
)
const SettingRoutes = retryImport(
  () => import(/* webpackChunkName: "settingRoutes" */ '../settingRoutes'),
)
const V2settingRoutes = retryImport(
  () => import(/* webpackChunkName: "settingRoutes" */ '../v2settingRoutes'),
)
const PaymentProcessingRoutes = retryImport(
  () => import(/* webpackChunkName: "PaymentProcessingRoutes" */ '../PaymentProcessingRoutes'),
)
const GosiSyncRoutes = retryImport(
  () =>
    import(
      /* webpackChunkName: "PaymentProcessingRoutes" */ '../../../containers/authorised/Finance/GosiSync'
    ),
)
const PerformanceObjectivesRoutes = retryImport(
  () => import('../performanceRoutes/ObjectivesRoutes'),
)
const PerformanceCyclesRoutes = retryImport(() => import('../performanceRoutes/CyclesRoutes'))

const ATSRoutes = retryImport(() => import('../ats/ATSRoute'))

const PerformanceCompetenciesRoutes = retryImport(
  () => import('../performanceRoutes/CompetenciesRoutes'),
)
const OffCycleRoutes = retryImport(() => import('../OffCycleRoutes'))
const FeatureSetupRoutes = retryImport(() => import('../featuresSetup'))
const EmailUnsubscribe = retryImport(() => import('../../../containers/public/EmailUnsubscribe'))
const AnalysisRoutes = retryImport(() => import('../AnalysisRoutes'))
const MuqeemServicesRoutes = retryImport(() => import('../MuqeemServicesRoutes'))

const HiredCandidatesRoutes = retryImport(() => import('../HiredCandidatesRoutes'))

const AssetsRoutes = retryImport(() => import('../AssetsRoutes'))

const MarketplaceRoutes = retryImport(() => import('../MarkeplaceRoutes'))

const Automation = retryImport(() => import('../AutomationRoutes'))

const BenetfitManagementRoutes = retryImport(() => import('../BenetfitManagementRoutes'))
const InvitationRoutes = retryImport(() => import('../InvitationRoutes'))
const AnnouncementRoute = retryImport(() => import('../AnnouncementRoutes'))

const OrganizationChartRoutes = retryImport(() => import('../OrganizationChartRoutes'))
// Performance V2

const EvaluationForms = retryImport(() => import('../performanceRoutesV2'))

const NewOnboardingRoutes = retryImport(
  () => import(/* webpackChunkName: "NewOnboarding" */ '../NewOnboardingRoutes'),
)

const LearningAndDevelopmentRoutes = retryImport(
  () => import(/* webpackChunkName: "NewOnboarding" */ '../LearningAndDevelopmentRoutes'),
)
// TODO: LET BACKEND TEAM RETURN PERMISSION AS HASH OBJECT AS URL AND BOOL VALUE
const routes = [
  {
    permissionKey: 'Overview',
    path: '/',
    component: OverviewRoutes,
    isExact: true,
  },
  {
    permissionKey: 'Employee profile',
    path: '/requests/:tab?',
    component: RequestsRoutes,
    isExact: true,
  },
  {
    permissionKey: 'Employee profile',
    path: '/employees',
    component: EmployeeRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Onboarding Employees',
    path: '/onboarding_employees',
    component: OnboardingEmployeesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Employee profile',
    path: '/organization_chart',
    component: OrganizationChartRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Muqeem Services',
    path: '/muqeem_services',
    component: MuqeemServicesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Hired Candidates',
    path: '/hired_candidates',
    component: HiredCandidatesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Assets',
    path: '/assets',
    component: AssetsRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Employee profile',
    path: '/setup',
    component: FeatureSetupRoutes,
    isExact: false,
  },
  {
    permissionKey: 'PerformanceObjectives',
    path: '/performance/objectives',
    component: PerformanceObjectivesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'performanceCyclesCompetencies',
    path: '/performance/cycles',
    component: PerformanceCyclesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'PerformanceEvaluation',
    path: '/performance_v2',
    component: EvaluationForms,
    isExact: false,
  },
  {
    permissionKey: 'performanceCyclesCompetencies',
    path: '/performance/competencies',
    component: PerformanceCompetenciesRoutes,
    isExact: false,
  },
  {
    permissionKey: 'ATSRecruitment',
    path: '/ats',
    component: ATSRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Leave',
    path: '/leave_management',
    component: LeavesManagementRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Attendance',
    path: '/attendance',
    component: AttendanceRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Scheduler',
    path: '/scheduler',
    component: SchedulerRoutes,
    isExact: false,
  },
  {
    permissionKey: 'ViolationList',
    path: '/reported_violations',
    component: ReportedViolationsRoutes,
    isExact: false,
  },
  {
    permissionKey: 'AttendanceDashboard',
    path: '/dashboard',
    component: AttendanceDashboardRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Payroll',
    path: '/payroll',
    component: PayrollRoutes,
    isExact: false,
  },
  {
    permissionKey: 'BenefitsManagement',
    path: '/benefits_management',
    component: BenetfitManagementRoutes,
    isExact: false,
  },
  {
    permissionKey: 'FinalSettlement',
    path: '/off_cycle',
    component: OffCycleRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Payroll',
    path: '/transfers',
    component: PaymentProcessingRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Payroll',
    path: '/gosi_sync',
    component: GosiSyncRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Reports',
    path: '/reports',
    component: ReportRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Reports',
    path: '/export_history',
    component: ExportHistoryRoute,
    isExact: false,
  },
  {
    permissionKey: 'Settings',
    path: '/setting',
    component: SettingRoutes,
    isExact: false,
  },
  {
    permissionKey: 'NewOnboarding',
    path: '/new_onboarding',
    component: NewOnboardingRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Settings',
    path: '/setting_v2',
    component: V2settingRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Settings',
    path: '/marketplace',
    component: MarketplaceRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Employee profile',
    path: '/unsubscribe',
    component: EmailUnsubscribe,
    isExact: false,
  },
  {
    permissionKey: 'Analysis',
    path: '/data_analysis',
    component: AnalysisRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Automations',
    path: '/automations',
    component: Automation,
    isExact: false,
  },
  {
    permissionKey: 'Overview',
    path: '/invitations',
    component: InvitationRoutes,
    isExact: false,
  },
  {
    permissionKey: 'Overview',
    path: '/announcement',
    component: AnnouncementRoute,
    isExact: false,
  },
  {
    permissionKey: 'Overview',
    path: '/announcement/listing',
    component: AnnouncementRoute,
    isExact: false,
  },
  {
    permissionKey: 'Marketplace', // To Do: Update key 'Learning And Development'
    path: '/learning_and_development',
    component: LearningAndDevelopmentRoutes,
    isExact: false,
  },
]

export default routes
