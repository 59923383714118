import { Typography } from '@jisr-hr/ds'
import { omit } from 'lodash'
import { AdditionTransactionType } from 'redux/setting/payrollManagement/payType/reduecer'
import I18n from 'translations/i18n'
import styles from '../style.module.css'

export type TransactionFormType = Partial<AdditionTransactionType> & {
  in_payrun?: boolean
  out_of_payrun?: boolean
}

// ? @todo fix this function type
export const handelValues = (value: Record<string, unknown>): Record<string, unknown> => {
  if (value.name === 'Overtime') {
    if (value.pay_type === 'in_payrun') {
      const val = { ...value, pay_type: 'in_payrun', default_pay_type: 'in_payrun' }
      return omit(val, ['in_payrun', 'out_of_payrun', 'is_default', 'name_i18n'])
    } else if (value?.pay_type === 'out_of_payrun') {
      const val = { ...value, pay_type: 'out_of_payrun', default_pay_type: 'out_of_payrun' }
      return omit(val, ['in_payrun', 'out_of_payrun', 'is_default', 'name_i18n'])
    }
  }
  if (value.in_payrun && !value.out_of_payrun) {
    const val = { ...value, pay_type: 'in_payrun', default_pay_type: 'in_payrun' }
    return omit(val, ['in_payrun', 'out_of_payrun', 'is_default', 'name_i18n'])
  } else if (value?.out_of_payrun && !value.in_payrun) {
    const val = { ...value, pay_type: 'out_of_payrun', default_pay_type: 'out_of_payrun' }
    return omit(val, ['in_payrun', 'out_of_payrun', 'is_default', 'name_i18n'])
  } else if (value.out_of_payrun && value.in_payrun) {
    const val = { ...value, pay_type: 'both' }
    return omit(val, ['in_payrun', 'out_of_payrun', 'is_default', 'name_i18n'])
  }
  return value
}

export const handelValuesEdit = (values: Record<string, unknown>): Record<string, unknown> => {
  if (values.name === 'Overtime') return values

  if (values.pay_type === 'in_payrun') {
    return { ...values, in_payrun: true, out_of_payrun: false }
  } else if (values.pay_type === 'out_of_payrun') {
    return { ...values, in_payrun: false, out_of_payrun: true }
  } else if (values.pay_type === 'both') {
    return { ...values, in_payrun: true, out_of_payrun: true }
  }
  return values
}

export const options = [
  {
    name: I18n.t('active'),
    id: 'active',
    testId: 'active',
  },
  {
    id: 'inactive',
    name: I18n.t('inactive'),
    testId: 'inactive',
  },
]

type payTypeListType = {
  name: string
  id: 'in_payrun' | 'out_of_payrun'
  testId: string
}

export const payTypeList: payTypeListType[] = [
  {
    name: I18n.t('in_payroll'),
    id: 'in_payrun',
    testId: 'in_payrun',
  },
  {
    id: 'out_of_payrun',
    name: I18n.t('out_of_payroll'),
    testId: 'out_of_payrun',
  },
]

export type InitStateType = {
  search?: string
  filters?: {
    default_pay_type: string[]
    status: string[]
  }
}

export const initState: InitStateType = {
  search: '',
  filters: {
    default_pay_type: [],
    status: [],
  },
}

export const inActiveContent = (): JSX.Element => (
  <>
    <div className={styles.in_active_cover} />
    <div className={styles.in_active_text_cover}>
      <Typography
        style={{ margin: 'auto' }}
        variant="interface/default/sm"
        text={I18n.t('inactive')}
      />
    </div>
  </>
)

export function updateOneListOfRecordsByFindIndex<T extends { id: number }>(
  records: T[],
  updatedRecord: T,
): void {
  const index = records.findIndex((item) => item.id === updatedRecord.id)
  records[index] = updatedRecord
}
