import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { IdTypes } from 'types/sharedResponse'
import * as actions from './actions'

export type appDataType =
  | {
      id: IdTypes
      connected: boolean
      configured?: Record<string, never>
    }
  | Record<string, never>

type InitialValueType = {
  fetching: boolean
  workable: appDataType
  elevatus: appDataType
  activeAccount: string
}

const initialValues: InitialValueType = {
  fetching: false,
  elevatus: {},
  workable: {},
  activeAccount: '',
}

export default function reducer(state = initialValues, action: Action): InitialValueType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_API_PERMISSIONS_STATUS_PENDING:
    case actions.ALLOW_APP_API_INTEGRATION_PENDING:
    case actions.DENY_APP_API_INTEGRATION_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.GET_API_PERMISSIONS_STATUS_FULFILLED: {
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      const workableData = payload?.data?.data.open_api_aggregators?.workable
      return {
        ...state,
        fetching: false,
        elevatus: payload?.data?.data.open_api_aggregators?.elevatus,
        workable: workableData ? { ...workableData, configured: workableData?.configred } : {},
        activeAccount: payload?.data?.data.open_api_aggregators?.active_ats_name,
      }
    }

    case actions.ALLOW_APP_API_INTEGRATION_FULFILLED:
    case actions.DENY_APP_API_INTEGRATION_FULFILLED: {
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      if (action?.payload?.data?.error) Toastr.error(action?.payload?.data?.error)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.GET_API_PERMISSIONS_STATUS_REJECTED:
    case actions.ALLOW_APP_API_INTEGRATION_REJECTED:
    case actions.DENY_APP_API_INTEGRATION_REJECTED: {
      if (payload?.data?.message) Toastr.error(handleResponseErr(payload.data.message))
      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
