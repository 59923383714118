import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import * as actions from './actions'

// We added name and don't remove old fields like name_en & name)ar ... due to that will crash in other places
type MolRegistration = {
  id: number
  gosi_name_en?: string
  gosi_name_ar?: string
  name: string
  gosi_number: string
  mol_name_en?: string
  mol_name_ar?: string
  mol_prefix: string
  mol_number: string
  mol_registration_number: string
}

export type BankLogoType = {
  logo_thumb: string
}

export type BankAccountType = {
  id: number
  iban: string
  bank_id: number
  bank_name: string
  bank_logo: BankLogoType | string
  mudad_supported: boolean
}

type GosiIntegrationType = {
  id: number
  status: 'pending' | 'not_integrated' | 'integrated' | 'revoked' | 'expired'
  gosi_integration_start_date: string | null
  gosi_integration_end_date: string | null
  signatory_name: string
  signatory_email: string
  signatory_legal_id_number: string
  is_integrated: boolean
}

type MainCommercialRegistration = { id: number; name: string; registration_number: string }

// We added name and don't remove old fields like name_en & name)ar ... due to that will crash in other places
export type CommercialRegistrationType = {
  id: number
  invoice_already_generated: boolean
  name_en?: string
  name: string
  archived_at?: string
  name_ar?: string
  name_i18n?: string
  payment_mode: string | null
  registration_number: string
  registration_id: number
  mol_registration: MolRegistration
  gosi_integration: GosiIntegrationType | null
  main_commercial_registration?: MainCommercialRegistration
  number_of_employees: number
  sub_commercial_registrations?: CommercialRegistrationType[]
  main_id?: number | null
}

type InitStateType = {
  commercial_registrations: CommercialRegistrationType[]
  hierarchical_archived_commercial_registrations: CommercialRegistrationType[]
  hierarchical_commercial_registrations: CommercialRegistrationType[]
  succMsg: string | null
  errMsg: string | null
  fetching: boolean
}

const DEFAULT_STATE: InitStateType = {
  commercial_registrations: [],
  hierarchical_archived_commercial_registrations: [],
  hierarchical_commercial_registrations: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
}

export default function commercialRegistrationsReducer(
  state = DEFAULT_STATE,
  action: Action,
): typeof DEFAULT_STATE {
  const { payload } = action
  let commercialRegistrations: CommercialRegistrationType[] = []
  let newRecord: CommercialRegistrationType | null = null
  let updatedRecord: CommercialRegistrationType | null = null

  switch (action.type) {
    case actions.COMMERCIAL_REGISTRATIONS_LOAD_PENDING:
    case actions.COMMERCIAL_REGISTRATIONS_CREATE_PENDING:
    case actions.COMMERCIAL_REGISTRATIONS_UPDATE_PENDING:
    case actions.COMMERCIAL_REGISTRATIONS_DELETE_PENDING:
    case actions.COMMERCIAL_REGISTRATIONS_ARCHIVE_PENDING:
    case actions.COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_PENDING: {
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }
    }

    case actions.COMMERCIAL_REGISTRATIONS_LOAD_REJECTED:
    case actions.COMMERCIAL_REGISTRATIONS_CREATE_REJECTED:
    case actions.COMMERCIAL_REGISTRATIONS_UPDATE_REJECTED:
    case actions.COMMERCIAL_REGISTRATIONS_DELETE_REJECTED:
    case actions.COMMERCIAL_REGISTRATIONS_ARCHIVE_REJECTED:
    case actions.COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    }

    case actions.COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED: {
      const { commercial_registrations } = payload.data.data as {
        commercial_registrations: CommercialRegistrationType[]
      }
      if (payload?.params?.with_archived && payload?.params?.hierarchical) {
        const data = commercial_registrations.reduce(
          (acc, current: CommercialRegistrationType) => {
            if (current.archived_at) {
              acc.archivedCommercialRegistrations.push(current)
            } else {
              acc.commercialRegistration.push(current)
            }
            return acc
          },
          {
            commercialRegistration: [] as CommercialRegistrationType[],
            archivedCommercialRegistrations: [] as CommercialRegistrationType[],
          },
        )
        return {
          ...state,
          hierarchical_commercial_registrations: data.commercialRegistration,
          hierarchical_archived_commercial_registrations: data.archivedCommercialRegistrations,
          fetching: false,
          succMsg: payload.data?.message,
          errMsg: null,
        }
      }
      const key = payload?.params?.hierarchical
        ? 'hierarchical_commercial_registrations'
        : 'commercial_registrations'
      return {
        ...state,
        [key]: commercial_registrations,
        fetching: false,
        succMsg: payload.data?.message,
        errMsg: null,
      }
    }

    case actions.COMMERCIAL_REGISTRATIONS_CREATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      commercialRegistrations = [...state.hierarchical_commercial_registrations]
      newRecord = payload.data.data.commercial_registration as CommercialRegistrationType
      if (!payload.main_id) {
        newRecord = { ...newRecord, sub_commercial_registrations: [] }
        return {
          ...state,
          fetching: false,
          succMsg: payload.data?.message,
          hierarchical_commercial_registrations: [...commercialRegistrations, newRecord],
        }
      }
      const mainCR = commercialRegistrations.find((i) => i.id === payload.main_id)
      mainCR?.sub_commercial_registrations?.push(newRecord)
      return {
        ...state,
        fetching: false,
        succMsg: payload.data?.message,
        hierarchical_commercial_registrations: updateOneListOfRecords(
          commercialRegistrations,
          mainCR,
        ),
      }
    }

    case actions.COMMERCIAL_REGISTRATIONS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      commercialRegistrations = [...state.hierarchical_commercial_registrations]
      updatedRecord = payload.data.data.commercial_registration as CommercialRegistrationType
      // ? if we update sub CR
      if (action.payload.oldMainId) {
        // ? Deleting updatedCR from previous main CR
        const oldMain = commercialRegistrations.find((cr) => cr.id === action.payload.oldMainId)
        oldMain!.sub_commercial_registrations = removeOneListOfRecords(
          oldMain?.sub_commercial_registrations,
          updatedRecord.id,
        )
        // ? find new main CR
        const newMainCR = commercialRegistrations.find(
          (cr) => cr.id === updatedRecord?.main_commercial_registration?.id,
        )
        // ? if new main CR exist that's mean we change sub CR as sub from Main to Main
        if (newMainCR) {
          newMainCR.sub_commercial_registrations = Array.isArray(
            newMainCR?.sub_commercial_registrations,
          )
            ? [...newMainCR.sub_commercial_registrations, updatedRecord]
            : [updatedRecord]

          return {
            ...state,
            fetching: false,
            hierarchical_commercial_registrations: updateOneListOfRecords(
              commercialRegistrations,
              newMainCR,
            ),
          }
        }
        // ? if new main CR not exist that's mean we change sub CR to be main CR
        return {
          ...state,
          fetching: false,
          hierarchical_commercial_registrations: [...commercialRegistrations, updatedRecord],
        }
      } else if (!action.payload.oldMainId && updatedRecord?.main_commercial_registration) {
        // ? Deleting updatedCR from previous main CR
        commercialRegistrations = removeOneListOfRecords(commercialRegistrations, updatedRecord.id)

        // ? find new mainCR
        const newMainCR = commercialRegistrations.find(
          (cr) => cr.id === updatedRecord?.main_commercial_registration?.id,
        )
        if (newMainCR) {
          newMainCR.sub_commercial_registrations = Array.isArray(
            newMainCR?.sub_commercial_registrations,
          )
            ? [...newMainCR.sub_commercial_registrations, updatedRecord]
            : [updatedRecord]
        }
        return {
          ...state,
          fetching: false,
          hierarchical_commercial_registrations: updateOneListOfRecords(
            commercialRegistrations,
            newMainCR,
          ),
        }
      }

      // ? if we update MainCR
      return {
        ...state,
        fetching: false,
        hierarchical_commercial_registrations: updateOneListOfRecords(
          commercialRegistrations,
          updatedRecord,
        ),
      }

    case actions.COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED:
      let deletedCR = state.hierarchical_commercial_registrations.find((cr) => cr.id === payload.id)
      let list: CommercialRegistrationType[] | undefined =
        state.hierarchical_commercial_registrations
      let key = 'hierarchical_commercial_registrations'
      // if the record in archived_commercial_registrations state
      if (!deletedCR) {
        deletedCR = state.hierarchical_archived_commercial_registrations.find(
          (cr) => cr.id === payload.id,
        )
        list = state.hierarchical_archived_commercial_registrations
        key = 'hierarchical_archived_commercial_registrations'
      }
      // if the record in sub commercial registrations
      if (!deletedCR) {
        let mainCR = state.hierarchical_commercial_registrations.find((cr) =>
          cr.sub_commercial_registrations?.find((sub_cr) => sub_cr.id === payload.id),
        )
        if (mainCR) {
          mainCR.sub_commercial_registrations = mainCR.sub_commercial_registrations?.filter(
            (cr) => cr.id !== payload.id,
          )
        } else {
          mainCR = state.hierarchical_archived_commercial_registrations.find((cr) =>
            cr.sub_commercial_registrations?.find((sub_cr) => sub_cr.id === payload.id),
          )
          if (mainCR) {
            mainCR.sub_commercial_registrations = mainCR.sub_commercial_registrations?.filter(
              (cr) => cr.id !== payload.id,
            )
          }
        }
      }
      return {
        ...state,
        fetching: false,
        [key]: removeOneListOfRecords(list, payload.id),
      }

    case actions.COMMERCIAL_REGISTRATIONS_ARCHIVE_FULFILLED:
      const record = payload.data.data as CommercialRegistrationType
      let mainCR = record
      const commercialRegistrationsCount = state.hierarchical_commercial_registrations.length

      state.hierarchical_commercial_registrations =
        state.hierarchical_commercial_registrations.filter((cr): boolean => {
          if (cr.id === record?.id) {
            mainCR = cr
            return false
          }
          return true
        })

      if (commercialRegistrationsCount === state.hierarchical_commercial_registrations.length) {
        state.hierarchical_commercial_registrations.forEach((cr) => {
          const sub = cr.sub_commercial_registrations?.find((sub_cr) => sub_cr.id === record.id)
          if (sub) {
            sub.archived_at = record.archived_at
          }
        })
      } else {
        mainCR.archived_at = record.archived_at
        state.hierarchical_archived_commercial_registrations = [
          ...state.hierarchical_archived_commercial_registrations,
          mainCR,
        ]
      }
      return {
        ...state,
        fetching: false,
      }

    case actions.COMMERCIAL_REGISTRATIONS_MOVE_EMPLOYEES_FULFILLED:
      let from_CR = state.hierarchical_commercial_registrations.find(
        (cr) => cr.id === payload.from_id,
      )
      if (!from_CR) {
        state.hierarchical_commercial_registrations.forEach((cr) => {
          if (!from_CR)
            from_CR = cr.sub_commercial_registrations?.find(
              (sub_cr) => sub_cr.id === payload.from_id,
            )
        })
      }
      let to_CR = state.hierarchical_commercial_registrations.find((cr) => cr.id === payload.to_id)

      if (!to_CR) {
        state.hierarchical_commercial_registrations.forEach((cr) => {
          if (!to_CR)
            to_CR = cr.sub_commercial_registrations?.find((sub_cr) => sub_cr.id === payload.to_id)
        })
      }

      if (from_CR) {
        if (to_CR) to_CR.number_of_employees += payload.data.data.assigned_employees_count
        from_CR.number_of_employees = payload.data.data.remaining_employees_count
      }
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
