import { memo } from 'react'
import { MultiSelectProps, MultiSelect, OptionType } from '@jisr-hr/ds-beta'
import { Field } from 'react-final-form'
import { isEmpty, memoize } from 'lodash'

type NewMultiSelectFieldType = Omit<MultiSelectProps, 'onChange'> & {
  name: string
  validate?: (value: any, values: any) => string | undefined
  onChange?: (value: OptionType[]) => void
}

const NewMultiSelectField = (props: NewMultiSelectFieldType): JSX.Element => {
  const { name, validate, ...rest } = props

  const getOption = memoize((value: OptionType['value'][] | null): OptionType[] | undefined => {
    return rest.options.filter((option) => value?.includes(option.value))
  })
  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => {
        return (
          <MultiSelect
            {...rest}
            onChange={(value): void => {
              if (isEmpty(value)) input.onChange(undefined)
              else input.onChange(value.map((option) => option.value))

              props.onChange?.(value)
            }}
            value={getOption(input.value || [])}
            status={meta.error && meta.touched ? 'destructive' : undefined}
            helperText={meta.error && meta.touched ? meta.error : props.helperText}
          />
        )
      }}
    </Field>
  )
}

export default memo(NewMultiSelectField)
