import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PayrollTransactionStatusTypes = {
  filterKey: string
  filters: string[]
}

const PayrollTransactionStatus = (props: PayrollTransactionStatusTypes): JSX.Element => {
  const { payroll_transaction_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(payroll_transaction_status)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default PayrollTransactionStatus
