export const CREATE_OPEN_API_KEY = 'CREATE_OPEN_API_KEY'
export const CREATE_OPEN_API_KEY_PENDING = 'CREATE_OPEN_API_KEY_PENDING'
export const CREATE_OPEN_API_KEY_FULFILLED = 'CREATE_OPEN_API_KEY_FULFILLED'
export const CREATE_OPEN_API_KEY_REJECTED = 'CREATE_OPEN_API_KEY_REJECTED'

export const GET_OPEN_API_KEYS_LIST = 'GET_OPEN_API_KEYS_LIST'
export const GET_OPEN_API_KEYS_LIST_PENDING = 'GET_OPEN_API_KEYS_LIST_PENDING'
export const GET_OPEN_API_KEYS_LIST_FULFILLED = 'GET_OPEN_API_KEYS_LIST_FULFILLED'
export const GET_OPEN_API_KEYS_LIST_REJECTED = 'GET_OPEN_API_KEYS_LIST_REJECTED'

export const VIEW_OPEN_API_KEY = 'VIEW_OPEN_API_KEY'
export const VIEW_OPEN_API_KEY_PENDING = 'VIEW_OPEN_API_KEY_PENDING'
export const VIEW_OPEN_API_KEY_FULFILLED = 'VIEW_OPEN_API_KEY_FULFILLED'
export const VIEW_OPEN_API_KEY_REJECTED = 'VIEW_OPEN_API_KEY_REJECTED'

export const UPDATE_OPEN_API_KEY = 'UPDATE_OPEN_API_KEY'
export const UPDATE_OPEN_API_KEY_PENDING = 'UPDATE_OPEN_API_KEY_PENDING'
export const UPDATE_OPEN_API_KEY_FULFILLED = 'UPDATE_OPEN_API_KEY_FULFILLED'
export const UPDATE_OPEN_API_KEY_REJECTED = 'UPDATE_OPEN_API_KEY_REJECTED'

export const DELETE_OPEN_API_KEY = 'DELETE_OPEN_API_KEY'
export const DELETE_OPEN_API_KEY_PENDING = 'DELETE_OPEN_API_KEY_PENDING'
export const DELETE_OPEN_API_KEY_FULFILLED = 'DELETE_OPEN_API_KEY_FULFILLED'
export const DELETE_OPEN_API_KEY_REJECTED = 'DELETE_OPEN_API_KEY_REJECTED'

export const GET_OPEN_API_GROUP_LIST = 'GET_OPEN_API_GROUP_LIST'
export const GET_OPEN_API_GROUP_LIST_PENDING = 'GET_OPEN_API_GROUP_LIST_PENDING'
export const GET_OPEN_API_GROUP_LIST_FULFILLED = 'GET_OPEN_API_GROUP_LIST_FULFILLED'
export const GET_OPEN_API_GROUP_LIST_REJECTED = 'GET_OPEN_API_GROUP_LIST_REJECTED'
