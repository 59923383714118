export const GL_ACCOUNT_LOAD = 'GL_ACCOUNT_LOAD'
export const GL_ACCOUNT_LOAD_PENDING = 'GL_ACCOUNT_LOAD_PENDING'
export const GL_ACCOUNT_LOAD_FULFILLED = 'GL_ACCOUNT_LOAD_FULFILLED'
export const GL_ACCOUNT_LOAD_REJECTED = 'GL_ACCOUNT_LOAD_REJECTED'

export const GL_ACCOUNTS_LIST_LOAD = 'GL_ACCOUNTS_LIST_LOAD'
export const GL_ACCOUNTS_LIST_LOAD_PENDING = 'GL_ACCOUNTS_LIST_LOAD_PENDING'
export const GL_ACCOUNTS_LIST_LOAD_FULFILLED = 'GL_ACCOUNTS_LIST_LOAD_FULFILLED'
export const GL_ACCOUNTS_LIST_LOAD_REJECTED = 'GL_ACCOUNTS_LIST_LOAD_REJECTED'

export const GL_ACCOUNT_CREATE = 'GL_ACCOUNT_CREATE'
export const GL_ACCOUNT_CREATE_PENDING = 'GL_ACCOUNT_CREATE_PENDING'
export const GL_ACCOUNT_CREATE_FULFILLED = 'GL_ACCOUNT_CREATE_FULFILLED'
export const GL_ACCOUNT_CREATE_REJECTED = 'GL_ACCOUNT_CREATE_REJECTED'

export const GL_ACCOUNT_TRANSACTION_SYNC = 'GL_ACCOUNT_TRANSACTION_SYNC'
export const GL_ACCOUNT_TRANSACTION_SYNC_PENDING = 'GL_ACCOUNT_TRANSACTION_SYNC_PENDING'
export const GL_ACCOUNT_TRANSACTION_SYNC_FULFILLED = 'GL_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const GL_ACCOUNT_TRANSACTION_SYNC_REJECTED = 'GL_ACCOUNT_TRANSACTION_SYNC_REJECTED'

export const GL_ACCOUNT_BULK_UPDATE = 'GL_ACCOUNT_BULK_UPDATE'
export const GL_ACCOUNT_BULK_UPDATE_PENDING = 'GL_ACCOUNT_BULK_UPDATE_PENDING'
export const GL_ACCOUNT_BULK_UPDATE_FULFILLED = 'GL_ACCOUNT_BULK_UPDATE_FULFILLED'
export const GL_ACCOUNT_BULK_UPDATE_REJECTED = 'GL_ACCOUNT_BULK_UPDATE_REJECTED'

export const GL_ACCOUNT_DELETE = 'GL_ACCOUNT_DELETE'
export const GL_ACCOUNT_DELETE_PENDING = 'GL_ACCOUNT_DELETE_PENDING'
export const GL_ACCOUNT_DELETE_FULFILLED = 'GL_ACCOUNT_DELETE_FULFILLED'
export const GL_ACCOUNT_DELETE_REJECTED = 'GL_ACCOUNT_DELETE_REJECTED'

export const GL_ACCOUNT_UPDATE = 'GL_ACCOUNT_UPDATE'
export const GL_ACCOUNT_UPDATE_PENDING = 'GL_ACCOUNT_UPDATE_PENDING'
export const GL_ACCOUNT_UPDATE_FULFILLED = 'GL_ACCOUNT_UPDATE_FULFILLED'
export const GL_ACCOUNT_UPDATE_REJECTED = 'GL_ACCOUNT_UPDATE_REJECTED'

export const UNMAPPED_COUNT = 'UNMAPPED_COUNT'
export const UNMAPPED_COUNT_PENDING = 'UNMAPPED_COUNT_PENDING'
export const UNMAPPED_COUNT_FULFILLED = 'UNMAPPED_COUNT_FULFILLED'
export const UNMAPPED_COUNT_REJECTED = 'UNMAPPED_COUNT_REJECTED'

export const GL_MAPPING_BULK_UPLOAD = 'GL_MAPPING_BULK_UPLOAD'
export const GL_MAPPING_BULK_UPLOAD_PENDING = 'GL_MAPPING_BULK_UPLOAD_PENDING'
export const GL_MAPPING_BULK_UPLOAD_FULFILLED = 'GL_MAPPING_BULK_UPLOAD_FULFILLED'
export const GL_MAPPING_BULK_UPLOAD_REJECTED = 'GL_MAPPING_BULK_UPLOAD_REJECTED'

export const GL_MAPPING_BULK_IMPORT_SAMPLE = 'GL_MAPPING_BULK_IMPORT_SAMPLE'
export const GL_MAPPING_BULK_IMPORT_SAMPLE_PENDING = 'GL_MAPPING_BULK_IMPORT_SAMPLE_PENDING'
export const GL_MAPPING_BULK_IMPORT_SAMPLE_FULFILLED = 'GL_MAPPING_BULK_IMPORT_SAMPLE_FULFILLED'
export const GL_MAPPING_BULK_IMPORT_SAMPLE_REJECTED = 'GL_MAPPING_BULK_IMPORT_SAMPLE_REJECTED'
