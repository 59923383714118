import React from 'react'
import PropTypes from 'prop-types'
import { MenuPopup } from 'components/global/atoms'

import CreateStep, { MenuList } from './CreateStep'

const WorkflowSteps = ({ onSelected, children, hideCreate }) => (
  <div>
    <div className="workflow-steps">{children}</div>

    {!hideCreate && (
      <MenuPopup
        trigger={<CreateStep />}
        content={<MenuList onSelected={onSelected} />}
        startRight
      />
    )}
  </div>
)

WorkflowSteps.propTypes = {
  onSelected: PropTypes.func,
  hideCreate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

WorkflowSteps.defaultProps = {
  onSelected: () => {},
}

export default WorkflowSteps
