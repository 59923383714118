/** **************
 *   Auth Reducer
 /*************** */

// import { handleResponseErr } from 'utils/apiHelperUtils';
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  employee_contracts: [],
  employee_contract: {},
  passed: false,
  fetching: false,
}

let changes = null

export default function employeeContractReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let newRecord = {}
  let employee_contracts = []
  switch (action.type) {
    /*
     * On Success
     * Action: put success message and run onSuccess function from actions
     */
    case 'CONTRACT_CREATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'CONTRACT_CREATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CONTRACT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      newRecord = payload.data.data.employee_contract
      employee_contracts = [...state.employee_contracts]
      changes = {
        employee_contracts: [...employee_contracts, newRecord],
        editing: false,
      }
      return { ...state, ...changes }

    case 'NEW_EMPLOYEE_CONTRACT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return { ...state }

    case 'CONTRACTS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
