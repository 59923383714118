import { Spacer } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useForm, useFormState } from 'react-final-form'
import Frame from 'containers/authorised/setting/v2/components/Frame'
import { TextInputField, MultiSelectField, NewSelectField } from 'components/final-form'
import { composeValidators, maxLength, required } from 'components/global/form/FormValidations'
import { useCategories, useOnboardingCategories } from 'apis/OrgManagement/categories/useCategories'
import { TCategory } from 'apis/OrgManagement/categories/useCategories.type'
import { useDepartmentsContext } from '../context'
import styles from '../../style.module.css'

type TDefaultFormProps = {
  hideParentInput?: boolean
}

const DefaultForm = ({ hideParentInput }: TDefaultFormProps): JSX.Element => {
  const { initialValues } = useFormState()
  const { change } = useForm()
  const { isInOnboarding } = useDepartmentsContext()
  const listDepartments = useSelector((s) => s.newDepartments.list_departments)
  const employeeAll = useSelector(({ eligibleEmployees }) => eligibleEmployees.employee_all)

  const { data: categories } = useCategories(
    { category_type: 'department' },
    {
      query: {
        select: ({ data }) => data.data.categories,
        enabled: !isInOnboarding,
      },
    },
  )

  const { data: onboardingCategories } = useOnboardingCategories(
    { category_type: 'department' },
    {
      query: {
        select: ({ data }) => data.data.categories,
        enabled: isInOnboarding,
      },
    },
  )

  return (
    <div className={styles.drawerContent}>
      <Frame>
        {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
        <TextInputField
          name="name"
          label={i18n.t('name_in_english_onboarding')}
          autoFocus={!initialValues?.id}
          validate={composeValidators(maxLength(100), required)}
        />
        <Spacer height={5} />
        {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
        <TextInputField
          name="name_ar"
          label={i18n.t('name_in_arabic')}
          validate={composeValidators(maxLength(100), required)}
        />
        <Spacer height={5} />
        {!hideParentInput && (
          <>
            <NewSelectField
              name="parent_id"
              valueKey="id"
              labelKey="name_i18n"
              options={listDepartments}
              label={i18n.t('parent_department_optional')}
              isClearable
              disabled={initialValues?.child_id}
            />
            <Spacer height={10} />
          </>
        )}
        {isInOnboarding ? (
          <NewSelectField
            isClearable
            valueKey="id"
            labelKey="name_i18n"
            name="category_ids[0]"
            label={`${i18n.t('category')} ${i18n.t('label.optional')}`}
            options={onboardingCategories ?? []}
            placeholder={i18n.t('select')}
          />
        ) : (
          <MultiSelectField
            options={categories ?? []}
            name="category_ids"
            validate={required}
            returnType="value"
            onChange={(list: TCategory[]): void => {
              if (list.length === 0) {
                change('category_ids', undefined)
              }
            }}
            label={i18n.t('category')}
            placeholder={i18n.t('select')}
            valueKey="id"
            labelKey="name_i18n"
          />
        )}

        <Spacer height={5} />
        {!isInOnboarding && (
          <NewSelectField
            name="manager_id"
            valueKey="id"
            labelKey="name"
            options={employeeAll}
            label={i18n.t('manager_optional')}
            isClearable
          />
        )}
        <Spacer height={5} />
      </Frame>
    </div>
  )
}

export default DefaultForm
