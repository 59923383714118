import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type PayrunSummaryType = {
  percentage: number
  text: string
  name: string
  value: string
}

export type PayrunTransactionSummaryType = { text: string; value: string; percentage?: number }

type PayrunComparingType = {
  difference: number
  percentage: number
  previous_month: string
  total_amount: number
}

type InitialStateType = {
  fetching: boolean
  additions_summary: PayrunTransactionSummaryType[]
  earnings_summary: PayrunTransactionSummaryType[]
  deductions_summary: PayrunTransactionSummaryType[]
  outsourcing_provider_summary: PayrunTransactionSummaryType[]
  payrun_summary: Record<
    'agency_fees' | 'total_amount' | 'earnings' | 'additions' | 'deductions',
    PayrunSummaryType
  > | null
  payrun_comparing: PayrunComparingType | null
  employment_types_summary: {
    employment_type: string
    number_of_employess: number
    total_amount: number
  }[]
  payment_types_summary: {
    number_of_employess: number
    payment_type: string
    total_amount: number
  }[]
  all_employees: number
  currency_type: string
  excluded_employees: number
  included_employees: number
  total_amount: string
}

const initialState: InitialStateType = {
  fetching: false,
  additions_summary: [],
  earnings_summary: [],
  deductions_summary: [],
  outsourcing_provider_summary: [],
  payrun_summary: null,
  payrun_comparing: null,
  employment_types_summary: [],
  payment_types_summary: [],
  all_employees: 0,
  excluded_employees: 0,
  included_employees: 0,
  currency_type: '',
  total_amount: '',
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYRUN_SUMMARY_VIEW_LOAD_FULFILLED: {
      const newData = action.payload.data.data as Omit<InitialStateType, 'fetching'>
      return {
        ...state,
        additions_summary: newData.additions_summary.map((it) => ({ ...it, name: it.text })),
        earnings_summary: newData.earnings_summary.map((it) => ({ ...it, name: it.text })),
        deductions_summary: newData.deductions_summary.map((it) => ({ ...it, name: it.text })),
        outsourcing_provider_summary: newData.outsourcing_provider_summary.map((it) => ({
          ...it,
          name: it.text,
        })),
        payrun_summary: newData?.payrun_summary,
        payrun_comparing: newData?.payrun_comparing,
        employment_types_summary: newData?.employment_types_summary,
        payment_types_summary: newData?.payment_types_summary,
        all_employees: newData?.all_employees,
        excluded_employees: newData?.excluded_employees,
        included_employees: newData?.included_employees,
        currency_type: newData?.currency_type,
        total_amount: newData?.total_amount || '0',
        fetching: false,
      }
    }
    case actions.PAYRUN_SUMMARY_VIEW_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYRUN_SUMMARY_VIEW_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
