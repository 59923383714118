import { Flex, Spacer, Button, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { statusLabel } from 'containers/authorised/setting/organizationSetting/BulkImportSheets/helper'
import DSIcon from 'components/DSIcon'

import styles from '../style.module.css'
import { statusImages, StatusValueType, UploadingProcessProps } from '../helper'

const statusProcess = (statusValue: StatusValueType): string => statusLabel[statusValue]
const handleProcess = (statusValue: StatusValueType): string => statusImages[statusValue]

const UploadingProcess = ({
  statusValue,
  metadata,
  errorFileUrl,
  s3Url,
}: UploadingProcessProps): JSX.Element => {
  return (
    <div style={{ width: 518 }}>
      <Flex
        itemsCenter
        className={styles.statusProcess}
      >
        {statusValue === 1 ? (
          <DSIcon
            name="loading-01"
            className={styles.rotate}
          />
        ) : (
          <img
            src={handleProcess(statusValue)}
            alt=""
            style={{ height: 14, width: 14 }}
          />
        )}
        <Spacer width={8} />
        <Typography
          variant="subtitle-1"
          style={{ color: 'var(--color-base-colors-black-500)' }}
          text={statusProcess(statusValue)}
        />
      </Flex>
      {statusValue !== 1 && (
        <>
          <Spacer height={24} />
          <div className={styles.statusProcess}>
            <Flex
              justifyBetween
              itemsCenter
            >
              <Flex itemsCenter>
                <DSIcon
                  name="check-circle"
                  styles={{ color: 'var(--color-feedback-success-default)' }}
                />
                <Spacer width={8} />
                <Typography
                  variant="interface/default/m"
                  style={{ color: 'var(--color-base-colors-black-500)' }}
                  text={I18n.t('imported_successfuly')}
                />
              </Flex>
              {s3Url && (
                <a href={s3Url}>
                  <Button
                    trailingIcon="download-03"
                    label={I18n.t('download_file')}
                    size="small"
                  />
                </a>
              )}
            </Flex>
            <Spacer height={16} />

            <Flex itemsCenter>
              <DSIcon
                name="alert-circle"
                styles={{ color: 'var(--color-feedback-warning-default' }}
              />
              <Spacer width={8} />
              <Typography
                variant="interface/default/sm"
                style={{ color: 'var(--color-base-colors-grey-600)' }}
                text={
                  <>
                    {I18n.t('faulty_records_new_records_added', {
                      added: metadata.success_count,
                      faulty: metadata.failed_count,
                    })}
                    {!!metadata.failed_count && (
                      <Typography
                        variant="interface/default/sm"
                        style={{ color: 'var(--color-base-colors-blue-600)' }}
                        text={<a href={errorFileUrl}> &nbsp;{I18n.t('download_faulty_records')}</a>}
                      />
                    )}
                  </>
                }
              />
            </Flex>
          </div>
        </>
      )}
    </div>
  )
}

export default UploadingProcess
