import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  ApiKeySummaryType,
  ApiGroupType,
  ApiKeyDetails,
} from 'containers/authorised/setting/v2/integration/OpenApiKeys/types'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationExtendedTypes } from 'types/sharedResponse'
import * as actions from './actions'

type InitStateType = {
  responseMessage: { message: string; type: string }
  fetching: boolean
  openApisKeyList: ApiKeySummaryType[]
  pagination: PaginationExtendedTypes
  apiGroups: ApiGroupType[]
  selectedApiKeyDetails: ApiKeyDetails | Record<string, never>
  resetPageData: boolean
}

const initialState: InitStateType = {
  fetching: false,
  responseMessage: { message: '', type: '' },
  openApisKeyList: [],
  pagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
  apiGroups: [],
  selectedApiKeyDetails: {},
  resetPageData: false,
}

const addSelectAllValue = (apiGroupsData: ApiGroupType[]): ApiGroupType[] => {
  return apiGroupsData.map((group: ApiGroupType) => {
    return {
      ...group,
      select_all: group.actions.every((a) => a.is_selected),
    }
  })
}

const updateKeyDetails = (selectedKeyData: ApiKeyDetails): ApiKeyDetails => {
  if (selectedKeyData?.api_groups) {
    selectedKeyData = {
      ...selectedKeyData,
      api_groups: addSelectAllValue(selectedKeyData.api_groups),
    }
  }
  return selectedKeyData
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_OPEN_API_KEYS_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        resetPageData: false,
      }

    case actions.GET_OPEN_API_GROUP_LIST_PENDING:
    case actions.CREATE_OPEN_API_KEY_PENDING:
    case actions.VIEW_OPEN_API_KEY_PENDING:
    case actions.UPDATE_OPEN_API_KEY_PENDING:
    case actions.DELETE_OPEN_API_KEY_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CREATE_OPEN_API_KEY_FULFILLED:
      let selectedApiData: ApiKeyDetails = payload.data.data || {}
      selectedApiData = updateKeyDetails(selectedApiData)
      return {
        ...state,
        fetching: false,
        selectedApiKeyDetails: selectedApiData,
        responseMessage: { message: payload.data.message, type: 'success' },
      }

    case actions.GET_OPEN_API_KEYS_LIST_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      const { data } = payload.data
      return {
        ...state,
        fetching: false,
        openApisKeyList: data.open_api_keys ?? [],
        pagination: data.pagination ?? {},
        resetPageData: false,
      }
    }
    case actions.VIEW_OPEN_API_KEY_FULFILLED: {
      let selectedApiDataView: ApiKeyDetails = payload.data.data || {}
      selectedApiDataView = updateKeyDetails(selectedApiDataView)
      return {
        ...state,
        fetching: false,
        selectedApiKeyDetails: selectedApiDataView,
      }
    }

    case actions.UPDATE_OPEN_API_KEY_FULFILLED: {
      let selectedApiDataEdit: ApiKeyDetails = payload.data.data || {}
      selectedApiDataEdit = updateKeyDetails(selectedApiDataEdit)
      return {
        ...state,
        selectedApiKeyDetails: selectedApiDataEdit,
        fetching: false,
        responseMessage: { message: payload.data.message, type: 'success' },
      }
    }

    case actions.DELETE_OPEN_API_KEY_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.GET_OPEN_API_GROUP_LIST_FULFILLED: {
      let apiGroupsData: ApiGroupType[] = payload.data.data.api_groups
      apiGroupsData = addSelectAllValue(apiGroupsData)
      return {
        ...state,
        apiGroups: apiGroupsData,
        fetching: false,
      }
    }
    case actions.GET_OPEN_API_KEYS_LIST_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        resetPageData: true,
      }
    }

    case actions.VIEW_OPEN_API_KEY_REJECTED:
    case actions.GET_OPEN_API_GROUP_LIST_REJECTED:
    case actions.DELETE_OPEN_API_KEY_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.CREATE_OPEN_API_KEY_REJECTED:
    case actions.UPDATE_OPEN_API_KEY_REJECTED: {
      return {
        ...state,
        fetching: false,
        responseMessage: { message: handleResponseErr(payload), type: 'error' },
      }
    }

    default:
      return state
  }
}
