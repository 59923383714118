import { FC } from 'react'
import clsx from 'classnames'
import { SpringValue, animated } from 'react-spring'
import { Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { ReactComponent as MudadImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/mudad.svg'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import { useSelector } from 'utils/hooks'

import styles from '../styles.module.css'
import PaymentBankDetails from './PaymentBankDetails'
import { usePaymentMethod } from '../../components/usePaymentMethods'
import PaymentConfigMenu from '../../components/PaymentConfigMenu'

type LinkedPaymentCardPropsType = {
  paymentConfiguration: PaymentConfigurationType
  style: {
    opacity: SpringValue<number>
    transform: SpringValue<string>
  }
}

const PAYMENT_NAME = {
  mudad: i18n.t('mudad'),
  offline: i18n.t('offline_method'),
}

const LinkedPaymentCard: FC<LinkedPaymentCardPropsType> = (props) => {
  const { style, paymentConfiguration } = props
  const {
    id,
    name,
    mol_registration,
    registration_number,
    active_payment_method,
    bank_accounts,
    owner_identification_number,
    unified_mol_number,
  } = paymentConfiguration

  const paymentCtx = usePaymentMethod()
  const fetching = useSelector((s) => s.paymentMethods.fetchingShowMethod)

  return (
    <animated.div
      key={id}
      style={style}
      className={clsx(
        mainStyles.main_widget,
        styles.linked_card__main__container,
        mainStyles.grid_card_item,
      )}
    >
      <MudadImg />

      <div className={styles.payment_card_content_box}>
        {/* ? header */}
        <div
          className={styles.payment_card_content_header}
          style={{ marginBottom: 24 }}
        >
          <div>
            {!!active_payment_method && (
              <Typography
                variant="subtitle-1"
                style={{
                  color: 'var(--color-base-colors-grey-800)',
                  marginBottom: 12,
                  fontWeight: 600,
                  lineHeight: '20px',
                }}
                text={`${PAYMENT_NAME[active_payment_method]} - ${name}`}
              />
            )}
            <div className={mainStyles.payment_info_divider}>
              <Typography
                variant="interface/default/sm"
                className={mainStyles.primary_text}
                text={`${i18n.t('commercial_registration')} : ${registration_number}`}
              />
              <Typography
                variant="interface/default/sm"
                className={mainStyles.primary_text}
                text={`${i18n.t('mol_registration_number')} :${' '} ${
                  mol_registration.mol_registration_number
                }`}
              />

              {!!owner_identification_number && (
                <Typography
                  variant="interface/default/sm"
                  className={mainStyles.primary_text}
                  text={i18n.t('gosi_owner_number', {
                    number: owner_identification_number,
                  })}
                />
              )}
              {!!unified_mol_number && (
                <Typography
                  variant="interface/default/sm"
                  className={mainStyles.primary_text}
                  text={`${i18n.t('unified_mol_no')} : ${unified_mol_number}`}
                />
              )}
            </div>
          </div>
          {/* ? this should be shared */}
          <PaymentConfigMenu
            disabled={fetching}
            options={[
              {
                name: i18n.t('authorize_employees_edit_bank'),
                onClick: (): void => {
                  paymentCtx.handleMethodModalOpen(paymentConfiguration, active_payment_method)
                },
              },
              ...(!paymentCtx.isOnBoarding
                ? [
                    {
                      name: i18n.t('manage_bank_account'),
                      onClick: (): void => {
                        paymentCtx.handleManagementPaymentOpen(paymentConfiguration)
                      },
                    },
                  ]
                : []),
            ]}
          />
        </div>

        {/* ? banks list */}
        <PaymentBankDetails
          bank_accounts={bank_accounts}
          activeMethod={paymentConfiguration.active_payment_method}
        />
      </div>
    </animated.div>
  )
}

export default LinkedPaymentCard
