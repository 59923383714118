import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { withLenses, lens } from '@dhmk/zustand-lens'
import { GosiType, createGosiSlice } from './createGosiSlice'
import { createConfirmationSlice, CreateConfirmationType } from './createConfirmationSlice'

interface GlobalStoreType {
  gosi: GosiType
  notification: CreateConfirmationType
}

export const useGlobalStore = create<GlobalStoreType>()(
  devtools(
    withLenses(() => ({
      gosi: lens<GosiType>(createGosiSlice),
      notification: lens(createConfirmationSlice),
    })),
    {
      name: 'GlobalStore',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
)
