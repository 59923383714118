export const RESET = 'RESET'

export const TIME_AND_ATTENDANCE_DETAILS = 'TIME_AND_ATTENDANCE_DETAILS'
export const TIME_AND_ATTENDANCE_DETAILS_FULFILLED = 'TIME_AND_ATTENDANCE_DETAILS_FULFILLED'
export const TIME_AND_ATTENDANCE_DETAILS_PENDING = 'TIME_AND_ATTENDANCE_DETAILS_PENDING'

export const TIME_AND_ATTENDANCE_LOAD = 'TIME_AND_ATTENDANCE_LOAD'
export const TIME_AND_ATTENDANCE_LOAD_FULFILLED = 'TIME_AND_ATTENDANCE_LOAD_FULFILLED'
export const TIME_AND_ATTENDANCE_LOAD_PENDING = 'TIME_AND_ATTENDANCE_LOAD_PENDING'
export const TIME_AND_ATTENDANCE_LOAD_REJECTED = 'TIME_AND_ATTENDANCE_LOAD_REJECTED'

export const TIME_AND_ATTENDANCE_RECORDS = 'TIME_AND_ATTENDANCE_RECORDS'
export const TIME_AND_ATTENDANCE_RECORDS_PENDING = 'TIME_AND_ATTENDANCE_RECORDS_PENDING'
export const TIME_AND_ATTENDANCE_RECORDS_FULFILLED = 'TIME_AND_ATTENDANCE_RECORDS_FULFILLED'
export const TIME_AND_ATTENDANCE_RECORDS_REJECTED = 'TIME_AND_ATTENDANCE_RECORDS_REJECTED'

export const SET_ATTENDANCE_FILTERS = 'SET_ATTENDANCE_FILTERS'
export const CLEAR_ATTENDANCE_FILTERS = 'CLEAR_ATTENDANCE_FILTERS'

export const OVERTIME_LOGS_LOAD = 'OVERTIME_LOGS_LOAD'
export const OVERTIME_LOGS_LOAD_PENDING = 'OVERTIME_LOGS_LOAD_PENDING'
export const OVERTIME_LOGS_LOAD_FULFILLED = 'OVERTIME_LOGS_LOAD_FULFILLED'
export const OVERTIME_LOGS_LOAD_REJECTED = 'OVERTIME_LOGS_LOAD_REJECTED'

export const DELETE_OVERTIME_LOG = 'DELETE_OVERTIME_LOG'
export const DELETE_OVERTIME_LOG_PENDING = 'DELETE_OVERTIME_LOG_PENDING'
export const DELETE_OVERTIME_LOG_FULFILLED = 'DELETE_OVERTIME_LOG_FULFILLED'
export const DELETE_OVERTIME_LOG_REJECTED = 'DELETE_OVERTIME_LOG_REJECTED'

export const ADD_OVERTIME_LOG = 'ADD_OVERTIME_LOG'
export const ADD_OVERTIME_LOG_PENDING = 'ADD_OVERTIME_LOG_PENDING'
export const ADD_OVERTIME_LOG_FULFILLED = 'ADD_OVERTIME_LOG_FULFILLED'
export const ADD_OVERTIME_LOG_REJECTED = 'ADD_OVERTIME_LOG_REJECTED'

export const ATTENDANCE_RECORD_LOAD = 'ATTENDANCE_RECORD_LOAD'
export const ATTENDANCE_RECORD_LOAD_PENDING = 'ATTENDANCE_RECORD_LOAD_PENDING'
export const ATTENDANCE_RECORD_LOAD_FULFILLED = 'ATTENDANCE_RECORD_LOAD_FULFILLED'
export const ATTENDANCE_RECORD_LOAD_REJECTED = 'ATTENDANCE_RECORD_LOAD_REJECTED'
