import swal from 'sweetalert'
import I18n from 'translations/i18n'

const defaultArgs = {
  title: I18n.t('are_you_sure'),
  successTitle: I18n.t('successfully'),
  faildTitle: I18n.t('faild'),
  confirmButtonColor: 'var(--color-brand-primary-default-new)',
  onSuccess: () => {},
}

export function confirmAlert(arg) {
  const args = {
    ...defaultArgs,
    ...arg,
  }

  swal({
    title: args.title,
    icon: 'warning',
    text: args.text || '',
    buttons: {
      cancel: {
        text: I18n.t('cancel'),
        className: 'swal--cancel-btn',
        visible: true,
      },
      confirm: {
        text: args.confirmText || I18n.t('Yes'),
        closeModal: false,
        className: 'swal--confirem-btn',
      },
    },
    ...(args.dangerMode && { dangerMode: true }),
  })
    .then((confirm) => {
      if (!confirm) {
        if (args.onCancel) {
          args.onCancel()
        }
        // eslint-disable-next-line no-throw-literal
        throw null
      }
      return args.action()
    })
    .then(() => {
      args.onSuccess()
      if (args.hideSuccessPopup) {
        swal.close()
        return
      }

      swal({
        title: args.successTitle,
        text: '...',
        icon: 'success',
        button: false,
        timer: 2000,
      })
    })
    .catch((error) => {
      if (error) {
        const err = error.response ? error.response.data.error : I18n.t('something_went_wrong')
        swal({
          text: err,
          icon: 'error',
          buttons: {
            confirm: {
              text: I18n.t('okay'),
              closeModal: true,
              className: 'swal--confirem-btn',
            },
          },
        })
      } else {
        swal.stopLoading()
        swal.close()
      }
    })
}
