import { FC } from 'react'
import { Form, BetaTextInputField as TextInputField } from 'components/final-form'
import { required, composeValidators, number } from 'components/global/form/FormValidations'
import { Button, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'

type OwnerFormType = {
  onSubmit: (arg: { identification_number: string }) => void
  initialValues: { identification_number?: string }
}

const OwnerForm: FC<OwnerFormType> = (props) => {
  const { initialValues } = props
  const fetching = useSelector((s) => s.mudadIntegration.fetching)
  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, valid, pristine }): JSX.Element => {
        return (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ maxWidth: 600 }}
          >
            <Flex
              itemsStart
              style={{ gap: 24 }}
            >
              <TextInputField
                name="identification_number"
                label={i18n.t('owner_national_id_iqama')}
                validate={composeValidators(required, number)}
              />
              <Button
                label={i18n.t('verify')}
                type="submit"
                size="medium"
                disabled={pristine || !valid || fetching}
                style={{ marginTop: 24, flexShrink: 0 }}
              />
            </Flex>
          </form>
        )
      }}
    </Form>
  )
}

export default OwnerForm
