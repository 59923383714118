import { useEffect } from 'react'
import i18n from 'translations/i18n'
import {
  approvalDetailReset,
  taskDetailLoad,
  updateTask,
} from 'redux/requests/newRequestsApproval/actionCreators'
import { Flex, Containers, Spacer, Icon, Typography, Badge, Label, Button } from '@jisr-hr/ds'
import { format } from 'utils/date'
import { BetaTextInputField as TextInputField, SwitchField } from 'components/final-form'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import {
  composeValidators,
  maximum,
  minValue,
  number,
  required,
  validateDecimalPlaces,
} from 'components/global/form/FormValidations'
import { FieldArray } from 'react-final-form-arrays'
import { ReassignTask } from 'components/global/templates/RequestDrawer/components'
import InfoList from 'components/global/templates/RequestDrawer/ds/InfoList'
import { items } from 'components/global/templates/RequestDrawer/components/Info/helper'
import { omit } from 'lodash'
import EmployeeProfile from 'components/global/templates/RequestDrawer/ds/EmployeeProfile'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  getGroupDetails,
  saveTaskChanges,
  resetTask,
} from 'redux/performanceV2/evaluationForms/actionCreators'
import { TaskCriteriaType } from 'redux/performanceV2/evaluationForms/reducer'
import { usePerformanceStore } from 'store/performance/usePerformanceStore'
import { useActionTaskRequest } from 'apis/OrgManagement/requests/useRequests'
import { useRequest } from '../../RequestProvider'
import DueDate from '../../components/DueDate'

type FormValue = {
  criteria: TaskCriteriaType[]
}

const PerformanceGroupTask = (): JSX.Element => {
  const dispatch = useDispatch()
  const taskGroup = useSelector((state) => state.evaluationForms.taskGroup)
  const fetching = useSelector((state) => state.evaluationForms.fetching)
  const status = usePerformanceStore((s) => s.EvaluationSteps.status)
  const setStatus = usePerformanceStore((s) => s.EvaluationSteps.setStatus)

  const { mutateAsync: onActionTask } = useActionTaskRequest()

  const {
    drawerToggle,
    vars: { request },
  } = useRequest()

  const initCriterions = taskGroup?.criterions?.length
    ? taskGroup?.criterions
    : [{ name_en: '', weight: '' }]
  const validateDuplicateName = (name: string, val: FormValue, key: 'name_en' | 'name_ar') => {
    if (!name) {
      return i18n.t('required')
    }
    const duplicateCount = val?.criteria.filter(
      (crt) => crt[key]?.trim().toLocaleLowerCase() === name.trim().toLocaleLowerCase(),
    ).length
    return duplicateCount >= 2 ? i18n.t('criterion_with_the_same_name_already_exists') : undefined
  }

  const onSubmit = async (value: FormValue): Promise<void> => {
    const existingIds = value.criteria.reduce((acc: number[], currentValue) => {
      if (currentValue?.id) {
        acc.push(currentValue?.id)
      }
      return acc
    }, [])
    const deletedIds =
      taskGroup?.criterions &&
      taskGroup?.criterions.reduce((accumulator: number[], currentValue) => {
        if (currentValue?.id && !existingIds.includes(currentValue?.id)) {
          accumulator.push(currentValue?.id)
        }
        return accumulator
      }, [])
    const data = {
      group: {
        deleted_criterion_ids: deletedIds && deletedIds?.length > 0 ? deletedIds : undefined,
        criterions: value.criteria.map((item: TaskCriteriaType) => ({
          ...omit(item, 'showAr', 'form_id', 'group_id'),
          weight: Number(item?.weight),
          created_through: 'task',
          name_ar: item?.name_ar || undefined,
        })),
      },
    }
    dispatch(saveTaskChanges(data, request?.group_details?.id)).then(async () => {
      if (status === 'completed') {
        await onActionTask({ taskId: request.id, status: 'complete' })
        dispatch(taskDetailLoad(request.id))
      }
      setStatus(null)
      drawerToggle()
      dispatch(approvalDetailReset())
    })
  }

  const handelDueDate = (e: string | Date | null): void => {
    const dueDate = e ? new Date(e) : undefined
    dispatch(
      updateTask(request.id, {
        task: {
          due_date: dueDate,
        },
      }),
    )
  }

  useEffect(() => {
    if (request?.group_details?.id && request?.status === 'Pending') {
      dispatch(getGroupDetails(request?.group_details?.id))
    }
    return () => {
      dispatch(resetTask())
    }
  }, [request?.group_details?.id])

  return (
    <div className="flex flex-col gap-[16px] relative">
      <Containers
        border
        borderRadius="sm"
        color="white"
        className="p-[16px]"
      >
        <EmployeeProfile
          name={request.employee?.name_i18n}
          jobTitle={request.employee?.job_title}
          code={request.employee?.code}
          avatar={request.employee?.avatar_thumb}
        />
        <Spacer height={16} />
        <InfoList
          items={items({ status: request.status, status_i18n: request?.status_i18n })
            ?.filter(({ key }) => ['status']?.includes(key))
            .concat([
              {
                label: i18n.t('date'),
                icon: (
                  <Icon
                    name="calendar"
                    size="xs"
                  />
                ),
                value: (
                  <Typography
                    variant="body-new/bold"
                    text={format(request?.created_at, 'EEEE, dd MMM')}
                  />
                ),
              },
              {
                label: i18n.t('due_date'),
                icon: (
                  <Icon
                    name="calendar"
                    size="xs"
                  />
                ),
                value: (
                  <DueDate
                    showAction
                    runAction={handelDueDate}
                    dateValue={request.due_date}
                  />
                ),
              },
            ])}
        />
      </Containers>
      <Containers
        border
        borderRadius="sm"
        color="white"
        className="p-[16px] flex flex-col gap-[16px]"
      >
        <Typography
          variant="heading"
          text={i18n.t('note')}
        />
        <Typography text={taskGroup?.task?.note} />
      </Containers>
      {request?.status === 'Pending' && <ReassignTask />}
      {(taskGroup || request?.status !== 'Pending') && (
        <Form
          onSubmit={onSubmit}
          initialValues={{
            criteria:
              request?.status === 'Pending'
                ? initCriterions
                : request?.group_details?.criterions || [],
          }}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators,
          }}
          validate={(values: {
            criteria: Record<string, unknown>[]
          }): { err?: string } | undefined => {
            if (taskGroup) {
              if (values?.criteria?.length) {
                const criteria = values?.criteria
                const weightSum = criteria?.length
                  ? criteria?.reduce((total, criterias) => total + Number(criterias?.weight), 0)
                  : 0
                if (taskGroup.weight !== weightSum && status === 'completed') {
                  return {
                    err: i18n.t('group_weight_equal_message', { weight: taskGroup.weight }),
                  }
                }
                if (taskGroup.weight < weightSum) {
                  return {
                    err: i18n.t('group_weight_not_exceed_message', { weight: taskGroup.weight }),
                  }
                }
                return undefined
              }
            } else {
              return { err: 'At Least one criteria should be present' }
            }

            return undefined
          }}
        >
          {({ submitFailed, handleSubmit, errors, values, form: { change } }): JSX.Element => (
            <form
              id="task-id"
              onSubmit={handleSubmit}
            >
              <Containers
                border
                borderRadius="sm"
                color="white"
                className="p-[16px]"
              >
                <Flex
                  flexCol
                  className="gap-[16px]"
                >
                  <Flex
                    flexRow
                    className="gap-[12px]"
                    itemsCenter
                  >
                    <Typography
                      text={
                        request?.status === 'Pending'
                          ? taskGroup?.name_i18n
                          : request?.group_details?.name_i18n
                      }
                      variant="body-new/semibold"
                    />
                    <Flex
                      className="gap-[12px]"
                      itemsCenter
                    >
                      <Badge
                        label={
                          request?.status === 'Pending' && taskGroup
                            ? `${taskGroup.weight}%`
                            : `${request?.group_details?.weight}%`
                        }
                      />
                      <Badge
                        label={
                          request?.status === 'Pending' && taskGroup
                            ? `${taskGroup?.rating_method_i18n}`
                            : `${request?.group_details?.rating_method_i18n}`
                        }
                      />
                    </Flex>
                  </Flex>

                  {submitFailed && errors?.err && (
                    <Typography
                      text={errors?.err}
                      textColor="color/fg/danger/default"
                      variant="subtitle-1"
                    />
                  )}

                  <FieldArray name="criteria">
                    {({ fields: goalF }): JSX.Element[] =>
                      goalF.map((criterias, i) => (
                        <Containers
                          color="white"
                          key={i}
                          className="p-4 space-y-3"
                          border
                          borderRadius="md"
                        >
                          <Flex justifyBetween>
                            <Label
                              name={i18n.t('criteria_name_en')}
                              className="w-[210px]"
                            >
                              <TextInputField
                                testId={`task_name_en_${i}`}
                                name={`${criterias}.name_en`}
                                placeholder={i18n.t('product_placeholder')}
                                size="regular"
                                validate={(name: string, val: FormValue): string | undefined =>
                                  validateDuplicateName(name, val, 'name_en')
                                }
                                disabled={
                                  request?.status !== 'Pending' || !request.can_mark_as_completed
                                }
                              />
                            </Label>

                            <Flex className="gap-[10px] w-[200px]">
                              <Button
                                variant="outlined"
                                color="neutral"
                                onClick={(): void => {
                                  let shouldShow = true
                                  if (values.criteria[i]?.showAr) {
                                    shouldShow = false
                                  }
                                  const criteriaArr = [...values.criteria]
                                  criteriaArr[i] = { ...criteriaArr[i], showAr: shouldShow }
                                  change(`criteria`, criteriaArr)
                                }}
                                type="button"
                                className="mt-[20px]"
                                iconOnly
                                leadingIcon="globe-02"
                                testId="lang-button"
                                disabled={!request.can_mark_as_completed}
                              />
                              <Label name={i18n.t('weight')}>
                                <TextInputField
                                  name={`${criterias}.weight`}
                                  testId={`task_weight_${i}`}
                                  size="regular"
                                  validate={composeValidators(
                                    required,
                                    number,
                                    minValue(0.01),
                                    maximum(100),
                                    validateDecimalPlaces(),
                                  )}
                                  suffix="%"
                                  disabled={
                                    request?.status !== 'Pending' || !request.can_mark_as_completed
                                  }
                                />
                              </Label>
                              <Button
                                variant="ghost"
                                leadingIcon="trash-01"
                                iconOnly
                                type="button"
                                color="neutral"
                                className="mt-[20px]"
                                onClick={(): void => {
                                  const val = [...values.criteria]
                                  val.splice(i, 1)
                                  change(`criteria`, val)
                                }}
                                disabled={
                                  values.criteria.length === 1 ||
                                  request?.status !== 'Pending' ||
                                  !request.can_mark_as_completed
                                }
                              />
                            </Flex>
                          </Flex>
                          {values.criteria[i]?.showAr && (
                            <Label
                              name={i18n.t('criteria_name_ar')}
                              className="mt-[20px]"
                            >
                              <TextInputField
                                name={`${criterias}.name_ar`}
                                placeholder={i18n.t('prodct_name_placeholder')}
                                size="regular"
                                validate={(name: string, val: FormValue): string | undefined =>
                                  validateDuplicateName(name, val, 'name_ar')
                                }
                                disabled={
                                  request?.status !== 'Pending' || !request.can_mark_as_completed
                                }
                              />
                            </Label>
                          )}
                          <SwitchField
                            name={`${criterias}.require_remarks`}
                            label={i18n.t('label.require_remarks')}
                            description={i18n.t('communication.require_remarks_description')}
                            disabled={
                              request?.status !== 'Pending' || !request.can_mark_as_completed
                            }
                          />
                        </Containers>
                      ))
                    }
                  </FieldArray>
                  <Button
                    className="justify-start w-max"
                    variant="ghost"
                    label={i18n.t('new_criteria')}
                    leadingIcon="plus"
                    type="button"
                    onClick={(): void => {
                      change(`criteria`, [
                        ...values.criteria,
                        { name_en: '', name_ar: '', weight: '', showAr: false },
                      ])
                    }}
                    disabled={
                      (values.criteria?.length > 0 &&
                        (!values.criteria[values.criteria?.length - 1]?.name_en ||
                          !values.criteria[values.criteria?.length - 1]?.weight)) ||
                      request?.status !== 'Pending' ||
                      !request.can_mark_as_completed
                    }
                  />
                </Flex>
              </Containers>
            </form>
          )}
        </Form>
      )}
      {fetching && <JisrLoader absolute />}
    </div>
  )
}

export default PerformanceGroupTask
