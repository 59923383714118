import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type RequestsState = {
  fetching: boolean
  mudad_subscriptions_history: {
    action_at: string
    action_by_details: {
      action_by_name_i18n: string
      by_employee: boolean
    }
    status: 'inactive' | 'active' | 'renewed' | 'expired' | 'deleted'
    status_i18n: string
    employee_id: number
    id: number
  }[]
}

const initialState: RequestsState = {
  fetching: false,
  mudad_subscriptions_history: [],
}

export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.MUDAD_SUBSCRIPTIONS_HISTORY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.MUDAD_SUBSCRIPTIONS_HISTORY_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        mudad_subscriptions_history: payload.data.data,
      }
    case actions.MUDAD_SUBSCRIPTIONS_HISTORY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
