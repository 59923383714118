import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  importFetching: false,
  exportFetching: false,
  unavailable_records: {
    count: 0,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_PENDING:
      return {
        ...state,
        importFetching: true,
      }

    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_PENDING:
      return {
        ...state,
        exportFetching: true,
      }

    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_FULFILLED:
      return {
        ...state,
        ...action.payload.data,
        importFetching: false,
      }

    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_FULFILLED:
      return {
        ...state,
        exportFetching: false,
      }

    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_FULFILLED:
      return {
        ...state,
        unavailable_records: {
          ...state.unavailable_records,
          ...action.payload.data.data,
        },
      }

    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_REJECTED:
    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_REJECTED:
    case actions.UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        importFetching: false,
        exportFetching: false,
      }

    default:
      return state
  }
}
