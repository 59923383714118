export const XERO_LOAD_ACCOUNT_STATUS = 'XERO_LOAD_ACCOUNT_STATUS'
export const XERO_LOAD_ACCOUNT_STATUS_PENDING = 'XERO_LOAD_ACCOUNT_STATUS_PENDING'
export const XERO_LOAD_ACCOUNT_STATUS_FULFILLED = 'XERO_LOAD_ACCOUNT_STATUS_FULFILLED'
export const XERO_LOAD_ACCOUNT_STATUS_REJECTED = 'XERO_LOAD_ACCOUNT_STATUS_REJECTED'

export const XERO_DISCONNECT_ACCOUNT = 'XERO_DISCONNECT_ACCOUNT'
export const XERO_DISCONNECT_ACCOUNT_PENDING = 'XERO_DISCONNECT_ACCOUNT_PENDING'
export const XERO_DISCONNECT_ACCOUNT_FULFILLED = 'XERO_DISCONNECT_ACCOUNT_FULFILLED'
export const XERO_DISCONNECT_ACCOUNT_REJECTED = 'XERO_DISCONNECT_ACCOUNT_REJECTED'

export const XERO_LOAD_ACCOUNT_INFO = 'XERO_LOAD_ACCOUNT_INFO'
export const XERO_LOAD_ACCOUNT_INFO_PENDING = 'XERO_LOAD_ACCOUNT_INFO_PENDING'
export const XERO_LOAD_ACCOUNT_INFO_FULFILLED = 'XERO_LOAD_ACCOUNT_INFO_FULFILLED'
export const XERO_LOAD_ACCOUNT_INFO_REJECTED = 'XERO_LOAD_ACCOUNT_INFO_REJECTED'

export const UPDATE_XERO_ACCOUNT_INFO = 'UPDATE_XERO_ACCOUNT_INFO'
export const UPDATE_XERO_ACCOUNT_INFO_PENDING = 'UPDATE_XERO_ACCOUNT_INFO_PENDING'
export const UPDATE_XERO_ACCOUNT_INFO_FULFILLED = 'UPDATE_XERO_ACCOUNT_INFO_FULFILLED'
export const UPDATE_XERO_ACCOUNT_INFO_REJECTED = 'UPDATE_XERO_ACCOUNT_INFO_REJECTED'

export const SYNC_XERO_EMPLOYEE = 'SYNC_XERO_EMPLOYEE'
export const SYNC_XERO_EMPLOYEE_PENDING = 'SYNC_XERO_EMPLOYEE_PENDING'
export const SYNC_XERO_EMPLOYEE_FULFILLED = 'SYNC_XERO_EMPLOYEE_FULFILLED'
export const SYNC_XERO_EMPLOYEE_REJECTED = 'SYNC_XERO_EMPLOYEE_REJECTED'
