/** **************
 *  letterRequestsActions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function letterRequestCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/letter_requests/`
  const method = 'POST'
  return {
    type: 'LETTER_REQUEST_CREATE',
    payload: handleAPI(url, {}, method, { letter_request: data }),
  }
}
export function letterRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/letter_requests/${id}`
  const method = 'PUT'
  return {
    type: 'LETTER_REQUEST_UPDATE',
    payload: handleAPI(url, {}, method, { letter_request: data }),
  }
}
