import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { formatDistanceToNow, isToday } from 'date-fns'
import { arSA, enUS } from 'date-fns/locale'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import _, { omitBy } from 'lodash'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  notificationsLoad,
  resetNotifications,
  toggleNotificationsPanel,
} from 'redux/appNotifications/actionCreators'
import { TNotification } from 'redux/appNotifications/reducer'
import { unreadNotificationsLoad } from 'redux/appNotifications/markAsRead/actionCreators'
import {
  approvalDetailReset,
  taskDetailLoad,
} from 'redux/requests/newRequestsApproval/actionCreators'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import JisrLoader from 'components/global/JisrLoader'
import Lazyload, { Scrolling } from 'components/global/atoms/Lazyload'
import RequestDrawer from 'components/global/templates/RequestDrawer'
import RequestDetails from 'containers/authorised/requests/components/RequestDetails'
import { isRtl } from 'utils/uiHelpers'
import DSIcon from 'components/DSIcon'
import { Avatars, Typography, Flex, Spacer, Tab, Modal } from '@jisr-hr/ds'
import { hasPermission } from 'components/global/HelperFunctions'
import { useViolationsStore } from 'store/violations/useViolationsStore'
import NotificationsSettings from './NotificationsSettings'
import NotificationsGuide from '../NotificationsGuide'
import styles from '../styles.module.css'
import { TTab } from '../types'
import { isChrome, isEdge, isFirefox, isSafari } from './helper'
import { AtsCategory } from './types'

const locales = { en: enUS, ar: arSA }
const LOCALE = localStorage.getItem('Locale') ?? 'en'

const NOTIFICATION_ICON = {
  new_joining: 'user-plus-01',
  birthday: 'cake-candles',
  anniversary: 'party-horn',
}

const ATS_CATEGORIES: AtsCategory[] = [
  'apply_via_form',
  'moved_to_custom_stage',
  'assigned_manually',
  'job_edited',
  'added_as_employee',
  'job_closed',
  'job_reopened',
  'hired',
  'rejected',
  'employee_mentioned',
]

const NoNotifications = (): JSX.Element => (
  <Flex
    itemsCenter
    justifyCenter
    flexCol
    style={{ height: 'calc(100vh - 162px)', gap: 16 }}
  >
    <Flex
      itemsCenter
      justifyCenter
      className={styles.nodataIconContainer}
    >
      <DSIcon
        name="check"
        size="lg"
      />
    </Flex>
    <Typography
      text={I18n.t('no_new_notifications')}
      variant="subheading"
      textColor="color/fg/bold"
    />
  </Flex>
)

const NotificationItem = ({
  title,
  message,
  created_at,
  category,
  employee,
  is_read,
  data,
  testId,
  handleView,
  handleATSNotification,
  handleClickViolation,
}: TNotification): JSX.Element => {
  const isRequest = [
    'visible_approval',
    'request_approved',
    'request_rejected',
    'request_expired',
    'request_pending_for_correction',
    'bypass_approval',
    'notify_approver',
  ].includes(category)

  const isTask = category === 'task'
  const isATS = ATS_CATEGORIES.includes(category as unknown as AtsCategory)
  const onClick = (): void | undefined => {
    if (handleView && isRequest && data?.employee_id) handleView()
    else if (handleView && isTask) handleView()
    else if (handleATSNotification && isATS) handleATSNotification()
    else if (data?.class_name === 'Violation') handleClickViolation?.()
    return undefined
  }

  return (
    <Lazyload fallback={(): JSX.Element => <>{I18n.t('loading')}</>}>
      <Flex
        className={cn(
          styles.notificationItem,
          { [styles.unread]: !is_read },
          {
            [styles.pointer]: (data?.employee_id && isRequest) || isTask || isATS,
          },
        )}
        style={{ gap: 16 }}
        data-testid={testId}
        onClick={onClick}
      >
        <Flex
          itemsCenter
          justifyCenter
          className={cn({
            [styles.imgContainer]: !isRequest && !isTask,
          })}
        >
          {isRequest || isTask ? (
            <Avatars
              type="initials"
              imageSrc={employee?.avatar_thumb}
              imageAlt={employee?.full_name_i18n}
              size="l"
            />
          ) : (
            <DSIcon
              name={NOTIFICATION_ICON[category] ?? 'bell-03'}
              color="var(--color-natural-gray-darker-1-new)"
              size="ml"
            />
          )}
        </Flex>
        <div style={{ flex: 1 }}>
          <Typography
            variant="subheading"
            text={title ?? message}
          />
          <Typography
            text={formatDistanceToNow(new Date(created_at), {
              addSuffix: true,
              locale: locales[LOCALE],
            })}
            variant="subheading"
            textColor="color/fg/lighter"
          />
        </div>
      </Flex>
    </Lazyload>
  )
}

export const guideContent = (): JSX.Element => {
  const chromeGuide = (
    <Typography
      text={
        <Trans
          i18nKey="chrome_notifications_guide"
          components={{
            br: <br />,
            b: <b />,
            Dots: (
              <DSIcon
                name="dots-vertical"
                size="md"
                color="var(--color-natural-black-default)"
              />
            ),
            whiteLabel: <>Jisr</>,
          }}
        />
      }
      variant="subheading"
      textColor="color/fg/lighter"
    />
  )

  const edgeGuide = (
    <Typography
      text={
        <Trans
          i18nKey="edge_notifications_guide"
          components={{
            br: <br />,
            b: <b />,
            Dots: (
              <DSIcon
                name="dots-horizontal"
                size="md"
                color="var(--color-natural-black-default)"
              />
            ),
            whiteLabel: <>Jisr</>,
          }}
        />
      }
      variant="subheading"
      textColor="color/fg/lighter"
    />
  )

  const firefoxGuide = (
    <Typography
      text={
        <Trans
          i18nKey="firefox_notifications_guide"
          components={{
            br: <br />,
            b: <b />,
            Bars: (
              <DSIcon
                name="menu-01"
                size="md"
                color="var(--color-natural-black-default)"
              />
            ),
            whiteLabel: <>Jisr</>,
          }}
        />
      }
      variant="subheading"
      textColor="color/fg/lighter"
    />
  )

  const safariGuide = (
    <Typography
      text={
        <Trans
          i18nKey="safari_notifications_guide"
          components={{
            br: <br />,
            b: <b />,
            Apple: (
              <DSIcon
                name="apple"
                size="md"
              />
            ),
            whiteLabel: <>Jisr</>,
          }}
        />
      }
      variant="subheading"
      textColor="color/fg/lighter"
    />
  )

  if (isEdge) {
    return edgeGuide
  } else if (isChrome) {
    return chromeGuide
  } else if (isFirefox) {
    return firefoxGuide
  } else if (isSafari) {
    return safariGuide
  }
  return <></>
}

type typeProps = {
  filters: { page: number; rpp: number; old_notifications: boolean }
  setFilters: Dispatch<SetStateAction<{ page: number; rpp: number; old_notifications: boolean }>>
  activeTab: TTab
  setActiveTab: Dispatch<SetStateAction<TTab>>
}

export default function NotificationsPanel(props: typeProps): JSX.Element {
  const { filters, setFilters, activeTab, setActiveTab } = props

  const [openSettings, setOpenSettings] = useState(false)
  const [openGuide, setOpenGuide] = useState(false)
  const [activeRequest, setActiveRequest] = useState<{
    employee_id?: number
    request_type?: string
    request_id?: number
    request_type_name?: string
  } | null>(null)
  const history = useHistory()

  const dispatch = useDispatch()
  const {
    notifications,
    pagination,
    total_pending_team_requests,
    has_old_notification,
    is_old_notifications,
    fetching,
  } = useSelector(({ appNotifications }) => appNotifications)
  const currentEmployee = useSelector((s) => s.auth.employee)
  const isMolEnabled = useSelector((s) => !!s.auth.employee?.organization?.mol_enabled)
  const { setOpenRecordAndReportViolationDrawer, setActiveViolationId } = useViolationsStore(
    (s) => s.violations,
  )

  const { todayList = [], earlierList = [] } = _.groupBy(notifications, (obj) =>
    isToday(new Date(obj.created_at)) ? 'todayList' : 'earlierList',
  )

  const tabs: TTab[] = [
    {
      label: I18n.t('all'),
      key: 'all',
      value: 0,
      testId: 'all',
    },
    {
      label: I18n.t('requests_and_tasks'),
      key: 'requests_and_tasks',
      value: 1,
      ...(total_pending_team_requests && { badgeValue: String(total_pending_team_requests) }),
      testId: 'requests_and_tasks_tab',
      category: ['requests'],
    },
    ...(isMolEnabled
      ? [
          {
            label: I18n.t('label.violations'),
            key: 'violations',
            value: 2,
            testId: 'violations_tab',
            category: ['violation'],
          },
        ]
      : []),
    {
      label: I18n.t('events'),
      key: 'updates',
      testId: 'updates_tab',
      value: isMolEnabled ? 3 : 2,

      category: ['shift_schedule_changed', 'events'],
    },
  ]

  const path = window.location.hash?.split?.('#/')?.[1] || ''
  const isHomeTasks = !path || path.includes('/tasks')

  const fetchData = (): void => {
    const pureFilters = omitBy(filters, (v) => v === false)
    dispatch(
      notificationsLoad({
        all: true,
        category: activeTab?.category,
        ...pureFilters,
      }),
    )
    dispatch(unreadNotificationsLoad())
  }

  const handleTabChange = (v: number): void => {
    setActiveTab(tabs[v])
    setFilters({
      ...filters,
      page: 1,
      old_notifications: false,
    })
    dispatch(resetNotifications())
  }

  const handleClickViolation = (data: TNotification) => {
    const violationId = data.data?.id
    const employeeId = data.data?.employee_id

    const canViewEmployeeViolation = hasPermission(
      currentEmployee,
      employeeId,
      currentEmployee?.permission_scopes?.view_reported_violations,
    )

    if (isMolEnabled && canViewEmployeeViolation && violationId) {
      dispatch(toggleNotificationsPanel(false))
      setActiveViolationId(violationId)
      setOpenRecordAndReportViolationDrawer('respond_violation_case')
      history.push(`/employees/${employeeId}/recorded_violations`)
    }
  }

  const loadRequestDetail = (
    request_id?: number,
    employee_id?: number,
    request_type?: string,
    request_type_ar?: string,
    request_type_en?: string,
  ): void => {
    if (employee_id) {
      setActiveRequest({
        employee_id,
        request_type,
        request_id,
        request_type_name: isRtl ? request_type_ar : request_type_en,
      })
    } else {
      dispatch(taskDetailLoad(request_id))
    }
  }

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(unreadNotificationsLoad())
    }
  }, [activeTab?.key, filters])

  useEffect(() => {
    if (has_old_notification) {
      setFilters({
        ...filters,
        page: 1,
        ...((has_old_notification || is_old_notifications) && { old_notifications: true }),
      })
    }
  }, [has_old_notification])

  useEffect(() => {
    if (activeTab?.key === 'all') dispatch(getAllEmployeesLoad())
  }, [activeTab?.key])

  const onClickATSNotification = useCallback((notificationData: TNotification) => {
    const atsCategory = notificationData.category as unknown as AtsCategory
    if (atsCategory === 'employee_mentioned') {
      // @ts-expect-error -- id, candidate_id is present
      const { id, candidate_id } = notificationData?.data?.comment_details

      history.push(`/ats/recruitment/candidates/${candidate_id}?comment_id=${id}`)
      dispatch(toggleNotificationsPanel(false))
      return
    }
    if (atsCategory === 'added_as_employee') {
      // @ts-expect-error -- id, candidate_id is present
      const { id } = notificationData?.data?.candidate_details
      history.push(`/ats/recruitment/candidates/${id}`)
      dispatch(toggleNotificationsPanel(false))
      return
    }
    // @ts-expect-error -- id, candidate_id is present
    const { id } = notificationData?.data?.job_details
    if (!id) return
    if (
      [
        'apply_via_form',
        'moved_to_custom_stage',
        'assigned_manually',
        'job_edited',
        'job_closed',
        'job_reopened',
      ].includes(atsCategory)
    ) {
      history.push(`/ats/recruitment/job_openings/${id}`)
    } else if (atsCategory === 'rejected') {
      history.push(`/ats/recruitment/job_openings/${id}#rejected_stage`)
    } else if (atsCategory === 'hired') {
      history.push(`/ats/recruitment/job_openings/${id}#hired_stage`)
    }
    dispatch(toggleNotificationsPanel(false))
  }, [])

  return (
    <>
      <div className={styles.header}>
        <Spacer height={26} />
        <Flex
          justifyBetween
          itemsCenter
          className={styles.tabs}
        >
          <Tab
            tabs={tabs}
            activeTab={activeTab?.value}
            onTabClick={handleTabChange}
          />
          <DSIcon
            name="settings-02"
            size="ml"
            className={styles.settingIcon}
            color="var(--color-natural-black-light-new)"
            onClick={(): void => setOpenSettings(true)}
            data-testid="open_settings"
          />
        </Flex>
      </div>
      <div className={styles.content}>
        <Scrolling
          className="scroll-y scroll-x"
          style={{ maxHeight: '100%' }}
          {...(pagination?.next_page && {
            onScrollEnd: (): void =>
              setFilters({
                ...filters,
                page: pagination?.next_page,
                ...((has_old_notification || is_old_notifications) && { old_notifications: true }),
              }),
          })}
        >
          {!fetching && !notifications.length && <NoNotifications />}
          {fetching && <JisrLoader absolute />}

          {!!todayList.length && (
            <Typography
              text={I18n.t('today')}
              variant="subheading"
              textColor="color/fg/light"
              style={{ marginInline: 24, marginTop: 16 }}
            />
          )}
          {todayList.map((data) => (
            <NotificationItem
              key={data.id}
              handleView={(): void => {
                if (data.category === 'task') {
                  loadRequestDetail(data.data?.id)
                } else {
                  loadRequestDetail(
                    data.data?.id,
                    data.data?.employee_id,
                    data.data?.class_name,
                    data.data?.resource_type_ar,
                    data.data?.resource_type_en,
                  )
                }
              }}
              testId="notification_item"
              handleATSNotification={(): void => onClickATSNotification(data)}
              handleClickViolation={(): void => handleClickViolation(data)}
              {...data}
            />
          ))}

          {!!earlierList.length && (
            <Typography
              text={I18n.t('earlier')}
              variant="subheading"
              style={{ marginInline: 24, marginTop: 16 }}
            />
          )}
          {earlierList.map((data) => (
            <NotificationItem
              key={data.id}
              handleView={(): void => {
                if (data.category === 'task') {
                  loadRequestDetail(data.data?.id)
                } else {
                  loadRequestDetail(
                    data.data?.id,
                    data.data?.employee_id,
                    data.data?.class_name,
                    data.data?.resource_type_ar,
                    data.data?.resource_type_en,
                  )
                }
              }}
              handleATSNotification={(): void => onClickATSNotification(data)}
              handleClickViolation={(): void => handleClickViolation(data)}
              testId="notification_item"
              {...data}
            />
          ))}
        </Scrolling>
      </div>

      <Modal
        open={openSettings}
        size="medium"
        header={{ title: I18n.t('browser_notifications'), withClose: true }}
        onClose={(): void => setOpenSettings(false)}
      >
        <NotificationsSettings setOpenGuide={setOpenGuide} />
      </Modal>
      <NotificationsGuide
        open={openGuide}
        guideContent={guideContent()}
        onClose={(): void => setOpenGuide(false)}
      />
      <RequestDrawer
        needApproval
        actionCallback={(): void => {
          setActiveRequest(null)
        }}
        active={!!activeRequest}
        requestType={activeRequest?.request_type}
        onClose={(): void => setActiveRequest(null)}
        vars={activeRequest}
        hideFooter
      />
      {!isHomeTasks && (
        <RequestDetails actionCallback={(): Promise<void> => dispatch(approvalDetailReset())} />
      )}
    </>
  )
}
