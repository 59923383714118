import { Typography, Spacer } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import Styles from '../../Request.module.css'

type DetailsNoteProps = {
  reason: string
  title?: string
}

const DetailsNote = (props: DetailsNoteProps): JSX.Element => {
  const { title = i18n.t('note'), reason } = props

  return (
    <div className={Styles.groupField}>
      {title && (
        <Typography
          text={title}
          variant="title-1"
        />
      )}
      <Spacer height={16} />
      <Typography
        text={reason}
        variant="subheading"
      />
    </div>
  )
}

export default DetailsNote
