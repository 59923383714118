export const ORG_SUBSIDIARY_ROLES_LOAD = 'ORG_SUBSIDIARY_ROLES_LOAD'
export const ORG_SUBSIDIARY_ROLES_LOAD_PENDING = 'ORG_SUBSIDIARY_ROLES_LOAD_PENDING'
export const ORG_SUBSIDIARY_ROLES_LOAD_FULFILLED = 'ORG_SUBSIDIARY_ROLES_LOAD_FULFILLED'
export const ORG_SUBSIDIARY_ROLES_LOAD_REJECTED = 'ORG_SUBSIDIARY_ROLES_LOAD_REJECTED'

export const ORG_SUBSIDIARY_EMPLOYEES_LOAD = 'ORG_SUBSIDIARY_EMPLOYEES_LOAD'
export const ORG_SUBSIDIARY_EMPLOYEES_LOAD_PENDING = 'ORG_SUBSIDIARY_EMPLOYEES_LOAD_PENDING'
export const ORG_SUBSIDIARY_EMPLOYEES_LOAD_FULFILLED = 'ORG_SUBSIDIARY_EMPLOYEES_LOAD_FULFILLED'
export const ORG_SUBSIDIARY_EMPLOYEES_LOAD_REJECTED = 'ORG_SUBSIDIARY_EMPLOYEES_LOAD_REJECTED'

export const ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE = 'ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE'
export const ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_PENDING = 'ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_PENDING'
export const ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED = 'ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED'
export const ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED = 'ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED'

export const REVOKE_SUBSIDIARY_EMPLOYEE_ROLE = 'REVOKE_SUBSIDIARY_EMPLOYEE_ROLE'
export const REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_PENDING = 'REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_PENDING'
export const REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED = 'REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED'
export const REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED = 'REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED'
