export const ONBOARDING_ORGANIZATION_LOAD = 'ONBOARDING_ORGANIZATION_LOAD'
export const ONBOARDING_ORGANIZATION_LOAD_PENDING = 'ONBOARDING_ORGANIZATION_LOAD_PENDING'
export const ONBOARDING_ORGANIZATION_LOAD_FULFILLED = 'ONBOARDING_ORGANIZATION_LOAD_FULFILLED'
export const ONBOARDING_ORGANIZATION_LOAD_REJECTED = 'ONBOARDING_ORGANIZATION_LOAD_REJECTED'

export const ONBOARDING_ORGANIZATION_PUT = 'ONBOARDING_ORGANIZATION_PUT'
export const ONBOARDING_ORGANIZATION_PUT_PENDING = 'ONBOARDING_ORGANIZATION_PUT_PENDING'
export const ONBOARDING_ORGANIZATION_PUT_FULFILLED = 'ONBOARDING_ORGANIZATION_PUT_FULFILLED'
export const ONBOARDING_ORGANIZATION_PUT_REJECTED = 'ONBOARDING_ORGANIZATION_PUT_REJECTED'

export const ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT =
  'ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT'
export const ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_PENDING =
  'ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_PENDING'
export const ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_FULFILLED =
  'ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_FULFILLED'
export const ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_REJECTED =
  'ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_REJECTED'

export const ONBOARDING_ORGANIZATION_CONFIRM_PUT = 'ONBOARDING_ORGANIZATION_CONFIRM_PUT'
export const ONBOARDING_ORGANIZATION_CONFIRM_PUT_PENDING =
  'ONBOARDING_ORGANIZATION_CONFIRM_PUT_PENDING'
export const ONBOARDING_ORGANIZATION_CONFIRM_PUT_FULFILLED =
  'ONBOARDING_ORGANIZATION_CONFIRM_PUT_FULFILLED'
export const ONBOARDING_ORGANIZATION_CONFIRM_PUT_REJECTED =
  'ONBOARDING_ORGANIZATION_CONFIRM_PUT_REJECTED'

export const ONBOARDING_STEP_UPDATE = 'ONBOARDING_STEP_UPDATE'
export const ONBOARDING_STEP_UPDATE_PENDING = 'ONBOARDING_STEP_UPDATE_PENDING'
export const ONBOARDING_STEP_UPDATE_FULFILLED = 'ONBOARDING_STEP_UPDATE_FULFILLED'
export const ONBOARDING_STEP_UPDATE_REJECTED = 'ONBOARDING_STEP_UPDATE_REJECTED'

export const VALIDATE_ADMINS_EMAIL = 'VALIDATE_ADMINS_EMAIL'
export const VALIDATE_ADMINS_EMAIL_PENDING = 'VALIDATE_ADMINS_EMAIL_PENDING'
export const VALIDATE_ADMINS_EMAIL_FULFILLED = 'VALIDATE_ADMINS_EMAIL_FULFILLED'
export const VALIDATE_ADMINS_EMAIL_REJECTED = 'VALIDATE_ADMINS_EMAIL_REJECTED'

export const VALIDATE_PIN_CODE_CREATE = 'VALIDATE_PIN_CODE_CREATE'
export const VALIDATE_PIN_CODE_CREATE_PENDING = 'VALIDATE_PIN_CODE_CREATE_PENDING'
export const VALIDATE_PIN_CODE_CREATE_FULFILLED = 'VALIDATE_PIN_CODE_CREATE_FULFILLED'
export const VALIDATE_PIN_CODE_CREATE_REJECTED = 'VALIDATE_PIN_CODE_CREATE_REJECTED'

export const SLUG_AVALIABLE = 'SLUG_AVALIABLE'
export const SLUG_AVALIABLE_PENDING = 'SLUG_AVALIABLE_PENDING'
export const SLUG_AVALIABLE_FULFILLED = 'SLUG_AVALIABLE_FULFILLED'
export const SLUG_AVALIABLE_REJECTED = 'SLUG_AVALIABLE_REJECTED'

export const ONBOARDING_ADMINS_RESET_TOKEN = 'ONBOARDING_ADMINS_RESET_TOKEN'
export const ONBOARDING_ADMINS_RESET_TOKEN_PENDING = 'ONBOARDING_ADMINS_RESET_TOKEN_PENDING'
export const ONBOARDING_ADMINS_RESET_TOKEN_FULFILLED = 'ONBOARDING_ADMINS_RESET_TOKEN_FULFILLED'
export const ONBOARDING_ADMINS_RESET_TOKEN_REJECTED = 'ONBOARDING_ADMINS_RESET_TOKEN_REJECTED'

export const ONBOARDING_COMPLETE_STEP = 'ONBOARDING_COMPLETE_STEP'

export const ONBOARDING_RESET_TOKEN = 'ONBOARDING_RESET_TOKEN'

export const ONBOARDING_SLUG_RESET = 'ONBOARDING_SLUG_RESET'

export const LOGIN_FROM_ONBOARDING = 'LOGIN_FROM_ONBOARDING'
