import { Action } from 'types/redux'
import { handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export const constantsLoad = (params = {}): Action => {
  const url = '/v2/onboarding/organization/constants'

  return {
    type: actions.ONBOARDING_CONSTANTS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
