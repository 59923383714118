import { FC } from 'react'
import i18n from 'translations/i18n'
import { resetMudadState } from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import { useIntercome } from 'components/global/Intercom/Intrcom'
import { useSelector, useDispatch } from 'utils/hooks'
import DSIcon from 'components/DSIcon'
import { Button, Typography, Flex } from '@jisr-hr/ds'

import { usePaymentMethod } from '../../../components/usePaymentMethods'

type MudadConfigFooter = {
  onNextClick: () => void
  nextLabel: string
  disableNextBtn: boolean
  onBackClick: () => void
  disableBackBtn: boolean
}

const MudadConfigFooter: FC<MudadConfigFooter> = (props) => {
  const { nextLabel, disableNextBtn, disableBackBtn } = props
  const dispatch = useDispatch()
  const { show: showIntercome, openChatWidget } = useIntercome()
  const paymentCtx = usePaymentMethod()
  const { setupModalOpen, selectedPaymentMethod } = paymentCtx

  const fetching = useSelector((s) => s.paymentMethods.fetchingShowMethod)
  return (
    <Flex
      justifyBetween={showIntercome}
      justifyEnd={!showIntercome}
      itemsCenter
      style={{ padding: '8px 0 24px', flexShrink: 0 }}
    >
      {showIntercome && (
        <Flex
          itemsCenter
          style={{ gap: 8, cursor: 'pointer', width: 'fit-content' }}
          onClick={openChatWidget}
        >
          <DSIcon
            name="headphones-01"
            size="md"
            color="var(--ds-core-color-gray-600)"
          />
          <Typography
            style={{ lineHeight: '20px', color: 'var(--color-base-colors-black-1000)' }}
            variant="interface/default/sm"
            text={i18n.t('have_an_issues_contact_support')}
          />
        </Flex>
      )}
      <Flex
        itemsCenter
        style={{ gap: 8 }}
      >
        <Typography
          style={{
            lineHeight: '20px',
            cursor: 'pointer',
            pointerEvents: fetching ? ('none' as any) : ('default' as any),
            color: 'var(--color-base-colors-black-1000)',
          }}
          variant="interface/default/sm"
          onClick={(): void => {
            // ? @note incase the setup modal open we don't need to reset the current payment
            const data = setupModalOpen ? selectedPaymentMethod : null
            paymentCtx.handleMethodModalOpen(data, null)
            paymentCtx.setCurrentSignatory(null)
            paymentCtx.setCurrentAuthorizer(null)
            dispatch(resetMudadState())
          }}
          text={i18n.t('save_close')}
        />
        <Button
          disabled={disableBackBtn}
          onClick={props.onBackClick}
          label={i18n.t('back')}
          size="medium"
          variant="outlined"
          color="neutral"
        />
        <Button
          label={nextLabel}
          disabled={disableNextBtn}
          onClick={props.onNextClick}
          size="medium"
        />
      </Flex>
    </Flex>
  )
}

export default MudadConfigFooter
