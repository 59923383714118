import { Action } from 'types/redux'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'

import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function laborawConfigurationsCreate(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('labor_law_configurations/default_policies')}`
  const method = 'POST'

  return {
    type: actions.ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE,
    payload: handleAPI(url, params, method),
  }
}

export function laborawConfigurationsUpdate(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('labor_law_configurations/custom_policies')}`
  const method = 'PUT'

  return {
    type: actions.LABORAW_CONFIGURATIONS_UPDATE,
    payload: handleAPI(url, params, method),
  }
}
