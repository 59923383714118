import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

type PayrollTransactionType = {
  category: string
  default_pay_type: string
  id: number
  name: string
  name_ar: string
  pay_type: string
  status: string
}

type PayrunConfigurations = {
  loan_full_or_basic: {
    value: string
  }
  loan_settings: {
    value: {
      config: string
      is_applicable: boolean
    }
  }
}

export function loanTypesLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/`
  const method = 'GET'

  return {
    type: actions.ONBOARDING_LOAN_TYPES_LOAD,
    payload: handleAPI(url, { category: 'LoanType', template: 'public', ...params }, method),
  }
}

export function loanTypeCreate(data: PayrollTransactionType): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/`
  const method = 'POST'
  return {
    type: actions.ONBOARDING_LOAN_TYPE_CREATE,
    payload: handleAPI(url, {}, method, { payroll_transaction_type: data }),
  }
}

export function loanTypeUpdate(data: PayrollTransactionType): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/${data.id}/`
  const method = 'PUT'
  return {
    type: actions.ONBOARDING_LOAN_TYPE_UPDATE,
    payload: handleAPI(url, {}, method, { payroll_transaction_type: data }),
  }
}

export function loanTypeDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/${id}/`
  const method = 'DELETE'

  return {
    type: actions.ONBOARDING_LOAN_TYPE_DELETE,
    payload: handleAPI(url, { category: 'LoanType' }, method),
  }
}

export function loanLimitBulkUpdate(data: PayrunConfigurations): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payrun_configurations/bulk_update`
  const method = 'PUT'

  return {
    type: actions.ONBOARDING_LOAN_LIMIT_BULK_UPDATE,
    payload: handleAPI(url, {}, method, { payrun_configurations: data }),
  }
}

export function loanLimitLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payrun_configurations/loan_settings`
  const method = 'GET'

  return {
    type: actions.ONBOARDING_LOAN_LIMIT_LOAD,
    payload: handleAPI(url, {}, method),
  }
}

export function loanFullOrBasicLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payrun_configurations/loan_full_or_basic`

  return {
    type: actions.ONBOARDING_LOAN_FULL_OR_BASIC_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}
