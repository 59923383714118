import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import lodash from 'lodash'
import * as actions from './actions'

const initialState = {
  all_employees: [],
  employees: [],
  employees_ids: [],
  bulk_employees_ids: [],
  employees_by_business: {},
  employees_custom: [],
  employees_not_linked: [],
  bulk_assignee: [],
  fetching: false,
  loading_bulk_assignee: false,
  unsynced_employee_count: null,
  unsynced_with_employee_dimension_count: null,
  unassigned_to_cost_centre_count: null,
  unassigned_to_subsidiary_count: null,
  pagination: {},
  netsuit_Pagination: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GL_MAPPING_EMPLOYEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.NETSUITE_EMPLOYEES_LOAD_PENDING:
    case actions.ACCOUNTING_BULK_SYNC_EMPLOYEE_PENDING:
      return {
        ...state,
        loading_bulk_assignee: true,
      }
    case actions.GL_MAPPING_EMPLOYEES_LOAD_FULFILLED: {
      const { data } = action.payload.data
      const employeesData = data?.employees

      const employees_custom = employeesData.filter((ite) => !ite.business_unit_subsidiary_id)
      const employees_not_linked = employeesData.filter(
        (ite) => !ite.linked_subsidiary && !ite?.business_unit?.id,
      )
      const employees_by_business = lodash.groupBy(employeesData, 'business_unit.id')
      return {
        ...state,
        all_employees: employeesData,
        employees: employeesData.filter((it) => it.status === 'active'),
        employees_ids: employeesData?.map((it) => it?.id) || [],
        employees_by_business,
        employees_not_linked,
        employees_custom,
        fetching: false,
        pagination: data?.pagination,
        unsynced_employee_count: data?.unsynced_employee_count,
        unsynced_with_employee_dimension_count: data?.unsynced_with_employee_dimension_count,
        unassigned_to_cost_centre_count: data?.unassigned_to_cost_centre_count,
        unassigned_to_subsidiary_count: data?.unassigned_to_subsidiary_count,
      }
    }

    case actions.NETSUITE_EMPLOYEES_LOAD_FULFILLED: {
      const { data } = action.payload.data
      const bulk_assignee = data?.employees

      return {
        ...state,
        bulk_assignee,
        bulk_employees_ids: bulk_assignee?.map((it) => it?.id) || [],
        loading_bulk_assignee: false,
        netsuit_Pagination: data?.pagination,
        unassigned_to_cost_centre_count: data?.unassigned_to_cost_centre_count,
        unassigned_to_subsidiary_count: data?.unassigned_to_subsidiary_count,
      }
    }
    case actions.ACCOUNTING_BULK_SYNC_EMPLOYEE_FULFILLED: {
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        loading_bulk_assignee: false,
      }
    }

    case actions.NETSUITE_EMPLOYEES_LOAD_REJECTED:
    case actions.ACCOUNTING_BULK_SYNC_EMPLOYEE_REJECTED:
    case actions.GL_MAPPING_EMPLOYEES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading_bulk_assignee: false,
      }
    }
    default:
      return state
  }
}
