import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  employeeReport: { header: [], records: [] },
  organizationsReport: { header: [], records: [] },
  employeeReportPagination: {},
  organizationsReportPagination: {},
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_PENDING:
    case actions.REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_FULFILLED: {
      return {
        ...state,
        organizationsReport: payload.data.data,
        organizationsReportPagination: payload.data.data.pagination,
        fetching: false,
      }
    }
    case actions.REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_FULFILLED: {
      return {
        ...state,
        employeeReport: payload.data.data,
        employeeReportPagination: payload.data.data.pagination,
        fetching: false,
      }
    }

    case actions.REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_REJECTED:
    case actions.REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
