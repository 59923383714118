import { Action } from 'types/redux'
import { handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function createJobTitles(body = {}): Action {
  const url = '/v2/onboarding/job_titles'

  return {
    type: actions.ONBOARDING_JOB_TITLES_POST,
    payload: handleAPI(url, {}, 'POST', body),
  }
}

export function indexJobTitles(params = {}): Action {
  const url = '/v2/onboarding/job_titles'

  return {
    type: actions.ONBOARDING_JOB_TITLES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function showJobTitles(id: number): Action {
  const url = `/onboarding/job_titles/${id}`

  return {
    type: actions.ONBOARDING_JOB_TITLES_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const deleteJobTitle = (id: number): Action => {
  const url = `/v2/onboarding/job_titles/${id}`

  return {
    type: actions.ONBOARDING_JOB_TITLE_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export const jobTitleSuggestionsLoad = (industry_id: number, params = {}): Action => {
  const url = `/v2/onboarding/industries/${industry_id}/job_title_suggestions`

  return {
    type: actions.ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
