import React from 'react'
import { Flex } from '@jisr-hr/ds'
import {
  Info,
  Reason,
  Workflow,
  Comments,
  Tabs,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'

import ExitReEntryVisaDetails from '../../components/ExitReEntryVisaDetails'
import FlightTicketDetails from '../../components/FlightTicketDetails'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const ExitReentryVisaRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()

  const [activeTab, setActiveTab] = React.useState('request_details')
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <React.Fragment>
          <Info
            details={{
              created_by: request.created_by,
              status: request.status,
              status_i18n: request.status_i18n,
              type: request.request_type,
              type_i18n: request.request_name_i18n,
              created_at: request.created_at,
              requested_on: request.created_at,
              employee: request.employee,
              request: request?.request,
              request_name: request?.request_name,
              cancellation_request_info: request?.cancellation_request_info,
              is_cancellation_request: request?.is_cancellation_request,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
            }}
          />

          {(request.reason || request.attachments?.length > 0) && (
            <Reason
              details={{
                ...request,
                attachments: request.attachments,
              }}
            />
          )}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <ExitReEntryVisaDetails details={request} />

          {request.require_ticket && (
            <FlightTicketDetails
              details={{
                leave_date: request.ticket_info?.leaving_date,
                return_date: request.ticket_info?.returning_date,
                family_details: request.ticket_info?.family_details,
              }}
            />
          )}

          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request.approvals}
          />
        </React.Fragment>
      )}
      {activeTab === 'workflow_and_comments' && (
        <React.Fragment>
          <>
            <Comments
              comments={request.comments || []}
              reloadRequest={reloadRequest}
              approval={{
                request_id: request.id,
                request_type: request.request_type,
              }}
              can_add_comment={request.can_add_comment}
            />
            <Workflow approvals={request.approvals} />
          </>
        </React.Fragment>
      )}
    </Flex>
  )
}

export default ExitReentryVisaRequest
