import { ChangeEvent, useEffect, useState } from 'react'
import { UploadingTab } from '@jisr-hr/design-system'
import { Typography, Flex } from '@jisr-hr/ds'
import Box from '@material-ui/core/Box'
import I18n from 'translations/i18n'
import { convertToFormData } from 'components/global/HelperFunctions'
import { ReactComponent as CloudUploadIcon } from 'assets/figma-icons/cloud-upload.svg'
import { useDispatch, useSelector } from 'utils/hooks'
import { handleRecordStatus } from 'containers/authorised/setting/organizationSetting/BulkImportSheets/helper'
import { loadOnboardingBulkUploadCategories } from 'redux/organizationOnborading/bulkUpload/actionCreators'
import { assetmanagerBulkUploadImportPost } from 'redux/setting/organizationSetting/assetmanagerBulkUpload/actionsCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { TOnboardingRecords } from 'redux/organizationOnborading/bulkUpload/reducer'
import styles from './styles.module.css'

type TUploadingBox = {
  type: string
  action: () => void
}

const empData = {
  title: '',
  type: '',
  id: 0,
  employee_id: null,
  name: '',
  s3_url: '',
  error_file_url: null,
  category: '',
  category_value: '',
  status: '',
  completed_time: null,
  uploaded_time: '',
  updated_at: '',
  metadata: null,
  status_value: 1,
  details: '',
}

function UploadingBox({ type, action }: TUploadingBox): JSX.Element {
  const { fetching, empCat } = useSelector(({ onboardingBulkUpload }) => ({
    ...onboardingBulkUpload,
    empCat: onboardingBulkUpload.onboarding_records.find((cat) => cat.type === type) || empData,
  }))

  const dispatch = useDispatch()
  const [onSuccess, setSuccess] = useState(0)
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): Promise<void> | undefined => {
    if (!e.target.files?.length) return undefined
    const file = e.target.files[0]
    const values = convertToFormData({
      file,
      type,
    })
    return dispatch(assetmanagerBulkUploadImportPost(values)).then(() => {
      dispatch(loadOnboardingBulkUploadCategories())
      dispatch(organizationInfo())
    })
  }

  useEffect(() => {
    dispatch(loadOnboardingBulkUploadCategories())
  }, [])

  useEffect(() => {
    const fetchCats = setInterval(() => {
      if (empCat.status_value === 1) {
        return dispatch(loadOnboardingBulkUploadCategories()).then(({ value }) => {
          setSuccess(
            value.data?.data?.onboarding_records.find(
              (cat: TOnboardingRecords) => cat.type === type,
            ).status_value,
          )
        })
      }
      return {}
    }, 10000)
    return () => {
      clearInterval(fetchCats)
    }
  })

  useEffect(() => {
    if ([3, 4].includes(onSuccess) && action) {
      action()
    }
  }, [onSuccess])

  return (
    <Flex
      className={styles.uploadingBox}
      flexCol
    >
      <CloudUploadIcon className={styles.uplodingIcon} />
      <UploadingTab
        accept=".xls, .xlsx"
        label={
          <Flex style={{ gap: 3 }}>
            <Typography
              variant="subtitle-2"
              text={I18n.t('drag_and_drop_file_or')}
            />
            <Typography
              variant="subtitle-2"
              style={{ color: 'var(--color-base-colors-blue-600)' }}
              text={I18n.t('browse_computer')}
            />
          </Flex>
        }
        disabled={empCat.status_value === 1 || fetching}
        onChange={handleOnChange}
      />
      {empCat.category && (
        <Box style={{ border: 'none', height: 'auto', width: 'auto' }}>
          {handleRecordStatus(empCat.status_value, empCat.metadata || {}, empCat.error_file_url)}
        </Box>
      )}
    </Flex>
  )
}

export default UploadingBox
