import { useState } from 'react'
import I18n from 'translations/i18n'
import { ButtonProps } from '@jisr-hr/ds'
import { useForm, useFormState } from 'react-final-form'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { useDispatch, useSelector } from 'utils/hooks'
import { employeeLeaveTypesLoad } from 'redux/actions/employeeLeaveTypesAction'
import { requestsWithCancellationFlow } from '../helper'
import { useRequest } from '../RequestProvider'

const useRequestFooter = () => {
  const { invalid, submitting, values } = useFormState()
  const { submit } = useForm()
  const dispatch = useDispatch()
  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  const is_super_admin = useSelector(({ auth }) => auth.employee?.is_super_admin) ?? false
  const employee_id = useSelector(({ auth }) => auth.employee?.id)
  const {
    drawerToggle,
    isOtherRequest,
    onCancel,
    editRequest,
    setEditRequest,
    canApprove,
    disableSubmit,
    setOpenNext,
  } = useRequest()

  const [status, setStatus] = useState('reject')
  const [addRejectComment, setAddRejectComment] = useState(false)
  const [addReturnComment, setAddReturnComment] = useState(false)
  const [isOpen, setIsOpen] = useState<null | string>(null)
  const [showExtendingModal, setShowExtendingModal] = useState(false)
  const isApproved = details?.status?.toLowerCase() === 'approved'
  const showCancellationFlow = requestsWithCancellationFlow.includes(details?.request_type || '')
  const showResumptionActions =
    isApproved &&
    details?.leave_resumption_keys &&
    (details?.leave_resumption_keys?.confirm || details?.leave_resumption_keys?.early_return)
  const askCancelAuthorize =
    showCancellationFlow &&
    isApproved &&
    ((is_super_admin && !showResumptionActions) || [details?.employee?.id].includes(employee_id))

  const hasNextRequest = values?.show_flight_ticket_request

  const handleCancelRequest = () => {
    if (showCancellationFlow && isApproved) {
      setAddRejectComment(true)
      setStatus('cancel')
    } else {
      onCancel()
    }
  }

  const handleReject = () => {
    setAddRejectComment(true)
    setStatus('reject')
  }

  const handleCancel = (showDetailsActions: boolean, handelCancel?: () => void) => {
    if (editRequest) {
      setEditRequest(false)
    } else {
      drawerToggle(false)
      handelCancel?.()
    }
    if (!showDetailsActions && status === 'pending_for_correction') {
      mixPanelAnalytics({
        event_name:
          'employee_cancel_submit_return_for_correction_click_request_details_sidedrawer_web',
      })
    }
  }

  const handelSubmitBtn = (
    showDetailsActions?: boolean,
    onClose?: () => void,
  ): ButtonProps | null => {
    if (showDetailsActions && showResumptionActions) {
      return {
        label: I18n.t('resume_working'),
        leadingIcon: 'check',
        onClick: () => setIsOpen('early_return'),
      }
    } else if (!editRequest && details.can_update) {
      return {
        label: I18n.t('edit'),
        leadingIcon: 'edit-01',
        onClick: () => {
          setEditRequest(true)
          if (status === 'pending_for_correction') {
            mixPanelAnalytics({
              event_name: 'edit_return_for_correction_click_request_details_sidedrawer_web',
            })
          }
        },
      }
    } else if (canApprove || editRequest) {
      return {
        label: showDetailsActions
          ? I18n.t('approve')
          : I18n.t(isOtherRequest || hasNextRequest ? 'next' : 'submit'),
        disabled: invalid || disableSubmit || submitting,
        leadingIcon: 'check',
        testId: 'pending-request-details-approve',
        onClick: async () => {
          submit()
          if (showDetailsActions) {
            onClose?.()
          }
          if (isOtherRequest) {
            setOpenNext(true)
          }
          if (!showDetailsActions && status === 'pending_for_correction') {
            mixPanelAnalytics({
              event_name:
                'employee_submit_return_for_correction_click_request_details_sidedrawer_web',
            })
          }
        },
      }
    }
    return null
  }
  const handelLinkBtn = () => {
    if (askCancelAuthorize) {
      return {
        label: I18n.t('ask_for_cancellation'),
        variant: 'neutral',
        onClick: handleCancelRequest,
      }
    }
    return {
      label: I18n.t('label.return_for_correction'),
      variant: 'danger',
      onClick: () => {
        setAddReturnComment(true)
        mixPanelAnalytics({
          event_name: 'return_for_correction_click_request_details_sidedrawer_web',
        })
      },
    }
  }

  const handelCancelBtn = (
    showDetailsActions: boolean,
    needApproval?: boolean,
    onClickCancel?: () => void,
    showCancelAndRejectBtn?: boolean,
  ): ButtonProps | null => {
    if (showCancelAndRejectBtn) return null
    if (showResumptionActions) {
      return {
        label: I18n.t('extend'),
        onClick: () => {
          dispatch(employeeLeaveTypesLoad(details?.employee?.id))
          setShowExtendingModal(true)
        },
      }
    } else if (!editRequest && details.can_update) {
      return {
        label: I18n.t('cancel'),
        onClick: () => {
          drawerToggle(false)
          onClickCancel?.()
          setOpenNext(false)
        },
      }
    } else if (canApprove || editRequest) {
      return {
        label: showDetailsActions ? I18n.t('reject') : I18n.t('cancel'),
        leadingIcon: needApproval ? 'x-close' : '',
        testId: 'pending-request-details-reject',
        onClick: () =>
          showDetailsActions ? handleReject() : handleCancel(showDetailsActions, onClickCancel),
      }
    }
    return null
  }
  return {
    askCancelAuthorize,
    showResumptionActions,
    addRejectComment,
    addReturnComment,
    setAddRejectComment,
    setAddReturnComment,
    showExtendingModal,
    setShowExtendingModal,
    isOpen,
    setIsOpen,
    handelLinkBtn,
    handelCancelBtn,
    handelSubmitBtn,
    status,
  }
}

export default useRequestFooter
