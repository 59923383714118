import React from 'react'
import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

const Areas = (props) => {
  const { areas } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={areas}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

Areas.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default Areas
