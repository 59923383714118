import React from 'react'
import PropTypes from 'prop-types'

const WorkflowEmployees = ({ employees, onSelected, handleOnSearch }) => (
  <div
    data-testid="workflow-employees-list"
    className="workflow-employees-list"
  >
    <div className="workflow-employees-list__search">
      <input
        data-testid="workflow-employees-input"
        onChange={handleOnSearch}
        type="text"
        placeholder="Search"
      />
    </div>
    <ul className="scroll-y">
      {employees.map((employee) => (
        <li
          data-testid={`approver-${employee.id}`}
          key={`approver-${employee.id}`}
          onClick={() => onSelected(employee.id)}
        >
          {employee.name}
        </li>
      ))}
    </ul>
  </div>
)

WorkflowEmployees.propTypes = {
  employees: PropTypes.arrayOf(Object),
  onSelected: PropTypes.func,
  handleOnSearch: PropTypes.func,
}

export default WorkflowEmployees
