import React from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { useDispatch } from 'react-redux'
import { FileUpload } from '@jisr-hr/ds-beta'
import { useSelector } from 'utils/hooks'
import { printLetterTemplateLoad } from 'redux/letterTemplates/actionCreators'
import { ReactComponent as DownloadArrowIcon } from 'components/global/templates/RequestDrawer/components/Reason/icons/download-arrow.svg'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { ReactComponent as FileIcon } from '../../icons/file-text.svg'
import { useRequest } from '../../RequestProvider'
import { requestDateFormat } from '../../helper'
import useTaskFooter from '../../Footer/useTaskFooter'

const LetterRequestTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { onAttachTaskFile } = useTaskFooter()
  const { fetching_submit } = useSelector((state) => state.approvals)
  const dispatch = useDispatch()

  const handlePrintLetter = () => {
    dispatch(
      printLetterTemplateLoad({
        empId: request.employee.id,
        requestId: request?.task_info?.id,
      }),
    )
  }

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          type_i18n: request.name,
          created_at: request?.request?.created_time,
          due_date: request.due_date,
          requested_on: request?.request?.created_time,
          metadata: request?.metadata,
          items: [
            {
              icon: <FileIcon />,
              label: i18n.t('letter_type'),
              value: request?.task_info?.letter_type?.name,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('date'),
              value: requestDateFormat(request?.task_info?.date),
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('approved_date'),
              value: requestDateFormat(request?.task_info?.approved_at),
            },
            {
              label: i18n.t('directed_to'),
              value: request?.task_info?.directed_to,
            },
            {
              label: i18n.t('attachment'),
              value: (
                <DownloadArrowIcon
                  className="pointer"
                  onClick={handlePrintLetter}
                />
              ),
            },
          ],
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default LetterRequestTask
