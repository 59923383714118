export const ODOO_CONNECT_ACCOUNT = 'ODOO_CONNECT_ACCOUNT'
export const ODOO_CONNECT_ACCOUNT_PENDING = 'ODOO_CONNECT_ACCOUNT_PENDING'
export const ODOO_CONNECT_ACCOUNT_FULFILLED = 'ODOO_CONNECT_ACCOUNT_FULFILLED'
export const ODOO_CONNECT_ACCOUNT_REJECTED = 'ODOO_CONNECT_ACCOUNT_REJECTED'

export const ODOO_DISCONNECT_ACCOUNT = 'ODOO_DISCONNECT_ACCOUNT'
export const ODOO_DISCONNECT_ACCOUNT_PENDING = 'ODOO_DISCONNECT_ACCOUNT_PENDING'
export const ODOO_DISCONNECT_ACCOUNT_FULFILLED = 'ODOO_DISCONNECT_ACCOUNT_FULFILLED'
export const ODOO_DISCONNECT_ACCOUNT_REJECTED = 'ODOO_DISCONNECT_ACCOUNT_REJECTED'

export const ODOO_LOAD_ACCOUNT_INFO = 'ODOO_LOAD_ACCOUNT_INFO'
export const ODOO_LOAD_ACCOUNT_INFO_PENDING = 'ODOO_LOAD_ACCOUNT_INFO_PENDING'
export const ODOO_LOAD_ACCOUNT_INFO_FULFILLED = 'ODOO_LOAD_ACCOUNT_INFO_FULFILLED'
export const ODOO_LOAD_ACCOUNT_INFO_REJECTED = 'ODOO_LOAD_ACCOUNT_INFO_REJECTED'

export const ODOO_LOAD_ACCOUNT_STATUS = 'ODOO_LOAD_ACCOUNT_STATUS'
export const ODOO_LOAD_ACCOUNT_STATUS_PENDING = 'ODOO_LOAD_ACCOUNT_STATUS_PENDING'
export const ODOO_LOAD_ACCOUNT_STATUS_FULFILLED = 'ODOO_LOAD_ACCOUNT_STATUS_FULFILLED'
export const ODOO_LOAD_ACCOUNT_STATUS_REJECTED = 'ODOO_LOAD_ACCOUNT_STATUS_REJECTED'

export const SYNC_ODOO_EMPLOYEE = 'SYNC_ODOO_EMPLOYEE'
export const SYNC_ODOO_EMPLOYEE_PENDING = 'SYNC_ODOO_EMPLOYEE_PENDING'
export const SYNC_ODOO_EMPLOYEE_FULFILLED = 'SYNC_ODOO_EMPLOYEE_FULFILLED'
export const SYNC_ODOO_EMPLOYEE_REJECTED = 'SYNC_ODOO_EMPLOYEE_REJECTED'
