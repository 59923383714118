import { useState } from 'react'
import { Typography, Avatars, Spacer, FileInfo, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import DSIcon from 'components/DSIcon'
import styles from './styles.module.css'
import Attachment from '../Attachment'

type HiringEmployeesDetailsProps = {
  noOfVacancies?: number
  title?: string
  hiringMonth?: string
  description?: string
  location?: string
  employmentType?: string
  businessUnit?: string
  hiringManagerName?: string
  hiringManagerJobTitle?: string
  hiringManagerAvatar?: string
  attachments?: FileInfo[]
}

const HiringEmployeesDetails = ({
  noOfVacancies,
  title,
  hiringMonth,
  description,
  location,
  employmentType,
  businessUnit,
  hiringManagerName,
  hiringManagerJobTitle,
  hiringManagerAvatar,
  attachments,
}: HiringEmployeesDetailsProps): JSX.Element => {
  const [showFullText, setShowFullText] = useState(false)

  const toggleFullText = (): void => {
    setShowFullText((prev) => !prev)
  }

  const getDisplayText = (
    jobDescription: string,
    showFullDescription: boolean,
    toggleFullDescription: () => void,
  ): JSX.Element => {
    if (jobDescription.length <= 290 || showFullDescription) {
      return (
        <>
          {jobDescription}
          {showFullDescription && (
            <span
              className={styles.hideSection}
              onClick={toggleFullDescription}
            >
              {' '}
              {I18n.t('label.see_less-1')}
            </span>
          )}
        </>
      )
    }
    return (
      <>
        {jobDescription.slice(0, 290)}...
        <span
          className={styles.hideSection}
          onClick={toggleFullDescription}
        >
          {' '}
          {I18n.t('label.see_more')}
        </span>
      </>
    )
  }

  return (
    <Frame title={I18n.t('label.hiring_details')}>
      <Flex
        flexCol
        style={{ gap: '16px' }}
      >
        <Flex justifyBetween>
          <Flex
            flexCol
            style={{ gap: '8px' }}
          >
            <Typography
              text={title}
              variant="interface/medium/m"
            />
            <Typography
              text={hiringMonth}
              variant="interface/medium/sm"
              textColor="color/fg/lighter"
            />
          </Flex>
          <Flex
            itemsCenter
            style={{ height: 'fit-content' }}
          >
            <DSIcon
              name="users-01"
              style={{ margin: '0 8px' }}
            />
            <Typography
              text={noOfVacancies}
              variant="interface/strong/sm"
            />
          </Flex>
        </Flex>
        <Flex
          flexCol
          style={{ gap: '16px' }}
        >
          {description && (
            <Flex
              flexCol
              style={{ gap: '8px' }}
            >
              <Typography
                text={I18n.t('label.job_description')}
                variant="interface/medium/sm"
              />
              <Typography
                text={getDisplayText(description, showFullText, toggleFullText)}
                variant="interface/default/sm"
                textColor="color/fg/light"
              />
            </Flex>
          )}
          {location && (
            <Flex
              flexCol
              style={{ gap: '8px' }}
            >
              <Typography
                text={I18n.t('label.location')}
                variant="interface/medium/sm"
              />
              <Typography
                text={location}
                variant="interface/default/sm"
                textColor="color/fg/light"
              />
            </Flex>
          )}
          {employmentType && (
            <Flex
              flexCol
              style={{ gap: '8px' }}
            >
              <Typography
                text={I18n.t('label.job_type')}
                variant="interface/medium/sm"
              />
              <Typography
                text={employmentType}
                variant="interface/default/sm"
                textColor="color/fg/light"
              />
            </Flex>
          )}
          {businessUnit && (
            <Flex
              flexCol
              style={{ gap: '8px' }}
            >
              <Typography
                text={I18n.t('label.business_unit')}
                variant="interface/medium/sm"
              />
              <Typography
                text={businessUnit}
                variant="interface/default/sm"
                textColor="color/fg/light"
              />
            </Flex>
          )}
          {hiringManagerName && (
            <Flex
              flexCol
              style={{ gap: '8px' }}
            >
              <Typography
                text={I18n.t('label.hiring_manager')}
                variant="interface/medium/sm"
              />
              <Flex
                itemsCenter
                style={{ gap: '8px' }}
              >
                <Avatars
                  size="sm"
                  imageSrc={hiringManagerAvatar}
                />
                <Flex
                  flexCol
                  style={{ gap: '8px' }}
                >
                  <Typography
                    text={hiringManagerName}
                    variant="interface/default/sm"
                    textColor="color/fg/light"
                  />
                  <Typography
                    text={hiringManagerJobTitle}
                    variant="interface/default/s"
                    textColor="color/fg/lighter"
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Spacer height={8} />
          {attachments &&
            attachments?.map((item: FileInfo & { url?: string }, i: number) => {
              if (item.url) {
                return (
                  <div
                    className={styles.attachContainer}
                    key={i}
                  >
                    <Attachment
                      {...item}
                      size={+item.size}
                    />
                  </div>
                )
              }
              return null
            })}
        </Flex>
      </Flex>
    </Frame>
  )
}

export default HiringEmployeesDetails
