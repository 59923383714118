import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { Cards, Card, Menu } from 'components/global/atoms'
import { CardLoading } from 'components/global/atoms/Loading'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete-24px.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/edit-24px.svg'
import I18n from 'translations/i18n'
import styles from './styles.module.css'

const ListCards = ({ actions, loading, onDelete, onEdit, list, testId }) => (
  <React.Fragment>
    <Spacer height="30px" />
    {!list.length && loading && <CardLoading />}
    <Cards
      gap="20px"
      width="80px"
      testId={`cards-list-${testId}`}
    >
      {list.map((data, i) => (
        <Card
          key={i}
          testId={`${testId}-${i}`}
        >
          <Flex justifyBetween>
            <Typography
              text={data?.name}
              variant="subheading"
            />
            <Flex itemsCenter>
              {data?.is_default && (
                <Typography
                  text={`(${I18n.t('default')})`}
                  variant="subtitle-2"
                />
              )}
              {onEdit && !data.hideEdit && (
                <EditIcon
                  onClick={() => {
                    onEdit(data)
                  }}
                  className={classnames(styles.icon, styles.editIcon)}
                  data-testid={`card-edit-btn-${data.name}`}
                />
              )}
              {!data.is_default && !data.hideDelete && (
                <DeleteIcon
                  onClick={() => {
                    onDelete(data)
                  }}
                  className={classnames(styles.icon, styles.deleteIcon)}
                  data-testid={`card-delete-btn-${data.name}`}
                />
              )}
              {actions && actions.length !== 0 && (
                <Menu
                  button={
                    <i
                      className="fa fa-ellipsis-v leave-day__actions__icon"
                      aria-hidden="true"
                    />
                  }
                  item={data}
                  actions={actions}
                />
              )}
            </Flex>
          </Flex>
          <Spacer height="10px" />

          <Typography
            text={data.name_ar}
            variant="subheading"
          />

          {data.descriptionSection && (
            <Typography
              text={data.descriptionSection}
              variant="subheading"
            />
          )}
        </Card>
      ))}
    </Cards>
  </React.Fragment>
)

ListCards.propTypes = {
  list: PropTypes.arrayOf(Object).isRequired,
  actions: PropTypes.arrayOf(Object),
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  testId: PropTypes.string,
}

ListCards.defaultProps = {
  testId: 'card',
}

export default ListCards
