import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  users: [],
  employees: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.INTEGRATION_FOODICS_USERS_LOAD_PENDING:
    case actions.INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_PENDING:
    case actions.ORGANIZATIONS_FOODICS_INTEGRATED_PENDING:
    case actions.INTEGRATION_FOODICS_SESSIONS_PENDING:
    case actions.INTEGRATION_FOODICS_USERS_SYNC_PENDING:
    case actions.INTEGRATION_FOODICS_SESSIONS_DELETE_PENDING:
    case actions.INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_FULFILLED:
    case actions.INTEGRATION_FOODICS_SESSIONS_FULFILLED:
    case actions.ORGANIZATIONS_FOODICS_INTEGRATED_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_FOODICS_USERS_LOAD_FULFILLED: {
      const { previous_page } = payload.data.data.pagination
      return {
        ...state,
        users: [...new Set([...(previous_page ? state.users : []), ...payload.data.data.users])],
        no_of_foodics_users: payload.data.data.no_of_foodics_users,
        linked_foodics_employee_ids: payload.data.data.linked_foodics_employee_ids,
        pagination: payload.data.data.pagination,
        fetching: false,
      }
    }

    case actions.INTEGRATION_FOODICS_USERS_SYNC_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_FOODICS_SESSIONS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        is_connected: false,
      }

    case actions.INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_FOODICS_USERS_LOAD_REJECTED:
    case actions.INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_REJECTED:
    case actions.INTEGRATION_FOODICS_SESSIONS_REJECTED:
    case actions.INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_REJECTED:
    case actions.ORGANIZATIONS_FOODICS_INTEGRATED_REJECTED:
    case actions.INTEGRATION_FOODICS_USERS_SYNC_REJECTED:
    case actions.INTEGRATION_FOODICS_SESSIONS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.FOODICS_UPDATING_DATA: {
      const indix = state.users.findIndex((e) => e.id === +payload.id)
      state.users[indix] = {
        ...state.users[indix],
        key: payload.key,
        employee_id: payload.employee_id,
      }
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
