/* eslint-disable prefer-template */
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import lodash from 'lodash'
import * as actions from './actions'

const initialState = {
  fetching: false,
  types_fetching: false,
  loading: false,
  cost_centers: [],

  locations: [],
  departments: [],
  classes: [],
}

function reducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case actions.NETSUIT_COST_CENTER_LIST_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.NETSUIT_COST_CENTER_CREATE_PENDING:
    case actions.NETSUIT_COST_CENTER_DELETE_PENDING:
    case actions.NETSUIT_COST_CENTER_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.NETSUIT_LOCATIONS_LOAD_PENDING:
    case actions.NETSUIT_DEPARTMENT_LOAD_PENDING:
    case actions.NETSUIT_CLASSES_LOAD_PENDING:
      return {
        ...state,
        type_fetching: true,
      }

    // * departments
    case actions.NETSUIT_DEPARTMENT_LOAD_FULFILLED: {
      const departments = payload?.data?.data || []

      return {
        ...state,
        grouped_departments: lodash.sortBy(departments, (data) => data?.name?.toLowerCase()),
        type_fetching: false,
      }
    }

    // * locations
    case actions.NETSUIT_LOCATIONS_LOAD_FULFILLED: {
      return {
        ...state,
        grouped_locations: payload?.data?.data || [],
        type_fetching: false,
      }
    }
    // * classes
    case actions.NETSUIT_CLASSES_LOAD_FULFILLED: {
      const classes = payload?.data?.data || []
      const parent_class = classes?.filter((it) => !it.parent_internal_id)
      const grouped_classes = parent_class.map((it) => ({
        ...it,
        options: classes?.filter((sub) => sub.parent_internal_id === it.internal_id),
      }))
      return {
        ...state,
        grouped_classes,
        grouping_class: classes,
        type_fetching: false,
      }
    }

    // * locations, departments
    case actions.NETSUIT_LOCATIONS_LOAD_REJECTED:
    case actions.NETSUIT_CLASSES_LOAD_REJECTED:
    case actions.NETSUIT_DEPARTMENT_LOAD_REJECTED:
      return {
        ...state,
        type_fetching: false,
      }

    case actions.NETSUIT_COST_CENTER_LIST_LOAD_FULFILLED: {
      const cost_centers = payload.data.data.tracking_dimensions
      const cost_values = cost_centers?.map((cost) => ({
        ...cost,
        employees_ids: lodash.uniq(
          cost?.tracking_dimension_values?.map((it) => it?.mapped_employee_ids)?.flat() || [],
        ),
      }))
      const all_selected_ids = cost_values?.map((it) => it?.employees_ids)?.flat()
      return {
        ...state,
        cost_centers: cost_values,
        all_selected_ids: lodash.uniq(all_selected_ids || []),
        fetching: false,
      }
    }
    case actions.NETSUIT_COST_CENTER_CREATE_FULFILLED:
    case actions.NETSUIT_COST_CENTER_UPDATE_FULFILLED:
    case actions.NETSUIT_COST_CENTER_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.NETSUIT_COST_CENTER_CREATE_REJECTED:
    case actions.NETSUIT_COST_CENTER_UPDATE_REJECTED:
    case actions.NETSUIT_COST_CENTER_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.NETSUIT_COST_CENTER_LIST_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
