import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type XeroAccountInfoType = {
  account_id: string
  sync_employees_information: null | boolean
  tenant_id: string
  tenant_name: string
}

type InitStateType = {
  account: XeroAccountInfoType | null
  connected: boolean
  err_msg: string
  fetching: boolean
  loading: boolean
  statusFetching: boolean
}

const initialState: InitStateType = {
  fetching: false,
  loading: false,
  connected: false,
  account: null,
  err_msg: '',
  statusFetching: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.UPDATE_XERO_ACCOUNT_INFO_PENDING:
    case actions.XERO_DISCONNECT_ACCOUNT_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.XERO_LOAD_ACCOUNT_INFO_PENDING:
    case actions.SYNC_XERO_EMPLOYEE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.XERO_LOAD_ACCOUNT_STATUS_PENDING:
      return {
        ...state,
        statusFetching: true,
      }
    case actions.XERO_LOAD_ACCOUNT_INFO_FULFILLED:
      return {
        ...state,
        fetching: false,
        account: payload.data.data || {},
      }

    case actions.XERO_DISCONNECT_ACCOUNT_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
        connected: false,
      }
    }
    case actions.UPDATE_XERO_ACCOUNT_INFO_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.XERO_LOAD_ACCOUNT_STATUS_FULFILLED: {
      return {
        ...state,
        statusFetching: false,
        connected: payload.data.data?.connected || false,
      }
    }

    case actions.SYNC_XERO_EMPLOYEE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SYNC_XERO_EMPLOYEE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.XERO_DISCONNECT_ACCOUNT_REJECTED:
    case actions.UPDATE_XERO_ACCOUNT_INFO_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.XERO_LOAD_ACCOUNT_INFO_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.XERO_LOAD_ACCOUNT_STATUS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        statusFetching: false,
      }
    }
    default:
      return state
  }
}
