import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'translations/i18n'
import { Modal } from '@jisr-hr/ds'

const ModalStatus = (props) => {
  const { postingData, isOpen, handleClose } = props
  return (
    <Modal
      header={{ title: i18n.t('posing_status'), withClose: true }}
      open={isOpen}
      onClose={handleClose}
      width={800}
      size="x-large"
    >
      <div className="max-h-[80vh] overflow-auto">{postingData}</div>
    </Modal>
  )
}

ModalStatus.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  postingData: PropTypes.node,
}

export default ModalStatus
