import { ChangeEvent, FocusEvent, memo } from 'react'
import {
  DropdownField,
  InlineField,
  ListItemProps,
  PasswordField,
  Textarea,
  TextField,
  TextFieldProps,
} from '@jisr-hr/ds-beta'
import { Field, FieldRenderProps } from 'react-final-form'
import { sanitizeInput } from './form.helper'

export type TextFieldAsT = 'dropdown' | 'inline' | 'textarea' | 'text'

export type TextFieldTypeT = 'text' | 'password' | 'email' | 'number'

type TextInputFieldProps<T = ListItemProps> = Omit<TextFieldProps, 'type'> & {
  name: string
  validate?: (_: any, values: any) => string | undefined
  parse?: <V, R>(value: V, name?: string) => R
  status?: 'destructive' | 'success'
  statusMessage?: string
  type?: TextFieldTypeT
  as?: TextFieldAsT
  listItems?: T[]
  dropdownValue?: T
  textareaHeight?: string
  showErrorMessage?: boolean
  resizable?: boolean
  normalize?: (value: any, previousValue: any) => any
  onInlineFieldSubmit?: (value: string) => any
  dropdownLabelKey?: string
  dropdownValueKey?: string
  dropdownSublabelKey?: string
  onDropdownChange?: (
    value: string,
    listValue?: string,
    event?: ChangeEvent<HTMLInputElement>,
  ) => void
}

const TextInputField = <T,>(props: TextInputFieldProps<T>): JSX.Element => {
  const {
    name,
    validate,
    parse,
    type,
    statusMessage,
    listItems,
    dropdownValue,
    textareaHeight,
    onDropdownChange,
    status,
    normalize,
    onInlineFieldSubmit,
    as = 'text',
    showErrorMessage = true,
    ...rest
  } = props
  return (
    <Field
      name={name}
      parse={parse}
      validate={validate}
      normalize={normalize}
    >
      {({ input, meta }: FieldRenderProps<string, HTMLElement>): JSX.Element => {
        const sharedProps = {
          id: name,
          ...input,
          ...rest,
          type,
          ...(((meta.touched && meta.error) || status) && {
            status: meta.error ? 'destructive' : status,
            ...(showErrorMessage && { helperText: meta.error || statusMessage }),
          }),
          onChange: (value: string) => {
            const sanitized = sanitizeInput(value)
            props.onChange?.(sanitized)
            input.onChange(sanitized)
          },
          onFocus: (e: FocusEvent<HTMLInputElement>) => {
            props.onFocus?.(e)
            input.onFocus(e)
          },
          onBlur: (e: FocusEvent<HTMLInputElement>) => {
            props.onBlur?.(e)
            input.onBlur(e)
          },
        }

        if (as === 'textarea') {
          return (
            <Textarea
              {...sharedProps}
              height={textareaHeight}
            />
          )
        }

        if (as === 'inline') {
          return (
            <InlineField
              {...sharedProps}
              onChange={(value) => input.onChange(value)}
              onSubmit={onInlineFieldSubmit}
            />
          )
        }

        if (as === 'dropdown') {
          return (
            <DropdownField
              {...sharedProps}
              labelKey={props.dropdownLabelKey}
              valueKey={props.dropdownValueKey}
              subLabelKey={props.dropdownSublabelKey}
              listItems={listItems ?? []}
              dropdownValue={dropdownValue}
              onChange={onDropdownChange}
              {...(((meta.touched && meta.error) || status) && {
                status: meta.touched && meta.error ? 'destructive' : status,
              })}
            />
          )
        }

        if (type === 'password') {
          return <PasswordField {...sharedProps} />
        }

        return <TextField {...sharedProps} />
      }}
    </Field>
  )
}

export default memo(TextInputField) as typeof TextInputField
