import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  employee_contract: {},
  employee_contracts: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.INVITE_ALL_EMPLOYEES_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.INVITE_ALL_EMPLOYEES_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.INVITE_ALL_EMPLOYEES_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
