import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function itemPriceOnboardingLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/item_price`
  return {
    type: actions.ITEM_PRICE_ONBOARDING_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}
