const Employee = {
  // A
  accepted_invites: 'Accepted Invites',
  avatar: 'Avatar',
  add_contributor_details: 'Add Contributor Details',
  add_new_contract: 'Add new contract',
  add_employee: 'Add Employee',
  addition_deduction: 'Addition/Deduction',
  addition_deduction_type: 'Addition/Deduction type',
  add_addition_deducation: 'Add Addition or Deduction',
  assignee: 'Assignee',
  assignee_2: 'Assignee',
  assign_to: 'Assign To',
  add_another_bank_authorizer_for_paying_the: 'Add another bank authorizer',
  account_balance: 'Leave balance based on Calendar days: {{count}}',
  actual_working_hours: 'Working Hours',
  additional_clause: 'Additional Clause',
  additional_clause_arabic: 'Additional Clause in Arabic',
  additional_clause_english: 'Additional Clause in English',
  authorized_signatory_arabic: 'Establishment’s representative name in Arabic',
  authorized_signatory_english: 'Establishment’s representative name in English',
  answer: 'Answer',
  address: 'Address',
  assign_bank_authorizer: 'Assign Bank Authorizer',
  appraisal: 'Appraisal',
  address_in_saudi: 'Address in Saudi',
  amount_of_end_of_service_settlement: 'Amount of end of service settlement',
  account_holder_name: 'ACCOUNT HOLDER NAME',
  account_holder_name_in_arabic: 'ACCOUNT HOLDER NAME in Arabic',
  additional_personal_information: 'Additional personal information',
  additional_contact_information: 'Additional contact information',
  additional_number: 'Additional Number',
  account_number: 'Account Number',
  assets: 'Assets',
  assets_request: 'Assets request',
  assets_return_date: 'Return Date (Gregorian)',
  allocated_assets: 'Allocated Assets',
  allocated_assets_alert: 'By clearing the assets for employees, you are no longer can undo this.',
  allocated_assets_description: "Assets should be cleared before ending employee's service",
  asset_name: 'Asset Name',
  asset_name_en: 'Asset Name in English',
  asset_name_ar: 'Asset Name in Arabic',
  asset_type: 'Asset Type',
  asset_description: 'Asset Description',
  asset_status: 'Asset Status',
  add_new_clearance: 'Add New Clearance',
  add_new_asset: 'Add New Asset',
  add_new_file: 'Add New File',
  add_new_benefit: 'Add new benefit',
  add_files: 'Add Documents',
  add_new_education: 'Add New Education',
  add_new_expenses: 'Add New Expenses',
  add_new_family_member: 'Add new Family member',
  add_family_member: 'Add Family Member',
  add_new_loans: 'Add New Loans',
  add_new_loan: 'Add New Loan',
  add_new_loan_settlements: 'Add New Loan Settlements',
  add_new_loan_settlement: 'Add New Loan Settlement',
  add_new_employee: 'Add New Employee',
  add_new_qualification: 'Add new qualification',
  add_new_warning_notices: 'Add new Warning Notices',
  add_new_warning_notice: 'Add new Warning Notice',
  add_non_saudi_contributor: 'Add Non-Saudi contributor',
  add_non_saudi_contributor_desc: 'This service allow you to register a Non-Saudi contributor​​​',
  add_non_saudi_contributor_details: 'Add Non-Saudi Contributor Details',
  add_saudi_contributor: 'Add Saudi Contributor',
  add_saudi_contributor_desc: 'This service allow you to register a Saudi contributor​​',
  address_in_home_country: 'Address in Home Country',
  add_promotion: 'Add promotion',
  add_appraisals: 'Add Appraisals',
  add_item: 'Add item',
  account_balance_business: 'Leave balance based on Business days: {{count}}',
  addition_type: 'Addition Type',
  add_your_bank_accounts: 'Add your bank accounts',
  access_role: 'Access role',
  role: 'Role',
  add_new_bank_attachment: 'Add new bank attachment',
  add_new_bank: 'Add new bank',
  availability_in_cchi: 'Availability In Cchi',
  add_benefits: 'Add Allowance',
  apprise: 'Appraisal',
  action: 'Action',
  ACTION: 'ACTION',
  available: 'Available',
  available_leave_balance: 'Available leave balance',
  available_shifts: 'Available shifts',
  add_photo: 'Add photo',
  define_probation_period: 'Define Probation Period',
  annual_leave_entitlement: 'Annual leave entitlement',
  add_employee_shift_text: 'It’s a {{shift_type}} Shift and has {{has}} people working in it.',
  attach_contract: 'Attach contract',
  attach_contract_description: 'Click "attach" button to attach the contract',
  attach: 'Attach',
  attendance_reviewer: 'Attendance Reviewer',
  add_new_out_of_payroll_transactions: 'Add out of payroll transactions',
  apply_for_leave: 'Apply for leave',
  apply_for_resum: 'Apply for resume',
  annual_tip: 'The balance of the annual leave due to the employee in the year',
  appraisal_history: 'Appraisal history',
  add_allowance: 'Add Allowance',
  allowance_name: 'Allowance Name',
  ajeer_fees: 'Ajeer Fees',
  alert_to_make_changes: 'Are you sure you want to  make changes ?',
  an_immediate_changes: 'An immediate changes',
  a_scheduled_change: 'A scheduled change',
  all_loans: 'All Loans',
  add_contract: 'Add Contract',
  add_passcode: 'Add Passcode',
  added_on: 'Added On',
  are_you_sure_want_offboard_this_employee:
    'Are you sure that you want to offboard this employee ?',
  allowances: 'Allowances',
  allowances_alt_translation: 'Allowances',
  allowance_type: 'Allowance Type',
  are_you_sure_you_want_to_complete: 'Are you sure you want to complete the probation period ?',
  add_insurance_details: 'Add insurance details',
  add_to_gosi: 'Add to Gosi',
  area_manager: 'Area Manager',
  assign_new_line_manager: 'Assign New Line Manager',
  assign_approval: 'Assign Approval',
  assign_approval_for_attendance_reviewer: 'Assign Approval',
  add_another_approval: 'Add Another Approval',
  available_to_add: 'Available to add: ',
  all_employees: 'All Employees ',
  all_employees_2: 'All Employees',
  annual_balance: 'Annual Balance',
  audit_log: 'Audit log',
  affected_employee: 'Affected Employee',
  added_employee: 'Added Employee',
  // B
  benefits: 'Benefits',
  bulk_update_payment_details: 'Bulk update payment details',
  benefit_name: 'Benefit Name',
  back_to_employees: 'Back to Employees',
  bank_account: 'Bank Account',
  back_to_profile: 'Back to profile',
  benefit_type: 'Benefit Type',
  bank_authorization: 'Bank Authorization',
  basic_salary: 'Basic Salary',
  basic_wage: 'Basic Wage',
  bank_name: 'Bank Name',
  bank_name_arabic: 'Bank Name in Arabic',
  bank_name_english: 'Bank Name in English',
  bank_country_type: 'Bank country type',
  bank_location: 'Bank location',
  bank_accounts: 'Bank Accounts',
  branch_name: 'BRANCH NAME',
  branch_in_arabic: 'BRANCH in Arabic',
  border_no: 'Border No.',
  bulk_upload_import_sheets: 'Bulk upload import sheets',
  business_unit_manager: 'Business Unit Manager',
  building_number: 'Building Number',
  build_up: 'Build up',
  biometric_devices_management: 'Biometric Devices Management',
  biometric_devices_management_description:
    'Manage the different ways which employee can submit the attendance log from a biometric device.',
  billing_history_model: 'Billing History',
  // C
  capacity_of_arabic: 'Job title of the establishment’s representative in Arabic',
  capacity_of_english: 'Job title of the establishment’s representative in English',
  create_transaction: 'Create new transaction',
  company_contribution: 'Company Contribution',
  current: 'Current',
  cost_allocation: 'Cost allocation',
  current_time_perioud: 'The current time preiod is set to {{duration}} days. ',
  clearance_tasks: 'Clearance Tasks',
  contract_clauses: 'Contract Clauses',
  contract_is_expired: '{{name}} contract is expired!',
  contract_period: 'Contract Period',
  contract_type: 'Contract Type',
  change_salary_info: 'Change Salary info',
  contract_start_date: 'Contract Start Date (Gregorian)',
  contract_start_date_jijri: 'Contract Start Date (Hijri)',
  contract_end_date: 'Contract End Date (Gregorian)',
  contract_end_date_jijri: 'Contract End Date (Hijri)',
  complete_the_contract: 'Complete the Contract',
  contract_warning_title: 'Are you sure you want to complete the contract?',
  compensation: 'Compensation',
  commissions: 'Commissions',
  clearance_process: 'Clearance Process',
  confirm_changes: 'Confirm Changes',
  delete_all_employee_information: 'Delete All Employee Information',
  confirm_offboarding: 'Confirm Offboarding',
  create_contract: 'Create Contract',
  cr_no: 'CR No.',
  country: 'Country',
  completed: 'Completed',
  create_new_item: 'Create new item',
  company_contribution_percentage: '{{count}}% company contribution',
  company_contribution_percentage_retired_employees:
    '{{count}}% company contribution “Retired Employees”',
  company_and_employee_contribution_percentage:
    '{{count}}% company contribution and {{count2}}% employee contribution',
  company_and_employee_contribution_percentage_saned_excluded:
    '{{count}}% company contribution and {{count2}}% employee contribution "Saned Excluded"',
  contract: 'Contract',
  cost: 'Cost',
  costs: 'Costs',
  cchi_effective_date: 'Cchi effective date',
  contract_details: 'Contract details',
  create_contract_description: 'Click "Build up" button to view and download the contract',
  contracts_notification_msg:
    'You have <b>{{count}}</b> employees their contracts have expired, and <b>{{count2}}</b> employees their contract is about to expire',
  contributor_type: 'Contributor Type',
  contributor_is_abroad: 'Contributor is abroad',
  contributor_language: 'Contributor language',
  carry_forward_notification_msg:
    '<b>{{count}}</b> employees who will be affected by carry forward policy',
  check_them_out_now: 'Check them out now',
  changes_are_being_reviewed: 'Changes are being reviewed',
  cr_and_salary_bank: 'CR name',
  create_probation_period: 'Create Probation Period',
  change_the_start_date_headding: 'Are you sure you want to change the start date ?',
  change_the_start_date_description:
    "This is employee's first day of work, Changing an employee's start date changes their payroll and contract  start.",
  change_the_start_date_description_performance:
    "This is the employee's first day of work, Changing an employee's start date changes his/her payroll, contract, probation period review start dates.",
  changes_effect_payroll: 'These changes will effect payroll.',
  changes_that_already_happened: 'Changes that already happened',
  commercial_registrations: 'Commercial registrations • {{number}}',
  other_commercial_registrations: 'Other Commercial Registrations',
  commercial_registration: 'Commercial registration',
  choose_salary_bank: 'Select the CR and the salary bank account',
  card: 'Card',
  card_number: 'Card Number',
  card_facility_code: 'Card Facility Code',
  current_scheduled: 'Current & Scheduled',
  current_leaves: 'Current',
  scheduled_leave: 'Scheduled',
  current_salary: 'Current Salary',
  can_not_update_the_joining_date:
    'Can not update the joining date, as the employee has a completed probation period review',
  changed_by: 'Changed by',
  change_language: 'العربية',
  create_leave_request: 'Create a leave request',
  clearing_asset: 'Clearing asset',
  cancel_employee_offboarding: 'All actions are saved, you can exit and continue later.',
  contact_details: 'Contact Details',
  contact_type: 'Contact Type',
  can_not_add_employees: 'You can’t add new employees!',
  can_not_add_employees_dec:
    'This action is disabled, please visit “Billing History” section to settle the due payment and enable adding employees',
  can_add_employees: 'Additional Employees Fees',
  can_add_employees_dec:
    'Adding new employees will be in additional fees for subscription, calculated from creation date to the end of the subscription. If the intention is to replace an employee with another, you can offboard the active employee first, and then add the new employee.',
  can_add_employees_dec_with_account:
    'You have {{num}} employees available to add. Adding extra employees will be in additional fees for subscription, calculated from the creation date to the end of the subscription.If the intention is to replace an employee with another, you can offboard the active employee first, and then add the new employee.',
  country_phone_code: 'Country Code',
  // D
  due_date: 'Due Date',
  delete_profile: 'Delete Profile',
  delete_confirmation_alert:
    'Deleting the profile will result in canceling pending requests, take action on requests to avoid canceling it.',
  delete_confirmation_note:
    'Unlike offboarding employee, deleting will NOT allow you to: <ul><li>Prepare Final settlement</li><li>Clear assets</li><li>View employee data in reports</li><li>Perform any action related to this employee</li></ul>',
  delete_employee: 'Delete Employee',
  degree: 'Degree',
  deduction_starting_month: 'Deduction Starting Month',
  date_of_birth: 'Date of birth (Gregorian)',
  date_form: 'YYYY-MM-DD',
  date_form_dd_mm_yyyy: 'DD-MM-YYYY',
  date_form_yyyy_mm_dd: 'YYYY-MM-DD',
  medical_insurance_expiry_date: 'Medical insurance expiry date (Gregorian)',
  document_no: 'DOCUMENT NO',
  done: 'Done',
  edit_bank: 'Edit Bank',
  delete_it: 'delete it!',
  deleted: 'Deleted!',
  deleted_employee: 'Deleted',
  secondary_reasons: 'Secondary reasons',
  secondary_reason: 'Secondary reason',
  document: 'Document',
  document_request_confirmation_message:
    'To add this document, you need to send a request to approve this change.',
  duration: 'Duration',
  deduction_type: 'Deduction type',
  daily_salary: 'Daily Salary',
  documents: 'Documents',
  discard_changes: 'Discard Changes',
  district: 'District',
  define_employee_in_attendance_exemption_policy:
    'Define the employee in Attendance exemption policy',
  document_reviewed: 'Document is being reviewed',
  delete_passcode: 'Delete Passcode',
  delete_card: 'Delete Card',
  department_manager: 'Department Manager',
  did_employee_have_a_probation: 'Did the Employee have a Probation Period?',
  drag_and_drop_excel_sheet_pdf_image_or: 'Drag-and-drop, Excel sheet, PDF, Image, or',
  do_you_want_to_terminate_active_employee_from_GOSI:
    'Do you want to exclude/terminate an active employee from GOSI',
  do_you_want_to_add_this_employee_to_GOSI: 'Do you want to add this employee to GOSI?',
  date_picker_duration: 'Duration',
  // E
  expired_invites: 'Expired Invites',
  extend_probation_period: 'Extend Probation Period',
  extend_months: 'Extend to 6 months',
  extend_probation: 'Extend Probation',
  expenses: 'Expenses',
  edit_asset: 'Edit Asset',
  edit_confirmation: 'Edit Confirmation',
  edit_education: 'Edit Education',
  edit_on_the_salary: 'Edit on the salary',
  edit_file: 'Edit File',
  edit_fees: 'Edit fees',
  edit_basic_information: 'Edit Basic Information',
  edit_job_information: 'Edit Job Information',
  edit_iqama_information: ' Edit ID and Iqama Information',
  employee_account_type: 'Account type for the employee',
  employee_information_changes: 'Employee Information Changes',
  employee_files: 'Employee Files',
  employees: 'Employees',
  employee_id: 'Employee ID',
  dynmaic_employee_id: 'Employee ID {{ id }}',
  employee_terminated_successfully: 'Employee {{name}} Terminated Successfully',
  expiry_date: 'Expiry Date (Gregorian)',
  EXPIRY_DATE: 'EXPIRY DATE',
  emp_id: 'Emp ID',
  employee_name: 'Employee Name',
  edit_loan: 'Edit Loan',
  exit_interview: 'Exit Interview',
  exit_offboarding: 'Exit Off-boarding',
  end_of_service_settlement: 'End of service settlement',
  education: 'Education',
  edit_contract: 'Edit Contract',
  expense_type: 'Expense Type',
  extend_the_probation_period: 'Extend the probation period',
  employment_type: 'Employment type',
  emergency_contact: 'Emergency Contact',
  emergency_contact_name: 'Emergency Contact Name',
  edit_emergency_contact: 'Edit Emergency Contact',
  edit_family_member: 'Edit Family Member Data',
  edit_passport_information: 'Edit Passport Information',
  effective_date: 'Effective Date',
  effective_date_month: 'Effective date (month)',
  effective_date_year: 'Effective date (year)',
  event_date: 'Effective date',
  employee_delete_warning: 'All pending  requests and related actions will be deleted',
  employee_delete_confirmation: 'Employee has been deleted.',
  employee_email: 'Employee email',
  employee_added_successfully: 'Employee added successfully',
  employee_data: 'Employee Data',
  employees_without_emails: 'Employees without email',
  promote_employee: 'Promote employee',
  employees_without_emails_alert:
    '{{count}} Employees are not able to use the Employee Self service',
  employees_without_fingerprint: '{{count}} Employees their fingerprint has not been registered',
  expiring_contracts: 'Expiring contracts',
  employee_documents: 'Employee Documents',
  employee_docs: 'Employees Docs',
  employee_loans: 'Employee Loans',
  employee_cost_to_company: 'Employee Cost to Company',
  update_allowance: 'Update allowance',
  employee_assets: 'Employee Assets',
  employee_information: 'Employee Information',
  establish_effective_date_the_changes: 'First, let’s establish the effective date of the changes',
  employer_number: 'Employer Number',
  employee_has_re_assigned:
    'Employee has reassigned tasks. Kindly, Reassign those tasks to another employee',
  employee_defined_as_executor: 'Employee defined as a tasks executor',
  employee_number: '{{ count }} employees',
  edit_address_in_home_country: 'Edit Address in Home Country',
  edit_address_in_saudi: 'Edit Address in Saudi',
  edit_date: 'Edit date',
  effective_date_history: 'Effective date',
  edit_item: 'Edit item',
  edit_others: 'Edit Others',
  edit_salary_info: 'Edit Salary Info',
  edit_payment_details: 'Edit Payment Details',
  edit_gosi_configuration: 'Edit GOSI subscription details',
  edit_insurance: 'Edit insurance',
  employee_inviter: 'Employee inviter',
  engagement_details: 'Engagement Details',
  establishment: 'Establishment',
  establishment_location: 'Establishment Location',
  establishment_mol_branch: 'Establishment MOL Branch',
  exclude_terminate: 'Exclude / Terminate',
  expiry_date_hijri: 'Expiry Date (Hijri)',
  ex_prisoner: 'Ex-prisoner',
  experience: 'Experience',
  extension: 'Extension',
  employee_requests: 'Employee Requests',
  executor: 'Executor',
  audit_record_actions: 'Actions',
  // F
  family_info: 'Family Info',
  file_name: 'File Name',
  final_compensation: 'Final compensation',
  finalize_compensation: 'Finalize Compensation',
  final_net_settlement: 'Final net settlement',
  first_name: 'First name',
  first_name_in_arabic: 'First Name in Arabic',
  full_name_english: 'Full name in English',
  full_name_arabic: 'Full name in Arabic',
  fix_term: 'Fix term',
  fallback_gosi_error: 'Something went wrong on server, try again later',
  files_uploaded: 'Files Uploaded',
  fees: 'Fees',
  finger_type: 'Finger Type',
  fingerprints: 'Fingerprints',
  fingerprints_single: 'Fingerprints',
  fingerprint: 'Fingerprint',
  full_name: 'Full Name',
  // G
  graduation_date: 'Graduation date (Gregorian)',
  gosi_configuration: 'GOSI Configuration',
  gosi_configurations: 'Gosi configurations',
  gosi_registration: 'GOSI Registration',
  gosi_employee_number: 'GOSI employee Number',
  gosi_applicable_amount_comp: 'GOSI applicable amount',
  gosi_effective_date: 'GOSI Effective Date',
  general: 'General',
  gosi_changes: 'GOSI Changes',
  gois_name_and_bank: 'CR and the Salary Bank Account',
  gosi_name: 'GOSI Name',
  gosi_services: 'GOSI Services',
  gosi_subscription: 'GOSI Subscription',
  gosi_subscriptions: 'GOSI Subscriptions',
  gosi_subscription_details: 'GOSI Subscription Details',
  gosi_file_deleted_contain_employees:
    "This Gosi file  can't be deleted because it contain employees",
  gosi_subscription_tooltip:
    'GOSI subscription by default = Basic Salary + Housing + Commission however you can adjust the value manually',
  gosi_termination_date: 'GOSI Termination Date',
  gosi_termination_reason: 'GOSI Termination Reason',
  gosi_registration_name_number: 'GOSI Registration (Name & Number)',
  gosi_registration_no: 'GOSI Registration No.',
  go_to_billing_history: 'Go to Billing History',
  // H
  has_no_contract: '{{name}} Has No Contract',
  has_no_contract_desc: 'Create a new contract to get all details correctly in Offboading.',
  home_po_box: 'Home PO BOX',
  hide: 'Hide',
  housing: 'Housing',
  housing_benefit: 'Housing Benefit',
  history: 'Leaves History',
  // I
  invitations_tracking: 'Invitations Tracking',
  id_iqama_and_passport_details: 'ID / Iqama and Passport Details',
  id_iqama_details: 'ID / Iqama Details',
  import_bulk_employees: 'Import Bulk Employees',
  installment_starts_in: 'Installment Starts In',
  installments_ends_in: 'Installments Ends In',
  issue_date: 'Issue date (Gregorian)',
  issue_month: 'Issue Month',
  institute: 'Institute',
  iban: 'IBAN',
  iban__salary_card: 'IBAN/Salary Card No.',
  id: 'ID',
  id_iqama_information: 'Id and Iqama Information',
  id_iqama_number: 'ID / Iqama Number',
  id_number: 'Document number',
  id_expiry: 'Id Expiry',
  id_expiry_date: 'ID / Iqama Expiry (Gregorian)',
  iqama_issue_date: 'ID / Iqama Issue Date (Gregorian)',
  id_expiry_date_hijri: 'ID / Iqama expiry  (Hijri)',
  id_type: 'ID Type',
  iqama_issue_place: 'ID / Iqama issue place',
  iqama_issue_date_hijri: 'ID / Iqama issue date (Hijri)',
  iqama_expiry_date_hijri: 'ID / Iqama expirt date (Hijri)',
  iqama_expiry_date: 'IQAMA EXPIRY DATE (Hijri)',
  iqama_number_national_id: 'Iqama Number / National ID',
  identification_information: 'Identification Information',
  id_information: 'ID information',
  invite: 'Invite',
  id_iqama_border_number: 'ID/iqama/Border number',
  insurance_coverage: 'Insurance Coverage',
  information_change: 'Information Change',
  info_change_request_confirmation_message:
    'To change this information, you need to send a request to approve this change.',
  insurance: 'Insurance',
  insurance_category: 'Category',
  insurance_number: 'Insurance Number',
  insurance_effective_date: 'Insurance effective date',
  identification: 'Identification',
  institute_name: 'Institute name',
  medical_insurance_number: 'Insurance no',
  invite_all_employee_message:
    'This will reset the current password for all employees and create a new password for each employee and send it to his email, would you like to continue? <br /> Note: the password of the accounts with no email will not be changed',
  invite_all_employees: 'Invite all employees',
  in_payroll_loan_description: 'Loan amount will be paid inside the payroll',
  in_payroll_description: 'Transaction will count inside the payroll',
  iqama_profession: 'Iqama Profession',
  iqama_profession_en: 'Iqama Profession EN',
  iqama_profession_ar: 'Iqama Profession AR',
  item: 'Item',
  immediate_change: 'Immediate change',
  invitation_sent: 'Invitation sent ({{password_reset_expire_after}} to re-invite)',
  invitation_expired: 'Invitation will be expired after ',
  invitations_statuses: 'Invitations Status',
  // J
  job_information: 'Job Information',
  job_title: 'Job Title',
  job_title_of_the_employee_in_arabic: 'Job title of the employee in Arabic',
  job_title_of_the_employee_in_english: 'Job title of the employee in English',
  jisr_will_sync_following_salary_note:
    '{{app_name}} will sync the following salary details for this employee with your GOSI account:',
  joining_date: 'Joining Date',
  joining_date_hijri: 'Joining Date (HIJRI)',
  // K
  // L
  letter_type: 'Letter type',
  last_active_time: 'Last active time is: {{time}}',
  last_active: 'Last Active',
  loans: 'Loans',
  loan_type: 'Loan Type',
  loan_installments: 'Loan Installments',
  loan_settlement: 'Loan settlement',
  loan_settlements: 'Loan settlements',
  last_name: 'Last Name',
  used_leaves_tip: 'Number of annual leave days used during the current year',
  last_name_in_arabic: 'Last Name in Arabic',
  loan_amount: 'Loan Amount',
  leave_balance: 'Leave Balance',
  leave_history: 'Leave History',
  last_working_day: 'Last Working Day',
  left_pickie: 'Left Pickie',
  left_ring: 'Left Ring',
  left_middle: 'Left Middle',
  left_index: 'Left Index',
  left_thumb: 'Left Thumb',
  leave_resumption_date: 'Leave resumption (Gregorian)',
  list_of_non_saudi_workers: 'List of Non-Saudi Workers',
  location_manager: 'Location Manager',
  last_payslip_allowances: 'Total Earning',
  net_salary: 'Net Salary',
  // M
  monthly_deduction: 'Monthly Deduction',
  deduction_month: 'Deduction Month',
  main_information: 'Main information',
  manunal_addtion_deduction: 'Manual Addition and Deduction',
  major: 'Major',
  middle_name: 'Middle Name',
  middle_name_in_arabic: 'Middle Name in Arabic',
  marital_status: 'Marital Status',
  manual_additions: 'Manual additions',
  manual_deductions: 'Manual deductions',
  medical_insurance: 'Medical Insurance',
  medical_insurance_providers: 'Medical insurance providers',
  medical_insurance_provider: 'Medical insurance provider',
  my_subordinates: 'My team members',
  method: 'Method',
  manager: 'Manager',
  mobile_number: 'Mobile Number',
  my_leaves: 'My Leaves',
  manged_groups_count: 'Groups',
  // N
  not_invited_employees: 'Not Invited Employees',
  not_delivered_invites: 'Not Delivered Invites',
  new_employee: 'New Employee',
  new_insurance: 'Add insurance details',
  new_Line_manager: 'New Line Manager',
  national_id_or_iqama_number: ' ID / Iqama ',
  national_id: 'National ID',
  next_step: 'Next step',
  next: 'Next',
  no_fingerprint: 'No fingerprint',
  no_transactions: 'No performed transactions yet',
  notify_people_about_this_change: 'Notify people about this change',
  notification_date: 'Notification Date',
  never_logged: 'Never logged',
  new_cost: 'New Cost',
  new_doc: 'New Document',
  new_education: 'New Education',
  new_employee_cost: 'New Employee Cost to Company',
  no_team_members: 'No Team Members',
  no_promotion_history: 'No Promotion History',
  no_appraisal_history: 'No Appraisal history',
  no_contact_has_been_added: 'No contract has been added.',
  new_settlement: 'New Settlement',
  new_leave_request: 'New leave request',
  none: 'none',
  no_organization_history: 'No organization history',
  no_compensation_history: 'No salary and financial history',
  no_running_and_upcoming_leaves: 'There is no Running  and upcoming leaves for employee',
  not_registered_in_gosi: 'Not Registered in Gosi',
  not_in_saudi: 'Outside The Kingdom',
  no_passcode_has_been_added_for_the_employee: 'No passcode has been added for the employee.',
  note_passcode: 'Note: Passcode should be in numerical and contains upto 6 digits.',
  no_card_has_been_added_for_the_employee: 'No card has been added for the employee.',
  number_of_probation_days: 'Probation Period Days',
  notice_period: 'Notice Period',
  no_fingerprint_added_for_the_employee: 'No Fingerprint added for the employee',
  no_previous_records: 'No Previous Records',
  no_history: 'No Leaves History',
  no_leave_records: "You don't have any leave records yet",
  no_scheduled_changes: 'No Scheduled Changes',
  no_leave_currently: 'is not on a leave currently',
  no_scheduled_leave: 'There is no scheduled leaves',
  note_Once_confirmed_employee_status_would_inActive:
    ' Note: Once it is confirmed the employee status would be inActive, and a final settlement task would be generated to calculate the final settlement for the employee ',
  no_wait: 'No, Wait',
  new_address_in_home_country: 'New Address in Home Country',
  new_address_in_saudi: 'New Address in Saudi',
  new_approval: 'New approval',
  new_custom_fields: 'New Custom Fields',
  new_emergency_contact: 'New Emergency Contact',
  new_executor: 'New executor',
  new_assignee: 'New Assignee',
  new_passport_information: 'New Passport Information',
  new_company_document: 'Company Documents',
  new_contract: 'New Contract',
  new_employee_documents: 'New Employee Documents',
  new_assigner: 'New Assigner',
  notification_date_must_be_before_last_working_day:
    'Notification date must be before last working day',
  new_line_manager: 'New Line Manager',
  new_executer: 'New Executer',
  not_now: 'Not Now',
  new_issued: 'A new invoice has been issued!',
  new_employee_alert:
    'We won’t be able to refund the amount for this employee, but you can replace the employee with another.',
  no_employee: 'No Employee',
  no_activity_recorded: 'No Activity Recorded',
  no_activity_recorded_desc:
    'There are currently no audit log entries to display. All actions such as add, update, or delete will appear here once performed.',
  no_results: 'No results',
  no_results_desc:
    'No results found for your search.Please check your spelling or try different keywords.',

  // O
  other_allownces: 'Other Allowances',
  Other_Documents_Details: 'Other Documents Details',
  other_details: 'Other details',
  organization_info: 'Organization',
  off_boarding: 'Off-boarding',
  organization_structure: 'Organization structure',
  organization_and_information: 'Organization and information',
  offboard_steps: 'Offboard Steps',
  offboarding_step_warning: 'Please complete Step 1 first!',
  optional: 'Optional',
  optional_clause: 'Optional Clause',
  optional_clause_option1:
    "Either party may terminate the contract by serving on the other party no less than 60 days' written notice.",
  optional_clause_option2:
    'The contract shall be automatically renewed for an equivalent period, unless at least 30 days perior tp the contract end date a notice is given by either party of its intent not to renew the contract.',
  operation_could_take_some_time_reflect_gosi: 'Operation could take some time to reflect on GOSI',
  offboard: 'Offboard',
  out_of_payroll: 'Out of payroll',
  out_of_payroll_description: 'Transaction will count outside the payroll',
  out_of_payroll_loan_description: 'Loan amount will be paid outside the payroll',
  outsourcing_company: 'Outsourcing company',
  outsourcing_company_change:
    "Changing the employee's Outsourcing provider will force him to follow the new outsourcing provider's configuration. this could lead to minimize the number of his benefits.",
  optional_information: 'OPTIONAL INFORMATION',
  organization_history: 'Organization History',
  okay_when_did_this_change_go_into_effect: 'Okay, when did this change go into effect?',
  outsourcing_provider_contain_employees:
    "This Outsourcing provider can't be deleted because it contain the following employees",
  only_numerical_allowed_from_4_to_6_digits: 'Only numerical allowed from 4 to 6 digits',
  organization_tasks:
    'The employee is an executor for the following tasks. Kindly, Re-assign the executor',
  opening_balance_tip:
    'The initial annual leave balance for the employee while registered in {{app_name}}.',
  old: 'Old',
  other_allowances: 'Other Allowances',
  offboarding_employee_steps: 'Off-boarding an employee includes these steps',
  org_immediate_change_description: 'Changes will take effect starting from today.',
  no_results_found: 'No Results Found',
  // P
  pending_invites: 'Pending Invites',
  pending_employee_requests: 'Pending employee requests',
  pending_requests_name: 'Pending requests by {{name}}',
  pending_requests_adjustment: 'Pending requests adjustment',
  pending_requests_delete: 'Pending requests',
  parent_department_manager: 'Parent Department Manager',
  part_of_workflow: 'Part of a workflow',
  previous_contracts: 'Previous contracts',
  previous_records: 'Previous Records',
  probation_period: 'Probation Period',
  print_summary: 'Print Summary',
  personal: 'Personal',
  personal_email: 'Personal email',
  pending_loan_amount: 'Pending Loan Amount',
  pending_amount: 'Pending Amount',
  pending_requests_for_this_employee: 'Pending requests for this employee',
  pending_tasks: 'Pending Tasks',
  payment_details: 'Payment Details',
  payment_type: 'Payment Type',
  passport_number: 'Passport Number',
  passport_expiry_date: 'Passport Expiry Date (Gregorian)',
  passport_issue_date: 'Passport Issue Date (Gregorian)',
  passport_issue_place: 'Passport Issue Place',
  passport_information: 'Passport information',
  personal_information: 'Personal information',
  place_of_birth: 'Place of Birth',
  place_of_birth_in_arabic: 'Place of Birth in Arabic',
  postal_code: 'Postal Code',
  promotion_history: 'Promotion History',
  promoted_by: 'Promoted by',
  past_change_without_retroactive_calculation: 'Past change without retroactive calculation',
  past_retrospective_change_description:
    'Changes will be applied from a past date, but there will be no salary recalculation for past period',
  promote: 'Promote',
  probation_warning: 'Are you sure you want to complete the probation?',
  probation_warning_note:
    'This action couldn’t be cancelled. Once you click on (Yes) the probation period status will be changed to “Passed”',
  probation_success_message: 'Probation has been successfully completed',
  payrolls: 'Payrolls',
  policy_number: 'Policy No',
  processes: 'Processes',
  per_page: 'Per page',
  probation_duration: 'Probation duration',
  probation_expire: 'Probation expire',
  previose_tip:
    "The employee's carried forward  balance from the previous calendar year and is considered the opening balance in the current year for employee",
  probation_notification_msg:
    'You have <b>{{count}}</b> employees their probation period is about to expire',
  passcode: 'Passcode',
  password_reset_link_has_been: 'The reset password email has been sent',
  preiod_is_set_to: 'Probation period duration was {{days}} Days <br /> Ended at {{endDate}}.',
  past_change: 'Past change',
  past_change_description:
    'Changes will be applied in the past. They will also reflect in reports after this date',
  presents: 'Presents',
  // Q
  // R
  reduce_months: 'Reduce to 3 months',
  renew_contract: 'Renew Contract',
  relationship: 'Relationship',
  religion: 'Religion',
  retro: 'Retro',
  re_invite: 'Reinvite',
  reinvite_warning: 'You will lose the last credential!',
  registered_fingerprint: 'Registered fingerprint',
  request_cancellation: 'Request cancellation',
  rest_days_per_week: 'Rest days per week',
  reason_of_this_change: "What's the reason of this change?",
  running_upcoming_leaves: 'Running & Upcoming Leaves',
  request_cancellation_cancellation_workflow:
    'This Request cancellation will not go throw cancellation workflow will be cancelled direct  and shown in Requests Reports.',
  registered: 'Registered',
  registered_in_gosi: 'Registered in Gosi',
  right_thumb: 'Right Thumb',
  right_index: 'Right Index',
  right_middle: 'Right Middle',
  right_ring: 'Right Ring',
  right_pickie: 'Right Pickie',
  remarks: 'Remarks',
  re_assigned_tasks: 'Re assigned tasks',
  requests: 'Requests',
  reset_password: 'Reset password',
  reason_of_ending_service: 'End of service reason',
  reassign_workflows: 'Reassigning workflow, tasks, and organization structure',
  retrospective_change: 'Retrospective change',
  retrospective_change_description_shared: 'Changes will be retrospectively from a past date',
  retrospective_change_description:
    'Changes will be retrospectively from a past date, salary will recalculated based on this change',
  replace_employee: 'Replace Employee',
  request_leave: 'Request a Leave',
  // S
  serial_number: 'Serial Number',
  scheduled: 'Scheduled',
  salary_changes: 'Salary Changes',
  salary_settlement: 'Salary settlement',
  salary_items: 'Salary items',
  salary_up_to_date: 'Salary up to date',
  settlement_in_cash: 'Settlement in cash',
  settlement_of_paid_leaves: 'Settlement of paid leaves',
  step_back: 'Step back',
  step: 'Step',
  salary_appraisals: 'Salary appraisals',
  study_length: 'Study length',
  student_a: 'Student',
  save_answer: 'Save answer',
  salary: 'Salary',
  swift_code: 'SWIFT CODE',
  salary_correct: 'Salary Correct',
  sort_code: 'SORT CODE',
  shift_details: 'Shift Details',
  selected: 'Selected ({{number}})',
  skip: 'Skip',
  salary_card_number: 'Salary card number',
  salary_card: 'Salary card',
  salary_status_gosi: 'Salary Status in GOSI',
  salary_appraisals_history: 'Salary Appraisals History',
  second_name: 'Second name',
  second_name_in_arabic: 'Second name in Arabic',
  see_all: 'See all',
  send: 'Send',
  send_request: 'Send Request',
  send_to_finance: 'Send to finance',
  select_payroll: 'Select Payroll',
  select_people: 'Select People',
  salary_and_benefits: 'Salary & Benefits',
  street_name: 'Street name',
  save_changes: 'Save changes',
  save_exit: 'Save & Exit',
  skip_for_now: 'Skip for now',
  subordinates: 'Team Members',
  salary_adjustment: 'Salary Adjustment',
  salary__financial: 'Salary & Financial',
  salary_info: 'Salary Info',
  select_date: 'Select Date',
  shift: 'Shift',
  salary_and_financial_history: 'Salary and financial history',
  submit_log_using_passcode_on_the_device: 'Submit log using passcode on the device.',
  save_passcode: 'Save Passcode',
  submit_log_using_digital_card_on_the_device: 'Submit log using digital card on the device.',
  submit_log_using_fingerprint_on_the_device: 'Submit log using fingerprint on the device.',
  salary_change_before_current_payroll_note:
    'The system would calculate the salary update you did and it would be considered in the payroll with a retroactive. Do you want to confirm these changes?',
  salary_change_before_current_payroll_no_retro_note:
    'The system would calculate the salary update you did and it would be considered in the payroll without retroactive. Do you want to confirm these changes?',
  salary_change_after_current_payroll_note:
    'The system would calculate the salary update/GOSI applicable amount update you did and it would be considered in the {{ date }} payroll. Do you want to confirm these changes?',
  salary_change_in_current_payroll_note:
    'The system would calculate the salary update/GOSI applicable amount update you did and it would be considered in the current open payroll. Do you want to confirm these changes?',
  system_will_consider:
    'System will consider the employee probation period as 90 Days. You can modify the period from the custom option or employee profile later on',
  scheduled_changes: 'Scheduled Changes',
  salary_changes_immediate_note:
    'The system would calculate the salary update you did and it would be considered in the current open payroll. Do you want to confirm these changes?',
  scheduled_change: 'Scheduled Change',
  past_change_finance: 'Changes will be applied from a past date',
  scheduled_change_description:
    'Information will remain same untill this date, and will be applied in this day',
  salary_immediate_change_description: 'Changes will take effect starting from this month',
  salary_immediate_change_description_date:
    'Changes will take effect starting from current payroll month ({{ date }})',
  send_via_email: 'Send via Email',
  select_one_of_gosi_registration_establishment: 'Select one of GOSI registration establishment',
  specialization: 'Specialization',
  sick_leaves: 'Sick Leaves',
  send_invitations: 'Send Invitations',
  // T
  total_sent_invites: 'Total Sent Invites',
  employee_returned_the_asset_to_the_organization:
    'Employee returned the asset to the organization',
  employee_recieved_this_asset: 'Employee recieved this asset',
  task_assignee: 'Task Assignee',
  task_status: 'Task Status',
  terminate: 'Terminate',
  terminate_contributor: 'Terminate Contributor',
  terminate_contributor_desc: 'Terminate an active employee from GOSI',
  terminate_contributor_from_gosi: 'Terminate Contributor from GOSI',
  terminated: 'Terminated',
  termination_details: 'Termination Details',
  team_and_org_structure: 'Team & org structure',
  termination_reason: 'Termination reason',
  termination_date: 'Termination Date',
  telephone: 'Telephone',
  town_city: 'Town/City',
  total_payable_salary: 'Total Payable Salary',
  total_end_of_service_amount: 'Total End of Service Amount',
  take_action_now: 'Take action now',
  to_show_the_id:
    'To show the ID / Iqama details in the Document tab. Please fill the ID / Iqama Number, Expiry date and attach the ID / Iqama Copy',
  to_show_the_iqama:
    'To show the passport details in the Document tab. Please fill in the Passport Number, Expiry date and attach the passport Copy',
  these_changes_would_appear_in_employees_profile_once_happened:
    'These changes would appear in employees profile once happened',
  totle_balance_tip:
    'Total of carried forward balance from the previous year plus annual entitlement in the current year minus the used days in the current year',
  tell_us_whether_you_are_making_changes:
    'Tell us whether you are making changes with a past, present, or future effective date',
  the_defined_period_is:
    'The defined period is more than the allowed period (180 Days ) according to KSA labor law',
  third_name: 'Third name',
  transportation_allowance: 'Transportation Allowance',
  third_name_in_arabic: 'Third name in Arabic',
  task_type: 'Task Type',
  tasks: 'Tasks',
  types_of_changes: 'Types of changes',
  change_type: 'Change Type',

  task: 'Task',
  type_to_confirm_delete: 'To delete the profile permanently, enter “<b>{{name}}</b>” below.',
  employee_has_some_pending:
    'The employee has some pending requests. Kindly, Re-assign it to to another employee',
  team_members: 'Team members',
  telephone_number: 'Telephone Number',
  the_contract_of_this_employee_is_expired_deduction:
    ' The contract of this employee is expired , you can offboard the employee or add a new contract.',
  total_wage: 'Total Wage',
  team_attendance: 'Team Attendance',
  the_action: 'Action',

  // U
  upload_file: 'Upload File',
  update_probation_period: 'Update Probation Period',
  update_line_manager_of_organization_structure:
    'Update the line manager of the Organization structure',
  update_line_manager_of_subordinates:
    'The employee has team members. Kindly, update the line manager for the following employee',
  update_line_manager_of_organization_tasks:
    'The employee is the manager of the following aspects: parent Department - Department - Business unit - Country - Area - location. Kindly, update the managers',
  update_line_manager_of_workflow:
    'The employee is in the following approval cycles as an approver. Kindly, Re-assign the approver to another employee or you can remove the employee from the approval cycle',
  update_line_manager_of_workflow_desc:
    'Employee has re-assigned tasks. Kindly, Re-assign these tasks to another employee',
  undo_offboarding: 'Undo Offboarding',
  unit_number: 'Unit Number',
  unskip: 'Unskip',
  update: 'Update',
  used_leaves_this_year: 'Used leaves this year',
  update_their_emails_now: 'Update Their Emails Now',
  update_now: 'Update now',
  unpaid_leave_tip: ' A deduction for the employees unpaid leave during the period of the payroll',
  up_to_date_balance_tip:
    ' Balance carried forward from previous year plus cumulative daily outstanding balance from the beginning of the calendar year to todays date',
  update_cost: 'Update Cost',
  update_payment_details: 'Update payment details',
  unsaved_changes: 'there is unsaved change, are you sure you want to leave without saving?',
  under_probation_ends_at: 'Under Probation <br /> Ends at {{endDate}}',
  update_was_not_completed_try_again: 'Update was not completed! try again',
  gosi_un_assigned: 'Un-Assigned',
  update_employee: 'Update Employee',

  // V
  vacation_settlement: 'Vacation settlement',
  view_all: 'View All',
  view_profile: 'View Profile',
  village: 'Village',
  vacations: 'Vacations',
  // W
  occupation_and_wage_details: 'Wage and Occupation details',
  warning_noticies: 'Warning Notices',
  wanna_delete_employee: 'Do you want to delete <b>{{name}}</b> profile permanently?',
  with_employee: 'With Employee',
  working_days_per_week: 'Working days per week',
  working_experience: 'Working Experience',
  warning_date: 'Warning date',
  work_email: 'Email ID',
  work_flow: 'Workflow',
  who_should_we_notify: 'Who should we notify ?',
  work_type: 'Work Type',
  workflow_adjustment: 'Workflow adjustment',
  workflow_adjustment_top_header: 'Tasks and Approval cycles adjustment',
  worker_company_number: 'Worker Company Number',
  team_org_structure_adjustment: 'Team and organization structure adjustment',
  without_emails: 'Without emails',
  without_resumptions: 'Without resumptions',
  without_resumption_notification_msg:
    "There are <b>{{count}}</b> employees who didn't confirm their resumption from their leave",
  withoutGeofencing: 'The employee can punch in/out without consideration of any Geo location.',
  whats_reason_of_this_change: 'What’s the reason of this change?',
  what_kind_of_change_are_you_making: 'What kind of change are you making?',
  wrong_payment_note: 'You have {{count}} Employees with wrong payment details',
  wrong_gosi_note: 'You have {{count}} Employees their Commercial registration not Specified',
  wrong_payment_details_note_2:
    'You can see the list of those employees and update their payment details one by one:',
  wrong_payment_details_note_3:
    'Or Also you can do bulk update for them by download the recent employees file, then update the payment details for those employees and upload it again:',
  work_city: 'Work City',
  workflow_type: 'Workflow Type',
  when_is_employee_last_day_of_work: 'When is employee last day of work?',
  when_to_notify_the_employee: 'When to notify the employee?',
  what_is_the_reason_of_terminating_the_employee:
    'What is the reason of terminating the employee ?',

  // X
  // Y
  you_do_not_have_permission_to_change_bank_authorizer:
    'You do not have permission to change bank authorizer',
  your_balance_details: 'Your balance details',
  yes_complete: 'Yes, complete',
  you_cant_reverse_these_changes_once_they_are_applied:
    'you can`t reverse these changes once they are applied',
  you_donot_have_any_probation_period: 'This Employee has no defined probation Period.',
  you_dont_have_permission_to_change_the_reviewer:
    "You don't have permission to change the reviewer",
  yes_confirm: 'Yes, Confirm',
  you_dont_have_permission_to_clear:
    "You don't have permission to clear assets for this employee. Please contact your administrator",
  you_dont_have_employees: "You don't have employees",
  your_balance: 'Your Balance',

  need_a_help_contact_support: 'Need a Help? Contact Support',
  // Z
  change_deduction_amount: 'Change deduction amount',
}

export default Employee
