import i18n from 'translations/i18n'
import { useState } from 'react'
import { DatePicker, Spacer } from '@jisr-hr/ds-beta'
import { ViewedComponent } from 'components/authorised/Performace'

import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

const DateMonthlyRange = () => {
  const { filter, handleFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const monthlyDate = filter?.date_monthly_period

  return (
    <div className="p-4">
      <FilterBoxHeader
        canShowClear={false}
        handleClearFilter={(): void => undefined}
        handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
        title={i18n.t('date')}
      />
      <Spacer height={8} />
      <ViewedComponent available={!isCollapsed}>
        <div className="w-full">
          <DatePicker
            calenderProps={{
              showMonthYearPicker: true,
              size: 'compact',
              type: 'single',
            }}
            {...(monthlyDate?.date && {
              value: new Date(monthlyDate.date),
            })}
            onChange={(date): void => {
              handleFilter('date_monthly_period', { date })
            }}
            onRemoveDate={(): void => handleFilter('date_monthly_period', undefined)}
          />
        </div>
      </ViewedComponent>
    </div>
  )
}

export default DateMonthlyRange
