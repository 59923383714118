/** **************
 *   Auth Actions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function workflowLoad() {
  const url = `/${API_VERSION_TWO}/workflow_details/`
  const method = 'GET'
  const params = {}

  return {
    type: 'WORKFLOW_LOAD',
    payload: handleAPI(url, params, method),
  }
}
