export const NON_SAUDI_EMPLOYEES_LOAD = 'NON_SAUDI_EMPLOYEES_LOAD'
export const NON_SAUDI_EMPLOYEES_LOAD_PENDING = 'NON_SAUDI_EMPLOYEES_LOAD_PENDING'
export const NON_SAUDI_EMPLOYEES_LOAD_FULFILLED = 'NON_SAUDI_EMPLOYEES_LOAD_FULFILLED'
export const NON_SAUDI_EMPLOYEES_LOAD_REJECTED = 'NON_SAUDI_EMPLOYEES_LOAD_REJECTED'

export const LIST_OF_VALUES_LOAD = 'LIST_OF_VALUES_LOAD'
export const LIST_OF_VALUES_LOAD_PENDING = 'LIST_OF_VALUES_LOAD_PENDING'
export const LIST_OF_VALUES_LOAD_FULFILLED = 'LIST_OF_VALUES_LOAD_FULFILLED'
export const LIST_OF_VALUES_LOAD_REJECTED = 'LIST_OF_VALUES_LOAD_REJECTED'

export const APPROVE_NON_SAUDI = 'APPROVE_NON_SAUDI'
export const APPROVE_NON_SAUDI_PENDING = 'APPROVE_NON_SAUDI_PENDING'
export const APPROVE_NON_SAUDI_FULFILLED = 'APPROVE_NON_SAUDI_FULFILLED'
export const APPROVE_NON_SAUDI_REJECTED = 'APPROVE_NON_SAUDI_REJECTED'

export const RESET_NON_SAUDI_ERROR_MESSAGE = 'RESET_NON_SAUDI_ERROR_MESSAGE'
