import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TOrganizationConfiguration } from '../ApplyingLeaves/reducer'

type TOrganizationConfigurationCustom = {
  [key in keyof TOrganizationConfiguration]: key extends 'value'
    ? string
    : TOrganizationConfiguration[key]
}

type TInitialState = {
  fetching: boolean
  organization_configuration: TOrganizationConfigurationCustom
  annual_leave_entitlement: TOrganizationConfiguration
}

export const initialState: TInitialState = {
  fetching: false,
  organization_configuration: {},
  annual_leave_entitlement: {},
}

export default function reducer(state = initialState, { type, payload }: Action): TInitialState {
  switch (type) {
    case actions.ONBOARDING_COUNTING_DAYS_LOAD_PENDING:
    case actions.ONBOARDING_COUNTING_DAYS_UPDATE_PENDING:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_COUNTING_DAYS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_FULFILLED:
      return {
        ...state,
        annual_leave_entitlement: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.ONBOARDING_COUNTING_DAYS_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_COUNTING_DAYS_LOAD_REJECTED:
    case actions.ONBOARDING_COUNTING_DAYS_UPDATE_REJECTED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
