import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Flex = ({
  alignStart,
  alignEnd,
  bottom,
  children,
  spaceBetween,
  vcenter,
  wrap,
  style,
  hcenter,
  className,
  testId,
  ...rest
}) => (
  <div
    data-testid={testId}
    className={classNames(
      'flex items-center',
      spaceBetween && 'flex--space-between',
      alignStart && 'flex--align-start',
      wrap && 'flex--wrap',
      vcenter && 'flex--vcenter',
      bottom && 'flex--bottom',
      alignEnd && 'flex--align-end',
      hcenter && 'flex--hcenter',
      className,
    )}
    style={style}
    {...rest}
  >
    {children}
  </div>
)

Flex.propTypes = {
  children: PropTypes.node,
  alignStart: PropTypes.bool,
  bottom: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  vcenter: PropTypes.bool,
  wrap: PropTypes.bool,
  alignEnd: PropTypes.bool,
  style: PropTypes.shape(),
  hcenter: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string,
}

export default Flex
