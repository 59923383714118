import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  Reason,
  Workflow,
  AddidtionInfo,
  Tabs,
  Comments,
} from 'components/global/templates/RequestDrawer/components'
import { isEmpty } from 'lodash'
import { useDispatch } from 'utils/hooks'
import i18n from 'translations/i18n'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import FlightTicketDetails from '../../components/FlightTicketDetails'
import LeaveDuration from '../../components/LeaveDuration'
import ExitReEntryVisaDetails from '../../components/ExitReEntryVisaDetails'
import EmployeeAssetsAllocated from '../../components/EmployeeAssetsAllocated'
import RecentLoans from '../../components/RecentLoans'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'
import { useRequest } from '../../RequestProvider'

const LeaveRequest = () => {
  const dispatch = useDispatch()
  const { loading, request, reloadRequest } = useRequestDetail()
  const { setRequestType, setEnableHittingRequestListener } = useRequest()

  const [activeTab, setActiveTab] = React.useState('request_details')

  const isAnnual = request.leave_type?.category === 'annual'
  const isHalfDayLeave = request?.half_day_leave
  const isHasAdditional =
    request.is_require_vacation_salary || request.require_ticket || request.is_re_entry_exit

  const isResumption = ['LeaveResumptionCancellationRequest', 'LeaveResumptionRequest'].includes(
    request.request_type,
  )

  const onClickLinkedRequest = () => {
    setEnableHittingRequestListener(false)
    setRequestType('FlightTicketRequest')
    // ? setTimeout here to execute the dispatch after the execution of resetRequestDetail on the useRequestDetail listener
    setTimeout(() => {
      dispatch(
        requestDetailsLoad(request?.employee?.id, {
          sequential_id: request?.metadata?.flight_ticket_request_sequential_id,
        }),
      ).finally(() => setEnableHittingRequestListener(true))
    }, 1)
  }

  const ticketInfo = request.ticket_info

  React.useEffect(() => {
    return () => setEnableHittingRequestListener(true)
  })

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              ...request,
              type_i18n: request?.request_name_i18n,
              linkedWith: i18n.t('label.flight_ticket_request'),
              onClickLinkedRequest,
            }}
            showColumns={[
              isHasAdditional && isAnnual && 'additional_needs',
              request?.metadata?.flight_ticket_request_sequential_id && 'linked_with_request',
            ].filter(Boolean)}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          <LeaveDuration
            applicable_days={request.applied_days || 0}
            unscheduled_dates={request.unscheduled_dates}
            from={request.from}
            to={request.to}
            balance={request.available_balance}
            showDetails
            isAnnual={isAnnual}
            returnDate={isResumption && request.return_date}
            deducted_balance={request?.deducted_balance}
            employeeId={request?.employee?.id}
            isHalfDayLeave={isHalfDayLeave}
            balanceVals={{
              applied_days: request?.applied_days,
              pending_balance: request?.pending_balance,
              requested_days: request?.requested_days,
            }}
          />

          {ticketInfo && (
            <FlightTicketDetails
              details={{
                ...request,
                leave_date: ticketInfo?.leaving_date,
                return_date: ticketInfo?.returning_date,
                family_details: ticketInfo?.family_details,
              }}
            />
          )}

          {request.is_re_entry_exit && (
            <ExitReEntryVisaDetails details={request?.exit_reentry_info} />
          )}

          {request.assets?.length > 0 && (
            <EmployeeAssetsAllocated
              details={request?.assets}
              id={request?.employee?.id}
              isCanShowAssets
            />
          )}

          <RecentLoans
            canViewLoan
            details={{
              ...request,
              loans: request?.active_loans,
            }}
          />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <AddidtionInfo teamOnLeave={request?.team_on_leave} />
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          {!isEmpty(request.approvals) && <Workflow approvals={request.approvals} />}
        </>
      )}
    </Flex>
  )
}

export default LeaveRequest
