import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TTNationalities = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
  created_at: string
  updated_at: string
}

type TInitialState = {
  fetching: boolean
  nationalities: TTNationalities[]
}

export const initialState: TInitialState = {
  fetching: false,
  nationalities: [],
}

export default function reducer(state = initialState, action: Action): TInitialState {
  switch (action.type) {
    case actions.LOAD_ONBOARDING_NATIONALITIES_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LOAD_ONBOARDING_NATIONALITIES_FULFILLED:
      return {
        ...state,
        nationalities: action.payload.data.data?.nationalities,
        fetching: false,
      }

    case actions.LOAD_ONBOARDING_NATIONALITIES_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
