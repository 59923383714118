import React from 'react'
import { useSelector } from 'react-redux'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  Reason,
  Workflow,
  Tabs,
  Comments,
  Policy,
} from 'components/global/templates/RequestDrawer/components'
import I18n from 'translations/i18n'
import LeaveDuration from '../../components/LeaveDuration'
import FlightTicketDetails from '../../components/FlightTicketDetails'
import ExitReEntryVisaDetails from '../../components/ExitReEntryVisaDetails'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const BusinessTrip = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const { currency_type_i18n } = useSelector(
    ({ organizationProfile }) => organizationProfile.organization_currency,
  )

  const [activeTab, setActiveTab] = React.useState('request_details')
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              ...request,
              type_i18n: request?.request_type_i18n,
              currency: currency_type_i18n,
            }}
            showColumns={['destination', 'total_amount', 'additional_services']}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          <LeaveDuration
            title={I18n.t('business_trip_duration')}
            applicable_days={request.number_of_days}
            from={request.leave_date}
            to={request.return_date}
            showDetails
          />

          {request.require_ticket && (
            <FlightTicketDetails
              details={{
                ...request,
                leave_date: request?.ticket_info?.leaving_date,
                return_date: request?.ticket_info?.returning_date,
                family_details: request?.ticket_info?.family_details,
              }}
            />
          )}

          {request.is_re_entry_exit && (
            <ExitReEntryVisaDetails details={request.exit_reentry_info} />
          )}

          <Policy details={request} />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          <Workflow approvals={request?.approvals} />
        </>
      )}
    </Flex>
  )
}

export default BusinessTrip
