import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'utils/hooks'
import { Button, Icon, Spacer, Typography } from '@jisr-hr/ds'
import { listCountries } from 'redux/setting/countries/actionCreators'
import {
  useCreateOnboardingEmp,
  useValidateOnboardingEmpStep,
} from 'apis/PeopleManagement/OnboardingEmployee/data_completion/useOnboardingEmployeeForm'
import { OnboardingSubmittingForm } from 'apis/PeopleManagement/OnboardingEmployee/data_completion/useOnboardingEmployeeForm.type'
import { nationalitiesLoad } from 'redux/actions/staticRecordActions'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import { documentRequestsUpdate } from 'redux/actions/employeeFileActions'
import { updateInfoChangeRequest } from 'redux/employees/editProfile/actionCreator'
import { OnboardingOverviewContractRes } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview.type'
import { useGetOnboardingOverview } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview'
import { approvalsLoad } from 'redux/requests/newRequestsApproval/actionCreators'
import JisrLoader from 'components/global/JisrLoader'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { OnboardingStepsEnum, stepNames } from '../../constants'
import { PersonalInfo } from '../../components/forms/PersonalInfo'
import { PassportInfo } from '../../components/forms/PassportInfo'
import { IdInfo } from '../../components/forms/IdInfo'
import { SaudiAddress } from '../../components/forms/SaudiAddress'
import { HomeCountyAddress } from '../../components/forms/HomeCountryAddress'
import { EmergencyContact } from '../../components/forms/EmergencyContanct'
import { OnboardingFormSuccess } from '../../components/OnboardingFormSuccess'
import { OnboardingFormHeader } from '../../components/OnboardingFormHeader'
import { OnboardingClarificationCard } from '../../components/OnboardingClarificationCard'
import { ActionRequiredAccordion } from '../ActionRequiredAccordion'

export const OnboardingForm = ({
  clarification,
  activeForm,
  requestId,
}: {
  clarification?: boolean
  activeForm?: number
  requestId?: number
}) => {
  const [activeStep, setActiveStep] = useState(activeForm || OnboardingStepsEnum.PERSONAL_INFO)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isCompleteEditing, setIsCompleteEditing] = useState(false)
  const dispatch = useDispatch()

  const { data } = useGetOnboardingOverview()
  const overviewDetails: OnboardingOverviewContractRes | undefined = data?.data?.data

  const { request } = useSelector(({ requestDetails }) => requestDetails)
  const { employee } = useSelector(({ auth }) => auth)
  const empId = Number(employee?.id)

  const { mutate: submitOnboardingEmp } = useCreateOnboardingEmp()
  const { mutate: validateOnboardingEmpStep, isLoading } = useValidateOnboardingEmpStep()

  const hasCompletedLastStep = activeStep > OnboardingStepsEnum.EMERGENCY_CONTACT
  const isLastStep = activeStep === OnboardingStepsEnum.EMERGENCY_CONTACT

  const generateInitialValues = () => {
    const initialValues = {
      [String(request?.metadata?.info_change_request_type)]: request?.metadata?.changeset,
    }

    if (request?.category) {
      const category = String(request.category)
      const categoryMetadata = { ...request.metadata }

      if (request.attachments) {
        const attachmentData = {
          data: request.attachments[0]?.url,
          filename: request.attachments[0]?.name,
          id: request.attachments[0]?.id,
        }

        if (category === 'address') {
          categoryMetadata.proof_attachment = [attachmentData]
        } else {
          const attachmentKey = `${category}_attachments`
          categoryMetadata[attachmentKey] = [attachmentData]
        }
      }

      initialValues[category] = categoryMetadata
    }

    return initialValues
  }

  const initialValues = generateInitialValues()

  const onSubmit = (formDetails: OnboardingSubmittingForm) => {
    if (clarification) {
      if (formDetails?.id_iqama || formDetails?.passport || formDetails?.address) {
        const handleCategory = (): string => {
          if (formDetails?.id_iqama) return 'id_iqama'
          if (formDetails?.passport) return 'passport'
          if (formDetails?.address) return 'address'
          return ''
        }
        const handleAttachment = (): Array<{ data: string; filename: string }> | null => {
          if (formDetails?.id_iqama) return formDetails.id_iqama.id_iqama_attachments
          if (formDetails?.passport) return formDetails.passport.passport_attachments
          if (formDetails?.address) return formDetails.address.proof_attachment
          return null
        }
        const handleDocValues = (): Record<string, string> => {
          const category = handleCategory()
          if (category) {
            return {
              category,
              ...formDetails[category],
              attachments: handleAttachment(),
            }
          }
          return {}
        }

        validateOnboardingEmpStep(
          {
            id: empId,
            data: {
              onboarding_employee: {
                passport: formDetails.passport,
                id_iqama: formDetails.id_iqama,
                address: formDetails.address,
              },
              step_name: handleCategory(),
            },
          },
          {
            onSuccess: () => {
              dispatch(documentRequestsUpdate(empId, Number(request?.id), handleDocValues())).then(
                () => {
                  setIsCompleteEditing(true)
                  dispatch(
                    approvalsLoad({
                      type: 'employee_requests',
                      filter: { status: ['Pending for Correction'] },
                    }),
                  )
                },
              )
            },
          },
        )
      } else {
        validateOnboardingEmpStep(
          {
            id: empId,
            data: {
              onboarding_employee: {
                personal_info: formDetails.personal_info,
                address_in_home: formDetails.address_in_home,
                emergency_contact: formDetails.emergency_contact,
              },
              step_name: String(request?.metadata?.info_change_request_type),
            },
          },
          {
            onSuccess: () => {
              dispatch(
                updateInfoChangeRequest(empId, Number(request?.id), {
                  changeset: {
                    ...(formDetails?.personal_info ? formDetails.personal_info : {}),
                    ...(formDetails?.emergency_contact ? formDetails.emergency_contact : {}),
                    ...(formDetails?.address_in_home ? formDetails.address_in_home : {}),
                  },
                  info_change_request_type: request?.metadata?.info_change_request_type,
                }),
              ).then(() => {
                setIsCompleteEditing(true)
                dispatch(
                  approvalsLoad({
                    type: 'employee_requests',
                    filter: { status: ['Pending for Correction'] },
                  }),
                )
              })
            },
          },
        )
      }
    } else {
      const currentStepName = stepNames?.[activeStep]
      const formStepDetails = formDetails?.[currentStepName]
      if (formStepDetails) {
        validateOnboardingEmpStep(
          {
            id: empId,
            data: {
              onboarding_employee: {
                ...(currentStepName === 'personal_info' && {
                  personal_info: formDetails.personal_info,
                }),
                ...(currentStepName === 'passport' && {
                  passport: formDetails.passport,
                }),
                ...(currentStepName === 'id_iqama' && {
                  id_iqama: formDetails.id_iqama,
                }),
                ...(currentStepName === 'address' && {
                  address: formDetails.address,
                }),
                ...(currentStepName === 'address_in_home' && {
                  address_in_home: formDetails.address_in_home,
                }),
                ...(currentStepName === 'emergency_contact' && {
                  emergency_contact: formDetails.emergency_contact,
                }),
              },
              step_name: stepNames?.[activeStep],
            },
          },
          {
            onSuccess: () => {
              setActiveStep((currentStep) => currentStep + 1)
              if (isLastStep) {
                submitOnboardingEmp(
                  {
                    id: Number(employee?.id),
                    data: {
                      onboarding_employee: formDetails,
                    },
                  },
                  {
                    onSuccess: () => {
                      setIsSuccess(true)
                    },
                    onError: () => {
                      setActiveStep((currentStep) => currentStep - 1)
                    },
                  },
                )
              }
            },
          },
        )
        return
      }
      if (isLastStep) {
        submitOnboardingEmp(
          {
            id: Number(employee?.id),
            data: {
              onboarding_employee: formDetails,
            },
          },
          {
            onSuccess: () => {
              setIsSuccess(true)
            },
            onError: () => {
              setActiveStep((currentStep) => currentStep - 1)
            },
          },
        )
      }
      setActiveStep((currentStep) => currentStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((currentStep) => currentStep - 1)
  }

  useEffect(() => {
    dispatch(listCountries({ template: 'public' }))
    dispatch(nationalitiesLoad())
  }, [])

  useEffect(() => {
    if (clarification) {
      dispatch(
        requestDetailsLoad(Number(employee?.id), {
          request_id: requestId,
        }),
      )
    }
  }, [clarification])

  if (isCompleteEditing) {
    return <ActionRequiredAccordion overviewDetails={overviewDetails} />
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          {!hasCompletedLastStep && !clarification && (
            <OnboardingFormHeader
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleBack={handleBack}
            />
          )}
          {clarification && (
            <div className="flex flex-row mt-4 w-full justify-between items-center">
              <div className="flex flex-row gap-2 items-center">
                <Icon
                  name={isRtl ? 'arrow-right' : 'arrow-left'}
                  onClick={() => setIsCompleteEditing(true)}
                  className="cursor-pointer"
                />
                <Typography
                  text={i18n.t('label.edit_info')}
                  variant="title-1"
                />
              </div>
              <Button
                size="medium"
                type="submit"
                label={i18n.t('label.submit')}
                disabled={pristine}
                trailingIcon="check-circle-filled"
                onClick={() =>
                  mixPanelAnalytics({
                    event_name: 'Employee Onboarding Form Editied',
                    properties: {
                      flow_type: `${values}`,
                    },
                  })
                }
              />
            </div>
          )}
          <Spacer height={clarification ? 8 : 24} />

          <div className="flex flex-row mt-8 w-full gap-12">
            {clarification ? <OnboardingClarificationCard /> : null}
            <div className={`flex-1 mx-auto ${clarification ? '' : 'max-w-[800px]'} `}>
              {isLoading && <JisrLoader />}
              {activeStep === OnboardingStepsEnum.PERSONAL_INFO && <PersonalInfo />}
              {activeStep === OnboardingStepsEnum.PASSPORT_INFO && <PassportInfo />}
              {activeStep === OnboardingStepsEnum.ID_INFO && <IdInfo />}
              {activeStep === OnboardingStepsEnum.SAUDI_ADDRESS && <SaudiAddress />}
              {activeStep === OnboardingStepsEnum.HOME_COUNTRY_ADDRESS && <HomeCountyAddress />}
              {activeStep === OnboardingStepsEnum.EMERGENCY_CONTACT && <EmergencyContact />}
              {isSuccess ? <OnboardingFormSuccess /> : null}
            </div>
          </div>
        </form>
      )}
    />
  )
}
