import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export const initialState = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_EMPLOYEES_INVITE_LOAD_PENDING:
    case actions.ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_EMPLOYEES_INVITE_LOAD_FULFILLED:
    case actions.ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_EMPLOYEES_INVITE_LOAD_REJECTED:
    case actions.ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
