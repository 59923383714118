import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  report: { header: [], records: [] },
  leaveReport: { header: [], records: [] },
  errMsg: null,
  succMsg: null,
  fetching: false,
  eligible_users: [],
}

let changes = null
export default function reportReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'REPORT_LOAD_PENDING':
    case 'LEAVE_REPORT_LOAD_PENDING':
    case 'PAYROLL_GROUP_REPORT_LOAD_PENDING':
    case 'CHART_REPORT_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'REPORT_LOAD_REJECTED':
    case 'LEAVE_REPORT_LOAD_REJECTED':
    case 'PAYROLL_GROUP_REPORT_LOAD_REJECTED':
    case 'CHART_REPORT_LOAD_REJECTED':
    case 'REPORT_ELIGIBLE_USERS_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'REPORT_LOAD_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        report: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LEAVE_REPORT_LOAD_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        leaveReport: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PAYROLL_GROUP_REPORT_LOAD_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        report: action.payload,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'CHART_REPORT_LOAD_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        report: action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'REPORT_ELIGIBLE_USERS_LOAD_FULFILLED':
      changes = {
        eligible_users: action.payload.data.data.users,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'REPORT_RESET':
      changes = {
        report: { header: [], records: [] },
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
