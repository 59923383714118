export const TAWUNIYA_CONNECT_POLICY = 'TAWUNIYA_CONNECT_POLICY'
export const TAWUNIYA_CONNECT_POLICY_PENDING = 'TAWUNIYA_CONNECT_POLICY_PENDING'
export const TAWUNIYA_CONNECT_POLICY_FULFILLED = 'TAWUNIYA_CONNECT_POLICY_FULFILLED'
export const TAWUNIYA_CONNECT_POLICY_REJECTED = 'TAWUNIYA_CONNECT_POLICY_REJECTED'

export const GET_TAWUNIYA_POLICY_ACCOUNTS = 'GET_TAWUNIYA_POLICY_ACCOUNTS'
export const GET_TAWUNIYA_POLICY_ACCOUNTS_PENDING = 'GET_TAWUNIYA_POLICY_ACCOUNTS_PENDING'
export const GET_TAWUNIYA_POLICY_ACCOUNTS_FULFILLED = 'GET_TAWUNIYA_POLICY_ACCOUNTS_FULFILLED'
export const GET_TAWUNIYA_POLICY_ACCOUNTS_REJECTED = 'GET_TAWUNIYA_POLICY_ACCOUNTS_REJECTED'

export const TAWUNIYA_DISCONNECT_POLICY = 'TAWUNIYA_DISCONNECT_POLICY'
export const TAWUNIYA_DISCONNECT_POLICY_PENDING = 'TAWUNIYA_DISCONNECT_POLICY_PENDING'
export const TAWUNIYA_DISCONNECT_POLICY_FULFILLED = 'TAWUNIYA_DISCONNECT_POLICY_FULFILLED'
export const TAWUNIYA_DISCONNECT_POLICY_REJECTED = 'TAWUNIYA_DISCONNECT_POLICY_REJECTED'

export const TAWUNIYA_DELETE_POLICY = 'TAWUNIYA_DELETE_POLICY'
export const TAWUNIYA_DELETE_POLICY_PENDING = 'TAWUNIYA_DELETE_POLICY_PENDING'
export const TAWUNIYA_DELETE_POLICY_FULFILLED = 'TAWUNIYA_DELETE_POLICY_FULFILLED'
export const TAWUNIYA_DELETE_POLICY_REJECTED = 'TAWUNIYA_DELETE_POLICY_REJECTED'

export const TAWUINYA_RESET_FORM_DATA = 'TAWUINYA_RESET_FORM_DATA'

export const TAWUNIYA_LEGAL_NOTICE = 'TAWUNIYA_LEGAL_NOTICE'
export const TAWUNIYA_LEGAL_NOTICE_PENDING = 'TAWUNIYA_LEGAL_NOTICE_PENDING'
export const TAWUNIYA_LEGAL_NOTICE_FULFILLED = 'TAWUNIYA_LEGAL_NOTICE_FULFILLED'
export const TAWUNIYA_LEGAL_NOTICE_REJECTED = 'TAWUNIYA_LEGAL_NOTICE_REJECTED'
