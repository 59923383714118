import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { salarypackagesSelector } from 'redux/payrollOnboarding/SalarySetup/selectors'
import { salariesPackagesLoad } from 'redux/payrollOnboarding/SalarySetup/actionsCreators'
import SummaryCard from '../components/SummaryCard'
import ViewMoreModal from './ViewMoreModal'
import { THandleEditStep } from '../SummaryReview'

const SalariesPackagesSummary = ({ handleEditStep }: THandleEditStep): JSX.Element => {
  const dispatch = useDispatch()
  const { statistics } = useSelector(salarypackagesSelector)
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency.currency_type_i18n,
  )
  const [open, setOpen] = useState(false)

  const displayNum = statistics?.total_salaries_packages
    ?.toFixed(2)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  useEffect(() => {
    dispatch(salariesPackagesLoad({ rpp: 9999999 }))
  }, [])
  return (
    <>
      <SummaryCard
        title="salaries_packages"
        contentItems={[
          {
            label: 'total_salaries',
            value: statistics?.total_salaries_packages ? `${currency} ${displayNum || 0}` : '',
          },
        ]}
        onEditClick={(): void => handleEditStep(3)}
        onViewClick={(): void => setOpen(true)}
      />

      <ViewMoreModal
        isOpen={open}
        onClose={(): void => setOpen(false)}
      />
    </>
  )
}

export default SalariesPackagesSummary
