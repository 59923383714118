import { PermissionDetails } from 'redux/authUser/types'

export const showAnalysis = (permission: PermissionDetails): number => {
  const {
    view_attendance,
    view_payroll_table,
    view_personal_and_organization_information,
    view_assets,
    view_reports_and_statistics,
  } = permission

  if (
    view_attendance?.some((it) => it === 'all') ||
    view_payroll_table ||
    view_personal_and_organization_information?.some((it) => it === 'all') ||
    view_assets?.some((it) => it === 'all') ||
    view_reports_and_statistics?.some((it) => it === 'all')
  ) {
    return 4
  }
  return 0
}

// ? Finance Payroll
export const showFinancePayroll = (permission: PermissionDetails): boolean => {
  const { view_payroll_table } = permission
  if (view_payroll_table) {
    return true
  }
  return false
}

// ? Workforce
export const showWorkforce = (permission: PermissionDetails): boolean => {
  const { view_personal_and_organization_information } = permission
  if (view_personal_and_organization_information?.some((it) => it === 'all')) {
    return true
  }
  return false
}

// ? Attendance leaves
export const showAttendanceLeaves = (permission: PermissionDetails): boolean => {
  const { view_attendance } = permission
  if (view_attendance?.some((it) => it === 'all')) {
    return true
  }
  return false
}

// ? Assets
export const showAssets = (permission: PermissionDetails): boolean => {
  const { view_assets } = permission
  if (view_assets?.some((it) => it === 'all')) {
    return true
  }
  return false
}

// showATSStatistics
export const showATSStatistics = (
  permission: PermissionDetails,
  subscribedFeatures: string[],
): boolean => {
  if (
    permission?.view_reports_and_statistics?.some((it) => it === 'all') &&
    subscribedFeatures.includes('applicant_tracking_system') &&
    subscribedFeatures.includes('analytics')
  ) {
    return true
  }
  return false
}
