import i18n from 'translations/i18n'
import { serverEnvironment } from 'env'

type TItem = {
  text: string
  leadingIcon: string
  value: string
  nextDivider?: boolean
}

export const listItems = (isOnboarding: boolean | undefined): TItem[] => {
  const dropdownItems = [
    {
      text: i18n.t('change_language'),
      leadingIcon: 'translate-01',
      value: 'language',
    },
    {
      text: i18n.t('change_password'),
      leadingIcon: 'passcode-lock',
      value: 'change_password',
    },
    {
      text: i18n.t('invite_admins'),
      leadingIcon: 'user-plus-02',
      value: 'invite_admins',
      nextDivider: true,
    },
    {
      text: i18n.t('log_out'),
      leadingIcon: 'log-out-01',
      value: 'logout',
    },
  ]

  if (isOnboarding) return dropdownItems
  return [
    {
      text: i18n.t('view_profile'),
      leadingIcon: 'user-01',
      value: 'view_profile',
      nextDivider: true,
    },
    ...dropdownItems.filter((i) => i.value !== 'invite_admins'),
  ]
}

type MenuListType = {
  label?: string
  path?: string
  testId?: string
  icon?: string
  gruoTitle?: string
}[]

const path = '/setting_v2'
export const menuList = ({
  isPerformanceSupport,
  isGlSupported,
  canManageBulkImportSheets,
  manage_account_information,
  manage_organization_settings,
  manage_shifts_and_working_hours,
  leave_settings,
  manage_performance,
  payroll_settings,
  manage_request_workflow,
  manage_roles_and_permission,
  manage_security_settings,
  manage_integrations,
  manage_billing,
  manage_company_documents,
  manage_notification,
  employee_profile_settings,
  manage_attendance_policies,
  manage_reporting_methods,
  manage_letter_templates,
  allowOpenApiGeneration,
  isSuperAdmin,
  showOpenApi,
  showWebhook,
  is_jisr_pay_enabled,
  isPerformanceEvaluation,
  view_violation_rules,
  isMolEnabled,
  manage_flight_bookings,
  make_payments,
  isFlightBookingEnabled,
  exemption_from_deduction,
  working_hours,
  configure_payroll,
  configure_manual_adjustment,
  roles_list,
}: any): MenuListType =>
  [
    (manage_account_information || manage_company_documents || manage_notification) && {
      group: {
        title: i18n.t('account'),
        menu: [
          manage_account_information && {
            label: i18n.t('general_info'),
            path: `${path}/account/general`,
            testId: 'account_general_info',
            icon: 'annotation-info',
            event_name: 'GeneralInfo_Viewed',
          },
          manage_company_documents && {
            label: i18n.t('settings_company_documents'),
            path: `${path}/account/company_documents`,
            testId: 'account_company_documents',
            icon: 'folder',
            event_name: 'CompanyDocuments_Viewed',
          },
          manage_notification && {
            label: i18n.t('notifications'),
            path: `${path}/account/notification`,
            testId: 'account_notification',
            icon: 'notification-message',
            event_name: 'NotificationsSettings_Viewed',
          },
        ].filter(Boolean),
      },
    },
    // ORGANIZATION
    (manage_organization_settings ||
      employee_profile_settings ||
      payroll_settings ||
      canManageBulkImportSheets ||
      view_violation_rules) && {
      group: {
        title: i18n.t('ORGANIZATION'),
        menu: [
          manage_organization_settings && {
            label: i18n.t('org__structure'),
            path: `${path}/organization/org_structure`,
            testId: 'organization_org_structure',
            icon: 'dataflow-01',
            event_name: 'OrgStructureSettings_Viewed',
          },
          (manage_organization_settings ||
            (Array.isArray(roles_list) && roles_list.includes('Group Manager'))) && {
            label: i18n.t('module.groups'),
            path: `${path}/organization/groups`,
            testId: 'groups_settings',
            icon: 'users-01',
            // event_name: 'OrgStructureSettings_Viewed',
            badge: {
              size: 'small',
              label: i18n.t('label.new'),
              leadingIcon: 'star-06',
              color: 'raspberry',
              className: 'shrink-0',
            },
          },
          isMolEnabled && {
            label: i18n.t('create_label.sidemenuvandp'),
            path: `${path}/organization/violation_rules`,
            testId: 'violations_rules',
            icon: 'dataflow-04',
          },
          (employee_profile_settings || manage_organization_settings) && {
            label: i18n.t('employee_profile'),
            path: `${path}/organization/employee_profile`,
            testId: 'organization_employee_profile',
            icon: 'user-square',
          },
          canManageBulkImportSheets && {
            label: i18n.t('bulk_import_sheets'),
            path: `${path}/organization/bulk_import_sheets`,
            testId: 'bulk_import_sheets',
            icon: 'switch-horizontal-02',
            event_name: 'ImportExportList_Viewed',
          },
          employee_profile_settings && {
            label: i18n.t('asset_types'),
            path: `${path}/organization/assets`,
            testId: 'organization_assets',
            icon: 'laptop-01',
          },
        ].filter(Boolean),
      },
    },
    // LEAVES_HOLIDAYS
    leave_settings && {
      group: {
        title: i18n.t('LEAVES_HOLIDAYS'),
        menu: [
          {
            label: i18n.t('settings_leave_types'),
            path: `${path}/leave_management/leave_types`,
            testId: 'leave_management_leave_types',
            icon: 'calendar',
          },
          {
            label: i18n.t('public_holidays'),
            path: `${path}/leave_management/holidays`,
            testId: 'leave_management_holidays',
            icon: 'sun-setting-01',
          },
        ],
      },
    },

    // attendance
    (manage_shifts_and_working_hours || manage_attendance_policies || manage_reporting_methods) && {
      group: {
        title: i18n.t('attendance'),
        menu: [
          manage_shifts_and_working_hours && {
            label: i18n.t('shifts__working_hours'),
            path: `${path}/attendance/shifts_working_hours`,
            testId: 'attendance_shifts_working_hours',
            icon: 'shift-cards',
          },
          manage_attendance_policies && {
            label: i18n.t('excuse_and_overtime'),
            path: `${path}/attendance/policies`,
            testId: 'attendance_policies',
            icon: 'clock-plus',
          },
          manage_reporting_methods && {
            label: i18n.t('label.attendancereporting_title'),
            path: `${path}/attendance/reporting_methods`,
            testId: 'attendance_reporting_methods',
            icon: 'bar-chart-01',
          },
        ].filter(Boolean),
      },
    },
    // performance
    (isPerformanceSupport || isPerformanceEvaluation) &&
      manage_performance && {
        group: {
          title: i18n.t('performance'),
          menu: [
            isPerformanceSupport && {
              label: i18n.t('rating_methods'),
              path: `${path}/performance_management`,
              icon: 'star-01',
            },
            isPerformanceEvaluation && {
              label: i18n.t('label.roles_and_reviewers'),
              path: `${path}/roles_and_reviewers`,
              icon: 'users-check',
              testId: 'performance_roles_and_reviewers',
            },
            isPerformanceEvaluation && {
              label: i18n.t('rating_methods'),
              path: `${path}/performance_rating_management`,
              icon: 'star-01',
              testId: 'performance_rating_management',
            },
          ].filter(Boolean),
        },
      },
    // JisrWallet
    (isFlightBookingEnabled || is_jisr_pay_enabled) &&
      (manage_flight_bookings || make_payments) && {
        group: {
          title: i18n.t('label.jisr_wallet_'),
          menu: [
            {
              label: i18n.t('label.jisr_wallet_'),
              path: `${path}/jisr_wallet/wallet`,
              testId: 'jisr-wallet',
              icon: 'wallet-02',
              badge: {
                label: i18n.t('label.new'),
                className: 'shrink-0',
                color: 'raspberry',
              },
            },
            {
              label: i18n.t('label.wallet_history'),
              path: `${path}/jisr_wallet/history`,
              testId: 'wallet-history',
              icon: 'calendar',
            },
          ].filter(Boolean),
        },
      },
    // payroll
    (payroll_settings || leave_settings || manage_organization_settings) && {
      group: {
        title: i18n.t('payroll'),
        menu: [
          configure_payroll &&
            is_jisr_pay_enabled && {
              label: i18n.t('label.online_payment'),
              path: `${path}/payroll/online_payment`,
              testId: 'online_payment',
              icon: 'coins-hand',
            },
          payroll_settings && {
            label: i18n.t('label.payrolls_settings'),
            path: `${path}/payroll/configurations`,
            testId: 'payroll_settings',
            icon: 'coins-01',
          },
          configure_payroll &&
            !is_jisr_pay_enabled && {
              label: i18n.t('label.online_payment'),
              path: `${path}/payroll/process_payment`,
              testId: 'payroll_process_payment',
              icon: 'coins-hand',
            },
          (configure_payroll || configure_manual_adjustment) && {
            label: i18n.t('payables'),
            path: `${path}/payroll/payables`,
            testId: 'payroll_payables',
            icon: 'coins-stacked-01',
          },
          (exemption_from_deduction || working_hours) && {
            label: i18n.t('attendance_reflection'),
            path: `${path}/payroll/attendance_integration`,
            testId: 'payroll_attendance_integration',
            icon: 'fingerprint-03',
          },
        ].filter(Boolean),
      },
    },
    // WORKFLOW__APPROVALS
    (manage_request_workflow ||
      manage_letter_templates ||
      payroll_settings ||
      manage_organization_settings) && {
      group: {
        title: i18n.t('WORKFLOW__APPROVALS'),
        menu: [
          manage_request_workflow && {
            label: i18n.t('requests'),
            path: `${path}/workflow/requests`,
            testId: 'workflow_requests',
            icon: 'file-06',
          },
          (manage_request_workflow || payroll_settings || manage_organization_settings) && {
            label: i18n.t('tasks'),
            path: `${path}/workflow/tasks`,
            testId: 'workflow_tasks',
            icon: 'list',
          },
          manage_letter_templates && {
            label: i18n.t('letters'),
            path: `${path}/workflow/letters/letter_templates`,
            testId: 'workflow_letters',
            icon: 'mail-02',
          },
        ].filter(Boolean),
      },
    },
    (manage_security_settings || manage_roles_and_permission) && {
      group: {
        title: i18n.t('security'),
        menu: [
          manage_roles_and_permission && {
            label: i18n.t('roles'),
            path: `${path}/security/roles`,
            testId: 'security_roles',
            icon: 'users-01',
          },
          manage_roles_and_permission && {
            label: i18n.t('user_permissions'),
            path: `${path}/security/user_permissions`,
            testId: 'security_user_permission',
            icon: 'key-02',
          },
          (manage_security_settings || manage_roles_and_permission) && {
            label: i18n.t('passwords__login'),
            path: `${path}/security/login_settings`,
            testId: 'security_login_settings',
            icon: 'lock-01',
          },
        ].filter(Boolean),
      },
    },
    // INTEGRATIONS
    ['aws', 'staging', 'oci', 'dev', 'e2e'].includes(serverEnvironment) &&
      ((isSuperAdmin && allowOpenApiGeneration && (showWebhook || showOpenApi)) || isGlSupported) &&
      manage_integrations && {
        group: {
          title: i18n.t('INTEGRATIONS'),
          menu: [
            isGlSupported && {
              label: i18n.t('settings_file_based'),
              path: `${path}/integration/file_based`,
              testId: 'integration_file_based',
              icon: 'intersect-circle',
            },
            isSuperAdmin &&
              showOpenApi &&
              allowOpenApiGeneration && {
                label: i18n.t('api_keys'),
                path: `${path}/integration/api_keys`,
                testId: 'integration_api_keys',
                icon: 'passcode-lock',
                event_name: 'click_settings_integration_api_keys_marketplace_web',
              },
            isSuperAdmin &&
              allowOpenApiGeneration &&
              showWebhook && {
                label: i18n.t('webhooks'),
                path: `${path}/integration/webhooks`,
                testId: 'integration_webhooks',
                icon: 'server-06',
                event_name: 'click_settings_integration_webhooks_marketplace_web',
              },
          ].filter(Boolean),
        },
      },
    // billing
    manage_billing && {
      group: {
        title: i18n.t('billing'),
        hideDivider: true,
        menu: [
          {
            label: i18n.t('create_label.subscriptions_and_billing'),
            path: `${path}/billing/payment_info`,
            testId: 'billing_payment_info',
            icon: 'receipt',
          },
          {
            label: i18n.t('billing_history'),
            path: `${path}/billing/billing_history`,
            testId: 'billing_billing_history',
            icon: 'clock-refresh',
          },
        ],
      },
    },
  ].filter(Boolean) as MenuListType
