// import { Toastr } from 'components/global/Toastr';
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  loading: false,
  verification_modes: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_DEVICES_MANAGEMENT_LOAD_PENDING:
    case actions.UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.EMPLOYEE_DEVICES_MANAGEMENT_LOAD_FULFILLED:
      return {
        ...state,
        verification_modes: action.payload.data.data,
        loading: false,
      }

    case actions.UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.EMPLOYEE_DEVICES_MANAGEMENT_LOAD_REJECTED:
    case actions.UPDATE_EMPLOYEE_DEVICES_MANAGEMENT_REJECTED:
      // eslint-disable-next-line no-case-declarations
      const msg = action.payload.response ? action.payload.response.data.error : action.payload
      Toastr.error(msg)
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
