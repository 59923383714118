const DEFAULT_STATE = {
  fetching: false,
  loading: false,
  leave_time_lines_record: [],
  leave_time_lines_days: [],
  employee_leave_details: {},
  fetchingDetails: false,
  employee_leave_days: [],
}

let changes = null
export default function timeAndAttendanceReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'LEAVE_TIME_LINES_RESET':
      changes = {
        leave_time_lines_record: [],
        fetching: true,
      }
      return { ...state, ...changes }

    case 'LEAVE_TIME_LINES_LOAD_PENDING':
    case 'EMPLOYEE_LEAVE_DAYS_LOAD_PENDING':
      changes = {
        fetching: true,
        loading: !(state.employee_leave_days.length > 0),
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVE_DETAILS_LOAD_FULFILLED':
      changes = {
        employee_leave_details: action.payload.data.data.employee_leave_details,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVE_DAYS_LOAD_FULFILLED':
      changes = {
        employee_leave_days: action.payload.data.data.employee_leave_days,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LEAVE_TIME_LINES_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        leave_time_lines_record: [
          ...state.leave_time_lines_record,
          ...action.payload.data.data.leave_time_lines,
        ],
        leave_time_lines_days: [...action.payload.data.data.days],
        fetching: false,
        fetchingDetails: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVE_DAYS_LOAD_FAILD':
      return { ...state, fetching: false, loading: false }

    default:
      return state
  }
}
