import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  integrated_with_gosi: 0,
  not_integrated_with_gosi: 0,
  commercial_registrations: [],
  fetching: false,
  errMsg: '',
  connecting: false,
  salary_status: '',
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.GOSI_INTEGRATION_LIST_LOAD_PENDING:
    case actions.GOSI_INTEGRATION_UPDATE_SALARY_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GOSI_INTEGRATION_CR_CONNECT_PENDING:
    case actions.GOSI_INTEGRATION_CR_DISCONNECT_PENDING:
      return {
        ...state,
        connecting: true,
      }

    case actions.GOSI_INTEGRATION_LIST_LOAD_FULFILLED:
      return {
        ...state,
        integrated_with_gosi: payload.data.data.integrated_with_gosi,
        not_integrated_with_gosi: payload.data.data.not_integrated_with_gosi,
        commercial_registrations: payload.data.data.commercial_registrations,
        fetching: false,
      }

    case actions.GOSI_INTEGRATION_LIST_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.GOSI_INTEGRATION_UPDATE_SALARY_FULFILLED:
      return {
        ...state,
        salary_status: 'success',
        fetching: false,
      }

    case actions.GOSI_INTEGRATION_CR_CONNECT_FULFILLED:
    case actions.GOSI_INTEGRATION_CR_DISCONNECT_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        connecting: false,
      }

    case actions.GOSI_INTEGRATION_CR_CONNECT_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        connecting: false,
      }
    }

    case actions.GOSI_INTEGRATION_CR_DISCONNECT_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        connecting: false,
      }
    }

    case actions.GOSI_INTEGRATION_UPDATE_SALARY_REJECTED: {
      // Toastr.error(handleResponseErr(payload));
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        salary_status: 'error',
        fetching: false,
      }
    }
    case actions.GOSI_INTEGRATION_UPDATE_SALARY_DISMISS: {
      return {
        ...state,
        errMsg: '',
        salary_status: 'dismissed',
      }
    }
    default:
      return state
  }
}
