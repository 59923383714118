import { Item } from 'components/Filter/SelectedFilters'
import {
  CandidateFilterScreening,
  CandidateFilterScreeningAPI,
  JobApplicantScreeningRequest,
} from 'redux/ATS/jobApplicationInfo/jobApplicants/actions'

export const formatCandidateFilter = (
  candidateFilter: CandidateFilterScreeningAPI[],
): CandidateFilterScreening[] => {
  let id = 0
  return candidateFilter.map((question) => {
    return {
      id: question?.id,
      title: question?.title,
      options: question.options.map((option) => {
        id += 1
        return {
          id,
          title: option,
          options: [],
        }
      }),
    }
  })
}

export const formatScreeningQuestions = (
  selectedIds: number[],
  screeningQuestions: CandidateFilterScreening[],
): JobApplicantScreeningRequest[] => {
  const formattedScreeningQuestions: JobApplicantScreeningRequest[] = []
  screeningQuestions.forEach((question) => {
    const selectedOptions: string[] = []
    const optionIds = question.options.map((option) => option.id)
    selectedIds.forEach((id) => {
      if (optionIds.includes(id)) {
        const val = question.options.find((option) => option.id === id)?.title ?? ''
        selectedOptions.push(val)
      }
    })
    if (selectedOptions?.length) {
      formattedScreeningQuestions.push({
        id: question.id,
        title: question?.title,
        options: selectedOptions,
      })
    }
  })
  return formattedScreeningQuestions
}

export const formatScreeningTag = (
  tagIds: number[],
  screeningFilters: CandidateFilterScreening[],
): Item[] => {
  const screeningList: Item[] = []
  screeningFilters.forEach((screeningFilter) => {
    const filters = screeningFilter.options.filter((option) => tagIds?.includes(option.id))
    if (filters?.length) {
      filters.forEach((filter) => {
        screeningList.push({
          id: filter.id,
          name: filter?.title,
          keyType: 'candidate_screening',
        })
      })
    }
  })
  return screeningList
}
