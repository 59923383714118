import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  employee_leave: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  affectedEmployees: [],
  employees: [],
  employee_leave_history: {},
}

let changes = null
export default function annualLeaveReducer(state = DEFAULT_STATE, action) {
  const { payload } = action

  let updatedRecord = {}
  switch (action.type) {
    case 'ANNUAL_LEAVES_LOAD_PENDING':
    case 'ANNUAL_LEAVE_LOAD_PENDING':
    case 'ANNUAL_LEAVE_UPDATE_PENDING':
    case 'ANNUAL_BALANCE_UPDATE_PENDING':
    case 'ANNUAL_LEAVE_RECALCULATE_UPDATE_PENDING':
    case 'EMPLOYEE_LEAVES_BRIEF_LOAD_PENDING':
    case 'MANUAL_BALANCE_ADJUSTMENT_HISTORY_LOAD_PENDING':
    case 'UPTO_DATE_BALANCE_UPDATE_PENDING':
    case 'UPTO_DATE_CARRY_FORWARD_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'ANNUAL_LEAVES_LOAD_REJECTED':
    case 'ANNUAL_LEAVE_LOAD_REJECTED':
    case 'ANNUAL_LEAVE_UPDATE_REJECTED':
    case 'ANNUAL_BALANCE_UPDATE_REJECTED':
    case 'EMPLOYEE_LEAVES_BRIEF_LOAD_REJECTED':
    case 'MANUAL_BALANCE_ADJUSTMENT_HISTORY_LOAD_REJECTED':
    case 'UPTO_DATE_BALANCE_UPDATE_REJECTED':
    case 'UPTO_DATE_CARRY_FORWARD_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ANNUAL_LEAVE_RECALCULATE_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        affectedEmployees: payload.response.data.data.employees_with_invalid_requests,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ANNUAL_LEAVES_LOAD_FULFILLED':
    case 'ANNUAL_LEAVE_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVES_BRIEF_LOAD_FULFILLED':
      changes = {
        fetching: false,
        employees: payload.data.data,
      }
      return { ...state, ...changes }

    case 'MANUAL_BALANCE_ADJUSTMENT_HISTORY_LOAD_FULFILLED':
      changes = {
        fetching: false,
        employee_leave_history: payload.data.data,
      }
      return { ...state, ...changes }

    case 'ANNUAL_BALANCE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)

      updatedRecord = payload.data.data.employee_leave
      changes = {
        employee_leave: payload.data.data?.employee_leave,
        editing: {
          ...state.editing,
          [`annual_balance_${updatedRecord.id}`]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'UPTO_DATE_BALANCE_UPDATE_FULFILLED':
    case 'UPTO_DATE_CARRY_FORWARD_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)

      updatedRecord = payload.data.data?.employee_leave

      changes = {
        employee_leave: payload.data.data?.employee_leave,
        editing: {
          ...state.editing,
          [`uptoDate_${updatedRecord.id}`]: false,
        },
        fetching: false,
      }

      return { ...state, ...changes }

    case 'ANNUAL_LEAVE_RECALCULATE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case 'ANNUAL_LEAVE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [`${action.obj.editFor}_${action.obj.id}`]: true,
        },
      }
      return { ...state, ...changes }
    case 'ANNUAL_LEAVE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [`${action.obj.editFor}_${action.obj.id}`]: false,
        },
      }
      return { ...state, ...changes }

    case 'RESET':
      changes = {
        employee_leave: {},
        affectedEmployees: [],
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
