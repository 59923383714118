import { useEffect, useRef } from 'react'
import { Flex } from '@jisr-hr/ds-beta'
import ClientMessageComponent from './ClientMessageComponent'
import JisrAiResponseComponent from './JisrAiResponseComponent'
import { MessageListProps } from '../types'

const MessageList = ({ messageList, setMessageList, isLanguageSelected }: MessageListProps) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Scroll to the last message when messages changes
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messageList?.length])

  return (
    <Flex
      flexCol
      className="flex-1 p-3 overflow-y-auto h-64 pt-8"
    >
      {(Array.isArray(messageList) ? messageList : []).map((item, index) => {
        if (item?.type === 'client') {
          return (
            <ClientMessageComponent
              key={`client-${index}`}
              message={item?.message}
              showSelectLanguageOption={item?.showSelectLanguageOption}
              setMessageList={setMessageList}
              isLanguageSelected={isLanguageSelected}
              data-testid="client-message-component"
            />
          )
        }
        return (
          <JisrAiResponseComponent
            key={`bot-${item?.messageId}-${index}`}
            message={item?.message}
            showLoader={item?.showLoader}
            showFeedbackOptions={item?.showFeedbackOptions}
            messageId={item?.messageId}
            setMessageList={setMessageList}
            feedbackValue={item?.feedbackValue}
            showHelpOptions={item?.showHelpOptions}
            data-testid="jisrai-response-component"
          />
        )
      })}
      <div ref={endOfMessagesRef} />
    </Flex>
  )
}

export default MessageList
