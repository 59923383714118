import React from 'react'
import I18n from 'translations/i18n'
import { Input } from 'components/global/atoms'
import { useDispatch, useSelector } from 'react-redux'
import { taskReassign, taskDetailLoad } from 'redux/requests/newRequestsApproval/actionCreators'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import Frame from '../../ds/Frame'
import { useRequest } from '../../RequestProvider'

const ReassignTask = () => {
  const dispatch = useDispatch()
  const { employee_all } = useSelector((state) => state.employee)
  const isSuperAdmin = useSelector((state) => state.auth.employee.is_super_admin)
  const { fetching_reassign } = useSelector((state) => state.approvals)
  const {
    vars: { request },
  } = useRequest()

  const handleChange = (employee_id) => {
    dispatch(
      taskReassign(request.id, {
        reassigned_employee_id: employee_id,
      }),
    ).then(() => dispatch(taskDetailLoad(request.id)))
  }

  React.useEffect(() => {
    if (isSuperAdmin) dispatch(getAllEmployeesLoad())
  }, [])

  if (!isSuperAdmin) {
    return null
  }

  return (
    <Frame title={I18n.t('reassign_task')}>
      <Input.Select
        userSelect
        testId="reassign-tasks"
        options={employee_all}
        labelKey="name"
        valueKey="id"
        imagekey="avatar_thumb"
        version="v1"
        onChange={handleChange}
        disabled={fetching_reassign}
      />
    </Frame>
  )
}

export default ReassignTask
