import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import AnnouncementForm, { SubmitDataProps } from 'components/authorised/Home/AnnouncementForm'
import { announcementsCreate } from 'redux/actions/announcementsActions'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import { businessUnitsLoad } from 'redux/actions/businessUnitActions'
import { listDepartmentsLoad } from 'redux/newDepartments/actionCreators'
import { locationsLoad } from 'redux/actions/filterActions'
import { Modal } from '@jisr-hr/ds'
import { mixPanelAnalytics } from 'utils/mixpanel'
import I18n from 'translations/i18n'

const Announcement = (): JSX.Element => {
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { fetching } = useSelector(({ announcements }) => announcements)
  const currentEmployee = useSelector(({ auth }) => auth.employee)

  const onSubmit = (values: SubmitDataProps): void => {
    dispatch(announcementsCreate(values)).then(() => {
      setOpenForm(false)
      if (values?.group_type === 'subsidiaries') {
        mixPanelAnalytics({
          event_name: 'Announcement_Shared',
          properties: {
            Subsidiary_Selected: values?.group_ids,
            Announcement_Type: values.group_type,
            User_ID: currentEmployee?.id,
          },
        })
      }
    })
  }

  useEffect(() => {
    if (openForm) {
      dispatch(getAllEmployeesLoad({ template: 'public' }))
      dispatch(locationsLoad({ template: 'public' }))
      dispatch(listDepartmentsLoad({ template: 'public' }))
      dispatch(businessUnitsLoad({ template: 'public' }))
    }
  }, [openForm])

  return (
    <div>
      <Modal
        open={openForm}
        size="large"
        onClose={(): void => setOpenForm(false)}
        header={{ title: I18n.t('share_announcement'), withClose: true }}
        footer={{
          submit: {
            label: I18n.t('share'),
            disabled: disabled || fetching,
            type: 'submit',
            formId: 'announcement-form',
            onClick: (): void => undefined,
            testId: 'submit-showAnnouncement',
          },
          cancel: {
            label: I18n.t('cancel'),
            onClick: (): void => setOpenForm(false),
          },
        }}
      >
        <div style={{ maxHeight: '75vh', overflow: 'scroll' }}>
          <AnnouncementForm
            onSubmit={onSubmit}
            setDisabled={setDisabled}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Announcement
