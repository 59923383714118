import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import { AssetData } from 'containers/authorised/employee/EmployeeDetails/Tabs/EmployeeAssets/EmployeeAssets'
import * as actions from './actions'

type TInitState = {
  employee_assets: AssetData[]
  fetching: boolean
  pagination: PaginationTypes | Record<string, never>
}

const initialState: TInitState = {
  fetching: false,
  employee_assets: [],
  pagination: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.GET_EMPLOYEE_ASSETS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_EMPLOYEE_ASSETS_FULFILLED:
      return {
        ...state,
        employee_assets: [
          ...new Set([
            ...(payload.data.data.pagination?.previous_page ? state.employee_assets : []),
            ...payload.data.data.employee_assets,
          ]),
        ],
        pagination: payload.data.data.pagination,
        fetching: false,
      }

    case actions.GET_EMPLOYEE_ASSETS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
