import { Fragment } from 'react'
import { Table } from '@jisr-hr/design-system'
import { Typography, Flex, Modal } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import { salarypackagesSelector } from 'redux/payrollOnboarding/SalarySetup/selectors'
import { ViewMoreModalProps } from '../../SummaryReview'

const ViewMoreModal = ({ isOpen, onClose }: ViewMoreModalProps): JSX.Element => {
  const { header, records } = useSelector(salarypackagesSelector)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="x-large"
      header={{ title: I18n.t('salaries_packages'), withClose: true }}
    >
      <div className="pb-[24px]">
        <Table
          variant="rounded"
          headers={header}
          containerStyles={{ maxHeight: 588 }}
        >
          {records?.map((record, i) => (
            <Table.Row key={i}>
              {record?.map((record_item, index: number) => (
                <Fragment key={index}>
                  {index === 0 && (
                    <Table.Cell>
                      <Flex style={{ width: 150 }}>
                        <Flex flexCol>
                          <Typography
                            variant="interface/default/sm"
                            text={record_item.name_i18n}
                          />
                          <Typography
                            style={{ color: 'var(--color-base-colors-black-500)' }}
                            variant="interface/default/sm"
                            text={record_item.code}
                          />
                        </Flex>
                      </Flex>
                    </Table.Cell>
                  )}
                  {index === 1 && (
                    <Table.Cell>
                      <Flex
                        flexCol
                        style={{ width: 107 }}
                      >
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.basic_salary}
                        />
                      </Flex>
                    </Table.Cell>
                  )}
                  {index === 2 && (
                    <Table.Cell>
                      <Flex
                        flexCol
                        style={{ width: 107 }}
                      >
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.ajeer_fees}
                        />
                      </Flex>
                    </Table.Cell>
                  )}
                  {index > 2 && (
                    <Table.Cell>
                      {index !== record.length - 1 ? (
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.company_contribution}
                        />
                      ) : (
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.full_package_salary}
                        />
                      )}
                    </Table.Cell>
                  )}
                </Fragment>
              ))}
            </Table.Row>
          ))}
        </Table>
      </div>
    </Modal>
  )
}

export default ViewMoreModal
