import { useEffect, useState } from 'react'
import { Flex } from '@jisr-hr/ds-beta'

const ChatLoadingDots = () => {
  const [dotIndex, setDotIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setDotIndex((prev) => (prev + 1) % 3)
    }, 400)
    return () => clearInterval(interval)
  }, [])

  return (
    <Flex
      itemsCenter
      justifyCenter
      className="bg-base-colors-white-1000 mb-3 rounded-tl-lg rounded-tr-lg rounded-br-lg max-w-[70%] py-3 px-4"
    >
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className="w-1.5 h-1.5 rounded-full bg-base-colors-grey-600 transition-opacity duration-300 ease-in-out my-1 mx-1.5"
          style={{ opacity: dotIndex === i ? 1 : 0.3 }}
        />
      ))}
    </Flex>
  )
}

export default ChatLoadingDots
