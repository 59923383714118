import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type MemberLinkedTypes = {
  filterKey: string
  filters: string[]
}

const MemberLinked = (props: MemberLinkedTypes): JSX.Element => {
  const { member_linked_data } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(member_linked_data)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (typeof val === 'boolean') {
      handleFilter('is_member_linked', [val])
    } else {
      handleFilter('is_member_linked', [])
    }
  }

  const checkSelectedIds = (): string[] => {
    if (fltr && typeof fltr[0] === 'boolean') {
      return fltr as string[]
    }
    return ['all']
  }

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={checkSelectedIds()}
      componentType="badges"
      valueKey="id"
      {...props}
      title={`${i18n.t('is_member_linked')} ${i18n.t('jisr_translation_name')}`}
    />
  )
}

export default MemberLinked
