export const HIRING_REQUESTS_LOAD = 'HIRING_REQUESTS_LOAD'
export const HIRING_REQUESTS_LOAD_FULFILLED = 'HIRING_REQUESTS_LOAD_FULFILLED'
export const HIRING_REQUESTS_LOAD_REJECTED = 'HIRING_REQUESTS_LOAD_REJECTED'

export const HIRING_REQUEST_CREATE = 'HIRING_REQUEST_CREATE'
export const HIRING_REQUEST_CREATE_PENDING = 'HIRING_REQUEST_CREATE_PENDING'
export const HIRING_REQUEST_CREATE_FULFILLED = 'HIRING_REQUEST_CREATE_FULFILLED'
export const HIRING_REQUEST_CREATE_REJECTED = 'HIRING_REQUEST_CREATE_REJECTED'

export const HIRING_REQUEST_UPDATE = 'HIRING_REQUEST_UPDATE'
export const HIRING_REQUEST_UPDATE_PENDING = 'HIRING_REQUEST_UPDATE_PENDING'
export const HIRING_REQUEST_UPDATE_FULFILLED = 'HIRING_REQUEST_UPDATE_FULFILLED'
export const HIRING_REQUEST_UPDATE_REJECTED = 'HIRING_REQUEST_UPDATE_REJECTED'
