import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function resignationRequestsLoad() {
  const url = `/${API_VERSION_TWO}/resignation_requests`
  return {
    type: actions.RESIGNATION_REQUESTS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function resignationRequestCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/resignation_requests`
  return {
    type: actions.RESIGNATION_REQUEST_CREATE,
    payload: handleAPI(url, {}, 'POST', { resignation_request: data }),
  }
}

export function resignationRequestUpdate(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/resignation_requests/${id}`
  return {
    type: actions.RESIGNATION_REQUEST_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { resignation_request: data }),
  }
}

export function resignationReasonsLoad(params) {
  const url = `/${API_VERSION_TWO}/resignation_reasons`
  return {
    type: actions.RESIGNATION_REASONS_LOAD,
    payload: handleAPI(url, params, 'get'),
  }
}
