export const LOAD_POLICIES_LIST = 'LOAD_POLICIES_LIST'
export const LOAD_POLICIES_LIST_FULFILLED = 'LOAD_POLICIES_LIST_FULFILLED'
export const LOAD_POLICIES_LIST_PENDING = 'LOAD_POLICIES_LIST_PENDING'
export const LOAD_POLICIES_LIST_REJECTED = 'LOAD_POLICIES_LIST_REJECTED'

export const GET_POLICY_MEMBERS = 'GET_POLICY_MEMBERS'
export const GET_POLICY_MEMBERS_PENDING = 'GET_POLICY_MEMBERS_PENDING'
export const GET_POLICY_MEMBERS_FULFILLED = 'GET_POLICY_MEMBERS_FULFILLED'
export const GET_POLICY_MEMBERS_REJECTED = 'GET_POLICY_MEMBERS_REJECTED'

export const GET_ELIGIBLE_EMPLOYEE_POLICIES = 'GET_ELIGIBLE_EMPLOYEE_POLICIES'
export const GET_ELIGIBLE_EMPLOYEE_POLICIES_PENDING = 'GET_ELIGIBLE_EMPLOYEE_POLICIES_PENDING'
export const GET_ELIGIBLE_EMPLOYEE_POLICIES_FULFILLED = 'GET_ELIGIBLE_EMPLOYEE_POLICIES_FULFILLED'
export const GET_ELIGIBLE_EMPLOYEE_POLICIES_REJECTED = 'GET_ELIGIBLE_EMPLOYEE_POLICIES_REJECTED'

export const GET_POLICY_DETAILS = 'GET_POLICY_DETAILS'
export const GET_POLICY_DETAILS_PENDING = 'GET_POLICY_DETAILS_PENDING'
export const GET_POLICY_DETAILS_FULFILLED = 'GET_POLICY_DETAILS_FULFILLED'
export const GET_POLICY_DETAILS_REJECTED = 'GET_POLICY_DETAILS_REJECTED'

export const GET_MEMBER_DETAILS = 'GET_MEMBER_DETAILS'
export const GET_MEMBER_DETAILS_PENDING = 'GET_MEMBER_DETAILS_PENDING'
export const GET_MEMBER_DETAILS_FULFILLED = 'GET_MEMBER_DETAILS_FULFILLED'
export const GET_MEMBER_DETAILS_REJECTED = 'GET_MEMBER_DETAILS_REJECTED'

export const SYNC_POLICIES = 'SYNC_POLICIES'
export const SYNC_POLICIES_PENDING = 'SYNC_POLICIES_PENDING'
export const SYNC_POLICIES_FULFILLED = 'SYNC_POLICIES_FULFILLED'
export const SYNC_POLICIES_REJECTED = 'SYNC_POLICIES_REJECTED'

export const SYNC_MEMBERS = 'SYNC_MEMBERS'
export const SYNC_MEMBERS_PENDING = 'SYNC_MEMBERS_PENDING'
export const SYNC_MEMBERS_FULFILLED = 'SYNC_MEMBERS_FULFILLED'
export const SYNC_MEMBERS_REJECTED = 'SYNC_MEMBERS_REJECTED'

export const VERIFY_MEMBER_REASON = 'VERIFY_MEMBER_REASON'
export const VERIFY_MEMBER_REASON_PENDING = 'VERIFY_MEMBER_REASON_PENDING'
export const VERIFY_MEMBER_REASON_FULFILLED = 'VERIFY_MEMBER_REASON_FULFILLED'
export const VERIFY_MEMBER_REASON_REJECTED = 'VERIFY_MEMBER_REASON_REJECTED'

export const GET_MEMBER_DATA_FROM_ELM = 'GET_MEMBER_DATA_FROM_ELM'
export const GET_MEMBER_DATA_FROM_ELM_PENDING = 'GET_MEMBER_DATA_FROM_ELM_PENDING'
export const GET_MEMBER_DATA_FROM_ELM_FULFILLED = 'GET_MEMBER_DATA_FROM_ELM_FULFILLED'
export const GET_MEMBER_DATA_FROM_ELM_REJECTED = 'GET_MEMBER_DATA_FROM_ELM_REJECTED'

export const EDIT_MEMBER = 'EDIT_MEMBER'
export const EDIT_MEMBER_PENDING = 'EDIT_MEMBER_PENDING'
export const EDIT_MEMBER_FULFILLED = 'EDIT_MEMBER_FULFILLED'
export const EDIT_MEMBER_REJECTED = 'EDIT_MEMBER_REJECTED'

export const CHANGE_MEMBER_ID = 'CHANGE_MEMBER_ID'
export const CHANGE_MEMBER_ID_PENDING = 'CHANGE_MEMBER_ID_PENDING'
export const CHANGE_MEMBER_ID_FULFILLED = 'CHANGE_MEMBER_ID_FULFILLED'
export const CHANGE_MEMBER_ID_REJECTED = 'CHANGE_MEMBER_ID_REJECTED'

export const GET_INSURANCE_INFO_IN_OFFBOARD = 'GET_INSURANCE_INFO_IN_OFFBOARD'
export const GET_INSURANCE_INFO_IN_OFFBOARD_PENDING = 'GET_INSURANCE_INFO_IN_OFFBOARD_PENDING'
export const GET_INSURANCE_INFO_IN_OFFBOARD_FULFILLED = 'GET_INSURANCE_INFO_IN_OFFBOARD_FULFILLED'
export const GET_INSURANCE_INFO_IN_OFFBOARD_REJECTED = 'GET_INSURANCE_INFO_IN_OFFBOARD_REJECTED'

export const RESET_MEMBER_DETAILS = 'RESET_MEMBER_DETAILS'
export const RESET_MEMBER_OF_ELM = 'RESET_MEMBER_OF_ELM'
