export const ONBOARDING_EMPLOYEES_INVITE_LOAD = 'ONBOARDING_EMPLOYEES_INVITE_LOAD'
export const ONBOARDING_EMPLOYEES_INVITE_LOAD_PENDING = 'ONBOARDING_EMPLOYEES_INVITE_LOAD_PENDING'
export const ONBOARDING_EMPLOYEES_INVITE_LOAD_FULFILLED =
  'ONBOARDING_EMPLOYEES_INVITE_LOAD_FULFILLED'
export const ONBOARDING_EMPLOYEES_INVITE_LOAD_REJECTED = 'ONBOARDING_EMPLOYEES_INVITE_LOAD_REJECTED'

export const ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD = 'ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD'
export const ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_PENDING =
  'ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_PENDING'
export const ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_FULFILLED =
  'ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_FULFILLED'
export const ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_REJECTED =
  'ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD_REJECTED'
