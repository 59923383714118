import { DatePicker, DatePickerProps } from '@jisr-hr/ds-beta'
import { Field, FieldRenderProps } from 'react-final-form'
import { memo, FC, useCallback } from 'react'
import { isRtl } from 'utils/uiHelpers'

type DatePickerFieldProps = DatePickerProps & {
  name: string
  statusMessage?: string
  showErrorMessage?: boolean
  validate?: (_: any, values: any) => string | undefined
  parse?: <V, R>(value: V, name: string) => R
}

const DatePickerDSField: FC<DatePickerFieldProps> = (props): JSX.Element => {
  const {
    name,
    validate,
    parse,
    onChange,
    showErrorMessage = true,
    statusMessage,
    value,
    width,
    calenderProps,
    buttonProps,
    textFieldProps,
    dateFormat = 'yyyy-MM-dd',
    position,
    triggerType = 'input',
    label,
    onRemoveDate,
  } = props
  return (
    <Field
      name={name}
      parse={parse}
      validate={validate}
    >
      {({ input, meta }: FieldRenderProps<string, HTMLElement>): JSX.Element => {
        const handleChange = useCallback(
          (val, date) => {
            input.onChange(val)
            if (onChange) {
              onChange(val, date)
            }
          },
          [input, onChange],
        )

        return (
          <DatePicker
            label={label}
            width={width}
            value={input.value && calenderProps?.type !== 'range' ? new Date(input.value) : value}
            dateFormat={dateFormat}
            position={position || (isRtl ? 'right-start' : 'left-start')}
            triggerType={triggerType}
            onChange={handleChange}
            onRemoveDate={(): void => {
              input.onChange(undefined)
              onRemoveDate?.()
            }}
            calenderProps={{
              size: 'compact',
              type: 'single',
              preSetRange: false,
              ...calenderProps,
            }}
            buttonProps={{ ...buttonProps }}
            textFieldProps={{
              onBlur: input.onBlur,
              ...(!!meta.touched && showErrorMessage && meta.error
                ? { status: 'destructive' }
                : {}),
              helperText: (!!meta.touched && showErrorMessage && meta.error) || statusMessage,
              ...textFieldProps,
            }}
          />
        )
      }}
    </Field>
  )
}

export default memo(DatePickerDSField)
