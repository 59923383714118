import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import { useFilters } from './context'
import useFilterAPI from '../useFilterAPI'

type TTimesheetAssignedPaygroupProps = {
  title: string
  filterKey: string
  extra_title: string
  filters: string[]
}

const TimesheetAssignedPaygroup = (props: TTimesheetAssignedPaygroupProps) => {
  const { filter, handleFilter } = useFilters()

  const { paygroups } = useFilterAPI()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={paygroups ?? []}
      selectedIds={filter.paygroup_ids}
      {...props}
      title={i18n.t('modal.announcement.new_attendnace_sheet.subtitle_1')}
    />
  )
}

export default TimesheetAssignedPaygroup
