import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { Candidate, GetCandidateResponse } from './actions'

type InitialStateType = {
  response: GetCandidateResponse
  fetching: boolean
  candidate: Candidate | null
}

const initialState: InitialStateType = {
  candidate: null,
  response: {
    data: {} as Candidate,
    statusCode: 200,
    success: false,
  },
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.GET_CANDIDATE_BY_ID_PENDING: {
      return {
        ...state,
        candidate: null,
        fetching: true,
      }
    }

    case actions.GET_CANDIDATE_BY_ID_FULFILLED: {
      return {
        ...state,
        candidate: action.payload.data.data,
        response: {
          ...action.payload.data,
          success: true,
        },
        fetching: false,
      }
    }

    case actions.GET_CANDIDATE_BY_ID_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        response: {
          ...state.response,
          success: false,
        },
        fetching: false,
      }
    }

    case actions.CLEAR_CANDIDATE_BY_ID_DATA: {
      return {
        ...state,
        response: { data: {} as Candidate, statusCode: 200, success: false },
      }
    }

    default:
      return state
  }
}
