import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'

type InfoProps = {
  children?: Node
  grayStyle?: boolean
  testId?: string
  tip?: string | Node
}

const Info = ({ tip, children, grayStyle, testId, ...props }: InfoProps): JSX.Element => {
  const id = Math.floor(Math.random() * Math.floor(1000))
  const text = tip || children
  return (
    <span
      data-testid={testId}
      data-for={`tol-${id}`}
      className={classNames('jisr-info-tooltip', grayStyle && 'gray-style')}
      data-tip={text}
    >
      <ReactTooltip
        {...props}
        effect="solid"
        id={`tol-${id}`}
      >
        {text}
      </ReactTooltip>
    </span>
  )
}

Info.propTypes = {
  testId: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  grayStyle: PropTypes.bool,
  children: PropTypes.node,
  html: PropTypes.bool,
}
export default Info
