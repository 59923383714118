import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { SheetState } from './SheetTypes'

const initialState: SheetState = {
  fetching: false,
  loading: false,
  timesheets: [],
  pagination: {},
  stats: {},
}
export default function reducer(state = initialState, action: Action): SheetState {
  switch (action.type) {
    case actions.RESET_TIMESHEETS_LISTING:
      return {
        ...state,
        timesheets: [],
        pagination: {},
      }

    case actions.TIMESHEETS_LISTING_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DISMISS_TIMESHEET_PENDING:
    case actions.APPROVE_TIMESHEET_PENDING:
    case actions.REOPEN_TIMESHEET_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.DISMISS_TIMESHEET_FULFILLED:
    case actions.APPROVE_TIMESHEET_FULFILLED:
    case actions.REOPEN_TIMESHEET_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.TIMESHEETS_LISTING_FULFILLED:
      return {
        ...state,
        timesheets: [...state.timesheets, ...action.payload.data.data.timesheets],
        pagination: action.payload.data.data.pagination,
        stats: action.payload.data.data.stats,
        fetching: false,
      }

    case actions.TIMESHEETS_LISTING_REJECTED:
    case actions.DISMISS_TIMESHEET_REJECTED:
    case actions.APPROVE_TIMESHEET_REJECTED:
    case actions.REOPEN_TIMESHEET_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
