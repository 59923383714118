import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'

const DEFAULT_STATE = {
  fetching: false,
  schedule: [],
}

let changes = null
export default function addUserShiftReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'ADD_USER_SHIFT_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'ADD_USER_SHIFT_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        schedule: action.payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ADD_USER_SHIFT_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
