export const HR_COUNTRIES_LOAD = 'HR_COUNTRIES_LOAD'
export const HR_COUNTRIES_LOAD_PENDING = 'HR_COUNTRIES_LOAD_PENDING'
export const HR_COUNTRIES_LOAD_FULFILLED = 'HR_COUNTRIES_LOAD_FULFILLED'
export const HR_COUNTRIES_LOAD_REJECTED = 'HR_COUNTRIES_LOAD_REJECTED'

export const COUNTRIES_v2_LOAD = 'COUNTRIES_v2_LOAD'
export const COUNTRIES_v2_LOAD_PENDING = 'COUNTRIES_v2_LOAD_PENDING'
export const COUNTRIES_v2_LOAD_FULFILLED = 'COUNTRIES_v2_LOAD_FULFILLED'
export const COUNTRIES_v2_LOAD_REJECTED = 'COUNTRIES_v2_LOAD_REJECTED'

export const REGIONS_LOAD = 'REGIONS_LOAD'
export const REGIONS_LOAD_PENDING = 'REGIONS_LOAD_PENDING'
export const REGIONS_LOAD_FULFILLED = 'REGIONS_LOAD_FULFILLED'
export const REGIONS_LOAD_REJECTED = 'REGIONS_LOAD_REJECTED'

export const CITIES_LOAD = 'CITIES_LOAD'
export const CITIES_LOAD_PENDING = 'CITIES_LOAD_PENDING'
export const CITIES_LOAD_FULFILLED = 'CITIES_LOAD_FULFILLED'
export const CITIES_LOAD_REJECTED = 'CITIES_LOAD_REJECTED'

export const LOCATIONS_LOAD = 'LOCATIONS_LOAD'
export const LOCATIONS_LOAD_PENDING = 'LOCATIONS_LOAD_PENDING'
export const LOCATIONS_LOAD_FULFILLED = 'LOCATIONS_LOAD_FULFILLED'
export const LOCATIONS_LOAD_REJECTED = 'LOCATIONS_LOAD_REJECTED'

export const AREAS_LOAD = 'AREAS_LOAD'
export const AREAS_LOAD_PENDING = 'AREAS_LOAD_PENDING'
export const AREAS_LOAD_FULFILLED = 'AREAS_LOAD_FULFILLED'
export const AREAS_LOAD_REJECTED = 'AREAS_LOAD_REJECTED'

export const AREA_BULK_CREATE = 'AREA_BULK_CREATE'
export const AREA_BULK_CREATE_PENDING = 'AREA_BULK_CREATE_PENDING'
export const AREA_BULK_CREATE_FULFILLED = 'AREA_BULK_CREATE_FULFILLED'
export const AREA_BULK_CREATE_REJECTED = 'AREA_BULK_CREATE_REJECTED'

export const LOCATION_BULK_CREATE = 'LOCATION_BULK_CREATE'
export const LOCATION_BULK_CREATE_PENDING = 'LOCATION_BULK_CREATE_PENDING'
export const LOCATION_BULK_CREATE_FULFILLED = 'LOCATION_BULK_CREATE_FULFILLED'
export const LOCATION_BULK_CREATE_REJECTED = 'LOCATION_BULK_CREATE_REJECTED'

export const LOCATION_CREATE = 'LOCATION_CREATE'
export const LOCATION_CREATE_PENDING = 'LOCATION_CREATE_PENDING'
export const LOCATION_CREATE_FULFILLED = 'LOCATION_CREATE_FULFILLED'
export const LOCATION_CREATE_REJECTED = 'LOCATION_CREATE_REJECTED'

export const AREA_UPDATE = 'AREA_UPDATE'
export const AREA_UPDATE_PENDING = 'AREA_UPDATE_PENDING'
export const AREA_UPDATE_FULFILLED = 'AREA_UPDATE_FULFILLED'
export const AREA_UPDATE_REJECTED = 'AREA_UPDATE_REJECTED'

export const LOCATION_UPDATE = 'LOCATION_UPDATE'
export const LOCATION_UPDATE_PENDING = 'LOCATION_UPDATE_PENDING'
export const LOCATION_UPDATE_FULFILLED = 'LOCATION_UPDATE_FULFILLED'
export const LOCATION_UPDATE_REJECTED = 'LOCATION_UPDATE_REJECTED'

export const ASSIGN_LOCATIONS_EMPLOYEES = 'ASSIGN_LOCATIONS_EMPLOYEES'
export const ASSIGN_LOCATIONS_EMPLOYEES_PENDING = 'ASSIGN_LOCATIONS_EMPLOYEES_PENDING'
export const ASSIGN_LOCATIONS_EMPLOYEES_FULFILLED = 'ASSIGN_LOCATIONS_EMPLOYEES_FULFILLED'
export const ASSIGN_LOCATIONS_EMPLOYEES_REJECTED = 'ASSIGN_LOCATIONS_EMPLOYEES_REJECTED'

export const COUNTRY_DELETE = 'COUNTRY_DELETE'
export const COUNTRY_DELETE_PENDING = 'COUNTRY_DELETE_PENDING'
export const COUNTRY_DELETE_FULFILLED = 'COUNTRY_DELETE_FULFILLED'
export const COUNTRY_DELETE_REJECTED = 'COUNTRY_DELETE_REJECTED'

export const OPEN_AREA = 'OPEN_AREA'
export const OPEN_LOCATION = 'OPEN_LOCATION'
export const DELETE_AREA = 'DELETE_AREA'
export const DELETE_LOCATION = 'DELETE_LOCATION'

export const COUNTRY_CITIES_LOAD = 'COUNTRY_CITIES_LOAD'
export const COUNTRY_CITIES_LOAD_PENDING = 'COUNTRY_CITIES_LOAD_PENDING'
export const COUNTRY_CITIES_LOAD_FULFILLED = 'COUNTRY_CITIES_LOAD_FULFILLED'
export const COUNTRY_CITIES_LOAD_REJECTED = 'COUNTRY_CITIES_LOAD_REJECTED'
