import { memo } from 'react'
import { MultiSelect, MultiSelectProps } from '@jisr-hr/ds-beta'
import { Field } from 'react-final-form'

type MultiSelectFieldProps<T> = MultiSelectProps<T> & {
  name: string
  validate?: (_: any, values: any) => string | undefined
  returnType?: 'value' | 'object'
}

const MultiSelectField = <T,>({
  valueKey = 'value',
  returnType = 'object',
  name,
  onChange,
  validate,
  maxMenuHeight = 200,
  ...rest
}: MultiSelectFieldProps<T>) => (
  <Field
    name={name}
    validate={validate}
  >
    {({ input, meta }) => {
      const { value } = input
      let valuesFromOptions = []
      if (returnType === 'object') {
        const valueKeys = Array.isArray(value)
          ? value.filter((v) => v && v[valueKey] !== undefined).map((v) => v[valueKey])
          : []
        valuesFromOptions = rest.options?.filter?.((v: T) => valueKeys.includes(v && v[valueKey]))
      } else {
        valuesFromOptions = rest.options.filter((v) => value.includes(v && v[valueKey]))
      }
      return (
        <MultiSelect
          {...rest}
          {...input}
          {...(meta.touched && meta.error && { status: 'destructive' })}
          {...(meta.touched && { helperText: meta.error })}
          value={valuesFromOptions}
          valueKey={valueKey}
          onChange={(val: T[], actionMeta) => {
            if (returnType === 'object') {
              input.onChange(val ?? [])
              onChange?.(val, actionMeta)
            } else {
              const vals = val?.map((v) => v[valueKey]) ?? []
              input.onChange(vals)
              onChange?.(vals, val)
            }
          }}
          maxMenuHeight={maxMenuHeight}
        />
      )
    }}
  </Field>
)

export default memo(MultiSelectField) as typeof MultiSelectField
