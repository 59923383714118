import { serverEnvironment } from 'env'
import { segmentAnalytics } from 'integration/segment'
import { handleAPI, API_VERSION_TWO } from './apiUtils'
import store from '../redux/store'

type THandleCandu = {
  isSegmentEnable: boolean
  accountType?: 'actual' | 'demo' | 'test' | 'testing'
}

const releaseVersion =
  import.meta.env.UkVBQ1RfQVBQX1NFTUFQSE9SRV9HSVRfVEFHX05BTUU ||
  import.meta.env.UkVBQ1RfQVBQX0dJVF9UQUc

const ID_PREFIX = { aws: 1, oci: 2, staging: 3, dev: 4, default: 4 }

// list of pages that should not be tracked (for none logged users)
export const blockPages = [
  'login',
  'forgot_password',
  'change_password',
  'onboarding',
  'print',
  'billing_details',
  'unsubscribe',
  'careers',
]

export const handleCanduAccess = ({ isSegmentEnable, accountType }: THandleCandu): boolean => {
  const hasCanduToken = import.meta.env.UkVBQ1RfQVBQX0ZFX0NBTkRVX1RPS0VO && window.Candu
  const isProductionEnv = ['aws', 'oci'].includes(serverEnvironment)
  const isDevEnv = ['staging', 'dev'].includes(serverEnvironment)

  if (!hasCanduToken) return false
  if (isDevEnv) return accountType === 'actual' ? isSegmentEnable : false
  if (isProductionEnv) return accountType === 'actual' || isSegmentEnable
  return false
}

const createString = (prefix: string, id: string): string => {
  const totalLength = 5
  const combined = `${prefix}${id}`
  const numZeros: number = totalLength - combined.length
  const zeros: string = '0'.repeat(Math.max(0, numZeros))

  return `${prefix}${zeros}${id}`
}

export const generatePrefix = (id: string): string => {
  return createString(ID_PREFIX[serverEnvironment ?? 'default'], id)
}

export const mixPanelBEevent = async <T extends Record<string, unknown>>(data: {
  event_name: string
  properties?: T
}): Promise<void> => {
  try {
    const url = `/${API_VERSION_TWO}/events`
    const method = 'POST'
    await handleAPI(url, {}, method, data)
  } catch (e) {
    console.log('mixpanel error', e)
  }
}

export const mixPanelRegisterCompanyEvent = <T extends Record<string, unknown>>(data: {
  // ! Do not use this function outside the registered company flow
  event_name: string
  properties?: T
}): void => {
  const { onboardingAdmins, organization } = store.getState()
  const { account_type } = onboardingAdmins
  const orgId = organization?.organization?.id?.toString() ?? ''
  const id = onboardingAdmins.employee.id?.toString()
  const orgPrefix = createString(ID_PREFIX[serverEnvironment ?? 'default'], orgId)
  const identity = createString(ID_PREFIX[serverEnvironment ?? 'default'], id ?? '')
  const permittedEvents = import.meta.env.UkVBQ1RfQVBQX1NFR01FTlRfUEVSTUlUVEVEX0VWRU5UUw?.split(',')
  const isPermitAll = import.meta.env.UkVBQ1RfQVBQX1NFR01FTlRfUEVSTUlUX0FMTA === 'true'

  if (
    ['aws', 'oci', 'staging', 'dev'].includes(serverEnvironment) &&
    account_type === 'actual' &&
    (isPermitAll || permittedEvents?.includes(data.event_name))
  ) {
    segmentAnalytics.track(
      data.event_name,
      {
        ...data.properties,
        jisr_code_version: releaseVersion || '',
        org_id: orgId && orgPrefix,
        is_admin: true,
      },
      {
        userId: id && identity,
        groupId: orgId && orgPrefix,
        referrer: document?.referrer || '$direct',
        referrer_domain: document?.referrer?.split('/')[2] || '$direct',
        screen_width: window?.screen.width,
        screen_height: window?.screen.height,
      },
    )
  }
}

export const mixPanelAnalytics = <T extends Record<string, unknown>>(data: {
  event_name: string
  properties?: T
}): void => {
  const { payrun, auth, organization } = store.getState()
  const account_type = auth?.company?.account_type
  const orgId = auth?.employee?.organization?.id.toString() ?? ''
  const isOnboarding =
    !!organization?.organization?.metadata?.first_admin_details?.first_admin_token
  const id = auth?.employee?.id?.toString()
  const orgPrefix = createString(ID_PREFIX[serverEnvironment ?? 'default'], orgId)
  const identity = createString(ID_PREFIX[serverEnvironment ?? 'default'], id ?? '')
  const permittedEvents = import.meta.env.UkVBQ1RfQVBQX1NFR01FTlRfUEVSTUlUVEVEX0VWRU5UUw?.split(',')
  const isPermitAll = import.meta.env.UkVBQ1RfQVBQX1NFR01FTlRfUEVSTUlUX0FMTA === 'true'

  if (
    ['aws', 'oci', 'staging', 'dev'].includes(serverEnvironment) &&
    account_type === 'actual' &&
    !isOnboarding &&
    (isPermitAll || permittedEvents?.includes(data.event_name))
  ) {
    segmentAnalytics.track(
      data.event_name,
      {
        ...data.properties,
        jisr_code_version: releaseVersion || '',
        payroll_vctivation_version: payrun?.payrun_activation_status?.setup_version || 'N/A',
        is_admin: auth?.employee?.is_super_admin || false,
        org_id: orgId && orgPrefix,
      },
      {
        userId: id && identity,
        groupId: orgId && orgPrefix,
        referrer: document?.referrer || '$direct',
        referrer_domain: document?.referrer?.split('/')[2] || '$direct',
        screen_width: window?.screen.width,
        screen_height: window?.screen.height,
      },
    )
  }
}

// Function to convert the string case to human readable case
export const humanize = (str: string): string => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (m) => {
      return m.toUpperCase()
    })
}
