import { FC } from 'react'
import { LoadingIndicator, LoadingIndicatorProps } from '@jisr-hr/ds'
import i18n from 'translations/i18n'

const DSLoadingIndicator: FC<LoadingIndicatorProps> = (props) => {
  return (
    <LoadingIndicator
      text={i18n.t('design_system.loading')}
      {...props}
    />
  )
}

export default DSLoadingIndicator
