import { useState } from 'react'
import Markdown from 'react-markdown'
import i18n from 'translations/i18n'
import { Flex, Icon, Typography } from '@jisr-hr/ds-beta'
import { useSendJisrResponseFeedback } from 'apis/JisriChatbot/useSendFeedback'
import { getSelectedLanguage } from '../utils'
import ChatLoadingDots from './ChatLoadingComponent'
import JIcon from '../icons/JIcon'
import { HelpTextPoints } from '../constants'
import { JisrAiResponseComponentProps } from '../types'

const JisrAiResponseComponent = ({
  message = '',
  showLoader,
  showFeedbackOptions,
  messageId,
  setMessageList,
  feedbackValue,
  showHelpOptions,
}: JisrAiResponseComponentProps) => {
  const { mutate: sendJisriResponseFeedback } = useSendJisrResponseFeedback()
  const [selectedFeedback, setSelectedFeedback] = useState(feedbackValue ?? '')

  const onFeedbackClick = (value: string) => {
    if (selectedFeedback || feedbackValue === value) return
    setSelectedFeedback(value)
    sendJisriResponseFeedback(
      {
        data: {
          message_id: messageId,
          feedback_signal: value,
        },
      },
      {
        onSuccess: () => {
          setMessageList((prev) => {
            const updatedMessages = prev.map((messageItem) => {
              if (messageItem?.messageId === messageId) {
                return { ...messageItem, feedbackValue: value }
              }
              return messageItem
            })
            return updatedMessages
          })
        },
        onError: () => {
          setSelectedFeedback('')
        },
      },
    )
  }

  return (
    <Flex
      flexCol
      className="mb-6"
    >
      <Flex
        flexRow
        itemsStart
        className="gap-3"
      >
        <Flex
          itemsCenter
          justifyCenter
          className="mt-1.5 h-6 w-6 rounded-full bg-[var(--color-base-colors-blue-600)]"
        >
          <JIcon
            width={8}
            height={12}
          />
        </Flex>

        {showLoader ? (
          <ChatLoadingDots />
        ) : (
          <Typography
            text={
              <Markdown
                disallowedElements={['script', 'iframe', 'img', 'object', 'embed', 'form']}
                unwrapDisallowed
                skipHtml
                components={{
                  ul: ({ children }) => <ul className="ml-3 mr-3">{children}</ul>,
                  ol: ({ children }) => <ol className="ml-3 mr-3">{children}</ol>,
                }}
              >
                {message}
              </Markdown>
            }
            variant="subtitle-1"
            className={`p-3 pb-0.5 max-w-[70%] rounded-tl-lg rounded-tr-lg ${
              i18n.language === 'ar' ? 'rounded-bl-lg' : 'rounded-br-lg'
            } bg-base-colors-white-1000`}
          />
        )}
      </Flex>
      {!showLoader && showFeedbackOptions && (
        <Flex className="gap-1.5 mt-1.5 ml-11 mr-11">
          <Icon
            name="thumbs-up"
            className="w-3 h-3"
            styles={{
              color: 'var(--color-base-colors-grey-500)',
              fill: selectedFeedback === 'like' ? '#697586' : undefined,
            }}
            onClick={() => onFeedbackClick('like')}
          />

          <Icon
            name="thumbs-down"
            className="w-3 h-3"
            styles={{
              color: 'var(--color-base-colors-grey-500)',
              fill: selectedFeedback === 'dislike' ? '#697586' : undefined,
            }}
            onClick={() => onFeedbackClick('dislike')}
          />
        </Flex>
      )}
      {showHelpOptions && (
        <div className="ml-3 mt-4">
          <Typography
            text={i18n.t('label.im_here_to_help_you_with', {
              lng: getSelectedLanguage(),
            })}
            variant="interface/strong/sm"
            textColor="color/fg/lighter"
          />
          <div className="ml-1.5 mt-4">
            {HelpTextPoints.map((item, index) => (
              <Flex
                itemsCenter
                key={index}
                className="gap-2 mb-2"
              >
                <Icon
                  name="star-06"
                  color="var(--color-base-colors-blue-600)"
                  size="xxs"
                />
                <Typography
                  text={i18n.t(item, {
                    lng: getSelectedLanguage(),
                  })}
                  variant="interface/medium/sm"
                  textColor="color/fg/light"
                />
              </Flex>
            ))}
          </div>
        </div>
      )}
    </Flex>
  )
}

export default JisrAiResponseComponent
