import React from 'react'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeeAttendanceDetails } from 'redux/attendance/employeeDetails/actionsCreators'

const useEmployeeAttendanceShifts = () => {
  const [shifts, setShifts] = React.useState([])
  const [shiftsDetail, setShiftsDetail] = React.useState([])
  const dispatch = useDispatch()

  const { attendance_record, requestFetching: fetching } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )

  const loadShifts = (emp_id, dates, create_attendance_records = false) => {
    dispatch(getEmployeeAttendanceDetails(emp_id, { dates, create_attendance_records }, true))
  }

  const resetShifts = () => {
    setShifts([])
    setShiftsDetail([])
  }

  const handleShiftLabel = (list) => {
    return list.map((l) => ({
      ...l,
      label: `${replaceAmPm(l.shift_start_time)} - ${replaceAmPm(l.shift_end_time)}`,
    }))
  }

  const handleShifts = () => {
    if (attendance_record?.length) {
      let shiftsList = []
      attendance_record.map((r) => {
        shiftsList = [...shiftsList, ...r.employee_shifts]
        return true
      })
      setShifts(handleShiftLabel(shiftsList))
      setShiftsDetail(attendance_record)
    } else {
      setShifts(handleShiftLabel([]))
      setShiftsDetail(attendance_record)
    }
  }

  React.useEffect(() => {
    handleShifts()
  }, [attendance_record])

  return {
    attendance_record,
    loadShifts,
    shifts,
    shiftsDetail,
    resetShifts,
    fetching,
  }
}

export default useEmployeeAttendanceShifts
