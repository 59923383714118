import React from 'react'
import i18n from 'translations/i18n'
import { isEmpty } from 'lodash'
import { useSelector } from 'utils/hooks'
import { Flex, Badge, FileUpload } from '@jisr-hr/ds-beta'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { useRequest } from '../../RequestProvider'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { ReactComponent as MoneyIcon } from '../../icons/Money.svg'
import { requestDateFormat } from '../../helper'
import TicketInformation from './LeaveRequestTask/TicketInformation'
import useTaskFooter from '../../Footer/useTaskFooter'

const BusinessTripRequestTask = () => {
  const { onAttachTaskFile } = useTaskFooter()
  const { employee } = useSelector(({ auth }) => auth)
  const {
    vars: { request },
  } = useRequest()
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          created_at: request?.request?.created_time,
          due_date: request.due_date,
          requested_on: request?.request?.created_time,
          type_i18n: request.name,
          payment_date: request?.task_info?.paid_at,
          metadata: request?.metadata,
          items: [
            {
              icon: <CalendarIcon />,
              label: i18n.t('leave_date'),
              value: requestDateFormat(request.task_info?.leave_date),
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('return_date'),
              value: requestDateFormat(request.task_info?.return_on),
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('approved_date'),
              value: requestDateFormat(request.task_info?.approved_at),
            },
            {
              label: i18n.t('per_diem_total'),
              value: request.task_info?.per_diem_total,
            },
            {
              label: i18n.t('total_amount'),
              value: request.task_info?.total_amount,
            },
            {
              label: i18n.t('destination'),
              value: request.task_info?.city_name,
            },
            {
              label: i18n.t('number_of_days'),
              value: request.task_info?.number_of_days,
            },
            !isEmpty(request?.task_info?.additions) && {
              label: i18n.t('additional_services'),
              value: (
                <Flex
                  flexWrap
                  style={{ gap: 8, justifyContent: 'flex-end' }}
                >
                  {request?.task_info?.additions?.map((item, i) => (
                    <Badge
                      key={i}
                      label={item.text}
                      size="small"
                      color="gray"
                      variant="tinted"
                    />
                  ))}
                </Flex>
              ),
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('payment_type'),
              value: request.task_info?.payment_details?.payment_type_i18n,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_name'),
              value: request.task_info?.payment_details?.bank_name_i18n,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_iban'),
              value: request.task_info?.payment_details?.iban,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('salary_type'),
              value: request.task_info?.payment_details?.salary_type_i18n,
            },
          ],
        }}
        // showColumns={['additional_services']}
      />
      {(request?.request?.reason || request?.request?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.request?.reason,
            attachments: request?.request?.attachments,
          }}
        />
      )}
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}

      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <TicketInformation />
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default BusinessTripRequestTask
