/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

export type JobOpeningTypeProps = {
  filterKey: string
  mixPanelEventName?: string
  filters: string[]
}

const JobOpeningType = (props: JobOpeningTypeProps): JSX.Element => {
  const { filterKey, mixPanelEventName } = props
  const { job_openings } = useFilterAPI()
  const { handleFilter, filter } = useFilters()

  return (
    <FilterBox
      title={i18n.t('job_opening_filter_title')}
      handleFilter={handleFilter}
      mixPanelEventName={mixPanelEventName}
      filterKey={filterKey}
      selectedIds={filter[filterKey]}
      options={job_openings?.map((openJob: any) => {
        return {
          id: openJob.id,
          label: openJob.title_i18n,
          name: openJob.title_i18n,
          name_i18n: openJob.title_i18n,
          testId: openJob.title_i18n,
        }
      })}
      filters={props.filters}
    />
  )
}
export default memo(JobOpeningType)
