import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

export type SapBusinessOneAccountInfoType = {
  companydb: string
  password: string
  config: {
    sync_employees_information: boolean
  }
  sync_employees_information?: boolean
  url: string
  username: string
}

type InitStateType = {
  account: SapBusinessOneAccountInfoType | null
  connected: boolean
  err_msg: string
  fetching: boolean
  loading: boolean
  statusFetching: boolean
}

const initialState: InitStateType = {
  fetching: false,
  loading: false,
  connected: false,
  account: null,
  err_msg: '',
  statusFetching: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.SYNC_SAP_BUSINESSES_EMPLOYEE_PENDING:
    case actions.SYNC_SAP_BUSINESSES_DIMENSION_PENDING:
    case actions.SAP_BUSINESSES_DISCONNECT_ACCOUNT_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.SAP_BUSINESSES_CONNECT_ACCOUNT_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.SAP_BUSINESSES_CONNECT_ACCOUNT_FULFILLED:
      return {
        ...state,
        loading: false,
      }

    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_INFO_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_PENDING:
      return {
        ...state,
        statusFetching: true,
      }
    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_INFO_FULFILLED:
      return {
        ...state,
        fetching: false,
        account: payload.data.data.account || {},
      }

    case actions.SYNC_SAP_BUSINESSES_EMPLOYEE_FULFILLED:
    case actions.SYNC_SAP_BUSINESSES_DIMENSION_FULFILLED:
    case actions.SAP_BUSINESSES_DISCONNECT_ACCOUNT_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
        connected: false,
      }
    }

    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_FULFILLED: {
      return {
        ...state,
        statusFetching: false,
        connected: payload.data.data?.connected || false,
      }
    }
    case actions.SYNC_SAP_BUSINESSES_EMPLOYEE_REJECTED:
    case actions.SYNC_SAP_BUSINESSES_DIMENSION_REJECTED:
    case actions.SAP_BUSINESSES_DISCONNECT_ACCOUNT_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_INFO_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SAP_BUSINESSES_CONNECT_ACCOUNT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }

    case actions.SAP_BUSINESSES_LOAD_ACCOUNT_STATUS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        statusFetching: false,
      }
    }
    default:
      return state
  }
}
