import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
  submitting: false,
}

type CustomRequestsState = typeof DEFAULT_STATE

export default function customRequestsReducer(
  state = DEFAULT_STATE,
  action: Action,
): CustomRequestsState {
  switch (action.type) {
    case actions.CUSTOM_REQUESTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CUSTOM_REQUESTS_CREATE_PENDING:
    case actions.CUSTOM_REQUESTS_UPDATE_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.CUSTOM_REQUESTS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.CUSTOM_REQUESTS_CREATE_FULFILLED:
    case actions.CUSTOM_REQUESTS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        submitting: false,
      }

    case actions.CUSTOM_REQUESTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.CUSTOM_REQUESTS_CREATE_REJECTED:
    case actions.CUSTOM_REQUESTS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
