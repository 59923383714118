import { Divider, Flex, Icon, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useState } from 'react'
import { Select } from '@jisr-hr/design-system'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ExportTypeExportHistoryTypes = {
  filterKey: string
  filters: string[]
  title: string
}

type OptionType = {
  name: string
  id: string
}

const ExportTypeExportHistory = (props: ExportTypeExportHistoryTypes): JSX.Element => {
  const { filters, title, filterKey } = props
  const [show, setShow] = useState(true)
  const { export_type_export_history } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const onHandleFilter = (val: OptionType): void => {
    setFilter({
      ...filter,
      export_type: val.id,
    })
  }

  return (
    <>
      <div className="flex flex-col px-6 py-4 gap-4">
        <Flex
          justifyBetween
          style={{ cursor: 'pointer', marginBottom: 16 }}
          onClick={(): void => setShow(!show)}
        >
          <Typography
            text={i18n.t('label.report_type')}
            variant="interface/strong/m"
          />
          <Icon
            name={show ? 'chevron-up' : 'chevron-down'}
            styles={{ width: 24, height: 24, color: 'var(--color-base-colors-grey-500)' }}
          />
        </Flex>
        {show && (
          <Select
            onChange={onHandleFilter}
            value={export_type_export_history.find(
              (it: OptionType) => it.id === filter[props.filterKey],
            )}
            getOptionValue={(option: OptionType): string => option.id}
            getOptionLabel={(option: OptionType): string => option.name}
            options={export_type_export_history}
            placeholder="Select Report type"
            maxMenuHeight={200}
          />
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </>
  )
}

export default ExportTypeExportHistory
