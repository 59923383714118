import { Button, Radio, Tag, Typography } from '@jisr-hr/ds'
import { ListItem, Dropdown, Tooltip } from '@jisr-hr/ds-beta'
import { format } from 'utils/date'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import { useActionTaskRequest } from 'apis/OrgManagement/requests/useRequests'
import { TReqLinkedTask } from 'redux/attendance/timesheets/requestDetails/typeReducer'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { mixPanelAnalytics } from 'utils/mixpanel'
import TaskConfirmModal from './TaskConfirmModal'

type PropsType = {
  list: TReqLinkedTask[]
}

type OptionType = {
  value: 'mark_as_completed' | 'view_task' | 'reject_task'
  text: string
  destructive?: boolean
  leadingIcon: string
  isDisabled?: boolean
  testId: string
}

const CreatedTasks = (props: PropsType): JSX.Element => {
  const { list = [] } = props

  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  const employee = useSelector((s) => s.auth.employee)
  const dispatch = useDispatch()

  const history = useHistory()

  const { mutateAsync: onActionTask, isLoading } = useActionTaskRequest()

  const [confirm, setConfirm] = useState<{
    open: string
    task_id: number
  } | null>(null)
  const [openPopper, setOpenPopper] = useState<string | null>(null)

  const options: OptionType[] = [
    {
      value: 'mark_as_completed',
      text: i18n.t('label.mark_task_as_completed'),
      leadingIcon: 'check',
      testId: 'mark_as_completed',
    },
    {
      value: 'view_task',
      text: i18n.t('label.view_task'),
      leadingIcon: 'arrow-narrow-up-right',
      testId: 'view_task',
    },
    {
      value: 'reject_task',
      text: i18n.t('label.reject_task'),
      destructive: true,
      leadingIcon: 'x',
      testId: 'reject_task',
    },
  ]

  const handleRadioChange = (val: string): void => {
    setOpenPopper(val)
  }

  const handleMarkAsCompleted = async (task: TReqLinkedTask): Promise<void> => {
    if (
      [
        'loan_payment_task',
        'advance_vacation_salary_payment_task',
        'final_settlement_payment_task',
        'expense_claim_payment_task',
        'business_trip_payment_task',
      ].includes(`${task.category}`) &&
      task.can_mark_as_completed
    ) {
      setOpenPopper(null)
      setConfirm({ open: 'paid_at', task_id: task.id })
    } else {
      await onActionTask({ taskId: task.id, status: 'complete' })
      await dispatch(
        requestDetailsLoad(details?.employee?.id as number, {
          request_id: details?.id as number,
        }),
      )
      setOpenPopper(null)
      setConfirm(null)
    }
  }

  const handelMixedPanel = (event_name: string, taskId: number, option?: string): void => {
    mixPanelAnalytics({
      event_name,
      properties: {
        Request_ID: details?.id,
        Task_ID: taskId,
        User_ID: employee?.id,
        ...(option && option !== 'complete' && { Option_Selected: option }),
      },
    })
  }

  const handelOnClickItem = async (
    val: 'view_task' | 'mark_as_completed' | 'reject_task',
    task: TReqLinkedTask,
  ): Promise<void> => {
    if (val === 'view_task') {
      handelMixedPanel('Task_Option_Selected', task.id, 'view')
      history.push('/setting_v2/workflow/tasks')
    } else if (val === 'mark_as_completed') {
      handelMixedPanel('Mark_Task_Completed', task.id, 'complete')
      handleMarkAsCompleted(task)
    } else if (val === 'reject_task') {
      handelMixedPanel('Task_Option_Selected', task.id, 'reject')
      setConfirm({ open: 'reject', task_id: task.id })
    }
  }

  const handelPaymentDate = async (paid_at: string | null): Promise<void> => {
    if (confirm?.task_id && paid_at) {
      await onActionTask({ taskId: confirm?.task_id, status: 'complete', paid_at })
      await dispatch(
        requestDetailsLoad(details?.employee?.id as number, { request_id: details?.id as number }),
      )
      setOpenPopper(null)
      setConfirm(null)
    }
  }

  const handelRejectionTask = async (comment: string): Promise<void> => {
    if (confirm?.task_id) {
      await onActionTask({ taskId: confirm?.task_id, status: 'reject', rejectionReason: comment })
      await dispatch(
        requestDetailsLoad(details?.employee?.id as number, { request_id: details?.id as number }),
      )
      setOpenPopper(null)
      setConfirm(null)
    }
  }

  return (
    <>
      {list.map((i) => (
        <div
          className="flex justify-between mb-2 items-center"
          key={i.id}
        >
          <Tooltip
            behavior="popover"
            disabledFocus
            placement="top-start"
            maxWidth="260px"
            open={openPopper === i.organization_task.name}
            onClose={(): void => setOpenPopper(null)}
            trigger={
              <Radio
                label={i.organization_task.name_i18n}
                description={`${format(i.created_at, 'dd MMM, yyyy')}`}
                value={i.organization_task.name}
                disabled={!i.can_mark_as_completed || i.status !== 'Pending'}
                active={i.status === 'Completed' || i.status === 'Rejected'}
                onChange={(e): void => handleRadioChange(e.target.value)}
              />
            }
          >
            <div className="h-[100%] flex flex-col">
              <div className="mb-4">
                <Typography
                  variant="body-new/semibold"
                  text={i18n.t('label.mark_task_as_completed')}
                  textColor="color/fg/default"
                  className="mb-1"
                />
                <Typography
                  variant="body-new/regular"
                  text={i18n.t(
                    'communication.description.mark_task_as_completed_popover_description',
                  )}
                />
              </div>
              <div className="flex flex-grow gap-2 items-center justify-end">
                <Button
                  label={i18n.t('label.discard')}
                  onClick={(): void => setOpenPopper(null)}
                  size="small"
                  variant="ghost"
                  disabled={isLoading}
                  color="neutral"
                />
                <Button
                  label={i18n.t('label.confirm')}
                  size="small"
                  disabled={isLoading}
                  onClick={(): Promise<void> => handleMarkAsCompleted(i)}
                />
              </div>
            </div>
          </Tooltip>
          <div className="flex items-center">
            <Tag
              label={i.assignee.name_i18n}
              type="stroke"
              closeIcon={false}
            />
            <Dropdown
              dropDownElement={
                <Button
                  iconOnly
                  variant="ghost"
                  color="neutral"
                  size="small"
                  leadingIcon="dots-vertical"
                  onClick={handelMixedPanel.bind(null, 'Task_Menu_Opened', i.id, '')}
                  testId="dropdown"
                />
              }
              position="bottom-end"
            >
              {options.map((listItem, index) => {
                return (
                  <ListItem
                    // destructive={listItem.textColor === 'color/fg/danger/default'}
                    destructive={listItem?.destructive}
                    {...listItem}
                    onClick={(): Promise<void> => handelOnClickItem(listItem.value, i)}
                    key={index}
                    {...(listItem.value !== 'view_task' && {
                      isDisabled: !i.can_mark_as_completed || i.status !== 'Pending' || isLoading,
                    })}
                  />
                )
              })}
            </Dropdown>
          </div>
        </div>
      ))}
      <TaskConfirmModal
        confirm={confirm}
        onClose={(): void => setConfirm(null)}
        handelPaymentDate={handelPaymentDate}
        handelRejectionTask={handelRejectionTask}
        disabled={isLoading}
      />
    </>
  )
}

export default CreatedTasks
