import Language from '../Language'
import VerifyUser from '../VerifyUser'

const MicroTemplete = ({ language }: { language: boolean }): JSX.Element => {
  const showPinCode = btoa(sessionStorage.getItem(atob('pincode')) || '')

  return (
    <>
      {showPinCode?.length !== 4 && <VerifyUser />}
      {language && <Language />}
    </>
  )
}

export default MicroTemplete
