import {
  DatePickerDSField,
  SelectField,
  BetaTextInputField as TextInputField,
} from 'components/final-form'
import { required, composeValidators, naturalNumber } from 'components/global/form/FormValidations'
import { positiveInteger } from 'components/global/form/formNormalize'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Typography, FileUpload, FileInfo } from '@jisr-hr/ds'
import { getPublicTemplates } from 'redux/publicTemplates/actionCreators'
import { getPublicFilterKeys } from 'components/Filter/helper'
import { filters } from 'containers/authorised/ATS/organisms/JobControlFilters/constants'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import { useForm, useFormState } from 'react-final-form'
import { useEffect, useState } from 'react'
import styles from './HiringDetails.module.css'
import Frame from '../../../ds/Frame'

type TitleWithOptionalTextProps = {
  title: string
  optionalText: string
}

export const TitleWithOptionalText = ({
  title,
  optionalText,
  ...props
}: TitleWithOptionalTextProps): JSX.Element => {
  return (
    <Flex className={styles.title}>
      <Typography
        text={title}
        variant="subtitle-2"
        textColor="color/fg/bold"
        {...props}
      />
      <Typography
        text={optionalText}
        variant="caption"
        textColor="color/fg/lighter"
        {...props}
      />
    </Flex>
  )
}

const HiringDetails = (): JSX.Element => {
  const { values } = useFormState()
  const [initFile, setInitFile] = useState<FileInfo[] | undefined>([])
  const dispatch = useDispatch()
  const { department, location, business_unit, employment_type } = useSelector(
    ({ publicTemplates }) => publicTemplates,
  )
  const { employee_all } = useSelector(({ employee }) => employee)
  const { change } = useForm()
  useEffect(() => {
    dispatch(
      getPublicTemplates({
        filters: getPublicFilterKeys(filters, {}, false),
      }),
    )
    dispatch(
      getAllEmployeesLoad({
        filter: {
          permission_name: 'manage_applicant_tracking_system',
        },
      }),
    )
  }, [])

  useEffect(() => {
    if (values?.attachments?.length) {
      const files = values.attachments?.map(
        (file: FileInfo & { url: string; data: string; filename: string }) => ({
          id: file.id,
          name: file.name ?? file.filename,
          file: file.url ?? file.data,
          size: file.size,
        }),
      )
      setInitFile(files)
    }
  }, [values.attachments])

  return (
    <Frame title={I18n.t('label.request_details')}>
      <Flex
        flexCol
        className={styles.requestContent}
      >
        <Flex className={styles.requestContent}>
          <Flex
            flexCol
            className={styles.jobTitle}
          >
            <Typography
              text={I18n.t('label.job_title_english')}
              variant="subtitle-2"
              textColor="color/fg/bold"
            />
            <TextInputField
              name="title_en"
              validate={required}
              testId="request_title_en"
            />
          </Flex>
          <Flex
            flexCol
            className={styles.jobTitle}
          >
            <Typography
              text={I18n.t('label.job_title_arabic')}
              variant="subtitle-2"
              textColor="color/fg/bold"
            />
            <TextInputField
              name="title_ar"
              validate={required}
              testId="request_title_ar"
            />
          </Flex>
        </Flex>
        <Flex
          flexCol
          className={styles.jobTitle}
        >
          <Typography
            text={I18n.t('label.no._of_vacancies')}
            variant="subtitle-2"
            textColor="color/fg/bold"
          />
          <TextInputField
            name="no_of_vacancies"
            validate={composeValidators(required, naturalNumber)}
            parse={positiveInteger}
            testId="request_no_of_vacancies"
          />
        </Flex>
        <DatePickerDSField
          name="start_date"
          label={I18n.t('label.expected_start_date')}
          textFieldProps={{
            optionalLabel: I18n.t('label.optional'),
          }}
          calenderProps={{
            minDate: new Date(),
          }}
        />
        <SelectField
          // @ts-expect-error // select field needs to be migrated to TS
          label={
            <TitleWithOptionalText
              title={I18n.t('label.location')}
              optionalText={I18n.t('label.optional')}
            />
          }
          name="location_id"
          valueKey="id"
          labelKey="address_i18n"
          options={location}
          maxMenuHeight={250}
          menuPlacement="bottom"
          menuPosition="absolute"
        />
        <SelectField
          // @ts-expect-error // select field needs to be migrated to TS
          label={
            <TitleWithOptionalText
              title={I18n.t('label.job_type')}
              optionalText={I18n.t('label.optional')}
            />
          }
          name="employment_type_id"
          valueKey="id"
          labelKey="name_i18n"
          options={employment_type}
          maxMenuHeight={250}
          menuPlacement="bottom"
          menuPosition="absolute"
        />
        <Flex className={styles.requestContent}>
          <div className={styles.selected}>
            <SelectField
              // @ts-expect-error // select field needs to be migrated to TS
              label={
                <TitleWithOptionalText
                  title={I18n.t('label.business_unit')}
                  optionalText={I18n.t('label.optional')}
                />
              }
              name="business_unit_id"
              valueKey="id"
              labelKey="name_i18n"
              options={business_unit}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPosition="absolute"
            />
          </div>
          <div className={styles.selected}>
            <SelectField
              // @ts-expect-error // select field needs to be migrated to TS
              label={
                <TitleWithOptionalText
                  title={I18n.t('label.department')}
                  optionalText={I18n.t('label.optional')}
                />
              }
              name="department_id"
              valueKey="id"
              labelKey="name_i18n"
              options={department}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPosition="absolute"
            />
          </div>
        </Flex>
        <SelectField
          // @ts-expect-error // select field needs to be migrated to TS
          label={
            <TitleWithOptionalText
              title={I18n.t('label.hiring_manager')}
              optionalText={I18n.t('label.optional')}
            />
          }
          name="hiring_manager_id"
          valueKey="id"
          labelKey="name"
          options={employee_all}
          maxMenuHeight={250}
          menuPlacement="bottom"
          menuPosition="absolute"
        />
        <Flex flexCol>
          <TextInputField
            as="textarea"
            name="description"
            label={I18n.t('label.job_description')}
            optionalLabel={I18n.t('label.optional')}
          />
        </Flex>

        <FileUpload
          variant="version2"
          accept={{
            acceptedFiles: ['.doc, .docx, .pdf, image/*'],
          }}
          initFiles={initFile}
          onLoad={(files: FileInfo | FileInfo[] | null): void => {
            if (Array.isArray(files)) {
              setInitFile(files)
              const attachments = files.map((val) => ({
                data: val?.file,
                filename: val?.name,
                ...(typeof val?.id === 'number' && { id: val?.id }),
              }))
              change('attachments', attachments)
            }
          }}
          onRemove={(file: FileInfo): void => {
            setInitFile([])
            if (typeof file?.id === 'number') {
              change('deletedAttachments', [
                ...values?.deletedAttachments,
                { ...file, delete: true },
              ])
            }
          }}
          multiple
          keepPrevious
          hasDeleteButton
          errorMessage="Unable to upload file"
          loadingMessage="Uploading file"
          message={
            <TitleWithOptionalText
              title={I18n.t('label.add_attachement')}
              optionalText={I18n.t('label.optional')}
            />
          }
          successMessage={I18n.t('file_selected')}
          fileType=""
        />
      </Flex>
    </Frame>
  )
}

export default HiringDetails
