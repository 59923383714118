export const RESET = 'RESET'

export const FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD = 'FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD'
export const FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_PENDING =
  'FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_PENDING'
export const FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_FULFILLED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_FULFILLED'
export const FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_REJECTED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_REJECTED'

export const FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE = 'FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_PENDING =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_PENDING'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_FULFILLED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_FULFILLED'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_REJECTED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_REJECTED'

export const FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE = 'FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_PENDING =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_PENDING'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_FULFILLED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_FULFILLED'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_REJECTED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_REJECTED'

export const FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE = 'FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_PENDING =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_PENDING'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_FULFILLED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_FULFILLED'
export const FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_REJECTED =
  'FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_REJECTED'
