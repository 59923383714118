import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TBody = {
  organization_configuration: { value: string }
}

export function configurationsLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization_configurations/language_preference`

  return {
    type: actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function configurationsUpdate(body: TBody): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization_configurations/language_preference`

  return {
    type: actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT,
    payload: handleAPI(url, {}, 'PUT', body),
  }
}

export function employeeConfigurationsLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employees/${id}/configurations/language_preference`

  return {
    type: actions.ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function employeeConfigurationsUpdate(value: 'en' | 'ar', id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employees/${id}/configurations/language_preference`

  return {
    type: actions.ONBOARDING_EMPLOYEE_ORGANIZATION_CONFIGURATIONS_PUT,
    payload: handleAPI(url, {}, 'PUT', { employee_configuration: { value } }),
  }
}
