export const VACATION_SALARY_CALCULATION_LOAD = 'VACATION_SALARY_CALCULATION_LOAD'
export const VACATION_SALARY_CALCULATION_LOAD_PENDING = 'VACATION_SALARY_CALCULATION_LOAD_PENDING'
export const VACATION_SALARY_CALCULATION_LOAD_FULFILLED =
  'VACATION_SALARY_CALCULATION_LOAD_FULFILLED'
export const VACATION_SALARY_CALCULATION_LOAD_REJECTED = 'VACATION_SALARY_CALCULATION_LOAD_REJECTED'

export const VACATION_SALARY_CALCULATION_UPDATE = 'VACATION_SALARY_CALCULATION_UPDATE'
export const VACATION_SALARY_CALCULATION_UPDATE_PENDING =
  'VACATION_SALARY_CALCULATION_UPDATE_PENDING'
export const VACATION_SALARY_CALCULATION_UPDATE_FULFILLED =
  'VACATION_SALARY_CALCULATION_UPDATE_FULFILLED'
export const VACATION_SALARY_CALCULATION_UPDATE_REJECTED =
  'VACATION_SALARY_CALCULATION_UPDATE_REJECTED'

export const ONBOARDING_BENEFIT_TYPES_LOAD = 'ONBOARDING_BENEFIT_TYPES_LOAD'
export const ONBOARDING_BENEFIT_TYPES_LOAD_PENDING = 'ONBOARDING_BENEFIT_TYPES_LOAD_PENDING'
export const ONBOARDING_BENEFIT_TYPES_LOAD_FULFILLED = 'ONBOARDING_BENEFIT_TYPES_LOAD_FULFILLED'
export const ONBOARDING_BENEFIT_TYPES_LOAD_REJECTED = 'ONBOARDING_BENEFIT_TYPES_LOAD_REJECTED'
