/*
  Radio: React Component that conver Material-UI checkbox
  to radio input.
  usage: use this component to make a radio input

  @return: value of selected option
  @props:
    - onChange: callback function.
    - options: array of the opptions
*/
import React from 'react'
import PropTypes from 'prop-types'

const isTrueSet = (value) => value === 'true'

const HOC = (WrappedComponent) => {
  class Radio extends React.Component {
    state = { value: null }

    // sync the value from props to state
    static getDerivedStateFromProps(props) {
      if (props.value) return { value: props.value }

      // [fix] clear value in rest form otherways will retrun previous value state
      return {
        value: null,
      }
    }

    // handle on change the value
    handleChange = (value) => {
      const { boolValue } = this.props
      this.setState({ value })
      if (this.state.value !== value) {
        this.props.onChange(boolValue ? isTrueSet(value) : value)
      }
    }

    handleOptions = () => {
      const { options, labelKey, valueKey } = this.props
      if (!valueKey) return options
      if (!options) return []

      const temp = []
      options.map((option) => {
        if (option && option !== null) {
          temp.push({
            value: `${option[valueKey]}`,
            label: option[labelKey],
          })
        }
        return true
      })

      return temp
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          options={this.handleOptions()}
          value={this.state.value}
          handleChange={this.handleChange}
        />
      )
    }
  }

  Radio.propTypes = {
    onChange: PropTypes.func,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    options: PropTypes.arrayOf(Object),
    boolValue: PropTypes.bool,
  }

  Radio.defaultProps = {
    onChange: () => {},
  }
  return Radio
}

export default HOC
