const SSO = {
  // C
  config_sso: 'Configure SSO',
  copy_caps: 'Copy',
  certificate_SP: 'Certificate of SP',
  certification_of_idp: 'Certificate of IDP',

  // E
  enter_url_here: 'Enter the URL here',

  // I
  identity_provider_entity_id: 'Identity provider entity ID',
  idp_target_url: 'IDP target URL',
  idp_slo_service_url: 'IDP SLO Service URL',

  // P
  provide_the_following: 'Provide the following',
  pass_certificate_content: 'Paste certificate content here',

  // S
  sso_configuration: 'SSO Configuration',
  sso_desc: 'Manual SSO Configuration ',
  sp_redirection_endpoint: 'SP Redirection Endpoint URL',
  service_provider_entity_id: 'Service provider entity ID',
  sign_hash_algorithm: 'Signature hash algorithm',
  sha_1: 'SHA-1',
  sha_256: 'SHA-256',
  sha_512: 'SHA-512',
  save_configuration: 'Save Configuration',
  save_enable_configuration: 'Save and Enable Configuration',
}

export default SSO
