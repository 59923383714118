import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function applicableRequestsLoad(id) {
  const url = `/${API_VERSION_TWO}/employees/${id}/applicable_requests`
  const method = 'GET'
  return {
    type: 'APPLICABLE_REQUESTS_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function applicableRequestTypesLoad(id) {
  const url = `/${API_VERSION_TWO}/employees/${id}/applicable_request_types`
  const method = 'GET'
  return {
    type: 'APPLICABLE_REQUESTS_TYPES_LOAD',
    payload: handleAPI(url, {}, method),
  }
}
