import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import I18n from 'translations/i18n'
import { Typography, Flex, Spacer, Banner } from '@jisr-hr/ds'
import { ReactComponent as ArrowIcon } from 'components/global/templates/RequestDrawer/components/RecordChanges/icons/Arrow.svg'
import { leaveGrantTypeLoad } from 'redux/actions/leaveManagementConfigurationActions'
import { hasPermission } from 'components/global/HelperFunctions'

import { isValid } from 'date-fns'
import { format } from 'utils/date'
import { isRtl } from 'utils/uiHelpers'

import LeaveBalanceTemplate from '../forms/AnnualLeave/LeaveBalanceTemplate'
import Frame from '../../ds/Frame'
import { formatMonth } from '../helper'
import styles from './styles.module.css'

const LeaveDuration = ({
  title,
  from,
  to,
  showDetails,
  leave_type_id,
  applicable_days,
  unscheduled_dates,
  deducted_balance,
  isAnnual,
  employeeId,
  returnDate,
  isHalfDayLeave,
  balanceVals,
}) => {
  const dispatch = useDispatch()
  const { grant_configuration } = useSelector(({ leaveConfiguration }) => leaveConfiguration)
  const { employee } = useSelector(({ auth }) => auth)

  const { errorMessage, fetching } = useSelector(({ leaveRequests }) => leaveRequests)
  const showUnscheduled =
    !_.isEmpty(unscheduled_dates) && grant_configuration?.value === 'Business' && isAnnual
  const viewShiftSchedule = hasPermission(
    employee,
    employeeId,
    employee?.permission_scopes?.view_shift_schedule,
  )

  const daysTranslation = (days) => {
    if (days <= 1) {
      return I18n.t('day')
    }

    return I18n.t('days')
  }

  React.useEffect(() => {
    if (employeeId) {
      dispatch(leaveGrantTypeLoad({ employee_id: employeeId }))
    }
  }, [employeeId])

  if ((!from || !to || !leave_type_id || !applicable_days) && !showDetails) {
    return null
  }

  let frameTitle = title || I18n.t('leave_duration')
  frameTitle = returnDate ? I18n.t('return_date') : frameTitle

  const showContent =
    (!errorMessage && !!showDetails) ||
    (isAnnual && deducted_balance > 0) ||
    !!errorMessage ||
    showUnscheduled

  return (
    <Frame
      title={frameTitle}
      titleSuffix={
        !returnDate && (
          <Typography
            text={`${applicable_days} ${daysTranslation(applicable_days)}`}
            variant="body-new/semibold"
            textColor="color/fg/lighter"
          />
        )
      }
    >
      {returnDate && (
        <Flex
          justifyBetween
          itemsCenter
          className={styles.returnDate}
        >
          <Flex
            flexCol
            itemsCenter
            justifyCenter
            className={styles.details}
          >
            <Typography
              text={from && format(new Date(returnDate), 'd')}
              variant="title-1"
            />
            <Typography
              text={from && format(new Date(returnDate), `${formatMonth} yyyy`)}
              variant="body-new/regular"
              textColor="color/fg/brand/bold"
            />
          </Flex>
        </Flex>
      )}

      {returnDate && (
        <Flex
          justifyBetween
          itemsCenter
          className={styles.durationTitle}
        >
          <Typography
            text={I18n.t('leave_duration')}
            variant="title-1"
          />
          <Typography
            text={`${applicable_days} ${daysTranslation(applicable_days)}`}
            variant="subheading"
            textColor="color/fg/lighter"
          />
        </Flex>
      )}

      {showContent && (
        <Flex
          flexCol
          style={{ gap: 16 }}
        >
          {!errorMessage && !!showDetails && (
            <Flex
              justifyCenter
              itemsCenter
            >
              <Flex
                flexCol
                itemsCenter
                justifyCenter
                className={styles.details}
              >
                <Typography
                  text={from && format(new Date(from), 'd')}
                  variant="title-1"
                />
                <Typography
                  text={from && format(new Date(from), `${formatMonth} yyyy`)}
                  variant="subheading"
                  textColor="color/fg/brand/default"
                />
              </Flex>
              {!isHalfDayLeave && (
                <>
                  <Spacer width={8} />
                  <ArrowIcon className={isRtl && styles.rotateIcon} />
                  <Spacer width={8} />
                  <Flex
                    flexCol
                    itemsCenter
                    justifyCenter
                    className={styles.details}
                  >
                    <Typography
                      text={to && format(new Date(to), 'd')}
                      variant="title-1"
                    />
                    <Typography
                      text={to && format(new Date(to), `${formatMonth} yyyy`)}
                      variant="subheading"
                      textColor="color/fg/brand/default"
                    />
                  </Flex>
                </>
              )}
            </Flex>
          )}

          {isAnnual && deducted_balance > 0 && (
            <>
              <Flex
                justifyBetween
                itemsCenter
                style={
                  returnDate || showDetails
                    ? { borderTop: '1px solid #E8EBEE', paddingTop: '16px' }
                    : {}
                }
              >
                <Typography
                  text={I18n.t('deducted_balance')}
                  variant="title-1"
                />
                <Typography
                  text={`${deducted_balance} ${daysTranslation(deducted_balance)}`}
                  variant="subheading"
                  textColor="color/fg/brand/default"
                />
              </Flex>
            </>
          )}

          {!!errorMessage && !fetching && (
            <Banner
              variant="tinted"
              color="danger"
              leadingIcon="x-circle-filled"
              description={errorMessage}
            />
          )}

          {showUnscheduled && (
            <Banner
              variant="tinted"
              color="info"
              leadingIcon="info-circle-filled"
              title={I18n.t('unscheduled_days')}
              description={I18n.t('unscheduled_days_within_selected_period', {
                count: unscheduled_dates?.length,
              })}
              learnMore={viewShiftSchedule ? I18n.t('view_in_scheduler') : undefined}
              onClickLearnMore={() => {
                window.open(
                  `#/scheduler?id=${employeeId}&&date=${format(
                    isValid(new Date()) ? new Date(unscheduled_dates?.[0]) : new Date(),
                    'yyyy-MM-dd',
                  )}`,
                  '_blank',
                )
              }}
            />
          )}
        </Flex>
      )}

      {!!to && !!balanceVals && (
        <>
          {(!!returnDate || showContent) && <Spacer height={16} />}
          <LeaveBalanceTemplate {...balanceVals} />
        </>
      )}
    </Frame>
  )
}

LeaveDuration.propTypes = {
  employeeId: PropTypes.number,
  title: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  leave_type_id: PropTypes.number,
  applicable_days: PropTypes.number,
  deducted_balance: PropTypes.number,
  showDetails: PropTypes.bool,
  isAnnual: PropTypes.bool,
  balanceVals: PropTypes.shape(),
  unscheduled_dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  returnDate: PropTypes.string,
  isHalfDayLeave: PropTypes.string,
}

export default LeaveDuration
