import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  fetching: false,
}

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case actions.LEAVE_EXTENSIONS_POST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LEAVE_EXTENSIONS_POST_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.LEAVE_EXTENSIONS_POST_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
