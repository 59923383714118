import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const initialState = {
  employee_leave_days: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_LEAVE_DAYS_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.EMPLOYEE_LEAVE_DAYS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEE_LEAVE_DAYS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.EMPLOYEE_LEAVE_DAYS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        employee_leave_days: [...state.employee_leave_days, payload.data.data.employee_leave_day],
        fetching: false,
      }

    case actions.EMPLOYEE_LEAVE_DAYS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        employee_leave_days: updateOneListOfRecords(
          state.employee_leave_days,
          payload.data.data.employee_leave_day,
        ),
        fetching: false,
      }

    case actions.EMPLOYEE_LEAVE_DAYS_LOAD_REJECTED:
    case actions.EMPLOYEE_LEAVE_DAYS_CREATE_REJECTED:
    case actions.EMPLOYEE_LEAVE_DAYS_UPDATE_REJECTED:
    case actions.EMPLOYEE_LEAVE_DAYS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
