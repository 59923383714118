import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@jisr-hr/ds'
import { useForm } from 'react-final-form'
import { isRtl } from 'utils/uiHelpers'
import Frame from '../../../ds/Frame'
import ProfileAndTime from '../../../ds/ProfileAndTime'
import { useRequest } from '../../../RequestProvider'

const Profile = ({ employee }) => {
  const { setEmployeeId, setEmployee, setRequestType } = useRequest()

  const { reset } = useForm()
  const handleClick = () => {
    setEmployee({})
    setEmployeeId(null)
    setRequestType(null)
    reset()
  }

  if (!employee?.name || !employee?.name_ar) return null

  return (
    <Frame>
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        <ProfileAndTime
          onProfileClick={handleClick}
          name={isRtl ? employee?.name_ar : employee?.name}
          code={employee?.code}
          jobTitle={employee?.job_title?.name_i18n}
          avatar={employee?.avatar_thumb}
        />
      </Flex>
    </Frame>
  )
}

Profile.propTypes = {
  employee: PropTypes.shape(),
}

export default Profile
