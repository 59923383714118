import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'

import Record from '../Record'

const Deductable = ({ data }) => (
  <Record
    title={I18n.t('deductable')}
    label={<DeductableText value={data.from} />}
    value={<DeductableText value={data.to} />}
  />
)

const DeductableText = ({ value }) => {
  const color = value > 0 ? 'var(--color-base-colors-red-600)' : 'var(--color-base-colors-grey-500)'
  const minutes = value ? `${value} ${I18n.t('min')}` : 0

  return (
    <Typography
      text={minutes || '--'}
      variant="body-new/semibold"
      style={{ color }}
      textColor="color/fg/brand/default"
    />
  )
}

DeductableText.propTypes = {
  value: PropTypes.string,
}

Deductable.propTypes = {
  data: PropTypes.shape(),
}

export default Deductable
