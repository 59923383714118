import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export type TGeneralJobTitle = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
  category_id?: number
}

export type TJobTitle = Omit<TGeneralJobTitle, 'name_en'> & {
  name: string
  description: string
}

type TInitState = {
  fetching: boolean
  name: string
  name_ar: string
  job_titles: TJobTitle[]
  job_title_suggestions: TGeneralJobTitle[]
}

export const initialState: TInitState = {
  fetching: false,
  name: '',
  name_ar: '',
  job_titles: [],
  job_title_suggestions: [],
}

export default function reducer(state = initialState, action: Action): TInitState {
  switch (action.type) {
    case actions.ONBOARDING_JOB_TITLES_LOAD_PENDING:
    case actions.ONBOARDING_JOB_TITLES_POST_PENDING:
    case actions.ONBOARDING_JOB_TITLES_SHOW_PENDING:
    case actions.ONBOARDING_JOB_TITLE_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_JOB_TITLES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        job_titles: action.payload.data.data.job_titles,
        fetching: false,
      }

    case actions.ONBOARDING_JOB_TITLES_SHOW_FULFILLED:
    case actions.ONBOARDING_JOB_TITLE_DELETE_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        job_title_suggestions: action.payload.data.data.job_title_suggestions,
        fetching: false,
      }

    case actions.ONBOARDING_JOB_TITLES_LOAD_REJECTED:
    case actions.ONBOARDING_JOB_TITLES_POST_REJECTED:
    case actions.ONBOARDING_JOB_TITLES_SHOW_REJECTED:
    case actions.ONBOARDING_JOB_TITLE_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
