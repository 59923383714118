import { FC } from 'react'
import { OtpInput } from '@jisr-hr/ds-beta'
import { Button, Typography, Flex } from '@jisr-hr/ds'
import { Form, Field } from 'components/final-form'
import { required, composeValidators, minLength } from 'components/global/form/FormValidations'
import i18n from 'translations/i18n'
import styles from '../styles.module.css'
import useOtpCounter from './useOtpCounter'

type MudadOTPFormType = {
  onSubmit: (arg: { otp_code: string }) => void
  onResend: () => void
  updated_at: string | undefined
  number?: number
  disabled: boolean
}

const MudadOTPForm: FC<MudadOTPFormType> = (props) => {
  const { updated_at, number, disabled } = props
  const time = useOtpCounter(updated_at)

  return (
    <div>
      <Typography
        variant="interface/default/sm"
        style={{ lineHeight: '20px', marginBottom: 12 }}
        text={
          number
            ? i18n.t('we_will_send_otp_please_enter_here', {
                number: `XXX ${String(number)?.slice(-3)}`,
              })
            : i18n.t('please_enter_otp')
        }
      />
      <Form onSubmit={props.onSubmit}>
        {({ handleSubmit, valid }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
            className={styles.otp_form}
            autoComplete="off"
          >
            <Flex
              itemsStart
              style={{ gap: 16 }}
            >
              <Field
                name="otp_code"
                validate={composeValidators(required, minLength(4))}
              >
                {({ input }): JSX.Element => (
                  <OtpInput
                    {...input}
                    length={4}
                    onChange={(v: string): void => input.onChange(v.replace(/,/g, ''))}
                  />
                )}
              </Field>
              <Button
                label={i18n.t('confirm')}
                size="medium"
                type="submit"
                disabled={!valid || !time || disabled}
              />
            </Flex>
          </form>
        )}
      </Form>

      <Typography
        variant="interface/default/sm"
        onClick={(): void => {
          if (time) return
          props.onResend()
        }}
        style={{
          lineHeight: '20px',
          marginTop: 16,
          width: 'fit-content',
          cursor: time ? 'default' : 'pointer',
          opacity: disabled && !time ? '0.6' : '1',
          //  pointerEvents: disabled && !time ? 'none' : 'default',
          color: 'var(--color-base-colors/blue/700)',
        }}
        text={
          !time
            ? i18n.t('resend_otp')
            : i18n.t('resend_after', {
                min: time.minutes,
                sec: time.seconds,
              })
        }
      />
    </div>
  )
}

export default MudadOTPForm
