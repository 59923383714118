import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const ApprovalText = ({ label }) => (
  <Typography
    text={label}
    textColor="--color-base-colors-grey-600"
    variant="subheading"
  />
)

ApprovalText.propTypes = {
  label: PropTypes.string,
}

export default ApprovalText
