import React from 'react'

import Switch from './Switch'
import Checkbox from './Checkbox'
import Radio from './Radio'
import Select from './Select'
import MultipleSelect from './MultipleSelect'
import MultiSelect from './MultiSelect'
import TextInput from './TextInput'

export default class Input extends React.Component {
  static Switch = Switch
  static Checkbox = Checkbox
  static Radio = Radio
  static Select = Select
  static MultipleSelect = MultipleSelect
  static MultiSelect = MultiSelect
  static TextInput = TextInput

  render() {
    return ''
  }
}
