const Analysis = {
  // A
  all_filters: 'كل المنشأة',
  attendance_statistics: 'إحصائيات الحضور',
  assets_statistics: 'إحصائيات الأصول',
  // B
  // C
  // D
  // E
  employees_statistics: 'إحصائيات الموظفين',
  export_title_data: 'تصدير الرسم البياني ل{{title}}',
  // F
  finance_analysis: 'المالية',
  // G
  // H
  // I
  // J
  // K
  // L
  // M
  // N
  // O
  // P
  payroll_statistics: 'إحصائيات المرتبات',
  // Q
  // R
  // S
  statistics: 'الإحصائيات',
  search_by_keywords: 'البحث بالاسم…',
  reports_statistics: 'التقارير والإحصائيات',
  // T
  title_breakdown: 'تفاصيل {{title}}',
  there_is_no_data: 'لا توجد بيانات في الفترة المحددة، اختر فترة مختلفة',
  // U
  // V
  // W
  // X
  xls_analysis: 'تحميل ملف Excel',
  // Y
  year_analysis: 'سنوي',
  // Z
}

export default Analysis
