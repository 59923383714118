import { ComponentType, ReactNode, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

type PrivateRouteProps = RouteProps & {
  component: ComponentType<unknown>
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, ...rest } = props
  const token = localStorage.getItem('LoggedIn')

  useEffect(() => {
    if (!token) window.location.reload()
  }, [])

  return (
    <Route
      {...rest}
      render={(renderProps): ReactNode => <Component {...renderProps} />}
    />
  )
}

export default PrivateRoute
