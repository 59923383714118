import DOMPurify from 'dompurify'

/**
+ * Sanitizes input string by removing all HTML tags and attributes to prevent XSS attacks.
+ * @param value - The input string to sanitize
+ * @returns The sanitized string with all HTML tags and attributes removed
+ */
export const sanitizeInput = (value: string) => {
  return DOMPurify.sanitize(value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })
}
