import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { PaginationExtendedTypes } from 'types/sharedResponse'
import * as actions from './actions'

type StateType = {
  authorizeUsers: {
    avatar_thumb: string
    code: string
    full_name_i18n: string
    id: number
    identification_info: {
      document_number: string
      document_type: string
      passport_number: string
    }
    is_super_admin: boolean
    job_title: string
  }[]
  eligiablUsers: {
    avatar_thumb: string
    code: string
    full_name_i18n: string
    id: number
    identification_info: {
      document_number: string
      document_type: string
      passport_number: string
    }
    is_super_admin: boolean
    job_title: string
  }[]
  eligibleUsersPagination: PaginationExtendedTypes
  authorizeUsersPagination: PaginationExtendedTypes
  fetching: boolean
}

const DEFAULT_STATE: StateType = {
  fetching: false,
  authorizeUsers: [],
  eligiablUsers: [],
  eligibleUsersPagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
  authorizeUsersPagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
}

export default function reducer(state = DEFAULT_STATE, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_MUQEEM_ELIGIABLE_USERS_LOAD_PENDING:
    case actions.ADD_AUTHORIZED_USER_PENDING:
    case actions.REMOVE_AUTHORIZED_USER_PENDING:
    case actions.GET_AUTHORIZED_USERS_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.REMOVE_AUTHORIZED_USER_FULFILLED:
    case actions.ADD_AUTHORIZED_USER_FULFILLED:
      if (payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.GET_AUTHORIZED_USERS_FULFILLED:
      return {
        ...state,
        authorizeUsers:
          payload.data.data.pagination?.current_page !== 1
            ? [...state.authorizeUsers, ...payload.data.data.employees]
            : payload.data.data.employees,
        authorizeUsersPagination: payload.data.data.pagination,
        fetching: false,
      }
    case actions.GET_MUQEEM_ELIGIABLE_USERS_LOAD_FULFILLED:
      return {
        ...state,
        eligiablUsers:
          payload.data.data.pagination?.current_page !== 1
            ? [...state.eligiablUsers, ...payload.data.data.employees]
            : payload.data.data.employees,
        eligibleUsersPagination: payload.data.data.pagination,
        fetching: false,
      }
    case actions.GET_MUQEEM_ELIGIABLE_USERS_LOAD_REJECTED:
    case actions.REMOVE_AUTHORIZED_USER_REJECTED:
    case actions.ADD_AUTHORIZED_USER_REJECTED:
    case actions.GET_AUTHORIZED_USERS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
