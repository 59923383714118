/** **************
 *   Setting Organization Profile
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function organizationLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'GET'

  return {
    type: 'ORGANIZATION_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function getOrganizationCurrencyTypeLoad() {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'GET'
  const params = { only_currency_type: true }

  return {
    type: 'GET_ORGANIZATION_CURRENCY_TYPE_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function putOrganization(data) {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'PUT'
  const params = {}

  return {
    type: 'ORGANIZATION_UPDATE',
    payload: handleAPI(url, params, method, { organization: data }),
  }
}

export function organizationContactInfoUpdate(data) {
  const url = `/${API_VERSION_TWO}/contact_informations/`
  const method = 'PUT'
  const params = {}

  return {
    type: 'ORGANIZATION_CONTACT_INFO_UPDATE',
    payload: handleAPI(url, params, method, { contact_information: data }),
  }
}
