import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import {
  InviteEmployeesTypes,
  LeaveSetupTypes,
  OnboardingStepsTypes,
  OrganizationInfoTypes,
  PayrollStepsTypes,
} from 'containers/public/Onboarding/types/onboardingTypes'
import * as actions from './actions'

export type slugAvailability = {
  data: {
    data: {
      available: boolean
    }
  }
}

const POS = {
  id: null as null | number,
  name: '',
  name_ar: '',
  name_i18n: '',
}

const industry = {
  id: null as number | null,
  name_en: '',
  name_ar: '',
  name_i18n: '',
}

const logo = {
  file_path: '',
  file_name: '',
}

const initialState = {
  loginFromOnboarding: false,
  fetching: false,
  error: null as null | string,
  errorStatus: null as null | boolean,
  success: null as null | boolean,
  employeeDate: new Date(),
  organization: {
    account_type: '' as 'actual' | 'demo' | 'test',
    name: '',
    name_ar: '',
    name_i18n: '',
    brand_name: '',
    brand_name_ar: '',
    slug: '',
    id: 0,
    logo,
    industry,
    logo_file_name: '',
    industry_id: null,
    is_organization_subsidiary: false,
    country_id: null,
    organization_type: null as null | number,
    metadata: {
      is_custom_labor_law_configurations: null,
      new_onboarding_payroll: false,
      is_segment_enable: false,
      first_admin_details: {
        full_name_en: '',
        first_admin_token: '',
        email: '',
      },
      onboarding_steps: {} as OnboardingStepsTypes,
      leaves_setup: {} as LeaveSetupTypes,
      payroll_setup: {} as PayrollStepsTypes,
      invite_employees: {} as InviteEmployeesTypes,
      organization_info: {} as OrganizationInfoTypes,
    },
    require_pincode: false,
    medical_insurance_provider: {
      ...POS,
      website: '',
    } as (typeof POS & { website: string }) | null,
    point_of_sale_provider: POS as typeof POS | null,
    accounting_system_provider: POS as typeof POS | null,
    onboarding_flow: '',
  },
  slug_avaliable: true as boolean,
}
export type TOrganization = typeof initialState

export default function reducer(state = initialState, action: Action): TOrganization {
  const { payload } = action
  switch (action.type) {
    case actions.LOGIN_FROM_ONBOARDING:
      return {
        ...state,
        loginFromOnboarding: true,
      }

    case actions.ONBOARDING_RESET_TOKEN:
      return {
        ...state,
        organization: {
          ...state.organization,
          metadata: {
            ...state.organization.metadata,
            first_admin_details: {
              ...state.organization.metadata.first_admin_details,
              first_admin_token: '',
            },
          },
        },
      }
    case actions.ONBOARDING_SLUG_RESET:
      return {
        ...state,
        slug_avaliable: true,
      }

    case actions.ONBOARDING_COMPLETE_STEP:
      const key = payload.data.title

      return {
        ...state,
        organization: {
          ...state.organization,
          metadata: {
            ...state.organization.metadata,
            first_admin_details: {
              ...state.organization.metadata.first_admin_details,
            },
            [payload.type]: {
              ...state.organization.metadata[payload.type],
              [key]: {
                ...state.organization.metadata[payload.type][key],
                is_completed: true,
              },
            },
          },
        },
      }

    case actions.ONBOARDING_ORGANIZATION_LOAD_PENDING:
    case actions.ONBOARDING_ORGANIZATION_PUT_PENDING:
    case actions.SLUG_AVALIABLE_PENDING:
    case actions.ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_PENDING:
    case actions.ONBOARDING_ORGANIZATION_CONFIRM_PUT_PENDING:
    case actions.ONBOARDING_STEP_UPDATE_PENDING:
    case actions.VALIDATE_ADMINS_EMAIL_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_ADMINS_RESET_TOKEN_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.VALIDATE_PIN_CODE_CREATE_PENDING:
      return {
        ...state,
        error: null,
        fetching: true,
      }

    case actions.ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_FULFILLED:
    case actions.ONBOARDING_ORGANIZATION_CONFIRM_PUT_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
        success: payload.data.success,
        errorStatus: false,
      }

    case actions.ONBOARDING_ADMINS_RESET_TOKEN_FULFILLED:
      return {
        ...state,
      }

    case actions.VALIDATE_PIN_CODE_CREATE_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        error: null,
        fetching: false,
      }

    case actions.SLUG_AVALIABLE_FULFILLED:
      return {
        ...state,
        slug_avaliable: payload.data.data.available,
        error: null,
        fetching: false,
      }

    case actions.ONBOARDING_ORGANIZATION_PUT_FULFILLED:
    case actions.ONBOARDING_ORGANIZATION_LOAD_FULFILLED:
    case actions.ONBOARDING_STEP_UPDATE_FULFILLED:
    case actions.VALIDATE_ADMINS_EMAIL_FULFILLED:
      const metadata = payload.data.data?.organization?.metadata

      return {
        ...state,
        employeeDate:
          action.payload.headers && action.payload.headers.date
            ? new Date(action.payload.headers.date)
            : state.employeeDate,
        ...payload.data.data,
        organization: {
          ...payload.data.data?.organization,
          metadata: {
            ...state.organization.metadata,
            ...metadata,
            organization_info: {
              ...state.organization.metadata?.organization_info,
              ...metadata?.organization_info,
              reviewing_employee_data: {
                is_completed: metadata?.organization_info?.upload_employee_data?.is_completed,
              },
              verify: {
                is_completed: metadata?.organization_info?.upload_employee_data?.is_completed,
              },
            },

            leaves_setup: {
              ...state.organization.metadata?.leaves_setup,
              ...metadata?.leaves_setup,
              review_page: {
                is_completed: metadata?.leaves_setup?.upload_annual_leave_files?.is_completed,
              },
              leaves_and_payroll_configurations: {
                is_completed: typeof metadata?.is_custom_labor_law_configurations === 'boolean',
              },
            },

            payroll_setup: {
              ...state.organization.metadata?.payroll_setup,
              ...metadata?.payroll_setup,
              review: { is_completed: true },
              done_finish: { is_completed: true },
            },
          },
        },
        fetching: false,
      }

    case actions.ONBOARDING_ORGANIZATION_LOAD_REJECTED:
    case actions.ONBOARDING_ORGANIZATION_PUT_REJECTED:
    case actions.SLUG_AVALIABLE_REJECTED:
    case actions.ONBOARDING_ORGANIZATION_CONFIRM_PUT_REJECTED:
    case actions.ONBOARDING_STEP_UPDATE_REJECTED:
    case actions.VALIDATE_ADMINS_EMAIL_REJECTED:
    case actions.VALIDATE_PIN_CODE_CREATE_REJECTED:
    case actions.ONBOARDING_ADMINS_RESET_TOKEN_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        error: handleResponseErr(action.payload),
      }

    case actions.ONBOARDING_ORGANIZATION_SLUG_AVAILABILITY_PUT_REJECTED:
      return {
        ...state,
        fetching: false,
        errorStatus: !action.payload.response.data.success,
        error: action.payload.response.data.error,
        success: false,
      }
    default:
      return state
  }
}
