import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isValid, format as formatFns, isEqual } from 'date-fns'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { format } from 'utils/date'
import { getManualPunchValidationMessage } from 'containers/authorised/attendance/helper'

import { ReactComponent as PunchIcon } from '../icons/fingerprint.svg'
import { ReactComponent as DeviceIcon } from '../icons/mobile-devices.svg'
import { ReactComponent as EditIcon } from '../icons/edit.svg'

import CorrectionBadge from './CorrectionBadge'
import Styles from '../ProposedCorrectionTime.module.css'

const handleIcons = (isOnRange) => ({
  biometric: <PunchIcon className={isOnRange ? Styles.grayDark : Styles?.grayLight} />,
  geofencing: <DeviceIcon className={!isOnRange && Styles?.grayLight} />,
  manual: <EditIcon className={!isOnRange && Styles?.grayLight} />,
  correction_punch_request: <EditIcon className={!isOnRange && Styles?.grayLight} />,
  bulk_upload: <EditIcon className={!isOnRange && Styles?.grayLight} />,
})

const punchType = {
  in: 'green',
  out: 'yellow',
}

const Logs = ({ date, punchList, isMoreShift, onClick, minTime, maxTime, fieldTime }) => {
  const handleTime = (time) => {
    time = isValid(new Date(time)) ? formatFns(new Date(time), 'hh:mm aa') : time
    return replaceAmPm(time)
  }

  return (
    <div>
      <Typography
        text={isValid(new Date(date)) ? format(new Date(date), 'EEE, dd MMM yyyy') : date}
        variant="caption"
        style={{ color: 'var(--color-base-colors-grey-600)' }}
      />

      <Spacer height={8} />

      {punchList?.map((log, i) => {
        const logDate = new Date(`${log?.date} ${log?.punch_time}`)
        const isOnRange =
          !getManualPunchValidationMessage(logDate, minTime, maxTime) &&
          !isEqual(fieldTime, logDate)

        const icons = handleIcons(isOnRange)

        return (
          <Flex
            key={`${i}-log`}
            justifyBetween
            itemsCenter
            onClick={() => isOnRange && onClick(log)}
            className={classNames(Styles.punch, isOnRange && 'pointer')}
          >
            <Flex itemsCenter>
              {icons[log?.reporting_method]}
              <Spacer width={10} />
              <Typography
                text={replaceAmPm(log?.punch_time)}
                variant="body-new/regular"
                style={{
                  color: isOnRange
                    ? 'var(--color-base-colors-black-600)'
                    : 'var(--color-base-colors-grey-600)',
                }}
              />
            </Flex>
            {punchType[log?.punch_type] && (
              <CorrectionBadge
                label={`${log?.punch_type_i18n}${
                  isMoreShift
                    ? `(${handleTime(log?.employee_shift?.start_time)} — ${handleTime(
                        log?.employee_shift?.end_time,
                      )})`
                    : ''
                }`}
                size="small"
                type={punchType[log?.punch_type]}
              />
            )}
          </Flex>
        )
      })}
    </div>
  )
}

Logs.propTypes = {
  isMoreShift: PropTypes.bool,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  punchList: PropTypes.arrayOf(Object),
  fieldTime: PropTypes.arrayOf(Object),
}

export default Logs
