import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'

const DEFAULT_STATE = {
  fetching: false,
  absence_deduction_policy: {},
}

let changes = null
export default function absenceDeductionPolicyReducer(state = DEFAULT_STATE, action) {
  const { payload } = action

  switch (action.type) {
    case 'ABSENCE_DEDUCTION_POLICY_LOAD_PENDING':
    case 'ABSENCE_DEDUCTION_POLICY_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'ABSENCE_DEDUCTION_POLICY_RESET':
      changes = {
        absence_deduction_policy: {},
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ABSENCE_DEDUCTION_POLICY_LOAD_FULFILLED':
      changes = {
        absence_deduction_policy: payload.data.data.absence_deduction_policy,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ABSENCE_DEDUCTION_POLICY_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ABSENCE_DEDUCTION_POLICY_LOAD_REJECTED':
    case 'ABSENCE_DEDUCTION_POLICY_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, ...changes, fetching: false }

    default:
      return state
  }
}
