const TimeAttendance = {
  // A
  a: 'A',
  absent: 'Absent',
  actual_overtime: 'Actual overtime',
  add_device: 'Add Device',
  add_another_device: 'Add another Device',
  adding_device: 'Adding Device:',
  am_or_pm: 'AM Or PM',
  article_link: 'Article link',
  any_fingerprint_after_this_time_will_not_be_considered:
    'Any fingerprint after this time will not be considered an automatic entry fingerprint by the system, and the employee must submit a correction request for the fingerprint to be recorded as an entry fingerprint',
  any_fingerprint_after_this_time_will_not_be_automatically:
    'Any fingerprint after this time will not be automatically considered an exit fingerprint by the system. The employee must submit a correction request for the fingerprint to be recorded as an exit fingerprint.',
  i_want_to_add_admins_now: 'I want to add admins now',
  absent_tip: 'Absent',
  approve_and_submit: 'Approve & Submit',
  approved_overtime: 'Approved overtime',
  attendance_status: 'Attendance Status',
  actual_delay: 'Actual delay',
  approved_on: 'Approved on',
  attendance_change: 'Attendance change',
  attendance_sheet_submitted_successfully:
    '{{month}} {{year}} attendance sheet submitted successfully.',
  attendance_sheet_dismissed_successfully:
    '{{month}} {{year}} Attendance sheet dissmissed successfully',
  attendance_sheet_reopened_successfully:
    '{{month}} {{year}} Attendance sheet canceled submission successfully',
  are_you_sure_you_want_to_dismiss_the_attendance:
    'Are you sure you want to dismiss the attendance for {{month}} {{year}}?',
  actual_shortage: 'Actual shortage',
  actual_early_departure: 'Actual early departure',
  actual_late_arrival: 'Actual late arrival',
  any_attendance_incident_that_would_normally:
    'Any attendance incident that would normally cause a deduction such as absence, late arrivals, or early departures, will be dropped and deductions will not take effect on this payroll.',
  approved_requests_that_were_raised:
    'Approved requests that were raised to recover from a potential deduction such as Late Arrival, Early Departure, and Full Day excuse requests or Correction requests, will not take an effect on payroll since there is no deduction in the first place.',
  additions_from_approved_overtime:
    'Additions from approved Overtime requests won’t be paid in the payroll,  And affected employees will be notified.',
  are_you_sure_approve_pending_request:
    'You have {{pending_requests}} pending requests. Are you sure you want to submit?',
  and_it_ends_once_the_payroll_is_confirmed:
    'And it ends once the payroll is confirmed or upon successful submission.',
  automatically_add_by_shift: 'Automatically add by shift',
  at: 'at',
  At: 'at',
  approve_and_submit_anyway: 'Approve & submit anyway',
  assign_now: 'Assign now',
  abnormal: 'Abnormal',
  approved_ot: 'Approved OT',
  allowed: 'Allowed',
  actual: 'Actual',
  assigned_locations: 'Assigned locations',
  allow_to_clock_offsite: 'Allow to clock in/out offsite (out of geo-location range)',
  allowed_to_clock_offsite: 'Allowed to clock in/out offsite (out of geo-location range)',
  automatically_added: 'Automatically added',
  approve_sheet: 'Approve Sheet',
  Attendance_sheet_might_not_accurate: 'Attendance sheet might not be accurate',
  attendance_day: 'Attendance day',
  attendance_duration: 'Attendance duration',
  add_attatchment: 'Add attatchment',
  attendance_record: 'Attendance record',
  add_day: 'Add day',
  a_shift_need_to_assigned:
    'A shift needs to be assigned in order calculate & capture working hours',
  attendance_sheets: 'Attendance Sheets',
  asked_for_cancellation_by: 'Asked for cancellation by',
  all_schedule: 'All Schedule',
  allow_punch_in_with_penalties_till: 'Allow punch in with penalties till:',
  add_your_bio_devices_here_so_that_your_employees:
    'add your bio devices here so that your employees are able to punch in and out through these devices',
  arevamped_design_for_better_navigation:
    'A revamped design for better navigation and user experience',
  assign_staff_to_schedules: 'Assign staff to schedules',
  assign_different_schedules_to_your_employee_groups:
    'Assign different schedules to your employee groups and gain more flexibility and better management',
  assign_shifts_to_employees: 'Assign Shifts to employees',
  assign_employees_to_schedules:
    'Assign employees to schedules so that they’re able to know when and where they’re required to work',
  assign_staff_to_schedule: 'Assign staff to schedules',
  away_to_help_you_save_time_and_effort_for_overtime:
    'A way to help you save time and effort for overtime calculations',
  are_you_sure_you_want_to_leave: 'Are you sure you want to leave?',
  automatically_remind_employees_to_clock_in_out:
    'Automatically remind employees to clock/in & out at the start & end of their shifts through a notification on their {{app_name}} mobile app.',
  all_employees_will_get_notified: 'All employees will get notified',
  all_employees_will_receive_anotification_on_their_mobile_app:
    'All Employees will receive a notification on their mobile app based on your configuration',
  // B
  b: 'B',
  bulk_days: 'bulk days',
  both_geo_and_bio: 'Both geofencing and bio devices',
  bio_device: 'Bio Device',
  bio_devices_maintenance: 'Bio devices might need maintenance',
  business_unit: 'Business Unit',
  bio_metric_devices: 'Bio Metric Devices',
  by_shift: 'By shift',
  back_step: 'Back',
  based_on_you_have_allowed_your_employees_to_punch_out:
    'based on this you’ve allowed your employees to punch out {{allowed}} earlier with No penalties',
  by_changing_this_form_name_type:
    'By changing this {{form_name}} type the data you entered will be removed, and you will have to enter it again.',
  by_leaving_this_page_without_saving:
    'By leaving this page without saving, your data will be removed.',
  // C
  c: 'C',
  check_in: 'Check In',
  connect_your_devices_to_our_server: 'Connect your devices to our server',
  check_out: 'Check Out',
  comment_placeholder: 'Type your comment here',
  clocked_in: 'Clocked in',
  clocked_out: 'Clocked out',
  clock_in_time: 'Clock-in time',
  clock_out_time: 'Clock-out time',
  clear_all_deductions: 'Clear All Deductions',
  columns: 'Columns',
  correction_requests: 'Correction requests',
  cancellation: 'Cancellation',
  correction: 'Correction',
  choose_another_person_decide: 'Choose another person to decide on your behalf',
  choose_shift_replacement: 'Choose shift replacement',
  correction_date_and_type: 'Correction date',
  cancel_submission: 'Cancel Submission',
  cancel_attendance_sheet_submission: 'Cancel Attendance Sheet Submission',
  cant_select_time_before_shift:
    "Can't select a time before shift start time for middle of day excuse request.",
  cant_select_time_after_shift:
    "Can't select a time after shift end time for middle of day excuse request.",
  cant_select_time_before_after_shift:
    "Can't select a time before shift start time or after shift end time for middle of day excuse request.",
  cant_select_time_before_from_time: "Can't select a time before or equal (From) time.",
  cutoff_time_for_punching_out_is: 'Cutoff time for punching out is:',
  clock_out_is_not_required: 'Clock-out is not required',
  customize_shifts: 'Customize shifts',
  cancel_and_dont_delete: 'Cancel and don’t delete',
  configure_the_method: 'configure the {{method_name}}',
  choose_from_a_number_of_options:
    'Choose from a number of options that allow you to track working hours',
  check_created_shifts: 'Check created shifts',
  check_the_schedule: 'Check the schedule',
  choose_number_of_shifts_and_the_shifts: 'Choose number of shifts and the shifts',
  continue_setup: 'Continue setup',
  changing_step_type: 'Changing {{form_name}} type',
  check_the_settings: 'Check the settings',
  contact_support_dashboard: 'Contact support',
  // D
  day: 'day',
  delete_this_device: 'Delete this device',
  define_geolocation: 'Define Geolocation',
  define_bio: 'Define Bio',
  defining_punching_method_help_your_teams:
    'Defining punch method help your teams log in and out of their working shifts',
  defining_punching_method: 'Defining punching method',
  defining_punching_method_for: 'Defining reporting method for ({{location_name}})',
  delay_tip:
    'The time recorded on the employee because of being late at the beginning of his day shift',
  daily_view: 'Daily View',
  device_online: '{{number}} Device online',
  device_pending: '{{number}} Device pending',
  device_offline: '{{number}} Device is offline',
  dismiss_attendance: 'Dismiss attendance',
  dismissed: 'Dismissed',
  define: 'Define',
  document_ID: 'Document ID',
  days_with_overtime: 'Days with overtime',
  hours_with_overtime: 'Hours with overtime',
  dismissing_attendance_means_that_employees:
    'Dismissing attendance means that employees salaries wont be affected by their attendance records,and employees will be paid their normal wages, no additions no deductions.',
  do_you_really_want_to_delete_it: 'Do you really want to delete it?',
  difference: 'Difference',
  device_info: 'Device info',
  dismiss_sheet: 'Dismiss Sheet',
  days_off: 'Days Off',
  Delay: 'Delay',
  days: 'Days',
  delay_duration: 'Delay duration',
  delegate_decision: 'Delegate Decision',
  decision_will_passed: 'Decision will be passed to the next approver',
  deductable: 'Deductable',
  different_durations: 'Different durations',
  duration_period: 'Duration',
  delay_deduction: 'Delay deduction',
  define_schedule_name: 'Define schedule name',
  define_shift_name_and_color: 'Define Shift name and color',
  define_working_hours: 'Define Working hours',
  define_clocking_rules: 'Define Clocking rules',
  define_overtime: 'Define overtime',
  device_serial_number: 'Device serial number',
  define_what_employees_on_this_shift_should_do: 'Define what employees on this shift should do',
  define_who_are_employees_on_this_schedule: 'Define who’re employees on this schedule',
  define_the_time_allowed_for_employees_to_punch_in:
    'Define the time allowed for employees to punch in without penalties after the shift start',
  define_the_time_allowed_for_employees_to_punch_out:
    'Define the time allowed for employees to punch out without penalties before the shift ends',

  define_number_of_working_hours: 'Define number of working hours',
  do_more_configuration: 'Do more configuration',
  device_will_be_deleted: 'Device will be deleted',
  device_status: 'Device status',
  define_the_shifts: 'Define the shifts',
  done_for_now: 'Done for now',
  define_shifts_for_each_employee_group:
    'Define shifts for each employee group and help track their attendance',
  define_shifts: 'Define shifts',
  define_all_locations_reporting_methods:
    'Define all locations reporting methods so that your employees can report their attendance',
  define_shifts_criteria_that_fits_your_work_needs:
    'Define shifts criteria that fits your work needs',
  define_the_clocking_rules: 'Define the clocking rules',
  define_how_employees_on_this_shift_should_punch_in:
    'Define how employees on this shift should punch in',
  define_how_employees_on_this_schedule_will_work:
    'Define how employees on this schedule will work',
  define_the_schedule: 'Define the schedule',
  device_limit_reached: 'Device limit reached',
  // E
  employee_attendance_details: 'Employee Attendance details',
  enter_reason_detail: 'Enter Reason Detail',
  employees_will_be_able_to_clock_in:
    'Employees will be able to clock in anytime from {{from}}  to {{to}}',
  edit_device_admins: 'Edit device admins',
  employment_type: 'Employment Type',
  edit_previous_step: 'Edit previous step',
  excused_time: 'Excused time',
  extra_hours: 'Extra hours',
  extra_time: 'Extra time',
  excused_delay_tip: 'The time in which the employee was allowed to be late.',
  excused_shortage_tip: 'The time in which the employee was allowed early exit.',
  extra_time_tip:
    'An additional period of time spent by the employee at work in excess of the hours of work required from him or outside ',
  excuse_time_field_valedation_1: 'Excuse time must be greater than or equal to 5 Minutes',
  excuse_time_field_valedation_2: 'Excuse time must be less than or equal to 6 Hours',
  ex_personal: 'Personal',
  ex_business: 'Business',
  excuse_time: 'Excuse Duration',
  expected_hours: 'Expected hours',
  excused_shortage: 'Excused early departure',
  excused_delay: 'Excused late arrival',
  early_departure: 'Early departure',
  excused_late_arrival: 'Excused late arrival',
  excused_early_departure: 'Excused early departure',
  edit_information: 'Edit Information',
  edit_logs_hint_message:
    'If you edit the check in/check out, the logs that are coming from any reporting method will not overwrite it.',
  excuse_date_cant_be_before_days: 'Excuse date can’t be before 60 days in the past',
  early_out: 'Early out',
  early_in: 'Early in',
  exempted: 'Exempted',
  employee_attendance: 'Employee Attendance',
  early_outs: 'Early Out’s',
  employees_review_period: 'Employees in this review period',
  excuse_requests: 'Excuse requests',
  excuse_date_and_type: 'Excuse date & type',
  enter_your_reason: 'Enter your reason',
  excuse_duration: 'Excuse duration',
  estimated_delay_duration: 'Estimated delay duration: (<b>{{minutes}}</b>)',
  estimated_shortage_duration: 'Estimated shortage duration: (<b>{{minutes}}</b>)',
  estimated_clock_in_time: 'Estimated clock-in time',
  estimated_clock_out_time: 'Estimated clock-out time',
  enter_time_manually: 'Enter time manually',
  early_out_excuse: 'Early Out Excuse',
  entry_method: 'Entry method',
  edited: 'Edited',
  exempted_from_attendance_deductions: 'Exempted from attendance deductions',
  exclude_exempted_employees: 'Exclude exempted employees',
  employee_in_this_sheet: 'Employee in this sheet',
  estimated_excused_time: 'Estimated excused time',
  estimated_excused_duration: 'Estimated excused duration',
  employees_on_this_schedule: 'Employees on this schedule',
  employees_on_this_schedule_will_work_one_shift: 'Employees on this schedule will work 1 shift',
  employees_on_this_schedule_will_work_two_shifts: 'Employees on this schedule will work 2 shifts',
  enter_the_schedules_name:
    'Enter the schedule’s name and select a color so it’s easier to identify when anyone sees it',
  enter_the_shifts_name:
    'Enter the shift’s name and select a color so it’s easier to identify when anyone sees it',
  enable_automatic_overtime_calculation: 'Enable automatic overtime calculation',
  enable_flexibility: 'Enable flexibility',
  earliest_punch_in: 'Earliest punch in :',
  earliest_time_possible_to_start_the_shift:
    'Enter at the earliest possible time to start the shift, so that the system automatically registers the fingerprint as an entry fingerprint for this shift.',
  earliest_punch_out_from: 'Earliest punch out from:',
  earliest_time_possible_to_finish_the_shift: 'earliest time possible to finish the shift',
  earliest_punch_out_without_penalties_from: 'Earliest punch out without penalties from:',
  employee_will_work_per_day: 'Employees will work per day:',
  employees_are_assigned:
    '{{countOfEmployeesAssignedToShifts}} out of {{employeeCount}} employee are assigned',
  enabled_by_default: 'Enabled by default',
  // F
  first_shift_in_time: 'First shift in time',
  first_shift_out_time: 'First shift out time',
  full_day_excuse: 'Full day excuse',
  full_day: 'Full day',
  from_you: 'From you',
  from_other_approvals: 'From other approvals',
  first_in: 'First In',
  facility: 'Facility',
  fixed_schedule_with_one_shift_preset: 'Fixed Schedule with one shift preset',
  for_example_if_the_shift_is:
    'for example if the shift is {{start_time}} to {{end_time}} if you added flexibility of 120 minutes they’ll be able to start on {{new_start_time}} to {{new_end_time}} while same clocking rules apply',
  // G
  go_back: 'Go back',
  go_back_recommended: 'Go back (Recommended)',
  grant_us_access_to_your_location: 'Grant us access to your location',
  geo_only: 'Geofencing',
  geo: 'Geofencing',
  geofencing_live: 'Geofencing live',
  go_to_settings: 'Go to Settings',
  go_to_template_settings: 'Go to template settings',
  got_approved_overtime: 'Got approved overtime',
  got_deductables: 'Got deductables',
  got_retroactives: 'Got retroactives',
  got_pending_requests: 'Got pending requests',
  got_unscheduled_days: 'Got unscheduled days',
  got_incomplete_records: 'Got incomplete records',
  got_incomplete_schedule: 'Got incomplete schedule',
  got_incomplete_schedule_summary: 'Got incomplete schedule',
  got_deductions: 'Got deductions',
  got_retroactive_transactions: 'Got retroactive transactions',
  // H
  hide_comments: 'Hide comments',
  highly_recommended: 'Highly recommended',
  how_connect_your_devices: 'How to connect your devices to {{app_name}} servers',
  holiday: 'Holiday',
  hours: 'Hours',
  how_does_it_affect_payroll: 'How does it affect payroll?',
  hide_shift_rules: 'Hide shift rules',
  hr_1: '1 hr',
  hr_2: '2 hr',
  hr: 'hr',
  hide_map: 'Hide map',
  high_to_low: 'High to Low',
  highlights: 'Highlights',
  highest_positive_difference: 'Highest positive difference',
  highest_negative_difference: 'Highest negative difference',
  has_no_off_days: 'Has no off days',
  // I
  in_time: 'In time',
  in: 'In',
  i_use_bio_devices_only: 'I Use bio devices only',
  i_will_add_admins_to_this_device_later: "I'll add admins to this device later",
  instant: 'Instant',
  include_exempted_employees: 'Include exempted employees',
  incident_date: 'Incident date',
  if_an_employee_was_on_leave_period:
    'If an employee was on a leave period that wasn’t approved, he/she will be considerd absent.',
  if_excuse_requests_werent_approved:
    'If excuse requests weren’t approved, hours might be deducted depending on the actual records.',
  if_overtime_requests_werent_approved:
    'If overtime requests weren’t approved, overtime hours won’t be paid.',
  it_seems_no_overview_requests: 'It seems no data found',
  it_seems_no_retroactives_requests: 'It seems no retroactives in this attendance sheet',
  it_seems_no_pending_requests: 'It seems no pending requests in this attendance sheet',
  if_an_employee_was_on_a_leave:
    'If an employee was on a leave period that wasn’t approved, they will be paid normally and their leave balance won’t be affected.',
  if_missing_punch_requests_werent_approved:
    "If correction requests weren't approved, the employee will have Incomplete record.",
  incomplete_records: 'Incomplete records',
  incomplete_record: 'Incomplete record',
  incomplete_schedule: 'Incomplete schedule',
  incomplete_records_days: 'Days with incomplete records',
  i_want_to_the_teams_on_assigned_to_this_location_using_both_methods:
    'I want to the teams on assigned to this location to be able to punch using both methods Geo and Bio',
  i_am_sure_delete_this_device: 'I’m sure, delete this device',
  i_wll_be_using_method_only: 'I’ll be using {{method_name}} only',
  // J
  job_Title: 'Job Title',
  joining_date: 'Joining Date  (Gregorian)',
  // K
  keep: 'Keep',
  // L
  leave: 'Leave',
  leaving_early: 'Leaving Early',
  locations_punch: 'Locations punch methods',
  late_arrival: 'Late arrival',
  last_edited_on: 'Edited on',
  late_in: 'Late in',
  less_details: 'Less details',
  leave_and_overtime_requests: 'Leave and Overtime requests will carry forward and remain pending.',
  late_arrival_excuse: 'Late arrival excuse',
  late_out: 'Late out',
  last_out: 'Last Out',
  late_ins: 'Late In’s',
  low_to_high: 'Low to High',
  late_in_excuse: 'Late In Excuse',
  last_action_by: 'Last action by',
  leave_dashboard: 'Leave',
  locations_methods_done:
    '{{locationsWithReportingMethodsCount}} out of {{locationsCount}} locations methods done',
  // M
  missed_punch: 'Missed punch',
  meters: 'Meters',
  missing: 'Missing',
  missing_punches_tip: 'Missing punch',
  monthly_view: 'Monthly view',
  missing_punch_date: 'Incomplete record date',
  missing_punch_date_cant_be_before_days:
    'Correction request date can’t be before 60 days in the past',
  multiple_shifts_were_scheduled: '1 or multiple shifts were scheduled but haven’t been published',
  missed_shift: 'Missed shift',
  monthly: 'Monthly',
  min_1: '1 min',
  min_2: '2 min',
  min: 'min',
  m: 'm',
  missed_shifts_duration: 'Missed shifts duration',
  missed_shifts: 'Missed shifts',
  missed_shift_deduction: 'Missed shift deduction',
  manual: 'Manual',
  multiple_days: 'Multiple days',
  min_delay: 'delay',
  min_shortage: 'shortage',
  monthly_payroll: 'Monthly payroll',
  missing_punch_date_and_type: 'Missing punch date & type',
  month_attendance: '{{month}} Attendance',
  middle_of_day_excuse: 'Middle of Day Excuse',
  make_sure_you_assign_all_employees:
    'Make sure you assign all employees so that you’re able to track their attendance and get the most out of {{app_name}}',
  // N
  not_joined_yet: 'Not joined yet',
  no_maintenance_needed: 'No maintenance needed',
  not_set_yet: 'Not set yet',
  now_set_how_far_around_location: 'Now set how far around this location your team can punch from',
  not_taking_action_on_the_pending_requests:
    'Not taking action on the pending requests or incidents will affect your employees’ payroll.',
  no_employees_found: 'No employees found',
  no_attendance_sheets_available: 'No attendance sheets available',
  no_record: 'No record',
  no_shift_was_scheduled: 'No shift was scheduled',
  need_attention: 'Need attention',
  not_required: 'Not required',
  no_of_days: 'No. of days',
  no_attendance_required_on_this_day:
    'No attendance required on this day. There’s no need for an excuse request. The reason could be one of the following: <ul><li>This day been scheduled as a ‘Day Off’.</li><li>The selected date is a public holiday.</li><li>You have an approved leave on this day.</li></ul>',
  no_delay_was_recorded_for_this_shift:
    'No delay was recorded for this shift. There’s no need for an excuse request.',
  no_shift_assigned: 'No Shift Assigned',
  no_attendance_required: 'No attendance required',
  number_of_shifts_on_each_day: 'Number of shifts on each day',
  not_now: 'Not now',
  new_schedule: 'New schedule',
  // O
  out_time: 'Out time',
  out: 'Out',
  out_of_clocking_windows: 'Out of clocking windows',
  overtime_hours: 'Overtime hours',
  overtime_ta_tip:
    'Overtime hours that have been assigned to the employee or got approved, and it directly affect the employees payroll',
  overtime_type: 'Overtime type',
  overtime_date: 'Overtime date',
  overtime_date_cant_be_before_days: 'Overtime start date can’t be before 60 days in the past',
  overtimes: 'Overtimes',
  once_you_approve_the_attendance_report_it_will_be_sent:
    'Once you approve the attendance report it will be sent for payroll processing. Any further attendance reflections or approved requests after this point will only take effect next month. <b>Are you ready to submit?</b>',
  once_you_submit_the_attendance: 'Once you submit the attendance sheet you can’t re-open it.',
  overtime_one_total_distributed_equally: 'One total, distributed equally',
  off_day: 'Off Day',
  open_st: 'Open',
  one_shift: 'One shift',
  on_this_schedule_employees_will_work: 'On this schedule employees will work:',
  off: 'Off',
  open_shifts_are_for_people:
    'Open Shifts are for people who have flexible working hours during the day (for example IT team members who need to work for 8 hours per day at any time between 8:00 AM to 8:00 PM)',
  // P
  pending_on: 'Pending on',
  presen: 'Presen',
  please_follow_these_steps: 'Please follow these steps',
  present: 'Present',
  public_holiday: 'Holiday',
  punch_method: 'Punch method status',
  performed_by: 'Performed By',
  payroll_reflection: 'Payroll reflection',
  payroll_cycle: 'Payroll cycle',
  punches: 'Punches',
  punctuality_deductables: 'Punctuality Deductions',
  proposed_correction_time: 'Proposed correction time',
  proposed_correction: 'Proposed Correction',
  processing: 'Processing',
  proceed_to_cancel_submission: 'Proceed to cancel submission for the following sheet?',
  punch_in_without_penalities_till: 'Punch in without penalties till:',
  punches_after_will_be_pending_admin_actions:
    'Punches after {{punchingTime}}, will be pending admin actions',
  // Q
  // R
  reason_type: 'Reason type',
  radius: 'Radius',
  request: 'Request',
  requested: 'Requested',
  requested_days: 'Requested days',
  review_and_submission: 'Review & Submission',
  retroactives: 'Retroactives',
  requested_on: 'Requested on',
  requested_excuse_period: 'Requested excuse period',
  records_need_to_be_recalculated: '{{count}} records need to be recalculated',
  recalculate_now: 'Recalculate now',
  requested_period: 'Requested period',
  requested_overtime_more_than_actual_overtime:
    'The requested overtime is more than the actual overtime recorded',
  requests_that_were_raised_to_recover:
    'Requests that were raised to recover from a potential deduction such as Late Arrival, Early Departure, and Full Day excuse requests or Correction requests, will be cancelled.',
  retroactives_that_appear:
    'Retroactives that appear in the current cycle will not take an effect on the payroll.',
  review_now: 'Review now',
  reason_of_reject: 'Reason of reject',
  requested_by: 'Requested by',
  record: 'Record',
  reflection: 'Reflection',
  roaster_rotating: 'Rotating',
  reporting_methods: 'Reporting Methods',
  request_approved_at: 'Request approved at',
  review_approval: 'Review & Approval',
  recorded: 'Recorded',
  reporting_methods_settings: 'Reporting Methods Settings',
  remind_approvers: 'Remind Approvers',
  requested_duration: 'Requested duration',
  record_changes: 'Record changes',
  recorded_clock_in: 'Recorded clock-in',
  recorded_clock_out: 'Recorded clock-out',
  recorded_clock_in_time: 'Recorded clock-in time',
  recorded_clock_out_time: 'Recorded clock-out time',
  remove_day_off: 'Remove Day Off',
  remove_all_deductibles: 'Remove All Deductibles',
  remove_all_deductables_modal_message:
    'Removing deductibles will match the ‘Allowed’ duration with the ‘Recorded’ delay/shortage duration.',
  repeat_forever: 'Repeat forever',
  reporting: 'Reporting',
  // S
  shortage: 'Early departure',
  select_admins: 'Select admins',
  save_and_proceed: 'Save and proceed',
  save_and_proceed_bio: 'Save and proceed to Bio device settings',
  save_device_settings: 'Save device settings',
  search_location: 'Search for location',
  select_current_location: 'Select your current location',
  select_reporting_methods: 'Select reporting methods',
  set_reporting_methods: 'Set Reporting methods',
  second_shift_in_time: 'Second shift check in',
  second_shift_in: 'second Shift In',
  second_shift_out: 'second Shift Out',
  second_shift_out_time: 'Second shift check out',
  Second_shift_start_time: 'Second Shift Start Time',
  second_shift_end_time: 'Second Shift End Time',
  single_day: 'Single day',
  sync_now: 'Sync now',
  shortage_tip:
    'The time recorded on the employee for not completing the working hours until the end of his day shift',
  show_comments: 'Show Comments',
  search_by_employee_id_or_name: 'Search by employee id or name',
  search_by_employee_id_name_objective_name: 'Search by Employee ID, Name, Objective name',
  second_shift_clock_in: 'Second shift clock in',
  second_shift_clock_out: 'Second shift clock out',
  submitted: 'Submitted',
  submission_window_is_the_time_where:
    'A submission window is the time where you’ll be able to submit the attendance sheet/report for the payroll admin to process it, and it <b>starts after the payroll cut-off date </b> that was set in the Payroll Configuration, which in your case is:',
  set_reporting_methods_for_your_locations:
    'Set reporting methods for your {{locationCount}} locations',
  set_reporting_method_for_your_locations_to_be_able_to_track:
    'Set reporting method for your locations to be able to track the attendance of your employees',
  set_methods: 'Set methods',
  // shift_name: 'Shift Name',
  shift_name_tip: 'shift name',
  shift_info: 'Shift',
  shift_location: 'Shift Location',
  shift_type: 'Shift Type',
  punch_in: 'Punch In',
  punch_out: 'Punch Out',
  shift_start_time: 'Shift Start Time',
  shift_end_time: 'Shift End Time',
  split_first_shift: 'First shift: {{first}} - {{last}}',
  split_first_in_out: 'First shift: {{time}}',
  split_second_shift: 'Second shift: {{first}} - {{last}}',
  split_second_in_out: 'Second shift: {{time}}',
  split_time: 'Split Time',
  select_time: 'Select Time',
  shift_one: '1st Shift',
  shift_two: '2nd Shift',
  show_records_in_days: 'Show records in days',
  show_records_in_hours: 'Show records in hours',
  sort_a_to_z: 'Sort A → Z',
  sort_z_to_a: 'Sort Z → A',
  source: 'Source',
  summary_sheet: 'Summary Sheet',
  shift_ended_without_any_clock: 'Shift ended without any clock in or out',
  show_shift_rules: 'Show shift rules',
  scheduled_hrs: 'Scheduled hrs',
  show_map: 'Show map',
  sort_by_extra_time: 'SORT BY EXTRA TIME',
  sort_by_shortage: 'SORT BY SHORTAGE',
  shift_schedule: 'Shift Schedule',
  show_on_scheduler: 'Show on Scheduler',
  sheet: 'Sheet',
  Shortage: 'Shortage',
  some_employees_have_unscheduled_days:
    'Some employees have unscheduled days or incomplete records which affect the accuracy of their attendance data. Please address the highlighted cases to avoid undesired reflections on payroll',
  scheduled_duration: 'Scheduled duration',
  shift_duration: 'Shift Duration',
  shift_timing: 'shift Period',
  set_as_day_off: 'Set as Day Off',
  skip_decision: 'Skip Decision',
  shortage_duration: 'Shortage duration',
  select_from_existing_punch: 'Select from existing punch',
  select_punch: 'Select punch',
  shift_settings: 'Shift Settings',
  show_geo_location: 'Show Geo-location',
  scheduled_att: 'Scheduled',
  shift_time: 'Shift time',
  shortage_deduction: 'Shortage deduction',
  schedule: 'Schedule',
  set_up_now: 'Setup up now',
  my_schedule: 'My Schedule',
  schedules: 'Schedules',
  search_by_schedule_name: 'Search by schedule name',
  schedule_type: 'Schedule type',
  select_a_shift: 'Select a shift',
  select_employees_who_will_work_on_it: 'Select employees who will work on it',
  schedule_with_different_shift_presets: 'Schedule with Different Shift Presets',
  schedule_details: 'Schedule details',
  save_and_proceed_to_shift_selection: 'Save and proceed to shift selection',
  select_the_shift: 'Select the shift',
  search_in_shifts: 'Search in shifts',
  save_and_proceed_to_repetition: 'Save and proceed to repetition',
  select_case_the_start_and_end_times_are_different:
    'Select this in case the start and end times are different on different days',
  select_case_the_start_and_end_times_are_fixed:
    'Select this in case the start and end times are on fixed times for fixed days; for example from 09:00 AM to 05:00 PM every weekday',
  shift_preset: 'Shift preset',
  shift_details: 'Shift details',
  save_and_proceed_to_working_hours: 'Save and proceed to working hours',
  save_and_proceed_to_clocking_rules: 'Save and proceed to clocking rules',
  start_work_at: 'Start work at:',
  should_work_for: 'Should work for:',
  select_working_days: 'Select working days',
  save_and_publish_schedule: 'Save and publish schedule',
  select_what_days_should_unselected:
    'Select what days should this schedule be on, unselected days will be considered off days',
  select_employees_that_you_want_assign_this_fixed:
    'Select employees that you want to assign to this fixed weekly template',
  save_and_proceed_to_overtime: 'Save and proceed to Overtime',
  schedule_with_fixed_shift_preset: 'Schedule with fixed shift preset',
  start_using_the_attendance: 'Start using the attendance',
  shift_presets_are_for_people:
    'Shift presets are for people who work certain hours in a certain timeframe (for example working 8 hours from 9:00AM to 5:00 PM)',
  setup_attendance: 'Setup Attendance',
  staff_working_hours: 'Staff working hours',
  set_the_rules_of_which_if_happened_an_overtime:
    'Set the rules of which if happened an overtime will be calculated automatically instead of having employees on this shift to send a request and waiting for its approval.',
  select_working_days_time_and_offdays: 'Select working days, times and off days',
  stay_on_page: 'Stay on page',
  search_by_location_name: 'Search by location name',
  save_time_on_correction_requests_with_jisr:
    'Save time on correction requests with {{app_name}} Attendance Reminders.',
  set_when_to_remind_your_employees: 'Set when to remind your employees',
  // T
  attendance_leaves: 'Attendance & Leaves',
  time_main_navigation: 'Time', // will use this keyword only in min navigation for Time Attendance
  time: 'Time',
  timesheet_type: 'Timesheet type',
  total_number_of_approved_business: 'Total number of approved business',
  total_number_of_approved_personal: 'Total number of approved personal',
  total_worked_hours: 'Total worked hours',
  total_worked_days: 'Total worked days',
  type_your_comment_here: 'Type your comment here',
  this_happen_when_an_attendance:
    'This happen when there is an attendance change  - such as approving an excuse request -  the attendance of the employee should be recalculated automatically by the system according to the new change, and this take upto few minutes, still the admin can recalculate the attendance immiditaly,  50 attendance recalculates per action',
  the_employee: 'Employee',
  the_requested_overtime_is_more_than:
    'The requested overtime is more than the actual overtime recorded.',
  tracking: 'Tracking',
  times_late_in: 'Times Late In',
  times_early_out: 'Times Early Out',
  total_shortage: 'Total shortage',
  total_delay: 'Total delay',
  the_full_recorded_duration: 'The full recorded duration (<b>{{minutes}} min</b>)',
  total_extra_worked_hrs: 'Total extra worked hrs',
  total_working_hours_inside_the_shifts: 'Total Working hours inside the shifts',
  this_will_withdraw_all_submitted_attendance_calculations:
    'This will withdraw all the submitted attendance calculations that were sent for payroll processing. You’ll be able to review and resubmit again',
  total_working_hours: 'Total working hours',
  two_shifts: '2 Shifts',
  till: 'Till',
  type_when_should_the_shift_start: 'Type when should the shift start',
  type_how_many_hours_per_day: 'Type how many hours per day employees should work on this shift',
  this_shift_will_end_by:
    'this shift will end by {{endTime}}, edit start time or number of working hours to modify this.',
  the_duration_can_come_late_duration_must_stay_late:
    'The duration can come in late, is the duration must stay late',
  tailor_your_shifts_to_your_business_requirements:
    'Tailor your shifts to your business requirements',
  // U
  unavailable: 'Unavailable',
  undo: 'Undo',
  unregistered: 'Unregistered',
  upload_bulk_overtime: 'Upload bulk overtime',
  unscheduled_days: 'Unscheduled days',
  unscheduled_unpublished_days: 'Unscheduled/Unpublished days',
  using_geofencing_would_help_your_employees:
    'Using geofencing would help your employees clock in and out using {{app_name}} app when the get to locations you set...select a location or search for it and then set the radius in meters',
  using_geofencing_would_help_your_employees_clock_in_and_out:
    'Using geofencing would help your employees clock in and out using {{app_name}} app when the get to locations you set',
  // V
  view_employee_attendance_details_page: 'View employee attendance details page',
  valid_range: 'Valid range',
  view_on_scheduler: 'View on Scheduler',
  view_in_scheduler: 'View in Scheduler',
  via_request: 'Via request',
  view_locations: 'View locations',
  view_settings: 'View settings',
  // W
  what_happens_to_pending_requests: 'What happens to pending requests?',
  will_contact_you_later_to_add_your_biometric_devices:
    '{{app_name}} will contact you later to add your biometric devices',
  who_are_the_admins: 'Who’re the admins of this device?:',
  what_happens_to_approved_requests: 'What happens to approved requests?',
  What_happens_to_retroactives_from_past_months: 'What happens to Retroactives from past months?',
  what_is_a_retroactive: 'What’s a retroactive',
  what_is_submission_window: 'what is a submission window?',
  what_to_add_admins: 'Want to add admins of this device?',
  worked: 'Worked',
  worked_hrs: 'Worked hrs',
  worked_duration: 'Worked duration',
  worked_days: 'Worked days',
  working_hrs_inside_the_shift: 'Working hrs inside the shift',
  working_hours_inside_the_shift: 'Working hours inside the shift',
  when_should_shift_start_and_end: 'When should a shift start and end?',
  who_will_work_on_this_schedule: 'Who will work on this schedule?',
  when_enabled_the_system_automatically_start_calculate:
    'When enabled, the system will automatically start to calculate overtime after the duration you specify',
  if_you_want_you_can_enable_the_teams_who_will_work:
    'If you want, you can enable the teams who will work on this shift to be able to start a bit later.',
  when_enabled_the_system_will_automatically_record:
    'When enabled, the system will automatically record {{recordTime}} as the clock-out time',
  welcome_to_jisr_attendance: 'Welcome to {{app_name}} attendance 👋🏼',
  weekly_shift_detail: 'Weekly Shift Details',
  we_eenabled_it_by_default: 'We enabled it by defualt',
  we_noticed_that_you_have_Correction_request:
    'We noticed that you have {{numberOfCorrectionRequests}} correction request in the past 3 months. we created attendance reminders to help reduce this. This is a beta feature and may change in the future.',
  // X
  // Y
  yes_approve_and_submit: 'Yes, approve & submit',
  your_browser_should_ask_you:
    'your browser should ask you to let {{app_name}} access your location in order to help you with the option you selected, please enable this access',
  yes_dismiss_attendance: 'Yes, dismiss attendance',
  you_can_select_from_shifts: 'You can select from shifts',
  you_are_about_to_approve_and_submit_the_attendance:
    'You’re about to approve & submit the attendance report for {{month}} {{year}}',
  you_select_punch_already_selected:
    'You’ve selected a punch that was already recorded as (Out) time. If that’s the case, you need to propose an alternative (Out) time.',
  you_have_allowed_your_employees_to_punch_in:
    'You’ve allowed your employees to punch in {{allowedTime}} after the shift starts without any penalties',
  you_have_assigned_all_employees: 'You’ve assigned all employees 👏🏼 🎉',
  you_are_about_to_delete_working_device: 'You’re about to delete a working device.',
  you_configured_method_only: 'You configured {{method_name}} only',
  you_have_created_shifts: 'You’ve created {{shiftsCount}} shifts',
  you_need_to_defin_punch_methods_or_shift_rules_first:
    'You need to define punch methods or shift rules first',
  you_will_not_able_to_assign_staff_to_shifts:
    'You won’t be able to assign staff to shifts before Finishing your setup',
  you_choose_both_geofencing_and_bio:
    'You choose both geofencing and bio. devices as punching methods, you configured only {{method_name}}.',
  you_can_remind_them_before_after_their_shifts:
    'You can remind them before & after their shifts to remind them to clock in & out',
  you_can_only_connect_maximum_devices:
    'You can only connect a maximum of 10 {{appName}}-hosted WDMS devices. Delete an existing device to continue, or contact support for other options.',
  // Z
}

export default TimeAttendance
