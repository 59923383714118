import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export const employeesInviteLoad = (id: number): Action => {
  const url = `/v2/onboarding/employees/${id}/invite`

  return {
    type: actions.ONBOARDING_EMPLOYEES_INVITE_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const employeesInviteAllLoad = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/employees/invite_all`

  return {
    type: actions.ONBOARDING_EMPLOYEES_INVITE_ALL_LOAD,
    payload: handleAPI(url, {}, 'POST'),
  }
}
