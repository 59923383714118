const SSO = {
  // C
  config_sso: 'تهيئة SSO',
  copy_caps: 'Copy',
  certificate_SP: 'شهادة SP',
  certification_of_idp: 'شهادة معرف IDP',

  // E
  enter_url_here: 'أدخل الرابط هنا',

  // I
  identity_provider_entity_id: 'معرف مقدم الهوية IDP',
  idp_target_url: 'رابط IDP المستهدف',
  idp_slo_service_url: 'IDP SLO Service URL',

  // P
  provide_the_following: 'يرجى ملء ما يلي',
  pass_certificate_content: 'لصق محتوى الشهادة هنا',

  // S
  sso_configuration: 'SSO Configuration',
  sso_desc: 'تهيئة SSO يدويًا',
  sp_redirection_endpoint: 'رابط SP Redirection Endpoint',
  service_provider_entity_id: 'هوية SP',
  sign_hash_algorithm: 'Signature hash algorithm',
  sha_1: 'SHA-1',
  sha_256: 'SHA-256',
  sha_512: 'SHA-512',
  save_configuration: 'حفظ الإعدادات',
  save_enable_configuration: 'حفظ وتفعيل الإعدادات',
}

export default SSO
