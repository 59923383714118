import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'

import * as actions from './actions'
import { CombosState } from './comboShiftTypes'

const initialState: CombosState = {
  fetching: false,
  loading: false,
  shift_combos: [],
  shift_combo: {},
}

export default function reducer(state = initialState, action: Action): CombosState {
  switch (action.type) {
    case actions.COMBO_SHIFT_LISTING_LOAD_PENDING:
    case actions.DELETE_COMBO_SHIFT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CREATE_COMBO_SHIFT_PENDING:
    case actions.UPDATE_COMBO_SHIFT_PENDING:
    case actions.COMBO_SHIFT_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.CREATE_COMBO_SHIFT_FULFILLED:
    case actions.UPDATE_COMBO_SHIFT_FULFILLED:
    case actions.DELETE_COMBO_SHIFT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.COMBO_SHIFT_LISTING_LOAD_FULFILLED:
      const comboShiftListing: Pick<CombosState, 'shift_combos'> = action.payload.data.data || {}
      return {
        ...state,
        ...comboShiftListing,
        fetching: false,
      }

    case actions.COMBO_SHIFT_LOAD_FULFILLED:
      const comboShift: Pick<CombosState, 'shift_combo'> = action.payload.data.data || {}
      return {
        ...state,
        ...comboShift,
        loading: false,
      }

    case actions.COMBO_SHIFT_LISTING_LOAD_REJECTED:
    case actions.COMBO_SHIFT_LOAD_REJECTED:
    case actions.CREATE_COMBO_SHIFT_REJECTED:
    case actions.UPDATE_COMBO_SHIFT_REJECTED:
    case actions.DELETE_COMBO_SHIFT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
