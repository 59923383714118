import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { OvertimePolicyConfigurationT } from './overtimePolicyConfigurationType'

const initialState: OvertimePolicyConfigurationT = {
  organization_configuration: {},
  fetching: false,
}

export default function reducer(
  state = initialState,
  action: Action,
): OvertimePolicyConfigurationT {
  const { payload } = action
  switch (action.type) {
    case actions.OVERTIME_POLICY_CONFIGURATION_LOAD_PENDING:
    case actions.OVERTIME_POLICY_CONFIGURATION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.OVERTIME_POLICY_CONFIGURATION_LOAD_FULFILLED:
      const overtimePolicyConfigurationLoad: Pick<
        OvertimePolicyConfigurationT,
        'organization_configuration'
      > = payload.data.data
      return {
        ...state,
        ...overtimePolicyConfigurationLoad,
        fetching: false,
      }

    case actions.OVERTIME_POLICY_CONFIGURATION_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      const overtimePolicyConfigurationUpdate: Pick<
        OvertimePolicyConfigurationT,
        'organization_configuration'
      > = payload.data.data
      return {
        ...state,
        ...overtimePolicyConfigurationUpdate,
        fetching: false,
      }

    case actions.OVERTIME_POLICY_CONFIGURATION_UPDATE_REJECTED:
    case actions.OVERTIME_POLICY_CONFIGURATION_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
