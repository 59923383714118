export const EMPLOYEE_EXPIRING_DOCUMENTS_LOAD = 'EMPLOYEE_EXPIRING_DOCUMENTS_LOAD'
export const EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_FULFILLED =
  'EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_FULFILLED'
export const EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_REJECTED = 'EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_REJECTED'
export const EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_PENDING = 'EMPLOYEE_EXPIRING_DOCUMENTS_LOAD_PENDING'

export const COMPANY_EXPIRING_DOCUMENTS_LOAD = 'COMPANY_EXPIRING_DOCUMENTS_LOAD'
export const COMPANY_EXPIRING_DOCUMENTS_LOAD_FULFILLED = 'COMPANY_EXPIRING_DOCUMENTS_LOAD_FULFILLED'
export const COMPANY_EXPIRING_DOCUMENTS_LOAD_REJECTED = 'COMPANY_EXPIRING_DOCUMENTS_LOAD_REJECTED'
export const COMPANY_EXPIRING_DOCUMENTS_LOAD_PENDING = 'COMPANY_EXPIRING_DOCUMENTS_LOAD_PENDING'
