const Scheduler = {
  // A
  add_shift: 'Add shift',
  assign_shift: 'Assign Shift',
  avoid_days_with_existing_shifts: 'Skip days with scheduled shifts',
  all_employees_count: 'All employees ({{count}})',
  attendance_exemption: 'Attendance exemption',
  assign_shifts_to_employee:
    'Assign shifts to employees following the defined format in the template',
  accepted_file_formats: 'Accepted file formats (csv, xls, xlsx)',
  any_existing_shifts_for_the_selected_employees:
    'Any existing shifts for the selected employees and period will be replaced with the imported',
  approved_overtime_will_not_be_effected: 'Approved overtime will not be effected',
  assigning_new_shift_preset: 'Assigning New Shift Preset',
  approved_overtime_will_not_be_affected_by_shift_changes:
    'Approved overtime will not be affected by shift changes, but changes might affect worked hours in these days',
  all_shifts_uploaded_successfully: 'All shifts uploaded successfully',

  // B
  // C
  conflict: 'Conflict',
  conflicts: 'Conflicts',
  clear_schedule: 'Clear Schedule',
  conflicts_that_cant_published: 'Conflicts that can’t be published',
  conflicted_shift: 'Conflicted Shift',
  continue_to_unpublish:
    'Continue to unpublish <span>{{shift_count}} shifts</span> for <span>{{empNumberMessage}}</span> for <span>{{dateRange}}</span>?',
  continue_to_clear:
    'Continue to clear <span>{{shift_count}} shifts</span> for <span>{{empNumberMessage}}</span> for <span>{{dateRange}}</span>?',
  continue_to_remove:
    'Continue to remove <span>{{shift_count}} shift</span> for <span>{{empNumberMessage}}</span> for <span>{{dateRange}}</span>?',
  clear_shifts_count: 'Clear {{count}} Shifts',
  conflict_with_current_schedule: 'Conflict with current schedule',
  click_to_upload: 'Click to upload',
  confirm_and_import: 'Confirm & Import',
  conflict_found: 'Conflict found',
  // D
  day_off: 'Day Off',
  day_off_associated: 'Day Off can not be associated with other shifts',
  do_you_want_to_repeat_this_shift: 'Do you want to repeat this shift?',
  DAILY: 'DAILY',
  default_assigned_location: 'Registered location in employee profile',
  dept: 'Dept',
  download_template: 'Download Template',
  download_rejected_schedules: 'Download Rejected Schedules',
  days_import: 'days',
  // E
  employee_rejected_schedules: '{{rejected_employees}} Rejected Schedules',
  employees_in_file: 'Employees in file',
  empty_day_cells_will_be_imported_unscheduled: 'Empty day cells will be imported as “Unscheduled”',
  excuse_or_correction_requests_associated_with_existing:
    'Excuse or correction requests associated with existing shifts will be expired',
  errors_resulted_in_file_rejection:
    'Errors resulted in file rejection are specified in the Rejected Records file, you can upload it again to correct it.',
  employees_have_conflictions: '{{count}} employees have conflictions',
  // F
  fixed: 'Fixed',
  failed_bulk: 'Failed',
  // G

  // H
  half_day_off: 'Half Day Off',
  half_day: 'Half Day',
  // I
  import_bulk_employees_schedulers: 'Import bulk employees schedulers',
  if_day_had_existing_shifts:
    'If a day had existing shifts and was left empty in the imported file, existing shifts will be removed.',
  if_existing_shift_had_pending_or_approved:
    'If an existing shift had pending or approved excuse or correction requests and shift was changed in the imported file, those requests will expire.',
  in_which_locations: 'In Which Locations',
  // J
  // K
  // L
  learn_more_about_bulk: 'Learn more about bulk schedule import',
  // M
  // N
  notify_affected_employee_email: 'Notify affected employees',
  notify_employee_email: 'Notify employee',
  // O
  overtime_for: 'Overtime for',
  or_drag_and_drop_here: 'or drag and drop here',
  oldest_created_shifts_first: 'Oldest created shifts first',
  // P
  publish: 'Publish',
  publish_shifts_count: 'Publish {{count}} Shifts',
  publish_shift: 'Publish Shift',
  prepare: 'Prepare',
  processing_file: 'Processing file',
  partially_completed: 'Partially completed',
  processing_bulk: 'Processing',

  // Q
  // R
  rotated: 'Rotated',
  recipients: 'Recipients',
  repalce_existing_shifts:
    'Replace scheduled shifts with Day Off (scheduled shifts will be removed)',
  repeat_days: 'Repeat days',
  recently_created_shifts_first: 'Recently created shifts first',
  // S
  shift_template: 'Shift template',
  shifts_with_approved_attendace: 'Shifts with approved attendace (Editing not allowed)',
  some_employees_have_conflicting_shifts: 'Some employees have conflicting shifts',
  some_employees_have_existing_shifts:
    'Some of the selected employees have existing shifts in the selected day',
  shift_presets_needs_to_be_defined:
    'Shift Presets needs to be defined in Shift Settings before scheduling in the template.',
  some_employees_have_approved: 'Some employees have approved attendance days',
  shift_conflict_with_other:
    'Shifts that conflict/overlap with other shifts can not be published. Please address the conflict then try again.',
  selected_filtered_employees_count: 'selected/filtered employees ({{count}})',
  save_shift_as_template: 'Save this shift as new template',
  search_combo_name: 'Search combo name',
  scheduled_hours_week: 'Scheduled hours for this week',
  scheduled_hours_day: 'Scheduled hours for this day',
  schedule_and_prepare_file_for_upload: 'Schedule & Prepare File for Upload',
  select_period: 'Select Period',
  save_this_file_as: 'Save the file as (csv), (xlsx) or (xls) format then upload it here',
  schedule_period: 'Schedule Period',
  successful: 'Successful',
  save_as_draft: 'Save as Draft',
  server_error: 'Server Error',
  select_employees_and_shift: 'Select employees and shift',
  set_repetition: 'Set repetition',
  should_they_work_on: 'Should they work on',
  starting_and_repeating: 'Starting and Repeating',
  set_when_should_this_settings: 'Set when should this settings apply and for how long',
  sort: 'Sort',
  save_and_publish: 'Save and Publish',
  // T
  type_your_comment: 'Type your comment',
  total_scheduled_hours: 'Total scheduled hours',
  total_unpublished_shifts: 'Total unpublished shifts',
  total_unpublished_dayoff: 'Total unpublished day off',
  total_already_published_shifts: 'Total already published shifts',
  total_published_dayoff: 'Total published day off',
  total_shifts_published_unpublished: 'Total shifts (Published & Unpublished)',
  total_dayoff_published_unpublished: 'Total day off (Published & Unpublished)',
  this_can_not_be_undone: 'This can not be undone.',
  this_will_affect_attendance_calculation:
    'This will affect attendance calculation for those shifts',
  this_will_clear_any_shift:
    'This will clear any shift that starts in the current view. However, this will not clear shifts that only end in the current view.',
  total_hours_week: 'Total hours for this week',
  total_hours_day: 'Total hours for this day',
  this_will_expire_attached: 'This will expire the attached excuse requests to this shift.',
  this_will_remove_added_overtime: 'This will remove the added overtime via shift or the manager.',
  this_might_take_some_time: 'This might take some time depending on the file size',
  // U
  unpublish: 'Unpublish',
  unpublish_shifts_count: 'Unpublish {{count}} Shifts',
  unpublishing_to_draft_mode:
    'Unpublishing shifts will set them back to draft mode, which is invisable to employees, and is not considered when calculating attendance',
  unpublish_shifts: 'Unpublish Shifts',
  unpublish_shift: 'Unpublish Shift',
  use_this_spreadsheet_template:
    'Use this spreadsheet template to avoid difficulties in uploading your schedules.',
  upload_schedule_file: 'Upload Schedule File',
  upload_schedule: 'Upload Schedule',
  uploading_schedule: 'Uploading Schedule',
  uploaded_schedule_will_replace_existing: 'Uploaded schedule will replace existing',
  upload_complete: 'Upload Completed',
  upload_corrected_file: 'Upload Corrected File',
  uploaded_file_does_match_the_template:
    'Uploaded file does match the template, please make sure headers are as the following format and order: <br /> 1. Employee ID <br /> 2. Employee Name EN <br />3. Employee Name AR <br /> 4.Job Title <br />5. Department <br />6. Location <br />7. Area <br />8.Business unit',

  // V
  // W
  WEEKLY: 'WEEKLY',
  wrong_template_format: 'Wrong template format',
  // X
  // Y
  yes_unpublish: 'Yes, Unpublish',
  yes_remove_shift: 'Yes, Remove Shift',
  yes_clear_shifts: 'Yes, Clear Shifts',
  yes_clear: 'Yes, Clear',
  you_do_not_have_permission_to_publish_shifts: "You don't have the permission to publish Shifts",
  you_can_select_from_shifts_or_shift_templates: 'You can select from shifts or shift templates',
  // Z
}

export default Scheduler
