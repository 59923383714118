import { Flex, Typography } from '@jisr-hr/ds'
import { useSelector } from 'utils/hooks'
import styles from './style.module.css'
import { ReactComponent as LightInfo } from './assets/lightInfo.svg'

const CostumeAlert = (): JSX.Element => {
  const { errorMsg } = useSelector(({ validationAdminInvited }) => validationAdminInvited)

  return (
    <div className={styles.costumeAlertContainer}>
      <Flex
        justifyCenter
        itemsCenter
        className="my-[8px] py-[4px] gap-[8px]"
      >
        <LightInfo />

        <Typography
          variant="subtitle-2"
          style={{ width: 270 }}
          text={errorMsg}
        />
      </Flex>
    </div>
  )
}

export default CostumeAlert
