import React from 'react'
import PropTypes from 'prop-types'
// import { format } from 'utils/date';
import I18n from 'translations/i18n'
import { statusTypes } from 'utils/uiHelpers'
import { Flex, Spacer } from '@jisr-hr/ds'
import Frame from '../../ds/Frame'
import ProfileAndTime from '../../ds/ProfileAndTime'
import InfoBadge from '../Info/components/InfoBadge'

const Assignee = ({ details }) => {
  return (
    <Frame title={I18n.t('assignee')}>
      <ProfileAndTime
        name={details?.assignee?.full_name_i18n}
        code={details?.assignee?.code}
        jobTitle={details?.assignee?.job_title_i18n}
        avatar={details?.assignee?.avatar_thumb}
      />
      <Flex>
        <Spacer width={48} />
        <InfoBadge
          label={details?.status_i18n}
          type={statusTypes[details?.status?.toLowerCase()] || 'gray'}
        />
      </Flex>
    </Frame>
  )
}
Assignee.propTypes = {
  details: PropTypes.shape(),
}
export default Assignee
