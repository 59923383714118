import { Action } from 'types/redux'
import { TTrigger, TConstants } from 'containers/authorised/Automation/types'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type StateTypes = {
  fetching: boolean
  automation_triggers: TTrigger[]
  constants: Partial<TConstants>
}

const DEFAULT_STATE: StateTypes = {
  fetching: false,
  automation_triggers: [],
  constants: {},
}

type AutomationType = typeof DEFAULT_STATE

export default function reducer(state = DEFAULT_STATE, action: Action): AutomationType {
  switch (action.type) {
    case actions.AUTOMATION_TRIGGERS_LIST_PENDING:
    case actions.AUTOMATION_TRIGGERS_CONSTANTS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.AUTOMATION_TRIGGERS_CONSTANTS_FULFILLED: {
      return {
        ...state,
        constants: action.payload.data.data.constants,
        fetching: false,
      }
    }
    case actions.AUTOMATION_TRIGGERS_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        automation_triggers: [
          ...(action.payload.data.data?.pagination?.previous_page ? state.automation_triggers : []),
          ...action.payload.data.data?.automation_triggers,
        ],
        fetching: false,
      }
    }
    case actions.AUTOMATION_TRIGGERS_LIST_REJECTED:
    case actions.AUTOMATION_TRIGGERS_CONSTANTS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
