export const SUMMARYSHEET_LISTING = 'SUMMARYSHEET_LISTING'
export const SUMMARYSHEET_LISTING_PENDING = 'SUMMARYSHEET_LISTING_PENDING'
export const SUMMARYSHEET_LISTING_FULFILLED = 'SUMMARYSHEET_LISTING_FULFILLED'
export const SUMMARYSHEET_LISTING_REJECTED = 'SUMMARYSHEET_LISTING_REJECTED'

export const EMPLOYEE_SUMMARYSHEET = 'EMPLOYEE_SUMMARYSHEET'
export const EMPLOYEE_SUMMARYSHEET_PENDING = 'EMPLOYEE_SUMMARYSHEET_PENDING'
export const EMPLOYEE_SUMMARYSHEET_FULFILLED = 'EMPLOYEE_SUMMARYSHEET_FULFILLED'
export const EMPLOYEE_SUMMARYSHEET_REJECTED = 'EMPLOYEE_SUMMARYSHEET_REJECTED'

export const TIMESHEET_EMPLOYEES = 'TIMESHEET_EMPLOYEES'
export const TIMESHEET_EMPLOYEES_PENDING = 'TIMESHEET_EMPLOYEES_PENDING'
export const TIMESHEET_EMPLOYEES_FULFILLED = 'TIMESHEET_EMPLOYEES_FULFILLED'
export const TIMESHEET_EMPLOYEES_REJECTED = 'TIMESHEET_EMPLOYEES_REJECTED'

export const RESET_SUMMARYSHEET_LISTING = 'RESET_SUMMARYSHEET_LISTING'

export const RESET_EMPLOYEE_SUMMARYSHEET_LISTING = 'RESET_EMPLOYEE_SUMMARYSHEET_LISTING'
