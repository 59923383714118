import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { PaginationTypes } from 'types/sharedResponse'
import { Action } from 'types/redux'

type DefaultStateType = {
  custom_fields: {
    id: number | string
    data_type: string
  }[]
  pagination: PaginationTypes | Record<string, unknown>
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
}

const DEFAULT_STATE: DefaultStateType = {
  custom_fields: [],
  pagination: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

type State = typeof DEFAULT_STATE

let changes = null
export default function customFieldsReducer(state = DEFAULT_STATE, action: Action): State {
  const { payload } = action
  switch (action.type) {
    case 'CUSTOM_FIELDS_LOAD_PENDING':
    case 'CUSTOM_FIELDS_UPDATE_PENDING':
    case 'CUSTOM_FIELDS_CREATE_PENDING':
    case 'CUSTOM_FIELDS_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'CUSTOM_FIELDS_LOAD_REJECTED':
    case 'CUSTOM_FIELDS_UPDATE_REJECTED':
    case 'CUSTOM_FIELDS_CREATE_REJECTED':
    case 'CUSTOM_FIELDS_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CUSTOM_FIELDS_LOAD_FULFILLED':
    case 'CUSTOM_FIELDS_UPDATE_FULFILLED':
    case 'CUSTOM_FIELDS_CREATE_FULFILLED':
    case 'CUSTOM_FIELDS_DELETE_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
