import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

export type TLeaves = {
  code: string
  name_i18n: string
  employment_type: string
  leave_id: number
  annual_leave_entitlement: null | number
  opening_balance: null | number
  up_to_which_date: null | string

  id: number
  annual_leave: {
    previous_year_balance: null | number
    annual_leave_days: null | number
  }
}

export const initialState = {
  fetching: false,
  leaves: [] as TLeaves[],
  pagination: {} as PaginationTypes,
}

export default function reducer(
  state = initialState,
  { type, payload }: Action,
): typeof initialState {
  switch (type) {
    case actions.ONBOARDING_LEAVE_LOAD_PENDING:
    case actions.ONBOARDING_LEAVE_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_LEAVE_LOAD_FULFILLED:
      const isNewOnboarding = window.location.hash.includes('#/new_onboarding')
      return {
        ...state,
        ...payload.data.data,
        ...(isNewOnboarding && {
          leaves: payload.data.data?.onboarding_leaves,
        }),
        fetching: false,
      }

    case actions.ONBOARDING_LEAVE_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_LEAVE_LOAD_REJECTED:
    case actions.ONBOARDING_LEAVE_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
