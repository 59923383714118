import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import { CompetencySetProps } from 'types/talentRetention/competencies'
import * as actions from './actions'

export type StateType = {
  fetching: boolean
  submitting: boolean
  competency_set: CompetencySetProps
  competency_sets: CompetencySetProps[]
  pagination: PaginationTypes | Record<string, unknown>
  success?: boolean
  fetching_share?: boolean
}

export const initialState: StateType = {
  fetching: false,
  submitting: false,
  competency_sets: [],
  competency_set: {} as CompetencySetProps,
  pagination: {},
}

export default function reducer(state = initialState, action: Action): StateType {
  switch (action.type) {
    case actions.COMPETENCY_SETS_LOAD_PENDING:
    case actions.COMPETENCY_SETS_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.COMPETENCY_SETS_SHOW_PENDING:
    case actions.COMPETENCY_SETS_UPDATE_PENDING:
    case actions.COMPETENCY_SETS_CREATE_PENDING:
    case actions.ASSIGN_COMPETENCY_SETS_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.COMPETENCY_SETS_LOAD_FULFILLED: {
      const { previous_page } = action.payload.pagination
      return {
        ...state,
        competency_sets: [...(previous_page ? state.competency_sets : []), ...action.payload.data],
        pagination: action.payload.pagination,
        success: action.payload.success,
        fetching: false,
      }
    }
    case actions.COMPETENCY_SETS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ASSIGN_COMPETENCY_SETS_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        submitting: false,
      }

    case actions.COMPETENCY_SETS_SHOW_FULFILLED:
      return {
        ...state,
        competency_set: action.payload.data.data,
        submitting: false,
      }
    case actions.COMPETENCY_SETS_UPDATE_FULFILLED:
    case actions.COMPETENCY_SETS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        competency_set: action.payload.data.data,
        submitting: false,
      }

    case actions.COMPETENCY_SETS_LOAD_REJECTED:
    case actions.COMPETENCY_SETS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }

    case actions.COMPETENCY_SETS_SHOW_REJECTED:
    case actions.COMPETENCY_SETS_UPDATE_REJECTED:
    case actions.COMPETENCY_SETS_CREATE_REJECTED:
    case actions.ASSIGN_COMPETENCY_SETS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
