import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Spacer, Typography, Avatars } from '@jisr-hr/ds'
import { ReactComponent as RedirctIcon } from 'assets/figma-icons/RedirctIcon.svg'
import stoc from 'string-to-color'
import styles from './style.module.css'

const renderNameBadges = (name, badges) => {
  const renderName = () => (
    <Typography
      text={name}
      variant="body-new/regular"
    />
  )
  if (!name && !badges) return null
  if (!badges) return renderName()
  return (
    <Flex itemsCenter>
      {renderName()}
      <Spacer width={8} />
      {badges}
    </Flex>
  )
}

const EmployeeJobIdAvatar = ({
  id,
  name,
  badges,
  jobTitle,
  avatar,
  avatarsize,
  scrollPosition,
  onRedirect,
  ...rest
}) => {
  let additionalInfo = ''
  if (jobTitle) additionalInfo = jobTitle
  if (jobTitle && id) additionalInfo += ' - '
  if (id) additionalInfo += id
  return (
    <div
      className={styles.employeeJobIdAvatar}
      {...rest}
    >
      <Flex itemsCenter>
        <Avatars
          size="m"
          imageSrc={avatar}
          imageAlt={name}
          color={stoc(name)}
        />
        <Spacer width="8px" />
        <div>
          {renderNameBadges(name, badges)}
          {additionalInfo && (
            <Typography
              text={additionalInfo}
              variant="subtitle-2"
              textColor="color/fg/light"
            />
          )}
        </div>
        {onRedirect && (
          <RedirctIcon
            style={{ cursor: 'pointer', margin: '0 0 16px 10px' }}
            onClick={onRedirect}
          />
        )}
      </Flex>
    </div>
  )
}
EmployeeJobIdAvatar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  badges: PropTypes.node,
  jobTitle: PropTypes.string,
  onRedirect: PropTypes.func,
  avatar: PropTypes.node,
  avatarsize: PropTypes.number,
  scrollPosition: PropTypes.shape(),
}

EmployeeJobIdAvatar.defaultProps = {
  avatarsize: 32,
}

export default React.memo(EmployeeJobIdAvatar)
