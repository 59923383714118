import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { RatingV2Props } from 'types/talentRetention/ratingMethods'
import * as actions from './actions'

export type RatingMethodType = {
  id?: number
  level_number?: number
  from_rating?: number | null
  to_rating?: number | null
  label_en: string
  label_ar: string
}

export type RatingSubmitType = {
  number_of_levels?: number
  rateable_type?: string
  rating_type?: string
  rating_method_levels?: RatingV2Props[]
  deleted_rating_method_levels_ids?: (number | undefined)[]
  is_enabled?: boolean
}

export type RatingType = {
  id: number
  type: string
  rateable_type?: string
  rating_type?: string
  number_of_levels?: number
  rating_method_levels?: RatingMethodType[]
  is_enabled?: boolean
}

export type initialStateType = {
  fetching: boolean
  organizationRatings: RatingType[]
  usedRatingMethods: string[]
}

export const initialState: initialStateType = {
  fetching: false,
  organizationRatings: [],
  usedRatingMethods: [],
}

export default function reducer(state = initialState, action: Action): initialStateType {
  switch (action.type) {
    case actions.RATING_CONFIGURATIONS_V2_LOAD_PENDING:
    case actions.RATING_CONFIGURATIONS_V2_CREATE_PENDING:
    case actions.RATING_CONFIGURATIONS_V2_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.RATING_CONFIGURATIONS_V2_LOAD_FULFILLED:
      return {
        ...state,
        organizationRatings: action.payload.data.data,
        usedRatingMethods: action.payload.data?.used_rating_methods,
        fetching: false,
      }

    case actions.RATING_CONFIGURATIONS_V2_CREATE_FULFILLED:
    case actions.RATING_CONFIGURATIONS_V2_UPDATE_FULFILLED:
      Toastr.success(action.payload?.data?.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.RATING_CONFIGURATIONS_V2_LOAD_REJECTED:
    case actions.RATING_CONFIGURATIONS_V2_CREATE_REJECTED:
    case actions.RATING_CONFIGURATIONS_V2_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
