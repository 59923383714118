import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  TExtendsEmployee,
  TPagination,
} from 'redux/organizationOnborading/employees/employees.type'

import * as actions from './actions'

export const initialState = {
  fetching: false,
  employees: [] as TExtendsEmployee[],
  pagination: {} as TPagination,
}

type TInitState = typeof initialState

export default function reducer(state = initialState, { type, payload }: Action): TInitState {
  switch (type) {
    case actions.ONBOARDING_EMPLOYEES_WITH_EMAIL_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_EMPLOYEES_WITH_EMAIL_LOAD_FULFILLED:
      return {
        ...state,
        employees: payload.data.data.employees,
        pagination: payload.data.data.pagination,
        fetching: false,
      }

    case actions.ONBOARDING_EMPLOYEES_WITH_EMAIL_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
