import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query'
import { Toastr } from 'components/global/Toastr'
import { API_VERSION_TWO, api } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import { DataType, BulkMoveType } from './useUpdateOrgDataType'

// ? functions
const updateOrgInfo = (params: DataType): Promise<BulkMoveType> => {
  return api({
    url: `/${API_VERSION_TWO}/employees/bulk_move`,
    method: 'PUT',
    data: params,
  })
}

// ? hooks
/**
 * @endpoint: PUT: v2/employees/bulk_move
 * @summary This hook used to updating location and department on onboarding highTier
 */
export const useOnboardingBulkMove = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<BulkMoveType, TError, DataType>
}): UseMutationResult<BulkMoveType, TError, DataType> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => updateOrgInfo(payload),
    onSuccess: (res, variables, context) => {
      Toastr.success(res?.data?.message)
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.message)
    },
  })
}
