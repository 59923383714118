import { FC } from 'react'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type DeviceStatusProps = {
  title: string
  filterKey: string
  filters: string[]
}

const DeviceStatus: FC<DeviceStatusProps> = (props) => {
  const { device_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={device_status}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default DeviceStatus
