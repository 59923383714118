import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.MARKED_AS_COMPLETED_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.MARKED_AS_COMPLETED_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        competency_sets: action.payload.data.data,
        fetching: false,
      }

    case actions.MARKED_AS_COMPLETED_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }

    default:
      return state
  }
}
