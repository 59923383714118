import React from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { format } from 'utils/date'
import { FileUpload } from '@jisr-hr/ds-beta'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { useSelector } from 'utils/hooks'
import { useRequest } from '../../RequestProvider'
import { ReactComponent as MoneyIcon } from '../../icons/Money.svg'
import { ReactComponent as DivisionMarkIcon } from '../../icons/DivisionMark.svg'
import { getMonthDifference } from '../../helper'
import { formatMonth } from '../../components/helper'
import useTaskFooter from '../../Footer/useTaskFooter'

const LoanRequestTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { onAttachTaskFile } = useTaskFooter()
  const { fetching_submit } = useSelector((state) => state.approvals)
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          type: 'loan_request',
          id: request.id,
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request?.status_i18n,
          due_date: request.due_date,
          type_i18n: request?.name,
          loan_type_i18n: request?.task_info?.loan_type?.name,
          created_at: request?.created_at,
          requested_on: request?.created_at,
          payment_date: request?.task_info?.paid_at,
          items: [
            request?.task_info?.amount && {
              label: i18n.t('loan_amount'),
              icon: <MoneyIcon />,
              value: `${i18n.t('sar')} ${request?.task_info?.amount}`,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('monthly_deduction'),
              value: `${i18n.t('sar')} ${request?.task_info?.monthly_deduction}`,
            },
            {
              label: i18n.t('installment'),
              icon: <DivisionMarkIcon />,
              value: `${getMonthDifference(
                new Date(request?.task_info?.issue_date),
                new Date(request?.task_info?.return_date),
              )} ${i18n.t('months')}`,
            },
            {
              label: i18n.t('period_of_deduction'),
              icon: <DivisionMarkIcon />,
              value: `${format(
                new Date(request?.task_info?.issue_date),
                `${formatMonth} yyyy`,
              )} - ${format(new Date(request?.task_info?.return_date), `${formatMonth} yyyy`)}`,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('payment_type'),
              value: request.task_info?.payment_details?.payment_type_i18n,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_name'),
              value: request.task_info?.payment_details?.bank_name_i18n,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_iban'),
              value: request.task_info?.payment_details?.iban,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('salary_type'),
              value: request.task_info?.payment_details?.salary_type_i18n,
            },
          ],
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default LoanRequestTask
