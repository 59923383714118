import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CandidateRatingProps = {
  filterKey: string
  filters: Array<string>
}

const CandidateRating = (props: CandidateRatingProps): JSX.Element => {
  const { candidate_rating } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(candidate_rating)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        candidate_rating: ['all'],
      })
      return
    }
    const updatedRatings = [...filter.candidate_rating]
    if (updatedRatings.includes('all')) {
      updatedRatings.splice(updatedRatings.indexOf('all'), 1)
    }
    const index = updatedRatings.indexOf(val)
    if (index >= 0) {
      updatedRatings.splice(index, 1)
      if (updatedRatings.length === 0) {
        setFilter({
          ...filter,
          candidate_rating: ['all'],
        })
        return
      }
      setFilter({
        ...filter,
        candidate_rating: updatedRatings,
      })
      return
    }
    setFilter({
      ...filter,
      candidate_rating: [...updatedRatings, val],
    })
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      isClearable={false}
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('label.rating')}
    />
  )
}

export default CandidateRating
