import { useState } from 'react'
import { Modal } from '@jisr-hr/ds'
import { useSelector } from 'react-redux'
import {
  Typography,
  Button,
  Flex,
  Badge,
  Textarea,
  Spacer,
  Avatars,
  ScrollBarContainer,
} from '@jisr-hr/ds-beta'
import { format } from 'utils/date'
import { Scrolling } from 'components/global/atoms/Lazyload'
import {
  useAddComment,
  useMarkAnnouncementAsRead,
  useGetSingleAnnouncement,
} from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg'
import JisrLoader from 'components/global/JisrLoader'
import i18n from 'translations/i18n'
import AnnouncementWidgetBody from './announcementWidgetBody'

interface SingleAnnouncementModalProps {
  announcementId: number
  isOpen: boolean
  onClose: () => void
}

const SingleAnnouncementModal = ({
  announcementId,
  isOpen,
  onClose,
}: SingleAnnouncementModalProps) => {
  const { employee } = useSelector(({ auth }) => auth)
  const [commentText, setCommentText] = useState<string>('')
  const { mutate: markAsRead } = useMarkAnnouncementAsRead()
  const { mutate: addComment } = useAddComment()

  const { data, isFetching, refetch } = useGetSingleAnnouncement(announcementId, {
    query: {
      select: ({ data: responseData }) => ({
        announcementData: responseData?.data?.announcement_recipient || {},
      }),
      enabled: !!announcementId,
      staleTime: 0,
      cacheTime: 0,
    },
  })

  const announcementData = data?.announcementData

  const handleMarkAsRead = () => {
    if (announcementData?.id) {
      markAsRead(
        { id: announcementData.id },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    }
  }

  const handleLeaveComment = () => {
    if (announcementData?.id) {
      addComment(
        {
          comment: {
            commentable_type: 'Announcement',
            commentable_id: announcementData.announcement.id,
            content: commentText,
          },
        },
        {
          onSuccess: () => {
            setCommentText('')
            refetch()
          },
        },
      )
    }
  }

  return (
    <Modal
      variant="info"
      size="large"
      open={isOpen}
      onClose={onClose}
      header={{
        leadingSpace: {
          custom: (
            <Flex
              justifyBetween
              style={{ gap: 12 }}
              flexCol
            >
              <Flex
                style={{ gap: 12 }}
                flexRow
              >
                <img
                  src={announcementData?.announcement?.icon_url}
                  alt={announcementData?.announcement?.title}
                  width={30}
                  height={30}
                  className="m-1"
                />

                <Flex flexCol>
                  <Flex style={{ gap: 12 }}>
                    <Typography
                      text={announcementData?.announcement?.title}
                      variant="interface/strong/ml"
                    />
                    <Badge
                      label={i18n.t(announcementData?.announcement?.priority_level_i18n)}
                      color={
                        announcementData?.announcement?.priority_level_i18n !== 'Normal'
                          ? 'orange'
                          : 'gray'
                      }
                      size="small"
                      variant="tinted"
                    />
                  </Flex>
                  <Typography
                    text={`${i18n.t('label.published_on')} ${format(
                      announcementData?.announcement?.created_at,
                      'dd MMM, yyyy',
                    )}`}
                    variant="body-new/regular"
                    textColor="color/fg/light"
                  />
                </Flex>
              </Flex>
              <Spacer height={12} />
              <div
                dangerouslySetInnerHTML={{ __html: announcementData?.announcement?.body ?? '' }}
              />
            </Flex>
          ),
        },
        withClose: true,
      }}
      footer={
        !announcementData?.is_read
          ? {
              submit: {
                label: i18n.t('action.mark_as_read'),
                leadingIcon: 'check',
                onClick: handleMarkAsRead,
              },
            }
          : {}
      }
    >
      <Flex
        flexCol
        style={{ gap: 12, paddingBottom: '20px' }}
      >
        {isFetching ? (
          <JisrLoader />
        ) : (
          <ScrollBarContainer height="50vh">
            <Scrolling className="scroll-y scroll-x">
              <AnnouncementWidgetBody announcement={announcementData?.announcement} />
            </Scrolling>
          </ScrollBarContainer>
        )}

        {announcementData?.announcement?.allow_comment && (
          <Flex style={{ gap: 12 }}>
            <Avatars
              imageSrc={employee?.avatar_thumb}
              imageAlt={employee?.full_name_i18n}
            />
            <Textarea
              onChange={(value) => setCommentText(value)}
              value={commentText}
              resizable
            />
            <Button
              label={i18n.t('action.publish_comment')}
              color="primary"
              variant="tinted"
              size="medium"
              disabled={!commentText}
              onClick={handleLeaveComment}
            />
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}

export default SingleAnnouncementModal
