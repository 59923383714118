import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import i18n from 'translations/i18n'
import useBenefitsManagementPermissions from 'containers/authorised/BenefitsManagement/permissions/useBenefitsManagementPermissions'
import { useRequest } from '../../RequestProvider'
import FlightTicketDetailsSection from '../../components/FlightTicketComponents/FlightTicketDetailsSection'
import Dependents from '../../components/FlightTicketComponents/Dependents'

const FlightTicketRequestTask = (): JSX.Element => {
  const {
    vars: { request },
  } = useRequest()
  const { isAnnualFlightTicketsEnabled } = useBenefitsManagementPermissions()

  return (
    <div className="flex flex-col gap-[16px]">
      <Info
        details={{
          ...request,
          type_i18n: request?.name,
          status: request.status,
          status_i18n: request?.status_i18n,
          policy_details: request?.task_info?.policy_details,
          hasFlightTicketPermission: isAnnualFlightTicketsEnabled,
        }}
        showColumns={[
          request?.policy_details?.policy_status !== 'no_policy' && 'flight_ticket_policy_status',
          'flight_ticket_date',
        ].filter(Boolean)}
      />
      <FlightTicketDetailsSection
        departure_date={request?.task_info?.departure_date}
        return_date={request?.task_info?.return_date}
        destination={request?.task_info?.travel_destination}
        round_trip={request?.task_info?.round_trip}
        cabin_class={request?.task_info?.flight_classes_i18n}
        exception_attributes={request?.task_info?.exception_attributes}
      />

      {!!request?.task_info?.dependents?.length && (
        <Dependents
          dependents={request?.task_info?.dependents ?? []}
          exceptionNoOfDependents={
            request?.task_info?.exception_attributes?.exception_no_of_dependents
          }
          numberOfDependentCovered={request?.task_info?.no_of_dependents_covered}
        />
      )}

      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}

      {request?.status === 'Pending' && <ReassignTask />}

      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </div>
  )
}

export default FlightTicketRequestTask
