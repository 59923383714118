import React from 'react'
import { Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import {
  Info,
  Reason,
  ReassignTask,
  Workflow,
  Assignee,
} from 'components/global/templates/RequestDrawer/components'
import { isEmpty } from 'lodash'
import { FileUpload } from '@jisr-hr/ds-beta'
import { useSelector } from 'utils/hooks'
import { useRequest } from '../../../RequestProvider'
import { ReactComponent as CalendarIcon } from '../../../icons/calendar.svg'
import { ReactComponent as UserIcon } from '../../../icons/user.svg'
import { ReactComponent as MoneyIcon } from '../../../icons/Money.svg'
import TicketInformation from './TicketInformation'
import ExitReentryInfo from './ExitReentryInfo'
import useTaskFooter from '../../../Footer/useTaskFooter'

const LeaveRequestTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { onAttachTaskFile } = useTaskFooter()
  const { fetching_submit } = useSelector((state) => state.approvals)

  const approvalsDetails = request?.approvals_details?.map((item) => ({
    ...item,
    assignee: {
      ...item?.assignee,
      name: item?.assignee?.name_i18n,
    },
  }))

  const showPatment = !['exit_reentry_issuing_task', 'issuing_ticket_task'].includes(
    request?.category,
  )
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          requested_on: request?.request?.created_time,
          created_at: request?.request?.created_time,
          due_date: request.due_date,
          type_i18n: request.name,
          items: [
            {
              icon: <CalendarIcon />,
              label: i18n.t('leave_start_date'),
              value: format(request?.task_info?.from, 'MMMM dd, yyyy') || '',
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('leave_to_date'),
              value: format(request?.task_info?.to, 'MMMM dd, yyyy') || '',
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('resumption_date'),
              value: request?.task_info?.return_date,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('applied_days'),
              value: request?.task_info?.applied_days,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('difference_in_days'),
              value: request?.task_info?.difference_in_days,
            },
            {
              icon: <UserIcon />,
              label: i18n.t('reason'),
              value: request?.task_info?.reason,
            },
            showPatment && {
              icon: <MoneyIcon />,
              label: i18n.t('payment_type'),
              value: request.task_info?.payment_details?.payment_type_i18n,
            },
            showPatment && {
              icon: <MoneyIcon />,
              label: i18n.t('bank_name'),
              value: request.task_info?.payment_details?.bank_name_i18n,
            },
            showPatment && {
              icon: <MoneyIcon />,
              label: i18n.t('bank_iban'),
              value: request.task_info?.payment_details?.iban,
            },
            showPatment && {
              icon: <MoneyIcon />,
              label: i18n.t('salary_type'),
              value: request.task_info?.payment_details?.salary_type_i18n,
            },
          ],
        }}
      />
      <TicketInformation />
      <ExitReentryInfo />
      {!isEmpty(approvalsDetails) && <Workflow approvals={request.approvals} />}
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request?.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              compact
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default LeaveRequestTask
