import CheckMarkIcon from 'assets/images/check-mark.png'
import blueSpinner from 'assets/images/blue-spinner.gif'
import WarnRedIcon from 'assets/images/warn-red.png'
import WarnIcon from 'assets/images/warn-yellow.png'

export type StatusValueType = 0 | 1 | 2 | 3 | 4

export const statusImages = {
  4: WarnIcon,
  3: CheckMarkIcon,
  2: WarnRedIcon,
  0: blueSpinner,
}

export type UploadingProcessProps = {
  errorFileUrl: string
  metadata: {
    failed_count?: number
    success_count?: number
  }
  s3Url: string
  statusValue: StatusValueType
}
