import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useHistory } from 'react-router-dom'

import VerticalStepper from '../components/VerticalStepper'
import SharedStep from '../components/SharedStep'
import { OnboardingContext } from '../context'

import { StepsTypes, customArray, handleUrl, increaseOne, isPreviousStepsComplete } from '../helper'
import { microNewPayrollSteps } from '../microHelper'
import { SetupStepTypes, StepType } from '../types/onboardingTypes'

const PayrollSetup = ({ initStep }: { initStep: number }): JSX.Element => {
  const history = useHistory()
  const { payroll_setup, onboarding_steps } = useSelector(
    (s) => s.organization?.organization?.metadata,
  )

  const { require_pincode } = useSelector((s) => s.organization?.organization)
  const { setSidebar, onboardingSteps, setPayrollCurrentStep, org } = useContext(OnboardingContext)

  const getKeyAndVariant = (): { key: StepType; variantSteps: SetupStepTypes[] } => {
    return { key: 'microNewPayrollSteps', variantSteps: microNewPayrollSteps }
  }

  const { key, variantSteps } = getKeyAndVariant()

  const steps: (false | SetupStepTypes)[] = [
    onboardingSteps?.payroll_setup !== 'Completed' && {
      stepNo: 0,
      title: I18n.t('payroll_setup'),
      component: SharedStep,
      sidebar: null,
    },
    ...variantSteps,
  ]
  const [currentStep, setCurrentStep] = useState(initStep)
  const { component: CurrentComponent, sidebar, stepNo } = steps[currentStep] || {}

  const customPayrollSteps = customArray(payroll_setup, key)
  const filteredSteps = _.uniqBy(customPayrollSteps, 'stepNo')

  useEffect(() => {
    setSidebar(sidebar)
  }, [sidebar])

  useEffect(() => {
    const initStepPayroll = onboarding_steps.current_step === 'payroll_setup' ? 0 : 1
    const onboardingStep =
      Number(localStorage.getItem(`payroll_setup_step_${org.id}`)) || initStepPayroll

    if (initStep === onboardingStep) {
      setCurrentStep(initStep)
      setPayrollCurrentStep(initStep)
    } else {
      setCurrentStep(onboardingStep)
      setPayrollCurrentStep(onboardingStep)
      localStorage.setItem(`payroll_setup_step_${org.id}`, onboardingStep.toString())
    }
  }, [localStorage.getItem(`payroll_setup_step_${org.id}`)])

  const goNextStep = (): void => {
    const toStep = currentStep + 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`payroll_setup_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setPayrollCurrentStep(toStep)
  }

  const goPreviousStep = (): void => {
    const toStep = currentStep - 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`payroll_setup_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setPayrollCurrentStep(toStep)
  }

  const goToStep = (toStep: number, step: StepsTypes): void => {
    const stepNumber = increaseOne.includes(step?.title) ? toStep + 1 : toStep
    const isComplete = isPreviousStepsComplete(toStep, customPayrollSteps)

    if (isComplete) {
      const path = handleUrl(toStep, key, require_pincode)
      history.push(`?${path}`)
      localStorage.setItem(`payroll_setup_step_${org.id}`, stepNumber.toString())
      setCurrentStep(stepNumber)
      setPayrollCurrentStep(stepNumber)
    }
  }

  return (
    <VerticalStepper
      steps={filteredSteps}
      currentStep={stepNo || 0}
      setCurrentStep={goToStep}
    >
      {CurrentComponent && (
        <CurrentComponent
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={steps[currentStep + 1]}
          previousStep={steps[currentStep - 1]}
          setCurrentStep={setCurrentStep}
        />
      )}
    </VerticalStepper>
  )
}

export default PayrollSetup
