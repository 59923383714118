// fingers icons
import { BadgeProps } from '@jisr-hr/ds'
import LeftIndexIcon from 'assets/figma-icons/fingers/Left-Index.svg'
import LeftMiddleIcon from 'assets/figma-icons/fingers/Left-Middle.svg'
import LeftPickieIcon from 'assets/figma-icons/fingers/Left-Pinkie.svg'
import LeftRingIcon from 'assets/figma-icons/fingers/Left-Ring.svg'
import LeftThumbIcon from 'assets/figma-icons/fingers/Left-Thumb.svg'
import RightIndexIcon from 'assets/figma-icons/fingers/Right-Index.svg'
import RightMiddleIcon from 'assets/figma-icons/fingers/Right-Middle.svg'
import RightPickieIcon from 'assets/figma-icons/fingers/Right-Pinkie.svg'
import RightRingIcon from 'assets/figma-icons/fingers/Right-Ring.svg'
import RightThumbIcon from 'assets/figma-icons/fingers/Right-Thumb.svg'
import { Buffer } from 'buffer'
import { isEmpty } from 'lodash'

export const colors: { [key: string]: string } = {
  'dark-grey': '#606060',
  'light-grey': '#909090',
  'slate-grey': '#7889a0',
  'olive-green': '#8fd683',
  'light-blue': '#0371ff',
  'dark-gray': '#4b6c89',
  'blue-700-new': 'var(--color-brand-primary-default-new)',
  azure: '#1676ff',
  blue: '#1676ff',
  white: '#fff',
  black: '#000',
  brandPrimaryDefault: 'var(--color-brand-primary-default-new)',
  brandPrimaryLight: 'var(--color-brand-primary-light-new)',
  naturalGrayDarker2: 'var(--color-natural-gray-darker-2)',
}

export const getColor = (color: string): string => colors[color] || colors.white

export const getColorHEX = (color: string): string =>
  getComputedStyle(document.documentElement).getPropertyValue(color)

export const isRtl = localStorage.getItem('Locale') === 'ar'

export const allowed_devices = [1, 3]

export const fingerprintHands = {
  0: 'left_pickie',
  1: 'left_ring',
  2: 'left_middle',
  3: 'left_index',
  4: 'left_thumb',
  5: 'right_thumb',
  6: 'right_index',
  7: 'right_middle',
  8: 'right_ring',
  9: 'right_pickie',
}

export const reporting_methods = [
  { id: 1, name: 'biometric' },
  { id: 2, name: 'geofencing_method' },
  { id: 3, name: 'missing_punch_request' },
  { id: 4, name: 'bulk_upload' },
]

export const biometrics = [
  { reporting_method_id: 1, id: 1, name: 'fingerprint' },
  { reporting_method_id: 1, id: 2, name: 'card' },
  { reporting_method_id: 1, id: 3, name: 'passcode' },
  { reporting_method_id: 2, id: 4, name: 'geofencing' },
  { reporting_method_id: 3, id: 5, name: 'missing_punch_request' },
  { reporting_method_id: 4, id: 6, name: 'bulk_upload' },
]

export const iconsIndex = {
  0: LeftPickieIcon,
  1: LeftRingIcon,
  2: LeftMiddleIcon,
  3: LeftIndexIcon,
  4: LeftThumbIcon,
  5: RightThumbIcon,
  6: RightIndexIcon,
  7: RightMiddleIcon,
  8: RightRingIcon,
  9: RightPickieIcon,
}

export const timesheetExceptions = ['final_settlement', 'vacation_settlement']

export const statusesIds = {
  Active: 1,
  Inactive: 2,
}

export const truncateText = (text = '', stringLength = 20): string => {
  if (!text) return ''
  if (text.length > stringLength) {
    return `${text.substring(0, stringLength - 3)}...`
  }
  return text
}

type ISortKeys = {
  [key: string]: string
}

export const handleSortableColumns = (
  headerKeys: string[],
  sortKeys: ISortKeys,
): (string | null)[] => {
  if (!headerKeys) return []

  return headerKeys.map((key: string): string | null => sortKeys[key] || null)
}

export const allFileTypes = 'image/*, .doc, .docx, .pdf, .xls, .xlsx, .csv'

export function readableFileSize(size: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = 0
  while (size >= 1024) {
    size /= 1024
    // eslint-disable-next-line no-plusplus
    ++i
  }
  return `${size.toFixed(1)} ${units[i]}`
}

export const statusTypes: Record<string, BadgeProps['color']> = {
  pending: 'yellow',
  pending_for_correction: 'yellow',
  confirmed: 'green',
  customRequestTypePathsompleted: 'green',
  approved: 'green',
  present: 'green',
  completed: 'green',
  cancelled: 'gray',
  dismissed: 'red',
  rejected: 'red',
  incomplete: 'red',
  sent: 'primary',
}

export const getRequestTranslationKey = (req: string): string => {
  return req
    ?.replace(/([A-Z])/g, '_$1')
    .slice(1)
    .toLowerCase()
}
export function isValidJSONString(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const requestTypes = {
  excuse_request: 'excuse',
  missing_punch_request: 'correction',
  overtime_request: 'overtime',
}

export const numberFormatter = (val: number): string =>
  val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const filterGroupTitles = {
  form_status: 'label.status',
  statuses: 'statuses',
  status_employee: 'statuses',
  job_title_id: 'job_Title',
  job_title_ids: 'job_Title',
  job_openings: 'Job Openings',
  grade_id: 'grades',
  grade_ids: 'grades',
  group_id: 'group',
  frequency: 'label.recurrence',
  department_id: 'departments',
  department_ids: 'departments',
  business_unit_id: 'business_unit',
  business_unit_ids: 'business_unit',
  location_id: 'location',
  location_ids: 'location',
  record_location_ids: 'record_location',
  gosi_subscriptions: 'gosi_subscriptions',
  mol_registration_ids: 'gosi_subscriptions',
  employment_type_id: 'employment_types',
  employment_type_ids: 'employment_types',
  nationalities: 'nationalities',
  nationality: 'nationalities',
  gosi_linked_data: 'gosi_linked',
  uncorrect_data: 'payment_details',
  payment_methods: 'payment_methods',
  outsourcing_company_ids: 'outsourcing_company',
  loan_type_ids: 'loan_types',
  payrun_types: 'types',
  payrun_status: 'payrun_status',
  payroll_transaction_status: 'payroll_transaction_status',
  payroll_transaction_type_ids: 'out_of_payroll_types',
  document_type: 'document_types',
  biometric_ids: 'reporting_method',
  shift_type: 'shift_type',
  shift_ids: 'shifts',
  asset_status: 'asset_status',
  asset_types: 'asset_types',
  review_cycle: 'review_cycles',
  schedule_type: 'filters',
  area_ids: 'area',
  device_status: 'device_status',
  joining_dates: 'joining_dates',
  candidate_resume: 'label.resume',
  candidate_source: 'label.source',
  candidate_title: 'label.current_title',
  candidate_rating: 'label.rating',
  candidate_screening: 'label.screening_questions',
  ats_recruiter: 'label.assigned_recruiter',
  ats_hiring_manager: 'label.hiring_manager',
  export_format: 'label.file_type',
  export_type: 'label.report_type',
  banks: 'label.banks',
  invoices_status: 'label.status',
  payments: 'create_label.payment',
  issuance_date: 'create_label.issuance_date',
  due_date: 'label.due_date',
  violation_status: 'status',
  violation_category: 'category',
  timesheet_settlement: 'modal.announcement.new_attendnace_sheet.subtitle_2',
  timesheet_reviewer_ids: 'reviewer',
  paygroup_ids: 'modal.announcement.new_attendnace_sheet.subtitle_1',
  date_monthly_period: 'date',
  settlement_employee_ids: 'employees',
  timesheet_status: 'status',
  violation_reporter: 'label.reported_by',
  action_taken_by: 'label.table_header.action_taken_by',
  reporting_date: 'create_label.tableheader.reportingdate',
  date_period: 'date',
  commercial_registration_ids: 'organizations',
  year_data: 'label.year',
}
type GroupedListTypes = Array<Record<string, unknown>>

export const getGroupedList = (list: GroupedListTypes, groupKey: string): GroupedListTypes => {
  let newList: GroupedListTypes = []
  list.map((li, i) => {
    if (isEmpty(newList.find((nl) => nl.name === li?.[groupKey]))) {
      const data = {
        id: i + 1,
        name: li?.[groupKey],
        name_i18n: filterGroupTitles[`${li?.[groupKey]}`],
        list: list.filter((gli) => gli?.[groupKey] === li?.[groupKey]),
      }
      newList = [...newList, data]
    }
    return null
  })
  return newList
}

export const customRequestTypePaths = ['requests', 'employees']

export const uniqId = (): string => {
  const start = Date.now().toString(36)
  const last = Math.random().toString(36).substring(2)

  return start + last
}

export const encodedString = (val: string): string => Buffer.from(val, 'utf-8').toString('base64')

export const decodedString = (val: string): string => Buffer.from(val, 'base64').toString('utf-8')

export const isRouteMatch = (key: string): boolean => {
  const path = window.location.hash.split('/')
  return path[path.length - 1] === key
}
