import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import { Form, SubdomainInputField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import {
  onboardingSlugRest,
  slugAvaliable,
  updateOrganization,
} from 'redux/organizationOnborading/organization/actionCreators'
import I18n from 'translations/i18n'
import { useSelector, useDispatch } from 'utils/hooks'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { useEffect } from 'react'
import { APP_DOMAIN_URL } from 'env'

type OrganizationType = {
  slug: string
}

const Subdomain = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { organization, fetching } = useSelector((s) => s.organization)
  const slug_avaliable = useSelector((s) => s.organization?.slug_avaliable)
  let handleNextStep: () => void
  const onSubmit = (value: OrganizationType): void => {
    if (organization.slug === value.slug) {
      dispatch(updateOrganization(value)).then(() => {
        goNextStep?.()
      })
    } else {
      dispatch(slugAvaliable({ slug: value.slug, search_all: true })).then((res) => {
        if (res.value.data.data.available) {
          dispatch(updateOrganization(value)).then(() => {
            goNextStep?.()
          })
        }
      })
    }
  }

  useTrackingSteps({ step_name: 'invite_employees', level_name: 'org_account_link' })

  useEffect(() => {
    dispatch(onboardingSlugRest())
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ slug: organization.slug }}
      render={({ handleSubmit, values }): JSX.Element => {
        handleNextStep = handleSubmit

        return (
          <StepTemplate
            goNextStep={(): void => handleNextStep()}
            goPreviousStep={goPreviousStep}
            nextStep={nextStep}
            previousStep={previousStep}
            disableNextBtn={!values.slug}
          >
            <Flex flexCol>
              <Typography
                variant="interface/default/m"
                text={I18n.t('what_subdomain_would_description', {
                  app_name: I18n.t('jisr_translation_name'),
                })}
              />
              <Spacer height="16px" />
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <SubdomainInputField
                domain={`.${APP_DOMAIN_URL}`}
                name="slug"
                placeholder="xxxx"
                validate={required}
                style={{ maxWidth: '400px' }}
                loading={fetching}
              />
              {!slug_avaliable && (
                <Typography
                  text={I18n.t('check_slug')}
                  variant="subtitle-2"
                  textColor="color/fg/danger/default"
                />
              )}
            </Flex>
          </StepTemplate>
        )
      }}
    />
  )
}

export default Subdomain
