import { useEffect } from 'react'
import QueryString from 'qs'
import { useDispatch, useSelector } from 'utils/hooks'
import { serverEnvironment } from 'env'
import { onboardingTrackingSteps } from 'redux/organizationOnborading/trackingSteps/actionCreators'
import { orgSteps } from './OrganizationSetup/helper'
import { leaveSteps } from './LeavesSetup/helper'
import { payrollSteps } from './PayrollSetup/helper'
import { steps as InviteSteps } from './InviteEmployees/InviteEmployees'
import {
  StepType,
  SetupKeysTypes,
  SetupStepTypes,
  TypesArray,
  TrackingDataType,
} from './types/onboardingTypes'
import {
  microOrgSteps,
  microNewOrgSteps,
  microLeaveSteps,
  microPayrollSteps,
  microNewPayrollSteps,
  microActivateSteps,
} from './microHelper'

export type StepsTypes = SetupStepTypes & SetupKeysTypes

export const initialActiveCR = {
  bank_accounts: [],
  id: null,
  mol_registration: {
    gosi_number: null,
  },
  name_en: '',
  registration_id: null,
  bank_account_id: null,
  name_ar: '',
  name_i18n: '',
  payment_mode: '',
  registration_number: '',
}

export const initialEditBank = {
  bank_accounts: [
    {
      bank_id: null,
      bank_logo: {
        logo_thumb: '',
      },
      bank_name: '',
      iban: '',
      id: 0,
      mudad_supported: false,
    },
  ],
  name_ar: '',
  id: 0,
  invoice_already_generated: false,
  name_en: '',
  name: '',
  archived_at: '',
  name_i18n: '',
  payment_mode: null,
  registration_number: '',
  registration_id: 0,
  mol_registration: {
    id: 0,
    gosi_number: '',
    mol_prefix: '',
    mol_number: '',
    mol_registration_number: '',
    name: '',
  },
  gosi_integration: null,
  number_of_employees: 0,
  main_commercial_registration: null,
  bank_account_id: 0,
}

const setupTypes = {
  orgSteps,
  microOrgSteps,
  microNewOrgSteps,
  leaveSteps,
  microLeaveSteps,
  payrollSteps,
  microPayrollSteps,
  microNewPayrollSteps,
  InviteSteps,
  microActivateSteps,
}

const notExist = ['review_employees_data']
export const increaseOne = [
  'review_employees_data',
  'review_employees_data',
  'review_employees_payment',
  'loans',
  'vacation_settlement',
  'payroll_bank',
  'payment_methods',
  'review',
  'done_finish',
]

const skip = [
  'reviewing_employee_data',
  'verify',
  'upload_annual_leave_files',
  'review_page',
  'review',
  'done_finish',
]

export const skipAmplitude = ['org_details_and_industry', 'Leaves Setup', 'Confirm', 'Allowances']

export const customArray = (types: TypesArray, step_type: StepType): StepsTypes[] => {
  let steps: StepsTypes[] = []

  setupTypes[step_type].forEach((step) => {
    if (!notExist.includes(step.title)) {
      const current_step: SetupKeysTypes = types[step.title]

      steps = [
        ...steps,
        {
          is_completed: current_step?.is_completed,
          is_mandatory: current_step?.is_mandatory,
          ...step,
        },
      ]
    }
  })

  return steps
}

export const isPreviousStepsComplete = (step: number, arr: StepsTypes[]): boolean => {
  let isComplete = false
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < step; i++) {
    const is_boolean = typeof arr[i]?.is_mandatory === 'boolean'
    if ((is_boolean && !arr[i]?.is_mandatory) || arr[i]?.is_completed) {
      isComplete = true
    } else if (skip.includes(arr[i]?.title)) {
      isComplete = true
    } else {
      isComplete = false
      break
    }
  }
  return isComplete
}

// const microSetup = ['microOrgSteps', 'microLeaveSteps', 'microPayrollSteps', 'microActivateSteps']

export const handleUrl = (toStep: number, key: string, micro?: boolean): string => {
  const currentStep = setupTypes[key]?.find(
    (orgStep: SetupStepTypes, i: number) => i + 1 === toStep,
  ) || { title: '' }

  const path = QueryString.stringify({
    micro,
    path: currentStep.title,
  })
  return path
}

export const isProductionServer = ['aws', 'oci'].includes(serverEnvironment || '')

export function useTrackingSteps({ step_name, level_name }: TrackingDataType): void {
  const dispatch = useDispatch()
  const { metadata } = useSelector((state) => state.organization.organization)

  useEffect(() => {
    if (!metadata[step_name][level_name]?.started_at) {
      dispatch(
        onboardingTrackingSteps({
          step_name,
          level_name,
        }),
      )
    }
  }, [])
}
