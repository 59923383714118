import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function getSwitchableOrgs(): Action {
  const url = `/${API_VERSION_TWO}/organizations/list`
  return {
    type: actions.SWITCHABLE_ORGANIZATIONS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}
