import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Flex, Button, Spacer } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import { format } from 'utils/date'
import { isRtl } from 'utils/uiHelpers'
import I18n from 'translations/i18n'
import { groupBy } from 'lodash'

import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import { Link } from 'react-router-dom'
import { formatDateRequestOn } from 'containers/authorised/requests/helper'
import { handelRequestPath } from './helper'
import Frame from '../../ds/Frame'
import ApprovalState from './components/ApprovalState'
import ApprovalText from './components/ApprovalText'
import Styles from './Workflow.module.css'
import RequestedBy from '../Info/components/RequestedBy'
import CancellationBy from './components/CancellationBy'
import { useRequest } from '../../RequestProvider'
import ReturnedForCorrection from '../ReturnedForCorrection'
import ApprovalByPassLog from '../ApprovalByPassLog'
import ApprovalNotify from '../ApprovalNotify'

const Workflow = ({ lessDetail, approvals }) => {
  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  const { request: req } = useSelector((state) => state.approvals)
  const isSuperAdmin = useSelector((state) => state?.auth.employee?.is_super_admin)
  const manage_request_workflow = useSelector(
    (s) => s?.auth.employee?.permission_scopes?.manage_request_workflow,
  )

  const { employeeDate } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const requestHook = useRequest()
  const reqStatus = details?.status === 'cancelled'

  const isDiffEmp = details.created_by?.id !== details?.employee?.id

  const request = {
    ...details,
    ...req,
  }

  const sentBy = {
    assignee: request?.employee,
    received_at: request?.created_at,
    approval_step: 0,
    is_visible: true,
    status: 'Sent',
    status_i18n: I18n.t('submitted'),
  }

  const approvalsList = [...(request?.created_by ? [sentBy] : []), ...(approvals || [])]
  const approvalState = groupBy(approvalsList, 'status')

  const isApproved = approvalsList[approvalsList.length - 1]?.status === 'Approved'

  const empInsideWorkflow = details?.approvals?.find(
    (item) => item.assignee.id === details?.cancelled_by?.id,
  )
  // is_visible: to identify the position of Cancelled tag
  const isCancelledByEmp = !!empInsideWorkflow && empInsideWorkflow?.is_visible === true

  const handleRequestDetail = (reqDetails) => {
    requestHook?.setRequestType(reqDetails?.cancellation_request_info?.type)
    dispatch(
      requestDetailsLoad(reqDetails?.employee?.id, {
        request_id: reqDetails?.cancellation_request_info?.id,
      }),
    )
  }

  return (
    <Frame
      title={I18n.t('approvals')}
      titleSuffix={
        <>
          {isSuperAdmin && manage_request_workflow && details?.status === 'pending' && (
            <>
              <Link to={handelRequestPath(details, `workflow_id=${details?.workflow_id}`)}>
                <Flex style={{ gap: 4 }}>
                  <Typography
                    variant="interface/medium/sm"
                    text={I18n.t('label.edit_approval_cycle')}
                    textColor="color/fg/brand/default"
                  />
                  <DSIcon name={isRtl ? 'arrow-narrow-up-left' : 'arrow-narrow-up-right'} />
                </Flex>
              </Link>
            </>
          )}
        </>
      }
    >
      <Flex
        flexCol
        className={Styles.listContainer}
      >
        {isDiffEmp && (
          <RequestedBy
            name={details?.created_by?.name_i18n}
            jobtitle={details?.created_by?.job_title}
          />
        )}

        {Object.entries(approvalState).map(([key, list], index) => {
          const lessList = lessDetail ? list.slice(0, 2) : list

          return lessList.map((approval, i) => {
            const isPending = approval.status === 'Pending'
            const approvalStatus = approval?.status === 'Cancelled'
            const shouldShow =
              reqStatus &&
              approvalStatus &&
              approval?.received_at &&
              (isCancelledByEmp || !!details?.cancelled_by)

            return (
              <React.Fragment key={`${key}-${i}`}>
                <ReturnedForCorrection
                  firstListIndex={index}
                  secondListIndex={i}
                  corrections={request?.request_corrections ?? []}
                />
                <Flex
                  flexCol
                  className={classNames(
                    Styles.approvalState,
                    isPending ? Styles.approvalPending : undefined,
                    details?.cancellation_request_info &&
                      details?.cancellation_request_info?.status === 'Pending' &&
                      Styles.cancellationAskedBy,
                    details?.cancellation_request_info &&
                      details?.cancellation_request_info?.status === 'Approved' &&
                      Styles.approvedCancellation,
                    reqStatus &&
                      isApproved &&
                      !details?.cancellation_request_info &&
                      Styles.cancelledReq,
                    reqStatus &&
                      !isApproved &&
                      !details?.cancellation_request_info &&
                      !isCancelledByEmp &&
                      Styles.cancelled,
                  )}
                >
                  {!(lessDetail && i > 0 && list?.length - 1 > 1) ? (
                    <>
                      {shouldShow && (
                        <>
                          <CancellationBy
                            cancellationLabel={
                              <Typography
                                text={
                                  details?.cancelled_by?.name
                                    ? I18n.t('cancelled_by')
                                    : I18n.t('cancelled')
                                }
                                textColor="--color-base-colors-black-500"
                                variant="subtitle-2"
                              />
                            }
                            label={details?.cancelled_by?.name}
                            dateAndTime={format(
                              details?.cancelled_by?.cancelled_at,
                              'd MMM - hh:mm a',
                            )}
                            isCanceled
                          />
                          <Spacer height={48} />
                        </>
                      )}
                      <ApprovalState
                        approvalId={details?.approval?.id}
                        employeeName={approval?.assignee?.name}
                        code={approval?.assignee?.code}
                        avatar={approval?.assignee?.avatar_thumb}
                        jobTitle={
                          approval?.assignee?.job_title || approval?.assignee?.job_title_i18n
                        }
                        status={approval?.status}
                        status_i18n={approval?.status_i18n}
                        is_action_by_automation={approval?.is_action_by_automation}
                        isVisible={!shouldShow ? approval?.is_visible : false}
                        date={
                          !shouldShow &&
                          formatDateRequestOn(
                            employeeDate,
                            approval?.action_at || approval?.received_at,
                          )
                        }
                        time={
                          !shouldShow &&
                          format(approval?.action_at || approval?.received_at, 'hh:mm a')
                        }
                        hasCancellationRequestInfo={!!details?.cancellation_request_info}
                        canBypass={approval?.can_bypass}
                        canNotify={approval?.can_notify}
                      />
                    </>
                  ) : (
                    <Flex
                      className={Styles.moreCollapse}
                      itemsCenter
                    >
                      <Flex
                        justifyCenter
                        itemsCenter
                      >
                        <ApprovalText label={`+${list?.length - 1}`} />
                      </Flex>
                      <ApprovalText
                        label={I18n.t('more_approvers', {
                          count: list?.length - 1,
                        })}
                      />
                    </Flex>
                  )}
                </Flex>
                {approval?.notifications?.length && (
                  <ApprovalNotify
                    notifications={approval.notifications}
                    isLastApproval={i === lessList.length - 1 && !approval?.is_bypassed}
                  />
                )}
                {approval?.is_bypassed && (
                  <ApprovalByPassLog
                    approver={approval.action_by?.name_i18n}
                    assignee={approval.assignee?.name}
                    actionAt={approval?.action_at}
                    isLastApproval={i === lessList.length - 1}
                  />
                )}
              </React.Fragment>
            )
          })
        })}
      </Flex>
      {details?.cancellation_request_info && (
        <CancellationBy
          cancellationLabel={
            <Typography
              text={I18n.t('asked_for_cancellation_by')}
              textColor="--color-base-colors-black-500"
              variant="subtitle-2"
            />
          }
          label={details?.cancellation_request_info?.created_by}
          dateAndTime={format(details?.cancellation_request_info?.created_at, 'd MMM - hh:mm a')}
        />
      )}
      {(details?.cancellation_request_info?.approved_at || (reqStatus && isApproved)) && (
        <>
          <Spacer height={48} />
          <CancellationBy
            cancellationLabel={
              <Typography
                text={
                  !details?.cancelled_by?.name && !details?.cancellation_request_info
                    ? I18n.t('cancelled')
                    : I18n.t('cancelled_by')
                }
                style={{ color: 'var(--color-base-colors-black-500)' }}
                variant="subtitle-2"
              />
            }
            label={
              details?.cancellation_request_info ? (
                <Button
                  trailingIcon="arrow-narrow-up-right"
                  label={I18n.t('cancellation_request')}
                  size="small"
                  variant="ghost"
                  color="primary"
                  onClick={() => handleRequestDetail(details)}
                  className={Styles.btn}
                />
              ) : (
                details?.cancelled_by?.name
              )
            }
            dateAndTime={format(
              details?.cancellation_request_info
                ? details?.cancellation_request_info?.approved_at
                : details?.cancelled_by?.cancelled_at,
              'd MMM - hh:mm a',
            )}
            hasCancelledBy={!!details?.cancellation_request_info?.approved_at}
          />
        </>
      )}
    </Frame>
  )
}

Workflow.propTypes = {
  lessDetail: PropTypes.bool,
  approvals: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.object])),
}

export default Workflow
