import { FC, Fragment, useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { useForm } from 'react-final-form'
import { Typography, Flex, Badge } from '@jisr-hr/ds'
import { CheckboxField } from 'components/final-form'
import { Default_pay_type } from 'redux/setting/payrollManagement/payType/reduecer'
import { payTypeList } from './helper'

type PaymentMethodsPropsType = {
  handleMethodChange: (arg: { name: string; value: string }) => void
  initValue: Default_pay_type
  formValues: Record<string, unknown>
}

const PaymentMethods: FC<PaymentMethodsPropsType> = (props) => {
  const { handleMethodChange, initValue, formValues } = props
  const form = useForm()
  const [defaultMethod, setDefaultMethod] = useState<Default_pay_type | ''>(initValue || '')

  function handleChange(method: { name: string; id: string; testId: string }): void {
    if (formValues.in_payrun && formValues.out_of_payrun) {
      setDefaultMethod(method.id === 'in_payrun' ? 'out_of_payrun' : 'in_payrun')
      form.change('default_pay_type', method.id)
    } else {
      setDefaultMethod('')
    }
  }
  useEffect(() => {
    form.change('default_pay_type', defaultMethod)
  }, [defaultMethod])

  return (
    <Fragment>
      <Flex style={{ gap: 20 }}>
        {payTypeList.map((method, i) => (
          <Flex
            key={i}
            itemsCenter
            style={{ marginBottom: 8 }}
          >
            <CheckboxField
              data-testid="paid-method"
              name={method.id}
              // value={method.id}
              label={I18n.t(method.name)}
              // size="small"
              style={{ color: ' #06062B ' }}
              onChange={(value): void => {
                if (!value && method.id === defaultMethod) {
                  handleChange(method)
                } else if (value && defaultMethod === '') {
                  setDefaultMethod(method.id)
                }
              }}
            />
            {defaultMethod &&
              (defaultMethod === method.id ? (
                <Badge
                  label={I18n.t('default')}
                  size="small"
                  color="gray"
                  variant="tinted"
                />
              ) : (
                <Typography
                  onClick={(): void => {
                    setDefaultMethod(method.id)
                    handleMethodChange({ name: method.id, value: method.id })
                  }}
                  style={{
                    padding: '0 8px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'var(--color-base-colors-black-500)',
                    width: 'fit-content',
                    flexShrink: 0,
                  }}
                  variant="caption"
                  text={I18n.t('set_as_default')}
                />
              ))}
          </Flex>
        ))}
      </Flex>
    </Fragment>
  )
}

export default PaymentMethods
