import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { OvertimePolicy } from './overtimePoliciesTypes'
import * as actions from './actions'

type InitialOvertimePolicies = {
  overtime_policies: OvertimePolicy[]
  overtime_policy: OvertimePolicy | Record<string, never>
  fetching: boolean
}

const initialState: InitialOvertimePolicies = {
  overtime_policies: [],
  overtime_policy: {},
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialOvertimePolicies {
  const { payload } = action
  switch (action.type) {
    case actions.RESET_OVERTIME_POLICY:
      return {
        ...state,
        overtime_policy: {},
      }

    case actions.OVERTIME_POLICIES_LOAD_PENDING:
    case actions.OVERTIME_POLICY_CREATE_PENDING:
    case actions.OVERTIME_POLICY_LOAD_PENDING:
    case actions.OVERTIME_POLICY_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.OVERTIME_POLICIES_LOAD_FULFILLED:
    case actions.OVERTIME_POLICY_LOAD_FULFILLED:
      const overtimeLoad: Pick<InitialOvertimePolicies, 'overtime_policies' | 'fetching'> =
        payload.data.data
      return {
        ...state,
        ...overtimeLoad,
        fetching: false,
      }

    case actions.OVERTIME_POLICY_CREATE_FULFILLED:
    case actions.OVERTIME_POLICY_UPDATE_FULFILLED:
    case actions.OVERTIME_POLICY_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      const overtimeOperations: { overtime_policy: OvertimePolicy } = payload.data.data
      return {
        ...state,
        ...overtimeOperations,
        fetching: false,
      }

    case actions.OVERTIME_POLICIES_LOAD_REJECTED:
    case actions.OVERTIME_POLICY_CREATE_REJECTED:
    case actions.OVERTIME_POLICY_LOAD_REJECTED:
    case actions.OVERTIME_POLICY_UPDATE_REJECTED:
    case actions.OVERTIME_POLICY_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
