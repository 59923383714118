/* eslint-disable react/no-danger */
/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import PropTypes from 'prop-types'

const PDFPage = ({ header, footer, content }) => (
  <div className="letter-pdf">
    <div className="letter-pdf__header">
      <div
        dangerouslySetInnerHTML={{
          __html: header,
        }}
      />
    </div>
    <div className="letter-pdf__content">
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
    <div className="letter-pdf__footer">
      <div
        dangerouslySetInnerHTML={{
          __html: footer,
        }}
      />
    </div>
  </div>
)

PDFPage.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.string,
  content: PropTypes.string,
}

export default PDFPage
