import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ReactComponent as ExitVisaIcon } from 'assets/figma-icons/ExitVisa.svg'
import { Spacer } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import { Tooltip } from '@jisr-hr/ds-beta'
import I18n from 'translations/i18n'
import { SwitchField, SelectField, DatePickerDSField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import { required } from 'components/global/form/FormValidations'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { resetEmployeeInformation } from 'redux/attendance/employeeDetails/actionsCreators'
import { briefListOfEmployees } from 'redux/scheduler/assignEmployees/actionCreators'

import { useRequest } from '../../../RequestProvider'
import { ExitReEntryType, period } from './helper'

const ExitReEntryVisa = ({
  isExitReentryVisaRequest,
  withEmployeeDetail,
  disabled,
  disableMessage,
}) => {
  const dispatch = useDispatch()

  const { briefEmployees } = useSelector(({ scheduler }) => scheduler, shallowEqual)

  const isSaudi = briefEmployees[0]?.nationality === 'Saudi'

  const {
    vars: { id },
  } = useRequest()

  const { change } = useForm()
  const {
    values: { is_re_entry_exit, exit_reentry_info_attributes, exit_reentry_visa_request },
    initialValues,
  } = useFormState()
  let obj = exit_reentry_info_attributes
  let objString = 'exit_reentry_info_attributes.'
  if (isExitReentryVisaRequest) {
    obj = exit_reentry_visa_request
    objString = ''
  }
  const optionPeriod =
    obj?.entry_type === 'Multiple' ? period.filter((per) => per.id !== 2) : period

  React.useEffect(() => {
    if (id && withEmployeeDetail) {
      dispatch(briefListOfEmployees({ id }))
    }
    return () => {
      dispatch(resetEmployeeInformation())
    }
  }, [])

  if (isSaudi) return null

  return (
    <Frame
      title={I18n.t('exit_re_entry_visa')}
      icon={<ExitVisaIcon />}
      titleSuffix={
        isExitReentryVisaRequest ? null : (
          <Tooltip
            description={disableMessage}
            disabled={!disableMessage}
            trigger={
              <div>
                <SwitchField
                  name="is_re_entry_exit"
                  active={is_re_entry_exit}
                  disabled={disabled}
                  onChange={(checked) => {
                    if (!isExitReentryVisaRequest && initialValues?.id) {
                      if (checked) {
                        change('ticket_info_attributes._destroy', undefined)
                      } else {
                        change('ticket_info_attributes._destroy', true)
                      }
                    }
                  }}
                />
              </div>
            }
          />
        )
      }
    >
      {(isExitReentryVisaRequest || is_re_entry_exit) && (
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={4}
            >
              <SelectField
                validate={required}
                name={`${objString}entry_type`}
                labelKey="label"
                valueKey="id"
                options={ExitReEntryType}
                label={I18n.t('type')}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <SelectField
                validate={required}
                name={`${objString}period`}
                labelKey="label"
                valueKey="id"
                options={optionPeriod}
                label={I18n.t('visa_period')}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <DatePickerDSField
                validate={required}
                name={`${objString}apply_date`}
                label={I18n.t('needed_before')}
              />
            </Grid>
          </Grid>
          <Spacer height={12} />
        </>
      )}
    </Frame>
  )
}

ExitReEntryVisa.propTypes = {
  isExitReentryVisaRequest: PropTypes.bool,
  withEmployeeDetail: PropTypes.bool,
  disabled: PropTypes.bool,
  disableMessage: PropTypes.string,
}

export default ExitReEntryVisa
