import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type State = {
  medical_insurance_providers: Array<{
    id: number
    name: string
    name_ar: string
    name_i18n: string
    website: string
  }>
  fetching: boolean
  errMsg?: string | null
}

const initialState: State = {
  medical_insurance_providers: [],
  fetching: false,
}

export default function reducer(state = initialState, action: Action): State {
  const { payload } = action
  switch (action.type) {
    case actions.MEDICAL_INSURANCE_PROVIDERS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
      }

    case actions.MEDICAL_INSURANCE_PROVIDERS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
