import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'

import * as actions from './actions'
import { TrackingReducerState } from './trackingSharedTypes'

const initialState: TrackingReducerState = {
  fetching: false,
  loading: false,
  loadingPopup: false,
  groupByFetching: false,
  can_upload_attendance_file: false,
  tracking: [],
  groupByList: [],
  employeeDetail: {},
  trackingState: {},
  pagination: {},
}

export default function reducer(state = initialState, action: Action): TrackingReducerState {
  switch (action.type) {
    case actions.RESET_TRACKING:
      return {
        ...state,
        tracking: [],
        pagination: {},
      }

    case actions.RESET_GROUP_LIST:
      return {
        ...state,
        groupByList: [],
      }

    case actions.TRACKING_LIST_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_RECORD_DETAIL_PENDING:
    case actions.ATTENDANCE_UPDATE_RECORD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.ATTENDANCE_RECORD_DETAIL_POPUP_PENDING:
      return {
        ...state,
        loadingPopup: true,
      }

    case actions.GROUP_BY_LIST_LOAD_PENDING:
      return {
        ...state,
        groupByFetching: true,
      }

    case actions.TRACKING_LIST_LOAD_FULFILLED:
      return {
        ...state,
        tracking: [...state.tracking, ...action.payload.data?.data?.data?.employees],
        trackingState: action.payload.data?.data?.data?.stats,
        can_upload_attendance_file: action.payload.data?.data?.data?.can_upload_attendance_file,
        pagination: action.payload.data?.data?.pagination,
        fetching: false,
      }

    case actions.ATTENDANCE_RECORD_DETAIL_FULFILLED:
      return {
        ...state,
        employeeDetail: action.payload.data.data,
        loading: false,
      }

    case actions.ATTENDANCE_RECORD_DETAIL_POPUP_FULFILLED:
      return {
        ...state,
        employeeDetail: action.payload.data.data,
        loadingPopup: false,
      }

    case actions.GROUP_BY_LIST_LOAD_FULFILLED:
      return {
        ...state,
        groupByList: action.payload.data.data.data,
        groupByFetching: false,
      }

    case actions.ATTENDANCE_UPDATE_RECORD_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.TRACKING_LIST_LOAD_REJECTED:
    case actions.GROUP_BY_LIST_LOAD_REJECTED:
    case actions.ATTENDANCE_RECORD_DETAIL_REJECTED:
    case actions.ATTENDANCE_UPDATE_RECORD_REJECTED:
    case actions.ATTENDANCE_RECORD_DETAIL_POPUP_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        groupByFetching: false,
        loadingPopup: false,
      }

    default:
      return state
  }
}
