import { useContext, useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { useHistory } from 'react-router-dom'
import { onboardingCompleteStep } from 'redux/organizationOnborading/organization/actionCreators'
import VerticalStepper from '../components/VerticalStepper'
import SharedStep from '../components/SharedStep'
import { OnboardingContext } from '../context'
import { leaveSteps } from './helper'
import { StepsTypes, customArray, handleUrl, isPreviousStepsComplete } from '../helper'
import { SetupStepTypes } from '../types/onboardingTypes'
import { microLeaveSteps } from '../microHelper'

type LeavesSetupTypes = {
  initStep: number
  isConfigurationStep?: boolean
}

const LeavesSetup = ({ initStep = 1, isConfigurationStep }: LeavesSetupTypes): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { leaves_setup } = useSelector(({ organization }) => organization?.organization?.metadata)
  const { require_pincode } = useSelector(({ organization }) => organization?.organization)
  const { organization: orgInfo } = useSelector(({ organization }) => organization)

  const { setSidebar, onboardingSteps, setLeaveCurrentStep, org } = useContext(OnboardingContext)

  const microSteps = !isConfigurationStep ? [microLeaveSteps[0]] : microLeaveSteps

  const key = require_pincode ? 'microLeaveSteps' : 'leaveSteps'
  const variantSteps = require_pincode ? microSteps : leaveSteps

  const steps: (false | SetupStepTypes)[] = [
    onboardingSteps?.leaves_setup !== 'Completed' && {
      stepNo: 0,
      title: I18n.t('leaves_setup'),
      component: SharedStep,
      sidebar: null,
    },
    ...variantSteps,
  ]

  const [currentStep, setCurrentStep] = useState<number>(initStep)
  const { component: CurrentComponent, sidebar } = steps[currentStep] || {}

  const customLeaveSteps = customArray(leaves_setup, key)

  useEffect(() => {
    setSidebar(sidebar)
  }, [sidebar])

  useEffect(() => {
    const initStepLeave =
      orgInfo?.metadata?.onboarding_steps?.current_step === 'leaves_setup' ? 0 : 1
    const onboardingStep =
      Number(localStorage.getItem(`leave_setup_step_${org.id}`)) || initStepLeave
    if (initStep === onboardingStep) {
      setCurrentStep(initStep)
      setLeaveCurrentStep(initStep)
    } else {
      setCurrentStep(onboardingStep)
      setLeaveCurrentStep(onboardingStep)
      localStorage.setItem(`leave_setup_step_${org.id}`, onboardingStep.toString())
    }
  }, [localStorage.getItem(`leave_setup_step_${org.id}`)])

  const goNextStep = (step: number): void => {
    const toStep = typeof step === 'number' ? step : currentStep + 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    const dataStep = customLeaveSteps.find((i) => i.stepNo === toStep - 1)
    localStorage.setItem(`leave_setup_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setLeaveCurrentStep(toStep)
    if (!dataStep?.is_completed && dataStep?.title !== 'upload_annual_leave_files') {
      dispatch(onboardingCompleteStep(dataStep ?? {}, 'leaves_setup'))
    }
  }

  const goPreviousStep = (): void => {
    const toStep = currentStep - 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`leave_setup_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setLeaveCurrentStep(toStep)
  }

  const list = (): StepsTypes[] => {
    if (require_pincode && !isConfigurationStep) {
      return [customLeaveSteps[0]]
    }
    if (require_pincode) {
      return customLeaveSteps.filter((step) => step.stepNo !== 1)
    }
    return customLeaveSteps
  }

  const goToStep = (toStep: number): void => {
    const isComplete = isPreviousStepsComplete(toStep, customLeaveSteps)

    if (isComplete) {
      const path = handleUrl(toStep, key, require_pincode)
      history.push(`?${path}`)
      localStorage.setItem(`leave_setup_step_${org.id}`, toStep.toString())
      setCurrentStep(toStep)
      setLeaveCurrentStep(toStep)
    }
  }

  return (
    <VerticalStepper
      steps={list()}
      currentStep={currentStep}
      setCurrentStep={goToStep}
    >
      {CurrentComponent && (
        <CurrentComponent
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={steps[currentStep + 1]}
          previousStep={steps[currentStep - 1]}
        />
      )}
    </VerticalStepper>
  )
}

export default LeavesSetup
