import { useSelector } from 'utils/hooks'

const useIsEmployeeOrg = (): boolean => {
  const isEmployeeOrg = useSelector(
    (state) => state.auth?.employee?.is_belong_to_current_organization,
  )

  return !!isEmployeeOrg
}

export default useIsEmployeeOrg
