export const ZOHO_BOOKS_LOAD_ACCOUNT_STATUS = 'ZOHO_BOOKS_LOAD_ACCOUNT_STATUS'
export const ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_PENDING = 'ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_PENDING'
export const ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_FULFILLED = 'ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_FULFILLED'
export const ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_REJECTED = 'ZOHO_BOOKS_LOAD_ACCOUNT_STATUS_REJECTED'
export const ZOHO_BOOKS_DISCONNECT_ACCOUNT = 'ZOHO_BOOKS_DISCONNECT_ACCOUNT'
export const ZOHO_BOOKS_DISCONNECT_ACCOUNT_PENDING = 'ZOHO_BOOKS_DISCONNECT_ACCOUNT_PENDING'
export const ZOHO_BOOKS_DISCONNECT_ACCOUNT_FULFILLED = 'ZOHO_BOOKS_DISCONNECT_ACCOUNT_FULFILLED'
export const ZOHO_BOOKS_DISCONNECT_ACCOUNT_REJECTED = 'ZOHO_BOOKS_DISCONNECT_ACCOUNT_REJECTED'

export const ZOHO_BOOKS_LOAD_ACCOUNT_INFO = 'ZOHO_BOOKS_LOAD_ACCOUNT_INFO'
export const ZOHO_BOOKS_LOAD_ACCOUNT_INFO_PENDING = 'ZOHO_BOOKS_LOAD_ACCOUNT_INFO_PENDING'
export const ZOHO_BOOKS_LOAD_ACCOUNT_INFO_FULFILLED = 'ZOHO_BOOKS_LOAD_ACCOUNT_INFO_FULFILLED'
export const ZOHO_BOOKS_LOAD_ACCOUNT_INFO_REJECTED = 'ZOHO_BOOKS_LOAD_ACCOUNT_INFO_REJECTED'

export const ZOHO_BOOKS_CONNECT_ACCOUNT = 'ZOHO_BOOKS_CONNECT_ACCOUNT'
export const ZOHO_BOOKS_CONNECT_ACCOUNT_PENDING = 'ZOHO_BOOKS_CONNECT_ACCOUNT_PENDING'
export const ZOHO_BOOKS_CONNECT_ACCOUNT_FULFILLED = 'ZOHO_BOOKS_CONNECT_ACCOUNT_FULFILLED'
export const ZOHO_BOOKS_CONNECT_ACCOUNT_REJECTED = 'ZOHO_BOOKS_CONNECT_ACCOUNT_REJECTED'

export const SYNC_ZOHO_BOOKS_EMPLOYEE = 'SYNC_ZOHO_BOOKS_EMPLOYEE'
export const SYNC_ZOHO_BOOKS_EMPLOYEE_PENDING = 'SYNC_ZOHO_BOOKS_EMPLOYEE_PENDING'
export const SYNC_ZOHO_BOOKS_EMPLOYEE_FULFILLED = 'SYNC_ZOHO_BOOKS_EMPLOYEE_FULFILLED'
export const SYNC_ZOHO_BOOKS_EMPLOYEE_REJECTED = 'SYNC_ZOHO_BOOKS_EMPLOYEE_REJECTED'
