/* ***************
 *   Auth Reducer
 /*************** */

import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  employment_files: [],
  employment_file: { file_type: {} },
  editing: false,
  errMsg: null,
  succMsg: null,
  fetching: false,
  employment_files_aprove: [],
}

let changes = null
export default function fileReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let employment_files = []
  let newRecord = {}
  let updatedRecord = {}
  let fileData = []

  switch (action.type) {
    case 'RESET':
      return {
        ...state,
        employment_files: [],
        employment_file: { file_type: {} },
        editing: false,
        errMsg: null,
        succMsg: null,
        fetching: false,
      }

    case 'FILES_LOAD_PENDING':
    case 'FILE_LOAD_PENDING':
    case 'FILE_CREATE_PENDING':
    case 'DOCUMENT_REQUEST_CREATE_PENDING':
    case 'FILE_UPDATE_PENDING':
    case 'FILE_DELETE_PENDING':
    case 'DOCUMENT_REQUESTS_LOAD_PENDING':
    case 'DOCUMENT_REQUEST_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'FILES_LOAD_REJECTED':
    case 'FILE_LOAD_REJECTED':
    case 'FILE_CREATE_REJECTED':
    case 'DOCUMENT_REQUEST_CREATE_REJECTED':
    case 'DOCUMENT_REQUESTS_LOAD_REJECTED':
    case 'FILE_UPDATE_REJECTED':
    case 'FILE_DELETE_REJECTED':
    case 'DOCUMENT_REQUEST_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'FILES_LOAD_FULFILLED':
    case 'FILE_TYPES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        employment_files: [...state.employment_files, ...payload.data.data.employment_files],
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return {
        ...state,
        ...changes,
        employment_files_aprove: payload.data.data.employment_files,
      }

    case 'FILE_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'FILE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employment_files = [...state.employment_files]
      newRecord = payload.data.data.employment_file
      changes = {
        employment_files: [...employment_files, newRecord],
        editing: false,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'FILE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employment_files = [...state.employment_files]
      updatedRecord = payload.data.data.employment_file
      fileData = employment_files.map((employment_file) => {
        if (employment_file.id === updatedRecord.id) return updatedRecord
        return employment_file
      })
      changes = {
        employment_files: fileData,
        editing: false,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'FILE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employment_files = [...state.employment_files]
      employment_files.forEach((file, index) => {
        if (file.id === payload.id) {
          employment_files.splice(index, 1)
        }
      })
      return {
        ...state,
        employment_files,
        error: null,
        fetching: false,
      }

    case 'FILE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'FILE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'RESET_FORM':
      changes = {
        employment_file: { file_type: {} },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DOCUMENT_REQUEST_CREATE_FULFILLED':
    case 'DOCUMENT_REQUEST_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employment_files = [...state.employment_files]
      newRecord = payload.data?.data?.document_request || []
      changes = {
        employment_files: [...employment_files, newRecord],
        editing: false,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DOCUMENT_REQUESTS_LOAD_FULFILLED': {
      const document_requests = action.payload.data.data.document_requests || []
      employment_files = [...state.employment_files, ...document_requests]
      changes = {
        employment_files: [...employment_files],
        editing: false,
        fetching: false,
      }
      return { ...state, ...changes }
    }
    default:
      return state
  }
}
