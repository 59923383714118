import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { FieldValidator } from 'final-form'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import {
  TextInputField,
  RadioBoxField,
  NewSelectField,
  CheckboxField,
  MultiSelectField,
} from 'components/final-form'
import { groupBy, isEmpty, orderBy } from 'lodash'

import {
  composeValidators,
  maxLength,
  notOnlySpaces,
  required,
} from 'components/global/form/FormValidations'
import { TAnswers, TQuestion, TOption } from './type'

type TQuestionTitle = {
  title: string
  Required?: boolean
}

type TProps = Omit<TQuestion, 'priority' | 'is_required'> & {
  index: number
  name: string
  required: boolean
  fetching?: boolean
  menuPlacement?: 'auto' | 'top' | 'bottom'
}

const checkValidate = (isRequired: boolean): FieldValidator<unknown> => {
  if (isRequired) return composeValidators(notOnlySpaces, required)
  return notOnlySpaces
}

const QuestionTitle = ({ title, Required }: TQuestionTitle): JSX.Element => (
  <Flex itemsCenter>
    <Typography
      text={title}
      testId={title}
      variant="subheading"
    />
    {Required && (
      <>
        <Spacer width={4} />
        <Typography
          variant="caption"
          textColor="color/fg/lighter"
          text={`(${i18n.t('Required')})`}
        />
      </>
    )}
  </Flex>
)

const GetFields = (props: TProps): JSX.Element => {
  const {
    input_type,
    name_i18n,
    required: requiredField,
    options,
    index,
    id,
    name,
    fetching,
    menuPlacement = 'auto',
  } = props
  const { values } = useFormState()
  const { change } = useForm()
  const optionsList = useMemo(
    () => options.map((e) => ({ ...e, value: e.id, label: e.name_i18n })),
    [options],
  )
  const radioBoxOptions = options?.map((e) => ({
    ...e,
    id: e.id.toString(),
    testId: `question-${id}-Item-${e.id}`,
  }))

  const validateCheckBox = (allValues: Record<string, TAnswers[]>): string | undefined =>
    isEmpty(allValues.answers[index].option_ids) ? i18n.t('required') : undefined

  const handleMultiSelectCheckboxChange = (
    val: boolean,
    option: TOption,
    checkboxOther: TOption | undefined,
  ): void => {
    const oldValue = values.answers[index].option_ids ?? []
    const newValue: number[] = val
      ? [...oldValue, option.id]
      : oldValue.filter((item: number) => item !== option.id)
    if (checkboxOther && checkboxOther?.id === option?.id) {
      change(`${name}.other_reason`, null)
    }
    change(`${name}.option_ids`, newValue)
    change(`${name}.question_id`, id)
  }

  switch (input_type) {
    case 'radio_select':
      const radioOther = options?.find((e) => e.can_add_text)
      return (
        <div>
          <QuestionTitle title={name_i18n} />
          <Spacer height={8} />
          {/* @ts-expect-error need to migrate RadioBoxField to TS */}
          <RadioBoxField
            valueKey="id"
            labelKey="name_i18n"
            options={radioBoxOptions}
            name={`${name}.option_id`}
            onChange={(): void => {
              change(`${name}.question_id`, id)
              change(`${name}.other_reason`, null)
            }}
            {...(requiredField && {
              validate: required,
            })}
          />
          {values.answers[index].option_id &&
            values.answers[index].option_id === radioOther?.id && (
              <>
                <Spacer height={16} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  as="textarea"
                  testId={`question-${id}-radio-other`}
                  name={`${name}.other_reason`}
                  placeholder={i18n.t('enter_description')}
                  validate={composeValidators(required, notOnlySpaces, maxLength(500))}
                  {...(values.answers[index]?.other_reason?.length > 500 && {
                    status: 'error',
                  })}
                />
              </>
            )}
        </div>
      )

    case 'range_select':
      return (
        <div>
          <QuestionTitle
            title={name_i18n}
            Required={requiredField}
          />
          <Spacer height={8} />
          {options?.length && (
            <div style={{ width: 'fit-content' }}>
              <Flex justifyBetween>
                <Typography
                  variant="body-new/regular"
                  text={options[0]?.description_i18n || ''}
                />
                <Typography
                  variant="body-new/regular"
                  text={options.at(-1)?.description_i18n || ''}
                />
              </Flex>
              <Flex style={{ gap: 45 }}>
                {/* @ts-expect-error need to migrate RadioBoxField to TS */}
                <RadioBoxField
                  valueKey="id"
                  labelKey="name_i18n"
                  options={radioBoxOptions}
                  style={{ flexDirection: 'column' }}
                  name={`${name}.option_id`}
                  onChange={(): void => change(`${name}.question_id`, id)}
                  {...(requiredField && {
                    validate: required,
                  })}
                />
              </Flex>
            </div>
          )}
        </div>
      )

    case 'text_area':
      return (
        /* @ts-expect-error Need to migrate TextInputField.js to TypeScript */
        <TextInputField
          as="textarea"
          testId={`question-${id}-text-area`}
          name={`${name}.other_reason`}
          placeholder={i18n.t('enter_description')}
          onChange={(): void => change(`${name}.question_id`, id)}
          validate={checkValidate(requiredField)}
          label={
            <QuestionTitle
              title={name_i18n}
              Required={requiredField}
            />
          }
        />
      )

    case 'dropdown_list':
      const dropdownOther = options?.find((e) => e.can_add_text)
      return (
        <div>
          <div style={{ width: 335 }}>
            <NewSelectField
              valueKey="id"
              options={optionsList}
              labelKey="name_i18n"
              name={`${name}.option_id`}
              testId={`question-${id}-dropdwon-list`}
              maxMenuHeight={140}
              placeholder={i18n.t('select_the_system')}
              onChange={(v): void => {
                change(`${name}.option_id`, v?.value)
                change(`${name}.question_id`, id)
                change(`${name}.other_reason`, null)
              }}
              optionalLabel={i18n.t('Required')}
              label={name_i18n}
              {...(requiredField && {
                validate: required,
              })}
            />
          </div>
          {values.answers[index].option_id &&
            values.answers[index].option_id === dropdownOther?.id && (
              <>
                <Spacer height={16} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  as="textarea"
                  testId={`question-${id}-radio-other`}
                  name={`${name}.other_reason`}
                  placeholder={i18n.t('enter_description')}
                  validate={composeValidators(required, notOnlySpaces, maxLength(500))}
                  {...(values.answers[index]?.other_reason?.length > 500 && {
                    status: 'error',
                  })}
                />
              </>
            )}
        </div>
      )

    case 'multi_select':
      return (
        <MultiSelectField
          valueKey="id"
          labelKey="name_i18n"
          returnType="value"
          options={options}
          disabled={fetching}
          maxMenuHeight={200}
          menuPlacement={menuPlacement}
          name={`${name}.option_ids`}
          label={name_i18n}
          onChange={(list): void => {
            change(`${name}.question_id`, id)
            if (!list.length && requiredField) {
              change(`${name}.option_ids`, undefined)
            }
          }}
          {...(requiredField && {
            validate: required,
          })}
        />
      )

    case 'multi_select_checkbox':
      const groupedOptions = groupBy(options, 'category_en')
      const checkboxOther = options?.find((e) => e.can_add_text)
      return (
        <div>
          <QuestionTitle title={name_i18n} />
          <Spacer height={8} />
          <Flex
            flexCol
            style={{ gap: 4, marginInlineStart: 12 }}
          >
            {Object.entries(groupedOptions).map(([category, categoryOptions], indx) => (
              <Flex
                key={category}
                flexCol
              >
                <Typography
                  text={categoryOptions[0]?.category_i18n}
                  variant="body-new/bold"
                />
                <Spacer height={4} />
                {orderBy(categoryOptions, ['priority'], ['asc']).map((option, i) => (
                  <>
                    <CheckboxField
                      key={option.id}
                      label={option.name_i18n}
                      name={`${name}.options.${indx}.${i}`}
                      onChange={(val: boolean): void =>
                        handleMultiSelectCheckboxChange(val, option, checkboxOther)
                      }
                      testId={`question-${id}-checkbox-item-${option.id}`}
                      {...(requiredField && {
                        validate: (_, allValues) =>
                          validateCheckBox(allValues as Record<string, TAnswers[]>),
                      })}
                    />
                    {checkboxOther?.id === option?.id &&
                      values?.answers[index]?.options?.[indx]?.[i] && (
                        <>
                          <Spacer height={16} />
                          {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                          <TextInputField
                            as="textarea"
                            testId={`question-${id}-checkbox-other`}
                            name={`${name}.other_reason`}
                            placeholder={i18n.t('enter_description')}
                            validate={composeValidators(required, notOnlySpaces, maxLength(500))}
                            {...(values.answers[index]?.other_reason?.length > 500 && {
                              status: 'error',
                            })}
                          />
                        </>
                      )}
                  </>
                ))}
              </Flex>
            ))}
          </Flex>
        </div>
      )

    default:
      return <></>
  }
}

export default GetFields
