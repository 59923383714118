export const CUSTOM_REQUESTS_LOAD = 'CUSTOM_REQUESTS_LOAD'
export const CUSTOM_REQUESTS_LOAD_PENDING = 'CUSTOM_REQUESTS_LOAD_PENDING'
export const CUSTOM_REQUESTS_LOAD_FULFILLED = 'CUSTOM_REQUESTS_LOAD_FULFILLED'
export const CUSTOM_REQUESTS_LOAD_REJECTED = 'CUSTOM_REQUESTS_LOAD_REJECTED'

export const CUSTOM_REQUESTS_CREATE = 'CUSTOM_REQUESTS_CREATE'
export const CUSTOM_REQUESTS_CREATE_PENDING = 'CUSTOM_REQUESTS_CREATE_PENDING'
export const CUSTOM_REQUESTS_CREATE_FULFILLED = 'CUSTOM_REQUESTS_CREATE_FULFILLED'
export const CUSTOM_REQUESTS_CREATE_REJECTED = 'CUSTOM_REQUESTS_CREATE_REJECTED'

export const CUSTOM_REQUESTS_UPDATE = 'CUSTOM_REQUESTS_UPDATE'
export const CUSTOM_REQUESTS_UPDATE_PENDING = 'CUSTOM_REQUESTS_UPDATE_PENDING'
export const CUSTOM_REQUESTS_UPDATE_FULFILLED = 'CUSTOM_REQUESTS_UPDATE_FULFILLED'
export const CUSTOM_REQUESTS_UPDATE_REJECTED = 'CUSTOM_REQUESTS_UPDATE_REJECTED'
