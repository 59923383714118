import QueryString from 'qs'
import { useHistory } from 'react-router-dom'

type KeywordsT = boolean | null | undefined

// ? there an issue when parse boolean/numbers that always be string
// solution source: https://github.com/ljharb/qs/issues/91
export function decoder(str: string): number | string | KeywordsT {
  // Match numbers
  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return +str
  }

  const keywords: Record<string, KeywordsT> = {
    true: true,
    false: false,
    null: null,
    undefined,
  }

  if (str in keywords) {
    return keywords[str]
  }

  try {
    return decodeURIComponent(str)
  } catch (e) {
    return str
  }
}

const useRouteQueries = <QueryReturnT extends Record<string, unknown>>(
  withCustomDecoder?: boolean,
): QueryReturnT => {
  const { location } = useHistory()
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    ...(withCustomDecoder && { decoder }),
  }) as QueryReturnT

  return query
}

export default useRouteQueries
