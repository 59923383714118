import { useState, forwardRef } from 'react'
import { Button, Flex } from '@jisr-hr/ds'
import {
  cloneParentOrgDepartments,
  departmentsPost,
  hierarchicalDepartmentsLoad,
  listDepartmentsLoad,
} from 'redux/newDepartments/actionCreators'
import { Form } from 'redux/newDepartments/types'
import { useDispatch, useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { OnboardingTier, Statuses } from 'containers/authorised/NewOnboarding/helper'
import { mixPanelAnalytics } from 'utils/mixpanel'
import DepartmentForm, { FormTypes } from './DepartmentForm'
import { useDepartmentsContext } from './context'

const AddParentDepartemnt = forwardRef<HTMLDivElement, object>((_, ref): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { isInOnboarding, setSearch } = useDepartmentsContext()

  const { cloning, hierarchical_departments } = useSelector((s) => s.newDepartments)
  const org = useSelector((s) => s.auth.employee?.organization)
  const isNewOnboarding = org?.status === Statuses.ONBOARDING_PHASE && org.new_onboarding_enabled
  const isSubOrg = useSelector(
    ({ organization }) => organization?.organization?.is_organization_subsidiary,
  )
  const employee = useSelector((s) => s.auth.employee)
  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER || false

  const onSubmit = (values: Form, form: FormTypes): void => {
    dispatch(
      departmentsPost({ ...values, ...(isNewOnboarding && { reference_source: 'custom' }) }),
    ).then(() => {
      if (isInOnboarding) {
        dispatch(organizationInfo())
      }
      form.reset()
      setIsOpen(false)
      setSearch({ value: values.child_id })
      dispatch(
        hierarchicalDepartmentsLoad({
          ...(isNewOnboarding &&
            isHighTier && {
              filter: { source: 'manual' },
            }),
        }),
      )
      dispatch(
        listDepartmentsLoad({
          ...(isNewOnboarding &&
            isHighTier && {
              filter: { source: 'sheet' },
            }),
        }),
      )
      dispatch(listDepartmentsLoad({}, true))
    })
  }

  const handleCloneParentLocations = (): void => {
    dispatch(cloneParentOrgDepartments()).then(() => {
      dispatch(
        hierarchicalDepartmentsLoad({
          ...(isNewOnboarding &&
            isHighTier && {
              filter: { source: 'manual' },
            }),
        }),
      )
      dispatch(
        listDepartmentsLoad({
          ...(isNewOnboarding &&
            isHighTier && {
              filter: { source: 'sheet' },
            }),
        }),
      )
    })
  }

  return (
    <div>
      {(isInOnboarding || isNewOnboarding) && (
        <Flex
          itemsCenter
          style={{ gap: 8 }}
        >
          {isSubOrg && !hierarchical_departments?.length && (
            <Button
              label={i18n.t('label.import_from_main_organization')}
              variant="outlined"
              leadingIcon="download-01"
              onClick={handleCloneParentLocations}
              disabled={cloning}
              size="medium"
            />
          )}
          <div ref={ref}>
            <Button
              label={i18n.t('add_new_department')}
              leadingIcon="plus"
              onClick={(): void => {
                setIsOpen(true)
                mixPanelAnalytics({ event_name: 'NewDepartment_Initiated' })
              }}
              size="medium"
            />
          </div>
        </Flex>
      )}
      <DepartmentForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={onSubmit}
        isHighTier={isHighTier}
      />
    </div>
  )
})

export default AddParentDepartemnt
