import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type TimesheetSettlementProps = {
  title: string
  filterKey: string
  filters: string[]
}

const TimesheetSettlement = (props: TimesheetSettlementProps) => {
  const { timesheet_settlement } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const optionList = returnBadgeList(timesheet_settlement)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        timesheet_settlement: [],
      })
    } else {
      handleFilter('timesheet_settlement', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.timesheet_settlement)) {
      setSelectedIds(filter.timesheet_settlement)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.timesheet_settlement])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={selectedIds}
      componentType="badges"
      {...props}
      title={i18n.t('modal.announcement.new_attendnace_sheet.subtitle_2')}
    />
  )
}

export default TimesheetSettlement
