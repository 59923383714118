import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'
import I18n from 'translations/i18n'
import { differenceInMinutes } from 'date-fns'
import { Trans } from 'react-i18next'
import { Typography, Flex } from '@jisr-hr/ds'
import {
  get24hour,
  increaseDecreaseTime,
  isNextDay,
  minuteToHumanReadable,
} from 'components/global/form/formNormalize'
import { composeValidators, invalidTime, required } from 'components/global/form/FormValidations'
import { TimePickerField } from 'components/final-form'
import { useSelector } from 'react-redux'
import useUpdateEffect from 'components/global/hooks/useUpdateEffect'

import Frame from '../../../ds/Frame'

import Styles from './EstimatedTime.module.css'
import { useRequest } from '../../../RequestProvider'

const estimateTypes = {
  late_in: I18n.t('estimated_clock_in_time'),
  early_out: I18n.t('estimated_clock_out_time'),
}

const durationType = {
  late_in: 'estimated_delay_duration',
  early_out: 'estimated_shortage_duration',
}

const EstimatedTime = ({ type, shift, recorded, request }) => {
  const { change } = useForm()
  const { handleDisableSubmit, editRequest } = useRequest()

  const penalize_actual_delay = useSelector(
    ({ attendancePolicy }) => attendancePolicy.attendanceEmployeePolicy?.penalize_actual_delay,
  )

  const actualDelay = !penalize_actual_delay ? shift?.allowed_delay : 0

  const shiftStartWithFlexible = increaseDecreaseTime(
    shift?.shift_start_time,
    '+',
    shift?.flexible_duration || 0,
  )
  const shiftTime = type === 'late_in' ? shiftStartWithFlexible : shift?.expected_exit_time
  const time = shiftTime ? get24hour(shiftTime) : {}

  const duration = recorded ? get24hour(recorded) : {}
  const durationDay = isNextDay(shiftStartWithFlexible, recorded) ? 3 : 2
  let difference = 0
  if (type === 'late_in') {
    const isEqualTime = duration?.hour === time?.hour && duration?.minutes === (time?.minutes || 0)

    if (!isEqualTime) {
      difference = differenceInMinutes(
        new Date(2014, 6, durationDay, duration?.hour, duration?.minutes, 0),
        new Date(2014, 6, 2, time?.hour, time?.minutes || 0, 0),
      )
      difference =
        difference > shift?.allowed_delay
          ? difference - actualDelay
          : difference - shift?.allowed_delay
    }
  }

  if (type === 'early_out') {
    const outPunchNextDay = isNextDay(shiftStartWithFlexible, shift?.expected_exit_time) ? 3 : 2

    difference = differenceInMinutes(
      new Date(2014, 6, outPunchNextDay, time?.hour, time?.minutes || 0, 0),
      new Date(2014, 6, durationDay, duration?.hour, duration?.minutes, 0),
    )

    difference = difference > shift?.allowed_shortage ? difference - shift?.allowed_shortage : 0
  }

  React.useEffect(() => {
    if (difference > 0) {
      change('estimateTime', difference)
      handleDisableSubmit(false)
    } else {
      handleDisableSubmit(true)
      change('estimateTime', null)
    }
  }, [difference])

  useUpdateEffect(() => {
    if (shiftTime) {
      change('duration', shiftTime)
    }
  }, [type, shiftTime])

  React.useEffect(() => {
    // Set duration initial value
    if (editRequest && request) {
      const durationInitValue = increaseDecreaseTime(
        shiftTime,
        type === 'late_in' ? '+' : '-',
        request?.excuse_time || 0,
      )
      change('duration', durationInitValue)
    }
  }, [editRequest, request?.id, shiftTime])

  React.useEffect(() => {
    change('isEstimate', true)
    return () => {
      change('isEstimate', false)
      change('duration', null)
      handleDisableSubmit(false)
    }
  }, [])

  const maxTime = isNextDay(shiftStartWithFlexible, shift?.expected_exit_time)
    ? null
    : shift?.expected_exit_time
  return (
    <Frame title={estimateTypes[type]}>
      <Flex
        flexCol
        className={Styles.estimatedTime}
      >
        <TimePickerField
          name="duration"
          validate={composeValidators(required, invalidTime)}
          minTime={maxTime ? shiftStartWithFlexible : null}
          maxTime={maxTime}
        />
        {difference > 0 && (
          <Typography
            text={
              <Trans
                i18nKey={durationType[type]}
                components={{ b: <b style={{ color: '#000' }} /> }}
                values={{ minutes: `${minuteToHumanReadable(difference)}` }}
              />
            }
            variant="body-new/regular"
            style={{ color: 'var(--color-base-colors-grey-600)' }}
          />
        )}
      </Flex>
    </Frame>
  )
}

EstimatedTime.propTypes = {
  type: PropTypes.string,
  recorded: PropTypes.number,
  shift: PropTypes.shape(),
  request: PropTypes.shape(),
}

export default React.memo(EstimatedTime)
