import React from 'react'
import PropTypes from 'prop-types'

const HOC = (WrappedComponent) => {
  class SelectedButtons extends React.Component {
    state = { selectedDays: [] }

    // sync the value from props to state
    static getDerivedStateFromProps(props) {
      if (props.selectedDays !== undefined) return { selectedDays: props.selectedDays }
      return null
    }

    handleOnClick = (day) => {
      const { selectedDays } = this.state
      if (selectedDays.includes(day)) {
        this.handleRemoveDay(day)
        this.props.onRemoved(day)
      } else {
        this.handleAddDay(day)
        this.props.onClicked(day)
      }
    }

    handleAddDay = (day) => {
      const { selectedDays } = this.state
      if (this.props.radioSelected) {
        this.setState({ selectedDays: [day] }, this.handleOnChange)
        return
      }
      if (!this.props.selectedDays) {
        selectedDays.push(day)
        this.setState({ selectedDays }, this.handleOnChange)
      }
    }

    handleRemoveDay = (day) => {
      const selectedDays = this.state.selectedDays.filter((e) => e !== day)

      if (!this.props.selectedDays) {
        this.setState({ selectedDays }, this.handleOnChange)
      }
    }

    handleOnChange = () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.selectedDays)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          selectedDays={this.state.selectedDays}
          handleOnClick={this.handleOnClick}
        />
      )
    }
  }

  SelectedButtons.propTypes = {
    onClicked: PropTypes.func,
    onRemoved: PropTypes.func,
    onChange: PropTypes.func,
    radioSelected: PropTypes.bool,
    selectedDays: PropTypes.arrayOf(String),
  }

  SelectedButtons.defaultProps = {
    onClicked: () => {},
    onRemoved: () => {},
  }

  return SelectedButtons
}

export default HOC
