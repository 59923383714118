import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Align = ({ children, right, floated, verticalCenter, testId }) => (
  <div
    data-testid={testId}
    className={classNames(
      'jisr-align',
      right && 'jisr-align--right',
      floated && 'jisr-align--floated',
      verticalCenter && 'jisr-align--vertica_center',
    )}
  >
    {children}
  </div>
)

Align.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  right: PropTypes.bool,
  testId: PropTypes.string,
  floated: PropTypes.bool,
  verticalCenter: PropTypes.bool,
}
export default Align
