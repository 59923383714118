import { Tooltip, Typography } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import { Destination } from 'apis/BenefitsManagement/FlightTicketPolicy/FlightTicketPolicy.type'
import { isRtl } from 'utils/uiHelpers'
import Frame from '../../../ds/Frame'
import InfoList from '../../../ds/InfoList'

const FlightTicket = (props: {
  departure_date: string
  return_date: string | null
  destination: Destination
  round_trip: boolean
  cabin_class: string
  exception_attributes: {
    exception_destination: boolean | null
    exception_class: boolean | null
    exception_trip: boolean | null
    exception_no_of_dependents: boolean | null
    no_of_dependents_covered: boolean | null
  }
}): JSX.Element => {
  const {
    departure_date,
    return_date,
    destination,
    round_trip,
    cabin_class,
    exception_attributes,
  } = props
  return (
    <Frame title={i18n.t('label.ticket_details')}>
      <InfoList
        items={[
          {
            icon: <DSIcon name="arrow-narrow-up-right" />,
            value: (
              <Typography
                variant="body-new/regular"
                text={format(departure_date, 'dd MMM yyyy')}
              />
            ),
            label: i18n.t('label.departure'),
          },
          round_trip &&
            return_date && {
              icon: <DSIcon name="arrow-narrow-down-left" />,
              value: (
                <Typography
                  variant="body-new/regular"
                  text={format(return_date, 'dd MMM yyyy')}
                />
              ),
              label: i18n.t('label.return'),
            },
          {
            icon: <DSIcon name="globe-05" />,
            value: (
              <div className="flex gap-[4px]">
                <Typography
                  variant="body-new/regular"
                  text={destination?.name_i18n}
                />
                {exception_attributes?.exception_destination && (
                  <Tooltip
                    supportingText={i18n.t(
                      'communication.user_policy_does_not_support_the_choosen_destination',
                    )}
                    arrow={isRtl ? 'topLeft' : 'topRight'}
                  >
                    <DSIcon name="alert-triangle" />
                  </Tooltip>
                )}
              </div>
            ),
            label: i18n.t('label.destination'),
          },
          {
            icon: <DSIcon name="plane" />,
            value: (
              <div className="flex gap-[4px]">
                <Typography
                  variant="body-new/regular"
                  text={i18n.t(round_trip ? 'label.round_trip' : 'label.one_way')}
                />
                {exception_attributes?.exception_trip && (
                  <Tooltip
                    supportingText={i18n.t(
                      'communication.the_users_policy_does_not_support_the_chosen_trip_type_but_you_can_still_proceed_with_the_request.',
                    )}
                    arrow={isRtl ? 'topLeft' : 'topRight'}
                  >
                    <DSIcon name="alert-triangle" />
                  </Tooltip>
                )}
              </div>
            ),
            label: i18n.t('label.trip_type'),
          },
          cabin_class && {
            icon: <DSIcon name="luggage-01" />,
            value: (
              <div className="flex gap-[4px]">
                <Typography
                  variant="body-new/regular"
                  text={cabin_class}
                />
                {exception_attributes?.exception_class && (
                  <Tooltip
                    supportingText={i18n.t(
                      'communication.user_policy_does_not_support_the_chosen_cabin_class',
                    )}
                    arrow={isRtl ? 'topLeft' : 'topRight'}
                  >
                    <DSIcon name="alert-triangle" />
                  </Tooltip>
                )}
              </div>
            ),
            label: i18n.t('label.cabin_class'),
          },
        ].filter(Boolean)}
      />
    </Frame>
  )
}

export default FlightTicket
