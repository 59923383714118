import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function loadAttendancePolicyStats(): Action {
  const url = `/${API_VERSION_TWO}/attendance/policies/stats`

  return {
    type: actions.ATTENDANCE_POLICY_STATS,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function loadEmployeePolices(
  id: number,
  params: { policy_type: 'overtime' | 'excuse' },
): Action {
  const url = `/${API_VERSION_TWO}/attendance/employees/${id}/policies`

  return {
    type: actions.ATTENDANCE_EMPLOYEE_POLICES,
    payload: handleAPI(url, params, 'GET'),
  }
}
