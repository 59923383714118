import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import { ReactComponent as DownloadIcon } from 'assets/figma-icons/download-box.svg'
import styles from './styles.module.css'

type TDownloadBox = {
  handelDownload: () => void
  title: string
}

function DownloadBox(props: TDownloadBox): JSX.Element {
  const { title } = props
  return (
    <Flex
      justifyBetween
      itemsCenter
      className={styles.downloadBox}
      onClick={(): void => {
        props.handelDownload()
      }}
    >
      <Typography
        variant="title-1"
        text={title}
      />

      <DownloadIcon />
    </Flex>
  )
}

DownloadBox.propTypes = {
  title: PropTypes.string,
  handelDownload: PropTypes.func,
}

export default DownloadBox
