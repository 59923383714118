export const GEOFENCING_LOAD = 'GEOFENCING_LOAD'
export const GEOFENCING_LOAD_PENDING = 'GEOFENCING_LOAD_PENDING'
export const GEOFENCING_LOAD_FULFILLED = 'GEOFENCING_LOAD_FULFILLED'
export const GEOFENCING_LOAD_REJECTED = 'GEOFENCING_LOAD_REJECTED'

export const GEOFENCY_LOAD = 'GEOFENCY_LOAD'
export const GEOFENCY_LOAD_PENDING = 'GEOFENCY_LOAD_PENDING'
export const GEOFENCY_LOAD_FULFILLED = 'GEOFENCY_LOAD_FULFILLED'
export const GEOFENCY_LOAD_REJECTED = 'GEOFENCY_LOAD_REJECTED'

export const GEOFENCE_UPDATE = 'GEOFENCE_UPDATE'
export const GEOFENCE_UPDATE_PENDING = 'GEOFENCE_UPDATE_PENDING'
export const GEOFENCE_UPDATE_FULFILLED = 'GEOFENCE_UPDATE_FULFILLED'
export const GEOFENCE_UPDATE_REJECTED = 'GEOFENCE_UPDATE_REJECTED'

export const GEOFENCE_DELETE = 'GEOFENCE_DELETE'
export const GEOFENCE_DELETE_PENDING = 'GEOFENCE_DELETE_PENDING'
export const GEOFENCE_DELETE_FULFILLED = 'GEOFENCE_DELETE_FULFILLED'
export const GEOFENCE_DELETE_REJECTED = 'GEOFENCE_DELETE_REJECTED'

export const GEOFENCING_NOT_LINKED_LOAD = 'GEOFENCING_NOT_LINKED_LOAD'
export const GEOFENCING_NOT_LINKED_LOAD_PENDING = 'GEOFENCING_NOT_LINKED_LOAD_PENDING'
export const GEOFENCING_NOT_LINKED_LOAD_FULFILLED = 'GEOFENCING_NOT_LINKED_LOAD_FULFILLED'
export const GEOFENCING_NOT_LINKED_LOAD_REJECTED = 'GEOFENCING_NOT_LINKED_LOAD_REJECTED'

export const RESET_GEOFENCY_DETAIL = 'RESET_GEOFENCY_DETAIL'
