import React from 'react'
import i18n from 'translations/i18n'
import { Banner, Flex } from '@jisr-hr/ds'
import { useDispatch } from 'react-redux'
import { omit } from 'lodash'
import JisrLoader from 'components/global/JisrLoader'
import {
  createDelegationRequests,
  updateDelegationRequests,
} from 'redux/leaves/DelegationRequest/actionsCreator'
import { DelegationDetails, Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const DelegationRequest = () => {
  const dispatch = useDispatch()
  const {
    setInitialValues,
    setOnSubmit,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const onSubmit = (val) => {
    const values = {
      ...val,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }
    if (val.id) {
      return dispatch(
        updateDelegationRequests(empId, val.id, {
          ...values,
          attachments: values?.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }
    return dispatch(
      createDelegationRequests(empId, {
        ...val,
        attachments: val?.attachments?.map((it) => omit(it, 'id')),
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({ ...request, delegator_id: request.delegator?.id, deletedAttachments: [] })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: 16 }}
    >
      {loading && <JisrLoader absolute />}
      <Banner
        color="info"
        variant="tinted"
        leadingIcon="alert-circle"
        description={i18n.t('communication.delegation_exceptions')}
      />
      <Profile employee={employee} />
      <DelegationDetails />

      <Reason fieldPlaceholder={i18n.t('placeholder.reason_for_delegation')} />
    </Flex>
  )
}

export default DelegationRequest
