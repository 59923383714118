import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type OrganizationConfigurationType<T> = {
  id: number
  name: string
  value: T
  is_applicable: boolean
  is_all_employee_selected: boolean
  number_of_employees: number
  employees: []
}

type InitStateType = {
  enabled_configuration: OrganizationConfigurationType<boolean> | null
  min_vacation_salary_days: OrganizationConfigurationType<number> | null
  fetching: boolean
  errMsg: string
}

const initialState: InitStateType = {
  enabled_configuration: null,
  min_vacation_salary_days: null,
  fetching: false,
  errMsg: '',
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING:
    case actions.MIN_VACATION_SALARY_DAYS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED:
      return {
        ...state,
        min_vacation_salary_days: {
          ...payload.data.data.organization_configuration,
        },
        fetching: false,
      }

    case actions.MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        min_vacation_salary_days: {
          ...payload.data.data.organization_configuration,
        },
        fetching: false,
      }

    case actions.IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED:
      return {
        ...state,
        enabled_configuration: { ...payload.data.data.organization_configuration },
      }

    case actions.IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        enabled_configuration: { ...payload.data.data.organization_configuration },
        fetching: false,
      }

    case actions.MIN_VACATION_SALARY_DAYS_LOAD_REJECTED:
    case actions.MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED:
    case actions.IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED:
    case actions.IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
