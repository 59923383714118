import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { RegionsType } from '../countries/reducer'
import * as actions from './actions'

export type LocationsType = {
  id: number
  address_en: string
  address_ar: string
  address_i18n: string
  city: RegionsType
  area: {
    id: number
    name: string
    name_ar: string
    name_i18n: string
    country: {
      id: number
      name_en: string
      name_ar: string
      name_i18n: string
      alpha_2_code: string
    }
  }
}

const initialState = {
  fetching: false,
  cloning: false,
  locations: [] as LocationsType[],
  pagination: {} as PaginationTypes,
}

type TInitialState = typeof initialState

export default function reducer(state = initialState, action: Action): TInitialState {
  const { payload } = action
  switch (action.type) {
    case actions.LOCATIONS_SHOW_PENDING:
    case actions.ONBOARDING_LOCATIONS_POST_PENDING:
    case actions.ONBOARDING_LOCATIONS_PUT_PENDING:
    case actions.ONBOARDING_LOCATIONS_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CLONE_PARENT_ORG_LOCATIONS_PENDING:
      return {
        ...state,
        cloning: true,
      }

    case actions.LOCATIONS_LOAD_FULFILLED:
      return {
        ...state,
        pagination: payload.data?.data?.pagination,
        locations: payload.data?.data?.locations.map((p: LocationsType) => ({
          ...p,
          value: p.address_i18n,
          label: p.address_i18n,
        })),
        fetching: false,
      }

    case actions.LOCATIONS_SHOW_FULFILLED:
    case actions.ONBOARDING_LOCATIONS_PUT_FULFILLED:
    case actions.ONBOARDING_LOCATIONS_DELETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }
    case actions.ONBOARDING_LOCATIONS_POST_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.CLONE_PARENT_ORG_LOCATIONS_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        cloning: false,
      }

    case actions.LOCATIONS_SHOW_REJECTED:
    case actions.ONBOARDING_LOCATIONS_POST_REJECTED:
    case actions.ONBOARDING_LOCATIONS_PUT_REJECTED:
    case actions.ONBOARDING_LOCATIONS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.CLONE_PARENT_ORG_LOCATIONS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        cloning: false,
      }

    default:
      return state
  }
}
