import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  fetching: false,
  fetchingEligibility: false,
  eligibility_data: {},
}

let changes = null
export default function overtimeRequestReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'OVERTIME_REQUEST_PENDING':
    case 'UPDATE_OVERTIME_PENDING':
    case 'UPDATE_OVERTIME_REQUEST_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }
    case 'OVERTIME_REQUEST_CHECK_ELIGIBILITY_PENDING':
      changes = {
        fetchingEligibility: true,
      }
      return { ...state, ...changes }

    case 'OVERTIME_REQUEST_FULFILLED':
    case 'UPDATE_OVERTIME_REQUEST_FULFILLED':
    case 'UPDATE_OVERTIME_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OVERTIME_REQUEST_CHECK_ELIGIBILITY_FULFILLED':
      changes = {
        eligibility_data: payload.data.data,
      }
      return { ...state, ...changes }

    case 'OVERTIME_REQUEST_CHECK_ELIGIBILITY_REJECTED':
    case 'OVERTIME_REQUEST_REJECTED':
    case 'UPDATE_OVERTIME_REJECTED':
    case 'UPDATE_OVERTIME_REQUEST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
