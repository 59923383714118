import React from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { FileUpload } from '@jisr-hr/ds-beta'
import { useSelector } from 'utils/hooks'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'

import { ReactComponent as FileIcon } from '../../icons/file-text.svg'
import { useRequest } from '../../RequestProvider'
import useTaskFooter from '../../Footer/useTaskFooter'

const IdentificationInfoTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { fetching_submit } = useSelector((state) => state.approvals)
  const { onAttachTaskFile } = useTaskFooter()

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          created_at: request?.request?.created_time,
          requested_on: request?.request?.created_time,
          type_i18n: request.name,
          due_date: request.due_date,
          items: [
            {
              icon: <FileIcon />,
              label: i18n.t('document_type'),
              value: request.task_info?.document_type,
            },
            {
              icon: <FileIcon />,
              label: i18n.t('document_number'),
              value: request.task_info?.document_number,
            },
          ],
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default IdentificationInfoTask
