import { Flex, Typography } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { JISRI_SELECTED_LANGUAGE } from '../constants'
import { ClientMessageComponentProps } from '../types'

const ClientMessageComponent = ({
  message,
  setMessageList,
  showSelectLanguageOption,
  isLanguageSelected,
}: ClientMessageComponentProps) => {
  const onLanguageSelected = (language: string) => {
    if (isLanguageSelected) {
      return
    }
    localStorage.setItem(JISRI_SELECTED_LANGUAGE, language)
    setMessageList((prev) => [
      ...prev,
      {
        type: 'bot',
        message: i18n.t('communication.how_can_i_help_you', {
          lng: language,
        }),
        showLoader: false,
        showHelpOptions: true,
      },
    ])
  }

  if (showSelectLanguageOption) {
    return (
      <Flex
        justifyEnd
        className="gap-2"
      >
        <div
          onClick={() => {
            onLanguageSelected('ar')
          }}
          className="cursor-pointer"
        >
          <Typography
            text={i18n.t('label.arabic_switcher')}
            variant="subtitle-1"
            className="self-end bg-base-colors-grey-200 mb-4 rounded-full px-4 py-2"
            textColor="color/fg/default"
          />
        </div>
        <div
          onClick={() => {
            onLanguageSelected('en')
          }}
          className="cursor-pointer"
        >
          <Typography
            text={i18n.t('label.english')}
            variant="subtitle-1"
            className="self-end bg-base-colors-grey-200 mb-4 rounded-full px-4 py-2"
            textColor="color/fg/default"
          />
        </div>
      </Flex>
    )
  }

  return (
    <Typography
      text={message}
      variant="subtitle-1"
      className={`self-end bg-base-colors-grey-200 p-3 max-w-[70%] rounded-tl-lg rounded-tr-lg ${
        i18n.language === 'ar' ? 'rounded-br-lg' : 'rounded-bl-lg'
      }  mb-6`}
      textColor="color/fg/default"
    />
  )
}

export default ClientMessageComponent
