import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { DataType } from './type'

// POST
export const businessUnitCreate = (body: DataType): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/business_units`
  return {
    type: actions.ONBOARDING_BUSINESS_UNITS_POST,
    payload: handleAPI(url, {}, 'POST', body),
  }
}

export const businessUnitUpdate = (id: number, body: DataType): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/business_units/${id}`

  return {
    type: actions.ONBOARDING_BUSINESS_UNITS_PUT,
    payload: handleAPI(url, {}, 'PUT', body),
  }
}

// GET
export const businessUnitShow = (id: number, params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/business_units/${id}`

  return {
    type: actions.ONBOARDING_BUSINESS_UNITS_SHOW,
    payload: handleAPI(url, params, 'GET'),
  }
}

// GET
export const businessUnitLoad = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/business_units`

  return {
    type: actions.ONBOARDING_BUSINESS_UNITS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const businessUnitDelete = (id: number): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/business_units/${id}`

  return {
    type: actions.ONBOARDING_BUSINESS_UNITS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}
