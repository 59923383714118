import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import I18n from 'translations/i18n'
import { Action } from 'types/redux'
import * as actions from './actions'

type PayrollFiltersType = Record<
  | 'commercial_registration_ids'
  | 'location_ids'
  | 'department_ids'
  | 'employment_type_ids'
  | 'job_title_ids'
  | 'business_unit_ids'
  | 'nationalities'
  | 'outsourcing_company_ids'
  | 'grade_ids'
  | 'payment_methods'
  | 'report_type'
  | 'area_ids',
  number[] | string
> & {
  status_employee: string[]
}

export type PayrollReportParamsType = {
  id: number | null
  paygroup_id: number | null
  columns?: string[]
  filter: Partial<PayrollFiltersType>
  search: string
}

export type FlattenedPayrollReportParamsType = Omit<PayrollReportParamsType, 'filter'> &
  PayrollReportParamsType['filter']

export type Payrun = {
  attendance_review_request_id: string
  attendance_review_status: string
  attendance_reviewer_name?: string
  attendance_reviewer_url?: string
  confirmed_at: string
  cut_off_from_date: string
  cut_off_to_date: string
  cutoff_end_date: string
  cutoff_start_date: string
  employees_count: number
  id: number
  month: number
  month_year_i18n: string
  pay_period_end: string
  pay_period_start: string
  payroll_approval_cycle: null
  payrun_id: number
  remaining_days: number | null
  status: string
  status_i18n: string
  year: number
}

type PayrunSummary = {
  current_employees_count: number
  current_pay_period: {
    from: string
    to: string
  }
  current_total_payoff: number
  previous_pay_period: {
    from: string
    to: string
  }
  suspended_employees_count: number
}

type InitialStateType = {
  fetching_share: boolean
  fetching: boolean
  payrun: Payrun | null
  payrun_summary: PayrunSummary | null
  header: string[]
  records: string[][]
  total: string[]
  employees_ids: string[]
}

export const initialState: InitialStateType = {
  fetching_share: false,
  fetching: false,
  payrun: null,
  payrun_summary: null,
  header: [],
  records: [],
  total: [],
  employees_ids: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYROLL_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYROLL_REPORT_SHARE_PENDING: {
      return {
        ...state,
        fetching_share: true,
      }
    }

    case actions.PAYROLL_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        payrun: action.payload.data.data?.payrun,
        payrun_summary: action.payload.data.data?.payrun_summary,
        header: action.payload.data.data?.header,
        records: action.payload.data.data?.records,
        total: action.payload.data.data?.total,
        employees_ids: action.payload.data.data?.employees_ids,
        fetching: false,
      }
    }

    case actions.PAYROLL_REPORT_SHARE_FULFILLED: {
      Toastr.success(I18n.t('email_sent_successfully'))
      return {
        ...state,
        fetching_share: false,
      }
    }

    case actions.PAYROLL_REPORT_SHARE_REJECTED:
    case actions.PAYROLL_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }
    }

    default:
      return state
  }
}
