import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Spacer, Flex, Tag, Button, Modal } from '@jisr-hr/ds'
import {
  employmentTypesList,
  destroyEmploymentTypes,
  createNewEmploymentTypes,
} from 'redux/organizationOnborading/employmentTypes/actionCreators'
import {
  indexCompanies,
  destroyCompanies,
  createNewCompanies,
} from 'redux/organizationOnborading/outsourcingCompanies/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'

import { ReactComponent as Plus } from '@jisr-hr/design-system/dist/assets/icons/Icons12px/Plus.svg'
import I18n from 'translations/i18n'
import classNames from 'classnames'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import PageNwe from './PageNwe'
import StepTemplate from '../../../components/StepTemplate'
import { StepsTypes } from '../../../types/onboardingTypes'
import {
  ElementType,
  EmploymentTypesFetching,
  OutsourcingCompaniesType,
  SubmitEmploymentType,
  SubmitNewEmploymentType,
} from './EmploymentType.types'
import CheckboxItem from './CheckboxItem'
import Styles from './EmploymentType.module.css'

const lang = I18n.language

const employmentTypes = [
  {
    name: 'Full Time',
    name_ar: 'دوام كامل',
    name_i18n: lang === 'ar' ? 'دوام كامل' : 'Full Time',
  },
  {
    name: 'Part Time',
    name_ar: 'دوام جزئي',
    name_i18n: lang === 'ar' ? 'دوام جزئي' : 'Part Time',
  },
  {
    name: 'Remote',
    name_ar: 'موظف عن بعد',
    name_i18n: lang === 'ar' ? 'موظف عن بعد' : 'Remote',
  },
  {
    name: 'Domestic Labor',
    name_ar: 'العمالة المنزلية',
    name_i18n: lang === 'ar' ? 'العمالة المنزلية' : 'Domestic Labor',
  },
]
const fixedData = ['Full Time', 'Part Time', 'Domestic Labor', 'Remote', 'Ajeer']

const EmploymentType = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const { employment_types } = useSelector((state) => state.employmentTypes)
  const fetchingEmploymentTypes = useSelector(
    (s: EmploymentTypesFetching) => s.employmentTypes.fetching,
  )
  const fetchingOutsourcingCompanies = useSelector(
    ({ outsourcingCompanies }: EmploymentTypesFetching) => outsourcingCompanies.fetching,
  )

  const [ajeer, setAjeer] = useState(false)
  const [pageNwe, setPageNwe] = useState(false)
  const [textAndCheck, setTextAndCheck] = useState('')
  const [outsourcingCompanies, setOutsourcingCompanies] = useState<OutsourcingCompaniesType>([])

  const fetching = fetchingEmploymentTypes || fetchingOutsourcingCompanies
  const NameEmploymentTypes = employment_types.map((e) => e.name)

  const onClickNew = (value: string): void => {
    setTextAndCheck(value)
    setPageNwe(true)
  }

  const reload = (): void => {
    dispatch(organizationInfo())
    dispatch(employmentTypesList())
  }

  const onSubmitPageNwe = (data: SubmitNewEmploymentType): void => {
    if (textAndCheck === 'define_a_new_employment_type') {
      dispatch(createNewEmploymentTypes(data)).then(({ value }) => {
        if (value.data.success) {
          reload()
          setPageNwe(false)
        }
      })
    } else if (textAndCheck === 'whats_the_name_of_outsource_provider_description') {
      dispatch(createNewCompanies(data)).then(({ value }) => {
        if (value.data.success) {
          if (outsourcingCompanies.length === 0) {
            dispatch(createNewEmploymentTypes({ name: 'Ajeer', name_ar: 'موظف أجير' })).then(() => {
              reload()
            })
          }
          setOutsourcingCompanies([...outsourcingCompanies, value.data.data.outsourcing_company])
          setPageNwe(false)
        }
      })
    }
  }

  const onSubmitEmployment = ({ status, value }: SubmitEmploymentType): void => {
    if (status) {
      dispatch(
        createNewEmploymentTypes({
          name: value.name,
          name_ar: value.name_ar,
        }),
      ).then(() => reload())
    }
    if (!status) {
      if (!value?.id) return
      dispatch(destroyEmploymentTypes(value.id)).then(() => reload())
    }
  }

  const onSubmitAjeer = (checked: boolean): void => {
    if (checked) {
      setAjeer(checked)
    } else if (!checked && outsourcingCompanies.length === 0) {
      setAjeer(checked)
    }
  }

  const deleteAjeer = (id: number): void => {
    dispatch(destroyCompanies(id)).then(() => {
      const outsourcingCompany = outsourcingCompanies.filter(
        (element: ElementType) => element.id !== id,
      )
      if (outsourcingCompanies.length === 1) {
        const ajeerType = employment_types.find((element) => element.name === 'Ajeer')
        if (!ajeerType?.id) return
        dispatch(destroyEmploymentTypes(ajeerType.id)).then(() => {
          dispatch(employmentTypesList())
          setOutsourcingCompanies(outsourcingCompany)
        })
      }
      setOutsourcingCompanies(outsourcingCompany)
    })
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'contract_type' })

  useEffect(() => {
    dispatch(employmentTypesList())
    dispatch(indexCompanies()).then(({ value }) => {
      if (value.data.data.outsourcing_companies.length > 0) {
        setAjeer(true)
        setOutsourcingCompanies(value.data.data.outsourcing_companies)
      } else {
        setAjeer(false)
      }
    })
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!employment_types.length && !ajeer}
    >
      <Typography
        variant="interface/default/m"
        text={I18n.t('what_employment_types_you_have')}
      />
      <Spacer height="16px" />
      <Flex
        className={Styles.checkbox}
        flexWrap
      >
        {employmentTypes.map((employmentType) => (
          <CheckboxItem
            disabled={fetching}
            key={employmentType.name}
            label={employmentType.name_i18n}
            active={NameEmploymentTypes.includes(employmentType.name)}
            onChange={(event): void => {
              const data = {
                value:
                  employment_types.find((element) => element.name === employmentType.name) ||
                  employmentType,
                status: event.target.checked,
              }
              onSubmitEmployment(data)
            }}
          />
        ))}
        {employment_types.map((element) => {
          if (!fixedData.includes(element.name)) {
            return (
              <CheckboxItem
                active
                key={element.id}
                disabled={fetching}
                label={element.name_i18n}
                onChange={(event): void => {
                  const data = {
                    value: element,
                    status: event.target.checked,
                  }
                  onSubmitEmployment(data)
                }}
              />
            )
          }
          return null
        })}
        <Flex
          itemsCenter
          className={Styles.buttonNewEmployment}
          onClick={(): void => onClickNew('define_a_new_employment_type')}
        >
          <Plus />
          <Typography
            variant="interface/default/m"
            text={I18n.t('add_new_contract_type')}
          />
        </Flex>
      </Flex>
      <Spacer height="32px" />
      <div className={Styles.borderBottom} />
      <Spacer height="32px" />

      <div className={Styles.textOutsource}>
        <Typography
          variant="interface/default/sm"
          text={I18n.t('for_outsource_employees')}
        />
      </div>
      <Flex className={Styles.checkbox}>
        <CheckboxItem
          active={ajeer}
          disabled={fetching}
          label={I18n.t('ajeer_onboarding')}
          onChange={(e): void => onSubmitAjeer(e.target.checked)}
        />
      </Flex>
      <Spacer height="7px" />
      <Flex
        itemsCenter
        className={classNames(Styles.selectDataTab, fetching && Styles.disabled)}
      >
        {outsourcingCompanies &&
          outsourcingCompanies.map((data) => (
            <Tag
              closeIcon
              key={data.id}
              disabled={fetching}
              label={data.name_i18n}
              onClose={(): void => deleteAjeer(data.id)}
            />
          ))}
        {ajeer && (
          <Button
            size="small"
            color="neutral"
            variant="outlined"
            trailingIcon="plus"
            label={I18n.t('add_new')}
            onClick={(): void => onClickNew('whats_the_name_of_outsource_provider_description')}
          />
        )}
      </Flex>

      <Modal
        header={{ title: I18n.t(textAndCheck), withClose: true }}
        open={pageNwe}
        onClose={(): void => setPageNwe(false)}
      >
        <PageNwe
          onSubmit={onSubmitPageNwe}
          fetching={fetching}
        />
      </Modal>
    </StepTemplate>
  )
}

export default EmploymentType
