import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { ProgressSteps } from '@jisr-hr/ds-beta'
import { Typography, Spacer, Flex, ButtonGroup } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import JisrLoader from 'components/global/JisrLoader'
import {
  onboardingResetToken,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'
import { ReactComponent as QuestionIcon } from 'assets/figma-icons/question-icon-group.svg'
import { ReactComponent as HomeIcon } from 'assets/figma-icons/samrt-home.svg'
import { UsersnapProvider } from 'components/UserSnap/UsersnapContext'
import { AxiosResponse } from 'axios'
import { OrganizationType } from 'types/auth/auth'
import { useHistory } from 'react-router-dom'
import {
  configurationsLoad,
  configurationsUpdate,
} from 'redux/organizationOnborading/organizationConfigurations/actionCreators'
import QueryString from 'qs'

import OnboardingWrapper from './components/OnboardingWrapper'
import OrganizationSetup from './OrganizationSetup'
import LeavesSetup from './LeavesSetup'
import PayrollSetup from './PayrollSetup'
import InviteEmployees from './InviteEmployees'
import { OnboardingContext } from './context'
import { orgSteps } from './OrganizationSetup/helper'
import { leaveSteps } from './LeavesSetup/helper'
import { handleUrl } from './helper'
import { microLeaveSteps, microOrgSteps, microNewPayrollSteps } from './microHelper'
import styles from './styles.module.css'

const langList = [
  { label: 'English', value: 'en' },
  { label: 'عربي', value: 'ar' },
]

export const CURRENT_STEP = {
  organization_info: 0,
  leaves_setup: 1,
  payroll_setup: 2,
  invite_employees: 3,
}

const calculateProgress = (val: number, total: number): number =>
  (Number(val) / Number(total)) * 100
const Onboarding = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const language = useSelector(
    ({ organizationConfigurations }) =>
      organizationConfigurations?.organization_configuration?.value,
  )

  const { organization: orgInfo, fetching } = useSelector(({ organization }) => organization)

  const [firstRender, setFirstRender] = useState(true)
  const {
    mainStep,
    setMainStep,
    setOnboardingSteps,
    orgCurrentStepNo,
    leaveCurrentStepNo,
    payrollCurrentStepNo,
    invCurrentStepNo,
    showSidebar,
    setShowSidebar,
    org,
    setOrg,
  } = useContext(OnboardingContext)

  const { onboarding_steps, first_admin_details, is_custom_labor_law_configurations } =
    orgInfo?.metadata ?? {}

  const orgKey = orgInfo.require_pincode ? 'microOrgSteps' : 'orgSteps'
  const leaveKey = orgInfo.require_pincode ? 'microLeaveSteps' : 'leaveSteps'
  const payrollKey = 'microPayrollSteps'
  const inviteKey = orgInfo.require_pincode ? 'microActivateSteps' : 'InviteSteps'
  const microPath = QueryString.stringify({ micro: orgInfo.require_pincode })

  const getInitStep = (
    stepStatus: string | undefined,
    stepName: string,
  ): null | string | number => {
    if (stepStatus === 'Completed') {
      if (localStorage[stepName] && Number(localStorage[stepName]) !== 0) {
        return localStorage.getItem(stepName)
      }
      return 1
    }
    return localStorage.getItem(stepName) || 0
  }

  const getPayrollTotalSteps = (): number => {
    return microNewPayrollSteps.length
  }

  const steps = [
    {
      label: I18n.t('organization_info_onboarding'),
      component: OrganizationSetup,
      initStep: getInitStep(onboarding_steps?.organization_info, `onboarding_step_${org.id}`),
      disabled:
        onboarding_steps?.organization_info === 'Pending' &&
        onboarding_steps?.current_step !== 'organization_info',
    },
    {
      label: I18n.t('leaves_setup'),
      component: LeavesSetup,
      initStep: getInitStep(onboarding_steps?.leaves_setup, `leave_setup_step_${org.id}`),
      disabled:
        onboarding_steps?.leaves_setup === 'Pending' &&
        onboarding_steps?.current_step !== 'leaves_setup',
    },
    {
      label: I18n.t('payroll_setup'),
      component: PayrollSetup,
      initStep: getInitStep(onboarding_steps?.payroll_setup, `payroll_setup_step_${org.id}`),
      disabled:
        onboarding_steps?.payroll_setup === 'Pending' &&
        onboarding_steps?.current_step !== 'payroll_setup',
    },
    {
      label: I18n.t(org.require_pincode ? 'invite_admins' : 'invite_employees'),
      component: InviteEmployees,
      initStep: getInitStep(onboarding_steps?.invite_employees, `invite_employees_step_${org.id}`),
      disabled:
        onboarding_steps?.invite_employees === 'Pending' &&
        onboarding_steps?.current_step !== 'invite_employees',
    },
  ]

  const STEPS_NUMBERS = {
    0: {
      currentStepNo: orgCurrentStepNo,
      totalSteps: orgInfo.require_pincode ? microOrgSteps.length : orgSteps.length,
      storageKey: 'onboarding_step_',
      key: orgKey,
      title: 'Organization Info',
    },
    1: {
      currentStepNo: leaveCurrentStepNo,
      totalSteps: orgInfo.require_pincode ? microLeaveSteps.length : leaveSteps.length,
      storageKey: 'leave_setup_step_',
      key: leaveKey,
      title: 'Leaves Setup',
    },
    2: {
      currentStepNo: payrollCurrentStepNo,
      totalSteps: getPayrollTotalSteps(),
      storageKey: 'payroll_setup_step_',
      key: payrollKey,
      title: 'Payroll Setup',
    },
    3: {
      currentStepNo: invCurrentStepNo,
      totalSteps: 2,
      storageKey: 'invite_employees_step_',
      key: inviteKey,
      title: 'Invite Employees',
    },
  }
  const { component: StepComponent, initStep } = steps[mainStep || 0]
  const isConfigurationStep =
    orgInfo?.require_pincode && !(mainStep === 1 && steps[mainStep || 0]?.initStep === '1')
  const onStepClick = (step: number): void => {
    const isBoolean = typeof is_custom_labor_law_configurations === 'boolean'

    const stepNumber = Number(
      localStorage.getItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`),
    )
    const path = handleUrl(stepNumber || 1, STEPS_NUMBERS[step]?.key, orgInfo.require_pincode)
    history.push(`?${path}`)
    setMainStep(step)
    if (step === 1 && (stepNumber === 1 || !stepNumber)) {
      localStorage.setItem(
        `${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`,
        isBoolean ? '2' : '1',
      )
    } else if (!stepNumber) {
      localStorage.setItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`, '1')
    }
  }

  const onChangeLanguage = (value: string | number | undefined): void => {
    if (typeof value === 'string') {
      dispatch(configurationsUpdate({ organization_configuration: { value } })).then(() => {
        localStorage.setItem('Locale', value)
        window.location.reload()
      })
    }
  }

  useEffect((): (() => void) => {
    return () => dispatch(onboardingResetToken())
  }, [])

  useEffect(() => {
    if (typeof mainStep === 'number' && mainStep === 0 && firstRender) {
      dispatch(organizationInfo()).then(
        ({ value }: { value: AxiosResponse<OrganizationType> }): void => {
          setOrg(value.data.data.organization)
          if (
            value.data.data.organization.require_pincode &&
            btoa(sessionStorage.getItem(atob('pincode')) || '')
          ) {
            dispatch(configurationsLoad())
          }
        },
      )
    }
    setFirstRender(false)
  }, [mainStep])

  useEffect(() => {
    const stepNumber = Number(
      localStorage.getItem(
        `${STEPS_NUMBERS[CURRENT_STEP[onboarding_steps?.current_step || '']]?.storageKey}${
          orgInfo?.id
        }`,
      ),
    )

    setMainStep(CURRENT_STEP[onboarding_steps?.current_step || ''])
    setOnboardingSteps(onboarding_steps)
    if (onboarding_steps?.current_step) {
      const path = handleUrl(
        stepNumber,
        STEPS_NUMBERS[CURRENT_STEP[onboarding_steps?.current_step || '']]?.key,
        orgInfo.require_pincode,
      )
      if (stepNumber) {
        history.push(`?${path}`)
      } else {
        history.push(`onboarding?${microPath}`)
      }
    }
  }, [onboarding_steps?.current_step])

  const handleHomeClick = (): void => {
    localStorage.setItem(
      `${STEPS_NUMBERS[CURRENT_STEP[onboarding_steps?.current_step || '']]?.storageKey}${org.id}`,
      '0',
    )
    setMainStep(CURRENT_STEP[org.metadata.onboarding_steps.current_step])
    history.push(`onboarding?${microPath}`)
  }
  const isBetween = !orgInfo?.require_pincode || isConfigurationStep

  return (
    <OnboardingWrapper>
      <>
        <UsersnapProvider
          initParams={{
            locale: I18n.language,
            custom: {
              'Company Details': {
                Slug: orgInfo?.slug,
                'Name Ar': orgInfo?.name_ar,
                'Name En': orgInfo?.name,
              },
              'User Details': {
                Name: first_admin_details?.full_name_en,
                Email: first_admin_details?.email,
              },
            },
          }}
        >
          {fetching && <JisrLoader absolute />}
          <Flex
            itemsCenter
            className={styles.mainStepprtContainer}
          >
            <Flex
              itemsCenter
              className={styles.mainStepperTitleContainer}
              onClick={handleHomeClick}
            >
              <HomeIcon />
              <Typography
                variant="title-1"
                text={I18n.t('home')}
              />
            </Flex>
            <Flex
              style={{ flexGrow: 1, maxWidth: 'calc(100vw - 333px)' }}
              itemsCenter
              justifyBetween={isBetween}
              justifyEnd={!isBetween}
            >
              {isBetween && (
                <ProgressSteps
                  activeStep={mainStep}
                  onStepClick={onStepClick}
                  steps={steps}
                  type="minimal"
                  activePercentage={calculateProgress(
                    STEPS_NUMBERS[mainStep]?.currentStepNo,
                    STEPS_NUMBERS[mainStep]?.totalSteps,
                  )}
                  disable="next_disabled"
                />
              )}
              {orgInfo?.require_pincode && (
                <>
                  <Spacer width={16} />
                  <ButtonGroup
                    list={langList}
                    selected={language}
                    onSelectChange={onChangeLanguage}
                  />
                </>
              )}
            </Flex>
            {!showSidebar && (
              <QuestionIcon
                onClick={(): void => {
                  setShowSidebar((prev: boolean) => !prev)
                }}
                style={{
                  position: 'absolute',
                  insetInlineEnd: showSidebar ? -60 : 23,
                  transition: 'inset ease-in-out 0.3s',
                  cursor: 'pointer',
                }}
              />
            )}
          </Flex>
          <StepComponent
            isConfigurationStep={isConfigurationStep}
            initStep={Number(initStep)}
          />
        </UsersnapProvider>
      </>
    </OnboardingWrapper>
  )
}

Onboarding.propTypes = {}

export default Onboarding
