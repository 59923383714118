import React from 'react'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import {
  Info,
  Reason,
  Tabs,
  Comments,
  Workflow,
  ExpenseClaimItems,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'
import { ReactComponent as MoneyIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/Money.svg'
import { useSelector } from 'utils/hooks'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const ExpenseClaimRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()

  const [activeTab, setActiveTab] = React.useState('request_details')
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency.currency_type_i18n,
  )
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              created_at: request.created_at,
              created_by: request.created_by,
              requested_on: request.created_at,
              employee: request?.employee,
              status: request?.status,
              status_i18n: request?.status_i18n,
              type_i18n: request?.request_name_i18n,
              request: request?.request,
              request_name: request?.request_name,
              cancellation_request_info: request?.cancellation_request_info,
              is_cancellation_request: request?.is_cancellation_request,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
              items: [
                {
                  label: I18n.t('total_expense_claim'),
                  icon: <MoneyIcon />,
                  value: `${currency} ${request.total || 0}`,
                },
              ],
            }}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <ExpenseClaimItems ecrItems={request.ecr_items} />
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}
      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </>
      )}
    </Flex>
  )
}

export default ExpenseClaimRequest
