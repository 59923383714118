import { useMemo } from 'react'
import i18n from 'translations/i18n'
import { useOrganizationsModuleStatus } from 'apis/Onboarding/organizationsModuleStatus/useOrganizationsModuleStatus'

export const useModulesList = () => {
  const { data: moduleStatus } = useOrganizationsModuleStatus({
    query: {
      cacheTime: 0,
      select: (s) => s.data.data,
    },
  })

  return useMemo(
    () => [
      {
        id: 'leaves',
        icon: 'clock',
        title: 'leaves',
        subTitle: i18n.t(
          'description.define_the_vacation_balances_and_allow_your_employees_to_request_leaves.',
        ),
        path: '/new_onboarding/module_setup/leaves',
        status: moduleStatus?.leave_module_status,
        completionPath: '/leave_management',
        completionLabel: 'label.go_to_leaves',
      },
      {
        id: 'attendance',
        icon: 'lightning-01',
        title: 'attendance',
        subTitle: i18n.t(
          'description.manage_your_employees_punch_in_and_punch_out_and_schedule_their_shifts.',
        ),
        path: '/dashboard',
        status: moduleStatus?.attendance_module_status,
        completionPath: '/attendance/tracking',
        completionLabel: 'label.go_to_attendance',
      },
      {
        id: 'payroll',
        icon: 'coins-stacked-01',
        title: 'payroll_new_onboarding',
        subTitle: i18n.t('description.manage_review_and_run_your_employees_payroll.'),
        path: '/payroll',
        status: moduleStatus?.payroll_module_status,
        completionPath: '/payroll',
        completionLabel: 'label.go_to_payroll',
      },
    ],
    [moduleStatus],
  )
}
