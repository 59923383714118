import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  competencies: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.COMPETENCIES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.COMPETENCIES_LOAD_FULFILLED: {
      const { previous_page } = action.payload.data.pagination
      return {
        ...state,
        competencies: [...(previous_page ? state.competencies : []), ...action.payload.data.data],
        pagination: action.payload.data.pagination,
        fetching: false,
      }
    }

    case actions.COMPETENCIES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
