export const PAYRUN_APPROVALS_LIST_LOAD = 'PAYRUN_APPROVALS_LIST_LOAD'
export const PAYRUN_APPROVALS_LIST_LOAD_PENDING = 'PAYRUN_APPROVALS_LIST_LOAD_PENDING'
export const PAYRUN_APPROVALS_LIST_LOAD_FULFILLED = 'PAYRUN_APPROVALS_LIST_LOAD_FULFILLED'
export const PAYRUN_APPROVALS_LIST_LOAD_REJECTED = 'PAYRUN_APPROVALS_LIST_LOAD_REJECTED'

export const PAYRUN_APPROVALS_STEPS_LOAD = 'PAYRUN_APPROVALS_STEPS_LOAD'
export const PAYRUN_APPROVALS_STEPS_LOAD_PENDING = 'PAYRUN_APPROVALS_STEPS_LOAD_PENDING'
export const PAYRUN_APPROVALS_STEPS_LOAD_FULFILLED = 'PAYRUN_APPROVALS_STEPS_LOAD_FULFILLED'
export const PAYRUN_APPROVALS_STEPS_LOAD_REJECTED = 'PAYRUN_APPROVALS_STEPS_LOAD_REJECTED'

export const PAYROLL_APPROVALS_STEPS_UPDATE = 'PAYROLL_APPROVALS_STEPS_UPDATE'
export const PAYROLL_APPROVALS_STEPS_UPDATE_PENDING = 'PAYROLL_APPROVALS_STEPS_UPDATE_PENDING'
export const PAYROLL_APPROVALS_STEPS_UPDATE_FULFILLED = 'PAYROLL_APPROVALS_STEPS_UPDATE_FULFILLED'
export const PAYROLL_APPROVALS_STEPS_UPDATE_REJECTED = 'PAYROLL_APPROVALS_STEPS_UPDATE_REJECTED'

export const PAYRUN_APPROVALS_CREATE_REQUEST = 'PAYRUN_APPROVALS_CREATE_REQUEST'
export const PAYRUN_APPROVALS_CREATE_REQUEST_PENDING = 'PAYRUN_APPROVALS_CREATE_REQUEST_PENDING'
export const PAYRUN_APPROVALS_CREATE_REQUEST_FULFILLED = 'PAYRUN_APPROVALS_CREATE_REQUEST_FULFILLED'
export const PAYRUN_APPROVALS_CREATE_REQUEST_REJECTED = 'PAYRUN_APPROVALS_CREATE_REQUEST_REJECTED'

export const PAYRUN_APPROVALS_LIST_REQUESTS = 'PAYRUN_APPROVALS_LIST_REQUESTS'
export const PAYRUN_APPROVALS_LIST_REQUESTS_PENDING = 'PAYRUN_APPROVALS_LIST_REQUESTS_PENDING'
export const PAYRUN_APPROVALS_LIST_REQUESTS_FULFILLED = 'PAYRUN_APPROVALS_LIST_REQUESTS_FULFILLED'
export const PAYRUN_APPROVALS_LIST_REQUESTS_REJECTED = 'PAYRUN_APPROVALS_LIST_REQUESTS_REJECTED'

export const PAYRUN_APPROVALS_REJECT = 'PAYRUN_APPROVALS_REJECT'
export const PAYRUN_APPROVALS_REJECT_PENDING = 'PAYRUN_APPROVALS_REJECT_PENDING'
export const PAYRUN_APPROVALS_REJECT_FULFILLED = 'PAYRUN_APPROVALS_REJECT_FULFILLED'
export const PAYRUN_APPROVALS_REJECT_REJECTED = 'PAYRUN_APPROVALS_REJECT_REJECTED'

export const PAYRUN_APPROVALS_APPROVE = 'PAYRUN_APPROVALS_APPROVE'
export const PAYRUN_APPROVALS_APPROVE_PENDING = 'PAYRUN_APPROVALS_APPROVE_PENDING'
export const PAYRUN_APPROVALS_APPROVE_FULFILLED = 'PAYRUN_APPROVALS_APPROVE_FULFILLED'
export const PAYRUN_APPROVALS_APPROVE_REJECTED = 'PAYRUN_APPROVALS_APPROVE_REJECTED'

export const PAYRUN_REOPEN = 'PAYRUN_REOPEN'
export const PAYRUN_REOPEN_PENDING = 'PAYRUN_REOPEN_PENDING'
export const PAYRUN_REOPEN_FULFILLED = 'PAYRUN_REOPEN_FULFILLED'
export const PAYRUN_REOPEN_REJECTED = 'PAYRUN_REOPEN_REJECTED'
