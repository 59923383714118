import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TData } from './type'

export function vacationSalaryCalculationLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payrun_configurations/vacation_salary_calculation`
  const method = 'GET'
  return {
    type: actions.VACATION_SALARY_CALCULATION_LOAD,
    payload: handleAPI(url, {}, method),
  }
}

export function vacationSalaryCalculationUpdate(data: TData): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payrun_configurations/vacation_salary_calculation`
  const method = 'PUT'
  return {
    type: actions.VACATION_SALARY_CALCULATION_UPDATE,
    payload: handleAPI(url, {}, method, { payrun_configuration: data }),
  }
}

export function benefitTypesLoad(parmas = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types`
  const method = 'GET'

  return {
    type: actions.ONBOARDING_BENEFIT_TYPES_LOAD,
    payload: handleAPI(url, { ...parmas, category: 'EarningType', template: 'public' }, method),
  }
}
