import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { LineManager } from 'redux/authUser/types'
import { Action } from 'types/redux'

export type businessUnitType = {
  id: number
  manager: LineManager
  number_of_employees: number
  description: string
  name: string
  name_ar: string
  name_i18n: string
}

type DefaultStateType = {
  business_units: businessUnitType[]
  editing: Record<string, unknown>
  errMsg: string | null
  succMsg: string | null
  error: string | null
  fetching: boolean
  loading: boolean
}

const DEFAULT_STATE: DefaultStateType = {
  business_units: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  error: null,
  fetching: false,
  loading: false,
}

let changes = null
export default function businessUnitReducer(
  state = DEFAULT_STATE,
  action: Action & { id: number },
): DefaultStateType {
  const { payload } = action
  let businessUnits = []
  let newRecord: businessUnitType
  let updatedRecord: businessUnitType
  switch (action.type) {
    case 'BUSINESS_UNITS_LOAD_PENDING':
      return {
        ...state,
        loading: true,
        errMsg: null,
        succMsg: null,
      }
    case 'BUSINESS_UNIT_CREATE_PENDING':
    case 'BUSINESS_UNIT_UPDATE_PENDING':
    case 'BUSINESS_UNIT_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'BUSINESS_UNITS_LOAD_REJECTED':
    case 'BUSINESS_UNIT_CREATE_REJECTED':
    case 'BUSINESS_UNIT_UPDATE_REJECTED':
    case 'BUSINESS_UNIT_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'BUSINESS_UNITS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'BUSINESS_UNIT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      businessUnits = [...state.business_units]
      newRecord = payload.data.data.business_unit
      changes = {
        business_units: [...businessUnits, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'BUSINESS_UNIT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.business_unit
      changes = {
        business_units: updateOneListOfRecords(state.business_units, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'BUSINESS_UNIT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        fetching: false,
        business_units: removeOneListOfRecords(state.business_units, payload.id),
        error: null,
      }

    case 'BUSINESS_UNIT_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'BUSINESS_UNIT_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
