import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type PayslipReportBase = {
  name: string
  value: string
  remarks: string[]
}

export type Addition = PayslipReportBase

export type Deduction = PayslipReportBase

export type Benefit = PayslipReportBase

export type Earning = {
  basic_salary: PayslipReportBase
  benefits: Benefit[]
  remarks: string[]
}

export type EmployeeDetails = {
  code: string
  name_i18n: string
  avatar: string
}

export type EmployeeInfo = {
  name: string
  value: string
}

export type Header = {
  title: string
  month_year: string
  earnings: string
  additions: string
  deductions: string
  total_earnings: string
  total_deductions: string
  net_salary: string
  employee_name: string
  employee_id: string
  designation: string
  payment_method: string
  bank_name: string
  date: string
  iban: string
  employee_signature: string
  director_signature: string
  currency: string
  basic_salary: string
  salary_type: string
  loan: string
  loans_addition: string
  loans_deduction: string
}

export type Total = {
  earnings: number
  deductions: number
  net_salary: number
}

export type PaySlipReport = {
  title: string
  month_year: string
  employee_signature: string
  employee_info: EmployeeInfo[]
  employee_details: EmployeeDetails
  header: Header
  earnings: Earning[]
  additions: Addition[]
  deductions: Deduction[]
  earnings_deductions: (string[] | string)[][]
  total: Total
  payment_method: string
  payment_info: Array<Array<null | string>>
  employee_id: number
}

type InitialStateType = {
  fetching: boolean
  payslip_report: PaySlipReport | null
}

export const initialState: InitialStateType = {
  fetching: false,
  payslip_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYSLIP_REPORT_LOAD_PENDING: {
      return {
        ...state,
        payslip_report: null,
        fetching: true,
      }
    }

    case actions.PAYSLIP_REPORT_LOAD_FULFILLED: {
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      const payslip_report = action.payload.data.data?.payslip_report as Omit<
        InitialStateType,
        'fetching'
      >['payslip_report']
      return {
        ...state,
        payslip_report,
        fetching: false,
      }
    }

    case actions.PAYSLIP_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
