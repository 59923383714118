/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable prefer-template */
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { CostCenter } from 'types/Accounts/costCenter'
import lodash from 'lodash'
import * as actions from './actions'

// ? ------------------- shared types -------------------
export type QuickBooksCategories = {
  internal_id: string
  name: string
  parent_internal_id?: string | null
}

export type GroupedClassesType = QuickBooksCategories & {
  options: QuickBooksCategories[]
}

// ? ------------------- end shared types -------------------

type QuickBooksCostCenter = CostCenter & {
  employees_ids?: number[]
}

type ReducerInitStateType = {
  all_selected_ids: number[]
  classes: QuickBooksCategories[]
  cost_centers: QuickBooksCostCenter[]
  fetching: boolean
  grouped_classes: GroupedClassesType[]
  grouped_locations: QuickBooksCategories[]
  grouping_class: QuickBooksCategories[]
  loading: boolean
  locations: QuickBooksCategories[]
  type_fetching: boolean
}

const initialState: ReducerInitStateType = {
  fetching: false,
  type_fetching: false,
  loading: false,
  grouped_classes: [],
  grouping_class: [],
  cost_centers: [],
  grouped_locations: [],
  all_selected_ids: [],
  locations: [],
  classes: [],
}

function reducer(state = initialState, action: Action): ReducerInitStateType {
  const { payload } = action

  switch (action.type) {
    case actions.QUICKBOOKS_COST_CENTER_LIST_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.QUICKBOOKS_COST_CENTER_CREATE_PENDING:
    case actions.QUICKBOOKS_COST_CENTER_DELETE_PENDING:
    case actions.QUICKBOOKS_COST_CENTER_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.QUICKBOOKS_LOCATIONS_LOAD_PENDING:
    case actions.QUICKBOOKS_CLASSES_LOAD_PENDING:
      return {
        ...state,
        type_fetching: true,
      }

    // * locations
    case actions.QUICKBOOKS_LOCATIONS_LOAD_FULFILLED:
      const locations: QuickBooksCategories[] = payload.data.data || []
      const grouped_locations = locations?.map((item) => {
        const parent = locations?.find((it) => it.internal_id === item.parent_internal_id)
        return {
          ...item,
          name: parent?.internal_id ? parent.name + ' / ' + item.name : item.name,
        }
      })
      return {
        ...state,
        grouped_locations: lodash.sortBy(grouped_locations, (data) => data?.name?.toLowerCase()),
        locations,
        type_fetching: false,
      }

    // * classes
    case actions.QUICKBOOKS_CLASSES_LOAD_FULFILLED: {
      const classes: QuickBooksCategories[] = payload.data.data || []
      const parent_class = classes?.filter((it) => !it.parent_internal_id)
      const grouped_classes: GroupedClassesType[] = parent_class.map((it) => ({
        ...it,
        options: classes?.filter((sub) => sub.parent_internal_id === it.internal_id),
      }))
      const grouping_class = classes?.map((item) => {
        const parent = classes?.find((it) => it.internal_id === item.parent_internal_id)
        return {
          ...item,
          name: parent?.internal_id ? parent.name + ' / ' + item.name : item.name,
        }
      })
      return {
        ...state,
        classes,
        grouped_classes,
        grouping_class,
        type_fetching: false,
      }
    }

    case actions.QUICKBOOKS_CLASSES_LOAD_REJECTED:
    case actions.QUICKBOOKS_LOCATIONS_LOAD_REJECTED:
      return {
        ...state,
        type_fetching: false,
      }

    case actions.QUICKBOOKS_COST_CENTER_LIST_LOAD_FULFILLED: {
      const cost_centers: CostCenter[] = payload.data.data.tracking_dimensions
      const cost_values: QuickBooksCostCenter[] = cost_centers?.map((cost) => ({
        ...cost,
        org_category_type: cost.org_category_type || 'custom',
        employees_ids:
          lodash.uniq(
            cost?.tracking_dimension_values?.map((it) => it.mapped_employee_ids!)?.flat(),
          ) || [],
      }))
      const all_selected_ids = cost_values?.map((it) => it.employees_ids!)?.flat()
      return {
        ...state,
        cost_centers: cost_values,
        all_selected_ids: lodash.uniq(all_selected_ids),
        fetching: false,
      }
    }
    case actions.QUICKBOOKS_COST_CENTER_CREATE_FULFILLED:
    case actions.QUICKBOOKS_COST_CENTER_UPDATE_FULFILLED:
    case actions.QUICKBOOKS_COST_CENTER_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.QUICKBOOKS_COST_CENTER_CREATE_REJECTED:
    case actions.QUICKBOOKS_COST_CENTER_UPDATE_REJECTED:
    case actions.QUICKBOOKS_COST_CENTER_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.QUICKBOOKS_COST_CENTER_LIST_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
