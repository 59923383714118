import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

export const initialState = {
  check_device_records: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHECK_DEVICE_RECORDS_FULFILLED:
      return {
        ...state,
        check_device_records: action.payload.data.data,
      }

    case actions.CHECK_DEVICE_RECORDS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
