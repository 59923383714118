import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Spacer, Checkbox, Flex } from '@jisr-hr/ds'
import cns from 'classnames'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import TipComponents from 'containers/public/Onboarding/components/TipComponents'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import { ReactComponent as Plus } from 'assets/figma-icons/add.svg'
import {
  onboardingHolidaysLoad,
  onboardingHolidaysDefaultLoad,
  createOnboardingHolidays,
  deleteOnboardingHolidays,
  UpdateOnboardingHolidays,
  onboardingHolidaysDefaultUpdate,
} from 'redux/leavesOnboarding/holidays/actionCreators'
import { THolidaysData, DefaultHolidays } from 'redux/leavesOnboarding/holidays/type'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import FormCustomLeave from './FormCustomLeave'
import styles from './styles.module.css'

type SubmitCheckbox = {
  id?: number
  status?: boolean
  value?: DefaultHolidays
}

const Holidays = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const lang = I18n.language
  const dispatch = useDispatch()
  const { holidays, fetching, selected_default, default_holidays } = useSelector(
    ({ onboardingHolidays }) => onboardingHolidays,
  )

  const [openOthers, setOpenOthers] = useState<boolean>(false)
  const [dataEdit, setDataEdit] = useState<THolidaysData | Record<string, never>>({})
  const defaultData = default_holidays.map((e) => e.name)
  const checkedDefaultData = holidays.map((e) => e.name)

  useEffect(() => {
    dispatch(onboardingHolidaysLoad())
    dispatch(onboardingHolidaysDefaultLoad())
  }, [])

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'public_holidays' })

  const onSubmit = (value: THolidaysData): void => {
    if (value?.id) {
      dispatch(UpdateOnboardingHolidays(value)).then(() => {
        dispatch(onboardingHolidaysLoad())
        setOpenOthers(false)
      })
      return
    }
    dispatch(createOnboardingHolidays(value)).then(() => {
      dispatch(onboardingHolidaysLoad())
      setOpenOthers(false)
    })
  }

  const onSubmitCheckbox = ({ status, value, id }: SubmitCheckbox): void => {
    if (status && value) {
      dispatch(createOnboardingHolidays(value)).then(() => dispatch(onboardingHolidaysLoad()))
      return
    }
    if (id) {
      dispatch(deleteOnboardingHolidays(id)).then(() => dispatch(onboardingHolidaysLoad()))
      return
    }
    const holiday = holidays.find((e) => e.name === value?.name)
    if (holiday?.id) {
      dispatch(deleteOnboardingHolidays(holiday?.id)).then(() => dispatch(onboardingHolidaysLoad()))
    }
  }

  const changeStatusDefault = (value: boolean): void => {
    dispatch(onboardingHolidaysDefaultUpdate(value)).then(() => dispatch(onboardingHolidaysLoad()))
  }

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('public_holidays')}
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('public_holidays_description')}
      />
      <Spacer height="32px" />
      <Flex
        flexCol
        className={styles.content}
      >
        <Flex justifyBetween>
          <Typography
            variant="title-1"
            text={I18n.t('select_holidays')}
          />

          <Flex
            itemsCenter
            onClick={(): void => {
              setDataEdit({
                name: '',
                name_ar: '',
                to_date: '',
                from_date: '',
                extend_probation: true,
                group_type: 'all_company',
              })
              setOpenOthers(true)
            }}
          >
            <Plus className={styles.svqPlus} />
          </Flex>
        </Flex>
        <Spacer height="8px" />
        <Checkbox
          active={selected_default}
          label={I18n.t('follow_the_labor_law_holidays')}
          onChange={(event): void => changeStatusDefault(event.target.checked)}
        />
        <Spacer height="16px" />
        <Flex
          flexCol
          className={cns(styles.cardsContainer, 'scroll-y')}
        >
          {default_holidays.map((holiday, i) => (
            <SwitchCard
              key={i}
              name={lang === 'en' ? holiday.name : holiday.name_ar}
              isActive={checkedDefaultData.includes(holiday.name)}
              handleOnEdit={(): void => {
                const data = holidays.find((e) => e.name === holiday.name)
                if (data) {
                  setDataEdit({
                    id: data.id,
                    name: data.name,
                    name_ar: data.name_ar,
                    to_date: data.to_date,
                    from_date: data.from_date,
                    group_type: data.group_type,
                    extend_probation: data.extend_probation,
                  })
                  setOpenOthers(true)
                }
              }}
              handleOnChange={(status: boolean): void => {
                const dataCheckbox = {
                  status,
                  value: holiday,
                }
                onSubmitCheckbox(dataCheckbox)
              }}
            />
          ))}

          {holidays?.map((item) => {
            if (!defaultData.includes(item.name)) {
              return (
                <SwitchCard
                  key={item.id}
                  name={item.name_i18n}
                  isActive
                  handleOnEdit={(): void => {
                    setDataEdit(item)
                    setOpenOthers(true)
                  }}
                  handleOnChange={(): void => onSubmitCheckbox({ id: item.id })}
                />
              )
            }
            return null
          })}
        </Flex>
      </Flex>
      <Spacer height="8px" />
      <TipComponents tip={I18n.t('public_holidays_tip')} />
      <FormCustomLeave
        open={openOthers}
        onSubmit={onSubmit}
        fetching={fetching}
        onClose={setOpenOthers}
        initialValues={dataEdit}
        listDisabled={defaultData}
      />
    </StepTemplate>
  )
}

export default Holidays
