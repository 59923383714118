/** **************
 *  letterTypesActions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function letterTypesLoad() {
  const url = `/${API_VERSION_TWO}/letter_types?excluded_letter_types=Contract`
  const method = 'GET'
  return {
    type: 'LETTER_TYPES_LOAD',
    payload: handleAPI(url, { template: 'public' }, method),
  }
}
