import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ATSJobOpenStatusesProps = {
  filterKey: string
  filters: Array<string>
}

const ATSJobOpenStatuses = (props: ATSJobOpenStatusesProps): JSX.Element => {
  const { ats_job_opening_statuses } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(ats_job_opening_statuses)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        ats_job_opening_statuses: [],
      })
    } else {
      if (filter.ats_job_opening_statuses.includes('all')) {
        setFilter({
          ...filter.ats_job_opening_statuses.splice(
            filter.ats_job_opening_statuses.indexOf('all'),
            1,
          ),
        })
      }
      handleFilter('ats_job_opening_statuses', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.ats_job_opening_statuses)) {
      setSelectedIds(filter.ats_job_opening_statuses)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.ats_job_opening_statuses])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default ATSJobOpenStatuses
