import I18n from 'translations/i18n'
import Frame from 'containers/authorised/setting/v2/components/Frame'
import { Form } from 'react-final-form'
import { TextInputField, RadioBoxField } from 'components/final-form'
import { Spacer, Flex, Button, Typography } from '@jisr-hr/ds'
import { required } from 'components/global/form/FormValidations'
import { useDispatch, useSelector } from 'utils/hooks'
import { loanTypeCreate, loanTypeUpdate } from 'redux/payrollOnboarding/Loans/actionCreators'
import {
  handelValues,
  options,
  payTypeList,
} from 'containers/authorised/setting/v2/payroll/Payables/components/helper'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import PaymentMethods from './paymentMethod'

type PropType = {
  loansType: { id: number } | null
  onClose: () => void
}

type PayloadType = {
  default_pay_type: 'in_payrun' | 'out_of_payrun'
  in_payrun: boolean
  name: string
  name_ar: string
  status: string
}

type PayrollTransactionType = {
  category: string
  default_pay_type: 'in_payrun' | 'out_of_payrun'
  id: number
  name: string
  name_ar: string
  pay_type: string
  status: string
}

type ValuesType = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: { change: (name: string, value: string) => void }
  // eslint-disable-next-line react/no-unused-prop-types
  pristine: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  values: {
    default_pay_type: 'in_payrun' | 'out_of_payrun'
    id: number
    is_default: boolean
    name: string
  }
  // eslint-disable-next-line react/no-unused-prop-types
  handleSubmit: () => void
}

const LoanTypeForm = ({ onClose, loansType }: PropType): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.onboardingLoans?.loading)

  const onSubmit = (v: PayloadType): void => {
    const newValue = {
      ...v,
      category: 'LoanType',
    }
    const values = handelValues(newValue) as PayrollTransactionType
    if (values.id) {
      dispatch(loanTypeUpdate(values)).then(onClose)
    } else {
      dispatch(loanTypeCreate(values)).then(() => {
        onClose()
        dispatch(organizationInfo())
      })
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={loansType}
    >
      {({ handleSubmit, values, form: { change }, pristine }: ValuesType): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          style={{ height: '100%' }}
        >
          <Flex
            flexCol
            justifyBetween
            style={{ height: '100%' }}
          >
            <div>
              <Frame>
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name"
                  label={I18n.t('name_in_english')}
                  placeholder={I18n.t('name_in_english')}
                  validate={required}
                />
                <Spacer height={8} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name_ar"
                  placeholder={I18n.t('name_in_ar')}
                  label={I18n.t('name_in_ar')}
                  validate={required}
                  style={{ margin: 0 }}
                />
              </Frame>
              <Frame>
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('where_it_would_be_paid')}
                />
                <Spacer height={8} />
                {values?.is_default && values.name === 'Overtime' ? (
                  // @ts-expect-error Need to migrate RadioBoxField.js to TypeScript
                  <RadioBoxField
                    options={payTypeList}
                    labelKey="name"
                    valueKey="id"
                    name="pay_type"
                    variant="simple"
                  />
                ) : (
                  <PaymentMethods
                    initValue={values?.default_pay_type}
                    handleMethodChange={({
                      name,
                      value,
                    }: {
                      name: string
                      value: string
                    }): void => {
                      change(name, value)
                    }}
                    formValues={values}
                  />
                )}
                <Spacer height={8} />
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('status')}
                />
                <Spacer height={8} />
                <Flex style={{ gap: 16 }}>
                  {/* @ts-expect-error Need to migrate RadioBoxField.js to TypeScript */}
                  <RadioBoxField
                    options={options}
                    labelKey="name"
                    valueKey="id"
                    name="status"
                    variant="simple"
                    validate={required}
                    disabled={values.is_default}
                    data-testid="status"
                  />
                </Flex>
                <Spacer height={8} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="description"
                  as="textarea"
                  label={I18n.t('description')}
                  parse={(value: string): string => (value === '' ? '' : value)}
                  inputStyle={{ height: '88px' }}
                  disabled={values.is_default}
                  testId="description"
                  style={{ margin: 0 }}
                />
              </Frame>
            </div>
            <Flex
              style={{ paddingBottom: 24, gap: 8 }}
              justifyEnd
            >
              <Button
                label={I18n.t('cancel')}
                onClick={onClose}
                size="medium"
                variant="outlined"
                color="neutral"
              />
              <Button
                label={I18n.t(loansType?.id ? 'update' : 'save')}
                onClick={handleSubmit}
                disabled={pristine || loading}
                size="medium"
              />
            </Flex>
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default LoanTypeForm
