import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { TAction, TAutomationData } from 'containers/authorised/Automation/types'
import { ParamProps } from 'containers/authorised/Automations/Types'
import * as actions from './actions'

export function automationListLoad(params: ParamProps): Action {
  const url = `/${API_VERSION_TWO}/hr/automations`
  return {
    type: actions.AUTOMATION_LIST_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function automationCreate(automation: TAutomationData): Action {
  const url = `/${API_VERSION_TWO}/hr/automations`
  return {
    type: actions.AUTOMATION_CREATE,
    payload: handleAPI(url, {}, 'POST', { automation }),
  }
}

export function automationUpdate(id: number, automation: TAutomationData): Action {
  const url = `/${API_VERSION_TWO}/hr/automations/${id}`
  return {
    type: actions.AUTOMATION_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { automation }),
  }
}

export function automationDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/hr/automations/${id}`
  return {
    type: actions.AUTOMATION_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function automationShow(id: number): Action {
  const url = `/${API_VERSION_TWO}/hr/automations/${id}`
  return {
    type: actions.AUTOMATION_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function automationBulkUpsert(data: TAction[]): Action {
  const url = `/${API_VERSION_TWO}/hr/automation_actions/bulk_upsert`
  return {
    type: actions.AUTOMATION_BULK_UPSERT,
    payload: handleAPI(url, {}, 'PUT', { automation_action: data }),
  }
}

export function automationActionsVariables(trigger_type: string): Action {
  const url = `/${API_VERSION_TWO}/hr/automation_actions/variables`
  return {
    type: actions.AUTOMATION_ACTIONS_VARIABLES_LOAD,
    payload: handleAPI(url, { trigger_type }, 'GET'),
  }
}

export function automationPostAttachment({
  attachment,
}: {
  attachment: {
    data: string
    filename: string
  }
}) {
  const url = `/${API_VERSION_TWO}/common/attachments`
  return {
    type: actions.AUTOMATION_POST_ATTACHMENT,
    payload: handleAPI(url, {}, 'POST', { attachment }),
  }
}
