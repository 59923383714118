import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export const employmentTypesList = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/employment_types`

  return {
    type: actions.ONBOARDING_EMPLOYMENT_TYPES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function showEmploymentTypes(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employment_types`

  return {
    type: actions.ONBOARDING_EMPLOYMENT_TYPES_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function createNewEmploymentTypes(body = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employment_types`

  return {
    type: actions.ONBOARDING_EMPLOYMENT_TYPES_POST,
    payload: handleAPI(url, {}, 'POST', { employment_type: body }),
  }
}

export function updateNewEmploymentTypes(body = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employment_types`

  return {
    type: actions.ONBOARDING_EMPLOYMENT_TYPES_PUT,
    payload: handleAPI(url, {}, 'PUT', { employment_type: body }),
  }
}

export function destroyEmploymentTypes(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employment_types/${id}`

  return {
    type: actions.ONBOARDING_EMPLOYMENT_TYPES_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}
