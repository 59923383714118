import { useGetOpenJobs } from 'apis/ATS/jobs/useJob'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'translations/i18n'
import { biometrics, reporting_methods } from 'utils/uiHelpers'
import Staticfilters from './staticRecords'

const useFilterAPI = () => {
  const {
    area: areas,
    asset_type: assetTypes,
    business_unit: business_units,
    department: departments,
    document_type,
    employment_type: employment_types,
    grade: grades,
    job_title: job_titles,
    leave_type,
    loan_type: loan_types,
    location,
    mol_registration: mol_registrations,
    nationality,
    country,
    outsourcing_companies,
    payroll_transaction_type: out_of_payroll_types,
    shift: shifts,
    request_type: request_type_list,
    archived_request_type: archived_request_type_list,
    commercial_registration,
    organization_task,
    lookups,
    joining_dates,
    banks,
    paygroup_with_payrun,
    // payrolls: payroll_months,
    issuance_date,
    due_date,
    paygroups,
    group,
  } = useSelector(({ publicTemplates }) => publicTemplates)

  const { data } = useGetOpenJobs(
    {
      page: 1,
      per_page: 50,
      filter: { search: '' },
    },
    { query: { enabled: false } },
  )

  const openJobs = useMemo(() => {
    return data?.pages?.flatMap((p) => p?.data?.data) ?? []
  }, [data])

  const { reviewer_ids } = useSelector((s) => s.reviewCyclesV2EligibleEmployees)
  const { candidateFilter } = useSelector(({ jobApplicants }) => jobApplicants)
  const { employee_all } = useSelector(({ employee }) => employee)
  const timesheetReviewerEmployees = [
    { id: 'unassign', name: i18n.t('unassigned') },
    ...employee_all,
  ]

  const document_types = document_type?.concat(Staticfilters.employment_document_type)
  const leave_types = [...leave_type, ...Staticfilters.leave_types]
  const locations = location?.map((loc) => ({ ...loc, name_i18n: loc.address_i18n }))
  const orgTasks = !isEmpty(organization_task)
    ? organization_task.map((t) => ({ ...t, name: t.name_i18n }))
    : []

  return {
    areas,
    biometrics,
    business_units,
    departments,
    group,
    // dispatch,
    document_types,
    employment_types,
    // expiry_status: Staticfilters,
    // extra_cols: Staticfilters,
    // filters: Staticfilters,
    gosi_linked_data: Staticfilters.gosi_linked_data,
    member_linked_data: Staticfilters.member_linked_data,
    enrollment_eligibility_data: Staticfilters.enrollment_eligibility_data,
    grades,
    // isGosiEnabled: Staticfilters,
    job_titles,
    leave_statuses: Staticfilters.leave_statuses,
    leave_types,
    loan_types,
    locations,
    mol_registrations,
    nationalities: nationality,
    nationality,
    country,
    // onClearFilters,
    // onSubmitFilter,
    out_of_payroll_types,
    outsourcing_companies,
    payment_statuses: Staticfilters.payment_statuses,
    payment_methods: Staticfilters.payment_methods,
    payment_modes: Staticfilters.payment_modes,
    payment_processing_methods: Staticfilters.payment_processing_methods,
    payrun_types: Staticfilters.payrun_types,
    record_types: Staticfilters.record_types,
    reporting_method: reporting_methods,
    request_statuses: Staticfilters.request_statuses,
    ats_job_opening_statuses: Staticfilters.ats_job_opening_statuses,
    candidate_statuses: Staticfilters.candidate_statuses,
    candidate_resume: Staticfilters.candidate_resume,
    tasks_request_statuses: Staticfilters.tasks_request_statuses,
    request_type: Staticfilters.request_type,
    tasks_request_type: [...Staticfilters.tasks_request_type, ...orgTasks],
    // selected,
    shift_types: Staticfilters.shift_types,
    shifts,
    status_employee: Staticfilters.status_employee,
    shift_type: Staticfilters.shift_type,
    statuses: Staticfilters.statuses,
    task_statuses: Staticfilters.task_statuses,
    tasks_categories: Staticfilters.tasks_categories,
    payrun_status: Staticfilters.payrun_status,
    filter_payrun_status: Staticfilters.filter_payrun_status,
    filter_attendance_status: Staticfilters.filter_attendance_status,
    payroll_transaction_status: Staticfilters.payroll_transaction_status,
    // testId,
    uncorrect_data: Staticfilters.uncorrect_data,
    punch_in: Staticfilters.punch_in,
    punch_out: Staticfilters.punch_out,
    violation_status: Staticfilters.violation_status,
    violation_category: Staticfilters.violation_category,
    timesheet_settlement: Staticfilters.timesheet_settlement,
    timesheet_reviewer_ids: timesheetReviewerEmployees,
    settlement_employee_ids: employee_all,
    timesheet_status: Staticfilters.timesheet_status,
    assetStatuses: Staticfilters.assetStatuses,
    assetTypes,
    job_openings: openJobs,
    candidate_screening: candidateFilter.screening_questions,
    candidate_title: candidateFilter?.current_title,
    ats_recruiter: employee_all,
    ats_hiring_manager: employee_all,
    candidate_source: candidateFilter?.job_application_source,
    candidate_rating: candidateFilter?.rating,
    pending_on: Staticfilters.pending_on,
    tasks_pending_on: Staticfilters.tasks_pending_on,
    request_type_list,
    archived_request_type_list,
    commercial_registration,
    lookups,
    insurance_status: Staticfilters.insurance_statuses,
    device_status: Staticfilters.device_status,
    endorsement_member_type_data: Staticfilters.endorsement_member_type_data,
    action_type: Staticfilters.action_type,
    duration: Staticfilters.duration,
    invitations_statuses: Staticfilters.invitations_statuses,
    invoices_status: Staticfilters.invoices_status,
    payments: Staticfilters.payments,
    error_status: Staticfilters.error_status,
    contract_duration: Staticfilters.contract_duration,
    joining_dates,
    issuance_date,
    due_date,
    form_type: Staticfilters.form_type,
    form_status: Staticfilters.form_status,
    frequency: Staticfilters.frequency,
    reviewer_ids,
    event_type_history: Staticfilters.event_type_history,
    file_type_export_history: Staticfilters.file_type_export_history,
    status_export_history: Staticfilters.status_export_history,
    export_type_export_history: Staticfilters.export_type_export_history,
    banks,
    paygroup_with_payrun,
    // payroll_months,
    last_active_contract_type: Staticfilters.last_active_contract_type,
    paygroups,
    status_flight_booking: Staticfilters.status_flight_booking,
    transaction_types: Staticfilters.transaction_types,
    wallet_services_types: Staticfilters.wallet_services_types,
    wallet_transactions_statuses: Staticfilters.wallet_transactions_statuses,
  }
}

export default useFilterAPI
