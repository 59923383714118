import { ChangeEvent, useMemo, useState } from 'react'
import { useDispatch } from 'utils/hooks'
import { Grid } from 'components/global/atoms'
import { Spacer, Flex, Typography, Button } from '@jisr-hr/ds'
import { Toastr } from 'components/global/Toastr'
import {
  GENDER,
  MARITAL_STATUS,
  RELIGION,
  NOT_IN_SAUDI,
  CONTRACT_TYPES,
} from 'components/global/constants'

import I18n from 'translations/i18n'
import { Form, TextInputField, SelectField, DatePickerDSField } from 'components/final-form'
import {
  required,
  number,
  composeValidators,
  validateCode,
} from 'components/global/form/FormValidations'
import { normalizeDDMMYYYY } from 'components/global/form/formNormalize'

import {
  TEmployeeForm,
  TEmployeeFormOptional,
  employeesCreate,
  employeesLoad,
  employeesUpdate,
} from 'redux/organizationOnborading/employees/actionCreators'
import { EmploymentTypes } from 'redux/organizationOnborading/employmentTypes/employeeTypes.type'
import {
  TExtendsEmployee,
  TGrade,
  TOutsourcingCompany,
  TSharedObject,
} from 'redux/organizationOnborading/employees/employees.type'
import { TBusinessUnits } from 'redux/organizationOnborading/businessUnits/type'
import { TJobTitle } from 'redux/organizationOnborading/jobTitles/reducer'
import { Department } from 'redux/newDepartments/types'
import { LocationsType } from 'redux/organizationOnborading/locations/reducer'
import { TParams } from 'containers/public/Onboarding/types/onboardingTypes'
import { deepDiff } from 'components/global/HelperFunctions'
import { isArray } from 'lodash'

import { TAjeer } from '../../EmployeeData'

const colItem = {
  xs: 6,
  item: true,
}
type TContractPeriods = {
  id: string
  name: string
}

type TEmployeeData = {
  id?: number
  code?: string
  full_name_ar?: string
  full_name_en?: string
  joining_date?: string
  location_id?: number
  department_id?: number
  employment_type_id?: number
  employee_contracts_attributes?: {
    contract_period?: string
    contract_type?: string
    start_date?: string
    end_date?: string
    id?: number
  }[]
  employee_outsourcing_company_attributes?: {
    outsourcing_company_id?: number
  }
  identification_info_attributes?: {
    document_number?: string | null
    employer_number?: string | null
    iqama_expiry_date?: string | null
    iqama_expiry_date_ar?: string | null
    iqama_issue_date?: string | null
    nationality_id?: number | null
    not_in_saudi?: string | null
    passport_expiry_date?: string | null
    passport_number?: string | null
    religion?: string | null
  }
  gender?: string | null
  marital_status?: string | null
  date_of_birth?: string
  email?: string | null
  job_title_id: number | null
  line_manager_id: number | null
  business_unit_id: number | null
  grade_id?: number
  iqama_profession?: string | null
  iqama_profession_ar?: string | null
  telephone?: string | null
}

type TBody = {
  params: TParams
  line_manager: TExtendsEmployee[]
  openModal: (v: React.SetStateAction<Partial<TExtendsEmployee> | null>) => void
  locations: LocationsType[]
  departments: Department[]
  nationalities: TSharedObject[]
  jobTitles: TJobTitle[]
  outsourcingCompanies: TOutsourcingCompany[]
  businessUnits: TBusinessUnits[]
  employmentTypes: EmploymentTypes[]
  grades: TGrade[]
  fetching: boolean
  initialValues: TEmployeeData
}

type TChange = <F extends 'id' | keyof TEmployeeForm | 'employee_outsourcing_company_attributes'>(
  name: F,
  value?: TEmployeeFormOptional[F] | undefined,
) => void

const CONTRACT_PERIODS: TContractPeriods[] = [
  { id: '1 year', name: I18n.t('one_year') },
  { id: '2 years', name: I18n.t('two_years') },
  { id: 'Custom', name: I18n.t('custom') },
]

const Body = ({
  line_manager,
  openModal,
  locations,
  departments,
  nationalities,
  jobTitles,
  outsourcingCompanies,
  businessUnits,
  employmentTypes,
  // grades,
  fetching,
  initialValues: employeesValues,
  params,
}: TBody): JSX.Element => {
  const dispatch = useDispatch()
  const initialValues = useMemo(() => employeesValues, [])
  const isAjeer = (): boolean => {
    const ajeer = employmentTypes.find((element) => element.name === 'Ajeer')
    return initialValues.employment_type_id === ajeer?.id
  }
  const [optional, setOptional] = useState<boolean>(false)
  const [ajeer, setAjeer] = useState<boolean>(isAjeer())

  const onSubmit = (values: TEmployeeFormOptional): void => {
    const updateData = deepDiff(values, initialValues) as TEmployeeForm | TEmployeeFormOptional

    const employee_contracts_attributes = Object.values({
      ...values.employee_contracts_attributes,
    }) as TEmployeeForm['employee_contracts_attributes']

    const newData = {
      ...updateData,
      employee_contracts_attributes: employee_contracts_attributes.map((contract) => {
        if (contract.contract_type === 'Indefinite') {
          return {
            ...contract,
            contract_period: null,
          }
        }
        return contract
      }),
    } as TEmployeeForm | TEmployeeFormOptional

    if (values.id) {
      if (!updateData.employee_contracts_attributes) {
        delete newData.employee_contracts_attributes
      }
      dispatch(employeesUpdate(values.id, newData)).then(({ value }) => {
        Toastr.success(value.data.message)
        dispatch(employeesLoad(params))
        openModal(null)
      })
    } else {
      const dataWithoutId = {
        ...values,
        employee_contracts_attributes: [
          {
            contract_period: values.employee_contracts_attributes?.length
              ? values.employee_contracts_attributes[0].contract_period
              : '',
            contract_type: values.employee_contracts_attributes?.length
              ? values.employee_contracts_attributes[0].contract_type
              : '',
            start_date: values.employee_contracts_attributes?.length
              ? values.employee_contracts_attributes[0].start_date
              : '',
            end_date: values.employee_contracts_attributes?.length
              ? values.employee_contracts_attributes[0].end_date
              : '',
          },
        ],
      } as TEmployeeForm | TEmployeeFormOptional
      dispatch(employeesCreate(dataWithoutId)).then(({ value }) => {
        Toastr.success(value.data.message)
        dispatch(employeesLoad())
        openModal(null)
      })
    }
  }

  const checkAjeer = (e: TAjeer | undefined): void | boolean => {
    if (e?.name === 'Ajeer') return setAjeer(true)
    return setAjeer(false)
  }

  const contractPeriod = (
    e: string,
    values: TEmployeeForm | TEmployeeFormOptional,
    change: TChange,
  ): void => {
    const isCustom = e === 'Custom'
    change(
      'employee_contracts_attributes',
      values.employee_contracts_attributes?.length
        ? [
            {
              ...values.employee_contracts_attributes[0],
              start_date: isCustom ? values.joining_date : undefined,
              end_date: isCustom ? values.employee_contracts_attributes[0].end_date : undefined,
              contract_period: e,
            },
          ]
        : [
            {
              contract_period: e,
              id: 0,
              contract_type: values.employee_contracts_attributes?.length
                ? values.employee_contracts_attributes[0].contract_type
                : '',
            },
          ],
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues.id ? initialValues : {}}
      render={({ handleSubmit, form: { change }, values, pristine }): JSX.Element => (
        <div style={{ maxWidth: '700px', paddingBlock: 10 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={6}
              item
            >
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="code"
                label={I18n.t('emp_id')}
                autoFocus={!values.id}
                validate={composeValidators(required, validateCode)}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="full_name_en"
                label={I18n.t('name_in_english_onboarding')}
                validate={required}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="full_name_ar"
                label={I18n.t('name_in_arabic')}
                validate={required}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
              <TextInputField
                name="identification_info_attributes.document_number"
                label={I18n.t('id_iqama_border_number')}
                validate={composeValidators(required, number)}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              <div>
                <DatePickerDSField
                  name="joining_date"
                  label={I18n.t('joining_date')}
                  validate={required}
                  textFieldProps={{
                    placeholder: I18n.t('date_form_dd_mm_yyyy'),
                  }}
                  onChange={(date): void => {
                    change('joining_date', date as string)
                    if (
                      values.employee_contracts_attributes?.length &&
                      values.employee_contracts_attributes[0].contract_period === 'Custom'
                    ) {
                      change('employee_contracts_attributes', [
                        {
                          ...values.employee_contracts_attributes[0],
                          start_date: date as string,
                        },
                      ])
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                name="employment_type_id"
                valueKey="id"
                labelKey="name_i18n"
                options={employmentTypes}
                label={I18n.t('employment_type')}
                validate={required}
                onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                  checkAjeer(
                    employmentTypes.find((element) => element.id === (e as unknown as number)),
                  )
                }}
              />
            </Grid>
            {ajeer && (
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  name="employee_outsourcing_company_attributes.outsourcing_company_id"
                  options={outsourcingCompanies}
                  label={I18n.t('outsourcing_company')}
                  valueKey="id"
                  labelKey="name_i18n"
                  validate={required}
                />
              </Grid>
            )}
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                options={nationalities}
                name="identification_info_attributes.nationality_id"
                label={I18n.t('nationality')}
                valueKey="id"
                labelKey="name_i18n"
                validate={required}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                options={locations}
                name="location_id"
                label={I18n.t('location')}
                valueKey="id"
                labelKey="address_i18n"
                validate={required}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                options={departments}
                name="department_id"
                label={I18n.t('department')}
                valueKey="id"
                labelKey="name_i18n"
                validate={required}
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                options={CONTRACT_PERIODS}
                name="employee_contracts_attributes.[0].contract_period"
                label={I18n.t('contract_period')}
                valueKey="id"
                labelKey="name"
                onChange={(e: string): void => contractPeriod(e, values, change)}
                disabled={
                  initialValues.employee_contracts_attributes?.length &&
                  initialValues.employee_contracts_attributes[0].contract_period &&
                  initialValues.joining_date !== values.joining_date &&
                  values.id
                }
              />
            </Grid>
            <Grid
              xs={6}
              item
            >
              <SelectField
                // @ts-expect-error need to migrate SelectField to TS
                options={CONTRACT_TYPES}
                name="employee_contracts_attributes.[0].contract_type"
                label={I18n.t('contract_type')}
                valueKey="id"
                labelKey="name"
                validate={required}
                disabled={
                  initialValues.employee_contracts_attributes?.length &&
                  initialValues.employee_contracts_attributes[0].contract_type &&
                  initialValues.joining_date !== values.joining_date &&
                  values.id
                }
              />
            </Grid>
            {isArray(values.employee_contracts_attributes) &&
              values.employee_contracts_attributes[0].contract_period === 'Custom' && (
                <>
                  <Grid
                    xs={6}
                    item
                  >
                    <DatePickerDSField
                      name="employee_contracts_attributes.[0].start_date"
                      label={I18n.t('start_date')}
                      validate={required}
                      textFieldProps={{
                        placeholder: I18n.t('date_form_dd_mm_yyyy'),
                        disabled: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    item
                  >
                    <DatePickerDSField
                      name="employee_contracts_attributes.[0].end_date"
                      label={I18n.t('end_date')}
                      validate={required}
                      textFieldProps={{
                        placeholder: I18n.t('date_form_dd_mm_yyyy'),
                      }}
                    />
                  </Grid>
                </>
              )}
          </Grid>
          <Spacer height="25px" />
          <Flex justifyCenter>
            <Button
              label={I18n.t('optional_information')}
              variant="ghost"
              color="primary"
              size="small"
              onClick={(): void => setOptional(!optional)}
              trailingIcon={optional ? 'chevron-down' : 'chevron-up'}
            />
          </Flex>
          <Spacer height="25px" />
          {optional && (
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  name="gender"
                  valueKey="id"
                  labelKey="name"
                  options={GENDER}
                  label={I18n.t('gender')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  label={I18n.t('marital_status')}
                  name="marital_status"
                  valueKey="id"
                  labelKey="name"
                  options={MARITAL_STATUS}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  label={I18n.t('religion')}
                  name="identification_info_attributes.religion"
                  valueKey="id"
                  labelKey="name"
                  options={RELIGION}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('identification')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <DatePickerDSField
                  name="date_of_birth"
                  label={I18n.t('date_of_birth')}
                  textFieldProps={{
                    placeholder: I18n.t('date_form_dd_mm_yyyy'),
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  label={I18n.t('not_in_saudi')}
                  name="identification_info_attributes.not_in_saudi"
                  valueKey="id"
                  labelKey="name"
                  options={NOT_IN_SAUDI}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="email"
                  label={I18n.t('email')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="telephone"
                  label="Mobile No"
                  validate={number}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="identification_info_attributes.employer_number"
                  label={I18n.t('employer_number')}
                  validate={number}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('id_iqama_details')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <DatePickerDSField
                  label={I18n.t('iqama_issue_date')}
                  name="identification_info_attributes.iqama_issue_date"
                  textFieldProps={{
                    placeholder: I18n.t('date_form_dd_mm_yyyy'),
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <DatePickerDSField
                  label={I18n.t('id_expiry_date')}
                  name="identification_info_attributes.iqama_expiry_date"
                  textFieldProps={{
                    placeholder: I18n.t('date_form_dd_mm_yyyy'),
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  label={I18n.t('id_expiry_date_hijri')}
                  name="identification_info_attributes.iqama_expiry_date_ar"
                  placeholder={I18n.t('date_form_dd_mm_yyyy')}
                  parse={normalizeDDMMYYYY}
                />
              </Grid>
              <Grid
                {...colItem}
                xs={12}
              >
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('passport_information')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  label={I18n.t('passport_number')}
                  name="identification_info_attributes.passport_number"
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <DatePickerDSField
                  label={I18n.t('passport_expiry_date')}
                  name="identification_info_attributes.passport_expiry_date"
                  textFieldProps={{
                    placeholder: I18n.t('date_form_dd_mm_yyyy'),
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  options={jobTitles}
                  name="job_title_id"
                  label={I18n.t('job_title')}
                  valueKey="id"
                  labelKey="name_i18n"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('organization_details')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  name="line_manager_id"
                  options={line_manager}
                  label={I18n.t('line_manager')}
                  valueKey="id"
                  labelKey="full_name_en"
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  options={businessUnits}
                  name="business_unit_id"
                  label={I18n.t('business_unit')}
                  valueKey="id"
                  labelKey="name_i18n"
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  label={I18n.t('iqama_profession_en')}
                  name="iqama_profession"
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  label={I18n.t('iqama_profession_ar')}
                  name="iqama_profession_ar"
                />
              </Grid>
            </Grid>
          )}

          <Spacer height="25px" />
          <Flex justifyEnd>
            <Button
              label={I18n.t('save')}
              onClick={(): void => {
                handleSubmit()
              }}
              disabled={fetching || pristine}
              size="medium"
            />
          </Flex>
        </div>
      )}
    />
  )
}

export default Body
