import { FC, memo } from 'react'
import { Modal } from '@jisr-hr/ds'
import { ScrollBarContainer } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { ReactComponent as MudadImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/mudad.svg'
import { useDispatch } from 'utils/hooks'
import { resetMudadState } from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import { usePaymentMethod } from '../../components/usePaymentMethods'
import OwnerVerification from './OwnerVerification'
import Establishment from './Establishment'
import AuthorizePaymentAdmins from './AuthorizePaymentAdmins'
import VerifyPaymentAdmin from './VerifyPaymentAdmin'

type propsType = {
  afterSubmit?: () => void
}

const MudadPaymentMethod: FC<propsType> = (props) => {
  const dispatch = useDispatch()
  const paymentCtx = usePaymentMethod()
  const { methodModalOpen, selectedPaymentMethod, setupModalOpen, mudadCurrentStep } = paymentCtx

  return (
    <Modal
      open={methodModalOpen === 'mudad'}
      onClose={(): void => {
        // ? @note incase the setup modal open we don't need to reset the current payment
        const data = setupModalOpen ? selectedPaymentMethod : null
        paymentCtx.handleMethodModalOpen(data, null)
        paymentCtx.setCurrentSignatory(null)
        paymentCtx.setCurrentAuthorizer(null)
        dispatch(resetMudadState())
      }}
      size="x-large"
      header={{
        title: i18n.t('setup_mudad'),
        withClose: true,
        leadingSpace: { custom: <MudadImg style={{ width: 32, height: 32 }} /> },
      }}
    >
      <ScrollBarContainer maxHeight="80vh">
        {mudadCurrentStep === 1 && <Establishment />}
        {mudadCurrentStep === 2 && <OwnerVerification />}
        {mudadCurrentStep === 3 && <VerifyPaymentAdmin />}
        {mudadCurrentStep === 4 && <AuthorizePaymentAdmins afterSubmit={props.afterSubmit} />}
      </ScrollBarContainer>
    </Modal>
  )
}

export default memo(MudadPaymentMethod)
