import i18n from 'translations/i18n'

import FiltersDateRange from './FiltersDateRange'

const ReportingDate = () => {
  return (
    <FiltersDateRange
      title={i18n.t('create_label.tableheader.reportingdate')}
      filterKey="reporting_date"
    />
  )
}

export default ReportingDate
