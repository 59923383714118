const checkNotificationPromise = (): boolean => {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }
  return true
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const askNotificationPermission = (cb: { (): void }) => {
  const hasPermission = (): boolean => !['denied', 'default'].includes(Notification.permission)

  const onSuccess = (): void => {
    if (hasPermission()) cb()
  }
  if (!('Notification' in window)) {
    return false
  } else if (checkNotificationPromise()) {
    return Notification.requestPermission().then(onSuccess)
  }
  return Notification.requestPermission(onSuccess)
}
