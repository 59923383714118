import { useMemo } from 'react'
import { isTruthy } from 'utils/truthy'
import { Spacer, ProgressSteps } from '@jisr-hr/ds-beta'
import { Button, Containers, Flex, Typography } from '@jisr-hr/ds'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { Space } from 'components/global/atoms'
import { ONBOARDING_FORM_STEPS, OnboardingStepsEnum } from '../constants'

export const OnboardingFormHeader = ({
  activeStep,
  setActiveStep,
  handleBack,
}: {
  activeStep: OnboardingStepsEnum
  setActiveStep: (step: OnboardingStepsEnum) => void
  handleBack: () => void
}) => {
  const { nationality } = useSelector(({ employee }) => ({
    nationality: employee.employee.identification_info?.nationality || '',
  }))
  const isLastStep = activeStep === OnboardingStepsEnum.EMERGENCY_CONTACT
  const isSaudi = nationality === 'Saudi'

  const steps = useMemo(
    () =>
      ONBOARDING_FORM_STEPS.map((step) => {
        if (step.label === 'Home country address' && isSaudi) {
          return null
        }
        return step
      }),
    [isSaudi],
  ).filter(isTruthy)

  const mixPanelSteps = (stepIndex: number) => {
    mixPanelAnalytics({
      event_name: 'Employee Onboarding Form Step Started',
      properties: {
        step_name: `${ONBOARDING_FORM_STEPS[stepIndex].label}`,
      },
    })
  }
  return (
    <>
      <Space size={16} />
      <Typography
        text={i18n.t('add_information')}
        variant="title-1"
      />
      <Space size={16} />
      <Containers
        color="gray"
        borderRadius="md"
        className="px-4"
      >
        <Flex
          itemsCenter
          justifyEnd
        >
          <ProgressSteps
            activeStep={activeStep}
            onStepClick={(stepIndex) => {
              if (activeStep > stepIndex) {
                setActiveStep(stepIndex)
                mixPanelSteps(stepIndex)
              }
            }}
            type="numbers"
            orientation="horizontal"
            steps={steps}
          />
          <Spacer width="12vw" />
          <Flex
            itemsCenter
            justifyEnd
          >
            <Button
              size="medium"
              variant="outlined"
              color="neutral"
              onClick={() => {
                handleBack()
                mixPanelSteps(activeStep - 1)
              }}
              type="button"
              label={i18n.t('back')}
              leadingIcon="arrow-narrow-left"
              disabled={activeStep === 0}
            />
            <Spacer width={8} />
            <Button
              size="medium"
              type="submit"
              label={isLastStep ? i18n.t('submit') : i18n.t('next')}
              trailingIcon="arrow-narrow-right"
              onClick={() => {
                if (activeStep === OnboardingStepsEnum.EMERGENCY_CONTACT) {
                  mixPanelAnalytics({
                    event_name: 'Employee Onboarding Form Submitted',
                  })
                } else {
                  mixPanelSteps(activeStep)
                }
              }}
            />
          </Flex>
        </Flex>
      </Containers>
    </>
  )
}
