import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  missing_punch_request_workflows: [],
  missing_punch_request_workflow: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  is_applicable_to_all: false,
}

let changes = null
export default function missingPunchRequestWorkflowReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let missing_punch_request_workflows = []
  let newRecord = {}
  let updatedRecord = {}
  let is_applicable_to_all = ''
  switch (action.type) {
    case 'MISSING_PUNCH_REQUEST_WORKFLOWS_LOAD_PENDING':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.missing_punch_request_workflows.length > 0),
      }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        loading: true,
        missing_punch_request_workflow: {},
      }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_CREATE_PENDING':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: true,
      }

    case 'MISSING_PUNCH_REQUEST_WORKFLOWS_LOAD_REJECTED':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_CREATE_REJECTED':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_UPDATE_REJECTED':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_WORKFLOWS_LOAD_FULFILLED':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_LOAD_FULFILLED':
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      missing_punch_request_workflows = [...state.missing_punch_request_workflows]
      newRecord = payload.data.data.missing_punch_request_workflow
      changes = {
        loading: false,
        missing_punch_request_workflow: {},
        missing_punch_request_workflows: [...missing_punch_request_workflows, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      missing_punch_request_workflows = [...state.missing_punch_request_workflows]
      updatedRecord = payload.data.data.missing_punch_request_workflow
      if (updatedRecord.is_default) {
        is_applicable_to_all = updatedRecord.is_applicable_to_all || false
      }
      changes = {
        loading: false,
        missing_punch_request_workflows: updateOneListOfRecords(
          missing_punch_request_workflows,
          updatedRecord,
        ),
        is_applicable_to_all,
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_DELETE_FULFILLED':
      missing_punch_request_workflows = [...state.missing_punch_request_workflows]
      return {
        ...state,
        missing_punch_request_workflows: removeOneListOfRecords(
          missing_punch_request_workflows,
          payload.id,
        ),
        error: null,
      }

    case 'MISSING_PUNCH_REQUEST_WORKFLOW_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'MISSING_PUNCH_REQUEST_WORKFLOW_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'RESET':
      changes = {
        missing_punch_request_workflow: {},
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
