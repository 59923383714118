import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export const listOnboardingAdmins = (params = {}): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins`

  return {
    type: actions.ONBOARDING_ADMINS_LIST,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const inviteOnboardingAdmin = (email: string): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins/invite`

  return {
    type: actions.ONBOARDING_ADMINS_INVITE_POST,
    payload: handleAPI(url, {}, 'POST', { employee: { email } }),
  }
}

export const undoOnboardingAdmin = (email: string): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins/undo_invite`

  return {
    type: actions.ONBOARDING_ADMINS_UNDO_INVITE_PUT,
    payload: handleAPI(url, {}, 'PUT', { employee: { email } }),
  }
}

export const deleteOnboardingAdmin = (id: number): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins/${id}`

  return {
    type: actions.ONBOARDING_ADMINS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export const verifyOnboardingAdmins = (): Action => {
  const url = `/${API_VERSION_TWO}/onboarding_admins/completed_data`
  return {
    type: actions.ONBOARDING_VERIFY_ADMINS_LIST,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const completeVerifyOnboardingAdmins = (): Action => {
  return {
    type: actions.COMPLETE_ONBOARDING_VERIFY_ADMINS_LIST,
  }
}

export const deleteUserLocaly = (data = {}): Action => {
  return {
    type: actions.DELETE_USER_LOCALY,
    payload: data,
  }
}

export const changeAnyUserDeletedToFalse = (): Action => {
  return {
    type: actions.CHANGE_ANY_USER_DELETED_TO_FALSE,
  }
}
