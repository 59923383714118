import React from 'react'
import i18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'
import {
  Info,
  Tabs,
  Reason,
  Workflow,
  Comments,
  InfoChange,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'
import { ReactComponent as DocumentIcon } from '@jisr-hr/design-system/dist/assets/icons/Files/Documents/File/Text.svg'
import { isEmpty } from 'lodash'
import { useRequestDetail } from '../../hooks'

const DocumentRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const newChangeSet = request.changeset || {}
  const oldChangeSet = request.old_changeset || {}
  const [activeTab, setActiveTab] = React.useState('request_details')
  return (
    <div className="flex flex-col gap-4">
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              requested_by: request?.employee,
              status: request?.status,
              status_i18n: request?.status_i18n,
              type: request?.request_type,
              type_i18n: request?.request_name_i18n,
              created_at: request.created_at,
              created_by: request.created_by,
              requested_on: request.created_by,
              employee: request?.employee,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
              items: [
                {
                  icon: <DocumentIcon />,
                  label: i18n.t('file_name'),
                  value: (
                    <Typography
                      text={
                        <a
                          download
                          href={request?.file_url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {request?.document_file_type_name}
                        </a>
                      }
                      variant="body-new/regular"
                    />
                  ),
                },
              ],
            }}
          />
          {!isEmpty(newChangeSet) &&
            !isEmpty(oldChangeSet) &&
            ['id_iqama', 'address', 'passport'].includes(request.category) && (
              <InfoChange
                items={Object.entries(newChangeSet).map(([key, val]) => {
                  return {
                    label: i18n.t(key),
                    oldValue: oldChangeSet?.[key],
                    newValue: val,
                    infoChangeRequestType: request?.category,
                  }
                })}
              />
            )}
          {(request.reason || request.attachments?.length > 0) && (
            <Reason
              details={{
                reason: request.reason,
                attachments: request.attachments,
              }}
            />
          )}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}
      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </>
      )}
    </div>
  )
}

export default DocumentRequest
