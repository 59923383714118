import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import UndoItem from './Undo'
import styles from './Undo.module.css'

const UndoContainer = () => {
  const undoList = useSelector(({ undoAlerts }) => undoAlerts.undo_list)

  return (
    <div className={styles.undoContainer}>
      {undoList.map((undo) => (
        <UndoItem
          key={undo.id}
          {...undo}
        />
      ))}
    </div>
  )
}

UndoContainer.propTypes = {
  callback: PropTypes.func,
  onUndo: PropTypes.func,
  label: PropTypes.string,
  testId: PropTypes.string,
}

export default UndoContainer
