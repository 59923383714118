import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  job_titles: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function jobTitleReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let jobTitles = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'JOB_TITLES_LOAD_PENDING':
    case 'JOB_TITLE_CREATE_PENDING':
    case 'JOB_TITLE_UPDATE_PENDING':
    case 'JOB_TITLE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'JOB_TITLES_LOAD_REJECTED':
    case 'JOB_TITLE_CREATE_REJECTED':
    case 'JOB_TITLE_UPDATE_REJECTED':
    case 'JOB_TITLE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'JOB_TITLES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'JOB_TITLE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      jobTitles = [...state.job_titles]
      newRecord = payload.data.data.job_title
      changes = {
        job_titles: [...jobTitles, newRecord],
        fetching: false,
      }
      return { ...state, ...changes }

    case 'JOB_TITLE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      jobTitles = [...state.job_titles]
      updatedRecord = payload.data.data.job_title
      changes = {
        job_titles: updateOneListOfRecords(jobTitles, updatedRecord),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'JOB_TITLE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      jobTitles = [...state.job_titles]
      return {
        ...state,
        job_titles: removeOneListOfRecords(jobTitles, payload.id),
        error: null,
        fetching: false,
      }

    default:
      return state
  }
}
