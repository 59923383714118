import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TBusinessUnits, InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  business_units: [],
  tree: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  let treeData = []
  switch (action.type) {
    case actions.ONBOARDING_BUSINESS_UNITS_PUT_PENDING:
    case actions.ONBOARDING_BUSINESS_UNITS_LOAD_PENDING:
    case actions.ONBOARDING_BUSINESS_UNITS_SHOW_PENDING:
    case actions.ONBOARDING_BUSINESS_UNITS_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_BUSINESS_UNITS_POST_PENDING:
      state.tree.pop()
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_BUSINESS_UNITS_POST_FULFILLED:
    case actions.ONBOARDING_BUSINESS_UNITS_PUT_FULFILLED:
    case actions.ONBOARDING_BUSINESS_UNITS_SHOW_FULFILLED:
    case actions.ONBOARDING_BUSINESS_UNITS_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_BUSINESS_UNITS_LOAD_FULFILLED:
      treeData = action.payload.data.data.business_units.map((p: TBusinessUnits) => ({
        ...p,
        value: p.name,
      }))
      return {
        ...state,
        tree: [...treeData, { id: 'new', value: '' }],
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_BUSINESS_UNITS_POST_REJECTED:
    case actions.ONBOARDING_BUSINESS_UNITS_PUT_REJECTED:
    case actions.ONBOARDING_BUSINESS_UNITS_LOAD_REJECTED:
    case actions.ONBOARDING_BUSINESS_UNITS_SHOW_REJECTED:
    case actions.ONBOARDING_BUSINESS_UNITS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
