import React from 'react'
import PropTypes from 'prop-types'

const ScrollPagination = ({
  onScrollBottom,
  rpp,
  page,
  count,
  element, // e.target
}) => {
  const handlePagination = () => {
    if (count >= rpp) {
      onScrollBottom(page + 1)
    }
  }

  const onScroll = () => {
    const obj = element || document.querySelector('html')
    const bottom = obj.scrollHeight - obj.scrollTop - obj.clientHeight < 1

    if (bottom) {
      obj.scrollTop += 10
      handlePagination()
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return <div />
}

ScrollPagination.propTypes = {
  onScrollBottom: PropTypes.func,
  rpp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  element: PropTypes.node,
}

export default ScrollPagination
