import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
  loading: false,
  delegation: {},
  employeeDelegator: [],
}

export default function delegationRequestsReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.CREATE_DELEGATION_REQUEST_PENDING:
    case actions.UPDATE_DELEGATION_REQUEST_PENDING:
    case actions.DELEGATION_REQUEST_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DELEGATION_REQUEST_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        delegation: action.payload.data.data.delegation_request,
      }
    case actions.APPLICABLE_DELEGATORS_REQUEST_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.CREATE_DELEGATION_REQUEST_FULFILLED:
    case actions.UPDATE_DELEGATION_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    case actions.APPLICABLE_DELEGATORS_REQUEST_LOAD_FULFILLED:
      return {
        ...state,
        employeeDelegator: action.payload.data.data.employees,
        loading: false,
      }
    case actions.CREATE_DELEGATION_REQUEST_REJECTED:
    case actions.UPDATE_DELEGATION_REQUEST_REJECTED:
    case actions.APPLICABLE_DELEGATORS_REQUEST_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
