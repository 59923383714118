import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { HTTPValidationError } from 'types/httpTypes'
import {
  TParamsTemplateDefaultName,
  TTemplateDefaultNameAxiosResponse,
} from './useDefaultName.type'

// ? query keys
const defaultNameKey = (template_id: number): QueryKey => ['automations_default_name', template_id]

// ? functions
export const getTemplateDefaultName = (
  params: TParamsTemplateDefaultName,
): Promise<TTemplateDefaultNameAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/automations/default_name`, { params })
}

// ? hooks
/**
 * @endpoint: GET /v2/hr/automations/default_name
 * @summary This hook used to get the automations default name for template
 */
export const useTemplateDefaultName = <
  TError extends HTTPValidationError,
  TData = TTemplateDefaultNameAxiosResponse,
>(
  params: TParamsTemplateDefaultName,
  opts?: {
    query?: UseQueryOptions<TTemplateDefaultNameAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: defaultNameKey(params.template_id),
    queryFn: () => getTemplateDefaultName(params),
    onError: (err) => Toastr.error(err?.response?.data.error),
  })
}
