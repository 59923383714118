export const SUBORDINATES_LOAD = 'SUBORDINATES_LOAD'
export const SUBORDINATES_LOAD_PENDING = 'SUBORDINATES_LOAD_PENDING'
export const SUBORDINATES_LOAD_FULFILLED = 'SUBORDINATES_LOAD_FULFILLED'
export const SUBORDINATES_LOAD_REJECTED = 'SUBORDINATES_LOAD_REJECTED'

export const SUBORDINATES_DELETE = 'SUBORDINATES_DELETE'
export const SUBORDINATES_DELETE_FULFILLED = 'SUBORDINATES_DELETE_FULFILLED'
export const SUBORDINATES_DELETE_REJECTED = 'SUBORDINATES_DELETE_REJECTED'

export const SUBORDINATES_CHANGE = 'SUBORDINATES_CHANGE'
export const SUBORDINATES_CHANGE_FULFILLED = 'SUBORDINATES_CHANGE_FULFILLED'
export const SUBORDINATES_CHANGE_REJECTED = 'SUBORDINATES_CHANGE_REJECTED'

export const LOAD_EMPLOYEE_TEAM_MEMBERS = 'LOAD_EMPLOYEE_TEAM_MEMBERS'
export const LOAD_EMPLOYEE_TEAM_MEMBERS_PENDING = 'LOAD_EMPLOYEE_TEAM_MEMBERS_PENDING'
export const LOAD_EMPLOYEE_TEAM_MEMBERS_FULFILLED = 'LOAD_EMPLOYEE_TEAM_MEMBERS_FULFILLED'
export const LOAD_EMPLOYEE_TEAM_MEMBERS_REJECTED = 'LOAD_EMPLOYEE_TEAM_MEMBERS_REJECTED'
