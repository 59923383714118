import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as schedulerViewActions from './schedulerView/actions'
import * as clearSchedulerActions from './clearScheduler/actions'
import * as publishAndUnpublishActions from './publishAndUnpublish/actions'
import * as assignEmployeesActions from './assignEmployees/actions'
import {
  SchedulerState,
  LastImportDetailTypes,
  AssignShiftStateT,
  TFilterEmployeesArgs,
} from './schedulerSharedTypes'
import * as schedulerBulkImportActions from './schedulerBulkImport/actions'

const shiftState = {
  frozen: 0,
  conflict: 0,
  published: 0,
  unpublished: 0,
  publishable_shifts: 0,
  unpublishable_shifts: 0,
  published_day_off_count: 0,
  unpublished_day_off_count: 0,
  employees: 0,
  published_hours: '',
  unpublished_hours: '',
  frozen_employee_ids: [],
}

const initialState: SchedulerState = {
  fetching: false,
  lastImportFetching: false,
  downloadTemplateFetching: false,
  loading: false,
  shiftLoading: false,
  include_locked_days: false,
  fetchingBrief: false,
  assignTemplateFetching: false,
  assignStatsFetching: false,
  employees: [],
  employee_ids: [],
  reviewed_attendance_employee_ids: [],
  briefEmployees: [],
  shiftState,
  pagination: {},
  lastImportDetail: {},
  assignShiftStats: null,
  total_conflict: 0,
  total_unavailable: 0,
  total_unpublished: 0,
  total_unscheduled: 0,
  group_by: {
    loading: false,
    list: [],
  },
}

export default function reducer(state = initialState, action: Action): SchedulerState {
  switch (action.type) {
    case schedulerViewActions.RESET_EMPLOYEES:
      return {
        ...state,
        employees: [],
      }

    case publishAndUnpublishActions.RESET_SHIFT_STATE:
      return {
        ...state,
        shiftState,
      }

    case assignEmployeesActions.RESET_ASSIGN_SHIFT_STATE:
      return {
        ...state,
        assignShiftStats: null,
      }

    case assignEmployeesActions.RESET_CONFLICT_EMPLOYEES:
      return {
        ...state,
        employee_ids: [],
        reviewed_attendance_employee_ids: [],
      }

    case schedulerViewActions.FILTER_GROUP_EMPLOYEES:
      const { groupId, groupType }: TFilterEmployeesArgs = action.payload

      return {
        ...state,
        employees: state.employees.filter(
          (emp) =>
            emp.employee[groupType === 'location' ? 'location_id' : 'department_id'] !== groupId,
        ),
      }

    case schedulerViewActions.SCHEDULER_VIEW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case schedulerBulkImportActions.DOWNLOAD_SCHEDULING_TEMPLATE_PENDING:
      return {
        ...state,
        downloadTemplateFetching: true,
      }

    case schedulerBulkImportActions.SCHEDULER_LAST_IMPORT_PENDING:
    case schedulerBulkImportActions.MARK_IMPORT_COMPLETE_PENDING:
      return {
        ...state,
        lastImportFetching: true,
      }

    case assignEmployeesActions.BRIEF_LIST_EMPLOYEES_PENDING:
    case assignEmployeesActions.CONFLICT_EMPLOYEES_PENDING:
      return {
        ...state,
        fetchingBrief: true,
      }

    case assignEmployeesActions.ASSIGN_TEMPLATE_PENDING:
      return {
        ...state,
        assignTemplateFetching: true,
      }

    case assignEmployeesActions.ASSIGN_SHIFT_STATE_PENDING:
      return {
        ...state,
        assignStatsFetching: true,
      }

    case publishAndUnpublishActions.PUBLISH_SINGLE_SHIFT_PENDING:
    case publishAndUnpublishActions.PUBLISH_MULTI_SHIFT_PENDING:
    case publishAndUnpublishActions.UNPUBLISH_SINGLE_SHIFT_PENDING:
    case publishAndUnpublishActions.UNPUBLISH_MULTI_SHIFT_PENDING:
    case clearSchedulerActions.CLEAR_SCHEDULERS_PENDING:
    case clearSchedulerActions.DELETE_EMPLOYEE_SHIFT_PENDING:
      return {
        ...state,
        shiftLoading: true,
      }

    case publishAndUnpublishActions.EMPLOYEE_SHIFT_STATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case schedulerViewActions.SCHEDULER_GROUP_BY_PENDING:
      return {
        ...state,
        fetching: true,
        group_by: {
          ...state.group_by,
          loading: true,
        },
      }

    case schedulerBulkImportActions.DOWNLOAD_SCHEDULING_TEMPLATE_FULFILLED:
      return {
        ...state,
        downloadTemplateFetching: false,
      }

    case assignEmployeesActions.ASSIGN_SHIFT_STATE_FULFILLED:
      const assignShiftStats: AssignShiftStateT = action.payload.data.data

      return {
        ...state,
        assignShiftStats,
        assignStatsFetching: false,
      }

    case assignEmployeesActions.BRIEF_LIST_EMPLOYEES_FULFILLED:
      return {
        ...state,
        fetchingBrief: false,
        briefEmployees: [...action.payload.data.data],
      }

    case assignEmployeesActions.CONFLICT_EMPLOYEES_FULFILLED:
      return {
        ...state,
        fetchingBrief: false,
        ...action.payload.data.data.data,
      }

    case schedulerBulkImportActions.MARK_IMPORT_COMPLETE_FULFILLED:
      return {
        ...state,
        lastImportFetching: false,
      }

    case schedulerBulkImportActions.SCHEDULER_LAST_IMPORT_FULFILLED:
      const lastImportDetail: LastImportDetailTypes = action.payload.data.data.bulk_import || {}
      return {
        ...state,
        lastImportFetching: false,
        lastImportDetail,
      }

    case publishAndUnpublishActions.EMPLOYEE_SHIFT_STATE_FULFILLED:
      return {
        ...state,
        shiftState: { ...action.payload.data.data.data },
        loading: false,
      }

    case schedulerViewActions.SCHEDULER_VIEW_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data.data,
        employees: [...state.employees, ...action.payload.data.data.data.employees],
        pagination: action.payload.data.data.pagination,
        fetching: false,
      }

    case clearSchedulerActions.CLEAR_SCHEDULERS_FULFILLED:
    case clearSchedulerActions.DELETE_EMPLOYEE_SHIFT_FULFILLED:
    case publishAndUnpublishActions.PUBLISH_SINGLE_SHIFT_FULFILLED:
    case publishAndUnpublishActions.PUBLISH_MULTI_SHIFT_FULFILLED:
    case publishAndUnpublishActions.UNPUBLISH_SINGLE_SHIFT_FULFILLED:
    case publishAndUnpublishActions.UNPUBLISH_MULTI_SHIFT_FULFILLED:
    case assignEmployeesActions.ASSIGN_TEMPLATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        loading: false,
        shiftLoading: false,
        assignTemplateFetching: false,
      }

    case schedulerViewActions.SCHEDULER_GROUP_BY_FULFILLED:
      return {
        ...state,
        fetching: false,
        group_by: {
          ...state.group_by,
          loading: false,
          list: action.payload.data.data.data,
        },
      }

    case schedulerBulkImportActions.MARK_IMPORT_COMPLETE_REJECTED:
    case schedulerBulkImportActions.SCHEDULER_LAST_IMPORT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        lastImportFetching: false,
      }

    case assignEmployeesActions.ASSIGN_SHIFT_STATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        assignStatsFetching: false,
      }

    case schedulerViewActions.SCHEDULER_VIEW_REJECTED:
    case clearSchedulerActions.CLEAR_SCHEDULERS_REJECTED:
    case clearSchedulerActions.DELETE_EMPLOYEE_SHIFT_REJECTED:
    case publishAndUnpublishActions.PUBLISH_SINGLE_SHIFT_REJECTED:
    case publishAndUnpublishActions.UNPUBLISH_SINGLE_SHIFT_REJECTED:
    case publishAndUnpublishActions.PUBLISH_MULTI_SHIFT_REJECTED:
    case publishAndUnpublishActions.UNPUBLISH_MULTI_SHIFT_REJECTED:
    case publishAndUnpublishActions.EMPLOYEE_SHIFT_STATE_REJECTED:
    case schedulerViewActions.SCHEDULER_GROUP_BY_REJECTED:
    case assignEmployeesActions.BRIEF_LIST_EMPLOYEES_REJECTED:
    case assignEmployeesActions.ASSIGN_TEMPLATE_REJECTED:
    case assignEmployeesActions.CONFLICT_EMPLOYEES_REJECTED:
    case schedulerBulkImportActions.DOWNLOAD_SCHEDULING_TEMPLATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        downloadTemplateFetching: false,
        fetching: false,
        loading: false,
        shiftLoading: false,
        fetchingBrief: false,
        assignTemplateFetching: false,
        group_by: {
          ...state.group_by,
          loading: false,
        },
      }

    default:
      return state
  }
}
