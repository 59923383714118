import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  letter_types: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function letterTypesReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'LETTER_TYPES_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'LETTER_TYPES_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LETTER_TYPES_LOAD_FULFILLED':
      changes = {
        letter_types: payload.data.data.letter_types,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
