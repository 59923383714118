import { useState, MouseEvent } from 'react'
import { DatePicker } from '@jisr-hr/ds-beta'
import { Divider, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

type IssuanceDateT = {
  filterKey: string
  filters: string[]
  title: string
}

const IssuanceDate = (props: IssuanceDateT): JSX.Element => {
  const { filters, title, filterKey } = props
  const { filter, setFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onChange = (val: string, field: string): void => {
    setFilter({
      ...filter,
      issuance_date: {
        ...filter?.issuance_date,
        [field]: val,
      },
    })
  }

  const handleClear = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setFilter({
      ...filter,
      issuance_date: undefined,
    })
  }

  return (
    <div>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={filter?.issuance_date?.from || filter?.issuance_date?.to}
          handleClearFilter={handleClear}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title="create_label.issuance_date"
        />
        {!isCollapsed && (
          <Flex
            style={{
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DatePicker
              textFieldProps={{
                testId: 'from-date',
                showClearIcon: false,
              }}
              label={i18n.t('from')}
              triggerType="input"
              value={
                filter?.issuance_date?.from ? new Date(filter?.issuance_date?.from) : undefined
              }
              onChange={(val: string | string[]): void => onChange(val as string, 'from')}
              calenderProps={{
                type: 'single',
                maxDate: new Date(),
              }}
            />
            <DatePicker
              label={i18n.t('to')}
              textFieldProps={{
                showClearIcon: false,
                testId: 'to-date',
              }}
              triggerType="input"
              value={filter?.issuance_date?.to ? new Date(filter?.issuance_date?.to) : undefined}
              onChange={(val: string | string[]): void => onChange(val as string, 'to')}
              calenderProps={{
                type: 'single',
                minDate: filter?.issuance_date?.from
                  ? new Date(filter?.issuance_date?.from)
                  : new Date(),
              }}
            />
          </Flex>
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

export default IssuanceDate
