const Assets = {
  // A
  add_asset: 'Add Asset',
  all_images_and_pdf: 'SVG, PNG, JPG, GIF or PDF',
  allocate: 'Allocate',
  allocate_asset: 'Allocate Asset',
  asset_added_successfully: 'Asset added successfully',
  asset_allocation_task: 'Asset allocation task',
  asset_deallocation_task: 'Asset deallocation task',
  allocation_date: 'Allocation Date',
  allocation_details: 'Allocation Details',
  allocation_task_disclaimer:
    'Before clicking on " Mark as Completed ", You need to ensure that all required assets are allocated to the employee; either through the employee profile under "Assets" tab OR Assets page so that it would reflect on the employees\' profile.',
  archive: 'Archive',
  archived: 'Archived',
  available_assets: 'Available Assets',
  // B
  brand: 'Brand',
  bulk_import_for_assets: 'Bulk Import for Assets',
  // C
  change_the_status_to_be_available_to_be_able_to_allocate:
    'Asset marked as In-Repair, has to be available to allocate to an employee',
  // D
  damaged: 'Damaged',
  deallocate: 'Deallocate',
  deallocate_asset: 'Deallocate Asset',
  deallocation_date: 'Deallocation Date',
  deallocation_details: 'Deallocation Details',
  deallocation_task_disclaimer:
    'Before clicking on " Mark as Completed ", You need to ensure that all required assets are deallocated from the employee; either through the employee profile under "Assets" tab OR Assets page so that it would reflect on the employees\' profile.',
  delete_allocated_asset_desc:
    'Asset is allocated to employee, Deallocate it first to be able to delete it.',
  delete_asset: 'Delete Asset',
  delete_asset_desc:
    'By deleting this asset [{{asset}}] you will lose all the history logs  of the asset and will not be able to retrieve this asset to be used again.',
  // E
  // F
  // G
  go_to_assets: 'Go to Assets',
  // H
  history_logs: 'History Logs',
  // I
  inrepair: 'In-Repair',
  // J
  // K
  // L
  // M
  mark_as_available: 'Mark As Available',
  mark_as_damaged: 'Mark As Damaged',
  mark_as_inrepair: 'Mark As In-Repair',
  model_name_ar: 'Model (Name in Arabic)',
  model_name_en: 'Model (Name in English)',
  // N
  new_asset: 'New Asset',
  no_assets_available: 'No assets from this asset type <b>({{name}})</b> is available',
  not_now_later: 'Not Now, Later',
  // O
  // P
  purchase_cost: 'Purchase Cost',
  purchase_date: 'Purchase Date',
  purchase_invoice_or_any_other_relevant_document:
    'Purchase invoice or any other relevant document',
  // Q
  // R
  // S
  // T
  // U
  unallocation_date: 'Unallocation Date',
  // V
  view_asset: 'View Asset',
  // W
  warranty_end_date: 'Warranty End Date',
  would_you_like_to_allocate_this_asset_to_an_employee:
    'Would you like to allocate this asset to an employee ?',
  // X
  // Y
  you_dont_have_assets: 'You don’t have assets',

  // Z
}

export default Assets
