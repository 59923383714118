import { Avatars, Containers, Flex, Spacer, Typography } from '@jisr-hr/ds'
import { format } from 'utils/date'
import i18n from 'translations/i18n'
import styles from './Request.module.css'

type RejectReasonProps = {
  task: {
    rejection_reason: string
    assignee: {
      avatar_thumb: string | undefined
      full_name_i18n: string
    }
    updated_at: string
  }
}

const RejectReason = (props: RejectReasonProps): JSX.Element => {
  const { task } = props
  return (
    <Containers
      borderRadius="sm"
      color="error"
      border
      zIndex={1}
      className="p-4"
    >
      <Flex
        flexCol
        className="gap-4"
      >
        <div>
          <Typography
            text={i18n.t('label.rejection_reason')}
            textColor="color/fg/accent/red/bold"
            variant="subheading"
          />
          <Typography
            text={task.rejection_reason}
            textColor="color/fg/default"
            variant="body-new/medium"
          />
        </div>
        <div className={`pt-2.5 ${styles.borderAccentRedLight}`}>
          <Flex
            itemsCenter
            justifyBetween
          >
            <div className="flex items-center gap-1 py-1.5">
              <Avatars
                size="m"
                showAvatarStatus={false}
                imageSrc={task?.assignee?.avatar_thumb}
                imageAlt={task?.assignee?.full_name_i18n}
              />
              <Typography
                text={task?.assignee?.full_name_i18n}
                textColor="color/fg/light"
                variant="interface/strong/sm"
              />
            </div>
            <Spacer />
            <Typography
              text={format(task?.updated_at, 'dd MMM, yyyy')}
              textColor="color/fg/default"
              variant="body-new/medium"
            />
          </Flex>
        </div>
      </Flex>
    </Containers>
  )
}

export default RejectReason
