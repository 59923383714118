import { Component, ErrorInfo, ReactNode } from 'react'
import Rollbar, { LogArgument } from 'rollbar'
import ErrorPage from 'components/global/ErrorPage'
import { ReactComponent as JisrError500 } from 'assets/images/Jisr500.svg'

const WEB_WIDGET_OPEN_ERROR_MESSAGE = 'webWidget:on.open does not exist'

type RollbarProps = LogArgument & {
  message?: string
  response?: string
}

const isWebWidgetOpenError = (errorMessage: RollbarProps): boolean => {
  return errorMessage?.[1]?.message?.includes(WEB_WIDGET_OPEN_ERROR_MESSAGE)
}

export const rollBar = new Rollbar({
  accessToken: import.meta.env.UkVBQ1RfQVBQX1JPTExCQVJfVE9LRU4,
  captureUncaught: true,
  enabled: import.meta.env.MODE !== 'development',
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.UkVBQ1RfQVBQX1JPTExCQVJfRU5W,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version:
          import.meta.env.UkVBQ1RfQVBQX1NFTUFQSE9SRV9HSVRfVEFHX05BTUU ||
          import.meta.env.UkVBQ1RfQVBQX0dJVF9UQUc,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: (isUncaught, args?: RollbarProps): boolean => {
    // ignore 404 error for gl_onboarding_status
    if (typeof args === 'object' && Array.isArray(args)) {
      if (
        args?.[1]?.config?.url?.includes('/gl_onboarding_status') &&
        args?.[1]?.response?.status === 404
      ) {
        return true
      }
    }
    if (typeof args === 'object' && Array.isArray(args)) {
      if (args?.[1]?.response || args?.[1]?.message === 'Network Error') {
        return true
      }
      if (isWebWidgetOpenError(args)) {
        return true
      }
    }
    // checkIgnore if its false will report Rollbar log
    return false
  },
})

type ErrorBoundaryProps = {
  children?: ReactNode
  content?: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state = { hasError: false }

  componentDidCatch = (error: Error, info: ErrorInfo): void => {
    // You can also log the error to rollbaran error reporting service
    rollBar.error(error, info)
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.content ?? (
          <ErrorPage
            errorCodeImage={<JisrError500 />}
            title="500_title"
            subTitle="500_description"
            isBoundary
          />
        )
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
