export const SCHEDULER_VIEW = 'SCHEDULER_VIEW'
export const SCHEDULER_VIEW_PENDING = 'SCHEDULER_VIEW_PENDING'
export const SCHEDULER_VIEW_FULFILLED = 'SCHEDULER_VIEW_FULFILLED'
export const SCHEDULER_VIEW_REJECTED = 'SCHEDULER_VIEW_REJECTED'

export const SCHEDULER_GROUP_BY = 'SCHEDULER_GROUP_BY'
export const SCHEDULER_GROUP_BY_PENDING = 'SCHEDULER_GROUP_BY_PENDING'
export const SCHEDULER_GROUP_BY_FULFILLED = 'SCHEDULER_GROUP_BY_FULFILLED'
export const SCHEDULER_GROUP_BY_REJECTED = 'SCHEDULER_GROUP_BY_REJECTED'

export const RESET_EMPLOYEES = 'RESET_EMPLOYEES'

export const FILTER_GROUP_EMPLOYEES = 'FILTER_GROUP_EMPLOYEES'
