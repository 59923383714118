import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import { Typography, Flex, Spacer, Badge } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Loader from './Loader'
import ExemptedEmployee from '../ExemptedEmployee'

const EmployeeInfo = ({
  employee = {},
  testId,
  fetching,
  editAvatar,
  exemption_policy_message,
}) => (
  <Flex
    justifyStart
    className="employee-info"
  >
    {fetching ? (
      <Loader />
    ) : (
      <Fragment>
        {editAvatar || <Avatar src={employee.avatar} />}
        <div>
          <Flex
            itemsCenter
            style={{ gap: 8 }}
          >
            <Typography
              text={employee.name_i18n}
              variant="heading"
              testId={`${testId}-title`}
            />
            {employee.role && (
              <Badge
                label={employee.role}
                size="small"
                color="primary"
                variant="tinted"
              />
            )}
            {employee.employment_type && (
              <Badge
                label={employee.employment_type}
                size="small"
                color="gray"
                variant="tinted"
              />
            )}
            {employee.contract_type && (
              <Badge
                label={employee.contract_type}
                size="small"
                color="gray"
                variant="tinted"
              />
            )}
          </Flex>
          <Spacer height={8} />
          <Typography
            text={employee.code}
            variant="body-new/regular"
          />
          {employee.job_title && (
            <Typography
              text={employee.job_title}
              variant="body-new/regular"
            />
          )}
          {employee.working_experience_period && (
            <Typography
              text={`${I18n.t('working_experience')} :
              ${employee.working_experience_period}`}
              variant="body-new/regular"
            />
          )}
          {employee.last_active_time && (
            <Typography
              text={I18n.t('last_active_time', {
                time: employee.last_active_time,
              })}
              variant="body-new/regular"
            />
          )}

          {exemption_policy_message && (
            <Typography
              text={exemption_policy_message}
              variant="body-new/regular"
            />
          )}
          <ExemptedEmployee
            fullShow
            isExempted={employee?.isExempted}
          />
        </div>
      </Fragment>
    )}
  </Flex>
)

EmployeeInfo.propTypes = {
  employee: PropTypes.shape(),
  fetching: PropTypes.bool,
  editAvatar: PropTypes.node,
  testId: PropTypes.string,
  exemption_policy_message: PropTypes.string,
}

export default React.memo(EmployeeInfo)
