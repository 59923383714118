import { Typography, Grid } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { BetaTextInputField as TextInputField } from 'components/final-form'

export const HomeCountyAddress = () => {
  const gridItemProps = {
    item: true,
    columnSpacing: 3,
  }
  const addressKeys = 'address_in_home'

  return (
    <div className="flex flex-col gap-8">
      <Typography
        text={i18n.t('label.address_in_home_country')}
        variant="title-1"
      />
      <Grid
        direction="row"
        container
        rowSpacing={20}
        spacing={3}
      >
        <Grid {...gridItemProps}>
          <TextInputField
            testId="address-field"
            name={`${addressKeys}.home_address1`}
            label={i18n.t('address')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="town-city-field"
            name={`${addressKeys}.home_city`}
            label={i18n.t('town_city')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="country-field"
            name={`${addressKeys}.home_country`}
            label={i18n.t('country')}
          />
        </Grid>
      </Grid>
    </div>
  )
}
