import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  subsidiaries: [],
  subsidiary: {},
  notification: [],
  employees_data: {},
  notification_loading: false,
  init_subsidiary_loading: true,
  fetching: false,
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET_SUBSIDIARY_DATA:
      return {
        ...state,
        subsidiary: {},
      }

    case actions.SUBSIDIARIES_LOAD_PENDING:
    case actions.SUBSIDIARY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        init_subsidiary_loading: true,
      }

    case actions.SUBSIDIARY_DELETE_PENDING:
    case actions.SUBSIDIARY_UPDATE_PENDING:
    case actions.SUBSIDIARY_CREATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.SUBSIDIARY_DELETE_FULFILLED:
    case actions.SUBSIDIARY_UPDATE_FULFILLED:
    case actions.SUBSIDIARY_CREATE_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        loading: false,
      }

    case actions.SUBSIDIARY_UPDATE_REJECTED:
    case actions.SUBSIDIARY_DELETE_REJECTED:
    case actions.SUBSIDIARY_CREATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    case actions.SUBSIDIARIES_LOAD_REJECTED:
    case actions.SUBSIDIARY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        init_subsidiary_loading: false,
      }

    case actions.SUBSIDIARIES_LOAD_FULFILLED:
      return {
        ...state,
        subsidiaries: action.payload.data.data?.subsidiaries,
        fetching: false,
        init_subsidiary_loading: false,
      }

    case actions.SUBSIDIARY_LOAD_FULFILLED:
      return {
        ...state,
        subsidiary: action.payload.data.data?.subsidiary,
        fetching: false,
      }

    // ? notification
    case actions.SUBSIDIARY_NOTIFICATION_LOAD_PENDING:
      return {
        ...state,
        notification_loading: true,
      }

    case actions.SUBSIDIARY_NOTIFICATION_LOAD_FULFILLED:
      return {
        ...state,
        notification_loading: false,
        notification: action.payload.data.data,
      }

    case actions.SUBSIDIARY_NOTIFICATION_LOAD_REJECTED:
      return {
        ...state,
        notification_loading: false,
      }

    // ? assign employees
    case actions.SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED:
      return {
        ...state,
        loading: false,
      }
    case actions.SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
