import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type AuthorizedEmployee = {
  avatar_thumb: string
  code: string
  full_name_i18n: string
  id: number
  is_deleted: boolean
  is_owner: boolean
  name_i18n: string
}

type MolRegistration = {
  id: number
  gosi_name_en?: string
  gosi_name_ar?: string
  name: string
  gosi_number: string
  mol_name_en?: string
  mol_name_ar?: string
  mol_prefix: string
  mol_number: string
  mol_registration_number: string
}

export type BankAccountType = {
  id: number
  iban: string
  bank_id: number
  bank_name: string
  bank_logo: string
  authorization_status: 'authorized' | 'not_authorized' | ''
  is_active: boolean
  mudad_supported: boolean
  authorized_employees?: AuthorizedEmployee[]
  is_direct_bank_integrated: boolean
}

export type PaymentConfigurationType = {
  id: number
  name_en?: string
  name_ar?: string
  name_i18n?: string
  name: string
  active_payment_method: 'mudad' | 'offline' | null
  registration_number: string
  registration_id: number
  owner_identification_number: string | null
  mol_registration: MolRegistration
  bank_accounts: BankAccountType[]
  unified_mol_number: string | null
}

type PaymentDetailType = {
  id: number
  payment_mode: 'mudad'
  status: 'active' | 'inactive'
  bank_account: {
    iban: string
    id: number
    name: string
  }
}

type CurrentPaymentMethod = {
  invoice_already_generated: boolean
  commercial_registration: PaymentConfigurationType
  mudad_establishment: {
    created_at: string
    deleted_at: string | null
    is_completed?: boolean
    external_establishment_id: string
    id: number
    organization_id: number
    owner_signatory_id: number
    registration_id: number
    status: 'active' | 'inactive'
    subscription_end_date: string
    subscription_start_date: string
    updated_at: string
  } | null
  mudad_payment_configuration: PaymentDetailType | null
  offline_payment_configuration: PaymentDetailType | null
}

export type EmployeePaymentType = {
  avatar_thumb: string
  code: string
  id: number
  name_i18n: string
}

type InitStateType = {
  fetching: boolean
  fetchingShowMethod: boolean
  loading: boolean
  is_mudad_enabled: boolean
  current_payment_method: CurrentPaymentMethod | null
  configured_payment_methods: PaymentConfigurationType[]
  pending_payment_methods: PaymentConfigurationType[]
}

const initialState: InitStateType = {
  fetching: false,
  is_mudad_enabled: false,
  configured_payment_methods: [],
  pending_payment_methods: [],
  current_payment_method: null,
  loading: false,
  fetchingShowMethod: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.PAYMENT_METHODS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.PAYMENT_METHODS_LOAD_FULFILLED: {
      const { configured_payment_methods, pending_payment_methods } = payload.data.data

      return {
        ...state,
        fetching: false,
        configured_payment_methods: configured_payment_methods || [],
        pending_payment_methods: pending_payment_methods || [],
      }
    }
    case actions.PAYMENT_METHODS_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.PAYMENT_METHOD_CREATE_PENDING:
    case actions.PAYMENT_METHOD_SET_DEFAULT_PENDING:
    case actions.PAYMENT_METHOD_CHANGE_DEFAULT_BANK_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.PAYMENT_METHOD_CREATE_FULFILLED:
    case actions.PAYMENT_METHOD_CREATE_REJECTED:
    case actions.PAYMENT_METHOD_SET_DEFAULT_FULFILLED:
    case actions.PAYMENT_METHOD_SET_DEFAULT_REJECTED:
    case actions.PAYMENT_METHOD_CHANGE_DEFAULT_BANK_FULFILLED:
    case actions.PAYMENT_METHOD_CHANGE_DEFAULT_BANK_REJECTED: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.PAYMENT_METHOD_LOAD_PENDING: {
      return {
        ...state,
        fetchingShowMethod: true,
      }
    }

    case actions.PAYMENT_METHOD_LOAD_FULFILLED:
      return {
        ...state,
        fetchingShowMethod: false,
        current_payment_method: payload.data.data,
      }

    case actions.PAYMENT_METHOD_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetchingShowMethod: false,
      }
    }

    case actions.MUDAD_INTEGRATION_ENABLED_FULFILLED:
      return {
        ...state,
        is_mudad_enabled: payload.data.data.payrun_configuration.value,
      }
    case actions.MUDAD_INTEGRATION_ENABLED_REJECTED:
      return {
        ...state,
        is_mudad_enabled: false,
      }
    // ? local update
    case actions.PAYMENT_METHODS_UPDATE_ITEM_OF_LIST: {
      const newData = payload as PaymentConfigurationType

      let pendingTarget = [...state.pending_payment_methods]
      let target = [...state.configured_payment_methods]
      const index = target.findIndex((item) => item.id === newData.id)
      if (index >= 0 && newData.active_payment_method) {
        target[index] = newData
      } else if (index >= 0 && !newData.active_payment_method) {
        target = target.filter((it) => it.id !== newData.id)
        pendingTarget = [...pendingTarget, newData]
      } else if (newData.active_payment_method) {
        // ? incase it's new one we have to remove it from pending and add it to configured list
        const paymentIndex = state.pending_payment_methods?.findIndex((it) => it.id === newData.id)
        if (paymentIndex >= 0) {
          target = [...target, newData]
          pendingTarget = pendingTarget.filter((it) => it.id !== newData.id)
        }
      }

      return {
        ...state,
        configured_payment_methods: target,
        pending_payment_methods: pendingTarget,
      }
    }

    default:
      return state
  }
}
