export const LOCATIONS_LOAD = 'LOCATIONS_LOAD'
export const LOCATIONS_LOAD_PENDING = 'LOCATIONS_LOAD_PENDING'
export const LOCATIONS_LOAD_FULFILLED = 'LOCATIONS_LOAD_FULFILLED'
export const LOCATIONS_LOAD_REJECTED = 'LOCATIONS_LOAD_REJECTED'

export const LOCATIONS_SHOW = 'LOCATIONS_SHOW'
export const LOCATIONS_SHOW_PENDING = 'LOCATIONS_SHOW_PENDING'
export const LOCATIONS_SHOW_FULFILLED = 'LOCATIONS_SHOW_FULFILLED'
export const LOCATIONS_SHOW_REJECTED = 'LOCATIONS_SHOW_REJECTED'

export const ONBOARDING_LOCATIONS_POST = 'ONBOARDING_LOCATIONS_POST'
export const ONBOARDING_LOCATIONS_POST_PENDING = 'ONBOARDING_LOCATIONS_POST_PENDING'
export const ONBOARDING_LOCATIONS_POST_FULFILLED = 'ONBOARDING_LOCATIONS_POST_FULFILLED'
export const ONBOARDING_LOCATIONS_POST_REJECTED = 'ONBOARDING_LOCATIONS_POST_REJECTED'

export const ONBOARDING_LOCATIONS_PUT = 'ONBOARDING_LOCATIONS_PUT'
export const ONBOARDING_LOCATIONS_PUT_PENDING = 'ONBOARDING_LOCATIONS_PUT_PENDING'
export const ONBOARDING_LOCATIONS_PUT_FULFILLED = 'ONBOARDING_LOCATIONS_PUT_FULFILLED'
export const ONBOARDING_LOCATIONS_PUT_REJECTED = 'ONBOARDING_LOCATIONS_PUT_REJECTED'

export const ONBOARDING_LOCATIONS_DELETE = 'ONBOARDING_LOCATIONS_DELETE'
export const ONBOARDING_LOCATIONS_DELETE_PENDING = 'ONBOARDING_LOCATIONS_DELETE_PENDING'
export const ONBOARDING_LOCATIONS_DELETE_FULFILLED = 'ONBOARDING_LOCATIONS_DELETE_FULFILLED'
export const ONBOARDING_LOCATIONS_DELETE_REJECTED = 'ONBOARDING_LOCATIONS_DELETE_REJECTED'

export const CLONE_PARENT_ORG_LOCATIONS = 'CLONE_PARENT_ORG_LOCATIONS'
export const CLONE_PARENT_ORG_LOCATIONS_PENDING = 'CLONE_PARENT_ORG_LOCATIONS_PENDING'
export const CLONE_PARENT_ORG_LOCATIONS_FULFILLED = 'CLONE_PARENT_ORG_LOCATIONS_FULFILLED'
export const CLONE_PARENT_ORG_LOCATIONS_REJECTED = 'CLONE_PARENT_ORG_LOCATIONS_REJECTED'
