import React from 'react'
import { Flex } from '@jisr-hr/ds'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  overtimeRequestsCreate,
  overtimeRequestsUpdate,
} from 'redux/actions/overtimeRequestActions'
import { omit } from 'lodash'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import {
  OvertimeDuration,
  Reason,
  Profile,
} from 'components/global/templates/RequestDrawer/components/forms'
import { durationByMinutes, timeToNumbers } from 'components/global/form/formNormalize'
import JisrLoader from 'components/global/JisrLoader'
import { format, isValid } from 'date-fns'
import { dateWithOutTimeZone } from 'utils/date'
import { uniqId } from 'utils/uiHelpers'

import { useEmployeeAttendanceShifts, useRequestDetail } from '../../hooks'

const handleValues = (val) => {
  if (val.overtime_type === 'single_day') return val

  let overtime_logs_attributes = []

  val.multiple_shifts?.forEach((record) => {
    const minutes = timeToNumbers(record?.duration)?.totalMinutes

    if (minutes > 0) {
      overtime_logs_attributes = [
        ...overtime_logs_attributes,
        {
          date: record?.date,
          duration: record?.duration,
          ...(record?.detail?.employee_shift_id && {
            employee_shift_id: record?.detail?.employee_shift_id,
          }),
        },
      ]
    }
  })

  return {
    ...val,
    overtime_logs_attributes,
  }
}

const overtimeTypes = {
  single_day: 'single',
  multiple_days: 'multiple',
  bulk_overtime: 'bulk',
}

const overtimeMapping = Object.entries(overtimeTypes).reduce((prev, [key, value]) => {
  return { ...prev, [value]: key }
}, {})

const OvertimeRequest = () => {
  const { change } = useForm()
  const dispatch = useDispatch()
  const { fetching: fetchingShift } = useEmployeeAttendanceShifts()
  const {
    setOnSubmit,
    actionCallback,
    setInitialValues,
    vars,
    drawerToggle,
    editRequest,
    setEditRequest,
  } = useRequest()

  const { request, loading } = useRequestDetail()
  const empId = vars.id ?? request?.employee?.id

  const detail = {
    date:
      isValid(dateWithOutTimeZone(vars?.date)) &&
      format(dateWithOutTimeZone(vars?.date), 'yyyy-MM-dd'),
    duration: '00:00',
  }

  const { fetching } = useSelector(({ overtimeRequest }) => overtimeRequest)

  const handleOnSubmit = (values) => {
    change('disableSubmit', true)
    const val = handleValues(values)
    const overtimeValues = {
      input_type: overtimeTypes?.[val.overtime_type],
      reason: val?.reason,
      overtime_logs_attributes: val?.overtime_logs_attributes,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }

    if (val.id) {
      dispatch(
        overtimeRequestsUpdate(val.id, {
          ...overtimeValues,
          attachments: overtimeValues.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      )
        .then(() => {
          change('disableSubmit', false)
          setEditRequest(false)
        })
        .catch(() => change('disableSubmit', false))

      return
    }

    dispatch(
      overtimeRequestsCreate(empId, {
        ...overtimeValues,
        attachments: overtimeValues.attachments?.map((it) => omit(it, 'id')),
      }),
    )
      .then(() => {
        change('disableSubmit', false)
        drawerToggle()
        if (actionCallback) {
          actionCallback()
        }
      })
      .catch(() => change('disableSubmit', false))
  }

  const handleMultipleShiftsInitial = () => {
    const data =
      request?.request_days?.map((st) => {
        return {
          date: st.date,
          duration: durationByMinutes(st.duration || 0),
          detail: {
            indexId: uniqId(),
            start: st?.employee_shift?.shift_start_time,
            end: st?.employee_shift?.shift_end_time,
            ...(st?.employee_shift?.id && { employee_shift_id: st?.employee_shift?.id }),
            is_day_off: st?.attendance_record?.is_day_off,
          },
        }
      }) ?? []

    return data
  }

  React.useEffect(() => {
    setOnSubmit(handleOnSubmit)
    setInitialValues({
      overtime_type: 'single_day',
      overtime_dates: [],
      multiple_shifts: [],
      deletedAttachments: [],
      overtime_logs_attributes: [detail],
      ...(editRequest && {
        ...request,
        initialShiftId: request?.employee_shift?.id,
        overtime_type: overtimeMapping[request?.input_type],
        ...(request?.input_type === 'single'
          ? {
              initialShiftDuration: request?.request_days?.[0]?.duration,
              overtime_logs_attributes: [
                {
                  date: request?.request_days?.[0]?.date,
                  duration: durationByMinutes(request?.request_days?.[0]?.duration ?? 0),
                  ...(request?.request_days?.[0]?.employee_shift?.id && {
                    employee_shift_id: request.request_days?.[0].employee_shift.id,
                  }),
                },
              ],
            }
          : {
              overtime_dates: [...new Set(request?.request_days?.map((req) => req.date) || [])],
              initialOvertimeDates: [
                ...new Set(request?.request_days?.map((req) => req.date) || []),
              ],
              multiple_shifts: handleMultipleShiftsInitial(),
              initialMultipleShifts: handleMultipleShiftsInitial(),
            }),
      }),
    })

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(fetching || fetchingShift || loading) && <JisrLoader absolute />}
      <Profile employee={vars?.employee} />
      <OvertimeDuration overtimeDetail={detail} />
      <Reason />
    </Flex>
  )
}

export default OvertimeRequest
