import { FC } from 'react'
import { Form, CheckboxField, NewSelectField } from 'components/final-form'
import { useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'
import { differenceWith } from 'lodash'
import { required } from 'components/global/form/FormValidations'
import { Button, Flex } from '@jisr-hr/ds'
import { VerificationEmployeeTyp } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import { BankAccountType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import i18n from 'translations/i18n'
import { usePaymentMethod } from '../../../components/usePaymentMethods'

export type AuthorizerFormPayloadType = {
  bank_account_id: number
  mudad_signatory_id: number
  agree?: boolean
}

type AuthorizeAdminFormType = {
  onSubmit: (arg: AuthorizerFormPayloadType) => void
  initialValues: Partial<AuthorizerFormPayloadType>
  banks: BankAccountType[]
  verifiedAdmins: VerificationEmployeeTyp[]
}

const AuthorizeAdminForm: FC<AuthorizeAdminFormType> = (props) => {
  const { initialValues, banks, verifiedAdmins } = props
  const paymentCtx = usePaymentMethod()
  const { currentAuthorizer, selectedPaymentMethod, isOnBoarding } = paymentCtx

  const authorizing = useSelector((s) => s.mudadIntegration.authorizing)
  const authorized_employees = useSelector(
    (s) => s.mudadIntegration.authorized_employees,
    shallowEqual,
  )

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, valid, pristine, values, form }): JSX.Element => {
        const currentBankSelectedEmp = authorized_employees.filter(
          (auth) => auth.bank_info?.id === values.bank_account_id,
        )
        const filteredEmployee = differenceWith(
          verifiedAdmins,
          currentBankSelectedEmp,
          (arg, arg2) => {
            return arg.id === arg2.mudad_signatory_id
          },
        )
        const isExist =
          filteredEmployee.findIndex((it) => it.id === currentAuthorizer?.mudad_signatory_id) < 0
        return (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Flex
              itemsStart
              style={{ gap: 24 }}
            >
              <NewSelectField
                name="bank_account_id"
                label={i18n.t('your_salaries_bank')}
                valueKey="id"
                labelKey="bank_name"
                maxMenuHeight={300}
                validate={required}
                onChange={(): void => {
                  form.change('mudad_signatory_id', undefined)
                }}
                footer={
                  isOnBoarding
                    ? {
                        label: i18n.t('add_new_bank_account'),
                        onClick: () =>
                          paymentCtx.handleManagementPaymentOpen(selectedPaymentMethod),
                        leadingIcon: 'plus',
                      }
                    : undefined
                }
                // components={!isOnBoarding && { Menu: BanksSelectMenu }}
                options={banks}
              />

              <NewSelectField
                name="mudad_signatory_id"
                label={i18n.t('authorized_employee')}
                valueKey="id"
                labelKey="full_name_i18n"
                maxMenuHeight={300}
                validate={required}
                options={
                  currentAuthorizer && isExist
                    ? [
                        {
                          id: currentAuthorizer.mudad_signatory_id,
                          full_name_i18n: currentAuthorizer.full_name_i18n,
                        },
                        ...filteredEmployee,
                      ]
                    : filteredEmployee
                }
              />
            </Flex>
            <Flex style={{ marginTop: 24 }}>
              <CheckboxField
                name="agree"
                validate={required}
                label={i18n.t('i_agree_to_authorize_mudad')}
              />
            </Flex>
            <Button
              label={i18n.t('request_to_authorize')}
              type="submit"
              size="medium"
              disabled={authorizing || pristine || !valid}
              style={{ marginTop: 24, flexShrink: 0 }}
            />
          </form>
        )
      }}
    </Form>
  )
}

export default AuthorizeAdminForm
