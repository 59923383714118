import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import IconFab from '@material-ui/core/Fab'
import classNames from 'classnames'
import { Typography } from '@jisr-hr/ds'

const Fab = ({ title, Icon, onClick, color, disabled, style, size, className, testId }) => (
  <div className={classNames('fab-container', className && className)}>
    <IconFab
      disabled={disabled}
      style={{
        background: color,
        ...style,
      }}
      size={size}
      onClick={onClick}
      data-testid={testId}
    >
      {Icon}
    </IconFab>
    {title && (
      <Typography
        text={I18n.t(title)}
        variant="body-new/regular"
      />
    )}
  </div>
)

Fab.propTypes = {
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  Icon: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.shape(),
}

export default Fab
