import React from 'react'
import PropTypes from 'prop-types'
import RequestDrawer from 'components/global/templates/RequestDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  approvalDetailReset,
  approvalDetailLoad,
} from 'redux/requests/newRequestsApproval/actionCreators'

const RequestDetails = ({ actionCallback, vars = {} }) => {
  const { request } = useSelector((state) => state.approvals)
  const dispatch = useDispatch()
  const reloadRequest = () => dispatch(approvalDetailLoad(request?.id))
  const onClose = () => dispatch(approvalDetailReset())
  const type =
    request && !request?.category
      ? 'CustomTask'
      : request?.request_type ?? request?.request?.request_type

  return (
    <div>
      <RequestDrawer
        header={{ title: request.title }}
        vars={{
          request,
          reloadRequest,
          ...vars,
        }}
        actionCallback={actionCallback}
        needApproval
        active={!isEmpty(request)}
        requestType={
          request?.organization_task?.name === 'performance_collaboration_task'
            ? 'PerformanceGroupTask'
            : type
        }
        onClose={onClose}
      />
    </div>
  )
}

RequestDetails.propTypes = {
  actionCallback: PropTypes.func,
  vars: PropTypes.shape(),
}

export default RequestDetails
