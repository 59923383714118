import { FC } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { MudadSignatoriesType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import {
  createMudadSignatory,
  mudadFlowStepsLoad,
  mudadSignatoriesVerify,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import PaymentAlert, {
  PaymentAlertType,
} from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/PaymentAlert'
import i18n from 'translations/i18n'

import MudadStepsTemplate from '../components/MudadStepsTemplate'
import { usePaymentMethod } from '../../../components/usePaymentMethods'
import OwnerForm from './OwnerForm'
import MudadOTPCounter from '../components/MudadOTPForm'

const OwnerVerification: FC = () => {
  const dispatch = useDispatch()

  const paymentCtx = usePaymentMethod()
  const { currentSignatory, selectedPaymentMethod } = paymentCtx
  const establishment_owner = useSelector((s) => s.mudadIntegration.establishment_owner)
  const loading = useSelector((s) => s.mudadIntegration.loading)
  const fetching = useSelector((s) => s.mudadIntegration.fetching)

  const isOwnerVerified =
    typeof establishment_owner?.show_owner_popup === 'boolean' &&
    !establishment_owner?.show_owner_popup

  const alertContent: PaymentAlertType = !isOwnerVerified
    ? {
        title: i18n.t('make_sure_contact_ur_org_owner_note'),
        description: i18n.t('we_will_send_otp_verify_owner_note'),
      }
    : {
        type: 'success',
        title: i18n.t('owner_success_note', {
          number: establishment_owner?.identification_number,
        }),
      }

  const handleCreateOwner = async (identification_number: string): Promise<void> => {
    try {
      if (!selectedPaymentMethod) return
      const res = await dispatch(
        createMudadSignatory(selectedPaymentMethod.registration_id, {
          is_owner: true,
          identification_number,
        }),
      )
      const signatory = res.value.data.data.mudad_signatory as MudadSignatoriesType
      paymentCtx.setCurrentSignatory(signatory)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error.response.data.error,
      })
    }
  }

  const handleResendOtp = async (): Promise<void> => {
    try {
      if (!selectedPaymentMethod) return
      const res = await dispatch(
        createMudadSignatory(selectedPaymentMethod.registration_id, {
          is_owner: true,
          identification_number: currentSignatory?.identification_number,
        }),
      )
      const signatory = res.value.data.data.mudad_signatory as MudadSignatoriesType
      paymentCtx.setCurrentSignatory(signatory)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error.response.data.error,
      })
    }
  }

  const handleConfirmOtp = async (data: { otp_code: string }): Promise<void> => {
    if (!selectedPaymentMethod) return
    if (!currentSignatory) return
    try {
      await dispatch(
        mudadSignatoriesVerify(selectedPaymentMethod.registration_id, currentSignatory?.id, {
          is_owner: true,
          otp: data.otp_code,
        }),
      )
      paymentCtx.setMudadCurrentStep(3)
      paymentCtx.setCurrentSignatory(null)
      dispatch(mudadFlowStepsLoad(selectedPaymentMethod.registration_id))
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error.response.data.error,
      })
    }
  }

  const handleDisableNextBtn = (): boolean => {
    if (typeof establishment_owner?.show_owner_popup === 'undefined') return true
    if (!establishment_owner?.show_owner_popup) return false
    if (!currentSignatory) return true
    return false
  }

  return (
    <MudadStepsTemplate
      disableBackBtn={false}
      disableNextBtn={handleDisableNextBtn()}
      onNextClick={(): void => paymentCtx.setMudadCurrentStep(3)}
    >
      <PaymentAlert
        style={{ marginBottom: 24 }}
        {...alertContent}
      />
      {!isOwnerVerified && (
        <>
          <OwnerForm
            initialValues={{ identification_number: currentSignatory?.identification_number }}
            onSubmit={(data): Promise<void> => handleCreateOwner(data.identification_number)}
          />
          {!!currentSignatory && (
            <MudadOTPCounter
              onResend={handleResendOtp}
              onSubmit={handleConfirmOtp}
              updated_at={currentSignatory.updated_at}
              disabled={loading || fetching}
            />
          )}
        </>
      )}
    </MudadStepsTemplate>
  )
}

export default OwnerVerification
