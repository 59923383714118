import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CandidateSourceProps = {
  filterKey: string
  filters: Array<string>
}

const CandidateSource = (props: CandidateSourceProps): JSX.Element => {
  const { candidate_source } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(candidate_source)
  const fltr = filter[props.filterKey]

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (val !== 'all') {
      if (optionList.length > 3 && !filter.candidate_source.includes('all')) {
        setFilter({ ...filter, candidate_source: [...filter.candidate_source, val] })
      } else {
        setFilter({ ...filter, candidate_source: [val] })
      }
    } else {
      setFilter({ ...filter, candidate_source: ['all'] })
    }
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      isClearable={false}
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('label.source')}
    />
  )
}

export default CandidateSource
