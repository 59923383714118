import { useDispatch, useSelector } from 'utils/hooks'
import { useLocation } from 'react-router-dom'
import { Modal, Spacer } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { FormApi } from 'final-form'
import {
  undoOnboardingAdmin,
  listOnboardingAdmins,
  inviteOnboardingAdmin,
  deleteOnboardingAdmin,
  verifyOnboardingAdmins,
} from 'redux/organizationOnborading/inviteAdmins/actionCreators'
import { OnboardingAdminType } from 'redux/organizationOnborading/inviteAdmins/type'
import { OnboardingTier } from 'containers/authorised/NewOnboarding/helper'
import { mixPanelAnalytics } from 'utils/mixpanel'
import InvitedAdmins, { InvitedAdminsButtonConfig } from './components/InvitedAdmins'
import InviteForm from './components/InviteForm'
import FailuresList from './components/FailuresList'

type OnboardingInviteAdminsProps = {
  isOpen: boolean
  onClose: () => void
}

const invitedStatus = ['accepted', 'expired', 'pending', 'initiated']

const OnboardingInviteAdmins = (props: OnboardingInviteAdminsProps): JSX.Element => {
  const { isOpen } = props
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const employee = useSelector(({ auth }) => auth.employee)
  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER
  const { onboarding_admins, fetching } = useSelector(
    ({ onboardingInviteAdmins }) => onboardingInviteAdmins,
  )

  const invitedAdmins =
    onboarding_admins.filter(
      (e) => invitedStatus.includes(e.invitation_status || '') && e.id !== employee?.id,
    ) || []
  const failuresList =
    onboarding_admins.filter((e) =>
      ['failed', 'not_delivered'].includes(e.invitation_status || ''),
    ) || []

  const handleOnSubmit = (
    values: { email: string },
    fun: FormApi<{ email: string }, Partial<{ email: string }>>,
  ): void => {
    dispatch(inviteOnboardingAdmin(values.email)).then(() => {
      mixPanelAnalytics({
        event_name: 'Onboarding Admin Invited',
        properties: {
          page_name: pathname,
          language: i18n.language,
          onboarding_flow: employee?.organization.onboarding_flow,
        },
      })

      dispatch(listOnboardingAdmins())
      fun.reset()
    })
  }

  const handleOnClick = (values: OnboardingAdminType, isCancel?: boolean): void => {
    mixPanelAnalytics({
      event_name: 'Onboarding Admin Invite Action List',
      properties: {
        page_name: pathname,
        language: i18n.language,
        affected_email: values.email,
        onboarding_flow: employee?.organization.onboarding_flow,
        action_name: InvitedAdminsButtonConfig[values.invitation_status ?? ''][0],
      },
    })
    if (values.invitation_status === 'accepted') {
      dispatch(deleteOnboardingAdmin(values.id)).then(() => {
        dispatch(listOnboardingAdmins())
      })
    } else if (['pending', 'initiated'].includes(values.invitation_status || '') || isCancel) {
      dispatch(undoOnboardingAdmin(values.email)).then(() => {
        dispatch(listOnboardingAdmins())
      })
    } else if (['failed', 'not_delivered', 'expired'].includes(values.invitation_status || '')) {
      dispatch(inviteOnboardingAdmin(values.email)).then(() => {
        dispatch(listOnboardingAdmins())
      })
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={(): void => {
        if (isHighTier) {
          dispatch(
            listOnboardingAdmins({
              within_first_admin: 1,
              template: 'index_with_tmp_errors',
            }),
          )
        }
        dispatch(verifyOnboardingAdmins())
        props.onClose()
      }}
      size="medium"
      header={{
        withClose: true,
        title: i18n.t('label.invite_admins'),
        description: i18n.t(
          'description.admins_can_help_you_with_setting_up_your_workspace._you_can_change_admins_permissions_later',
        ),
      }}
    >
      <div className="mb-[32px]">
        <InviteForm
          fetching={fetching}
          onSubmit={handleOnSubmit}
        />
        {!!failuresList.length && (
          <>
            <Spacer height={24} />
            <FailuresList
              fetching={fetching}
              list={failuresList}
              onClick={handleOnClick}
            />
          </>
        )}

        <Spacer height={32} />
        <InvitedAdmins
          fetching={fetching}
          list={invitedAdmins}
          onClick={handleOnClick}
        />
      </div>
    </Modal>
  )
}

export default OnboardingInviteAdmins
