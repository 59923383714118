/*
 *   App Environments
 */

import { removeAuthToken } from 'redux/authUser/actionCreators'

export const isDevelopmentStage =
  window.location.hostname.includes('jisr.dev') &&
  !(
    window.location.hostname.includes('staging.jisr.dev') ||
    window.location.hostname.includes('makenhr.jisr.dev')
  )

export function getOrganisationName() {
  const hostname = window.location.hostname.split('.')

  if (isDevelopmentStage) {
    if (!localStorage.getItem('slug')) {
      removeAuthToken()
    }
    return localStorage.getItem('slug')
  }

  return hostname[0]
}

const awsApiUrl = 'jisr.net/api'
const makenhrApiUrl = 'makenhr.com/api'
const stcApiUrl = 'https://api.ksa.jisr.net'
const stagingApiUrl = 'staging.jisr.net/api'
// const stagingPerformanceUrl = 'staging.pm.jisr.dev';

const apiUrls = [awsApiUrl, makenhrApiUrl, stagingApiUrl]
const assetManagerApiUrls = [
  // 'staging.assetmanager.jisr.dev',
  // 'jisr.net/asset',
  'makenhr.com/asset',
]

export const ORGANISATION_NAME = getOrganisationName()

/**
 * The function to get/overwrite env variables
 *
 * Priority:
 * 1. local storage (for developers)
 * 2. import.meta.env @deprecated
 * 3. JSON file for async config loading @todo Add it
 */
export function getEnvVar(name) {
  /**
   * Show deprecated console to be sure migration to the new env usage is done
   */
  if (import.meta.env.MODE === 'development') {
    if (import.meta.env[name]) {
      console.warn(
        `⚠ import.meta.env.${name} deprecated` +
          '\n Use local storage variables for development mode on localhost' +
          `\n You can set "localStorage.setItem('${name}', '${
            import.meta.env[name]
          }');" and remove the varibale from .env`,
      )
    }
  }
  return localStorage.getItem(name) || import.meta.env[name]
}

const getApiUrlWithOrganizationName = (url) => `https://${ORGANISATION_NAME}.${url}`

const APP_ASSETMANAGER_API = getEnvVar('UkVBQ1RfQVBQX0FTU0VUTUFOQUdFUl9BUEk')
const APP_PM_API = getEnvVar('UkVBQ1RfQVBQX1BNX0FQSQ')
const APP_ATS_API = getEnvVar('UkVBQ1RfQVBQX0FUU19BUEk')
const APP_BASE_URL = getEnvVar('UkVBQ1RfQVBQX0JBU0VfVVJM')
export const CHATBOT_URL = getEnvVar('SklTUl9DSEFUQk9UX0JBU0VfVVJM')

let apiRoot = APP_BASE_URL
let assetManagerRoot = `${APP_ASSETMANAGER_API}/api`
const performanceRoot = `${APP_PM_API}/api`
const atsRoot = `${APP_ATS_API}/api`

if (apiUrls.includes(APP_BASE_URL)) {
  apiRoot = getApiUrlWithOrganizationName(apiRoot)
}

if (assetManagerApiUrls.includes(APP_ASSETMANAGER_API)) {
  assetManagerRoot = getApiUrlWithOrganizationName(assetManagerRoot)
}

// if (stagingPerformanceUrl === APP_PM_API) {
//   performanceRoot = getApiUrlWithOrganizationName(performanceRoot);
// }

export const IS_PRODUCTION_AWS = awsApiUrl === APP_BASE_URL
export const API_ROOT = apiRoot
export const ASSET_MANAGER_ROOT = assetManagerRoot
export const PERFORMANCE_ROOT = performanceRoot
export const ATS_ROOT = atsRoot

let env = ''
switch (APP_BASE_URL) {
  case awsApiUrl:
    env = 'aws'
    break
  case stcApiUrl:
    env = 'stc'
    break
  case makenhrApiUrl:
    env = 'makenhr'
    break
  case stagingApiUrl:
    env = 'staging'
    break
  default:
    env = 'dev'
}

export const ENV = env

// serverEnvironment for AWS = aws
// serverEnvironment for MAKEN = maken
// serverEnvironment for STC = oci
export const serverEnvironment = import.meta.env.UkVBQ1RfQVBQX1NFUlZFUl9FTlZJUk9OTUVOVA

export const STATIC_FILES_URL = 'https://staticfiles.jisr.net/static-files-main/assets/jisr'
export const APP_DOMAIN_URL = 'jisr.net'
