export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_PENDING = 'GET_NOTIFICATIONS_PENDING'
export const GET_NOTIFICATIONS_FULFILLED = 'GET_NOTIFICATIONS_FULFILLED'
export const GET_NOTIFICATIONS_REJECTED = 'GET_NOTIFICATIONS_REJECTED'

export const TOGGLE_NOTIFICATIONS_PANEL = 'TOGGLE_NOTIFICATIONS_PANEL'

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'

export const GET_EVENT_NOTIFICATIONS = 'GET_EVENT_NOTIFICATIONS'
export const GET_EVENT_NOTIFICATIONS_PENDING = 'GET_EVENT_NOTIFICATIONS_PENDING'
export const GET_EVENT_NOTIFICATIONS_FULFILLED = 'GET_EVENT_NOTIFICATIONS_FULFILLED'
export const GET_EVENT_NOTIFICATIONS_REJECTED = 'GET_EVENT_NOTIFICATIONS_REJECTED'
