export const SAP_BUSINESSES_SUBSIDIARIES_LOAD = 'SAP_BUSINESSES_SUBSIDIARIES_LOAD'
export const SAP_BUSINESSES_SUBSIDIARIES_LOAD_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARIES_LOAD_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARIES_LOAD_REJECTED = 'SAP_BUSINESSES_SUBSIDIARIES_LOAD_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARIES_LOAD_PENDING = 'SAP_BUSINESSES_SUBSIDIARIES_LOAD_PENDING'

export const SAP_BUSINESSES_MAPPED_SUBSIDIARIES = 'SAP_BUSINESSES_MAPPED_SUBSIDIARIES'
export const SAP_BUSINESSES_MAPPED_SUBSIDIARIES_FULFILLED =
  'SAP_BUSINESSES_MAPPED_SUBSIDIARIES_FULFILLED'
export const SAP_BUSINESSES_MAPPED_SUBSIDIARIES_REJECTED =
  'SAP_BUSINESSES_MAPPED_SUBSIDIARIES_REJECTED'
export const SAP_BUSINESSES_MAPPED_SUBSIDIARIES_PENDING =
  'SAP_BUSINESSES_MAPPED_SUBSIDIARIES_PENDING'

export const SAP_BUSINESSES_SUBSIDIARY_CREATE = 'SAP_BUSINESSES_SUBSIDIARY_CREATE'
export const SAP_BUSINESSES_SUBSIDIARY_CREATE_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARY_CREATE_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARY_CREATE_REJECTED = 'SAP_BUSINESSES_SUBSIDIARY_CREATE_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARY_CREATE_PENDING = 'SAP_BUSINESSES_SUBSIDIARY_CREATE_PENDING'

export const SAP_BUSINESSES_SUBSIDIARY_DELETE = 'SAP_BUSINESSES_SUBSIDIARY_DELETE'
export const SAP_BUSINESSES_SUBSIDIARY_DELETE_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARY_DELETE_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARY_DELETE_REJECTED = 'SAP_BUSINESSES_SUBSIDIARY_DELETE_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARY_DELETE_PENDING = 'SAP_BUSINESSES_SUBSIDIARY_DELETE_PENDING'

export const SAP_BUSINESSES_SUBSIDIARY_UPDATE = 'SAP_BUSINESSES_SUBSIDIARY_UPDATE'
export const SAP_BUSINESSES_SUBSIDIARY_UPDATE_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARY_UPDATE_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARY_UPDATE_REJECTED = 'SAP_BUSINESSES_SUBSIDIARY_UPDATE_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARY_UPDATE_PENDING = 'SAP_BUSINESSES_SUBSIDIARY_UPDATE_PENDING'

export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'

export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED'
export const SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING =
  'SAP_BUSINESSES_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING'
