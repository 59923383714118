import React from 'react'
import I18n from 'translations/i18n'
import { Flex, Typography } from '@jisr-hr/ds'

import Styles from '../ProposedCorrectionTime.module.css'
import PunchOption from './PunchOption'

const CorrectionPunch = (props) => (
  <Flex
    flexCol
    className={Styles.correctionPunch}
  >
    <Typography
      text={I18n.t('proposed_correction')}
      variant="body-new/regular"
      style={{ color: 'var(--color-base-colors-grey-600)' }}
    />

    <PunchOption {...props} />
  </Flex>
)

export default CorrectionPunch
