import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import MtMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(() => ({
  button: {
    margin: 0,
    minWidth: 20,
    padding: 0,
  },
}))

const Menu = ({ button, actions, item, testId }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        data-testid="menu-button"
        onClick={handleClick}
        className={classes.button}
      >
        {button}
      </Button>
      <MtMenu
        data-testid={testId}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {actions &&
          actions.map((action) => (
            <MenuItem
              data-testid={action.testId}
              style={{
                display: 'flex',
                padding: 5,
              }}
              key={action.label}
              onClick={() => {
                action.onClick(item)
                handleClose()
              }}
            >
              {action.label}
            </MenuItem>
          ))}
      </MtMenu>
    </div>
  )
}

Menu.propTypes = {
  button: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  actions: PropTypes.arrayOf(Object),
  item: PropTypes.shape(),
  testId: PropTypes.string,
}
export default Menu
