import { Flex, Typography } from '@jisr-hr/ds'
import cn from 'classnames'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import { formatDateRequestOn } from 'containers/authorised/requests/helper'
import Signpost from '../ds/Signpost'

type Props = {
  notifications: Array<{
    action_at: string
    action_by: {
      name_i18n: string
    }
    action_for: {
      name_i18n: string
    }
  }>
  isLastApproval: boolean
}

const ApprovalNotify = ({ notifications, isLastApproval }: Props): JSX.Element[] => {
  const employeeDate = useSelector(({ auth }) => auth.employeeDate)
  return notifications.map(({ action_at, action_by, action_for }, i) => {
    const isLastItem = isLastApproval && i === notifications.length - 1
    return (
      <div
        key={action_at}
        className={cn('-mt-8', {
          "-mt-20 after:content-[''] after:bg-base-colors-black-1000 after:w-[2px] after:inline-block after:h-6 after:ms-[19px]":
            !isLastItem,
        })}
      >
        <Signpost>
          <Flex
            itemsCenter
            justifyBetween
          >
            <Flex className="gap-1">
              <Typography
                variant="subtitle-2"
                textColor="color/fg/brand/default"
                text={action_by.name_i18n}
              />
              <Typography
                variant="subtitle-2"
                text={i18n.t('communication.employee_notified_log')}
              />
              <Typography
                variant="subtitle-2"
                textColor="color/fg/brand/default"
                text={action_for.name_i18n}
              />
            </Flex>
            <Typography
              variant="subtitle-2"
              text={`${formatDateRequestOn(employeeDate, action_at)} — ${format(action_at, 'p')}`}
              textColor="color/fg/lighter"
            />
          </Flex>
        </Signpost>
      </div>
    )
  })
}

export default ApprovalNotify
