import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  transactionsDetails: {},
  breakdown: [],
  key: null,
  name: null,
  employee_remarks: {},
  manual_remarks: [],
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PAYROLL_SINGLE_REMARK_LOAD_PENDING:
    case actions.PAYROLL_MANUAL_REMARKS_LOAD_PENDING:
    case actions.AUTOMATIC_REMARKS_PENDING:
    case actions.PAYROLL_REMARKS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.PAYROLL_REMARKS_CREATE_PENDING:
    case actions.PAYROLL_REMARKS_UPDATE_PENDING:
    case actions.PAYROLL_REMARKS_DELETE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.PAYROLL_REMARKS_CREATE_FULFILLED:
    case actions.PAYROLL_REMARKS_UPDATE_FULFILLED:
    case actions.PAYROLL_REMARKS_DELETE_FULFILLED:
      if (action?.payload?.data?.message) {
        Toastr.success(action?.payload?.data?.message)
      }
      return {
        ...state,
        loading: false,
      }
    case actions.PAYROLL_REMARKS_CREATE_REJECTED:
    case actions.PAYROLL_REMARKS_UPDATE_REJECTED:
    case actions.PAYROLL_REMARKS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    case actions.PAYROLL_SINGLE_REMARK_LOAD_FULFILLED: {
      return {
        ...state,
        ...(action?.payload?.data?.data ? action?.payload?.data?.data : initialState),
        fetching: false,
      }
    }

    case actions.AUTOMATIC_REMARKS_FULFILLED: {
      return {
        ...state,
        transactionsDetails: action.payload.data.data,
        fetching: false,
      }
    }

    case actions.PAYROLL_REMARKS_LOAD_FULFILLED: {
      return {
        ...state,
        employee_remarks: action.payload.data.data,
        fetching: false,
      }
    }

    case actions.PAYROLL_MANUAL_REMARKS_LOAD_FULFILLED: {
      return {
        ...state,
        manual_remarks: action.payload.data.data.manual_remarks,
        fetching: false,
      }
    }
    case actions.PAYROLL_REMARKS_RESET: {
      return {
        ...initialState,
      }
    }

    case actions.PAYROLL_SINGLE_REMARK_LOAD_REJECTED:
    case actions.AUTOMATIC_REMARKS_REJECTED:
    case actions.PAYROLL_MANUAL_REMARKS_LOAD_REJECTED:
    case actions.PAYROLL_REMARKS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...initialState,
        fetching: false,
      }
    }

    default:
      return state
  }
}
