import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from '../Table.module.css'

const Row = ({ children, error, className, clickable, ...props }) => (
  <tr
    className={classnames(
      className,
      error && Styles.errorTr,
      (clickable || props.onClick) && Styles.clickableRow,
    )}
    {...props}
  >
    {children}
  </tr>
)

Row.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Row
