export const PAYROLL_TRANSACTIONS_LOAD = 'PAYROLL_TRANSACTIONS_LOAD'
export const PAYROLL_TRANSACTIONS_LOAD_PENDING = 'PAYROLL_TRANSACTIONS_LOAD_PENDING'
export const PAYROLL_TRANSACTIONS_LOAD_FULFILLED = 'PAYROLL_TRANSACTIONS_LOAD_FULFILLED'
export const PAYROLL_TRANSACTIONS_LOAD_REJECTED = 'PAYROLL_TRANSACTIONS_LOAD_REJECTED'

export const PAYROLL_TRANSACTIONS_CREATE = 'PAYROLL_TRANSACTIONS_CREATE'
export const PAYROLL_TRANSACTIONS_CREATE_PENDING = 'PAYROLL_TRANSACTIONS_CREATE_PENDING'
export const PAYROLL_TRANSACTIONS_CREATE_FULFILLED = 'PAYROLL_TRANSACTIONS_CREATE_FULFILLED'
export const PAYROLL_TRANSACTIONS_CREATE_REJECTED = 'PAYROLL_TRANSACTIONS_CREATE_REJECTED'

export const PAYROLL_TRANSACTIONS_UPDATE = 'PAYROLL_TRANSACTIONS_UPDATE'
export const PAYROLL_TRANSACTIONS_UPDATE_PENDING = 'PAYROLL_TRANSACTIONS_UPDATE_PENDING'
export const PAYROLL_TRANSACTIONS_UPDATE_FULFILLED = 'PAYROLL_TRANSACTIONS_UPDATE_FULFILLED'
export const PAYROLL_TRANSACTIONS_UPDATE_REJECTED = 'PAYROLL_TRANSACTIONS_UPDATE_REJECTED'

export const PAYROLL_TRANSACTIONS_DELETE = 'PAYROLL_TRANSACTIONS_DELETE'
export const PAYROLL_TRANSACTIONS_DELETE_PENDING = 'PAYROLL_TRANSACTIONS_DELETE_PENDING'
export const PAYROLL_TRANSACTIONS_DELETE_FULFILLED = 'PAYROLL_TRANSACTIONS_DELETE_FULFILLED'
export const PAYROLL_TRANSACTIONS_DELETE_REJECTED = 'PAYROLL_TRANSACTIONS_DELETE_REJECTED'

export const EXPORT_PAYROLL_TRANSACTIONS = 'EXPORT_PAYROLL_TRANSACTIONS'
export const EXPORT_PAYROLL_TRANSACTIONS_PENDING = 'EXPORT_PAYROLL_TRANSACTIONS_PENDING'
export const EXPORT_PAYROLL_TRANSACTIONS_FULFILLED = 'EXPORT_PAYROLL_TRANSACTIONS_FULFILLED'
export const EXPORT_PAYROLL_TRANSACTIONS_REJECTED = 'EXPORT_PAYROLL_TRANSACTIONS_REJECTED'

export const BULK_UPDATE_TRANSACTIONS = 'BULK_UPDATE_TRANSACTIONS'
export const BULK_UPDATE_TRANSACTIONS_PENDING = 'BULK_UPDATE_TRANSACTIONS_PENDING'
export const BULK_UPDATE_TRANSACTIONS_FULFILLED = 'BULK_UPDATE_TRANSACTIONS_FULFILLED'
export const BULK_UPDATE_TRANSACTIONS_REJECTED = 'BULK_UPDATE_TRANSACTIONS_REJECTED'

export const RESET_PAYROLL_TRANSACTIONS = 'RESET_PAYROLL_TRANSACTIONS'
