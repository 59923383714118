import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  organization_configuration: {},
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.ENTITLEMENT_AUTO_CONVERT_LOAD_PENDING:
    case actions.ENTITLEMENT_AUTO_CONVERT_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ENTITLEMENT_AUTO_CONVERT_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ENTITLEMENT_AUTO_CONVERT_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ENTITLEMENT_AUTO_CONVERT_LOAD_REJECTED:
    case actions.ENTITLEMENT_AUTO_CONVERT_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
