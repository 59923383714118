import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { CheckListDataTypes } from 'types/sharedComponents'
import CheckItem from './CheckItem'

type extraData = {
  children: Array<number>
  isMulti: boolean
  parent: number
}

type CheckListPropsTypes = {
  childKey?: string
  labelKey?: string
  list: Array<CheckListDataTypes>
  selected: Array<number>
  singleValue?: boolean
  valueKey?: string
  onChange: (selected: Array<number> | number, data?: extraData) => void
}

const MultiLevelCheckList = ({
  list,
  singleValue,
  selected,
  childKey,
  labelKey,
  valueKey,
  onChange,
}: CheckListPropsTypes): JSX.Element => {
  const [listItems, setListItems] = useState<Array<CheckListDataTypes>>([])
  const [selectedIds, setSelectedIds] = useState<Array<number>>([])
  const [listShouldShown, setListShouldShown] = useState<Array<number>>([])

  type CheckDataType = { checked: boolean; value: number }

  let parent: number | null = null
  const onChangeValue = (data: CheckDataType, children?: Array<number>): void => {
    let vals = selectedIds.concat(selected)
    if (!parent) {
      parent = data.value
    }
    if (singleValue) {
      if (parent === data.value) {
        onChange(data.value, children ? { children, parent, isMulti: true } : undefined)
      }
    } else {
      const isExist = vals.find((val) => Number(val) === Number(data.value))
      if (!isEmpty(isExist)) {
        vals = vals.filter((val) => Number(val) !== Number(data.value))
      } else if (vals.includes(data.value) && !data.checked) {
        vals = [...vals, data.value]
      }
      vals = [...new Set([...vals, ...(children || [])])]
      setSelectedIds(vals)
      onChange(vals)
    }
  }

  const appendListShouldShown = (data: Array<number>, removedList: Array<number> = []): void => {
    const allList = [...data.filter((da) => !listShouldShown.includes(da)), ...listShouldShown]
    const filteredList = allList.filter((al) => !removedList.includes(al))
    const resultList = !isEmpty(removedList) ? filteredList : allList
    setListShouldShown(resultList)
  }

  useEffect(() => {
    if (!isEmpty(list)) setListItems(list)
  }, [list])

  useEffect(() => {
    if (selected) setSelectedIds(selected.filter(Boolean))
    if (parent) parent = null
  }, [selected])

  let resultlist: Array<number> = []
  const loadItems = (da: CheckListDataTypes): void => {
    if (selectedIds.includes(da.id)) {
      if (!resultlist.includes(da.id)) {
        resultlist = [...resultlist, da.id]
      }
      if (!isEmpty(da?.department_path)) {
        da?.department_path?.map((path) => {
          if (!resultlist.includes(path)) {
            resultlist = [...resultlist, path]
          }
          return null
        })
      }
      const notEmptyList = !isEmpty(da?.[childKey || ''])
      if (notEmptyList) {
        da?.[childKey || ''].map((d: CheckListDataTypes) => loadItems(d))
      }
      setListShouldShown(resultlist)
    }
  }

  useEffect(() => {
    listItems.map((d) => {
      return loadItems(d)
    })
  }, [])

  return (
    <>
      {listItems.map((d, i) => (
        <CheckItem
          key={i}
          data={d}
          onChange={onChangeValue}
          selected={selectedIds}
          childKey={childKey}
          labelKey={labelKey}
          valueKey={valueKey}
          singleValue={singleValue}
          listShouldShown={listShouldShown}
          appendListShouldShown={appendListShouldShown}
        />
      ))}
    </>
  )
}

export default MultiLevelCheckList
