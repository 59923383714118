const LeaveManagement = {
  // A
  annual: 'سنوية',
  annual_entitlement: 'الاستحقاق السنوي للإجازة',
  annual_leave_details: 'تفاصيل الإجازة السنوية',
  add_new_request: 'إضافة طلب جديد',
  available_balance: 'الرصيد الحالي',
  annual_leave_balance: 'رصيد الإجازة السنوية',
  attachment_url: 'مرفقات',
  approved: 'مؤكد',
  accommodation: 'سكن',
  applied_for: 'المدة',
  applying_for_request: 'تقديم طلب',
  advanced_vacation_salary: 'طلب راتب الإجازة مقدما',
  advanced_vacation_salary_v2: 'راتب الإجازة مقدما',
  applicable_days_values:
    'رصيد الموظف {{pending_balance}} يوم/أيام ,الأيام المطلوبة {{days}} يوم/أيام',
  annual_leave_entitlement: 'خيارات رصيد الاجازة السنوية',
  annual_leave_entitlement_description:
    'تعريف عدد أيام الإستحقاق السنوي للإجازة السنوية, والتي ستظهر في مرحلة إضافة موظف جديد.',
  applied_days: 'عدد الأيام',
  add_extensions_leave: 'تمديد الإجازة',
  all_years: 'جميع السنوات',
  // B
  balance: 'الرصيد المستحق الى نهاية السنة',
  balance_up_to_date: 'الرصيد الى اليوم',
  business_trip_request: 'طلب مهمة عمل',
  business_trip_details: 'تفاصيل مهمة العمل',
  balance_deducted: 'الرصيد المستقطع',
  booked_balance: 'الرصيد المحجوز',
  // C
  can_be_used: 'الرصيد المستحق الى اليوم',
  casual: 'إعتيادية',
  carried_forward: 'رصيد السنة السابقة',
  confirm_deleting_leave: 'سيؤدي هذا إلى حذف الإجازة ولن تظهر بعد الآن في النظام',
  // D
  days: 'أيام',
  days_used: 'الأيام المستخدمة',
  death_of_relative: 'وفاة أقارب',
  difference_in_days: 'فارق الأيام',
  delegation_request: 'طلب تفويض',
  delegation: 'تفويض',
  delegat_to: 'تفويض إلى',
  deducted_days: 'الرصيد المستقطع',
  deducted_balance: 'الرصيد المقتطع',
  // E
  employee_leaves_details: 'تفاصيل إجازات الموظف',
  employee_from: '{{employee_count}} من {{count}}',
  exit_re_entry_request: 'طلب خروج وعودة',
  exam: 'إختبار',
  emergency: 'طارئة',
  exit_re_entry_type: 'نوع الخروج والعودة',
  exit_re_entry_visa_request: 'طلب تأشيره خروج و عودة',
  exit_re_entry_visa: 'تأشيره خروج و عودة',
  expected_return: 'تاريخ العودة المتوقع',
  expected_return_date: 'التاريخ المتوقع للعودة',
  extending_leave: 'تمديد الإجازة',
  extensions_leave_request: 'طلب تمديد الإجازة',
  extend: 'تمديد',
  early_return: 'استئناف مبكر',
  // F
  from: 'من',
  file: 'ملف',
  // G
  // H
  // I
  ID: 'رقم الموظف',
  include_user: ' تقديم للموظف ',
  is_re_entry_exit: 'طلب خروج وعودة',
  include: 'إدراج',
  // J
  // K
  // L
  leaves_management: 'إدارة الإجازات',
  leaves_planning: 'مخطط الاجازات',
  leaves_history: 'بيان الإجازات',
  lorem_ipsum: '',
  leave_type: 'نوع الإجازة',
  departure_date: 'تاريخ الذهاب',
  leave_date: 'تاريخ الذهاب',
  leave_request: 'طلب إجازة',
  leave_duration: 'مدة الإجازة',
  leave_request_details: 'تفاصيل طلب الإجازة',
  leaves_used: 'الإجازة المستخدمة',
  leave_start_date: 'تاريخ الذهاب (ميلادي)',
  leave_resumption: 'تاريخ العودة',
  leave_end_date: ' تاريخ الانتهاء (ميلادي)',
  update_employee_balance: 'تحديث الأستحقاق السنوي للموظف',
  // M
  maternity: 'إجازة أمومة',
  marriage: 'إجازة زواج',
  multiple_leave: 'متعدد',
  manual_entry: 'الإدخال اليدوي',
  manual_entry_tooltip:
    'يمكنك تعديل الإستحقاق السنوي للموظف عن طريق الإضافة أو  الخصم على قيمة الإدخال اليدوي المعرفة مسبقا.',
  manual_entry_tooltip_examp_add:
    'مثال :<br />إضافة الى الرصيد : إستحقاق الموظف الى اليوم  هو 10 أيام الإدخال اليدوي هو 0 إستحاق الموظف المتوقع الى اليوم هو 12.<br /> لذلك ، ستكون قيمة الإدخال اليدوي كتعديل لمدة يومين.',
  manual_entry_tooltip_examp_deduct:
    'خصم : إستحقاق الموظف الى اليوم  هو 10 أيام الإدخال اليدوي هو 0.  إستحاق الموظف المتوقع الى اليوم هو 8  لذلك ، ستكون قيمة الإدخال اليدوي كتعديل -2.',

  // N
  number_of_days: 'عدد الأيام',
  new_business_trip_request: 'طلب مهمة عمل',
  // O
  other_leaves: 'إجازات أخرى',
  on_leave_for_this_month: 'في إجازة لهذا الشهر',
  no_leave_history: 'لاتوجد لديك سجلات للأجازات',
  no_scheduled_leaves: 'لا يبدو أن لديك أي إجازة مجدولة',
  // P
  planning: 'مخطط الاجازات',
  pending: 'في الانتظار',
  paternity: 'إجازة مولود جديد',
  // Q
  // R
  remaining_balance: 'الرصيد المتبقي',
  returning_date: 'تاريخ العودة',
  reason: 'السبب',
  require_ticket: 'طلب تذكرة',
  resumption_date: 'تاريخ العودة',
  resum: 'إستئناف',
  // S
  scheduled_leaves: 'الإجازات المجدولة',
  sick_leave: 'إجازة مرضية',
  sick: 'إجازة مرضية',
  single_leave: 'فردي',
  started_on: 'تاريخ البداية ',
  // T
  ticket_request: 'طلب تذكرة سفر',
  total_balance_up_to_end_of_year: 'إجمالي الرصيد المتوقع حتى نهاية السنة الحالية',
  total_booked: 'إجمالي الرصيد المحجوز',
  total_booked_tip:
    'إجمالي الرصيد المحجوز هو جميع أيام الإجازة السنوية المقدمة  في المستقبل ولم يتم خصمها بعد من رصيد الموظف.',
  total_days: 'إجمالي الأيام',
  there_is_no_other_leaves_requested: 'لا يوجد طلب إجازات أخرى قمت بتقديمه',
  total_type_leaves: 'مجموع الإجازات ال{{type}}',
  // U
  used: 'الرصيد المستخدم',
  used_balance: 'الرصيد المستخدم',
  up_to_date_balance: 'الرصيد المستحق إلى هذا اليوم',
  unpaid: 'إجازة غير مدفوعة',
  unpaid_leave_deduction: 'خصم رصيد الإجازة غير المدفوعة',
  // V
  // W
  weekend: 'نهاية اسبوع',
  week: 'أسبوع',
  // X
  // Y
  YEARLY: 'سنوي',
  year_end_balance: 'رصيد نهاية السنة الحالية',
  // Z
}

export default LeaveManagement
