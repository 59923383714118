export const DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD = 'DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_PENDING =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_PENDING'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_FULFILLED =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_FULFILLED'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_REJECTED =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_REJECTED'

export const DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_PENDING =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_PENDING'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_FULFILLED =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_FULFILLED'
export const DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_REJECTED =
  'DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_REJECTED'

export const DELAY_DEDICTION_POLICIES = 'DELAY_DEDICTION_POLICIES'
export const DELAY_DEDICTION_POLICIES_PENDING = 'DELAY_DEDICTION_POLICIES_PENDING'
export const DELAY_DEDICTION_POLICIES_FULFILLED = 'DELAY_DEDICTION_POLICIES_FULFILLED'
export const DELAY_DEDICTION_POLICIES_REJECTED = 'DELAY_DEDICTION_POLICIES_REJECTED'

export const DELAY_DEDICTION_POLICIES_LOAD = 'DELAY_DEDICTION_POLICIES_LOAD'
export const DELAY_DEDICTION_POLICIES_LOAD_PENDING = 'DELAY_DEDICTION_POLICIES_LOAD_PENDING'
export const DELAY_DEDICTION_POLICIES_LOAD_FULFILLED = 'DELAY_DEDICTION_POLICIES_LOAD_FULFILLED'
export const DELAY_DEDICTION_POLICIES_LOAD_REJECTED = 'DELAY_DEDICTION_POLICIES_LOAD_REJECTED'

export const DELAY_DEDICTION_POLICIES_CREATE = 'DELAY_DEDICTION_POLICIES_CREATE'
export const DELAY_DEDICTION_POLICIES_CREATE_PENDING = 'DELAY_DEDICTION_POLICIES_CREATE_PENDING'
export const DELAY_DEDICTION_POLICIES_CREATE_FULFILLED = 'DELAY_DEDICTION_POLICIES_CREATE_FULFILLED'
export const DELAY_DEDICTION_POLICIES_CREATE_REJECTED = 'DELAY_DEDICTION_POLICIES_CREATE_REJECTED'

export const DELAY_DEDICTION_POLICIES_UPDATE = 'DELAY_DEDICTION_POLICIES_UPDATE'
export const DELAY_DEDICTION_POLICIES_UPDATE_PENDING = 'DELAY_DEDICTION_POLICIES_UPDATE_PENDING'
export const DELAY_DEDICTION_POLICIES_UPDATE_FULFILLED = 'DELAY_DEDICTION_POLICIES_UPDATE_FULFILLED'
export const DELAY_DEDICTION_POLICIES_UPDATE_REJECTED = 'DELAY_DEDICTION_POLICIES_UPDATE_REJECTED'

export const DELAY_DEDICTION_POLICIES_DELETE = 'DELAY_DEDICTION_POLICIES_DELETE'
export const DELAY_DEDICTION_POLICIES_DELETE_PENDING = 'DELAY_DEDICTION_POLICIES_DELETE_PENDING'
export const DELAY_DEDICTION_POLICIES_DELETE_FULFILLED = 'DELAY_DEDICTION_POLICIES_DELETE_FULFILLED'
export const DELAY_DEDICTION_POLICIES_DELETE_REJECTED = 'DELAY_DEDICTION_POLICIES_DELETE_REJECTED'
