export const ONBOARDING_LEAVE_TYPES_LOAD = 'ONBOARDING_LEAVE_TYPES_LOAD'
export const ONBOARDING_LEAVE_TYPES_LOAD_PENDING = 'ONBOARDING_LEAVE_TYPES_LOAD_PENDING'
export const ONBOARDING_LEAVE_TYPES_LOAD_FULFILLED = 'ONBOARDING_LEAVE_TYPES_LOAD_FULFILLED'
export const ONBOARDING_LEAVE_TYPES_LOAD_REJECTED = 'ONBOARDING_LEAVE_TYPES_LOAD_REJECTED'

export const ONBOARDING_LEAVE_TYPES_POST = 'ONBOARDING_LEAVE_TYPES_POST'
export const ONBOARDING_LEAVE_TYPES_POST_PENDING = 'ONBOARDING_LEAVE_TYPES_POST_PENDING'
export const ONBOARDING_LEAVE_TYPES_POST_FULFILLED = 'ONBOARDING_LEAVE_TYPES_POST_FULFILLED'
export const ONBOARDING_LEAVE_TYPES_POST_REJECTED = 'ONBOARDING_LEAVE_TYPES_POST_REJECTED'

export const ONBOARDING_LEAVE_TYPES_PUT = 'ONBOARDING_LEAVE_TYPES_PUT'
export const ONBOARDING_LEAVE_TYPES_PUT_PENDING = 'ONBOARDING_LEAVE_TYPES_PUT_PENDING'
export const ONBOARDING_LEAVE_TYPES_PUT_FULFILLED = 'ONBOARDING_LEAVE_TYPES_PUT_FULFILLED'
export const ONBOARDING_LEAVE_TYPES_PUT_REJECTED = 'ONBOARDING_LEAVE_TYPES_PUT_REJECTED'

export const ONBOARDING_LEAVE_TYPES_DELETE = 'ONBOARDING_LEAVE_TYPES_DELETE'
export const ONBOARDING_LEAVE_TYPES_DELETE_PENDING = 'ONBOARDING_LEAVE_TYPES_DELETE_PENDING'
export const ONBOARDING_LEAVE_TYPES_DELETE_FULFILLED = 'ONBOARDING_LEAVE_TYPES_DELETE_FULFILLED'
export const ONBOARDING_LEAVE_TYPES_DELETE_REJECTED = 'ONBOARDING_LEAVE_TYPES_DELETE_REJECTED'

export const ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT = 'ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT'
export const ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_PENDING =
  'ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_PENDING'
export const ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_FULFILLED =
  'ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_FULFILLED'
export const ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_REJECTED =
  'ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_REJECTED'
