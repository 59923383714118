import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Button, Flex, Spacer } from '@jisr-hr/ds'
import CheckMarkIcon from 'assets/images/check-mark.png'
import blueSpinner from 'assets/images/blue-spinner.gif'
import WarnRedIcon from 'assets/images/warn-red.png'
import WarnIcon from 'assets/images/warn-yellow.png'
import { ReactComponent as CheckMarkIco } from 'assets/figma-icons/check-mark.svg'
import { ReactComponent as WarnIco } from 'assets/figma-icons/warn.svg'
import I18n from 'translations/i18n'
import { mixPanelAnalytics } from 'utils/mixpanel'

export const govtSheetData = ['wps_gosi_infos', 'mol_infos', 'muqeem_infos']

// list of bulk types moved to Assetmanager Service
export const assetmanagerService = [
  'attendance_logs',
  'assets',
  'family_details',
  'loans',
  'leaves',
  'employees',
  'leave_requests',
  'bupa_insurances',
  'salaries_packages',
  'other_financial_information',
  'muqeem_infos',
  'approved_overtimes',
  'standard_shifts',
  'attendance_configurations',
  'opening_balance',
  'shift_templates',
  'custom_fields',
  'addresses',
]

export const statusColor = {
  4: '#DC7300',
  3: '#2AAB0E',
  2: '#D13240',
  1: '#6F89A1',
  0: '#6F89A1',
}

export const statusLabel = {
  4: I18n.t('partially_updated'),
  3: I18n.t('imported_successfuly'),
  2: I18n.t('import_failed'),
  1: I18n.t('processing_data'),
  0: I18n.t('uploading'),
}

const StatusTd = ({ icon, label, value, metadata, reportFile, detail, s3_url }) => (
  <Flex itemsCenter>
    <Tooltip
      disabled={!detail}
      description={detail}
      placement="left"
      trigger={
        <div style={{ width: 14, height: 14 }}>
          <img
            src={icon}
            alt={label}
            style={{ width: '100%', height: '100%', marginBottom: 6 }}
          />
        </div>
      }
    />

    <span
      style={{
        marginInline: 5,
        color: `${statusColor[value]}`,
      }}
    >
      {label}
    </span>
    {![0, 1].includes(value) && (
      <>
        <div style={{ color: '#c4c4c4' }}>•</div>
        <Tooltip
          behavior="popover"
          trigger={
            <div>
              <Button
                label={I18n.t('view_details')}
                size="small"
                variant="ghost"
                styles={{ width: 'max-content' }}
                onClick={() =>
                  mixPanelAnalytics({
                    event_name: 'ImportDetails_Viewed',
                    properties: {
                      File_Title: metadata.fileTitle,
                      Status: label,
                    },
                  })
                }
              />
            </div>
          }
        >
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              color: 'var(--metallic-blue)',
              fontWeight: 500,
              marginBottom: 15,
            }}
          >
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
              <CheckMarkIco />
              <span style={{ marginInlineStart: 5 }}>
                <Trans
                  i18nKey="new_records_add"
                  components={{ bold: <strong /> }}
                  values={{ count: metadata.success_count || 0 }}
                />
              </span>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
              <WarnIco />
              <span style={{ marginInlineStart: 5 }}>
                <Trans
                  i18nKey="invalid_records"
                  components={{ bold: <strong /> }}
                  values={{ count: metadata.failed_count || 0 }}
                />
              </span>
            </li>
          </ul>
          {reportFile && (
            <a
              download
              href={reportFile}
            >
              <Button
                label={I18n.t('download_report_file')}
                size="small"
                style={{ width: '100%' }}
              />
            </a>
          )}
          {s3_url && [3, 4].includes(value) && (
            <>
              {reportFile && <Spacer height={8} />}
              <a
                download
                href={s3_url}
              >
                <Button
                  label={I18n.t('download_uploaded_data')}
                  size="small"
                  style={{ width: '100%' }}
                  variant="secondary"
                />
              </a>
            </>
          )}
        </Tooltip>
      </>
    )}
  </Flex>
)

export const handleRecordStatus = (
  statusValue,
  metadata,
  reportFile,
  detail,
  eventName,
  s3_url,
) => {
  switch (statusValue) {
    case 4:
      return (
        <StatusTd
          s3_url={s3_url}
          icon={WarnIcon}
          label={statusLabel[statusValue]}
          value={statusValue}
          metadata={metadata}
          reportFile={reportFile}
          detail={detail}
          eventName={eventName}
        />
      )
    case 3:
      return (
        <StatusTd
          s3_url={s3_url}
          icon={CheckMarkIcon}
          label={statusLabel[statusValue]}
          value={statusValue}
          metadata={metadata}
          detail={detail}
          eventName={eventName}
        />
      )
    case 2:
      return (
        <StatusTd
          s3_url={s3_url}
          icon={WarnRedIcon}
          label={statusLabel[statusValue]}
          value={statusValue}
          metadata={metadata}
          reportFile={reportFile}
          detail={detail}
          eventName={eventName}
        />
      )
    case 0:
    case 1:
      return (
        <StatusTd
          s3_url={s3_url}
          icon={blueSpinner}
          label={statusLabel[statusValue]}
          value={statusValue}
          eventName={eventName}
        />
      )
    default:
      return '-'
  }
}

StatusTd.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  metadata: PropTypes.shape(),
  reportFile: PropTypes.string,
  detail: PropTypes.string,
  s3_url: PropTypes.string,
}
