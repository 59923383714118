import React from 'react'
import onClickOutside from 'react-onclickoutside'

const HOC = (WrappedComponent) => {
  class MenuPopup extends React.Component {
    state = { active: false }

    handleOpen = () => {
      this.setState({ active: true })
    }

    handleOnClickOutside = () => {
      this.setState({ active: false })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          active={this.state.active}
          handleOpen={this.handleOpen}
          handleClose={() => this.setState({ active: false })}
        />
      )
    }
  }

  const clickOutsideConfig = {
    handleClickOutside: (instance) => instance.handleOnClickOutside,
  }

  return onClickOutside(MenuPopup, clickOutsideConfig)
}

export default HOC
