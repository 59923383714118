import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { Flex } from '@jisr-hr/ds'
import { DatePicker } from '@jisr-hr/ds-beta'
import { SelectField } from 'components/final-form'
import { addDays, addMonths, differenceInDays, format, subDays } from 'date-fns'
import useUpdateEffect from 'components/global/hooks/useUpdateEffect'

import Frame from '../../../ds/Frame'
import SingleDay from './components/SingleDay'
import MultipleDays from './components/MultipleDays'
import { overtimeTypes } from './helper'

import Styles from './OvertimeDuration.module.css'
import { useRequest } from '../../../RequestProvider'

const OvertimeDuration = (props) => {
  const { overtimeDetail } = props
  const { vars } = useRequest()
  const { change } = useForm()
  const {
    values: { overtime_type, initialOvertimeDates },
  } = useFormState()

  const [date, setDate] = React.useState([])

  const resetDateTime = (d) => new Date(d?.setHours(0, 0, 0, 0))

  const handleDate = (_, v) => {
    change('overtime_dates', [])
    change('multiple_shifts', [])
    change('initialOvertimeDates', undefined)
    change('initialMultipleShifts', undefined)
    setDate(v)
  }

  const handleBulkDates = () => {
    // ? Reset the time for Date due to differenceInDays depend on both date and time
    // ? Increase difference by one day because differenceInDays ignore one day
    const difference = differenceInDays(resetDateTime(date?.[1]), resetDateTime(date?.[0])) + 1

    const dates = Array.from({ length: difference }).map((_, i) =>
      format(addDays(date?.[0], i), 'yyyy-MM-dd'),
    )

    change('overtime_dates', dates)
  }

  useUpdateEffect(() => {
    if (overtime_type === 'bulk_overtime' && date.length === 2 && !initialOvertimeDates) {
      handleBulkDates()
    }
  }, [date])

  React.useEffect(() => {
    if (initialOvertimeDates?.length > 0) {
      setDate([
        new Date(initialOvertimeDates[0]),
        new Date(initialOvertimeDates[initialOvertimeDates.length - 1]),
      ])
    }
  }, [initialOvertimeDates])

  return (
    <Frame title={I18n.t('overtime_duration')}>
      <Flex
        flexCol
        className={Styles.container}
      >
        <div className="grid grid-cols-12 gap-2">
          <div className={overtime_type === 'bulk_overtime' ? 'col-span-6' : 'col-span-12'}>
            <SelectField
              label={I18n.t('type')}
              name="overtime_type"
              options={overtimeTypes}
              disabled={vars?.shiftView}
              onChange={() => {
                change('initialMultipleShifts', undefined)
                change('initialOvertimeDates', undefined)
                change('multiple_shifts', [])
                change('overtime_dates', [])
                change('overtime_logs_attributes', [
                  overtime_type === 'single_day' ? overtimeDetail : {},
                ])
              }}
            />
          </div>
          {overtime_type === 'bulk_overtime' && (
            <div className="col-span-6">
              <DatePicker
                calenderProps={{
                  type: 'range',
                  minDate: subDays(new Date(), 59),
                  maxDate: addMonths(new Date(), 3),
                }}
                label={I18n.t('date_range')}
                triggerType="input"
                onChange={handleDate}
                value={date.length === 2 ? date : undefined}
              />
            </div>
          )}
        </div>

        {overtime_type === 'single_day' && <SingleDay />}
        {['multiple_days', 'bulk_overtime'].includes(overtime_type) && (
          <MultipleDays handleDate={(d) => setDate([d])} />
        )}
      </Flex>
    </Frame>
  )
}

OvertimeDuration.propTypes = {
  overtimeDetail: PropTypes.shape(),
}

export default OvertimeDuration
