import { useEffect } from 'react'
import { Select } from '@jisr-hr/design-system'
import { Spacer, Typography } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'utils/hooks'
import { updateOrganization } from 'redux/organizationOnborading/organization/actionCreators'
import {
  industriesLoad,
  providersLoad,
} from 'redux/organizationOnborading/industries/actionCreators'
import I18n from 'translations/i18n'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { IndustryType, StepsTypes } from '../../../types/onboardingTypes'
import { providersTypes } from '../CrAndIndustry/CrAndIndustry'

import IndustriesProviders from '../../components/Industries'
import StepTemplate from '../../../components/StepTemplate'

type IndustriesType = {
  industries: {
    industries: IndustryType[]
  }
}

const Industries = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const industries = useSelector((s: IndustriesType) => s.industries?.industries)
  const { organization, fetching } = useSelector((s) => s.organization)

  const { point_of_sale_provider, accounting_system_provider, medical_insurance_provider } =
    organization

  const is_Accointing_and_medical_exist = !(
    accounting_system_provider?.id && medical_insurance_provider?.id
  )
  const is_point_of_sale_provider_exist =
    ['Food and beverages', 'Retail, Wholesale and Distribution'].includes(
      organization?.industry?.name_en,
    ) && !point_of_sale_provider?.id

  const onChange = ({ id }: { id: number }): void => {
    dispatch(updateOrganization({ industry_id: id }))
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'industry' })

  useEffect(() => {
    dispatch(industriesLoad())
    providersTypes.map((type) => dispatch(providersLoad(type)))
  }, [])

  return (
    <StepTemplate
      fetching={fetching}
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={
        !organization.industry?.id ||
        is_Accointing_and_medical_exist ||
        is_point_of_sale_provider_exist
      }
    >
      <Typography
        variant="interface/default/m"
        text={I18n.t('what_industries_Savola_description', {
          name: organization.name_i18n,
        })}
      />
      <Spacer height="16px" />
      <Select
        style={{ maxWidth: 414 }}
        onChange={onChange}
        getOptionValue={(industry: IndustryType): number => industry?.id}
        getOptionLabel={(industry: IndustryType): string => industry?.name_i18n}
        options={industries}
        value={industries.find(
          (industry: IndustryType) => industry.id === organization?.industry?.id,
        )}
        maxMenuHeight={170}
      />
      <Spacer height={16} />
      <IndustriesProviders />
    </StepTemplate>
  )
}

export default Industries
