const Webhooks = {
  // A
  authentication: 'Authentication',
  api_key_auth: 'API Key',
  add_header: 'Add Header',
  add_to: 'Add to',
  active_webhook: 'Active',
  activation_confirm_title: 'Activate Webhook',
  activation_confirm_message: 'Are you sure you want to activate "<bold>{{name}}</bold>"?',
  auth_error: 'Authentication fields cannot be empty',

  // B
  basic_auth: 'Basic Authentication',
  bearer_auth: 'Bearer Authentication',

  // C
  create_webhook: 'Create Webhook',
  custom_header_error: 'Either header name or value is empty for all custom headers.',
  created_at_webhook: 'Created At',

  // D
  delete_webhook: 'Delete Webhook',
  delete_header: 'Delete Header',
  delete_webhook_text: 'Are you sure you want to delete “<bold>{{name}}</bold>” webhook ?',
  delete_header_text: 'Are you sure you want to delete “<bold>{{name}}</bold>” header ?',
  description_placeholder: 'This a long description for the webhook.',
  deactivation_confirm_title: 'Deactivate Webhook',
  deactivation_confirm_message: 'Are you sure you want to deactivate "<bold>{{name}}</bold>"?',
  // E
  empty_list_title: 'Add your first Webhook',
  empty_list_subtext: 'Receive an HTTP request when ever an event is triggered',
  end_point_url: 'End-Point URL',
  endpoint: 'End point',
  event_subscription: 'Event Subscription',

  // H
  header_webhook: 'Header',
  headers: 'Headers',
  headers_helper_text: 'You can add up to 5 headers.',
  header_name: 'Header Name',
  header_name_placeholder: 'Your header name',
  header_value: 'Value',
  header_value_placeholder: 'Your header value',

  // N
  none_auth: 'None',

  // Q
  query_params: 'Query Param',

  // S
  selected_count: 'Selected',
  select_atleast_one_event: 'Select at least one of events',

  // T
  token: 'Token',
  token_value: 'Token value',
  try_your_webhook: 'Try your webhook!',
  try_it: 'Try it',
  test_success: 'Webhook connection is working successfully!',
  test_fail: 'The webhook connection failed to connect to your URL.',

  // U
  url: 'URL',
  update_webhook: 'Update Webhook',
  user: 'User',

  // W
  webhooks: 'Webhooks',
  webhook_info: 'Webhook Info',
  webhook_name: 'Webhook Name',
  webhook_name_placeholder: 'Your webhook name',
  webhook_format_text: 'We currently support JSON format only.',
}

export default Webhooks
