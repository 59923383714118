import { useState } from 'react'
import { Modal, Spacer, Typography } from '@jisr-hr/ds'
import { Select } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'

type GenericPopupProps<T> = {
  open: boolean
  title: string
  description: string
  submitLabel: string
  cancelLabel: string
  selectPlaceholder: string
  listEntities: T[]
  fetching?: boolean
  onBack: () => void
  onRemoveEntity: (newEntity: T) => void
  labelKey: string
}

const GenericPopup = <T,>({
  open,
  title,
  fetching,
  listEntities,
  description,
  cancelLabel,
  submitLabel,
  selectPlaceholder,
  onBack,
  labelKey,
  onRemoveEntity,
}: GenericPopupProps<T>): JSX.Element => {
  const [updatedEntity, setUpdatedEntity] = useState<T | null>(null)

  const handleOnClose = (): void => {
    setUpdatedEntity(null)
    onBack()
  }

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      size="medium"
      variant="danger"
      header={{
        title: i18n.t(title),
        description,
        leadingSpace: { icon: 'switch-horizontal-01' },
      }}
      footer={{
        submit: {
          label: i18n.t(submitLabel),
          disabled: updatedEntity === null || fetching,
          onClick: (): void => {
            if (updatedEntity) onRemoveEntity(updatedEntity)
          },
        },
        cancel: {
          label: i18n.t(cancelLabel),
          onClick: handleOnClose,
        },
      }}
    >
      <Typography
        variant="body-new/medium"
        text={i18n.t(selectPlaceholder)}
      />
      <Spacer height={8} />
      <Select
        maxMenuHeight={185}
        value={updatedEntity ?? undefined}
        options={listEntities}
        data-testid="generic-popup-select-field"
        placeholder={i18n.t(selectPlaceholder)}
        labelKey={labelKey}
        valueKey="id"
        onChange={(value: T | null): void => setUpdatedEntity(value)}
      />
    </Modal>
  )
}

export default GenericPopup
