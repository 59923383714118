export const ONBOARDING_COUNTRIES_LOAD = 'ONBOARDING_COUNTRIES_LOAD'
export const ONBOARDING_COUNTRIES_LOAD_PENDING = 'ONBOARDING_COUNTRIES_LOAD_PENDING'
export const ONBOARDING_COUNTRIES_LOAD_FULFILLED = 'ONBOARDING_COUNTRIES_LOAD_FULFILLED'
export const ONBOARDING_COUNTRIES_LOAD_REJECTED = 'ONBOARDING_COUNTRIES_LOAD_REJECTED'

export const ONBOARDING_RIGIONS_LOAD = 'ONBOARDING_RIGIONS_LOAD'
export const ONBOARDING_RIGIONS_LOAD_PENDING = 'ONBOARDING_RIGIONS_LOAD_PENDING'
export const ONBOARDING_RIGIONS_LOAD_FULFILLED = 'ONBOARDING_RIGIONS_LOAD_FULFILLED'
export const ONBOARDING_RIGIONS_LOAD_REJECTED = 'ONBOARDING_RIGIONS_LOAD_REJECTED'

export const ONBOARDING_REGIONS_CITIES_LOAD = 'ONBOARDING_REGIONS_CITIES_LOAD'
export const ONBOARDING_REGIONS_CITIES_LOAD_PENDING = 'ONBOARDING_REGIONS_CITIES_LOAD_PENDING'
export const ONBOARDING_REGIONS_CITIES_LOAD_FULFILLED = 'ONBOARDING_REGIONS_CITIES_LOAD_FULFILLED'
export const ONBOARDING_REGIONS_CITIES_LOAD_REJECTED = 'ONBOARDING_REGIONS_CITIES_LOAD_REJECTED'

export const ONBOARDING_COUNTRY_CITIES_LOAD = 'ONBOARDING_COUNTRY_CITIES_LOAD'
export const ONBOARDING_COUNTRY_CITIES_LOAD_PENDING = 'ONBOARDING_COUNTRY_CITIES_LOAD_PENDING'
export const ONBOARDING_COUNTRY_CITIES_LOAD_FULFILLED = 'ONBOARDING_COUNTRY_CITIES_LOAD_FULFILLED'
export const ONBOARDING_COUNTRY_CITIES_LOAD_REJECTED = 'ONBOARDING_COUNTRY_CITIES_LOAD_REJECTED'
