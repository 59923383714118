import { useMemo, useState, useCallback, memo } from 'react'
import { Button, Containers, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { SwitchField, SelectField, DatePickerDSField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import { required } from 'components/global/form/FormValidations'
import DSIcon from 'components/DSIcon'
import useBenefitsManagementPermissions from 'containers/authorised/BenefitsManagement/permissions/useBenefitsManagementPermissions'
import {
  InitiateFlightTicketPolicyRequestTypes,
  TravelDestinationType,
} from '../../../types/flightTicketRequest'
import styles from './styles.module.css'

type FlightTicketRequestFormPropsType = {
  flightTicketPolicyData?: InitiateFlightTicketPolicyRequestTypes
  employeeNotEnrolledErrorMsgI18n?: string
  travelDestination?: TravelDestinationType[]
}

const FlightTicketRequestForm = ({
  flightTicketPolicyData,
  employeeNotEnrolledErrorMsgI18n,
  travelDestination,
}: FlightTicketRequestFormPropsType): JSX.Element => {
  const { isAnnualFlightTicketsEnabled } = useBenefitsManagementPermissions()
  const [showException, setShowException] = useState(false)
  const [showFlightClassException, setShowFlightClassException] = useState(false)

  const { values } = useFormState()
  const { change } = useForm()

  const getFlightTicketDestination = useMemo(() => {
    if (employeeNotEnrolledErrorMsgI18n) {
      return (
        travelDestination?.map((destination) => ({
          id: destination?.id,
          label: destination?.name_i18n,
        })) || []
      )
    }

    return (
      flightTicketPolicyData?.flight_ticket_policy_destinations
        ?.sort((a, b) => {
          if (a.is_exception === b.is_exception) {
            return 0
          }
          return a.is_exception ? 1 : -1
        })
        ?.map((destination) => ({
          id: destination?.travel_destination?.id,
          label: destination?.travel_destination?.name_i18n,
        })) || []
    )
  }, [employeeNotEnrolledErrorMsgI18n, travelDestination, flightTicketPolicyData])

  const getFlightClasses = useMemo(
    () =>
      flightTicketPolicyData?.flight_classes
        ?.sort((a, b) => {
          if (a.is_exception === b.is_exception) {
            return 0
          }
          return a.is_exception ? 1 : -1
        })
        .map((flightClass) => ({
          id: Object.keys(flightClass)?.[0],
          label: Object.values(flightClass)?.[0],
        })),
    [flightTicketPolicyData],
  )

  const validate = useCallback(
    (val: string, defValues: Record<string, any>): string | undefined => {
      if (defValues?.round_trip) {
        return required(val)
      }
      return undefined
    },
    [],
  )

  const navigateToPolicyPage = useCallback((): void => {
    const url = `${window.location.origin}/#/benefits_management/annualFlightTicket/policies/${flightTicketPolicyData?.id}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [flightTicketPolicyData?.id])

  const handleDestinationChange = useCallback(
    (val: number): void => {
      const selectedDestination = flightTicketPolicyData?.flight_ticket_policy_destinations.find(
        (destination) => destination.travel_destination.id === val,
      )

      setShowException(!!selectedDestination?.is_exception)
    },
    [flightTicketPolicyData],
  )

  const handleRoundTripChange = useCallback(
    (val): void => {
      if (!val) {
        change('flight_request_returning_date', '')
      }
    },
    [change],
  )

  const handleCabinClassChange = useCallback(
    (val: string): void => {
      const selectedDestination = flightTicketPolicyData?.flight_classes.find(
        (flightClass) => Object.keys(flightClass)?.[0] === val,
      )

      if (selectedDestination?.is_exception) {
        setShowFlightClassException(true)
      } else {
        setShowFlightClassException(false)
      }
    },
    [flightTicketPolicyData],
  )

  return (
    <Containers
      color="white"
      borderRadius="sm"
      border
      width="100%"
      height="auto"
      styles={{ padding: 12, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      <Typography
        text={I18n.t('label.flight_details')}
        variant="title-1"
      />

      {!employeeNotEnrolledErrorMsgI18n ? (
        <Flex
          itemsCenter
          className="gap-1.5"
        >
          <DSIcon
            name="file-06"
            size="md"
          />
          <Typography
            text={flightTicketPolicyData?.name_i18n}
            variant="body-new/regular"
          />

          {isAnnualFlightTicketsEnabled && (
            <Button
              leadingIcon="share-04"
              variant="ghost"
              color="neutral"
              iconOnly
              size="small"
              onClick={navigateToPolicyPage}
            />
          )}
        </Flex>
      ) : (
        <></>
        // * this commented code will be needed in the upcoming work
        // <Flex className="gap-1.5">
        //   <DSIcon
        //     name="file-06"
        //     size="md"
        //     color="var(--ds-core-color-error-800)"
        //   />
        //   <Typography
        //     text={employeeNotEnrolledErrorMsgI18n}
        //     variant="body-new/regular"
        //     textColor="color/fg/danger/default"
        //   />
        // </Flex>
      )}

      <Flex className="gap-3">
        <Flex
          flexCol
          style={{ gap: 6, flexGrow: 3 }}
        >
          <Typography
            variant="interface/medium/sm"
            text={I18n.t('label.destination')}
          />
          <SelectField
            // @ts-expect-error // select field needs to be migrated to TS
            options={getFlightTicketDestination}
            valueKey="id"
            labelKey="label"
            name="flight_destinations"
            validate={required}
            testId="flight_destinations"
            placeholder={I18n.t('placeholder.select_destination')}
            status={showException && 'warning'}
            statusMessage={showException && I18n.t('communication.policy_exception')}
            onChange={handleDestinationChange}
          />
        </Flex>
        {(!flightTicketPolicyData?.only_one_way || flightTicketPolicyData.exception_allowed) && (
          <div
            className={`flex flex-col flex-grow-1 justify-center  h-[80px]  ${styles.switchContainer}`}
          >
            <SwitchField
              name="round_trip"
              label={I18n.t('label.round_trip')}
              description={
                flightTicketPolicyData?.exception_allowed
                  ? I18n.t('communication.policy_exception')
                  : ''
              }
              id="covers_round_trips"
              testId="round_trip"
              onChange={handleRoundTripChange}
            />
          </div>
        )}
      </Flex>
      <Flex className="gap-3">
        <div className="flex-1">
          <DatePickerDSField
            calenderProps={{
              minDate: new Date(),
            }}
            validate={required}
            name="flight_request_departure_date"
            label={I18n.t('departure_date')}
            triggerType="input"
            textFieldProps={{
              placeholder: I18n.t('placeholder.select_date'),
            }}
          />
        </div>

        {(!flightTicketPolicyData?.only_one_way || flightTicketPolicyData.exception_allowed) && (
          <div className="flex-1">
            <DatePickerDSField
              calenderProps={{
                minDate: values.flight_request_departure_date
                  ? new Date(values.flight_request_departure_date)
                  : new Date(),
              }}
              validate={validate}
              name="flight_request_returning_date"
              label={I18n.t('return_date')}
              triggerType="input"
              textFieldProps={{
                disabled: !values.round_trip,
                placeholder: I18n.t('placeholder.select_date'),
              }}
            />
          </div>
        )}
      </Flex>
      {!employeeNotEnrolledErrorMsgI18n && (
        <Flex
          flexCol
          style={{ gap: 6, flexGrow: 3 }}
        >
          <Typography
            variant="interface/medium/sm"
            text={I18n.t('label.cabin_class')}
          />
          <SelectField
            // @ts-expect-error // select field needs to be migrated to TS
            options={getFlightClasses}
            valueKey="id"
            labelKey="label"
            name="flight_cabin_class"
            validate={required}
            testId="flight_cabin_class"
            placeholder={I18n.t('label.cabin_class')}
            onChange={handleCabinClassChange}
            status={showFlightClassException && 'warning'}
            statusMessage={showFlightClassException && I18n.t('communication.policy_exception')}
          />
        </Flex>
      )}
    </Containers>
  )
}

export default memo(FlightTicketRequestForm)
