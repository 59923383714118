import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { Flex, Typography } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'

import Frame from '../../ds/Frame'
import FingerprintDetails from './FingerprintDetails'

import Styles from './ProposedCorrection.module.css'

const ProposedCorrection = ({ attendance_logs }) => (
  <Frame title={I18n.t('proposed_correction')}>
    <Flex
      flexCol
      className={Styles.container}
    >
      {attendance_logs?.map((log, i) => (
        <Flex
          key={i}
          className={classNames(
            Styles.punchDetail,
            log?.punch_type === 'in_punch' ? Styles.green : undefined,
            log?.punch_type === 'out_punch' ? Styles.orange : undefined,
          )}
        >
          <Grid xs={2}>
            <Typography
              text={replaceAmPm(log?.punch_time)}
              style={{ color: 'var(--color-base-colors-black-500)' }}
              variant="subheading"
            />
          </Grid>
          <Grid xs={8}>
            <FingerprintDetails log={log} />
          </Grid>
        </Flex>
      ))}
    </Flex>
  </Frame>
)

ProposedCorrection.propTypes = {
  attendance_logs: PropTypes.arrayOf(Object),
}

export default ProposedCorrection
