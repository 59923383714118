import { Spacer, Badge, Flex, Typography, Radio, FeaturedIcon } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useContext, useEffect, useState } from 'react'
import {
  laborawConfigurationsCreate,
  laborawConfigurationsUpdate,
} from 'redux/organizationOnborading/laborLawConfigurations/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'
import ConfirmModal from 'containers/authorised/setting/v2/components/ConfirmModal'
import { OnboardingContext } from '../../context'
import StepTemplate from '../StepTemplate'
import { StepsTypes } from '../../types/onboardingTypes'

import styles from './styles.module.css'

type ConfigurationType = {
  actionType: string
  badgeColor: 'green' | 'primary'
  description: string
  icon: string
  isFaster?: boolean
  title: string
  viewConfiguration: string
}

type ConfigType = 'faster_setup' | 'more_customized'

const configuration: ConfigurationType[] = [
  {
    icon: 'feather',
    title: 'default_settings',
    actionType: 'faster_setup',
    badgeColor: 'green',
    description: 'saudi_labor_law',
    viewConfiguration: 'view_the_applied_settings',
    isFaster: true,
  },
  {
    icon: 'edit-05',
    title: 'customized_settings',
    actionType: 'more_customized',
    badgeColor: 'primary',
    description: 'customized_settings_title',
    viewConfiguration: 'des_customized',
  },
]

const pdfLink = isRtl
  ? 'https://jisr-public.s3.me-south-1.amazonaws.com/documents/%D8%AF%D9%84%D9%8A%D9%84+%D9%82%D8%A7%D9%86%D9%88%D9%86+%D8%A7%D9%84%D8%B9%D9%85%D9%84+-+%D8%B9%D8%B1%D8%A8%D9%8A.pdf'
  : 'https://jisr-public.s3.me-south-1.amazonaws.com/documents/Labor+Law+Policies+Configurations+-+English.pdf'

const ConfigurationTemplate = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const fetching = useSelector(
    (s: { laborLawConfigurations: { fetching: boolean } }) => s.laborLawConfigurations.fetching,
  )
  const [configType, setConfigType] = useState<ConfigType | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { is_custom_labor_law_configurations, new_onboarding_payroll } = useSelector(
    ({ organization }) => organization?.organization?.metadata,
  )
  const isBoolean = typeof is_custom_labor_law_configurations === 'boolean'
  const { org } = useContext(OnboardingContext)

  const actions = {
    faster_setup: async (setLocaleStorage: boolean): Promise<void> => {
      if (!setLocaleStorage) {
        if (is_custom_labor_law_configurations || !isBoolean) {
          await dispatch(laborawConfigurationsCreate())
        }
        await goNextStep?.(7)
        await dispatch(organizationInfo())
        localStorage.setItem(`payroll_setup_step_${org.id}`, new_onboarding_payroll ? '1' : '3')
      }
    },
    more_customized: async (setLocaleStorage: boolean): Promise<void> => {
      if (!setLocaleStorage) {
        if (!is_custom_labor_law_configurations || !isBoolean) {
          await dispatch(laborawConfigurationsUpdate())
        }
        await goNextStep?.(2)
        await dispatch(organizationInfo())
        localStorage.setItem(`payroll_setup_step_${org.id}`, '1')
      }
    },
  }

  const onChangeConfig = (e: ConfigType): void => {
    setConfigType(e)
    actions[e](true)
  }

  const goToNextStep = (): void => {
    if (configType) {
      actions[configType](false)
    }
  }

  useEffect(() => {
    if (typeof is_custom_labor_law_configurations === 'boolean') {
      setConfigType(is_custom_labor_law_configurations ? 'more_customized' : 'faster_setup')
    }
  }, [is_custom_labor_law_configurations])

  const handleCancel = (): void => {
    setIsOpen(false)
  }

  const handleConfirm = (): void => {
    if (configType === 'faster_setup' && is_custom_labor_law_configurations) {
      setIsOpen(true)
    } else {
      goToNextStep()
    }
  }
  return (
    <StepTemplate
      goNextStep={handleConfirm}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!configType}
    >
      {fetching && <JisrLoader absolute />}
      <div style={{ maxWidth: 768, minWidth: 570 }}>
        <Typography
          variant="heading"
          text={i18n.t('leaves_and_payroll_configurations')}
        />

        <Spacer height={8} />
        <Typography
          variant="interface/default/sm"
          text={i18n.t('preferred_settings')}
        />
        <Spacer height={16} />

        <Flex
          flexCol
          style={{ gap: 60 }}
        >
          {configuration.map((config, i) => (
            <div
              key={i}
              className={styles.content}
            >
              <div>
                <Flex
                  justifyBetween
                  itemsCenter
                  className={styles.headerContent}
                >
                  <Flex itemsCenter>
                    <FeaturedIcon
                      iconName={config.icon}
                      colorType="brand"
                    />
                    <Spacer width={16} />
                    <Typography
                      style={{ color: 'var(--color-base-colors-grey-600)' }}
                      text={i18n.t(config.title)}
                    />
                  </Flex>
                  <Radio
                    active={configType === config.actionType}
                    value={config.actionType}
                    onChange={(e): void => onChangeConfig(e.target.value as ConfigType)}
                  />
                </Flex>
                <div className={styles.line} />
              </div>

              <div style={{ padding: 16 }}>
                <Flex justifyBetween>
                  <Typography
                    variant="subtitle-1"
                    text={i18n.t(config.description, {
                      app_name: i18n.t('jisr_translation_name'),
                    })}
                    style={{ color: 'var(--color-base-colors-grey-600)' }}
                  />

                  <Badge
                    label={i18n.t(config.actionType)}
                    color={config.badgeColor}
                    variant="tinted"
                  />
                </Flex>
                <Spacer height={12} />
                <Typography
                  variant="subtitle-1"
                  style={{ color: 'var(--color-base-colors-grey-600)' }}
                  text={
                    <>
                      {i18n.t(config.viewConfiguration)}
                      &nbsp;
                      {config.isFaster && (
                        <a
                          href={pdfLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            variant="subtitle-1"
                            text={i18n.t('click_here_onboard')}
                          />
                        </a>
                      )}
                    </>
                  }
                />
              </div>
            </div>
          ))}
        </Flex>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={goToNextStep}
        message={i18n.t('saudi_labor_des')}
        title="saudi_labor_title"
        labelConfirm="confirm"
        labelCancel="cancel"
        variant="warning"
      />
    </StepTemplate>
  )
}

export default ConfigurationTemplate
