import { AutomationFormData } from 'containers/authorised/ATS/organisms/AutomationEditForm/AutomationEditForm'
import { cvFileFields } from 'redux/ATS/CandidateView/actions'
import { PaginationTypes } from 'types/sharedResponse'

export const GET_JOB_APPLICANTS = 'GET_JOB_APPLICANTS'
export const GET_JOB_APPLICANTS_PENDING = 'GET_JOB_APPLICANTS_PENDING'
export const GET_JOB_APPLICANTS_FULFILLED = 'GET_JOB_APPLICANTS_FULFILLED'
export const GET_JOB_APPLICANTS_REJECTED = 'GET_JOB_APPLICANTS_REJECTED'

export const CREATE_JOB_APPLICANT = 'CREATE_JOB_APPLICANT'
export const CREATE_JOB_APPLICANT_PENDING = 'CREATE_JOB_APPLICANT_PENDING'
export const CREATE_JOB_APPLICANT_FULFILLED = 'CREATE_JOB_APPLICANT_FULFILLED'
export const CREATE_JOB_APPLICANT_REJECTED = 'CREATE_JOB_APPLICANT_REJECTED'

export const GET_CANDIDATE_FILTER = 'GET_CANDIDATE_FILTER'
export const GET_CANDIDATE_FILTER_PENDING = 'GET_CANDIDATE_FILTER_PENDING'
export const GET_CANDIDATE_FILTER_FULFILLED = 'GET_CANDIDATE_FILTER_FULFILLED'
export const GET_CANDIDATE_FILTER_REJECTED = 'GET_CANDIDATE_FILTER_REJECTED'

type CandidateFilterNationality = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
}

type CandidateFilterCountry = {
  phone_code: string
} & CandidateFilterNationality

export type CandidateFilterScreening = {
  id: number
  title: string
  options: {
    id: number
    title: string
    options: []
  }[]
}

export type FilterData = {
  name: string
  id: number | string
}

export type CandidateFilterScreeningAPI = {
  id: number
  title: string
  question_type: string
  options: string[]
}

export type CandidateFilter = {
  nationality: CandidateFilterNationality[]
  country: CandidateFilterCountry[]
  job_application_source: FilterData[]
  rating: FilterData[]
  screening_questions: CandidateFilterScreening[]
  current_title: FilterData[]
}

export type JobApplication = {
  id: number
  created_at: string
  updated_at: string
  candidate_id: number
  job_id: number
  job_workflow_stage_id: number
  organization_id: number
}

export type CreateJobApplicationResponse = {
  data: {
    job_application: JobApplication
    message: string
  }
  success?: boolean
  statusCode?: number | null
}

export type JobApplicantCandidate = {
  id: number
  name_ar: string
  name_en: string
  name_i18n: string
  current_role?: string
  cv_file_fields: cvFileFields
  comment_count?: number
  status?: string
  total_score?: number
  job_application: {
    id: number
    source: string
    is_auto_reject?: boolean
    source_i18n: string
    created_at: string
    updated_at: string
  }
  job_application_ids: string[]
}

export type CandidatesData = {
  id?: number
  name_ar?: string
  name_en?: string
  name_i18n?: string
  current_role?: string
  status?: string
  automation?: AutomationFormData
  automation_id?: number
  automation_enabled?: boolean
  job_application?: {
    id: number
    is_auto_reject: boolean
    created_at: string
    updated_at: string
    source: string
    job_workflow_stage: {
      id: number
      stage_name: string
      stage_name_i18n: string
    }
  }
  total_score?: number
  count?: number
  stage_id?: number
  stage_name?: string
  stage_name_i18n?: string
  job_application_ids?: string[]
  candidates?: JobApplicantCandidate[]
}

export type JobApplicant = {
  candidates_data: CandidatesData[]
  all_candidate_ids: number[]
  pagination?: PaginationTypes
}

export type JobApplicantResponse = {
  data: JobApplicant
  success: boolean
  statusCode: number
}

export type JobApplicantScreeningRequest = {
  id: number
  title: string
  options: string[]
}

export type JobApplicantFilterRequest = {
  country_id?: number[]
  nationality_id?: number[]
  search_by_name?: string
  resume?: boolean
  job_application_source?: string
  rating?: string[]
  current_role?: string[]
  screening_questions?: JobApplicantScreeningRequest[]
}

export type JobApplicantRequest = {
  search?: string
  page?: number
  per_page?: number
  filter?: JobApplicantFilterRequest
  sort?: {
    [key: string]: string
  }
  view_type: 'kanban_view' | 'list_view'
}
