export const ADD_SAUDI = 'ADD_SAUDI'
export const ADD_SAUDI_PENDING = 'ADD_SAUDI_PENDING'
export const ADD_SAUDI_FULFILLED = 'ADD_SAUDI_FULFILLED'
export const ADD_SAUDI_REJECTED = 'ADD_SAUDI_REJECTED'

export const UPLOAD_SAUDI_CONTRACT = 'UPLOAD_SAUDI_CONTRACT'
export const UPLOAD_SAUDI_CONTRACT_PENDING = 'UPLOAD_SAUDI_CONTRACT_PENDING'
export const UPLOAD_SAUDI_CONTRACT_FULFILLED = 'UPLOAD_SAUDI_CONTRACT_FULFILLED'
export const UPLOAD_SAUDI_CONTRACT_REJECTED = 'UPLOAD_SAUDI_CONTRACT_REJECTED'

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'
