import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  asset_types: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function assetTypeReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let assetTypes = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'ASSET_TYPES_LOAD_PENDING':
    case 'ASSET_TYPE_CREATE_PENDING':
    case 'ASSET_TYPE_UPDATE_PENDING':
    case 'ASSET_TYPE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'ASSET_TYPES_LOAD_REJECTED':
    case 'ASSET_TYPE_CREATE_REJECTED':
    case 'ASSET_TYPE_UPDATE_REJECTED':
    case 'ASSET_TYPE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ASSET_TYPES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ASSET_TYPE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      assetTypes = [...state.asset_types]
      newRecord = payload.data.data.asset_type
      changes = {
        asset_types: [...assetTypes, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ASSET_TYPE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      assetTypes = [...state.asset_types]
      updatedRecord = payload.data.data.asset_type
      changes = {
        asset_types: updateOneListOfRecords(assetTypes, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ASSET_TYPE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      assetTypes = [...state.asset_types]
      return {
        ...state,
        asset_types: removeOneListOfRecords(assetTypes, payload.id),
        error: null,
        fetching: false,
      }

    case 'ASSET_TYPE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'ASSET_TYPE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
