export const ONBOARDING_HELP_CENTER = 'ONBOARDING_HELP_CENTER'
export const ONBOARDING_HELP_CENTER_PENDING = 'ONBOARDING_HELP_CENTER_PENDING'
export const ONBOARDING_HELP_CENTER_FULFILLED = 'ONBOARDING_HELP_CENTER_FULFILLED'
export const ONBOARDING_HELP_CENTER_REJECTED = 'ONBOARDING_HELP_CENTER_REJECTED'

export const ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT = 'ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT'
export const ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_PENDING =
  'ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_PENDING'
export const ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_FULFILLED =
  'ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_FULFILLED'
export const ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_REJECTED =
  'ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_REJECTED'

export const ONBOARDING_HELP_CENTER_KEY = 'ONBOARDING_HELP_CENTER_KEY'
