import { useEffect } from 'react'
import { Typography, Switch, Banner } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import { Trans } from 'react-i18next'
import cn from 'classnames'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  employeeNotificationsSettingLoad,
  employeeNotificationsSettingUpdate,
} from 'redux/appNotifications/NotificationsSetting/actionCreators'
import JisrLoader from 'components/global/JisrLoader'
import useNotificationApi from '../useNotificationAPI'
import { SettingsOption } from '../types'
import { supportedGuide } from './helper'
import styles from '../styles.module.css'

export default function NotificationsSettings({
  setOpenGuide,
}: {
  setOpenGuide: (val: boolean) => void
}): JSX.Element {
  const dispatch = useDispatch()
  const { permission, loading, requestPermission } = useNotificationApi()

  const currentEmployee = useSelector(({ auth }) => auth.employee)
  const {
    employee_configuration: { options: settings },
    fetching,
  } = useSelector(({ notificationsSetting }) => notificationsSetting)

  const handleUpdateSetting = (key: string, val: boolean): void => {
    if (currentEmployee?.id) {
      dispatch(
        employeeNotificationsSettingUpdate(currentEmployee.id, {
          [key]: String(val),
        }),
      )
    }
  }

  const options: SettingsOption[] = [
    {
      label: 'requests_and_tasks',
      active: settings?.requests_and_tasks === 'true',
      onChange: (checked): void => {
        handleUpdateSetting('requests_and_tasks', checked)
      },
    },
    {
      label: 'updates',
      active: settings?.updates === 'true',
      onChange: (checked): void => {
        handleUpdateSetting('updates', checked)
      },
    },
  ]

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(employeeNotificationsSettingLoad(currentEmployee.id))
    }
  }, [])

  return (
    <>
      {fetching && <JisrLoader absolute />}
      <Grid
        container
        direction="column"
        spacing={3}
      >
        {permission !== 'granted' && (
          <Grid item>
            <Banner
              color="danger"
              variant="tinted"
              leadingIcon="x-circle-filled"
              title={I18n.t('browser_notifications_disabled_alert_title')}
              description={
                <Trans
                  i18nKey="browser_notifications_disabled_alert_desc"
                  components={{
                    span: (
                      <span
                        {...(!loading && { onClick: requestPermission })}
                        {...(permission === 'denied' &&
                          supportedGuide && { onClick: () => setOpenGuide(true) })}
                        className={cn(
                          {
                            [styles.enableNotifications]: supportedGuide
                              ? true
                              : permission === 'default',
                          },
                          {
                            [styles.loading]: loading,
                          },
                        )}
                        data-testid="browser_notifications"
                      />
                    ),
                  }}
                />
              }
            />
          </Grid>
        )}
        {options.map((option) => (
          <Grid
            container
            item
            justify="space-between"
            key={option.label}
          >
            <Typography
              text={I18n.t(option.label)}
              variant="subheading"
            />
            <Switch
              active={option.active}
              onChange={(_, checked): void => option.onChange(checked)}
              disabled={permission === 'denied'}
              testId={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
