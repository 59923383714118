export const TICKET_REPORT_LOAD = 'TICKET_REPORT_LOAD'
export const TICKET_REPORT_LOAD_PENDING = 'TICKET_REPORT_LOAD_PENDING'
export const TICKET_REPORT_LOAD_FULFILLED = 'TICKET_REPORT_LOAD_FULFILLED'
export const TICKET_REPORT_LOAD_REJECTED = 'TICKET_REPORT_LOAD_REJECTED'

export const TICKET_REPORT_SHARE = 'TICKET_REPORT_SHARE'
export const TICKET_REPORT_SHARE_PENDING = 'TICKET_REPORT_SHARE_PENDING'
export const TICKET_REPORT_SHARE_FULFILLED = 'TICKET_REPORT_SHARE_FULFILLED'
export const TICKET_REPORT_SHARE_REJECTED = 'TICKET_REPORT_SHARE_REJECTED'

export const CHECK_TICKET_POLECY_LOAD = 'CHECK_TICKET_POLECY_LOAD'
export const CHECK_TICKET_POLECY_LOAD_PENDING = 'CHECK_TICKET_POLECY_LOAD_PENDING'
export const CHECK_TICKET_POLECY_LOAD_FULFILLED = 'CHECK_TICKET_POLECY_LOAD_FULFILLED'
export const CHECK_TICKET_POLECY_LOAD_REJECTED = 'CHECK_TICKET_POLECY_LOAD_REJECTED'
