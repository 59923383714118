import { useContext } from 'react'
import { Flex, Typography } from '@jisr-hr/ds'
import classnames from 'classnames'
import DSButton from 'components/DSButton/DSButton'
import { isRtl } from 'utils/uiHelpers'
import DSIcon from 'components/DSIcon'
import I18n from 'translations/i18n'
import { OnboardingContext } from '../../context'
import { CURRENT_STEP } from '../../Onboarding'
import { StepsTypes } from '../../types/onboardingTypes'
import styles from './styles.module.css'

function StepTemplate({
  children,
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
  fetching,
  disableNextBtn,
  nextBtnType = 'button',
  hideFooter,
  className,
}: StepsTypes): JSX.Element {
  const { org, setMainStep } = useContext(OnboardingContext)
  const goToNextStep = (): void => {
    goNextStep?.()
  }

  const handleGoPrevStep = (): void => {
    if (previousStep?.title) {
      return goPreviousStep?.()
    }
    localStorage.setItem(`${org.metadata.onboarding_steps.current_step}_step_${org.id}`, '0')
    return setMainStep(CURRENT_STEP[org.metadata.onboarding_steps.current_step])
  }

  return (
    <>
      <div className={classnames('scroll-y')}>
        <div className={classnames(styles.content, className)}>{children}</div>
      </div>

      {!hideFooter && (
        <Flex
          itemsCenter
          justifyBetween
          className={classnames(styles.footer)}
        >
          <DSButton
            type="button"
            color="neutral"
            variant="outlined"
            onClick={(): void => {
              handleGoPrevStep()
            }}
            style={{ backgroundColor: 'var(--ds-core-color-gray-100)' }}
            label={
              <Flex
                justifyBetween
                style={{ width: 248, height: 56 }}
              >
                <DSIcon name={isRtl ? 'arrow-right' : 'arrow-left'} />
                <Flex
                  flexCol
                  itemsEnd
                  style={{ gap: 6 }}
                >
                  <Typography
                    variant="title-1"
                    style={{ color: 'var(--color-base-colors-black-1000)' }}
                    text={previousStep?.title ? I18n.t('back') : I18n.t('exit')}
                  />
                  <Typography
                    variant="subtitle-1"
                    style={{ color: 'var(--color-base-colors-black-1000)' }}
                    className={styles.nextPrevStepTitle}
                    text={I18n.t(previousStep?.title || '')}
                  />
                </Flex>
              </Flex>
            }
          />

          <DSButton
            type={nextBtnType}
            onClick={(): void => {
              goToNextStep()
            }}
            disabled={fetching || disableNextBtn}
            label={
              <Flex
                justifyBetween
                style={{ width: 248, height: 56 }}
              >
                <Flex
                  flexCol
                  style={{ gap: 6 }}
                >
                  <Typography
                    variant="title-1"
                    style={{ color: 'var(--color-base-colors-white-1000)' }}
                    text={I18n.t('next_onboardin')}
                  />

                  <Typography
                    variant="subtitle-1"
                    style={{ color: 'var(--color-base-colors-white-1000)' }}
                    className={styles.nextPrevStepTitle}
                    text={I18n.t(nextStep?.title || '')}
                  />
                </Flex>
                <DSIcon
                  color="#fff"
                  name={isRtl ? 'arrow-left' : 'arrow-right'}
                />
              </Flex>
            }
          />
        </Flex>
      )}
    </>
  )
}

export default StepTemplate
