/* eslint-disable prefer-template */
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import lodash from 'lodash'
import { CostCenter } from 'types/Accounts/costCenter'
import * as actions from './actions'

// ? <----------------- these types should be shared at some point ----------------->
export type XeroCategory = {
  internal_id: string
  name: string
}

type XeroCostCenterAccount = {
  acct_name: string
  acct_number: string
  internal_id: string
}

export type XeroCategoriesType = XeroCategory & {
  options: XeroCategory[]
}

type XeroCostCenter = CostCenter & {
  employees_ids?: number[]
}

type JournalValue = {
  label: string
  label_ar: string
  name: string
  name_ar: string
  value: string
}
// ? <----------------- end ----------------->

type InitStateType = {
  accounts: XeroCostCenterAccount[]
  all_selected_ids: number[]
  catagories: XeroCategoriesType[]
  cost_centers: XeroCostCenter[]
  fetching: boolean
  journal_shape: JournalValue[]
  loading: boolean
  type_fetching: boolean
}

const initialState: InitStateType = {
  fetching: false,
  type_fetching: false,
  loading: false,
  cost_centers: [],
  all_selected_ids: [],
  journal_shape: [],
  catagories: [],
  accounts: [],
}

function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action

  switch (action.type) {
    case actions.XERO_COST_CENTER_LIST_LOAD_PENDING:
    case actions.XERO_COST_CENTER_ACCOUNTS_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.XERO_COST_CENTER_CREATE_PENDING:
    case actions.XERO_COST_CENTER_DELETE_PENDING:
    case actions.XERO_COST_CENTER_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.XERO_COST_CENTER_CATAGORIES_FULFILLED: {
      return {
        ...state,
        catagories: payload.data.data as XeroCategoriesType[],
        type_fetching: false,
      }
    }
    // * accounts
    case actions.XERO_COST_CENTER_ACCOUNTS_FULFILLED: {
      const accounts = (payload.data.data || []) as XeroCostCenterAccount[]
      return {
        ...state,
        accounts,
        type_fetching: false,
      }
    }

    case actions.XERO_COST_CENTER_LIST_LOAD_FULFILLED: {
      const cost_centers = payload.data.data.tracking_dimensions as CostCenter[]
      let reBuildCostCenter: XeroCostCenter[] = []
      let journal_shape: JournalValue[] = []

      cost_centers?.forEach((cost) => {
        reBuildCostCenter = [
          ...reBuildCostCenter,
          {
            ...cost,
            org_category_type: cost.org_category_type || 'custom',
            employees_ids: lodash.uniq(
              cost?.tracking_dimension_values?.map((it) => it.mapped_employee_ids!)?.flat(),
            ),
          },
        ]
        journal_shape = [
          ...journal_shape,
          {
            name: cost.name,
            name_ar: cost.name,
            label: cost.name + ' Name',
            label_ar: cost.name,
            value: cost?.name?.toLowerCase() + '_name',
          },
        ]
      })

      const all_selected_ids = reBuildCostCenter?.map((it) => it.employees_ids!)?.flat()
      return {
        ...state,
        cost_centers: reBuildCostCenter,
        journal_shape,
        all_selected_ids: lodash.uniq(all_selected_ids || []),
        fetching: false,
      }
    }

    case actions.XERO_COST_CENTER_CREATE_FULFILLED:
    case actions.XERO_COST_CENTER_UPDATE_FULFILLED:
    case actions.XERO_COST_CENTER_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.XERO_COST_CENTER_CREATE_REJECTED:
    case actions.XERO_COST_CENTER_UPDATE_REJECTED:
    case actions.XERO_COST_CENTER_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.XERO_COST_CENTER_LIST_LOAD_REJECTED:
    case actions.XERO_COST_CENTER_ACCOUNTS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
