import { TNamePageGroups, TStep } from 'redux/organizationOnborading/steps/type'

export const languagesList = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'عربي',
    value: 'ar',
  },
]

export const organizationRequiredGroups: TNamePageGroups[] = [
  'initial_onboarding',
  'onboarding_organization_structure',
  'onboarding_employees_data',
]

export const handelOrganizationListStatus = (
  stepName: string,
  list: TStep[] | [],
): 'completed' | 'pending' | 'disabled' => {
  const step = list?.find((e) => e.name === stepName)
  const stepIndex = list?.findIndex((e) => e.name === stepName)

  if (step && step.completed) return 'completed'
  if (stepIndex === 0 && !step?.completed) return 'pending'
  if (stepIndex && list && list[stepIndex - 1]?.completed) return 'pending'
  if (stepIndex === list?.length + 1) return 'completed'
  return 'disabled'
}
