import * as actions from './actions'

const initialState = {
  filters: {
    area_ids: [],
    department_ids: [],
    is_gosi_linked: [],
    job_title_ids: [],
    grade_ids: [],
    business_units: [],
    department_id: [],
    locations: [],
    gosi_subscriptions: [],
    employment_type_id: [],
    group_id: [],
  },
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.SAVE_FILTERS:
      return {
        ...state,
        filters: payload.filters,
      }

    default:
      return state
  }
}
