import React from 'react'
import { Spacer } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'react-redux'
import I18n from 'translations/i18n'
import { assetTypesLoad } from 'redux/actions/assetTypeActions'
import { SelectField, MultiSelectField } from 'components/final-form'

import { useFormState } from 'react-final-form'
import { employeeAssetClearRequestsNewLoad } from 'redux/requests/assetRequests/actionCreators'
import { required } from 'components/global/form/FormValidations'
import { isEmpty } from 'lodash'

import Frame from '../../../ds/Frame'
import { useRequest } from '../../../RequestProvider'
import { useRequestDetail } from '../../../hooks'

const AssetDetails = () => {
  const dispatch = useDispatch()
  const {
    values: { request_type },
  } = useFormState()
  const {
    vars: { id },
    editRequest,
  } = useRequest()
  const { request } = useRequestDetail()

  const { asset_types } = useSelector(({ assetType }) => assetType)
  const employee_assets = useSelector(({ assetRequests }) => assetRequests?.employee_assets)
  const options = request_type === 2 ? employee_assets : asset_types
  const assetTitle = request_type === 2 ? 'asset_clear_details' : 'asset_details'
  const assetlabel = request_type === 2 ? 'asset_item' : 'asset_type'
  const requestType = [
    { id: 1, name: I18n.t('asset_request') },
    { id: 2, name: I18n.t('asset_clear_request') },
  ]
  const empId = id ?? request?.employee?.id

  React.useEffect(() => {
    if (request_type === 2 && empId) {
      dispatch(employeeAssetClearRequestsNewLoad(empId))
    }
  }, [request_type, empId])

  React.useEffect(() => {
    dispatch(assetTypesLoad({ template: 'public', filter: { is_requestable: true } }))
  }, [])

  return (
    <Frame title={I18n.t(assetTitle)}>
      <SelectField
        name="request_type"
        labelKey="name"
        options={requestType}
        valueKey="id"
        testId="asset_request_type"
        disabled={editRequest}
      />

      <Spacer height={8} />

      <MultiSelectField
        name="asset_request_types_attributes"
        testId="asset-request-types-attributes"
        labelKey="name_i18n"
        valueKey="id"
        returnType="value"
        options={options}
        disabled={isEmpty(options)}
        placeholder={I18n.t('select')}
        label={I18n.t(assetlabel)}
        validate={required}
        maxMenuHeight={208}
      />
    </Frame>
  )
}

export default AssetDetails
