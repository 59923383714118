import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CandidateStatusesProps = {
  filterKey: string
  filters: Array<string>
}

const CandidateStatuses = (props: CandidateStatusesProps): JSX.Element => {
  const { candidate_statuses } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(candidate_statuses)

  const onHandleFilter = (_: string, val: string): void => {
    if (filter.job_openings.length > 0) {
      handleFilter('candidate_statuses', 'assigned')
      setFilter({ ...filter, candidate_statuses: ['assigned'] })
      return
    }

    if (val === 'all') {
      setFilter({
        ...filter,
        candidate_statuses: [],
        ats_job_opening_statuses: [],
      })
    } else {
      handleFilter('candidate_statuses', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.candidate_statuses)) {
      if (filter?.candidate_statuses?.includes('all') && filter?.candidate_statuses.length > 1) {
        filter.candidate_statuses = filter.candidate_statuses.filter(
          (status: string) => status !== 'all',
        )
        setSelectedIds(filter.candidate_statuses)
      } else {
        setSelectedIds(filter.candidate_statuses)
      }
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.candidate_statuses.length])

  useEffect(() => {
    if (filter.job_openings.length === 0) return
    setSelectedIds(['assigned'])
    setFilter({ ...filter, candidate_statuses: ['assigned'] })
  }, [filter.job_openings])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      isClearable={false}
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default CandidateStatuses
