import { Radio, Typography } from '@jisr-hr/ds'
import { Flex } from '@jisr-hr/ds-beta'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

const RadioBoxField = ({
  label,
  labelKey,
  name,
  onChange,
  options,
  validate,
  valueKey,
  style,
  testId = '',
  ...rest
}) => (
  <>
    {options.map((option, i) => (
      <Field
        key={i}
        name={name}
        validate={validate}
      >
        {({ input, meta }) => (
          <Flex
            flexCol={option?.flexCol}
            itemsCenter={!option?.flexCol}
            justifyBetween
          >
            <Flex
              flexCol
              className="gap-1"
            >
              <Radio
                {...option}
                {...rest}
                {...input}
                label={option[labelKey]}
                value={option[valueKey]}
                {...(option?.description && { description: option?.description })}
                active={input.value === option[valueKey]}
                onChange={(val) => {
                  input.onChange(val.target.value)
                  if (onChange) {
                    onChange(val.target.value, option)
                  }
                }}
                data-testid={option.testId}
              />
              {i === options.length - 1 && meta.error && meta.touched && (
                <Typography
                  className="ml-11"
                  text={meta.error}
                  variant="subtitle-2"
                  textColor="color/fg/danger/bold"
                  testId={testId}
                />
              )}
            </Flex>
            <Flex>{option?.node}</Flex>
          </Flex>
        )}
      </Field>
    ))}
  </>
)

RadioBoxField.defaultProps = {
  options: [],
  valueKey: 'value',
  labelKey: 'label',
}

RadioBoxField.propTypes = {
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.string,
  valueKey: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
}

export default React.memo(RadioBoxField)
