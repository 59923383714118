import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type SuspendEmployeeType = {
  address: string
  avatar: string
  avatar_thumb: string
  code: string
  full_name_i18n: string
  id: number
  job_title_i18n: string | null
  joining_date: string
  name: string
  name_ar: string
  suspended_payruns: {
    total: number
    collection: {
      id: number
      name: string
      is_selected: boolean
    }[]
    suspended_months_names: string[]
  }
}

type InitialStateType = {
  fetching: boolean
  loading: boolean
  suspended_employees: SuspendEmployeeType[]
  suspend_employees: SuspendEmployeeType[]
}

const initialState: InitialStateType = {
  fetching: false,
  loading: false,
  suspended_employees: [],
  suspend_employees: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYROLLS_SUSPEND_EMPLOYEE_PENDING:
    case actions.PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_PENDING:
    case actions.PAYRUN_SUSPEND_EMPLOYEES_LOAD_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_FULFILLED: {
      return {
        ...state,
        suspended_employees: action.payload.data.data.employees,
        loading: false,
      }
    }

    case actions.PAYRUN_SUSPEND_EMPLOYEES_LOAD_FULFILLED: {
      return {
        ...state,
        suspend_employees: action.payload.data.data.employees,
        loading: false,
      }
    }

    case actions.PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_FULFILLED:
    case actions.PAYROLLS_SUSPEND_EMPLOYEE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_REJECTED:
    case actions.PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_REJECTED:
    case actions.PAYRUN_SUSPEND_EMPLOYEES_LOAD_REJECTED:
    case actions.PAYROLLS_SUSPEND_EMPLOYEE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }
    }

    default:
      return state
  }
}
