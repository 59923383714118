import { useMemo, useState } from 'react'
import ConfirmModal from 'containers/authorised/setting/v2/components/ConfirmModal'
import ConfirmModalV2 from 'containers/authorised/NewOnboarding/components/ConfirmationModal'

import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  departmentsDelete,
  hierarchicalDepartmentsLoad,
  listDepartmentsLoad,
} from 'redux/newDepartments/actionCreators'
import { useNewOnboardingStore } from 'store/NewOnboarding/useNewOnboardingStore'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import DeleteDeptPopup from 'containers/authorised/NewOnboarding/pages/Organization/pages/OrganizationStructure/pages/components/DeleteDeptPopup'
import { useOnboardingBulkMove } from 'apis/Onboarding/updateOrgData/useUpdateOrgData'
import { isRtl } from 'utils/uiHelpers'
import { Typography, Button } from '@jisr-hr/ds'
import { Tooltip, TooltipProps } from '@jisr-hr/ds-beta'
import { Department } from 'redux/newDepartments/types'

import { useDepartmentsContext } from './context'
import ContentDeleteModal from './ContentDeleteModal'

type Props = {
  confirmMessage: {
    canDelete: boolean
    message: string
    title: string
    type: string
    data?: Department
  }
  id: number
  onDone: () => void
  target?: JSX.Element
  toolTipPlacement?: TooltipProps['placement']
  width?: string
}

const DeleteDepartment = ({
  id,
  onDone,
  confirmMessage,
  target,
  toolTipPlacement,
  width,
}: Props): JSX.Element => {
  const { data } = confirmMessage
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { isInOnboarding, setSearch, search, isHighTier, isNewOnboarding } = useDepartmentsContext()
  const { departmentCount, setDepartmentCount } = useNewOnboardingStore(
    (s) => s.onboardingEventState,
  )
  const { fetching } = useSelector((s) => s.newDepartments)
  const { basicListDepartments } = useSelector((s) => s.newDepartments)
  const { mutate: updateDepartmentApi, isLoading } = useOnboardingBulkMove()

  const hasSubDept = !!(data?.departments && data?.departments.length > 0)

  const hasEmployees = !!data?.number_of_employees

  const isNewExperience = isHighTier && isNewOnboarding

  const isDisabled = useMemo(() => {
    return isNewExperience && (hasSubDept || (hasEmployees && basicListDepartments.length === 1))
  }, [hasSubDept, hasEmployees, basicListDepartments])

  const deleteTooltips = useMemo(
    () => ({
      title: hasSubDept
        ? i18n.t(
            'communication.to_delete_this_department_you_need_to_delete_the_sub_departments_first',
          )
        : i18n.t('communication.add_new_department_before_deleting_this_one'),
      description: hasSubDept
        ? ''
        : i18n.t('communication.you_have_count_employees_assigned_to_this_department.', {
            employee_count: data?.number_of_employees,
          }),
    }),
    [data, confirmMessage],
  )

  const handleDelete = (): void => {
    dispatch(departmentsDelete(id)).then(() => {
      if (isInOnboarding) {
        dispatch(organizationInfo())
      }
      setIsOpen(false)
      dispatch(
        hierarchicalDepartmentsLoad({
          ...(isNewExperience && {
            filter: { source: 'manual' },
          }),
        }),
      )
      dispatch(
        listDepartmentsLoad({
          ...(isNewExperience && {
            filter: { source: 'sheet' },
          }),
        }),
      )
      if (isNewExperience) {
        dispatch(listDepartmentsLoad({}, true))
      }
      setSearch({ ...search, deleted_id: id })
      setDepartmentCount({ ...departmentCount, deleted: departmentCount.deleted + 1 })
      onDone()
    })
  }

  const removeDepartment = (currentDept: Department, targetDept: Department): void => {
    updateDepartmentApi(
      { current_id: currentDept.id, target_id: targetDept.id, type: 'department' },
      {
        onSuccess: () => {
          handleDelete()
        },
      },
    )
  }

  return (
    <div>
      <Tooltip
        placement={toolTipPlacement || (isRtl ? 'top-end' : 'top-start')}
        title={isDisabled ? deleteTooltips.title : ''}
        description={isDisabled ? deleteTooltips.description : ''}
        maxWidth={width || '176px'}
        trigger={
          target ? (
            <div
              onClick={(): void => setIsOpen(true)}
              className={`${isDisabled ? 'disabled cursor-not-allowed' : ''}`}
            >
              {target}
            </div>
          ) : (
            <Button
              label={i18n.t('delete')}
              size="medium"
              variant="outlined"
              color="danger"
              onClick={(): void => setIsOpen(true)}
              disabled={isDisabled}
            />
          )
        }
      />
      <ConfirmModalV2
        fetching={fetching}
        isOpen={
          isOpen &&
          confirmMessage.canDelete &&
          (!isNewExperience || (isNewExperience && !hasEmployees))
        }
        title={`${confirmMessage.title}${isRtl ? '؟' : '?'}`}
        description={
          <Typography
            text={<ContentDeleteModal data={data} />}
            style={{ margin: 24, marginBottom: 16 }}
            variant="subheading"
          />
        }
        titleConfirm="delete"
        onConfirm={handleDelete}
        onClose={(): void => setIsOpen(false)}
      />
      <ConfirmModal
        variant={confirmMessage.type}
        isOpen={
          isOpen &&
          !confirmMessage.canDelete &&
          (!isNewExperience || (isNewExperience && !hasEmployees))
        }
        hideConfirm={!confirmMessage.canDelete}
        title={confirmMessage?.title}
        message={confirmMessage.message}
        onCancel={(): void => setIsOpen(false)}
        onConfirm={handleDelete}
        labelConfirm="delete"
      />
      {data && isNewExperience && (
        <DeleteDeptPopup
          fetching={isLoading}
          open={isOpen && hasEmployees}
          selectedDepartment={data}
          listSuggetionsDepartment={basicListDepartments}
          onRemoveDepartment={(targetDept: Department): void => removeDepartment(data, targetDept)}
          onBack={(): void => setIsOpen(false)}
        />
      )}
    </div>
  )
}

export default DeleteDepartment
