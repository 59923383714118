import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'

import * as actions from './actions'

type InitialStateType = {
  fetching: boolean
}

export const initialState: InitialStateType = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.REMOVE_ADMIN_USER_PENDING:
    case actions.ADD_ADMIN_USER_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.REMOVE_ADMIN_USER_FULFILLED:
    case actions.ADD_ADMIN_USER_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    case actions.ADD_ADMIN_USER_REJECTED:
    case actions.REMOVE_ADMIN_USER_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
