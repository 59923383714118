import { useCallback, useEffect, useState } from 'react'
import { Toastr } from 'components/global/Toastr'
import { FileRejectionType } from '@jisr-hr/ds'
import { Field } from 'react-final-form'
import { FieldValidator } from 'final-form'
import { FileInfo, FileUpload, FileUploadProps } from '@jisr-hr/ds-beta'
import { isArray, isEmpty } from 'lodash'
import I18n from 'translations/i18n'

export type NewFileUploadFieldProps = Omit<FileUploadProps, 'accept'> & {
  name: string
  validate?: FieldValidator<any>
  accept?: string
  isActiveStorage?: boolean
  customKeys?: {
    file?: string
    id?: string
    name?: string
  }
}

const NewFileUploadField = ({
  name,
  validate,
  accept,
  errorMessage,
  onLoad,
  isActiveStorage,
  ...props
}: NewFileUploadFieldProps): JSX.Element => {
  const [showErrors, setErrors] = useState<FileRejectionType[]>()

  const getFirstFile = (files: any): any => {
    return Array.isArray(files) ? files[0]?.file : files?.file
  }

  const handleError = useCallback(
    (errorsList: FileRejectionType[]): void => {
      errorsList?.forEach(({ errors }) => {
        errors?.forEach((e) =>
          e?.code === 'file-too-small'
            ? Toastr.error(I18n.t('empty_file_error_msg'))
            : Toastr.error(e?.message),
        )
      })
    },
    [showErrors?.length],
  )

  useEffect(() => {
    if (showErrors) {
      handleError(showErrors)
    }
  }, [showErrors?.length, handleError])

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => (
        <FileUpload
          {...props}
          {...input}
          accept={{
            acceptedFiles: accept ? accept?.split(', ').map((type) => type?.trim()) : [],
          }}
          onLoad={(files): void => {
            if (!isEmpty(files)) {
              onLoad?.(files)
              const selectedFile = props?.multiple ? files : getFirstFile(files)
              const filesList = Array.isArray(files)
                ? files?.map((f: FileInfo) => {
                    if (props?.customKeys) {
                      return {
                        ...(!!props.customKeys?.id && {
                          [props.customKeys.id]: f.id,
                        }),
                        ...(!!props.customKeys?.file && {
                          [props.customKeys.file]: f.file,
                        }),
                        ...(!!props.customKeys?.name && {
                          [props.customKeys.name]: f.name,
                        }),
                      }
                    }
                    return {
                      data: f.file,
                      filename: f.name,
                      ...(typeof f?.id === 'number' && { id: f.id }),
                    }
                  })
                : [
                    {
                      data: files?.file,
                      filename: files?.name,
                      ...(typeof files?.id === 'number' && { id: files.id }),
                      size: files?.size,
                    },
                  ]
              const selectedFileList = props?.multiple ? filesList : filesList?.[0]
              input.onChange(isActiveStorage || props?.customKeys ? selectedFileList : selectedFile)
            } else {
              input.onChange(undefined)
            }
          }}
          errorMessage={meta.error && meta.touched ? meta.error : errorMessage ?? ''}
          minSizeKB={props.minSizeKB || 1}
          onError={(errors) => {
            if (isArray(errors)) {
              props.onError?.(errors)
              setErrors(errors)
            }
          }}
        />
      )}
    </Field>
  )
}

export default NewFileUploadField
