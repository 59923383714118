import { ButtonProps } from '@jisr-hr/ds'
import clsx from 'classnames'
import DSIcon from 'components/DSIcon'
import styles from './styles.module.scss'

type DSButtonProps = Omit<ButtonProps, 'label'> & {
  label: JSX.Element | string
}

const DSButton = ({ label, testId, ...props }: DSButtonProps): JSX.Element => {
  return (
    <button
      {...props}
      data-testid={testId}
      className={clsx(
        styles.buttonStyle,
        styles[props?.size || 'medium'], // small or medium
        styles[props?.variant || 'filled'], // filled-primary or outlined-neutral
        props?.className,
        {
          [styles.disabled]: props?.disabled,
        },
      )}
    >
      {props?.leadingIcon && (
        <DSIcon
          name={props?.leadingIcon}
          size={props?.size === 'small' ? 'xxs' : 'sm'}
        />
      )}
      {label && label}
      {props?.trailingIcon && (
        <DSIcon
          name={props?.trailingIcon}
          size={props?.size === 'small' ? 'xxs' : 'sm'}
        />
      )}
    </button>
  )
}

export default DSButton
