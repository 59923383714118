import { ReactNode } from 'react'
import { Grid } from 'components/global/atoms'
import { Button, Typography, Flex, Spacer } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import styles from '../styles.module.css'

type TSummaryCardProps = {
  title: string
  contentItems: {
    label: string
    value: ReactNode
  }[]
  onEditClick?: () => void
  onViewClick?: () => void
}

const SummaryCard = ({
  title,
  contentItems,
  onEditClick,
  onViewClick,
}: TSummaryCardProps): JSX.Element => {
  return (
    <div className={styles.cardContainer}>
      <Flex
        justifyBetween
        itemsCenter
        className={styles.cardHeader}
      >
        <Typography
          variant="subtitle-1"
          text={I18n.t(title)}
        />
        <Button
          label={I18n.t('edit')}
          onClick={onEditClick}
          size="small"
          variant="outlined"
          color="neutral"
        />
      </Flex>
      <Grid
        container
        spacing={1}
        className={styles.cardBody}
      >
        {contentItems.map((obj, i) => (
          <Grid
            item
            key={i}
            md={6}
            sm={12}
          >
            <Typography
              variant="subtitle-2"
              style={{ color: 'var(--color-base-colors-grey-500)' }}
              text={I18n.t(obj.label)}
            />
            <Spacer height={8} />
            <Typography
              variant="interface/default/m"
              text={obj.value}
            />
          </Grid>
        ))}
      </Grid>
      {onViewClick && (
        <Flex
          justifyCenter
          itemsCenter
          className={styles.cardFooter}
        >
          <Button
            label={I18n.t('view_more')}
            onClick={onViewClick}
            size="small"
            variant="ghost"
          />
        </Flex>
      )}
    </div>
  )
}
export default SummaryCard
