import { memo } from 'react'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ContractTypeProps = {
  filterKey: string
  filters: Array<string>
}

const ContractType = (props: ContractTypeProps) => {
  const { last_active_contract_type } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={last_active_contract_type}
      selectedIds={filter[props.filterKey]}
      {...props}
      title="create_label.contract_type"
    />
  )
}

export default memo(ContractType)
