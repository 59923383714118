import { Lens } from '@dhmk/zustand-lens'

// ? types

export type OptionAnnouncementType = {
  group_type: string | null
  group_id?:
    | {
        id: string
        name: string
      }[]
    | null
  group_names?: { name_i18n: string }[]
  group_ids?:
    | {
        id: string
        name?: string
        name_i18n?: string
      }[]
  expired_date?: string
  priority_level?: 'normal' | 'critical' | null
  allow_comment?: boolean
  send_email?: boolean
}

export type attachmentType = {
  url?: string
  name?: string
  size?: number | string
  filename?: string
  file?: string
  id?: string | number | undefined
  data?: string
  delete?: boolean
  content_type?: string
}

export type ContentAnnouncementType = {
  title: string
  message?: string
  attachments?: attachmentType[]
  delete_attachments?: attachmentType[]
  attachment_file_name?: string
  body: string | null
}

export type CreateAnnouncement = {
  nextStep: number
  setNextStep: (value: number) => void
  contentValues: ContentAnnouncementType | null | undefined
  optionsValues: OptionAnnouncementType | null | undefined
  setOptionAnnouncementFormValues: (value: OptionAnnouncementType | null | undefined) => void
  setContentAnnouncementFormValues: (value: ContentAnnouncementType | null | undefined) => void
}

export const createAnnouncement: Lens<CreateAnnouncement> = (set) => {
  return {
    // ? states
    nextStep: 0,
    optionsValues: null,
    contentValues: null,
    // ? actions
    setNextStep: (nextStep: number) => set({ nextStep }),
    setOptionAnnouncementFormValues: (optionsValues) => set({ optionsValues }),
    setContentAnnouncementFormValues: (contentValues) => set({ contentValues }),
  }
}
export type AnnouncementsDrawer = {
  open: boolean
  toggleAnnouncementDrawer: (value: boolean) => void
}

export const announcementsDrawer: Lens<AnnouncementsDrawer> = (set) => {
  return {
    open: false,
    toggleAnnouncementDrawer: (value: boolean) => set({ open: value }),
  }
}
