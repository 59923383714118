import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { SummarySheetState } from './SummarySheetTypes'

const initialState: SummarySheetState = {
  fetching: false,
  loading: false,
  employees: [],
  subordinates: [],
  employee_data: null,
  pagination: {},
  stats: {},
  employeeStats: {},
}

export default function reducer(state = initialState, action: Action): SummarySheetState {
  switch (action.type) {
    case actions.RESET_SUMMARYSHEET_LISTING:
      return {
        ...state,
        employees: [],
        pagination: {},
      }

    case actions.RESET_EMPLOYEE_SUMMARYSHEET_LISTING:
      return {
        ...state,
        employee_data: null,
        employeeStats: {},
      }

    case actions.SUMMARYSHEET_LISTING_PENDING:
    case actions.EMPLOYEE_SUMMARYSHEET_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.TIMESHEET_EMPLOYEES_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.SUMMARYSHEET_LISTING_FULFILLED:
      const summarySheetDataList: SummarySheetState = action.payload.data.data
      return {
        ...state,
        ...summarySheetDataList,
        employees: [...state.employees, ...action.payload.data.data.employees],
        pagination: action.payload.data.data.pagination,
        stats: action.payload.data.data.stats,
        fetching: false,
      }

    case actions.EMPLOYEE_SUMMARYSHEET_FULFILLED:
      const employeeSummarySheetData: SummarySheetState = action.payload.data.data
      return {
        ...state,
        ...employeeSummarySheetData,
        employee_data: action.payload.data.data.employee_data,
        employeeStats: action.payload.data.data.stats,
        fetching: false,
      }

    case actions.TIMESHEET_EMPLOYEES_FULFILLED:
      return {
        ...state,
        subordinates: [...action.payload.data.data],
        loading: false,
      }

    case actions.SUMMARYSHEET_LISTING_REJECTED:
    case actions.TIMESHEET_EMPLOYEES_REJECTED:
    case actions.EMPLOYEE_SUMMARYSHEET_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
