import React from 'react'
import _, { omit } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useFormState, useForm } from 'react-final-form'
import { Flex } from '@jisr-hr/ds'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import { RecordedClock } from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'
import {
  ExcuseDateAndType,
  Reason,
  ExcuseDuration,
  EstimatedTime,
  Profile,
  EstimatedExcusedTime,
} from 'components/global/templates/RequestDrawer/components/forms'
import { excuseRequestCreate, excuseRequestUpdate } from 'redux/actions/excuseRequestActions'
import { timeToNumbers } from 'components/global/form/formNormalize'
import { loadEmployeePolices } from 'redux/setting/attendanceManagement/attendancePolicy/actionCreators'

import { useEmployeeAttendanceShifts, useRequestDetail } from '../../hooks'

const types = {
  full_day: 'Full Day',
  late_in: 'Coming Late',
  early_out: 'Leaving Early',
  middle_of_day: 'middle_of_day',
}

const MapExcuseType = {
  late_arrival: 'late_in',
  early_departure: 'early_out',
}

const ExcuseRequest = () => {
  const { change } = useForm()
  const dispatch = useDispatch()
  const { fetching: createFetching } = useSelector(
    ({ excuseRequest }) => excuseRequest,
    shallowEqual,
  )
  const {
    values: { type, shift, excuse_date, duration: estimate_duration },
  } = useFormState()

  const { fetching, attendance_record, loadShifts } = useEmployeeAttendanceShifts()

  const {
    vars,
    setOnSubmit,
    setInitialValues,
    drawerToggle,
    actionCallback,
    handleDisableSubmit,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = vars.id ?? request?.employee?.id

  const record = attendance_record?.[0]
  const currentShift = record?.employee_shifts?.find((st) => st.id === shift)
  const isUnAvailable = record?.is_on_leave || record?.is_holiday || record?.is_day_off
  const duration = type === 'late_in' ? currentShift?.delay : currentShift?.shortage
  const clock = type === 'late_in' ? currentShift?.in_time : currentShift?.out_time
  const isLateEarly = ['late_in', 'early_out'].includes(type)
  const noShifts = _.isEmpty(record?.employee_shifts)
  const isShiftGotEnded = currentShift?.is_shift_got_ended
  const showRecorded = isShiftGotEnded || !!duration || !!clock
  const showLateEarlyField = isLateEarly && !noShifts && !isUnAvailable
  const showMiddleOfDay = type === 'middle_of_day' && !isUnAvailable && excuse_date && !noShifts

  const disableForm = React.useMemo(
    () =>
      isUnAvailable ||
      (isLateEarly && ((!duration && clock) || noShifts || (isShiftGotEnded && !duration))) ||
      (type === 'middle_of_day' && noShifts),
    [type, shift, record],
  )

  const handleOnSubmit = (val) => {
    change('disableSubmit', true)
    const { totalMinutes } = timeToNumbers(val.duration)

    const values = {
      date: val.excuse_date,
      reason: val.reason,
      reason_type: val.reason_type,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
      excuse_type: types[val.type],
      excuse_time: val?.isEstimate ? val?.estimateTime : totalMinutes,
      ...(val?.type !== 'full_day' && { employee_shift_id: val.shift }),
      ...(val?.type === 'middle_of_day' && {
        from_time: val?.from_time,
        to_time: val?.to_time,
      }),
    }

    if (val.id) {
      return dispatch(
        excuseRequestUpdate(empId, val.id, {
          ...values,
          attachments: values?.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      )
        .then(() => {
          change('disableSubmit', false)
          setEditRequest(false)
        })
        .catch(() => change('disableSubmit', false))
    }

    return dispatch(
      excuseRequestCreate(empId, {
        ...values,
        attachments: values?.attachments?.map((it) => omit(it, 'id')),
      }),
    )
      .then(() => {
        change('disableSubmit', false)
        drawerToggle()
        if (actionCallback) {
          actionCallback()
        }
      })
      .catch(() => change('disableSubmit', false))
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        type: MapExcuseType[request.excuse_type] ?? request.excuse_type,
        excuse_date: request.date,
        initialShiftId: request?.employee_shift?.id,
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        type: vars.fromProfile ? 'late_in' : 'full_day',
        ...(vars?.type && { type: vars?.type }),
        ...(vars.date && { excuse_date: vars.date }),
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    if (excuse_date && empId) {
      loadShifts(empId, [excuse_date])
    }
  }, [excuse_date, empId])

  React.useEffect(() => {
    handleDisableSubmit(disableForm)
  }, [disableForm])

  React.useEffect(() => {
    if (vars.id) {
      dispatch(
        loadEmployeePolices(vars.id, {
          policy_type: 'excuse',
        }),
      )
    }
    setOnSubmit(handleOnSubmit)

    return () => {
      setInitialValues({})
      setOnSubmit(() => {})
      handleDisableSubmit(false)
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(fetching || createFetching || loading) && <JisrLoader absolute />}
      <Profile employee={vars?.employee} />

      <ExcuseDateAndType
        hasShifts={!noShifts}
        isUnAvailable={isUnAvailable}
      />

      {showRecorded && showLateEarlyField && (
        <RecordedClock
          type={type}
          clock={clock}
          duration={duration}
          isShiftGotEnded={isShiftGotEnded}
        />
      )}

      {!!duration && !!clock && showLateEarlyField && (
        <ExcuseDuration
          clock={clock}
          recorded={duration}
        />
      )}

      {!showRecorded && showLateEarlyField && (
        <EstimatedTime
          type={type}
          shift={currentShift}
          recorded={estimate_duration}
          request={request}
        />
      )}

      {showMiddleOfDay && <EstimatedExcusedTime shift={currentShift} />}

      {!disableForm && <Reason showReasonType />}
    </Flex>
  )
}

export default ExcuseRequest
