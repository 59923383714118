import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  configuration: {},
  leave_resumption_configuration: {},
  fetching: false,
  processing: false,
  action_period: '',
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.IS_AUTO_RESUMPTION_ENABLED_UPDATE_PENDING:
    case actions.IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_PENDING:
    case actions.RESUMPTION_PERIOD_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.RESUMPTION_PERIOD_UPDATE_PENDING:
      return {
        ...state,
        processing: true,
      }

    case actions.IS_AUTO_RESUMPTION_ENABLED_LOAD_FULFILLED:
    case actions.RESUMPTION_PERIOD_UPDATE_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
        processing: false,
      }

    case actions.RESUMPTION_PERIOD_LOAD_FULFILLED:
      return {
        ...state,
        action_period: payload.data.data.organization_configuration.value,
        fetching: false,
        processing: false,
      }

    case actions.IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_FULFILLED:
      return {
        ...state,
        leave_resumption_configuration: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.IS_AUTO_RESUMPTION_ENABLED_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        leave_resumption_configuration: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.IS_AUTO_RESUMPTION_ENABLED_LOAD_REJECTED:
    case actions.IS_AUTO_RESUMPTION_ENABLED_UPDATE_REJECTED:
    case actions.IS_LEAVE_RESUMPTION_REQUEST_ENABLED_LOAD_REJECTED:
    case actions.RESUMPTION_PERIOD_LOAD_REJECTED:
    case actions.RESUMPTION_PERIOD_UPDATE_REJECTED:
    case actions.IS_LEAVE_RESUMPTION_REQUEST_ENABLED_UPDATE:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
