import {
  Typography,
  Spacer,
  Button,
  Flex,
  Badge,
  FeaturedIcon,
  AvatarLabel,
  Dropdown,
} from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { OnboardingAdminType } from 'redux/organizationOnborading/inviteAdmins/type'

type TProps = {
  fetching: boolean
  list: OnboardingAdminType[]
  onClick: (values: OnboardingAdminType, isCancel?: boolean) => void
}

type BadgeColorType =
  | 'primary'
  | 'green'
  | 'red'
  | 'yellow'
  | 'gray'
  | 'gray-light'
  | 'green-light'
  | 'teal'
  | 'orange'
  | 'cyan'
  | 'raspberry'
  | 'violet'
  | undefined

type ButtonConfigType = {
  color: 'neutral' | 'danger' | 'primary'
  label: string
  isCancel: boolean
}

export const InvitedAdminsButtonConfig: Record<string, ButtonConfigType[]> = {
  pending: [
    {
      color: 'neutral',
      label: i18n.t('label.cancel_invitation'),
      isCancel: false,
    },
  ],
  initiated: [
    {
      color: 'neutral',
      label: i18n.t('label.cancel_invitation'),
      isCancel: false,
    },
  ],
  accepted: [
    {
      color: 'danger',
      label: i18n.t('label.delete_admin'),
      isCancel: false,
    },
  ],
  expired: [
    {
      color: 'neutral',
      label: i18n.t('label.re-invite'),
      isCancel: false,
    },
  ],
  failed: [
    {
      color: 'neutral',
      label: i18n.t('label.re-invite'),
      isCancel: false,
    },
  ],
}

const status: Record<string, BadgeColorType> = {
  accepted: 'green',
  expired: 'red',
  pending: 'yellow',
  initiated: 'yellow',
  not_delivered: 'gray',
  failed: 'gray',
}

const InvitedAdmins = (props: TProps): JSX.Element => {
  const { list, fetching } = props

  return (
    <Flex
      flexCol
      className="gap-[16px] max-h-[320px]"
    >
      {list.length ? (
        <>
          {list.map((admin, key) => (
            <Flex
              key={key}
              itemsCenter
              className="h-[32px]"
              justifyBetween
            >
              <AvatarLabel
                headerText={admin.full_name_i18n || i18n.t('No name')}
                supportingText={admin.email}
                avatarProps={{
                  size: 'm',
                  type: 'initials',
                  imageAlt: admin.full_name_i18n || '-',
                }}
                className="px-0 mx-0"
              />
              {admin.invitation_status && admin.invitation_status_i18n && (
                <Flex itemsCenter>
                  <Badge
                    variant="tinted"
                    label={admin.invitation_status_i18n}
                    color={status[admin.invitation_status]}
                  />
                  <Spacer width={12} />
                  <Dropdown
                    dropDownElement={
                      <Button
                        variant="outlined"
                        color="neutral"
                        iconOnly
                        leadingIcon="dots-horizontal"
                        size="small"
                      />
                    }
                    fitDropDown
                  >
                    {(InvitedAdminsButtonConfig[admin.invitation_status] || []).map(
                      (button, index) => (
                        <Button
                          key={index}
                          size="small"
                          type="button"
                          disabled={fetching}
                          variant="ghost"
                          color={button.color}
                          label={button.label}
                          onClick={(): void => props.onClick(admin, button.isCancel)}
                        />
                      ),
                    )}
                  </Dropdown>
                </Flex>
              )}
            </Flex>
          ))}
        </>
      ) : (
        <>
          <Flex
            flexCol
            itemsCenter
            justifyCenter
          >
            <FeaturedIcon
              variant="tinted"
              colorType="neutral"
              rounded
              iconName="users-01"
            />
            <Typography text={i18n.t('placeholder.no_invited_admins_yet')} />
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default InvitedAdmins
