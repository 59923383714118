import { useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import Box from '@material-ui/core/Box'
import { UploadingTab } from '@jisr-hr/design-system'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { handleRecordStatus } from 'containers/authorised/setting/organizationSetting/BulkImportSheets/helper'
import { ReactComponent as DownloadIcon } from 'assets/figma-icons/download-box.svg'
import { ReactComponent as CloudUploadIcon } from 'assets/figma-icons/cloud-upload.svg'
import { assetmanagerBulkUploadImportPost } from 'redux/setting/organizationSetting/assetmanagerBulkUpload/actionsCreators'
import {
  exportOnboardingOrganizationData,
  loadOnboardingBulkUploadCategories,
} from 'redux/organizationOnborading/bulkUpload/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'

import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import I18n from 'translations/i18n'
import { Trans } from 'react-i18next'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import styles from './styles.module.css'

const CountingHolidays = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { fetching, empCat } = useSelector(({ onboardingBulkUpload }) => ({
    ...onboardingBulkUpload,
    empCat: onboardingBulkUpload.onboarding_records.find((cat) => cat.type === 'opening_balance'),
  }))

  const locale = localStorage.getItem('Locale') || 'en'

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return

    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append('type', 'opening_balance')
    formData.append('language', localStorage.getItem('Locale') ?? 'en')
    dispatch(assetmanagerBulkUploadImportPost(formData)).then(() => {
      dispatch(loadOnboardingBulkUploadCategories())
      dispatch(organizationInfo())
    })
  }

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'upload_annual_leave_files' })

  useEffect(() => {
    dispatch(loadOnboardingBulkUploadCategories())
  }, [])

  useEffect(() => {
    const fetchCats = setInterval(
      () => (empCat?.status_value === 1 && dispatch(loadOnboardingBulkUploadCategories())) || {},
      10000,
    )
    return () => {
      clearInterval(fetchCats)
    }
  })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('upload_annual_leave_files')}
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={
          <Trans
            i18nKey="upload_annual_leave_files_discription"
            components={{ br: <br /> }}
            values={{ appName: I18n.t('jisr_translation_name') }}
          />
        }
      />

      <Spacer height="24px" />
      <Flex
        flexWrap
        className={styles.content}
      >
        <Flex
          justifyBetween
          itemsCenter
          className={styles.downloadLeaveDetails}
          onClick={(): void => {
            dispatch(
              exportOnboardingOrganizationData({
                type: 'onboarding_opening_balance',
              }),
            )
          }}
        >
          <Typography
            variant="interface/default/sm"
            text={I18n.t('download_leave_etails_sheet')}
          />

          <DownloadIcon />
        </Flex>
      </Flex>
      <Spacer height="16px" />
      <Flex
        flexWrap
        className={styles.content}
      >
        <Flex
          className={styles.UploadingTab}
          flexCol
        >
          <CloudUploadIcon className={styles.iconUploding} />
          <UploadingTab
            accept=".xls, .xlsx"
            style={{
              cursor: empCat?.status_value === 1 || fetching ? 'no-drop' : 'pointer',
            }}
            label={
              <Flex style={{ gap: 3 }}>
                <Typography
                  variant="subtitle-2"
                  text={I18n.t('drag_and_drop_file_or')}
                />
                <Typography
                  variant="subtitle-2"
                  style={{ color: 'var(--color-base-colors-blue-600)' }}
                  text={I18n.t('browse_computer')}
                />
              </Flex>
            }
            locale={locale}
            disabled={empCat?.status_value === 1 || fetching}
            onChange={handleOnChange}
          />
          {empCat?.category && (
            <Box style={{ border: 'none', height: 'auto', width: 'auto' }}>
              {handleRecordStatus(
                empCat?.status_value,
                empCat?.metadata || {},
                empCat?.error_file_url,
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </StepTemplate>
  )
}

export default CountingHolidays
