import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function listCountries(params = {}): Action {
  const url = `/${API_VERSION_TWO}/hr/countries`
  return {
    type: actions.HR_COUNTRIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function listRegions(id: number, params = {}): Action {
  const url = `/${API_VERSION_TWO}/hr/countries/${id}/regions`
  return {
    type: actions.REGIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function listCities(id: number, params = {}): Action {
  const url = `/${API_VERSION_TWO}/hr/regions/${id}/cities`
  return {
    type: actions.CITIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
