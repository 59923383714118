export const OFFBOARDING_DYNAMIC_STEPS_LOAD = 'OFFBOARDING_DYNAMIC_STEPS_LOAD'
export const OFFBOARDING_DYNAMIC_STEPS_LOAD_PENDING = 'OFFBOARDING_DYNAMIC_STEPS_LOAD_PENDING'
export const OFFBOARDING_DYNAMIC_STEPS_LOAD_FULFILLED = 'OFFBOARDING_DYNAMIC_STEPS_LOAD_FULFILLED'
export const OFFBOARDING_DYNAMIC_STEPS_LOAD_REJECTED = 'OFFBOARDING_DYNAMIC_STEPS_LOAD_REJECTED'

export const OFFBOARDING_BANK_AUTHORIZATION_LOAD = 'OFFBOARDING_BANK_AUTHORIZATION_LOAD'
export const OFFBOARDING_BANK_AUTHORIZATION_LOAD_PENDING =
  'OFFBOARDING_BANK_AUTHORIZATION_LOAD_PENDING'
export const OFFBOARDING_BANK_AUTHORIZATION_LOAD_FULFILLED =
  'OFFBOARDING_BANK_AUTHORIZATION_LOAD_FULFILLED'
export const OFFBOARDING_BANK_AUTHORIZATION_LOAD_REJECTED =
  'OFFBOARDING_BANK_AUTHORIZATION_LOAD_REJECTED'
