import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactComponent as Active } from '@jisr-hr/design-system/dist/assets/icons/active_payrun.svg'
import { ReactComponent as InActive } from '@jisr-hr/design-system/dist/assets/icons/inactive_payrun.svg'
import { ReactComponent as Done } from '@jisr-hr/design-system/dist/assets/icons/Success.svg'

const SideStepper = ({ step, steps, handleTab, vertical, testId }) => (
  <div
    data-testid={testId}
    className="side-stepper"
  >
    <ul className={classNames(vertical && 'vertical-stepper')}>
      {steps.map(({ value, label, clickable, testId: stepTestId }) => (
        <li
          key={value}
          data-testid={stepTestId}
          className={classNames(
            step === value && 'active',
            value < step && 'completed',
            !clickable && value > step && 'disable',
          )}
          onClick={() => handleTab(value)}
        >
          {/* <span className="checkbox" /> */}
          {step === value && <Active className="checkbox" />}
          {value < step && <Done className="checkbox" />}
          {!clickable && value > step && <InActive className="checkbox" />}
          {label && label}
        </li>
      ))}
    </ul>
  </div>
)

SideStepper.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()),
  handleTab: PropTypes.func.isRequired,
  vertical: PropTypes.bool,
  testId: PropTypes.string,
}

export default SideStepper
