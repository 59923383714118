import I18n from 'translations/i18n'
import { APP_DOMAIN_URL } from 'env'
import { SetupStepTypes } from '../types/onboardingTypes'
import Sidebar from '../components/Sidebar'

import {
  Language,
  organizationType,
  UploadLogo,
  OrganizationPage,
  Uploading,
  UploadingSucess,
  locationsBoard,
  CRInformationBoard,
  Departements,
  BusinessUnits,
  EmployeeData,
  EmploymentType,
  Industries,
  JobTitles,
  UploadGovt,
} from './steps'

export const orgSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    title: 'language_selection',
    component: Language,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('wizard_language'),
            description: I18n.t(
              'select_your_preffered_language_it_will_be_used_throughout_this_wizard',
            ),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'organization_structure',
    component: OrganizationPage,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('organization'),
            description: I18n.t('an_organisation_desc'),
          },
          {
            title: I18n.t('subdomain_name'),
            description: I18n.t('a_unique_name_linked_to_our_main_domain_jisr_description', {
              app_name: I18n.t('jisr_translation_name'),
              domain: APP_DOMAIN_URL,
              app: 'jisr',
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'logo',
    component: UploadLogo,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('logo'),
            description: I18n.t('copmany_logo_desc', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 4,
    title: 'industry',
    component: Industries,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('industry'),
            description: I18n.t('select_the_field_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 5,
    title: 'organization_type',
    component: organizationType,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 6,
    title: 'label.work_locations_branches',
    component: locationsBoard,
    sidebar: (
      <Sidebar
        videoKey="work_locations"
        infoSet={[
          {
            title: I18n.t('primary_work_location'),
            description: I18n.t('enter_the_main_headquarter_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 7,
    title: 'organization_details',
    component: CRInformationBoard,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('organization_details'),
            description: I18n.t('commercial_registration_details', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 8,
    title: 'business_units',
    component: BusinessUnits,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('business_units_discription'),
            description: I18n.t('define_business_units_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 9,
    title: 'department',
    component: Departements,
    sidebar: (
      <Sidebar
        videoKey="department"
        hideFullContent
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 10,
    title: 'job_titles',
    component: JobTitles,
    sidebar: (
      <Sidebar
        videoKey="job_titles"
        infoSet={[
          {
            description: I18n.t('based_on_your_industry_desc', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 11,
    title: 'contract_type',
    component: EmploymentType,
    sidebar: (
      <Sidebar
        videoKey="contract_type"
        infoSet={[
          {
            title: I18n.t('employment_type_discription'),
            description: I18n.t('define_various_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 12,
    title: 'upload_govt_files',
    component: UploadGovt,
    sidebar: (
      <Sidebar
        videoKey="upload_govt_files"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 13,
    title: 'upload_employee_data',
    component: Uploading,
    sidebar: (
      <Sidebar
        videoKey="upload_employee_data"
        infoSet={[
          {
            title: I18n.t('note'),
            description: I18n.t('kindly_ensure_to_add_the_email_description'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 14,
    title: 'reviewing_employee_data',
    component: EmployeeData,
    sidebar: (
      <Sidebar
        videoKey="reviewing_employee_data"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 15,
    title: 'verify',
    component: UploadingSucess,
    sidebar: <Sidebar infoSet={[]} />,
  },
]
