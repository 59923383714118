import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-irregular-whitespace
import { Select as SelectDS } from '@jisr-hr/design-system'
import I18n from 'translations/i18n'

const Select = ({
  label,
  error,
  style,
  version,
  message,
  optionalLabel,
  withCalendar,
  userSelect,
  isPending,
  testId,
  ...props
}) => (
  <SelectDS
    status={!!error && 'error'}
    statusMessage={error}
    {...props}
    label={label}
    optionalLabel={optionalLabel}
    menuPlacement="auto"
    placeholder={I18n.t('select')}
    noOptionsMessage={() => I18n.t('no_data')}
    style={style}
    data-testid={testId}
  />
)

Select.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  version: PropTypes.string,
  optionalLabel: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.shape(),
  ]),
  withCalendar: PropTypes.bool,
  userSelect: PropTypes.bool,
  isPending: PropTypes.bool,
}

export default Select
