import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Flex } from '@jisr-hr/ds'
import { Popper } from '@jisr-hr/design-system'
import { isRtl } from 'utils/uiHelpers'
import PostingStatus from 'components/global/molecules/PostingStatus/PostingStatus'
import { useSpring, animated } from 'react-spring'
import arrowIcon from 'assets/figma-icons/chevron.svg'
import clsx from 'classnames'
import styles from './styles.module.css'

const CollapseMenu = (props) => {
  const {
    options,
    trigger,
    labelKey = 'label',
    valueKey = 'value',
    subLabelKey = 'label',
    subValueKey = 'value',
    subListKey = 'options',
    maxHeight,
    disabled,
    title,
    style: c_styles,
  } = props

  const [isCollapsed, setIsCollapsed] = React.useState('')

  const style = useSpring({
    minHeight: isCollapsed ? 80 : 'auto',
    maxHeight: isCollapsed ? 250 : 'auto',
    overflow: isCollapsed ? 'hidden' : 'unset',
  })

  const imgStyle = useSpring({
    transform: isCollapsed ? 'rotate(270deg)' : 'rotate(90deg)',
  })

  const handleItemClick = (value, option) => {
    if (option.onClick) {
      option?.onClick(value, option)
    } else if (props.onItemClick) {
      props.onItemClick(value, option)
    }
  }

  return (
    <Popper
      trigger={
        trigger || (
          <Button
            size="small"
            leadingIcon="plus"
            iconOnly
          />
        )
      }
      placement={isRtl ? 'bottom-start' : 'bottom-end'}
      className={styles.menuBox}
      maxHeight={maxHeight || 'fit-content'}
      style={{ minWidth: 300, ...c_styles }}
    >
      {title && (
        <Typography
          text={title}
          variant="body-new/regular"
        />
      )}
      {options?.map((option) => {
        const hasSubList = Boolean(option[subListKey])
        const Icon = option?.icon
        if (hasSubList) {
          return (
            <animated.div
              key={option[valueKey]}
              className={styles.collapseContainer}
              style={isCollapsed === option[valueKey] ? style : {}}
            >
              <Flex
                justifyBetween
                itemsCenter
                className={styles.collapseHeader}
                onClick={() =>
                  setIsCollapsed((pre) => (pre === option[valueKey] ? null : option[valueKey]))
                }
                style={{
                  gap: 8,
                  flex: 1,
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >
                <Flex
                  itemsCenter
                  style={{
                    gap: 8,
                  }}
                >
                  {Boolean(option?.icon) && Icon}
                  <Typography
                    text={option[labelKey]}
                    variant="body-new/regular"
                  />
                </Flex>
                <animated.img
                  src={arrowIcon}
                  alt="arrow"
                  style={
                    isCollapsed === option[valueKey]
                      ? imgStyle
                      : { transform: isRtl ? 'rotate(180deg)' : '0' }
                  }
                />
              </Flex>
              {isCollapsed === option[valueKey] &&
                option[subListKey]?.map((sub_opt, j) => (
                  <Flex
                    key={j}
                    justifyBetween
                    style={{ flexGrow: 1 }}
                  >
                    <Typography
                      text={sub_opt[subLabelKey]}
                      style={{
                        flexGrow: 1,
                        paddingInlineStart: 30,
                        maxWidth: '100%',
                      }}
                      variant="body-new/regular"
                      className={clsx(styles.menuItem, disabled && styles.disabled)}
                      onClick={() => handleItemClick(sub_opt[subValueKey], sub_opt)}
                    />
                    {sub_opt.journalEntry && (
                      <PostingStatus
                        postingData={sub_opt.journalEntry}
                        status={sub_opt.status}
                      />
                    )}
                  </Flex>
                ))}
            </animated.div>
          )
        }
        return (
          <Flex
            itemsCenter
            key={option[valueKey]}
            style={{ gap: 8 }}
          >
            <Flex
              style={{ flexGrow: 1, gap: 8 }}
              className={clsx(styles.menuItem, disabled && styles.disabled)}
              onClick={() => handleItemClick(option[subValueKey], option)}
            >
              {Boolean(option?.icon) && Icon}
              <Typography
                text={option[labelKey]}
                variant="body-new/regular"
              />
            </Flex>
            {option.journalEntry && (
              <PostingStatus
                postingData={option.journalEntry}
                status={option.status}
              />
            )}
          </Flex>
        )
      })}
    </Popper>
  )
}

CollapseMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onItemClick: PropTypes.func,
  labelKey: PropTypes.string,
  subLabelKey: PropTypes.string,
  disabled: PropTypes.bool,
  subValueKey: PropTypes.string,
  subListKey: PropTypes.string,
  valueKey: PropTypes.string,
  trigger: PropTypes.node,
  maxHeight: PropTypes.string,
  style: PropTypes.shape(),
  title: PropTypes.string,
}

export default CollapseMenu
