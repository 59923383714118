const Webhooks = {
  // A
  authentication: 'التوثيق',
  api_key_auth: 'API Key',
  add_header: 'إضافة header',
  add_to: 'إضافة لـ',
  active_webhook: 'مفعل',
  activation_confirm_title: 'تفعيل الويب هوك',
  activation_confirm_message: 'هل أنت متأكد أنك تريد تفعيل "<bold>{{name}}</bold>"؟',
  auth_error: 'حقول التوثيق لا يمكن أن تكون فارغة.',

  // B
  basic_auth: 'Basic Authentication',
  bearer_auth: 'Bearer Authentication',

  // C
  create_webhook: 'إنشاء Webhook',
  custom_header_error: 'إما اسم العنوان أو القيمة فارغة لجميع العناوين المخصصة.',
  created_at_webhook: 'تاريخ الإنشاء',

  // D
  delete_webhook: 'حذف Webhook',
  delete_header: 'حذف Header',
  delete_webhook_text: 'هل أنت متأكد من حذف "<bold>{{name}}</bold>"',
  delete_header_text: 'هل متأكد من حذف "<bold>{{name}}</bold>" ؟',
  description_placeholder: 'وصف طويل لهذا الربط',
  deactivation_confirm_title: 'إلغاء تفعيل الويب هوك',
  deactivation_confirm_message: 'هل أنت متأكد أنك تريد إلغاء تنشيط "<bold>{{name}}</bold>"؟',

  // E
  empty_list_title: 'أنشئ أول ويب هوك',
  empty_list_subtext: 'استقبل تحديثات من النظام على التغييرات',
  end_point_url: 'End-Point URL',
  endpoint: 'End point',
  event_subscription: 'متابعة التحديثات',

  // H
  header_webhook: 'Header',
  headers: 'Headers',
  headers_helper_text: 'يمكنك إضافة حتى 5 headers',
  header_name: 'اسم header',
  header_name_placeholder: 'Your header name',
  header_value: 'header قيمة',
  header_value_placeholder: 'Your header value',

  // N
  none_auth: 'None',

  // Q
  query_params: 'Query Param',

  // S
  selected_count: 'محددة',
  select_atleast_one_event: 'Select at least one of events',

  // T
  token: 'Token',
  token_value: 'Token قيمة',
  try_your_webhook: 'قم بتجربة الـ Webhook',
  try_it: 'جّرب الآن',
  test_success: 'نجحت تجربة الـWebhook على الرابط',
  test_fail: 'حدث خطأ أثناء تجربة الربط بالـWebhook بسبب الرابط',

  // U
  url: 'الرابط',
  update_webhook: 'تحديث Webhook',
  user: 'المستخدم',

  // W
  webhooks: 'Webhooks',
  webhook_info: 'معلومات Webhook',
  webhook_name: 'اسم Webhook',
  webhook_name_placeholder: 'Your webhook name',
  webhook_format_text: 'صيغة JSON هي المدعومة حاليًا.',
}

export default Webhooks
