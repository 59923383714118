import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  fetchingLocation: false,
  fetchingCountry: false,
  countriesWithRelated: [],
  areas: [],
  area: {},
  locations: [],
  countries: [],
  countriesV2: [],
  regions: [],
  cities: [],
  most_used_countries_count: 0,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.OPEN_AREA:
      return {
        ...state,
        countriesV2: state.countriesV2?.map((i) => ({
          ...i,
          isOpen: i.id === payload?.data.id ? !i.isOpen : i.isOpen,
        })),
      }

    case actions.OPEN_LOCATION:
      return {
        ...state,
        countriesV2: state.countriesV2?.map((i) => ({
          ...i,
          areas: i.areas.map((l) => ({
            ...l,
            isOpen: l.id === payload?.data.id ? !l.isOpen : l.isOpen,
          })),
        })),
      }

    case actions.COUNTRIES_v2_LOAD_FULFILLED:
      return {
        ...state,
        fetchingCountry: false,
        countriesV2: payload.data.data?.countries?.map((country) => {
          const currentCountry = state.countriesV2.find((c) => c.id === country.id) || {}

          return {
            ...country,
            isCountry: true,
            type: 'country',
            isOpen: currentCountry.isOpen,
            areas: country.areas.map((area) => {
              const currentArea = currentCountry.areas?.find((c) => c.id === area.id) || {}

              return {
                ...area,
                isOpen: currentArea.isOpen,
                isArea: true,
                type: 'area',
                locations: area.locations.map((location) => ({
                  ...location,
                  type: 'location',
                  isLocation: true,
                })),
              }
            }),
          }
        }),
      }

    case actions.DELETE_AREA:
      return {
        ...state,
        countriesV2: state.countriesV2?.map((country) => ({
          ...country,
          areas: country.areas.filter((area) => area.id !== payload.id),
        })),
      }

    case actions.AREA_UPDATE_FULFILLED: {
      // const { area } = payload.data.data;
      return {
        ...state,
        // countriesWithRelated: state.countriesWithRelated?.map(country => ({
        //   ...country,
        //   countriesWithAreas: country.countriesWithAreas
        //     .map(a => (area.id === a.id
        //       ? {
        //         ...area,
        //         isArea: true,
        //         isOpen: a.isOpen,
        //         areaWithLocation: state.locations?.filter(lo => lo.area_id === area.id),
        //       }
        //       : a)),
        // })),
      }
    }

    case actions.LOCATION_UPDATE_FULFILLED: {
      // const { location } = payload.data.data;
      return {
        ...state,
        // countriesWithRelated: state.countriesWithRelated?.map(country => ({
        //   ...country,
        //   countriesWithAreas: country.countriesWithAreas.map(area => ({
        //     ...area,
        //     areaWithLocation: area?.areaWithLocation
        //       ?.map(l => (
        //         l.id === location.id
        //           ? { ...location, isLocation: true }
        //           : l
        //       )),
        //   })),
        // })),
      }
    }

    case actions.DELETE_LOCATION:
      return {
        ...state,
        countriesV2: state.countriesV2?.map((country) => ({
          ...country,
          areas: country.areas.map((area) => ({
            ...area,
            locations: area?.locations?.filter((location) => location.id !== payload.id),
          })),
        })),
      }

    case actions.HR_COUNTRIES_LOAD_PENDING:
    case actions.REGIONS_LOAD_PENDING:
    case actions.AREAS_LOAD_PENDING:
    case actions.CITIES_LOAD_PENDING:
    case actions.LOCATION_CREATE_PENDING:
    case actions.LOCATION_UPDATE_PENDING:
    case actions.AREA_UPDATE_PENDING:
    case actions.AREA_BULK_CREATE_PENDING:
    case actions.LOCATION_BULK_CREATE_PENDING:
    case actions.ASSIGN_LOCATIONS_EMPLOYEES_PENDING:
    case actions.COUNTRY_DELETE_PENDING:
    case actions.COUNTRY_CITIES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.COUNTRIES_v2_LOAD_PENDING:
      return {
        ...state,
        fetchingCountry: true,
        fetching: true,
      }

    case actions.LOCATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        fetchingLocation: true,
      }

    case actions.REGIONS_LOAD_FULFILLED:
    case actions.CITIES_LOAD_FULFILLED:
    case actions.COUNTRY_CITIES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.HR_COUNTRIES_LOAD_FULFILLED:
      const { countries } = payload.data.data

      return {
        ...state,
        countries,
        ...payload.data.data,
        fetching: false,
      }

    case actions.LOCATIONS_LOAD_FULFILLED:
      return {
        ...state,
        locations: payload.data.data.locations?.map((location) => ({
          ...location,
          isLocation: true,
        })),
        fetchingLocation: false,
        fetching: false,
      }

    case actions.LOCATION_BULK_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.AREA_BULK_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.LOCATION_CREATE_FULFILLED:
      const location = {
        ...payload.data.data?.location,
        isLocation: true,
      }
      return {
        ...state,
        location,
        // countriesWithRelated: state.countriesWithRelated.map(country => ({
        //   ...country,
        //   countriesWithAreas: country.countriesWithAreas.map((area) => {
        //     if (area.id === location.area_id) {
        //       return ({ ...area, areaWithLocation: [...area.areaWithLocation, location] });
        //     }

        //     return ({
        //       ...area,
        //       areaWithLocation: area.areaWithLocation,
        //     });
        //   }),
        // })),
        fetching: false,
      }

    case actions.AREAS_LOAD_FULFILLED:
      return {
        ...state,
        areas: payload.data.data?.areas.map((area) => ({ ...area, isOpen: false, isArea: true })),
        fetching: false,
      }

    case actions.COUNTRY_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ASSIGN_LOCATIONS_EMPLOYEES_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.HR_COUNTRIES_LOAD_REJECTED:
    case actions.AREAS_LOAD_REJECTED:
    case actions.REGIONS_LOAD_REJECTED:
    case actions.CITIES_LOAD_REJECTED:
    case actions.LOCATION_CREATE_REJECTED:
    case actions.LOCATION_UPDATE_REJECTED:
    case actions.AREA_UPDATE_REJECTED:
    case actions.AREA_BULK_CREATE_REJECTED:
    case actions.LOCATION_BULK_CREATE_REJECTED:
    case actions.ASSIGN_LOCATIONS_EMPLOYEES_REJECTED:
    case actions.COUNTRY_DELETE_REJECTED:
    case actions.COUNTRY_CITIES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.COUNTRIES_v2_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetchingCountry: false,
        fetching: false,
      }

    case actions.LOCATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        fetchingLocation: false,
      }

    default:
      return state
  }
}
