import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type StateType = {
  organization_type?: Array<{ id: number; name_i18n: string }>
  consants: []
  fetching: boolean
}

const initialState: StateType = {
  fetching: false,
  consants: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.CONSTANTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CONSTANTS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.CONSTANTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
