import { useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { Typography, Spacer, Button, Flex } from '@jisr-hr/ds'
import { ReactComponent as LargePlus } from 'assets/figma-icons/largePlus.svg'
import { useDispatch, useSelector } from 'utils/hooks'
import { LocationsType } from 'redux/organizationOnborading/locations/reducer'
import {
  cloneParentOrgLocations,
  locationDelete,
  locationLoad,
} from 'redux/organizationOnborading/locations/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import WorkLocation from './WorkLocation'
import styles from './style.module.css'
import StepTemplate from '../../../components/StepTemplate'

const initLocation = {
  id: 0,
  address_en: '',
  address_ar: '',
  address_i18n: '',
  city: {
    id: 0,
    name_ar: '',
    name_en: '',
    name_i18n: '',
  },
  area: {
    id: 0,
    name: '',
    name_ar: '',
    name_i18n: '',
    country: {
      id: 0,
      name_en: '',
      name_ar: '',
      name_i18n: '',
      alpha_2_code: '',
    },
  },
}

const locationsBoard = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { locations, fetching, cloning } = useSelector((s) => s.locations)
  const [changeUi, setChangeUi] = useState(true)
  const [checkAddOrEdit, setCheckAddOrEdit] = useState(true)
  const [locationData, setLocationData] = useState<LocationsType>(initLocation)

  const isSubOrg = useSelector(
    ({ organization }) => organization?.organization?.is_organization_subsidiary,
  )

  const handleChange = (): void => {
    setChangeUi(true)
    dispatch(locationLoad())
  }

  const handleCreateNew = (): void => {
    setCheckAddOrEdit(true)
    setChangeUi(false)
  }

  const handleDelete = (id: LocationsType['id']): void => {
    dispatch(locationDelete(id)).then(() => {
      dispatch(locationLoad())
      dispatch(organizationInfo())
    })
  }

  function handleEdit(values: LocationsType): void {
    setLocationData(values)
    setCheckAddOrEdit(false)
    setChangeUi(false)
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'work_locations' })

  const handleCloneParentLocations = (): void => {
    dispatch(cloneParentOrgLocations()).then(() => {
      dispatch(locationLoad())
    })
  }

  useEffect(() => {
    dispatch(locationLoad())
  }, [])

  return (
    <>
      {changeUi ? (
        <StepTemplate
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={nextStep}
          previousStep={previousStep}
          disableNextBtn={!locations.length}
        >
          <Flex
            justifyBetween
            itemsCenter
          >
            <Typography
              variant="title-1"
              text={I18n.t('label.work_locations_branches')}
            />
            {isSubOrg && !locations?.length && (
              <Button
                size="small"
                leadingIcon="download-01"
                color="primary"
                variant="filled"
                onClick={handleCloneParentLocations}
                disabled={cloning}
                label={I18n.t('label.import_from_main_organization')}
              />
            )}
          </Flex>
          <Spacer height="16px" />
          <Flex
            flexWrap
            style={{ gap: '16px' }}
          >
            {locations &&
              locations.map((location) => (
                <Flex
                  justifyBetween
                  className={styles.items}
                  key={location.id}
                >
                  <Flex
                    itemsStart
                    flexCol
                    style={{ padding: '0 24px' }}
                  >
                    <Typography
                      variant="body-new/regular"
                      text={location.area?.country?.name_i18n}
                    />
                    <Typography
                      variant="body-new/regular"
                      text={location.area?.name_i18n}
                    />
                    {location.city && (
                      <Typography
                        variant="body-new/regular"
                        text={location.city?.name_i18n}
                      />
                    )}
                    <Typography
                      variant="body-new/regular"
                      text={location.address_i18n}
                    />
                  </Flex>
                  <Flex style={{ padding: '0 16px' }}>
                    <Button
                      variant="ghost"
                      color="neutral"
                      label={I18n.t('edit')}
                      size="medium"
                      onClick={(): void => handleEdit(location)}
                    />
                    <Button
                      disabled={fetching}
                      variant="ghost"
                      color="neutral"
                      label={I18n.t('delete')}
                      size="medium"
                      onClick={(): void => handleDelete(location.id)}
                    />
                  </Flex>
                </Flex>
              ))}

            <Flex
              style={{ cursor: 'pointer' }}
              className={styles.formCard}
            >
              <div
                onClick={handleCreateNew}
                className={styles.item}
              >
                <LargePlus />
                <Spacer height="8px" />
                <Typography
                  style={{ textAlign: 'center' }}
                  variant="body-new/medium"
                  textColor="color/fg/light"
                  text={I18n.t('add_new')}
                />
              </div>
            </Flex>
          </Flex>
        </StepTemplate>
      ) : (
        <WorkLocation
          onClickChangeUi={handleChange}
          locationData={locationData}
          checkAddOrEdit={checkAddOrEdit}
          defaultLocation={locations[locations.length - 1]?.area?.country?.id || null}
        />
      )}
    </>
  )
}

export default locationsBoard
