import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import lodash from 'lodash'
import {
  AccountTransactionTypesType,
  ExternalAccountsType,
  JisrAccountTypesType,
} from 'types/Accounts/chartAccount'
import { Action } from 'types/redux'
import * as actions from './actions'

const category_vals = {
  Allowances: 1,
  'Absenteeism transactions': 2,
  Accruals: 2,
  'Loan Types': 3,
  'Other PayTypes': 4,
  'Other Deductions': 5,
}

type InitStateType = {
  accounts: ExternalAccountsType[]
  current_mapped_items: { count: number; label: string }[]
  fetching: boolean
  loading: boolean
  microsoft_account_types: AccountTransactionTypesType[]
  transaction_types: Record<string, JisrAccountTypesType[]>
  transaction_types_cats: { label: string; value: number }[]
  transaction_types_list: JisrAccountTypesType[]
}

const initialState: InitStateType = {
  fetching: false,
  loading: false,
  transaction_types: {},
  accounts: [],
  current_mapped_items: [],
  microsoft_account_types: [],
  transaction_types_list: [],
  transaction_types_cats: [],
}

function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action

  switch (action.type) {
    case actions.MICROSOFT_ACCOUNTS_LOAD_PENDING:
    case actions.MICROSOFT_SPECIFIC_ACCOUNT_LOAD_PENDING:
    case actions.MICROSOFT_ACCOUNT_TRANSACTION_SYNC_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.MICROSOFT_SPECIFIC_ACCOUNT_DELETE_PENDING:
    case actions.MICROSOFT_ACCOUNT_BULK_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.MICROSOFT_SPECIFIC_ACCOUNT_DELETE_FULFILLED:
    case actions.MICROSOFT_ACCOUNT_BULK_UPDATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.MICROSOFT_SPECIFIC_ACCOUNT_DELETE_REJECTED:
    case actions.MICROSOFT_SPECIFIC_ACCOUNT_LOAD_REJECTED:
    case actions.MICROSOFT_ACCOUNT_BULK_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.MICROSOFT_ACCOUNT_TRANSACTION_SYNC_FULFILLED: {
      const types = (payload.data?.data?.gl_transaction_types || []) as JisrAccountTypesType[]
      const category = lodash.groupBy(types, 'gl_transaction_category') || {}
      const category_list = Object.keys(category)?.map((label) => ({
        label,
        value: category_vals[label] as number,
      }))
      const transaction_types_cats = category_list.sort((a, b) => a.value - b.value)

      const counts = transaction_types_cats?.map((item) => {
        const list = category[item.label]?.filter(
          (it) => !it.external_accounting_infos?.internal_id && !it.map_to_earnings,
        )
        return { label: item.label, count: list.length }
      })

      return {
        ...state,
        fetching: false,
        transaction_types_cats,
        transaction_types: category,
        transaction_types_list: types,
        current_mapped_items: counts,
      }
    }
    case actions.MICROSOFT_ACCOUNTS_LOAD_FULFILLED: {
      const accounts = (payload.data?.data || []) as ExternalAccountsType[]
      return {
        ...state,
        accounts,
        fetching: false,
      }
    }
    case actions.MICROSOFT_SPECIFIC_ACCOUNT_LOAD_FULFILLED: {
      const microsoft_account_types = (payload.data?.data?.gl_account_transaction_types ||
        []) as AccountTransactionTypesType[]
      return {
        ...state,
        microsoft_account_types,
        fetching: false,
      }
    }

    case actions.MICROSOFT_ACCOUNTS_LOAD_REJECTED:
    case actions.MICROSOFT_ACCOUNT_TRANSACTION_SYNC_REJECTED: {
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
