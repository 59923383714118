import { OnboardingContext } from 'containers/public/Onboarding/context'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import {
  onboardingStepUpdate,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'
import i18n from 'translations/i18n'
import { ReactComponent as DoneIcon } from 'assets/figma-icons/version-2/done-onboarding.svg'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useContext } from 'react'
import { useSelector, useDispatch } from 'utils/hooks'
import { Flex, Spacer, Typography } from '@jisr-hr/ds'

const Done = ({ goPreviousStep, previousStep }: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { organization: orgInfo } = useSelector(({ organization }) => organization)
  const { setMainStep } = useContext(OnboardingContext)

  const { onboarding_steps } = orgInfo?.metadata ?? {}
  const handleNext = (): Promise<void> | void => {
    if (onboarding_steps?.payroll_setup === 'Pending') {
      return dispatch(
        onboardingStepUpdate({
          step_name: 'payroll_setup',
          step_status: 'Completed',
          current_step: 'invite_employees',
        }),
      ).then(() => dispatch(organizationInfo()))
    }
    return setMainStep(3)
  }

  return (
    <StepTemplate
      goNextStep={handleNext}
      goPreviousStep={goPreviousStep}
      previousStep={previousStep}
      nextStep={{ title: i18n.t('invite_employees') }}
    >
      <Flex
        itemsCenter
        justifyCenter
        style={{ height: 'calc(100vh - 216px)' }}
      >
        <div style={{ textAlign: 'center' }}>
          <DoneIcon />
          <Spacer height={14} />
          <Typography
            variant="heading"
            text={i18n.t('done_finish')}
          />

          <Spacer height={10} />
          <Typography
            variant="title-1"
            text={i18n.t('payroll_preferences_are_all_set')}
          />
        </div>
      </Flex>
    </StepTemplate>
  )
}

export default Done
