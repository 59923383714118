import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type LoanTypesTypes = {
  filterKey: string
  filters: string[]
}

const LoanTypes = (props: LoanTypesTypes): JSX.Element => {
  const { loan_types } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(loan_types)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default LoanTypes
