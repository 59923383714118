import { FC } from 'react'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import classNames from 'classnames'
import { Typography, Flex, Badge, Avatars } from '@jisr-hr/ds'
import { config, useTransition, animated } from 'react-spring'
import i18n from 'translations/i18n'

import DSIcon from 'components/DSIcon'

import { usePaymentMethod } from '../../components/usePaymentMethods'

const PaymentAccountsList: FC = () => {
  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod } = paymentCtx

  const animatedBanksList = useTransition(selectedPaymentMethod?.bank_accounts || [], {
    keys: (item) => Number(item?.id),
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: config.wobbly,
    trail: 250,
  })

  // ? functions
  const handleAccountDel = (account_id: number): void => {
    if (!selectedPaymentMethod) return
    paymentCtx.handleBankAccountDel({
      account_id,
      onSuccess: () => {
        const bank_accounts = selectedPaymentMethod.bank_accounts.filter(
          (account) => account.id !== account_id,
        )
        paymentCtx.setSelectedPaymentMethod({ ...selectedPaymentMethod, bank_accounts })
      },
    })
  }

  return (
    <div>
      {animatedBanksList((style, account) => {
        return (
          <animated.div
            key={account.id}
            className={classNames(mainStyles.main_widget)}
            style={{ ...style, marginBottom: 16 }}
          >
            <Flex
              itemsCenter
              justifyBetween
              style={{ marginBottom: 12 }}
            >
              <Avatars
                imageSrc={account.bank_logo}
                imageAlt={account.bank_name}
                size="xl"
              />

              {!account.authorization_status && (
                <DSIcon
                  name="trash-01"
                  size="ml"
                  color="var(--ds-core-color-gray-400)"
                  className={mainStyles.deleteIcon}
                  onClick={(): void => handleAccountDel(account.id)}
                />
              )}
            </Flex>
            <Typography
              variant="interface/default/sm"
              className={mainStyles.secondary_text}
              text={i18n.t('bank_account_name_iban_format', {
                name: account.bank_name,
                iban: account.iban.replace('SA', ''),
              })}
            />
            <div className={mainStyles.payment_info_divider}>
              <Typography
                variant="interface/default/sm"
                className={mainStyles.primary_text}
                text={`${i18n.t('commercial_registration')} : ${
                  selectedPaymentMethod?.registration_number
                }`}
              />
              <Typography
                variant="interface/default/sm"
                className={mainStyles.primary_text}
                text={`${i18n.t('mol_registration_number')} :${' '}
                ${selectedPaymentMethod?.mol_registration.mol_number}`}
              />
            </div>
            {account.authorization_status && (
              <Badge
                label={i18n.t(account.authorization_status)}
                color={account.authorization_status === 'authorized' ? 'green' : 'red'}
                size="small"
                variant="tinted"
              />
            )}
          </animated.div>
        )
      })}
    </div>
  )
}

export default PaymentAccountsList
