import { Grid } from '@jisr-hr/ds-beta'
import { Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { NewFileUploadField, BetaTextInputField as TextInputField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import { normalizeDDMMYYYY } from 'components/global/form/formNormalize'
import { OnboardingSubmittingForm } from 'apis/PeopleManagement/OnboardingEmployee/data_completion/useOnboardingEmployeeForm.type'
import { naturalNumber } from 'components/global/form/FormValidations'

export const PassportInfo = () => {
  const { change } = useForm()
  const { values } = useFormState<OnboardingSubmittingForm>()

  const gridItemProps = {
    item: true,
    columnSpacing: 3,
  }

  const passportKey = 'passport'

  return (
    <div className="flex flex-col gap-8">
      <Typography
        text={i18n.t('label.passport_info')}
        variant="title-1"
      />
      <Grid
        container
        rowSpacing={20}
      >
        <Grid {...gridItemProps}>
          <TextInputField
            testId="passport-number"
            name={`${passportKey}.passport_number`}
            label={i18n.t('passport_number')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="passport-issue-place"
            name={`${passportKey}.passport_issue_place`}
            label={i18n.t('passport_issue_place')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${passportKey}.passport_issue_date`}
            label={i18n.t('passport_issue_date')}
            placeholder={i18n.t('date_form_dd_mm_yyyy')}
            parse={normalizeDDMMYYYY}
            testId="Passport-Issue-Date-(Gregorian)"
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${passportKey}.passport_expiry_date`}
            label={i18n.t('passport_expiry_date')}
            placeholder={i18n.t('date_form_dd_mm_yyyy')}
            parse={normalizeDDMMYYYY}
            testId="Passport-Expiry-Date-(Gregorian)"
          />
        </Grid>
        <Grid {...gridItemProps}>
          <NewFileUploadField
            initFiles={
              values?.passport?.passport_attachments
                ? [
                    {
                      file: values.passport.passport_attachments[0]?.data,
                      name: values.passport.passport_attachments[0]?.filename,
                      id: String(values.passport.passport_attachments[0]?.id),
                      status: 'success',
                    },
                  ]
                : undefined
            }
            label={i18n.t('label.upload')}
            compact
            name={`${passportKey}.passport_attachments[0].data`}
            accept=".pdf, image/*"
            descriptionMessage={i18n.t('communication.supported_formats', { count: 50 })}
            onLoad={(file): void => {
              change(
                `${passportKey}.passport_attachments[0].filename`,
                Array.isArray(file) ? file?.[0]?.name : '',
              )
              change(`${passportKey}.passport_attachments[0].id`, file?.[0]?.id)
            }}
            onRemove={(file) => {
              if (!naturalNumber(file.id)) {
                change(`${passportKey}.passport_attachments`, [
                  ...values?.passport?.passport_attachments,
                  ...(file?.id === 'undefined'
                    ? []
                    : [
                        {
                          data: file?.file,
                          filename: file.name,
                          id: file.id,
                          delete: true,
                        },
                      ]),
                ])
              } else {
                change(
                  `${passportKey}.passport_attachments`,
                  values?.passport?.passport_attachments?.filter((it) => it.id !== file.id),
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
