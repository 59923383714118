import { Flex, Typography } from '@jisr-hr/ds'
import cn from 'classnames'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import { formatDateRequestOn } from 'containers/authorised/requests/helper'
import Signpost from '../ds/Signpost'

type Props = {
  approver: string
  assignee: string
  actionAt: string
  isLastApproval: boolean
}

const ApprovalByPassLog = ({
  approver,
  assignee,
  actionAt,
  isLastApproval,
}: Props): JSX.Element => {
  const employeeDate = useSelector(({ auth }) => auth.employeeDate)
  return (
    <div
      className={cn('-mt-8', {
        "-mt-20 after:content-[''] after:bg-base-colors-black-1000 after:w-[2px] after:inline-block after:h-6 after:ms-[19px]":
          !isLastApproval,
      })}
    >
      <Signpost>
        <Flex
          itemsCenter
          justifyBetween
        >
          <Flex className="gap-1">
            <Typography
              variant="subtitle-2"
              textColor="color/fg/brand/default"
              text={approver || ''}
            />
            <Typography
              variant="subtitle-2"
              text={i18n.t('communication.admin_approverd_for_the_approver_log')}
            />
            <Typography
              variant="subtitle-2"
              textColor="color/fg/brand/default"
              text={assignee}
            />
          </Flex>
          <Typography
            variant="subtitle-2"
            text={`${formatDateRequestOn(employeeDate, actionAt)} — ${format(actionAt, 'p')}`}
            textColor="color/fg/lighter"
          />
        </Flex>
      </Signpost>
    </div>
  )
}

export default ApprovalByPassLog
