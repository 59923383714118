const Request = {
  // A
  active_loans: 'سلف قيد التسديد',
  add_resumption: 'إضافة استئناف',
  advanced: 'راتب مقدم',
  approve: 'موافقة',
  approve_a: 'الموافقة على',
  add_comment: 'اضافة تعليق',
  approve_request: 'الموافقة على الطلب',
  approved_from: 'تمت الموافقة من قبل',
  archives: 'أرشيف الطلبات',
  all_requests: 'كل الطلبات',
  asset_clear: 'إخلاء عُهدة',
  asset_request: 'طلب عُهدة',
  asset_clear_request: 'طلب إخلاء عُهدة ',
  asset_cancellation_request: 'طلب إلغاء عُهدة',
  asset_clear_cancellation_request: 'طلب إلغاء إخلاء عُهدة',
  asset_item: 'عنصر العهده',
  asset_items: 'عناصر العهده',
  approval_process: 'سير الموافقات',
  approved_by: 'تمت الموافقة من',
  approver: 'المسؤول',
  approved_business_hours: 'ساعات إستئذان مؤكدة بسبب العمل',
  approved_personal_hours: 'ساعات إستئذان مؤكدة بسبب شخصي',
  attendance: 'الحضور والإنصراف',
  ATTENDANCE: 'الحضور والإنصراف',
  attendance_requests: 'طلبات الحضور والإنصراف',
  approved_date: 'تاريخ الموافقة',
  accept: 'موافقة',
  attachment_file: 'الملف المرفق',
  attendance_extra_hours: 'ساعات الحضور الاضافي',
  attendance_overview: 'نظرة عامة على الحضور واﻹنصراف',
  all_Documents: '({{doc}}) كل المستندات',
  all_Requests: '({{request}}) كل الطلبات',
  all_expiring_documents: 'كل الملفات المنتهية',
  are_you_really_sure: 'هل أنت متأكد من إالغاء الأشتراك ؟',
  attendance_is_not_yet_approved_this_employee:
    'مهلاً, لم يتم تأكيد سجل الحضور والإنصراف بعد للموظف',
  ask_for_attendance_approval: 'طلب تأكيد سجل الحضور والإنصراف للموظف',
  attendance_period_to_be_approved: 'الفترة التي سيتم تأكيد سجل الحضور والإنصراف فيها:',
  are_you_sure_that_you_want_confirm_vacation_settlement: 'هل تريد التأكيد على مخالصة الإجازة؟  ',
  are_you_sure_that_you_want_confirm_final_settlement: 'هل تريد التأكيد على المخالصة النهائية؟',
  are_you_sure_that_you_want_cancel_vacation_settlement: 'هل تريد بالتأكيد إلغاء مخالصة الإجازة ؟',
  are_you_sure_that_you_want_cancel_final_settlement: 'هل تريد بالتأكيد إلغاء المخالصة النهائية ؟',
  available_to_use: 'متاحة للاستخدام',
  additional_services: 'خدمات إضافية',
  additional_needs: 'احتياجات إضافية',
  available_to_use_up: 'متاح للاستخدام = رصيد محدث',
  asset_clear_type: 'نوع إخلاء العهدة',
  asset_clear_details: 'تفاصيل إخلاء العهدة',
  approved_request: 'تمت الموافقة',
  all_announcement_will_be_sent_to_mobile_app: 'كل الإعلانات يتم إرسالها من خلال اشعارات التطبيق',
  approvers_platform: 'سلسلة الموافقات',
  add_field: 'إضافة حقل',
  arabic_field_title: 'العنوان العربي للحقل',
  arabic_start_date_title: 'عنوان تاريخ البداية (عربي)',
  arabic_end_date_title: 'عنوان تاريخ النهاية (عربي)',
  already_added_cannot_be_added_again: 'مُضاف مسبقًا،لا يمكن اضافته مرة اخرى',
  assignee_details: 'تفاصيل المسؤول عن المهمة',

  // B
  business_trip_cancellation_request: 'طلب إلغاء مهمة عمل',
  business_trip_duration: 'مدة رحلة العمل',
  bank_iban: 'رقم الآيبان',
  business_trip_policy: 'سياسة رحلة العمل',
  browse_templates: 'تصفح القوالب',

  // C
  cancel_request: 'إلغاء الطلب',
  can_not_submit_leave_on_an_unscheduled_day:
    'لا يمكنك اضافة اجازة نصف يوم في ايام بدون دوامات مجدولة، يمكنك اما اختيار يوم اخر او الرجوع والالغاء',
  can_not_submit_leave_on_an_off_day:
    'لا يمكنك اضافة اجازة نصف يوم في يوم راحة، يمكنك اما اختيار يوم اخر او الرجوع والالغاء',
  cancelltion_request: 'إلغاء الطلب',
  canceled_by_employee: 'ملغي من قبل الموظف',
  cancellation_request: 'طلب الإلغاء',
  cancelled_by: 'ملغي بحسب',
  comments_required: 'التعليقات مطلوبة',
  created_at: 'تاريخ اﻹنشاء',
  cancel_subscription: 'إلغاء الأشتراك',
  contact_support: 'التواصل مع مدير نجاح الحساب',
  customer_success_manager_details: 'بيانات مدير نجاح الحساب',
  check_device_connectivity: 'تأكد من إتصالها',
  contracts_and_documents_about_to_expire: 'هؤلاء الموظفين لديهم مستندات ستنتهي قريباً',
  clock_in: 'وقت الدخول',
  clock_out: 'وقت الخروج',
  confirm_return: 'تأكيد العودة',
  cancelation_requested_by: 'طلب الإلغاء مرسل من',
  completed_by: 'تم الإكمال من',
  copy_request_link: 'نسخ رابط الطلب',
  current_month_overtime_hours: 'ساعات العمل الاضافي خلال الشهر الحالي',
  cut_off_from_date: 'تاريخ بدء فترة إستقطاع الراتب',
  cut_off_to_date: 'تاريخ إنتهاء فترة إستقطاع الراتب',
  cancel_request_msg: 'هل انت متأكد من إلغاء هذا الطلب؟',
  contract_no: 'عقد',
  configure_bank_file: 'اعدادات ملف البنك',
  configure_general_ledger: 'اعدادات التصدير الغير مباشر',
  contract: 'العقد',
  carry_forward_balance_notification:
    'الرجاء  التأكد من سياسة "ترحيل الرصيد" لتجنب الأخطاء التى تؤثر في الأرصدة.',
  confirm_the_final_settlement: 'تأكيد المخالصة النهائية',
  confirm_the_vacation_settlement: ' تأكيد مخالصة الإجازة',
  confirm_return_description: 'أكّد العودة من الإجازة لتنبيه الإدارة بها وإنشاء طلب بذلك',
  count_family_members: 'أفراد الأسرة ({{number}})',
  delegation_details: 'تفاصيل التفويض',
  changes_on_attendance_record_if_request_got_approved: 'تأثير قبول الطلب على سجل الحضور والانصراف',
  cancelled_request: 'تم الإلغاء',
  corrections_can_requested_for_scheduled_shifts:
    'يمكن طلب تصحيح البصمات فقط للفترات المجدولة. لطفاً، راجع المشرف ليساعدك او اختر يوم آخر.',
  choose_which_fields_will_appear_description: 'اختر الحقول التي ستظهر للموظفين عند إرسال الطلب',
  create_approval_cycles_for_this_request_in_order:
    'قم بإنشاء سلسلة موافقات لهذا الطلب حتى يتمكن الموظفون من استخدامه.',
  creation_date: 'تاريخ الطلب',
  create_workflow: 'إنشاء',
  custom_requests_is_not_in_your_plan: 'الطلبات المخصصة ليست في خطة اشتراكك',
  clear_date: 'حذف التاريخ',
  custom_requests: 'طلبات مخصصة',
  cancel_immediately_one_request: 'إلغاء الطلب على الفور',
  cancel_immediately_two_requests: 'إلغاء طلبين على الفور',
  cancel_immediately_selected_requests_plural_ar: 'إلغاء {{count}} طلبات على الفور',
  cancel_immediately_selected_requests_singular_ar: 'إلغاء {{count}} طلب على الفور',
  can_select_more_than_one: 'يمكن اختيار اكثر من ١',
  custom_tasks: 'مهام مخصصة',
  create_custom_task: 'إنشاء مهمة مخصصة',
  custom_tasks_is_not_in_your_plan: 'المهام المخصصة ليست في خطة اشتراكك',
  communicate_recorded_violation_task: 'Communicate recorded violation:ar',

  // D
  document_request: 'طلب مستند',
  days_deducted: 'ما سيتم خصمه',
  days: 'أيام',
  deduction_from_salary_monthly: 'القسط الشهري',
  download_file: 'تنزيل الملف',
  directed_to_ar: 'موجه الى (عربي)',
  directed_to_en: 'موجه الى (إنجليزي)',
  days_left: 'الايام المتبقية',
  DAYS_LEFT: 'الايام المتبقية',
  dont_miss_it: 'لا تنسى أن',
  destination: 'المدينة (الوجهة)',
  device_name: 'اسم الجهاز',
  document_type: 'نوع المستند',
  deduction_starting_month: 'شهر بدء التقسيط',
  date_of_missing_punch: 'تاريخ السجل الغير مكتمل',
  date_expected: 'التاريخ وعدد ساعات العمل',
  date_of_last_request: 'تاريخ اخر طلب',
  delegate_to: 'تفويض إلى',
  doc_days: '  {{days}} ايام',
  delegator: 'المفوّض',
  download_invoice: 'تحميل الفاتورة',
  do_you_want_include_attendance_transactions:
    'هل تريد إدراج مؤثرات الحضور والإنصراف ضمن مخالصة الإجازة؟',
  do_you_want_include_attendance_transactions_final:
    'هل تريد إدراج مؤثرات الحضور والإنصراف ضمن المخالصة النهائية ؟',
  days_number: '{{number}} أيام',
  departure_date: 'تاريخ المغادرة',
  dec: 'ديسمبر',
  delegation_to: 'تفويض إلى',
  delegator_to: 'تفويض إلى',
  delegation_duration: 'مدة التفويض',
  daily_allowance: 'البدل اليومي',
  drag_and_drop_file: ' قم بسحب  وإفلات  الملف  أو',
  delegation_alert:
    'سيتم تفويض جميع الطلبات باستثناء مراجعة كشوف المرتبات، ومراجعة التسوية النهائية، ومراجعة تسوية الإجازة',
  disable: 'تعطيل',
  disable_request: 'تعطيل الطلب',
  des_delete_request: 'هل أنت متأكد أنك تريد حذف هذا الطلب؟ لا يمكن التراجع عن هذا الإجراء.',
  des_delete_task: 'هل أنت متأكد أنك تريد حذف هذه المهمة؟ لا يمكن التراجع عن هذا الإجراء.',
  des_disable_request: 'هل أنت متأكد أنك تريد تعطيل {{name}} ؟لن يتمكن الموظفون من تقديم هذا الطلب',
  des_delete_workflow: 'هل انت متأكد من حذف {{name}}؟',
  deleted_requests: 'الطلبات المحذوفة',
  do_you_want_to: 'هل تود',
  disabled_requests: 'الطلبات المعطلة',
  disabled_tasks: 'المهام المعطلة',
  default_assignee: 'المُسند الافتراضي',
  description_details: 'تفاصيل',

  // E
  empty: 'فارغ',
  excuse_cancellation_request: 'طلب إلغاء الإستئذان',
  exit_reentry_visa_request: 'طلب تأشيرة خروج وعودة',
  exit_reentry_visa_cancellation_request: 'طلب إلغاء تأشيرة خروج وعودة',
  expense_claim_cancellation_request: 'طلب إلغاء مصاريف مالية',
  exit_re_enter_payment: 'الدفع للخروج والعودة',
  excuse_date: 'تاريخ الإستئذان',
  excuse_hours: 'ساعات الاستئذان المطلوبة',
  excuse_type: 'نوع الإستئذان',
  expens_claim_items: 'عناصر مطالبة النفقات',
  entry_type: 'نوع الإدخال',
  employees_with_cash: ' موظفون يستلمون رواتبهم نقداً',
  employees_with_bank: 'موظفون يستلمون رواتبهم عن طريق البنك',
  employees_with_international_transfer: 'موظفون يستلمون رواتبهم عن طريق حوالة دولية',
  employees_with_cheque: 'موظفين يستلمون رواتبهم عن طريق شيك',
  employees_with_direct_transfer: 'موظفون يستلمون رواتبهم عن طريق حوالة مباشرة',
  expiring_contracts___documents: 'عقود ومستندات على وشك الانتهاء',
  employees_without_email: 'موظفين ليس لديهم بريد الكتروني',
  employees_have_never_logged_in_to_jisr: 'موظفين لم يسجلوا الدخول ل{{app_name}}',
  expired_by: 'أنتهت بواسطة',
  employees_from_the_same_team_have_leaves: 'موظفين من نفس الفريق في إجازة',
  exit_reentry_type: ' نوع الخروج والعودة',
  employees_number: 'عدد الموظفين',
  ending_date: ' تاريخ إنهاء عقد الموظف',
  employees_getting_paid: 'موظف سيتم الدفع لهم لرواتب شهر {{month}}',
  employee_no_email_msg: '{{count}} موظف/موظفين ليس لديهم بريد الكتروني',
  end_date_not_defined_yet: 'لم يتم تحديد تاريخ النهاية  ',
  employee_joining_date: ' تاريخ انضمام الموظف  ',
  exit_re_entry_visa: 'تأشيرة الخروج والعودة',
  end: 'نهاية',
  enter_time_manually: 'ادخال الوقت يدويا',
  expired_request: 'انتهاء',
  final_settlement: 'نهاية الخدمة',
  employee_assets_allocated: 'عهد الموظف',
  employees_will_be_able_to_sendـdescription: 'سيتمكن الموظفون من إرسال هذا الطلب بدون محتوى',
  employees_reasonـdescription: 'سيتمكن الموظفون من إضافة سبب لهذا الطلب.',
  employees_dateـdescription: 'سيتمكن الموظفون من تحديد تاريخ للطلب.',
  employees_attachmentsـdescription: 'سيتمكن الموظفون من إضافة مرفقات إلى الطلب.',
  end_date_request: 'تاريخ النهاية {{optional}}',
  employees_cant_submit_this_type_of_requests: 'لا يمكن للموظفين إرسال هذا النوع من الطلبات',
  eligible_employees: 'الموظفون المؤهلون',
  employees_will_be_moved_from_description:
    'سيتم نقل الموظفين من سلاسل الموافقات الأخرى إلى سلسلة الموافقات هذه',
  enable: 'تفعيل',
  english_field_title: 'العنوان الانجليزي للحقل',
  english_start_date_title: 'عنوان تاريخ البداية (انجليزي)',
  english_end_date_title: 'عنوان تاريخ النهاية (انجليزي)',
  employee_beneficiary: 'الموظف المستفيد',

  // F
  family_members: 'أفراد العائلة',
  first_day_to_work: 'اول يوم للعودة للعمل',
  first_day_off: 'اول يوم راحة',
  first_half: 'نصف الدوام الاول({{from}} - {{to}})',
  first_half_approval: 'نصف الدوام الاول',
  for_a_Day_or_more_annual_leaves: 'لطلبات الإجازات ليوم او أكثر',
  from_annual_leave_balance: 'يتم خصمها من رصيد الإجازات السنوي',
  fingerprint_machines_are_offline: 'جهاز/أجهزة بصمة غير متصلة',
  finance_requests: 'الطلبات المالية',
  finance_filter: 'الطلبات المالية',
  finance: 'الطلبات المالية',
  from_time: 'من الساعه',
  family_members__ticket: 'أفراد العائلة (تذكرة)',
  family_members__exit_reentry: 'أفراد العائلة (خروج وعودة)',
  food: 'طعام',
  for_one_employee: 'لموظف واحد؟',
  for_two_employees: 'لـ٢ من الموظفين؟',
  for_employees_count_plural_ar: 'لـ {{count}} موظفين؟',
  for_employees_count_singular_ar: 'لـ {{count}} موظف؟',
  flight_ticket_request: 'طلب تذكرة طيران',
  flight_ticket_cancellation_request: 'طلب إلغاء تذكرة الطيران',
  // G
  gosi_amount: 'القيمة في التأمينات اﻹجتماعية',
  go_to_request: 'الانتقال إلى الطلب',

  // H
  hiring_month: 'شهر التوظيف',
  half_day_leave: 'إجازة نصف يوم',
  having_trouble: 'هل تواجه مشكلة؟',
  hiring_cancellation_request: 'طلب إلغاء توظيف',
  hrs: '  ساعات ',
  hr_requests: 'الطلبات الاخرى',
  half_day_leave_is_not_available_on_days_with_more_than_one_shift:
    'إجازات منتصف اليوم في الوقت الحالي متاحة فقط في حال ان لديك دوام واحد باليوم، أختر يوم اخر او الإجازات لمدة يوم او اكثر...أيضًا يمكنك الرجوع والغاء الطلب.',
  date_and_expected_worked_hours: 'التاريخ وعدد ساعات العمل',
  hiring_details: 'تفاصيل التوظيف',
  hm: 'س:د',
  // I
  is_require_vacation_salary: 'مع راتب الإجازة',
  in_out: 'وقت الدخول والخروج',
  in_city: 'في',
  items: 'العناصر',
  info_change: 'تعديل المعلومات',
  // eslint-disable-next-line max-len
  in_order_include_attendance_transactions_attendance_hould_reviewd_approved_first_for_this_employee:
    'من أجل إحتساب متغيرات سجل الحضور والإنصراف, يجب أولاً طلب مراجعة سجل الحضور والإنصراف وتأكيده لهذا الموظف.',
  installments_ends: 'أقساط تنتهي',
  installment: 'القسط',
  information_changes: 'Information changes',
  installment_calculation: 'القسط الشهري',
  item_name: 'اسم العنصر',
  immediately: 'على الفور',

  // J
  joined_this_month: 'انضموا هذا الشهر',
  jan: 'يناير',
  // K
  keep_request: 'إلغاء',

  // L
  loading: 'جاري التحميل',
  load_more: 'المزيد',
  leave_from_same_team: 'في إجازة من نفس الفريق',
  leave_days: 'أيام الإجازة',
  leaves_remaining: 'أيام الاجازة المتبقة',
  leaving_day: 'تاريخ المغادرة',
  leave_resumption_overdue: 'متأخر عن العودة من اﻹجازة',
  leave_overview: 'تفاصيل الاجازة السنوية',
  leave_overview_description: 'هؤلاء الموظفين لم يؤكدوا عودتهم من الاجازة حتى الآن',
  last_connectivity: 'آخر إتصال',
  last_overtime_on: 'تاريخ أخر أوفرتايم في',
  last_overtime_expected_hours: 'عدد الساعات لاخر أوفرتايم',
  last_request: 'آخر طلب ',
  loan_active: 'سلف قيد التسديد',
  last_applied_loan: 'آخر سلفة',
  letter_type: 'نوع الخطاب',
  last_request_expected_hours: 'الساعات لاخر طلب',
  leave_from_same_team_with_value: 'إجازة من نفس الفريق ({{number}})',
  letter_details: 'تفاصيل الرسالة ',
  loan_details: 'تفاصيل السلفة',
  loan_type_with_number: 'نوع السلفة ({{number}})',
  leave_from_same_team_with_number: 'إجازة من نفس الفريق ({{number}})',
  leaves_on: 'إجازة في',
  leave_cancellation_request: 'طلب إلغاء إجازة',
  leave_resumption_cancellation_request: 'طلب إلغاء عودة من الإجازة',
  leave_resumption_request: 'طلب عودة من الإجازة',
  loan_cancellation_request: 'طلب إلغاء سلفة',
  leave_to_date: 'تاريخ العودة',
  last_day_off: 'اخر يوم إجازة',
  leaves_summary: 'ملخص اجازات {{employee_name}}',
  // M
  mark_as_completed: 'التحديد كمكتمل',
  missed_time: 'وقت نسيان البصمة',
  months: 'شهر / شهور',
  my_requests: 'طلباتي',
  my_tasks: 'المهام',
  month_excuse_hours: 'إجمالي ساعات الاستئذان لهذا الشهر',
  month_overtime_hours: 'ساعات العمل الاضافي خلال شهر',
  month_overtime_cost: 'تكلفة اوفرتايم في الشهر',
  missing_punches: 'السجلات الغير مكتملة',
  more_details: 'تفاصيل اكثر',
  more_approvers: '{{count}} مراجعين آخرين في السلسلة',
  multiple_days: 'عدة أيام',
  multiple_days_range: 'فترة زمنية محددة',
  more_people: '{{count}} more people',
  missing_punch_cancellation_request: 'طلب إلغاء تصحيح بصمة',
  multiple_choice: 'الاختيار المتعدد',
  multiple_choices: 'الاختيار المتعدد',
  multiple_choices_title: 'عنوان الاختيار المتعدد',

  // N
  no_comments: 'لا يوجد تعليق',
  no_tasks: 'لا يوجد لديك مهام',
  no_shifts_scheduled: 'لم يتم جدولة دوامات لهذا اليوم',
  next_payroll_in: 'مسير الرواتب التالي في',
  no_requests: 'يبدو أنه لا توجد لديك طلبات للموافقة عليها اليوم. يمكنك التحقق من حالة الطلبات.',
  no_tasks_msg: 'يبدو أنه لا توجد لديك مهام لﻹكمالها اليوم. يمكنك التحقق من حالة الطلبات.',
  no_summary: 'لايوجد ملخص',
  number_of_tickets: 'عدد التذاكر',
  number_of_installments: 'عدد الاقساط',
  month_for__months: 'شهر على {{noi}} شهر/اشهر',
  notifiction: 'إشعار',
  not_received_yet: 'لم تُستلم بعد',
  not_reviewed_yet: 'لم تُراجع بعد',
  not_using_jisr: 'لم يسجلوا الدخول ل{{app_name}}',
  no_go_back: 'العودة الى القائمة الرئيسية',
  net_end_of_service: 'صافي نهاية الخدمة',
  note_once_the_final_settlement_confirmed_you_not_able_undo_offboarding_employee:
    ' يرجى التأكد من مراجعة المخالصة النهائية بشكل صحيح. لن تتمكن من إجراء أي تغييرات على المخالصة بعد التأكيد ولايمكنك التراجع عن إنهاء خدمات الموظف!',
  note_once_the_vacation_settlement_confirmed_you_not_able_update_or_cancel_it:
    'يرجى التأكد من مراجعة مخالصة الإجازة بشكل صحيح. لن تتمكن من إجراء أي تغييرات على المخالصة بعد التأكيد!',
  note_the_system_will_un_do_offboarding_employee:
    'ملاحظة: عند إلغاء المخالصة النهائية, سوف يقوم النظام بالتراجع عن إنهاء خدمات الموظف',
  note_related_leave_request_wouldn_cancelled_due_this:
    'ملاحظة: عند إلغاء مخالصة الإجازة, لن يتم إلغاء طلب الإجازة المقدم',
  needed_before: 'مطلوبة قبل',
  new_request: 'طلب جديد',
  no_shift_scheduled_on_selected_date: 'لا توجد فترة دوام مجدولة لهذا اليوم',
  new_workflow_request: 'سلسلة موافقات جديده',
  new_request_platform: 'طلب جديد',
  new_approvers_cycle: 'سلسلة موافقات جديدة',
  new_approver: 'مسؤول الموافقة الجديد',
  new_task: 'مهمة جديدة',

  // O
  out_of: 'من',
  offline_fingerprint_machines: 'أجهزة البصمة الغير متصلة {{num}}',
  on_what_day: 'في أي يوم:',
  on_what_time: 'في أي وقت',
  offline: 'بنك',
  offline_method: 'الدفع عن طريق ملف البنك',
  on_trip: 'اجازة سفر',
  off_boarded_this_month: 'انهيت خدماتهم هذا الشهر',
  other_documents: 'مستندات اخرى',
  ok_request_attendance_approval: 'طلب تأكيد سجل الحضور والإنصراف',
  overtime_duration: 'مدة العمل الإضافي',
  other_request: 'other_request:ar',
  overtime_cancellation_request: 'طلب إلغاء عمل إضافي',
  original_request: 'الطلب الأساسي',
  one_request: 'طلب',
  overlapped_period: 'فترات متعارضه',
  overlapped_period_msg:
    ' راتب الإجازة من {{from}} إلى {{to}}  تم إحتسابه مسبقاً. لطلب أيام إضافية، قم بتقديم طلب آخر بشكل منفصل',
  // P
  period: 'الفترة',
  pending_from: 'معلق من قبل',
  pending_on_me: 'انت',
  pending_on_others: 'من الأخرين',
  pending_for_correction: 'بانتظار إعادة التصحيح',
  please_type_comment: 'رجاءً اكتب تعليقاً',
  policy_configuration: 'إعدادات سياسة مهمة العمل',
  policy_configuration_setting: 'إعدادات السياسة',
  position: 'المسمى',
  pending_by: 'في الإنتظار من',
  previous: 'السابقة',
  per_diem_total: 'إجمالي اليوم الواحد',
  punch_type: 'نوع نسيان البصمه',
  processed_absent: 'Ar: Processed Absent',
  processed_weekend: 'Ar: Processed Weekend',
  processed_holiday: 'Ar: Processed Holiday',
  processed_on_trip: 'Ar: Processed On Trip',
  profile_picture: 'الصورة الشخصية',
  payroll_due_date: 'تاريخ الإستقطاع لمسير الرواتب الحالي',
  play_video: 'شغل الفيديو',
  probation_period: 'فترة التجربة',
  phone: 'الهاتف',
  proceed_anyway: 'متابعة',
  policy_for: 'policy for:ar',
  passed_through: 'مرّ على {{count}} مراجعين آخرين',
  proposed_time: 'الوقت المقترح',
  period_of_deduction: 'فترة الحسم',
  passed_through_others: 'قبلها {{count}} من المسؤولين',
  payment_info: 'معلومات الدفع',
  propose_clock_in_time_first: 'اقترح وقت الحضور أولاً',
  // Q
  // R
  return_on: 'العودة في',
  request_actions: 'طلبات الموظفين',
  request_approved_successfully: 'تمت الموافقة على الطلب بنجاح',
  request_id: 'الرقم',
  reject: 'رفض',
  reject_request: 'رفض الطلب',
  rejected_from: 'تم الرفض من قبل',
  request_rejected_successfully: 'تم رفض الطلب بنجاح',
  request_cancellation_successfully: 'تم إلغاء الطلب بنجاح',
  request_on_descending: 'ترتيب حسب:وقت الطلب تنازلي',
  received_on_descending: ' ترتيب حسب:وقت الاستلام تنازلي ',
  request_on_ascending: 'ترتيب حسب:وقت الطلب تصاعدي',
  received_on_ascending: 'ترتيب حسب:وقت الاستلام تصاعدي',
  received_on: 'تم الإستلام في',
  resignation: 'استقالة',
  resignation_request: 'طلب استقالة',
  resignation_requests: 'طلبات الاستقالة',
  resignation_reason: 'سبب الاستقالة',
  requests_home: 'الطلبات',
  rejected_reason: 'سبب الرفض',
  request_status: 'حالة الطلب',
  request_attendance_approval: 'طلب تأكيد سجل الحضور والإنصراف',
  request_date: 'تاريخ اﻹستلام',
  request_details: 'تفاصيل الطلب',
  rejected_by: 'تم الرفض من',
  received_at: '  تم الاستلام في {{date}}',
  received: 'تم الإستلام',
  received_on_2: 'وقت الاستلام',
  reviewed_on: 'تم المراجعة في',
  regular_payroll: 'مسير الرواتب',
  require_vacation_salary: 'طلب راتب الإجازة',
  require_exit_re_entry: 'طلب خروج وعودة',
  run_payroll: 'احتساب مسير الرواتب',
  reach_out: 'معالجة الآن',
  required: 'الحقل مطلوب',
  renew_subscription: 'تجديد الأشتراك',
  reassign_tasks: 'إعادة تكليف المُسند',
  resume_working: 'استئناف العمل',
  payroll_review_request: 'طلب مراجعة مسير الرواتب',
  resume_working_description:
    'استئناف العمل ستضاف الأيام المتبقية من الإجازة إلى رصيد الإجازة السنوية عند استئناف للعمل',
  return_date: 'تاريخ العودة',
  requested_flight_ticket: 'طلب تذكرة طيران',
  requested_overtime: 'العمل الإضافي المطلوب',
  recent_loans: 'آخر السلف',
  request_for: 'طلب لـ',
  resignation_cancellation_request: 'طلب إلغاء استقالة',
  reassign_task: 'إعادة تكليف المُسند',
  rejected_request: 'مرفوض',
  request_link_copied: 'تم نسخ الرابط بنجاح!',
  request_name: 'اسم الطلب',
  request_name_english: 'الاسم بالانجليزية',
  request_upgrade: 'طلب ترقية',
  results: 'نتيجه',
  requests_approved_for_employees: 'تمت الموافقة على {{request_count}} لـ{{employee_count}} موظفين',
  requests_rejected_for_employees: 'تم الرفض  {{request_count}} لـ{{employee_count}} موظفين',
  request_is_approved_and_cant_be_changed: 'هذا الطلب موافق عليه ولا يمكن رفضه',
  request_is_rejected_and_cant_be_changed: 'الطلب مرفوض ولا يمكن تغييره',
  request_is_expired_and_cant_be_changed: 'الطلب منتهي الصلاحية ولا يمكن تغييره',
  request_is_canceled_and_cant_be_changed: 'الطلب ملغي ولا يمكن تغييره',
  request_statuses: 'الحالة',
  request_types: 'نوع الطلب',

  // S
  share: 'مشاركة',
  second_half: 'نصف الدوام الثاني({{from}} - {{to}})',
  second_half_approval: 'نصف الدوام الثاني',
  share_announcement: 'مشاركة إعلان',
  select_a_range: 'أختر فترة',
  summery: 'الملخص',
  saudi_city: 'المدينة',
  saudi_postal_code: 'الرمز البريدي',
  say_welcome_to_your_new_employees: 'رحب بالموظفين المنضمين حديثاً',
  services_requested: 'الخدمات الملحقة',
  sorry_attendance_approval_required_confirm_vacation_settlement:
    'عذراً! يجب تأكيد سجل الحضور والانصراف أولاً لتأكيد مخالصة الإجازة.',
  sorry_attendance_approval_required_confirm_final_settlement:
    'عذراً! يجب تأكيد سجل الحضور والانصراف أولاً لتأكيد المخالصة النهائية.',
  select_members: 'اختر الأعضاء',
  select_destination: 'اختر وجهتك',
  select_services: 'اختر الخدمات',
  subjected_to_en: 'Subjected to (EN) AR',
  subjected_to_ar: 'Subjected to (AR) AR',
  selected_duration_exceeds_your_balance: 'المدة المحددة تتجاوز رصيدك "متاح للاستخدام"',
  select_delegator: 'حدد المفوض',
  select_type: 'اختر النوع',
  sent: 'مرسلة',
  select_the_recorded_punches: 'حدد ما تريد تصحيحه من اوقات الحضور والانصراف المسجلة',
  shift_not_yet_ended: 'لم ينتهي الدوام بعد',
  start_date_request: 'تاريخ البداية {{optional}}',
  select_date: 'Select date :AR',
  specific_employees: 'موظفين محددين',
  singleـdate: 'تاريخ واحد',
  selected_requests_singular_ar: '{{count}} طلب',
  selected_requests_plural_ar: '{{count}} طلبات',
  select_only_limit_requests_at_once: 'لا يمكنك اختيار أكثر من 50 طلب',
  something_went_wrong_please_try_again: 'حدث خطاء، الرجاء المحاوله مرة اخرى',
  something_went_wrong: 'حدث خطأ ما',
  something_went_wrong_with_some_requests_please_try_again:
    'حدث خطأ في بعض الطلبات، يرجى المحاولة مرة أخرى',
  self: 'انا',
  start_end_dates: 'تاريخ البدء والانتهاء',
  select_employee_beneficiary: 'اختر الموظف المستفيد',
  select_task_type: 'اختر نوع المهمة',
  select_default_assignee: 'اختر المُسند الإفتراضي',

  // T
  view_details: 'عرض التفاصيل',
  total_expected_hours: 'اجمالي الساعات ',
  total_pending_tasks: 'إجمالي المهام المعلقة',
  this_will_be_deducted_from_your_annual_leave_balance:
    'سوف يتم خصم تلك المدة من رصيد الإجازات السنوية',
  total_pending_requests: 'إجمالي الطلبات المعلقة',
  total_excused_time_this_month: 'إجمالي ساعات الاستئذان لهذا الشهر',
  total_approved_excuse_personal: 'إجمالي ساعات إستئذان مؤكدة بسبب شخصي',
  total_approved_excuse_business: 'إجمالي ساعات إستئذان مؤكدة بسبب العمل',
  total_cost_of_requested_hours: 'اجمالي تكلفة الساعات المطلوبة',
  total_requested_hours: 'اجمالي الساعات المطلوبة',
  to_time: 'الى الساعة',
  Tasks: 'المهام',
  tasks_categories: 'أنواع المهام',
  task_updated_successfully: 'تم تحديث المهمه بنجاح',
  trip_date: 'تاريخ الرحلة',
  ticket_count: 'عدد التذكرة',
  tickets: 'تذاكر',
  ticket_issue: 'استخراج التذكرة',
  ticket_payment: 'دفع التذكرة',
  travellers: 'المسافرين',
  total_per_diem: 'الإجمالي لكل يوم',
  total_bill: 'إجمالي الفاتورة',
  total_amount: 'إجمالي القيمة',
  total_this_month: 'اجمالي الساعات لهذا الشهر',
  total_this_year: 'اجمالي الساعات لهذه السنة',
  total_per_month: 'إجمالي الساعات لهذا الشهر',
  total_per_year: 'إجمالي الساعات لهذه السنة',
  time_requested: 'عدد الايام المطلوبة',
  today: 'اليوم',
  total_delay_this_month: 'التأخير الكلي لهذا الشهر',
  total_shortage_this_month: 'أجمالي الخروج المبكر لهذا الشهر',
  total_delay_recorded: 'إجمالي التأخير المسجل',
  total_shortage_recorded: 'إجمالي الخروج المبكر المسجل',
  total_cost_of_hours: 'اجمالي تكلفة الساعات',
  total_expense_claim: ' اجمالي المصاريف المالية',
  total_overview_pending: '{{num}} طلب بإنتظار موافقتك',
  total_employees: 'عدد الموظفين',
  total_employees_low_tires: 'إجمالي عدد الموظفين',
  requests_menu: 'الطلبات',
  termination_date: 'تاريخ الإنهاء',
  termination_reason: ' سبب إنهاء الخدمة',
  transportation: 'وسائل النقل',
  total_requested: 'إجمالي المطلوب',
  team_requests: 'طلبات الفريق',
  this_balance_is_what_will_be:
    'هذا الرصيد هو ما سيكون "متاحًا للاستخدام" بنهاية فترة الإجازة المطلوبة <Text>({{date}}).</Text>',
  the_proposed_time_overlaps_before:
    'الوقت المقترح يتعارض مع بصمات مسجلة. بإمكانك اقتراح اي وقت قبل <span>{{max}}</span>',
  the_proposed_time_overlaps_after:
    'الوقت المقترح يتعارض مع بصمات مسجلة. بإمكانك اقتراح اي وقت بعد <span>{{min}}</span>',
  the_proposed_time_overlaps_between:
    'الوقت المقترح يتعارض مع بصمات مسجلة. بإمكانك اقتراح اي وقت بين <span>{{min}}</span> و <span>{{max}}</span>',
  the_proposed_time_overlaps:
    'الوقت المقترح ل{{field}} يتعارض مع بصمات مسجلة. بإمكانك اقتراح اي وقت {{before_after}} <span>{{max}}</span>',
  traveler: 'المسافرون',
  this_is_what_your_employeesـdescription: 'هذا ما سيراه موظفوك عند إنشاء هذا الطلب',
  this_request_doesnt_require_additional_info: 'لا يتطلب هذا الطلب معلومات إضافية',
  two_requests: 'طلبين',
  the_request: 'الطلب',
  text_field_title: 'عنوان حق النص',
  task_type: 'نوع المهمة',

  // U
  days_used_this_year: 'يوم/ايام مستخدمة هذه السنة',
  used_this_year: 'إجازة مستخدمة هذه السنة',
  unprocessed_absent: 'Ar:Unprocessed Absent',
  unprocessed_weekend: 'Ar:Unprocessed Weekend',
  unprocessed_holiday: 'Ar:Unprocessed Holiday',
  unprocessed_on_trip: 'Ar:Unprocessed On Trip',
  unscheduled_days_within_selected_period:
    '{{count}} أيام غير مجدولة في مدة الإجازة المحددة ستُخصم من رصيد الإجازات.',
  // V
  vaction_salary_payment: 'دفع راتب الإجازة',
  view_summary: 'عرض التفاصيل',
  view_settlement_report: 'عرض تقرير مستحقات الاجازة',
  view_travellers: 'عرض أسماء المسافرين',
  visa_type: 'نوع الخروج والعودة',
  view_last_report: 'عرض آخر تقرير',
  view_profile: 'عرض الملف الشخصي',
  video_guide: 'إليك هذا الفيديو السريع لمساعدتك على تعلم مسير الرواتب',
  view_attendance: ' عرض الحضور',
  view_attendance_details: 'عرض تفاصيل الحضور ',
  view_employees: 'عرض الموظفين',
  visa_period: 'فترة التأشيرة',
  view_details_over: 'عرض تفاصيل المخالصة  ',
  // W
  without_email: 'بدون بريد الكتروني',
  wait_for_approval: 'الإنتظار',
  workflow_and_comments: 'سلسلة الموافقات والتعليقات',
  workflow_arabic: 'بالعربية',
  workflow_english: 'بالانجليزية',
  workflow_details: 'تفاصيل السلسله',
  workflow_title: 'هنالك طلب بنفس الاسم {{language}} موجود بالفعل',
  workflow_title_des: 'اختر اسمًا آخر لطلبك الجديد',
  // X
  // Y
  you_dont_have_any_request: 'ليس لديك أي طلب حتى الآن',
  you_are_off_on_this_day: 'لديك يوم راحة في هذا اليوم',
  you_have_two_shifts: 'لديك اكثر من دوام في هذا اليوم',
  you_have_a_shift_on_this_day: 'لديك دوام في هذا اليوم',
  you_will_be_off_for: 'عدد ايام الراحة',
  you_have_no_tasks: 'يبدو أنه لا توجد لديك مهام',
  your_shift_is_from: 'دوامك في الوقت من {{from}} إلي {{to}}',
  you_have_no_pending_requests: 'يبدو أنه لا توجد لديك طلبات',
  you_have_no_pending_tasks: 'يبدو أنه لا توجد لديك مهام',
  year_excuse_hours: 'إجمالي ساعات الاستئذان لهذه السنة',
  year_overtime_hours: 'ساعات العمل الاضافي خلال سنة',
  yesterday: 'أمس',
  year_overtime_cost: 'تكلفة اوفرتايم في السنه',
  yes_cancel_our_subscription: 'نعم, نرغب في إلغاء الأشتراك',
  you_have_askedto_approve_the_attendance_employee_yet_approved:
    'لقد قمت بطلب التأكيد على سجل الحضور والإنصراف للموظف, ولم يتم بعد التأكيد عليه ',
  you_cant_confirm_vacation_settlement_until_the_attendance_period_approved:
    'عذراً, لايمكنك تأكيد مخالصة الإجازة حتى يتم تأكيد سجل الحضور والإنصراف للموظف الذي يخص الفترة  <b>{{month}}</b>',
  you_cant_confirm_final_settlement_until_the_attendance_period_approved:
    'عذراً, لايمكنك تأكيد المخالصة النهائية  حتى يتم تأكيد سجل الحضور والإنصراف للموظف الذي يخص الفترة  <b>{{month}}</b>',
  you_can_upgrade_your_plan_to_get_this_feature_and_many_more:
    'يمكنك ترقية خطة اشتراكك للحصول على هذه الميزة وغيرها الكثير!',
  you_cant_take_action_on_this_request: 'لا يمكنك اتخاذ إجراء بشأن هذا الطلب',
  you_arent_one_of_requests_approvers: 'لست ضمن سلسلة الموافقات لهذا الطلب',
  you_dont_have_pending_requests: 'ليس لديك اي طلبات معلقه',
  your_requests: 'طلباتي',
  you_are_not_eligible_to_apply_for_requests: 'ليس لديك الصلاحية للتقديم على الطلبات',
  you_cannot_request_type_for_half_leave: 'لا يمكنك طلب {{type}} في حالة اجازة نصف يوم',
  // Z
}

export default Request
