import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  vacation_salary_calculation: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.VACTION_SALART_CALCULATION_LOAD_PENDING:
    case actions.VACTION_SALART_CALCULATION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.VACTION_SALART_CALCULATION_UPDATE_FULFILLED:
    case actions.VACTION_SALART_CALCULATION_LOAD_FULFILLED:
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      return {
        ...state,
        fetching: false,
        vacation_salary_calculation: action?.payload?.data?.data?.payrun_configuration,
      }
    case actions.VACTION_SALART_CALCULATION_LOAD_REJECTED:
    case actions.VACTION_SALART_CALCULATION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return {
        ...state,
        fetching: false,
      }
  }
}
