/** **************
 *   Auth Reducer
 /*************** */

// import { handleResponseErr } from 'utils/apiHelperUtils';
import { groupBy } from 'lodash'

const DEFAULT_STATE = {
  sponsors: [],
  parent_departments: [],
  departments: [],
  accounting_levels: {},
  hierarchical_departments: [],
  areas: [],
  locations: [],
  nationalities: [],
  job_titles: [],
  out_of_payroll_types: [],
  fetching: false,
  fetching_parent_departments: false,
  fetching_departments: false,
  fetching_areas: false,
  fetching_locations: false,
}

let changes = null
export default function filterReducer(state = DEFAULT_STATE, action) {
  let payTypes
  let payroll_transaction
  switch (action.type) {
    /*
     * On Success
     * Action: put success message and run onSuccess function from actions
     */
    case 'PARENT_DEPARTMENTS_LOAD_PENDING':
      return {
        ...state,
        fetching_parent_departments: true,
      }
    case 'DEPARTMENTS_LOAD_PENDING':
      return {
        ...state,
        fetching_departments: true,
      }
    case 'AREAS_LOAD_PENDING':
      return {
        ...state,
        fetching_areas: true,
      }
    case 'LOCATIONS_LOAD_PENDING':
      return {
        ...state,
        fetching_locations: true,
      }

    case 'SPONSORS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        sponsors: action.payload.data.data.sponsors,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'PARENT_DEPARTMENTS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        parent_departments: action.payload.data.data.parent_departments,
        fetching: false,
        fetching_parent_departments: false,
      }
      return { ...state, ...changes }
    case 'DEPARTMENTS_LOAD_FULFILLED': {
      const departments = action?.payload?.data?.data?.departments || []
      let accounting_levels = {
        ...state.accounting_levels,
      }

      if (action?.payload?.params?.return_subtree_ids) {
        const lvl = groupBy(departments, (obj) => obj.department_path.length)
        Object.entries(lvl).forEach(([key, val]) => {
          const newKey = `Department_Level_${key}`
          accounting_levels = {
            ...accounting_levels,
            [newKey]: {
              jisr_options: val,
              key: 'name',
              name: 'name_i18n',
              employee_key: 'department',
            },
          }
        })
      }
      changes = {
        ...action.payload.data.data,
        departments,
        accounting_levels,
        fetching: false,
        fetching_departments: false,
      }
      return { ...state, ...changes }
    }
    case 'HIERARCHICAL_DEPARTMENTS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        hierarchical_departments: action.payload.data.data.departments,
        fetching: false,
        fetching_departments: false,
      }
      return { ...state, ...changes }
    case 'AREAS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        areas: action.payload.data.data.areas,
        fetching: false,
        fetching_areas: false,
      }
      return { ...state, ...changes }
    case 'LOCATIONS_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        locations: action.payload.data.data.locations.map((loc) => ({
          ...loc,
          name_i18n: loc.address_i18n,
        })),
        fetching: false,
        fetching_locations: false,
      }
      return { ...state, ...changes }
    case 'FILTERED_NATIONALITIES_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        nationalities: action.payload.data.data.nationalities,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'JOB_TITLES_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        job_titles: action.payload.data.data.job_titles,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OUT_OF_PAYROLL_TYPES_FULFILLED':
      payTypes = ['out_of_payrun', 'both']
      payroll_transaction = action.payload?.data?.data?.payroll_transaction_types
      return {
        ...state,
        out_of_payroll_types: payroll_transaction?.filter((item) =>
          payTypes.includes(item.pay_type),
        ),
        fetching: false,
      }

    case 'PARENT_DEPARTMENTS_LOAD_REJECTED':
      return {
        ...state,
        fetching_parent_departments: false,
      }
    case 'DEPARTMENTS_LOAD_REJECTED':
      return {
        ...state,
        fetching_departments: false,
      }
    case 'AREAS_LOAD_REJECTED':
      return {
        ...state,
        fetching_areas: false,
      }
    case 'LOCATIONS_LOAD_REJECTED':
      return {
        ...state,
        fetching_locations: false,
      }

    default:
      return state
  }
}
