export const ONBOARDING_ADMINS_LIST = 'ONBOARDING_ADMINS_LIST'
export const ONBOARDING_ADMINS_LIST_PENDING = 'ONBOARDING_ADMINS_LIST_PENDING'
export const ONBOARDING_ADMINS_LIST_FULFILLED = 'ONBOARDING_ADMINS_LIST_FULFILLED'
export const ONBOARDING_ADMINS_LIST_REJECTED = 'ONBOARDING_ADMINS_LIST_REJECTED'

export const ONBOARDING_ADMINS_INVITE_POST = 'ONBOARDING_ADMINS_INVITE_POST'
export const ONBOARDING_ADMINS_INVITE_POST_PENDING = 'ONBOARDING_ADMINS_INVITE_POST_PENDING'
export const ONBOARDING_ADMINS_INVITE_POST_FULFILLED = 'ONBOARDING_ADMINS_INVITE_POST_FULFILLED'
export const ONBOARDING_ADMINS_INVITE_POST_REJECTED = 'ONBOARDING_ADMINS_INVITE_POST_REJECTED'

export const ONBOARDING_ADMINS_UNDO_INVITE_PUT = 'ONBOARDING_ADMINS_UNDO_INVITE_PUT'
export const ONBOARDING_ADMINS_UNDO_INVITE_PUT_PENDING = 'ONBOARDING_ADMINS_UNDO_INVITE_PUT_PENDING'
export const ONBOARDING_ADMINS_UNDO_INVITE_PUT_FULFILLED =
  'ONBOARDING_ADMINS_UNDO_INVITE_PUT_FULFILLED'
export const ONBOARDING_ADMINS_UNDO_INVITE_PUT_REJECTED =
  'ONBOARDING_ADMINS_UNDO_INVITE_PUT_REJECTED'

export const ONBOARDING_ADMINS_DELETE = 'ONBOARDING_ADMINS_DELETE'
export const ONBOARDING_ADMINS_DELETE_PENDING = 'ONBOARDING_ADMINS_DELETE_PENDING'
export const ONBOARDING_ADMINS_DELETE_FULFILLED = 'ONBOARDING_ADMINS_DELETE_FULFILLED'
export const ONBOARDING_ADMINS_DELETE_REJECTED = 'ONBOARDING_ADMINS_DELETE_REJECTED'

export const ONBOARDING_VERIFY_ADMINS_LIST = 'ONBOARDING_VERIFY_ADMINS_LIST'
export const ONBOARDING_VERIFY_ADMINS_LIST_PENDING = 'ONBOARDING_VERIFY_ADMINS_LIST_PENDING'
export const ONBOARDING_VERIFY_ADMINS_LIST_FULFILLED = 'ONBOARDING_VERIFY_ADMINS_LIST_FULFILLED'
export const ONBOARDING_VERIFY_ADMINS_LIST_REJECTED = 'VERIFY_ADMINS_LIST_REJECTED'

export const DELETE_USER_LOCALY = 'DELETE_USER_LOCALY'
export const COMPLETE_ONBOARDING_VERIFY_ADMINS_LIST = 'COMPLETE_ONBOARDING_VERIFY_ADMINS_LIST'
export const CHANGE_ANY_USER_DELETED_TO_FALSE = 'CHANGE_ANY_USER_DELETED_TO_FALSE'
