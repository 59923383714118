/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

export type AtsRecruiterProps = {
  filterKey: string
  filters: string[]
}
const AtsRecruiter = (props: AtsRecruiterProps): JSX.Element | null => {
  const { ats_recruiter } = useFilterAPI()
  const { handleFilter, filter } = useFilters()
  if (!ats_recruiter?.length) return null

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={ats_recruiter}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('label.assigned_recruiter')}
    />
  )
}
export default memo(AtsRecruiter)
