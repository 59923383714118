const JIcon = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <svg
      width={width ?? 10}
      height={height ?? 18}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.165.271v8.251c0 3.75-2.462 3.722-2.977 3.716h-.045c-.17 0-.34-.012-.507-.036l-.516 5.45c.435.052.872.078 1.31.077 3.901 0 8.45-2.003 8.45-8.4V.272H4.165z"
        fill="#fff"
      />
    </svg>
  )
}

export default JIcon
