import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'

import * as actions from './actions'
import { TSubscription } from '../types'

type StateType = {
  fetching: boolean
  subscription: Partial<TSubscription>
}

const initialState: StateType = {
  fetching: false,
  subscription: {},
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.SUB_ORG_BILLING_INFO_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SUB_ORG_BILLING_INFO_LOAD_FULFILLED:
      return {
        ...state,
        subscription: payload.data.data.organization_subsidiary?.subscription,
        fetching: false,
      }

    case actions.SUB_ORG_BILLING_INFO_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
