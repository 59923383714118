import { useEffect } from 'react'
import classNames from 'classnames'
import { Flex } from '@jisr-hr/ds'
import { hierarchicalDepartmentsLoad } from 'redux/newDepartments/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import Chart from './components/Chart'
import AddParentDepartemnt from './components/AddParentDepartemnt'
import SearchBox from './components/SearchBox'
import DepartmentsProvider from './components/context'

import styles from './style.module.css'

const Departments = (): JSX.Element => {
  const dispatch = useDispatch()
  const { hierarchical_departments } = useSelector((s) => s.newDepartments)

  useEffect(() => {
    dispatch(hierarchicalDepartmentsLoad())
  }, [])

  return (
    <DepartmentsProvider>
      <Flex
        justifyBetween
        itemsCenter
      >
        <SearchBox />
        <AddParentDepartemnt />
      </Flex>
      <div
        className={classNames(styles.chartContainer, 'scroll-x', 'scroll-y')}
        style={{
          marginTop: 15,
          paddingBottom: 30,
        }}
      >
        <Chart departments={hierarchical_departments} />
      </div>
    </DepartmentsProvider>
  )
}

export default Departments
