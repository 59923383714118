import { useEffect, useState } from 'react'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import cns from 'classnames'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import {
  onboardingLeaveTypesLoad,
  onboardingLeaveTypesCreate,
  onboardingLeaveTypesUpdate,
  onboardingLeaveTypesDelete,
} from 'redux/leavesOnboarding/leaveTypes/actionCreators'
import { ReactComponent as Plus } from 'assets/figma-icons/add.svg'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { LeaveType, LeavesFormDataTypes } from 'redux/leavesOnboarding/leaveTypes/type'
import FormLeavesTypes from '../LeavesTypes/FormLeavesTypes'
import ReturnConfirmation from './ReturnConfirmation'
import styles from './style.module.css'

type DataCheckSwitch = {
  status: boolean
  value: LeaveType
}

const CustomizingLeaves = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [editData, setEditData] = useState<{ leave_types?: LeaveType }>({})
  const lang = I18n.language
  const [defaultCustomLeaves, setDefaultCustomLeaves] = useState({})
  const oddDefCustomLeaves = ['Casual', 'Emergency']

  const isEmpty = Object.keys(editData).length === 0

  const dispatch = useDispatch()
  const { leave_types, fetching } = useSelector(({ onboardingLeaveTypes }) => onboardingLeaveTypes)
  const customLeaves = leave_types.filter((item) => !item.is_default)

  const switchDefaultCustomChange = ({ status, value }: DataCheckSwitch): void => {
    if (!status) {
      dispatch(onboardingLeaveTypesDelete(value.id)).then(() => {
        dispatch(onboardingLeaveTypesLoad())
      })
      return
    }

    dispatch(onboardingLeaveTypesCreate({ ...value, is_active: true })).then(() => {
      dispatch(onboardingLeaveTypesLoad())
    })
  }

  const onSubmit = (data: { leave_types: LeavesFormDataTypes }): void => {
    if (isEmpty) {
      dispatch(onboardingLeaveTypesCreate({ ...data.leave_types, is_active: true })).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    } else {
      dispatch(onboardingLeaveTypesUpdate(data.leave_types)).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    }
  }

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'customizing_leaves' })

  useEffect(() => {
    dispatch(onboardingLeaveTypesLoad())
  }, [])

  useEffect(() => {
    setDefaultCustomLeaves({
      Casual: leave_types.find((e) => e.name === 'Casual') || {
        days_per_year: 1,
        name: 'Casual',
        name_ar: 'اعتيادية',
      },
      Emergency: leave_types.find((e) => e.name === 'Emergency') || {
        days_per_year: 1,
        name: 'Emergency',
        name_ar: 'طارئة',
      },
    })
  }, [leave_types])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('customizing_leaves')}
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('customizing_leaves_description')}
      />

      <Spacer height="24px" />
      <Flex
        flexCol
        className={styles.contentLeaves}
      >
        <Flex justifyBetween>
          <Typography
            variant="title-1"
            text={I18n.t('customizing_leaves')}
          />

          <Flex
            itemsCenter
            style={{ cursor: 'pointer' }}
            onClick={(): void => {
              setEditData({})
              setModalOpen(true)
            }}
          >
            <Plus className={styles.svqPlus} />
          </Flex>
        </Flex>
        <Spacer height="16px" />
        <Flex
          flexCol
          className={cns(styles.cardsContainer, 'scroll-y')}
        >
          {oddDefCustomLeaves.map((item, i) => (
            <SwitchCard
              key={i}
              disabled={fetching}
              name={
                lang === 'en' ? defaultCustomLeaves[item]?.name : defaultCustomLeaves[item]?.name_ar
              }
              days={defaultCustomLeaves[item]?.days_per_year}
              isActive={defaultCustomLeaves[item]?.is_active}
              handleOnEdit={(): void => {
                setEditData({ leave_types: defaultCustomLeaves[item] })
                setModalOpen(true)
              }}
              handleOnChange={(status: boolean): void => {
                const dataCheckSwitch: DataCheckSwitch = {
                  status,
                  value: defaultCustomLeaves[item],
                }

                switchDefaultCustomChange(dataCheckSwitch)
              }}
            />
          ))}

          {customLeaves.length > 0 && (
            <>
              {customLeaves?.map((item, i) => {
                if (!oddDefCustomLeaves.includes(item.name)) {
                  return (
                    <SwitchCard
                      key={i}
                      disabled={fetching}
                      name={item.name_i18n}
                      days={item.days_per_year}
                      isActive={item.is_active}
                      handleOnEdit={(): void => {
                        setEditData({ leave_types: item })
                        setModalOpen(true)
                      }}
                      handleOnChange={(): void => {
                        dispatch(onboardingLeaveTypesDelete(item.id)).then(() => {
                          dispatch(onboardingLeaveTypesLoad())
                        })
                      }}
                    />
                  )
                }
                return null
              })}
            </>
          )}
        </Flex>
      </Flex>
      <Spacer height="32px" />
      <ReturnConfirmation />

      <FormLeavesTypes
        isOpen={modalOpen}
        onSubmit={onSubmit}
        onClose={setModalOpen}
        initialValues={editData}
        fetching={fetching}
        isEmpty={isEmpty}
        oddDefCustomLeaves={oddDefCustomLeaves}
      />
    </StepTemplate>
  )
}

export default CustomizingLeaves
