export const QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC = 'QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC'
export const QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_FULFILLED =
  'QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_REJECTED =
  'QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_PENDING =
  'QUICKBOOKS_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const QUICKBOOKS_ACCOUNT_BULK_UPDATE = 'QUICKBOOKS_ACCOUNT_BULK_UPDATE'
export const QUICKBOOKS_ACCOUNT_BULK_UPDATE_FULFILLED = 'QUICKBOOKS_ACCOUNT_BULK_UPDATE_FULFILLED'
export const QUICKBOOKS_ACCOUNT_BULK_UPDATE_REJECTED = 'QUICKBOOKS_ACCOUNT_BULK_UPDATE_REJECTED'
export const QUICKBOOKS_ACCOUNT_BULK_UPDATE_PENDING = 'QUICKBOOKS_ACCOUNT_BULK_UPDATE_PENDING'

export const QUICKBOOKS_ACCOUNTS_LOAD = 'QUICKBOOKS_ACCOUNTS_LOAD'
export const QUICKBOOKS_ACCOUNTS_LOAD_FULFILLED = 'QUICKBOOKS_ACCOUNTS_LOAD_FULFILLED'
export const QUICKBOOKS_ACCOUNTS_LOAD_REJECTED = 'QUICKBOOKS_ACCOUNTS_LOAD_REJECTED'
export const QUICKBOOKS_ACCOUNTS_LOAD_PENDING = 'QUICKBOOKS_ACCOUNTS_LOAD_PENDING'

export const QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD = 'QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_FULFILLED =
  'QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_REJECTED = 'QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_PENDING = 'QUICKBOOKS_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE = 'QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_FULFILLED =
  'QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_REJECTED =
  'QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_PENDING =
  'QUICKBOOKS_SPECIFIC_ACCOUNT_DELETE_PENDING'
