import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  loading: false,
  cut_off_date: '',
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case actions.PAY_SCHEDULES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAY_SCHEDULES_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.PAY_SCHEDULES_LOAD_FULFILLED: {
      return {
        ...state,
        cut_off_date: action.payload.data?.data?.cut_off_date,
        fetching: false,
      }
    }

    case actions.PAY_SCHEDULES_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.PAY_SCHEDULES_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.PAY_SCHEDULES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
