import { Typography } from '@jisr-hr/ds'
import { Grid } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import {
  DatePickerDSField,
  Field,
  NewSelectField,
  BetaTextInputField as TextInputField,
} from 'components/final-form'
import {
  composeValidators,
  email,
  maxLength,
  minLength,
  naturalNumber,
  required,
} from 'components/global/form/FormValidations'
import { useForm, useFormState } from 'react-final-form'
import { GENDER, RELIGION } from 'components/global/constants'
import { useDispatch, useSelector } from 'utils/hooks'
import { listCountries } from 'redux/setting/v2/countries/actionCreators'
import { useEffect } from 'react'

export const PersonalInfo = () => {
  const form = useForm()
  const { values } = useFormState()
  const countries = useSelector((s) => s.countriesv2.countries)
  const dispatch = useDispatch()

  const {
    nationalities,
  }: {
    nationalities: Array<{
      id: number
      name: string
      name_i18n: string
    }>
  } = useSelector(({ staticRecord }) => staticRecord)

  const gridItemProps = {
    item: true,
    columnSpacing: 3,
  }

  const identification_key = 'personal_info'

  useEffect(() => {
    dispatch(listCountries({ template: 'public' }))
  }, [])

  return (
    <div className="flex flex-col gap-8">
      <Typography
        text={i18n.t('label.personal_info')}
        variant="title-1"
      />
      <Grid
        container
        rowSpacing={20}
      >
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${identification_key}.full_name_en`}
            label={i18n.t('full_name_english')}
            validate={required}
            required
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${identification_key}.full_name_ar`}
            label={i18n.t('full_name_arabic')}
            validate={required}
            required
          />
        </Grid>
        <Grid {...gridItemProps}>
          <NewSelectField
            name={`${identification_key}.nationality_id`}
            label={i18n.t('nationality')}
            valueKey="id"
            labelKey="name_i18n"
            options={nationalities}
            validate={required}
            required
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${identification_key}.personal_email`}
            label={i18n.t('personal_email')}
            validate={composeValidators(email)}
          />
        </Grid>

        <Grid {...gridItemProps}>
          <TextInputField
            name={`${identification_key}.telephone`}
            validate={composeValidators(naturalNumber, minLength(7), maxLength(10))}
            label={i18n.t('phone')}
            as="dropdown"
            listItems={countries}
            dropdownValue={values?.personal_info?.country_phone_code}
            dropdownValueKey="phone_code"
            dropdownLabelKey="phone_code"
            dropdownSublabelKey="name_i18n"
            onDropdownChange={(value: string, dropdownValue?: string): void => {
              form.change(`${identification_key}.telephone`, value)
              form.change(`${identification_key}.country_phone_code`, dropdownValue)
            }}
          />
          <Field
            component={() => null}
            name={`${identification_key}.country_phone_code`}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <NewSelectField
            name={`${identification_key}.gender`}
            label={i18n.t('gender')}
            valueKey="id"
            labelKey="name"
            options={GENDER}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <NewSelectField
            name={`${identification_key}.religion`}
            label={i18n.t('religion')}
            valueKey="id"
            labelKey="name"
            options={RELIGION}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <DatePickerDSField
            name={`${identification_key}.date_of_birth`}
            label={i18n.t('date_of_birth')}
          />
        </Grid>
      </Grid>
    </div>
  )
}
