import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  probation_period: {},
  fetching: false,
}

type ProbationPeriodState = typeof initialState

export default function reducer(state = initialState, action: Action): ProbationPeriodState {
  const { payload } = action
  switch (action.type) {
    case actions.PROBATION_DELETE_PENDING:
    case actions.PROBATION_UPDATE_PENDING:
    case actions.PROBATION_EDIT_PENDING:
    case actions.PROBATION_CREATE_PENDING:
    case actions.PROBATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PROBATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.PROBATION_DELETE_FULFILLED:
    case actions.PROBATION_UPDATE_FULFILLED:
    case actions.PROBATION_EDIT_FULFILLED:
    case actions.PROBATION_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.PROBATION_DELETE_REJECTED:
    case actions.PROBATION_UPDATE_REJECTED:
    case actions.PROBATION_EDIT_REJECTED:
    case actions.PROBATION_CREATE_REJECTED:
    case actions.PROBATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
