import { useEffect, useState } from 'react'
import { Grid } from 'components/global/atoms'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import cns from 'classnames'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  onboardingLeaveTypesLoad,
  onboardingLeaveTypesCreate,
  onboardingLeaveTypesUpdate,
} from 'redux/leavesOnboarding/leaveTypes/actionCreators'
import { LeaveType, LeavesFormDataTypes } from 'redux/leavesOnboarding/leaveTypes/type'
import FormLeavesTypes from '../../LeavesTypes/FormLeavesTypes'
import styles from '../style.module.css'

const LeavesTypes = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [editData, setEditData] = useState<{ leave_types?: LeaveType }>({})
  const oddDefCustomLeaves = ['Casual', 'Emergency']

  const isEmpty = Object.keys(editData).length === 0

  const dispatch = useDispatch()
  const { leave_types, fetching } = useSelector(({ onboardingLeaveTypes }) => onboardingLeaveTypes)

  const defaultLeaves = leave_types.filter((item) => item.is_default)

  const handleChange = (data: LeaveType): void => {
    dispatch(onboardingLeaveTypesUpdate({ ...data, is_active: !data.is_active })).then(() => {
      dispatch(onboardingLeaveTypesLoad())
    })
  }

  const onSubmit = (data: { leave_types: LeavesFormDataTypes }): void => {
    if (isEmpty) {
      dispatch(onboardingLeaveTypesCreate({ ...data.leave_types, is_active: true })).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    } else {
      dispatch(onboardingLeaveTypesUpdate(data.leave_types)).then(() => {
        setModalOpen(false)
        dispatch(onboardingLeaveTypesLoad())
      })
    }
  }

  useEffect(() => {
    dispatch(onboardingLeaveTypesLoad())
  }, [dispatch])

  return (
    <>
      <Typography
        variant="heading"
        text={I18n.t('labor_law_leaves')}
      />

      <Spacer height="16px" />

      <Flex
        flexCol
        className={styles.contentLeaves}
      >
        <Flex
          flexCol
          className={cns(styles.cardsContainer, 'scroll-y')}
        >
          <Grid
            container
            spacing={2}
          >
            {defaultLeaves?.map((item, i) => (
              <Grid
                item
                xs={6}
                key={i}
                style={{ paddingInline: 4 }}
              >
                <SwitchCard
                  name={item.name_i18n}
                  isActive={item.is_active}
                  days={item.days_per_year}
                  handleOnEdit={(): void => {
                    setEditData({ leave_types: item })
                    setModalOpen(true)
                  }}
                  handleOnChange={(): void => {
                    handleChange(item)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Flex>
      </Flex>

      <FormLeavesTypes
        isOpen={modalOpen}
        onSubmit={onSubmit}
        onClose={setModalOpen}
        initialValues={editData}
        fetching={fetching}
        isEmpty={isEmpty}
        oddDefCustomLeaves={oddDefCustomLeaves}
      />
    </>
  )
}

export default LeavesTypes
