import { lens, withLenses } from '@dhmk/zustand-lens'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  announcementsDrawer,
  AnnouncementsDrawer,
  CreateAnnouncement,
  createAnnouncement,
} from './Announcement/Announcement'
import {
  GroupsStateType,
  createGroupsSlice,
  createGroupsApprovalCycleSlice,
  GroupsApprovalCycleStateType,
} from './Groups/GroupsSlice'

type OrgManagementStore = {
  groups: GroupsStateType
  groupsApprovalCycle: GroupsApprovalCycleStateType
  announcement: CreateAnnouncement
  announcementsDrawer: AnnouncementsDrawer
}

export const useOrgManagement = create<OrgManagementStore>()(
  devtools(
    withLenses(() => ({
      groups: lens(createGroupsSlice),
      groupsApprovalCycle: lens(createGroupsApprovalCycleSlice),
      announcement: lens(createAnnouncement),
      announcementsDrawer: lens(announcementsDrawer),
    })),
    {
      name: 'OrgManagementStore',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
)
