export const VACATION_SETTELEMENT_APPROVALS_LIST_LOAD = 'VACATION_SETTELEMENT_APPROVALS_LIST_LOAD'
export const VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_PENDING =
  'VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_PENDING'
export const VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_FULFILLED =
  'VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_FULFILLED'
export const VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_REJECTED =
  'VACATION_SETTELEMENT_APPROVALS_LIST_LOAD_REJECTED'

export const VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD = 'VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_PENDING =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_PENDING'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_FULFILLED =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_FULFILLED'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_REJECTED =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_LOAD_REJECTED'

export const VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_PENDING =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_PENDING'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_FULFILLED =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_FULFILLED'
export const VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_REJECTED =
  'VACATION_SETTELEMENT_APPROVALS_STEPS_UPDATE_REJECTED'
