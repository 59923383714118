export type TimeToNumbersReturn = {
  hour: number
  hour24: number
  minutes: number
  ampm: string | false
}

export const pad = (number: unknown, noLimit?: boolean): string => {
  let result = String(`0${number}`).slice(-2)
  const val = String(number)

  if (noLimit && val?.length > 2) {
    result = val
  }

  return result
}

const ampmGraper = (text: string, hours: number): string | false => {
  if (!text) return false
  const context = text.toLowerCase().replace(/[^ap]/g, '')
  const ampm = context.slice(-1).replace('a', 'am').replace('p', 'pm')
  const results = hours > 12 ? 'pm' : ampm || 'am'

  return results
}

export const timeToNumbers = (time: unknown, noLimit?: boolean): false | TimeToNumbersReturn => {
  if (!time || typeof time !== 'string') return false

  const timeArr = time?.split(':')

  if (timeArr.length < 2) return false
  if (timeArr[1] === '') return false

  const secondPart = timeArr[1].split(' ')

  let hour = parseInt(timeArr[0], 10)
  let minutes = parseInt(secondPart[0], 10)
  const ampm = ampmGraper(secondPart[1]?.toLowerCase(), hour)
  let hour24 = ampm === 'pm' ? hour + 12 : hour
  if (ampm === 'am' && hour === 12) {
    hour24 = 0
  } else if (hour === 12) {
    hour24 = 12
  }

  if (hour > 24 && !noLimit) hour = 0
  if (minutes > 59) minutes = 0

  return {
    hour,
    hour24,
    minutes,
    ampm,
  }
}

export const isValidTime = (time: unknown, noLimit?: boolean): boolean => {
  if (!time) return false
  const numbers = timeToNumbers(time, noLimit)
  if (!numbers) return false
  if ((numbers.hour > 24 && !noLimit) || numbers.minutes > 60) return false

  return true
}

export const getTimeChars = (value: string): string => value.replace(/[^\d:ap]/g, '')

export const timeGraper = (
  text: string | undefined,
  is24Hour: boolean | undefined,
  timeOnly: boolean | undefined,
  withResetTime: boolean | undefined,
  noLimit?: boolean,
): string | false => {
  const clean = getTimeChars(text ?? '')

  const time = timeToNumbers(clean, noLimit)
  const defaultHour = withResetTime ? 0 : 1

  const numbers = clean.replace(/[^0-9]/g, '')

  let hours: number | string = numbers
  let minutes: number | string = '00'

  const count = numbers.length
  if (count <= 0) return false

  if (count >= 3) minutes = numbers.slice(-2)
  hours = count > 3 ? numbers.substring(0, 2) : numbers.replace(minutes, '')

  if (count > 4 && noLimit) {
    hours = numbers.substring(0, count - 2)
  }

  hours = (time && time?.hour) || parseInt(hours || String(defaultHour), 10)
  hours = !withResetTime && hours < 1 ? 1 : hours

  minutes = (time && time?.minutes) || parseInt(minutes, 10)

  if ((hours > 24 || minutes > 59) && !noLimit) return false

  let ampm = (time && time?.ampm) || ampmGraper(text ?? '', hours)

  if (is24Hour || timeOnly) ampm = ''

  if (!is24Hour && hours > 12) hours -= 12

  return `${pad(hours, noLimit)}:${pad(minutes)} ${ampm}`
}
