import RenderCheckbox from '../RenderCheckbox'

type FilterBoxSubObtionsListProps = {
  handleFilter: (key: string, opt: string | number | number[]) => void
  subOptions: Array<Record<string, string | number | number[]>>
  options: Array<Record<string, string | number | number[]>>
  selectedIds: Array<number>
  subSelectedIds: Array<number>
  optionKey: string
  subOptionKey: string
  subOptionFKey: string
  optionFilterKey: string
  subOptionFilterKey: string
}

const FilterBoxSubObtionsList = (props: FilterBoxSubObtionsListProps): JSX.Element => {
  const {
    options,
    optionKey,
    subOptions,
    subOptionFKey,
    selectedIds,
    subOptionKey,
    optionFilterKey,
    subSelectedIds,
    subOptionFilterKey,
    handleFilter,
  } = props

  return (
    <>
      {options.map((option, i) => {
        let isFirst = true
        return subOptions.map(
          (subOption: Record<string, string | number | number[]>, j: number) => {
            if (option?.[optionKey] === subOption?.[subOptionFKey]) {
              const showParent = isFirst
              isFirst = false

              return (
                <>
                  {showParent && (
                    <RenderCheckbox
                      key={i}
                      data={option}
                      selectedIds={selectedIds}
                      filterKey={optionFilterKey}
                      handleFilter={(): void => handleFilter(optionFilterKey, option?.[optionKey])}
                    />
                  )}
                  <div style={{ paddingLeft: 16, paddingTop: 5 }}>
                    <RenderCheckbox
                      key={`${i}-${j}`}
                      data={subOption}
                      selectedIds={subSelectedIds}
                      filterKey={subOptionFilterKey}
                      handleFilter={(): void =>
                        handleFilter(subOptionFilterKey, subOption?.[subOptionKey])
                      }
                    />
                  </div>
                </>
              )
            }
            return null
          },
        )
      })}
    </>
  )
}

export default FilterBoxSubObtionsList
