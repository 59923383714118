import { ReactNode, useEffect, useMemo } from 'react'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'utils/hooks'
import { orderBy, omit } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import I18n from 'translations/i18n'
import { Form, TextInputField, SubdomainInputField } from 'components/final-form'
import {
  onboardingSlugRest,
  organizationInfo,
  slugAvaliable,
  updateOrganization,
} from 'redux/organizationOnborading/organization/actionCreators'
import { required } from 'components/global/form/FormValidations'
import GetFields from 'components/GetFields'
import { TAnswers } from 'components/GetFields/type'
import {
  answerOnboardingQuestions,
  onboardingQuestionsReset,
  showOnboardingQuestions,
} from 'redux/organizationOnborading/onboardingQuestions/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { APP_DOMAIN_URL } from 'env'
import StepTemplate from '../../../components/StepTemplate'
import styles from './style.module.css'

type TSubmitValue = {
  slug: string
  name: string
  name_ar?: string
  brand_name?: string
  brand_name_ar?: string
  answers: TAnswers
}

const OrganizationPage = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  let handleNextStep = (): void => undefined
  const dispatch = useDispatch()
  const { organization, fetching } = useSelector(
    ({ organization: organizationSelector }) => organizationSelector,
  )
  const { questions, fetching: fetchingQuestions } = useSelector(
    ({ onboardingQuestions }) => onboardingQuestions,
  )
  const slug_avaliable = useSelector((s) => s.organization?.slug_avaliable)

  const answers = useMemo(
    () =>
      questions.map((e) => ({
        question_id: e.id,
        option_ids: e.selected_option_ids,
      })),
    [questions.length],
  )

  const onSubmit = (value: TSubmitValue): void => {
    const data = {
      ...omit(value, ['answers']),
      brand_name: value.brand_name || null,
      brand_name_ar: value.brand_name_ar || null,
    }
    if (organization.slug === value.slug) {
      dispatch(updateOrganization(data)).then(() => {
        dispatch(answerOnboardingQuestions(value.answers, 'org_structure_and_logo'))
        goNextStep?.()
      })
    } else {
      dispatch(slugAvaliable({ slug: value.slug, search_all: true })).then((res) => {
        if (res.value.data.data.available) {
          dispatch(updateOrganization(data)).then(() => {
            dispatch(answerOnboardingQuestions(value.answers, 'org_structure_and_logo'))
            goNextStep?.()
          })
        }
      })
    }
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'organization_structure' })

  useEffect(() => {
    dispatch(onboardingQuestionsReset())
    dispatch(organizationInfo())
    dispatch(showOnboardingQuestions('org_structure_and_logo'))
    dispatch(onboardingSlugRest())
  }, [])

  return (
    <StepTemplate
      goNextStep={(): void => handleNextStep()}
      nextBtnType="submit"
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          answers,
          name: organization.name,
          name_ar: organization.name_ar,
          slug: organization.slug,
          brand_name: organization.brand_name,
          brand_name_ar: organization.brand_name_ar,
        }}
        render={({ handleSubmit }): ReactNode => {
          handleNextStep = handleSubmit
          return (
            <Flex
              flexCol
              className={styles.content}
            >
              <Flex flexCol>
                <Typography
                  variant="subheading"
                  text={I18n.t('whats_the_name_of_your_organization')}
                />
                <Spacer height="16px" />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name"
                  placeholder={I18n.t('organization_name_en')}
                  validate={required}
                  style={{ maxWidth: '400px' }}
                />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name_ar"
                  placeholder={I18n.t('organization_name_ar')}
                  style={{ maxWidth: '400px' }}
                />
              </Flex>
              <Flex flexCol>
                <Typography
                  variant="subheading"
                  text={I18n.t('brand_name')}
                />
                <Spacer height="16px" />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="brand_name"
                  placeholder={I18n.t('brand_name_en')}
                  style={{ maxWidth: '400px' }}
                />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="brand_name_ar"
                  placeholder={I18n.t('brand_name_ar')}
                  style={{ maxWidth: '400px' }}
                />
              </Flex>
              {!!questions.length && (
                <Flex
                  flexCol
                  style={{ gap: 16 }}
                >
                  <FieldArray name="answers">
                    {({ fields }): JSX.Element[] => {
                      return fields.map((name, key) => {
                        const elements = questions[key]
                        return (
                          <div
                            key={name}
                            style={{ maxWidth: 400 }}
                          >
                            <GetFields
                              name={name}
                              index={key}
                              id={elements.id}
                              menuPlacement="top"
                              fetching={fetchingQuestions}
                              required={elements.is_required}
                              input_type={elements.input_type}
                              name_i18n={elements.name_i18n}
                              options={orderBy(elements.options, ['priority'], ['asc'])}
                            />
                          </div>
                        )
                      })
                    }}
                  </FieldArray>
                </Flex>
              )}

              <Flex flexCol>
                <Typography
                  variant="subheading"
                  text={I18n.t('what_subdomain_would_description', {
                    app_name: I18n.t('jisr_translation_name'),
                  })}
                />
                <Spacer height="16px" />
                {/* @ts-expect-error Need to migrate SubdomainInputField.js to TypeScript */}
                <SubdomainInputField
                  domain={`.${APP_DOMAIN_URL}`}
                  name="slug"
                  placeholder="xxxx"
                  validate={required}
                  style={{ maxWidth: '400px' }}
                  loading={fetching}
                />
                {!slug_avaliable && (
                  <Typography
                    text={I18n.t('check_slug')}
                    variant="subtitle-2"
                    textColor="color/fg/danger/default"
                  />
                )}
              </Flex>
            </Flex>
          )
        }}
      />
    </StepTemplate>
  )
}

export default OrganizationPage
