import { Flex } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { format } from 'date-fns'
import { getPublicFilterKeys } from 'components/Filter/helper'
import JisrLoader from 'components/global/JisrLoader'
import {
  Comments,
  HiringEmployeesDetails,
  Info,
  Tabs,
  Workflow,
} from 'components/global/templates/RequestDrawer/components'
import { filters } from 'containers/authorised/ATS/organisms/JobControlFilters/constants'
import React from 'react'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import { getPublicTemplates } from 'redux/publicTemplates/actionCreators'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const HiringRequest = () => {
  const dispatch = useDispatch()
  const { loading, request, reloadRequest } = useRequestDetail()
  const { location, business_unit, employment_type } = useSelector(
    ({ publicTemplates }) => publicTemplates,
  )

  const { employee_all } = useSelector(({ employee }) => employee)
  const authEmployee = useSelector(({ auth }) => auth.employee)
  const userLocation = location.find((address) => address.id === request.location_id)
  const employmentType = employment_type.find((name) => name.id === request.employment_type_id)
  const businessUnit = business_unit.find((name) => name.id === request.business_unit_id)
  const hiringManager = employee_all.find((employee) => employee.id === request.hiring_manager_id)
  const [activeTab, setActiveTab] = React.useState('request_details')
  const items = [
    {
      label: i18n.t('label.job_title_english'),
      icon: <DSIcon name="briefcase-02" />,
      value: request?.title_en,
    },
    {
      label: i18n.t('label.job_title_arabic'),
      icon: <DSIcon name="briefcase-02" />,
      value: request?.title_ar,
    },
  ]

  React.useEffect(() => {
    dispatch(
      getPublicTemplates({
        filters: getPublicFilterKeys(filters, {}, false),
      }),
    )
    dispatch(getAllEmployeesLoad())
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => {
          if (tab === 'workflow_and_comments') {
            const mixPanelData = {
              event_name: 'clicking_on_approvals_and_comments',
              properties: {
                user_id: authEmployee?.id,
                timestamp: format(new Date(), 'HH:mm dd/MM/yy'),
              },
            }
            mixPanelAnalytics(mixPanelData)
          }
          setActiveTab(tab)
        }}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              employee: request?.employee,
              created_at: request.created_at,
              requested_on: request.created_at,
              created_by: request.created_by,
              status: request?.status,
              status_i18n: request?.status_i18n,
              type_i18n: request?.request_name_i18n,
              request: request?.request,
              request_name: request?.request_name,
              cancellation_request_info: request?.cancellation_request_info,
              is_cancellation_request: request?.is_cancellation_request,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
              items,
            }}
          />
          <HiringEmployeesDetails
            noOfVacancies={request?.no_of_vacancies}
            title={request?.title_i18n}
            hiringMonth={request?.month_and_year_i18n}
            description={request?.description ?? ''}
            location={userLocation?.address_i18n}
            employmentType={employmentType?.name_i18n}
            businessUnit={businessUnit?.name_i18n}
            hiringManagerName={hiringManager?.name}
            hiringManagerJobTitle={hiringManager?.job_title_i18n}
            hiringManagerAvatar={hiringManager?.avatar_thumb}
            attachments={request?.attachments}
          />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}
      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals_details} />
        </>
      )}
    </Flex>
  )
}

export default HiringRequest
