export const PAYRUN_SEND_REMINDER = 'PAYRUN_SEND_REMINDER'
export const PAYRUN_SEND_REMINDER_PENDING = 'PAYRUN_SEND_REMINDER_PENDING'
export const PAYRUN_SEND_REMINDER_FULFILLED = 'PAYRUN_SEND_REMINDER_FULFILLED'
export const PAYRUN_SEND_REMINDER_REJECTED = 'PAYRUN_SEND_REMINDER_REJECTED'

export const PAYROLL_PAYRUN_CONFIRM = 'PAYROLL_PAYRUN_CONFIRM'
export const PAYROLL_PAYRUN_CONFIRM_PENDING = 'PAYROLL_PAYRUN_CONFIRM_PENDING'
export const PAYROLL_PAYRUN_CONFIRM_FULFILLED = 'PAYROLL_PAYRUN_CONFIRM_FULFILLED'
export const PAYROLL_PAYRUN_CONFIRM_REJECTED = 'PAYROLL_PAYRUN_CONFIRM_REJECTED'

export const PAYROLL_PAYRUN_CANCEL = 'PAYROLL_PAYRUN_CANCEL'
export const PAYROLL_PAYRUN_CANCEL_PENDING = 'PAYROLL_PAYRUN_CANCEL_PENDING'
export const PAYROLL_PAYRUN_CANCEL_FULFILLED = 'PAYROLL_PAYRUN_CANCEL_FULFILLED'
export const PAYROLL_PAYRUN_CANCEL_REJECTED = 'PAYROLL_PAYRUN_CANCEL_REJECTED'

export const PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS = 'PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS'
export const PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_PENDING =
  'PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_PENDING'
export const PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_FULFILLED =
  'PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_FULFILLED'
export const PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_REJECTED =
  'PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_REJECTED'

export const PAYRUN_DETAIL_LOAD = 'PAYRUN_DETAIL_LOAD'
export const PAYRUN_DETAIL_LOAD_PENDING = 'PAYRUN_DETAIL_LOAD_PENDING'
export const PAYRUN_DETAIL_LOAD_FULFILLED = 'PAYRUN_DETAIL_LOAD_FULFILLED'
export const PAYRUN_DETAIL_LOAD_REJECTED = 'PAYRUN_DETAIL_LOAD_REJECTED'

export const PAYRUN_DETAIL_LOAD_WITH_RESET = 'PAYRUN_DETAIL_LOAD_WITH_RESET'
export const PAYRUN_DETAIL_LOAD_WITH_RESET_PENDING = 'PAYRUN_DETAIL_LOAD_WITH_RESET_PENDING'
export const PAYRUN_DETAIL_LOAD_WITH_RESET_FULFILLED = 'PAYRUN_DETAIL_LOAD_WITH_RESET_FULFILLED'
export const PAYRUN_DETAIL_LOAD_WITH_RESET_REJECTED = 'PAYRUN_DETAIL_LOAD_WITH_RESET_REJECTED'

export const PAYRUNS_LOAD = 'PAYRUNS_LOAD'
export const PAYRUNS_LOAD_PENDING = 'PAYRUNS_LOAD_PENDING'
export const PAYRUNS_LOAD_FULFILLED = 'PAYRUNS_LOAD_FULFILLED'
export const PAYRUNS_LOAD_REJECTED = 'PAYRUNS_LOAD_REJECTED'

export const PREVIOUS_PAYRUNS = 'PREVIOUS_PAYRUNS'
export const PREVIOUS_PAYRUNS_PENDING = 'PREVIOUS_PAYRUNS_PENDING'
export const PREVIOUS_PAYRUNS_FULFILLED = 'PREVIOUS_PAYRUNS_FULFILLED'
export const PREVIOUS_PAYRUNS_REJECTED = 'PREVIOUS_PAYRUNS_REJECTED'

export const START_PAYRUNS = 'START_PAYRUNS'
export const START_PAYRUNS_PENDING = 'START_PAYRUNS_PENDING'
export const START_PAYRUNS_FULFILLED = 'START_PAYRUNS_FULFILLED'
export const START_PAYRUNS_REJECTED = 'START_PAYRUNS_REJECTED'

export const SKIP_PAYRUNS = 'SKIP_PAYRUNS'
export const SKIP_PAYRUNS_PENDING = 'SKIP_PAYRUNS_PENDING'
export const SKIP_PAYRUNS_FULFILLED = 'SKIP_PAYRUNS_FULFILLED'
export const SKIP_PAYRUNS_REJECTED = 'SKIP_PAYRUNS_REJECTED'

export const PREVIOUS_PERIODS_PAYRUNS = 'PREVIOUS_PERIODS_PAYRUNS'
export const PREVIOUS_PERIODS_PAYRUNS_PENDING = 'PREVIOUS_PERIODS_PAYRUNS_PENDING'
export const PREVIOUS_PERIODS_PAYRUNS_FULFILLED = 'PREVIOUS_PERIODS_PAYRUNS_FULFILLED'
export const PREVIOUS_PERIODS_PAYRUNS_REJECTED = 'PREVIOUS_PERIODS_PAYRUNS_REJECTED'

export const REOPEN_PAYRUNS = 'REOPEN_PAYRUNS'
export const REOPEN_PAYRUNS_PENDING = 'REOPEN_PAYRUNS_PENDING'
export const REOPEN_PAYRUNS_FULFILLED = 'REOPEN_PAYRUNS_FULFILLED'
export const REOPEN_PAYRUNS_REJECTED = 'REOPEN_PAYRUNS_REJECTED'

export const PAYRUN_ACTIVATION_STATUS = 'PAYRUN_ACTIVATION_STATUS'
export const PAYRUN_ACTIVATION_STATUS_PENDING = 'PAYRUN_ACTIVATION_STATUS_PENDING'
export const PAYRUN_ACTIVATION_STATUS_REJECTED = 'PAYRUN_ACTIVATION_STATUS_REJECTED'
export const PAYRUN_ACTIVATION_STATUS_FULFILLED = 'PAYRUN_ACTIVATION_STATUS_FULFILLED'

export const PAYRUN_SET_ACTIVATION_COMPLETED = 'PAYRUN_SET_ACTIVATION_COMPLETED'
export const PAYRUN_SET_ACTIVATION_COMPLETED_PENDING = 'PAYRUN_SET_ACTIVATION_COMPLETED_PENDING'
export const PAYRUN_SET_ACTIVATION_COMPLETED_REJECTED = 'PAYRUN_SET_ACTIVATION_COMPLETED_REJECTED'
export const PAYRUN_SET_ACTIVATION_COMPLETED_FULFILLED = 'PAYRUN_SET_ACTIVATION_COMPLETED_FULFILLED'

export const PAYRUN_UPDATE_ACTIVATION_STEP_STATUS = 'PAYRUN_UPDATE_ACTIVATION_STEP_STATUS'
export const PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_PENDING =
  'PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_PENDING'
export const PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_REJECTED =
  'PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_REJECTED'
export const PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_FULFILLED =
  'PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_FULFILLED'

export const LAST_IMPORT_LOAD = 'LAST_IMPORT_LOAD'
export const LAST_IMPORT_LOAD_PENDING = 'LAST_IMPORT_LOAD_PENDING'
export const LAST_IMPORT_LOAD_FULFILLED = 'LAST_IMPORT_LOAD_FULFILLED'
export const LAST_IMPORT_LOAD_REJECTED = 'LAST_IMPORT_LOAD_REJECTED'

export const PAYSCHEDULES_LOAD = 'PAYSCHEDULES_LOAD'
export const PAYSCHEDULES_LOAD_PENDING = 'PAYSCHEDULES_LOAD_PENDING'
export const PAYSCHEDULES_LOAD_FULFILLED = 'PAYSCHEDULES_LOAD_FULFILLED'
export const PAYSCHEDULES_LOAD_REJECTED = 'PAYSCHEDULES_LOAD_REJECTED'

export const PAYSCHEDULES_CREATE = 'PAYSCHEDULES_CREATE'
export const PAYSCHEDULES_CREATE_PENDING = 'PAYSCHEDULES_CREATE_PENDING'
export const PAYSCHEDULES_CREATE_FULFILLED = 'PAYSCHEDULES_CREATE_FULFILLED'
export const PAYSCHEDULES_CREATE_REJECTED = 'PAYSCHEDULES_CREATE_REJECTED'

export const PAYSCHEDULES_UPDATE = 'PAYSCHEDULES_UPDATE'
export const PAYSCHEDULES_UPDATE_PENDING = 'PAYSCHEDULES_UPDATE_PENDING'
export const PAYSCHEDULES_UPDATE_FULFILLED = 'PAYSCHEDULES_UPDATE_FULFILLED'
export const PAYSCHEDULES_UPDATE_REJECTED = 'PAYSCHEDULES_UPDATE_REJECTED'

export const RESET_SPECIFIC_LAST_IMPORT = 'RESET_SPECIFIC_LAST_IMPORT'

export const EMPLOYEES_WITHOUT_CR_UPDATE = 'EMPLOYEES_WITHOUT_CR_UPDATE'
export const EMPLOYEES_WITHOUT_CR_UPDATE_PENDING = 'EMPLOYEES_WITHOUT_CR_UPDATE_PENDING'
export const EMPLOYEES_WITHOUT_CR_UPDATE_FULFILLED = 'EMPLOYEES_WITHOUT_CR_UPDATE_FULFILLED'
export const EMPLOYEES_WITHOUT_CR_UPDATE_REJECTED = 'EMPLOYEES_WITHOUT_CR_UPDATE_REJECTED'
