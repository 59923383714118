import axios, { AxiosRequestConfig } from 'axios'
import Qs, { IStringifyOptions } from 'qs'
import errorResponseHandler from 'utils/errorHandler'
import { API_ROOT, ORGANISATION_NAME } from '../env'

export const API_VERSION_TWO = 'v2'
export const API_VERSION_ONE = 'v1'

export const serializerConfig: IStringifyOptions = {
  arrayFormat: 'brackets',
  encode: false,
}

const pincode = btoa(sessionStorage.getItem(atob('pincode')) || '')

export const headersInfo: AxiosRequestConfig['headers'] = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  locale: localStorage.getItem('Locale') || 'en',
  slug: ORGANISATION_NAME,
  token: localStorage.getItem('onboarding_token'),
  ...(pincode
    ? {
        pincode,
      }
    : {}),
}

export const api = axios.create({
  baseURL: API_ROOT,
  withCredentials: true,
  headers: headersInfo,
  paramsSerializer: (params) => {
    if (params?.search) params.search = encodeURIComponent(params.search)
    return Qs.stringify(params, serializerConfig)
  },
})

api.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: ORGANISATION_NAME,
  }
  return config
})

// ? To prevent Storybook, because we handling dummy API response
api.interceptors?.response?.use(undefined, errorResponseHandler)
