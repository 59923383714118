import React from 'react'
import { Table } from '@jisr-hr/design-system'
import { isArray } from 'lodash'
import I18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'

export const handleTable = (data) => {
  if (data.length !== 0) {
    return (
      <Table
        variant="rounded"
        headers={data.map(({ name }) => ({
          label: name,
        }))}
      >
        <Table.Row>
          {data.map(({ value }, i) => (
            <Table.Cell
              key={i}
              style={{ minWidth: 128 }}
            >
              <Typography
                text={isArray(value) ? value.join(', ') : value}
                variant="body-new/regular"
              />
            </Table.Cell>
          ))}
        </Table.Row>
      </Table>
    )
  }
  return (
    <Typography
      text={I18n.t('no_data')}
      variant="interface/m/sm"
    />
  )
}

export const exitReentryTables = (data) => {
  const { employee, exit_reentry_info, exit_reentry_issuing_task, exit_reentry_payment_task } =
    data?.task_info || {}

  if (data.length !== 0) {
    return (
      <Table
        variant="rounded"
        headers={[
          I18n.t('traveler'),
          I18n.t('period'),
          I18n.t('type'),
          I18n.t('reason'),
          I18n.t('issuing_status'),
          I18n.t('issuing_assignee'),
          I18n.t('payment_status'),
          I18n.t('payment_assignee'),
        ]}
      >
        <Table.Row>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={employee?.name}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_info?.period}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_info?.entry_type_i18n}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_info?.reason}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_issuing_task?.assignee}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_issuing_task?.status}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_payment_task?.assignee}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={exit_reentry_payment_task?.status}
              variant="body-new/regular"
            />
          </Table.Cell>
        </Table.Row>
      </Table>
    )
  }
  return (
    <Typography
      text={I18n.t('no_data')}
      variant="interface/m/sm"
    />
  )
}

export const handleTicketTables = (data) => {
  const { employee, ticket_info, ticket_issuing_task, ticket_payment_task } = data?.task_info || {}
  const travelers = ticket_info?.family_details?.map((item) => {
    return item?.name
  })

  if (data.length !== 0) {
    return (
      <Table
        variant="rounded"
        headers={[
          I18n.t('traveler'),
          I18n.t('leaving_day'),
          I18n.t('return_date'),
          I18n.t('issuing_status'),
          I18n.t('issuing_assignee'),
          I18n.t('payment_status'),
          I18n.t('payment_assignee'),
        ]}
      >
        <Table.Row>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={`${employee?.name} ,${travelers?.toString() || ''}`}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_info?.leaving_date}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_info?.returning_date}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_issuing_task?.assignee}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_issuing_task?.status}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_payment_task?.assignee}
              variant="body-new/regular"
            />
          </Table.Cell>
          <Table.Cell style={{ minWidth: 128 }}>
            <Typography
              text={ticket_payment_task?.status}
              variant="body-new/regular"
            />
          </Table.Cell>
        </Table.Row>
      </Table>
    )
  }
  return (
    <Typography
      text={I18n.t('no_data')}
      variant="interface/m/sm"
    />
  )
}
