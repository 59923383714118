const gl_mapping = {
  // A
  allowances: 'الراتب والبدلات',
  assign_type: 'نوع التعيين',
  assign_employees_type: 'طريقة تحديد الشركة الفرعية',
  assign_employees_type_modal: 'طريقة تحديد الشركة الفرعية',
  assign_employees_type_tracking: 'طريقة تحديد عناصر مركز التكلفة',
  assign_to_business_unit: 'تعيين إلى وحده العمل',
  assign_to_subsidiary: 'إضافة إلى شركة فرعية',
  select_subsidiary: 'أختر شركة فرعية',
  assign_employee_to: 'تعيين الموظفين لـ',
  account_number: 'رقم الحساب',
  account_name: 'إسم الحساب',
  activate: 'تفعيل',
  applicable_subsidiaries: 'الشركات الفرعية',
  accounts_mapping: 'ربط شجرة الحسابات',
  all_employees: 'كل الموظفين ({{ number }})',
  all_dimensions: 'مزامنة نموذج الموظف ({{ number }})',
  assigned_subsidiary: 'الشركة الفرعية',
  allowances_netsuite: 'الراتب والبدلات ({{ mapped }} من {{ all }} مرتبط)',
  absenteeism_transactions_netsuite: 'التأخير والغيابات ({{ mapped }} من {{ all }} مرتبط)',
  accruals_netsuite: 'المخصصات والمستحقات ({{ mapped }} من {{ all }} مرتبط)',
  loan_types_netsuite: 'السلف ({{ mapped }} من {{ all }} مرتبط)',
  other_paytypes_netsuite: 'مدفوعات اخرى ({{ mapped }} من {{ all }} مرتبط)',
  other_deductions_netsuite: 'خصميات اخرى ({{ mapped }} من {{ all }} مرتبط)',
  absenteeism_transactions_netsuite_description:
    'تحتوي القائمة على معاملات الحضور والانصراف (التأخير، الخروج المبكر، الغياب، نسيان البصمة و الإجازة الغير مدفوعة)',
  activate_account_warning_note:
    'يبدو أن {{ current }} مفعل حالياً ، وأنت الآن تحاول تنشيط {{ target }}. سيؤدي القيام بذلك إلى ازالة جميع الإعدادات التي تخص {{ current }} ',
  change_account_warning_note:
    'يبدو أن الربط مع {{accounting_name}} موجود بالفعل ، وأنت الآن تحاول تنشيط الاتصال بحساب مختلف. سيؤدي القيام بذلك إلى تدمير كل الإعدادات الحالية مع ربط {{accounting_name}} ',
  applicable: 'Applicable AR',
  other_deductions_netsuite_description: 'تحتوي القائمة على أنواع الخصميات الآخرى',
  loan_types_netsuite_description: 'تحتوي القائمة على أنواع السلف',
  other_paytypes_netsuite_description: 'تحتوي القائمة على أنواع المدفوعات الآخرى',
  accruals_netsuite_description:
    'تحتوي القائمة على مخصصات ومستحقات (الإجازة، نهاية الخدمة، التأمينات الاجتماعية وصافي الراتب)',
  allowances_netsuite_description:
    'تحتوي القائمة على الراتب الأساسي، البدلات وزيادات الراتب بأثر رجعي',
  assign_employees: 'تعيين الموظفين',
  assigned: 'معينين',
  assign_all_employees: 'اضافة {{number}} موظف',
  assign_filtered_employees: 'اضافة {{number}} موظف',
  assign_cost_center: 'تعيين مركز تكلفة',
  add_new_item: 'إضافة عنصر جديد',
  add_cost_location: 'اضافة موقع',
  add_cost_class: 'اضافة تصنيف',
  add_cost_department: 'اضافة ادارة',
  integrations: 'الربط والتطبيقات',
  INTEGRATIONS: 'الربط',
  assigned_number: 'معينين {{number}}',
  assign_to_tracking_dimensions: 'تعيين مركز التكلفة',
  absenteeism_transactions: 'التأخير و التغيب',
  accruals: 'المخصصات و المستحقات',
  added_from_this_shift_template: 'Added <Text>{{number}}</Text> from this shift template AR',
  add_more_type: 'اضافة معلومات آخرى',
  added_employees_to_template: 'اضافة <Text>{{number}}</Text> موظف إلى {{target}}',
  added_to_this_shift_template: 'اضافة <Text>{{number}}</Text> إلى {{target}}',
  link_them_now: 'إضافة الموظفين',
  all_employess_connect:
    'جميع الموظفين المرتبطين بوحدة العمل سيتم ربطهم بشكل تلقائي بالشركة الفرعية',
  added_assig_type: 'اضافة',
  accounting_integration: 'الربط المحاسبي',
  accounting_integration_setup: 'تهيئة الربط المحاسبي',
  all_transactions: 'جميع المعاملات المالية',
  // B
  // C
  chart_of_accounts: 'شجرة الحسابات',
  accounts_list_view: 'شجرة الحسابات',
  changing_integration_type: 'هل تريد تغيير نوع الربط المحاسبي؟',
  changing_integration_account: 'تغيير حساب الربط؟',
  current_assign: 'Current Assign:ar',
  create_new_subsidiary: 'إنشاء شركة فرعية جديدة',
  you_can_separate_employee_information:
    'يمكنك فصل معلومات الموظفين حسب الشركات الفرعية، وستظهر في تقارير مستقلة تسهل متابعة المصاريف المالية لكل شركة',
  create_new_subsidary: 'إضافة شركة فرعية جديدة',
  create_new_tracking_dimensions: 'إنشاء مركز التكلفة',
  create_tracking_dimensions: 'إضافة مركز التكلفة',
  create_multiple_tracking_dimensions:
    'تتيح لك مراكز التكلفة تتبع جميع المصاريف المالية حسب مصدرها مثل الفروع, والأقسام, والمشاريع',
  click_here_to_undo: 'قم بالنقر هنا للتراجع',
  customize_your_chart_of_accounts: 'لا توجد شجرة محاسبية',
  custom_assign_employees: 'موظفين غير مضافين لشركة فرعية',
  create_new_gl_account: 'إضافة حساب جديد',
  cost_center_type: 'نوع مركز التكلفة',
  connect_netsuite: 'اربط موقع نت سويت',
  connect_quickbooks_class: 'اربط تصنيف كويك بوكس',
  connect_quickbooks_department: 'اربط ادارة كويك بوكس',
  connect_quickbooks_location: 'اربط الموقع كويك بوكس',
  connect_quickbooks: 'Map QuickBooks {{item}}',
  customize_your_file_structure: 'إعداد ورقة العمل (ملف القيود Excel)',
  customize_your_transaction_entry_code: 'إدخال القيد المحاسبي للمعاملات المالية',
  code_journal_example: 'مثال : 12005-0022-8797 ',
  cost_center_type_in_netsuite: 'نوع مركز التكلفة (نت سويت)',
  cost_center_type_in_quickbooks: 'نوع مركز التكلفة (كويك بوكس)',
  cost_center_type_in_microsoft: 'نوع مركز التكلفة (مايكروسوفت)',
  cost_center_type_in_microsoft_finops: 'نوع مركز التكلفة (MS Dynamics Finance & Operation)',
  cost_center_type_in_oracle_fusion: 'نوع مركز التكلفة (Oracle Fusion)',
  cost_center_type_in_zoho_books: 'نوع مركز التكلفة (Zoho Books)',
  cost_center_type_in_sap: 'نوع مركز التكلفة (Sap Business One)',
  cost_center_type_in_xero: 'نوع مركز التكلفة (زيرو)',
  cost_center_type_in_qoyod: 'نوع مركز التكلفة (قيود)',
  cost_center_type_in_odoo: 'نوع مركز التكلفة (اودو)',
  customize_your_journal_structure:
    'خصص هيكل القيد المحاسبي, وحدد كافة المعلومات المطلوبة لاستخراج ملف القيود الخاص بك حسب الطريقة التي تناسب احتياجك',
  custom_assignment: 'تعيين مخصص',
  create_new_tracking: 'إنشاء عنصر جديد',
  create_new_item: 'إضافة عنصر جديد',
  create_tracking_dimension: 'إنشاء مركز تكلفة',
  custom: 'مخصص',
  current_assigned: 'المعين الحالي',
  combination_code: 'استخدام أكثر من مكون',
  combination_code_separator_view:
    'هكذا سيتم الفصل بين مكونات رقم الحساب التجميعي  (12005{{ code }}0022{{code}}8797)',
  chart_of_accounts_tab: 'الشجرة المحاسبية',
  create_your_gl_accounts_note: 'قم بتعريف شجرة الحسابات الخاصة بك',
  create_your_gl_mapping_to_manage:
    'Create your GL mapping to manage your payment and any content here describe that future AR',
  cool_lets_go_and_start_the_journey: 'جيد, دعنا نبدأ رحلة الاعداد الآن',
  chart_of_account: 'شجرة حسابات (نت سويت)',
  chart_of_account_quickbooks: 'شجرة حسابات (كويك بوكس)',
  chart_of_account_xero: 'شجرة حسابات (زيرو)',
  chart_of_account_qoyod: 'شجرة حسابات (قيود)',
  chart_of_account_microsoft: 'شجرة حسابات (مايكروسوفت)',
  chart_of_account_microsoft_finops: 'شجرة حسابات (MS Dynamics Finance & Operation)',
  chart_of_account_oracle_fusion: 'شجرة حسابات (Oracle Fusion)',
  chart_of_account_zoho_books: 'شجرة حسابات (Zoho Books)',
  chart_of_account_sap: 'شجرة حسابات (Sap Business One)',
  chart_of_account_odoo: 'شجرة حسابات (اودو)',
  // D
  done_complate: 'مكتمل',
  default_chart_of_account: 'شجرة الحسابات الرئيسية',
  main_chart_of_accounts: 'رئيسية',
  disclaimer: 'تنويه',
  disclaimer_netsuite_employee_sync_note:
    'قبل تفعيل خيار مزامنة معلومات  الموظفين, في حال وجود سجلات موظفين في نت سويت ينبغي التأكد من ربط هؤلاء الموظفين لتجنب التكرار في سجلات الموظفين ',
  disclaimer_quickbooks_employee_sync_note:
    'قبل تفعيل خيار مزامنة معلومات  الموظفين, في حال وجود سجلات موظفين في كويك بوكس ينبغي التأكد من ربط هؤلاء الموظفين لتجنب التكرار في سجلات الموظفين.',
  readـthis_article: 'لمزيد من التفاصيل يرجى قراءة هذا المقال',
  class: 'تصنيف',
  Department_Level_1: 'اقسام المستوى الاول',
  Department_Level_2: 'اقسام المستوى الثاني',
  Department_Level_3: 'اقسام المستوى الثالث',
  Department_Level_4: 'اقسام المستوى الرابع',
  Departments: 'الاقسام',
  dimension_found: 'متزامن',
  dimension_not_found: 'غير موجود',
  dimension_synced: 'تم مزامنة النموذج',
  define_the_separator_for_combination_code: 'قم بتحديد شكل الفاصل لرقم الحساب التجميعي  ',
  employeeـdimension: 'تعيين نموذج الموظف',
  employeeـdimension_des: 'تعيين نموذج الموظف بالنظام المحاسبي MS Dynamics',
  employeeـdimension_oracle_fusion_des: 'تعيين نموذج الموظف بالنظام المحاسبي Oracle Fusion',
  employeeـdimension_des_sap: 'تعيين نموذج الموظف بالنظام المحاسبي Sap Business One',
  journal_templates: 'نموذج تعيين القيد المحاسبي',
  journal_templates_des: 'تحديد نموذج تعيين القيد المحاسبي  بالنظام المحاسبي MS Dynamics',
  journal_templates_des_sap: 'تحديد نموذج تعيين القيد المحاسبي  بالنظام المحاسبي Sap Business One',
  journal_templates_des_oracle_fusion:
    'تحديد نموذج تعيين القيد المحاسبي  بالنظام المحاسبي Oracle Fusion',
  journal_templates_des_odoo: 'تحديد نموذج تعيين القيد المحاسبي  بالنظام المحاسبي في اودو',
  dimension_sync: 'مزامنة مركز تكلفة الموظف',
  jisr_journal_entries: 'القيود المحاسبية ب{{app_name}}',
  ms_dynamics_templates: 'نماذج القيد المحاسبي بMS Dynmics',
  oracle_fusion_templates: 'نماذج القيد المحاسبي ب Oracle Fusion',
  odoo_dynamics_templates: 'نماذج القيد المحاسبي في اودو',

  // E
  enter_subsidiary_name: 'ادخل الإسم',
  enter_subsidiary_code: 'ادخل الرمز',
  enter_gl_account_name: 'ادخل الاسم العربي للحساب',
  enter_gl_account: 'ادخل رقم الحساب',
  edit_subsidiary: 'تعديل شركة فرعية',
  edit_tracking_dimension: 'تعديل مركز التكلفة',
  enter_tracking_dimension_name: 'ادخل الاسم',
  edit_item: 'تعديل العنصر',
  enter_item_name_in_english: 'ادخل الاسم الإنجليزي للعنصر',
  enter_item_name_in_arabic: 'ادخل الاسم العربي للعنصر',
  enter_Item_code: 'ادخل الرمز',
  employees_numbers: 'الموظفين {{number}}',
  start_employees_numbers: '{{number}} الموظفين ',
  employees_allocation: 'توزيع الموظفين',
  employees_allocation_dec: 'توزيع الموظفين على الشركات الفرعية ومراكز التكلفة',
  employees_allocation_dec_quickbooks: 'توزيع الموظفين على مراكز التكلفة',
  items_count: 'عدد العناصر',
  employess_selected_tracking: 'مضافين ({{number}})',
  employess_unassigned_tracking: 'غير معين ({{number}})',
  exit: 'خروج',
  // F
  file_based_integration: 'الربط عبر الملف',
  file_based: 'الربط عبر الملف',
  // G
  gl_mapping: 'الربط المحاسبي',
  gl_account_name_in_english: 'اسم الحساب (إنجليزي)',
  gl_account_name_in_arabic: 'اسم الحساب (عربي)',
  gl_account_nam_code: 'رقم الحساب',
  gl_nothing_opt: 'لا شيء',
  gl_emp_tip_note: 'عند الاختيار, سوف يتم توزيع القيود المحاسبية على مستوى كل موظف ',
  gl_payment_type_opt: 'طريقة دفع (حوالة بنكية، نقدي...إلخ)',
  gl_account_darg_drop_note: 'اسحب وأفلت الملف هنا, أو قم بتصفح جهاز الكمبيوتر',
  generate_single_sheet_per_payment: 'اضافة ورقة عمل لكل',
  gl_journal_select_element_note: 'حدد مراكز التكلفة المستخدمة في عملية تجميع القيود المحاسبية',
  gl_entry_code_note: 'حدد طريقة إدخال المعاملات المالية في النظام المحاسبي',
  gl_mapping_preferences_are_all_set:
    ' تم إكمال التهيئة يمكنك الآن تصدير القيود المحاسبية الخاصة بمسير الرواتب, المخالصة النهائية, مخالصة الإجازة, والمعاملات المالية خارج الرواتب ',
  get_started: 'البداية',
  gl_setup_done_title: 'تم إكمال التهيئة',
  get_started_description:
    'ابدأ بتهيئة الهيكل المالي للمنشأة, مثل الشركات الفرعية, مراكز التكلفة, وهيكل القيد المحاسبي',
  // H
  how_do_you_want_to_order: 'رتب مكونات القيد المحاسبي',
  how_to_map_subsidiaries: 'كيفية ربط الشركات الفرعية',
  how_to_map_subsidiaries_odoo: 'كيفية ربط الشركات الفرعية',
  how_to_map_subsidiaries_zoho_books: 'ربط الشركات الفرعية المستخدمة في زوهو مع {{app_name}} ',
  employees_allocation_zoho_books: 'توزيع الموظفين على مراكز التكلفة أثناء الربط مع زوهو',
  how_to_map_zoho_books_cost_centers:
    'ربط مراكز التكلفة ( علامات إعداد التقارير ) في زوهو مع {{app_name}} ',
  how_to_configure_and_map_zoho_books_chart_of_account:
    'ربط الشجرة المحاسبية ( دليل الحسابات ) لزوهو مع {{app_name}} ',
  posting_Journal_entry_from_app_zoho_books: 'إرسال القيود المحاسبية من {{app_name}}  إلى زوهو',
  defining_employee_cost_centre_subsidiary_zoho_books:
    'تحديد مركز تكلفة الموظف والشركة التابعة ( توزيع التكلفة ) لزوهو',
  zoho_books_journal_entry_structure_app: 'تهيئة هيكلة القيد المحاسبي لزوهو مع {{app_name}} ',
  connecting_zoho_Books_prerequisites: 'متطلبات تفعيل الربط مع زوهو Zoho Books',
  setting_connection_with_zoho_books_on_app:
    'تفعيل الإتصال بين نظام {{app_name}}  و زوهو Zoho Books',
  how_to_map_subsidiaries_ms_business:
    'ربط الشركات الفرعية المستخدمة في ( مايكروسوفت ) مع {{app_name}} ',
  employees_allocation_ms_business: 'توزيع الموظفين على مراكز التكلفة أثناء الربط مع مايكروسوفت',
  how_to_map_ms_business_cost_centers: 'ربط مراكز التكلفة في ( مايكروسوفت ) مع {{app_name}} ',
  how_to_configure_and_map_ms_business_chart_of_account:
    'ربط الشجرة المحاسبية ( دليل الحسابات ) لمايكروسوفت مع {{app_name}} ',
  posting_Journal_entry_from_app_ms_business:
    'إرسال القيود المحاسبية من {{app_name}}  إلى مايكروسوفت MS Dynamic',
  defining_employee_cost_centre_subsidiary_ms_business:
    'تحديد مركز تكلفة الموظف والشركة التابعة ( توزيع التكلفة ) لمايكروسوفت',
  ms_business_journal_entry_structure_app:
    'تهيئة هيكلة القيد المحاسبي لمايكروسوفت مع {{app_name}} ',
  connecting_ms_business_prerequisites:
    'متطلبات تفعيل الربط مع مايكروسوفت Microsoft Dynamics 365 - Business Central',
  setting_connection_with_ms_business_on_app:
    'تفعيل الإتصال بين نظام {{app_name}}  و مايكروسوفت Microsoft Dynamics 365 - Business Central',

  how_to_map_netSuite_cost_centers: 'كيفية ربط مراكز التكلفة',
  how_to_map_quickbooks_cost_centers: ' كيفية ربط مراكز التكلفة لبرنامج الكويك بوكس',
  how_to_map_odoo_cost_centers: ' كيفية ربط مراكز التكلفة لبرنامج اودو',
  how_to_map_xero_cost_centers: ' كيفية ربط مراكز التكلفة لبرنامج  زيرو',
  how_to_configure_and_map_netSuite_chart_of_account: 'كيفية إعداد وربط  شجرة الحسابات',
  how_to_configure_and_map_quickbooks_chart_of_account:
    'كيفية إعداد وربط شجرة الحسابات لبرنامج الكويك بوكس',
  how_to_configure_and_map_odoo_chart_of_account: 'كيفية إعداد وربط شجرة الحسابات لبرنامج اودو',
  how_to_configure_and_map_xero_chart_of_account: 'كيفية إعداد وربط شجرة الحسابات لبرنامج زيرو',
  how_to_configure_the_structure_of_the_journal_entry: 'كيفية تهيئة هيكل القيد المحاسبي',
  how_to_configure_the_structure_of_the_journal_entry_xero:
    'كيفية تهيئة هيكل القيد المحاسبي لبرنامج زيرو',
  how_to_configure_the_structure_of_the_journal_entry_odoo: 'كيفية تهيئة هيكل القيد المحاسبي لاودو',
  how_to_allocate_the_employees_to_subsidiaries_and_cost_centers:
    'كيفية توزيع الموظفين على الشركات الفرعية ومراكز التكلفة',
  how_to_allocate_the_employees_to_cost_centers: 'كيفية توزيع الموظفين على مراكز التكلفة',
  how_to_activate_syncing_employees_information_option: 'كيفية تفعيل خيار مزامنة معلومات الموظفين',
  how_to_send_journal_entries_to_netSuite: 'كيفية ارسال ملف القيد المحاسبي إلى نت سويت',
  how_to_send_journal_entries_to_quickbooks: 'كيفية ارسال ملف القيد المحاسبي إلى كويك بوكس',
  how_to_send_journal_entries_to_xero: 'كيفية ارسال ملف القيد المحاسبي إلى زيرو',
  JisrHR_Netsuite_Integration_FAQs: 'الأسئلة الشائعة',
  how_to_connect_zohoBooks: 'كيفية ربط  Zoho Books',
  defining_employee_cost_centre_cost_allocation: 'تحديد مركز تكلفة الموظف ( توزيع التكلفة )',
  // I
  items_tracking_dimension: 'عناصر مركز التكلفة',
  item_name_in_english: 'اسم العنصر',
  item_name_in_arabic: 'اسم العنصر بالعربي',
  item_code: 'رمز العنصر',
  integration_create_subsidiary_as: 'شركه فرعيه مخصصة {{ name }}',
  item_tracking_dimension: 'عنصر مركز التكلفة',
  i_do_not_use_cost_centers: 'لا استخدم مراكز التكلفة',
  // J
  journal_structure: 'هيكلة القيد المحاسبي',
  journal_structure_managment: 'تخصيص هيكل القيود المحاسبية',
  journal_entry_lines: 'معلومات مراكز التكلفة والموظف',
  journal_entry_structure: 'هيكلة القيد المحاسبي',
  journal_entry_lines_xero: 'معلومات مراكز التكلفة',
  journal_entry_structure_note:
    'خصص هيكل القيد المحاسبي المناسب لاحتياجك لاستخراجه من جسر ليكون ملائم لإرساله لـ نت سويت خصص هيكل القيد المحاسبي من خلال تحديد إعدادات آلية إرسال وتصدير ملف القيد المحاسبي',
  journal_entry_structure_note_sap:
    'خصص هيكل القيد المحاسبي المناسب لاحتياجك لاستخراجه من جسر ليكون ملائم لإرساله لـ (sap Business One) خصص هيكل القيد المحاسبي من خلال تحديد إعدادات آلية إرسال وتصدير ملف القيد المحاسبي',
  journal_entry_lines_note: 'اضافة معلومات مراكز التكلفة ومعلومات الموظف في القيد المحاسبي',
  journal_entry_changes_note: 'تغييرات حدثت على اعدادات القيد المحاسبي',
  journal_entry_grouping: 'تجميع القيد المحاسبي',
  journal_memo_information: 'وصف القيد المحاسبي',
  journal_description_information: 'وصف العمليات',
  journal_line_des_select_2_info: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_line_des_select_2_info_quickbooks: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_entry_description: 'وصف القيد المحاسبي',
  journal_entry: 'القيد المحاسبي',
  journal_entry_ref: 'القيد',
  journal_line_des_select_info: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_line_des_select_info_quickbooks: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_line_des_select_info_xero: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_line_des_select_info_qoyod: 'حدد المعلومات التي تريد ظهورها في خانة الوصف',
  journal_entry_description_note:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_quickbooks:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_microsoft:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_sap:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_oracle_fusion:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_odoo:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_zoho_books:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_xero:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_entry_description_note_qoyod:
    'اضافة معلومات الوصف لملف القيد المحاسبي العام ومعلومات الوصف لكل صف للقيد المحاسبي  .',
  journal_transaction_line_select_note:
    'عند تحديد معاملة مالية معينة سيتم إرسال معلومة الموظف فقط لهذه المعاملة',
  journal_entry_grouping_note:
    'حدد طريقة تجميع ملف القيد المحاسبي، على سبيل المثال عند تحديد خيار (الأقسام) سيقوم النظام بإنشاء ملف قيد واحد لكل قسم',
  jisr_location: 'مواقع {{app_name}} ({{number}})',
  This_change_will_update_the_cost_center: 'يجب عليك تحديث قيمة مركز التكلفة إلى “{{name}}”',
  This_change_will_update_the_subsidiary: 'يجب عليك تحديث قيمة الشركة الفرعية إلى “{{name}}”',
  // K
  keep_in_sync_with_your_accounting_system: 'ابقى على تزامن مع نظامك المحاسبي',
  // L
  link_your_subsaidary_with_business: 'حدد وحدة العمل لاضافة الموظفين بطريقة تلقائية',
  Lets_know_what_current_accounting: 'قبل البدء, ماهو النظام المحاسبي المستخدم لديكم حالياً؟',

  // M
  map_them_now: 'قم بربطهم الآن',
  gl_account: 'الحساب',
  mapping_view: 'ربط الحسابات',
  mapping_the_gl_account: 'ربط الحساب',
  mapping_cost_centers: 'ربط مراكز التكلفة',
  map_subsidiaries_setup_note:
    'اربط شركات نت سويت الفرعية مع ما يقابلها في جسر من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  microsoft_map_subsidiaries_setup_note:
    'اربط شركات مايكروسوفت الفرعية مع ما يقابلها في جسر من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  microsoft_finops_map_subsidiaries_setup_note:
    'اربط شركات MS Dynamics Finance & Operation الفرعية مع ما يقابلها في {{app_name}} من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  oracle_fusion_map_subsidiaries_setup_note:
    'اربط شركات Oracle fusion الفرعية مع ما يقابلها في {{app_name}} من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  zoho_books_map_subsidiaries_setup_note:
    'اربط شركات Zoho books الفرعية مع ما يقابلها في {{app_name}} من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  sap_map_subsidiaries_setup_note:
    'اربط شركات Sap Business one الفرعية مع ما يقابلها في {{app_name}} من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  odoo_map_subsidiaries_setup_note:
    'اربط شركات اودو الفرعية مع ما يقابلها في جسر من وحدات العمل، في حال لا تستخدم وحدات العمل كشركات فرعية يرجى تحديد خيار (شركة فرعية مخصصة)',
  main_account_number: 'استخدم رقم الحساب الرئيسي',
  made_following_changes: 'قمت بالتغييرات التالية:',
  map_netsuite_chart_of_accounts: 'ربط شجرة الحسابات',
  map_quickbooks_chart_of_accounts: 'ربط شجرة الحسابات',
  map_netsuite_chart_of_accounts_note:
    'اربط شجرة حسابات نت سويت الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل شركة فرعية أو مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_xero_chart_of_accounts: 'ربط شجرة الحسابات',
  map_qoyod_chart_of_accounts: 'ربط شجرة الحسابات',
  map_quickbooks_chart_of_accounts_note:
    'اربط شجرة حسابات كويك بوكس الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_microsoft_chart_of_accounts: 'ربط شجرة الحسابات',
  map_microsoft_finops_chart_of_accounts: 'ربط شجرة الحسابات',
  map_oracle_fusion_chart_of_accounts: 'ربط شجرة الحسابات',
  map_zoho_books_chart_of_accounts: 'ربط شجرة الحسابات',
  map_sap_chart_of_accounts: 'ربط شجرة الحسابات',
  map_odoo_chart_of_accounts: 'ربط شجرة الحسابات',
  map_microsoft_chart_of_accounts_note:
    'اربط شجرة حسابات MS Dynamics Finance & Operation الافتراضية مع ما يقابلها من معاملات {{app_name}} المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_microsoft_finops_chart_of_accounts_note:
    'اربط شجرة حسابات مايكروسوفت الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_oracle_fusion_chart_of_accounts_note:
    'اربط شجرة حسابات Oracle Fusion الافتراضية مع ما يقابلها من معاملات {{app_name}} المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_zoho_books_chart_of_accounts_note:
    'اربط شجرة حسابات Zoho Books الافتراضية مع ما يقابلها من معاملات {{app_name}} المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_sap_chart_of_accounts_note:
    'اربط شجرة حسابات Sap Business One الافتراضية مع ما يقابلها من معاملات {{app_name}} المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_odoo_chart_of_accounts_note:
    'اربط شجرة حسابات اودو الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_xero_chart_of_accounts_note:
    'اربط شجرة حسابات زيرو الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  map_qoyod_chart_of_accounts_note:
    'اربط شجرة حسابات قيود الافتراضية مع ما يقابلها من معاملات جسر المالية، في حال وجود شجرة حسابات خاصة لكل مركز تكلفة يمكنك من اضافتها عن طريق (+)',
  // N
  no_subsidiary: 'لا توجد شركات فرعية محددة',
  no_business_unit_assigned:
    'لم يتم تحديد وحدة عمل للموظف, ينبغى تحديد وحدة العمل أولاً لكي يتم اسناد الموظف للشركة الفرعية',
  jisr_transaction_type: 'معاملات {{app_name}} المالية',
  netsuite_account_element: 'حساب (نت سويت)',
  quickbooks_account_element: 'حساب (كويك بوكس)',
  xero_account_element: 'حساب (زيرو)',
  qoyod_account_element: 'حساب (قيود)',
  microsoft_account_element: 'حساب (مايكرسوفت)',
  microsoft_finops_account_element: 'حساب (MS Dynamics Finance & Operation)',
  oracle_fusion_account_element: 'حساب (Oracle Fusion)',
  zoho_books_account_element: 'حساب (Zoho books)',
  sap_business_one_account_element: 'حساب (Sap Business One)',
  odoo_account_element: 'حساب (اودو)',
  netsuite_status: 'حالة المزامنة - نت سويت',
  quickbooks_status: 'حالة المزامنة - كويك بوكس',
  xero_status: 'حالة المزامنة - زيرو',
  netsuite_mapping_done: 'ربط إعدادات (نت سوت) تمت بنجاح',
  microsoft_mapping_done: 'ربط إعدادات (مايكروسوفت) تمت بنجاح',
  microsoft_finops_mapping_done: 'ربط إعدادات (MS Dynamics Finance & Operation) تمت بنجاح',
  oracle_fusion_mapping_done: 'ربط إعدادات (Oracle Fusion) تمت بنجاح',
  zoho_books_mapping_done: 'ربط إعدادات (Zoho Books) تمت بنجاح',
  sap_mapping_done: 'ربط إعدادات (Sap Business One) تمت بنجاح',
  odoo_mapping_done: 'ربط إعدادات (اودو) تمت بنجاح',
  quickbooks_mapping_done: 'ربط إعدادات (كويك بوكس) تمت بنجاح',
  xero_mapping_done: 'ربط إعدادات (زيرو) تمت بنجاح',
  qoyod_mapping_done: 'ربط إعدادات (قيود) تمت بنجاح',
  netsuite_cost_center_tab: 'مركز التكلفة',
  netsuite_chart_accountr_tab: 'شجرة الحسابات',
  netsuite_mapping_done_dec: 'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب نت سويت',
  microsoft_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب مايكروسوفت',
  microsoft_finops_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من {{app_name}} إلى حساب MS Dynamics Finance & Operation',
  oracle_fusion_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من {{app_name}} إلى حساب Oracle Fusion',
  zoho_books_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من {{app_name}} إلى حساب Zoho Books',
  sap_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من {{app_name}} إلى حساب Sap Business One',
  odoo_mapping_done_dec: 'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب اودو',
  quickbooks_mapping_done_dec:
    'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب كويك بوكس',
  xero_mapping_done_dec: 'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب زيرو',
  qoyod_mapping_done_dec: 'يمكنك الآن إرسال وتصدير ملف القيود المحاسبية من جسر إلى حساب قيود',
  map_cost_center_with_jisr_org_structure:
    'اربط نوع مركز تكلفة نت سويت مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_quickbooks:
    'اربط نوع مركز تكلفة كويك بوكس مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_xero:
    'اربط نوع مركز تكلفة زيرو مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_qoyod:
    'اربط نوع مركز تكلفة قيود مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_microsoft:
    'اربط نوع مركز تكلفة مايكروسوفت مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_microsoft_finops:
    'اربط نوع مركز تكلفة MS Dynamics Finance & Operation مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_oracle_fusion:
    'اربط نوع مركز تكلفة Oracle Fusion مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_zoho_books:
    'اربط نوع مركز تكلفة Zoho Books مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_sap:
    'اربط نوع مركز تكلفة Sap Business One مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_with_jisr_org_structure_odoo:
    'اربط نوع مركز تكلفة اودو مع ما يقابلها في {{app_name}} من (مواقع، مناطق، إدارات، أقسام)، في حال لا تستخدم هذه التصنيفات يرجى تحديد خيار مركز تكلفة مخصص',
  map_cost_center_type_in_jisr: 'نوع مركز التكلفة ({{app_name}})',
  map_netsuite_location: 'مواقع نت سويت',
  map_netsuite_department: 'إدارات نت سويت',
  map_netsuite_class: 'كلاس نت سويت',
  map_quickbooks_location: 'مواقع كويك بوكس',
  map_quickbooks_department: 'إدارات كويك بوكس',
  map_qoyod_location: 'مواقع قيود',
  map_qoyod_project: 'مشاريع قيود',
  map_quickbooks_class: 'كلاس كويك بوكس',
  map_xero: 'زيرو {{name}}',
  ms_environment: 'Environment',
  map_odoo: 'اودو {{name}}',
  map_microsoft: 'مايكروسوفت {{name}}',
  map_microsoft_finops: 'MS Dynamics Finance & Operation {{name}}',
  map_oracle_fusion: 'Oracle Fusion {{name}}',
  map_zoho_books: 'Zoho Books {{name}}',
  map_sap_business_one: 'Sap Business One {{name}}',
  microsoft: 'Microsoft Dynamics 365 Business Central',
  microsoft_finops: 'MS Dynamics Finance & Operation',
  oracle_fusion: 'Oracle Fusion',
  sap_business_one: 'SAP Business One',
  zoho_books: 'Zoho Books',
  odoo: 'Odoo',
  odoo_journal_warring:
    'يرجى إضافة البريد الإلكتروني للموظفين حتى يتمكن إظهار أسم الموظف بالقيود المحاسبي بأودو',
  map_jisr_parentdepartment: ' قطاعات {{app_name}} ({{ number }}) ',
  map_jisr_department: 'اقسام {{app_name}} ({{ number }}) ',
  map_jisr_location: 'مواقع {{app_name}} ({{ number }}) ',
  map_jisr_area: 'مناطق {{app_name}} ({{ number }}) ',
  map_jisr_department_level_1: '  {{app_name}} الاقسام المستوى الاول({{ number }}) ',
  map_jisr_department_level_2: ' {{app_name}} الاقسام المستوى الثاني({{ number }}) ',
  map_jisr_department_level_3: '{{app_name}} الاقسام المستوى الثالث ({{ number }}) ',
  map_jisr_department_level_4: '{{app_name}} الاقسام المستوى الرابع ({{ number }}) ',
  no_classes_has_been_added: 'لا يوجد مراكز تكلفة مضافة',
  netsuite_subsidiary: 'شركات نت سويت الفرعية',
  microsoft_subsidiary: 'شركات مايكروسوفت الفرعية',
  microsoft_finops_subsidiary: 'شركات MS Dynamics Finance & Operation الفرعية',
  oracle_fusion_subsidiary: 'شركات Oracle Fusion الفرعية',
  zoho_books_subsidiary: 'شركات Zoho books الفرعية',
  sap_subsidiary: 'شركات Sap Business one الفرعية',
  odoo_subsidiary: 'شركات اودو الفرعية',
  netsuite_cost_center: 'مراكز تكلفة نت سويت',
  qucikbooks_cost_center: 'مراكز تكلفة كويك بوكس',
  xero_cost_center: 'مراكز تكلفة زيرو',
  qoyod_cost_center: 'مراكز تكلفة قيود',
  odoo_cost_center: 'مراكز تكلفة اودو',
  microsoft_cost_center: 'مراكز تكلفة مايكروسوفت',
  microsoft_finops_cost_center: 'مراكز تكلفة MS Dynamics Finance & Operation',
  oracle_fusion_cost_center: 'مراكز تكلفة Oracle Fusion',
  zoho_books_cost_center: 'مراكز تكلفة Zoho Books',
  sap_cost_center: 'مراكز تكلفة Sap Business One',
  no_employee_assigned_to_this_subsidiary: 'لم يتم اضافة قائمة الموظفين للشركة الفرعية هذه',
  netsuite_assign_employees_to_their_subsidiary_note:
    ' يمكنك اضافة قائمة الموظفين للشركة الفرعية هذه لاحقاً، في خطوة "توزيع الموظفين"',
  netsuite_department: 'ادارة',
  netsuite_location: 'الموقع',
  qoyod_location: 'موقع',
  qoyod_project: 'مشروع',
  ms_dynamics_accounting: 'مايكروسوفت',
  ms_dynamics_finops_accounting: 'MS Dynamics Finance & Operation Integration',
  fusion_accounting: 'Oracle Fusion Integration',
  zoho_books_accounting: 'Zoho Books',
  sap_business_one_accounting: 'Sap business One',
  odoo_accounting: 'اودو',
  netsuite_class: 'تصنيف',
  narration_information: 'وصف القيد المحاسبي',
  // O
  org_level_assignment: 'وحدة عمل',
  org_level_assignment_tracking: 'حسب هيكل المنشأة',
  org_level_assignment_employees: 'تعيين حسب الهيكل التنظيمي',
  org_level_dec: 'اربط عناصر مراكز تكلفة نت سويت مع ما يقابلها في جسر',
  org_level_dec_quickbooks: 'اربط عناصر مراكز تكلفة كويك بوكس مع ما يقابلها في جسر',
  org_level_dec_xero: 'اربط عناصر مراكز تكلفة زيرو مع ما يقابلها في جسر',
  org_level_dec_qoyod: 'اربط عناصر مراكز تكلفة قيود مع ما يقابلها في جسر',
  org_level_dec_odoo: 'اربط عناصر مراكز تكلفة اودو مع ما يقابلها في جسر',
  org_level_dec_microsoft: 'اربط عناصر مراكز تكلفة مايكروسوفت مع ما يقابلها في {{app_name}}',
  org_level_dec_zoho_books: 'اربط عناصر مراكز تكلفة Zoho books مع ما يقابلها في جسر',
  org_level_dec_sap_business_one: 'اربط عناصر مراكز تكلفة Sap Business One  مع ما يقابلها في جسر',
  org_level_dec_microsoft_finops:
    'اربط عناصر مراكز تكلفة MS Dynamics Finance & Operation مع ما يقابلها في {{app_name}}',
  org_level_dec_oracle_fusion: 'اربط عناصر مراكز تكلفة Oracle Fusion مع ما يقابلها في {{app_name}}',
  org_level: 'الهيكل التنظيمي',
  other_paytypes: 'مدفوعات أخرى',
  other_chart_of_accounts: 'مخصصة',
  other_deductions: 'خصومات اخرى',
  other_additions: 'اضافات اخرى',
  no_tracking_dimensions: 'لا يوجد مركز تكلفة',
  other: 'اخرى',
  oracle_ledger: 'دفتر الاستاذ العام بأوراكل',
  oracle_ledger_des: 'حدد دفتر الاستاذ العام بأوراكل',
  oracle_segment: 'عنصر القيد بأوراكل',
  // P
  please_select_the_type_of_assign: 'إختر نوع التعيين',
  project: 'مشروع',
  posting_date_time: 'تاريخ الارسال',
  posted_by: 'المرسل',
  posing_status: 'حالة الارسال',
  posting_successfully: 'نجح الارسال',
  partially_posted: 'ارسل بشكل جزئي',
  posting_failed: 'فشل الارسال',

  // Q
  // R
  removing_employees_will_unassign:
    'Removing employees will unassign them from being fixed hours employees, meaning you’ll need to manage their shifts from Scheduler. If you’re planning to re-assign them to other fixed hours template, it’s better to assign them straight from the other template. AR',
  removed_employees_from_template: 'إزالة <Text>{{number}}</Text> موظف من {{target}}',
  removed_from_this_shift_template: 'إزالة <Text>{{number}}</Text> من {{target}}',

  // S
  start_mapping: 'تم',
  subsidiaries: 'الشركات الفرعية',
  subsidiary: 'الشركة الفرعية',
  single_subsidiary: 'شركة فرعية',
  subsidiary_code: 'رمز الشركة الفرعية',
  subsidiaries_tab: 'شركة فرعيه',
  netsuite_subsidiaries_tab: 'الشركة الفرعية',
  search_employess: 'ابحث عن الموظفين',
  select_netsuite_location: 'اختيار موقع',
  select_netsuite_department: 'اختيار قسم',
  select_netsuite_class: 'اختيار تصنيف',
  selected_netsuite_location: 'موقع',
  selected_netsuite_project: 'مشروع',
  selected_netsuite_department: 'إدارات',
  selected_netsuite_class: 'تصنيف',
  search_cost_center: 'بحث عن مركز تكلفة',
  select_microsoft_dimension: 'حدد النموذج من MS Dynamics',
  select_microsoft_dimension_finops: 'حدد النموذج من MS Dynamics Finance & Operation',
  select_dimension_oracle_fusion: 'حدد مزاكز التكلفه من Oracle Fusion',
  select_netsuite_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (نت سويت) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_quickbooks_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (كويك بوكس) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_microsoft_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (مايكروسوفت) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_microsoft_finops_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (MS Dynamics Finance & Operation) التي تتبع بها تكاليف الموظفين لربطها مع {{app_name}}، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_oracle_fusion_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (Oracle Fusion) التي تتبع بها تكاليف الموظفين لربطها مع {{app_name}}، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_zoho_books_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (Zoho Books) التي تتبع بها تكاليف الموظفين لربطها مع {{app_name}}، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_sap_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (Sap Business One) التي تتبع بها تكاليف الموظفين لربطها مع {{app_name}}، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_zoho_books_dimension: 'حدد النموذج من Zoho Books',
  select_sap_business_one_dimension: 'حدد النموذج من Sap Business One',

  select_odoo_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (اودو) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_xero_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (زيرو) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  select_qoyod_cost_center_types:
    'حدد نوع مراكز التكلفة المستخدمة في (قيود) التي تتبع بها تكاليف الموظفين لربطها مع جسر، في حال لا تستخدم مراكز التكلفة يرجى تحديد خيار لا استخدم مراكز التكلفة"',
  subsidiary_mapping: 'ربط الشركات الفرعية',
  subsidiary_in_netsuite: 'الشركة الفرعية (نت سويت)',
  subsidiary_in_microsoft: 'الشركة الفرعية (مايكروسوفت)',
  subsidiary_in_microsoft_finops: 'الشركة الفرعية (MS Dynamics Finance & Operation)',
  subsidiary_in_oracle_fusion: 'الشركة الفرعية (Oracle Fusion)',
  subsidiary_in_zoho_books: 'الشركة الفرعية (Zoho Books)',
  subsidiary_in_sap: 'الشركة الفرعية (Sap Business one)',
  subsidiary_in_odoo: 'الشركة الفرعية (اودو)',
  subsidiary_in_jisr: 'الشركة الفرعية ({{app_name}})',
  subsidiary_name: 'اسم الشركة الفرعية',
  subsidiary_name_in_english: 'اسم الشركة الفرعية',
  subsidiary_name_in_arabic: 'اسم الشركة الفرعية (عربي)',
  subsidiary_notification_note:
    '{{count}} موظفين ضمن وحدة العمل ({{name}}) غير مضافين لأي شركة فرعية.',
  subsidiary_notification_no_business_unit: '{{count}} موظفين غير مضافين لأي شركة فرعية.',
  subsidiary_update_note:
    'Removing employees will unassign them from being fixed hours employees, meaning you’ll need to manage their shifts from Scheduler. If you’re planning to re-assign them to other fixed hours template, it’s better to assign them straight from the other template. AR',
  select_the_subsidiaries_which_the: 'اختر المدفوعات المالية لربطها',
  select_elements_which_would_used: 'تجميع حسب مركز التكلفة',
  select_location: 'اختيار الموقع',
  select_project: 'اختيار المشروع',
  select_the_org_level_dimension: 'حدد عنصر هيكل المنشأة لانشاء عناصر مركز التكلفة والموظفين',
  select_the_tracking_dimension: 'حدد عنصر هيكل المنشأة',
  select_the_employees_who_will_be_assigned: 'حدد الموظفين التابعين للشركة الفرعية بشكل يدوي',
  select_the_employees_who_will_be_assigned_tracking:
    'انشاء عناصر مركز التكلفة والموظفين بطريقة يدوية',
  select_the_employees_who_will_be_assigned_manual: 'تحديد الموظفين لكل شركة فرعية بشكل يدوي',
  search_by_name_or_code: 'البحث بالاسم اوالرقم الوظيفي',
  select_business_unit: 'حدد وحدة العمل',
  select_employees: 'اختيار الموظفين',
  select_cost_center: 'حدد مراكز التتبع',
  save_exit: 'حفظ و خروج',
  save_and_continue_later: 'حفظ, والمواصلة لاحقاً',
  set_up_your_general_ledger_mapping: 'إعداد الربط المحاسبي',
  gl_setup_now: 'ابدء بإعداد الربط',
  start_now: 'ابدأ الآن',
  subsidiaries_mapping: 'ربط الشركات الفرعية',
  select_subsidiaries: 'حدد الشركات',
  synced: 'تمت المزامنة',
  synced_employee: 'متزامن',
  not_synced: 'غير متزامن',
  sync_all: 'مزامنة الكل',
  sync_all_employees: 'مزامنة معلومات الموظفين ({{ number }})',
  sync_all_employees_manual: 'تعيين حالة الموظف كمتزامن',
  sync_all_employees_manual_sub:
    'كم بإدخال رقم الموظف في نت سويت هنا للتمكن من تعيين حالة الموظف كمتزامن',
  set_as_synced: 'تعيين كمتزامن',
  show_the_inactive_employees: 'إظهار الموظفين غير النشطين',
  select_netSuite_subsidiaries: 'حدد الشركات الفرعية المستخدمة في (نت سويت) التي تود ربطها مع جسر',
  select_microsoft_subsidiaries:
    'حدد الشركات الفرعية المستخدمة في (مايكروسوفت) التي تود ربطها مع جسر',
  select_microsoft_finops_subsidiaries:
    'حدد الشركات الفرعية المستخدمة في (MS Dynamics Finance & Operation) التي تود ربطها مع {{app_name}}',
  select_oracle_fusion_subsidiaries:
    'حدد الشركات الفرعية المستخدمة في (oracle Fusion) التي تود ربطها مع {{app_name}}',
  select_zoho_books_subsidiaries:
    'حدد الشركات الفرعية المستخدمة في (Zoho Books) التي تود ربطها مع {{app_name}}',
  select_sap_subsidiaries:
    'حدد الشركات الفرعية المستخدمة في (Sap Business one) التي تود ربطها مع {{app_name}}',
  select_odoo_subsidiaries: 'حدد الشركات الفرعية المستخدمة في (اودو) التي تود ربطها مع جسر',
  specified_transactions: 'معاملات مالية محددة',
  sync_dimension: 'مزامنة النموذج',
  select_oracle_gl_account_segment: 'حدد شجره الحسابات بأوراكل',
  select_oracle_ledger: 'اختر دفتر الاستاذ العام بأوراكل',
  // T
  tracking_dimensions: 'مراكز التكلفة',
  there_are_employees_still_not_assigned: 'يوجد موظفين ليس لديهم شركة فرعية',
  total_assign_employees: 'إجمالي الموظفين المعينين',
  tracking_dimension_name: 'اسم مركز التكلفة',
  tracking_dimension_type: 'نوع العنصر',
  transactions_type: 'نوع المعاملة المالية',
  tracking_dimensions_tab: 'مراكز التكلفة/التتبع',
  this_transaction_still_not_mapped_to_any_account: 'هناك معاملة غير مرتبطة بأي حساب',
  transaction_types_mapped_to_this_account: 'أنواع المعاملات المالية المرتبطة بهذا الحساب',
  there_is_no_transactions_type: 'There is no transactions type mapped to this account yet! AR',
  tracking_dimension_name_in_english: 'اسم مركز التكلفة',
  tracking_dimension_name_in_arabic: 'اسم مركز التكلفة (عربي)',
  there_are_some_transaction_types: 'يوجد مدفوعات مالية غير مرتبطة',
  there_are_some_subsidiaries_not_like_to_link_to_gl_account:
    'There are some subsidiaries NOT like to link to GL account AR',
  the_business_unit: 'وحدة العمل',
  type_system_name_here: 'أكتب اسم النظام المحاسبي',

  // U
  upload_your_chart_of_accounts: 'رفع ملف قائمة الحسابات',
  update_gl_account: 'تعديل الحساب',
  un_assigned: 'غير معين',
  un_assign: 'غير معين',
  updated_assign_employees_type: "تحديث طريقة تحديد الشركة الفرعية, <Text>'{{type}}'</Text>",
  updated_subsidairy_name:
    "تحديث اسم الشركة الفرعية, <Text>'{{old}}'</Text> إلى <Text>'{{new}}'</Text>",
  updated_subsidairy_name_ar:
    "تحديث اسم الشركة الفرعية (عربي), <Text>'{{old}}'</Text> إلى <Text>'{{new}}'</Text>",
  updated_subsidairy_code:
    "تحديث رمز الشركة الفرعية, <Text>'{{old}}'</Text> إلى <Text>'{{new}}'</Text>",
  updated_tracking_name:
    "تحديث اسم مركز التكلفة, <Text>'{{old}}'</Text> إلى <Text>'{{new}}'</Text>",
  updated_tracking_name_ar:
    "تحديث اسم مركز التكلفة(عربي), <Text>'{{old}}'</Text> إلى <Text>'{{new}}'</Text>",
  using_this_feature:
    'يمكنك الآن الاستفادة من خاصية الربط مع نظامك المحاسبي وتخصيص ملف القيود الخاص بمنشأتك بالطريقة التي تتناسب مع متطلبات العمل لديكم',
  use_org_structure_in_jisr: 'هيكل المنشأة',
  assigned_cost_centers: 'مراكز التكلفة',
  unassigned_cost_cente: '{{number}} غير مضافين لمركز تكلفة',
  unassigned_subsidiary: '{{number}} غير مضافين لشركة فرعية',
  unassigned_location: '{{number}} غير مضافين لموقع',
  unassigned_department: '{{number}} غير مضافين لادارة',
  unassigned_class: '{{number}} غير مضافين لتصنيف',
  update_subsidiary: 'تحديث',
  update_cost_center: 'تحديث',
  no_cost_center_was_defined: 'لم يتم تعريف مراكز التكلفة',
  no_subsidiary_was_defined: 'لم يتم تعريف شركات فرعية',
  this_employee_is_not_assigned_to_any_cost_center: 'لم يتم تحديد مركز التكلفة للموظف',
  this_employee_is_not_assigned_to_any_subsidiary: 'لم يتم تحديد الشركة الفرعية للموظف',
  // V
  // W
  // X
  xero_accounts: 'حسابات  زيرو',
  // Y
  you_have_transaction_not_mapped_account:
    '{{ number }} مدفوعات مالية غير مرتبطة بالحسابات الخاصة بها.',
  you_ready_to_export_your_journal: 'انت جاهز لإصدار ملف القيود المحاسبية الخاص بك',
  you_have_employees_still_not: 'يوجد {{number}} موظفين لم يتم ربطهم بمركز التكلفة.',
  you_can_customize_your_journal_entries_for_payroll_description:
    'يمكنك تخصيص القيود المحاسبية الخاصة بمسير الرواتب, المخصصات, معاملات مالية خارج الرواتب والكثير. وعند الإنتهاء من تحضير مسير الرواتب يمكنك إستخراج ملف القيود على شكل Excel sheet جاهز للرفع لنظامك المحاسبي',
  // Z
}

export default gl_mapping
