export const punchIcons = {
  biometric: 'fingerprint-03',
  geofencing: 'phone-02',
  manual: 'edit-02',
}

export const punchTypes = {
  in_punch: 'gray',
  out_punch: 'yellow',
  in: 'gray',
  out: 'yellow',
}
