import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CRTypesTypes = {
  filterKey: string
  filters: string[]
}

const CommercialRegistrations = (props: CRTypesTypes): JSX.Element => {
  const { commercial_registration } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      {...props}
      handleFilter={handleFilter}
      options={commercial_registration}
      labelKey="name_i18n"
      componentType="multiLevel"
      title={i18n.t('organizations')}
      childKey="sub_commercial_registrations"
      valueKey="id"
      selectedIds={filter[props.filterKey]}
    />
  )
}

export default CommercialRegistrations
