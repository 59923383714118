import { useContext } from 'react'
import { Flex, Spacer, Typography } from '@jisr-hr/ds'
import { useSelector, useDispatch } from 'utils/hooks'
import { ReactComponent as Done } from 'assets/figma-icons/ok-plus-Tick-mark.svg'
import I18n from 'translations/i18n'
import {
  organizationInfo,
  onboardingStepUpdate,
} from 'redux/organizationOnborading/organization/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { OnboardingContext } from '../../../context'
import StepTemplate from '../../../components/StepTemplate'

const UploadingSucess = ({ goPreviousStep, previousStep }: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { setMainStep, org } = useContext(OnboardingContext)
  const { organization } = useSelector(
    ({ organization: organizationSelector }) => organizationSelector,
  )
  const { onboarding_steps } = organization?.metadata ?? {}

  function handleNext(): Promise<void> | void {
    if (org.require_pincode) {
      localStorage.setItem(`leave_setup_step_${org.id}`, '1')
    }
    if (onboarding_steps?.organization_info === 'Pending') {
      return dispatch(
        onboardingStepUpdate({
          step_name: 'organization_info',
          step_status: 'Completed',
          current_step: 'leaves_setup',
        }),
      ).then(() => {
        dispatch(organizationInfo())
      })
    }
    return setMainStep(1)
  }

  return (
    <StepTemplate
      goNextStep={handleNext}
      goPreviousStep={goPreviousStep}
      nextStep={{
        title: I18n.t('leaves_setup'),
      }}
      previousStep={previousStep}
    >
      <Flex
        flexCol
        itemsCenter
        justifyCenter
        style={{ height: '100%' }}
      >
        <Done />
        <Spacer height="20px" />
        <Typography
          variant="interface/default/m"
          text={I18n.t('completed_msg', {
            app_name: I18n.t('jisr_translation_name'),
          })}
        />
      </Flex>
    </StepTemplate>
  )
}

export default UploadingSucess
