import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { TInitStateAction } from './employeeTypes.type'

export const initialState: TInitStateAction = {
  fetching: false,
  employment_types: [],
}

export default function reducer(state = initialState, action: Action): TInitStateAction {
  switch (action.type) {
    case actions.ONBOARDING_EMPLOYMENT_TYPES_LOAD_PENDING:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_POST_PENDING:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_PUT_PENDING:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_DELETE_PENDING:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_EMPLOYMENT_TYPES_POST_FULFILLED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_PUT_FULFILLED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_DELETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_EMPLOYMENT_TYPES_LOAD_FULFILLED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_SHOW_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        employment_types: action.payload.data.data.employment_types,
        pagination: action.payload.data.data.pagination,
        fetching: false,
      }

    case actions.ONBOARDING_EMPLOYMENT_TYPES_LOAD_REJECTED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_POST_REJECTED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_PUT_REJECTED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_DELETE_REJECTED:
    case actions.ONBOARDING_EMPLOYMENT_TYPES_SHOW_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
