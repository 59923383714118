import React from 'react'
import _ from 'lodash'
import { Flex } from '@jisr-hr/ds'
import { minuteToHumanReadable, replaceAmPm } from 'components/global/form/formNormalize'
import { useSelector } from 'react-redux'
import {
  Info,
  Reason,
  RequestedOvertime,
  AttendanceRecord,
  Comments,
  Workflow,
  Tabs,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'
import { format } from 'utils/date'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const OvertimeRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const { fetching: updateFetching } = useSelector(({ overtimeRequest }) => overtimeRequest)

  const [activeTab, setActiveTab] = React.useState('request_details')

  const attendanceDate = request.request_days?.[0]?.date
  const from = request.request_days?.[0]?.date
  const to = request.request_days?.slice(-1)?.[0]?.date
  const overtimeRange = request.request_days?.length
  // Avoid repeat date in case two shifts
  const uniqDatesRange = _.uniqBy(request.request_days, 'date')?.length

  const overtimeDays = request.request_days?.map((day) => {
    let label = format(new Date(day.date), 'EEEE, d MMM')

    if (day?.employee_shift?.shift_start_time) {
      label += ` (${replaceAmPm(day?.employee_shift?.shift_start_time)} - ${replaceAmPm(
        day?.employee_shift?.shift_end_time,
      )})`
    }

    return {
      label,
      value: minuteToHumanReadable(day?.duration),
    }
  })
  const attendance_record = request.attendance_record || request.employee_shift

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(loading || updateFetching) && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />

      {/* request details tab */}
      {activeTab === 'request_details' && (
        <React.Fragment>
          <Info
            details={{
              ...request,
              attendance_day: attendanceDate,
              overtimeRange: uniqDatesRange,
              from,
              to,
            }}
            showColumns={
              uniqDatesRange <= 1 ? ['attendance_day'] : ['attendance_duration', 'day_number']
            }
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          <RequestedOvertime
            details={request}
            overtimeDays={overtimeDays}
            exclude={overtimeRange > 1 ? [] : ['total_work']}
            reloadRequest={reloadRequest}
          />

          {attendance_record && overtimeRange <= 1 && (
            <AttendanceRecord
              record={attendance_record}
              employee_id={request.employee?.id}
              date={attendanceDate}
              dateFormat="EEEE, dd MMM yyyy"
            />
          )}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          {request.approvals?.length > 0 && (
            <Workflow
              lessDetail
              onClick={() => setActiveTab('workflow_and_comments')}
              approvals={request.approvals}
            />
          )}
        </React.Fragment>
      )}

      {/* comments & workflow tab */}
      {activeTab === 'workflow_and_comments' && (
        <React.Fragment>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          {request.approvals?.length > 0 && <Workflow approvals={request.approvals} />}
        </React.Fragment>
      )}
    </Flex>
  )
}

export default OvertimeRequest
