import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { readableFileSize } from 'utils/uiHelpers'
import { FilePreviewModal } from 'components/global/molecules'
import DSIcon from 'components/DSIcon'

import Styles from './Attachment.module.css'

const Attachment = ({ name, size, url, withBox }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Flex
        justifyBetween
        itemsCenter
        {...(withBox && {
          style: {
            padding: 12,
            border: '1px solid var(--color-natural-gray-lighter-new)',
            borderRadius: 4,
            backgroundColor: 'var(--color-natural-gray-lightest-1-new)',
          },
        })}
      >
        <Flex itemsCenter>
          <DSIcon
            name="paperclip"
            styles={{ rotate: '-45deg' }}
          />
          <Spacer width={10} />

          <Typography
            text={
              <>
                {name}
                {size && (
                  <span className={Styles.attachmentSize}>
                    {typeof size === 'string' ? size : readableFileSize(size)}
                  </span>
                )}
              </>
            }
            variant="subtitle-2"
          />
        </Flex>
        <Spacer width={10} />
        <Flex
          itemsCenter
          style={{ gap: 12 }}
        >
          <DSIcon
            name="eye"
            size="md"
            fill="var(--ds-core-color-base-black)"
            color="var(--ds-core-color-base-white)"
            styles={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
          <a
            download
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: 'unset' }}
          >
            <DSIcon name="download-01" />
          </a>
        </Flex>
      </Flex>

      <FilePreviewModal
        open={open}
        url={url}
        title={name}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

Attachment.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  url: PropTypes.string,
  withBox: PropTypes.bool,
}

export default Attachment
