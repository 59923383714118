const Tawuniya = {
  // A
  add_new_policy: 'Add new Policy',

  // C
  connect_your_tawuniya_account: 'Connect your Tawuniya account',
  connect_your_policies: 'Connect your Policies',

  // D
  delete_policy: 'Delete Policy',
  delete_policy_text: 'Are you sure you want to delete “<bold>{{name}}</bold>” policy ?',

  // H
  how_to_connect_tawuniya_to_jisr: 'How to connect Tawuniya with {{app_name}}',

  // P
  policy_num: 'Policy No.',

  // T
  tawuniya: 'Tawuniya',
  tawuniya_info_note: 'You can learn more about Tawuniya integration.',
  tawuniya_integration_card_note: 'Get insured within minutes!',
  tawuniya_permission_text:
    'By connecting your Tawuniya account with {{app_name}}. {{app_name}} will have the permission to do following actions:',
  tawuniya_view_action: 'View endorsements',
  tawuniya_add_action: 'Add endorsements',
  tawuniya_sharing_data_confirmation_msg:
    'By connecting you agree to share your Tawuniya Policy information with {{app_name}}',

  // Tawuniya Legal Notice content start
  tawuniya_legal_notice_acceptance_title:
    'Notice from Tawuniya Regarding Data Controller Responsibilities and Data Processing Agreement',
  tawuniya_legal_notice_acceptance_accept_title:
    'I accept the Notice from Tawuniya Regarding Data Controller Responsibilities and Data Processing Agreement.',
  tawuniya_legal_notice_acceptance_paragraph1: 'Dear Tawuniya Client,',
  tawuniya_legal_notice_acceptance_paragraph2: `Tawuniya is pleased to offer you access to our comprehensive health administration services provided in collaboration with {{app_name}}. It's important to note that by utilizing these services, you, as our Client, are recognized as the Data Controller under the Personal Data Protection Law (PDPL) and other relevant data protection legislation like The General Data Protection Regulation (GDPR).`,
  tawuniya_legal_notice_acceptance_paragraph3: `As the Data Controller, you play a crucial role in ensuring the lawful processing of your employees' personal data. This includes, but is not limited to, member additions, deletions, and other modifications pertaining to their health services managed through {{app_name}} platform enabled by Tawuniya Insurance Company.`,
  tawuniya_legal_notice_acceptance_paragraph4: `By accepting and utilizing our online health administration services, you acknowledge and assume the responsibility of obtaining necessary consent from your employees to lawfully process their personal data. This acknowledgment is a fundamental requirement that allows Tawuniya Insurance, acting as a Data Processor, to support the lawful processing of your employees' personal data as per PDPL/GDPR regulations.`,
  tawuniya_legal_notice_acceptance_paragraph5: `In compliance with PDPL/GDPR requirements, it is essential that a Data Processing Agreement is implemented between you, the Data Controller, and Tawuniya Insurance, acting as the Data Processor. This agreement formalizes the obligations, responsibilities, and data processing terms between both parties, ensuring that the processing of personal data is carried out in a compliant and secure manner.`,
  tawuniya_legal_notice_acceptance_paragraph6: `It is your responsibility, as the Data Controller, to ensure that the personal data shared with Tawuniya Insurance Company through the {{app_name}} platform is lawfully obtained and processed. Your commitment to securing appropriate consent from your employees to process their personal data is essential for compliance with PDPL/GDPR regulations.`,
  tawuniya_legal_notice_acceptance_paragraph7: `Tawuniya Insurance is dedicated to upholding the highest standards of data privacy and security. We are committed to supporting you in this mutual responsibility by providing a secure and compliant platform through {{app_name}} for the management of health services for your employees.`,
  tawuniya_legal_notice_acceptance_paragraph8: `Should you have any questions or require further information regarding your role as a Data Controller, or the necessity of a Data Processing Agreement, please do not hesitate to reach out to Privacy@tawuniya.com`,
  tawuniya_legal_notice_acceptance_paragraph9:
    'Thank you for your commitment to data privacy and compliance.',
  // Tawuniya Legal Notice content end

  // U
  username_or_policy_no: 'Username / Policy No.',
}

export default Tawuniya
