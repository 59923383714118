import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import {
  GlobalOffCycleStatsType,
  OffCyclePayrunDetailsType,
  OffCyclePayrunType,
  OffCycleStatsType,
  PaginationInfoType,
  PayrunTransactionType,
  TimeLineType,
} from './types'

type InitialStateType = {
  off_cycles: OffCyclePayrunType[]
  pagination_info: PaginationInfoType | null
  stats: OffCycleStatsType | null
  fetching: boolean
  list_fetching: boolean
  transactions_fetching: boolean
  details_fetching: boolean
  loading: boolean
  global_stats: GlobalOffCycleStatsType | null
  off_cycles_details: OffCyclePayrunDetailsType | null
  process_timeline: TimeLineType[]
  payrun_transaction: PayrunTransactionType | null
  request: {
    // ? @todo this request type still miss some properties
    id: number
    message: string
    pending_from: string
    pending_from_ar: string
    received_at: string
    request_name_i18n: string
    request_type: string
    request_type_i18n: string
    status: string
    status_date: string
    status_i18n: string
    time: string
    view_only: boolean
    request: {
      from: string
      id: number
    }
  } | null
}

const initialState: InitialStateType = {
  off_cycles: [],
  pagination_info: null,
  fetching: false,
  transactions_fetching: false,
  details_fetching: false,
  list_fetching: false,
  loading: false,
  stats: null,
  global_stats: null,
  off_cycles_details: null,
  payrun_transaction: null,
  // off_cycles_transaction: {
  //   end_of_service_settlement: {},
  //   leave_balance_settlement: {},
  //   loan_settlement: {},
  //   working_days: {},
  // },
  process_timeline: [],
  request: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_PENDING:
    case actions.STATISTICS_OFF_CYCLE_PAYRUN_LOAD_PENDING:
    case actions.EXPORT_VACATION_PAYRUN_PENDING:
    case actions.FCHECK_VACATION_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_PENDING: {
      return {
        ...state,
        transactions_fetching: true,
      }
    }
    case actions.OFF_CYCLE_PAYRUN_DETAILS_PENDING: {
      return {
        ...state,
        details_fetching: true,
      }
    }
    case actions.OFF_CYCLE_PAYRUNS_LOAD_PENDING:
      return {
        ...state,
        list_fetching: true,
      }
    case actions.EXPORT_OFF_CYCLE_PAYRUNS_PENDING:
    case actions.DOWNLOAD_PAYMENT_FILE_PENDING:
    case actions.FINAL_SETTLEMENT_REQUEST_UPDATE_PENDING:
    case actions.OFF_CYCLE_SEND_ATTENDANCE_REVIEW_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.PROCESS_TIMELINE_LOAD_PENDING: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.STATISTICS_OFF_CYCLE_PAYRUN_LOAD_FULFILLED:
      return {
        ...state,
        stats: action.payload.data.data,
        fetching: false,
      }
    case actions.STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_FULFILLED:
      return {
        ...state,
        global_stats: action.payload.data.data,
        fetching: false,
      }
    case actions.PROCESS_TIMELINE_LOAD_FULFILLED:
      return {
        ...state,
        process_timeline: action.payload.data.data,
        fetching: false,
      }
    case actions.CHECK_VACATION_LOAD_FULFILLED:
      return {
        ...state,
        request: action.payload.data.data.request,
        fetching: false,
      }
    case actions.OFF_CYCLE_PAYRUNS_LOAD_FULFILLED: {
      const previous_page = action.payload.data.data?.pagination_info?.previous_page
      return {
        ...state,
        pagination_info: action.payload.data.data.pagination_info,
        off_cycles: [
          ...(previous_page ? state.off_cycles : []),
          ...action.payload.data.data.off_cycles,
        ],
        list_fetching: false,
      }
    }
    case actions.EXPORT_OFF_CYCLE_PAYRUNS_FULFILLED:
    case actions.DOWNLOAD_PAYMENT_FILE_FULFILLED:
    case actions.FINAL_SETTLEMENT_REQUEST_UPDATE_FULFILLED:
    case actions.OFF_CYCLE_SEND_ATTENDANCE_REVIEW_FULFILLED: {
      if (action?.payload?.data?.message) {
        Toastr.success(action?.payload?.data?.message)
      }
      return {
        ...state,
        loading: false,
      }
    }
    case actions.OFF_CYCLE_PAYRUN_DETAILS_FULFILLED:
      return {
        ...state,
        off_cycles_details: action.payload.data.data,
        fetching: false,
      }
    case actions.EXPORT_VACATION_PAYRUN_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED: {
      const payrun_transaction = action.payload.data.data
      return {
        ...state,
        payrun_transaction,
        transactions_fetching: false,
      }
    }

    case actions.OFF_CYCLE_PAYRUNS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        list_fetching: false,
      }
    }

    case actions.OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        transactions_fetching: false,
      }
    }

    case actions.OFF_CYCLE_PAYRUN_DETAILS_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        details_fetching: false,
      }
    }

    case actions.STATISTICS_OFF_CYCLE_PAYRUN_LOAD_REJECTED:
    case actions.STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_REJECTED:
    case actions.EXPORT_VACATION_PAYRUN_REJECTED:
    case actions.PROCESS_TIMELINE_LOAD_REJECTED:
    case actions.CHECK_VACATION_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    case actions.OFF_CYCLE_SEND_ATTENDANCE_REVIEW_REJECTED:
    case actions.DOWNLOAD_PAYMENT_FILE_REJECTED:
    case actions.FINAL_SETTLEMENT_REQUEST_UPDATE_REJECTED:
    case actions.EXPORT_OFF_CYCLE_PAYRUNS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    case actions.VACATION_DETAILS_RESET:
      return {
        ...state,
        off_cycles: [],
        off_cycles_details: null,
        process_timeline: [],
        payrun_transaction: null,
        request: null,
      }

    default:
      return state
  }
}
