export const ALL_EMPLOYEES_LOAD = 'ALL_EMPLOYEES_LOAD'
export const ALL_EMPLOYEES_LOAD_PENDING = 'ALL_EMPLOYEES_LOAD_PENDING'
export const ALL_EMPLOYEES_LOAD_FULFILLED = 'ALL_EMPLOYEES_LOAD_FULFILLED'
export const ALL_EMPLOYEES_LOAD_REJECTED = 'ALL_EMPLOYEES_LOAD_REJECTED'

export const ADDITION_AVAILABILITY_LOAD = 'ADDITION_AVAILABILITY_LOAD'
export const ADDITION_AVAILABILITY_LOAD_PENDING = 'ADDITION_AVAILABILITY_LOAD_PENDING'
export const ADDITION_AVAILABILITY_LOAD_FULFILLED = 'ADDITION_AVAILABILITY_LOAD_FULFILLED'
export const ADDITION_AVAILABILITY_LOAD_REJECTED = 'ADDITION_AVAILABILITY_LOAD_REJECTED'

export const RESET = 'RESET'

export const SET_EMPLOYEES_FILTERS = 'SET_EMPLOYEES_FILTERS'
export const CLEAR_EMPLOYEES_FILTERS = 'CLEAR_EMPLOYEES_FILTERS'
export const LOCAL_UPDATE_EMPLOYEES_TAB = 'LOCAL_UPDATE_EMPLOYEES_TAB'

export const ONBOARDING_EMPLOYEE_CREATE = 'ONBOARDING_EMPLOYEE_CREATE'
export const ONBOARDING_EMPLOYEE_CREATE_PENDING = 'ONBOARDING_EMPLOYEE_CREATE_PENDING'
export const ONBOARDING_EMPLOYEE_CREATE_FULFILLED = 'ONBOARDING_EMPLOYEE_CREATE_FULFILLED'
export const ONBOARDING_EMPLOYEE_CREATE_REJECTED = 'ONBOARDING_EMPLOYEE_CREATE_REJECTED'

export const ONBOARDING_EMPLOYEE_UPDATE = 'ONBOARDING_EMPLOYEE_UPDATE'
export const ONBOARDING_EMPLOYEE_UPDATE_PENDING = 'ONBOARDING_EMPLOYEE_UPDATE_PENDING'
export const ONBOARDING_EMPLOYEE_UPDATE_FULFILLED = 'ONBOARDING_EMPLOYEE_UPDATE_FULFILLED'
export const ONBOARDING_EMPLOYEE_UPDATE_REJECTED = 'ONBOARDING_EMPLOYEE_UPDATE_REJECTED'
