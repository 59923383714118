import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type GosiReportFilter = Record<
  | 'location_ids'
  | 'department_ids'
  | 'employment_type_ids'
  | 'job_title_ids'
  | 'area_ids'
  | 'mol_registration_ids'
  | 'grade_ids',
  number[]
>

type InitialStateType = {
  fetching: boolean
  main_header: {
    label: string
    categories: number
  }[]
  header: {
    label: string
    key: string
    sort: string | null
  }[]
  records: string[][]
  total: string[]
  filters: GosiReportFilter
}

export const initialState: InitialStateType = {
  fetching: false,
  header: [],
  records: [],
  main_header: [],
  total: [],
  filters: {
    location_ids: [],
    department_ids: [],
    employment_type_ids: [],
    job_title_ids: [],
    area_ids: [],
    mol_registration_ids: [],
    grade_ids: [],
  },
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.GOSI_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GOSI_REPORT_LOAD_FULFILLED: {
      const { header, main_header, records, total } = action.payload.data.data as Omit<
        InitialStateType,
        'filters' | 'fetching'
      >
      return {
        ...state,
        header,
        main_header,
        records,
        total,
        fetching: false,
      }
    }

    case actions.GOSI_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.SET_FILTERS_GOSI: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    }

    case actions.CLEAR_FILTERS_GOSI: {
      return {
        ...state,
        filters: initialState.filters,
      }
    }

    default:
      return state
  }
}
