import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState: { fetching: boolean } = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): { fetching: boolean } {
  switch (action.type) {
    case actions.CLEAR_ALL_DEDUCTIONS_PENDING:
    case actions.ATTENDANCE_UPDATE_DEDUCTIONS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CLEAR_ALL_DEDUCTIONS_FULFILLED:
    case actions.ATTENDANCE_UPDATE_DEDUCTIONS_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.CLEAR_ALL_DEDUCTIONS_REJECTED:
    case actions.ATTENDANCE_UPDATE_DEDUCTIONS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
