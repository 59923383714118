import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ContractDurationTypes = {
  filterKey: string
  filters: string[]
}

const ContractDuration = (props: ContractDurationTypes): JSX.Element => {
  const { contract_duration } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={contract_duration}
      labelKey="name_i18n"
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('label.contract_duration')}
    />
  )
}

export default ContractDuration
