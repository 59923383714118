export const MUDAD_SUBSCRIPTIONS_LOAD = 'MUDAD_SUBSCRIPTIONS_LOAD'
export const MUDAD_SUBSCRIPTIONS_LOAD_PENDING = 'MUDAD_SUBSCRIPTIONS_LOAD_PENDING'
export const MUDAD_SUBSCRIPTIONS_LOAD_FULFILLED = 'MUDAD_SUBSCRIPTIONS_LOAD_FULFILLED'
export const MUDAD_SUBSCRIPTIONS_LOAD_REJECTED = 'MUDAD_SUBSCRIPTIONS_LOAD_REJECTED'

export const MUDAD_SUBSCRIPTIONS_PUT = 'MUDAD_SUBSCRIPTIONS_PUT'
export const MUDAD_SUBSCRIPTIONS_PUT_PENDING = 'MUDAD_SUBSCRIPTIONS_PUT_PENDING'
export const MUDAD_SUBSCRIPTIONS_PUT_FULFILLED = 'MUDAD_SUBSCRIPTIONS_PUT_FULFILLED'
export const MUDAD_SUBSCRIPTIONS_PUT_REJECTED = 'MUDAD_SUBSCRIPTIONS_PUT_REJECTED'

export const ADDONS_LOAD = 'ADDONS_LOAD'
export const ADDONS_LOAD_PENDING = 'ADDONS_LOAD_PENDING'
export const ADDONS_LOAD_FULFILLED = 'ADDONS_LOAD_FULFILLED'
export const ADDONS_LOAD_REJECTED = 'ADDONS_LOAD_REJECTED'
