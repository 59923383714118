import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  step: 1,
  integratable_cost_centers: [],
  addition_types: [],
  benefit_types: [],
  deduction_types: [],
  loan_types: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.ACCOUNTING_JOURNAL_STEPS:
      return {
        ...state,
        step: payload.step,
        fetching: false,
      }

    case actions.PAYROLL_EXPORT_JOURNALS_PENDING:
    case actions.PAYROLL_EXPORT_ODD_JOURNALS_PENDING:
    case actions.PAYROLL_EXPORT_FIOPS_JOURNALS_PENDING:
    case actions.PAYROLL_EXPORT_NETSUITE_JOURNALS_PENDING:
    case actions.PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_PENDING:
    case actions.PAYROLL_EXPORT_XERO_JOURNALS_PENDING:
      return {
        fetching: true,
      }

    case actions.PAYROLL_EXPORT_NETSUITE_JOURNALS_FULFILLED:
    case actions.PAYROLL_EXPORT_ODD_JOURNALS_FULFILLED:
    case actions.PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_FULFILLED:
    case actions.PAYROLL_EXPORT_XERO_JOURNALS_FULFILLED:
    case actions.PAYROLL_EXPORT_FIOPS_JOURNALS_FULFILLED:
    case actions.PAYROLL_EXPORT_JOURNALS_FULFILLED: {
      Toastr.success(payload?.data?.message)
      return {
        fetching: false,
      }
    }

    case actions.PAYROLL_ELEMENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.PAYROLL_ELEMENTS_UPDATE_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.PAYROLL_ELEMENTS_LOAD_REJECTED:
    case actions.PAYROLL_ELEMENTS_UPDATE_REJECTED:
    case actions.PAYROLL_EXPORT_JOURNALS_REJECTED:
    case actions.PAYROLL_EXPORT_XERO_JOURNALS_REJECTED:
    case actions.PAYROLL_EXPORT_ODD_JOURNALS_REJECTED:
    case actions.PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_REJECTED:
    case actions.PAYROLL_EXPORT_FIOPS_JOURNALS_REJECTED:
    case actions.PAYROLL_EXPORT_NETSUITE_JOURNALS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
