import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'
import classNames from 'classnames'

const Arrow = ({ color, rotate = 0, style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="9"
    viewBox="0 0 6 9"
    style={{ ...style, transform: `rotate(${rotate}deg)` }}
    className={classNames(className)}
  >
    <path
      fill={getColor(color)}
      fillRule="evenodd"
      d="M5.42879461,8.138845 C5.53304571,8.35942538 5.75437086,8.5 5.99740852,
      8.5 C6.24044619,8.5 6.46177133,8.35942538 6.56602243,8.138845 L10.3492379,
      3.37399091 C10.658484,2.89389728 10.4789218,2.5 9.93025921,
      2.5 L2.06455783,2.5 C1.52587097,2.5 1.336333,2.89389728 1.65555484,3.37399091 L5.42879461,
      8.138845 Z"
      transform="rotate(-90 4 6.5)"
    />
  </svg>
)

Arrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  rotate: PropTypes.number,
  style: PropTypes.shape(),
}

Arrow.defaultProps = {
  color: 'dark-grey',
}

export default Arrow
