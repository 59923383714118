import { ReactNode } from 'react'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { required } from 'components/global/form/FormValidations'
import TextEditorField from 'components/final-form/EditorField'
import {
  Form,
  TextInputField,
  CheckboxField,
  NewSelectField,
  NewFileUploadField,
  MultiSelectField,
} from 'components/final-form'
import { Typography, Spacer } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import usePermissions from 'components/Navigation/usePermissions'
import { isTruthy } from 'utils/truthy'

export type SubmitDataProps = {
  group_ids: string | null[] | undefined
  group_type: string
  notification: {
    attachment?: string | undefined
    attachment_file_name?: string | undefined
    title: string
  }
  send_email: boolean
}

export type EmpProps = {
  avatar_thumb: JSX.Element
  code: string
  id: number
  job_title_i18n: string
  name: string
}

export type filterOptionProps = {
  data: {
    name_i18n: string
    avatar?: JSX.Element
    filter?: string
    id: number | string
  }
  label: string
  value: number | string
}

type AnnouncementFormProps = {
  onSubmit: (v: SubmitDataProps) => void
  setDisabled: (v: boolean) => void
}

const AnnouncementForm = ({ onSubmit, setDisabled }: AnnouncementFormProps): JSX.Element => {
  const { isMultiSubSupported } = usePermissions()
  const groupTypeList = [
    { id: 'departments', label: I18n.t('the_departments') },
    { id: 'locations', label: I18n.t('locations') },
    { id: 'business_units', label: I18n.t('business_units') },
    { id: 'employees', label: I18n.t('employees') },
    { id: 'all_employees', label: I18n.t('all_employees') },
    isMultiSubSupported && { id: 'subsidiaries', label: I18n.t('subsidiaries') },
  ].filter(isTruthy)

  const { employees } = useSelector(({ employee }) => ({
    employees: employee.employee_all || [],
  }))

  const { locations } = useSelector(({ filterReducer }) => filterReducer)
  const { business_units } = useSelector(({ businessUnit }) => businessUnit)
  const { list_departments } = useSelector(({ newDepartments }) => newDepartments)
  const { accounts } = useSelector((s) => s.switchableOrganizations)

  const getOptionsGroupIds = (key: string) => {
    if (key === 'business_units') return business_units
    if (key === 'departments') return list_departments
    if (key === 'locations') return locations
    if (key === 'employees') {
      return employees.map((emp: EmpProps) => ({
        id: emp.id,
        avatar: emp.avatar_thumb,
        filter: `${emp.code}, ${emp.name}`,
        name_i18n: emp.name,
        subLabel: `${emp.code} - ${emp.job_title_i18n ?? ''}`,
      }))
    }
    if (key === 'subsidiaries') return accounts
    return []
  }

  const filterOption = (option: filterOptionProps, inputValue: string): boolean => {
    if (option.data.filter) {
      return option.data.filter.toLowerCase()?.includes(inputValue.toLowerCase())
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
    >
      {({ handleSubmit, values, pristine, form }): ReactNode => {
        setDisabled(pristine)
        return (
          <form
            onSubmit={handleSubmit}
            id="announcement-form"
          >
            <Grid
              container
              direction="column"
            >
              <Grid
                item
                xs={12}
                style={{ zIndex: 35 }}
              >
                <NewSelectField
                  valueKey="id"
                  labelKey="label"
                  name="group_type"
                  validate={required}
                  label={I18n.t('to')}
                  testId="group-type"
                  maxMenuHeight={300}
                  onChange={(): void => form.change('group_ids', undefined)}
                  options={groupTypeList}
                />
              </Grid>
              <Spacer height={16} />
              {values.group_type && values.group_type !== 'all_employees' && (
                <Grid
                  item
                  xs={12}
                  style={{ zIndex: 30 }}
                >
                  <MultiSelectField
                    valueKey="id"
                    labelKey="name_i18n"
                    avatarSrcKey="avatar"
                    name="group_ids"
                    returnType="value"
                    options={getOptionsGroupIds(values.group_type)}
                    onChange={(list: string | number[]): void => {
                      if (list.length === 0) {
                        form.change('group_ids', undefined)
                      }
                    }}
                    validate={required}
                    filterOption={filterOption}
                    label={
                      groupTypeList.find((groupType) => groupType.id === values.group_type)?.label
                    }
                  />
                </Grid>
              )}

              <Spacer height={16} />
              <Grid
                item
                xs={12}
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  testId="notification-title"
                  name="notification.title"
                  label={I18n.t('title')}
                  validate={required}
                />
              </Grid>
              <Spacer height={16} />
              <Grid
                item
                xs={12}
              >
                <TextEditorField
                  label={I18n.t('message')}
                  validate={required}
                  name="notification.body"
                  testId="notification-body"
                  init={{
                    height: 237,
                    menubar: false,
                    toolbar:
                      'bold italic underline strikethrough | styles | alignleft aligncenter alignright alignjustify | outdent indent |numlist bullist checklist| forecolor backcolor| fullscreen insertfile ltr rtl showcomments addcomment',
                  }}
                />
              </Grid>
              <Spacer height={16} />
              <Grid
                item
                xs={12}
              >
                <Typography
                  text={I18n.t('attachment')}
                  variant="subtitle-2"
                />
                <Spacer height={6} />
                <NewFileUploadField
                  compact
                  name="notification.attachment"
                  accept=".doc, .docx, .xls, .xlsx, .csv, .pdf, image/*"
                  onRemove={(): void => {
                    form.change('notification.attachment' as keyof SubmitDataProps, undefined)
                    form.change(
                      'notification.attachment_file_name' as keyof SubmitDataProps,
                      undefined,
                    )
                  }}
                  onLoad={(file): void => {
                    if (file) {
                      form.change(
                        'notification.attachment_file_name' as keyof SubmitDataProps,
                        file[0].name,
                      )
                    }
                  }}
                />
              </Grid>
              <Spacer height={16} />
              <Grid
                item
                xs={12}
              >
                <CheckboxField
                  name="send_email"
                  label={I18n.t('send_via_email')}
                />
              </Grid>
            </Grid>
            <Spacer height={8} />
            <Typography
              text={I18n.t('all_announcement_will_be_sent_to_mobile_app')}
              style={{ color: 'var(--color-base-colors-black-700)' }}
              variant="subtitle-2"
            />
          </form>
        )
      }}
    </Form>
  )
}

export default AnnouncementForm
