import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  objective: {
    rateable_type: 'objective',
  },
  competency: {
    rateable_type: 'competency',
  },
  normal_review_cycle: {
    rateable_type: 'normal_review_cycle',
  },
  probation_period_review_cycle: {
    rateable_type: 'probation_period_review_cycle',
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RATING_CONFIGURATIONS_LOAD_PENDING:
    case actions.RATING_CONFIGURATIONS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.RATING_CONFIGURATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.RATING_CONFIGURATIONS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.RATING_CONFIGURATIONS_LOAD_REJECTED:
    case actions.RATING_CONFIGURATIONS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
