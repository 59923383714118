/** **************
 *   Setting Organization Profile
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { EmpFamilyDetailsValuesType } from 'containers/authorised/employee/EmployeeDetails/Tabs/Personal/components/types'

export function familyDetailsLoad(
  employeeId: number,
  params: Record<string, unknown> = {},
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employeeId}/family_details/`
  const method = 'GET'

  return {
    type: 'FAMILY_DETAILS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export const familyDetailCreate = (
  employeeId: number,
  data: EmpFamilyDetailsValuesType,
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employeeId}/family_details/`

  return {
    type: 'FAMILY_DETAIL_CREATE',
    payload: handleAPI(url, {}, 'POST', { family_detail: data }),
  }
}

export const familyDetailUpdate = (
  employeeId: number,
  id: number,
  data: EmpFamilyDetailsValuesType,
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employeeId}/family_details/${id}/`

  return {
    type: 'FAMILY_DETAIL_UPDATE',
    payload: handleAPI(url, {}, 'PUT', { family_detail: data }),
  }
}

export const familyDetailDelete = (employeeId: number, id: number): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employeeId}/family_details/${id}/`

  return {
    type: 'FAMILY_DETAIL_DELETE',
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

// export function resetForm() {
//   return {
//     type: 'RESET_FORM',
//     family_detail: {},
//   };
// }
