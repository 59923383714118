import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TIndustry = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
}

type TMAP = Omit<TIndustry, 'name_ar' | 'name_en'> & { name: string }

type TInitialState = {
  fetching: boolean
  industries: TIndustry[]
  accounting_system_providers: TMAP[]
  point_of_sale_providers: TMAP[]
  medical_insurance_providers: TMAP[]
}

export const initialState: TInitialState = {
  fetching: false,
  industries: [],
  accounting_system_providers: [],
  point_of_sale_providers: [],
  medical_insurance_providers: [],
}

export default function reducer(state = initialState, action: Action): TInitialState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_INDUSTRIRIES_LOAD_PENDING:
    case actions.ONBOARDING_PROVIDERS_LOAD_PENDING:
    case actions.ONBOARDING_PROVIDERS_POST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_INDUSTRIRIES_LOAD_FULFILLED:
    case actions.ONBOARDING_PROVIDERS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_PROVIDERS_POST_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_INDUSTRIRIES_LOAD_REJECTED:
    case actions.ONBOARDING_PROVIDERS_LOAD_REJECTED:
    case actions.ONBOARDING_PROVIDERS_POST_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
