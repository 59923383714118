import { FC } from 'react'
import { Containers, Flex, Button, Typography, Spacer } from '@jisr-hr/ds'
import { ReactComponent as OnlinePayment } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/OnlinePayment.svg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'utils/hooks'
import { isRtl } from 'utils/uiHelpers'
import i18n from 'translations/i18n'
import styles from './JisrPayWallet.module.css'

type ActivateWalletPopupProps = {
  onClose: () => void
}

const articleLink = isRtl
  ? 'https://jisr.zendesk.com/hc/ar-sa/articles/24515591698321-%D9%86%D8%A8%D8%B0%D8%A9-%D8%B9%D9%86-%D8%AE%D8%AF%D9%85%D8%A9-%D8%A7%D9%84%D8%AF%D9%81%D8%B9-%D8%B9%D9%86-%D8%B7%D8%B1%D9%8A%D9%82-%D8%AC%D8%B3%D8%B1'
  : 'https://jisr.zendesk.com/hc/en-sa/articles/24515591698321-Jisr-online-payment-overview'

const ActivateWalletPopup: FC<ActivateWalletPopupProps> = (props) => {
  const history = useHistory()
  const canConfigurePayroll = useSelector(
    (s) => s.auth.employee?.permission_details?.configure_payroll,
  )
  return (
    <>
      <Containers
        color="white"
        borderRadius="lg"
      >
        <Flex justifyBetween>
          <OnlinePayment />
          <Button
            iconOnly
            size="small"
            variant="ghost"
            leadingIcon="x-close"
            onClick={props.onClose}
          />
        </Flex>
        <Spacer height={20} />
        <Typography
          text={i18n.t('label.jisr_integrated_with_wps')}
          variant="title-1"
        />
        <Spacer height={20} />
        <ul className={styles.list}>
          <li>
            <Typography
              text={i18n.t('label.hassle_free_compiance_')}
              variant="body-new/regular"
              textColor="color/fg/lighter"
            />
          </li>
          <li>
            <Typography
              text={i18n.t('label.its_free_zero_subscription_fee')}
              variant="body-new/regular"
              textColor="color/fg/lighter"
            />
          </li>
          <li>
            <Typography
              text={i18n.t('label.save_money_transaction_fee_is_lower')}
              variant="body-new/regular"
              textColor="color/fg/lighter"
            />
          </li>
          <li>
            <Typography
              text={i18n.t('label.processing_and_transferring_payrolls_in_few_clicks')}
              variant="body-new/regular"
              textColor="color/fg/lighter"
            />
          </li>
        </ul>
        <Spacer height={20} />
        <Flex>
          {canConfigurePayroll && (
            <Button
              label={i18n.t('label.activate_payment')}
              size="medium"
              onClick={(): void => {
                history.push('/setting_v2/payroll/online_payment')
                props.onClose()
              }}
            />
          )}
          <Button
            className="ml-3"
            variant="ghost"
            color="neutral"
            label={i18n.t('label.read_more')}
            size="medium"
            onClick={(): void => {
              window.open(articleLink, '_blank')
              props.onClose()
            }}
          />
        </Flex>
      </Containers>
    </>
  )
}

export default ActivateWalletPopup
