import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

const ShiftType = (props) => {
  const { shift_type } = useFilterAPI()
  const { filter, setFilter, handleFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState([])
  const optionList = returnBadgeList(shift_type)

  const onHandleFilter = (__, val) => {
    if (val === 'all') {
      setFilter({
        ...filter,
        shift_type: [],
      })
    } else {
      handleFilter('shift_type', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.shift_type)) {
      setSelectedIds(filter.shift_type)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.shift_type])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

ShiftType.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default ShiftType
