import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  attendance_summary_report: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ATTENDANCE_SUMMARY_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ATTENDANCE_SUMMARY_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.ATTENDANCE_SUMMARY_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
