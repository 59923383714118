import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  role: {},
  rolePermissions: [],
  entityScopedPermissions: {},
  employeePermissions: {},
  roles: {},
  roleEmployees: [],
  allRoleEmployees: [],
  managers: [],
  fetchingRole: false,
  fetchingRoles: false,
  fetchingRolePermissions: false,
  fetchingEmployeePermissions: false,
  fetchingRoleEmployees: false,
  fetchingAllRoleEmployees: false,
  loading: false,
}

let changes = null
export default function organizationProfileReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let newRoles = []
  let url
  let id
  switch (action.type) {
    case actions.ROLES_LOAD_PENDING:
      return { ...state, fetchingRoles: true }
    case actions.ROLE_PERMISSIONS_LOAD_PENDING:
      return { ...state, fetchingRolePermissions: true }
    case actions.ROLE_LOAD_PENDING:
    case actions.ROLE_CREATE_PENDING:
    case actions.ROLE_UPDATE_PENDING:
    case actions.ROLE_DELETE_PENDING:
    case actions.ROLE_EMPLOYEES_UPDATE_PENDING:
    case actions.ROLE_EMPLOYEES_DELETE_PENDING:
      return { ...state, fetchingRole: true }
    // case actions.ROLE_MANAGERS_LOAD_PENDING:
    case actions.ROLE_EMPLOYEES_LOAD_PENDING:
      return { ...state, fetchingRoleEmployees: true }
    case actions.ALL_ROLE_MANAGERS_LOAD_PENDING:
    case actions.ALL_ROLE_EMPLOYEES_LOAD_PENDING:
      return { ...state, fetchingAllRoleEmployees: true }
    case actions.EMPLOYEE_PERMISSIONS_LOAD_PENDING:
      return { ...state, fetchingEmployeePermissions: true }
    case actions.ROLES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, errMsg: handleResponseErr(action.payload), fetchingRoles: false }
    case actions.ROLE_PERMISSIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetchingRolePermissions: false,
      }
    case actions.ROLE_LOAD_REJECTED:
    case actions.ROLE_CREATE_REJECTED:
    case actions.ROLE_UPDATE_REJECTED:
    case actions.ROLE_DELETE_REJECTED:
    case actions.ROLE_EMPLOYEES_UPDATE_REJECTED:
    case actions.ROLE_EMPLOYEES_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, errMsg: handleResponseErr(action.payload), fetchingRole: false }

    // case actions.ROLE_MANAGERS_LOAD_REJECTED:
    case actions.ROLE_EMPLOYEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, fetchingRoleEmployees: false }

    case actions.ALL_ROLE_MANAGERS_LOAD_REJECTED:
    case actions.ALL_ROLE_EMPLOYEES_LOAD_REJECTED:
      return { ...state, fetchingAllRoleEmployees: false }
    case actions.ROLES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetchingRoles: false,
      }

    case actions.EMPLOYEE_PERMISSIONS_LOAD_REJECTED:
      return { ...state, fetchingEmployeePermissions: false }
    case actions.ROLE_PERMISSIONS_LOAD_FULFILLED:
      return {
        ...state,
        rolePermissions: action.payload.data.data?.module_permissions?.map?.((module) => {
          return {
            ...module,
            module_name: module.module_name_i18n?.toLowerCase()?.split(' ')?.join('_'),
          }
        }),
        entityScopedPermissions: action.payload.data.data?.entity_scoped_permissions,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetchingRolePermissions: false,
      }

    case actions.ROLE_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        role: payload.data.data.role,
        roles: {
          ...state.roles,
          custom_roles: [
            { ...payload.data.data.role, employee_count: 0 },
            ...state.roles?.custom_roles,
          ],
        },
        fetchingRole: false,
      }

    case actions.ROLE_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetchingRole: false,
      }
    case actions.ROLE_UPDATE_FULFILLED:
    case actions.ROLE_EMPLOYEES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetchingRole: false,
      }

    case actions.ROLE_DELETE_FULFILLED:
      url = action.payload?.request?.responseURL
      id = url.split('/')
      id = id[id.length - 2 < 0 ? 0 : id.length - 2]

      return {
        ...state,
        roles: {
          default_roles: state?.roles?.default_roles,
          custom_roles: state?.roles?.custom_roles?.filter((role) => role?.id !== parseInt(id, 10)),
        },
        fetchingRole: false,
        error: null,
      }
    case actions.ROLE_EMPLOYEES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return { ...state, fetchingRole: false }
    case actions.ROLE_RESET:
      changes = {
        role: { employee_ids: [], permissions: [] },
        fetchingRole: false,
      }
      return { ...state, ...changes }
    // case actions.ROLE_MANAGERS_LOAD_FULFILLED:
    case actions.ROLE_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        fetchingRoleEmployees: false,
        roleEmployees: payload.data.data.employees,
      }

    case actions.ALL_ROLE_MANAGERS_LOAD_FULFILLED:
    case actions.ALL_ROLE_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        fetchingAllRoleEmployees: false,
        allRoleEmployees: payload.data.data.employees,
      }
    case actions.EMPLOYEE_PERMISSIONS_LOAD_FULFILLED:
      return {
        ...state,
        employeePermissions: payload.data.data.employee,
        fetchingEmployeePermissions: false,
      }
    case actions.ADD_NEW_ROLE:
      newRoles = {
        ...state?.roles,
        custom_roles: [{ id: '', name: '', module_permissions: [] }, ...state?.roles?.custom_roles],
      }
      return {
        ...state,
        roles: newRoles,
      }
    case actions.REMOVE_NEW_ROLE:
      newRoles = [...state?.roles?.custom_roles]
      newRoles.splice(0, 1)
      return { ...state, roles: { ...state?.roles, custom_roles: newRoles } }
    default:
      return state
  }
}
