import { Spacer, Flex, Button, Typography } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import RadioWithContent from 'containers/public/Onboarding/components/RadioWithContent'
import { useDispatch, useSelector } from 'utils/hooks'
import { Form, TextInputField } from 'components/final-form'
import I18n from 'translations/i18n'
import {
  onboardingCarryForwardLoad,
  onboardingCarryForwardUpdate,
  onboardingCarryForwardTypeLoad,
} from 'redux/leavesOnboarding/carryForword/actionCreators'
import { composeValidators, number, required } from 'components/global/form/FormValidations'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import AnnualLeaveRequiredReason from 'containers/authorised/setting/v2/LeaveManagement/LeaveTypes/components/AnnualLeaveRequiredReason'
import { useEffect } from 'react'
import {
  onboardingLeaveTypesLoad,
  onboardingLeaveTypesUpdate,
} from 'redux/leavesOnboarding/leaveTypes/actionCreators'

const CarryForward = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const data = useSelector(({ carryForword }) => carryForword)
  const { leave_types, fetching: fetchingTypes } = useSelector(
    ({ onboardingLeaveTypes }) => onboardingLeaveTypes,
  )
  const annual = leave_types?.[0] ?? {}
  const { organizationLimit, organizationType, fetching } = data
  const options = [
    {
      value: 'Carry all',
      label: I18n.t('carry_all_forward'),
      description: I18n.t('carry_all_forward_description'),
    },
    {
      value: 'No carry',
      label: I18n.t('reset_balance'),
      description: I18n.t('reset_balance_description'),
      tip: I18n.t('remaining_balance_settings_description_tip'),
    },
    {
      value: 'Limited carry',
      label: I18n.t('limited_carry_forward'),
      description: I18n.t('limited_carry_forward_description'),
      withChildren: true,
      tip: I18n.t('remaining_balance_settings_description_tip'),
    },
  ]

  const handleChange = (val: string): void => {
    dispatch(onboardingCarryForwardUpdate(val)).then(() => {
      dispatch(onboardingCarryForwardTypeLoad())
    })
  }

  const loadTypes = () => {
    dispatch(onboardingLeaveTypesLoad({ filter_by: { name: ['Annual'] } }))
  }

  const onSubmit = ({ value }: { value: string }): void => {
    dispatch(onboardingCarryForwardUpdate(value, 'carry_forward')).then(() => {
      dispatch(onboardingCarryForwardLoad())
    })
  }

  const handleAttachmentRequired = async (is_attachment_required: boolean) => {
    await dispatch(
      onboardingLeaveTypesUpdate(
        { ...annual, is_attachment_required },
        { filter_by: { name: ['Annual'] } }, // ? bypass the annual leave validation in BE
      ),
    )
    loadTypes()
  }

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'remaining_balance_onboarding' })

  useEffect(() => {
    loadTypes()
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!organizationType?.value}
    >
      <Typography
        variant="heading"
        text={I18n.t('remaining_balance_settings')}
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('remaining_balance_settings_description')}
      />

      <Spacer height="32px" />

      {/* @ts-expect-error Need to RadioWithContent.js to TypeScript */}
      <RadioWithContent
        options={options}
        disabled={fetching}
        value={organizationType?.value || ''}
        handleChange={(val: string): void => handleChange(val)}
      >
        {organizationType?.value === 'Limited carry' ? (
          <>
            <Spacer height="10px" />
            <Form
              onSubmit={onSubmit}
              initialValues={{ value: organizationLimit?.value }}
              render={({ handleSubmit, pristine }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <Flex style={{ gap: '8px' }}>
                    {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
                    <TextInputField
                      name="value"
                      validate={composeValidators(required, number)}
                      style={{ width: 70, marginInlineStart: '24px' }}
                      inputStyle={{ width: '100%' }}
                    />
                    <Button
                      disabled={pristine || fetching}
                      type="submit"
                      label={I18n.t('save')}
                      onClick={(): void => {
                        handleSubmit()
                      }}
                      size="medium"
                    />
                  </Flex>
                </form>
              )}
            />
          </>
        ) : (
          <></>
        )}
      </RadioWithContent>

      {annual?.id && (
        <>
          <Spacer height="16px" />
          <AnnualLeaveRequiredReason
            active={annual.is_attachment_required}
            onChange={(_, isChecked) => handleAttachmentRequired(isChecked)}
            disabled={fetchingTypes}
          />
        </>
      )}
    </StepTemplate>
  )
}

export default CarryForward
