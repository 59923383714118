export const ATTENDANCE_TRACKING_REPORT_LOAD = 'ATTENDANCE_TRACKING_REPORT_LOAD'
export const ATTENDANCE_TRACKING_REPORT_LOAD_PENDING = 'ATTENDANCE_TRACKING_REPORT_LOAD_PENDING'
export const ATTENDANCE_TRACKING_REPORT_LOAD_FULFILLED = 'ATTENDANCE_TRACKING_REPORT_LOAD_FULFILLED'
export const ATTENDANCE_TRACKING_REPORT_LOAD_REJECTED = 'ATTENDANCE_TRACKING_REPORT_LOAD_REJECTED'

export const ATTENDANCE_TRACKING_REPORT_SHARE = 'ATTENDANCE_TRACKING_REPORT_SHARE'
export const ATTENDANCE_TRACKING_REPORT_SHARE_PENDING = 'ATTENDANCE_TRACKING_REPORT_SHARE_PENDING'
export const ATTENDANCE_TRACKING_REPORT_SHARE_FULFILLED =
  'ATTENDANCE_TRACKING_REPORT_SHARE_FULFILLED'
export const ATTENDANCE_TRACKING_REPORT_SHARE_REJECTED = 'ATTENDANCE_TRACKING_REPORT_SHARE_REJECTED'
