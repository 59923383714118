import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  fetching: false,
  offboarding_workflow_steps: [],
}

type OffboardingWorkflowState = typeof initialState

export default function reducer(state = initialState, action: Action): OffboardingWorkflowState {
  const { payload } = action
  switch (action.type) {
    case actions.OFFBOARDINGWORKFLOW_LOAD_PENDING:
    case actions.OFFBOARDINGWORKFLOW_PUT_PENDING:
    case actions.OFFBOARDINGWORKFLOW_DELETE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.OFFBOARDINGWORKFLOW_LOAD_FULFILLED:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.OFFBOARDINGWORKFLOW_PUT_FULFILLED:
    case actions.OFFBOARDINGWORKFLOW_CHANGE_FULFILLED:
    case actions.OFFBOARDINGWORKFLOW_DELETE_FULFILLED:
      if (payload.data.message) Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.OFFBOARDINGWORKFLOW_PUT_REJECTED:
    case actions.OFFBOARDINGWORKFLOW_LOAD_REJECTED:
    case actions.OFFBOARDINGWORKFLOW_DELETE_REJECTED:
    case actions.OFFBOARDINGWORKFLOW_CHANGE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
