import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type InitialStateType = {
  fetching: boolean
  organization_configuration: {
    value?: string
  }
}

export const initialState: InitialStateType = {
  fetching: false,
  organization_configuration: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_PENDING:
    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED:
    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_LOAD_REJECTED:
    case actions.ONBOARDING_ORGANIZATION_CONFIGURATIONS_PUT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
