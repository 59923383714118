import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import Arrow from 'components/global/icons/Arrow'
import Info from 'components/global/icons/Info'
import { Flex } from 'components/global/atoms'

const AlertMessage = ({ message, link, testId }) => (
  <div
    data-testid={testId}
    className="jisr-alert-message"
  >
    <Flex>
      <Info className="mx-3" />
      <p>{message}</p>
      {link && (
        <p
          className="jisr-alert-message__link"
          onClick={link.onClick}
        >
          {I18n.t(link.label)}
          <Arrow color="azure" />
        </p>
      )}
    </Flex>
  </div>
)

AlertMessage.propTypes = {
  message: PropTypes.node,
  testId: PropTypes.string,
  link: PropTypes.shape(),
}

export default AlertMessage
