import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type InitialValueType = {
  isElevatusShown: boolean
  isWorkableShown: boolean
  isTawuniyaShown: boolean
  isSSOShown: boolean
  showOpenApi: boolean
  showWebhook: boolean
  showLumofy: boolean
  showAlmosafer: boolean
  fetching: boolean
}

const initialValues: InitialValueType = {
  isElevatusShown: false,
  showOpenApi: false,
  fetching: false,
  isWorkableShown: false,
  isTawuniyaShown: true,
  showWebhook: false,
  isSSOShown: false,
  showLumofy: false,
  showAlmosafer: false,
}

export default function reducer(state = initialValues, action: Action): InitialValueType {
  const { payload } = action

  switch (action.type) {
    case actions.GET_STATUS_ALLOWED_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GET_STATUS_ALLOWED_FULFILLED: {
      return {
        ...state,
        fetching: false,
        isElevatusShown: payload?.data?.data?.elevatus,
        showOpenApi: payload?.data?.data?.open_api,
        isWorkableShown: payload?.data?.data?.workable,
        showWebhook: payload?.data?.data?.webhook,
        isTawuniyaShown: payload?.data?.data?.tawuniya,
        isSSOShown: payload?.data?.data?.sso,
        showLumofy: payload?.data?.data?.lumofy,
        showAlmosafer: payload?.data?.data?.almosafer,
      }
    }

    case actions.GET_STATUS_ALLOWED_REJECTED: {
      if (payload?.data?.message) Toastr.error(handleResponseErr(payload.data.message))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
