import { Trans } from 'react-i18next'
import classNames from 'classnames'
import { format } from 'utils/date'
import i18n from 'translations/i18n'
import { Flex, Typography } from '@jisr-hr/ds'
import { Employee } from 'redux/authUser/types'
import { formatDateRequestOn } from 'containers/authorised/requests/helper'
import { useSelector } from 'utils/hooks'
import Signpost from '../../ds/Signpost'

import styles from './ReturnedForCorrection.module.css'

type Props = {
  firstListIndex: number
  secondListIndex: number
  corrections: Array<{
    id: string
    time: Date
    employee: Employee
    reason: string
  }>
}

export default function ReturnedForCorrection({
  corrections,
  firstListIndex,
  secondListIndex,
}: Props): JSX.Element[] | null {
  const employeeDate = useSelector(({ auth }) => auth.employeeDate)

  if (firstListIndex === 1 && secondListIndex === 0) {
    return corrections?.map((correction) => (
      <div
        key={correction.id}
        className={classNames(styles.returnForCorrection)}
      >
        <Signpost>
          <Flex
            itemsCenter
            justifyBetween
          >
            <Flex style={{ gap: 8 }}>
              <Typography
                variant="subtitle-2"
                text={
                  <Trans
                    i18nKey="customKey"
                    defaults={`${i18n.t(
                      'communcation.returned_for_correction_by_employee',
                    )} <span>{{employee_name}}</span>`}
                    values={{
                      employee_name: correction.employee.name_i18n,
                    }}
                    components={{
                      span: <span style={{ color: 'var(--ds-core-color-primary-600)' }} />,
                    }}
                  />
                }
              />
            </Flex>
            <Typography
              text={`${formatDateRequestOn(employeeDate, correction.time)} — ${format(
                correction.time,
                'p',
              )}`}
              variant="subtitle-2"
              textColor="color/fg/lighter"
            />
          </Flex>
          <Typography
            className={styles.correctionReason}
            text={correction.reason}
            variant="body-new/medium"
            textColor="color/fg/lighter"
          />
        </Signpost>
      </div>
    ))
  }

  return null
}
