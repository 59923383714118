export const SELECT_CURRENT_ACTIVE_CR = 'SELECT_CURRENT_ACTIVE_CR'

export const JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD = 'JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD'
export const JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_PENDING =
  'JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_PENDING'
export const JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED =
  'JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED'
export const JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED =
  'JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED'

export const VERIFY_COMMERCIAL_REGISTRATIONS = 'VERIFY_COMMERCIAL_REGISTRATIONS'
export const VERIFY_COMMERCIAL_REGISTRATIONS_PENDING = 'VERIFY_COMMERCIAL_REGISTRATIONS_PENDING'
export const VERIFY_COMMERCIAL_REGISTRATIONS_FULFILLED = 'VERIFY_COMMERCIAL_REGISTRATIONS_FULFILLED'
export const VERIFY_COMMERCIAL_REGISTRATIONS_REJECTED = 'VERIFY_COMMERCIAL_REGISTRATIONS_REJECTED'

export const SEND_OWNER_OTP = 'SEND_OWNER_OTP'
export const SEND_OWNER_OTP_PENDING = 'SEND_OWNER_OTP_PENDING'
export const SEND_OWNER_OTP_FULFILLED = 'SEND_OWNER_OTP_FULFILLED'
export const SEND_OWNER_OTP_REJECTED = 'SEND_OWNER_OTP_REJECTED'

export const VERIFY_OWNER_OTP = 'VERIFY_OWNER_OTP'
export const VERIFY_OWNER_OTP_PENDING = 'VERIFY_OWNER_OTP_PENDING'
export const VERIFY_OWNER_OTP_FULFILLED = 'VERIFY_OWNER_OTP_FULFILLED'
export const VERIFY_OWNER_OTP_REJECTED = 'VERIFY_OWNER_OTP_REJECTED'

export const WALLET_LOAD = 'WALLET_LOAD'
export const WALLET_LOAD_PENDING = 'WALLET_LOAD_PENDING'
export const WALLET_LOAD_FULFILLED = 'WALLET_LOAD_FULFILLED'
export const WALLET_LOAD_REJECTED = 'WALLET_LOAD_REJECTED'
