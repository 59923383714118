import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { Flex, Button } from '@jisr-hr/ds'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import I18n from 'translations/i18n'
import ApprovalState from './components/ApprovalState'
import Styles from './Workflow.module.css'

const AddidtionInfo = ({ lessDetail, onClick, teamOnLeave }) => {
  if (_.isEmpty(teamOnLeave)) return null

  return (
    <Frame
      title={I18n.t('leave_from_same_team_with_value', { number: teamOnLeave?.length })}
      titleSuffix={
        lessDetail && (
          <Button
            leadingIcon="arrow-narrow-down"
            label="Detail"
            size="small"
            variant="tinted"
            onClick={onClick}
          />
        )
      }
    >
      <Flex
        flexCol
        className={Styles.listContainer}
      >
        {teamOnLeave?.map((team, _i) => (
          <Flex
            key={_i}
            flexCol
            className={classNames(
              Styles.approvalState,
              team?.status === 'Pending' ? Styles.approvalPending : undefined,
            )}
          >
            <ApprovalState
              employeeName={team?.name}
              code={team?.code}
              avatar={team?.avatar_thumb}
              jobTitle={team?.job_title_i18n}
              date={team?.from}
              // time={format(team?.received_at, 'hh:mm a')}
              // status={team?.status}
              // status_i18n={team?.status}
              // comment="comment"
            />
          </Flex>
        ))}
      </Flex>
    </Frame>
  )
}

AddidtionInfo.propTypes = {
  lessDetail: PropTypes.bool,
  onClick: PropTypes.func,
  teamOnLeave: PropTypes.arrayOf(PropTypes.shape()),
}

export default AddidtionInfo
