import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function employeesNewIdLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/employees/new_code`
  const method = 'get'

  return {
    type: 'EMPLOYEES_NEW_ID_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function getAllEmployeesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/employees/get_all/`
  const method = 'GET'

  return {
    type: 'GET_ALL_EMPLOYEES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function employeeCreate(data) {
  const url = `/${API_VERSION_TWO}/employees/`
  const method = 'POST'
  return {
    type: 'EMPLOYEE_CREATE',
    payload: handleAPI(url, {}, method, { employee: data }),
  }
}

export function employeeUpdate(employee_id, data, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}`
  const method = 'PUT'
  return {
    type: 'EMPLOYEE_UPDATE',
    payload: handleAPI(url, params, method, { employee: data }),
  }
}

export function employeeDelete(employee_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}`
  const method = 'DELETE'
  return {
    type: 'EMPLOYEE_DELETE',
    payload: handleAPI(url, {}, method),
  }
}

export function reset() {
  return {
    type: 'RESET',
    employees: [],
  }
}

export function employeesEdit(id, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${id}`
  const method = 'get'

  return {
    type: 'EMPLOYEE_EDIT',
    payload: handleAPI(url, params, method),
  }
}

export function employeeReinvite(id, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${id}/invite`
  const method = 'get'
  return {
    type: 'EMPLOYEE_INVITE',
    payload: handleAPI(url, params, method),
  }
}

export function changeLanguage(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/configurations/language_preference`
  const method = 'PUT'
  return {
    type: 'CHANGE_LANGUAGE',
    payload: handleAPI(url, {}, method, { employee_configuration: data }),
  }
}

export function undoOffboarding(employee_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/revert_offboarding`
  const method = 'POST'
  return {
    type: 'UNDO_OFFBOARDING',
    payload: handleAPI(url, {}, method, {}),
  }
}

export function employeeDeleteChecker(id) {
  const url = `/${API_VERSION_TWO}/employees/${id}/deletion_restriction_reason`
  const method = 'GET'

  return {
    type: 'EMPLOYEE_DELETE_CHECKER',
    payload: handleAPI(url, {}, method),
  }
}

export function employeeBulkUpdate(data, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/bulk_update`
  const method = 'PUT'

  return {
    type: 'EMPLOYEE_BULK_UPDATE',
    payload: handleAPI(url, params, method, data),
  }
}
