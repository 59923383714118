import { useEffect, useState, memo, useCallback } from 'react'
import { Flex } from '@jisr-hr/ds'
import { useForm, useFormState } from 'react-final-form'
import JisrLoader from 'components/global/JisrLoader'
import {
  useCreateFlightTicketRequest,
  useInitiateFlightTicketRequestDataLoad,
} from 'apis/Request/FlightTicketRequest/useFlightTicketRequest'
import { omit } from 'lodash'
import { useDispatch, useSelector } from 'utils/hooks'
import { createLeaveRequest } from 'redux/actions/leaveRequestsAction'
import { RequestType } from 'containers/authorised/Finance/VacationSettlement/VacationsList/AnnualRequest/LeaveForm'
import { Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'
import {
  DependentDataType,
  SubmitFlightTicketRequestPayloadType,
} from '../../types/flightTicketRequest'
import FlightTicketForm from '../../components/forms/FlightTicketRequest/FlightTicketRequestForm'
import DependentForm from '../../components/forms/FlightTicketRequest/DependentForm/DependentForm'
import AnnualLeaveRequestSelectFiled from '../../components/forms/FlightTicketRequest/AnnualLeaveRequest/AnnualLeaveRequestSelectFiled'
import { formatLeaveRequestSubmissionPayload } from './LeaveRequest'
import { NewFlightTicketProps } from '../../components/forms/NewFlightTicket/NewFlightTicket'

export type FlightTicketRequestFormValueType = {
  flight_destinations: number
  flight_cabin_class: string
  round_trip: boolean
  flight_request_departure_date: string
  flight_request_returning_date: string
  linked_request_sequential_id: number
  require_flight_ticket?: boolean
  from_request?: NewFlightTicketProps['from_request']
}

type DependentWithRelationKey = DependentDataType & {
  relationships_key?: string
  listId?: string
}

export const formatFlightTicketRequestValues = (
  val: FlightTicketRequestFormValueType,
  addedDependent: DependentWithRelationKey[],
): SubmitFlightTicketRequestPayloadType => {
  return {
    flight_ticket_request: {
      travel_destination_id: val.flight_destinations,
      flight_class: val.flight_cabin_class,
      round_trip: val.round_trip,
      departure_date: val.flight_request_departure_date,
      return_date: val.flight_request_returning_date || undefined,
      flight_ticket_request_dependents_attributes: addedDependent.map((dependent) => {
        return {
          id: dependent?.id,
          family_detail_id: dependent?.family_detail_id,
          name_en: dependent.name_i18n,
          relationship: dependent.relationships_key,
          date_of_birth: dependent.date_of_birth,
          passport_number: dependent.passport_number,
          gender: dependent.gender,
          id_number: dependent.id_number,
        }
      }),
      linked_request_sequential_id: val.linked_request_sequential_id,
    },
  }
}

const FlightTicketRequest = (): JSX.Element => {
  const dispatch = useDispatch()
  const { setOnSubmit, vars, drawerToggle, actionCallback, editRequest, setInitialValues } =
    useRequest()
  const { loading, request } = useRequestDetail()
  const { reset, change } = useForm()
  const { values } = useFormState()

  const { fetching: addingLeaveRequest } = useSelector(({ leaveRequests }) => leaveRequests)
  const [addedDependent, setAddedDependent] = useState<DependentWithRelationKey[]>([])
  const id = vars.id ?? request?.employee?.id

  const { mutate: createFlightTicketRequest, isLoading } = useCreateFlightTicketRequest()
  const { data: intiateFlightTicketRequestData, isFetching } =
    useInitiateFlightTicketRequestDataLoad(id, {
      query: {
        select: (s) => s.data.data,
        enabled: !!id,
      },
    })

  const onSubmit = useCallback(
    (val: FlightTicketRequestFormValueType): void => {
      const submitFlightTicketRequestPayload = formatFlightTicketRequestValues(val, addedDependent)

      if (val?.require_flight_ticket && val?.from_request === 'LeaveRequest') {
        const combinedRequestsPayload = {
          ...formatLeaveRequestSubmissionPayload(val),
          ...submitFlightTicketRequestPayload,
          require_flight_ticket: val?.require_flight_ticket,
        }

        dispatch(
          createLeaveRequest(id, {
            ...combinedRequestsPayload,
            attachments: combinedRequestsPayload.attachments?.map((it: RequestType) =>
              omit(it, 'id'),
            ),
          }),
        ).then(() => {
          drawerToggle()
          actionCallback?.()
        })
        return
      }

      createFlightTicketRequest(
        { employeeId: id, data: submitFlightTicketRequestPayload },
        {
          onSuccess: () => {
            drawerToggle()
            actionCallback?.()
          },
        },
      )
    },
    [addedDependent],
  )

  useEffect(() => {
    change('show_flight_ticket_request', undefined)

    return (): void => {
      reset(values)
    }
  }, [])

  useEffect(() => {
    setOnSubmit(onSubmit)

    return (): void => {
      setOnSubmit(null)
    }
  }, [addedDependent])

  useEffect(() => {
    if (editRequest) {
      setInitialValues({
        flight_destinations: request?.travel_destination?.id,
        flight_cabin_class: request?.flight_class,
        round_trip: request?.round_trip,
        flight_request_departure_date: request?.departure_date,
        flight_request_returning_date: request?.return_date,
      })
      setAddedDependent(request?.dependents ?? [])
    }
  }, [editRequest])

  return (
    <Flex
      flexCol
      className="gap-4"
    >
      {(isFetching || loading || isLoading || addingLeaveRequest) && <JisrLoader absolute />}
      <Profile employee={vars.employee} />
      <FlightTicketForm
        flightTicketPolicyData={intiateFlightTicketRequestData?.flight_ticket_policy}
        employeeNotEnrolledErrorMsgI18n={
          intiateFlightTicketRequestData?.employee_not_enrolled_error_i18n
        }
        travelDestination={intiateFlightTicketRequestData?.travel_destinations}
      />
      {(intiateFlightTicketRequestData?.flight_ticket_policy?.dependents_coverage_allowed ||
        intiateFlightTicketRequestData?.employee_not_enrolled_error_i18n) && (
        <DependentForm
          dependentsData={intiateFlightTicketRequestData?.dependents_data}
          flightTicketPolicyData={intiateFlightTicketRequestData?.flight_ticket_policy}
          addedDependent={addedDependent}
          setAddedDependent={setAddedDependent}
        />
      )}

      {!values?.require_flight_ticket && <AnnualLeaveRequestSelectFiled id={+id} />}
    </Flex>
  )
}

export default memo(FlightTicketRequest)
