import { Dispatch } from 'react'
import { Button, Spacer, Flex, Modal } from '@jisr-hr/ds'
import { Form, TextInputField } from 'components/final-form'
import I18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import {
  onboardingBenefitCreate,
  onboardingBenefitUpdate,
  onboardingBenefitLoad,
  TBenefitCreate,
} from 'redux/payrollOnboarding/allowances/actionsCreators'
import { required } from 'components/global/form/FormValidations'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import styles from './style.module.css'
import { TEditValue } from '../Allowances'

type TAllowanceForm = {
  onClose: () => void
  initialValues: TEditValue
  fetching?: boolean
  isOpen: boolean
  editItem: Dispatch<React.SetStateAction<TEditValue>>
}

const AllowanceForm = ({
  onClose,
  initialValues,
  fetching,
  isOpen,
  editItem,
}: TAllowanceForm): JSX.Element => {
  const dispatch = useDispatch()
  const onSubmit = (values: TBenefitCreate): void => {
    if (values.id) {
      dispatch(onboardingBenefitUpdate({ ...values, category: 'EarningType' })).then(() => {
        dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
        onClose()
        editItem({})
      })
    } else {
      dispatch(
        onboardingBenefitCreate({ ...values, category: 'EarningType', is_optional: true }),
      ).then(() => {
        dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
        onClose()
        dispatch(organizationInfo())
        editItem({})
      })
    }
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      header={{
        title: initialValues.id ? I18n.t('update_allowance') : I18n.t('add_new_allowance'),
        withClose: true,
      }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, invalid }): JSX.Element => (
          <div className={styles.formContent}>
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('allowance_name_en')}
              name="name"
              autoFocus={!initialValues.id}
              placeholder={I18n.t('type_allowance_name')}
              validate={required}
            />
            <Spacer height="8px" />
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              label={I18n.t('allowance_name_ar')}
              name="name_ar"
              placeholder={I18n.t('type_allowance_name')}
              validate={required}
            />
            <Spacer height="16px" />
            <Flex justifyEnd>
              <Button
                label={initialValues.id ? I18n.t('edit') : I18n.t('add')}
                disabled={fetching || pristine || invalid}
                onClick={handleSubmit}
                size="medium"
              />
            </Flex>
          </div>
        )}
      />
    </Modal>
  )
}

export default AllowanceForm
