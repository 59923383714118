import I18n from 'translations/i18n'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Badge } from '@jisr-hr/ds'

type ExemptedEmployeeProps = {
  isExempted: boolean
  fullShow?: string
}

const ExemptedEmployee = ({ isExempted, fullShow }: ExemptedEmployeeProps): JSX.Element | null => {
  return isExempted ? (
    <Tooltip
      description={!fullShow ? I18n.t('exempted_from_attendance_deductions') : ''}
      trigger={
        <span>
          <Badge
            // bordered
            label={fullShow ? I18n.t('exempted_from_attendance_deductions') : 'E'}
            size={fullShow ? 'medium' : 'small'}
            color="green"
            variant="tinted"
          />
        </span>
      }
    />
  ) : null
}

export default ExemptedEmployee
