import { Containers, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { SelectField } from 'components/final-form'
import { useAnnualLeaveRequestDataLoad } from 'apis/Request/FlightTicketRequest/useFlightTicketRequest'
import { AnnualLeaveListLabel } from './RequestListLabel'

const AnnualLeaveRequestSelectFiled = ({ id }: { id: number }): JSX.Element => {
  const { data: annualLeaveRequestData } = useAnnualLeaveRequestDataLoad(id, {
    query: {
      select: (s) => s.data.data,
      enabled: !!id,
    },
  })

  return (
    <Containers
      color="white"
      borderRadius="sm"
      border
      width="100%"
      height="auto"
      className="p-3 flex flex-col gap-4"
    >
      <Typography
        text={I18n.t('label.annual_leave')}
        variant="title-1"
      />

      <Flex
        flexCol
        className="gap-2"
      >
        <Typography
          variant="interface/default/sm"
          text={I18n.t('leave_requests')}
        />
        <SelectField
          // @ts-expect-error // select field needs to be migrated to TS
          options={annualLeaveRequestData?.requests?.map((item) => ({
            id: item?.sequential_id,
            label: <AnnualLeaveListLabel {...item} />,
          }))}
          valueKey="id"
          labelKey="label"
          name="linked_request_sequential_id"
          testId="annual_request_list"
          placeholder={I18n.t('placeholder.add_annual_leave_request_id')}
        />
      </Flex>
    </Containers>
  )
}

export default AnnualLeaveRequestSelectFiled
