import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  step: 1,
  values: {},
  allowRedirect: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.VALIDATE_HANDLE_NEXT_STEP:
      return {
        ...state,
        step: payload.step,
      }

    case actions.SET_VALUES_STEPS:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload.values,
        },
      }

    case actions.REST_VALUES_STEPS:
      return {
        ...state,
        values: {},
        allowRedirect: false,
      }
    case actions.SET_ALLOW_REDIRECT:
      return {
        ...state,
        allowRedirect: true,
      }

    default:
      return state
  }
}
