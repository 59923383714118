import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Popper } from '@jisr-hr/design-system'
import { Button } from '@jisr-hr/ds'
import dotIcon from 'assets/images/dots-icon.svg'
import RequestDrawer from 'components/global/templates/RequestDrawer'
import { Fab } from 'components/global/atoms'
import {
  applicableRequestsLoad,
  applicableRequestTypesLoad,
} from 'redux/actions/applicableRequestsActions'
import { getRequestTranslationKey, customRequestTypePaths } from 'utils/uiHelpers'
import { isEmpty, isNumber } from 'lodash'

const ApplicableRequests = ({
  employeeId,
  placement,
  requiredRequests,
  excludeTypes,
  vars,
  typeTrigger,
  showThreeDotIcon,
  actionCallback,
}) => {
  const { request_types: applicableRequestTypes } = useSelector(
    ({ applicableRequests }) => applicableRequests,
  )
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [createRequest, setCreateRequest] = React.useState(null)
  const [typeId, setTypeId] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)

  const pathData = pathname.split('/').find((p) => customRequestTypePaths.includes(p))
  const withCustomTypes = !isEmpty(pathData)
  const requestTypeList = withCustomTypes
    ? applicableRequestTypes.filter((art) => !isNumber(art.id)).map((art) => art.type)
    : applicableRequestTypes
  const customTypes = withCustomTypes && applicableRequestTypes.filter((art) => isNumber(art.id))
  const allTypes = [...requestTypeList, 'CancelltionRequest', 'EditTime']
  let requestTypes = requiredRequests?.length ? requiredRequests : allTypes
  requestTypes = requestTypes.filter((r) => !excludeTypes?.includes(r))
  const options = requestTypes
    .filter((r) => allTypes.includes(r))
    .map((type) => ({
      label: I18n.t(getRequestTranslationKey(type)),
      onClick: () => (typeTrigger?.[type] ? typeTrigger?.[type]() : setCreateRequest(type)),
      testId: type,
    }))

  const createTypeRequest = (t) => {
    setCreateRequest(t.type)
    setTypeId(t.id)
  }

  const allOptions = withCustomTypes
    ? [
        ...options,
        ...customTypes.map((t) => ({
          id: t.id,
          label: t.name_i18n,
          onClick: () => (typeTrigger?.[t.type] ? typeTrigger?.[t.type]() : createTypeRequest(t)),
          testId: t.type,
        })),
      ]
    : options

  useEffect(() => {
    // if (isOpen && !applicableRequestTypes?.length) {
    if (isOpen) {
      if (withCustomTypes) {
        dispatch(applicableRequestTypesLoad(employeeId))
      } else {
        dispatch(applicableRequestsLoad(employeeId))
      }
    }
  }, [isOpen])

  return (
    <React.Fragment>
      <Popper
        onClick={(val) => setIsOpen(val)}
        options={allOptions}
        dropdown
        maxHeight={350}
        placement={placement || 'bottom'}
        trigger={
          showThreeDotIcon ? (
            <Fab
              Icon={
                <img
                  src={dotIcon}
                  alt=""
                />
              }
              style={{ borderRadius: '50%', backgroundColor: 'white' }}
              data-testid="btn-add-new-request"
              onClick={() => setIsOpen(!isOpen)}
            />
          ) : (
            <Button
              testId="btn-add-new-request"
              label={I18n.t('new_request')}
              onClick={() => setIsOpen(!isOpen)}
              variant="filled"
              // divider
              trailingIcon="chevron-down"
              size="small"
            />
          )
        }
      />
      <RequestDrawer
        // title={createRequest}
        vars={{
          ...vars,
          id: employeeId,
          request_type_id: typeId,
          request: {},
        }}
        active={!!createRequest}
        requestType={createRequest}
        actionCallback={() => actionCallback()}
        onClose={() => {
          setCreateRequest(null)
          setTypeId(null)
        }}
      />
    </React.Fragment>
  )
}

ApplicableRequests.defaultProps = {
  showThreeDotIcon: false,
}

ApplicableRequests.propTypes = {
  placement: PropTypes.string,
  requiredRequests: PropTypes.arrayOf(PropTypes.string),
  excludeTypes: PropTypes.arrayOf(PropTypes.string),
  employeeId: PropTypes.number,
  vars: PropTypes.shape(),
  typeTrigger: PropTypes.shape(),
  showThreeDotIcon: PropTypes.bool,
}

export default React.memo(ApplicableRequests)
