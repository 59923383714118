import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { StandardShiftState } from './standardShiftTypes'

const initialState: StandardShiftState = {
  fetching: false,
  loading: false,
  assignFetching: false,
  shifts: [],
  filtersShifts: [],
  assignShifts: [],
  shift: {},
  stats: {},
  pagination: {},
}

export default function reducer(state = initialState, action: Action): StandardShiftState {
  switch (action.type) {
    case actions.RESET_STANDARD_SHIFTS:
      return {
        ...state,
        shifts: [],
        pagination: {},
      }

    case actions.RESET_SHIFT_DETAIL:
      return {
        ...state,
        shift: {},
      }

    case actions.STANDARD_SHIFT_LISTING_LOAD_PENDING:
    case actions.STANDARD_SHIFT_LISTING_PAGINATION_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STANDARD_SHIFT_UPDATE_PENDING:
    case actions.STANDARD_SHIFT_CREATE_PENDING:
    case actions.STANDARD_SHIFT_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.STANDARD_SHIFT_LISTING_PAGINATION_FULFILLED: {
      const listingPagination: Pick<StandardShiftState, 'pagination' | 'stats' | 'shifts'> =
        action.payload.data.data

      const shifts = [...state.shifts, ...action.payload.data.data.shifts]
      return {
        ...state,
        ...listingPagination,
        shifts,
        fetching: false,
      }
    }

    case actions.STANDARD_SHIFT_LISTING_ASSIGN_LOAD_PENDING: {
      return {
        ...state,
        assignFetching: true,
      }
    }

    case actions.STANDARD_SHIFT_LISTING_LOAD_FULFILLED: {
      const standardListing: Pick<StandardShiftState, 'pagination' | 'stats' | 'shifts'> =
        action.payload.data.data

      return {
        ...state,
        ...standardListing,
        filtersShifts: state.shifts?.length > 0 ? state?.shifts : action.payload.data.data.shifts,
        fetching: false,
      }
    }

    case actions.STANDARD_SHIFT_LISTING_ASSIGN_LOAD_FULFILLED: {
      return {
        ...state,
        assignShifts: action.payload.data.data.shifts,
        assignFetching: false,
      }
    }
    case actions.STANDARD_SHIFT_LOAD_FULFILLED:
      const standardShiftLoad: Pick<StandardShiftState, 'shift'> = action.payload.data.data

      return {
        ...state,
        ...standardShiftLoad,
        loading: false,
      }

    case actions.STANDARD_SHIFT_CREATE_FULFILLED:
    case actions.STANDARD_SHIFT_DELETE_FULFILLED:
    case actions.STANDARD_SHIFT_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.STANDARD_SHIFT_LISTING_LOAD_REJECTED:
    case actions.STANDARD_SHIFT_LISTING_PAGINATION_REJECTED:
    case actions.STANDARD_SHIFT_LOAD_REJECTED:
    case actions.STANDARD_SHIFT_CREATE_REJECTED:
    case actions.STANDARD_SHIFT_DELETE_REJECTED:
    case actions.STANDARD_SHIFT_UPDATE_REJECTED:
    case actions.STANDARD_SHIFT_LISTING_ASSIGN_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        assignFetching: false,
      }

    default:
      return state
  }
}
