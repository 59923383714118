import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { withLenses, lens } from '@dhmk/zustand-lens'

import { ViolationStateT, createViolationsSlice } from './createViolationsSlice'

type ViolationStore = {
  violations: ViolationStateT
}

export const useViolationsStore = create<ViolationStore>()(
  devtools(
    withLenses(() => ({
      violations: lens(createViolationsSlice),
    })),
    {
      name: 'ViolationStore',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
)
