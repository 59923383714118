import React from 'react'
import PropTypes from 'prop-types'
import { useForm, useFormState } from 'react-final-form'
import { Trans } from 'react-i18next'
import { Typography, Flex, Banner } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { subDays } from 'date-fns'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { required } from 'components/global/form/FormValidations'
import { SelectField, DatePickerDSField } from 'components/final-form'

import SelectShifts from '../../../ds/SelectShifts'
import { useRequest } from '../../../RequestProvider'

const excuseTypes = [
  { label: I18n.t('full_day_excuse'), value: 'full_day' },
  { label: I18n.t('late_in_excuse'), value: 'late_in' },
  { label: I18n.t('early_out_excuse'), value: 'early_out' },
  { label: I18n.t('middle_of_day_excuse'), value: 'middle_of_day' },
]

const ExcuseDateAndType = ({ hasShifts, isUnAvailable }) => {
  const formState = useFormState()

  const { vars } = useRequest()
  const { change } = useForm()

  const { type, excuse_date } = formState.values

  return (
    <Frame title={I18n.t('excuse_date_and_type')}>
      <Flex
        flexCol
        style={{ gap: '16px' }}
      >
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-6">
            <SelectField
              name="type"
              label={I18n.t('type')}
              options={excuseTypes}
              disabled={vars?.shiftView}
              testId="excuse-type"
            />
          </div>
          <div className="col-span-6">
            <DatePickerDSField
              label={I18n.t('date')}
              name="excuse_date"
              validate={required}
              calenderProps={{
                minDate: subDays(new Date(), 59),
              }}
              textFieldProps={{
                disabled: vars?.shiftView,
              }}
              onChange={() => change('initialShiftId', undefined)}
            />
          </div>
        </div>

        {excuse_date && (isUnAvailable || (type !== 'full_day' && !hasShifts)) && (
          <Banner
            color="success"
            variant="tinted"
            leadingIcon="check-circle-filled"
            description={
              <Flex className="ms-4">
                <Typography
                  text={
                    <>
                      <Trans
                        i18nKey="no_attendance_required_on_this_day"
                        components={{ ul: <ul />, li: <li /> }}
                      />

                      {type !== 'full_day' && (
                        <ul>
                          <li>{I18n.t('no_shift_was_scheduled')}</li>
                        </ul>
                      )}
                    </>
                  }
                  variant="body-new/semibold"
                />
              </Flex>
            }
          />
        )}

        {type !== 'full_day' && excuse_date && !isUnAvailable && <SelectShifts />}
      </Flex>
    </Frame>
  )
}

ExcuseDateAndType.propTypes = {
  hasShifts: PropTypes.bool,
  isUnAvailable: PropTypes.bool,
}

export default ExcuseDateAndType
