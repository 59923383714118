import { useEffect, useRef, useState } from 'react'
import { TreeNode } from 'react-organizational-chart'
import { isEmpty } from 'lodash'
import { Department, Form } from 'redux/newDepartments/types'
import { useDispatch, useSelector } from 'utils/hooks'
import classNames from 'classnames'
import { Typography } from '@jisr-hr/ds'
import {
  departmentsPost,
  departmentsUpdate,
  hierarchicalDepartmentsLoad,
  listDepartmentsLoad,
} from 'redux/newDepartments/actionCreators'
import DSIcon from 'components/DSIcon'
import ConfirmationModal from 'containers/authorised/NewOnboarding/components/ConfirmationModal'
import i18n from 'translations/i18n'

import Card from './Card'
import DepartmentForm, { FormTypes } from './DepartmentForm'
import { useDepartmentsContext } from './context'
import styles from '../style.module.css'
import { scrollOptions } from './helper'
import DepartmentEmployees from './DepartmentEmployees'
import MoveDepartmentConfirm from './MoveDepartmentConfirm'
import { countNestedList } from './ContentDeleteModal'

type Props = {
  data: Department
  readonly?: boolean
}

type RIds = {
  child_id?: number
  parent_id?: number
}

const Node = ({ data, readonly }: Props): JSX.Element => {
  const { search, isInOnboarding, isNewOnboarding, isHighTier, setSearch } = useDepartmentsContext()
  const [show, setShow] = useState(!!isNewOnboarding)
  const [vals, setVals] = useState<Form>({ name: '', manager_id: '0', name_ar: '' })
  const [isMoveDepartment, setIsMoveDepartment] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [releatedIds, setReleatedIds] = useState<RIds>()
  const [showEmployeeList, setShowEmployeeList] = useState(false)
  const companyName = useSelector((s) => s.auth.employee?.organization?.name_i18n)
  const { build_type } = useSelector((s) => s.newDepartments)

  const dispatch = useDispatch()
  const cardRef = useRef<HTMLDivElement>(null)
  const cardDivRef = useRef<HTMLDivElement>(null)

  const componyData = {
    id: 0,
    name: companyName,
    name_ar: companyName,
    name_i18n: companyName,
    parent_id: null,
  }
  const count = data.departments?.length || 0
  const isInclude =
    search?.department_path && search.department_path.includes(data.id) && search.value !== data.id

  const supDepartmentCount = countNestedList(data.departments)

  // neet to check when to update department and remove parent should be the parent is org

  const onConfirmMove = (): void => {
    if (vals.id) {
      dispatch(
        departmentsUpdate(vals.id, {
          id: vals.id,
          parent_id: vals.parent_id,
        }),
      ).then(() => {
        setIsMoveDepartment?.(false)
        setIsOpen(false)
        setSearch({ value: vals.id })
        dispatch(
          hierarchicalDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'manual' },
              }),
          }),
        )
        dispatch(
          listDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'sheet' },
              }),
          }),
        )
      })
    }
  }

  const onSubmit = (values: Form, form: FormTypes): void => {
    if (values.id) {
      dispatch(departmentsUpdate(values.id, values)).then(() => {
        form.reset()
        setIsOpen(false)
        setSearch({ value: values.id })
        dispatch(
          hierarchicalDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'manual' },
              }),
          }),
        )
        dispatch(
          listDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'sheet' },
              }),
          }),
        )
      })
    } else {
      if (Number(values.parent_id) === 0) delete values.parent_id
      dispatch(
        departmentsPost({ ...values, ...(isNewOnboarding && { reference_source: 'custom' }) }),
      ).then(() => {
        form.reset()
        setIsOpen(false)
        setSearch({ value: values?.parent_id, isCreate: true })
        dispatch(
          hierarchicalDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'manual' },
              }),
          }),
        )
        dispatch(
          listDepartmentsLoad({
            ...(isNewOnboarding &&
              isHighTier && {
                filter: { source: 'sheet' },
              }),
          }),
        )
        setShow(true)
      })
    }
  }

  const onSubmitNewOnboarding = (values: Form, form: FormTypes): void => {
    const isHasChanges = data.parent_id !== values.parent_id
    const showMoveSubDepartments = !!data?.departments?.length && isHasChanges
    if (values.id) {
      if (values.parent_id === 0) delete values.parent_id
      if (showMoveSubDepartments) {
        const { name, name_ar } = values
        dispatch(departmentsUpdate(values.id, { name, name_ar })).then(() => {
          dispatch(
            hierarchicalDepartmentsLoad({
              ...(isNewOnboarding &&
                isHighTier && {
                  filter: { source: 'manual' },
                }),
            }),
          )
          setVals(values)
          setIsMoveDepartment?.(true)
        })
      } else {
        onSubmit(values, form)
      }
    } else {
      onSubmit(values, form)
    }
    return undefined
  }

  useEffect(() => {
    if (show) {
      cardDivRef.current?.scrollIntoView(scrollOptions)
    }
  }, [show])

  useEffect(() => {
    if (search?.department_path) {
      setShow(!!isInclude)
      if (search?.value === data.id) {
        cardRef.current?.scrollIntoView({
          ...scrollOptions,
          ...(search?.isCreate && { block: 'end' }),
        })
      }
    }
  }, [search])

  useEffect(() => {
    if (build_type !== 'hide_auto') {
      setShow(true)
    }
  }, [build_type])

  return (
    <>
      <TreeNode
        className={styles.tree}
        key={data.id}
        label={
          <div ref={cardDivRef}>
            <Card
              hidAddIcon={data?.department_path?.length === 6}
              ref={cardRef}
              readonly={readonly}
              isOnboarding={isInOnboarding}
              isNewOnboarding={!!isNewOnboarding}
              isHighTier={isHighTier || false}
              isActive={search?.value === data.id}
              onClickAddParent={(isFirstLevel): void => {
                setIsOpen(true)
                setIsEdit(false)
                setReleatedIds({
                  parent_id: isFirstLevel ? 0 : data.parent_id,
                  child_id: data.id,
                })
              }}
              onClickAddSub={(): void => {
                setIsOpen(true)
                setIsEdit(false)
                setReleatedIds({
                  parent_id: data.id,
                })
              }}
              onClickAddSide={(parentId): void => {
                setIsOpen(true)
                setIsEdit(false)
                setReleatedIds({
                  parent_id: parentId ?? 0,
                })
              }}
              onClickEmp={(): void => setShowEmployeeList(true)}
              onClickEdit={(): void => {
                setIsOpen(true)
                setIsEdit(true)
                setReleatedIds({
                  parent_id: isNewOnboarding ? data.parent_id ?? 0 : data.parent_id,
                })
              }}
              parent_id={data.parent_id}
              name={data.name_i18n}
              data={data}
              manager={data.manager}
              numberOfEmployees={data.number_of_employees}
              totalNumberOfEmployees={data.total_number_of_employees}
              collapse={
                <div
                  className={classNames(styles.collapse, !isEmpty(data.departments) && 'pointer')}
                  onClick={(): void => {
                    setShow(!show)
                  }}
                >
                  <DSIcon name="department" />
                  <Typography
                    text={count}
                    variant="interface/default/sm"
                  />
                  {!isEmpty(data.departments) && (
                    <DSIcon name={show ? 'chevron-down' : 'chevron-up'} />
                  )}
                </div>
              }
            />
          </div>
        }
      >
        {show
          ? data.departments &&
            data.departments.map(
              (d: Department, i: number): JSX.Element => (
                <Node
                  data={d}
                  readonly={readonly}
                  key={i}
                />
              ),
            )
          : null}
      </TreeNode>
      <DepartmentForm
        data={data}
        initialValues={{
          ...releatedIds,
          ...(isEdit && {
            id: data.id,
            name: data.name,
            name_ar: data.name_ar,
            manager_id: data.manager?.id,
            category_ids: data.categories?.map((c) => c.id),
          }),
        }}
        isOpen={isOpen}
        componyData={data.parent_id ? undefined : componyData}
        setIsOpen={setIsOpen}
        onSubmit={isNewOnboarding ? onSubmitNewOnboarding : onSubmit}
        isHighTier={isHighTier || false}
      />
      <DepartmentEmployees
        data={data}
        isOpen={showEmployeeList}
        setIsOpen={setShowEmployeeList}
      />
      <ConfirmationModal
        isOpen={isMoveDepartment}
        title={i18n.t(`Move ${supDepartmentCount || 0} departments to Bar?`)}
        description={
          <div style={{ marginInline: 24 }}>
            <MoveDepartmentConfirm data={data} />
          </div>
        }
        titleConfirm="keep"
        titleClose="label.move_all"
        colorConfirm="primary"
        onConfirm={(): void => {
          setIsMoveDepartment(false)
          setIsOpen(false)
        }}
        onClose={onConfirmMove}
      />
    </>
  )
}

export default Node
