import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type StatusExportHistoryTypes = {
  filterKey: string
  filters: string[]
}

const StatusExportHistory = (props: StatusExportHistoryTypes): JSX.Element => {
  const { status_export_history } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={status_export_history}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default StatusExportHistory
