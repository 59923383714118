import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isRtl } from 'utils/uiHelpers'
import { debounce } from 'lodash'

const DEBOUNCE_TIME = 400

const Scrolling = ({
  as = 'div',
  children,
  onScrollEnd = () => {},
  onScrollUpEnd = (e) => e,
  onScrollSide = (isHorizontalScrollEnd) => isHorizontalScrollEnd,
  ...props
}) => {
  const Tag = as
  const [scrollHeight, setScrollHeight] = useState(0)
  const debouncedScroll = debounce(() => onScrollEnd(), DEBOUNCE_TIME, { leading: true })
  const debouncedScrollUp = debounce((e) => onScrollUpEnd(e), DEBOUNCE_TIME, {
    leading: true,
  })

  const trackScrolling = (e) => {
    if (onScrollSide) {
      const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget
      const isHorizontalScrollEnd = isRtl
        ? scrollLeft > -(scrollWidth - clientWidth - 10)
        : scrollLeft + clientWidth + 5 < scrollWidth
      onScrollSide(isHorizontalScrollEnd)
    }
    const obj = e.target
    const bottom = obj.scrollHeight - obj.scrollTop - obj.clientHeight < 1
    if (!bottom && obj.scrollTop === 0) {
      debouncedScrollUp(e)
      return false
    }
    // reset when clear the records
    if (obj.scrollHeight < scrollHeight) {
      setScrollHeight(0)
      e.target.scrollTop = 0
      return false
    }

    // save scroll height to check it recorde reset
    setScrollHeight(e.target.scrollHeight)

    // if scroll on the bottom
    if (bottom) {
      debouncedScroll()
    }
    return true
  }

  return (
    <Tag
      {...props}
      ref={props.refScrolling}
      onScroll={trackScrolling}
    >
      {children}
    </Tag>
  )
}

Scrolling.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  fallback: PropTypes.node,
  onScrollEnd: PropTypes.func,
  onScrollUpEnd: PropTypes.func,
  onScrollHorizontalEnd: PropTypes.func,
}

export default Scrolling
