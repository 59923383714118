import { createContext, useState } from 'react'

import type {
  IdType,
  OnboardingContextType,
  OnboardingStepsTypes,
  OrgType,
  SidebarType,
  ActiveCR,
  EditBank,
  CommercialRegistration,
} from './types/onboardingTypes'
import { initialActiveCR, initialEditBank } from './helper'

export const OnboardingContext = createContext<OnboardingContextType>({
  activeCR: initialActiveCR,
  editBank: initialEditBank,
  invCurrentStepNo: 0,
  isOtpOpen: false,
  lastConfiremStep: 0,
  leaveCurrentStepNo: 0,
  mainStep: 0,
  message: {},
  mudadCurrentStep: {},
  onboardingSteps: {},
  org: {
    require_pincode: false,
    id: null,
    metadata: {
      is_custom_labor_law_configurations: false,
      onboarding_steps: {
        current_step: 0,
      },
    },
  },
  showActivateAccount: false,
  orgCurrentStepNo: 0,
  payrollCurrentStepNo: 0,
  setActiveCR: () => null,
  setEditBank: () => null,
  setInvCurrentStep: () => null,
  setLastConfirmStep: () => null,
  setLeaveCurrentStep: () => null,
  setMainStep: () => null,
  setMessage: () => null,
  setMudadCurrentStep: () => null,
  setOnboardingSteps: () => null,
  setOrg: () => null,
  setOrgCurrentStep: () => null,
  setPayrollCurrentStep: () => null,
  setShowSidebar: () => null,
  setSidebar: () => null,
  setShowActivateAccount: () => null,
  setVerification(): IdType {
    return { id: null }
  },
  showSidebar: false,
  sidebar: null,
  verification: { id: null },
  handleOTPOpenClose: () => null,
})

export const OnboardingProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [showActivateAccount, setShowActivateAccount] = useState(false)
  const [mainStep, setMainStep] = useState<number>(0)
  const [orgCurrentStepNo, setOrgCurrentStep] = useState<number>(0)
  const [leaveCurrentStepNo, setLeaveCurrentStep] = useState<number>(0)
  const [payrollCurrentStepNo, setPayrollCurrentStep] = useState<number>(0)
  const [invCurrentStepNo, setInvCurrentStep] = useState<number>(0)
  const [lastConfiremStep, setLastConfirmStep] = useState<number>(0)
  const [sidebar, setSidebar] = useState<SidebarType>(null)
  const [isOtpOpen, setOtpOpen] = useState(false)
  const [showSidebar, setShowSidebar] = useState<boolean>(true)
  const [mudadCurrentStep, setMudadCurrentStep] = useState({})
  const [verification, setVerification] = useState<IdType>({ id: null })
  const [message, setMessage] = useState({})
  const [onboardingSteps, setOnboardingSteps] = useState<OnboardingStepsTypes>({})
  const [activeCR, setActiveCR] = useState<ActiveCR>({
    bank_accounts: [],
    id: null,
    mol_registration: {
      gosi_number: null,
    },
    name_en: '',
    registration_id: null,
  })
  const [editBank, setEditBank] = useState<EditBank | CommercialRegistration>(initialEditBank)
  const [org, setOrg] = useState<OrgType>({
    id: null,
    require_pincode: false,
    metadata: {
      is_custom_labor_law_configurations: false,
      onboarding_steps: {
        current_step: 0,
      },
    },
  })

  return (
    <OnboardingContext.Provider
      value={{
        showActivateAccount,
        setShowActivateAccount,
        verification,
        setVerification,
        isOtpOpen,
        message,
        setMessage,
        lastConfiremStep,
        setLastConfirmStep,
        mudadCurrentStep,
        setMudadCurrentStep,
        activeCR,
        setActiveCR,
        editBank,
        setEditBank,
        mainStep,
        setMainStep,
        sidebar,
        setSidebar,
        onboardingSteps,
        setOnboardingSteps,
        orgCurrentStepNo,
        leaveCurrentStepNo,
        payrollCurrentStepNo,
        invCurrentStepNo,
        setOrgCurrentStep,
        setLeaveCurrentStep,
        setPayrollCurrentStep,
        setInvCurrentStep,
        showSidebar,
        setShowSidebar,
        org,
        setOrg,
        handleOTPOpenClose: (v: boolean) => setOtpOpen(v ?? !isOtpOpen),
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
