import { useEffect } from 'react'
import { benefitTypesLoad } from 'redux/organizationOnborading/VacationSalary/actionCreators'
import { Widget } from 'components/global/atoms'
import { useDispatch } from 'utils/hooks'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  useUpdateOnboardingPayrunConfigurations,
  useGetOnboardingPayrunConfigurations,
} from 'apis/Finance/payrun_configurations/usePayrunConfigurations'
import { PayrunConfigsTypes } from 'apis/Finance/payrun_configurations/usePayrunConfigurations.types'
import SwitchAttendanceReflection from './SwitchAttendanceReflection'

const CalcualtionOfVacationSalary = (): JSX.Element => {
  const dispatch = useDispatch()
  const { data: onboardingConfigs, isFetching } = useGetOnboardingPayrunConfigurations({
    query: {
      select: (d) => d.data.data,
    },
  })
  const { mutateAsync: payrunConfigUpdate } = useUpdateOnboardingPayrunConfigurations()

  const handleOnRadioChange = (value: number, key: string): void => {
    const payload = [
      {
        name: key as PayrunConfigsTypes,
        value,
      },
    ]

    if (value !== 3) {
      payrunConfigUpdate({ configurations: payload }).then(() => dispatch(organizationInfo()))
    }
  }

  useEffect(() => {
    dispatch(benefitTypesLoad())
  }, [])
  return (
    <div>
      <Widget fetching={isFetching}>
        <SwitchAttendanceReflection
          title="vacation_salary"
          configName="vacation_salary_calculation"
          active
          configurationValue={onboardingConfigs?.vacation_salary_calculation?.value as number}
          handleOnRadioChange={handleOnRadioChange}
          benefit_type_ids={onboardingConfigs?.vacation_salary_calculation?.benefit_type_ids}
        />
      </Widget>
    </div>
  )
}

export default CalcualtionOfVacationSalary
