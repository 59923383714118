import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex, Button } from '@jisr-hr/ds'
import { useForm, useFormState } from 'react-final-form'
import { isValidTime } from 'components/global/form/formNormalize'
import { TimePicker } from 'components/global/ds'
import { addDays, parse, subDays } from 'date-fns'
import { isLogNextDay } from 'containers/authorised/attendance/helper'

import Styles from '../ProposedCorrectionTime.module.css'

const ManuallyTime = ({
  type,
  detail,
  handleClose,
  manualTime,
  setTime,
  status,
  punchType,
  isInNotRecorded,
  handleExit,
  error,
}) => {
  const { values } = useFormState()
  const { change } = useForm()
  const punchTime = values?.[type]?.punch_time || detail?.punchTime

  const isNext = isLogNextDay(
    parse(`${manualTime.date} ${manualTime.time}`, 'yyyy-MM-dd hh:mm a', new Date()),
    new Date(values.correction_date),
  )

  const handleSubmit = () => {
    const newPunch = {
      punch_time: manualTime.time,
      date: manualTime.date,
      reporting_method: 'manual',
      reporting_method_i18n: I18n.t('manual'),
    }

    change(type, newPunch)
    change(punchType, manualTime.time)
    change(`${punchType}_date`, manualTime.date)
    handleClose()
  }

  const handleIntVal = () => {
    let defaultTime = ''
    if (punchTime) defaultTime = punchTime
    else if (type === 'in_punch') defaultTime = detail?.shift_start_time
    else if (type === 'out_punch') defaultTime = detail?.shift_end_time
    if (defaultTime) {
      setTime({ time: defaultTime })
    }
  }

  React.useEffect(() => {
    handleIntVal()
  }, [detail])

  React.useEffect(() => {
    return () => {
      setTime({ time: '' })
    }
  }, [])

  return (
    <Flex
      container
      className={classnames(Styles.manuallyContainer, error && Styles.error)}
    >
      <TimePicker
        nextDay={isNext && type === 'out_punch' && I18n.t('next_day')}
        value={manualTime.time}
        onChange={(v) => setTime({ time: v })}
        disabled={isInNotRecorded}
        withDatePicker
        datePickerProps={{
          ...(values.correction_date && {
            minDate: subDays(new Date(values.correction_date), 1),
            maxDate: addDays(new Date(values.correction_date), 1),
          }),
          initialDate: manualTime.date,
        }}
        onChangeDate={(date) => {
          setTime({ date })
        }}
      />

      <Flex className={Styles.actions}>
        <Button
          size="medium"
          iconOnly
          leadingIcon="check"
          onClick={handleSubmit}
          disabled={!isValidTime(manualTime.time) || status || isInNotRecorded}
          testId={`add-manual-${type}`}
        />
        <Button
          size="medium"
          variant="outlined"
          color="neutral"
          iconOnly
          leadingIcon="x-close"
          disabled={!values?.[type]}
          onClick={() => {
            setTime({ time: '' })
            handleExit()
          }}
        />
      </Flex>
    </Flex>
  )
}

ManuallyTime.propTypes = {
  isInNotRecorded: PropTypes.bool,
  punchType: PropTypes.string,
  type: PropTypes.string,
  manualTime: PropTypes.shape(),
  status: PropTypes.string,
  detail: PropTypes.shape(),
  handleClose: PropTypes.func,
  setTime: PropTypes.func,
  handleExit: PropTypes.func,
  error: PropTypes.bool,
}

export default React.memo(ManuallyTime)
