const atsRecruitment = {
  application_tracking_system: 'تتبع المتقدمين',
  applicant_tracking: 'تتبع المتقدمين',
  job_openings: 'الوظيفة',
  job_openings_title: 'منشورات الوظائف',
  active_jobs: 'وظيفة مفتوحة',
  jobs: 'الوظائف',
  job: 'الوظيفة',
  job_openings_subTitle: 'إدارة جميع الوظائف لديك في مكان واحد.',
  new_job: 'وظيفة جديدة',
  total_candidate: 'إجمالي المرشحين',
  created: 'تم الإنشاء',
  applied: 'تقديم',
  hired: 'مقبول',
  search_jobs_by_title_or_id: 'البحث بالمسمى الوظيفي أو برقم الوظيفة',
  add_job: 'وظيفة جديدة',
  job_details: 'تفاصيل الوظيفة',
  workflow: 'مراحل التوظيف',
  job_title_english: 'مسمى الوظيفة (انجليزي)',
  job_title_arabic: 'مسمى الوظيفة (عربي)',
  job_description: 'وصف الوظيفة (اختياري)',
  hiring_stages: 'مراحل التوظيف',
  add_stage: 'إضافة مرحلة',
  job_type: 'نوع الوظيفة',
  attachment_optional: 'مرفق (اختياري)',
  click_to_upload: 'انقر للتحميل',
  supported_file_formats_png_pdf_word: 'الملفات المدعومة: PNG, JPG, JPEG, PDF, Word',
  drag_and_drop: 'او اسحب وأفلت الملف هنا',
  file_format: 'صيغة الملف المدعومة:PDF, DOC, PNG (أقصى حجم للتحميل 5 MB)',
  file_format_2: 'صيغة الملف المدعومة PDF, DOC, PNG, XLS (أقصى حجم للتحميل 2 MB)',
  file_format_10: 'صيغة الملف المدعومة PDF, DOC, PNG, JPG, JPEG (أقصى حجم للتحميل 10 MB)',
  file_formated: 'صيغة الملف المدعومة:PDF, DOC , PNG, JPG, JPEG (أقصى حجم للتحميل 5 MB)',
  select_job_type: 'اختر نوع الوظيفة',
  select_location: 'اختر الموقع',
  select_business_unit: 'اختر وحدة العمل',
  select_department: 'اختر القسم',
  edit_job: 'تعديل الوظيفة',
  business_unit_ats: 'وحدة العمل',
  Statuses: 'الحالة',
  step: 'الخطوة',
  of: 'من',
  no_job_message: 'لم تضاف أي وظيفة حتى الآن',
  track_job_message: 'تابع المرشحين من التقديم إلى التوظيف، واحتفظ بجميع بياناتهم في مكان واحد.',
  delete_job: 'حذف الوظيفة',
  this_will_result_in_losing_all_job_and_candidates_data:
    'سيؤدي هذا إلى فقدان جميع معلومات الوظيفة و المرشحين.',
  job_search_result: 'لا توجد نتيجة مطابقة',
  try_changing_filters: 'يرجى التحقق من التهجئة أو محاولة استخدام كلمة مفتاحية مختلفة.',
  create_job: 'إضافة الوظيفة',
  discard_job: 'إلغاء الوظيفة',
  job_draft_modal_caption: 'سيؤدي هذا إلى فقدان جميع معلومات الوظيفة.',
  save_as_draft: 'حفظ مسودة',
  back_to_Jobs: 'العودة إلى الوظائف',
  add_candidate: 'إضافة مرشح',
  move: 'نقل',
  move_to_another_job: 'النقل إلى وظيفة أخرى',
  candidates: 'مرشحين',
  candidate: 'مرشح',
  select_the_stage_to_move_the_candidates: 'اختر المرحلة لنقل المرشحين.',
  reassign: 'نقل',
  select_the_new_job_to_reassign_the_candidates: 'اختر الوظيفة لنقل المرشحين.',
  select_job: 'اختر الوظيفة',
  selecter: 'مختار',
  selecters: 'مختارين',
  reassign_to_another_job: 'إعادة التعيين في وظيفة أخرى',
  the_candidates: 'المرشحين',
  search_by_candidates_name: 'البحث باسم المرشح',
  search_by_candidate_name: ' البحث باستخدام اسم المرشح',
  candidates_subTitle: 'أدر جميع المرشحين الخاصين بك في مكان واحد.',
  import_candidates: 'رفع المرشحين',
  upload_candidates: 'رفع المرشحين',
  create_candidate: 'مرشح جديد',
  create_candidate_btn: 'إضافة مرشح',
  no_candidates_created_yet: 'لا يوجد أي مرشح لحد الآن.',
  input_candidates_contact_information: 'ستظهر هنا جميع معلومات المرشحين الجدد بعد إضافتهم.',
  candidate_name: 'اسم المرشح',
  assigned_job: 'الوظيفة',
  stage: 'المرحلة',
  date_added: 'تاريخ الإضافة',
  delete_candidate_permanently: 'سيؤدي هذا إلى حذف بيانات المرشح نهائياً.',
  delete_candidate_from_job: 'سيؤدي هذا إلى حذف بيانات المرشح من الوظيفة.',
  delete_candidate: 'حذف المرشح',
  edit_candidate: 'تعديل المرشح',
  designation: 'المسمى الوظيفي',
  more_information: 'معلومات إضافية',
  upload_resume: 'تحميل السيرة الذاتية',
  link: 'الروابط',
  add_more: 'أضف المزيد',
  candidate_comments: 'التعليقات',
  step_1_download_template_file: 'الخطوة 1: تحميل القالب',
  download_file: 'تحميل القالب',
  use_this_template_to_avoid_difficulties_importing_ur_candidates_information:
    'استخدم هذا القالب لتجنب الصعوبات في تحميل معلومات المرشحين الخاصة بك.',
  step_2_prepare_the_file_for_upload: 'الخطوة 2: تعبئة بيانات المرشحين وتجهيز القالب للرفع',
  fill_the_required_information_as_per_the_template_:
    'املأ المعلومات المطلوبة حسب النموذج، انقر على تعلم المزيد لقراءة المقالة الكاملة حول كيفية التحميل الجماعي خطوة بخطوة.',
  learn_more_bulk_upload: 'تعلم المزيد',
  step_3_upload_the_file: 'الخطوة 3: حمل الملف',
  step_4_upload_all_candidates_resumes_optional:
    'الخطوة 4: تحميل السيرة الذاتية لجميع المرشحين (اختياري)',
  resumes_that_do_not_match:
    'السيرة الذاتية التي لا تتطابق مع ملف Excel الذي تم تحميله سوف تتطلب إضافة يدوية بعد التحميل.',
  save_the_file_as_csv_xlsx_xls_format:
    'احفظ الملف بصيغة (csv)، (xlsx) أو (xls) ثم قم بتحميله هنا.',
  supported_file_formats: 'الملفات المدعومة: .xls، .xlsx، .csv',
  back_to_candidates: 'العودة إلى المرشحين',
  reassign_to_a_new_job: 'إعادة التعيين في وظيفة أخرى',
  remove_from_job: 'حذف من الوظيفة',
  profile: 'الملف الشخصي',
  candidate_out_of: ' من ',
  success_upload_candidate:
    'تم تحميل معلومات {{success_count}} من أصل {{all_candidate}} مرشحًا بنجاح.',
  re_upload_file: 'إعادة تحميل الملف',
  bulk_upload_candidates: 'تحميل متعدد للمرشحين',
  assigned: 'مسند لوظيفة',
  not_assigned: 'غير مسند لوظيفة',
  job_opening_filter_title: 'الوظيفة',
  unassigned: 'غير مضاف',
  no_candidates_have_been_found: 'لا يوجد أي مرشح لحد الآن.',
  all_comments: 'جميع التعليقات',
  add_comments: 'أضف تعليق',
  pick_a_date: 'اختيار التاريخ',
  resume_heading: 'السيرة الذاتية',
  see_more: 'عرض المزيد...',
  create_new: 'إنشاء مرشح جديد',
  add_existing: 'إضافة مرشح حالي',
  download_error_report: 'تنزيل تقرير الأخطاء',
  invalid_file_data: 'بيانات الملف غير صالحة',
  select_candidate: 'اختر مرشح',
  activity: 'النشاط',
  select_candidate_add_job: 'اختر مرشح لإضافته إلى هذه الوظيفة.',
  see_less: 'عرض اقل...',
  pdf_load_err: 'هذا العنوان غير صالح',
  same_stage_name_is_not_allowed: 'غير مسموح باستخدام اسم المرحلة اكثر من مرة واحدة',
  enter_stage_name: 'ادخل اسم المرحلة',
  enter_valid_phone_number: 'أدخل رقم هاتف صالح',
  delete_comment_message: 'سيؤدي هذا إلى حذف التعليق نهائياً.',
  delete_comment: 'حذف التعليق',
  cannot_exceed_more_than_1000_word: 'لا يمكن أن يتجاوز أكثر من 1000 كلمة',
  applicants_tracking_system_questionnaire: 'استبيان نظام تتبع المتقدمين (ATS)',
  onboarding_subheading_text:
    'أكمل هذا الاستبيان القصير لمساعدتنا في تصميم نظامنا لتلبية احتياجات عملك بشكل أفضل.',
  are_you_currently_using_any_applications_tracking_system:
    '1. هل تستخدم حاليًا أي نظام تتبع للمتقدمين (ATS)؟',
  which_industry_does_your_company_work_in: '2. ما هي الصناعة التي تعمل بها شركتك؟',
  select_industry: 'حدد الصناعة',
  select_sector: 'حدد القطاع',
  add_another_industry: 'أضف صناعة أخرى',
  who_will_be_using_applicants_tracking_system_in_your_company:
    '3. من سيستخدم نظام تتبع المتقدمين (ATS) في شركتك؟',
  CEO: 'المدير التنفيذي',
  HR_manager: 'مدير الموارد البشرية',
  recruitment_manager: 'مدير التوظيف',
  recruitment_team_member: 'عضو فريق التوظيف',
  hiring_line_manager: 'المدير المكلف بالتعيين',
  business_owner: 'صاحب العمل',
  ats_others: 'آخرون',
  how_many_new_hires_are_you_planning_for_this_year:
    '4. كم عدد الموظفين الجدد الذين تخططون لتعيينهم هذا العام؟',
  select_range: 'حدد نطاق العدد',
  what_are_the_biggest_challenges_you_experience_in_recruitment_and_hiring:
    '5. ما هي أكبر التحديات التي تواجهها في عملية التوظيف؟',
  proceed_to_ats: 'المتابعة إلى النظام',
  application_for: 'استمارة التقديم لوظيفة',
  candidate_application: 'استمارة التقديم',
  response: 'الإجابة:',
  the_candidate: 'المرشح',
  thank_you: 'شكرا على التقديم.',
  suitable_candidate: 'إذا كنت مرشحًا مناسبًا، فسيتواصل معك فريق التوظيف قريبًا.',
  application_form: 'استمارة التقديم',
  job_overview: 'نظرة عامة عن الوظيفة:',
  responsibilities: 'المسؤوليات:',
  requirements: 'المتطلبات:',
  language_options: 'اعدادات اللغة',
  language_option_description: 'اختر اللغة التي تريد أن يعرض بها النموذج للمرشحين.',
  candidate_profile_questions: 'معلومات المرشح',
  add_more_links_placeholder: 'على سبيل المثال الموقع، روابط وسائل التواصل الاجتماعي',
  add_more_links: 'اضف المزيد من الروابط',
  screening_questions: 'أسئلة الفحص',
  show_field: 'إظهار الحقل',
  hide_field: 'إخفاء الحقل',
  add_question: 'إضافة سؤال',
  short_text: 'اجابة قصيرة',
  paragraph: 'اجابة طويلة',
  multiple_choice: 'خيارات متعددة',
  file_upload: 'تحميل ملف',
  checkboxes: 'خانات اختيار',
  add_options: 'إضافة خيار',
  no_questions_added_yet: 'لم تتم إضافة أي أسئلة حتى الآن',
  empty_questions_description:
    'يمكنك توفير الوقت عن طريق إضافة أسئلة الفحص لضمان تلبية المتقدمين لمتطلباتك.',
  add_questions: 'إضافة أسئلة',
  screen_text_field_placeholder: 'أكتب سؤالك هنا...',
  job_application_overview: 'نظرة عامة',
  job_application: 'التقديم',
  apply_now: 'قدم الآن',
  powered_by: 'مدعوم من',
  job_application_submit: 'التقديم',
  ats_full_name_english: 'الاسم الكامل (إنجليزي)',
  ats_full_name_arabic: 'الاسم الكامل (عربي)',
  save_as_job: 'حفظ الوظيفة',
  no_longer_accepting_applicants: 'لم يعد يقبل المتقدمين',
  share_job: 'مشاركة الوظيفة',
  copy_to_clipboard: 'نسخ إلى الحافظة',
  via_form: 'عبر استمارة',
  screen_answer_field_placeholder: 'أكتب جوابك هنا...',
  about_the_company_optional: 'عن الشركة (اختياري)',
  about_the_company: 'عن الشركة',
  displayed_to_candidates_who_apply_via_form: 'يتم عرضها للمرشحين الذين يتقدمون عبر النموذج',
  use_for_all_jobs: 'استخدام لجميع الوظائف الجديدة',
  card_days: 'يوم',
  card_week: 'اسبوع',
  card_weeks: 'اسابيع',
  card_years: 'yr',
  no_duplicates: 'الأسماء المتكررة للروابط غير مسموح بها',
  all_fields_must_be_filled: 'يجب ملء الحقول',
  please_fill_all_the_fields: 'الرجاء تعبئة جميع الحقول',
  update_job: 'تحديث الوظيفة',
  skip_and_update: 'تخطي وتحديث',
  this_job_was_deleted: 'تم حذف هذه الوظيفة',
  skip_and_create: 'تخطي وإضافة',
  no_response_to_show: 'لا يوجد رد لعرضه',
  include_screening_questions: 'لم تتضمن استمارة التقديم أسئلة فحص.',
  no_resume_attached: 'لا يوجد سيرة ذاتية مرفقة',
  profile_upload_resume: 'تحميل السيرة الذاتية',
  company_logo: 'شعار الشركة',
  change_status: 'تغيير الحالة',
  bulk_upload_resumes: 'رفع السير الذاتية',
  resumes_found_with_no_match: 'تم العثور على {{unmatched_resumes_count}} سيرة ذاتية بدون تطابق',
  candidate_resumes_for_candidates_matched_successfully:
    'تمت مطابقة السيرة الذاتية لـ {{countOfSuccess}} من أصل {{countOfUpload}} مرشحًا بنجاح.',
  follow_these_simple_steps_to_bulk_upload_candidate_data:
    'اتبع هذه الخطوات البسيطة لتحميل بيانات المرشحين بشكل مجمع.',
  file_upload_limit: 'حد تحميل الملف',
  you_can_only_upload_a_maximum_of_200_candidate_resumes_at_once:
    'يمكنك فقط تحميل 200 سيرة ذاتية للمرشحين كحد أقصى في وقت واحد.',
  file_upload_complete: 'اكتمل تحميل الملف!',
  upload_without_resumes: 'تحميل دون السير الذاتية',
  enter_the_value: 'ادخل القيمة',
  Duplicated_field: 'القيم المتكررة للحقول غير مسموح بها',
  add_employee_title: 'هل ترغب بإضافة هذا الموظف لشركتك؟',
  add_as_employee: 'أضف كموظف',
  fill_employee_info: 'ستحتاج إلى ملء بعض المعلومات الإلزامية لإضافته إلى مؤسستك.',
  additional_employee_fees: 'رسوم الموظفين الإضافية',
  additional_employee_fees_message:
    'ستؤدي إضافة موظفين جدد إلى فرض رسوم إضافية حتى انتهاء الاشتراك.',
  congratulations_found_Your_stars: 'تهانينا، لقد وجدت نجومك!',
  add_hired_candidates: `
  يمكنك جعلهم موظفين رسميين عن طريق إضافتهم إلى مؤسستك. قم بإدارة وإضافة جميع المرشحين المعينين لديك بسهولة على صفحة "مرشحين تم توظيفهم".`,
  go_to_hired_candidates: 'الذهاب إلى مرشحين تم توظيفهم',
  employees_cannot_be_moved_through_stages: 'لا يمكن نقل الموظفين عبر المراحل',
  reinstate_as_candidate: 'إعادة تفعيل كمرشح',

  recruiter_placeholder: 'اختر مسؤول التوظيف',
  recruiter_label: 'مسؤول التوظيف (اختياري)',
  recruiter_tooltip: 'لا يمكنهم حذف الوظيفة أو إعادة تعيين المرشحين.',
  hiring_manager_label: 'مدير التوظيف (اختياري)',
  hiring_manager_tooltip:
    'لا يمكنهم تعديل الوظائف أو حذفها، أو تعديل تفاصيل المرشح، تعيين المرشحين أو إدارة قوائم الوظائف.',
  hiring_manager_placeholder: 'اختر مدير التوظيف',
  for_this_job: '{{ others }} لهذه الوظيفة',
  assigned_to: 'تم تعيين',
  recruiter: 'مسؤول التوظيف',
  hiring_manager: 'مدير التوظيف',

  generate_candidates_report: 'إنشاء تقرير المرشحين',
  generate_candidates_report_details:
    'يقدم هذا التقرير نظرة شاملة وتفصيلية حول جميع بيانات المرشحين في نظام تتبع المتقدمين ',
  generate_jobs_report: 'إنشاء تقرير الوظائف',
  generate_jobs_report_details:
    'يقدم هذا التقرير نظرة شاملة وتفصيلية حول جميع بيانات الوظائف في نظام تتبع المتقدمين',
  jobs_breakdown: 'تحليل الوظائف',
  ats_reports: 'تقارير نظام تتبع المتقدمين',
  job_reports: 'تقارير الوظائف',
  candidate_reports: 'تقارير المرشحين',
  ats_statistics: 'إحصائيات نظام تتبع المتقدمين',
  ats_all_filters: 'الكل',
  total_candidates: 'إجمالي المرشحين',
  total_jobs: 'إجمالي عدد الوظائف',
  jobs_in_the_last_30_days: 'وظائف جديدة في آخر 30 يومًا',
  hired_candidates: 'المرشحين الموظفين',
  closed_with_no_hires: 'المغلقة بدون توظيف',
  open_with_no_hires: 'الشاغرة بدون توظيف',
  active_jobs_reports: 'وظائف نشطة',
  job_status: 'الحالة',
  stage_name: 'اسم المرحلة',
  use_mention: `قم باستخدم @ قبل الاسم لذكر شخص ما وسنقوم بإعلامهم.`,
  receive_job_notifications: 'استقبل إشعارات الوظائف',
  receive_job_notifications_detail:
    'تمكين استقبال الإشعارات بتحديثات الوظائف الجديدة وأنشطة المتقدمين.',
  watcher_label: 'اختر المستخدمين الآخرين الذين تود إشعارهم',
  watcher_placeholder: 'اختر المستخدمين',
  users_receiving_job_notifications: 'المستخدمون الذين يتلقون إشعارات الوظائف',
  access_denied_comment_section:
    'رفض الدخول: لا يمكن عرض هذا التعليق بدون صلاحيات الوصول إلى الوظيفة.',
  comment_edited_on: 'آخر تحديث في',

  yes_no: 'نعم/لا',
  auto_rejection_answer: 'اجابة رفض تلقائي',
  set_this_response_to_auto_reject: 'ضبط هذه الاجابة للرفض التلقائي',
  unable_to_set_all_responses_as_auto_reject: 'لا يمكن تحديد جميع الإجابات كرفض تلقائي',
  candidate_was_auto_rejected: 'تم رفض المرشح تلقائياً',
  sorting_old_to_new: 'من الأقدم إلى الأحدث',
}

export default atsRecruitment
