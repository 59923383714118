import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type ReducerType = {
  id: string | number
  name_i18n: string
}

type TEmployeeInvitationTime = {
  invitation_duration_i18n: string
  enabled_invitation_widget: boolean
}

type StateType = {
  fetching: boolean
  fileFetching: boolean
  currency_types: ReducerType[]
  bank_report_types: ReducerType[]
  bank_country_types: ReducerType[]
  device_types: ReducerType[]
  relationship: ReducerType[]
  identification_info_attachment_categories: ReducerType[]
  employee: TEmployeeInvitationTime
}

export const initialState: StateType = {
  fetching: false,
  fileFetching: false,
  currency_types: [],
  bank_report_types: [],
  bank_country_types: [],
  device_types: [],
  relationship: [],
  identification_info_attachment_categories: [],
  employee: {
    invitation_duration_i18n: '',
    enabled_invitation_widget: false,
  },
}

export default function reducer(state = initialState, action: Action): StateType {
  switch (action.type) {
    case actions.CONSTANTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DOWNLOAD_FILE_PENDING:
      return {
        ...state,
        fileFetching: true,
      }

    case actions.CONSTANTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        identification_info_attachment_categories:
          action.payload.data.data.identification_info_attachment_categories?.map(
            (item: { name_i18n: string }) => ({
              ...item,
              name: item.name_i18n,
            }),
          ) ?? [],
        fetching: false,
      }

    case actions.DOWNLOAD_FILE_FULFILLED:
      Toastr.success('File generated and downloaded successfully')
      return {
        ...state,
        fileFetching: false,
      }

    case actions.CONSTANTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.DOWNLOAD_FILE_REJECTED:
      Toastr.error('Something went wrong on server!')
      return {
        ...state,
        fileFetching: false,
      }

    default:
      return state
  }
}
