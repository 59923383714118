import i18n from 'translations/i18n'

import FiltersDateRange from './FiltersDateRange'

const DateRangePeriod = () => {
  return (
    <FiltersDateRange
      title={i18n.t('date')}
      filterKey="date_period"
    />
  )
}

export default DateRangePeriod
