import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type BusinessTripReport = {
  title: string
  per_diem_title: string
  employee_info: {
    name: string
    value: string | number | null
  }[]
  request_info: {
    name: string
    value: string | number | null
  }[]
  per_diem_structure: {
    name: string
    value: number
  }[]
  total_per_diem: {
    name: string
    value: number
  }
  currency: string
  calculation_basis_hint: string
}

type InitialStateType = {
  fetching: boolean
  business_trip_report: BusinessTripReport | null
}

export const initialState: InitialStateType = {
  fetching: false,
  business_trip_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.BUSINESS_TRIP_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.BUSINESS_TRIP_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        business_trip_report: action.payload.data.data.business_trip_report,
        fetching: false,
      }
    }

    case actions.BUSINESS_TRIP_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
