export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD = 'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_PENDING =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_PENDING'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_FULFILLED =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_FULFILLED'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_REJECTED =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_LOAD_REJECTED'

export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE = 'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_PENDING =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_PENDING'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_FULFILLED =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_FULFILLED'
export const MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_REJECTED =
  'MUDAD_SUBSCRIPTIONS_NOTIFICATIONS_UPDATE_REJECTED'

export const NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD = 'NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_PENDING =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_PENDING'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_FULFILLED =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_FULFILLED'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_REJECTED =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_LOAD_REJECTED'

export const NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE = 'NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_PENDING =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_PENDING'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_FULFILLED =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_FULFILLED'
export const NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_REJECTED =
  'NEW_INVOICE_ISSUED_NOTIFICATIONS_UPDATE_REJECTED'

export const DUNNING_NOTIFICATIONS_LOAD = 'DUNNING_NOTIFICATIONS_LOAD'
export const DUNNING_NOTIFICATIONS_LOAD_PENDING = 'DUNNING_NOTIFICATIONS_LOAD_PENDING'
export const DUNNING_NOTIFICATIONS_LOAD_FULFILLED = 'DUNNING_NOTIFICATIONS_LOAD_FULFILLED'
export const DUNNING_NOTIFICATIONS_LOAD_REJECTED = 'DUNNING_NOTIFICATIONS_LOAD_REJECTED'

export const DUNNING_NOTIFICATIONS_UPDATE = 'DUNNING_NOTIFICATIONS_UPDATE'
export const DUNNING_NOTIFICATIONS_UPDATE_PENDING = 'DUNNING_NOTIFICATIONS_UPDATE_PENDING'
export const DUNNING_NOTIFICATIONS_UPDATE_FULFILLED = 'DUNNING_NOTIFICATIONS_UPDATE_FULFILLED'
export const DUNNING_NOTIFICATIONS_UPDATE_REJECTED = 'DUNNING_NOTIFICATIONS_UPDATE_REJECTED'
