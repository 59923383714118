import { Modal, Button, Flex, Typography, ButtonProps, Divider } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { FC } from 'react'

type Props = {
  isOpen: boolean
  title: string
  fetching?: boolean
  hideFooter?: boolean
  showDividerHeader?: boolean
  description: string | JSX.Element
  titleConfirm?: string
  colorConfirm?: ButtonProps['color']
  titleClose?: string
  onConfirm?: () => void
  onClose: () => void
}

const ConfirmationModal: FC<Props> = ({
  description,
  isOpen,
  onClose,
  onConfirm,
  title,
  fetching,
  titleClose = 'keep',
  titleConfirm,
  colorConfirm = 'danger',
  hideFooter = false,
  showDividerHeader = false,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className="mx-[-24px]">
        <Typography
          text={i18n.t(title)}
          style={{ margin: 24, marginBottom: 16 }}
          variant="heading"
        />
        {showDividerHeader && <Divider />}
        {typeof description === 'string' ? (
          <Typography
            text={i18n.t(description)}
            style={{ margin: 24 }}
            variant="subheading"
          />
        ) : (
          description
        )}
        {!hideFooter && (
          <Flex
            style={{ margin: 24, gap: 12 }}
            justifyEnd
          >
            <Button
              color={colorConfirm}
              variant="ghost"
              disabled={fetching}
              onClick={onConfirm}
              label={i18n.t(titleConfirm || '')}
            />

            <Button
              onClick={onClose}
              disabled={fetching}
              label={i18n.t(titleClose)}
            />
          </Flex>
        )}
      </div>
    </Modal>
  )
}

export default ConfirmationModal
