import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function createBusinessTripRequests(employee_id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/business_trip_requests`
  const method = 'POST'
  return {
    type: 'CREATE_BUSINESS_TRIP_REQUEST',
    payload: handleAPI(url, {}, method, { business_trip_request: data }),
  }
}

export function updateBusinessTripRequests(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/business_trip_requests/${id}`
  const method = 'PUT'
  return {
    type: 'UPDATE_BUSINESS_TRIP_REQUEST',
    payload: handleAPI(url, {}, method, { business_trip_request: data }),
  }
}
