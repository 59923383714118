import React from 'react'
import { format } from 'utils/date'
import { Flex, Badge, Typography } from '@jisr-hr/ds'
import { ReactComponent as CalendarIcon } from '@jisr-hr/design-system/dist/assets/icons/Emails/Calendar/Schedule.svg'
import { ReactComponent as ResignReasonIcon } from '@jisr-hr/design-system/dist/assets/icons/Interface/Essential/LoginLogoutCrossDelete.svg'
import I18n from 'translations/i18n'
import {
  Info,
  Reason,
  Workflow,
  Tabs,
  Comments,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'
import Styles from './style.module.css'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const ResignationRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const [activeTab, setActiveTab] = React.useState('request_details')

  return (
    <Flex
      className={Styles.resignationRequest}
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              employee: request.employee,
              created_by: request.created_by,
              created_at: request.created_at,
              status: request.status,
              status_i18n: request.status_i18n,
              requested_on: request.created_at,
              type_i18n: request.request_name_i18n,
              last_working_day: request.date,
              resignation_reason: request.resignation_reason,
              request: request?.request,
              request_name: request?.request_name,
              cancellation_request_info: request?.cancellation_request_info,
              is_cancellation_request: request?.is_cancellation_request,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
              items: [
                {
                  label: I18n.t('last_working_day'),
                  icon: <CalendarIcon />,
                  value: request.date ? (
                    <Typography
                      text={format(new Date(request.date), 'EEEE, d MMM')}
                      variant="interface/strong/m"
                    />
                  ) : null,
                },
                {
                  label: I18n.t('resignation_reason'),
                  icon: <ResignReasonIcon />,
                  value: (
                    <Badge
                      variant="tinted"
                      color="gray"
                      label={request?.resignation_reason ?? ''}
                    />
                  ),
                },
              ],
            }}
          />

          {(request.reason || request.attachments?.length > 0) && (
            <Reason
              details={{
                ...request,
                reason: request.reason,
                attachments: request.attachments,
              }}
            />
          )}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request.approvals}
          />
        </>
      )}
      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request.request_type,
            }}
            can_add_comment={request.can_add_comment}
          />
          <Workflow approvals={request.approvals} />
        </>
      )}
    </Flex>
  )
}

export default ResignationRequest
