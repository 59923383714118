export const INVITATIONS_STATISTICS_LOAD = 'INVITATIONS_STATISTICS_LOAD'
export const INVITATIONS_STATISTICS_LOAD_PENDING = 'INVITATIONS_STATISTICS_LOAD_PENDING'
export const INVITATIONS_STATISTICS_LOAD_REJECTED = 'INVITATIONS_STATISTICS_LOAD_REJECTED'
export const INVITATIONS_STATISTICS_LOAD_FULFILLED = 'INVITATIONS_STATISTICS_LOAD_FULFILLED'

export const EMPLOYEE_INVITATIONS_RESET = 'EMPLOYEE_INVITATIONS_RESET'
export const EMPLOYEE_INVITATIONS_LOAD = 'EMPLOYEE_INVITATIONS_LOAD'
export const EMPLOYEE_INVITATIONS_LOAD_PENDING = 'EMPLOYEE_INVITATIONS_LOAD_PENDING'
export const EMPLOYEE_INVITATIONS_LOAD_REJECTED = 'EMPLOYEE_INVITATIONS_LOAD_REJECTED'
export const EMPLOYEE_INVITATIONS_LOAD_FULFILLED = 'EMPLOYEE_INVITATIONS_LOAD_FULFILLED'
