import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import I18n from 'translations/i18n'
import {
  Info,
  Reason,
  AttendanceRecord,
  // RecordChanges,
  ProposedCorrection,
  Workflow,
  Comments,
  Tabs,
} from 'components/global/templates/RequestDrawer/components'
import { getLogTimeAndDate, isLogNextDay } from 'containers/authorised/attendance/helper'
import { parse } from 'date-fns'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const logIntVal = {
  reporting_method: 'manual',
  reporting_method_i18n: I18n.t('manual_entry'),
}

const CorrectionRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const inTime = request?.in_time_log?.punch_type
    ? request?.in_time_log
    : {
        ...logIntVal,
        punch_time: getLogTimeAndDate(request?.in_punch).time,
        punch_type: 'in_punch',
        punch_type_i18n: I18n.t('in')?.toUpperCase(),
        is_late_or_early_out: request?.employee_shift?.delay > 0,
      }

  const outTime = request?.out_time_log?.punch_type
    ? request?.out_time_log
    : {
        ...logIntVal,
        punch_time: getLogTimeAndDate(request?.out_punch).time,
        punch_type: 'out_punch',
        punch_type_i18n: I18n.t('out')?.toUpperCase(),
        is_late_or_early_out: request?.employee_shift?.shortage > 0,
        is_next_day: request?.out_punch
          ? isLogNextDay(
              parse(request?.out_punch, 'yyyy-MM-dd hh:mm a', new Date()),
              new Date(request.date),
            )
          : false,
      }
  const attendance_logs = [request.in_punch && inTime, request.out_punch && outTime].filter(Boolean)

  const [activeTab, setActiveTab] = React.useState('request_details')

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />

      {/* request details tab */}
      {activeTab === 'request_details' && (
        <React.Fragment>
          <Info
            details={{
              ...request,
              attendance_day: request.date,
            }}
            showColumns={['attendance_day']}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}

          {request.employee_shift && (
            <AttendanceRecord
              record={request.employee_shift}
              employee_id={request.employee?.id}
              date={request.date}
              dateFormat="EEEE, dd MMM yyyy"
            />
          )}

          {attendance_logs && <ProposedCorrection attendance_logs={attendance_logs} />}

          {/* {(request.record_changes && request.status === 'pending') &&
            <RecordChanges record_changes={request.record_changes || {}} />
          } */}

          {request.approvals?.length > 0 && (
            <Workflow
              lessDetail
              onClick={() => setActiveTab('workflow_and_comments')}
              approvals={request.approvals}
            />
          )}
        </React.Fragment>
      )}

      {/* comments & workflow tab */}
      {activeTab === 'workflow_and_comments' && (
        <React.Fragment>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          {request.approvals?.length > 0 && <Workflow approvals={request.approvals} />}
        </React.Fragment>
      )}
    </Flex>
  )
}

export default CorrectionRequest
