import PropTypes from 'prop-types'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { ReactComponent as CheckIcon } from '@jisr-hr/design-system/dist/assets/icons/Icons12px/Check.svg'
import styles from './styles.module.css'

type TDoneStep = {
  title: any
  description: any
}

export default function DoneStep({ title, description }: TDoneStep): JSX.Element {
  return (
    <Flex
      flexCol
      itemsCenter
      justifyCenter
      className={styles.doneContainer}
    >
      <Flex
        itemsCenter
        justifyCenter
        className={styles.iconContainer}
      >
        <CheckIcon />
      </Flex>

      <Spacer height="16px" />

      <Typography
        text={title}
        variant="heading"
      />

      <Spacer height="8px" />

      <Typography
        variant="title-1"
        text={description}
      />
    </Flex>
  )
}

DoneStep.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
