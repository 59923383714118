import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Button, Flex } from '@jisr-hr/ds'
import { TimePicker } from 'components/global/ds'
import { increaseDecreaseTime, timeToNumbers } from 'components/global/form/formNormalize'

import Styles from './IncreaseDecreaseField.module.css'

const IncreaseDecreaseField = ({
  name,
  maxTime,
  minTime,
  disabled,
  timeField,
  validate,
  label,
  testId,
  touched,
  ...props
}) => (
  <Field
    name={name}
    validate={validate}
  >
    {({ input, meta }) => {
      const { totalMinutes } = timeToNumbers(input.value)
      const { totalMinutes: maxMinutes } = timeToNumbers(maxTime)
      const { totalMinutes: minMinutes } = timeToNumbers(minTime || 0)

      return (
        <Flex className={Styles.container}>
          <TimePicker
            {...props}
            {...input}
            timeOnly
            hideIcon
            label={label}
            maxTime={maxTime}
            minTime={minTime}
            disabled={disabled}
            value={input.value}
            data-testid={testId}
            status={meta.error && (meta.touched || touched) && 'error'}
            statusMessage={meta.error}
            withResetTime
          />
          <Flex
            className={Styles.buttons}
            style={{ paddingTop: label && 24 }}
          >
            <Button
              size="medium"
              iconOnly
              leadingIcon="minus"
              variant="outlined"
              color="neutral"
              disabled={totalMinutes <= minMinutes || disabled}
              onClick={() => {
                const time = increaseDecreaseTime(input.value, '-', 1)
                input.onChange(time)
                input.onBlur()
              }}
            />
            <Button
              size="medium"
              iconOnly
              leadingIcon="plus"
              variant="outlined"
              color="neutral"
              disabled={totalMinutes >= maxMinutes || disabled}
              onClick={() => {
                const time = increaseDecreaseTime(input.value, '+', 1)
                input.onChange(time)
                input.onBlur()
              }}
            />
          </Flex>
        </Flex>
      )
    }}
  </Field>
)

IncreaseDecreaseField.propTypes = {
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  timeField: PropTypes.bool,
  maxTime: PropTypes.number,
  minTime: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  touched: PropTypes.bool,
}

IncreaseDecreaseField.defaultProps = {
  validate: () => {},
  maxTime: '24:00',
  minTime: '00:00',
}

export default IncreaseDecreaseField
