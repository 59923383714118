/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

export type CandidateScreeningProps = {
  filterKey: string
  mixPanelEventName?: string
  filters: string[]
}
const CandidateScreening = (props: CandidateScreeningProps): JSX.Element | null => {
  const { candidate_screening } = useFilterAPI()
  const { handleFilter, filter } = useFilters()
  if (!candidate_screening.length) return null
  return (
    <FilterBox
      handleFilter={handleFilter}
      options={candidate_screening}
      selectedIds={filter[props.filterKey]}
      {...props}
      componentType="multiLevel"
      title={i18n.t('label.screening_questions')}
      childKey="options"
      labelKey="title"
      valueKey="id"
    />
  )
}
export default memo(CandidateScreening)
