import { FC, Fragment } from 'react'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { LazyAvatar } from 'components/global/atoms'
import { MudadSignatoriesType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'
import { Typography, Flex, Button } from '@jisr-hr/ds'

type SignatoryBoxType = {
  signatory: MudadSignatoriesType
  onReVerify: () => void
  onDeleteClick: () => void
}

const SignatoryBox: FC<SignatoryBoxType> = (props) => {
  const { signatory } = props
  const { employee, status_body, status } = signatory

  return (
    <Fragment>
      <Flex
        itemsCenter
        justifyBetween
      >
        <Flex
          itemsCenter
          style={{ gap: 16, flex: '0.5 1 0' }}
        >
          <LazyAvatar
            containerStyle={{
              height: 40,
              width: 40,
              margin: 0,
            }}
            src={employee?.avatar_thumb}
            alt={employee?.full_name_i18n}
          />
          <div>
            <Typography
              variant="interface/default/sm"
              style={{ lineHeight: '20px' }}
              text={employee?.full_name_i18n}
            />
            <Typography
              variant="caption"
              style={{ color: 'var(--color-base-colors-grey-600' }}
              text={`${i18n.t('emp_id')}: ${employee?.id}`}
            />
          </div>
        </Flex>
        <Flex
          itemsCenter
          justifyBetween={status === 'verified'}
          justifyEnd={status !== 'verified'}
          style={{ flex: '0.5 1 0' }}
        >
          {status === 'verified' && (
            <Flex
              itemsCenter
              style={{ gap: 8 }}
            >
              <DSIcon
                name="check-circle"
                size="md"
                color="#009950"
              />
              <Typography
                variant="interface/medium/sm"
                style={{ lineHeight: '20px', color: 'var(--color-base-colors-green-700)' }}
                text={status_body.status}
              />
            </Flex>
          )}
          <Flex
            itemsCenter
            style={{ gap: 16 }}
          >
            {(status === 'error' || status === 'verification_inprogress') && (
              <Button
                label={i18n.t('re_verify')}
                size="medium"
                color={status === 'error' ? 'danger' : 'neutral'}
                onClick={props.onReVerify}
              />
            )}

            {status !== 'verified' && (
              <DSIcon
                name="trash-01"
                size="ml"
                className={mainStyles.deleteIcon}
                onClick={props.onDeleteClick}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      {status !== 'verified' && (
        <Typography
          variant="caption"
          style={{
            marginTop: 8,
            color:
              status === 'error'
                ? 'var(--color-base-colors-red-700)'
                : 'var(--color-base-colors-yellow-700)',
          }}
          text={status_body.status}
        />
      )}
    </Fragment>
  )
}

export default SignatoryBox
