import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  exit_reentry_visa_request_workflows: [],
  exit_reentry_visa_request_workflow: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}
export default function exitreenteryRequestReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_PENDING:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_PENDING:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_PENDING:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_PENDING:
      return {
        ...state,
        errMsg: null,
        succMsg: null,
        loading: true,
        fetching: true,
      }
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        errMsg: null,
        fetching: false,
        loading: false,
      }

    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_FULFILLED:
      return {
        ...state,
        exit_reentry_visa_request_workflow: payload.data.data.exit_reentry_visa_request_workflow,
        errMsg: null,
        fetching: false,
        loading: false,
      }

    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_FULFILLED:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        error: null,
        loading: false,
        fetching: false,
      }
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_LOAD_REJECTED:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOW_LOAD_REJECTED:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_CREATE_REJECTED:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_UPDATE_REJECTED:
    case actions.EXIT_REENTERY_VISA_REQUEST_WORKFLOWS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        loading: false,
      }
    default:
      return state
  }
}
