import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LinkIcon } from 'assets/figma-icons/Link.svg'
import { Table } from '@jisr-hr/design-system'
import I18n from 'translations/i18n'
import { Typography, Flex } from '@jisr-hr/ds'
// import { employeesLoansLoad } from 'redux/actions/employeeLoanActions';
// import { useDispatch } from 'react-redux';
import { format } from 'utils/date'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import styles from './styles.module.css'
import Frame from '../../ds/Frame'
import InfoBadge from '../Info/components/InfoBadge'
import { formatMonth } from '../helper'

const stateType = {
  received: 'warning',
  not_received: 'warning',
  closed: 'success',
  Housing: 'gray',
}

const RecentLoans = ({ canViewLoan, details }) => {
  // const dispatch = useDispatch();

  const headers = [
    { label: I18n.t('loan_type_with_number', { number: details?.loans?.length || 0 }) },
    { label: I18n.t('pending_amount') },
    { label: I18n.t('installments_ends_in') },
    { label: I18n.t('status') },
  ]

  // React.useEffect(() => {
  //   dispatch(employeesLoansLoad(details?.empId));
  // }, []);

  if (!canViewLoan || isEmpty(details?.loans)) return null

  return (
    <Frame
      title={I18n.t('recent_loans')}
      titleSuffix={
        <Link
          to={`/employees/${details?.employee?.id}/financial`}
          target="_blank"
        >
          <LinkIcon style={{ cursor: 'pointer' }} />
        </Link>
      }
    >
      <Table
        headers={headers}
        className={styles.un_bordered_tbl}
        containerStyles={{ maxHeight: 290, margin: '-16px' }}
      >
        {details?.loans
          ?.filter((loan) => loan?.status !== 'closed')
          ?.map((item, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <InfoBadge
                  label={item?.loan_type?.name}
                  type="gray"
                />
              </Table.Cell>
              <Table.Cell>
                <Flex itemsCenter>
                  <Typography
                    text={`${item?.pending_amount}/`}
                    variant="body-new/regular"
                  />
                  <Typography
                    text={item?.amount}
                    style={{ color: 'var(--color-base-colors-grey-500)' }}
                    variant="body-new/regular"
                  />
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Typography
                  text={format(item.return_date, `${formatMonth}, yyyy`)}
                  variant="body-new/regular"
                />
              </Table.Cell>
              <Table.Cell>
                <InfoBadge
                  label={item.status_i18n}
                  type={stateType[item.status]}
                />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table>
    </Frame>
  )
}

RecentLoans.propTypes = {
  details: PropTypes.shape(),
  canViewLoan: PropTypes.bool,
}

export default RecentLoans
