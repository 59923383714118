import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const DEFAULT_STATE = {
  outsourcing_companies: [],
  outsourcing_company: {},
  employees: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function outSourcingCompanyReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let outSourcingCompanies = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case actions.OUTSOURCING_COMPANIES_LOAD_PENDING:
    case actions.OUTSOURCING_COMPANY_CREATE_PENDING:
    case actions.OUTSOURCING_COMPANY_UPDATE_PENDING:
    case actions.OUTSOURCING_COMPANY_DELETE_PENDING:
    case actions.OUTSOURCING_COMPANY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case actions.OUTSOURCING_COMPANIES_LOAD_REJECTED:
    case actions.OUTSOURCING_COMPANY_CREATE_REJECTED:
    case actions.OUTSOURCING_COMPANY_UPDATE_REJECTED:
    case actions.OUTSOURCING_COMPANY_DELETE_REJECTED:
    case actions.OUTSOURCING_COMPANY_EMPLOYEES_LOAD_REJECTED:
    case actions.OUTSOURCING_COMPANY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.OUTSOURCING_COMPANIES_LOAD_FULFILLED:
    case actions.OUTSOURCING_COMPANY_EMPLOYEES_LOAD_FULFILLED:
    case actions.OUTSOURCING_COMPANY_LOAD_FULFILLED:
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.OUTSOURCING_COMPANY_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      outSourcingCompanies = [...state.outsourcing_companies]
      newRecord = payload.data.data.outsourcing_company
      changes = {
        outsourcing_companies: [...outSourcingCompanies, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.OUTSOURCING_COMPANY_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      outSourcingCompanies = [...state.outsourcing_companies]
      updatedRecord = payload.data.data.outsourcing_company
      changes = {
        outsourcing_companies: updateOneListOfRecords(outSourcingCompanies, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.OUTSOURCING_COMPANY_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      outSourcingCompanies = [...state.outsourcing_companies]
      return {
        ...state,
        outsourcing_companies: removeOneListOfRecords(outSourcingCompanies, payload.id),
        error: null,
        fetching: false,
      }

    case actions.OUTSOURCING_COMPANY_EDIT:
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case actions.OUTSOURCING_COMPANY_EDIT_CANCEL:
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
