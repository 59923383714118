import { Flex, Button, Spacer } from '@jisr-hr/ds'
import { TextInputField } from 'components/final-form'
import i18n from 'translations/i18n'

type Props = {
  name: string
  disabled?: boolean
  showButton: boolean
  onClick: () => void
  // statusMessage?: string
}

const TextInputOther = (props: Props): JSX.Element => (
  <Flex
    itemsCenter
    style={{ gap: 6 }}
  >
    {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
    <TextInputField
      autoFocus
      name={props.name}
      label={i18n.t('what_is_the_other_company')}
    />
    {props.showButton && (
      <div>
        <Spacer height={16} />
        <Button
          size="medium"
          label={i18n.t('save')}
          onClick={props.onClick}
          variant="ghost"
          color="primary"
          disabled={props.disabled}
        />
      </div>
    )}
  </Flex>
)

export default TextInputOther
