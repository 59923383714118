import { Button, CompactButton, FeaturedIcon, Flex, Spacer, Typography } from '@jisr-hr/ds'
import { Modal } from '@jisr-hr/ds-beta'
import Styles from './Styles.module.css'

type MessageModalProps = {
  title: string
  message: string
  actionButtonLabel: string
  show: boolean
  updateShow(data: boolean): void
  actionButton: () => void
}

const HiringModal = ({
  title,
  show,
  message,
  actionButtonLabel,
  updateShow,
  actionButton,
}: MessageModalProps): JSX.Element => {
  return (
    <Modal
      open={show}
      onClose={(): void => updateShow(false)}
      variant="neutral"
      size="medium"
    >
      <Flex
        flexCol
        style={{ gap: '16px' }}
        className={Styles.modal}
      >
        <Flex justifyBetween>
          <FeaturedIcon
            colorType="info"
            iconName="star-01"
            size="lg"
          />
          <CompactButton
            icon="x"
            onClick={(): void => updateShow(false)}
            size="medium"
            variant="ghost"
          />
        </Flex>
        <Flex
          flexCol
          style={{ gap: '4px' }}
        >
          <Typography
            text={title}
            variant="interface/strong/ml"
          />
          <Typography
            text={message}
            variant="body-new/regular"
            textColor="color/fg/lighter"
          />
        </Flex>
        <Flex flexCol>
          <Spacer height={16} />
          <Flex justifyEnd>
            <Button
              label={actionButtonLabel}
              onClick={actionButton}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default HiringModal
