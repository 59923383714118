import { useEffect, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { isEmpty } from 'lodash'
import { requestTypesShow } from 'redux/reports/customRequests/requestTypes/actionCreators'
import {
  customRequestCreate,
  customRequestUpdate,
} from 'redux/requests/customRequests/actionCreators'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  RequestTypeField,
  RequestTypeVersionFieldsPros,
} from 'redux/reports/customRequests/requestTypes/types'
import JisrLoader from 'components/global/JisrLoader'
import CustomRequestFields from '../../components/CustomRequestFields'
import { useRequestDetail } from '../../hooks'

type CustomRequestProps = {
  customTypeId: number
}

type FormVals = RequestTypeVersionFieldsPros & {
  id?: number
  attachments_attributes: {
    document: string
    document_file_name: string
  }[]
  employee_id: number
  request_type_id: number
  updatedAttachments?: { id: number; data: string; filename: string }[]
}

type DateFieldValue = { start: string; end?: string }

type FieldTypes = {
  date: string[]
  multiple_choice: string[]
  number: string[]
  text: string[]
}

const CustomRequest = (props: CustomRequestProps): JSX.Element => {
  const { customTypeId } = props

  const {
    setOnSubmit,
    vars: { id },
    setInitialValues,
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const dispatch = useDispatch()
  const { change } = useForm()
  const { request_type, fetching } = useSelector((s) => s.customRequestsRequestTypes)

  const fields = editRequest
    ? request?.request_type_version?.fields
    : request_type?.active_version?.fields

  const typeFields = useMemo(() => fields, [fields]) as RequestTypeVersionFieldsPros

  let flatArray: Array<RequestTypeField> = []

  Object.entries(typeFields || {})?.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      flatArray = flatArray.concat(value.map((v) => ({ ...v, type: key })))
    } else {
      flatArray.push({ ...value, type: key })
    }
  })

  flatArray.sort((a, b) => a.position! - b.position!)

  const onSubmit = ({ text, number, date, multiple_choice, ...val }: FormVals): Promise<void> => {
    const textArr = text ?? []
    const numberArr = number ?? []
    const dateArr = date ?? []
    const multiChoiceArr = multiple_choice ?? []

    const textFieldVals =
      flatArray
        ?.filter((v) => v.type === 'text')
        ?.map((item, i) => ({
          name_en: item.name_en,
          name_ar: item.name_ar,
          type: 'text',
          value: textArr[i] ? [textArr[i]] : [],
        })) || []

    const numberFieldVals =
      flatArray
        ?.filter((v) => v.type === 'number')
        ?.map((item, i) => ({
          name_en: item.name_en,
          name_ar: item.name_ar,
          type: 'number',
          value: numberArr[i] ? [numberArr[i]] : [],
        })) || []

    const multiChoiceVals =
      flatArray
        ?.filter((v) => v.type === 'multiple_choice')
        ?.map((item, i) => {
          if (item.is_multiple) {
            const values = multiChoiceArr[i] as unknown as Array<{ value: string }>
            const value = values?.length ? values.map((v) => v.value) : []
            return {
              name_en: item.name_en,
              name_ar: item.name_ar,
              type: 'multiple_choice',
              value,
            }
          }
          return {
            name_en: item.name_en,
            name_ar: item.name_ar,
            type: 'multiple_choice',
            value: multiChoiceArr[i] ? [multiChoiceArr[i]] : [],
          }
        }) || []

    const dateFieldVals =
      flatArray
        ?.filter((v) => v.type === 'date')
        ?.map((item, i) => {
          const value = []
          value.push({
            name_en: item.start_date_name_en,
            name_ar: item.start_date_name_ar,
            type: 'date',
            value: dateArr[i]?.start ? [dateArr[i]?.start] : [],
          })
          if (item.is_range) {
            value.push({
              name_en: item.end_date_name_en,
              name_ar: item.end_date_name_ar,
              type: 'date',
              value: dateArr[i]?.end ? [dateArr[i]?.end] : [],
            })
          }

          return value
        })
        ?.flat() || []

    if (val.id) {
      return dispatch(
        customRequestUpdate(empId, val.id, {
          metadata: [...textFieldVals, ...numberFieldVals, ...multiChoiceVals, ...dateFieldVals],
          attachments: val.updatedAttachments ?? [],
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }

    return dispatch(
      customRequestCreate(empId, {
        request_type_version_id: request_type?.active_version?.id,
        metadata: [...textFieldVals, ...numberFieldVals, ...multiChoiceVals, ...dateFieldVals],
        ...val,
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  useEffect(() => {
    if (editRequest) {
      const result =
        request.metadata?.fields_value?.reduce(
          (acc: FieldTypes, item: { type: keyof FieldTypes; value: string }) => {
            if (!acc[item.type]) {
              acc[item.type] = []
            }
            acc[item.type].push(item.value)
            return acc
          },
          {},
        ) ?? {}

      const getDates = (): DateFieldValue[] => {
        const dates = flatArray?.filter((v) => v.type === 'date')
        const dateValues = result.date?.flat()

        const updatedDates: DateFieldValue[] = []
        let valsIndex = 0

        if (dateValues?.length > 0) {
          dates.forEach((item) => {
            const obj: Partial<DateFieldValue> = {}

            // Check if the item is not a range and valsIndex is within bounds
            if (!item.is_range && valsIndex < dateValues.length) {
              obj.start = dateValues[valsIndex]
              valsIndex += 1
            }
            // Check if the item is a range and ensure there are enough date values
            else if (item.is_range && valsIndex + 1 < dateValues.length) {
              obj.start = dateValues[valsIndex]
              valsIndex += 1
              obj.end = dateValues[valsIndex]
              valsIndex += 1
            }

            // Only push to updatedDates if obj has at least a start value
            if (obj.start !== undefined) {
              updatedDates.push(obj as DateFieldValue)
            }
          })
        }

        return updatedDates
      }

      setInitialValues({
        id: request?.id,
        text: result?.text?.flat(),
        number: result?.number?.flat(),
        date: getDates(),
        multiple_choice: result?.multiple_choice?.map((item: string[]) =>
          item?.map((i) => ({ value: i, label: i })),
        ),
        attachments: request?.attachments ?? [],
      })
    }
  }, [editRequest])

  useEffect(() => {
    if (customTypeId) {
      dispatch(requestTypesShow(customTypeId))
    }
    change('request_type_id', customTypeId)
    return () => {
      setOnSubmit(() => ({}))
      setInitialValues({})
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(typeFields)) {
      setOnSubmit(onSubmit)
    }
  }, [typeFields])

  return (
    <>
      {fetching || (loading && <JisrLoader absolute />)}
      <CustomRequestFields
        activeFields={flatArray}
        isUpdate={editRequest}
      />
    </>
  )
}

export default CustomRequest
