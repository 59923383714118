import { Typography, Flex } from '@jisr-hr/ds'
import React from 'react'
import { ReactComponent as InprogressIcon } from 'assets/figma-icons/scheduler/clock-watch.svg'
import WarnIcon from 'assets/images/warn-yellow.png'
import WarnRedIcon from 'assets/images/warn-red.png'
import i18n from 'translations/i18n'
import PropTypes from 'prop-types'
import { ReactComponent as CheckIcon } from 'assets/figma-icons/green-check-circle.svg'
import ModalStatus from './ModalStatus'

const PostingStatus = (props) => {
  const { status, postingData } = props
  const [open, handleOpen] = React.useState(false)

  const status_posting = {
    in_progress: {
      label: i18n.t('in_progress'),
      icon: <InprogressIcon style={{ width: '100%', height: '100%', marginBottom: 6 }} />,
    },
    success: {
      label: i18n.t('posting_successfully'),
      icon: <CheckIcon style={{ width: '100%', height: '100%', marginBottom: 6 }} />,
    },
    partially_posted: {
      label: i18n.t('partially_posted'),
      icon: (
        <div style={{ width: 14, height: 14 }}>
          <img
            src={WarnIcon}
            alt="Partially Posted"
            style={{ width: '100%', height: '100%', marginBottom: 6 }}
          />
        </div>
      ),
    },
    failure: {
      label: i18n.t('posting_failed'),
      icon: (
        <div>
          <img
            src={WarnRedIcon}
            alt="Posted failed"
            style={{ width: '100%', height: '100%', marginBottom: 6 }}
          />
        </div>
      ),
    },
  }
  return (
    <>
      <Flex itemsCenter>
        <div style={{ pointerEvents: status === 'in_progress' && 'none', width: 14, height: 14 }}>
          {status_posting[status]?.icon}
        </div>
        <Typography
          text={status_posting[status]?.label}
          style={{
            marginInline: 5,
            paddingInlineEnd: 16,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          variant="subtitle-2"
          onClick={() => handleOpen(true)}
        />
      </Flex>
      <ModalStatus
        postingData={postingData}
        handleClose={() => handleOpen(false)}
        isOpen={open}
      />
    </>
  )
}

PostingStatus.propTypes = {
  status: PropTypes.string,
  postingData: PropTypes.node,
}

export default PostingStatus
