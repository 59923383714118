import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { usePaymentListSelector } from 'redux/payrollOnboarding/paymentReview/selectors'
import { onBoardingPaymentDetailsLoad } from 'redux/payrollOnboarding/paymentReview/actionsCreators'
import i18n from 'translations/i18n'
import SummaryCard from '../components/SummaryCard'
import ViewMoreModal from './ViewMoreModal'
import { THandleEditStep } from '../SummaryReview'

const EmployeePaymentDetailsSummary = ({ handleEditStep }: THandleEditStep): JSX.Element => {
  const dispatch = useDispatch()
  const { statistics } = usePaymentListSelector()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(onBoardingPaymentDetailsLoad({ rpp: 9999999 }))
  }, [])
  return (
    <>
      <SummaryCard
        title="employee_payment_details"
        contentItems={[
          {
            label: 'number_of_employees_use_bank',
            value: i18n.t('employee_number', {
              count: statistics?.bank || 0,
            }),
          },
          {
            label: 'number_of_employees_use_cash',
            value: i18n.t('employee_number', {
              count: statistics?.cash || 0,
            }),
          },
          {
            label: 'number_of_employees_use_cheque',
            value: i18n.t('employee_number', {
              count: statistics?.cheque || 0,
            }),
          },
          {
            label: 'number_of_employees_use_international_transfer',
            value: i18n.t('employee_number', {
              count: statistics?.international_transfer || 0,
            }),
          },
        ]}
        onEditClick={(): void => handleEditStep(5)}
        onViewClick={(): void => setOpen(true)}
      />

      <ViewMoreModal
        isOpen={open}
        onClose={(): void => setOpen(false)}
      />
    </>
  )
}

export default EmployeePaymentDetailsSummary
