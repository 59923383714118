import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  departments: [],
  department: { manager: {} },
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function departmentReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let departments = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'DEPARTMENTS_LOAD_PENDING':
    case 'DEPARTMENT_LOAD_PENDING':
    case 'DEPARTMENT_CREATE_PENDING':
    case 'DEPARTMENT_UPDATE_PENDING':
    case 'DEPARTMENT_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'DEPARTMENTS_LOAD_REJECTED':
    case 'DEPARTMENT_LOAD_REJECTED':
    case 'DEPARTMENT_CREATE_REJECTED':
    case 'DEPARTMENT_UPDATE_REJECTED':
    case 'DEPARTMENT_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEPARTMENT_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEPARTMENTS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEPARTMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      departments = [...state.departments]
      newRecord = payload.data.data.department
      changes = {
        departments: [...departments, newRecord],
        editing: {
          ...state.editing,
          [`Parent-${newRecord.department_id}-Department-New`]: false,
        },
      }
      return { ...state, ...changes }

    case 'DEPARTMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      departments = [...state.departments]
      updatedRecord = payload.data.data.department
      changes = {
        departments: updateOneListOfRecords(departments, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'DEPARTMENT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      departments = [...state.departments]
      return {
        ...state,
        departments: updateOneListOfRecords(departments, payload.id),
        error: null,
      }

    case 'DEPARTMENT_EDIT':
      changes = {
        editing: {
          id: action.id,
          parent_department_id: action.parentDepartmentId,
        },
      }
      return { ...state, ...changes }
    case 'DEPARTMENT_EDIT_CANCEL':
      changes = {
        editing: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
