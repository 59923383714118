import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { omit } from 'lodash'
import { getMonthlyDeduction } from 'utils/string'
import { loanRequestCreate, loanRequestUpdate } from 'redux/actions/loanRequestActions'
import { useDispatch } from 'react-redux'
import { useRequest } from '../../RequestProvider'
import { LoanDetails, Reason, Profile, InstallmentCalculation } from '../../components/forms'
import { useRequestDetail } from '../../hooks'

const LoanRequest = () => {
  const dispatch = useDispatch()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const onSubmit = (val) => {
    const newVal = {
      ...val,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.id ? val?.deletedAttachments ?? [] : [])]
        : val?.deletedAttachments ?? null,
    }
    if (newVal.installment_type === 'no_of_months') {
      newVal.monthly_deduction = getMonthlyDeduction(newVal.amount, newVal.no_of_months)
      delete newVal.no_of_months
    }
    delete newVal.installment_type

    if (newVal.id) {
      return dispatch(
        loanRequestUpdate(empId, newVal.id, {
          ...newVal,
          attachments: newVal.attachments.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }

    return dispatch(
      loanRequestCreate(empId, {
        ...newVal,
        attachments: newVal.attachments?.map((it) => omit(it, 'id')),
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        loan_type_id: request?.loan_type?.id,
        installment_type: 'monthly_deduction',
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        installment_type: 'monthly_deduction',
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <LoanDetails />
      <InstallmentCalculation stopReloadTypes />
      <Reason />
    </Flex>
  )
}

export default LoanRequest
