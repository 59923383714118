import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  pagination: {},
  suggestions: [],
  search_suggestions: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.ONBOARDING_HR_INDUSTRY_TEMPLATES_LIST_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_HR_INDUSTRY_TEMPLATES_LIST_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        pagination: action.payload.data?.data?.pagination ?? {},
        suggestions: action.payload.data?.data?.industry_templates ?? [],
      }

    case actions.ONBOARDING_HR_INDUSTRY_TEMPLATES_LIST_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
