export function pushNewTransaction(transactionTypes, newData, key, type_id) {
  return transactionTypes.map((transactions) => {
    if (transactions.id === newData[type_id]) {
      return {
        ...transactions,
        [key]: [...transactions[key], newData],
      }
    }
    return transactions
  })
}

export function updateTransaction(transactionTypes, newData, key, type_id) {
  return transactionTypes.map((transactions) => {
    if (transactions.id === newData[type_id]) {
      return {
        ...transactions,
        [key]: transactions[key].map((transaction) =>
          transaction.id === newData.id ? newData : transaction,
        ),
      }
    }
    return transactions
  })
}

function handleClassNames(label) {
  switch (label) {
    case 'Employee Details':
      return 'employee-detail-second-header'
    case 'Earnings':
      return 'purple-light'
    case 'Additions':
      return 'green-light'
    case 'Deductions':
      return 'red-light'
    case 'Outsourcing provider costs':
      return 'yellow-light'
    default:
      return 'extra_columns'
  }
}

export function handlePayrunClasses(headers) {
  const temp = []
  headers.forEach((item) => {
    const count = item.col_span
    ;[...Array(count).keys()].forEach(() => temp.push(handleClassNames(item.label)))
  })
  return temp.slice(1)
}
