import { Toastr } from 'components/global/Toastr'
import { endOfMonth, format, startOfMonth, subMonths, subYears } from 'date-fns'
import i18n from 'translations/i18n'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'

const DATE_FILTERS = {
  end: endOfMonth(subMonths(new Date(), 1)),
  start: subYears(startOfMonth(subMonths(new Date(), 1)), 1),
}

type ObjProps = {
  [key: string]: number | string
}

export type FiltersValuesProps = {
  filter: ObjProps
  filters_details: {
    [key: string]: number | string | ObjProps
    details: {
      filterLabel: string
      label: string | number
      value: string
    }
  }
  from: string
  period_type: string
  to: string
  organization_id?: string | number | null
}

export type DataProps = {
  date?: string
  name?: Record<string, string | number> | string
  name_en?: string
  name_i18n?: string
  status_i18n?: string
  stage_name?: string
  round?: number
  value?:
    | {
        [key: string]: number
      }
    | string
    | number
  values?: {
    [key: string]: number
  }
}

export const DEFAULT_STATE = {
  fetching: false,
  data: [] as DataProps[],
  keys: {} as Record<string, string>,
  order: [] as string[],
  filters: [''] as string[],
  chart: {
    name: '',
    name_i18n: '',
    description: '' || null,
    description_i18n: '' || null,
    identifier: '',
    module: '',
    type: '',
  },
  filters_values: {
    filter: {},
    period_type: 'monthly',
    to: format(DATE_FILTERS.end, 'yyyy-MM-dd'),
    from: format(DATE_FILTERS.start, 'yyyy-MM-dd'),
    organization_id: '',
  },
  filters_details: {
    all_filters: 'all_filters',
    organization_id: '',
    details: {
      filterLabel: i18n.t('all_filters'),
      label: '',
      value: 'all_filters',
    },
  },
}

type State = typeof DEFAULT_STATE

export const handleFilterLabel = (filterLabel?: string): State => {
  const defaultState = {
    ...DEFAULT_STATE,
    filters_details: {
      ...DEFAULT_STATE.filters_details,
      details: {
        ...DEFAULT_STATE.filters_details.details,
        label: filterLabel ?? `${i18n.t('all_filters')}`,
      },
    },
  }
  return defaultState
}

type CreateAnalysisReducerActions = {
  filter?: string
  filterLabel?: string
  fulfilled: string
  pending: string
  rejected: string
}

function reorderData(data: DataProps[], order: string[] = []): DataProps[] {
  if (!order.length) {
    return data
  }

  return data.map((d) => ({
    ...d,
    values: order.reduce(
      (acc, key) => ({
        ...acc,
        [key]: d.values ? d.values[key] : 0,
      }),
      {},
    ),
  }))
}

export function createAnalysisReducer({
  pending,
  fulfilled,
  filter,
  rejected,
  filterLabel,
}: CreateAnalysisReducerActions) {
  return (state = handleFilterLabel(filterLabel), action: Action): State => {
    const { payload } = action
    switch (action.type) {
      case pending:
        return {
          ...state,
          fetching: true,
        }

      case fulfilled:
        const { order, data } = payload.data.data
        return {
          ...state,
          ...payload.data.data,
          data: reorderData(data, order),
          fetching: false,
        }

      case filter:
        return {
          ...state,
          filters_details: payload.filters_details ?? state.filters_details,
          filters_values: {
            filter: payload.filter,
            period_type: payload.period_type,
            to: payload.to,
            from: payload.from,
            organization_id: payload.organization_id,
          },
          fetching: false,
        }

      case rejected:
        Toastr.error(handleResponseErr(payload))
        return {
          ...state,
          fetching: false,
        }

      default:
        return state
    }
  }
}
