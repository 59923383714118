const OffCyclePayroll = {
  // A
  approved: 'Approved',
  add_addition: 'Add addition',
  add_deduction: 'Add deduction',
  advance_salary: 'Advance salary?',
  add_new_addition: 'New Addition ',
  add_to_salary: ' Add to salary',
  approve_reason: 'Reason of Approve',
  attendance_for_not_yet_pproved: 'Attendance for {{from}}-{{to}} is not yet approved',
  attendance_for_not_yet_reviewed: 'Attendance for {{from}}-{{to}} is not yet reviewed',
  attendance_status_pending_reminder_off:
    'Proceeding without attendance approval will dismiss overtime, delay, and all the Time-Related Transactions.',
  attendance_period_to_be_approved_off: 'Attendance period to be reviewed & approved ',
  addition_details: 'Addition details',
  approval_form_note_offcycle:
    'This selection is one time only but you can always change settings from the',
  approve_vacation_Settelement_request: 'Vacation Settlement Review Request',
  approve_final_Settelement_request: 'Final Settlement Review Request',
  attendance_requested: 'Attendance Requested',
  attendance_approved: 'Attendance approved',
  attendance_dismissed: 'Attendance dismissed',
  no_attendance_request: 'No attendance request',
  // B
  bank_iban: 'Bank IBAN',
  calculation_basic: 'Calculation Base',
  back_to_vacation_settlement: 'Back to Vacation Settlement',
  back_to_final_settlement: 'Back to Final Settlement',
  pending_on_approval: 'Sent for approval',
  // C
  created_at: 'Created at',
  comments: 'Comments',
  created_on_off: 'Created on',
  confirm_final_settlement: 'Confirm Final Settlement',
  confirm_vacation_settlement: 'Confirm Vacation Settlement',
  create_approval_cycle_offcycle: 'Create approval cycle',
  continu_without_approval_cycle_offcycle: 'Continue without approval cycle',
  cancel_settlement: 'Cancel Settlement',
  // D
  deductions_off: 'Deductions',
  deduction_types_off: 'Deduction type',
  deductionـdetails: 'Deduction details',
  daily_salary_rate: 'Daily salary rate',
  duration_of_service: 'Duration of service',
  deductions_details: 'Deduction details',
  dismiss_vacation_settlement: 'Dismiss vacation settlement?',
  dismiss_final_settlement: 'Dismiss Final settlement?',
  dismiss_warning:
    'dismissing the settlement will remove it permanently, are you sure you want to dismiss it?',
  delete_addition: 'Delete addition?',
  delete_deduction: 'Delete Deduction?',
  delete_warning_addition:
    'Deleting the addition would remove it permanently, are you sure you want to delete it?',
  delete_warning_deduction:
    'Deleting the deduction would remove it permanently, are you sure you want to delete it?',
  delete_remarks: 'Delete Remark',
  delete_warning_remarks:
    'Deleting the remark would remove it, are you sure you want to delete it?',
  days_number: '{{days}} Days',
  // E
  exit_and_re_entry_visa: 'Exit and Re-entry Visa',
  earnings: 'Earnings',
  employees_on_leave_from_the_same_team: 'Employees on leave from the same team',
  edit_remark: 'Edit Remark',
  edit_deduction: 'Edit deduction ',
  edit_addition_off: 'Edit deduction',
  employee_joining_date: 'Employee joining date',
  end_of_service_reason: 'End of service reason',
  endـofـserviceـreward: 'End of service reward  ',
  eligible_end_of_service: 'Eligible for end of service reward?',
  eligible_leave_settlement: 'Eligible Leave Settlement?',
  end_of_service_reward_amount: 'End of service reward amount',
  exit_re_entry_details: 'Exit re-entry Detials',
  excluded_service_period: 'Excluded service period',
  // F
  final_settlement: '{{name}}Final Settlement',
  final_Settlement: '{{name}} Final Settlement',
  flight_ticket: 'Flight ticket',
  flight_ticket_info: 'Flight Ticket Info',
  from_cut_off: ' From Cut-off for previous month',
  finalـsettlement_request: 'Final settlement request',
  finalـsettlement_request_tit: 'Final settlement request ',
  full_package_salary: 'Full package salary',
  final_settlement_review_request: 'Final Settlement Review Request',
  filter_payrun_status: 'Status',
  filter_attendance_status: 'Attendance status',
  // G
  // H
  // I
  issuing_assignee: 'Issuing assignee',
  issuing_assignee_ticket: 'Issuing assignee',
  issuing_status: 'Issuing status',
  in_order_include_attendance_transactions__description:
    'To include attendance transactions, attendance should be reviewed and approved for this employee',
  // J
  // K
  // L
  leave_starts_at: 'Leave starts on',
  leave_ends_at: 'Leave ends on',
  leavingـdate_off: 'Leaving date',
  last_working_day_off: 'Last working day',
  leave_settlement: 'Leave settlement  ',
  leave_balance_settlement: 'Leave balance settlement',
  loan_settlement: 'Loan Settlement',
  leave_to_date: 'Leave to date',
  // M
  months_offcycle: 'Months',
  mark_as_paid_off: 'Mark as paid',
  // N
  net_service_experience: 'Net Service Experience',
  no_addition_found: 'No Addition found for this employee',
  no_deductions_found: 'No Deductions found for this employee',
  no_remarks_found: 'No Remarks found for this employee',
  notification_date: 'Notification date ',
  number_of_leave_balance_days: 'Number of leave balance days',
  no_details_available: 'No details available ',
  note_confirmation_final_settlement:
    '<b>Note:</b> Upon confirmation of the final settlement and the completion of approvals within the series of approvals for the settlement, the changes made cannot be undone (Re-opening the settlement for edit), so please ensure that all financial information is correct before performing the final confirmation.',
  note_confirmation_Vacation_settlement:
    '<b>Note:</b> Upon confirmation of the vacation settlement and the completion of approvals within the series of approvals for the settlement, the changes made cannot be undone (Re-opening the settlement for edit), so please ensure that all financial information is correct before performing the final confirmation.',
  no_lets_review: "No, let's review",
  no_cancel: 'No, cancel',
  no_vacation_here: 'No vacation settlement here',
  net_service_period: 'Net service period',
  // O
  okay_offcycle: 'Okay',
  ok_approve: 'Ok, approve',
  off_cycle_vacation_Settlement: 'Vacation Settlements',
  off_cycle_final_Settlement: 'Final Settlements',
  offboard_employee: 'Off-board Employee',
  once_you_offboard_employee_the_FC_process_will_start_from_this_page:
    'Once you offboard any employee, the Final Settlement process will start from this page.',
  new_remark: 'New Remark',
  // P
  process_again: 'Reopen Settlement',
  payment_assignee: 'Payment assignee',
  payment_assignee_ticket: 'Payment assignee',
  process_timeline: 'Timeline ',
  paid_leave_balance_days: 'Paid leave balance days',
  // R
  request_attendance_review: 'Request attendance review',
  re_request_attendance_review: 'Re-Request Attendance Review',
  re_open_final_settlement_message:
    'The reopen will affect the current final settlement details, and you will lose all paying data',
  remarks_off: 'Remarks',
  return_date_off: 'Return date',
  remark: 'Note',
  reject_reason: 'Reason of rejection',
  rejected_resaon: 'Rejection reason',
  reject_vacation_Settelement_request: 'Vacation Settlement Review Request',
  reject_final_Settelement_request: 'Final Settlement Review Request',
  request_annual_leave: 'Request Annual Leave',

  // S
  send_to_approval_cycle: 'Send to approval cycle',
  send_for_approval: 'Send for Approval',
  summary_off: 'Summary',
  send_for_attendance_review: 'Send for attendance review',
  service_period: 'Service period',
  salary_details: 'Salary Details',
  salary_up_to_last_working_day: 'Salary up to last working day ({{work_days}} Days)',
  salary_settlement_off: 'Salary settlement',
  salary_settlement: 'Total Salary',
  skipping_loan_deduction_off:
    'Skipping the loan deduction would remove it from this vacation settlement and move it to the next payroll pay period',
  // T
  total_amount_off: 'Total amount',
  total_amount: 'Total amount',
  total_salary_off: 'Total Earnings',
  total_earnings: 'Total Earnings',
  total_service_experience: 'Total service experience',
  travelers: 'Travelers({{count}})',
  ticket_details: 'Ticket Details',
  to_complete_clearance_attedance_managerP_required_approve_attendance:
    'To complete the vacation settlement, attendance manager is required to approve the attendance.',
  total_pending_amount: 'Total pending amount',
  types: 'Types',
  total_service_period: 'Total service period',
  tip_unpaid_leave_days:
    'in case the employee has more than 20 unpaid leave days, these days are deducted from the total service experience',
  // U
  upload_attachment: 'Upload Attachment',
  unpaid_leave_days: 'Unpaid Leave Days',
  vacation_settlement_review_request: 'Vacation Settlement Review Request',
  unused_leave_settlement: 'Leave balance settlement',

  // V
  vacation_Final_Settlement: 'Vacation & Final Settlement',
  vacation_settelment: '{{name}} Vacation Settlement',
  vacation_Settelment: '{{name}} Vacation Settlement',
  vacation_days_salary: 'Vacation salary  ({{leave_days}} Days)',
  view_request: 'View the Request',
  view_details: 'View details',
  vacation_days: 'Vacation days',
  vacation_days_salary_rem: 'Vacation days salary',
  vacation_Settelement_Requst:
    'It seems the employee has requested to get his vacation salary for a closed payroll period. Please note that approving the request will not generate a vacation settlement for this employee.',

  // W
  working_days_salary: 'Working days salary ({{work_days}} Days)',
  // X
  // Y
  years_months_days: ' {{years}} Years, {{months}} Months, {{days}} Days',
  you_are_about_to_confirm_final_settlement:
    'You’re about to confirm the final settlement for {{name}}. Are you sure?',
  you_are_about_to_confirm_vacation_settlement:
    'You’re about to confirm the vacation settlement for {{name}}. Are you sure?',

  you_can_request_vacation_for_employees:
    'You can start requesting a vacation settlement to your employees',
  yes_dismiss_it: 'Yes, Dismiss it',
  yes_delete_it: 'Yes, Delete it',

  // Z
}
export default OffCyclePayroll
