import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import Frame from '../../ds/Frame'
import ExpenseClaimItem from './ExpenseClaimItem'

const ExpenseClaimItems = ({ ecrItems }) => (
  <Frame title={`${I18n.t('items')} (${ecrItems?.length ?? 0})`}>
    {ecrItems?.map((item, i) => (
      <ExpenseClaimItem
        item={item}
        key={i}
      />
    ))}
  </Frame>
)

ExpenseClaimItems.propTypes = {
  ecrItems: PropTypes.arrayOf(PropTypes.shape()),
}

export default ExpenseClaimItems
