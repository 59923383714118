import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'utils/date'
import { Typography, Flex, Spacer, Avatars } from '@jisr-hr/ds'
import { statusTypes } from 'utils/uiHelpers'

import WorkFlowBadge from '../WorkFlowBadge'

const EmployeeProfile = ({ name, avatar, jobTitle, code, date, status, status_i18n }) => (
  <Flex itemsStart>
    <Avatars
      imageSrc={avatar}
      imageAlt={name}
    />
    <Spacer width={8} />
    <Flex flexCol>
      <Typography
        text={name}
        textColor="color/fg/lighter"
        variant="interface/strong/m"
      />
      {(date || code || jobTitle) && (
        <Typography
          text={
            date ? (
              format(date, 'MMM d, yyyy, hh:mma')
            ) : (
              <>
                {jobTitle} {jobTitle && code && '-'} {code}
              </>
            )
          }
          textColor="color/fg/lighter"
          style={{
            color: date ? 'var(--color-base-colors-grey-600)' : 'var(--color-base-colors-grey-400)',
          }}
          variant="subheading"
        />
      )}
      {status_i18n && (
        <>
          <Spacer height={8} />
          <Flex flexWrap>
            <WorkFlowBadge
              label={status_i18n}
              type={statusTypes[status?.toLowerCase()] || 'gray'}
            />
          </Flex>
        </>
      )}
    </Flex>
  </Flex>
)

EmployeeProfile.propTypes = {
  date: PropTypes.instanceOf(Date),
  code: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  avatar: PropTypes.string,
  status: PropTypes.string,
  status_i18n: PropTypes.string,
}

export default EmployeeProfile
