import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type InsuranceClassesPorps = {
  filterKey: string
  filters: string[]
}

const InsuranceClasses = (props: InsuranceClassesPorps): JSX.Element => {
  const { lookups } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  return (
    <FilterBox
      handleFilter={handleFilter}
      options={lookups.insurance_policy_class}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('insurance_policy_class')}
    />
  )
}

export default InsuranceClasses
