const Scheduler = {
  // A
  add_shift: 'إضافة دوام',
  assign_shift: 'إسناد دوام',
  avoid_days_with_existing_shifts: 'تخطّي الأيام المجدولة',
  all_employees_count: 'كل الموظفين ({{count}})',
  attendance_exemption: 'إعفاء من الحضور',
  assign_shifts_to_employee: 'اسند الدوامات بالطريقة الموضحة في القالب قبل رفعه',
  assigning_new_shift_preset: 'تعريف الفترات مسبقًا',
  accepted_file_formats: 'الصيغة المدعومة: xlsx, xls, cvs',
  any_existing_shifts_for_the_selected_employees:
    'ستستبدل الجداول المرفوعة جميع الدوامات المسندة سابقًا للموظفين المحددين.',
  approved_overtime_will_not_be_effected: 'لن يتأثر العمل الإضافي المعتمد',
  approved_overtime_will_not_be_affected_by_shift_changes:
    'لن تتغير ساعات العمل الإضافي المعتمدة حتى لو تغير الدوام المسند للموظف في الملف المرفوع.',
  all_shifts_uploaded_successfully: 'نجح رفع كل الدوامات',
  // B
  // C
  conflict: 'تعارض',
  conflicts: 'التعارضات',
  clear_schedule: 'إزالة جدول الدوامات',
  conflicts_that_cant_published: 'التعارضات التي لا يمكن نشرها',
  conflicted_shift: 'الدوام المتضارب',
  continue_to_unpublish:
    'هل تريد متابعة إلغاء نشر <span> {{shift_count}} دوامات </ span> لـ <span> {{empNumberMessage}} </span> لـ <span> {{dateRange}} </span>؟',
  clear_shifts_count: 'ازاله {{count}} دوامات',
  continue_to_clear:
    'هل تريد الاستمرار في مسح <span> {{shift_count}} دوامات </ span> لـ <span> {{empNumberMessage}} </span> لـ <span> {{dateRange}} </span>؟',
  continue_to_remove:
    'هل تريد الاستمرار في إزالة <span> {{shift_count}} دوام </ span> لـ <span> {{empNumberMessage}} </span> لـ <span> {{dateRange}} </span>؟',
  conflict_with_current_schedule: 'تعارض مع الجدول الحالي',
  click_to_upload: 'اضغط للاختيار',
  confirm_and_import: 'التأكيد والرفع',
  conflict_found: 'تعارض مع الجدول الحالي',
  // D
  day_off: 'يوم راحة',
  day_off_associated: 'لا يمكن جدولة يوم الراحة مع فترات دوام',
  do_you_want_to_repeat_this_shift: 'هل تريد تكرار هذا الدوام؟',
  DAILY: 'يومي',
  default_assigned_location: 'الموقع المعرّف في ملف الموظف',
  dept: 'القسم',
  download_template: 'تحميل القالب',
  download_rejected_schedules: 'تنزيل الجداول المرفوضة',
  days_import: 'يوم',

  // E
  employee_rejected_schedules: '{{rejected_employees}} جدول مرفوض',
  employees_in_file: 'عدد الموظفين في الملف',
  empty_day_cells_will_be_imported_unscheduled: 'الأيام الفارغة في الملف ستكون أيام «غير مجدولة»',
  excuse_or_correction_requests_associated_with_existing:
    'ستنتهي صلاحية طلبات الإذن وتصحيح البصمة عند تغيير الدوام المسند',
  errors_resulted_in_file_rejection:
    'الأخطاء المسببة للرفض موضحة في ملف الجداول المرفوضة، يمكنك تصحيحها ورفعها مرة أخرى.',
  employees_have_conflictions: 'يتعارض هذا الاختيار مع الجداول الحالية لـ {{count}} موظف',
  // F
  fixed: 'دوام ثابت',
  failed_bulk: 'فشل التحميل',
  // G
  // H
  half_day_off: 'راحة لنصف يوم',
  half_day: 'نصف يوم',
  // I
  import_bulk_employees_schedulers: 'استيراد دوامات الموظفين',
  if_day_had_existing_shifts:
    'الأيام المسندة سابقًا ستكون أيام «غير مجدولة» إذا تركت خانة اليوم فارغة في الملف المرفوع.',
  if_existing_shift_had_pending_or_approved:
    'ستنتهي صلاحية الطلبات المرسلة والمعتمدة لتصحيح البصمة والاستئذان عند تغيير الدوام المرتبط بهذه الطلبات في الملف المرفوع.',
  in_which_locations: 'في اي من المواقع',

  // J
  // K
  // L
  learn_more_about_bulk: 'للمزيد عن إسناد الدوامات بالملف',

  // M
  // N
  notify_affected_employee_email: 'إخطار الموظفين المتأثرين',
  notify_employee_email: 'إخطار الموظف',
  // O
  overtime_for: 'العمل الإضافي لـ ',
  or_drag_and_drop_here: 'أو اسحب وافلت الملف هنا',
  oldest_created_shifts_first: 'الأقدم أولًا',
  // P
  publish: 'نشر',
  publish_shifts_count: 'نشر {{count}} دوامات',
  publish_shift: 'نشر الدوام',
  prepare: 'التجهيز',
  processing_file: 'جاري الرفع',
  partially_completed: 'تم التحديث جزئيا',
  processing_bulk: 'جاري المعالجة',

  // Q
  // R
  rotated: 'دوام متغيّر',
  recipients: 'المستلمون',
  repalce_existing_shifts: 'استبدال الأيام المجدولة بأيام راحة (سيتم حذف الفترات المجدولة)',
  repeat_days: 'تكرار الأيام',
  recently_created_shifts_first: 'الأحدث أولًا',
  // S
  shift_template: 'قالب الدوام',
  shifts_with_approved_attendace: 'مناوبات بالحضور المعتمد  (التعديل غير مسموح)',
  some_employees_have_conflicting_shifts: 'بعض الموظفين لديهم دوامات متضاربة',
  some_employees_have_existing_shifts: 'بعض الموظفين المختارين لديهم دوامات في اليوم المحدد',
  some_employees_have_approved: 'وافق بعض الموظفين على أيام الحضور',
  shift_conflict_with_other:
    '.لا يمكن نشر الدوامات التي تتعارض / تتداخل مع الدوامات الأخرى. الرجاء معالجة الصراع ثم حاول مرة أخرى',
  selected_filtered_employees_count: 'الموظفون المحددون / الذين تمت تصفيتهم ({{count}})',
  shift_presets_needs_to_be_defined:
    'يتطلب اختيار فترات الدوام في الملف أن تكون الفترة معرفة في فترات الدوام من إعدادات الحضور والانصراف،',
  save_shift_as_template: 'حفظ الدوام كفترة جديدة',
  search_combo_name: 'البحث باسم المجموعه',
  scheduled_hours_week: 'ساعات مجدولة لهذا الاسبوع',
  scheduled_hours_day: 'ساعات مجدولة لهذا اليوم',
  schedule_and_prepare_file_for_upload: 'جدولة الدوامات في الملف وتجهيز القالب للرفع',
  select_period: 'اختر الفترة',
  save_this_file_as: 'احفظ ملف القالب ثم ارفعه',
  schedule_period: 'فترة الجدول',
  successful: 'ناجح',
  save_as_draft: 'حفظ مسودة',
  server_error: 'خطأ في السيرفر',
  select_employees_and_shift: 'اختيار الموظفين',
  set_repetition: 'التكرار',
  should_they_work_on: 'اي نوع من الدوامات تود اسناده لهم',
  starting_and_repeating: 'متى يبدأ وتكراره',
  set_when_should_this_settings: 'اختر متى يتم العمل بهذه الاعدادت ولاي مدة',
  start_date_scheduler: 'تاريخ البدايه',
  sort: 'ترتيب',
  save_and_publish: 'حفظ ونشر',
  // T
  type_your_comment: 'أكتب تعليقك',
  total_scheduled_hours: 'إجمالي الساعات المقررة',
  total_unpublished_shifts: 'مجموع الدوامات غير المنشورة',
  total_unpublished_dayoff: 'إجمالي ايام الإجازة غير المنشورة',
  total_already_published_shifts: 'إجمالي الدوامات المنشورة بالفعل',
  total_published_dayoff: 'إجمالي ايام العطله المنشورة',
  total_shifts_published_unpublished: 'الدوامات (المنشورة & غير المنشورة)',
  total_dayoff_published_unpublished: 'ايام العطله (المنشورة & غير المنشورة)',
  this_will_affect_attendance_calculation: 'سيؤثر هذا على حساب الحضور لتلك الدوامات',
  this_can_not_be_undone: 'لايمكن التراجع عن هذه العملية',
  this_will_clear_any_shift:
    'سيُحذف فقط كل دوام يبدأ في المدة المعروضة. الدوامات التي تنتهي في المدة المعروضة لن تُحذف.',
  total_hours_week: 'مجموع ساعات العمل لهذا الأسبوع',
  total_hours_day: 'مجموع ساعات العمل لهذا اليوم',
  this_will_expire_attached:
    'إزالة الدوامات سيؤدي إلى انهاء صلاحية طلبات الإستئذان التي تم رفعها لهذه الدوامات',
  this_will_remove_added_overtime:
    'إزالة الدوامات سيؤدي إلى إزالة العمل الإضافي التي تم إضافتها من قبل المدير او بشكل تلقائي من حضور الموظف لهذه الدوامات',
  this_might_take_some_time: 'قد تطول مدة الرفع بحسب حجم الملف',
  // U
  unpublish: 'إلغاء النشر',
  unpublish_shifts_count: 'إلغاء نشر {{count}} دوامات',
  unpublishing_to_draft_mode:
    '.سيؤدي عدم نشر الدوامات إلى إعادتهم إلى وضع المسودة ، وهو غير مرئي للموظفين ، ولا يؤخذ في الاعتبار عند حساب الحضور',
  unpublish_shifts: 'إلغاء نشر الدوامات',
  unpublish_shift: 'إلغاء نشر الدوام',
  use_this_spreadsheet_template: 'استخدم القالب لتجنب الأخطاء والمشاكل عند رفع الجداول.',
  upload_schedule_file: 'رفع ملف الجداول',
  upload_schedule: 'رفع الجداول',
  uploading_schedule: 'جاري رفع الجداول',
  uploaded_schedule_will_replace_existing: 'الجداول المرفوعة ستحل محل أي دوام مسند سابقًا',
  upload_complete: 'اكتمل الرفع',
  upload_corrected_file: 'رفع الملف بعد التصحيح',
  uploaded_file_does_match_the_template:
    'تنسيق الملف المرفوع لا يطابق التنسيق المطلوب، رجاءًا تأكد من إن رؤؤس الأعمدة تطابق التنسيق والترتيب التالي: <br /> 1. الرقم الوظيفي <br /> 2. اسم الموظف بالانجليزي <br />3. اسم الموظف بالعربي <br /> 4. المسمى الوظيفي <br />5. القسم <br />6. الفرع <br />7. المنطقة <br />8. وحدة عمل',
  // V
  // W
  WEEKLY: 'أسبوعي',
  weekly: 'أسبوعي',
  wrong_template_format: 'ملف بتنسيق خاطئ',
  // X
  // Y
  yes_unpublish: 'نعم ، إلغاء النشر',
  yes_remove_shift: 'نعم ، قم بإزالة الدوام',
  yes_clear_shifts: 'نعم ، مسح الدوامات',
  yes_clear: 'نعم، امسح',
  you_do_not_have_permission_to_publish_shifts:
    'ليست لديك صلاحية إسناد الدوامات لهذا الموظف لأنه ليس ضمن «الفريق»',
  you_can_select_from_shifts_or_shift_templates: 'يمكنك الاختيار من الفترات المعرفة او القوالب',
  // Z
}

export default Scheduler
