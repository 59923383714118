import { FC } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isRtl } from 'utils/uiHelpers'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 6 * 60 * 1000, // ? 5 mins
      retry: 2,
    },
  },
})

const QueryProvider: FC = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools
        initialIsOpen={false}
        position={isRtl ? 'bottom-left' : 'bottom-right'}
      />
    </QueryClientProvider>
  )
}

export { queryClient, QueryProvider }
