import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { AssignShiftStatsParamsT } from '../schedulerSharedTypes'
import * as actions from './actions'

export function briefListOfEmployees<T extends Record<string, unknown>>(params: T): Action {
  const url = `/${API_VERSION_TWO}/attendance/employees/brief`

  return {
    type: actions.BRIEF_LIST_EMPLOYEES,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function assignTemplate<T extends Record<string, unknown>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/attendance/schedulers`

  return {
    type: actions.ASSIGN_TEMPLATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function conflictEmployees<T extends Record<string, unknown>>(params: T): Action {
  const url = `/${API_VERSION_TWO}/attendance/schedulers/conflicts`

  return {
    type: actions.CONFLICT_EMPLOYEES,
    payload: handleAPI(url, params, 'GET'),
  }
}

// Change to POST due to HTTPs limitation on params
export const getAssignShiftState = (data: AssignShiftStatsParamsT): Action => {
  const url = `/${API_VERSION_TWO}/attendance/schedulers/assign_stats`

  return {
    type: actions.ASSIGN_SHIFT_STATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function resetConflictEmployees(): { type: string } {
  return {
    type: actions.RESET_CONFLICT_EMPLOYEES,
  }
}

export function resetAssignShiftState(): { type: string } {
  return {
    type: actions.RESET_ASSIGN_SHIFT_STATE,
  }
}
