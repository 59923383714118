import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Employee } from 'redux/authUser/types'
import * as actions from './actions'

type ManagerType = { manager: { id: number; name_i18n: string } }

export type PerformanceEmployee = Employee & {
  location: ManagerType
  area: ManagerType
  parent_department: ManagerType
  business_unit: ManagerType
  line_manager: ManagerType
  department: ManagerType
}

type State = {
  fetching: boolean
  employeesAll: PerformanceEmployee[]
  message?: string
  success?: boolean
}

export const initialState: State = {
  fetching: false,
  employeesAll: [],
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.GET_PERFORMANCE_ALL_EMPLOYEES_CLEANUP:
      return {
        ...state,
        employeesAll: [],
        fetching: false,
      }

    case actions.GET_PERFORMANCE_ALL_EMPLOYEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_PERFORMANCE_ALL_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        employeesAll: action.payload.data.data.employees,
        message: action.payload.data.data.message,
        success: action.payload.data.data.success,
        fetching: false,
      }

    case actions.GET_PERFORMANCE_ALL_EMPLOYEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
