import { useState, useEffect, useContext } from 'react'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useHistory } from 'react-router-dom'
import { orgSteps } from './helper'
import { OnboardingContext } from '../context'
import VerticalStepper from '../components/VerticalStepper'
import SharedStep from '../components/SharedStep'
import { customArray, handleUrl, isPreviousStepsComplete } from '../helper'
import { SetupStepTypes, StepType } from '../types/onboardingTypes'
import { microNewOrgSteps, microOrgSteps } from '../microHelper'

const OrganizationSetup = ({ initStep }: { initStep: number }): JSX.Element => {
  const history = useHistory()

  const {
    require_pincode,
    metadata: { organization_info, new_onboarding_payroll },
  } = useSelector(({ organization }) => organization?.organization)

  const { setSidebar, setOrgCurrentStep, onboardingSteps, org } = useContext(OnboardingContext)
  const getKeyAndVariant = (): { key: StepType; variantSteps: SetupStepTypes[] } => {
    if (require_pincode && new_onboarding_payroll) {
      return { key: 'microNewOrgSteps', variantSteps: microNewOrgSteps }
    }
    if (require_pincode) {
      return { key: 'microOrgSteps', variantSteps: microOrgSteps }
    }
    return { key: 'orgSteps', variantSteps: orgSteps }
  }

  const { key } = getKeyAndVariant()
  const { variantSteps } = getKeyAndVariant()

  const customOrgSteps = customArray(organization_info, key)

  const steps: (false | SetupStepTypes)[] = [
    onboardingSteps?.organization_info !== 'Completed' && {
      stepNo: 0,
      title: I18n.t('organization_info_onboarding'),
      component: SharedStep,
      sidebar: null,
    },
    ...variantSteps,
  ]
  const [step, setStep] = useState(initStep)
  const { component: CurrentComponent, sidebar } = steps[step] || {}

  useEffect(() => {
    const onboardingStep = Number(localStorage.getItem(`onboarding_step_${org.id}`)) || 0
    if (initStep === onboardingStep) {
      setStep(initStep)
      setOrgCurrentStep(initStep)
    } else {
      setStep(onboardingStep)
      setOrgCurrentStep(onboardingStep)
    }
  }, [localStorage.getItem(`onboarding_step_${org.id}`)])

  useEffect(() => {
    setSidebar(sidebar)
  }, [sidebar])

  const goNextStep = (): void => {
    const toStep = step + 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`onboarding_step_${org.id}`, toStep.toString())
    setStep(toStep)
    setOrgCurrentStep(toStep)
  }

  const goPreviousStep = (): void => {
    const toStep = step - 1
    const path = handleUrl(toStep, key, require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`onboarding_step_${org.id}`, toStep.toString())
    setStep(toStep)
    setOrgCurrentStep(toStep)
  }

  const goToStep = (toStep: number): void => {
    const isComplete = isPreviousStepsComplete(toStep, customOrgSteps)
    if (isComplete) {
      const path = handleUrl(toStep, key, require_pincode)
      history.push(`?${path}`)
      localStorage.setItem(`onboarding_step_${org.id}`, toStep.toString())
      setStep(toStep)
      setOrgCurrentStep(toStep)
    }
  }

  return (
    <VerticalStepper
      steps={customOrgSteps}
      currentStep={step}
      setCurrentStep={goToStep}
    >
      {CurrentComponent && (
        <CurrentComponent
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={steps[step + 1]}
          previousStep={steps[step - 1]}
        />
      )}
    </VerticalStepper>
  )
}

export default OrganizationSetup
