import { showAnalysis } from 'containers/authorised/Analysis/helper/permission'
import { Statuses } from 'containers/authorised/NewOnboarding/helper'
import { config as reportsList } from 'containers/authorised/report/components/config'
import { getSettingsNavList } from 'containers/authorised/setting/SettingNavigation/SettingNavigation'
import { setLocale } from './actionCreators'
import {
  ActionType,
  Company,
  Employee,
  PerformancePermission,
  PermissionDetails,
  ScopeType,
} from './types'

type HandlePermissionsReturn = { module: string; value: number }[]

type ViewEditType = ActionType | ScopeType | ScopeType[] | boolean | undefined

// this is the base structure for Performance permissions,
// in upcoming features will be different permission for each of keys added in this function
// don't add two roles in one key
export const handlePerformancePermisstion = (
  permission_scopes: PermissionDetails,
  is_super_admin: boolean,
): PerformancePermission => {
  const Performance = {
    view_performance: permission_scopes?.view_employee_performance,
    manage_performance: permission_scopes?.manage_performance,
    manage_objectives: permission_scopes?.manage_objectives,
    update_objective_progress: permission_scopes?.update_objective_progress,
  }

  return {
    // View permisstion
    view_performance_permission: Performance.view_performance,

    // Manage permisstion
    manage_cycles_permission: Performance.manage_performance,
    manage_competencies_permission: Performance.manage_performance,
    manage_objectives_permission: Performance.manage_objectives,
    update_objective_progress: Performance.update_objective_progress,
    can_share_results: is_super_admin, // or is a reviewer for the selected employee
  }
}

export const handlePermissions = (
  {
    permission_scopes,
    permission_details,
    is_muqeem_access,
    organization,
    is_super_admin,
  }: Employee,
  { activated_modules = [], subscribed_features = [] }: Company,
): HandlePermissionsReturn => {
  const getValue = (canView: ViewEditType, canEdit?: ViewEditType): number => {
    if (canEdit) return 4
    if (canView) return 1
    return 0
  }
  return [
    {
      module: 'Leave',
      value: getValue(permission_scopes?.view_leaves),
    },
    {
      module: 'Attendance',
      value: organization.attendance_onboarding_completed
        ? getValue(permission_scopes?.view_attendance)
        : 0,
    },
    {
      module: 'Scheduler',
      value: organization.attendance_onboarding_completed
        ? getValue(permission_scopes?.view_shift_schedule)
        : 0,
    },
    {
      module: 'AttendanceDashboard',
      value: is_super_admin && !organization.attendance_onboarding_completed ? 4 : 0,
    },
    {
      module: 'Compensation',
      value: 4,
    },
    {
      module: 'Employee profile',
      value: 4,
    },
    {
      module: 'Onboarding Employees',
      value: getValue(
        organization?.onboarding_employee_enabled &&
          permission_scopes.add_new_employees?.some((it) => it === 'all'),
      ),
    },
    {
      module: 'ViolationList',
      value:
        !permission_scopes?.view_reported_violations ||
        permission_scopes?.view_reported_violations?.some((it) => it === 'myself') ||
        !organization.mol_enabled
          ? 0
          : 4,
    },
    {
      module: 'Assets',
      value: getValue(permission_scopes?.manage_assets?.some((it) => it === 'all')),
    },
    {
      module: 'Muqeem Services',
      value: getValue(is_muqeem_access),
    },
    {
      module: 'Hired Candidates',
      value: getValue(
        (organization?.ats_enabled &&
          permission_scopes?.add_new_employees?.some((it) => it === 'all')) ||
          (permission_scopes?.add_new_employees?.some((it) => it === 'all') &&
            permission_scopes?.add_employee_from_ats),
        (organization?.ats_enabled &&
          permission_scopes?.add_new_employees?.some((it) => it === 'all')) ||
          (permission_scopes?.add_new_employees?.some((it) => it === 'all') &&
            permission_scopes?.add_employee_from_ats),
      ),
    },
    {
      module: 'Payroll',
      value: getValue(
        !!permission_details.view_payroll_table,
        !!permission_details.view_payroll_table,
      ),
    },
    {
      module: 'VacationSettlement',
      value: getValue(
        permission_scopes?.view_vacation_settlement,
        permission_scopes?.view_vacation_settlement,
      ),
    },
    {
      module: 'FinalSettlement',
      value: getValue(
        permission_scopes?.view_final_settlement,
        permission_scopes?.view_final_settlement,
      ),
    },
    {
      module: 'PaymentProcessing',
      value: getValue(
        !!permission_details?.view_payment_processing,
        !!permission_details?.view_payment_processing,
      ),
    },
    {
      module: 'PerformanceObjectives',
      value: getValue(permission_scopes?.view_employee_performance),
    },
    {
      module: 'ATSRecruitment',
      value: is_super_admin
        ? 1
        : getValue(
            permission_scopes?.manage_applicant_tracking_system ||
              permission_scopes?.view_jobs_and_its_candidates,
          ),
    },
    {
      module: 'performanceCyclesCompetencies',
      value: getValue(
        permission_scopes?.view_employee_performance,
        permission_scopes?.manage_performance,
      ),
    },
    {
      module: 'PerformanceEvaluation',
      value: getValue(
        permission_scopes?.view_employee_performance,
        permission_scopes?.manage_performance,
      ),
    },
    {
      module: 'Reports',
      // @ts-expect-error temp untill convert reportsList to TS
      value: reportsList(permission_scopes, subscribed_features)?.length ? 4 : 0,
    },
    {
      module: 'Settings',
      // @ts-expect-error temp untill convert getSettingsNavList to TS
      value: getSettingsNavList({
        ...permission_scopes,
        edit_on_payroll_table: permission_details.edit_on_payroll_table,
        make_payments: permission_details.make_payments,
        payroll_settings: permission_details.payroll_settings,
        isJisrPayEnabled: organization?.jisrpay_enabled,
        isFlightBookingEnabled:
          organization?.benefits_management_enablement?.sub_modules_enablement
            ?.flight_ticket_bookings_enabled,
      })?.length
        ? 4
        : 0,
    },
    {
      module: 'Analysis',
      value: showAnalysis(permission_scopes),
    },
    {
      module: 'Automations',
      value: getValue(permission_scopes?.manage_automation_workflow),
    },
    {
      module: 'BenefitsManagement',
      value: organization?.benefits_management_enablement?.enabled
        ? getValue(
            permission_scopes?.manage_health_insurance ||
              permission_scopes?.manage_annual_flight_tickets ||
              permission_scopes?.view_flight_bookings,
          )
        : 0,
    },
    {
      module: 'Organization Chart',
      value: getValue(permission_scopes?.manage_subsidiaries),
    },
    {
      module: 'NewOnboarding',
      value:
        is_super_admin &&
        organization?.new_onboarding_enabled &&
        (organization?.status === Statuses.ONBOARDING_PHASE ||
          (organization?.status === Statuses.LIVE && (activated_modules?.length ?? 0) < 3))
          ? 4
          : 0,
    },
  ]
}

export const fixLocale = (employee: Employee): void => {
  const emp_config = employee?.employee_configurations?.find(
    (item) => item.name === 'language_preference',
  )
  if (emp_config?.name === 'language_preference') {
    if (
      ['ar', 'en'].includes(emp_config?.value) &&
      emp_config?.value !== localStorage.getItem('Locale')
    ) {
      setLocale(emp_config?.value)
      window.location.reload()
    }
  }
}
