import { useState } from 'react'
import { CommercialRegistrationWithWalletType } from 'redux/payroll/JisrPay/reducer'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'

type UseOnlinePaymentType = {
  isCopied: boolean
  handleCopy: (value: string) => Promise<void>
  mappingPaymentType: (payment: CommercialRegistrationWithWalletType) => PaymentConfigurationType
}

const useOnlinePayment = (): UseOnlinePaymentType => {
  const [isCopied, setIsCopied] = useState(false)

  // ? functions
  const handleCopy = async (value: string): Promise<void> => {
    await navigator?.clipboard?.writeText(value)
    setIsCopied(true)
    const timeout = setTimeout(() => {
      setIsCopied(false)
      clearTimeout(timeout)
    }, 5000)
  }

  const mappingPaymentType = (
    payment: CommercialRegistrationWithWalletType,
  ): PaymentConfigurationType => {
    return {
      id: payment.id,
      name_en: payment.name_ar,
      name_ar: payment.name_ar,
      name_i18n: payment.name_ar,
      name: payment.name_ar,
      active_payment_method: payment.active_payment_method,
      registration_number: payment.registration_number,
      registration_id: payment?.registration_id,
      owner_identification_number:
        payment?.commercial_registration_owners.find((owner) => owner?.otp_status === 'verified')
          ?.identity_id || null,
      mol_registration: payment.mol_registration,
      bank_accounts: payment.bank_accounts, // need from BE
      unified_mol_number: payment.entity_number,
    }
  }
  return {
    handleCopy,
    isCopied,
    mappingPaymentType,
  }
}

export default useOnlinePayment
