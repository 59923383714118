const Marketplace = {
  // A
  active_integrations: 'Active integrations',
  all_apps: 'All Apps',
  are_you_sure_disconnect: 'Are you sure you want to disconnect this integration?',
  app_not_in_plan: 'This app is not in your plan',
  app_not_in_plan_desc: 'Upgrade to the Professional Plan now to get this app and many more.',

  // B
  back_to_marketplace: 'Back to Marketplace',

  // C
  category_marketplace: 'Category',
  categories: 'Categories',
  categories_subtitle: 'What we offer! to be updated...',
  contact_us_help: 'If any help please ',
  contact_us: 'Contact us',
  connect_marketplace: 'Connect',

  // D
  disconnect_integration: 'Disconnect Integration',
  disconnect_apps: 'Disconnect',
  disable_marketplace: 'Disable',
  disabled_marketplace: 'Disabled',
  developer_documents: 'Developer Documents',

  // E
  explore_all_apps: 'Explore All Apps',

  // F
  filters_apps: 'Filters',

  // H
  highlight: 'Highlight',

  // I
  it_look:
    "It looks like you haven't integrated any apps yet. Don't worry, we're here to help you get started.",

  // K
  knowledge_base: 'Knowledge base',
  key_features: 'Key features',

  // M
  manage_apps: 'Manage Apps',
  manage_connected_apps: 'Manage Connected Apps',

  // N
  need_help: 'Need Help?',
  no_apps: 'No Apps Connected',
  no_apps_to_show: 'No apps to show currently',

  // O
  or_check_our: 'Or check our',
  overview_app: 'Overview',

  // P
  pro: 'PRO',
  pricing_plan: 'Pricing plan',
  popular_apps: 'Popular Apps',
  poster_title: 'Welcome to The New Marketplace!',
  poster_subtitle:
    "Discover apps faster and easier with our redesigned platform. We've also added more apps to boost your productivity.",
  package_compatibility: '{{app_name}} Package compatibility',

  // R
  request_upgrade: 'Request Upgrade',

  // S
  search_for_apps: 'Search for Apps...',

  // T
  time_to_upgrade:
    "Time to upgrade! Your app's on a break because you're rocking an older package.",

  // V
  version: 'Version',
}

export default Marketplace
