import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paginate from './Paginate'

const TablePagination = ({
  currentPage,
  totalEntries,
  rowsPerPage,
  handleChange,
  handleChangeRPP,
  fetching,
}) => {
  function handleeChangeRPP(e, obj) {
    handleChangeRPP(e.target.value, obj)
  }
  const list = [
    {
      label: '10',
      value: 10,
      amplitudeValue: 10,
    },
    {
      label: '20',
      value: 20,
      amplitudeValue: 20,
    },
    {
      label: '30',
      value: 30,
      amplitudeValue: 30,
    },
    {
      label: '40',
      value: 40,
      amplitudeValue: 40,
    },
    {
      label: '50',
      value: 50,
      amplitudeValue: 50,
    },
    {
      label: I18n.t('all'),
      value: 999999999,
      amplitudeValue: 'All',
    },
  ]

  return (
    <div className="tbl-pagination--box">
      <div className="per-page">
        <span className="select-lable">{I18n.t('per_page')}</span>
        {rowsPerPage && (
          <Select
            data-testid="table-rpp-select"
            value={rowsPerPage}
            onChange={handleeChangeRPP}
            variant="filled"
            disabled={fetching}
          >
            {list.map((item, i) => (
              <MenuItem
                key={i}
                data-testid={`table-rpp-${item.label}`}
                value={item.value}
                amplitudeValue={item.amplitudeValue}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <Paginate
        testId="page-no"
        currentPage={currentPage || 1}
        totalEntries={totalEntries || 0}
        rowsPerPage={rowsPerPage || 10}
        handleChange={handleChange}
        fetching={fetching}
      />
    </div>
  )
}
TablePagination.propTypes = {
  currentPage: PropTypes.number,
  totalEntries: PropTypes.number,
  rowsPerPage: PropTypes.number,
  fetching: PropTypes.bool,

  handleChange: PropTypes.func.isRequired,
  handleChangeRPP: PropTypes.func.isRequired,
}

export default TablePagination
