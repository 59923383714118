/* eslint-disable no-case-declarations */
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  fetchingReviews: false,
  submittingAction: false,
  submitting: false,

  review_cycles: [],
  review_cycle: {},
  // review cycle details: ---------------
  review_cycle_summary: {},
  all_employee_reviews: [],
  // -------------------------------------
  employee_review_summary: {},
  pagination: {},
  stats: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PAGINATION_RESET:
      return {
        ...state,
        pagination: {},
      }

    case actions.REVIEW_CYCLES_SUMMARY_RESET:
      return {
        ...state,
        employee_review_summary: {},
        review_cycles: action.payload.clear_review_cycles ? [] : state.review_cycles,
      }

    case actions.REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.REVIEW_CYCLE_START_PUT_PENDING:
    case actions.REVIEW_CYCLES_COMPLETE_PUT_PENDING:
    case actions.REVIEW_CYCLES_LOAD_PENDING:
    case actions.REVIEW_CYCLES_DELETE_PENDING:
    case actions.REVIEW_CYCLES_SHOW_SUMMARY_PENDING:
    case actions.REVIEW_CYCLES_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_PENDING:
      return {
        ...state,
        fetchingReviews: true,
      }

    case actions.EMPLOYEE_REVIEW_RATING_PUT_PENDING:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_SHARE_PENDING:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_PENDING:
    case actions.REVIEW_CYCLES_REMINDER_REVIEWER_PENDING:
    case actions.EXPORT_EMPLOYEE_REVIEW_PENDING:
    case actions.REVIEW_CYCLES_EXPORT_PENDING:
    case actions.REVIEW_CYCLES_UPDATE_PENDING:
      return {
        ...state,
        submittingAction: true,
      }

    case actions.NORMAL_REVIEW_CYCLES_CREATE_PENDING:
    case actions.REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.REVIEW_CYCLES_LOAD_FULFILLED: {
      const { previous_page } = action?.payload?.data?.pagination || {}

      return {
        ...state,
        stats: action.payload.data.stats,
        pagination: action.payload.data.pagination,
        review_cycles: [...(previous_page ? state.review_cycles : []), ...action.payload.data.data],
        fetching: false,
      }
    }
    case actions.REVIEW_CYCLE_START_PUT_FULFILLED:
    case actions.REVIEW_CYCLES_COMPLETE_PUT_FULFILLED:
    case actions.REVIEW_CYCLES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        review_cycles: [],
        pagination: { page: 1 },
        fetching: false,
      }

    case actions.REVIEW_CYCLES_SHOW_SUMMARY_FULFILLED:
      return {
        ...state,
        review_cycle_summary: action.payload.data.data,
        fetching: false,
      }

    case actions.CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_FULFILLED: {
      const pre_page = action.payload?.pagination?.previous_page

      const reviewsResponse = {
        ...state,
        all_employee_reviews: {
          summary_reviews: [
            ...new Set([
              ...(pre_page ? state.all_employee_reviews.summary_reviews : []),
              ...action.payload.data.employee_reviews,
            ]),
          ],
          pagination: action.payload.pagination,
          success: action.payload.success,
          status_code: action.payload.status_code,
        },
        fetchingReviews: false,
      }

      return reviewsResponse
    }

    case actions.REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_FULFILLED:
      return {
        ...state,
        employee_review_summary: action.payload,
        fetching: false,
      }

    case actions.REVIEW_CYCLES_SHOW_FULFILLED:
      return {
        ...state,
        review_cycle: action.payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEE_REVIEW_RATING_PUT_FULFILLED:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_SHARE_FULFILLED:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_FULFILLED:
    case actions.REVIEW_CYCLES_REMINDER_REVIEWER_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        submittingAction: false,
      }

    case actions.EXPORT_EMPLOYEE_REVIEW_FULFILLED:
    case actions.REVIEW_CYCLES_EXPORT_FULFILLED:
      return {
        ...state,
        submittingAction: false,
      }

    case actions.REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_FULFILLED: {
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        review_cycle: action.payload.data.data,
        pagination: { page: 1 },
        submitting: false,
      }
    }

    case actions.REVIEW_CYCLES_UPDATE_FULFILLED:
    case actions.NORMAL_REVIEW_CYCLES_CREATE_FULFILLED:
    case actions.REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        review_cycle: action.payload.data.data,
        review_cycles: [],
        pagination: { page: 1 },
        submitting: false,
      }

    case actions.REVIEW_CYCLES_LOAD_REJECTED:
    case actions.REVIEW_CYCLES_DELETE_REJECTED:
    case actions.REVIEW_CYCLE_START_PUT_REJECTED:
    case actions.REVIEW_CYCLES_COMPLETE_PUT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.EMPLOYEE_REVIEW_RATING_PUT_REJECTED:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_SHARE_REJECTED:
    case actions.REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_REJECTED:
    case actions.REVIEW_CYCLES_REMINDER_REVIEWER_REJECTED:
    case actions.EXPORT_EMPLOYEE_REVIEW_REJECTED:
    case actions.REVIEW_CYCLES_EXPORT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submittingAction: false,
      }

    case actions.CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingReviews: false,
      }

    case actions.REVIEW_CYCLES_SHOW_REJECTED:
    case actions.REVIEW_CYCLES_SHOW_SUMMARY_REJECTED:
    case actions.REVIEW_CYCLES_UPDATE_REJECTED:
    case actions.NORMAL_REVIEW_CYCLES_CREATE_REJECTED:
    case actions.REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
        fetching: false,
      }

    case actions.REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.RESET_EMPLOYEE_REVIEW_SUMMARY:
      return {
        ...state,
        fetchingReviews: false,
        review_cycle_summary: {},
        all_employee_reviews: [],
      }

    case actions.RESET_EMPLOYEE_REVIEWS:
      return {
        ...state,
        fetchingReviews: false,
        all_employee_reviews: [],
      }

    case actions.RESET_EMPLOYEE_REVIEWS_FETCHING:
      return {
        ...state,
        fetching: false,
        fetchingReviews: false,
        submitting: false,
        submittingAction: false,
        fetching_share: false,
      }

    default:
      return state
  }
}
