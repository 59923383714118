import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Employee } from 'redux/authUser/types'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

type WeeklyEmail = {
  is_applicable: boolean
}

export type NotificationsEmployee = Employee & {
  configuration_enabled: boolean
}

type NotificationsEmail = {
  fetching: boolean
  employees: NotificationsEmployee[]
  manager_weekly_email_notification: WeeklyEmail | Record<string, unknown>
  employee_weekly_email_notification: WeeklyEmail | Record<string, unknown>
  pagination?: PaginationTypes
}

const initialState: NotificationsEmail = {
  fetching: false,
  employees: [],
  manager_weekly_email_notification: {},
  employee_weekly_email_notification: {},
}

export default function reducer(state = initialState, action: Action): NotificationsEmail {
  const { payload } = action
  switch (action.type) {
    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_PENDING:
    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING:
    case actions.ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING:
    case actions.ORGANIZATION_CONFIGURATIONS_PUT_PENDING:
    case actions.EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_PENDING:
    case actions.WEEKLY_EMAIL_UNSUBSCRIBE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_FULFILLED:
      const { previous_page } = action.payload.data.data.pagination

      return {
        ...state,
        employees: [
          ...new Set([
            ...(previous_page ? state.employees : []),
            ...action.payload.data.data.employees,
          ]),
        ],
        pagination: action.payload.data.data.pagination,
        fetching: false,
      }

    case actions.ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED:
    case actions.EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_FULFILLED:
    case actions.WEEKLY_EMAIL_UNSUBSCRIBE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED:
      return {
        ...state,
        manager_weekly_email_notification: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED:
      return {
        ...state,
        employee_weekly_email_notification: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_REJECTED:
    case actions.ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED:
    case actions.ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED:
    case actions.ORGANIZATION_CONFIGURATIONS_PUT_REJECTED:
    case actions.EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_REJECTED:
    case actions.WEEKLY_EMAIL_UNSUBSCRIBE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
