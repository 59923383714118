export const ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD = 'ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_PENDING =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_PENDING'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_FULFILLED =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_FULFILLED'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_REJECTED =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_REJECTED'

export const ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_PENDING =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_PENDING'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_FULFILLED =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_FULFILLED'
export const ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_REJECTED =
  'ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_REJECTED'

export const RESET_STATES = 'RESET_STATES'
