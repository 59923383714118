import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  grant_configuration: {},
  grant_configuration_leave: {},
  can_apply_in_negative_configuration: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function leaveManagementConfigurationReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'LEAVE_GRANT_TYPE_LOAD_PENDING':
    case 'CAN_APPLY_IN_NEGATIVE_LOAD_PENDING':
    case 'CAN_APPLY_IN_NEGATIVE__UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !state.grant_configuration.value,
      }

    case 'LEAVE_GRANT_TYPE_UPDATE_PENDING':
      return {
        ...state,
        errMsg: null,
        succMsg: null,
        fetching: true,
        loading: true,
      }

    case 'ENABLE_HALF_DAY_LEAVE_UPDATE_PENDING':
    case 'ENABLE_HALF_DAY_LEAVE_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'LEAVE_GRANT_TYPE_LOAD_REJECTED':
    case 'LEAVE_GRANT_TYPE_UPDATE_REJECTED':
    case 'CAN_APPLY_IN_NEGATIVE_REJECTED':
    case 'CAN_APPLY_IN_NEGATIVE_UPDATE_REJECTED':
    case 'ENABLE_HALF_DAY_LEAVE_LOAD_REJECTED':
    case 'ENABLE_HALF_DAY_LEAVE_UPDATE_REJECTED':
      Toastr.error(payload)
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LEAVE_GRANT_TYPE_LOAD_FULFILLED':
    case 'LEAVE_GRANT_TYPE_UPDATE_FULFILLED':
      if (payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        grant_configuration: payload.data.data.organization_configuration,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'ENABLE_HALF_DAY_LEAVE_LOAD_FULFILLED':
    case 'ENABLE_HALF_DAY_LEAVE_UPDATE_FULFILLED':
      if (payload.data.message) {
        Toastr.success(action.payload.data.message)
      }

      return {
        ...state,
        fetching: false,
        grant_configuration_leave: payload?.data?.data?.organization_configuration,
      }

    case 'CAN_APPLY_IN_NEGATIVE_LOAD_FULFILLED':
    case 'CAN_APPLY_IN_NEGATIVE_UPDATE_FULFILLED':
      if (payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        can_apply_in_negative_configuration: payload.data.data.organization_configuration,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
