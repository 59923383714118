import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { PublicTemplate } from './publicTemplateTypes'

export const initialState: PublicTemplate = {
  fetching: false,
  area: [],
  department: [],
  job_title: [],
  location: [],
  mol_registration: [],
  outsourcing_companies: [],
  shift: [],
  business_unit: [],
  document_type: [],
  employment_type: [],
  group: [],
  grade: [],
  country: [],
  loan_type: [],
  nationality: [],
  industry_sector: [],
  payroll_transaction_type: [],
  leave_type: [],
  asset_type: [],
  request_type: [],
  archived_request_type: [],
  commercial_registration: [],
  organization_task: [],
  lookups: {
    nationality: [],
    id_type: [],
    insurance_policy_class: [],
    gcc_nationality: [],
    gender_type: [],
    marital_status: [],
    member_relationship: [],
    endorsement_type: [],
  },
}

export default function reducer(state = initialState, action: Action): PublicTemplate {
  switch (action.type) {
    case actions.PUBLIC_TEMPLATES_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PUBLIC_TEMPLATES_FULFILLED: {
      const templates: PublicTemplate = action.payload?.data?.data
      const molReq = templates?.mol_registration || []
      const lookups = templates?.lookups || {}
      return {
        ...state,
        ...templates,
        lookups: { ...state.lookups, ...lookups },
        mol_registration:
          molReq.map((mol) => ({
            ...mol,
            name_i18n: `${mol.gosi_number} - ${mol.name}`,
          })) || [],
        fetching: false,
      }
    }

    case actions.PUBLIC_TEMPLATES_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
