import { RootState } from 'types/redux'
import { TInitialSalaryPackages } from './type'

export const salarypackagesSelector = (s: RootState): TInitialSalaryPackages => ({
  header: s.salaryPackages.header,
  records: s.salaryPackages.records,
  statistics: s.salaryPackages.statistics,
  pagination: s.salaryPackages.pagination,
})

export const fetchingSelector = ({
  state,
}: {
  state: {
    salaryPackages: TInitialSalaryPackages
  }
}): boolean | undefined => state?.salaryPackages?.fetching
export const loadingSelector = ({
  state,
}: {
  state: {
    salaryPackages: TInitialSalaryPackages
  }
}): boolean | undefined => state.salaryPackages.loading
