import React from 'react'
import { Flex } from '@jisr-hr/ds'
import {
  Workflow,
  Tabs,
  Info,
  Comments,
  Reason,
} from 'components/global/templates/RequestDrawer/components'
import { ReactComponent as TaskCheckmarkIcon } from 'assets/images/TaskCheckmark.svg'
import I18n from 'translations/i18n'

import JisrLoader from 'components/global/JisrLoader'
import { useRequestDetail } from '../../hooks'
import InfoBadge from '../../components/Info/components/InfoBadge'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const AssetRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const [activeTab, setActiveTab] = React.useState('request_details')

  const assets = request.asset_types || request.assets || []
  const assetNames = assets.map((item) => item?.name_i18n || item?.name)
  const items = [
    {
      alignStart: true,
      key: 'asset_items',
      label: I18n.t('asset_items'),
      icon: <TaskCheckmarkIcon />,
      value: (
        <Flex
          flexWrap
          style={{ gap: 8, justifyContent: 'flex-end' }}
        >
          {assetNames?.map((item, i) => (
            <InfoBadge
              key={i}
              label={item}
              type="gray"
            />
          ))}
        </Flex>
      ),
    },
  ]

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              employee: request.employee,
              created_by: request.created_by,
              requested_on: request.created_at,
              created_at: request.created_at,
              status: request?.status,
              status_i18n: request?.status_i18n,
              type_i18n: request?.request_name_i18n,
              asset_items: assetNames,
              items,
              request: request?.request,
              request_name: request?.request_name,
              cancellation_request_info: request?.cancellation_request_info,
              is_cancellation_request: request?.is_cancellation_request,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
            }}
          />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          {(request.reason || request.attachments?.length > 0) && (
            <Reason
              details={{
                ...request,
                reason: request.reason,
                attachments: request.attachments,
              }}
            />
          )}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </>
      )}
    </Flex>
  )
}

export default AssetRequest
