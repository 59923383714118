import i18n from 'translations/i18n'
import { Flex, Button, Typography } from '@jisr-hr/ds'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import styles from './styles.module.css'

type ErrorPageProps = {
  errorCodeImage: JSX.Element
  title: string
  subTitle: string
  isBoundary?: boolean
  className?: string
}

const ErrorPage = ({
  errorCodeImage,
  title,
  subTitle,
  isBoundary,
  className,
}: ErrorPageProps): JSX.Element => {
  const history = useHistory()

  return (
    <Flex
      className={className}
      flexCol
      itemsCenter
      justifyCenter
      style={{ gap: 40 }}
    >
      {errorCodeImage}

      <Flex
        flexCol
        itemsCenter
        justifyCenter
        style={{ gap: 16 }}
      >
        <Flex
          flexCol
          itemsCenter
          justifyCenter
          style={{ gap: 8 }}
        >
          <Typography
            text={i18n.t(title)}
            variant="heading"
            className={classNames({ [styles.dsHeading]: isBoundary })}
          />

          <Typography
            text={i18n.t(subTitle)}
            variant="subtitle-1"
            textColor="color/fg/lighter"
            className={classNames({ [styles.dsSubTitle]: isBoundary })}
          />
        </Flex>
        <Flex
          itemsCenter
          justifyCenter
          style={{ gap: 16 }}
        >
          {!isBoundary && (
            <Button
              label={i18n.t('back_to_home')}
              onClick={(): void => {
                history.push({
                  pathname: '/',
                })
              }}
              size="medium"
              variant="outlined"
              color="neutral"
            />
          )}
          {isBoundary && (
            <a href="/">
              <Button
                label={i18n.t('back_to_home')}
                size="medium"
                variant="outlined"
                color="neutral"
              />
            </a>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ErrorPage
