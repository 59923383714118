import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import { isEmpty } from 'lodash'
import { Toastr } from 'components/global/Toastr'
import FileSaver from 'file-saver'
import Qs, { IStringifyOptions } from 'qs'
import { API_ROOT, ORGANISATION_NAME } from '../env'
// import { buildUrlWithQueryString } from './apiHelperUtils';
import { getErrorMessage, handleErrorStatusCases } from './apiHelperUtils'
import errorResponseHandler from './errorHandler'
import { isOldBrowser } from './oldBrowser'
import { decodedString, encodedString } from './uiHelpers'

const API_VERSION_ONE = 'v1'
const API_VERSION_TWO = 'v2'

const http = axios.create({
  withCredentials: true,
})

http.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: ORGANISATION_NAME,
  }
  return config
})

// To prevent Storybook, becuse we handling dummy API response
if (http.interceptors) {
  http.interceptors.response.use(undefined, errorResponseHandler)
}

export const serializerConfig = {
  arrayFormat: 'brackets',
  encode: false,
}

type SimpleObject = Record<string, string | unknown>

type TError = {
  success: boolean
  error?: string | { message?: string }
  errors?: { message?: string }
  data: unknown | null
  status_code?: number | string
}

type TResponse = { responseFileName?: string; data: Blob | TError }

function handleAPI<R, P extends SimpleObject = SimpleObject, D extends SimpleObject = SimpleObject>(
  path: string,
  params: P | SimpleObject = {},
  method: Method,
  data: D | SimpleObject | null = null,
  cache?: boolean,
  options?: AxiosRequestConfig | undefined,
  isThirdPartyUrl?: boolean,
  rootAPI?: string | undefined,
): AxiosPromise<R> | undefined {
  type Headers = {
    Accept?: string
    'Access-Token'?: string | null
    'Content-Type': string
    locale?: string
    pincode?: string | null
    slug?: string | null
    token?: string | null
  }

  const headersInfo: Headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: localStorage.getItem('Locale') || 'en',
    slug: ORGANISATION_NAME,
    token: localStorage.getItem('onboarding_token'),
    ...(decodedString(sessionStorage.getItem(encodedString('onboarding_token')) || '') && {
      token: decodedString(sessionStorage.getItem(encodedString('onboarding_token')) || ''),
    }),
    pincode: btoa(sessionStorage.getItem(atob('pincode')) || '') || null,
  }

  if (!headersInfo.pincode) {
    delete headersInfo.pincode
  }

  const headersWithThirdParty: Headers = {
    'Content-Type': 'application/json',
  }

  let headers = headersInfo
  let url = rootAPI ? rootAPI + path : API_ROOT + path

  if (isThirdPartyUrl) {
    url = path
    headers = headersWithThirdParty
  }

  return http({
    method,
    url,
    params: {
      ...params,
      ...(params?.search // @ts-expect-error Check and fix what the type of search here
        ? { search: encodeURIComponent(params.search) }
        : {}),
    },
    paramsSerializer: (paramObject) =>
      Qs.stringify(paramObject, serializerConfig as IStringifyOptions),
    data,
    headers,
    ...options,
  })
}

function downloadAPI<R, P extends SimpleObject = SimpleObject>(
  path: string,
  fileType: string,
  fileName?: string, // If not provided, it will be generated from the response
  params?: P | SimpleObject,
  rootAPI: string | undefined = API_ROOT,
  viewTarget?: string | null,
  method?: string,
  body?: Record<string, unknown>,
  withResponseName?: boolean,
): Promise<R | unknown> {
  if (isOldBrowser()) {
    // Use Axios for old browsers
    const axiosPromise = axios
      .get(path, {
        responseType: 'blob',
        params,
      })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'] || ''
        const responseFileName = decodeURIComponent(
          contentDisposition.match(/filename\*?="([^"]+)"/)?.[1] ?? '',
        )
        const fileNameToUse = fileName || responseFileName || 'downloaded-file'
        const withExtension =
          fileNameToUse.split('.').length >= 2
            ? fileNameToUse
            : `${fileNameToUse}.${fileType.split('/')[1]}`
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', withExtension)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        Toastr.error(error)
      })
    return axiosPromise
  }
  const qs = `?${Qs.stringify(params, serializerConfig as IStringifyOptions)}`

  const promise = new Promise((resolve: (value?: unknown) => void, reject) => {
    fetch(rootAPI + path + qs, {
      credentials: 'include',
      headers: {
        Accept: fileType,
        token: localStorage.getItem('onboarding_token'),
        locale: localStorage.getItem('Locale') || 'en',
        slug: ORGANISATION_NAME,
        pincode: btoa(sessionStorage.getItem(atob('pincode')) || '') || null,
      },
      ...(method && { method }),
      ...(body && { body: JSON.stringify(body) }),
      responseType: 'blob',
    } as RequestInit)
      .then((response): Promise<TResponse> => {
        if (!response.ok) {
          return response?.json?.().then((data) => ({ data }))
        }

        const contentDisposition = response.headers.get('content-disposition') || ''
        const responseFileName = decodeURIComponent(
          contentDisposition.match(/filename\*?="([^"]+)"/)?.[1] ?? '',
        )

        return response.blob().then((blob) => ({ responseFileName, data: blob }))
      })
      .then(({ responseFileName, data }) => {
        if (data instanceof Blob) {
          const extension = data.type.split('/')[1]
          const fileNameToUse = fileName || responseFileName || 'downloaded-file'
          const withExtension =
            fileNameToUse.split('.').length >= 2 ? fileNameToUse : `${fileNameToUse}.${extension}`

          if (viewTarget) {
            const fileURL = window.URL.createObjectURL(data)
            window.open(fileURL, viewTarget)
          } else {
            FileSaver.saveAs(
              data,
              withResponseName && responseFileName ? responseFileName : withExtension,
            )
          }
          resolve()
          return
        }

        if (!isEmpty(data) && (data.errors || data.error)) {
          const msg = getErrorMessage(data)
          Toastr.error(msg)
          handleErrorStatusCases(data?.status_code || '')
          reject(data)
        }
      })
      .catch((err) => {
        Toastr.error(err)
        reject(err)
      })
  })

  return promise
}

export { handleAPI, downloadAPI, API_VERSION_ONE, API_VERSION_TWO }
