/* eslint-disable prefer-template */
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

import { Action } from 'types/redux'
import lodash from 'lodash'
import { AccountTransactionOdooTypes } from 'types/Accounts/chartAccount'
import { CostCenter, CostCenterOdoo } from 'types/Accounts/costCenter'
import * as actions from './actions'

// ? <----------------- these types should be shared at some point ----------------->
export type OdooCategory = {
  internal_id: string
  name: string
  external_subsidiry_id: number
}

type OdooCostCenterAccount = {
  acct_name: string
  acct_number: string
  internal_id: string
}

export type JournalEntryTemplates = {
  acct_name: string
  internal_id: number
  subsidiary_id: number
  subsidiary_name: string
}
export type OdooCategoriesType = OdooCategory & {
  tracking_category_items: OdooCategory[]
}

export type JournalEntries = {
  Accrual?: string
  Ajeer?: string
  EndOfService?: string
  Gosi?: string
  OutOfPayroll?: string
  Regular?: string
  VacationSettlement?: string
}

type OdooCostCenter = CostCenter & {
  employees_ids?: number[]
}

export type JournalValue = {
  label: string
  label_ar: string
  name: string
  name_ar: string
  value: string
}
// ? <----------------- end ----------------->

type InitStateType = {
  accounts: OdooCostCenterAccount[]
  all_selected_ids: number[]
  catagories: OdooCategoriesType[]
  cost_centers: CostCenterOdoo[]
  fetching: boolean
  journal_shape: JournalValue[]
  loading: boolean
  type_fetching: boolean
  journal_entry_templates: JournalEntryTemplates[]
  tracking_dimension_pair_subsidiary: AccountTransactionOdooTypes[]
}

const initialState: InitStateType = {
  fetching: false,
  type_fetching: false,
  loading: false,
  cost_centers: [],
  all_selected_ids: [],
  journal_shape: [],
  catagories: [],
  accounts: [],
  journal_entry_templates: [],
  tracking_dimension_pair_subsidiary: [],
}

function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action

  switch (action.type) {
    case actions.ODOO_COST_CENTER_LIST_LOAD_PENDING:
    case actions.TRACKING_DIMENSION_PAIR_SUBSIDIARY_PENDING:
    case actions.ODOO_COST_CENTER_CATAGORIES_PENDING:
    case actions.ODOO_COST_CENTER_ACCOUNTS_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.ODOO_COST_CENTER_CREATE_PENDING:
    case actions.ODD_JOURNAL_TEMPLATE_LOAD_PENDING:
    case actions.ODOO_COST_CENTER_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.ODOO_COST_CENTER_CATAGORIES_FULFILLED: {
      return {
        ...state,
        catagories: payload.data.data as OdooCategoriesType[],
        type_fetching: false,
        fetching: false,
      }
    }
    case actions.TRACKING_DIMENSION_PAIR_SUBSIDIARY_FULFILLED: {
      return {
        ...state,
        tracking_dimension_pair_subsidiary: payload.data.data as AccountTransactionOdooTypes[],
        fetching: false,
      }
    }
    // * accounts
    case actions.ODOO_COST_CENTER_ACCOUNTS_FULFILLED: {
      const accounts = (payload.data.data || []) as OdooCostCenterAccount[]
      return {
        ...state,
        accounts,
        type_fetching: false,
      }
    }

    case actions.ODOO_COST_CENTER_LIST_LOAD_FULFILLED: {
      const cost_centers = payload.data.data.tracking_dimensions as CostCenter[]
      let reBuildCostCenter: OdooCostCenter[] = []
      let journal_shape: JournalValue[] = []

      cost_centers?.forEach((cost) => {
        reBuildCostCenter = [
          ...reBuildCostCenter,
          {
            ...cost,
            org_category_type: cost.org_category_type || 'custom',
            employees_ids: lodash.uniq(
              cost?.tracking_dimension_values?.map((it) => it.mapped_employee_ids!)?.flat(),
            ),
          },
        ]
        journal_shape = [
          ...journal_shape,
          {
            name: cost.name,
            name_ar: cost.name,
            label: cost.name + ' Name',
            label_ar: cost.name,
            value: cost?.name?.toLowerCase() + '_name',
          },
        ]
      })

      const all_selected_ids = reBuildCostCenter?.map((it) => it.employees_ids!)?.flat()
      return {
        ...state,
        cost_centers: reBuildCostCenter,
        journal_shape,
        all_selected_ids: lodash.uniq(all_selected_ids || []),
        fetching: false,
      }
    }
    case actions.ODD_JOURNAL_TEMPLATE_LOAD_FULFILLED: {
      return {
        ...state,
        journal_entry_templates: payload.data.data as JournalEntryTemplates[],
        loading: false,
      }
    }
    case actions.ODOO_COST_CENTER_CREATE_FULFILLED:
    case actions.ODOO_COST_CENTER_UPDATE_FULFILLED:
    case actions.ODOO_COST_CENTER_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ODOO_COST_CENTER_CREATE_REJECTED:
    case actions.ODOO_COST_CENTER_CATAGORIES_REJECTED:
    case actions.ODOO_COST_CENTER_UPDATE_REJECTED:
    case actions.ODD_JOURNAL_TEMPLATE_LOAD_REJECTED:
    case actions.ODOO_COST_CENTER_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
        fetching: false,
      }
    }

    case actions.TRACKING_DIMENSION_PAIR_SUBSIDIARY_REJECTED:
    case actions.ODOO_COST_CENTER_LIST_LOAD_REJECTED:
    case actions.ODOO_COST_CENTER_ACCOUNTS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
