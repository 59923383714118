import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Attach = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
  >
    <path
      fill={getColor(color)}
      d="M16.7737249,6.64917808 C16.2698568,6.15072999 15.7710536,6.64917808 15.7710536,
        6.64917808 L7.33961919,14.9930708 C6.02332186,16.2955321 4.61828539,
        16.8330781 3.38728299,16.5064602 C2.47780805,16.2652563 1.78227954,15.5576846 1.52639474,
        14.6131194 C1.17711098,13.3230892 1.69252739,11.8750634 2.94034565,10.6399708 L11.7854909,
        1.88605143 C12.6187879,1.06138651 13.7142504,1.3936184 14.3795914,2.05146562 C14.7353584,
        2.40374772 14.950723,2.83001507 14.9859756,3.25167088 C15.0242671,3.71162258 14.8459784,
        4.14129846 14.4551615,4.52786641 L5.53505396,13.3557711 C5.01254652,13.8726653 4.52164481,
        13.9458486 4.32552725,13.7515621 C4.13589291,13.5636917 4.21632542,13.0854943 4.73883286,
        12.568199 L10.4987733,6.86812631 C10.7757286,6.59424051 10.7757286,6.14972748 10.4987733,
        5.87584168 C10.221818,5.60175538 9.77305726,5.60175538 9.49610199,5.87584168 L3.73616155,
        11.5759144 C2.54973133,12.7502549 2.63016384,14.0589318 3.32285594,14.7442477 C4.02223387,
        15.4365813 5.35109244,15.5231982 6.53772526,14.3484567 L15.4576302,5.52095305 C16.1472833,
        4.83884509 16.4728628,4.01397966 16.3991161,3.1361817 C16.3359046,2.38369751 15.97487,
        1.64605048 15.3818575,1.05978249 C14.2268304,-0.084282192 12.2225008,-0.531000754 10.7828196,
        0.893766797 L1.93787695,9.6472852 C0.321933052,11.246289 -0.327199876,13.1883519 0.156610808,
        14.9760281 C0.544996521,16.4104198 1.61553909,17.4893213 3.02017036,17.8620546 C3.36904892,
        17.954486 3.72643671,18 4.08949733,18 C5.51357827,18 7.01525532,17.2990448 8.34229049,
        15.9855559 L16.7735223,7.64146271 C16.7737249,7.64146271 17.2828607,7.15283922 16.7737249,
        6.64917808 Z"
    />
  </svg>
)

Attach.propTypes = {
  color: PropTypes.string,
}

Attach.defaultProps = {
  color: 'slate-grey',
}

export default Attach
