import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react'
import { useDispatch } from 'utils/hooks'
import i18n from 'translations/i18n'
import {
  // paymentMethodSetDefault,
  paymentMethodLoad,
  paymentMethodsLoad,
  updateSinglePaymentOfList,
} from 'redux/setting/payrollManagement/paymentMethods/actionCreators'
import {
  AuthorizedEmployeeType,
  MudadSignatoriesType,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import { organizationBanksDel } from 'redux/setting/payrollManagement/organizationBanks/actionsCreator'
import {
  mudadFlowStepsLoad,
  mudadRevokeEstablishment,
  resetMudadState,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import { useLocation } from 'react-router-dom'
import PaymentConfirmation, { ModalOptionsType } from './PaymentConfirmation'
import { MudadStepsNumber } from './helper'

type ErrType = { response: { data: { error: string } } }

type BankArgType = {
  account_id: number
  onSuccess?: () => void
  onReject?: (err: ErrType) => void
}

type PaymentConfigType = PaymentConfigurationType | null

type PaymentMethodContextType = {
  openMangeAccount: boolean
  isOnBoarding: boolean
  modalOptions: ModalOptionsType | null
  selectedPaymentMethod: PaymentConfigType
  setupModalOpen: boolean
  addNewConfigOpen: boolean
  methodModalOpen: PaymentConfigurationType['active_payment_method']
  mudadCurrentStep: MudadStepsNumber | null
  termsConditionsOpen: boolean
  currentSignatory: MudadSignatoriesType | null
  currentAuthorizer: AuthorizedEmployeeType | null
  handleChangeDefaultPayment: (id: number) => void
  handleMethodModalOpen: (
    data: PaymentConfigType,
    mood: PaymentConfigurationType['active_payment_method'],
  ) => Promise<void>
  setAddNewConfigOpen: Dispatch<SetStateAction<boolean>>
  handleRevokeMudad: () => Promise<void>
  setModalOptions: Dispatch<SetStateAction<ModalOptionsType | null>>
  handleManagementPaymentOpen: (data: PaymentConfigType) => void
  handleBankAccountDel: (opts: BankArgType) => Promise<void>
  setMudadCurrentStep: Dispatch<SetStateAction<MudadStepsNumber | null>>
  handleSetupMethodOpen: (data: PaymentConfigType) => Promise<void>
  setTermsConditionsOpen: Dispatch<SetStateAction<boolean>>
  setOpenMangeAccount: Dispatch<SetStateAction<boolean>>
  setMethodModalOpen: Dispatch<SetStateAction<PaymentConfigurationType['active_payment_method']>>
  setSelectedPaymentMethod: Dispatch<SetStateAction<PaymentConfigType>>
  setCurrentSignatory: Dispatch<SetStateAction<MudadSignatoriesType | null>>
  setCurrentAuthorizer: Dispatch<SetStateAction<AuthorizedEmployeeType | null>>
}

type ActiveMethod = PaymentConfigurationType['active_payment_method']

const PaymentMethodContext = createContext<PaymentMethodContextType | null>(null)

const PaymentMethodProvider: FC = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [openMangeAccount, setOpenMangeAccount] = useState(false)
  const [modalOptions, setModalOptions] = useState<ModalOptionsType | null>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentConfigType>(null)
  const [setupModalOpen, setSetupModalOpen] = useState(false)
  const [addNewConfigOpen, setAddNewConfigOpen] = useState(false)
  const [methodModalOpen, setMethodModalOpen] = useState<ActiveMethod>(null)
  const [mudadCurrentStep, setMudadCurrentStep] = useState<MudadStepsNumber | null>(null)
  const [termsConditionsOpen, setTermsConditionsOpen] = useState(false)
  const [currentSignatory, setCurrentSignatory] = useState<MudadSignatoriesType | null>(null)
  const [currentAuthorizer, setCurrentAuthorizer] = useState<AuthorizedEmployeeType | null>(null)

  const isOnBoarding = location.pathname.includes('/onboarding')

  // ? this got duplicated with useBanks ctx
  // ! need to make it custom hook if there are multiple think get shared
  const handleBankAccountDel = async (opts: BankArgType): Promise<void> => {
    const options: ModalOptionsType = {
      message: i18n.t('are_you_sure'),
      labelCancel: i18n.t('back'),
      labelConfirm: i18n.t('delete'),
      fetching: false,
      async onSuccess(): Promise<void> {
        setModalOptions({ ...this, fetching: true })
        try {
          await dispatch(organizationBanksDel(opts.account_id))
          if (opts.onSuccess) opts.onSuccess()
        } catch (e) {
          const err = e as ErrType
          if (opts.onReject) opts.onReject(err)
        } finally {
          setModalOptions(null)
        }
      },
    }
    setModalOptions(options)
  }

  const handleManagementPaymentOpen = (data: PaymentConfigType): void => {
    setOpenMangeAccount(Boolean(data))
    setSelectedPaymentMethod(data)
  }

  const handleSetupMethodOpen = async (data: PaymentConfigType): Promise<void> => {
    // ? call the api to reset the previous data
    if (data) await dispatch(paymentMethodLoad(data.id))
    setSetupModalOpen(!!data)
    setSelectedPaymentMethod(data)
  }

  const handleChangeDefaultPayment = (): void => {
    if (!selectedPaymentMethod) return
    const isMudadActive = selectedPaymentMethod.active_payment_method === 'mudad'
    const options: ModalOptionsType = {
      variant: 'warning',
      message: i18n.t(
        isMudadActive
          ? 'change_payment_method_to_manual_payment'
          : 'change_payment_method_to_mudad',
      ),
      subMessage: i18n.t(
        isMudadActive ? '' : 'You_will_not_be_able_to_pay_your_employee_with_the_bank_file',
      ),
      labelCancel: i18n.t('back'),
      labelConfirm: i18n.t(isMudadActive ? 'change_to_manual_payment' : 'change_to_mudad'),
      fetching: false,
      async onSuccess(): Promise<void> {
        setModalOptions({ ...this, fetching: true })
        try {
          // await dispatch(paymentMethodSetDefault({ cr_id: selectedPaymentMethod.id, id }))
          const res = await dispatch(paymentMethodLoad(selectedPaymentMethod.id))
          const data = res.value.data.data.commercial_registration as PaymentConfigurationType
          setModalOptions(null)
          setSelectedPaymentMethod(data)
          dispatch(updateSinglePaymentOfList(data))
        } catch (e) {
          const err = e as ErrType
          setModalOptions({
            ...this,
            variant: 'danger',
            fetching: false,
            subMessage: err?.response?.data?.error,
            onSuccess: undefined,
          })
        }
      },
    }
    setModalOptions(options)
  }

  const handleMethodModalOpen = async (
    data: PaymentConfigType,
    mood: PaymentConfigurationType['active_payment_method'],
  ): Promise<void> => {
    // ? @note if you want to open mudad and org doesn't support it
    // if (mood === 'mudad' && (!isOnBoarding && !is_mudad_enabled)) {
    //   setModalOptions({
    //     variant: 'info',
    //     fetching: false,
    //     message: 'please contact with manager',
    //   })
    //   return
    // }

    if (data) {
      await dispatch(paymentMethodLoad(data.id))
      await dispatch(paymentMethodsLoad())
    }

    if (mood === 'mudad') {
      await dispatch(mudadFlowStepsLoad(Number(data?.registration_id)))
      setMudadCurrentStep(3)
    }

    setSelectedPaymentMethod(data)
    setMethodModalOpen(mood)
  }

  const handleRevokeMudad = async (): Promise<void> => {
    if (!selectedPaymentMethod) return
    const options: ModalOptionsType = {
      message: i18n.t('you_are_about_to_delete_mudad_establishment'),
      labelCancel: i18n.t('back'),
      labelConfirm: i18n.t('delete'),
      fetching: false,
      async onSuccess(): Promise<void> {
        setModalOptions({ ...this, fetching: true })
        try {
          await dispatch(mudadRevokeEstablishment(selectedPaymentMethod.registration_id))
          setModalOptions(null)

          const res = await dispatch(paymentMethodLoad(selectedPaymentMethod.id))
          const data = res.value.data.data.commercial_registration as PaymentConfigurationType
          dispatch(updateSinglePaymentOfList(data))
          setSelectedPaymentMethod(data)
          dispatch(resetMudadState())
        } catch (e) {
          const err = e as ErrType
          setModalOptions({
            ...this,
            fetching: false,
            subMessage: err?.response?.data?.error || 'something went wrong',
            onSuccess: undefined,
          })
        }
      },
    }
    setModalOptions(options)
  }

  // ? values
  const ctxValue = {
    isOnBoarding,
    openMangeAccount,
    handleManagementPaymentOpen,
    setOpenMangeAccount,
    modalOptions,
    setModalOptions,
    handleBankAccountDel,
    selectedPaymentMethod,
    handleSetupMethodOpen,
    setSelectedPaymentMethod,
    setupModalOpen,
    addNewConfigOpen,
    handleChangeDefaultPayment,
    handleRevokeMudad,
    setAddNewConfigOpen,
    handleMethodModalOpen,
    setMethodModalOpen,
    methodModalOpen,
    mudadCurrentStep,
    setMudadCurrentStep,
    termsConditionsOpen,
    setTermsConditionsOpen,
    currentSignatory,
    setCurrentSignatory,
    currentAuthorizer,
    setCurrentAuthorizer,
  }

  return (
    <PaymentMethodContext.Provider value={ctxValue}>
      {props.children}

      <PaymentConfirmation
        modalOptions={modalOptions}
        onClose={(): void => setModalOptions(null)}
      />
    </PaymentMethodContext.Provider>
  )
}

const usePaymentMethod = (): PaymentMethodContextType => {
  const context = useContext(PaymentMethodContext)
  if (context === null) throw Error('you should pass context provider')
  return context
}

export { PaymentMethodProvider, usePaymentMethod }
