import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'

import * as actions from './actions'

type IdType = null | number

type LoanTypes = {
  category: string
  default_pay_type: string
  description: null | string
  id: IdType
  is_default: boolean
  name: string
  name_ar: string
  name_i18n: string
  pay_type: string
  status: string
}

type LoanLimitType = {
  id: IdType
  name: string
  value: {
    config: string
    is_applicable: boolean
  }
}

type PayrunConfigurationType = {
  id: IdType
  name: string
  value: string
}

type StateType = {
  bulk_payrun_configurations: []
  errMsg: null
  fetching: boolean
  fetching_loan_policy: boolean
  loading: boolean
  loan_limit: LoanLimitType
  loan_types: LoanTypes[]
  payroll_transaction_types: []
  payrun_configuration: PayrunConfigurationType
}

const DEFAULT_STATE: StateType = {
  payrun_configuration: {
    id: null,
    name: '',
    value: '',
  },
  loan_limit: {
    id: null,
    name: '',
    value: {
      config: '',
      is_applicable: false,
    },
  },
  payroll_transaction_types: [],

  bulk_payrun_configurations: [],
  loan_types: [],

  errMsg: null,
  fetching: false,
  fetching_loan_policy: false,
  loading: false,
}

let changes = null

export default function configurationReducer(state = DEFAULT_STATE, action: Action): StateType {
  const { payload } = action
  let loanTypes = []
  let newRecord: LoanTypes = {
    category: '',
    default_pay_type: '',
    description: '',
    id: null,
    is_default: false,
    name: '',
    name_ar: '',
    name_i18n: '',
    pay_type: '',
    status: '',
  }
  let updatedRecord: { id: number | string } = { id: '' }

  switch (action.type) {
    case actions.ONBOARDING_LOAN_LIMIT_LOAD_PENDING:
    case actions.ONBOARDING_LOAN_LIMIT_BULK_UPDATE_PENDING: {
      return {
        ...state,
        fetching_loan_policy: true,
        errMsg: null,
      }
    }
    case actions.ONBOARDING_LOAN_TYPES_LOAD_PENDING:
      return {
        ...state,
        errMsg: null,
        fetching: true,
      }
    case actions.ONBOARDING_LOAN_TYPE_CREATE_PENDING:
    case actions.ONBOARDING_LOAN_TYPE_DELETE_PENDING:
    case actions.ONBOARDING_LOAN_TYPE_UPDATE_PENDING:
      return {
        ...state,
        errMsg: null,
        loading: true,
      }

    case actions.ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_PENDING: {
      return {
        ...state,
        fetching_loan_policy: true,
      }
    }

    case actions.ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_FULFILLED:
      return {
        ...state,
        payrun_configuration: {
          id: payload.data.data?.payrun_configuration?.id,
          name: payload.data.data?.payrun_configuration?.name,
          value: payload.data.data?.payrun_configuration?.value,
        },
        fetching_loan_policy: false,
      }

    case actions.ONBOARDING_LOAN_LIMIT_LOAD_FULFILLED:
      return {
        ...state,
        loan_limit: {
          // ...payload.data.data.payrun_configuration,
          id: payload.data.data.payrun_configuration?.id,
          name: payload.data.data.payrun_configuration?.name,
          value: {
            config: payload.data.data.payrun_configuration?.value?.config,
            is_applicable: payload.data.data.payrun_configuration?.value?.is_applicable,
          },
        },
        fetching_loan_policy: false,
      }

    case actions.ONBOARDING_LOAN_TYPES_LOAD_FULFILLED:
      changes = {
        ...state,
        ...payload.data.data.payroll_transaction_types,
        payroll_transaction_types: payload.data.data.payroll_transaction_types,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.ONBOARDING_LOAN_LIMIT_BULK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      changes = {
        bulk_payrun_configurations: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching_loan_policy: false,
      }
      return { ...state, ...changes }

    case actions.ONBOARDING_LOAN_TYPE_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        loading: false,
      }

    case actions.ONBOARDING_LOAN_TYPE_CREATE_FULFILLED:
      Toastr.success(payload.data.message)
      newRecord = payload.data.data.payroll_transaction_type
      changes = {
        loan_types: [...state.loan_types, newRecord],
        loading: false,
      }
      return {
        ...state,
        loan_types: [...state.loan_types, newRecord],
        loading: false,
      }

    case actions.ONBOARDING_LOAN_TYPE_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      loanTypes = [...state.loan_types]
      updatedRecord = payload.data.data.payroll_transaction_type
      changes = {
        loading: false,
        loan_types: updateOneListOfRecords(loanTypes, updatedRecord),
      }
      return { ...state, ...changes }

    case actions.ONBOARDING_LOAN_LIMIT_BULK_UPDATE_REJECTED:
    case actions.ONBOARDING_LOAN_LIMIT_LOAD_REJECTED:
    case actions.ONBOARDING_LOAN_FULL_OR_BASIC_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
        fetching_loan_policy: false,
        delay_calculation_fetching: false,
        absence_calculation_fetching: false,
      }
      return { ...state, ...changes }

    case actions.ONBOARDING_LOAN_TYPE_CREATE_REJECTED:
    case actions.ONBOARDING_LOAN_TYPE_UPDATE_REJECTED:
    case actions.ONBOARDING_LOAN_TYPE_DELETE_REJECTED:
    case actions.ONBOARDING_LOAN_TYPES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        loading: false,
        delay_calculation_fetching: false,
        absence_calculation_fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
