import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  fetching: false,
  records: [],
  pagination: {},
  normal_records: [],
  goverment_records: [],
  onboarding_records: [],
  action: '',
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.RESET_ASSETMANAGER_BULK_UPLOAD_IMPORT:
      return initialState

    case actions.ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_PENDING:
    case actions.ASSETMANAGER_BULK_UPLOAD_PENDING:
    case actions.ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_FULFILLED:
      const { data, pagination } = payload.data
      return {
        ...state,
        records: [...new Set([...(pagination?.previous_page ? state.records : []), ...data])],
        pagination,
        fetching: false,
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_FULFILLED:
      return {
        ...state,
        data: null,
        normal_records: payload.data.data.normal_records,
        goverment_records: payload.data.data.goverment_records,
        onboarding_records: payload.data.data.onboarding_records,
        fetching: false,
        action: 'categories',
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_FULFILLED:
      if (!payload.options?.hideSuccessMsg) Toastr.success(payload.data.data.message)
      return {
        ...state,
        ...payload.data,
        fetching: false,
        action: 'bulkupload',
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_REJECTED:
      if (payload.response?.data?.errors?.length) {
        Toastr.error(payload.response.data.errors[0].message)
      } else {
        Toastr.error(handleResponseErr(payload))
      }

      return {
        ...state,
        fetching: false,
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case actions.ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
