/** **************
 *   Auth Actions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function leaveGrantTypeLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/organization_configurations/leave_grant_type`
  const method = 'GET'

  return {
    type: 'LEAVE_GRANT_TYPE_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function leaveGrantTypeUpdate(data) {
  const url = `/${API_VERSION_TWO}/organization_configurations/leave_grant_type`
  const method = 'PUT'
  const params = {}

  return {
    type: 'LEAVE_GRANT_TYPE_UPDATE',
    payload: handleAPI(url, params, method, { organization_configuration: data }),
  }
}

export function enableHalfDayLeaveLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/organization_configurations/enable_half_day_leave`
  const method = 'GET'

  return {
    type: 'ENABLE_HALF_DAY_LEAVE_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function enableHalfDayLeaveUpdate(data) {
  const url = `/${API_VERSION_TWO}/organization_configurations/enable_half_day_leave`
  const method = 'PUT'

  return {
    type: 'ENABLE_HALF_DAY_LEAVE_UPDATE',
    payload: handleAPI(url, {}, method, { organization_configuration: data }),
  }
}

export function canApplyInNegativeLoad() {
  const url = `/${API_VERSION_TWO}/organization_configurations/can_apply_in_negative`
  const method = 'GET'
  const params = {}

  return {
    type: 'CAN_APPLY_IN_NEGATIVE_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function canApplyInNegativeUpdate(data) {
  const url = `/${API_VERSION_TWO}/organization_configurations/can_apply_in_negative`
  const method = 'PUT'
  const params = {}

  return {
    type: 'CAN_APPLY_IN_NEGATIVE_UPDATE',
    payload: handleAPI(url, params, method, { organization_configuration: data }),
  }
}
