import { intervalToDuration, subMinutes, addHours, format, addMinutes } from 'date-fns'
import I18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'

function replaceArabicAMPM(time) {
  return time?.replace('صباحاً', 'am')?.replace('مساءً', 'pm')
}

export function replaceAmPm(time) {
  if (!time) return ''

  try {
    return !isRtl ? time : time.toLowerCase().replace('am', 'صباحاً').replace('pm', 'مساءً')
  } catch (e) {
    return false
  }
}

export const normalizeDate = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return `${onlyNums}-`
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-`
    }
    if (onlyNums.length === 8) {
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(6, 8)}`
    }
  }
  if (onlyNums.length <= 4) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4)}`
  }
  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(6, 8)}`
}

export const normalizeTime = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return `${onlyNums >= 12 ? 12 : onlyNums}:`
    }

    if (onlyNums.length === 4) {
      return `${onlyNums.slice(0, 2)}:${
        onlyNums.slice(2, 4) === 59 || onlyNums.slice(2, 4) > 59 ? '59' : onlyNums.slice(2, 4)
      }`
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`
  }
  return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`
}

const validTimeRange = (val, maxVal) => {
  let res = Math.min(val, maxVal)
  if (res < 10) res = `0${res}`
  return res
}

export const validHours = (val) => validTimeRange(val, 23)
export const validMinutes = (val) => validTimeRange(val, 59)

export const normalizeTime24 = (value = '', previousValue = '') => {
  if (value.length <= previousValue.length) {
    return value
  }
  let meridiem = value
    .toLowerCase()
    .replace(/[^\sap]/g, '')
    .replace(/\s\s+/g, ' ')
  if (meridiem.includes('a')) meridiem = ' am'
  if (meridiem.includes('p')) meridiem = ' pm'
  const time = value.replace(/[^\d:]/g, '')
  if (!time.includes(':')) {
    return time.length > 1 ? `${validHours(time)}:${meridiem}` : `${time}${meridiem}`
  }
  let [hours, minutes] = time.split(':')
  if (hours.length >= 2) hours = validHours(hours.slice(0, 2))
  if (minutes.length >= 2) minutes = validMinutes(minutes.slice(0, 2))
  return `${hours}:${minutes}${meridiem}`
}

export const convert24Hourto12Hour = (val, previousValue) => {
  if (!val) return previousValue
  let [time, meridiem = ''] = val.split(' ')
  time = time.replace(/[^\d:]/g, '')
  let [hours, minutes = '00'] = time.split(':')
  if (meridiem.includes('a')) meridiem = ' am'
  if (meridiem.includes('p') || hours > 12) meridiem = ' pm'
  if (![' am', ' pm'].includes(meridiem)) {
    meridiem = ' am'
    if (hours >= 12) meridiem = ' pm'
  }
  hours = hours % 12 || 12
  ;[hours, minutes] = [validHours(hours), validMinutes(minutes)]
  return `${hours}:${minutes}${meridiem}`
}

export const integerNumber = (value) => value.replace(/[^\d]+|^0+(?!$)/g, '')

export const positiveInteger = (value) => {
  const parsed = parseInt(value, 10)
  if (Number.isNaN(parsed) || parsed < 1) return ''
  return value.replace(/[^0-9]/g, '')
}

export const floatNumber = (value) => value.replace(/[^0-9.]/g, '')

// get normalize date format DD-MM-YYYY
export const normalizeDDMMYYYY = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return `${onlyNums}-`
    }
    if (onlyNums.length === 4) {
      return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-`
    }
    if (onlyNums.length === 8) {
      return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-${onlyNums.slice(4, 8)}`
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`
  }
  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-${onlyNums.slice(4, 8)}`
}

export function isNextDay(sTime, eTime) {
  try {
    if (!sTime || !eTime) return false

    const startTime = replaceArabicAMPM(sTime).toLowerCase()
    const endTime = replaceArabicAMPM(eTime).toLowerCase()

    const getTime = (time) => {
      const hour = time?.split(':')[0].replace('12', 0)
      const minutes = time?.split(':')[1]

      return parseFloat(`${hour}.${minutes}`)
    }

    const startHours = getTime(startTime)
    const endHours = getTime(endTime)

    if (startTime?.includes('pm') && endTime?.includes('am')) {
      return true
    }
    if (
      (startTime?.includes('am') && endTime?.includes('am')) ||
      (startTime?.includes('pm') && endTime?.includes('pm'))
    ) {
      return startHours >= endHours
    }

    return false
  } catch (e) {
    return false
  }
}

export function get24hour(t = '') {
  const time = replaceArabicAMPM(t)
  const hour = parseInt(time.split(':')[0], 10)
  const minutes = parseInt(time.split(':')[1], 10)
  const ampm = time.split(' ')[1]?.toLowerCase()
  let increase = 0
  if (ampm === 'am' && hour === 12) increase = -12
  if (ampm === 'pm' && hour !== 12) increase = 12
  if ((ampm === 'am' && hour !== 12) || (ampm === 'pm' && hour === 12)) increase = 0

  return {
    hour: hour + increase,
    minutes,
  }
}

export function getDifferenceInHours(from, to, minus = 0) {
  try {
    const fromHours = get24hour(from)
    const toHours = get24hour(to)
    const toDay = isNextDay(from, to) ? 2 : 1
    const end = subMinutes(new Date(2000, 1, toDay, toHours.hour, toHours.minutes), minus)

    const results = intervalToDuration({
      start: new Date(2000, 1, 1, fromHours.hour, fromHours.minutes),
      end,
    })

    const total_minutes = results.minutes + results.hours * 60 + results.days * 1440

    return {
      ...results,
      total_minutes,
    }
  } catch (e) {
    return {
      total_minutes: 0,
    }
  }
}

export function timeToNumber(time) {
  const time24 = get24hour(time)
  const minutes = (time24.minutes * 100) / 60

  return parseFloat(`${time24.hour}.${minutes}`)
}

export function timeCompare(firs_time, operator, end_time) {
  if (!firs_time || !end_time) return undefined

  const first = timeToNumber(firs_time)
  const second = timeToNumber(end_time)

  const check = {
    '>': () => first > second,
    '<': () => first < second,
    '<=': () => first <= second,
    '>=': () => first >= second,
    '==': () => first === second,
  }

  return check?.[operator]()
}

export function timeAddHours(time, hours) {
  const time24 = get24hour(time)
  const sub = addHours(new Date(2000, 1, 1, time24.hour, time24.minutes), hours)

  return format(sub, 'hh:mm a')
}

export function timeAddMinutes(time, minutes) {
  if (!time) return ''
  const time24 = get24hour(time)
  const sub = addMinutes(new Date(2000, 1, 1, time24.hour, time24.minutes), minutes)

  return format(sub, 'hh:mm a')
}

export function timeSubMinutes(time, minutes) {
  if (!time) return ''
  const time24 = get24hour(time)
  const sub = subMinutes(new Date(2000, 1, 1, time24.hour, time24.minutes), minutes)

  return format(sub, 'hh:mm a')
}

export function timeToNumbers(time) {
  if (!time || typeof time !== 'string') return false

  const hour = parseInt(time?.split(':')[0], 10)
  const secondPart = time?.split(':')[1]?.split(' ')
  const minutes = parseInt(secondPart?.[0], 10)
  const ampm = secondPart?.[1]?.toLowerCase()
  let hour24 = ampm === 'pm' ? hour + 12 : hour
  hour24 = ampm === 'pm' && hour === 12 ? 12 : hour24
  hour24 = ampm === 'am' && hour === 12 ? 0 : hour24
  const totalMinutes = hour24 * 60 + minutes

  return {
    hour,
    hour24,
    minutes,
    totalMinutes,
    ampm,
  }
}

export function isValidTime(time) {
  if (!time) return false
  const numbers = timeToNumbers(time)

  if (!numbers) return false
  if (!numbers.hour) return false
  if (!numbers.minutes && numbers.minutes !== 0) return false
  if (numbers.hour > 24 || numbers.minutes > 60) return false

  return true
}

export function calculateBufferTime(start_time, end_time, is_next_day) {
  if (!start_time || !end_time) return false

  let bufferValue = timeAddHours(end_time, 4)

  if (
    !is_next_day &&
    timeCompare(start_time, '>', '01:30 am') &&
    timeCompare(end_time, '<=', '09:00 pm')
  ) {
    bufferValue = '11:59 pm'
  }

  return bufferValue
}

export const minuteToHumanReadable = (timeByMinutes) => {
  if (timeByMinutes === 0 || !timeByMinutes) return ''

  const minutes = Math.abs(timeByMinutes)
  const hours = Math.floor(minutes / 60)
  const minute = Math.floor(minutes % 60)

  let hour = ''
  let min = ''
  let result = ''

  if (hours > 0) {
    const hr_hrs = hours >= 3 && hours <= 10 ? 'hrs' : 'Hr'
    hour = hours < 3 ? I18n.t(`hr_${hours}`) : `${hours} ${I18n.t(hr_hrs).toLowerCase()}`
    result = hour
  }

  if (minute > 0) {
    const min_mins = minute >= 3 && minute <= 10 ? 'mins' : 'Min'

    min = minute < 3 ? I18n.t(`min_${minute}`) : `${minute} ${I18n.t(min_mins).toLowerCase()}`

    min = hours > 0 ? ` ${min}` : min
    min = hours > 0 && isRtl ? ` و ${min}` : min

    result += min
  }

  return result
}

/**
 * formats
 *  - d = days, return x days
 *  - h = hours, return x hrs
 *  - m = minutes, return x min
 *  - hh = hours, total count of hours
 */
export function durationByMinutes(min, is12Hour) {
  const minutes = Math.abs(+min)

  if (!minutes && minutes !== 0) return 0

  // Calculate hours
  let hours = Math.floor(minutes / 60)
  let ampm = is12Hour ? 'AM' : null

  // Calculate Minutes
  let mins = minutes % 60
  mins = `0${mins}`.slice(-2)

  const negative = min < 0 ? '-' : ''

  if (is12Hour && hours > 12) {
    hours -= 12
    ampm = 'PM'
  }

  let result = negative
  result += `${hours < 10 ? `0${hours}` : hours}:${mins}`

  if (ampm) {
    result = `${result} ${ampm}`
  }

  return result
}

export function increaseDecreaseTime(time, operation, value) {
  const t = timeToNumbers(time || '00:00')
  let minutes = t.totalMinutes

  if (operation === '+') minutes += value || 0
  if (operation === '-') minutes -= value || 0

  if (minutes < 0) minutes = 1440 + minutes

  const is12Hour = !!t.ampm

  return durationByMinutes(minutes, is12Hour)
}

export const timeFormat = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return `${onlyNums}:`
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }

  const tFormat = `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`

  if (tFormat.length === 5) {
    const time = timeToNumbers(tFormat)
    return durationByMinutes(time.totalMinutes)
  }

  return tFormat
}

export function getTimeDuration(from, to) {
  if (!from || !to) return false

  const minutes = getDifferenceInHours(from, to)?.total_minutes
  return minuteToHumanReadable(minutes)
}

export const padNumber = (number) => String(`0${number}`).slice(-2)
