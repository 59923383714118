import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TextInput = (props) => {
  const {
    className,
    icon,
    label,
    optionalLabel,
    disabled,
    style,
    message,
    isPending,
    error,
    testId,
    ...rest
  } = props
  const iconClass = icon ? 'with-icon' : ''
  const errorClass = error ? 'danger' : ''
  return (
    <div
      className={classNames(
        'jisr-form-group',
        isPending && 'jisr-input--pending',
        className,
        iconClass,
        errorClass,
      )}
      style={style}
    >
      {label && (
        <label className="label-field">
          {label}
          {optionalLabel && <span className="optional-label">{optionalLabel}</span>}
        </label>
      )}
      <input
        {...rest}
        className={className}
        style={style}
        data-testid={testId}
        disabled={disabled}
      />
      {error && <small className="form-text text-muted color-red">{error}</small>}
      {message && <small>{message}</small>}
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  optionalLabel: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  asText: PropTypes.bool,
  disabled: PropTypes.bool,
  isPending: PropTypes.bool,
  style: PropTypes.shape(),
  min: PropTypes.string,
  value: PropTypes.string,
  testId: PropTypes.string,
}

export default TextInput
