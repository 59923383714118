import { isValid } from 'date-fns'
import { format } from 'utils/date'

export const requestDateFormat = (date) =>
  isValid(new Date(date)) ? format(new Date(date), 'd MMM, yyyy') : '-'

export const requestsWithCancellationFlow = [
  'LeaveRequest',
  'BusinessTripRequest',
  'ExcuseRequest',
  'OvertimeRequest',
  'ExpenseClaimRequest',
  'MissingPunchRequest',
  'AssetRequest',
  'AssetClearRequest',
  'LoanRequest',
  'HiringRequest',
  'ResignationRequest',
  'LeaveResumptionRequest',
  'ExitReentryVisaRequest',
  'FlightTicketRequest',
]

export const getMonthDifference = (startDate, endDate) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear()) +
    1
  )
}

export const getSizeFromKB = (value) => {
  return Number(value?.substring(0, value?.indexOf('K') - 1)) * 1024
}
