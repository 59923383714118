export default {
  // A
  alpha_numeric_msg: 'Only alphanumeric characters',
  aol_msg: 'Really? You still use AOL for your email?',
  // B
  // C
  // D
  // E
  email_msg: 'عنوان البريد الإلكتروني غير صالح',
  // F
  fixed_length_validation_msg: ' يجب أن يتكون من {{len}} رموز',
  // G
  // H
  has_blank_spaces: 'يجب أن لا يكون هناك مساحات فارغة',

  // I
  invalid_date_msg: 'Invalid date',
  invalid_hijri_date_msg: 'Invalid hijri date',
  invalid_time: 'الوقت غير صحيح',
  invalid_url: 'العنوان غير صحيح',
  invalid_https_url: "Enter valid URL that starts with 'https://'",
  // J
  // K
  // L
  // M
  max_length: 'يجب ألا يزيد عدد الأحرف عن {{max}} حرف ',
  min_length_msg: 'يجب أن يكون {{min}} حروف أو أكثر',
  min_value_msg: 'يجب ان يكون على الاقل {{min}}',
  must_be_or_greater_than: 'يجب ان يساوي او اكثر من {{days}}',
  // N
  number_msg: 'يجب أن يحتوي على أرقام فقط',
  // O
  // P
  phone_number_msg: 'Invalid phone number, must be 10 digits',
  positive_msg: 'Must be a positive number',
  // Q
  // R
  // S
  start_with_msg: 'يجب ان يبدأ بـ {{start_with_digits}}',
  // T
  too_old_msg: 'You might be too old for this',
  space_not_allowed: 'يجب ان لايحتوي على اي مسافه',
  // U
  // V
  value_should_have_at_most: 'يجب أن تحتوي القيمة على {{count}} منزلة عشرية على الأكثر.',
  // W
  // X
  // Y
  // Z
  employee_name_length: 'لا يجب أن يتجاوز اسم الموظف 35 حرفًا',
}
