const Automations = {
  // A
  automated: 'Automated',
  automation: 'Automation',
  automation_name: 'Automation Name',
  add_a_trigger: 'Add a Trigger',
  automation_name_english: 'Automation name (English)',
  automation_name_arabic: 'Automation name (Arabic)',
  action_type: 'Action Type',
  action_details: 'Action Details',
  auto_approve: 'Auto Approve',
  auto_reject: 'Auto Reject',
  auto_approver_details: 'Auto Approver Details',
  auto_reject_details: 'Auto Reject Details',
  auto_reject_alert_description:
    'This action will automatically reject the request if approval is not received within the defined time frame. If no time frame is specified or the last approver is reached, the request will be immediately and automatically rejected',
  auto_approver_alert_description:
    'This action will automatically approve and move to the next approver. If the last approver is reached, the request will be automatically approved',
  announcement_details: 'Announcement Details',
  add_action: 'Add Action',
  add_condition: 'Add Condition',
  automation_for: 'For',
  automation_properties: 'Automation Properties',
  all_employees_receivers: 'All Employees',
  automation_lists: 'Automation Lists',
  automate_your_operations_desc: 'Automate your operations with efficient workflows.',
  approved_by_automation: 'Approved By Automation',
  automation_options: 'Automation Options',

  // B

  // C
  condition_details: 'Condition Details',
  condition: 'Condition',
  create_task: 'Create Task',
  contains: 'Contains',
  condition_and: 'AND',
  condition_yes: 'Yes',
  condition_no: 'No',
  cannot_upload_more_than_files: "You can't upload more than {{max}} files.",
  can_upload_a_maximum_of_files: 'You can upload a maximum of {{max}} files.',
  current_approver: 'Current approver',

  // D
  deleted_automation: 'Deleted',
  // E
  email_details: 'Email Details',
  enable_this_automation: 'Enable this Automation',
  edit_message: 'Edit Message',
  enable_this_if_you_want_the_automation:
    'Enable this if you want the automation to run just one time.',
  edit_name: 'Edit name',
  // F
  // G
  // H
  history_automation: 'History',

  // I
  if: 'IF',
  includes: 'Includes',

  // J
  // K
  // L
  last_run: 'Last Run',
  less_than_or_equal: 'Less than or equal',
  line_manager_approver: "Approver's line manager",

  // M
  more_than: 'More than',
  // N
  new_automation: 'New automation',
  notification_details: 'Notification Details',
  no_condition_path: 'No condition path',
  number_employee: '{{employees}}  Employees are assigned to this policy .',
  no_history_yet: 'No history yet!',
  never_married: 'Single',

  // O
  // P
  performed: 'Performed',
  please_select: 'Please Select',
  please_select_request_type: 'Please Select Request Type',
  personal_id: 'ID',
  pending_approval_days: 'Pending approval days',

  // Q
  // R
  runs: 'Runs',
  rename_this_automation: 'Rename this automation',
  remove_condition: 'Remove Condition',
  remove_no_condition_path: 'Remove no condition path',
  rejected_by_automation: 'Rejected By Automation',
  run_only_once: 'Run Only Once',

  // S
  search_by_automation_name: 'Search by automation name',
  suggested_triggers_for_you: 'Suggested triggers for you...',
  start_a_workflow_after_a_new_employee_gets_added:
    'Start a workflow after a new employee gets added',
  select_task: 'Select Task',
  subject: 'Subject',
  send_to: 'Send To',
  specify_user: 'Specify User',
  send_announcement: 'Send Announcement',
  send_email: 'Send Email',
  select_please: 'Select Please',
  search_by_template_name: 'Search by template name..',
  // T
  trigger_details: 'Trigger Details',
  triggers_are_the_starting_point_of_any_workflow:
    'Triggers are the starting point of any workflow. It defines when the workflow should start.',
  task_details: 'Task Details',
  triggered_employee: 'Triggered Employee',
  trigger: 'Trigger',
  // U
  // V
  // W
  workflow_name_en_required: 'Workflow name English is missing',
  workflow_name_ar_required: 'Workflow name Arabic is missing',
  write_the_title: 'Write The Title',
  write_message: 'Write Message',

  // X
  // Y
  // Z
}

export default Automations
