export const REQUEST_DETAILS_LOAD = 'REQUEST_DETAILS_LOAD'
export const REQUEST_DETAILS_LOAD_PENDING = 'REQUEST_DETAILS_LOAD_PENDING'
export const REQUEST_DETAILS_LOAD_FULFILLED = 'REQUEST_DETAILS_LOAD_FULFILLED'
export const REQUEST_DETAILS_LOAD_REJECTED = 'REQUEST_DETAILS_LOAD_REJECTED'

export const REQUEST_DETAILS_COMMENT = 'REQUEST_DETAILS_COMMENT'

export const ATTENDANCE_LOGS_LOAD = 'ATTENDANCE_LOGS_LOAD'
export const ATTENDANCE_LOGS_LOAD_PENDING = 'ATTENDANCE_LOGS_LOAD_PENDING'
export const ATTENDANCE_LOGS_LOAD_FULFILLED = 'ATTENDANCE_LOGS_LOAD_FULFILLED'
export const ATTENDANCE_LOGS_LOAD_REJECTED = 'ATTENDANCE_LOGS_LOAD_REJECTED'

export const RESET_REQUEST_DETAIL = 'RESET_REQUEST_DETAIL'
