import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import I18n from 'translations/i18n'
import { Modal } from '@jisr-hr/ds-beta'
import { leaveExtensionsPost } from 'redux/requests/leaveExtensionsRequest/actionCreators'
import { Form, Field } from 'react-final-form'

import { required } from 'components/global/form/FormValidations'
import AttachmentComponent from 'components/global/form/AttachmentComponent'
import { NewSelectField, TextAreaField } from 'components/final-form'
import DatePickerField from 'components/final-form/DatePickerField'

const LeaveExtensionsRequest = (props) => {
  const { requestId, employeeId, isOpen } = props

  const dispatch = useDispatch()

  const fetching = useSelector((s) => s.leaveExtensionsRequest.fetching)

  const submitRequestForm = async (values) => {
    await dispatch(
      leaveExtensionsPost(employeeId, {
        ...values,
        extended_request_id: requestId,
      }),
    )

    props.onDone?.()
    props.onCloseModal()
  }

  return (
    <Modal
      open={isOpen}
      onClose={props.onCloseModal}
      header={{ title: I18n.t('extensions_leave_request'), withClose: true }}
      size="large"
      footer={{
        submit: {
          label: I18n.t('apply'),
          size: 'medium',
          disabled: fetching,
          type: 'submit',
          formId: 'extend_leave_form',
        },
      }}
    >
      <div style={{ minWidth: 600 }}>
        <RequestForm
          initialValues={{
            extended_request_id: requestId,
          }}
          onSubmit={submitRequestForm}
        />
      </div>
    </Modal>
  )
}

const RequestForm = (props) => {
  const { initialValues } = props

  const leaveTypes = useSelector((s) => s.employeeLeaveTypes.leave_types, shallowEqual)

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={handleSubmit}
          id="extend_leave_form"
        >
          <div className="row">
            <div className="col">
              <NewSelectField
                name="leave_type_id"
                validate={required}
                valueKey="id"
                label={I18n.t('leave_type')}
                labelKey="name"
                options={leaveTypes}
              />
            </div>
            <div className="col">
              <DatePickerField
                name="return_date"
                validate={required}
                label={I18n.t('return_date')}
                textFieldProps={{
                  placeholder: I18n.t('assets_return_date'),
                }}
              />
            </div>
          </div>

          <div className="row mt-[18px]">
            <div className="col mb-4">
              <TextAreaField
                name="reason"
                label={I18n.t('reason')}
                height={140}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field name="attachment">
                {({ input, meta }) => (
                  <AttachmentComponent
                    input={input}
                    meta={meta}
                    onDone={(file) => form.change('attachment_file_name', file.file_file_name)}
                  />
                )}
              </Field>
            </div>
          </div>
        </form>
      )}
    />
  )
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
}

const idPropTypes = [PropTypes.string, PropTypes.number]

LeaveExtensionsRequest.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onDone: PropTypes.func,
  isOpen: PropTypes.bool,
  employeeId: PropTypes.oneOfType(idPropTypes),
  requestId: PropTypes.oneOfType(idPropTypes),
}

export default LeaveExtensionsRequest
