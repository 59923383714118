import { Toastr } from 'components/global/Toastr'
import { Employee } from 'redux/authUser/types'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export type TNotification = {
  attachment?: null
  category: string
  category_text: string
  category_value: number
  created_at: string
  data?: Record<string, never>
  employee: Employee
  id: number
  is_read: true | false
  message: string
  title?: string
  updated_at: string
  testId?: string
  handleView?: () => void
  openAnnouncement?: () => void
  handleATSNotification?: () => void
  handleClickViolation?: () => void
}

type TInitState = {
  fetching: boolean
  has_old_notification: boolean
  is_old_notifications: boolean
  isOpen: boolean
  notifications: TNotification[]
  eventsNotifications: TNotification[]
  pagination: PaginationTypes | Record<string, never>
  total_pending_team_requests: number
}

export const initialState: TInitState = {
  notifications: [],
  eventsNotifications: [],
  pagination: {},
  total_pending_team_requests: 0,
  fetching: false,
  isOpen: false,
  has_old_notification: false,
  is_old_notifications: false,
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { type, payload } = action
  const { pagination, has_old_notification, is_old_notifications } =
    action?.payload?.data?.data ?? {}
  switch (type) {
    case actions.GET_NOTIFICATIONS_PENDING:
    case actions.GET_EVENT_NOTIFICATIONS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_NOTIFICATIONS_FULFILLED:
      // const { pagination, has_old_notification, is_old_notifications } = payload.data.data
      const hasPreviousData =
        pagination?.previous_page || has_old_notification || is_old_notifications

      const isPage1 = payload.config.params.page === 1 && !payload.config.params.old_notifications

      return {
        ...state,
        notifications: [
          ...new Set([
            ...(hasPreviousData && !isPage1 ? state.notifications : []),
            ...(payload.data.data?.notifications ?? []),
          ]),
        ],
        pagination: payload.data.data?.pagination,
        total_pending_team_requests: payload.data.data.total_pending_team_requests,
        has_old_notification: payload.data.data.has_old_notification,
        is_old_notifications: payload.data.data.is_old_notifications,
        fetching: false,
      }

    case actions.GET_EVENT_NOTIFICATIONS_FULFILLED:
      const hasPreviousEvents =
        pagination?.previous_page || has_old_notification || is_old_notifications

      const isPage = payload.config.params.page === 1 && !payload.config.params.old_notifications
      return {
        ...state,
        eventsNotifications: [
          ...new Set([
            ...(hasPreviousEvents && !isPage ? state.eventsNotifications : []),
            ...(payload.data.data?.notifications ?? []),
          ]),
        ],
        pagination: payload.data.data?.pagination,
        total_pending_team_requests: payload.data.data.total_pending_team_requests,
        has_old_notification: payload.data.data.has_old_notification,
        is_old_notifications: payload.data.data.is_old_notifications,
        fetching: false,
      }

    case actions.GET_EVENT_NOTIFICATIONS_REJECTED:
    case actions.GET_NOTIFICATIONS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.TOGGLE_NOTIFICATIONS_PANEL:
      return {
        ...state,
        ...payload,
      }

    case actions.RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      }

    default:
      return state
  }
}
