import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import { Form } from 'react-final-form'
import { Typography } from '@jisr-hr/ds'
import ConfirmModalV2 from 'containers/authorised/NewOnboarding/components/ConfirmationModal'
import { SideDrawer } from '@jisr-hr/ds-beta'

import { Department, Form as FormValues } from 'redux/newDepartments/types'
import { isRtl } from 'utils/uiHelpers'
import { useDispatch, useSelector } from 'utils/hooks'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  departmentsDelete,
  hierarchicalDepartmentsLoad,
  listDepartmentsLoad,
} from 'redux/newDepartments/actionCreators'

import DefaultForm from './Form/DefaultForm'
import NewOnboardingForm from './Form/NewOnboardingForm'
import { useDepartmentsContext } from './context'

import ContentDeleteModal from './ContentDeleteModal'

export type FormTypes = {
  reset: () => void
}

export type TCompanyData = {
  id: number
  name: string | undefined
  name_ar: string | undefined
  name_i18n: string | undefined
  parent_id: null
}

export type InitialValuesType = {
  child_id?: number
  id?: number
  manager_id?: number
  name?: string
  name_ar?: string
  parent_id?: number
  disableNameFieldes?: boolean
  isCreateSupInFirstLevel?: boolean
}

type AddDepartmentFormProps = {
  data?: Department

  hideParentInput?: boolean
  initialValues?: InitialValuesType
  isOpen: boolean
  onSubmit: (values: FormValues, form: FormTypes) => void
  setIsOpen: (x: boolean) => void
  componyData?: TCompanyData
  isHighTier: boolean
}

const AddDepartmentForm = ({
  hideParentInput,
  initialValues,
  isOpen,
  onSubmit,
  setIsOpen,
  data,
  componyData,
  isHighTier,
}: AddDepartmentFormProps): JSX.Element => {
  const dispatch = useDispatch()

  const fetching = useSelector((s) => s.newDepartments.fetching)
  const { isNewOnboarding, isInOnboarding, search, setSearch } = useDepartmentsContext()

  const [open, setOpen] = useState(false)

  const canDelete = !(data?.number_of_employees !== 0)
  const hasEmployees = !!data?.number_of_employees
  const isNewExperience = isNewOnboarding

  const titleMessage = data?.number_of_employees
    ? i18n.t('department_has_employees')
    : i18n.t('department_has_subdepartments')

  const onClose = (): void => {
    setIsOpen(false)
    // rest the list and remove filters that applied useEffect
    dispatch(
      listDepartmentsLoad({
        ...(isNewOnboarding &&
          isHighTier && {
            filter: { source: 'sheet' },
          }),
      }),
    )
  }

  const handleDelete = (id: number | undefined): void => {
    dispatch(departmentsDelete(id!)).then(() => {
      if (isInOnboarding) {
        dispatch(organizationInfo())
      }
      setIsOpen(false)
      dispatch(
        hierarchicalDepartmentsLoad({
          ...(isNewExperience && {
            filter: { source: 'manual' },
          }),
        }),
      )
      dispatch(
        listDepartmentsLoad({
          ...(isNewExperience && {
            filter: { source: 'sheet' },
          }),
        }),
      )
      if (isNewExperience) {
        dispatch(listDepartmentsLoad({}, true))
      }
      setSearch({ ...search, deleted_id: id })
      setIsOpen(false)
    })
  }

  useEffect(() => {
    if (isOpen && ((!initialValues?.child_id && initialValues?.id) || !initialValues?.id)) {
      dispatch(
        listDepartmentsLoad({
          filter: {
            ...(initialValues?.id && { excluded_self_and_descendants: initialValues?.id }),
            ...(isNewOnboarding && isHighTier && { source: 'manual' }),
          },
        }),
      )
    }
  }, [isOpen])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }): JSX.Element => (
        <>
          <SideDrawer
            active={isOpen}
            onClose={(): void => {
              onClose()
              form.reset()
            }}
            header={{
              title: initialValues?.id ? i18n.t('edit_department') : i18n.t('add_new_department'),
              withClose: true,
            }}
            footer={{
              submit: {
                disabled: fetching,
                label: initialValues?.id ? i18n.t('label.save') : i18n.t('label.add'),
                onClick: (): void => {
                  handleSubmit()
                },
              },
              cancel: {
                label: i18n.t('label.cancel'),
                onClick: (): void => {
                  onClose()
                  form.reset()
                },
              },
              ...(!!initialValues?.id && {
                link: {
                  label: i18n.t('delete'),
                  variant: 'danger',
                  disabled: fetching,
                  onClick: (): void => {
                    setOpen(true)
                  },
                },
              }),
            }}
          >
            <>
              {isNewOnboarding ? (
                <NewOnboardingForm
                  componyData={componyData}
                  hideParentInput={hideParentInput}
                />
              ) : (
                <DefaultForm hideParentInput={hideParentInput} />
              )}
              <ConfirmModalV2
                fetching={fetching}
                isOpen={open && (!isNewExperience || (isNewExperience && !hasEmployees))}
                title={`${
                  canDelete ? `${i18n.t('label.delete')} ${data?.name_i18n}` : titleMessage
                }${isRtl ? '؟' : '?'}`}
                description={
                  <Typography
                    text={<ContentDeleteModal data={data} />}
                    style={{ margin: 24, marginBottom: 16 }}
                    variant="subheading"
                  />
                }
                titleConfirm="delete"
                onConfirm={(): void => handleDelete(initialValues?.id)}
                onClose={(): void => setOpen(false)}
              />
            </>
          </SideDrawer>
        </>
      )}
    />
  )
}

export default AddDepartmentForm
