import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { AttendanceReview } from './attendanceReviewTypes'

const initialState: AttendanceReview = {
  fetching: false,
  employees: [],
  selected_employee_id: 0,
}

export default function reducer(state = initialState, action: Action): AttendanceReview {
  switch (action.type) {
    case actions.ATTENDANCE_REVIEWERS_LOAD_PENDING:
    case actions.ATTENDANCE_REVIEWERS_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.ATTENDANCE_REVIEWERS_LOAD_FULFILLED:
      return {
        ...state,
        employees: action.payload.data.data.employees,
        selected_employee_id: action.payload.data.data.selected_employee_id,
        fetching: false,
      }
    case actions.ATTENDANCE_REVIEWERS_CREATE_FULFILLED:
      Toastr.success(action.payload.data && action.payload.data.message)
      return { ...state, fetching: false }

    case actions.ATTENDANCE_REVIEWERS_LOAD_REJECTED:
    case actions.ATTENDANCE_REVIEWERS_CREATE_REJECTED:
      Toastr.error(action.payload.data && action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
