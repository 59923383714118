import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function industriesLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/industries`

  return {
    type: actions.ONBOARDING_INDUSTRIRIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function providersLoad(providersType: string): Action {
  const url = `/${API_VERSION_TWO}/onboarding/${providersType}`

  return {
    type: actions.ONBOARDING_PROVIDERS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function createOnboardingProvider(providersType: string, body: { name: string }): Action {
  const url = `/${API_VERSION_TWO}/onboarding/${providersType}`
  return {
    type: actions.ONBOARDING_PROVIDERS_POST,
    payload: handleAPI(url, {}, 'POST', body),
  }
}
