import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TBankAccount = {
  id?: number
  iban?: string
  account_number?: string
  bank_id?: number
  bank_name?: string
  commercial_registration_id?: number
  cr_name_ar?: string
  bank_country_type?: {
    id?: number
    name_i18n?: string
  }
  mudad_supported?: boolean
  is_direct_bank_integrated?: boolean
  commercial_registration?: {
    registration_number?: string
    name_i18n?: string
    mol_number?: string
    mol_registration_number?: string
  }
  bank_logo?: string
}

const initialState: { loading: boolean; bank_account: TBankAccount } = {
  loading: false,
  bank_account: {},
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case actions.ONBOARDING_BANK_ACCOUNTS_CREATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.ONBOARDING_BANK_ACCOUNTS_DELETE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.ONBOARDING_BANK_ACCOUNTS_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.ONBOARDING_BANK_ACCOUNTS_CREATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        loading: false,
      }
    }

    case actions.ONBOARDING_BANK_ACCOUNTS_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        loading: false,
      }
    }

    case actions.ONBOARDING_BANK_ACCOUNTS_DELETE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.ONBOARDING_BANK_ACCOUNTS_DELETE_REJECTED:
    case actions.ONBOARDING_BANK_ACCOUNTS_UPDATE_REJECTED:
    case actions.ONBOARDING_BANK_ACCOUNTS_CREATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
