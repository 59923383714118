export const OBJECTIVE_SETS_LOAD = 'OBJECTIVE_SETS_LOAD'
export const OBJECTIVE_SETS_LOAD_PENDING = 'OBJECTIVE_SETS_LOAD_PENDING'
export const OBJECTIVE_SETS_LOAD_FULFILLED = 'OBJECTIVE_SETS_LOAD_FULFILLED'
export const OBJECTIVE_SETS_LOAD_REJECTED = 'OBJECTIVE_SETS_LOAD_REJECTED'

export const OBJECTIVE_SETS_BRIEF_LOAD = 'OBJECTIVE_SETS_BRIEF_LOAD'
export const OBJECTIVE_SETS_BRIEF_LOAD_PENDING = 'OBJECTIVE_SETS_BRIEF_LOAD_PENDING'
export const OBJECTIVE_SETS_BRIEF_LOAD_FULFILLED = 'OBJECTIVE_SETS_BRIEF_LOAD_FULFILLED'
export const OBJECTIVE_SETS_BRIEF_LOAD_REJECTED = 'OBJECTIVE_SETS_BRIEF_LOAD_REJECTED'

export const OBJECTIVE_SETS_CREATE = 'OBJECTIVE_SETS_CREATE'
export const OBJECTIVE_SETS_CREATE_PENDING = 'OBJECTIVE_SETS_CREATE_PENDING'
export const OBJECTIVE_SETS_CREATE_FULFILLED = 'OBJECTIVE_SETS_CREATE_FULFILLED'
export const OBJECTIVE_SETS_CREATE_REJECTED = 'OBJECTIVE_SETS_CREATE_REJECTED'

export const OBJECTIVE_SETS_SHOW = 'OBJECTIVE_SETS_SHOW'
export const OBJECTIVE_SETS_SHOW_PENDING = 'OBJECTIVE_SETS_SHOW_PENDING'
export const OBJECTIVE_SETS_SHOW_FULFILLED = 'OBJECTIVE_SETS_SHOW_FULFILLED'
export const OBJECTIVE_SETS_SHOW_REJECTED = 'OBJECTIVE_SETS_SHOW_REJECTED'

export const OBJECTIVE_SETS_UPDATE = 'OBJECTIVE_SETS_UPDATE'
export const OBJECTIVE_SETS_UPDATE_PENDING = 'OBJECTIVE_SETS_UPDATE_PENDING'
export const OBJECTIVE_SETS_UPDATE_FULFILLED = 'OBJECTIVE_SETS_UPDATE_FULFILLED'
export const OBJECTIVE_SETS_UPDATE_REJECTED = 'OBJECTIVE_SETS_UPDATE_REJECTED'

export const OBJECTIVE_SETS_WEIGHTAGE_UPDATE = 'OBJECTIVE_SETS_WEIGHTAGE_UPDATE'
export const OBJECTIVE_SETS_WEIGHTAGE_UPDATE_PENDING = 'OBJECTIVE_SETS_WEIGHTAGE_UPDATE_PENDING'
export const OBJECTIVE_SETS_WEIGHTAGE_UPDATE_FULFILLED = 'OBJECTIVE_SETS_WEIGHTAGE_UPDATE_FULFILLED'
export const OBJECTIVE_SETS_WEIGHTAGE_UPDATE_REJECTED = 'OBJECTIVE_SETS_WEIGHTAGE_UPDATE_REJECTED'

export const OBJECTIVE_SETS_DELETE = 'OBJECTIVE_SETS_DELETE'
export const OBJECTIVE_SETS_DELETE_PENDING = 'OBJECTIVE_SETS_DELETE_PENDING'
export const OBJECTIVE_SETS_DELETE_FULFILLED = 'OBJECTIVE_SETS_DELETE_FULFILLED'
export const OBJECTIVE_SETS_DELETE_REJECTED = 'OBJECTIVE_SETS_DELETE_REJECTED'
