import React from 'react'
import { Grid } from 'components/global/atoms'
import { Flex, Spacer } from '@jisr-hr/ds'
import { SelectField, DatePickerDSField, MultiSelectField } from 'components/final-form'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { useFormState, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { required } from 'components/global/form/FormValidations'

const BusinessTripDetails = () => {
  const { change } = useForm()
  const { values } = useFormState()
  const { cities } = useSelector(({ businessTripCities }) => businessTripCities)

  const additionsList = [
    { id: 1, label: I18n.t('accommodation') },
    { id: 2, label: I18n.t('transportation') },
    { id: 3, label: I18n.t('food') },
  ]

  return (
    <Frame title={I18n.t('business_trip_details')}>
      <Flex flexCol>
        <SelectField
          name="business_trip_city_id"
          labelKey="name"
          valueKey="id"
          options={cities}
          label={I18n.t('destination')}
          style={{ width: '100%' }}
          validate={required}
          placeholder={I18n.t('select_destination')}
        />
        <Spacer height={16} />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={6}
          >
            <DatePickerDSField
              name="leave_date"
              label={I18n.t('from')}
              validate={required}
              onChange={() => change('return_date', null)}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <DatePickerDSField
              calenderProps={{
                minDate: new Date(values?.leave_date),
              }}
              name="return_date"
              label={I18n.t('to')}
              validate={required}
              textFieldProps={{
                disabled: !values?.leave_date,
              }}
            />
          </Grid>
        </Grid>
        <Spacer height={12} />
        <MultiSelectField
          placeholder={I18n.t('select_services')}
          name="additions"
          label={I18n.t('allowances_alt_translation')}
          options={additionsList}
          labelKey="label"
          valueKey="id"
          returnType="value"
          minMenuHeight="100px"
        />
      </Flex>
    </Frame>
  )
}

export default BusinessTripDetails
