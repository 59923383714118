import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { GoalsType } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/ReviewForms/types'
import { PaginationTypes } from 'types/sharedResponse'
import { FormStatusType } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/type'
import { EvaluationDashboardType } from 'apis/Performance/evaluationDashboard/useEvaluationDashboardType'
import { InquiryQuestionsAndDestinations } from 'apis/Performance/evaluation_forms/questions_and_decisions/useQuestionsAndDecisions.type'
import * as actions from './actions'

export type EvaluationFormListType = {
  frequenc_i18n: string
  frequency: string
  id: number
  name_i18n: string
  status: FormStatusType
  status_i18n: string
  evaluation_start_date?: string
  evaluation_submission_date?: string
  is_reopened?: boolean
  type: string
  number_of_repetitions?: number
  review_required?: boolean
  created_by_id: number
  form_template_id?: number
  review_imported: boolean
}

export type StatusType = {
  completed: string
  draft: string
  active: string
  total: string
  upcoming: string
}

export type TaskCriteriaType = {
  name_en: string
  name_ar: string
  weight: string
  showAr?: boolean
  form_id?: number
  group_id?: number
  id?: number
  name_i18n?: string
}

export type taskGroupType = {
  criterions?: TaskCriteriaType[]
  id: number
  is_general: boolean
  name_ar: string
  name_en: string
  name_i18n: string
  rating_method: string
  rating_method_i18n: string
  weight: number
  form_id: number
  task: {
    note?: string
    assignee_id?: number
    submission_date?: string
  } | null
  status: string
  status_i18n: string
}

export type groupDetailsType = {
  status_i18n: string
  rating_method: string
  status: string
  task?: {
    note?: string
    assignee_id?: number
    submission_date?: string
    complated_at?: string
    rejection_date?: string
    rejection_reason?: string
  } | null
  name_i18n: string
  rating_method_i18n: string
  weight: number
  created_at: string
  created_by_id?: number | null
  criterions: {
    name_i18n: string
    weight: number
  }[]
}

export type FormInquiresType = {
  inquiry_id: number
  content_i18n: string
  description_i18n: string
  type: string
  is_visible_to_employee: boolean
  is_enabled: boolean
}

export type formDetailsType = {
  full_name_i18n: string
  name_i18n: string
  frequency: string
  frequency_i18n: string
  organization_id: number
  type_i18n: string
  type: string
  evaluation_start_date: string
  evaluation_submission_date: string
  created_at: string
  occurance_message_i18n: string
  groups: groupDetailsType[]
  status_i18n: string
  status: string
  description?: string
  is_reopened?: boolean
  number_of_repetitions: number
  following_form?: {
    status: string
  }
  review_required?: boolean
  created_by_id: number
  form_template_id?: number
  primary_reviewer?: {
    id: number
    value: string
    value_i18n: string
  }
  reminder_enabled?: boolean
  statistics: EvaluationDashboardType
  review_imported: boolean
  form_inquiry_mappings?: InquiryQuestionsAndDestinations[]
}

export type initialStateType = {
  taskGroup: taskGroupType | null
  deleted_group_ids?: GoalsType['deleted_group_ids']
  fetching: boolean
  pagination?: PaginationTypes
  evaluationList: EvaluationFormListType[]
  assignEmployeeLoading: boolean
  status?: StatusType
  evaluationFormDetails: formDetailsType | Record<string, never>
  evaluationFormDetailsLoading: boolean
  overlappedEmployees?: {
    ineligible_employees_ids?: number[]
    messages?: string[]
  } | null
  assigneeIds?: number[]
  lastAssigneeId?: number
  dataMigrationStatus?: 'in_progress' | 'completed'
  reminder_enabled?: boolean
}

const initialState: initialStateType = {
  fetching: false,
  assignEmployeeLoading: false,
  evaluationList: [],
  evaluationFormDetails: {},
  evaluationFormDetailsLoading: false,
  overlappedEmployees: null,
  taskGroup: null,
}

export default function reducer(state = initialState, action: Action): initialStateType {
  const { payload } = action

  switch (action.type) {
    case actions.REMOVE_EMPLOYEE_REVIEW_FROM_PENDING:
    case actions.REQUEST_EVALUATION_REVIEW_PENDING:
    case actions.GET_GROUP_DETAILS_PENDING:
    case actions.SAVE_TASK_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ASSIGN_EMPLOYEES_EVALUATION_PENDING:
      return {
        ...state,
        assignEmployeeLoading: true,
      }

    case actions.GET_EVALUATION_DETAILS_PENDING:
      return {
        ...state,
        evaluationFormDetailsLoading: true,
        fetching: true,
      }

    case actions.GET_GROUP_DETAILS_FULFILLED:
      return {
        ...state,
        fetching: false,
        taskGroup: payload?.data?.data,
      }

    case actions.SAVE_TASK_FULFILLED:
      Toastr.success(payload?.data?.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_EVALUATION_DETAILS_FULFILLED:
      return {
        ...state,
        evaluationFormDetailsLoading: false,
        fetching: false,
        evaluationFormDetails: payload?.data?.data?.details,
      }

    case actions.RESET_TASK:
      return {
        ...state,
        taskGroup: null,
      }

    case actions.ASSIGN_EMPLOYEES_EVALUATION_FULFILLED:
      Toastr.success(payload?.data?.data?.message)
      return {
        ...state,
        assignEmployeeLoading: false,
      }

    case actions.EMPLOYEES_REVIEWS_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.REMOVE_EMPLOYEE_REVIEW_FROM_FULFILLED:
    case actions.REQUEST_EVALUATION_REVIEW_FULFILLED:
      Toastr.success(payload?.data?.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_EVALUATION_DETAILS_REJECTED:
    case actions.ASSIGN_EMPLOYEES_EVALUATION_REJECTED:
    case actions.REMOVE_EMPLOYEE_REVIEW_FROM_REJECTED:
    case actions.REQUEST_EVALUATION_REVIEW_REJECTED:
    case actions.SAVE_TASK_REJECTED:
    case actions.GET_GROUP_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        assignEmployeeLoading: false,
        evaluationFormDetailsLoading: false,
      }

    default:
      return state
  }
}
