const BenefitsManagement = {
  // A
  add_endorsments: 'Add Endorsments',
  add_endorsment_verify: 'Verify',
  add_endorsment_cancel: 'Cancel',
  add_endorsment_continue: 'Continue',
  add_endorsment_save_close: 'Save & Close',
  assign_policy: 'Assign Policy',
  all_changes_saved: 'All changes saved',
  add_endorsement: 'Add Endorsement',
  add_dependent: 'Add Dependent',
  action_required: 'Action Required',
  active_bill_notice: 'Active Bill Notice',
  active_bill_msg:
    'You can only generate a new bill once the active one expires. Each bill is valid for two weeks.',
  attachment_type_list: 'PDF or JPG (max. 10mb)',
  add_another_endorsement: 'Add another endorsement',
  action_required_msg:
    "Please visit Tawuniya's portal to address the required action on request number {{request_num}}",
  add_dependent_title: 'What is the dependent relation?',
  adjustment_by_mistake: 'Adjustment by mistake',
  available_classes: 'Available classes',

  // B
  benefits_management: 'Benefits Management',
  benefits_management_insurance: 'Insurance',
  benefits_management_health_insurance: 'Health Insurance',
  benefits_management_policies: 'Policies',
  benefits_management_class: 'Class',
  back_to_insurance: 'Back to Insurance',
  back_to_policies: 'Back to Policies',
  sadad_bill_number: 'SADAD Invoice Number',
  benefits_management_providers: 'Providers',
  back_to_providers: 'Back to Providers',

  // C
  cancel_add_endorsement_msg: 'Are you sure you want cancelling adding this employee?',
  card_code: 'Card Code',
  cancel_dependent_msg: 'Are you sure you want to delete this dependent?',
  connect_policy: 'Connect Policy',
  change_member_id: 'Change member ID',
  change_member_class: 'Change member class',
  copy_of_identity: 'Copy of identity',
  choose_nationality: 'Choose nationality',
  copy_of_iqama: 'Copy of IQAMA',
  cancellation_reason_label: 'What’s the cancellation reason?',
  cancellation_reason_placeholder: 'Select the reason',
  choose_class: 'Choose class',
  change_class_upgrade_title: 'What’s the reason of upgrade?',
  change_class_downgrade_title: 'What’s the reason of downgrade?',
  change_class_current_class: 'Current Class',
  change_class_upgrade: 'Upgrade',
  change_class_downgrade: 'Downgrade',
  change_class_hd_msg:
    'Kindly be noted that you need to review all the health declarations for each member.',
  // D
  dependent: 'Dependent',
  dependents: 'Dependents',
  download_document: 'Download Document',
  dependents_relation: "Dependent's Relation",
  dependent_national_id_iqama: 'Dependent National ID / Iqama',
  dependent_info: 'Dependent Info',
  delete_member: 'Delete member',
  delete_member_msg: 'Please provide the reason of deletion.',
  delete_member_alert_title: 'Deleting this member means that all dependents will be too.',
  delete_member_alert_msg:
    'This action is permanent and cannot be undone verify first to continue with employee deletion.',
  demotion_letter: 'Demotion letter',

  // E
  endorsements: 'Endorsements',
  external_nationalities: 'Nationalities',
  endorsement_details: 'Endorsement Details',
  endorsement_payment: 'Payment',
  endorsement_due_date: 'Due Date',
  employee_national_id_iqama: 'Employee National ID / Iqama',
  edit_member: 'Edit member',
  edit_member_load_details: 'Load details',
  endorsement_request_type: 'Request Type',
  endorsement_member_type: 'Membership Type',

  // F

  // G
  gcc_id_or_passport_no: 'GCC ID/Passport No',
  gosi_print: 'GOSI Print',
  generate_bill: 'Generate Bill',

  // H
  health_declaration: 'Health Declaration',

  // I
  insurance_sub_title: 'Get insured within minutes!',
  insurance_status: 'Insurance Status',
  insurance_policy: 'Insurance Policy',
  insurance_type: 'Insurance Type',
  insurance_id: 'ID',
  insurance_details: 'Insurance Details',
  insurance_filter_lable: 'Filters',
  insurance_effective_date: 'Effective date',
  insurance_statuse_draft: 'Draft',
  insurance_statuse_completed: 'Completed',
  insurance_statuse_rejected: 'Rejected',
  insurance_statuse_pending_approval: 'Pending Approval',
  insurance_policy_class: 'Classes',
  insurance_policy_number: 'Policy Number',
  insurance_full_name_en: 'Full name (English)',
  is_eligible_to_enroll: 'Policy eligibility',

  // J
  job_demotion: 'Job Demotion',

  // L
  load_employee_details: 'Load Employee Details',
  linked: 'Added',

  // M
  member_card_code: 'Member Card Code',
  member_full_name: 'Member full name',
  member_id: 'Member ID',
  members: 'Members',
  member: 'Member',
  member_details: 'Member Details',
  member_employees_count: '{{count}} Employees',
  member_dependents_count: '{{count}} Dependents',
  members_not_linked_with_jisr: 'Insured employees count who are not added yet on {{app_name}}',
  is_member_linked: 'ID Status on ',
  membership_type: 'Membership Type',

  // N
  natural_numbers_validation_msg: 'Numerical value only is accepted',
  no_endorsements_yet: 'No endorsements yet!',
  no_policies_yet: 'No policies yet!',
  not_linked_member_info:
    "This insured employee ID doesn't exist on {{app_name}}. Please add them as a New Employee",
  not_linked: 'Not added',
  no_active_policies_msg: 'Connect a policy first',
  nationality_attachment: 'Attachment/ copy of nationality',
  new_iqama_no: 'IQAMA new No.',
  new_iqama_placeholder: 'Enter the new IQAMA number',
  new_job_promotion: 'New job promotion',
  no_providers_yet: 'No providers yet!',

  // O
  offboarding_insurance_permission_msg:
    'Medical Insurance permission is required to proceed with the action',
  offboarding_insurance_endorsement_msg:
    'This employee has an active endorsement request, please contact {{provider_name}} to cancel it',
  offboard_deleted_msg: 'Action has already been completed',

  // P
  policy_details: 'Policy Details',
  premium: 'Premium',
  policy_document: 'Policy Document',
  pending_payment: 'Pending Payment',
  promotion_letter: 'Promotion letter',

  // Q

  // R
  reation: 'Reation',
  rejection_reasons: 'Rejection Reasons',
  resubmit_msg: 'Are you sure you want to resubmit the attached file(s)?',
  resubmit: 'Resubmit',
  request_type: 'Request Type',
  review_actions: 'Review actions',

  // S
  select_policy: 'Select Policy',
  select_class: 'Select Class',
  save_dependent: 'Save Dependent',
  sponsor_no: 'Sponsor No.',
  submit_endo_heading: 'Your add member request {{number}} has been submitted successfully',
  submit_endo_subHeading:
    'The request will be reviewed by the “{{provider_name}}” team and a response to you within one working days.',
  sync_endosements_statuses_tooltip: "Sync employees' statuses",
  sync_policies_tooltip: "Sync employees' classes with policies",
  sync_members_tooltip: 'Sync insured employees associated with this policy',
  submit_endorsement_msg: 'Are you sure you want to submit this endorsement?',
  submit_downgrade_confirmation_msg:
    'The class downgrade request will take effect immediately once it is submitted. Are you sure you would like to proceed?',
  // T
  the_member: 'Member',
  total_members: 'Members ({{count}})',

  // U
  under_review: 'Under Review',

  // V
  verification: 'Verification',
  validate_reason: 'Validate reason',

  // W

  // X

  // Y
  your_changes_will_be_saved: 'Your changes will be saved',

  // Z
}

export default BenefitsManagement
