import React from 'react'
import { PropTypes } from 'prop-types'
import { Badge, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'

export const BaseBadge = (props) => (
  <Flex style={{ margin: 4, textOverflow: 'ellipsis' }}>
    <Badge
      size="small"
      variant="tinted"
      label={props.label ?? '-'}
      {...props}
    />
  </Flex>
)

export const TypeBadge = ({ color, label, ...rest }) => (
  <BaseBadge
    color={color}
    label={label}
    {...rest}
  />
)

TypeBadge.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
}

TypeBadge.defaultProps = {
  color: 'primary',
}

export const InfoBadge = (props) => (
  <BaseBadge
    color="gray"
    {...props}
  />
)
export const CancellationBadge = (props) => (
  <BaseBadge
    color="gray-light"
    label={I18n.t('cancellation')}
    {...props}
  />
)

export const DateBadge = (props) => (
  <InfoBadge
    leadingIcon="calendar"
    {...props}
  />
)
export const ReasonBadge = ({ label, ...rest }) => (
  <InfoBadge
    leadingIcon="message-question-circle"
    label={typeof label === 'string' && label.length > 22 ? `${label?.substring(0, 20)}...` : label}
    {...rest}
  />
)

ReasonBadge.propTypes = TypeBadge.propTypes

export const TimeBadge = (props) => (
  <InfoBadge
    leadingIcon="clock"
    {...props}
  />
)

export const MoneyBadge = (props) => (
  <InfoBadge
    data-private
    leadingIcon="bank-note-03"
    {...props}
  />
)

export const ItemBadge = (props) => (
  <InfoBadge
    leadingIcon="file-check-02"
    {...props}
  />
)

export const TravelBadge = (props) => (
  <InfoBadge
    leadingIcon="plane"
    {...props}
  />
)

export const DocumentBadge = (props) => (
  <InfoBadge
    leadingIcon="file-05"
    {...props}
  />
)

export const LetterBadge = (props) => (
  <InfoBadge
    leadingIcon="mail-02"
    {...props}
  />
)

export const HiringPositionBadge = (props) => (
  <InfoBadge
    leadingIcon="luggage-03"
    {...props}
  />
)

export const AttachmentBadge = (props) => (
  <InfoBadge
    leadingIcon="paperclip"
    {...props}
  />
)
