export const NETSUIT_ACCOUNT_TRANSACTION_SYNC = 'NETSUIT_ACCOUNT_TRANSACTION_SYNC'
export const NETSUIT_ACCOUNT_TRANSACTION_SYNC_FULFILLED =
  'NETSUIT_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const NETSUIT_ACCOUNT_TRANSACTION_SYNC_REJECTED = 'NETSUIT_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const NETSUIT_ACCOUNT_TRANSACTION_SYNC_PENDING = 'NETSUIT_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const NETSUIT_ACCOUNT_BULK_UPDATE = 'NETSUIT_ACCOUNT_BULK_UPDATE'
export const NETSUIT_ACCOUNT_BULK_UPDATE_FULFILLED = 'NETSUIT_ACCOUNT_BULK_UPDATE_FULFILLED'
export const NETSUIT_ACCOUNT_BULK_UPDATE_REJECTED = 'NETSUIT_ACCOUNT_BULK_UPDATE_REJECTED'
export const NETSUIT_ACCOUNT_BULK_UPDATE_PENDING = 'NETSUIT_ACCOUNT_BULK_UPDATE_PENDING'

export const NETSUIT_ACCOUNTS_LOAD = 'NETSUIT_ACCOUNTS_LOAD'
export const NETSUIT_ACCOUNTS_LOAD_FULFILLED = 'NETSUIT_ACCOUNTS_LOAD_FULFILLED'
export const NETSUIT_ACCOUNTS_LOAD_REJECTED = 'NETSUIT_ACCOUNTS_LOAD_REJECTED'
export const NETSUIT_ACCOUNTS_LOAD_PENDING = 'NETSUIT_ACCOUNTS_LOAD_PENDING'

export const NETSUIT_SPECIFIC_ACCOUNT_LOAD = 'NETSUIT_SPECIFIC_ACCOUNT_LOAD'
export const NETSUIT_SPECIFIC_ACCOUNT_LOAD_FULFILLED = 'NETSUIT_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const NETSUIT_SPECIFIC_ACCOUNT_LOAD_REJECTED = 'NETSUIT_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const NETSUIT_SPECIFIC_ACCOUNT_LOAD_PENDING = 'NETSUIT_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const NETSUIT_SPECIFIC_ACCOUNT_DELETE = 'NETSUIT_SPECIFIC_ACCOUNT_DELETE'
export const NETSUIT_SPECIFIC_ACCOUNT_DELETE_FULFILLED = 'NETSUIT_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const NETSUIT_SPECIFIC_ACCOUNT_DELETE_REJECTED = 'NETSUIT_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const NETSUIT_SPECIFIC_ACCOUNT_DELETE_PENDING = 'NETSUIT_SPECIFIC_ACCOUNT_DELETE_PENDING'
