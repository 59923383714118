export const BILLING_NOTICES_LOAD = 'BILLING_NOTICES_LOAD'
export const BILLING_NOTICES_LOAD_FULFILLED = 'BILLING_NOTICES_LOAD_FULFILLED'

export const BILLING_MANGER_INFO_LOAD = 'BILLING_MANGER_INFO_LOAD'
export const BILLING_MANGER_INFO_LOAD_PENDING = 'BILLING_MANGER_INFO_LOAD_PENDING'
export const BILLING_MANGER_INFO_LOAD_FULFILLED = 'BILLING_MANGER_INFO_LOAD_FULFILLED'
export const BILLING_MANGER_INFO_LOAD_REJECTED = 'BILLING_MANGER_INFO_LOAD_REJECTED'

export const BILLING_NEW_OR_CANCEL_REQUEST_LOAD = 'BILLING_NEW_OR_CANCEL_REQUEST_LOAD'
export const BILLING_NEW_OR_CANCEL_REQUEST_LOAD_PENDING =
  'BILLING_NEW_OR_CANCEL_REQUEST_LOAD_PENDING'
export const BILLING_NEW_OR_CANCEL_REQUEST_LOAD_FULFILLED =
  'BILLING_NEW_OR_CANCEL_REQUEST_LOAD_FULFILLED'
export const BILLING_NEW_OR_CANCEL_REQUEST_LOAD_REJECTED =
  'BILLING_NEW_OR_CANCEL_REQUEST_LOAD_REJECTED'

export const BILLING_NEW_OR_CANCEL_REQUEST_POST = 'BILLING_NEW_OR_CANCEL_REQUEST_POST'
export const BILLING_NEW_OR_CANCEL_REQUEST_POST_PENDING =
  'BILLING_NEW_OR_CANCEL_REQUEST_POST_PENDING'
export const BILLING_NEW_OR_CANCEL_REQUEST_POST_FULFILLED =
  'BILLING_NEW_OR_CANCEL_REQUEST_POST_FULFILLED'
export const BILLING_NEW_OR_CANCEL_REQUEST_POST_REJECTED =
  'BILLING_NEW_OR_CANCEL_REQUEST_POST_REJECTED'
