import { Dispatch } from 'react'
import { Typography, Spacer, Flex, Checkbox } from '@jisr-hr/ds'
import { Table, CustomColumnDef } from '@jisr-hr/ds-beta'
import I18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'
import { TExtendsEmployee } from 'redux/organizationOnborading/employees/employees.type'
import { TDeleteValues } from '../../EmployeeData'
import styles from './style.module.css'

export type TPropsrTableEmployeeData = {
  employees: TExtendsEmployee[]
  listChecked: number[]
  setInitialValues: Dispatch<React.SetStateAction<Partial<TExtendsEmployee> | null>>
  setDeleteValues: (v: React.SetStateAction<TDeleteValues>) => void
  handleCheckbox: ({ isChecked, id }: { isChecked: boolean; id: number }) => void
}

type RowDataType = { row: { original: TExtendsEmployee } }

const TableEmployeeData = (props: TPropsrTableEmployeeData): JSX.Element => {
  const { employees, listChecked } = props

  const columns: CustomColumnDef[] = [
    {
      id: 'emp_id',
      header: '',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Checkbox
            active={!!listChecked.find((emp) => emp === employee.id)}
            onChange={(event): void =>
              props.handleCheckbox({ isChecked: event.target.checked, id: employee.id })
            }
          />
        )
      },
    },
    {
      id: 'emp_id',
      header: I18n.t('emp_id'),
      width: '80px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.code}
          />
        )
      },
    },
    {
      id: 'name_in_english',
      header: I18n.t('name_in_english'),
      width: '180px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.full_name_en}
          />
        )
      },
    },
    {
      id: 'name_in_arabic',
      header: I18n.t('name_in_arabic'),
      width: '180px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.full_name_ar}
          />
        )
      },
    },
    {
      id: 'id_iqama_border_number',
      header: I18n.t('id_iqama_border_number'),
      width: '150px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.document_number}
          />
        )
      },
    },
    {
      id: 'nationality',
      header: I18n.t('nationality'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.nationality?.name_i18n}
          />
        )
      },
    },
    {
      id: 'joining_date',
      header: I18n.t('joining_date'),
      width: '110px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.joining_date}
          />
        )
      },
    },
    {
      id: 'location',
      header: I18n.t('location'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.location?.address_i18n}
          />
        )
      },
    },
    {
      id: 'department',
      header: I18n.t('department'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.department?.name_i18n}
          />
        )
      },
    },
    {
      id: 'employment_type',
      header: I18n.t('employment_type'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.employment_type?.name_i18n}
          />
        )
      },
    },
    {
      id: 'outsourcing_company',
      header: I18n.t('outsourcing_company'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.outsourcing_company?.name_i18n}
          />
        )
      },
    },
    {
      id: 'line_manager',
      header: I18n.t('line_manager'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.line_manager?.name_i18n}
          />
        )
      },
    },
    {
      id: 'email',
      header: I18n.t('email'),
      width: '200px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.email}
          />
        )
      },
    },
    {
      id: 'job_title',
      header: I18n.t('job_title'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.job_title?.name_i18n}
          />
        )
      },
    },
    {
      id: 'business_unit',
      header: I18n.t('business_unit'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.business_unit?.name_i18n}
          />
        )
      },
    },
    {
      id: 'gender',
      header: I18n.t('gender'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.gender_i18n}
          />
        )
      },
    },
    {
      id: 'marital_status',
      header: I18n.t('marital_status'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.marital_status_i18n}
          />
        )
      },
    },
    {
      id: 'religion',
      header: I18n.t('religion'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={
              employee.identification_info.religion &&
              (employee.identification_info.religion === 'Muslim'
                ? I18n.t('muslim')
                : I18n.t('non_muslim'))
            }
          />
        )
      },
    },
    {
      id: 'telephone',
      header: I18n.t('telephone'),
      width: '150px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.telephone}
          />
        )
      },
    },
    {
      id: 'date_of_birth',
      header: I18n.t('date_of_birth'),
      width: '110px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.date_of_birth}
          />
        )
      },
    },
    {
      id: 'passport_number',
      header: I18n.t('passport_number'),
      width: '200px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.identification_info.passport_number}
          />
        )
      },
    },
    {
      id: 'passport_expiry_date',
      header: I18n.t('passport_expiry_date'),
      width: '110px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.identification_info.passport_expiry_date}
          />
        )
      },
    },
    {
      id: 'iqama_issue_date',
      header: I18n.t('iqama_issue_date'),
      width: '110px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.identification_info.iqama_issue_date}
          />
        )
      },
    },
    {
      id: 'id_expiry_date',
      header: I18n.t('id_expiry_date'),
      width: '110px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.identification_info.iqama_expiry_date}
          />
        )
      },
    },
    {
      id: 'not_in_saudi',
      header: I18n.t('not_in_saudi'),
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={
              employee.identification_info.not_in_saudi.toString() === 'true'
                ? I18n.t('yes')
                : I18n.t('no')
            }
          />
        )
      },
    },
    {
      id: 'employer_number',
      header: I18n.t('employer_number'),
      width: '200px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.identification_info.employer_number}
          />
        )
      },
    },
    {
      id: 'iqama_profession_en',
      header: I18n.t('iqama_profession_en'),
      width: '200px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.iqama_profession}
          />
        )
      },
    },
    {
      id: 'iqama_profession_ar',
      header: I18n.t('iqama_profession_ar'),
      width: '200px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={employee.iqama_profession_ar}
          />
        )
      },
    },
    {
      id: 'actions',
      header: '',
      width: '80px',
      cell: (rowData: RowDataType) => {
        const employee = rowData.row.original
        return (
          <Flex className={styles.action}>
            <DSIcon
              name="trash-01"
              size="ml"
              className={styles.icon}
              onClick={(): void =>
                props.setDeleteValues((prev) => ({
                  ...prev,
                  confirmModaValues: [employee.id],
                }))
              }
            />
            <Spacer width={16} />
            <DSIcon
              name="edit-02"
              size="md"
              className={styles.icon}
              onClick={(): void => {
                props.setInitialValues(employee)
              }}
            />
          </Flex>
        )
      },
    },
  ]

  return (
    <Table
      data={employees}
      columns={columns}
      pinColums="both"
      tableSize={{
        maxHeight: 515,
      }}
    />
  )
}

export default TableEmployeeData
