import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type PriceRange = {
  starting_unit: number
  ending_unit: number | null
  price: number
}[]

export type TPlanDuration = {
  id: string
  item_family_id: string
  item_id: string
  item_type: string
  name: string
  pricing_tiers: PriceRange
}

type StateType = {
  fetching: boolean
  durations: TPlanDuration[]
}

const initialState: StateType = {
  fetching: false,
  durations: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.PLAN_DURATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PLAN_DURATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.PLAN_DURATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
