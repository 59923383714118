import { addMonths, differenceInMonths, format, subDays } from 'date-fns'
import { useMemo } from 'react'
import { useSelector } from 'utils/hooks'

export const calculateNewDate = (from: Date | string, to: Date | string): string | undefined => {
  const dateFrom = new Date(from)
  const newDate = subDays(new Date(addMonths(new Date(dateFrom), 6)), 1)
  const dateTo = new Date(to)
  if (Math.abs(differenceInMonths(+dateFrom, +dateTo)) >= 6) {
    return format(new Date(newDate), 'yyyy-MM-dd')
  }
  return undefined
}

export const maxDate = (from: Date): Date => {
  return subDays(new Date(addMonths(from, 6)), 1)
}

// helper function to get years options
// from the company creation year to the current year
export const useGetYearsOptions = () => {
  const company = useSelector((a) => a?.auth?.company)

  return useMemo(() => {
    const startedYear = new Date(company?.created_at ?? '').getFullYear()
    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: currentYear - startedYear + 1 }, (_, i) => {
      const year = startedYear + i
      return { label: year.toString(), value: +year, id: +year }
    })
    return years.sort((a, b) => Number(b.value) - Number(a.value))
  }, [company?.created_at])
}
