import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function createDelegationRequests(employee_id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/delegation_requests`
  const method = 'POST'
  return {
    type: actions.CREATE_DELEGATION_REQUEST,
    payload: handleAPI(url, {}, method, { delegation_request: data }),
  }
}

export function updateDelegationRequests(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/delegation_requests/${id}`
  const method = 'PUT'
  return {
    type: actions.UPDATE_DELEGATION_REQUEST,
    payload: handleAPI(url, {}, method, { delegation_request: data }),
  }
}

export function applicableDelegatorsRequestLoad(employee_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/applicable_delegators`
  const method = 'GET'
  return {
    type: actions.APPLICABLE_DELEGATORS_REQUEST_LOAD,
    payload: handleAPI(url, {}, method),
  }
}
