import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'react-redux'
import {
  hiringRequestCreate,
  hiringRequestUpdate,
} from 'redux/requests/hiringRequests/actionCreators'
import I18n from 'translations/i18n'
import { HiringDetails, Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { TitleWithOptionalText } from '../../../RequestDrawer/components/forms/HiringDetails/HiringDetails'
import { useRequestDetail } from '../../hooks'

const HiringRequest = () => {
  const dispatch = useDispatch()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = request?.employee?.id

  const onSubmit = (val) => {
    const data = {
      ...val,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }

    if (val.id) {
      return dispatch(hiringRequestUpdate(empId, val.id, data)).then(() => {
        setEditRequest(false)
      })
    }
    return dispatch(hiringRequestCreate(id, val)).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback(id, val)
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({ ...request, deletedAttachments: [] })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={{ ...employee, name: employee?.name_i18n }} />
      <HiringDetails />
      <Reason
        title={
          <TitleWithOptionalText
            title={I18n.t('label.notes_optional')}
            optionalText={I18n.t('label.optional')}
            variant="title-1"
          />
        }
        hideFileUpload
        fieldPlaceholder="Enter your notes here"
      />
    </Flex>
  )
}

export default HiringRequest
