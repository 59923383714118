import { Typography, Divider, Flex, Spacer } from '@jisr-hr/ds'
import { DatePickerDSField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import { useForm, useFormState } from 'react-final-form'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'

import LeaveBalanceTemplate from './LeaveBalanceTemplate'
import Styles from './AnnualLeave.module.css'

const SelectRange = (): JSX.Element => {
  const { values } = useFormState()
  const { change } = useForm()

  const balanceVals = useSelector((s) => {
    const detail = s.leaveRequests?.leave_requests_detail
    return {
      applied_days: detail?.applied_days,
      pending_balance: detail?.pending_balance,
      requested_days: detail?.requested_days,
    }
  }, shallowEqual)

  return (
    <Flex
      flexCol
      style={{ gap: 16, width: '100%' }}
    >
      <Flex
        style={{ gap: 8, width: '100%' }}
        className={Styles.datePickerField}
      >
        <DatePickerDSField
          name="from"
          label={i18n.t('first_day_off')}
          onChange={(): void => {
            change('to', null)
          }}
          validate={required}
        />
        <Spacer
          width="13px"
          height="2px"
          style={{
            background: 'var(--color-natural-gray-darker-0-new)',
            marginTop: '42px',
            minWidth: 13,
          }}
        />
        <DatePickerDSField
          name="to"
          label={i18n.t('last_day_off')}
          calenderProps={{
            minDate: new Date(values?.from),
          }}
          textFieldProps={{ disabled: !values?.from }}
          validate={required}
        />
      </Flex>
      <Divider direction="horizontal" />
      <Flex
        flexCol
        style={{ gap: 8, paddingInlineStart: 16 }}
      >
        <Typography
          className={Styles.bulletPoint}
          variant="subtitle-1"
          text={i18n.t('this_will_be_deducted_from_your_annual_leave_balance')}
          style={{
            color: 'var(--color-natural-gray-dark-new)',
          }}
        />
      </Flex>
      {values?.to && <LeaveBalanceTemplate {...balanceVals} />}
    </Flex>
  )
}

export default SelectRange
