import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as MoneyIcon } from 'assets/figma-icons/Money.svg'
import I18n from 'translations/i18n'
import { SwitchField } from 'components/final-form'
import { useFormState } from 'react-final-form'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { Tooltip } from '@jisr-hr/ds-beta'

const AdvancedVacationSalary = (props) => {
  const { disabled, disableMessage } = props
  const { values } = useFormState()

  return (
    <Frame
      title={I18n.t('advanced_vacation_salary')}
      icon={<MoneyIcon />}
      titleSuffix={
        <Tooltip
          description={disableMessage}
          disabled={!disableMessage}
          trigger={
            <div>
              <SwitchField
                name="is_require_vacation_salary"
                active={values.is_require_vacation_salary}
                disabled={disabled}
              />
            </div>
          }
        />
      }
    />
  )
}

AdvancedVacationSalary.propTypes = {
  disabled: PropTypes.bool,
  disableMessage: PropTypes.string,
}

export default AdvancedVacationSalary
