import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  info_change_request: {},
  fetching: false,
}

type InfoChangeRequestType = typeof DEFAULT_STATE

export default function reducer(state = DEFAULT_STATE, action: Action): InfoChangeRequestType {
  switch (action.type) {
    case actions.CREATE_EDIT_PROFILE_REQUEST_PENDING:
    case actions.UPDATE_INFO_CHANGE_REQUEST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CREATE_EDIT_PROFILE_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      }

    case actions.UPDATE_INFO_CHANGE_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.CREATE_EDIT_PROFILE_REQUEST_REJECTED:
    case actions.UPDATE_INFO_CHANGE_REQUEST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
