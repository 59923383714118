import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ClearIcon } from 'assets/images/icons/clear-24px.svg'
import { Flex } from 'components/global/atoms'

const Step = ({ title, no, label, onRemove, testId }) => (
  <div
    data-testid={testId}
    className="workflow-steps__step"
  >
    <div className="workflow-steps__step__alias">
      <div className="workflow-steps__stepno">{no}</div>
      <div className="workflow-steps__label">{label}</div>
    </div>
    <div className="workflow-steps__step__btn">
      <Flex spaceBetween>
        <span>{title}</span>
        <ClearIcon
          onClick={onRemove}
          className="workflow-steps__clear__icon"
        />
      </Flex>
    </div>
  </div>
)

Step.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  no: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  onRemove: PropTypes.func,
}

Step.defaultProps = {
  onRemove: () => {},
}

export default Step
