import { FC } from 'react'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Typography, Button } from '@jisr-hr/ds'
import { isRtl } from 'utils/uiHelpers'
import styles from '../PaymentMethods/styles.module.css'

type PaymentConfigMenuType = {
  disabled: boolean
  options: {
    onClick: () => void
    name: string
  }[]
  btnVariant?: 'ghost' | 'outlined'
}

const PaymentConfigMenu: FC<PaymentConfigMenuType> = (props) => {
  const { disabled, options, btnVariant } = props
  return (
    <Tooltip
      behavior="popover"
      placement={isRtl ? 'bottom-start' : 'bottom-end'}
      zIndex={1000}
      trigger={
        <Button
          size="small"
          variant={btnVariant || 'outlined'}
          style={{
            paddingInline: 13,
          }}
          iconOnly
          leadingIcon="dots-vertical"
        />
      }
    >
      {options.map((option, index) => {
        return (
          <Typography
            key={index}
            variant="interface/default/m"
            color="#101828"
            style={{
              lineHeight: '22px',
              opacity: disabled ? '0.6' : '1',
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            onClick={option.onClick}
            className={styles.payment_method_menu__items}
            text={option.name}
          />
        )
      })}
    </Tooltip>
  )
}

export default PaymentConfigMenu
