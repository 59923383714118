export const TIMESHEETS_LISTING = 'TIMESHEETS_LISTING'
export const TIMESHEETS_LISTING_PENDING = 'TIMESHEETS_LISTING_PENDING'
export const TIMESHEETS_LISTING_FULFILLED = 'TIMESHEETS_LISTING_FULFILLED'
export const TIMESHEETS_LISTING_REJECTED = 'TIMESHEETS_LISTING_REJECTED'

export const DISMISS_TIMESHEET = 'DISMISS_TIMESHEET'
export const DISMISS_TIMESHEET_PENDING = 'DISMISS_TIMESHEET_PENDING'
export const DISMISS_TIMESHEET_FULFILLED = 'DISMISS_TIMESHEET_FULFILLED'
export const DISMISS_TIMESHEET_REJECTED = 'DISMISS_TIMESHEET_REJECTED'

export const APPROVE_TIMESHEET = 'APPROVE_TIMESHEET'
export const APPROVE_TIMESHEET_PENDING = 'APPROVE_TIMESHEET_PENDING'
export const APPROVE_TIMESHEET_FULFILLED = 'APPROVE_TIMESHEET_FULFILLED'
export const APPROVE_TIMESHEET_REJECTED = 'APPROVE_TIMESHEET_REJECTED'

export const REOPEN_TIMESHEET = 'REOPEN_TIMESHEET'
export const REOPEN_TIMESHEET_PENDING = 'REOPEN_TIMESHEET_PENDING'
export const REOPEN_TIMESHEET_FULFILLED = 'REOPEN_TIMESHEET_FULFILLED'
export const REOPEN_TIMESHEET_REJECTED = 'REOPEN_TIMESHEET_REJECTED'

export const RESET_TIMESHEETS_LISTING = 'RESET_TIMESHEETS_LISTING'
