import { BannerProps } from '@jisr-hr/ds'
import i18n from 'translations/i18n'

const registeredPunchProps: BannerProps = {
  variant: 'tinted',
  color: 'success',
  leadingIcon: 'info-circle-filled',
}

const unRegisteredPunchProps: BannerProps = {
  size: 'extended',
  variant: 'filled',
  color: 'warning',
  leadingIcon: 'alert-triangle-filled',
}

const messages = {
  late_in: {
    sectionTitle: i18n.t('recorded_clock_in'),
    missingPunchTitle: i18n.t('label.alert.punch_in_is_missing_title'),
    missingPunchDescription: i18n.t('label.alert.punch_in_is_missing_description'),
    noRecordedDurationDescription: i18n.t('no_delay_was_recorded_for_this_shift'),
  },
  early_out: {
    sectionTitle: i18n.t('recorded_clock_out'),
    missingPunchTitle: i18n.t('label.alert.punch_out_is_missing_title'),
    missingPunchDescription: i18n.t('label.alert.punch_out_is_missing_description'),
    noRecordedDurationDescription: i18n.t('label.alert.no_shortage_recorded'),
  },
}

export const getExcuseHintMessages = (
  type: 'late_in' | 'early_out',
  withPunch: boolean,
): { sectionTitle: string } & BannerProps => {
  const {
    sectionTitle,
    missingPunchTitle,
    missingPunchDescription,
    noRecordedDurationDescription,
  } = messages[type]
  const bannerProps = withPunch ? registeredPunchProps : unRegisteredPunchProps
  const title = !withPunch ? missingPunchTitle : undefined
  const description = !withPunch ? missingPunchDescription : noRecordedDurationDescription

  return {
    sectionTitle,
    title,
    description,
    ...bannerProps,
  }
}
