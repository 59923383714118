import React from 'react'
import { Flex, Spacer } from '@jisr-hr/ds'
import { Table } from '@jisr-hr/design-system'
import { DatePicker } from '@jisr-hr/ds-beta'
import I18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { addMonths, subDays } from 'date-fns'

import { ReactComponent as PlusIcon } from '../icons/plus.svg'

import TextCaption from './TextCaption'
import Styles from '../OvertimeDuration.module.css'

const AddDay = () => {
  const { change } = useForm()
  const { values } = useFormState()
  const [addDate, setAddDate] = React.useState(false)
  const excludeDates = values.overtime_dates?.map((d) => new Date(d))

  const handleAddDate = (d) => {
    change('overtime_dates', [...values.overtime_dates, d])
    change('initialMultipleShifts', undefined)
    setAddDate(false)
  }

  return (
    <Table.Row>
      <td
        colSpan="100%"
        className={Styles.addAndTotal}
      >
        {addDate ? (
          <DatePicker
            onChange={handleAddDate}
            triggerType="input"
            calenderProps={{
              type: 'single',
              maxDate: addMonths(new Date(), 3),
              minDate: subDays(new Date(), 59),
              disabledDates: excludeDates,
            }}
          />
        ) : (
          <Flex
            className={Styles.addDay}
            onClick={() => setAddDate(true)}
          >
            <PlusIcon />
            <Spacer width={8} />
            <TextCaption
              className="pointer"
              label={I18n.t('add_day')}
              color="var(--color-base-colors-blue-600)"
            />
          </Flex>
        )}
      </td>
    </Table.Row>
  )
}

export default AddDay
