import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  locations: [],
  location: { manager: {} },
  editing: {},
  locationEditing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function locationReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let locations = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'LOCATIONS_LOAD_PENDING':
    case 'LOCATION_LOAD_PENDING':
    case 'LOCATION_CREATE_PENDING':
    case 'LOCATION_UPDATE_PENDING':
    case 'LOCATION_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'LOCATIONS_LOAD_REJECTED':
    case 'LOCATION_LOAD_REJECTED':
    case 'LOCATION_CREATE_REJECTED':
    case 'LOCATION_UPDATE_REJECTED':
    case 'LOCATION_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOCATION_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOCATIONS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        locations: payload.data?.data?.locations?.map((loc) => ({
          ...loc,
          name_i18n: loc.address_i18n,
        })),
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOCATION_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      locations = [...state.locations]
      newRecord = payload.data.data.location
      changes = {
        locations: [...locations, newRecord],
        editing: {
          ...state.editing,
          [`Area-${newRecord.area_id}-Location-New`]: false,
        },
      }
      return { ...state, ...changes }

    case 'LOCATION_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      locations = [...state.locations]
      updatedRecord = payload.data.data.location
      changes = {
        locations: updateOneListOfRecords(locations, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'LOCATION_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      locations = [...state.locations]
      return {
        ...state,
        locations: removeOneListOfRecords(locations, payload.id),
        error: null,
      }

    case 'LOCATION_EDIT':
      changes = {
        editing: {
          id: action.id,
          area_id: action.area_id,
        },
      }
      return { ...state, ...changes }
    case 'LOCATION_EDIT_CANCEL':
      changes = {
        editing: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
