const Employee = {
  // A
  accepted_invites: 'الدعوات المقبولة',
  avatar: 'صورة العرض',
  add_contributor_details: 'تفاصيل اضافة مشترك',
  add_new_contract: 'إضافة عقد جديد',
  add_employee: 'اضافة موظف',
  addition_deduction: 'إضافة/خصم',
  addition_deduction_type: 'نوع الإضافة/الخصم',
  add_addition_deducation: 'إضافة إضافة او خصم',
  assignee: 'صاحب المهمة',
  assignee_2: 'المنفذ',
  assign_to: 'إسناد الى',
  account_balance: 'رصيد الإجازات على اساس ايام اعتيادية: {{count}}',
  actual_working_hours: 'ساعات العمل الفعلية',
  additional_clause: 'بنود إضافية',
  additional_clause_arabic: 'بند إضافي (عربي)',
  additional_clause_english: 'بند إضافي (انجليزي)',
  authorized_signatory_arabic: 'مثلها في توقيع هذا العقد (عربي)',
  authorized_signatory_english: 'مثلها في توقيع هذا العقد (انجليزي)',
  answer: 'الإجابة',
  appraisal: 'علاوة راتب',
  address: 'العنوان',
  address_in_saudi: 'العنوان في المملكة',
  amount_of_end_of_service_settlement: 'قيمة نهاية الخدمة',
  account_holder_name: 'اسم صاحب الحساب',
  account_holder_name_in_arabic: 'اسم صاحب الحساب بالعربية',
  additional_personal_information: 'معلومات شخصية إضافية',
  additional_contact_information: 'معلومات تواصل إضافية',
  additional_number: 'رقم اضافي',
  account_number: 'رقم الحساب',
  assets: 'العهد',
  assets_request: 'طلب عهده',
  assets_return_date: 'تاريخ الإخلاء (ميلادي)',
  allocated_assets: 'عهد في حوزة الموظف',
  allocated_assets_alert: 'لا يمكن التراجع عن عملية استرجاع العهدة من الموظف',
  allocated_assets_description: 'تصفية العهد ضرورية قبل إنهاء خدمة الموظف',
  asset_name: 'اسم العهدة',
  asset_name_en: ' اسم العهدة بالإنجليزية',
  asset_name_ar: ' اسم العهدةبالعربية',
  asset_type: 'نوع العهدة',
  asset_description: 'تفاصيل العهدة',
  asset_status: 'حالة العهدة',
  add_new_clearance: 'إضافة مهمة جديدة',
  add_new_asset: 'إضافة عهدة جديدة',
  add_new_file: 'إضافة ملف جديد',
  add_new_benefit: 'إضافة بدل جديد',
  add_files: 'إضافة ملفات',
  add_new_education: 'إضافة مؤهل ',
  add_new_expenses: 'إضافة مصروف جديد',
  add_new_family_member: 'إضافة فرد عائلة جديد',
  add_family_member: 'إضافة فرد عائلة جديد',
  add_new_loans: 'إضافة سلفة جديدة',
  add_new_loan: 'إضافة سلفة جديدة',
  add_new_loan_settlements: 'إضافة معادلة سلفة جديدة',
  add_new_loan_settlement: 'إضافة معادلة سلفة جديدة',
  add_new_employee: 'إضافة موظف جديد',
  add_new_qualification: 'إضافة مؤهل جديد',
  add_new_warning_notices: 'إضافة خطاب انذار جديد',
  add_new_warning_notice: 'إضافة خطاب انذار جديد',
  add_non_saudi_contributor: 'اضافة مشترك غير سعودي',
  add_non_saudi_contributor_desc: 'اضافة مشترك غير سعودي في التأمينات الاجتماعية',
  add_non_saudi_contributor_details: 'تفاصيل اضافة مشترك',
  add_saudi_contributor: 'اضافة مشترك سعودي',
  add_saudi_contributor_desc: 'اضافة مشترك سعودي في التأمينات الاجتماعية',
  address_in_home_country: 'العنوان في البلد الأم',
  add_promotion: 'إضافة ترقية',
  add_appraisals: 'إضافة علاوة',
  add_item: 'إضافة عنصر',
  add_your_bank_accounts: 'أضف حساباتك البنكية',
  account_balance_business: 'رصيد الإجازات على اساس ايام العمل: {{count}}',
  addition_type: 'نوع الإضافة',
  add_another_bank_authorizer_for_paying_the: 'إضافة مفوض بنكي آخر',
  access_role: 'صلاحية الوصول',
  role: 'صلاحية',
  add_new_bank_attachment: 'إضافة مرفق بنكي جديد',
  add_new_bank: 'اضافة بنك جديد',
  assign_bank_authorizer: 'إسناد مفوض بنكي',
  availability_in_cchi: 'الإتاحة في مجلس الضمان الصحي',
  add_benefits: 'اضافة بدلات',
  apprise: 'علاوة',
  action: 'اجراء',
  ACTION: 'اجراء حدث',
  available_shifts: 'الدوامات المتوفرة',
  available: 'متاحة',
  available_leave_balance: ' الرصيد المتاح',
  add_photo: 'اضافة صورة',
  annual_leave_entitlement: 'رصيد الاجازة السنوية',
  add_employee_shift_text: 'نوع الدوام {{shift_type}}، ويوجد {{has}} موظف ضمن هذا الدوا',
  attach_contract: 'إرفاق العقد',
  attach_contract_description: 'اضغط على الزر "ارفاق" لإرفاق العقد',
  attach: 'ارفاق',
  attendance_reviewer: 'مراجعة الحضور والانصراف',
  add_new_out_of_payroll_transactions: 'إضافة عملية خارج الرواتب',
  apply_for_leave: ' طلب إجازة',
  apply_for_resum: 'طلب عودة من الإجازة',
  annual_tip: 'رصيد الاجازة السنوية المستحقة للموظف في السنة',
  appraisal_history: 'سجل العلاوات',
  add_allowance: 'اضافة بدل',
  allowance_name: 'اسم البدل',
  ajeer_fees: 'رسوم أجير',
  add_contract: ' إضافة عقد',
  add_to_gosi: 'اضافة إلى التأمينات',
  alert_to_make_changes: 'هل أنت متأكد أنك تريد إجراء تغييرات؟',
  an_immediate_changes: 'تغييرات فورية',
  a_scheduled_change: 'تغيير مجدول',
  all_loans: 'كل السلف',
  add_passcode: 'إضافة رمز المرور',
  added_on: 'اضيف في',
  are_you_sure_want_offboard_this_employee: 'هل أنت متأكد من إنهاء خدمات هذا الموظف؟  ',
  allowances: 'البدلات',
  allowances_alt_translation: 'البدلات المطلوبة',
  allowance_type: 'نوع البدل',
  are_you_sure_you_want_to_complete: 'هل انت متاكد من استكمال فترة التجربة ؟',
  add_insurance_details: 'اضافة معلومات التأمين الطبي',
  area_manager: 'مدير منطقة',
  assign_new_line_manager: 'تعيين مدير مباشر بديل',
  assign_approval: 'تعيين مسؤول موافقة',
  assign_approval_for_attendance_reviewer: 'تعيين الموظف البديل',
  add_another_approval: 'الموظف مسندة له بعض المهام لتنفيذها، اختر موظف لتنفيذ المهام',
  available_to_add: 'المتاح للإضافة:',
  all_employees: 'اجمالي عدد الموظفين: ',
  all_employees_2: 'كل الموظفين',
  annual_balance: 'الاستحقاق السنوي',
  audit_log: 'سجل المراجعة',
  affected_employee: 'الموظف المتأثر',
  added_employee: 'أضاف موظف',
  // B
  benefits: 'البدلات',
  benefit_name: 'نوع البدل',
  bulk_update_payment_details: 'قوالب تحديث البيانات',
  back_to_employees: 'الرجوع لقائمة الموظفين',
  back_to_profile: 'الرجوع لملف الموظف',
  benefit_type: 'نوع البدل',
  bank_authorization: 'التفويض البنكي',
  basic_salary: 'الراتب الأساسي',
  basic_wage: 'الأجر الأساسي',
  bank_name: 'اسم البنك',
  bank_accounts: 'الحسابات البنكية',
  bank_location: 'موقع البنك',
  branch_name: 'اسم الفرع',
  bank_country_type: 'bank_country_type:ar',
  bank_name_arabic: 'اسم البنك (عربي)',
  bank_name_english: 'اسم البنك (انجليزي)',
  branch_in_arabic: 'اسم الفرع بالعربية',
  border_no: 'رقم الحدود',
  bulk_upload_import_sheets: 'قوالب تحديث البيانات',
  business_unit_manager: 'مدير وحدة عمل',
  building_number: 'رقم المبنى',
  bank_account: 'حساب بنكي',
  build_up: 'إنشاء',
  biometric_devices_management: 'إدارة أجهزة البصمة',
  biometric_devices_management_description:
    'إدارة الطرق المختلفة التي يمكن للموظف من خلالها تقديم سجل الحضور من جهاز المقاييس الحيوية.',
  billing_history_model: 'سجل الفواتير',
  // C
  capacity_of_arabic: 'المسمى الوظيفي لممثل المنشأة (عربي)',
  capacity_of_english: 'المسمى الوظيفي لممثل المنشأة (انجليزي)',
  create_transaction: 'اضافة معاملة مالية جديدة',
  company_contribution: 'القيمة',
  current: 'حاليا',
  current_time_perioud: 'الفترة الزمنية الحالية {{duration}} ايام. ',
  clearance_tasks: 'المهام',
  contract_clauses: 'بنود العقد',
  contract_is_expired: 'انتهى عقد {{name}}',
  contract_period: 'مدة العقد',
  contract_type: 'نوع العقد',
  contract_start_date: 'تاريخ بداية العقد (ميلادي)',
  contract_start_date_jijri: 'تاريخ بداية العقد (الهجري)',
  contract_end_date: 'تاريخ نهاية العقد (ميلادي)',
  contract_end_date_jijri: 'تاريخ نهاية العقد (الهجري)',
  complete_the_contract: 'استكمال عقد العمل',
  contract_warning_title: 'هل انت متأكد من استكمال عقد العمل؟',
  compensation: 'الراتب والبدلات',
  clearance_process: 'عملية انهاء الخدمات',
  delete_all_employee_information: 'حذف كل معلومات الموظف',
  confirm_offboarding: 'تأكيد انهاء عقد موظف',
  cr_and_salary_bank: 'اسم المنشأة',
  create_contract: 'انشاء عقد عمل',
  country: 'الدولة',
  commissions: 'عمولات',
  completed: 'مكتمل',
  create_new_item: 'إضافة عنصر جديد',
  commercial_registrations: 'السجلات التجارية • {{number}}',
  other_commercial_registrations: 'سجلات تجارية اخرى',
  commercial_registration: 'السجل التجاري',
  change_salary_info: 'معلومات الراتب',
  company_contribution_percentage: '%{{count}} مساهمة الشركة ',
  company_contribution_percentage_retired_employees: '%{{count}} مساهمة الشركة "موظف متقاعد"',
  change_language: 'English',

  company_and_employee_contribution_percentage:
    '%{{count}} مساهمة الشركة و {{count2}}% مساهمة الموظف',
  company_and_employee_contribution_percentage_saned_excluded:
    '%{{count}} مساهمة الشركة و {{count2}}% مساهمة الموظف "بدون ساند"',

  contract: 'العقد',
  contact_type: 'العنوان البريدي المفضل',
  cost: 'التكلفة',
  costs: 'التكاليف',
  cost_allocation: 'توزيع التكلفة',
  cchi_effective_date: 'تاريخ بدء مجلس الضمان الصحي',
  confirm_changes: 'تأكيد التغيير',
  contract_details: 'تفاصيل العقد',
  contributor_type: 'نوع المشترك',
  contributor_is_abroad: 'يعمل خارج المملكة',
  contributor_language: 'لغة المشترك',
  create_contract_description: 'اضغط على الزر "إنشاء" لمعاينة وتنزيل العقد',
  contracts_notification_msg:
    'عقود العمل لـ <b>{{count}}</b> موظفين منتهية, و <b>{{count2}}</b> موظفين عقودهم على وشك الإنتهاء',
  carry_forward_notification_msg: 'سياسة ترحيل الرصيد ستؤثر على <b>{{count}}</b> موظفين',
  check_them_out_now: 'تحقق منهم الآن',
  changes_are_being_reviewed: 'التغييرات قيد المراجعة',
  create_probation_period: 'إنشاء فترة تجربة',
  change_the_start_date_headding: 'هل أنت متأكد أنك تريد تغيير تاريخ الالتحاق؟',
  change_the_start_date_description:
    'هذا هو أول يوم عمل للموظف ، يؤدي تغيير تاريخ التحاق الموظف إلى تغييرفي مسير الرواتب وبداية العقد',
  change_the_start_date_description_performance:
    'هذا هو أول يوم عمل للموظف ، يؤدي تغيير تاريخ التحاق الموظف إلى تغيير وقت البدء في مسير الرواتب وبداية العقد ودورة مراجعة فترة التجربة.',
  changes_effect_payroll: 'ستؤثر هذه التغييرات على مسير الروااتب',
  changes_that_already_happened: 'التغييرات التي حدثت بالفعل',
  choose_salary_bank: 'اختر المنشأة والحساب البنكي الذي يصرف منه الراتب',
  card: 'البطاقة الرقمية',
  card_number: 'رقم البطاقة الرقمية',
  card_facility_code: 'رمز البطاقة الرقمية',
  current_scheduled: 'الإجازات الحالية و المجدولة',
  current_leaves: 'الإجازات الحالية',
  current_salary: 'الراتب الحالي',
  can_not_update_the_joining_date:
    'لايمكن تعديل تاريخ الالتحاق، لان الموظف يمتلك مراجعة فترة تجربة مكتملة',
  changed_by: 'المسؤول عن التغيير',
  create_leave_request: 'إنشاء طلب إجازة',
  cr_no: 'رقم السجل التجاري',
  clearing_asset: 'تصفية العهد',
  cancel_employee_offboarding: 'تم حفظ جميع التغييرات يمكنك الخروج والاكمال لاحقاً',
  contact_details: 'تفاصيل اضافة مشترك',
  edit_item: 'Edit item AR',
  edit_others: 'تعديل الحقل المخصص',
  can_not_add_employees: 'لايمكنك إضافة موظفين!',
  can_not_add_employees_dec:
    'تم تعطيل هذا الإجراء، يرجى زيارة قسم "سجل الفواتير" لتسوية الفواتير المستحقة وإعادة تمكين إضافة موظفين',
  can_add_employees: 'رسوم إضافة الموظف',
  can_add_employees_dec:
    'رسوم أضافة موظفين جدد تحسب من تاريخ إضافة الموظف وحتى نهاية اشتراككم الحالي في حال رغبتك باستبدال موظف بآخر ، يمكنك إنهاء الخدمة الموظف ، ثم إضافة الموظف الجديد',
  can_add_employees_dec_with_account:
    ' يمكنك إضافة  {{num}} موظفين بدون إصدار فاتورة. في حالة الزيادة ,رسوم أضافة الموظفين الجدد تحسب من تاريخ إضافة الموظف وحتى نهاية اشتراككم الحالي في حال رغبتك باستبدال موظف بآخر ، يمكنك إنهاء الخدمة الموظف ، ثم إضافة الموظف الجديد',
  country_phone_code: 'رمز الدولة',
  // D
  due_date: 'تاريخ انهاء المهمة',
  delete_profile: 'حذف ملف الموظف',
  delete_confirmation_alert:
    'حذف ملف الموظف سيؤدي إلى إلغاء الطلبات في الإنتظار، عالج الطلبات لتفادي إلغائها.',
  delete_confirmation_note:
    'بخلاف خيار إنهاء الخدمة، لن يسمح لك حذف ملف الموظف بالتالي: <ul><li>إعداد المخالصة النهائية</li><li>تصفية العهد</li><li>استعراض سجل طلبات الموظف</li><li>استعراض معلومات الموظف في التقارير</li><li>تنفيذ أي إجراء يخص الموظف</li></ul>',
  delete_employee: 'حذف موظف',
  degree: 'المؤهل',
  deduction_starting_month: ' بداية إستقطاع الخصم (شهر)',
  date_of_birth: 'تاريخ الميلاد (ميلادي)',
  date_form: 'سنة / شهر / يوم',
  date_form_dd_mm_yyyy: 'سنة-شهر-يوم',
  date_form_yyyy_mm_dd: 'يوم-شهر-سنة',
  medical_insurance_expiry_date: 'تاريخ انتهاء صلاحية التأمين الطبي (ميلادي)',
  document_no: 'رقم الهوية',
  document_request_confirmation_message:
    'الإضافة والتعديل على المستندات تؤكد بعد إرسال طلب إضافة مستند لسلسلة الموافقات.',
  delete_it: 'احذف',
  deleted: 'تم الحذف',
  deleted_employee: 'محذوف',
  secondary_reason: 'سبب ثانوي لإنهاء الخدمة',
  document: 'مستند',
  duration: 'المدة',
  deduction_type: 'نوع الخصم',
  daily_salary: 'الراتب اليومي',
  documents: 'المستندات',
  discard_changes: 'إلغاء',
  district: 'المنطقة',
  done: 'تطبيق',
  define_employee_in_attendance_exemption_policy:
    'تحديد الموظف في سياسة الإعفاء من الحضور والإنصراف',
  document_reviewed: 'المستند قيد المراجعة',
  define_probation_period: 'اضافة فترة التجربة',
  delete_passcode: 'حذف رمز المرور',
  delete_card: 'حذف البطاقة الرقمية',
  department_manager: 'مدير قسم',
  did_employee_have_a_probation: 'هل الموظف في فترة التجربة ؟',
  drag_and_drop_excel_sheet_pdf_image_or: 'قم بسحب  وإفلات  الملف  أو',
  do_you_want_to_terminate_active_employee_from_GOSI:
    'هل تود استبعاد مشترك نشط من التأمينات الاجتماعية؟',
  do_you_want_to_add_this_employee_to_GOSI: 'هل تود اضافة هذا الموظف في التأمينات الاجتماعية؟',
  date_picker_duration: 'الفترة الزمنية',
  // E
  expired_invites: 'الدعوات المنتهية',
  extend_probation_period: 'تمديد فترة التجربة',
  extend_months: 'تمديد إلى 6 أشهر',
  extend_probation: 'تمديد فترة التجربة',
  expenses: 'التكاليف',
  edit_asset: 'تعديل العهدة',
  edit_confirmation: 'edit_confirmation:AR',
  edit_file: 'تعديل الملف',
  edit_basic_information: 'تعديل المعلومات الاساسية',
  edit_job_information: 'تعديل المعلومات الوظيفية',
  edit_iqama_information: ' تعديل تفاصيل الهوية و الإقامة',
  employee_account_type: 'نوع الحساب الخاص بالموظف',
  employee_information_changes: 'تعديل معلومات الموظف',
  employee_files: 'المستندات',
  employees: 'الموظفين',
  employee_id: 'الرقم الوظيفي',
  dynmaic_employee_id: 'الرقم الوظيفي{{id}}',
  employee_terminated_successfully: 'تم استبعاد {{name}} من التأمينات بنجاح',
  expiry_date: 'تاريخ الانتهاء (ميلادي)',
  edit_bank: 'تعديل البنك',
  edit_education: 'تعديل المؤهل',
  EXPIRY_DATE: 'تاريخ الانتهاء',
  emp_id: 'الرقم الوظيفي',
  employee_name: 'اسم الموظف',
  EMPLOYEE_NAME: 'اسم الموظف',
  edit_loan: 'تعديل السلفة',
  exit_interview: 'مقابلة انهاء الخدمة',
  exit_offboarding: 'حفظ وخروج',
  end_of_service_settlement: 'قيمة نهاية الخدمة',
  education: 'المؤهلات',
  edit_contract: 'تعديل العقد',
  expense_type: 'نوع المصروف',
  edit_on_the_salary: 'تصحيح الراتب',
  extend_the_probation_period: 'تمديد فترة التجربة',
  ex_prisoner: 'سجين سابق',
  employment_type: 'نوع التوظيف',
  emergency_contact: 'جهة الاتصال للطوارئ',
  emergency_contact_name: 'جهة الاتصال للطوارئ',
  edit_emergency_contact: 'تعديل جهة الاتصال للطوارئ',
  edit_family_member: 'تعديل بيانات فرد العائلة',
  edit_passport_information: 'تعديل تفاصيل جواز السفر',
  effective_date: 'تاريخ الأستحقاق',
  effective_date_month: 'تاريخ الفاعلية (شهر) ',
  effective_date_year: 'تاريخ الفاعلية (سنة) ',
  event_date: 'تاريخ الفعالية',
  employee_delete_warning: '.سيتم حذف جميع الطلبات المعلقة والإجراءات ذات الصلة بالموظف',
  employee_delete_confirmation: 'تم حذف الموظف بنجاح',
  employee_email: 'البريد الالكتروني للموظف',
  employee_added_successfully: 'تمت إضافة الموظف بنجاح',
  employee_data: 'بيانات الموظف',
  employees_without_emails: 'موظفيم بدون بريدالكتروني',
  promote_employee: 'ترقية الموظف',
  employees_without_emails_alert: '{{count}} موظف غير قادرين على استخدام الخدمة الذاتية للموظفين',
  employees_without_fingerprint: '{{count}} موظف لم يتم تسجيل بصماتهم',
  exclude_terminate: 'استبعاد',
  expiring_contracts: 'المنتهية عقودهم',
  employee_documents: 'مستندات الموظف',
  employee_docs: 'ملفات الموظفين',
  employee_loans: 'سلف الموظف',
  employee_cost_to_company: 'تكاليف الموظف على الشركة',
  employee_assets: 'العهد',
  employee_information: 'معلومات الموظف',
  employee_doesnot_have_any_loan: 'لاتوجد سلف على الموظف',
  establish_effective_date_the_changes: 'أولاً ، دعنا نحدد تاريخ تأثير التغييرات',
  employer_number: 'رقم صاحب العمل',
  employee_has_re_assigned:
    'المهام التالية معاد تعيينها لهذا الموظف . الرجاء إعادة تعيينها لموظف بديل عنه',
  employee_defined_as_executor: 'الموظف معرف في صفحة الإعدادات الخاصة بالمهام',
  employee_number: 'موظفين {{ count }}',
  engagement_details: 'بيانات مدة الاشتراك',
  edit_address_in_saudi: 'تعديل العنوان في السعودية',
  edit_address_in_home_country: 'تعديل العنوان في البلد الأم',
  edit_date: 'تاريخ التعديل',
  effective_date_history: 'تاريخ الفاعلية',
  edit_contract_status: 'Edit Contract Status ar',
  edit_salary_info: 'تعديل معلومات الراتب',
  edit_payment_details: 'تعديل معلومات الدفع',
  edit_gosi_configuration: 'تعديل معلومات التسجيل في التأمينات الإجتماعية',
  edit_insurance: 'تعديل معلومات التأمين الطبي',
  edit_fees: 'تعديل الرسوم',
  establishment: 'المنشأة',
  establishment_location: 'موقع المنشأة',
  establishment_mol_branch: 'رقم مكتب العمل',
  establishment_representative_details: 'بيانات ممثل المنشأة في العقد',
  expiry_date_hijri: 'تاريخ الانتهاء (هجري)',
  experience: 'الخبرة',
  extension: 'التحويلة',
  employee_requests: 'طلبات الموظف',
  executor: 'المنفذ',
  audit_record_actions: 'الإجراءات',

  // F
  family_info: 'أفراد العائلة',
  file_name: 'اسم الملف',
  final_compensation: 'تصفية مستحقات نهاية الخدمة',
  finalize_compensation: 'المخالصات المالية',
  final_net_settlement: 'صافي المخالصة المالية',
  first_name: 'الإسم الأول',
  first_name_in_arabic: 'الإسم الأول بالعربية',
  full_name_english: 'الاسم كاملاً باللغة الانجليزية',
  full_name_arabic: 'الاسم الكامل (عربي)',
  fix_term: 'محدد المدة',
  files_uploaded: 'الملفات المرفوعة',
  fees: 'رسوم',
  fallback_gosi_error: 'يوجد بعض الخطأ, الرجاء المحاولة لاحقاً',
  finger_type: 'نوع الإصبع',
  fingerprints: 'بصمات الأصابع',
  fingerprints_single: 'بصمات',
  fingerprint: 'بصمة الأصبع',
  full_name: 'الاسم الكامل',
  // G
  graduation_date: 'تاريخ التخرج (ميلادي)',
  gosi_configuration: 'تفاصيل التأمينات الإجتماعية',
  gosi_configurations: 'إعدادات التأمينات الإجتماعية',
  gosi_registration: 'التسجيل في التأمينات الإجتماعية',
  gosi_employee_number: 'رقم الموظف في التأمينات الإجتماعية',
  gosi_applicable_amount_comp: 'الأجر المسجل في التأمينات الإجتماعية',
  gosi_effective_date: 'تاريخ التسجيل في التأمينات الإجتماعية',
  general: 'المعلومات العامة',
  gosi_changes: 'تغيير في معلومات التأمينات الاجتماعية',
  gois_name_and_bank: 'المنشأه والبنك الذي يصرف منه الراتب',
  gosi_name: 'اسم المنشأه',
  gosi_services: 'خدمات التأمينات الإجتماعية',
  gosi_subscription: 'إشتراك التأمينات',
  gosi_subscriptions: 'إشتراكات التأمينات',
  gosi_subscription_details: 'معلومات اشتراك التأمينات الاجتماعية',
  gosi_file_deleted_contain_employees:
    'لا يمكنك حذف ملف التأمينات الاجتماعية  لأنه يحتوي على موظفين التالية اسمائهم',
  gosi_subscription_tooltip:
    'الأجر الخاضع للأشتراك = الأجر الاساسي + بدل السكن + عمولات ايضاً يمكنك تعديل قيمة الأجر الخاضع للأشتراك بشكل يدوي',
  gosi_termination_date: 'تاريخ الاستبعاد من التأمينات الاجتماعية',
  gosi_termination_reason: 'سبب الاستبعاد من التأمينات الاجتماعية',
  gosi_registration_name_number: 'اسم ورقم اشتراك التأمينات الاجتماعية',
  gosi_registration_no: 'رقم المنشأة',
  go_to_billing_history: 'الذهاب إلى المدفوعات',
  // H
  has_no_contract: '{{name}} Has No Contract:AR',
  has_no_contract_desc: 'Create a new contract to get all details correctly in Offboading:AR.',
  home_po_box: 'العنوان البريدي',
  hide: 'اخفاء',
  housing: 'بدل السكن',
  housing_benefit: 'بدل السكن الشهري',
  history: 'الإجازات السابقة',
  // I
  invitations_tracking: 'تتبع الدعوات',
  id_iqama_and_passport_details: 'تفاصيل الهوية, الاقامة ,جواز السفر',
  id_iqama_details: 'تفاصيل الهوية والإقامة',
  import_bulk_employees: 'قالب معلومات الموظفين',
  installment_starts_in: ' بداية الإستقطاع ',
  installments_ends_in: ' نهاية الإستقطاع',
  issue_date: 'تاريخ الإصدار (ميلادي)',
  issue_month: 'شهر الإصدار',
  insurance_coverage: 'إحتساب تغطية اشتراك التأمينات الاجتماعية',
  institute: 'الجامعة / المعهد',
  iban: 'رقم الآيبان',
  iban__salary_card: 'رقم الأيبان/ بطاقة الرواتب',
  id_iqama_number: 'رقم الهوية / الإقامة',
  id: 'الرقم الوظيفي',
  id_iqama_information: 'معلومات الهوية الوطنية والإقامة',
  id_number: 'رقم الهوية',
  id_expiry: 'تاريخ انتهاء الهوية',
  id_expiry_date: 'تاريخ انتهاء الهوية / الإقامة (ميلادي)',
  id_expiry_date_hijri: 'تاريخ انتهاء الهوية / الإقامة (هجري)',
  iqama_issue_date: 'تاريخ إصدار الهوية / الإقامة (ميلادي)',
  iqama_expiry_date: 'تاريخ انتهاء الهوية',
  id_type: 'نوع الهوية',
  iqama_issue_place: 'مكان إصدار الهوية / الإقامة',
  iqama_issue_date_hijri: 'تاريخ إصدار الهوية / الإقامة (هجري)',
  iqama_expiry_date_hijri: 'تاريخ انتهاء الإقامة بالهجري ( سنة / شهر / يوم )',
  iqama_number_national_id: 'رقم الهوية الوطنية',
  identification_information: 'تفاصيل الهوية',
  id_information: 'تفاصيل الهوية',
  invite: 'دعوة الموظف',
  id_iqama_border_number: 'رقم الهوية/الإقامة/رقم الحدود',
  information_change: 'تعديل معلومة',
  info_change_request_confirmation_message:
    'التعديل على المعلومات يؤكد بعد إرسال طلب تعديل معلومة لسلسلة الموافقات.',
  insurance: 'التأمين',
  insurance_category: 'الفئة',
  insurance_number: 'رقم التأمين',
  insurance_effective_date: 'تاريخ بدء التأمين',
  identification: 'تعريف الموظف',
  institute_name: 'اسم الجامعة / المعهد',
  medical_insurance_number: 'رقم التأمين الطبي',
  invite_all_employee_message:
    'سيتم إعادة تعيين كلمات المرور الحالية لجميع الموظفين وسيتم انشاء كلمة مرور جديدة لكل موظف وارسالها الى البريد الالكتروني الخاص به، هل تريد الاستمرار؟ <br /> ملاحظة: لن يتم تغيير كلمة المرور لأي حساب لايوجد فيه بريد الكتروني.',
  invite_all_employees: 'دعوة كافة الموظفين',
  in_payroll_loan_description: 'سيتم دفع مبلغ السلفة داخل مسير الرواتب',
  in_payroll_description: 'سيتم دفع مبلغ العملية داخل مسير الرواتب',
  iqama_profession: 'المسمى الوظيفي في الاقامة',
  iqama_profession_en: 'المسمى الوظيفي في الاقامة - إنجليزي',
  iqama_profession_ar: 'المسمى الوظيفي في الاقامة - عربي',
  item: 'العنصر',
  employee_inviter: 'الداعي',
  immediate_change: 'تغيير فوري',
  invitation_sent: 'تم إرسال الدعوة ({{password_reset_expire_after}} لتتمكن من اعادة الارسال)',
  invitation_expired: 'ستنتهي صلاحية الدعوة بعد ',
  invitations_statuses: 'الحالة',
  // J
  job_information: 'المعلومات الوظيفية',
  job_title: 'المسمى الوظيفي',
  job_title_of_the_employee_in_arabic: 'المسمى الوظيفي (عربي)',
  job_title_of_the_employee_in_english: 'المسمى الوظيفي (انجليزي)',
  jisr_will_sync_following_salary_note:
    'سيقوم {{app_name}} بإرسال معلومات الراتب التالية لمنصة التأمينات من أجل تحديث أجر الموظف',
  joining_date: 'تاريخ الإلتحاق (ميلادي)',
  joining_date_hijri: 'تاريخ الإلتحاق (هجري)',
  // K
  // L
  last_working_day: 'تاريخ اخر يوم عمل',
  letter_type: 'نوع الخطاب',
  last_active_time: 'أخر دخول للموظف في: {{time}}',
  last_active: 'آخر نشاط',
  loans: 'السلف',
  loan_type: 'نوع السلفة',
  loan_installments: 'جدولة السلفة',
  used_leaves_tip: 'عدد أيام الإجازة السنوية المستخدمة خلال السنة الحالية',
  loan_settlement: 'تسوية السلفة',
  loan_settlements: 'تسويات السلفة',
  last_name: 'الإسم الإخير',
  last_name_in_arabic: 'الإسم الأخير بالعربية',
  loan_amount: 'مبلغ السلفة',
  leave_balance: 'رصيد الإجازات',
  leave_history: 'سجلات الإجازات',
  left_pickie: 'الخنصر الأيسر',
  left_ring: 'البنصر الأيسر',
  left_middle: 'الوسطى الأيسر',
  left_index: 'السبابة اليسرى',
  left_thumb: 'الإبهام الأيسر',
  leave_resumption_date: 'تاريخ العودة (ميلادي)',
  location_manager: 'مدير فرع',
  list_of_non_saudi_workers: 'قائمة التسجيل الإستباقي لمشترك غير سعودي',

  learn_more_to_notifications: 'اعرف المزيد',
  last_payslip_allowances: 'اجمالي الراتب و البدلات',
  // M
  monthly_deduction: 'الخصم الشهري',
  deduction_month: 'شهر الخصم',
  main_information: 'المعلومات الأساسية',
  major: 'التخصص',
  manunal_addtion_deduction: 'الإضافات والخصومات اليدوية',
  middle_name: 'الإسم الوسط',
  middle_name_in_arabic: 'الإسم الوسط بالعربية',
  marital_status: 'الحالة الإجتماعية',
  manual_additions: 'إضافات يدوية',
  manual_deductions: 'خصومات يدوية',
  medical_insurance: 'التأمين الصحي',
  medical_insurance_providers: 'شركات التأمين الصحي',
  medical_insurance_provider: 'شركة التأمين الصحي',
  my_subordinates: 'موظفي الفريق',
  method: 'عن طريق',
  manager: 'مدير',
  mobile_number: 'رقم الجوال',
  my_leaves: 'رصيد الإجازات',
  manged_groups_count: 'مجموعات',
  // N
  not_invited_employees: 'الموظفين غير المدعوين',
  not_delivered_invites: 'دعوات لم تصل',
  new_employee: 'موظف جديد',
  new_insurance: 'اضافة معلومات التأمين الطبي',
  new_Line_manager: 'المدير المباشر الجديد',
  national_id_or_iqama_number: 'رقم الهوية/الإقامة',
  national_id: 'رقم الهوية الوطنية',
  next_step: 'الخطوة التالية',
  next: 'التالي',
  no_fingerprint: 'بصمه غير معرفه',
  number_of_probation_days: 'عدد أيام فترة التجربة',
  notice_period: 'فترة الإشعار',
  no_transactions: 'لا يوجد معاملات مالية بعد',
  notification_date: 'تاريخ الإشعار',
  never_logged: 'لم يسجلوا الدخول',
  new_cost: 'تكليف جديد',
  new_employee_cost: 'تكاليف الموظف على الشركة',
  no_subordinates: 'لايوجد بيانات',
  no_team_members: 'لا يوجد فريق',
  no_promotion_history: 'لايوجد سجل للترقيات',
  no_appraisal_history: 'لا يوجد سجل للعلاوات',
  no_contact_has_been_added: 'لم تتم إضافة أي عقد.',
  new_education: 'إضافة مؤهل ',
  new_settlement: 'اضافة تسوية جديدة',
  new_leave_request: 'طلب إجازة جديد',
  none: 'بلا',
  no_organization_history: 'لاتوجد سجلات للمعلومات الوظيفية',
  no_compensation_history: 'No salary and financial history',
  no_running_and_upcoming_leaves: 'لاتوجد اجازات حالية و مجدولة  للموظف',
  not_registered_in_gosi: 'غير مسجل في التأمينات الإجتماعية',
  not_in_saudi: 'خارج المملكة',
  no_passcode_has_been_added_for_the_employee: 'لم يتم إضافة رمز مرور للموظف.',
  note_passcode: 'ملاحظة: يجب أن يكون رمز المرور رقميًا ويحتوي على 6 أرقام كحد أقصى.',
  notify_people_about_this_change: 'إشعار أشخاص عن هذا التغيير',
  no_card_has_been_added_for_the_employee: 'لم يتم إضافة البطاقة الرقمية للموظف',
  no_fingerprint_added_for_the_employee: 'لم يتم إضافه بصمه إصبع للموظف.',
  no_previous_records: 'لا يوجد تعديلات سابقة',
  no_history: 'لا يوجد إجازات سابقة',
  no_leave_records: 'ليس لديك أي سجلات إجازة حتى الآن',
  no_scheduled_changes: 'لايوجد تعديلات مجدولة',
  no_leave_currently: ' ليس في إجازة حاليا',
  no_scheduled_leave: 'لاتوجد إجازات مجدولة',
  note_Once_confirmed_employee_status_would_inActive:
    ' ملاحظة: عند التأكيد, سوف يتم تحويل حالة الموظف إلى موظف غير فعال وسيقوم النظام بإنشاء مهمة من أجل إحتساب مخالصة نهاية الخدمة',
  no_wait: 'لا, إنتظر',
  new_address_in_home_country: 'عنوان جديد في البلد الأم',
  new_address_in_saudi: 'عنوان جديد في المملكة',
  new_approval: 'الموظف البديل له في سلسلة الموافقات',
  new_custom_fields: 'إضافة حقل مخصص',
  new_doc: 'إضافة مستند',
  new_emergency_contact: 'إضافة جهة اتصال للطوارئ',
  new_executor: 'المنفذ البديل عنه للمهام',
  new_assignee: 'الموظف البديل',
  new_passport_information: 'إضافة تفاصيل جواز السفر',
  new_company_document: 'مستندات الشركة',
  new_contract: 'إضافة عقد',
  new_employee_documents: 'إضافة مستندات الموظف',
  new_assigner: 'الموظف البديل',
  notification_date_must_be_before_last_working_day:
    'تاريخ الإشعار يجب أن يكون قبل تاريخ آخر يوم عمل',
  new_line_manager: 'المدير المباشر البديل',
  new_executer: 'الموظف البديل',
  not_now: 'ليس الآن',
  new_issued: 'لقد تم إصدار فاتورة جديدة',
  new_employee_alert:
    'لن تتمكن من استرداد المبلغ لهذا الموظف في حالة الإضافة، ولكن يمكنك استبدال الموظف بآخر.',
  no_results_found: 'لم يتم العثور على نتائج',
  no_employee: 'لايوجد موظفين',
  net_salary: 'صافي الراتب',
  no_activity_recorded: 'لا يوجد نشاط',
  no_activity_recorded_desc:
    'لا توجد حاليًا إدخالات لسجل المراجعة لعرضها. ستظهر هنا جميع الإجراءات مثل الإضافة، التحديث، أو الحذف بمجرد إجرائها.',
  no_results: 'لا يوجد نتائج',
  no_results_desc:
    'لم يتم العثور على نتائج لبحثك. الرجاء التحقق من صحة الإملاء أو تجربة كلمات مفتاحية مختلفة.',
  // O
  other_allownces: 'بدلات اخرى',
  Other_Documents_Details: 'تفاصيل المستندات الأخرى',
  other_details: 'أسباب أخرى لإنهاء الخدمة',
  organization_info: 'المعلومات الوظيفية',
  off_boarding: 'انهاء عقد موظف',
  organization_structure: 'هيكل المنظمة',
  organization_and_information: 'المعلومات الوظيفية',
  offboard_steps: 'خطوات إنهاء الخدمة',
  offboarding_step_warning: 'فضلاً، أكمل الخطوة 1 أولا',
  operation_could_take_some_time_reflect_gosi:
    'قد تأخذ عملية الاستبعاد بعض الوقت لينعكس في التأمينات الاجتماعية',
  optional: 'اختياري',
  optional_clause: 'بنود اختيارية',
  optional_clause_option1:
    'يلتزم الطرف الراغب بأنهاء العقد بإشعار الطرف الاخر خطيا خلال مدة لا تقل عن 60 يوماً',
  optional_clause_option2:
    'يتجدد هذا العقد تلقائيا لمدة مماثلة، مالم يشعر أحد الأطراف بعدم رغبته بالتجديد بمدة لا تقل عن 30 من نهاية العقد',
  other_allowances: 'بدلات أخرى',
  offboard: 'إنهاء الخدمة',
  out_of_payroll: 'عمليات خارج الرواتب',
  out_of_payroll_loan_description: 'سيتم دفع مبلغ السلفة خارج مسير الرواتب',
  out_of_payroll_description: 'سيتم دفع مبلغ العملية خارج مسير الرواتب',
  outsourcing_company: 'شركات تأجير العمالة',
  outsourcing_company_change:
    'عند تغيير شركة العمالة للموظف سيتم تطبيق اعدادات شركة العمالة الجديدة، قد يؤدي ذلك الى تقليل عدد البدلات الخاصة بالموظف',
  optional_information: 'معلومات اختيارية',
  organization_history: 'سجل المعلومات الوظيفية',
  okay_when_did_this_change_go_into_effect: 'حسنًا ، متى دخل هذا التغيير حيز التنفيذ؟',
  outsourcing_provider_contain_employees:
    'لا يمكنك حذف شركة تأجير العمالة  لأنه يحتوي على موظفين التالية أسمائهم',
  only_numerical_allowed_from_4_to_6_digits: 'يسمح فقط بالأرقام من 4 إلى 6 أرقام',
  organization_tasks: 'تم تعيين الموظف منفذ للمهام التالية ,الرجاء إعادة تعيين شخص بديل عنه',
  opening_balance_tip: 'الرصيد الأولي ( الإفتتاحي ) للموظف الذي تم إدخاله على نظام {{app_name}}',
  old: 'القيمة السابقة',
  offboarding_employee_steps: 'إنهاء عقد الموظف يحتوي الخطوات التالية',
  org_immediate_change_description: 'سيتم تطبيق التغييرات بدأ من تاريخ اليوم',
  // P
  pending_invites: 'الدعوات المعلقة',
  pending_employee_requests: 'طلبات الموظف المعلقة',
  pending_requests_name: 'طلبات في الانتظار لـ {{name}}',
  pending_requests_delete: 'طلبات في الانتظار',
  parent_department_manager: 'مدير قسم أساسي',
  part_of_workflow: 'جزء من سلسلة موافقات',
  previous_contracts: 'العقود المنتهية',
  previous_records: 'التعديلات السابقة',
  probation_period: 'فترة التجربة',
  print_summary: 'طباعة الخلاصة',
  personal: 'شخصي',
  personal_email: 'البريد الإلكتروني الشخصي',
  pending_loan_amount: 'قيمة السلف المتبقية',
  pending_amount: 'المبلغ المتبقي',
  pending_requests_for_this_employee: 'هناك طلبات في الإنتظار لهذا الموظف',
  pending_tasks: 'مهام في الإنتظار',
  past_change_without_retroactive_calculation: 'تغيير سابق (دون أثر رجعي)',
  payment_details: 'تفاصيل الدفع',
  past_retrospective_change_description:
    'تطبيق هذه التغييرات من تاريخ في الماضي، ولكن لن يعاد احتساب الراتب بحسب هذا التغيير',
  payment_type: 'طريقة الدفع',
  passport_number: 'رقم جواز السفر',
  passport_expiry_date: 'تاريخ انتهاء جواز السفر (ميلادي)',
  passport_issue_date: 'تاريخ اصدار جواز السفر (ميلادي)',
  passport_issue_place: 'مكان اصدار جواز السفر',
  passport_information: 'تفاصيل جواز السفر',
  personal_information: 'المعلومات الشخصية',
  place_of_birth: 'مكان الميلاد',
  place_of_birth_in_arabic: 'مكان الميلاد بالعربية',
  postal_code: 'الرمز البريدي',
  promotion_history: 'سجل الترقيات',
  promoted_by: 'تمت ترقيته بواسطة',
  promote: 'ترقية',
  probation_warning: 'هل تريد بالفعل إكمال الفترة التجريبية؟',
  probation_warning_note:
    'هذا الإجراء لايمكن التراجع عنه. فور النقر على (نعم) سيتم تغيير حالة الفترة التجريبية إلى "تمت بنجاح".',
  probation_success_message: 'تم إكمال الفترة التجريبية بنجاح',
  payrolls: 'مسير الرواتب',
  policy_number: 'رقم البوليصة',
  processes: 'العمليات',
  per_page: 'لكل صفحة',
  probation_duration: 'الفترة التدريبية',
  past_change_finance: 'تطبيق هذه التغييرات من تاريخ في الماضي',
  probation_expire: 'انهوا فترة التجربة',
  probation_notification_msg: 'فترة التجربة لـ <b>{{count}}</b> موظفين على وشك الإنتهاء',
  previose_tip:
    'الرصيد المُرحل للموظف من السنة الميلادية السابقة ويعتبر الرصيد الافتتاحي للموظف في السنة الحالية',
  passcode: 'رمز المرور',
  password_reset_link_has_been: 'تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور',
  preiod_is_set_to: 'مدة فترة التجربة  {{days}}  يوم <br /> أجتاز فترة التجربة في  {{endDate}}',
  past_change: 'تغيير سابق',
  past_change_description:
    'تطبيق هذه التغييرات من تاريخ في الماضي، وستظهر التغييرات في التقارير المصدّرة بعد هذا التاريخ',
  pending_requests_adjustment: 'معالجة الطلبات المعلقة',
  presents: 'حاضر',
  // Q
  // R
  reduce_months: 'تقليص الى 3 أشهر',
  renew_contract: 'تجديد العقد',
  relationship: 'صلة القرابة',
  religion: 'الديانة',
  retro: 'أثر رجعي',
  re_invite: 'إعادة دعوة الموظف',
  reinvite_warning: 'سوف تفقد كلمة المرور الحالية',
  registered: 'مسجل',
  registered_fingerprint: 'عدد البصمات المسجلة',
  request_cancellation: 'إلغاء الطلب',
  reason_of_this_change: 'سبب التغيير',
  running_upcoming_leaves: 'الاجازات الحالية والمجدولة',
  request_cancellation_cancellation_workflow:
    'سيتم إلغاء الطلب مباشرة دون المرور بسلسلة الموفقات وسيتم إظهارة  في تقرير الطلبات',
  registered_in_gosi: 'مسجل في التأمينات الإجتماعية',
  right_thumb: 'الإبهام الأيمن',
  right_index: 'السبابة اليمنى',
  right_middle: 'الوسطى اليمنى',
  right_ring: 'البنصر الأيمن',
  right_pickie: 'الخنصر الأيمن',
  remarks: 'ملاحظات',
  requests: 'Requests',
  reset_password: 'إعادة تعيين كلمة المرور',
  rest_days_per_week: 'أيام الراحة في الأسبوع',
  reason_of_ending_service: 'سبب إنهاء الخدمة',
  reassign_workflows: 'نقل مهام الموظف وسلسلة الموافقات والهيكل الإداري لشخص آخر',
  retrospective_change: 'تغيير بأثر رجعي',
  retrospective_change_description:
    'تطبيق هذه التغييرات بأثر رجعي من تاريخ في الماضي، وسيعاد احتساب الراتب بحسب هذا التغيير',
  retrospective_change_description_shared: 'تطبيق هذه التغييرات بأثر رجعي من تاريخ في الماضي',
  replace_employee: 'تعيين الموظف البديل',
  request_leave: 'طلب إجازة',
  // S
  send_invitations: 'إرسال الدعوات',
  serial_number: 'الرقم التسلسلي',
  scheduled: 'المجدول',
  salary_changes: 'تغيير في الراتب',
  salary_settlement: 'راتب أيام العمل',
  salary_items: 'عناصر الراتب',
  salary_up_to_date: 'الراتب المستحق حتى الآن',
  settlement_in_cash: 'صافي مخالصة الإجازة ',
  settlement_of_paid_leaves: 'مخالصه الاجازت المدفوعه',
  step_back: 'الخطوة السابقة',
  step: 'الخطوة',
  study_length: 'مدة الدراسة',
  student_a: 'طالب',
  save_answer: 'حفظ الإجابة',
  salary: 'الراتب',
  swift_code: 'السويفت كود',
  sort_code: 'السورت كود',
  salary_card: 'بطاقة رواتب',
  shift_details: 'نوع الدوام',
  salary_card_number: ' رقم بطاقة الراتب',
  selected: 'المختارين ({{number}})',
  skip: 'تخطي',
  salary_correct: 'تصحيح راتب',
  salary_appraisals_history: 'سجل العلاوات',
  save_exit: 'Save & Exit:AR',
  see_all: 'استعراض',
  send: 'إرسال',
  send_request: 'إرسال الطلب',
  send_to_finance: 'إرسال للمحاسب',
  select_payroll: 'إختر مسير الرواتب',
  select_people: 'إرسال الإشعار إلى',
  second_name: 'الاسم الثاني',
  second_name_in_arabic: 'الاسم الثاني بالعربية',
  salary_and_benefits: 'الراتب والبدلات',
  street_name: 'اسم الشارع',
  save_changes: 'حفظ التغييرات',
  subordinates: 'موظفي الفريق',
  salary_adjustment: 'تعديلات الراتب',
  salary__financial: 'الراتب والتفاصيل المالية',
  salary_info: 'معلومات الراتب',
  select_date: 'اختر التاريخ',
  shift: 'الدوام',
  salary_status_gosi: 'حالة الراتب في التأمينات الاجتماعية',
  salary_and_financial_history: 'سجلات التفاصيل المالية',
  submit_log_using_passcode_on_the_device: 'بادخال رمز المرور',
  save_passcode: 'حفظ رمز المرور',
  submit_log_using_digital_card_on_the_device: 'بمسح البطاقة الرقمية على الجهاز',
  submit_log_using_fingerprint_on_the_device: 'إرسال السجل باستخدام بصمة الإصبع على الجهاز.',
  salary_change_before_current_payroll_no_retro_note:
    'سوف يقوم النظام بإحتساب تغييرات الراتب وتضمينها في مسير الرواتب من دون احتساب الأثر الرجعي. هل تريد تأكيد هذه التغييرات؟',
  salary_change_before_current_payroll_note:
    'سوف يقوم النظام بإحتساب تغييرات الراتب وتضمينها في مسير الرواتب بأثر رجعي. هل تريد تأكيد هذه التغييرات؟',
  salary_change_after_current_payroll_note:
    'سوف يقوم النظام بإحتساب تغييرات الراتب/أجر أشتراك التأمينات وتضمينها في مسير رواتب {{ date }} . هل تريد تأكيد هذه التغييرات؟',
  salary_change_in_current_payroll_note:
    'سوف يقوم النظام بإحتساب تغييرات الراتب/أجر أشتراك التأمينات وتضمينها في مسير الرواتب الحالي. هل تريد تأكيد هذه التغييرات؟',
  system_will_consider:
    'سيعتبر النظام فترة التجربة للموظف 90 يومًا. يمكنك تعديل الفترة من خيار مخصص او من  ملف تعريف الموظف لاحقا  .',
  scheduled_changes: 'التغييرات المجدولة',
  salary_changes_immediate_note:
    'سوف يقوم النظام بإحتساب تغييرات الراتب وتضمينها في مسير الرواتب الحالي. هل تريد تأكيد هذه التغييرات؟',
  scheduled_leave: 'الإجازات المجدولة',
  scheduled_change: 'تغيير مستقبلي',
  salary_appraisals: 'علاوة للراتب',
  scheduled_change_description:
    'ستبقى هذه المعلومات كما هي حتى هذا التاريخ،ثم ستتغير في اليوم المحدد',
  salary_immediate_change_description: 'تطبيق هذه التغييرات ابتداء من هذا الشهر',
  salary_immediate_change_description_date:
    'تطبيق هذه التغييرات ابتداء من شهر مسير الرواتب الحالي ({{ date }})  ',
  send_via_email: 'إرسال عبر البريد الإلكتروني',
  select_one_of_gosi_registration_establishment: 'اختر احدى المنشآت',
  specialization: 'التخصص',
  sick_leaves: 'إجازة مرضية',
  // T
  total_sent_invites: 'إجمالي الدعوات المرسلة',
  employee_returned_the_asset_to_the_organization: 'أعاد الموظف العهدة للمنشأة',
  employee_recieved_this_asset: 'استلم الموظف العهدة من المنشأة',
  skip_for_now: 'ليس الآن',
  task_assignee: 'المسؤول عن المهمة',
  task_status: 'حالة المهمة',
  team_and_org_structure: 'الفريق والهيكل التنظيمي',
  terminate: 'استبعاد',
  terminate_contributor: 'استبعاد مشترك',
  terminate_contributor_desc: '.استبعاد موظف نشط من التأمينات الاجتماعية',
  terminate_contributor_from_gosi: 'استبعاد مشترك من التأمينات الاجتماعية',
  terminated: 'مستبعد',
  termination_details: 'تفاصيل انهاء عقد الموظف',
  termination_reason: 'سبب انهاء عقد الموظف',
  termination_date: 'تاريخ إنهاء عقد الموظف',
  telephone: 'رقم الجوال',
  town_city: 'المدينة',
  types_of_changes: 'نوع التغيير',
  change_type: 'نوع التغيير',
  these_changes_would_appear_in_employees_profile_once_happened:
    'ملاحظة: سوف يتم إبراز التغييرات في ملف الموظف عند حدوثها',
  total_payable_salary: 'الراتب الإجمالي المستحق',
  total_end_of_service_amount: 'إجمالي مبلغ نهاية الخدمة',
  take_action_now: 'اتخاذ إجراء الآن',
  to_show_the_id:
    'لإظهار تفاصيل الهوية / الإقامة في علامة تبويب المستند. يرجى تعبئة رقم الهوية / الإقامة وتاريخ انتهاء الصلاحية وإرفاق صورة الهوية / الإقامة.',
  to_show_the_iqama:
    'لإظهار تفاصيل جواز السفر في علامة التبويب المستند. يرجى تعبئة رقم جواز السفر) (وتاريخ انتهاء الصلاحية وإرفاق صورة من جواز السفر    ',
  totle_balance_tip:
    'إجمالي الرصيد المُرحل من السنة السابقة بالإضافة إلى الاستحقاق السنوي للسنة الحالية مطروحًا منه الأيام المستخدمة في السنة الحالية',
  tell_us_whether_you_are_making_changes:
    'أخبرنا اذا كنت أجريت التغييرات في الماضي او توّد إجراءها فورا او تغييرات مجدولة؟',
  the_defined_period_is:
    'المدة المحددة أكثر من الفترة المسموح بها (180 يوم) حسب قانون العمل السعودي',
  tasks: 'مهام',
  task: 'مهمة',
  team_members: 'موظفي الفريق',
  team_org_structure_adjustment: 'معالجة الفريق والهيكل التنظيمي',
  re_assigned_tasks: 'المهام المعاد تعيينها',
  task_type: 'نوع المهمة',
  transportation_allowance: 'بدل النقل',
  type_to_confirm_delete: 'لتأكيد الحذف النهائي لملف الموظف، اكتب “<b>{{name}}</b>” أدناه.',
  the_contract_of_this_employee_is_expired_deduction:
    'العقد الوظيفي منتهي لهذا الموظف، يمكنك انهاء خدماته أو إضافه عقد جديد.',
  third_name: 'الاسم الثالث',
  third_name_in_arabic: 'الاسم الثالث بالعربية',
  telephone_number: 'رقم الهاتف',
  total_wage: 'إجمالي الأجر',
  team_attendance: 'حضور الفريق',
  the_action: 'الإجراء',
  employee_has_some_pending:
    'يوجد لدى الموظف بعض الطلبات المعلقة. يرجى إعادة تعيينها لشخص بديل عنه',
  // U
  upload_file: 'رفع القالب',
  undo_offboarding: 'إلغاء إنهاء الخدمة',
  unit_number: 'رقم الوحدة',
  unskip: 'عدم تخطي',
  update: 'تحديث',
  update_line_manager_of_organization_structure: 'تحديث المدراء المباشرين لهيكاة المنشأة',
  update_line_manager_of_subordinates:
    'يوجد موظفين تابعين للموظف. الرجاء إعادة تحديث المدير المباشر للموظفين التاليين',
  update_line_manager_of_organization_tasks:
    'الموظف معرف في صفحة الإعدادات كمدير لبعض الإدارات - الأقسام - وحدات العمل - الدولة - المنطقة. الرجاء اعادة تعيين مدير آخر بديل عنه',
  update_line_manager_of_workflow:
    'الموظف معرف في سلسلة الموافقات التالية. الرجاء تحديد موظف آخر بديل عنه أو يمكنك حذفه من سلسلة الموافقات',
  update_line_manager_of_workflow_desc:
    'الموظف لديه بعض المهام التي تم إعادة إسنادها له، اختر الموظف البديل',
  update_their_emails_now: 'حدّث معلومات بريدهم الإلكتروني الآن',
  update_now: 'حدّث الان',
  unpaid_leave_tip: 'خصم بسبب تقديم الموظف على إجازة غير مدفوعة خلال فترة مسير الرواتب',
  up_to_date_balance_tip:
    'الرصيد المرحل من السنة السابقة مضافاً له الرصيد المستحق اليومي تراكمياً من بداية السنة الميلادية الى تاريخ اليوم',
  update_cost: 'تعديل التكلفة',
  update_payment_details: 'تحديث تفاصيل الدفع',
  update_probation_period: 'تعديل فترة التجربة',
  unsaved_changes: 'هناك تغيير غير محفوظ ، هل أنت متأكد أنك تريد المغادرة دون حفظ؟',
  under_probation_ends_at: 'تحت التجربة <br /> تنتهي في {{endDate}}',
  update_was_not_completed_try_again: 'لم يتم التحديث! اعادة المحاولة',
  gosi_un_assigned: 'اختر المنشأة',
  update_employee: 'تحديث موظف',
  // V
  vacation_settlement: 'مخالصة الإجازة',
  view_all: 'عرض الكل',
  view_profile: 'عرض الملف الشخصي',
  village: 'المدينة',
  vacations: 'إجازة',
  // W
  occupation_and_wage_details: 'بيانات المهن والأجور',
  warning_noticies: 'خطابات الإنذار',
  wanna_delete_employee: 'هل تريد الحذف النهائي لملف <b>{{name}}</b>؟',
  with_employee: 'في عهدة الموظف',
  working_experience: 'مدة الخدمة في الشركة',
  warning_date: 'تاريخ الإنذار',
  working_days_per_week: 'أيام العمل في الأسبوع',
  work_email: 'البريد الإلكتروني',
  work_flow: 'سلسلة الموافقات',
  work_type: 'نوع العمل',
  work_city: 'مقر العمل',
  workflow_adjustment: 'سلسلة الموافقات',
  workflow_adjustment_top_header: 'معالجة المهام وسلسلة الموافقات',
  worker_company_number: 'worker_company_number:AR',
  without_emails: 'من دون بريد إلكتروني',
  without_resumptions: 'لم يؤكدوا عودتهم من اﻹجازة',
  without_resumption_notification_msg: 'هناك <b>{{count}}</b> موظفين لم يؤكدوا عودتهم من الإجازة',
  withoutGeofencing: 'إمكانية رفع السجل بدون الاعتبار للموقع الجغرافي',
  who_should_we_notify: 'من يجب أن نبلغ ؟',
  whats_reason_of_this_change: 'ما سبب هذا التغيير؟',
  what_kind_of_change_are_you_making: 'مانوع التغييرات التى توّد إجراءها؟',
  wrong_payment_note: 'لديك {{count}} موظفين تفاصيل حساباتهم البنكية (تفاصيل الدفع) ليست صحيحة.',
  wrong_gosi_note: 'لديك {{count}} موظف لم تحدد لهم المنشأة التي ستصرف لهم الراتب',
  wrong_payment_details_note_2:
    'بإمكانك الاطلاع على هذه القائمة وتحديث المعلومات البنكية للموظفين من خلال',
  wrong_payment_details_note_3:
    'او بإمكانك تحديث تفاصيل الدفع للموظفين كمجموعة من خلال تحميل ملف الموظفين من (الاعدادات - قوالب تحديث البيانات) وتحديث تفاصيل الدفع للموظفين ورفعه مرة اخرى من خلال:',
  workflow_type: 'نوع سير العمل',
  when_is_employee_last_day_of_work: 'When is employee last day of work? AR',
  when_to_notify_the_employee: 'When to notify the employee? AR',
  what_is_the_reason_of_terminating_the_employee:
    'What is the reason of terminating the employee ? AR',
  // X
  // X
  // Y
  you_do_not_have_permission_to_change_bank_authorizer: 'لاتملك صلاحية تغيير مفوض البنك',
  yes_complete: 'نعم',
  your_balance_details: 'تفاصيل رصيد إجازاتك',
  you_cant_reverse_these_changes_once_they_are_applied:
    'ملاحظة: لايمكن التراجع عن هذه التغييرات بمجرد حصولها',
  you_donot_have_any_probation_period: 'لا توجد فترة تجربة معرفة لهذا الموظف.',
  you_dont_have_permission_to_change_the_reviewer: 'لاتملك صلاحية تغيير المراجع',
  yes_confirm: 'نعم, تأكيد',
  you_dont_have_permission_to_clear: 'لا تملك صلاحية لعرض عهد هذا الموظف. يرجى. التواصل مع المشرف',
  you_dont_have_employees: 'لا يوجد موظفين',
  your_balance: 'الرصيد السنوي',

  need_a_help_contact_support: 'تحتاج للمساعدة؟ تواصل مع خدمة الدعم الفني',
  // Z
  change_deduction_amount: 'تغيير مبلغ الخصم',
  ats_onboard_optional: '(اختياري)',
}

export default Employee
