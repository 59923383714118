import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

export type TicketCostsType = {
  id: number
  nationality_id: number
  nationality_name_i18n: string
  amount: number
  employees_count: number
}

export type TicketProvisionPolicyType = {
  id: number
  period: 'per_year' | 'per_contract'
  max_family_member: number
  included_family: boolean
  ticket_costs: TicketCostsType[]
}

type InitialStateType = {
  ticket_provision_policy: TicketProvisionPolicyType
  errMsg?: string
  fetching: boolean
  fetchingLoading: boolean
  is_empty: boolean
}

const initialState: InitialStateType = {
  ticket_provision_policy: {
    id: 0,
    included_family: false,
    max_family_member: 0,
    period: 'per_year',
    ticket_costs: [],
  },
  fetching: false,
  fetchingLoading: false,
  is_empty: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.TICKET_PROVISION_POLICIES_CREATE_PENDING:
    case actions.TICKET_PROVISION_POLICIES_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
        fetchingLoading: false,
      }

    case actions.TICKET_PROVISION_POLICIES_LOAD_PENDING:
      return {
        ...state,
        fetchingLoading: true,
      }

    case actions.TICKET_PROVISION_POLICIES_ADD: {
      const list = new Array(...(state.ticket_provision_policy?.ticket_costs || []))
      list[payload.index] = {
        ...list[payload.index],
        ...payload.data,
      }
      return {
        ...state,
        ticket_provision_policy: {
          ...state.ticket_provision_policy,
          ticket_costs: list,
        },
      }
    }

    case actions.TICKET_PROVISION_POLICIES_CANCEL: {
      return {
        ...state,
        ticket_provision_policy: {
          ...state.ticket_provision_policy,
        },
      }
    }

    case actions.TICKET_PROVISION_POLICIES_LOAD_FULFILLED:
      const { ticket_provision_policy } = payload.data.data
      return {
        ...state,
        ticket_provision_policy,
        fetching: false,
        is_empty: !ticket_provision_policy.id,
        fetchingLoading: false,
      }

    case actions.TICKET_PROVISION_POLICIES_UPDATE_FULFILLED:
    case actions.TICKET_PROVISION_POLICIES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        ticket_provision_policy: payload.data.data.ticket_provision_policy,
        fetching: false,
        fetchingLoading: false,
      }

    case actions.TICKET_PROVISION_POLICIES_LOAD_REJECTED:
    case actions.TICKET_PROVISION_POLICIES_CREATE_REJECTED:
    case actions.TICKET_PROVISION_POLICIES_UPDATE:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
        fetchingLoading: false,
      }

    default:
      return state
  }
}
