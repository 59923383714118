import { useContext } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Button, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { ReactComponent as EmptyCircleIcon } from 'assets/figma-icons/empty-circle.svg'
import { ReactComponent as CheckedCircleIcon } from 'assets/figma-icons/checked-circle.svg'
import {
  onboardingStepUpdate,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { OnboardingContext } from '../../context'
import { handleUrl } from '../../helper'
import { orgSteps } from '../../OrganizationSetup/helper'
import { leaveSteps } from '../../LeavesSetup/helper'
import { payrollSteps } from '../../PayrollSetup/helper'
import { StepsTypes } from '../../types/onboardingTypes'
import styles from './styles.module.css'

type TStepBox = {
  title: string
  description: string
  handleConfig: () => void
  status?: string
  isCurrentStep: boolean
}

const StepBox = ({
  title,
  description,
  handleConfig,
  // handleSkip,
  status,
  isCurrentStep,
}: TStepBox): JSX.Element => {
  const Icon = status === 'Completed' ? CheckedCircleIcon : EmptyCircleIcon
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <Flex itemsCenter>
          <Icon
            className={classNames(styles.icon, {
              [styles.patron]: status === 'Completed',
            })}
          />
          <div style={{ maxWidth: 440 }}>
            <Typography
              variant="title-1"
              text={I18n.t(title)}
              className={styles.cardTitle}
            />

            <Typography
              variant="body-new/regular"
              text={I18n.t(description)}
            />
          </div>
        </Flex>
        <Button
          style={{ width: 120, justifyContent: 'center' }}
          label={status === 'Completed' ? I18n.t('edit') : I18n.t('start')}
          size="small"
          variant={status === 'Completed' ? 'filled' : 'filled'}
          onClick={handleConfig}
          color="primary"
          disabled={!isCurrentStep && status === 'Pending'}
        />
      </div>
    </div>
  )
}

function SharedStep({ goNextStep }: StepsTypes): JSX.Element {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    setMainStep,
    leaveCurrentStepNo,
    payrollCurrentStepNo,
    invCurrentStepNo,
    orgCurrentStepNo,
  } = useContext(OnboardingContext)
  const { organization: orgInfo } = useSelector(({ organization }) => organization)
  const { onboarding_steps, is_custom_labor_law_configurations } = orgInfo?.metadata ?? {}

  const STEPS_NUMBERS = {
    0: {
      currentStepNo: orgCurrentStepNo,
      totalSteps: orgSteps.length,
      storageKey: 'onboarding_step_',
      key: 'orgSteps',
    },
    1: {
      currentStepNo: leaveCurrentStepNo,
      totalSteps: leaveSteps.length,
      storageKey: 'leave_setup_step_',
      key: 'leaveSteps',
    },
    2: {
      currentStepNo: payrollCurrentStepNo,
      totalSteps: payrollSteps.length,
      storageKey: 'payroll_setup_step_',
      key: 'payrollSteps',
    },
    3: {
      currentStepNo: invCurrentStepNo,
      totalSteps: 2,
      storageKey: 'invite_employees_step_',
      key: 'InviteSteps',
    },
  }

  const updateCurrentStep = (step: number): void => {
    const stepNumber = localStorage.getItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`)
    if ((!stepNumber || stepNumber === '1') && step === 1) {
      localStorage.setItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`, '2')
    } else if (!stepNumber) {
      localStorage.setItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`, '1')
    }
  }

  const goToStep = (step: number): void => {
    const stepNumber =
      Number(localStorage.getItem(`${STEPS_NUMBERS[step]?.storageKey}${orgInfo?.id}`)) || 1
    const path = handleUrl(stepNumber, STEPS_NUMBERS[step]?.key, orgInfo.require_pincode)
    history.push(`?${path}`)
  }

  return (
    <Flex
      justifyCenter
      flexCol
      className={styles.sharedStepContainer}
    >
      {[
        {
          title: 'organization_info_onboarding',
          description: I18n.t('all_basic_information_for_your_organization_is_saved', {
            app_name: I18n.t('jisr_translation_name'),
          }),
          handleConfig: (): void => {
            updateCurrentStep(0)
            if (onboarding_steps?.current_step === 'organization_info') {
              return goNextStep?.()
            }
            goToStep(0)
            return setMainStep(0)
          },
          status: onboarding_steps?.organization_info,
          isCurrentStep: onboarding_steps?.current_step === 'organization_info',
        },
        {
          title: 'leaves_setup',
          description: 'set_annual_leave_policy',
          handleConfig: (): void => {
            updateCurrentStep(1)
            if (onboarding_steps?.current_step === 'leaves_setup') {
              const isBoolean = typeof is_custom_labor_law_configurations === 'boolean'
              return goNextStep?.(isBoolean ? 2 : undefined)
            }
            goToStep(1)
            return setMainStep(1)
          },
          status: onboarding_steps?.leaves_setup,
          isCurrentStep: onboarding_steps?.current_step === 'leaves_setup',
        },
        {
          title: 'payroll_setup',
          description: 'payroll_setup_desc',
          handleConfig: (): void => {
            updateCurrentStep(2)
            if (onboarding_steps?.current_step === 'payroll_setup') {
              return goNextStep?.()
            }
            goToStep(2)
            return setMainStep(2)
          },
          handleSkip: (): void => {
            dispatch(
              onboardingStepUpdate({
                step_name: 'payroll_setup',
                step_status: 'Completed',
                current_step: 'invite_employees',
              }),
            ).then(() => dispatch(organizationInfo()))
          },
          status: onboarding_steps?.payroll_setup,
          isCurrentStep: onboarding_steps?.current_step === 'payroll_setup',
        },
        {
          title: 'invite_employees',
          description: I18n.t('enable_employees_to_use_jisr_by_inviting_them', {
            app_name: I18n.t('jisr_translation_name'),
          }),
          handleConfig: (): void => {
            updateCurrentStep(3)
            if (onboarding_steps?.current_step === 'invite_employees') {
              return goNextStep?.()
            }
            goToStep(3)
            return setMainStep(3)
          },
          status: onboarding_steps?.invite_employees,
          isCurrentStep: onboarding_steps?.current_step === 'invite_employees',
        },
      ].map((box, i) => (
        <StepBox
          key={i}
          {...box}
        />
      ))}
    </Flex>
  )
}

export default SharedStep
