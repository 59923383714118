import React from 'react'
import PropTypes from 'prop-types'
import stoc from 'string-to-color'
import { isRtl } from 'utils/uiHelpers'
import { FeaturedIcon, Typography, Flex, Button } from '@jisr-hr/ds'
import { Tooltip } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { LazyAvatar } from 'components/global/atoms'
import {
  useNotifyRequestApprover,
  useUpdateApprovalByPass,
} from 'apis/OrgManagement/requests/useRequests'
import { useDispatch, useSelector } from 'utils/hooks'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import EmployeeProfile from '../EmployeeProfile'

const AutomatedAvatar = ({ name, jobTitle, code, avatar }) => {
  if (!name) {
    return (
      <Flex
        itemsCenter
        style={{ gap: 12 }}
      >
        <Tooltip
          description={i18n.t('created_by_automation')}
          placement="topCenter"
          trigger={
            <div>
              <FeaturedIcon
                iconName="lightning-01"
                colorType="brand"
                size="md"
              />
            </div>
          }
        />
        <Typography
          variant="body-new/medium"
          text={i18n.t('automated')}
        />
      </Flex>
    )
  }
  let additionalInfo = ''
  if (jobTitle) additionalInfo = jobTitle
  if (jobTitle && code) additionalInfo += ' - '
  if (code) additionalInfo += code

  return (
    <Flex
      itemsCenter
      style={{ gap: 12 }}
    >
      <div style={{ position: 'relative' }}>
        <LazyAvatar
          containerStyle={{ width: 34, height: 34 }}
          style={{
            width: 34,
            height: 34,
            backgroundColor: stoc(name),
          }}
          src={avatar}
          alt={name}
        />
        <div className="absolute -end-1 -bottom-1">
          <Tooltip
            description={i18n.t('created_by_automation')}
            placement="top"
            trigger={
              <FeaturedIcon
                iconName="lightning-01"
                colorType="brand"
                size="xs"
              />
            }
          />
        </div>
      </div>
      <div>
        <Typography
          variant="body-new/regular"
          text={name}
        />
        <Typography
          variant="caption"
          textColor="color/fg/lighter"
          text={additionalInfo}
        />
      </div>
    </Flex>
  )
}

const ProfileAndTime = ({
  name,
  jobTitle,
  code,
  avatar,
  date,
  time,
  onProfileClick,
  is_created_by_automation,
  approvalId,
  canBypass,
  canNotify,
}) => {
  const [openPopper, setOpenPopper] = React.useState()
  const { mutate: approveByPass, isLoading: approving } = useUpdateApprovalByPass()
  const { mutate: notifyApprover, isLoading: notifying } = useNotifyRequestApprover()
  const dispatch = useDispatch()
  const request = useSelector(({ requestDetails }) => requestDetails.request)

  const canApproveOrNotify = canBypass || canNotify

  const onSuccess = () => {
    setOpenPopper()
    dispatch(requestDetailsLoad(request?.employee?.id, { request_id: request?.id }))
  }

  const handleApproveByPass = () =>
    approveByPass(
      { approvalId },
      {
        onSuccess,
      },
    )

  const handleNotifyApprover = () =>
    notifyApprover(
      { approvalId },
      {
        onSuccess,
      },
    )

  return (
    <Flex
      justifyBetween
      itemsCenter
    >
      {is_created_by_automation ? (
        <AutomatedAvatar
          name={name}
          jobTitle={jobTitle}
          code={code}
          avatar={avatar || ''}
        />
      ) : (
        <EmployeeProfile
          name={name}
          jobTitle={jobTitle}
          code={code}
          avatar={avatar || ''}
          weight="medium"
          avatarStyle={{ backgroundColor: stoc(name) }}
          onProfileClick={onProfileClick}
        />
      )}
      {canApproveOrNotify ? (
        <div className="flex items-center gap-2">
          {canBypass && (
            <Tooltip
              behavior="popover"
              disabledFocus
              placement="top-end"
              open={openPopper === 'bypass'}
              onClose={() => setOpenPopper()}
              maxWidth="300px"
              trigger={
                <Tooltip
                  placement={isRtl ? 'left' : 'bottom'}
                  description={i18n.t('communication.approve')}
                  trigger={
                    <Button
                      onClick={() => setOpenPopper('bypass')}
                      iconOnly
                      variant="outlined"
                      color="neutral"
                      leadingIcon="check-circle"
                    />
                  }
                />
              }
            >
              <Typography
                variant="body-new/regular"
                text={
                  <>
                    <span>{i18n.t('confirmation_modal.approve_request')}</span>
                    <span className="ms-2 font-bold">{name}</span>
                  </>
                }
              />
              <Flex className="gap-2 mt-2">
                <Button
                  label={i18n.t('label.approve')}
                  onClick={handleApproveByPass}
                  disabled={approving}
                  loading={approving}
                  size="small"
                />
                <Button
                  label={i18n.t('label.cancel')}
                  onClick={() => setOpenPopper()}
                  size="small"
                  variant="outlined"
                  color="neutral"
                />
              </Flex>
            </Tooltip>
          )}
          {canNotify && (
            <Tooltip
              placement="bottom"
              description={i18n.t('communication.notify')}
              trigger={
                <Button
                  iconOnly
                  variant="outlined"
                  color="neutral"
                  leadingIcon="bell-ringing-02"
                  onClick={handleNotifyApprover}
                  disabled={notifying}
                  loading={notifying}
                />
              }
            />
          )}
        </div>
      ) : (
        <Typography
          text={
            <>
              {date || ''} <br />
              {time}
            </>
          }
          variant="body-new/regular"
          textColor="color/fg/lighter"
          style={{ textAlign: 'end' }}
        />
      )}
    </Flex>
  )
}

ProfileAndTime.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  avatar: PropTypes.string,
  time: PropTypes.string,
  is_created_by_automation: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onProfileClick: PropTypes.func,
  approvalId: PropTypes.string,
  canBypass: PropTypes.bool,
  canNotify: PropTypes.bool,
}

export default ProfileAndTime
