import { DatePicker, Typography } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { useFilters } from './context'

type PayrollMonthsT = {
  title: string
}

const PayrollMonths = (props: PayrollMonthsT): JSX.Element => {
  const { title } = props
  const { filter, setFilter } = useFilters()

  return (
    <>
      <div className="flex flex-col px-6 py-4 gap-4">
        <Typography
          text={i18n.t('label.payroll_month')}
          variant="interface/strong/s"
        />

        <DatePicker
          calenderProps={{
            showMonthYearPicker: true,
            size: 'compact',
            type: 'single',
          }}
          label={title}
          value={filter.payroll_month}
          onChange={(val) => {
            setFilter({
              ...filter,
              payroll_month: val,
            })
          }}
        />
      </div>
    </>
  )
}

export default PayrollMonths
