import { forwardRef, memo, useState } from 'react'
import classNames from 'classnames'

import Styles from './ScrollTablePagination.module.css'

type ScrollTablePaginationProps = {
  children: JSX.Element
  loading: boolean
  hasNextPage: boolean
  className: string
  handleLoadMore: () => void
  handleHorizontalScroll?: () => void
}

const ScrollTablePagination = forwardRef<HTMLDivElement, ScrollTablePaginationProps>(
  (props, ref): JSX.Element => {
    const [canLoadMore, setCanLoadMore] = useState(true)

    // Add function for wait before each load more to avoid load more twice in same scroll
    const handleCanLoadMore = (): void => {
      setCanLoadMore(false)

      setInterval(() => {
        setCanLoadMore(true)
      }, 500)
    }

    const handleVerticalScroll = (): void => {
      if (typeof ref === 'function' || !ref?.current) return
      const { scrollHeight, scrollTop, offsetHeight } = ref.current
      const isScrollYEnd = Math.ceil(scrollTop + offsetHeight) >= scrollHeight - 1

      if (isScrollYEnd && !props.loading && props.hasNextPage && canLoadMore) {
        ref.current.scrollTop = scrollTop - 5
        handleCanLoadMore()
        props.handleLoadMore()
      }
    }

    const handleScroll = (): void => {
      handleVerticalScroll()
      props.handleHorizontalScroll?.()
    }

    return (
      <div
        ref={ref}
        className={classNames('scroll-x', 'scroll-y', Styles.tablePagination, props.className)}
        onScroll={handleScroll}
      >
        {props.children}
      </div>
    )
  },
)

export default memo(ScrollTablePagination)
