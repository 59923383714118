import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex, Checkbox } from '@jisr-hr/ds'
import { Field, useForm } from 'react-final-form'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { required } from 'components/global/form/FormValidations'

import CorrectionBadge from './CorrectionBadge'
import CorrectionPunch from './CorrectionPunch'
import Styles from '../ProposedCorrectionTime.module.css'
import { reportMethodIcons } from '../helper'
import Text16 from './Text16'

const ProposedPunch = ({ label, ...rest }) => {
  const { type, detail, punchType } = rest
  const { change } = useForm()
  const [editPunch, setEditPunch] = React.useState(false)
  const { in_time, out_time } = detail || {}

  const defaultTime = type === 'in_punch' ? in_time : out_time

  const handleEditPunch = (e) => {
    const val = e.target.checked
    if (!val) {
      change(punchType, defaultTime)
      change(type, null)
    }
    setEditPunch(val)
  }

  React.useEffect(() => {
    setEditPunch(!detail?.punchTime && detail?.is_shift_got_ended)
  }, [detail?.punchTime, detail?.is_shift_got_ended])

  return (
    <Flex flexCol>
      <Checkbox
        label={label}
        disabled={!detail?.punchTime || !detail?.is_shift_got_ended}
        active={editPunch}
        onChange={handleEditPunch}
      />

      <div className={Styles.detail}>
        {!detail?.is_shift_got_ended ? (
          <Text16 label={I18n.t('shift_not_yet_ended')} />
        ) : (
          <>
            <Flex>
              <Text16 label={replaceAmPm(detail?.punchTime) || '--:--'} />
              {detail.next_day && (
                <CorrectionBadge
                  label={I18n.t('next_day')}
                  type="primary"
                />
              )}
              {detail.status && (
                <CorrectionBadge
                  label={detail.status}
                  type="yellow"
                />
              )}
              {!detail?.punchTime && (
                <CorrectionBadge
                  label={I18n.t('no_record')}
                  type="gray"
                />
              )}
              {detail.reportMethodI18n && (
                <CorrectionBadge
                  label={detail.reportMethodI18n}
                  icon={reportMethodIcons[detail?.reportMethod]}
                  type="gray"
                />
              )}
            </Flex>
            {editPunch && (
              <>
                <Field
                  name={type}
                  validate={required}
                >
                  {() => (
                    <CorrectionPunch
                      {...rest}
                      is_existing={detail?.is_existing}
                    />
                  )}
                </Field>
              </>
            )}
          </>
        )}
      </div>
    </Flex>
  )
}

ProposedPunch.propTypes = {
  label: PropTypes.string,
}

export default React.memo(ProposedPunch)
