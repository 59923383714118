import { FC, memo } from 'react'
import { Typography, Button, Flex } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'utils/hooks'
import { MudadStepsType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import { mudadFlowStepsLoad } from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import useIsDemoAccount from 'components/global/hooks/useIsDemoAccount'
import i18n from 'translations/i18n'
import PaymentMethodCard from '../components/PaymentMethodCard'
import OnlinePaymentMethodCard from '../components/OnlinePaymentMethodCard'
import { usePaymentMethod } from '../../components/usePaymentMethods'
import { convertMudadStepToNumber } from '../../components/helper'

const PaymentMethodsConfigList: FC = () => {
  const dispatch = useDispatch()
  const isDemo = useIsDemoAccount()
  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod } = paymentCtx

  // const is_mudad_enabled = useSelector((s) => s.paymentMethods.is_mudad_enabled)
  const paymentMethod = useSelector((s) => s.paymentMethods.current_payment_method)
  const fetching = useSelector((s) => s.mudadIntegration.fetching)
  const is_jisr_pay_enabled = useSelector((s) => s.auth.employee?.organization.jisrpay_enabled)

  const mudad_establishment = paymentMethod?.mudad_establishment

  const handleCompleteRegistration = async (): Promise<void> => {
    if (!selectedPaymentMethod) return
    const res = await dispatch(mudadFlowStepsLoad(selectedPaymentMethod.registration_id))
    const current = res.value.data.data.mudad_flow_steps as MudadStepsType
    const stepNumber = convertMudadStepToNumber(current)

    paymentCtx.setMudadCurrentStep(stepNumber)
    paymentCtx.setMethodModalOpen('mudad')
  }

  const handleMudadRegister = (): void => {
    paymentCtx.setTermsConditionsOpen(true)
  }
  // const handleRevoke = (): void => {
  //   paymentCtx.handleRevokeMudad()
  // }
  return (
    <div>
      {/* JisrPay card */}
      {is_jisr_pay_enabled && <OnlinePaymentMethodCard />}
      {/* mudad card */}
      <PaymentMethodCard
        is_active={!!mudad_establishment?.is_completed}
        method="mudad"
        style={{ marginBottom: 24 }}
      >
        <Flex
          {...(mudad_establishment?.is_completed ? { justifyEnd: true } : { justifyBetween: true })}
          itemsCenter
          style={{ gap: 16, marginTop: 16 }}
        >
          {!mudad_establishment && (
            <Button
              label={i18n.t('register_to_mudad')}
              onClick={handleMudadRegister}
              disabled={isDemo}
              size="medium"
            />
          )}

          {!!mudad_establishment && !mudad_establishment.is_completed && (
            <Button
              label={i18n.t('complete_mudad_registration')}
              onClick={handleCompleteRegistration}
              disabled={fetching || isDemo}
              size="medium"
            />
          )}
          <Flex
            itemsCenter
            style={{ gap: 20 }}
          >
            <Typography
              text={i18n.t('mudad_subscription')}
              textColor="color/fg/warning/default"
              style={{ lineHeight: '20px' }}
            />
            <Typography
              text={`${i18n.t('sar')} 805.5 / ${i18n.t('year')}`}
              textColor="color/fg/warning/default"
              style={{ lineHeight: '20px' }}
            />
          </Flex>
        </Flex>
      </PaymentMethodCard>
    </div>
  )
}

export default memo(PaymentMethodsConfigList)
