import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function approvalsLoad(params) {
  const url = `/${API_VERSION_TWO}/requests/approvals`
  return {
    type: actions.APPROVALS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function teamRequestsApprovalsLoad(params) {
  const url = `/${API_VERSION_TWO}/requests/approvals`
  return {
    type: actions.TEAM_REQUESTS_APPROVALS_LOAD,
    payload: handleAPI(
      url,
      {
        ...params,
        filter: {
          status: ['Pending', 'Pending for Correction'],
          pending_on: 'self',
        },
      },
      'GET',
    ),
  }
}

export function approvalDetailLoad(id) {
  const url = `/${API_VERSION_TWO}/requests/approvals/${id}`

  return {
    type: actions.APPROVAL_DETAIL_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function requestApprove(id, params) {
  const url = `/${API_VERSION_TWO}/requests/approvals/${id}/approve`
  return {
    type: actions.APPROVE_REQUEST,
    payload: handleAPI(url, params || {}, 'POST'),
  }
}

export function clearApprovedReqRes() {
  return {
    type: actions.CLEAR_APPROVE_REQ_RES,
  }
}

export function requestReject(id, comments) {
  const url = `/${API_VERSION_TWO}/requests/approvals/${id}/reject`
  return {
    type: actions.REJECT_REQUEST,
    payload: handleAPI(url, {}, 'POST', { request: comments }),
  }
}

export function requestCancel(id, params) {
  const url = `/${API_VERSION_TWO}/requests/approvals/${id}/cancel`
  return {
    type: actions.CANCEL_REQUEST,
    payload: handleAPI(url, params || {}, 'POST'),
  }
}

export function approvalDetailReset() {
  return {
    type: actions.APPROVAL_DETAIL_RESET,
  }
}

// Task apis
export function tasksLoad(params) {
  const url = `/${API_VERSION_TWO}/tasks`
  return {
    type: actions.REQUESTS_TASKS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function taskDetailLoad(id) {
  const url = `/${API_VERSION_TWO}/tasks/${id}`
  return {
    type: actions.TASK_DETAIL_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function updateTask(id, data) {
  const url = `/${API_VERSION_TWO}/tasks/${id}`
  return {
    type: actions.UPDATE_TASK,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export function taskReassign(id, data) {
  const url = `/${API_VERSION_TWO}/tasks/${id}/reassign`
  return {
    type: actions.TASK_REASSIGN,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export function customTasksCreate(data) {
  const url = `/${API_VERSION_TWO}/tasks`
  return {
    type: actions.REQUESTS_CUSTOM_TASKS_CREATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function removeRequest(id) {
  return {
    type: actions.REMOVE_REQUEST,
    payload: { id },
  }
}

export function removeRequestPayrun(id) {
  return {
    type: actions.REMOVE_REQUEST_PAYRUN,
    payload: { id },
  }
}

export function removeRequestList() {
  return {
    type: actions.REMOVE_REQUEST_LIST,
  }
}

export function statusStatisticsLoad(params) {
  const url = `/${API_VERSION_TWO}/requests/approvals/status_statistics`

  return {
    type: actions.STATUS_STATISTICS_LOAD,
    payload: handleAPI(url, params || {}, 'GET'),
  }
}
