import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PunchInTypes = {
  filterKey: string
  filters: string[]
}

const PunchIn = (props: PunchInTypes): JSX.Element => {
  const { punch_in } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(punch_in)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={filter[props.filterKey]}
      componentType="badges"
      {...props}
    />
  )
}

PunchIn.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default PunchIn
