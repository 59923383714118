import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function unreadNotificationsLoad(params: Record<string, unknown> = {}): Action {
  const url = `/${API_VERSION_TWO}/notifications/unread`

  return {
    type: actions.GET_IS_UNREAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function markAllAsRead(): Action {
  const url = `/${API_VERSION_TWO}/notifications/mark_as_read`

  return {
    type: actions.MARK_ALL_AS_READ,
    payload: handleAPI(url, {}, 'PUT'),
  }
}
