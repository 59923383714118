import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function customRequestLoad(id: number, params: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/employees/${id}/custom_request`

  return {
    type: actions.CUSTOM_REQUESTS_LOAD,
    payload: handleAPI(url, params || {}, 'GET'),
  }
}

export function customRequestCreate(employee_id: number, request: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/requests`

  return {
    type: actions.CUSTOM_REQUESTS_CREATE,
    payload: handleAPI(url, {}, 'POST', { request }),
  }
}

export function customRequestUpdate(
  employee_id: number,
  id: number,
  request: Record<string, unknown>,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/requests/${id}`

  return {
    type: actions.CUSTOM_REQUESTS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { request }),
  }
}
