export const PAYROLLS_SUSPEND_EMPLOYEE = 'PAYROLLS_SUSPEND_EMPLOYEE'
export const PAYROLLS_SUSPEND_EMPLOYEE_PENDING = 'PAYROLLS_SUSPEND_EMPLOYEE_PENDING'
export const PAYROLLS_SUSPEND_EMPLOYEE_FULFILLED = 'PAYROLLS_SUSPEND_EMPLOYEE_FULFILLED'
export const PAYROLLS_SUSPEND_EMPLOYEE_REJECTED = 'PAYROLLS_SUSPEND_EMPLOYEE_REJECTED'

export const PAYROLLS_SUSPENDED_EMPLOYEES_LOAD = 'PAYROLLS_SUSPENDED_EMPLOYEES_LOAD'
export const PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_PENDING = 'PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_PENDING'
export const PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_FULFILLED =
  'PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_FULFILLED'
export const PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_REJECTED =
  'PAYROLLS_SUSPENDED_EMPLOYEES_LOAD_REJECTED'

export const PAYRUN_SUSPEND_EMPLOYEES_LOAD = 'PAYRUN_SUSPEND_EMPLOYEES_LOAD'
export const PAYRUN_SUSPEND_EMPLOYEES_LOAD_PENDING = 'PAYRUN_SUSPEND_EMPLOYEES_LOAD_PENDING'
export const PAYRUN_SUSPEND_EMPLOYEES_LOAD_FULFILLED = 'PAYRUN_SUSPEND_EMPLOYEES_LOAD_FULFILLED'
export const PAYRUN_SUSPEND_EMPLOYEES_LOAD_REJECTED = 'PAYRUN_SUSPEND_EMPLOYEES_LOAD_REJECTED'

export const PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE = 'PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE'
export const PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_PENDING =
  'PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_PENDING'
export const PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_FULFILLED =
  'PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_FULFILLED'
export const PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_REJECTED =
  'PAYRUNS_UNSUSPEND_EMPLOYEES_UPDATE_REJECTED'
