import { PageHeaderListItems } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import useIsEmployeeOrg from 'hooks/useIsEmployeeOrg'
import { isTruthy } from 'utils/truthy'
import { useMemo } from 'react'
import { useSelector } from 'utils/hooks'

type Props = {
  showViewProfile?: boolean
  showChangeLanguage?: boolean
}

type ListItemsType = Pick<PageHeaderListItems, 'text' | 'value' | 'nextDivider'> & {
  leadingIcon: string
}

const useListItems = ({
  showViewProfile = true,
  showChangeLanguage = true,
}: Props = {}): ListItemsType[] => {
  const isCurrentEmpOrg = useIsEmployeeOrg()
  const isOnboardingEmp = useSelector((state) => state.auth?.employee?.is_onboarding_employee)
  const listItems = useMemo(
    () =>
      [
        !isOnboardingEmp &&
          isCurrentEmpOrg &&
          showViewProfile && {
            text: i18n.t('view_profile'),
            leadingIcon: 'user-01',
            value: 'view_profile',
            nextDivider: true,
          },
        showChangeLanguage && {
          text: i18n.t('change_language'),
          leadingIcon: 'translate-01',
          value: 'language',
        },
        !isOnboardingEmp && {
          text: i18n.t('change_password'),
          leadingIcon: 'passcode-lock',
          value: 'change_password',
          nextDivider: true,
        },
        {
          text: i18n.t('log_out'),
          leadingIcon: 'log-out-01',
          value: 'logout',
        },
      ].filter(isTruthy),
    [isCurrentEmpOrg, isOnboardingEmp],
  )
  return listItems
}

export default useListItems
