import { AnalyticsBrowser } from '@segment/analytics-next'
import { serverEnvironment } from 'env'
import { generatePrefix } from 'utils/mixpanel'

type SegmentIdentifyType = {
  id: number
  organization: { id: number; name: string }
  is_super_admin: boolean
  account_type?: string | null
  full_name_i18n: string
  created_at: string
  first_name: string
}

export const segmentAnalytics = AnalyticsBrowser.load({
  writeKey: import.meta.env.UkVBQ1RfQVBQX1NFR01FTlRfS0VZ,
})

export const segmentIdentify = (current_user: SegmentIdentifyType): void => {
  const { id, organization, account_type } = current_user
  const userPrefix = generatePrefix(id.toString())
  const orgPrefix = generatePrefix(organization.id.toString())

  if (
    ['aws', 'oci', 'staging', 'dev'].includes(serverEnvironment) &&
    account_type === 'actual' &&
    current_user.is_super_admin
  ) {
    window?.Vitally?.account({
      accountId: orgPrefix,
      traits: {
        event: 'Account Active',
        name: organization.name,
      },
    })
    window?.Vitally?.user({
      userId: userPrefix,
      createdAt: current_user?.created_at,
      traits: {
        event: 'User Active',
        name: current_user?.full_name_i18n,
        is_admin: current_user?.is_super_admin,
      },
    })
    if (window?.Vitally?.nps === 'function') {
      window?.Vitally?.nps('survey', {
        userId: userPrefix,
        accountId: orgPrefix,
        productName: 'Jisr HR',
        delay: 0,
        primaryColor: '#2353F5',
        npsQuestion: `سؤال سريع، ${current_user?.first_name}: ما احتمال ترشيحك منصة جسر إلى دائرتك المهنية`,
      })
    }
  }
}
