import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  loans: [],
  loan_installments: [],
  loan_settlements: [],
  installments: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function loanReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let loans = []
  let loan_settlements = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'EMPLOYEES_LOANS_LOAD_PENDING':
    case 'EMPLOYEES_LOAN_CREATE_PENDING':
    case 'EMPLOYEES_LOAN_UPDATE_PENDING':
    case 'EMPLOYEES_LOAN_DELETE_PENDING':
    case 'EMPLOYEES_LOAN_SETTLEMENT_CREATE_PENDING':
    case 'EMPLOYEES_LOAN_INSTALLMENTS_UPDATE_PENDING':
    case 'EMPLOYEES_LOANS_INSTALLEMENTS_LOAD_PENDING':
    case 'EMPLOYEES_LOAN_INSTALLEMENTS_UPDATE_PENDING':
    case 'EMPLOYEES_LOAN_EDIT_SETTLEMENTS_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'EMPLOYEES_LOANS_LOAD_REJECTED':
    case 'EMPLOYEES_LOAN_UPDATE_REJECTED':
    case 'EMPLOYEES_LOAN_CREATE_REJECTED':
    case 'EMPLOYEES_LOAN_SETTLEMENT_CREATE_REJECTED':
    case 'EMPLOYEES_LOAN_INSTALLMENTS_UPDATE_REJECTED':
    case 'EMPLOYEES_LOANS_INSTALLEMENTS_LOAD_REJECTED':
    case 'EMPLOYEES_LOAN_INSTALLEMENTS_UPDATE_REJECTED':
    case 'EMPLOYEES_LOAN_EDIT_SETTLEMENTS_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_DELETE_REJECTED':
      Toastr.error(handleResponseErr(payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOANS_LOAD_FULFILLED':
    case 'LOAN_TYPES_LOAD_FULFILLED':
    case 'EMPLOYEES_LOAN_EDIT_FULFILLED':
    case 'EMPLOYEES_LOAN_EDIT_INSTALLEMENTS_FULFILLED':
    case 'EMPLOYEES_LOAN_EDIT_SETTLEMENTS_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOANS_INSTALLEMENTS_LOAD_FULFILLED':
      return {
        ...state,
        errMsg: null,
        succMsg: payload.data.message,
        fetching: false,
        installments: payload.data.data.instalments,
      }

    case 'EMPLOYEES_LOAN_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loans = [...state.loans]
      newRecord = payload.data.data.loan
      changes = {
        fetching: false,
        loans: [...loans, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_SETTLEMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loan_settlements = [...state.loan_settlements]
      newRecord = payload.data.data.loan_settlement
      changes = {
        fetching: false,
        loan_settlements: [...loan_settlements, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loans = [...state.loans]
      updatedRecord = payload.data.data.loan
      changes = {
        fetching: false,
        loans: updateOneListOfRecords(loans, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_INSTALLMENTS_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.loan_installment
      changes = {
        fetching: false,
        loan_installments: updateOneListOfRecords(state.loan_installments, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_INSTALLEMENTS_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.loan_installment
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAN_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        loans: removeOneListOfRecords(state.loans, payload.id),
        error: null,
      }

    case 'EMPLOYEES_LOAN_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'EMPLOYEES_LOAN_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
