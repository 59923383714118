export const ACCOUNTING_JOURNAL_STEPS = 'ACCOUNTING_JOURNAL_STEPS'

export const PAYROLL_ELEMENTS_LOAD = 'PAYROLL_ELEMENTS_LOAD'
export const PAYROLL_ELEMENTS_LOAD_FULFILLED = 'PAYROLL_ELEMENTS_LOAD_FULFILLED'
export const PAYROLL_ELEMENTS_LOAD_REJECTED = 'PAYROLL_ELEMENTS_LOAD_REJECTED'

export const PAYROLL_ELEMENTS_UPDATE = 'PAYROLL_ELEMENTS_UPDATE'
export const PAYROLL_ELEMENTS_UPDATE_FULFILLED = 'PAYROLL_ELEMENTS_UPDATE_FULFILLED'
export const PAYROLL_ELEMENTS_UPDATE_REJECTED = 'PAYROLL_ELEMENTS_UPDATE_REJECTED'

export const PAYROLL_EXPORT_JOURNALS = 'PAYROLL_EXPORT_JOURNALS'
export const PAYROLL_EXPORT_JOURNALS_PENDING = 'PAYROLL_EXPORT_JOURNALS_PENDING'
export const PAYROLL_EXPORT_JOURNALS_FULFILLED = 'PAYROLL_EXPORT_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_JOURNALS_REJECTED = 'PAYROLL_EXPORT_JOURNALS_REJECTED'

export const PAYROLL_EXPORT_NETSUITE_JOURNALS = 'PAYROLL_EXPORT_NETSUITE_JOURNALS'
export const PAYROLL_EXPORT_NETSUITE_JOURNALS_PENDING = 'PAYROLL_EXPORT_NETSUITE_JOURNALS_PENDING'
export const PAYROLL_EXPORT_NETSUITE_JOURNALS_FULFILLED =
  'PAYROLL_EXPORT_NETSUITE_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_NETSUITE_JOURNALS_REJECTED = 'PAYROLL_EXPORT_NETSUITE_JOURNALS_REJECTED'

export const PAYROLL_EXPORT_QUICKBOOKS_JOURNALS = 'PAYROLL_EXPORT_QUICKBOOKS_JOURNALS'
export const PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_PENDING =
  'PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_PENDING'
export const PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_FULFILLED =
  'PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_REJECTED =
  'PAYROLL_EXPORT_QUICKBOOKS_JOURNALS_REJECTED'

export const PAYROLL_EXPORT_XERO_JOURNALS = 'PAYROLL_EXPORT_XERO_JOURNALS'
export const PAYROLL_EXPORT_XERO_JOURNALS_PENDING = 'PAYROLL_EXPORT_XERO_JOURNALS_PENDING'
export const PAYROLL_EXPORT_XERO_JOURNALS_FULFILLED = 'PAYROLL_EXPORT_XERO_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_XERO_JOURNALS_REJECTED = 'PAYROLL_EXPORT_XERO_JOURNALS_REJECTED'

export const PAYROLL_EXPORT_ODD_JOURNALS = 'PAYROLL_EXPORT_ODD_JOURNALS'
export const PAYROLL_EXPORT_ODD_JOURNALS_PENDING = 'PAYROLL_EXPORT_ODD_JOURNALS_PENDING'
export const PAYROLL_EXPORT_ODD_JOURNALS_FULFILLED = 'PAYROLL_EXPORT_ODD_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_ODD_JOURNALS_REJECTED = 'PAYROLL_EXPORT_ODD_JOURNALS_REJECTED'

export const PAYROLL_EXPORT_FIOPS_JOURNALS = 'PAYROLL_EXPORT_FIOPS_JOURNALS'
export const PAYROLL_EXPORT_FIOPS_JOURNALS_PENDING = 'PAYROLL_EXPORT_FIOPS_JOURNALS_PENDING'
export const PAYROLL_EXPORT_FIOPS_JOURNALS_FULFILLED = 'PAYROLL_EXPORT_FIOPS_JOURNALS_FULFILLED'
export const PAYROLL_EXPORT_FIOPS_JOURNALS_REJECTED = 'PAYROLL_EXPORT_FIOPS_JOURNALS_REJECTED'
