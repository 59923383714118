import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './action'

export type HourlyRatePolicyType = {
  id: number
  organization_id: number
  name: string
  is_default: boolean
  working_hours: number
  number_of_employees: number
  is_all_employee_selected: boolean
  employees?: []
}

export type NewHourlyRatePolicyType = {
  name: string
  is_default: boolean
  working_hours: number
  is_all_employee_selected: boolean
}

export type HourlyRatePolicyPayloadType<T> = {
  hourly_rate_policy: T
  employee_ids?: number[]
}

type InitStateType = {
  hourly_rate_policies: HourlyRatePolicyType[]
  hourly_rate_policy: HourlyRatePolicyType | null
  fetching: boolean
}

const initialState: InitStateType = {
  hourly_rate_policies: [],
  hourly_rate_policy: null,
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.HOURLY_RATE_POLICIES_LOAD_PENDING:
    case actions.HOURLY_RATE_POLICY_LOAD_PENDING:
    case actions.HOURLY_RATE_POLICY_CREATE_PENDING:
    case actions.HOURLY_RATE_POLICY_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.HOURLY_RATE_POLICIES_LOAD_FULFILLED:
      return {
        ...state,
        hourly_rate_policies: payload.data.data.hourly_rate_policies,
        fetching: false,
      }
    case actions.HOURLY_RATE_POLICY_LOAD_FULFILLED:
      return {
        ...state,
        hourly_rate_policy: payload.data.data.hourly_rate_policy,
        fetching: false,
      }

    case actions.HOURLY_RATE_POLICY_CREATE_FULFILLED:
    case actions.HOURLY_RATE_POLICY_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        hourly_rate_policies: payload.data.data.hourly_rate_policies,
        hourly_rate_policy: payload.data.data.hourly_rate_policy,
        fetching: false,
      }

    case actions.HOURLY_RATE_POLICIES_LOAD_REJECTED:
    case actions.HOURLY_RATE_POLICY_LOAD_REJECTED:
    case actions.HOURLY_RATE_POLICY_CREATE_REJECTED:
    case actions.HOURLY_RATE_POLICY_UPDATE_REJECTED:
    case actions.HOURLY_RATE_POLICY_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    case actions.HOURLY_RATE_POLICY_DELETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
