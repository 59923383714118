import { Typography } from '@jisr-hr/ds-beta'
import clsx from 'classnames'
import styles from './Toggle.module.css'

type ToggleProps = {
  list: Array<{ label: string; value: string }>
  selected: string
  handleChange: (item: { label: string; value: string }) => void
}

const Toggle = ({ list, selected, handleChange }: ToggleProps) => {
  return (
    <div className={clsx(styles.mainContainer)}>
      <div className={clsx(styles.baseBox)}>
        {list &&
          list.map((item, i) => (
            <div
              key={i}
              className={clsx(item.value === selected && styles.active)}
              onClick={() => handleChange(item)}
            >
              <Typography
                variant="body-new/regular"
                text={item.label}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Toggle
