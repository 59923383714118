export const ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_PENDING =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_PENDING'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_FULFILLED =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_FULFILLED'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_REJECTED =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEES_LOAD_REJECTED'

export const ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW =
  'ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW'
export const ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING =
  'ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING'
export const ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED =
  'ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED'
export const ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED =
  'ORGANIZATION_CONFIGURATIONS_MANAGER_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED'

export const ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_PENDING'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_FULFILLED'
export const ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED =
  'ORGANIZATION_CONFIGURATIONS_EMPLOYEE_WEEKLY_EMAIL_NOTIFICATION_SHOW_REJECTED'

export const ORGANIZATION_CONFIGURATIONS_PUT = 'ORGANIZATION_CONFIGURATIONS_PUT'
export const ORGANIZATION_CONFIGURATIONS_PUT_PENDING = 'ORGANIZATION_CONFIGURATIONS_PUT_PENDING'
export const ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED = 'ORGANIZATION_CONFIGURATIONS_PUT_FULFILLED'
export const ORGANIZATION_CONFIGURATIONS_PUT_REJECTED = 'ORGANIZATION_CONFIGURATIONS_PUT_REJECTED'

export const EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT = 'EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT'
export const EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_PENDING =
  'EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_PENDING'
export const EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_FULFILLED =
  'EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_FULFILLED'
export const EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_REJECTED =
  'EMPLOYEES_WEEKLY_EMAIL_CONFIGURATIONS_PUT_REJECTED'

export const WEEKLY_EMAIL_UNSUBSCRIBE = 'WEEKLY_EMAIL_UNSUBSCRIBE'
export const WEEKLY_EMAIL_UNSUBSCRIBE_PENDING = 'WEEKLY_EMAIL_UNSUBSCRIBE_PENDING'
export const WEEKLY_EMAIL_UNSUBSCRIBE_FULFILLED = 'WEEKLY_EMAIL_UNSUBSCRIBE_FULFILLED'
export const WEEKLY_EMAIL_UNSUBSCRIBE_REJECTED = 'WEEKLY_EMAIL_UNSUBSCRIBE_REJECTED'
