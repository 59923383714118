import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { TQuestion } from 'components/GetFields/type'
import * as actions from './action'

type TCompanyQuestion = Omit<TQuestion, 'priority'> & {
  options: number | null
  selected_option_ids: number[]
}

type InitialStateType = {
  fetching: boolean
  questions: TCompanyQuestion[]
}

const initialState: InitialStateType = {
  fetching: false,
  questions: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.PAGE_GROUP_STEPS_ANSWERS_POST_PENDING:
    case actions.PAGE_GROUP_STEPS_QUESTIONS_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PAGE_GROUP_STEPS_QUESTIONS_SHOW_FULFILLED:
      return {
        ...state,
        fetching: false,
        questions: payload.data.data?.questions,
      }

    case actions.PAGE_GROUP_STEPS_ANSWERS_POST_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.PAGE_GROUP_STEPS_ANSWERS_POST_REJECTED:
    case actions.PAGE_GROUP_STEPS_QUESTIONS_SHOW_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    // case actions.QUESTIONS_RESET:
    //   return {
    //     ...state,
    //     questions: [],
    //     fetching: false,
    //   }
    default:
      return state
  }
}
