import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type CountriesType = {
  alpha_2_code: string
  id: number
  name_ar: string
  name_en: string
  name_i18n: string
}

export type RegionsType = {
  id: number
  name_ar: string
  name_en: string
  name_i18n: string
}

const initialState = {
  fetching: false,
  countries: [] as CountriesType[],
  pagination: {} as PaginationTypes,
  regions: [] as RegionsType[],
  cities: [] as RegionsType[],
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_COUNTRIES_LOAD_PENDING:
    case actions.ONBOARDING_COUNTRY_CITIES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_COUNTRIES_LOAD_FULFILLED:
    case actions.ONBOARDING_RIGIONS_LOAD_FULFILLED:
    case actions.ONBOARDING_REGIONS_CITIES_LOAD_FULFILLED:
    case actions.ONBOARDING_COUNTRY_CITIES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_COUNTRIES_LOAD_REJECTED:
    case actions.ONBOARDING_COUNTRY_CITIES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
