import { FC, memo } from 'react'
import { Modal } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import PaymentMethodsConfigList from './PaymentMethodsConfigList'
import { usePaymentMethod } from '../../components/usePaymentMethods'

const PaymentMethodSetup: FC = () => {
  const paymentCtx = usePaymentMethod()
  const { setupModalOpen } = paymentCtx
  return (
    <Modal
      open={setupModalOpen}
      header={{ title: i18n.t('select_payment_method'), withClose: true }}
      onClose={(): Promise<void> => paymentCtx.handleSetupMethodOpen(null)}
      size="x-large"
    >
      <div
        style={{ paddingBottom: 24 }}
        className="max-h-[70vh] overflow-scroll"
      >
        <PaymentMethodsConfigList />
      </div>
    </Modal>
  )
}

export default memo(PaymentMethodSetup)
