import { Lens } from '@dhmk/zustand-lens'
import { LineManagerType } from 'containers/authorised/employee/EmployeeDetails/types'
import { TEmployeeData } from 'containers/authorised/NewOnboarding/pages/Organization/pages/EmployeesData/pages/EmployeesDataTable/components/BulkUpdateModal'

export type TSelectedEmployees = {
  allEmployees: boolean
  selectedEmployees: TEmployeeData[]
  unselectedEmployeeIds: number[]
}

export const defaultSelected = {
  allEmployees: false,
  selectedEmployees: [],
  unselectedEmployeeIds: [],
}

export type EmployeesDataTableSliceType = {
  lineManager: LineManagerType[] | []
  listSelectedEmployees: TSelectedEmployees
  setLineManager: (list: LineManagerType[] | []) => void
  setListSelectedEmployees: (data: TSelectedEmployees) => void
}

const InitialEmployeesDataTableSlice: EmployeesDataTableSliceType = {
  lineManager: [],
  listSelectedEmployees: defaultSelected,
  setLineManager: () => null,
  setListSelectedEmployees: () => null,
}

export const createEmployeesDataTableSlice: Lens<EmployeesDataTableSliceType> = (set) => ({
  ...InitialEmployeesDataTableSlice,
  setLineManager: (list): void => set(() => ({ lineManager: list })),
  setListSelectedEmployees: (data): void => set(() => ({ listSelectedEmployees: data })),
})
