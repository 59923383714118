import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  fetchingConstants: false,
  fetchingTableData: false,
  allReports: [],
  constants: {},
  selectedReportId: null,
  selectedReportTableData: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CUSTOM_REPORTS_CONSTANTS_LOAD_PENDING: {
      return {
        ...state,
        fetchingConstants: true,
      }
    }
    case actions.CUSTOM_REPORTS_SHAW_PENDING: {
      return {
        ...state,
        fetchingTableData: true,
      }
    }
    case actions.CUSTOM_REPORTS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.CUSTOM_REPORTS_SHAW_FULFILLED: {
      return {
        ...state,
        selectedReportTableData: action.payload.data.data.custom_report,
        fetchingTableData: false,
      }
    }
    case actions.CUSTOM_REPORTS_LOAD_FULFILLED: {
      return {
        ...state,
        allReports: action.payload.data.data.custom_reports,
        fetching: false,
      }
    }

    case actions.CUSTOM_REPORTS_CONSTANTS_LOAD_FULFILLED: {
      return {
        ...state,
        constants: action.payload.data.data.constants,
        fetchingConstants: false,
      }
    }
    case actions.CUSTOM_REPORTS_UPDATE_FULFILLED:
    case actions.CUSTOM_REPORTS_CREATE_FULFILLED: {
      return {
        ...state,
      }
    }
    case actions.CUSTOM_REPORTS_EXPORT_FULFILLED:
    case actions.CUSTOM_REPORTS_GENERATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
      }
    }
    case actions.CUSTOM_REPORTS_CONSTANTS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingConstants: false,
      }
    }
    case actions.CUSTOM_REPORTS_SHAW_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingTableData: false,
      }
    }
    case actions.CUSTOM_REPORTS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.CUSTOM_REPORTS_EXPORT_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
      }
    }
    case actions.SET_SELECTED_REPORT_ID: {
      return {
        ...state,
        selectedReportId: action.payload.id,
      }
    }

    default:
      return state
  }
}
