import { assetmanagerAPI } from 'utils/assetmanagerUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type optionsType = {
  hideSuccessMsg?: boolean
}

type CategoriesType =
  | 'muqeem_infos'
  | 'mol_infos'
  | 'wps_gosi_infos'
  | 'employees'
  | 'salaries_packages'
  | 'other_financial_information'
  | 'leave_requests'
  | 'loans'
  | 'family_details'
  | 'assets'
  | 'attendance_logs'
  | 'bupa_insurances'
  | 'leaves'
  | 'shift_templates'
  | 'payroll_adjustments'
  | 'approved_overtimes'
  | 'opening_balance'
  | 'custom_fields'
  | 'upload_own_sheet'
  | 'new_onboarding_employees'

export type TCategories = {
  id: number
  details: number
  employee_id: number
  process_progress: number
  processing_time: string
  name: string
  title: string
  status: string
  s3_url: string
  category: string
  error_file_url: string
  updated_at: Date
  uploaded_time: Date
  completed_time: Date
  category_value: string
  type: CategoriesType
  /* 
   1- file data is being processed after mapping
   5- file mapping needs to be done 
   6- canceling file processing in progress
   7- file processing canceled for Gov files
  */
  status_value: 1 | 2 | 3 | 4 | 5 | 6 | 7
  metadata: {
    filename: string
    failed_count: number
    success_count: number
    file_from?: string
  }
}

export function assetmanagerBulkUpload(params = {}): Action {
  return {
    type: actions.ASSETMANAGER_BULK_UPLOAD,
    payload: assetmanagerAPI('/v1/bulk_upload', params, 'GET'),
  }
}

export function assetmanagerBulkUploadCategoriesLoad(params = {}, baseUrl?: string): Action {
  return {
    type: actions.ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD,
    payload: assetmanagerAPI('/v1/bulk_upload/categories', params, 'GET', null, baseUrl),
  }
}

export function assetmanagerBulkUploadImportPost(
  data = {},
  params = {},
  options?: optionsType,
): Action {
  return {
    type: actions.ASSETMANAGER_BULK_UPLOAD_IMPORT_POST,
    payload: new Promise((resolve, reject) => {
      assetmanagerAPI('/v1/bulk_upload', params, 'POST', data)
        ?.then((response) => resolve({ options, ...response }))
        .catch((err) => reject(err))
    }),
  }
}

export function resetAssetmanagerBulkUploadImport(): Action {
  return {
    type: actions.RESET_ASSETMANAGER_BULK_UPLOAD_IMPORT,
  }
}
