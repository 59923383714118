export const QUALIFICATIONS_LOAD = 'QUALIFICATIONS_LOAD'
export const QUALIFICATIONS_LOAD_PENDING = 'QUALIFICATIONS_LOAD_PENDING'
export const QUALIFICATIONS_LOAD_FULFILLED = 'QUALIFICATIONS_LOAD_FULFILLED'
export const QUALIFICATIONS_LOAD_REJECTED = 'QUALIFICATIONS_LOAD_REJECTED'

export const QUALIFICATION_LOAD = 'QUALIFICATION_LOAD'
export const QUALIFICATION_LOAD_PENDING = 'QUALIFICATION_LOAD_PENDING'
export const QUALIFICATION_LOAD_FULFILLED = 'QUALIFICATION_LOAD_FULFILLED'
export const QUALIFICATION_LOAD_REJECTED = 'QUALIFICATION_LOAD_REJECTED'

export const QUALIFICATION_CREATE = 'QUALIFICATION_CREATE'
export const QUALIFICATION_CREATE_PENDING = 'QUALIFICATION_CREATE_PENDING'
export const QUALIFICATION_CREATE_FULFILLED = 'QUALIFICATION_CREATE_FULFILLED'
export const QUALIFICATION_CREATE_REJECTED = 'QUALIFICATION_CREATE_REJECTED'

export const QUALIFICATION_UPDATE = 'QUALIFICATION_UPDATE'
export const QUALIFICATION_UPDATE_PENDING = 'QUALIFICATION_UPDATE_PENDING'
export const QUALIFICATION_UPDATE_FULFILLED = 'QUALIFICATION_UPDATE_FULFILLED'
export const QUALIFICATION_UPDATE_REJECTED = 'QUALIFICATION_UPDATE_REJECTED'

export const QUALIFICATION_DELETE = 'QUALIFICATION_DELETE'
export const QUALIFICATION_DELETE_PENDING = 'QUALIFICATION_DELETE_PENDING'
export const QUALIFICATION_DELETE_FULFILLED = 'QUALIFICATION_DELETE_FULFILLED'
export const QUALIFICATION_DELETE_REJECTED = 'QUALIFICATION_DELETE_REJECTED'
