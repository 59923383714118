import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LinkIcon } from 'assets/figma-icons/Link.svg'
import { Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { Link } from 'react-router-dom'

// import Progress from 'components/global/ds/Progress';

import { ReactComponent as TaskCheckmark } from 'assets/images/TaskCheckmark.svg'

import styles from './styles.module.css'
import InfoList from '../../ds/InfoList'
import InfoBadge from '../Info/components/InfoBadge'

const InfoItem = ({ items }) => {
  return (
    <Flex
      itemsCenter
      className={styles.badge}
      flexWrap
    >
      {items.map((item, i) => (
        <InfoBadge
          key={i}
          label={item?.name_i18n}
          type="gray"
        />
      ))}
    </Flex>
  )
}
InfoItem.propTypes = {
  items: PropTypes.shape(),
}

const EmployeeAssetsAllocated = ({ details, isCanShowAssets, id }) => {
  if (!isCanShowAssets) return null

  const items = [
    { icon: <TaskCheckmark />, value: <InfoItem items={details} />, label: I18n.t('asset_items') },
  ]

  return (
    <Frame
      title={I18n.t('employee_assets_allocated')}
      titleSuffix={
        <Link
          to={`/employees/${id}/assets`}
          target="_blank"
        >
          <LinkIcon style={{ cursor: 'pointer' }} />
        </Link>
      }
    >
      <InfoList items={items} />
    </Frame>
  )
}

EmployeeAssetsAllocated.propTypes = {
  details: PropTypes.shape(),
  isCanShowAssets: PropTypes.shape(),
  id: PropTypes.number,
}

export default EmployeeAssetsAllocated
