import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { TAttachment } from 'containers/authorised/Assets/types'
import * as actions from './actions'

type TInitState = {
  employee_asset: {
    allocation_attachments?: TAttachment[]
    allocation_date?: string
    employee_id?: number
  }
  fetching: boolean
}

const initialState: TInitState = {
  fetching: false,
  employee_asset: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.ASSET_ALLOCATE_PENDING:
    case actions.ASSET_DEALLOCATE_PENDING:
    case actions.ASSET_ALLOCATION_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ASSET_ALLOCATE_FULFILLED:
    case actions.ASSET_DEALLOCATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ASSET_ALLOCATION_DETAILS_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ASSET_ALLOCATE_REJECTED:
    case actions.ASSET_DEALLOCATE_REJECTED:
    case actions.ASSET_ALLOCATION_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
