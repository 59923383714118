import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import { Action } from 'types/redux'
import * as actions from './actions'

type UpdateCloneLeaveSettingsT =
  | 'starting_balance'
  | 'remaining_balance_onboarding'
  | 'onboarding_annual_leave_policies'
  | 'annual_leave_onboarding'
  | 'public_holidays'
  | 'labor_law_leaves'
  | 'customizing_leaves'

export function onboardingAnnualLeaveEntitlementsLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('employee_leave_days')}`

  return {
    type: actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function onboardingAnnualLeaveEntitlementCreate(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('employee_leave_days')}`

  return {
    type: actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE,
    payload: handleAPI(url, { employee_leave_day: params }, 'POST'),
  }
}

export function onboardingAnnualLeaveEntitlementUpdate(id: number, params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('employee_leave_days')}/${id}`

  return {
    type: actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE,
    payload: handleAPI(url, { employee_leave_day: params }, 'PUT'),
  }
}

export function onboardingAnnualLeaveEntitlementDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('employee_leave_days')}/${id}`

  return {
    type: actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function updateCloneLeaveSettings(name: UpdateCloneLeaveSettingsT): Action {
  const url = `/${API_VERSION_TWO}/onboarding/attendance/clone_leave_settings/${name}`

  return {
    type: actions.CLONE_LEAVES_SETTINGS,
    payload: handleAPI(url, {}, 'PUT'),
  }
}
