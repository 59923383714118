import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  security_policies: {},
  employees: [],
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.SECURITY_POLICIES_LOAD_PENDING:
    case actions.SECURITY_POLICIES_CREATE_PENDING:
    case actions.EMPLOYEES_WITHOUT_EMAILS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SECURITY_POLICIES_LOAD_FULFILLED:
    case actions.EMPLOYEES_WITHOUT_EMAILS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.SECURITY_POLICIES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.SECURITY_POLICIES_LOAD_REJECTED:
    case actions.SECURITY_POLICIES_CREATE_REJECTED:
    case actions.EMPLOYEES_WITHOUT_EMAILS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
