import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type MudadSubscriptionsProps = {
  action_by_details: {
    action_by_i18n: string
    is_deleted: boolean
  }
  addons_notification?: {
    message: {
      sub_title: string
      title: string
    }
    notification_type:
      | 'issuing_invoice'
      | 'subscription_about_to_disable'
      | 'disabled_due_to_late_payment'
      | 'subscription_about_to_expire'
      | 'subscription_is_expired'
  }
  commercial_registration: {
    is_deleted: false
    name_i18n: string
    registration_number: string
  }
  id: number
  invoice_line_item?: {
    invoice: {
      status: 'payment_due' | 'posted' | 'posted' | 'not_paid' | 'voided' | 'pending'
      status_i18n: string
    }
    unit_amount: string
  }
  last_history: {
    action_by_details?: {
      by_employee: boolean
      action_by_name_i18n: string
    }
  }
  mudad_establishment: {
    subscription_end_date: string
    subscription_start_date: string
  }

  status: 'active' | 'inactive' | 'expired' | 'deleted'
  status_i18n: string
  addons_type: 'Mudad'
  organization_id: number | string
}

type AddonType =
  | 'multi_subsidiaries'
  | 'performance_management'
  | 'applicant_tracking_system'
  | 'lumofy'

export type AddonsAndLumofyLicenses = {
  name: AddonType
  name_i18n: string
  status: 'active' | 'inactive' | 'expired' | 'not_paid'
  status_i18n: string
  start_date: string
  end_date: string
  employees_count?: number
  total_price?: string
}

export type TAppsAndAddOns = {
  mudad_subscriptions: MudadSubscriptionsProps[]
  add_ons: AddonsAndLumofyLicenses[]
  lumofy_licenses: AddonsAndLumofyLicenses[]
}

type RequestsState = {
  fetching: boolean
  mudad_subscriptions: MudadSubscriptionsProps[]
  appsAndAddons: TAppsAndAddOns
}

const initialState: RequestsState = {
  fetching: false,
  mudad_subscriptions: [],
  appsAndAddons: {
    add_ons: [],
    mudad_subscriptions: [],
    lumofy_licenses: [],
  },
}

export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action

  switch (action.type) {
    case actions.MUDAD_SUBSCRIPTIONS_PUT_PENDING:
    case actions.MUDAD_SUBSCRIPTIONS_LOAD_PENDING:
    case actions.ADDONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.MUDAD_SUBSCRIPTIONS_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    case actions.MUDAD_SUBSCRIPTIONS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        mudad_subscriptions: payload.data.data.mudad_subscriptions,
      }

    case actions.ADDONS_LOAD_FULFILLED:
      const {
        add_ons = [],
        lumofy_licenses = [],
        mudad_subscriptions = [],
      } = payload?.data?.data || {}
      return {
        ...state,
        appsAndAddons: {
          mudad_subscriptions,
          add_ons: add_ons.filter(
            (item: AddonsAndLumofyLicenses) => item.name !== 'multi_subsidiaries',
          ),
          lumofy_licenses,
        },
        fetching: false,
      }

    case actions.MUDAD_SUBSCRIPTIONS_PUT_REJECTED:
    case actions.MUDAD_SUBSCRIPTIONS_LOAD_REJECTED:
    case actions.ADDONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
