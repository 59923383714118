export const APPS_LOAD = 'APPS_LOAD'
export const APPS_LOAD_FULFILLED = 'APPS_LOAD_FULFILLED'
export const APPS_LOAD_PENDING = 'APPS_LOAD_PENDING'
export const APPS_LOAD_REJECTED = 'APPS_LOAD_REJECTED'

export const APPS_LOOKUPS_LOAD = 'APPS_LOOKUPS_LOAD'
export const APPS_LOOKUPS_LOAD_FULFILLED = 'APPS_LOOKUPS_LOAD_FULFILLED'
export const APPS_LOOKUPS_LOAD_PENDING = 'APPS_LOOKUPS_LOAD_PENDING'
export const APPS_LOOKUPS_LOAD_REJECTED = 'APPS_LOOKUPS_LOAD_REJECTED'

export const POPULAR_APPS_LOAD = 'POPULAR_APPS_LOAD'
export const POPULAR_APPS_LOAD_FULFILLED = 'POPULAR_APPS_LOAD_FULFILLED'
export const POPULAR_APPS_LOAD_PENDING = 'POPULAR_APPS_LOAD_PENDING'
export const POPULAR_APPS_LOAD_REJECTED = 'POPULAR_APPS_LOAD_REJECTED'

export const APP_DETAILS = 'APP_DETAILS'
export const APP_DETAILS_FULFILLED = 'APP_DETAILS_FULFILLED'
export const APP_DETAILS_PENDING = 'APP_DETAILS_PENDING'
export const APP_DETAILS_REJECTED = 'APP_DETAILS_REJECTED'

export const MANAGED_APPS_LOAD = 'MANAGED_APPS_LOAD'
export const MANAGED_APPS_LOAD_FULFILLED = 'MANAGED_APPS_LOAD_FULFILLED'
export const MANAGED_APPS_LOAD_PENDING = 'MANAGED_APPS_LOAD_PENDING'
export const MANAGED_APPS_LOAD_REJECTED = 'MANAGED_APPS_LOAD_REJECTED'
