import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  resignation_requests: [],
  resignation_request: {},
  resignation_reasons: [],
  reasons: {},
  fetching: false,
}

export default function assetRequestsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.RESIGNATION_REQUEST_CREATE_PENDING:
    case actions.RESIGNATION_REQUEST_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.RESIGNATION_REQUESTS_LOAD_FULFILLED:
    case actions.RESIGNATION_REASONS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.RESIGNATION_REQUEST_CREATE_FULFILLED:
    case actions.RESIGNATION_REQUEST_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.RESIGNATION_REQUESTS_LOAD_REJECTED:
    case actions.RESIGNATION_REQUEST_CREATE_REJECTED:
    case actions.RESIGNATION_REQUEST_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
