import React from 'react'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useUsersnapApi } from './UsersnapContext'
import { CannyApi, IsCannyEnable } from './canny'

const UserSnap = () => {
  const { employee, company } = useSelector((state) => state.auth)
  const usersnapApi = useUsersnapApi()

  React.useEffect(() => {
    if (usersnapApi) {
      usersnapApi?.init({
        locale: i18n.language,
        user: {
          userId: employee?.id,
          email: employee?.email,
        },
        custom: {
          'Company Details': {
            Slug: employee?.organization?.slug,
            'Name Ar': employee?.organization?.name_ar,
            'Name En': employee?.organization?.name,
            'Brand En': employee?.organization?.brand_name,
            'Brand Ar': employee?.organization?.brand_name_ar,
            'Industry name': employee?.organization?.industry?.name_i18n,
            Tier: employee?.organization?.tier,
            'Subscription Plan': company?.subscription_plan?.name,
            Id: company?.id,
          },
          'User Details': {
            Name: employee?.full_name_i18n,
            Phone: employee?.telephone,
            Email: employee?.email,
            'Role Type': employee?.role?.role_type,
            Id: employee?.id,
          },
        },
      })
    }
    if (IsCannyEnable && employee?.id) {
      CannyApi({
        name: 'identify',
        data: {
          user: {
            email: employee.email,
            name: employee.full_name_i18n,
            id: employee.id,
            avatarURL: employee?.avatar_thumb,
            customFields: {
              Phone: employee?.telephone,
              Slug: company?.slug,
              'Organization Name Ar': employee.organization?.name_ar,
              'Organization Name En': employee.organization?.name,
              'Industry Name': employee.organization?.industry?.name_i18n,
              'Industry ID': employee.organization?.industry?.id,
              'Subscription Plan': company?.subscription_plan?.name,
              Tier: employee.organization?.tier,
              is_super_admin: employee.is_super_admin,
              'Role Type': employee.role?.role_type,
            },
          },
        },
      })
    }
  }, [usersnapApi, employee])

  return null
}

export default UserSnap
