import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getRequestTranslationKey, customRequestTypePaths } from 'utils/uiHelpers'
import { useForm } from 'react-final-form'
import _, { isEmpty } from 'lodash'

import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import {
  applicableRequestsLoad,
  applicableRequestTypesLoad,
} from 'redux/actions/applicableRequestsActions'
import { NewSelectField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'

const RequestType = ({ employee_id }) => {
  const [types, setTypes] = React.useState([])
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { change } = useForm()
  const { fetching, request_types } = useSelector(({ applicableRequests }) => applicableRequests)

  const pathData = pathname.split('/').find((p) => customRequestTypePaths.includes(p))
  const withCustomTypes = !isEmpty(pathData) || pathname === '/'

  const handleRequestTypesOptions = () => {
    const options = request_types.map((req) => {
      if (typeof req === 'string') {
        return {
          value: req,
          label: _.startCase(_.toLower(getRequestTranslationKey(req))),
        }
      }
      return {
        value: req.id,
        label: req.name_i18n.toLowerCase(),
      }
    })
    setTypes(options)
    change('request_type', null)
  }

  React.useEffect(() => {
    if (employee_id) {
      if (withCustomTypes) {
        dispatch(applicableRequestTypesLoad(employee_id))
      } else {
        dispatch(applicableRequestsLoad(employee_id))
      }
    }
  }, [employee_id])

  React.useEffect(() => {
    handleRequestTypesOptions()
  }, [request_types])

  return (
    <Frame title={I18n.t('request_type')}>
      <NewSelectField
        name="request_type"
        testId="request-type"
        placeholder={I18n.t('select')}
        options={types}
        maxMenuHeight={208}
        disabled={fetching || !employee_id}
        validate={required}
      />
    </Frame>
  )
}

RequestType.propTypes = {
  employee_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default RequestType
