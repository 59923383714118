import { serverEnvironment } from 'env'

export const IsCannyEnable =
  import.meta.env.UkVBQ1RfQVBQX0NBTk5ZX0tFWQ && serverEnvironment !== 'dev'

type AgrsTypes = {
  name: string
  data?: Record<string, string> | object
}

export const CannyApi = (args: AgrsTypes): void | null => {
  if (typeof window.Canny !== 'function') return null

  if (args?.name === 'authenticateCannyLink') {
    return window.Canny('authenticateCannyLink', 'https://jisr.canny.io/feature-requests')
  }

  return window.Canny(args?.name, {
    appID: import.meta.env.UkVBQ1RfQVBQX0NBTk5ZX0tFWQ,
    ...args?.data,
  })
}
