import { ChangeEvent } from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { Spacer, Checkbox, Flex } from '@jisr-hr/ds'
import { ReactComponent as ArrowIcon } from '@jisr-hr/design-system/dist/assets/icons/down-arrow.svg'
import { ViewedComponent } from 'components/authorised/Performace'
import { CheckListDataTypes } from 'types/sharedComponents'
import styles from './styles.module.css'

type CheckListPropsTypes = {
  childKey?: string
  data: CheckListDataTypes
  labelKey?: string
  listShouldShown: Array<number>
  selected: Array<number>
  singleValue?: boolean
  valueKey?: string
  appendListShouldShown: (data: Array<number>, removedList?: Array<number>) => void
  onChange: (
    data: {
      checked: boolean
      value: number
    },
    childIds?: Array<number>,
  ) => void
}

const CheckItem = ({
  data,
  selected,
  childKey = 'child',
  labelKey = 'label',
  valueKey = 'value',
  singleValue,
  listShouldShown,
  onChange,
  appendListShouldShown,
}: CheckListPropsTypes): JSX.Element => {
  let childIds: Array<number> = []
  const getAllChildren = (da: CheckListDataTypes): Array<number> => {
    da?.[childKey]?.map((ch: CheckListDataTypes) => {
      childIds = [...childIds, ch?.[valueKey]]
      if (!isEmpty(ch?.[childKey])) {
        getAllChildren(ch)
      }
      return null
    })
    return childIds
  }

  const handleShow = (): void => {
    const isAdded = listShouldShown.includes(data?.[valueKey])
    const addedItems = !isAdded ? [data.id] : []
    const removedItems = !isAdded ? [] : [data.id]
    appendListShouldShown(addedItems, removedItems)
  }

  const iconProps = {
    onClick: handleShow,
    className: classNames(
      styles.showIcons,
      !listShouldShown.includes(data?.[valueKey]) && styles.rightShowIcons,
    ),
  }

  const handleOnChange = (da: CheckListDataTypes, chk?: boolean): void => {
    const checked = Boolean(chk)
    let childList: Array<number> = []
    getAllChildren(da)
    if (!isEmpty(da?.[childKey]) && checked) {
      childList = [...new Set(childIds)]
    }
    onChange({ value: da?.[valueKey], checked }, childList)

    if (checked && !singleValue) {
      const unselectedChilds = childIds.filter((ch) => !selected.includes(ch))
      unselectedChilds.forEach((value) => setTimeout(() => onChange({ checked, value }), 1))
    }
    appendListShouldShown([...childList, da?.[valueKey]])
    childIds = []
  }

  return (
    <ul className={styles.checkItemContainer}>
      {data?.[valueKey] && (
        <>
          <Spacer height={8} />
          <Flex>
            <ViewedComponent available={!isEmpty(data?.[childKey])}>
              <ArrowIcon {...iconProps} />
            </ViewedComponent>
            <ViewedComponent available={isEmpty(data?.[childKey])}>
              <Spacer width={20} />
            </ViewedComponent>
            <Spacer width={5} />
            <li
              className={styles.listItem}
              id={`item${data?.[valueKey]}`}
            >
              <Checkbox
                label={data?.[labelKey]}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  handleOnChange(data, event.target.checked)
                }
                active={selected.includes(Number(data?.[valueKey]))}
              />
              <ViewedComponent available={listShouldShown.includes(data?.[valueKey])}>
                <>
                  {data?.[childKey] &&
                    data?.[childKey].map(
                      (d: CheckListDataTypes, i: number): JSX.Element => (
                        <CheckItem
                          key={i}
                          data={d}
                          onChange={onChange}
                          selected={selected}
                          childKey={childKey}
                          labelKey={labelKey}
                          valueKey={valueKey}
                          listShouldShown={listShouldShown}
                          appendListShouldShown={appendListShouldShown}
                        />
                      ),
                    )}
                </>
              </ViewedComponent>
            </li>
          </Flex>
        </>
      )}
    </ul>
  )
}

export default CheckItem
