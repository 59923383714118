import { ReactComponent as attendaneImg } from '@jisr-hr/design-system/dist/assets/icons/attendance_report_icon.svg'
import { ReactComponent as employeeImg } from '@jisr-hr/design-system/dist/assets/icons/employee_report_icon.svg'
import { ReactComponent as finaceImg } from '@jisr-hr/design-system/dist/assets/icons/finace_report_icon.svg'
import { serverEnvironment } from 'env'
import I18n from 'translations/i18n'

export const config = (
  {
    export_attendance_reports,
    export_vacation_settlement_reports,
    export_final_settlement_reports,
    export_employee_data_reports,
    export_leaves_reports,
    export_performance_reports,
    view_reports_and_statistics,
    isPerformanceSupport,
    export_violation_reports,
    isMolEnabled,
    export_payroll_reports,
  },
  subscribed_features,
) => {
  const showFinanceReports =
    export_payroll_reports ||
    export_vacation_settlement_reports ||
    export_employee_data_reports ||
    export_final_settlement_reports

  const isNewPerformanceSupport = subscribed_features?.includes('new_performance_management')

  return [
    showFinanceReports && {
      title: I18n.t('finance_reports'),
      sub_title: I18n.t('finance_reports_here'),
      icon: finaceImg,
      routes: [
        export_payroll_reports && {
          name: I18n.t('payroll'),
          path: '/reports/payroll',
          testId: 'reports_payroll',
        },
        export_payroll_reports && {
          name: I18n.t('pay_slip'),
          path: '/reports/pay_slip',
          testId: 'reports_payroll_slip',
        },
        export_payroll_reports && {
          name: I18n.t('gosi_report'),
          path: '/reports/gosi',
          testId: 'reports_gosi_report',
        },
        export_final_settlement_reports && {
          name: I18n.t('final_settlement_report'),
          path: '/reports/final_settlement',
          testId: 'reports_final_settlement',
        },
        export_employee_data_reports && {
          name: I18n.t('loans_report'),
          path: '/reports/loans',
          testId: 'reports_loans',
        },
        (export_vacation_settlement_reports || export_final_settlement_reports) && {
          name: I18n.t('off_cycle_payruns'),
          path: '/reports/off_cycle_payruns',
          testId: 'off_cycle_payruns',
        },
        export_payroll_reports && {
          name: I18n.t('out_of_payroll_transactions'),
          path: '/reports/out_of_payroll',
          testId: 'reports_out_of_payroll',
        },
        export_payroll_reports && {
          name: I18n.t('outsourcing_employees'),
          path: '/reports/outsourcing_employees',
          testId: 'reports_outsourcing_employees',
        },
        export_payroll_reports &&
          ['aws', 'staging'].includes(serverEnvironment) && {
            name: I18n.t('revenue_report'),
            path: '/reports/revenue_report',
            testId: 'reports_revenues_report',
          },
        export_payroll_reports && {
          name: I18n.t('end_of_service_report'),
          path: '/reports/eos',
          testId: 'reports_end_of_service_report',
        },
        export_payroll_reports && {
          name: I18n.t('ticket_provision_report'),
          path: '/reports/ticket_report',
          testId: 'reports_ticket_provision_report',
        },
        // {
        //   name: I18n.t('label.employee_cost'),
        //   path: '/reports/employee_cost',
        //   testId: 'reports_employee_cost',
        // },
      ].filter(Boolean),
    },
    export_employee_data_reports && {
      title: I18n.t('employees_reports'),
      sub_title: I18n.t('employees_reports_here'),
      icon: employeeImg,
      routes: [
        {
          name: I18n.t('employee_master_report'),
          path: '/reports/employee_master',
          testId: 'reports_employee_master',
        },
        {
          name: I18n.t('headcount_report'),
          path: '/reports/head_count',
          testId: 'reports_head_count',
        },
        {
          name: I18n.t('expiring_documents'),
          path: '/reports/expiring_documents',
          testId: 'reports_expiring_documents',
        },
        {
          name: I18n.t('assets_allocation_report'),
          path: '/reports/assets',
          testId: 'reports_assets',
        },
        {
          name: I18n.t('requests'),
          path: '/reports/requests',
          testId: 'reports_requests',
        },
        {
          name: I18n.t('edit_employee_profile_report'),
          path: '/reports/edit_employee_profile',
          testId: 'reports_edit_employee_profile',
        },
        {
          name: I18n.t('bulk_import_and_export_report'),
          path: '/reports/bulk_import',
          testId: 'reports_bulk_import',
        },
        {
          name: I18n.t('family_details_report'),
          path: '/reports/family_details',
          testId: 'reports_family_details',
        },
      ],
    },
    !!export_attendance_reports && {
      title: I18n.t('attendance_reports'),
      sub_title: I18n.t('attendance_reports_here'),
      icon: attendaneImg,
      routes: [
        {
          name: I18n.t('employee_attendance_details_report'),
          path: '/reports/employee_attendance_details',
          testId: 'reports_employee_attendance_details',
        },
        {
          name: I18n.t('attendance_logs'),
          path: '/reports/attendance_logs',
          testId: 'reports_attendance_logs',
        },
        {
          name: I18n.t('attendance_summary_report'),
          path: '/reports/attendance_summary',
          testId: 'reports_attendance_summary',
        },
        {
          name: I18n.t('attendance_details_report'),
          path: '/reports/attendance_details_report',
          testId: 'reports_attendance_details_report',
        },
        {
          name: I18n.t('approved_overtime_report'),
          path: '/reports/overtime',
          testId: 'reports_approved_overtime_report',
        },
        {
          name: I18n.t('attendance_tracking_report'),
          path: '/reports/attendance_tracking_report',
          testId: 'reports_attendance_tracking_report',
        },
      ],
    },
    !!export_violation_reports &&
      !!isMolEnabled && {
        title: I18n.t('label.reports.violation_reports'),
        sub_title: I18n.t('label.reports.violation_reports'),
        icon: employeeImg,
        routes: [
          {
            name: I18n.t('label.reports.violation_reports_description'),
            path: '/reports/violation_report',
            testId: 'reports_violation_report',
          },
        ],
      },
    view_reports_and_statistics && {
      title: I18n.t('ats_reports'),
      sub_title: I18n.t('ats_reports'),
      icon: employeeImg,
      routes: [
        {
          name: I18n.t('job_reports'),
          path: '/reports/job_reports',
          testId: 'job_reports',
        },
        {
          name: I18n.t('candidate_reports'),
          path: '/reports/candidate_reports',
          testId: 'candidate_reports',
        },
      ],
    },
    export_leaves_reports && {
      title: I18n.t('leaves_reports'),
      sub_title: I18n.t('leaves_reports_here'),
      icon: employeeImg,
      routes: [
        {
          name: I18n.t('leaves_report'),
          path: '/reports/leaves',
          testId: 'reports_leaves',
        },
        {
          name: I18n.t('leave_balance_report'),
          path: '/reports/leave_balance',
          testId: 'reports_leave_balance_report',
        },
      ],
    },
    export_performance_reports &&
      isPerformanceSupport && {
        title: I18n.t('performance_reports'),
        sub_title: I18n.t('performance_reports'),
        icon: employeeImg,
        routes: [
          {
            name: I18n.t('employees_performance'),
            path: '/reports/employees_performance',
            testId: 'reports_employees_performance',
          },
        ],
      },
    export_performance_reports &&
      isNewPerformanceSupport && {
        title: I18n.t('performance_reports'),
        sub_title: I18n.t('performance_reports'),
        icon: employeeImg,
        routes: [
          {
            name: I18n.t('label.performance_summary_report'),
            path: '/reports/employees_performance_summary',
            testId: 'reports_employees_performance_summary',
          },
        ],
      },
  ].filter(Boolean)
}

export const modulesReports = (
  permission_scopes = {},
  permission_details = {},
  subscribedFeatrues = [],
) => {
  const aataAnalysis =
    permission_scopes?.finance_and_payroll_dashboard ||
    permission_scopes?.workforce_dashboard ||
    permission_scopes?.attendance_dashboard ||
    permission_scopes?.custom_dashboard
  return config(permission_scopes, permission_details, subscribedFeatrues).length || aataAnalysis
}
