import React from 'react'
import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import stoc from 'string-to-color'
import Avatar from '@material-ui/core/Avatar'
import {
  Info,
  Reason,
  Workflow,
  Comments,
  Tabs,
  InfoChange,
} from 'components/global/templates/RequestDrawer/components'
import JisrLoader from 'components/global/JisrLoader'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const InfoChangeRequest = () => {
  const style = { width: 40, height: 40 }
  const { loading, request, reloadRequest } = useRequestDetail()

  const changeset = request.changeset || {}
  const oldChange = request.old_changeset || {}

  const newChangeSet = {
    ...(request?.avatar && {
      avatar: (
        <Flex justifyEnd>
          <Avatar
            style={style}
            alt={request?.avatar_file_name}
            src={request?.avatar}
          />
        </Flex>
      ),
    }),
    ...changeset,
  }

  const oldChangeSet = {
    ...oldChange,
    avatar: (
      <Avatar
        alt={oldChange?.avatar}
        src={oldChange?.avatar}
        style={{
          ...style,
          backgroundColor: stoc(request?.request?.pending_from),
        }}
      />
    ),
  }

  const [activeTab, setActiveTab] = React.useState('request_details')
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <React.Fragment>
          <Info
            details={{
              created_by: request.created_by,
              status: request?.status,
              status_i18n: request?.status_i18n,
              type: request?.request_type,
              type_i18n: request?.request_name_i18n,
              created_at: request.created_at,
              requested_on: request.created_at,
              employee: request?.employee,
              metadata: request?.metadata,
              sequential_id: request?.sequential_id,
            }}
          />
          <InfoChange
            items={Object.entries(newChangeSet).map(([key, val]) => {
              return {
                label: i18n.t(key),
                oldValue: oldChangeSet?.[key],
                newValue: val,
                infoChangeRequestType: request?.metadata?.info_change_request_type,
              }
            })}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}

          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </React.Fragment>
      )}
      {activeTab === 'workflow_and_comments' && (
        <React.Fragment>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </React.Fragment>
      )}
    </Flex>
  )
}

export default InfoChangeRequest
