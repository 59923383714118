import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function employeeLeaveTypesLoad(employee_id) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employee_leave_types`
  const method = 'GET'
  return {
    type: 'EMPLOYEE_LEAVE_TYPES_LOAD',
    payload: handleAPI(url, {}, method),
  }
}
