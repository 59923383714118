import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
  payroll_transaction_creation_status: 'loading', // ? 'loading' | 'done'
  creating_payroll_error: '',
}

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.PAYRUN_ADJUSTMENT_CREATE_PENDING:
    case actions.PAYRUN_ADJUSTMENT_UPDATE_PENDING:
    case actions.PAYRUN_ADJUSTMENT_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.PAYRUN_ADJUSTMENT_CREATE_REJECTED:
    case actions.PAYRUN_ADJUSTMENT_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    case actions.PAYRUN_ADJUSTMENT_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.PAYRUN_ADJUSTMENT_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.PAYRUN_ADJUSTMENT_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
