import { Flex, Modal } from '@jisr-hr/ds'
import FilePreview from './FilePreview'

import { FilePreviewModalTypes } from './types'

const FilePreviewModal = ({
  url,
  open,
  onClose,
  title,
  handlePreviewLetter,
}: FilePreviewModalTypes): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
    size="x-large"
    variant="neutral"
    header={{
      title,
      withClose: true,
    }}
  >
    <Flex
      flexCol
      className="h-[85vh]"
    >
      <FilePreview
        url={url}
        handlePreviewLetter={handlePreviewLetter}
      />
    </Flex>
  </Modal>
)

export default FilePreviewModal
