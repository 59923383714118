import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import { isEmpty } from 'lodash'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type RequestStatusesProps = {
  filterKey: string
  filters: Array<string>
}

const RequestStatuses = (props: RequestStatusesProps): JSX.Element => {
  const { request_statuses, tasks_request_statuses } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const isTasks = props.filters.includes('tasks_request_type')
  const optionList = returnBadgeList(isTasks ? tasks_request_statuses : request_statuses)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        request_statuses: [],
      })
    } else {
      handleFilter('request_statuses', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.request_statuses)) {
      setSelectedIds(filter.request_statuses)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.request_statuses])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="label"
      selectedIds={selectedIds as never[]}
      componentType="badges"
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default RequestStatuses
