import { useDispatch, useSelector } from 'utils/hooks'
import { useEffect } from 'react'
import { listOnboardingStepsFetchGroups } from 'redux/organizationOnborading/steps/actionCreators'
import { useModulesList } from 'containers/authorised/NewOnboarding/components/useUpdatedModulesList'
import { Tooltip } from '@jisr-hr/ds-beta'
import { useHistory } from 'react-router-dom'
import { Button, Containers, FeaturedIcon, Flex, Typography } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'

const OnboardingInviteEmployees = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const modulesList = useModulesList()

  const slug = useSelector((s) => s.auth.company?.slug)
  const activatedModules = useSelector((s) => s?.auth?.company?.activated_modules?.length)

  const navigateToEmployeeInvitation = (): void => history.push('/new_onboarding/invitations')

  const handleModuleActivation = (path: string): void => history.push(path)

  useEffect(() => {
    dispatch(listOnboardingStepsFetchGroups('onboarding_leaves'))
  }, [])

  return (
    <>
      {activatedModules ? (
        <Button
          size="small"
          onClick={navigateToEmployeeInvitation}
          label={i18n.t('label.invite_org_name_employees', { company_name: slug })}
        />
      ) : (
        <Tooltip
          maxWidth="335px"
          behavior="popover"
          trigger={
            <Button
              label={i18n.t('label.invite_org_name_employees', { company_name: slug })}
              size="small"
            />
          }
        >
          <Containers
            border
            borderRadius="md"
            width={314}
            height="100%"
            color="gray-dark"
            styles={{ padding: 16 }}
          >
            <Flex style={{ gap: 12 }}>
              <DSIcon name="info-circle" />
              <Typography
                variant="body-new/medium"
                text={i18n.t(
                  'description.you_arent_ready_yet_to_invite_your_company_employees_to_use_jisr',
                )}
                textColor="color/fg/bold"
              />
            </Flex>
          </Containers>
          <Typography
            variant="body-new/semibold"
            textColor="color/fg/light"
            text={i18n.t('description.activate_one_of_the_modules_to_be_able_to_invite_employees')}
          />
          <Flex
            style={{ gap: 12 }}
            flexCol
          >
            {modulesList.map((module, i) => (
              <Containers
                key={i}
                width="100%"
                color="white"
                border
                borderRadius="sm"
                styles={{ padding: 8 }}
              >
                <Flex
                  justifyBetween
                  itemsCenter
                >
                  <Flex
                    style={{ gap: 8 }}
                    itemsCenter
                  >
                    <FeaturedIcon
                      iconName={module.icon}
                      size="sm"
                      colorType="neutral"
                    />
                    <Typography text={i18n.t(module.title)} />
                  </Flex>
                  <Button
                    size="small"
                    variant="ghost"
                    label={i18n.t('label.activate')}
                    trailingIcon={isRtl ? 'arrow-left' : 'arrow-right'}
                    onClick={(): void => handleModuleActivation(module.path)}
                  />
                </Flex>
              </Containers>
            ))}
          </Flex>
        </Tooltip>
      )}
    </>
  )
}

export default OnboardingInviteEmployees
