import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

type InitStateType = {
  fetching: boolean
  invoice_already_generated: boolean
}

const initialState: InitStateType = {
  fetching: false,
  invoice_already_generated: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.MUDAD_INVOICE_GENERATED_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.MUDAD_INVOICE_GENERATED_LOAD_FULFILLED: {
      const { invoice } = payload.data.data
      return {
        ...state,
        invoice_already_generated: invoice?.generated,
        fetching: false,
      }
    }

    case actions.MUDAD_INVOICE_GENERATED_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
