import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { AttendanceRemindersT } from './notificationManagementTypes'

type NotificationType = {
  id: number
  name: string
  value: boolean
  is_applicable: boolean
  is_all_employee_selected: boolean
  number_of_employees: number
  employees: Record<string, unknown>
}

type NotificationManagementState = {
  fetching: boolean
  birthday_notification:
    | {
        value: boolean
      }
    | Record<string, unknown>
  anniversary_notification: NotificationType | Record<string, never>
  new_joining_notification: NotificationType | Record<string, never>
  attendance_reminders: AttendanceRemindersT | null
  errMsg?: string | null
}

const initialState: NotificationManagementState = {
  fetching: false,
  birthday_notification: {},
  anniversary_notification: {},
  new_joining_notification: {},
  attendance_reminders: null,
}

export default function notificationManagementReducer(
  state = initialState,
  action: Action,
): NotificationManagementState {
  const { payload } = action
  switch (action.type) {
    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_PENDING:
    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_PENDING:
    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_PENDING:
    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_PENDING:
    case actions.NEW_JOINING_NOTIFICATION_ENABLED_LOAD_PENDING:
    case actions.NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_PENDING:
    case actions.ATTENDANCE_REMINDERS_LOAD_PENDING:
    case actions.ATTENDANCE_REMINDERS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_FULFILLED:
    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_FULFILLED:
      return {
        ...state,
        anniversary_notification: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_FULFILLED:
    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_FULFILLED:
      return {
        ...state,
        birthday_notification: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.NEW_JOINING_NOTIFICATION_ENABLED_LOAD_FULFILLED:
    case actions.NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_FULFILLED:
      return {
        ...state,
        new_joining_notification: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.ATTENDANCE_REMINDERS_LOAD_FULFILLED:
    case actions.ATTENDANCE_REMINDERS_UPDATE_FULFILLED:
      return {
        ...state,
        attendance_reminders: payload.data.data.organization_configuration,
        fetching: false,
      }

    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_LOAD_REJECTED:
    case actions.IS_BIRTHDAY_NOTIFICATION_ENABLED_UPDATE_REJECTED:
    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_LOAD_REJECTED:
    case actions.IS_ANNIVERSARY_NOTIFICATION_ENABLED_UPDATE_REJECTED:
    case actions.NEW_JOINING_NOTIFICATION_ENABLED_LOAD_REJECTED:
    case actions.NEW_JOINING_NOTIFICATION_ENABLED_UPDATE_REJECTED:
    case actions.ATTENDANCE_REMINDERS_UPDATE_REJECTED:
    case actions.ATTENDANCE_REMINDERS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    default:
      return state
  }
}
