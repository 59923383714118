import { useState } from 'react'
import { Select } from '@jisr-hr/ds-beta'
import { Divider } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

type PickYearDateType = {
  filterKey: string
  filters: string[]
  title: string
}

const years = Array.from({ length: 3 }, (_, i) => {
  const year = (new Date().getFullYear() - i).toString()

  return {
    label: year,
    key: year,
  }
})

const PickYearDate = (props: PickYearDateType): JSX.Element => {
  const { filters, title, filterKey } = props
  const { filter, handleFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const value = years.find((i) => i.key === filter[filterKey])

  return (
    <div className="flex flex-col px-6 py-4 gap-4">
      <FilterBoxHeader
        canShowClear={false}
        handleClearFilter={(): void => undefined}
        handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
        title={title}
      />

      {!isCollapsed && (
        <Select
          placeholder={i18n.t('label.select')}
          options={years}
          labelKey="label"
          valueKey="key"
          onChange={(option) => handleFilter(filterKey, option?.key)}
          value={value}
        />
      )}

      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

export default PickYearDate
