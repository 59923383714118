import { useEffect } from 'react'
import RadioWithContent from 'containers/public/Onboarding/components/RadioWithContent'
import I18n from 'translations/i18n'
import {
  onboardingApplyingLeavesResumptionLoad,
  onboardingApplyingLeavesUpdate,
} from 'redux/leavesOnboarding/ApplyingLeaves/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Spacer } from '@jisr-hr/ds'

const ReturnConfirmation = (): JSX.Element => {
  const dispatch = useDispatch()
  const { leave_resumption, fetching } = useSelector(
    ({ onboardingApplyingLeaves }) => onboardingApplyingLeaves,
  )
  const balance = leave_resumption?.value ? 'yes' : 'no'

  const handleUpdate = (value: { name: string; value: boolean }): void => {
    dispatch(onboardingApplyingLeavesUpdate(value.name, value)).then(() => {
      dispatch(onboardingApplyingLeavesResumptionLoad())
    })
  }

  useEffect(() => {
    dispatch(onboardingApplyingLeavesResumptionLoad())
  }, [dispatch])

  return (
    <>
      <Typography
        variant="title-1"
        text={I18n.t('return_confirmation')}
      />

      <Spacer height="8px" />
      <Typography
        variant="interface/default/m"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('return_confirmation_description', {
          app_name: I18n.t('jisr_translation_name'),
        })}
      />

      <Spacer height="16px" />
      {/* @ts-expect-error Need to RadioWithContent.js to TypeScript */}
      <RadioWithContent
        options={[
          {
            label: I18n.t('require_Return_Confirmation_after_leave'),
            description: I18n.t('require_Return_Confirmation_after_leave_description', {
              app_name: I18n.t('jisr_translation_name'),
            }),
            value: 'yes',
          },
          {
            label: I18n.t('No_confirmation_is_needed'),
            description: I18n.t('No_confirmation_is_needed_description'),
            value: 'no',
          },
        ]}
        disabled={fetching}
        value={balance}
        handleChange={(status: string): void => {
          const data = {
            name: 'is_leave_resumption_request_enabled',
            value: status === 'yes',
          }
          handleUpdate(data)
        }}
      />
    </>
  )
}

export default ReturnConfirmation
