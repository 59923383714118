import { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'utils/hooks'
import i18n from 'translations/i18n'
import { walletsLoad } from 'redux/payroll/JisrPay/actionCreators'
import { Popover } from '@jisr-hr/ds'
import { Badge, Containers, Icon, Typography } from '@jisr-hr/ds-beta'
import classNames from 'classnames'
import ActivateWalletPopup from './ActivateWalletPopup'
import AddMoneyPopup from './AddMoneyPopup'
import styles from './JisrPayWallet.module.css'

const JisrPayWallet: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(walletsLoad())
  }, [])
  const [walletOpen, setWalletOpen] = useState(false)
  const walletFetching = useSelector((s) => s.jisrPay.wallet_fetching)
  const wallet = useSelector((s) => s.jisrPay.wallets?.[0])
  const isWalletEnabled = wallet !== undefined
  const walletBalance = new Intl.NumberFormat('en-US').format(
    typeof wallet?.balance === 'number' ? wallet?.balance : parseFloat(wallet?.balance || '0'),
  )
  const iban = wallet?.viban || '0'
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency.currency_type_i18n,
  )

  return (
    <>
      <Popover
        classNameTrigger="self-center"
        className={styles.container}
        showArrow={false}
        position="bottom-end"
        trigger={
          <>
            <Containers
              color="gray"
              borderRadius="sm"
            >
              <div
                className={classNames(
                  'flex justify-center items-center gap-2 hover:cursor-pointer px-[12px] py-[10px] h-[32px]',
                  walletFetching &&
                    'hover:cursor-not-allowed opacity-[var(--measure-dimension-opacity-disabled)] ',
                )}
                onClick={(): void => {
                  if (walletFetching) return
                  setWalletOpen(!walletOpen)
                }}
              >
                <Icon name="wallet-04" />
                <Typography
                  text={
                    isWalletEnabled ? `${currency} ${walletBalance}` : i18n.t('label.jisr_wallet_')
                  }
                  className="shrink-0"
                />
                <Badge
                  label={i18n.t('label.new')}
                  variant="tinted"
                  color="raspberry"
                  size="small"
                  className="shrink-0"
                />
              </div>
            </Containers>
          </>
        }
        open={walletOpen}
        onClose={(): void => setWalletOpen(false)}
      >
        {isWalletEnabled ? (
          <AddMoneyPopup
            balance={`${currency} ${walletBalance}`}
            iban={iban}
          />
        ) : (
          <ActivateWalletPopup onClose={(): void => setWalletOpen(false)} />
        )}
      </Popover>
    </>
  )
}

export default JisrPayWallet
