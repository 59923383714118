import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { CloseIcon } from 'components/global/icons'
import JisrLoader from 'components/global/JisrLoader'
import IconButton from '@material-ui/core/IconButton'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'

// handleOnCloseIcon only if user click on close icon
const Modal = ({
  open,
  handleClose,
  handleOnClose,
  hideClose,
  title,
  description,
  children,
  size,
  fullWidth,
  width,
  header,
  subHeader,
  fetching,
  grayDesgin,
  noPadding,
  headerDivider,
  contentStyle,
  headerStyle,
  subHeaderStyle,
  className,
  titleColor,
  TitleIcon,
}) => (
  <Dialog
    fullWidth={fullWidth}
    maxWidth={size}
    open={open}
    data-testid="mui_modal"
    onClose={!handleOnClose && handleClose}
    className={classNames(
      'jisr-modal-mu',
      grayDesgin && 'jisr-modal-mu__gray',
      noPadding && 'jisr-modal-no_padding',
      width && 'jisr-modal--fit-content',
      className,
    )}
  >
    {fetching && <JisrLoader absolute />}
    {header && (
      <div
        data-testid="modal-header"
        className={classNames(
          'jisr-modal-mu__header',
          grayDesgin && 'jisr-modal-mu__gray_header',
          headerDivider && 'jisr-modal--header-divider',
        )}
        style={headerStyle}
      >
        <div>
          <Flex itemsCenter>
            {TitleIcon && (
              <>
                <TitleIcon />
                <Spacer width={12} />
              </>
            )}
            <Typography
              text={title}
              variant="title-1"
              testId="modal-header-title"
              style={{ color: titleColor }}
            />
          </Flex>
          {description && (
            <Typography
              text={description}
              variant="body-new/bold"
              testId="modal-header-description"
            />
          )}
        </div>
        {!hideClose && (
          <IconButton
            onClick={handleOnClose || handleClose}
            data-testid="modal-close-button"
          >
            <CloseIcon
              style={{
                width: 22,
                height: 22,
                filter: 'brightness(0)',
              }}
            />
          </IconButton>
        )}
      </div>
    )}
    {subHeader && (
      <div
        data-testid="modal-subheader"
        className={classNames('sub-header', grayDesgin && 'gray-desgin--header')}
        style={subHeaderStyle}
      >
        {subHeader}
      </div>
    )}
    <DialogContent
      style={{
        width,
        ...contentStyle,
      }}
    >
      <div data-testid="modal-content">{children}</div>
    </DialogContent>
  </Dialog>
)

Modal.propTypes = {
  fetching: PropTypes.bool,
  grayDesgin: PropTypes.bool,
  noPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideClose: PropTypes.bool,
  open: PropTypes.bool,
  headerDivider: PropTypes.bool,
  header: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOnClose: PropTypes.func,
  description: PropTypes.string,
  className: PropTypes.string,
  titleColor: PropTypes.string,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentStyle: PropTypes.shape(),
  headerStyle: PropTypes.shape(),
  subHeaderStyle: PropTypes.shape(),
  TitleIcon: PropTypes.node,
}

Modal.defaultProps = {
  open: true,
  size: 'md',
  width: 'inherit',
  header: true,
}
export default Modal
