import i18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  Assignee,
  HiringEmployeesDetails,
  Reason,
} from 'components/global/templates/RequestDrawer/components'
import ReassignHiringTask from 'components/global/templates/RequestDrawer/components/ReassignHiringTask/ReassignHiringTask'
import { format } from 'utils/date'
import DSIcon from 'components/DSIcon'
import { useSelector } from 'utils/hooks'
import { useRequest } from '../../RequestProvider'

const HiringRequestTask = (): JSX.Element => {
  const {
    vars: { request },
  } = useRequest()
  const { fetching_reassign } = useSelector((state) => state.approvals)

  const items = [
    {
      label: i18n.t('label.date_submitted'),
      icon: <DSIcon name="calendar" />,
      value: format(request?.task_info?.created_at, 'EEEE dd MMMM'),
    },
    {
      label: i18n.t('label.date_approved'),
      icon: <DSIcon name="calendar" />,
      value: format(request?.task_info?.approved_at, 'EEEE dd MMMM'),
    },
  ]

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {fetching_reassign && <JisrLoader absolute />}
      <Info
        details={{
          id: request.id,
          erequested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          type_i18n: request.name,
          created_at: request?.request?.created_time,
          due_date: request.due_date,
          requested_on: request?.request?.created_time,
          items,
        }}
      />
      <HiringEmployeesDetails
        noOfVacancies={request?.task_info?.no_of_vacancies}
        title={request?.task_info?.title_i18n}
        hiringMonth={request?.task_info?.month_and_year_i18n}
        description={request?.task_info?.description ?? ''}
      />

      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
      {request?.status === 'Pending' && <ReassignHiringTask />}
      {request?.task_info?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment')}
          details={{ attachments: request?.task_info?.attachments }}
        />
      )}
    </Flex>
  )
}

export default HiringRequestTask
