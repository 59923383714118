export const ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD = 'ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD'
export const ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_PENDING =
  'ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_PENDING'
export const ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_FULFILLED =
  'ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_FULFILLED'
export const ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_REJECTED =
  'ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_REJECTED'

export const ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD =
  'ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD'
export const ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_PENDING =
  'ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_PENDING'
export const ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_FULFILLED =
  'ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_FULFILLED'
export const ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_REJECTED =
  'ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_REJECTED'

export const ONBOARDING_APPLYING_LEAVES_UPDATE = 'ONBOARDING_APPLYING_LEAVES_UPDATE'
export const ONBOARDING_APPLYING_LEAVES_UPDATE_PENDING = 'ONBOARDING_APPLYING_LEAVES_UPDATE_PENDING'
export const ONBOARDING_APPLYING_LEAVES_UPDATE_FULFILLED =
  'ONBOARDING_APPLYING_LEAVES_UPDATE_FULFILLED'
export const ONBOARDING_APPLYING_LEAVES_UPDATE_REJECTED =
  'ONBOARDING_APPLYING_LEAVES_UPDATE_REJECTED'
