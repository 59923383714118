import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Arrow = ({ color, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={style}
  >
    <path
      fill={getColor(color)}
      fillRule="evenodd"
      d="M967.520815,33.5208153 L976.935029,33.5208153 L976.935029,34.5208153 L967.520815,
        34.5208153 L967.520815,43.9350288 L966.520815,43.9350288 L966.520815,
        34.5208153 L957.106602,34.5208153 L957.106602,33.5208153 L966.520815,33.5208153 L966.520815,
        24.1066017 L967.520815,24.1066017 L967.520815,33.5208153 Z"
      transform="rotate(-45 456.136 1178.636)"
    />
  </svg>
)

export const Close = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill={getColor(color)}
      fillRule="evenodd"
      d="M7.061 6l4.72-4.719A.75.75 0 1 0 10.718.22L6 4.939 1.281.219A.75.75 0 1 0 .22 1.282l4.719 4.72-4.72 4.718a.75.75 0 1 0 1.062 1.061l4.72-4.719 4.718 4.72a.748.748 0 0 0 1.061 0 .75.75 0 0 0 0-1.062L7.061 6z"
    />
  </svg>
)

Close.propTypes = {
  color: PropTypes.string,
}

Arrow.propTypes = {
  color: PropTypes.string,
  style: PropTypes.shape(),
}

Arrow.defaultProps = {
  color: 'slate-grey',
}

export default Arrow
