import Loader from 'components/global/molecules/EmployeeInfo/Loader'

type ViewedComponentProps = {
  alt?: JSX.Element
  available: boolean
  children: JSX.Element
  forceShow?: boolean
  loader?: JSX.Element
  loading?: boolean
  showLoader?: boolean
}

const ViewedComponent = ({
  loading,
  showLoader,
  loader,
  children,
  available,
  forceShow,
  alt,
}: ViewedComponentProps): JSX.Element => {
  const canShow = (available && !loading) || forceShow

  if (showLoader) {
    return <>{canShow ? children : loader || <Loader />}</>
  }
  return <>{canShow ? children : alt || <span />}</>
}

export default ViewedComponent
