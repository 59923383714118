import { useSelector } from 'react-redux'

const usePermissions = () => {
  const { is_super_admin, permission_scopes, permission_details, organization, roles_list } =
    useSelector(({ auth }) => auth.employee)
  const { company } = useSelector(({ auth }) => auth)
  const { showOpenApi, showWebhook } = useSelector(({ enablerDataReducer }) => enablerDataReducer)

  const isPerformanceSupport = company?.subscribed_features?.includes('performance_management')
  const isNewPerformanceSupport = company?.subscribed_features?.includes(
    'new_performance_management',
  )
  const is_jisr_pay_enabled = useSelector((s) => s.auth.employee?.organization.jisrpay_enabled)
  const isMolEnabled = !!organization?.mol_enabled

  const isFlightBookingEnabled = useSelector(
    ({ auth }) =>
      auth.employee?.organization?.benefits_management_enablement?.sub_modules_enablement
        ?.flight_ticket_bookings_enabled,
  )

  const isGlSupported = company?.subscribed_features?.includes('gl_accounting')
  const allowOpenApiGeneration = company?.subscribed_features?.includes('open_api')
  const isAnalysis = company?.subscribed_features?.includes('analytics')
  const isAutomationSupported = company?.subscribed_features?.includes('automation_workflow')
  const isMultiSubSupported = company?.subscribed_features?.includes('multi_subsidiaries')
  const isSuperAdmin = is_super_admin
  const canManageBulkImportSheets =
    !!permission_scopes.bulk_update_employee_information ||
    !!permission_scopes.add_new_employees ||
    !!permission_scopes.manage_salary_information ||
    !!permission_scopes.manage_personal_and_organization_information ||
    !!permission_scopes.manage_assets ||
    !!permission_scopes.manage_other_financial_information ||
    !!permission_scopes.manage_loans ||
    !!permission_scopes.bulk_update_leave_balance ||
    !!permission_scopes.bulk_edit_attendance_records ||
    !!permission_scopes.bulk_edit_approved_overtime_sheet ||
    !!permission_details.edit_on_payroll_table ||
    !!permission_scopes.manage_shifts_and_working_hours
  const isPerformanceEvaluation = company?.subscribed_features?.includes(
    'new_performance_management',
  )

  return {
    ...permission_scopes,
    edit_on_payroll_table: permission_details.edit_on_payroll_table,
    confirm_payroll: permission_details.confirm_payroll,
    export_journal_entries: permission_details.export_journal_entries,
    export_payroll_reports: permission_details.export_payroll_reports,
    make_payments: permission_details.make_payments,
    mark_payroll_payment_as_paid: permission_details.mark_payroll_payment_as_paid,
    payroll_settings: permission_details.payroll_settings,
    suspend_employees: permission_details.suspend_employees,
    view_payment_processing: permission_details.view_payment_processing,
    view_payroll_table: permission_details.view_payroll_table,
    canManageBulkImportSheets,
    isPerformanceSupport,
    is_jisr_pay_enabled,
    isGlSupported,
    allowOpenApiGeneration,
    isSuperAdmin,
    showOpenApi,
    showWebhook,
    isAnalysis,
    isAutomationSupported,
    isMultiSubSupported,
    isPerformanceEvaluation,
    isMolEnabled,
    isNewPerformanceSupport,
    isFlightBookingEnabled,
    roles_list,
  }
}

export default usePermissions
