import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import {
  DelayDeductionPoliciesT,
  DelayDeductionPolicyConfigurationState,
  OrganizationConfigurationType,
} from './delayDeductionPolicyConfigurationTypes'
import * as actions from './actions'

const initialState: DelayDeductionPolicyConfigurationState = {
  organization_configuration: {},
  fetching: false,
  delay_deduction_policies: [],
  pagination: null,
}

export default function reducer(
  state = initialState,
  action: Action,
): DelayDeductionPolicyConfigurationState {
  const { payload } = action
  switch (action.type) {
    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_PENDING:
    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DELAY_DEDICTION_POLICIES_LOAD_PENDING:
    case actions.DELAY_DEDICTION_POLICIES_CREATE_PENDING:
    case actions.DELAY_DEDICTION_POLICIES_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_FULFILLED:
      const delayDeductionPolicyLoad: OrganizationConfigurationType = payload.data.data
      return {
        ...state,
        ...delayDeductionPolicyLoad,
        fetching: false,
      }

    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      const delayDeductionPolicyUpdate: OrganizationConfigurationType = payload.data.data
      return {
        ...state,
        ...delayDeductionPolicyUpdate,
        fetching: false,
      }

    case actions.DELAY_DEDICTION_POLICIES_LOAD_FULFILLED:
      const delayDeductionPolicy: Pick<
        DelayDeductionPolicyConfigurationState,
        'delay_deduction_policies' | 'pagination'
      > = payload.data.data

      return { ...state, ...delayDeductionPolicy, fetching: false }

    case actions.DELAY_DEDICTION_POLICIES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      const newRecord: DelayDeductionPoliciesT = payload.data.data.delay_deduction_policy

      return {
        ...state,
        delay_deduction_policies: [...state.delay_deduction_policies, newRecord],
        fetching: false,
      }

    case actions.DELAY_DEDICTION_POLICIES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      const delay_deduction_policies = [...state.delay_deduction_policies]
      const updatedRecord = payload.data.data.delay_deduction_policy
      return {
        ...state,
        delay_deduction_policies: updateOneListOfRecords(delay_deduction_policies, updatedRecord),
        fetching: false,
      }

    case actions.DELAY_DEDICTION_POLICIES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)

      return { ...state, fetching: false }

    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_UPDATE_REJECTED:
    case actions.DELAY_DEDUCTION_POLICY_CONFIGURATION_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.DELAY_DEDICTION_POLICIES_LOAD_REJECTED:
    case actions.DELAY_DEDICTION_POLICIES_CREATE_REJECTED:
    case actions.DELAY_DEDICTION_POLICIES_UPDATE_REJECTED:
    case actions.DELAY_DEDICTION_POLICIES_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
