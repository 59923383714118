import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import PaymentAlert from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/PaymentAlert'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { useDispatch, useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'
import i18n from 'translations/i18n'
import {
  mudadAllAuthorizedEmployeesLoad,
  mudadAuthorizeCreate,
  mudadAuthorizerDelete,
  mudadSignatoriesLoad,
  mudadVerifyAuthorizer,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import {
  AuthorizedEmployeeType,
  VerificationEmployeeTyp,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import {
  paymentMethodLoad,
  paymentMethodsLoad,
} from 'redux/setting/payrollManagement/paymentMethods/actionCreators'
import {
  BankAccountType,
  PaymentConfigurationType,
} from 'redux/setting/payrollManagement/paymentMethods/reducer'
import { Typography, Button, Spacer } from '@jisr-hr/ds'
import MudadStepsTemplate from '../components/MudadStepsTemplate'
import { usePaymentMethod } from '../../../components/usePaymentMethods'
import AuthorizerAdminBox from './AuthorizerAdminBox'
import AuthorizeAdminForm, { AuthorizerFormPayloadType } from './AuthorizeAdminForm'
import MudadOTPCounter from '../components/MudadOTPForm'

type propsType = {
  afterSubmit?: () => void
}

const AuthorizePaymentAdmins: FC<propsType> = (props) => {
  const dispatch = useDispatch()
  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod, currentAuthorizer, setupModalOpen, isOnBoarding } = paymentCtx

  const authorized_employees = useSelector(
    (s) => s.mudadIntegration.authorized_employees,
    shallowEqual,
  )
  const authorizing = useSelector((s) => s.mudadIntegration.authorizing)
  const fetching_admins = useSelector((s) => s.mudadIntegration.fetching_admins)
  const signatoriesList = useSelector((s) => s.mudadIntegration.mudad_signatories, shallowEqual)
  const paymentMethod = useSelector((s) => s.paymentMethods.current_payment_method)
  const fetchingShowMethod = useSelector((s) => s.paymentMethods.fetchingShowMethod)
  const payment_loading = useSelector((s) => s.paymentMethods.loading)
  const is_mudad_completed = paymentMethod?.mudad_establishment?.is_completed

  const [openCreateForm, setOpenCreateForm] = useState(false)

  const banks = useMemo(() => {
    if (!selectedPaymentMethod) return []
    return selectedPaymentMethod?.bank_accounts.reduce((pre: BankAccountType[], cur) => {
      if (cur.mudad_supported) {
        return [
          ...pre,
          {
            ...cur,
            bank_name: `${cur.bank_name} - ${cur.iban}`,
          },
        ]
      }
      return pre
    }, [])
  }, [selectedPaymentMethod?.bank_accounts])

  const canPass = useMemo(() => {
    return !(authorized_employees?.findIndex((sing) => sing.status === 'authorized') >= 0)
  }, [authorized_employees])

  const verifiedAdmins: VerificationEmployeeTyp[] = useMemo(() => {
    return signatoriesList.reduce((pre: VerificationEmployeeTyp[], acc) => {
      if (acc.status === 'verified') {
        return acc.employee
          ? [
              ...pre,
              {
                ...acc.employee,
                id: acc.id, // ? replace employee id with signatory id
              },
            ]
          : pre
      }
      return pre
    }, [])
  }, [signatoriesList])

  // ? functions
  const handleCreateAuthorizer = async (data: AuthorizerFormPayloadType): Promise<void> => {
    if (!selectedPaymentMethod) return
    try {
      await dispatch(
        mudadAuthorizeCreate({
          bank_account_id: data.bank_account_id,
          mudad_signatory_id: data.mudad_signatory_id,
          registration_id: selectedPaymentMethod.registration_id,
        }),
      )
      const res = await dispatch(
        mudadAllAuthorizedEmployeesLoad(selectedPaymentMethod.registration_id),
      )
      const freshAdminsList = res.value.data.data.authorized_employees as AuthorizedEmployeeType[]
      const authorizer =
        freshAdminsList?.find(
          (it) =>
            it.mudad_signatory_id === data.mudad_signatory_id &&
            it.bank_info?.id === data.bank_account_id,
        ) || null
      setOpenCreateForm(true)
      paymentCtx.setCurrentAuthorizer(authorizer)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error?.response?.data?.error || 'something went wrong',
      })
    }
  }

  const handleVerifyAuthorizerOTP = async (data: { otp_code: string }): Promise<void> => {
    if (!selectedPaymentMethod) return
    if (!currentAuthorizer) return
    try {
      await dispatch(
        mudadVerifyAuthorizer({
          mudad_bank_authorization_id: currentAuthorizer.id,
          otp: data.otp_code,
          mudad_signatory_id: currentAuthorizer.mudad_signatory_id,
          registration_id: selectedPaymentMethod.registration_id,
        }),
      )
      dispatch(mudadAllAuthorizedEmployeesLoad(selectedPaymentMethod.registration_id))

      paymentCtx.setCurrentAuthorizer(null)
      setOpenCreateForm(false)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error?.response?.data?.error || 'something went wrong',
      })
    }
  }

  const handleDeleteAuthorizer = (authorizer: AuthorizedEmployeeType): void => {
    if (!selectedPaymentMethod) return
    const { registration_id } = selectedPaymentMethod
    const options = {
      fetching: false,
      message: i18n.t('are_you_sure'),
      async onSuccess(): Promise<void> {
        paymentCtx.setModalOptions({ ...this, fetching: true })
        try {
          await dispatch(
            mudadAuthorizerDelete({
              mudad_bank_authorization_id: authorizer.id,
              mudad_signatory_id: authorizer.mudad_signatory_id,
              registration_id,
            }),
          )
          paymentCtx.setModalOptions(null)
          paymentCtx.setCurrentAuthorizer(null)
          setOpenCreateForm(false)
        } catch (e) {
          const err = e as { response: { data: { error: string } } }
          paymentCtx.setModalOptions({
            ...this,
            fetching: false,
            subMessage: err?.response?.data?.error || 'Something went wrong',
            onSuccess: undefined,
          })
        }
      },
    }

    paymentCtx.setModalOptions(options)
  }

  const handleNextClick = async (): Promise<void> => {
    if (!selectedPaymentMethod) return
    await dispatch(paymentMethodsLoad())

    const res = await dispatch(paymentMethodLoad(selectedPaymentMethod.id))
    const newSelectedCr = res.value.data.data.commercial_registration as PaymentConfigurationType
    paymentCtx.setSelectedPaymentMethod(newSelectedCr)

    props.afterSubmit?.()

    if (!setupModalOpen) paymentCtx.setSelectedPaymentMethod(null)
    paymentCtx.setMethodModalOpen(null)
    paymentCtx.setCurrentAuthorizer(null)
    paymentCtx.setCurrentSignatory(null)

    if (!is_mudad_completed)
      paymentCtx.setModalOptions({
        variant: 'success',
        fetching: false,
        labelConfirm: i18n.t(isOnBoarding ? 'ok' : 'proceed_to_payment'),
        message: i18n.t('muded_has_been_installed_successfully'),
        hideCancel: true,
        onSuccess() {
          paymentCtx.setModalOptions(null)
        },
        subMessage: i18n.t(
          isOnBoarding
            ? 'Now_you_can_pay_your_employees_directly_from_jisr'
            : 'now_you_can_go_to_payment_and_and_pay_with_one_click_through_muded',
        ),
      })
  }

  useEffect(() => {
    if (selectedPaymentMethod) {
      dispatch(mudadAllAuthorizedEmployeesLoad(selectedPaymentMethod.registration_id))
      if (signatoriesList.length === 0) {
        dispatch(mudadSignatoriesLoad(selectedPaymentMethod.registration_id))
      }
    }
    paymentCtx.setCurrentSignatory(null)
  }, [])

  return (
    <MudadStepsTemplate
      disableBackBtn={false}
      disableNextBtn={canPass || payment_loading || fetchingShowMethod}
      onNextClick={handleNextClick}
    >
      <div>
        <Typography
          variant="interface/default/sm"
          style={{ lineHeight: '20px', color: 'var( --color-base-colors-black-1000)' }}
          text={i18n.t('authorize_with_the_responsible_of_the_payment_process')}
        />
        <Typography
          variant="interface/default/sm"
          style={{ lineHeight: '20px', marginTop: 4, color: 'var(--color-base-colors-grey-800' }}
          text={i18n.t('select_the_employee_that_authorized_to_process_salary_payments')}
        />
      </div>

      {authorized_employees?.length > 0 && (
        <div
          className={mainStyles.info_alert_box}
          style={{ border: 'unset', marginTop: 24 }}
        >
          <Typography
            variant="interface/default/sm"
            style={{
              lineHeight: '20px',
              marginBottom: 24,
              color: 'var(--color-base-colors-grey-800',
            }}
            text={i18n.t('authorized_employees')}
          />
          {authorized_employees.map((authorizer, index) => {
            return (
              <Fragment key={authorizer.id}>
                <AuthorizerAdminBox
                  authorizer={authorizer}
                  onReVerify={(): void => {
                    setOpenCreateForm(true)
                    paymentCtx.setCurrentAuthorizer(authorizer)
                  }}
                  onDeleteClick={(): void => handleDeleteAuthorizer(authorizer)}
                />
                {index + 1 !== authorized_employees.length && (
                  <Spacer
                    width={0}
                    height={16}
                  />
                )}
              </Fragment>
            )
          })}
          {!openCreateForm && (
            <Button
              label={i18n.t('authorize_other_employee')}
              size="small"
              variant="ghost"
              style={{ marginTop: 24 }}
              onClick={(): void => setOpenCreateForm(true)}
              leadingIcon="plus"
            />
          )}
        </div>
      )}

      {((!fetching_admins && authorized_employees?.length <= 0) || openCreateForm) && (
        <>
          <PaymentAlert
            style={{ marginBlock: 24 }}
            title={i18n.t('make_sure_that_is_the_persons_are_authorized')}
          >
            <Typography
              variant="interface/default/sm"
              style={{ color: 'var(--color-base-colors-grey-800)' }}
              text={i18n.t('an_otp_code_will_be_sent_note')}
            />
          </PaymentAlert>

          <AuthorizeAdminForm
            initialValues={{
              bank_account_id: currentAuthorizer?.bank_info?.id,
              mudad_signatory_id: currentAuthorizer?.mudad_signatory_id,
              agree: !!currentAuthorizer?.bank_info?.id,
            }}
            banks={banks}
            verifiedAdmins={verifiedAdmins}
            onSubmit={handleCreateAuthorizer}
          />
          <Spacer
            width={0}
            height={24}
          />
          {!!currentAuthorizer && (
            <MudadOTPCounter
              onSubmit={handleVerifyAuthorizerOTP}
              onResend={(): void => {
                if (!currentAuthorizer) return
                handleCreateAuthorizer({
                  bank_account_id: Number(currentAuthorizer.bank_info?.id),
                  mudad_signatory_id: currentAuthorizer.mudad_signatory_id,
                })
              }}
              disabled={authorizing}
              number={currentAuthorizer.remarks?.mobile_number}
              updated_at={currentAuthorizer.updated_at}
            />
          )}

          <Spacer
            width={0}
            height={100}
          />
        </>
      )}
    </MudadStepsTemplate>
  )
}

export default AuthorizePaymentAdmins
