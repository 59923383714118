import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  dueRequestApprovals: [],
  requestApprovals: [],
  requestStatistics: {},
  paginationRequests: {},
  paginationDueRequests: {},
  fetching: false,
  fetchingRequests: false,
}

export default function reducer(state = initialState, action) {
  const {
    due_requests: dueRequestApprovals,
    requests: requestApprovals,
    assigned_requests_count: assignedRequestsCount,
    assigned_tasks_count: assignedTasksCount,
    due_requests_count: dueRequestsCount,
  } = action?.payload?.data?.data ?? {}
  switch (action.type) {
    case actions.INBOX_ASSIGNED_REQUESTS_LOAD_PENDING:
      return {
        ...state,
        fetchingRequests: true,
      }
    case actions.INBOX_REQUEST_STATISTICS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.INBOX_ASSIGNED_REQUESTS_LOAD_FULFILLED:
      return {
        ...state,
        dueRequestApprovals: [
          ...(dueRequestApprovals.pagination?.previous_page ? state.dueRequestApprovals : []),
          ...dueRequestApprovals.approvals,
        ],
        requestApprovals: [
          ...(requestApprovals.pagination?.previous_page ? state.requestApprovals : []),
          ...requestApprovals.approvals,
        ],
        paginationDueRequests: dueRequestApprovals.pagination,
        paginationRequests: requestApprovals.pagination,
        fetchingRequests: false,
      }
    case actions.INBOX_REQUEST_STATISTICS_LOAD_FULFILLED:
      return {
        ...state,
        requestStatistics: {
          assignedRequestsCount,
          assignedTasksCount,
          dueRequestsCount,
        },
        fetching: false,
      }
    case actions.INBOX_ASSIGNED_REQUESTS_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetchingRequests: false,
      }
    case actions.INBOX_REQUEST_STATISTICS_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
    default:
      return state
  }
}
