export const FIXED_SHIFTS_LISTING_LOAD = 'FIXED_SHIFTS_LISTING_LOAD'
export const FIXED_SHIFTS_LISTING_LOAD_PENDING = 'FIXED_SHIFTS_LISTING_LOAD_PENDING'
export const FIXED_SHIFTS_LISTING_LOAD_FULFILLED = 'FIXED_SHIFTS_LISTING_LOAD_FULFILLED'
export const FIXED_SHIFTS_LISTING_LOAD_REJECTED = 'FIXED_SHIFTS_LISTING_LOAD_REJECTED'

export const FIXED_SHIFT_LOAD = 'FIXED_SHIFT_LOAD'
export const FIXED_SHIFT_LOAD_PENDING = 'FIXED_SHIFT_LOAD_PENDING'
export const FIXED_SHIFT_LOAD_FULFILLED = 'FIXED_SHIFT_LOAD_FULFILLED'
export const FIXED_SHIFT_LOAD_REJECTED = 'FIXED_SHIFT_LOAD_REJECTED'

export const FIXED_SHIFT_CREATE = 'FIXED_SHIFT_CREATE'
export const FIXED_SHIFT_CREATE_PENDING = 'FIXED_SHIFT_CREATE_PENDING'
export const FIXED_SHIFT_CREATE_FULFILLED = 'FIXED_SHIFT_CREATE_FULFILLED'
export const FIXED_SHIFT_CREATE_REJECTED = 'FIXED_SHIFT_CREATE_REJECTED'

export const FIXED_SHIFT_UPDATE = 'FIXED_SHIFT_UPDATE'
export const FIXED_SHIFT_UPDATE_PENDING = 'FIXED_SHIFT_UPDATE_PENDING'
export const FIXED_SHIFT_UPDATE_FULFILLED = 'FIXED_SHIFT_UPDATE_FULFILLED'
export const FIXED_SHIFT_UPDATE_REJECTED = 'FIXED_SHIFT_UPDATE_REJECTED'

export const FIXED_SHIFT_DELETE = 'FIXED_SHIFT_DELETE'
export const FIXED_SHIFT_DELETE_FULFILLED = 'FIXED_SHIFT_DELETE_FULFILLED'
export const FIXED_SHIFT_DELETE_REJECTED = 'FIXED_SHIFT_DELETE_REJECTED'

export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEES_PENDING = 'GET_EMPLOYEES_PENDING'
export const GET_EMPLOYEES_FULFILLED = 'GET_EMPLOYEES_FULFILLED'
export const GET_EMPLOYEES_REJECTED = 'GET_EMPLOYEES_REJECTED'

export const GET_CURRENT_TIMESHEET = 'GET_CURRENT_TIMESHEET'
export const GET_CURRENT_TIMESHEET_PENDING = 'GET_CURRENT_TIMESHEET_PENDING'
export const GET_CURRENT_TIMESHEET_FULFILLED = 'GET_CURRENT_TIMESHEET_FULFILLED'
export const GET_CURRENT_TIMESHEET_REJECTED = 'GET_CURRENT_TIMESHEET_REJECTED'

export const REQUESTED_EMPLOYEE_SHIFTS = 'REQUESTED_EMPLOYEE_SHIFTS'
export const REQUESTED_EMPLOYEE_SHIFTS_PENDING = 'REQUESTED_EMPLOYEE_SHIFTS_PENDING'
export const REQUESTED_EMPLOYEE_SHIFTS_FULFILLED = 'REQUESTED_EMPLOYEE_SHIFTS_FULFILLED'
export const REQUESTED_EMPLOYEE_SHIFTS_REJECTED = 'REQUESTED_EMPLOYEE_SHIFTS_REJECTED'

export const RESET_FIXED_SHIFT = 'RESET_FIXED_SHIFT'
