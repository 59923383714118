import { Typography, Flex, Modal } from '@jisr-hr/ds'
import { Table, CustomColumnDef } from '@jisr-hr/ds-beta'
import { usePaymentListSelector } from 'redux/payrollOnboarding/paymentReview/selectors'
import { TPaymentDetails } from 'redux/payrollOnboarding/paymentReview/type'
import I18n from 'translations/i18n'
import { ViewMoreModalProps } from '../../SummaryReview'

type RowDataType = { row: { original: TPaymentDetails } }

const ViewMoreModal = ({ isOpen, onClose }: ViewMoreModalProps): JSX.Element => {
  const { payment_details } = usePaymentListSelector()

  const columns: CustomColumnDef[] = [
    {
      id: 'emp_id',
      header: I18n.t('emp_id'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return (
          <Flex
            flexCol
            style={{ width: 210 }}
          >
            <Typography
              variant="subtitle-2"
              text={item.name_i18n}
            />
            <Typography
              variant="subtitle-2"
              text={item.code}
            />
          </Flex>
        )
      },
    },
    {
      id: 'employment_type',
      header: I18n.t('employment_type'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.employment_type || '-'
      },
    },
    {
      id: 'payment_type',
      header: I18n.t('payment_type'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.payment_type_i18n
      },
    },
    {
      id: 'Salary Type',
      header: I18n.t('salary_type'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.salary_type_i18n || '-'
      },
    },
    {
      id: 'Bank Name',
      header: I18n.t('bank_name'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.bank_name || item.bank_name_i18n || '-'
      },
    },
    {
      id: 'IBAN',
      header: I18n.t('iban_onboarding'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.iban || '-'
      },
    },
    {
      id: 'Salary Card No',
      header: I18n.t('salary_card_no'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.account_number || '-'
      },
    },
    {
      id: 'swift code',
      header: I18n.t('swift_code'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.swift_code || '-'
      },
    },
    {
      id: 'Country',
      header: I18n.t('country'),
      cell: (rowData: RowDataType) => {
        const item = rowData?.row?.original
        return item.country || '-'
      },
    },
  ]

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="x-large"
      header={{ title: I18n.t('employee_payment_details'), withClose: true }}
    >
      <div className="mb-[24px]">
        <Table
          columns={columns}
          data={payment_details}
          tableSize={{ maxHeight: 528 }}
        />
      </div>
    </Modal>
  )
}

export default ViewMoreModal
