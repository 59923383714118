import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { orderBy, omit } from 'lodash'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { UploadingTab } from '@jisr-hr/design-system'
import { Grid } from 'components/global/atoms'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import I18n from 'translations/i18n'
import {
  organizationInfo,
  updateOrganization,
} from 'redux/organizationOnborading/organization/actionCreators'
import {
  answerOnboardingQuestions,
  onboardingQuestionsReset,
  showOnboardingQuestions,
} from 'redux/organizationOnborading/onboardingQuestions/actionCreators'
import GetFields from 'components/GetFields'
import { TAnswers } from 'components/GetFields/type'
import { Form, TextInputField } from 'components/final-form'
import { toBase64 } from 'components/global/HelperFunctions'
import { required } from 'components/global/form/FormValidations'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import StepTemplate from '../../../components/StepTemplate'
import { StepsTypes } from '../../../types/onboardingTypes'
import styles from './style.module.css'

type StrNull = string | null

type FieldType = {
  brand_name: StrNull
  brand_name_ar: StrNull
  name: StrNull
  name_ar: StrNull
  slug?: string
  answers: TAnswers
}

const OrganizationInfo = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { organization, error } = useSelector((s) => s.organization)
  const { questions, fetching } = useSelector(({ onboardingQuestions }) => onboardingQuestions)
  const answers = useMemo(
    () =>
      questions.map((e) => ({
        question_id: e.id,
        option_ids: e.selected_option_ids,
      })),
    [questions.length],
  )

  const [imageUpload, setImageUpload] = useState('')
  const message1 = I18n.t('upload_Savola_Groups_Logo', {
    groups_name: organization.name_i18n,
  })
  const message2 = I18n.t('adjust_your_logo')
  const imgStyle = {
    maxHeight: 256,
    maxWidth: 256,
  }

  let handleNextStep: () => void

  const reciveImage = async (e: ChangeEvent<HTMLInputElement>): Promise<any> => {
    const filename = e.target?.files?.[0]?.name
    const base64 = await toBase64(e.target.files && e.target.files[0])
    dispatch(
      updateOrganization({
        logo: {
          data: base64,
          filename,
        },
      }),
    )
  }

  const removeImage = (): void => {
    dispatch(updateOrganization({ logo: null })).then(() => setImageUpload(''))
  }

  useEffect(() => {
    if (organization?.logo?.file_path && organization.logo?.file_name) {
      setImageUpload(organization.logo.file_path)
    }
  }, [organization.logo])

  const onSubmit = (value: FieldType): void => {
    const data = {
      ...omit(value, ['answers']),
      skip_slug_validation: !value.slug && true,
      brand_name: value.brand_name || null,
      brand_name_ar: value.brand_name_ar || null,
    }

    dispatch(updateOrganization(data)).then(() => {
      dispatch(answerOnboardingQuestions(value.answers, 'org_structure_and_logo'))
      goNextStep?.()
    })
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'org_structure_and_logo' })

  useEffect(() => {
    dispatch(onboardingQuestionsReset())
    dispatch(organizationInfo())
    dispatch(showOnboardingQuestions('org_structure_and_logo'))
  }, [])

  return (
    <StepTemplate
      goNextStep={(): void => handleNextStep()}
      nextBtnType="submit"
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          answers,
          slug: organization.slug,
          name: organization.name,
          name_ar: organization.name_ar,
          brand_name: organization.brand_name,
          brand_name_ar: organization.brand_name_ar,
        }}
        render={({ handleSubmit }): JSX.Element => {
          handleNextStep = handleSubmit
          return (
            <Flex
              flexCol
              className={styles.content}
              style={{ gap: 48 }}
            >
              <Flex flexCol>
                <Typography
                  variant="subheading"
                  text={I18n.t('whats_the_name_of_your_organization')}
                />
                <Spacer height="16px" />
                <Flex style={{ gap: 48 }}>
                  {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
                  <TextInputField
                    name="name"
                    placeholder={I18n.t('organization_name_en')}
                    validate={required}
                    style={{ maxWidth: '400px' }}
                  />
                  {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
                  <TextInputField
                    name="name_ar"
                    placeholder={I18n.t('organization_name_ar')}
                    style={{ maxWidth: '400px' }}
                  />
                </Flex>
              </Flex>
              <Flex flexCol>
                <Typography
                  variant="subheading"
                  text={I18n.t('onboarding_brand_name')}
                />
                <Spacer height="16px" />
                <Flex style={{ gap: 48 }}>
                  {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
                  <TextInputField
                    name="brand_name"
                    placeholder={I18n.t('brand_name_en')}
                    style={{ maxWidth: '400px' }}
                  />
                  {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
                  <TextInputField
                    name="brand_name_ar"
                    placeholder={I18n.t('brand_name_ar')}
                    style={{ maxWidth: '400px' }}
                  />
                </Flex>
              </Flex>
              {!!questions.length && (
                <Grid
                  container
                  spacing={4}
                >
                  <FieldArray name="answers">
                    {({ fields }): JSX.Element[] => {
                      return fields.map((name, key) => {
                        const elements = questions[key]
                        return (
                          <Grid
                            item
                            xs={6}
                            key={name}
                          >
                            <GetFields
                              index={key}
                              name={name}
                              fetching={fetching}
                              id={elements.id}
                              menuPlacement="top"
                              required={elements.is_required}
                              input_type={elements.input_type}
                              name_i18n={elements.name_i18n}
                              options={orderBy(elements.options, ['priority'], ['asc'])}
                            />
                          </Grid>
                        )
                      })
                    }}
                  </FieldArray>
                </Grid>
              )}

              <div>
                <Typography
                  variant="body-new/medium"
                  text={imageUpload ? message2 : message1}
                />
                <Spacer height="16px" />
                {!imageUpload && (
                  <UploadingTab
                    accept="image/*"
                    isUploading
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      reciveImage(e)
                    }}
                    errorMessage={error}
                    local="en"
                    label={I18n.t('drag_and_drop')}
                  />
                )}
                {imageUpload && (
                  <>
                    <img
                      src={imageUpload}
                      style={imgStyle}
                      alt="savola logo"
                    />
                    <br />
                    <Typography
                      className="pointer"
                      variant="caption"
                      onClick={removeImage}
                      text={I18n.t('remove')}
                    />
                  </>
                )}
              </div>
            </Flex>
          )
        }}
      />
    </StepTemplate>
  )
}

export default OrganizationInfo
