import { Select } from '@jisr-hr/ds-beta'
import { usePayGroupsLoad } from 'apis/Finance/paygroups/usePaygroups'
import { usePayrunsLoad } from 'apis/Finance/payruns/usePayruns'
import { useState } from 'react'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'
import { useFilters } from './context'

type OptionType = {
  payrun_id: number | null
  paygroup_id: number | null
}

const PaygroupAndPayrun = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { filter, setFilter } = useFilters()
  const [paygroupId, setPaygroupId] = useState<number | undefined>(
    filter.paygroup_with_payrun?.paygroup_id,
  )
  const { data: pay_groups = [] } = usePayGroupsLoad({
    params: { template: 'id_name_only', authorized_to: 'export_payroll_reports' },
    query: { select: (s) => s.data.data.paygroups, queryKey: ['PAY_GROUP_REPORT_KEY'] },
  })

  const { data: payruns = [], isFetching } = usePayrunsLoad(
    { status_in: [6], is_testing_eq: false, paygroup_id: paygroupId },
    {
      query: {
        select: (s) => s.data.data.payruns,
        enabled: !!paygroupId,
      },
    },
  )

  const onHandleFilter = (key: string, val: OptionType): void => {
    setFilter({
      ...filter,
      [key]: val,
    })
  }

  return (
    <div className=" flex flex-col gap-4 px-6 py-4">
      <FilterBoxHeader
        canShowClear={false}
        handleClearFilter={(): void => undefined}
        handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
        title="paygroup"
      />
      {!isCollapsed && (
        <div className="flex flex-col gap-4">
          <Select
            value={pay_groups.find((group) => group.id === paygroupId)}
            label="paygroup"
            labelKey="name"
            valueKey="id"
            options={pay_groups}
            maxMenuHeight={200}
            onChange={(option): void => {
              setPaygroupId(option?.id)
            }}
          />
          <Select
            value={
              payruns.find((payrun) => payrun.id === filter.paygroup_with_payrun?.payrun_id) || null
            }
            label="payrun"
            labelKey="month_year_i18n"
            valueKey="id"
            disabled={!paygroupId || isFetching}
            options={payruns}
            maxMenuHeight={200}
            onChange={(payrun): void => {
              onHandleFilter('paygroup_with_payrun', {
                payrun_id: Number(payrun?.id),
                paygroup_id: Number(paygroupId),
              })
            }}
          />
        </div>
      )}
    </div>
  )
}

export default PaygroupAndPayrun
