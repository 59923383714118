import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import * as actions from './actions'

export function onboardingApplyingLeavesNgativeLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/can_apply_in_negative',
  )}`

  return {
    type: actions.ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingApplyingLeavesResumptionLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/is_leave_resumption_request_enabled',
  )}`

  return {
    type: actions.ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingApplyingLeavesUpdate(
  name: string,
  data: {
    value?: boolean
    is_applicable?: boolean
    name: string
  },
): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('organization_configurations')}/${name}`

  return {
    type: actions.ONBOARDING_APPLYING_LEAVES_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { organization_configuration: data }),
  }
}
