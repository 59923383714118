import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
// import { Employee } from 'redux/authUser/types'
import * as actions from './actions'

export type ApproveNonSaudi = {
  first_name: string
  second_name: string
  third_name: string
  last_name: string
  passport_expiry_date?: string
  worker_company_number: string
  occupation_code: string
  location_code: string
  marital_status: string
  education: string
  contact_type: string
  building_number: string
  unit_number: string
  zip_code: string
  additional_number: string
  street: string
  country_code: string
  city: string
  village_code: string
  po_box: string
  free_text_description2: string
  telephone: string
  extension: string
  fax_number: string
  mobile_number: string
  email: string
}

export type NonSaudiEmployee = {
  registration_number: number
  iqama_number: null
  boarder_number: number
  nationality: number
  employee_id?: number
  birthdate: string
  english_name: null
  sex: number
  joining_date: string
  location_code: number
  passport_issue_date: string
  iqama_expiry_date: string
  occupation_code: string
  contact_type: null
  building_number: string
  unit_number: null
  zip_code: string
  additional_number: string
  street: null
  village_id: string
  district: string
  city: string
  country: string
  pobox: null
  region: string
  free_text_description2: null
  telephon_number: null
  extension: null
  fax_number: null
  mobile_number: null
  email_id: null
  is_applicable: false
  reason?: string
}

type TOption = {
  code: string
  title: string
  title_ar: string
  title_i18n: string
}

type TInitState = {
  fetching: boolean
  errorMessage?: string
  employees: NonSaudiEmployee[]
  education: TOption[]
  specialization: TOption[]
  country: TOption[]
  location: TOption[]
  contact_type: TOption[]
  village: TOption[]
  occupation: TOption[]
  insurance_coverage: TOption[]
  marital_status: TOption[]
  student_status: TOption[]
  prisoner_status: TOption[]
  work_type: TOption[]
  contributor_type: TOption[]
  inland_abroad_indicator: TOption[]
  contract_type: TOption[]
  contributor_language: TOption[]
  religion: TOption[]
}

const initialState: TInitState = {
  fetching: false,
  employees: [],
  education: [],
  specialization: [],
  country: [],
  location: [],
  contact_type: [],
  village: [],
  occupation: [],
  insurance_coverage: [],
  marital_status: [],
  student_status: [],
  prisoner_status: [],
  work_type: [],
  contributor_type: [],
  inland_abroad_indicator: [],
  contract_type: [],
  contributor_language: [],
  religion: [],
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload, type } = action

  switch (type) {
    case actions.NON_SAUDI_EMPLOYEES_LOAD_PENDING:
    case actions.LIST_OF_VALUES_LOAD_PENDING:
    case actions.APPROVE_NON_SAUDI_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.NON_SAUDI_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        employees: payload.data.data,
        fetching: false,
      }

    case actions.LIST_OF_VALUES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.APPROVE_NON_SAUDI_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.NON_SAUDI_EMPLOYEES_LOAD_REJECTED:
    case actions.LIST_OF_VALUES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.APPROVE_NON_SAUDI_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errorMessage: handleResponseErr(payload),
        fetching: false,
      }

    case actions.RESET_NON_SAUDI_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
      }

    default:
      return state
  }
}
