import I18n from 'translations/i18n'

export const ExitReEntryType = [
  { id: 'Single', label: I18n.t('single_leave') },
  { id: 'Multiple', label: I18n.t('multiple_leave') },
]

export const period = [
  { id: 2, label: `2 ${I18n.t('months')}` },
  { id: 3, label: `3 ${I18n.t('months')}` },
  { id: 4, label: `4 ${I18n.t('months')}` },
  { id: 5, label: `5 ${I18n.t('months')}` },
  { id: 6, label: `6 ${I18n.t('months')}` },
]
