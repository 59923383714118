import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export const loadSecurityPolicies = (params = {}) => {
  const url = `/${API_VERSION_TWO}/security_policies`
  return {
    type: actions.SECURITY_POLICIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function securityPloiciesCreate(data) {
  const url = `/${API_VERSION_TWO}/security_policies`
  return {
    type: actions.SECURITY_POLICIES_CREATE,
    payload: handleAPI(url, {}, 'POST', { security_policies: data }),
  }
}

export const loadEmployeesWithoutEmails = (params = {}) => {
  const url = `/${API_VERSION_TWO}/security_policies/2fa/invalid_employees `
  return {
    type: actions.EMPLOYEES_WITHOUT_EMAILS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
