import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Workflow,
  Tabs,
  Info,
  Comments,
  Reason,
  RecentLoans,
} from 'components/global/templates/RequestDrawer/components'
import I18n from 'translations/i18n'
import { ReactComponent as MoneyIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/Money.svg'
import { ReactComponent as DivisionMarkIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/DivisionMark.svg'
import { format } from 'utils/date'
import { isValid } from 'date-fns'
import { useSelector } from 'react-redux'
import { hasPermission } from 'components/global/HelperFunctions'

import { useRequestDetail } from '../../hooks'
import { formatMonth } from '../../components/helper'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const LoanRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const { loans } = useSelector(({ employee_loan }) => employee_loan)
  const currentEmployee = useSelector(({ auth }) => auth.employee)
  const [activeTab, setActiveTab] = React.useState('request_details')
  const { currency_type_i18n } = useSelector(
    ({ organizationProfile }) => organizationProfile.organization_currency,
  )
  const isDatevalid =
    isValid(new Date(request.deduction_starting_month)) ||
    isValid(new Date(request.last_applied_loan))
  const items = [
    request.amount && {
      label: I18n.t('loan_amount'),
      icon: <MoneyIcon />,
      value: `${request.amount} ${currency_type_i18n}`,
    },
    request.monthly_deduction && {
      label: I18n.t('monthly_deduction'),
      icon: <DivisionMarkIcon />,
      value: `${request.monthly_deduction} ${currency_type_i18n}`,
    },
    request.number_of_installments && {
      label: I18n.t('installment'),
      icon: <DivisionMarkIcon />,
      value: `${request.number_of_installments} ${I18n.t('months')}`,
    },
    isDatevalid && {
      label: I18n.t('period_of_deduction'),
      icon: <DivisionMarkIcon />,
      value: `${format(new Date(request.issue_date), `${formatMonth} yyyy`)} - ${format(
        new Date(request.return_date),
        `${formatMonth} yyyy`,
      )}`,
    },
  ].filter(Boolean)

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}

      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              ...request,
              created_by: request.created_by,
              type_i18n: request?.request_name_i18n,
              created_at: request.created_at,
              requested_on: request.created_at,
              employee: request?.employee,
              items,
            }}
          />

          {(request.reason || request.attachments?.length > 0) && <Reason details={request} />}

          <RecentLoans
            canViewLoan={hasPermission(
              currentEmployee,
              request?.employee?.id,
              currentEmployee.permission_scopes.view_loans,
            )}
            details={{
              ...request,
              empId: request?.employee?.id,
              loans,
            }}
          />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </>
      )}
    </Flex>
  )
}

export default LoanRequest
