import React from 'react'
import PropTypes from 'prop-types'
import Checkboxes from './Checkboxes'

function MultipleSelect({ onChange, groups, values = [], testId }) {
  const [valuesSelected, setValuesSelected] = React.useState({})

  function handleOnChange(name, dataValues) {
    const newData = { [name]: dataValues }
    // setValuesSelected({ ...valuesSelected, ...newData });
    onChange({ ...valuesSelected, ...newData })
  }

  React.useEffect(() => {
    setValuesSelected({ ...values })
  }, [values])

  return (
    <div
      data-testid={testId}
      className="jisr-multipleSelect"
    >
      {groups.map((group, index) => (
        <div
          key={`group-${index}`}
          data-testid={`${testId}-${index}`}
          className="jisr-multipleSelect__group"
        >
          <div className="jisr-groupselect__group__title">{group.title}</div>
          <div
            className="scroll-y"
            style={{ height: 250 }}
          >
            <Checkboxes
              testId={`${testId}-${index}-checkboxes`}
              options={group.options}
              values={values[group.name]}
              onChange={(value) => handleOnChange(group.name, value)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

MultipleSelect.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.shape(),
  groups: PropTypes.arrayOf(Object),
  testId: PropTypes.string,
}

export default MultipleSelect
