import i18n from 'translations/i18n'

export const ONBOARDING_FORM_STEPS = [
  {
    label: i18n.t('label.personal_info'),
    testId: 'label-example1',
  },
  {
    label: i18n.t('label.passport_info'),
    testId: 'label-example2',
  },
  {
    label: i18n.t('label.id_iqama'),
    testId: 'label-example3',
  },
  {
    label: i18n.t('label.address_in_saudi'),
    testId: 'label-example4',
  },
  {
    label: i18n.t('label.address_in_home_country'),
    testId: 'label-example4',
  },
  {
    label: i18n.t('label.emergency_contact'),
    testId: 'label-example4',
  },
]

// eslint-disable-next-line no-shadow
export enum OnboardingStepsEnum {
  PERSONAL_INFO = 0,
  PASSPORT_INFO = 1,
  ID_INFO = 2,
  SAUDI_ADDRESS = 3,
  HOME_COUNTRY_ADDRESS = 4,
  EMERGENCY_CONTACT = 5,
}

export const stepNames = {
  0: 'personal_info',
  1: 'passport',
  2: 'id_iqama',
  3: 'address',
  4: 'address_in_home',
  5: 'emergency_contact',
}

export const activeForm = {
  personal_info: 0,
  passport: 1,
  id_iqama: 2,
  address: 3,
  address_in_home: 4,
  emergency_contact: 5,
}
