import { API_VERSION_TWO } from 'utils/apiUtils'
import { performanceAPI } from 'utils/performanceUtils'
import { Action } from 'types/redux'

import * as actions from './actions'

export function loadOnboardingSteps(withPagination = false): Action {
  const url = `/${API_VERSION_TWO}/onboarding_steps?pagination=${withPagination}`
  const method = 'GET'

  return {
    type: actions.PM_ONBOARDING_LOAD,
    payload: performanceAPI(url, {}, method),
  }
}

export function updateOnboardingSteps(stepNumber: string): Action {
  const url = `/${API_VERSION_TWO}/onboarding_steps/onboarding_form`
  const method = 'PUT'

  return {
    type: actions.PM_ONBOARDING_UPDATE_STEPS,
    payload: performanceAPI(url, {}, method, {
      onboarding_step: { step_number: stepNumber },
    }),
  }
}

export function completeOnboarding(migrate_data = false): Action {
  const url = `/${API_VERSION_TWO}/onboarding_steps/onboarding_form/complete`
  const method = 'PUT'

  return {
    type: actions.PM_ONBOARDING_COMPLETE,
    payload: performanceAPI(url, {}, method, { migrate_data }),
  }
}
