import _ from 'lodash'
import qs from 'qs'
import { AxiosResponse } from 'axios'
import store from 'redux/store'
import { removeAuthToken, removeAuthUser } from 'redux/authUser/actionCreators'
import { unsubscribeFirebase } from 'redux/actions/firebaseActions'
import { getTokenFromDB } from 'firebaseUtils'
import i18n from 'translations/i18n'
import { API_ROOT, ORGANISATION_NAME } from '../env'

const locale = localStorage.getItem('Locale') || 'en'
export function buildQueryString(params = {}): string {
  return qs.stringify(params, { arrayFormat: 'brackets', encode: false })
}

export function buildUrlWithQueryString(url: string, params: Record<string, unknown>): string {
  const paramsQueryString = buildQueryString(params)
  if (_.isEmpty(paramsQueryString)) {
    return url
  }

  if (url.indexOf('?') >= 0) {
    return `${url}&${paramsQueryString}`
  }

  return `${url}?${paramsQueryString}`
}

export function buildDownloadUrl(
  path: string,
  params: Record<string, unknown> = {},
  apiRoot = API_ROOT,
): string {
  const url = apiRoot + path
  const data = {
    ...params,
    slug: ORGANISATION_NAME,
    locale,
  }
  return buildUrlWithQueryString(url, data)
}

export function handleResponseErr(error: { response?: AxiosResponse }): string {
  const response = error?.response
  let errMessage = ''
  const passwordAgeToken = localStorage.getItem('password-age-token')

  if (!navigator.onLine) {
    return i18n.t('internet_error_message')
  }

  if (response && response.data) {
    const { data } = response
    // for handling password age reseting
    if (data.password_reset_token && !passwordAgeToken) {
      localStorage.setItem('password-age-token', data.password_reset_token)
      removeAuthToken()
      window.location.reload()
    }
    // for assetmanger API BE returning the error message in diffrent object
    if (data.errors) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data.errors.message
    }

    errMessage = data.message ? data.message : data.error

    return errMessage
  }

  return 'Something went wrong on server!'
}

export function handleErrorStatusCases(status: number | string): void {
  const isForceRefreshed = window.location.href.includes('#reloaded')
  const isLoggedIn = localStorage.getItem('LoggedIn')
  const path = window.location.hash?.split?.('#/')?.[1] || ''
  const isHomeLogin = !path || path.includes('login')

  switch (status) {
    case 401:
      store.dispatch(removeAuthUser())
      getTokenFromDB({ clear: true }).then((token) => {
        if (token) store.dispatch(unsubscribeFirebase({ token }))
      })
      break

    case 403:
      if (isLoggedIn && !isHomeLogin) {
        if (isForceRefreshed) {
          window.location.href = '/'
        } else {
          window.location.href += '#reloaded'
          window.location.reload()
        }
      }
      break

    default:
      break
  }
}

type errorMsgType = {
  error?: string | { message?: string }
  errors?: { message?: string }
}

export function getErrorMessage(data: errorMsgType): string | undefined {
  if (!navigator.onLine) {
    return i18n.t('internet_error_message')
  }

  const serverError = 'Something went wrong on server!'
  if (data) {
    if (data.errors || data.error) {
      if (_.isString(data.error)) {
        return data.error
      }
      const msg = data.errors?.message || data.error?.message
      return msg
    }
    return serverError
  }
  return serverError
}
