import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import { TCategoriesAxiosResponse, TCategoriesParams } from './useCategories.type'

// ? query keys
const categoryKey = (params?: TCategoriesParams): QueryKey => ['get_categories', params]

// ? functions
export const getCategories = (params: TCategoriesParams): Promise<TCategoriesAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/common/categories`, { params })
}

const getOnboardingCategories = (params: TCategoriesParams): Promise<TCategoriesAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/onboarding/categories`, { params })
}

// ? hooks
/**
 * @endpoint: GET: /v2/onboarding/categories
 * @summary This hook used to list categories
 */
export const useOnboardingCategories = <
  TData = TCategoriesAxiosResponse,
  TError = HTTPValidationError,
>(
  params: TCategoriesParams,
  opts?: {
    query?: UseQueryOptions<TCategoriesAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: categoryKey(params),
    queryFn: () => getOnboardingCategories(params),
  })
}

/**
 * @endpoint: GET: /v2/common/categories
 * @summary This hook used to list categories
 */
export const useCategories = <TData = TCategoriesAxiosResponse, TError = HTTPValidationError>(
  params: TCategoriesParams,
  opts?: {
    query?: UseQueryOptions<TCategoriesAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: categoryKey(params),
    queryFn: () => getCategories(params),
  })
}
