import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classNames from 'classnames'

const MultiSelect = ({
  label,
  error,
  style,
  options,
  valueKey,
  labelKey,
  disabled,
  testId,
  ...props
}) => (
  <div
    className={classNames(error && 'jisrSelect--error')}
    data-testid={`${testId}-container`}
    style={style}
  >
    {label && <div className="jisrSelect__label">{label}</div>}
    <Select
      {...props}
      data-testid={testId}
      isMulti
      closeMenuOnSelect={false}
      menuPlacement="auto"
      options={options}
      getOptionLabel={(data) => data[labelKey]}
      getOptionValue={(data) => data[valueKey]}
      classNamePrefix="jisrSelect"
      isDisabled={disabled}
      styles={{
        control: (base, state) => ({
          ...base,
          borderColor: 'var(--color-brand-primary-default-new)',
          '&:hover': {
            borderColor: state.isFocused && 'var(--color-brand-primary-default-new)',
            boxShadow: 'none',
          },
          boxShadow: 'none',
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused && 'var(--color-brand-primary-lightest-1-new)',
          ':active': {
            ...base[':active'],
            backgroundColor: 'var(--color-brand-primary-lightest-2-new)',
          },
        }),
      }}
    />
    {error && <small className="jisrSelect__error_msg">{error}</small>}
  </div>
)

MultiSelect.propTypes = {
  label: PropTypes.string,
  testId: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  disabled: PropTypes.bool,
}
export default MultiSelect
