import {
  useQuery,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query'
import { api } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { queryClient } from 'hooks/useReactQuery'
import { HTTPValidationError } from 'types/httpTypes'
import { API_VERSION_TWO } from 'utils/apiUtils'
import {
  PayrunConfigsParamsT,
  PayrunConfigurationsPayloadT,
  PayrunConfigurationsResT,
} from './usePayrunConfigurations.types'

// ? query keys
export const payrunConfigurationsKey = (params?: PayrunConfigsParamsT): QueryKey => [
  'payrun_configurations',
  params,
]
export const onboardingPayrunConfigurationsKey = (): QueryKey => [
  'onboarding_payrun_configurations',
]

// ? query functions
const payrunConfigurationsLoad = (
  params?: PayrunConfigsParamsT,
): Promise<PayrunConfigurationsResT> => {
  return api.get(`/${API_VERSION_TWO}/finance/configurations`, { params })
}

const onboardingPayrunConfigurationsLoad = (): Promise<PayrunConfigurationsResT> => {
  return api.get(`/${API_VERSION_TWO}/onboarding/finance/configurations`)
}

const payrunConfigurationsUpdate = (
  payload: PayrunConfigurationsPayloadT,
): Promise<PayrunConfigurationsResT> => {
  return api.put(`/${API_VERSION_TWO}/finance/configurations/bulk_update`, payload)
}

const onboardingPayrunConfigurationsUpdate = (
  payload: PayrunConfigurationsPayloadT,
): Promise<PayrunConfigurationsResT> => {
  return api.put(`/${API_VERSION_TWO}/onboarding/finance/configurations/bulk_update`, payload)
}

/**
 * @endpoint: GET: /v2/finance/configurations
 * @summary This hook used to List All payrun configurations
 */
export const useGetPayrunConfigurations = <
  TData = PayrunConfigurationsResT,
  TError = HTTPValidationError,
>(opts?: {
  params?: PayrunConfigsParamsT
  query?: UseQueryOptions<PayrunConfigurationsResT, TError, TData>
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? payrunConfigurationsKey(opts?.params),
    queryFn: () => payrunConfigurationsLoad(opts?.params),
    staleTime: 60 * 1000,
  })
}

/**
 * @endpoint: PUT: /v2/finance/configurations
 *
 * @summary This hook used to update payrun configurations
 */
export const useUpdatePayrunConfigurations = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<PayrunConfigurationsResT, TError, PayrunConfigurationsPayloadT>
}): UseMutationResult<PayrunConfigurationsResT, TError, PayrunConfigurationsPayloadT> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (data) => payrunConfigurationsUpdate(data),
    onSuccess: (response, variables, context) => {
      queryClient.setQueriesData(
        payrunConfigurationsKey(
          variables.paygroup_id ? { paygroup_id: variables.paygroup_id } : undefined,
        ),
        response,
      )
      opts?.mutation?.onSuccess?.(response, variables, context)
      Toastr.success(response.data.message)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}

/**
 * @endpoint: GET: /v2/onboarding/finance/configurations
 * @summary This hook used to List All onboarding payrun configurations
 */
export const useGetOnboardingPayrunConfigurations = <
  TData = PayrunConfigurationsResT,
  TError = HTTPValidationError,
>(opts?: {
  query?: UseQueryOptions<PayrunConfigurationsResT, TError, TData>
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? onboardingPayrunConfigurationsKey(),
    queryFn: () => onboardingPayrunConfigurationsLoad(),
  })
}

/**
 * @endpoint: PUT: /v2/onboarding/finance/configurations
 *
 * @summary This hook used to update onboarding payrun configurations
 */
export const useUpdateOnboardingPayrunConfigurations = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<PayrunConfigurationsResT, TError, PayrunConfigurationsPayloadT>
}): UseMutationResult<PayrunConfigurationsResT, TError, PayrunConfigurationsPayloadT> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (data) => onboardingPayrunConfigurationsUpdate(data),
    onSuccess: (response, variables, context) => {
      queryClient.setQueriesData<PayrunConfigurationsResT>(
        [onboardingPayrunConfigurationsKey()[0]],
        response,
      )
      opts?.mutation?.onSuccess?.(response, variables, context)
      Toastr.success(response.data.message)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
