import { Grid } from '@material-ui/core'
import { DatePickerDSField, SelectField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { applicableDelegatorsRequestLoad } from 'redux/leaves/DelegationRequest/actionsCreator'
import I18n from 'translations/i18n'
import Frame from '../../../ds/Frame'
import { useRequest } from '../../../RequestProvider'
import { useRequestDetail } from '../../../hooks'

const DelegationDetails = () => {
  const { values } = useFormState()
  const dispatch = useDispatch()
  const { employeeDelegator } = useSelector(({ delegationRequests }) => delegationRequests)
  const {
    vars: { id },
  } = useRequest()
  const { request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  React.useEffect(() => {
    dispatch(applicableDelegatorsRequestLoad(empId))
  }, [])

  return (
    <Frame title={I18n.t('delegation_details')}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <SelectField
            name="delegator_id"
            label={I18n.t('label.delegate_to')}
            valueKey="id"
            labelKey="name_i18n"
            validate={required}
            options={employeeDelegator}
            placeholder={I18n.t('select_delegator')}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DatePickerDSField
            calenderProps={{
              maxDate: values?.to_date && new Date(values?.to_date),
            }}
            name="from_date"
            validate={required}
            label={I18n.t('start')}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DatePickerDSField
            calenderProps={{
              minDate: values?.from_date && new Date(values?.from_date),
            }}
            name="to_date"
            validate={required}
            label={I18n.t('end')}
          />
        </Grid>
      </Grid>
    </Frame>
  )
}

export default DelegationDetails
