export const OUTSOURCING_COMPANIES_LOAD = 'OUTSOURCING_COMPANIES_LOAD'
export const OUTSOURCING_COMPANIES_LOAD_PENDING = 'OUTSOURCING_COMPANIES_LOAD_PENDING'
export const OUTSOURCING_COMPANIES_LOAD_FULFILLED = 'OUTSOURCING_COMPANIES_LOAD_FULFILLED'
export const OUTSOURCING_COMPANIES_LOAD_REJECTED = 'OUTSOURCING_COMPANIES_LOAD_REJECTED'

export const OUTSOURCING_COMPANY_EMPLOYEES_LOAD = 'OUTSOURCING_COMPANY_EMPLOYEES_LOAD'
export const OUTSOURCING_COMPANY_EMPLOYEES_LOAD_FULFILLED =
  'OUTSOURCING_COMPANY_EMPLOYEES_LOAD_FULFILLED'
export const OUTSOURCING_COMPANY_EMPLOYEES_LOAD_REJECTED =
  'OUTSOURCING_COMPANY_EMPLOYEES_LOAD_REJECTED'

export const OUTSOURCING_COMPANY_LOAD = 'OUTSOURCING_COMPANY_LOAD'
export const OUTSOURCING_COMPANY_LOAD_PENDING = 'OUTSOURCING_COMPANY_LOAD_PENDING'
export const OUTSOURCING_COMPANY_LOAD_FULFILLED = 'OUTSOURCING_COMPANY_LOAD_FULFILLED'
export const OUTSOURCING_COMPANY_LOAD_REJECTED = 'OUTSOURCING_COMPANY_LOAD_REJECTED'

export const OUTSOURCING_COMPANY_CREATE = 'OUTSOURCING_COMPANY_CREATE'
export const OUTSOURCING_COMPANY_CREATE_PENDING = 'OUTSOURCING_COMPANY_CREATE_PENDING'
export const OUTSOURCING_COMPANY_CREATE_FULFILLED = 'OUTSOURCING_COMPANY_CREATE_FULFILLED'
export const OUTSOURCING_COMPANY_CREATE_REJECTED = 'OUTSOURCING_COMPANY_CREATE_REJECTED'

export const OUTSOURCING_COMPANY_UPDATE = 'OUTSOURCING_COMPANY_UPDATE'
export const OUTSOURCING_COMPANY_UPDATE_PENDING = 'OUTSOURCING_COMPANY_UPDATE_PENDING'
export const OUTSOURCING_COMPANY_UPDATE_FULFILLED = 'OUTSOURCING_COMPANY_UPDATE_FULFILLED'
export const OUTSOURCING_COMPANY_UPDATE_REJECTED = 'OUTSOURCING_COMPANY_UPDATE_REJECTED'

export const OUTSOURCING_COMPANY_DELETE = 'OUTSOURCING_COMPANY_DELETE'
export const OUTSOURCING_COMPANY_DELETE_PENDING = 'OUTSOURCING_COMPANY_DELETE_PENDING'
export const OUTSOURCING_COMPANY_DELETE_FULFILLED = 'OUTSOURCING_COMPANY_DELETE_FULFILLED'
export const OUTSOURCING_COMPANY_DELETE_REJECTED = 'OUTSOURCING_COMPANY_DELETE_REJECTED'

export const OUTSOURCING_COMPANY_EDIT = 'OUTSOURCING_COMPANY_EDIT'
export const OUTSOURCING_COMPANY_EDIT_CANCEL = 'OUTSOURCING_COMPANY_EDIT_CANCEL'
