import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Check = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={getColor(color)}
      d="M8.65685425,12.6568542 L16.6568542,12.6568542 L16.6568542,
      14.6568542 L8.65685425,14.6568542 L6.65685425,14.6568542 L6.65685425,8.65685425 L8.65685425,
      8.65685425 L8.65685425,12.6568542 Z"
      transform="rotate(-45 11.657 11.657)"
    />
  </svg>
)

export const Accept = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
  >
    <path
      fill={color ? getColor(color) : '#fff'}
      fillRule="evenodd"
      d="M15.098.409a1.358 1.358 0 0 0-1.943 0L4.986 8.712 2.814 6.815a1.34 1.34 0 0 0-1.917 0c-.53.538-.53 1.41 0 1.949l3.245 2.833a1.34 1.34 0 0 0 1.917 0c.053-.054.098-.114.14-.174.008-.008.017-.013.024-.02l8.875-9.02a1.412 1.412 0 0 0 0-1.974z"
    />
  </svg>
)

Accept.propTypes = {
  color: PropTypes.string,
}

Check.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

Check.defaultProps = {
  size: '24',
  color: 'white',
}

export default Check
