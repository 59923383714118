const Setting = {
  // A
  assign_admin: 'اضافة مدير',
  after_completing_working_hours: 'بعد الاطار الزمني للدوام',
  after_completing_the_whole_required: 'بعد انهاء كامل ساعات العمل المطلوبة',
  attendance_reminders: 'تنبيهات الحضور',
  after_completing_hr_out_of_working_hours:
    'بعد إكمال {{beforeWorking}} من أصل {{workingShift}} عمل',
  after_shift_end_time: ' {{overtimeHr}} بعد نهاية الفترة',
  after_shift_timeframe: '({{overtimeHr}}) بعد الاطار الزمني للدوام',
  After_completing_the_required_working_hours: 'بعد إكمال ساعات العمل المطلوبة',
  after_shift_duration: 'بعد ساعات الدوام',
  any_worked_duration_between_first_end_time:
    '  أي فترة حضور مسجلة بين<span>{{first_time}}</span> و <span>{{second_time}}</span> ستحتسب كعمل إضافي معتمد',
  area_in_english: 'اسم المنطقة بالانجليزي',
  area_in_arabic: 'اسم المنطقة بالعربي',
  automated_and_hassle_free: 'مؤتمتة وسهلة الاستخدام, مربوطه مع البنوك ونظام حماية الأجور.',
  apps: 'التطبيقات ',
  activate_the_offer: 'تفعيل العرض',
  analysis_user_activity: 'Analysis user activity. :AR',
  addition_types: 'انواع الاضافات',
  address_in_english: 'العنوان',
  address_in_arabic: 'العنوان بالعربي',
  admins: 'مشرف النظام',
  admins_weekly_email: 'بريد المشرفين',
  areas_locations: 'المواقع / الفروع',
  async: 'مزامنة',
  approver: 'مسؤول الموافقة',
  attendance_approval: 'تأكيد سجل الحضور والإنصراف',
  attendance_approval_labe: 'تأكيد سجل الحضور والإنصراف مطلوب لمسير الرواتب',
  all_organization: 'كل الشركة',
  all_payments: 'قائمة الدفعات',
  allow_employee_request_loan:
    'السماح بطلب أكثر من سلفة في نفس الفترة للموظف (لا يتأثر حد إجمالي مبالغ السلف بهذا الخيار)',
  accounts_script_number: 'Accounts script number',
  asset_types: 'أنواع العهد',
  Odoo: 'اودو',
  annual_leave_configuartion: 'تعريف الإجازة السنوية',
  attendance_management: 'إدارة الحضور',
  absence: 'غياب',
  deductions: 'الخصومات',
  apply: 'تطبيق',
  apply_to: 'التطبيق على',
  apply_to_all_employees: 'تطبيق على كافة الموظفين',
  apply_for: 'التطبيق على',
  additions: 'الاضافات',
  add_new_city: 'إضافة مدينة جديدة',
  add_new_admin: 'اضافة مدير جديد',
  accomodation_per_diem: 'مبلغ الإقامة اليومية <p>لكل يوم</p>',
  active: 'نشط/فعال',
  assigned_to: 'مسندة إلى',
  assign_new_employee: 'إسناد إلى موظف',
  actions: 'العمليات',
  action_period: 'فترة اخفاء خيارت الاجازة',
  action_period_tip:
    'يمكنك تحديد عدد الأيام التي يمكنك فيها اتخاذ إجراء بعد انتهاء الإجازة في حالة عدم تنشيط طلب تأكيد الاستئناف. بعد الأيام المحددة سيتم إخفاء (التمديد والعودة المبكرة. يجب أن تكون الفترة بين (0 - 30) يوم.',
  area_name: 'اسم المنطقة بالانجليزية',
  area_locations: 'الموقع / المنطقة',
  area_name_in_arabic: 'إسم المنطقة بالعربية',
  admin_email_address: 'البريد الإلكتروني للمسؤول',
  add_step: 'إضافة مرحلة موافقة',
  add_approver_employee: 'إضافة ',
  add_approver: 'إضافة مسؤول الموافقة',
  anniversary_notification: 'إشعارات الذكرى السنوية',
  address_ar: 'العنوان بالعربية',
  absence_policy: 'سياسة الغياب',
  absence_deduct: 'خصم الغياب',
  add_valid_iban_note: 'أضف رقم أيبان صحيح',
  absence_policy_description:
    'سياسة الخصم على الغياب سيتم تطبيقها اذا تم تفعيل خيار "تطبيق الخصم على الغياب" (في صفحة يوم اقتطاع الرواتب )، والخصم في مسير الرواتب سيكون بحسب ما تم اختياره في خيارات احتساب الغياب في صفحة يوم اقتطاع الرواتب، مع تطبيق العقوبة (ان وجدت) بحسب الاعدادات ادناه',
  absence_policy_configuration: 'تعريف سياسة الغياب',
  android_developer: 'مبرمج أندرويد',
  area_name_ar: 'اسم المنطقة بالعربية',
  is_absence_deduction_applicable: 'تطبيق خصم الغياب في مسير الرواتب؟',
  additions_types: 'أنواع الإضافات',
  app_modules: 'وحدات النظام',
  all_locations: 'كل الفروع',
  activate_mudad: 'تفعيل طريقة الدفع (مـدد)',
  authorized_person: 'المفوض',
  account_id: 'رقم الحساب',
  authorized_person_message: ' المفوض <span>({{message}})</span>',
  authorized_persons: 'المفوضين',
  authorize_mudad: 'تفويض مدد',
  add_signatory: 'إضافة موقّع',
  authorize: 'تفويض',
  authorization_failed: 'فشل التفويض',
  authorized_employees_for_xbank: 'الموظفين المفوضين لـ {{name}}',
  authorized_employees_can_process: 'الاشخاص المفوضين بإمكانهم صرف الرواتب عن طريق مدد',
  all_data: 'بيانات الجميع',
  accrual: 'الاستحقاق',
  add_new_job_title: 'إضافة مسمى وظيفي جديد',
  add_new_business_unit: 'إضافة وحدة عمل جديدة',
  add_new_sponsor: 'إضافة سجل تجاري جديد',
  add_new_employment_type: 'إضافة نوع جديد',
  add_document_type: 'إضافة نوع جديد',
  add_new_clearance: 'إضافة مخالصات جديدة',
  add_asset_type: 'إضافة نوع عهدة جديد',
  add_new_holiday: 'إضافة إجازة عيد',
  add_destination: 'إضافة وجهة',
  annual_leave_days: 'الاستحقاق السنوي للإجازة',
  accrue_balance: 'الرصيد المستحق يوميا',
  apply_in_negative: 'التقديم من غير رصيد كافي',
  authenticate_employee_contracts: 'توثيق عقود الموظفين',
  annual_balance_warning:
    'سيؤدي تغيير الرصيد السنوي إلى تغيير الرصيد المستحق الى اليوم. هل تريد المتابعة؟',
  annual_leave_entitlement_auto_convert_desc:
    'تتيح هذه الميزة للموظف ترقية الاستحقاق السنوي تلقائيًا إذا أكمل الموظف 5 سنوات في المنشأة وتحديد الموظفين المعنيين تلقائيًا.',
  add_new_deduction_type: 'إضافة خصم جديد',
  add_new_addition_type: 'إضافة إضافة جديدة',
  add_new_grade: 'إضافة مستوى وظيفي جديد',
  add_new_loan_type: 'إضافة نوع سلفة جديد',
  add_new_benefit_type: 'إضافة نوع بدل جديد',
  add_new_expense_type: 'إضافة نوع مصروف جديد',
  advance_vacation_salary_payment_task: 'مهمة دفع مخالصة الإجازة',
  mudad_is_activated: 'تم تفعيل الدفع عن طريق مدد',
  offline_method_is_activated: 'تم تفعيل الدفع عن طريق ملف البنك',
  assignee: 'المسند إليه',
  add_new_cost_type: 'إضافة نوع تكلفة',
  automatic_resumption_configuration: 'إعدادات الاستئناف التلقائي للإجازة',
  automatic_resumption: 'الاستئناف التلقائي للإجازة',
  absence_exemption_policy: 'سياسة الإعفاء من الحضور والإنصراف',
  advanced_vacation_salary_days_configuration: 'طلب مخالصة إجازة',
  apply_advanced_vacation_salary_days: 'سيتم إنشاء مخالصة الإجازة قبل يوم بداية الإجازة بـ',
  advanced_vacation_salary_minimum_days: 'الحد الأدنى لأيام طلب راتب إجازة مقدما',
  apply_configuration: 'تطبيق الإعدادات',
  admin: 'صلاحية مدير',
  attendance_configurations: 'Attendance configurations:Ar',
  accounting_offline_journal_export: 'تصدير القيود المحاسبية بشكل غير مباشر',
  account: 'الحساب',
  ACCOUNT: 'الحساب',
  add_new_delay_policy: 'اضافه سياسه جديدة',
  attendance_devices_configuration: 'إعدادات اجهزة الحضور',
  assign_people: 'اضافة اشخاص',
  add_overtime_policy: 'اضافة سياسة اوفرتايم جديدة',
  add_people_to_your_list: 'اضافة اشخاص الى القائمة',
  advanced_vacation_description: 'اعدادات مخالصة الإجازة السنوية المدفوعة مقدما',
  apply_advanced_vacation_salary_days_description:
    'عدد الأيام التي تسبق اول يوم إجازة للموظف والتي سيتم إنشاء مخالصة الإجازة السنوية لها',
  apply_advanced_vacation_minimum_days_description:
    'الحد الأدنى لعدد الايام التي يستطيع الموظف التقديم على مخالصة إجازة سنوية مدفوعة مقدما',
  add_new_custom_leave: 'إضافة اجازة مخصصة جديدة',
  add_new_custom_field: 'إضافة حقل مخصص',
  all_holidays: 'جميع الأعياد',
  all_company: 'كل المنشأة',
  add_new_annual_leave: 'إضافة إجازة سنوية جديدة',
  assets_workflow: 'طلب عهدة',
  add_executor_for_the_following_tasks: 'أضف منفذ للمهام التالية',
  all_types: 'كل انواع الإجازات',
  all_emplyees: 'كافة الموظفين',
  add_new_ticket: 'Add new ticket',
  add_new_outsourcing_company: 'إضافة شركة تأجير عمالة جديدة',
  allowances_for_ajeer_employees: 'بدلات موظفو أجير',
  allowance_paid_to_employee: 'هل يتم دفع البدل للموظف ؟',
  allowance_paid_to_employee_hint:
    'نعم: سيتم دفع البدلات في مسير الرواتب، لا: سيتم دفع البدلات خارج مسير الرواتب (في تقرير موظفوا شركات العمالة)',
  allowance_types: 'انواع البدلات',
  ajeer_allowance_types: 'انواع البدلات',
  attendance_reporting_methods: 'وسائل تسجيل الحضور',
  attendance_review: 'مراجعة الحضور',
  add_new_geolocation: 'إضافة موقع جغرافي جديد',
  basic_selected_allowances: 'الراتب الأساسي + بدلات مختارة',
  add_new_department: 'إضافة قسم',
  add_new_commercial_registration: 'إضافة سجل تجاري',
  add_new_cr: 'إضافة سجل تجاري',
  apply_in_negative_description1:
    'غير مفعل: ستكون عدد الأيام المتاحة للموظف لتقديم طلب إجازة سنوية تساوي الرصيد المستحق إلى هذا اليوم ، وبحد أقصى رصيد الموظف المتوقع الى تاريخ آخر يوم في طلب الإجازة المقدم ، ولن يسمح للموظف بتقديم طلب في حالة كان الرصيد غير كافي.',
  apply_in_negative_description2:
    'مفعل: ستكون عدد الأيام المتاحة للموظف لتقديم طلب الإجازة السنوية تساوي كحد أقصى إجمالي الرصيد المتوقع حتى نهاية السنة الحالية.',
  apply_in_negative_configuration_description:
    'لن تكون كافة الطلبات المعلقة بهذا السياسة صالحة. الرجاء إشعار  الموظفين لإعادة تقديم الطلب. -سيتم تطبيق كافة الطلبات الموافق عليها المرتبطة بهذا السياسة على حسب السياسة السابقة .',
  are_you_sure_you_want_to_change_your_payment_method_from_offline_processing_to_mudad:
    'هل تريد تغير طريقة الدفع عن طريق ملف البنك الى مدد ؟',
  are_you_sure_you_want_to_change_your_payment_method_from_mudad_to_offline_processing:
    'هل تريد تغير طريقة الدفع من مدد الى الدفع عن طريق ملف البنك؟',
  add_hourly_rate_policy: 'أضف سياسة جديدة',
  apply_in_payment_processing: 'تطبيق على عملية تحويل الراتب',
  activate_this_option:
    'تفعيل هذا الخيار سيمدد فترة التجربة للموظفين الذين هم تحت فترة التجربة لكامل فترة الإجازة المرضية المطلوبة.',
  activate_this_option_note:
    'ملاحظة :هذا الخيار سيؤثر على الفترة القادمة و لايوجد أي تأثير على الفترة السابقة',
  activate_this_option_holiday:
    'تفعيل هذا الخيار سيمدد فترة التجربة للموظفين الذين هم تحت فترة التجربة لكامل فترة الاعيادة المعرفة.',
  add_new_pay_type: 'إضافة نوع دفع جديد',
  add_allowances: 'إضافة بدل',
  allowed_delay: 'التأخير المسموح به',
  allowed_shortage: 'النقص المسموح به',
  allow_members_to_clock: 'عند التمكين ، سيسمح النظام للأعضاء بالخروج تلقائيًا',
  add_custom: 'إضافة مخصص',
  assign_employees: 'تعيين الموظفين',
  added_employees: 'تمت إضافة <span> {{employees}} </span> إلى نموذج هذا الدوام ',
  attendance_log: 'سجلات الحضور',
  activity_log: 'سجل النشاطات',
  am: 'صباحاً',
  add_payment_method: 'إضافة طريقة دفع جديدة',
  add_payment_method_low: 'أضف طريقة دفع',
  activate_payment_method: 'تفعيل طريقة دفع الرواتب',
  start_setup_payment_method: 'إبدا اعدادات طريقة الدفع',
  annual_leave: 'الإجازة السنوية',
  any_clock_in_before_will_no_record:
    'اي بصمة دخول قبل الساعة ال  <span>{{time}}</span> ستسجل <span>(لايوجد سجل)</span>',
  any_clock_in_after_will_no_record:
    'تسجيل الحضور بعد الساعة  <span>{{time}}</span> ستسجل  <span>(لايوجد سجل)</span>',
  any_clock_out_before_will_no_record:
    'تسجيل الانصراف قبل الساعة <span>{{time}}</span> ستسجل  <span>(لايوجد سجل)</span>',
  any_clock_out_after_will_no_record:
    'تسجيل الانصراف بعد الساعة <span>{{time}}</span> سيسجل <span>(لايوجد سجل)</span>',
  address_details: 'تفاصيل العنوان',
  admin_details: 'تفاصيل مسؤول الدفع',
  add_bank_accounts: 'اضافة حسابات بنكية',
  add_bank_account: 'اضافة حساب بنكي',
  add_another_bank_account: 'اضافة حساب بنكي اخر',
  allow_clock_in_before: 'قبل بداية الدوام بـ',
  allow_clock_out_before: 'قبل نهاية الدوام بـ',
  allow_clock_in_until: 'بعد بداية الدوام بـ',
  allow_clock_out_untill: 'بعد نهاية الدوام بـ',
  all_shift_presets: 'جميع الفترات المعرفة',
  admin_only: 'للمشرف فقط',
  a_manager_of: 'مدير لـ',
  add_users: 'إضافة مستخدم',
  adding_new_employees_saudi_non_saudi: 'إضافة موظفين جدد (سعوديين, غير سعوديين)',
  auto_approve_the_last_hr_of_the_shift_duration:
    'إحتساب تلقائي لآخر <span>{{time}}</span> من الفترة كعمل إضافي معتمد',
  any_worked_duration_between:
    'أي فترة حضور مسجلة بين <span>{{first_time}}</span> و <span>{{second_time}}</span> ستحتسب كعمل إضافي معتمد',
  automatic_overtime: 'عمل إضافي تلقائي',
  auto_overtime: 'عمل إضافي تلقائي',
  assigned_roles: 'الأدوار المسندة',
  admins_have_full_access: 'المشرفون لديهم صلاحيات كاملة لجميع البيانات والإجراءات.',
  add_alt: 'إضافة',
  all_alt: 'للكل',
  add_new_in_house: 'اضافة نوع جديد',
  ajeer_name: 'اسم اجير',
  ajeer_alert: 'By default, all selected allowances will be assigned to Provider AR',
  attendance_integration: 'Attendance Integration:AR',
  attendance_reflection: 'تأثيرات الحضور والانصراف',
  add_type: 'استحقاق جديد',
  add_custom_type: 'اضافة نوع',
  add_approval_cycle: 'اضافة سلسلة موافقات',
  automatic_overtime_disclaimer_short:
    'لا يعمل الوقت الإضافي التلقائي بالمستوى المطلوب حاليًا، لأن.... <span>إقرأ المزيد</span>',
  automatic_overtime_disclaimer:
    'لا يعمل الوقت الإضافي التلقائي بالمستوى المطلوب حاليًا، لأنه يحتسب مدة العمل الاضافي من آخر فترة الدوام المحددة، دون مراعاة ما إذا كان الموظف أكمل ساعات العمل المطلوبة. <p>و نعمل على تطوير خصائص افضل واذكى في الإعتماد التلقائي للعمل الإضافي في التحديثات القادمة. كونوا بالإنتظار.</p>',
  all_leave_types: 'جميع انواع الاجازات',
  assign_manager: 'تعيين مدير',
  annual_leave_description:
    'ستتمكن من اختيار الرصيد المستحق لكل موظف من الأيام المعرفة في هذه القائمة فقط.',
  annual_leave_calculation_description:
    'ستختلف آلية خصم رصيد إجازات الموظفين بحسب الآلية في احتساب أيام الراحة ضمن الإجازة أو عدم احتسابها',
  annual_leave_calculation: 'آلية استقطاع رصيد الإجازة',
  advanced_configuration_description:
    'يمكنك الحد من طلبات الراتب المقدم بوضع حد أدنى من أيام الإجازة للسماح بطلب الراتب مقدمًا',
  approver_employee: 'موظف معتمد',
  approvers: 'عدد المسؤولين',
  active_employees: 'عدد الموظفين <b> {{ count }} </b>',
  are_you_sure_you_want_to_delete_emp: 'هل أنت متأكد أنك تريد حذف؟',
  alinma_bank: 'مصرف الإنماء',
  alinma_bank_reverse: 'Alinma Bank',
  authorize_employees_edit_bank: 'تفويض الموظفين / تعديل البنك',
  confirm_delete_department: 'هل أنت متأكد أنك تريد حذف هذا القسم؟ لا يمكن التراجع عن هذا الإجراء.',
  please_move_department_employees: 'انقل موظفي هذا القسم إلى قسم مختلف لتتمكن من حذف القسم',
  are_you_sure_you_move_departments: 'هل أنت متأكد من رغبتك في نقل هؤلاء الموظفين إلى أقسام أخرى؟',
  asset_utilization: 'الإستفادة من العٌهد',
  asset_type_name: 'نوع العهد',
  allow_employees_to_request_assets_of_this_type: 'السماح للموظفين تقديم طلب لهذا النوع',
  allow_employees_to_request_asset: 'السماح للموظفين بتقديم الطلب',
  asset_statuses: 'حالة العهد',
  approval_cycles: 'سلسلة الموافقات',
  api_keys: 'مفاتيح API',
  api_key: 'مفتاح API',
  api_secret: 'مفتاح سر API',
  api_key_name_title: 'اسم مفتاح API',
  api_key_name_placeHolder: 'اسم مفتاح Api',
  api_key_required: 'مطلوب',
  api_secret_helper_text: 'يستخدم API Secret لمصادقة المنظمة',
  api_key_helper_text: "يتم استخدام مفتاح API لسماح العميل من استخدام قائمة API's المحددة",
  api_secret_visible_only_once:
    'سيكون سر مفتاح API مرئيًا مرة واحدة فقط،<strong> احتفظ بنسخه</strong>',
  activity_history: 'سجلات النشاط',
  add_ons: 'الخدمات الإضافية',
  add_ons_mudad_notifications: 'الإضافات',
  addـonـtype: 'نوع الخدمات الإضافية',
  are_you_sure_you_want_to_activate_mudad_for_cr:
    'هل أنت متأكد من أنك تريد {{status}} مدد الخاص بالسجل التجاري {{crNumber}}',
  activate_confirm:
    'إذا قمت بتأكيد كشوف المرتبات الخاصة بك، فسيتم تطبيق التغييرات في الشهر التالي، هل تريد التجديد؟',
  archive_this_legal_structure: 'Archive this Legal Structure AR',
  archived: 'مؤرشف',
  archive_cr: 'أرشفة',
  add_sub_organization: 'إضافة سجل فرعي',
  add_legal_structure: 'انشاء هيكل تنظيمي',
  new_registration: 'تسجيل جديد',
  add_your_first_registration: 'أضف أول سجل منشأة',
  add_your_first_registration_description:
    'ابدأ بإضافة سجلات المنشأة للتأكد من انسيابية وتوافق المعلومات مع المنصات الحكومية مثل التأمينات الإجتماعية وحماية الأجور (مدد)',
  any_time_of_the_day: 'اي وقت خلال اليوم',
  are_you_sure_you_want_to_cancel_your_account: 'هل أنت متأكد أنك تريد إلغاء اشتراكك؟',
  are_you_really_ready_to_give_system: 'هل أنت مستعد فعلاً لإلغاء إشتراكك في نظام {{app_name}}؟',
  all_employees: 'كل الموظفين',
  add_another_employee: 'أضف موظف آخر',
  at_least_2_fingerprints: 'وجود بصمتين على الأقل',
  after_the_shift_ends: 'بعد انتهاء الدوام',

  // B
  bank_transfer_details: 'تفاصيل التحويل البنكي',
  before_the_shift_starts: 'قبل بداية الدوام',
  before_the_shift_ends: 'قبل نهاية الدوام',
  before_deductions_applied: 'قبل تطبيق سياسات الخصم',
  before_shift_end_time: ' {{overtimeHr}} قبل نهاية الفترة',
  before_shift_timeframe: '({{overtimeHr}}) قبل انتهاء الأطار الزمني للدوام',
  bank_name_ar: 'اسم البنك بالعربي',
  bank_name_reverse: 'Bank Name',
  beneficiary_name: 'أسم المستفيد',
  beneficiary_name_reverse: 'Beneficiary Name',
  beneficiary_name_ar: 'أسم المستفيد بالعربي',
  back_to_annual_leave: 'الرجوع الى الاجازة السنوية',
  back_to_selecting_employees: 'العودة إلى اختيار الموظفين',
  back_to_email_updates_for_admin: 'الرجوع الى بريد المشرفين',
  back_to_email_updates_for_employees: 'الرجوع الى بريد الموظفين',
  back_to_old_interface: 'الرجوع الى الواجهة القديمة',
  back_to_exemption_from_deductions: 'الرجوع الى الإعفاء من الخصميات',
  bank_payment: 'التحويل البنكي',
  business_units: 'وحدات العمل',
  businessunit: 'وحدة العمل',
  business_trip: 'رحلة عمل',
  business_trips: 'رحلات العمل',
  birthday_notification: 'إشعارات عيد الميلاد',
  business_trip_request: 'طلب مهمة عمل',
  buffer_time_for_overnight_shift: 'أقصى مدة للدوام المسائي',
  buffer_time: 'أقصى مدة',
  by_connecting_gosi_model_opening_title:
    'من خلال ربط حسابك في التأمينات الإجتماعية مع {{app_name}}, أنت تسمح {{app_name}} بالقيام بالعمليات التالية:',
  bank_account_number: 'رقم حساب البنك',
  back: 'الرجوع',
  business: 'أيام عمل',
  bank_details: 'تفاصيل البنك',
  benefit_types: 'أنواع البدلات',
  benefits_compensations: 'ملف رفع الرواتب',
  bupa_insurance: 'ملف رفع التأمين الصحي من شركة بوبا',
  buffer_note: 'أقصى مدة للنوبات الليلية ستكون 4 ساعات بعد نهاية الدوام',
  assets_request: 'طلب عهدة',
  business_trip_payment_task: 'مهمة الدفع لمهمة العمل',
  payroll_basic_configuration: 'إعدادات كشف الرواتب الأساسية',
  browse_files: 'تصفح الملفات',
  business_description:
    'احتساب أيام العمل سيتم استثناء ايام الراحة المعرفة للموظف في الدوام, أو في قسم جدولة الدوامات للموظفين من خصم الرصيد عند التقديم عليها',
  by_group: 'مجموعة',
  business_trip_workflow: 'طلب مهمات عمل',
  by_type: 'إجازة معينة',
  by: 'بواسطة',
  by_reminder: 'بـ',
  by_employees: 'موظف',
  before_continue_make_sure_you_provide_note:
    'يرجى التأكد من أن المعلومات المدخلة تخص مالك المنشأة أو الشخص المفوض على حساب التأمينات الإجتماعية للمنشأة المحددة',
  billing: 'الفوترة',
  BILLING: 'الفوترة',
  billing_history: 'الفواتير',
  billing_history_mudad: 'تفاصيل الفواتير',
  billing_history_mudad_notifications: 'أعرف المزيد عن الية الدفع',
  by_none: 'By None:ar',
  bulk_import_for: 'رفع البيانات لـ <strong>{{name}}</strong>',
  bulk_import_and_export: 'قوالب تحديث البيانات',
  bulk_import_and_export_report: 'تقرير قوالب تحديث البيانات',
  break: 'استراحة',
  bulk_import: 'استيراد بالجملة',
  blue: 'أزرق',
  brown: 'بني',
  bank_removed_successfully: 'تم حذف الحساب البنكي بنجاح',
  bank_account_name_iban_format: '{{ name }} - رقم الايبان : {{ iban }}',
  billing_contact_details: 'معلومات التواصل للمدفوعات',
  business_unit_title: 'اسم وحدات العمل',
  business_unit_title_in_ar: 'اسم وحدات العمل بالعربي',
  back_to_billing_page: 'العودة الى صفحة الدفع',
  // C
  confirm_renewal: 'تأكيد التجديد',
  current_settings: 'إعدادتك الحالية: {{ settings }}',
  calculate_overtime_after: 'البدء بعد:',
  change_payment_method: 'تغير طريقة الدفع',
  choose_the_commercial_registration: 'اختر السجل التجاري الذي تريد إضافة هذه الطريقة عليه',
  changing_the_fund_date_msg: 'سيتم تغييرت تاريخ صرف الرواتب, هل تريد الأستمرار؟',
  changing_the_payslip_msg: 'سيتم تغييرت موعد إرسال كشف الرواتب ,هل تريد الأستمرار ؟',
  changing_the_cut_off_date:
    'تغير يوم الاستقطاع سوف يأثر على مسير الرواتب و حسابته ، هل تريد الاستمرار ؟',
  changing_the_salary_reflection:
    'من خلال تغيير أعدادات الراتب (مقسم / منفصل) ، فإنه يقسم المعاملة إلى تفاصيل الراتب أو أن يكون لديك عمود منفصل في كشوف المرتبات ، هل أنت متأكد من المتابعة؟"',
  calculation_of_vacation_salary: 'احتساب مخالصة الإجازة',
  current_plan: 'الباقة',
  cutoff__payment: 'الاستقطاع والدفع',
  cut_off: 'الاستقطاع',
  connected: 'متصل',
  country_name: 'اسم الدولة',
  calculate_cashier_users_revenue: 'احتساب العوائد الخاصة بكل موظف كاشير',
  connect_your_apps_account: 'ربط حسابكم في {{apps}}',
  confirm_mudad_subscription: 'تأكيد الاشتراك في خدمة مدد',
  casual_description: 'اجازه',
  cancel_payment: 'إلغاء التحويل',
  canceling_payment: 'إلغاء التحويل',
  canceling_payment_info: 'أنت بصدد إلغاء التحويل # {{id}}، هل تريد التأكيد؟',
  change_password: 'تغيير كلمة المرور',
  check_connection: 'تأكد من الاتصال',
  company_documents: 'مستندات المنشأ',
  company_document: 'مستندات المنشأ',
  company_docs: 'ملفات الشركة',
  clearances: 'المخالصات',
  cut_off_date: 'يوم إستقطاع الرواتب',
  cost_centers: 'مراكز التكلفة',
  compensation_policy: 'سياسة البدلات',
  company_location: 'الموقع',
  custom_day: 'يوم مخصص',
  code: 'الرمز',
  create_new_letter: 'إنشاء خطاب جديد',
  cr_numbers_validation: 'رقم السجل التجاري غير مطابق للرقم المسجل ',
  mol_numbers_validation: 'رقم التسجيل في مكتب العمل لا يتطابق مع الرقم المسجل',
  calculation_double_overtime:
    'عند الاحتساب بناء على خيار الاوفر تايم المضاعف: فإن لكل ساعة من العمل الإضافي يتم احتسابها بساعة من كل الراتب وساعة من الراتب الاساسي.',
  calculation_single_overtime:
    'عند الاحتساب بناء على خيار الاوفر تايم: فإن لكل ساعة من العمل الإضافي يتم احتسابها بساعة من كامل الراتب و نصف ساعة من الراتب الاساسي.',
  category: 'الفئة',
  carry_type: 'نوع الترحيل',
  calculation_configuration: 'اعدادات احتساب مسير الرواتب',
  city_name: 'اسم المدينة',
  configuration: 'تعريفات / اعدادات',
  contact_person: 'الاسم',
  contact_number: 'رقم التواصل',
  city: 'المدينة',
  country_ar: 'الدولة بالعربية',
  city_ar: 'المدينة بالعربية',
  cities: 'المدن',
  connect: 'إتصال',
  commerical_registrations_crs: 'السجلات التجارية',
  confirm_payment_method: 'تأكيد طريقة الدفع',
  configure_offline_processing: 'تهيئة طريقة الدفع (ملف البنك)',
  confirmation: 'تأكيد',
  cr_name: 'إسم المنشأة',
  cr_name_in_english: 'إسم المنشأة (إنجليزي)',
  cr_name_in_arabic: 'اسم سجل تجاري بالعربي',
  choose_person: 'إختر الموظف',
  choose_person_message: ' إختر الموظف <span>({{message}})</span>',
  choose_the_type: ' اختر نوع العمل الإضافي الذي سيتم حسابه',
  can_an_employee_request_a_loan: 'هل يستطيع الموظف التقديم على أكثر من سلفة؟',
  can_an_employee_request_a_loan_tool_tip:
    'يمكنك التحكم فيما إذا يمكن للموظف التقدم بطلب سلفة أخرى أثناء وجود لديه سلفة أخرى نشطة',
  cost_centers_mapping: 'ربط مراكز التكلفة',
  calendar: 'أيام تقويم',
  carry_forward_title: 'كيف تود ترحيل رصيد الإجازات؟',
  carry_forward_title2: 'ماهو الحد الأعلى للأيام التي تود ترحيلها',
  carry_forward_title3: 'هل تود تطبيق هذه السياسة على جميع الموظفين؟',
  choose_criteria_and_apply: 'قم باختيار المعيار ثم قم بالتأكيد',
  changing_configuration_message:
    'تغيير الإعدادات سيؤدي الى ازالة جميع السياسات التي قمت بإنشاءها، <b>هل انت متأكد؟<b>',
  configuration_salary_reflection_note:
    'هذة الإعدادات تتم لتقسيم المعاملة إلى توزيع الراتب أو الحصول على عمود منفصل في كشوف المرتبات (موظف جديد ، راتب إجازة متداخل ، تقييم سابق) يعتمد على: تقسيم المعاملة إلى توزيع الراتب أو عمود منفصل  في كشوف المرتبات',
  contact_information: 'معلومات التواصل',
  confirm: 'تأكيد',
  can_loan_apply: 'هل يستطيع الموظف التقديم على أكثر من سلفة؟',
  continue_cancellation: 'الاستمرار في الإلغاء',
  configurations: 'الإعدادات',
  costs_types: 'أنواع التكاليف',
  company_id: 'معرف الشركة',
  chart_of_accounts_mapping: 'ربط الشجرة المحاسبية',
  cost_center_types: 'أنواع مراكز التكلفة',
  credits: 'الإضافات',
  calendar_description:
    'احتساب أيام تقويمية سيتم احتساب أيام الراحة المعرفة للموظف في الدوام, أو في قسم جدولة الدوامات للموظفين من خصم الرصيد عند التقديم عليها.',
  carry_forward_description:
    'سياسة ترحيل أرصدة الإجازات هي لتحفيز الموظفين لاستخدام أرصدة إجازاتهم السنوية قبل انتهاء السنة الميلادية, بوضع سياسة عدم ترحيل رصيد, أو حد ترحيل الرصيد بقيمة محددة, لمزيد من المعلومات تابع المقالة التالية',
  carry_balance_description: ' لا تسمح للموظف بترحيل أي رصيد إجازات سنوية للسنة الميلادية القادمة',
  carry_all_balance_description:
    'السماح للموظف بترحيل رصيد إجازاته السنوية للسنة الميلادية القادمة',
  choose_the_bank_for_payroll_processing: 'اختر البنك لعملية كشف الرواتب',
  currency_type: 'نوع العملة',
  currency: 'العملة',
  choose_the_shift_type: 'إختر نوع الدوام',
  create_new_role: 'إنشاء صلاحية جديدة',
  connect_device: 'توصيل جهاز',
  connect_integrate: 'ربط',
  complete_setup: 'إكمال الربط',
  connected_integrate: 'مرتبط',
  connect_your_gosi_account_aamaly: 'ربط حسابك في التأمينات الإجتماعية - منصة أعمالي',
  create_new_allowance: 'انشاء بدل جديد',
  configuration_deduction_note:
    'يمكنك اختيار طريقة احتساب خصم الغياب, التأخير, الخروج المبكر, الأوفر تايم, السجل الغير مكتمل, ما على أساس الراتب الأساسي, أو على أساس مجموع الراتب, أو على أساس الراتب الأساسي ومجموعة من البدلات التي يمكن تحديدها',
  calculation_reflection_time_msg:
    'التأثيرات في سجلات الحضور الناتجة عن التغييرات الجديدة سيتم تحديثها في الساعة {{time}}',
  calculation_reflection_time_new_shift_msg:
    'التأثيرات في سجلات الحضور الناتجة عن إضافة الدوام سيتم تحديثها في الساعة {{time}}',
  calculation_reflection_time_new_holiday_msg:
    'التأثيرات في سجلات الحضور الناتجة عن إضافة الإجازة سيتم تحديثها في الساعة {{time}}',
  calculation_reflection_time_delete_holiday_msg:
    ' التأثيرات في سجلات الحضور الناتجة عن حذف الإجازة سيتم تحديثها في الساعة {{time}}',
  calculation_reflection_time_approving_request_msg:
    ' التأثيرات في سجلات الحضور الناتجة عن الموافقة على طلب {{request_type}} سيتم تحديثها في الساعة {{time}}',
  calculation_reflection_time_cancel_request_msg:
    'التأثيرات في سجلات الحضور الناتجة عن الموافقة على {{request_type}} سيتم تحديثها في الساعة {{time}}',
  calculation_relate_to_new_changes:
    'التأثيرات في سجلات الحضور الناتجة عن التغيرات الجديدة سيتم تحديثها في الساعة {{time}}',
  calculation_end__service_settlement: 'إحتساب تسوية نهاية الخدمة',
  choose_location: 'إختر الموقع',
  choose_radius: 'حدد نصف القطر',
  created_at: 'تاريخ الإنشاء',
  created_at_open_api: 'تاريخ الإنشاء',
  created_by_open_api: 'انشأت بواسطة',
  created_by: 'المنشئ',
  calculation_vacation_eos_settlement: 'إحتساب راتب الإجازة وتسوية نهاية الخدمة',
  create_new_parent_department: 'إضافة إدارة جديدة',
  create_a_letter_template: 'انشاء قالب خطابات',
  cr_registration_number: 'رقم السجل التجاري',
  commercial_registration_number: 'رقم السجل التجاري',
  cr_name_en: 'إسم المنشأة (إنجليزي)',
  cr_name_ar: 'إسم المنشأة (عربي)',
  calculation_the_accommodation: 'هل ترغب بحساب عدد أيام الإقامة',
  carry_forward_recalculate_description:
    'هل ترغب في إعادة حساب الرصيد المُرحل من السنة السابقة، وسوف تؤثر على حسابات أرصدة الإجازات للسنة الحالية؟',
  carry_forward_recalculate_invalid_description:
    'تغير إعدادات ترحيل الإجازات سوف يؤثر علي الطلبات التالية',
  content: 'المحتوى',
  complete_mudad_registration: 'إكمال تفعيل مدد',
  consumer_key: 'Consumer ID',
  token_secret: 'Token Secret',
  consumer_secret: 'Consumer Secret',
  confirm_and_log_users_out: 'تأكيد وتسجيل خروج المستخدمين',
  confirm_and_keep_users_logged: 'تأكيد وإبقاء المستخدمين مسجلين',
  combos: 'المجموعات',
  create_new_shift: 'إنشاء دوام جديد',
  clock_out_not_required: 'تسجيل الانصراف غير مطلوب',
  clockout_not_required: 'تسجيل الانصراف غير مطلوب',
  create_custom_shift: 'إنشاء تحويل مخصص',
  create_custom_template: 'إنشاء فترة مخصصة',
  create_new_template: 'إنشاء قالب جديد',
  create_template: 'إنشاء القالب',
  create_shift_presets: 'إنشاء فترة جديدة',
  create_combo: 'إنشاء المجموعة',
  create_destination: 'انشاء وجهة جديدة',
  created_by_automation: 'تم الإنشاء بسير العمل التلقائي',
  chip_allowed_delay: '<span>{{allowed_delay}}</span> التأخير المسموح به',
  chip_allowed_shortage: '<span>{{allowed_shortage}}</span> النقص المسموح به ',
  combo_name_in_arabic: 'اسم التحرير والسرد بالعربية (اختياري)',
  current_template_assigned: 'القالب الحالي المعين',
  change_take_effect_today: 'التغييرات سارية المفعول ابتداء من اليوم.',
  change_take_effect_tomorrow: 'ستدخل التغييرات حيز التنفيذ اعتبارًا من الغد.',
  copy_previous_week: 'نسخ الأسبوع السابق',
  copy_previous_day: 'نسخ اليوم السابق',
  copy_previous: 'نسخ السابق',
  clear_this_week: 'مسح هذا الأسبوع',
  clear_this_day: 'مسح هذا اليوم',
  changes_allowed_on_days:
    'التغييرات غير مسموح بها في الأيام التي تمت الموافقة عليها وتقديمها من مدير الحضور والإنصراف من قبل',
  clear_all_filters: 'مسح كل عوامل التصفية',
  custom_fields: 'الحقول المخصصة',
  clock_in_rules: 'آلية تسجيل الحضور',
  clock_out_rules: 'آلية تسجيل الانصراف',
  connection_information: 'معلومات الارتباط',
  clock_in_after_will_late_in:
    'تسجيل الحضور بعد الساعة <span>{{time}}</span> ستسجل <span>(تأخير)</span>',
  clock_out_after_will_late_in:
    'تسجيل الانصراف قبل الساعة <span>{{time}}</span> ستسجل <span>(خروج مبكر)</span>',
  clock_in_not_required: 'تسجيل الحضور غير مطلوب',
  connect_your_gosi_subscriptions: 'قم بربط إشتراك التأمينات المطلوب ({{ count }})',
  customizing_leaves_setting_description:
    'يمكنك إضافة إجازات خاصة بحسب احتياجات الشركة وطبيعة عملها، يمكنك تحديد نوع الإجازة، عدد أيامها، و يمكنك تحديد نوع الأجازة و عدد أيامها',
  confirm_changes: 'تأكيد التغييرات',
  correction_request_workflow: 'موافقات طلب التصحيح',
  correction_request: 'طلب التصحيح',
  count_employees: '{{count}} موظفين',
  create_fixed_hours: 'إنشاء دوام ثابت',
  connect_to_netsuite_account: 'ربط حساب (نت سويت)',
  connect_to_quickbooks_account: 'ربط حساب (كويك بوكس)',
  connect_to_microsoft_account: 'ربط حساب (مايكروسوفت)',
  connect_to_microsoft_finops_account: 'ربط حساب (MS Dynamics Finance & Operation)',
  connect_to_oracle_fusion_account: 'ربط حساب (Oracle Fusion)',
  connect_to_zoho_books_account: 'ربط حساب (زوهو بوكس)',
  connect_to_sap_account: 'ربط حساب (ساب)',
  connect_to_odoo_account: 'ربط حساب (اودو)',
  connect_to_xero_account: 'ربط حساب (زيرو)',
  clock_in_window_start_at: 'النافذة الزمنية لتسجيل الحضور تبدأ من: <span>{{time}}</span>',
  clock_in_window_end_at: 'النافذة الزمنية لتسجيل الحضور تنتهي: <span>{{time}}</span>',
  clock_out_window_start_at: 'النافذة الزمنية لتسجيل الانصراف تبدأ من: <span>{{time}}</span>',
  clock_out_window_end_at: 'النافذة الزمنية لتسجيل الانصراف تنتهي: <span>{{time}}</span>',
  clocking_after: 'تسجيل الحضور بعد <span>{{time}}</span> سيسجل كـــ <span>(حضور متأخر)</span>',
  clocking_before: 'تسجيل الانصراف قبل <span>{{time}}</span> سيسجل كـــ <span>(انصراف مبكر)</span>',
  custom_alt: 'مخصصة',
  company_name: 'اسم الشركة',
  company_file_expiry_date_optional: 'تاريخ انتهاء صلاحية ملف الشركة (اختياري)',
  commercial_registration_no: 'رقم السجل التجاري',
  commercial_registration: 'السجل التجاري',
  custom_leaves: 'الإجازات المخصصة',
  confirm_message: 'هل انت متأكد من حذف {{name}} ؟',
  changing_annual_leave_calculation_configuration:
    'تغيير سياسة احتساب الإجازة السنوية سوف يأثر على آلية احتساب الطلبات المفتوحة أو المستقبلية فقط، هل تريد الاستمرار؟',
  confirm_api_delete_text: 'هل تريد حذف  مفتاح API س"{{name}}"',
  create_open_api_key: 'مفتاح API جديد',
  copy: 'نسخ',
  copied: 'تم النسخ',
  confirm_enable_integration_text: 'هل أنت متأكد من رغبتك في تفعيل الربط مع {{name}} ؟',
  confirm_disable_integration_text: 'هل أنت متأكد من رغبتك في إيقاف تفعيل الربط مع {{name}} ؟',
  cancel_my_subscription: 'إلغاء الإشتراك',
  confirm_message_mudad:
    'هل متأكد من رغبتك في حذف السجل التجاري {{name}} ؟ في حال التأكيد سيتم حذف اشتراك مدد الخاص بهذا السجل',
  change_ats_message:
    'يبدو أن {{ current }} متكامل بالفعل. هل أنت متأكد من رغبتك في تغيير التكامل إلى {{ target }}؟ سيؤدي هذا إلى تعطيل {{ current }}.',
  cannot_edit_legal_structure_err_msg_title: 'لا يمكن تعديل هذا السجل ',
  cannot_edit_legal_structure_err_msg_description:
    'هذا السجل مرتبط بمنصة مدد، ولا يمكن تعديله. يمكنك تسجيل منشأة جديدة وأرشفة هذا السجل.',
  cannot_change_org_type_err_msg_title: 'لايمكنك تعديل نوع المنشأة',
  cannot_change_org_type_err_msg_description:
    'لا يمكنك تحويل المنشأة الرئيسية الى فرع نظرا لوجود سجلات فرعية مرتبطة بها',
  cannot_change_gosi_number_err_msg_title: 'لا يمكنك تعديل معلومات التأمينات الاجتماعية',
  cannot_change_gosi_number_err_msg_description:
    'هذا السجل مرتبط بمنصة أعمالي، ولا يمكن تعديله إلا بإلغاء ربط منصة أعمالي.',
  cannot_delete_cr_tooltip_title: 'لا يمكن إزاله هذا السجل لوجود طريقة دفع مفعله',
  cannot_delete_cr_linked_with_gosi: 'لا يمكن حذف هذه المنشآه لان لديها اشتراك مفعل  بالتامينات',
  cannot_archive_cr_linked_with_gosi: 'لا يمكن أرشفة هذه المنشآه لان لديها اشتراك مفعل  بالتامينات',
  cr_name_example: 'مثال: شركة {{app_name}} لتكنولوجيا المعلومات',
  cr_number_example: 'مثال xxxxxxx445',
  create_new_registration: 'انشاء منشأة جديدة',
  close: 'إغلاق',
  clocking_rules: 'آلية تسجيل الحضور',
  confirm_cancellation: 'تأكيد الالغاء',
  connect_with_agent: 'تواصل مع فريق {{app_name}}',
  create_and_manage_your_company_structure: 'قم بإنشاء وإدارة الهيكل التنظيمي لشركتك.',

  // D
  due_to_late_payments: 'بسبب التأخر في السداد',
  deduct_half_day: 'سيتم خصم إجازات نصف اليوم من رصيد الإجازات السنوي',
  delete_annual_leave_are_sure_to_continue: 'حذف اجازة سنوية ، هل تريد الاستمرار ؟',
  delete_outsourcing_providers: 'حذف شركة تأجير عمالة؟',
  delete_department: 'حدف القطاع',
  delete_sub_department: 'حدف القسم',
  delete_country: 'حدف الدولة',
  delete_area: 'حدف المنطقة',
  delete_location: 'حدف الموقع',
  delete_role: 'حذف الدور',
  department_policies: 'اقسام',
  delete_role_name: 'حذف الدور: {{role}}',
  delete: 'حذف',
  death_of_relatives_description:
    'تشمل: حالات الوفاة وهي إجازة 5 أيام بأجر لوفاة زوجة العامل أو أحد الأصول أو الفروع (الأصول: الوالدين وإن علا - الفروع: الأبناء وإن دنى',
  deduction_types: 'انواع الخصوم',
  delay: 'تأخير',
  date_due: 'تاريخ الاستحقاق',
  data_type: 'نوع الحقل',
  date_time: 'تاريخ',
  delay_time: 'وقت التأخير',
  default_account_for_paying_the_salary_stream: 'الحساب البنكي الافتراضي المستخدم للدفع',
  do_you_want_to_authorize: 'هل تريد تفويض موقعين اخرين ؟',
  do_you_want_to_verify: 'هل تريد إضافة موقّعين آخرين؟',
  day: 'يوم',
  disconnect_integrate: 'إلغاء الربط',
  devices_configurations: 'إعدادات الأجهزة',
  devices_admin: 'مدير الجهاز',
  delay_allowed_time: 'وقت التأخير المسموح',
  delete_custom_field: 'هل تريد حذف الحقل المخصص؟',
  delete_custom_field_warning:
    'جميع البيانات المتعلقة بحقل {{name}} ستحذف من ملف الموظف.       لا يمكنك استرجاع البيانات بعد الحذف',
  days_per_year: 'أيام في السنة',
  department_name: 'اسم القسم',
  due_amount: 'المبلغ المستحق',
  department_name_ar: 'اسم القسم بالعربية',
  departments: 'الأقسام',
  department_id: 'الأقسام',
  departments_plural: 'الأقسام',
  disconnect: 'غير متصل',
  days_counting_description:
    'تعريف طريقة احتساب الإجازة السنوية كاستحقاق وخصم رصيد, إما أيام عمل أو أيام تقويمية',
  daily: 'يومي',
  is_delay_deduction_applicable: 'تطبيق خصم التأخير في مسير الرواتب؟',
  deductions_types: 'أنواع الخصومات',
  do_you_want_to_confirm: 'هل تريد التأكيد ؟',
  default_bank_tooltip_note: 'انت تستخدم {{account}} لصرف الرواتب عن طريق {{method}}',
  diff_payment_method: 'بإمكانك تغيير البنك من صفحة طريقة الدفع',
  device_records: 'ملف رفع تفاصيل الحضور والإنصراف من ZKtecho',
  device_record_upload: 'سجلات الحضور والانصراف',
  device_location: 'مكان الجهاز',
  devices: 'الأجهزة',
  duplicate_entries_warning: 'الإدخالات المكررة غير مسموح بها',
  deduct_from_salary: 'خصم من الراتب',
  deduct_from_annual_leave_balance: 'خصم من رصيد الإجازة السنوية',
  double_overtime: '(2x) مضاعف',
  double_overtime_description:
    'طريقة إحتساب الأوفر تايم المضاعف: الساعة الأولى تُدفع من كامل الراتب, والساعة الأخرى تُدفع من الراتب الأساسي',
  document_number: 'رقم المستند',
  debits: 'الخصوم',
  day_days: 'يوم/ايام',
  do_not_deduct: 'عدم تفعيل الخصم',
  deduct: 'تفعيل الخصم',
  date_of_sharing_payslip_with_employees: 'ارسال كشف الرواتب للموظفين بعد تأكيد المسير بـ (ايام)',
  decimal_points_included: 'إظهار الفواصل العشرية؟',
  deduction_calculation_configuration: 'طريقة إحتساب الخصم على الحضور والإنصراف',
  deductions_overtime: 'الخصميات والعمل الإضافي',
  deductions_additions: 'الخصومات والإضافات',
  department_name_in_english: 'اسم القسم بالإنجليزية',
  department_name_in_arabic: 'اسم القسم بالعربية',
  do_you_want_use_offline_process_as_your_payment_method:
    'هل تريد استخدام طريقة الدفع عن طريق ملف البنك ؟',
  do_you_want_use_Mudad_as_your_payment_method: 'هل تريد استخدام طريقة الدفع عن طريق مدد ؟',
  default: 'افتراضية',
  default_case_1: 'افتراضي',
  default_rate: 'افتراضية',
  drop_file_here: 'اسحب وأفلت الملف هنا',
  download_report_file: 'تحميل  ملف الاخطاء',
  day_template: 'قالب دوام يوم',
  date: 'التاريخ',
  delete_shift: 'حذف الدوام',
  do_not_allow_reusing_last: 'لا تسمح بإعادة استخدام آخر',
  discard_changes: 'تجاهل التغييرات',
  dear_client_kindly_update_the_billing_description:
    'عزيزي العميل. يرجى تحديث تفاصيل الفواتير لتجنب أي مشاكل في معلومات الفاتورة.',
  duration_can_come_late: 'المده التي يمكن للموظف تعويضها بالبقاء متأخراً',
  disconnected: 'غير متصل',
  disclaimer: 'تنويه',
  deleting_this_role: 'حذف الدور سيزيل جميع الصلاحيات الممنوحة فيه للمستخدمين المسندين',
  delete_letter: 'حذف نموذج الخطاب',
  delete_letter_desc: 'ستفقد كل محتوى القالب. لن يتمكن أي موظف من طلب هذا النوع بعد حذفه  ',
  days_until_expiry: 'المدة المحددة للتجديد الدوري (بالأيام)',
  destinations: 'وجهة',
  destination_name: 'أسم وجهة',
  different_locations: 'إلى مواقع مختلفة',
  different_locations_desc: 'حدد موقع مختلف لكل موظف',
  different_department: 'قسم مختلف',
  different_department_desc: 'يمكنك اختيار قسم مختلف لكل موظف',
  delete_annual_leave: 'حدف الإجازة السنوية',
  delete_job_title: 'حدف المسمى الوظيفي',
  delete_employment_type: 'حدف نوع التوظيف',
  delete_document_type: 'حدف المستند',
  delete_allowances: 'حدف البدل',
  delete_loan_type: 'حدف نوع السلفة',
  delete_cost_type: 'حدف نوع التكلفة',
  delete_workflow: 'حدف السلسة',
  delete_holiday: 'حذف إجازة عيد',
  delete_subdepartments_order_department: 'قم بمسح الاقسام الفرعيه لتتمكن من حذف هذا القسم',
  deleting_asset_type_warning_message:
    'عند حذف {{assetTypeName}}، لن تكون قادرًا على إستخدام هذا النوع',
  delete_api: 'حذف API',
  disabled: 'غير مفعل',
  disabled_by: 'Disabled by {{name}} :AR',
  do_you_want_to_renew_mudad_for_this_cr: 'هل ترغب بتجديد إشتراك مدد للسجل التجاري رقم',
  deactivate_confirm:
    ' إذا قمت بتأكيد كشوف المرتبات الخاصة بك هذا الشهر، فستحدث التغييرات في كشوف المرتبات التالية. لا يزال بإمكانك إعادة تنشيط مدد مرة أخرى خلال فترة اشتراكك.',
  deactivate: 'الغاء تفعيل',
  disable_app: 'تعطيل {{name}}',
  deleted_employee: 'Deleted Employee: AR',
  duplicate: 'Duplicate: AR',

  describe_for_rewards: 'تقدم لك {{app_name}} هدية خاصة كعميل مهم 🎉 ',
  did_you_miss_us_reactivate_your_subscription: 'هل افتقدتنا؟ أعد تفعيل اشتراكك',
  // E
  enter_description: 'التفاصيل...',
  example_total_cost_per_hour:
    'بأفتراض ان قيمة ساعة ({{reflectionType}})  = <b>{{total}} {{currency}}</b> والأساسي = <b>{{basic}} {{currency}}</b> والقيمة المخصصة <b>{{multiple}}</b> سيكون حساب ساعة العمل الأضافي <b>{{result}} {{currency}}</b>',
  example_total_cost_per_hour_basic:
    'بأفتراض ان قيمة ساعة ({{reflectionType}})  = <b>{{basic}} {{currency}}</b> والقيمة المخصصة <b>{{multiple}}</b> سيكون حساب ساعة العمل الأضافي <b>{{result}} {{currency}}</b>',
  edit_workflow: 'Edit Workflow :AR',
  enable_half_day_leave: 'أتح إجازات بنصف يوم',
  exit_re_entry_issuing_task: 'مهمة اصدار خروج وعودة',
  edit_excuse_policy: 'تعديل سياسة الاستاذان',
  edit_overtime_policy: 'تعديل سياسة العمل الإضافي',
  early_departure_deduct: 'خصم التقصير (الانصراف المبكر)',
  employees_selected: '{{number}} موظف محدد',
  emergency_description: 'اجازة طارئه',
  enter_owner_authorizer_details: 'أدخل معلومات المفوض/المالك',
  employee_form_number: 'employee_form_number',
  employees_in_file_not_in_jisr: 'موظفين في الملف غير موجودين في {{app_name}}',
  employees_in_jisr_not_in_file: 'موظفين في {{app_name}} غير موجودين في الملف',
  gosi_salary_differences: 'اختلافات التامينات الاجتماعية',
  geofencing_technology: 'تقنية السياج الجغرافي',
  gosi_integration_card_note:
    'ربط حساب التأمينات الإجتماعية يتيح لك  تحديث معلومات وأجور الموظفين في التأمينات الاجتماعية من خلال {{app_name}} مباشرة',
  gosi_platform: 'منصة أعمالي- التأمينات الإجتماعية',
  jisr_gosi_salary: 'التامينات الاجتماعية في الملف',
  file_gosi_salary: 'التامينات الاجتماعية في {{app_name}}',
  employees_without_gosi_amount: 'موظفين بدون تامينات اجتماعية',
  employees_without_email_note: 'البريد الالكتروني لـ {{count}} موظف/موظفين غير معرف.',
  employee_profile: 'ملف الموظف',
  employee_document_types: 'أنواع ملفات الموظفين',
  expense_types: 'أنواع المصاريف',
  extra_working_time: 'ساعات العمل الإضافية',
  employee_info_update_request: 'طلب تحديث بيانات الموظف',
  expense_claim_request: 'طلب مصاريف مالية',
  excuse_request: 'طلب إستئذان',
  exit_time: 'وقت الخروج',
  update_cost_type: 'تعديل نوع التكليف',
  employee: 'الموظف',
  employees: 'الموظفين',
  edit_asset_type: 'تعديل نوع العهدة',
  establishment_id: 'رقم السجل التجاري',
  edit_department: 'تعديل القسم',
  edit_location: 'تعديل الموقع',
  edit_deduction: 'تعديل الخصم ',
  employee_info_update_request_workflow: 'سلسلة الموافقة لطلب تحديث بيانات الموظف',
  eligible_people: 'الموظفين المخولين',
  employment_document_types: 'أنواع مستندات الموظف',
  employment_types: 'أنواع عقود التوظيف',
  employment_type_id: 'أنواع عقود التوظيف',
  edit_custom_field: 'تعديل اسم الحقل',
  edit_employment_type: 'تعديل نوعية التوظيف',
  edit_document_type: 'تعديل النوع',
  edit_job_title: 'تعديل المسمى الوظيفي',
  edit_offline_processing: 'تعديل طريقة الدفع (ملف البنك)',
  edit_mudad: 'تعديل طريقة الدفع (مـدد)',
  eos_calc_not:
    'يمكنك اختيار طريقة احتساب مكافأة الخدمة إما على أساس الراتب الأساسي, أو على أساس مجموع الراتب, أو على أساس الراتب الأساسي ومجموعة من البدلات التي يمكن تحديدها',
  employees_verification_summary: 'قائمة الموقّعين',
  employees_authorizationa_summary: 'قائمة المفوضين',
  edit: 'تعديل',
  edit_area: 'تعديل المنطقة',
  device_brand: 'نوع الجهاز',
  expense_claim: 'مصاريف مالية',
  expense_claim_desc: 'الوصف',
  ess_policy: 'سياسة الخدمة الذاتية للموظفين',
  export_government_files: 'قسم رفع الملفات الحكومية',
  export_employees: 'ملف رفع بيانات الموظفين',
  export_compensations: 'ملف رفع الرواتب',
  export_leave_balance: 'ملف رفع رصيد الإجازات السنوية',
  export_leave_balance_employees: 'ملف رفع رصيد الإجازات السنوية',
  export_leave_requests: 'ملف رفع الإجازات',
  export_family_details: 'ملف رفع بيانات العائلة للموظفين',
  export_loans: 'ملف رفع السلف للموظفين',
  export_assets: 'ملف  رفع العهد للموظفين',
  exemption_policy: 'الإعفاء من الخصميات',
  employee_update_script_number: 'Employee update script number',
  employees_script_number: 'Employees script number',
  employee_read_script_number: 'Employee read script number',
  export_bupa_insurance: 'ملف رفع التأمين الصحي من شركة بوبا',
  export_device_records: 'ملف رفع بيانات الحضور والانصراف ZKtecho',
  exit_reentry_payment_task: 'مهمة دفع رسوم خروج وعودة',
  exit_reentry: 'خروج وعودة',
  expense_claim_payment_task: 'مهمة دفع صرف مالي',
  easy_clocking: 'إيزي كلوكينق',
  edit_parent_department: 'تعديل التفاصيل',
  export_style: 'نمط التصدير',
  export_journal_to_excel_sheet: 'تصدير القيود لملف اكسل',
  export_shifts: 'تصدير الدوامات',
  employee_role: 'صلاحية موظف',
  excuse_and_overtime: 'العمل الإضافي والاستئذان',
  excuse_policy: 'سياسة الإستئذان',
  enter_name: 'ادخل الاسم',
  excuse_polices: 'سياسة الإستئذان',
  employee_loans_policy: 'سياسة السلف للموظف',
  edit_custom_leave: 'تعديل الاجازه المخصصة',
  enter_day_number: 'ادخل عدد الايام',
  enter_days_number: 'عدد الايام',
  edit_addition: 'تعديل الاضافه',
  update_allowance: 'تعديل البدل',
  edit_grade: 'تعديل المستوى الوظيفي',
  expense_claim_workflow: 'طلب مصاريف مالية',
  exit_reentery_workflow: 'طلب خروج وعوده',
  excuse_workflow: 'طلب إستئذان',
  employees_not_assigned_to_leave: '{{count}} موظف لايمتلكون سلسلة موافقات إجازة {{type}}',
  employees_do_not_have_workflow: '{{count}} موظف لايمتلكون سلسلة موافقات',
  enablement_hub: 'قناة التمكين الداخلية',
  edit_profile_workflow: 'طلب تغيير معلومات الموظف',
  edit_role: 'تعديل صلاحية',
  edit_hourly_rate: 'تعديل سعر ساعة',
  Employees: 'موظفين',
  employees_weekly_emails: 'بريد الموظفين',
  edit_outsourcing_company: 'تعديل شركة تأجير العمالة',
  edit_holiday: 'تعديل عيد',
  edit_geolocation: 'تعديل موقع جغرافي',
  easy_clocking_integration: 'الربط مع إيزي كلوكينق',
  enter_otp: 'أدخل رمز التحقق',
  enabled_attendance_approval:
    'تأكيد سجل الحضور والإنصراف إذا تم تمكينه ، يجب أن يوافق مدير الحضور على سجل الحضور والانصراف ، من أجل معالجة مسير الرواتب ، وتسوية الإجازة ، والتسوية النهائية ',
  eight_characters_as_minimum_characters: '٨ خانات كحد أدنى من الأحرف',
  enable_calculating_the_whole:
    'تفعيل احتساب كامل فترة التاخير في حال تجاوز الموظف سياسة التاخير المسموح',
  enable_to_set_selected:
    'قم بتمكين هذا إذا كنت تريد تعيين اليوم / التاريخ المحدد على أنه يوم عطلة',
  employees_should_clock_out: 'يجب على الموظفين ألا يزيد عدد ساعات عملهم عن <span>{{time}}</ span>',
  enable_shift_to_repeat: 'قم بتمكين هذا إذا كنت تريد أن يتكرر هذا الدوام لفترة زمنية محددة',
  employees_assigned_template: 'الموظفين المعينين لهذا القالب',
  employee_list: 'قائمة الموظفين',
  effective_immediately: 'ساري المفعول فورا',
  effective_tomorrow: 'ساري المفعول غدا',
  effective_in_past: 'فعال في الماضي',
  edit_confirmation: 'تحرير التأكيد',
  edit_shift: 'تحرير الدوام',
  edit_shift_combo: 'تحرير مجموعة الدوام',
  edit_fixed_hours_template: 'تحرير نموذج ساعات العمل الثابتة',
  extend_for_probation: 'تمديد فترة التجربة للموظفين',
  export_csv: 'تصدير (CSV)',
  earliest_start_time: 'أبكر بداية دوام',
  exceeds_shift_duration: 'تعدى فترة الدوام',
  excuse_polices_configurations: 'اعدادات سياسات الإستئذان',
  edit_general_information: 'تعديل معلومات عامة',
  edit_contact_information: 'تعديل معلومات التواصل',
  edit_destination: 'تعديل الوجهة',
  edit_policy: 'تعديل سياسة',
  edit_document: 'تعديل المستند',
  exemption_policy_description:
    ' عند إضافة الموظفين إلى سياسة الإعفاء من الخصميات  ، فسيتم إستثنائهم من جميع خصومات الحضور والانصراف ( الغياب ، التأخير ، الخروج المبكر ، السجل الغير مكتمل ) ضمن مسير الرواتب',
  extend_for_probation_period_employees_tip:
    'هذا الخيار سيمدد فترة التجربة للموظفين الذين هم تحت فترة التجربة لكامل فترة الاعيادة المعرفة . ملاحظة: هذا الخيار سيؤثر على الفترة القادمة ولا يوجد أي تأثير على الفترة السابقة.',
  edition: 'الأصدار',
  edit_open_api_key: 'تعديل مفتاح API',
  enabled: 'مفعل',
  edit_group: 'تعديل المجموعة',
  edit_fixed_working_hours_group: 'تعديل الدوام الثابت',
  enable_app: 'تفعيل {{name}}',
  edit_registration: 'تعديل السجل',
  employees_on_this_shift: 'الموظفون بهذا الدوام',
  employees_will_be_able_to_clock_in_anytime:
    'الموظفون سيستطيعون تسجيل الدخول في اي وقت من الساعة <span>{{startTime}}</span> إلي الساعة <span>{{endTime}}</span> ',
  employees_will_need_to_use_jisr_app:
    'على الموظفين استخدام تطبيق الهاتف الخاص ب{{app_name}} لتجربه افضل لبيانات الحضور والانصراف',
  employees_will_be_able_to_clock_in_any_time_of_calendar:
    'يستطيع الموظفون في هذا الدوام تسجيل الدخول او الخروج في اي وقت خلال اليوم',
  employees_will_be_able_to_clock_in_any_time_during_specific_hours:
    'يعمل الموظفون في هذا الدوام خلال ساعات محددة خلال اليوم',
  ends_in_next_day: 'ينتهي في اليوم القادم',
  expired_not_active_description:
    'عذرًا! يبدو أن الرابط الذي استخدمته لإعادة تعيين كلمة المرور قد انتهت صلاحيته',
  expired_contact_your_administrator_description:
    'عذرًا! يبدو أن الرابط الذي استخدمته لإعادة تعيين كلمة المرور قد انتهت صلاحيته.<br /> الرجاء التواصل مع الأدمين.',
  excuses: 'Excuses:ar',
  enter_multible: 'أدخل المضاعف',
  employees_can_submit_overtime_request_only_if: 'تطبيق شروط خاصة قبل طلب عمل أضافي',
  employees_this_policy: 'موظفين في هذه السياسة',
  // F
  for_alinma_bank: 'لعملاء بنك الإنما، الرجاء اتباع الدليل المرفق',
  few_things_to_keep_in_mind: 'معلومات تهمك',
  foodics: 'فودكس',
  faq: 'الأسئلة الشائعة',
  food_per_diem: 'مبلغ الطعام <br/><p>لكل يوم</p>',
  folder: 'مجلد',
  fax_number: 'رقم الفاكس',
  file: 'ملف',
  file_selected: 'الملف المحدد',
  file_number: 'رقم الملف',
  finish_wizard: 'تم',
  family_details: 'بيانات العائلة للموظفين',
  final_settlement_payment_task: 'مهمة دفع المخالصة النهائية',
  final_settlement_payment_task_message: 'مهمة دفع المخالصة النهائية <span>({{message}})</span>',
  final_settlement_processing_task: 'مهمة إحتساب المخالصة النهائية',
  final_settlement_payment_processing: 'تأكيد سجل الحضور والإنصراف مطلوب للمخالصة النهائية',
  fingerprint_device_admin: 'ادمن',
  fingerprint_device_manager: 'مدير',
  full_pacakage: 'كل الراتب',
  family_included: 'إضافة افراد العائلة',
  fund_date: 'تاريخ صرف الرواتب',
  fund_date_form_note:
    'سيتم تحويل رواتب الموظفين في تاريخ {{date}}. إذا أردت بإمكانك تغيير التاريخ.',
  footer: 'تذييل الصفحه',
  fixed_working_hours: 'الدوامات الثابتة',
  flexibility: 'المرونة',
  for: 'إلى',
  flexbility_duration: '<div> <b> {{flexible_duration}} </ b> مرونة </ div>',
  fixed_template_name: 'اسم القالب الثابت',
  fixed_hours_employee: 'موظف دوام ثابت',
  field_name_ar: 'الاسم بالعربي',
  field_name_en: 'الاسم بالإنجليزي',
  from_here: 'من هنا',
  foodics_cashier_users: 'قائمة مستخدمي فودكس كاشير',
  foodics_is_a_restaurant_management_system_description:
    'فودكس هو نظام إدارة مطعم يدير المبيعات والمعاملات والمخزون وجداول الموظفين وعلاقات العملاء. تفعيل الربط سوف يمكنك من الحصول على تحليلات قيمة حول موظفيك',
  flight_tickets: 'تذاكر السفر',
  final_settlement_payment: 'المخالصة النهائية',
  flexible_anytime_of_the_day: 'مرن - اي وقت في اليوم',
  first_punch_in_and_last_punch_out_will_be_recorded_as:
    'يتم احتساب وقت العمل بناءًا على اول بصمة واخر بصمة',
  flexible_open_shift: '<span>{{openSiftType}}</span> دوام مفتوح',
  follow_up_on_your_team_requests_and_approve_them_on_spot:
    'حدد او أطلع على سياسات العمل الأضافي والاستئذان الموجودة بالمنشأة',
  flight_tickets_workflow: 'تذكرة طيران',
  for_examble: 'على سبيل المثال 2.5',
  // G
  gosi_linked: 'معلومات التأمينات الإجتماعية',
  mol_registrations: 'إشتراكات التأمينات',
  grades: 'المستوى الوظيفي',
  gosi_info: 'معلومات التأمينات الإجتماعية',
  government_files: 'الملفات الحكومية',
  group_by: 'مجمع على أساس',
  gosi_subscription: 'اشتراك التأمينات الإجتماعية',
  gosi_subscription_no: 'رقم الإشتراك في التأمينات الإجتماعية - {{ num }}',
  gosi_subscription_number: 'رقم الإشتراك في التأمينات الإجتماعية',
  generating_the_advanced: 'إنشاء المخالصة قبل الإجازة السنوية بـ',
  google_map_search: 'ابحث عن مدينة، مكان',
  gosi_number: 'رقم الإشتراك في التأمينات الإجتماعية',
  gosi_number_LS: 'رقم الإشتراك في التأمينات الإجتماعية',
  gosi_name_ar: 'إسم المنشأة في التأمينات (عربي)',
  gosi_name_en: 'إسم المنشأة في التأمينات (إنجليزي)',
  green: 'أخضر',
  general_info: 'معلومات الحساب',
  general_organization_for_social_insurance: 'المؤسسة العامة للتأمينات الاجتماعية',
  general_organization_for_social_insurance_no: 'رقم الإشتراك في التأمينات الإجتماعية',
  gosi_tasks: 'التأمينات الاجتماعية',
  geo_location: 'الموقع الجغرافي',
  gosi_number_LS_example: 'مثال: 1234554645',
  greater_than_or_equal: 'أكبر من أو يساوي',
  // H
  how_to_pay: 'كيف تدفع؟',
  holiday_name_in_ar: 'اسم إجازة عيد بالعربي',
  holiday_name_in_en: 'اسم إجازة عيد بالانجليزي',
  holidays: 'الأعياد',
  hours_per_month: '{{numberOfHours}} ساعات بالشهر',
  hajj_description:
    'إجازة أداء فريضة الحج: ومدتها من 10 إلى 15 يوم بما فيها إجازة عيد الأضحى لمن أمضوا سنتين على الأقل في الخدمة ويحق للمنشأة تحديد نسبة العاملين المستحقين لهذه الإجازة سنويًا بحيث لا يؤثر على سير العمل.',
  holiday_name: 'اسم الأعياد',
  hire_carry_forward: 'هل يتم ترحيل كامل الرصيد للموظفين الجدد؟',
  hiring_request: 'طلب توظيف',
  hiring: 'توظيف',
  how_to_connect_netsuite_to_jisr: 'شاهد طريقة ربط نت سويت مع {{app_name}}',
  how_to_connect_quickbooks_to: 'شاهد طريقة ربط كويك بوكس مع {{app}} ',
  click_accounting: 'انقر على زر الاتصال',
  credentials_accounting_quickbooks: 'قم بتسجيل الدخول على كويك بوكس',
  credentials_accounting_xero: 'قم بتسجيل الدخول على زيرو',
  credentials_accounting_qoyod: 'قم بتسجيل الدخول على قيود',
  start_mapping_cost_center_accounting: 'قم بتحديد مراكز التكلفة وربط الموظفين',
  start_mapping_cost_center_accounting_for_qoyod: 'قم بتحديد مراكز التكلفة',
  configure_chart_of_accounts: 'قم بربط شجرة الحسابات',
  ensure_cost_centers_step_accounting: 'تأكد من توزيع الموظفين على مراكز التكلفة',
  done_step_accounting: 'تم الربط !',
  how_to_connect_xero_to: 'شاهد طريقة ربط زيرو مع {{app}} ',
  how_to_connect_qoyod_to: 'شاهد طريقة ربط قيود مع {{app}} ',
  how_to_connect_microsoft_finops_to: 'شاهد طريقة ربط MS Dynamics Finance & Operation مع {{app}} ',
  how_to_connect_oracle_fusion_to: 'شاهد طريقة ربط Oracle Fusion مع {{app}} ',
  how_to_connect_zoho_books_to: 'شاهد طريقة ربط زوهو بوكس مع {{app}} ',
  how_to_connect_microsoft_to: 'شاهد طريقة ربط مايكروسوفت مع {{app}} ',
  how_to_connect_odoo_to: 'شاهد طريقة ربط اودو مع {{app}} ',
  hiring_requests: 'طلبات التوظيف',
  how_it_works: 'كيف يعمل ؟',
  hiring_workflow: 'طلب توظيف',
  have_you_changed_your_bank_account: 'هل تريد تغيير بنك صرف الرواتب ؟',
  header: 'رأس الصفحه',
  holiday_description: 'الأعياد الرسمية تعد أيام إجازة لجميع الموظفين في تواريخ محددة.',
  hourly_rate_polices: 'سياسات سعر الساعة ',
  hourly_rate_policy: 'سياسة سعر الساعة',
  hourly_rate_policy_note:
    'لقد قمت بجعل هذه السياسة كسياسة إفتراضية, سيتم نقل كافة الموظفين الموجودين في السياسة الإفتراضية الحالية إلى السياسة الإفتراضية الجديدة<br />  إيضا عند إضافة موظف جديد سيتم إضافته إلى السياسة الإفتراضية بشكل تلقائي .',
  hourly_rate_policy_description:
    'سياسة سعر الساعة تساعدك على تحديد كيف يجب على النظام إحتساب أجر ساعات العمل الإضافي و خصميات التأخير والخروج المبكر.مثال: راتب الموظف 30،000 ريال ، ساعات عمل الموظف 10 ساعات. فإن سعر الساعة سيكون: 100 ريال سعودي. (الراتب / 30 / ساعة عمل). تحتاج فقط إلى تحديد ساعات العمل و إسناد الموظفين للسياسة',
  hourly_rate_description:
    'سياسة سعر الساعة تساعدك على تحديد كيف يجب على النظام إحتساب أجر ساعات العمل الإضافي و خصميات التأخير والخروج المبكر.<br /><br /> مثال: راتب الموظف 30،000 ريال ، ساعات عمل الموظف 10 ساعات. فإن سعر الساعة سيكون: 100 ريال سعودي. (الراتب / 30 / ساعة عمل). تحتاج فقط إلى تحديد ساعات العمل و إسناد الموظفين للسياسة',
  hour_rate: 'معدل الساعات',
  hours_per_day_open_shift: '{{time}}/لليوم',
  hours_per_day: 'ساعات/خلال اليوم',
  register_to_mudad: 'تفعيل مدد',
  manual_payment: 'الدفع عن طريق ملف البنك',
  change_to_manual_payment: 'تغيير طريقة الدفع عن طريق ملف البنك',
  delete_establishment: 'حذف المؤسسة',
  change_to_mudad: 'تغيير طريقة الدفع للدفع بمدد',
  before_add_mudad: 'قبل اعداد مدد',
  save_close: 'حفظ & خروج',
  have_an_issues_contact_support: 'لديك مشاكل ، اتصل بالدعم',
  review_org_structure: 'مراجعة مستندات المنشأة',
  authorizer_verification: 'التحقق من المالك/المفوض',
  verify_payment_admin: 'تحديد مسؤولي عملية الدفع',
  authorize_payment_admins: 'تفويض مسؤولي عملية الدفع',
  setup_mudad: 'إعداد الربط مع مدد',
  register: 'تسجيل',
  edit_legal_structure: 'تعديل هيكل المنظمة',
  revoke_mudad: 'إلغاء مداد',
  you_are_about_to_delete_mudad_establishment: 'سوق يتم حذف اعدادات التسجيل الخاصه باشتراك مدد',
  authorize_other_employee: 'تفويض موظف أخر',
  an_otp_code_will_be_sent_note:
    'سيتم ارسال رمز OTP الى رقم جواله المسجل لدى بنك المنشأة ، للحساب البنكي المفوض عليه',
  make_sure_that_is_the_persons_are_authorized:
    'تأكد من أن هذا الموظف لديه تفويض ساري على حساب المنشأة البنكي ( قد يتطلب مراجعة البنك لتحديث التفويض )',
  commercial_registration_name: 'اسم المنشأة - السجل التجاري',
  commercial_registration_name_ar: 'اسم المنشأة بالعربي - السجل التجاري',
  commercial_registration_name_en: 'اسم المنشأة بالانجليزي - السجل التجاري',
  owner_national_id_iqama: 'رقم الهوية/الاقامة للشخص المالك/المفوض',
  learn_more: 'المزيد',
  select_the_employee_that_authorized_to_process_salary_payments:
    'إخترالموظف المصرح له ( من قائمة الموظفين الذين تمت إضافتهم في الخطوة السابقة ) لمعالجة مدفوعات الرواتب من خلال حساب المنشأة البنكي',
  authorize_with_the_responsible_of_the_payment_process: 'تفويض المسؤولين عن الدفع',
  we_will_send_otp_please_enter_here:
    'تم إرسال رمز OTP الي الرقم الذي يبنتهي ب {{ number }} ، يرجى إدخال رمز OTP هنا',
  please_enter_otp: 'يرجى إدخال رمز OTP هنا',
  owner_success_note: 'تم التحقق من المالك الذي يحمل رقم التعريف {{number}} بنجاح',
  make_sure_that_the_authorized_person_in_the_bank_has_jisr_account:
    'تأكد من أن الشخص الذي يتم إختياره في هذه الخطوة لديه حساب في أبشر وعلى نظام جسر وأن رقم هويته في ملفه الشخصي على جسر متطابق مع المعلومات في أبشر',
  please_wait: 'الرجاء الانتظار ...',
  setup_offline: 'اعداد الدفع عن طريق ملف البنك',
  bank_account_can_not_be_deleted: 'لا يمكن حذف الحساب المصرفي',
  authorized_employee: 'المفوض',
  request_to_authorize: 'تفويض',
  mudad_authorized_employee: 'تحديد الموظف المراد تفويضة',
  verify_another_employee: 'تحقق من موظف جديد',
  your_salaries_bank: 'الحساب البنكي لدفع الرواتب',
  select_the_employee_that_authorized:
    'حدد الموظف المصرح له بمعالجة مدفوعات الرواتب من خلال البنك الذي تتعامل معه',
  verification_with_the_responsible: 'التحقق مع المسؤول عن عملية الدفع عند البنك',
  we_will_send_otp_verify_owner_note:
    'سوف نرسل رمز OTP للتحقق من هوية مالك المنشأة أو الشخص المفوض ( مشرف المنشأة ) على حساب التأمينات الاجتماعية إلى رقم جواله المسجل في أبشر',
  make_sure_contact_ur_org_owner_note:
    'تأكد من التنسيق مع مفوض التأمينات الاجتماعية او مالك المنشأة عند القيام بهذه الخطوة ان لم تكن أنت المفوض',
  the_establishment_with_Mudad_has_been_registered_successfully:
    'تم تسجيل المنشأة في مدد بنجاح. الرجاء المتابعة',
  make_sure_that_is_the_information: 'تأكد من صحة معلومات الهيكلة القانونية لمنشئتك',
  make_sure_establishment_note:
    'تأكد من صحة سجلك التجاري ورقم المنشأة في وزارة العمل MOL. إذا كان هناك خطأ في المعلومات، فلن تتمكن من تفعيل مدد',
  Hiring_Date_Diffrences_description:
    'يؤثر توقيت انضمام الموظفين الجدد على احتساب أول راتب لهم في شهر الانضمام أو الشهر الذي يليه، ويكون التأثير بالزيادة إذا كان الانضمام بعد تاريخ الاستقطاع وبالنقص إذا كان قبله.',
  working_hour_rate: 'احتساب ساعة العمل',
  establishment_deleted_successfully: 'تم حذف المنشأة بنجاح',
  Hr: 'ساعة',
  hour: 'ساعة',
  hours_high_to_low: 'الساعات: من الأعلى إلى الأقل',
  hours_low_to_high: 'الساعات: من الأقل إلى الأعلى',
  hourly_rate: 'احتساب الساعة',
  here_will_be_the_permissions:
    'هنا ملخص بالصلاحيات الممنوحة للمستخدمين المضافين لهذا الدور, بالإضافة الى  <span>الصلاحيات الأساسية</span> الممنوحة لكل المستخدمين تلقائياً',
  working_hours_day: 'ساعات العمل / اليوم',
  how_to_get_this_info: 'كيف أحصل على هذه المعلومات؟',
  hourly_rate_mutiple: 'مضاعفة ساعة العمل الأضافي',

  // I
  invoice_status: 'حالة الفاتورة',
  included_in_shift_duration: 'من ساعات الدوام',
  imported_successfuly: 'اكتمل',
  import_failed: 'فشل',
  integratable_cost_centers: 'مراكز التكلفة القابلة للربط',
  integration: 'الربط',
  is_active: 'فعال/نشط',
  is_addition: 'إضافة',
  integratable_cost_center_mapping: 'ربط مراكز التكلفة',
  integratable_manual_addition_types: 'ربط أنواع الإضافات اليدوية',
  integratable_manual_deduction_types: 'ربط أنواع الخصومات اليدوية',
  integratable_manual_benefit_types: 'ربط أنواع البدلات',
  integratable_loan_types: 'ربط أنواع السلف',
  is_applicable: 'تطبيق الخصم في مسير الرواتب',
  invoice_no: 'رقم الفاتورة',
  issuing_ticket_task: 'مهمة استخراج تذكرة',
  issuing_exit_reentry_task: 'مهمة اصدار خروج وعودة',
  is_double_overtime_applicable: 'تطبيق الأوفر تايم المضاعف :',
  info_alert: 'تنبيه',
  it_works_with_all_banks: 'مدعوم مع كل البنوك',
  i_agree_to_authorize_mudad: 'أوافق على تفويض مدد للقيام بدفع الرواتب من حساب البنك المحدد',
  information: 'المعومات',
  is_automatic_resumption_applicable: 'تطبيق الاستئناف التلقائي للإجازة',
  includes_emp_name: 'على أساس اسم الموظف',
  includes_payment_type: 'على أساس طريقة الدفع',
  invalid_records: '<strong>{{count}}</strong> سجل غير صالح',
  import_bulk_shifts: 'استيراد الدوامات',
  is_resumption_applicable: 'تطبيق الاستئناف من الإجازة',
  is_the_requested_excuse: 'هل ساعات الإستئذان الشخصية عن طريق الطلب محدودة خلال الشهر ؟',
  is_the_requested_overtime: 'هل ساعات العمل الإضافية عن طريق الطلب محدوده خلال الشهر ؟',
  import_en: 'رفع EN',
  identification_number: 'Identification Number',
  import_ar: 'رفع AR',
  integration_with_gosi: 'الربط مع مؤسسة التأمينات الإجتماعية - منصة أعمالي',
  inma_bank_payroll_payment: 'تحويل الرواتب عبر بنك الإنماء',
  i_agree: 'أُوافق',
  in_payroll: 'داخل مسير الرواتب  ',
  in_payrun: 'داخل مسير الرواتب',
  invoice_details: 'تفاصيل الفاتورة',
  integration_select_employee: 'حدد موظف',
  integration_disconnect: 'فك الارتباط',
  integration_connect: 'ربط',
  include_expire_date: 'يجب أن يكون لهذه المستند تاريخ انتهاء الصلاحية',
  included: 'يشمل',
  in_which_tab_do_you_want_the_input_field_display: 'في أي علامة تبويب تريد عرض الحقل',
  icon: 'أيقونة',
  installed_by: 'تم التفعيل بواسطة',
  inactive_by_user: 'غير نشط من المستخدم',
  invitation_link_expired: ' رابط الدعوة منتهي',
  invitation_link_expired_description:
    'لقد انتهت صلاحية الرابط. من فضلك قم بطلب رابط جديد للمتابعة.',
  i_understand_that_by_proceeding_description:
    'أدرك أنه من خلال المتابعة، سأفقد الوصول إلى حسابي الشخصي وجميع محتوياته بما في ذلك وصول الموظفين وبياناتهم وتاريخ حسابي منذ الانضمام',

  // J
  jisr_systems_for_communicatons_and:
    'شركة {{app_name}} النظم للاتصالات وتقنية المعلومات شركه شخص واحد',
  jisr_systems_for_communicatons_and_reverse: '{{app_name}} SYSTEMS FOR COMMUNICATONS  AND',
  journal_form_number: 'journal form number',
  journal_create_script_number: 'Journal create script number',
  job_titles: 'المسميات الوظيفية',
  journal_structure: 'هيكل دفتر اليومية',
  jisr: 'جسر',
  // K
  key_name: 'عنوان المفتاح',
  key: 'المفتاح',
  keep_subscription: 'متابعة الإشتراك',
  // L
  labor_law: 'إجازات مكتب العمل',
  labor_law_desc:
    'Saudi labor law leaves are enabled By default to ensure your organization is complied with labor law.You can Also set and adjust leaves depending on your organization’s policy. :AR',
  labor_law_leave_validation: 'لايمن أن يكون أقل من الحد الأدنى لساعات العمل اليومية',
  last_connected: 'اخر اتصال',
  learn_more_overtime: 'مزيد من المعلومات',
  limit_in_numbers: 'عدد الساعات',
  location_policies: 'مواقع',
  limited_to: 'محدود لـ',
  last_disconnected: 'اخر عدم اتصال',
  last_import: 'أخر تحديث للبيانات',
  late_arrival_deduct: 'خصم التأخير',
  log_out: 'تسجيل خروج',
  leave_management: 'إدارة الإجازات',
  LEAVES_HOLIDAYS: 'الإجازات والعطلات',
  loan_types: 'السلف',
  loan_types_management: 'إدارة السلف',
  letters_management: 'إدارة الخطابات',
  letter_templates: 'صيغ وأنواع الخطابات',
  letter_template: 'صيغة الخطابات',
  loan_request: 'طلب سلفة',
  letter_request: 'طلب خطاب',
  leave_request: 'طلب إجازة',
  loan_policy: 'سياسة السلف',
  linked_bank_account: 'بنك دفع الرواتب',
  list_options: 'عناصر القائمة',
  location_details: 'تفاصيل الموقع',
  leave_type: 'نوع الإجازة',
  location_name: 'اسم الموقع',
  location_name_ar: 'اسم الموقع بالعربية',
  loan_type_title: 'ماهو أقصى مبلغ يمكن للموظف طلبه كسلفة',
  leave_balances: 'رصيد الإجازات السنوية',
  leave_cancellation: 'إلغاء إجازة سنوية',
  leave_requests: 'طلبات الإجازات',
  login_bar_text: 'لا داعي للاتصال. يرجى زيارة موقعنا',
  login_authentication: 'سياسة تسجيل الدخول',
  login_two_factor_auth: 'رمز التحقق لتسجيل الدخول',
  login_two_factor_auth_desc: 'تسجيل الدخول يتطلب إدخال رمز التحقق المرسل على البريد الإلكتروني.',
  leave_related_tasks: 'المهام المتعلقة بالإجازات',
  loan_payment_task: 'مهمة دفع السلفة',
  leave_resumption_description:
    'في حال تفعيل هذا الخيار, يتوجب على كل موظف التقديم على تأكيد مباشرة عمل لكل إجازة يتم التقديم عليها, ولتفاصيل أكثر يمكنك قراءة المقالة التالية',
  limited_carry_balance_description: 'السماح للموظف بترحيل رصيد الإجازات السنوية بوجود حد أقصى',
  leave_name: 'اسم الاجازه',
  leave_name_ar: 'اسم الاجازه بالعربي',
  leave_name_in_ar: 'اسم الاجازه بالعربي',
  leave_name_in_en: 'اسم الاجازه بالانجليزي',
  leave_workflow: 'طلب إجازة',
  loan_workflow: 'طلب سلفة',
  letter_workflow: 'الخطابات',
  letter_issuing_task: 'مهمة اصدار خطاب',
  legal_structure: 'الهيكل القانوني',
  late_more_min: 'الدخول بعد أكثر من  <span>{{allowed_delay}}</span> سيسجل ك <span>(تأخير)</span>',
  leaving_earlier_min:
    'سيتم تسجيل المغادرة قبل <b>{{allowed_shortage}}<b/> على أنها <b>خروج مبكر</b>',
  leaving_more_recorded:
    'سيتم تسجيل أكثر من <span>{{allowed_delay}} دقيقة</ span> على أنها <span> تأخير في </ span> ',
  leaving_earlier_recorded:
    'سيتم تسجيل المغادره قبل أقل من <span> {{allowed_shortage}} </span>على أنها <span>في وقت مبكر</span>',
  learn_more_about_multiple: 'تعرف على المزيد حول الدوامات المتعددة',
  log: 'السجل',
  login_failed_attempts_configuring: 'إعدادات محاولات تسجيل الدخول الفاشلة',
  login_failed_attempts_configuring_description:
    'سيقفل حساب المستخدم عند تكرار ٣ محاولات خاطئة خلال ٥ دقائق، ويمكن للمستخدم فتح الحساب بتغيير كلمة المرور.',
  login_attempt: 'الحد من محاولات التسجيل الخاطئة',
  login_attempt_note: 'هل أنت متأكد من تطبيق الحد من محاولات التسجيل الخاطئة في الخيارات المحددة؟',
  link: 'رابط',
  leave_balance_settlement_fs: 'مستحقات رصيد الإجازة (المخالصةالنهائية)',
  leave_balance_settlement_note:
    'يمكنك اختيار طريقة احتساب مستحقات رصيد الإجازة المتبقي عند إنهاء خدمات الموظف إما على أساس الراتب الأساسي, أو على أساس مجموع الراتب, أو على أساس الراتب الأساسي ومجموعة من البدلات التي يمكن تحديدها',
  link_your_apps_account_with_jisr_system:
    'ربط حسابك في {{apps}} مع نظام {{app_name}} سيخول {{app_name}} للقيام بالتالي',
  line_manager_alt: 'مدير مباشر',
  legal: 'الهيكل القانوني',
  login_settings: 'تسجيل الدخول وكلمات المرور',
  letters: 'الخطابات',
  language: 'اللغة',
  learn_how_to_pay: 'أعرف المزيد عن الية الدفع',
  locations_policy: 'مواقع',
  // M
  method_payment: 'نوع الدفع',
  make_sure_employee_have_app:
    'تأكد من ان الموظفين مفعلين السماح للتنبيهات من تطبيق {{app_name}} بالوصول لهواتفهم',
  more_about_overtime_calculation: 'المزيد عن حساب الأوفرتايم',
  more_on_your_employees_shift: 'المزيد عن دوام الموظفيين',
  mol_unified_no: 'الرقم الموحد للمنشأة في مكتب العمل',
  maximum_overtime: 'الحد الأقصى',
  moving_department: 'نقل قسم',
  maximum_password_age_policy_configuration_description:
    'يطلب تغيير كلمة المرور من المستخدمين بعد مدة محددة',
  minute_minutes: 'دقيقة/دقائق',
  missing_punch_request: 'طلب تصحيح بصمة',
  MissingPunchRequest: 'طلب تصحيح بصمة',
  missing_punch_deduct: 'خصم السجل غير المكتمل',
  max_hours_weekdays: 'أقصى عدد ساعات في الأسبوع',
  max_hours_non_weekdays: 'أقصى عدد ساعات في أيام نهاية الأسبوع',
  manager: 'المدير',
  manager_role: 'صلاحية مسؤول حساب',
  mol_id: 'محتوى رقم مكتب العمل غير صالح',
  missing_punch_configuration: 'تعريف /إعدادات السجل الغير مكتمل',
  manual_addition_types: 'أنواع الإضافات اليدوية',
  manual_deduction_types: 'أنواع الخصومات اليدوية',
  mol_info: 'ملف مكتب العمل',
  muqeem_info: 'ملف مقيم',
  my_data_only: 'معلوماتي فقط',
  my_notifications: 'My Notifications AR',
  my_subordinate: 'معلومات موظفي الفريق',
  my_subordinate_plus_my_data: 'معلوماتي + موظفي الفريق',
  minimum_days_warning: 'يجب ألا تقل أيام الإجازة السنوية عن  {{days}}',
  multiplies_absence_days: 'مضروباً في عدد أيام الغياب',
  is_missing_punch_deduction_applicable: 'تطبيق خصم السجل الغير مكتمل في مسير الرواتب؟',
  manual_account_name: 'إسم الحساب',
  manual_account_number: 'رقم الحساب',
  maximum_loan_amount: 'أقصى عدد من الرواتب التي يمكن طلبها في السلفة',
  maximum_loan_amount_from_basic__full:
    'أقصى مبلغ يمكن للموظف طلبه كسلفة من الراتب الأساسي/ كامل الراتب',
  maximum_excuse_hours_month: 'اعلى ساعات اعذار في الشهر',
  maximum_overtime_Hours: 'اعلى ساعات اوفرتايم في الشهر',
  maximum_password_age: 'الحد الأقصى لعمر كلمة المرور',
  maximum_password_age_policy: 'تجديد كلمة المرور',
  maximum_password_age_policy_desc:
    'يحدد إعداد سياسة الحد الأقصى لعمر كلمة المرور الفترة الزمنية (بالأيام) التي يمكن فيها استخدام كلمة المرور قبل أن يطلب النظام من المستخدم تغييرها.',
  maximum_password_age_save_note:
    'هل أنت متأكد من تطبيق الحد الأقصى لعمر كلمة المرور في الخيارات المحددة؟',
  maximum_password_age_reset_note:
    'لقد انتهت صلاحية كلمة مرورك السابقة ، يرجى إنشاء كلمة مرور جديدة لمواصلة استخدام {{app_name}}',
  marriage_description: 'إجازة الزواج: 5 أيام بأجر كامل',
  maternity_description:
    'إجازة الوضع (للمرأة العاملة): ومدتها عشر أسابيع بأجر فعلي كامل، توزعها المرآة العاملة كيفما تشاء بحد أقصى 4 أسابيع قبل تاريخ الولادة المتوقعة إضافية دون أجر.',
  monthly_limited_hours: 'الحد اﻷقصى شهرياً',
  minimum_days_to_apply: 'الحد اﻷدنى لتقديم إجازة سنوية براتب مقدم',
  minimum_password_length: 'كلمة مرور طويلة',
  minimum_password_length_desc: 'لا يسمح لكلمات المرور أن تكون أقل من ٨ أحرف.',
  missing_punch_workflow: 'طلب التصحيح',
  mins: 'دقائق',
  minimum_annual_leave_entitlement_value:
    'الحد الأدنى لقيمة الإجازة السنوية للموظف الذي أكمل 5 سنوات',
  minimum_annual_leave_entitlement_value_desc:
    'سيكون هذا هو الحد الأدنى لقيمة الاستحقاق للموظفين الذين أكملوا 5 سنوات.',
  minimum_annual_leave_entitlement_value_note:
    'ملاحظة: إذا كان هناك بعض الموظفين الذين تزيد استحقاقاتهم السنوية عن الحد الأدنى المحدد مسبقًا ، فلن يقوم النظام تلقائيًا بتحويل استحقاقهم السنوي.',
  mudad_verification: 'التحقق من مداد',
  mudad_origanization_not_enalbled_msg:
    'الربط مع مُدد غير متوفر لحسابكم حاليًا، برجاء التواصل مع مدير نجاح العملاء الخاص بكم او فريق الدعم الفني لتفعيل الربط وإكمال التسجيل, شاكرين تفهمكم.',
  maximum_number_family_included: 'الحد الاقصى لأفراد العائلة المسموح باضافتهم',
  must_be_less_than: 'يجب ان يكون اقل من او يساوي {{max}}',
  meter: 'متر/أمتار',
  main_menu: 'القائمة الرئيسية',
  mol_registration_number: 'رقم المنشأة في وزارة الموارد البشرية',
  payment_method_account_list: 'حسابات {{name}} البنكية',
  gosi_owner_number: 'المالك/المفوض : {{number}}',
  gosi_owner_number_info: 'المالك/المفوض',
  muded_has_been_installed_successfully: 'تم تفعيل مدد بنجاح',
  proceed_to_Payment: 'اكمال الدفع',
  now_you_can_go_to_payment_and_and_pay_with_one_click_through_muded:
    'الآن بامكانك دفع المرتبات بنقرة واحدة من خلال صفحة تحويل الرواتب',
  mol_name_en: 'إسم المنشأة في مكتب العمل (إنجليزي)',
  Now_you_can_pay_your_employees_directly_from_jisr: 'يمكنك الآن دفع رواتب موظفيك مباشرة من جسر',
  mol_name_ar: 'إسم المنشأة في مكتب العمل (عربي)',
  mudad_card_discription: 'مؤتمتة وسهلة الاستخدام,<br /> مربوطه مع البنوك ونظام حماية الأجور.',
  mudad_processing: 'الدفع عن طريق مدد',
  mudad_step1_success_msg: 'تم تسجيل المؤسسة بنجاح.',
  mudad_step1_alert_msg: 'انت بصدد تسجيل مؤسسة {{rc_name}} في مدد',
  mudad_step_1_check_out: 'يرجى التأكد من',
  manage_bank_account: 'إدارة الحساب البنكي',
  mudad_step_1_legal_structure: 'هيكلة قانونية',
  mudad_step_1_billingـhistory: 'الفواتير',
  manage_integrate: 'إدارة الارتباط',
  due_to_payment_lateness_for_issued_invoices:
    'بسبب تأخر سداد الفواتير الصادرة. لا يمكنك تحديد مدد كوسيلة دفع. يرجى دفع الفواتير المستحقة',
  mudad_step_1_to_fix_the_issues_and_retry_again: 'لمراجعة الأخطاء والمحاولة لاحقا',
  mudad_step4_no_worries: 'بإمكانك اختيار',
  mudad_step4_different_bank: 'بنك آخر',
  mudad_step2_no_data_msg: 'ليس لديك اي موقّعين.',
  mudad_step2_otp_sent_note:
    'تم إرسال <b>رمز التحقق</b> الى <b>{{name}}</b> يرجى إدخال الرمز أدناه.',
  mudad_step3_otp_sent_note:
    'تم إرسال <b>رمز التحقق</b> الى <b>{{name}}</b> لرقم الجوال الذي ينتهي ب <b>{{number}}</b>. يرجى إدخال الرمز أدناه لإكمال التفويض.',
  mudad_step3_no_data_note:
    'ليس لديك أي موظفين مفوضين. <br /> يرجى اختيار موظف لإرسال طلب التفويض.',
  mudad: 'مدد',
  i_gree_on_terms_conditions: 'انا موافق على هذه الشروط و الاحكام',
  mudad_is_configured_with: 'الدفع عن طريق مدد مفعل مع',
  edit_authorization: 'تعديل المفوض',
  delete_authorization: 'حذف المفوض',
  change_payment_method_to_manual_payment:
    'هل تريد استخدام الدفع عن طريق ملف البنك كطريقة دفع المرتبات؟',
  change_payment_method_to_mudad: 'تغيير طريقة الدفع إلى مدد',
  You_will_not_be_able_to_pay_your_employee_with_the_bank_file:
    'لن تتمكن من دفع أجور موظفك من خلال ملف البنك',
  mudad_has_successfully_been_activated_and_configured_with: 'تم تفعيل حسابك في مدد بنجاح مع',
  mudad_step2_owner_msg: 'تم الحقق من مالك المنشأة برقم الهوية {{ number }} بنجاح',
  Min: 'دقيقة',
  min_plural: 'دقيقة',
  mint: 'دقائق',
  modifications: 'التعديلات',
  multi_select: 'تحديد متعدد',
  multi_line_text: 'نص',
  mol_no: 'رقم التسجيل في مكتب العمل',
  cr_mol_no: 'رقم المنشأة في وزارة الموارد البشرية',
  mol_number: 'رقم المنشأة',
  multi_select_list: 'عدة اختيارات من القائمة',
  mapping_employees: 'ربط الموظفين ({{ number }})',
  maximum_value: 'اقصى قيمة {{max}}',
  managers: 'مدراء',
  maximum_logo_size: 'الحد الأقصى لحجم الشعار هو 100 ميغا بايت',
  ministry_of_labor: 'وزارة العمل',
  ministry_of_labor_no: 'رقم التسجيل في مكتب العمل',
  ministry_of_labor_info: 'معلومات وزارة الموارد البشرية والتنمية الاجتماعية',
  moving_employees: 'نقل موظفين',
  move: 'نقل',
  manager_optional: 'المدير (اختياري)',
  move_employees_to_different_department: 'انقل موظفي هذا القسم إلى قسم مختلف لتتمكن من حذف القسم',
  mol_unified_number_for_all_sub_branches_notify:
    'هذا الرقم يمثل رقم المنشأة الموحد في وزارة العمل للمنشأة الرئيسية والفروع',
  main_organization: 'منشأة رئيسية',
  mol_prefix_of_legal_structure: 'بادئة',
  main_org: 'مؤسسة رئيسية',
  ministry_of_commerce_info: 'معلومات وزارة التجارة',
  move_to_another_registration: 'نقل الى منشأة أخرى',
  matches: 'يطابق',

  // N
  new_bank_account: 'بنك حسابي جديد',
  new_iban: 'آيبان جديد',
  name_ar_cant_be_blank: 'الاسم العربي لايمكن أن يكون فارغ',
  name_en_cant_be_blank: 'الاسم الانجليزي لايمكن أن يكون فارغ',
  notify_employees_about_their: 'لأرسال تنبيهات لموظفي المؤسسة بخصوص ذكرى عمل زملائهم',
  new_overall_rating: 'إضافة تقيم هدف جديد',
  new_competencies_rating: 'إضافة تقييم الكفائات جديد',
  important_notes_before_setup_mudad: 'ملاحظات هامة قبل إعداد مداد',
  your_subscription_will_start_after_completing_registration:
    'سيبدأ اشتراكك بعد الانتهاء من التسجيل!',
  you_ll_need_to_pay_for_the_subscription:
    'ستحتاج إلى دفع رسوم الاشتراك في غضون الثلاثين يومًا القادمة لتجنب تعطيل اشتراك مدد ، <span>وسوف نرسل لك فاتورة تحتوي على التفاصيل المصرفية لسداد الاشتراك</span>',
  new_normal_review_overall_rating: 'إضافة التقييم العام للفترة الإعتياديه جديد',
  new_probation_review_overall_rating: 'إضافة تقييم العام لفترة التجربة جديد',
  mudad_subscription: 'إشتراك مدد',
  that_is_you_have_your_company_legal_structure_in_Jisr_is_correct:
    'تأكد من أن تعريف الهيكل القانوني للمنشأة في جسر صحيح',
  make_sure_that_your_bank_account_is_linked_to_your_commercial:
    'يجب آن يكون الحساب المصرفي للمنشأة المسجلة بالسجل التجاري',
  We_will_send_you_invoice:
    'سوف نرسل لك فاتورة تحتوي تحتوي على التفاصيل البنكية لسداد مبلغ الاشتراك. ستحتاج إلى دفع رسوم الاشتراك في غضون الثلاثين يومًا القادمة لتجنب تعطيل اشتراك مدد',
  make_sure_to_contact_your_organization_note:
    'كن على تواصل مع مالك المنشأة او المفوض على التأمينات الاجتماعية، سنرسل رسالة نصية تحتوي علي رمز التحقق علي هاتفهم المحمول ',
  new_department: 'إضافة قسم',
  new_area: 'اضافة منطقة جديدة',
  netsuite_base_url: 'netsuite_base_url',
  netsuite: 'نت سويت',
  netsuite_help_note: 'ما زلت تريد مساعدة؟ <span>تواصل معنا</span>',
  netsuite_info_note:
    'ملاحظة: ننصح بتواجد شخص مُلم من فريق تقنية المعلومات لديه المعرفة بإضافة الإعدادات اللازمة لربط حساب نت سويت مع {{app_name}}.',
  netsuit_login_details: 'معلومات التسجيل',
  notifications: 'الإشعارات',
  new_joining_notification: 'تمكين إشعارات المنضمين حديثاً',
  number_of_days: 'عدد الأيام',
  no_bank_accounts: 'لا يوجد حسابات بنكية',
  you_have_not_add_bank_account_yet: 'لا توجد لديك حسابات بنكية مضافة',
  next_billing_at: 'تاريخ الفوترة التالي : <b>{{ date }}</b>',
  next_billing: 'تاريخ الفوترة التالي <b>{{ date }}</b>',
  number_of_employees: 'عدد الموظفين',
  number_of_employees_in_current_dep: 'عدد الموظفين في القسم',
  no_attendance_configuration: 'تعريف/إعدادات إعفاء الحضور',
  no_attendance_navigation: 'إعفاء الحضور',
  no_dont_want: 'لا',
  none: 'بلا',
  new_shift_type: 'اضافة دوام جديد',
  name_your_policy: 'إسم السياسه ',
  new_excuse_policy: 'سياسة استئذان جديدة',
  name_in_ar: 'الاسم بالعربي',
  name_in_english: 'الاسم بالانجليزي',
  new_addition: 'إضافة جديدة',
  new_deduction: 'خصم جديد',
  new_allowances: 'بدل جديد',
  new_loan: 'سلفة جديد',
  new_cost_type: 'اضافة تكليف ',
  new_grade: 'مستوى وظيفي جديد',
  new_records_add: '<strong>{{count}}</strong> سجل جديد تمت إضافته',
  new_workflow: 'موافقة جديدة',
  new_step: 'خطوة موافقه جديدة',
  new_workflow_step: 'خطوة موافقه جديدة',
  name_your_shift: 'اسم الدوام',
  not_paid: 'لم تدفع',
  new_role: 'صلاحية جديدة',
  new_policy: 'إضافة سياسة جديدة',
  new_admin: 'اضافة مدير',
  new_hourly_rate: 'إضافة سعر ساعة جديد ',
  new_holidays: 'إجازة عيد جديد',
  notify_people: 'الاشعارات',
  no_not_yet: 'لا, ليس الان',
  number_of_hours:
    'عدد الساعات التي يمكن للموظفين أن يتأخروا فيها (<b>{{flexible_duration}}</b>) هو عدد الساعات التي يمكنهم البقاء فيها متأخرًا',
  new_shift: 'فترة جديدة',
  name_in_arabic_optional: 'الاسم بالعربية (اختياري)',
  new_shift_combo: 'إنشاء مجموعة دوامات',
  new_fixed_hours_template: 'دوام ثابت جديد',
  noon: 'وقت الظهيرة',
  not_working: 'إجازة',
  netsuite_is_connected_to_jisr: 'حساب نت سويت الآن مرتبط',
  quickbooks_is_connected_to_jisr: 'حساب كويك بوكس الآن مرتبط',
  xero_is_connected_to_jisr: 'حساب زيرو الآن مرتبط',
  qoyod_is_connected_to_jisr: 'حساب قيود الآن مرتبط',
  microsoft_is_connected_to_jisr: 'حساب مايكروسوفت الآن مرتبط',
  microsoft_finops_is_connected_to_jisr: 'حساب MS Dynamics Finance & Operation الآن مرتبط',
  oracle_fusion_is_connected_to_jisr: 'حساب Oracle Fusion الآن مرتبط',
  zoho_books_is_connected_to_jisr: 'حساب Zoho Books الآن مرتبط',
  sap_is_connected_to_jisr: 'حساب Sap Business One الآن مرتبط',
  odoo_is_connected_to_jisr: 'حساب اودو الآن مرتبط',
  connected_app_note: 'أبدا في إعداد ربط نت سويت مع {{app_name}}',
  qoyod_connected_app_note: 'أبدا في إعداد ربط قيود مع {{app_name}}',
  netsuite_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ نت سويت مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  quickbooks_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ كويك بوكس مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  xero_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ زيرو مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <span>أقرا المزيد عن مزامنة معلومات الموظفين</span>',
  microsoft_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ مايكروسوفت مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  sap_sync_employees_note:
    'تفعيل المزامنة سيقوم جسر بتحديث واضافة معلومات الموظفين الموجودة في جسر لـ Sap Business One مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  odoo_sync_employees_note:
    'تفعيل المزامنة سيقوم جسر بتحديث واضافة معلومات الموظفين الموجودة في جسر لـ اودو مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  microsoft_finops_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ اودو مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  oracle_fusion_sync_employees_note:
    'مزامنة بُعد الموظفين مع أوراكل فيوجن يعني تفعيل مزامنة بُعد الموظفين أن {{app_name}} سيقوم بمطابقة بيانات الموظفين بناءً على بُعد الموظف المحدد في أوراكل فيوجن/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',

  zoho_books_sync_employees_note:
    'تفعيل المزامنة سيقوم {{app_name}} بتحديث واضافة معلومات الموظفين الموجودة في {{app_name}} لـ اودو مثل (الاسم، الايميل...الخ). لمعرفة المزيد أقرا هذا المقال/ <a>أقرا المزيد عن مزامنة معلومات الموظفين</a>',
  number: 'أرقام',
  how_to_get_these_information_odoo: 'كيف يمكنني الحصول على هذه المعلومات؟<a> اقرأ المقال</a>',
  name_first_last: 'الإسم: <b>{{nameFirst}} {{nameLast}}</b>',
  not_allowed_clock_in_before: 'غير مسموح تسجيل الحضور قبل',
  not_allowed_clock_in_after: 'غير مسموح تسجيل الحضور بعد',
  not_allowed_clock_out_before: 'غير مسموح تسجيل الانصراف قبل',
  not_allowed_clock_out_after: 'غير مسموح تسجيل الانصراف بعد',
  no_earlier_than: 'قبل بداية الدوام بـ',
  new_joining_employee_reflection: 'فروقات تاريخ التعيين',
  new_joining_employee_reflection_desc:
    'يؤثر توقيت انضمام الموظفين الجدد على احتساب أول راتب لهم في شهر الانضمام أو الشهر الذي يليه، ويكون التأثير بالزيادة إذا كان الانضمام بعد تاريخ الاستقطاع وبالنقص إذا كان قبله.',
  no_earlier_than_out: 'قبل نهاية الدوام بـ',
  no_later_than: 'بعد بداية الدوام بـ',
  no_later_than_out: 'بعد نهاية الدوام بـ',
  new_changes_were_made: 'تم إجراء تعديلات على هذا الدور',
  new_shift_preset: 'تعريف فترة جديدة',
  new_template: 'قالب جديد',
  new_fixed_working_hours: 'دوام ثابت جديد',
  new_fixed_working_hours_template: 'تعريف فترة جديدة',
  no_users_have_been_assigned: ' لم يتم تعيين مستخدمين لهذا الدور بعد',
  new_company_documents: 'إضافة مستندات المنشأة',
  new_outsourcing_provider: 'اضافة شركة تأجير عمالة',
  new_parent_department: 'إضافة إدارة جديدة',
  new_locations: 'مواقع جديد',
  new_location: 'موقع جديد',
  new_job_titles: 'اضافة مسمى وظيفي جديد',
  new_overtime_policy: 'اضافة سياسة العمل الإضافي',
  new_overtime: 'سياسه جديدة',
  new_custom: 'مخصص جديد',
  new_assets: 'اضافة عهدة',
  new_loan_types: 'اضافة نوع سلفة',
  no_billing_history: 'لا توجد فواتير سابقة',
  no_devices_registered: 'لا توجد أجهزة مسجلة',
  not_included: 'لا يشمل',
  new_departments: 'إضافة قسم',
  no_manager_assigned: 'لم يتم تعين مدير',
  new_payment_method: 'اضافة طريقة دفع جديدة',
  no_result_for: 'لا توجد نتائج',
  new_asset_type: 'نوع عهدة جديد',
  no_data_available: 'لا توجد بيانات',
  new_api_key: 'مفتاح API جديد',
  new_group: 'مجموعة جديدة',
  new_fixed_working_hours_group: 'دوام ثابت جديد',
  new_open_shift: 'اضافة دوام مفتوح جديد',
  employee_number: '{{number}} موظف',
  new_subsidiary: 'شركة فرعية جديدة',
  new_business_unit: 'وحدة عمل جديدة',
  new_org_registration: 'تسجيل جديد',
  new_org_location: 'موقع جديد',
  new_org_department: 'قسم جديد',
  no_assigned_manager: 'لم يتم اسناد مدير',
  notify_the_organization_employees: 'لأرسال تنبيهات لموظفي المؤسسة بخصوص أعياد ميلاد زملائهم',
  // O
  organization_located_countries: 'مواقع المنشأة',
  oauth_consumer_key: 'oauth consumer key',
  overtime_settings: 'إعداداتك',
  overtime_number_of_overtime_hours:
    'ساعة العمل الأضافي = <b>قيمة الساعة بحسب إعدادتك الحالية</b> + ({القيمة المخصصة - ١} * {الاجر الأساسي للساعة})',
  oauth_consumer_secret: 'oauth consumer secret',
  oauth_token: 'oauth token',
  an_otp_has_been_sent: 'تم إرسال رمز التحقق للبريد الإلكتروني ، يرجى إدخال الرمز أدناه:',
  oauth_token_secret: 'oauth token secret',
  oauth_realm: 'oauth realm',
  ORGANIZATION: 'المنشأة',
  // organization: 'إعدادات الشركة',
  organization_settings: 'إعدادات المنشأة',
  organization_profile: 'الملف التعريفي للشركة',
  other_leave_configuartion: 'تعريف /إعدادات أنواع الإجازات الأخرى',
  overtime: 'عمل إضافي',
  overtime_ta: 'عمل اضافي مؤكد',
  overtime_request: 'طلب عمل إضافي',
  overtime_policy: 'سياسة العمل الإضافي',
  overtime_calculation: 'حساب الأوفر تايم',
  organization_information_en: 'معلومات الشركة بالإنجليزية',
  organization_information_ar: 'بيانات المنشأة بالعربي',
  organization_name: 'إسم الشركة',
  organization_mediafiles: 'ملفات الشركة',
  organization_logo: 'شعار الشركة',
  organization_registration_optinal: 'المنشأة المسجلة للموظف',
  organization_registration_optinal_description: 'سيظهر الموظف في ملف الدفع للمنشأة المسجلة',
  org_departments: 'الأقسام',
  org_department: 'الإدارة',
  of_basic_salary: 'من الراتب الأساسي',
  other_leave_types_configuration: 'تعريف /إعدادات أنواع الإجازات الآخرى',
  options: 'الخيارات',
  overtime_calculated_based_on: 'يتم إحتساب الأوفر تايم على أساس:',
  other_leave: 'اجازات اخرى',
  overtime_hour_rate_can_employee_apply_for:
    'يستطيع الموظف التقديم على طلب وقت اضافي بمعدل احتساب الساعة التالي:',
  overlapping_vacation_salary_reflection: 'فروقات الرواتب بعد الإجازة المدفوعة مقدمًا',
  overlapping_vacation_salary_desc:
    'يؤثر توقيت الإجازات المدفوعة مقدمًا على احتساب الراتب بالنقص في حال دفع جزء من الراتب مقدمًا، وبالزيادة في حال تأجيل دفع المتبقي من الراتب لحين عودة الموظف من الإجازة.',
  overtime_polices: 'سياسات الاوفر تايم',
  overtime_workflow: 'عمل إضافي',
  outsourcing_company: 'شركة تأجير عمالة',
  out_of_payroll: 'خارج مسير الرواتب',
  out_of_payrun: 'خارج مسير الرواتب',
  overtime_deduct: 'إضافات العمل الإضافي',
  violation_calculation: 'خصم مخالفات قانون العمل',
  owner_mudad_step2_otp_sent_note:
    'تم إرسال <b>رمز التحقق</b> الى صاحب رقم الهوية {{ number }} يرجى إدخال الرمز أدناه.',
  owner_verification: 'التحقق من هوية مالك المنشأة',
  owner_form_note: 'يرجى إدخال هوية مالك المنشأة او الشخص المفوض في التأمينات الإجتماعية',
  out_sourcing_companies: 'شركات تأجير العمالة',
  offline_processing: 'الدفع عن طريق ملف البنك',
  offline_card_discription: 'دفع الرواتب يدويا,<br /> عن طريق تحميل ملف البنك ورفعه للبنك.',
  offline_supported_info_card: '<b>{{name}}</b> — {{iban}}',
  offline_form_config_msg:
    'ليس لديك أي موظفين مفوضين.يرجى إضافة موظف لتفعيل طريقة صرف الرواتب (ملف البنك)',
  otp_sent: 'تم إرسال رمز التحقق',
  otp_valid_until: '<b>رمز التحقق</b> صالح لمدة <b>{{time}}</b> دقيقة.',
  overtime_paid_to_employee: 'هل يتم دفع ساعات العمل الإضافية للموظف؟',
  overtime_paid_to_employee_hint:
    'نعم: سيتم دفع الإضافي في مسير الرواتب، لا: سيتم دفع الإضافي خارج مسير الرواتب (في تقرير موظفوا شركات العمالة)',
  x1_overtime_hour_rate: 'الساعة الأولى تُدفع من كامل الراتب, ونصف ساعة تُدفع من الراتب الأساسي',
  x2_overtime_hour_rate:
    'الساعة الأولى تُدفع من كامل الراتب, والساعة الأخرى تُدفع من الراتب الأساسي',
  offline_processing_has_been_configured_with: 'تم تفعيل طريقة الدفع عن طريق ملف البنك بنجاح مع',
  offline_processing_configured_with: 'الدفع عن طريق ملف البنك مفعل مع',
  restaurant_management_software: 'نظام إدارة مطاعم',
  other_assigned_roles: 'أدوار اخرى مسندة',
  only_people_with_permission_payment_processing:
    'فقط الاشخاص الذين لديهم صلاحية عرض تحويل الرواتب',
  org__structure: 'الهيكل التنظيمي',
  outsourcing_providers: 'شركة تأجير عمالة',
  overtime_configurations: 'اعدادات سياسات العمل الإضافي',
  open_shifts: 'دوامات مفتوحة',
  open_shift: 'دوام مفتوح',
  once_you_cancel_your_subscription: 'بمجرد إلغاء اشتراكك:',
  one_of_our_most_experienced_employees_discription:
    'سيتم التواصل معاك من قبل أحد موظفينا الأكثر كفاءة في مجال الموارد البشرية على وجه السرعة للمساعدة في حل مشكلتك.',
  only_allow_requests_for_days_with_atleast_2_fingerprints:
    'السماح بتقديم الطلبات في حال وجود على الأقل بصمتين لليوم المطلوب',
  only_allow_requests_for_days_with_required_hours_worked:
    'السماح بتقديم الطلبات في حال إتمام كامل ساعات العمل المطلوبة في الدوام المسند',
  // P
  preview: 'معاينة',
  payroll_item: 'بند الراتب',
  add_from_shift_presets: 'أضف من الفترات المعرّفة',
  overlap: 'تداخل',
  orange: 'برتقالي',
  Or: 'أو',
  own: 'للفرد',
  only_managers_with_employees_can_be_assigned:
    'يمكن منح هذا الدور فقط للمدراء الذين لديهم موظفين أو أقسام تحت إدارتهم',
  only_managers_with_employees_will_be_listed:
    'فقط المدراء الذين لديهم موظفين أو أقسام أو فروع تحت إدارتهم سيظهرون في هذه القائمة',

  // P
  passwords__login: 'تسجيل الدخول وكلمات المرور',
  passwords__login_description: 'التحكم بالتفضيلات الأمنية لتسجيل الدخول، والتوثيق، وكلمات المرور.',
  department_title_in_english: 'Department Title  in English:AR',
  department_title_in_arabic: 'Department Title  in Arabic:AR',
  permissions: 'الصلاحيات',
  pay_type: 'انواع المدفوعات',
  payment_setup: 'Payment Process:AR',
  payment_info: 'معلومات الدفع',
  payment_information: 'معلومات الدفع',
  new_pay_type: 'نوع دفع جديد',
  parent_departments: 'القطاع',
  proceed_to_payment: 'متابعه للدفع',
  parentdepartment: 'القطاع',
  payment_due: 'مستحقة',
  paid: 'مدفوعة',
  payroll_management: 'كشف الرواتب',
  penalty: 'عقوبة جزائية',
  process__payment: 'الاستقطاع والدفع',
  process_payment_page_header: 'تحويل الرواتب <span>• راتب {{date}}</span>',
  process_payment_page_header_low_tires: 'تحويل الرواتب <span> {{date}}</span>',
  process_payment_page_sub_header_low_tires:
    '<span class="payroll-month"> {{date1}} مسير الرواتب</span>',
  process_setup: 'Payroll Process:AR',
  partially_updated: 'تم التحديث جزئيًا',
  processing_data: 'قيد المعالجة',
  payment_configuration: 'طرق الدفع',
  payment_id: 'رقم الدفعة',
  payment_tracking: 'تتبع مدفوعات الرواتب',
  payment_status: 'حالة الدفع',
  plan_active_employees: 'عدد الموظفين :  <b> {{ count }} </b>',
  payment_two_factor_auth: 'رمز التحقق لتحويل الرواتب',
  payment_two_factor_auth_desc:
    'تحويل الرواتب عن طريق مدد يتطلب إدخال رمز التحقق المرسل على البريد الإلكتروني.',
  payroll_payment_configuration: 'إعدادات دفع الرواتب',
  ping_script_number: 'Ping script number',
  policy_name: 'اسم السياسة',
  payroll_elements: 'بنود الراتب',
  payroll_workflow: 'مراجعة مسير الرواتب',
  final_settlement_workflow: 'مراجعة  نهاية الخدمة',
  vacation_settlement_workflow: 'مراجعة  مخالصة الإجازة',
  policy_warning: 'احفظ السياسة أولاً ثم قم بالتعديل',
  po_box: 'رقم الصندوق البريدي',
  paid_amount: 'المبلغ المدفوع',
  payroll_calculation_configuration: 'اعدادات احتساب مسير الرواتب',
  parent_department_name: 'اسم الإدارة بالإنجليزية',
  parent_department_name_ar: 'اسم الإدارة بالعربية',
  permission_change_warning: 'عند تغيير صلاحية العرض فإن صلاحيات أخرى ستتغير في المقابل',
  persmission_level: 'نوع الصلاحية',
  previous_year_balance: 'رصيد السنة السابقة',
  payroll_elements_mapping: 'ربط قوائم مسير الرواتب بالشجرة المحاسبية',
  payroll_element: 'مسيرالراتب',
  progressing: 'جار المعالجة...',
  processed_transactions: 'اجمالي  التحويلات',
  payroll_payment_task: 'مهمة دفع مسير الرواتب',
  payroll_payment_task_message: 'مهمة دفع مسير الرواتب <span>({{message}})</span>',
  paternity_description: 'إجازة المولود: 3 أيام بأجر كامل.',
  permission_level: 'نوع الصلاحية',
  per_year: 'سنوياً',
  per_contract: 'بناء على العقد',
  pay_mode: 'أسلوب الدفع',
  paid_annual_leave_diff_description:
    'يؤثر توقيت الإجازات المدفوعة مقدمًا على احتساب الراتب بالنقص في حال دفع جزء من الراتب مقدمًا، وبالزيادة في حال تأجيل دفع المتبقي من الراتب لحين عودة الموظف من الإجازة.',
  parent_department_in_english: 'اسم الإدارة بالإنجليزية',
  parent_department_in_arabic: 'اسم الإدارة بالعربية',
  per_day: 'باليوم',
  per_night: 'بالليلة',
  per_day_description: 'باليوم: سيكون حساب عدد أيام الإقامة هو نفسه الأيام التي قدّم عليها الموظف',
  per_night_description: 'بالليلة: سيكون حساب عدد أيام الإقامة أقل من الأيام المقدمة بيوم واحد',
  sending_payslip_enabled: 'السماح بإرسال كشف الرواتب للموظفين عبر البريد الالكتروني',
  password_policy: 'سياسة كلمة المرور',
  password_policy_save_note:
    'سيتم تسجيل خروج جميع حسابات الموظفين. وعليهم إعادة تعيين كلمات المرور الخاصة بهم مرة أخرى للوصول إلى سياسة كلمة مرور جديدة. يرجى التأكد من أن جميع الموظفين قد أضافوا بريدهم الإلكتروني لتلقي رسائل البريد إلكتروني لإعادة تعيين كلمة المرور.',
  password_complexity_requirement: 'كلمة مرور معقدة',
  password_complexity_requirement_desc:
    'لا يمكن أن تحتوي كلمة المرور على الاسم الأول أو اسم العائلة أو عنوان البريد الإلكتروني للمستخدم. يجب أن تستخدم كلمات المرور ثلاثة على الأقل من أنواع الأحرف الأربعة المتاحة ، وهي أحرف صغيرة وأحرف كبيرة وأرقام ورموز.',
  passwords_must_meet_complexity_requirements: 'يجب أن تفي كلمات المرور بمتطلبات الحماية و الامان',
  punching_in_not_needed: 'تسجيل الحضور غير مطلوب، سيقوم  النظام بذلك تلقائياً',
  punching_out_not_needed: 'بصمة الخروج غير مطلوبة، سيقوم  النظام بذلك تلقائياً',
  punch_time: 'وقت البصمه',
  print: 'طباعه',
  pm: 'مساءً',
  publish_changes: 'نشر التغييرات',
  pink: 'وردي',
  purple: 'بنفسجي',
  performance_management: 'إدارة الأداء',
  public_holidays_setting_description:
    'الأعياد الرسمية تعد أيام إجازة لجميع الموظفين في تواريخ محددة.',
  password_reusing_limit: 'حد إعادة استخدام كلمة المرور',
  payment_methods_setup_warning_note:
    'إذا قمت بتأكيد مسير الرواتب لهذا الشهر ، فسيتم تطبيق أي تغييرات في الشهر التالي',
  password_reusing_limit_desc:
    'سيمنع المستخدمين من تغيير كلمة المرور لأخرى مستعملة مسبقًا، ويمكنك تحديد عدد كلمات المرور الممنوعة من التكرار.',
  password_reusing_limit_note:
    'هل أنت متأكد من تطبيق إعادة استخدام كلمة المرور في الخيارات المحددة؟',
  passwords: ' كلمات مرور',
  phone_no: 'رقم الجوال',
  postal_zip_code: 'الرمز البريدي',
  policy_configurations: 'اعدادات السياسة',
  policies: 'السياسات',
  Please_make_sure_you_are_description:
    'يرجى التأكد من القيام بتنصيب تطبيق {{app_name}} في حسابكم الخاص في فودكس وتسجيل الدخول إلى الحساب قبل البدء بالعملية.',
  permission: 'الصلاحية',
  punches_out_of_this_window:
    'البصمات خارج إطار النافذة الزمنية ستعرّف بـ<span>"خارج النافذة"</span> ويمكن تصحيحها يدويًا أو عبر سلسلة الموافقات',
  parent_dept: 'الأقسام',
  permissions_summary: 'ملخص الصلاحيات',
  payables: 'المدفوعات',
  payroll_review: 'مراجعة مسير الرواتب',
  PERFORMANCE: 'الأداء',
  payroll_payment: 'مسير الرواتب',
  parent_department_optional: 'القسم الرئيسي (اختياري)',
  process_payments_directly_with_bank: 'تحويل الرواتب بالربط المباشر مع البنك',
  paying_bills_at_jisr: 'دفع الفواتير في {{app_name}}',
  paying_bills_at_jisr_desc: 'طريقة دفع الفواتير بالخطوات',
  please_enter_shift_name_in_english: 'يرجاء ادخال اسم الدوام باللغة الانجليزية',
  please_enter_shift_name_in_arabic: 'يرجاء ادخال اسم الدوام باللغة العربية',
  per: 'خلال:',
  please_take_a_moment_to_let_us_know_description:
    'يرجى تخصيص بعض الوقت لإعلامنا بسبب الإلغاء.  ملاحظاتك محل تقدير كبير بالنسبة لنا',
  password_reset_link_expired: 'رابط إعادة تعيين كلمة المرور منتهي',
  password_reset_link_expired_description:
    'عذرًا! يبدو أن الرابط الذي استخدمته لإعادة تعيين كلمة المرور قد انتهت صلاحيته.<br /> الرجاء طلب رابط جديد للمتابعة.',
  // Q
  quickbooks: 'كويك بوكس',
  quickbooks_sync_accounts: 'مزامنة الحسابات مع كويك بوكس',
  quickbooks_sync_trackings: 'مزامنة الاقسام مع كويك بوكس',
  quickbooks_account_code: 'حساب كويك بوكس',
  quickbooks_sync_please: 'الرجاء المزامنة أولا',
  quickbooks_export: 'تصدير إلى كويك بوكس',
  quick_info_to_be_considered: 'معلومة يجب الإطلاع عليها',
  // R
  getting_users_basic_information: 'الوصول الى المعلومات الاساسية لمستخدمي نظام {{apps}}',
  remind_employees_to_punch: 'تنبيه الموظفين قبل بداية الدوام في حالة عدم تسجيل حضورهم',
  reminders_are_now_available_as_push_notifications:
    'ترسل التنبيهات عن طريق تطبيق {{app_name}}، تأكد من ان الموظفين لديهم اخر تحديث للتطبيق',
  reminders_will_be_sent_to_all_employees:
    'ترسل التنبيهات لكل الموظفين ما عدا المسند لهم دوامات مفتوحة (ليست مدعومة حاليًا)',
  remind_employees_on_the_selected_shift:
    'تنبيه الموظفين قبل تطبيق الخصومات في حالة عدم تسجيل حضورهم',
  remind_employees_on_the_selected_shift_to_out:
    'تنبيه الموظفين قبل نهاية الدوام في حالة عدم تسجيل أنصرافهم',
  remind_employees_on_the_selected_shift_to_in:
    'تنبيه الموظفين قبل تطبيق الخصومات حسب السياسات في حالة عدم تسجيل حضورهم بعد',
  rating_methods: 'آلية التقييم',
  remind_employees_to_log_their_punches: 'نبه الموظفين بتسجيل حضورهم وأنصرافهم',
  record_count: 'عدد الحقول',
  request_workflow: 'سلسلة الموافقات',
  roles: 'الأدوار',
  retro_appraisal_diff_description:
    'يؤثر تاريخ تعديل رواتب الموظفين على احتساب الراتب بالزيادة أو بالنقص، في حال تسجيل التعديل بأثر رجعي.',
  roles_and_permissions: 'صلاحيات الدخول',
  registration_number: 'رقم الوثيقة',
  registration_expiry_date: 'تاريخ انتهاء الوثيقة',
  registration_name: 'اسم الوثيقة',
  role_type: 'نوع الصلاحية',
  resend_new_otp: 'إعادة إرسال رمز تحقق جديد',
  resend_otp: 'إعادة إرسال رمز تحقق',
  resend_after: 'ستتمكن من إعادة الإرسال بعد {{min}}:{{sec}}',
  resumption: 'Resumption:ar',
  register_establishment: 'تسجيل المؤسسة',
  retro_appraisal_reflection: 'فروقات الرواتب المعدلة بأثر رجعي',
  retro_appraisal_reflection_desc:
    'يؤثر تاريخ تعديل رواتب الموظفين على احتساب الراتب بالزيادة أو بالنقص، في حال تسجيل التعديل بأثر رجعي.',
  re_verify: 'إعادة التحقق',
  re_authorize: 'إعادة التحقق',
  request_authorization: 'طلب التفويض',
  resumption_configuration: 'إعدادات الاستئناف من الإجازة',
  resignation_workflow: 'طلب استقالة',
  repeat_shift: 'تكرار الدوام',
  repeat: 'تكرار',
  rules: 'القواعد',
  recently_created: 'تم إنشاؤه مؤخراً',
  removed_from_shift: 'تمت إزالة <span> {{employee}} </span> من نموذج الدوام',
  removing_unassign:
    'ستؤدي إزالة الموظفين إلى إلغاء تعيينهم من أن يكونوا موظفين ساعات ثابتة ، مما يعني أنك ستحتاج إلى إدارة دواماتهم من المجدول. إذا كنت تخطط لإعادة تعيينها إلى نموذج آخر لساعات العمل الثابتة ، من الأفضل تعيينها مباشرة من النموذج الآخر. <span> انقر هنا للتراجع عن الإزالة </ span>',
  remove_shift: 'إزالة الدوام',
  red: 'أحمر',
  reset_default: 'إعادة الوقت الافتراضي',
  reset: 'إلغاء',
  remove_all_permissions: 'إزالة  كامل الصلاحيات',
  remove_user_from_role: 'إزالة المستخدم من الدور',
  remove_user_from_role_named: 'إزالة {{user}} من {{role}}',
  remove_all_premissions_from_user: 'إزالة جميع الصلاحيات من {{user}}',
  request_another_invitation: 'طلب رابط دعوة آخرى',
  removing_all_permissions:
    'بعد إزالة جميع الصلاحيات الممنوحة لهذا المستخدم سيكون قادراً على الوصول الى معلوماته الشخصية فقط',
  removing_user_from_this: 'إزالة المستخدم من هذا الدور سيقلل من الصلاحيات الممنوحة له',
  roles_alt: 'أدوار المستخدمين',
  remove_photo: 'إزالة صورة',
  requests_employee_alert_message: 'لن يتمكن الموظفون من إرسال الطلبات دون إسنادهم إلى موافقة.',
  renew_the_subscription: 'تجديد الاشتراك',
  registered_organizations: 'المنشآت المسجلة',
  register_organization: 'تسجيل المنشأة',
  reactivate_your_subscription: 'أعد تفعيل الاشتراك',
  reactivate_your_subscription_description:
    'قم بإعادة تفعيل اشتراكك، وسيعود كل شيء إلى ما كان عليه قبل الإلغاء، ويمكن لموظفيك استئناف استخدام {{app_name}} على الفور',
  recalculate: 'إعادة الحساب',
  // S
  select_cr: 'إختر السجل التجاري',
  select_the_system: 'أختر النظام',
  select_how_you_want_to_send_push: 'أختر كيف يتم أرسال التنبيهات للموظفين لتذكيرهم بتسجيل بصماتهم',
  show_guide: 'اظهر الدليل',
  select_how_you_want_to_send_push_notifications:
    'أختر كيف يتم أرسال التنبيهات للموظفين لتذكيرهم بتسجيل بصماتهم',
  send_reminders_the_organization_employees:
    'لإرسال تنبيهات عن طريق تطبيق {{app_name}} لموظفي المنشأة بخصوص تسجيل حضورهم وانصرافهم مما قد يساعد في تقليل طلبات تصحيح البصمة',
  start_counting_and_auto_approve:
    'سوف يبدأ احتساب حتى <span>{{overtime}}</span> من العمل الإضافي بشكل تلقائي، فقط بعد أن يكون الموظف بقي لمدة <span>{{overtimeHr}} </span> علي الأقل {{state}} انتهاء الدوام  <br /> <span>مثال</span>: اذا كان الدوام يجب أن ينتهي في {{endTime}} وساعات العمل الإضافي حتى {{overtime}} ولن يتم احتساب ساعات عمل اضافي اقل من {{overtimeHr}} .',
  start_counting_and_auto_approve_before_shift_end_time:
    'سوف يبدأ احتساب حتى <span>{{overtime}}</span> من العمل الإضافي بشكل تلقائي، فقط بعد أن يكون الموظف بقي لمدة لا تقل عن<span>{{overtimeHr}}</span> بين <span>{{startOvertime}}</span> و <span>{{endTime}}</span> .',
  start_calculating_after_specific_duration: 'بدء حساب العمل الإضافي التلقائي بعد مدة معينة؟',
  start_counting_and_auto_approve_up_to:
    '  احتساب واعتماد حتى <span>{{overtimeHr}}</span>  تلقائياً فقط عند إكمال الموظف<span>{{workingHoursShift}} عمل</span>',

  earning_difference_view: 'عرض فروقات الرواتب',
  earning_difference_option: 'عرض فروقات الرواتب',
  salary_earning_view_option:
    'توفر خيارات عرض الرواتب إمكانية تحديد طريقة عرض القيم المؤثرة على احتساب الراتب الأساسي والبدلات في جدول مسير الرواتب. دون أن تؤثر طريقة العرض على تسجيل القيود المحاسبية.',
  separated: 'قيمة منفصلة',
  splitted: 'قيمة مدمجة',
  separated_new_hiring_desc:
    'إظهار عمود منفصل لفروقات تاريخ التعيين في شهر الانضمام في جدول مسير الرواتب.',
  splitted_merge_desc: ' دمج الفروقات مع الرواتب والبدلات.',
  separated_vacation_desc: 'إظهار عمود إضافي لفروقات راتب الإجازة في جدول مسير الرواتب.',
  separated_retro_desc: 'إظهار عمود إضافي لفروقات تعديل الراتب بأثر رجعي في جدول مسير الرواتب.',
  setup_payment: 'إعداد الدفع',
  saved_iban: 'ايبان محفوظ',
  setup_payment_method: 'إعداد طريقة الدفع',
  select_employees: 'اختيار الموظفين',
  show_only_added_employees: 'إظهار الموظفين المضافين فقط',
  select_mudad: 'اختار مدد',
  select_offline: 'اختار الدفع عن طريق ملف البنك',
  search_department: 'البحث عن قسم',
  select_parent_department: 'أختار إدارة',
  select_area: 'اختار منطقة',
  get_fresh_list: 'تحديث القائمة',
  same_location: 'نفس الموقع',
  same_location_desc: 'جميع الموظفين سينتقلون إلى الموقع المحدد',
  same_department: 'نفس القسم',
  same_department_desc: 'جميع الموظفين سينتقلون إلى القسم المحدد',
  select_department: 'اختار قسم',
  select_city: 'اختار مدينة:AR',
  security: 'الحماية و الأمان',
  SECURITY: 'الأمان',
  see_details: 'استعرض التفاصيل',
  subsidaries_mapping: 'ربط الفروع',
  subsidaries: 'الفروع',
  subsidaries_updated_successfully: 'ربط الفروع تم بنجاح',
  support: 'الدعم الفني',
  notification_setting: 'اعدادات الاشعارات',
  subsidiaries_script_number: 'Subsidiaries script number',
  subscription_details: 'تفاصيل الاشتراك',
  sponsors: 'التأمينات الاجتماعية',
  shift_name: 'اسم الدوام',
  shift_name_ar: 'اسم الدوام بالعربي',
  sync_salary_update_with_gosi: 'تحديث الراتب في التأمينات الاجتماعية',
  shift_type: 'نوع الدوام',
  shift_starting_time: 'وقت بداية الدوام',
  shift_ending_time: 'وقت نهاية الدوام',
  split_time: 'الوقت الفاصل',
  second_shift_start_time: 'تسجيل الدخول للفترة الثانية',
  second_shift_exit_time: 'تسجيل الخروج للفترة الثانية',
  shift_punch_time: 'وقت بصمة الدوام',
  start_time: 'وقت البداية',
  sync_employees_information_to_netsuite: 'مزامنة معلومات الموظفين في حساب نت سويت',
  sync_employees_information_to_quickbooks: 'مزامنة معلومات الموظفين في حساب كويك بوكس',
  sync_employees_information_to_microsoft: 'مزامنة معلومات الموظفين في حساب مايكروسوفت',
  sync_employees_information_to_microsoft_finops:
    'مزامنة معلومات الموظفين في حساب MS Dynamics Finance & Operation',
  sync_employees_information_to_oracle_fusion: 'مزامنة معلومات الموظفين في حساب Oracle Fusion',
  sync_employees_information_to_zoho_books: 'مزامنة معلومات الموظفين في حساب Zoho Boooks',
  sync_employees_information_to_odoo: 'مزامنة معلومات الموظفين في حساب اودو',
  sync_employees_information_to_sap: 'مزامنة معلومات الموظفين في حساب Sap Business One',
  sync_employees_information_to_xero: 'مزامنة معلومات الموظفين في حساب زيرو',
  second_shift: 'الفترة الثانية',
  set_delay_allowed_time: 'تمكين التأخير المسموح',
  status_on_off: 'الحالة مفعل /غير مفعل',
  sponsor: 'التأمينات الاجتماعية',
  select_your_group: 'اختر مجموعة',
  subscription_start_date: 'بداية الإشتراك :  <b>{{ date }}</b>',
  start_date_of_subscription: 'بداية الإشتراك <b>{{ date }}</b>',
  subscription_end_date: 'نهاية الإشتراك  : <b>{{ date }}</b>',
  end_date_of_subscription: 'نهاية الإشتراك <b>{{ date }}</b>',
  sync_accounts_with_xero: 'ربط الحسابات مع زيرو',
  sync_trackings_with_xero: 'زامن مراكز التتبع والربط مع زيرو',
  sync_accounts_with_xero_please: 'زامن الحسابات مع زيرو من فضلك',
  sync_trackings_with_xero_please: 'زامن مراكز التتبع والربط مع زيرو من فضلك',
  section: 'القسم',
  save_settings: 'حفظ الإعدادات',
  show_decimal_points: 'إظهار الفواصل العشرية؟',
  shift_has_been_deleted: 'تم حذف الدوام بنجاح.',
  select_your_working_days: 'اختر ايام الدوام',
  setup_your_shift: 'إعداد الدوام',
  shift_exit_time: ' وقت نهاية الدوام',
  show_employees: 'عرض الموظفين',
  shift_note:
    '"الدوامات التي تنتهي عند أو بعد الساعة التاسعة مساءاً أو الدوامات التي تبدأ ضمن هذا النطاق: 12ِ صباحا - 1:30 صباحاً"',
  shifts__working_hours: 'الدوامات وفترات العمل',
  show_xero_bill: 'عرض فاتورة زيرو',
  is_shortage_deduction_applicable: 'تطبيق خصم الخروج المبكر في مسير الرواتب؟',
  single_overtime: '(1.5x) عادي',
  single_overtime_description:
    'طريقة إحتساب الأوفر تايم: الساعة الأولى تُدفع من كامل الراتب, ونصف ساعة تُدفع من الراتب الأساسي',
  single_select_list: 'اختيار من القائمة',
  suspend_selected_employee: 'ايقاف راتب {{name}}',
  emp_name_will_not_receive_this_month_payroll: '{{name}} لن يستلم راتب الشهر الحالي',
  serial_number: 'الرقم التسلسلي',
  brand_name: 'اسم العلامة التجارية',
  sync_data: 'مزامنة البيانات',
  setup_your_policy: 'إعداد سياستك',
  subscription_status: 'حالة الإشتراك',
  setup_the_policy: 'إعداد السياسة',
  send_to_bank: 'إرسال الى البنك',
  study_description:
    'إجازة أداء الامتحانات: وتكون بعدد أيام الإمتحانات الفعلية، وتتم وفقًا لشروط مُحددة أوضحتها المادة 155. (مدفوعة الأجر بشروط: لا تكون عن سنة معادة - والحصول على موافقة المنشأة للانتساب)',
  sick_description: 'الإجازة المرضية: ومدتها 120 يومًا سنويًا ابتدءًا من تاريخ أول إجازة مرضية',
  suspended_employees: 'الموظفين الموقوفين من مسير الرواتب',
  special_cases_of_leaves: 'إجازات الحالات الخاصة',
  supported_banks: 'البنوك المدعومة',
  submission_date: 'تاريخ الإرسال',
  selected_bank_account_mudad: 'سيتم صرف الرواتب من البنك المحدد',
  set_as_default: 'تعيين كافتراضي',
  show_shift_name: 'إظهار اسم الفترة',
  search_shifts: 'البحث عن الدوامات',
  search_and_select_employees: 'بحث واختيار الموظفين',
  Some_selected_has_conflict: 'يعاني بعض الموظفين المختارين من تضارب مع هذا الدوام',
  save_this_shift: 'احفظ هذا الدوام كقالب',
  save_draft: 'حفظ المسودة',
  save_publish: 'حفظ ونشر',
  st_shift: '{{count}}st shift:ar',
  search_shift_templates: 'البحث في قوالب الفترات',
  shift_combo_name: 'اسم مجموعة الدوام',
  search_shift_presets: 'الفتره المعرفة أو وقت البدء',
  shift_templates: 'قوالب الدوام',
  search_fixed_shift_templates: 'البحث في الدوامات الثابتة',
  set_up_desired_weekly:
    'قم بإعداد التشكيل الأسبوعي المطلوب للدوامات وأيام الراحة. يمكنك تعيين ما يصل إلى دوامين في اليوم.',
  select_employees_to_assign: 'حدد الموظفين الذين تريد اسناد هذا الدوام الثابت لهم.',
  search_employees: 'البحث عن الموظفين',
  select_to_change_reflect:
    'حدد هذا لكي تنعكس التغييرات على السجلات السابقة. سيؤدي هذا إلى إعادة حساب سجلات الحضور للمتضررين  موظفين ابتداء من التاريخ المحدد.',
  select_time_effect: 'الرجاء تحديد الوقت الذي تريد أن يصبح فيه ساري المفعول',
  sort_by: 'الترتيب حسب',
  supported_formats_is_xls_xlsx_csv: 'ضيغةالملف المدعومة: .xls .xlsx .csv',
  session_timeout: 'تسجيل خروج تلقائي',
  automatic_upload_file_to_wps: 'الربط التلقائي مع نظام حماية الأجور',
  salaries_transfer_with_one_clicks: 'دفع رواتب الموظفين بضغطة زر',
  session_timeout_note: 'هل أنت متأكد من تطبيق تسجيل خروج تلقائي في الخيارات المحددة؟',
  subscription: 'الإشتراك',
  subscription_detail: 'تفاصيل الإشتراك',
  sort_by_name: 'ترتيب بالاسم',
  smart_simple_online_accounting_software_for_small_business:
    'برنامج محاسبي سحابي ذكي وبسيط, مناسب للشركات الصغيرةتفعيل الربط يمكنك من مزامنة كافة معاملات مسير الرواتب تلقائيًا مع Quickbooks عند الإنتهاء من تحضير مسير الرواتب',
  system_will_record_as_clock_in:
    'في حال التفعيل، سيتم احتساب الساعة  <span>{{time}}</span> كتوقيت الدخول تلقائياً',
  system_will_record_as_clock_out:
    'في حال التفعيل، سيتم احتساب الساعة  <span>{{time}}</span> كتوقيت خروج تلقائياً',
  shift_presets: 'الفترات المعرفة',
  sheet_approval: 'إعتماد الكشوفات',
  search_users: 'البحث عن مستخدمين',
  select_manager: 'اختيار المدير',
  sub_departments: 'الأقسام',
  settings_file_based: 'ملفات الربط',
  settings_company_documents: 'مستندات المنشأة',
  settings_leave_types: 'أنواع الإجازات',
  status_will_update_after_minutes:
    'تحديث الحالة يكون بعد ٣٠ دقيقة على الأقل من إرسال مسير الرواتب للبنك',
  search_open_api: 'البحث',
  select_atleast_one: 'اختر واحد على الأقل من APIs',
  step_one: '1. انتقل إلى "الإعدادات -> معلومات الدفع" ',
  step_one_desc: 'ستجد الفواتير الخاصة بك',
  step_two: '2.  اضغط على "تحميل الفاتورة" للفواتير المحددة',
  step_two_desc: 'قد تحتوي الفاتورة على أكثر من اشتراك واحد',
  step_three: '3.  ستجد بيانات {{app_name}} البنكية',
  step_three_dec: 'ستجد تفاصيل الايبان في الجزء الأسفل من الفاتورة',
  step_four: '4. دفع فواتيرك (تحويل بنكي)',
  step_four_desc: 'قم بتأكيد معاملتك واحتفظ بمعرف المعاملة الخاص بك',
  subscription_fees: 'رسوم الاشتراك',
  subscriptionـstart: 'سيبدأ الاشتراك بعد إكمال عملية التسجيل',
  subscriptionـstart_description:
    'لتجنب تعطيل اشتراك مدد يتعين عليك دفع الفاتورة في غضون الثلاثين يومًا القادمة،',
  subscriptionـstart_description_link:
    ' وسوف نرسل لك فاتورة تحتوي على التفاصيل البنكية لسداد الاشتراك.',
  subscription_value: '{{price}} ريال سعودي سنويا',
  saudi_currency: '񥀠',
  session_timeout_allowed_duration: 'المدة المسموحة',
  cr_see_less: 'عرض أقل',
  no_sub_organizations: 'لا يوجد سجلات فرعية',
  see_sub_organization: 'عرض السجلات الفرعية ({{sub_length}})',
  sub_organization: 'منشأة فرعية',
  sub_org: 'مؤسسة فرعية',
  select_the_main_organization: 'حدد المنشأة الرئيسية للفرع',
  search_open_shifts: 'البحث في الدوامات المفتوحة',
  should_work: 'عليهم العمل لـ:',
  select_clocking_method: 'اختر طريقة لحضور الموظفين حتى تظهر المعلوما التوضيحية عنه',
  specific_hours_within_day: 'خلال ساعات محددة خلال اليوم',
  save_and_create: 'حفظ وانشاء',
  save_and_update: 'حفظ وتعديل',
  subscription_cancellation: 'إلغاء الاشتراك',
  subscription_cancellation_description:
    'يمكنك إلغاء اشتراكك إذا لم تعد ترغب في استخدام خدمة جسر المميزة. خلال عملية الإلغاء يوجد تعليمات واضحة. ويمكنك دائمًا العودة وتنشيط الاشتراك.',
  select_if_employees_should_fulfill_certain_terms_before_submitting_requests:
    'فعل هذا الخيار في حالة حاجة الموظفين المسند لهم هذه السياسة لأتمام شروط معينة قبل تقديم طلب عمل إضافي',
  select_location: 'اختيار الموقع',
  switch_to: 'Switch to {{organization}}:AR',
  select_role: 'اختيار صلاحية',

  // T
  types_requests: 'الانواع',
  the_number_of_minimum_days:
    'الحد الأدنى لعدد الايام التي يستطيع الموظف التقديم على مخالصة إجازة سنوية مدفوعة مقدما',
  try_our_new_settings_interface: '👀 تجربة واجهة الإعدادات الجديدة',
  the_apps_will_be_available_soon: 'سيتم إتاحة التطبيقات قريبًا',
  this_sub_department_have_already_employees:
    'هذا القسم يحتوى على موظفين ،قم أولاً بتعيين هؤلاء الموظفين ثم احذفهم',
  this_department_have_already_employees:
    'لايمكنك حذف هذا الادارة بعد حذف جميع الأقسام المضافة إليه',
  this_subdepartment_is_empty: 'هل أنت متأكد أنك تريد حذف؟',
  this_department_is_empty: 'هل أنت متأكد أنك تريد حذف؟',
  this_country_is_empty: 'This Country is empty, if you delete it:AR',
  this_area_is_empty: 'This Area is empty, if you delete it:AR',
  this_location_is_empty: 'This Location is empty, if you delete it:AR',
  this_country_have_already_employees:
    'هذي الدولة تحتوى على موظفين ،قم أولاً بتعيين هؤلاء الموظفين ثم احذفهم',
  this_area_have_already_employees:
    'هذي المنطقة تحتوي على موظفين ،قم أولاً بتعيين هؤلاء الموظفين ثم احذفهم',
  this_location_have_already_employees:
    'هذا الموقع يحتوى على موظفين ، ،قم أولاً بتعيين هؤلاء الموظفين ثم احذفهم',
  task_name: 'اسم المهمة',
  tab_name: 'التصنيف',
  travel_days: 'أيام السفر',
  transportation_per_diem: 'مبلغ النقل <p>لكل يوم</p>',
  tracking_mapping: 'الربط والتتبع',
  template_name: 'اسم النموذج',
  template_name_ar: 'أسم النمودج بالعربي',
  This_device_is_not_linked_to_alocation: ' هذا الجهاز غير مرتبط بمنطقة',
  to_whom_the_task_of_payment_should_go_to: 'لمن يجب ان تسند مهمة الدفع:',
  ticket_payment_task: 'مهمة دفع رسوم التذكرة',
  to_automate_your_bills_for_alimna_bank_clients: 'لأتمتتة عملية الدفع لعملاء بنك الإنماء',
  to_whom_the_task_of_issuing_the_ticket_should_go_to: 'لمن يجب ان تسند مهمة استخراج التذكرة',
  transaction_type: 'نوع العملية',
  to_whom_the_task_of_issuing_exit_reentry_should_go_to:
    'لمن يجب ان تسند مهمة اصدار الخروج والعودة',
  tasks: 'المهام',
  tracking_id: 'معرف التتبع',
  tasks_management: 'إدارة المهام',
  time_sheet_reflection_payroll: 'تأثير الحضور والإنصراف في مسير الرواتب',
  there_error_link_description: 'هناك خطأ بالرابط. من فضلك تفقد بريد الإلكتروني',
  time_sheet_reflection: 'تأثير الحضور والإنصراف',
  tip_cut_off_date:
    'يوم الاستقطاع هو اليوم الذي يجب أن تكون فيه جميع المعاملات قد تم اعتمادها لكي يتم احتسابهافي مسير الرواتب. ضبط يوم الاستقطاع يساعدك على إحتساب مسير الرواتب بطريقة مثالية',
  tip_fund_date:
    'تاريخ صرف المرتبات هو التاريخ الذي يتلقى فيه الموظف أجرًا من صاحب العمل. تاريخ إيداع المبالغ في حسابات الموظفين',
  tip_decimal_points_included:
    'في حال تفعيل الخيار سيقوم النظام بتدوير الأرقام بعد الفاصلة العشرية لمبلغ صافي الراتب للموظفين',
  tip_time_sheet_reflection_payroll: 'يمكنك التحكم بتأثيرات الحضور والإنصراف من هنا ',
  toggling_between_location:
    'تغيير الخيار مابين (الموقع) و (القسم) سوف يؤدي إلى حذف السياسات المتواجدة حالياً',
  type_workflow_name: 'أدخل اسم الموافقة',
  total_headcount: 'عدد الموظفين',
  total_payments: 'إجمالي الدفعات',
  total_number_of_emp_including_sub_dep: 'العدد الإجمالي شاملاً الأقسام الفرعية',
  ticket_provision_policy: 'سياسة مخصصات التذاكر',
  ticket_cost: 'تكلفة التذكرة',
  tickets_configuration: 'اعدادات التذاكر',
  ticket_paid: 'تدفع التذكرة',
  terms_and_conditions: 'الأحكام و الشروط',
  template_name_en: 'اسم النموذج بالإنجليزي',
  two_factor_auth: 'رمز التحقق',
  two_factor_auth_for_login: 'المصادقة الثنائية لتسجيل الدخول',
  two_factor_auth_save_note: 'هل أنت متأكد من تطبيق المصادقة الثنائية في الخيارات المحددة؟',
  templates: 'القوالب',
  total_duration: 'المدة الإجمالية',
  Total_working_duartion: 'إجمالي المدة المجدولة',
  total_unpaid_break: 'مجموع استراحة غير مدفوعة',
  template_name_in_english: 'اسم القالب بالانجليزي',
  template_name_in_arabic: 'اسم القالب بالعربية (اختياري)',
  template_name_in_english_fixed: 'اسم الدوام (انجليزي)',
  template_name_in_arabic_fixed: 'اسم الدوام (عربي)',
  tow_nd_shift: 'الدوام الثاني',
  this_place_where_you_can_manage_your_variable_Pay_types:
    'يمكنك من خلال هذه الشاشة التحكم بأنواع المدفوعات الخاصة بك',
  the_value_cannot_be_less_than_one: 'لا يمكن أن تكون القيمة أقل من واحد',
  the_value_cannot_be_more_than_24: 'لا يمكن أن تكون القيمة أكثر من 24',
  time_session_out_configuration: 'تسجيل الخروج التلقائي',
  terminate_employees: 'إنهاء خدمات الموظفين',
  this_cost_item_should_be_mapped: 'اربط {{ item }} نت سويت مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_quickbooks:
    'اربط {{ item }} كويك بوكس مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_microsoft:
    'اربط {{ item }} مايروسوفت مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_xero: 'اربط {{ item }} زيرو مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_qoyod: 'اربط {{ item }} قيود مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_odoo: 'اربط {{ item }} اودو مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_microsoft_finops:
    'اربط {{ item }} MS Dynamics Finance & Operation مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_oracle_fusion:
    'اربط {{ item }} Oracle Fusion مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_zoho_books:
    'اربط {{ item }} Zoho Books مع ما يقابلها في {{app_name}}',
  this_cost_item_should_be_mapped_sap:
    'اربط {{ item }} Sap Business One مع ما يقابلها في {{app_name}}',
  time_session_out_configuration_description:
    'تسجيل الخروج تلقائياً بعد مرور مدة دون نشاط، يمكنك تحديد المدة المسموحة دون تسجيل الخروج.',
  the_cloud_business_software_suite_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (نت سويت) مع {{app}} مباشرةً',
  microsoft_card_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (مايكروسوفت) مع {{app}} مباشرةً',
  microsoft_finops_card_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (MS Dynamics Finance & Operation) مع {{app_name}} مباشرةً',
  sap_business_card_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (SAP Business One) مع {{app_name}} مباشرةً',
  zoho_books_card_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (Zoho books) مع {{app_name}} مباشرةً',
  xero_description: 'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (زيرو) مع {{app}} مباشرةً',
  odoo_description: 'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (اودو) مع {{app}} مباشرةً',
  quick_books_description:
    'إرسال وتصدير القيود المحاسبية من خلال ربط حساب (كويك بوكس) مع {{app}} مباشرةً',
  these_are_the_permissions: 'هنا ملخص بالصلاحيات الممنوحة للمستخدمين المضافين لهذا الدور',
  this_role_only_has_basic_permissions:
    'هذا الدور لا يحتوي إلا على الصلاحيات الأساسية الممنوحة لجميع الموظفين على معلوماتهم الشخصية فقط. ',
  these_are_the_basic_permissions: 'هذه الصلاحيات الاساسية الممنوحة تلقائيا لكل المستخدمين',
  // these_are_the_permissions_user:
  // 'هنا ملخص بالصلاحيات الممنوحة ل {{name}} قد تتضمن صلاحيات من أدوار مختفلة',
  these_are_the_permissions_user:
    'هنا ملخص بالصلاحيات الممنوحة لـ {{name}}، وقد تحتوي على صلاحيات من أدوار مختلفة.',
  department_has_subdepartments: 'هذا القسم يحتوي على اقسام فرعيه',
  team: 'للفريق',
  the_shift_looks_too_long: 'مدة الدوام أطول من اللازم',
  the_first_punch_within_this_window:
    '<span>أول بصمة</span> في إطار النافذة الزمنية  ستسجل <span>بصمة حضور</span> تلقائياً',
  the_last_punch_within_this_window:
    '<span>آخر بصمة</span> في إطار النافذة الزمنية  ستسجل <span>بصمة انصراف</span> تلقائياً',
  time_overlapping: 'تعارض في الوقت المدخل',
  text: 'نص',
  department_has_employees: 'هذا القسم يحتوي على موظفين',
  there_is_no_open_shifts: 'لم يتم اضافة دوامات مفتوحة بعد',
  timeframe_anytime_between: 'اي وقت خلال الفترة من {{startTime}} - {{endTime}}  ',
  timeframe: 'خلال اطار زمني',
  the_first_punch_and_the_last_punch_will_be_recorded:
    'سوف يتم احتساب وقت العمل بناءًا على اول بصمة واخر بصمة',
  take_me_back_to_my_account: 'العودة الى الحساب',
  title_for_congratulation: 'نحن سعداء لاستمرار شراكتنا! 🎉',
  title_for_SubscriptionCanceled: 'بحلول نهاية فترة الاشتراك الحالية، سيتم إلغاء اشتراكك',
  title_fot_you_can_return:
    'إلغاء اشتراكك أمر محزن للغاية . ولكن يمكنك العودة إلى {{app}} في أي وقت تريد.',
  the_current_policy_allows: 'السياسة الحالية تسمح بمضاعفة حتى x{{overTimePolicy}}',
  this_employee_already_added: 'تمت إضافة هذا الموظف بنفس الصلاحية مسبقاً',
  to_notify_selected_employees_by_email: 'أرسل التنبيهات للبريد الألكتروني لموظفين تقوم بأختيارهم',
  to_notify_selected_admins_by_email: 'أرسل التنبيهات للبريد الألكتروني لمشرفين تقوم بأختيارهم',
  // U
  unit: 'وحدة',
  update_department: 'تحديث القسم',
  update: 'تحديث',
  unlimited: 'غير محدود',
  update_employees_wages: 'تحديث أجور الموظفين',
  update_business_policy_headding: 'هل أنت متأكد؟',
  update_employees_information: 'تحديث معلومات الموظفين',
  update_business_policy_description:
    'هذا التغيير في سياسة مهمة العمل سوف يؤثر في جميع الطلبات المعلقة التي تتعلق بنفس السياسة',
  updated_records: 'الحقول المعدلة',
  upload: 'رفع',
  update_device: 'تحديث الجهاز',
  unselect_all: 'إلغاء الاختيار عن الكل',
  upload_new_document: 'رفع مستند جديد',
  upload_file_xls_format_hnit:
    'الرجاء تحميل نفس تنسيق ملف {{app_name}} لتجنب أي اخطاء أثناء رفع البيانات.',
  unpaid_calculated_based_on: 'يتم إحتساب الإجازة غير المدفوعة على أساس:',
  upto_date_balance: 'الرصيد المستحق الى اليوم',
  upto_date_warning:
    'التغيير في الرصيد المستحق الى اليوم سيغير رصيد السنة السابقة. هل تريد المتابعة؟',
  uptodate_balance_warning:
    'التعديل على قيمة الادخال اليدوي سيؤثر على الرصيد المستحق للموظف الى هذا اليوم',
  unsubscribe: 'unsubscribe:AR',
  unsubscribe_weekly_email: 'إيقاف التحديثات الأسبوعية',
  unsubscribe_weekly_email_description:
    'إذا قمت بتأكيد إلغاء الاشتراك ، فلن تتمكن من تلقي البريد الإلكتروني الأسبوعي المرسل من {{app_name}}.',
  users_on_this_device: '{{count}} موظف مجلس في هده الجهاز',
  users_their_fingerprint_is_not_taken: '{{count}} موظف لم يتم تسجيل بصمتهم',
  unpaid_description:
    'إجازة دون أجر: ولا تؤدي إلى توقف عقد العمل إذا كانت عشرين يومًا فأقل (متصل أو منفصل)، ويتوقف عقد العمل بمنافعه إن زادت عن عشرين يومًا.',
  unchecked_benefits:
    'بدل تم ازالته, هذا قد يؤدي إلى إزالة هذه البدلات من موظفو اجير التابعون لشركة تأجير العمالة',
  you_have_geo_locations: 'لديك  {{count}} مواقع جغرافية',
  unpaid_break_deducted:
    'استراحة لمدة<b>{{break_duration}}</b> غير مدفوعة الأجر تخصم من المدة الإجمالية',
  unpaid_break: 'استراحة غير مدفوعة الأجر',
  update_combo: 'تعديل كمبو',
  update_template: 'تعديل القالب',
  updated_shifts: 'دوامات محدثة لـ <span> {{days}} <span>',
  unassign_employees: 'أنت على وشك إلغاء تعيين {{count}} موظفين',
  unavailablility: 'عدم التوفر',
  unpublish: 'إلغاء النشر',
  unpublished: 'غير منشورة',
  unscheduled: 'غير مجدول',
  untill: 'حتى',
  netsuite_accounting: 'الربط مع نت سويت',
  quickbooks_accounting: 'الربط مع كويك بوكس',
  xero_accounting: 'الربط مع زيرو',
  qoyod_accounting: 'الربط مع قيود',
  offline_journal: 'الربط عبر الملف',
  unassigned: 'غير معين',
  user: 'المستخدم',
  users: 'المستخدم',
  users_alt: 'المستخدمين',
  user_permissions: 'صلاحيات المستخدمين',
  upload_photo: 'رفع صورة',
  update_business_unit: 'تعديل وحدة عمل جديدة',
  update_commercial_registration: 'تعديل السجل التجاري',
  update_job_title: 'تعديل المسمى الوظيفي',
  update_outsourcing_provider: 'تعديل شركة تأجير عمالة',
  update_document_type: ' تعديل نوع الملف',
  update_grade: 'تحديث المستوى الوظيفي',
  update_in_house: 'Update In-House AR',
  update_billing_details: 'تحديث معلومات الدفع',
  update_parent_department: 'تعديل الادارة',
  update_custom_field: 'تحديث',
  update_holiday: 'تعديل إجازة عيد',
  update_loan_type: 'تحديث نوع السلفة',
  update_location: 'تحديث الموقع',
  uploading_file: 'تحميل الملف',
  update_labor_law_leave: 'تعديل اجازه مكتب العمل',
  unified_mol_no: 'رقم المنشأة الموحد',
  update_open_shift: 'تعديل دوام مفتوح',
  // V
  values: 'List options',
  value_per_hour_per_weekdays: 'قيمة الساعة خلال أيام الأسبوع',
  value_per_hours_of_non_weekdays: 'القيمة بالساعة خلال أيام نهاية الأسبوع',
  vacation_salary_calculated_based_on: 'يتم إحتساب الإجازة على أساس:',
  vacation_calculated_based_on: 'يتم إحتساب الإجازة على أساس',
  vacation_settlement_task: 'مهمة دفع مخالصة الإجازة <span>({{message}})</span>',
  payroll_task: 'مهمة دفع مسير الرواتب  <span>({{message}})</span>',
  final_settlement_task: 'مهمة دفع المخالصة النهائية <span>({{message}})</span>',
  vacation_settlement_processing_task: 'مهمة معالجة مخالصة الإجازة',
  vacation_settlement_payment_processing: 'تأكيد سجل الحضور والإنصراف مطلوب لمخالصة الإجازة',
  view: 'عرض',
  vacation_salary: 'راتب الإجازة',
  verify_employees: 'إضافة الموقّعين',
  verify: 'تحقق',
  voided: 'ملغية',
  verification_failed: 'فشل التحقق',
  verified_successfully: 'تم التحقق بنجاح',
  verified: 'تم التحقق',
  variables: 'المتغيرات',
  view_by: 'العرض حسب',
  vat_number: 'رقم تسجيل ضريبة القيمة المضافة',
  view_employee_list: 'عرض قائمة الموظفين',
  vacation_salary_calc_note:
    'يمكنك اختيار طريقة احتساب راتب الإجازة السنوية إما على أساس الراتب الأساسي, أو على أساس مجموع الراتب, أو على أساس الراتب الأساسي ومجموعة من البدلات التي يمكن تحديدها',
  vacation_settlement_payment: 'مخالصة الإجازة',
  v2_documents: 'الملفات',
  view_open_api_key: 'عرض مفتاح API',
  visit_billing_history: 'الذهاب الى تفاصيل الدفع',
  view_org_chart: 'عرض مخطط الشركة',

  // W
  wait_5_min: 'انتظر 5 دقائق أخرى',
  weekly_offs: 'أيام العطلة',
  with_expiry_date: 'تضمين تاريخ انتهاء الصلاحية',
  without_expiry_date: 'لايتضمن تاريخ أنتهاء الصلاحية',
  working_hours_and_shifts: 'الدوامات',
  working_days: 'أيام العمل',
  working_hours: 'ساعات العمل',
  website: 'الموقع الإلكتروني',
  weekly_email_updates: 'بريد التحديثات الأسبوعية',
  want_to_apply: 'هل ترغب في تطبيق هذه السياسة على جميع موظفين الشركة؟',
  workflow_note: 'تنبيه هناك {{unassigned_users}} موظف لم يتم تعيينه على أي سياسة',
  workflow: 'سلسلة الموافقات',
  workflows: 'سلسلة الموافقات',
  WORKFLOW__APPROVALS: 'الطلبات والموافقات',
  why_single_overtime: 'لماذا الاوفر تايم ؟',
  why_double_overtime: 'لماذا الاوفر تايم المضاعف ؟',
  We_are_working_with_banks_note:
    'نعمل على الربط مع البنوك مباشرة لتسهيل واتمتة تحويل الرواتب. قريبا سيتم توفير خاصية تحويل الرواتب مباشرة مع بنك الإنماء.',
  workflow_steps_warning:
    'تغيير اعدادات سلسلة الموافقات سوف يعيد كل الطلبات المعلقة للموافقة عليها بحسب الاعدادات الجديدة, ويجب عليك الموافقة مرة أخرى على الطلبات المعلقة',
  widow_description:
    'إجازة العدّة (للمرأة العاملة المتوفى زوجها): إجازة عدة 4 أشهر و10 أيام مدفوعة الأجر بالكامل للمرأة المسلمة، 15 يومًا لغير المسلمة.',
  what_is_the_maximum_loan: 'ماهو أقصى مبلغ يمكن للموظف طلبه كسلفة؟',
  what_is_the_maximum_loan_tool_tip:
    'حدد عدد الرواتب التي يمكن أن يتقدم الموظف بطلب للحصول عليها كسلفة',
  working_hours_mins: 'ساعات العمل: <span>{{totalWorkingHours}}</span>',
  total_scheduled_duration: 'إجمالي المدة المجدولة : <span>{{working_hours}}</ span>',
  weekly_shift_details: 'جدول الأسبوع',
  weeks: 'أسابيع',
  weekly: 'أسبوعي',
  workflow_payroll_message: 'فقط الاشخاص الذين لديهم صلاحية عرض كشف الرواتب',
  workflow_vacation_message: 'فقط الاشخاص الذين لديهم صلاحية عرض مخالصة الإجازة',
  workflow_final_message: 'فقط الاشخاص الذين لديهم صلاحية عرض مخالصة نهاية الخدمة',
  workflow_name: 'اسم سلسلة الموافقات',
  will_generate_apiKey_onClick: 'سوف يتم إنشاء المفتاح بعد الضغط على "‘إنشاء"',
  within_timeframe: 'خلال اطار زمني',
  when_this_feature_is_activated:
    'عند تفعيل هذه الخاصية، سيكون بإمكان الموظفين من تقديم طلبات إجازة  نصف اليوم، بحيث تخصم من رصيد إجازاتهم السنوي',
  we_would_like_to_hear_your_feedback: 'نود أن نسمع تعليقاتك',
  we_value_our_partnership: 'نحن نقدر شراكتنا',
  we_have_received_your_request_successfully_description:
    'لقد تلقينا طلبك بنجاح. سيتم التواصل معك قريبا .',
  worked_all_the_required_hours_of_the_assigned_shift:
    'عمل كل الساعات المطلوبة في الدوام المسند إليهم',

  // X
  xero: 'زيرو',
  qoyod: 'قيود',
  xero_account_codes: 'رموز حساب زيرو',
  xero_export_option: 'تصدير فواتير الموظفين على أساس',
  xero_is_a_beautiful_easy_to_use_online_accounting_description:
    'Xero هو برنامج محاسبي سحابي جميل وسهل الاستخدام للشركات الصغيرة ومستشاريها.تفعيل الربط يمكنك من مزامنة كافة معاملات مسير الرواتب تلقائيًا مع Xero عند الإنتهاء من تحضير مسير الرواتب',
  // Y
  You_cant_delete: 'لا تستطيع الحذف',
  confirm_mudad_subscription_dec:
    'انت على وشك انشاء اشتراك جديد في خدمة مدد لدفع الرواتب، يرجى التأكيد من خلال إدخال المعلومات التالية',
  yes_cancel_payment: 'نعم، تأكيد الإلغاء',
  you_have_not_any_geolocation_yet: 'لم تقم بتعريف موقع جغرافي',
  you_dont_have_commercial_registration: 'لا يوجد سجلات تجارية , برجاء إضافة السجلات التجارية',
  you_dont_have_commercial_registration_and_bank:
    'برجاء أضافة السجلات التجارية اولاَ, لتتمكن من أضافة الحسابات البنكية الخاصة بكل سجل',
  you_dont_have_bank_for_this_commercial_registration: 'لا توجد حسابات بنكية ل {{name}}',
  you_always_revert_back_to_your_previous_notes:
    'بإمكانك الرجوع الى الاعدادات السابقة من صفحة طريقة الدفع',
  yes_im_sure: 'نعم, تأكيد',
  you_are_about_to_change_the_payroll_bank: 'أنت بصدد تغيير بنك الرواتب',
  you_have_some_employees_their_salary_bank_not_specified:
    'لديك بعض الموظفين غير مرتبطين بأي سجل تجاري',
  you_need_add_shift: 'أضف فترة ثانية لإنشاء قالب دوام يوم',
  you_can_assign_shifts: 'يمكنك تعيين ما يصل إلى دوامين في اليوم حاليًا',
  you_can_assign_up_to_one_shift: 'يمكنك تعيين ما يصل إلى دوام واحد في اليوم حاليًا',
  yellow: 'أصفر',
  your_plan_is: 'خطة الاشتراك : <b>{{ plan }}</b>',
  your_commercial_registration: 'السجلات التجارية',
  you_are_not_authorized_send_payment_transfer_bank: 'لست الشخص المحول بتحويل الرواتب الى البنك',
  you_can_not_change_company_name: 'لا يمكنك تغير أسم الشركة',
  your_current_subscription_will_end_on: 'سينتهي اشتراكك الحالي في {{time}}',
  yo_wont_be_able_to_track_shifts_and_schedules:
    'لن تتمكن من تتبع جدولة الدوامات ومتابعة حضور الموظفين',
  you_wont_be_able_to_access_your_payroll_history: 'لن تتمكن من الوصول إلى سجل الرواتب الخاص بك',
  you_will_lose_access_to_all_your_employees_data:
    'سوف تفقد إمكانية الوصول إلى جميع بيانات موظفيك على {{app_name}}',
  you_are_going_to_activate_mudad:
    'ستقوم بتفعيل مدد لـ *{{crName}} * لسنة أخرى، إذا قمت بتأكيد كشوف المرتبات الخاصة بك، فسيتم تطبيق التغييرات في الشهر التالي، هل تريد التجديد؟',
  you_will_need_to_define_off_days: 'سوف تحتاج لتعريف ايام الراحة اثناء اسناد الدوامات للموظفين',
  // Z
  ZK_techo_attendance_integration: 'خاصية ربط جهاز البصمة ZKT',
  zk_techo_attendance_integration: 'خاصية ربط جهاز البصمة ZKT',
  zk_attendance_keys: 'مفاتيح اجهزة البصمة',
  zk_attendance_login: 'ZK attendance login',
  'Annual leave Entitlement': 'احقية المغادرة السنوي',
  'Days counting': 'أيام العد',
  'Carry forward': 'سياسة ترحيل الرصيد',
  should_new_hire_have:
    'هل ترغب باستثناء الموظفين الملتحقين خلال السنة الحالية من سياسة الترحيل المحدودة؟',
  Yes: 'نعم',
  No: 'لا',
  'Why this feture is so important for me ?': 'لماذا هذه الميزة مهمة للغاية بالنسبة لي؟',
  'Read full article': 'قراءة المادة كاملة',
  'Carry all': 'ترحيل كل الرصيد',
  'No carry': 'لايوجد ترحيل',
  'Carry limted': 'ترحيل رصيد محدد',
  'Add Executor For the Following Task': 'أضف منفذ للمهمة التالية',

  add_new_mangaing_person: 'إضافة مدير جديد',
  fingerprint_devices: 'أجهزة البصمة',
  devices_installed_in_total: 'لديك {{count}} جهاز مثبت.',
  add_admins: 'إضافة مدراء',
  add_location: 'اضافة الموقع',
  this_device_doesnt_have_any_admins: 'لايوجد اي مدراء لهذا الجهاز',
  add_new_manager: 'اضافة مدير جديد',
  managers_count: 'مدراء الأجهزة • {{count}}',
  and_more_with_count: 'و {{count}} آخرين',
  digits_24: '24 رقم',
  since: 'منذُ',
  refresh_status: 'تحديث الحاله',
  refresh_device_status: 'تحديث حاله الجهاز',
  updating_status: 'تحديث الحاله',
  refreshed_at: 'اخر تحديث',
  refresh: 'تحديث',
  monthly_payroll_journal_entry: 'قيد الرواتب الشهرية',
  monthly_payroll_journal: 'قيد الرواتب الشهري',
  gosi_odoo_journal: 'قيد مستحقات التأمينات الاجتماعية',
  Vacation_End_of_Service_Accrual: 'مخصصات الإجازة ومكافأة نهاية الخدمة',
  vacation_end_of_Service_accruals_journal_entry: 'قيد مخصصات الإجازة ومكافأة نهاية الخدمة',
  send_journal_entry_to_netSuite: 'ارسال القيد المحاسبي الى نت سويت',
  send_journal_entry_to_xero: 'ارسال القيد المحاسبي الى زيرو',
  send_journal_entry_to_qoyod: 'ارسال القيد المحاسبي الى قيود',
  send_journal_entry_to_oracle_fusion: 'ارسال القيد المحاسبي الى اوراكل',
  send_journal_entry_to_ms: 'ارسال القيد المحاسبي الى مايكروسوفت',
  send_journal_entry_to_odoo: 'ارسال القيد المحاسبي الى اودو',
  send_journal_entry_to_finops: 'ارسال القيد المحاسبي الى MS Dynamics Finance & Operation',
  send_journal_entry_to_sap: 'ارسال القيد المحاسبي الى Sap business One',
  send_journal_entry_to_quickBooks: 'ارسال القيد المحاسبي الى كويك بوكس',
  send_journal_entry_to_ms_finops: 'ارسال القيد المحاسبي الى MS Dynamics Finance & Operation',
  send_journal_entry_to_zoho_books: 'ارسال القيد المحاسبي الى Zoho Books',
  gosi_accrual_journal_entry: 'قيد مستحقات التأمينات الإجتماعية',
  Ajeer_Journal_odoo: 'قيد رسوم العمالة المؤجرة',
  Out_Of_Payroll_Journal: 'عمليات خارج الرواتب',
  Vacation_Settlement_Journal: 'قيد مخالصة الإجازة',
  Final_Settlement_Journal: 'قيد المخالصة النهائية',
  you_cannot_delete_this_record: 'لا يمكنك حذف هذا السجل',
  you_cannot_delete_this_record_sub_message:
    'السجل التجاري مرتبط بإشتراك في منصة مدد<br/>يوجد سجلات خاصة بعمليات دفع مرتبطة بهذا السجل<br/><br/>يمكنك أرشفة هذا السجل ولن يتم اتاحته للإستخدام',
  archive_registration: 'أرشفة سجل المنشأة',
  okay: 'حسناً',
  delete_registration_record: 'حذف سجل المنشأة',
  delete_registration_record_msg: 'ستحذف جميع المعلومات المرتبطة بهذ السجل ولن تتمكن من استرجاعها.',
  delete_record: 'حذف السجل',
  archive_registration_msg:
    'لن تتمكن من استخدام السجل بعد أرشفته، ولن يلغي ذلك اشتراك خدمة مدد للسجل.',
  move_employees_title: 'هناك {{count}} موظف مرتبط بـــ "{{name}}"',
  move_employees_delete_msg: 'يجب نقل الموظفين الى منشأة أخرى للتمكن من حذف هذا السجل',
  move_employees_archive_msg: 'يجب نقل الموظفين الى منشأة أخرى للتمكن من أرشفة هذا السجل',
  move_employees: 'نقل الموظفين',
  donnot_have_needed_permissions_toMove_employees: 'ليس لديك الصلاحيات اللازمه لنقل الموظفيين',
  archive_cr_with_subs_msg:
    'لا يمكنك أرشفة المنشأة الرئيسية بسبب وجود سجلات فرعية مرتبطة بها، يجب أرشفة السجلات الفرعية والمحاولة مرة أخرى',
  success_archive_title: 'أرشف السجل بنجاح',
  success_partial_employees_moved_title: 'تم نقل {{count1}} موظفين بنجاح',
  success_partial_employees_moved_msg:
    'لكن يوجد {{count2}} موظفين لم يتم نقلهم لعدم توفر الصلاحيات الخاصه بنقلهم لديك',
  delete_cr_with_subs_msg:
    'لا يمكنك حذف المنشأة الرئيسية بسبب وجود سجلات فرعية مرتبطة بها, يجب حذف السجلات الفرعية والمحاولة مرة أخرى',
  success_delete_registration_title: 'تم حذف المنشأة بنجاح',
  success_delete_registration_msg: 'تم حذف المنشأة بنجاح',
  archived_records: 'سجلات مؤرشفة',
}

export default Setting
