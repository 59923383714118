export const ODOO_SUBSIDIARIES_LOAD = 'ODOO_SUBSIDIARIES_LOAD'
export const ODOO_SUBSIDIARIES_LOAD_FULFILLED = 'ODOO_SUBSIDIARIES_LOAD_FULFILLED'
export const ODOO_SUBSIDIARIES_LOAD_REJECTED = 'ODOO_SUBSIDIARIES_LOAD_REJECTED'
export const ODOO_SUBSIDIARIES_LOAD_PENDING = 'ODOO_SUBSIDIARIES_LOAD_PENDING'

export const ODOO_MAPPED_SUBSIDIARIES = 'ODOO_MAPPED_SUBSIDIARIES'
export const ODOO_MAPPED_SUBSIDIARIES_FULFILLED = 'ODOO_MAPPED_SUBSIDIARIES_FULFILLED'
export const ODOO_MAPPED_SUBSIDIARIES_REJECTED = 'ODOO_MAPPED_SUBSIDIARIES_REJECTED'
export const ODOO_MAPPED_SUBSIDIARIES_PENDING = 'ODOO_MAPPED_SUBSIDIARIES_PENDING'

export const ODOO_SUBSIDIARY_CREATE = 'ODOO_SUBSIDIARY_CREATE'
export const ODOO_SUBSIDIARY_CREATE_FULFILLED = 'ODOO_SUBSIDIARY_CREATE_FULFILLED'
export const ODOO_SUBSIDIARY_CREATE_REJECTED = 'ODOO_SUBSIDIARY_CREATE_REJECTED'
export const ODOO_SUBSIDIARY_CREATE_PENDING = 'ODOO_SUBSIDIARY_CREATE_PENDING'

export const ODOO_SUBSIDIARY_DELETE = 'ODOO_SUBSIDIARY_DELETE'
export const ODOO_SUBSIDIARY_DELETE_FULFILLED = 'ODOO_SUBSIDIARY_DELETE_FULFILLED'
export const ODOO_SUBSIDIARY_DELETE_REJECTED = 'ODOO_SUBSIDIARY_DELETE_REJECTED'
export const ODOO_SUBSIDIARY_DELETE_PENDING = 'ODOO_SUBSIDIARY_DELETE_PENDING'

export const ODOO_SUBSIDIARY_UPDATE = 'ODOO_SUBSIDIARY_UPDATE'
export const ODOO_SUBSIDIARY_UPDATE_FULFILLED = 'ODOO_SUBSIDIARY_UPDATE_FULFILLED'
export const ODOO_SUBSIDIARY_UPDATE_REJECTED = 'ODOO_SUBSIDIARY_UPDATE_REJECTED'
export const ODOO_SUBSIDIARY_UPDATE_PENDING = 'ODOO_SUBSIDIARY_UPDATE_PENDING'

export const ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN = 'ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN'
export const ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED =
  'ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED = 'ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'
export const ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING = 'ODOO_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'

export const ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN = 'ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN'
export const ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED =
  'ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED'
export const ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED =
  'ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED'
export const ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING =
  'ODOO_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING'
