import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import { Action } from 'types/redux'
import { THolidaysData } from './type'
import * as actions from './actions'

export function onboardingHolidaysLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays')}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingHolidaysDefaultLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays/default_holidays')}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_DEFAULT_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingHolidaysDefaultUpdate(params: boolean): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays/set_defaults')}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_DEFAULT_UPDATE,
    payload: handleAPI(url, { follow_labor_law: params }, 'PUT'),
  }
}

export function createOnboardingHolidays(body: THolidaysData): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays')}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_CREATE,
    payload: handleAPI(url, {}, 'POST', { holiday: body }),
  }
}

export function UpdateOnboardingHolidays(body: THolidaysData): Action {
  const { id } = body
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays')}/${id}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { holiday: body }),
  }
}

export function deleteOnboardingHolidays(id: number): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('holidays')}/${id}`

  return {
    type: actions.ONBOARDING_HOLIDAYS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}
