import React from 'react'
import { Flex } from '@jisr-hr/ds'
import { useDispatch } from 'react-redux'
import {
  expenseClaimRequestCreate,
  expenseClaimRequestUpdate,
} from 'redux/actions/expenseClaimRequestActions'
import { omit } from 'lodash'
import JisrLoader from 'components/global/JisrLoader'
import { ExpenseClaimItemDetails, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'
import { getSizeFromKB } from '../../helper'

const ExpenseClaimRequest = () => {
  const dispatch = useDispatch()

  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()

  const { loading, request } = useRequestDetail()

  const empId = request?.employee?.id

  const onSubmit = (val) => {
    const itemDeletedAttachments = (list, item) =>
      list.map((it) => (it?.itemId === item?.id ? omit(it, 'itemId') : null))
    const data = {
      ...val,
      ecr_items_attributes: val?.ecr_items_attributes?.map((item) => ({
        ...item,
        ecr_attachments_attributes:
          item?.ecr_attachments_attributes?.file && item?.ecr_attachments_attributes !== null
            ? [
                {
                  attachment: item?.ecr_attachments_attributes?.file,
                  attachment_file_size: getSizeFromKB(item?.ecr_attachments_attributes?.size),
                  attachment_file_name: item?.ecr_attachments_attributes?.name,
                },
                ...(itemDeletedAttachments(val?.deletedAttachments, item) ?? []),
              ].filter(Boolean)
            : [
                ...(item?.ecr_attachments_attributes ?? []),
                ...(itemDeletedAttachments(val?.deletedAttachments, item) ?? []),
              ].filter(Boolean),
        ecr_attachments: [
          ...(item?.ecr_attachments ?? []),
          ...(itemDeletedAttachments(val?.deletedAttachments, item) ?? []),
        ].filter(Boolean),
      })),
    }
    const omittedData = omit(data, ['ecr_items', 'deletedAttachments'])
    if (data.id) {
      return dispatch(expenseClaimRequestUpdate(empId, val.id, omittedData)).then(() => {
        setEditRequest(false)
      })
    }
    return dispatch(expenseClaimRequestCreate(id, data)).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        ecr_items_attributes: request.ecr_items,
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        title: 'test title',
        reason: '',
        ecr_items_attributes: [{}],
        deletedAttachments: [],
      })
    }
  }, [])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <ExpenseClaimItemDetails />
    </Flex>
  )
}

export default ExpenseClaimRequest
