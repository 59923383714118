import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type EmployeeFees = {
  employee_outsourcing_company: {
    fees: string
  }
  fetching: boolean
}

const initialState: EmployeeFees = {
  employee_outsourcing_company: {
    fees: '0',
  },
  fetching: false,
}

export default function reducer(state = initialState, action: Action): EmployeeFees {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_FEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEE_FEES_LOAD_FULFILLED:
      return {
        ...state,
        employee_outsourcing_company: payload.data.data?.employee_outsourcing_company,
        fetching: false,
      }

    case actions.EMPLOYEE_FEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
