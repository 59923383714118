import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { ShiftPreset } from 'redux/setting/attendanceManagement/standardShift/standardShiftTypes'
import * as actions from './actions'

type Shift = {
  allowed_delay: string
  allowed_shortage: string
  clock_out_not_required: boolean
  clocking_in_end_duration: number
  clocking_in_start_duration: number
  clocking_out_end_duration: number
  clocking_out_start_duration: number
  color: ShiftPreset['color']
  effective_date: string
  end_time: string
  flexible_duration: number
  flexible_duration_i18n: string
  id: number
  is_end_time_next_day: boolean
  is_overlap: boolean
  name_ar: string | null
  name_en: string
  name_i18n: string
  overtime: number
  overtime_i18n: string
  start_time: string
  total_duration: string
  minimum_overtime: number | null
  overtime_within_shift: boolean | null
  mandatory_working_duration: string | null
  open_shift_duration: number | null
  open_shift_type?: 'Open' | 'Timeframe' | null
  open_shift_duration_in_words?: string
}

type ShiftReplacementState = {
  assignFetching: boolean
  assignableShifts: Shift[]
  fetching: boolean
  popupAssignFetching: boolean
}

const initialState: ShiftReplacementState = {
  fetching: false,
  assignFetching: false,
  popupAssignFetching: false,
  assignableShifts: [],
}

export default function reducer(state = initialState, action: Action): ShiftReplacementState {
  switch (action.type) {
    case actions.LOAD_ASSIGNABLE_SHIFTS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ASSIGN_REPLACE_SHIFT_PENDING:
      return {
        ...state,
        assignFetching: true,
      }

    case actions.POPUP_ASSIGN_REPLACE_SHIFT_PENDING:
      return {
        ...state,
        popupAssignFetching: true,
      }

    case actions.LOAD_ASSIGNABLE_SHIFTS_FULFILLED:
      return {
        ...state,
        fetching: false,
        assignableShifts: action.payload.data.data,
      }

    case actions.ASSIGN_REPLACE_SHIFT_FULFILLED:
      return {
        ...state,
        assignFetching: false,
      }

    case actions.POPUP_ASSIGN_REPLACE_SHIFT_FULFILLED:
      return {
        ...state,
        popupAssignFetching: false,
      }

    case actions.LOAD_ASSIGNABLE_SHIFTS_REJECTED:
    case actions.ASSIGN_REPLACE_SHIFT_REJECTED:
    case actions.POPUP_ASSIGN_REPLACE_SHIFT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        assignFetching: false,
        popupAssignFetching: false,
      }

    default:
      return state
  }
}
