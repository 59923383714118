import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import I18n from 'translations/i18n'
import { Button, Spacer } from '@jisr-hr/ds'
import { format } from 'utils/date'
import {
  durationByMinutes,
  minuteToHumanReadable,
  replaceAmPm,
} from 'components/global/form/formNormalize'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { isValid } from 'date-fns'
import { getLogTimeAndDate } from 'containers/authorised/attendance/helper'

import ShiftDetail from './ShiftDetail'

import Record from './Record'

import { useRequest } from '../../RequestProvider'
import Styles from './AttendanceRecord.module.css'

const AttendanceRecord = ({ record, employee_id, date, dateFormat }) => {
  const { vars } = useRequest()

  const handleDifference = () => {
    if (record?.work_difference > 0) return { symbol: '+', color: 'green' }
    if (record?.work_difference < 0) return { symbol: '', color: 'red' }
    return { symbol: '', color: 'black' }
  }

  const difference = handleDifference()

  return (
    <Frame
      title={I18n.t('attendance_record')}
      collapse
      active={false}
    >
      <ShiftDetail
        shift={record}
        isDayOff={record?.is_day_off}
      />
      <Spacer height={16} />
      <div className={Styles.recordCards}>
        <Record
          label={I18n.t('first_in')}
          value={replaceAmPm(getLogTimeAndDate(record?.in_punch).time)}
          description={
            record?.delay > 0 && `(${minuteToHumanReadable(record?.delay)} ${I18n.t('min_delay')})`
          }
          badge={record?.delay > 0 && I18n.t('late_in')}
          badgeType="yellow"
        />
        <Record
          label={I18n.t('last_out')}
          value={replaceAmPm(getLogTimeAndDate(record?.out_punch).time)}
          badge={record?.shortage > 0 && I18n.t('early_out')}
          description={
            record?.shortage > 0 &&
            `(${minuteToHumanReadable(record?.shortage)} ${I18n.t('min_shortage')})`
          }
          badgeType="yellow"
        />
        <Record
          label={I18n.t('worked')}
          value={durationByMinutes(record?.actual_work_duration)}
        />
        <Record
          label={I18n.t('difference')}
          value={`${difference.symbol}${durationByMinutes(record?.work_difference)}`}
          color={difference.color}
        />
        <Record
          label={I18n.t('approved_ot')}
          value={durationByMinutes(record?.total_overtime)}
        />
        <Record
          label={I18n.t('deductable')}
          value={durationByMinutes(record?.deductable)}
          color={record?.deductable === 0 ? 'black' : 'red'}
        />
      </div>
      <Spacer height={16} />

      {date && (
        <>
          {vars?.stopRecordRedirect ? (
            <DateButton
              dateFormat={dateFormat}
              date={date}
            />
          ) : (
            <Link
              target="_blank"
              to={{
                pathname: `/attendance/tracking?id=${employee_id}&&date=${
                  isValid(new Date(date))
                    ? format(new Date(date), 'yyyy-MM-dd')
                    : format(new Date(), 'yyyy-MM-dd')
                }`,
              }}
            >
              <DateButton
                dateFormat={dateFormat}
                date={date}
              />
            </Link>
          )}
        </>
      )}
    </Frame>
  )
}

AttendanceRecord.propTypes = {
  record: PropTypes.shape(),
  employee_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  dateFormat: PropTypes.string,
}

export default AttendanceRecord

const DateButton = ({ date, dateFormat }) => {
  const { vars, drawerToggle } = useRequest()

  return (
    <Button
      size="small"
      variant="ghost"
      label={format(date, dateFormat || 'EEEE, d MMM')}
      leadingIcon="arrow-up-right"
      onClick={vars?.stopRecordRedirect && drawerToggle}
    />
  )
}

DateButton.propTypes = {
  date: PropTypes.string,
  dateFormat: PropTypes.string,
}
