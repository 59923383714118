export const ATTENDANCE_CONFIGURATION_LOAD = 'ATTENDANCE_CONFIGURATION_LOAD'
export const ATTENDANCE_CONFIGURATION_LOAD_PENDING = 'ATTENDANCE_CONFIGURATION_LOAD_PENDING'
export const ATTENDANCE_CONFIGURATION_LOAD_FULFILLED = 'ATTENDANCE_CONFIGURATION_LOAD_FULFILLED'
export const ATTENDANCE_CONFIGURATION_LOAD_REJECTED = 'ATTENDANCE_CONFIGURATION_LOAD_REJECTED'

export const ATTENDANCE_LINK_LOCATION = 'ATTENDANCE_LINK_LOCATION'
export const ATTENDANCE_LINK_LOCATION_PENDING = 'ATTENDANCE_LINK_LOCATION_PENDING'
export const ATTENDANCE_LINK_LOCATION_FULFILLED = 'ATTENDANCE_LINK_LOCATION_FULFILLED'
export const ATTENDANCE_LINK_LOCATION_REJECTED = 'ATTENDANCE_LINK_LOCATION_REJECTED'

export const ORGANIZATION_DEVICE_ROLE_CREATE = 'ORGANIZATION_DEVICE_ROLE_CREATE'
export const ORGANIZATION_DEVICE_ROLE_CREATE_PENDING = 'ORGANIZATION_DEVICE_ROLE_CREATE_PENDING'
export const ORGANIZATION_DEVICE_ROLE_CREATE_FULFILLED = 'ORGANIZATION_DEVICE_ROLE_CREATE_FULFILLED'
export const ORGANIZATION_DEVICE_ROLE_CREATE_REJECTED = 'ORGANIZATION_DEVICE_ROLE_CREATE_REJECTED'

export const ORGANIZATION_PUNCH_DEVICE_LOAD = 'ORGANIZATION_PUNCH_DEVICE_LOAD'
export const ORGANIZATION_PUNCH_DEVICE_LOAD_PENDING = 'ORGANIZATION_PUNCH_DEVICE_LOAD_PENDING'
export const ORGANIZATION_PUNCH_DEVICE_LOAD_FULFILLED = 'ORGANIZATION_PUNCH_DEVICE_LOAD_FULFILLED'
export const ORGANIZATION_PUNCH_DEVICE_LOAD_REJECTED = 'ORGANIZATION_PUNCH_DEVICE_LOAD_REJECTED'

export const ORGANIZATION_DEVICE_ROLE_DELETE = 'ORGANIZATION_DEVICE_ROLE_DELETE'
export const ORGANIZATION_DEVICE_ROLE_DELETE_PENDING = 'ORGANIZATION_DEVICE_ROLE_DELETE_PENDING'
export const ORGANIZATION_DEVICE_ROLE_DELETE_FULFILLED = 'ORGANIZATION_DEVICE_ROLE_DELETE_FULFILLED'
export const ORGANIZATION_DEVICE_ROLE_DELETE_REJECTED = 'ORGANIZATION_DEVICE_ROLE_DELETE_REJECTED'

export const REFRESH_PUNCH_DEVICE_STATUS = 'REFRESH_PUNCH_DEVICE_STATUS'
export const REFRESH_PUNCH_DEVICE_STATUS_PENDING = 'REFRESH_PUNCH_DEVICE_STATUS_PENDING'
export const REFRESH_PUNCH_DEVICE_STATUS_FULFILLED = 'REFRESH_PUNCH_DEVICE_STATUS_FULFILLED'
export const REFRESH_PUNCH_DEVICE_STATUS_REJECTED = 'REFRESH_PUNCH_DEVICE_STATUS_REJECTED'

export const PUNCH_DEVICE_STATUS = 'PUNCH_DEVICE_STATUS'
export const PUNCH_DEVICE_STATUS_PENDING = 'PUNCH_DEVICE_STATUS_PENDING'
export const PUNCH_DEVICE_STATUS_FULFILLED = 'PUNCH_DEVICE_STATUS_FULFILLED'
export const PUNCH_DEVICE_STATUS_REJECTED = 'PUNCH_DEVICE_STATUS_REJECTED'

export const CREATE_PUNCH_DEVICE = 'CREATE_PUNCH_DEVICE'
export const CREATE_PUNCH_DEVICE_PENDING = 'CREATE_PUNCH_DEVICE_PENDING'
export const CREATE_PUNCH_DEVICE_FULFILLED = 'CREATE_PUNCH_DEVICE_FULFILLED'
export const CREATE_PUNCH_DEVICE_REJECTED = 'CREATE_PUNCH_DEVICE_REJECTED'

export const DELETE_PUNCH_DEVICE = 'DELETE_PUNCH_DEVICE'
export const DELETE_PUNCH_DEVICE_PENDING = 'DELETE_PUNCH_DEVICE_PENDING'
export const DELETE_PUNCH_DEVICE_FULFILLED = 'DELETE_PUNCH_DEVICE_FULFILLED'
export const DELETE_PUNCH_DEVICE_REJECTED = 'DELETE_PUNCH_DEVICE_REJECTED'

export const GET_PUNCHES_RESTRICTION = 'GET_PUNCHES_RESTRICTION'
export const GET_PUNCHES_RESTRICTION_PENDING = 'GET_PUNCHES_RESTRICTION_PENDING'
export const GET_PUNCHES_RESTRICTION_FULFILLED = 'GET_PUNCHES_RESTRICTION_FULFILLED'
export const GET_PUNCHES_RESTRICTION_REJECTED = 'GET_PUNCHES_RESTRICTION_REJECTED'

export const GET_RESTRICTION_EMPLOYEES = 'GET_RESTRICTION_EMPLOYEES'
export const GET_RESTRICTION_EMPLOYEES_PENDING = 'GET_RESTRICTION_EMPLOYEES_PENDING'
export const GET_RESTRICTION_EMPLOYEES_FULFILLED = 'GET_RESTRICTION_EMPLOYEES_FULFILLED'
export const GET_RESTRICTION_EMPLOYEES_REJECTED = 'GET_RESTRICTION_EMPLOYEES_REJECTED'

export const UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION =
  'UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION'
export const UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_PENDING =
  'UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_PENDING'
export const UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_FULFILLED =
  'UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_FULFILLED'
export const UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_REJECTED =
  'UPDATE_RESTRICTION_EMPLOYEES_CONFIGURATION_REJECTED'

export const UPDATE_DEFAULT_RESTRICTION = 'UPDATE_DEFAULT_RESTRICTION'
export const UPDATE_DEFAULT_RESTRICTION_PENDING = 'UPDATE_DEFAULT_RESTRICTION_PENDING'
export const UPDATE_DEFAULT_RESTRICTION_FULFILLED = 'UPDATE_DEFAULT_RESTRICTION_FULFILLED'
export const UPDATE_DEFAULT_RESTRICTION_REJECTED = 'UPDATE_DEFAULT_RESTRICTION_REJECTED'

export const PUNCHES_RESTRICTION_STATS = 'PUNCHES_RESTRICTION_STATS'
export const PUNCHES_RESTRICTION_STATS_PENDING = 'PUNCHES_RESTRICTION_STATS_PENDING'
export const PUNCHES_RESTRICTION_STATS_FULFILLED = 'PUNCHES_RESTRICTION_STATS_FULFILLED'
export const PUNCHES_RESTRICTION_STATS_REJECTED = 'PUNCHES_RESTRICTION_STATS_REJECTED'

export const DOWNLOAD_EMPLOYEES_RESTRICTION_LIST = 'DOWNLOAD_EMPLOYEES_RESTRICTION_LIST'
export const DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_PENDING =
  'DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_PENDING'
export const DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_FULFILLED =
  'DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_FULFILLED'
export const DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_REJECTED =
  'DOWNLOAD_EMPLOYEES_RESTRICTION_LIST_REJECTED'
