import { sortBy } from 'lodash'
import CutOffDayImg from 'assets/images/cut-off-day.svg'
import Departments from 'containers/public/Onboarding/OrganizationSetup/steps/Departments'
import I18n from 'translations/i18n'
import { Typography, Flex } from '@jisr-hr/ds'
import InviteAdminUsers from './InviteEmployees/steps/InviteAdminUsers'
import Sidebar from './components/Sidebar'
import ApplyingLeaves from './LeavesSetup/steps/ApplyingLeaves'
import CarryForward from './LeavesSetup/steps/CarryForward'
import CountingDays from './LeavesSetup/steps/CountingDays'
import CustomizingLeaves from './LeavesSetup/steps/CustomizingLeaves'
import ImportLeaveDetails from './LeavesSetup/steps/ImportLeaveDetails'
import ReviewLeaveDetails from './LeavesSetup/steps/ReviewLeaveDetails'
import { SetupStepTypes } from './types/onboardingTypes'
import Allowances from './PayrollSetup/steps/Allowances'
import PaymentMethods from './PayrollSetup/steps/PaymentMethods'
import PayrollBank from './PayrollSetup/steps/PayrollBank'
import PaySchedules from './PayrollSetup/steps/PaySchedules'
import ReviewEmployeesData from './PayrollSetup/steps/ReviewEmployeesData'
import ReviewEmployeesPayment from './PayrollSetup/steps/ReviewEmployeesPayment'
import SalariesPackages from './PayrollSetup/steps/SalariesPackages'
import SummaryReview from './PayrollSetup/steps/SummaryReview'
import Done from './PayrollSetup/steps/Done'
import ConfigurationTemplate from './components/ConfigurationTemplate'
import Subdomain from './InviteEmployees/steps/Subdomain'
import OrganizationInfo from './OrganizationSetup/steps/OrganizationInfo'
import CrAndIndustry from './OrganizationSetup/steps/CrAndIndustry'
import locationsBoard from './OrganizationSetup/steps/locationsBoard'
import JobTitles from './OrganizationSetup/steps/jobTitles'
import EmploymentType from './OrganizationSetup/steps/EmploymentType'
import UploadGovt from './OrganizationSetup/steps/uploadGovt'
import Uploading from './OrganizationSetup/steps/UploadingEmployeesData'
import EmployeeData from './OrganizationSetup/steps/EmployeeData'
import UploadingSucess from './OrganizationSetup/steps/uploadingSucess'
import LaborLawLeavesPublicHolidays from './LeavesSetup/steps/LaborLawLeavesPublicHolidays'
import Industries from './OrganizationSetup/steps/industries'

export const microOrgSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    title: 'org_structure_and_logo',
    component: OrganizationInfo,
    sidebar: (
      <Sidebar
        videoKey="org_structure_and_logo"
        infoSet={[
          {
            title: I18n.t('organization'),
            description: I18n.t('an_organisation_desc'),
          },
          {
            title: I18n.t('logo'),
            description: I18n.t('copmany_logo_desc', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'org_details_and_industry',
    component: CrAndIndustry,
    sidebar: (
      <Sidebar
        videoKey="org_details_and_industry"
        infoSet={[
          {
            title: I18n.t('organization_details'),
            description: I18n.t('commercial_registration_details', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
          {
            title: I18n.t('industry'),
            description: I18n.t('select_the_field_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'label.work_locations_branches',
    component: locationsBoard,
    sidebar: (
      <Sidebar
        videoKey="work_locations"
        infoSet={[
          {
            title: I18n.t('primary_work_location'),
            description: I18n.t('enter_the_main_headquarter_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 4,
    title: 'department',
    component: (e) => Departments(e),
    sidebar: (
      <Sidebar
        videoKey="department"
        hideFullContent
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 5,
    title: 'job_titles',
    component: JobTitles,
    sidebar: (
      <Sidebar
        videoKey="job_titles"
        infoSet={[
          {
            description: I18n.t('based_on_your_industry_desc', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 6,
    title: 'contract_type',
    component: EmploymentType,
    sidebar: (
      <Sidebar
        videoKey="contract_type"
        infoSet={[
          {
            title: I18n.t('employment_type_discription'),
            description: I18n.t('define_various_desc'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 7,
    title: 'upload_govt_files',
    component: UploadGovt,
    sidebar: (
      <Sidebar
        videoKey="upload_govt_files"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 8,
    title: 'upload_employee_data',
    component: Uploading,
    sidebar: (
      <Sidebar
        videoKey="upload_employee_data"
        infoSet={[
          {
            title: I18n.t('note'),
            description: I18n.t('kindly_ensure_to_add_the_email_description'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 9,
    title: 'reviewing_employee_data',
    component: EmployeeData,
    sidebar: (
      <Sidebar
        videoKey="reviewing_employee_data"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 10,
    title: 'verify',
    component: UploadingSucess,
    sidebar: <Sidebar infoSet={[]} />,
  },
]

export const microNewOrgSteps: SetupStepTypes[] = sortBy(
  [
    ...microOrgSteps.filter((e) => e.stepNo !== 2),
    {
      stepNo: 2,
      title: 'industry',
      component: Industries,
      sidebar: (
        <Sidebar
          infoSet={[
            {
              title: I18n.t('industry'),
              description: I18n.t('select_the_field_desc'),
            },
          ]}
        />
      ),
    },
  ],
  'stepNo',
)

export const microLeaveSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    title: 'leaves_and_payroll_configurations',
    component: ConfigurationTemplate,
    sidebar: (
      <Sidebar
        videoKey="leaves_and_payroll_configurations"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 2,
    sectionTitle: I18n.t('leaves_setup'),
    title: 'annual_leave_onboarding',
    component: CountingDays,
    sidebar: (
      <Sidebar
        videoKey="annual_leave_onboarding"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'starting_balance',
    component: ApplyingLeaves,
    sidebar: (
      <Sidebar
        videoKey="starting_balance"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 4,
    title: 'remaining_balance_onboarding',
    component: CarryForward,
    sidebar: (
      <Sidebar
        videoKey="remaining_balance_onboarding"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 5,
    title: 'labor_law_leaves_and_public_holidays',
    component: LaborLawLeavesPublicHolidays,
    sidebar: (
      <Sidebar
        videoKey="labor_law_leaves_and_public_holidays"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 6,
    title: 'customizing_leaves',
    component: CustomizingLeaves,
    sidebar: (
      <Sidebar
        videoKey="customizing_leaves"
        infoSet={[
          {
            title: I18n.t('return_confirmation'),
            description: I18n.t('return_confirmation_description_tip', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 7,
    sectionTitle: I18n.t('employees_data'),
    title: 'upload_annual_leave_files',
    component: ImportLeaveDetails,
    sidebar: (
      <Sidebar
        videoKey="upload_annual_leave_files"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 8,
    title: 'review_page',
    component: ReviewLeaveDetails,
    sidebar: <Sidebar infoSet={[]} />,
  },
]

export const microPayrollSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    sectionTitle: I18n.t('payroll_setup'),
    title: 'pay_schedules',
    component: PaySchedules,
    sidebar: (
      <Sidebar
        videoKey="pay_schedules"
        infoSet={[
          {
            imgSrc: CutOffDayImg,
            title: I18n.t('cutoff_day'),
            description: I18n.t('cutoff_day_desc'),
            custom: (
              <Flex
                flexCol
                style={{ gap: 16 }}
              >
                {[
                  {
                    color: 'var(--color-secondary-bittersweet-light-new)',
                    text: I18n.t('cutoff_date'),
                  },
                  {
                    color: 'var(--color-secondary-mantis-lighter-new)',
                    text: I18n.t('pay_day'),
                  },
                ].map(({ color, text }, i) => (
                  <Flex
                    key={i}
                    itemsCenter
                    style={{ gap: 16 }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: '50%',
                        backgroundColor: color,
                      }}
                    />
                    <Typography
                      variant="subtitle-2"
                      text={text}
                    />
                  </Flex>
                ))}
              </Flex>
            ),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    sectionTitle: I18n.t('salary_setup'),
    title: 'allowances',
    component: Allowances,
    sidebar: (
      <Sidebar
        videoKey="allowances"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'salaries_packages',
    component: SalariesPackages,
    sidebar: (
      <Sidebar
        videoKey="salaries_packages"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'review_employees_data',
    component: ReviewEmployeesData,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 4,
    title: 'review_employees_payment',
    component: ReviewEmployeesPayment,
    sidebar: (
      <Sidebar
        videoKey="review_employees_payment"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 5,
    sectionTitle: I18n.t('payroll_payment_configuration'),
    title: 'payroll_bank',
    component: PayrollBank,
    sidebar: (
      <Sidebar
        videoKey="payroll_bank"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 6,
    title: 'payment_methods',
    component: PaymentMethods,
    sidebar: (
      <Sidebar
        videoKey="payment_methods"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 7,
    sectionTitle: I18n.t('summary_review'),
    title: 'review',
    component: SummaryReview,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 8,
    title: 'done_finish',
    component: Done,
    sidebar: <Sidebar infoSet={[]} />,
  },
]

export const microNewPayrollSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    sectionTitle: I18n.t('salary_setup'),
    title: 'allowances',
    component: Allowances,
    sidebar: (
      <Sidebar
        videoKey="allowances"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'salaries_packages',
    component: SalariesPackages,
    sidebar: (
      <Sidebar
        videoKey="salaries_packages"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'review_employees_data',
    component: ReviewEmployeesData,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 3,
    title: 'done_finish',
    component: Done,
    sidebar: <Sidebar infoSet={[]} />,
  },
]

export const microActivateSteps: SetupStepTypes[] = [
  {
    stepNo: 1,
    title: 'org_account_link',
    component: Subdomain,
    sidebar: (
      <Sidebar
        videoKey="org_account_link"
        infoSet={[
          {
            title: I18n.t('add_admins_onb'),
            description: I18n.t('Make_sure_that_have_admin'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'invite_admin_users',
    component: InviteAdminUsers,
    sidebar: (
      <Sidebar
        videoKey="invite_admin_users"
        infoSet={[
          {
            title: I18n.t('invite_admins'),
            description: I18n.t('add_only_the_admins', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
]
