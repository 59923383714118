import I18n from 'translations/i18n'
import { Input } from 'components/global/atoms'
import { useDispatch, useSelector } from 'utils/hooks'
import { taskReassign, taskDetailLoad } from 'redux/requests/newRequestsApproval/actionCreators'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'
import { FC, useEffect } from 'react'
import Frame from '../../ds/Frame'
import { useRequest } from '../../RequestProvider'

const ReassignHiringTask: FC = () => {
  const dispatch = useDispatch()
  const { employee_all } = useSelector((state) => state.employee)
  const isSuperAdmin = useSelector((state) => state?.auth.employee?.is_super_admin)
  const { fetching_reassign } = useSelector((state) => state.approvals)
  const {
    vars: { request },
  } = useRequest()

  const handleChange = (employee_id: number): void => {
    dispatch(
      taskReassign(request.id, {
        reassigned_employee_id: employee_id,
      }),
    ).then(() => dispatch(taskDetailLoad(request.id)))
  }

  useEffect(() => {
    if (isSuperAdmin)
      dispatch(
        getAllEmployeesLoad({
          filter: {
            permission_name: 'edit_create_jobs',
          },
        }),
      )
  }, [])

  if (!isSuperAdmin) {
    return null
  }

  return (
    <Frame title={I18n.t('reassign_task')}>
      <Input.Select
        userSelect
        testId="reassign-tasks"
        options={employee_all}
        labelKey="name"
        valueKey="id"
        imagekey="avatar_thumb"
        version="v1"
        onChange={handleChange}
        disabled={fetching_reassign}
      />
    </Frame>
  )
}

export default ReassignHiringTask
