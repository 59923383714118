import { ChangeEvent, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { UploadingTab } from '@jisr-hr/design-system'
import { Grid } from 'components/global/atoms'
import { Flex, Typography } from '@jisr-hr/ds'
import { ReactComponent as UnionDownload } from 'assets/figma-icons/UnionDownload.svg'
import I18n from 'translations/i18n'
import { convertToFormData } from 'components/global/HelperFunctions'
import { useSelector, useDispatch } from 'utils/hooks'
import JisrLoader from 'components/global/JisrLoader'
import {
  exportOnboardingOrganizationData,
  loadOnboardingBulkUploadCategories,
} from 'redux/organizationOnborading/bulkUpload/actionCreators'
import { assetmanagerBulkUploadImportPost } from 'redux/setting/organizationSetting/assetmanagerBulkUpload/actionsCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { TOnboardingRecords } from 'redux/organizationOnborading/bulkUpload/reducer'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import StepTemplate from '../../../components/StepTemplate'
import UploadingProcess from './components/UploadingProcess'
import { StatusValueType } from './helper'
import styles from './style.module.css'

const LOCALE = localStorage.getItem('Locale') || 'en'

const Uploading = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const [download, setDownload] = useState(false)
  const { require_pincode } = useSelector((s) => s.organization.organization)
  const { empCat, fetching, fetchingBulk } = useSelector(
    ({ onboardingBulkUpload, assetmanagerBulk }) => ({
      fetching: onboardingBulkUpload.fetching || assetmanagerBulk.fetching,
      fetchingBulk: assetmanagerBulk.fetching,
      empCat:
        onboardingBulkUpload.onboarding_records.find(
          (cat) => cat.type === 'onboarding_employees',
        ) || ({} as TOnboardingRecords),
    }),
  )

  const disabled = require_pincode
    ? empCat.status_value === 1
    : !download || fetching || empCat.status_value === 1

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): Promise<void> | void => {
    const file = e.target.files && e.target.files[0]
    const values = convertToFormData({
      file,
      type: 'onboarding_employees',
      language: localStorage.getItem('Locale'),
    })
    return dispatch(assetmanagerBulkUploadImportPost(values)).then(() => {
      dispatch(loadOnboardingBulkUploadCategories())
      dispatch(organizationInfo())
    })
  }
  const handleOnClickDownload = (): void => {
    if (!fetching) {
      dispatch(exportOnboardingOrganizationData({ type: 'onboarding_employees' })).then(() => {
        setDownload(true)
      })
    }
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'upload_employee_data' })

  useEffect(() => {
    dispatch(loadOnboardingBulkUploadCategories())
  }, [])

  useEffect(() => {
    if (empCat.status_value) {
      setDownload(true)
    }
  }, [empCat])

  useEffect(() => {
    const fetchCats = setInterval(
      () => (empCat.status_value === 1 && dispatch(loadOnboardingBulkUploadCategories())) || {},
      10000,
    )
    return () => {
      clearInterval(fetchCats)
    }
  })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      {fetchingBulk && <JisrLoader absolute />}
      <Flex className={styles.formMain}>
        <Flex className={styles.formCardContent}>
          <Typography
            variant="body-new/medium"
            text={I18n.t('upload_employee_data')}
          />
          <Flex className={styles.formCard}>
            <Flex style={{ gap: '16px' }}>
              <div className={styles.syrcl}>
                <Typography
                  variant="body-new/medium"
                  text="1"
                />
              </div>
              <Flex className={styles.formItem}>
                <Typography
                  variant="body-new/regular"
                  text={I18n.t('we_ve_created_a_sample_employee_data_description')}
                />
                <Grid
                  container
                  style={{
                    alignItems: 'center',
                    gap: '7px',
                    cursor: fetching ? 'progress' : 'pointer',
                  }}
                  onClick={handleOnClickDownload}
                >
                  <UnionDownload />
                  <Typography
                    variant="body-new/medium"
                    style={{ color: 'var(--color-base-colors-blue-600)' }}
                    text={I18n.t('download_sample_employee_data_file')}
                  />
                </Grid>
              </Flex>
            </Flex>
            <Flex style={{ gap: '16px' }}>
              <div className={styles.syrcl}>
                <Typography
                  variant="body-new/medium"
                  text="2"
                />
              </div>
              <Flex className={styles.UploadingTab}>
                <UploadingTab
                  accept=".xls, .xlsx, .csv"
                  style={{ cursor: !disabled ? 'pointer' : 'no-drop' }}
                  label={I18n.t('please_import_the_employee_data_file_after_you_fill_it')}
                  onChange={handleOnChange}
                  locale={LOCALE}
                  disabled={disabled}
                />
              </Flex>
            </Flex>
          </Flex>
          {empCat.category && (
            <Box style={{ marginInlineStart: 55 }}>
              <UploadingProcess
                statusValue={empCat.status_value as StatusValueType}
                metadata={empCat.metadata || {}}
                errorFileUrl={empCat.error_file_url || ''}
                s3Url={empCat.s3_url}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </StepTemplate>
  )
}

export default Uploading
