import React from 'react'
import i18n from 'translations/i18n'
import { isEmpty } from 'lodash'
import { isValid } from 'date-fns'
import { FileUpload, Flex, Icon } from '@jisr-hr/ds-beta'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  DetailsNote,
  Assignee,
  Reason,
} from 'components/global/templates/RequestDrawer/components'
import Attachment from 'components/global/templates/RequestDrawer/components/Attachment'
import ReassignTask from 'components/global/templates/RequestDrawer/components/ReassignTask/ReassignTask'
import { statusTypes } from 'utils/uiHelpers'
import { format } from 'utils/date'
import { isTruthy } from 'utils/truthy'
import { useDispatch, useSelector } from 'utils/hooks'
import { updateTask } from 'redux/requests/newRequestsApproval/actionCreators'
import { ReactComponent as StatusIcon } from '../../icons/status.svg'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { useRequestDetail } from '../../hooks'
import InfoBadge from '../../components/Info/components/InfoBadge'
import DueDate from '../../components/DueDate'
import useTaskFooter from '../../Footer/useTaskFooter'

const CustomTask = () => {
  const { request } = useRequestDetail()
  const { employee } = useSelector(({ auth }) => auth)
  const { fetching_reassign, fetching_submit } = useSelector((state) => state.approvals)
  const { onAttachTaskFile } = useTaskFooter()
  const dispatch = useDispatch()
  const handelDueDate = (e) => {
    const dueDate = e ? new Date(e) : undefined // Handle null case
    dispatch(
      updateTask(request.id, {
        task: {
          due_date: dueDate,
        },
      }),
    )
  }

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {fetching_reassign && <JisrLoader absolute />}
      <Info
        details={request}
        customItems={[
          {
            label: i18n.t('status'),
            icon: <StatusIcon />,
            value: (
              <InfoBadge
                label={request?.status_i18n}
                color={statusTypes[request?.status?.toLowerCase()] || 'gray'}
              />
            ),
            key: 'status',
          },
          {
            label: i18n.t('date'),
            icon: <CalendarIcon />,
            value: isValid(new Date(request?.created_at))
              ? format(new Date(request?.created_at), 'dd MMM, yyyy')
              : '--',
            color: 'var(--color-natural-black-default-new)',
            key: 'date',
          },
          {
            label: i18n.t('label.due_date'),
            icon: (
              <Icon
                name="calendar"
                size="sm"
              />
            ),
            value: (
              <DueDate
                showAction
                runAction={handelDueDate}
                dateValue={request.due_date}
              />
            ),
          },
          ...(!isEmpty(request.attachments)
            ? request.attachments.map((att, i) => ({
                label: i18n.t('attachment'),
                icon: <span />,
                value: (
                  <Attachment
                    name={att?.name}
                    size={att?.size}
                    url={att?.url}
                  />
                ),
                key: i,
              }))
            : []),
        ].filter(isTruthy)}
      />

      {!isEmpty(request.details) && (
        <DetailsNote
          title={i18n.t('description')}
          reason={request.details}
        />
      )}

      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              compact
              multiple
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
    </Flex>
  )
}

export default CustomTask
