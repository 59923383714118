import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'

import {
  Info,
  Reason,
  AttendanceRecord,
  RecordChanges,
  Workflow,
  Comments,
  Tabs,
} from 'components/global/templates/RequestDrawer/components'

import { getDifferenceInHours } from 'components/global/form/formNormalize'

import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const ExcuseRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()

  const [activeTab, setActiveTab] = React.useState('request_details')
  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />

      {/* request details tab */}
      {activeTab === 'request_details' && (
        <React.Fragment>
          <Info
            details={{
              ...request,
              attendance_day: request.date,
              ...(request.excuse_type === 'middle_of_day'
                ? {
                    excuse_time: getDifferenceInHours(request?.from_time, request?.to_time)
                      ?.total_minutes,
                  }
                : {}),
            }}
            showColumns={[
              ...(request.excuse_type === 'full_day'
                ? ['attendance_day']
                : ['attendance_day', 'requested_duration']),
              ...(request.excuse_type === 'middle_of_day' ? ['from_time', 'to_time'] : []),
            ]}
          />

          <Reason details={request} />
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          {request.employee_shift && (
            <AttendanceRecord
              record={request.employee_shift}
              employee_id={request.employee?.id}
              date={request.date}
              dateFormat="dd MMM yyyy"
            />
          )}

          {request?.record_changes &&
            request?.status === 'pending' &&
            !request?.is_cancellation_request && (
              <RecordChanges record_changes={request.record_changes || {}} />
            )}

          {request.approvals?.length > 0 && (
            <Workflow
              lessDetail
              onClick={() => setActiveTab('workflow_and_comments')}
              approvals={request.approvals}
            />
          )}
        </React.Fragment>
      )}

      {/* comments & workflow tab */}
      {activeTab === 'workflow_and_comments' && (
        <React.Fragment>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          {request.approvals?.length > 0 && <Workflow approvals={request.approvals} />}
        </React.Fragment>
      )}
    </Flex>
  )
}

export default ExcuseRequest
