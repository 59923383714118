import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'

export type EligibleEmployeeType = {
  avatar_thumb: string
  code: string
  id: number
  job_title_i18n: string | null
  name: string
  name_i18n: string
}

type InitStateType = {
  fetching: boolean
  users: EligibleEmployeeType[]
  filteredUsers: EligibleEmployeeType[]
}

const initState: InitStateType = {
  fetching: false,
  users: [],
  filteredUsers: [],
}

export default function eligibleUsersReducer(state = initState, action: Action): InitStateType {
  switch (action.type) {
    case 'ELIGIBLE_USERS_LOAD_PENDING':
    case 'ELIGIBLE_USERS_FILTER_LOAD_PENDING':
      return { ...state, fetching: true }

    case 'ELIGIBLE_USERS_LOAD_FULFILLED':
      return { ...state, users: action.payload.data.data.employees, fetching: false }

    case 'ELIGIBLE_USERS_FILTER_LOAD_FULFILLED':
      return { ...state, filteredUsers: action.payload.data.data.employees, fetching: false }

    case 'ELIGIBLE_USERS_LOAD_REJECTED':
    case 'ELIGIBLE_USERS_FILTER_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, fetching: false }

    default:
      return state
  }
}
