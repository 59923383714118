import { Divider, Select } from '@jisr-hr/ds-beta'
import { useGetYearsOptions } from 'containers/authorised/report/helper'
import { useState } from 'react'
import i18n from 'translations/i18n'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'
import { useFilters } from './context'

type PeriodOfTimeProps = {
  filterKey: string
}

const PeriodOfTime = ({ filterKey }: PeriodOfTimeProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const yearsOptions = useGetYearsOptions()

  const { filter, handleFilter } = useFilters()

  const value = yearsOptions.find((y) => +y.value === +filter[filterKey])

  return (
    <>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={false}
          handleClearFilter={(): void => undefined}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title={i18n.t('label.period_of_time')}
        />
        {!isCollapsed && (
          <Select
            placeholder={i18n.t('label.period_of_time')}
            withPortal
            options={yearsOptions}
            labelKey="label"
            valueKey="value"
            onChange={(option) => handleFilter(filterKey, option?.value)}
            value={value}
          />
        )}
      </div>
      <Divider />
    </>
  )
}

export default PeriodOfTime
