import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type InsuranceStatusPorps = {
  filterKey: string
  filters: string[]
}

const InsuranceStatus = (props: InsuranceStatusPorps): JSX.Element => {
  const { insurance_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  return (
    <FilterBox
      handleFilter={handleFilter}
      options={insurance_status}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default InsuranceStatus
