import I18n from 'translations/i18n'

export const hasPendingChanges = (key, obj) => {
  if (!obj) return false
  return Object.keys(obj).includes(key)
}

export const renderFieldMessages = (name, pendingChanges) => {
  const ishasPendingChanges = hasPendingChanges(name, pendingChanges)

  if (typeof pendingChanges === 'object' && ishasPendingChanges) {
    const text = pendingChanges[name]
    return `${I18n.t('changes_are_being_reviewed')} (${text})`
  }
  return false
}
