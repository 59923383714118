import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SelectedButtons = ({
  options,
  handleOnClick,
  selectedDays,
  disabled,
  size,
  lightBlue,
  space,
}) => (
  <div className="jisr-selected-buttons">
    {options.map((item) => (
      <button
        style={{
          margin: space,
        }}
        data-testid={item.testId}
        key={item.value}
        className={classNames(
          'jisr-selected-buttons__item',
          lightBlue && 'jisr-selected-buttons__light-blue',
          selectedDays.includes(item.value) && 'jisr-selected-buttons__item--active',
          size && `jisr-selected-buttons__item--${size}`,
        )}
        onClick={() => handleOnClick(item.value)}
        disabled={disabled}
      >
        {item.label}
      </button>
    ))}
  </div>
)

SelectedButtons.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(String).isRequired,
  disabled: PropTypes.bool,
  lightBlue: PropTypes.bool,
  size: PropTypes.string,
  space: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default SelectedButtons
