import { Flex, Spacer, Typography } from '@jisr-hr/ds'
import { Editor } from '@tinymce/tinymce-react'
import { TextEditor } from 'components/global/atoms'
import { ReactComponent as ErrorIcon } from 'components/global/templates/RequestDrawer/components/forms/ProposedCorrectionTime/icons/error.svg'
import { FieldValidator as Validator } from 'final-form'
import { Field } from 'react-final-form'

type InitProps =
  | {
      [key: string]:
        | boolean
        | number
        | string
        | string[]
        | { custom: { title: string; items: string | string[] } }
    }
  | {
      setup?: (editor: any) => void
    }

type TextEditorFieldProps = {
  init: InitProps
  label?: string | JSX.Element
  name: string
  testId?: string
  validate?: Validator<string>
  direction?: string
  onInit?: (editorRef?: Editor['editor']) => void
  renderValue?: boolean
  isDisabled?: boolean
  attachmentName?: string
  change?: (name: string, value: any) => void
  // onChange?: (v: string) => void
}

const TextEditorField = (props: TextEditorFieldProps): JSX.Element => {
  const {
    init,
    name,
    validate,
    label,
    isDisabled,
    testId,
    direction,
    onInit,
    renderValue,
    attachmentName,
    change,
  } = props

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => (
        <>
          {label && (
            <>
              <Typography
                text={label}
                variant="subtitle-2"
              />
              <Spacer height={6} />
            </>
          )}

          <div
            className={isDisabled ? 'opacity-40 pointer-events-none cursor-not-allowed' : ''}
            style={{
              borderRadius: 3,
              border: meta.error && meta.touched && '1px solid var(--color-feedback-error-default)',
            }}
          >
            <TextEditor
              {...input}
              onInit={onInit}
              init={init}
              direction={direction}
              renderValue={renderValue}
              attachmentName={attachmentName}
              change={change}
            />
            {/*
              Temp: Editore take time to load
              And somtime not catching in cypress tests: HR/Overview/shareAnnouncement.spec.js
            */}
            <input
              className="hidden"
              value={input.value}
              onChange={input.onChange}
              data-testid={testId}
              name={name}
            />
          </div>
          {meta.error && meta.touched && (
            <>
              <Spacer height={6} />
              <Flex style={{ gap: 8 }}>
                <ErrorIcon />
                <Typography
                  text={meta.error}
                  variant="subtitle-2"
                  textColor="color/fg/light"
                />
              </Flex>
            </>
          )}
        </>
      )}
    </Field>
  )
}

export default TextEditorField
