import { ElementRef, FC, useEffect, useRef, useState } from 'react'
import I18n from 'translations/i18n'
import classnames from 'classnames'
import { DatePicker, Spacer } from '@jisr-hr/ds-beta'
import { TextInput } from '@jisr-hr/design-system'
import { format } from 'date-fns'
import Toggle from './Toggle'
import TimeTable from './TimeTable'
import { ReactComponent as CloseICon } from './assets/Close.svg'

import Styles from './TimePicker.module.css'

import { pad, timeToNumbers, timeGraper, isValidTime, TimeToNumbersReturn } from './functions'

const initTime = {
  ampm: 'am',
  minutes: 0,
  hour: null,
}

export type TimeT = {
  ampm: string
  minutes: number
  hour: number | null
  hour24?: number
}

export type TimePickerProps = {
  label?: string
  value?: string
  timeOnly?: boolean
  maxTime?: string
  minTime?: string
  nextDay?: string
  suffix?: string
  withResetTime?: boolean
  isClearable?: boolean
  fit?: boolean
  hideIcon?: boolean
  statusMessage?: string
  className?: string
  status?: string
  placeholder?: string
  style?: React.CSSProperties
  noLimit?: boolean
  disabled?: boolean
  endIcon?: React.ReactNode
  datePickerProps?: {
    minDate?: Date
    maxDate?: Date
    initialDate?: string
  }
  withDatePicker?: boolean
  'data-testid'?: string
  onChange?: (value: string | undefined | null) => void
  onBlur?: (value: string) => void
  onChangeDate?: (value: string) => void
}

const TimePicker: FC<TimePickerProps> = (props) => {
  const {
    label,
    value: propValue,
    timeOnly,
    maxTime,
    minTime,
    nextDay,
    withResetTime,
    isClearable,
    fit,
    hideIcon,
    style,
    noLimit,
    endIcon,
    datePickerProps,
    withDatePicker,
    className,
    ...rest
  } = props

  const [value, setValue] = useState<string | undefined>('')
  const [time, setTime] = useState<TimeT>(initTime)
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [typing, setTyping] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)

  const clearable = value && isClearable
  const max = timeToNumbers(maxTime, noLimit) || null
  const min = timeToNumbers(minTime, noLimit) || null

  // input field reference
  const inputRef = useRef<ElementRef<'input'>>(null)

  const validate = (timeText: string): string | undefined => {
    const inputValue = timeToNumbers(timeText, noLimit) as TimeToNumbersReturn

    const isMoreThanMax =
      (max?.hour24 ?? NaN) < inputValue?.hour24 ||
      (max?.hour24 === inputValue?.hour24 && inputValue?.minutes > max?.minutes)

    const isLessThanMin =
      (min?.hour24 ?? NaN) > inputValue?.hour24 ||
      (min?.hour24 === inputValue?.hour24 && inputValue?.minutes < min?.minutes)

    if (isMoreThanMax) {
      setTime(timeToNumbers(maxTime, noLimit) as TimeT)
      return maxTime
    }

    if (isLessThanMin) {
      setTime(timeToNumbers(minTime, noLimit) as TimeT)
      return minTime
    }

    return timeText
  }

  const timeToText = (): string => {
    const { ampm, hour, minutes } = time
    let val = `${pad(hour, noLimit)}:${pad(minutes)} ${
      !timeOnly && ampm ? ampm?.toUpperCase() : ''
    }`
    if (timeOnly) val = val.replace(/\s+/g, '')

    return val
  }

  // set input value
  const handleValue = (): boolean => {
    if (typing) return false
    const val = timeToText()

    const validatedValue = validate(val)

    setValue(validatedValue)
    props.onChange?.(validatedValue)

    return true
  }

  // handle on time selecting
  const handleChangeTime = <Key extends keyof TimeT>(key: Key, val: TimeT[Key]): void => {
    setTime({
      ...time,
      [key]: val,
    })

    inputRef.current?.focus()
  }

  const handleResetTime = (): void => {
    setTime({
      ...initTime,
      hour24: 0,
      hour: 0,
    })
  }

  const handleClearValue = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setValue('')
    props.onChange?.(null)
    setTime(initTime)
  }

  const handleDatePickerChange = (val: string | string[]): void => {
    if (Array.isArray(val)) return
    setDate(val)
  }

  // show/hide the dropdown
  const dropdownToggle = (): void => {
    // close any dropdown opened
    // document.querySelector('body').click();

    // open dropdown
    setOpenDropdown(true)

    // close dropdown listner
    const listner = (): void => {
      setOpenDropdown(false)
      document.removeEventListener('click', listner)
    }

    // // close dropdown when click outside
    setTimeout(() => {
      document.addEventListener('click', listner)
    }, 100)
  }

  const setValueToTime = (): void | false => {
    if (propValue === value) return false
    if (!isValidTime(propValue, noLimit)) {
      setTime(initTime)
      setValue('')
      return false
    }

    const val = propValue?.split(':')
    if (!val?.length) return false

    const timeValue = timeToNumbers(propValue, noLimit) as TimeT

    return setTime(timeValue)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value

    setValue(inputValue)
    setTyping(true)
    const t = timeGraper(inputValue, timeOnly, timeOnly, withResetTime, noLimit)

    const tempTime = timeToText()

    props.onChange?.(t || tempTime || '00:00 AM')

    if (t) {
      setTime(timeToNumbers(t, noLimit) as TimeT)
    }

    setOpenDropdown(true)
  }

  const handleOnBlur = (): void => {
    setTyping(false)
    const t = timeGraper(value, timeOnly, timeOnly, withResetTime, noLimit)
    if (t) {
      setValue(t)
      props.onBlur?.(t)
    }
  }

  // set value once time selected
  useEffect(() => {
    if (time.hour || time.hour === 0) handleValue()
  }, [time, typing])

  useEffect(() => {
    props.onChangeDate?.(date)
  }, [date])

  useEffect(() => {
    setValueToTime()
  }, [propValue])

  useEffect(() => {
    if (datePickerProps?.initialDate) setDate(datePickerProps.initialDate)
  }, [])

  return (
    <div
      className={classnames(
        Styles.timePicker,
        !clearable && Styles.clockIcon,
        timeOnly && Styles.timeOnly,
        (fit || timeOnly) && Styles.fit,
        hideIcon && Styles.hideIcon,
      )}
      style={style}
    >
      {nextDay && (
        <span className={classnames(Styles.nextday, !label && Styles.nextDayWithOutLabel)}>
          {nextDay}
        </span>
      )}

      <TextInput
        {...rest}
        label={label}
        ref={inputRef}
        className={classnames(Styles.timePickerInput, className)}
        value={value
          ?.toString()
          .toUpperCase()
          .replace('AM', I18n.t('am'))
          .replace('PM', I18n.t('pm'))}
        onClick={dropdownToggle}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...(clearable && {
          endIcon: (
            <CloseICon
              className="pointer"
              onClick={handleClearValue}
            />
          ),
        })}
        {...(endIcon && {
          endIcon,
        })}
      />

      {openDropdown && !timeOnly && (
        <div
          className={Styles.dropdown}
          onClick={(e): void => e.stopPropagation()}
        >
          {withDatePicker && (
            <>
              <DatePicker
                value={new Date(date)}
                dateFormat="yyyy-MM-dd"
                triggerType="input"
                position="bottom"
                textFieldProps={{
                  showClearIcon: false,
                }}
                calenderProps={{
                  size: 'compact',
                  type: 'single',
                  preSetRange: false,
                  showMonthYearPicker: false,
                  maxDate: datePickerProps?.maxDate,
                  minDate: datePickerProps?.minDate,
                }}
                onChange={handleDatePickerChange}
              />
              <Spacer height={10} />
            </>
          )}

          <div onMouseDown={(e): void => e.preventDefault()}>
            <div className={Styles.toggle}>
              <Toggle
                list={[
                  { label: I18n.t('am'), value: 'am' },
                  { label: I18n.t('pm'), value: 'pm' },
                ]}
                selected={time.ampm}
                handleChange={(t: { label: string; value: string }): void =>
                  handleChangeTime('ampm', t.value)
                }
              />
            </div>
            <TimeTable
              time={time}
              withResetTime={withResetTime || timeOnly}
              handleResetTime={handleResetTime}
              onChange={handleChangeTime}
              maxTime={max}
              minTime={min}
              is24Hour={timeOnly}
              noLimit={noLimit}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default TimePicker
