export const VALIDATE_ADD_EMPLOYEE_STEPS_LOAD = 'VALIDATE_ADD_EMPLOYEE_STEPS_LOAD'
export const VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_PENDING = 'VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_PENDING'
export const VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_FULFILLED =
  'VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_FULFILLED'
export const VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_REJECTED = 'VALIDATE_ADD_EMPLOYEE_STEPS_LOAD_REJECTED'

export const VALIDATE_HANDLE_NEXT_STEP = 'VALIDATE_HANDLE_NEXT_STEP'
export const SET_VALUES_STEPS = 'SET_VALUES_STEPS'
export const REST_VALUES_STEPS = 'REST_VALUES_STEPS'
export const SET_ALLOW_REDIRECT = 'SET_ALLOW_REDIRECT'
