import { useDispatch, useSelector } from 'utils/hooks'
import { firebaseConfig, getTokenFromDB } from 'firebaseUtils'
import { ORGANISATION_NAME, serverEnvironment } from 'env'
import {
  getMessaging as getFirebaseMessaging,
  getToken as getFirebaseToken,
  isSupported,
} from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import {
  firebaseTopicsLoad,
  registrationToken,
  subscribeFirebase,
  unsubscribeFirebase,
} from 'redux/actions/firebaseActions'
import { askNotificationPermission } from './notifications'

type useFirebaseHooksType = {
  initializeFirebase: () => void
}

const useFirebaseHooks = (): useFirebaseHooksType => {
  const dispatch = useDispatch()

  // ? selectors
  const userId = useSelector((s) => s.auth?.employee?.id)
  const {
    assign_shifts,
    publish_shifts,
    bulk_update_employee_information,
    manage_other_financial_information,
    manage_salary_information,
  } = useSelector((s) => s.auth?.employee?.permission_scopes) || {}

  const { edit_on_payroll_table, view_payroll_table } =
    useSelector((s) => s.auth?.employee?.permission_details) || {}
  const company = useSelector((s) => s.auth?.company)

  const notificationsSettings = useSelector(
    (s) => s.notificationsSetting.employee_configuration?.options,
  )

  const isPerformanceEvaluation = company?.subscribed_features?.includes(
    'new_performance_management',
  )

  // ? locals

  const topics = [
    ...(notificationsSettings?.announcements === 'true'
      ? [
          `${serverEnvironment}_jisr_announcements_${ORGANISATION_NAME}`,
          `${serverEnvironment}_jisr_announcements_${userId}`,
        ]
      : []),
    ...(notificationsSettings?.updates === 'true'
      ? [`${serverEnvironment}_jisr_updates_${ORGANISATION_NAME}`]
      : []),
    ...(view_payroll_table ? [`${serverEnvironment}_jisr_${ORGANISATION_NAME}_payrun`] : []),
    ...(!!assign_shifts || !!publish_shifts
      ? [`${serverEnvironment}_jisr_${userId}_bulk_import_scheduler`]
      : []),
    ...(!!bulk_update_employee_information && !!manage_other_financial_information
      ? [
          `${serverEnvironment}_jisr_${userId}_bulk_import_payroll_startup_payment_details`,
          `${serverEnvironment}_jisr_${userId}_bulk_import_wps_gosi_infos`,
        ]
      : []),
    ...(!!bulk_update_employee_information && !!manage_salary_information
      ? [`${serverEnvironment}_jisr_${userId}_bulk_import_salaries_packages`]
      : []),
    ...(edit_on_payroll_table
      ? [`${serverEnvironment}_jisr_${userId}_bulk_import_payroll_adjustments`]
      : []),
    `${serverEnvironment}_jisr_${userId}_employee`,
    ...(isPerformanceEvaluation
      ? [`${serverEnvironment}_jisr_${userId}_employee_performance`]
      : []),
  ]

  // ? functions

  /**
   * Checks if the user is already subscribed to the existing topics.
   *
   * @returns A promise that resolves to a boolean indicating whether the user is already subscribed to the existing topics.
   */
  const isTopicsAlreadySubscribed = async (): Promise<boolean> => {
    // >> 1 - check if there is any token in the browser DB
    // >> 2 - if there is no token we will hit subscribe API directly
    // >> 3 - if there is token we will hit list API to get the topics
    // >> 4 - then we will match these topics with the latest topics existing in FE
    // >> 5 - if matched we will return true otherwise false

    const userToken = await getTokenFromDB().then((token) => token)
    if (!userToken) return false

    try {
      const subscribedTopicsRes = await dispatch(firebaseTopicsLoad({ token: userToken }))
      const subscribedTopics = subscribedTopicsRes.value.data.data.topics as string[]

      // * matching the topics with the one comes from BE
      if (topics.length !== subscribedTopics.length) return false
      const latestTopicsSet = new Set(topics)
      const subscribedTopicsSet = new Set(subscribedTopics)
      return (
        latestTopicsSet.size === subscribedTopicsSet.size &&
        [...latestTopicsSet].every((item) => subscribedTopicsSet.has(item))
      )
    } catch {
      return false
    }
  }

  const onSubscribe = (token: string): void => {
    const payload = {
      token,
      topics,
    }

    dispatch(subscribeFirebase(payload))
    dispatch(
      registrationToken({
        token,
        platform: 'web',
        service_name: 'firebase',
      }),
    )
  }

  const handleSubscribe = (): boolean | Promise<NotificationPermission> | Promise<void> =>
    askNotificationPermission(() => {
      const firebaseApp = initializeApp(firebaseConfig)
      const messaging = getFirebaseMessaging(firebaseApp)
      getFirebaseToken(messaging, {
        vapidKey: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX1ZBUElEX0tFWQo,
      })
        .then((currentToken) => {
          if (currentToken) onSubscribe(currentToken)
        })
        .catch((e) => console.error(e))
    })

  /**
   * Initializes Firebase and handles subscription to topics.
   *
   * @returns void
   */
  const initializeFirebase = (): void => {
    // 1 >> check if messaging notifications supported
    // 2 >> if yes, we will check if user is already subscribed to the latest topics or not
    // 3 >> if not, we will check if there is any token in the browser DB ,if yes we will unsubscribe the user from the subscribed topics and remove the token from the DB
    // 4 >> then we will subscribe the user to the latest topics

    if (!firebaseConfig.apiKey) {
      console.error('Firebase config is missing in .env file')
      return
    }

    isSupported().then((messagingIsSupported) => {
      if (!messagingIsSupported) return
      isTopicsAlreadySubscribed().then(async (isInit) => {
        if (isInit) return
        const userToken = await getTokenFromDB({ clear: true }).then((token) => token)
        if (userToken) await dispatch(unsubscribeFirebase({ token: userToken }))
        handleSubscribe()
      })
    })
  }

  return {
    initializeFirebase,
  }
}

export default useFirebaseHooks
