export const COMPETENCY_SETS_LOAD = 'COMPETENCY_SETS_LOAD'
export const COMPETENCY_SETS_LOAD_PENDING = 'COMPETENCY_SETS_LOAD_PENDING'
export const COMPETENCY_SETS_LOAD_FULFILLED = 'COMPETENCY_SETS_LOAD_FULFILLED'
export const COMPETENCY_SETS_LOAD_REJECTED = 'COMPETENCY_SETS_LOAD_REJECTED'

export const COMPETENCY_SETS_CREATE = 'COMPETENCY_SETS_CREATE'
export const COMPETENCY_SETS_CREATE_PENDING = 'COMPETENCY_SETS_CREATE_PENDING'
export const COMPETENCY_SETS_CREATE_FULFILLED = 'COMPETENCY_SETS_CREATE_FULFILLED'
export const COMPETENCY_SETS_CREATE_REJECTED = 'COMPETENCY_SETS_CREATE_REJECTED'

export const COMPETENCY_SETS_SHOW = 'COMPETENCY_SETS_SHOW'
export const COMPETENCY_SETS_SHOW_PENDING = 'COMPETENCY_SETS_SHOW_PENDING'
export const COMPETENCY_SETS_SHOW_FULFILLED = 'COMPETENCY_SETS_SHOW_FULFILLED'
export const COMPETENCY_SETS_SHOW_REJECTED = 'COMPETENCY_SETS_SHOW_REJECTED'

export const COMPETENCY_SETS_UPDATE = 'COMPETENCY_SETS_UPDATE'
export const COMPETENCY_SETS_UPDATE_PENDING = 'COMPETENCY_SETS_UPDATE_PENDING'
export const COMPETENCY_SETS_UPDATE_FULFILLED = 'COMPETENCY_SETS_UPDATE_FULFILLED'
export const COMPETENCY_SETS_UPDATE_REJECTED = 'COMPETENCY_SETS_UPDATE_REJECTED'

export const COMPETENCY_SETS_DELETE = 'COMPETENCY_SETS_DELETE'
export const COMPETENCY_SETS_DELETE_PENDING = 'COMPETENCY_SETS_DELETE_PENDING'
export const COMPETENCY_SETS_DELETE_FULFILLED = 'COMPETENCY_SETS_DELETE_FULFILLED'
export const COMPETENCY_SETS_DELETE_REJECTED = 'COMPETENCY_SETS_DELETE_REJECTED'

export const ASSIGN_COMPETENCY_SETS = 'ASSIGN_COMPETENCY_SETS'
export const ASSIGN_COMPETENCY_SETS_PENDING = 'ASSIGN_COMPETENCY_SETS_PENDING'
export const ASSIGN_COMPETENCY_SETS_FULFILLED = 'ASSIGN_COMPETENCY_SETS_FULFILLED'
export const ASSIGN_COMPETENCY_SETS_REJECTED = 'ASSIGN_COMPETENCY_SETS_REJECTED'
