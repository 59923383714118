export const ROLE_RESET = 'ROLE_RESET'

export const APP_MODULES_LOAD = 'APP_MODULES_LOAD'
export const APP_MODULES_LOAD_PENDING = 'APP_MODULES_LOAD_PENDING'
export const APP_MODULES_LOAD_FULFILLED = 'APP_MODULES_LOAD_FULFILLED'
export const APP_MODULES_LOAD_REJECTED = 'APP_MODULES_LOAD_REJECTED'

export const ROLES_LOAD = 'ROLES_LOAD'
export const ROLES_LOAD_PENDING = 'ROLES_LOAD_PENDING'
export const ROLES_LOAD_FULFILLED = 'ROLES_LOAD_FULFILLED'
export const ROLES_LOAD_REJECTED = 'ROLES_LOAD_REJECTED'

export const ROLE_LOAD = 'ROLE_LOAD'
export const ROLE_LOAD_PENDING = 'ROLE_LOAD_PENDING'
export const ROLE_LOAD_FULFILLED = 'ROLE_LOAD_FULFILLED'
export const ROLE_LOAD_REJECTED = 'ROLE_LOAD_REJECTED'

export const ROLE_CREATE = 'ROLE_CREATE'
export const ROLE_CREATE_PENDING = 'ROLE_CREATE_PENDING'
export const ROLE_CREATE_FULFILLED = 'ROLE_CREATE_FULFILLED'
export const ROLE_CREATE_REJECTED = 'ROLE_CREATE_REJECTED'

export const ROLE_UPDATE = 'ROLE_UPDATE'
export const ROLE_UPDATE_PENDING = 'ROLE_UPDATE_PENDING'
export const ROLE_UPDATE_FULFILLED = 'ROLE_UPDATE_FULFILLED'
export const ROLE_UPDATE_REJECTED = 'ROLE_UPDATE_REJECTED'

export const ROLE_DELETE = 'ROLE_DELETE'
export const ROLE_DELETE_PENDING = 'ROLE_DELETE_PENDING'
export const ROLE_DELETE_FULFILLED = 'ROLE_DELETE_FULFILLED'
export const ROLE_DELETE_REJECTED = 'ROLE_DELETE_REJECTED'

export const ROLE_EMPLOYEES_UPDATE = 'ROLE_EMPLOYEES_UPDATE'
export const ROLE_EMPLOYEES_UPDATE_PENDING = 'ROLE_EMPLOYEES_UPDATE_PENDING'
export const ROLE_EMPLOYEES_UPDATE_FULFILLED = 'ROLE_EMPLOYEES_UPDATE_FULFILLED'
export const ROLE_EMPLOYEES_UPDATE_REJECTED = 'ROLE_EMPLOYEES_UPDATE_REJECTED'

export const ROLE_EMPLOYEES_DELETE = 'ROLE_EMPLOYEES_DELETE'
export const ROLE_EMPLOYEES_DELETE_PENDING = 'ROLE_EMPLOYEES_DELETE_PENDING'
export const ROLE_EMPLOYEES_DELETE_FULFILLED = 'ROLE_EMPLOYEES_DELETE_FULFILLED'
export const ROLE_EMPLOYEES_DELETE_REJECTED = 'ROLE_EMPLOYEES_DELETE_REJECTED'

export const ROLE_PERMISSIONS_LOAD = 'ROLE_PERMISSIONS_LOAD'
export const ROLE_PERMISSIONS_LOAD_PENDING = 'ROLE_PERMISSIONS_LOAD_PENDING'
export const ROLE_PERMISSIONS_LOAD_FULFILLED = 'ROLE_PERMISSIONS_LOAD_FULFILLED'
export const ROLE_PERMISSIONS_LOAD_REJECTED = 'ROLE_PERMISSIONS_LOAD_REJECTED'

export const EMPLOYEE_PERMISSIONS_LOAD = 'EMPLOYEE_PERMISSIONS_LOAD'
export const EMPLOYEE_PERMISSIONS_LOAD_PENDING = 'EMPLOYEE_PERMISSIONS_LOAD_PENDING'
export const EMPLOYEE_PERMISSIONS_LOAD_FULFILLED = 'EMPLOYEE_PERMISSIONS_LOAD_FULFILLED'
export const EMPLOYEE_PERMISSIONS_LOAD_REJECTED = 'EMPLOYEE_PERMISSIONS_LOAD_REJECTED'

export const ROLE_EMPLOYEES_LOAD = 'ROLE_EMPLOYEES_LOAD'
export const ROLE_EMPLOYEES_LOAD_PENDING = 'ROLE_EMPLOYEES_LOAD_PENDING'
export const ROLE_EMPLOYEES_LOAD_FULFILLED = 'ROLE_EMPLOYEES_LOAD_FULFILLED'
export const ROLE_EMPLOYEES_LOAD_REJECTED = 'ROLE_EMPLOYEES_LOAD_REJECTED'

export const ALL_ROLE_EMPLOYEES_LOAD = 'ALL_ROLE_EMPLOYEES_LOAD'
export const ALL_ROLE_EMPLOYEES_LOAD_PENDING = 'ALL_ROLE_EMPLOYEES_LOAD_PENDING'
export const ALL_ROLE_EMPLOYEES_LOAD_FULFILLED = 'ALL_ROLE_EMPLOYEES_LOAD_FULFILLED'
export const ALL_ROLE_EMPLOYEES_LOAD_REJECTED = 'ALL_ROLE_EMPLOYEES_LOAD_REJECTED'

// export const ROLE_MANAGERS_LOAD = 'ROLE_MANAGERS_LOAD';
// export const ROLE_MANAGERS_LOAD_PENDING = 'ROLE_MANAGERS_LOAD_PENDING';
// export const ROLE_MANAGERS_LOAD_FULFILLED = 'ROLE_MANAGERS_LOAD_FULFILLED';
// export const ROLE_MANAGERS_LOAD_REJECTED = 'ROLE_MANAGERS_LOAD_REJECTED';

export const ALL_ROLE_MANAGERS_LOAD = 'ALL_ROLE_MANAGERS_LOAD'
export const ALL_ROLE_MANAGERS_LOAD_PENDING = 'ALL_ROLE_MANAGERS_LOAD_PENDING'
export const ALL_ROLE_MANAGERS_LOAD_FULFILLED = 'ALL_ROLE_MANAGERS_LOAD_FULFILLED'
export const ALL_ROLE_MANAGERS_LOAD_REJECTED = 'ALL_ROLE_MANAGERS_LOAD_REJECTED'

export const ADD_NEW_ROLE = 'ADD_NEW_ROLE'
export const REMOVE_NEW_ROLE = 'REMOVE_NEW_ROLE'
