import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type TEmployeeLeaveDays = {
  id: number
  days_per_year: number
  is_default: boolean
}

export const initialState = {
  fetching: false,
  employee_leave_days: [] as TEmployeeLeaveDays[],
}

export default function reducer(
  state = initialState,
  { type, payload }: Action,
): typeof initialState {
  switch (type) {
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_PENDING:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_PENDING:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_PENDING:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_PENDING:
    case actions.CLONE_LEAVES_SETTINGS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_FULFILLED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_FULFILLED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.CLONE_LEAVES_SETTINGS_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_REJECTED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_REJECTED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_REJECTED:
    case actions.ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_REJECTED:
    case actions.CLONE_LEAVES_SETTINGS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
