import { Flex } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import styles from './styles.module.css'

type InfoProps = {
  height?: number
  width?: number
}

const Info = ({ width = 40, height = 40 }: InfoProps): JSX.Element => {
  return (
    <Flex
      itemsCenter
      justifyCenter
      className={styles.info}
      style={{ width, height }}
    >
      <DSIcon
        name="info-circle"
        color="var(--color-brand-primary-default-new)"
        size="sm"
      />
    </Flex>
  )
}

export default Info
