export const OVERTIME_POLICY_CONFIGURATION_LOAD = 'OVERTIME_POLICY_CONFIGURATION_LOAD'
export const OVERTIME_POLICY_CONFIGURATION_LOAD_PENDING =
  'OVERTIME_POLICY_CONFIGURATION_LOAD_PENDING'
export const OVERTIME_POLICY_CONFIGURATION_LOAD_FULFILLED =
  'OVERTIME_POLICY_CONFIGURATION_LOAD_FULFILLED'
export const OVERTIME_POLICY_CONFIGURATION_LOAD_REJECTED =
  'OVERTIME_POLICY_CONFIGURATION_LOAD_REJECTED'

export const OVERTIME_POLICY_CONFIGURATION_UPDATE = 'OVERTIME_POLICY_CONFIGURATION_UPDATE'
export const OVERTIME_POLICY_CONFIGURATION_UPDATE_PENDING =
  'OVERTIME_POLICY_CONFIGURATION_UPDATE_PENDING'
export const OVERTIME_POLICY_CONFIGURATION_UPDATE_FULFILLED =
  'OVERTIME_POLICY_CONFIGURATION_UPDATE_FULFILLED'
export const OVERTIME_POLICY_CONFIGURATION_UPDATE_REJECTED =
  'OVERTIME_POLICY_CONFIGURATION_UPDATE_REJECTED'
