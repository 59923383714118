import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PayrunTypesTypes = {
  filterKey: string
  filters: string[]
}

const PayrunTypes = (props: PayrunTypesTypes): JSX.Element => {
  const { payrun_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(payrun_status)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default PayrunTypes
