export const ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE =
  'ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE'
export const ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_PENDING =
  'ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_PENDING'
export const ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_FULFILLED =
  'ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_FULFILLED'
export const ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_REJECTED =
  'ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_REJECTED'

export const LABORAW_CONFIGURATIONS_UPDATE = 'LABORAW_CONFIGURATIONS_UPDATE'
export const LABORAW_CONFIGURATIONS_UPDATE_PENDING = 'LABORAW_CONFIGURATIONS_UPDATE_PENDING'
export const LABORAW_CONFIGURATIONS_UPDATE_FULFILLED = 'LABORAW_CONFIGURATIONS_UPDATE_FULFILLED'
export const LABORAW_CONFIGURATIONS_UPDATE_REJECTED = 'LABORAW_CONFIGURATIONS_UPDATE_REJECTED'
