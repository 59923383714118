import I18n from 'translations/i18n'
import { timeToNumbers } from 'components/global/form/formNormalize'

export const entryMethodTypes = [
  { label: I18n.t('different_durations'), value: 'different_durations' },
  { label: I18n.t('overtime_one_total_distributed_equally'), value: 'distributed_equally' },
]

export const headers = [I18n.t('date'), I18n.t('shift'), I18n.t('overtime')]

export const overtimeTypes = [
  { label: I18n.t('single_day'), value: 'single_day' },
  { label: I18n.t('multiple_days'), value: 'multiple_days' },
  { label: I18n.t('multiple_days_range'), value: 'bulk_overtime' },
]

export const canSubmitOverTime = (records) => {
  let canSubmit = false
  records?.forEach((record) => {
    const { totalMinutes } = timeToNumbers(record?.duration)
    if (totalMinutes > 0) {
      canSubmit = true
    }
  })

  return canSubmit
}
