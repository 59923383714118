import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { InitialStateType } from './type'
import * as actions from './actions'

const initialState: InitialStateType = {
  attendance_review_required: false,
  vacation_review_required: false,
  final_review_required: false,
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING:
    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED: {
      return {
        ...state,
        vacation_review_required: payload.data?.data?.payrun_configuration.value,
        fetching: false,
      }
    }

    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        vacation_review_required: payload.data?.data?.payrun_configuration.value,
        fetching: false,
      }
    }

    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED:
    case actions.ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
