import React from 'react'
import PropTypes from 'prop-types'

const Thead = ({ children, ...props }) => <thead {...props}>{children}</thead>

Thead.propTypes = {
  children: PropTypes.node,
}

export default Thead
