import { useState } from 'react'
import { Typography, Button, Spacer, Flex } from '@jisr-hr/ds'
import { CustomColumnDef, Table } from '@jisr-hr/ds-beta'
import { titleCase } from 'utils/string'
import JisrLoader from 'components/global/JisrLoader'
import { EmployeeJobIdAvatar } from 'components/global/molecules'
import { useSelector, useDispatch } from 'utils/hooks'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { removeAdminUser } from 'redux/organizationOnborading/roles/actionCreators'
import { admenEmployeesLoad } from 'redux/organizationOnborading/employees/actionCreators'
import { TEmployees } from 'redux/organizationOnborading/employees/employees.type'
import I18n from 'translations/i18n'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'

import AddUsers from './AddUsers'

type RowDataType = { row: { original: TEmployees } }

const AdminUser = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const { adminEmployees, fetching } = useSelector((s) => s.reviewingEmployee)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  const AdminUsers = adminEmployees?.filter((user) =>
    user?.roles.find((role) => role?.is_super_admin),
  )

  const onRemoveUser = (id: number): void => {
    dispatch(
      removeAdminUser({
        employee_role: [{ employee_id: id }],
      }),
    ).then(() => {
      dispatch(admenEmployeesLoad())
      dispatch(organizationInfo())
    })
  }

  const handleNext = (): void => {
    goNextStep?.()
  }

  useTrackingSteps({ step_name: 'invite_employees', level_name: 'admin_users' })

  const columns: CustomColumnDef[] = [
    {
      id: 'user',
      header: titleCase(I18n.t('user')),
      cell: (rowData: RowDataType): JSX.Element => {
        const employee = rowData.row.original
        return (
          <>
            {/* @ts-expect-error Need to migrate EmployeeJobIdAvatar.js to TypeScript */}
            <EmployeeJobIdAvatar
              id={employee.code}
              name={employee.full_name_i18n}
              avatar={employee.avatar_thumb}
              jobTitle={employee.job_title?.name_i18n}
            />
          </>
        )
      },
    },
    {
      header: '',
      id: 'actions',
      cell: (rowData: RowDataType): JSX.Element => {
        const employee = rowData.row.original
        return (
          <Flex justifyEnd>
            <Button
              variant="outlined"
              color="neutral"
              size="small"
              leadingIcon="x"
              onClick={(): void => onRemoveUser(employee?.id)}
            />
          </Flex>
        )
      },
      alignment: 'right',
    },
  ]

  return (
    <StepTemplate
      goNextStep={handleNext}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      fetching={fetching}
    >
      {fetching && <JisrLoader absolute />}
      <Typography
        variant="heading"
        text={I18n.t('admin_users')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ paddingInlineEnd: '70px', color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('admin_users_desc', { app_name: I18n.t('jisr_translation_name') })}
      />

      <Flex
        justifyEnd
        style={{ paddingInlineEnd: '70px' }}
      >
        <Button
          label={I18n.t('add_admins_onb')}
          size="medium"
          onClick={(): void => setIsFormOpen(true)}
          leadingIcon="plus"
        />
      </Flex>
      <AddUsers
        open={isFormOpen}
        onClose={(): void => {
          setIsFormOpen(false)
          dispatch(admenEmployeesLoad())
        }}
      />

      <Spacer height={32} />
      <Table
        data={AdminUsers}
        columns={columns}
        tableSize={{ maxHeight: 'calc(100vh - 312px)' }}
      />
    </StepTemplate>
  )
}

export default AdminUser
