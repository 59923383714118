import { Typography, Spacer } from '@jisr-hr/ds'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import Attachment from '../Attachment'
import Styles from '../../Request.module.css'

type AttachmentTypes = {
  name?: string
  size?: number
  url?: string
}

type DetailsAttachmentsProps = {
  attachments: Array<AttachmentTypes>
  title?: string
}

const DetailsAttachments = (props: DetailsAttachmentsProps): JSX.Element => {
  const { title = i18n.t('attachment'), attachments } = props

  return (
    <div className={Styles.groupField}>
      {title && (
        <Typography
          text={title}
          variant="title-1"
        />
      )}
      <Spacer height={16} />
      {!isEmpty(attachments) &&
        attachments.map((att, i) => (
          <div
            style={{
              padding: '8px 0',
              ...(i !== 0 && {
                borderTop: '1px solid var(--color-natural-gray-lighter-new)',
              }),
            }}
            key={i}
          >
            <Attachment
              key={i}
              name={att?.name}
              size={att?.size}
              url={att?.url}
            />
          </div>
        ))}
    </div>
  )
}

export default DetailsAttachments
