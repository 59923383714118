import * as actions from './actions'
import { ToggleNavigationState, ToggleNavigationType } from './toggleNavigationType'

export const initialState = {
  isNavigationOpen: true,
}

export default function reducer(
  state = initialState,
  action: ToggleNavigationType,
): ToggleNavigationState {
  switch (action.type) {
    case actions.TOGGLE_NAVIGATION:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
