/* eslint-disable import/first */
import Axios from 'axios'
import I18n from 'translations/i18n'
import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'
import { Organization, TVerifyOTP } from './types'

export function setAuthToken(): void {
  localStorage.setItem('LoggedIn', 'true')
}

export function removeAuthToken(): void {
  localStorage.removeItem('LoggedIn')
}

export function setLocale(locale = 'en'): void {
  localStorage.setItem('Locale', locale)
}

export function removeAuthUser(): { type: string } {
  removeAuthToken()

  return {
    type: actions.REMOVE_AUTH_USER,
  }
}

export function login<T extends Record<string, string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/sessions/`
  const method = 'POST'
  const params = {}

  return {
    type: actions.LOGIN,
    payload: handleAPI(url, params, method, data),
  }
}

export function logout<T extends Record<string, string>>(data?: T): Action {
  const url = `/${API_VERSION_TWO}/sessions/`
  const method = 'DELETE'
  const params = {}

  return {
    type: actions.LOGOUT,
    payload: handleAPI(url, params, method, data),
  }
}

export function validateCompany<T extends Record<string, string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/sessions/validate_company`
  const method = 'POST'
  const params = {}

  return {
    type: actions.VALIDATE_COMPANY,
    payload: handleAPI(url, params, method, data),
  }
}
export function validateCompanyKsa<T extends Record<string, string>>(data: T): Action {
  const http = Axios({
    method: 'POST',
    url: `https://api.jisr.net.sa/api/${API_VERSION_TWO}/sessions/validate_company`,
    data,
    headers: {
      locale: localStorage.getItem('Locale') || 'en',
    },
  })
  return {
    type: actions.VALIDATE_COMPANY_KSA,
    payload: http,
  }
}

export function changesOrgniationStatus(
  status: 'new_account' | 'onboarding_phase' | 'live',
): Action {
  return {
    type: actions.CHANGES_ORGNIATION_STATUS,
    payload: status,
  }
}

export function updateAcceptedTermsAndConditions(payload: Record<string, boolean>): Action {
  return {
    type: actions.UPDATE_ACCEPTED_TERMS_AND_CONDITIONS,
    payload,
  }
}

export function onboardingChangesOrganizationInfo(data: Partial<Organization>): Action {
  return {
    type: actions.ONBOARDING_CHANGES_ORGANIZATION_INFO,
    payload: data,
  }
}

export function getCurrentUser(): Action {
  const url = `/${API_VERSION_TWO}/sessions/employee/`
  const method = 'get'
  const params = {}

  return {
    type: actions.GET_CURRENT_USER,
    payload: handleAPI(url, params, method),
  }
}

export function currentEmployeeChangeLanguage<T extends Record<string, string>>(
  employee_id: number,
  data: T,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/configurations/language_preference`
  const langParams = { employee_configuration: data }
  const payloadData = new Promise<void>((resolve, reject) => {
    handleAPI(url, {}, 'PUT', langParams)
      ?.then(() => {
        I18n.changeLanguage(data.value).then(() => {
          window.location.reload()
          resolve()
        })
      })
      .catch((err) => reject(err))
  })

  return {
    type: actions.CURRENT_EMPLOYEE_CHANGE_LANGUAGE,
    payload: payloadData,
  }
}

export function changePassword<T extends Record<string, string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/passwords/change`
  const method = 'POST'
  const params = {}

  return {
    type: actions.CHANGE_PASSWORD,
    payload: handleAPI(url, params, method, { employee: data }),
  }
}

export function forgotPassword<T extends Record<string, string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/passwords/`
  const method = 'POST'
  const params = {}

  return {
    type: actions.FORGOT_PASSWORD,
    payload: handleAPI(url, params, method, data),
  }
}

export function resetPassword<T extends Record<string, string>>(token: string, data: T): Action {
  const url = `/${API_VERSION_TWO}/passwords/${token}`
  const method = 'PUT'

  return {
    type: actions.RESET_PASSWORD,
    payload: handleAPI(url, {}, method, { employee: data }),
  }
}

export function frameSuccessLogin<T extends Record<string, string>>(data: T): Action {
  return {
    type: actions.LOGIN_FULFILLED,
    payload: data,
  }
}

export function frameSuccessRelogin<T extends Record<string, string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/sessions/sso_login`
  const method = 'POST'
  const params = {}

  return {
    type: actions.LOGIN,
    payload: handleAPI(url, params, method, data),
  }
}

export function lastConfirmedPayroll(params = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payruns/last_confirmed_payrun`

  return {
    type: actions.LAST_CONFIRMED_PAYROLL,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function experimentValuesCreate<T extends Record<string, number | string>>(data: T): Action {
  const url = `/${API_VERSION_TWO}/experiment_values`
  const method = 'POST'
  return {
    type: actions.EXPERIMENT_VALUES_CREATE,
    payload: handleAPI(url, {}, method, { experiment_value: data }),
  }
}

export function experimentValuesUpdate<T extends Record<string, string | object>>(
  data: T,
  empID: number,
): Action {
  const url = `/${API_VERSION_TWO}/experiment_values/${empID}`
  return {
    type: actions.EXPERIMENT_VALUES_UPDATE,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export function updateEmployeePermissions<T extends Record<string, unknown>>(vals: T): Action {
  return {
    type: actions.UPDATE_EMPLOYEE_PERMISSION,
    payload: vals,
  }
}

export function company_subdomains(data: { email: string; otp_code?: string }): Action {
  const url = `/${API_VERSION_TWO}/company_subdomains`
  return {
    type: actions.COMPANY_SUBDOMAINS,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function onboardingChangeTourStep(data: {
  step_tour?: number | null
  welcome_page_tour_completed?: boolean
  department_tour_completed?: boolean
}): Action {
  return {
    type: actions.ONBOARDING_CHANGE_TOUR_STEP,
    payload: data,
  }
}

export function microsoftVerifyOtp(data: {
  email: string
  otp: string
  tid: string
  slug: string
  type: string
  provider: string
}): Action {
  const url = '/auth/microsoft/verify_otp'
  return {
    type: actions.MICROSOFT_VERIFY_OTP,
    payload: handleAPI(url, data, 'GET'),
  }
}

export function verifyPhoneNumber(data: TVerifyOTP): Action {
  const url = `/${API_VERSION_TWO}/sessions/mobile_number`
  const method = 'POST'

  return {
    type: actions.VERIFY_PHONE_NUMBER,
    payload: handleAPI(url, {}, method, data),
  }
}

export function verifyOTPDigits(data: TVerifyOTP): Action {
  const url = `/${API_VERSION_TWO}/sessions/verify_mobile_otp`
  const method = 'POST'

  return {
    type: actions.VERIFY_OTP_DIGITS,
    payload: handleAPI(url, {}, method, data),
  }
}

export function redirectAfterLogin(data: string | null): Action {
  return {
    type: actions.REDIRECT_AFTER_LOGIN,
    payload: data,
  }
}
