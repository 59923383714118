const BenefitsManagement = {
  // A
  add_endorsments: 'إضافة موظف جديد',
  add_endorsment_verify: 'التحقق',
  add_endorsment_cancel: 'حذف',
  add_endorsment_continue: 'المتابعة',
  add_endorsment_save_close: 'الحفظ والإغلاق',
  assign_policy: 'اختر الوثيقة',
  all_changes_saved: 'جميع التغييرات حُفظت',
  add_endorsement: 'تأمين موظف',
  add_dependent: 'إضافة تابع',
  attachment_type_list: 'PDF or JPG (10mb .كحد أقصى)',
  action_required: 'الإجراء مطلوب',
  active_bill_notice: 'إشعار الفاتورة النشطة',
  active_bill_msg:
    'يمكنك إنشاء فاتورة جديدة فقط بعد انتهاء صلاحية الفاتورة النشطة. تكون كل فاتورة صالحة لمدة أسبوعين.',
  add_another_endorsement: 'إضافة طلب آخر',
  action_required_msg:
    'برجاء زيارة حسابك على تعاونية لتكملة الإجراءات المطلوبة بخصوص طلب رقم {{request_num}}',
  add_dependent_title: 'ما هي العلاقة التابعة؟',
  adjustment_by_mistake: 'إجراء عن طريق الخطأ',
  available_classes: 'الفئات المتاحة',

  // B
  benefits_management: 'إدارة المزايا والمكافآت',
  benefits_management_insurance: 'التأمين الصحي',
  benefits_management_health_insurance: 'التأمين الصحي',
  benefits_management_policies: 'وثائقك',
  benefits_management_class: 'فئة التأمين',
  back_to_insurance: 'العودة للتأمين الصحي',
  back_to_policies: 'العودة إلى قائمة الوثائق',
  sadad_bill_number: 'رقم فاتورة سداد',
  benefits_management_providers: 'مقدمي خدمات',
  back_to_providers: 'العودة إلى مقدمي الخدمات',

  // C
  cancel_add_endorsement_msg: 'هل تريد حذف الطلب؟',
  card_code: 'رمز البطاقة',
  cancel_dependent_msg: 'هل أنت متأكد من حذف هذا التابع؟',
  connect_policy: 'ربط وثيقة',
  change_member_id: 'تعديل رقم العضوية',
  change_member_class: 'تغيير فئة العضوية',
  copy_of_identity: 'الاسم بالكامل (بالإنجليزية)',
  choose_nationality: 'اختر الجنسية',
  copy_of_iqama: 'نسخة من الإقامة',
  cancellation_reason_label: 'ما سبب الحذف؟',
  cancellation_reason_placeholder: 'اختر السبب',
  choose_class: 'اختيار الفئة',
  change_class_upgrade_title: 'ما هو سبب تعلية الفئة؟',
  change_class_downgrade_title: 'ما سبب تخفيض الفئة؟',
  change_class_current_class: 'الفئة الحالية',
  change_class_upgrade: 'ترقية الفئة',
  change_class_downgrade: 'تخفيض الفئة',
  change_class_hd_msg: 'يرجى ملاحظة أنك بحاجة إلى مراجعة البيانات الصحية لكل عضو.',

  // D
  dependent: 'تابع',
  dependents: 'التابعين',
  download_document: 'تحميل الوثيقة',
  dependents_relation: 'صلة قرابة التابع',
  dependent_national_id_iqama: 'رقم الهوية/الاقامة للتابع',
  dependent_info: 'معلومات التابع',
  delete_member: 'حذف العضو',
  delete_member_msg: 'من فضلك قم بتوضيح سبب الحذف.',
  delete_member_alert_title: 'بحذف هذا العضو سيتم حذف الأعضاء التابعين أيضًا',
  delete_member_alert_msg: 'يعتبر هذا الإجراء دائمًا ولا يمكن الرجوع عنه أو إلغائه بعد حذف العضو.',
  demotion_letter: 'خطاب رسمي مع تخفيض الفئة',

  // E
  endorsements: 'الطلبات',
  external_nationalities: 'الجنسيات',
  endorsement_details: 'تفاصيل الطلب',
  endorsement_payment: 'الدفع',
  endorsement_due_date: 'تاريخ الإنتهاء',
  employee_national_id_iqama: 'رقم الهوية/الاقامة للموظف',
  edit_member: 'تعديل بيانات العضو',
  edit_member_load_details: 'تحميل البيانات',
  endorsement_request_type: 'نوع الطلب',
  endorsement_member_type: 'نوع العضوية',
  // F

  // G
  gcc_id_or_passport_no: 'هوية خليجية/جواز سفر',
  gosi_print: 'طبعة GOSI',
  generate_bill: 'إنشاء فاتورة',

  // H
  health_declaration: 'الإفصاح الطبي',

  // I
  insurance_sub_title: 'أمن في دقائق!',
  insurance_status: 'الحالة',
  insurance_policy: 'الوثيقة',
  insurance_type: 'نوع التأمين',
  insurance_id: 'الهوية',
  insurance_details: 'تفاصيل التأمين',
  insurance_filter_lable: 'التصفية',
  insurance_effective_date: 'تاريخ التفعيل',
  insurance_statuse_draft: 'مسودة',
  insurance_statuse_completed: 'مكتملة',
  insurance_statuse_rejected: 'مرفوضة',
  insurance_statuse_pending_approval: 'في انتظار الموافقة',
  insurance_policy_class: 'فئات التأمين',
  insurance_policy_number: 'رقم الوثيقة',
  insurance_full_name_en: 'الاسم بالكامل (بالإنجليزية)',
  is_eligible_to_enroll: 'الأهلية للسياسة',

  // J
  job_demotion: 'خفض في المستوى الوظيفي',

  // L
  load_employee_details: 'التحقق من الهوية',
  linked: 'مضاف',

  // M
  member_card_code: 'رمز البطاقة',
  member_full_name: 'اسم المشترك كاملًا ',
  member_id: 'بطاقة تعريف المشترك',
  members: 'إجمالي الأعضاء',
  member: 'عضو',
  member_details: 'معلومات العضو',
  member_employees_count: '{{count}} موظفين',
  member_dependents_count: '{{count}} تابعين',
  members_not_linked_with_jisr: 'مؤمن عليهم غير مضافين على منصة {{app_name}} كموظفين',
  is_member_linked: 'حالة الإضافة في ',
  membership_type: 'نوع العضوية',

  // N
  natural_numbers_validation_msg: 'ادخل قيم رقميه فقط',
  no_endorsements_yet: 'لا يوجد طلبات حتى الآن.',
  no_policies_yet: 'لا يوجد وثائق حتى الآن.',
  not_linked_member_info:
    'بيانات هذا الموظف المؤمن عليه غير موجودة على منصة {{app_name}} ، يرجي إضافته كموظف جديد',
  not_linked: 'غير مضاف',
  no_active_policies_msg: 'قم بربط وثيقة أولًا ',
  nationality_attachment: 'وثيقة/ نسخة من الجنسية',
  new_iqama_no: 'رقم الإقامة الجديد',
  new_iqama_placeholder: 'أدخل رقم الإقامة الجديد',
  new_job_promotion: 'ترقية جديدة بالعمل',
  no_providers_yet: 'لا يوجد مقدمي خدمات حتي الان',

  // O
  offboarding_insurance_permission_msg:
    'يجب تفعيل صلاحية "إدارة التأمين الصحي" لمتابعة عملية الحذف',
  offboarding_insurance_endorsement_msg:
    'يوجد طلب إضافة تأمين لهذا الموظف، الرجاء التواصل مع {{provider_name}} لإلغائه',
  offboard_deleted_msg: 'تم استكمال طلب الحذف بنجاح',

  // P
  policy_details: 'تفاصيل الوثيقة',
  premium: 'تكلفة التأمين',
  policy_document: 'مستند الوثيقة',
  pending_payment: 'في انتظار الدفع',
  promotion_letter: 'خطاب الترقية',

  // Q

  // R
  reation: 'العلاقة',
  rejection_reasons: 'أسباب الرفض',
  resubmit_msg: 'هل تريد إعادة إرسال الملفات المرفقة؟',
  resubmit: 'إعادة الإرسال ',
  request_type: 'نوع الطلب',
  review_actions: 'مراجعة الإجراءات',

  // S
  select_policy: 'اختر الوثيقة',
  select_class: 'اختر الفئة',
  save_dependent: 'حفظ التابع',
  sponsor_no: 'Sponsor No. AR',
  submit_endo_heading: 'تم تقديم طلبك لإضافة عضو {{number}} بنجاح',
  submit_endo_subHeading:
    'سيتم مراجعة الطلب من قبل فريق "{{provider_name}}" وسيتم الرد عليك خلال يوم عمل واحد.',
  sync_endosements_statuses_tooltip: 'مزامنة حالة الطلبات',
  sync_policies_tooltip: ' مزامنة فئات الموظفين مع الوثائق',
  sync_members_tooltip: 'مزامنة بيانات المؤمن عليهم في هذه الوثيقة',
  submit_endorsement_msg: 'هل أنت متأكد من إرسال هذا الطلب؟',
  submit_downgrade_confirmation_msg:
    'سوف يتم تفعيل تخفيض الفئة لهذا العضو  مباشرة بمجرد إرسال الطلب. هل تريد المتابعة؟',
  // T
  the_member: 'العضو',
  total_members: 'إجمالي الأعضاء ({{count}})',

  // U
  under_review: 'تحت المراجعة',

  // V
  verification: 'التحقق من الهوية',
  validate_reason: 'التحقق من السبب',

  // W

  // X

  // Y
  your_changes_will_be_saved: 'تغييراتك سيتم حفظها',

  // Z
}

export default BenefitsManagement
