import React from 'react'
import { isEmpty, omit } from 'lodash'

import { Flex } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFormState } from 'react-final-form'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import {
  CorrectionDateAndType,
  ProposedCorrectionTime,
  Reason,
  Profile,
} from 'components/global/templates/RequestDrawer/components/forms'
import { getEmployeeAttendanceDetails } from 'redux/attendance/employeeDetails/actionsCreators'
import {
  missingPunchRequestCreate,
  missingPunchRequestUpdate,
} from 'redux/actions/missingPunchRequestActions'
import JisrLoader from 'components/global/JisrLoader'
import { attendanceLogsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'

import { useRequestDetail } from '../../hooks'

const CorrectionRequest = () => {
  const dispatch = useDispatch()
  const {
    values: { correction_date, attendance_record_id, shift },
  } = useFormState()
  const { change } = useForm()
  const { fetching: createFetching } = useSelector(({ missingPunchRequest }) => missingPunchRequest)
  const { attendance_record, requestFetching: fetching } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )

  const record = attendance_record?.[0]
  const selectedShift = record?.employee_shifts?.find(({ id }) => id === shift)
  const shiftIndex = record?.employee_shifts?.findIndex(({ id }) => shift === id)

  const {
    setOnSubmit,
    setInitialValues,
    vars,
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = vars.id ?? request?.employee?.id

  const handleShiftList = () => {
    dispatch(
      getEmployeeAttendanceDetails(
        empId,
        {
          dates: [correction_date],
          include_attendance_log_details: true,
        },
        true,
      ),
    )
  }

  const handleOnSubmit = (values) => {
    change('disableSubmit', true)
    const val = {
      employee_shift_id: values?.shift,
      attendance_record_id: values?.attendance_record_id,
      justification: values.justification,
      reason: values?.reason,
      attachments: values?.attachments?.[0]?.data
        ? [...values?.attachments, ...(values?.deletedAttachments ?? [])]
        : values?.deletedAttachments ?? null,
      ...(values?.in_punch && {
        in_time: `${values?.in_punch?.date} ${values?.in_punch?.punch_time}`,
        ...(values?.in_punch?.id && { in_time_log_id: values?.in_punch?.id }),
      }),
      ...(values?.out_punch && {
        out_time: `${values?.out_punch?.date} ${values?.out_punch?.punch_time}`,
        ...(values?.out_punch?.id && { out_time_log_id: values?.out_punch?.id }),
      }),
    }

    if (values.id) {
      return dispatch(
        missingPunchRequestUpdate(empId, values.id, {
          ...val,
          attachments: val?.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      )
        .then(() => {
          change('disableSubmit', false)
          setEditRequest(false)
        })
        .catch(() => change('disableSubmit', false))
    }

    return dispatch(
      missingPunchRequestCreate(empId, {
        ...val,
        attachments: val?.attachments?.map((it) => omit(it, 'id')),
      }),
    )
      .then(() => {
        change('disableSubmit', false)
        drawerToggle()
        if (actionCallback) {
          actionCallback()
        }
      })
      .catch(() => change('disableSubmit', false))
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        deletedAttachments: [],
        ...request,
        correction_date: request?.date,
        initialShiftId: request?.employee_shift?.id,
      })
    } else {
      setInitialValues({
        deletedAttachments: [],
        punches: {},
        correction_date: vars?.date,
        attendance_record_id: vars?.recordId,
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(handleOnSubmit)
    if (editRequest) {
      const defaultInTime = request?.in_time_log?.punch_time || request?.in_time
      const defaultOutTime = request?.out_time_log?.punch_time || request?.out_time

      if (shiftIndex <= 0) {
        change('first_in_punch', defaultInTime)
        change('first_out_punch', defaultOutTime)
      } else {
        change('second_in_punch', defaultInTime)
        change('second_out_punch', defaultOutTime)
      }
    }

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  React.useEffect(() => {
    if (correction_date && empId) {
      const changes = {
        attendance_record_id: undefined,
        shift: undefined,
        in_punch: undefined,
        out_punch: undefined,
      }
      Object.entries(changes).forEach(([key, value]) => {
        change(key, value)
      })
      handleShiftList()
    }
  }, [correction_date, empId])

  React.useEffect(() => {
    if (attendance_record_id) {
      dispatch(attendanceLogsLoad(attendance_record_id))
    }
  }, [attendance_record_id])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(fetching || createFetching || loading) && <JisrLoader absolute />}
      <Profile employee={vars?.employee} />
      <CorrectionDateAndType />
      <ProposedCorrectionTime
        employee_shifts={attendance_record?.[0]?.employee_shifts}
        shift={{
          is_shift_got_ended: isEmpty(record?.employee_shifts),
          in_time_log: {
            reporting_method: record?.in_time_log?.reporting_method,
            reporting_method_i18n: record?.in_time_log?.reporting_method_i18n,
          },
          out_time_log: {
            reporting_method: record?.out_time_log?.reporting_method,
            reporting_method_i18n: record?.out_time_log?.reporting_method_i18n,
          },
          ...selectedShift,
          in_time: selectedShift?.in_punch || record?.in_punch,
          out_time: selectedShift?.out_punch || record?.out_punch,
          ...(request?.in_time_log?.punch_time && { is_in_time_existing: true }),
          ...(request?.out_time_log?.punch_time && { is_out_time_existing: true }),
        }}
      />
      <Reason
        fieldPlaceholder="enter_reason_detail"
        showPunchReasonTypes
      />
    </Flex>
  )
}

export default CorrectionRequest
