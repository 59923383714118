import { Select, SelectType } from '@jisr-hr/ds-beta'
import { FieldValidator } from 'final-form'
import { memo } from 'react'
import { Field } from 'react-final-form'

const NewSelectField = <T,>({
  name,
  onChange,
  validate,
  status,
  helperText,
  options,
  value,
  defaultValue,
  valueKey = 'value',
  ...rest
}: SelectType<T> & {
  name: string
  defaultValue?: T
  validate?: FieldValidator<any>
}): JSX.Element => {
  const getValue = (val?: T | string): T | undefined => {
    return Array.isArray(options)
      ? options.find((option) =>
          val !== null && typeof val === 'object'
            ? option[valueKey] === val[valueKey]
            : option[valueKey] === val,
        )
      : undefined
  }

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }) => (
        <Select<T>
          {...rest}
          {...input}
          status={meta.error && meta.touched ? 'destructive' : status ?? undefined}
          helperText={meta.error && meta.touched ? meta.error : helperText}
          value={defaultValue ?? getValue(input.value ?? value)}
          options={options}
          valueKey={valueKey}
          onChange={(val): void => {
            input.onChange(val && val?.[valueKey])
            onChange?.(val)
          }}
          key={input.value ?? value ?? 'empty-key'}
        />
      )}
    </Field>
  )
}

export default memo(NewSelectField) as typeof NewSelectField
