import { useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Select } from '@jisr-hr/design-system'
import { Spacer, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { updateOrganization } from 'redux/organizationOnborading/organization/actionCreators'
import {
  industriesLoad,
  providersLoad,
} from 'redux/organizationOnborading/industries/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { StepsTypes } from '../../../types/onboardingTypes'
import CRInformationBoard from '../CRInformationBoard'
import Industries from '../../components/Industries'

type IndustryType = {
  id: number
  name_ar: string
  name_en: string
  name_i18n: string
}

type IndustriesType = {
  industries: {
    industries: IndustryType[]
  }
}

export const providersTypes = [
  'accounting_system_providers',
  'point_of_sale_providers',
  'medical_insurance_providers',
]

const CrAndIndustry = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const industryId = useSelector(({ organization }) => organization?.organization?.industry?.id)

  const { organization } = useSelector((s) => s.organization)

  const { point_of_sale_provider, accounting_system_provider, medical_insurance_provider } =
    organization

  const is_Accointing_and_medical_exist = !(
    accounting_system_provider?.id && medical_insurance_provider?.id
  )
  const is_point_of_sale_provider_exist =
    ['Food and beverages', 'Retail, Wholesale and Distribution'].includes(
      organization?.industry?.name_en,
    ) && !point_of_sale_provider?.id

  const industries = useSelector((s: IndustriesType) => {
    return s.industries?.industries?.map((industry: IndustryType) => ({
      ...industry,
      label: industry.name_i18n,
      value: industry.id,
    }))
  })

  const onChange = ({ id }: { id: number }): void => {
    dispatch(updateOrganization({ industry_id: id }))
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'org_details_and_industry' })

  useEffect(() => {
    dispatch(industriesLoad())
    providersTypes.map((type) => dispatch(providersLoad(type)))
  }, [])

  return (
    <CRInformationBoard
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={
        !industryId || is_Accointing_and_medical_exist || is_point_of_sale_provider_exist
      }
    >
      <Spacer height={54} />
      <Typography
        variant="title-1"
        style={{ color: 'var(--color-base-colors-black-600)' }}
        text={I18n.t('industry')}
      />

      <Spacer height={16} />
      <Select
        style={{ maxWidth: 414 }}
        onChange={onChange}
        options={industries}
        value={industries.find((industry: { id: number }) => industry.id === industryId)}
        maxMenuHeight={170}
      />
      <Spacer height={16} />
      <Industries />
    </CRInformationBoard>
  )
}

export default CrAndIndustry
