import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function paySchedulesLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payschedules`

  return {
    type: actions.PAY_SCHEDULES_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function paySchedulesUpdate(data = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payschedules`

  return {
    type: actions.PAY_SCHEDULES_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { payschedule: data }),
  }
}
