import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@jisr-hr/ds'

const WorkFlowBadge = ({ label, type, icon }) => (
  <Badge
    label={label}
    leadingIcon={icon}
    size="medium"
    color={type}
    variant="tinted"
  />
)

WorkFlowBadge.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
}

export default WorkFlowBadge
