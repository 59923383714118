const DEFAULT_STATE = {
  leave_requests_detail: {},
  fetching: false,
}

let changes = null
export default function leaveRequestsDetailReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'LEAVE_REQUESTS_DETAIL_LOAD_FULFILLED':
      changes = {
        leave_requests_detail: action.payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
