import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  advance_vacation_salary_payment_task: [],
  issuing_ticket_task: {},
  ticket_payment_task: {},
  exit_reentry_payment_task: {},
  exit_reentry_issuing_task: {},
  loan_payment_task: {},
  final_settlement_payment_task: [],
  business_trip_payment_task: {},
  expense_claim_payment_task: {},
  payroll_payment_task: [],
  gosi_salary_updation_task: {},
  employee_gosi_registration_task: {},
  letter_issuing_task: {},
  fetching: false,
  vacation_settlement_processing_task: {},
  final_settlement_processing_task: {},
  vacation_settlement_employees: {},
  final_settlement_employees: {},
  payrun_employees: {},
  asset_allocation_task: {},
  asset_deallocation_task: {},
  flight_ticket_task: {},
  organization_tasks: [],
  configuration: {},
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_PENDING:
    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_PENDING:
    case actions.ISSUING_TICKET_TASK_LOAD_PENDING:
    case actions.ISSUING_TICKET_TASK_UPDATE_PENDING:
    case actions.TICKET_PAYMENT_TASK_LOAD_PENDING:
    case actions.TICKET_PAYMENT_TASK_UPDATE_PENDING:
    case actions.EXIT_REENTRY_PAYMENT_TASK_LOAD_PENDING:
    case actions.EXIT_REENTRY_PAYMENT_TASK_UPDATE_PENDING:
    case actions.ISSUING_EXIT_REENTRY_TASK_LOAD_PENDING:
    case actions.ISSUING_EXIT_REENTRY_TASK_UPDATE_PENDING:
    case actions.LOAN_PAYMENT_TASK_LOAD_PENDING:
    case actions.LOAN_PAYMENT_TASK_UPDATE_PENDING:
    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_PENDING:
    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_PENDING:
    case actions.BUSINESS_TRIP_PAYMENT_TASK_LOAD_PENDING:
    case actions.BUSINESS_TRIP_PAYMENT_TASK_UPDATE_PENDING:
    case actions.EXPENSE_CLAIM_PAYMENT_TASK_LOAD_PENDING:
    case actions.EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_PENDING:
    case actions.PAYRUN_PAYMENT_TASK_LOAD_PENDING:
    case actions.PAYRUN_PAYMENT_TASK_UPDATE_PENDING:
    case actions.GOSI_SALARY_UPDATION_TASK_LOAD_PENDING:
    case actions.GOSI_SALARY_UPDATION_TASK_UPDATE_PENDING:
    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_PENDING:
    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_PENDING:
    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_PENDING:
    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_PENDING:
    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_PENDING:
    case actions.LETTER_ISSUING_TASK_LOAD_PENDING:
    case actions.LETTER_ISSUING_TASK_UPDATE_PENDING:
    case actions.VACATION_SALARY_EMPLOYEES_LOAD_PENDING:
    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_PENDING:
    case actions.ASSET_ALLOCATION_TASK_LOAD_PENDING:
    case actions.ASSET_DEALLOCATION_TASK_LOAD_PENDING:
    case actions.ASSET_ALLOCATION_TASK_UPDATE_PENDING:
    case actions.ASSET_DEALLOCATION_TASK_UPDATE_PENDING:
    case actions.ORGANIZATION_TASKS_CREATE_PENDING:
    case actions.ORGANIZATION_TASKS_LOAD_PENDING:
    case actions.ORGANIZATION_TASKS_SHOW_PENDING:
    case actions.ORGANIZATION_TASKS_PUT_PENDING:
    case actions.BOOK_FLIGHT_TICKET_TASKS_LOAD_PENDING:
    case actions.BOOK_FLIGHT_TICKET_TASK_UPDATE_PENDING:
    case actions.ORGANIZATION_TASKS_DELETE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.VACATION_SALARY_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        vacation_settlement_employees: payload.data.data.employees,
        fetching: false,
      }
    case actions.FINAL_SETTLEMENT_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        final_settlement_employees: payload.data.data.employees,
        fetching: false,
      }
    case actions.PAYRUN_EMPLOYEES_LOAD_FULFILLED:
      return {
        ...state,
        payrun_employees: payload.data.data.employees,
        fetching: false,
      }

    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        advance_vacation_salary_payment_task: payload.data.data,
        fetching: false,
      }

    case actions.ISSUING_TICKET_TASK_LOAD_FULFILLED:
      return {
        ...state,
        issuing_ticket_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.TICKET_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        ticket_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.EXIT_REENTRY_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        exit_reentry_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ISSUING_EXIT_REENTRY_TASK_LOAD_FULFILLED:
      return {
        ...state,
        exit_reentry_issuing_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.LOAN_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        loan_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        final_settlement_payment_task: payload.data.data,
        fetching: false,
      }

    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_FULFILLED:
      return {
        ...state,
        final_settlement_processing_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.BUSINESS_TRIP_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        business_trip_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.EXPENSE_CLAIM_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        expense_claim_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.PAYRUN_PAYMENT_TASK_LOAD_FULFILLED:
      return {
        ...state,
        payroll_payment_task: payload.data.data,
        fetching: false,
      }

    case actions.GOSI_SALARY_UPDATION_TASK_LOAD_FULFILLED:
      return {
        ...state,
        gosi_salary_updation_task: payload.data.data.configuration,
      }

    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_FULFILLED:
      return {
        ...state,
        employee_gosi_registration_task: payload.data.data.configuration,
      }
    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_FULFILLED:
      return {
        ...state,
        vacation_settlement_processing_task: payload.data.data.configuration,
      }

    // LETTER_ISSUING_TASK
    case actions.LETTER_ISSUING_TASK_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        letter_issuing_task: payload.data.data.configuration,
      }

    case actions.LETTER_ISSUING_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        letter_issuing_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ISSUING_EXIT_REENTRY_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        exit_reentry_issuing_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.EXIT_REENTRY_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        exit_reentry_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ISSUING_TICKET_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        issuing_ticket_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.TICKET_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ticket_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.LOAN_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loan_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.BUSINESS_TRIP_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        business_trip_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        expense_claim_payment_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.PAYRUN_PAYMENT_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.GOSI_SALARY_UPDATION_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        gosi_salary_updation_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        employee_gosi_registration_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        final_settlement_processing_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        vacation_settlement_processing_task: payload.data.data.configuration,
        fetching: false,
      }

    // ASSET_ALLOCATION/DEALLOCATION_TASK
    case actions.ASSET_ALLOCATION_TASK_LOAD_FULFILLED:
      return {
        ...state,
        asset_allocation_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ASSET_DEALLOCATION_TASK_LOAD_FULFILLED:
      return {
        ...state,
        asset_deallocation_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ASSET_ALLOCATION_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        asset_allocation_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ASSET_DEALLOCATION_TASK_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        asset_deallocation_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ORGANIZATION_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        organization_tasks: payload.data.data.organization_tasks,
        fetching: false,
      }
    case actions.BOOK_FLIGHT_TICKET_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        flight_ticket_task: payload.data.data.configuration,
        fetching: false,
      }
    case actions.BOOK_FLIGHT_TICKET_TASK_UPDATE_FULFILLED:
      return {
        ...state,
        flight_ticket_task: payload.data.data.configuration,
        fetching: false,
      }

    case actions.ORGANIZATION_TASKS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_TASKS_SHOW_FULFILLED:
      return {
        ...state,
        configuration: action.payload.data.data.configuration,
        fetching: false,
      }

    case actions.ORGANIZATION_TASKS_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGANIZATION_TASKS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_REJECTED:
    case actions.ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.ISSUING_TICKET_TASK_LOAD_REJECTED:
    case actions.ISSUING_TICKET_TASK_UPDATE_REJECTED:
    case actions.TICKET_PAYMENT_TASK_LOAD_REJECTED:
    case actions.TICKET_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.EXIT_REENTRY_PAYMENT_TASK_LOAD_REJECTED:
    case actions.EXIT_REENTRY_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.ISSUING_EXIT_REENTRY_TASK_LOAD_REJECTED:
    case actions.ISSUING_EXIT_REENTRY_TASK_UPDATE_REJECTED:
    case actions.LOAN_PAYMENT_TASK_LOAD_REJECTED:
    case actions.LOAN_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_REJECTED:
    case actions.FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.BUSINESS_TRIP_PAYMENT_TASK_LOAD_REJECTED:
    case actions.BUSINESS_TRIP_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.EXPENSE_CLAIM_PAYMENT_TASK_LOAD_REJECTED:
    case actions.EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.PAYRUN_PAYMENT_TASK_LOAD_REJECTED:
    case actions.PAYRUN_PAYMENT_TASK_UPDATE_REJECTED:
    case actions.GOSI_SALARY_UPDATION_TASK_LOAD_REJECTED:
    case actions.GOSI_SALARY_UPDATION_TASK_UPDATE_REJECTED:
    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_REJECTED:
    case actions.EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_REJECTED:
    case actions.LETTER_ISSUING_TASK_LOAD_REJECTED:
    case actions.LETTER_ISSUING_TASK_UPDATE_REJECTED:
    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_REJECTED:
    case actions.FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_REJECTED:
    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_REJECTED:
    case actions.VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_REJECTED:
    case actions.VACATION_SALARY_EMPLOYEES_LOAD_REJECTED:
    case actions.ASSET_ALLOCATION_TASK_LOAD_REJECTED:
    case actions.ASSET_DEALLOCATION_TASK_LOAD_REJECTED:
    case actions.ASSET_ALLOCATION_TASK_UPDATE_REJECTED:
    case actions.ASSET_DEALLOCATION_TASK_UPDATE_REJECTED:
    case actions.ORGANIZATION_TASKS_LOAD_REJECTED:
    case actions.BOOK_FLIGHT_TICKET_TASKS_LOAD_REJECTED:
    case actions.BOOK_FLIGHT_TICKET_TASK_UPDATE_REJECTED:
    case actions.ORGANIZATION_TASKS_CREATE_REJECTED:
    case actions.ORGANIZATION_TASKS_SHOW_REJECTED:
    case actions.ORGANIZATION_TASKS_PUT_REJECTED:
    case actions.ORGANIZATION_TASKS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
