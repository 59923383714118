import React from 'react'
import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

const EmploymentType = (props) => {
  const { employment_types } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={employment_types}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

EmploymentType.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default EmploymentType
