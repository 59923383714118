import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from '../Table.module.css'

import ActionsButtons from './ActionsButtons'

const Cell = ({
  children,
  error,
  className,
  th,
  actions,
  onClickEdit,
  lineAfter,
  sortable,
  sortDir,
  noWrap,
  icon,
  sortActive,
  rowspan,
  colspan,
  subCell,
  alignCenter,
  ...props
}) => {
  const Tag = th ? 'th' : 'td'

  return (
    <Tag
      className={classnames(
        className,
        error && Styles.errorTd,
        actions && Styles.actionsCell,
        lineAfter && Styles.cellLineAfter,
        sortable && Styles.sortable,
        sortActive && Styles.sortActive,
        sortDir === 'desc' && Styles.sortDesc,
        sortDir === 'asc' && Styles.sortAsc,
        noWrap && Styles.noWrap,
        subCell && Styles.subCell,
        alignCenter && Styles.cellCenter,
      )}
      colSpan={colspan}
      rowSpan={rowspan}
      {...props}
    >
      <div className={classnames(Styles.cellContent)}>
        {actions ? <ActionsButtons onClickEdit={onClickEdit} /> : children}
        {icon && <div>{icon}</div>}
      </div>
    </Tag>
  )
}

Cell.defaultProps = {
  onClickEdit: () => {},
}

Cell.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  className: PropTypes.string,
  th: PropTypes.bool,
  actions: PropTypes.bool,
  onClickEdit: PropTypes.func,
  lineAfter: PropTypes.bool,
  sortable: PropTypes.bool,
  sortDir: PropTypes.string,
  noWrap: PropTypes.bool,
  icon: PropTypes.node,
  sortActive: PropTypes.bool,
  rowspan: PropTypes.number,
  colspan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  subCell: PropTypes.bool,
  alignCenter: PropTypes.bool,
}

export default Cell
