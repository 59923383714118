import { Flex, Spacer, Button, Typography, Modal } from '@jisr-hr/ds'
import { ReactComponent as PlusIcon } from '@jisr-hr/design-system/dist/assets/icons/plus-icon.svg'
import { TBusinessUnits } from 'redux/organizationOnborading/businessUnits/type'
import styles from './styles.module.css'

type TodoListProps = {
  fetching: boolean
  form: JSX.Element
  headerTitle: string
  isOpen: boolean
  list: TBusinessUnits[]
  title: string
  onDelete: (item: { id: number }) => void
  onEdit: (item: { id: number }) => void
  setIsOpen: (item: boolean) => void
}

const TodoList = ({
  title,
  headerTitle,
  list,
  onEdit,
  onDelete,
  form,
  isOpen,
  setIsOpen,
  fetching,
}: TodoListProps): JSX.Element => {
  // make it any because this component is a shared component
  const handleForm = (val: any): void => {
    setIsOpen(true)
    onEdit(val)
  }

  return (
    <>
      <div style={{ width: 475 }}>
        <Flex
          itemsCenter
          className={styles.addNew}
          onClick={(): void => {
            handleForm({})
          }}
        >
          <PlusIcon />
          <Spacer width={10} />
          <Typography
            variant="subtitle-1"
            style={{ color: 'var(--color-base-colors-blue-700)' }}
            text={title}
          />
        </Flex>
        <Spacer height={24} />

        <div className={styles.container}>
          {list.map((item, i) => (
            <>
              <Flex
                key={item.id}
                justifyBetween
                itemsCenter
                className={styles.item}
              >
                <Typography
                  variant="subtitle-1"
                  text={item.name_i18n}
                />
                <Flex itemsCenter>
                  <Button
                    iconOnly
                    leadingIcon="edit-02"
                    variant="outlined"
                    color="neutral"
                    disabled={fetching && styles.disabled}
                    onClick={(): void => handleForm(item)}
                  />
                  <Spacer width={10} />
                  <Button
                    iconOnly
                    leadingIcon="trash-01"
                    variant="outlined"
                    color="danger"
                    disabled={fetching && styles.disabled}
                    onClick={(): void => onDelete(item)}
                  />
                </Flex>
              </Flex>
              {list?.length !== i + 1 && <Spacer height={16} />}
            </>
          ))}
        </div>
      </div>

      <Modal
        open={isOpen}
        onClose={(): void => setIsOpen(false)}
        header={{ title: headerTitle, withClose: true }}
      >
        {form}
      </Modal>
    </>
  )
}

export default TodoList
