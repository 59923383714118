import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  errorMsg: '',
}

type TInitState = typeof initialState

export default function reducer(state = initialState, { type, payload }: Action): TInitState {
  switch (type) {
    case actions.RESET_STATES:
      return {
        fetching: false,
        errorMsg: '',
      }

    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_PENDING:
    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_PENDING:
      return {
        fetching: true,
        errorMsg: '',
      }

    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_FULFILLED:
    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_FULFILLED:
      return {
        errorMsg: '',
        fetching: false,
      }

    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        errorMsg: handleResponseErr(payload),
        fetching: false,
      }

    case actions.ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD_REJECTED:
      return {
        errorMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
