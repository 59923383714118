export const REVIEW_CYCLE_START_PUT = 'REVIEW_CYCLE_START_PUT'
export const REVIEW_CYCLE_START_PUT_PENDING = 'REVIEW_CYCLE_START_PUT_PENDING'
export const REVIEW_CYCLE_START_PUT_FULFILLED = 'REVIEW_CYCLE_START_PUT_FULFILLED'
export const REVIEW_CYCLE_START_PUT_REJECTED = 'REVIEW_CYCLE_START_PUT_REJECTED'

export const EMPLOYEE_REVIEW_RATING_PUT = 'EMPLOYEE_REVIEW_RATING_PUT'
export const EMPLOYEE_REVIEW_RATING_PUT_PENDING = 'EMPLOYEE_REVIEW_RATING_PUT_PENDING'
export const EMPLOYEE_REVIEW_RATING_PUT_FULFILLED = 'EMPLOYEE_REVIEW_RATING_PUT_FULFILLED'
export const EMPLOYEE_REVIEW_RATING_PUT_REJECTED = 'EMPLOYEE_REVIEW_RATING_PUT_REJECTED'

export const REVIEW_CYCLES_COMPLETE_PUT = 'REVIEW_CYCLES_COMPLETE_PUT'
export const REVIEW_CYCLES_COMPLETE_PUT_PENDING = 'REVIEW_CYCLES_COMPLETE_PUT_PENDING'
export const REVIEW_CYCLES_COMPLETE_PUT_FULFILLED = 'REVIEW_CYCLES_COMPLETE_PUT_FULFILLED'
export const REVIEW_CYCLES_COMPLETE_PUT_REJECTED = 'REVIEW_CYCLES_COMPLETE_PUT_REJECTED'

export const REVIEW_CYCLES_REVIEW_RESULTS_SHARE = 'REVIEW_CYCLES_REVIEW_RESULTS_SHARE'
export const REVIEW_CYCLES_REVIEW_RESULTS_SHARE_PENDING =
  'REVIEW_CYCLES_REVIEW_RESULTS_SHARE_PENDING'
export const REVIEW_CYCLES_REVIEW_RESULTS_SHARE_FULFILLED =
  'REVIEW_CYCLES_REVIEW_RESULTS_SHARE_FULFILLED'
export const REVIEW_CYCLES_REVIEW_RESULTS_SHARE_REJECTED =
  'REVIEW_CYCLES_REVIEW_RESULTS_SHARE_REJECTED'

export const REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE = 'REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE'
export const REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_PENDING =
  'REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_PENDING'
export const REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_FULFILLED =
  'REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_FULFILLED'
export const REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_REJECTED =
  'REVIEW_CYCLES_REVIEW_RESULTS_BULK_SHARE_REJECTED'

export const REVIEW_CYCLES_REMINDER_REVIEWER = 'REVIEW_CYCLES_REMINDER_REVIEWER'
export const REVIEW_CYCLES_REMINDER_REVIEWER_PENDING = 'REVIEW_CYCLES_REMINDER_REVIEWER_PENDING'
export const REVIEW_CYCLES_REMINDER_REVIEWER_FULFILLED = 'REVIEW_CYCLES_REMINDER_REVIEWER_FULFILLED'
export const REVIEW_CYCLES_REMINDER_REVIEWER_REJECTED = 'REVIEW_CYCLES_REMINDER_REVIEWER_REJECTED'

export const REVIEW_CYCLES_LOAD = 'REVIEW_CYCLES_LOAD'
export const REVIEW_CYCLES_LOAD_PENDING = 'REVIEW_CYCLES_LOAD_PENDING'
export const REVIEW_CYCLES_LOAD_FULFILLED = 'REVIEW_CYCLES_LOAD_FULFILLED'
export const REVIEW_CYCLES_LOAD_REJECTED = 'REVIEW_CYCLES_LOAD_REJECTED'

export const REVIEW_CYCLES_EXPORT = 'REVIEW_CYCLES_EXPORT'
export const REVIEW_CYCLES_EXPORT_PENDING = 'REVIEW_CYCLES_EXPORT_PENDING'
export const REVIEW_CYCLES_EXPORT_FULFILLED = 'REVIEW_CYCLES_EXPORT_FULFILLED'
export const REVIEW_CYCLES_EXPORT_REJECTED = 'REVIEW_CYCLES_EXPORT_REJECTED'

export const EXPORT_EMPLOYEE_REVIEW = 'EXPORT_EMPLOYEE_REVIEW'
export const EXPORT_EMPLOYEE_REVIEW_PENDING = 'EXPORT_EMPLOYEE_REVIEW_PENDING'
export const EXPORT_EMPLOYEE_REVIEW_FULFILLED = 'EXPORT_EMPLOYEE_REVIEW_FULFILLED'
export const EXPORT_EMPLOYEE_REVIEW_REJECTED = 'EXPORT_EMPLOYEE_REVIEW_REJECTED'

export const NORMAL_REVIEW_CYCLES_CREATE = 'REVIEW_CYCLES_CREATE'
export const NORMAL_REVIEW_CYCLES_CREATE_PENDING = 'REVIEW_CYCLES_CREATE_PENDING'
export const NORMAL_REVIEW_CYCLES_CREATE_FULFILLED = 'REVIEW_CYCLES_CREATE_FULFILLED'
export const NORMAL_REVIEW_CYCLES_CREATE_REJECTED = 'REVIEW_CYCLES_CREATE_REJECTED'

export const REVIEW_CYCLES_SHOW = 'REVIEW_CYCLES_SHOW'
export const REVIEW_CYCLES_SHOW_PENDING = 'REVIEW_CYCLES_SHOW_PENDING'
export const REVIEW_CYCLES_SHOW_FULFILLED = 'REVIEW_CYCLES_SHOW_FULFILLED'
export const REVIEW_CYCLES_SHOW_REJECTED = 'REVIEW_CYCLES_SHOW_REJECTED'

export const REVIEW_CYCLES_SHOW_SUMMARY = 'REVIEW_CYCLES_SHOW_SUMMARY'
export const REVIEW_CYCLES_SHOW_SUMMARY_PENDING = 'REVIEW_CYCLES_SHOW_SUMMARY_PENDING'
export const REVIEW_CYCLES_SHOW_SUMMARY_FULFILLED = 'REVIEW_CYCLES_SHOW_SUMMARY_FULFILLED'
export const REVIEW_CYCLES_SHOW_SUMMARY_REJECTED = 'REVIEW_CYCLES_SHOW_SUMMARY_REJECTED'

export const CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS = 'CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS'
export const CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_PENDING =
  'CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_PENDING'
export const CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_FULFILLED =
  'CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_FULFILLED'
export const CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_REJECTED =
  'CYCLES_SHOW_SUMMARY_EMPLOYEE_REVIEWS_REJECTED'

export const REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY = 'REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY'
export const REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_PENDING =
  'REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_PENDING'
export const REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_FULFILLED =
  'REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_FULFILLED'
export const REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_REJECTED =
  'REVIEW_CYCLES_SHOW_EMPLOYEE_SUMMARY_REJECTED'

export const REVIEW_CYCLES_UPDATE = 'REVIEW_CYCLES_UPDATE'
export const REVIEW_CYCLES_UPDATE_PENDING = 'REVIEW_CYCLES_UPDATE_PENDING'
export const REVIEW_CYCLES_UPDATE_FULFILLED = 'REVIEW_CYCLES_UPDATE_FULFILLED'
export const REVIEW_CYCLES_UPDATE_REJECTED = 'REVIEW_CYCLES_UPDATE_REJECTED'

export const REVIEW_CYCLES_DELETE = 'REVIEW_CYCLES_DELETE'
export const REVIEW_CYCLES_DELETE_PENDING = 'REVIEW_CYCLES_DELETE_PENDING'
export const REVIEW_CYCLES_DELETE_FULFILLED = 'REVIEW_CYCLES_DELETE_FULFILLED'
export const REVIEW_CYCLES_DELETE_REJECTED = 'REVIEW_CYCLES_DELETE_REJECTED'

export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE = 'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_PENDING =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_PENDING'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FULFILLED =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FULFILLED'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_REJECTED =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_REJECTED'

export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_PENDING =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_PENDING'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_FULFILLED =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_FULFILLED'
export const REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_REJECTED =
  'REVIEW_CYCLES_EMPLOYEE_REVIEW_UPDATE_FEEDBACK_REJECTED'

export const PAGINATION_RESET = 'PAGINATION_RESET'
export const REVIEW_CYCLES_SUMMARY_RESET = 'REVIEW_CYCLES_SUMMARY_RESET'
export const RESET_EMPLOYEE_REVIEWS = 'RESET_EMPLOYEE_REVIEWS'
export const RESET_EMPLOYEE_REVIEWS_FETCHING = 'RESET_EMPLOYEE_REVIEWS_FETCHING'
export const RESET_EMPLOYEE_REVIEW_SUMMARY = 'RESET_EMPLOYEE_REVIEW_SUMMARY'
