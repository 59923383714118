import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export const getEmployeeAttendanceDetails = <T extends Record<string, unknown>>(
  id: number,
  params: T,
  fromRequests?: boolean,
): Action => {
  const url = `/${API_VERSION_TWO}/attendance/employees/${id}/records`
  return {
    type: fromRequests
      ? actions.LOAD_EMPLOYEE_REQUESTS_DETAILS
      : actions.LOAD_EMPLOYEE_ATTENDANCE_DETAILS,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const resetAttendanceRecords = <T extends Record<string, unknown>>(state?: T): Action => {
  return {
    type: actions.RESET_ATTENDANCE_RECORD,
    payload: state,
  }
}

export const getEmployeeRestrictionConfiguration = (id: number): Action => {
  const url = `/${API_VERSION_TWO}/employees/${id}/configurations/punch_restriction`
  return {
    type: actions.GET_EMPLOYEE_RESTRICTION_CONFIGURATION,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const resetEmployeeInformation = (): Action => {
  return {
    type: actions.RESET_EMPLOYEE_INFORMATION,
  }
}
