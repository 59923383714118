import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@jisr-hr/ds'
import { ReactComponent as CalendarIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/calendar.svg'
import { ReactComponent as HeartIcon } from 'assets/images/Heart.svg'
import I18n from 'translations/i18n'

import InfoBadge from '../Info/components/InfoBadge'
import styles from './styles.module.css'

export const RequestType = ({ items }) => {
  return (
    <Flex
      itemsCenter
      className={styles.badge}
    >
      {items.map((item, i) => (
        <InfoBadge
          key={i}
          label={item}
          type="gray"
        />
      ))}
    </Flex>
  )
}

RequestType.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
}

export const list = [
  { icon: <CalendarIcon />, value: I18n.t('Single'), label: I18n.t('Departure Date') },
  { icon: <CalendarIcon />, value: '3 months', label: I18n.t('Return Date') },
  {
    icon: <HeartIcon />,
    value: <RequestType items={['Mohsen Saleh', 'Sami Saleh']} />,
    label: I18n.t('Family members (2)', { number: 2 }),
  },
]
