import { FC, useEffect } from 'react'
import { Loader } from 'components/global/JisrLoader/JisrLoader'
import { shallowEqual } from 'react-redux'
import { useDispatch, useSelector } from 'utils/hooks'
import { useTransition, config } from 'react-spring'
import { Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { paymentMethodsLoad } from 'redux/setting/payrollManagement/paymentMethods/actionCreators'
import { itemPriceLoad } from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'

import UnlinkedPaymentCard from './components/UnlinkedPaymentCard'
import LinkedPaymentCard from './components/LinkedPaymentCard'
import ManagePaymentBanks from './ManagePaymentBanks'
import PaymentMethodSetup from './PaymentMethodSetup'
import AddNewPaymentConfigModal from './components/AddNewPaymentConfigModal'
import MudadPaymentMethod from './MudadPaymentMethod'
import MudadTermsSubscriptionsModal from './components/MudadTermsSubsicriptionsModal'

const PaymentMethods: FC = () => {
  const dispatch = useDispatch()

  const fetching = useSelector((s) => s.paymentMethods.fetching)
  const linked_list = useSelector((s) => s.paymentMethods.configured_payment_methods, shallowEqual)
  const unlinked_list = useSelector((s) => s.paymentMethods.pending_payment_methods, shallowEqual)

  const animatedLinkedList = useTransition(linked_list || [], {
    keys: (item) => Number(item?.id),
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: config.wobbly,
    trail: 150,
  })

  const animatedUnLinkedList = useTransition(unlinked_list || [], {
    keys: (item) => Number(item?.id),
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: config.wobbly,
    trail: 150,
  })

  useEffect(() => {
    dispatch(paymentMethodsLoad())
    dispatch(itemPriceLoad())
  }, [])

  return (
    <div>
      {fetching && !unlinked_list?.length && !linked_list?.length && <Loader />}
      <div className={mainStyles.grid_card_container}>
        {animatedLinkedList((style, paymentConfiguration) => {
          return (
            <LinkedPaymentCard
              style={style}
              paymentConfiguration={paymentConfiguration}
              key={paymentConfiguration.id}
            />
          )
        })}
      </div>

      {!fetching && !!unlinked_list?.length && !!linked_list?.length && (
        <Typography
          variant="interface/default/sm"
          style={{ margin: '32px 0 16px', color: 'var(--color-base-colors-grey-800)' }}
          text={i18n.t('other_commercial_registrations')}
        />
      )}

      <div className={mainStyles.grid_card_container}>
        {animatedUnLinkedList((style, paymentConfiguration) => {
          return (
            <UnlinkedPaymentCard
              style={style}
              paymentConfiguration={paymentConfiguration}
              key={paymentConfiguration.id}
            />
          )
        })}
      </div>

      {/* @note modals */}
      <ManagePaymentBanks />
      <PaymentMethodSetup />
      <AddNewPaymentConfigModal />
      <MudadPaymentMethod />
      <MudadTermsSubscriptionsModal />
    </div>
  )
}

export default PaymentMethods
