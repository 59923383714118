import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationTypes } from 'types/sharedResponse'
import { AutomationProps } from 'containers/authorised/Automations/Types'
import * as actions from './actions'

type StateTypes = {
  fetching: boolean
  loading: boolean
  isFetched: boolean
  automations: AutomationProps[]
  variables: {
    title: string
    options: { [key: string]: string }
  }[]
  automation: AutomationProps
  pagination?: PaginationTypes
}

const DEFAULT_STATE: StateTypes = {
  fetching: false,
  loading: false,
  isFetched: false,
  automation: {} as AutomationProps,
  automations: [],
  variables: [],
}

type AutomationType = typeof DEFAULT_STATE

export default function reducer(state = DEFAULT_STATE, action: Action): AutomationType {
  switch (action.type) {
    case actions.AUTOMATION_LIST_LOAD_PENDING:
    case actions.AUTOMATION_SHOW_PENDING:
    case actions.AUTOMATION_CREATE_PENDING:
    case actions.AUTOMATION_UPDATE_PENDING:
    case actions.AUTOMATION_DELETE_PENDING:
    case actions.AUTOMATION_BULK_UPSERT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.AUTOMATION_ACTIONS_VARIABLES_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.AUTOMATION_LIST_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        automations: [
          ...(action.payload.data.data?.pagination?.previous_page ? state.automations : []),
          ...action.payload.data.data?.automations,
        ],
        fetching: false,
        isFetched: true,
      }
    case actions.AUTOMATION_SHOW_FULFILLED:
      return {
        ...state,
        automation: action.payload.data.data.automation,
        fetching: false,
      }

    case actions.AUTOMATION_CREATE_FULFILLED:
    case actions.AUTOMATION_UPDATE_FULFILLED:
    case actions.AUTOMATION_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      }

    case actions.AUTOMATION_BULK_UPSERT_FULFILLED:
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      }

    case actions.AUTOMATION_ACTIONS_VARIABLES_LOAD_FULFILLED:
      return {
        ...state,
        variables: action.payload.data.data.options,
        loading: false,
      }

    case actions.AUTOMATION_LIST_LOAD_REJECTED:
    case actions.AUTOMATION_SHOW_REJECTED:
    case actions.AUTOMATION_CREATE_REJECTED:
    case actions.AUTOMATION_UPDATE_REJECTED:
    case actions.AUTOMATION_DELETE_REJECTED:
    case actions.AUTOMATION_BULK_UPSERT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.AUTOMATION_ACTIONS_VARIABLES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
