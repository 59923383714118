import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  can_edit: true,
  employee_all: [],
  employees: [],
  employee: {
    job_title: {},
    employees: {
      job_title: {},
      department: {},
      line_manager: {},
      sponsor: {},
      location: {},
      shift: {},
      employment_type: {},
      employee_all: [],
    },
    employee: {
      pending_changes: {
        changeset: {},
      },
    },
  },
  employee_delete_check: {},
  fetching: false,
  checking: false,
}

let changes = null

export default function employeeReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let updatedRecord = {}
  let employees = []
  switch (action.type) {
    case 'EMPLOYEE_CREATE_PENDING':
    case 'EMPLOYEE_DELETE_PENDING':
    case 'EMPLOYEE_INVITE_PENDING':
    case 'EMPLOYEES_LOAD_PENDING':
    case 'CHANGE_LANGUAGE_PENDING':
    case 'EMPLOYEE_GOSI_DEDUCTION_UPDATE_PENDING':
    case 'EMPLOYEES_NEW_ID_LOAD_PENDING':
    case 'GET_ALL_EMPLOYEES_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'EMPLOYEE_BULK_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        bulk_update_fetching: true,
      }

    case 'EMPLOYEE_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        checking: true,
      }
    case 'EMPLOYEE_EDIT_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        employee: DEFAULT_STATE.employee,
      }
    case 'EMPLOYEE_DELETE_CHECKER_PENDING':
      return {
        ...state,
        checking: true,
      }
    case 'EMPLOYEE_CREATE_REJECTED':
    case 'EMPLOYEE_EDIT_REJECTED':
    case 'EMPLOYEE_UPDATE_REJECTED':
    case 'EMPLOYEE_DELETE_REJECTED':
    case 'EMPLOYEE_INVITE_REJECTED':
    case 'EMPLOYEES_LOAD_REJECTED':
    case 'CHANGE_LANGUAGE_REJECTED':
    case 'EMPLOYEE_GOSI_DEDUCTION_UPDATE_REJECTED':
    case 'UNDO_OFFBOARDING_REJECTED':
    case 'EMPLOYEES_NEW_ID_LOAD_REJECTED':
    case 'EMPLOYEE_DELETE_CHECKER_REJECTED':
    case 'EMPLOYEE_BULK_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        checking: false,
        bulk_update_fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEES_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        employees: action.payload.data.data.employees,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_DELETE_CHECKER_FULFILLED':
      changes = {
        employee_delete_check: action.payload.data.data,
        checking: false,
      }
      return { ...state, ...changes }

    case 'GET_ALL_EMPLOYEES_LOAD_FULFILLED':
      changes = {
        employee_all: [...action.payload.data.data.employees],
        employee_all_pagination: action.payload.data.data?.pagination ?? {},
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_INVITE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return { ...state, fetching: false }

    case 'EMPLOYEE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return { ...state, fetching: false }

    case 'EMPLOYEE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.data.message)
      return { ...state, fetching: false }

    case 'EMPLOYEE_EDIT_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        ...action.payload.data.data,
        employee: {
          ...action.payload.data.data.employee,
          ...action.payload.data.data.employee.employees,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'UNDO_OFFBOARDING_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      return { ...state, fetching: false }

    case 'EMPLOYEE_GOSI_DEDUCTION_UPDATE_FULFILLED':
    case 'EMPLOYEES_NEW_ID_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'RESET':
      changes = {
        employees: [],
        fetching: false,
      }
      return { ...state, ...changes }
    case 'EMPLOYEE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employees = [...state.employees]
      updatedRecord = {
        ...payload.data.data.employee,
        ...payload.data.data.employee.employees,
      }
      changes = {
        employees: updateOneListOfRecords(employees, updatedRecord),
        employee: updatedRecord,
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
        checking: false,
      }
      return { ...state, ...changes }

    case 'CHANGE_LANGUAGE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return { ...state }

    case 'EMPLOYEE_BULK_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        bulk_update_fetching: false,
      }

    default:
      return state
  }
}
