import { AccordionGroup, Typography } from '@jisr-hr/ds-beta'
import { OnboardingOverviewContractRes } from 'apis/PeopleManagement/OnboardingEmployee/overview/useGetOnboardingOverview.type'
import { contentTranslationKeysMapping, translationKeysMapping } from './helper'
import './style.css'

export const EmpInfoTemplate = ({
  overviewDetails,
}: {
  overviewDetails: OnboardingOverviewContractRes | undefined
}) => {
  const formSections = overviewDetails
    ? (Object.keys(overviewDetails) as Array<keyof OnboardingOverviewContractRes>)
    : []
  return (
    <div className="max-w-[800px] mx-auto">
      <AccordionGroup
        accordionList={formSections.map((section) => {
          const sectionDetails =
            formSections.length && overviewDetails ? overviewDetails[section] : []
          return {
            title: translationKeysMapping[section],
            children: (
              <div className="px-5">
                <ul className="list-disc px-2 space-y-1 custom-bullet-color">
                  {sectionDetails?.map((item, index) => (
                    <li key={index}>
                      <Typography
                        text={contentTranslationKeysMapping[item]}
                        textColor="color/fg/lighter"
                        variant="subtitle-2"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ),
          }
        })}
        color="white"
        isRounded
        size="medium"
        spacing="md"
      />
    </div>
  )
}
