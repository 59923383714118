import { useMemo, useState, useCallback, memo } from 'react'
import { Button, Containers, Divider, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { isEmpty, cloneDeep } from 'lodash'
import { SelectField, DatePickerDSField, NewTextInputField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import { GENDER } from 'components/global/constants'
import { required } from 'components/global/form/FormValidations'
import { uniqId } from 'utils/uiHelpers'
import {
  DependentDataType,
  InitiateFlightTicketPolicyDependentRequestTypes,
  InitiateFlightTicketPolicyRequestTypes,
} from '../../../../types/flightTicketRequest'
import DependentCard from './DependentCard'

export type DependentWithAddedKeys = DependentDataType & {
  relationships_key?: string
  listId?: string
}

type FlightTicketRequestDependentFormPropTypes = {
  dependentsData?: InitiateFlightTicketPolicyDependentRequestTypes
  flightTicketPolicyData?: InitiateFlightTicketPolicyRequestTypes
  addedDependent: DependentWithAddedKeys[]
  setAddedDependent: React.Dispatch<React.SetStateAction<DependentWithAddedKeys[]>>
}

type DependentFieldNameType =
  | 'dependent_relation'
  | 'dependent_date_of_birth'
  | 'dependent_passport_input'
  | 'dependent_gender'
  | 'dependent_document_id_input'

type RelationshipKeys = keyof InitiateFlightTicketPolicyDependentRequestTypes['relationships_i18n']

const FlightTicketRequestDependentForm = ({
  dependentsData,
  flightTicketPolicyData,
  addedDependent,
  setAddedDependent,
}: FlightTicketRequestDependentFormPropTypes): JSX.Element => {
  const [addDependent, setAddDependent] = useState(false)
  const [dependentsCount, setDependentsCount] = useState(0)
  const [editDependentId, setEditDependentId] = useState<string>('')
  const defaultDisabledFields = {
    dependent_relation: true,
    dependent_date_of_birth: true,
    dependent_passport_input: true,
    dependent_gender: true,
    dependent_document_id_input: true,
  }
  const [disabledFields, setDisabledFields] = useState(defaultDisabledFields)

  const { change, batch } = useForm()
  const { values } = useFormState()
  const {
    exception_allowed,
    no_of_dependents_covered,
    dependent_coverage_percentage,
    dependent_age,
    dependent_restriction_operator,
    max_no_of_dependents_covered,
  } = (flightTicketPolicyData as InitiateFlightTicketPolicyRequestTypes) || {}

  const dependentList = useMemo(() => {
    const addNewDependentTitel = {
      id: 1,
      label: (
        <Typography
          text={I18n.t('action.add_dependent')}
          variant="body-new/semibold"
        />
      ),
    }
    const depList = dependentsData?.dependents
      ?.filter(
        (dependent) =>
          !addedDependent.some((dep) => dep.family_detail_id === dependent.family_detail_id),
      )
      ?.map((dependent) => {
        return {
          id: dependent.family_detail_id,
          label: dependent.name_i18n,
        }
      })
    return [...(depList ?? []), addNewDependentTitel]
  }, [dependentsData, addedDependent])

  const dependentRelationDetails = useMemo(() => {
    return Object.keys(dependentsData?.relationships_i18n || {})?.map((item) => {
      return {
        id: item,
        label: dependentsData?.relationships_i18n[item as RelationshipKeys],
      }
    })
  }, [dependentsData])

  const changeMultipleFields = useCallback((fields: Record<string, unknown>): void => {
    batch(() => {
      Object.keys(fields).forEach((field) => {
        change(field, fields[field])
      })
    })
  }, [])

  const handleSaveDependent = useCallback((): void => {
    if (editDependentId) {
      const editDependent = addedDependent?.map((dep) => {
        if (dep.listId === editDependentId) {
          return {
            ...dep,
            name_i18n: values.dependent_full_name_text_field,
            relation:
              dependentsData?.relationships_i18n[values.dependent_relation as RelationshipKeys] ??
              '',
            relationships_key: values.dependent_relation,
            date_of_birth: values.dependent_date_of_birth,
            passport_number: values.dependent_passport_input,
            gender: values.dependent_gender,
            id_number: values.dependent_document_id_input,
          }
        }

        return dep
      })

      setAddedDependent(editDependent)
      setEditDependentId('')
    } else {
      const depList = cloneDeep(dependentsData?.dependents)
      const selectedDependent = depList?.find(
        (dependent) => dependent.family_detail_id === values.dependent_full_name,
      ) as DependentWithAddedKeys

      if (!isEmpty(selectedDependent)) {
        selectedDependent.relation =
          dependentsData?.relationships_i18n[values.dependent_relation as RelationshipKeys] ?? ''
        selectedDependent.date_of_birth = values.dependent_date_of_birth
        selectedDependent.passport_number = values.dependent_passport_input
        selectedDependent.gender = values.dependent_gender
        selectedDependent.id_number = values.dependent_document_id_input
        selectedDependent.relationships_key = values.dependent_relation
        setAddedDependent((prev) => [selectedDependent, ...prev])
      } else {
        const newDependent = {
          name_i18n: values.dependent_full_name_text_field,
          relation:
            dependentsData?.relationships_i18n[values.dependent_relation as RelationshipKeys] ?? '',
          relationships_key: values.dependent_relation,
          date_of_birth: values.dependent_date_of_birth,
          passport_number: values.dependent_passport_input,
          gender: values.dependent_gender,
          id_number: values.dependent_document_id_input,
          listId: uniqId(),
        }
        setAddedDependent((prev) => [newDependent, ...prev])
      }
      setDependentsCount(addedDependent.length + 1)
    }
    setAddDependent(false)
    changeMultipleFields({
      dependent_full_name: '',
      dependent_relation: '',
      dependent_date_of_birth: '',
      dependent_passport_input: '',
      dependent_gender: '',
      dependent_document_id_input: '',
      dependent_full_name_text_field: '',
    })
    setDisabledFields(defaultDisabledFields)
  }, [editDependentId, addedDependent, dependentsData, values])

  const handleDeleteDependent = useCallback(
    (dependentName: string): void => {
      const filteredDep = addedDependent?.filter((dep) => dep.name_i18n !== dependentName)
      setAddedDependent(filteredDep)
      if (dependentsCount >= 1) {
        setDependentsCount(dependentsCount - 1)
      }
    },
    [addedDependent, dependentsCount],
  )

  const handleEditDependent = useCallback(
    (listId: string | undefined): void => {
      setAddDependent(true)
      const filteredDep = addedDependent.find((dep) => dep?.listId === listId)
      setEditDependentId(listId ?? '')
      changeMultipleFields({
        dependent_full_name: 1,
        dependent_full_name_text_field: filteredDep?.name_i18n,
        dependent_relation: filteredDep?.relation,
        dependent_date_of_birth: filteredDep?.date_of_birth,
        dependent_passport_input: filteredDep?.passport_number,
        dependent_gender: filteredDep?.gender,
        dependent_document_id_input: filteredDep?.id_number,
      })
      setDisabledFields({
        dependent_relation: false,
        dependent_date_of_birth: false,
        dependent_passport_input: false,
        dependent_gender: false,
        dependent_document_id_input: false,
      })
    },
    [addedDependent],
  )

  const handleDependentFieldsDisable = useCallback(
    (fieldName: DependentFieldNameType): boolean => {
      if (disabledFields[fieldName] && values.dependent_full_name && !values[fieldName])
        return false
      return disabledFields[fieldName]
    },
    [values, disabledFields],
  )

  const handleDependentSelect = useCallback(
    (val: number): void => {
      // To check its selected new dependent or not (code 1 is for only if user selected "add new dependent")
      if (val !== 1) {
        const selectedDependent = dependentsData?.dependents.find(
          (dependent) => dependent.family_detail_id === val,
        )
        changeMultipleFields({
          dependent_full_name: val,
          dependent_relation: selectedDependent?.relation,
          dependent_date_of_birth: selectedDependent?.date_of_birth,
          dependent_passport_input: selectedDependent?.passport_number,
          // needed to fix when backend will send response in object with gender id
          dependent_gender: selectedDependent?.gender,
          dependent_document_id_input: selectedDependent?.id_number,
        })
      } else {
        setDisabledFields({
          dependent_relation: false,
          dependent_date_of_birth: false,
          dependent_passport_input: false,
          dependent_gender: false,
          dependent_document_id_input: false,
        })
        changeMultipleFields({
          dependent_full_name: val,
          dependent_relation: '',
          dependent_date_of_birth: '',
          dependent_passport_input: '',
          dependent_gender: '',
          dependent_document_id_input: '',
        })
      }
    },
    [dependentsData],
  )

  const handleCancelDependent = useCallback((): void => {
    setAddDependent(false)
    if (dependentsCount >= 1 && !editDependentId) {
      setDependentsCount(dependentsCount - 1)
    }
    setDisabledFields(defaultDisabledFields)
    changeMultipleFields({
      dependent_full_name: '',
      dependent_relation: '',
      dependent_date_of_birth: '',
      dependent_passport_input: '',
      dependent_gender: '',
      dependent_document_id_input: '',
      dependent_full_name_text_field: '',
    })
  }, [dependentsCount, editDependentId])

  const handleDependentSaveBtn = (): boolean => {
    return (
      (!values.dependent_full_name ||
        (values.dependent_full_name && values.dependent_full_name === 1)) &&
      ![
        'dependent_full_name_text_field',
        'dependent_relation',
        'dependent_date_of_birth',
        'dependent_passport_input',
        'dependent_gender',
        'dependent_document_id_input',
      ].every((field) => values[field])
    )
  }

  return (
    <Containers
      color="white"
      borderRadius="sm"
      border
      width="100%"
      height="auto"
      styles={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 16 }}
    >
      <Flex flexCol>
        <Flex
          justifyBetween
          className="p-4"
        >
          <Typography
            text={I18n.t('label.dependents_count', {
              count: dependentsCount,
            })}
            variant="title-1"
          />
          <Button
            variant="outlined"
            color="neutral"
            label={I18n.t('action.add_dependent')}
            size="small"
            onClick={(): void => {
              setAddDependent(true)
              setDependentsCount((prev) => prev + 1)
              setEditDependentId('')
            }}
            leadingIcon="plus"
            disabled={
              addDependent ||
              (!exception_allowed && no_of_dependents_covered <= dependentsCount) ||
              (exception_allowed && dependentsCount >= max_no_of_dependents_covered)
            }
          />
        </Flex>
        <Divider />
      </Flex>

      {addDependent && (
        <Flex
          flexCol
          className="px-4 gap-4"
        >
          <Flex
            flexCol
            style={{ gap: 6 }}
          >
            <Typography
              variant="interface/medium/sm"
              text={I18n.t('label.full_name')}
            />
            {values.dependent_full_name === 1 ? (
              <NewTextInputField
                name="dependent_full_name_text_field"
                placeholder={I18n.t('label.full_name')}
                validate={required}
                testId="dependent_full_name_text_field"
                size="md"
              />
            ) : (
              <SelectField
                // @ts-expect-error // select field needs to be migrated to TS
                options={dependentList}
                valueKey="id"
                labelKey="label"
                name="dependent_full_name"
                validate={required}
                testId="dependent_full_name"
                placeholder={I18n.t('label.full_name')}
                onChange={handleDependentSelect}
              />
            )}
          </Flex>

          <Flex style={{ gap: 12 }}>
            <Flex
              flexCol
              style={{ gap: 8, flex: 1 }}
            >
              <Typography
                variant="interface/medium/sm"
                text={I18n.t('label.relation')}
              />
              <SelectField
                // @ts-expect-error // select field needs to be migrated to TS
                options={dependentRelationDetails}
                valueKey="id"
                labelKey="label"
                name="dependent_relation"
                validate={required}
                testId="dependent_relation"
                placeholder={I18n.t('label.select_relation')}
                disabled={handleDependentFieldsDisable('dependent_relation')}
                onChange={(): void => {
                  setDisabledFields({ ...disabledFields, dependent_relation: false })
                }}
              />
            </Flex>

            <div className="flex-1">
              <DatePickerDSField
                dateFormat="yyyy-MM-dd"
                validate={required}
                name="dependent_date_of_birth"
                label={I18n.t('label.date_of_birth')}
                triggerType="input"
                textFieldProps={{
                  disabled: handleDependentFieldsDisable('dependent_date_of_birth'),
                  placeholder: I18n.t('select_date'),
                }}
                onChange={(): void => {
                  setDisabledFields({ ...disabledFields, dependent_date_of_birth: false })
                }}
              />
            </div>
          </Flex>

          <Flex
            flexCol
            style={{ gap: 6 }}
          >
            <Typography
              variant="interface/medium/sm"
              text={I18n.t('label.passport_number')}
            />
            <NewTextInputField
              name="dependent_passport_input"
              placeholder={I18n.t('placeholder.add_passport_number')}
              validate={required}
              testId="dependent_passport_input"
              size="md"
              disabled={handleDependentFieldsDisable('dependent_passport_input')}
              onChange={(): void => {
                setDisabledFields({ ...disabledFields, dependent_passport_input: false })
              }}
            />
          </Flex>
          <Flex className="gap-4">
            <Flex
              flexCol
              className="flex-1 gap-1.5"
            >
              <Typography
                variant="interface/medium/sm"
                text={I18n.t('label.gender')}
              />
              <SelectField
                // @ts-expect-error // select field needs to be migrated to TS
                options={GENDER}
                valueKey="id"
                labelKey="name"
                name="dependent_gender"
                validate={required}
                testId="dependent_gender"
                placeholder={I18n.t('placeholder.select_gender')}
                disabled={handleDependentFieldsDisable('dependent_gender')}
                onChange={(): void => {
                  setDisabledFields({ ...disabledFields, dependent_gender: false })
                }}
              />
            </Flex>

            <Flex
              flexCol
              className="flex-1 gap-1.5"
            >
              <Typography
                variant="interface/medium/sm"
                text={I18n.t('label.document_number')}
              />
              <NewTextInputField
                name="dependent_document_id_input"
                placeholder={I18n.t('placehoder.add_document_id')}
                validate={required}
                testId="dependent_document_id_input"
                size="md"
                disabled={handleDependentFieldsDisable('dependent_document_id_input')}
                onChange={(): void => {
                  setDisabledFields({ ...disabledFields, dependent_document_id_input: false })
                }}
              />
            </Flex>
          </Flex>
          <Flex
            justifyEnd
            className="gap-3"
          >
            <Button
              label={I18n.t('label.cancel')}
              onClick={handleCancelDependent}
              variant="outlined"
              color="neutral"
              size="medium"
            />
            <Button
              label={I18n.t('label.save')}
              onClick={handleSaveDependent}
              variant="filled"
              color="primary"
              size="medium"
              disabled={handleDependentSaveBtn()}
            />
          </Flex>
          <Divider />
        </Flex>
      )}

      <Flex flexCol>
        {addedDependent.map((dependent, index) => {
          return (
            <DependentCard
              dependent={dependent}
              handleEditDependent={handleEditDependent}
              handleDependentDelete={handleDeleteDependent}
              isException={
                exception_allowed && index < addedDependent.length - no_of_dependents_covered
              }
              addDivider={addedDependent?.length > 1 && addedDependent?.length - 1 !== index}
              dependentCoveragePercentage={dependent_coverage_percentage}
              dependentAge={dependent_age}
              dependentRestrictionOperator={dependent_restriction_operator}
            />
          )
        })}
      </Flex>
    </Containers>
  )
}

export default memo(FlightTicketRequestDependentForm)
