import { Toastr } from 'components/global/Toastr'
import { PaginationTypes } from 'types/sharedResponse'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type exportHistoryType = {
  id: string
  created_at: string
  export_type: string
  export_type_i18n: string
  event_type: string
  event_type_i18n: string
  export_format: string
  shared_employees: {
    id: string
    avatar_thumb: string
    full_name_i18n: string | null
    name_i18n: string | null
  }[]
  status: string
  status_i18n: string
  url: string | null
}

type InitialStateType = {
  fetching: boolean
  file_exports: exportHistoryType[]
  pagination: PaginationTypes
}

export const initialState: InitialStateType = {
  fetching: false,
  file_exports: [],
  pagination: {} as PaginationTypes,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.EXPORT_HISTORY_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.EXPORT_REPORT_FILE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EXPORT_HISTORY_LOAD_FULFILLED: {
      const { previous_page } = action.payload.data.data.pagination
      return {
        ...state,
        file_exports: [
          ...new Set([
            ...(previous_page ? state.file_exports : []),
            ...action.payload.data.data.file_exports,
          ]),
        ],
        pagination: action.payload.data.data.pagination,
        fetching: false,
      }
    }

    case actions.EXPORT_REPORT_FILE_FULFILLED: {
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        fetching: false,
      }
    }

    case actions.EXPORT_REPORT_FILE_REJECTED:
    case actions.EXPORT_HISTORY_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
