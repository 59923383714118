import { businessUnitType } from 'redux/reducers/businessUnitReducer'
import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function businessUnitsLoad(params?: { template?: 'public' }): Action {
  const url = `/${API_VERSION_TWO}/business_units/`
  const method = 'GET'

  return {
    type: 'BUSINESS_UNITS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function businessUnitCreate(data: businessUnitType): Action {
  const url = `/${API_VERSION_TWO}/business_units/`
  const method = 'POST'
  return {
    type: 'BUSINESS_UNIT_CREATE',
    payload: handleAPI(url, {}, method, { business_unit: data }),
  }
}

export function businessUnitUpdate(data: businessUnitType): Action {
  const url = `/${API_VERSION_TWO}/business_units/${data.id}/`
  const method = 'PUT'
  return {
    type: 'BUSINESS_UNIT_UPDATE',
    payload: handleAPI(url, {}, method, { business_unit: data }),
  }
}

export function businessUnitDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/business_units/${id}/`
  const method = 'DELETE'

  return {
    type: 'BUSINESS_UNIT_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, {}, method)
        ?.then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        })
    }),
  }
}

export function businessUnitEdit(id: number): { type: string; id: number } {
  return {
    type: 'BUSINESS_UNIT_EDIT',
    id,
  }
}

export function businessUnitEditCancel(id: number): { type: string; id: number } {
  return {
    type: 'BUSINESS_UNIT_EDIT_CANCEL',
    id,
  }
}
