import React from 'react'
import PropTypes from 'prop-types'

const Section = ({ children, padding }) => <section style={{ padding }}>{children}</section>

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  padding: PropTypes.string,
}

Section.defaultProps = {
  padding: '30px 0',
}

export default Section
