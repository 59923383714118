import { ChangeEvent, useEffect, useState } from 'react'
import { DataTab, TextInput } from '@jisr-hr/design-system'
import { Typography, Spacer, Button, Flex } from '@jisr-hr/ds'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  createJobTitles,
  deleteJobTitle,
  indexJobTitles,
  jobTitleSuggestionsLoad,
} from 'redux/organizationOnborading/jobTitles/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import I18n from 'translations/i18n'
import classnames from 'classnames'
import StepTemplate from '../../../components/StepTemplate'
import styles from './style.module.css'

type TFilter = {
  name: string
  name_ar: string
  name_i18n: string
  category_id?: number
}

const JobTitles = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const [dataInput, setDataInput] = useState('')
  const [suggestionsList, setSuggestionsList] = useState<TFilter[]>([])
  const { job_titles } = useSelector((state) => state.jobTitles)
  const { job_title_suggestions } = useSelector((state) => state.jobTitles)
  const { industry } = useSelector((state) => state.organization.organization)
  const { name_i18n: groups_name } = useSelector((state) => state.organization?.organization)

  const refreshContent = (): void => {
    dispatch(indexJobTitles())
    dispatch(organizationInfo())
  }

  const setValue = (): void => {
    dispatch(
      createJobTitles({
        job_title: [
          {
            name: dataInput,
            name_ar: dataInput,
          },
        ],
      }),
    ).then(() => {
      refreshContent()
      setDataInput('')
    })
  }

  const Delete = (id: number): void => {
    dispatch(deleteJobTitle(id)).then(() => {
      refreshContent()
    })
  }

  const handleOnkeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && dataInput.length) {
      setValue()
    }
  }

  const createFromSuggestion = (data = {}): void => {
    dispatch(
      createJobTitles({
        job_title: [data],
      }),
    ).then(() => {
      refreshContent()
    })
  }

  const createAllSuggestion = (): void => {
    dispatch(
      createJobTitles({
        job_title: suggestionsList,
      }),
    ).then(() => {
      refreshContent()
    })
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'job_titles' })

  useEffect(() => {
    refreshContent()
    dispatch(organizationInfo())
  }, [])

  useEffect(() => {
    if (industry?.id) {
      dispatch(jobTitleSuggestionsLoad(industry?.id))
    }
  }, [industry])

  useEffect(() => {
    if (Array.isArray(job_titles)) {
      const filters = job_title_suggestions
        .filter((list) => ![...job_titles.map((j) => j.name)].includes(list.name_en))
        .map((li) => ({
          name: li.name_en,
          name_ar: li.name_ar,
          name_i18n: li.name_i18n,
          category_id: li?.category_id,
        }))

      setSuggestionsList(filters)
    }
  }, [job_titles, job_title_suggestions])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="interface/default/m"
        text={I18n.t('define_job_titles_description', {
          groups_name,
        })}
      />
      <Spacer height="16px" />
      <Flex style={{ gap: 16 }}>
        <TextInput
          type="text"
          style={{ maxWidth: 680 }}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => setDataInput(e.target.value)}
          value={dataInput}
          onKeyDown={handleOnkeyDown}
          size="medium"
        />
        <Button
          autoFocus
          variant="outlined"
          color="neutral"
          label={I18n.t('add')}
          size="medium"
          onClick={setValue}
          disabled={!dataInput.length}
        />
      </Flex>

      <Spacer height="16px" />

      <Flex
        flexWrap
        className={classnames(styles.listDataTab, styles.save)}
      >
        {job_titles?.map((job, index) => (
          <div key={index}>
            <DataTab
              label={job.name_i18n}
              onClick={(): void => Delete(job.id)}
            />
          </div>
        ))}
      </Flex>
      <Spacer height="48px" />
      <Flex
        justifyBetween
        itemsCenter
        style={{ maxWidth: 682 }}
      >
        <Typography
          variant="interface/default/sm"
          text={I18n.t('usually_has_the_following_job_titles', {
            name: industry?.name_i18n,
          })}
        />
        {suggestionsList.length !== 0 && (
          <Button
            size="small"
            variant="ghost"
            trailingIcon="plus"
            onClick={(): void => createAllSuggestion()}
            label={I18n.t('add_all')}
          />
        )}
      </Flex>
      <Spacer height="4px" />
      <Flex
        flexWrap
        className={classnames(styles.listDataTab, styles.add)}
      >
        {suggestionsList.map((sugg, index) => (
          <div key={index}>
            <DataTab
              label={sugg.name_i18n}
              onClick={(): void => createFromSuggestion(sugg)}
            />
          </div>
        ))}
      </Flex>
    </StepTemplate>
  )
}

export default JobTitles
