/** **************
 *   Setting Organization Profile
 /*************** */

import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function organizationLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'GET'

  return {
    type: 'ORGANIZATION_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function getOrganizationCurrencyTypeLoad(): Action {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'GET'
  const params = { only_currency_type: true }

  return {
    type: 'GET_ORGANIZATION_CURRENCY_TYPE_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function putOrganization(data: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'PUT'
  const params = {}

  return {
    type: 'ORGANIZATION_UPDATE',
    payload: handleAPI(url, params, method, { organization: data }),
  }
}

export function organizationLogoUpdate(data: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/organizations/`
  const method = 'PUT'
  const params = {}

  return {
    type: 'ORGANIZATION_LOGO_UPDATE',
    payload: handleAPI(url, params, method, { organization: data }),
  }
}

export function organizationContactInfoUpdate(data: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/contact_informations/`
  const method = 'PUT'
  const params = {}

  return {
    type: 'ORGANIZATION_CONTACT_INFO_UPDATE',
    payload: handleAPI(url, params, method, { contact_information: data }),
  }
}
