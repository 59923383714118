/** **************
 *   Setting Organization Profile
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function nationalitiesLoad() {
  const url = `/${API_VERSION_TWO}/nationalities/`
  const method = 'GET'

  return {
    type: 'NATIONALITIES_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function banksLoad(prams = {}) {
  const url = `/${API_VERSION_TWO}/finance/banks/`
  const method = 'GET'

  return {
    type: 'BANKS_LOAD',
    payload: handleAPI(url, prams, method),
  }
}
