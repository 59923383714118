import { CSSProperties, FC } from 'react'
import { ReactComponent as InfoIcon } from 'assets/images/info_icon_black.svg'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import classNames from 'classnames'
import DSIcon from 'components/DSIcon'
import { Typography, Flex } from '@jisr-hr/ds'

export type PaymentAlertType = {
  title?: string
  description?: string
  type?: 'info' | 'error' | 'success'
  style?: CSSProperties
}

const PaymentAlert: FC<PaymentAlertType> = (props) => {
  const { title, description, type = 'info', style } = props
  return (
    <Flex
      itemsStart
      style={{ gap: 8, ...(style || {}) }}
      className={classNames(
        type === 'error' ? mainStyles.warning_alert_box : mainStyles.info_alert_box,
      )}
    >
      {type === 'success' ? (
        <DSIcon
          name="check-verified-01"
          size="md"
          styles={{ flexShrink: 0 }}
        />
      ) : (
        <InfoIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
      )}

      <div>
        {title && (
          <Typography
            variant="interface/default/sm"
            style={{ lineHeight: '20px', color: '#14171F' }}
            text={title}
          />
        )}

        {!!description && (
          <Typography
            variant="interface/default/sm"
            style={{ lineHeight: '20px', marginTop: 4, fontWeight: 400, color: '#14171F' }}
            text={description}
          />
        )}
        {props.children}
      </div>
    </Flex>
  )
}

export default PaymentAlert
