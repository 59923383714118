import { QueryKey, useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { HTTPValidationError } from 'types/httpTypes'
import { OnboardingFormBody, ValidateOnboardingEmpStepBody } from './useOnboardingEmployeeForm.type'

// ? functions

const validateOnboardingEmpStep = (
  employeeId: number,
  data: ValidateOnboardingEmpStepBody,
): Promise<{ success: boolean }> => {
  return api({
    url: `/${API_VERSION_TWO}/hr/onboarding_employees/${employeeId}/validate_submit_form_steps`,
    method: 'POST',
    data,
  })
}

const submitOnboardingEmp = (
  employeeId: number,
  data: OnboardingFormBody,
): Promise<{ success: boolean }> => {
  return api({
    url: `/${API_VERSION_TWO}/hr/onboarding_employees/${employeeId}/submit`,
    method: 'POST',
    data,
  })
}

// ? query keys

export const validateKey = (employeeId: number, data: ValidateOnboardingEmpStepBody): QueryKey => [
  'validate_onboarding_employee_step',
  employeeId,
  data,
]

export const submitForm = (employeeId: number, data: OnboardingFormBody): QueryKey => [
  'submit_onboarding_employee_form',
  employeeId,
  data,
]

// ? hooks

/**
 * @endpoint: POST: /v2/hr/onboarding_employees/:id/validate_submit_form_steps
 * @summary This hook used to validate the form steps before submitting the form
 */
export const useValidateOnboardingEmpStep = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<
    { success: boolean },
    TError,
    { id: number; data: ValidateOnboardingEmpStepBody }
  >
}): UseMutationResult<
  { success: boolean },
  TError,
  { id: number; data: ValidateOnboardingEmpStepBody }
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => validateOnboardingEmpStep(payload.id, payload?.data),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}

/**
 * @endpoint: POST: /v2/hr/onboarding_employees/:id/submit
 * @summary This hook used to submit the onboarding form and create requests for the employee
 */
export const useCreateOnboardingEmp = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<
    { success: boolean },
    TError,
    { id: number; data: OnboardingFormBody }
  >
}): UseMutationResult<{ success: boolean }, TError, { id: number; data: OnboardingFormBody }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => submitOnboardingEmp(payload.id, payload?.data),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
