import { FC } from 'react'
import clsx from 'classnames'
import { SpringValue, animated } from 'react-spring'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import { Typography, Button } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { useSelector } from 'utils/hooks'

import styles from '../styles.module.css'
import { usePaymentMethod } from '../../components/usePaymentMethods'
import PaymentConfigMenu from '../../components/PaymentConfigMenu'

type UnlinkedPaymentCardPropsType = {
  paymentConfiguration: PaymentConfigurationType
  style: {
    opacity: SpringValue<number>
    transform: SpringValue<string>
  }
}

const UnlinkedPaymentCard: FC<UnlinkedPaymentCardPropsType> = (props) => {
  const { paymentConfiguration, style } = props
  const paymentCtx = usePaymentMethod()
  const fetchingShowMethod = useSelector((s) => s.paymentMethods.fetchingShowMethod)

  const { id, name, registration_number, mol_registration, unified_mol_number } =
    paymentConfiguration

  return (
    <animated.div
      style={style}
      className={clsx(
        mainStyles.main_widget,
        styles.unlinked_card__main__container,
        mainStyles.grid_card_item,
      )}
      key={id}
    >
      <div style={{ flex: 1 }}>
        <Typography
          variant="subtitle-1"
          style={{
            color: 'var(--color-base-colors/grey/800)',
            marginBottom: 6,
            fontWeight: 600,
            lineHeight: '20px',
          }}
          text={name}
        />
        <div className={mainStyles.payment_info_divider}>
          <Typography
            variant="interface/default/sm"
            className={mainStyles.primary_text}
            text={`${i18n.t('commercial_registration')} : ${registration_number}`}
          />
          <Typography
            variant="interface/default/sm"
            className={mainStyles.primary_text}
            text={`${i18n.t('mol_registration_number')} :${' '}
            ${mol_registration.mol_registration_number}`}
          />
          {!!unified_mol_number && (
            <Typography
              variant="interface/default/sm"
              className={mainStyles.primary_text}
              text={`${i18n.t('unified_mol_no')} : ${unified_mol_number}`}
            />
          )}
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Button
          label={i18n.t('payment_method')}
          size="small"
          disabled={fetchingShowMethod}
          onClick={(): Promise<void> => paymentCtx.handleSetupMethodOpen(paymentConfiguration)}
          leadingIcon="plus"
        />
        {!paymentCtx.isOnBoarding && (
          <PaymentConfigMenu
            disabled={false}
            options={[
              {
                name: i18n.t('manage_bank_account'),
                onClick: (): void => {
                  paymentCtx.handleManagementPaymentOpen(paymentConfiguration)
                },
              },
            ]}
          />
        )}
      </div>
    </animated.div>
  )
}

export default UnlinkedPaymentCard
