import { useEffect, useRef } from 'react'
import { FeaturedIcon, FeaturedIconProps, Flex, Spacer, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import classNames from 'classnames'
import styles from './styles.module.css'

export type TOrganizationStep = {
  key: string
  title: string
  status: 'completed' | 'pending' | 'disabled'
}

type TProps = {
  list: TOrganizationStep[]
  inProgress: string
  onClick?: (v: TOrganizationStep) => void
}

const OrganizationSteps = ({ list, inProgress, onClick }: TProps): JSX.Element => {
  const stepRefs = useRef<(HTMLDivElement | null)[]>([])
  const inProgressStep = list.find((s) => s.key === inProgress)?.key ?? {}

  const gitFeaturedIconProps = (step: TOrganizationStep): FeaturedIconProps => {
    if (inProgressStep === step.key) return { colorType: 'brand', iconName: 'circle' }
    if (step.status === 'completed') return { colorType: 'success', iconName: 'check' }
    return { colorType: 'neutral', iconName: 'circle' }
  }

  const assignRef = (el: HTMLDivElement | null, index: number) => {
    stepRefs.current[index] = el
  }

  useEffect(() => {
    const firstPendingStep = stepRefs.current.find((ref) => ref && ref.dataset.status === 'pending')
    if (firstPendingStep) {
      firstPendingStep.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [list])

  return (
    <Flex className="pb-[6px] gap-[16px] overflow-y-auto">
      {list.map((step, index) => (
        <div
          key={index}
          data-status={step.status}
          ref={(el) => assignRef(el, index)}
          className={classNames(
            onClick && styles.clickProgress,
            onClick && step.status === 'disabled' && styles.disabledProgress,
          )}
          {...(onClick &&
            step.status !== 'disabled' && {
              onClick: (): void => onClick?.(step),
            })}
        >
          <Flex itemsCenter>
            <FeaturedIcon
              size="xs"
              {...gitFeaturedIconProps(step)}
            />
            <Spacer width={4} />
            <Typography
              text={i18n.t(step.title)}
              variant="body-new/medium"
              {...(inProgressStep === step.key && {
                textColor: 'color/fg/brand/default',
              })}
            />
          </Flex>
          <Spacer height={8} />
          <div
            className={classNames(
              styles.stepBar,
              styles[`bar-status-${step.status}`],
              inProgressStep === step.key && styles['bar-status-in-progress'],
            )}
          />
        </div>
      ))}
    </Flex>
  )
}

export default OrganizationSteps
