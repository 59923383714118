import { useEffect, useState } from 'react'
import { Divider } from '@jisr-hr/ds'
import { DatePicker } from '@jisr-hr/ds-beta'
import { format } from 'date-fns'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'
import { useFilters } from './context'

type DateProps = {
  end: Date
  start: Date
}

type DurationT = {
  filterKey: string
  filters: string[]
  title: string
}

const Duration = (props: DurationT): JSX.Element => {
  const { filters, title, filterKey } = props
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [startEndDate, setStartEndDate] = useState<DateProps>()

  const { filter, handleFilter } = useFilters()

  const handleChangeDate = (_: string | string[], dates: Date | Date[]): void => {
    if (dates?.[0] && dates?.[1]) {
      setStartEndDate({ start: dates[0], end: dates[1] })
      handleFilter('duration', {
        from: format(dates[0], 'yyyy-MM-dd'),
        to: format(dates[1], 'yyyy-MM-dd'),
      })
    } else {
      setStartEndDate({ start: dates?.[0], end: dates?.[1] })
    }
  }

  useEffect(() => {
    if (filter.duration?.from && filter.duration?.to) {
      setStartEndDate({
        start: new Date(filter.duration?.from),
        end: new Date(filter.duration?.to),
      })
    }
  }, [])

  return (
    <>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={false}
          handleClearFilter={(): void => undefined}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title="date_picker_duration"
        />
        {!isCollapsed && (
          <DatePicker
            value={
              startEndDate?.start && startEndDate?.end && [startEndDate?.start, startEndDate?.end]
            }
            calenderProps={{
              type: 'range',
            }}
            onChange={handleChangeDate}
          />
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </>
  )
}

export default Duration
