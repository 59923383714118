import { Lens } from '@dhmk/zustand-lens'

export type TCanduStore = {
  canduIsEnable: boolean
  setCanduIsEnable: (data: boolean) => void
}

export const createCanduStore: Lens<TCanduStore> = (set) => ({
  canduIsEnable: false,
  setCanduIsEnable: (data): void => set(() => ({ canduIsEnable: data })),
})
