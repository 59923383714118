import { ChangeEvent, useState, useEffect } from 'react'
import I18n from 'translations/i18n'
import { useSelector, useDispatch } from 'utils/hooks'
import { toBase64 } from 'components/global/HelperFunctions'
import { UploadingTab } from '@jisr-hr/design-system'
import { Typography, Spacer } from '@jisr-hr/ds'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import {
  updateOrganization,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'
import StepTemplate from '../../../components/StepTemplate'

const UploadLogo = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { organization, fetching, error } = useSelector(
    ({ organization: organizationSelector }) => organizationSelector,
  )

  const [imageUpload, setImageUpload] = useState('')
  const message1 = I18n.t('upload_Savola_Groups_Logo', {
    groups_name: organization.name_i18n,
  })
  const message2 = I18n.t('adjust_your_logo')
  const imgStyle = {
    maxHeight: 256,
    maxWidth: 256,
  }

  const reciveImage = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const filename = e.target?.files?.[0]?.name
    const base64 = await toBase64(e.target.files && e.target.files[0])
    dispatch(
      updateOrganization({
        logo: {
          data: base64,
          filename,
        },
      }),
    )
  }

  const removeImage = (): void => {
    dispatch(updateOrganization({ logo: null })).then((): void => {
      setImageUpload('')
    })
  }

  useEffect(() => {
    if (organization?.logo?.file_path && organization.logo?.file_name) {
      setImageUpload(organization.logo.file_path)
    }
  }, [organization.logo])

  useTrackingSteps({ step_name: 'organization_info', level_name: 'logo' })

  useEffect(() => {
    dispatch(organizationInfo())
  }, [])

  return (
    <StepTemplate
      fetching={fetching}
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="interface/default/sm"
        text={imageUpload ? message2 : message1}
      />
      <Spacer height="16px" />
      {!imageUpload && (
        <UploadingTab
          local="en"
          isUploading
          errorMessage={error}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            reciveImage(e)
          }}
        />
      )}
      {imageUpload && (
        <>
          <img
            src={imageUpload}
            style={imgStyle}
            alt="savola logo"
          />
          <br />
          <Typography
            className="pointer"
            variant="subtitle-2"
            onClick={removeImage}
            text={I18n.t('remove')}
          />
        </>
      )}
    </StepTemplate>
  )
}

export default UploadLogo
