/* eslint-disable no-underscore-dangle */
import { Typography, Button, Flex } from '@jisr-hr/ds'
import React, { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { isEmpty } from 'lodash'
import ExpenseClaimItem from '../../ExpenseClaimItems/ExpenseClaimItem'
import ExpenseClaimItemForm from '../ExpenseClaimItem'
import Reason from '../Reason'
import { useRequest } from '../../../RequestProvider'
import Styles from './style.module.css'

const ExpenseClaimItemDetails = () => {
  const [editingItem, setEditingItem] = useState(0)
  const [disableSaveItem, setDisableSaveItem] = useState(true)
  const { setInitialValues, handleDisableSubmit, editRequest } = useRequest()
  const {
    mutators: { unshift, remove },
    reset,
    change,
  } = useForm()
  const ecrItemName = 'ecr_items_attributes'
  const { values, values: { ecr_items_attributes: ecrItems = [], reason } = {} } = useFormState()

  const hasRequiredItems = (item) => {
    const isExist = ['name', 'date', 'description', 'amount'].reduce(
      // eslint-disable-next-line no-prototype-builtins
      (acc, x) => acc && item?.hasOwnProperty(x),
      true,
    )
    return isExist
  }

  const ecrItemsAttributes = ecrItems.filter((item) => !isEmpty(item) && hasRequiredItems(item))

  const ecrItemsAtt = ecrItems.filter((item) => item !== 'empty' && !item._destroy)
  const itemCountString = ecrItemsAtt?.length ? ` (${ecrItemsAtt.length})` : ''

  const isReadOnly = editingItem >= 0
  React.useEffect(() => {
    if (editingItem >= 0) {
      handleDisableSubmit(true)
      const isEnabled = ['name', 'date', 'description', 'amount'].reduce(
        (acc, x) => acc && !!ecrItems?.[editingItem]?.[x],
        true,
      )
      return setDisableSaveItem(!isEnabled)
    }
    return handleDisableSubmit(!ecrItems?.length && reason)
  }, [ecrItems, editingItem, reason])

  React.useEffect(() => {
    if (editRequest) {
      setEditingItem(-1)
    }
  }, [editRequest])

  return (
    <>
      <Frame
        header={
          <>
            <Flex justifyBetween>
              <Typography
                text={`${I18n.t('items')}${itemCountString}`}
                style={{ color: 'var(--color-base-colors-grey-600)' }}
                variant="title-2"
              />
              <Button
                disabled={isReadOnly}
                label={I18n.t('add_item')}
                size="small"
                variant="outlined"
                color="neutral"
                onClick={() => {
                  unshift(ecrItemName, {})
                  setEditingItem(0)
                }}
                leadingIcon="plus"
              />
            </Flex>
            <div className={Styles.divider} />
          </>
        }
      >
        <Flex
          flexCol
          style={{ gap: '16px' }}
        >
          <FieldArray name={ecrItemName}>
            {({ fields }) =>
              fields.map((name, i) => {
                const record = fields?.value[i]

                return editingItem === i ? (
                  <ExpenseClaimItemForm
                    key={i}
                    formPrefix={name}
                    item={hasRequiredItems(ecrItems[i]) && ecrItems[i]}
                    disableSave={disableSaveItem}
                    onCancelClick={() => {
                      if (ecrItems.length === 1 && !hasRequiredItems(ecrItems[0])) {
                        delete ecrItems[i]
                      }
                      setEditingItem(-1)
                      reset()
                    }}
                    onSaveClick={() => {
                      setEditingItem(-1)
                      setInitialValues({ ...values, ecr_items_attributes: ecrItemsAttributes })
                    }}
                  />
                ) : (
                  !isEmpty(ecrItems[i]) && hasRequiredItems(ecrItems[i]) && !record._destroy && (
                    <ExpenseClaimItem
                      key={i}
                      // readOnly={isReadOnly}
                      item={hasRequiredItems(ecrItems[i]) && ecrItems[i]}
                      onDeleteClick={() => {
                        if (record?.id) {
                          change(`${name}._destroy`, true)
                        } else {
                          remove(ecrItemName, i)
                          setInitialValues(values)
                          delete ecrItems[i]
                        }
                      }}
                      onEditClick={() => {
                        setEditingItem(i)
                        if (isEmpty(ecrItems[0])) delete ecrItems[0]
                      }}
                      isForm
                    />
                  )
                )
              })
            }
          </FieldArray>
        </Flex>
      </Frame>
      <Reason
        showReasonType={false}
        hideFileUpload
        isRequired
      />
    </>
  )
}

export default ExpenseClaimItemDetails
