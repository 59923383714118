import { Toastr } from 'components/global/Toastr'
import lodash from 'lodash'
import { JournalEntries } from 'redux/setting/integration/Microsoft/microsoftCostCenter/reducer'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type JournalStatus = {
  data: unknown[]
  status: unknown
}

type InitStateType = {
  export_journal_config: {
    id: number
    name: string
    value: {
      combination_code: string[]
      employee_dimension?: Record<string, { code: string; internal_id: string; name: string }>
      ledger?: Record<string, { code: string; internal_id: string; name: string }>
      gl_transaction_type_ids?: number[]
      groupings: string[]
      headers: string[]
      includes_emp_name: boolean
      journal_entry_templates?: Record<string, JournalEntries>
      journal_for_each?: string
      memo_journal_entry?: string[]
      memo_journal_line_entry?: string[]
      sheet_for_each_payment_type: boolean
      combination_code_separator?: string
    }
  }
  fetching: boolean
  journal_accrual: JournalStatus
  journal_ajeer: JournalStatus
  journal_end_of_service: JournalStatus
  journal_gosi: JournalStatus
  journal_out_of_payroll: JournalStatus
  journal_regular: JournalStatus
  journal_vacation_settlement: JournalStatus
  journal_ticket: JournalStatus
  loading: boolean
}

const initialState: InitStateType = {
  export_journal_config: {
    id: 1,
    name: '',
    value: {
      headers: [],
      groupings: [],
      combination_code: [],
      includes_emp_name: false,
      sheet_for_each_payment_type: false,
    },
  },
  fetching: false,
  loading: false,
  journal_accrual: {
    data: [],
    status: {},
  },
  journal_ajeer: {
    data: [],
    status: {},
  },
  journal_gosi: {
    data: [],
    status: {},
  },
  journal_regular: {
    data: [],
    status: {},
  },
  journal_out_of_payroll: {
    data: [],
    status: {},
  },
  journal_end_of_service: {
    data: [],
    status: {},
  },
  journal_vacation_settlement: {
    data: [],
    status: {},
  },
  journal_ticket: {
    data: [],
    status: {},
  },
}

export default function reducer(state = initialState, action: Action): InitStateType {
  switch (action.type) {
    // ? load
    case actions.JOURNAL_STRUCTURE_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.JOURNAL_STATUS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.JOURNAL_STRUCTURE_LOAD_FULFILLED:
      return {
        ...state,
        export_journal_config: action.payload.data?.data?.export_journal_config,
        fetching: false,
      }
    case actions.JOURNAL_STATUS_FULFILLED:
      const journalType = lodash.groupBy(action.payload.data?.data?.journal_entries, 'journal_type')
      return {
        ...state,
        journal_accrual: {
          ...state.journal_accrual,
          data: journalType.accrual,
          status: lodash.groupBy(journalType.accrual, 'status'),
        },
        journal_ajeer: {
          ...state.journal_accrual,
          data: journalType.ajeer,
          status: lodash.groupBy(journalType.ajeer, 'status'),
        },
        journal_regular: {
          ...state.journal_regular,
          data: journalType.regular,
          status: lodash.groupBy(journalType.regular, 'status'),
        },
        journal_gosi: {
          ...state.journal_gosi,
          data: journalType.gosi,
          status: lodash.groupBy(journalType.gosi, 'status'),
        },
        journal_out_of_payroll: {
          ...state.journal_out_of_payroll,
          data: journalType.out_of_payroll,
          status: lodash.groupBy(journalType.out_of_payroll, 'status'),
        },
        journal_end_of_service: {
          ...state.journal_end_of_service,
          data: journalType.end_of_service,
          status: lodash.groupBy(journalType.end_of_service, 'status'),
        },
        journal_vacation_settlement: {
          ...state.journal_vacation_settlement,
          data: journalType.vacation_settlement,
          status: lodash.groupBy(journalType.vacation_settlement, 'status'),
        },
        journal_ticket: {
          ...state.journal_ticket,
          data: journalType.ticket,
          status: lodash.groupBy(journalType.ticket, 'status'),
        },
        fetching: false,
      }

    case actions.JOURNAL_STRUCTURE_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }
    case actions.JOURNAL_STATUS_REJECTED:
      return {
        ...state,
        fetching: false,
      }
    // ? update
    case actions.JOURNAL_STRUCTURE_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.JOURNAL_STRUCTURE_UPDATE_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        loading: false,
      }

    case actions.JOURNAL_STRUCTURE_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
