export const REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD = 'REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD'
export const REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_PENDING =
  'REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_PENDING'
export const REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_FULFILLED =
  'REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_FULFILLED'
export const REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_REJECTED =
  'REPORTS_EXPIRING_DOCUMENTS_EMPLOYEES_LOAD_REJECTED'

export const REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD =
  'REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD'
export const REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_PENDING =
  'REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_PENDING'
export const REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_FULFILLED =
  'REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_FULFILLED'
export const REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_REJECTED =
  'REPORTS_EXPIRING_DOCUMENTS_ORGANIZATIONS_LOAD_REJECTED'
