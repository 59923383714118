import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import EmployeeProfile from '../../ds/EmployeeProfile'
import Styles from './Comments.module.css'
import Attachment from '../Attachment'

const Comment = ({ comment }) => {
  const locale = localStorage.getItem('Locale')
  const order = locale === 'en' ? ['name', 'name_ar'] : ['name_ar', 'name']
  const name = comment?.employee?.[order[0]] || comment?.employee?.[order[1]]
  return (
    <Flex
      flexCol
      className={Styles.comment}
    >
      <Flex
        justifyBetween
        itemsCenter
      >
        <EmployeeProfile
          name={name}
          jobTitle={comment?.display_time}
          avatar={comment?.employee?.avatar_thumb}
          date=""
          weight="medium"
        />
      </Flex>
      <Flex
        flexCol
        style={{ gap: 16 }}
      >
        {comment?.content && (
          <Typography
            text={comment?.content}
            variant="subheading"
            className="break-words"
          />
        )}
        {comment?.attachment_url && (
          <Attachment
            url={comment?.attachment_url}
            name={comment?.attachment_file_name}
            size={comment?.attachment_file_size}
            withBox
          />
        )}
      </Flex>
    </Flex>
  )
}
Comment.propTypes = {
  comment: PropTypes.shape(),
}

export default Comment
