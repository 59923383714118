import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'react-redux'
import { omit } from 'lodash'
import {
  resignationRequestCreate,
  resignationRequestUpdate,
} from 'redux/requests/resignationRequests/actionCreators'
import { ResignationDetails, Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const ResignationRequest = () => {
  const dispatch = useDispatch()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()

  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const onSubmit = (val) => {
    const data = {
      ...val,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }

    if (data.id) {
      return dispatch(
        resignationRequestUpdate(empId, data.id, {
          ...val,
          attachments: val.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }

    return dispatch(
      resignationRequestCreate(empId, {
        ...val,
        attachments: val.attachments?.map((it) => omit(it, 'id')),
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        resignation_reason: request.metadata?.resignation_reason,
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <ResignationDetails employeeId={empId} />
      <Reason />
    </Flex>
  )
}

export default ResignationRequest
