import React from 'react'
import Intercom from 'react-intercom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ORGANISATION_NAME } from '../../../env'

export const useIntercome = () => {
  const { employee } = useSelector((state) => state.auth)
  const chatConfig = employee?.chat_support_config || {}

  const showIntercom = false

  return {
    show: Boolean(showIntercom),
    openChatWidget: () => {
      if (chatConfig?.provider === 'zendesk') {
        window.zE('messenger', 'loginUser', (callback) => {
          callback(chatConfig?.token)
          window.zE('messenger:set', 'locale', employee.language)
          window.zE('messenger', 'open')
        })
      } else {
        window.Intercom('show')
      }
    },
  }
}

const appID = {
  staging: 'exjhq21r',
  production: 'r8ee12zn',
}

const Intrcom = () => {
  const { employee: current_user, company } = useSelector((state) => state.auth)
  const { show: showIntercom } = useIntercome()

  const employee = {
    user_id: current_user?.guid,
    email: current_user?.email,
    name: `${current_user?.first_name} ${current_user?.middle_name} ${current_user?.last_name} (${ORGANISATION_NAME})`,
    isSuperAdmin: current_user?.is_super_admin ? 'Yes' : 'No',
    alignment: window.localStorage.Locale === 'ar' && 'left',
    plan: company?.subscription_plan?.name,
    slug: company?.slug,
    is_payroll_admin: !!current_user?.permission_details?.view_payroll_table,
    user_language: localStorage.getItem('Locale') || 'en',
  }

  const logoutZendesk = () => {
    window.zE('messenger', 'close')
    window.zE('messenger', 'logoutUser')
  }

  React.useEffect(() => {
    window.addEventListener('beforeunload', logoutZendesk)
    return () => {
      window.removeEventListener('beforeunload', logoutZendesk)
      logoutZendesk()
    }
  }, [])

  if (showIntercom) {
    return (
      <Intercom
        appID={appID[import.meta.env.REACT_APP_ENVIRONMENT_NAME] || 'exjhq21r'}
        {...employee}
      />
    )
  }

  return null
}

Intrcom.propTypes = {
  current_user: PropTypes.shape(),
  company: PropTypes.shape(),
}
export default Intrcom
