import { useEffect, useState } from 'react'
import { Modal, ScrollbarContainer, SideDrawer, Typography } from '@jisr-hr/ds-beta'
import {
  useAnnouncementRecipients,
  useMarkAnnouncementAsRead,
} from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg'
import { TAnnouncementRecipient } from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg.type'
import { useOrgManagement } from 'store/OrgManagement/useOrgManagement'
import _ from 'lodash'
import { isToday } from 'date-fns'
import { Scrolling } from 'components/global/atoms/Lazyload'
import DSLoadingIndicator from 'components/DSLoadingIndicator'
import i18n from 'translations/i18n'
import styles from '../../announcements/style.module.css'
import AnnouncementsSettings from './AnnouncementSettings'
import AnnouncementWidget from './announcementWidget'

const AnnouncementsDrawer = () => {
  const [page, setPage] = useState<number>(1)
  const [announcements, setAnnouncements] = useState<TAnnouncementRecipient[]>([])
  const { toggleAnnouncementDrawer, open } = useOrgManagement((s) => s.announcementsDrawer)
  const [openSettings, setOpenSettings] = useState(false)

  const { data, isFetching, refetch } = useAnnouncementRecipients(
    { page, rpp: 20 },
    {
      query: {
        select: ({ data: responseData }) => ({
          announcements: responseData?.data?.announcement_recipients || [],
          pagination: responseData?.data?.pagination,
        }),
        enabled: open, // Fetch data only when modal is open
        staleTime: 0,
        cacheTime: 0,
      },
    },
  )

  const { mutate: markAsRead } = useMarkAnnouncementAsRead()

  // Handle marking announcements as read
  const handleMarkAllAsRead = () => {
    markAsRead(
      { all: true },
      {
        onSuccess: () => {
          // Optionally, you can call refetch here to refresh the data
          refetch()
        },
      },
    )
  }

  // Update announcements state when data changes
  useEffect(() => {
    if (data?.announcements) {
      setAnnouncements(data.announcements)
    }
  }, [data])

  // Group announcements into today and earlier lists
  const { todayList = [], earlierList = [] } = _.groupBy(announcements, (obj) =>
    isToday(new Date(obj.created_at)) ? 'todayList' : 'earlierList',
  )

  // Refetch data when the drawer is opened
  useEffect(() => {
    if (open) {
      setPage(1) // Reset page
      refetch() // Fetch new data
    }
  }, [open, refetch])

  return (
    <>
      <SideDrawer
        active={open}
        header={{
          title: i18n.t('announcements'),
          withClose: true,
          actions: [
            {
              label: i18n.t('label.mark_all_as_read'),
              color: 'primary',
              variant: 'tinted',
              onClick: handleMarkAllAsRead,
            },
            {
              label: i18n.t('label.settings'),
              color: 'primary',
              variant: 'tinted',
              onClick: (): void => setOpenSettings(true),
            },
          ],
        }}
        onClose={() => {
          toggleAnnouncementDrawer(false)
        }}
        zIndex={1000}
        size="wide"
      >
        {isFetching ? (
          <div style={{ paddingBlockEnd: 24 }}>
            <DSLoadingIndicator />
          </div>
        ) : (
          <div className={styles.content}>
            <ScrollbarContainer height="100vh">
              <Scrolling
                className="scroll-y scroll-x"
                style={{ maxHeight: '100%' }}
                {...(data?.pagination?.next_page && {
                  onScrollEnd: (): void => {
                    setPage((prevPage) => prevPage + 1)
                  },
                })}
              >
                <>
                  {!!todayList.length && (
                    <Typography
                      text={i18n.t('today')}
                      variant="subheading"
                      textColor="color/fg/light"
                    />
                  )}
                  {todayList.map((item, index) => (
                    <AnnouncementWidget
                      key={item.id}
                      item={item}
                      refetch={refetch}
                      sideDrawer
                      ind={index}
                    />
                  ))}
                  {!!earlierList.length && (
                    <Typography
                      text={i18n.t('earlier')}
                      variant="subheading"
                      style={{ marginInline: 24 }}
                    />
                  )}
                  {earlierList.map((item, index) => (
                    <AnnouncementWidget
                      key={item.id}
                      item={item}
                      refetch={refetch}
                      sideDrawer
                      ind={index}
                    />
                  ))}
                </>
              </Scrolling>
            </ScrollbarContainer>
          </div>
        )}
      </SideDrawer>{' '}
      <Modal
        open={openSettings}
        size="medium"
        header={{ title: i18n.t('browser_notifications'), withClose: true }}
        onClose={(): void => setOpenSettings(false)}
      >
        <AnnouncementsSettings />
      </Modal>
    </>
  )
}
export default AnnouncementsDrawer
