import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  objectives: [],
}

const mapRunObj = (params, objectives) => {
  if (!params.id) {
    return objectives.concat({
      ...params,
      id: null,
      weightage: 0,
      name_i18n: params.name_en,
    })
  }
  return objectives.map((ob) =>
    ob.id === params.id
      ? {
          ...ob,
          ...params,
        }
      : ob,
  )
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REVIEW_RUN_OBJECTIVES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.REVIEW_RUN_OBJECTIVES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        objectives: mapRunObj(action.meta.objective, action.payload.data.data.objectives),
        fetching: false,
      }

    case actions.REVIEW_RUN_OBJECTIVES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
