export const GET_CANDIDATE_BY_ID = 'GET_CANDIDATE_BY_ID'
export const GET_CANDIDATE_BY_ID_PENDING = 'GET_CANDIDATE_BY_ID_PENDING'
export const GET_CANDIDATE_BY_ID_FULFILLED = 'GET_CANDIDATE_BY_ID_FULFILLED'
export const GET_CANDIDATE_BY_ID_REJECTED = 'GET_CANDIDATE_BY_ID_REJECTED'
export const CLEAR_CANDIDATE_BY_ID_DATA = 'CLEAR_CANDIDATE_BY_ID_DATA'

export type jobWorkflowStage = {
  id: number
  stage_name: string
  stage_name_i18n: string
}

export type cvFileFields = {
  name: string | null
  url: string | null
  size: number | null
  content_type: string | null
}

export type job = {
  id: number
  title_en: string
  title_ar: string
  title_i18n: string
  job_workflow_stages: jobWorkflowStage[]
}

export type jobs = {
  id: number
  job_workflow_stages: jobWorkflowStage[]
}

export type changeSet = {
  new_job: {
    id?: number
    name_ar?: string
    name_en?: string
  }
  new_job_workflow_stage: {
    id?: number
    name_ar?: string
    name_en?: string
  }
}

export type JobApplication = {
  id: number
  job_workflow_stage: jobWorkflowStage
  job: job
  is_auto_reject?: boolean
}

export type Activities = {
  action_by_id: number
  action_type: string
  action_type_i18n: string
  changeset: changeSet
  created_at: string
  is_via_form: boolean
}

export type AssesmentCard = {
  id: number
  title: string
}

export type Skill = {
  id: number
  name: string
}

export type Assements = { assessment_card: AssesmentCard; skills: Skill[] }

export type Candidate = {
  id: number
  name_ar: string
  name_en: string
  name_i18n: string
  current_role: string
  email: string
  address: string | null
  phone_number: string
  nationality_id: number
  created_by_id: number
  all_links: string[]
  links: string[]
  organization_id: number
  cv_file_fields: cvFileFields
  comment_count?: number
  application_submision_count?: number
  created_at: string
  updated_at: string
  is_hiring_manager?: boolean
  is_watcher?: boolean
  is_recruiter?: boolean
  job_application: JobApplication
  job: jobs
  activities: Activities[]
  country_id?: number
  city?: {
    id: number
    name: string
  }
  status: string
  status_i18n: string
  country_phone_code?: string
  nationality_name: string
  can_assess_candidate: boolean
  is_assessor: boolean
  assessments: Assements
  total_score: number
  variables: { [key: string]: string }
}

export type GetCandidateResponse = {
  data: Candidate
  success?: boolean
  statusCode?: number
}
