import { SelectField, DatePickerDSField } from 'components/final-form'
import i18n from 'translations/i18n'
import { Banner, Divider, Flex, Spacer } from '@jisr-hr/ds'
import { required } from 'components/global/form/FormValidations'
import { isEmpty } from 'lodash'
import {
  getDifferenceInHours,
  replaceAmPm,
  timeAddMinutes,
} from 'components/global/form/formNormalize'
import { useFormState } from 'react-final-form'
import { useEffect } from 'react'
import { resetAttendanceRecords } from 'redux/attendance/employeeDetails/actionsCreators'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'utils/hooks'

import {
  useEmployeeAttendanceShifts,
  useRequestDetail,
} from 'components/global/templates/RequestDrawer/hooks'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import LeaveBalanceTemplate from './LeaveBalanceTemplate'

const handelHalfShift = (shiftStartTime: string, shiftEndTime: string): string => {
  const difference = getDifferenceInHours(shiftStartTime, shiftEndTime)
  const halfShift = difference?.total_minutes / 2

  return replaceAmPm(timeAddMinutes(shiftStartTime, halfShift)) as string
}

const HalfDayLeave = (): JSX.Element => {
  const { attendance_record, loadShifts } = useEmployeeAttendanceShifts()
  const { vars, editRequest } = useRequest()
  const { request } = useRequestDetail()
  const { values } = useFormState()
  const dispatch = useDispatch()

  const balanceVals = useSelector((s) => {
    const detail = s.leaveRequests?.leave_requests_detail
    return {
      applied_days: detail?.applied_days,
      pending_balance: detail?.pending_balance,
      requested_days: detail?.requested_days,
    }
  }, shallowEqual)

  const attendanceRecord = attendance_record?.[0]
  const shiftStartTime = attendanceRecord?.employee_shifts?.[0]?.shift_start_time
  const shiftEndTime = attendanceRecord?.employee_shifts?.[0]?.shift_end_time
  const empId = vars?.id ?? request?.employee?.id

  const AnnualLeaveOptions = [
    {
      label: i18n.t('first_half', {
        from: replaceAmPm(shiftStartTime),
        to: handelHalfShift(shiftStartTime, shiftEndTime),
      }),
      value: 'first_half',
    },
    {
      label: i18n.t('second_half', {
        from: handelHalfShift(shiftStartTime, shiftEndTime),
        to: replaceAmPm(shiftEndTime),
      }),
      value: 'second_half',
    },
  ]

  const handelStateEmployeeShift = (): { value: string; title: string; description: string } => {
    const employeeShiftsLength = attendanceRecord?.employee_shifts?.length
    if (attendanceRecord?.is_day_off) {
      return {
        value: 'you_are_off_on_this_day',
        title: i18n.t('you_are_off_on_this_day'),
        description: i18n.t('can_not_submit_leave_on_an_off_day'),
      }
    }
    if (isEmpty(attendanceRecord?.employee_shifts)) {
      return {
        value: 'no_shifts_scheduled',
        title: i18n.t('no_shifts_scheduled'),
        description: i18n.t('can_not_submit_leave_on_an_unscheduled_day'),
      }
    }
    if (employeeShiftsLength > 1) {
      return {
        value: 'you_have_two_shifts',
        title: i18n.t('you_have_two_shifts'),
        description: i18n.t('half_day_leave_is_not_available_on_days_with_more_than_one_shift'),
      }
    }

    return {
      value: 'you_have_a_shift_on_this_day',
      title: i18n.t('you_have_a_shift_on_this_day'),
      description: i18n.t('your_shift_is_from', {
        from: replaceAmPm(shiftStartTime),
        to: replaceAmPm(shiftEndTime),
      }),
    }
  }

  const stateEmployeeShift = handelStateEmployeeShift()
  const canSubmitHalfLeave = stateEmployeeShift.value === 'you_have_a_shift_on_this_day'

  useEffect(() => {
    if (empId && values?.from) {
      loadShifts(empId, values?.from)
    }
    dispatch(resetAttendanceRecords())
  }, [editRequest])

  return (
    <Flex
      flexCol
      style={{ gap: 16 }}
    >
      <Flex
        itemsStart
        style={{ gap: 8 }}
      >
        <DatePickerDSField
          name="from"
          label={i18n.t('on_what_day')}
          width="162px"
          onChange={(date): void => {
            loadShifts(empId, [date], true)
          }}
        />
        <Spacer
          width="17px"
          height="2px"
          style={{ background: 'var(--color-natural-gray-darker-0-new)', marginTop: '42px' }}
        />
        <SelectField
          // @ts-expect-error SelectField needs to be migrated to TS
          style={{ width: '100%' }}
          name="half_day_leave.time"
          options={AnnualLeaveOptions}
          label={i18n.t('on_what_time')}
          validate={required}
          disabled={stateEmployeeShift.value !== 'you_have_a_shift_on_this_day'}
        />
      </Flex>
      {attendanceRecord && (
        <>
          <Divider direction="horizontal" />
          <Banner
            title={stateEmployeeShift.title}
            variant="tinted"
            color={canSubmitHalfLeave ? 'info' : 'danger'}
            leadingIcon={canSubmitHalfLeave ? 'info-circle-filled' : 'x-circle-filled'}
            description={stateEmployeeShift.description}
          />
          {canSubmitHalfLeave && values?.half_day_leave?.time && (
            <LeaveBalanceTemplate
              isHalfLeave
              {...balanceVals}
            />
          )}
        </>
      )}
    </Flex>
  )
}

export default HalfDayLeave
