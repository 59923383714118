import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlaneIcon } from 'assets/figma-icons/Plane.svg'
import { Spacer } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import { Tooltip } from '@jisr-hr/ds-beta'
import I18n from 'translations/i18n'
import { SwitchField, DatePickerDSField, MultiSelectField } from 'components/final-form'
import { useFormState, useForm } from 'react-final-form'
import { required } from 'components/global/form/FormValidations'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import Frame from '../../../ds/Frame'

const FlightTicket = (props) => {
  const { disabled, disableMessage } = props
  const { change } = useForm()
  const family_info = useSelector(
    ({ employee_family_detail }) => employee_family_detail.family_details,
  )

  const {
    values: { require_ticket, ticket_info_attributes },
    initialValues,
  } = useFormState()

  return (
    <Frame
      title={I18n.t('flight_ticket')}
      icon={<PlaneIcon />}
      titleSuffix={
        <Tooltip
          description={disableMessage}
          placement="left"
          trigger={
            <div>
              <SwitchField
                name="require_ticket"
                active={require_ticket}
                disabled={disabled}
                onChange={(checked) => {
                  if (initialValues?.id && !checked) {
                    change('ticket_info_attributes._destroy', true)
                  } else if (initialValues?.id && checked) {
                    change('ticket_info_attributes._destroy', undefined)
                  }
                }}
              />
            </div>
          }
        />
      }
    >
      {require_ticket && (
        <>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={6}
            >
              <DatePickerDSField
                validate={required}
                name="ticket_info_attributes.leaving_date"
                label={I18n.t('departure_date')}
                onChange={() => change('ticket_info_attributes.returning_date', null)}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <DatePickerDSField
                calenderProps={{
                  minDate: new Date(ticket_info_attributes?.leaving_date),
                }}
                textFieldProps={{
                  disabled: !ticket_info_attributes?.leaving_date,
                }}
                validate={required}
                name="ticket_info_attributes.returning_date"
                label={I18n.t('return_date')}
              />
            </Grid>
          </Grid>
          <Spacer height={12} />
          <MultiSelectField
            name="ticket_info_attributes.family_detail_ids"
            labelKey="name_i18n"
            valueKey="id"
            returnType="value"
            optionalLabel={`${ticket_info_attributes?.family_detail_ids?.length || 0}/${
              family_info?.length || 0
            }`}
            options={family_info}
            label={I18n.t('family_members')}
            placeholder={I18n.t('select_members')}
            disabled={isEmpty(family_info)}
            minMenuHeight="100px"
          />
        </>
      )}
    </Frame>
  )
}

FlightTicket.propTypes = {
  disabled: PropTypes.bool,
  disableMessage: PropTypes.string,
}

export default FlightTicket
