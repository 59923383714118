import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { RequestTypePros, RequestTypesPros } from './types'

type InitStateType = {
  fetching: boolean
  not_found_request?: boolean
  request_type: RequestTypePros | null
  request_types: RequestTypesPros[]
}

const initialState: InitStateType = {
  request_type: null,
  request_types: [],
  not_found_request: false,
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.REQUEST_TYPES_SHOW_PENDING:
    case actions.REQUEST_TYPES_POST_PENDING:
    case actions.REQUEST_TYPES_PUT_PENDING:
    case actions.REQUEST_TYPES_DELETE_PENDING:
    case actions.REQUEST_TYPES_LOAD_PENDING: {
      return {
        ...state,
        not_found_request: false,
        fetching: true,
      }
    }

    case actions.REQUEST_TYPES_SHOW_FULFILLED:
    case actions.REQUEST_TYPES_LOAD_FULFILLED: {
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }
    }

    case actions.REQUEST_TYPES_PUT_FULFILLED:
    case actions.REQUEST_TYPES_POST_FULFILLED:
    case actions.REQUEST_TYPES_DELETE_FULFILLED: {
      Toastr.success(payload.data?.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.REQUEST_TYPES_SHOW_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      const { response } = payload
      return {
        ...state,
        not_found_request: response?.status === 404,
        fetching: false,
      }
    }

    case actions.REQUEST_TYPES_POST_REJECTED:
    case actions.REQUEST_TYPES_PUT_REJECTED:
    case actions.REQUEST_TYPES_DELETE_REJECTED:
    case actions.REQUEST_TYPES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
