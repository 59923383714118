export const STANDARD_SHIFT_LISTING_LOAD = 'STANDARD_SHIFT_LISTING_LOAD'
export const STANDARD_SHIFT_LISTING_LOAD_PENDING = 'STANDARD_SHIFT_LISTING_LOAD_PENDING'
export const STANDARD_SHIFT_LISTING_LOAD_FULFILLED = 'STANDARD_SHIFT_LISTING_LOAD_FULFILLED'
export const STANDARD_SHIFT_LISTING_LOAD_REJECTED = 'STANDARD_SHIFT_LISTING_LOAD_REJECTED'

export const STANDARD_SHIFT_LISTING_PAGINATION_LOAD = 'STANDARD_SHIFT_LISTING_PAGINATION'
export const STANDARD_SHIFT_LISTING_PAGINATION_PENDING = 'STANDARD_SHIFT_LISTING_PAGINATION_PENDING'
export const STANDARD_SHIFT_LISTING_PAGINATION_FULFILLED =
  'STANDARD_SHIFT_LISTING_PAGINATION_FULFILLED'
export const STANDARD_SHIFT_LISTING_PAGINATION_REJECTED =
  'STANDARD_SHIFT_LISTING_PAGINATION_REJECTED'

export const STANDARD_SHIFT_LOAD = 'STANDARD_SHIFT_LOAD'
export const STANDARD_SHIFT_LOAD_PENDING = 'STANDARD_SHIFT_LOAD_PENDING'
export const STANDARD_SHIFT_LOAD_FULFILLED = 'STANDARD_SHIFT_LOAD_FULFILLED'
export const STANDARD_SHIFT_LOAD_REJECTED = 'STANDARD_SHIFT_LOAD_REJECTED'

export const STANDARD_SHIFT_LISTING_ASSIGN_LOAD = 'STANDARD_SHIFT_LISTING_ASSIGN_LOAD'
export const STANDARD_SHIFT_LISTING_ASSIGN_LOAD_PENDING =
  'STANDARD_SHIFT_LISTING_ASSIGN_LOAD_PENDING'
export const STANDARD_SHIFT_LISTING_ASSIGN_LOAD_FULFILLED =
  'STANDARD_SHIFT_LISTING_ASSIGN_LOAD_FULFILLED'
export const STANDARD_SHIFT_LISTING_ASSIGN_LOAD_REJECTED =
  'STANDARD_SHIFT_LISTING_ASSIGN_LOAD_REJECTED'

export const STANDARD_SHIFT_UPDATE = 'STANDARD_SHIFT_UPDATE'
export const STANDARD_SHIFT_UPDATE_PENDING = 'STANDARD_SHIFT_UPDATE_PENDING'
export const STANDARD_SHIFT_UPDATE_FULFILLED = 'STANDARD_SHIFT_UPDATE_FULFILLED'
export const STANDARD_SHIFT_UPDATE_REJECTED = 'STANDARD_SHIFT_UPDATE_REJECTED'

export const STANDARD_SHIFT_CREATE = 'STANDARD_SHIFT_CREATE'
export const STANDARD_SHIFT_CREATE_PENDING = 'STANDARD_SHIFT_CREATE_PENDING'
export const STANDARD_SHIFT_CREATE_FULFILLED = 'STANDARD_SHIFT_CREATE_FULFILLED'
export const STANDARD_SHIFT_CREATE_REJECTED = 'STANDARD_SHIFT_CREATE_REJECTED'

export const STANDARD_SHIFT_DELETE = 'STANDARD_SHIFT_DELETE'
export const STANDARD_SHIFT_DELETE_FULFILLED = 'STANDARD_SHIFT_DELETE_FULFILLED'
export const STANDARD_SHIFT_DELETE_REJECTED = 'STANDARD_SHIFT_DELETE_REJECTED'

export const RESET_STANDARD_SHIFTS = 'RESET_STANDARD_SHIFTS'

export const RESET_SHIFT_DETAIL = 'RESET_SHIFT_DETAIL'
