export const PROBATIONS_LOAD = 'PROBATIONS_LOAD'
export const PROBATIONS_LOAD_PENDING = 'PROBATIONS_LOAD_PENDING'
export const PROBATIONS_LOAD_FULFILLED = 'PROBATIONS_LOAD_FULFILLED'
export const PROBATIONS_LOAD_REJECTED = 'PROBATIONS_LOAD_REJECTED'

export const PROBATION_CREATE = 'PROBATION_CREATE'
export const PROBATION_CREATE_PENDING = 'PROBATION_CREATE_PENDING'
export const PROBATION_CREATE_FULFILLED = 'PROBATION_CREATE_FULFILLED'
export const PROBATION_CREATE_REJECTED = 'PROBATION_CREATE_REJECTED'

export const PROBATION_EDIT = 'PROBATION_EDIT'
export const PROBATION_EDIT_PENDING = 'PROBATION_EDIT_PENDING'
export const PROBATION_EDIT_FULFILLED = 'PROBATION_EDIT_FULFILLED'
export const PROBATION_EDIT_REJECTED = 'PROBATION_EDIT_REJECTED'

export const PROBATION_UPDATE = 'PROBATION_UPDATE'
export const PROBATION_UPDATE_PENDING = 'PROBATION_UPDATE_PENDING'
export const PROBATION_UPDATE_FULFILLED = 'PROBATION_UPDATE_FULFILLED'
export const PROBATION_UPDATE_REJECTED = 'PROBATION_UPDATE_REJECTED'

export const PROBATION_DELETE = 'PROBATION_DELETE'
export const PROBATION_DELETE_PENDING = 'PROBATION_DELETE_PENDING'
export const PROBATION_DELETE_FULFILLED = 'PROBATION_DELETE_FULFILLED'
export const PROBATION_DELETE_REJECTED = 'PROBATION_DELETE_REJECTED'
