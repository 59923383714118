import { FC } from 'react'
import { ReactComponent as OfflineImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/offline.svg'
import { ReactComponent as MudadImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/mudad.svg'
import { Typography, Flex, Badge } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { ReactComponent as CheckIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/check-icon.svg'
// import { ReactComponent as AhliBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/ahli_bank.svg'
import { ReactComponent as AnbhBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/anb_bank.svg'
import { ReactComponent as InmaBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/inma_bank.svg'
import { ReactComponent as RiyadhBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/riyadh_bank.svg'
import { ReactComponent as StcBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/stc_bank.svg'
import { ReactComponent as AlRajhiBankIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/Al_Rajhi_Bank_Logo.svg'

import styles from '../styles.module.css'

type PaymentMethodCardType = {
  method: 'mudad' | 'offline'
  is_active: boolean
  style?: React.CSSProperties
}

const mudadList = [
  {
    title: i18n.t('automatic_upload_file_to_wps'),
  },
  {
    title: i18n.t('salaries_transfer_with_one_clicks'),
  },
  {
    title: i18n.t('supported_banks'),
  },
]

const PaymentMethodCard: FC<PaymentMethodCardType> = (props) => {
  const { method, is_active, style } = props

  return (
    <div
      style={style}
      className={`${styles.payment_method_card_box} ${is_active ? styles.payment_active : ''}`}
    >
      {method === 'mudad' ? <MudadImg /> : <OfflineImg />}
      <div style={{ flex: 1 }}>
        <Flex
          itemsCenter
          style={{ gap: 8 }}
        >
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '24px' }}
            text={method === 'mudad' ? i18n.t('mudad') : i18n.t('offline_method')}
          />
          {is_active && (
            <Badge
              label={i18n.t('active')}
              color="green"
              size="small"
              variant="tinted"
            />
          )}
        </Flex>
        <Typography
          variant="interface/default/sm"
          style={{ color: 'var(--color-base-colors/grey/800)', lineHeight: '20px', marginTop: 4 }}
          text={
            method === 'mudad'
              ? i18n.t('automated_and_hassle_free')
              : i18n.t('offline_processing_desc')
          }
        />
        {method === 'mudad' && (
          <div className={styles.mudad_card_description_box}>
            {mudadList.map((item, i) => {
              return (
                <Flex
                  itemsStart={i === mudadList.length - 1}
                  itemsCenter={i !== mudadList.length - 1}
                  key={i}
                  style={{ gap: 8 }}
                >
                  <CheckIcon style={{ flexShrink: 0 }} />
                  <Flex
                    flexCol
                    style={{ gap: 4 }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: 'var(--color-base-colors/grey/800)' }}
                      title={item.title}
                      text={item.title}
                    />
                    {i === mudadList.length - 1 && (
                      <Flex
                        itemsCenter
                        style={{ gap: 4 }}
                      >
                        {/* <AhliBankIcon /> */}
                        <InmaBankIcon />
                        <RiyadhBankIcon />
                        <StcBankIcon />
                        <AnbhBankIcon style={{ width: 40, height: 40 }} />
                        <AlRajhiBankIcon style={{ width: 40, height: 40 }} />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              )
            })}
          </div>
        )}
        {props.children}
      </div>
    </div>
  )
}

export default PaymentMethodCard
