import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords, updateOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  parentDepartments: [],
  parentDepartment: { manager: {} },
  editing: false,
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function parentDepartmentReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let parentDepartments = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'PARENT_DEPARTMENTS_LOAD_PENDING':
    case 'PARENT_DEPARTMENT_CREATE_PENDING':
    case 'PARENT_DEPARTMENT_UPDATE_PENDING':
    case 'PARENT_DEPARTMENT_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'PARENT_DEPARTMENTS_LOAD_REJECTED':
    case 'PARENT_DEPARTMENT_CREATE_REJECTED':
    case 'PARENT_DEPARTMENT_UPDATE_REJECTED':
    case 'PARENT_DEPARTMENT_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_LOAD_FULFILLED':
      changes = {
        parentDepartment: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENTS_LOAD_FULFILLED':
      changes = {
        parentDepartments: payload.data.data.parent_departments,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      parentDepartments = [...state.parentDepartments]
      newRecord = payload.data.data.parent_department
      changes = {
        parentDepartments: [...parentDepartments, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.parent_department
      changes = {
        parentDepartments: updateOneListOfRecords(state.parentDepartments, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        parentDepartments: removeOneListOfRecords(state.parentDepartments, payload.id),
        error: null,
      }

    case 'PARENT_DEPARTMENT_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'PARENT_DEPARTMENT_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
