import { Tooltip } from '@jisr-hr/ds-beta'
import { Typography } from '@jisr-hr/ds'
import MUITooltip from '@material-ui/core/Tooltip'

type toolTipHandlerProps = {
  children: JSX.Element
  customTitle?: JSX.Element
  isShown?: boolean
  useMUI?: boolean
  placement?: string
  title?: string
  style?: Record<string, unknown>
}

const ToolTipHandler = ({
  isShown = true,
  title,
  children,
  customTitle,
  placement,
  useMUI,
  style,
  ...rest
}: toolTipHandlerProps): JSX.Element => {
  const renderTooltip = (): JSX.Element => {
    if (useMUI) {
      return (
        <MUITooltip
          title={
            customTitle || (
              <Typography
                text={title}
                variant="caption"
                textColor="color/fg/inverse"
              />
            )
          }
          {...rest}
        >
          <div style={{ cursor: 'pointer' }}>{children}</div>
        </MUITooltip>
      )
    }
    return (
      <Tooltip
        placement={placement}
        {...rest}
        trigger={<div style={{ cursor: 'pointer', ...style }}>{children}</div>}
      >
        {customTitle || (
          <Typography
            text={title}
            variant="caption"
            textColor="color/fg/inverse"
          />
        )}
      </Tooltip>
    )
  }
  return <>{isShown ? renderTooltip() : <>{children}</>}</>
}

export default ToolTipHandler
