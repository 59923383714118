export const RESET = 'RESET'

export const ASSETS_REQUEST_WORKFLOWS_LOAD = 'ASSETS_REQUEST_WORKFLOWS_LOAD'
export const ASSETS_REQUEST_WORKFLOWS_LOAD_PENDING = 'ASSETS_REQUEST_WORKFLOWS_LOAD_PENDING'
export const ASSETS_REQUEST_WORKFLOWS_LOAD_FULFILLED = 'ASSETS_REQUEST_WORKFLOWS_LOAD_FULFILLED'
export const ASSETS_REQUEST_WORKFLOWS_LOAD_REJECTED = 'ASSETS_REQUEST_WORKFLOWS_LOAD_REJECTED'

export const ASSETS_REQUEST_WORKFLOW_LOAD = 'ASSETS_REQUEST_WORKFLOW_LOAD'
export const ASSETS_REQUEST_WORKFLOW_LOAD_PENDING = 'ASSETS_REQUEST_WORKFLOW_LOAD_PENDING'
export const ASSETS_REQUEST_WORKFLOW_LOAD_FULFILLED = 'ASSETS_REQUEST_WORKFLOW_LOAD_FULFILLED'
export const ASSETS_REQUEST_WORKFLOW_LOAD_REJECTED = 'ASSETS_REQUEST_WORKFLOW_LOAD_REJECTED'

export const ASSETS_REQUEST_WORKFLOW_CREATE = 'ASSETS_REQUEST_WORKFLOW_CREATE'
export const ASSETS_REQUEST_WORKFLOW_CREATE_PENDING = 'ASSETS_REQUEST_WORKFLOW_CREATE_PENDING'
export const ASSETS_REQUEST_WORKFLOW_CREATE_REJECTED = 'ASSETS_REQUEST_WORKFLOW_CREATE_REJECTED'
export const ASSETS_REQUEST_WORKFLOW_CREATE_FULFILLED = 'ASSETS_REQUEST_WORKFLOW_CREATE_FULFILLED'

export const ASSETS_REQUEST_WORKFLOW_UPDATE = 'ASSETS_REQUEST_WORKFLOW_UPDATE'
export const ASSETS_REQUEST_WORKFLOW_UPDATE_PENDING = 'ASSETS_REQUEST_WORKFLOW_UPDATE_PENDING'
export const ASSETS_REQUEST_WORKFLOW_UPDATE_FULFILLED = 'ASSETS_REQUEST_WORKFLOW_UPDATE_FULFILLED'
export const ASSETS_REQUEST_WORKFLOW_UPDATE_REJECTED = 'ASSETS_REQUEST_WORKFLOW_UPDATE_REJECTED'

export const ASSETS_REQUEST_WORKFLOW_DELETE = 'ASSETS_REQUEST_WORKFLOW_DELETE'
export const ASSETS_REQUEST_WORKFLOW_DELETE_FULFILLED = 'ASSETS_REQUEST_WORKFLOW_DELETE_FULFILLED'
export const ASSETS_REQUEST_WORKFLOW_DELETE_REJECTED = 'ASSETS_REQUEST_WORKFLOW_DELETE_REJECTED'

export const ASSETS_REQUEST_WORKFLOW_UNASSIGNED_LOAD = 'ASSETS_REQUEST_WORKFLOW_UNASSIGNED_LOAD'
export const ASSETS_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED =
  'ASSETS_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED'

export const ASSETS_REQUEST_WORKFLOW_EDIT = 'ASSETS_REQUEST_WORKFLOW_EDIT'
export const ASSETS_REQUEST_WORKFLOW_EDIT_CANCEL = 'ASSETS_REQUEST_WORKFLOW_EDIT_CANCEL'
