export const FINAL_SETTELEMENT_APPROVALS_LIST_LOAD = 'FINAL_SETTELEMENT_APPROVALS_LIST_LOAD'
export const FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_PENDING =
  'FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_PENDING'
export const FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_FULFILLED =
  'FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_FULFILLED'
export const FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_REJECTED =
  'FINAL_SETTELEMENT_APPROVALS_LIST_LOAD_REJECTED'

export const FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD = 'FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_PENDING =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_PENDING'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_FULFILLED =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_FULFILLED'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_REJECTED =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_LOAD_REJECTED'

export const FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE = 'FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_PENDING =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_PENDING'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_FULFILLED =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_FULFILLED'
export const FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_REJECTED =
  'FINAL_SETTELEMENT_APPROVALS_STEPS_UPDATE_REJECTED'
