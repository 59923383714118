import { useEffect, useState } from 'react'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { commercialLoad } from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import PayrollBankForm from './PayrollBankForm'
import PayrollBankCard from './components/PayrollBankCard'

const PayrollBank = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { commercial_registrations } = useSelector((s) => s?.commercialRegistrationsOnborading)

  const disable_button = commercial_registrations?.find(
    (method) => method.bank_accounts?.length === 0,
  )
  const [open, setOpen] = useState(false)

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'payroll_bank' })

  useEffect(() => {
    dispatch(commercialLoad({ with_bank_accounts: true }))
  }, [])
  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!!disable_button}
    >
      <Typography
        variant="heading"
        text={I18n.t('payroll_bank')}
      />
      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-grey-500)' }}
        text={I18n.t('payroll_bank_desc')}
      />

      <Spacer height={32} />

      <Flex
        flexWrap
        style={{ gap: 24 }}
      >
        {commercial_registrations?.map((item, i) => (
          <PayrollBankCard
            key={i}
            commercialRegistration={item}
            onEditClick={(isOpen: boolean): void => {
              setOpen(isOpen)
            }}
          />
        ))}
      </Flex>

      <PayrollBankForm
        isOpen={open}
        onClose={(): void => setOpen(false)}
      />
    </StepTemplate>
  )
}

export default PayrollBank
