import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PaymentProcessingMethodsTypes = {
  filterKey: string
  filters: string[]
}

const PaymentProcessingMethods = (props: PaymentProcessingMethodsTypes): JSX.Element => {
  const { payment_methods } = useFilterAPI()

  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const processingPaymentMethods = payment_methods.filter((it) => it.name !== 'direct_transfer')
  const optionList = returnBadgeList(processingPaymentMethods)
  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        payment_methods: [],
      })
    } else {
      handleFilter('payment_methods', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.payment_methods)) {
      setSelectedIds(filter.payment_methods)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.payment_methods])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

export default PaymentProcessingMethods
