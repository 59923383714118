export function handleResponce({ records, header, main_header }) {
  const newMinHeader = [
    {
      label: main_header.employee_details.text,
      colSpan: main_header.employee_details.col_span,
    },
    {
      label: main_header.change_details.text,
      colSpan: main_header.change_details.col_span,
    },
    {
      label: main_header.organization_changes.text,
      colSpan: main_header.organization_changes.col_span,
    },
    {
      label: main_header.compensation_changes.text,
      colSpan: main_header.compensation_changes.col_span,
    },
  ]

  const newHeader = [
    ...header.employee_details,
    ...header.change_details,
    ...header.organization_changes,
    ...header.compensation_changes,
  ]

  const newRecords = records.employee_details.map((emData, i) => [
    ...emData.map((d) => d.current),
    ...records.change_details[i].map((d) => d.current),

    records.organization_changes[i].map((d) => d.change_title),
    records.organization_changes[i].map((d) => d.current || '-'),
    records.organization_changes[i].map((d) => d.previous || '-'),

    records.compensation_changes[i].map((d) => d.change_title),
    records.compensation_changes[i].map((d) => d.current || '-'),
    records.compensation_changes[i].map((d) => d.previous || '-'),
  ])

  return {
    main_header: newMinHeader,
    header: newHeader,
    records: newRecords,
  }
}
