import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  additions: [],
  offboarding_addition: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function offboardDeductionReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let additions = []
  let newRecord = {}
  let updatedRecord = {}

  switch (action.type) {
    case 'OFFBOARD_ADDITIONS_LOAD_PENDING':
    case 'OFFBOARD_ADDITION_CREATE_PENDING':
    case 'OFFBOARD_ADDITION_UPDATE_PENDING':
    case 'OFFBOARD_ADDITION_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'OFFBOARD_ADDITIONS_LOAD_REJECTED':
    case 'OFFBOARD_ADDITION_CREATE_REJECTED':
    case 'OFFBOARD_ADDITION_UPDATE_REJECTED':
    case 'OFFBOARD_ADDITION_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_ADDITIONS_LOAD_FULFILLED':
    case 'OFFBOARD_ADDITION_TYPES_LOAD_FULFILLED':
    case 'OFFBOARD_ADDITION_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_ADDITION_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      additions = [...state.additions]
      newRecord = payload.data.data.offboarding_addition
      changes = {
        additions: [...additions, newRecord],
        editing: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_ADDITION_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      additions = [...state.additions]
      updatedRecord = payload.data.data.offboarding_addition
      changes = {
        additions: updateOneListOfRecords(additions, updatedRecord),
        editing: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_ADDITION_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      additions = [...state.additions]
      return {
        ...state,
        additions: removeOneListOfRecords(additions, payload.id),
        error: null,
      }

    case 'OFFBOARD_ADDITION_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'OFFBOARD_ADDITION_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'RESET_FORM':
      changes = {
        offboarding_addition: {},
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
