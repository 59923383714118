export const PAYRUN_ADDITIONS_DEDUCTIONS_LOAD = 'PAYRUN_ADDITIONS_DEDUCTIONS_LOAD'
export const PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_PENDING = 'PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_PENDING'
export const PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED =
  'PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED'
export const PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_REJECTED = 'PAYRUN_ADDITIONS_DEDUCTIONS_LOAD_REJECTED'

export const PAYRUN_ALL_INFO_LOAD = 'PAYRUN_ALL_INFO_LOAD'
export const PAYRUN_ALL_INFO_LOAD_PENDING = 'PAYRUN_ALL_INFO_LOAD_PENDING'
export const PAYRUN_ALL_INFO_LOAD_FULFILLED = 'PAYRUN_ALL_INFO_LOAD_FULFILLED'
export const PAYRUN_ALL_INFO_LOAD_REJECTED = 'PAYRUN_ALL_INFO_LOAD_REJECTED'

export const PAYRUN_STEPPER = 'PAYRUN_STEPPER'

export const PAYRUN_CONFIRM = 'PAYRUN_CONFIRM'
export const PAYRUN_CONFIRM_PENDING = 'PAYRUN_CONFIRM_PENDING'
export const PAYRUN_CONFIRM_FULFILLED = 'PAYRUN_CONFIRM_FULFILLED'
export const PAYRUN_CONFIRM_REJECTED = 'PAYRUN_CONFIRM_REJECTED'

export const PAYRUN_GOSI_FILE_ISSUES_LOAD = 'PAYRUN_GOSI_FILE_ISSUES_LOAD'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_PENDING = 'PAYRUN_GOSI_FILE_ISSUES_LOAD_PENDING'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_FULFILLED = 'PAYRUN_GOSI_FILE_ISSUES_LOAD_FULFILLED'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_REJECTED = 'PAYRUN_GOSI_FILE_ISSUES_LOAD_REJECTED'

export const PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE =
  'PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_PENDING =
  'PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_PENDING'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_FULFILLED =
  'PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_FULFILLED'
export const PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_REJECTED =
  'PAYRUN_GOSI_FILE_ISSUES_LOAD_WITHOUT_PENDING_STATE_REJECTED'

export const PAYRUN_GOSI_FILES_LOAD = 'PAYRUN_GOSI_FILES_LOAD'
export const PAYRUN_GOSI_FILES_LOAD_PENDING = 'PAYRUN_GOSI_FILES_LOAD_PENDING'
export const PAYRUN_GOSI_FILES_LOAD_FULFILLED = 'PAYRUN_GOSI_FILES_LOAD_FULFILLED'
export const PAYRUN_GOSI_FILES_LOAD_REJECTED = 'PAYRUN_GOSI_FILES_LOAD_REJECTED'

export const PAYRUN_GOSI_SALARY_UPDATION = 'PAYRUN_GOSI_SALARY_UPDATION'
export const PAYRUN_GOSI_SALARY_UPDATION_PENDING = 'PAYRUN_GOSI_SALARY_UPDATION_PENDING'
export const PAYRUN_GOSI_SALARY_UPDATION_FULFILLED = 'PAYRUN_GOSI_SALARY_UPDATION_FULFILLED'
export const PAYRUN_GOSI_SALARY_UPDATION_REJECTED = 'PAYRUN_GOSI_SALARY_UPDATION_REJECTED'

export const PAYRUN_GOSI_EMPLOYEE_REGISTRATION = 'PAYRUN_GOSI_EMPLOYEE_REGISTRATION'
export const PAYRUN_GOSI_EMPLOYEE_REGISTRATION_PENDING = 'PAYRUN_GOSI_EMPLOYEE_REGISTRATION_PENDING'
export const PAYRUN_GOSI_EMPLOYEE_REGISTRATION_FULFILLED =
  'PAYRUN_GOSI_EMPLOYEE_REGISTRATION_FULFILLED'
export const PAYRUN_GOSI_EMPLOYEE_REGISTRATION_REJECTED =
  'PAYRUN_GOSI_EMPLOYEE_REGISTRATION_REJECTED'

export const UPDATE_PAYSLIPS_SCHEDULE = 'UPDATE_PAYSLIPS_SCHEDULE'
export const UPDATE_PAYSLIPS_SCHEDULE_PENDING = 'UPDATE_PAYSLIPS_SCHEDULE_PENDING'
export const UPDATE_PAYSLIPS_SCHEDULE_FULFILLED = 'UPDATE_PAYSLIPS_SCHEDULE_FULFILLED'
export const UPDATE_PAYSLIPS_SCHEDULE_REJECTED = 'UPDATE_PAYSLIPS_SCHEDULE_REJECTED'

export const UPTO_DATE_GOSI_FILES = 'UPTO_DATE_GOSI_FILES'
export const UPTO_DATE_GOSI_FILES_PENDING = 'UPTO_DATE_GOSI_FILES_PENDING'
export const UPTO_DATE_GOSI_FILES_FULFILLED = 'UPTO_DATE_GOSI_FILES_FULFILLED'
export const UPTO_DATE_GOSI_FILES_REJECTED = 'UPTO_DATE_GOSI_FILES_REJECTED'

export const PAYRUN_PRINT_REPORT_DOWNLOAD = 'PAYRUN_PRINT_REPORT_DOWNLOAD'
export const PAYRUN_PRINT_REPORT_DOWNLOAD_PENDING = 'PAYRUN_PRINT_REPORT_DOWNLOAD_PENDING'
export const PAYRUN_PRINT_REPORT_DOWNLOAD_FULFILLED = 'PAYRUN_PRINT_REPORT_DOWNLOAD_FULFILLED'
export const PAYRUN_PRINT_REPORT_DOWNLOAD_REJECTED = 'PAYRUN_PRINT_REPORT_DOWNLOAD_REJECTED'

export const PAYRUN_EXECL_REPORT_DOWNLOAD = 'PAYRUN_EXECL_REPORT_DOWNLOAD'
export const PAYRUN_EXECL_REPORT_DOWNLOAD_PENDING = 'PAYRUN_EXECL_REPORT_DOWNLOAD_PENDING'
export const PAYRUN_EXECL_REPORT_DOWNLOAD_FULFILLED = 'PAYRUN_EXECL_REPORT_DOWNLOAD_FULFILLED'
export const PAYRUN_EXECL_REPORT_DOWNLOAD_REJECTED = 'PAYRUN_EXECL_REPORT_DOWNLOAD_REJECTED'

export const PAYROLL_TESTING_LOAD = 'PAYROLL_TESTING_LOAD'
export const PAYROLL_TESTING_LOAD_PENDING = 'PAYROLL_TESTING_LOAD_PENDING'
export const PAYROLL_TESTING_LOAD_FULFILLED = 'PAYROLL_TESTING_LOAD_FULFILLED'
export const PAYROLL_TESTING_LOAD_REJECTED = 'PAYROLL_TESTING_LOAD_REJECTED'

export const PAYROLL_TESTING_CREATE = 'PAYROLL_TESTING_CREATE'
export const PAYROLL_TESTING_CREATE_PENDING = 'PAYROLL_TESTING_CREATE_PENDING'
export const PAYROLL_TESTING_CREATE_FULFILLED = 'PAYROLL_TESTING_CREATE_FULFILLED'
export const PAYROLL_TESTING_CREATE_REJECTED = 'PAYROLL_TESTING_CREATE_REJECTED'

export const PAYROLL_TESTING_DELETE = 'PAYROLL_TESTING_DELETE'
export const PAYROLL_TESTING_DELETE_PENDING = 'PAYROLL_TESTING_DELETE_PENDING'
export const PAYROLL_TESTING_DELETE_FULFILLED = 'PAYROLL_TESTING_DELETE_FULFILLED'
export const PAYROLL_TESTING_DELETE_REJECTED = 'PAYROLL_TESTING_DELETE_REJECTED'

export const SET_PAYRUN_FILTERS = 'SET_PAYRUN_FILTERS'

export const REFRESH_STEP_2 = 'REFRESH_STEP_2'

export const RESET_PAYRUN = 'RESET_PAYRUN'
