import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Select } from '@jisr-hr/design-system'

const SelectField = React.forwardRef(
  (
    {
      disabled,
      labelKey,
      name,
      onChange,
      options,
      validate,
      valueKey,
      testId,
      status,
      statusMessage,
      maxMenuHeight,
      value,
      ...rest
    },
    ref,
  ) => (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }) => (
        <Select
          menuPosition="fixed"
          {...rest}
          {...input}
          status={(meta.error && meta.touched && 'error') || status}
          statusMessage={meta.error || statusMessage}
          data-testid={testId}
          value={options.find((option) => option[valueKey] === input.value) || null}
          getOptionLabel={(data) => data && data[labelKey]}
          getOptionValue={(data) => data && data[valueKey]}
          onChange={(val) => {
            input.onChange(val && val[valueKey])
            if (onChange) {
              onChange(val && val[valueKey], val)
            }
          }}
          isDisabled={disabled}
          options={options}
          maxMenuHeight={maxMenuHeight ?? 200}
          ref={ref}
        />
      )}
    </Field>
  ),
)

SelectField.name = 'SelectField'

SelectField.defaultProps = {
  options: [],
  labelKey: 'label',
  valueKey: 'value',
}

SelectField.propTypes = {
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.func,
  valueKey: PropTypes.string,
  testId: PropTypes.string,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  maxMenuHeight: PropTypes.number,
}

export default React.memo(SelectField)
