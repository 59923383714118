const DEFAULT_STATE = {
  configuration: {},
  approvers: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function workflowReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'WORKFLOW_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'WORKFLOW_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
