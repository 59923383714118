export const ODOO_ACCOUNT_TRANSACTION_SYNC = 'ODOO_ACCOUNT_TRANSACTION_SYNC'
export const ODOO_ACCOUNT_TRANSACTION_SYNC_FULFILLED = 'ODOO_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const ODOO_ACCOUNT_TRANSACTION_SYNC_REJECTED = 'ODOO_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const ODOO_ACCOUNT_TRANSACTION_SYNC_PENDING = 'ODOO_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const ODOO_ACCOUNT_BULK_UPDATE = 'ODOO_ACCOUNT_BULK_UPDATE'
export const ODOO_ACCOUNT_BULK_UPDATE_FULFILLED = 'ODOO_ACCOUNT_BULK_UPDATE_FULFILLED'
export const ODOO_ACCOUNT_BULK_UPDATE_REJECTED = 'ODOO_ACCOUNT_BULK_UPDATE_REJECTED'
export const ODOO_ACCOUNT_BULK_UPDATE_PENDING = 'ODOO_ACCOUNT_BULK_UPDATE_PENDING'

export const ODOO_ACCOUNTS_LOAD = 'ODOO_ACCOUNTS_LOAD'
export const ODOO_ACCOUNTS_LOAD_FULFILLED = 'ODOO_ACCOUNTS_LOAD_FULFILLED'
export const ODOO_ACCOUNTS_LOAD_REJECTED = 'ODOO_ACCOUNTS_LOAD_REJECTED'
export const ODOO_ACCOUNTS_LOAD_PENDING = 'ODOO_ACCOUNTS_LOAD_PENDING'

export const ODOO_SPECIFIC_ACCOUNT_LOAD = 'ODOO_SPECIFIC_ACCOUNT_LOAD'
export const ODOO_SPECIFIC_ACCOUNT_LOAD_FULFILLED = 'ODOO_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const ODOO_SPECIFIC_ACCOUNT_LOAD_REJECTED = 'ODOO_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const ODOO_SPECIFIC_ACCOUNT_LOAD_PENDING = 'ODOO_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const ODOO_SPECIFIC_ACCOUNT_DELETE = 'ODOO_SPECIFIC_ACCOUNT_DELETE'
export const ODOO_SPECIFIC_ACCOUNT_DELETE_FULFILLED = 'ODOO_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const ODOO_SPECIFIC_ACCOUNT_DELETE_REJECTED = 'ODOO_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const ODOO_SPECIFIC_ACCOUNT_DELETE_PENDING = 'ODOO_SPECIFIC_ACCOUNT_DELETE_PENDING'
