import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { WorkableStateType } from 'containers/authorised/HiredCandidates/types'
import * as actions from './actions'

const initialValues: WorkableStateType = {
  candidateList: [],
  candidateData: {},
  fetching: false,
  responseMessage: { message: '', type: '' },
  last_updated: '',
  pagination: {
    current_page: 1,
    next_page: null,
    previous_page: null,
    total_pages: 1,
    per_page: 10,
    total_entries: 0,
  },
  resetPageData: false,
  auth: { token: '', domain: '' },
}

export default function reducer(state = initialValues, action: Action): WorkableStateType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_CANDIDATE_LIST_PENDING:
    case actions.SYNC_CANDIDATE_LIST_PENDING:
    case actions.GET_CANDIDATE_IMPORT_DATA_PENDING:
    case actions.SHOW_PENDING:
    case actions.WORKABLE_CONNECT_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.GET_CANDIDATE_LIST_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      if (payload?.data?.error) Toastr.error(payload?.data?.error)
      return {
        ...state,
        fetching: false,
        candidateList: payload?.data?.data?.candidates,
        last_updated: payload?.data?.data?.last_update,
        pagination: payload?.data?.data?.pagination,
        resetPageData: false,
      }
    }

    case actions.SHOW_FULFILLED: {
      return {
        ...state,
        fetching: false,
        auth: payload?.data?.data,
      }
    }

    case actions.WORKABLE_CONNECT_FULFILLED:
    case actions.SYNC_CANDIDATE_LIST_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      if (payload?.data?.error) Toastr.error(payload?.data?.error)
      return {
        ...state,
        fetching: false,
        responseMessage: { message: '', type: '' },
      }
    }

    case actions.GET_CANDIDATE_IMPORT_DATA_FULFILLED: {
      if (payload?.data?.error) Toastr.error(payload?.data?.error)
      return {
        ...state,
        fetching: false,
        candidateData: payload?.data?.data?.candidate,
      }
    }

    case actions.SYNC_CANDIDATE_LIST_REJECTED:
    case actions.GET_CANDIDATE_IMPORT_DATA_REJECTED: {
      if (payload?.response?.data?.error) Toastr.error(payload?.response?.data?.error)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.GET_CANDIDATE_LIST_REJECTED: {
      if (payload?.response?.data?.error) Toastr.error(payload?.response?.data?.error)
      return {
        ...state,
        fetching: false,
        resetPageData: true,
      }
    }

    case actions.WORKABLE_CONNECT_REJECTED: {
      if (payload?.data?.message) Toastr.error(handleResponseErr(payload.data.message))
      return {
        ...state,
        fetching: false,
        responseMessage: { message: handleResponseErr(payload), type: 'error' },
      }
    }

    case actions.SHOW_REJECTED: {
      return {
        ...state,
        auth: { token: '', domain: '' },
      }
    }

    case actions.CLEAR_CANDIDATE_DATA: {
      return {
        ...state,
        candidateData: {},
      }
    }

    case actions.WORKABLE_RESET_FORM_DATA: {
      return {
        ...state,
        responseMessage: { message: '', type: '' },
      }
    }

    default:
      return state
  }
}
