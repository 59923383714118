import React from 'react'
import { DatePickerDSField, NewSelectField } from 'components/final-form'
import { useSelector } from 'react-redux'
import { useFormState, useForm } from 'react-final-form'
import { required } from 'components/global/form/FormValidations'
import I18n from 'translations/i18n'
import { subDays } from 'date-fns'

import Frame from '../../../ds/Frame'

const LeaveDetails = (props) => {
  const { isAnnual, isHalfDay } = props
  const { leave_types } = useSelector(({ employeeLeaveTypes }) => employeeLeaveTypes)
  const { values } = useFormState()
  const { change } = useForm()

  const options = React.useMemo(
    () =>
      leave_types.map((item) => ({
        id: item.id,
        name: item.name,
        subLabel: item.leave_type?.description_i18n,
      })),
    [leave_types],
  )

  return (
    <Frame title={I18n.t('leave_details')}>
      <div>
        <NewSelectField
          validate={required}
          name="leave_type_id"
          testId="leave-type"
          placeholder={I18n.t('select')}
          labelKey="name"
          valueKey="id"
          subLabelKey="subLabel"
          options={options}
          label={I18n.t('type')}
          maxMenuHeight={256}
          onChange={() => {
            change('is_require_vacation_salary', false)
          }}
        />
        {(!isAnnual || (isAnnual && !isHalfDay)) && (
          <div className="grid grid-cols-12 gap-6 mt-4">
            <div className="col-span-6">
              <DatePickerDSField
                textFieldProps={{ testId: 'select-start-date' }}
                validate={required}
                name="from"
                label={I18n.t('start')}
                onChange={() => change('to', null)}
                calenderProps={{
                  minDate: subDays(new Date(), 59),
                }}
              />
            </div>
            <div className="col-span-6">
              <DatePickerDSField
                validate={required}
                name="to"
                label={I18n.t('end')}
                calenderProps={{
                  minDate: values?.from,
                }}
                textFieldProps={{
                  testId: 'select-end-date',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Frame>
  )
}

export default LeaveDetails
