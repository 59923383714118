import { useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  onboardingCountingDaysLoad,
  onboardingCountingDaysUpdate,
} from 'redux/leavesOnboarding/countingDays/actionCreators'
import RadioWithContent from 'containers/public/Onboarding/components/RadioWithContent'
import I18n from 'translations/i18n'
import { Typography, Spacer } from '@jisr-hr/ds'

export default function AnnualLeaveSettings(): JSX.Element {
  const dispatch = useDispatch()
  const { organization_configuration, fetching } = useSelector(
    ({ onboardingCountingDays }) => onboardingCountingDays,
  )

  useEffect(() => {
    dispatch(onboardingCountingDaysLoad())
  }, [dispatch])

  const handleUpdate = (value: string): void => {
    dispatch(onboardingCountingDaysUpdate({ value })).then(() => {
      dispatch(onboardingCountingDaysLoad())
    })
  }

  return (
    <>
      <Typography
        variant="title-1"
        text={I18n.t('annual_leave_settings')}
      />

      <Spacer height="8px" />
      <Typography
        variant="interface/default/m"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('annual_leave_settings_description')}
      />

      <Spacer height="16px" />
      {/* @ts-expect-error Need to migrate RadioWithContent.js to TypeScript */}
      <RadioWithContent
        options={[
          {
            label: I18n.t('calendar_days'),
            description: I18n.t('calendar_days_description'),
            value: 'Calendar',
          },
          {
            label: I18n.t('business_days'),
            description: I18n.t('business_days_discription'),
            value: 'Business',
          },
        ]}
        disabled={fetching}
        value={organization_configuration.value || ''}
        handleChange={handleUpdate}
      />
    </>
  )
}
