import { useState, useEffect, Fragment, ChangeEvent, useContext } from 'react'
import { SearchBar, Table } from '@jisr-hr/design-system'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { salarypackagesSelector } from 'redux/payrollOnboarding/SalarySetup/selectors'
import { useDispatch, useSelector } from 'utils/hooks'
import { salariesPackagesLoad } from 'redux/payrollOnboarding/SalarySetup/actionsCreators'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import {
  StepsTypes,
  TParams,
  SetupStepTypes,
} from 'containers/public/Onboarding/types/onboardingTypes'
import {
  onboardingStepUpdate,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'

import FormTable from './Form'
import styles from '../styles.module.css'
import BulkUpdate from '../../componetns/BulkUpdate'

type TExtendsParams = Partial<TParams>

const ReviewEmployeesData = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const fetching = useSelector((s) => s.salaryPackages.fetching)
  const { header, records, pagination } = useSelector(salarypackagesSelector)
  const { require_pincode, metadata } = useSelector(
    ({ organization }) => organization?.organization,
  )
  const { setMainStep } = useContext(OnboardingContext)

  const [params, setParams] = useState<TExtendsParams>({
    search: '',
    rpp: 10,
    page: 1,
  })

  const [idEdit, setIdEdit] = useState<Record<string, string | number>>({})

  const handleFilters = (values: TExtendsParams): void => {
    const newParams = {
      ...params,
      ...values,
    }
    setParams(newParams)
    dispatch(salariesPackagesLoad(newParams))
  }

  const getNextStep = (): SetupStepTypes | undefined => {
    if (require_pincode && metadata.new_onboarding_payroll) {
      return { title: I18n.t('invite_employees') }
    }
    return nextStep
  }

  const handleNextStep = (): void => {
    if (require_pincode && metadata.new_onboarding_payroll) {
      if (metadata.onboarding_steps?.payroll_setup === 'Pending') {
        dispatch(
          onboardingStepUpdate({
            step_name: 'payroll_setup',
            step_status: 'Completed',
            current_step: 'invite_employees',
          }),
        ).then(() => dispatch(organizationInfo()))
      } else {
        setMainStep(3)
      }
    } else {
      goNextStep?.()
    }
  }

  useEffect(() => {
    dispatch(salariesPackagesLoad(params))
  }, [])
  return (
    <StepTemplate
      goNextStep={handleNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={getNextStep()}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('review_employees_data')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('review_employees_data_desc', { app_name: I18n.t('jisr_translation_name') })}
      />

      <Spacer height={64} />

      <Flex
        justifyBetween
        itemsCenter
      >
        <SearchBar
          onChange={(_: ChangeEvent<HTMLInputElement>, search: string): void =>
            handleFilters({ page: 1, search })
          }
          placeholder={I18n.t('search_placeholder2')}
          style={{ width: 360 }}
          value={params.search || ''}
        />
        <BulkUpdate
          action={(): void => {
            dispatch(salariesPackagesLoad())
          }}
          type="onboarding_salaries_packages"
        />
      </Flex>

      <Spacer height={16} />

      <Table
        hoverActions
        variant="rounded"
        headers={header || []}
        className={styles.reviewEmployeesTable}
        containerStyles={{ maxHeight: '42vh' }}
        onLoadMore={(): void => handleFilters({ page: +pagination.current_page + 1 })}
        hasNextPage={!fetching && pagination.next_page}
      >
        {records?.map((record, Index1) =>
          idEdit.Index1 === Index1 ? (
            <FormTable
              editRecord={record}
              onClose={setIdEdit}
            />
          ) : (
            <Table.Row key={Index1}>
              {record?.map((record_item, Index2) => (
                <Fragment key={Index2}>
                  {Index2 === 0 && (
                    <Table.Cell>
                      <Flex>
                        <Flex flexCol>
                          <Typography
                            variant="interface/default/sm"
                            text={record_item.name_i18n}
                          />
                          <Typography
                            style={{ color: 'var(--color-base-colors-black-500)' }}
                            variant="interface/default/sm"
                            text={record_item.code}
                          />
                        </Flex>
                      </Flex>
                    </Table.Cell>
                  )}
                  {Index2 === 1 && (
                    <Table.Cell>
                      <Flex
                        flexCol
                        style={{ width: 107 }}
                      >
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.basic_salary}
                        />
                      </Flex>
                    </Table.Cell>
                  )}
                  {Index2 === 2 && (
                    <Table.Cell>
                      <Flex
                        flexCol
                        style={{ width: 107 }}
                      >
                        <Typography
                          variant="interface/default/sm"
                          text={record_item?.ajeer_fees}
                        />
                      </Flex>
                    </Table.Cell>
                  )}
                  {Index2 > 2 && (
                    <Table.Cell>
                      {Index2 !== record.length - 1 ? (
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.company_contribution}
                        />
                      ) : (
                        <Typography
                          variant="interface/default/sm"
                          text={record_item.full_package_salary}
                        />
                      )}
                    </Table.Cell>
                  )}
                  {Index2 === record.length - 1 && (
                    <Table.Cell
                      width="75px"
                      actions
                      onClickEdit={(): void => setIdEdit({ Index1 })}
                    />
                  )}
                </Fragment>
              ))}
            </Table.Row>
          ),
        )}
      </Table>
    </StepTemplate>
  )
}
export default ReviewEmployeesData
