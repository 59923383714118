import { Containers, Switch, SwitchProps, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'

type AnnualLeaveRequiredReasonProps = Pick<SwitchProps, 'active' | 'onChange' | 'disabled'>

const AnnualLeaveRequiredReason = (props: AnnualLeaveRequiredReasonProps) => {
  return (
    <Containers
      border
      borderRadius="sm"
      color="white"
      className="w-full h-full p-6 flex justify-between items-center"
    >
      <div>
        <Typography
          text={i18n.t('label.toggle.require_attachments')}
          variant="title-1"
        />
        <Typography
          text={i18n.t('label.description.require_attachments')}
          variant="subtitle-2"
          className="mt-4"
        />
      </div>
      <Switch {...props} />
    </Containers>
  )
}

export default AnnualLeaveRequiredReason
