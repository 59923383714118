import { TextInputField } from 'components/final-form'
import { Form } from 'react-final-form'
import I18n from 'translations/i18n'
import { Spacer, Flex, Button, Divider } from '@jisr-hr/ds'
import {
  businessUnitCreate,
  businessUnitLoad,
  businessUnitUpdate,
} from 'redux/organizationOnborading/businessUnits/actionCreators'
import { useDispatch } from 'utils/hooks'
import { required } from 'components/global/form/FormValidations'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'

import styles from '../BusinessUnits.module.css'

type BusinessUnitsFormProps = {
  value: { id: number } | null
  onClose: () => void
}

type dataType = {
  id: number
  name: string
  name_ar: string
}

const BusinessUnitsForm = ({ value, onClose }: BusinessUnitsFormProps): JSX.Element => {
  const dispatch = useDispatch()

  const reload = (): void => {
    onClose()
    dispatch(businessUnitLoad())
    dispatch(organizationInfo())
  }

  const onSubmit = (v: dataType): void => {
    const data = {
      name: v.name,
      name_ar: v.name,
    }

    if (v.id) {
      dispatch(businessUnitUpdate(v.id, { business_unit: data })).then(() => reload())
    } else {
      dispatch(businessUnitCreate({ business_unit: data })).then(() => reload())
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={value}
    >
      {({ handleSubmit, pristine }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          className="mx-[-24px] mt-[16px]"
        >
          <div className="mx-[24px] mb-[24px]">
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              name="name"
              label={I18n.t('name')}
              autoFocus
              validate={required}
            />
          </div>
          <Divider />
          <Flex
            justifyEnd
            className={styles.footerForm}
          >
            <Button
              label={I18n.t('cancel')}
              variant="outlined"
              color="neutral"
              size="medium"
              onClick={onClose}
            />
            <Spacer width={8} />
            <Button
              label={I18n.t(value?.id ? 'save' : 'add')}
              size="medium"
              type="submit"
              disabled={pristine}
            />
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default BusinessUnitsForm
