import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'
import { TRating } from './type'

export const getHelpCentreInfo = (step_name: string): Action => {
  const url = `/${API_VERSION_TWO}/page_group_steps/${step_name}/onboarding_help_centre`

  return {
    type: actions.ONBOARDING_HELP_CENTER,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function ratingHelpCenterVideos(step_name: string, answer: TRating): Action {
  const url = `/${API_VERSION_TWO}/page_group_steps/${step_name}/rating_video`
  return {
    type: actions.ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT,
    payload: handleAPI(url, {}, 'PUT', { answer }),
  }
}

export function changeHelpCenterKey(key: string): Action {
  return {
    type: actions.ONBOARDING_HELP_CENTER_KEY,
    payload: key,
  }
}
