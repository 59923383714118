import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  configurationType: {},
  configurationValue: {},
  configurationCanCarry: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function carryForwardReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'CARRY_FORWARD_TYPE_LOAD_PENDING':
    case 'CARRY_FORWARD_TYPE_UPDATE_PENDING':
    case 'CARRY_FORWARD_VALUE_LOAD_PENDING':
    case 'CARRY_FORWARD_VALUE_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'CARRY_FORWARD_TYPE_LOAD_REJECTED':
    case 'CARRY_FORWARD_TYPE_UPDATE_REJECTED':
    case 'CARRY_FORWARD_VALUE_LOAD_REJECTED':
    case 'CARRY_FORWARD_VALUE_UPDATE_REJECTED':
      Toastr.error(action.payload)
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CARRY_FORWARD_TYPE_LOAD_FULFILLED':
    case 'CARRY_FORWARD_TYPE_UPDATE_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        configurationType: {
          ...state.configurationType,
          ...action.payload.data.data.organization_configuration,
        },
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CARRY_FORWARD_VALUE_LOAD_FULFILLED':
    case 'CARRY_FORWARD_VALUE_UPDATE_FULFILLED':
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      changes = {
        configurationValue: {
          ...state.configurationValue,
          ...action.payload.data.data.organization_configuration,
        },
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
