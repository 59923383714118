import { FC, useEffect } from 'react'
import { Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import PaymentMethodContent from 'containers/authorised/setting/v2/payroll/PaymentProcess/PaymentMethods'
import { useDispatch } from 'utils/hooks'
import { PaymentMethodProvider } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/usePaymentMethods'

import { itemPriceOnboardingLoad } from 'redux/payrollOnboarding/PaymentMethods/mudad/actionsCreators'
import { employeesLoad } from 'redux/organizationOnborading/employees/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

const PaymentMethods: FC<StepsTypes> = (props) => {
  const { goNextStep, goPreviousStep, nextStep, previousStep } = props
  const dispatch = useDispatch()

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'payment_methods' })

  useEffect(() => {
    dispatch(employeesLoad({ rpp: 99999999 }))
    dispatch(itemPriceOnboardingLoad())
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('payment_methods')}
      />

      <Typography
        variant="subtitle-1"
        style={{ margin: '8px 0 32px', color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('payment_methods_desc', { appName: I18n.t('jisr_translation_name') })}
      />

      <PaymentMethodProvider>
        <PaymentMethodContent />
      </PaymentMethodProvider>
    </StepTemplate>
  )
}

export default PaymentMethods
