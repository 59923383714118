import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Radio as DSRadio } from '@jisr-hr/ds'

const Radio = ({
  value,
  label,
  error,
  options,
  handleChange,
  horizontal,
  rightAligned,
  normalAligned,
  inline,
  flex,
  disabled,
  testId,
}) => (
  <div
    data-testid={testId}
    className="jisr-radio__container"
  >
    {label && <div className="jisr-radio__label">{label}</div>}
    <div
      className={classNames(
        horizontal && 'jisr-radio--horizontal',
        rightAligned && 'jisr-radio--right-aligned',
        normalAligned && 'jisr-radio--normal-aligned',
        inline && 'jisr-radio--inline',
        flex && 'jisr-radio--flex',
      )}
    >
      {options &&
        options.map((option) => (
          <DSRadio
            key={`radio-${option.value}`}
            id={`radio-${option.value}`}
            label={option.label}
            active={value === option.value}
            value={option.value}
            onChange={handleChange}
            disabled={disabled}
            testId={option.testId}
          />
        ))}
    </div>

    {error && <div className="jisr-radio__error_msg">{error}</div>}
  </div>
)

Radio.propTypes = {
  testId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  options: PropTypes.arrayOf(Object).isRequired,
  handleChange: PropTypes.func,
  horizontal: PropTypes.bool,
  rightAligned: PropTypes.bool,
  normalAligned: PropTypes.bool,
  inline: PropTypes.bool,
  flex: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default Radio
