import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Lazyload, { Scrolling } from 'components/global/atoms/Lazyload'
import { ReactComponent as InfoIcon } from '@jisr-hr/design-system/dist/assets/icons/Mics/Information.svg'
import { isArray } from 'lodash'

const LoadingTr = () => (
  <tr>
    <td colSpan="100%">Loading...</td>
  </tr>
)

const switchSortDirection = (sortDir) => (sortDir === 'asc' ? 'desc' : 'asc')

const isValidPrimitive = (val) => ['string', 'number', 'boolean'].includes(typeof val)
const isValidCellData = (val) => {
  if (isValidElement(val) || isValidPrimitive(val)) return true
  if (Array.isArray(val)) return val.reduce((acc, v) => acc && isValidCellData(v), true)
  return false
}

const Table = ({
  headers,
  records,
  topHeaders,
  children,
  height,
  noScroll,
  grayDesign,
  maxHeight: maxheight,
  headerStart,
  employeeTable,
  contentStart,
  className,
  breakSpaces,
  tblStyle,
  onScrollEnd,
  sortBy,
  sortDir,
  onSorting,
  sortableColumns,
  tableClassName,
  testId,
  tooltips,
}) => {
  const [sort, setSort] = React.useState({
    sortBy,
    sortDir: sortDir || 'asc',
  })
  const handleOnSorting = (key) => {
    const srt = {
      sortBy: key,
      sortDir: sort.sortBy === key ? switchSortDirection(sort.sortDir) : 'asc',
    }

    onSorting(srt)
    setSort(srt)
  }
  return (
    <div className="jisr-tbl_container">
      <Scrolling
        className={classNames(
          'jisr-tbl scroll-y',
          className,
          !noScroll && 'scroll-x',
          grayDesign && 'jisr-tbl--gray_design',
          headerStart && 'jisr-tbl--header_start',
          employeeTable && 'jisr-tbl--employee-table',
          contentStart && 'jisr-tbl--content_start',
          breakSpaces && 'jisr-tbl--break-spaces',
          tblStyle && `jisr-tbl--style-${tblStyle}`,
        )}
        style={{
          minHeight: height,
          maxHeight: maxheight,
        }}
        onScrollEnd={onScrollEnd}
      >
        <table
          data-testid={testId}
          className={tableClassName}
        >
          <thead>
            {topHeaders && Array.isArray(topHeaders) && (
              <tr data-testid="table-top-headers">
                {topHeaders.map((head, i) => (
                  <th
                    key={i}
                    colSpan={head.colSpan}
                  >
                    {head.label}
                  </th>
                ))}
              </tr>
            )}
            <tr data-testid="table-header">
              {headers &&
                Array.isArray(headers) &&
                headers.map(
                  (head, i) =>
                    head !== null && (
                      <th
                        key={i}
                        className={classNames(
                          sortableColumns[i] && 'sortable',
                          sortableColumns[i] === sort.sortBy && 'active',
                          sort.sortDir,
                        )}
                        {...(sortableColumns[i] && {
                          onClick: () => handleOnSorting(sortableColumns[i]),
                        })}
                      >
                        {head}
                        {tooltips?.includes(head) && (
                          <InfoIcon
                            data-tip
                            style={{ marginInlineStart: 10 }}
                            data-for={head}
                          />
                        )}
                      </th>
                    ),
                )}
            </tr>
          </thead>
          <tbody>
            {records &&
              Array.isArray(records) &&
              records.map((data, i) => (
                <Lazyload
                  key={`lazy-${i}`}
                  fallback={<LoadingTr />}
                >
                  <tr key={i}>
                    {Array.isArray(data) &&
                      data.map((subData, subi) => {
                        let cellData = ''
                        if (typeof subData === 'boolean') cellData = String(subData)
                        else if (isValidCellData(subData)) cellData = subData
                        if (isArray(subData)) {
                          return (
                            <td style={{ padding: '0' }}>
                              {subData.length > 1
                                ? subData.map((subIsArray, key) => (
                                    <div
                                      key={key}
                                      className="trMultiValue"
                                    >
                                      {subIsArray || '-'}
                                    </div>
                                  ))
                                : subData[0]}
                            </td>
                          )
                        }
                        return <td key={subi}>{cellData}</td>
                      })}
                  </tr>
                </Lazyload>
              ))}
            {children}
          </tbody>
        </table>
      </Scrolling>
    </div>
  )
}

Table.defaultProps = {
  height: '50vh',
  maxHeight: '50vh',
  onSorting: () => {},
  sortableColumns: [],
}

Table.propTypes = {
  topHeaders: PropTypes.arrayOf(PropTypes.shape()),
  headers: PropTypes.arrayOf(PropTypes.string),
  records: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noScroll: PropTypes.bool,
  height: PropTypes.string,
  tblStyle: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
  grayDesign: PropTypes.bool,
  headerStart: PropTypes.bool,
  maxHeight: PropTypes.string,
  employeeTable: PropTypes.bool,
  contentStart: PropTypes.bool,
  breakSpaces: PropTypes.bool,
  onScrollEnd: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  onSorting: PropTypes.func,
  sortableColumns: PropTypes.arrayOf(PropTypes.string),
  tableClassName: PropTypes.string,
  tooltips: PropTypes.arrayOf(PropTypes.node),
}

export default Table
