import React from 'react'
import PropTypes from 'prop-types'

const WorkflowDetails = ({ approvers, onSelected }) => (
  <div className="workflow-details-list">
    <ul>
      {approvers.map((approver) => (
        <li
          data-testid={`approver-${approver.id}`}
          key={`approver-${approver.id}`}
          onClick={() => onSelected(approver.key)}
        >
          {approver.name_i18n}
        </li>
      ))}
    </ul>
  </div>
)

WorkflowDetails.propTypes = {
  approvers: PropTypes.arrayOf(Object),
  onSelected: PropTypes.func,
}

export default WorkflowDetails
