export const REQUEST_TYPES_WORKFLOWS_LOAD = 'REQUEST_TYPES_WORKFLOWS_LOAD'
export const REQUEST_TYPES_WORKFLOWS_LOAD_PENDING = 'REQUEST_TYPES_WORKFLOWS_LOAD_PENDING'
export const REQUEST_TYPES_WORKFLOWS_LOAD_FULFILLED = 'REQUEST_TYPES_WORKFLOWS_LOAD_FULFILLED'
export const REQUEST_TYPES_WORKFLOWS_LOAD_REJECTED = 'REQUEST_TYPES_WORKFLOWS_LOAD_REJECTED'

export const REQUEST_TYPES_WORKFLOWS_SHOW = 'REQUEST_TYPES_WORKFLOWS_SHOW'
export const REQUEST_TYPES_WORKFLOWS_SHOW_PENDING = 'REQUEST_TYPES_WORKFLOWS_SHOW_PENDING'
export const REQUEST_TYPES_WORKFLOWS_SHOW_FULFILLED = 'REQUEST_TYPES_WORKFLOWS_SHOW_FULFILLED'
export const REQUEST_TYPES_WORKFLOWS_SHOW_REJECTED = 'REQUEST_TYPES_WORKFLOWS_SHOW_REJECTED'

export const REQUEST_TYPES_WORKFLOWS_POST = 'REQUEST_TYPES_WORKFLOWS_POST'
export const REQUEST_TYPES_WORKFLOWS_POST_PENDING = 'REQUEST_TYPES_WORKFLOWS_POST_PENDING'
export const REQUEST_TYPES_WORKFLOWS_POST_FULFILLED = 'REQUEST_TYPES_WORKFLOWS_POST_FULFILLED'
export const REQUEST_TYPES_WORKFLOWS_POST_REJECTED = 'REQUEST_TYPES_WORKFLOWS_POST_REJECTED'

export const REQUEST_TYPES_WORKFLOWS_PUT = 'REQUEST_TYPES_WORKFLOWS_PUT'
export const REQUEST_TYPES_WORKFLOWS_PUT_PENDING = 'REQUEST_TYPES_WORKFLOWS_PUT_PENDING'
export const REQUEST_TYPES_WORKFLOWS_PUT_FULFILLED = 'REQUEST_TYPES_WORKFLOWS_PUT_FULFILLED'
export const REQUEST_TYPES_WORKFLOWS_PUT_REJECTED = 'REQUEST_TYPES_WORKFLOWS_PUT_REJECTED'

export const REQUEST_TYPES_WORKFLOWS_DELETE = 'REQUEST_TYPES_WORKFLOWS_DELETE'
export const REQUEST_TYPES_WORKFLOWS_DELETE_PENDING = 'REQUEST_TYPES_WORKFLOWS_DELETE_PENDING'
export const REQUEST_TYPES_WORKFLOWS_DELETE_FULFILLED = 'REQUEST_TYPES_WORKFLOWS_DELETE_FULFILLED'
export const REQUEST_TYPES_WORKFLOWS_DELETE_REJECTED = 'REQUEST_TYPES_WORKFLOWS_DELETE_REJECTED'

export const PAYRUN_APPROVALS_LIST_LOAD = 'PAYRUN_APPROVALS_LIST_LOAD'
export const PAYRUN_APPROVALS_LIST_LOAD_PENDING = 'PAYRUN_APPROVALS_LIST_LOAD_PENDING'
export const PAYRUN_APPROVALS_LIST_LOAD_FULFILLED = 'PAYRUN_APPROVALS_LIST_LOAD_FULFILLED'
export const PAYRUN_APPROVALS_LIST_LOAD_REJECTED = 'PAYRUN_APPROVALS_LIST_LOAD_REJECTED'
