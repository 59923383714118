import { ChangeEvent } from 'react'
import { Spacer, Checkbox, Flex } from '@jisr-hr/ds'
import { ListDataTypes } from 'types/sharedComponents'

type CheckListPropsTypes = {
  labelKey?: string
  selected: Array<number>
  valueKey?: string
  data: ListDataTypes
  onChange: (data: { checked: boolean; value: number; testId?: string }) => void
}

const CheckItem = (props: CheckListPropsTypes): JSX.Element => {
  const { data, selected, labelKey = 'label', valueKey = 'value', onChange } = props
  const isSelected = Number(data?.[valueKey])
    ? selected.includes(Number(data?.[valueKey]))
    : selected.includes(data?.[valueKey])
  const handleOnChange = (da: ListDataTypes, chk?: boolean): void => {
    const checked = Boolean(chk)
    onChange({ value: da?.[valueKey], checked })
  }
  return (
    <>
      {data?.[valueKey] && (
        <>
          <Spacer height={8} />
          <Flex>
            <li
              style={{ listStyle: 'none' }}
              id={`item${data?.[valueKey]}`}
            >
              <Checkbox
                label={data?.[labelKey]}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleOnChange(data, e.target.checked)
                }
                active={isSelected}
                testId={data.testId}
              />
            </li>
          </Flex>
        </>
      )}
    </>
  )
}

export default CheckItem
