import i18n from 'translations/i18n'
import { createAnalysisReducer } from 'redux/analysis/helper'
import * as actions from './actions'

export default createAnalysisReducer({
  filter: actions.SET_ASSETS_TYPES_COST_FILTERS,
  pending: actions.ASSETS_TYPES_COST_LOAD_PENDING,
  fulfilled: actions.ASSETS_TYPES_COST_LOAD_FULFILLED,
  rejected: actions.ASSETS_TYPES_COST_LOAD_REJECTED,
  filterLabel: i18n.t('all'),
})
