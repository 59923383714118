import { Trans } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { Modal } from '@jisr-hr/design-system'
import ChangePasswordForm from 'containers/public/components/ChangePasswordForm'
import { useDispatch, useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { changePassword } from 'redux/authUser/actionCreators'
import { Spacer, Typography } from '@jisr-hr/ds'

type ChangePasswordModalProps = {
  isOpen: boolean
  onClose: () => void
}

type PasswordForm = {
  current_password: string
  new_password: string
  confirm_password: string
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps): JSX.Element => {
  const { security_policies } = useSelector((s) => s.securityReducer)
  const { fetching } = useSelector((s) => s.auth)
  const dispatch = useDispatch()
  const hasSecurityPolicy =
    security_policies.password_min_length?.enabled ||
    security_policies.password_complexity_requirement?.enabled

  const handleChangePassword = (data: PasswordForm): void => {
    dispatch(changePassword(data)).then(() => {
      onClose()
    })
  }

  return (
    <Modal
      onClose={onClose}
      header={
        <Typography
          text={I18n.t('change_password')}
          variant="title-1"
        />
      }
      isOpen={isOpen}
      variant="centerDialog"
    >
      <Grid container>
        {hasSecurityPolicy && (
          <Grid
            item
            xs={5}
            style={{ padding: 25 }}
          >
            {security_policies.password_min_length?.enabled && (
              <Typography
                text={
                  <Trans
                    i18nKey="minimum_password_length_note"
                    components={{ b: <b /> }}
                  />
                }
                variant="body-new/regular"
              />
            )}
            <Spacer height="20px" />
            {security_policies.password_complexity_requirement?.enabled && (
              <Typography
                text={
                  <Trans
                    i18nKey="password_complexity_note"
                    components={{ b: <b /> }}
                  />
                }
                variant="body-new/regular"
              />
            )}
          </Grid>
        )}
        <Grid
          item
          xs={hasSecurityPolicy ? 7 : 12}
          style={{ padding: '0 20px 0 40px' }}
        >
          <ChangePasswordForm
            fetching={fetching}
            onSubmit={handleChangePassword}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ChangePasswordModal
