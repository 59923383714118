import { useEffect, useState } from 'react'
import { format } from 'date-fns'

type CounterType = {
  minutes: string
  seconds: string
}

const getTimeLeft = (updatedAt: string): CounterType | null => {
  let timeLeft = null

  if (!updatedAt) return timeLeft

  const updatedDate = format(new Date(updatedAt), 'yyyy-MM-dd hh:mm:ss')
  const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
  const time = +new Date(updatedDate) + 240000
  const difference = time - +new Date(now)

  if (difference > 0) {
    timeLeft = {
      minutes:
        Math.floor((difference / 1000 / 60) % 60) < 10
          ? `0${Math.floor((difference / 1000 / 60) % 60)}`
          : String(Math.floor((difference / 1000 / 60) % 60)),
      seconds:
        Math.floor((difference / 1000) % 60) < 10
          ? `0${Math.floor((difference / 1000) % 60)}`
          : String(Math.floor((difference / 1000) % 60)),
    }
  }

  return timeLeft
}

const useOtpCounter = (updatedAt?: string): CounterType | null => {
  const [timeLeft, setTimeLeft] = useState<CounterType | null>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeft(updatedAt || ''))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return timeLeft
}

export default useOtpCounter
