import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Badge, Spacer } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { isEmpty } from 'lodash'

import Frame from '../../ds/Frame'
import Attachment from '../Attachment'
import Styles from './Reason.module.css'

const Reason = ({ details, title }) => {
  const showHeaderLine =
    (details.reason || !isEmpty(details.attachments)) && details?.reason_type_i18n

  if (!details.reason && isEmpty(details.attachments) && !details?.reason_type_i18n) {
    return null
  }

  return (
    <Frame
      title={title}
      showHeaderLine={showHeaderLine}
      style={!details.reason && isEmpty(details.attachments) && Styles.reasonContent}
      {...(details?.reason_type_i18n && {
        titleSuffix: (
          <Badge
            label={details.reason_type_i18n}
            variant="tinted"
            color="gray"
            size="small"
          />
        ),
      })}
    >
      <>
        <Typography
          className={Styles.reason}
          text={I18n.t(details?.reason)}
          style={{ color: 'var(--color-base-colors-black-500)' }}
          variant="subheading"
        />
        {details?.justification && (
          <Typography
            className={Styles.reason}
            text={details?.justification}
            style={{ color: 'var(--color-base-colors-black-500)' }}
            variant="subheading"
          />
        )}
        <Spacer height={8} />
        {!isEmpty(details.attachments) &&
          details?.attachments?.map((item, i) => {
            if (item.url) {
              return (
                <div
                  className={Styles.attachContainer}
                  key={i}
                >
                  <Attachment {...item} />
                </div>
              )
            }
            return null
          })}
      </>
    </Frame>
  )
}

Reason.defaultProps = {
  title: I18n.t('reason'),
}

Reason.propTypes = {
  title: PropTypes.string,
  details: PropTypes.shape(),
}

export default Reason
