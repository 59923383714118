import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const isOnboarding = window.location.href.includes('/onboarding')
const urlPrefix = isOnboarding ? 'onboarding/payroll/' : ''

export function mudadFlowStepsLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${id}/flow_steps`
  return {
    type: actions.MUDAD_FLOW_STEPS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function mudadRegisterEstablishment(data: { registration_id: number }): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments`
  return {
    type: actions.MUDAD_REGISTER_ESTABLISHMENT,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function createMudadSignatory(registration_id: number, data = {}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${registration_id}/signatories`
  return {
    type: actions.MUDAD_SIGNATORIES_CREATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function mudadSignatoriesLoad(registration_id: number, params = {}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${registration_id}/signatories`
  return {
    type: actions.MUDAD_SIGNATORIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function mudadSignatoriesVerify(
  registration_id: number,
  sin_id: number,
  data: { otp: string; is_owner?: boolean },
): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${registration_id}/signatories/${sin_id}/verify_activation`

  return {
    type: actions.MUDAD_SIGNATORIES_VERIFY,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function mudadVerifyEmployeesLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${id}/employees`
  return {
    type: actions.MUDAD_ALL_VERIFY_EMPLOYEES,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function mudadAllAuthorizedEmployeesLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${id}/banks/all_authorized_employees`
  return {
    type: actions.MUDAD_ALL_AUTHORIZED_EMPLOYEES,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function mudadAuthorizeCreate(data: {
  bank_account_id: number
  mudad_signatory_id: number
  registration_id: number
}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/bank_authorizations/authorize_pms`

  return {
    type: actions.MUDAD_AUTHORIZED_PMS_CREATE,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function mudadSignatoryDelete(registration_id: number, id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${registration_id}/signatories/${id}`
  return {
    type: actions.MUDAD_SIGNATORIES_DELETE,
    payload: new Promise((resolve, reject) =>
      handleAPI(url, {}, 'DELETE')
        ?.then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        }),
    ),
  }
}

export function mudadAuthorizerDelete(data: {
  mudad_signatory_id: number
  registration_id: number
  mudad_bank_authorization_id: number
}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/bank_authorizations/remove_authorization`
  return {
    type: actions.MUDAD_AUTHORIZER_DELETE,
    payload: new Promise((resolve, reject) =>
      handleAPI(url, {}, 'DELETE', data)
        ?.then((response) => {
          resolve({ id: data.mudad_bank_authorization_id, ...response })
        })
        .catch((err) => {
          reject(err)
        }),
    ),
  }
}

export function mudadVerifyAuthorizer(data: {
  mudad_bank_authorization_id: number
  mudad_signatory_id: number
  otp: string
  registration_id: number
}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/bank_authorizations/verify_pms_authorization`

  return {
    type: actions.MUDAD_AUTHORIZED_PMS_VERIFY,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function itemPriceLoad(): Action {
  const prefix = isOnboarding ? 'onboarding/' : ''
  const url = `/${API_VERSION_TWO}/${prefix}/item_price`
  return {
    type: actions.ITEM_PRICE_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function mudadRevokeEstablishment(id: number): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}/integration/mudad/establishments/${id}`
  return {
    type: actions.MUDAD_REVOKE_ESTABLISHMENT,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function resetMudadState(): Action {
  return {
    type: actions.RESET_MUDAD_STATE,
  }
}
