import { useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Spacer, Flex, Button, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { Form, TextInputField, SwitchField } from 'components/final-form'
import BoxWithSwitch from 'containers/public/Onboarding/components/BoxWithSwitch'
import { onboardingAnnualLeaveEntitlementLoad } from 'redux/leavesOnboarding/countingDays/actionCreators'
import { onboardingApplyingLeavesUpdate } from 'redux/leavesOnboarding/ApplyingLeaves/actionCreators'

export default function EntitlementAutoConvert(): JSX.Element {
  const dispatch = useDispatch()
  const { annual_leave_entitlement } = useSelector(
    ({ onboardingCountingDays }) => onboardingCountingDays,
  )

  useEffect(() => {
    dispatch(onboardingAnnualLeaveEntitlementLoad())
  }, [dispatch])

  const onSubmit = (values: { is_applicable: boolean; name: string }): void => {
    dispatch(onboardingApplyingLeavesUpdate('annual_leave_entitlement_auto_convert', values)).then(
      () => dispatch(onboardingAnnualLeaveEntitlementLoad()),
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...annual_leave_entitlement,
      }}
      render={({ handleSubmit, values, pristine }): JSX.Element => (
        <BoxWithSwitch
          title={I18n.t('annual_leave_auto_upgrade')}
          switchInput={
            <>
              <SwitchField name="is_applicable" />
            </>
          }
          content={
            <>
              <Typography
                variant="interface/default/m"
                text={I18n.t('annual_leave_auto_upgrade_description')}
              />
              <Spacer height="16px" />
              {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
              <TextInputField
                name="value"
                label={I18n.t('minimum_value')}
                style={{ width: 250 }}
                disabled={!values.is_applicable}
              />
              <Flex justifyEnd>
                <Button
                  label={I18n.t('save')}
                  onClick={(): void => {
                    handleSubmit()
                  }}
                  type="submit"
                  size="small"
                  disabled={pristine}
                />
              </Flex>
            </>
          }
        />
      )}
    />
  )
}
