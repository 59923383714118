import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { HTTPValidationErrorATS } from 'apis/ATS/ATSCommanTypes'
import { Toastr } from 'components/global/Toastr'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import {
  TAnnouncementRecipientsAxiosResponse,
  TAnnouncementRecipientsParams,
  TAnnouncementComment,
  TAnnouncementRecipientAxiosResponse,
} from './useAnnouncementSeg.type'

// ? query keys
const announcementsKey = (params?: TAnnouncementRecipientsParams): QueryKey => [
  'get_announcement_recipients',
  params,
]
const singleAnnouncementKey = (params?: number): QueryKey => ['get_single_announcement', params]
// ? functions
export const getAnnouncementRecipients = (
  params: TAnnouncementRecipientsParams,
): Promise<TAnnouncementRecipientsAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/announcement_recipients`, { params })
}

const markAnnouncementAsRead = (
  params: TAnnouncementRecipientsParams,
): Promise<TAnnouncementRecipientsAxiosResponse> => {
  return api.put(
    `/${API_VERSION_TWO}/hr/announcement_recipients/mark_as_read
`,
    params,
  )
}
const addComment = (
  params: TAnnouncementComment,
): Promise<TAnnouncementRecipientsAxiosResponse> => {
  return api.post(
    `/${API_VERSION_TWO}/comments/

`,
    { comment: params },
  )
}

export const getAnnouncementUnreadExists = (): Promise<TAnnouncementRecipientsAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/announcement_recipients/unread_exists`)
}
export const getAnnouncementById = (id: number): Promise<TAnnouncementRecipientsAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/announcement_recipients/${id}`)
}

// ? hooks

/**
 * @endpoint: GET: /v2/hr/announcement_recipients
 * @summary This hook used to list categories
 */
export const useAnnouncementRecipients = <
  TData = TAnnouncementRecipientsAxiosResponse['data']['data'],
  TError = HTTPValidationError,
>(
  params: TAnnouncementRecipientsParams,
  opts?: {
    query?: UseQueryOptions<TAnnouncementRecipientsAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? announcementsKey(params),
    queryFn: () => getAnnouncementRecipients(params),
  })
}

export const useGetSingleAnnouncement = <
  TData = TAnnouncementRecipientAxiosResponse['data']['data'],
  TError = HTTPValidationError,
>(
  params: number,
  opts?: {
    query?: UseQueryOptions<any, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? singleAnnouncementKey(params),
    queryFn: () => getAnnouncementById(params),
  })
}

/**
 * @endpoint: GET: /v2/hr/announcement_recipients
 * @summary This hook used to list categories
 */
export const useAnnouncementUnreadExists = <
  TData = TAnnouncementRecipientsAxiosResponse,
  TError = HTTPValidationError,
>(opts?: {
  query?: UseQueryOptions<TAnnouncementRecipientsAxiosResponse, TError, TData>
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? announcementsKey(),
    queryFn: () => getAnnouncementUnreadExists(),
  })
}

/**
 * @endpoint: PUT: /v2/hr/announcement_recipients/mark_as_read
 * @summary This hook used to change reminder status
 */
export const useMarkAnnouncementAsRead = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<
    TAnnouncementRecipientsAxiosResponse,
    TError,
    TAnnouncementRecipientsParams
  >
}) => {
  return useMutation({
    mutationFn: (payload: TAnnouncementRecipientsParams) => markAnnouncementAsRead(payload),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
    },
    onError: (err) => {
      const errorMessage = err?.response?.data?.message || 'An error occurred'
      Toastr.error(errorMessage)
    },
    ...opts?.mutation,
  })
}

/**
 * @endpoint: POST: /v2/comments
 * @summary This hook used to send candidate CV
 */

export const useAddComment = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<
    TAnnouncementRecipientsAxiosResponse,
    TError,
    { comment: TAnnouncementComment }
  >
}) => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: ({ comment }) => addComment(comment),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
    },
    onError: (err, variables, context) => {
      opts?.mutation?.onError?.(err, variables, context)
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}
