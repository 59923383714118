import React from 'react'
import PropTypes from 'prop-types'

const HOC = (WrappedComponent) => {
  class Switch extends React.Component {
    state = { active: false }

    // sync the value from props to state
    static getDerivedStateFromProps(props) {
      if (props.active !== undefined) return { active: props.active }
      return null
    }

    handleToggle = () => {
      const active = !this.state.active
      this.setState({ active })
      this.props.onChange(active)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleToggle={this.handleToggle}
          active={this.state.active}
        />
      )
    }
  }

  Switch.propTypes = {
    onChange: PropTypes.func,
    active: PropTypes.bool,
  }
  Switch.defaultProps = {
    onChange: () => {},
  }
  return Switch
}

export default HOC
