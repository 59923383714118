export const PAYRUN_ADJUSTMENT_CREATE = 'PAYRUN_ADJUSTMENT_CREATE'
export const PAYRUN_ADJUSTMENT_CREATE_PENDING = 'PAYRUN_ADJUSTMENT_CREATE_PENDING'
export const PAYRUN_ADJUSTMENT_CREATE_FULFILLED = 'PAYRUN_ADJUSTMENT_CREATE_FULFILLED'
export const PAYRUN_ADJUSTMENT_CREATE_REJECTED = 'PAYRUN_ADJUSTMENT_CREATE_REJECTED'

export const PAYRUN_ADJUSTMENT_UPDATE = 'PAYRUN_ADJUSTMENT_UPDATE'
export const PAYRUN_ADJUSTMENT_UPDATE_PENDING = 'PAYRUN_ADJUSTMENT_UPDATE_PENDING'
export const PAYRUN_ADJUSTMENT_UPDATE_FULFILLED = 'PAYRUN_ADJUSTMENT_UPDATE_FULFILLED'
export const PAYRUN_ADJUSTMENT_UPDATE_REJECTED = 'PAYRUN_ADJUSTMENT_UPDATE_REJECTED'

export const PAYRUN_ADJUSTMENT_DELETE = 'PAYRUN_ADJUSTMENT_DELETE'
export const PAYRUN_ADJUSTMENT_DELETE_PENDING = 'PAYRUN_ADJUSTMENT_DELETE_PENDING'
export const PAYRUN_ADJUSTMENT_DELETE_FULFILLED = 'PAYRUN_ADJUSTMENT_DELETE_FULFILLED'
export const PAYRUN_ADJUSTMENT_DELETE_REJECTED = 'PAYRUN_ADJUSTMENT_DELETE_REJECTED'
