import { useContext } from 'react'
import { Typography, Spacer, Flex, Button } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Avatar from '@material-ui/core/Avatar'
import { EditBank } from 'containers/public/Onboarding/types/onboardingTypes'
import { TCommercialRegistration } from 'redux/organizationOnborading/commercialRegistrations/reducer'

import styles from '../styles.module.css'
import { OnboardingContext } from '../../../../context'

type PayrollBankCardProps = {
  commercialRegistration: TCommercialRegistration | EditBank
  onEditClick?: (isOpen: boolean) => void
}

const PayrollBankCard = (props: PayrollBankCardProps): JSX.Element => {
  const { setEditBank } = useContext(OnboardingContext)
  const { commercialRegistration } = props

  const bank_accounts = commercialRegistration?.bank_accounts || []
  return (
    <div className={styles.crCard}>
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-grey-400)' }}
        text={I18n.t('cr_registration_number')}
      />
      <Typography
        variant="body-new/regular"
        text={commercialRegistration.registration_number}
      />
      <Spacer height={8} />
      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-grey-400)' }}
        text={I18n.t('cr_name')}
      />
      <Typography
        variant="body-new/regular"
        text={commercialRegistration.name}
      />
      <Spacer height={8} />
      <Flex
        justifyBetween
        itemsCenter
        className={styles.bankContainer}
      >
        <div>
          <Typography
            variant="subtitle-2"
            style={{ color: 'var(--color-base-colors-grey-400)' }}
            text={I18n.t('bank_name')}
          />

          <Typography
            variant="body-new/regular"
            text={bank_accounts[0]?.bank_name || '-'}
          />
        </div>
        {bank_accounts[0]?.bank_logo && (
          <Avatar
            style={{ width: 24, height: 24 }}
            src={bank_accounts[0]?.bank_logo?.logo_thumb}
            alt={bank_accounts[0]?.bank_name}
          />
        )}
      </Flex>
      <Spacer height={8} />
      <Typography
        variant="subtitle-2"
        style={{ color: 'var(--color-base-colors-grey-400)' }}
        text={I18n.t('iban_onboarding')}
      />

      <Typography
        variant="interface/default/sm"
        text={bank_accounts[0]?.iban || '-'}
      />
      <Spacer height={38} />
      {props.onEditClick && (
        <>
          <Spacer height={38} />
          <Flex justifyCenter>
            <Button
              label={
                commercialRegistration.bank_accounts?.length !== 0
                  ? I18n.t('enter_iban')
                  : I18n.t('add_bank_account')
              }
              size="small"
              onClick={(): void => {
                setEditBank(commercialRegistration)
                props.onEditClick?.(true)
              }}
              color={commercialRegistration.bank_accounts?.length !== 0 ? 'neutral' : 'primary'}
              variant={commercialRegistration.bank_accounts?.length !== 0 ? 'outlined' : 'filled'}
              className={styles.csBtn}
            />
          </Flex>
        </>
      )}
    </div>
  )
}

export default PayrollBankCard
