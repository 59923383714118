import { Grid } from '@jisr-hr/ds-beta'
import { Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import { NewFileUploadField, BetaTextInputField as TextInputField } from 'components/final-form'
import { useForm, useFormState } from 'react-final-form'
import { fixedLength, naturalNumber } from 'components/global/form/FormValidations'
import { hasPendingChanges } from 'components/authorised/employee/helper'
import { renderFieldMessages } from 'containers/authorised/employee/EmployeeDetails/helper'
import { OnboardingSubmittingForm } from 'apis/PeopleManagement/OnboardingEmployee/data_completion/useOnboardingEmployeeForm.type'

export const SaudiAddress = () => {
  const { change } = useForm()
  const { values } = useFormState<OnboardingSubmittingForm>()

  const gridItemProps = {
    item: true,
    columnSpacing: 3,
  }
  const {
    employee: { pending_changes },
  } = useSelector(({ employee }) => employee)

  const { changeset: pendingChanges } = pending_changes ?? {}
  const addressKeys = 'address'

  return (
    <div className="flex flex-col gap-8">
      <Typography
        text={i18n.t('label.address_in_saudi')}
        variant="title-1"
      />
      <Grid
        container
        rowSpacing={20}
      >
        <Grid {...gridItemProps}>
          <TextInputField
            testId="building-number-field"
            name={`${addressKeys}.building_number`}
            label={i18n.t('building_number')}
            {...(hasPendingChanges('building_number', pendingChanges) && { status: 'destructive' })}
            statusMessage={renderFieldMessages('building_number', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="street-name-field"
            name={`${addressKeys}.street_name`}
            label={i18n.t('street_name')}
            {...(hasPendingChanges('street_name', pendingChanges) && { status: 'destructive' })}
            statusMessage={renderFieldMessages('street_name', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="district-field"
            name={`${addressKeys}.district`}
            label={i18n.t('district')}
            {...(hasPendingChanges('district', pendingChanges) && { status: 'destructive' })}
            statusMessage={renderFieldMessages('district', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="city-field"
            name={`${addressKeys}.saudi_city`}
            label={i18n.t('city')}
            {...(hasPendingChanges('saudi_city', pendingChanges) && { status: 'destructive' })}
            statusMessage={renderFieldMessages('saudi_city', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="postal-code-field"
            name={`${addressKeys}.saudi_postal_code`}
            label={i18n.t('postal_code')}
            {...(hasPendingChanges('saudi_postal_code', pendingChanges) && {
              status: 'destructive',
            })}
            statusMessage={renderFieldMessages('saudi_postal_code', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            testId="national_short_address"
            name={`${addressKeys}.national_short_address`}
            label={i18n.t('label.short_address')}
            {...(hasPendingChanges('national_short_address', pendingChanges) && {
              status: 'destructive',
            })}
            statusMessage={
              renderFieldMessages('national_short_address', pendingChanges) || undefined
            }
            validate={fixedLength(8)}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <NewFileUploadField
            initFiles={
              values?.address?.proof_attachment
                ? [
                    {
                      file: values.address.proof_attachment[0]?.data,
                      name: values.address.proof_attachment[0]?.filename,
                      id: String(values.address.proof_attachment[0]?.id),
                      status: 'success',
                    },
                  ]
                : undefined
            }
            compact
            label={i18n.t('add_attachment')}
            name={`${addressKeys}.proof_attachment[0].data`}
            accept=".pdf, image/*"
            descriptionMessage={i18n.t('communication.supported_formats', { count: 50 })}
            onLoad={(e): void => {
              change(`${addressKeys}.proof_attachment[0].filename`, e?.[0]?.name)
              change(`${addressKeys}.proof_attachment[0].id`, e?.[0]?.id)
            }}
            onRemove={(file) => {
              if (!naturalNumber(file.id)) {
                change(`${addressKeys}.proof_attachment`, [
                  ...values?.address?.proof_attachment,
                  ...(file?.id === 'undefined'
                    ? []
                    : [
                        {
                          data: file?.file,
                          filename: file.name,
                          id: file.id,
                          delete: true,
                        },
                      ]),
                ])
              } else {
                change(
                  `${addressKeys}.proof_attachment`,
                  values?.address?.proof_attachment?.filter((it) => it.id !== file.id),
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
