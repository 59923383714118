/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Typography, Button, Flex, Spacer } from '@jisr-hr/ds'
import PropTypes from 'prop-types'
import { format } from 'utils/date'
import { useSelector } from 'utils/hooks'
import Styles from './style.module.css'
import Attachment from '../Attachment'

const ExpenseClaimItem = ({ item, onEditClick, onDeleteClick, isForm }) => {
  const ecrAttachmentsLength = item?.ecr_attachments?.length
  const attachment = ecrAttachmentsLength || item?.ecr_attachments_attributes
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency.currency_type_i18n,
  )
  return (
    <div className={Styles.itemContainer}>
      <Flex justifyBetween>
        <Flex itemsCenter>
          <Typography
            text={item?.name}
            variant="interface/strong/sm"
          />
          {!isForm ? null : (
            <>
              <Spacer width={8} />
              <Button
                size="small"
                variant="outlined"
                color="neutral"
                onClick={onEditClick}
                iconOnly
                leadingIcon="edit-03"
              />
            </>
          )}
          {!isForm ? null : (
            <Button
              size="small"
              variant="outlined"
              color="neutral"
              onClick={onDeleteClick}
              iconOnly
              leadingIcon="trash-02"
            />
          )}
        </Flex>
        <Typography
          text={`${currency} ${item?.amount}`}
          variant="interface/strong/sm"
          data-private
        />
      </Flex>
      {item?.date ? (
        <>
          <Typography
            text={format(item.date, 'EEEE, d MMM')}
            variant="body-new/regular"
            style={{ color: 'var(--color-base-colors-grey-600)' }}
            data-private
          />
          <Spacer height={16} />
        </>
      ) : null}
      {item?.description ? (
        <Typography
          text={item.description}
          variant="body-new/regular"
          style={{ color: 'var(--color-base-colors-grey-600)' }}
          data-private
        />
      ) : null}
      {attachment ? (
        <>
          <Spacer height={16} />
          <Flex
            flexCol
            style={{ gap: '16px' }}
          >
            <Attachment
              url={
                ecrAttachmentsLength
                  ? item?.ecr_attachments[ecrAttachmentsLength - 1]?.attachment_url
                  : item?.ecr_attachments_attributes?.attachment ??
                    item?.ecr_attachments_attributes?.file
              }
              name={
                ecrAttachmentsLength
                  ? item?.ecr_attachments[ecrAttachmentsLength - 1]?.attachment_file_name
                  : item?.ecr_attachments_attributes?.attachment_file_name ??
                    item?.ecr_attachments_attributes?.name
              }
              size={
                ecrAttachmentsLength
                  ? item?.ecr_attachments[ecrAttachmentsLength - 1]?.attachment_file_size
                  : item?.ecr_attachments_attributes?.attachment_file_size ??
                    item?.ecr_attachments_attributes?.size
              }
              withBox
            />
          </Flex>
        </>
      ) : null}
    </div>
  )
}

ExpenseClaimItem.defaultProps = {
  onEditClick: () => {},
  onDeleteClick: () => {},
}

ExpenseClaimItem.propTypes = {
  item: PropTypes.shape(),
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isForm: PropTypes.bool,
}

export default ExpenseClaimItem
