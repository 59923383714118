import React from 'react'

import WorkflowSteps from './WorkflowSteps'
import Step from './Step'
import CreateStep from './CreateStep'

import './style.css'

export default class WorkflowStepsContainer extends React.Component {
  static Step = Step
  static CreateStep = CreateStep

  render() {
    return <WorkflowSteps {...this.props} />
  }
}
