import { Button, Spacer, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { Form, TextInputField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import { SubmitNewEmploymentType } from '../EmploymentType.types'

export type TProps = { onSubmit: (data: SubmitNewEmploymentType) => void; fetching: boolean }

const PageNwe = ({ onSubmit, fetching }: TProps): JSX.Element => (
  <>
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      render={({ handleSubmit }): JSX.Element => (
        <>
          <Flex
            flexCol
            className="pb-[24px]"
          >
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              placeholder={I18n.t('name_in_english_onboarding')}
              name="name"
              autoFocus
              validate={required}
            />
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              placeholder={I18n.t('name_in_arabic')}
              name="name_ar"
              validate={required}
            />
            <Spacer height="16px" />
            <Flex itemsCenter>
              <Button
                label={I18n.t('save')}
                onClick={handleSubmit}
                disabled={fetching}
                size="medium"
              />
            </Flex>
          </Flex>
        </>
      )}
    />
  </>
)

export default PageNwe
