export const SATAISTICS_LOAD = 'SATAISTICS_LOAD'
export const SATAISTICS_LOAD_PENDING = 'SATAISTICS_LOAD_PENDING'
export const SATAISTICS_LOAD_FULFILLED = 'SATAISTICS_LOAD_FULFILLED'
export const SATAISTICS_LOAD_REJECTED = 'SATAISTICS_LOAD_REJECTED'

export const PENDING_REQUESTS_AND_TASKS_COUNT = 'PENDING_REQUESTS_AND_TASKS_COUNT'
export const PENDING_REQUESTS_AND_TASKS_COUNT_PENDING = 'PENDING_REQUESTS_AND_TASKS_COUNT_PENDING'
export const PENDING_REQUESTS_AND_TASKS_COUNT_FULFILLED =
  'PENDING_REQUESTS_AND_TASKS_COUNT_FULFILLED'
export const PENDING_REQUESTS_AND_TASKS_COUNT_REJECTED = 'PENDING_REQUESTS_AND_TASKS_COUNT_REJECTED'

export const REQUESTS_SATAISTICS_LOAD = 'REQUESTS_SATAISTICS_LOAD'
export const REQUESTS_SATAISTICS_LOAD_PENDING = 'REQUESTS_SATAISTICS_LOAD_PENDING'
export const REQUESTS_SATAISTICS_LOAD_FULFILLED = 'REQUESTS_SATAISTICS_LOAD_FULFILLED'
export const REQUESTS_SATAISTICS_LOAD_REJECTED = 'REQUESTS_SATAISTICS_LOAD_REJECTED'
