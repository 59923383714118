import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import arrayMutators from 'final-form-arrays'
import { isEmpty } from 'lodash'
// import { requestsWithCancellationFlow } from 'components/global/templates/RequestDrawer/helper';

const RequestContext = React.createContext()

const RequestProvider = ({ children, vars, active, actionCallback, requestType: reqType }) => {
  const { request: req } = useSelector(({ requestDetails }) => requestDetails)

  const [open, setOpen] = React.useState(false)
  const [disableSubmit, setDisableSubmit] = React.useState(false)
  const [canApprove, setCanApprove] = React.useState(true)
  const [openNext, setOpenNext] = React.useState(false)
  const [showFooter, setShowFooter] = React.useState(true)
  const [onSubmit, setSubmit] = React.useState(() => () => {})
  const [onReject, setReject] = React.useState(() => () => {})
  const [onCancel, setCancel] = React.useState(() => () => {})
  const [onSuperAdminCancel, setSuperAdminCancel] = React.useState(() => () => {})
  const [initialValues, setInitialValues] = React.useState({})
  const [employeeId, setEmployeeId] = React.useState(vars?.id)
  const [employee, setEmployee] = React.useState(vars?.employee)
  const [requestType, setRequestType] = React.useState()
  const [showPaymentDate, setShowPaymentDate] = React.useState(Boolean(vars?.showPaymentDate))
  const [editRequest, setEditRequest] = React.useState(false)
  const [enableHittingRequestListener, setEnableHittingRequestListener] = React.useState(true)

  const request = vars?.request || req

  // const {
  //   employee: {
  //     role: {
  //       is_super_admin: isSuperAdmin,
  //     } = {},
  //   } = {},
  // } = useSelector(({ auth }) => auth || {});
  let formHandler

  const drawerToggle = (val) => {
    if (typeof val === 'boolean') {
      return setOpen(val)
    }
    return setOpen(!open)
  }

  const handleDisableSubmit = (v) => {
    setDisableSubmit(v)
  }

  const setOnSubmit = (callback) => {
    setSubmit(() => callback)
  }

  const setOnReject = (callback) => {
    setReject(() => callback)
  }

  const setOnCancel = (callback) => {
    setCancel(() => callback)
  }

  const validate = (values) => {
    const errors = {}

    if (values.disableSubmit) {
      errors.disableSubmit = true
    }

    return errors
  }

  const setOnSuperAdminCancel = (callback) => {
    setSuperAdminCancel(() => callback)
  }

  React.useEffect(() => {
    if (!open) {
      formHandler.reset()
      setEmployeeId(null)
      setEmployee({})
      setRequestType(null)
      setCanApprove(true)
      setShowFooter(false)
      setDisableSubmit(false)
      setShowPaymentDate(false)
    } else if (vars?.isCreate || vars?.id) {
      setShowFooter(true)
    }
  }, [open])

  React.useEffect(() => {
    if (open !== !!active) {
      drawerToggle(!!active)
    }
  }, [active])

  React.useEffect(() => {
    setRequestType(reqType)
  }, [reqType, active])

  React.useEffect(() => {
    setShowPaymentDate(Boolean(vars?.showPaymentDate))
  }, [vars?.showPaymentDate])

  React.useEffect(() => {
    if (!isEmpty(request)) {
      // const {
      //   can_mark_as_completed,
      //   can_take_action,
      //   view_only,
      //   can_cancel,
      //   isTask,
      // } = request || {};

      // const isApproved = request?.status?.toLowerCase() === 'approved';
      // const showCancellationFlow = isApproved &&
      //   requestsWithCancellationFlow.includes(request?.request_type);

      // const showCancelForAdmin = (isSuperAdmin && !isTask && showCancellationFlow);

      // do not add status case here its depend on tasks request
      // setShowFooter(can_mark_as_completed ||
      //   can_take_action ||
      //   can_cancel ||
      //   showCancelForAdmin ||
      //   (typeof view_only === 'boolean' && !view_only));

      setShowFooter(true) // always show footer
    }
  }, [request])

  return (
    <RequestContext.Provider
      value={{
        onReject,
        onCancel,
        onSuperAdminCancel,
        canApprove,
        setOnSubmit,
        setOnReject,
        setOnCancel,
        setOnSuperAdminCancel,
        setInitialValues,
        setCanApprove,
        handleDisableSubmit,
        disableSubmit,
        drawerToggle,
        actionCallback,
        open,
        showFooter,
        setEmployeeId,
        setEmployee,
        setShowPaymentDate,
        showPaymentDate,
        setRequestType,
        requestType,
        isOtherRequest: !requestType,
        canCancel: request?.can_cancel,
        editRequest,
        setEditRequest,
        setEnableHittingRequestListener,
        enableHittingRequestListener,
        vars: {
          id: employeeId,
          employee,
          ...vars,
        },
        setOpenNext,
        openNext,
      }}
    >
      <FinalForm
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
      >
        {({ form }) => {
          formHandler = form
          return children
        }}
      </FinalForm>
    </RequestContext.Provider>
  )
}

RequestProvider.propTypes = {
  children: PropTypes.node,
  vars: PropTypes.shape(),
  active: PropTypes.bool,
  actionCallback: PropTypes.func,
  requestType: PropTypes.string,
}

RequestProvider.defaultProps = {
  actionCallback: () => {},
}

export default RequestProvider

export const useRequest = () => React.useContext(RequestContext)
