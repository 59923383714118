export const RESET = 'RESET'

export const UNAVAILABLE_ATTENDANCE_RECORDS = 'UNAVAILABLE_ATTENDANCE_RECORDS'
export const UNAVAILABLE_ATTENDANCE_RECORDS_PENDING = 'UNAVAILABLE_ATTENDANCE_RECORDS_PENDING'
export const UNAVAILABLE_ATTENDANCE_RECORDS_FULFILLED = 'UNAVAILABLE_ATTENDANCE_RECORDS_FULFILLED'
export const UNAVAILABLE_ATTENDANCE_RECORDS_REJECTED = 'UNAVAILABLE_ATTENDANCE_RECORDS_REJECTED'

export const UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT = 'UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT'
export const UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_PENDING =
  'UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_PENDING'
export const UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_FULFILLED =
  'UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_FULFILLED'
export const UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_REJECTED =
  'UNAVAILABLE_ATTENDANCE_RECORDS_IMPORT_REJECTED'

export const UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT = 'UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT'
export const UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_PENDING =
  'UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_PENDING'
export const UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_FULFILLED =
  'UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_FULFILLED'
export const UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_REJECTED =
  'UNAVAILABLE_ATTENDANCE_RECORDS_EXPORT_REJECTED'
