import { Lens } from '@dhmk/zustand-lens'
import { AlertModalProps } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/modals/AlertModal'

export type PerformanceModalType = Omit<AlertModalProps, 'show'> & {
  value?: string
}

export type ModalSliceType = {
  modalOpen: PerformanceModalType | null
  setModalOpen: (modal: PerformanceModalType | null) => void
}

export const createModalSlice: Lens<ModalSliceType> = (set) => {
  return {
    modalOpen: null,
    setModalOpen: (modal) => set({ modalOpen: modal }),
  }
}
