import React from 'react'
import PropTypes from 'prop-types'

const Info = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
  >
    <path
      fill="#F5A623"
      fillRule="nonzero"
      d="M23.12 12.557l1.792-4.26a1.125 1.125 0 0 0-.601-1.474L20.05 5.03 18.304.75a1.125 1.125 0 0 0-1.467-.617l-4.28 1.746L8.297.088A1.125 1.125 0 0 0 6.822.69L5.03 4.95.75 6.696a1.124 1.124 0 0 0-.617 1.467l1.746 4.28-1.792 4.26a1.125 1.125 0 0 0 .601 1.474L4.95 19.97l1.746 4.28c.234.576.892.852 1.467.617l4.28-1.746 4.26 1.792a1.126 1.126 0 0 0 1.474-.601l1.792-4.261 4.28-1.746c.576-.234.852-.891.617-1.467l-1.746-4.28zm-9.434 5.3h-2.373v-2.295h2.373v2.296zm0-4.011h-2.373V7.143h2.373v6.703z"
    />
  </svg>
)

export const InfoCircle = ({ className, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    className={className}
  >
    <g
      fill={color || '#F5A623'}
      fillRule="evenodd"
    >
      <path
        fillRule="nonzero"
        d="M8.5 0A8.495 8.495 0 0 0 0 8.5C0 13.198 3.802 17 8.5 17S17 13.198 17 8.5 13.198 0 8.5 0zm0 15.672A7.168 7.168 0 0 1 1.328 8.5 7.168 7.168 0 0 1 8.5 1.328 7.168 7.168 0 0 1 15.672 8.5 7.168 7.168 0 0 1 8.5 15.672z"
      />
      <path d="M8 4c-.552 0-1 .318-1 .71v4.58c0 .392.448.71 1 .71s1-.318 1-.71V4.71C9 4.319 8.552 4 8 4z" />
      <circle
        cx="8"
        cy="12"
        r="1"
      />
    </g>
  </svg>
)

Info.propTypes = {
  className: PropTypes.string,
}

InfoCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
}

export default Info
