import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export type ATSPublicTemplate = {
  fetching: boolean
  nationality: actions.ATSPublicTemplateItem[]
  country: (actions.ATSPublicTemplateItem & {
    phone_code: string
  })[]
  industry_sector: actions.ATSPublicTemplateItem[]
  company_about_info?: actions.CompanyAboutInfo
  ats_persona: actions.ATSPublicTemplateItem[]
}

export const initialState: ATSPublicTemplate = {
  fetching: false,
  nationality: [],
  industry_sector: [],
  country: [],
  ats_persona: [],
}

export default function reducer(state = initialState, action: Action): ATSPublicTemplate {
  switch (action.type) {
    case actions.ATS_PUBLIC_TEMPLATES_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ATS_PUBLIC_TEMPLATES_FULFILLED: {
      return {
        ...state,
        nationality: action.payload.data.data.nationality,
        country: action.payload.data.data.country,
        industry_sector: action.payload.data.data.industry_sector,
        ats_persona: action.payload.data.data.ats_persona,
        company_about_info: action.payload.data.data.company_about_info,
        fetching: false,
      }
    }

    case actions.ATS_PUBLIC_TEMPLATES_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
