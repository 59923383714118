import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const status = {
  active: 'error_employees_count',
  incomplete: 'incomplete_employees_count',
  inactive: 'completed_employees_count',
}

const initialState = {
  fetching: false,
  total_available: 0,
  prevent_adding: false,
  subscription_quantity: 0,
  employees: [],
  pagination: {},
  total_employees: '',
  active_employees_count: 0,
  active_employees_with_email_count: 0,
  updatingEmployees: false,
  filters: {
    page: 1,
    rpp: 10,
    search: '',
    statuses: [1],
    department_ids: [],
    parent_department_ids: [],
    area_ids: [],
    business_unit_ids: [],
    is_data_correct: [],
    is_gosi_linked: [],
    job_title_ids: [],
    nationalities: [],
    location_ids: [],
    employment_type_ids: [],
    mol_registration_ids: [],
    status_employee: [],
    payment_methods: [],
    paygroup_ids: [],
    group_id: [],
  },
}

export default function reducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case actions.ALL_EMPLOYEES_LOAD_PENDING:
    case actions.ADDITION_AVAILABILITY_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.ONBOARDING_EMPLOYEE_CREATE_PENDING:
    case actions.ONBOARDING_EMPLOYEE_UPDATE_PENDING:
      return {
        ...state,
        updatingEmployees: true,
      }

    case actions.ONBOARDING_EMPLOYEE_CREATE_FULFILLED:
      return {
        ...state,
        total_employees: state.total_employees + 1,
        employees_count: state?.employees_count + 1,
        updatingEmployees: false,
      }
    case actions.ONBOARDING_EMPLOYEE_UPDATE_FULFILLED:
      return {
        ...state,
        updatingEmployees: false,
      }

    case actions.ALL_EMPLOYEES_LOAD_FULFILLED:
    case actions.ADDITION_AVAILABILITY_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }
    case actions.RESET:
      return {
        ...state,
        employees: [],
        fetching: false,
      }
    case actions.ONBOARDING_EMPLOYEE_UPDATE_REJECTED:
    case actions.ONBOARDING_EMPLOYEE_CREATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        updatingEmployees: false,
      }
    case actions.ALL_EMPLOYEES_LOAD_REJECTED:
    case actions.ADDITION_AVAILABILITY_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    // Filters Cases

    case actions.SET_EMPLOYEES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }

    case actions.CLEAR_EMPLOYEES_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      }

    case actions.LOCAL_UPDATE_EMPLOYEES_TAB:
      const { newStatus, prevStatus } = payload
      const sameThing = newStatus === prevStatus

      return {
        ...state,
        ...(!sameThing &&
          newStatus && {
            [status[newStatus]]: state[status[newStatus]] + 1,
          }),
        ...(!sameThing &&
          prevStatus &&
          state[status[prevStatus]] && {
            [status[prevStatus]]: state[status[prevStatus]] - 1,
          }),
      }

    default:
      return state
  }
}
