import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'
import { RetroactivesTypes } from './TypesRetroactive'

const initialState: RetroactivesTypes = {
  fetching: false,
  retroactives: [],
  pagination: {},
  employees_count: 0,
}

export default function reducer(state = initialState, action: Action): RetroactivesTypes {
  switch (action.type) {
    case actions.RESET_TIMESHEET_RETROACTIVE:
      return {
        ...state,
        retroactives: [],
        pagination: {},
      }

    case actions.TIMESHEET_RETROACTIVE_LISTING_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.TIMESHEET_RETROACTIVE_LISTING_FULFILLED:
      const timeSheetData: RetroactivesTypes = action.payload.data.data
      return {
        ...state,
        ...timeSheetData,
        retroactives: [...state.retroactives, ...timeSheetData.retroactives],
        fetching: false,
      }

    case actions.TIMESHEET_RETROACTIVE_LISTING_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
