import { Action } from 'types/redux'
import { TOrganizationStep } from 'containers/authorised/NewOnboarding/components/OrganizationSteps'
import * as actions from './actions'

export type InitialStateType = {
  path: string
  title: string
  list: TOrganizationStep[]
}

const initialState: InitialStateType = {
  path: '',
  title: '',
  list: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.SET_ONBOARDING_HEADER_STEPS:
      return {
        ...state,
        path: payload?.path,
        title: payload?.title,
        list: payload?.list,
      }

    case actions.RESET_ONBOARDING_HEADER_STEPS:
      return initialState

    default:
      return state
  }
}
