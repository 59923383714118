import { isEmpty, uniq } from 'lodash'
import i18n from 'translations/i18n'
import ATSJobOpenStatuses from './components/ATSJobOpenStatuses'
import Areas from './components/Areas'
import AssetTypes from './components/AssetTypes'
import AssetsStatus from './components/AssetsStatus'
import AtsHiringManager from './components/AtsHiringManager'
import AtsRecruiter from './components/AtsRecruiter'
import AttendanceStatus from './components/AttendanceStatus'
import BusinessUnits from './components/BusinessUnits'
import CandidateRating from './components/CandidateRating'
import CandidateResume from './components/CandidateResume'
import CandidateScreening from './components/CandidateScreening'
import CandidateSource from './components/CandidateSource'
import CandidateStatuses from './components/CandidateStatuses'
import CandidateTitle from './components/CandidateTitle'
import CommercialRegistrations from './components/CommercialRegistrations'
import ContractDuration from './components/ContractDuration'
import Country from './components/Country'
import Departments from './components/Departments'
import Group from './components/Group'
import DeviceStatus from './components/DeviceStatus'
import DocumentTypes from './components/DocumentTypes'
import Duration from './components/Duration'
import EmpAuditLogActions from './components/EmpAuditLogActions'
import EmployeeInvitationsStatus from './components/EmployeeInvitationsStatus'
import EmploymentType from './components/EmploymentType'
import EndorsementMemberType from './components/EndorsementMemberType'
import EndorsementRequestType from './components/EndorsementRequestType'
import ErrorStatus from './components/ErrorStatus'
import ExternalNationalities from './components/ExternalNationalities'
import FinalVacationStatus from './components/FinalVacationStatus'
import FormStatus from './components/FormStatus'
import FormType from './components/FormType'
import GosiLinked from './components/GosiLinked'
import GosiLinkedPaymentProcessing from './components/GosiLinkedPaymentProcessing'
import Grades from './components/Grades'
import IdTypes from './components/IdTypes'
import InsuranceClasses from './components/InsuranceClasses'
import InsuranceStatus from './components/InsuranceStatus'
import JobOpeningType from './components/JobOpeningType'
import JobTitles from './components/JobTitles'
import JoiningDate from './components/JoiningDate'
import FundDate from './components/FundDate'
import LoanTypes from './components/LoanTypes'
import Locations from './components/Locations'
import MemberLinked from './components/MemberLinked'
import MolRegistrations from './components/MolRegistrations'
import Nationalities from './components/Nationalities'
import Nationality from './components/Nationality'
import OutOfPayrollTypes from './components/OutOfPayrollTypes'
import OutsourcingCompanies from './components/OutsourcingCompanies'
import PaymentDetails from './components/PaymentDetails'
import PaymentStatus from './components/PaymentStatus'
import PaymentMethods from './components/PaymentMethods'
import PaymentProcessingMethods from './components/PaymentProcessingMethods'
import PayrollTransactionStatus from './components/PayrollTransactionStatus'
import PayrunStatus from './components/PayrunStatus'
import PayrunTypes from './components/PayrunTypes'
import PunchIn from './components/PunchIn'
import PunchOut from './components/PunchOut'
import Recurrence from './components/Recurrence'
import ReportingMethods from './components/ReportingMethods'
import RequestStatuses from './components/RequestStatuses'
import RequestTypes from './components/RequestTypes'
import RequestsPendingOn from './components/RequestsPendingOn'
import Reviewer from './components/Reviewer'
import ShiftType from './components/ShiftType'
import Shifts from './components/Shifts'
import StatusEmployee from './components/StatusEmployee'
import EnrollmentEligibility from './components/EnrollmentEligibility'
import EventExportHistory from './components/EventExportHistory'
import FileTypeExportHistory from './components/FileTypeExportHistory'
import StatusExportHistory from './components/StatusExportHistory'
import ExportTypeExportHistory from './components/ExportTypeExportHistory'
import RequestDate from './components/RequestDate'
import PaymentModes from './components/PaymentModes'
import Banks from './components/Banks'
import PayrollMonths from './components/PayrollMonths'
import InvoicesStatus from './components/InvoicesStatus'
import Payments from './components/Payments'
import IssuanceDate from './components/IssuanceDate'
import DueDate from './components/DueDate'
import ViolationStatus from './components/ViolationStatus'
import ViolationCategory from './components/ViolationCategory'
import ViolationReporter from './components/ViolationReporter'
import ActionTakenBy from './components/ActionTakenBy'
import ReportingDate from './components/ReportingDate'
import ContractType from './components/ContractType'
import DateRangePeriod from './components/DateRangePeriod'
import TimesheetStatus from './components/TimesheetStatus'
import TimesheetAssignedReviewer from './components/TimesheetAssignedReviewer'
import TimesheetAssignedPaygroup from './components/TimesheetAssignedPaygroup'
import TimesheetSettlement from './components/TimesheetSettlement'
import TimesheetEmployee from './components/TimesheetEmployee'
import DateMonthlyRange from './components/DateMonthlyRange'
import FlightBookingStatus from './components/FlightBookingStatus'
import WalletTransactionStatus from './components/WalletTransactionStatus'
import TransactionTypes from './components/TransactionTypes'
import WalletServicesTypes from './components/WalletServicesTypes'
import PaymentDates from './components/PaymentDates'
import PaygroupAndPayrun from './components/PaygroupAndPayrun'
import PickYearDate from './components/PickYearDate'
import PeriodOfTime from './components/PeriodOfTime'

export const filterComponent = [
  {
    title: 'area',
    component: Areas,
    filterKey: 'area_ids',
    extra_title: 'location_area',
  },
  {
    title: 'departments',
    component: Departments,
    filterKey: 'department_ids',
  },
  {
    title: 'group',
    component: Group,
    filterKey: 'group_id',
  },
  {
    title: 'grades',
    component: Grades,
    filterKey: 'grade_ids',
  },
  {
    title: 'form_type',
    component: FormType,
    filterKey: 'form_type',
  },
  {
    title: 'form_status',
    component: FormStatus,
    filterKey: 'form_status',
  },
  {
    title: 'frequency',
    component: Recurrence,
    filterKey: 'frequency',
  },
  {
    title: 'reviewer',
    component: Reviewer,
    filterKey: 'reviewer_ids',
  },
  {
    title: 'fund_dates',
    component: FundDate,
    filterKey: 'fund_dates',
  },
  {
    title: 'joining_dates',
    component: JoiningDate,
    filterKey: 'joining_dates',
  },
  {
    title: 'issuance_date',
    component: IssuanceDate,
    filterKey: 'issuance_date',
  },
  {
    title: 'due_date',
    component: DueDate,
    filterKey: 'due_date',
  },
  {
    title: 'grades',
    extra_title: 'grade_id',
    component: Grades,
    filterKey: 'grade_id',
  },
  {
    title: 'job_titles',
    component: JobTitles,
    filterKey: 'job_title_ids',
  },
  {
    title: 'job_title',
    extra_title: 'job_title_id',
    component: JobTitles,
    filterKey: 'job_title_id',
  },
  {
    title: 'nationalities',
    component: Nationalities,
    filterKey: 'nationalities',
  },
  {
    title: 'nationality',
    component: Nationality,
    filterKey: 'nationality',
  },
  {
    title: 'country',
    component: Country,
    filterKey: 'country',
  },
  {
    title: 'outsourcing_company',
    component: OutsourcingCompanies,
    filterKey: 'outsourcing_company_ids',
  },
  {
    title: 'mol_registrations',
    component: MolRegistrations,
    filterKey: 'mol_registration_ids',
  },
  {
    title: 'gosi_subscriptions',
    component: MolRegistrations,
    filterKey: 'gosi_subscriptions',
  },
  {
    title: 'shifts',
    component: Shifts,
    filterKey: 'shift_ids',
  },
  {
    title: 'gosi_linked',
    component: GosiLinked,
    filterKey: 'is_gosi_linked',
  },
  {
    title: 'payment_details',
    extra_title: 'uncorrect_data',
    component: PaymentDetails,
    filterKey: 'is_data_correct',
  },
  {
    title: 'locations',
    component: Locations,
    filterKey: 'location_ids',
  },
  {
    title: 'location',
    extra_title: 'location_id',
    component: Locations,
    filterKey: 'location_id',
  },
  {
    title: 'record_location',
    component: Locations,
    filterKey: 'record_location_ids',
  },
  {
    title: 'statuses',
    extra_title: 'status_employee',
    component: StatusEmployee,
    filterKey: 'status_employee',
  },
  {
    title: 'shift_type',
    component: ShiftType,
    filterKey: 'shift_type',
  },
  {
    title: 'business_unit',
    extra_title: 'business_unit_id',
    component: BusinessUnits,
    filterKey: 'business_unit_id',
  },
  {
    title: 'business_units',
    component: BusinessUnits,
    filterKey: 'business_unit_ids',
  },
  {
    title: 'department_id',
    component: Departments,
    filterKey: 'department_id',
  },
  {
    title: 'employment_types',
    component: EmploymentType,
    filterKey: 'employment_type_ids',
  },
  {
    title: 'employment_type_id',
    component: EmploymentType,
    filterKey: 'employment_type_id',
  },
  {
    title: 'job_openings',
    component: JobOpeningType,
    filterKey: 'job_openings',
  },
  {
    title: 'candidate_screening',
    component: CandidateScreening,
    filterKey: 'candidate_screening',
  },
  {
    title: 'candidate_title',
    component: CandidateTitle,
    filterKey: 'candidate_title',
  },
  {
    title: 'ats_recruiter',
    component: AtsRecruiter,
    filterKey: 'ats_recruiter',
  },
  {
    title: 'ats_hiring_manager',
    component: AtsHiringManager,
    filterKey: 'ats_hiring_manager',
  },
  {
    title: 'punch_in',
    component: PunchIn,
    filterKey: 'punch_in',
  },
  {
    title: 'punch_out',
    component: PunchOut,
    filterKey: 'punch_out',
  },
  {
    title: 'reporting_method',
    component: ReportingMethods,
    filterKey: 'reporting_method_ids',
  },
  {
    title: 'document_types',
    component: DocumentTypes,
    filterKey: 'document_type',
  },
  {
    title: 'payroll_month',
    component: PayrollMonths,
    filterKey: 'payroll_month',
  },
  {
    title: 'payment_statuses',
    component: PaymentStatus,
    filterKey: 'payment_statuses',
  },
  {
    title: 'payment_methods',
    component: PaymentMethods,
    filterKey: 'payment_methods',
  },
  {
    title: 'loan_types',
    component: LoanTypes,
    filterKey: 'loan_type_ids',
  },
  {
    title: 'payrun_types',
    component: PayrunTypes,
    filterKey: 'payrun_types',
  },
  {
    title: 'payrun_status',
    component: PayrunStatus,
    filterKey: 'payrun_status',
  },
  {
    title: 'filter_payrun_status',
    component: FinalVacationStatus,
    filterKey: 'filter_payrun_status',
  },
  {
    title: 'filter_attendance_status',
    component: AttendanceStatus,
    filterKey: 'filter_attendance_status',
  },
  {
    title: 'payroll_transaction_status',
    component: PayrollTransactionStatus,
    filterKey: 'payroll_transaction_status',
  },
  {
    title: 'out_of_payroll_types',
    component: OutOfPayrollTypes,
    filterKey: 'payroll_transaction_type_ids',
  },
  {
    title: 'paygroup_with_payrun',
    component: PaygroupAndPayrun,
    filterKey: 'paygroup_with_payrun',
  },
  {
    title: 'period_of_time',
    component: PeriodOfTime,
    filterKey: 'period_of_time',
  },
  {
    title: 'asset_status',
    component: AssetsStatus,
    filterKey: 'asset_status',
  },
  {
    title: 'asset_types',
    component: AssetTypes,
    filterKey: 'asset_types',
  },
  {
    title: 'request_statuses',
    component: RequestStatuses,
    filterKey: 'request_statuses',
  },
  {
    title: 'ats_job_opening_statuses',
    component: ATSJobOpenStatuses,
    filterKey: 'ats_job_opening_statuses',
  },
  {
    title: 'candidate_statuses',
    component: CandidateStatuses,
    filterKey: 'candidate_statuses',
  },
  {
    title: 'candidate_source',
    component: CandidateSource,
    filterKey: 'candidate_source',
  },
  {
    title: 'candidate_resume',
    component: CandidateResume,
    filterKey: 'candidate_resume',
  },
  {
    title: 'candidate_rating',
    component: CandidateRating,
    filterKey: 'candidate_rating',
  },
  {
    title: 'pending_on',
    component: RequestsPendingOn,
    filterKey: 'pending_on',
  },
  {
    title: 'request_type',
    component: RequestTypes,
    filterKey: 'request_type',
  },
  {
    title: 'tasks_request_type',
    component: RequestTypes,
    filterKey: 'category',
  },
  {
    title: 'commercial_registration_ids',
    component: CommercialRegistrations,
    filterKey: 'commercial_registration_ids',
  },
  {
    title: 'payment_modes',
    component: PaymentModes,
    filterKey: 'payment_modes',
  },
  {
    title: 'payment_method',
    component: PaymentProcessingMethods,
    filterKey: 'payment_methods',
  },
  {
    title: 'gosi_linked_payment_processing',
    component: GosiLinkedPaymentProcessing,
    filterKey: 'is_gosi_linked',
  },
  {
    title: 'external_nationalities',
    component: ExternalNationalities,
    filterKey: 'external_nationalities',
  },
  {
    title: 'id_type',
    component: IdTypes,
    filterKey: 'id_type',
  },
  {
    title: 'insurance_policy_class',
    component: InsuranceClasses,
    filterKey: 'insurance_policy_class',
  },
  {
    title: 'insurance_status',
    component: InsuranceStatus,
    filterKey: 'insurance_status',
  },
  {
    title: 'is_member_linked',
    component: MemberLinked,
    filterKey: 'is_member_linked',
  },
  {
    title: 'is_eligible_to_enroll',
    component: EnrollmentEligibility,
    filterKey: 'is_eligible_to_enroll',
  },
  {
    title: 'device_status',
    component: DeviceStatus,
    filterKey: 'device_status',
  },
  {
    title: 'endorsement_request_type',
    component: EndorsementRequestType,
    filterKey: 'endorsement_request_type',
  },
  {
    title: 'endorsement_member_type',
    component: EndorsementMemberType,
    filterKey: 'endorsement_member_type',
  },
  {
    title: 'audit_record_actions',
    component: EmpAuditLogActions,
    filterKey: 'action_type',
  },
  {
    title: 'date_picker_duration',
    component: Duration,
    filterKey: 'duration',
  },
  {
    title: 'invitations_statuses',
    component: EmployeeInvitationsStatus,
    filterKey: 'invitations_statuses',
  },
  {
    title: 'invoices_status',
    component: InvoicesStatus,
    filterKey: 'invoices_status',
  },
  {
    title: 'payments',
    component: Payments,
    filterKey: 'payments',
  },
  {
    title: 'error_status',
    component: ErrorStatus,
    filterKey: 'error_status',
  },
  {
    title: 'contract_duration',
    component: ContractDuration,
    filterKey: 'contract_duration',
  },
  {
    title: 'event_type',
    component: EventExportHistory,
    filterKey: 'event_type',
  },
  {
    title: 'label.file_type',
    component: FileTypeExportHistory,
    filterKey: 'export_format',
  },
  {
    title: 'status_export_history',
    component: StatusExportHistory,
    filterKey: 'status_export_history',
  },
  {
    title: 'label.report_type',
    component: ExportTypeExportHistory,
    filterKey: 'export_type',
  },
  {
    title: 'creation_date',
    component: RequestDate,
    filterKey: 'creation_date',
  },
  {
    title: 'banks',
    component: Banks,
    filterKey: 'banks',
  },
  {
    title: 'violation_status',
    component: ViolationStatus,
    filterKey: 'violation_status',
  },
  {
    title: 'violation_category',
    component: ViolationCategory,
    filterKey: 'violation_category',
    extra_title: 'violation_category',
  },
  {
    title: 'timesheet_settlement',
    component: TimesheetSettlement,
    filterKey: 'timesheet_settlement',
    extra_title: 'timesheet_settlement',
  },
  {
    title: 'reviewer',
    component: TimesheetAssignedReviewer,
    filterKey: 'timesheet_reviewer_ids',
    extra_title: 'timesheet_reviewer_ids',
  },
  {
    title: 'paygroup',
    component: TimesheetAssignedPaygroup,
    filterKey: 'paygroup_ids',
    extra_title: 'paygroup_ids',
  },
  {
    title: 'employees',
    component: TimesheetEmployee,
    filterKey: 'settlement_employee_ids',
    extra_title: 'settlement_employee_ids',
  },
  {
    title: 'timesheet_status',
    component: TimesheetStatus,
    filterKey: 'timesheet_status',
  },
  {
    title: 'violation_reporter',
    component: ViolationReporter,
    filterKey: 'violation_reporter',
  },
  {
    title: 'action_taken_by',
    component: ActionTakenBy,
    filterKey: 'action_taken_by',
  },
  {
    title: 'reporting_date',
    component: ReportingDate,
    filterKey: 'reporting_date',
  },
  {
    title: 'date_period',
    component: DateRangePeriod,
    filterKey: 'date_period',
  },
  {
    title: 'date_monthly_period',
    component: DateMonthlyRange,
    filterKey: 'date_monthly_period',
  },
  { title: 'contractsType', component: ContractType, filterKey: 'last_active_contract_type' },
  {
    title: 'status_flight_booking',
    component: FlightBookingStatus,
    filterKey: 'status_flight_booking',
  },
  {
    title: 'wallet_transactions_statuses',
    component: WalletTransactionStatus,
    filterKey: 'wallet_transactions_statuses',
  },
  {
    title: 'transaction_types',
    component: TransactionTypes,
    filterKey: 'transaction_types',
  },
  {
    title: 'wallet_services_types',
    component: WalletServicesTypes,
    filterKey: 'wallet_services_types',
  },
  {
    title: 'payment_dates',
    component: PaymentDates,
    filterKey: 'payment_dates',
  },
  {
    title: 'label.year',
    extra_title: 'year_data',
    component: PickYearDate,
    filterKey: 'year_data',
  },
]
export const initialValues = {
  filters: {},
  mol_registration_ids: [],
  payrun_types: [],
  payrun_status: [],
  filter_payrun_status: [],
  filter_attendance_status: [],
  payroll_transaction_status: [],
  is_data_correct: [],
  is_gosi_linked: [],
  grade_ids: [],
  department_ids: [],
  group_id: [],
  area_ids: [],
  record_loc_ids: [],
  location_ids: [],
  reporting_method_ids: [],
  biometric_ids: [],
  shift_ids: [],
  job_title_ids: [],
  record_types: [],
  loan_type_ids: [],
  shift_types: [],
  shift_type: [],
  leave_type_ids: [],
  employment_type_ids: [],
  leave_status_ids: [],
  business_unit_ids: [],
  request_status_ids: [],
  request_type_ids: [],
  requests_approvers_list_ids: [],
  outsourcing_company_ids: [],
  statuses: [1],
  period_of_time: undefined,
  payment_statuses: [],
  payment_methods: [],
  payment_modes: [],
  task_statuses: [],
  nationalities: [],
  additional_columns: {},
  additional_info: [],
  document_type: [],
  expiry_status: [],
  extra_cols: [],
  categories: [],
  payroll_transaction_type_ids: [],
  status_employee: [],
  department_id: [],
  area_id: [],
  location_id: [],
  employment_type_id: [],
  business_unit_id: [],
  job_title_id: [],
  grade_id: [],
  gosi_subscriptions: [],
  nationality: [],
  payrunTypes: [],
  status: [1],
  asset_status: [],
  asset_types: [],
  request_statuses: [],
  ats_job_opening_statuses: [],
  candidate_statuses: [],
  candidate_source: [],
  candidate_resume: [],
  candidate_rating: [],
  country: [],
  job_openings: [],
  candidate_screening: [],
  candidate_title: [],
  ats_recruiter: [],
  ats_hiring_manager: [],
  pending_on: [],
  request_type: [],
  category: [],
  commercial_registration_ids: [],
  organization_task_id: [],
  external_nationalities: [],
  id_type: [],
  insurance_policy_class: [],
  insurance_status: [],
  is_member_linked: [],
  is_eligible_to_enroll: [],
  device_status: [],
  endorsement_request_type: [],
  endorsement_member_type: [],
  action_type: [],
  duration: {},
  invitations_statuses: [],
  fund_dates: undefined,
  invoices_status: [],
  payments: [],
  joining_date: {},
  form_type: [],
  form_status: [],
  frequency: [],
  reviewer_ids: [],
  status_export_history: [],
  event_type: [],
  export_format: [],
  export_type: [],
  violation_status: [],
  violation_category: [],
  timesheet_settlement: [],
  timesheet_reviewer_ids: [],
  paygroup_ids: [],
  date_monthly_period: {},
  settlement_employee_ids: [],
  timesheet_status: [],
  violation_reporter: [],
  action_taken_by: [],
  creation_date: {},
  banks: [],
  paygroup_with_payrun: [],
  payroll_month: undefined,
  payroll_month_id: undefined,
  year_data: undefined,
  reporting_date: {},
  date_period: {},
  last_active_contract_type: [],
  status_flight_booking: [],
  wallet_transactions_statuses: [],
  transaction_types: [],
  wallet_services_types: [],
}

export const newState = {
  mol_registration_ids: [],
  payrun_types: [],
  payrun_status: [],
  filter_payrun_status: [],
  filter_attendance_status: [],
  payroll_transaction_status: [],
  grade_ids: [],
  department_ids: [],
  group_id: [],
  area_ids: [],
  record_loc_ids: [],
  reporting_method_ids: [],
  shift_ids: [],
  job_title_ids: [],
  record_types: [],
  shift_types: [],
  shift_type: [],
  leave_type_ids: [],
  employment_type_ids: [],
  leave_status_ids: [],
  business_unit_ids: [],
  request_status_ids: [],
  request_type_ids: [],
  requests_approvers_list_ids: [],
  statuses: [],
  status_employee: [],
  payment_statuses: [],
  payment_methods: [],
  payment_modes: [],
  task_statuses: [],
  categories: [],
  country: [],
  nationalities: [],
  document_type: [],
  expiry_status: [],
  loan_type_ids: [],
  location_ids: [],
  record_location_ids: [],
  biometric_ids: [],
  extra_cols: [],
  payroll_transaction_type_ids: [],
  department_id: [],
  area_id: [],
  location_id: [],
  employment_type_id: [],
  business_unit_id: [],
  job_title_id: [],
  grade_id: [],
  gosi_subscriptions: [],
  status: [],
  nationality: [],
  is_gosi_linked: [],
  is_data_correct: [],
  payment_details: [],
  period_of_time: undefined,
  asset_status: [],
  asset_types: [],
  request_statuses: [],
  ats_job_opening_statuses: [],
  candidate_statuses: [],
  candidate_source: [],
  candidate_resume: [],
  candidate_rating: [],
  job_openings: [],
  candidate_screening: [],
  candidate_title: [],
  ats_recruiter: [],
  ats_hiring_manager: [],
  pending_on: [],
  request_type: [],
  category: [],
  commercial_registration_ids: [],
  organization_task_id: [],
  external_nationalities: [],
  id_type: [],
  insurance_policy_class: [],
  insurance_status: [],
  is_member_linked: [],
  is_eligible_to_enroll: [],
  device_status: [],
  endorsement_request_type: [],
  endorsement_member_type: [],
  action_type: [],
  duration: {},
  invitations_statuses: [],
  fund_dates: undefined,
  invoices_status: [],
  payments: [],
  joining_date: {},
  form_type: [],
  form_status: [],
  frequency: [],
  reviewer_ids: [],
  status_export_history: [],
  event_type: [],
  export_format: [],
  export_type: [],
  violation_status: [],
  violation_category: [],
  timesheet_settlement: [],
  timesheet_reviewer_ids: [],
  paygroup_ids: [],
  date_monthly_period: {},
  settlement_employee_ids: [],
  timesheet_status: [],
  violation_reporter: [],
  year_data: undefined,
  action_taken_by: [],
  creation_date: {},
  banks: [],
  paygroup_with_payrun: [],
  payroll_month: undefined,
  reporting_date: {},
  date_period: {},
  last_active_contract_type: [],
  status_flight_booking: [],
  wallet_transactions_statuses: [],
  transaction_types: [],
  wallet_services_types: [],
}

export const filterPairKeys = {
  business_units: 'business_unit_ids',
  departments: 'department_ids',
  group: 'group_id',
  document_types: 'document_type',
  employment_types: 'employment_type_ids',
  expiry_status: 'expiry_status',
  gosi_linked: 'is_gosi_linked',
  member_linked: 'is_member_linked',
  grades: 'grade_ids',
  job_titles: 'job_title_ids',
  leave_statuses: 'leave_status_ids',
  leave_types: 'leave_type_ids',
  loan_types: 'loan_type_ids',
  locations: 'location_ids',
  mol_registrations: 'mol_registration_ids',
  payrun_types: 'payrun_types',
  payroll_transaction_status: 'payroll_transaction_status',
  payrun_status: 'payrun_status',
  filter_payrun_status: 'filter_payrun_status',
  filter_attendance_status: 'filter_attendance_status',
  nationalities: 'nationalities',
  out_of_payroll_types: 'payroll_transaction_type_ids',
  outsourcing_company: 'outsourcing_company_ids',
  payment_statuses: 'payment_statuses',
  payment_methods: 'payment_methods',
  payment_modes: 'payment_modes',
  record_types: 'record_types',
  reporting_method: 'reporting_method_ids',
  request_statuses: 'request_statuses',
  ats_job_opening_statuses: 'ats_job_opening_statuses',
  candidate_statuses: 'candidate_statuses',
  candidate_source: 'candidate_source',
  candidate_resume: 'candidate_resume',
  candidate_rating: 'candidate_rating',
  country: 'country',
  job_openings: 'job_openings',
  candidate_screening: 'candidate_screening',
  candidate_title: 'candidate_title',
  ats_recruiter: 'ats_recruiter',
  ats_hiring_manager: 'ats_hiring_manager',
  request_type: 'request_type_ids',
  category: 'category',
  organization_task_id: 'organization_task_id',
  requests_approvers_list: 'requests_approvers_list_ids',
  shift_types: 'shift_types',
  shift_type: 'shift_type',
  shifts: 'shift_ids',
  status_employee: 'status_employee',
  statuses: 'statuses',
  task_statuses: 'task_statuses',
  tasks_categories: 'categories',
  uncorrect_data: 'is_data_correct',
  biometrics: 'biometric_ids',
  business_unit_id: 'business_unit_id',
  department_id: 'department_id',
  employment_type_id: 'employment_type_id',
  grade_id: 'grade_id',
  location_id: 'location_id',
  job_title_id: 'job_title_id',
  gosi_subscriptions: 'gosi_subscriptions',
  status: 'status',
  record_location: 'record_location_ids',
  nationality: 'nationality',
  punch_in: 'punch_in',
  punch_out: 'punch_out',
  pending_on: 'pending_on',
  asset_status: 'asset_status',
  asset_types: 'asset_types',
  commercial_registration_ids: 'commercial_registration_ids',
  external_nationalities: 'external_nationalities',
  id_type: 'id_type',
  insurance_policy_class: 'insurance_policy_class',
  insurance_status: 'insurance_status',
  device_status: 'device_status',
  endorsement_request_type: 'endorsement_request_type',
  endorsement_member_type: 'endorsement_member_type',
  action_type: 'action_type',
  duration: 'duration',
  invitations_statuses: 'invitations_statuses',
  invoices_status: 'invoices_status',
  payments: 'payments',
  reviewer_ids: 'reviewer_ids',
  form_status: 'form_status',
  status_export_history: 'status_export_history',
  event_type: 'event_type',
  export_type: 'export_type',
  export_format: 'export_format',
  banks: 'banks',
  paygroup_with_payrun: 'paygroup_with_payrun',
  payroll_month: 'payroll_month',
  wallet_transactions_statuses: 'wallet_transactions_statuses',
  transaction_types: 'transaction_types',
  wallet_services_types: 'wallet_services_types',
  period_of_time: 'period_of_time',
}

export const groupingFilter = {
  filters: ['area_ids', 'area_id', 'record_loc_ids', 'reporting_method_ids'],
  area_ids: {
    key_name: 'area_id',
    list: 'locations',
    insert_ids_keys: 'area_ids',
  },
  area_id: {
    key_name: 'area_id',
    list: 'locations',
    insert_ids_keys: 'location_id',
  },
  record_loc_ids: {
    key_name: 'area_id',
    list: 'locations',
    insert_ids_keys: 'record_location_ids',
  },
  reporting_method_ids: {
    key_name: 'reporting_method_id',
    list: 'biometrics',
    insert_ids_keys: 'biometric_ids',
  },
}

export function getSelectedFilters(state, filters) {
  const selected = {}

  filters.map((key) => {
    selected[filterPairKeys[key]] = state[filterPairKeys[key]]
    return key
  })

  return selected
}

export function handleSelectGroupingFilter(data) {
  const { list, name, id } = data
  const ids = list.filter((f) => f[name] === id).map((loca) => loca.id)
  return ids
}

export function handleRemoveSelectedGroupingFilter(data) {
  const { list, id, selectedIds, name } = data

  const getSelectedList = list
    .filter((f) => f[name] === id)
    .filter((li) => selectedIds.includes(li.id))
    .map((li) => li.id)

  return selectedIds.filter((sid) => !getSelectedList.includes(sid))
}

export const transformFilters = (filters, transformfilterKeys) => {
  const transformFilter = {}
  Object.entries(transformfilterKeys).forEach(([key, value]) => {
    if (filters?.filter?.[key]?.length) transformFilter[value] = filters?.filter?.[key]
  })
  return { ...filters, filter: transformFilter }
}

export const returnBadgeList = (list) => {
  return list.map((li) => ({ ...li, label: i18n.t(li.name), testId: li.name?.toLowerCase() }))
}

export const getPublicFilterKeys = (
  filters = [],
  specificParams = {},
  hierarchicalSettings = true,
) => {
  let keys = {}
  let lookups = []
  const getKeys = (key) => {
    switch (key) {
      case 'location_area':
        return {
          area: true,
        }

      case 'locations':
      case 'location_id':
      case 'areas':
      case 'record_location':
        return {
          location: true,
          area: true,
        }

      case 'departments':
      case 'department_id':
        return {
          department: {
            hierarchical: hierarchicalSettings,
          },
        }

      case 'mol_registrations':
      case 'gosi_subscriptions':
        return {
          mol_registration: true,
        }
      case 'country':
        return {
          country: true,
        }

      case 'employment_type_id':
      case 'employment_types':
        return {
          employment_type: true,
        }

      case 'nationality':
      case 'nationalities':
        return {
          nationality: {
            ...(specificParams.invalid_employee
              ? { invalid_employee: true }
              : { employee_list: true }),
          },
        }

      case 'job_titles':
      case 'job_title_id':
        return {
          job_title: true,
        }

      case 'business_units':
      case 'business_unit_id':
        return {
          business_unit: true,
        }

      case 'outsourcing_company':
        return {
          outsourcing_companies: true,
        }

      case 'shifts':
        return {
          shift: true,
        }

      case 'grades':
      case 'grade_id':
        return {
          grade: true,
        }

      case 'loan_types':
        return {
          loan_type: true,
        }

      case 'document_types':
        return {
          document_type: true,
        }

      case 'out_of_payroll_types':
        return {
          payroll_transaction_type: {
            show_vs_fs: false,
            category: 'PayTypePage',
          },
        }

      case 'leave_types':
        return {
          leave_type: true,
        }
      case 'group':
        return {
          group: true,
        }
      case 'asset_types':
        return {
          asset_type: true,
        }
      case 'request_type':
        return {
          request_type: true,
          archived_request_type: true,
        }
      case 'task_type':
        return {
          organization_task: true,
        }
      case 'commercial_registration_ids':
        return {
          commercial_registration: hierarchicalSettings,
        }

      case 'external_nationalities':
        lookups = uniq([...lookups, 'nationality'])
        return {
          lookups,
        }
      case 'id_type':
        lookups = uniq([...lookups, 'id_type'])
        return {
          lookups,
        }
      case 'insurance_policy_class':
        lookups = uniq([...lookups, 'insurance_policy_class'])
        return {
          lookups,
        }
      case 'endorsement_request_type':
        lookups = uniq([...lookups, 'endorsement_type'])
        return {
          lookups,
        }
      case 'banks':
        return {
          banks: { payment_transfer_id: specificParams.payment_transfer_id },
        }
      // case 'payroll_months':
      //   return {
      //     payrolls: true,
      //   }

      case 'paygroup_ids':
        return {
          paygroups: true,
        }

      default:
        return null
    }
  }

  filters.forEach((filter) => {
    if (getKeys(filter)) {
      keys = {
        ...keys,
        ...getKeys(filter),
      }
    }
  })

  return keys
}

export const canShowClearFilter = (filter, filterKey, optionFilterKey, subOptionFilterKey) => {
  if (['statuses'].includes(filterKey)) {
    return !isEmpty(filter.statuses || [])
  }
  if (['status_employee'].includes(filterKey)) {
    return !isEmpty(filter.status_employee || [])
  }
  const emptyFilter = isEmpty(filter[filterKey] || [])
  const emptyOptionsFilter = isEmpty(filter[optionFilterKey] || [])
  const emptySubOptionsFilter = isEmpty(filter[subOptionFilterKey] || [])
  return !emptyFilter || !emptyOptionsFilter || !emptySubOptionsFilter
}

export const getTranslatedList = (list, key) => {
  return list.map((li) => ({ ...li, [`${[key]}_i18n`]: i18n.t(li?.[key]), testId: li?.[key] }))
}

export const getUniqueList = (list1, list2) => {
  const li1 = list1.map((l) => l.id)
  const li2 = list2.filter((l) => !li1.includes(l.id))
  return [...li2, ...list1]
}
