export const MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS = 'MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_PENDING =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_PENDING'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_FULFILLED =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_FULFILLED'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_REJECTED =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_STATUS_REJECTED'

export const MICROSOFT_FINOPS_DISCONNECT_ACCOUNT = 'MICROSOFT_FINOPS_DISCONNECT_ACCOUNT'
export const MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_PENDING =
  'MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_PENDING'
export const MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_FULFILLED =
  'MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_FULFILLED'
export const MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_REJECTED =
  'MICROSOFT_FINOPS_DISCONNECT_ACCOUNT_REJECTED'

export const MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO = 'MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_PENDING =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_PENDING'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_FULFILLED =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_FULFILLED'
export const MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_REJECTED =
  'MICROSOFT_FINOPS_LOAD_ACCOUNT_INFO_REJECTED'

export const MICROSOFT_FINOPS_CONNECT_ACCOUNT = 'MICROSOFT_FINOPS_CONNECT_ACCOUNT'
export const MICROSOFT_FINOPS_CONNECT_ACCOUNT_PENDING = 'MICROSOFT_FINOPS_CONNECT_ACCOUNT_PENDING'
export const MICROSOFT_FINOPS_CONNECT_ACCOUNT_FULFILLED =
  'MICROSOFT_FINOPS_CONNECT_ACCOUNT_FULFILLED'
export const MICROSOFT_FINOPS_CONNECT_ACCOUNT_REJECTED = 'MICROSOFT_FINOPS_CONNECT_ACCOUNT_REJECTED'

export const SYNC_MICROSOFT_FINOPS_EMPLOYEE = 'SYNC_MICROSOFT_FINOPS_EMPLOYEE'
export const SYNC_MICROSOFT_FINOPS_EMPLOYEE_PENDING = 'SYNC_MICROSOFT_FINOPS_EMPLOYEE_PENDING'
export const SYNC_MICROSOFT_FINOPS_EMPLOYEE_FULFILLED = 'SYNC_MICROSOFT_FINOPS_EMPLOYEE_FULFILLED'
export const SYNC_MICROSOFT_FINOPS_EMPLOYEE_REJECTED = 'SYNC_MICROSOFT_FINOPS_EMPLOYEE_REJECTED'
