import { Textarea, TextAreaProps } from '@jisr-hr/ds-beta'
import { FieldValidator } from 'final-form'
import { memo } from 'react'
import { Field } from 'react-final-form'

type NewTextAreaFieldProps = TextAreaProps & {
  name: string
  validate?: FieldValidator<any>
}

const TextAreaField = (props: NewTextAreaFieldProps): JSX.Element => {
  const { name, validate, required, ...rest } = props
  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => (
        <Textarea
          {...input}
          {...rest}
          required={required}
          {...(meta.error && meta.touched && { status: 'destructive', helperText: meta.error })}
        />
      )}
    </Field>
  )
}

export default memo(TextAreaField)
