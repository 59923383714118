export const ONBOARDING_PAYMENT_DETAILS_LOAD = 'ONBOARDING_PAYMENT_DETAILS_LOAD'
export const ONBOARDING_PAYMENT_DETAILS_LOAD_PENDING = 'ONBOARDING_PAYMENT_DETAILS_LOAD_PENDING'
export const ONBOARDING_PAYMENT_DETAILS_LOAD_FULFILLED = 'ONBOARDING_PAYMENT_DETAILS_LOAD_FULFILLED'
export const ONBOARDING_PAYMENT_DETAILS_LOAD_REJECTED = 'ONBOARDING_PAYMENT_DETAILS_LOAD_REJECTED'

export const ONBOARDING_PAYMENT_DETAILS_UPDATE = 'ONBOARDING_PAYMENT_DETAILS_UPDATE'
export const ONBOARDING_PAYMENT_DETAILS_UPDATE_PENDING = 'ONBOARDING_PAYMENT_DETAILS_UPDATE_PENDING'
export const ONBOARDING_PAYMENT_DETAILS_UPDATE_FULFILLED =
  'ONBOARDING_PAYMENT_DETAILS_UPDATE_FULFILLED'
export const ONBOARDING_PAYMENT_DETAILS_UPDATE_REJECTED =
  'ONBOARDING_PAYMENT_DETAILS_UPDATE_REJECTED'

export const ONBOARDING_BANKS_LOAD = 'ONBOARDING_BANKS_LOAD'
export const ONBOARDING_BANKS_LOAD_PENDING = 'ONBOARDING_BANKS_LOAD_PENDING'
export const ONBOARDING_BANKS_LOAD_FULFILLED = 'ONBOARDING_BANKS_LOAD_FULFILLED'
export const ONBOARDING_BANKS_LOAD_REJECTED = 'ONBOARDING_BANKS_LOAD_REJECTED'
