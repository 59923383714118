import { memo } from 'react'
import { Button, Divider, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { parseISO, differenceInYears } from 'date-fns'
import { DependentDataType } from 'components/global/templates/RequestDrawer/types/flightTicketRequest'
import DSIcon from 'components/DSIcon'

type DependentWithAddedKeys = DependentDataType & {
  relationships_key?: string
  listId?: string
}

type DependentCardProps = {
  dependent: DependentWithAddedKeys
  isException?: boolean
  addDivider: boolean
  dependentCoveragePercentage?: number
  dependentAge?: number
  dependentRestrictionOperator?: string
  handleDependentDelete: (dependentId: string) => void
  handleEditDependent: (listId: string | undefined) => void
}

const DependentCard = ({
  dependent,
  isException,
  addDivider,
  dependentCoveragePercentage,
  dependentAge = 0,
  dependentRestrictionOperator,
  handleDependentDelete,
  handleEditDependent,
}: DependentCardProps): JSX.Element => {
  const yearsDifference = differenceInYears(new Date(), parseISO(dependent.date_of_birth))

  const dependentAgeTitle = yearsDifference
    ? `${dependent.relation} - ${I18n.t('label.age_years_old', {
        Age: yearsDifference,
      })}`
    : dependent.relation

  const dependentCoveragePercent = (): number | undefined => {
    if (
      dependentRestrictionOperator === 'less_than_or_equal_to' &&
      yearsDifference <= dependentAge
    ) {
      return dependentCoveragePercentage
    } else if (
      dependentRestrictionOperator === 'greater_than_or_equal_to' &&
      yearsDifference >= dependentAge
    ) {
      return dependentCoveragePercentage
    }

    return 100
  }

  return (
    <Flex
      flexCol
      className="gap-4 px-4 pb-4"
    >
      <Flex
        flexCol
        className="gap-1"
      >
        <Flex justifyBetween>
          <div>
            <Typography
              variant="body-new/semibold"
              text={dependent.name_i18n}
            />
            <Typography
              variant="subtitle-2"
              text={dependentAgeTitle}
            />
          </div>
          <Flex className="gap-3">
            {!dependent?.family_detail_id && (
              <Button
                leadingIcon="edit-03"
                variant="outlined"
                color="neutral"
                iconOnly
                size="small"
                onClick={(): void => {
                  handleEditDependent(dependent?.listId)
                }}
              />
            )}
            <Button
              leadingIcon="trash-04"
              variant="outlined"
              color="neutral"
              iconOnly
              size="small"
              onClick={(): void => {
                handleDependentDelete(dependent.name_i18n)
              }}
            />
          </Flex>
        </Flex>
        <Typography
          variant="subtitle-2"
          text={dependent.passport_number}
        />
        <Typography
          variant="subtitle-2"
          text={I18n.t('communication.covering_percent_of_the_ticket', {
            percentage: dependentCoveragePercent(),
          })}
        />
        {isException && (
          <Flex
            itemsCenter
            className="gap-1.5"
          >
            <DSIcon
              name="alert-triangle"
              color="var(--color-base-colors-yellow-700)"
            />
            <Typography
              variant="body-new/regular"
              text={I18n.t('communication.policy_exception')}
              textColor="color/fg/warning/default"
            />
          </Flex>
        )}
      </Flex>
      {addDivider && <Divider />}
    </Flex>
  )
}

export default memo(DependentCard)
