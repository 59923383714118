import { FeaturedIcon, Flex, Spacer, Typography } from '@jisr-hr/ds'
import { Dispatch, ForwardedRef, SetStateAction, forwardRef } from 'react'
import { setInitialValues } from 'redux/newDepartments/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import DSIcon from 'components/DSIcon'
import classNames from 'classnames'

import styles from '../style.module.css'

type Props = {
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  orgName?: string
}

const initialValues = {
  name: '',
  name_ar: '',
  parent_id: 0,
  id: 0,
  name_en: '',
  name_i18n: '',
  template_for: '',
  template_type: '',
  industry_id: 0,
  children: null,
}

const OrgCard = forwardRef(
  ({ orgName, setIsOpen }: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const dispatch = useDispatch()
    const { hierarchical_departments } = useSelector((s) => s.newDepartments)
    return (
      <div
        className={!hierarchical_departments.length ? styles.coverMainCard : undefined}
        ref={ref}
      >
        <div
          className={styles.card}
          style={{
            height: 48,
            width: 'fit-content',
            borderRadius: '8px',
          }}
        >
          <Flex
            style={{ gap: 8, height: 'inherit', padding: '12px 37px' }}
            justifyCenter
            itemsCenter
          >
            <FeaturedIcon
              colorType="neutral"
              iconName="building-07"
              size="sm"
            />

            <Typography
              text={orgName}
              textColor="color/fg/light"
              variant="body-new/semibold"
            />
          </Flex>
          <Spacer height={8} />

          <div
            className={classNames(styles.boxDown, styles.maincard)}
            onClick={(): void => {
              dispatch(setInitialValues(initialValues))
              setIsOpen?.(true)
            }}
          >
            <DSIcon
              name="plus"
              styles={{ color: 'var(--ds-core-color-base-white)' }}
            />
          </div>
        </div>
      </div>
    )
  },
)

export default OrgCard
