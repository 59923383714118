import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type EmployeeNewlyAddedStateT = {
  avatar: string
  id: number
  job_title: string
  joining_date: string
  name: string
  remark: string
  this_month_salary: string
  total_earnings: string
}

type StateType = {
  count: number
  employees: EmployeeNewlyAddedStateT[]
  fetching: boolean
}

const initialState: StateType = {
  fetching: false,
  count: 0,
  employees: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  switch (action.type) {
    case actions.PAYROLL_EMPLOYEE_NEWLY_ADDED_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYROLL_EMPLOYEE_NEWLY_ADDED_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.PAYROLL_EMPLOYEE_NEWLY_ADDED_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
