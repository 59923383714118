export const EMPLOYEE_MASTER_REPORT_LOAD = 'EMPLOYEE_MASTER_REPORT_LOAD'
export const EMPLOYEE_MASTER_REPORT_LOAD_PENDING = 'EMPLOYEE_MASTER_REPORT_LOAD_PENDING'
export const EMPLOYEE_MASTER_REPORT_LOAD_FULFILLED = 'EMPLOYEE_MASTER_REPORT_LOAD_FULFILLED'
export const EMPLOYEE_MASTER_REPORT_LOAD_REJECTED = 'EMPLOYEE_MASTER_REPORT_LOAD_REJECTED'

export const EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD = 'EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD'
export const EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_PENDING =
  'EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_PENDING'
export const EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_FULFILLED =
  'EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_FULFILLED'
export const EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_REJECTED =
  'EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_REJECTED'

export const SET_FILTERS_EMPLOYEE_MASTER = 'SET_FILTERS_EMPLOYEE_MASTER'
export const CLEAR_FILTERS_EMPLOYEE_MASTER = 'CLEAR_FILTERS_EMPLOYEE_MASTER'
