const LoaderComponent = (): JSX.Element => (
  <div
    className="loading-content"
    data-testid="lazy-component-loader"
  >
    <i className="loader azure-color" />
  </div>
)

export default LoaderComponent
