import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { HTTPValidationError } from 'types/httpTypes'
import { ApprovalBypassRes, TActionTaskPayload } from './useRequests.type'

// ? functions

const handleApprovalBypass = (approvalId: number): Promise<ApprovalBypassRes> => {
  return api.put(`/${API_VERSION_TWO}/requests/approvals/${approvalId}/bypass`)
}

const handleNotifyApprover = (approvalId: number): Promise<ApprovalBypassRes> => {
  return api.post(`/${API_VERSION_TWO}/requests/approvals/${approvalId}/notify`)
}

const handleActionTaskRequest = (
  taskId: number,
  status: 'reject' | 'complete',
  rejectionReason?: string,
  paid_at?: string,
): Promise<ApprovalBypassRes> => {
  if (status === 'reject' && !rejectionReason) {
    throw new Error('Rejection reason is required when status is "reject"')
  }

  let requestBody

  if (status === 'reject') {
    requestBody = { task: { rejection_reason: rejectionReason } }
  } else if (status === 'complete' && paid_at) {
    requestBody = { task: { paid_at } }
  }

  return api.put(`/${API_VERSION_TWO}/tasks/${taskId}/${status}`, requestBody)
}

// ? hooks

/**
 * @endpoint: PUT: /v2/requests/approvals/:id/bypass
 * @summary This hook used to update request approval bypass
 */
export const useUpdateApprovalByPass = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<ApprovalBypassRes, TError, { approvalId: number }>
}): UseMutationResult<ApprovalBypassRes, TError, { approvalId: number }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => handleApprovalBypass(payload.approvalId),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
    },
    onError: (error) => {
      Toastr.error(error?.response?.data?.error)
    },
  })
}

/**
 * @endpoint: PUT: /v2/requests/approvals/:id/notify
 * @summary This hook used to send a notification to the request's approver
 */
export const useNotifyRequestApprover = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<ApprovalBypassRes, TError, { approvalId: number }>
}): UseMutationResult<ApprovalBypassRes, TError, { approvalId: number }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => handleNotifyApprover(payload.approvalId),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
    },
    onError: (error) => {
      Toastr.error(error?.response?.data?.error)
    },
  })
}

/**
 * @endpoint: PUT: /v2/tasks/:id/reject | /v2/tasks/:id/complete
 * @summary This hook used to accept or reject task request
 */
export const useActionTaskRequest = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<ApprovalBypassRes, TError, TActionTaskPayload>
}): UseMutationResult<ApprovalBypassRes, TError, TActionTaskPayload> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload: TActionTaskPayload): Promise<ApprovalBypassRes> => {
      return handleActionTaskRequest(
        payload.taskId,
        payload.status,
        payload.status === 'reject' ? payload.rejectionReason : undefined,
        payload.status === 'complete' ? payload.paid_at : undefined,
      )
    },
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
