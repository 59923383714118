import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  working_hours: '',
  halfday_hours: '',
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.CALCULATE_WORKING_HOURS_LOAD_FULFILLED:
      return {
        ...state,
        working_hours: payload.data.data.working_hours,
        fetching: false,
      }
    case actions.CALCULATE_HALFDAY_WORKING_HOURS_LOAD_FULFILLED:
      return {
        ...state,
        halfday_hours: payload.data.data.working_hours,
        fetching: false,
      }

    case actions.CALCULATE_WORKING_HOURS_LOAD_REJECTED:
    case actions.CALCULATE_HALFDAY_WORKING_HOURS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
