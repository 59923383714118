import { PinCode } from '@jisr-hr/design-system'
import { Flex, Spacer, Typography, Button } from '@jisr-hr/ds'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { validatePinCodeCreate } from 'redux/organizationOnborading/organization/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import jisrLogo from 'assets/images/JisrLogo.svg'
import Styles from './VerifyUser.module.css'

const VerifyUser = (): JSX.Element => {
  const dispatch = useDispatch()
  const [pinCode, setPinCode] = useState('')
  const { organization: org, fetching, error } = useSelector(({ organization }) => organization)

  const onSubmit = (values: { pincode: 'string' }): void => {
    dispatch(validatePinCodeCreate(values.pincode))
      .then(() => {
        sessionStorage.setItem(atob('pincode'), atob(values.pincode))
        window.location.reload()
      })
      .catch(() => {
        setPinCode(values.pincode)
      })
  }

  return (
    <Flex
      className={Styles.verifyContainer}
      justifyCenter
      itemsCenter
    >
      <Flex
        flexCol
        justifyCenter
        itemsCenter
      >
        <Flex
          className={Styles.companyLogo}
          justifyCenter
          itemsCenter
        >
          <img
            src={jisrLogo}
            style={{ height: 27 }}
            alt=""
          />
        </Flex>
        <Spacer height={24} />
        <Typography
          variant="heading"
          text=" Account Setup | تهيئة الحساب"
          style={{ color: 'var(--color-base-colors-black-700)' }}
        />

        <Typography
          variant="heading"
          text={org.name_i18n}
          style={{ color: 'var(--color-base-colors-grey-600)' }}
        />

        <Spacer height={8} />
        <Typography
          variant="subtitle-1"
          style={{ color: 'var(--color-base-colors-black-600)', textAlign: 'center' }}
          text={
            <>
              {' '}
              Enter access code sent to | أدخل رمز المرور المرسل إلى البريد
              <br />
              {org?.metadata?.first_admin_details?.email}{' '}
            </>
          }
        />

        <Spacer height={24} />
        <Form
          onSubmit={onSubmit}
          initialValues={{ pincode: '' }}
        >
          {({ handleSubmit, values }): JSX.Element => {
            const isPinCodeEqual = values.pincode === pinCode
            const disabled =
              isPinCodeEqual ||
              typeof values.pincode === 'undefined' ||
              values.pincode?.length < 4 ||
              fetching
            return (
              <form onSubmit={handleSubmit}>
                <Field name="pincode">
                  {({ input }): JSX.Element => (
                    <PinCode
                      status={isPinCodeEqual && error && 'error'}
                      label="Access Code رمز المرور"
                      {...input}
                    />
                  )}
                </Field>
                {error && isPinCodeEqual && (
                  <>
                    <Spacer height={8} />
                    <Typography
                      variant="interface/default/sm"
                      style={{ color: 'var(--color-base-colors-red-600)' }}
                      text="Incorrect Code الرمز خاطـئ"
                    />
                  </>
                )}
                <Spacer height={24} />
                <Button
                  label="Verify | تحقق"
                  size="medium"
                  type="submit"
                  style={{ margin: 'auto', direction: 'ltr' }}
                  disabled={disabled}
                  onClick={handleSubmit}
                />
              </form>
            )
          }}
        </Form>
      </Flex>
    </Flex>
  )
}

export default VerifyUser
