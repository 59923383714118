import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
// import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators';
import { useSelector } from 'react-redux'
import { format } from 'utils/date'
import InfoText from './InfoText'
import Styles from './InfoList.module.css'
// import { useRequest } from '../../RequestProvider';
import CancellationBy from '../../components/Workflow/components/CancellationBy'

const InfoList = (props) => {
  const items = props?.items?.filter((f) => f?.value)
  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  // const dispatch = useDispatch();
  // const { setRequestType } = useRequest();

  // const handleRequestDetail = (reqDetails) => {
  //   setRequestType(reqDetails?.cancellation_request_info?.type);
  //   dispatch(requestDetailsLoad(
  //     reqDetails?.employee?.id,
  //     reqDetails?.cancellation_request_info?.id,
  //     reqDetails?.cancellation_request_info?.type,
  //   ));
  // };

  return (
    <Flex
      flexCol
      className={Styles.infoItem}
    >
      {items?.map((item, i) => (
        <Flex
          flexCol
          key={i}
        >
          <Flex
            flexCol
            style={{ gap: 8 }}
          >
            <Flex
              style={{ gap: 8, flexWrap: 'wrap' }}
              itemsStart={item?.alignStart}
              itemsCenter={!item?.alignStart}
              justifyBetween
            >
              <Flex
                itemsCenter
                className={Styles.iconLabelContainer}
              >
                {item?.icon && (
                  <>
                    {item?.icon}
                    <Spacer width={10} />
                  </>
                )}
                <InfoText
                  label={item?.label}
                  color="var(--color-base-colors-black-500)"
                />
              </Flex>
              <Flex
                flexWrap
                style={{ gap: 8 }}
              >
                {typeof item?.value !== 'object' ? (
                  <Typography
                    text={item?.value}
                    style={{ color: item?.color }}
                  />
                ) : (
                  <>{item?.value ? item?.value : ''}</>
                )}
              </Flex>
            </Flex>
            {item?.key === 'status' && details?.cancellation_request_info?.approved_at && (
              <CancellationBy
                cancellationLabel={
                  <Typography
                    text={I18n.t('cancelation_requested_by')}
                    variant="subtitle-2"
                    style={{ color: 'var(--color-base-colors-black-500)', textAlign: 'end' }}
                  />
                }
                label={details?.cancellation_request_info?.created_by}
                dateAndTime={format(
                  details?.cancellation_request_info?.approved_at,
                  'd MMM - hh:mm a',
                )}
              />
            )}
          </Flex>
          {item?.subList && (
            <Flex
              flexCol
              className={Styles.subList}
            >
              {item?.subList.map((sub, _i) => (
                <Flex
                  itemsCenter
                  justifyBetween
                  key={_i}
                >
                  <InfoText
                    label={sub?.label}
                    color="var(--color-base-colors-grey-600)"
                  />
                  <InfoText
                    label={sub?.value}
                    color="var(--color-base-colors-grey-600)"
                  />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  )
}

InfoList.propTypes = {
  items: PropTypes.arrayOf(Object),
}

export default InfoList
