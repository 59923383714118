export function handleResponce({ records, header, main_header }) {
  const newMinHeader = [
    {
      label: main_header?.employee_details.text,
      colSpan: main_header?.employee_details.col_span,
    },
    {
      label: main_header?.family_details.text,
      colSpan: main_header?.family_details.col_span,
    },
  ]

  const headerKeys = header && Object.keys(header).map((key) => header[key])

  return {
    main_header: newMinHeader,
    header: headerKeys,
    headers: header,
    records,
  }
}
