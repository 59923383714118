export const titleCase = (str: string): string =>
  str.toLowerCase().replace(/(^|\s)\S/g, (t) => t.toUpperCase())

export const getMonthlyDeduction = (amount: number, months: number): string => {
  let floatAmount = (amount * 100) / months
  if (floatAmount - parseInt(floatAmount.toString(), 10) > 0) floatAmount += 1
  floatAmount /= 100
  const intAmount = parseInt(floatAmount.toString(), 10)
  return floatAmount - intAmount < 0.1 ? intAmount.toString() : floatAmount.toFixed(2)
}
