import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  devices: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function deviceReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let devices = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'DEVICES_LOAD_PENDING':
    case 'DEVICE_CREATE_PENDING':
    case 'DEVICE_UPDATE_PENDING':
    case 'DEVICE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'DEVICES_LOAD_REJECTED':
    case 'DEVICE_CREATE_REJECTED':
    case 'DEVICE_UPDATE_REJECTED':
    case 'DEVICE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEVICES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEVICE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      devices = [...state.devices]
      newRecord = payload.data.data.device
      changes = {
        devices: [...devices, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'DEVICE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      devices = [...state.devices]
      updatedRecord = payload.data.data.device
      changes = {
        devices: updateOneListOfRecords(devices, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'DEVICE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      devices = [...state.devices]
      return {
        ...state,
        devices: removeOneListOfRecords(devices, payload.id),
        error: null,
      }

    case 'DEVICE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'DEVICE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
