export const EMPLOYEE_CONTRACTS_LOAD = 'EMPLOYEE_CONTRACTS_LOAD'
export const EMPLOYEE_CONTRACTS_LOAD_PENDING = 'EMPLOYEE_CONTRACTS_LOAD_PENDING'
export const EMPLOYEE_CONTRACTS_LOAD_FULFILLED = 'EMPLOYEE_CONTRACTS_LOAD_FULFILLED'
export const EMPLOYEE_CONTRACTS_LOAD_REJECTED = 'EMPLOYEE_CONTRACTS_LOAD_REJECTED'

export const EMPLOYEE_CONTRACTS_CREATE = 'EMPLOYEE_CONTRACTS_CREATE'
export const EMPLOYEE_CONTRACTS_CREATE_PENDING = 'EMPLOYEE_CONTRACTS_CREATE_PENDING'
export const EMPLOYEE_CONTRACTS_CREATE_FULFILLED = 'EMPLOYEE_CONTRACTS_CREATE_FULFILLED'
export const EMPLOYEE_CONTRACTS_CREATE_REJECTED = 'EMPLOYEE_CONTRACTS_CREATE_REJECTED'

export const EMPLOYEE_CONTRACTS_UPDATE = 'EMPLOYEE_CONTRACTS_UPDATE'
export const EMPLOYEE_CONTRACTS_UPDATE_PENDING = 'EMPLOYEE_CONTRACTS_UPDATE_PENDING'
export const EMPLOYEE_CONTRACTS_UPDATE_FULFILLED = 'EMPLOYEE_CONTRACTS_UPDATE_FULFILLED'
export const EMPLOYEE_CONTRACTS_UPDATE_REJECTED = 'EMPLOYEE_CONTRACTS_UPDATE_REJECTED'

export const EMPLOYEE_CONTRACTS_DELETE = 'EMPLOYEE_CONTRACTS_DELETE'
export const EMPLOYEE_CONTRACTS_DELETE_PENDING = 'EMPLOYEE_CONTRACTS_DELETE_PENDING'
export const EMPLOYEE_CONTRACTS_DELETE_FULFILLED = 'EMPLOYEE_CONTRACTS_DELETE_FULFILLED'
export const EMPLOYEE_CONTRACTS_DELETE_REJECTED = 'EMPLOYEE_CONTRACTS_DELETE_REJECTED'

export const CHECK_CONTRACT_PERIOD_LOAD = 'CHECK_CONTRACT_PERIOD_LOAD'
export const CHECK_CONTRACT_PERIOD_LOAD_PENDING = 'CHECK_CONTRACT_PERIOD_LOAD_PENDING'
export const CHECK_CONTRACT_PERIOD_LOAD_FULFILLED = 'CHECK_CONTRACT_PERIOD_LOAD_FULFILLED'
export const CHECK_CONTRACT_PERIOD_LOAD_REJECTED = 'CHECK_CONTRACT_PERIOD_LOAD_REJECTED'
