import { MudadStepsType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'

export type MudadStepsNumber = 1 | 2 | 3 | 4

type StepsNumberType = {
  [key in MudadStepsType['current_step']]: MudadStepsNumber
}

export const convertMudadStepToNumber = (steps: MudadStepsType): MudadStepsNumber => {
  const stepsNumber: StepsNumberType = {
    register_establishment: 1,
    owner_verification: 2,
    verify_employees: 3,
    authorize_mudad: 4,
    confirmation: 4,
  }
  return stepsNumber[steps.current_step] || 1
}
