export const XERO_COST_CENTER_LIST_LOAD = 'XERO_COST_CENTER_LIST_LOAD'
export const XERO_COST_CENTER_LIST_LOAD_FULFILLED = 'XERO_COST_CENTER_LIST_LOAD_FULFILLED'
export const XERO_COST_CENTER_LIST_LOAD_REJECTED = 'XERO_COST_CENTER_LIST_LOAD_REJECTED'
export const XERO_COST_CENTER_LIST_LOAD_PENDING = 'XERO_COST_CENTER_LIST_LOAD_PENDING'

export const XERO_COST_CENTER_CREATE = 'XERO_COST_CENTER_CREATE'
export const XERO_COST_CENTER_CREATE_FULFILLED = 'XERO_COST_CENTER_CREATE_FULFILLED'
export const XERO_COST_CENTER_CREATE_REJECTED = 'XERO_COST_CENTER_CREATE_REJECTED'
export const XERO_COST_CENTER_CREATE_PENDING = 'XERO_COST_CENTER_CREATE_PENDING'

export const XERO_COST_CENTER_UPDATE = 'XERO_COST_CENTER_UPDATE'
export const XERO_COST_CENTER_UPDATE_FULFILLED = 'XERO_COST_CENTER_UPDATE_FULFILLED'
export const XERO_COST_CENTER_UPDATE_REJECTED = 'XERO_COST_CENTER_UPDATE_REJECTED'
export const XERO_COST_CENTER_UPDATE_PENDING = 'XERO_COST_CENTER_UPDATE_PENDING'

export const XERO_COST_CENTER_DELETE = 'XERO_COST_CENTER_DELETE'
export const XERO_COST_CENTER_DELETE_FULFILLED = 'XERO_COST_CENTER_DELETE_FULFILLED'
export const XERO_COST_CENTER_DELETE_REJECTED = 'XERO_COST_CENTER_DELETE_REJECTED'
export const XERO_COST_CENTER_DELETE_PENDING = 'XERO_COST_CENTER_DELETE_PENDING'

export const XERO_COST_CENTER_CATAGORIES = 'XERO_COST_CENTER_CATAGORIES'
export const XERO_COST_CENTER_CATAGORIES_FULFILLED = 'XERO_COST_CENTER_CATAGORIES_FULFILLED'
export const XERO_COST_CENTER_CATAGORIES_REJECTED = 'XERO_COST_CENTER_CATAGORIES_REJECTED'
export const XERO_COST_CENTER_CATAGORIES_PENDING = 'XERO_COST_CENTER_CATAGORIES_PENDING'

export const XERO_COST_CENTER_ACCOUNTS = 'XERO_COST_CENTER_ACCOUNTS'
export const XERO_COST_CENTER_ACCOUNTS_FULFILLED = 'XERO_COST_CENTER_ACCOUNTS_FULFILLED'
export const XERO_COST_CENTER_ACCOUNTS_REJECTED = 'XERO_COST_CENTER_ACCOUNTS_REJECTED'
export const XERO_COST_CENTER_ACCOUNTS_PENDING = 'XERO_COST_CENTER_ACCOUNTS_PENDING'
