export const UPDATE_REVIEW_CYCLE_GROUPS = 'UPDATE_REVIEW_CYCLE_GROUPS'
export const UPDATE_REVIEW_CYCLE_GROUPS_FULFILLED = 'UPDATE_REVIEW_CYCLE_GROUPS_FULFILLED'
export const UPDATE_REVIEW_CYCLE_GROUPS_REJECTED = 'UPDATE_REVIEW_CYCLE_GROUPS_REJECTED'
export const UPDATE_REVIEW_CYCLE_GROUPS_PENDING = 'UPDATE_REVIEW_CYCLE_GROUPS_PENDING'

export const ASSIGN_EMPLOYEES_EVALUATION = 'ASSIGN_EMPLOYEES_EVALUATION'
export const ASSIGN_EMPLOYEES_EVALUATION_PENDING = 'ASSIGN_EMPLOYEES_EVALUATION_PENDING'
export const ASSIGN_EMPLOYEES_EVALUATION_FULFILLED = 'ASSIGN_EMPLOYEES_EVALUATION_FULFILLED'
export const ASSIGN_EMPLOYEES_EVALUATION_REJECTED = 'ASSIGN_EMPLOYEES_EVALUATION_REJECTED'

export const EMPLOYEES_REVIEWS = 'EMPLOYEES_REVIEWS'
export const EMPLOYEES_REVIEWS_PENDING = 'EMPLOYEES_REVIEWS_PENDING'
export const EMPLOYEES_REVIEWS_FULFILLED = 'EMPLOYEES_REVIEWS_FULFILLED'
export const EMPLOYEES_REVIEWS_REJECTED = 'EMPLOYEES_REVIEWS_REJECTED'

export const GET_EVALUATION_DETAILS = 'GET_EVALUATION_DETAILS'
export const GET_EVALUATION_DETAILS_PENDING = 'GET_EVALUATION_DETAILS_PENDING'
export const GET_EVALUATION_DETAILS_FULFILLED = 'GET_EVALUATION_DETAILS_FULFILLED'
export const GET_EVALUATION_DETAILS_REJECTED = 'GET_EVALUATION_DETAILS_REJECTED'

export const REMOVE_EMPLOYEE_REVIEW_FROM = 'REMOVE_EMPLOYEE_REVIEW_FROM'
export const REMOVE_EMPLOYEE_REVIEW_FROM_PENDING = 'REMOVE_EMPLOYEE_REVIEW_FROM_PENDING'
export const REMOVE_EMPLOYEE_REVIEW_FROM_FULFILLED = 'REMOVE_EMPLOYEE_REVIEW_FROM_FULFILLED'
export const REMOVE_EMPLOYEE_REVIEW_FROM_REJECTED = 'REMOVE_EMPLOYEE_REVIEW_FROM_REJECTED'

export const REQUEST_EVALUATION_REVIEW = 'REQUEST_EVALUATION_REVIEW'
export const REQUEST_EVALUATION_REVIEW_PENDING = 'REQUEST_EVALUATION_REVIEW_PENDING'
export const REQUEST_EVALUATION_REVIEW_FULFILLED = 'REQUEST_EVALUATION_REVIEW_FULFILLED'
export const REQUEST_EVALUATION_REVIEW_REJECTED = 'REQUEST_EVALUATION_REVIEW_REJECTED'

export const GET_GROUP_DETAILS = 'GET_GROUP_DETAILS'
export const GET_GROUP_DETAILS_PENDING = 'GET_GROUP_DETAILS_PENDING'
export const GET_GROUP_DETAILS_FULFILLED = 'GET_GROUP_DETAILS_FULFILLED'
export const GET_GROUP_DETAILS_REJECTED = 'GET_GROUP_DETAILS_REJECTED'

export const SAVE_TASK = 'SAVE_TASK'
export const SAVE_TASK_PENDING = 'SAVE_TASK_PENDING'
export const SAVE_TASK_FULFILLED = 'SAVE_TASK_FULFILLED'
export const SAVE_TASK_REJECTED = 'SAVE_TASK_REJECTED'

export const RESET_TASK = 'RESET_TASK'
