import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type IdTypesPorps = {
  filterKey: string
  filters: string[]
}

const EndorsementRequestType = (props: IdTypesPorps): JSX.Element => {
  const { lookups } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  return (
    <FilterBox
      handleFilter={handleFilter}
      options={lookups.endorsement_type}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('endorsement_request_type')}
    />
  )
}
export default EndorsementRequestType
