import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type QuickBooksAccountType = {
  account_id: number | null
  id: number | null
  sync_employees_information: boolean
}

type InitStateType = {
  account: QuickBooksAccountType
  connected: boolean
  err_msg: string
  fetching: boolean
  loading: boolean
  statusFetching: boolean
}

const account: QuickBooksAccountType = {
  id: null,
  account_id: null,
  sync_employees_information: false,
}

const initialState: InitStateType = {
  fetching: false,
  loading: false,
  connected: false,
  statusFetching: false,
  account,
  err_msg: '',
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.QUICKBOOKS_LOAD_ACCOUNT_INFO_PENDING:
    case actions.SYNC_QUICKBOOKS_EMPLOYEE_PENDING:
    case actions.QUICBOOKS_DISCONNECT_ACCOUNT_PENDING:
    case actions.QUICKBOOKS_EXPORT_PAYROLL_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.QUICKBOOKS_LOAD_ACCOUNT_STATUS_PENDING:
      return {
        ...state,
        statusFetching: true,
      }
    case actions.QUICKBOOKS_CONNECT_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.QUICKBOOKS_EXPORT_PAYROLL_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.QUICKBOOKS_LOAD_ACCOUNT_STATUS_FULFILLED: {
      return {
        ...state,
        statusFetching: false,
        connected: payload.data?.data?.connected,
      }
    }
    case actions.QUICKBOOKS_LOAD_ACCOUNT_INFO_FULFILLED: {
      return {
        ...state,
        account: payload.data?.data?.account,
        fetching: false,
      }
    }
    case actions.QUICBOOKS_DISCONNECT_ACCOUNT_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
        connected: false,
      }
    }

    case actions.SYNC_QUICKBOOKS_EMPLOYEE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.QUICKBOOKS_CONNECT_ACCOUNT_FULFILLED: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.SYNC_QUICKBOOKS_EMPLOYEE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.QUICKBOOKS_EXPORT_PAYROLL_REJECTED:
    case actions.QUICKBOOKS_LOAD_ACCOUNT_INFO_REJECTED:
    case actions.QUICBOOKS_DISCONNECT_ACCOUNT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        err_msg: handleResponseErr(payload),
        fetching: false,
        statusFetching: false,
      }
    case actions.QUICKBOOKS_LOAD_ACCOUNT_STATUS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        err_msg: handleResponseErr(payload),
        fetching: false,
        statusFetching: false,
      }
    case actions.QUICKBOOKS_CONNECT_ACCOUNT_REJECTED: {
      return {
        ...state,
        err_msg: handleResponseErr(payload),
        loading: false,
      }
    }
    default:
      return state
  }
}
