import _ from 'lodash'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

function mapOptionsFunc(options = []) {
  const allOptions = []
  options.map((data) => allOptions.push(...data.options))
  const newOptions = allOptions.map((newData) => ({ [newData.id]: newData.title }))
  return _.defaults(...newOptions)
}

export const initialState = {
  fetching: false,
  updating: false,
  downloading: false,
  template: {},
  templates: [],
  options: [],
  mapOptions: [],
  printTemplate: {},
}

export default function letterTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LETTER_TEMPLATES_GET_ONE_PENDING:
    case actions.LETTER_TEMPLATES_OPTIONS_LOAD_PENDING:
    case actions.LETTER_TEMPLATES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
        template: {},
      }
    }

    case actions.LETTER_TEMPLATES_POST_PENDING:
    case actions.LETTER_TEMPLATES_UPDATE_PENDING:
    case actions.LETTER_TEMPLATES_POST_ATTACHMENT_PENDING: {
      return {
        ...state,
        updating: true,
      }
    }

    case actions.LETTER_TEMPLATES_PDF_LOAD_PENDING: {
      return {
        ...state,
        downloading: true,
      }
    }

    case actions.LETTER_TEMPLATES_POST_ATTACHMENT_FULFILLED: {
      return {
        ...state,
        updating: false,
      }
    }

    case actions.LETTER_TEMPLATES_PDF_LOAD_FULFILLED: {
      return {
        ...state,
        downloading: false,
      }
    }

    case actions.LETTER_TEMPLATES_LOAD_FULFILLED: {
      return {
        ...state,
        templates: action.payload.data.data.letter_types,
        fetching: false,
      }
    }

    case actions.LETTER_TEMPLATES_GET_ONE_FULFILLED: {
      return {
        ...state,
        template: action.payload.data.data.letter_type,
        fetching: false,
      }
    }

    case actions.LETTER_TEMPLATES_POST_FULFILLED: {
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        updating: false,
        templates: [].concat(state.templates, [action.payload.data.data.letter_type]),
      }
    }

    case actions.LETTER_TEMPLATES_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        updating: false,
        templates: updateOneListOfRecords(state.templates, action.payload.data.data.letter_type),
      }
    }

    case actions.LETTER_TEMPLATES_OPTIONS_LOAD_FULFILLED: {
      return {
        ...state,
        options: action.payload.data.data.options,
        fetching: false,
        mapOptions: mapOptionsFunc(action.payload.data.data.options),
      }
    }

    case actions.LETTER_TEMPLATES_DELETE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.LETTER_TEMPLATES_DELETE_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.LETTER_TEMPLATES_POST_REJECTED:
    case actions.LETTER_TEMPLATES_UPDATE_REJECTED:
    case actions.LETTER_TEMPLATES_GET_ONE_REJECTED:
    case actions.LETTER_TEMPLATES_LOAD_REJECTED:
    case actions.LETTER_TEMPLATES_OPTIONS_LOAD_REJECTED:
    case actions.LETTER_TEMPLATES_POST_ATTACHMENT_REJECTED:
    case actions.LETTER_TEMPLATES_DELETE_REJECTED:
    case actions.LETTER_TEMPLATES_PDF_LOAD_REJECTED:
    case actions.PRINT_LETTER_TEMPLATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        updating: false,
        fetching: false,
        downloading: false,
      }
    }

    case actions.PRINT_LETTER_TEMPLATE_PENDING:
    case actions.PREVIEW_LETTER_TEMPLATE_PENDING: {
      return {
        ...state,
        fetching: true,
        printTemplate: {},
      }
    }

    case actions.PRINT_LETTER_TEMPLATE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        printTemplate: action.payload.data.data,
      }
    }

    case actions.PREVIEW_LETTER_TEMPLATE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        printTemplate: action.payload,
      }
    }

    default:
      return state
  }
}
