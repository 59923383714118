import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PaymentModesTypes = {
  filterKey: string
  filters: string[]
}

const PaymentModes = (props: PaymentModesTypes): JSX.Element => {
  const { payment_modes } = useFilterAPI()

  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(payment_modes)
  const fltr = filter[props.filterKey]
  const paymentModes = filter.payment_modes

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (val !== 'all') {
      const withoutAll = paymentModes.filter((item: string) => item !== 'all')
      setFilter({ ...filter, payment_modes: [...withoutAll, val] })
    } else {
      setFilter({ ...filter, payment_modes: [] })
    }
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

export default PaymentModes
