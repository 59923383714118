import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState: { fetching: boolean } = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): { fetching: boolean } {
  switch (action.type) {
    case actions.ADD_MANUAL_OVERTIME_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ADD_MANUAL_OVERTIME_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ADD_MANUAL_OVERTIME_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
