export const MUDAD_FLOW_STEPS_LOAD = 'MUDAD_FLOW_STEPS_LOAD'
export const MUDAD_FLOW_STEPS_LOAD_PENDING = 'MUDAD_FLOW_STEPS_LOAD_PENDING'
export const MUDAD_FLOW_STEPS_LOAD_FULFILLED = 'MUDAD_FLOW_STEPS_LOAD_FULFILLED'
export const MUDAD_FLOW_STEPS_LOAD_REJECTED = 'MUDAD_FLOW_STEPS_LOAD_REJECTED'

export const MUDAD_REGISTER_ESTABLISHMENT = 'MUDAD_REGISTER_ESTABLISHMENT'
export const MUDAD_REGISTER_ESTABLISHMENT_PENDING = 'MUDAD_REGISTER_ESTABLISHMENT_PENDING'
export const MUDAD_REGISTER_ESTABLISHMENT_FULFILLED = 'MUDAD_REGISTER_ESTABLISHMENT_FULFILLED'
export const MUDAD_REGISTER_ESTABLISHMENT_REJECTED = 'MUDAD_REGISTER_ESTABLISHMENT_REJECTED'

export const MUDAD_SIGNATORIES_LOAD = 'MUDAD_SIGNATORIES_LOAD'
export const MUDAD_SIGNATORIES_LOAD_PENDING = 'MUDAD_SIGNATORIES_LOAD_PENDING'
export const MUDAD_SIGNATORIES_LOAD_FULFILLED = 'MUDAD_SIGNATORIES_LOAD_FULFILLED'
export const MUDAD_SIGNATORIES_LOAD_REJECTED = 'MUDAD_SIGNATORIES_LOAD_REJECTED'

export const MUDAD_SIGNATORIES_CREATE = 'MUDAD_SIGNATORIES_CREATE'
export const MUDAD_SIGNATORIES_CREATE_PENDING = 'MUDAD_SIGNATORIES_CREATE_PENDING'
export const MUDAD_SIGNATORIES_CREATE_FULFILLED = 'MUDAD_SIGNATORIES_CREATE_FULFILLED'
export const MUDAD_SIGNATORIES_CREATE_REJECTED = 'MUDAD_SIGNATORIES_CREATE_REJECTED'

export const MUDAD_SIGNATORIES_VERIFY = 'MUDAD_SIGNATORIES_VERIFY'
export const MUDAD_SIGNATORIES_VERIFY_PENDING = 'MUDAD_SIGNATORIES_VERIFY_PENDING'
export const MUDAD_SIGNATORIES_VERIFY_FULFILLED = 'MUDAD_SIGNATORIES_VERIFY_FULFILLED'
export const MUDAD_SIGNATORIES_VERIFY_REJECTED = 'MUDAD_SIGNATORIES_VERIFY_REJECTED'

export const MUDAD_SIGNATORIES_DELETE = 'MUDAD_SIGNATORIES_DELETE'
export const MUDAD_SIGNATORIES_DELETE_PENDING = 'MUDAD_SIGNATORIES_DELETE_PENDING'
export const MUDAD_SIGNATORIES_DELETE_FULFILLED = 'MUDAD_SIGNATORIES_DELETE_FULFILLED'
export const MUDAD_SIGNATORIES_DELETE_REJECTED = 'MUDAD_SIGNATORIES_DELETE_REJECTED'

export const MUDAD_DEFAULT_BANK_LOAD = 'MUDAD_DEFAULT_BANK_LOAD'
export const MUDAD_DEFAULT_BANK_LOAD_PENDING = 'MUDAD_DEFAULT_BANK_LOAD_PENDING'
export const MUDAD_DEFAULT_BANK_LOAD_FULFILLED = 'MUDAD_DEFAULT_BANK_LOAD_FULFILLED'
export const MUDAD_DEFAULT_BANK_LOAD_REJECTED = 'MUDAD_DEFAULT_BANK_LOAD_REJECTED'

export const MUDAD_AUTHORIZED_PMS_CREATE = 'MUDAD_AUTHORIZED_PMS_CREATE'
export const MUDAD_AUTHORIZED_PMS_CREATE_PENDING = 'MUDAD_AUTHORIZED_PMS_CREATE_PENDING'
export const MUDAD_AUTHORIZED_PMS_CREATE_FULFILLED = 'MUDAD_AUTHORIZED_PMS_CREATE_FULFILLED'
export const MUDAD_AUTHORIZED_PMS_CREATE_REJECTED = 'MUDAD_AUTHORIZED_PMS_CREATE_REJECTED'

export const MUDAD_AUTHORIZED_PMS_VERIFY = 'MUDAD_AUTHORIZED_PMS_VERIFY'
export const MUDAD_AUTHORIZED_PMS_VERIFY_PENDING = 'MUDAD_AUTHORIZED_PMS_VERIFY_PENDING'
export const MUDAD_AUTHORIZED_PMS_VERIFY_FULFILLED = 'MUDAD_AUTHORIZED_PMS_VERIFY_FULFILLED'
export const MUDAD_AUTHORIZED_PMS_VERIFY_REJECTED = 'MUDAD_AUTHORIZED_PMS_VERIFY_REJECTED'

export const MUDAD_ALL_VERIFY_EMPLOYEES = 'MUDAD_ALL_VERIFY_EMPLOYEES'
export const MUDAD_ALL_VERIFY_EMPLOYEES_PENDING = 'MUDAD_ALL_VERIFY_EMPLOYEES_PENDING'
export const MUDAD_ALL_VERIFY_EMPLOYEES_FULFILLED = 'MUDAD_ALL_VERIFY_EMPLOYEES_FULFILLED'
export const MUDAD_ALL_VERIFY_EMPLOYEES_REJECTED = 'MUDAD_ALL_VERIFY_EMPLOYEES_REJECTED'

export const MUDAD_ALL_AUTHORIZED_EMPLOYEES = 'MUDAD_ALL_AUTHORIZED_EMPLOYEES'
export const MUDAD_ALL_AUTHORIZED_EMPLOYEES_PENDING = 'MUDAD_ALL_AUTHORIZED_EMPLOYEES_PENDING'
export const MUDAD_ALL_AUTHORIZED_EMPLOYEES_FULFILLED = 'MUDAD_ALL_AUTHORIZED_EMPLOYEES_FULFILLED'
export const MUDAD_ALL_AUTHORIZED_EMPLOYEES_REJECTED = 'MUDAD_ALL_AUTHORIZED_EMPLOYEES_REJECTED'

export const ITEM_PRICE_LOAD = 'ITEM_PRICE_LOAD'
export const ITEM_PRICE_LOAD_PENDING = 'ITEM_PRICE_LOAD_PENDING'
export const ITEM_PRICE_LOAD_FULFILLED = 'ITEM_PRICE_LOAD_FULFILLED'
export const ITEM_PRICE_LOAD_REJECTED = 'ITEM_PRICE_LOAD_REJECTED'

export const MUDAD_AUTHORIZER_DELETE = 'MUDAD_AUTHORIZER_DELETE'
export const MUDAD_AUTHORIZER_DELETE_PENDING = 'MUDAD_AUTHORIZER_DELETE_PENDING'
export const MUDAD_AUTHORIZER_DELETE_FULFILLED = 'MUDAD_AUTHORIZER_DELETE_FULFILLED'
export const MUDAD_AUTHORIZER_DELETE_REJECTED = 'MUDAD_AUTHORIZER_DELETE_REJECTED'

export const MUDAD_REVOKE_ESTABLISHMENT = 'MUDAD_REVOKE_ESTABLISHMENT'
export const MUDAD_REVOKE_ESTABLISHMENT_PENDING = 'MUDAD_REVOKE_ESTABLISHMENT_PENDING'
export const MUDAD_REVOKE_ESTABLISHMENT_FULFILLED = 'MUDAD_REVOKE_ESTABLISHMENT_FULFILLED'
export const MUDAD_REVOKE_ESTABLISHMENT_REJECTED = 'MUDAD_REVOKE_ESTABLISHMENT_REJECTED'

export const RESET_MUDAD_STATE = 'RESET_MUDAD_STATE'
