const Setting = {
  // A
  assign_admin: 'Assign admin',
  after_completing_working_hours: 'After shift timeframe',
  after_completing_the_whole_required: 'After completing the whole required working hours',
  attendance_reminders: 'Attendance reminders',
  after_completing_hr_out_of_working_hours:
    'After completing {{beforeWorking}} out of  {{workingShift}} working hours',
  After_completing_the_required_working_hours: 'After completing the required working hours',
  after_shift_end_time: '({{overtimeHr}}) after shift end time',
  after_shift_timeframe: '({{overtimeHr}}) after shift timeframe',
  apps: 'Apps',
  area_in_english: 'Area  in English',
  area_in_arabic: 'Area  in Arabic',
  activate_the_offer: 'Activate the offer',
  automated_and_hassle_free: 'Automated and hassle free. It’s Linked with the Banks and WPS',
  analysis_user_activity: 'Analysis user activity.',
  accounts_script_number: 'Accounts script number',
  addition_types: 'Addition types',
  address_in_english: 'Address',
  address_in_arabic: 'Address in arabic',
  admins: 'Admins',
  admins_weekly_email: 'Admin email',
  authorize_employees_edit_bank: 'Authorize employees / Edit Bank',
  areas_locations: 'Areas / Locations',
  asset_types: 'Asset Types',
  all_organization: 'All Organization',
  all_payments: 'All Payments',
  allow_employee_request_loan:
    "Allow more than 1 active loan for each employee (this doesn't affect maximum loan amount)",
  annual_leave_configuartion: 'Annual leave configuration',
  attendance_management: 'Attendance Management',
  absence: 'Absence',
  additions: 'Additions',
  deductions: 'Deductions',
  apply: 'Apply',
  apply_to: 'Apply to',
  add_new_city: 'Add new city',
  add_new_admin: 'Add New Admin',
  accomodation_per_diem: 'Accomodation <p>Per diem</p>',
  active: 'Active',
  assigned_to: 'Assigned to',
  assign_new_employee: 'Assign new employee',
  approver: 'Approver',
  attendance_approval: 'Attendance Approval',
  attendance_approval_labe: 'Attendance approval required for payroll',
  area_name: 'Area name',
  area_locations: 'Area / locations',
  area_name_in_arabic: 'Area name in Arabic',
  admin_email_address: 'Admin email address',
  add_step: 'Add Step',
  add_approver_employee: 'Add approver employee',
  add_approver: 'Add Approver',
  add_payment_method: 'Add Payment Method',
  add_payment_method_low: 'Add Payment Method',
  activate_payment_method: 'Activate a Payment Method',
  mudad_is_activated: 'Mudad is Activated',
  offline_method_is_activated: 'Bank Processing is Activated',
  address_ar: 'Address in Arabic',
  account_id: 'Account ID',
  absence_policy: 'Absence policy',
  absence_deduct: 'Absence deduction',
  anniversary_notification: 'Anniversary notifications',
  absence_policy_description:
    'Absence policy will be applied if (absence calculation) in “cut off the day” page is applicable and the absence deduction on payroll will be based on the penalty below',
  absence_policy_configuration: 'Absence Policy Configuration',
  android_developer: 'Android Developer',
  area_name_ar: 'Area name in Arabic',
  is_absence_deduction_applicable: 'Is absence deduction applicable?',
  additions_types: 'Additions types',
  attendance_devices_configuration: 'Attendance devices configuration',
  app_modules: 'App modules',
  all_locations: 'All Locations',
  attendance_configurations: 'Attendance configurations',
  all_data: 'All data',
  accrual: 'Accrual',
  add_new_job_title: 'Add new job title',
  add_new_business_unit: 'Add new business unit',
  add_new_sponsor: 'Add new sponsor',
  add_new_employment_type: 'Add new type',
  add_document_type: 'Add new document type',
  add_new_clearance: 'Add new clearance',
  add_asset_type: 'Add new asset type',
  add_new_holiday: 'Add Holiday',
  add_destination: 'Add Destination',
  annual_leave_days: 'Annual leave entitlement',
  accrue_balance: 'Accrue balance',
  apply_in_negative: 'Apply in negative',
  activate_mudad: 'Activate Mudad',
  action_period: 'Action period',
  authenticate_employee_contracts: 'Authenticate employee contracts',
  action_period_tip:
    'You can identify the number of days that you can take an action after leave end in case of Resumption Confirmation Request inactive. After the defined days the (Extend and Early Return will be hidden. The period should be between (0 - 30) days',
  authorized_person: 'Authorized Person',
  authorized_person_message: 'Authorized Person <span>({{message}})</span>',
  authorized_persons: 'Authorized Persons',
  authorize_mudad: 'Authorize Mudad',
  add_signatory: 'Add Signatory',
  authorization_failed: 'Authorization Failed',
  authorize: 'Authorize',
  authorized_employees_can_process:
    'The authorized employees can process the payroll payment with Mudad.',
  authorized_employees_for_xbank: 'Authorized Employees for {{name}}',
  add_valid_iban_note: 'Add valid IBAN No',
  annual_balance_warning:
    'Changing Annual balance will change the upto date balance. Do you want to proceed?',
  annual_leave_entitlement_auto_convert_desc:
    'This feature allows the employee to automatically upgrade the annual entitlement if the employee completed 5 years in the facility and automatically determines the relevant employees.',
  add_new_deduction_type: 'Add new deduction type',
  add_new_addition_type: 'Add new addition type',
  add_new_grade: 'Add new grade',
  add_new_loan_type: 'Add new loan type',
  add_new_benefit_type: 'Add new benefit type',
  add_new_expense_type: 'Add new expense type',
  assets_request: 'Assets request',
  advance_vacation_salary_payment_task: 'Vacation Settlement payment task',
  assignee: 'Assignee',
  add_new_custom_field: 'New Custom Field',
  add_new_cost_type: 'Add new cost type',
  automatic_resumption_configuration: 'Automatic resumption configuration',
  automatic_resumption: 'Automatic resumption',
  absence_exemption_policy: 'Attendance exemption policy',
  advanced_vacation_salary_days_configuration: 'Advanced Vacation Salary Days Configuration',
  apply_advanced_vacation_salary_days:
    'Vacation Settlement will be generated before leave start date',
  advanced_vacation_salary_minimum_days: 'Advanced Vacation Salary Minimum Days',
  apply_configuration: 'Apply Configuration',
  admin: 'Admin',
  accounting_offline_journal_export: 'Accounting offline journal export',
  account: 'Account',
  ACCOUNT: 'ACCOUNT',
  add_new_delay_policy: 'Add new late arrival policy',
  assign_people: 'Assign people',
  add_overtime_policy: 'Add overtime policy',
  add_people_to_your_list: 'Add people to your list',
  advanced_vacation_description: 'The configuration of the Annual leave advanced settlement',
  apply_advanced_vacation_salary_days_description:
    'The number of days previous to the start of the vacation when the vacation settlement report will be generated',
  apply_advanced_vacation_minimum_days_description:
    'The number of minimum days of annual leave where the employee will be allowed to request for advanced salary',
  add_new_custom_leave: 'Add new custom leave',
  all_company: 'All Company',
  add_new_annual_leave: 'Add new annual leave',
  assets_workflow: 'Assets',
  add_executor_for_the_following_tasks: 'Add Executor For the Following Tasks',
  all_types: 'All Types',
  all_emplyees: 'All emplyees',
  add_new_ticket: 'Add new ticket',
  add_new_outsourcing_company: 'Add new outsourcing provider',
  allowances_for_ajeer_employees: 'Allowances for Ajeer Employees',
  allowance_paid_to_employee: 'Allowance Paid to Employee?',
  allowance_paid_to_employee_hint:
    'Yes: The allowances will be paid in-payroll, No: The allowances will be out-of-payroll (Outsourcing Employees Report).',
  ajeer_allowance_types: 'Ajeer Allowance Types',
  allowance_types: 'Allowance Types',
  attendance_reporting_methods: 'Attendance Reporting Methods',
  attendance_review: 'Attendance Review',
  add_new_geolocation: 'Add New Geo-location',
  add_new_department: 'Add New Department',
  add_new_commercial_registration: 'Add New Commercial Registration',
  add_new_cr: 'Add New CR',
  apply_in_negative_description1:
    'Disable: the number of days that will be available for the employee to submit an annual leave request will be equal to the balance due to the applied day, and a maximum of the employee’s expected balance until the date of the last day of the submitted leave request, and the employee will not be allowed to submit a request in case The balance was not enough.',
  apply_in_negative_description2:
    'Enable: The number of days that will be available for the employee to submit the annual leave request will be equal to a maximum of the total expected balance until the end of the current year.',
  apply_in_negative_configuration_description:
    'All pending Requests related to this configuration will not be valid. please, Notify the employees to reapply the request. -All Approved Requests that  related to this configuration will applied as previous configuration.',
  add_new_mangaing_person: 'Add New Admin',
  add_admins: 'Add Admins',
  add_location: 'Add Location',
  add_new_manager: 'Add New Manager',
  and_more_with_count: 'and {{count}} more',
  are_you_sure_you_want_to_change_your_payment_method_from_offline_processing_to_mudad:
    'Are you sure you want to change your payment method from Offline Processing to Mudad',
  are_you_sure_you_want_to_change_your_payment_method_from_mudad_to_offline_processing:
    'Are you sure you want to change your payment method from Mudad to Offline Processing?',
  add_hourly_rate_policy: 'Add a new policy',
  apply_in_payment_processing: 'Apply in Payment Processing',
  api_keys: 'API Keys',
  api_key: 'API Key',
  api_secret: 'API Secret',
  apply_to_all_employees: 'Apply to all employees',
  apply_for: 'Apply for',
  activate_this_option:
    'Activate this option will extend the probation period for employees who are Under probation with the same Sick leave period.',
  activate_this_option_note:
    'Note: This update will affect the next period and there is no affects on the previous calculations',
  activate_this_option_holiday:
    'Activate this option will extend the probation period for employees who are Under probation with the same holiday period.',
  add_new_pay_type: 'Add New Pay Type',
  add_allowances: 'Add Allowance',
  allowed_delay: 'Allowed Delay',
  allowed_shortage: 'Allowed Shortage',
  allow_members_to_clock: 'When enabled, the system will allow members to clock out automatically',
  add_custom: 'Add Custom',
  assign_employees: 'Assign Employees',
  added_employees: 'Added <span>{{employees}}</span> to this shift template',
  attendance_log: 'Attendance logs',
  activity_log: 'Activity log',
  am: 'AM',
  annual_leave: 'Annual Leave',
  any_clock_in_before_will_no_record:
    'Any clock-in before <span>{{time}}</span> will be recorded as a <span>No Record</span>',
  any_clock_in_after_will_no_record:
    'Any clock-in after <span>{{time}}</span> will be recorded as a <span>No Record</span>',
  any_clock_out_before_will_no_record:
    'Any clock-out before <span>{{time}}</span> will be recorded as a <span>No Record</span>',
  any_clock_out_after_will_no_record:
    'Any clock-out after <span>{{time}}</span> will be recorded as a <span>No Record</span>',
  actions: 'Actions',
  address_details: 'Address details',
  admin_details: 'Admin details',
  allow_clock_in_before: 'Allow clock-in before',
  allow_clock_out_before: 'Allow clock-out before',
  allow_clock_in_until: 'Allow clock-in until',
  allow_clock_out_untill: 'Allow clock-out until',
  all_shift_presets: 'All Shift Presets',
  admin_only: 'Admin Only',
  a_manager_of: 'A manager of',
  add_users: 'Add Users',
  adding_new_employees_saudi_non_saudi: 'Adding new employees (Saudi/Non-Saudi)',
  auto_approve_the_last_hr_of_the_shift_duration:
    'Auto-approve the last <span>{{time}}</span> of the shift duration',
  any_worked_duration_between:
    'Any worked duration between <span>{{first_time}}</span> and <span>{{second_time}}</span> end of the shift will count as approved overtime',
  any_worked_duration_between_first_end_time:
    'Any worked duration between <span>{{first_time}}</span> and <span>{{second_time}}</span> end of the shift will count as approved overtime',

  automatic_overtime: 'Automatic Overtime',
  automatic_overtime_disclaimer_short:
    'Currently, Automatic Overtime is not as smart as it should be. I... <span>Read more</span>',
  automatic_overtime_disclaimer:
    'Currently, Automatic Overtime is not as smart as it should be. It will not take in consideration whether employees did or did not complete the expected working duration from them. <p> We’re working on providing much better Automatic Overtime configurations in upcoming updates. Stay tuned.</p>',
  auto_overtime: 'auto overtime',
  assigned_roles: 'Assigned roles',
  admins_have_full_access: 'Admins have full access to all data and actions.',
  add_alt: 'Add',
  all_alt: 'All',
  add_new_in_house: 'Add New In-House',
  ajeer_name: 'Ajeer Name',
  ajeer_alert: 'By default, all selected allowances will be assigned to Provider',
  attendance_integration: 'Attendance Integration',
  attendance_reflection: 'Attendance Reflection',
  add_type: 'Add type',
  add_custom_type: 'Add type',
  add_approval_cycle: 'Add Approval Cycle',
  all_leave_types: 'All Leave Types',
  assign_manager: 'Assign manager',
  annual_leave_description:
    'You will be able to select the annual entitlement for each employee only from the listed entitlement days',
  annual_leave_calculation: 'Annual Leave Calculation',
  annual_leave_calculation_description:
    'Business or calendar days will change employees balance deduction method, any changes will also reflect on their current balances',
  advanced_configuration_description:
    'Limit the number of days to request advanced vacation salary for all employees',
  approver_employee: 'Approver Employee',
  approvers: 'Approvers',
  active_employees: 'Active employees <b> {{ count }} </b>',
  are_you_sure_you_want_to_delete_emp: 'Are you sure you want to delete?',
  alinma_bank: 'Alinma Bank',
  alinma_bank_reverse: 'مصرف الإنماء',
  confirm_delete_department:
    'Are you sure you want to delete this department? This action cannot be undone.',
  manager_optional: 'Manager (optional)',
  are_you_sure_you_move_departments:
    'Are you sure you want to move these employees to other departments?',
  asset_utilization: 'Asset Utilization',
  asset_type_name: 'Asset Type',
  allow_employees_to_request_assets_of_this_type: 'Allow employees to request this type',
  allow_employees_to_request_asset: 'Allow employees to request',
  asset_statuses: 'Asset Statuses',
  approval_cycles: 'Approval Cycles',
  api_key_name_title: 'API Key Name',
  api_key_name_placeHolder: 'Api Key Name',
  api_key_required: 'Required',
  api_secret_helper_text: 'API Secret is used to authenticate the organization',
  api_key_helper_text: 'API Key is used to authorize the client for the selected APIs',
  api_secret_visible_only_once:
    'The API secret will only be visible once, <strong>Save a copy</strong>',
  activity_history: 'Activity History ',
  add_ons: 'Add-ons',
  addـonـtype: 'Add-on type',
  add_ons_mudad_notifications: 'Add-ons',
  are_you_sure_you_want_to_activate_mudad_for_cr:
    'Are you sure you want to {{status}} Mudad for CR-{{crNumber}}?',
  activate_confirm:
    'If you confirmed your payroll this month, the changes will take place on your next payroll.',
  after_shift_duration: 'After shift duration',
  archive_this_legal_structure: 'Archive this Legal Structure',
  archived: 'Archived',
  archive_cr: 'Archive',
  add_sub_organization: 'Add Sub-Organization',
  add_legal_structure: 'Add legal structure',
  new_registration: 'New Registration',
  add_your_first_registration: 'Add your first registration',
  add_your_first_registration_description:
    'Begin adding the registered organization information to ensure a smooth sync with governmental entities such as GOSI and WPS (Mudad)',
  any_time_of_the_day: 'Any time of the day',
  are_you_sure_you_want_to_cancel_your_account:
    'Are you sure you want to cancel your Subscription?',
  are_you_really_ready_to_give_system: 'Are you really ready to give up {{app_name}} system?',
  all_employees: 'All employees',
  at_least_2_fingerprints: 'At least 2 fingerprints',
  add_another_employee: 'Add another employee',
  after_the_shift_ends: 'After the shift ends',
  // B
  bank_name_ar: 'Bank Name in Arabic',
  before_the_shift_ends: 'Before the shift ends',
  before_deductions_applied: 'Before deductions applied',
  before_the_shift_starts: 'Before the shift starts',
  before_shift_end_time: '({{overtimeHr}}) before shift end time',
  before_shift_timeframe: '({{overtimeHr}}) before end of shift timeframe',
  bank_transfer_details: 'Bank Transfer Details',
  bank_name_reverse: 'أسم البنك',
  beneficiary_name: 'Beneficiary Name',
  beneficiary_name_reverse: 'أسم المستفيد',
  beneficiary_name_ar: 'Beneficiary Name in Arabic',
  back_to_annual_leave: 'Back to Annual Leave',
  back_to_selecting_employees: 'Back to selecting employees',
  back_to_email_updates_for_admin: 'Back to Email updates for Admin',
  back_to_email_updates_for_employees: 'Back to Email updates for employees',
  back_to_old_interface: 'Back to old interface',
  back_to_exemption_from_deductions: 'Back to Exemption From Deductions',
  bank_payment: 'Bank Payment',
  boolean: 'Boolean',
  business_units: 'Business Units',
  businessunit: 'Business Unit',
  business_trip: 'Business trip',
  business_trips: 'Business trips',
  business_trip_request: 'Business trip request',
  buffer_time_for_overnight_shift: 'Buffer time for overnight shift',
  buffer_time: 'Buffer Time',
  by_connecting_gosi_model_opening_title:
    'By connecting your GOSI account with {{app_name}}. {{app_name}} will have the permission to do following actions:',
  bank_account_number: "BANK'S ACCOUNT NUMBER",
  back: 'Back',
  before_continue_make_sure_you_provide_note:
    'Before continue, make sure you provide the correct information of the owner/authorizer',
  business: 'Business',
  bank_details: 'Bank details',
  benefit_types: 'Benefit types',
  birthday_notification: 'Birthdays notifications',
  benefits_compensations: 'Benefits (Compensations)',
  bupa_insurance: 'Bupa insurance',
  buffer_note: 'Buffer for Night Shifts will be 4 hours.',
  business_trip_payment_task: 'Business trip payment task',
  payroll_basic_configuration: 'Payroll basic configuration',
  browse_files: 'Browse Files',
  business_description:
    'Business days calculation will exclude the day off which is defined for the employees either in the shift or the scheduler from the balance deduction',
  by_group: 'By Group',
  business_trip_workflow: 'Business Trip',
  by_type: 'By Type',
  bank_account_name_iban_format: '{{ name }} - IBAN: {{ iban }}',
  by: 'By',
  by_reminder: 'By',
  billing_posted: 'Posted',
  bank_removed_successfully: 'Bank removed successfully',
  billing_pending: 'Pending',
  billing: 'Billing',
  BILLING: 'BILLING',
  billing_history: 'Billing History',
  billing_history_mudad: 'Billing History',
  by_none: 'By None',
  by_employees: 'By Employees',
  basic_selected_allowances: 'Basic + Selected allowances',
  bulk_import_for: 'Bulk import for <strong>{{name}}</strong>',
  bulk_import_and_export: 'Bulk Import and Export',
  bulk_import_and_export_report: 'Bulk Import and Export report',
  break: 'Break',
  bulk_import: 'Bulk import',
  blue: 'Blue',
  brown: 'Brown',
  billing_contact_details: 'Billing contact details',
  business_unit_title: 'Business unit Title',
  business_unit_title_in_ar: 'Business unit title in Arabic',
  back_to_billing_page: 'Back to Billing Page',
  // C
  change_payment_method: 'Change payment method',
  confirm_renewal: 'Confirm Renewal',
  confirm_mudad_subscription: 'Confirm Mudad Subscription',
  current_settings: 'Current settings: {{ settings }}',
  changing_the_fund_date_msg: 'The fund date will be changed, Are you sure to continue?',
  changing_the_payslip_msg: 'Sharing the Payslip time will be changed, Are you sure to continue?',
  changing_the_cut_off_date:
    'Changing the cut-off date it will effect the  payroll and all calculation, Are sure to continue?',
  changing_the_salary_reflection:
    'By Changing the salary reflection configuration(splitted/separated) it split the transaction to salary breakdown or to have a separate column in  payroll , Are sure to continue?',
  calculation_of_vacation_salary: 'Calculation of Vacation Salary',
  current_plan: 'Current plan',
  cutoff__payment: 'Cut-off & Payment',
  cut_off: 'Cut-off',
  calculate_overtime_after: 'Calculate Overtime After',
  complete_mudad_registration: 'Complete Mudad Registration',
  connected: 'Connected',
  country_name: 'Country Name',
  calculate_cashier_users_revenue: "Calculate cashier users' revenue",
  connect_your_apps_account: 'Connect your {{apps}} account',
  casual_description: 'Casual leave',
  connect_integrate: 'Connect',
  connected_integrate: 'Connected',
  cancel_payment: 'Cancel Payment',
  canceling_payment: 'Canceling Payment',
  canceling_payment_info: 'You’re about to cancel payment # {{id}}. Are you sure about it?',
  calculation_configuration: 'Calculation Configuration',
  change_password: 'Change Password',
  check_connection: 'Check connection',
  company_documents: 'Company Documents',
  company_document: 'Company Document',
  company_docs: 'Company Docs',
  cost_centers_script_number: 'Const center script number',
  clearances: 'Clearances',
  cut_off_date: 'Cut Off Date',
  cost_centers: 'Cost centers',
  compensation_policy: 'Compensation policy',
  company_location: 'Company location',
  custom_day: 'Custom day',
  connect_your_gosi_subscriptions: 'Connect your gosi subscriptions ({{ count }})',
  code: 'Code',
  create_new_letter: 'Create New Letter',
  calculation_double_overtime:
    ' The calculation will be First hour paid in full package, and second hour paid in Basic.',
  calculation_single_overtime:
    ' For Single overtime the calculation will be First hour paid in full package, half an hour paid in Basic.',
  can_an_employee_request_a_loan: 'Can an employee request a loan while having an active loan?',
  can_an_employee_request_a_loan_tool_tip:
    'Control if the employee can request another loan while having an active one',
  category: 'Category',
  carry_type: 'Carry type',
  city_name: 'City name',
  configuration: 'Configuration',
  contact_person: 'Contact person',
  contact_number: 'Contact number',
  city: 'City',
  choose_the_commercial_registration:
    'Choose the commercial registration that you want to add this method on it',
  country_ar: 'Country in Arabic',
  city_ar: 'City in Arabic',
  cities: 'Cities',
  connect: 'Connect',
  connect_device: 'Connect device',
  cost_centers_mapping: 'Cost centers mapping',
  calendar: 'Calendar',
  choose_the_shift_type: 'Choose the shift type',
  carry_forward_title: 'How would you like the leave to carry forward?',
  carry_forward_title2: 'What is the maximum number of days to carry over next year?',
  carry_forward_title3: 'Do you want to apply this policy to all employees in company?',
  choose_criteria_and_apply: 'Choose criteria and apply',
  changing_configuration_message:
    'Changing the configuration will delete all policies you created, <b>Are you sure?<b>',
  contact_information: 'Contact Information',
  confirm: 'Confirm',
  can_loan_apply: 'Can an employee request a loan while having an active loan?',
  continue_cancellation: 'Continue to cancellation',
  configurations: 'Configurations',
  costs_types: 'Costs Types',
  company_id: 'Company ID',
  chart_of_accounts_mapping: 'Chart of Accounts Mapping',
  cost_center_types: 'Cost Center Types',
  created_by_automation: 'Created By Automation',
  credits: 'Credits',
  choose_person: 'Choose Person',
  consumer_secret: 'Consumer Secret',
  consumer_key: 'Consumer ID',
  choose_person_message: 'Choose Person <span>({{message}})</span>',
  calendar_description:
    'Calendar days will include the day off defined for the employees in the shift or in the scheduler module from the balance deduction',
  carry_forward_description:
    "Annual leave balance carry forward policy is to motivate the employees to use their balance before the end of the Calendar year, otherwise they'll either not carry the balance, or they'll carry a limited balance, for more details please read the following article.",
  carry_balance_description:
    "Don't allow any employee to transfer his annual leave balance to the next calendar year",
  carry_all_balance_description:
    'Allow employee to transfer his complete annual balance to the next calendar year ',
  choose_the_bank_for_payroll_processing: 'Choose the bank for payroll processing',
  currency_type: 'Currency type',
  currency: 'Currency',
  create_new_role: 'Create new role',
  choose_the_type: 'Choose the type of overtime that will be calculated',
  create_new_allowance: 'Create New Allowance',
  calculation_reflection_time_msg:
    'The calculations relate to the new changes will be updated in {{time}}',
  calculation_reflection_time_new_shift_msg:
    'The calculation relate to the new shift will be updated in {{time}}',
  calculation_reflection_time_new_holiday_msg:
    'Calculation relate to the new holiday will be updated in {{time}}',
  calculation_reflection_time_delete_holiday_msg:
    'The calculation relate to Delete the holiday will be updated in {{time}}',
  calculation_reflection_time_approving_request_msg:
    'The calculation relate to approving the {{request_type}} request will be updated in {{time}}',
  calculation_reflection_time_cancel_request_msg:
    'The calculation relate to cancel the {{request_type}} request will be updated in {{time}}',
  calculation_relate_to_new_changes:
    'The calculation relate to the new changes will be updated in {{time}}',
  calculation_end__service_settlement: 'Calculation End Of Service Settlement',
  choose_location: 'Choose Location',
  choose_radius: 'Choose Radius',
  configuration_deduction_note:
    'This configuration sets up on which the deduction of absenteeism (Absence, late arrival, early departure, overtime, Incomplete record) depends on: Full package, Basic salary or Basic + selected allowances',
  configuration_salary_reflection_note:
    'This configuration sets up on which to split the transaction to the salary breakdown or to have a separate column in the payroll (New joining employee, Overlapping vacation salary, Retro appraisal) depends on: Split the transaction to the salary breakdown OR Separate column in the payroll',
  create_new_parent_department: 'Create new parent department',
  create_a_letter_template: 'Create a letter template',
  cr_registration_number: 'CR No',
  commercial_registration_number: 'Commercial registration number',
  cr_name_en: 'CR Name En',
  connect_to_netsuite_account: 'Connect to Netsuite Account',
  connect_to_quickbooks_account: 'Connect to QuickBooks Account',
  connect_to_microsoft_account: 'Connect to MicroSoft Account',
  connect_to_microsoft_finops_account: 'Connect to MS Dynamics Finance & Operation Account',
  connect_to_oracle_fusion_account: 'Connect to Oracle Fusion Account',
  connect_to_zoho_books_account: 'Connect to Zoho Books Account',
  connect_to_sap_account: 'Connect to Sap Business One Account',
  connect_to_odoo_account: 'Connect to Odoo Account',
  Odoo: 'Odoo',
  connect_to_xero_account: 'Connect to Xero Account',
  connect_to_qoyod_account: 'Connect to Qoyod Account',
  cr_name_ar: 'CR Name Ar',
  calculation_the_accommodation: 'Would you like to calculation the accommodation',
  commerical_registrations_crs: 'Commerical Registrations / CRs',
  configure_offline_processing: 'Configure Bank Processing',
  cr_name: 'CR Name',
  cr_name_in_english: 'CR Name In English',
  cr_name_in_arabic: 'CR Name In Arabic',
  confirm_payment_method: 'Confirm Payment Method',
  confirmation: 'Confirmation',
  carry_forward_recalculate_description:
    'Would you like to recalculate the carried balance for the previous year and will effect on current year leave balances calculations?',
  carry_forward_recalculate_invalid_description:
    'Changing in carry forward configuration  will affect the following Requests',
  content: 'Content',
  confirm_and_log_users_out: 'Confirm & log users out',
  confirm_and_keep_users_logged: 'Confirm & keep users logged',
  combos: 'Combos',
  create_new_shift: 'Create New Shift',
  clock_out_not_required: 'Clock out is not required',
  clockout_not_required: 'Clock out not required',
  create_custom_shift: 'CREATE CUSTOM SHIFT',
  create_custom_template: 'CREATE CUSTOM SHIFT',
  create_template: 'Create Template',
  create_shift_presets: 'Create Shift Presets',
  create_combo: 'Create Combo',
  create_destination: 'Create Destination',
  complete_setup: 'Complete setup',
  chip_allowed_delay: '<span>{{allowed_delay}}</span> allowed delay',
  chip_allowed_shortage: '<span>{{allowed_shortage}}</span> allowed shortage',
  combo_name_in_arabic: 'Combo Name in Arabic(Optional)',
  current_template_assigned: 'Current Template Assigned',
  change_take_effect_today: 'Changes will take effect starting from today.',
  change_take_effect_tomorrow: 'Changes will take effect starting from tomorrow.',
  copy_previous_week: 'Copy previous week',
  copy_previous_day: 'Copy previous day',
  connection_information: 'Connection information',
  copy_previous: 'Copy previous',
  clear_this_week: 'Clear this week',
  clear_this_day: 'Clear this day',
  changes_allowed_on_days:
    'Changes are not allowed on days that have been approved and submitted before.',
  clear_all_filters: 'Clear all filters',
  clock_in_rules: 'Clock-in Rules',
  clock_out_rules: 'Clock-out Rules',
  clock_in_after_will_late_in:
    'Clocking in after <span>{{time}}</span> will be recorded as <span>Late In</span>',
  clock_out_after_will_late_in:
    'Clocking out before <span>{{time}}</span> will be recorded as <span>Early Out</span>',
  clock_in_not_required: 'Clock in is not required',
  custom_fields: 'Custom Fields',
  creaed_at: 'Created at',
  created_by: 'Created By',
  connect_your_gosi_account_aamaly: 'Connect your GOSI account - Aamaly',
  calculation_vacation_eos_settlement:
    ' Calculation of Vacation Salary & End Of Service Settlement',
  customizing_leaves_setting_description:
    'You can add leave types based on your organization’s need, you can define the name that will appear to employees, and the days per year.',
  confirm_changes: 'Confirm Changes',
  correction_request_workflow: 'Correction request workflow',
  correction_request: 'Correction request',
  count_employees: '{{count}} Employees',
  create_fixed_hours: 'Create Fixed Hours',
  clock_in_window_start_at: 'Clock-in window start at: <span>{{time}}</span>',
  clock_in_window_end_at: 'Clock-in window end at: <span>{{time}}</span>',
  clock_out_window_start_at: 'Clock-out window start at: <span>{{time}}</span>',
  clock_out_window_end_at: 'Clock-out window end at: <span>{{time}}</span>',
  clocking_after:
    'Clocking in after <span>{{time}}</span> will be recorded as <span>‘Late In’</span>',
  clocking_before:
    'Clocking out before <span>{{time}}</span> will be recorded as <span>‘Early Out’</span>',
  custom_alt: 'Custom',
  company_name: 'Company name',
  company_file_expiry_date_optional: 'Company file Expiry date (Optional)',
  commercial_registration_no: 'Commercial Registration No',
  commercial_registration: 'Commercial Registration',
  custom_leaves: 'Custom Leaves',
  confirm_message: 'Are you sure you want to delete "{{name}}" ?',
  changing_annual_leave_calculation_configuration:
    'Changing annual leave calculation configuration will effect the calculation for open and upcoming annual leave requests only. Are you sure to continue?',
  created_by_open_api: 'Created By',
  created_at_open_api: 'Created At',
  confirm_api_delete_text: 'Are you sure you want to delete “{{name}}” API Key',
  create_open_api_key: 'New API Key',
  copy: 'copy',
  copied: 'copied',
  confirm_enable_integration_text: 'Are you sure you want to enable {{name}} Integration ?',
  confirm_disable_integration_text: 'Are you sure you want to disable {{name}} Integration ?',
  cancel_my_subscription: 'Cancel my subscription',
  confirm_message_mudad:
    'This will delete the CR and “Mudad” Subscription linked to it. Are you sure you want to delete "{{name}}" ? ',
  change_ats_message:
    'It seems that {{ current }} is already integrated. Are you sure you want to change integration to {{ target }}, this will disable {{ current }}?',
  cannot_edit_legal_structure_err_msg_title: 'You can’t edit this registration',
  cannot_edit_legal_structure_err_msg_description:
    'This registration is linked with Mudad, you can archive it and create a new one',
  cannot_change_org_type_err_msg_title: 'You can’t change the organization type',
  cannot_change_org_type_err_msg_description:
    "You can't make this registration a sub-organization because it has associated sub-organizations.",
  cannot_change_gosi_number_err_msg_title: "You can't change GOSI subscription number",
  cannot_change_gosi_number_err_msg_description:
    'Your account has an active integration with the GOSI platform, revoke it first.',
  cannot_delete_cr_tooltip_title:
    'You can’t delete this legal structure because it linked to payment method',
  cannot_delete_cr_linked_with_gosi: 'Cannot delete this CR , It is already linked with GOSI',
  cannot_archive_cr_linked_with_gosi: 'Cannot archive this CR , It is already linked with GOSI',
  cr_name_example: 'Ex: {{app_name}} systems for information technology',
  cr_number_example: 'Ex: 12345',
  create_new_registration: 'Create new registration',
  close: 'Close',
  clocking_rules: 'Clocking Rules',
  confirm_cancellation: 'Confirm cancellation',
  connect_with_agent: 'Connect with a {{app_name}} agent',
  create_and_manage_your_company_structure: "Create and manage your company's structure.",

  // D
  due_to_late_payments: 'Due to late payments',
  deduct_half_day: 'Deduct half day leaves from annual balance',
  delete_annual_leave_are_sure_to_continue: 'Delete Annual Leave, Are sure to continue?',
  delete_outsourcing_providers: 'Delete Outsourcing Providers',
  delete_sub_department: 'Delete Department',
  department_policies: 'Department',
  delete_department: 'Delete Parent Department',
  delete_country: 'Delete Country',
  delete_area: 'Delete Area',
  delete_location: 'Delete Location',
  delete_role: 'Delete Role',
  delete_role_name: 'Delete role: {{role}}',
  delete_custom_field: 'Delete Custom Field?',
  delete_custom_field_warning:
    "All information related to {{name}} field will be deleted from Employee's Profile.\nYou can't recover data after deleting it.",
  delete: 'Delete',
  death_of_relatives_description:
    'in case of death of spouse or any other blood relation in the family (mother, father, brother, sister, son, daughter), workers are entitled 3 days paid leave.',
  deduction_types: 'Deduction types',
  delay: 'Late arrival',
  delay_time: 'Late arrival time',
  day: 'day',
  date_time: 'Date',
  devices_configurations: 'Devices configurations',
  days_per_year: 'Days per year',
  department_name: 'Department Name',
  department_name_ar: 'DEPARTMENT NAME AR',
  departments: 'Departments',
  department_id: 'Parent Departments/Departments',
  departments_plural: 'Departments',
  disconnect: 'Disconnect',
  device_brand: 'Device brand',
  devices_admin: 'Devices Admin',
  do_you_want_to_authorize: 'Do you want to authorize more employees to use Mudad ?',
  do_you_want_to_verify: 'Do you want to Verify more employees ?',
  days_counting_description:
    'This is to define the type of calculation the annual leave ( entitlement and deductions ) if it is based on Calendar days or business days',
  daily: 'Daily',
  delay_allowed_time: 'Late arrival allowed time',
  is_delay_deduction_applicable: 'Is late arrival deduction applicable?',
  deductions_types: 'Deductions types',
  device_records: 'Attendance logs',
  device_record_upload: 'Attendance logs',
  device_location: 'Device location',
  date_due: 'Date due',
  due_amount: 'Due amount',
  devices: 'Devices',
  disconnect_integrate: 'Disconnect',
  default_bank_tooltip_note: ' You’re currently using {{account}} with {{method}} payment method',
  diff_payment_method: 'Choose a different bank from Payment Methods',
  duplicate_entries_warning: 'Duplicate entries are not allowed',
  deduct_from_salary: 'Deduct from salary',
  deduct_from_annual_leave_balance: 'Deduct from annual leave balance',
  double_overtime: 'Double (2x)',
  double_overtime_description:
    'For Double overtime the calculation will be: First hour paid in full package, and second hour paid in Basic.',
  document_number: 'Document number',
  debits: 'Debits',
  day_days: 'Day/Days',
  do_not_deduct: 'Do not Deduct',
  deduct: 'Deduct',
  date_of_sharing_payslip_with_employees:
    'Share payslip with employees after confirming payroll by (Days)',
  decimal_points_included: 'Decimal points included ?',
  deduction_calculation_configuration: 'Deduction on attendance calculation configuration',
  deductions__overtime: 'Deductions & Overtime',
  deductions_additions: 'Deductions & Additions',
  department_name_in_english: 'Department name in English',
  department_name_in_arabic: 'Department name in Arabic',
  do_you_want_use_offline_process_as_your_payment_method:
    'Do you want to use Offline Processing as your payment method?',
  do_you_want_use_Mudad_as_your_payment_method: 'Do you want to use Mudad as your payment method?',
  do_you_want_to_confirm: 'Do you want to confirm?',
  default: 'Default',
  default_case_1: 'Default',
  default_rate: 'Default',
  drop_file_here: 'Drop your file here',
  download_report_file: 'Download Error Report File',
  day_template: 'DAY TEMPLATE',
  date: 'Date',
  delete_shift: 'Delete shift',
  data_type: 'Data Type',
  do_not_allow_reusing_last: 'Do not allow reusing last',
  discard_changes: 'Discard Changes',
  dear_client_kindly_update_the_billing_description:
    'Dear Client. Kindly, Update the billing details to avoid any issues in invoice info.',
  duration_can_come_late: 'The duration can come in late, is the duration can stay late',
  disclaimer: 'Disclaimer',
  delete_letter: 'Delete Letter Template',
  delete_letter_desc:
    'You will lose all template content. No employee will be able to request this type after deleting it',
  deleting_this_role: 'Deleting this role will also remove all granted permissions from users.',
  days_until_expiry: 'Days until expiry',
  destinations: 'Destinations',
  destination_name: 'Destination Name',
  disconnected: 'Disconnected',
  different_locations: 'Different Locations',
  different_locations_desc: 'You can select a different location for each employee',
  different_department: 'Different Department',
  different_department_desc: 'You can select a different department for each employee',
  delete_annual_leave: 'Delete Annual Leave',
  delete_job_title: 'Delete Job Title',
  delete_employment_type: 'Delete employment type',
  delete_document_type: 'Delete document type',
  delete_allowances: 'Delete Allowances',
  delete_loan_type: 'Delete Loan Type',
  delete_cost_type: 'Delete Cost Type',
  delete_workflow: 'Delete Workflow',
  delete_holiday: 'Delete Holiday',
  delete_subdepartments_order_department:
    'Delete sub-departments in order to delete this department.',
  deleting_asset_type_warning_message:
    'By deleting {{assetTypeName}} you will not be able to use this type.',
  delete_api: 'Delete API',
  disabled: 'Disabled',

  disabled_by: 'Disabled by {{name}}',
  default_account_for_paying_the_salary_stream: 'Default account for paying the salary stream',
  do_you_want_to_renew_mudad_for_this_cr: 'Do you want to Renew Mudad for this CR?',
  deactivate_confirm:
    'If you confirmed your payroll this month, the changes will take place on your next payroll. You still can reactivate Mudad again during your subscription period.',
  deactivate: 'deactivate',
  disable_app: 'Disable {{name}}',
  deleted_employee: 'Deleted Employee',
  duplicate: 'Duplicate',
  describe_for_rewards: '{{app_name}} offering you a special gift as a valued customer 🎉 ',
  did_you_miss_us_reactivate_your_subscription: 'Did you miss us? Reactivate your subscription ',
  // E
  enter_description: 'Enter a description...',
  example_total_cost_per_hour:
    'example: a {{reflectionType}} hour = <b>{{total}} {{currency}}</b> and the base is <b>{{basic}} {{currency}}</b> and your multiple = <b>{{multiple}}</b>, an overtime hour will cost <b>{{result}} {{currency}}</b>',
  example_total_cost_per_hour_basic:
    'example: a {{reflectionType}} hour = <b>{{basic}} {{currency}}</b> and your multiple = <b>{{multiple}}</b>, an overtime hour will cost <b>{{result}} {{currency}}</b>',
  edit_workflow: 'Edit Workflow',
  enable_half_day_leave: 'Enable half day leave',
  exit_re_entry_issuing_task: 'Exit re-entry issuing task',
  edit_excuse_policy: 'Update Excuse Policy',
  edit_overtime_policy: 'Update Overtime Policy',
  early_departure_deduct: 'Early out deduction',
  emergency_description: 'emergency_description',
  employee_form_number: 'Employee from number',
  update_cost_type: 'Update cost type',
  employees_selected: '{{number}} Employees Selected',
  employees_verification_summary: 'Employees Verification Summary',
  employees_without_email_note: 'There are {{count}} employee/employees without email.',
  edit_offline_processing: 'Edit Bank Processing',
  edit_mudad: 'Edit Mudad',
  enter_owner_authorizer_details: 'Enter Owner/Authorizer details',
  eos_calc_not:
    'This configuration sets up on which end of service reward calculation depends on: Full package, Basic salary or Basic + selected allowances',
  employees_authorizationa_summary: 'Employees Authorization Summary',
  employees_in_file_not_in_jisr: 'Employees in File not in {{app_name}}',
  employees_in_jisr_not_in_file: 'Employees in {{app_name}} not in File',
  employees_without_gosi_amount: 'Employees without GOSI amount',
  gosi_salary_differences: 'Gosi Salary Differences',
  geofencing_technology: 'Geofencing Technology',
  jisr_gosi_salary: '{{app_name}} Gosi Salary',
  file_gosi_salary: 'File Gosi Salary',
  employee_profile: 'Employee profile',
  employee_document_types: 'Employee document types',
  expense_types: 'Costs Types',
  employee_info_update_request: 'Employee info update request',
  expense_claim_request: 'Expense claim request',
  excuse_request: 'Excuse request',
  edit_asset_type: 'Edit Asset Type',
  exit_time: 'Exit time',
  extra_working_time: 'Extra Working Time',
  employee: 'Employee',
  employees: 'Employees',
  establishment_id: 'ESTABLISHMENT ID',
  edit_department: 'Edit Department',
  edit_job_title: 'Edit Job Title',
  edit_location: 'Edit Location',
  edit_deduction: 'Edit Deduction',
  employee_info_update_request_workflow: 'Employee info update request workflow',
  eligible_people: 'Eligible people',
  employment_document_types: 'Employee document types',
  employment_types: 'Employment types',
  employment_type_id: 'Employment types',
  edit: 'Edit',
  edit_area: 'Edit Area',
  expense_claim: 'Expense claim',
  expense_claim_desc: 'Compare Prices Find The Best Computer Accessory',
  ess_policy: 'ESS policy',
  export_government_files: 'Export Government Files',
  export_employees: 'Export employees',
  edit_employment_type: 'Edit employment type',
  edit_document_type: 'Edit document type',
  export_compensations: 'Export Compensation',
  export_leave_balance: 'Export leave balance',
  export_leave_balance_employees: 'Export leave balance employees',
  export_leave_requests: 'Export leave requests',
  export_family_details: 'Export family details',
  export_loans: 'Export loans',
  export_assets: 'Export assets',
  exemption_policy: 'Exemption From Deductions',
  employee_update_script_number: 'Employee update script number',
  employees_script_number: 'Employees script number',
  employee_read_script_number: 'Employee read script number',
  export_bupa_insurance: 'Export Bupa insurance',
  export_device_records: 'Import attendance logs via sheet',
  exit_reentry_payment_task: 'Exit Re-entry payment task',
  exit_reentry: 'Exit Re-entry',
  expense_claim_payment_task: 'Expense claim payment task',
  easy_clocking: 'Easy Clocking',
  edit_parent_department: 'Edit parent department',
  edit_custom_field: 'Edit custom field',
  export_style: 'Export Style',
  export_journal_to_excel_sheet: 'Export journal to excel sheet',
  export_shifts: 'Export shifts',
  employee_role: 'Employee',
  excuse_and_overtime: 'Excuse & Overtime',
  excuse_policy: 'Excuse Policy',
  enter_name: 'Enter name',
  enablement_hub: 'Enablement hub',
  excuse_polices: 'Excuse',
  employee_loans_policy: 'Employee loans policy',
  edit_custom_leave: 'Edit custom leave',
  enter_day_number: 'Enter day number',
  enter_days_number: 'Enter days number',
  edit_addition: 'Edit addition',
  update_grade: 'Update Grade',
  expense_claim_workflow: 'Expense Claim',
  exit_reentery_workflow: 'Exit Re-entery',
  excuse_workflow: 'Excuse',
  employees_not_assigned_to_leave: ' {{count}} employees do not have {{type}} leave workflow',
  employees_do_not_have_workflow: '{{count}} Employees do not have approval cycle',
  edit_profile_workflow: 'Info change',
  earning_difference_view: 'Earnings Differences View',
  earning_difference_option: 'Earnings Differences Options',
  edit_role: 'Edit role',
  edit_hourly_rate: 'Edit hourly rate',
  Employees: 'Employees',
  employees_weekly_emails: 'Employee email',
  edit_outsourcing_company: 'Edit outsourcing provider',
  edit_holiday: 'Edit holiday',
  edit_geolocation: 'Edit Geo-location',
  easy_clocking_integration: 'EasyClocking integration',
  enter_otp: 'Enter OTP',
  enabled_attendance_approval:
    'If it’s enabled, the attendance manager must approve the attendance, in order to process the payroll, vacation settlement, and final settlement.',
  eight_characters_as_minimum_characters: '8 characters as minimum characters',
  enable_calculating_the_whole:
    'Enable calculating the whole delay period in case the employee exceeded the delay policy',
  enable_to_set_selected: 'Enable this if you want to set the selected day/date as a Day Off',
  employees_should_clock_out: 'Employees should clock out no longer than <span>{{time}}</span>',
  enable_shift_to_repeat:
    'Enable this if you want this shift to repeat for a specified period of time',
  employees_assigned_template: 'Employees assigned to this template',
  employee_list: 'Employees list',
  effective_immediately: 'Effective immediately',
  effective_tomorrow: 'Effective tomorrow',
  effective_in_past: 'Effective in the past',
  edit_confirmation: 'Edit Confirmation',
  edit_shift: 'Edit Shift',
  edit_shift_combo: 'Edit Shift Combo',
  edit_fixed_hours_template: 'Edit Fixed Hours Template',
  extend_for_probation: 'Extend for Probation Period employees',
  export_csv: 'Export (csv)',
  earliest_start_time: 'Earliest Start Time',
  exceeds_shift_duration: 'Exceeds shift duration',
  excuse_polices_configurations: 'Excuse Polices configurations',
  edit_general_information: 'Edit General Information',
  edit_contact_information: 'Edit Contact Information',
  edit_destination: 'Edit Destination',
  edit_policy: 'Edit Policy',
  edit_document: 'Edit Document',
  exemption_policy_description:
    'Employees exempted from attendance deductions will not be affected by absence, lateness, shortage, and incomplete record. Attendance records are not affected by deduction exemption.',
  extend_for_probation_period_employees_tip:
    'Activate this option will extend the probation period for employees who are under probation with same holiday period. Note:This update will effect the next period and there is no affects on the pervioscalculations',
  edition: 'Edition',
  edit_open_api_key: 'Edit API Key',
  enabled: 'Enabled',
  edit_group: 'Edit Group',
  edit_fixed_working_hours_group: 'Edit Fixed Working Hours Group',
  enable_app: 'Enable {{name}}',
  edit_registration: 'Edit Registration',
  employees_on_this_shift: 'Employees on this shift',
  employees_will_be_able_to_clock_in_anytime:
    'Employees will be able to clock in anytime from <span>{{startTime}}</span> to <span>{{endTime}}</span>',
  employees_will_need_to_use_jisr_app:
    'Employees will need to use {{app_name}} app for better experience',
  employees_will_be_able_to_clock_in_any_time_of_calendar:
    'Employees will be able to clock in and out any time of a calendar day',
  employees_will_be_able_to_clock_in_any_time_during_specific_hours:
    'employees will only be able to clock in and out during Specific Hours within a day',
  ends_in_next_day: 'Ends in next day',
  expired_not_active_description: "The link you've clicked to reset your password has expired.",
  expired_contact_your_administrator_description:
    "The link you've clicked to reset your password has expired.<br /> Please contact your administrator.",
  excuses: 'Excuses',
  enter_multible: 'Enter multiple',
  employees_can_submit_overtime_request_only_if: 'Employees can submit overtime request only if',
  employees_this_policy: 'Employees in this policy',
  // F
  for_alinma_bank: 'For Alinma Bank, please refer to this guide',
  few_things_to_keep_in_mind: 'Few things to keep in mind',
  foodics: 'Foodics',
  faq: 'FAQ',
  food_per_diem: 'Food <br/><p>Per diem</p>',
  folder: 'Folder',
  fax_number: 'Fax number',
  file: 'File',
  file_selected: 'File selected',
  final_settlement_payment_processing: 'Attendance approval required for Final settlement',
  finish_wizard: 'Finish Wizard',
  family_details: 'Family Details',
  final_settlement_payment_task: 'Final settlement payment task',
  final_settlement_payment_task_message: 'Final settlement payment task <span>({{message}})</span>',
  final_settlement_processing_task: 'Final Settlement Processing Task',
  fingerprint_device_admin: 'Admin',
  fingerprint_device_manager: 'Manager',
  full_pacakage: 'Full Pacakage',
  family_included: 'Family included',
  fund_date: 'Fund Date',
  fund_date_form_note:
    'Your employee salaries will be transferred on the default fund date {{date}}. You can change the date if you want',
  footer: 'Footer',
  fixed_working_hours: 'Fixed Hours',
  flexibility: 'Flexibility',
  for: 'For',
  flexbility_duration: '<div><b>{{flexible_duration}}</b> flexbility</div>',
  fixed_template_name: 'Fixed Template Name',
  fixed_hours_employee: 'Fixed hours employee',
  field_name_ar: 'Field Name AR',
  field_name_en: 'Field Name EN',
  from_here: 'from here',
  foodics_cashier_users: 'Foodics Cashier Users',
  foodics_is_a_restaurant_management_system_description:
    'Foodics is a restaurant management system that manages sales, transactions, inventory, employee schedules, and customer relationships. Connect your account to get helpful insights about your employees',
  file_number: 'File number',
  flight_tickets: 'Flight tickets',
  final_settlement_payment: 'Final settlement payment',
  flexible_anytime_of_the_day: 'Flexible Anytime of the day',
  first_punch_in_and_last_punch_out_will_be_recorded_as:
    'First punch in and last punch out will be recorded as...',
  flexible_open_shift: '<span>{{openSiftType}}</span> open shift',
  follow_up_on_your_team_requests_and_approve_them_on_spot:
    'Follow up on your team requests and approve them on spot',
  for_examble: 'for example 2.5',
  flight_tickets_workflow: 'Flight Ticket',
  // G
  gosi_linked: 'Gosi info',
  mol_registrations: 'GOSI Subscriptions',
  grades: 'Grades',
  gosi_info: 'Gosi info',
  gosi_platform: 'GOSI - Aamaly',
  government_files: 'Government Files',
  group_by: 'Group By',
  gosi_subscription_no: 'GOSI Subscription No. - {{ num }}',
  gosi_subscription_number: 'GOSI Subscription No.',
  generating_the_advanced: 'Generating the advanced annual leave settlement date',
  google_map_search: 'Search for City, Place',
  gosi_number: 'GOSI No',
  gosi_number_LS: 'GOSI subscription number',
  gosi_integration_card_note:
    'Connecting your GOSI account will allow you to sync your employees  data and wages with your GOSI account directly from {{app_name}}',
  gosi_name_ar: 'GOSI Name Ar',
  gosi_name_en: 'GOSI Name En',
  green: 'Green',
  gosi_subscription: 'GOSI subscription',
  general_info: 'General Info',
  general_organization_for_social_insurance: 'General Organization for Social Insurance',
  general_organization_for_social_insurance_no: 'General Organization for Social Insurance No',
  geo_location: 'Geo-location',
  gosi_tasks: 'Gosi tasks',
  gosi_number_LS_example: 'Ex: 1234554645',
  greater_than_or_equal: 'Greater than or equal',
  // H
  how_to_pay: 'How to pay?',
  holiday_name_in_ar: 'Holiday name in Ar',
  holiday_name_in_en: 'Holiday name in En',
  hajj_description:
    'If an employee joins the Hajj, he is allowed 10 days of paid vacation. employee is required to finish two years with the current company before availing of this privilege.',
  holidays: 'Holidays',
  holiday_name: 'Holiday name',
  should_new_hire_have: 'Should new hire have their leaves prorated for the next year?',
  hiring: 'Hiring',
  hiring_request: 'Hiring request',
  hiring_requests: 'Hiring requests',
  how_it_works: 'How it works?',
  hiring_workflow: 'Hiring',
  have_you_changed_your_bank_account: 'Have you changed your bank account?',
  header: 'Header',
  holiday_description:
    'You can establish a fixed holidays period time for all employees in your company.',
  hourly_rate_polices: 'Hourly Rate Policies',
  hourly_rate_policy: 'Hourly Rate Policy',
  how_to_connect_netsuite_to_jisr: 'How to Connect NetSuite with {{app_name}}',
  how_to_connect_quickbooks_to: 'How to Connect QuickBooks with {{app}}',
  click_accounting: 'Click connect button',
  credentials_accounting_quickbooks: 'Enter your QuickBooks credentials',
  credentials_accounting_xero: 'Enter your Xero credentials',
  credentials_accounting_qoyod: 'Enter your Qoyod credentials',
  start_mapping_cost_center_accounting: 'Start mapping for Cost Centers & Sync employees',
  start_mapping_cost_center_accounting_for_qoyod: 'Start mapping for Cost Centers',
  configure_chart_of_accounts: 'Configure the Chart Of Accounts',
  ensure_cost_centers_step_accounting: 'Ensure that employees are assigned on cost centers',
  done_step_accounting: 'Done !',
  how_to_connect_xero_to: 'How to Connect Xero with {{app}}',
  how_to_connect_qoyod_to: 'How to Connect Qoyod with {{app}}',
  how_to_connect_microsoft_finops_to: 'How to Connect MS Dynamics Finance & Operation with {{app}}',
  how_to_connect_oracle_fusion_to: 'How to Connect Oracle Fusion with {{app}}',
  how_to_connect_zoho_books_to: 'How to Connect Zoho Books with {{app}}',
  how_to_connect_microsoft_to: 'How to Connect Microsoft with {{app}}',
  how_to_connect_odoo_to: 'How to Connect  Odoo with {{app}}',
  hourly_rate_policy_note:
    'Setting this policy as Default Policy will move all employees that currently assigned to the Current default policy.<br />  Moreover All Newly added employees will be assigned to this default  policy.',
  hourly_rate_policy_description:
    'The hourly rate policy helps you define how the system should calculate the overtime, delay and shortage for employees.Example: employee salary is 30,000 SAR, employee working hours is 10 hours. The hourly rate would be: 100 SAR. (Salary/30/working hours). You need just to define the working hours and assign employees to the policy.',
  hourly_rate_description:
    'Define how you calculate the cost of each hour for overtime, lateness, and shortage for attendance reflection.<br /><br /> Example: Employee salary is 10,000 SAR, employee daily working hours are 8 hours. The hourly rate would be: 41.67 SAR. (Salary/30days/working hours). ',
  working_hours_day: 'Working Hours/Day',
  Hiring_Date_Diffrences_description:
    'Joining Date affects the calculation of the first salary for new joiners, by addition when they join after Cut-off date, and by deduction when they join before it.',
  hour_rate: 'Hour rate',
  hourly_rate: 'Hourly rate',
  working_hour_rate: 'Working Hour Rate',
  Hr: 'Hr',
  hour: 'hrs',
  hours_high_to_low: 'Hours: High to Low',
  hours_low_to_high: 'Hours: Low to High',
  hours_per_day_open_shift: '{{time}}/Per Day',
  here_will_be_the_permissions:
    'Here will be the permissions that users will be granted when given this role, along with the <span>basic permissions</span> that all employees have',
  how_to_get_this_info: 'How to get this info?',
  hours_per_day: 'Hours/Per Day',
  hours_per_month: '{{numberOfHours}} hours per month',
  hourly_rate_mutiple: 'Hourly rate multiple',

  // I
  invoice_status: 'Invoice Status',
  included_in_shift_duration: 'Included in shift duration',
  imported_successfuly: 'Imported Successfully',
  import_failed: 'Import Failed',
  integratable_cost_centers: 'Integratable cost center',
  integration: 'Integration',
  is_active: 'Is active',
  integration_with_gosi: 'Integration with GOSI - Aamaly',
  is_addition: 'Is addition',
  integratable_cost_center_mapping: 'Integratable cost center mapping',
  integratable_manual_addition_types: 'Integratable manual addition types',
  integratable_manual_deduction_types: 'Integratable manual deduction types',
  integratable_manual_benefit_types: 'Integratable manual benefit types',
  integratable_loan_types: 'Integratable loan types',
  is_applicable: 'Is applicable',
  invoice_no: 'Invoice No',
  issuing_ticket_task: 'Ticket issuing task',
  issuing_exit_reentry_task: 'Exit re-entry issuing task',
  is_double_overtime_applicable: 'Is double overtime applicable :',
  info_alert: 'Info alert',
  information: 'Information',
  identification_number: 'Identification Number',
  is_automatic_resumption_applicable: 'Is automatic resumption applicable',
  includes_emp_name: 'Includes Emp Name',
  includes_payment_type: 'Includes Payment Type',
  invalid_records: '<strong>{{count}}</strong> record(s) are invalid',
  import_bulk_shifts: 'Import bulk shifts',
  is_resumption_applicable: 'Is resumption applicable',
  is_the_requested_excuse: 'Is the requested personal excuse hours monthly limited?',
  is_the_requested_overtime: 'Is the requested overtime hours monthly limited?',
  is_shortage_deduction_applicable: 'Is early departure deduction applicable?',
  import_en: 'import EN',
  import_ar: 'import AR',
  it_works_with_all_banks: 'It works with all banks',
  inma_bank_payroll_payment: 'Inma Bank Payroll Payment',
  request_to_authorize: 'Request to Authorize',
  i_agree_to_authorize_mudad:
    'I agree to authorize Mudad Co to send payroll instruction to my bank account',
  i_agree: 'I Agree',
  in_payroll: 'In-payroll',
  in_payrun: 'In-payroll',
  invoice_details: 'Invoice details',
  integration_select_employee: 'Select Employee',
  integration_disconnect: 'Disconnect',
  integration_connect: 'Connect',
  include_expire_date: 'This document must have an expiry date',
  included: 'Included',
  in_which_tab_do_you_want_the_input_field_display:
    'In which tab do you want the input field display',
  icon: 'Icon',
  installed_by: 'Installed By',
  inactive_by_user: 'Inactive by User',
  invitation_link_expired: 'Invitation Link Expired',
  invitation_link_expired_description:
    'The link has expired. Please request a fresh link to continue',
  i_understand_that_by_proceeding_description:
    'I understand that by proceeding, I will lose access to {{app_name}} account, and all of its content including my employees access and their data, and the history of my account since joining',

  // J
  jisr_systems_for_communicatons_and: '{{app_name}} SYSTEMS FOR COMMUNICATONS AND',
  jisr_systems_for_communicatons_and_reverse:
    'شركة {{app_name}} النظم للاتصالات وتقنية المعلومات شركه شخص واحد',
  journal_create_script_number: 'Journal create script number',
  job_titles: 'Job Titles',
  journal_structure: 'Journal Structure',
  jisr: 'Jisr',
  // K
  key_name: 'Key Name',
  key: 'Key',
  keep_subscription: 'Keep Subscription',
  // L
  labor_law: 'Labor Law Leaves',
  learn_more_overtime: 'Learn more',
  limit_in_numbers: 'Limit in numbers',
  labor_law_desc:
    'Saudi labor law leaves are enabled By default to ensure your organization is complied with labor law.You can Also set and adjust leaves depending on your organization’s policy.',
  labor_law_leave_validation: 'Can’t be less more the minimum , based on Labor Law',
  link: 'URL link',
  location_policies: 'Locations',
  list_options: 'List options',
  last_connected: 'Last connected',
  last_disconnected: 'Last disconnected',
  last_import: 'Last import',
  late_arrival_deduct: 'Lateness deduction',
  log_out: 'Log out',
  leave_management: 'Leave management',
  LEAVES_HOLIDAYS: 'Leaves & Holidays',
  loan_types: 'Loan Types',
  loan_types_management: 'Loan Types',
  letters_management: 'Letters management',
  letter_templates: 'Letter templates',
  letter_template: 'Letter template',
  loan_request: 'Loan request',
  letter_request: 'Letter request',
  leave_request: 'Leave Request',
  location_details: 'Location details',
  leave_type: 'Leave type',
  location_name: 'Location name',
  loan_policy: 'Loan Policy',
  location_name_ar: 'Location name Arabic',
  linked_bank_account: 'Payroll Bank',
  // letter_templates: 'Letter templates', // Duplicate key
  loan_type_title: 'What is the maximum loan amount can employee request?',
  leave_balances: 'Leave Balances',
  leave_cancellation: 'Leave cancellation',
  leave_requests: 'Leave requests',
  login_bar_text: 'No need ring for service. Please visit our Knowledge Base Center',
  login_authentication: 'Login Authentication',
  login_two_factor_auth: 'Login Authentication',
  login_two_factor_auth_desc:
    'It’s an extra layer of security Turning it on would require admins to provide a code sent on their email in order to login.',
  leave_related_tasks: 'Leave-related tasks',
  loan_payment_task: 'Loan payment task',
  leave_resumption_description:
    'If you enable the leave resumption feature, it will activate the need for each employee to apply for resumption to work for each leave, for more details please read the following article',
  limited_carry_balance_description: 'Allow the employee to transfer balance with a maximum limit',
  labour_law_default_leave_types: 'Special cases of leaves',
  leave_name: 'Leave name',
  leave_name_ar: 'Leave name Ar',
  leave_name_in_ar: 'اسم اجازة مخصصة بالعربي',
  leave_name_in_en: 'اسم اجازة مخصصة بالانجليزي',
  leave_workflow: 'Leave',
  loan_workflow: 'Loan',
  letter_workflow: 'Letter',
  letter_issuing_task: 'Letter issuing task',
  legal_structure: 'Legal Structure',

  late_more_min:
    'Late more than <span>{{allowed_delay}}</span> will be recorded as <span>Late In</span>',
  leaving_earlier_min:
    'Leaving earlier than <b>{{allowed_shortage}}</b> will be recorded as <b>Early Out</b>',
  leaving_more_recorded:
    'Late more than <span>{{allowed_delay}} min</span> will be recorded as <span>Late In</span>',
  leaving_earlier_recorded:
    'Leaving earlier than <span>{{allowed_shortage}}</span> will be recorded as <span>Earely Out</span>',
  learn_more_about_multiple: 'Learn more about multiple shifts',
  log: 'Log',
  leave_balance_settlement_fs: 'Leave balance settlement (Final Settlement)',
  leave_balance_settlement_note:
    'This configuration sets up on which leave settlement calculation in the final settlement depends on: Full package, Basic salary or Basic + selected allowances',
  login_failed_attempts_configuring: 'Login Failed Attempts Configuring',
  login_failed_attempts_configuring_description:
    'Locks account when 3 failed attempts happen within 5 minutes, the user will have to reset password to unlock the account.',
  login_attempt: 'Limit failed login attempts',
  login_attempt_note: 'Are you sure to apply limit failed login attempts in the selected options?',
  link_your_apps_account_with_jisr_system:
    'Connecting {{app_name}} with your {{apps}} account will authorize {{app_name}} to do the following in your behalf',
  line_manager_alt: 'Line Manager',
  legal: 'Legal',
  login_settings: 'Login Settings',
  letters: 'Letters',
  language: 'Language',
  learn_how_to_pay: 'Learn how to pay',
  limited_to: 'Limited to',
  locations_policy: 'Locations',

  // M
  method_payment: 'Method Payment',
  more_on_your_employees_shift: 'More on your employees shift',
  make_sure_employee_have_app:
    'Make sure your employees have {{app_name}} app notifications enabled',
  more_about_overtime_calculation: 'More about overtime calculation',
  moving_department: 'Moving Department',

  maximum_password_age_policy_configuration_description:
    'Users must change the passwords after a specific duration',
  minute_minutes: 'Minute/Minutes',
  missing_punch: 'Incomplete record',
  missing_punch_request: 'Punch Correction Request',
  MissingPunchRequest: 'Punch Correction Request',
  missing_punch_deduct: 'Incomplete record deduction',
  max_hours_weekdays: 'Max hour per weekdays',
  max_hours_non_weekdays: 'Maximum number of non weekdays',
  manager: 'Manager',
  manager_role: 'Manager',
  mol_id: 'MOL ID',
  missing_punch_configuration: 'Incomplete record Configuration',
  manual_addition_types: 'Manual addition types',
  manual_deduction_types: 'Manual deduction types',
  mol_info: 'MOL info',
  muqeem_info: 'Muqeem info',
  manage_integrate: 'Manage',
  my_data_only: 'My data only',
  my_subordinate: 'My team members',
  my_subordinate_plus_my_data: 'My team members + My data',
  minimum_days_warning: 'Annual leave days should not be less than {{days}}',
  multiplies_absence_days: 'Multiplies absence day(s)',
  is_missing_punch_deduction_applicable: 'Is Incomplete record deduction applicable?',
  manual_account_name: 'Manual account name',
  manual_account_number: 'Manual account number',
  maximum_loan_amount: 'Maximum number of salaries to be requested as a loan',
  maximum_loan_amount_from_basic__full: 'Maximum loan amount from basic/full package',
  maximum_excuse_hours_month: 'Maximum excuse hours in a month',
  maximum_overtime_Hours: 'Maximum overtime hours in a month',
  maximum_overtime: 'Maximum Overtime',
  maximum_password_age: 'Maximum Password Age',
  maximum_password_age_policy: 'Password expiry',
  maximum_password_age_policy_desc:
    'The Maximum password age policy determines the period of time (in days) that a password can be used before the system requires the user to change it.',
  maximum_password_age_save_note:
    'Are you sure to apply Maximum Password Age in the selected options?',
  maximum_password_age_reset_note:
    'Your previous password has been expired, please create a new password for continuing using {{app_name}}',
  marriage_description: 'Employees are given 3 days of paid marriage',
  maternity_description:
    'female workers are entitled to 10 weeks of leave to deliver a child: 4 weeks before delivery date and 6 weeks after.',
  monthly_limited_hours: 'Monthly limited hours',
  minimum_days_to_apply: 'Minimum days to apply annual leave settlement',
  minimum_password_length: 'Minimum Password Length',
  minimum_password_length_desc:
    'This policy enforces users to choose at least 8 characters to create a password.',
  minimum_annual_leave_entitlement_value:
    'Minimum annual leave entitlement value for an employee who completed 5 Years',
  minimum_annual_leave_entitlement_value_desc:
    'This will be the minimum entitlement value for employees who completed 5 Years.',
  minimum_annual_leave_entitlement_value_note:
    'Note: If there are some employees whose Annual entitlement is more than the predefined minimum value, the system will not auto-convert their Annual entitlement.',
  missing_punch_workflow: 'Correction Request',
  maximum_number_family_included: 'Maximum number of family members to be Included',
  must_be_less_than: 'Must be less than or equal to {{max}}',
  meter: 'Meter/Meters',
  mol_registration_number: 'MOL No.',
  gosi_owner_number: 'Owner / GOSI authorizer : {{number}}',
  gosi_owner_number_info: 'Owner / GOSI authorizer',
  payment_method_account_list: '{{name}} Bank account',
  add_bank_accounts: 'Add bank accounts',
  muded_has_been_installed_successfully: 'Muded has been installed successfully',
  you_are_about_to_delete_mudad_establishment: 'You are about to delete mudad establishment',
  establishment_deleted_successfully: 'Establishment deleted successfully',
  You_will_not_be_able_to_pay_your_employee_with_the_bank_file:
    'You will not be able to pay your employee with the bank file',
  proceed_to_payment: 'Proceed to Payment',
  now_you_can_go_to_payment_and_and_pay_with_one_click_through_muded:
    'Now you can go to payment and and pay with one click through Muded',
  Now_you_can_pay_your_employees_directly_from_jisr:
    'Now you can pay your employees directly from Jisr',
  add_bank_account: 'Add bank account',
  add_another_bank_account: 'Add Another Bank Account',
  mol_name_en: 'MOL Name En',
  mol_name_ar: 'MOL Name Ar',
  mudad_verification: 'Mudad verification',
  mudad_origanization_not_enalbled_msg:
    'Mudad Is not enabled for your account currently, please contact your Customer Success Manager or the support team to enable it and complete the registration, Thank You for Your Understanding.',
  mudad_step1_success_msg: 'The estabhlishment has successfully been registered. Please proceed.',
  mudad_step1_alert_msg: 'You’re about to register establishment for the {{rc_name}} on Mudad.',
  mudad_processing: 'Mudad Processing',
  mudad_step_1_check_out: 'Check out',
  mudad_step_1_legal_structure: 'Legal Structure',
  mudad_step_1_billingـhistory: 'Billing History',
  mudad_step_1_to_fix_the_issues_and_retry_again: 'to fix the issues and retry again',
  due_to_payment_lateness_for_issued_invoices:
    "Due to payment lateness for issued invoices. You can't define the Payment method as Mudad. Kindly, pay your invoices",
  you_cant_add_or_activate_mudad:
    "You can't add or activate Mudad as a Payment method due to late payment. please settle your due amount.",
  mudad_step4_no_worries: 'You can select a',
  mudad_step4_different_bank: 'Different Bank',
  mudad_step2_no_data_msg: 'You don’t seem to have any verified employees.',
  change_payment_method_to_manual_payment: 'Change payment method to manual payment',
  change_payment_method_to_mudad: 'Change payment method to Mudad',
  mudad_step2_otp_sent_note:
    'We’ve sent an <b>OTP</b> to <b>{{name}}</b> Please enter the OTP in the below field.',
  mudad_step3_otp_sent_note:
    'We’ve sent an <b>OTP</b> to <b> {{name}} </b> mobile number ending with <b> {{number}} </b>. Please enter the OTP in the below field to complete the authorization.',
  mudad_step3_no_data_note:
    ' You don’t seem to have any authorized employees. <br /> Select a verified employee to send authorization request.',
  mudad_card_discription: 'Automated and hassel free. <br /> It’s linked with the Banks and WPS.',
  managers_count: 'Devices Admins • {{count}}',
  mudad: 'Mudad',
  edit_authorization: 'Edit Authorization',
  delete_authorization: 'Delete Authorization',
  i_gree_on_terms_conditions: 'I Agree on terms and Conditions',
  mudad_is_configured_with: 'Mudad is configured with',
  bank_account_can_not_be_deleted: 'Bank account can not be deleted',
  setup_offline: 'Setup Offline',
  mudad_has_successfully_been_activated_and_configured_with:
    'Mudad has successfully been activated and configured with',
  mudad_step2_owner_msg:
    'The owner with Identification Number {{ number }} has successfully been verified.',
  Min: 'Min',
  min_plural: 'mins',
  mint: 'mins',
  modifications: 'Modifications',
  multi_select: 'Multi-select',
  multi_line_text: 'Text',
  mol_no: 'MOL No',
  cr_mol_no: 'MOL No',
  mol_number: 'Org no. in Ministry of Labour',
  multi_select_list: 'Multi-Selection List',
  mapping_employees: 'Mapping Employees ({{ number }})',
  maximum_value: 'Maximum value is {{max}}',
  managers: 'Managers',
  maximum_logo_size: 'Maximum logo size is 100 MB',
  ministry_of_labor: 'Ministry of Labor',
  ministry_of_labor_no: 'Ministry of Labor No',
  ministry_of_labor_info: 'Ministry of Labor (HRSD)',
  move: 'Move',
  moving_employees: 'Moving Employees',
  manage_bank_account: 'Manage Bank Account',
  move_employees_to_different_department:
    'Move this department’s employees to a different department in order to delete it',
  mol_unified_number_for_all_sub_branches_notify:
    "This is the organization's unified number in the Ministry of Labour for the main organization and its branches",
  main_organization: 'Main Organization',
  mol_prefix_of_legal_structure: 'Prefix',
  main_org: 'Main Org',
  ministry_of_commerce_info: 'Ministry of Commerce (MC)',
  move_to_another_registration: 'Move to another registration',
  matches: 'Matches',

  // N
  new_bank_account: 'New Bank Account',
  new_iban: 'New IBAN',
  name_ar_cant_be_blank: "name ar can't be blank",
  name_en_cant_be_blank: "name in english can't be blank",
  new_overall_rating: 'New Overall Rating',
  notify_employees_about_their:
    'Notify the organization employees about their coworkers work anniversary',
  new_competencies_rating: 'New Competencies Rating',
  new_normal_review_overall_rating: 'New Normal Review Overall Rating',
  new_probation_review_overall_rating: 'New Probation Review Overall Rating',
  new_department: 'New Department',
  new_area: 'New Area',
  netsuite_base_url: 'Netsuite base url',
  netsuite: 'Netsuite',
  new_cost_type: 'New cost type',
  journal_form_number: 'Journal form number',
  no_attendance: 'No attendance',
  no_attendance_navigation: 'No attendance',
  number_of_days: 'Number of days',
  number_of_employees_in_current_dep: 'Number of Employees',
  number: 'Number',
  no: 'No',
  netsuit_login_details: 'Login details',
  no_attendance_configuration: 'No attendance configuration',
  no_dont_want: "No. I Don't Want",
  none: 'None',
  next_billing_at: 'Next billing at: <b>{{ date }}</b>',
  next_billing: 'Next billing at <b>{{ date }}</b>',
  new_joining_notification: 'Enable New Joining Notifications',
  new_shift_type: 'New shift type',
  name_your_policy: 'Name your policy',
  new_excuse_policy: 'New excuse policy',
  name_in_ar: 'Name in Ar',
  name_in_english: 'Name in English',
  new_addition: 'New Addition',
  new_deduction: 'New Deduction',
  new_allowances: 'New Allowance',
  new_loan: 'New loan',
  new_grade: 'New Grade',
  new_records_add: '<strong>{{count}}</strong> new record(s) been added',
  name_first_last: 'Name: <b>{{nameFirst}} {{nameLast}}</b>',
  new_workflow: 'New Workflow',
  new_step: 'New Workflow Step',
  name_your_shift: 'Name your shift',
  new_workflow_step: 'New Workflow Step',
  normal_exit_time: 'Normal exit time',
  new_role: 'New Role',
  new_policy: 'New Policy',
  new_admin: 'New Admin',
  new_hourly_rate: 'New Hourly rate',
  new_holidays: 'New Holidays',
  no_users_have_been_assigned: 'No users have been asigned to this role yet',
  no_manager_assigned: 'No manager assigned',
  mins: 'Mins',
  main_menu: 'Main Menu',
  notify_people: 'Notify people',
  no_not_yet: 'No, not yet',
  number_of_hours:
    'The number of hours employees can come late (<b>{{flexible_duration}}</b>) is the number of hours they can stay late',
  new_shift: 'New Shift',
  name_in_arabic_optional: 'Name in Arabic (optional)',
  new_shift_combo: 'New Shift Combo',
  new_fixed_hours_template: 'New Fixed Hours',
  noon: 'Noon',
  no_bank_accounts: 'No bank accounts',
  not_working: 'Not working',
  not_paid: 'Not Paid',
  not_allowed_clock_in_before: 'Not Allowed clock in before',
  netsuite_is_connected_to_jisr: 'Netsuite account is now connected',
  quickbooks_is_connected_to_jisr: 'Quickbooks account is now connected',
  xero_is_connected_to_jisr: 'Xero account is now connected',
  qoyod_is_connected_to_jisr: 'Qoyod account is now connected',
  microsoft_is_connected_to_jisr: 'Microsoft account is now connected',
  microsoft_finops_is_connected_to_jisr: 'MS Dynamics Finance & Operation account is now connected',
  oracle_fusion_is_connected_to_jisr: 'Oracle Fusion account is now connected',
  zoho_books_is_connected_to_jisr: 'Zoho Books account is now connected',
  sap_is_connected_to_jisr: 'Sap Business One account is now connected',
  odoo_is_connected_to_jisr: 'Odoo account is now connected',
  connected_app_note: 'Now you can start setting up the integration',
  qoyod_connected_app_note: 'Now you can start setting up the integration',
  not_allowed_clock_in_after: 'Not Allowed clock in after',
  not_allowed_clock_out_before: 'Not Allowed clock out before',
  not_allowed_clock_out_after: 'Not Allowed clock out after',
  process__payment: 'Process & Payment',
  no_earlier_than: 'No earlier than',
  new_joining_employee_reflection: 'Hiring Date Differences',
  netsuite_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Netsuite account. <a>Read this article to know more details about Syncing employee information.</a>',
  xero_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Xero account. <span>Read this article to know more details about Syncing employee information.</span>',
  quickbooks_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Quickbooks account. <a>Read this article to know more details about Syncing employee information.</a>',
  microsoft_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Microsoft account. <a>Read this article to know more details about Syncing employee information.</a>',
  odoo_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Odoo account. <a>Read this article to know more details about Syncing employee information.</a>',
  sap_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Sap Business One account. <a>Read this article to know more details about Syncing employee information.</a>',
  microsoft_finops_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in MS Dynamics Finance & Operation account. <a>Read this article to know more details about Syncing employee information.</a>',
  oracle_fusion_sync_employees_note:
    'Activating Employees Sync means that {{app_name}} will map the employees IDs based on the selected employee dimension at Oracle Fusion.</a>',
  zoho_books_sync_employees_note:
    'Activating Employees Sync means {{app_name}} will create and update employees information such as (Name, Email...etc) in Zoho Books account. <a>Read this article to know more details about Syncing employee information.</a>',
  no_earlier_than_out: 'No earlier than',
  no_later_than: 'No later than',
  netsuite_help_note: 'IF you need any help please <span>Contact us</span>',
  netsuite_info_note:
    'Note: It is recommended to have someone from your IT team to add the required configuration in NetSuite.',
  no_later_than_out: 'No later than',
  new_changes_were_made: 'New changes were made on this role',
  new_shift_preset: 'New Shift Preset',
  new_template: 'New Template',
  new_fixed_working_hours: 'New Fixed Hours',
  new_fixed_working_hours_template: 'New Fixed Hours',
  new_company_documents: 'New Company Documents',
  new_outsourcing_provider: 'New Outsourcing Provider',
  new_parent_department: 'New Parent Department',
  new_locations: 'New Locations',
  new_location: 'New Location',
  new_job_titles: 'New Job Titles',
  new_overtime_policy: 'New Overtime Policy',
  new_overtime: 'New Overtime Policy',
  new_custom: 'New Custom',
  new_assets: 'New Assets',
  new_loan_types: 'New  Loan types',
  no_billing_history: 'No Billing History',
  no_devices_registered: 'No Devices Registered',
  not_included: 'Not Included',
  new_departments: 'New Departments',
  new_payment_method: 'New payment method',
  no_result_for: 'No Results for',
  new_asset_type: 'New Asset Type',
  no_data_available: 'No data available',
  new_api_key: 'New API Key',
  new_group: 'New Group',
  new_fixed_working_hours_group: 'New Fixed Working Hours Group',
  new_open_shift: 'Create New Open Shift',
  number_of_employees: 'Number of employees',
  employee_number: '{{number}} Employee',
  new_subsidiary: 'New subsidiary',
  new_business_unit: 'New business unit',
  new_org_registration: 'New registration',
  new_org_location: 'New location',
  new_org_department: 'New department',
  no_assigned_manager: 'No assigned manager',
  notify_the_organization_employees:
    'Notify the organization employees about their coworkers birthday, etc...',
  // O
  organization_located_countries: 'Organization located Countries',
  overtime_number_of_overtime_hours:
    'Overtime hour =  <b>current settings hour</b> + [(overtime multiple - 1) x  (basic salary hour)]',
  oauth_consumer_key: 'OAuth consumer key',
  overtime_settings: 'Overtime settings',
  oauth_consumer_secret: 'OAuth consumer secret',
  oauth_token: 'OAuth token',
  oauth_token_secret: 'OAuth token secret',
  oauth_realm: 'OAuth realm',
  organization_settings: 'Organization settings',
  organization_profile: 'Organization profile',
  other_leave_configuartion: 'Other leave configuration',
  overtime: 'Overtime',
  overtime_ta: 'Overtime',
  overtime_request: 'Overtime request',
  overtime_policy: 'Overtime Policy',
  overtime_calculation: 'Overtime calculation',
  organization_information_en: 'Organization information EN',
  organization_information_ar: ' بيانات المنشأة بالعربي',
  organization_name: 'Organization Name',
  organization_mediafiles: 'Organization mediafiles',
  organization_logo: 'Organization logo',
  organization_registration_optinal: 'Organization Registration',
  organization_registration_optinal_description:
    'The employee will be included in the payment file for the select registration',
  org_departments: 'Departments',
  org_department: 'Department',
  of_basic_salary: 'of basic salary',
  an_otp_has_been_sent:
    'An OTP has been sent to your registered email, please enter the OTP below:',
  owner_mudad_step2_otp_sent_note:
    'We’ve sent an OTP <b>to the person with NID ({{ number }})</b> Please enter the OTP in the below field.',
  owner_verification: 'Owner Verification',
  owner_form_note:
    'Please put the Identification Number for the the owner or the authorizer for the establishment within GOSI',
  other_leave_types_configuration: 'other leave types configuration',
  options: 'Options',
  overtime_calculated_based_on: 'Overtime calculated based on',
  other_leave: 'Other leave',
  overtime_hour_rate_can_employee_apply_for: 'Overtime hour rate can employee apply for:',
  overlapping_vacation_salary_reflection: 'Paid Annual Leave Differences',
  overtime_polices: 'Overtime polices',
  overtime_workflow: 'Overtime',
  outsourcing_company: 'Outsourcing Provider',
  out_sourcing_companies: 'Outsourcing Providers',
  out_of_payroll: 'Out-payroll',
  out_of_payrun: 'Out-payroll',
  overtime_deduct: 'Overtime addition',
  overtime_paid_to_employee: 'Overtime Paid to Employee?',
  violation_calculation: 'Violation deduction',
  offline_processing: 'Bank Processing',
  offline_card_discription:
    'Process payments manullay <br /> Download the bank file and upload it to your bank.',
  offline_supported_info_card: '<b>{{name}}</b> — {{iban}}',
  offline_form_config_msg:
    'You don’t seem to have any authorized person. Please choose a person to fully configure offline payment processing.',
  otp_sent: 'OTP Sent',
  otp_valid_until: 'Your current <b>OTP</b> is valid for only <b>{{time}}</b> minutes.',
  overtime_paid_to_employee_hint:
    'Yes: The overtime will be paid in-payroll, No: The overtime will be out-of-payroll (Outsourcing Employees Report).',
  x1_overtime_hour_rate: 'First hour paid in full package, half an hour paid in Basic',
  x2_overtime_hour_rate: 'First hour paid in full package, and second hour paid in basic',
  offline_processing_has_been_configured_with:
    'Offline Processing has been successfully configured with',
  offline_processing_configured_with: 'Offline Processing Configured with',
  online_accounting_software_for_your_business_description:
    'online accounting software for your business connects you to your bank, accountant, bookkeeper, and other business apps.',
  restaurant_management_software: 'Restaurant management software',
  online_accounting_software: 'online accounting software',
  add_from_shift_presets: 'Add from shift presets',
  overlap: 'Overlap',
  orange: 'Orange',
  Or: 'OR',
  own: 'Own',
  only_managers_with_employees_can_be_assigned:
    'Only managers with employees or divisions under their management can be assigned to this role',
  only_managers_with_employees_will_be_listed:
    'Only managers with employees or divisions under their management will be listed',
  other_assigned_roles: 'Other assigned roles',
  only_people_with_permission_payment_processing:
    'Only people with permission to View Payment Processing are listed',
  ORGANIZATION: 'Organization',
  org__structure: 'Org.Structure',
  outsourcing_providers: 'Outsourcing Providers',
  overtime_configurations: 'Overtime Polices configurations',
  open_shifts: 'Open Shifts',
  open_shift: 'open shift',
  once_you_cancel_your_subscription: 'Once you cancel your subscription:',
  one_of_our_most_experienced_employees_discription:
    'One of our most experienced employees in our team will urgently contact you to assist in resolving your issue.',
  only_allow_requests_for_days_with_atleast_2_fingerprints:
    'Only allow requests for days with at least 2 fingerprints',
  only_allow_requests_for_days_with_required_hours_worked:
    'Only allow requests for days with required hours worked',
  // P
  passwords__login: 'Passwords & Login',
  passwords__login_description:
    'Control security preferences for logins, authentication, and passwords.',
  department_title_in_english: 'Department Title  in English',
  department_title_in_arabic: 'Department Title  in Arabic',
  pay_type: 'Pay Types',
  payment_setup: 'Payment Process',
  payment_info: 'Payment Info',
  payment_information: 'Payment Information',
  new_pay_type: 'New Pay Type',
  parent_departments: 'Parent Departments',
  parentdepartment: 'Parent Department',
  payment_due: 'Payment due',
  paid: 'Paid',
  partially_updated: 'Partially Updated',
  processing_data: 'Processing Data',
  payroll_management: 'Payroll management',
  ping_script_number: 'Ping script number',
  penalty: 'Penalty',
  policy_name: 'Policy name',
  policy_warning: 'Save the Policy first to edit!',
  po_box: 'P.O box',
  plan_active_employees: 'Active employees:  <b> {{ count }} </b>',
  parent_department_name: 'Parent Department Name in English',
  parent_department_name_ar: 'Parent Department name in Arabic',
  permissions: 'Permissions',
  permission_change_warning:
    'On changing View Permission correspondingly other Permissions will be changed',
  persmission_level: 'Persmission Level',
  previous_year_balance: 'Previous year balance',
  payroll_elements_mapping: 'Payroll elements mapping',
  payroll_element: 'Payroll Element',
  progressing: 'Progressing...',
  processed_transactions: 'Processed Transactions',
  payroll_calculation_configuration: 'Payroll calculation configuration',
  payroll_payment_task: 'Payroll payment task',
  payroll_payment_task_message: 'Payroll payment task <span>({{message}})</span>',
  paternity_description:
    'Paternity leave: For Male employees who have a new child born, he will be entitled for 3 days ',
  per_year: 'Per year',
  per_contract: 'Per contract',
  paid_annual_leave_diff_description:
    'Annual leaves (when paid in advance) affects the calculation of salary by addition when remaining salary payment is postponed until the employee returns, or by deduction for the previously paid days.',
  pay_mode: 'Pay mode',
  payroll_workflow: 'Payroll Review',
  final_settlement_workflow: 'Final Settlement Review',
  vacation_settlement_workflow: 'Vacation settlement Review',
  parent_department_in_english: 'Parent department in English',
  parent_department_in_arabic: 'Parent department in Arabic',
  payment_id: 'Payment ID',
  payment_tracking: 'Payroll Payment Tracking',
  payment_status: 'Payment status',
  payment_two_factor_auth: 'Payment Processing Authentication',
  payment_two_factor_auth_desc:
    'Turning This on would require the user to provide a code sent on the email in order to Submit the Payroll Transfer To the Bank.',
  permission_level: 'Persmission Level',
  per_day: 'Per day',
  payroll_elements: 'Payroll Elements',
  per_night: 'Per night',
  per_day_description:
    'Per Day: the calculation for accommodation will be as same as requested days',
  per_night_description:
    'Per night: the calculation for accommodation will be less then requested days by one day',
  sending_payslip_enabled: 'Send payslip to employees via Email',
  payment_configuration: 'Payment Configuration',
  payroll_payment_configuration: 'Payroll Payment Configuration',
  preview: 'Preview',
  payables: 'Payables',
  payroll_item: 'Payroll Item',
  paid_amount: 'Paid amount',
  start_setup_payment_method: 'Start Setup Payment Method',
  process_payment_page_header:
    'Process Payment <span class="payroll-month">• {{date}} Payroll</span>',
  process_payment_page_header_low_tires: '<span class="payroll-month"> {{date}} Pay Run</span>',
  process_payment_page_sub_header_low_tires:
    '<span class="payroll-month"> {{date1}} Payroll</span>',
  process_setup: 'Payroll Process',
  password_policy: 'Password Policy',
  password_policy_save_note:
    'All employee accounts will be signed out, and they have to reset their passwords again to reach a new password policy. Kindly, ensure all employees have defined emails to receive a reset password email.',
  password_complexity_requirement: 'Password Complexity Requirement',
  password_complexity_requirement_desc:
    'Password can’t contain first name, last name, or email address of the user. Passwords must use at least three of the four available character types lowercase letters, uppercase letters, numbers, and symbols',
  passwords_must_meet_complexity_requirements: 'Passwords must meet complexity requirements',
  punching_in_not_needed: 'Punching in is not needed as the system will do it automatically',
  punching_out_not_needed: 'Punching out is not needed as the system will do it automatically',
  punch_time: 'Punch Time',
  print: 'Print',
  pm: 'PM',
  publish_changes: 'Publish changes',
  pink: 'Pink',
  purple: 'Purple',
  performance_management: 'Performance Management',
  public_holidays_setting_description:
    'These are holidays that will occur for all employees in your organization at the same period.',
  password_reusing_limit: 'Password reusing limit',
  password_reusing_limit_desc:
    'Users will not be allowed to change the password to a previously used one, you can set the number of passwords that can not be reused.gain.',
  password_reusing_limit_note: 'Are you sure to apply Password reusing in the selected options?',
  passwords: 'passwords',
  phone_no: 'Phone No',
  postal_zip_code: 'Postal/Zip code',
  payment_methods_setup_warning_note:
    'If you confirmed the payroll for this month, any changes will be applied on the next month',
  policy_configurations: 'Policy configurations',
  policies: 'Policies',
  Please_make_sure_you_are_description:
    'First make sure that {{app_name}} was installed at your Foodics account and you are logged-in in order to proceed with the connection',
  permission: 'Permission',
  punches_out_of_this_window:
    'Punches out of this window will be flagged as <span>‘Out of window’</span> and can be corrected manually or through approval cycle.',
  parent_dept: 'Parent Dept',
  permissions_summary: 'Permissions Summary',
  payroll_review: 'Payroll review',
  PERFORMANCE: 'PERFORMANCE',
  payroll_payment: 'Payroll payment',
  parent_department_optional: 'Parent Department (optional)',
  process_payments_directly_with_bank: 'Direct payroll transfer by bank integration',
  paying_bills_at_jisr: 'Paying bills at {{app_name}}',
  paying_bills_at_jisr_desc: 'How to pay bills at {{app_name}} step by step',
  please_take_a_moment_to_let_us_know_description:
    'Please take a moment to let us know why you are canceling. Your feedback is greatly appreciated.',
  password_reset_link_expired: 'Password Reset Link Expired',
  password_reset_link_expired_description:
    "The link you've clicked to reset your password has expired..<br /> Please request a fresh link to continue.",
  // Q
  quickbooks: 'Quickbooks',
  quickbooks_sync_accounts: 'Sync with Quickbooks Accounts',
  quickbooks_sync_trackings: 'Sync with Quickbooks Trackings',
  quickbooks_account_code: 'Quickbooks Account',
  quickbooks_sync_please: 'Sync with Quickbooks please',
  quickbooks_export: 'Export to Quickbooks',
  quick_info_to_be_considered: 'Quick info to be considered',
  please_enter_shift_name_in_english: 'Please enter the shift name in English',
  please_enter_shift_name_in_arabic: 'Please enter the shift name in Arabic',
  per: 'Per',

  // R
  getting_users_basic_information: 'Getting {{apps}} users basic information',
  reminders_are_now_available_as_push_notifications:
    'Reminders are now available as push notifications to {{app_name}} app so make sure your employees have the latest version of the app installed',
  reminders_will_be_sent_to_all_employees:
    'Reminders will be sent to all employees except those who are assigned to open shifts as it’s not available to it yet',
  rating_methods: 'Rating Methods',
  remind_employees_to_punch: 'Remind employees to punch in case they didn’t yet',
  remind_employees_on_the_selected_shift_to_out:
    'Remind employees on the selected shift(s) to punch out in case they didn’t yet',
  remind_employees_on_the_selected_shift_to_in:
    'Remind employees on the selected shift(s) to punch in case they didn’t yet',
  record_count: 'Record count',
  remind_employees_to_log_their_punches: 'Remind employees to log their punches',
  request_workflow: 'Request WorkFlow',
  roles: 'Roles',
  retro_appraisal_diff_description:
    'Edited salaries affects the calculation of salary by addition or deduction when salary edit is recorded as retro edit.',
  roles_and_permissions: 'Roles and permissions',
  registration_number: 'Registration number',
  registration_expiry_date: 'Registration expiry date',
  registration_name: 'Registration name',
  role_type: 'Role type',
  resend_new_otp: 'Resend New OTP',
  resend_otp: 'Resend OTP',
  resumption: 'Resumption',
  resend_after: 'Resend After {{min}}:{{sec}}',
  resumption_configuration: 'Resumption configuration',
  resignation_workflow: 'Resignation',
  register_establishment: 'Register Establishment',
  retro_appraisal_reflection: 'Edited Salary Retroactive Differences',
  re_verify: 'Re-verify',
  reminder_sent: 'Reminder Sent',
  re_authorize: 'Re-Authorize',
  request_authorization: 'Request Authorization',
  repeat_shift: 'Repeat Shift',
  repeat: 'Repeat',
  rules: 'Rules',
  recently_created: 'Recently Created',
  removed_from_shift: 'Removed <span>{{employee}}</span> from this shift template',
  removing_unassign:
    'Removing employees will unassign them from being fixed hours employees, meaning you’ll need to manage their shifts from Scheduler. If you’re planning to re-assign them to other fixed hours template, it’s better to assign them straight from the other template. <span>Click here to undo removing</span>',
  remove_shift: 'Remove shift',
  red: 'Red',
  reset_default: 'Reset default',
  reset: 'Reset',
  remove_all_permissions: 'Remove all permissions',
  remove_user_from_role: 'Remove user from role',
  remove_user_from_role_named: 'Remove {{user}} from {{role}}',
  remove_all_premissions_from_user: 'Remove all permissions from {{user}}',
  removing_all_permissions:
    'Removing all permission from this user will limit thier access to thier own information only.',
  removing_user_from_this:
    'Removing user from this will limit thier access to other roles permissions.',
  roles_alt: 'Roles',
  remove_photo: 'Remove photo',
  request_another_invitation: 'Request another invitation',
  requests_employee_alert_message:
    'You can see all the employees and create approval cycle to them',
  renew_the_subscription: 'Renew the subscription',
  registered_organizations: 'Registered Organizations',
  register_organization: 'Register Organization',
  reactivate_your_subscription: 'Reactivate your subscription',
  reactivate_your_subscription_description:
    'Reactivate your subscription, and everything will return to the way it was before cancellation, and your employees can immediately resume using {{app_name}}',
  recalculate: 'Recalculate',
  // S
  select_cr: 'Select CR',
  select_the_system: 'Select the system',
  select_how_you_want_to_send_push:
    'Select how you want to send push notifications to remind employees to log their punches',
  show_guide: 'Show Guide',
  select_how_you_want_to_send_push_notifications:
    'Select how you want to send push notifications to remind employees to log their punches',
  send_reminders_the_organization_employees:
    'Send reminders the organization employees through {{app_name}} mobile app, to remind them to punch in and out. for less correction requests',
  start_calculating_after_specific_duration: 'Start Calculating After Specific Duration?',
  start_counting_and_auto_approve:
    'Start counting and <span> auto-approve up to {{overtime}} </span> only after <span> employee has completed {{overtimeHr}}</span> {{state}} the shift’s end <br /> <span>Example</span> : if the shift should end by {{endTime}} and the overtime is for {{overtime}}, no overtime less than {{overtimeHr}} would be calculated.',
  start_counting_and_auto_approve_before_shift_end_time:
    'Start counting and <span>auto-approve up to {{overtime}} </span> only after the employee has stayed at least <span> {{overtimeHr}}</span> between <span>{{startOvertime}}</span> and <span>{{endTime}}</span>',
  start_counting_and_auto_approve_up_to:
    'Start counting and auto-approve up to <span> {{overtimeHr}} </span> only after employee has completed <span> {{workingHoursShift}} working hours </span>',
  salary_earning_view_option:
    'Earnings view options allows you to select the view of the values that affects calculation of Earnings in Payroll Table.',
  separated: 'Separated Value',
  splitted: 'Merged Value',
  separated_new_hiring_desc: 'Show additional column for Hiring Date differences.',
  splitted_merge_desc: 'Merge differences in Earnings remarks.',
  separated_vacation_desc: 'Show additional column for Leaves Salary differences.',
  separated_retro_desc: 'Show additional column for Edited Salaries differences.',
  setup_payment: 'Setup Payment',
  saved_iban: 'Saved IBAN',
  setup_payment_method: 'Setup payment method',
  select_employees: 'Select employees',
  show_only_added_employees: 'Show only added employees',
  select_mudad: 'Select Mudad',
  register_to_mudad: 'Register to Mudad',
  manual_payment: 'Manual Payment',
  save_close: 'Save & Close',
  have_an_issues_contact_support: 'Have an issue, Contact Support',
  review_org_structure: 'Review Org. Structure',
  authorizer_verification: 'Authorizer Verification',
  verify_payment_admin: 'Verify Payment Admin',
  authorize_payment_admins: 'Authorize Payment Admins',
  setup_mudad: 'Setup Mudad',
  register: 'Register',
  owner_national_id_iqama: 'Owner National ID / Iqama',
  we_will_send_otp_please_enter_here:
    'We send an OTP to number that ends with {{ number }}, Please enter the OTP here',
  please_enter_otp: 'Please enter the OTP here',
  make_sure_contact_ur_org_owner_note:
    'Make sure to contact your organization GOSI authorizer / Owner, if you are not the authorizer',
  commercial_registration_name: 'Commercial Registration Name',
  commercial_registration_name_ar: 'Commercial Registration Name in Arabic',
  commercial_registration_name_en: 'Commercial Registration Name in English',
  owner_success_note:
    'The owner with Identification Number {{ number }} has successfully been verified.',
  we_will_send_otp_verify_owner_note:
    'We will send an OTP to verify your organization owner or the authorizer person for the establishment within GOSI',
  make_sure_establishment_note:
    'Make sure that your commercial registration and ministry of labor are correct. if the your organization structure is wrong you won’t be able to activate Mudad',
  change_to_manual_payment: 'Change to Manual Payment',
  mudad_subscription: 'Mudad Subscription',
  delete_establishment: 'Delete Establishment',
  change_to_mudad: 'Change to Mudad',
  before_add_mudad: 'Before add Mudad',
  important_notes_before_setup_mudad: 'Important notes before setup Mudad',
  select_the_employee_that_authorized:
    'Select the employee who is authorized to process salary payments through your bank',
  verification_with_the_responsible: 'Verification with the responsible of the payment process',
  make_sure_that_your_bank_account_is_linked_to_your_commercial:
    'Make sure that your bank account is linked to your commercial registration',
  Make_sure_to_contact_your_organization_owner_GOSI:
    'Make sure to contact your organization owner and GOSI authorizer we will send a mobile OTP to them.',
  mol_unified_no: 'Mol Unified No',
  make_sure_to_contact_your_organization_note:
    'Make sure to contact your organization owner and GOSI authorizer we will send an mobile OTP to them',
  We_will_send_you_invoice:
    "We will send you an invoice with banking details to pay for the subscription nad you'll need to pay for the subscription within the next 30 days to avoid disablingMudad subscription",
  that_is_you_have_your_company_legal_structure_in_Jisr_is_correct:
    'That is you have your company legal structure in Jisr is correct',
  you_ll_need_to_pay_for_the_subscription:
    'You’ll need to pay for the subscription within the next 30 days to avoid disablingMudad subscription, <span>we will send you an invoice with banking details to pay for the subscription</span>',
  select_offline: 'Select Offline',
  your_subscription_will_start_after_completing_registration:
    'Your subscription will start after completing registration!',
  search_department: 'Search Department',
  edit_legal_structure: 'Edit Legal Structure',
  revoke_mudad: 'Revoke Mudad',
  authorize_other_employee: 'Authorize Other Employee',
  select_the_employee_that_authorized_to_process_salary_payments:
    'Select the employee who authorized to process salary payments through your bank',
  authorize_with_the_responsible_of_the_payment_process:
    'Authorize with the responsible of the payment process',
  learn_more: 'Learn More',
  make_sure_that_is_the_persons_are_authorized:
    'Make sure that the employee has a valid authorization on the bank account (A Bank visit maybe required to update the authorization).',
  an_otp_code_will_be_sent_note:
    'An OTP code will be sent to his/her mobile number registered with the bank of the establishment, to the authorized bank account',
  the_establishment_with_Mudad_has_been_registered_successfully:
    'The establishment with Mudad has been registered successfully. Please proceed.',
  your_salaries_bank: 'Your Salaries Banks',
  authorized_employee: 'Authorized Employee',
  make_sure_that_is_the_information:
    'Make sure that is the information of your legal structure are correct',
  select_parent_department: 'Select Parent Department',
  mudad_authorized_employee: 'Authorized employee',
  please_wait: 'Please Wait ....',
  verify_another_employee: 'Verify another Employee',
  make_sure_that_the_authorized_person_in_the_bank_has_jisr_account:
    'Make sure that the person chosen in this step has an account in Absher and on the Jisr system, and that his ID number in his profile on Jisr matches the information in Absher',
  select_area: 'Select Area',
  get_fresh_list: 'Get fresh list',
  same_location: 'Same Location',
  same_location_desc: 'Employees will be transferred to the same location',
  same_department: 'Same Department',
  same_department_desc: 'department will be move the same department',
  select_department: 'Select Department',
  select_city: 'Select City',
  security: 'Security',
  SECURITY: 'SECURITY',
  see_details: 'See details',
  subsidaries_mapping: 'Subsidaries mapping',
  subsidaries: 'Subsidaries',
  subsidaries_updated_successfully: 'Subsidaries updated successfully',
  support: 'Support',
  subsidiaries_script_number: 'Subsidiaries script number',
  subscription_details: 'Subscription details',
  sponsors: 'Gosi Company',
  notification_setting: 'Notification Settings',
  notifications: 'Notifications',
  shift_name: 'Shift name',
  shift_type: 'Shift type',
  shift_starting_time: 'Shift starting time',
  shift_ending_time: 'Shift Ending time',
  split_time: 'Split time',
  second_shift_start_time: 'Second shift start time',
  second_shift_exit_time: 'Second shift exit time',
  shift_punch_time: 'Shift punch time',
  start_time: 'Start time',
  subscription_start_date: 'Subscription start date: <b>{{ date }}</b>',
  start_date_of_subscription: 'Subscription start date <b>{{ date }}</b>',
  subscription_end_date: 'Subscription end date: <b>{{ date }}</b>',
  end_date_of_subscription: 'Subscription end date <b>{{ date }}</b>',
  status_on_off: 'Status (on/off)',
  sponsor: 'Sponsor',
  second_shift: 'Second shift',
  subscription_status: 'Subscription status',
  shift_exit_time: 'Shift exit time',
  select_your_group: 'Select your group',
  set_delay_allowed_time: 'Set Late Arrival Allowed Time',
  send_to_bank: 'Send To Bank',
  sync_accounts_with_xero: 'Sync accounts with xero',
  sync_trackings_with_xero: 'Sync trackings with xero',
  sync_accounts_with_xero_please: 'sync accounts with xero please',
  sync_trackings_with_xero_please: 'sync trackings with xero please',
  show_employees: 'Show Employees',
  section: 'Section',
  save_settings: 'Save Settings',
  show_decimal_points: 'Show decimal points?',
  shift_has_been_deleted: 'Shift has been deleted.',
  setup_your_shift: 'Setup your shift',
  shift_name_ar: 'shift name Ar',
  supported_banks: 'Supported Banks',
  submission_date: 'Submission Date',
  selected_bank_account_mudad:
    'The selected bank account will be used to process the payroll payment.',
  shift_note:
    '"Shifts which end at/after 9 pm or shifts which start at this range: 12 AM - 1:30 AM"',
  shifts__working_hours: 'Shifts & Working Hours',
  show_xero_bill: 'Show xero bill',
  single_overtime: 'Single (1.5x)',
  single_select_list: 'Single-Selection List',
  single_line_text: 'Single Line Text',
  suspend_selected_employee: 'Suspend {{name}}',
  emp_name_will_not_receive_this_month_payroll: '{{name}} will not receive this month payroll',
  single_overtime_description:
    'For Single overtime the calculation will be: First hour paid in full package, half an hour paid in Basic.',
  brand_name: 'Brand name',
  sync_data: 'Sync data',
  select_your_working_days: 'Select your working days',
  setup_your_policy: 'Setup your policy',
  setup_the_policy: 'Setup the policy',
  sync_salary_update_with_gosi: 'Update salary in GOSI',
  study_description:
    'An employee shall, upon the approval of the employer to enroll in an education institute or to continue within such institution, have the right to a fully paid leave to sit for an examination of an unrepeated year, the leave duration shall be based on the days of actual examination.',
  sick_description:
    'An employee whose illness has been proven is allowed to take four months sick leave from employer * First 30 days- Fully paid * Next 60 days – 3/4 of total wages* Next 30 days – Unpaid',
  suspended_employees: 'Suspended Employees',
  special_cases_of_leaves: 'Special cases of leaves',
  set_as_default: 'Set as Default',
  show_shift_name: 'Show shift name',
  search_shifts: 'Search shifts',
  automatic_upload_file_to_wps: 'Automatic upload file to WPS',
  salaries_transfer_with_one_clicks: 'Salaries transfer with one click',
  search_and_select_employees: 'Search and select employees',
  Some_selected_has_conflict: 'Some of the selected employees has a conflict with this shift',
  save_this_shift: 'Save this shift as a template',
  save_draft: 'Save Draft',
  save_publish: 'Save & Publish',
  st_shift: '{{count}}st shift',
  search_shift_templates: 'Search shift templates',
  shift_combo_name: 'Shift Combo Name',
  search_shift_presets: 'Shift name or start time',
  shift_templates: 'Shift Templates',
  search_fixed_shift_templates: 'Search fixed hours groups',
  set_up_desired_weekly:
    'Set up the desired weekly formation of shifts and off days. You can assign up to 2 shifts per day.',
  select_employees_to_assign: 'Select employees that you want to assign to these fixed hours.',
  select_to_change_reflect:
    'Select this for changes to reflect on past records. This will recalculate attendance records for affected employees starting from the selected date.',
  select_time_effect: 'Please select the time you want this to take effect',
  system_will_record_as_clock_in:
    'When enabled, the system will automatically record <span>{{time}}</span> as the clock-in time',
  system_will_record_as_clock_out:
    'When enabled, the system will automatically record <span>{{time}}</span> as the clock-out time',
  session_timeout: 'Session Time-out',
  session_timeout_note: 'Are you sure to apply session time-out in the selected options?',
  subscription: 'Subscription',
  supported_formats_is_xls_xlsx_csv: 'Supported file format is: .xls .xlsx .csv',
  subscription_detail: 'Subscription detail',
  sort_by: 'SORT BY',
  sync_employees_information_to_netsuite: 'Sync Employees Information to Netsuite',
  sync_employees_information_to_quickbooks: 'Sync Employees Information to Quickbooks',
  sync_employees_information_to_microsoft: 'Sync Employees Information to Microsoft',
  sync_employees_information_to_microsoft_finops:
    'Sync Employees Information to MS Dynamics Finance & Operation',
  sync_employees_information_to_oracle_fusion: 'Sync Employee dimension to Oracle Fusion',
  sync_employees_information_to_zoho_books: 'Sync Employees Information to Zoho Books',
  sync_employees_information_to_odoo: 'Sync Employees Information to Odoo',
  sync_employees_information_to_sap: 'Sync Employees Information to Sap Business One',
  sync_employees_information_to_xero: 'Sync Employees Information to Xero',
  sort_by_name: 'Sort by name',
  search_employees: 'Search employees',
  smart_simple_online_accounting_software_for_small_business:
    'Smart, simple online accounting software for small business. Connect your Quickbooks account & automatically send Payroll transactions to Intuit',
  shift_presets: 'Shift Presets',
  sheet_approval: 'Sheet Approval',
  search_users: 'Search users',
  select_manager: 'Select manager',
  sub_departments: 'departements',
  settings_file_based: 'File based',
  settings_company_documents: 'Company Documents',
  settings_leave_types: 'Leave Types',
  status_will_update_after_minutes:
    'Update needs at least 30 minutes after sending payroll to bank',
  search_open_api: 'Search',
  select_atleast_one: 'Select at least one of APIs',
  step_one: '1. Visit “Settings/Billing History”',
  step_one_desc: 'You will find all of your invoices',
  step_two: '2.  Click on “Download invoice” for the selected ones',
  step_two_desc: 'An invoice might have more than one subscription',
  step_three: '3.  Find {{app_name}} banking details',
  step_three_dec:
    'Scroll down to the latest part of the invoice to find IBAN and {{app_name}} banking details',
  step_four: '4. Pay your bills (Banking Transfer)',
  step_four_desc: 'Confirm your transaction and keep your transaction ID',
  subscription_fees: 'Subscription fees',
  subscriptionـstart: 'Your subscription will start after completing registration!',
  subscriptionـstart_description:
    'You will need to pay for the subscription within the next 30 days to avoid disabling Mudad subscription',
  subscriptionـstart_description_link:
    'we will send you an invoice with bank details to pay for the subscription',
  subscription_value: '{{price}} SAR/Year',
  saudi_currency: '񥀠',
  cr_see_less: 'See less',
  no_sub_organizations: 'No sub-organizations',
  see_sub_organization: 'View Sub-Organizations ({{sub_length}})',
  sub_organization: 'Sub-organization',
  sub_org: 'Sub Org',
  select_the_main_organization: 'Select the main organization of the branch',
  search_open_shifts: 'Search Open Shifts',
  should_work: 'Should work:',
  select_clocking_method: 'Select clocking method to show more info',
  specific_hours_within_day: 'Specific Hours within a day',
  save_and_create: 'Save and Create',
  save_and_update: 'Save and Update',
  start_date_scheduler: 'Start date',
  subscription_cancellation: 'Subscription cancellation',
  subscription_cancellation_description:
    "You can cancel your subscription here if you no longer wish to use our services. We'll guide you through the process step-by-step and provide clear instructions on what will happen after you cancel. If you change your mind, you can always come back and deactivate the cancellation.",
  select_if_employees_should_fulfill_certain_terms_before_submitting_requests:
    'Select if employees should fulfill certain terms before submitting requests',
  select_location: 'Select Location',
  switch_to: 'Switch to {{organization}}',
  select_role: 'Select role',

  // T
  types_requests: 'Types',
  the_number_of_minimum_days:
    'The number of minimum days of annual leave where the employee will be allowed to request for advanced salary.',
  try_our_new_settings_interface: 'Try our new settings interface  👀',
  the_apps_will_be_available_soon: 'The apps will be available soon',
  there_error_link_description: 'There’s an error with the link. Please check your email.',
  this_country_is_empty: 'This Country is empty, if you delete it',
  this_area_is_empty: 'This Area is empty, if you delete it',
  this_location_is_empty: 'This Location is empty, if you delete it',
  this_sub_department_have_already_employees: 'Are you sure you want to delete',
  this_department_have_already_employees:
    'You can delete this parent department after deleting all departments added to it.',
  this_subdepartment_is_empty: 'Are you sure you want to delete',
  this_department_is_empty: 'Are you sure you want to delete',
  this_country_have_already_employees:
    'This Country have already employees you can not deleted, first assign these employees then delete',
  this_area_have_already_employees:
    'This Area have already employees you can not deleted, first assign these employees then delete',
  this_location_have_already_employees:
    'This Location have already employees you can not deleted, first assign these employees then delete',
  task_name: 'Task name',
  travel_days: 'Travel Days',
  to_automate_your_bills_for_alimna_bank_clients: 'To automate your bills for Alimna Bank clients',
  transportation_per_diem: 'Transportation <p>Per diem</p>',
  tracking_mapping: 'Tracking mapping',
  template_name: 'Template name',
  template_name_ar: 'Template name in Arabic',
  to_whom_the_task_of_payment_should_go_to: 'To whom the task of payment should go to:',
  ticket_payment_task: 'Ticket payment task',
  to_whom_the_task_of_issuing_the_ticket_should_go_to:
    'To whom the task of Issuing the Ticket should go to:',
  transaction_type: 'Transaction type',
  tracking_id: 'Tracking id',
  tip_cut_off_date:
    'The cut off day is the last date that all transactions should be submitted in order to get them included in the payroll Setting the cut off day helps you run the payroll in a perfect manner',
  tip_fund_date:
    'Fund date is the date an employee receives pay from the employer. The date that amounts deposited into employee’s accounts',
  tip_decimal_points_included:
    'If YES the system would round the amount of the Net salary for employees',
  tip_time_sheet_reflection_payroll: 'You can control the reflection of time related transaction',
  to_whom_the_task_of_issuing_exit_reentry_should_go_to:
    'To whom the task of Issuing Exit Re-entry should go to:',
  tasks_management: 'Tasks management',
  time_sheet_reflection: 'Time sheet reflection',
  This_device_is_not_linked_to_alocation: 'This device is not linked to a location',
  time_sheet_reflection_payroll: 'Attendance reflection in payroll',
  toggling_between_location: 'Toggling between location department destroys the existing policies',
  type_workflow_name: 'Type Workflow Name',
  total_headcount: 'Total Headcount',
  total_payments: 'Total Payments',
  total_number_of_emp_including_sub_dep: 'Total number including sub-departments',
  ticket_provision_policy: 'Ticket provision policy',
  ticket_cost: 'Ticket Cost',
  tickets_configuration: 'Tickets configuration',
  ticket_paid: 'Ticket paid',
  terms_and_conditions: 'Terms And Conditions',
  template_name_en: 'Template name in English',
  two_factor_auth: 'Two-factor Authentication',
  token_key: 'Token ID',
  token_secret: 'Token Secret',
  two_factor_auth_for_login: 'Two-factor authentication for logging in',
  two_factor_auth_save_note:
    'Are you sure to apply two-factor authentication in the selected options?',
  templates: 'Templates',
  total_duration: 'Total duration',
  Total_working_duartion: 'Total scheduled duration',
  total_unpaid_break: 'Total unpaid Break',
  template_name_in_english: 'Template Name in English',
  template_name_in_arabic: 'Template Name in Arabic (Optional)',
  template_name_in_english_fixed: 'Fixed Hours Name (English)',
  template_name_in_arabic_fixed: 'Fixed Hours Name (Arabic)',
  tow_nd_shift: '2nd Shift',
  tue: 'Tue',
  this_place_where_you_can_manage_your_variable_Pay_types:
    'This is the place where you can manage your variable Pay Types',
  session_timeout_allowed_duration: 'Allowed duration',
  time_session_out_configuration: 'Session Time out Configuration',
  time_session_out_configuration_description:
    'Users will be logged out after being inactive, you can set the allowed duration of inactivity.',
  tab_name: 'Tab Name',
  this_cost_item_should_be_mapped: 'NetSuite {{ item }} should be mapped',
  this_cost_item_should_be_mapped_quickbooks: 'QuickBooks {{ item }} should be mapped',
  this_cost_item_should_be_mapped_xero: 'Xero {{ item }} should be mapped',
  this_cost_item_should_be_mapped_qoyod: 'Qoyod {{ item }} should be mapped',
  this_cost_item_should_be_mapped_odoo: 'Odoo {{ item }} should be mapped',
  this_cost_item_should_be_mapped_microsoft: 'Microsoft {{ item }} should be mapped',
  this_cost_item_should_be_mapped_microsoft_finops:
    'MS Dynamics Finance & Operation {{ item }} should be mapped',
  this_cost_item_should_be_mapped_oracle_fusion: 'Oracle fusion {{ item }} should be mapped',
  this_cost_item_should_be_mapped_zoho_books: 'Zoho books {{ item }} should be mapped',
  this_cost_item_should_be_mapped_sap: 'Sap Busines One {{ item }} should be mapped',
  terminate_employees: 'Terminate employees',
  the_value_cannot_be_less_than_one: 'The value cannot be less than one',
  the_value_cannot_be_more_than_24: 'The value cannot be more than 24',
  update_employees_information: 'Update employees information',
  the_cloud_business_software_suite_description:
    'Post and Export the journal entries by connecting the Netsuite account with {{app}} directly',
  microsoft_card_description:
    'Post and Export the journal entries by connecting the Microsoft account with {{app}} directly',
  microsoft_finops_card_description:
    'Post and Export the journal entries by connecting the MS Dynamics Finance & Operation account with {{app_name}} directly',
  sap_business_card_description:
    'Post and Export the journal entries by connecting the SAP Business One account with {{app_name}} directly',
  zoho_books_card_description:
    'Post and Export the journal entries by connecting the Zoho Books account with {{app_name}} directly',
  quick_books_description:
    'Post and Export the journal entries by connecting the QuickBooks account with {{app}} directly',
  xero_description:
    'Post and Export the journal entries by connecting the Xero account with {{app}} directly',
  odoo_description:
    'Post and Export the journal entries by connecting the Odoo account with {{app}} directly',
  the_shift_looks_too_long: 'The shift looks too long',
  these_are_the_permissions:
    ' These are the permissions that users will be granted when given this role',
  this_role_only_has_basic_permissions:
    'This role has only the basic permissions which is granted to all employees for thier own information only.',
  these_are_the_basic_permissions: 'These are the basic permissions that are granted to all users',
  these_are_the_permissions_user:
    'These are the permissions that <span>{{name}}</span> has, it can contains diffrent permissions from multiple roles.',
  team: 'Team',
  the_first_punch_within_this_window:
    'The <span>first punch</span> within this window will be automatically recorded as a <span>Clock-in</span>',
  the_last_punch_within_this_window:
    'The <span>last punch</span> within this window will be automatically recorded as a <span>Clock-Out</span>',
  time_overlapping: 'Time overlapping',
  text: 'Text',
  department_has_subdepartments: 'This department has sub-departments',
  department_has_employees: 'This department has employees',
  tenant_id: 'Tenant ID',
  there_is_no_open_shifts: "There's no open shifts created yet",
  timeframe_anytime_between: 'Timeframe: Anytime between {{startTime}} - {{endTime}}',
  timeframe: 'Timeframe',
  the_first_punch_and_the_last_punch_will_be_recorded:
    'The first punch and the last punch will be recorded as the clocking in and out',
  take_me_back_to_my_account: 'Take me back to my account',
  title_for_congratulation: "We're glad our partnership continues! 🎉",
  title_for_SubscriptionCanceled:
    'By the end of your current subscription period, your subscription will be canceled',
  title_fot_you_can_return:
    'Having you leave is very sad for us. You can return to {{app}} whenever you like.',
  the_current_policy_allows: 'The current policy allows x{{overTimePolicy}}',
  this_employee_already_added: "This employee's already added with same role",
  to_notify_selected_employees_by_email: 'To also notify selected employees by email',
  to_notify_selected_admins_by_email: 'To also notify selected admins by email',

  // U
  unit: 'Unit',
  update_department: 'Update Department',
  update: 'Update',
  update_employees_wages: 'Update Employees Wages',
  update_business_policy_headding: 'Are you sure?',
  update_business_policy_description:
    'This change in business trip policy will effect in all pending Requests that related to the same policy',
  updated_records: 'Updated records',
  upload: 'Upload',
  update_device: 'Update device',
  unselect_all: 'Unselect all',
  unsubscribe: 'Unsubscribe',
  unsubscribe_weekly_email: 'Stop receiving weekly updates',
  unsubscribe_weekly_email_description:
    'If you confirm the unsubscribe you will not be able to receive the {{app_name}} weekly email.',
  upload_new_document: 'Upload new document',
  upload_file_xls_format_hnit:
    'Upload the same file format in (.xls) provided from {{app_name}} to avoid any errors while importing your data.',
  unpaid_calculated_based_on: 'Unpaid calculated based on',
  upto_date_balance: 'Upto date balance',
  upto_date_warning:
    'Changing Upto date balance will change the previous year balance. Do you want to proceed?',
  uptodate_balance_warning: 'Editing in Manual entry will effect on uptodate balance',
  users_on_this_device: '{{count}} users on this device',
  users_their_fingerprint_is_not_taken: '{{count}} users their fingerprint is not taken',
  unpaid_description:
    'A worker, subject to the employer’s approval, may obtain leave without pay for a durationto be agreed upon by the two parties. The work contract shall be deemed suspended for the duration of the leave in excess of twenty days',
  unchecked_benefits:
    'benefits has been removed, this will remove those benefits for all Ajeer employees of this outsourcing provider',
  you_have_not_any_geolocation_yet: 'You have not defined any geo-location yet.',
  you_have_geo_locations: 'You have  {{count}} geo-locations',
  unpaid_break_deducted: '<b>{{break_duration}}</b> unpaid break deducted from the total duration',
  unpaid_break: 'Unpaid break',
  update_combo: 'Update Combo',
  update_template: 'Update Template',
  updated_shifts: 'Updated shifts of <span>{{days}}<span>',
  unassign_employees: 'You’re about to unassign {{count}} employees',
  unavailablility: 'Unavailablility',
  unpublish: 'Unpublish',
  unpublished: 'Unpublished',
  unscheduled: 'Unscheduled',
  untill: 'Untill',
  unassigned: 'Unassigned',
  users: 'Users',
  netsuite_accounting: 'NetSuite Integration',
  quickbooks_accounting: 'QuickBooks Integration',
  xero_accounting: 'Xero Integration',
  qoyod_accounting: 'Qoyod Integration',
  offline_journal: 'File-based Integration',
  users_alt: 'Users',
  user_permissions: 'User Permissions',
  upload_photo: 'Upload photo',
  update_business_unit: 'Update Business Unit',
  update_commercial_registration: 'Update commercial registration',
  update_job_title: 'Update Job Title',
  update_outsourcing_provider: 'Update Outsourcing Provider',
  update_document_type: 'Update Employee Document Type',
  update_in_house: 'Update In-House',
  update_billing_details: 'Update billing Details',
  update_parent_department: 'Update Parent department',
  update_custom_field: 'Update Custom field',
  update_holiday: 'Update Holiday',
  update_loan_type: 'Update loan type',
  update_location: 'Update Location',
  uploading_file: 'Uploading File',
  update_labor_law_leave: 'Update Labor Law Leave',
  unified_mol_no: 'Unified MOL No.',
  update_open_shift: 'Update Open Shift',
  unlimited: 'Unlimited',
  // V
  value_per_hour_per_weekdays: 'Value per hour per weekdays',
  value_per_hours_of_non_weekdays: 'value per hours of non weekdays',
  vacation_salary_calculated_based_on: 'Vacation Salary calculated based on',
  vacation_calculated_based_on: 'Vacation calculated based on',
  vacation_settlement_payment_processing: 'Attendance approval required for Vacation settlement',
  vacation_settlement_processing_task: 'Vacation Settlement Processing Task',
  verify_employees: 'Verify Employees',
  verify: 'Verify',
  vacation_settlement_task: 'Vacation settlement Payment task <span>({{message}})</span>',
  payroll_task: 'Payroll payment task  <span>({{message}})</span>',
  final_settlement_task: 'Final settlement payment task <span>({{message}})</span>',
  view: 'View',
  voided: 'Voided',
  vacation_salary: 'Vacation salary',
  verification_failed: 'Verification Failed',
  verified_successfully: 'Verified Successfully',
  verified: 'Verified',
  variables: 'Variables',
  view_by: 'VIEW BY',
  vat_number: 'VAT Number',
  vacation_salary_calc_note:
    'This configuration sets up on which advance vacation salary calculation depends on: Full package, Basic salary or Basic + selected allowances',
  view_employee_list: 'View Employee List',
  vacation_settlement_payment: 'Vacation settlement payment',
  v2_documents: 'Documents',
  view_open_api_key: 'View API Key',
  visit_billing_history: 'Visit Billing History',
  view_org_chart: 'View Org. Chart',

  // W
  wait_5_min: 'Wait for other 5 minutes',
  weekly_offs: 'Weekly offs',
  with_expiry_date: 'Include expiry date',
  without_expiry_date: 'Not include expiry date',
  working_hours_and_shifts: 'Working Hours',
  working_days: 'Working days',
  working_hours: 'Working hours',
  website: 'Website',
  weekly_email_updates: 'Weekly Email Updates',
  my_notifications: 'My Notifications',
  why_single_overtime: 'Why single overtime ?',
  why_double_overtime: 'Why double overtime ?',
  want_to_apply: 'Do you want to apply this policy to all employees in company?',
  workflow_note: 'Please note, there are {{unassigned_users}} employees not assignedto any policy',
  workflow: 'WorkFlow',
  workflows: 'Workflows',
  WORKFLOW__APPROVALS: 'Requests & Approvals',
  We_are_working_with_banks_note:
    'We’re working with banks to make your life more easier, Alinma Bank Direct Online Processing is coming soon.',
  workflow_steps_warning:
    'If you change the workflow settings, all the pending requests will be resubmitted with the new workflow setup, and you have to approve all the steps again.',
  widow_description:
    'Widow leave for female workers whom their husband has passed away, if the female employee was muslim then she will be entitled of 4 months and 10 days, if she was not muslim then she will have only 15 days',
  what_is_the_maximum_loan: 'What is the maximum loan amount can employee request?',
  what_is_the_maximum_loan_tool_tip:
    'Define the number of salaries that the employee can request as a loan',
  working_hours_mins: 'Working hours: <span>{{totalWorkingHours}}</span>',
  total_scheduled_duration: 'Total scheduled duration: <span>{{working_hours}}</span>',
  weekly_shift_details: 'Week Schedule',
  weeks: 'Weeks',
  weekly: 'Weekly',
  workflow_payroll_message: 'Only people with permission to View Payroll Table are listed',
  workflow_vacation_message: 'Only people with permission to View Vacation Settlement are listed',
  workflow_final_message: 'Only people with permission to View Final Settlement are listed',
  workflow_name: 'Workflow name',
  will_generate_apiKey_onClick: 'Will be generated after clicking “Generate”',
  within_timeframe: 'Within Timeframe',
  when_this_feature_is_activated:
    'When this feature is activated, employees will be able to submit requests for half-day leave, and it will deduct from their annual leave balance.',
  we_would_like_to_hear_your_feedback: 'We would like to hear your feedback',
  we_value_our_partnership: 'We Value Our Partnership',
  we_have_received_your_request_successfully_description:
    'We have received your request successfully, expect a call soon.',
  worked_all_the_required_hours_of_the_assigned_shift:
    'Worked all the required hours of the assigned shift',
  // X
  xero: 'Xero',
  qoyod: 'Qoyod',
  xero_account_codes: 'Xero account codes',
  xero_export_option: 'Xero export option',
  xero_is_a_beautiful_easy_to_use_online_accounting_description:
    'Xero is a beautiful, easy-to-use online accounting software for small businesses and their advisors. Connect your Xero account & automatically sync Payroll transactions with Xero after each pay run',
  // Y
  You_cant_delete: 'You can’t delete',
  confirm_mudad_subscription_dec:
    'You are going to create a new subscription for Mudad service. Please confirm this by entering the following information',
  yes_cancel_payment: 'Yes. Cancel Payment',
  cr_numbers_validation: 'CR number does not match the registered number',
  mol_numbers_validation: 'Mol number does not match the registered number',
  you_dont_have_commercial_registration:
    'You don’t seem to have any Commercial Registrations (CRs) defined. Use to the button below to define one.',
  you_dont_have_commercial_registration_and_bank:
    'In order to add a bank account, or to choose a payment method, you need to define a CR (Commerical Registration) first. You don’t seem to have any CRs defined yet.',
  you_dont_have_bank_for_this_commercial_registration:
    'You don’t seem to have any bank accounts for {{name}}',
  you_always_revert_back_to_your_previous_notes:
    'You can always revert back to your previous method from the Payment Methods',
  yes_im_sure: "Yes i'm sure",
  you_have_not_add_bank_account_yet: 'You have not add bank account yet',
  you_are_about_to_change_the_payroll_bank: 'You are about to change the payroll bank',
  you_have_some_employees_their_salary_bank_not_specified:
    'You have some employees not linked to any Commercial Registration',
  you_need_add_shift: 'You need to add a 2nd shift to create a day template',
  you_can_assign_shifts: 'You can assign up to 2 shifts per day currently',
  you_can_assign_up_to_one_shift: 'You can assign up to 1 shift per day currently',
  yellow: 'Yellow',
  your_commercial_registration: 'Your commercial registration',
  your_plan_is: 'Your plan is: <b> {{ plan }} </b>',
  you_are_not_authorized_send_payment_transfer_bank:
    'You’re not authorized to send the payment transfer to the bank',
  you_can_not_change_company_name: 'You can’t change the company name',
  your_current_subscription_will_end_on: 'Your current subscription will end on {{time}}',
  yo_wont_be_able_to_track_shifts_and_schedules:
    "You won't be able to track schedules and employee Attendance",
  you_wont_be_able_to_access_your_payroll_history:
    "You won't be able to access your Payroll history",
  you_will_lose_access_to_all_your_employees_data:
    'You will lose access to all your employees data on {{app_name}}',
  you_are_going_to_activate_mudad:
    'You are going to activate Mudad for *{{crName}}* for another year, if you confirmed your payroll, the changes will apply on the next month, do you want to renew?',
  you_will_need_to_define_off_days:
    'You’ll need to define Off days while assign this shift to employees',
  // Z
  ZK_techo_attendance_integration: 'ZK techo attendance integration',
  zk_techo_attendance_integration: 'ZK techo attendance integration',
  zk_attendance_keys: 'ZK attendance keys',
  zk_attendance_login: 'ZK attendance login',
  refreshed_at: 'Refreshed at',
  refresh: 'Refresh',
  fingerprint_devices: 'Biometric Devices',
  devices_installed_in_total: 'You have {{count}} devices installed in total.',
  this_device_doesnt_have_any_admins: "This device doesn't have any admins",
  digits_24: '24 digits',
  since: 'from',
  refresh_status: 'Refresh Status',
  refresh_device_status: 'Refresh Device Status',
  updating_status: 'Updating Status',
  monthly_payroll_journal_entry: 'Monthly Payroll Journal Entry',
  monthly_payroll_journal: 'Monthly payroll journal',
  gosi_odoo_journal: 'GOSI journal',
  vacation_end_of_Service_accruals_journal_entry:
    'Vacation & End Of Service Accruals Journal Entry',
  gosi_accrual_journal_entry: 'GOSI Accrual Journal Entry',
  send_journal_entry_to_netSuite: 'Send journal entry to NetSuite',
  send_journal_entry_to_xero: 'Send journal entry to Xero',
  send_journal_entry_to_qoyod: 'Send journal entry to Qoyod',
  send_journal_entry_to_oracle_fusion: 'Send journal entry to Oracle Fusion',
  send_journal_entry_to_ms: 'Send journal entry to Microsoft',
  send_journal_entry_to_odoo: 'Send journal entry to Odoo',
  send_journal_entry_to_finops: 'Send journal entry to MS Dynamics Finance & Operation',
  send_journal_entry_to_ms_finops: 'Send journal entry to MS Dynamics Finance & Operation',
  send_journal_entry_to_zoho_books: 'Send journal entry to Zoho Books',
  send_journal_entry_to_quickBooks: 'Send journal entry to QuickBooks',
  send_journal_entry_to_sap: 'Send journal entry to Sap business One',
  Ajeer_Journal_odoo: 'Ajeer Journal',
  Out_Of_Payroll_Journal: 'Out Of Payroll Journal',
  Vacation_Settlement_Journal: 'Vacation Settlement Journal',
  Final_Settlement_Journal: 'Final Settlement Journal',
  you_cannot_delete_this_record: "You can't delete this record",
  you_cannot_delete_this_record_sub_message:
    'This registration is linked to an active payment method.<br/>This registration is linked to a payment method that already has paid transactions<br/><br/>You can archive the registration instead',
  archive_registration: 'Archive Registration',
  okay: 'Okay',
  delete_registration_record: 'Delete Registration',
  delete_registration_record_msg: "Deleted registrations can't be restored.",
  delete_record: 'Delete Registration',
  archive_registration_msg:
    'Archived registrations will not be available for use, but it will not cancel the related Mudad subscription if any',
  move_employees_title: 'You have {{count}} employee in "{{name}}"',
  move_employees_delete_msg: 'Move those employees to another registration to delete this record.',
  move_employees_archive_msg:
    'Move those employees to another registration to archive this record.',
  move_employees: 'Move Employees',
  donnot_have_needed_permissions_toMove_employees:
    "You don't have the needed permission to move the employees",
  archive_cr_with_subs_msg:
    "You can't archive this registration, because it has active sub-organizations, archive them first",
  success_archive_title: 'Archived Successfully',
  success_partial_employees_moved_title: '{{count1}} employees were moved successfully.',
  success_partial_employees_moved_msg:
    "But {{count2}} employees were not moved because you don't have permission to move them.",
  delete_cr_with_subs_msg:
    "You can't delete this registration, because it has active sub-organizations, delete them first",
  success_delete_registration_title: 'Deleted Successfully',
  success_delete_registration_msg: 'The organization registration was deleted successfully',
  archived_records: 'Archived Records',
}

export default Setting
