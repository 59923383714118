import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import { ReactComponent as PlusIcon } from 'assets/figma-icons/add.svg'
import I18n from 'translations/i18n'
import BoxWithSwitch from 'containers/public/Onboarding/components/BoxWithSwitch'
import {
  onboardingAnnualLeaveEntitlementsLoad,
  onboardingAnnualLeaveEntitlementDelete,
  onboardingAnnualLeaveEntitlementCreate,
  onboardingAnnualLeaveEntitlementUpdate,
} from 'redux/leavesOnboarding/annualLeaveEntitlements/actionCreators'
import { Confirm } from 'components/global/atoms'
import { Form, TextInputField } from 'components/final-form'

import styles from './styles.module.css'

type Values = {
  days_per_year: number | string
  id: number
}

type InputWithDeleteBtnTypes = {
  initialValues?: { days_per_year: number; id: number }
  autoFocus?: boolean
  handleDelete: () => void
  onSubmit: (vals: Values) => Promise<void>
}

const InputWithDeleteBtn = ({
  handleDelete,
  onSubmit,
  initialValues,
  autoFocus,
}: InputWithDeleteBtnTypes): JSX.Element => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
  >
    {({ handleSubmit }): JSX.Element => (
      <form onSubmit={handleSubmit}>
        <div className={styles.inputWithDeleteContainer}>
          <span
            className={styles.deleteBtn}
            onClick={handleDelete}
          >
            -
          </span>
          {/* @ts-expect-error Need to TextInputField.js to TypeScript */}
          <TextInputField
            autoFocus={autoFocus}
            type="number"
            name="days_per_year"
            inputStyle={{ width: '100%', textAlign: 'center' }}
          />
        </div>
      </form>
    )}
  </Form>
)

const AnnualLeaveEntitlement: FC<{
  addNew: boolean
  setAddNew: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
  const { addNew } = props

  const dispatch = useDispatch()
  const { employee_leave_days } = useSelector(
    ({ onboardingAnnualLeaveEntitlements }) => onboardingAnnualLeaveEntitlements,
  )

  useEffect(() => {
    dispatch(onboardingAnnualLeaveEntitlementsLoad())
  }, [])

  const handleOnSubmit = (values: Values): Promise<void> => {
    const action = values.id
      ? onboardingAnnualLeaveEntitlementUpdate(values.id, values)
      : onboardingAnnualLeaveEntitlementCreate(values)
    return dispatch(action).then(() => {
      dispatch(onboardingAnnualLeaveEntitlementsLoad())
      props.setAddNew(false)
    })
  }

  const handleDelete = (id: number): void => {
    const action = (): void => {
      dispatch(onboardingAnnualLeaveEntitlementDelete(id)).then(() => {
        dispatch(onboardingAnnualLeaveEntitlementsLoad())
      })
    }
    return Confirm({
      dangerMode: true,
      action,
    })
  }

  return (
    <BoxWithSwitch
      title={I18n.t('annual_leave_entitlement')}
      content={
        <>
          <Typography
            variant="interface/default/m"
            text={I18n.t('annual_leave_entitlement_onboarding_description')}
          />
          <Spacer height="16px" />
          <Flex
            flexWrap
            className={styles.leaveDaysContainer}
          >
            {employee_leave_days.map((day) => (
              <InputWithDeleteBtn
                key={day.id}
                initialValues={{ id: day.id, days_per_year: day.days_per_year }}
                handleDelete={(): void => handleDelete(day.id)}
                onSubmit={handleOnSubmit}
              />
            ))}
            {addNew && (
              <InputWithDeleteBtn
                handleDelete={(): void => props.setAddNew(false)}
                onSubmit={handleOnSubmit}
                autoFocus
              />
            )}
            {!addNew && (
              <Flex
                itemsCenter
                justifyCenter
                onClick={(): void => {
                  props.setAddNew(true)
                }}
                className={styles.addBtn}
              >
                <PlusIcon className={styles.addIcon} />
              </Flex>
            )}
          </Flex>
        </>
      }
    />
  )
}

export default AnnualLeaveEntitlement
