import { useEffect } from 'react'
import { Form, RadioBoxField } from 'components/final-form'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { updateOrganization } from 'redux/organizationOnborading/organization/actionCreators'
import { constantsLoad } from 'redux/organizationOnborading/constants/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import StepTemplate from '../../../components/StepTemplate'

const organizationType = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const onboardingConstants = useSelector((s) => s.onboardingConstants?.constants)
  const { organization_type } = useSelector((s) => s.organization?.organization)

  const onSubmit = (value: number): void => {
    dispatch(updateOrganization({ organization_type: value }))
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'organization_type' })

  useEffect(() => {
    dispatch(constantsLoad())
  }, [])
  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!organization_type}
    >
      <Typography
        variant="title-1"
        text={i18n.t('organization_type')}
      />
      <Typography
        variant="body-new/regular"
        text={i18n.t('Do_you_run_one')}
      />
      <Spacer height="16px" />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          organization_type,
        }}
        render={(): JSX.Element => (
          <Flex
            flexCol
            style={{ gap: 6 }}
          >
            {/* @ts-expect-error Need to migrate RadioBoxField.js to TypeScript */}
            <RadioBoxField
              options={onboardingConstants?.organization_type}
              name="organization_type"
              labelKey="name_i18n"
              valueKey="id"
              onChange={onSubmit}
            />
          </Flex>
        )}
      />
    </StepTemplate>
  )
}

export default organizationType
