import { useState } from 'react'
import { Typography, Spacer } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { isEmpty } from 'lodash'
import { required, number, composeValidators } from 'components/global/form/FormValidations'
import { useForm } from 'react-final-form'
import { SelectField } from 'components/final-form'
import RadioWithContent from 'containers/public/Onboarding/components/RadioWithContent'
import { useSelector } from 'utils/hooks'
import { CommercialRegistration } from 'containers/public/Onboarding/types/onboardingTypes'
import { OrgType } from './helper'

type MolRegistrationProps = {
  selectedCR: CommercialRegistration
}

const MolRegistration = (props: MolRegistrationProps): JSX.Element => {
  const { selectedCR } = props
  const { commercial_registrations } = useSelector(
    ({ commercialRegistrationsOnborading }) => commercialRegistrationsOnborading,
  )

  const [orgType, setOrgType] = useState<OrgType>(
    !selectedCR?.main_commercial_registration ? 'main_id' : 'sub_id',
  )

  const { change } = useForm()

  const options = [
    {
      label: i18n.t('main_organization'),
      value: 'main_id',
    },
    {
      label: i18n.t('sub_organization'),
      withChildren: true,
      value: 'sub_id',
    },
  ]
  const canEdit = commercial_registrations?.find(
    (it) => it?.main_commercial_registration?.id === selectedCR.id,
  )

  const handleSelectOrgType = (val: OrgType): void => {
    change('main_id', '')
    setOrgType(val)
  }

  const getCommercialRegistrations = (): CommercialRegistration[] => {
    return commercial_registrations.reduce(
      (CRs: CommercialRegistration[], currentCR): CommercialRegistration[] => {
        if (currentCR.id !== selectedCR.id && isEmpty(currentCR.main_commercial_registration))
          CRs.push({
            ...currentCR,
            name: `${currentCR.name} - ${currentCR.registration_number}`,
          })
        return CRs
      },
      [],
    )
  }

  return (
    <div style={{ width: '63.5%' }}>
      <Spacer height={32} />
      <Typography
        variant="interface/default/sm"
        text={i18n.t('organization_type')}
      />
      <Spacer height={8} />
      <>
        {/* @ts-expect-error Need to migrate RadioWithContent.js to TypeScript */}
        <RadioWithContent
          name={orgType}
          options={options}
          // disabled={fetching}
          value={orgType}
          disabled={!!canEdit}
          handleChange={(val: string): void => handleSelectOrgType(val)}
        >
          {orgType === 'sub_id' && (
            <>
              <SelectField
                // @ts-expect-error Need to migrate SelectField.js to TypeScript
                name="main_id"
                label={i18n.t('main_organization')}
                valueKey="id"
                labelKey="name"
                options={getCommercialRegistrations()}
                validate={composeValidators(required, number)}
                disabled={!!canEdit}
              />
              <Typography
                variant="body-new/regular"
                style={{ color: 'var(--color-base-colors-black-600)' }}
                text={i18n.t('select_the_main_organization')}
              />
            </>
          )}
        </RadioWithContent>
      </>
    </div>
  )
}

export default MolRegistration
