export const EMPLOYEE_TASKS_LOAD = 'EMPLOYEE_TASKS_LOAD'
export const EMPLOYEE_TASKS_LOAD_PENDING = 'EMPLOYEE_TASKS_LOAD_PENDING'
export const EMPLOYEE_TASKS_LOAD_FULFILLED = 'EMPLOYEE_TASKS_LOAD_FULFILLED'
export const EMPLOYEE_TASKS_LOAD_REJECTED = 'EMPLOYEE_TASKS_LOAD_REJECTED'

export const EMPLOYEE_TASKS_PUT_UPDATE = 'EMPLOYEE_TASKS_PUT_UPDATE'
export const EMPLOYEE_TASKS_PUT_UPDATE_PENDING = 'EMPLOYEE_TASKS_PUT_UPDATE_PENDING'
export const EMPLOYEE_TASKS_PUT_UPDATE_FULFILLED = 'EMPLOYEE_TASKS_PUT_UPDATE_FULFILLED'
export const EMPLOYEE_TASKS_PUT_UPDATE_REJECTED = 'EMPLOYEE_TASKS_PUT_UPDATE_REJECTED'

export const EPMLOYEE_TASKS_REASSIGN = 'EPMLOYEE_TASKS_REASSIGN'
export const EPMLOYEE_TASKS_REASSIGN_FULFILLED = 'EPMLOYEE_TASKS_REASSIGN_FULFILLED'
export const EPMLOYEE_TASKS_REASSIGN_REJECTED = 'EPMLOYEE_TASKS_REASSIGN_REJECTED'

export const EMPLOYEE_TASKS_RESET = 'EMPLOYEE_TASKS_RESET'

export const EMPLOYEE_SECTION_TASKS_LOAD = 'EMPLOYEE_SECTION_TASKS_LOAD'
export const EMPLOYEE_SECTION_TASKS_LOAD_PENDING = 'EMPLOYEE_SECTION_TASKS_LOAD_PENDING'
export const EMPLOYEE_SECTION_TASKS_LOAD_FULFILLED = 'EMPLOYEE_SECTION_TASKS_LOAD_FULFILLED'
export const EMPLOYEE_SECTION_TASKS_LOAD_REJECTED = 'EMPLOYEE_SECTION_TASKS_LOAD_REJECTED'

export const EMPLOYEE_SECTION_TASKS_PUT_UPDATE = 'EMPLOYEE_SECTION_TASKS_PUT_UPDATE'
export const EMPLOYEE_SECTION_TASKS_PUT_UPDATE_PENDING = 'EMPLOYEE_SECTION_TASKS_PUT_UPDATE_PENDING'
export const EMPLOYEE_SECTION_TASKS_PUT_UPDATE_FULFILLED =
  'EMPLOYEE_SECTION_TASKS_PUT_UPDATE_FULFILLED'
export const EMPLOYEE_SECTION_TASKS_PUT_UPDATE_REJECTED =
  'EMPLOYEE_SECTION_TASKS_PUT_UPDATE_REJECTED'
