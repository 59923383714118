export const REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES = 'REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES'
export const REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_PENDING =
  'REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_PENDING'
export const REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_FULFILLED =
  'REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_FULFILLED'
export const REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_REJECTED =
  'REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES_REJECTED'

export const GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD = 'GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD'
export const GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_PENDING =
  'GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_PENDING'
export const GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_FULFILLED =
  'GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_FULFILLED'
export const GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_REJECTED =
  'GET_V2_PERFORMANCE_ALL_EMPLOYEES_LOAD_REJECTED'

export const ASSIGN_LINE_MANAGER = 'ASSIGN_LINE_MANAGER'
export const ASSIGN_LINE_MANAGER_PENDING = 'ASSIGN_LINE_MANAGER_PENDING'
export const ASSIGN_LINE_MANAGER_FULFILLED = 'ASSIGN_LINE_MANAGER_FULFILLED'
export const ASSIGN_LINE_MANAGER_REJECTED = 'ASSIGN_LINE_MANAGER_REJECTED'

export const GET_V2_PERFORMANCE_ALL_EMPLOYEES_CLEANUP = 'GET_V2_PERFORMANCE_ALL_EMPLOYEES_CLEANUP'

export const RESET_REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES = 'RESET_REVIEW_CYCLES_V2_ELIGIBLE_EMPLOYEES'

export const GET_ASSIGNED_EMPLOYEES = 'GET_ASSIGNED_EMPLOYEES'
export const GET_ASSIGNED_EMPLOYEES_PENDING = 'GET_ASSIGNED_EMPLOYEES_PENDING'
export const GET_ASSIGNED_EMPLOYEES_FULFILLED = 'GET_ASSIGNED_EMPLOYEES_FULFILLED'
export const GET_ASSIGNED_EMPLOYEES_REJECTED = 'GET_ASSIGNED_EMPLOYEES_REJECTED'
