import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'

import {
  BankAccountsType,
  CommercialRegistration,
} from 'containers/public/Onboarding/types/onboardingTypes'

import * as actions from './actions'

export type TCommercialRegistration = CommercialRegistration & {
  bank_accounts: BankAccountsType
  payment_mode: string
  bank_account_id: number
}

export type TCommercialRegistrationOnboarding = {
  commercial_registrations: TCommercialRegistration[]
}

type TInitialStateCommercialRegistration = {
  fetching: boolean
  commercial_registrations: TCommercialRegistration[]
  commercial_registration: TCommercialRegistration | null
}

export const initialState: TInitialStateCommercialRegistration = {
  fetching: false,
  commercial_registrations: [],
  commercial_registration: null,
}

export default function reducer(
  state = initialState,
  action: Action,
): TInitialStateCommercialRegistration {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_PENDING:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_PENDING:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_PENDING:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_PENDING:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_FULFILLED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_REJECTED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_REJECTED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_REJECTED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_REJECTED:
    case actions.ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
