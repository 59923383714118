import { CSSProperties, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import { Checkbox } from '@jisr-hr/ds'
import { FieldValidator } from 'final-form'

type CheckboxFieldType = {
  labelTestId?: string
  description?: string
  direction?: 'right' | 'left'
  className?: string
  disabled?: boolean
  label?: string
  name: string
  style?: CSSProperties
  testId?: string
  validate?: FieldValidator<any>
  onChange?: (e: boolean) => void
}

const CheckboxField = ({ name, validate, onChange, ...rest }: CheckboxFieldType): JSX.Element => (
  <Field
    name={name}
    type="checkbox"
    validate={validate}
  >
    {({ input }): JSX.Element => {
      return (
        <Checkbox
          {...input}
          {...rest}
          active={input.checked}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            if (onChange) onChange(e.target.checked)
            input.onChange(e.target.checked)
          }}
        />
      )
    }}
  </Field>
)

export default CheckboxField
