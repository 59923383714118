import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  fetching: false,
  mol_registrations: [],
}

export default function notificationManagementReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.MOL_REGISTRATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.MOL_REGISTRATIONS_LOAD_FULFILLED:
      return {
        ...state,
        mol_registrations: payload.data.data.mol_registrations.map((item) => ({
          ...item,
          name_i18n: `${item.gosi_number} - ${item.name}`,
        })),
        fetching: false,
      }

    case actions.MOL_REGISTRATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    default:
      return state
  }
}
