import { useContext, useEffect, useState } from 'react'
import { SearchBar } from '@jisr-hr/design-system'
import { Typography, Button, Spacer, Flex, Modal } from '@jisr-hr/ds'
import { Table } from '@jisr-hr/ds-beta'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch, useSelector } from 'utils/hooks'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import I18n from 'translations/i18n'
import { StepsTypes, TParams } from 'containers/public/Onboarding/types/onboardingTypes'
import {
  employeesInviteAllLoad,
  employeesInviteLoad,
} from 'redux/organizationOnborading/invite/actionCreators'
import { TExtendsEmployee } from 'redux/organizationOnborading/employees/employees.type'
import { useUsersnapApi } from 'components/UserSnap/UsersnapContext'
import {
  onboardingValidateAdminsInvitationLoad,
  resetStates,
} from 'redux/leavesOnboarding/validationAdminInvited/actionCreators'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import { onboardingEmployeesWithEmailsLoad } from 'redux/leavesOnboarding/inviteEmployees/actionCreators'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import AddUsers from './AddUsers'
import CostumeAlert from './CostumeAlert'

type RowDataType = { row: { original: TExtendsEmployee } }

const InviteAdminUsers = ({ goPreviousStep, previousStep }: StepsTypes): JSX.Element => {
  const { setShowActivateAccount } = useContext(OnboardingContext)
  const dispatch = useDispatch()
  const usersnapApi = useUsersnapApi()
  const { errorMsg, fetching: fetchingValidate } = useSelector(
    ({ validationAdminInvited }) => validationAdminInvited,
  )
  const { employees, pagination, fetching } = useSelector(({ inviteEmployees }) => inviteEmployees)

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [params, setParams] = useState<TParams>({
    page: 1,
    rpp: 20,
    search: '',
  })
  const [isOpen, setIsOpen] = useState(false)
  const is_all_invited = employees.every((emp) => emp.is_invited)

  const validationInviting = (): void => {
    dispatch(onboardingValidateAdminsInvitationLoad({}, true)).then(() => {
      setShowActivateAccount(true)
    })
  }

  const handleClose = (): void => {
    setIsOpen(false)
    dispatch(resetStates())
  }

  const empLoad = (): void => {
    dispatch(onboardingEmployeesWithEmailsLoad(params))
  }

  const onClickInvite = (id: number): void => {
    dispatch(employeesInviteLoad(id)).then(() => {
      empLoad()
    })
  }

  const invite_all = (): void => {
    dispatch(employeesInviteAllLoad()).then(() => {
      empLoad()
    })
  }

  const handleFiltering = (key: string, val: number): void => {
    setParams((prevState) => ({ ...prevState, [key]: val }))
  }

  const handleNext = (): void => {
    setIsOpen(true)
  }

  useEffect(() => {
    empLoad()
  }, [params])

  useEffect(() => {
    if (usersnapApi) {
      usersnapApi?.logEvent('Onboarding_CSAT_Last')
    }
  }, [usersnapApi])

  useTrackingSteps({ step_name: 'invite_employees', level_name: 'invite_admin_users' })

  const columns = [
    {
      id: 'emp_id',
      cell: (rowData: RowDataType) => {
        const row = rowData?.row?.original
        return (
          <Flex flexCol>
            <Typography
              variant="subtitle-2"
              text={row.full_name_i18n}
            />
            <Typography
              variant="subtitle-2"
              text={row.code}
            />{' '}
          </Flex>
        )
      },
      header: I18n.t('emp_id'),
    },
    { id: 'email', accessorKey: 'email', header: I18n.t('email') },
    {
      cell: (rowData: RowDataType) => {
        const row = rowData?.row?.original
        return (
          <Button
            label={I18n.t(!row.is_invited ? 'invite' : 'invited_emp')}
            disabled={row.is_invited}
            size="small"
            color="neutral"
            variant="outlined"
            style={{ width: 118, height: 32, justifyContent: 'center' }}
            onClick={(): void => onClickInvite(row.id)}
          />
        )
      },
      header: I18n.t('action'),
      width: 150,
      id: 'actions',
    },
  ]
  return (
    <StepTemplate
      goNextStep={handleNext}
      goPreviousStep={goPreviousStep}
      nextStep={{ title: I18n.t('login') }}
      previousStep={previousStep}
      fetching={fetching}
    >
      {fetching && <JisrLoader absolute />}
      <Typography
        variant="heading"
        text={I18n.t('admin_users')}
      />

      <Spacer height={8} />
      <Typography
        style={{ paddingInlineEnd: '70px', color: 'var(--color-base-colors-black-500)' }}
        variant="subtitle-1"
        text={I18n.t('admin_users_desc', { app_name: I18n.t('jisr_translation_name') })}
      />

      <Spacer height={16} />
      <Flex justifyBetween>
        <SearchBar
          placeholder={I18n.t('search_employees_by_id_or_name')}
          style={{ width: 340 }}
          onChange={(_: string, search: string): void =>
            setParams((prevState) => ({
              ...prevState,
              search: search.trim(),
            }))
          }
          value={params.search}
        />
        <Flex style={{ gap: 16 }}>
          <Button
            label={I18n.t(!is_all_invited ? 'invite_all' : 'invited')}
            size="medium"
            disabled={is_all_invited}
            onClick={invite_all}
          />
          <Button
            label={I18n.t('add_admins_onb')}
            size="medium"
            onClick={(): void => setIsFormOpen(true)}
            trailingIcon="plus"
          />
        </Flex>
      </Flex>
      <AddUsers
        open={isFormOpen}
        onClose={(): void => {
          setIsFormOpen(false)
          empLoad()
        }}
      />

      <Spacer height="24px" />
      <Table
        columns={columns}
        data={employees.filter((emp) => emp.email)}
        tableSize={{ maxHeight: '40vh' }}
        pagination={{
          total_entries: pagination.total_entries,
          currentPage: params.page,
          per_page: params.rpp,
          onNext: (): void => {
            if (params.page < pagination.total_pages) handleFiltering('page', params.page + 1)
          },
          onJump: (val: number): void => handleFiltering('page', val),
          onPrev: (): void => {
            if (params.page > 0) handleFiltering('page', params.page - 1)
          },
        }}
      />

      <Modal
        header={{
          title: I18n.t('delete_confirmation'),
          withClose: true,
          leadingSpace: { icon: 'info-circle' },
        }}
        disableCloseOut={false}
        footer={{
          cancel: {
            label: I18n.t('confirm_cancel'),
            onClick: handleClose,
          },
          submit: {
            label: I18n.t('confirm_but'),
            onClick: validationInviting,
            loading: fetchingValidate,
            disabled: !!errorMsg || fetchingValidate,
          },
        }}
        open={isOpen}
      >
        <Typography
          variant="body-new/regular"
          style={{ color: 'var(--color-base-colors-black-700)' }}
          text={I18n.t(I18n.t('confirm_msg'))}
          testId="message"
        />
        {errorMsg && <CostumeAlert />}
      </Modal>
    </StepTemplate>
  )
}

export default InviteAdminUsers
