import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import { ApprovedRequestResType } from 'containers/authorised/requests/RequestTypes'
import * as actions from './actions'

type Request = {
  request_comments: unknown[]
}

const DEFAULT_STATE = {
  fetching: false,
  loading: false,
  submitting: false,
  openCardModal: false,
  super_admin_fetching: false,
  request: {} as Request,
  requests: [],
  approvedRequest: {} as ApprovedRequestResType,
  pendingRequests: [],
  payrun_pagination: {},
  pagination: {},
  filters: {},
  card_options: {
    show_modal: false,
    comment: {
      text: '',
      show: false,
      formCase: '',
    },
  },
  requests_navigation: {
    tab: 1,
  },
  bulk_rejected: false,
}

type RequestsState = typeof DEFAULT_STATE

export default function requestsReducer(state = DEFAULT_STATE, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.REQUESTS_CARD_OPTIONS:
      return {
        ...state,
        card_options: {
          ...state.card_options,
          ...action.payload.card_options,
          comment: {
            ...state.card_options.comment,
            ...action.payload.card_options.comment,
          },
        },
        request: {
          ...state.request,
          ...action.payload.request,
        },
      }

    case actions.UPDATE_REQUESTS_PARAMS:
      return {
        ...state,
        filters: action.payload.params,
      }

    case actions.ADD_NEW_COMMENT_REQUEST: {
      const updateRequest = {
        ...state.request,
        request_comments: [payload.comment, ...state.request.request_comments],
      }
      return {
        ...state,
        requests: updateOneListOfRecords(state.requests, updateRequest),
        pendingRequests: updateOneListOfRecords(state.pendingRequests, updateRequest),
        request: updateRequest,
      }
    }

    case actions.REQUESTS_NAVIGATION:
      return {
        ...state,
        requests_navigation: {
          ...state.requests_navigation,
          ...action.payload,
        },
      }

    case actions.REQUESTS_RESET:
      return {
        ...state,
        requests: [],
        pendingRequests: [],
      }

    case actions.REQUESTS_ALL_RESET:
      return {
        ...DEFAULT_STATE,
      }

    case actions.REQUESTS_LOAD_PENDING:
    case actions.RESUMPTION_REQUEST_PENDING:
    case actions.REQUESTS_APPROVALS_LOAD_PENDING:
    case actions.SINGLE_REQUEST_LOAD_PENDING:
    case actions.UPDATE_RESUMPTION_REQUEST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SUPER_ADMIN_CANCELING_REQUEST_PENDING:
      return {
        ...state,
        super_admin_fetching: true,
      }

    // :::::  Bulk Request Actions  ::::: //
    case actions.BULK_REQUESTS_APPROVE_SINGLE_PENDING:
    case actions.BULK_REQUESTS_REJECT_BULK_PENDING:
    case actions.BULK_PAYRUN_APPROVALS_APPROVE_PENDING:
    case actions.BULK_PAYRUN_APPROVALS_REJECT_PENDING:
      return {
        ...state,
        submitting: true,
        bulk_rejected: false,
      }

    case actions.REQUESTS_LOAD_FULFILLED:
    case actions.REQUESTS_APPROVALS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        pagination: payload.data.data.pagination,
        requests: [...state.requests, ...payload.data.data.requests],
        pendingRequests: payload.data.data.requests,
        fetching: false,
      }

    case actions.SINGLE_REQUEST_LOAD_FULFILLED:
      return {
        ...state,
        request: payload.data.data.request,
        fetching: false,
      }

    case actions.REQUESTS_APPROVE_REQUEST_PENDING:
    case actions.REQUESTS_REJECT_REQUEST_PENDING:
    case actions.REQUESTS_CANCEL_REQUEST_PENDING:
    case actions.CANCELLATION_REQUESTS_CREATE_PENDING:
    case actions.RETURN_FOR_CORRECTION_PENDING:
      return {
        ...state,
        loading: true,
        approvedRequest: {} as ApprovedRequestResType,
      }

    // :::::  Bulk Request Actions  ::::: //
    case actions.BULK_REQUESTS_REJECT_BULK_FULFILLED: {
      return {
        ...state,
        submitting: false,
        bulk_rejected: false,
      }
    }

    case actions.BULK_REQUESTS_APPROVE_SINGLE_FULFILLED:
    case actions.BULK_PAYRUN_APPROVALS_APPROVE_FULFILLED:
    case actions.BULK_PAYRUN_APPROVALS_REJECT_FULFILLED: {
      return {
        ...state,
        submitting: false,
      }
    }
    case actions.REQUESTS_APPROVE_REQUEST_FULFILLED:
    case actions.REQUESTS_REJECT_REQUEST_FULFILLED:
    case actions.REQUESTS_CANCEL_REQUEST_FULFILLED:
    case actions.RESUMPTION_REQUEST_FULFILLED:
    case actions.CANCELLATION_REQUESTS_CREATE_FULFILLED:
    case actions.RETURN_FOR_CORRECTION_FULFILLED:
    case actions.SUPER_ADMIN_CANCELING_REQUEST_FULFILLED:
    case actions.UPDATE_RESUMPTION_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        loading: false,
        super_admin_fetching: false,
        approvedRequest: action.payload.data?.data?.request,
      }

    case actions.REQUESTS_APPROVE_REQUEST_REJECTED:
    case actions.REQUESTS_REJECT_REQUEST_REJECTED:
    case actions.REQUESTS_CANCEL_REQUEST_REJECTED:
    case actions.REQUESTS_LOAD_REJECTED:
    case actions.RESUMPTION_REQUEST_REJECTED:
    case actions.CANCELLATION_REQUESTS_CREATE_REJECTED:
    case actions.RETURN_FOR_CORRECTION_REJECTED:
    case actions.SUPER_ADMIN_CANCELING_REQUEST_REJECTED:
    case actions.REQUESTS_APPROVALS_LOAD_REJECTED:
    case actions.SINGLE_REQUEST_LOAD_REJECTED:
    case actions.UPDATE_RESUMPTION_REQUEST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        super_admin_fetching: false,
        approvedRequest: {} as ApprovedRequestResType,
      }

    // :::::  Bulk Request Actions  ::::: //
    case actions.BULK_REQUESTS_REJECT_BULK_REJECTED: {
      return {
        ...state,
        submitting: false,
        bulk_rejected: true,
      }
    }
    case actions.BULK_REQUESTS_APPROVE_SINGLE_REJECTED:
    case actions.BULK_PAYRUN_APPROVALS_APPROVE_REJECTED:
    case actions.BULK_PAYRUN_APPROVALS_REJECT_REJECTED: {
      return {
        ...state,
        submitting: false,
      }
    }

    case actions.BULK_REQUESTS_CLEAR_SUCCESS_RESULTS:
      return {
        ...state,
        submitting: false,
      }

    case actions.CLEAR_APPROVED_REQUEST_RESPONSE:
      return {
        ...state,
        approvedRequest: {} as ApprovedRequestResType,
      }

    default:
      return state
  }
}
