import { FC, useEffect } from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'utils/hooks'
import ErrorPage from 'components/global/ErrorPage'
import PrivateRoute from 'routes/PrivateRoute'
import { ReactComponent as Error403 } from 'assets/images/error403.svg'
import { ReactComponent as JisrError404 } from 'assets/images/Jisr404.svg'
import { redirectAfterLoginSelector } from 'redux/authUser/selectors'
import { redirectAfterLogin } from 'redux/authUser/actionCreators'
import routes from './config'

export const Page403: FC = () => (
  <ErrorPage
    errorCodeImage={<Error403 />}
    title="403_title"
    subTitle="403_description"
  />
)

export const Page404: FC = () => (
  <ErrorPage
    errorCodeImage={<JisrError404 />}
    title="404_title"
    subTitle="404_description"
  />
)

const AuthorisedRoutes: FC = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const employee = useSelector((s) => s.auth.employee)
  const company = useSelector((s) => s.auth.company)
  const enablement = useSelector((s) => s.enablerDataReducer?.showLumofy)
  const redirectAfterLoginPath = useSelector(redirectAfterLoginSelector)

  useEffect(() => {
    // Clear redirectAfterLogin from localStorage after component mounts & redirect is processed
    dispatch(redirectAfterLogin(null))
  }, [])

  const isPermitted = (model: string): boolean => {
    let permitted = false
    if (
      ['PerformanceEvaluation', 'performanceOnboarding'].includes(model) &&
      !company?.subscribed_features?.includes('new_performance_management')
    ) {
      return false
    }
    if (
      [
        'PerformanceObjectives',
        'performanceCyclesCompetencies',
        'performanceCyclesCompetencies',
      ].includes(model) &&
      !company?.subscribed_features?.includes('performance_management')
    ) {
      return false
    }
    if (employee?.permissions) {
      ;[
        ...employee.permissions,
        {
          module: 'Overview',
          value: 4,
        },
        {
          module: 'Marketplace',
          value: employee?.can_access_lms && enablement ? 4 : 0,
        },
      ].forEach((permission) => {
        if (model === permission.module && permission.value > 0) permitted = true
      })
    }
    return permitted
  }

  return (
    <div>
      {employee?.permissions && (
        <Switch>
          {routes.map(({ path, permissionKey, component, isExact }, index) => (
            <PrivateRoute
              key={index}
              exact={isExact}
              path={path}
              component={isPermitted(permissionKey) ? component : Page403}
            />
          ))}
          <Redirect
            from="/login"
            to={{ pathname: redirectAfterLoginPath ?? '/', search }}
          />
          <PrivateRoute component={Page404} />
        </Switch>
      )}
    </div>
  )
}

export default AuthorisedRoutes
