import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  task: {},
  tasks: [],
  pagination: {},
  fetching: false,
  fetchingTasks: false,
}

export default function reducer(state = initialState, action) {
  const { tasks, task, pagination } = action?.payload?.data?.data ?? {}
  switch (action.type) {
    case actions.INBOX_TASKS_LOAD_PENDING:
      return {
        ...state,
        fetchingTasks: true,
      }
    case actions.INBOX_TASK_GET_PENDING:
    case actions.INBOX_TASK_UPDATE_PENDING:
    case actions.INBOX_TASK_REASSIGN_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.INBOX_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        tasks: [...(pagination?.previous_page ? state.tasks : []), ...tasks],
        pagination,
        fetchingTasks: false,
      }
    case actions.INBOX_TASK_UPDATE_FULFILLED:
    case actions.INBOX_TASK_REASSIGN_FULFILLED:
      return {
        ...state,
        fetching: false,
      }
    case actions.INBOX_TASK_GET_FULFILLED:
      return {
        ...state,
        task,
        fetching: false,
      }
    case actions.INBOX_TASKS_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetchingTasks: false,
      }
    case actions.INBOX_TASK_UPDATE_REJECTED:
    case actions.INBOX_TASK_REASSIGN_REJECTED:
    case actions.INBOX_TASK_GET_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }
    default:
      return state
  }
}
