import React from 'react'
import I18n from 'translations/i18n'
import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import Signpost from 'components/global/templates/RequestDrawer/ds/Signpost'
import { ReactComponent as ArrowIcon } from './icons/Arrow.svg'

const Record = ({ title, label, value }) => (
  <Signpost>
    <Grid
      container
      justifyBetween
    >
      <Grid
        item
        xs={4}
      >
        <Typography
          text={I18n.t(title)}
          style={{ color: 'var(--color-base-colors-grey-600)' }}
          variant="body-new/regular"
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        {typeof label === 'object' ? (
          label || '--'
        ) : (
          <Typography
            text={label}
            style={{ color: 'var(--color-base-colors-black-500)' }}
            variant="body-new/regular"
          />
        )}
      </Grid>
      <Grid
        item
        xs={1}
      >
        <ArrowIcon />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Flex justifyEnd>
          {typeof value === 'object' ? (
            value || '--'
          ) : (
            <Typography
              text={label}
              style={{ color: 'var(--color-base-colors-black-500)' }}
              variant="body-new/regular"
            />
          )}
        </Flex>
      </Grid>
    </Grid>
  </Signpost>
)

Record.propTypes = {
  title: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.node,
}

export default Record
