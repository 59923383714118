import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import { updateOneListOfRecordsByFindIndex } from 'containers/authorised/setting/v2/payroll/Payables/components/helper'
import { Toastr } from 'components/global/Toastr'
import * as actions from './action'

export type PayType = 'in_payrun' | 'out_of_payrun' | 'both'
export type Default_pay_type = 'in_payrun' | 'out_of_payrun'

export type Paygroup = {
  id?: number
  name?: string
  label?: string
  in_payrun?: boolean
  out_of_payrun?: boolean
  pay_type: PayType
  default_pay_type: Default_pay_type
  status: 'active' | 'inactive'
}
export type AdditionTransactionType = {
  category: string
  description: string | null
  id: number
  name: string
  name_ar: string
  name_i18n: string
  status: 'active' | 'inactive'
  is_default: boolean
  in_payrun?: boolean
  out_of_payrun?: boolean
  pay_type: PayType
  default_pay_type: Default_pay_type
  paygroups?: Paygroup[]
}

type InitStateAction = {
  payroll_transaction_types: AdditionTransactionType[]
  fetching: boolean
  loading: boolean
}

const initialState: InitStateAction = {
  payroll_transaction_types: [],
  fetching: false,
  loading: false,
}

export default function reducer(state = initialState, action: Action): InitStateAction {
  const { payload } = action
  switch (action.type) {
    case actions.PAY_TYPE_CREATE_PENDING:
    case actions.PAY_TYPE_UPDATE_PENDING:
    case actions.PAY_TYPE_DELETE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.PAY_TYPES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.PAY_TYPES_LOAD_FULFILLED: {
      return {
        ...state,
        payroll_transaction_types: payload.data.data.payroll_transaction_types,
        fetching: false,
      }
    }

    case actions.PAY_TYPE_CREATE_FULFILLED: {
      const { payroll_transaction_types } = state
      const { payroll_transaction_type } = action.payload.data.data
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        payroll_transaction_types: [...payroll_transaction_types, payroll_transaction_type],
        loading: false,
      }
    }

    case actions.PAY_TYPE_UPDATE_FULFILLED: {
      const additionPayTypes = state.payroll_transaction_types
      const additionType = payload.data.data.payroll_transaction_type as AdditionTransactionType

      updateOneListOfRecordsByFindIndex(additionPayTypes, additionType)

      Toastr.success(action.payload.data.message)
      return {
        ...state,
        payroll_transaction_types: [...additionPayTypes],
        loading: false,
      }
    }

    case actions.PAY_TYPE_DELETE_FULFILLED: {
      const { payroll_transaction_types } = state
      const { id } = payload
      const additionTypes = removeOneListOfRecords(payroll_transaction_types, id)
      Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
        payroll_transaction_types: additionTypes,
      }
    }

    case actions.PAY_TYPES_LOAD_REJECTED:
    case actions.PAY_TYPE_CREATE_REJECTED:
    case actions.PAY_TYPE_UPDATE_REJECTED:
    case actions.PAY_TYPE_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
