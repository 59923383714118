import { useEffect } from 'react'
import { Typography, Flex } from '@jisr-hr/ds'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'utils/hooks'
import { toggleNotificationsPanel } from 'redux/appNotifications/actionCreators'
import { useOrgManagement } from 'store/OrgManagement/useOrgManagement'
import { unreadNotificationsLoad } from 'redux/appNotifications/markAsRead/actionCreators'
import DSIcon from 'components/DSIcon'
import { shallowEqual } from 'react-redux'

import styles from '../styles.module.css'

const CustomToast = ({ title, message }: { title?: string; message: string }): JSX.Element => {
  return (
    <Flex style={{ gap: 16 }}>
      <Flex
        itemsCenter
        justifyCenter
        className={styles.imgContainer}
      >
        <DSIcon
          name="bell-03"
          size="ml"
        />
      </Flex>
      <div style={{ flex: 1 }}>
        {title && (
          <Typography
            text={title}
            variant="body-new/bold"
            textColor="color/fg/bold"
          />
        )}
        <Typography
          text={message}
          variant="body-new/regular"
          textColor="color/fg/bold"
        />
      </div>
    </Flex>
  )
}

export default function Notifications(): null {
  const dispatch = useDispatch()
  const history = useHistory()
  const notificationsSettings = useSelector(
    (s) => s.notificationsSetting.employee_configuration?.options,
  )
  const toggleAnnouncementDrawer = useOrgManagement(
    (s) => s.announcementsDrawer.toggleAnnouncementDrawer,
  )
  const isAttendanceOnboardingCompleted = useSelector(
    ({ auth }) => auth.employee?.organization.attendance_onboarding_completed,
    shallowEqual,
  )

  const onClickNotification = (category: string): void => {
    const redirectionURL = {
      scheduler: isAttendanceOnboardingCompleted ? '/scheduler' : '/dashboard/assign_shifts',
      payroll_startup_payment_details: sessionStorage.getItem('define_employees_payment_path'),
      wps_gosi_infos: sessionStorage.getItem('wps_gosi_info_path'),
      payroll_adjustments: sessionStorage.getItem('payroll_adjustment_path'),
      file_exports: '/export_history',
    }

    if (redirectionURL[category]) history.replace(redirectionURL[category])
  }

  const togglePanel = (category: string): void => {
    if (category === 'admin_announcement') {
      toggleAnnouncementDrawer(true)
    } else {
      dispatch(toggleNotificationsPanel(true))
    }
  }

  useEffect(() => {
    navigator.serviceWorker?.addEventListener('message', (event) => {
      const { data, notification } = event?.data
      if (
        data?.category_type === 'requests' &&
        notificationsSettings?.requests_and_tasks !== 'true'
      )
        return
      if (data?.push_type !== '10' && notification?.body) {
        const isNotificationClickable =
          data?.persistent_notification !== 'false' || data?.category_type === 'bulk_import'

        dispatch(unreadNotificationsLoad())
        toast(
          <CustomToast
            title={notification?.title}
            message={notification?.body}
          />,
          {
            ...(isNotificationClickable && {
              onClick: () =>
                ['file_exports', 'bulk_import'].includes(data?.category_type)
                  ? onClickNotification(data.category ?? data?.category_type)
                  : togglePanel(data.category ?? data?.category_type),
            }),
            autoClose: false,
            toastId: notification?.body, // prevent duplicate notifications
          },
        )
      }
    })
  }, [])

  return null
}
