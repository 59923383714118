export const SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC = 'SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC'
export const SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_FULFILLED =
  'SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_REJECTED =
  'SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_PENDING =
  'SAP_BUSINESSES_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const SAP_BUSINESSES_ACCOUNT_BULK_UPDATE = 'SAP_BUSINESSES_ACCOUNT_BULK_UPDATE'
export const SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_FULFILLED =
  'SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_FULFILLED'
export const SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_REJECTED =
  'SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_REJECTED'
export const SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_PENDING =
  'SAP_BUSINESSES_ACCOUNT_BULK_UPDATE_PENDING'

export const SAP_BUSINESSES_ACCOUNTS_LOAD = 'SAP_BUSINESSES_ACCOUNTS_LOAD'
export const SAP_BUSINESSES_ACCOUNTS_LOAD_FULFILLED = 'SAP_BUSINESSES_ACCOUNTS_LOAD_FULFILLED'
export const SAP_BUSINESSES_ACCOUNTS_LOAD_REJECTED = 'SAP_BUSINESSES_ACCOUNTS_LOAD_REJECTED'
export const SAP_BUSINESSES_ACCOUNTS_LOAD_PENDING = 'SAP_BUSINESSES_ACCOUNTS_LOAD_PENDING'

export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD = 'SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_FULFILLED =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_REJECTED =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_PENDING =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE = 'SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_FULFILLED =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_REJECTED =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_PENDING =
  'SAP_BUSINESSES_SPECIFIC_ACCOUNT_DELETE_PENDING'
