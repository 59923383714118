import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Info,
  Workflow,
  Tabs,
  Comments,
} from 'components/global/templates/RequestDrawer/components'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import useBenefitsManagementPermissions from 'containers/authorised/BenefitsManagement/permissions/useBenefitsManagementPermissions'
import Dependents from '../../components/FlightTicketComponents/Dependents'
import FlightTicketDetailsSection from '../../components/FlightTicketComponents/FlightTicketDetailsSection'
import { useRequestDetail } from '../../hooks'
import { useRequest } from '../../RequestProvider'

const FlightTicketRequest = (): JSX.Element => {
  const dispatch = useDispatch()

  const { loading, request, reloadRequest } = useRequestDetail()
  const { setRequestType, setEnableHittingRequestListener } = useRequest()
  const { isAnnualFlightTicketsEnabled } = useBenefitsManagementPermissions()
  const [activeTab, setActiveTab] = useState('request_details')

  const onClickLinkedRequest = (): void => {
    setEnableHittingRequestListener(false)
    setRequestType(request?.linked_request_attributes?.request_type)
    // ? setTimeout here to execute the hitting api after the execution of resetRequestDetail on the useRequestDetail listener
    setTimeout(() => {
      dispatch(
        requestDetailsLoad(request?.employee?.id, {
          sequential_id: request?.linked_request_attributes?.sequential_id,
        }),
      ).finally(() => {
        setEnableHittingRequestListener(true)
      })
    }, 1)
  }

  useEffect(() => {
    return () => setEnableHittingRequestListener(true)
  })

  return (
    <Flex
      flexCol
      className="gap-[16px]"
    >
      {loading && <JisrLoader absolute />}

      <Tabs
        active={activeTab}
        onChange={(tab): void => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              ...request,
              linkedWith: i18n.t('label.leave_request'),
              onClickLinkedRequest,
              hasFlightTicketPermission: isAnnualFlightTicketsEnabled,
            }}
            showColumns={[
              request?.policy_details?.policy_status !== 'no_policy' &&
                'flight_ticket_policy_status',
              'flight_ticket_date',
              request?.linked_request_attributes?.sequential_id && 'linked_with_request',
            ].filter(Boolean)}
          />

          <FlightTicketDetailsSection
            departure_date={request?.departure_date}
            return_date={request?.return_date}
            destination={request?.travel_destination}
            round_trip={request?.round_trip}
            cabin_class={request?.flight_classes_i18n}
            exception_attributes={request?.exception_attributes}
          />

          {!!request?.dependents?.length && (
            <Dependents
              dependents={request?.dependents ?? []}
              exceptionNoOfDependents={request?.exception_attributes?.exception_no_of_dependents}
              numberOfDependentCovered={request?.no_of_dependents_covered}
            />
          )}

          <Workflow
            lessDetail
            approvals={request.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request?.approval?.request_id,
              request_type: request?.approval?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />

          {!isEmpty(request.approvals) && <Workflow approvals={request.approvals} />}
        </>
      )}
    </Flex>
  )
}

export default FlightTicketRequest
