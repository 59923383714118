export const MIN_VACATION_SALARY_DAYS_LOAD = 'MIN_VACATION_SALARY_DAYS_LOAD'
export const MIN_VACATION_SALARY_DAYS_LOAD_PENDING = 'MIN_VACATION_SALARY_DAYS_LOAD_PENDING'
export const MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED = 'MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED'
export const MIN_VACATION_SALARY_DAYS_LOAD_REJECTED = 'MIN_VACATION_SALARY_DAYS_LOAD_REJECTED'

export const MIN_VACATION_SALARY_DAYS_UPDATE = 'MIN_VACATION_SALARY_DAYS_UPDATE'
export const MIN_VACATION_SALARY_DAYS_UPDATE_PENDING = 'MIN_VACATION_SALARY_DAYS_UPDATE_PENDING'
export const MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED = 'MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED'
export const MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED = 'MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED'

export const IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD = 'IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD'
export const IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED =
  'IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED'
export const IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED =
  'IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED'

export const IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE = 'IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE'
export const IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING =
  'IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING'
export const IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED =
  'IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED'
export const IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED =
  'IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED'
