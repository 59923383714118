import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { TrackingDataType } from 'containers/public/Onboarding/types/onboardingTypes'
import * as actions from './actions'

export function onboardingTrackingSteps(params: TrackingDataType): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization/tracking_steps`

  return {
    type: actions.ONBOARDING_ORGANIZATION_TRACKING_STEPS_PUT,
    payload: handleAPI(url, params, 'PUT'),
  }
}
