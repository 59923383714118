import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  excuse_request_types: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EXCUSE_REQUEST_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
      }

    case actions.EXCUSE_REQUEST_TYPES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
