import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TParams = {
  employee_role: {
    employee_id: number
  }[]
}

export function addAdminUser(params: TParams): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employee_roles`

  return {
    type: actions.ADD_ADMIN_USER,
    payload: handleAPI(url, params, 'POST'),
  }
}
export function removeAdminUser(params: TParams): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employee_roles/revoke`

  return {
    type: actions.REMOVE_ADMIN_USER,
    payload: handleAPI(url, params, 'DELETE'),
  }
}
