import { Flex, Tag } from '@jisr-hr/ds'

type optionTypes = { label: string; value: string | number; testId: string }

type MultiBadgesCheckListProps = {
  labelKey: string
  options: Array<optionTypes>
  selected: Array<string | number>
  valueKey: string
  onChange: (value: string | number) => void
}

const MultiBadgesCheckList = (props: MultiBadgesCheckListProps): JSX.Element => {
  const { selected, options, onChange, valueKey = 'value', labelKey = 'label' } = props

  return (
    <Flex
      flexWrap
      style={{ gap: 6 }}
    >
      {options.map((data: optionTypes, ind: number) => {
        return (
          <Flex key={ind}>
            <Tag
              label={data?.[labelKey]}
              active={Boolean(selected?.includes(data?.[valueKey]))}
              onClick={(): void => onChange(data?.[valueKey])}
              testId={data.testId}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}

export default MultiBadgesCheckList
