import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { ATSApi } from 'apis/ATSAxiosInstance'
import { AxiosResponse } from 'axios'
import { Toastr } from 'components/global/Toastr'
import { queryClient } from 'hooks/useReactQuery'
import { API_VERSION_ONE } from 'utils/apiUtils'
import { atsAPI, GetAllJobRequest } from 'utils/atsUtils'
import { HTTPValidationErrorATS } from '../ATSCommanTypes'
import { Params } from '../TimelineList/useTimelineListType'
import {
  CreateUpdateJobType,
  CreateUpdateJobTypeParams,
  CreateUpdateJobTypeResponse,
  GetAllJobResponse,
  GetAllOpenJobTemplateType,
  GetJobAssessmentCardType,
  GetJobDetailsType,
  JobApplicationFormDetails,
  JobDetails,
  JobDetailsType,
  ScreeningQuestionType,
  SuggestedLinkType,
  UpdateAssessmentCardData,
  UpdateJobApplicationFormDetailsParams,
  UpdateJobApplicationFormType,
  UpdateJobHiringStageType,
  UpdateJobHiringStageTypeParams,
} from './useJobType'

export const screeningQuestionKey = (searchQuery: string) => ['screening_question', searchQuery]

export const suggestedLinksKey = () => ['screening_question']

const createJob = (data: CreateUpdateJobTypeParams): Promise<CreateUpdateJobType> => {
  return ATSApi.post(`/${API_VERSION_ONE}/jobs`, {
    job: { ...data },
  })
}

const getScreeningQuestion = (searchQuery: string) =>
  ATSApi.get(
    searchQuery
      ? `/${API_VERSION_ONE}/suggested_questions?search=${searchQuery}`
      : `/${API_VERSION_ONE}/suggested_questions`,
  )

const getSuggestedLinks = () => ATSApi.get(`/${API_VERSION_ONE}/suggested_links`)

const updateJob = (
  data:
    | CreateUpdateJobTypeParams
    | UpdateJobHiringStageTypeParams
    | UpdateJobApplicationFormDetailsParams
    | { assessment_card: UpdateAssessmentCardData },
  id: number,
) => {
  return ATSApi.put(`/${API_VERSION_ONE}/jobs/${id ?? 0}`, {
    job: data,
  })
}

const getJobDetails = (jobId: number): Promise<JobDetailsType> => {
  return ATSApi.get(`/${API_VERSION_ONE}/jobs/${jobId}`)
}

/**
 * @endpoint: POST: /v1/job
 * @summary This hook used to create a job
 */

export const useCreateJob = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<CreateUpdateJobType, TError, { data: CreateUpdateJobTypeParams }>
}): UseMutationResult<CreateUpdateJobType, TError, { data: CreateUpdateJobTypeParams }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => createJob(payload.data),
    onSuccess: (res, variables, context) => {
      queryClient?.invalidateQueries(['jobs'])
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res?.data?.data?.message)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

/**
 * @endpoint: PUT: /v1/job/:id
 * @summary This hook used to update a job
 */

export const useUpdateJobDetails = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<CreateUpdateJobType, TError, { data: CreateUpdateJobTypeParams }>
}): UseMutationResult<CreateUpdateJobType, TError, { data: CreateUpdateJobTypeParams }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => updateJob(payload.data, payload.data.id ?? 0),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res?.data?.data?.message)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

/**
 * A custom hook to update a job application form.
 *
 * @param {Object} [opts] - Optional configuration for the mutation.
 */
export const useUpdateJobApplicationForm = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<
    UpdateJobApplicationFormType,
    TError,
    { data: Partial<JobApplicationFormDetails>; jobId: number }
  >
}): UseMutationResult<
  UpdateJobApplicationFormType,
  TError,
  { data: Partial<JobApplicationFormDetails>; jobId: number }
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => updateJob({ job_form: payload.data }, payload.jobId),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res?.data?.data?.message)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

/**
 * A custom hook to fetch job assessment card details.
 *
 * @param {number} jobId - The ID of the job.
 * @param {Object} [opts] - Optional configuration for the query.
 */
export const useGetAssessmentCard = <TError extends HTTPValidationErrorATS>(
  jobId: number,
  opts?: {
    query?: UseQueryOptions<GetJobAssessmentCardType, TError>
  },
): UseQueryResult<GetJobAssessmentCardType, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    // @ts-expect-error -- error from lib type file
    queryKey: opts?.query?.queryKey ?? ['getAssessmentCard', jobId],
    queryFn: () => {
      const url = `/${API_VERSION_ONE}/jobs/${jobId}/assessment_cards`
      return atsAPI<null, null, GetJobAssessmentCardType>(url, null, 'GET')
    },
  })
}

/**
 * A custom hook to update a job application form.
 *
 * @param {Object} [opts] - Optional configuration for the mutation.
 */
export const useUpdateAssessmentCardForm = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<
    UpdateJobApplicationFormType,
    TError,
    { data: UpdateAssessmentCardData; jobId: number }
  >
}): UseMutationResult<
  UpdateJobApplicationFormType,
  TError,
  { data: UpdateAssessmentCardData; jobId: number }
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => updateJob({ assessment_card: payload.data }, payload.jobId),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res?.data?.data?.message)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

export const useDeleteJobAssessmentCard = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<AxiosResponse<void>, TError, { cardId: number; jobId: number }>
}): UseMutationResult<AxiosResponse<void>, TError, { cardId: number; jobId: number }> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: async (payload) => {
      return ATSApi.delete(
        `/${API_VERSION_ONE}/jobs/${payload?.jobId}/assessment_cards/${payload?.cardId}`,
      )
    },
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      queryClient?.invalidateQueries(['job_details', variables.jobId])
      Toastr.success('Assessment card deleted successfully')
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

export const useUpdateJobHiringStage = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<
    UpdateJobHiringStageType,
    TError,
    { data: UpdateJobHiringStageTypeParams; jobId: number }
  >
}): UseMutationResult<
  UpdateJobHiringStageType,
  TError,
  { data: UpdateJobHiringStageTypeParams; jobId: number }
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => updateJob(payload.data, payload.jobId),
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res?.data?.data?.message)
    },
    onError: (err) => {
      Toastr.error(err?.response?.data.errors.message)
    },
  })
}

// /**
//  * @endpoint: GET: /v1/integrations/google/candidate_emails/:email_id
//  * @summary This hook used to get email details
//  */

export const useGetJobDetails = <TError extends HTTPValidationErrorATS, TData = JobDetails>(
  jobId: number,
  opts?: {
    query?: UseQueryOptions<GetJobDetailsType, TError, TData>
  },
  callback?: (data: TData) => void,
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? ['job_details', jobId],
    queryFn: () => getJobDetails(jobId),
    onSuccess: callback,
    onError: (err: TError) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

export const useGetJobs = <TError extends HTTPValidationErrorATS>(
  requestBody: GetAllJobRequest,
  opts?: UseQueryOptions<GetAllJobResponse, TError>,
): UseQueryResult<GetAllJobResponse, TError> => {
  const fetchJobs = async (): Promise<GetAllJobResponse> => {
    const url = `/${API_VERSION_ONE}/jobs`
    const response = await ATSApi.get(url, { params: requestBody })
    return response.data
  }

  return useQuery<GetAllJobResponse, TError>({
    ...opts,
    queryKey: ['jobs', requestBody],
    queryFn: fetchJobs,
    onError: (err: TError) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

export const useDeleteJobByID = <TError extends HTTPValidationErrorATS>(opts?: {
  mutation?: UseMutationOptions<CreateUpdateJobTypeResponse, TError, number>
}): UseMutationResult<CreateUpdateJobTypeResponse, TError, number> => {
  const deleteJob = async (id: number) => {
    const url = `/${API_VERSION_ONE}/jobs/${id}`
    const response = await ATSApi.delete(url)
    return response.data
  }

  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (id: number) => deleteJob(id),
    onSuccess: (res, variables, context) => {
      Toastr.success(res?.data?.message)
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (err: TError) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

const fetchOpenJobs = async (params: Params & { filter: { search: string } }) => {
  const url = `/${API_VERSION_ONE}/jobs/open_jobs`
  const response = await ATSApi.get(url, { params })
  return response
}

export const useGetOpenJobs = <
  TError extends HTTPValidationErrorATS,
  TData = GetAllOpenJobTemplateType,
>(
  params: Params & { filter: { search: string } },
  opts?: {
    query?: UseInfiniteQueryOptions<GetAllOpenJobTemplateType, TError, TData>
  },
): UseInfiniteQueryResult<TData, TError> => {
  return useInfiniteQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? ['open_jobs', params],
    queryFn: ({ pageParam = 1 }) =>
      fetchOpenJobs({
        ...params,
        ...{ per_page: params.per_page ?? 10, page: pageParam },
      }),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = !!lastPage.data.pagination?.next_page
      return nextPage ? pages.length + 1 : undefined
    },
    onError: (err) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

export const useCreateDuplicateJob = <
  TError extends HTTPValidationErrorATS,
  TData = AxiosResponse<JobDetails, HTTPValidationErrorATS>,
>(opts?: {
  mutation?: UseMutationOptions<TData, TError, { jobId: number }>
}): UseMutationResult<TData, TError, { jobId: number }> => {
  const duplicateJob = async (payload: { jobId: number }) => {
    const url = `/${API_VERSION_ONE}/jobs/${payload?.jobId}/duplicate`
    const response = await ATSApi.get(url)
    return response.data
  }

  return useMutation<TData, TError, { jobId: number }>({
    ...opts?.mutation,
    mutationFn: duplicateJob,
    onSuccess: (res, variables, context) => {
      Toastr.success(
        (res as AxiosResponse<{ message: string }, HTTPValidationErrorATS>)?.data?.message,
      )
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (err: TError) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

/**
 * @endpoint: GET: /v1/suggested_questions
 * @summary This hook used to get screening questions
 */

export const useScreeningQuestion = <
  TError extends HTTPValidationErrorATS,
  TData = ScreeningQuestionType,
>(
  searchQuery: string,
  opts?: {
    query?: UseQueryOptions<ScreeningQuestionType, TError, TData>
  },
) => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? screeningQuestionKey(searchQuery),
    queryFn: () => getScreeningQuestion(searchQuery),
    onError: (err) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}

/**
 * @endpoint: GET: /v1/suggested_links
 * @summary This hook used to get suggested links
 */

export const useGetSuggestedLinks = <
  TError extends HTTPValidationErrorATS,
  TData = SuggestedLinkType,
>(opts?: {
  query?: UseQueryOptions<SuggestedLinkType, TError, TData>
}) => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? suggestedLinksKey(),
    queryFn: () => getSuggestedLinks(),
    onError: (err) => {
      Toastr.error(err?.response?.data?.errors?.message)
    },
  })
}
