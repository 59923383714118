import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { workflowPros, WorkflowsPros, Employees } from './types'

export const defaultWorkflow = {
  name: '',
  employee_ids: [],
  group_ids: [],
  is_applicable_to_all: true,
  workflow_steps: [
    {
      approver: {
        id: 0,
        code: '',
        name: '',
        name_ar: '',
      },
      step_count: 1,
    },
  ],
}
const initialState = {
  fetching: false,
  employees: [] as Employees[],
  workflow: defaultWorkflow as workflowPros,
  workflows: [] as WorkflowsPros[],
}
type State = typeof initialState

export default function reducer(state = initialState, action: Action): State {
  const { payload } = action
  switch (action.type) {
    case actions.REQUEST_TYPES_WORKFLOWS_PUT_PENDING:
    case actions.REQUEST_TYPES_WORKFLOWS_DELETE_PENDING:
    case actions.REQUEST_TYPES_WORKFLOWS_SHOW_PENDING:
    case actions.REQUEST_TYPES_WORKFLOWS_POST_PENDING:
    case actions.PAYRUN_APPROVALS_LIST_LOAD_PENDING:
    case actions.REQUEST_TYPES_WORKFLOWS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.PAYRUN_APPROVALS_LIST_LOAD_FULFILLED:
    case actions.REQUEST_TYPES_WORKFLOWS_SHOW_FULFILLED:
    case actions.REQUEST_TYPES_WORKFLOWS_LOAD_FULFILLED: {
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }
    }

    case actions.REQUEST_TYPES_WORKFLOWS_DELETE_FULFILLED:
    case actions.REQUEST_TYPES_WORKFLOWS_PUT_FULFILLED:
    case actions.REQUEST_TYPES_WORKFLOWS_POST_FULFILLED: {
      Toastr.success(payload.data?.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_LIST_LOAD_REJECTED:
    case actions.REQUEST_TYPES_WORKFLOWS_DELETE_REJECTED:
    case actions.REQUEST_TYPES_WORKFLOWS_PUT_REJECTED:
    case actions.REQUEST_TYPES_WORKFLOWS_SHOW_REJECTED:
    case actions.REQUEST_TYPES_WORKFLOWS_POST_REJECTED:
    case actions.REQUEST_TYPES_WORKFLOWS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
