import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

export type PayrunConfigurationType<T> = {
  id: number
  name: string
  value: T
}

type InitStateType = {
  payroll_reflection_send: PayrunConfigurationType<'splitted' | 'separated'> | null
  payroll_vacation_send: PayrunConfigurationType<'splitted' | 'separated'> | null
  payroll_appraisal_send: PayrunConfigurationType<'splitted' | 'separated'> | null
  attendance_review_required: boolean
  vacation_review_required: boolean
  final_review_required: boolean
  fetching: boolean
  loading: boolean
  errMsg?: string
}

const initialState: InitStateType = {
  payroll_reflection_send: null,
  payroll_vacation_send: null,
  payroll_appraisal_send: null,
  attendance_review_required: false,
  vacation_review_required: false,
  final_review_required: false,
  fetching: false,
  loading: false,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ATTENDANCE_CALCULATION_UPDATE_PENDING:
    case actions.FINAL_SETTLEMENT_CALCULATION_UPDATE_PENDING:
    case actions.VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING:
    case actions.FINAL_SETTLEMENT_CALCULATION_LOAD_PENDING:
    case actions.VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING:
    case actions.ATTENDANCE_CALCULATION_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ATTENDANCE_CALCULATION_LOAD_FULFILLED: {
      return {
        ...state,
        attendance_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED: {
      return {
        ...state,
        vacation_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.FINAL_SETTLEMENT_CALCULATION_LOAD_FULFILLED: {
      return {
        ...state,
        final_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.ATTENDANCE_CALCULATION_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        attendance_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        vacation_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.FINAL_SETTLEMENT_CALCULATION_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        final_review_required: payload.data.data.payrun_configuration.value,
        fetching: false,
      }
    }
    case actions.FINAL_SETTLEMENT_CALCULATION_LOAD_REJECTED:
    case actions.VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED:
    case actions.ATTENDANCE_CALCULATION_LOAD_REJECTED:
    case actions.FINAL_SETTLEMENT_CALCULATION_UPDATE_REJECTED:
    case actions.VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED:
    case actions.ATTENDANCE_CALCULATION_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
