import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Spacer, Flex } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import RadioWithContent from 'containers/public/Onboarding/components/RadioWithContent'
import {
  onboardingApplyingLeavesNgativeLoad,
  onboardingApplyingLeavesUpdate,
} from 'redux/leavesOnboarding/ApplyingLeaves/actionCreators'
import CloneOrgDetail from 'containers/public/Onboarding/components/CloneOrgDetail'
import { onboardingAnnualLeaveEntitlementLoad } from 'redux/leavesOnboarding/countingDays/actionCreators'
import { updateCloneLeaveSettings } from 'redux/leavesOnboarding/annualLeaveEntitlements/actionCreators'
import I18n from 'translations/i18n'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import EntitlementAutoConvert from './EntitlementAutoConvert'

type OnboardingApplyingLeavesTypes = {
  apply_in_negative: {
    is_applicable?: boolean
  }
  fetching: boolean
}

const ApplyingLeaves = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { apply_in_negative, fetching } = useSelector(
    ({ onboardingApplyingLeaves }: { onboardingApplyingLeaves: OnboardingApplyingLeavesTypes }) =>
      onboardingApplyingLeaves,
  )
  const fetchingLeaveSettings = useSelector((s) => s.onboardingAnnualLeaveEntitlements.fetching)

  const balance = apply_in_negative?.is_applicable ? 'full_balance' : 'accumulative_balance'

  const handleUpdate = (value: { is_applicable: boolean; name: string }): void => {
    dispatch(onboardingApplyingLeavesUpdate(value.name, value)).then(() => {
      dispatch(onboardingApplyingLeavesNgativeLoad())
    })
  }

  const handleCloneLeaveSettings = (): void => {
    dispatch(updateCloneLeaveSettings('starting_balance')).then(() => {
      dispatch(onboardingAnnualLeaveEntitlementLoad())
      dispatch(onboardingApplyingLeavesNgativeLoad())
    })
  }

  useTrackingSteps({ step_name: 'leaves_setup', level_name: 'starting_balance' })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Flex justifyBetween>
        <Typography
          variant="heading"
          text={I18n.t('starting_balance')}
        />
        <CloneOrgDetail
          fetching={fetchingLeaveSettings}
          handleClick={handleCloneLeaveSettings}
        />
      </Flex>

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('starting_balance_description')}
      />

      <Spacer height="32px" />
      {/* @ts-expect-error Need to RadioWithContent.js to TypeScript */}
      <RadioWithContent
        options={[
          {
            label: I18n.t('full_balance'),
            description: I18n.t('full_balance_description'),
            tip: I18n.t('full_balance_description_tip'),
            value: 'full_balance',
          },
          {
            label: I18n.t('accumulative_balance'),
            description: I18n.t('accumulative_balance_description'),
            tip: I18n.t('accumulative_balance_description_tip'),
            value: 'accumulative_balance',
          },
        ]}
        disabled={fetching}
        value={balance}
        handleChange={(status: string): void => {
          const data = {
            name: 'can_apply_in_negative',
            is_applicable: status === 'full_balance',
          }
          handleUpdate(data)
        }}
      />
      <Spacer height="32px" />
      <EntitlementAutoConvert />
    </StepTemplate>
  )
}

export default ApplyingLeaves
