import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const TextParagraph = ({ label, weight = 'light' }) => (
  <Typography
    text={label}
    variant={weight === 'bold' ? 'body-new/semibold' : 'body-new/regular'}
  />
)

TextParagraph.propTypes = {
  label: PropTypes.string,
  weight: PropTypes.string,
}

export default TextParagraph
