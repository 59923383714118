export const INTEGRATION_FOODICS_SESSIONS = 'INTEGRATION_FOODICS_SESSIONS'
export const INTEGRATION_FOODICS_SESSIONS_PENDING = 'INTEGRATION_FOODICS_SESSIONS_PENDING'
export const INTEGRATION_FOODICS_SESSIONS_FULFILLED = 'INTEGRATION_FOODICS_SESSIONS_FULFILLED'
export const INTEGRATION_FOODICS_SESSIONS_REJECTED = 'INTEGRATION_FOODICS_SESSIONS_REJECTED'

export const INTEGRATION_FOODICS_USERS_LOAD = 'INTEGRATION_FOODICS_USERS_LOAD'
export const INTEGRATION_FOODICS_USERS_LOAD_PENDING = 'INTEGRATION_FOODICS_USERS_LOAD_PENDING'
export const INTEGRATION_FOODICS_USERS_LOAD_FULFILLED = 'INTEGRATION_FOODICS_USERS_LOAD_FULFILLED'
export const INTEGRATION_FOODICS_USERS_LOAD_REJECTED = 'INTEGRATION_FOODICS_USERS_LOAD_REJECTED'

export const INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD = 'INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD'
export const INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_PENDING =
  'INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_PENDING'
export const INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_FULFILLED =
  'INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_FULFILLED'
export const INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_REJECTED =
  'INTEGRATION_FOODICS_USERS_EMPLOYEES_LOAD_REJECTED'

export const INTEGRATION_FOODICS_USERS_SYNC = 'INTEGRATION_FOODICS_USERS_SYNC'
export const INTEGRATION_FOODICS_USERS_SYNC_PENDING = 'INTEGRATION_FOODICS_USERS_SYNC_PENDING'
export const INTEGRATION_FOODICS_USERS_SYNC_FULFILLED = 'INTEGRATION_FOODICS_USERS_SYNC_FULFILLED'
export const INTEGRATION_FOODICS_USERS_SYNC_REJECTED = 'INTEGRATION_FOODICS_USERS_SYNC_REJECTED'

export const ORGANIZATIONS_FOODICS_INTEGRATED = 'ORGANIZATIONS_FOODICS_INTEGRATED'
export const ORGANIZATIONS_FOODICS_INTEGRATED_PENDING = 'ORGANIZATIONS_FOODICS_INTEGRATED_PENDING'
export const ORGANIZATIONS_FOODICS_INTEGRATED_FULFILLED =
  'ORGANIZATIONS_FOODICS_INTEGRATED_FULFILLED'
export const ORGANIZATIONS_FOODICS_INTEGRATED_REJECTED = 'ORGANIZATIONS_FOODICS_INTEGRATED_REJECTED'

export const INTEGRATIONFOODICS_USERS_MAPPING_UPDATE = 'INTEGRATIONFOODICS_USERS_MAPPING_UPDATE'
export const INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_PENDING =
  'INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_PENDING'
export const INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_FULFILLED =
  'INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_FULFILLED'
export const INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_REJECTED =
  'INTEGRATIONFOODICS_USERS_MAPPING_UPDATE_REJECTED'

export const INTEGRATION_FOODICS_SESSIONS_DELETE = 'INTEGRATION_FOODICS_SESSIONS_DELETE'
export const INTEGRATION_FOODICS_SESSIONS_DELETE_PENDING =
  'INTEGRATION_FOODICS_SESSIONS_DELETE_PENDING'
export const INTEGRATION_FOODICS_SESSIONS_DELETE_FULFILLED =
  'INTEGRATION_FOODICS_SESSIONS_DELETE_FULFILLED'
export const INTEGRATION_FOODICS_SESSIONS_DELETE_REJECTED =
  'INTEGRATION_FOODICS_SESSIONS_DELETE_REJECTED'

export const FOODICS_UPDATING_DATA = 'FOODICS_UPDATING_DATA'
