import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords, updateOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'

type EmployeeFileType = {
  id?: number | string
  hideDelete?: boolean
  name?: string
  name_ar?: string
  with_expiry_date?: boolean
}

type DocumentTypes = {
  document_type_i18n: string
  document_type: string
  count: number
}

type StatType = {
  employment_file_types: EmployeeFileType[]
  document_types: DocumentTypes[]
  editing:
    | {
        id: string | number
        New: boolean
      }
    | Record<string, unknown>
  errMsg: null | string
  succMsg: null | string
  fetching: boolean
  error?: null
}

const DEFAULT_STATE: StatType = {
  employment_file_types: [],
  document_types: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}
type RequestsState = typeof DEFAULT_STATE

type ActionType = Action & {
  id: string
}

let changes = null
export default function employmentTypeReducer(
  state = DEFAULT_STATE,
  action: ActionType,
): RequestsState {
  const { payload } = action
  let employmentTypes = []
  let newRecord = {}
  let updatedRecord: { id: string } = {
    id: '',
  }
  switch (action.type) {
    case 'EMPLOYMENT_DOCUMENT_TYPES_LOAD_PENDING':
    case 'DOCUMENT_GROUPED_TYPES_PENDING':
    case 'EMPLOYMENT_DOCUMENT_TYPE_CREATE_PENDING':
    case 'EMPLOYMENT_DOCUMENT_TYPE_UPDATE_PENDING':
    case 'EMPLOYMENT_DOCUMENT_TYPE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'EMPLOYMENT_DOCUMENT_TYPES_LOAD_REJECTED':
    case 'DOCUMENT_GROUPED_TYPES_REJECTED':
    case 'EMPLOYMENT_DOCUMENT_TYPE_CREATE_REJECTED':
    case 'EMPLOYMENT_DOCUMENT_TYPE_UPDATE_REJECTED':
    case 'EMPLOYMENT_DOCUMENT_TYPE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYMENT_DOCUMENT_TYPES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYMENT_DOCUMENT_TYPE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employmentTypes = [...state.employment_file_types]
      newRecord = payload.data.data.employment_file_type
      changes = {
        employment_file_types: [...employmentTypes, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYMENT_DOCUMENT_TYPE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employmentTypes = [...state.employment_file_types]
      updatedRecord = payload.data.data.employment_file_type
      changes = {
        employment_file_types: updateOneListOfRecords(employmentTypes, updatedRecord) ?? [],
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'EMPLOYMENT_DOCUMENT_TYPE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      employmentTypes = [...state.employment_file_types]
      return {
        ...state,
        employment_file_types: removeOneListOfRecords(employmentTypes, payload.id) ?? [],
        error: null,
      }
    case 'DOCUMENT_GROUPED_TYPES_FULFILLED':
      return {
        ...state,
        document_types: payload.data.data,
        fetching: false,
        error: null,
      }

    case 'EMPLOYMENT_DOCUMENT_TYPE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'EMPLOYMENT_DOCUMENT_TYPE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
