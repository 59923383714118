import { Button, Flex, Modal } from '@jisr-hr/ds'
import I18n from 'translations/i18n'

type NotificationsGuideProps = {
  guideContent: JSX.Element
  open: boolean
  onClose: () => void
}

const NotificationsGuide = ({
  open,
  onClose,
  guideContent,
}: NotificationsGuideProps): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
    disableCloseOut
    size="large"
    header={{
      title: I18n.t('how_to_receive_notifications', { app_name: I18n.t('jisr_translation_name') }),
      withClose: true,
    }}
  >
    <>
      {guideContent}
      <Flex
        justifyEnd
        style={{ gap: 8, paddingTop: 16 }}
      >
        <Button
          size="medium"
          variant="outlined"
          color="neutral"
          label={I18n.t('close')}
          onClick={onClose}
        />
        <Button
          size="medium"
          label={I18n.t('finish')}
          trailingIcon="check"
          onClick={onClose}
          testId="finish"
        />
      </Flex>
    </>
  </Modal>
)

export default NotificationsGuide
