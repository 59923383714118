import { Fragment, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from '@jisr-hr/design-system/dist/assets/icons/Icons12px/Back-Arrow.svg'
import i18n from 'translations/i18n'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'

const { language } = i18n

type breadcumbProps = {
  backPath?: string
  forceGoBack?: boolean
  list: Array<unknown>
  locationState?: {
    pathname: string
    state: Record<string, unknown>
  }
}

const Breadcumb = ({ list, locationState, backPath, forceGoBack }: breadcumbProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore goBack is not profided in H.location
  const { push, location, goBack } = useHistory()

  const locData = {
    pathname: locationState?.pathname || '/performance/cycles/review',
    state: locationState?.state || {},
  }

  const handleBackOnClick = (): void => {
    const mainPath = location.pathname.split('/') || []

    if (mainPath.includes('new') || mainPath.includes('update') || forceGoBack) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return goBack()
    }

    if (locationState?.pathname) {
      return push(locData)
    }

    if (backPath) {
      return push(backPath)
    }

    mainPath.pop()
    const pathname = mainPath.join('/')
    return push(pathname)
  }

  return (
    <Flex itemsCenter>
      {list.length > 1 && (
        <>
          <BackIcon
            style={{
              height: 17,
              width: 17,
              cursor: 'pointer',
              transform: language === 'ar' && 'scale(-1)',
            }}
            onClick={handleBackOnClick}
          />
          <Spacer width={20} />
        </>
      )}
      <Typography
        text={list.map((label, i) => (
          <Fragment key={i}>
            {i > 0 && <span style={{ margin: '0 8px' }}>/</span>}
            {label}
          </Fragment>
        ))}
        variant="heading"
        textColor="color/fg/bold"
      />
    </Flex>
  )
}

export default memo(Breadcumb)
