import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { initialValues } from '../helper'

export const FiltersContext = React.createContext()
export const useFilters = () => React.useContext(FiltersContext)

const FiltersProvider = ({ children }) => {
  const [filter, setFilter] = React.useState(initialValues)
  const handleFilter = (filterFor, filter_id, extraData) => {
    const isBoolValue = [
      'is_gosi_linked',
      'is_data_correct',
      'is_member_linked',
      'is_eligible_to_enroll',
      'duration',
      'creation_date',
      'reporting_date',
      'date_period',
      'date_monthly_period',
      'year_data',
      'period_of_time',
    ].includes(filterFor)
    if (isBoolValue) {
      setFilter({
        ...filter,
        [filterFor]: filter_id,
      })
    } else if (!isEmpty(extraData?.ids)) {
      const extraLocalState = filter[extraData.key] || []
      const filteredExtraLocalState = extraLocalState?.filter((i) => !extraData.ids.includes(i))
      const LocalState = filter[filterFor]
      const filteredLocalState = LocalState?.filter((i) => i !== filter_id) || []

      setFilter({
        ...filter,
        [extraData.key]: [...filteredExtraLocalState, ...extraData.ids],
        [filterFor]: [...filteredLocalState, !LocalState.includes(filter_id) && filter_id].filter(
          Boolean,
        ),
      })
    } else {
      const localState = filter[filterFor]
      const filterLocalState =
        (!isBoolValue ? localState?.filter((i) => i !== filter_id) : []) ?? []
      // const insertKey = groupingFilter[filterFor]?.insert_ids_keys;
      // const localStateParent = filter[insertKey];
      // const filterLocalStateParent = localStateParent?.filter(i => i !== filter_id);

      if (extraData?.isMulti) {
        const allIds = extraData.children.filter((ls) => {
          return !filterLocalState.includes(ls) && ls !== filter_id
        })
        const newList = [...allIds, ...filterLocalState]
        setFilter({
          ...filter,
          [filterFor]: [...newList, !localState?.includes(filter_id) && filter_id].filter(Boolean),
        })
      } else {
        setFilter({
          ...filter,
          [filterFor]: [...filterLocalState, !localState?.includes(filter_id) && filter_id].filter(
            Boolean,
          ),
        })
      }
    }

    // if (!_.includes(localState, filter_id)) {
    //   if (groupingFilter.filters.includes(filterFor)) {
    //     setFilter({
    //       ...filter,
    //       [insertKey]: [
    //         ...filterLocalStateParent,
    //         !localStateParent.includes(filter_id) && filter_id,
    //       ].filter(Boolean),
    //     });
    //   }
    // }
  }

  return (
    <FiltersContext.Provider
      value={{
        filter,
        handleFilter,
        setFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

FiltersProvider.propTypes = {
  children: PropTypes.node,
}

export default FiltersProvider
