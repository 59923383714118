export const REQUEST_TYPES_LOAD = 'REQUEST_TYPES_LOAD'
export const REQUEST_TYPES_LOAD_PENDING = 'REQUEST_TYPES_LOAD_PENDING'
export const REQUEST_TYPES_LOAD_FULFILLED = 'REQUEST_TYPES_LOAD_FULFILLED'
export const REQUEST_TYPES_LOAD_REJECTED = 'REQUEST_TYPES_LOAD_REJECTED'

export const REQUEST_TYPES_SHOW = 'REQUEST_TYPES_SHOW'
export const REQUEST_TYPES_SHOW_PENDING = 'REQUEST_TYPES_SHOW_PENDING'
export const REQUEST_TYPES_SHOW_FULFILLED = 'REQUEST_TYPES_SHOW_FULFILLED'
export const REQUEST_TYPES_SHOW_REJECTED = 'REQUEST_TYPES_SHOW_REJECTED'

export const REQUEST_TYPES_PUT = 'REQUEST_TYPES_PUT'
export const REQUEST_TYPES_PUT_PENDING = 'REQUEST_TYPES_PUT_PENDING'
export const REQUEST_TYPES_PUT_FULFILLED = 'REQUEST_TYPES_PUT_FULFILLED'
export const REQUEST_TYPES_PUT_REJECTED = 'REQUEST_TYPES_PUT_REJECTED'

export const REQUEST_TYPES_DELETE = 'REQUEST_TYPES_DELETE'
export const REQUEST_TYPES_DELETE_PENDING = 'REQUEST_TYPES_DELETE_PENDING'
export const REQUEST_TYPES_DELETE_FULFILLED = 'REQUEST_TYPES_DELETE_FULFILLED'
export const REQUEST_TYPES_DELETE_REJECTED = 'REQUEST_TYPES_DELETE_REJECTED'

export const REQUEST_TYPES_POST = 'REQUEST_TYPES_POST'
export const REQUEST_TYPES_POST_PENDING = 'REQUEST_TYPES_POST_PENDING'
export const REQUEST_TYPES_POST_FULFILLED = 'REQUEST_TYPES_POST_FULFILLED'
export const REQUEST_TYPES_POST_REJECTED = 'REQUEST_TYPES_POST_REJECTED'
