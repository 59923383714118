import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { format } from 'utils/date'

import DSIcon from 'components/DSIcon'

import CorrectionBadge from './CorrectionBadge'
import CorrectionText from './CorrectionText'
import { punchIcons, punchTypes } from './helper'
import Styles from './ProposedCorrection.module.css'

const FingerprintDetails = ({ log }) => {
  const handleInfo = () => {
    const createdBy = log?.manual_log_info?.created_by
    const createdAt = format(new Date(log?.manual_log_info?.created_at), 'dd MMM yyyy hh:mm a')

    if (log?.reporting_method === 'biometric')
      return `${I18n.t('device_info')}: ${log?.source_type} - ${log?.source_detail?.serial_number}`
    if (log?.reporting_method === 'geofencing')
      return `${I18n.t('device_info')}: ${log?.source_detail?.device_brand} / ${
        log?.source_detail?.operating_system
      }`
    if (createdBy) return `${I18n.t('by')}: ${createdBy}, ${I18n.t('At')}: ${createdAt}`

    return true
  }

  const info = handleInfo()

  return (
    <Flex
      flexCol
      className={Styles.details}
    >
      <Flex>
        {log?.reporting_method_i18n && (
          <CorrectionBadge
            label={log?.reporting_method_i18n}
            type="gray"
            icon={punchIcons[log?.reporting_method]}
          />
        )}

        {log?.punch_type_i18n && (
          <CorrectionBadge
            label={log?.punch_type_i18n}
            type={punchTypes[log?.punch_type]}
          />
        )}

        {log?.is_next_day && <CorrectionBadge label={I18n.t('next_day')} />}
      </Flex>
      {info && <CorrectionText label={info} />}
      {log?.location?.name && (
        <CorrectionText
          label={log?.location?.name}
          icon={<DSIcon name="marker-pin-01" />}
        />
      )}
    </Flex>
  )
}

FingerprintDetails.propTypes = {
  log: PropTypes.shape(),
}

export default FingerprintDetails
