export const OUTSOURCING_EMPLOYEES_REPORT_LOAD = 'OUTSOURCING_EMPLOYEES_LOAD'
export const OUTSOURCING_EMPLOYEES_LOAD_PENDING = 'OUTSOURCING_EMPLOYEES_LOAD_PENDING'
export const OUTSOURCING_EMPLOYEES_LOAD_FULFILLED = 'OUTSOURCING_EMPLOYEES_LOAD_FULFILLED'
export const OUTSOURCING_EMPLOYEES_LOAD_REJECTED = 'OUTSOURCING_EMPLOYEES_LOAD_REJECTED'

export const OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD = 'OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD'
export const OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_FULFILLED =
  'OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_FULFILLED'
export const OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_REJECTED =
  'OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_REJECTED'

export const OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD = 'OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD'
export const OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_PENDING =
  'OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_PENDING'
export const OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_FULFILLED =
  'OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_FULFILLED'
export const OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_REJECTED =
  'OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_REJECTED'
