import { hasPermission } from 'components/global/HelperFunctions'

export const checkPermission = (currentEmployee, targetEmployeeId) => {
  const canViewPersonalAndOrg = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_personal_and_organization_information,
  )

  const canViewSalary = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_salary_and_financial_information,
  )

  const canViewLone = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_loans,
  )
  const canViewEmployeeCost = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_employee_cost_to_company,
  )
  const canViewPayrollTable = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_details.view_payroll_table,
  )

  const canViewPloyeePerformance = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_employee_performance,
  )

  const canViewAttendance = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_attendance,
  )

  const canViewLeaves = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_leaves,
  )

  const canViewDocuments = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_documents,
  )

  const canViewAssets = hasPermission(
    currentEmployee,
    targetEmployeeId,
    currentEmployee.permission_scopes.view_assets,
  )

  if (
    canViewLone ||
    canViewAssets ||
    canViewLeaves ||
    canViewSalary ||
    canViewDocuments ||
    canViewAttendance ||
    canViewPayrollTable ||
    canViewPersonalAndOrg ||
    canViewPloyeePerformance ||
    canViewEmployeeCost
  ) {
    return true
  }
  return false
}
