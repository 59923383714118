import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { PayrunApproverType, PayrunRequestType, PayrunReviewStep } from './types'

type InitialStateType = {
  fetching: boolean
  payrun_review_steps: PayrunReviewStep[]
  loading: boolean
  employees: PayrunApproverType[]
  payrun_review_requests: PayrunRequestType[]
}

const initialState: InitialStateType = {
  fetching: false,
  loading: false,
  employees: [],
  payrun_review_steps: [],
  payrun_review_requests: [],
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYRUN_APPROVALS_CREATE_REQUEST_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.PAYRUN_APPROVALS_LIST_LOAD_PENDING:
    case actions.PAYRUN_APPROVALS_STEPS_LOAD_PENDING:
    case actions.PAYRUN_APPROVALS_LIST_REQUESTS_PENDING:
    case actions.PAYRUN_APPROVALS_REJECT_PENDING:
    case actions.PAYRUN_REOPEN_PENDING:
    case actions.PAYRUN_APPROVALS_APPROVE_PENDING:
    case actions.PAYROLL_APPROVALS_STEPS_UPDATE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYRUN_APPROVALS_APPROVE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_REJECT_FULFILLED:
    case actions.PAYRUN_REOPEN_FULFILLED: {
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYROLL_APPROVALS_STEPS_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_STEPS_LOAD_FULFILLED: {
      return {
        ...state,
        payrun_review_steps: action.payload.data.data?.payrun_review_steps || [],
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_LIST_LOAD_FULFILLED: {
      return {
        ...state,
        employees: action.payload.data.data?.employees || [],
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_LIST_REQUESTS_FULFILLED: {
      return {
        ...state,
        payrun_review_requests: action.payload.data.data?.payrun_review_requests || [],
        fetching: false,
      }
    }

    case actions.PAYRUN_APPROVALS_CREATE_REQUEST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }

    case actions.PAYRUN_APPROVALS_CREATE_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }

    case actions.PAYRUN_APPROVALS_LIST_LOAD_REJECTED:
    case actions.PAYROLL_APPROVALS_STEPS_UPDATE_REJECTED:
    case actions.PAYRUN_APPROVALS_LIST_REQUESTS_REJECTED:
    case actions.PAYRUN_APPROVALS_REJECT_REJECTED:
    case actions.PAYRUN_APPROVALS_APPROVE_REJECTED:
    case actions.PAYRUN_REOPEN_REJECTED:
    case actions.PAYRUN_APPROVALS_STEPS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
