import React from 'react'
import PropTypes from 'prop-types'
import Cell from './components/Cell'

const ThCell = ({ data, sortable, sortActive, sortDir, onClick, subCell, th }) => (
  <Cell
    sortable={sortable}
    sortActive={sortActive}
    sortDir={sortDir}
    onClick={() => sortable && onClick(data.key)}
    icon={data.icon}
    colspan={data.colspan}
    rowspan={data.rowspan}
    subCell={subCell}
    th={th}
  >
    {data.label}
  </Cell>
)

ThCell.propTypes = {
  data: PropTypes.shape(),
  sortable: PropTypes.bool,
  onClick: PropTypes.func,
  subCell: PropTypes.bool,
  th: PropTypes.bool,
  sortActive: PropTypes.bool,
  sortDir: PropTypes.string,
}

export default ThCell
