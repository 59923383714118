import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Table } from 'components/global/atoms'
import I18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'

const PayrollTemplate = ({
  title,
  description,
  amount,
  type,
  additionalInfo,
  headers = [],
  records = [],
}) => (
  <div className="payroll--single-remark_box">
    <div className="payroll--single-remark_header">
      <Typography
        text={title}
        variant="title-1"
      />
      {description && <p className="description">{description}</p>}
      {amount && (
        <p
          data-private
          className={classNames('amount', type)}
        >
          {amount}
        </p>
      )}
      {!!additionalInfo && (
        <div className="remark-summary">
          {Object.keys(additionalInfo).map(
            (dataKey, i) =>
              !!additionalInfo[dataKey] && (
                <p key={i}>
                  {`${I18n.t(dataKey)}: `}
                  <b>{additionalInfo[dataKey]}</b>
                </p>
              ),
          )}
        </div>
      )}
    </div>
    {headers.length !== 0 && (
      <Table
        headers={headers.map((item) => I18n.t(item))}
        grayDesign
        height="50px"
        maxHeight="234px"
        headerStart
        contentStart
      >
        {records}
      </Table>
    )}
  </div>
)

PayrollTemplate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.string,
  type: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  records: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape(),
    PropTypes.arrayOf([PropTypes.node]),
  ]),
  additionalInfo: PropTypes.shape(),
}

export default PayrollTemplate
