const Report = {
  // A
  adding_custom_report: 'Adding Custom Report',
  add_new_filter: 'Add New Filter',
  assets_report: 'Assets report',
  area: 'Area',
  after: 'After',
  attendance_logs: 'Attendance Logs Report',
  attendance_details: 'Attendance details',
  attendance_report: 'Attendance report',
  attendance_fields: 'Attendance Fields',
  attendance_reports: 'Attendance reports',
  attendance_details_report: 'Attendance Details Report',
  attendance_tracking_report: 'Attendance Tracking Report',
  absences_report: 'Absences Report',
  add_information: 'Add Information',
  add_delay: 'Add delay',
  ajeer_fees_payable: 'Ajeer Fees Payable',
  add_working_hours: 'Add working hours',
  add_break_time: 'Add break time',
  add_extra_time: 'Add extra time',
  add_excused_time: 'Add excuse time',
  add_approved_overtime: 'Add approved overtime',
  add_user_shift: 'Add user shift',
  add_employee_shift: 'Add employee shift',
  all_reports: '<< All Reports',
  All_reports: 'All Reports',
  attendance_summary: 'Attendance summary',
  attendance_summary_report: 'Attendance Summary Report',
  add_manual_additions_deductions: 'Add Manual Additions/Deductions',
  attendance_reports_here: 'You can check all attendance reports here',
  add_company_details: 'Add company details',
  add_customized_reports: 'New Custom Report',
  add_edit_report_columns: 'Add, Edit report columns',
  approved_overtime_report: 'Approved Overtime report',
  attendance_summary_columns: 'Attendance Summary Columns',
  ajeer_company: 'Ajeer Company',
  asset_details: 'Asset Details',
  assets_allocation_report: 'Assets Allocation Report',
  allocated: 'Allocated',
  accruals_journalVacation_end_of_service: 'Accruals journal (Vacation & End Of Service)',
  ajeer_fees_journal: 'Ajeer fees journal',
  // B
  before: 'Before',
  basic_information: 'Basic Information',
  bank_name: 'Bank Name',
  back: 'Back',
  download_bulk_pdf: 'Download Bulk PDF',
  download_bulk_pdf_att: 'Download Bulk PDF',
  download_report: 'Download Report',
  download: 'Download',
  download_xls: 'Download XLS',
  download_pdf: 'Download PDF',
  download_started: 'Download has started',
  business_trip_report: 'Business trip report',
  base_details: 'Base Details',
  base_salary: 'Basic Salary',
  biometric: 'Biometric',
  businiess_trip_days: 'Businiess Trip Days',
  bulk_employees_attendance_details_report: 'Bulk Employees Attendance Details',
  // C
  choose_from_templates: 'Choose from templates (Optional)',
  calendar_view: 'Calendar View',
  cancelled: 'Cancelled',
  canceled: 'Canceled',
  cleared: 'Cleared',

  cost_center: 'Cost Center',
  count: 'Count',
  column_selection: 'Column Selection',
  custom_reports: 'Custom Reports',
  customize_columns: 'Customize columns',
  choose_an_employee: 'Choose an employee to share with',
  completed_time: 'Completed time',
  classification_columns: 'Classification Columns',
  close: 'Close',
  there_is_no_coordinates: 'There is no coordinates on the map for this record',
  // D
  delays: 'Delays',
  delete_report: 'Delete report',
  department: 'Department',
  delays_report: 'Delays Report',
  deductions: 'Deductions',
  document_types: 'Document Types',
  data_concern: 'Data concern',
  date_range: 'Date range',
  document_id: 'Document ID',
  document_name: 'Document Name',
  document_owner: 'Document Owner',
  download_payroll_report: 'Download Payroll Report',
  download_post: 'Download & Post',
  day_and_date: 'Day and Date',
  data_is_too_large: 'Data is too large to preview',
  // E
  editing_custom_report: 'Editing Custom Report',
  end_of_service_report: 'End of service report',
  export_netsuite: 'Export Netsuite',
  export_xero: 'Export Xero',
  employee_without_cr: 'Employee Without CR',
  export_quickbooks: 'Export Quickbooks',
  export_account_system: 'Export to accountant system',
  employees_reports: 'Employees Reports',
  employee_master: 'Employee master',
  employee_master_report: 'Employee master report',
  employee_name_ar: 'Employee name (Arabic)',
  employee_name_en: 'Employee name (English)',
  employee_records: 'Employee records',
  expiring_documents: 'Expiring Documents',
  expiring_documents_report: 'Expiring documents report',
  employee_records_report: 'Employee records report',
  employee_selection: 'Employee Selection',
  earnings: 'Earnings',
  excuse: 'Excuse',
  expired: 'Expired',
  expiry_status: 'Expiry Status',
  employee_info: 'Employee Info',
  employee_code: 'Employee Code',
  export_sheet: 'Export Sheet',
  export_as_pdf: 'Export {{title}} As .PDF',
  export_remarks_as_pdf: 'Export Remarks As .PDF',
  export_remarks_as_excel: 'Export Remarks As .EXCEL',
  export_as_excel: 'Export {{title}} As .EXCEL',
  employee_loan_report: 'Employee loan report',
  employees_reports_here: 'You can check all employees reports here',
  email_sent_successfully: 'Email sent successfully',
  employee_attendance_details_report: 'Employee Attendance Details Report',
  excuse_early_departure: 'Excuse early departure',
  excuse_late_arrival: 'Excuse Late Arrival',
  everyone: 'Everyone',
  employees_performance: 'Employees performance report',
  employee_review_cycles_report: 'Employee review cycles report',
  event_type: 'event to action',

  // F
  financial_details: 'Financial details',
  final_settlement_report: 'Final settlement report',
  finance_reports: 'Finance reports',
  finance_report: 'Finance report',
  finance_payroll: 'Finance payroll',
  finance_reports_here: 'You can check all finance reports here',
  from_to: 'From-To',
  file_status: 'File status',
  full_day_excuses: 'Full Day Excuses',
  finalizing_report: 'Finalizing Report',
  family_details_report: 'Family Details Report',
  header_family_details_report: 'Employees Family Details Report',

  // G
  greater_than: 'Greater than or equal to',
  gosi_report: 'Gosi report',
  group_by: 'Group by',
  generating: 'Generating...',
  generate: 'Generate',
  gosi: 'GOSI',
  generating_report: 'Generating report',
  generate_report: 'Generate report',
  generater_payroll_report: 'Generate payroll report',
  generater_eos_report: 'Generate End of Service Report',
  generater_ticket_provision_report: 'Generate Ticket Provision Report',
  generater_attendance_details_report: 'Generate Attendance Details Report',
  generater_outsourcing_employees_report: 'Generate Outsourcing Employees Report',
  generater_outsourcing_employees_report_description:
    'The report shows the out of payroll financial transaction for Ajeer employees',
  generater_employee_master_report: 'Generate Employee Master Report',
  generate_gosi_report: 'Generate GOSI Report',
  generated_on: 'Generated on',
  generated_by: 'Generated by',
  generated_by_custom_reports: 'by',
  generate_updated_report: 'Regenerate the Report',
  generate_employee_attendance_details_report: 'Generate Employee Attendance Details Report',
  geofencing: 'Geofencing',
  geofencing_method: 'Geofencing method',
  GOSI_journal: 'GOSI journal',
  generate_revenue_report: 'Generate Revenue Report',
  generate_revenue_report_details:
    "This report shows the total revenue for your employees as per employee's closed orders in your Foodics account",
  generate_employees_performance_report: 'Generate Employees Performance Report',

  // H
  head_count: 'Headcount',
  headcount: 'Headcount',
  headcount_report: 'Headcount report',
  // I
  info_change_request: 'Info change request',
  inactive: 'Inactive',
  in_progress: 'In Progress',
  imported_by: 'Imported by',
  iqama: 'Iqama',
  id_constants: 'ID',
  // J
  joining_date_from: 'Joining date from',
  joining_date_to: 'Joining date to',
  from_offboarded: 'Offboarding from',
  download_journal_file: 'Download Journal File',
  post_journal_to_netsuite: 'Post Journal to netsuite',
  journal_sheet: 'Journal sheet',

  post_journal_to_quickbooks: 'Post Journal to quickbooks',
  post_journal_to_xero: 'Post Journal to xero',
  post_journal_to_qoyod: 'Post Journal to qoyod',
  post_journal_to_oracle_fusion: 'Post Journal to Oracle Fusion',
  post_journal_to_microsoft: 'Post Journal to Microsoft',
  post_journal_to_odoo: 'Post Journal to Odoo',
  post_journal_to_finops: 'Post Journal to MS Dynamics Finance & Operation',
  post_journal_to_zoho_books: ' Post Journal to Zoho Books',
  post_journal_to_sap: ' Post Journal to Sap Business One',
  // K
  // L
  loans_report: 'Loans report',
  location: 'Location',
  leaves_reports: 'Leaves reports',
  leave_details: 'Leave Details',
  less_than: 'Less than or equal to',
  leaves_report: 'Leaves report',
  leave_balance_report: 'Leave balance report',
  loan: 'Loan',
  letter: 'Letter',
  leaving_early: 'Leaving Early',
  leaves_reports_here: 'You can check all leaves reports here',
  last_month: 'Last month',
  last_quarter: 'Last quarter',
  last_year: 'Last year',
  log_day: 'Log Day',
  log_time: 'Log Time',
  log_location: 'Log Location',
  // M
  make_precise_selection: 'Make percise selection by adding employee filters',
  market_place: 'Marketplace',
  // N
  net_eos: 'Net EOS',
  net_salary: 'Net Salary',
  not_expired: 'Not expired',
  netsuit: 'Netsuite',
  never_generated: 'Never Generated',
  no_ticket_plicy_msg: 'You need to define the ticket policy',
  no_data_founded: 'No data found',
  no_records: 'No records',
  // O
  organization_details: 'Organization details',
  offboarded_to: 'Offboarding to',
  out_of_payroll_transactions: 'Out of payroll transactions',
  out_of_payroll_types: 'Out of payroll types',
  offline_journals: 'Offline Journals',
  out_of_payroll_journals: 'Out of payroll journal',
  outsourcing_employees: 'Outsourcing employees',
  outsourcing_employees_low_tiers: 'Outsourcing Employees',
  outsourcing_employees_report: 'Outsourcing employees report',
  out_of_payroll_report: 'Out of payroll transactions report',
  off_days: 'Off Days',
  off_cycle_payruns: 'Off-Cycle PayRuns',
  other_reports: 'OTHER REPORTS',
  journal: 'Journal',
  // P
  passed: 'Passed',
  personal_details: 'Personal Details',
  parent_department: 'Parent Department',
  payment_type: 'Payment Type',
  payroll: 'Payroll',
  PAYROLL: 'PAYROLL',
  payroll_report: 'Payroll report',
  payslip: 'Payslip',
  pay_slip: 'Payslip',
  payslip_report: 'Payslip',
  payslip_report_conf: 'Payslip report will be sent to the employee after',
  payroll_group: 'Payroll group',
  payrun_status: 'Payrun status',
  payroll_group_report: 'Payroll group report',
  payroll_is_not_selected: 'Payroll is not selected',
  presence: 'Presence',
  please_select_at_least_one_employee: 'Please select at least one employee',
  please_select_pdf_or_excel: 'Please select PDF or Excel',
  provision_reports: 'Provision Reports',
  please_go_to: 'Please go to:',
  payment_employees_without_cr: ' Employees Not Assigned To Any CR',
  passport: 'Passport',
  payroll_journal: 'Payroll journal',
  post_to: 'Post to',
  payroll_journals: 'Payroll journal',
  payroll_journals_file_name: 'Payroll journal',
  performance_reports: 'Performance Reports',

  // Q
  quickbooks: 'Quickbooks',
  // R
  report: 'report',
  report_summary: 'Report Summary',
  report_name_in_english: 'Report name in English',
  report_name_in_arabic: 'Report name in Arabic (Optional)',
  report_description: 'Report Description (Optional)',
  report_basic_information: 'Report Basic Information',
  reports_family_details: 'Family Details',
  reports: 'Reports',
  requests: 'Requests',
  request_report: 'Request report',
  request_status: 'Request Status',
  request_type: 'Request Type',
  rejected: 'Rejected',
  request_info: 'Request info',
  report_created_on: 'Report created on',
  reopen_payroll_report_note:
    'This action will cancel the confirmation of this payroll, delete all the approval history and open the payroll as draft again, are you sure you want to continue ?',
  report_provides_overview:
    'This report provides an overview of payroll costs for a given date range.',
  reporting_method: 'Reporting method',
  record_location: 'Record Location',
  regenerated_by: 'Regenerated by',
  revenue_report: 'Revenue Report',
  // S
  status_export_history: 'Status',
  select_user: 'Select User',
  save_this_as_template: 'Save this report as template',
  search_employee: 'Search Employee',
  select_employee: 'Select Employee',
  suggest_new_column: 'Suggest new column and filter',
  segment_by: 'Segment by',
  sponsor: 'Sponsor',
  show_report: 'Show Report',
  share_settings: 'Share settings',
  share_with: 'Share with',
  send_to_someone: 'Send To Someone',
  sendـtoـmyـemail: 'Send to my email',
  shift_working_hours: 'Shift working hours',
  select_employee_by_type: 'Select employee by type',
  // T
  the_name_already_defined: 'The name has been already defined',
  time_sheet: 'Timesheet',
  timesheet: 'Timesheet',
  timesheet_report: 'Timesheet report',
  total_deductions: 'Total Deductions',
  total_net_salaries_low_tiers: 'Total Net Salaries',
  total_earnings: 'Total Earnings',
  total_salaries: 'Total Salaries',
  total_additions: 'Total Additions',
  total_salaries_low_tires: 'Total net salaries “Earnings”',
  this_month: 'This month',
  this_quarter: 'This quarter',
  this_year: 'This year',
  this_report_end_of_service:
    'This report provides an overview of end of service for a given date range.',
  this_report_ticket_provision:
    'This report provides an overview of ticket provision for a given date range.',
  total_liabilities: 'Total Liabilities',
  ticket_provision_report: 'Ticket Provision Report',
  this_report_attendance_details:
    'This report provides an overview of employees Attendance details for a given date range.',
  total_cost: 'Total cost',
  total_fees: 'Total fees',
  total_employees: 'Total employees',
  total_employees_low_tires: 'Total employees',
  total_per_diem_hint: 'The total per diem calculation will be based on {{value}} configuration.',
  this_report_shows_the_attendance_details:
    'This report shows the attendance details of the selected employee',
  total_logs: 'Total Logs',
  there_no_offboarded_employees_so_far: 'There is no offboarded employees so far',
  the_report_generating_too_large: "The report you're generating is too large for us to preview.",
  payroll_transaction_status: 'Transaction Status',
  // U
  urgent_loan: 'Urgent loan',
  uploaded_time: 'Uploaded time',
  under_probation: 'Under Probation',
  // V
  advance_vacation: 'Advance vacation',
  // W
  which_period_of_time: 'Which period of time?',
  which_view: 'Which View ?',
  // X
  // Y
  you_can_change_the_order: 'You can change the selected column order',
  you_can_export_to_view:
    'You can export it to view it, or refine the criteria to reduce the size of the data.',
  // Z
  generater_attendance_summary_report: 'Generate attendance summary report',
  this_report_shows_the_attendance_status:
    'this report shows the attendance status per employee for a given data range',
  which_employee: 'Which employee',

  generater_approved_overtime_report: 'Generate approved overtime report',
  this_report_approved_overtime:
    'This report shows the approved overtime per employee for a given data range',
  generate_attendance_logs_report: 'Generate attendance logs report',
  generate_attendance_tracking_report: 'Generate attendance tracking report',
  generate_out_of_payroll_report: 'Generate out of payroll transactions report',
  this_report_shows_the_attendance_logs:
    'This report shows the attendance logs per employee for a given data range',
  this_report_shows_the_attendance_tracking:
    'This report shows the attendance tracking per employee for a given data range',
  generater_attendance_report: 'Generater attendance report',
  this_report_shows_the_total_attendance_calculations:
    'This report shows the total attendance calculations per employee for a given data range',
  this_report_shows_the_out_of_paroll_transaction:
    'This report shows the out of payroll transactions per employee for a given data range',
  this_report_shows_the_off_cycle_payrun_transaction:
    'This report shows the Off cycle Payrun transactions per employee for a given data range',
}

export default Report
