import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const initialState = {
  fetching: false,
  downloading: false,
  invoices: [],
  subscription: {},
  banner: {},
  showAlert: {
    banner: true,
    billing: true,
  },
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.INVOICE_DOWNLOAD_PENDING:
      return {
        ...state,
        downloading: true,
      }

    case actions.EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_PENDING:
    case actions.BILLING_INFO_LOAD_PENDING:
    case actions.BILLING_INFO_PUT_PENDING:
    case actions.INVOICES_LOAD_PENDING:
    case actions.SUBSCRIPTIONS_LOAD_PENDING:
    case actions.ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SUBSCRIPTIONS_LOAD_FULFILLED:
      return {
        ...state,
        subscription: payload.data.data?.subscription,
        fetching: false,
      }

    case actions.INVOICE_DOWNLOAD_FULFILLED:
    case actions.INVOICE_DOWNLOAD_REJECTED:
      return {
        ...state,
        downloading: false,
      }

    case actions.BILLING_INFO_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.BILLING_INFO_LOAD_FULFILLED:
    case actions.INVOICES_LOAD_FULFILLED:
    case actions.ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_FULFILLED:
      return {
        ...state,
        banner: payload.data.data || {},
        fetching: false,
      }

    case actions.CLOSE_ALERT:
      return {
        ...state,
        showAlert: payload,
      }

    case actions.EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_REJECTED:
    case actions.BILLING_INFO_LOAD_REJECTED:
    case actions.BILLING_INFO_PUT_REJECTED:
    case actions.SUBSCRIPTIONS_LOAD_REJECTED:
    case actions.INVOICES_LOAD_REJECTED:
    case actions.ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
