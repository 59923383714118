import React from 'react'
import { Flex, Typography } from '@jisr-hr/ds'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import Signpost from '../../../ds/Signpost'

const RequestedBy = ({ name = '', jobtitle = '' }) => (
  <Signpost>
    <Flex style={{ gap: 4 }}>
      <Typography
        text={I18n.t('requested_by')}
        variant="body-new/regular"
      />
      <Typography
        text={`${name} ${jobtitle ?? ''}`}
        variant="body-new/regular"
        textColor="color/fg/brand/default"
      />
    </Flex>
  </Signpost>
)

RequestedBy.propTypes = {
  name: PropTypes.string,
  jobtitle: PropTypes.string,
}

export default RequestedBy
