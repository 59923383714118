import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type TimesheetStatusProps = {
  title: string
  filterKey: string
  filters: string[]
}

const TimesheetStatus = (props: TimesheetStatusProps) => {
  const { timesheet_status } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const optionList = returnBadgeList(timesheet_status)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        timesheet_status: [],
      })
    } else {
      handleFilter('timesheet_status', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.timesheet_status)) {
      setSelectedIds(filter.timesheet_status)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.timesheet_status])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name"
      selectedIds={selectedIds}
      componentType="badges"
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default TimesheetStatus
