import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { CancellationRequestsCreateData } from 'components/authorised/requestForms/CanceltionRequest/CancelApprovesRequest'
import { OtherRequestInfo } from 'containers/authorised/employee/EmployeeDetails/Tabs/EmployeeLeaves/EmployeeLeaves'
import * as actions from './actions'

export type ResumptionDataType = {
  leave_request_id?: string
  reason: string
  return_date: string
}

type ReqData = OtherRequestInfo & {
  is_early_return: boolean
  leave_request_id: string
}

type RejectData = {
  comments: string
}

type SuperAdminCancellationData = {
  request_id: number
  request_type: string
}

const { sessionStorage } = window

export function requestsReset(): Action {
  return {
    type: actions.REQUESTS_RESET,
  }
}

export function requestsAllReset(): Action {
  return {
    type: actions.REQUESTS_ALL_RESET,
  }
}

export function requestsLoad(params: Record<string, unknown>): Action {
  let url

  const section = sessionStorage.getItem('requests_section')

  if (section) {
    url = `/${API_VERSION_TWO}/${section}/requests/approvals`
  } else {
    url = `/${API_VERSION_TWO}/dashboard/requests`
  }

  return {
    type: actions.REQUESTS_LOAD,
    payload: handleAPI(url, params || {}, 'GET'),
  }
}

export function approveRequest(
  req_id: number,
  params: Record<string, unknown>,
  isGlobal: boolean,
): Action {
  let url

  if (isGlobal) {
    url = `/${API_VERSION_TWO}/requests/approvals/${req_id}/approve`
  } else {
    url = `/${API_VERSION_TWO}/dashboard/requests/${req_id}/approve`
  }

  return {
    type: actions.REQUESTS_APPROVE_REQUEST,
    payload: handleAPI(url, params || {}, 'POST'),
  }
}

export const clearApprovedReqResponse = (): Action => {
  return {
    type: actions.CLEAR_APPROVED_REQUEST_RESPONSE,
  }
}

export function rejectRequest(req_id: number, data: RejectData, isGlobal: boolean): Action {
  let url

  if (isGlobal) {
    url = `/${API_VERSION_TWO}/requests/approvals/${req_id}/reject`
  } else {
    url = `/${API_VERSION_TWO}/dashboard/requests/${req_id}/reject`
  }

  return {
    type: actions.REQUESTS_REJECT_REQUEST,
    payload: handleAPI(url, {}, 'POST', { request: data }),
  }
}

export function cancelRequest(
  req_id: number,
  params: Record<string, unknown>,
  isGlobal: boolean,
): Action {
  let url

  if (isGlobal) {
    url = `/${API_VERSION_TWO}/requests/approvals/${req_id}/cancel`
  } else {
    url = `/${API_VERSION_TWO}/dashboard/requests/${req_id}/cancel`
  }

  return {
    type: 'REQUESTS_CANCEL_REQUEST',
    payload: handleAPI(url, params || {}, 'POST'),
  }
}

// canceling for Super Admin
export function superAdminCancelingRequest(
  employee_id: number,
  data: SuperAdminCancellationData,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/requests/cancel`

  return {
    type: 'SUPER_ADMIN_CANCELING_REQUEST',
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export const resumptionPost = (employee_id: number, data: ReqData): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_resumption_requests`

  return {
    type: 'RESUMPTION_REQUEST',
    payload: handleAPI(url, {}, 'POST', { leave_resumption_request: data }),
  }
}

export const resumptionUpdate = (
  employee_id: number,
  data: ResumptionDataType,
  reqId: number,
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/leave_resumption_requests/${reqId}`

  return {
    type: 'UPDATE_RESUMPTION_REQUEST',
    payload: handleAPI(url, {}, 'PUT', { leave_resumption_request: data }),
  }
}

export function cancellationRequestsCreate(
  employee_id: number,
  data: CancellationRequestsCreateData,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/cancellation_requests`

  return {
    type: actions.CANCELLATION_REQUESTS_CREATE,
    payload: handleAPI(url, {}, 'POST', { cancellation_request: data }),
  }
}

export function requestsCardOptions(
  options: Record<string, unknown>,
  request?: Record<string, unknown>,
): Action {
  return {
    type: actions.REQUESTS_CARD_OPTIONS,
    payload: {
      card_options: options,
      request,
    },
  }
}

export function updateRequestsParams(params: Record<string, unknown>): Action {
  return {
    type: actions.UPDATE_REQUESTS_PARAMS,
    payload: { params },
  }
}

export function requestsNavigation(params: Record<string, unknown>): Action {
  return {
    type: actions.REQUESTS_NAVIGATION,
    payload: params,
  }
}

export function addNewCommentRequest(comment: string): Action {
  return {
    type: actions.ADD_NEW_COMMENT_REQUEST,
    payload: { comment },
  }
}

// section tasks
export function requestsApprovalsLoad(params: Record<string, unknown>, sec?: string): Action {
  const section = sec || sessionStorage.getItem('requests_section')
  const url = `/${API_VERSION_TWO}/${section}/requests/approvals`

  return {
    type: actions.REQUESTS_APPROVALS_LOAD,
    payload: handleAPI(url, params || {}, 'GET'),
  }
}

export function singleRequestLoad(
  employee_id: number,
  type: string,
  req_id: number,
  params: Record<string, unknown>,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/${type}/${req_id}`
  return {
    type: actions.SINGLE_REQUEST_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function requestCancel(req_id: number, params: Record<string, unknown>): Action {
  const url = `/${API_VERSION_TWO}/requests/approvals/${req_id}/cancel`

  return {
    type: 'REQUESTS_CANCEL_REQUEST',
    payload: handleAPI(url, params || {}, 'POST'),
  }
}

// :::::  Bulk Request Actions  ::::: //
export function approveRequestForBulkActions(req_id: number): Action {
  const url = `/${API_VERSION_TWO}/requests/approvals/${req_id}/approve`

  return {
    type: actions.BULK_REQUESTS_APPROVE_SINGLE,
    payload: handleAPI(url, {}, 'POST'),
  }
}

export function rejectBulkRequests(
  approval_ids: Array<number>,
  data: Record<string, unknown>,
): Action {
  const url = `/${API_VERSION_TWO}/requests/approvals/bulk_reject`

  return {
    type: actions.BULK_REQUESTS_REJECT_BULK,
    payload: handleAPI(url, {}, 'PUT', { approval_ids, ...data }),
  }
}

export const returnForCorrection = (
  employee_id: number,
  request_id: number,
  data: {
    reason: string
  },
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/requests/return_for_correction`

  return {
    type: actions.RETURN_FOR_CORRECTION,
    payload: handleAPI(url, { request_id }, 'PUT', data),
  }
}
