import { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { Flex, Spacer, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { ReactComponent as QuestionIcon } from 'assets/figma-icons/question-circle.svg'
import { ReactComponent as CloseIcon } from 'assets/figma-icons/cancel.svg'
import {
  showTrainingVideos,
  trainingVideosReset,
} from 'redux/organizationOnborading/trainingVideos/actionCreators'

import { OnboardingContext } from '../../context'
import PlayVideo from './PlayVideo'
import styles from './styles.module.css'

type TInfoSet = {
  imgSrc?: string
  title?: string
  description?: string
  noteInline?: boolean
  note?: string
  custom?: JSX.Element
}

type TSidbar = {
  infoSet: TInfoSet[]
  videoKey?: string
  hideFullContent?: boolean
}

const Sidebar = ({ infoSet, videoKey, hideFullContent }: TSidbar): JSX.Element | null => {
  const dispatch = useDispatch()
  const { showSidebar, setShowSidebar } = useContext(OnboardingContext)
  const { training_video } = useSelector(({ trainingVideos }) => trainingVideos)

  useEffect(() => {
    dispatch(trainingVideosReset())
    if (videoKey) {
      dispatch(showTrainingVideos(videoKey))
    }
  }, [videoKey])

  if (hideFullContent && !showSidebar) {
    return null
  }
  return (
    <>
      {showSidebar && (
        <div className={styles.sidebarContainer}>
          <div className={styles.sidebarHeader}>
            <Flex
              itemsCenter
              style={{ gap: 16 }}
            >
              <div className={styles.infoIconContainer}>
                <QuestionIcon className={styles.infoIcon} />
              </div>
              <Typography
                variant="title-1"
                text={I18n.t('help')}
              />
            </Flex>
            <div
              className={styles.closeIconContainer}
              onClick={(): void => setShowSidebar((prev) => !prev)}
            >
              <CloseIcon className={styles.closeIcon} />
            </div>
          </div>
          {videoKey && training_video.id && (
            <>
              <div style={{ width: '88%' }}>
                <Typography
                  variant="body-new/medium"
                  text={I18n.t('help_videos')}
                />
              </div>
              <Spacer height={16} />
              <div
                style={{ padding: '24px 16px' }}
                className={styles.infoCard}
              >
                <PlayVideo path={videoKey} />
              </div>
            </>
          )}

          {showSidebar &&
            infoSet.map((info, i) => (
              <div
                key={i}
                className={styles.infoCard}
              >
                {info.imgSrc && (
                  <img
                    src={info.imgSrc}
                    alt={info.title}
                    className={styles.infoCardImg}
                  />
                )}
                <div className={styles.infoCardContent}>
                  <Typography
                    variant="interface/medium/m"
                    className={styles.title}
                    text={info.title}
                  />

                  <Typography
                    variant="body-new/regular"
                    className={classNames(styles.description, {
                      [styles.noteInline]: info.noteInline,
                    })}
                    text={info.description}
                  />

                  {info.note && (
                    <Typography
                      variant="interface/default/sm"
                      style={{ color: 'var(--color-base-colors-red-800' }}
                      className={classNames(info.noteInline && styles.noteInline)}
                      text={info.note}
                    />
                  )}
                  <Spacer height={16} />
                  {info.custom && info.custom}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default Sidebar
