/** **************
 *  announcementReducer
 /*************** */
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'

const DEFAULT_STATE = {
  fetching: false,
  announcement: {},
}

let changes = null
export default function announcementsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'ANNOUNCEMENTS_CREATE_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'ANNOUNCEMENTS_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        announcement: payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'ANNOUNCEMENTS_CREATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
