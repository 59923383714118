import { handleResponseErr } from 'utils/apiHelperUtils'
import i18n from 'translations/i18n'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

export type MudadStepsType = {
  register_establishment: 'completed' | 'pending'
  verify_employees: 'completed' | 'pending'
  authorize_mudad: 'completed' | 'pending'
  confirmation: 'completed' | 'pending'
  owner_verification: 'completed' | 'pending'
  current_step:
    | 'register_establishment'
    | 'verify_employees'
    | 'authorize_mudad'
    | 'owner_verification'
    | 'confirmation'
}

export type VerificationEmployeeTyp = {
  avatar_thumb: string
  code: string
  full_name_i18n: string
  id: number
  is_deleted: boolean
  name_i18n: string
}

export type MudadSignatoriesType = {
  employee: VerificationEmployeeTyp | null
  status_body: {
    status: string
  }
  error_message: string | null
  updated_at: string
  identification_number: string
  id: number
  mudad_establishment_id: number
  status: 'verified' | 'verification_inprogress' | 'error'
}

export type AuthorizedEmployeeType = {
  id: number
  mudad_bank_id: number
  mudad_signatory_id: number
  error_message: string | null
  status_body: {
    status: string
  }
  status: 'authorization_inprogress' | 'authorized' | 'error'
  updated_at: string
  identification_number: string
  code: string
  employee_id: number
  name_i18n: string
  full_name_i18n: string
  avatar: string
  remarks: { mobile_number: number } | null
  bank_info: {
    bank_name: string
    bank_logo: string
    iban: string
    id: number
  } | null
}

export type ItemPriceType = {
  deleted_at: string | null
  id: number
  item_price_id: string
  item_price_type: string
  name: string
  name_ar: string
  price: string
  status: string
}

type InitStateType = {
  flow_steps: MudadStepsType | null
  fetching: boolean
  loading: boolean
  authorizing: boolean
  establishing: boolean
  fetching_admins: boolean
  deleting: boolean

  establishment_err: string
  item_price: ItemPriceType | null
  authorized_employees: AuthorizedEmployeeType[]
  verification_employees: VerificationEmployeeTyp[]
  mudad_signatories: MudadSignatoriesType[]
  establishment_owner: {
    identification_number: number | null
    show_owner_popup: boolean
  } | null
}

const initialState: InitStateType = {
  loading: false,
  fetching: false,
  authorizing: false,
  establishing: false,
  fetching_admins: false,
  deleting: false,

  establishment_err: '',
  mudad_signatories: [],
  verification_employees: [],
  authorized_employees: [],
  flow_steps: null,
  item_price: null,
  establishment_owner: null,
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.MUDAD_FLOW_STEPS_LOAD_PENDING:
    case actions.MUDAD_SIGNATORIES_CREATE_PENDING:
    case actions.MUDAD_SIGNATORIES_LOAD_PENDING:
    case actions.ITEM_PRICE_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.MUDAD_FLOW_STEPS_LOAD_FULFILLED: {
      return {
        ...state,
        flow_steps: payload.data.data.mudad_flow_steps,
        fetching: false,
      }
    }
    case actions.ITEM_PRICE_LOAD_FULFILLED: {
      return {
        ...state,
        item_price: payload.data.data.item_price,
        fetching: false,
      }
    }

    case actions.MUDAD_FLOW_STEPS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.MUDAD_REGISTER_ESTABLISHMENT_PENDING: {
      return {
        ...state,
        establishing: true,
      }
    }
    case actions.MUDAD_REGISTER_ESTABLISHMENT_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        establishing: false,
      }
    }

    case actions.MUDAD_REGISTER_ESTABLISHMENT_REJECTED: {
      return {
        ...state,
        establishing: false,
        establishment_err: handleResponseErr(payload),
      }
    }

    case actions.MUDAD_SIGNATORIES_LOAD_FULFILLED: {
      return {
        ...state,
        mudad_signatories: payload.data.data.mudad_signatories,
        establishment_owner: payload.data.data.establishment_owner,
        fetching: false,
      }
    }

    case actions.MUDAD_SIGNATORIES_CREATE_FULFILLED: {
      const mudad_signatory = payload.data.data.mudad_signatory as MudadSignatoriesType
      return {
        ...state,
        mudad_signatories: [...state.mudad_signatories, mudad_signatory],
        verification_employees: state.verification_employees.filter(
          (it) => it.id !== mudad_signatory.employee?.id,
        ),
        fetching: false,
      }
    }
    case actions.MUDAD_SIGNATORIES_CREATE_REJECTED:
    case actions.MUDAD_SIGNATORIES_LOAD_REJECTED:
    case actions.ITEM_PRICE_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.MUDAD_SIGNATORIES_VERIFY_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.MUDAD_SIGNATORIES_VERIFY_FULFILLED:
    case actions.MUDAD_SIGNATORIES_VERIFY_REJECTED:
      return {
        ...state,
        loading: false,
      }

    case actions.MUDAD_ALL_VERIFY_EMPLOYEES_PENDING:
    case actions.MUDAD_ALL_AUTHORIZED_EMPLOYEES_PENDING:
      return {
        ...state,
        fetching_admins: true,
      }

    case actions.MUDAD_ALL_VERIFY_EMPLOYEES_REJECTED:
    case actions.MUDAD_ALL_AUTHORIZED_EMPLOYEES_REJECTED:
      return {
        ...state,
        fetching_admins: false,
      }
    case actions.MUDAD_ALL_VERIFY_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        verification_employees: payload.data.data.employees,
        fetching_admins: false,
      }
    }
    case actions.MUDAD_ALL_AUTHORIZED_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        authorized_employees: payload.data.data.authorized_employees,
        fetching_admins: false,
      }
    }
    case actions.MUDAD_SIGNATORIES_DELETE_PENDING:
    case actions.MUDAD_AUTHORIZER_DELETE_PENDING:
    case actions.MUDAD_REVOKE_ESTABLISHMENT_PENDING: {
      return {
        ...state,
        deleting: true,
      }
    }

    case actions.MUDAD_SIGNATORIES_DELETE_FULFILLED: {
      const { id } = payload
      return {
        ...state,
        mudad_signatories: state.mudad_signatories.filter((it) => it.id !== id),
        deleting: false,
      }
    }

    case actions.MUDAD_AUTHORIZER_DELETE_FULFILLED: {
      const { id } = payload
      return {
        ...state,
        authorized_employees: state.authorized_employees.filter((it) => it.id !== id),
        deleting: false,
      }
    }
    case actions.MUDAD_REVOKE_ESTABLISHMENT_FULFILLED: {
      Toastr.success(i18n.t('establishment_deleted_successfully'))
      return {
        ...state,
        deleting: false,
      }
    }

    case actions.MUDAD_REVOKE_ESTABLISHMENT_REJECTED:
    case actions.MUDAD_AUTHORIZER_DELETE_REJECTED:
    case actions.MUDAD_SIGNATORIES_DELETE_REJECTED: {
      return {
        ...state,
        deleting: false,
      }
    }
    case actions.MUDAD_AUTHORIZED_PMS_CREATE_PENDING:
    case actions.MUDAD_AUTHORIZED_PMS_VERIFY_PENDING:
      return {
        ...state,
        authorizing: true,
      }
    case actions.MUDAD_AUTHORIZED_PMS_CREATE_FULFILLED:
    case actions.MUDAD_AUTHORIZED_PMS_CREATE_REJECTED:
    case actions.MUDAD_AUTHORIZED_PMS_VERIFY_FULFILLED:
    case actions.MUDAD_AUTHORIZED_PMS_VERIFY_REJECTED:
      return {
        ...state,
        authorizing: false,
      }
    case actions.RESET_MUDAD_STATE: {
      return {
        ...initialState,
        item_price: state.item_price,
      }
    }

    default:
      return state
  }
}
