const Payroll = {
  _face1: 'Very Dissatisfied',
  _face2: 'Dissatisfied',
  _face3: 'Neutral',
  _face4: 'Satisfied',
  _face5: 'Very satisfied',

  //
  add_task: 'Add task',
  add_a_comment: 'Add a comment',
  add_step: 'Add Step',
  add_bank_account: 'Add bank account',
  addition: 'Addition',
  additions: 'Additions',
  exclude: 'Exclude',
  authorized_employees: 'Authorized Employees',
  add_addition_deduction_in_bulk: 'Add addition &amp; deduction bulk',
  amount: 'Amount',
  amount_sar: 'Amount (񥀠)',
  apprised_this_month: 'Appraised this month',
  areas: 'Areas',
  'Payroll Accountant setup note':
    '{{ name }} will be able to view the payroll, and download/send the payroll journal entry.',
  'Payroll Admin setup note':
    '{{ name }} will be able to view, edit the payroll, and update the payroll settings.',
  'Payroll Executor setup note':
    '{{ name }} will be able to edit the payroll, and send it for  review. ',
  'Payroll Viewer setup note':
    '{{ name }} will be able to view payroll without the ability to add additions/deductions.',
  are_you_sure_send_attendance_manager:
    'Are you sure you want to send a reminder to the attendance manager?',
  show_details: 'Show details',
  alinma_bank: 'Alinma Bank',
  additional_info: 'Additional info',
  add_new_addition_or_deduction_type: '+Add new addition or deduction type',
  additions__eductions: 'Additions & Deductions',
  addition_amount: 'Addition amount',
  deduction_amount: 'Deduction amount',
  deduction_amount_gosi: 'Deduction amount',

  approval_form_note: 'This selection is one time only but you can always change settings from the',
  add_new_type: 'Add new type',
  approvals: 'Approvals',
  employees_have_wrong_english_name: 'Employees have wrong English names',
  ajeer: 'Outsourcing provider costs',
  agency_fees: 'Agency fees',
  agency_fees_low_tiers: 'Agency fees',
  ajeer_company_name: 'Ajeer company name',
  add_remarks: 'Add Remarks',
  add_remark: 'Add Remark',
  absence_addition_rate: 'Absence retroactive addition rate',
  absence_addition_base: 'Absence retroactive addition base',
  absence_deduction_rate: 'Absence deduction rate',
  absence_deduction_base: 'Absence deduction base',
  absence_deduction_retro_rate: 'Absence deduction rate - Retro',
  absence_deduction_retro_base: 'Absence deduction base - Retro',
  absent_day: 'Absent day',
  added_by: 'Added by',
  actual_resumption_date: 'Actual resumption date',
  activate_mudad_now: 'Activate Mudad Now',
  attendance_status_dismissed_title: 'Attendance for {{date}} is dismissed ',
  attendance_status_dismissed_discprition_reminder:
    'To complete the payroll, attendance manager is required to approve the attendance.',
  attendance_status_dismissed_sup_title: 'Overtime, delay, and all the',
  attendance_status_dismissed_discprition:
    'will be dismissed. The employees will get just basic salaries and allowances.',
  attendance_status_approved_title: 'Attendance for {{date}} is approved',
  attendance_status_approved_discprition: 'Run payroll to see the overtime, delay, and all',
  attendance_status_not_yet_approved_title: 'Attendance for {{date}} is not yet approved',
  attendance_status_not_yet_approved_discprition:
    'To complete the payroll, attendance manager is required to approve the attendance.',
  attendance_status_pending_reminder:
    'Proceeding without attendance approval will dismiss overtime, delay, and all the Time-Related Transactions.',
  attendance_status_pending_title: 'Overtime, delay, and all the',
  attendance_status_pending_discprition:
    'will not reflect on payroll sheet. But you can process the payroll.',
  add_new_transaction: 'Add New Transaction',
  add_transaction: 'Add Transaction',
  approve: 'Approve',
  approved: 'Approved',
  addition_type: 'Addition type',
  attendance_manager_is_required_to_approve_attendance_to_complete_clearance:
    'To complete the clearance, attendance manager is required to approve the attendance.',
  add_working_days_salary: 'Add working days salary',
  annual_leave_details: 'Annual Leave Details',
  attendancereview: 'Attendance Review',
  annual_leave_detials: 'Annual Leave Details',
  applicable_amount: 'GOSI subscription salary',
  activity_log_pay: 'Activity Log',
  attendance_sheet_review: 'Attendance Sheet Review',
  attendance_sheet_review_tip:
    'The attendance sheet affects the payroll calculation once it is confirmed.',
  active_pay: 'Active',
  any_modifications:
    'Any modifications made in previous unclosed payrolls will be discarded. Skipped payroll periods will not be available in Payment Processing or Reports',
  add_approvers: 'Add Reviewer',
  add_workflow_approvals: 'Add Workflow approvals',
  add_at_least_one_cr_details:
    'You need to add the legal details for one commercial registration at least',
  add_at_least_one_cr_details_note:
    'Add commercial registration no., MOL no., and GOSI subscription no.',
  automate_your_payroll: 'Automate your payroll and pay employees in Minutes',
  activate_payroll_payment_method: 'Activate Your Payroll Payment Method ',
  activate_payroll_payment_method_desc:
    'Use our {{app_name}} payment service, integrated with WPS directly, for hassle-free salary payments. Ensure your organization pays employees’ salaries on time.',
  add_your_payroll_bank_account:
    'Add your payroll bank account, & link it with Mudad or directly with SAB for hassle-free payment processing. Streamline your payments effortlessly!',
  add_your_payroll_pay_bank_account:
    'Activate {{app_name}} payment service to integrate with WPS directly, for hassle-free salary payments',
  access_full_course: 'Access Full Course',
  add_employees_payment_methods: 'Add Employees Payment Methods',
  attendance_deductions: 'Attendance Deductions',
  add_hings_that_Jjsr_doesnt_automate:
    'Add the things that {{app_name}} doesn`t automate. Loans Deductions, GOSI Deductions, Attendance Deductions, and Unpaid Leaves Deductions {{app_name}} already automates.',
  add_collaborators: 'Add Collaborators',
  add_collaborators_desc:
    'Collaborate seamlessly with your team. Choose users from the list who can work with you to prepare and process the payroll.',
  add_salary_info: 'Add Salary Info',
  app_also_auto_calculate: '{{app_name}} helps you control and calculate the following',
  any_other_additions_or_deductions: 'Any other additions or deductions',
  absence_deduction: 'Absence Deduction',
  all_issues: 'All issues ({{number}})',
  accepted_files_format: ' Accepted file types is xls, xlsx ',
  // B
  bank_report: 'Bank report',
  business_unit: 'Business unit',
  bulk_upload: 'Bulk upload',
  by_payment_method: 'By Payment Method',
  back: 'Back',
  back_pay: 'Back',
  bad_experience_payroll:
    'We are sorry for the bad experience, tell us what we can improve in Payroll',
  become_fimliar_jisr: 'Become familiar with {{app_name}} payroll',
  business_trips_payment: 'Business Trips Payment',
  back_to_payroll_landing: 'Back to Payroll Landing',
  business_trip_periderm: 'Business Trip Per-diems',

  // C
  cut_off_date_for_last_month: 'Cut off date for last month',
  calculate_status: 'Calculate',
  configure: 'Configure',
  change_period_date: 'Change period date',
  check_the_final_settlement: 'Check the final settlement',
  congras_reminder_sent_note:
    'Great! <br /> You’ve successfully sent a reminder to the attendance manager',
  comment: 'Comment',
  continue: 'Continue',
  continue_pay: 'Continue',
  create_approval_settings: 'Create approval settings',
  confirm_next: 'Confirm & Next',
  confirm_payroll: 'Confirm payroll',
  compensation_sar: 'Compensation (SAR)',
  click_here_to_file_upload: 'Click here to file upload',
  click_here: 'Click here',
  confirmed: 'Confirmed',
  create_approval_cycle: 'Create approval cycle',
  continu_without_approval_cycle: 'Continue without approval cycle',
  choose_a_bank: 'Choose a bank',
  compliance_system_status: 'Compliance System (WPS) Status',
  customize_column: 'Customize column',
  customize_payroll_table_column: 'Table Columns',
  confirm_payroll_note_reminder:
    'Make sure you’ve properly reviewed the payroll sheet. You will not be able to make any changes once it’s sent for Approval Cycle',
  confirm_payroll_note:
    '<b>Note:</b> Upon confirmation of the payroll and the completion of approvals within the series of approvals for the payroll, the changes made cannot be undone (Re-opening the payroll), so please ensure that all financial information is correct before performing the final confirmation.',
  confirmation_export_bank_report: 'Export Bank Report',
  confirmation_export_bank_report_secondary: 'Export sheet and send it to the bank',
  confirmation_payslip_message:
    'The Payrslips will be shared to Employees by Email in {{date}} you can',
  confirmation_payslip_message_edit_that: 'edit that',
  confirmation_quick_overiew: 'Quick overiew',
  confirmation_success_message: 'You have successfully generated the payroll for {{date}}!',
  confirmation_sync_with_accountant: 'Sync with your accountant system',
  confirmation_total_amount: 'Total amount of payroll',
  confirmation_total_employees: 'Total number of employees in the payroll',
  confirmation_your_current_accountant: 'Your current system is {{name}}',
  confirmation_bank_report_was_sent: 'Bank report was sent to your email',
  confirmation_check_your_inbox: 'Check your inbox or junk folder',
  congratulations: 'Congratulations !',
  comparing_payroll: 'Comparing to {{date}}',
  couldnt_receive_the_bank_file: 'Couldn’t receive the bank file?',
  cutOff_period_message_tip:
    'is the pre-determined length of time to group the financial transactions,cutOff period is determined by the cut off date of previous month + 1 till current month`s cut off date',
  cutOff_period_message_tip_permission:
    "You don't have permission to change period date. Please contact your administrator",
  cut_off_period: 'Cut Off Period',
  created_at: 'Created at',
  changes_history: 'Changes history',
  cancel: 'Cancel',
  completed: 'Completed',
  closing_date_pay: 'Closing date',
  changing_the_last_working_day_note:
    'Note that changing the last working day will require you to resubmit the attendance sheet for review. Any previously submitted attendance sheet related to the this final settlement will be removed.',
  confirm_operation_cut_off_period_for:
    'Confirm the operation cut-off period for {{month}}. The Attendance Sheet will be based on this period.',
  confirm_cut_off_period: 'Confirm Cut-off Period',
  clear_search: 'Clear Search',
  commercial_registration_ids: 'Filtered by these CRs',
  configure_workflow_approvals:
    'Configure your workflow approvals as needed. Define the steps for approvals, ensuring a smooth and efficient payroll process.',
  check_issues: 'Check Issues',
  completed_pay: 'Completed',
  complete_it: 'Mark as completed',
  check_mismatches: 'Check mismatches',
  book_a_session: 'Book a session',
  customize_overtime_policy:
    'Customize overtime policy at company, department, or location level. Set monthly limits and rate.',
  control_the_calculation:
    'Control the calculation for Absence, Early Departure, Late Arrival, and Incomplete records',
  customize_your_addition_types: 'Customize Your Addition Types',
  customize_addition_types:
    'Customize addition types for your employees, such as Expense claims, commission, bonus, and more. Define them easily in a few simple steps.',
  customize_your_loan_policy:
    'Customize your loan policy, pay loan requests, schedule installments, and deduct from salaries effortlessly.',
  customize_your_business_trips:
    'Set up your business trip policy, define per diem rates, and empower your employees to submit requests seamlessly.',
  customize_your_expense_claims:
    'Activate expense claim requests and empower employees to submit expenses along with invoices seamlessly',
  customize_your_deductions_types: 'Customize Your Deductions Types',
  customize_your_additions_types: 'Customize Your Addition Types',
  confirm_and_next: 'Confirm and Next ',
  confirm_and_continue: 'Confirm and Continue ',
  confirm_the_operation: 'Confirm the operation cut-off period',
  could_not_upload_payment_file_title: "The file “{{file_name}}“ couldn't be uploaded",
  could_not_upload_payment_file_desc:
    'Only our predefined template file can be uploaded, make sure you uploaded the correct file',
  calculate_employees_payroll: 'Start Calculation',
  congrats_ending_payroll: 'Congratulations on Completing Your Payroll Settings!',
  congrats_ending_payroll_note: 'You can change these configurations from settings page',
  choose_employee: 'Choose Employee',
  choose_role: 'Choose Role',
  click_to_upload_or_drop: '<click>Click here to Upload File</click> or drag and drop',
  // D
  days_spent_the_payroll: 'Days spent on the payroll are now minutes with {{app_name}}!',
  deduction: 'Deduction',
  deductions: 'Deductions',
  detail: 'Detail',
  download_additions_deductions_sample_file: 'Download Additions/Deductions Sample File',
  Review_records_low_tiers: 'Review & Edit',
  low_tiers_rejected_records: 'Re-upload Rejected Records',
  download_bank_file: 'Download Bank File',
  download_xls: 'Download XLS',
  fees_are_higher_in_same_fund_date: 'Fees are higher in same fund date',
  submit_payment_for_n_employees: 'Submit payment for {{number}} Employees',
  download_payment_file: 'Download Payment File',
  last_uploaded_transactions: 'Last uploaded transactions',
  uploading_transaction: 'Uploading transaction',
  payroll_name_already_exist: 'Payroll name already exist',
  you_need_to_top_up: 'You need to top up {{ number }} {{ currency }}',
  how_top_up: 'How top up',
  free_first_6_months: 'Free - first 6 months',
  download_pdf: 'Download PDF',
  n_payment_service: '{{ name }} Payment Service',
  download_template_file: 'Download template file',
  download_app_template_file: 'Download {{app_name}} Template File',
  download_app_template_file_note:
    'Use this spreadsheet template to avoid difficulties in uploading your schedules.',
  download_template_file_low_des:
    'Use this spreadsheet template to avoid difficulties in uploading your schedules.',
  discard: 'Discard',
  difference: 'Difference',
  download_now: 'Download Now',
  draft: 'Draft',
  download_sample_file: 'Download sample file',
  drag__drop:
    '<strong>Drag & Drop</strong> files here<br /> or <span class="browse">Browse</span> files',
  daily_rate: 'Daily rate',
  delay_deduction_hour: 'Delay deduction rate',
  late_arrival_base: 'Delay deduction base',
  late_arrival_retro: 'Delay deduction rate - Retro',
  late_arrival_base_retro: 'Delay deduction base - Retro',
  delay_addition_rate: 'Delay addition rate',
  delay_addition_base: 'Delay addition base',
  deduction_rate: 'Deduction rate',
  direct_transfer_file: 'Direct Transfer File',
  default_fund_date: 'Default fund date',
  fund_dates: 'Fund date',
  date_ef: 'Date',
  description: 'Description',
  delete: 'Delete',
  dismiss: 'Dismiss',
  direct_bank_integration: 'Direct bank integration',
  download_WPS_file: 'Download WPS File',
  days_remaining: '{{day}} days remaining for WPS submission',
  wps_file_is_available: 'File will be available 3 working days after submission',
  days_pay: 'days',
  download_wages_template: 'Download wages file',
  download_gosi_wages: 'Download GOSI wages file',
  download_gosi_wages_note:
    'Download the wages file from GOSI website "Aamaly". Or download & fill our template directly',
  define_your_employees_payment_methods: 'Set the payment method for each employee ',
  define_how_each_employee:
    'Define how each employee will receive their payments, ex: bank transfer, cash, or international transfer.',
  check_issues_and_take_action: 'Check these issues and take actions',
  define_payroll_schedule: 'Define Payroll Schedule',
  discover_more_dots: 'Discover More...',
  donot_show_again: 'Don’t show again',
  download_addition_deduction_sheet: 'Download the additions/deductions sheet',
  download_addition_deduction_sheet_note: 'Use this file to avoid difficulties while importing',
  download_the_file: 'Download file',
  download_failed_entries: 'Download failed entries',
  // E
  edit_payslip_send_date: 'Edit Payslip Send Date',
  employee_count_total: 'Employees ({{count}} total)',
  employees_with_salary_card: 'Employees with Salary Card. ',
  employee_gosi_registration_task: 'Employee gosi registration task',
  employee_will_paid: 'Employee will be Paid',
  employee_will_included: 'Employee Included',
  employee_will_excluded: 'Employee Excluded',
  export_to_xero: 'Export to xero',
  exit_wizard: 'Exit Wizard',
  employees_list: 'Employees List',
  employees_paid_in_this_payroll: 'Employees paid in this payroll',
  employees_paid_in_previous_payroll: 'Employees paid in previous payroll',
  employees_on_annual_leave: 'Employees on annual leave',
  employee_name: 'Employee name',
  employee_has_been_suspended: 'Employee payroll has been suspended.',
  extra_cols: 'Extra Columns',
  exit_reentry_info: 'ExitRe-entryInfo',
  earnings_deductions: 'Earnings and Deductions',
  employee_details: 'Employee details',
  export_excel: 'Export excel',
  export_xls: 'Export as XLS',
  export_pdf: 'Export as PDF',
  export_as_pdf: 'Export as PDF',
  export_pdf_with_company_details: 'Export as PDF + Company Details',
  earnings: 'Earnings',
  events_notification_note:
    'There are some changes affecting your Payroll Sheet happened in the background, refresh the page to see them reflected',
  employees_included: 'Employees Included',
  employees_included_low_tiers: 'Employees Included',
  employees_excluded_low_tiers: 'Employees Excluded',
  employees_excluded: '{{num}}  Excluded',
  excluded_list: 'Excluded list',
  excluded_reason: 'Excluded reason',
  export: 'Export',
  export_settings: 'Export Settings',
  employee__id: 'EMPLOYEE NAME & ID',
  edit_employee_profile_report: 'Employee information changes Report',
  early_resumption_base: 'Early resumption base',
  early_resumption_rate: 'Early resumption rate',
  excuse_hour_rate: 'Excuse hour rate',
  effective_month: 'Effective month',
  early_resumption_days: 'early resumption days',
  employees_included_message_tip:
    'The list of employees that you are going to pay their salaries for this month',
  employees_excluded_message_tip: 'The employees list that is excluded from this payroll month',
  effective_on: 'Effective on',
  xls_format: 'XLS Format',
  employee_contribution: 'Employee contribution %',
  export_report: 'Export Report',
  edit_cut_off_period: 'Edit  the cut-off period',
  edit_workflow_approvals: 'Edit Workflow Reviewer',
  payrun_employee: 'You have {{number}} employee didn’t have a payment method',
  payruns_remarks_report: 'Payrun Remarks',
  employee_without_salary:
    'You`ve started the payroll for {{start_number}} out of {{total_number}} employees, with {{last_number}} employees currently suspended',
  employee_without_salary_title: 'You run this payroll for {{number}} from {{total}} employee',
  employee_without_salary_note:
    'To ensure payroll accuracy, please verify the salaries of the missing employees',
  employee_without_payment_title: 'You have {{number}} employees didn’t have a payment method',
  employee_without_payment_note:
    'You’ll not be able to pay them till you define the payment for each one of them',
  explore_iisr_payroll: 'Explore {{app_name}}`s Payroll with a Test Run',
  enable_attendance_sheet_reflection: 'Enable attendance sheet reflection',
  expense_claims_payment: 'Expense Claims Payment',
  easily_add_define_new_addition:
    'Easily add and define new addition types as per your unique needs',
  enable_payslip_sharing_with_employees: 'Enable Payslip Sharing with Employees',
  ensure_compliance_with_gosi: 'Ensure 100% sync with GOSI',
  employees_attendance_records:
    'Employees` attendance records, including absences and delays, will be contingent on this period [If enabled]',
  easily_manage_payments: 'Easily manage payments for employees ',
  edit_salary: 'Edit Salary Info',
  edit_cut_off_date: 'Edit Cut off date',
  employees_without_salary: 'Employees without salary',
  employees_without_salary_note: "The salary hasn't been defined for the following employees",
  employees_didnot_have_payment_method: 'Employees didn’t have a payment method',
  expense_claims: 'Expense Claims',
  employees_loans: 'Loans',
  early_out_deduction: 'Early Out Deductions',
  end_testing_payroll_alert: 'End Testing Payroll',
  end_testing_payroll_alert_desc:
    'You are about to end your testing payroll, please note that all additions & deductions you made will be removed.',
  // F
  fsv_big_text:
    'Upload the GOSI wages file and let {{app_name}} verify GOSI contributions. and check salary mismatches. Avoid possible compliance issues with GOSI and the wages protection system (WPS).',
  payroll_final_settlement: 'Final settlement',
  filter_employees: 'Filter Employees',
  fees_vat_included: 'Fees (VAT Included)',
  for_employees_who_are_registered_with_gosi: 'For employees who are registered with GOSI',
  for_employees_who_are_not_registered_with_gosi: 'For employees who are not registered with GOSI',
  for_all_of_the_employees: 'For all of the employees',
  final_settelement: 'Final Settlement',
  final_settlement_list: 'Final settlement',
  from_cut_off: 'From Cut-off',
  flight_ticket: 'Flight ticket',
  fees_changes: 'Fees changes',
  final_settlement_review_request: 'Final Settlement Review Request',
  from_pay: 'From',
  from_to_total_days: '{{from}} - {{to}} ({{total}}Days)',
  full_day: 'Full Day',
  for_seamless_payroll_run: 'For a Seamless Payroll Run, Complete a Few Configurations First.',
  facilitate_your_hr_tasks:
    'Facilitate your HR tasks, simplify accounting and GL reporting, and manage payments – all on a single, integrated platform  ',
  failed_records: 'Failed Records',
  full_report: 'Show Full Report',
  full_report_hide: 'Hide Full Report',
  full_day_payroll: 'Full Day',
  file_ready_to_download: 'File is ready for download!',
  failed_entries: 'Failed entries',
  file_is_downloading_hint:
    'File is downloading, this might take some time depending on it’s size.',
  // G
  gosi_applicable_amount: 'GOSI subscription salary',
  gosi_enable_calculation: 'Enable GOSI calculation',
  gosi_disable_calculation: 'Disable GOSI calculation',
  gosi_file_name: 'Gosi file name',
  gosi_last_update: 'GOSI Last update',
  gosi_file_sample: 'Gosi File Sample.xls',
  gosi_salary_updation_task: 'Gosi salary updation task',
  grand_total: 'Grand Total',
  generater_edit_employee_profile_report: 'Generate edit employee profile report',
  got_it: 'Got it',
  gosi_company_name: 'Gosi company name',
  gosi_company_contribution: 'Gosi company contribution',
  gosi_company_contribution_low_tires: 'Gosi company contribution',
  gosi_payable: 'GOSI Payable',
  gosi_employee_contribution_percentage: 'Gosi employee contribution percentage',
  gosi_applicable_applicable_amount: 'Gosi applicable amount',
  go_to_gosi_website: 'Go to GOSI website “Business Insurance”',
  go_to_gosi_website_note: 'Open your company account in GOSI',
  go_to_gosi_web_link: 'Go to GOSI - Business Insurance',
  gosi_success_upload_message:
    'Salary & GOSI Information for {{number_of_success}} out of {{total}} employees was matched successfully',
  gosi_issue_upload_message: '{{number}} sync issues were found, check and take actions',
  gosi_upload_files_low_tries:
    'GOSI information for {{success_number}} Out of {{total_number}} Employees synced successfully',
  get_notified_about_all_GOSI:
    'Get notified about all possible gaps, and avoid labor law & WPS issues',
  get_personalized_assistance:
    'Join our live webinars to get the information required. Book your session now through this link',
  gosi_sync: 'Compliance Checker',
  gosi_deductions: 'GOSI Deductions',
  generate_new_file: 'Generate a new file',
  // H
  how_to_export_gosi_file: 'How to Export GOSI file ?',
  hours_minutes: 'Hours/Minutes',
  have_you_already_paid_this_to_the_employee: 'Have you already paid this to the employee ?',
  how_to_get_this_gosi_info:
    'How to obtain the subscription details of an employees GOSI membership?',
  half_day: 'Half Day',
  how_to_run_payroll: 'How to Run Payroll',
  have_sabb_bank_account_check_integration_steps:
    'Have a SAB bank account? check the integration steps',
  hide_empty_columns: 'Hide Empty Columns',
  half_day_payroll: 'Half Day',
  // I
  id: 'ID',
  it_takes_some_time_to_calculate: 'It takes some time to calculate payroll details',
  importing: 'Importing...',
  i_will_do_that_later: 'I`ll do that later',
  import: 'Import',
  is_retro: 'Is Retro',
  insurance_changes: 'Insurance changes',
  it_is_payroll_time_now: 'Well done! you can start your first payroll now!',
  in_complete_records_deduction: 'InComplete Records Deduction',
  // J
  jisr_knowledge_base: '{{app_name}} Knowledge Base',
  jisr_academy: '{{app_name}} Academy',
  joined_this_month: 'Joined this month',
  joining__date: 'Joining date',
  import_gosi_file_wages_form: 'Import GOSI file - Wages Form',
  in_payroll: 'In_payroll',
  payroll_new_employees: 'New employees',
  payroll_emp_excluded: 'Excluded',
  jisr_handles_everything:
    '{{app_name}} handles everything from new joiners, attendance reflection, loan schedule deductions, GOSI, and more.',
  jisr_verifies_your_data:
    '{{app_name}} verifies your data accuracy compared with the information available in GOSI. This step helps you avoid salary mismatches, missing contributors, and more...',

  // K
  kindly_hold_on: 'Kindly hold on until I get it done for you...',
  know_more_about_the: 'Know more about the',
  know_more: 'Know more',
  // L
  last_updated: 'Last updated',
  latest_request: 'Latest request',
  leaving_date: 'Leaving date',
  last_update: 'Last update:',
  learn_more: 'Learn more',
  leave___attedance: 'Leave & Attendance',
  learn_about_jisr_payroll: 'Learn about Jisr’s payroll',
  learn_more_about_mudad_integration: 'Learn more about Mudad Integration...',
  last_month_number_of_employees_in_the_payroll: 'Last month number of employees in the payroll',
  last_month_total_amount_of_payroll: 'Last month total amount of payroll',
  last_updated_head: 'Last updated on: {{date}}',
  loans_deduction_amount: 'Loans deduction amount',
  loans_remaining_balance_before_deduction: 'Loans remaining balance before deduction',
  learn_more_about_payroll: 'Learn more about payroll',
  leave_balance_amount: 'Leave balance amount',
  less: 'less',
  // M
  missing_punch_retro_amount: 'Incomplete record retro amount',
  manual_addition_amount: 'Addition amount',
  manual_addition_count: 'Addition count',
  mark_as_paid: 'Mark as paid',
  mark_transaction_as_paid: 'Mark this transaction as paid',
  missing_punch_rate: 'Incomplete record deduction rate',
  missing_punch_base: 'Incomplete record deduction base',
  missing_punch_retro_rate: 'Incomplete record deduction rate - Retro',
  missing_punch_retro_base: 'Incomplete record deduction base - Retro',
  missing_punch_deduction_rate: 'Missing punch deduction rate',
  missing_punch_deduction_base: 'Incomplete record base',
  more_optional_information: '{{ num }} More optional information',
  maximum_deduction_exceeded:
    'Salary exceeded the limit which is 45000 SAR, the deduction amount calculation will be based on 45000 SAR',
  match_employee_information: 'Match employee`s information between {{app_name}} & GOSI',
  match_employee_information_description:
    'Upload the GOSI wages file and let {{appName}} verify GOSI contributions. and check salary mismatches. Avoid possible compliance issues with GOSI and the wages protection system (WPS).',
  more: 'more',
  make_sure_using_supported_template: 'Make sure you use the supported template file only',

  // N
  national_id_iqama_number: 'National ID / Iqama Number',
  id_iqama: 'ID / Iqama',
  number_of_employees: 'No. of employees',
  ncb_misk: 'NCB non profit',
  albilad: 'Albilad Bank',
  news: 'New',
  number_employees: '{{number}} employee',
  new_joined_employees: 'New joined employees',
  new_joined_employees_subtitle: 'The employees who joined this month, and their joining date.',
  newly_added_employees_title: '{{num}} New Employees',
  not_sure_about_format: 'Not sure about the format of the file?',
  number_of_people: 'Number of People',
  no_remarks_have_been_add: 'No remarks have been added for this employee',
  number_of_days_deducted: 'Number of days deducted',
  new_full_package: 'New full package',
  non_wps_employees: 'Not Registered In Gosi',
  no_Do_it_later: 'No. Do it later',
  no_Lets_review_again: 'No. Let is review again',
  notifications_tasks: 'Notifications & Tasks',
  new_transaction: 'New Transaction',
  no_of_days_gosi: 'No. of days',
  net_total: 'Net Total',
  not_required: 'Not Required',
  no_final_settlements_requested: 'No Final Settlements Requested',
  no_payment: 'This organization has no active payment method on any of its CRs',
  no_cr: 'There are no CRs have been defined on the legal structure of the organization',
  new_collaborator: 'New Collaborator',
  // O
  or: 'or',
  on_leave_this_month: 'On leave this month',
  on_vacation_this_month: 'On vacation this month',
  off_boarded_this_month: 'Offboarded this month',
  on_leave_this_month_description:
    'employees who are in no attendance policy and did not confirm their return from leave',
  overtime_absence_automated:
    'Here you will see the automatic additions and deductions Also you can add manual additions and deductions',
  overtime_hour_retro_single: 'Total overtime hour rate (Single) - Retro',
  overtime_hour_retro_double: 'Total overtime hour rate (Double) - Retro',
  overtime_hour_rate_single: 'Total overtime hour rate (Single)',
  overtime_hour_rate_double: 'Total overtime hour rate (Double)',
  overtime_base: ' overtime hour base',
  overtime_base_retro: ' overtime hour base - Retro',
  open: 'Open',
  operations_cut_off_period: 'Operations Cut-off Period',
  operations_cut_off_period_tip:
    'The operations cut-off period is the period for calculating the financial transactions and variables of the payroll process. It is affected by the timing of the retroactive transactions between months such as absence and leave requests',
  overdue_payroll: 'Overdue Payroll',
  overdue_payroll_mse:
    'You have an overdue Payroll. If you’d like to keep record of the overdue payroll in Reports, we recommend that you confirm it first before starting This Month’s Payroll.',
  operation_cut_off_period: 'Operation Cut-off Period',
  organizations: 'Organizations',
  payrun_date: 'Payrun Date: {{ date }}',
  day_starting_from_date: '{{count}} Days starting from <span>{{date_day_month_from}}</span>',
  out_of_steps: '{{step}} out of {{length}} steps completed',
  overtime_calculation: 'Overtime Calculation',
  other_addition_types: 'Other Addition Types',
  outsourcing_costs: 'Outsourcing Costs',
  of_current_month: '{{number}} of Current Month',
  of_next_month: '{{number}} of Next Month',
  // P
  payroll_is_sent_for_approval: 'Payroll is sent for approval',
  payroll_is_confirmed: 'Payroll is confirmed',
  payroll_summary_landpage: 'Payroll Summary',
  payroll_summary_status: 'Payroll Summary',
  payrunPeriodLabel: '{{first_date}} to {{sec_date}}',
  Add_Payment_Methods_low_tries: 'Add Employees Payment Methods',
  Add_Payment_Methods_low_tries_des: "Start Defining Each Employee's Payment Method",
  paid: 'Paid',
  paid_at: 'Paid on',
  paid_on: 'Paid on {{date}}',
  payment_date: 'Payment date',
  payment_types: 'Payment types',
  payment_methods: 'Payment methods',
  payment_confirmation_for_employees: 'Payment confirmation for employees, {{name}}',
  payment_confirmed_for_employees: 'payment will be confirmed for {{number}} employees',
  payroll: 'Payroll',
  payroll_attendance_popp_title: 'The following are time related transactions.',
  payroll_wizerd: '{{date}} Payroll Wizard',
  processing_file_low_tiers: 'Processing file...',
  processing_file_low_tiers_des: 'Uploading the file may take time, depending on its size',
  proceed_anyways_discprition:
    'Overtime, delay, and all the Time Related Transactions will be dismissed, and employees will get just basic salaries and allowances.',
  payroll_attendace_review: {
    title: 'Overtime, delay, and all the',
    description: 'will not reflect on payroll sheet. But you can process the payroll.',
  },
  payroll_attendance_overtime: 'Overtime',
  payroll_attendance_delay: 'Delay',
  payroll_attendance_shortage: 'Shortage',
  payroll_attendance_missing_punches: 'Missing Punches',
  payroll_attendance_absents: 'Absents',
  play_video: 'Play video',
  please_choose_bank_before_conformation: 'Please Choose Bank before payroll confirmation',
  payroll_history: 'Payroll history',
  payroll_manual_adjustment_addition: 'Payroll mannual adjustment addition',
  payroll_manual_adjustment_deduction: 'Payroll mannual adjustment deduction',
  payroll_month: 'Payroll Month',
  payroll_months: 'Payroll Month',
  period: 'Period',
  payment_sent_successfully_note:
    'Salaries sent successfully to the bank, it will be transferred to the employees accounts in the specified fund date {{ date }}',
  payment_send_warning_note:
    'If the Fund date is the next business day then the fees will be 3.5 SAR/Employee (Without VAT) , otherwise the fees will be 3 SAR/Employee (Without VAT), the business day end at 2 PM',
  payment_send_warning_note_wallet:
    'If the Fund date is the today then the fees will be 10 SAR/Employee (Without VAT) , otherwise the fees will be 3 SAR/Employee (Without VAT), the business day ends at 2 PM',
  pay_period: 'Pay period',
  previous_pay_period: 'Previous pay period',
  previous_month_payroll_amount_sar: 'Previous month payroll amount (SAR)',
  payroll_summary: 'Payroll summary',
  payroll_suspened_employees: 'People not included in this payroll',
  payroll_elements_distributions: 'Payroll elements distributions',
  payment: 'Payment methods',
  payroll_duration: 'Duration of this Payroll',
  pending_requests: 'Pending Requests',
  paid_to_employee: 'Paid to employee',
  paid_to_provider: 'Paid to provider',
  paid_to_provider_low_tiers: 'Paid to provider',
  pending_balance: 'Pending balance',
  payroll_confirming: 'Payroll confirming...',
  payment_details_changes: 'Payment details changes',
  payroll_confirmed_successfully: 'payroll has been confirmed successfully',
  payroll_confirmation_message_1:
    'Once you confirm the payroll there will be no way to edit or reopen it again.',
  payroll_confirmation_message_2:
    'Payslips will be directly sent to all the employees on their email.',
  payroll_confirmation_message_3:
    'Bank file will be generated and sent to your email for instructions.',
  payroll_confirmation_message_4: 'A task will be assigned to pay the payroll for the employees.',
  prev: 'Prev.',

  payroll_failure_gosi_note:
    'Only wages file template from GOSI is acceptable, if you still face this issue، try to save the file with .xlsx and try again, or you can fill <thisTemplate>this template</thisTemplate> and upload it directly. <learnMore>Learn more</learnMore>',
  payroll_failure_file_note:
    'Only our file template and format is acceptable, if you still face this issue، try to save the file with .xlsx and try again, or you can fill <thisTemplate>this template</thisTemplate> and upload it directly. <learnMore>Learn more</learnMore>',
  payroll_card_header: '{{date}} Payroll',
  print_report: 'Print report',
  payroll_period: 'Payroll period',
  please_update_the_salary: 'Update salary',
  pending_request_payroll_note:
    'These requests will impact the payroll. Make sure to resolve the request before you run the payroll',
  prevuous_full_package: 'Previous full package',
  proceed_anyways: 'Proceed Anyway',
  please_proceed: 'Please Proceed',
  payroll_timeline: 'Payroll Timeline',
  payroll_timeline_message_tip:
    'Payroll timeline & updates that have been performed during payroll period ',
  pdf_format: 'PDF Format',
  previous_pay_puns: 'Previous Payrolls',
  payroll_pending_approval: 'Payroll Pending Approval',
  pay_run_still_pending_approval: '<strong>{{month}}</strong> payroll is still pending approval...',
  pending_approval_payroll_skipped: 'Pending approval payroll can not be skipped ',
  period_payment: 'Period Payment',
  possible_if_period_confirmed_after_this: 'Possible only if no period was confirmed after this',
  possible_if_period_drft_after_this: 'Possible only for the open payroll period',
  payroll_approval: 'Workflow Approvals',
  payroll_approval_switch: 'Choose the employees who will review & approve the payroll',
  loans_payment: 'Loans Payment',
  payroll_summary_low: 'Payroll summary',
  prepare_file_for_upload: 'Prepare the file for upload',
  prepare_file_for_upload_note: 'Add the required information to the file',
  pay_via_app: 'Pay your employees via {{app_name}} 💸',
  pay_via_app_desc:
    '{{app_name}} payroll is a hassle-free payroll system, pay your employees in a few clicks',
  payment_authriz_required: 'Payment Processing Authorization Required',
  payment_authriz_required_note:
    'You currently don’t have permission to pay the payroll. Payroll Payment is handled by {Employees Names}. If you believe there is an issue please contact your system admin.',
  testing_payroll: 'Testing Payroll',
  testing_payroll_note:
    'This testing payroll is for experimentation and understanding the functionality of the payroll system in {{app_name}}. Computed values are based on information in employee files.',
  end_testing_payroll: 'End Testing Payroll ',
  proceed_to_payment: 'Proceed to Payment',
  payroll_details: 'Payroll Details',
  payroll_schedule: 'Change Payroll Period',
  filtered_by: 'Filtered by:',
  fetched_of_total_employees:
    'Fetched <span>{{fetched}}</span> of <span>{{total}}</span> employees',
  payroll_summary_total_package_desc: '{{percentage}}% {{comparison}} than {{month}}’s payroll',
  payroll_review_badge: '{{employee_name}}: received the request on {{date}}',
  payroll_review_badge_pending: '{{employee_name}}: not received the request yet',
  payroll_approved_on: 'Approved the request on {{date}}',
  payroll_rejected_on: 'Rejected the request on {{date}}',
  payment_information: 'Payment Information',
  please_note_draft_transactions:
    'Please note that this action will cause a deletion of the current draft transactions',
  partially_uploaded: 'Partially Uploaded',
  ex_vat: 'ex VAT',
  uploaded: 'Uploaded',
  // Q
  quick_actions: 'Quick Actions',
  // R
  run_payroll: 'Run Payroll',
  run_payroll_anyway: 'Run Payroll Anyway',
  request_from_date: 'Request from date',
  reminder_time_hours_minutes: '{{h}} hr/s {{m}} min/s',
  reminder_time_minutes_seconds: '{{m}} min/s {{s}} sec/s',
  reminder: 'send reminder  in {{time}} mins',
  reminder_sent_successfully: 'Great! You’ve successfully sent a reminder',
  reminder_sent_successfully_discprition:
    'The changes you have made in the payroll sheet are saved',
  request_to_date: 'Request to date',
  requester_name: 'Requester name',
  requester_type: 'Requester type',
  requester_from_date: 'Requester from date',
  requester_to_date: 'Requester to date',
  reschedule_payment: 'Reschedule Payment',
  reschedule: 'Reschedule',
  retroed_requests: 'Retroed requests',
  remarks: 'Remarks',
  riyadh_bank: 'Riyadh bank',
  review_rage: 'Review Page',
  view_list: 'View List',
  view_employees_salaries: 'View Employees Salaries',
  view_these_employees: 'View these Employees',
  recently_added_employees: 'Recently Added Employees  •  {{num}}',
  run_your_payroll: 'Run your payroll',
  review_payroll: 'Review Payroll ({{date}})',
  review_payroll_status: 'Payroll Review Status',
  review_payroll_request: 'Payroll Review Request',
  run_new_payroll: 'Run new payroll',
  re_open_payroll: 'Reopen Payroll',
  run_payroll_note:
    'Please run payroll on {{day}} , {{date}} to pay your employees for their hard work. They’ll receive their payslip on {{dayOfPayslip}}, {{dateOfPayslip}}.',
  rate: 'Rate',
  reference: 'Reference',
  reject: 'Reject',
  rejected: 'Rejected',
  regular_payroll: 'Regular Payroll',
  rejection_reason: 'Reason of rejection',
  required_pay: 'Required',
  required_pay_lpw: 'Required',
  run_a_testing_payroll: 'Run a Testing Payroll',
  continue_testing_payroll: 'Continue Testing Payroll',
  re_upload: 'Re-Upload',
  reprocess_payroll: 'Re-Process Payroll',
  run_testing_payroll: 'Run Testing Payroll',
  run_testing_payroll_alert:
    'Your payroll was already started, if you did run a testing payroll you’ll lose all additions/deduction that you already made',
  re_upload_failed_entries: 'Re-upload failed entries',
  REMARK: 'Remark',
  // S
  start_processing_payments: 'Start Processing Payments',
  salary_type: 'Account Type',
  send_for_approval_cycle: 'Send for Approval Cycle',
  send_reminder_to_attendance_manager: 'Send Reminder to Attendance Manager',
  remind_attendance_manager: 'Remind Attendance Manager',
  send_reminder_to_attendance_manager_discprition:
    'A reminder will be sent to the attendance manager to approve the attendance. Your payroll changes will be saved.',
  suspend: 'Suspend',
  // suspend_list: 'Suspend list',
  suspended: 'Suspended',
  suspended_bang: 'Suspended!',
  successful_low_tires_upload: 'Successful',
  success_entries: 'Success entries',
  employees_upload_files: 'Employees {{number}}',
  setting_page: 'Setting page',
  set_payment_method_low_tries: 'Set the payment method for each employee',
  upload_payment_method_low_tries:
    'Add your payroll bank account, & link it with Mudad or directly with SAB for hassle-free payment processing. Streamline your payments effortlessly!',
  set_payment_method_low_tiers_des:
    'With just 3 steps, you will be on your way to streamlining your payroll process like never before',
  suspended_month: 'Suspended month',
  suspended_btn: 'Suspended ({{number}})',
  suspended_subtitle:
    'These employees are not included in this payroll, Unsuspend them to include them in the current payroll',
  summary: 'Summary',
  suspended_employeess: 'Suspended Employees • {{number}}',
  send_payslip: 'Send payslip',
  status: 'Status',
  payment_statuses: 'Payment Statuses',
  size: 'Size',
  show_full_name: 'Full name',
  show_department: 'Department',
  show_location: 'Location',
  show_parent_department: 'Parent department',
  show_area: 'Area',
  sick_leave_payroll_tooltip: '{{percentage}} Deducted for {{days}} days',
  show_business_unit: 'Business unit',
  show_sponsor: 'Sponsor',
  show_remarks: 'Remarks',
  show_payment_type: 'Payment type',
  show_bank: 'Bank',
  show_iban: 'Iban',
  show_employment_type: 'Employment type',
  show_work_email: 'Work email',
  sabb_bank: 'SABB bank',
  saudi_fransi_bank: 'Saudi fransi bank',
  suspension_reason: 'Suspention reason',
  sync: 'Sync',
  social_insurance: 'Social Insurance',
  social_insurance_subscription: 'Social Insurance Subscription',
  social_insurance_payable: 'Social Insurance Payable',
  share_pdf: 'Share As PDF',
  share_excel: 'Share As Excel',
  suspend__month: 'Suspended month',
  summary_view: 'Summary View',
  shortage_addition_base: 'Early departure addition base',
  shortage_deduction_base: 'Early departure deduction base',
  early_departure_deduction_rate: 'Early departure deduction rate',
  early_departure_deduction_base_retro: 'Early departure deduction base - Retro',
  early_departure_deduction_rate_retro: 'Early departure deduction rate - Retro',
  early_departure_addition_rate: 'Early departure deduction base',
  salary_difference: 'Salary difference',
  salary_up_To_last_working_day: 'Worked days salary (until last working day)',
  show: 'Show',
  send: 'Send',
  show_number_more: 'show {{ number }} more',
  not_authorized: 'Not Authorized',
  authorized: 'Authorized',
  save: 'Save',
  skipping_loan_deduction:
    ' Skipping the loan deduction would remove it from this payroll and move it to the next payroll pay period',
  start_payroll: 'Start Payroll',
  start_the_payoll: 'Start the Payroll',
  send_reminder_pay: 'Send Reminder',
  start_run: 'Start {{month}}’s Run',
  skip_overdue_payroll: 'Skip Overdue Payroll',
  skip_unclosed_periods_payroll:
    'Skip unclosed periods and start with this payroll <strong>{{month}}</strong>?',
  skip_unclosed_periods_payroll_skip:
    'Skip unclosed periods and skip with this payroll <strong>{{month}}</strong>?',
  skip_this_payroll: 'Skip this payroll <strong>{{month}}</strong> ?',
  skip_this_payroll_mse:
    'Any modifications made in this payroll will be discarded. Skipped payroll periods will not be available in Payment Processing or Reports.',
  unskip_this_payroll: 'Unskip this payroll {{month}} ?',
  unskip_this_payroll_mse:
    'Any modifications made in your draft  payroll <strong>{{month}}</strong> will be discarded',
  skip_unclosed_periods: 'Skip Unclosed Periods',
  skip: 'Skip',
  skip_payroll: 'Skip payroll',
  unskip_payrun_but: 'Unskip',
  unskip_payrun: 'Unskip',

  unskip_payroll: 'Unskip payroll',
  skip_by: 'By {{name}}',
  ß: 'Unskip',
  sync_with_jisr: 'Syncing with Jisr',
  sync_with_jisr_note: 'Uploading the file may take time, depending on its size',
  save_now_through_app: 'Save <span>25%</span> now - Pay through {{app_name}}',
  stay_informed_compliant_Jisr: 'Stay informed and compliant with {{app_name}}!',
  stay_top_attendance_records:
    'Stay on top of attendance records. Enable attendance tracking to monitor your employees` daily activity. {{app_name}} will automatically calculate and reflect any absences, delays, shortages, and overtime hours.',
  setup_manual_deductions: 'Setup Manual Deductions',
  share_payslips_with_employees: 'Share Payslips with the employees',
  show_the_ayslip:
    'Show the Payslip the employees self service app after confirming payroll by (Days)',
  send_payslips: 'Send Payslips to Employee`s Email',
  sync_issue_found_in_cr: '{{number}} sync issues were found in “{{CR_name}}”',
  gosi_bulk_import_title:
    '{{appName}} verifies your data accuracy compared with the information available in GOSI. This step helps you avoid salary mismatches, missing contributors, and more...',
  select_the_starting_month: 'Select the starting month of your payroll',
  start_your_first_payroll: 'Start Your First Payroll',
  start_the_payroll_take: 'Start the payroll and take your company to the next level',
  send_to_review: 'Send to Review',
  show_employee_profile: 'Employee Profile',
  save_and_close: 'Save & Close',
  sand_confirm: 'Save and Confirm',
  // T
  task_added: 'Task added',
  ticket_info: 'Ticket information',
  time_related_transactions: 'Time Related Transactions',
  total_amount_of_payroll_sar: 'Total amount of payroll (SAR)',
  totalearnings: 'Total Earnings',
  transaction_create_history_creator: '{{ name }} has created the transaction',
  tool_tip_pay_type: 'This pay type can be used to pay your employees in payroll',
  tool_tip_pay_type_in_payroll: 'This pay type can be used to pay your employees In payroll',
  tool_tip_pay_type_out_payroll: 'This pay type can be used to pay your employees Out payroll',
  tool_tip_transactions:
    'This screen shows all the transactions that have been performed for your employee',
  tool_tip_paid_in_payroll: 'This transaction was set to be paid inside your payroll',
  tool_tip_paid_out_payroll: 'This transaction was set to be paid out of your payroll',
  tool_tip_effective_date:
    'It is the date upon which the transaction is considered to take effect, which may be a past, present or future date',
  tool_tip_reschedule_date:
    'It is the date upon which the transaction is considered to take effect, choose a future date',
  table_view: 'Table View',
  this_month_salary: 'This month salary',
  total__earnings: 'Total earnings',
  total_end_of_service: 'End of service reward',
  total_unpaid_leave_deduction: 'Total unpaid leave deduction',
  total_overtime_hours: 'Total overtime hours',
  total_overtime_amount: 'Total overtime amount',
  total_sick_leave_deduction: 'Total sick leave deduction',
  total_sick_leave_days: 'Total sick leave days',
  total_unpaid_leave_days: 'Total unpaid leave days',
  total_settlement_deduction_days: 'Total settlement deduction days',
  total_settlement_amount: 'Total settlement amount',
  total_missing_punch_retro_times: 'Total Incomplete record retro times',
  total_unpaid_leave_retroactive_days: 'Total unpaid leave retroactive days',
  total_amount: 'Total amount',
  total_excuse_amount: 'Total excuse amount',
  total_absence_deduction_days: 'Total absence deduction days',
  total_retroactive_abcence_days: 'Total retroactive abcence days',
  total_excuse_hours: 'Total excuse hours',
  total_early_resumption_days: 'Total early resumption days',
  total_absence_deduction: 'Total absence deduction',
  total_early_resumption_amount: 'Total early resumption amount',
  total_delay_hours: 'Total delay hours',
  total_delay_amount: 'Total delay amount',
  total_missing_punch_times: 'Total Incomplete record times',
  total_missing_punch_deduction_amount: 'Total Incomplete record deduction amount',
  total_early_departure_hours: 'Total early departure hours',
  total_shortage_amount: 'Total early departure amount',
  total_hours: 'Total hours',
  total_absence_days: 'Total absence days',
  total_net_salary: 'Total net salary',
  total_amount_for: 'Total amount for {{date}} :',
  transfer_fees: 'Transfer Fees',
  transactions_list: 'Transactions List',
  type_of_transaction: 'Type Of Transaction',
  transaction_history: 'Transaction History',
  to: 'To',
  edit_transaction: 'Edit Transaction',
  cancel_transaction: 'Cancel Transaction',
  select_specific_employees: 'Select specific employees',
  total_leave_balance_settlement: 'Total leave balance settlement',
  total_loan_settlement: 'Total Loan settlement',
  total_working_days: 'Total Salary',
  till_us_the_reson: 'Tell us what we can improve in Payroll',
  this_months_pay_run: 'THIS MONTH’S PAYROLL',
  to_pay: 'To',
  payroll_period_tip:
    'The payroll period for each month is determined by the calendar month period.',
  ticket: 'Ticket Accrual',
  try_testing_payroll:
    'Try a testing payroll now to explore how it works, without affecting your actual data',
  try_full_experience: 'Try full experience',
  track_your_employees_attendance: 'Track your Employees` Attendance ',
  to_ensure_correct_reflection:
    'To ensure correct reflection, review and confirmation is required for the attendance sheet',
  track_and_integrate_employees: 'Track and integrate employees` attendance into the payroll',
  track_employees_attendance: "Track your Employees' Attendance",
  transactions_number: '{{count}} Transactions',

  // U
  upload_document: 'Upload document',
  upload_files: 'Upload files',
  upload_template_file: 'Upload template file',
  upload_your_gosi: 'Please upload your ( GOSI file )',
  unsuspend: 'Unsuspend',
  uploading: 'Uploading',
  update_remark: 'Update remark',
  upload_gosi_file: 'Upload GOSI file',
  upload_gosi: 'Upload Gosi',
  upload_new_gosi_file: 'Upload new gosi file',
  unpaid_leave_deduction_base: 'Unpaid leave deduction base',
  unpaid_leave_deduction_rate: 'Unpaid leave deduction rate',
  unpaid_leave_deduction_rate_retro: 'Unpaid leave deduction rate - Retro',
  unpaid_leave_deduction_base_retro: 'Unpaid leave deduction base - Retro',
  update_from: 'Update from',
  upload_wages_for_cr: 'Upload the wages file for each commercial registration',
  upload_wages_for_cr_note: 'Make sure you upload the file as it is without making any changes.',
  upload_wages_file: 'Upload Wages File',
  reupload_wages_file: 'Re-Upload Wages',
  reupload_the_wage: 'Re-Upload the Wage',
  unlock_savings: 'Unlock Savings, Pay online through {{app_name}} and Keep 25% in Your Pocket',
  upload_the_file: 'Upload the File',
  upload_file_we_will_do: 'Upload the file, we will do all the things for you',
  upload_file_we_will_do_note: 'Save the file as (csv), (xlsx) or (xls) format then upload it here',
  unpaid_leaves: 'Unpaid Leave',
  upload_transactions: 'Upload Transactions',
  upload_bulk_transaction_title: 'Upload bulk additions/deductions',
  upload_bulk_transaction_subtitle:
    'Use additions/deductions sheet to define the additions/deductions for a group of employees at once',
  // V
  vacation_starts_on: 'Vacation starts on',
  very_hard: 'Very Hard',
  very_easy: 'Very Easy',
  view_payroll_details: 'Details',
  view_payroll_summary: 'Summary',
  view_requests: 'View Requests',
  view_request: 'View the Request',
  vacation_days_salary: 'Vacation salary  ({{leave_days}} Days)',
  vacation_settelment: 'Vacation Settelment',
  vacation_settlement_list: 'Vacation List',
  view_details: 'View details',
  vacation_settelement: '{{name}} Vacation Settlement',
  vacation_salary_calculation: 'Vacation Salary calculation',
  vacation_settlement_review_request: 'Vacation Settlement Review Request',
  view_sheet: 'View Sheet',
  view_more_in_payroll_reports: 'View more in Payroll Reports',
  view_payroll: 'View {{month}} Payroll',
  validation_message_pay: 'The cut of period should be 30/31 days by maximum',
  // W
  will_apply_next_month: 'Will apply next month',
  why_do_i_need_to_upload_gosi_file: 'Why do I need to upload Gosi file ?',
  wage_type: 'Wage type',
  wps_employees: 'Registered In Gosi',
  WPS_employee_file: 'Payroll Bank File',
  waiting_exporting_payroll_file:
    'Your file is exporting, you can continue your work we will inform you once it got exported 🙂',
  waiting_review: 'Waiting review',
  were_happy_to_here_that: 'We are happy to hear that! Tell us what we can improve in Payroll.',
  wps_status: 'File will be available once the bank has finished processing the payment',
  welcome_jisr_payroll: 'Welcome to {{app_name}}  payroll 💸',
  watch_short_intro_video: 'Watch this Short Intro Video',
  watch_video_get_started:
    'Watch this video to get started and learn how to calculate and pay the payroll .',
  welcome_to_app: 'Welcome to {{app_name}}`s fully automated payroll',
  select_the_payroll_payment_day: 'Select the payroll payment day',
  select_the_payroll_payment_day_note: 'Payment day can be overwritten later',
  // X
  // Y
  you_can_revert_later_from_suspended_list: 'You can revert later from suspended list!',
  your_gosi_summary: 'Your GOSI Documents summary',
  you_are_going_to_upload_GOSI_file_for_desc:
    'You are going to upload GOSI file for {{ name }}, {{ number }}, make sure you are uploading the right file',
  you_can_send_new_reminder_in: 'You can send a new reminder in',
  your_payroll_was_rejected_by: 'Your payroll review request was rejected by:',
  You_are_about_to_confirm: 'You’re about to confirm {{date}} Payroll. Are you sure?',
  You_ve_successfully_confirmed: 'You’ve successfully confirmed the {{date}} Payroll.',
  you_are_about_to_send_approval:
    'Are you sure you want to send {{date}} payroll for approval cycle?',
  Yes_Lets_send_it: 'Yes. Let’s send it',
  you_dont_have_permission_approval_cycle:
    "You don't have permission to create approval cycle. Please contact your administrator",
  your_previous_listed_here: 'Your previous pay runs will be listed here',
  youll_be_able_to_start_payroll:
    'You’ll be able to start {{next_month}} payroll once {{month}}payroll is confirmed or rejected.',
  you_dont_have_permission_collaborators: 'You don’t have the permission to add collaborators',
  you_dont_have_permission_manage_workflow: 'You don’t have the permission to manage the workflow',
  // Z

  // extra
  business_unit_with_br: 'Business <br/> Units',
  payment_methods_with_br: 'Payment <br/> Methods',
  parent_departments_with_br: 'Parent <br/> Departments',
  payment_method: 'Payment Method',
  payment_modes: 'Payment Method',
  pay_method: 'Pay mode',
  payments_bank_account:
    'Payments can be processed directly through {{app_name}} with this bank account',
  approve_payroll_review_request: 'Approve payroll review request',
  reject_payroll_review_request: 'Reject payroll review request',
  'outsourcing provider costs': 'Outsourcing provider costs',
  'pre extra cols': 'Payment Information',
  'extra columns': 'Other Information',
  'employee details': 'Employee Information',
  // Status
  pending_approval: 'Pending Approval',
  overdue: 'Overdue',
  skipped: 'Skipped',
  must_include_a_letter: 'Must include at least one character',
  initiated: 'Initiated',
}

export default Payroll
