import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { MarketPlaceTypes } from './types'
import * as actions from './actions'

const initialState: MarketPlaceTypes = {
  fetching: false,
  lookUps: {
    categories: [],
    plans: {
      essential: '',
      professional: '',
    },
  },
  integrationApps: { categories: [] },
  popularApps: [],
  appDetails: {},
  managedAppsData: [],
}

export default function marketplaceReducers(
  state = initialState,
  action: Action,
): MarketPlaceTypes {
  switch (action.type) {
    case actions.APPS_LOAD_PENDING:
    case actions.APPS_LOOKUPS_LOAD_PENDING:
    case actions.POPULAR_APPS_LOAD_PENDING:
    case actions.MANAGED_APPS_LOAD_PENDING:
    case actions.APP_DETAILS_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.APPS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        integrationApps: action.payload.data?.data,
      }
    }

    case actions.APPS_LOOKUPS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        lookUps: action.payload.data?.data,
      }
    }

    case actions.POPULAR_APPS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        popularApps: action.payload.data?.data?.apps,
      }
    }

    case actions.APP_DETAILS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        appDetails: action.payload.data?.data?.app,
      }
    }

    case actions.MANAGED_APPS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        managedAppsData: action.payload.data?.data,
      }
    }

    case actions.APPS_LOAD_REJECTED:
    case actions.APPS_LOOKUPS_LOAD_REJECTED:
    case actions.POPULAR_APPS_LOAD_REJECTED:
    case actions.MANAGED_APPS_LOAD_REJECTED:
    case actions.APP_DETAILS_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
