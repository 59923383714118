import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import I18n from 'translations/i18n'
import { Action } from 'types/redux'
import * as actions from './actions'

export type OSRShareFilePropsType = {
  include_logo: boolean
  id: number
  file: 'pdf' | 'xls'
}

export type OSESummaryType = {
  total_employees: number
  total_cost: string
  total_salaries: string
  total_fees: string
}

type OSEFiltersType = Record<
  | 'area_ids'
  | 'business_unit_ids'
  | 'department_ids'
  | 'grade_ids'
  | 'job_title_ids'
  | 'location_ids'
  | 'nationalities'
  | 'outsourcing_company_ids',
  number[]
> & { status_employee: string[] }

export const ESOFilters = [
  'status_employee',
  'outsourcing_company',
  'business_units',
  'departments',
  'area',
  'locations',
  'job_titles',
  'grades',
  'nationalities',
]
export type OSEParamsType = {
  payroll_id?: number
  paygroup_id?: number
  columns?: string[]
  filter: Partial<OSEFiltersType>
  search: string
  export_format?: string
}

export type FlattenedOSEParamsType = Omit<OSEParamsType, 'filter'> & OSEParamsType['filter']

type OSEPayrollType = {
  date: string
  id: number
  month_year_i18n: string
}

type InitStateType = {
  fetching: boolean
  fetching_share: boolean
  ose_payrolls: OSEPayrollType[]
  header: string[]
  records: string[][]
  total: string[]
  summary: OSESummaryType | null
}

export const initParams: Partial<OSEParamsType> = {
  search: '',
  filter: {
    area_ids: [],
    business_unit_ids: [],
    department_ids: [],
    grade_ids: [],
    job_title_ids: [],
    location_ids: [],
    nationalities: [],
    status_employee: [],
    outsourcing_company_ids: [],
  },
}

export const initialState: InitStateType = {
  fetching: false,
  fetching_share: false,
  ose_payrolls: [],
  header: [],
  records: [],
  total: [],
  summary: null,
}

const reducer = (state = initialState, action: Action): InitStateType => {
  switch (action.type) {
    case actions.OUTSOURCING_EMPLOYEES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.OUTSOURCING_EMPLOYEES_LOAD_FULFILLED: {
      const payload = action.payload.data.data
      return {
        ...state,
        header: payload?.header,
        records: payload?.records,
        summary: payload?.summary,
        total: payload?.total,
        fetching: false,
      }
    }

    case actions.OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_FULFILLED: {
      return {
        ...state,
        ose_payrolls: action.payload.data.data,
        fetching: false,
      }
    }

    case actions.OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching_share: true,
      }
    }

    case actions.OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_FULFILLED: {
      Toastr.success(I18n.t('email_sent_successfully'))
      return {
        ...state,
        fetching_share: false,
      }
    }

    case actions.OUTSOURCING_EMPLOYEES_PAYROLLS_LOAD_REJECTED:
    case actions.OUTSOURCING_EMPLOYEES_SHARE_REPORT_LOAD_REJECTED:
    case actions.OUTSOURCING_EMPLOYEES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }
    }

    default:
      return state
  }
}

export default reducer
