const gl_mapping = {
  // A
  allowances: 'Allowances',
  assign_type: 'Type',
  assign_employees_type: 'subsidiary Type',
  assign_employees_type_modal: 'Link employees to a subsidiary',
  assign_employees_type_tracking: 'Cost center type',
  assign_to_business_unit: 'Assign to Business Unit',
  assign_to_subsidiary: 'Link to subsidiary',
  assign_employee_to: 'Assign Employee To',
  select_subsidiary: 'Select subsidiary',
  account_number: 'Account number',
  account_name: 'Account name',
  activate: 'Activate',
  allowances_netsuite: 'Salary and Allowances ({{ mapped }} of {{ all }} mapped)',
  all_employees: 'All Employees ({{ number }})',
  all_dimensions: 'All Dimension Values ({{ number }})',
  absenteeism_transactions_netsuite: 'Absenteeism Transactions ({{ mapped }} of {{ all }} mapped)',
  accruals_netsuite: 'Accruals & Payable ({{ mapped }} of {{ all }} mapped)',
  loan_types_netsuite: 'Loan Types ({{ mapped }} of {{ all }} mapped)',
  odoo_journal_warring:
    'Please note that employees must have the email in order to include their names in Odoo Journal entry',
  activate_account_warning_note:
    'It seems that {{ current }} is already in place, and now you are trying to activate {{ target }}. Doing so will destroy all configuration done with {{ current }}',
  change_account_warning_note:
    'It seems that {{accounting_name}} is already in place, and now you are trying to activate the connection with different account. Doing so will destroy all the existing configuration and data with {{accounting_name}} integration',
  applicable_subsidiaries: 'Applicable Subsidiaries',
  other_paytypes_netsuite: 'Other Additions ({{ mapped }} of {{ all }} mapped)',
  other_deductions_netsuite: 'Other Deductions ({{ mapped }} of {{ all }} mapped)',
  applicable: 'Applicable',
  absenteeism_transactions_netsuite_description:
    'This is the list of attendance transactions (such as absence deduction and overtime), unpaid leave, and others',
  accounts_mapping: 'Accounts Mapping',
  assign_employees: 'Assign employees',
  allowances_netsuite_description:
    'This is a list of basic salary, allowances, and retroactive appraisals',
  assigned: 'Assigned',
  other_deductions_netsuite_description: 'This is the list of other additions types',
  other_paytypes_netsuite_description: 'This is the list of other additions types',
  loan_types_netsuite_description: 'This is the list of loan types',
  accruals_netsuite_description:
    'This is the list of accruals such as vacation accrual, end of service accrual, GOSI accrual, and  payroll payable',
  assign_all_employees: 'Assign {{number}} employees',
  assign_filtered_employees: 'Assign Filtered Employees',
  assign_cost_center: 'Assign {{name}}',
  assigned_subsidiary: 'Assigned Subsidiary',
  add_new_item: 'Add New item',
  add_cost_location: 'Add location',
  add_cost_class: 'Add class',
  add_cost_department: 'Add department',
  assigned_number: 'Assigned {{number}}',
  assign_to_tracking_dimensions: 'Assign to tracking dimensions',
  integrations: 'Integrations',
  INTEGRATIONS: 'Integrations',
  absenteeism_transactions: 'Absenteeism transactions',
  accruals: 'Accruals',
  all_transactions: 'All Transactions',
  added_employees_to_template: 'Added <Text>{{number}}</Text> employees to {{target}}',
  added_to_this_shift_template: 'Added <Text>{{number}}</Text> to {{target}}',
  add_more_type: 'Additional information to show as columns',
  link_them_now: 'Link them now',
  all_employess_connect:
    'All employees under selected business unit will be linked automatically to the subsidiary',
  added_assig_type: 'Add',
  accounting_integration: 'Accounting integration',
  accounting_integration_setup: 'Accounting integration setup',
  // B
  // C
  chart_of_accounts: 'Chart Of Accounts',
  accounts_list_view: 'Accounts List View',
  changing_integration_type: 'Changing Integration Type ?',
  changing_integration_account: 'Changing the Integration Account ?',
  current_assign: 'Current Assign',
  combination_code_separator_view:
    'Combination code will be like (12005{{ code }}0022{{code}}8797)',
  create_new_subsidiary: 'Create New Subsidiary',
  you_can_separate_employee_information:
    'You can define employees list per subsidiary, so you get reports help you to track costs & expenses for each subsidiary.',
  create_new_subsidary: 'Create new subsidiary',
  create_tracking_dimensions: 'Create new cost center',
  create_new_tracking_dimensions: 'Create new cost center',
  create_multiple_tracking_dimensions:
    'Cost centers allow you to track all financial expenses by source such as branches, departments, and projects',
  click_here_to_undo: 'Click here to undo',
  custom_assign_employees: 'Employees not added to subsidiary',
  customize_your_chart_of_accounts: 'Create Your Chart Of Accounts',
  create_new_gl_account: 'Create new GL account',
  cost_center_type: 'Cost Center Type',
  connect_netsuite: 'Map NetSuite Location',
  connect_quickbooks_department: 'Map QuickBooks Department',
  connect_quickbooks_location: 'Map QuickBooks Location',
  customize_your_file_structure: 'Customize your File structure',
  customize_your_transaction_entry_code: 'Transaction Entry code',
  code_journal_example: 'Code journal example:  12005 - 0022 - 8797',
  cost_center_type_in_netsuite: 'Cost Center Type In Netsuite',
  cost_center_type_in_quickbooks: 'Cost Center Type In Quickbooks',
  cost_center_type_in_xero: 'Cost Center Type In Xero',
  cost_center_type_in_qoyod: 'Cost Center Type In Qoyod',
  cost_center_type_in_odoo: 'Cost Center Type In Odoo',
  cost_center_type_in_microsoft: 'Cost Center Type In Microsoft',
  cost_center_type_in_microsoft_finops: 'Cost Center Type In MS Dynamics Finance & Operation',
  cost_center_type_in_oracle_fusion: 'Cost Center Type In Oracle Fusion',
  cost_center_type_in_zoho_books: 'Cost Center Type In Zoho books',
  cost_center_type_in_sap: 'Cost Center Type In Sap Business One',
  customize_your_journal_structure:
    'Customize your journal structure the way you want and get your journal sheet ready to import to your accounting system',
  custom_assignment: 'Custom Assignment',
  create_new: 'create new',
  chart_of_accounts_tab: 'Chart of accounts',
  create_new_item: 'Create new item',
  create_tracking_dimension: 'Create new cost center',
  custom: 'Custom',
  cost_center_types: 'Cost Center Types',
  current_assigned: 'Current Assigned',
  combination_code: 'Combination code',
  main_account_number: 'Main account number',
  create_your_gl_accounts_note: 'You can log all financial transactions to their GL accounts',
  create_your_gl_mapping_to_manage:
    'Create your GL mapping to manage your payment and any content here describe that future',
  cool_lets_go_and_start_the_journey: 'Cool, let’s go and start the journey',
  chart_of_account: 'Chart Of Account',
  chart_of_account_quickbooks: 'Chart Of Account',
  chart_of_account_xero: 'Chart Of Account',
  chart_of_account_qoyod: 'Chart Of Account',
  chart_of_account_microsoft: 'Chart Of Account',
  chart_of_account_microsoft_finops: 'Chart Of Account',
  chart_of_account_oracle_fusion: 'Chart Of Account',
  chart_of_account_sap: 'Chart Of Account',
  chart_of_account_zoho_books: 'Chart Of Account',
  chart_of_account_odoo: 'Chart Of Account',
  class: 'Class',

  // D
  default_chart_of_account: 'Main Chart Of Accounts',
  done_complate: 'Done',
  dimension_found: 'Dimension Found',
  dimension_not_found: 'Dimension Not Found',
  dimension_synced: 'Dimension Synced',
  main_chart_of_accounts: 'Main Chart Of Accounts',
  Departments: 'Department',
  disclaimer_netsuite_employee_sync_note:
    'Before activating this option. Please note that in case you already have some employee records exist in NetSuite side, you’ll need to map them first.',
  disclaimer_quickbooks_employee_sync_note:
    'Before activating this option. Please note that in case you already have some employee records exist in QuickBooks side, you’ll need to map them first.',
  readـthis_article: 'Read this article to now more',
  disclaimer: 'Disclaimer',
  parent_departments: 'Parent Department',
  Department_Level_1: 'Department (Level 1)',
  Department_Level_2: 'Department (Level 2)',
  Department_Level_3: 'Department (Level 3)',
  Department_Level_4: 'Department (Level 4)',
  define_the_separator_for_combination_code: 'Define the separator for combination code',
  employeeـdimension: 'Employee Dimension',
  employeeـdimension_des: 'Map the employee dimension at MS Dynamics',
  employeeـdimension_oracle_fusion_des: 'Map the employee dimension at Oracle Fusion',
  employeeـdimension_des_sap: 'Map the employee dimension at Sap Business One',
  journal_templates: 'Journal Templates',
  journal_templates_des: 'Map all the Journal Templates at MS Dynamics',
  journal_templates_des_sap: 'Map all the Journal Templates at Sap Business One',
  journal_templates_des_finops: 'Map all the Journal Templates at MS Dynamics Finance & Operation',
  journal_templates_des_oracle_fusion: 'Map all the Journal Templates at Oracle Fusion',
  journal_templates_des_odoo: 'Map all the Journal Templates at Odoo',
  dimension_sync: 'Dimension sync',
  jisr_journal_entries: '{{app_name}} Journal Entries',
  ms_dynamics_templates: 'MS Dynamics Templates',
  ms_finops_dynamics_templates: 'MS Dynamics Finance & Operation Templates',
  oracle_fusion_templates: 'Oracle Fusion Templates',
  odoo_dynamics_templates: 'Odoo Templates',
  // E
  enter_subsidiary_name: 'Enter subsidiary name',
  enter_subsidiary_code: 'Enter subsidiary code',
  enter_gl_account_name: 'Enter gl account name',
  enter_gl_account: 'Enter gl account',
  employees_allocation: 'Employees Allocation',
  edit_subsidiary: 'Edit subsidiary',
  edit_tracking_dimension: 'Edit cost center',
  enter_tracking_dimension_name: 'Enter cost center name',
  edit_item: 'Edit item',
  enter_item_name_in_english: 'Enter Item name in English',
  enter_item_name_in_arabic: 'Enter Item name in Arabic',
  enter_Item_code: 'Enter Item code',
  employees_numbers: 'Employees {{number}}',
  start_employees_numbers: '{{number}} Employees',
  employees_allocation_dec:
    'In this step you will be able to allocate employees list for each subsidiary and cost center',
  employees_allocation_dec_quickbooks:
    'In this step you will be able to allocate employees list for each cost center',
  employess_selected_tracking: 'Selected ({{number}})',
  employess_unassigned_tracking: 'Unassigned ({{number}})',
  exit: 'Exit',

  // F
  file_based_integration: 'File based integration',
  file_based: 'File based',
  // G
  gl_mapping: 'GL Mapping',
  gl_account_name_in_english: 'GL account name in English',
  gl_account_name_in_arabic: 'GL account name in Arabic (optional)',
  gl_account_nam_code: 'GL account code',
  generate_single_sheet_per_payment: 'Generate single sheet per payment type or none of that',
  gl_journal_select_element_note:
    'Select cost center that would be used to group journal entry transactions',
  gl_account: 'GL Account',
  gl_emp_tip_note: 'If this option is selected, the journal entry will be in employee level even',
  gl_nothing_opt: 'None',
  gl_payment_type_opt: 'Payment type (bank, cash,.. etc)',
  gl_account_darg_drop_note: 'Drag-and-drop file, or browse computer',
  gl_entry_code_note:
    'Lets know how you are going to enter your transactions to the accounting system',
  gl_mapping_preferences_are_all_set:
    'You can now export your JV from Payroll, Final Settlement, Vacation Settlement, and Out of payroll transactions',
  get_started: 'Get started',
  get_started_description:
    'Start with defining your financial organization structure, like subsidiaries, cost centers, and JV columns.',
  how_to_send_journal_entries_to_netSuite: 'How to send journal entries to NetSuite',
  how_to_send_journal_entries_to_quickbooks: 'How to send journal entries to QuickBooks',
  how_to_send_journal_entries_to_xero: 'How to send journal entries to Xero',
  JisrHR_Netsuite_Integration_FAQs: 'JisrHR - Netsuite Integration FAQs',
  defining_employee_cost_centre_cost_allocation:
    'Defining employee cost centre ( Cost Allocation )',

  // H
  how_do_you_want_to_order: 'Order work sheet columns (Drag & Drop):',
  how_to_map_subsidiaries: 'How to map subsidiaries',
  how_to_map_subsidiaries_odoo: 'How to map subsidiaries',
  how_to_map_zoho_books_cost_centers:
    'Zoho Cost centers ( Reporting Tags ) setup & Mapping on {{app_name}} HR ',
  how_to_map_subsidiaries_zoho_books: 'Zoho Books Subsidiaries Setup & Mapping',
  connecting_zoho_Books_prerequisites: 'Connecting Zoho Books - Prerequisites',
  employees_allocation_zoho_books: 'Employees Allocation on Zoho Books',
  posting_Journal_entry_from_app_zoho_books:
    'Posting a Journal Entry from {{app_name}}  to Zoho Books',
  how_to_configure_and_map_zoho_books_chart_of_account:
    'Zoho Books Chart of Accounts Mapping with {{app_name}}  transactions',
  defining_employee_cost_centre_subsidiary_zoho_books:
    'Defining employee cost centre & subsidiary - Zoho Books',
  zoho_books_journal_entry_structure_app: 'Zoho Books Journal Entry Structure on {{app_name}} ',
  setting_connection_with_zoho_books_on_app:
    'Setting up a connection with Zoho Books on {{app_name}}  HR',
  how_to_map_subsidiaries_ms_business: 'Microsoft Subsidiaries Setup & Mapping',
  employees_allocation_ms_business: 'Employees Allocation on Microsoft Dynamic',
  how_to_map_ms_business_cost_centers: 'Microsoft Cost centers setup & Mapping on {{app_name}}  HR',
  how_to_configure_and_map_ms_business_chart_of_account:
    'Microsoft Chart of Accounts Mapping with {{app_name}}  transactions',
  posting_Journal_entry_from_app_ms_business:
    'Posting a Journal Entry from {{app_name}}  to MS Dynamic 365',
  defining_employee_cost_centre_subsidiary_ms_business:
    'Defining employee cost centre & subsidiary - MS Dynamics 365',
  ms_business_journal_entry_structure_app: 'MS Dynamic Journal Entry Structure on {{app_name}} ',
  connecting_ms_business_prerequisites: 'Connecting Microsoft Dynamics 365 - Prerequisites',
  setting_connection_with_ms_business_on_app:
    'Setting up a connection with Microsoft Dynamics 365 - Business Central on {{app_name}}  HR',

  how_to_map_netSuite_cost_centers: 'How to map NetSuite cost centers',
  how_to_map_quickbooks_cost_centers: 'How to map QuickBooks cost centers',
  how_to_map_odoo_cost_centers: 'How to map Odoo cost centers',
  how_to_map_xero_cost_centers: 'How to map Xero cost centers',
  how_to_map_qoyod_cost_centers: 'How to map Qoyod cost centers',
  how_to_configure_and_map_netSuite_chart_of_account:
    'How to configure and map NetSuite chart of account',
  how_to_configure_and_map_quickbooks_chart_of_account:
    'How to configure and map QuickBooks chart of account',
  how_to_configure_and_map_odoo_chart_of_account: 'How to configure and map Odoo chart of account',
  how_to_configure_and_map_xero_chart_of_account: 'How to configure and map Xero chart of account',
  how_to_configure_the_structure_of_the_journal_entry:
    'How to configure the structure of the journal entry',
  how_to_configure_the_structure_of_the_journal_entry_xero:
    'How to configure the structure of the journal entry',
  how_to_configure_the_structure_of_the_journal_entry_odoo:
    'How to configure the structure of the journal entry',
  how_to_allocate_the_employees_to_subsidiaries_and_cost_centers:
    'How to allocate the employees to subsidiaries and cost centers',
  how_to_get_these_information_odoo:
    'Don’t know how to get these information? <a>read this article</a>',
  how_to_allocate_the_employees_to_cost_centers: 'How to allocate the employees to cost centers',
  how_to_activate_syncing_employees_information_option:
    'How to activate syncing employees information option',
  how_to_connect_zohoBooks: 'How to connect Zoho Books',
  // I
  items_tracking_dimension: "Cost center's items",
  item_name_in_english: 'Item name in English',
  item_name_in_arabic: 'Item name in Arabic',
  item_code: 'Item code',
  item_tracking_dimension: 'Item tracking dimension',
  items_count: 'Items count',
  i_do_not_use_cost_centers: 'I do not use cost centers',

  // J
  journal_structure: 'Journal Structure',
  journal_structure_managment: 'Journal Structure managment',
  journal_entry_changes_note: 'New changes were made on Journal Entry Description',
  journal_entry_grouping: 'Journal Entry Grouping',
  journal_entry_description: 'Journal Entry Description',
  journal_entry_lines: 'Cost Centers and Employees Information',
  journal_entry_lines_xero: 'Cost Centers Information',
  journal_entry_structure: 'Journal Entry Structure',
  journal_memo_information: 'Memo Information',
  journal_entry: 'Journal Entry',
  journal_entry_ref: 'Journal Entry',
  journal_entry_structure_note:
    'Define the way you want the system to generate journal entry files, the type of cost centers that would be included per journal entry line, and customize the memo description for the journal entry file and lines',
  journal_entry_structure_note_sap:
    'Define the way you want the system to generate journal entry files, the type of cost centers that would be included per journal entry line, and customize the memo description for the journal entry file and lines',
  journal_description_information: 'Description Information',
  journal_line_des_select_2_info:
    'The selected information would be reflected in description field for each journal entry line.',
  journal_line_des_select_2_info_quickbooks:
    'The selected information would be reflected in description field for each journal entry line.',
  journal_line_des_select_info:
    'The selected information would be reflected in Memo field in NetSuite journal entry.',
  journal_line_des_select_info_quickbooks:
    'The selected information would be reflected in Memo field in QuickBooks journal entry.',
  journal_line_des_select_info_xero:
    'The selected information would be reflected in Memo field in Xero journal entry.',
  journal_line_des_select_info_qoyod:
    'The selected information would be reflected in Memo field in Qoyod journal entry.',

  journal_entry_description_note:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in NetSuite.',
  journal_entry_description_note_quickbooks:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in QuickBooks.',
  journal_entry_description_note_xero:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Xero.',
  journal_entry_description_note_qoyod:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Qoyod.',

  journal_entry_description_note_microsoft:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Microsoft.',
  journal_entry_description_note_sap:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Sap Business One.',
  journal_entry_description_note_microsoft_finops:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in MS Dynamics Finance & Operation.',
  journal_entry_description_note_oracle_fusion:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Oracle Fusion.',
  journal_entry_description_note_odoo:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Odoo.',
  journal_entry_description_note_zoho_books:
    'This configuration would allow you to customize the description of the journal entry file and journal entry lines. The description helps you recognizing the journal entries in the review process in Zoho Books.',
  journal_transaction_line_select_note:
    'Select if you want to apply this for all transactions or only for specific transactions.',
  journal_entry_lines_note:
    'Add cost centers and employee information that would be included in the journal entry lines',
  journal_entry_grouping_note:
    'This configuration would group and create a separate journal entry file per the selected entity. Example: if you selected to create journal entry file per employee, the system would create a separate accrual journal entry for each employee',
  jisr_location: '{{app_name}} Location’s ({{number}})',
  integration_create_subsidiary_as: 'Create subsidiary as {{ name }}',
  This_change_will_update_the_cost_center:
    ' You have to update the cost center value to “{{name}}”',
  This_change_will_update_the_subsidiary: ' You have to update the subsidiary value to “{{name}}”',
  // K
  keep_in_sync_with_your_accounting_system: 'Keep in sync with your accounting system',
  // L
  link_your_subsaidary_with_business:
    'Select a business unit, so all employees under business unit will linked automatically',
  Lets_know_what_current_accounting:
    'Before starting. Select what is your current accounting system?',

  // M
  map_them_now: 'Map them now',
  mapping_view: 'Mapping view',
  mapping_the_gl_account: 'Account mapping',
  mapping_cost_centers: 'Mapping Cost Centers',
  map_netsuite_chart_of_accounts: 'Map Netsuite Chart of Accounts',
  map_quickbooks_chart_of_accounts: 'Map Quickbooks Chart of Accounts',
  map_xero_chart_of_accounts: 'Map Xero Chart of Accounts',
  map_qoyod_chart_of_accounts: 'Map Qoyod Chart of Accounts',
  map_microsoft_chart_of_accounts: 'Map Microsoft Chart of Accounts',
  map_microsoft_finops_chart_of_accounts: 'Map MS Dynamics Finance & Operation Chart of Accounts',
  map_oracle_fusion_chart_of_accounts: 'Oracle Fusion Chart of Accounts',
  map_zoho_books_chart_of_accounts: 'Map Zoho Books Chart of Accounts',
  map_sap_chart_of_accounts: 'Map Sap Business One Chart of Accounts',
  map_odoo_chart_of_accounts: 'Map Odoo Chart of Accounts',
  map_xero: ' {{name}}',
  map_odoo: 'odoo {{name}}',
  map_microsoft: 'Microsoft {{name}}',
  map_microsoft_finops: 'MS Dynamics Finance & Operation {{name}}',
  map_oracle_fusion: 'Oracle Fusion {{name}}',
  map_zoho_books: 'Zoho Books {{name}}',
  map_sap_business_one: 'Sap Business One {{name}}',
  ms_environment: 'Environment',
  map_netsuite_chart_of_accounts_note:
    'Map NetSuite chart of accounts with {{app_name}} For each transaction type map the NetSuite account that would log it is transactions. You can define different chart of accounts for specific subsidiary or cost center by clicking on (+) button',
  map_quickbooks_chart_of_accounts_note:
    'Map QuickBooks chart of accounts with {{app_name}}. For each transaction type map the QuickBooks account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_xero_chart_of_accounts_note:
    'Map Xero chart of accounts with {{app_name}}. For each transaction type map the QuickBooks account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_qoyod_chart_of_accounts_note:
    'Map Qoyod chart of accounts with {{app_name}}. For each transaction type map the Qoyod account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',

  map_microsoft_chart_of_accounts_note:
    'Map Microsoft chart of accounts with {{app_name}}. For each transaction type map the Microsoft account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_microsoft_finops_chart_of_accounts_note:
    'Map MS Dynamics Finance & Operation chart of accounts with {{app_name}}. For each transaction type map the Microsoft account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_oracle_fusion_chart_of_accounts_note:
    'Map Oracle Fusion chart of accounts with {{app_name}}. For each transaction type map the Microsoft account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_zoho_books_chart_of_accounts_note:
    'Map Zoho Books chart of accounts with {{app_name}}. For each transaction type map the Zoho Books account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_sap_chart_of_accounts_note:
    'Map Sap Business One chart of accounts with {{app_name}}. For each transaction type map the Sap Business One account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_odoo_chart_of_accounts_note:
    'Map Odoo chart of accounts with {{app_name}}. For each transaction type map the Odoo account that would log it is transactions. You can define different chart of accounts for specific cost center by clicking on (+) button',
  map_cost_center_with_jisr_org_structure:
    'Map NetSuite cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_quickbooks:
    'Map QuickBooks cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_xero:
    'Map Xero cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_qoyod:
    'Map Qoyod cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',

  map_cost_center_with_jisr_org_structure_microsoft:
    'Map Microsoft cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_microsoft_finops:
    'Map MS Dynamics Finance & Operation cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_oracle_fusion:
    'Map Oracle Fusion cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_zoho_books:
    'Map Zoho Books cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_sap:
    'Map Sap Business One cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_cost_center_with_jisr_org_structure_odoo:
    'Map Odoo cost centers with {{app_name}} org-structure (Areas, Locations, and Departments), in case {{app_name}} org-structure is not used as cost centers click on "custom cost center" option',
  map_subsidiaries_setup_note:
    'Map NetSuite subsidiary with {{app_name}} the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  microsoft_map_subsidiaries_setup_note:
    'Map Microsoft subsidiary with {{app_name}} the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  microsoft_finops_map_subsidiaries_setup_note:
    'Map MS Dynamics Finance & Operation subsidiary with the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  oracle_fusion_map_subsidiaries_setup_note:
    'Map Oracle Fusion subsidiary with the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  zoho_books_map_subsidiaries_setup_note:
    'Map Zoho Books subsidiary with the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  sap_map_subsidiaries_setup_note:
    'Map Sap Business one subsidiary with the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  odoo_map_subsidiaries_setup_note:
    'Map Odoo subsidiary with {{app_name}} the related Business Unit. In case business units are not used as subsidiaries click on Use "Subsidiary" option.',
  made_following_changes:
    'There are some changes made, Do you want to save these changes or just dismass it.',
  map_cost_center_type_in_jisr: 'Cost Center Type In {{app_name}}',
  map_netsuite_location: 'NetSuite Locations',
  map_netsuite_department: 'NetSuite Department',
  map_netsuite_class: 'NetSuite Class',
  map_quickbooks_location: 'QuickBooks Locations',
  map_quickbooks_project: 'QuickBooks Project',
  map_qoyod_location: 'Qoyod Locations',
  map_qoyod_project: 'Qoyod Projects',
  map_quickbooks_department: 'QuickBooks Department',
  map_quickbooks_class: 'QuickBooks Class',
  map_jisr_department: '{{app_name}} Department ({{ number }}) ',
  map_jisr_department_level_1: '{{app_name}} Department Level 1 ({{ number }}) ',
  map_jisr_department_level_2: '{{app_name}} Department Level 2 ({{ number }}) ',
  map_jisr_department_level_3: '{{app_name}} Department Level 3 ({{ number }}) ',
  map_jisr_department_level_4: '{{app_name}} Department Level 4 ({{ number }}) ',
  map_jisr_location: '{{app_name}} Location ({{ number }}) ',
  map_jisr_area: '{{app_name}} Area ({{ number }}) ',
  microsoft: 'Microsoft Dynamics 365 Business Central',
  microsoft_finops: 'MS Dynamics Finance & Operation',
  oracle_fusion: 'Oracle Fusion',
  sap_business_one: 'SAP Business One',
  zoho_books: 'Zoho Books',
  odoo: 'Odoo',
  xero_accounts: 'Xero accounts',
  // N
  no_subsidiary: 'You have no subsidiary',
  no_business_unit_assigned:
    ' No business unit assigned to this employee. Assign a business unit first so the subsidiary is assigned accordingly',
  no_tracking_dimensions: 'You have no cost centers',
  netsuite_status: 'Netsuite Status',
  quickbooks_status: 'Quickbooks Status',
  xero_status: 'Xero Status',
  netsuite_mapping_done: 'NetSuite integration setup is done',
  quickbooks_mapping_done: 'QuickBooks integration setup is done',
  xero_mapping_done: 'Xero integration setup is done',
  qoyod_mapping_done: 'Qoyod integration setup is done',
  microsoft_cost_center: 'Microsoft Cost Center',
  microsoft_mapping_done: 'Microsoft integration setup is done',
  microsoft_finops_mapping_done: 'MS Dynamics Finance & Operation integration setup is done',
  oracle_fusion_mapping_done: 'Oracle Fusion integration setup is done',
  zoho_books_mapping_done: 'Zoho books integration setup is done',
  sap_mapping_done: 'Sap Business One integration setup is done',
  odoo_mapping_done: 'Odoo integration setup is done',
  netsuite_mapping_done_dec:
    'Now you are ready to post the journal entries to your NetSuite account.',
  quickbooks_mapping_done_dec:
    'Now you are ready to post the journal entries to your QuickBooks account.',
  xero_mapping_done_dec: 'Now you are ready to post the journal entries to your Xero account.',
  qoyod_mapping_done_dec: 'Now you are ready to post the journal entries to your Qoyod account.',
  microsoft_mapping_done_dec:
    'Now you are ready to post the journal entries to your Microsoft account.',
  microsoft_finops_mapping_done_dec:
    'Now you are ready to post the journal entries to your MS Dynamics Finance & Operation account.',
  oracle_fusion_mapping_done_dec:
    'Now you are ready to post the journal entries to your Oracle Fusion account.',
  zoho_books_mapping_done_dec:
    'Now you are ready to post the journal entries to your Zoho Books account.',
  sap_mapping_done_dec:
    'Now you are ready to post the journal entries to your Sap Business One account.',
  odoo_mapping_done_dec: 'Now you are ready to post the journal entries to your Odoo account.',
  netsuite_subsidiary: 'Netsuite Subsidiary',
  microsoft_subsidiary: 'Microsoft Subsidiary',
  microsoft_finops_subsidiary: 'MS Dynamics Finance & Operation Subsidiary',
  oracle_fusion_subsidiary: 'Oracle Fusion Subsidiary',
  zoho_books_subsidiary: 'Zoho Books Subsidiary',
  sap_subsidiary: 'Sap Business one Subsidiary',
  odoo_subsidiary: 'Odoo Subsidiary',
  netsuite_cost_center: 'Netsuite Cost Center',
  qucikbooks_cost_center: 'Quickbooks Cost Center',
  xero_cost_center: 'Xero Cost Center',
  qoyod_cost_center: 'Qoyod Cost Center',
  odoo_cost_center: 'Odoo Cost Center',
  microsoft_finops_cost_center: 'MS Dynamics Finance & Operation Cost Center',
  oracle_fusion_cost_center: 'Oracle Fusion Cost Center',
  zoho_books_cost_center: 'Zoho Books Cost Center',
  sap_cost_center: 'Sap Business One Cost Center',
  netsuite_account_element: 'Netsuite Account',
  quickbooks_account_element: 'Quickbooks Account',
  microsoft_account_element: 'Microsoft Account',
  microsoft_finops_account_element: 'MS Dynamics Finance & Operation Account',
  oracle_fusion_account_element: 'Oracle Fusion Account',
  zoho_books_account_element: 'Zoho Books Account',
  sap_business_one_account_element: 'Sap Business One Account',
  odoo_account_element: 'Odoo Account',
  xero_account_element: 'Xero Account',
  qoyod_account_element: 'Qoyod Account',
  netsuite_subsidiaries_tab: 'Subsidiary',
  netsuite_cost_center_tab: 'Cost Center',
  netsuite_chart_accountr_tab: 'Chart of Account',
  jisr_transaction_type: '{{app_name}} Transaction Type',
  no_classes_has_been_added: 'No {{app}} was added as a cost center yet',
  no_employee_assigned_to_this_subsidiary: 'No employee assigned to this subsidiary',
  netsuite_assign_employees_to_their_subsidiary_note:
    'You can Assign employee to their subsidiary later on the step of “Employee Cost Allocation”',
  netsuite_department: 'Department',
  netsuite_location: 'Location',
  qoyod_location: 'Location',
  qoyod_project: 'Project',
  netsuite_class: 'Class',
  narration_information: 'Narration Information',
  // O
  org_level_assignment: 'Business unit',
  org_level_assignment_employees: 'Org-Level assign Employees',
  org_level_dec_xero:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Xero {{app}} on the right',
  org_level_dec_qoyod:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Qoyod {{app}} on the right',
  org_level_dec:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with NetSuite {{app}} on the right',
  org_level_dec_quickbooks:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with QuickBooks {{app}} on the right',
  org_level_dec_odoo:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Odoo {{app}} on the right',
  org_level_dec_microsoft:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Microsoft {{app}} on the right',
  org_level_dec_zoho_books:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Zoho Books {{app}} on the right',
  org_level_dec_sap_business_one:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Sap Business One {{app}} on the right',
  org_level_dec_microsoft_finops:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with MS Dynamics Finance & Operation {{app}} on the right',
  org_level_dec_oracle_fusion:
    '{{app_name}} {{jisr_type}} on the left need to be mapped with Oracle Fusion {{app}} on the right',
  optional: 'optional',
  org_level: 'Org-Level',
  other: 'Other',
  other_chart_of_accounts: 'Other Chart Of Accounts',
  other_paytypes: 'Other PayTypes',
  other_deductions: 'Other Deductions',
  other_additions: 'Other Additions',
  org_level_assignment_tracking: 'Org-Structure cost center',
  oracle_segment: 'Oracle Segment',

  // P
  please_select_the_type_of_assign: 'Type',
  project: 'Project',
  posting_date_time: 'Posting Date & Time',
  posted_by: 'Posted By',
  posing_status: 'Posting status',
  posting_successfully: 'Posted successfully',
  partially_posted: 'Partially posted',
  posting_failed: 'Posting failed',

  // Q
  // R
  removing_employees_will_unassign:
    'Removing employees will unassign them from being fixed hours employees, meaning you’ll need to manage their shifts from Scheduler. If you’re planning to re-assign them to other fixed hours template, it’s better to assign them straight from the other template.',
  removed_employees_from_template: 'Removed <Text>{{number}}</Text> employees from {{target}}',
  removed_from_this_shift_template: 'Removed <Text>{{number}}</Text> from {{target}}',
  ms_dynamics_accounting: 'Microsoft Integration',
  ms_dynamics_finops_accounting: 'MS Dynamics Finance & Operation Integration',
  fusion_accounting: 'Oracle Fusion Integration',
  zoho_books_accounting: 'Zoho Books',
  sap_business_one_accounting: 'Sap business One',
  odoo_accounting: 'Odoo Integration',
  oracle_ledger: 'Oracle Ledger',
  oracle_ledger_des: 'Map the Ledger at Oracle Fusion',

  // S
  start_mapping: 'Ok',
  subsidiaries: 'Subsidiaries',
  subsidiary: 'Subsidiary',
  single_subsidiary: 'Subsidiary',
  subsidiaries_tab: 'Subsidiaries',
  subsidiary_mapping: 'Subsidiary Mapping',
  subsidiary_in_netsuite: 'Subsidiary in Netsuite',
  subsidiary_in_microsoft: 'Subsidiary in Microsoft',
  subsidiary_in_microsoft_finops: 'Subsidiary in MS Dynamics Finance & Operation',
  subsidiary_in_oracle_fusion: 'Subsidiary in Oracle Fusion',
  subsidiary_in_zoho_books: 'Subsidiary in Zoho Books',
  subsidiary_in_sap: 'Subsidiary in Sap Business one',
  subsidiary_in_odoo: 'Subsidiary in Odoo',
  subsidiary_in_jisr: 'Subsidiary In {{app_name}}',
  subsidiary_code: 'Subsidiary code',
  subsidiary_name: 'Subsidiary name',
  select_netsuite_location: 'Select Location',
  select_netsuite_department: 'Select Department',
  select_netsuite_class: 'Select Class',
  selected_netsuite_location: 'Location',
  selected_netsuite_project: 'project',
  selected_netsuite_department: 'Department',
  selected_netsuite_class: 'Class',
  search_employess: 'Search employees',
  search_cost_center: 'Search cost center',
  select_microsoft_dimension: 'Select Microsoft dimension',
  select_microsoft_dimension_finops: 'Select MS Dynamics Finance & Operation dimension',
  select_dimension_oracle_fusion: 'Select Oracle fusion dimension',
  select_zoho_books_dimension: 'Select Zoho Books dimension',
  select_sap_business_one_dimension: 'Select Sap Business One dimension',
  select_netsuite_cost_center_types:
    'Select the NetSuite Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_xero_cost_center_types:
    'Select the Xero Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_qoyod_cost_center_types:
    'Select the Qoyod Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_microsoft_cost_center_types:
    'Select the Microsoft Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_microsoft_finops_cost_center_types:
    'Select the MS Dynamics Finance & Operation Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_oracle_fusion_cost_center_types:
    'Select the Oracle Fusion Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_zoho_books_cost_center_types:
    'Select the Zoho Books Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_sap_cost_center_types:
    'Select the Sap Business One Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_odoo_cost_center_types:
    'Select the Odoo Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  select_quickbooks_cost_center_types:
    'Select the QuickBooks Cost Center Type that you would like to be connected with {{app_name}}, If you do not use these options click on “I do not use cost centers” option',
  subsidiary_name_in_english: 'Subsidiary name',
  subsidiary_name_in_arabic: 'Subsidiary name (arabic)',
  subsidiary_notification_note:
    'You have {{ count }} employees under business unit ({{ name }}) are still not assigned to any subsidiary.',
  subsidiary_notification_no_business_unit:
    'You have {{ count }} employees NOT related to any business unit are still not assigned to any subsidiary',
  subsidiary_update_note:
    'Removing employees will unassign them from being fixed hours employees, meaning you’ll need to manage their shifts from Scheduler. If you’re planning to re-assign them to other fixed hours template, it’s better to assign them straight from the other template.',
  select_the_subsidiaries_which_the:
    'Select transaction types that would be mapped to the GL account.',
  select_elements_which_would_used: 'Group by cost center',
  select_location: 'Select Location',
  select_project: 'Select Project',
  select_the_org_level_dimension:
    'Select an organizational structure to add cost center items and employees automatically',
  select_the_tracking_dimension: 'Select an organizational structure',
  select_the_employees_who_will_be_assigned:
    'Select the group of employees who will be under the subsidiary',
  search_by_name_or_code: 'Search by Employee name, Employee Code',
  select_business_unit: 'Select business unit',
  select_employees: 'Select employees',
  select_the_employees_who_will_be_assigned_manual:
    'Select the group of employees who will be under the subsidiary',
  select_the_employees_who_will_be_assigned_tracking:
    'Add cost center Items and employees manually',
  save_and_continue_later: 'Save & continue later',
  set_up_your_general_ledger_mapping: 'Setup your accounting integration',
  gl_setup_now: 'Lets get started',
  subsidiaries_mapping: 'Subsidiaries Mapping',
  select_subsidiaries: 'Select Subsidiaries',
  save_exit: 'Save & Exit',
  synced: 'Synced',
  synced_employee: 'Synced',
  not_synced: 'Not Synced',
  sync_all: 'Sync All',
  sync_all_employees: 'Sync All Employees ({{ number }})',
  sync_all_employees_manual: ' Set employee status as Synced',
  sync_all_employees_manual_sub: ' Enter the Netsuite internal id to set employee status as synced',
  set_as_synced: 'Set as Synced',
  show_the_inactive_employees: 'Show the Inactive Employees',
  select_netSuite_subsidiaries:
    'Select NetSuite subsidiaries that you would like to be connected with {{app_name}}',
  select_microsoft_subsidiaries:
    'Select Microsoft subsidiaries that you would like to be connected with {{app_name}}',
  select_microsoft_finops_subsidiaries:
    'Select MS Dynamics Finance & Operation subsidiaries that you would like to be connected with {{app_name}}',
  select_oracle_fusion_subsidiaries:
    'Select Oracle Fusion subsidiaries that you would like to be connected with {{app_name}}',
  select_zoho_books_subsidiaries:
    'Select Zoho Books subsidiaries that you would like to be connected with {{app_name}}',
  select_sap_subsidiaries:
    'Select Sap Business one subsidiaries that you would like to be connected with {{app_name}}',
  select_odoo_subsidiaries:
    'Select Odoo subsidiaries that you would like to be connected with {{app_name}}',
  gl_setup_done_title: 'Setup completed!',
  specified_transactions: 'Specified Transactions',
  start_now: 'Start Now',
  sync_dimension: 'Sync Dimension',
  select_oracle_gl_account_segment: "Select Oracle's GL Account Segment",
  select_oracle_ledger: 'Select Oracle Ledger',

  // T
  tracking_dimensions: 'Tracking Dimensions',
  there_are_employees_still_not_assigned:
    'We found that some employees their subsidiary is still not defined!',
  total_assign_employees: 'Employees count',
  tracking_dimension_name: 'Cost center name',
  tracking_dimension_type: 'Cost center type',
  tracking_dimensions_tab: 'Cost center',
  transactions_type: 'Transactions type',
  this_transaction_still_not_mapped_to_any_account:
    'There are transactions type still NOT mapped to any account',
  transaction_types_mapped_to_this_account: 'Transaction types mapped to this account',
  there_is_no_transactions_type: 'There is no transactions type mapped to this account yet!',
  tracking_dimension_name_in_english: 'Cost center name in English',
  tracking_dimension_name_in_arabic: 'Cost center name in Arabic',
  there_are_some_transaction_types:
    'There are some transaction types haven’t been mapped to a specfic GL account',
  there_are_some_subsidiaries_not_like_to_link_to_gl_account:
    'There are some subsidiaries NOT like to link to GL account',
  the_business_unit: 'business unit',
  type_system_name_here: 'Type accounting system name',

  // U
  upload_your_chart_of_accounts: 'Upload your chart of accounts',
  update_gl_account: 'Update GL account',
  un_assigned: 'Unassigned',
  un_assign: 'Un Assign',
  updated_assign_employees_type: "Updated Assign employees type, <Text>'{{type}}'</Text> assigment",
  updated_subsidairy_name:
    "Updated subsidairy name, <Text>'{{old}}'</Text> to <Text>'{{new}}'</Text>",
  updated_subsidairy_name_ar:
    "Updated subsidairy name ar, <Text>'{{old}}'</Text> to <Text>'{{new}}'</Text>",
  updated_subsidairy_code:
    "Updated subsidairy code, <Text>'{{old}}'</Text> to <Text>'{{new}}'</Text>",
  updated_tracking_name:
    "Updated Cost center name, <Text>'{{old}}'</Text> to <Text>'{{new}}'</Text>",
  updated_tracking_name_ar:
    "Updated Cost center name ar, <Text>'{{old}}'</Text> to <Text>'{{new}}'</Text>",
  using_this_feature:
    'Now! you can customize your journal voucher the way you want and get it ready to import to your accounting system',
  use_org_structure_in_jisr: 'Use Org-Structure in {{app_name}} As Cost center type',
  unassigned_cost_cente: ' {{number}} Unassigned to cost center',
  unassigned_subsidiary: ' {{number}} Unassigned to subsidiary',
  unassigned_location: ' {{number}} Unassigned to Location',
  unassigned_department: ' {{number}} Unassigned to Department',
  unassigned_class: ' {{number}} Unassigned to Class',
  update_subsidiary: 'Update Subsidiary',
  update_cost_center: 'Update cost center',
  assigned_cost_centers: 'Assigned Cost Centers',
  no_cost_center_was_defined: 'No cost center was defined',
  no_subsidiary_was_defined: 'No subsidiary was defined',
  this_employee_is_not_assigned_to_any_cost_center:
    'This employee is not assigned to any cost center',
  this_employee_is_not_assigned_to_any_subsidiary:
    'This employee is not assigned to any subsidiary',
  // V
  // W
  // X
  // Y
  you_have_transaction_not_mapped_account:
    'You have {{number}} transaction types still not mapped to a specific GL account.',
  you_ready_to_export_your_journal: 'You’re set and ready to export your payroll journal',
  you_have_employees_still_not:
    'You have {{number}} employees still not assigned to specific tracking dimension.',
  you_can_customize_your_journal_entries_for_payroll_description:
    'You can customize your journal entries for Payroll, Accruals, Out of payroll transactions, and more. Enable this feature and get your journal sheet once you close your Payroll',

  // Z
}

export default gl_mapping
