const Muqeem = {
  // A
  // B
  border_number: 'Border Number',
  birth_country: 'Birth country',
  birth_date: 'Birth Date',
  // C
  cancel_exitreentryvisa: 'Cancel Exit Re-Entry Visa',
  cancel_finalexitvisa: 'Cancel Exit Re-Entry Visa',
  // D
  // E
  extend_passport: 'Extend Passport Validity',
  extend_exitreentryvisa: 'Extend Exit Re-Entry Visa',
  // F
  // G
  // H
  // I
  issue_iqama: 'Issue Iqama',
  issue_iqama_desc: 'Issue Iqama for one of the organization’s residents',
  iqama_duration: 'Iqama duration',
  issue_exitreentryvisa: 'Issue Exit Re-Entry Visa',
  issue_finalexitvisa: 'Issue Final Exit Visa',
  // J
  // K
  // L
  // M
  // N
  new_sponsor_id: 'New sponsor ID',
  // O
  organization_number: 'Organization Number',
  // P
  passport_issue_city: 'Passport issue city',
  // Q
  // R
  // S
  // T
  transfer_iqama: 'Transfer Iqama',
  transfer_iqama_desc: 'Iqama Transfer for one of the organization’s residents',
  transfer: 'Transfer',
  translate_first_name: 'Translate first name',
  translate_father_name: 'Translate father name',
  translate_grandfather_name: 'Translate grandfather name',
  translate_family_name: 'Translate family name',
  // U
  // V
  // W
  // X
  // Y
  // Z
  iqama_number: 'Iqama Number',
  old_passport_number: 'Old Passport Number',
  new_passport_number: 'New Passport Number',
  new_passport_issue_date: 'New Passport Issue Date',
  new_passport_expiry_date: 'New Passport Expiry',
  passport_issue_place_mu: 'New Passport Issue Place',
  passport_number: 'Passport Number',
  residentName: 'Resident Name',
  iqamaNumber: 'Iqama Number',
  passportNumber: 'Passport Number',
  nationality: 'Nationality',
  occupation: 'Occupation',
  visaNumber: 'Visa Number',
  services: 'Services',
  visas: 'Visas',
  operation_report: 'Operation Report',
  muqeem_services: 'Muqeem Services',
  muqeem: 'Muqeem',
  how_use_muqeem_services_jisr: 'How to use Muqeem services from {{app_name}}',
  resident_id: 'Resident ID',
  IQAMAS: 'IQAMAS',
  renew_iqama: 'Renew Iqama',
  iqama_description: 'Renew an existing Iqama for one of the organization’s residents',
  passport: 'PASSPORTS',
  renew_passport: 'Renew Passport',
  passport_description: 'Update new passport information for one of the organization’s residents',
  extend_passport_validity: 'Extend Passport Validity',
  extend_passport_description:
    'Update passport validity information by extending the passport expiry date for one of the organization’s residents',
  report: 'REPORTS',
  muqeem_report: 'Muqeem Report',
  muqeem_report_description:
    'Print Muqeem report for specific resident under organization sponsorship',
  exit_re_entry_visa: 'Exit Re-Entry Visa',
  exit_re_entry_visa_bad: 'EXIT RE-ENTRY VISA',
  issue_new: 'Issue New',
  issue_new_description: 'Issue single or multiple entries exit re-entry visa',
  extend_mu: 'Extend',
  extend_description:
    'Extend an existing exit re-entry visa for residents currently outside the kingdom',
  print: 'Print',
  print_description: 'Re-print a previously issued exit re-entry visa',
  cancel: 'Cancel',
  cancel_description: 'Cancel a previously issued exit re-entry visa',
  final_exit_visa: 'Final Exit Visa',
  final_exit_visa_bad: 'FINAL EXIT VISA',
  issue_new_fi: 'Issue New',
  cancel_fin: 'Cancel',
  final_exit_visa_description: 'Issue final exit visa for one of the organization’s residents',
  final_exit_visa_description_ca: 'Cancel a previously issued final exit visa',
  vew_visa: 'New Visa',
  all_visas: 'All Visas',
  exit_re_entry: 'Exit Re-Entry',
  final_exit: 'Final Exit',
  iqama_no: 'Iqama No',
  visa_type: 'Visa Type',
  visa_number: 'Visa Number',
  duration: 'Duration',
  return_before: 'Return Before',
  issue_date: 'Issue date',
  extend_vis: 'Extend',
  cancel_vis: 'Cancel',
  muqeem_services_but: 'Muqeem Services',
  add_authorized_user: 'Add Authorized User',
  add_authorized_user_description:
    'Only users with Manage Personal And Organization information permission are eligible for this authorization',
  how_to_connect: 'How To Connect',
  muqeem_integration_setup_user_management: 'Muqeem integration setup and user management',
  authorized_users: 'Authorized Users',
  authorized_users_who_can_see:
    'Users who can see and execute Muqeem services from {{app_name}}. Admins are automatically authorized.',
  admin_mu: 'Admin',
  gosi_integration_card_note:
    'Sync employees information, Update Salary details, Onboard your registered employees from GOSI to {{app_name}}',
  api_activation: 'API Activation',
  read_article_setup_jisr_muqeem_integration:
    'Read the article to setup {{app_name}} & Muqeem Integration',
  muqeem_integration_requires_subscription_package:
    'Muqeem integration requires subscription package to be "Shamel"',
  activate_indirect_from_your_muqeem_account: 'Activate Indirect API from your Muqeem account',
  step_1: 'Step 1:',
  from_muqeem_portal: 'From Muqeem portal, go to:',
  organization_management_information_packages:
    '.  Organization Management → Organizations Information → <strong> Points and Packages </strong>',
  hen_click_add_new_subscription_button:
    '.  Then click on the <strong> ”Add New Subscription” </strong> button.',
  step_2: 'Step 2:',
  choose_indirect_API_package: 'Choose "Indirect API Package":',
  indirect_API_package:
    '.  By clicking on the "Learn More" button, you will find the details of the setup and the subscription fees for the indirect API package',
  step_3: 'Step 3:',
  click_select_integration_partner_button: 'Click on “Select Integration Partner” button',
  step_4: 'Step 4:',
  once_the_success_message:
    '.  Once the success message "The subscription request has been sent successfully" appears, {{app_name}} will review & approve your integration request.',
  from_integration_partners_list:
    'From the integration partners list, select "{{app_name}} {{app_name}}" and then click on the "Subscribe" button',
  step_5: 'Step 5:',
  allow_working_days: 'Allow 1-2 working days until the integration is approved and activated.',
  please_login_to_continue: 'Please login to continue',
  continue_mu: 'Continue',
  select_employee: ' Select Employee',
  iqama_renewal_duration: 'Iqama renewal duration',
  filled_from_employee_data: 'Filled from employee data',
  report_language: 'Report Language',
  service_details: 'Service Details',
  duration_type: 'Duration Type',
  english: 'English',
  arabic: 'Arabic',
  search_by_id_iqama: 'Search by ID or Iqama or name',
  search_by_id_name: 'Search by ID  or name',
  service: 'Service',
  new_muqeem_service: 'New Muqeem Service',
  submit_to_muqeem: 'Submit To Muqeem',
  cancel_footer: 'cancel',
  action_type_mu: 'Action Type',
  iqamas: 'Iqamas',
  renew: 'Renew',
  months_3: '3 months',
  months_6: '6 months',
  months_9: '9 months',
  months_12: '12 months',
  operation_could_take_some_time_reflect: 'Operation could take some time to reflect on Muqeem',
  Iqama: 'Iqama',
  muqeem_response: 'Muqeem Response',
  resident_name: 'Resident Name',
  version_number: 'Version Number',
  new_iqama_expiry: 'New Iqama Expiry',
  new_iqama_information_mes: 'Iqama information has been updated in the employee profile',
  passports: 'Passports',
  extend_type: 'Extend',
  select_city: 'Select City',
  passport_validity: 'Passport Validity',
  Passport: 'Passport',
  passport_information_mes: 'Passport information has been updated in the employee profile',
  issue: 'Issue',
  single_muq: 'Single',
  multiple: 'Multiple',
  duration_in_days: 'Duration in days',
  return_before_specific_date: 'Return before a specific date',
  visa_duration: 'Visa Duration',
  hijri: 'Hijri:',
  visa_extention_duration: 'Visa Extention Duration',
  last_issued_exit_reentry_visa: 'Last Issued Exit Re-Entry Visa',
  print_visa: 'Print Visa',
  visa_status: 'Visa Status',
  iqama_expiry: 'Iqama Expiry',
  passport_expiry: 'Passport Expiry',
  return_before_old_date: 'Return Before (Old date)',
  return_before_new_date: 'Return Before (New date)',
  renew_Passport: 'Renew Passport',
  extend_Passport: 'Extend Passport Validity',
  issue_ExitReEntryVisa: 'Issue Exit Re-Entry Visa',
  extend_ExitReEntryVisa: 'Extend Exit Re-Entry Visa',
  cancel_ExitReEntryVisa: 'Cancel Exit Re-Entry Visa',
  issue_FinalExitVisa: 'Issue Final Exit Visa',
  cancel_FinalExitVisa: 'Cancel Exit Re-Entry Visa',
  muqeem_user: 'Muqeem User',
  authentication: 'Authentication',
  muqeem_integration_card_note:
    'Connecting with Muqeem enables you to perform issue/cancel/reprint the exit/re-entry visa, issue/cancel the final exit visa, update the passport information and renew the Iqama within {{app_name}} system.',
  muqeem_emarks: 'Muqeem Remarks',
  result: 'Result',
  Time: 'Time',
  operation_type: 'Operation Type',
  last_issued_final_exit_visa: 'Last Issued Final Exit Visa',
  continuemu: 'استمرار',
}
export default Muqeem
