import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { EmployeeConfiguration, EmployeeSalaryFinancial } from './types'

type EmployeeState = {
  employee: EmployeeSalaryFinancial | null
  employee_configuration: EmployeeConfiguration | null
  fetching: boolean
  gosi_error: string
}

const initialState: EmployeeState = {
  employee: null,
  employee_configuration: null,
  fetching: false,
  gosi_error: '',
}

export default function reducer(state = initialState, action: Action): EmployeeState {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_EDIT_LOAD_PENDING:
    case actions.EMPLOYEE_GOSI_DEDUCTION_PENDING:
    case actions.EMPLOYEE_EARNING_POST_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.EMPLOYEE_EARNING_POST_FULFILLED:
      return {
        ...state,
        fetching: false,
      }
    case actions.EMPLOYEE_EDIT_LOAD_FULFILLED:
      if (action.payload.data.message) Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        employee: action.payload.data.data.employee,
      }

    case actions.EMPLOYEE_GOSI_DEDUCTION_FULFILLED:
      if (action.payload.data.message) Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        employee_configuration: action.payload.data?.data?.employee_configuration,
      }

    case actions.EMPLOYEE_EDIT_LOAD_REJECTED:
    case actions.EMPLOYEE_GOSI_DEDUCTION_REJECTED:
    case actions.EMPLOYEE_EARNING_POST_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.GOSI_ERROR_DISMISS_CALL:
      return {
        ...state,
        gosi_error: '',
      }
    default:
      return state
  }
}
