export const EMPLOYEE_INFORMATION_CHANGES_POST = 'EMPLOYEE_INFORMATION_CHANGES_POST'
export const EMPLOYEE_INFORMATION_CHANGES_POST_PENDING = 'EMPLOYEE_INFORMATION_CHANGES_POST_PENDING'
export const EMPLOYEE_INFORMATION_CHANGES_POST_FULFILLED =
  'EMPLOYEE_INFORMATION_CHANGES_POST_FULFILLED'
export const EMPLOYEE_INFORMATION_CHANGES_POST_REJECTED =
  'EMPLOYEE_INFORMATION_CHANGES_POST_REJECTED'

export const EMPLOYEE_INFORMATION_CHANGES_DELETE = 'EMPLOYEE_INFORMATION_CHANGES_DELETE'
export const EMPLOYEE_INFORMATION_CHANGES_DELETE_PENDING =
  'EMPLOYEE_INFORMATION_CHANGES_DELETE_PENDING'
export const EMPLOYEE_INFORMATION_CHANGES_DELETE_FULFILLED =
  'EMPLOYEE_INFORMATION_CHANGES_DELETE_FULFILLED'
export const EMPLOYEE_INFORMATION_CHANGES_DELETE_REJECTED =
  'EMPLOYEE_INFORMATION_CHANGES_DELETE_REJECTED'

export const ORGANIZATION_HISTORY_LOAD = 'ORGANIZATION_HISTORY_LOAD'
export const ORGANIZATION_HISTORY_LOAD_PENDING = 'ORGANIZATION_HISTORY_LOAD_PENDING'
export const ORGANIZATION_HISTORY_LOAD_FULFILLED = 'ORGANIZATION_HISTORY_LOAD_FULFILLED'
export const ORGANIZATION_HISTORY_LOAD_REJECTED = 'ORGANIZATION_HISTORY_LOAD_REJECTED'

export const COMPENSATION_HISTORY_LOAD = 'COMPENSATION_HISTORY_LOAD'
export const COMPENSATION_HISTORY_LOAD_PENDING = 'COMPENSATION_HISTORY_LOAD_PENDING'
export const COMPENSATION_HISTORY_LOAD_FULFILLED = 'COMPENSATION_HISTORY_LOAD_FULFILLED'
export const COMPENSATION_HISTORY_LOAD_REJECTED = 'COMPENSATION_HISTORY_LOAD_REJECTED'
