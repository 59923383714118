export const ASSET_ALLOCATE = 'ASSET_ALLOCATE'
export const ASSET_ALLOCATE_PENDING = 'ASSET_ALLOCATE_PENDING'
export const ASSET_ALLOCATE_FULFILLED = 'ASSET_ALLOCATE_FULFILLED'
export const ASSET_ALLOCATE_REJECTED = 'ASSET_ALLOCATE_REJECTED'

export const ASSET_DEALLOCATE = 'ASSET_DEALLOCATE'
export const ASSET_DEALLOCATE_PENDING = 'ASSET_DEALLOCATE_PENDING'
export const ASSET_DEALLOCATE_FULFILLED = 'ASSET_DEALLOCATE_FULFILLED'
export const ASSET_DEALLOCATE_REJECTED = 'ASSET_DEALLOCATE_REJECTED'

export const ASSET_ALLOCATION_DETAILS = 'ASSET_ALLOCATION_DETAILS'
export const ASSET_ALLOCATION_DETAILS_PENDING = 'ASSET_ALLOCATION_DETAILS_PENDING'
export const ASSET_ALLOCATION_DETAILS_FULFILLED = 'ASSET_ALLOCATION_DETAILS_FULFILLED'
export const ASSET_ALLOCATION_DETAILS_REJECTED = 'ASSET_ALLOCATION_DETAILS_REJECTED'
