export const SAP_BUSINESSES_COST_CENTER_LIST_LOAD = 'SAP_BUSINESSES_COST_CENTER_LIST_LOAD'
export const SAP_BUSINESSES_COST_CENTER_LIST_LOAD_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_LIST_LOAD_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_LIST_LOAD_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_LIST_LOAD_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_LIST_LOAD_PENDING =
  'SAP_BUSINESSES_COST_CENTER_LIST_LOAD_PENDING'

export const SAP_BUSINESSES_COST_CENTER_CREATE = 'SAP_BUSINESSES_COST_CENTER_CREATE'
export const SAP_BUSINESSES_COST_CENTER_CREATE_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_CREATE_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_CREATE_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_CREATE_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_CREATE_PENDING = 'SAP_BUSINESSES_COST_CENTER_CREATE_PENDING'

export const SAP_BUSINESSES_COST_CENTER_UPDATE = 'SAP_BUSINESSES_COST_CENTER_UPDATE'
export const SAP_BUSINESSES_COST_CENTER_UPDATE_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_UPDATE_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_UPDATE_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_UPDATE_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_UPDATE_PENDING = 'SAP_BUSINESSES_COST_CENTER_UPDATE_PENDING'

export const SAP_BUSINESSES_COST_CENTER_DELETE = 'SAP_BUSINESSES_COST_CENTER_DELETE'
export const SAP_BUSINESSES_COST_CENTER_DELETE_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_DELETE_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_DELETE_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_DELETE_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_DELETE_PENDING = 'SAP_BUSINESSES_COST_CENTER_DELETE_PENDING'

export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS = 'SAP_BUSINESSES_COST_CENTER_DIMENSIONS'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_PENDING =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_PENDING'

export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_PENDING =
  'SAP_BUSINESSES_COST_CENTER_DIMENSIONS_VALUES_PENDING'

export const SAP_BUSINESSES_COST_CENTER_ACCOUNTS = 'SAP_BUSINESSES_COST_CENTER_ACCOUNTS'
export const SAP_BUSINESSES_COST_CENTER_ACCOUNTS_FULFILLED =
  'SAP_BUSINESSES_COST_CENTER_ACCOUNTS_FULFILLED'
export const SAP_BUSINESSES_COST_CENTER_ACCOUNTS_REJECTED =
  'SAP_BUSINESSES_COST_CENTER_ACCOUNTS_REJECTED'
export const SAP_BUSINESSES_COST_CENTER_ACCOUNTS_PENDING =
  'SAP_BUSINESSES_COST_CENTER_ACCOUNTS_PENDING'

export const SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD = 'SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD'
export const SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_FULFILLED =
  'SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_FULFILLED'
export const SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_REJECTED =
  'SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_REJECTED'
export const SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_PENDING =
  'SAP_BUSINESSES_JOURNAL_TEMPLATE_LOAD_PENDING'
