/** **************
 *   Auth Actions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function sponsorsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/sponsors/`
  const method = 'get'

  return {
    type: 'SPONSORS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function parentDepartmentsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/parent_departments/`
  const method = 'get'

  return {
    type: 'PARENT_DEPARTMENTS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function departmentsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/departments/`
  const method = 'get'

  return {
    type: params.hierarchical ? 'HIERARCHICAL_DEPARTMENTS_LOAD' : 'DEPARTMENTS_LOAD',
    payload: new Promise((res, rej) => {
      handleAPI(url, params, method)
        .then((response) => {
          res({ params, ...response })
        })
        .catch((err) => {
          rej(err)
        })
    }),
  }
}

export function areasLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/areas/`
  const method = 'get'

  return {
    type: 'AREAS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function locationsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/locations/`
  const method = 'get'

  return {
    type: 'LOCATIONS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function nationalitiesFilterLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/nationalities/`
  const method = 'get'
  return {
    type: 'FILTERED_NATIONALITIES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function jobTitlesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/job_titles/`
  const method = 'get'

  return {
    type: 'JOB_TITLES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function nationalitiesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/nationalities/`
  const method = 'GET'

  return {
    type: 'NATIONALITIES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function outOfPayrunTypesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types`
  const method = 'GET'

  return {
    type: 'OUT_OF_PAYROLL_TYPES',
    payload: handleAPI(url, { ...params, category: 'PayTypePage', template: 'public' }, method),
  }
}
