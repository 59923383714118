import { isEqual, differenceBy } from 'lodash'

export const workflowDataFormat = (values, employee_ids, group_ids) => {
  const workflowSteps = values.workflow_steps.filter((e) => e.approver)
  const data = {
    name: values.name,
    is_applicable_to_all: values.is_applicable_to_all,
    employee_ids: values.is_applicable_to_all ? [] : employee_ids,
    group_ids: values.is_applicable_to_all ? [] : group_ids,
    workflow_steps_attributes: workflowSteps.map((emp, index) => ({
      approver: emp.approver.id ? emp.approver.id : emp.approver,
      step_count: index + 1,
    })),
  }
  return data
}

export const isNameChanged = (data, initialValues) => {
  const initData = workflowDataFormat(
    initialValues,
    initialValues.employee_ids,
    initialValues.group_ids,
  )
  return (
    data.is_applicable_to_all === initData.is_applicable_to_all &&
    data.type_eligibility_by === initialValues.type_eligibility_by &&
    data.employee_ids.length === initData.employee_ids.length &&
    isEqual(data.employee_ids.sort(), initData.employee_ids.sort()) &&
    data.group_ids.length === initData.group_ids.length &&
    isEqual(data.group_ids.sort(), initData.group_ids.sort()) &&
    data.type_ids.length === initialValues.type_ids.length &&
    isEqual(data.type_ids.sort(), initialValues.type_ids.sort()) &&
    data.workflow_steps_attributes.length === initData.workflow_steps_attributes.length &&
    differenceBy(data.workflow_steps_attributes, initData.workflow_steps_attributes, 'approver')
      .length === 0
  )
}

export const letterTypesDataFormat = (values) => {
  if (values?.letter_attachments_header) {
    values.letter_attachments = {
      ...values.letter_attachments,
      ...values.letter_attachments_header,
    }
    delete values.letter_attachments_header
  }
  if (values?.letter_attachments_template) {
    values.letter_attachments = {
      ...values.letter_attachments,
      ...values.letter_attachments_template,
    }
    delete values.letter_attachments_template
  }
  if (values?.letter_attachments_footer) {
    values.letter_attachments = {
      ...values.letter_attachments,
      ...values.letter_attachments_footer,
    }
    delete values.letter_attachments_footer
  }

  if (values.letter_attachments) {
    Object.keys(values.letter_attachments).forEach((key) => {
      if (values.header) {
        const elHeader = document.createElement('div')
        elHeader.innerHTML = `${values.header}`

        elHeader.querySelectorAll('img').forEach((imgEl) => {
          const src = imgEl?.src
          if (src === values.letter_attachments[key]) {
            imgEl.src = `{{{${key}}}}`
          }
        })
        values.header = elHeader.innerHTML
      }

      if (values.template) {
        const elTemplate = document.createElement('div')
        elTemplate.innerHTML = `${values.template}`

        elTemplate.querySelectorAll('img').forEach((imgEl) => {
          const src = imgEl?.src
          if (src === values.letter_attachments[key]) {
            imgEl.src = `{{{${key}}}}`
          }
        })
        values.template = elTemplate.innerHTML
      }

      if (values.footer) {
        const elFooter = document.createElement('div')
        elFooter.innerHTML = `${values.footer}`

        elFooter.querySelectorAll('img').forEach((imgEl) => {
          const src = imgEl?.src
          if (src === values.letter_attachments[key]) {
            imgEl.src = `{{{${key}}}}`
          }
        })
        values.footer = elFooter.innerHTML
      }
    })
  }
  return values
}

export const getFormattedTemplate = (template) => {
  if (template?.letter_attachments) {
    Object.keys(template?.letter_attachments)?.forEach((key) => {
      template.header =
        template.header &&
        template?.header?.replaceAll(`{{{${key}}}}`, template?.letter_attachments[key])
      template.template =
        template?.template &&
        template?.template?.replaceAll(`{{{${key}}}}`, template?.letter_attachments[key])
      template.footer =
        template?.footer &&
        template?.footer?.replaceAll(`{{{${key}}}}`, template?.letter_attachments[key])
    })
  }
  return template
}
