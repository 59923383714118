import { Button, Typography, Flex } from '@jisr-hr/ds'
import { useDispatch, useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { isRtl } from 'utils/uiHelpers'
import { onboardingChangeTourStep } from 'redux/authUser/actionCreators'
import OnboardingTour from 'containers/authorised/NewOnboarding/components/OnboardingTour'
import { onboardingCompletedTour } from 'redux/organizationOnborading/steps/actionCreators'
import styles from './styles.module.css'

const TourStepThree = (): JSX.Element => {
  const dispatch = useDispatch()
  const stepTour = useSelector((s) => s.auth.step_tour)
  const { fetching } = useSelector(({ onboardingSteps }) => onboardingSteps)

  return (
    <div className={styles.onboardingTour}>
      <OnboardingTour
        trigger={<div />}
        open={stepTour === 3}
        position={isRtl ? 'left' : 'right'}
        text={i18n.t('communication.jisr_modules_will_appear_here_gradually', {
          app_name: i18n.t('jisr_translation_name'),
        })}
        content={
          <Typography
            variant="caption"
            text={i18n.t(
              'communication.jisr_modules_will_appear_here_once_you_finish_entering_the_required_steps',
              {
                app_name: i18n.t('jisr_translation_name'),
              },
            )}
          />
        }
        footer={
          <Flex
            justifyBetween
            itemsCenter
          >
            <Typography
              variant="caption"
              text={i18n.t('tour_step', { step: 2, steps: 2 })}
            />
            <Button
              size="small"
              type="button"
              variant="filled"
              color="primary"
              disabled={fetching}
              label={i18n.t('finish')}
              onClick={(): void => {
                dispatch(onboardingCompletedTour({ welcome_page_tour_completed: true })).then(
                  () => {
                    dispatch(onboardingChangeTourStep({ step_tour: null }))
                  },
                )
              }}
            />
          </Flex>
        }
      />
    </div>
  )
}

export default TourStepThree
