export const INBOX_TASKS_LOAD = 'INBOX_TASKS_LOAD'
export const INBOX_TASKS_LOAD_PENDING = 'INBOX_TASKS_LOAD_PENDING'
export const INBOX_TASKS_LOAD_FULFILLED = 'INBOX_TASKS_LOAD_FULFILLED'
export const INBOX_TASKS_LOAD_REJECTED = 'INBOX_TASKS_LOAD_REJECTED'

export const INBOX_TASK_GET = 'INBOX_TASK_GET'
export const INBOX_TASK_GET_PENDING = 'INBOX_TASK_GET_PENDING'
export const INBOX_TASK_GET_FULFILLED = 'INBOX_TASK_GET_FULFILLED'
export const INBOX_TASK_GET_REJECTED = 'INBOX_TASK_GET_REJECTED'

export const INBOX_TASK_UPDATE = 'INBOX_TASK_UPDATE'
export const INBOX_TASK_UPDATE_PENDING = 'INBOX_TASK_UPDATE_PENDING'
export const INBOX_TASK_UPDATE_FULFILLED = 'INBOX_TASK_UPDATE_FULFILLED'
export const INBOX_TASK_UPDATE_REJECTED = 'INBOX_TASK_UPDATE_REJECTED'

export const INBOX_TASK_REASSIGN = 'INBOX_TASK_REASSIGN'
export const INBOX_TASK_REASSIGN_PENDING = 'INBOX_TASK_REASSIGN_PENDING'
export const INBOX_TASK_REASSIGN_FULFILLED = 'INBOX_TASK_REASSIGN_FULFILLED'
export const INBOX_TASK_REASSIGN_REJECTED = 'INBOX_TASK_REASSIGN_REJECTED'
