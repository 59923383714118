import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { HTTPValidationError } from 'types/httpTypes'
import {
  TPreviewTemplatesAxiosResponse,
  TTemplatesAxiosResponse,
  TTemplatesParams,
} from './useTemplates.types'

// keys
const templatesKey = (params?: TTemplatesParams): QueryKey => ['get_templates', params]
const mostUsedtemplatesKey = (params?: TTemplatesParams): QueryKey => [
  'get_most_used_templates',
  params,
]
const previewTemplatesKey = (id?: number | null | undefined): QueryKey => [
  'get_preview_templates',
  id,
]

// api
export const getTemplates = (params: TTemplatesParams): Promise<TTemplatesAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/automation_templates`, { params })
}

export const getMostUsedTemplates = (
  params: TTemplatesParams,
): Promise<TTemplatesAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/automation_templates`, {
    params: {
      ...params,
      'sort[automation_count]': 'DESC',
      rpp: 3,
    },
  })
}

export const getPreviewTemplates = (
  id: number | null | undefined,
): Promise<TPreviewTemplatesAxiosResponse> => {
  return api.get(`/${API_VERSION_TWO}/hr/automation_templates/${id}`)
}

// hooks

/**
 * @endpoint: GET: /v2/hr/automation_templates
 * @summary This hook used to list templates
 */
export const useTemplates = <TData = TTemplatesAxiosResponse, TError = HTTPValidationError>(
  params: TTemplatesParams,
  opts?: {
    query?: UseQueryOptions<TTemplatesAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: templatesKey(params),
    queryFn: () => getTemplates(params),
  })
}

/**
 * @endpoint: GET: /v2/hr/automation_templates?sort[automation_count]=DESC
 * @summary This hook used to list most used templates
 */

export const useMostUsedTemplates = <TData = TTemplatesAxiosResponse, TError = HTTPValidationError>(
  params: TTemplatesParams,
  opts?: {
    query?: UseQueryOptions<TTemplatesAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: mostUsedtemplatesKey(params),
    queryFn: () => getMostUsedTemplates(params),
  })
}

export const usePreviewTemplates = <
  TData = TPreviewTemplatesAxiosResponse,
  TError = HTTPValidationError,
>(
  id: number | null | undefined,
  opts?: {
    query?: UseQueryOptions<TPreviewTemplatesAxiosResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: previewTemplatesKey(id),
    queryFn: () => getPreviewTemplates(id),
  })
}
