import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  selected_types: [],
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.LEAVE_REQUEST_WORKFLOW_SELECTED_TYPES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LEAVE_REQUEST_WORKFLOW_SELECTED_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.LEAVE_REQUEST_WORKFLOW_SELECTED_TYPES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
