export const TICKET_PROVISION_POLICIES_LOAD = 'TICKET_PROVISION_POLICIES_LOAD'
export const TICKET_PROVISION_POLICIES_LOAD_PENDING = 'TICKET_PROVISION_POLICIES_LOAD_PENDING'
export const TICKET_PROVISION_POLICIES_LOAD_FULFILLED = 'TICKET_PROVISION_POLICIES_LOAD_FULFILLED'
export const TICKET_PROVISION_POLICIES_LOAD_REJECTED = 'TICKET_PROVISION_POLICIES_LOAD_REJECTED'

export const TICKET_PROVISION_POLICIES_CREATE = 'TICKET_PROVISION_POLICIES_CREATE'
export const TICKET_PROVISION_POLICIES_CREATE_PENDING = 'TICKET_PROVISION_POLICIES_CREATE_PENDING'
export const TICKET_PROVISION_POLICIES_CREATE_FULFILLED =
  'TICKET_PROVISION_POLICIES_CREATE_FULFILLED'
export const TICKET_PROVISION_POLICIES_CREATE_REJECTED = 'TICKET_PROVISION_POLICIES_CREATE_REJECTED'

export const TICKET_PROVISION_POLICIES_UPDATE = 'TICKET_PROVISION_POLICIES_UPDATE'
export const TICKET_PROVISION_POLICIES_UPDATE_PENDING = 'TICKET_PROVISION_POLICIES_UPDATE_PENDING'
export const TICKET_PROVISION_POLICIES_UPDATE_FULFILLED =
  'TICKET_PROVISION_POLICIES_UPDATE_FULFILLED'
export const TICKET_PROVISION_POLICIES_UPDATE_REJECTED = 'TICKET_PROVISION_POLICIES_UPDATE_REJECTED'

export const TICKET_PROVISION_POLICIES_ADD = 'TICKET_PROVISION_POLICIES_ADD'
export const TICKET_PROVISION_POLICIES_CANCEL = 'TICKET_PROVISION_POLICIES_CANCEL'
