import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Trans } from 'react-i18next'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import IncreaseDecreaseField from 'components/global/templates/RequestDrawer/ds/IncreaseDecreaseField'
import { useForm } from 'react-final-form'
import { durationByMinutes } from 'components/global/form/formNormalize'
import { Typography } from '@jisr-hr/ds'

const ExcuseDuration = ({ recorded, clock }) => {
  const { change } = useForm()

  React.useEffect(() => {
    if (recorded > 0) {
      change('duration', durationByMinutes(recorded))
    }
  }, [recorded])

  const maxDuration = durationByMinutes(recorded || 60)

  return (
    <Frame title={I18n.t('excuse_duration')}>
      <IncreaseDecreaseField
        name="duration"
        maxTime={maxDuration}
      />
      {recorded > 0 ? (
        <Typography
          text={
            <>
              <Trans
                i18nKey="the_full_recorded_duration"
                components={{ b: <b style={{ color: '#000' }} /> }}
                values={{ minutes: recorded }}
              />
            </>
          }
          style={{ color: 'var(--color-base-colors-grey-600)' }}
          variant="body-new/regular"
        />
      ) : (
        <Typography
          text={
            <>
              <Trans
                i18nKey="estimated_delay_duration"
                components={{ b: <b style={{ color: '#000' }} /> }}
                values={{ minutes: `${clock} ${I18n.t('hour')}` }}
              />
            </>
          }
          style={{ color: 'var(--color-base-colors-grey-600)' }}
          variant="body-new/regular"
        />
      )}
    </Frame>
  )
}

ExcuseDuration.propTypes = {
  clock: PropTypes.number,
  recorded: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ExcuseDuration
