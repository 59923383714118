export const ORGNIZTION_STRUCTURE_LOAD = 'ORGNIZTION_STRUCTURE_LOAD'
export const ORGNIZTION_STRUCTURE_LOAD_FULFILLED = 'ORGNIZTION_STRUCTURE_LOAD_FULFILLED'
export const ORGNIZTION_STRUCTURE_LOAD_REJECTED = 'ORGNIZTION_STRUCTURE_LOAD_REJECTED'

export const ORGNIZTION_STRUCTURE_DELETE = 'ORGNIZTION_STRUCTURE_DELETE'
export const ORGNIZTION_STRUCTURE_DELETE_FULFILLED = 'ORGNIZTION_STRUCTURE_DELETE_FULFILLED'
export const ORGNIZTION_STRUCTURE_DELETE_REJECTED = 'ORGNIZTION_STRUCTURE_DELETE_REJECTED'

export const ORGNIZTION_STRUCTURE_CHANGE = 'ORGNIZTION_STRUCTURE_CHANGE'
export const ORGNIZTION_STRUCTURE_CHANGE_FULFILLED = 'ORGNIZTION_STRUCTURE_CHANGE_FULFILLED'
export const ORGNIZTION_STRUCTURE_CHANGE_REJECTED = 'ORGNIZTION_STRUCTURE_CHANGE_REJECTED'
