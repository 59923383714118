import { useEffect } from 'react'
import { FormApi } from 'final-form'
import { Button, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import { BetaTextInputField as TextInputField, Form } from 'components/final-form'
import { email } from 'components/global/form/FormValidations'
import { listOnboardingAdmins } from 'redux/organizationOnborading/inviteAdmins/actionCreators'

type TProps = {
  fetching: boolean
  onSubmit: (
    values: { email: string },
    fun: FormApi<{ email: string }, Partial<{ email: string }>>,
  ) => void
}

const InviteForm = (props: TProps): JSX.Element => {
  const { fetching } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listOnboardingAdmins({ within_first_admin: 1 }))
  }, [])

  return (
    <Form onSubmit={props.onSubmit}>
      {({ handleSubmit, pristine }): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <Flex
            className="gap-[12px] w-full"
            itemsCenter
          >
            <TextInputField
              size="regular"
              name="email"
              placeholder={i18n.t('label.enter_email_address')}
              validate={email}
            />
            <Button
              size="medium"
              disabled={fetching || pristine}
              className="flex-grow-0 h-full"
              onClick={handleSubmit}
              label={i18n.t('label.invite')}
              leadingIcon="send-01"
            />
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default InviteForm
