import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationTypes } from 'types/sharedResponse'
import { Action } from 'types/redux'
import * as actions from './actions'
import { AuditLogType } from './types'

const initialState = {
  fetching: false,
  audit_records: [] as AuditLogType[],
  pagination: {} as PaginationTypes,
}

type AuditLogState = typeof initialState

export default function reducer(state = initialState, action: Action): AuditLogState {
  const { payload } = action
  switch (action.type) {
    case actions.AUDIT_LOG_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.AUDIT_LOG_LOAD_FULFILLED:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...payload.data.data,
        audit_records: [
          ...(payload.data.data?.pagination?.previous_page ? state.audit_records : []),
          ...payload.data.data?.audit_records,
        ],
        fetching: false,
      }

    case actions.AUDIT_LOG_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
