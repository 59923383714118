import { Lens } from '@dhmk/zustand-lens'
import { ReactNode } from 'react'

export type ModalOptionsType = {
  onSuccess?: () => void
  onCancel?: () => void
  title?: string
  message?: string | ReactNode
  fetching: boolean
  subMessage?: string | ReactNode
  hideCancel?: boolean
  manualClose?: boolean
  labelCancel?: string
  width?: number
  labelConfirm?: string
  variant?: 'neutral' | 'danger' | 'info' | 'success' | 'warning'
  icon?: string
  size?: string
  fullBtnWidth?: boolean
  withIcon?: boolean
  fancy?: boolean
  customContent?: ReactNode
}

export type CreateConfirmationType = {
  modalOptions: ModalOptionsType | null
  setModalOptions: (options: ModalOptionsType | null) => void
}

export const createConfirmationSlice: Lens<CreateConfirmationType> = (set) => ({
  modalOptions: null,
  setModalOptions: (options): void => set(() => ({ modalOptions: options })),
})
