export const COMBO_SHIFT_LISTING_LOAD = 'COMBO_SHIFT_LISTING_LOAD'
export const COMBO_SHIFT_LISTING_LOAD_PENDING = 'COMBO_SHIFT_LISTING_LOAD_PENDING'
export const COMBO_SHIFT_LISTING_LOAD_FULFILLED = 'COMBO_SHIFT_LISTING_LOAD_FULFILLED'
export const COMBO_SHIFT_LISTING_LOAD_REJECTED = 'COMBO_SHIFT_LISTING_LOAD_REJECTED'

export const COMBO_SHIFT_LOAD = 'COMBO_SHIFT_LOAD'
export const COMBO_SHIFT_LOAD_PENDING = 'COMBO_SHIFT_LOAD_PENDING'
export const COMBO_SHIFT_LOAD_FULFILLED = 'COMBO_SHIFT_LOAD_FULFILLED'
export const COMBO_SHIFT_LOAD_REJECTED = 'COMBO_SHIFT_LOAD_REJECTED'

export const CREATE_COMBO_SHIFT = 'CREATE_COMBO_SHIFT'
export const CREATE_COMBO_SHIFT_PENDING = 'CREATE_COMBO_SHIFT_PENDING'
export const CREATE_COMBO_SHIFT_FULFILLED = 'CREATE_COMBO_SHIFT_FULFILLED'
export const CREATE_COMBO_SHIFT_REJECTED = 'CREATE_COMBO_SHIFT_REJECTED'

export const UPDATE_COMBO_SHIFT = 'UPDATE_COMBO_SHIFT'
export const UPDATE_COMBO_SHIFT_PENDING = 'UPDATE_COMBO_SHIFT_PENDING'
export const UPDATE_COMBO_SHIFT_FULFILLED = 'UPDATE_COMBO_SHIFT_FULFILLED'
export const UPDATE_COMBO_SHIFT_REJECTED = 'UPDATE_COMBO_SHIFT_REJECTED'

export const DELETE_COMBO_SHIFT = 'DELETE_COMBO_SHIFT'
export const DELETE_COMBO_SHIFT_PENDING = 'DELETE_COMBO_SHIFT_PENDING'
export const DELETE_COMBO_SHIFT_FULFILLED = 'DELETE_COMBO_SHIFT_FULFILLED'
export const DELETE_COMBO_SHIFT_REJECTED = 'DELETE_COMBO_SHIFT_REJECTED'
