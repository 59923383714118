import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type ExternalNationalitiesPorps = {
  filterKey: string
  filters: string[]
}

const ExternalNationalities = (props: ExternalNationalitiesPorps): JSX.Element => {
  const { lookups } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  return (
    <FilterBox
      handleFilter={handleFilter}
      options={lookups.nationality}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('nationalities')}
    />
  )
}

export default ExternalNationalities
