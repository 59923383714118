import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type AttendanceStatusTypes = {
  filterKey: string
  filters: string[]
}

const AttendanceStatus = (props: AttendanceStatusTypes): JSX.Element => {
  const { filter_attendance_status } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(filter_attendance_status)

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        filter_attendance_status: [],
      })
    } else {
      handleFilter('filter_attendance_status', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.filter_attendance_status)) {
      setSelectedIds(filter.filter_attendance_status)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.filter_attendance_status])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      valueKey="id"
      labelKey="name"
      {...props}
      componentType="badges"
    />
  )
}

export default AttendanceStatus
