import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Button, Spacer } from '@jisr-hr/ds'
import PaymentAlert from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/PaymentAlert'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import {
  createMudadSignatory,
  mudadFlowStepsLoad,
  mudadSignatoriesLoad,
  mudadSignatoriesVerify,
  mudadSignatoryDelete,
  mudadVerifyEmployeesLoad,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import { MudadSignatoriesType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import i18n from 'translations/i18n'

import MudadStepsTemplate from '../components/MudadStepsTemplate'
import { usePaymentMethod } from '../../../components/usePaymentMethods'
import VerifyAdminForm from './VerifyAdminForm'
import SignatoryBox from './SignatoryBox'
import MudadOTPCounter from '../components/MudadOTPForm'

const VerifyPaymentAdmin: FC = () => {
  const dispatch = useDispatch()

  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod, currentSignatory } = paymentCtx
  const signatoriesList = useSelector((s) => s.mudadIntegration.mudad_signatories)
  const fetching = useSelector((s) => s.mudadIntegration.fetching)
  const loading = useSelector((s) => s.mudadIntegration.loading)
  const paymentMethod = useSelector((s) => s.paymentMethods.current_payment_method)
  const is_mudad_completed = !!paymentMethod?.mudad_establishment?.is_completed

  const [openCreateForm, setOpenCreateForm] = useState(false)

  const canPass = useMemo(() => {
    return signatoriesList?.findIndex((sing) => sing.status === 'verified') >= 0
  }, [signatoriesList])

  // ? functions
  const handleCreateVerifier = async (employee_id: number): Promise<void> => {
    try {
      if (!selectedPaymentMethod) return
      const res = await dispatch(
        createMudadSignatory(selectedPaymentMethod.registration_id, {
          employee_id,
        }),
      )
      const signatory = res.value.data.data.mudad_signatory as MudadSignatoriesType
      paymentCtx.setCurrentSignatory(signatory)
      setOpenCreateForm(true)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error.response.data.error,
      })
    }
  }

  const handleConfirmOtp = async (data: { otp_code: string }): Promise<void> => {
    if (!selectedPaymentMethod) return
    if (!currentSignatory) return
    try {
      const { registration_id } = selectedPaymentMethod
      await dispatch(
        mudadSignatoriesVerify(registration_id, currentSignatory?.id, {
          otp: data.otp_code,
        }),
      )
      if (signatoriesList?.findIndex((sing) => sing.status === 'verified') === -1) {
        await dispatch(mudadFlowStepsLoad(registration_id))
      }
      dispatch(mudadSignatoriesLoad(registration_id))
      paymentCtx.setCurrentSignatory(null)
      setOpenCreateForm(false)
    } catch (e) {
      const error = e as { response: { data: { error: string } } }
      paymentCtx.setModalOptions({
        fetching: false,
        message: error.response.data.error,
      })
    }
  }

  const handleDeleteSignatory = (signatory_id: number): void => {
    if (!selectedPaymentMethod) return
    const { registration_id } = selectedPaymentMethod
    const options = {
      fetching: false,
      message: i18n.t('are_you_sure'),
      async onSuccess(): Promise<void> {
        paymentCtx.setModalOptions({ ...this, fetching: true })
        try {
          await dispatch(mudadSignatoryDelete(registration_id, signatory_id))
          paymentCtx.setModalOptions(null)
          paymentCtx.setCurrentSignatory(null)
          setOpenCreateForm(false)
        } catch (e) {
          const err = e as { response: { data: { error: string } } }
          paymentCtx.setModalOptions({
            ...this,
            fetching: false,
            subMessage: err?.response?.data?.error || 'Something went wrong',
            onSuccess: undefined,
          })
        }
      },
    }

    paymentCtx.setModalOptions(options)
  }

  useEffect(() => {
    if (selectedPaymentMethod) {
      dispatch(mudadSignatoriesLoad(selectedPaymentMethod.registration_id))
      dispatch(mudadVerifyEmployeesLoad(selectedPaymentMethod.registration_id))
    }
    paymentCtx.setCurrentSignatory(null)
  }, [])

  return (
    <MudadStepsTemplate
      disableBackBtn={is_mudad_completed}
      disableNextBtn={!canPass}
      onNextClick={(): void => paymentCtx.setMudadCurrentStep(4)}
    >
      <div>
        <Typography
          variant="interface/default/sm"
          style={{ lineHeight: '20px', color: 'var(--color-base-colors-black-1000)' }}
          text={i18n.t('verification_with_the_responsible')}
        />
        <Typography
          variant="interface/default/sm"
          style={{ lineHeight: '20px', marginTop: 4, color: 'var(--color-base-colors-grey-800)' }}
          text={i18n.t('select_the_employee_that_authorized')}
        />
      </div>

      <PaymentAlert
        style={{ marginBlock: 24 }}
        title={i18n.t('make_sure_that_the_authorized_person_in_the_bank_has_jisr_account')}
      />
      {!openCreateForm && fetching && signatoriesList?.length <= 0 && (
        <Typography
          variant="interface/default/sm"
          style={{ lineHeight: '20px', marginTop: 4, color: 'var(--color-base-colors-grey-800)' }}
          text={i18n.t('please_wait')}
        />
      )}

      {signatoriesList?.length > 0 && (
        <div
          className={mainStyles.info_alert_box}
          style={{ border: 'unset' }}
        >
          <Typography
            variant="interface/default/sm"
            style={{
              lineHeight: '20px',
              marginBottom: 24,
              color: 'var(--color-base-colors-grey-900)',
            }}
            text={i18n.t('authorized_employees')}
          />
          {/* ? this should be shared */}
          {signatoriesList.map((signatory, index) => {
            return (
              <Fragment key={signatory.id}>
                <SignatoryBox
                  signatory={signatory}
                  onReVerify={(): void => {
                    setOpenCreateForm(true)
                    paymentCtx.setCurrentSignatory(signatory)
                  }}
                  onDeleteClick={(): void => handleDeleteSignatory(signatory.id)}
                />
                {index + 1 !== signatoriesList.length && (
                  <Spacer
                    width={0}
                    height={16}
                  />
                )}
              </Fragment>
            )
          })}
          {/* ? list end */}

          {!openCreateForm && (
            <Button
              label={i18n.t('verify_another_employee')}
              size="small"
              variant="ghost"
              style={{ marginTop: 24 }}
              onClick={(): void => setOpenCreateForm(true)}
              leadingIcon="plus"
            />
          )}
        </div>
      )}
      {((!fetching && signatoriesList?.length <= 0) || openCreateForm) && (
        <>
          <Spacer
            width={0}
            height={24}
          />
          <VerifyAdminForm
            onSubmit={(data): Promise<void> => handleCreateVerifier(data.id)}
            initialValues={{ id: currentSignatory?.employee?.id }}
          />
          <Spacer
            width={0}
            height={24}
          />
          {!!currentSignatory && (
            <MudadOTPCounter
              onSubmit={handleConfirmOtp}
              onResend={(): void => {
                if (!currentSignatory?.employee) return
                handleCreateVerifier(currentSignatory.employee.id)
              }}
              updated_at={currentSignatory.updated_at}
              disabled={fetching || loading}
            />
          )}
          {!currentSignatory && (
            <Spacer
              width={0}
              height={100}
            />
          )}
        </>
      )}
    </MudadStepsTemplate>
  )
}

export default VerifyPaymentAdmin
