export const JOURNAL_STRUCTURE_LOAD = 'JOURNAL_STRUCTURE_LOAD'
export const JOURNAL_STRUCTURE_LOAD_PENDING = 'JOURNAL_STRUCTURE_LOAD_PENDING'
export const JOURNAL_STRUCTURE_LOAD_FULFILLED = 'JOURNAL_STRUCTURE_LOAD_FULFILLED'
export const JOURNAL_STRUCTURE_LOAD_REJECTED = 'JOURNAL_STRUCTURE_LOAD_REJECTED'

export const JOURNAL_STRUCTURE_UPDATE = 'JOURNAL_STRUCTURE_UPDATE'
export const JOURNAL_STRUCTURE_UPDATE_PENDING = 'JOURNAL_STRUCTURE_UPDATE_PENDING'
export const JOURNAL_STRUCTURE_UPDATE_FULFILLED = 'JOURNAL_STRUCTURE_UPDATE_FULFILLED'
export const JOURNAL_STRUCTURE_UPDATE_REJECTED = 'JOURNAL_STRUCTURE_UPDATE_REJECTED'

export const JOURNAL_STATUS = 'JOURNAL_STATUS'
export const JOURNAL_STATUS_PENDING = 'JOURNAL_STATUS_PENDING'
export const JOURNAL_STATUS_FULFILLED = 'JOURNAL_STATUS_FULFILLED'
export const JOURNAL_STATUS_REJECTED = 'JOURNAL_STATUS_REJECTED'
