// export const NETSUIT_CONNECT_ACCOUNT = 'NETSUIT_CONNECT_ACCOUNT';
// export const NETSUIT_CONNECT_ACCOUNT_FULFILLED = 'NETSUIT_CONNECT_ACCOUNT_FULFILLED';
// export const NETSUIT_CONNECT_ACCOUNT_REJECTED = 'NETSUIT_CONNECT_ACCOUNT_REJECTED';
// export const NETSUIT_CONNECT_ACCOUNT_PENDING = 'NETSUIT_CONNECT_ACCOUNT_PENDING';

export const NETSUIT_SUBSIDARIES_LOAD = 'NETSUIT_SUBSIDARIES_LOAD'
export const NETSUIT_SUBSIDARIES_LOAD_FULFILLED = 'NETSUIT_SUBSIDARIES_LOAD_FULFILLED'
export const NETSUIT_SUBSIDARIES_LOAD_REJECTED = 'NETSUIT_SUBSIDARIES_LOAD_REJECTED'
export const NETSUIT_SUBSIDARIES_LOAD_PENDING = 'NETSUIT_SUBSIDARIES_LOAD_PENDING'

export const NETSUITE_MAPPED_SUBSIDIARIES = 'NETSUITE_MAPPED_SUBSIDIARIES'
export const NETSUITE_MAPPED_SUBSIDIARIES_FULFILLED = 'NETSUITE_MAPPED_SUBSIDIARIES_FULFILLED'
export const NETSUITE_MAPPED_SUBSIDIARIES_REJECTED = 'NETSUITE_MAPPED_SUBSIDIARIES_REJECTED'
export const NETSUITE_MAPPED_SUBSIDIARIES_PENDING = 'NETSUITE_MAPPED_SUBSIDIARIES_PENDING'

export const NETSUITE_SUBSIDIARY_CREATE = 'NETSUITE_SUBSIDIARY_CREATE'
export const NETSUITE_SUBSIDIARY_CREATE_FULFILLED = 'NETSUITE_SUBSIDIARY_CREATE_FULFILLED'
export const NETSUITE_SUBSIDIARY_CREATE_REJECTED = 'NETSUITE_SUBSIDIARY_CREATE_REJECTED'
export const NETSUITE_SUBSIDIARY_CREATE_PENDING = 'NETSUITE_SUBSIDIARY_CREATE_PENDING'

export const NETSUITE_SUBSIDIARY_DELETE = 'NETSUITE_SUBSIDIARY_DELETE'
export const NETSUITE_SUBSIDIARY_DELETE_FULFILLED = 'NETSUITE_SUBSIDIARY_DELETE_FULFILLED'
export const NETSUITE_SUBSIDIARY_DELETE_REJECTED = 'NETSUITE_SUBSIDIARY_DELETE_REJECTED'
export const NETSUITE_SUBSIDIARY_DELETE_PENDING = 'NETSUITE_SUBSIDIARY_DELETE_PENDING'

export const NETSUITE_SUBSIDIARY_UPDATE = 'NETSUITE_SUBSIDIARY_UPDATE'
export const NETSUITE_SUBSIDIARY_UPDATE_FULFILLED = 'NETSUITE_SUBSIDIARY_UPDATE_FULFILLED'
export const NETSUITE_SUBSIDIARY_UPDATE_REJECTED = 'NETSUITE_SUBSIDIARY_UPDATE_REJECTED'
export const NETSUITE_SUBSIDIARY_UPDATE_PENDING = 'NETSUITE_SUBSIDIARY_UPDATE_PENDING'

export const NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN = 'NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'

export const NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN = 'NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED'
export const NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING =
  'NETSUITE_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING'
