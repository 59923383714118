import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type EndorsementMemberTypeTypes = {
  filterKey: string
  filters: string[]
}

const EndorsementMemberType = (props: EndorsementMemberTypeTypes): JSX.Element => {
  const { endorsement_member_type_data } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])

  const optionList = returnBadgeList(endorsement_member_type_data)
  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        endorsement_member_type: [],
      })
    } else {
      handleFilter('endorsement_member_type', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.endorsement_member_type)) {
      if (filter.endorsement_member_type?.length === 2) {
        setFilter({ ...filter, endorsement_member_type: [filter.endorsement_member_type.at(1)] })
      } else {
        setSelectedIds(filter.endorsement_member_type)
      }
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.endorsement_member_type?.length])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      labelKey="name"
      {...props}
      title={i18n.t('membership_type')}
    />
  )
}

export default EndorsementMemberType
