import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import {
  Workflow,
  Tabs,
  Info,
  Comments,
  Reason,
} from 'components/global/templates/RequestDrawer/components'
import I18n from 'translations/i18n'
import { ReactComponent as DelegationIcon } from 'assets/figma-icons/Delegation-To.svg'
import EmployeeProfile from 'components/global/templates/RequestDrawer/ds/EmployeeProfile/EmployeeProfile'
import { differenceInDays } from 'date-fns'

import LeaveDuration from '../../components/LeaveDuration'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const DelegationRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const { from_date, to_date } = request
  const [activeTab, setActiveTab] = React.useState('request_details')

  const employeeProfile = {
    ...request?.delegator,
    avatar: request?.delegator?.avatar_thumb,
    name: request?.delegator?.name_i18n,
    jobTitle: request?.delegator?.job_title_i18n,
    typographyTitle: 'body-new/regular',
    typographySubTitle: 'subtitle-2',
    weight: 'medium',
    avatarStyle: { height: 30, width: 30 },
  }
  const items = [
    {
      icon: <DelegationIcon />,
      label: I18n.t('label.delegate_to'),
      value: <EmployeeProfile {...employeeProfile} />,
    },
  ]

  const applicable_days = (from_date, to_date)
    ? differenceInDays(new Date(to_date), new Date(from_date))
    : null

  return (
    <Flex
      flexCol
      style={{ gap: 16 }}
    >
      {loading && <JisrLoader absolute />}

      <Tabs
        active={activeTab}
        onChange={(tab) => setActiveTab(tab)}
      />
      {activeTab === 'request_details' && (
        <>
          <Info
            details={{
              ...request,
              type_i18n: request?.request_name_i18n,
              requested_on: request.created_at,
              employee: request?.employee,
              items,
            }}
          />

          {(request.reason || request.attachments?.length > 0) && (
            <Reason
              details={{
                ...request,
                reason: request.reason,
                attachments: request.attachments,
              }}
            />
          )}
          {['pending', 'approved'].includes(request.status) &&
            !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
            !request?.is_cancellation_request && <LinkedTaskWithReq />}
          <LeaveDuration
            title={I18n.t('delegation_duration')}
            applicable_days={applicable_days}
            leave_type_id
            from={from_date}
            to={to_date}
            showDetails
          />

          <Workflow
            lessDetail
            onClick={() => setActiveTab('workflow_and_comments')}
            approvals={request?.approvals}
          />
        </>
      )}

      {activeTab === 'workflow_and_comments' && (
        <>
          <Comments
            comments={request?.comments || []}
            reloadRequest={reloadRequest}
            approval={{
              request_id: request.id,
              request_type: request?.request_type,
            }}
            can_add_comment={request?.can_add_comment}
          />
          <Workflow approvals={request?.approvals} />
        </>
      )}
    </Flex>
  )
}

export default DelegationRequest
