export const ASSETMANAGER_BULK_UPLOAD = 'ASSETMANAGER_BULK_UPLOAD'
export const ASSETMANAGER_BULK_UPLOAD_PENDING = 'ASSETMANAGER_BULK_UPLOAD_PENDING'
export const ASSETMANAGER_BULK_UPLOAD_FULFILLED = 'ASSETMANAGER_BULK_UPLOAD_FULFILLED'
export const ASSETMANAGER_BULK_UPLOAD_REJECTED = 'ASSETMANAGER_BULK_UPLOAD_REJECTED'

export const ASSETMANAGER_BULK_UPLOAD_IMPORT_POST = 'ASSETMANAGER_BULK_UPLOAD_IMPORT_POST'
export const ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_PENDING =
  'ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_PENDING'
export const ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_FULFILLED =
  'ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_FULFILLED'
export const ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_REJECTED =
  'ASSETMANAGER_BULK_UPLOAD_IMPORT_POST_REJECTED'

export const ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD =
  'ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD'
export const ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_PENDING =
  'ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_PENDING'
export const ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_FULFILLED =
  'ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_FULFILLED'
export const ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_REJECTED =
  'ASSETMANAGER_BULK_UPLOAD_GROUPED_BY_CAT_LOAD_REJECTED'

export const RESET_ASSETMANAGER_BULK_UPLOAD_IMPORT = 'RESET_ASSETMANAGER_BULK_UPLOAD_IMPORT'
