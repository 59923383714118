import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function onboardingEmployeesWithEmailsLoad(params: Record<string, unknown> = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/employees/with_email`

  return {
    type: actions.ONBOARDING_EMPLOYEES_WITH_EMAIL_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
