import React from 'react'
import { useFormState, useForm } from 'react-final-form'
import PropTypes from 'prop-types'
import { Typography, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { composeValidators, invalidTime, required } from 'components/global/form/FormValidations'
import { TimePickerField } from 'components/final-form'
import { getTimeDuration, isNextDay, timeToNumbers } from 'components/global/form/formNormalize'

import Frame from '../../../ds/Frame'

const EstimatedExcusedTime = ({ shift }) => {
  const { change } = useForm()
  const [duration, setDuration] = React.useState(0)
  const {
    values: { from_time, to_time, force_update },
  } = useFormState()

  const isShiftNextDay = isNextDay(shift?.shift_start_time, shift?.shift_end_time)

  const calculateTimes = (value) => {
    const isValueNextDay = isNextDay(shift?.shift_start_time, value)
    const isFromNextDay = isNextDay(shift?.shift_start_time, from_time)

    const times = {
      shift_start: timeToNumbers(shift?.shift_start_time).totalMinutes,
      shift_end: timeToNumbers(shift?.shift_end_time).totalMinutes,
      from_time: timeToNumbers(from_time).totalMinutes,
      value: timeToNumbers(value).totalMinutes,
    }

    if (isShiftNextDay && isValueNextDay && times.value !== times.shift_start) {
      times.value += 1440
    }

    if (isShiftNextDay && isFromNextDay && times.from_time !== times.shift_start) {
      times.from_time += 1440
    }

    if (isShiftNextDay) {
      times.shift_end += 1440
    }

    return times
  }

  const fromTimeValidate = (value) => {
    const times = calculateTimes(value)

    if (times.value < times.shift_start) {
      return I18n.t('cant_select_time_before_shift')
    }

    if (times.value > times.shift_end) {
      const message = isShiftNextDay
        ? 'cant_select_time_before_after_shift'
        : 'cant_select_time_after_shift'
      return I18n.t(message)
    }

    return undefined
  }

  const toTimeValidate = (value) => {
    const times = calculateTimes(value)

    if (times.value > times.shift_end) {
      return I18n.t('cant_select_time_before_after_shift')
    }

    if (times.value < times.from_time || times.value === times.from_time) {
      return I18n.t('cant_select_time_before_from_time')
    }

    return undefined
  }

  React.useEffect(() => {
    change('force_update', !force_update)
    const d = getTimeDuration(from_time, to_time)
    setDuration(d)
  }, [from_time, to_time, shift])

  React.useEffect(() => {
    const times = calculateTimes(to_time)
    if (times.value < times.from_time) {
      change('to_time', null)
    }
  }, [from_time])

  return (
    <Frame title={I18n.t('estimated_excused_time')}>
      <Flex
        flexCol
        style={{ gap: '16px' }}
      >
        <Flex
          flex
          style={{ width: '100%', gap: '10px' }}
        >
          <TimePickerField
            name="from_time"
            label={I18n.t('from')}
            style={{ width: '100%' }}
            validate={composeValidators(fromTimeValidate, required, invalidTime)}
          />
          <TimePickerField
            name="to_time"
            validate={composeValidators(toTimeValidate, required, invalidTime)}
            label={I18n.t('to')}
            style={{ width: '100%' }}
            disabled={!from_time}
            // minTime={!isShiftNextDay && from_time}
            // maxTime={maxTime}
          />
        </Flex>

        {duration && (
          <Typography
            text={
              <>
                {I18n.t('estimated_excused_duration')}:
                <b style={{ color: 'var(--color-base-colors-grey-1000)', margin: '0 5px' }}>
                  {duration}
                </b>
              </>
            }
            variant="body-new/regular"
          />
        )}
      </Flex>
    </Frame>
  )
}

EstimatedExcusedTime.propTypes = {
  // type: PropTypes.string,
  // recorded: PropTypes.number,
  shift: PropTypes.shape(),
}

export default React.memo(EstimatedExcusedTime)
