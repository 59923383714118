import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function checkSetupIsDoneLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/finance/accounting/clients/gl_onboarding_status`

  return {
    type: actions.GL_SETUP_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function checkSetupIsDoneCreate(params = {}) {
  const url = `/${API_VERSION_TWO}/finance/accounting/clients/mark_gl_onboarding_complete`

  return {
    type: actions.GL_SETUP_CREATE,
    payload: handleAPI(url, params, 'POST'),
  }
}
