import { ChangeEventHandler, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, SearchBar } from '@jisr-hr/design-system'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import { TablePagination } from 'components/global/atoms'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  onboardingReviewLeaveDetailsLoad,
  onboardingReviewLeaveDetailsUpdate,
} from 'redux/leavesOnboarding/leave/actionCreators'
import { onboardingAnnualLeaveEntitlementsLoad } from 'redux/leavesOnboarding/annualLeaveEntitlements/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import {
  onboardingStepUpdate,
  organizationInfo,
} from 'redux/organizationOnborading/organization/actionCreators'
import DoneStep from 'containers/public/Onboarding/components/DoneStep'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import JisrLoader from 'components/global/JisrLoader'
import { TLeaves } from 'redux/leavesOnboarding/leave/reducer'
import FormTable from './FormTable'
import styles from './style.module.css'

type TSortDir = 'asc' | 'desc'

type TSortTable = {
  sortBy: 'emp_id' | 'employment_type' | 'opening_balance' | 'effect_date'
  sortDir: TSortDir
}

type TFilter = {
  rpp?: number
  page?: number
  search?: string
  sort?: {
    emp_id?: TSortDir
    employment_type?: TSortDir
    opening_balance?: TSortDir
    effect_date?: TSortDir
  }
}

const ReviewLeaveDetails = ({ goPreviousStep, previousStep }: StepsTypes): JSX.Element => {
  const { setMainStep } = useContext(OnboardingContext)
  const [done, setDone] = useState(false)
  const [idEdit, setIdEdit] = useState<null | string>(null)
  const [filters, setFilters] = useState({
    search: '',
    page: 1,
    rpp: 10,
  })
  const dispatch = useDispatch()
  const handleFilters = (value: TFilter): void => {
    setFilters((prev) => ({
      ...prev,
      ...value,
    }))
  }
  const { leaves, fetching, pagination } = useSelector(
    ({ reviewLeaveDetails }) => reviewLeaveDetails,
  )
  const { total_entries } = pagination
  const { organization: orgInfo } = useSelector(({ organization }) => organization)
  const { employee_leave_days } = useSelector(
    ({ onboardingAnnualLeaveEntitlements }) => onboardingAnnualLeaveEntitlements,
  )

  const { onboarding_steps } = orgInfo?.metadata ?? {}
  const headers = [
    { key: 'emp_id', label: I18n.t('emp_id') },
    { key: 'employment_type', label: I18n.t('employment_type') },
    {
      key: 'annual_leave_entitlement',
      label: I18n.t('annual_leave_entitlement'),
    },
    {
      key: 'opening_balance',
      label: I18n.t('opening_balance'),
      width: '100px',
    },
    { key: 'effect_date', label: I18n.t('up_to_which_date'), width: '130px' },
  ]

  useEffect(() => {
    dispatch(onboardingAnnualLeaveEntitlementsLoad())
    dispatch(onboardingReviewLeaveDetailsLoad(filters))
  }, [dispatch, filters])

  const onSubmit = (values: TLeaves): void => {
    const valuesFormat = {
      ...values,
      annual_leave_entitlement: values.annual_leave_entitlement || null,
      opening_balance: values.opening_balance || null,
      up_to_which_date: values.up_to_which_date || null,
    }
    dispatch(onboardingReviewLeaveDetailsUpdate(valuesFormat)).then(() => {
      dispatch(onboardingReviewLeaveDetailsLoad(filters))
      setIdEdit(null)
    })
  }
  const handleNext = (): Promise<void> | void => {
    if (done) {
      if (onboarding_steps?.leaves_setup === 'Pending') {
        return dispatch(
          onboardingStepUpdate({
            step_name: 'leaves_setup',
            step_status: 'Completed',
            current_step: 'payroll_setup',
          }),
        ).then(() => dispatch(organizationInfo()))
      }
      return setMainStep(2)
    }
    return setDone(true)
  }

  return (
    <StepTemplate
      goNextStep={handleNext}
      goPreviousStep={goPreviousStep}
      nextStep={{
        title: done ? I18n.t('payroll_setup') : I18n.t('confirm'),
      }}
      previousStep={previousStep}
    >
      {done ? (
        <DoneStep
          title={I18n.t('done')}
          description={I18n.t('leave_config_done')}
        />
      ) : (
        <>
          {fetching && <JisrLoader absolute />}
          <Typography
            variant="heading"
            text={I18n.t('review_page')}
          />

          <Spacer height="8px" />
          <Typography
            variant="subtitle-1"
            style={{ color: 'var(--color-base-colors-black-400)' }}
            text={I18n.t('review_page_description', { app_name: I18n.t('jisr_translation_name') })}
          />

          <Spacer height="56px" />
          <div style={{ maxWidth: '400px' }}>
            <SearchBar
              onChange={(_: ChangeEventHandler<HTMLInputElement>, search: string): void =>
                handleFilters({ search, page: 1 })
              }
              placeholder={I18n.t('review_search_placeholder')}
              value={filters.search}
            />
          </div>
          <Spacer height="16px" />
          <div className={styles.tableTh}>
            <Table
              hoverActions
              variant="rounded"
              headers={headers}
              sortExclude={['annual_leave_entitlement', 'actions']}
              containerStyles={{
                height: 398,
              }}
              onSorting={(val: TSortTable): void =>
                handleFilters({ sort: { [val.sortBy]: val.sortDir } })
              }
              sortable
            >
              {leaves.length === 0 ? (
                <Table.Row>
                  <Table.Cell
                    colSpan={headers.length}
                    className={styles.noData}
                  >
                    <Typography
                      variant="interface/default/sm"
                      text={I18n.t('no_data')}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : (
                leaves.map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell className={styles.borderTableCell}>
                      <Flex
                        className={styles.firstCell}
                        flexCol
                      >
                        <Typography
                          variant="subtitle-2"
                          text={item.name_i18n}
                        />
                        <Typography
                          variant="subtitle-2"
                          text={item.code}
                        />
                      </Flex>
                    </Table.Cell>
                    <Table.Cell className={styles.borderTableCell}>
                      {item.employment_type ?? '-'}
                    </Table.Cell>
                    {idEdit === item.code ? (
                      <FormTable
                        employee_leave_days={employee_leave_days}
                        onSubmit={onSubmit}
                        initialValues={item}
                        onClose={setIdEdit}
                      />
                    ) : (
                      <>
                        <Table.Cell className={styles.borderTableCell}>
                          {item.annual_leave_entitlement ?? '-'}
                        </Table.Cell>
                        <Table.Cell className={styles.borderTableCell}>
                          {item.opening_balance ?? '-'}
                        </Table.Cell>
                        <Table.Cell className={styles.borderTableCell}>
                          {item.up_to_which_date ?? '-'}
                        </Table.Cell>
                        <Table.Cell
                          width="75px"
                          actions
                          className={styles.borderTableCell}
                          onClickEdit={(): void => {
                            setIdEdit(item.code)
                          }}
                        />
                      </>
                    )}
                  </Table.Row>
                ))
              )}
            </Table>
          </div>
          {!!leaves.length && (
            <>
              <Spacer height="16px" />
              <TablePagination
                fetching={fetching}
                currentPage={filters.page}
                totalEntries={total_entries}
                rowsPerPage={filters.rpp}
                handleChange={(val): void => handleFilters({ page: val })}
                handleChangeRPP={(val): void => handleFilters({ rpp: val, page: 1 })}
              />
            </>
          )}
        </>
      )}
    </StepTemplate>
  )
}

ReviewLeaveDetails.propTypes = {
  goPreviousStep: PropTypes.func,
  previousStep: PropTypes.func,
}

export default ReviewLeaveDetails
