import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type FinalSettlementReport = {
  title: string
  header: {
    table: Record<
      | 'earnings'
      | 'deductions'
      | 'amount'
      | 'description'
      | 'company_contribution'
      | 'item'
      | 'total'
      | 'total_earnings'
      | 'total_deductions'
      | 'net_eos'
      | 'currency',
      string
    >
  }
  employee_info: {
    name: string
    value: string | number | null
  }[]
  earnings: (string | number | null)[][]
  deductions: (string | number | null)[][]
  salary_info_title: string
  salary_details: {
    name: string
    amount: string
  }[]
  total_salary: string
  total: {
    earnings: number
    deductions: number
    net_eos: number
  }
}

type InitialStateType = {
  fetching: boolean
  employee_report: FinalSettlementReport | null
}

export const initialState: InitialStateType = {
  fetching: false,
  employee_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.FINAL_SETTLEMENT_REPORT_LOAD_PENDING: {
      return {
        ...state,
        employee_report: null,
        fetching: true,
      }
    }

    case actions.FINAL_SETTLEMENT_REPORT_LOAD_FULFILLED: {
      const employee_report = action.payload.data.data?.final_settlement_report as Omit<
        InitialStateType,
        'fetching'
      >['employee_report']
      return {
        ...state,
        employee_report,
        fetching: false,
      }
    }

    case actions.FINAL_SETTLEMENT_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
