import React from 'react'
import PropTypes from 'prop-types'

import ProfileAndTime from './ProfileAndTime'

const ApprovalState = ({
  employeeName,
  jobTitle,
  code,
  time,
  // comment,
  date,
  avatar,
  // approverName,
  status,
  status_i18n,
}) => (
  <div>
    <ProfileAndTime
      name={employeeName}
      code={code}
      jobTitle={jobTitle}
      time={time}
      date={date}
      avatar={avatar}
      status={status}
      status_i18n={status_i18n}
    />
  </div>
)

ApprovalState.propTypes = {
  code: PropTypes.string,
  // approverName: PropTypes.string,
  employeeName: PropTypes.string,
  time: PropTypes.string,
  avatar: PropTypes.string,
  // comment: PropTypes.string,
  jobTitle: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  status: PropTypes.string,
  status_i18n: PropTypes.string,
}

export default ApprovalState
