const Constants = {
  // Payment types
  cash: 'Cash',
  bank: 'Bank',
  cheque: 'Cheque',
  international_transfer: 'International Transfer',
  direct_transfer: 'Direct Transfer',
  // Bank Types
  sabb_bamk: 'Sabb Bank',
  riadh_bank: 'Riyadh Bank',
  saudi_fransi_ban: 'Saudi Fransi Bank',
  ncb_bank: 'NCB Bank',
  rajhi_bank: 'Rajhi Bank',
  anp_bank: 'ANB Bank',
  aljazeera: 'Aljazeera Bank',
  investment: 'Investment Bank',
  alawwal: 'Alawwal Bank',
  // Gender
  male: 'Male',
  female: 'Female',
  // Marital status
  married: 'Married',
  single: 'Single',
  divorced: 'Divorced',
  // Religion
  muslim: 'Muslim',
  non_muslim: 'Non Muslim',
  // Ammount type
  percentage: 'Percentage',
  value: 'Value',
  // Letter types
  commitment_letter: 'Commitment Letter',
  clearance_letter: 'Clearance Letter',
  // Relationships
  spouse: 'Spouse',
  son: 'Son',
  daughter: 'Daughter',
  // Assets status
  allocated: 'Allocated',
  cleared: 'Cleared',
  // Contract types
  fixed_term: 'Fixed term',
  indefinite: 'Indefinite',
  // Contrct periods
  one_year: '1 year',
  two_years: 'Two years',
  years: 'Years',
  custom: 'Custom',
  // Loan category
  normal: 'Normal',
  urgent: 'Urgent',
  // Payroll calculation base
  basic_salary: 'Basic salary',
  full_package: 'Full Package',
  basic_plus_selected_benefits_types: 'Basic + Selected Benefit Types',
  // Leaving reasons
  leaving_reason_1: 'Worker and the employer to terminate the contract agreement',
  leaving_reason_2: 'Termination of the contract by the employer',
  leaving_reason_3: 'Termination of the contract by the employer for a case in Article (80)',
  leaving_reason_4: 'Leaving the worker to work as a result of force majeure',
  leaving_reason_5:
    'Termination of the labor contract to work within six months of the marriage contract, or within three months of the situation',
  leaving_reason_6: 'Worker to leave work for a case in Article (81)',
  leaving_reason_7:
    'Leaving the worker to work without providing the resignation of cases is contained in Article (81)',
  leaving_reason_8: 'Resignation factor',
  // Short week offs
  sun: 'Sun',
  mon: 'Mon',
  tus: 'Tus',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  // Shift types list
  split: 'Split',
  flexible: 'Flexible',
  one_punch: 'One punch',
  // Full week days
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  // Months Name
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  // Cary forward types
  no_carry: 'No carry',
  limited_carry: 'Limited Carry',
  carry_all: 'Carry all',
  // Segment by
  department: 'Department',
  location: 'Location',
  employment_type: 'Employment type',
  job_title: 'Job title',
  gender: 'Gender',
  nationality: 'Nationality',
  // view by
  stack: 'Number of employees',

  // Xero Configurations
  bill_per_employee: 'Bill per employee',
  grouped_bill: 'Grouped bill',

  // Insurance Category
  gold: 'Gold',
  silver: 'Silver',
  bronze: 'Bronze',
  staff: 'Staff',
  vip: 'VIP+',
}

export default Constants
