import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  leave_types: [],
  custom_leaves: [],
  other_leave: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}

let changes = null
export default function leaveTypeReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let leave_types = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'LEAVE_TYPES_LOAD_PENDING':
    case 'LEAVE_TYPE_LOAD_PENDING':
    case 'LEAVE_TYPE_CREATE_PENDING':
    case 'LEAVE_TYPE_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.leave_types.length > 0),
      }

    case 'LEAVE_TYPES_LOAD_REJECTED':
    case 'LEAVE_TYPE_LOAD_REJECTED':
    case 'LEAVE_TYPE_CREATE_REJECTED':
    case 'LEAVE_TYPE_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LEAVE_TYPES_LOAD_FULFILLED':
    case 'LEAVE_TYPE_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        all_leave_types: payload.data.data.leave_types,
        custom_leaves: payload.data.data.leave_types.filter((data) => !data.is_default),
        other_leave: payload.data.data.leave_types.filter(
          (data) => data.name !== 'Annual' && data.is_default,
        ),
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LEAVE_TYPE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      leave_types = [...state.leave_types]
      newRecord = payload.data.data.leave_type
      changes = {
        leave_types: [...leave_types, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'LEAVE_TYPE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      leave_types = [...state.leave_types]
      updatedRecord = payload.data.data.leave_type
      changes = {
        leave_types: updateOneListOfRecords(leave_types, updatedRecord),
        fetching: false,
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'LEAVE_TYPE_DELETE_FULFILLED':
      leave_types = [...state.leave_types]
      return {
        ...state,
        leave_types: removeOneListOfRecords(leave_types, payload.id),
        error: null,
      }

    case 'RESET':
      changes = {
        leave_type: {},
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
