import { FC } from 'react'
import ConfirmModal from '../../../components/ConfirmModal'

export type ModalOptionsType = {
  onSuccess?: () => void
  onCancel?: () => void
  message: string
  fetching: boolean
  subMessage?: string
  hideCancel?: boolean
  labelCancel?: string
  labelConfirm?: string
  variant?: 'danger' | 'warning' | 'info' | 'custom' | 'success'
}

type PaymentConfirmationType = { modalOptions: ModalOptionsType | null; onClose: () => void }

const PaymentConfirmation: FC<PaymentConfirmationType> = (props) => {
  const { modalOptions } = props
  return (
    <ConfirmModal
      isOpen={Boolean(modalOptions)}
      variant={modalOptions?.variant || 'danger'}
      fetching={modalOptions?.fetching}
      showLoader={false}
      message={modalOptions?.message}
      onCancel={(): void => {
        if (modalOptions?.onCancel) modalOptions?.onCancel()
        props.onClose()
      }}
      subMessage={modalOptions?.subMessage}
      labelCancel={modalOptions?.labelCancel}
      labelConfirm={modalOptions?.labelConfirm}
      hideConfirm={!modalOptions?.onSuccess}
      onConfirm={(): void => modalOptions?.onSuccess?.()}
      fullBtnWidth
      hideCancel={modalOptions?.hideCancel}
    />
  )
}

export default PaymentConfirmation
