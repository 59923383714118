import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type CountriesType = {
  id: number
  name_en: string
  name_ar: string
  name_i18n: string
}

type StateType = {
  fetching: boolean
  countries: CountriesType[]
  regions: CountriesType[]
  cities: CountriesType[]
}

const initialState: StateType = {
  fetching: false,
  countries: [],
  regions: [],
  cities: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.HR_COUNTRIES_LOAD_PENDING:
    case actions.REGIONS_LOAD_PENDING:
    case actions.CITIES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.HR_COUNTRIES_LOAD_FULFILLED:
    case actions.REGIONS_LOAD_FULFILLED:
    case actions.CITIES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.HR_COUNTRIES_LOAD_REJECTED:
    case actions.REGIONS_LOAD_REJECTED:
    case actions.CITIES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
