import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { withLenses, lens } from '@dhmk/zustand-lens'
import { createCanduStore, TCanduStore } from './createCanduStore'

export const useCanduStore = create<{ canduStore: TCanduStore }>()(
  devtools(
    withLenses(() => ({ canduStore: lens(createCanduStore) })),
    { name: 'CanduStore', enabled: process.env.NODE_ENV === 'development' },
  ),
)
