import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  employee_configuration: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function withoutGeofencingReducer(state = DEFAULT_STATE, action) {
  const { payload } = action

  switch (action.type) {
    case actions.WITHOUT_GEOFENCINGY_LOAD_PENDING:
    case actions.WITHOUT_GEOFENCINGY_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case actions.WITHOUT_GEOFENCINGY_LOAD_REJECTED:
    case actions.WITHOUT_GEOFENCINGY_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        // errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.WITHOUT_GEOFENCINGY_LOAD_FULFILLED:
      changes = {
        ...payload.data.data,
        // succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case actions.WITHOUT_GEOFENCINGY_UPDATE_FULFILLED:
      // Toastr.success(action.payload.data.message);
      changes = {
        ...action.payload.data,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
