import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PerformanceReportsState } from 'containers/authorised/report/Performance/performanceTypes'
import { Action } from 'types/redux'
import * as actions from './actions'

export const initialState: PerformanceReportsState = {
  fetching: false,
  header: [],
  records: [],
  pagination: {},
}

export default function reducer(state = initialState, action: Action): PerformanceReportsState {
  switch (action.type) {
    case actions.EMPLOYEE_REVIEWS_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EMPLOYEE_REVIEWS_REPORT_LOAD_FULFILLED: {
      const { header, records, pagination } = action.payload.data.data
      const allRecords = [...(pagination?.previous_page ? state.records : []), ...records]
      return {
        ...state,
        header,
        records: allRecords,
        pagination,
        fetching: false,
      }
    }

    case actions.EMPLOYEE_REVIEWS_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.EMPLOYEE_REVIEWS_REPORT_RESET: {
      return {
        ...state,
        records: [],
        fetching: false,
      }
    }

    default:
      return state
  }
}
