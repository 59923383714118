import { forwardRef, LegacyRef } from 'react'
import i18n from 'translations/i18n'
import classNames from 'classnames'
import { FeaturedIcon, Flex, Spacer, Typography } from '@jisr-hr/ds'
import { Badge } from '@jisr-hr/ds-beta'
import DSIcon from 'components/DSIcon'
import { ToolTipHandler } from 'components/authorised/Performace'
import { Department } from 'redux/newDepartments/types'
import { isRtl } from 'utils/uiHelpers'

import styles from '../style.module.css'
import DeleteDepartment from './DeleteDepartment'

type Props = {
  collapse: JSX.Element
  hidAddIcon: boolean
  isActive: boolean
  isOnboarding: boolean
  isNewOnboarding: boolean
  isHighTier: boolean
  data?: Department
  manager?: Department['manager']
  name: string
  numberOfEmployees: number
  totalNumberOfEmployees: number
  onClickAddParent: (isFirstLevel?: boolean) => void
  onClickAddSide: (parent_id?: number) => void
  onClickAddSub: () => void
  onClickEdit: () => void
  onClickEmp: () => void
  readonly?: boolean
  parent_id?: number
}

const Card = forwardRef(
  (
    {
      collapse,
      isActive,
      hidAddIcon,
      isOnboarding,
      isNewOnboarding,
      manager,
      name,
      numberOfEmployees,
      totalNumberOfEmployees,
      onClickAddParent,
      onClickAddSide,
      onClickAddSub,
      onClickEdit,
      onClickEmp,
      readonly,
      parent_id,
      data,
      isHighTier,
    }: Props,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <div className={classNames(styles.cardContainer, !isNewOnboarding && styles.container)}>
        <div className={classNames(isNewOnboarding && styles.coverContainer)}>
          <div
            ref={ref}
            className={`${classNames(
              styles.card,
              isActive && styles.active,
              isOnboarding && styles.onboarding,
              isNewOnboarding && styles.newOnboarding,
            )} ${isHighTier && isNewOnboarding ? 'h-[132px]' : 'h-[128px]'} ${
              isNewOnboarding ? 'pb-[12px]' : ''
            }`}
          >
            {!readonly && (
              <div
                className={styles.boxUp}
                onClick={(): void => {
                  onClickAddParent(!parent_id && isNewOnboarding)
                }}
              >
                <DSIcon
                  name="plus"
                  styles={{ color: 'var(--ds-core-color-base-white)' }}
                />
              </div>
            )}
            {isNewOnboarding && (
              <div
                className={styles.boxInsetEnd}
                onClick={(): void => {
                  onClickAddSide(parent_id)
                }}
              >
                <DSIcon
                  name="plus"
                  styles={{ color: 'var(--ds-core-color-base-white)' }}
                />
              </div>
            )}
            <div
              className={styles.card_header}
              style={{ cursor: readonly ? 'default' : 'pointer' }}
              onClick={(): void => {
                if (!readonly) onClickEdit()
              }}
            />
            <Flex
              flexCol
              className="py-[2px] px-[15px] gap-[4px]"
            >
              {isHighTier && isNewOnboarding && (
                <>
                  <Badge
                    size="small"
                    variant="tinted"
                    color="gray-light"
                    leadingIcon="users-01"
                    label={numberOfEmployees?.toString() ?? '0'}
                  />
                  <Spacer height={4} />
                </>
              )}
              <Flex
                style={{ cursor: readonly ? 'default' : 'pointer' }}
                onClick={(): void => {
                  if (!readonly) onClickEdit()
                }}
              >
                <ToolTipHandler
                  isShown={name.length >= 16}
                  title={name}
                >
                  <Typography
                    text={`${name.slice(0, 16)}${name.length >= 16 ? '...' : ''}`}
                    style={{ color: 'var(--color-base-colors-grey-1000)' }}
                    variant="interface/default/m"
                  />
                </ToolTipHandler>
              </Flex>
            </Flex>
            <div style={{ padding: '4px 15px 0  15px' }}>
              {!(isOnboarding || isNewOnboarding) ? (
                <>
                  {manager ? (
                    <Flex
                      className={styles.manager}
                      style={{ gap: 8 }}
                      itemsCenter
                    >
                      <FeaturedIcon
                        iconName="user-01"
                        colorType="info"
                        size="xs"
                      />
                      <ToolTipHandler
                        isShown={manager.full_name_i18n.length >= 20}
                        title={manager.full_name_i18n}
                      >
                        <Typography
                          text={`${manager.full_name_i18n.slice(0, 16)}${
                            manager.full_name_i18n.length >= 16 ? '...' : ''
                          }`}
                          style={{ color: 'var(--color-base-colors-grey-600)', width: 166 }}
                          variant="interface/default/m"
                        />
                      </ToolTipHandler>
                    </Flex>
                  ) : (
                    <Flex
                      itemsCenter
                      className={styles.assignManager}
                      style={{ cursor: readonly ? 'default' : 'pointer' }}
                      onClick={(): void => {
                        if (!readonly) onClickEdit()
                      }}
                    >
                      <DSIcon name={readonly ? 'info-circle' : 'plus'} />
                      <Typography
                        text={i18n.t(readonly ? 'no_assigned_manager' : 'assign_manager')}
                        style={{ color: 'var(--color-base-colors-blue-700)' }}
                        variant="interface/default/m"
                      />
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  <div style={{ height: isNewOnboarding ? 5 : 35 }} />
                </>
              )}
              <Spacer height={15} />
              <Flex justifyBetween>
                {isNewOnboarding ? (
                  <DeleteDepartment
                    confirmMessage={{
                      type: 'danger',
                      title: `${i18n.t('delete')} ${data?.name_i18n}`,
                      message: i18n.t('confirm_delete_department'),
                      data,
                      canDelete: true,
                    }}
                    id={data?.id || 0}
                    target={
                      <DSIcon
                        style={{ cursor: 'pointer' }}
                        name="trash-01"
                        color="var(--ds-core-color-gray-700)"
                      />
                    }
                    onDone={(): void => undefined}
                    toolTipPlacement={isRtl ? 'right' : 'left'}
                  />
                ) : (
                  <ToolTipHandler
                    customTitle={
                      <>
                        <Flex
                          style={{ gap: 4 }}
                          itemsCenter
                        >
                          <Typography
                            text={i18n.t('number_of_employees_in_current_dep')}
                            textColor="color/fg/inverse"
                            variant="interface/default/sm"
                          />
                          <Typography
                            text={`${numberOfEmployees}`}
                            textColor="color/fg/inverse"
                            variant="interface/default/sm"
                          />
                        </Flex>
                        <Flex
                          style={{ gap: 4 }}
                          itemsCenter
                        >
                          <Typography
                            text={`${i18n.t('total_number_of_emp_including_sub_dep')}: `}
                            textColor="color/fg/inverse"
                            variant="interface/default/sm"
                          />
                          <Typography
                            text={`${totalNumberOfEmployees}`}
                            textColor="color/fg/inverse"
                            variant="interface/default/sm"
                          />
                        </Flex>
                      </>
                    }
                  >
                    <Flex
                      style={{ gap: 7 }}
                      onClick={(): void => {
                        if (!isOnboarding && !readonly) {
                          onClickEmp()
                        }
                      }}
                      className={classNames(!isOnboarding && 'pointer')}
                      itemsCenter
                    >
                      <DSIcon
                        name="users-02"
                        styles={{ color: 'var(--ds-base-color-content-on-neutral-fg)' }}
                      />
                      <Typography
                        text={`${numberOfEmployees}`}
                        variant="interface/default/s"
                      />
                      {!!totalNumberOfEmployees && (
                        <>
                          | &nbsp; &nbsp;
                          <Typography
                            text={`${totalNumberOfEmployees}`}
                            variant="interface/default/s"
                          />
                        </>
                      )}
                    </Flex>
                  </ToolTipHandler>
                )}
                {!hidAddIcon && collapse}
              </Flex>
            </div>
            {!hidAddIcon && !readonly && (
              <div
                className={styles.boxDown}
                onClick={(): void => {
                  onClickAddSub()
                }}
              >
                <DSIcon
                  name="plus"
                  styles={{ color: 'var(--ds-core-color-base-white)' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export default Card
