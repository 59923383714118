import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import { Action } from 'types/redux'
import * as actions from './actions'
import { LeavesFormDataTypes } from './type'

export function onboardingLeaveTypesLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('leave_types')}`

  return {
    type: actions.ONBOARDING_LEAVE_TYPES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function onboardingLeaveTypesCreate(data: LeavesFormDataTypes): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('leave_types')}`

  return {
    type: actions.ONBOARDING_LEAVE_TYPES_POST,
    payload: handleAPI(url, {}, 'POST', { leave_type: data }),
  }
}

export function onboardingLeaveTypesUpdate(data: LeavesFormDataTypes, params = {}): Action {
  const { id } = data
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('leave_types')}/${id}`

  return {
    type: actions.ONBOARDING_LEAVE_TYPES_PUT,
    payload: handleAPI(url, params, 'PUT', { leave_type: data }),
  }
}

export function onboardingLeaveTypesDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('leave_types')}/${id}`

  return {
    type: actions.ONBOARDING_LEAVE_TYPES_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function onboardingLeaveTypesLaborLawUpdate(data: boolean): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('leave_types/labor_law')}`

  return {
    type: actions.ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT,
    payload: handleAPI(url, {}, 'PUT', { use_labor_law: data }),
  }
}
