/* eslint-disable prefer-template */
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import lodash from 'lodash'
import { CostCenter } from 'types/Accounts/costCenter'
import { ExternalAccountInfo } from 'types/Accounts/accountsGlobal'
import * as actions from './actions'

// ? <----------------- these types should be shared at some point ----------------->
export type ZohoBooksCategory = {
  internal_id: string
  name: string
}

export type JournalEntryTemplates = {
  displayName: string
  id: string
}

export type JournalEntries = {
  Accrual?: string
  Ajeer?: string
  EndOfService?: string
  Gosi?: string
  OutOfPayroll?: string
  Regular?: string
  VacationSettlement?: string
}

type ZohoBooksCostCenterAccount = {
  acct_name: string
  acct_number: string
  internal_id: string
}

export type ZohoBooksDimensionsType = ZohoBooksCategory & {
  code: string
  subsidiary_id: number
}

export type ZohoBooksDimensionValue = ZohoBooksCategory & {
  code: string
}

type ZohoBooksExternalAccountInfo = ExternalAccountInfo & {
  code?: string
}

type ZohoBooksCostCenter = Omit<CostCenter, 'external_accounting_infos'> & {
  employees_ids?: number[]
  external_accounting_infos: ZohoBooksExternalAccountInfo | null
}

export type ZohoBooksJournalValue = {
  label: string
  label_ar: string
  name: string
  name_ar: string
  value: string
}
export type ZohoBooksCombinationValue = {
  label: string
  label_ar: string
  value: string
}
// ? <----------------- end ----------------->

type InitStateType = {
  accounts: ZohoBooksCostCenterAccount[]
  all_selected_ids: number[]
  cost_centers: ZohoBooksCostCenter[]
  dimension_values: ZohoBooksDimensionValue[]
  dimension_values_fetching: boolean
  dimensions: ZohoBooksDimensionsType[]
  fetching: boolean
  journal_entry_templates: JournalEntryTemplates[]
  journal_shape: ZohoBooksJournalValue[]
  loading: boolean
  type_fetching: boolean
}

const initialState: InitStateType = {
  fetching: false,
  type_fetching: false,
  dimension_values_fetching: false,
  dimension_values: [],
  loading: false,
  cost_centers: [],
  all_selected_ids: [],
  journal_shape: [],
  dimensions: [],
  accounts: [],
  journal_entry_templates: [],
}

function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action

  switch (action.type) {
    case actions.ZOHO_BOOKS_COST_CENTER_LIST_LOAD_PENDING:
    case actions.ZOHO_BOOKS_COST_CENTER_ACCOUNTS_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.ZOHO_BOOKS_COST_CENTER_CREATE_PENDING:
    case actions.ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_PENDING:
    case actions.ZOHO_BOOKS_COST_CENTER_DELETE_PENDING:
    case actions.ZOHO_BOOKS_COST_CENTER_UPDATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.ZOHO_BOOKS_COST_CENTER_DIMENSIONS_FULFILLED: {
      return {
        ...state,
        dimensions: payload.data.data as ZohoBooksDimensionsType[],
        type_fetching: false,
      }
    }
    case actions.ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_FULFILLED: {
      return {
        ...state,
        journal_entry_templates: payload.data.data as JournalEntryTemplates[],
        loading: false,
      }
    }
    // * accounts
    case actions.ZOHO_BOOKS_COST_CENTER_ACCOUNTS_FULFILLED: {
      const accounts = (payload.data.data || []) as ZohoBooksCostCenterAccount[]
      return {
        ...state,
        accounts,
        type_fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_COST_CENTER_LIST_LOAD_FULFILLED: {
      const cost_centers = payload.data.data.tracking_dimensions as ZohoBooksCostCenter[]
      let reBuildCostCenter: ZohoBooksCostCenter[] = []
      let journal_shape: ZohoBooksJournalValue[] = []

      cost_centers?.forEach((cost) => {
        reBuildCostCenter = [
          ...reBuildCostCenter,
          {
            ...cost,
            org_category_type: cost.org_category_type || 'custom',
            employees_ids: lodash.uniq(
              cost?.tracking_dimension_values?.map((it) => it.mapped_employee_ids!)?.flat(),
            ),
          },
        ]
        journal_shape = [
          ...journal_shape,
          {
            name: cost.name,
            name_ar: cost.name,
            label: cost.name,
            label_ar: cost.name,
            value: cost?.name?.toLowerCase(),
          },
        ]
      })

      const all_selected_ids = reBuildCostCenter?.map((it) => it.employees_ids!)?.flat()
      return {
        ...state,
        cost_centers: reBuildCostCenter,
        journal_shape,
        all_selected_ids: lodash.uniq(all_selected_ids || []),
        fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_COST_CENTER_CREATE_FULFILLED:
    case actions.ZOHO_BOOKS_COST_CENTER_UPDATE_FULFILLED:
    case actions.ZOHO_BOOKS_COST_CENTER_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_FULFILLED: {
      return {
        ...state,
        dimension_values: payload.data.data,
        dimension_values_fetching: false,
      }
    }
    case actions.ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_PENDING: {
      return {
        ...state,
        dimension_values_fetching: true,
      }
    }
    case actions.ZOHO_BOOKS_COST_CENTER_DIMENSIONS_REJECTED:
    case actions.ZOHO_BOOKS_COST_CENTER_DIMENSIONS_VALUES_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        dimension_values: [],
        dimension_values_fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_COST_CENTER_CREATE_REJECTED:
    case actions.ZOHO_BOOKS_JOURNAL_TEMPLATE_LOAD_REJECTED:
    case actions.ZOHO_BOOKS_COST_CENTER_UPDATE_REJECTED:
    case actions.ZOHO_BOOKS_COST_CENTER_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ZOHO_BOOKS_COST_CENTER_LIST_LOAD_REJECTED:
    case actions.ZOHO_BOOKS_COST_CENTER_ACCOUNTS_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}

export default reducer
