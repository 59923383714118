import { FC, useState } from 'react'
import i18n from 'translations/i18n'
import { Typography, Checkbox, Modal } from '@jisr-hr/ds'
import { ReactComponent as MudadImg } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/mudad.svg'
import useIsDemoAccount from 'components/global/hooks/useIsDemoAccount'
import { usePaymentMethod } from '../../components/usePaymentMethods'

const MudadTermsSubscriptionsModal: FC = () => {
  const isDemo = useIsDemoAccount()
  const paymentCtx = usePaymentMethod()
  const { termsConditionsOpen } = paymentCtx

  const [readTerms, setReadTerms] = useState(false)
  return (
    <Modal
      open={termsConditionsOpen}
      onClose={(): void => {
        setReadTerms(false)
        paymentCtx.setTermsConditionsOpen(false)
      }}
      size="x-large"
      header={{
        title: i18n.t('before_add_mudad'),
        withClose: true,
        leadingSpace: { custom: <MudadImg style={{ width: 32, height: 32 }} /> },
      }}
      footer={{
        submit: {
          label: i18n.t('next'),
          disabled: !readTerms || isDemo,
          onClick: (): void => {
            paymentCtx.setTermsConditionsOpen(false)
            paymentCtx.setMudadCurrentStep(1)
            paymentCtx.setMethodModalOpen('mudad')
            setReadTerms(false)
          },
        },
      }}
    >
      <div
        className="scroll-y"
        style={{
          maxHeight: 500,
          padding: '16px 24px',
          textAlign: 'start',
          background: '#fafbfc',
        }}
      >
        <div style={{ direction: 'rtl' }}>
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px', marginBottom: 20 }}
            text="الشروط والأحكام"
          />
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px' }}
            text="شركة مدد الأعمال
    ويشار إليها في بنود هذه الاتفاقية بـ : (الشركة)
    منشأة
    ويشار إليها في بنود هذه الاتفاقية بـ : (المنشأة).
    ويشار إلى كل متعاقد من المتعاقدين بـ الطرف ويشار إليهما معا بـ الطرفين.
    كما يشار إلى بنك/مصرف الذي للمنشأة (حساب جاري) لديه بـ : (البنك)"
          />
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px', marginBottom: 8 }}
            text="تمهيد: <br />
            حيث إن نظام العمل السعودي قد نص في المادة (90/2) منه على أن: تلزم المنشآت بدفع الأجور في
            حسابات العمال عن طريق البنوك المعتمدة في المملكة. وحيث أكدت وزارة العمل على ذلك في قواعد
            عمل نظام حماية الأجور."
          />
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px', marginBottom: 8 }}
            text="وحيث إن الشركة لديها نظام إلكتروني شامل لرصد وتوثيق عمليات صرف أجور العاملين بالمنشآت في
            المملكة العربية السعودية."
          />
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px', marginBottom: 8 }}
            text="وحيث إن المنشأة ترغب في استخدام النظام الإلكتروني المشار إليه بغرض تحويل أجور العاملين
            من حساب المنشأة في البنك إلى حساباتهم الخاصة، وفق كشوف الأجور وبيانات العاملين الخاصة
            بكل منشأة."
          />

          <Typography
            variant="subtitle-1"
            style={{ lineHeight: '20px', marginBottom: 20 }}
            text="فقد اتفق الطرفان وهما بكامل الأهلية المعتبرة نظاماً للتعاقد على ما يلي:"
          />
          <h4>المادة (1) تعريفات:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  المفوَّض على الحساب: الشخص الذي يملك صلاحيات المستخدم الرئيسي على حساب المنشأة،
                  ويُطلق عليه (المفوَّض على الحساب) في سياق النظام الإلكتروني.
                </li>
              }
            />
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  النظام الإلكتروني: نظام إدارة الرواتب الذي يتيح للمنشآت الاشتراك لتنفيذ العمليات
                  الخاصة بالأجور آليًّا دون الحاجة إلى التعاقد مع البنوك.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  حسابات بطاقات الأجور: حسابات افتراضية لغرض صرف مستحقات العاملين، تقوم المنشأة بطلب
                  تأسيسها بأسماء العاملين لديها غير القادرين على التعامل مع البنوك من خلال النظام
                  الإلكتروني، وبصيغة متفق عليها بين البنك وبين الطرف الأول، بما يتوافق مع قواعد
                  خدمات الدفع المسبق في المملكة العربية السعودية الصادرة عن مؤسسة النقد العربي
                  السعودي في عام 2012م وتحديثاتها.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  بطاقات الأجور: بطاقات ذكية تعمل من خلال الشبكة السعودية للمدفوعات (مدى) وأجهزة
                  الصرف الآلي.
                </li>
              }
            />
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  المنشأة: الجهة التي يتم تحويل أجور العاملين بها من حسابها في البنك إلى حساباتهم
                  الشخصية من خلال النظام الإلكتروني.
                </li>
              }
            />
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  المحفظة الالكترونية: حسابات رقمية لغرض صرف مستحقات العاملين، تقوم المنشأة بطلب
                  تأسيسها تأسيسها بأسماء العاملين لديها غير القادرين على التعامل مع البنوك من خلال
                  النظام الإلكتروني، وبصيغة متفق عليها بين موفر الخدمة وبين الطرف الأول، بما يتوافق
                  مع قواعد خدمات الدفع المسبق في المملكة العربية السعودية الصادرة عن مؤسسة النقد
                  العربي السعودي.
                </li>
              }
            />
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={<li>الرسوم: رسوم الخدمات المقدمة بعملة الريال السعودي فقط.</li>}
            />
          </ol>
          <h4>المادة (2): بنود الاتفاقية:</h4>
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: 2 }}
            text={`
            يعتبر التمهيد أعلاه وأية ملاحق أخرى يتم توقيعها لاحقاً بين الطرفين؛ جزءاً لا يتجزأ من
            هذه الاتفاقية."`}
          />
          <h4>المادة (3): موضوع الاتفاقية:</h4>
          <Typography
            variant="subtitle-1"
            style={{ lineHeight: 2 }}
            text=" بموجب هذه الاتفاقية، فقد اتفق الطرفان على أن تتم عملية تحويل الأجور الخاصة بالعاملين
            بالمنشأة، وفق الإجراءات والشروط التالية:"
          />
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تلتزم المنشأة بتحديث بيانات المفوضين لاستخدام النظام الالكتروني كلما طرأ عليها
                  تغيير دون أدنى مسؤولية على الشركة.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={<li>آلية إدخال البيانات إلى النظام الالكتروني:</li>}
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  تحدد المنشأة المفوض على الحساب، ولا يسمح لغير المفوض على الحساب عمل هذه الإجراءات،
                  على أن تقوم الشركة بالتحقق من هوية المفوض على الحساب إلكترونيًّا أثناء عملية
                  التسجيل والتحقق من صحة حالته؛ بمطابقة بياناته على حساب المنشأة المرسل آليًّا وبشكل
                  مباشر، وفي حال ظهور عدم المطابقة تلتزم الشركة بإلغاء عملية التسجيل.
                </li>
              }
            />
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  موافقة المنشأة على منح الشركة الحق في استعراض بيانات العاملين التفصيلية من نظام
                  المؤسسة العامة للتأمينات الاجتماعية على النظام الإلكتروني
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>تقوم المنشأة بتجهيز كشوف أجور العاملين واعتمادها من خلال النظام الالكتروني.</li>
              }
            />
          </ol>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تلتزم المنشأة بتزويد الشركة بالتحديثات الآلية وبشكل مباشر من خلال النظام
                  الإلكتروني، فيما يخص صلاحية هويات العاملين أصحاب البطاقات (على سبيل المثال لا
                  الحصر: تاريخ هوية جديد، هوية منتهية، خروج نهائي) ولن تتحمل الشركة المسؤولية إذا لم
                  يتم تزويدها بتلك التحديثات قبل (15) خمسة عشر يوما من سريان مفعولها وفقاً لنظم
                  التشغيل.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  للمنشأة الحق في إرسال طلب (إيقاف/إلغاء) أوامر الأجور آليًّا قبل تاريخ الاستحقاق
                  بخمسة (5) أيام عمل كحد أدنى، وتلتزم الشركة بتنفيذ ذلك.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={<li>آلية تحويل الأجور:</li>}
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يقوم المفوض على الحساب بإرسال كشوف أجور العاملين - موضحاً بموجبها تاريخ الاستحقاق
                  - وإصدار اوامر الصرف للبنك من خلال النظام الإلكتروني خلال أيام العمل الرسمية فقط ،
                  وقبل تاريخ الاستحقاق.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يقوم البنك بتحويل أجور العاملين بالمنشأة من حساب المنشأة الجاري لدى البنك إلى
                  حسابات العاملين الشخصية وفق ما هو مدون في بياناتهم وذلك في ذات تاريخ الاستحقاق
                  الموضح في كشوف أجور العمال التي تم على أساسها اصدار أوامر الصرف من خلال النظام
                  الالكتروني .
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  سيتم تزويد المنشأة عن طريق النظام الإلكتروني بما يفيد قيد المبالغ في حسابات
                  العاملين الشخصية، وبأسماء العاملين الذين لم يتم تحويل مستحقاتهم إلى حساباتهم
                  الخاصة، وذلك في موعد أقصاه (5) خمسة أيام عمل من تاريخ الاستحقاق.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يتم تسجيل تحويلات أجور العاملين بالمنشأة إلى حساباتهم الشخصية بشكل آلي من خلال
                  النظام الإلكتروني والمرتبط مباشرة بنظام حماية الأجور
                </li>
              }
            />
          </ol>
          <h4>المادة (4) مدة الاتفاقية:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={<li>مدة هذه الاتفاقية سنة ميلادية واحدة تبدأ من تاريخ إبرامها.</li>}
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تتجدد الاتفاقية تلقائيا لمدة مماثلة ما لم يخطر أحد الطرفين الطرف الآخر كتابيا بعدم
                  رغبته في التجديد قبل (60) ستين يوما على الأقل من انتهاء مدة الاتفاقية الأساسية أو
                  المجددة، فتكون تلك المدة هي الأخيرة، وتنتهي الاتفاقية بنهايتها.
                </li>
              }
            />
          </ol>
          <h4>المادة (5): رسوم الخدمة المقدمة:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تلتزم المنشأة بدفع رسوم الباقة السنوية في حال الاشتراك في احدى الباقات للمنشأة
                  ويتم تحديد قيمة رسوم تلك الخدمة وفقاً للباقة التي تختارها المنشأة من الباقات
                  الواردة في النظام الالكتروني، إضافة إلى تحمل المنشأة ضريبة القيمة المضافة.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  سيتم خصم رسوم تحويل الأجور عن كل عامل فور طلب المنشأة دفع الأجور لكل امر، وسيتم
                  احتسابه حال تنفيذ العملية وتختلف رسوم تحويل الاجور بناء على تاريخ الاستحقاق.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={<li> رسوم استخراج او اعادة اصدار لبطاقات الرواتب لكل موظف.</li>}
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  في حال قامت المنشأة بالغاء عملية دفع الأجور بعد ارسالها للبنك سيتم خصم رسوم خدمة
                  تحويل الأجور من حساب المنشأة.
                </li>
              }
            />
          </ol>
          <h4>المادة (6) حقوق الملكية الفكرية:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تتعهد المنشأة بعدم الاحتفاظ بنسخة من النظام الإلكتروني ولا ما يحويه من بيانات
                  المنشآت ومعلوماتها بعد انتهاء هذه الاتفاقية.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  لا يحق للمنشأة تأجير النظام الإلكتروني أو التنازل عنه للغير أو إعارته أو التصرف
                  فيه بأي شكل من الاشكال أو نسخ أو محاكاة هذا المحتوى أو القيام بأي تعديلات برمجية
                  أو غيرها على هذا النظام بما في ذلك على سبيل المثال لا الحصر (قواعد البيانات،
                  والبرمجيات، والتصميمات والشعارات) دون أخذ موافقة خطية مسبقة من الشركة. ويخضع هذا
                  المحتوى لحقوق النشر وغيرها من حقوق الملكية الفكرية بموجب الأنظمة السارية في
                  المملكة العربية السعودية.
                </li>
              }
            />
          </ol>
          <h4>المادة (7): حدود المسؤولية:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تتعهد المنشأة بالتزامها بكافة الأنظمة واللوائح المعمول بها في المملكة العربية
                  السعودية، وبصفة خاصة نظام التعاملات الإلكترونية، الصادر بالمرسوم الملكي رقم (م/١٨)
                  وتاريخ 08 / 03 / 1428هـ، ونظــام مكافحة جرائم المعلوماتية الصادر بالمرسوم الملكي
                  رقم (م/17) وتاريخ 08/ 03/ 1428هـ، ونظام مؤسسة النقد العربي السعودي، الصادر
                  بالمرسوم الملكي رقم (23) وتاريخ 23/05/1377ه. وكذلك الالتزام بقرار مجلس الوزراء رقم
                  (163) وتاريخ 24/10/1417هـ بشأن ضوابط إدخال خدمة نظام نقل المعلومات السريع (شبكة
                  الإنترنت).
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  في حال حدوث أية أخطاء في عملية تحويل الأجور، فإن المنشأة تتحمل مسؤولية أية مطالبات
                  من قبل الأطراف المتضررة، ولا تتحمل الشركة أية مسؤولية عن ذلك؛ إلا إذا ثبت وقوع
                  الخطأ بسبب إهمال أو تقصير منها.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تقر المنشأة بعدم تحمل الشركة أي التزام يتعلق بأي نزاع ينشب بين المنشأة وبين أي طرف
                  آخر فيما يتعلق بهذه الاتفاقية أو في حالة حدوث أية أخطاء في عملية تحويل الأجور، كما
                  تقرّ بأنها تعفي الشركة وجميع مسئوليها، ومديريها، وموظفيها، ومقدمي خدماتها،
                  وتابعيها، ووكلائها، من أية مطالبات أيا كان نوعها. الا في حال كان النزاع او الخطأ
                  ناتج عن اهمال أو تقصير من الشركة .
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  تلتزم المنشأة باستخدام النظام الالكتروني وفقاً لسياسة الاستخدام المحددة من قبل
                  الشركة.
                </li>
              }
            />
          </ol>
          <h4>المادة (8): الإخلال بالاتفاقية:</h4>
          <Typography
            variant="subtitle-1"
            text=" يلتزم الطرفان بتنفيذ بنود وشروط هذه الاتفاقية وفقا لمبدأ حسن النية، ولا يحقّ لأي من
            الطرفين فسخ الاتفاقية بإرادته المنفردة أو نقضها والرجوع فيها بإرادته المنفردة."
          />
          <h4>البند (9): الأنظمة المعمول بها وحل النزاعات:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تطبق على هذه الاتفاقية أحكام الشريعة الإسلامية، وتخضع لكافة الأنظمة والتعليمات
                  السارية بالمملكة العربية السعودية، وتفسر ألفاظها وبنودها من خلالها، وتستكمل بنودها
                  فيما لم يرد به نصٌّ من الأنظمة واللوائح المعمول بها فيها.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  أي خلاف ينشأ بين الطرفين حول تفسير أو تنفيذ هذه الاتفاقية - ما لم يحلّ وديًّا -
                  فإنه يخضع للجهات القضائية المختصة بالمملكة العربية السعودية للفصل فيه.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يلتزم الطرفان بتنفيذ بنود الاتفاقية كاملة خلال فترة النزاع حتى يتم الفصل فيه.
                </li>
              }
            />
          </ol>
          <h4>المادة (10): السرية:</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تعتبر هذه الاتفاقية وكافة المعلومات والمستندات الخاصة بالشركة المرفقة بها؛ سرية لا
                  يحقّ للمنشأة ولا لأي من مديريها أو موظفيها الإعلان عنها أو نسخها أو نشرها أو
                  التواصل بشأنها أو إتاحتها للغير دون موافقة خطية مسبقة من الشركة (باستثناء ما هو
                  مطلوب بموجب الأنظمة واللوائح المعمول بها في مؤسسة النقد العربي السعودي)، وتلتزم
                  الشركة بالمثل بعدم الإفشاء عن المعلومات الخاصة بالمنشأة إلا لموظفيها أو مستشاريها
                  أو تابعيها الذين يحتاجون إليها لتنفيذ الالتزامات بموجب هذه الاتفاقية.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تلتزم وتتعهد المنشأة بالمحافظة على سرية المعلومات الخاصة بالشركة، والمحافظة على
                  سرية ما يحويه النظام الإلكتروني من معلومات وبيانات، وعدم استخدامها في أية أغراض
                  أخرى غير ما هو مذكور في هذه الاتفاقية. ولا يشمل ذلك أية بيانات أو معلومات سبق
                  للمنشأة الحصول عليها قبل إبرام هذه الاتفاقية وقبل استخدام النظام الإلكتروني، وكذلك
                  البيانات أو المعلومات التي يقدمها أي من العاملين باختياره إلى المنشأة بشكل مباشر.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تلتزم المنشأة بعدم الكشف لأي طرف أو أية جهة عن أية معلومات أو بيانات من النظام
                  الإلكتروني، فيما عدا الجهات المسموح لها ذلك بموجب الأنظمة واللوائح المعمول بها في
                  مؤسسة النقد العربي السعودي.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تتعهد المنشأة بعدم تعديل أو تغيير أو تبديل أو تعطيل أي من خدمات أو خصائص النظام
                  الإلكتروني، أو الالتفاف على إجراءات الحماية التقنية المتعلقة به أو بشيء من خدماته،
                  أو فك تشفيرها أو تجاوزها أو محاولة اكتشاف الأكواد البرمجية للنظام الإلكتروني؛ دون
                  موافقة خطية مسبقة من الشركة.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  لا يحق لأحد من موظفي المنشأة استخدام النظام الإلكتروني إلا إذا كان الموظف مسجلا
                  ومرخصا له من قبل الشركة، ولا تتحمل الشركة المسؤولية عن أية مطالبات أو أضرار ناجمة
                  عن استخدام النظام الإلكتروني من قبل أي موظف غير مرخص له.
                </li>
              }
            />
          </ol>
          <h4>المادة (11): بطلان الاتفاقية.</h4>
          <Typography
            variant="subtitle-1"
            text="
            في حال بطلان أي مادة من مواد هذه الاتفاقية؛ لتعارضها مع أي نظام من الأنظمة واللوائح
            السارية في المملكة العربية السعودية؛ فإن أثر البطلان يقتصر على تلك المادة فقط، وتبقى
            كافة مواد الاتفاقية الأخرى سارية المفعول، ويتم إضافة مادة بديلة لا تتعارض مع الأنظمة
            واللوائح السارية في المملكة العربية السعودية؛ لإعادة التزامات الطرفين وحقوقهما إلى ما
            كانت عليه، على أن يتم التوقيع على المادة الجديدة من الطرفين."
          />
          <h4>المادة (12): المراسلات و الإخطارات</h4>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تعتبر جميع المراسلات و الإخطارات التي تنشأ عن هذه الاتفاقية جزءا لا يتجزأ منها.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يتم تبادل المراسلات و الإخطارات عن طريق تسليمها باليد أو عن طريق الفاكس أو بواسطة
                  البريد المسجل أو بالبريد الإلكتروني وفقاً العناوين المبينة بصدر هذه الاتفاقية.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  {' '}
                  يعتبر أي من تلك الإخطارات أو المراسلات قد تم تسليمها: <br />
                  (أ) عند التسليم، في حالة التسليم مناولة باليد مقابل إيصال تأكيد استلام. <br />
                  (ب) بعد ثلاثة أيام عمل من تاريخ إيداعها لدى مزود خدمة البريد المسجل أو البريد
                  الجوي المعروف عالمياً في حال إرسالها بالبريد المسجل. <br />
                  (ج) في يوم الإرسال، في حال الإرسال عن طريق البريد الإلكتروني بما لا يتجاوز الساعة
                  الثالثة مساءً (بتوقيت مدينة الرياض) من أي يوم عمل، أو في يوم العمل التالي في حال
                  الإرسال بواسطة البريد الإلكتروني بعد الساعة الثالثة مساءً (بتوقيت مدينة الرياض) من
                  أي يوم عمل أو في أي يوم ليس يوم عمل.
                  <br />
                </li>
              }
            />
          </ol>
          <ol>
            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  تتم التبليغات على عنوان كل طرف الموضح بصدر هذه الاتفاقية وتعتبر صحيحة ونافذة
                  ومنتجة لآثارها.
                </li>
              }
            />

            <Typography
              variant="interface/medium/sm"
              style={{ lineHeight: 2 }}
              text={
                <li>
                  يلتزم كل من الطرفين بإبلاغ الطرف الآخر كتابيا حال تغيير عنوانه وأن يتضمن الإبلاغ
                  تحديد التاريخ الذي يبدأ فيه تلقي المراسلات والاخطارات على العنوان الجديد وإلا
                  اعتبرت العناوين المدونة في هذه الاتفاقية صحيحة ونافذة ومنتجة لآثارها.
                </li>
              }
            />
          </ol>
        </div>

        <Checkbox
          active={readTerms}
          onChange={(): void => setReadTerms(!readTerms)}
          label={i18n.t('i_gree_on_terms_conditions')}
        />
      </div>
    </Modal>
  )
}

export default MudadTermsSubscriptionsModal
