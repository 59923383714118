export const OUT_OF_PAYROLL_REPORT_LOAD = 'OUT_OF_PAYROLL_REPORT_LOAD'
export const OUT_OF_PAYROLL_REPORT_LOAD_PENDING = 'OUT_OF_PAYROLL_REPORT_LOAD_PENDING'
export const OUT_OF_PAYROLL_REPORT_LOAD_FULFILLED = 'OUT_OF_PAYROLL_REPORT_LOAD_FULFILLED'
export const OUT_OF_PAYROLL_REPORT_LOAD_REJECTED = 'OUT_OF_PAYROLL_REPORT_LOAD_REJECTED'

export const OUT_OF_PAYROLL_EXPORT_JOURNALS = 'OUT_OF_PAYROLL_EXPORT_JOURNALS'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_PENDING = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_PENDING'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_FULFILLED = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_FULFILLED'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_REJECTED = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_REJECTED'

export const OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_PENDING =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_PENDING'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_FULFILLED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_FULFILLED'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_REJECTED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_REJECTED'

export const OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_PENDING =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_PENDING'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_FULFILLED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_FULFILLED'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_REJECTED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_REJECTED'

export const OUT_OF_PAYROLL_EXPORT_JOURNALS_MS = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_MS'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_PENDING = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_PENDING'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_FULFILLED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_FULFILLED'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_REJECTED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_REJECTED'

export const OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD = 'OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_PENDING =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_PENDING'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_FULFILLED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_FULFILLED'
export const OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_REJECTED =
  'OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_REJECTED'
