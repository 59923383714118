/** **************
 *  businessTripRequestsReducer
 /*************** */

import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  fetching: false,
  business_trip: {},
}

let changes = null
export default function businessTripRequestsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'CREATE_BUSINESS_TRIP_REQUEST_PENDING':
    case 'UPDATE_BUSINESS_TRIP_REQUEST_PENDING':
    case 'BUSINESS_TRIP_REQUEST_LOAD_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'BUSINESS_TRIP_REQUEST_LOAD_FULFILLED':
      changes = {
        fetching: false,
        business_trip: payload.data.data.business_trip_request,
      }
      return { ...state, ...changes }

    case 'CREATE_BUSINESS_TRIP_REQUEST_FULFILLED':
    case 'UPDATE_BUSINESS_TRIP_REQUEST_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CREATE_BUSINESS_TRIP_REQUEST_REJECTED':
    case 'UPDATE_BUSINESS_TRIP_REQUEST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
