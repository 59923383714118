export const ONBOARDING_EMPLOYMENT_TYPES_LOAD = 'ONBOARDING_EMPLOYMENT_TYPES_LOAD'
export const ONBOARDING_EMPLOYMENT_TYPES_LOAD_PENDING = 'ONBOARDING_EMPLOYMENT_TYPES_LOAD_PENDING'
export const ONBOARDING_EMPLOYMENT_TYPES_LOAD_FULFILLED =
  'ONBOARDING_EMPLOYMENT_TYPES_LOAD_FULFILLED'
export const ONBOARDING_EMPLOYMENT_TYPES_LOAD_REJECTED = 'ONBOARDING_EMPLOYMENT_TYPES_LOAD_REJECTED'

export const ONBOARDING_EMPLOYMENT_TYPES_SHOW = 'ONBOARDING_EMPLOYMENT_TYPES_SHOW'
export const ONBOARDING_EMPLOYMENT_TYPES_SHOW_PENDING = 'ONBOARDING_EMPLOYMENT_TYPES_SHOW_PENDING'
export const ONBOARDING_EMPLOYMENT_TYPES_SHOW_FULFILLED =
  'ONBOARDING_EMPLOYMENT_TYPES_SHOW_FULFILLED'
export const ONBOARDING_EMPLOYMENT_TYPES_SHOW_REJECTED = 'ONBOARDING_EMPLOYMENT_TYPES_SHOW_REJECTED'

export const ONBOARDING_EMPLOYMENT_TYPES_POST = 'ONBOARDING_EMPLOYMENT_TYPES_POST'
export const ONBOARDING_EMPLOYMENT_TYPES_POST_PENDING = 'ONBOARDING_EMPLOYMENT_TYPES_POST_PENDING'
export const ONBOARDING_EMPLOYMENT_TYPES_POST_FULFILLED =
  'ONBOARDING_EMPLOYMENT_TYPES_POST_FULFILLED'
export const ONBOARDING_EMPLOYMENT_TYPES_POST_REJECTED = 'ONBOARDING_EMPLOYMENT_TYPES_POST_REJECTED'

export const ONBOARDING_EMPLOYMENT_TYPES_PUT = 'ONBOARDING_EMPLOYMENT_TYPES_PUT'
export const ONBOARDING_EMPLOYMENT_TYPES_PUT_PENDING = 'ONBOARDING_EMPLOYMENT_TYPES_PUT_PENDING'
export const ONBOARDING_EMPLOYMENT_TYPES_PUT_FULFILLED = 'ONBOARDING_EMPLOYMENT_TYPES_PUT_FULFILLED'
export const ONBOARDING_EMPLOYMENT_TYPES_PUT_REJECTED = 'ONBOARDING_EMPLOYMENT_TYPES_PUT_REJECTED'

export const ONBOARDING_EMPLOYMENT_TYPES_DELETE = 'ONBOARDING_EMPLOYMENT_TYPES_DELETE'
export const ONBOARDING_EMPLOYMENT_TYPES_DELETE_PENDING =
  'ONBOARDING_EMPLOYMENT_TYPES_DELETE_PENDING'
export const ONBOARDING_EMPLOYMENT_TYPES_DELETE_FULFILLED =
  'ONBOARDING_EMPLOYMENT_TYPES_DELETE_FULFILLED'
export const ONBOARDING_EMPLOYMENT_TYPES_DELETE_REJECTED =
  'ONBOARDING_EMPLOYMENT_TYPES_DELETE_REJECTED'
