export const DUDCTIONS_TYPES_LOAD = 'DUDCTIONS_TYPES_LOAD'
export const DUDCTIONS_TYPES_LOAD_FULFILLED = 'DUDCTIONS_TYPES_LOAD_FULFILLED'
export const DUDCTIONS_TYPES_LOAD_PENDING = 'DUDCTIONS_TYPES_LOAD_PENDING'
export const DUDCTIONS_TYPES_LOAD_REJECTED = 'DUDCTIONS_TYPES_LOAD_REJECTED'

export const DUDCTIONS_TYPES_UPDATE = 'DUDCTIONS_TYPES_UPDATE'
export const DUDCTIONS_TYPES_UPDATE_PENDING = 'DUDCTIONS_TYPES_UPDATE_PENDING'
export const DUDCTIONS_TYPES_UPDATE_FULFILLED = 'DUDCTIONS_TYPES_UPDATE_FULFILLED'
export const DUDCTIONS_TYPES_UPDATE_REJECTED = 'DUDCTIONS_TYPES_UPDATE_REJECTED'

export const DUDCTIONS_TYPES_CREATE = 'DUDCTIONS_TYPES_CREATE'
export const DUDCTIONS_TYPES_CREATE_PENDING = 'DUDCTIONS_TYPES_CREATE_PENDING'
export const DUDCTIONS_TYPES_CREATE_FULFILLED = 'DUDCTIONS_TYPES_CREATE_FULFILLED'
export const DUDCTIONS_TYPES_CREATE_REJECTED = 'DUDCTIONS_TYPES_CREATE_REJECTED'

export const DUDCTIONS_TYPES_DELETE = 'DUDCTIONS_TYPES_DELETE'
export const DUDCTIONS_TYPES_DELETE_PENDING = 'DUDCTIONS_TYPES_DELETE_PENDING'
export const DUDCTIONS_TYPES_DELETE_FULFILLED = 'DUDCTIONS_TYPES_DELETE_FULFILLED'
export const DUDCTIONS_TYPES_DELETE_REJECTED = 'DUDCTIONS_TYPES_DELETE_REJECTED'
