import { Employee } from 'redux/authUser/types'

type RequestType = Record<string, unknown> & {
  employee?: Employee
  request_corrections?: Array<{
    reason: string
  }>
}

export const handelRequestPath = (request: RequestType, params: string): string => {
  if (
    [
      'AssetRequest',
      'AssetClearRequest',
      'AssetCancellationRequest',
      'AssetClearCancellationRequest',
    ].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/asset_request?${params}`
  } else if (
    ['BusinessTripRequest', 'BusinessTripCancellationRequest'].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/business_trip_request?${params}`
  } else if (
    ['MissingPunchRequest', 'MissingPunchCancellationRequest'].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/missing_punch_request?${params}`
  } else if (['ExcuseRequest', 'ExcuseCancellationRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/excuse_request?${params}`
  } else if (
    ['ExitReentryVisaRequest', 'ExitReentryVisaCancellationRequest'].includes(
      `${request.request_type}`,
    )
  ) {
    return `/setting_v2/workflow/requests/exit_reentry_visa_request?${params}`
  } else if (
    ['ExpenseClaimRequest', 'ExpenseClaimCancellationRequest'].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/expense_claim_request?${params}`
  } else if (['HiringRequest', 'HiringCancellationRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/hiring_request?${params}`
  } else if (['InfoChangeRequest', 'DocumentRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/info_change_request?${params}`
  } else if (
    [
      'LeaveRequest',
      'LeaveCancellationRequest',
      'LeaveResumptionRequest',
      'LeaveResumptionCancellationRequest',
    ].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/leave_request?${params}`
  } else if (['LetterRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/letter_request?${params}`
  } else if (['LoanRequest', 'LoanCancellationRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/loan_request?${params}`
  } else if (
    ['OvertimeRequest', 'OvertimeCancellationRequest'].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/overtime_request?${params}`
  } else if (
    ['ResignationRequest', 'ResignationCancellationRequest'].includes(`${request.request_type}`)
  ) {
    return `/setting_v2/workflow/requests/resignation_request?${params}`
  } else if (['FlightTicketRequest'].includes(`${request.request_type}`)) {
    return `/setting_v2/workflow/requests/flight_ticket_request?${params}`
  }
  return `/setting_v2/workflow/requests/${request.request_type_id}/custom_requests?${params}`
}
