import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const getOnboardingUrl = (): string => {
  const isNewOnboarding = window.location.hash.includes('#/new_onboarding')
  const push = isNewOnboarding ? '/onboarding_' : '/onboarding/'
  return push
}

export function onboardingReviewLeaveDetailsLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}${getOnboardingUrl()}leaves`

  return {
    type: actions.ONBOARDING_LEAVE_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function onboardingReviewLeaveDetailsUpdate(body = {} as { leave_id: number }): Action {
  const url = `/${API_VERSION_TWO}${getOnboardingUrl()}leaves/${body.leave_id}`

  return {
    type: actions.ONBOARDING_LEAVE_UPDATE,
    payload: handleAPI(url, {}, 'PUT', body),
  }
}
