// import { Toastr } from 'components/global/Toastr';
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const initialState = {
  steps: [],
  errMsg: null,
  fetching: false,
}

function updateStepsRecord(steps) {
  return steps.map((step, i) => {
    if (typeof step.approver === 'object') {
      return {
        ...step,
        step_count: i + 1,
        approver: step.approver.id,
        employee: step.approver,
      }
    }
    return { ...step, step_count: i + 1 }
  })
}

export default function workflowStepsManagement(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.SET_WORKFLOW_STEPS:
      return {
        ...state,
        steps: updateStepsRecord(payload.steps),
        fetching: false,
        errMsg: null,
      }
    case actions.DELETE_WORKFLOW_STEP_FULFILLED:
      return {
        ...state,
        steps: removeOneListOfRecords(state.steps, payload.step.id).map((step, i) => ({
          ...step,
          step_count: i + 1,
        })),
        fetching: false,
        errMsg: null,
      }

    case actions.RESET_WORKFLOW_STEPS:
      return {
        ...state,
        steps: [],
        fetching: false,
      }

    default:
      return state
  }
}
