import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  deductions: [],
  offboarding_deduction: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function offboardDeductionReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let deductions = []
  let newRecord = {}
  let updatedRecord = {}

  switch (action.type) {
    case 'OFFBOARD_DEDUCTIONS_LOAD_PENDING':
    case 'OFFBOARD_DEDUCTION_CREATE_PENDING':
    case 'OFFBOARD_DEDUCTION_UPDATE_PENDING':
    case 'OFFBOARD_DEDUCTION_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'OFFBOARD_DEDUCTIONS_LOAD_REJECTED':
    case 'OFFBOARD_DEDUCTION_CREATE_REJECTED':
    case 'OFFBOARD_DEDUCTION_UPDATE_REJECTED':
    case 'OFFBOARD_DEDUCTION_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_DEDUCTIONS_LOAD_FULFILLED':
    case 'OFFBOARD_DEDUCTION_TYPES_LOAD_FULFILLED':
    case 'OFFBOARD_DEDUCTION_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_DEDUCTION_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      deductions = [...state.deductions]
      newRecord = payload.data.data.offboarding_deduction
      changes = {
        deductions: [...deductions, newRecord],
        editing: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_DEDUCTION_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      deductions = [...state.deductions]
      updatedRecord = payload.data.data.offboarding_deduction
      changes = {
        deductions: updateOneListOfRecords(deductions, updatedRecord),
        editing: false,
      }
      return { ...state, ...changes }

    case 'OFFBOARD_DEDUCTION_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      deductions = [...state.deductions]
      return {
        ...state,
        deductions: removeOneListOfRecords(deductions, payload.id),
        error: null,
      }

    case 'OFFBOARD_DEDUCTION_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'OFFBOARD_DEDUCTION_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'RESET_FORM':
      changes = {
        offboarding_deduction: {},
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
