import { Flex, Label, Spacer, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useFormState } from 'react-final-form'
import { NewSelectField, BetaTextInputField as NewTextInputField } from 'components/final-form'
import { composeValidators, maxLength, required } from 'components/global/form/FormValidations'
import { useCategories } from 'apis/OrgManagement/categories/useCategories'
import { TCompanyData } from '../DepartmentForm'
import styles from './style.module.css'

type TNewOnboardingFormProps = {
  hideParentInput?: boolean
  componyData?: TCompanyData
}

const NewOnboardingForm = ({
  hideParentInput,
  componyData,
}: TNewOnboardingFormProps): JSX.Element => {
  const { initialValues } = useFormState()
  const listDepartments = useSelector((s) => s.newDepartments.list_departments)
  const { data: categories } = useCategories(
    { category_type: 'department' },
    {
      query: {
        select: ({ data }) => data.data.categories,
      },
    },
  )

  return (
    <>
      <Flex
        flexCol
        className={styles.drawerFrame}
      >
        <Typography
          variant="subheading"
          textColor="color/fg/bold"
          text={i18n.t('label.department')}
        />
        <div>
          <Label name={i18n.t('label.department_name_english')} />
          <Spacer height={6} />
          <NewTextInputField
            name="name"
            // autoFocus={!initialValues?.id}
            disabled={initialValues?.disableNameFieldes}
            validate={composeValidators(maxLength(100), required)}
          />
        </div>
        <div>
          <Label name={i18n.t('label.department_name_arabic')} />
          <Spacer height={6} />
          <NewTextInputField
            name="name_ar"
            // autoFocus={!initialValues?.id}
            disabled={initialValues?.disableNameFieldes}
            validate={composeValidators(maxLength(100), required)}
          />
        </div>
        <div>
          <Flex className="gap-[6px]">
            <Label name={i18n.t('label.department_category')} />
            <Typography
              textColor="color/fg/lighter"
              variant="interface/medium/sm"
              text={i18n.t('label.optional-1')}
            />
          </Flex>
          <Spacer height={6} />

          <NewSelectField
            isClearable
            valueKey="id"
            labelKey="name_i18n"
            name="category_ids[0]"
            options={categories ?? []}
            placeholder={i18n.t('select')}
          />
        </div>
      </Flex>
      {!hideParentInput && (
        <>
          <Spacer height={16} />
          <Flex
            flexCol
            className={styles.drawerFrame}
          >
            <Typography
              variant="subheading"
              textColor="color/fg/bold"
              text={i18n.t('label.parent')}
            />
            <div>
              <Label name={i18n.t('label.list_this_department_under')} />
              <Spacer height={6} />
              <NewSelectField
                isClearable
                valueKey="id"
                name="parent_id"
                labelKey="name_i18n"
                disabled={initialValues?.child_id}
                options={[componyData, ...listDepartments].filter(Boolean)}
              />
            </div>
          </Flex>
        </>
      )}
    </>
  )
}

export default NewOnboardingForm
