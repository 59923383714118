import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  areas: [],
  area: { manager: {} },
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function areaReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let areas = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'AREAS_LOAD_PENDING':
    case 'AREA_CREATE_PENDING':
    case 'AREA_UPDATE_PENDING':
    case 'AREA_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'AREAS_LOAD_REJECTED':
    case 'AREA_CREATE_REJECTED':
    case 'AREA_UPDATE_REJECTED':
    case 'AREA_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'AREA_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'AREAS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'AREA_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      areas = [...state.areas]
      newRecord = payload.data.data.area
      changes = {
        areas: [...areas, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'AREA_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      areas = [...state.areas]
      updatedRecord = payload.data.data.area
      changes = {
        areas: updateOneListOfRecords(areas, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'AREA_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      areas = [...state.areas]
      return {
        ...state,
        areas: removeOneListOfRecords(areas, payload.id),
        error: null,
        fetching: false,
      }

    case 'AREA_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'AREA_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
