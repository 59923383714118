const Automations = {
  // A
  automated: 'النظام (سير العمل التلقائي)',
  automation: 'مسار العمل التلقائي',
  automation_name: 'اسم مسار العمل التلقائي',
  add_a_trigger: 'إضافة بداية',
  automation_name_english: 'اسم مسار العمل التلقائي',
  automation_name_arabic: 'اسم مسار العمل التلقائي (عربي)',
  action_type: 'نوع العملية',
  action_details: 'تفاصيل العملية',
  auto_approve: 'الموافقة التلقائية',
  auto_reject: 'الرفض التلقائي',
  auto_approver_details: 'تفاصيل الموافقة التلقائية',
  auto_reject_details: 'تفاصيل الرفض التلقائي',
  auto_reject_alert_description:
    'هذا الإجراء سيقوم برفض الطلب تلقائيًا إذا لم يتم الموافقة عليه خلال الإطار الزمني المحدد. إذا لم يتم تحديد إطار زمني أو تم الوصول إلى آخر موافق، سيتم رفض الطلب فورًا وتلقائيًا.',
  auto_approver_alert_description:
    'هذا الإجراء سيقوم تلقائيًا بالموافقة والانتقال إلى الموافق التالي. إذا تم الوصول إلى آخر الموافقين، سيتم الموافقة على الطلب تلقائيًا.',
  announcement_details: 'تفاصيل الإعلان',
  add_action: 'إضافة عملية',
  add_condition: 'أضف شرط',
  automation_for: 'لمدة',
  automation_properties: 'خواص مسار العمل',
  all_employees_receivers: 'جميع الموظفين',
  automation_lists: 'قائمة سير العمل',
  automate_your_operations_desc: 'سهل العمليات اليومية بإستخدام سير العمل التلقائي.',
  approved_by_automation: 'تمت الموافقة بسير العمل التلقائي',
  automation_options: 'خيارات الأتمتة',

  // B
  // C
  condition: 'الشرط',
  condition_details: 'تفاصيل الشرط',
  create_task: 'إنشاء مهمة',
  contains: 'تتضمن',
  condition_and: 'وأيضا',
  condition_yes: 'نعم',
  condition_no: 'لا',
  cannot_upload_more_than_files: 'لا يمكنك رفع أكثر من {{max}} ملفات.',
  can_upload_a_maximum_of_files: 'يمكنك رفع ما يصل إلى {{max}} ملفات كحد أقصى.',
  current_approver: 'الموافق الحالي',

  // D
  deleted_automation: 'محذوف',
  // E
  email_details: 'تفاصيل البريد الإلكتروني',
  enable_this_automation: 'تفعيل مسار العمل',
  edit_message: 'تحرير الرسالة',
  enable_this_if_you_want_the_automation: 'فعّل هذا إذا كنت تريد تشغيل الأتمتة لمرة واحدة فقط.',
  edit_name: 'تحرير الإسم',

  // F
  // G

  // H
  history_automation: 'تاريخ سير العمل التلقائي',

  // I
  if: 'في حالة',
  includes: 'يشمل',

  // J
  // K
  // L
  last_run: 'آخر تشغيل',
  less_than_or_equal: 'أصغر من أو يساوي',
  line_manager_approver: 'مدير مٌقدم الطلب',

  // M
  more_than: 'أكثر من',
  // N
  new_automation: 'مسار عمل جديد',
  notification_details: 'تفاصيل الإشعار',
  no_condition_path: 'مسار الشرط (لا)',
  number_employee: '{{employees}}  موظفين يعملون وفق هذه السياسة.',
  no_history_yet: 'ليس لديك تاريخ لأي سير عمليات حتى الآن!',
  never_married: 'أعزب',

  // O
  // P
  performed: 'نٌفذت',
  please_select: 'اختر من فضلك',
  please_select_request_type: 'اختر نوع الطلب من فضلك',
  personal_id: 'الهوية',
  pending_approval_days: 'أيام الموافقة المعلقة',

  // Q
  // R
  runs: 'تشغيلات',
  rename_this_automation: 'إعادة التسمية',
  remove_condition: 'إزالة الشرط',
  remove_no_condition_path: 'حذف مسار الشرط (لا)',
  rejected_by_automation: 'تم الرفض من قبل سير العمل التلقائي',
  run_only_once: 'تشغيل لمرة واحدة فقط',

  // S
  search_by_automation_name: 'البحث بإسم مسار العمل التلقائي',
  suggested_triggers_for_you: 'اقتراحات للبداية مُخصصة لك...',
  select_task: 'إختيار المهمة',
  subject: 'الموضوع',
  send_to: 'إرسالها إلى',
  specify_user: 'تحديد المستخدم',
  send_announcement: 'ارسال إعلان',
  send_email: 'إرسال بريد إلكتروني',
  select_please: 'اختر من فضلك',
  search_by_template_name: 'البحث بالإسم ',
  // T
  trigger_details: 'تفاصيل بداية مسار العمل',
  triggers_are_the_starting_point_of_any_workflow:
    'اختر بداية مسار العمل التلقائي لتحديد بدء سلسلة الموافقات.',
  task_details: 'تفاصيل المهمة',
  triggered_employee: 'الموظف المُحفِز',
  trigger: 'البداية',

  // U
  // V
  // W
  workflow_name_en_required: 'الاسم الإنجليزي لمسار العمل التلقائي غير موجود',
  workflow_name_ar_required: 'الاسم العربي لمسار العمل التلقائي غير موجود',
  write_the_title: 'اكتب العنوان',
  write_message: 'اكتب الرسالة',

  // X
  // Y
  // Z
}

export default Automations
