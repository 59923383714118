import { Action } from 'types/redux'

import * as actions from './actions'
import { TInitialState } from './type'

const initialState: TInitialState = {
  fetching: false,
  invitationsStatistics: {
    accepted_count: 0,
    expired_count: 0,
    not_delivered_count: 0,
    not_invited_employees: 0,
    pending_count: 0,
    total_invites: 0,
    is_employees_invited: false,
  },
  employeeInvitations: [],
  undelivered_and_expired_count: 0,
  pagination: {},
}

export default function payslipsReducer(state = initialState, action: Action): TInitialState {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_INVITATIONS_RESET:
      return {
        ...state,
        employeeInvitations: [],
      }

    case actions.INVITATIONS_STATISTICS_LOAD_PENDING:
    case actions.EMPLOYEE_INVITATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.INVITATIONS_STATISTICS_LOAD_FULFILLED:
      const invitationsStatistics: TInitialState['invitationsStatistics'] =
        payload.data.data ?? initialState.invitationsStatistics
      return {
        ...state,
        invitationsStatistics,
        fetching: false,
      }

    case actions.EMPLOYEE_INVITATIONS_LOAD_FULFILLED:
      const employeeInvitations: TInitialState['employeeInvitations'] =
        payload.data.data?.employee_invites ?? initialState.employeeInvitations
      const pagination: TInitialState['pagination'] =
        payload.data.data?.pagination ?? initialState.pagination
      const undelivered_and_expired_count: TInitialState['undelivered_and_expired_count'] =
        payload.data.data?.undelivered_and_expired_count ??
        initialState.undelivered_and_expired_count
      return {
        ...state,
        employeeInvitations:
          pagination.current_page === 1
            ? [...employeeInvitations]
            : [...state.employeeInvitations, ...employeeInvitations],
        pagination,
        undelivered_and_expired_count,
        fetching: false,
      }

    case actions.INVITATIONS_STATISTICS_LOAD_REJECTED:
    case actions.EMPLOYEE_INVITATIONS_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
