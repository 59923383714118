import { Lens } from '@dhmk/zustand-lens'

export type EventLocationCount = {
  created: number
  deleted: number
}

export type OnboardingEventState = {
  locationsCount: EventLocationCount
  departmentCount: EventLocationCount
  setLocationsCount: (data: EventLocationCount) => void
  setDepartmentCount: (data: EventLocationCount) => void
}

const onboardingEventState: OnboardingEventState = {
  locationsCount: { created: 0, deleted: 0 },
  departmentCount: { created: 0, deleted: 0 },
  setLocationsCount: () => null,
  setDepartmentCount: () => null,
}

export const createOnboardingEventState: Lens<OnboardingEventState> = (set) => ({
  ...onboardingEventState,
  setLocationsCount: (data): void => set(() => ({ locationsCount: data })),
  setDepartmentCount: (data): void => set(() => ({ locationsCount: data })),
})
