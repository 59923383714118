export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD = 'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_PENDING =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_PENDING'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_FULFILLED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_FULFILLED'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_REJECTED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENTS_LOAD_REJECTED'

export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_PENDING =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_PENDING'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_FULFILLED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_FULFILLED'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_REJECTED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_CREATE_REJECTED'

export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_PENDING =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_PENDING'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_FULFILLED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_FULFILLED'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_REJECTED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_UPDATE_REJECTED'

export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_PENDING =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_PENDING'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_FULFILLED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_FULFILLED'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_REJECTED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_DELETE_REJECTED'

export const CLONE_LEAVES_SETTINGS = 'CLONE_LEAVES_SETTINGS'
export const CLONE_LEAVES_SETTINGS_PENDING = 'CLONE_LEAVES_SETTINGS_PENDING'
export const CLONE_LEAVES_SETTINGS_FULFILLED = 'CLONE_LEAVES_SETTINGS_FULFILLED'
export const CLONE_LEAVES_SETTINGS_REJECTED = 'CLONE_LEAVES_SETTINGS_REJECTED'
