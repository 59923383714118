export const MICROSOFT_COST_CENTER_LIST_LOAD = 'MICROSOFT_COST_CENTER_LIST_LOAD'
export const MICROSOFT_COST_CENTER_LIST_LOAD_FULFILLED = 'MICROSOFT_COST_CENTER_LIST_LOAD_FULFILLED'
export const MICROSOFT_COST_CENTER_LIST_LOAD_REJECTED = 'MICROSOFT_COST_CENTER_LIST_LOAD_REJECTED'
export const MICROSOFT_COST_CENTER_LIST_LOAD_PENDING = 'MICROSOFT_COST_CENTER_LIST_LOAD_PENDING'

export const MICROSOFT_COST_CENTER_CREATE = 'MICROSOFT_COST_CENTER_CREATE'
export const MICROSOFT_COST_CENTER_CREATE_FULFILLED = 'MICROSOFT_COST_CENTER_CREATE_FULFILLED'
export const MICROSOFT_COST_CENTER_CREATE_REJECTED = 'MICROSOFT_COST_CENTER_CREATE_REJECTED'
export const MICROSOFT_COST_CENTER_CREATE_PENDING = 'MICROSOFT_COST_CENTER_CREATE_PENDING'

export const MICROSOFT_COST_CENTER_UPDATE = 'MICROSOFT_COST_CENTER_UPDATE'
export const MICROSOFT_COST_CENTER_UPDATE_FULFILLED = 'MICROSOFT_COST_CENTER_UPDATE_FULFILLED'
export const MICROSOFT_COST_CENTER_UPDATE_REJECTED = 'MICROSOFT_COST_CENTER_UPDATE_REJECTED'
export const MICROSOFT_COST_CENTER_UPDATE_PENDING = 'MICROSOFT_COST_CENTER_UPDATE_PENDING'

export const MICROSOFT_COST_CENTER_DELETE = 'MICROSOFT_COST_CENTER_DELETE'
export const MICROSOFT_COST_CENTER_DELETE_FULFILLED = 'MICROSOFT_COST_CENTER_DELETE_FULFILLED'
export const MICROSOFT_COST_CENTER_DELETE_REJECTED = 'MICROSOFT_COST_CENTER_DELETE_REJECTED'
export const MICROSOFT_COST_CENTER_DELETE_PENDING = 'MICROSOFT_COST_CENTER_DELETE_PENDING'

export const MICROSOFT_COST_CENTER_DIMENSIONS = 'MICROSOFT_COST_CENTER_DIMENSIONS'
export const MICROSOFT_COST_CENTER_DIMENSIONS_FULFILLED =
  'MICROSOFT_COST_CENTER_DIMENSIONS_FULFILLED'
export const MICROSOFT_COST_CENTER_DIMENSIONS_REJECTED = 'MICROSOFT_COST_CENTER_DIMENSIONS_REJECTED'
export const MICROSOFT_COST_CENTER_DIMENSIONS_PENDING = 'MICROSOFT_COST_CENTER_DIMENSIONS_PENDING'

export const MICROSOFT_COST_CENTER_DIMENSIONS_VALUES = 'MICROSOFT_COST_CENTER_DIMENSIONS_VALUES'
export const MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_FULFILLED =
  'MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_FULFILLED'
export const MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_REJECTED =
  'MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_REJECTED'
export const MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_PENDING =
  'MICROSOFT_COST_CENTER_DIMENSIONS_VALUES_PENDING'

export const MICROSOFT_COST_CENTER_ACCOUNTS = 'MICROSOFT_COST_CENTER_ACCOUNTS'
export const MICROSOFT_COST_CENTER_ACCOUNTS_FULFILLED = 'MICROSOFT_COST_CENTER_ACCOUNTS_FULFILLED'
export const MICROSOFT_COST_CENTER_ACCOUNTS_REJECTED = 'MICROSOFT_COST_CENTER_ACCOUNTS_REJECTED'
export const MICROSOFT_COST_CENTER_ACCOUNTS_PENDING = 'MICROSOFT_COST_CENTER_ACCOUNTS_PENDING'

export const MICROSOFT_JOURNAL_TEMPLATE_LOAD = 'MICROSOFT_JOURNAL_TEMPLATE_LOAD'
export const MICROSOFT_JOURNAL_TEMPLATE_LOAD_FULFILLED = 'MICROSOFT_JOURNAL_TEMPLATE_LOAD_FULFILLED'
export const MICROSOFT_JOURNAL_TEMPLATE_LOAD_REJECTED = 'MICROSOFT_JOURNAL_TEMPLATE_LOAD_REJECTED'
export const MICROSOFT_JOURNAL_TEMPLATE_LOAD_PENDING = 'MICROSOFT_JOURNAL_TEMPLATE_LOAD_PENDING'
