/* eslint-disable no-shadow */
export enum ReminderStatusKey {
  company_document = 'Reminder: Expiring company document',
  probationPeriods = 'Reminder: Expiring probation',
  employeeBirthday = 'Notification: Employee birthday',
  employeeAnniversary = 'Notification: Employee anniversary',
}

const companyDocument = {
  before: 'label.company_document_expires_in_30_days',
  send_email: 'label.to_all_admins',
  to: 'label.all_admins',
  subject: 'label.company_document_expiring_soon',
}

const probationPeriods = {
  before: 'label.probation_period_ends_in_7_days',
  send_email: 'label.to_line_manager',
  to: 'label.line_manager',
  subject: 'label.employee_probation_periods_ends_soon',
}

export const activateModalBodyData = {
  companyDocument,
  probationPeriods,
}
