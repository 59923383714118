import { Action } from 'types/redux'
import * as actions from './actions'

export const initialState: { fetching: boolean } = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case actions.ONBOARDING_ORGANIZATION_TRACKING_STEPS_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_ORGANIZATION_TRACKING_STEPS_PUT_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ORGANIZATION_TRACKING_STEPS_PUT_REJECTED:
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
