export const VACTION_SALART_CALCULATION_LOAD = 'VACTION_SALART_CALCULATION_LOAD'
export const VACTION_SALART_CALCULATION_LOAD_PENDING = 'VACTION_SALART_CALCULATION_LOAD_PENDING'
export const VACTION_SALART_CALCULATION_LOAD_FULFILLED = 'VACTION_SALART_CALCULATION_LOAD_FULFILLED'
export const VACTION_SALART_CALCULATION_LOAD_REJECTED = 'VACTION_SALART_CALCULATION_LOAD_REJECTED'

export const VACTION_SALART_CALCULATION_UPDATE = 'VACTION_SALART_CALCULATION_UPDATE'
export const VACTION_SALART_CALCULATION_UPDATE_PENDING = 'VACTION_SALART_CALCULATION_UPDATE_PENDING'
export const VACTION_SALART_CALCULATION_UPDATE_FULFILLED =
  'VACTION_SALART_CALCULATION_UPDATE_FULFILLED'
export const VACTION_SALART_CALCULATION_UPDATE_REJECTED =
  'VACTION_SALART_CALCULATION_UPDATE_REJECTED'
