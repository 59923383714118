import React from 'react'
import i18n from 'translations/i18n'
import { FileUpload, Flex } from '@jisr-hr/ds-beta'
import {
  Assignee,
  Info,
  Reason,
  ReassignTask,
} from 'components/global/templates/RequestDrawer/components'
import { useSelector } from 'utils/hooks'
import { isEmpty } from 'lodash'
import { ReactComponent as HeartIcon } from 'assets/images/Heart.svg'
import { ReactComponent as CalendarIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/calendar.svg'
import { useRequest } from '../../RequestProvider'
import { RequestType } from '../../components/FlightTicketDetails/helper'
import useTaskFooter from '../../Footer/useTaskFooter'

const ExitReentryVisaRequestTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { fetching_submit } = useSelector((state) => state.approvals)
  const { onAttachTaskFile } = useTaskFooter()
  const { task_info } = request

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          created_at: request?.request?.created_time,
          requested_on: request?.request?.created_time,
          type_i18n: request.name,
          due_date: request.due_date,
          items: [
            {
              icon: <CalendarIcon />,
              label: i18n.t('apply_date'),
              value: task_info?.apply_date,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('type'),
              value: task_info?.entry_type,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('period'),
              value: `${task_info?.period} ${i18n.t('month')}`,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('return_date'),
              value: task_info?.return_date,
            },
            !isEmpty(task_info?.family_details) && {
              icon: <HeartIcon />,
              value: <RequestType items={task_info?.family_details?.map((data) => data?.name)} />,
              label: i18n.t('family_members'),
            },
          ].filter(Boolean),
        }}
      />
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      {request?.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{ attachments: request.attachments }}
        />
      )}
      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
          time: request?.time,
        }}
      />
    </Flex>
  )
}

export default ExitReentryVisaRequestTask
