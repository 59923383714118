import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  fetching: false,
  qualifications: [],
}

type QualificationsState = typeof initialState

export default function reducer(state = initialState, action: Action): QualificationsState {
  const { payload } = action
  switch (action.type) {
    case actions.QUALIFICATIONS_LOAD_PENDING:
    case actions.QUALIFICATION_CREATE_PENDING:
    case actions.QUALIFICATION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.QUALIFICATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.QUALIFICATION_CREATE_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        qualifications: [
          // @ts-expect-error Need to migrate to TypeScript
          ...state.qualifications,
          // @ts-expect-error Need to migrate to TypeScript
          payload.data.data.qualification,
        ],
        fetching: false,
      }

    case actions.QUALIFICATION_UPDATE_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        qualifications: updateOneListOfRecords(
          state.qualifications,
          payload.data.data.qualification,
        ),
        fetching: false,
      }

    case actions.QUALIFICATION_DELETE_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.QUALIFICATIONS_LOAD_REJECTED:
    case actions.QUALIFICATION_CREATE_REJECTED:
    case actions.QUALIFICATION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
