import I18n from 'translations/i18n'

function Translate(value) {
  return I18n.t(value)
}

export const LEAVE_STATUSES = [
  { name: 'pending', id: 'Pending' },
  { name: 'approved', id: 'Approved' },
  { name: 'cancelled', id: 'Cancelled' },
  { name: 'rejected', id: 'Rejected' },
]

export const PAYMENT_STATUSES = [
  { name: Translate('label.all'), id: 'all' },
  { name: Translate('label.intiated_'), id: 'initiated' },
  { name: Translate('label.under_processing'), id: 'under_processing' },
  { name: Translate('label.scheduled'), id: 'scheduled' },
  { name: Translate('status.cancelled'), id: 'cancelled' },
  { name: Translate('label.failed'), id: 'failed' },
  { name: Translate('label.paid'), id: 'paid' },
  { name: Translate('label.partially_uploaded'), id: 'partially_uploaded' },
]

export const PAYMENT_METHODS = [
  { name: 'cash', id: 'Cash' },
  { name: 'bank', id: 'Bank' },
  { name: 'cheque', id: 'Cheque' },
  { name: 'international_transfer', id: 'International Transfer' },
]

export const PAYMENT_MODES = [
  { name: Translate('label.all'), id: 'all' },
  { name: Translate('label.jisr_wallet'), id: 'wallet' },
  { name: Translate('label.mudad'), id: 'mudad' },
  { name: Translate('label.cash'), id: 'cash' },
  { name: Translate('label.sab'), id: 'sabb' },
  { name: Translate('label.direct_transfare_file'), id: 'direct_transfer' },
  { name: Translate('label.payment_card_payments'), id: 'salary_card' },
  { name: Translate('label.international_transfer'), id: 'international_transfer' },
  { name: Translate('create_label.wps_without_payment_method'), id: 'wps_without_payment_method' },
]

export const CONTRACT_TYPES = [
  { id: 'Fixed term', name: I18n.t('fixed_term') },
  { id: 'Indefinite', name: I18n.t('indefinite') },
]

export const EMPLOYEE_STATUS = [
  { id: 'active', name: I18n.t('active') },
  { id: 'inactive', name: I18n.t('inactive') },
]

export const ASSETS_STATUS = [
  { name: I18n.t('available'), id: 'Available' },
  { name: I18n.t('allocated'), id: 'Allocated' },
  { name: I18n.t('inrepair'), id: 'In-Repair' },
  { name: I18n.t('damaged'), id: 'Damaged' },
  { name: I18n.t('archived'), id: 'Archived' },
]

export const EXPIRY_STATUS = [
  { id: 'expired', name: I18n.t('expired') },
  { id: 'not expired', name: I18n.t('not_expired') },
]

export const PAYMENT_TYPES = [
  { name_i18n: Translate('cash'), id: 'Cash' },
  { name_i18n: Translate('bank'), id: 'Bank' },
  { name_i18n: Translate('cheque'), id: 'Cheque' },
  { name_i18n: Translate('international_transfer'), id: 'International Transfer' },
]

export const GOSI_REGISTRATION_OPTIONS_EMPLOYEE_PROFILE = (t) => [
  {
    label: I18n.t('not_registered_in_gosi'),
    value: false,
    withChildren: !t && true,
  },
  {
    label: I18n.t('registered_in_gosi'),
    value: true,
    testId: 'registered_in_gosi',
    withChildren: t && true,
  },
]

export const GOSI_REGISTRATION_OPTIONS = [
  {
    name: I18n.t('not_registered_in_gosi'),
    id: 'false',
  },
  {
    name: I18n.t('registered_in_gosi'),
    id: 'true',
    testId: 'registered_in_gosi',
  },
]

export const SALARY_TYPES = [
  { id: 'Bank Account', name: I18n.t('bank_account') },
  { id: 'Salary Card', name: I18n.t('salary_card') },
]
export const BANK_TYPES = [
  { id: 'sabb', value: Translate('sabb_bank') },
  { id: 'riyadh', value: Translate('riyadh_bank') },
  { id: 'saudi_fransi', value: Translate('saudi_fransi_bank') },
  // { id: 'ncb', value: Translate('ncb_bank') },
  { id: 'rajhi', value: Translate('rajhi_bank') },
  { id: 'anb', value: Translate('anp_bank') },
  { id: 'alinma', value: Translate('alinma_bank') },
  { id: 'aljazeera', value: Translate('aljazeera') },
  { id: 'investment', value: Translate('investment') },
  { id: 'alawwal', value: Translate('alawwal') },
  { id: 'ncb_misk', value: Translate('ncb_misk') },
  { id: 'albilad', value: Translate('albilad') },
]

export const GENDER = [
  { id: 'Male', name: Translate('male') },
  { id: 'Female', name: Translate('female') },
]

export const MARITAL_STATUS = [
  { id: 'Married', name: I18n.t('married') },
  { id: 'Single', name: Translate('never_married') },
  { id: 'Divorced', name: Translate('divorced') },
]

export const RELIGION = [
  { id: 'Muslim', name: Translate('muslim') },
  { id: 'Non-Muslim', name: Translate('non_muslim') },
]

export const AMOUNT_TYPE = [
  { id: 'Percentage', name: Translate('percentage') },
  { id: 'Value', name: Translate('value') },
]

export const LETTER_TYPES = [
  { id: '1', name: Translate('commitment_letter') },
  { id: '2', name: Translate('clearance_letter') },
]

export const RELATIONSHIPS = [
  { id: 'Spouse', name: Translate('spouse') },
  { id: 'Son', name: Translate('son') },
  { id: 'Daughter', name: Translate('daughter') },
]

export const ASSET_STATUS = [
  { id: 'Allocated', name: Translate('with_employee') },
  { id: 'Cleared', name: Translate('cleared') },
]

export const LOAN_CATEGORY = [
  { id: 'Normal', name: Translate('normal') },
  { id: 'Urgent', name: Translate('urgent') },
]

export const PAYROLL_CALCULATION_BASE = [
  { id: '1', name: Translate('basic_salary') },
  { id: '2', name: Translate('full_package') },
  { id: '3', name: Translate('basic_plus_selected_benefits_types') },
]

export const LEAVING_REASONS = [
  {
    name: Translate('leaving_reason_1'),
    id: 'Worker and the employer to terminate the contract agreement',
  },
  {
    name: Translate('leaving_reason_2'),
    id: 'Termination of the contract by the employer',
  },
  {
    name: Translate('leaving_reason_3'),
    id: 'Termination of the contract by the employer for a case in Article (80)',
  },
  {
    name: Translate('leaving_reason_4'),
    id: 'Leaving the worker to work as a result of force majeure',
  },
  {
    name: Translate('leaving_reason_5'),
    id: 'Termination of the labor contract to work within six months of the marriage contract, or within three months of the situation',
  },
  {
    name: Translate('leaving_reason_6'),
    id: 'Worker to leave work for a case in Article (81)',
  },
  {
    name: Translate('leaving_reason_7'),
    id: 'Leaving the worker to work without providing the resignation of cases is contained in Article (81)',
  },
  {
    name: Translate('leaving_reason_8'),
    id: 'Resignation factor',
  },
]

export const SHIFT_TYPES_LIST = [
  { id: 'Normal', name: Translate('normal') },
  { id: 'Split', name: Translate('split') },
  { id: 'Flexible', name: Translate('flexible') },
  { id: 'One punch', name: Translate('one_punch') },
]

export const DURATIONS = [
  { id: '3', name: '3' },
  { id: '6', name: '6' },
]

export const FULL_WEEK_DAYS = [
  { id: 'Sunday', name: Translate('sunday') },
  { id: 'Monday', name: Translate('monday') },
  { id: 'Tuesday', name: Translate('tuesday') },
  { id: 'Wednesday', name: Translate('wednesday') },
  { id: 'Thursday', name: Translate('thursday') },
  { id: 'Friday', name: Translate('friday') },
  { id: 'Saturday', name: Translate('saturday') },
]

export const MONTHS_NAME = [
  Translate('january'),
  Translate('february'),
  Translate('march'),
  Translate('april'),
  Translate('may'),
  Translate('june'),
  Translate('july'),
  Translate('august'),
  Translate('september'),
  Translate('october'),
  Translate('november'),
  Translate('december'),
]

export const MONTHS = [
  { id: 1, name: Translate('january') },
  { id: 2, name: Translate('february') },
  { id: 3, name: Translate('march') },
  { id: 4, name: Translate('april') },
  { id: 5, name: Translate('may') },
  { id: 6, name: Translate('june') },
  { id: 7, name: Translate('july') },
  { id: 8, name: Translate('august') },
  { id: 9, name: Translate('september') },
  { id: 10, name: Translate('october') },
  { id: 11, name: Translate('november') },
  { id: 12, name: Translate('december') },
]

export const CARRY_FORWARD_TYPES = [
  { id: 'No carry', name: Translate('no_carry') },
  { id: 'Limited carry', name: Translate('limited_carry') },
  { id: 'Carry all', name: Translate('carry_all') },
]

export const SAUDI_NATIONALITY = ['Bahraini', 'Saudi', 'Kuwaiti', 'Qatari', 'Omani', 'Emirati']

export const SEGMENT_BY = [
  { id: 'department', name: Translate('department') },
  { id: 'location', name: Translate('location') },
  { id: 'employment_type', name: Translate('employment_type') },
  { id: 'job_title', name: Translate('job_title') },
  { id: 'gender', name: Translate('gender') },
  { id: 'nationality', name: Translate('nationality') },
]

export const VIEW_BY = [
  { id: 'stack', name: Translate('stack') },
  { id: 'percentage', name: Translate('percentage') },
]

export const REASON_TYPES = [
  { name: Translate('all'), id: '' },
  { id: 'Business', name: Translate('business') },
  { id: 'Personal', name: Translate('personal') },
]

export const EXCUSE_TYPES = [
  { id: 'Coming Late', name: Translate('coming_late') },
  { id: 'Leaving Early', name: Translate('leaving_early') },
]

export const EXCUES_REASON_TYPES = [
  { id: 'Personal', name: I18n.t('ex_personal') },
  { id: 'Business', name: I18n.t('ex_business') },
]

export const REQUEST_TYPES = [
  { name: Translate('leave_request'), id: 'leave' },
  { name: Translate('overtime_request'), id: 'overtime' },
  { name: Translate('loan_request'), id: 'loan' },
  { name: Translate('letter_request'), id: 'letter' },
  { name: Translate('excuse_request'), id: 'excuse' },
  { name: Translate('business_trip_request'), id: 'business_trip' },
  { name: Translate('missing_punch_request'), id: 'missing_punch' },
  { name: Translate('resignation_request'), id: 'resignation' },
  { name: Translate('hiring_request'), id: 'hiring' },
]

export const REQUEST_STATUS = [
  // { name: Translate('all'), id: 'all' },
  { name: Translate('approved'), id: 'Approved' },
  { name: Translate('pending'), id: 'Pending' },
  { name: Translate('status.pending_correction'), id: 'Pending for Correction' },
  { name: Translate('rejected'), id: 'Rejected' },
  { name: Translate('cancelled'), id: 'Cancelled' },
  { name: Translate('expired'), id: 'Expired' },
]

export const XERO_CONFIGURATIONS = [
  { name: Translate('bill_per_employee'), id: '1' },
  { name: Translate('grouped_bill'), id: '2' },
]

export const EXTRA_COLUMNS = [
  'show_full_name',
  'add_user_shift',
  'add_delay',
  'add_working_hours',
  'add_break_time',
  'add_extra_time',
  'add_excused_time',
  'add_approved_overtime',
  'parent_department',
  'department',
  'area',
  'location',
  'job_title',
  'business_unit',
  'sponsor',
  'remarks',
  'show_payment_type',
  'show_bank',
  'iban',
  'employment_type',
  'show_work_email',
  'parent_department',
  'grade',
  'nationality',
  'basic_salary',
  'benefits',
  'total_salary',
  'additions',
  'total_additions',
  'deductions',
  'total_deduction',
  'net_salary',
]
export const AnnouncementType = [{ id: 'News', name: I18n.t('announcement') }]

export const OtherLeaveDescription = [
  { id: 'Casual', name: I18n.t('casual_description') },
  { id: 'Death of relatives', name: I18n.t('death_of_relatives_description') },
  // { id: 'Emergency', name: I18n.t('emergency_description') },
  { id: 'Hajj', name: I18n.t('hajj_description') },
  { id: 'Marriage', name: I18n.t('marriage_description') },
  { id: 'Sick', name: I18n.t('sick_description') },
  { id: 'Study', name: I18n.t('study_description') },
  { id: 'Unpaid', name: I18n.t('unpaid_description') },
  { id: 'Widow', name: I18n.t('widow_description') },
  { id: 'Maternity', name: I18n.t('maternity_description') },
  { id: 'Paternity', name: I18n.t('paternity_description') },
]

export const COLORS = [
  '#69d2e7',
  '#a7dbd8',
  '#fa6900',
  '#fe4365',
  '#fc9d9a',
  '#f9cdad',
  '#c8c8a9',
  '#83af9b',
  '#ecd078',
  '#d95b43',
  '#c02942',
  '#542437',
  '#53777a',
  '#556270',
  '#4ecdc4',
  '#c7f464',
  '#ff6b6b',
  '#c44d58',
  '#774f38',
  '#e08e79',
  '#f1d4af',
  '#ece5ce',
  '#c5e0dc',
  '#e8ddcb',
  '#cdb380',
  '#036564',
  '#033649',
  '#031634',
  '#490a3d',
  '#bd1550',
  '#e97f02',
  '#f8ca00',
  '#8a9b0f',
  '#594f4f',
  '#547980',
  '#45ada8',
  '#9de0ad',
  '#e5fcc2',
  '#00a0b0',
  '#6a4a3c',
  '#cc333f',
  '#eb6841',
  '#edc951',
  '#e94e77',
  '#d68189',
  '#c6a49a',
  '#c6e5d9',
  '#f4ead5',
  '#3fb8af',
  '#7fc7af',
  '#dad8a7',
  '#ff9e9d',
  '#ff3d7f',
  '#d9ceb2',
  '#948c75',
  '#d5ded9',
  '#7a6a53',
  '#99b2b7',
  '#ffffff',
  '#cbe86b',
  '#f2e9e1',
  '#1c140d',
  '#cbe86b',
  '#efffcd',
  '#dce9be',
  '#555152',
  '#2e2633',
  '#99173c',
  '#343838',
  '#005f6b',
  '#008c9e',
  '#00b4cc',
  '#00dffc',
  '#413e4a',
  '#73626e',
  '#b38184',
  '#f0b49e',
  '#f7e4be',
  '#ff4e50',
  '#fc913a',
  '#f9d423',
  '#ede574',
  '#e1f5c4',
  '#99b898',
  '#fecea8',
  '#ff847c',
  '#e84a5f',
  '#2a363b',
  '#655643',
  '#80bca3',
  '#f6f7bd',
  '#e6ac27',
  '#bf4d28',
  '#00a8c6',
  '#40c0cb',
  '#f9f2e7',
  '#aee239',
  '#8fbe00',
  '#351330',
  '#424254',
  '#64908a',
  '#e8caa4',
  '#cc2a41',
  '#554236',
  '#f77825',
  '#d3ce3d',
  '#f1efa5',
  '#60b99a',
  '#ff9900',
  '#424242',
  '#e9e9e9',
  '#bcbcbc',
  '#3299bb',
  '#5d4157',
  '#838689',
  '#a8caba',
  '#cad7b2',
  '#ebe3aa',
  '#8c2318',
  '#5e8c6a',
  '#88a65e',
  '#bfb35a',
  '#f2c45a',
  '#fad089',
  '#ff9c5b',
  '#f5634a',
  '#ed303c',
  '#3b8183',
  '#ff4242',
  '#f4fad2',
  '#d4ee5e',
  '#e1edb9',
  '#f0f2eb',
  '#d1e751',
  '#ffffff',
  '#000000',
  '#4dbce9',
  '#26ade4',
  '#f8b195',
  '#f67280',
  '#c06c84',
  '#6c5b7b',
  '#355c7d',
  '#1b676b',
  '#519548',
  '#88c425',
  '#bef202',
  '#eafde6',
  '#bcbdac',
  '#cfbe27',
  '#f27435',
  '#f02475',
  '#3b2d38',
  '#5e412f',
  '#fcebb6',
  '#78c0a8',
  '#f07818',
  '#f0a830',
  '#452632',
  '#91204d',
  '#e4844a',
  '#e8bf56',
  '#e2f7ce',
  '#eee6ab',
  '#c5bc8e',
  '#696758',
  '#45484b',
  '#36393b',
  '#f0d8a8',
  '#3d1c00',
  '#86b8b1',
  '#f2d694',
  '#fa2a00',
  '#f04155',
  '#ff823a',
  '#f2f26f',
  '#fff7bd',
  '#95cfb7',
  '#2a044a',
  '#0b2e59',
  '#0d6759',
  '#7ab317',
  '#a0c55f',
  '#bbbb88',
  '#ccc68d',
  '#eedd99',
  '#eec290',
  '#eeaa88',
  '#b9d7d9',
  '#668284',
  '#2a2829',
  '#493736',
  '#7b3b3b',
  '#b3cc57',
  '#ecf081',
  '#ffbe40',
  '#ef746f',
  '#ab3e5b',
  '#a3a948',
  '#edb92e',
  '#f85931',
  '#ce1836',
  '#009989',
  '#67917a',
  '#170409',
  '#b8af03',
  '#ccbf82',
  '#e33258',
  '#e8d5b7',
  '#0e2430',
  '#fc3a51',
  '#f5b349',
  '#e8d5b9',
  '#aab3ab',
  '#c4cbb7',
  '#ebefc9',
  '#eee0b7',
  '#e8caaf',
  '#300030',
  '#480048',
  '#601848',
  '#c04848',
  '#f07241',
  '#ab526b',
  '#bca297',
  '#c5ceae',
  '#f0e2a4',
  '#f4ebc3',
  '#607848',
  '#789048',
  '#c0d860',
  '#f0f0d8',
  '#604848',
  '#a8e6ce',
  '#dcedc2',
  '#ffd3b5',
  '#ffaaa6',
  '#ff8c94',
  '#3e4147',
  '#fffedf',
  '#dfba69',
  '#5a2e2e',
  '#2a2c31',
  '#b6d8c0',
  '#c8d9bf',
  '#dadabd',
  '#ecdbbc',
  '#fedcba',
  '#fc354c',
  '#29221f',
  '#13747d',
  '#0abfbc',
  '#fcf7c5',
  '#1c2130',
  '#028f76',
  '#b3e099',
  '#ffeaad',
  '#d14334',
  '#edebe6',
  '#d6e1c7',
  '#94c7b6',
  '#403b33',
  '#d3643b',
  '#cc0c39',
  '#e6781e',
  '#c8cf02',
  '#f8fcc1',
  '#1693a7',
  '#dad6ca',
  '#1bb0ce',
  '#4f8699',
  '#6a5e72',
  '#563444',
  '#a7c5bd',
  '#e5ddcb',
  '#eb7b59',
  '#cf4647',
  '#524656',
  '#fdf1cc',
  '#c6d6b8',
  '#987f69',
  '#e3ad40',
  '#fcd036',
  '#5c323e',
  '#a82743',
  '#e15e32',
  '#c0d23e',
  '#e5f04c',
  '#230f2b',
  '#f21d41',
  '#ebebbc',
  '#bce3c5',
  '#82b3ae',
  '#b9d3b0',
  '#81bda4',
  '#b28774',
  '#f88f79',
  '#f6aa93',
  '#3a111c',
  '#574951',
  '#83988e',
  '#bcdea5',
  '#e6f9bc',
  '#5e3929',
  '#cd8c52',
  '#b7d1a3',
  '#dee8be',
  '#fcf7d3',
  '#1c0113',
  '#6b0103',
  '#a30006',
  '#c21a01',
  '#f03c02',
  '#382f32',
  '#ffeaf2',
  '#fcd9e5',
  '#fbc5d8',
  '#f1396d',
  '#e3dfba',
  '#c8d6bf',
  '#93ccc6',
  '#6cbdb5',
  '#1a1f1e',
  '#000000',
  '#9f111b',
  '#b11623',
  '#292c37',
  '#cccccc',
  '#c1b398',
  '#605951',
  '#fbeec2',
  '#61a6ab',
  '#accec0',
  '#8dccad',
  '#988864',
  '#fea6a2',
  '#f9d6ac',
  '#ffe9af',
  '#f6f6f6',
  '#e8e8e8',
  '#333333',
  '#990100',
  '#b90504',
  '#1b325f',
  '#9cc4e4',
  '#e9f2f9',
  '#3a89c9',
  '#f26c4f',
  '#5e9fa3',
  '#dcd1b4',
  '#fab87f',
  '#f87e7b',
  '#b05574',
  '#951f2b',
  '#f5f4d7',
  '#e0dfb1',
  '#a5a36c',
  '#535233',
  '#413d3d',
  '#040004',
  '#c8ff00',
  '#fa023c',
  '#4b000f',
  '#eff3cd',
  '#b2d5ba',
  '#61ada0',
  '#248f8d',
  '#605063',
  '#2d2d29',
  '#215a6d',
  '#3ca2a2',
  '#92c7a3',
  '#dfece6',
  '#cfffdd',
  '#b4dec1',
  '#5c5863',
  '#a85163',
  '#ff1f4c',
  '#4e395d',
  '#827085',
  '#8ebe94',
  '#ccfc8e',
  '#dc5b3e',
  '#9dc9ac',
  '#fffec7',
  '#f56218',
  '#ff9d2e',
  '#919167',
  '#a1dbb2',
  '#fee5ad',
  '#faca66',
  '#f7a541',
  '#f45d4c',
  '#ffefd3',
  '#fffee4',
  '#d0ecea',
  '#9fd6d2',
  '#8b7a5e',
  '#a8a7a7',
  '#cc527a',
  '#e8175d',
  '#474747',
  '#363636',
  '#ffedbf',
  '#f7803c',
  '#f54828',
  '#2e0d23',
  '#f8e4c1',
  '#f8edd1',
  '#d88a8a',
  '#474843',
  '#9d9d93',
  '#c5cfc6',
  '#f38a8a',
  '#55443d',
  '#a0cab5',
  '#cde9ca',
  '#f1edd0',
  '#4e4d4a',
  '#353432',
  '#94ba65',
  '#2790b0',
  '#2b4e72',
  '#0ca5b0',
  '#4e3f30',
  '#fefeeb',
  '#f8f4e4',
  '#a5b3aa',
  '#a70267',
  '#f10c49',
  '#fb6b41',
  '#f6d86b',
  '#339194',
  '#9d7e79',
  '#ccac95',
  '#9a947c',
  '#748b83',
  '#5b756c',
  '#edf6ee',
  '#d1c089',
  '#b3204d',
  '#412e28',
  '#151101',
  '#046d8b',
  '#309292',
  '#2fb8ac',
  '#93a42a',
  '#ecbe13',
  '#4d3b3b',
  '#de6262',
  '#ffb88c',
  '#ffd0b3',
  '#f5e0d3',
  '#fffbb7',
  '#a6f6af',
  '#66b6ab',
  '#5b7c8d',
  '#4f2958',
  '#ff003c',
  '#ff8a00',
  '#fabe28',
  '#88c100',
  '#00c176',
  '#fcfef5',
  '#e9ffe1',
  '#cdcfb7',
  '#d6e6c3',
  '#fafbe3',
  '#9cddc8',
  '#bfd8ad',
  '#ddd9ab',
  '#f7af63',
  '#633d2e',
  '#30261c',
  '#403831',
  '#36544f',
  '#1f5f61',
  '#0b8185',
  '#d1313d',
  '#e5625c',
  '#f9bf76',
  '#8eb2c5',
  '#615375',
  '#ffe181',
  '#eee9e5',
  '#fad3b2',
  '#ffba7f',
  '#ff9c97',
  '#aaff00',
  '#ffaa00',
  '#ff00aa',
  '#aa00ff',
  '#00aaff',
  '#c2412d',
  '#d1aa34',
  '#a7a844',
  '#a46583',
  '#5a1e4a',
]

export const PERIOD = [
  { id: 2, name: `2 ${I18n.t('months')}` },
  { id: 3, name: `3 ${I18n.t('months')}` },
  { id: 4, name: `4 ${I18n.t('months')}` },
  { id: 5, name: `5 ${I18n.t('months')}` },
  { id: 6, name: `6 ${I18n.t('months')}` },
]
export const ExitReEntryType = [
  { id: 'Single', name: I18n.t('single_leave') },
  { id: 'Multiple', name: I18n.t('multiple_leave') },
]

export const LETTER_TEMPLATE_TYPES = [
  { id: 'Promote', name: Translate('promote') },
  { id: 'Uplode_Attachment', name: Translate('uplode_attachment') },
]

export const NOT_IN_SAUDI = [
  { id: 'true', name: Translate('Yes') },
  { id: 'false', name: Translate('No') },
]
