import { FC } from 'react'
import { Form, NewSelectField, BetaTextInputField as TextInputField } from 'components/final-form'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import i18n from 'translations/i18n'
import { required, fixedLength, composeValidators } from 'components/global/form/FormValidations'
import { Button } from '@jisr-hr/ds'
import { config, animated, useSpring } from 'react-spring'
import { useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'
import { BankPayload } from 'types/PaymentMethods/banks'

type AddAccountFormProps = {
  hideCommercialList?: boolean
  handleSubmit: (arg: BankPayload) => void
}

const AddAccountForm: FC<AddAccountFormProps> = (props) => {
  const { hideCommercialList = false } = props
  const crs = useSelector((s) => s.commercialRegistrations.commercial_registrations, shallowEqual)
  const fetching = useSelector((s) => s.organizationBanks.fetching)
  const [style] = useSpring(() => ({
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: config.wobbly,
    trail: 150,
  }))

  return (
    <Form onSubmit={props.handleSubmit}>
      {({ handleSubmit }): JSX.IntrinsicElements['form'] => (
        <animated.form
          onSubmit={handleSubmit}
          className={mainStyles.main_widget}
          style={style}
        >
          {!hideCommercialList && (
            <NewSelectField
              name="commercial_registration_id"
              label={i18n.t('commercial_registration')}
              valueKey="id"
              labelKey="name"
              validate={required}
              options={crs}
              placeholder={i18n.t('commercial_registration')}
              testId="commercial_registrations"
            />
          )}
          <div className="py-4">
            <TextInputField
              name="iban"
              validate={composeValidators(required, fixedLength(22))}
              placeholder={i18n.t('iban')}
              label={i18n.t('iban')}
              prefix="SA"
              testId="new-iban"
            />
          </div>
          <Button
            label={i18n.t('add_bank_account')}
            size="medium"
            disabled={fetching}
            type="submit"
          />
        </animated.form>
      )}
    </Form>
  )
}

export default AddAccountForm
