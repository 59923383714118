import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const isOnboarding = window.location.href.includes('/onboarding')
const urlPrefix = isOnboarding ? 'onboarding/' : ''

export function haveMudadInvoice(params = {}): Action {
  const url = `/${API_VERSION_TWO}/${urlPrefix}mudad_subscriptions/invoice`
  return {
    type: actions.MUDAD_INVOICE_GENERATED_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
