/* eslint-disable no-console */

import { openDB } from 'idb'

const messagingDB = 'firebase-messaging-database'
const messagingStore = 'firebase-messaging-store'
const installationsDB = 'firebase-installations-database'
const installationsStore = 'firebase-installations-store'

type StoreValueType = {
  createTime: number
  token: string
  subscriptionOptions: {
    auth: string
    endpoint: string
    p256dh: string
    swScope: string
    vapidKey: string
  }
}

export const firebaseConfig = {
  apiKey: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FQSV9LRVk,
  authDomain: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FVVEhfRE9NQUlO,
  databaseURL: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0RBVEFCQVNFX1VSTAo,
  projectId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX1BST0pFQ1RfSUQK,
  storageBucket: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX1NUT1JBR0VfQlVDS0VU,
  messagingSenderId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX01FU1NBR0lOR19TRU5ERVJfSUQK,
  appId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX0FQUF9JRA,
  measurementId: import.meta.env.UkVBQ1RfQVBQX0ZJUkVCQVNFX01FQVNVUkVNRU5UX0lECg,
}

export const getTokenFromDB = async (options?: { clear: boolean }): Promise<string> => {
  const { clear = false } = options ?? {}
  try {
    const db = await openDB(messagingDB)
    const store = db.transaction(messagingStore).objectStore(messagingStore)
    const values: StoreValueType[] | null = await store.getAll()

    const token = values?.[0]?.token
    if (clear) {
      db.clear(messagingStore)
      const dbInstallations = await openDB(installationsDB)
      dbInstallations.clear(installationsStore)
    }
    return token
  } catch (e) {
    console.error("Couldn't open indexedDB", e)
    return ''
  }
}

const createIDB = async (dbName: string, storeName: string): Promise<void> => {
  try {
    await openDB(dbName, 1, {
      upgrade(db, oldVersion, newVersion) {
        if (newVersion === 1) {
          const stores = db.objectStoreNames
          if (!stores.contains(storeName)) {
            db.createObjectStore(storeName)
          }
        }
      },
    })
  } catch (e) {
    console.error(`Error creating ${dbName}`, e)
  }
}

createIDB(messagingDB, messagingStore)
createIDB(installationsDB, installationsStore)
