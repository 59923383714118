import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import I18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import { Select } from '@jisr-hr/ds-beta'
import { Field, useForm, useFormState } from 'react-final-form'
import { required } from 'components/global/form/FormValidations'
import { Flex, Typography } from '@jisr-hr/ds'
import { replaceAmPm } from 'components/global/form/formNormalize'
import { Trans } from 'react-i18next'
import useUpdateEffect from 'components/global/hooks/useUpdateEffect'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import {
  getLogTimeAndDate,
  getManualPunchValidationMessage,
} from 'containers/authorised/attendance/helper'
import { parse } from 'date-fns'

import ManuallyTime from './ManuallyTime'
import ExistingPunch from './ExistingPunch'
import DisplayPunch from './DisplayPunch'
import { correctTypes } from '../helper'

import { ReactComponent as ErrorIcon } from '../icons/error.svg'

import Styles from '../ProposedCorrectionTime.module.css'

const PunchOption = (props) => {
  const { type: punchType, is_existing } = props
  const { editRequest } = useRequest()
  const { change } = useForm()
  const { values } = useFormState()
  const { attendance_logs } = useSelector(({ requestDetails }) => requestDetails)
  const { attendance_record } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )
  const shifts = attendance_record?.[0]?.employee_shifts
  const isEmptyShifts = _.isEmpty(shifts)
  const { in_punch: in_time, out_punch: out_time } = attendance_record?.[0] || {}
  const {
    first_in_punch,
    first_out_punch,
    second_in_punch,
    second_out_punch,
    first_in_punch_date,
    first_out_punch_date,
    second_in_punch_date,
    second_out_punch_date,
  } = values || {}
  const { in_punch: first_in_time, out_punch: first_out_time } = shifts?.[0] || {}
  const {
    in_punch: second_in_time,
    out_punch: second_out_time,
    shift_start_time: second_shift_start_time,
  } = shifts?.[1] || {}

  const inTime = getLogTimeAndDate(isEmptyShifts ? in_time : first_in_time)
  const outTime = getLogTimeAndDate(isEmptyShifts ? out_time : first_out_time)

  const shiftIndex = shifts?.findIndex(({ id }) => values?.shift === id)
  const firstInTime = first_in_punch || inTime.time
  const firstOutTime = first_out_punch || outTime.time
  const secondInTime = second_in_punch || getLogTimeAndDate(second_in_time).time
  const secondOutTime = second_out_punch || getLogTimeAndDate(second_out_time).time
  const isInNotRecorded =
    !(shiftIndex === 0 || isEmptyShifts ? firstInTime : secondInTime) && punchType === 'out_punch'

  const dateKey = `${shiftIndex === 0 || isEmptyShifts ? 'first' : 'second'}_${punchType}_date`
  const [type, setType] = React.useState('existing')
  const [isEdit, setIsEdit] = React.useState(true)

  const [manualTime, setManualTime] = React.useState({
    time: '',
    date: values[dateKey] || values.correction_date,
  })

  const selectedType = correctTypes?.find((option) => option.value === type)

  const handleChange = (v) => {
    change(`${shiftIndex === 0 || isEmptyShifts ? 'first' : 'second'}_${punchType}`, v.punch_time)
    change(punchType, v)
    change(`${punchType}_date`, v.date)
    setIsEdit(false)
  }

  const handleChangeType = (v) => {
    setType(v.value)
    change(punchType, null)
  }

  const handleManualTime = (vals) => {
    setManualTime((prev) => ({
      ...prev,
      ...vals,
    }))
  }

  const handleMaxMin = () => {
    // ? Convert to new data across browsers
    const firstShiftInTime =
      firstInTime && first_in_punch_date
        ? parse(`${first_in_punch_date} ${firstInTime}`, 'yyyy-MM-dd hh:mm a', new Date())
        : null
    const firstShiftOutTime =
      firstOutTime && first_out_punch_date
        ? parse(`${first_out_punch_date} ${firstOutTime}`, 'yyyy-MM-dd hh:mm a', new Date())
        : null

    const secondShiftStartTime =
      second_shift_start_time && values.correction_date
        ? parse(
            `${values.correction_date} ${second_shift_start_time}`,
            'yyyy-MM-dd hh:mm a',
            new Date(),
          )
        : null

    const secondShiftInTime =
      secondInTime && second_in_punch_date
        ? parse(`${second_in_punch_date} ${secondInTime}`, 'yyyy-MM-dd hh:mm a', new Date())
        : null

    const secondShiftOutTime =
      secondOutTime && second_out_punch_date
        ? parse(`${second_out_punch_date} ${secondOutTime}`, 'yyyy-MM-dd hh:mm a', new Date())
        : null

    return {
      first_shift: {
        in_punch: {
          time: firstShiftInTime,
          min: null,
          max: firstShiftOutTime || secondShiftInTime || secondShiftOutTime,
        },
        out_punch: {
          time: firstShiftOutTime,
          min: firstShiftInTime,
          max: secondShiftInTime || secondShiftStartTime,
        },
      },
      second_shift: {
        in_punch: {
          time: secondShiftInTime,
          min: firstShiftOutTime || firstShiftInTime,
          max: secondShiftOutTime,
        },
        out_punch: {
          time: secondShiftOutTime,
          min: secondShiftInTime,
          max: null,
        },
      },
    }
  }

  const minMaxTime = handleMaxMin()
  const punches = shiftIndex === 1 ? minMaxTime.second_shift : minMaxTime.first_shift
  const validateLogs = () => {
    const manualTimeDate =
      manualTime.time && manualTime.date
        ? parse(`${manualTime.date} ${manualTime.time}`, 'yyyy-MM-dd hh:mm a', new Date())
        : null

    const currentInTime = punchType === 'in_punch' ? manualTimeDate : punches.in_punch.time
    const currentOutTime = punchType === 'out_punch' ? manualTimeDate : punches.out_punch.time

    const getInMessage = getManualPunchValidationMessage(
      currentInTime,
      punches.in_punch.min,
      punches.in_punch.max,
    )

    const getOutMessage = getManualPunchValidationMessage(
      currentOutTime,
      punches.out_punch.min,
      punches.out_punch.max,
    )

    const message = punchType === 'in_punch' ? getInMessage : getOutMessage
    return message
  }

  const messageError = validateLogs()

  const punchValidation = punchType === 'in_punch' ? punches.in_punch : punches.out_punch

  const typeComponents = {
    existing: (
      <ExistingPunch
        {...props}
        isInNotRecorded={isInNotRecorded}
        handleChange={handleChange}
        minTime={punchValidation.min}
        maxTime={punchValidation.max}
        fieldTime={punchValidation.time}
      />
    ),
    manually: (
      <ManuallyTime
        {...props}
        isInNotRecorded={isInNotRecorded}
        punchType={`${shiftIndex === 0 || isEmptyShifts ? 'first' : 'second'}_${punchType}`}
        status={messageError?.message && 'error'}
        manualTime={manualTime}
        handleClose={() => setIsEdit(false)}
        handleExit={() => {
          setIsEdit(false)
        }}
        setTime={handleManualTime}
        error={!!messageError}
      />
    ),
  }

  React.useEffect(() => {
    if (editRequest) {
      setType(!is_existing ? 'manually' : 'existing')
    } else {
      setType(_.isEmpty(attendance_logs) ? 'manually' : 'existing')
    }
  }, [attendance_logs, editRequest])

  useUpdateEffect(() => {
    if (punchType === 'in_punch') {
      change('in_punch', null)
    } else {
      change('out_punch', null)
    }

    change('first_in_punch', null)
    change('first_out_punch', null)
    change('second_in_punch', null)
    change('second_out_punch', null)

    change('first_in_punch_date', inTime.date || values.correction_date)
    change('first_out_punch_date', outTime.date || values.correction_date)
    change('second_in_punch_date', getLogTimeAndDate(second_in_time).date || values.correction_date)
    change(
      'second_in_punch_date',
      getLogTimeAndDate(second_out_time).date || values.correction_date,
    )

    setIsEdit(true)
  }, [values.shift])

  useUpdateEffect(() => {
    handleManualTime({
      date: values.correction_date,
    })
  }, [values.correction_date])

  return isEdit ? (
    <Flex
      flexCol
      style={{ gap: 8 }}
    >
      <Flex
        flexCol
        className={Styles.punchOption}
      >
        <div className="grid grid-cols-12 gap-2">
          <div className={type === 'manually' ? 'col-span-5' : 'col-span-6'}>
            <Select
              options={correctTypes}
              value={selectedType}
              onChange={handleChangeType}
              disabled={_.isEmpty(attendance_logs) || isInNotRecorded}
            />
          </div>
          <div className={type === 'manually' ? 'col-span-7' : 'col-span-6'}>
            <Field
              name="correct_time"
              validate={required}
            >
              {() => typeComponents[type]}
            </Field>
          </div>
        </div>
      </Flex>
      {((type === 'manually' && messageError?.message) || isInNotRecorded) && (
        <Flex
          itemsCenter
          style={{ gap: 10 }}
        >
          <ErrorIcon className={isInNotRecorded && Styles.orangeIcon} />
          <Typography
            text={
              !isInNotRecorded ? (
                <Trans
                  i18nKey={messageError?.message}
                  components={{ span: <span style={{ fontWeight: 700 }} /> }}
                  values={{
                    min: replaceAmPm(messageError?.values?.min),
                    max: replaceAmPm(messageError?.values?.max),
                  }}
                />
              ) : (
                I18n.t('propose_clock_in_time_first')
              )
            }
            variant="caption"
            style={{
              color: isInNotRecorded
                ? 'var(--color-base-colors-yellow-600)'
                : 'var(--color-base-colors-red-500)',
            }}
          />
        </Flex>
      )}
    </Flex>
  ) : (
    <DisplayPunch
      {...props}
      handleEdit={() => setIsEdit(true)}
    />
  )
}

PunchOption.propTypes = {
  is_existing: PropTypes.bool,
  type: PropTypes.string,
  detail: PropTypes.shape(),
}

export default React.memo(PunchOption)
