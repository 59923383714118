import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Typography } from '@jisr-hr/ds'

import { ReactComponent as ArrowIcon } from './icons/diagram-arrow.svg'

import Frame from '../../ds/Frame'
import Styles from './style.module.css'

export const changesetLabels = {
  home_city: I18n.t('town_city'),
  home_address1: I18n.t('address'),
  home_country: I18n.t('country'),
  nationality_name_i18n: I18n.t('nationality'),
  full_name_ar: I18n.t('label.full_name_arabic'),
  full_name_en: I18n.t('label.full_name_english'),
  iqama_expiry_date_ar: I18n.t('label.id.iqama_expiry_date_gregorian'),
  national_short_address: I18n.t('label.short_address'),
}

const InfoChange = ({ items }) => {
  const infoChangeRequestTypes = {
    family_detail: I18n.t('label.family_members'),
    qualification: I18n.t('label.education'),
    emergency_contact: I18n.t('label.emergency_contact'),
    id_iqama: I18n.t('label.id_iqama'),
    address: I18n.t('label.address_in_saudi'),
    passport: I18n.t('label.passport'),
    address_in_home: I18n.t('label.address_in_home_country'),
  }
  return (
    <Frame title={I18n.t('information_changes')}>
      <Typography
        text={
          infoChangeRequestTypes?.[items?.[0]?.infoChangeRequestType] ??
          I18n.t('personal_information')
        }
        variant="body-new/bold"
      />
      <table className={Styles.infoChangeTable}>
        <tbody>
          {items.map(({ label, newValue, oldValue }, i) => {
            if (label === 'attr_id') return null
            if (label === 'nationality_id') return null
            if (!oldValue && !newValue) return null

            return (
              <tr
                className={Styles.infoChangeRow}
                key={i}
              >
                <td className={Styles.infoChangeCell}>
                  <Typography
                    textColor="color/fg/lighter"
                    text={changesetLabels?.[label.toLowerCase()] ?? label}
                    variant="body-new/regular"
                  />
                </td>
                <td className={Styles.infoChangeCell}>
                  <Typography
                    text={oldValue || '--'}
                    variant="body-new/regular"
                  />
                </td>
                <td className={Styles.infoChangeCell}>
                  <ArrowIcon className={Styles.rotateY180} />
                </td>
                <td className={Styles.infoChangeCell}>
                  <Typography
                    text={newValue || '--'}
                    variant="body-new/semibold"
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Frame>
  )
}

InfoChange.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
}

export default InfoChange
