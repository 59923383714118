import I18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import { Widget } from 'components/global/atoms'
import { Flex, Spacer, Button, Typography } from '@jisr-hr/ds'
import { CheckboxField, Form, RadioBoxField, SelectField } from 'components/final-form'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  useGetOnboardingPayrunConfigurations,
  useUpdateOnboardingPayrunConfigurations,
} from 'apis/Finance/payrun_configurations/usePayrunConfigurations'
import {
  LoanSettingsType,
  PayrunConfigurationType,
} from 'apis/Finance/payrun_configurations/usePayrunConfigurations.types'
import styles from '../style.module.css'

type SubmitTypes = {
  is_applicable: boolean
  loan_full_or_basic: string
  loan_settings: string
}

const LoanPolicy = (): JSX.Element => {
  const dispatch = useDispatch()
  const { data: onboardingConfigs, isFetching } = useGetOnboardingPayrunConfigurations({
    query: {
      select: (d) => d.data.data,
    },
  })

  const { mutateAsync: onboardingPayrunConfigUpdate } = useUpdateOnboardingPayrunConfigurations()
  const loanSettingsValue = onboardingConfigs?.loan_settings.value as LoanSettingsType

  const maxLoanLimit = Array.from({ length: 25 }).map((_, i) => ({
    id: `${i + 1}`,
    value: `${i + 1}`,
  }))

  const options = [
    {
      value: 'Basic salary',
      label: I18n.t('basic_salary'),
      testId: 'Basic-salary',
    },
    {
      value: 'Full package',
      label: I18n.t('full_pacakage'),
      testId: 'full-pacakage',
    },
  ]

  const handleOnSubmit = (values: SubmitTypes): void => {
    const data: PayrunConfigurationType[] = [
      {
        name: 'loan_full_or_basic',
        value: values?.loan_full_or_basic,
      },
      {
        name: 'loan_settings',
        value: {
          is_applicable: values?.is_applicable,
          config: values?.loan_settings,
        },
      },
    ]
    onboardingPayrunConfigUpdate({ configurations: data }).then(() => {
      dispatch(organizationInfo())
    })
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={{
        is_applicable: loanSettingsValue?.is_applicable,
        loan_settings: loanSettingsValue?.config,
        loan_full_or_basic: onboardingConfigs?.loan_full_or_basic?.value,
      }}
    >
      {({ pristine, handleSubmit }): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            <Widget
              fetching={isFetching}
              style={{ padding: 24 }}
            >
              <div>
                <Typography
                  variant="title-1"
                  text={I18n.t('loan_policy')}
                  style={{ color: 'var(--color-base-colors-black-500)' }}
                />

                <div
                  style={{ marginBottom: 24 }}
                  className={styles.divider}
                />
              </div>
              <Typography
                variant="subtitle-2"
                text={I18n.t('maximum_loan_amount')}
              />
              <Spacer height={8} />
              <SelectField
                // @ts-expect-error // select field needs to be migrated to TS
                valueKey="value"
                labelKey="id"
                options={maxLoanLimit}
                name="loan_settings"
                style={{ width: 130 }}
                testId="maximum_loan"
              />
              <Spacer height={8} />
              <Flex style={{ gap: 10 }}>
                {/* @ts-expect-error Need to migrate RadioBoxField.js to TypeScript */}
                <RadioBoxField
                  options={options}
                  name="loan_full_or_basic"
                  labelKey="label"
                  valueKey="value"
                  variant="simple"
                />
              </Flex>
              <Spacer height={20} />
              <CheckboxField
                name="is_applicable"
                label={I18n.t('allow_employee_request_loan')}
                className={styles.checkboxStyle}
              />
              <div className={styles.divider} />
              <Spacer height="25px" />
              <Flex justifyEnd>
                <Button
                  disabled={pristine || isFetching}
                  label={I18n.t('save')}
                  type="submit"
                  size="medium"
                  data-testid="save"
                />
              </Flex>
            </Widget>
          </form>
        )
      }}
    </Form>
  )
}

export default LoanPolicy
