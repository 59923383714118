import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'

import * as actions from './actions'

const DEFAULT_STATE = {
  asset_types: [],
  asset_type_suggestions: [],
  editing: {},
  errMsg: '',
  succMsg: null,
  fetching: false,
}

type RequestsState = typeof DEFAULT_STATE

export default function assetTypeReducer(state = DEFAULT_STATE, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_ASSET_TYPES_LOAD_PENDING:
    case actions.ONBOARDING_ASSET_TYPE_CREATE_PENDING:
    case actions.ONBOARDING_ASSET_TYPE_UPDATE_PENDING:
    case actions.ONBOARDING_ASSET_TYPE_DELETE_PENDING:
    case actions.ONBOARDING_ASSET_TYPE_SUGGESTIONS_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: '',
        succMsg: null,
      }

    case actions.ONBOARDING_ASSET_TYPES_LOAD_REJECTED:
    case actions.ONBOARDING_ASSET_TYPE_CREATE_REJECTED:
    case actions.ONBOARDING_ASSET_TYPE_UPDATE_REJECTED:
    case actions.ONBOARDING_ASSET_TYPE_DELETE_REJECTED:
    case actions.ONBOARDING_ASSET_TYPE_SUGGESTIONS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case actions.ONBOARDING_ASSET_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }

    case actions.ONBOARDING_ASSET_TYPE_SUGGESTIONS_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }

    case actions.ONBOARDING_ASSET_TYPE_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ASSET_TYPE_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ASSET_TYPE_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
