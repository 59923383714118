import React from 'react'
import i18n from 'translations/i18n'
import { Flex, Typography } from '@jisr-hr/ds'
import { Table } from '@jisr-hr/design-system'
import { format } from 'utils/date'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReactComponent as LinkIcon } from 'assets/figma-icons/Link.svg'
import {
  Info,
  Reason,
  ReassignTask,
  Assignee,
} from 'components/global/templates/RequestDrawer/components'
import { FileUpload } from '@jisr-hr/ds-beta'
import { useRequest } from '../../RequestProvider'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { ReactComponent as UserIcon } from '../../icons/user.svg'
import { ReactComponent as MoneyIcon } from '../../icons/Money.svg'
import LeaveDuration from '../../components/LeaveDuration'
import Frame from '../../ds/Frame'
import useTaskFooter from '../../Footer/useTaskFooter'
import InfoList from '../../ds/InfoList'
import Styles from './style.module.css'

const headers = [
  { label: i18n.t('type'), key: 'Type' },
  { label: i18n.t('total'), key: 'Total' },
]

const PayrunTask = () => {
  const {
    vars: { request },
  } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)
  const { onAttachTaskFile } = useTaskFooter()
  const { fetching_submit } = useSelector((state) => state.approvals)
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency?.currency_type_i18n,
  )
  const type =
    request.category === 'final_settlement_payment_task'
      ? 'final_settlement'
      : 'vacation_settlement'

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <Info
        details={{
          id: request.id,
          requested_by: request.employee,
          employee: request.employee,
          status: request.status,
          status_i18n: request.status_i18n,
          type_i18n: request.name,
          created_at: request?.created_at,
          requested_on: request?.created_at,
          payment_date: request?.task_info?.paid_at,
          due_date: request.due_date,
          category: request.category,
          paygroup_name: request?.task_info?.paygroup_name,
          payrun_month: request?.task_info?.payrun_month,
        }}
      />
      {request.attachments?.length > 0 && (
        <Reason
          title={i18n.t('attachment_file')}
          details={{
            attachments: request.attachments,
          }}
        />
      )}
      {request?.category === 'advance_vacation_salary_payment_task' && (
        <LeaveDuration
          applicable_days={request?.task_info?.ticket_info?.applied_days}
          from={request?.task_info?.ticket_info?.leave_start_date}
          to={request?.task_info?.ticket_info?.leave_to_date}
          showDetails
        />
      )}
      <Frame title={i18n.t('payment_info')}>
        <InfoList
          items={[
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('employees_with_bank'),
              value: request.task_info?.employees_with_bank,
            },
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('employees_with_cash'),
              value: request.task_info?.employees_with_cash,
            },
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('employees_with_cheque'),
              value: request.task_info?.employees_with_cheque,
            },
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('employees_with_direct_transfer'),
              value: request.task_info?.employees_with_direct_transfer,
            },
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('employees_with_international_transfer'),
              value: request.task_info?.employees_with_international_transfer,
            },
            request?.category === 'payroll_payment_task' && {
              icon: <UserIcon />,
              label: i18n.t('suspended_employees'),
              value: request.task_info?.suspended_employees,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('joining_date'),
              value: format(request.task_info?.ticket_info?.joining_date, 'MMMM dd, yyyy') || '',
            },
            {
              icon: <UserIcon />,
              label: i18n.t('termination_reason'),
              value: request.task_info?.ticket_info?.termination_reason,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('total_amount'),
              value: request.task_info?.ticket_info?.total_amount,
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('leave_start_date'),
              value:
                format(request.task_info?.ticket_info?.leave_start_date, 'MMMM dd, yyyy') || '',
            },
            {
              icon: <CalendarIcon />,
              label: i18n.t('leave_to_date'),
              value: format(request.task_info?.ticket_info?.leave_to_date, 'MMMM dd, yyyy') || '',
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('payment_type'),
              value: request.task_info?.ticket_info?.bank_info?.payment_type,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_name'),
              value: request.task_info?.ticket_info?.bank_info?.bank_name,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('bank_iban'),
              value: request.task_info?.ticket_info?.bank_info?.bank_iban,
            },
            {
              icon: <MoneyIcon />,
              label: i18n.t('salary_type'),
              value: request.task_info?.ticket_info?.bank_info?.salary_type,
            },
          ]}
        />
      </Frame>
      {request?.category !== 'payroll_payment_task' && (
        <Frame
          title={i18n.t('summary')}
          titleSuffix={
            <Link
              to={`/off_cycle/${type}/${request?.task_info?.payrun_id}/`}
              target="_blank"
            >
              <LinkIcon style={{ cursor: 'pointer' }} />
            </Link>
          }
          style={{ padding: 0 }}
        >
          <Table
            headers={headers}
            containerStyles={{ borderRadius: 0 }}
            className={Styles.summaryTable}
          >
            {request?.task_info?.ticket_info?.summary &&
              Object.entries(request?.task_info?.ticket_info?.summary).map(([key, value], i) => {
                return (
                  <Table.Row
                    key={i}
                    typography="paragraph14"
                    style={{ color: 'var(--color-natural-gray-darker-1-new)' }}
                  >
                    <Table.Cell>
                      <Typography
                        text={i18n.t(key)}
                        variant="subtitle-2"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Flex
                        style={{ width: '100%' }}
                        justifyEnd
                      >
                        <Typography
                          text={`${value} ${currency}`}
                          variant="subtitle-2"
                        />
                      </Flex>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table>
        </Frame>
      )}

      {!request?.group_details &&
        employee?.id === request?.assignee?.id &&
        request?.status === 'Pending' && (
          <>
            <FileUpload
              fileType="base64"
              onLoad={(file) => {
                if (file && Array.isArray(file)) {
                  onAttachTaskFile(file[0])
                }
              }}
              errorMessage="Unable to upload file"
              loadingMessage="Uploading file"
              message={i18n.t('add_attatchment')}
              successMessage={i18n.t('file_selected')}
              disabled={fetching_submit}
            />
          </>
        )}
      {request?.status === 'Pending' && <ReassignTask />}
      {(request?.task_info?.reason || request?.task_info?.attachments?.length > 0) && (
        <Reason
          details={{
            reason: request.task_info?.reason,
            attachments: request.task_info?.attachments,
          }}
        />
      )}
      <Assignee
        details={{
          assignee: request.assignee,
          status: request.status,
          status_i18n: request.status_i18n,
        }}
      />
    </Flex>
  )
}

export default PayrunTask
