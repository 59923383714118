import { Flex, Tag, Typography } from '@jisr-hr/ds'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Department } from 'redux/newDepartments/types'
import i18n from 'translations/i18n'

type Props = { data?: Department; title?: string }

type PropsNested = { department: Department }

const SubDepartmentTag: FC<PropsNested> = ({ department }) => {
  return (
    <>
      <Tag
        key={department.id}
        label={department.name_i18n}
      />

      {department?.departments?.map((nestedDepartment) => (
        <SubDepartmentTag
          key={nestedDepartment.id}
          department={nestedDepartment}
        />
      ))}
    </>
  )
}

export const countNestedList = (department: Department[] = []): number => {
  let count = 1
  department.forEach((subDepartment) => {
    count += countNestedList(subDepartment.departments)
  })

  return count
}

const ContentDeleteModal: FC<Props> = ({
  data,
  title = 'description.deleting_the_department_will_also_delete_its_sub_departments',
}) => {
  const supDepartmentCount = countNestedList(data?.departments) - 1

  if (data?.departments?.length) {
    return (
      <>
        <Typography
          style={{ marginBottom: 16 }}
          text={
            (
              <Trans
                i18nKey={i18n.t(title, {
                  department_name: data?.name_i18n,
                  count: supDepartmentCount,
                })}
                components={{ bold: <strong /> }}
                values={{ name: data?.name_i18n, count: supDepartmentCount }}
              />
            ) as unknown as string
          }
        />

        <Flex
          className="gap-[8px]"
          flexWrap
        >
          {data.departments.map((department) => (
            <SubDepartmentTag
              key={department.id}
              department={department}
            />
          ))}
        </Flex>
      </>
    )
  }
  return (
    <Trans
      i18nKey={i18n.t('description.are_you_sure_you_want_to_delete_department', {
        department_name: data?.name_i18n,
      })}
      components={{ bold: <strong /> }}
      values={{ name: data?.name_i18n }}
    />
  )
}

export default ContentDeleteModal
