import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'

import FilterBox from '../FilterBox'
import { useFilters } from './context'

type TimesheetEmployeeProps = {
  title: string
  filterKey: string
  extra_title: string
  filters: string[]
}

const TimesheetEmployee = (props: TimesheetEmployeeProps) => {
  const { filter, handleFilter } = useFilters()

  const employees = useSelector(({ employee }) => employee?.employee_all, shallowEqual)

  return (
    <FilterBox
      handleFilter={handleFilter}
      valueKey="id"
      labelKey="name"
      options={employees}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('employees')}
    />
  )
}

export default TimesheetEmployee
