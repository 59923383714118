import { Dispatch, FC, Fragment, SetStateAction, useState } from 'react'
import { Button, Typography, Flex } from '@jisr-hr/ds'
import { AvatarLabel, Divider, Dropdown, ListItem } from '@jisr-hr/ds-beta'
import { useDispatch, useSelector } from 'utils/hooks'
import { isEmpty } from 'lodash'
import HelpCenter from 'components/NewNavigation/HelpCenter'
import OnboardingInviteEmployees from 'components/NewNavigation/OnboardingInviteEmployees'
import { useHistory, useLocation } from 'react-router-dom'
import { mixPanelAnalytics } from 'utils/mixpanel'
import i18n from 'translations/i18n'
import { isRouteMatch, isRtl } from 'utils/uiHelpers'
import {
  currentEmployeeChangeLanguage,
  onboardingChangeTourStep,
} from 'redux/authUser/actionCreators'
import { toggleNotificationsPanel } from 'redux/appNotifications/actionCreators'
import OnboardingTour from 'containers/authorised/NewOnboarding/components/OnboardingTour'
import OrganizationSteps from 'containers/authorised/NewOnboarding/components/OrganizationSteps'
import { OnboardingTier, OnboardingSteps } from 'containers/authorised/NewOnboarding/helper'
import Announcement from 'components/global/templates/Announcement'
import useListItems from 'components/NewNavigation/useListItems'
import GetHelp from './GetHelp/GetHelp'
import styles from './styles.module.css'

type Props = {
  setOnboardingInviteModalOpen: Dispatch<SetStateAction<boolean>>
  onClickListItem: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, data: { value: string }) => void
}

const imageTourInviteAdmin = isRtl
  ? 'https://jisr-public.s3.me-south-1.amazonaws.com/images/Tour-Invite-Admin-AR.gif'
  : 'https://jisr-public.s3.me-south-1.amazonaws.com/images/Tour-Invite-Admin-EN+.gif'

const OnboardingHeader: FC<Props> = ({ setOnboardingInviteModalOpen, onClickListItem }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const employee = useSelector((s) => s.auth.employee)
  const stepTour = useSelector((s) => s.auth.step_tour)

  const pageGroups = useSelector((s) => s.onboardingSteps.page_groups)
  const isUnread = useSelector(({ markAsRead }) => markAsRead.unread)
  const onboardingSteps = useSelector((s) => s.onboardingHeaderSteps)
  const { is_employees_invited: isEmployeeInvited } = useSelector(
    (s) => s.invitationsTracking.invitationsStatistics,
  )

  const canPostAnnouncement = employee?.permission_scopes.post_announcements
  const structureStep = pageGroups.find(
    (page) => page.name === OnboardingSteps.ORGANIZATION_STRUCTURE,
  )

  const isHighTier = employee?.organization?.onboarding_flow === OnboardingTier.HIGH_TIER
  const isHighTierStructureIncomplete =
    isHighTier && !structureStep?.is_completed && !isRouteMatch('welcomePage')
  const listItems = useListItems({
    showChangeLanguage: false,
    showViewProfile: employee?.organization.onboarding_info?.onboarding_steps_completed,
  })

  return (
    <div className="w-full">
      <Flex
        itemsCenter
        justifyBetween
      >
        <Typography
          variant="title-1"
          text={onboardingSteps.title}
        />
        <Flex
          itemsCenter
          className="gap-[8px]"
        >
          {!onboardingSteps.title &&
            (isRouteMatch('org_info') ||
              (!isEmpty(structureStep) && isHighTierStructureIncomplete)) && (
              <OnboardingTour
                open={stepTour === 2}
                position={isRtl ? 'bottom-end' : 'bottom-start'}
                text={i18n.t(
                  'description.invite_more_admins_to_help_you_with_completing_org_name_workspace_data',
                  {
                    company_name: employee?.organization?.slug,
                  },
                )}
                trigger={
                  <Button
                    size="small"
                    color="neutral"
                    variant="outlined"
                    leadingIcon="user-plus-02"
                    label={i18n.t('label.invite_admins')}
                    onClick={(): void => {
                      setOnboardingInviteModalOpen(true)
                      mixPanelAnalytics({
                        event_name: 'Onboarding Admin Invite Started',
                        properties: {
                          page_name: pathname,
                          language: i18n.language,
                          onboarding_flow: employee?.organization.onboarding_flow,
                        },
                      })
                    }}
                  />
                }
                content={
                  <img
                    alt="onboarding-tour"
                    src={imageTourInviteAdmin}
                    style={{
                      width: 296,
                      height: 180,
                      borderRadius: 8,
                    }}
                  />
                }
                footer={
                  <Flex
                    justifyBetween
                    itemsCenter
                  >
                    <Typography
                      variant="caption"
                      textColor="color/fg/inverse-light"
                      text={i18n.t('tour_step', { step: 1, steps: 2 })}
                    />
                    <Button
                      size="small"
                      type="button"
                      color="neutral"
                      variant="outlined"
                      label={i18n.t('next')}
                      onClick={(): void => {
                        dispatch(onboardingChangeTourStep({ step_tour: 3 }))
                      }}
                    />
                  </Flex>
                }
              />
            )}
          {employee?.organization.status === 'live' && (
            <>
              {canPostAnnouncement && <Announcement />}
              <Button
                size="medium"
                variant="ghost"
                color="neutral"
                leadingIcon="bell-02"
                style={{ position: 'relative' }}
                onClick={(): void => {
                  dispatch(toggleNotificationsPanel(true))
                }}
              >
                {isUnread && (
                  <span
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: 'var(--ds-core-color-error-500)',
                      position: 'absolute',
                      top: 4,
                      left: 4,
                      insetInlineEnd: 15,
                    }}
                  />
                )}
              </Button>
            </>
          )}

          {isRouteMatch('modules') && <OnboardingInviteEmployees />}

          <>
            {isEmployeeInvited ? (
              <HelpCenter
                target={
                  <Button
                    className={onboardingSteps.title ? styles.screenSize : undefined}
                    size="small"
                    variant="ghost"
                    trailingIcon="help-circle"
                    label={i18n.t('label.get_help')}
                  />
                }
              />
            ) : (
              <Button
                className={onboardingSteps.title ? styles.screenSize : undefined}
                size="small"
                variant="ghost"
                trailingIcon="help-circle"
                label={i18n.t('label.get_help')}
                onClick={(): void => {
                  setIsOpenDrawer(true)
                  mixPanelAnalytics({
                    event_name: 'Onboarding Get Help Clicked',
                    properties: {
                      page_name: pathname,
                      language: i18n.language,
                      role: employee?.is_first_admin ? 'First Admin' : 'Admin',
                    },
                  })
                }}
              />
            )}
          </>

          <GetHelp
            isOpenDrawer={isOpenDrawer}
            onClose={(): void => {
              setIsOpenDrawer(false)
              mixPanelAnalytics({
                event_name: 'Onboarding Get Help Closed',
                properties: {
                  page_name: pathname,
                  language: i18n.language,
                  role: employee?.is_first_admin ? 'First Admin' : 'Admin',
                },
              })
            }}
          />
          <Button
            size="medium"
            variant="ghost"
            label={isRtl ? 'English' : 'العربية'}
            trailingIcon="globe-03"
            onClick={(): void => {
              dispatch(
                currentEmployeeChangeLanguage(employee?.id as number, {
                  value: i18n.language === 'ar' ? 'en' : 'ar',
                }),
              )
            }}
          />
          <Dropdown
            onClickListItem={(event, item: { value: string }) => {
              const mouseEvent = event as unknown as React.MouseEvent<HTMLLIElement, MouseEvent>
              onClickListItem(mouseEvent, { value: item?.value })
            }}
            position="bottom"
            dropDownElement={
              <AvatarLabel
                showDropdown
                type="default"
                className="cursor-pointer"
                avatarLogo={employee?.avatar_thumb}
                headerText={employee?.full_name_i18n}
                supportingText={employee?.email ?? ''}
                avatarProps={{
                  size: 'm',
                  avatarStatusType: 'company',
                }}
              />
            }
          >
            {listItems.map((item) => (
              <Fragment key={item.value}>
                <ListItem
                  text={item.text}
                  value={item.value}
                  leadingIcon={item.leadingIcon}
                />
                {item.nextDivider && <Divider />}
              </Fragment>
            ))}
          </Dropdown>
        </Flex>
      </Flex>
      {!!onboardingSteps.list.length && (
        <OrganizationSteps
          list={onboardingSteps.list}
          inProgress={pathname.split('/')[4]}
          onClick={(v): void => history.push(`${onboardingSteps.path}/${v.key}`)}
        />
      )}
    </div>
  )
}

export default OnboardingHeader
