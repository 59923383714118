export const ORGANIZATION_BANKS_LOAD = 'ORGANIZATION_BANKS_LOAD'
export const ORGANIZATION_BANKS_LOAD_PENDING = 'ORGANIZATION_BANKS_LOAD_PENDING'
export const ORGANIZATION_BANKS_LOAD_FULFILLED = 'ORGANIZATION_BANKS_LOAD_FULFILLED'
export const ORGANIZATION_BANKS_LOAD_REJECTED = 'ORGANIZATION_BANKS_LOAD_REJECTED'

export const ORGANIZATION_BANKS_CREATE = 'ORGANIZATION_BANKS_CREATE'
export const ORGANIZATION_BANKS_CREATE_PENDING = 'ORGANIZATION_BANKS_CREATE_PENDING'
export const ORGANIZATION_BANKS_CREATE_FULFILLED = 'ORGANIZATION_BANKS_CREATE_FULFILLED'
export const ORGANIZATION_BANKS_CREATE_REJECTED = 'ORGANIZATION_BANKS_CREATE_REJECTED'

export const ORGANIZATION_BANKS_UPDATE = 'ORGANIZATION_BANKS_UPDATE'
export const ORGANIZATION_BANKS_UPDATE_PENDING = 'ORGANIZATION_BANKS_UPDATE_PENDING'
export const ORGANIZATION_BANKS_UPDATE_FULFILLED = 'ORGANIZATION_BANKS_UPDATE_FULFILLED'
export const ORGANIZATION_BANKS_UPDATE_REJECTED = 'ORGANIZATION_BANKS_UPDATE_REJECTED'

export const ORGANIZATION_BANKS_DELETE = 'ORGANIZATION_BANKS_DELETE'
export const ORGANIZATION_BANKS_DELETE_PENDING = 'ORGANIZATION_BANKS_DELETE_PENDING'
export const ORGANIZATION_BANKS_DELETE_FULFILLED = 'ORGANIZATION_BANKS_DELETE_FULFILLED'
export const ORGANIZATION_BANKS_DELETE_REJECTED = 'ORGANIZATION_BANKS_DELETE_REJECTED'

export const BANK_IBAN_CHECKING_LOAD = 'BANK_IBAN_CHECKING_LOAD'
export const BANK_IBAN_CHECKING_LOAD_PENDING = 'BANK_IBAN_CHECKING_LOAD_PENDING'
export const BANK_IBAN_CHECKING_LOAD_FULFILLED = 'BANK_IBAN_CHECKING_LOAD_FULFILLED'
export const BANK_IBAN_CHECKING_LOAD_REJECTED = 'BANK_IBAN_CHECKING_LOAD_REJECTED'

export const DEFAULT_BANK_ACCOUNTS_LOAD = 'DEFAULT_BANK_ACCOUNTS_LOAD'
export const DEFAULT_BANK_ACCOUNTS_LOAD_PENDING = 'DEFAULT_BANK_ACCOUNTS_LOAD_PENDING'
export const DEFAULT_BANK_ACCOUNTS_LOAD_FULFILLED = 'DEFAULT_BANK_ACCOUNTS_LOAD_FULFILLED'
export const DEFAULT_BANK_ACCOUNTS_LOAD_REJECTED = 'DEFAULT_BANK_ACCOUNTS_LOAD_REJECTED'

export const ORGANIZATION_BANKS_REMOVE_FROM_LIST = 'ORGANIZATION_BANKS_REMOVE_FROM_LIST'
export const ORGANIZATION_BANKS_ADD_TO_LIST = 'ORGANIZATION_BANKS_ADD_TO_LIST'
