import { Spacer } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import LoanPolicy from './components/LoanPolicy'
import LoanTypes from './components/LoanTypes'

const Loans = ({ goNextStep, goPreviousStep, nextStep, previousStep }: StepsTypes): JSX.Element => {
  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'loans' })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <LoanPolicy />
      <Spacer height={16} />
      <LoanTypes />
    </StepTemplate>
  )
}

export default Loans
