import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  qualifications: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function qualificationReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let qualifications = []
  let newRecord = {}
  switch (action.type) {
    case 'QUALIFICATIONS_LOAD_PENDING':
    case 'QUALIFICATION_CREATE_PENDING':
    case 'QUALIFICATION_UPDATE_PENDING':
    case 'QUALIFICATION_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'QUALIFICATIONS_LOAD_REJECTED':
    case 'QUALIFICATION_CREATE_REJECTED':
    case 'QUALIFICATION_UPDATE_REJECTED':
    case 'QUALIFICATION_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'QUALIFICATIONS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'QUALIFICATION_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      qualifications = [...state.qualifications]
      newRecord = payload.data.data.qualification
      changes = {
        qualifications: [...qualifications, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'QUALIFICATION_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        qualifications: updateOneListOfRecords(
          state.qualifications,
          payload.data.data.qualification,
        ),
        editing: {
          ...state.editing,
          [payload.data.data.qualification.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'QUALIFICATION_DELETE_FULFILLED':
      return {
        ...state,
        qualifications: removeOneListOfRecords(state.qualifications, payload.id),
        error: null,
        fetching: false,
      }

    case 'QUALIFICATION_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'QUALIFICATION_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
