export const RESET = 'RESET'

export const RESIGNATION_REQUEST_WORKFLOWS_LOAD = 'RESIGNATION_REQUEST_WORKFLOWS_LOAD'
export const RESIGNATION_REQUEST_WORKFLOWS_LOAD_PENDING =
  'RESIGNATION_REQUEST_WORKFLOWS_LOAD_PENDING'
export const RESIGNATION_REQUEST_WORKFLOWS_LOAD_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOWS_LOAD_FULFILLED'
export const RESIGNATION_REQUEST_WORKFLOWS_LOAD_REJECTED =
  'RESIGNATION_REQUEST_WORKFLOWS_LOAD_REJECTED'

export const RESIGNATION_REQUEST_WORKFLOW_LOAD = 'RESIGNATION_REQUEST_WORKFLOW_LOAD'
export const RESIGNATION_REQUEST_WORKFLOW_LOAD_PENDING = 'RESIGNATION_REQUEST_WORKFLOW_LOAD_PENDING'
export const RESIGNATION_REQUEST_WORKFLOW_LOAD_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOW_LOAD_FULFILLED'
export const RESIGNATION_REQUEST_WORKFLOW_LOAD_REJECTED =
  'RESIGNATION_REQUEST_WORKFLOW_LOAD_REJECTED'

export const RESIGNATION_REQUEST_WORKFLOW_CREATE = 'RESIGNATION_REQUEST_WORKFLOW_CREATE'
export const RESIGNATION_REQUEST_WORKFLOW_CREATE_PENDING =
  'RESIGNATION_REQUEST_WORKFLOW_CREATE_PENDING'
export const RESIGNATION_REQUEST_WORKFLOW_CREATE_REJECTED =
  'RESIGNATION_REQUEST_WORKFLOW_CREATE_REJECTED'
export const RESIGNATION_REQUEST_WORKFLOW_CREATE_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOW_CREATE_FULFILLED'

export const RESIGNATION_REQUEST_WORKFLOW_UPDATE = 'RESIGNATION_REQUEST_WORKFLOW_UPDATE'
export const RESIGNATION_REQUEST_WORKFLOW_UPDATE_PENDING =
  'RESIGNATION_REQUEST_WORKFLOW_UPDATE_PENDING'
export const RESIGNATION_REQUEST_WORKFLOW_UPDATE_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOW_UPDATE_FULFILLED'
export const RESIGNATION_REQUEST_WORKFLOW_UPDATE_REJECTED =
  'RESIGNATION_REQUEST_WORKFLOW_UPDATE_REJECTED'

export const RESIGNATION_REQUEST_WORKFLOW_DELETE = 'RESIGNATION_REQUEST_WORKFLOW_DELETE'
export const RESIGNATION_REQUEST_WORKFLOW_DELETE_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOW_DELETE_FULFILLED'
export const RESIGNATION_REQUEST_WORKFLOW_DELETE_REJECTED =
  'RESIGNATION_REQUEST_WORKFLOW_DELETE_REJECTED'

export const RESIGNATION_REQUEST_WORKFLOW_UNASSIGNED_LOAD =
  'RESIGNATION_REQUEST_WORKFLOW_UNASSIGNED_LOAD'
export const RESIGNATION_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED =
  'RESIGNATION_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED'

export const RESIGNATION_REQUEST_WORKFLOW_EDIT = 'RESIGNATION_REQUEST_WORKFLOW_EDIT'
export const RESIGNATION_REQUEST_WORKFLOW_EDIT_CANCEL = 'RESIGNATION_REQUEST_WORKFLOW_EDIT_CANCEL'
