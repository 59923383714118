import { Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import CutOffDayImg from 'assets/images/cut-off-day.svg'
import Sidebar from '../components/Sidebar'
import PaySchedules from './steps/PaySchedules'
import Allowances from './steps/Allowances'
import SalariesPackages from './steps/SalariesPackages'
import ReviewEmployeesData from './steps/ReviewEmployeesData'
import ReviewEmployeesPayment from './steps/ReviewEmployeesPayment'
import PayrollBank from './steps/PayrollBank'
import PaymentMethods from './steps/PaymentMethods'
import VacationSettlement from './steps/VacationSettlement'
import SummaryReview from './steps/SummaryReview'
import Done from './steps/Done'
import Loans from './steps/Loans'

export const payrollSteps = [
  {
    stepNo: 1,
    sectionTitle: I18n.t('payroll_setup'),
    title: 'pay_schedules',
    component: PaySchedules,
    sidebar: (
      <Sidebar
        videoKey="pay_schedules"
        infoSet={[
          {
            imgSrc: CutOffDayImg,
            title: I18n.t('cutoff_day'),
            description: I18n.t('cutoff_day_desc'),
            custom: (
              <Flex
                flexCol
                style={{ gap: 16 }}
              >
                {[
                  {
                    color: 'var(--color-secondary-bittersweet-light-new)',
                    text: I18n.t('cutoff_date'),
                  },
                  {
                    color: 'var(--color-secondary-mantis-lighter-new)',
                    text: I18n.t('pay_day'),
                  },
                ].map(({ color, text }, i) => (
                  <Flex
                    key={i}
                    itemsCenter
                    style={{ gap: 16 }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: '50%',
                        backgroundColor: color,
                      }}
                    />
                    <Typography
                      variant="subtitle-2"
                      text={text}
                    />
                  </Flex>
                ))}
              </Flex>
            ),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    sectionTitle: I18n.t('salary_setup'),
    title: 'allowances',
    component: Allowances,
    sidebar: (
      <Sidebar
        videoKey="allowances"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'salaries_packages',
    component: SalariesPackages,
    sidebar: (
      <Sidebar
        videoKey="salaries_packages"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 3,
    title: 'review_employees_data',
    component: ReviewEmployeesData,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 4,
    title: 'review_employees_payment',
    component: ReviewEmployeesPayment,
    sidebar: (
      <Sidebar
        videoKey="review_employees_payment"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 5,
    sectionTitle: I18n.t('payables'),
    title: 'loans',
    component: Loans,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 6,
    title: 'vacation_settlement',
    component: VacationSettlement,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 7,
    sectionTitle: I18n.t('payroll_payment_configuration'),
    title: 'payroll_bank',
    component: PayrollBank,
    sidebar: (
      <Sidebar
        videoKey="payroll_bank"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 8,
    title: 'payment_methods',
    component: PaymentMethods,
    sidebar: (
      <Sidebar
        videoKey="payment_methods"
        infoSet={[]}
      />
    ),
  },
  {
    stepNo: 9,
    sectionTitle: I18n.t('summary_review'),
    title: 'review',
    component: SummaryReview,
    sidebar: <Sidebar infoSet={[]} />,
  },
  {
    stepNo: 10,
    title: 'done_finish',
    component: Done,
    sidebar: <Sidebar infoSet={[]} />,
  },
]
