import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { EmployeeDetailsState, RestrictionConfigurationT } from './employeeDetailsTypes'

const initialState: EmployeeDetailsState = {
  fetching: false,
  requestFetching: false,
  fetchingEmployeeConfiguration: false,
  records: [],
  attendance_record: [],
  stats: {},
  employee: {},
  restrictionConfiguration: null,
}

export default function reducer(state = initialState, action: Action): EmployeeDetailsState {
  switch (action.type) {
    case actions.RESET_ATTENDANCE_RECORD:
      return {
        ...state,
        attendance_record: [],
      }

    case actions.RESET_EMPLOYEE_INFORMATION:
      return {
        ...state,
      }

    case actions.LOAD_EMPLOYEE_ATTENDANCE_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LOAD_EMPLOYEE_REQUESTS_DETAILS_PENDING:
      return {
        ...state,
        requestFetching: true,
      }

    case actions.GET_EMPLOYEE_RESTRICTION_CONFIGURATION_PENDING:
      return {
        ...state,
        fetchingEmployeeConfiguration: true,
      }

    case actions.LOAD_EMPLOYEE_ATTENDANCE_DETAILS_FULFILLED:
      return {
        ...state,
        attendance_record: action.payload.data.data,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LOAD_EMPLOYEE_REQUESTS_DETAILS_FULFILLED:
      return {
        ...state,
        attendance_record: action.payload.data.data,
        ...action.payload.data.data,
        requestFetching: false,
      }

    case actions.GET_EMPLOYEE_RESTRICTION_CONFIGURATION_FULFILLED:
      const restrictionConfiguration: RestrictionConfigurationT =
        action.payload.data.data.employee_configuration

      return {
        ...state,
        fetchingEmployeeConfiguration: false,
        restrictionConfiguration,
      }

    case actions.GET_EMPLOYEE_RESTRICTION_CONFIGURATION_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingEmployeeConfiguration: false,
      }

    case actions.LOAD_EMPLOYEE_ATTENDANCE_DETAILS_REJECTED:
    case actions.LOAD_EMPLOYEE_REQUESTS_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        requestFetching: false,
      }

    default:
      return state
  }
}
