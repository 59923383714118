import { FC } from 'react'
import { Department } from 'redux/newDepartments/types'
import ContentDeleteModal from './ContentDeleteModal'

type Props = {
  data: Department
}

const MoveDepartmentConfirm: FC<Props> = ({ data }) => {
  return (
    <ContentDeleteModal
      data={data}
      title="move_the_adminstration_department"
    />
  )
}

export default MoveDepartmentConfirm
