import { FC } from 'react'
import { pad, TimeToNumbersReturn } from './functions'

import { TimeT } from './TimePicker'
import Styles from './TimePicker.module.css'

const hours = Array.from({ length: 13 }, (_, k) => k)
const h24 = Array.from({ length: 24 }, (_, k) => k)
const mints = Array.from({ length: 60 }, (_, k) => k)

type TimeTableProps = {
  time: TimeT
  withResetTime?: boolean
  maxTime: TimeToNumbersReturn | null
  minTime: TimeToNumbersReturn | null
  is24Hour?: boolean
  noLimit?: boolean
  onChange: <Key extends keyof TimeT>(key: Key, val: TimeT[Key]) => void
  handleResetTime: () => void
}

const TimeTable: FC<TimeTableProps> = (props) => {
  const { time, withResetTime, maxTime, minTime, is24Hour, noLimit } = props
  const hoursArr = is24Hour ? h24 : hours

  return (
    <div className={Styles.timeTable}>
      <div>
        {(withResetTime ? hoursArr : hoursArr.slice(1)).map((hour, i) => {
          let hour24 = time?.ampm === 'pm' ? hour + 12 : hour
          if (time?.ampm === 'am' && hour === 12) {
            hour24 = 0
          } else if (hour === 12) {
            hour24 = 12
          }
          const disabled =
            time.hour === hour ||
            (maxTime?.hour24 ?? NaN) < hour24 ||
            (minTime?.hour24 ?? NaN) > hour24

          return (
            <button
              key={`hour-${hour}`}
              disabled={disabled}
              className={time.hour === hour ? Styles.active : ''}
              onClick={(): void =>
                i === 0 && withResetTime ? props.handleResetTime() : props.onChange('hour', hour)
              }
            >
              {pad(hour, noLimit)}
            </button>
          )
        })}
      </div>
      <div>
        {mints.map((mint) => {
          const hour24 = time?.ampm === 'pm' ? (time?.hour ?? 0) + 12 : time.hour
          const disabled =
            time.minutes === mint ||
            (maxTime?.hour24 === hour24 && maxTime?.minutes < mint) ||
            (minTime?.hour24 === hour24 && minTime?.minutes > mint)

          return (
            <button
              key={`mint-${mint}`}
              disabled={disabled}
              className={time.minutes === mint ? Styles.active : ''}
              onClick={(): void => props.onChange('minutes', mint)}
            >
              {pad(mint)}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default TimeTable
