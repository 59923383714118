export const LOAD_EMPLOYEE_ATTENDANCE_DETAILS = 'LOAD_EMPLOYEE_ATTENDANCE_DETAILS'
export const LOAD_EMPLOYEE_ATTENDANCE_DETAILS_PENDING = 'LOAD_EMPLOYEE_ATTENDANCE_DETAILS_PENDING'
export const LOAD_EMPLOYEE_ATTENDANCE_DETAILS_FULFILLED =
  'LOAD_EMPLOYEE_ATTENDANCE_DETAILS_FULFILLED'
export const LOAD_EMPLOYEE_ATTENDANCE_DETAILS_REJECTED = 'LOAD_EMPLOYEE_ATTENDANCE_DETAILS_REJECTED'

export const LOAD_EMPLOYEE_REQUESTS_DETAILS = 'LOAD_EMPLOYEE_REQUESTS_DETAILS'
export const LOAD_EMPLOYEE_REQUESTS_DETAILS_PENDING = 'LOAD_EMPLOYEE_REQUESTS_DETAILS_PENDING'
export const LOAD_EMPLOYEE_REQUESTS_DETAILS_FULFILLED = 'LOAD_EMPLOYEE_REQUESTS_DETAILS_FULFILLED'
export const LOAD_EMPLOYEE_REQUESTS_DETAILS_REJECTED = 'LOAD_EMPLOYEE_REQUESTS_DETAILS_REJECTED'

export const GET_EMPLOYEE_RESTRICTION_CONFIGURATION = 'GET_EMPLOYEE_RESTRICTION_CONFIGURATION'
export const GET_EMPLOYEE_RESTRICTION_CONFIGURATION_PENDING =
  'GET_EMPLOYEE_RESTRICTION_CONFIGURATION_PENDING'
export const GET_EMPLOYEE_RESTRICTION_CONFIGURATION_FULFILLED =
  'GET_EMPLOYEE_RESTRICTION_CONFIGURATION_FULFILLED'
export const GET_EMPLOYEE_RESTRICTION_CONFIGURATION_REJECTED =
  'GET_EMPLOYEE_RESTRICTION_CONFIGURATION_REJECTED'

export const RESET_EMPLOYEE_INFORMATION = 'RESET_EMPLOYEE_INFORMATION'

export const RESET_ATTENDANCE_RECORD = 'RESET_ATTENDANCE_RECORD'
