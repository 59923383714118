import { useState } from 'react'
import { Button } from '@jisr-hr/ds'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Toastr } from 'components/global/Toastr'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useRequest } from '../../../RequestProvider'

type Props = {
  sequentialId: number
}

const CopyRequestUrl = ({ sequentialId }: Props): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false)
  const { requestType } = useRequest()
  const { request } = useSelector(({ requestDetails }) => requestDetails)

  const handleCopyLink = async (): Promise<void> => {
    const link = `${window.location.origin}/#/?employee_id=${request?.employee?.id}&request_type=${requestType}&sequential_id=${sequentialId}`
    try {
      await navigator.clipboard.writeText(link)
      setIsCopied(true)

      const timeout = setTimeout(() => {
        setIsCopied(false)
        clearTimeout(timeout)
      }, 5000)
    } catch {
      Toastr.error(I18n.t('something_went_wrong_please_try_again'))
      setIsCopied(false)
    }
  }

  return (
    <Tooltip
      description={isCopied ? I18n.t('request_link_copied') : I18n.t('copy_request_link')}
      placement="top"
      trigger={
        <div>
          <Button
            size="small"
            variant="ghost"
            trailingIcon={isCopied ? 'check' : 'link-01'}
            onClick={handleCopyLink}
            label={sequentialId.toString()}
          />
        </div>
      }
    />
  )
}

export default CopyRequestUrl
