import React from 'react'
import PropTypes from 'prop-types'
import { Banner } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { minuteToHumanReadable, replaceAmPm } from 'components/global/form/formNormalize'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import InfoList from 'components/global/templates/RequestDrawer/ds/InfoList'
import { getExcuseHintMessages } from './helper'

import { ReactComponent as ClockTimerIcon } from './icons/clock-timer.svg'

const RecordedClock = ({ type, clock, duration, isShiftGotEnded }) => {
  const { sectionTitle, ...bannerData } = getExcuseHintMessages(type, !!clock)

  const items = [
    {
      label: type === 'late_in' ? I18n.t('clock_in_time') : I18n.t('clock_out_time'),
      icon: <ClockTimerIcon />,
      value: replaceAmPm(clock) || '--',
      color: 'var(--color-base-colors-black-500)',
    },
    {
      label: type === 'late_in' ? I18n.t('delay_duration') : I18n.t('shortage_duration'),
      icon: <ClockTimerIcon />,
      value: minuteToHumanReadable(duration) || '--',
      color:
        duration > 0 ? 'var(--color-base-colors-red-600)' : 'var(--color-base-colors-black-500)',
    },
  ]

  return (
    <Frame title={sectionTitle}>
      <div className="flex flex-col gap-4">
        <InfoList items={items} />

        {(isShiftGotEnded || clock) && !duration && <Banner {...bannerData} />}
      </div>
    </Frame>
  )
}

RecordedClock.propTypes = {
  type: PropTypes.string,
  clock: PropTypes.string,
  duration: PropTypes.string,
  isShiftGotEnded: PropTypes.bool,
}

export default RecordedClock
