import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import {
  GetProviderDetailsResponse,
  GetProvidersListingResponseType,
} from 'containers/authorised/BenefitsManagement/Insurance/Providers/types'
import * as actions from './actions'

type StateType = {
  fetching: boolean
  providersListing: GetProvidersListingResponseType[]
  providerDetails: GetProviderDetailsResponse
}

export const initialState: StateType = {
  fetching: false,
  providersListing: [],
  providerDetails: {} as GetProviderDetailsResponse,
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.GET_PROVIDERS_LISTING_PENDING:
    case actions.GET_PROVIDER_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_PROVIDERS_LISTING_FULFILLED:
      return {
        ...state,
        fetching: false,
        providersListing: payload?.data?.data?.providers,
      }

    case actions.GET_PROVIDER_DETAILS_FULFILLED:
      return {
        ...state,
        fetching: false,
        providerDetails: payload?.data?.data?.provider,
      }

    case actions.GET_PROVIDERS_LISTING_REJECTED:
    case actions.GET_PROVIDER_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
