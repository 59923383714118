import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@jisr-hr/ds'
import _ from 'lodash'
import I18n from 'translations/i18n'

const RenderCheckbox = ({ data, filterKey, handleFilter, selectedIds }) => {
  const checked = _.includes(selectedIds, data.id)

  return (
    <Checkbox
      label={data.name_i18n || I18n.t(data.name)}
      active={checked}
      onChange={() => handleFilter(filterKey, data.id)}
      testId={data.name}
    />
  )
}

RenderCheckbox.propTypes = {
  handleFilter: PropTypes.func,
  filterKey: PropTypes.string,
  data: PropTypes.shape(),
  selectedIds: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ),
}

export default RenderCheckbox
