import { PageLayout, HeaderNavigation } from '@jisr-hr/ds'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'utils/hooks'
import { currentEmployeeChangeLanguage, logout } from 'redux/authUser/actionCreators'
import I18n from 'translations/i18n'
import { getTokenFromDB } from 'firebaseUtils'
import { unRegistrationToken, unsubscribeFirebase } from 'redux/actions/firebaseActions'
import useListItems from 'components/NewNavigation/useListItems'
import ErrorBoundary from 'components/ErrorBoundary'
import styles from './styles.module.css'

type NewNavigationProps = {
  children: React.ReactNode
}

const OnboardingEmpNavigation = ({ children }: NewNavigationProps): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { employee } = useSelector(({ auth }) => auth)

  const listItems = useListItems()

  const handleLogout = async (): Promise<void> => {
    const unsubscribe = new Promise(() =>
      getTokenFromDB({ clear: true }).then((token) => {
        if (token) return dispatch(unsubscribeFirebase({ token }))
        return null
      }),
    )

    const Unregister = await getTokenFromDB().then((token) => {
      if (token) return dispatch(unRegistrationToken(token, 'firebase'))
      return null
    })

    const logoutSession = new Promise(() => dispatch(logout()))
    Promise.all([unsubscribe, Unregister, logoutSession]).finally(() => history.push('/'))
  }

  const handleHeaderNavigation = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    data: { value: string },
  ): void => {
    const actions = {
      language: (): void => {
        dispatch(
          currentEmployeeChangeLanguage(employee?.id as number, {
            value: I18n.language === 'ar' ? 'en' : 'ar',
          }),
        )
      },
      logout: () => handleLogout(),
    }
    const actionHandler = actions[data.value]
    if (actionHandler) {
      actionHandler()
    }
  }

  return (
    <ErrorBoundary>
      <PageLayout
        sidePanel={null}
        className={styles.sidePanelStyle}
        navigationHeader={
          <HeaderNavigation
            showToggelButton={false}
            onClickListItem={handleHeaderNavigation}
            dropdownListItems={listItems}
            employee={{
              name: employee?.full_name_i18n ?? '',
              avatar: employee?.avatar_thumb ?? '',
              email: employee?.email ?? '',
            }}
          />
        }
        body={<>{children}</>}
      />
    </ErrorBoundary>
  )
}

export default OnboardingEmpNavigation
