import { useState, useEffect, Fragment } from 'react'
import { TPagination } from 'redux/organizationOnborading/employees/employees.type'
import { SearchBar, Table } from '@jisr-hr/design-system'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import {
  onBoardingPaymentDetailsLoad,
  onBoardingBanksLoad,
} from 'redux/payrollOnboarding/paymentReview/actionsCreators'
import { useDispatch } from 'utils/hooks'
import { StepsTypes, TParams } from 'containers/public/Onboarding/types/onboardingTypes'

import { usePaymentListSelector } from 'redux/payrollOnboarding/paymentReview/selectors'
import { TPaymentDetails } from 'redux/payrollOnboarding/paymentReview/type'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import BulkUpdate from '../../componetns/BulkUpdate'
import FormTable from './Form'
import styles from '../styles.module.css'

type TExtendsParams = Partial<TParams>

type TStatistics = {
  completed_info_count: number
  incompleted_info_count: number
  cheque: number
  international_transfer: number
}

type TPaymentDetailsAPI = {
  payment_details: TPaymentDetails[]
  pagination: TPagination
  statistics: TStatistics
  fetching: boolean
  loading: boolean
}

const headers = [
  { key: 'emp_id', label: I18n.t('employee_onboarding'), width: '290px' },
  { key: 'employment_type', label: I18n.t('employment_type') },
  { key: 'payment_type', label: I18n.t('payment_type') },
  { key: 'Salary Type', label: I18n.t('salary_type') },
  { key: 'Bank Name', label: I18n.t('bank_name') },
  { key: 'IBAN', label: I18n.t('iban_onboarding') },
  { key: 'Salary Card No', label: I18n.t('salary_card_no') },
  { key: 'swift code', label: I18n.t('swift_code') },
  { key: 'Country', label: I18n.t('country') },
]

const ReviewEmployeesPayment = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const { payment_details, pagination, fetching } = usePaymentListSelector() as TPaymentDetailsAPI
  const [error, setError] = useState(false)
  const [params, setParams] = useState<TExtendsParams>({
    search: '',
    rpp: 10,
    page: 1,
  })

  const [edit, setEdit] = useState<TPaymentDetails | null>(null)

  const handleFilters = (values: TExtendsParams): void => {
    const newParams = {
      ...params,
      ...values,
    }
    setParams(newParams)
    dispatch(onBoardingPaymentDetailsLoad(newParams)).catch(() => {
      setError(true)
    })
  }

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'review_employees_payment' })

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(false)
    }, 10000)

    return () => clearTimeout(timer)
  }, [error])

  useEffect(() => {
    dispatch(onBoardingPaymentDetailsLoad(params))
    dispatch(onBoardingBanksLoad())
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      fetching={fetching}
    >
      <Typography
        variant="heading"
        text={I18n.t('review_employees_payment')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('review_employees_payment_desc')}
      />

      <Spacer height={64} />

      <Flex
        justifyBetween
        itemsCenter
      >
        <SearchBar
          onChange={(_: string, search: string): void => handleFilters({ page: 1, search })}
          placeholder={I18n.t('search_placeholder2')}
          style={{ width: 360 }}
          value={params.search || ''}
        />
        <BulkUpdate
          action={(): Promise<any> => dispatch(onBoardingPaymentDetailsLoad(params))}
          type="onboarding_payment_details"
        />
      </Flex>

      <Spacer height={16} />

      <Table
        hoverActions
        variant="rounded"
        headers={headers}
        loading={fetching || error}
        className={styles.reviewEmployeesTable}
        containerStyles={{ maxHeight: '43vh' }}
        onLoadMore={(): void => handleFilters({ page: +pagination.current_page + 1 })}
        hasNextPage={!fetching && pagination.next_page}
      >
        {payment_details?.map((item) => (
          <Fragment key={item.id}>
            {item.id === edit?.id ? (
              <FormTable
                item={item}
                handleClose={(): void => setEdit(null)}
              />
            ) : (
              <Table.Row>
                <Table.Cell>
                  <Flex flexCol>
                    <Typography
                      variant="subtitle-2"
                      text={item.name_i18n}
                    />
                    <Typography
                      variant="subtitle-2"
                      text={item.code}
                    />
                  </Flex>
                </Table.Cell>
                <Table.Cell>{item.employment_type || '-'}</Table.Cell>
                <Table.Cell>{item.payment_type_i18n}</Table.Cell>

                <Table.Cell>{item.salary_type_i18n || '-'}</Table.Cell>
                <Table.Cell>{item.bank_name || item.bank_name_i18n || '-'}</Table.Cell>
                <Table.Cell>{item.iban || '-'}</Table.Cell>
                <Table.Cell>{item.account_number || '-'}</Table.Cell>
                <Table.Cell>{item.swift_code || '-'}</Table.Cell>
                <Table.Cell>{item.country || '-'}</Table.Cell>
                <Table.Cell
                  width="75px"
                  actions
                  onClickEdit={(): void => setEdit(item)}
                />
              </Table.Row>
            )}
          </Fragment>
        ))}
      </Table>
    </StepTemplate>
  )
}

export default ReviewEmployeesPayment
