import React from 'react'
import { isEmpty } from 'lodash'
import { useForm, useFormState } from 'react-final-form'
import { DatePickerDSField, SelectField } from 'components/final-form'
import I18n from 'translations/i18n'
import { required } from 'components/global/form/FormValidations'
import { durationByMinutes, timeToNumbers } from 'components/global/form/formNormalize'
import { addMonths, subDays } from 'date-fns'
import { useSelector } from 'react-redux'

import IncreaseDecreaseField from 'components/global/templates/RequestDrawer/ds/IncreaseDecreaseField'
import { Flex } from '@jisr-hr/ds'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'

import { useEmployeeAttendanceShifts } from 'components/global/templates/RequestDrawer/hooks'
import Styles from '../OvertimeDuration.module.css'

const SingleDay = () => {
  const { vars } = useRequest()
  const { values } = useFormState()
  const { change } = useForm()
  const { shifts, attendance_record, loadShifts } = useEmployeeAttendanceShifts()
  const employee_id = useSelector(({ requestDetails }) => requestDetails.request?.employee?.id)
  const empId = vars.id ?? employee_id

  const shiftId = values?.overtime_logs_attributes[0]?.employee_shift_id

  const minDuration = (val) => {
    val = timeToNumbers(val).totalMinutes || 0
    if (val < 1) return I18n.t('must_be_or_greater_than', { days: '00:01' })

    return undefined
  }

  const handleOvertimeIntVal = () => {
    const { employee_shifts, actual_overtime } = attendance_record?.[0] || {}
    const shiftOvertime = employee_shifts?.find((shift) => shift?.id === shiftId)?.actual_overtime
    const actualOvertime = isEmpty(employee_shifts) ? actual_overtime : shiftOvertime
    const overtimeByMinutes =
      durationByMinutes(values.initialShiftDuration || actualOvertime) || '00:00'

    change('overtime_logs_attributes[0].duration', overtimeByMinutes)
  }

  React.useEffect(() => {
    if (shifts?.length === 1 || values.initialShiftId || vars?.shiftView) {
      change(
        'overtime_logs_attributes[0].employee_shift_id',
        values.initialShiftId || vars?.shiftId || shifts[0]?.id,
      )
    } else {
      change('overtime_logs_attributes[0].employee_shift_id', null)
    }
  }, [shifts])

  React.useEffect(() => {
    handleOvertimeIntVal()
  }, [attendance_record, shiftId])

  React.useEffect(() => {
    if (values?.overtime_logs_attributes?.[0]?.date && empId) {
      loadShifts(empId, values?.overtime_logs_attributes?.[0]?.date)
    }
  }, [empId])

  return (
    <Flex
      flexCol
      className={Styles.container}
    >
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-6">
          <DatePickerDSField
            label={I18n.t('date')}
            validate={required}
            name="overtime_logs_attributes[0].date"
            onChange={(d) => {
              change('initialShiftId', undefined)
              change('initialShiftDuration', undefined)
              loadShifts(empId, [d])
            }}
            textFieldProps={{
              disabled: vars?.shiftView,
            }}
            calenderProps={{
              minDate: subDays(new Date(), 59),
              maxDate: addMonths(new Date(), 3),
            }}
          />
        </div>
        <div className="col-span-6">
          <IncreaseDecreaseField
            label={I18n.t('overtime')}
            maxTime="24:00"
            name="overtime_logs_attributes[0].duration"
            validate={minDuration}
            testId="overtime-duration"
          />
        </div>
      </div>

      {values.overtime_logs_attributes?.[0]?.date && shifts?.length > 0 && (
        <SelectField
          name="overtime_logs_attributes[0].employee_shift_id"
          label={I18n.t('shift')}
          valueKey="id"
          labelKey="label"
          options={shifts}
          validate={required}
          disabled={shifts.length < 2 || vars?.shiftView}
          onChange={() => {
            change('initialShiftDuration', undefined)
          }}
        />
      )}
    </Flex>
  )
}

export default SingleDay
