import { DSThemeProvider } from '@jisr-hr/design-system'
import { QueryProvider } from 'hooks/useReactQuery'
import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'

import LoaderComponent from 'components/LoaderComponent'
import MuiThemeProvider from 'components/MuiThemeProvider'
import { isRtl } from 'utils/uiHelpers'

import '@jisr-hr/design-system/dist/main.css'
import '@jisr-hr/ds-beta/dist/style.css'
import '@jisr-hr/ds/dist/style.css'
import '@jisr-hr/ds/dist/themes/jisr-theme.css'

// External CSS Packages
import 'react-toastify/dist/ReactToastify.css'
import './translations/i18n'

// Component
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import { UsersnapProvider } from './components/UserSnap/UsersnapContext'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import '../public/bootstrap.css'
import './App.css'
import './root.css'

// redux store
import store from './redux/store'

if (window.Cypress) {
  window.store = store
}

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={<LoaderComponent />}>
      <Provider store={store}>
        <QueryProvider>
          <MuiThemeProvider>
            <DSThemeProvider theme={{ product: 'Jisr' }}>
              <UsersnapProvider>
                <App />
                <ToastContainer
                  rtl={isRtl}
                  position={isRtl ? 'top-left' : 'top-right'}
                  hideProgressBar
                />
              </UsersnapProvider>
            </DSThemeProvider>
          </MuiThemeProvider>
        </QueryProvider>
      </Provider>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root'),
)

serviceWorkerRegistration.register()
