import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Toastr } from 'components/global/Toastr'
import I18n from 'translations/i18n'
import { DatePickerDSField } from 'components/final-form'
import { Button, Flex } from '@jisr-hr/ds'
import { SideDrawer } from '@jisr-hr/ds-beta'
import { useReactToPrint } from 'react-to-print'
import { isNumber } from 'lodash'
import { getRequestTranslationKey } from 'utils/uiHelpers'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'utils/hooks'
import { returnForCorrection } from 'redux/requests/actionCreators'
import HiringModal from 'containers/authorised/ATS/organisms/HiringModal/HiringModal'

import Request from './Request'

import { useRequest } from './RequestProvider'
import { requestsWithCancellationFlow } from './helper'
import ResumptionActions from './Footer/ResumptionActions'
import AddComment from './Footer/AddComment'
import useTaskFooter from './Footer/useTaskFooter'
import useRequestFooter from './Footer/useRequestFooter'

const RequestDrawer = ({ needApproval, onClose, header, hideFooter, ...props }) => {
  const [printing, setPrinting] = React.useState(false)
  const [isCopied, setIsCopied] = React.useState(false)

  const componentToPrintRef = React.useRef(null)
  const onBeforeGetContentResolve = React.useRef(null)
  const { request_types } = useSelector(({ applicableRequests }) => applicableRequests)
  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  const is_super_admin = useSelector(({ auth }) => auth.employee?.is_super_admin) ?? false
  const { fetching_submit } = useSelector((state) => state.approvals)
  const { values } = useFormState()
  const dispatch = useDispatch()
  const {
    showRejectReason,
    openATSModal,
    handleTaskCancelBtn,
    handleTaskSubmitBtn,
    handelTaskLinkBtn,
    handelRejectionTask,
    handlPaymentDate,
    setShowRejectReason,
    setOpenATSModal,
  } = useTaskFooter()

  const {
    showResumptionActions,
    addRejectComment,
    addReturnComment,
    setAddRejectComment,
    setAddReturnComment,
    showExtendingModal,
    setShowExtendingModal,
    isOpen,
    setIsOpen,
    handelLinkBtn,
    handelCancelBtn,
    handelSubmitBtn,
    askCancelAuthorize,
    status,
  } = useRequestFooter()
  const {
    open,
    showFooter,
    drawerToggle,
    vars: { rejectComment, request: req } = {},
    vars,
    requestType,
    onSuperAdminCancel,
    canCancel,
    onCancel,
    editRequest,
    setShowPaymentDate,
    showPaymentDate,
    onReject,
  } = useRequest()

  const request = {
    ...req,
    ...req?.request,
    ...details,
  }

  const isApproved = details?.status?.toLowerCase() === 'approved'
  const isPending = details?.status?.toLowerCase() === 'pending'
  const pendingForCorrection = details?.status?.toLowerCase() === 'pending_for_correction'
  const showCancellationFlow = requestsWithCancellationFlow.includes(details?.request_type)

  const canAdminCancel =
    !showResumptionActions && is_super_admin && (isApproved || isPending || pendingForCorrection)
  const canEmployeeCancel = canCancel && !is_super_admin && (isPending || pendingForCorrection)
  const showDetailsActions = needApproval && !editRequest

  const currentType =
    requestType === 'Request' || isNumber(requestType)
      ? request_types?.find(
          (t) => t.id === (isNumber(requestType) ? requestType : vars?.request_type_id),
        )
      : requestType

  const handleCancelRequest = () => {
    if (showCancellationFlow && isApproved) {
      setAddRejectComment(true)
    } else {
      onCancel()
    }
  }

  const handleCancelImmediately = () => {
    onSuperAdminCancel()
    onClose()
  }

  const handleCancelation = () => {
    if (canCancel && canAdminCancel) handleCancelImmediately()
    else if (canEmployeeCancel) handleCancelRequest()
    onClose()
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentToPrintRef.current
  }, [componentToPrintRef.current])

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrinting(true)

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setPrinting(false)
        resolve()
      }, 2000)
    })
  }, [setPrinting])

  const headerTitle =
    requestType === 'Request' || isNumber(requestType)
      ? currentType?.name_i18n || vars?.request_type_name
      : I18n.t(getRequestTranslationKey(currentType))

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: headerTitle || currentType,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  })

  const handleCopyLink = async () => {
    const link = `${window.location.origin}/#/?employee_id=${details?.employee?.id}&request_type=${requestType}&sequential_id=${details?.sequential_id}`
    try {
      await navigator.clipboard.writeText(link)
      setIsCopied(true)

      const timeout = setTimeout(() => {
        setIsCopied(false)
        clearTimeout(timeout)
      }, 5000)
    } catch {
      Toastr.error(I18n.t('something_went_wrong_please_try_again'))
      setIsCopied(false)
    }
  }
  const handleActionsList = () => {
    if (showResumptionActions && is_super_admin && !request?.is_onboarding_employee) {
      return [
        {
          label: I18n.t('ask_for_cancellation'),
          onClick: handleCancelRequest,
        },
        {
          label: I18n.t('cancel_immediately'),
          onClick: handleCancelImmediately,
          testId: 'cancel-immediately-btn',
        },
      ]
    } else if (
      !showResumptionActions &&
      ((canCancel && canAdminCancel) || canEmployeeCancel) &&
      !request?.is_onboarding_employee
    ) {
      return [
        {
          label: I18n.t('cancel_immediately'),
          onClick: handleCancelation,
          testId: 'cancel-immediately-btn',
        },
      ]
    }
    return []
  }
  const handleReturnForCorrection = (reason) => {
    dispatch(returnForCorrection(details?.employee?.id, details?.id, { reason })).then(() => {
      mixPanelAnalytics({
        event_name: 'submit_return_for_correction_click_request_details_sidedrawer_web',
      })
      setAddReturnComment(false)
      onClose()
      drawerToggle()
    })
  }
  const handleReject = (comment) => {
    onReject(comment)
    onClose()
    setAddRejectComment(false)
  }
  const showRequestDetailsList = () => {
    if (showDetailsActions) {
      return [
        {
          onClick: handlePrint,
          leadingIcon: 'printer',
          isDisabled: printing,
        },
        {
          leadingIcon: isCopied ? 'check' : 'link-03',
          onClick: handleCopyLink,
        },
        ...(handleActionsList() || []),
        {
          label: I18n.t('print'),
          onClick: handlePrint,
          isDisabled: printing,
        },
        {
          label: I18n.t('copy_request_link'),
          onClick: handleCopyLink,
        },
      ]
    }
    return []
  }
  const showTaskDetailsList = () => {
    if (!showPaymentDate && details.request_type !== 'HiringRequestTask' && !details?.group_details)
      return [
        {
          onClick: handlePrint,
          leadingIcon: 'printer',
        },
      ]
    return []
  }
  const showActionsList = request?.isTask ? showTaskDetailsList() : showRequestDetailsList()
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current])
  React.useEffect(() => {
    setAddRejectComment(rejectComment)
  }, [])

  return (
    <>
      <SideDrawer
        active={open}
        onClose={() => {
          onClose()
          drawerToggle(false)
        }}
        header={{
          title: headerTitle || I18n.t('new_request'),
          withClose: true,
          actions: showActionsList,
          ...header,
        }}
        {...(showFooter &&
          !showRejectReason &&
          !addRejectComment &&
          !addReturnComment && {
            footer: {
              submit: request?.isTask
                ? handleTaskSubmitBtn()
                : handelSubmitBtn(showDetailsActions, onClose),
              cancel: request?.isTask
                ? handleTaskCancelBtn()
                : handelCancelBtn(
                    showDetailsActions,
                    needApproval,
                    onClose,
                    request.is_onboarding_employee,
                  ),
              ...(request?.isTask
                ? {
                    link: handelTaskLinkBtn(),
                  }
                : (request.can_return_for_correction || askCancelAuthorize) && {
                    link: handelLinkBtn(onClose),
                  }),
            },
          })}
        {...props}
        zIndex={1000}
        testId="request_drawer"
      >
        <div
          className={classNames(
            'h-full overflow-y-scroll p-4',
            showPaymentDate && 'h-[calc(100%-132px)] overflow-y-scroll p-4',
          )}
        >
          <Request
            ref={componentToPrintRef}
            readOnly={needApproval}
            onClose={onClose}
            requestType={
              requestType === 'Request' || isNumber(requestType)
                ? currentType?.type || vars?.request_type
                : currentType
            }
            customTypeId={currentType?.id}
            {...(!!requestType && { employeeId: vars?.id })}
          />
          {addRejectComment && (
            <AddComment
              onSubmit={(comment) =>
                status === 'reject'
                  ? handleReject(comment)
                  : (() => {
                      onCancel(comment)
                      onClose()
                      setAddRejectComment(false)
                    })()
              }
              onCancel={() => setAddRejectComment(false)}
            />
          )}
          {addReturnComment && (
            <AddComment
              onSubmit={handleReturnForCorrection}
              onCancel={() => {
                setAddReturnComment(false)
                mixPanelAnalytics({
                  event_name: 'cancel_return_for_correction_click_request_details_sidedrawer_web',
                })
              }}
              fieldLabel={I18n.t('label.return_reason')}
              fieldPlaceholder={I18n.t('placeholder.enter_the_correction_reason')}
              confirmLabel={I18n.t('confirm')}
            />
          )}
          {showRejectReason && (
            <AddComment
              onSubmit={handelRejectionTask}
              onCancel={() => setShowRejectReason(false)}
              fieldLabel={I18n.t('label.rejection_reason')}
              fieldPlaceholder={I18n.t('placeholder.enter_the_rejection_reason')}
            />
          )}
          {showPaymentDate && (
            <Flex
              flexCol
              className="fixed w-[474px] bg-[var(--color-base-colors-white-1000)] bottom-4"
            >
              <DatePickerDSField
                name="paid_at"
                placeholder={I18n.t('select_date')}
                label={I18n.t('payment_date')}
                className="z-[9999]"
              />
              <Flex className="gap-2 mt-[30px] mb-2">
                <Button
                  label={I18n.t('confirm')}
                  size="small"
                  variant="filled"
                  onClick={handlPaymentDate}
                  disabled={!values?.paid_at || fetching_submit}
                  testId="reject-comment-btn"
                />
                <Button
                  label={I18n.t('cancel')}
                  size="small"
                  variant="outlined"
                  color="neutral"
                  onClick={() => setShowPaymentDate(false)}
                  data-testid="cancel-comment-btn"
                />
              </Flex>
            </Flex>
          )}
        </div>
      </SideDrawer>
      <HiringModal
        title={I18n.t('label.subscribe_to_ats')}
        message={I18n.t(
          'description.youre_just_a_step_away_from_creating_this_job._to_access_this_feature_youll_need_to_subscribe_to_jisrs_ats.',
        )}
        show={openATSModal}
        updateShow={setOpenATSModal}
        actionButton={() =>
          window.open('https://share-eu1.hsforms.com/1rNwVfmNyTKmrAF9n3rVZ3Af1vq8', '_blank')
        }
        actionButtonLabel={I18n.t('label.request_a_free_demo')}
      />
      <ResumptionActions
        request={request}
        setShowExtendingModal={setShowExtendingModal}
        showExtendingModal={showExtendingModal}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  )
}

RequestDrawer.propTypes = {
  requestType: PropTypes.string,
  needApproval: PropTypes.bool,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  vars: PropTypes.shape(),
  hideFooter: PropTypes.bool,
}

export default memo(RequestDrawer)
