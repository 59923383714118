import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { AttendanceDashboardT, OnboardingStatsT, OnboardingStepsT } from './dashboardTypes'

const initialState: AttendanceDashboardT = {
  fetching: false,
  fetchingStats: false,
  replaceBulkFetching: false,
  pagination: {},
  locations_list: [],
  onboardingSteps: [],
  onboardingStats: null,
}

export default function reducer(state = initialState, action: Action): AttendanceDashboardT {
  switch (action.type) {
    case actions.LOAD_DASHBOARD_LOCATION_PENDING:
    case actions.LOAD_ATTENDANCE_DASHBOARD_STEPS_PENDING:
    case actions.COMPLETE_ATTENDANCE_DASHBOARD_STEPS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LOAD_ATTENDANCE_DASHBOARD_STATS_PENDING:
      return {
        ...state,
        fetchingStats: true,
      }

    case actions.ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_PENDING:
      return {
        ...state,
        replaceBulkFetching: true,
      }

    case actions.CREATE_ATTENDANCE_DASHBOARD_STEPS_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LOAD_DASHBOARD_LOCATION_FULFILLED:
      const dashboardData: Pick<AttendanceDashboardT, 'locations_list' | 'pagination'> =
        action.payload.data.data

      return {
        ...state,
        fetching: false,
        ...dashboardData,
      }

    case actions.LOAD_ATTENDANCE_DASHBOARD_STEPS_FULFILLED:
      const onboardingSteps: OnboardingStepsT[] = action.payload.data.data
      return {
        ...state,
        fetching: false,
        onboardingSteps,
      }

    case actions.LOAD_ATTENDANCE_DASHBOARD_STATS_FULFILLED:
      const onboardingStats: OnboardingStatsT = action.payload.data.data

      return {
        ...state,
        fetchingStats: false,
        onboardingStats,
      }

    case actions.UPDATE_ATTENDANCE_DASHBOARD_STATS:
      const newOnboardingStats = action.payload.data
      return {
        ...state,
        onboardingStats: { ...state.onboardingStats, ...newOnboardingStats },
      }

    case actions.CREATE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED:
    case actions.COMPLETE_ATTENDANCE_DASHBOARD_STEPS_FULFILLED:
      Toastr.success(action.payload.data.message)

      return {
        ...state,
        fetching: false,
      }

    case actions.LOAD_ATTENDANCE_DASHBOARD_STATS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetchingStats: false,
      }

    case actions.ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        replaceBulkFetching: false,
      }

    case actions.LOAD_DASHBOARD_LOCATION_REJECTED:
    case actions.LOAD_ATTENDANCE_DASHBOARD_STEPS_REJECTED:
    case actions.CREATE_ATTENDANCE_DASHBOARD_STEPS_REJECTED:
    case actions.COMPLETE_ATTENDANCE_DASHBOARD_STEPS_REJECTED:
    case actions.ATTENDANCE_DASHBOARD_REPLACE_BULK_DEVICES_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        replaceBulkFetching: false,
      }

    default:
      return state
  }
}
