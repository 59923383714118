import { MouseEvent, MouseEventHandler } from 'react'
import I18n from 'translations/i18n'
import classNames from 'classnames'
import { Typography } from '@jisr-hr/ds'
import { ReactComponent as ArrowIcon } from '@jisr-hr/design-system/dist/assets/icons/down-arrow.svg'
import styles from '../styles.module.css'

type FilterBoxHeaderProps = {
  handleCollabseFilterBox: () => void
  handleClearFilter: (e: MouseEvent<HTMLButtonElement>) => void
  canShowClear: boolean
  title: string
  isCollapsed: boolean
}

const FilterBoxHeader = (props: FilterBoxHeaderProps): JSX.Element => {
  const { handleCollabseFilterBox, handleClearFilter, title, canShowClear, isCollapsed } = props

  const iconProps = {
    className: classNames(styles.arrowIcon, !isCollapsed && styles.arrowIconCollapsed),
    onClick: handleCollabseFilterBox,
  }

  return (
    <div
      onClick={handleCollabseFilterBox}
      className="flex justify-between items-center cursor-pointer"
    >
      {!!title && (
        <Typography
          text={I18n.t(title)}
          variant="interface/strong/s"
          testId={`title-filter-${title}`}
        />
      )}
      <div className="flex items-center gap-2">
        {canShowClear && (
          <Typography
            text={I18n.t('clear')}
            onClick={handleClearFilter as unknown as MouseEventHandler<HTMLParagraphElement>}
            variant="body-new/regular"
            style={{
              cursor: 'pointer',
            }}
            textColor="color/fg/brand/default"
          />
        )}
        <ArrowIcon {...iconProps} />
      </div>
    </div>
  )
}

export default FilterBoxHeader
