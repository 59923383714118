import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Cards = ({ children, gap, cardWidth, blank, className, testId }) => (
  <div
    data-testid={testId}
    className={classNames('jisr-cards', blank && 'jisr-cards--blank', className)}
    style={{
      gridGap: gap,
      gridTemplateColumns: `repeat(auto-fill, minmax(${cardWidth}, 1fr))`,
    }}
  >
    {children}
  </div>
)

Cards.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  gap: PropTypes.string,
  testId: PropTypes.string,
  cardWidth: PropTypes.string,
  blank: PropTypes.bool,
  className: PropTypes.string,
}

Cards.defaultProps = {
  gap: '17px',
  cardWidth: '278px',
}

export default Cards
