export const QUICKBOOKS_EXPORT_PAYROLL = 'QUICKBOOKS_EXPORT_PAYROLL'
export const QUICKBOOKS_EXPORT_PAYROLL_PENDING = 'QUICKBOOKS_EXPORT_PAYROLL_PENDING'
export const QUICKBOOKS_EXPORT_PAYROLL_FULFILLED = 'QUICKBOOKS_EXPORT_PAYROLL_FULFILLED'
export const QUICKBOOKS_EXPORT_PAYROLL_REJECTED = 'QUICKBOOKS_EXPORT_PAYROLL_REJECTED'

export const QUICKBOOKS_LOAD_ACCOUNT_STATUS = 'QUICKBOOKS_LOAD_ACCOUNT_STATUS'
export const QUICKBOOKS_LOAD_ACCOUNT_STATUS_PENDING = 'QUICKBOOKS_LOAD_ACCOUNT_STATUS_PENDING'
export const QUICKBOOKS_LOAD_ACCOUNT_STATUS_FULFILLED = 'QUICKBOOKS_LOAD_ACCOUNT_STATUS_FULFILLED'
export const QUICKBOOKS_LOAD_ACCOUNT_STATUS_REJECTED = 'QUICKBOOKS_LOAD_ACCOUNT_STATUS_REJECTED'

export const QUICBOOKS_DISCONNECT_ACCOUNT = 'QUICBOOKS_DISCONNECT_ACCOUNT'
export const QUICBOOKS_DISCONNECT_ACCOUNT_PENDING = 'QUICBOOKS_DISCONNECT_ACCOUNT_PENDING'
export const QUICBOOKS_DISCONNECT_ACCOUNT_FULFILLED = 'QUICBOOKS_DISCONNECT_ACCOUNT_FULFILLED'
export const QUICBOOKS_DISCONNECT_ACCOUNT_REJECTED = 'QUICBOOKS_DISCONNECT_ACCOUNT_REJECTED'

export const SYNC_QUICKBOOKS_EMPLOYEE = 'SYNC_QUICKBOOKS_EMPLOYEE'
export const SYNC_QUICKBOOKS_EMPLOYEE_PENDING = 'SYNC_QUICKBOOKS_EMPLOYEE_PENDING'
export const SYNC_QUICKBOOKS_EMPLOYEE_FULFILLED = 'SYNC_QUICKBOOKS_EMPLOYEE_FULFILLED'
export const SYNC_QUICKBOOKS_EMPLOYEE_REJECTED = 'SYNC_QUICKBOOKS_EMPLOYEE_REJECTED'

export const QUICKBOOKS_CONNECT_ACCOUNT = 'QUICKBOOKS_CONNECT_ACCOUNT'
export const QUICKBOOKS_CONNECT_ACCOUNT_PENDING = 'QUICKBOOKS_CONNECT_ACCOUNT_PENDING'
export const QUICKBOOKS_CONNECT_ACCOUNT_FULFILLED = 'QUICKBOOKS_CONNECT_ACCOUNT_FULFILLED'
export const QUICKBOOKS_CONNECT_ACCOUNT_REJECTED = 'QUICKBOOKS_CONNECT_ACCOUNT_REJECTED'

export const QUICKBOOKS_LOAD_ACCOUNT_INFO = 'QUICKBOOKS_LOAD_ACCOUNT_INFO'
export const QUICKBOOKS_LOAD_ACCOUNT_INFO_PENDING = 'QUICKBOOKS_LOAD_ACCOUNT_INFO_PENDING'
export const QUICKBOOKS_LOAD_ACCOUNT_INFO_FULFILLED = 'QUICKBOOKS_LOAD_ACCOUNT_INFO_FULFILLED'
export const QUICKBOOKS_LOAD_ACCOUNT_INFO_REJECTED = 'QUICKBOOKS_LOAD_ACCOUNT_INFO_REJECTED'
