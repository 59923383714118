import { memo } from 'react'
import { Button, Flex, Typography, Modal } from '@jisr-hr/ds'
import { Form } from 'react-final-form'
import i18n from 'translations/i18n'
import { BetaTextInputField as TextInputField } from 'components/final-form'
import {
  composeValidators,
  fixedLength,
  naturalNumber,
  required,
  isNumEqual,
} from 'components/global/form/FormValidations'

type propsType = {
  isOpen: boolean
  onCloseModal: () => void
  onSubmit: () => void
  registration_number?: string
  mol_number?: string
}

const ConfirmEstablishmentModal = (props: propsType): JSX.Element => {
  const { isOpen, registration_number, mol_number } = props

  return (
    <Modal
      open={isOpen}
      onClose={props.onCloseModal}
      size="large"
    >
      <Form onSubmit={props.onSubmit}>
        {({ handleSubmit }): JSX.Element => {
          return (
            <div style={{ padding: 24 }}>
              <Typography
                text={i18n.t('confirm_mudad_subscription')}
                style={{ lineHeight: '20px', marginBottom: 40 }}
                variant="heading"
                textColor="color/fg/danger/default"
              />
              <Typography
                text={i18n.t('confirm_mudad_subscription_dec')}
                style={{ lineHeight: '20px' }}
              />
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4"
              >
                <TextInputField
                  name="registration_number"
                  label={i18n.t('commercial_registration_number')}
                  validate={composeValidators(
                    required,
                    naturalNumber,
                    fixedLength(10),
                    isNumEqual(registration_number, 'cr_numbers_validation'),
                  )}
                  testId="registration_number"
                />
                <TextInputField
                  label={i18n.t('mol_unified_no')}
                  name="mol_registration_attributes[mol_number]"
                  validate={composeValidators(
                    required,
                    isNumEqual(mol_number, 'mol_numbers_validation'),
                  )}
                  testId="mol_number"
                />
                <Flex justifyEnd>
                  <Button
                    variant="outlined"
                    color="neutral"
                    label={i18n.t('cancel')}
                    size="small"
                    // disabled={loading || loadingPayment}
                    onClick={props.onCloseModal}
                    style={{ marginInlineEnd: '10px' }}
                    testId="back"
                  />
                  <Button
                    size="small"
                    type="submit"
                    label={i18n.t('confirm')}
                    // disabled={loading || loadingPayment}
                    testId="confirm"
                  />
                </Flex>
              </form>
            </div>
          )
        }}
      </Form>
    </Modal>
  )
}

export default memo(ConfirmEstablishmentModal)
