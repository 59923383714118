import { useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { loanTypesLoad, loanTypeDelete } from 'redux/payrollOnboarding/Loans/actionCreators'
import { SearchBar, Popper, Modal } from '@jisr-hr/design-system'
import { Widget } from 'components/global/atoms'
import { Checkbox, Badge, Flex, Spacer, Button, Typography, Modal as NewModal } from '@jisr-hr/ds'
import { Table, CustomColumnDef } from '@jisr-hr/ds-beta'
import { ReactComponent as EditIcon } from '@jisr-hr/design-system/dist/assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from 'containers/authorised/Performance/Competencies/components/Delete.svg'
import { ReactComponent as HandArmCoinsIcon } from '@jisr-hr/design-system/dist/assets/icons/HandArmCoins.svg'
import { isEmpty } from 'lodash'
import EmptyState from 'containers/authorised/setting/v2/components/EmptyState'
import BadgeWrapper from 'containers/authorised/setting/v2/payroll/Payables/components/BadgeWrapper'
import classNames from 'classnames'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  handelValuesEdit,
  initState,
} from 'containers/authorised/setting/v2/payroll/Payables/components/helper'

import styles from '../style.module.css'
import LoanTypeForm from './LoanTypeForm'

type PayrollTransactionType = {
  default_pay_type: string
  description: string
  id: number
  name_i18n: string
  pay_type: string
  status: string
}

type DataType = {
  id: number
  name_i18n: string
}

type LoansType = { id: number } | null

type ParamsType = {
  filters?: {
    default_pay_type: string[]
    status: string[]
  }
  search?: string
}

type RowDataType = { row: { original: PayrollTransactionType } }

const LoanTypes = (): JSX.Element => {
  const dispatch = useDispatch()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [obj, setObj] = useState<DataType>({ id: 0, name_i18n: '' })
  const [params, setParams] = useState<ParamsType>(initState)
  const [firstRender, setFirstRender] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [loansType, setLoansType] = useState<LoansType>(null)

  const { payroll_transaction_types, fetching, loading } = useSelector(
    ({ onboardingLoans }) => onboardingLoans,
  )

  function handleFilter(data: object | string, key: string): void {
    setParams({ ...params, [key]: data })
    dispatch(loanTypesLoad({ ...params, [key]: data }))
    setFirstRender(false)
  }

  const handleOnChange = (value: string): void => {
    handleFilter(value, 'search')
    setFirstRender(false)
  }

  const handleEdit = (v: Record<string, unknown>): void => {
    setModalOpen(true)
    setLoansType(handelValuesEdit(v) as LoansType)
  }

  const onClose = (): void => {
    setModalOpen(false)
    setLoansType(null)
    dispatch(loanTypesLoad(params))
  }

  const handleDelete = (): void => {
    dispatch(loanTypeDelete(obj.id)).then(() => {
      setOpenConfirmModal(false)
      dispatch(loanTypesLoad(params))
      dispatch(organizationInfo())
    })
  }

  useEffect(() => {
    dispatch(loanTypesLoad(params))
  }, [])

  const columns: CustomColumnDef[] = [
    {
      header: I18n.t('name'),
      cell: (rowData: RowDataType) => {
        const item = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={item.name_i18n}
          />
        )
      },
    },
    {
      header: I18n.t('pay_mode'),
      cell: (rowData: RowDataType) => {
        const item = rowData.row.original
        return item.pay_type === 'both' ? (
          <Flex itemsCenter>
            <BadgeWrapper
              data_tip={I18n.t('tool_tip_pay_type_in_payroll')}
              id={1}
            >
              <Badge
                color="primary"
                label={I18n.t('in_payroll')}
                variant={item.default_pay_type === 'in_payrun' ? 'filled' : 'tinted'}
                {...(item.default_pay_type === 'in_payrun' && {
                  leadingIcon: 'star-01',
                })}
              />
            </BadgeWrapper>
            <Spacer width="8px" />
            <BadgeWrapper
              data_tip={I18n.t('tool_tip_pay_type_out_payroll')}
              id={0}
            >
              <Badge
                color="primary"
                label={I18n.t('out_of_payroll')}
                variant={item.default_pay_type === 'out_of_payrun' ? 'filled' : 'tinted'}
                {...(item.default_pay_type === 'out_of_payrun' && {
                  leadingIcon: 'star-01',
                })}
              />
            </BadgeWrapper>
          </Flex>
        ) : (
          <BadgeWrapper
            id={item.pay_type}
            data_tip={
              item.pay_type === 'in_payrun'
                ? I18n.t('tool_tip_pay_type_in_payroll')
                : I18n.t('tool_tip_pay_type_out_payroll')
            }
          >
            <Badge
              color="primary"
              variant="filled"
              label={
                item.pay_type === 'in_payrun' ? I18n.t('in_payroll') : I18n.t('out_of_payroll')
              }
            />
          </BadgeWrapper>
        )
      },
    },
    {
      header: I18n.t('description'),
      cell: (rowData: RowDataType) => {
        const item = rowData.row.original
        return (
          <Typography
            variant="interface/default/sm"
            text={item.description}
          />
        )
      },
    },
    {
      header: I18n.t('status'),
      cell: (rowData: RowDataType) => {
        const item = rowData.row.original
        return (
          <Badge
            label={I18n.t(item.status)}
            color="gray"
            variant="filled"
            size="medium"
          />
        )
      },
    },
    {
      header: I18n.t('actions'),
      alignment: 'right',
      cell: (rowData: RowDataType) => {
        const item = rowData.row.original
        return (
          <Flex>
            <EditIcon
              className={styles.icon}
              onClick={(): void => {
                handleEdit(item)
              }}
            />
            <Spacer width={8} />
            <DeleteIcon
              className={styles.icon}
              onClick={(): void => {
                setOpenConfirmModal(true)
                setObj(item)
              }}
            />
          </Flex>
        )
      },
    },
  ]

  return (
    <>
      {firstRender && isEmpty(payroll_transaction_types) ? (
        <EmptyState
          icon={<HandArmCoinsIcon />}
          labelBtn={I18n.t('new_loan_types')}
          onClick={(): void => {
            handleEdit({})
          }}
          title={I18n.t('loan_types')}
        />
      ) : (
        <Widget
          className={classNames(styles.widget, styles.widgeDrawer)}
          fetching={fetching}
        >
          <Flex
            justifyBetween
            itemsCenter
            className={styles.title}
          >
            <Typography
              variant="title-1"
              text={I18n.t('loan_types')}
              style={{ color: 'var(--color-base-colors-black-500)' }}
            />

            <Flex style={{ gap: 5 }}>
              <Button
                label={I18n.t('new_loan')}
                leadingIcon="plus"
                size="small"
                variant="outlined"
                color="neutral"
                onClick={(): void => handleEdit({})}
              />
            </Flex>
          </Flex>
          <div>
            <Spacer height={20} />
            <Flex style={{ gap: 8 }}>
              <Spacer width={8} />
              <SearchBar
                placeholder={I18n.t('search')}
                locale={localStorage.getItem('Locale')}
                onChange={(_: unknown, search: string): void => {
                  handleOnChange(search)
                }}
                value={params.search}
              />
              <Popper
                trigger={
                  <Button
                    iconOnly
                    size="medium"
                    color="neutral"
                    variant="outlined"
                    leadingIcon="filter-lines"
                  />
                }
                className={styles.pay_type_action_popper}
                placement="bottom-end"
                maxHeight={450}
              >
                <Flex
                  flexCol
                  style={{ padding: '10px 20px' }}
                >
                  <Flex
                    flexCol
                    style={{ marginBottom: '8px' }}
                  >
                    <Typography
                      variant="interface/default/sm"
                      text={I18n.t('pay_method')}
                    />
                    <Spacer height="8px" />
                    {['out_of_payrun', 'in_payrun'].map((item: string) => (
                      <Checkbox
                        key={item}
                        label={I18n.t(item)}
                        active={params?.filters?.default_pay_type.includes(item)}
                        onChange={(event): void => {
                          let result = []
                          if (event.target.checked) {
                            result = [...(params.filters?.default_pay_type || []), item]
                          } else {
                            result =
                              (params.filters?.default_pay_type ?? []).filter(
                                (it) => it !== item,
                              ) ?? []
                          }
                          handleFilter({ ...params.filters, default_pay_type: result }, 'filters')
                        }}
                      />
                    ))}
                  </Flex>
                  <Flex flexCol>
                    <Typography
                      variant="interface/default/sm"
                      text={I18n.t('status')}
                    />
                    <Spacer height="8px" />
                    {['active', 'inactive'].map((item: string) => (
                      <Checkbox
                        key={item}
                        label={I18n.t(item)}
                        onChange={(event): void => {
                          let result = []
                          if (event.target.checked) {
                            result = [...(params.filters?.status || []), item]
                          } else {
                            result = (params.filters?.status ?? []).filter((it) => it !== item)
                          }
                          handleFilter({ ...params.filters, status: result }, 'filters')
                        }}
                        active={params?.filters?.status.includes(item)}
                      />
                    ))}
                  </Flex>
                </Flex>
              </Popper>
            </Flex>
            <Spacer height={20} />
            <Table
              columns={columns}
              data={payroll_transaction_types}
              tableSize={{ maxHeight: '40vh' }}
            />
          </div>
        </Widget>
      )}
      <Modal
        header
        disableCloseOut
        isOpen={modalOpen}
        onClose={(): void => {
          setModalOpen(false)
        }}
        width={540}
        content={
          <LoanTypeForm
            loansType={loansType}
            onClose={onClose}
          />
        }
      />
      <NewModal
        variant="danger"
        size="small"
        header={{
          title: I18n.t('delete_loan_type'),
          withClose: true,
          leadingSpace: { icon: 'alert-triangle' },
        }}
        footer={{
          cancel: {
            label: I18n.t('cancel'),
            onClick: (): void => setOpenConfirmModal(false),
          },
          submit: {
            label: I18n.t('confirm'),
            onClick: handleDelete,
            disabled: loading,
            loading,
          },
        }}
        open={openConfirmModal}
        disableCloseOut
      >
        <Typography
          variant="body-new/regular"
          style={{ color: 'var(--color-base-colors-black-700)' }}
          text={I18n.t('confirm_message', { name: obj.name_i18n })}
        />
      </NewModal>
    </>
  )
}

export default LoanTypes
