import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import { Typography, Button, Flex, Spacer, LinkButton } from '@jisr-hr/ds'
import { isValid } from 'date-fns'
import { minuteToHumanReadable, replaceAmPm } from 'components/global/form/formNormalize'
import _ from 'lodash'
import { isTruthy } from 'utils/truthy'

import { ReactComponent as URLLinkIcon } from '@jisr-hr/design-system/dist/assets/icons/urlLink.svg'
import { ReactComponent as TaskCheckmarkIcon } from 'assets/images/TaskCheckmark.svg'
import { statusBadge } from 'containers/authorised/requests/helper'
import { ReactComponent as RequestTypeIcon } from '../../icons/requestType.svg'
import { ReactComponent as StatusIcon } from '../../icons/status.svg'
import { ReactComponent as ClockTimerIcon } from './icons/clock-timer.svg'
import { ReactComponent as CalendarIcon } from './icons/calendar.svg'
import { ReactComponent as MoneyIcon } from './icons/Money.svg'
import { ReactComponent as DivisionMarkIcon } from './icons/DivisionMark.svg'
import { ReactComponent as PlaneIcon } from './icons/gray-Plane.svg'
import { ReactComponent as ListTaskIcon } from './icons/list-task-checkmark.svg'

import InfoBadge from './components/InfoBadge'
import CopyRequestUrl from './components/CopyRequestUrl'
import DueDate from '../DueDate'

import Styles from './Info.module.css'

const typeKeys = {
  ExcuseRequest: 'excuse_type_i18n',
  ExcuseCancellationRequest: 'excuse_type_i18n',
  OvertimeRequest: 'translated_input_type',
  OvertimeCancellationRequest: 'translated_input_type',
}

export const AdditionalServices = ({ details }) => {
  return (
    <Flex>
      <div>
        <TaskCheckmarkIcon />
      </div>
      <Spacer width={8} />
      <Flex flexCol>
        <Typography
          text={details.name || i18n.t('additional_services')}
          variant="body-new/regular"
        />
        <Spacer height={8} />
        <Flex
          flexWrap
          className={Styles.requestType}
        >
          {details.tags?.map((item, i) => (
            <InfoBadge
              key={i}
              label={item}
              color="gray"
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

AdditionalServices.propTypes = {
  details: PropTypes.shape(),
}

const RequestType = ({ details }) => {
  const { in_time, out_time } = details || {}
  const key = typeKeys[details?.request_type]
  const description = details?.[key]
  const request_type =
    details?.request_name_i18n || details?.type_i18n || details?.request_type_i18n
  let punch_type_i18n = ''

  if (in_time && out_time) punch_type_i18n = `${i18n.t('in')} + ${i18n.t('out')}`
  else if (in_time) punch_type_i18n = i18n.t('in')
  else if (out_time) punch_type_i18n = i18n.t('out')

  const halfDayTranslate = `${details?.half_day_leave}_approval` // first_half - second_half

  return (
    <Flex
      itemsCenter
      className={Styles.requestType}
    >
      {request_type && (
        <InfoBadge
          label={request_type}
          color="primary"
        />
      )}
      {(description || punch_type_i18n) && (
        <InfoBadge
          label={description || punch_type_i18n?.toUpperCase()}
          color="gray"
        />
      )}
      {details?.leave_type?.name && (
        <InfoBadge
          label={details?.leave_type?.name}
          color="gray"
        />
      )}
      {details?.half_day_leave && (
        <InfoBadge
          label={`${i18n.t('half_day')} - ${i18n.t(halfDayTranslate)}`.toLowerCase()}
          color="gray"
        />
      )}
    </Flex>
  )
}

RequestType.propTypes = {
  details: PropTypes.shape(),
}

const CustomDates = ({ details, dateFormat }) => {
  const startDate = format(details?.date?.start_date, dateFormat)
  const endDate = format(details?.date?.end_date, dateFormat)

  return (
    <Typography
      text={`${startDate} ${endDate && ' - '} ${endDate}`}
      variant="interface/strong/m"
    />
  )
}

CustomDates.propTypes = {
  details: PropTypes.shape(),
  dateFormat: PropTypes.string,
}

export const items = (details) =>
  [
    details?.sequential_id && {
      label: i18n.t('request_id'),
      icon: <RequestTypeIcon />,
      value: <CopyRequestUrl sequentialId={details?.sequential_id} />,
      key: 'sequential_id',
    },
    {
      label: details?.linkedWith,
      key: 'linked_with_request',
      icon: <RequestTypeIcon />,
      value: (
        <LinkButton
          variant="primary"
          testId="job_title"
          trailingIcon="arrow-up-right"
          label={i18n.t('label.go_to_request')}
          onClick={details?.onClickLinkedRequest}
        />
      ),
    },
    {
      label: i18n.t('request_type'),
      icon: <RequestTypeIcon />,
      value: <RequestType details={details} />,
      key: 'type',
    },
    {
      label: details?.is_cancellation_request
        ? i18n.t('original_request')
        : i18n.t('cancellation_request'),
      icon: <URLLinkIcon className={Styles.linkIcon} />,
      value: (
        <Button
          leadingIcon="arrow-narrow-up-right"
          label={i18n.t('go_to_request')}
          size="small"
          variant="ghost"
          color="primary"
          onClick={details?.onClick}
        />
      ),
      key: 'cancellation_request_linking',
    },
    {
      label: i18n.t('status'),
      icon: <StatusIcon />,
      value: details?.status_i18n ? (
        <Flex
          itemsCenter
          style={{ gap: 4 }}
        >
          {statusBadge({
            key: details?.status?.toLowerCase(),
            label: details?.status_i18n,
            isAutomated: !!details?.metadata?.automation_event_action_id,
          })}
        </Flex>
      ) : (
        ''
      ),
      key: 'status',
    },
    {
      label: i18n.t('payment_date'),
      icon: <CalendarIcon />,
      value: details?.payment_date && format(new Date(details?.payment_date), 'dd/MM/yyyy'),
      key: 'payment_date',
    },
    {
      label: i18n.t('attendance_day'),
      icon: <CalendarIcon />,
      value: isValid(new Date(details.attendance_day))
        ? format(new Date(details.attendance_day), 'dd MMMM, yyyy')
        : '--',
      color: 'var(--color-natural-gray-darker-2)',
      key: 'attendance_day',
    },
    {
      label: i18n.t('requested_duration'),
      icon: <ClockTimerIcon />,
      value: minuteToHumanReadable(details?.excuse_time) || '--',
      color: 'var(--color-natural-black-default-new)',
      key: 'requested_duration',
    },
    {
      label: i18n.t('no_of_days'),
      icon: <CalendarIcon />,
      value: `${details?.overtimeRange || 0} ${i18n.t('days')}`,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'day_number',
    },
    {
      label: i18n.t('attendance_duration'),
      icon: <CalendarIcon />,
      value: `${
        isValid(new Date(details?.from)) ? format(new Date(details?.from), 'd MMM') : ''
      } - ${isValid(new Date(details?.to)) ? format(new Date(details?.to), 'd MMM, yyyy') : ''}`,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'attendance_duration',
    },
    {
      label: i18n.t('destination'),
      icon: <PlaneIcon />,
      value: details?.destination,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'destination',
    },
    {
      label: i18n.t('total_amount'),
      icon: <MoneyIcon />,
      value: `${details?.total_amount || 0} ${details.currency}`,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'total_amount',
    },
    {
      label: (
        <Flex flexCol>
          <Typography
            textColor="color/fg/lighter"
            text={i18n.t('additional_needs')}
            variant="body-new/regular"
          />
          <Flex style={{ gap: 8 }}>
            {details.is_require_vacation_salary && (
              <InfoBadge
                label={i18n.t('vacation_salary')}
                leadingIcon="bank-note-02"
                color="gray"
              />
            )}
            {details.require_ticket && (
              <InfoBadge
                label={i18n.t('flight_ticket')}
                leadingIcon="plane"
                color="gray"
              />
            )}
            {details.is_re_entry_exit && (
              <InfoBadge
                label={i18n.t('exit_re_entry_visa')}
                leadingIcon="globe-02"
                color="gray"
              />
            )}
          </Flex>
        </Flex>
      ),
      icon: <ListTaskIcon />,
      value: ' ',
      color: 'var(--color-natural-gray-darker-2)',
      key: 'additional_needs',
    },
    {
      label: (
        <Flex flexCol>
          <Typography
            textColor="color/fg/lighter"
            text={i18n.t('additional_services')}
            variant="body-new/regular"
          />
          <Flex style={{ gap: 8 }}>
            {details?.included_additions?.map((additions, i) => (
              <InfoBadge
                key={i}
                label={additions?.text}
                color="gray"
              />
            ))}
          </Flex>
        </Flex>
      ),
      icon: <ListTaskIcon />,
      value: _.isEmpty(details?.included_additions) ? '--' : ' ',
      color: 'var(--color-natural-gray-darker-2)',
      key: 'additional_services',
    },
    {
      label: i18n.t('period_of_deduction'),
      icon: <DivisionMarkIcon />,
      value: `${isValid(new Date(details?.from)) && format(new Date(details?.from), 'd MMM')} - ${
        isValid(new Date(details?.to)) && format(new Date(details?.to), 'd MMM, yyyy')
      }`,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'period_of_deduction',
    },
    {
      key: 'asset_items',
      label: i18n.t('asset_items'),
      icon: <TaskCheckmarkIcon />,
      value: (
        <Flex className={Styles.requestType}>
          {['Car', 'Laptob'].map((item, i) => (
            <InfoBadge
              key={i}
              label={item}
              type="gray"
            />
          ))}
        </Flex>
      ),
    },
    {
      label: i18n.t('period_of_deduction'),
      icon: <DivisionMarkIcon />,
      value: `${isValid(new Date(details?.from)) && format(new Date(details?.from), 'd MMM')} - ${
        isValid(new Date(details?.to)) && format(new Date(details?.to), 'd MMM, yyyy')
      }`,
      color: 'var(--color-natural-gray-darker-2)',
      key: 'period_of_deduction',
    },
    {
      label: i18n.t('asset_items'),
      icon: <TaskCheckmarkIcon />,
      value: (
        <Flex
          flexWrap
          className={Styles.requestType}
        >
          {details?.asset_items?.map((item, i) => (
            <InfoBadge
              key={i}
              label={item}
              type="gray"
            />
          ))}
        </Flex>
      ),
      key: 'asset_items',
    },
    {
      label: i18n.t('current_month_overtime_hours'),
      key: 'current_month_overtime_hours',
      icon: <ClockTimerIcon />,
      value: details?.month_overtime_hours?.hours || '--',
      color: 'var(--color-natural-gray-darker-2)',
    },
    {
      label: i18n.t('date_of_last_request'),
      key: 'date_of_last_request',
      icon: <CalendarIcon />,
      value: format(details?.last_overtime_request?.to_date, 'dd MMM, yyyy') || '--',
      color: 'var(--color-natural-gray-darker-2)',
    },
    {
      label: i18n.t('excuse_hours'),
      icon: <ClockTimerIcon />,
      value: details?.excuse_hours || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'excuse_hours',
    },
    {
      label: i18n.t('from'),
      icon: <ClockTimerIcon />,
      value: details?.from_time ? replaceAmPm(details?.from_time) : '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'from_time',
    },
    {
      label: i18n.t('to'),
      icon: <ClockTimerIcon />,
      value: details?.to_time ? replaceAmPm(details?.to_time) : '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'to_time',
    },
    {
      label: i18n.t('year_excuse_hours'),
      icon: <ClockTimerIcon />,
      value: details?.year_excuse_hours || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'year_excuse_hours',
    },
    {
      label: i18n.t('approved_business_hours'),
      icon: <ClockTimerIcon />,
      value: details?.approved_business_hours || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'approved_business_hours',
    },
    {
      label: i18n.t('approved_personal_hours'),
      icon: <ClockTimerIcon />,
      value: details?.approved_personal_hours || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'approved_personal_hours',
    },
    {
      label: i18n.t('approved_date'),
      icon: <ClockTimerIcon />,
      value: format(new Date(details?.approved_date), 'dd MMM, yyyy') || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'approved_date',
    },
    {
      label: i18n.t('missed_time'),
      icon: <ClockTimerIcon />,
      value: details?.request?.missed_time || '--', // minuteToHumanReadable(excuse_time)
      color: 'var(--color-natural-black-default-new)',
      key: 'missed_time',
    },
    {
      label: i18n.t('date'),
      icon: <CalendarIcon />,
      value: (
        <CustomDates
          details={details}
          dateFormat="EEEE, dd MMM"
        />
      ),
      color: 'var(--color-natural-black-default-new)',
      key: 'date',
    },
    {
      label: i18n.t('label.due_date'),
      icon: <CalendarIcon />,
      value: (
        <DueDate
          showAction
          runAction={details.handelDueDate}
          dateValue={details.due_date ?? ''}
        />
      ),
      key: 'due_date',
    },
    {
      label: i18n.t('date'),
      icon: <CalendarIcon />,
      value: <>{format(details?.created_at, 'EEEE, dd MMM')}</>,
      key: 'flight_ticket_date',
    },
    {
      label: i18n.t('label.payroll_name'),
      icon: <CalendarIcon />,
      value: details.paygroup_name,
      key: 'paygroup_name',
    },
    {
      label: i18n.t('label.payroll_month'),
      icon: <ClockTimerIcon />,
      value: details.payrun_month,
      key: 'payrun_month',
    },
  ].filter(isTruthy)
