export const QUICKBOOKS_COST_CENTER_LIST_LOAD = 'QUICKBOOKS_COST_CENTER_LIST_LOAD'
export const QUICKBOOKS_COST_CENTER_LIST_LOAD_FULFILLED =
  'QUICKBOOKS_COST_CENTER_LIST_LOAD_FULFILLED'
export const QUICKBOOKS_COST_CENTER_LIST_LOAD_REJECTED = 'QUICKBOOKS_COST_CENTER_LIST_LOAD_REJECTED'
export const QUICKBOOKS_COST_CENTER_LIST_LOAD_PENDING = 'QUICKBOOKS_COST_CENTER_LIST_LOAD_PENDING'

export const QUICKBOOKS_LOCATIONS_LOAD = 'QUICKBOOKS_LOCATIONS_LOAD'
export const QUICKBOOKS_LOCATIONS_LOAD_FULFILLED = 'QUICKBOOKS_LOCATIONS_LOAD_FULFILLED'
export const QUICKBOOKS_LOCATIONS_LOAD_REJECTED = 'QUICKBOOKS_LOCATIONS_LOAD_REJECTED'
export const QUICKBOOKS_LOCATIONS_LOAD_PENDING = 'QUICKBOOKS_LOCATIONS_LOAD_PENDING'

export const QUICKBOOKS_CLASSES_LOAD = 'QUICKBOOKS_CLASSES_LOAD'
export const QUICKBOOKS_CLASSES_LOAD_FULFILLED = 'QUICKBOOKS_CLASSES_LOAD_FULFILLED'
export const QUICKBOOKS_CLASSES_LOAD_REJECTED = 'QUICKBOOKS_CLASSES_LOAD_REJECTED'
export const QUICKBOOKS_CLASSES_LOAD_PENDING = 'QUICKBOOKS_CLASSES_LOAD_PENDING'

export const QUICKBOOKS_COST_CENTER_CREATE = 'QUICKBOOKS_COST_CENTER_CREATE'
export const QUICKBOOKS_COST_CENTER_CREATE_FULFILLED = 'QUICKBOOKS_COST_CENTER_CREATE_FULFILLED'
export const QUICKBOOKS_COST_CENTER_CREATE_REJECTED = 'QUICKBOOKS_COST_CENTER_CREATE_REJECTED'
export const QUICKBOOKS_COST_CENTER_CREATE_PENDING = 'QUICKBOOKS_COST_CENTER_CREATE_PENDING'

export const QUICKBOOKS_COST_CENTER_UPDATE = 'QUICKBOOKS_COST_CENTER_UPDATE'
export const QUICKBOOKS_COST_CENTER_UPDATE_FULFILLED = 'QUICKBOOKS_COST_CENTER_UPDATE_FULFILLED'
export const QUICKBOOKS_COST_CENTER_UPDATE_REJECTED = 'QUICKBOOKS_COST_CENTER_UPDATE_REJECTED'
export const QUICKBOOKS_COST_CENTER_UPDATE_PENDING = 'QUICKBOOKS_COST_CENTER_UPDATE_PENDING'

export const QUICKBOOKS_COST_CENTER_DELETE = 'QUICKBOOKS_COST_CENTER_DELETE'
export const QUICKBOOKS_COST_CENTER_DELETE_FULFILLED = 'QUICKBOOKS_COST_CENTER_DELETE_FULFILLED'
export const QUICKBOOKS_COST_CENTER_DELETE_REJECTED = 'QUICKBOOKS_COST_CENTER_DELETE_REJECTED'
export const QUICKBOOKS_COST_CENTER_DELETE_PENDING = 'QUICKBOOKS_COST_CENTER_DELETE_PENDING'
