import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Containers, Icon, Typography } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import { isRtl } from 'utils/uiHelpers'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { mixPanelAnalytics } from 'utils/mixpanel'
import { handelRequestPath } from '../Workflow/helper'
import AllTasks from './AllTasks'
import CreatedTasks from './CreatedTasks'

const LinkedTaskWithReq = (): JSX.Element => {
  const { request: details } = useSelector(({ requestDetails }) => requestDetails)
  const isSuperAdmin = useSelector((state) => state?.auth.employee?.is_super_admin)
  const manage_request_workflow = useSelector(
    (s) => s?.auth.employee?.permission_scopes?.manage_request_workflow,
  )
  const employee = useSelector((s) => s.auth.employee)

  type TaskDetails = typeof details

  const onViewLinkedTasks = (taskDetails: TaskDetails): void => {
    mixPanelAnalytics({
      event_name: 'View_Linked_Tasks',
      properties: {
        Request_ID: taskDetails.id,
        User_ID: employee?.id,
        Task_Count: taskDetails?.tasks?.length,
      },
    })
  }

  useEffect(() => {
    if ((details?.tasks?.length === 0 || !details?.tasks) && details?.status === 'pending') {
      mixPanelAnalytics({
        event_name: 'View_Pending_Tasks_Message_Displayed',
        properties: {
          Request_ID: details?.id,
          User_ID: employee?.id,
        },
      })
    }
  }, [details?.id])
  return (
    <Containers
      borderRadius="sm"
      border
      color="white"
      zIndex={1}
      className="p-4"
    >
      <div className="flex justify-between mb-4 items-center">
        <Typography
          text={i18n.t('module.requests.tasks')}
          variant="subheading"
        />
        {isSuperAdmin && manage_request_workflow && (
          <Link
            to={handelRequestPath(details, `linked_task=${1}`)}
            onClick={onViewLinkedTasks.bind(null, details)}
          >
            <div className="flex gap-1 items-center">
              <Typography
                variant="interface/medium/sm"
                text={i18n.t('label.linked_tasks')}
                textColor="color/fg/brand/default"
              />
              <DSIcon
                name={isRtl ? 'arrow-narrow-up-left' : 'arrow-narrow-up-right'}
                color="var(--color-brand-primary-default-new)"
                className="fill-base-colors-blue-1000"
              />
            </div>
          </Link>
        )}
      </div>
      <div className="flex gap-2 mb-4 items-center">
        {details.status === 'pending' && (
          <>
            <Icon
              name="info-circle-filled"
              size="md"
            />
            <Typography
              text={i18n.t(
                details?.linked_tasks && details?.linked_tasks.length === 0
                  ? 'create_description.request_doesnt_have_any_linked_tasks.'
                  : 'create_description.tasks_will_be_auto-created_after_request_approval',
              )}
              variant="subtitle-2"
              textColor="color/fg/bold"
            />
          </>
        )}
      </div>
      {details.status === 'approved' && details?.tasks && details?.tasks?.length > 0 && (
        <CreatedTasks list={details?.tasks} />
      )}
      {details.status === 'pending' &&
        details?.linked_tasks &&
        details?.linked_tasks?.length > 0 && <AllTasks linkedTasksList={details?.linked_tasks} />}
    </Containers>
  )
}

export default LinkedTaskWithReq
