import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function requestDetailsLoad(
  employee_id: number,
  { request_id, sequential_id }: { request_id?: number; sequential_id?: number },
): Action {
  const params = { request_id, sequential_id }
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/requests`

  return {
    type: actions.REQUEST_DETAILS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

// export function addAttendanceNewCommentRequest(comment) {
//   return {
//     type: actions.REQUEST_DETAILS_COMMENT,
//     payload: { comment },
//   };
// }

export function attendanceLogsLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/attendance/records/${id}/attendance_logs`

  return {
    type: actions.ATTENDANCE_LOGS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function resetRequestDetail(state = {}): Action {
  return {
    type: actions.RESET_REQUEST_DETAIL,
    payload: state,
  }
}
