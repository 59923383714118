import { useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { useDispatch, useSelector } from 'utils/hooks'
import { Typography, Button, Spacer, Flex, Modal } from '@jisr-hr/ds'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import { ratingTrainingVideos } from 'redux/organizationOnborading/trainingVideos/actionCreators'
import { TRating } from 'redux/organizationOnborading/trainingVideos/type'
import { ReactComponent as PlayVideoIcon } from './images/play_video.svg'
import styles from './styles.module.css'

type TProps = {
  path: string
}

const PlayVideo = ({ path }: TProps): JSX.Element => {
  const dispatch = useDispatch()
  const { org } = useContext(OnboardingContext)
  const [openVideo, setOpenVideo] = useState(false)
  const [listViewed, setListViewed] = useState<string[]>([])
  const { fetching, training_video } = useSelector(({ trainingVideos }) => trainingVideos)

  const handleOnClick = (id: number, values: TRating): void => {
    dispatch(ratingTrainingVideos(id, values))
  }

  useEffect(() => {
    const viewed = localStorage.getItem(`videos_have_viewed_${org.id}`)
    if (viewed) {
      setListViewed(JSON.parse(viewed))
    }
  }, [])

  return (
    <>
      <Flex
        itemsCenter
        justifyCenter
        className={styles.videoContent}
        onClick={(): void => {
          const newList = uniq([...listViewed, path])
          setOpenVideo(true)
          setTimeout(() => {
            setListViewed(newList)
            localStorage.setItem(`videos_have_viewed_${org.id}`, JSON.stringify(newList))
          }, 3000)
        }}
      >
        <PlayVideoIcon />
      </Flex>
      <Spacer height={16} />
      <Typography
        text={training_video.name_i18n}
        variant="body-new/semibold"
      />
      {training_video?.description_i18n && (
        <>
          <Spacer height={8} />
          <Typography
            text={training_video.description_i18n}
            variant="body-new/regular"
          />
        </>
      )}

      <Spacer height={8} />
      <Modal
        open={openVideo}
        size="large"
        onClose={(): void => setOpenVideo(false)}
      >
        <video
          controls
          autoPlay
          className="mb-[-6px] w-[758px] mx-[-24px]"
        >
          <source
            type="video/mp4"
            src={training_video.s3_url}
          />
          <track
            src=""
            kind="captions"
          />
        </video>
      </Modal>
      {(training_video.answer?.option?.id || listViewed?.includes(path)) && (
        <>
          <Typography
            variant="body-new/medium"
            text={training_video.question.name_i18n}
            style={{ color: 'var(--ds-base-color-actionable-secondary-fg)' }}
          />
          <Spacer height={8} />
          <Flex style={{ gap: '8px' }}>
            {training_video.question.options.map((e, i) => (
              <Button
                key={i}
                size="small"
                label={e.name_i18n}
                disabled={fetching}
                onClick={(): void =>
                  handleOnClick(training_video.id, {
                    option_id: e.id,
                    question_id: training_video.question.id,
                  })
                }
                style={{ padding: 4 }}
                variant={training_video.answer?.option?.id === e.id ? 'filled' : 'ghost'}
                color={training_video.answer?.option?.id === e.id ? 'primary' : 'neutral'}
                leadingIcon={e.name_en === 'Yes' ? 'thumbs-up' : 'thumbs-down'}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  )
}

export default PlayVideo
