import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { InitialStateType } from './type'

export const initialState: InitialStateType = {
  fetching: false,
  leave_types: [],
}

export default function reducer(state = initialState, { type, payload }: Action): InitialStateType {
  switch (type) {
    case actions.ONBOARDING_LEAVE_TYPES_LOAD_PENDING:
    case actions.ONBOARDING_LEAVE_TYPES_POST_PENDING:
    case actions.ONBOARDING_LEAVE_TYPES_PUT_PENDING:
    case actions.ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_PENDING:
    case actions.ONBOARDING_LEAVE_TYPES_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_LEAVE_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_LEAVE_TYPES_POST_FULFILLED:
    case actions.ONBOARDING_LEAVE_TYPES_PUT_FULFILLED:
    case actions.ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_FULFILLED:
    case actions.ONBOARDING_LEAVE_TYPES_DELETE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_LEAVE_TYPES_LOAD_REJECTED:
    case actions.ONBOARDING_LEAVE_TYPES_POST_REJECTED:
    case actions.ONBOARDING_LEAVE_TYPES_PUT_REJECTED:
    case actions.ONBOARDING_LEAVE_TYPES_LABOR_LAW_PUT_REJECTED:
    case actions.ONBOARDING_LEAVE_TYPES_DELETE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
