/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react'
import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

export type CandidateCountryProps = {
  filterKey: string
  mixPanelEventName?: string
  filters: string[]
}
const CandidateTitle = (props: CandidateCountryProps): JSX.Element | null => {
  const { candidate_title } = useFilterAPI()
  const { handleFilter, filter } = useFilters()
  if (!candidate_title?.length) return null

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={candidate_title}
      selectedIds={filter[props.filterKey]}
      {...props}
      title={i18n.t('label.current_title')}
    />
  )
}
export default memo(CandidateTitle)
