import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
}
type SettingStates = typeof DEFAULT_STATE

export default function configurationReducer(stats = DEFAULT_STATE, action: Action): SettingStates {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_PENDING:
    case actions.LABORAW_CONFIGURATIONS_UPDATE_PENDING: {
      return {
        ...stats,
        fetching: true,
      }
    }

    case actions.ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_FULFILLED:
    case actions.LABORAW_CONFIGURATIONS_UPDATE_FULFILLED: {
      return {
        ...stats,
        fetching: false,
      }
    }

    case actions.ONBOARDING_LABOR_LAW_CONFIGURATIONS_CREATE_REJECTED:
    case actions.LABORAW_CONFIGURATIONS_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...stats,
        fetching: false,
      }
    }
    default:
      return stats
  }
}
