import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PaymentStatusTypes = {
  filterKey: string
  filters: string[]
}

const PaymentStatus = (props: PaymentStatusTypes): JSX.Element => {
  const { payment_statuses } = useFilterAPI()

  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const optionList = returnBadgeList(payment_statuses)
  const fltr = filter[props.filterKey]
  const paymentStatuses = filter.payment_statuses

  const onHandleFilter = (__: string, val: string | boolean): void => {
    if (val !== 'all') {
      const withoutAll = paymentStatuses.filter((item: string) => item !== 'all')
      setFilter({ ...filter, payment_statuses: [...withoutAll, val] })
    } else {
      setFilter({ ...filter, payment_statuses: [] })
    }
  }

  useEffect(() => {
    if (!isEmpty(fltr)) {
      setSelectedIds(fltr)
    } else {
      setSelectedIds(['all'])
    }
  }, [fltr])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds as never[]}
      componentType="badges"
      valueKey="id"
      {...props}
      title="label.payment_status"
    />
  )
}

export default PaymentStatus
