import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { TOutsourcingCompany } from '../employees/employees.type'

type TInitStateOutsourcingCompany = {
  fetching: boolean
  outsourcingCompanies: TOutsourcingCompany[]
}

export const initialState = {
  fetching: false,
  outsourcingCompanies: [],
}

export default function reducer(
  state = initialState,
  action: Action,
): TInitStateOutsourcingCompany {
  switch (action.type) {
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_LOAD_PENDING:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_POST_PENDING:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_PUT_PENDING:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_DELETE_PENDING:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_OUTSOURCING_COMPANIES_POST_FULFILLED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_PUT_FULFILLED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_DELETE_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_OUTSOURCING_COMPANIES_LOAD_FULFILLED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_SHOW_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        outsourcingCompanies: action.payload.data?.data?.outsourcing_companies,
        fetching: false,
      }

    case actions.ONBOARDING_OUTSOURCING_COMPANIES_LOAD_REJECTED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_POST_REJECTED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_PUT_REJECTED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_DELETE_REJECTED:
    case actions.ONBOARDING_OUTSOURCING_COMPANIES_SHOW_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
