import { Lens } from '@dhmk/zustand-lens'
import { GetAllGroupsParams, GroupType } from 'apis/Setting/OrgManagement/Groups/useGroups.type'

export type GroupsStateType = {
  // ? states
  openDrawer:
    | 'add_group'
    | 'view_group'
    | 'add_members'
    | 'add_employee'
    | 'add_ungrouped_employee'
    | null
  openModal: 'delete_manager' | 'delete_member' | null
  editModal: 'edit_group' | null
  selectedGroup: GroupType | null
  showAddMembers: 'add_members' | 'edit_members' | null
  showMoveMembers: boolean
  selectedEmployee: any
  filters: GetAllGroupsParams
  // ? actions
  setOpenDrawer: (openModal: GroupsStateType['openDrawer']) => void
  setSelectedGroup: (selectedGroup: GroupType | null) => void
  setShowAddMembers: (showAddMembers: GroupsStateType['showAddMembers']) => void
  setShowMoveMembers: (moveMembers: boolean) => void
  setOpenModal: (showAddMembers: GroupsStateType['openModal']) => void
  setEditModal: (showAddMembers: GroupsStateType['editModal']) => void
  setSelectedEmployee: (selectedEmployee: any) => void
  setFilters: (search: GetAllGroupsParams) => void
}

export const createGroupsSlice: Lens<GroupsStateType> = (set) => {
  return {
    // ? states
    openDrawer: null,
    selectedGroup: null,
    openModal: null,
    editModal: null,
    showAddMembers: null,
    selectedEmployee: null,
    showMoveMembers: false,
    filters: {
      search: undefined,
    },
    // ? actions
    setOpenDrawer: (openDrawer: GroupsStateType['openDrawer']) => set({ openDrawer }),
    setOpenModal: (openModal: GroupsStateType['openModal']) => set({ openModal }),
    setEditModal: (editModal: GroupsStateType['editModal']) => set({ editModal }),
    setSelectedGroup: (selectedGroup: GroupType | null) => set({ selectedGroup }),
    setSelectedEmployee: (selectedEmployee: any) => set({ selectedEmployee }),
    setShowMoveMembers: (showMoveMembers: boolean) => set({ showMoveMembers }),
    setShowAddMembers: (showAddMembers: GroupsStateType['showAddMembers']) =>
      set({ showAddMembers }),
    setFilters: (filters: GetAllGroupsParams) => set({ filters }),
  }
}

export type GroupsApprovalCycleStateType = {
  // ? states
  openGroupsDrawer: boolean
  selectedGroups: Array<GroupType>

  // ? actions
  setOpenGroupsDrawer: (openModal: boolean) => void
  setSelectedGroups: (selectedGroups: GroupType[]) => void
}

export const createGroupsApprovalCycleSlice: Lens<GroupsApprovalCycleStateType> = (set) => {
  return {
    // ? states
    openGroupsDrawer: false,
    selectedGroups: [],

    // ? actions
    setOpenGroupsDrawer: (openGroupsDrawer: boolean) => set({ openGroupsDrawer }),
    setSelectedGroups: (selectedGroups: Array<GroupType>) => set({ selectedGroups }),
  }
}
