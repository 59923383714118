import { useState } from 'react'
import { Flex, Spacer, Typography } from '@jisr-hr/ds'
import { configurationsUpdate } from 'redux/organizationOnborading/organizationConfigurations/actionCreators'
import { useDispatch } from 'utils/hooks'
import classNames from 'classnames'
import jisrLogo from 'assets/images/JisrLogo.svg'
import { useTrackingSteps } from '../../helper'
import styles from './styles.module.css'

const Language = (): JSX.Element => {
  const [activeLanguage, setActiveLanguage] = useState('')
  const dispatch = useDispatch()

  const handleChange = (value: string): void => {
    localStorage.setItem('Locale', value)
    dispatch(
      configurationsUpdate({
        organization_configuration: { value },
      }),
    ).then(() => window.location.reload())
    setActiveLanguage(value)
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'language_selection' })

  return (
    <Flex
      className={styles.content}
      justifyCenter
      flexCol
      itemsCenter
    >
      <Flex
        className={styles.contentImgJisr}
        justifyCenter
        itemsCenter
      >
        <img
          src={jisrLogo}
          alt="Jisr"
        />
      </Flex>
      <Spacer height={24} />
      <Typography text="Language" />
      <Spacer height={8} />
      <Typography
        variant="subtitle-2"
        text="What’s your preferred language?"
      />
      <Spacer height={16} />
      <Flex className={styles.languageBady}>
        <div
          className={classNames(styles.language, activeLanguage === 'en' && styles.activeLanguage)}
          onClick={(): void => handleChange('en')}
        >
          <div>
            <Typography
              variant="interface/default/sm"
              text="English"
            />

            <Typography
              variant="subtitle-2"
              text="Use Jisr in English, you can change that later in settings, or from the top bar"
            />
          </div>
        </div>
        <div
          className={classNames(styles.language, activeLanguage === 'ar' && styles.activeLanguage)}
          onClick={(): void => handleChange('ar')}
        >
          <div style={{ textAlign: 'right' }}>
            <Typography
              variant="interface/default/sm"
              text="العربية"
            />

            <Typography
              variant="subtitle-2"
              text={
                <>
                  يمكنك استخدام جسر باللغة العربية وتغيير اللغة من الاعدادات لاحقاً أو من أعلي يمين
                  الصفحة
                </>
              }
            />
          </div>
        </div>
      </Flex>
    </Flex>
  )
}

export default Language
