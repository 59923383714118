import { useSelector } from 'utils/hooks'

export type AccountType = 'actual' | 'demo' | 'test'

const useIsDemoAccount = (): boolean => {
  const type = useSelector<AccountType>((s) => s.organization.organization.account_type)

  return type === 'demo'
}

export default useIsDemoAccount
