import { FC, useMemo, useState } from 'react'
import { Typography, Flex } from '@jisr-hr/ds'
import {
  mudadFlowStepsLoad,
  mudadRegisterEstablishment,
} from 'redux/setting/payrollManagement/paymentMethods/mudad/actionsCreator'
import { useDispatch, useSelector } from 'utils/hooks'
import { paymentMethodLoad } from 'redux/setting/payrollManagement/paymentMethods/actionCreators'
import i18n from 'translations/i18n'
import MudadStepsTemplate from '../components/MudadStepsTemplate'
import { usePaymentMethod } from '../../../components/usePaymentMethods'
import styles from '../styles.module.css'
import EstablishmentAlerts from './EstablishmentAlerts'
import ConfirmEstablishmentModal from '../components/ConfrimEstablishmentModal'

const Establishment: FC = () => {
  const dispatch = useDispatch()
  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod } = paymentCtx

  const paymentMethod = useSelector((s) => s.paymentMethods.current_payment_method)
  const fetching = useSelector((s) => s.paymentMethods.fetchingShowMethod)
  const establishing = useSelector((s) => s.mudadIntegration.establishing)
  const establishment_err = useSelector((s) => s.mudadIntegration.establishment_err)

  const [open, setOpen] = useState(false)

  const mudad_establishment = paymentMethod?.mudad_establishment
  const unifiedNumber = paymentMethod?.commercial_registration?.unified_mol_number

  const info = [
    [
      { name: 'commercial_registration_name', value: selectedPaymentMethod?.name || '' },
      {
        name: 'commercial_registration_no',
        value: selectedPaymentMethod?.registration_number || '',
      },
    ],
    [
      {
        name: unifiedNumber ? 'unified_mol_no' : 'ministry_of_labor_no',
        value: unifiedNumber || selectedPaymentMethod?.mol_registration.mol_registration_number,
      },
      { name: 'gosi_number', value: selectedPaymentMethod?.mol_registration.gosi_number || '' },
    ],
  ]

  const alertType: 'success' | 'error' | 'info' = useMemo(() => {
    if (mudad_establishment) return 'success'
    if (!mudad_establishment && establishment_err) return 'error'
    return 'info'
  }, [mudad_establishment, establishment_err])

  const handleEstablishmentClick = async (): Promise<void> => {
    if (!selectedPaymentMethod) return
    const data = {
      registration_id: selectedPaymentMethod.registration_id,
    }

    setOpen(!open)
    await dispatch(mudadRegisterEstablishment(data))
    paymentCtx.setMudadCurrentStep(2)
    await dispatch(paymentMethodLoad(selectedPaymentMethod.id))
    await dispatch(mudadFlowStepsLoad(selectedPaymentMethod.registration_id))
  }

  const handleNextClick = (): void => {
    if (mudad_establishment) {
      paymentCtx.setMudadCurrentStep(2)
    } else {
      setOpen(!open)
    }
  }

  return (
    <MudadStepsTemplate
      disableBackBtn
      disableNextBtn={establishing || fetching}
      nextLabel={mudad_establishment ? i18n.t('next') : i18n.t('register')}
      onNextClick={handleNextClick}
    >
      <EstablishmentAlerts type={alertType} />
      {info.map((section, i) => {
        return (
          <Flex
            key={i}
            style={{ gap: 128, marginTop: i === 0 ? 0 : 24 }}
          >
            {section.map((item) => (
              <Flex
                className={styles.establishment_box}
                key={item.name}
                flexCol
              >
                <Typography
                  variant="interface/default/m"
                  style={{
                    marginBottom: 4,
                    fontWeight: 400,
                    color: 'var(--color-base-colors-grey-800)',
                  }}
                  text={i18n.t(item.name)}
                />
                <Typography
                  variant="interface/default/m"
                  style={{ fontWeight: 700, color: 'var(--color-base-colors-grey-800)' }}
                  text={item.value}
                />
              </Flex>
            ))}
          </Flex>
        )
      })}
      <ConfirmEstablishmentModal
        isOpen={open}
        registration_number={selectedPaymentMethod?.registration_number}
        mol_number={
          unifiedNumber || selectedPaymentMethod?.mol_registration.mol_registration_number
        }
        onCloseModal={(): void => setOpen(!open)}
        onSubmit={(): Promise<void> => handleEstablishmentClick()}
      />
    </MudadStepsTemplate>
  )
}

export default Establishment
