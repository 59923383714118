import { format as formatFns, parseISO } from 'date-fns'
import { arSA, enUS } from 'date-fns/locale'
import i18n from 'translations/i18n'
import { DateSplit } from 'types/date'

export const locales: { [key: string]: Locale } = { en: enUS, ar: arSA }
const enFormat = ['yyyy-MM-dd', 'yyyy/MM/dd']

export function format(date: Date | string, frmatStr: string): string | Date {
  if (!date) return ''
  const LOCALE = localStorage.getItem('Locale') ?? 'en'
  const formatStr = LOCALE === 'ar' ? frmatStr.replace(/MMM+/g, 'MMMM') : frmatStr
  let newDate = date
  try {
    if (typeof newDate === 'string') {
      newDate = new Date(date)
    }

    if (enFormat.includes(formatStr)) {
      return formatFns(newDate, formatStr)
    }

    return formatFns(newDate, formatStr, {
      locale: locales[LOCALE],
    })
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return date
}

export const getDateSplit = (fullDate: string): DateSplit => {
  const [day, month, year] = fullDate?.split('-') || []
  return {
    year,
    month,
    day,
  }
}

export const dmyToymd = (dayMonthYear: string): string => {
  const { day, month, year } = getDateSplit(dayMonthYear)
  return day && month && year ? `${year}-${month}-${day}` : dayMonthYear
}

export const isDateMatch = (d: string): boolean => {
  const dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/
  const isMatch = `${d}`.match(dateReg) !== null
  if (isMatch) {
    return true
  }
  return false
}

export const getTwoDigits = (n: number | string): string => {
  const twoDigits = `0${n}`.slice(-2)
  return twoDigits
}

export const getInt = (numString?: string): number => {
  const number = numString ? parseInt(numString, 10) : 0

  return number
}

export const dateWithOutTimeZone = (date: string | Date): Date =>
  typeof date === 'string' ? new Date(`${date}T00:00`) : date

export const dateWithTimeZoneOffset = (date: Date | string): Date => {
  const offset = new Date(date).getTimezoneOffset()
  return new Date(new Date(date).getTime() + offset * 60 * 1000)
}

export function readableDateFormat(dateString: string | Date): string {
  const LOCALE = localStorage.getItem('Locale') ?? 'en'
  const date = parseISO(
    typeof dateString === 'string' ? dateString : formatFns(dateString, 'yyyy-MM-dd'),
  )

  return formatFns(date, 'dd MMMM, yyyy', {
    locale: locales[LOCALE],
  })
}

export const getDayLabel = (day: number): string => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return i18n.t('label.num_st_day', { count: day })
    case 22:
    case 2:
      return i18n.t('label.num_nd_day', { count: day })
    case 3:
    case 23:
      return i18n.t('label.num_rd_day', { count: day })
    default:
      return i18n.t('label.num_th_day', { count: day })
  }
}

export const generateTranslatedDaysOptions = (): {
  label: string
  value: string
  id: string
  name: string
}[] => {
  const days = Array.from({ length: 31 }, (_, index) => {
    const day = index + 1
    return {
      id: getDayLabel(day),
      label: getDayLabel(day),
      name: getDayLabel(day),
      value: day.toString(),
    }
  })

  return days
}

export const translatedDaysOptions = generateTranslatedDaysOptions()
