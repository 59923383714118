import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type InitialStateType = {
  fetching: boolean
  loan_report: {
    title: string
    employee_info: { name: string; value: string }[]
    request_info: { name: string; value: string }[]
  } | null
}

export const initialState: InitialStateType = {
  fetching: false,
  loan_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.EMPLOYEE_LOAN_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.EMPLOYEE_LOAN_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        loan_report: action.payload.data.data.loan_report,
        fetching: false,
      }
    }

    case actions.EMPLOYEE_LOAN_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
