export const LOAD_ENDORSEMENTS = 'LOAD_ENDORSEMENTS'
export const LOAD_ENDORSEMENTS_PENDING = 'LOAD_ENDORSEMENTS_PENDING'
export const LOAD_ENDORSEMENTS_FULFILLED = 'LOAD_ENDORSEMENTS_FULFILLED'
export const LOAD_ENDORSEMENTS_REJECTED = 'LOAD_ENDORSEMENTS_REJECTED'

export const LOAD_ENDORSEMENT_DETAILS = 'LOAD_ENDORSEMENT_DETAILS'
export const LOAD_ENDORSEMENT_DETAILS_PENDING = 'LOAD_ENDORSEMENT_DETAILS_PENDING'
export const LOAD_ENDORSEMENT_DETAILS_FULFILLED = 'LOAD_ENDORSEMENT_DETAILS_FULFILLED'
export const LOAD_ENDORSEMENT_DETAILS_REJECTED = 'LOAD_ENDORSEMENT_DETAILS_REJECTED'

export const GET_EMPLOYEES_List = 'GET_EMPLOYEES_List'
export const GET_EMPLOYEES_List_PENDING = 'GET_EMPLOYEES_List_PENDING'
export const GET_EMPLOYEES_List_FULFILLED = 'GET_EMPLOYEES_List_FULFILLED'
export const GET_EMPLOYEES_List_REJECTED = 'GET_EMPLOYEES_List_REJECTED'

export const VERIFICATION = 'VERIFICATION'
export const VERIFICATION_PENDING = 'VERIFICATION_PENDING'
export const VERIFICATION_FULFILLED = 'VERIFICATION_FULFILLED'
export const VERIFICATION_REJECTED = 'VERIFICATION_REJECTED'

export const VERIFY_EMPLOYEE_INFO = 'VERIFY_EMPLOYEE_INFO'
export const VERIFY_EMPLOYEE_INFO_PENDING = 'VERIFY_EMPLOYEE_INFO_PENDING'
export const VERIFY_EMPLOYEE_INFO_FULFILLED = 'VERIFY_EMPLOYEE_INFO_FULFILLED'
export const VERIFY_EMPLOYEE_INFO_REJECTED = 'VERIFY_EMPLOYEE_INFO_REJECTED'

export const DELETE_ENDORSEMENT = 'DELETE_ENDORSEMENT'
export const DELETE_ENDORSEMENT_PENDING = 'DELETE_ENDORSEMENT_PENDING'
export const DELETE_ENDORSEMENT_FULFILLED = 'DELETE_ENDORSEMENT_FULFILLED'
export const DELETE_ENDORSEMENT_REJECTED = 'DELETE_ENDORSEMENT_REJECTED'

export const SAVE_EMPLOYEE_INFO = 'SAVE_EMPLOYEE_INFO'
export const SAVE_EMPLOYEE_INFO_PENDING = 'SAVE_EMPLOYEE_INFO_PENDING'
export const SAVE_EMPLOYEE_INFO_FULFILLED = 'SAVE_EMPLOYEE_INFO_FULFILLED'
export const SAVE_EMPLOYEE_INFO_REJECTED = 'SAVE_EMPLOYEE_INFO_REJECTED'

export const SYNC_ENDORSEMENTS = 'SYNC_ENDORSEMENTS'
export const SYNC_ENDORSEMENTS_PENDING = 'SYNC_ENDORSEMENTS_PENDING'
export const SYNC_ENDORSEMENTS_FULFILLED = 'SYNC_ENDORSEMENTS_FULFILLED'
export const SYNC_ENDORSEMENTS_REJECTED = 'SYNC_ENDORSEMENTS_REJECTED'

export const VERIFY_DEPENDENTS = 'VERIFY_DEPENDENTS'
export const VERIFY_DEPENDENTS_PENDING = 'VERIFY_DEPENDENTS_PENDING'
export const VERIFY_DEPENDENTS_FULFILLED = 'VERIFY_DEPENDENTS_FULFILLED'
export const VERIFY_DEPENDENTS_REJECTED = 'VERIFY_DEPENDENTS_REJECTED'

// LOOK UPS

export const GET_HEALTH_DECLARATIONS_LOOK_UPS = 'GET_HEALTH_DECLARATIONS_LOOK_UPS'
export const GET_HEALTH_DECLARATIONS_LOOK_UPS_PENDING = 'GET_HEALTH_DECLARATIONS_LOOK_UPS_PENDING'
export const GET_HEALTH_DECLARATIONS_LOOK_UPS_FULFILLED =
  'GET_HEALTH_DECLARATIONS_LOOK_UPS_FULFILLED'
export const GET_HEALTH_DECLARATIONS_LOOK_UPS_REJECTED = 'GET_HEALTH_DECLARATIONS_LOOK_UPS_REJECTED'

export const SAVE_HEALTH_DECLARATION_ANSWERS = 'SAVE_HEALTH_DECLARATION_ANSWERS'
export const SAVE_HEALTH_DECLARATION_ANSWERS_PENDING = 'SAVE_HEALTH_DECLARATION_ANSWERS_PENDING'
export const SAVE_HEALTH_DECLARATION_ANSWERS_FULFILLED = 'SAVE_HEALTH_DECLARATION_ANSWERS_FULFILLED'
export const SAVE_HEALTH_DECLARATION_ANSWERS_REJECTED = 'SAVE_HEALTH_DECLARATION_ANSWERS_REJECTED'

export const INITIATE_ENDORSEMENT = 'INITIATE_ENDORSEMENT'
export const INITIATE_ENDORSEMENT_PENDING = 'INITIATE_ENDORSEMENT_PENDING'
export const INITIATE_ENDORSEMENT_FULFILLED = 'INITIATE_ENDORSEMENT_FULFILLED'
export const INITIATE_ENDORSEMENT_REJECTED = 'INITIATE_ENDORSEMENT_REJECTED'

export const GET_BILL_DETAILS = 'GET_BILL_DETAILS'
export const GET_BILL_DETAILS_PENDING = 'GET_BILL_DETAILS_PENDING'
export const GET_BILL_DETAILS_FULFILLED = 'GET_BILL_DETAILS_FULFILLED'
export const GET_BILL_DETAILS_REJECTED = 'GET_BILL_DETAILS_REJECTED'

export const GENERATE_BILL = 'GENERATE_BILL'
export const GENERATE_BILL_PENDING = 'GENERATE_BILL_PENDING'
export const GENERATE_BILL_FULFILLED = 'GENERATE_BILL_FULFILLED'
export const GENERATE_BILL_REJECTED = 'GENERATE_BILL_REJECTED'
export const SAVE_DEPENDENTS = 'SAVE_DEPENDENTS'
export const SAVE_DEPENDENTS_PENDING = 'SAVE_DEPENDENTS_PENDING'
export const SAVE_DEPENDENTS_FULFILLED = 'SAVE_DEPENDENTS_FULFILLED'
export const SAVE_DEPENDENTS_REJECTED = 'SAVE_DEPENDENTS_REJECTED'

export const DELETE_DEPENDENTS = 'DELETE_DEPENDENTS'
export const DELETE_DEPENDENTS_PENDING = 'DELETE_DEPENDENTS_PENDING'
export const DELETE_DEPENDENTS_FULFILLED = 'DELETE_DEPENDENTS_FULFILLED'
export const DELETE_DEPENDENTS_REJECTED = 'DELETE_DEPENDENTS_REJECTED'

export const SUBMIT_ENDORSEMENT = 'SUBMIT_ENDORSEMENT'
export const SUBMIT_ENDORSEMENT_PENDING = 'SUBMIT_ENDORSEMENT_PENDING'
export const SUBMIT_ENDORSEMENT_FULFILLED = 'SUBMIT_ENDORSEMENT_FULFILLED'
export const SUBMIT_ENDORSEMENT_REJECTED = 'SUBMIT_ENDORSEMENT_REJECTED'

export const SAVE_GCC_AND_BEDOON_DEPENDENTS = 'SAVE_GCC_AND_BEDOON_DEPENDENTS'
export const SAVE_GCC_AND_BEDOON_DEPENDENTS_PENDING = 'SAVE_GCC_AND_BEDOON_DEPENDENTS_PENDING'
export const SAVE_GCC_AND_BEDOON_DEPENDENTS_FULFILLED = 'SAVE_GCC_AND_BEDOON_DEPENDENTS_FULFILLED'
export const SAVE_GCC_AND_BEDOON_DEPENDENTS_REJECTED = 'SAVE_GCC_AND_BEDOON_DEPENDENTS_REJECTED'

export const REUPLOAD_REQUIRED_DOCUMENTS = 'REUPLOAD_REQUIRED_DOCUMENTS'
export const REUPLOAD_REQUIRED_DOCUMENTS_PENDING = 'REUPLOAD_REQUIRED_DOCUMENTS_PENDING'
export const REUPLOAD_REQUIRED_DOCUMENTS_FULFILLED = 'REUPLOAD_REQUIRED_DOCUMENTS_FULFILLED'
export const REUPLOAD_REQUIRED_DOCUMENTS_REJECTED = 'REUPLOAD_REQUIRED_DOCUMENTS_REJECTED'

export const RESUBMIT_ENDORSEMENT = 'RESUBMIT_ENDORSEMENT'
export const RESUBMIT_ENDORSEMENT_PENDING = 'RESUBMIT_ENDORSEMENT_PENDING'
export const RESUBMIT_ENDORSEMENT_FULFILLED = 'RESUBMIT_ENDORSEMENT_FULFILLED'
export const RESUBMIT_ENDORSEMENT_REJECTED = 'RESUBMIT_ENDORSEMENT_REJECTED'

export const CHANGE_CLASS = 'CHANGE_CLASS'
export const CHANGE_CLASS_PENDING = 'CHANGE_CLASS_PENDING'
export const CHANGE_CLASS_FULFILLED = 'CHANGE_CLASS_FULFILLED'
export const CHANGE_CLASS_REJECTED = 'CHANGE_CLASS_REJECTED'

export const RESET_DEPENDENT_STATE = 'RESET_DEPENDENT_STATE'
export const RESET_CHANGE_CLASS_HEALTH_DECLARATION = 'RESET_CHANGE_CLASS_HEALTH_DECLARATION'
