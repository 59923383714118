import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { minuteToHumanReadable, replaceAmPm } from 'components/global/form/formNormalize'
import { Typography, Flex, Spacer } from '@jisr-hr/ds'
import Note from 'components/global/ds/Note'

import { ReactComponent as ClockIcon } from './icons/clock-watch.svg'

import Styles from './AttendanceRecord.module.css'

const ShiftDetail = ({ shift, isDayOff }) => (
  <Note
    variant={isDayOff && 'gray'}
    isDayOff={isDayOff}
  >
    {isDayOff ? (
      <Typography
        text={I18n.t('day_off')}
        variant="subtitle-2"
        textColor="color/fg/light"
        style={{ textAlign: 'center', padding: '20px 0' }}
      />
    ) : (
      <Flex
        flexCol
        className={Styles.shiftContainer}
      >
        <Typography
          text={
            <>
              {`${replaceAmPm(shift?.shift_start_time)} — ${replaceAmPm(shift?.shift_end_time)}`}
              {shift?.shift_name_i18n && (
                <span className={Styles.shiftName}>({shift?.shift_name_i18n})</span>
              )}
            </>
          }
          variant="body-new/regular"
          textColor="color/fg/lighter"
        />
        {shift?.work_duration > 0 && (
          <RecordDetail
            label={minuteToHumanReadable(shift?.work_duration)}
            icon={<ClockIcon />}
          />
        )}
      </Flex>
    )}
  </Note>
)

export default ShiftDetail

ShiftDetail.propTypes = {
  shift: PropTypes.shape(),
  isDayOff: PropTypes.bool,
}

const RecordDetail = ({ icon, label }) => (
  <Flex itemsCenter>
    {icon}
    <Spacer width={6} />
    <Typography
      text={label}
      variant="subtitle-2"
      textColor="color/fg/lighter"
    />
  </Flex>
)

RecordDetail.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.shape(),
}
