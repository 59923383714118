import I18n from 'translations/i18n'
import { differenceInCalendarDays, isAfter } from 'date-fns'
import { isValidTime } from 'components/global/form/formNormalize'

export const required = (value) => (value ? undefined : I18n.t('required'))

export const duplicated = (value, list, msg) =>
  list.includes(value) ? `${msg ?? I18n.t('duplicated')}` : undefined

export const maxLength = (max) => (value) =>
  value && value.length > max ? I18n.t('max_length', { max }) : undefined

export const fixedLength =
  (len, msg = 'fixed_length_validation_msg') =>
  (value) =>
    value && value?.length !== len ? I18n.t(msg, { len }) : undefined

export const maximum =
  (max, msg = 'must_be_less_than') =>
  (value) =>
    value && value > max ? I18n.t(msg, { max }) : undefined

export const maximum10 = maximum(10)
export const maximum12 = maximum(12)
export const maxLength64 = maxLength(64)

export const minDate = (min) => (value) => {
  if (value) {
    const inputDate = new Date(value)
    const minimumDate = new Date(min)

    if (isAfter(minimumDate, inputDate)) {
      return I18n.t('communication.expiry_date_should_be_in_future')
    }
  }
  return undefined
}

export const minLength = (min) => (value) =>
  value && value.length < min ? I18n.t('min_length_msg', { min }) : undefined

export const minimumLength7 = minLength(7)
export const maximumLength10 = maxLength(10)
export const fixedLength15 = fixedLength(15, 'communication.vat_reg_no_length')
export const fixedLength10 = fixedLength(10, 'communication.crnum10digits')
export const fixedLength8 = fixedLength(8, 'fixed_length_validation_msg')

// ? accept negative or positive number
// ? accept float and integer number
export const number = (value) =>
  value && (Number.isNaN(Number(value)) || !/^-?\d*\.?\d+$/.test(value))
    ? I18n.t('number_msg')
    : undefined

// ? accept integer numbers only without any symbols or negative values
export const naturalNumber = (value) =>
  value && !/^[+]?([0-9]+(?:[eE][+-]?[0-9]+)?|\.[0-9]+(?:[eE][+-]?[0-9]+)?)$/.test(value)
    ? I18n.t('number_msg')
    : undefined

export const positive = (value) =>
  // eslint-disable-next-line no-useless-escape
  value && !/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/.test(value)
    ? I18n.t('positive_msg')
    : undefined

export const minValue = (min) => (value) =>
  value && value < min ? I18n.t('min_value_msg', { min }) : undefined

export const minValue1 = minValue(1)
export const minValue2 = minValue(2)

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9-]{2,63}$/i.test(value)
    ? I18n.t('email_msg')
    : undefined

export const time = (value) => {
  const onlyNums = value.replace(/[^\d]/g, '')

  if (value && onlyNums.length < 4) {
    return I18n.t('invalid_time')
  }

  return undefined
}

export const aol = (value) => (value && /.+@aol\.com/.test(value) ? I18n.t('aol_msg') : undefined)
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? I18n.t('alpha_numeric_msg') : undefined
export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? I18n.t('phone_number_msg') : undefined

export const arabicOnly = (value) =>
  value && !/^[\u0600-\u06FF\s\p{P}]+$/u.test(value) ? I18n.t('arabic_only') : undefined

export const englishOnly = (value) =>
  value && !/^[a-zA-Z\s\p{P}]+$/u.test(value) ? I18n.t('english_only') : undefined

export const noArabicLetters = (value) =>
  value && /[\u0600-\u06FF]/.test(value) ? I18n.t('english_only') : undefined

export const noSymbols =
  (except = '') =>
  (value) => {
    if (!value) return undefined
    const escapedExcept = except.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regex = new RegExp(`^(?!-)(?!.*-$)[a-zA-Z\u0600-\u06FF0-9\\s'${escapedExcept}]+$`, 'u')
    return !regex.test(value) ? I18n.t('communication.no_symbols') : undefined
  }

export const noSpace = (value) =>
  value && /\s/.test(value) ? I18n.t('space_not_allowed') : undefined

export const hasBlankSpaces = (value, errMsg) =>
  value && value.match(/^\s+$/) !== null ? I18n.t(errMsg || 'has_blank_spaces') : undefined

export const notOnlySpaces = (value) =>
  value && !value.replace(/\s/g, '').length ? I18n.t('space_not_allowed') : undefined

export const daysDifference = (checkDays, errMsg) => (value) => {
  const days = differenceInCalendarDays(new Date(value), new Date())

  return value && days <= checkDays ? errMsg : undefined
}

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined)

export const validTime = (value) => (!isValidTime(value) ? I18n.t('invalid_time') : undefined)

export const isValidURL = (value) => {
  if (value) {
    // eslint-disable-next-line
    const res = value.match(
      // eslint-disable-next-line no-useless-escape
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    )
    return res == null ? I18n.t('invalid_url') : undefined
  }
  return undefined
}

export const isValidHTTPSURL = (value) => {
  if (value) {
    try {
      const url = new URL(value)
      if (url.protocol === 'https:') {
        return undefined
      }
      return I18n.t('invalid_https_url')
    } catch (error) {
      return I18n.t('invalid_https_url')
    }
  }
  return undefined
}

export const ValidEmployeeName = (name) => {
  if (name.length > 35) {
    return I18n.t('employee_name_length')
  }
  return undefined
}

export const isNumEqual = (num, message) => (value) =>
  value && value !== num ? I18n.t(message) : undefined

export const leastOneNumber = (value) => {
  if (!/(?=.*[0-9])/.test(value)) {
    return 'At least one number'
  }
  return undefined
}

export const atLeastOneChar = (value) => {
  const trimmedValue = value.trim()
  return trimmedValue.length > 0 ? undefined : I18n.t('must_include_a_letter')
}

export const spaceSeparated = (name) => {
  const text = name.trim().split(' ')

  if (text.length <= 1) return 'The name must contain the first and last name'

  return undefined
}

export const invalidTime = (value) => (isValidTime(value) ? undefined : I18n.t('invalid_time'))

export const validateCode = (value) =>
  value && /[^a-zA-Z0-9-]/i.test(value) ? I18n.t('code_validation_msg') : undefined

export const shouldStartsWith = (start_with_digits) => (value) => {
  if (typeof value === 'string' && typeof start_with_digits === 'string') {
    return value && !value.startsWith(start_with_digits)
      ? I18n.t('start_with_msg', { start_with_digits })
      : undefined
  }
  return undefined
}

export const validateDecimalPlaces =
  (decimalPlaces = 2) =>
  (value) => {
    if (!value) return undefined
    const regex = new RegExp(`^\\d+(\\.\\d{0,${decimalPlaces}})?$`)
    return regex.test(value)
      ? undefined
      : I18n.t('communication.enter_a_valid_number_with_up_to_2_decimal_places')
  }
