export const PAY_TYPES_LOAD = 'PAY_TYPES_LOAD'
export const PAY_TYPES_LOAD_PENDING = 'PAY_TYPES_LOAD_PENDING'
export const PAY_TYPES_LOAD_FULFILLED = 'PAY_TYPES_LOAD_FULFILLED'
export const PAY_TYPES_LOAD_REJECTED = 'PAY_TYPES_LOAD_REJECTED'

export const PAY_TYPE_CREATE = 'PAY_TYPE_CREATE'
export const PAY_TYPE_CREATE_PENDING = 'PAY_TYPE_CREATE_PENDING'
export const PAY_TYPE_CREATE_FULFILLED = 'PAY_TYPE_CREATE_FULFILLED'
export const PAY_TYPE_CREATE_REJECTED = 'PAY_TYPE_CREATE_REJECTED'

export const PAY_TYPE_UPDATE = 'PAY_TYPE_UPDATE'
export const PAY_TYPE_UPDATE_PENDING = 'PAY_TYPE_UPDATE_PENDING'
export const PAY_TYPE_UPDATE_FULFILLED = 'PAY_TYPE_UPDATE_FULFILLED'
export const PAY_TYPE_UPDATE_REJECTED = 'PAY_TYPE_UPDATE_REJECTED'

export const PAY_TYPE_DELETE = 'PAY_TYPE_DELETE'
export const PAY_TYPE_DELETE_PENDING = 'PAY_TYPE_DELETE_PENDING'
export const PAY_TYPE_DELETE_FULFILLED = 'PAY_TYPE_DELETE_FULFILLED'
export const PAY_TYPE_DELETE_REJECTED = 'PAY_TYPE_DELETE_REJECTED'
