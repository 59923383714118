/** **************
 *  employeeLeaveTypesReducer
 /*************** */

const DEFAULT_STATE = {
  leave_types: [],
  fetching: false,
  loading: false,
}

let changes = null
export default function employeeLeaveTypesReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'EMPLOYEE_LEAVE_TYPES_LOAD_PENDING':
      return { ...state, loading: true }

    case 'EMPLOYEE_LEAVE_TYPES_LOAD_FULFILLED':
      changes = {
        leave_types: action.payload.data.data.leave_types,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'EMPLOYEE_LEAVE_TYPES_LOAD_REJECTED':
      return { ...state, loading: false }

    default:
      return state
  }
}
