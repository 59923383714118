import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch, useSelector } from 'react-redux'
import { businessTripCitiesLoad } from 'redux/actions/businessTripCitiesActions'
import { omit } from 'lodash'
import { familyDetailsLoad } from 'redux/actions/employeeFamilyDetailActions'
import {
  createBusinessTripRequests,
  updateBusinessTripRequests,
} from 'redux/actions/businessTripRequestsActions'
import {
  Reason,
  Profile,
  FlightTicket,
  ExitReEntryVisa,
  BusinessTripDetails,
} from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const BusinessTripRequest = () => {
  const dispatch = useDispatch()
  const { fetching } = useSelector(({ businessTripCities }) => businessTripCities)
  const { fetching: postFetching } = useSelector(({ businessTripRequests }) => businessTripRequests)

  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, date, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const onSubmit = (val) => {
    const values = {
      ...val,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }
    if (val.id) {
      return dispatch(
        updateBusinessTripRequests(empId, values.id, {
          ...values,
          attachments: values?.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => {
        setEditRequest(false)
      })
    }
    return dispatch(
      createBusinessTripRequests(empId, {
        ...val,
        attachments: val?.attachments?.map((it) => omit(it, 'id')),
      }),
    ).then(() => {
      drawerToggle()
      if (actionCallback) {
        actionCallback()
      }
    })
  }

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        business_trip_city_id: request?.business_trip_city?.id,
        additions: request?.additions?.map((add) => add.id),
        exit_reentry_info_attributes: request.exit_reentry_info,
        ticket_info_attributes: {
          ...request.ticket_info,
          family_detail_ids: request?.ticket_info?.family_details?.map(({ id: fId }) => fId),
        },
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        ...(date && { leave_date: date }),
        require_ticket: false,
        is_re_entry_exit: false,
        exit_reentry_info_attributes: {
          entry_type: 'Single',
          period: 3,
        },
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    dispatch(familyDetailsLoad(empId, { template: 'public' }))
    dispatch(businessTripCitiesLoad({ template: 'public', filters: { employee_id: empId } }))

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(fetching || postFetching || loading) && <JisrLoader absolute />}
      <Profile employee={employee} />

      <BusinessTripDetails />

      <FlightTicket />

      <ExitReEntryVisa withEmployeeDetail />

      <Reason />
    </Flex>
  )
}

export default BusinessTripRequest
