export const GET_CANDIDATE_LIST = 'GET_CANDIDATE_LIST'
export const GET_CANDIDATE_LIST_PENDING = 'GET_CANDIDATE_LIST_PENDING'
export const GET_CANDIDATE_LIST_FULFILLED = 'GET_CANDIDATE_LIST_FULFILLED'
export const GET_CANDIDATE_LIST_REJECTED = 'GET_CANDIDATE_LIST_REJECTED'

export const SYNC_CANDIDATE_LIST = 'SYNC_CANDIDATE_LIST'
export const SYNC_CANDIDATE_LIST_PENDING = 'SYNC_CANDIDATE_LIST_PENDING'
export const SYNC_CANDIDATE_LIST_FULFILLED = 'SYNC_CANDIDATE_LIST_FULFILLED'
export const SYNC_CANDIDATE_LIST_REJECTED = 'SYNC_CANDIDATE_LIST_REJECTED'

export const GET_CANDIDATE_IMPORT_DATA = 'GET_CANDIDATE_IMPORT_DATA'
export const GET_CANDIDATE_IMPORT_DATA_PENDING = 'GET_CANDIDATE_IMPORT_DATA_PENDING'
export const GET_CANDIDATE_IMPORT_DATA_FULFILLED = 'GET_CANDIDATE_IMPORT_DATA_FULFILLED'
export const GET_CANDIDATE_IMPORT_DATA_REJECTED = 'GET_CANDIDATE_IMPORT_DATA_REJECTED'

export const WORKABLE_CONNECT = 'WORKABLE_CONNECT'
export const WORKABLE_CONNECT_PENDING = 'WORKABLE_CONNECT_PENDING'
export const WORKABLE_CONNECT_FULFILLED = 'WORKABLE_CONNECT_FULFILLED'
export const WORKABLE_CONNECT_REJECTED = 'WORKABLE_CONNECT_REJECTED'

export const SHOW = 'SHOW'
export const SHOW_PENDING = 'SHOW_PENDING'
export const SHOW_FULFILLED = 'SHOW_FULFILLED'
export const SHOW_REJECTED = 'SHOW_REJECTED'

export const CLEAR_CANDIDATE_DATA = 'CLEAR_CANDIDATE_DATA'

export const WORKABLE_RESET_FORM_DATA = 'WORKABLE_RESET_FORM_DATA'
