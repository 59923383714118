import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { AccountType } from 'components/global/hooks/useIsDemoAccount'

export type InfoType = {
  id?: number
  name_en?: string
  name_ar?: string
  name_i18n?: string
}

export type ContactInformationType = {
  id?: number
  organization_id?: number
  website?: string
  admin_email?: string
  contact_person?: string
  contact_number?: number
  po_box?: string
  postal_code?: string
  fax?: string
}

export type LogoType = {
  file_name?: string
  file_path?: string
}

export type OrganizationType = {
  address1?: string
  address1_ar?: string
  address1_i18n?: string
  brand_name?: string
  brand_name_ar?: string
  brand_name_i18n?: string
  city?: InfoType
  contact_information?: ContactInformationType | Record<string, unknown>
  country?: InfoType
  currency_type_i18n?: string
  currency_type_value?: number
  domain?: string
  has_onboarding_workflow?: boolean
  identifier?: number
  industry?: InfoType
  located_countries?: string
  located_countries_i18n?: string
  logo?: LogoType
  medical_insurance_provider?: {
    id?: number
    name_i18n?: string
  }
  metadata?: unknown
  name?: string
  name_ar?: string
  name_i18n?: string
  organization_type?: number
  organization_type_i18n?: string
  region?: InfoType
  bank_info?: Record<string, unknown>
  account_type?: AccountType
}

export type OrganizationState = {
  organization: OrganizationType
  organization_currency: {
    currency_type_i18n: string
  }
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
  fetchingLogo: boolean
  fetchingContactInfo: boolean
  fetchingProfile: boolean
}
export type OrganizationInfo = {
  organization: OrganizationType
  currency_type_i18n: string
}

const DEFAULT_STATE: OrganizationState = {
  organization: { logo: {}, contact_information: {}, bank_info: {} },
  succMsg: null,
  fetching: false,
  fetchingLogo: false,
  fetchingContactInfo: false,
  fetchingProfile: false,
  organization_currency: {
    currency_type_i18n: '',
  },
  errMsg: null,
}
type RequestsState = typeof DEFAULT_STATE

let changes = null
export default function organizationProfileReducer(
  state = DEFAULT_STATE,
  action: Action,
): RequestsState {
  switch (action.type) {
    case 'ORGANIZATION_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'ORGANIZATION_UPDATE_PENDING':
      return {
        ...state,
        errMsg: null,
        succMsg: null,
        fetchingProfile: true,
      }

    case 'ORGANIZATION_CONTACT_INFO_UPDATE_PENDING':
      return {
        ...state,
        errMsg: null,
        succMsg: null,
        fetchingContactInfo: true,
      }

    case 'ORGANIZATION_LOGO_UPDATE_PENDING':
      return {
        ...state,
        errMsg: null,
        succMsg: null,
        fetchingLogo: true,
      }

    case 'ORGANIZATION_LOAD_REJECTED':
    case 'ORGANIZATION_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        fetchingProfile: false,
      }
      return { ...state, ...changes }

    case 'ORGANIZATION_CONTACT_INFO_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetchingContactInfo: false,
      }
      return { ...state, ...changes }

    case 'ORGANIZATION_LOGO_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(action.payload),
        fetchingLogo: false,
      }
      return { ...state, ...changes }

    case 'ORGANIZATION_LOAD_FULFILLED':
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
        fetchingProfile: false,
      } as RequestsState
      return { ...state, ...changes }

    case 'GET_ORGANIZATION_CURRENCY_TYPE_LOAD_FULFILLED':
      const { currency_type_i18n, currency_type } = action.payload.data.data?.organization
      return {
        ...state,
        organization_currency: {
          ...state.organization_currency,
          ...action.payload.data.data.organization,
          currency_type_i18n: currency_type === 'sar' ? '񥀠' : currency_type_i18n,
        },
      }

    case 'ORGANIZATION_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
        fetchingProfile: false,
      } as Partial<OrganizationState>

      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload.data.data,
        },
        ...changes,
      }

    case 'ORGANIZATION_CONTACT_INFO_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetchingContactInfo: false,
      } as Partial<OrganizationState>

      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload.data.data,
        },
        ...changes,
      }

    case 'ORGANIZATION_LOGO_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetchingLogo: false,
      } as Partial<OrganizationState>

      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload.data.data,
        },
        ...changes,
      }

    default:
      return state
  }
}
