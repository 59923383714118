import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'react-redux'
import { isEmpty, omit, omitBy } from 'lodash'
import {
  assetClearRequestsCreate,
  assetClearRequestsUpdate,
  assetsRequestCreate,
  assetsRequestUpdate,
} from 'redux/requests/assetRequests/actionCreators'
import { useFormState } from 'react-final-form'
import { AssetDetails, Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

const AssetRequest = () => {
  const dispatch = useDispatch()
  const {
    values: { request_type },
  } = useFormState()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { id, employee },
    drawerToggle,
    actionCallback,
    editRequest,
    setEditRequest,
  } = useRequest()

  const { loading, request } = useRequestDetail()

  const empId = id ?? request?.employee?.id

  const closeDrawer = () => {
    drawerToggle()
    if (actionCallback) {
      actionCallback()
    }
  }

  const handleAssetClearRequest = (values) => {
    const dataAssetClear = {
      ...values,
      asset_ids: values.asset_request_types_attributes?.map((asset_type_id) => asset_type_id),
      attachments: values?.attachments?.[0]?.data
        ? [...values?.attachments, ...(values?.deletedAttachments ?? [])]
        : values?.deletedAttachments ?? null,
    }
    delete dataAssetClear.asset_request_types_attributes
    delete dataAssetClear.request_type

    if (dataAssetClear.id) {
      dispatch(
        assetClearRequestsUpdate(empId, dataAssetClear.id, {
          ...dataAssetClear,
          attachments: dataAssetClear?.attachments?.map((it) => {
            if (typeof it.id === 'string') return omit(it, 'id')
            return it
          }),
        }),
      ).then(() => setEditRequest(false))
    } else {
      dispatch(
        assetClearRequestsCreate(empId, {
          ...dataAssetClear,
          attachments: dataAssetClear.attachments.map((it) => omit(it, 'id')),
        }),
      ).then(() => closeDrawer())
    }
  }

  const handleAssetRequest = (values) => {
    const dataAsset = {
      ...values,
      asset_request_types_attributes: values.asset_request_types_attributes?.map(
        (asset_type_id) => ({
          asset_type_id,
        }),
      ),
    }
    delete dataAsset.request_type

    if (dataAsset.id) {
      const newAddedAssets = dataAsset.asset_request_types_attributes.filter(
        (assetTypeAtt) =>
          !dataAsset.asset_request_types.some(
            (assetType) => assetType.asset_type_id === assetTypeAtt.asset_type_id,
          ),
      )
      const removedAssets = dataAsset.asset_request_types
        .filter(
          (assetType) =>
            !dataAsset.asset_request_types_attributes.some(
              (assetTypeAtt) => assetTypeAtt.asset_type_id === assetType.asset_type_id,
            ),
        )
        .map((obj) => ({ ...obj, _destroy: true }))

      const updatedAssetData = {
        ...(dataAsset.asset_request_types_attributes && {
          asset_request_types_attributes: [...newAddedAssets, ...removedAssets],
        }),
        attachments: values?.attachments?.[0]?.data
          ? [...values?.attachments, ...(values?.deletedAttachments ?? [])]
          : values?.deletedAttachments ?? null,
        reason: dataAsset?.reason,
      }
      dispatch(
        assetsRequestUpdate(empId, dataAsset.id, {
          ...omitBy(
            {
              ...updatedAssetData,
              attachments: updatedAssetData?.attachments?.map((it) => {
                if (typeof it.id === 'string') return omit(it, 'id')
                return it
              }),
            },
            (v) => v === null || isEmpty(v),
          ),
          reason: updatedAssetData?.reason,
        }),
      ).then(() => setEditRequest(false))
    } else {
      dispatch(
        assetsRequestCreate(id, {
          ...dataAsset,
          attachments: dataAsset?.attachments?.map((it) => omit(it, 'id')),
        }),
      ).then(() => closeDrawer())
    }
  }

  const onSubmit = (val) => {
    const currRequestType = val?.request_type || request_type

    if (currRequestType === 2) {
      handleAssetClearRequest(val)
    } else {
      handleAssetRequest(val)
    }
  }

  React.useEffect(() => {
    if (editRequest) {
      if (request.is_asset_clear) {
        setInitialValues({
          ...request,
          request_type: 2,
          asset_request_types_attributes: request.asset_ids,
          deletedAttachments: [],
        })
      } else {
        setInitialValues({
          ...request,
          request_type: 1,
          asset_request_types_attributes: request.asset_type_ids,
          deletedAttachments: [],
        })
      }
    } else {
      setInitialValues({
        request_type: 1,
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(() => {})
      setInitialValues({})
    }
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <AssetDetails />

      <Reason />
    </Flex>
  )
}

export default AssetRequest
