import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type InitialStateType = {
  fetching: boolean
  item_price:
    | {
        chargebee_item_price_id: string
        deleted_at?: string
        id: number
        item_price_type: string
        name: string
        name_ar: string
        price: string
        status: string
      }
    | Record<string, never>
}

const initialState: InitialStateType = {
  fetching: false,
  item_price: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.ITEM_PRICE_ONBOARDING_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ITEM_PRICE_ONBOARDING_LOAD_FULFILLED: {
      return {
        ...state,
        item_price: action.payload.data.data,
        fetching: false,
      }
    }

    case actions.ITEM_PRICE_ONBOARDING_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
