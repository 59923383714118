import ConfirmModal from 'containers/authorised/setting/v2/components/ConfirmModal'
import { Dispatch } from 'react'
import I18n from 'translations/i18n'
import { CommercialRegistration } from 'containers/public/Onboarding/types/onboardingTypes'
import { Typography } from '@jisr-hr/ds'

type ConfirmModalProps = {
  fetching: boolean
  isOpen: boolean
  item: CommercialRegistration
  onClose: Dispatch<React.SetStateAction<boolean>>
  handleConfirmDelete: (id: number) => void
  invoiceAlreadyGenerated: boolean
}

const ConfirmCR = ({
  item,
  fetching,
  isOpen,
  onClose,
  handleConfirmDelete,
  invoiceAlreadyGenerated,
}: ConfirmModalProps): JSX.Element => {
  return (
    <ConfirmModal
      variant="danger"
      fetching={fetching}
      labelConfirm="delete"
      isOpen={isOpen}
      onConfirm={(): void => handleConfirmDelete(item.id)}
      onCancel={(): void => onClose(false)}
      title={`${I18n.t('delete')} ${I18n.t('commercial_registration')}`}
      message={
        invoiceAlreadyGenerated ? (
          <>
            <Typography
              textColor="color/fg/bold"
              variant="interface/default/m"
              text={`${I18n.t('delete')}  ${I18n.t('commercial_registration')}`}
            />

            <Typography
              variant="body-new/regular"
              text={I18n.t(invoiceAlreadyGenerated ? 'confirm_message_mudad' : 'confirm_message', {
                name: item.name_i18n,
              })}
            />
          </>
        ) : (
          I18n.t('confirm_message', { name: item.name_i18n })
        )
      }
    />
  )
}

export default ConfirmCR
