export const ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC = 'ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC'
export const ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_FULFILLED =
  'ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_FULFILLED'
export const ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_REJECTED =
  'ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_REJECTED'
export const ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_PENDING =
  'ZOHO_BOOKS_ACCOUNT_TRANSACTION_SYNC_PENDING'

export const ZOHO_BOOKS_ACCOUNT_BULK_UPDATE = 'ZOHO_BOOKS_ACCOUNT_BULK_UPDATE'
export const ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_FULFILLED = 'ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_FULFILLED'
export const ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_REJECTED = 'ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_REJECTED'
export const ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_PENDING = 'ZOHO_BOOKS_ACCOUNT_BULK_UPDATE_PENDING'

export const ZOHO_BOOKS_ACCOUNTS_LOAD = 'ZOHO_BOOKS_ACCOUNTS_LOAD'
export const ZOHO_BOOKS_ACCOUNTS_LOAD_FULFILLED = 'ZOHO_BOOKS_ACCOUNTS_LOAD_FULFILLED'
export const ZOHO_BOOKS_ACCOUNTS_LOAD_REJECTED = 'ZOHO_BOOKS_ACCOUNTS_LOAD_REJECTED'
export const ZOHO_BOOKS_ACCOUNTS_LOAD_PENDING = 'ZOHO_BOOKS_ACCOUNTS_LOAD_PENDING'

export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD = 'ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_FULFILLED =
  'ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_FULFILLED'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_REJECTED = 'ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_REJECTED'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_PENDING = 'ZOHO_BOOKS_SPECIFIC_ACCOUNT_LOAD_PENDING'

export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE = 'ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_FULFILLED =
  'ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_FULFILLED'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_REJECTED =
  'ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_REJECTED'
export const ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_PENDING =
  'ZOHO_BOOKS_SPECIFIC_ACCOUNT_DELETE_PENDING'
