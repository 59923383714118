import { Badge, Button, Flex, Spacer, Typography } from '@jisr-hr/ds'
import { Tooltip, Dropdown } from '@jisr-hr/ds-beta'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import { handleSwitchSubsidiary } from 'components/global/HelperFunctions'
import { isRtl } from 'utils/uiHelpers'
import { ORGANISATION_NAME } from 'env'
import usePermissions from 'components/Navigation/usePermissions'
import { mixPanelAnalytics } from 'utils/mixpanel'

import styles from './AccountSwitcher.module.css'

const AccountSwitcher = (): JSX.Element => {
  const history = useHistory()
  const { manage_subsidiaries: canManageSubsidiaries } = usePermissions()

  const accounts = useSelector((s) => s.switchableOrganizations.accounts)
  const currentEmployee = useSelector(({ auth }) => auth.employee)

  const { pathname } = history.location

  const currentAccount = accounts.find(
    (account) => account.slug?.toLowerCase() === ORGANISATION_NAME?.toLowerCase(),
  )

  return (
    <Dropdown
      withPortal
      position={isRtl ? 'left-end' : 'right-end'}
      dropDownElement={
        <Tooltip
          description={currentAccount?.name_i18n}
          placement="right"
          trigger={
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: 8,
                overflow: 'hidden',
              }}
            >
              <img
                src={currentAccount?.logo}
                alt=""
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          }
        />
      }
    >
      <div className="py-3 px-4">
        <Typography
          variant="body-new/semibold"
          text={I18n.t('label.switch_organization')}
          textColor="color/fg/light"
          style={{ paddingInlineStart: 16 }}
        />
        <Spacer height={8} />
        {accounts.map((account, i) => (
          <Flex
            key={account.slug}
            itemsCenter
            justifyBetween
            className={classNames(styles.account, {
              [styles.selected]: account.slug?.toLowerCase() === ORGANISATION_NAME?.toLowerCase(),
            })}
            onClick={(): void => {
              if (account.slug?.toLowerCase() !== ORGANISATION_NAME?.toLowerCase()) {
                handleSwitchSubsidiary(account.slug)
                mixPanelAnalytics({
                  event_name: 'Organization_Switched',
                  properties: {
                    From_Organization: account?.name_i18n,
                    To_Organization: currentAccount?.name_i18n,
                    User_ID: currentEmployee?.id,
                  },
                })
              }
            }}
          >
            <Flex
              itemsCenter
              style={{ gap: 8 }}
            >
              <div
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 8,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={account.logo}
                  alt={account.name_i18n}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <Typography
                variant="subtitle-1"
                text={account.name_i18n}
                textColor="color/fg/light"
              />
            </Flex>
            {i === 0 && (
              <Badge
                variant="tinted"
                color="primary"
                size="small"
                label={I18n.t('label.main_organization')}
              />
            )}
          </Flex>
        ))}
        <Spacer height={8} />
        {canManageSubsidiaries && (
          <Button
            size="small"
            variant="tinted"
            color="neutral"
            leadingIcon="settings-01"
            onClick={(): void => {
              history.push('/setting_v2/organization/org_structure/sub_organizations')
              mixPanelAnalytics({
                event_name: 'Manage_Subsidiaries_Clicked',
                properties: {
                  User_ID: currentEmployee?.id,
                  Page_Location: pathname,
                },
              })
            }}
            className="w-full"
            label={I18n.t('label.manage_subsidiaries')}
          />
        )}
      </div>
    </Dropdown>
  )
}

export default AccountSwitcher
