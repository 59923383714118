import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

type FlightTicketsRequestWorkflow = {
  id: number
  name: string
  is_applicable_to_all: boolean
  is_default: boolean
  type_eligibility_by: string
  type_ids: number[]
  types: string[]
  type_eligibility_by_i18n: string
  workflow_steps: {
    id: number
    approver: string
    approver_i18n: string
    step_count: number
  }[]
  employee_ids: number[]
  number_of_employees: number
  number_of_approvals: number
}

type IntialStateType = {
  flight_tickets_request_workflows: FlightTicketsRequestWorkflow[]
  fetching: boolean
}

const initialState = {
  flight_tickets_request_workflows: [],
  fetching: false,
}

export default function reducer(state = initialState, action: Action): IntialStateType {
  const { payload } = action

  switch (action.type) {
    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD:
    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_FULFILLED:
      return {
        ...state,
        flight_tickets_request_workflows: payload?.data?.data?.flight_ticket_request_workflows,
        fetching: false,
      }

    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_FULFILLED:
    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOW_UPDATE_FULFILLED:
    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOW_DELETE_FULFILLED:
      Toastr.success(payload?.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOWS_LOAD_REJECTED:
    case actions.FLIGHT_TICKETS_REQUEST_WORKFLOW_CREATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
