import { PendingChangesType } from 'containers/authorised/employee/EmployeeDetails/Tabs/Personal/components/types'
import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function createEditProfileRequests(employee_id: number, data: PendingChangesType): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/info_change_requests`
  return {
    type: actions.CREATE_EDIT_PROFILE_REQUEST,
    payload: handleAPI(url, {}, 'POST', { info_change_request: data }),
  }
}

export function updateInfoChangeRequest(
  employee_id: number,
  id: number,
  data: PendingChangesType,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/info_change_requests/${id}`
  return {
    type: actions.UPDATE_INFO_CHANGE_REQUEST,
    payload: handleAPI(url, {}, 'PUT', { info_change_request: data }),
  }
}
