import { useState, MouseEvent } from 'react'
import { Divider, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { DatePicker } from '@jisr-hr/ds-beta'
import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

type DueDateT = { filters: string[]; title: string; filterKey: string }

const DueDate = (props: DueDateT): JSX.Element => {
  const { filters, title, filterKey } = props
  const { filter, setFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onChange = (val: string, field: string): void => {
    setFilter({
      ...filter,
      due_date: {
        ...filter?.due_date,
        [field]: val,
      },
    })
  }

  const handleClear = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setFilter({
      ...filter,
      due_date: undefined,
    })
  }

  return (
    <div>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={filter?.due_date?.from || filter?.due_date?.to}
          handleClearFilter={handleClear}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title="label.due_date"
        />
        {!isCollapsed && (
          <Flex className="gap-[8px] items-center">
            <DatePicker
              textFieldProps={{
                testId: 'from-date',
                showClearIcon: false,
              }}
              triggerType="input"
              label={i18n.t('from')}
              value={filter?.due_date?.from ? new Date(filter?.due_date?.from) : undefined}
              onChange={(val: string | string[]): void => onChange(val as string, 'from')}
              calenderProps={{
                type: 'single',
                maxDate: new Date(),
              }}
            />
            <DatePicker
              label={i18n.t('to')}
              value={filter?.due_date?.to ? new Date(filter?.due_date?.to) : undefined}
              onChange={(val: string | string[]): void => onChange(val as string, 'to')}
              triggerType="input"
              textFieldProps={{
                showClearIcon: false,
              }}
              calenderProps={{
                type: 'single',
                minDate: filter?.due_date?.from ? new Date(filter?.due_date?.from) : new Date(),
              }}
            />
          </Flex>
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

export default DueDate
