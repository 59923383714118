export const SUBSIDIARIES_LOAD = 'SUBSIDIARIES_LOAD'
export const SUBSIDIARIES_LOAD_PENDING = 'SUBSIDIARIES_LOAD_PENDING'
export const SUBSIDIARIES_LOAD_FULFILLED = 'SUBSIDIARIES_LOAD_FULFILLED'
export const SUBSIDIARIES_LOAD_REJECTED = 'SUBSIDIARIES_LOAD_REJECTED'

export const SUBSIDIARY_LOAD = 'SUBSIDIARY_LOAD'
export const SUBSIDIARY_LOAD_PENDING = 'SUBSIDIARY_LOAD_PENDING'
export const SUBSIDIARY_LOAD_FULFILLED = 'SUBSIDIARY_LOAD_FULFILLED'
export const SUBSIDIARY_LOAD_REJECTED = 'SUBSIDIARY_LOAD_REJECTED'

export const SUBSIDIARY_DELETE = 'SUBSIDIARY_DELETE'
export const SUBSIDIARY_DELETE_PENDING = 'SUBSIDIARY_DELETE_PENDING'
export const SUBSIDIARY_DELETE_FULFILLED = 'SUBSIDIARY_DELETE_FULFILLED'
export const SUBSIDIARY_DELETE_REJECTED = 'SUBSIDIARY_DELETE_REJECTED'

export const SUBSIDIARY_CREATE = 'SUBSIDIARY_CREATE'
export const SUBSIDIARY_CREATE_PENDING = 'SUBSIDIARY_CREATE_PENDING'
export const SUBSIDIARY_CREATE_FULFILLED = 'SUBSIDIARY_CREATE_FULFILLED'
export const SUBSIDIARY_CREATE_REJECTED = 'SUBSIDIARY_CREATE_REJECTED'

export const SUBSIDIARY_UPDATE = 'SUBSIDIARY_UPDATE'
export const SUBSIDIARY_UPDATE_PENDING = 'SUBSIDIARY_UPDATE_PENDING'
export const SUBSIDIARY_UPDATE_FULFILLED = 'SUBSIDIARY_UPDATE_FULFILLED'
export const SUBSIDIARY_UPDATE_REJECTED = 'SUBSIDIARY_UPDATE_REJECTED'

export const SUBSIDIARY_NOTIFICATION_LOAD = 'SUBSIDIARY_NOTIFICATION_LOAD'
export const SUBSIDIARY_NOTIFICATION_LOAD_PENDING = 'SUBSIDIARY_NOTIFICATION_LOAD_PENDING'
export const SUBSIDIARY_NOTIFICATION_LOAD_FULFILLED = 'SUBSIDIARY_NOTIFICATION_LOAD_FULFILLED'
export const SUBSIDIARY_NOTIFICATION_LOAD_REJECTED = 'SUBSIDIARY_NOTIFICATION_LOAD_REJECTED'

export const SUBSIDIARY_EMPLOYEES_ASSIGN = 'SUBSIDIARY_EMPLOYEES_ASSIGN'
export const SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING = 'SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING'
export const SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED = 'SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED'
export const SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED = 'SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED'

export const RESET_SUBSIDIARY_DATA = 'RESET_SUBSIDIARY_DATA'
