export const OFFBOARDINGWORKFLOW_LOAD = 'OFFBOARDINGWORKFLOW_LOAD'
export const OFFBOARDINGWORKFLOW_LOAD_PENDING = 'OFFBOARDINGWORKFLOW_LOAD_PENDING'
export const OFFBOARDINGWORKFLOW_LOAD_FULFILLED = 'OFFBOARDINGWORKFLOW_LOAD_FULFILLED'
export const OFFBOARDINGWORKFLOW_LOAD_REJECTED = 'OFFBOARDINGWORKFLOW_LOAD_REJECTED'

export const OFFBOARDINGWORKFLOW_DELETE = 'OFFBOARDINGWORKFLOW_DELETE'
export const OFFBOARDINGWORKFLOW_DELETE_PENDING = 'OFFBOARDINGWORKFLOW_DELETE_PENDING'
export const OFFBOARDINGWORKFLOW_DELETE_FULFILLED = 'OFFBOARDINGWORKFLOW_DELETE_FULFILLED'
export const OFFBOARDINGWORKFLOW_DELETE_REJECTED = 'OFFBOARDINGWORKFLOW_DELETE_REJECTED'

export const OFFBOARDINGWORKFLOW_CHANGE = 'OFFBOARDINGWORKFLOW_CHANGE'
export const OFFBOARDINGWORKFLOW_CHANGE_FULFILLED = 'OFFBOARDINGWORKFLOW_CHANGE_FULFILLED'
export const OFFBOARDINGWORKFLOW_CHANGE_REJECTED = 'OFFBOARDINGWORKFLOW_CHANGE_REJECTED'

export const OFFBOARDINGWORKFLOW_PUT = 'OFFBOARDINGWORKFLOW_PUT'
export const OFFBOARDINGWORKFLOW_PUT_PENDING = 'OFFBOARDINGWORKFLOW_PUT_PENDING'
export const OFFBOARDINGWORKFLOW_PUT_FULFILLED = 'OFFBOARDINGWORKFLOW_PUT_FULFILLED'
export const OFFBOARDINGWORKFLOW_PUT_REJECTED = 'OFFBOARDINGWORKFLOW_PUT_REJECTED'
