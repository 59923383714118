import React from 'react'
import PropTypes from 'prop-types'
import attachment from 'assets/images/attachment.png'
import { Flex } from 'components/global/atoms'

const DownloadAttachment = ({ url, name }) => (
  <Flex className="download-attachment">
    <img
      src={attachment}
      alt=""
    />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      download
    >
      {name}
    </a>
  </Flex>
)

DownloadAttachment.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
}

export default DownloadAttachment
