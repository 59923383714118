export const SECURITY_POLICIES_LOAD = 'SECURITY_POLICIES_LOAD'
export const SECURITY_POLICIES_LOAD_PENDING = 'SECURITY_POLICIES_LOAD_PENDING'
export const SECURITY_POLICIES_LOAD_FULFILLED = 'SECURITY_POLICIES_LOAD_FULFILLED'
export const SECURITY_POLICIES_LOAD_REJECTED = 'SECURITY_POLICIES_LOAD_REJECTED'

export const SECURITY_POLICIES_CREATE = 'SECURITY_POLICIES_CREATE'
export const SECURITY_POLICIES_CREATE_PENDING = 'SECURITY_POLICIES_CREATE_PENDING'
export const SECURITY_POLICIES_CREATE_FULFILLED = 'SECURITY_POLICIES_CREATE_FULFILLED'
export const SECURITY_POLICIES_CREATE_REJECTED = 'SECURITY_POLICIES_CREATE_REJECTED'

export const EMPLOYEES_WITHOUT_EMAILS_LOAD = 'EMPLOYEES_WITHOUT_EMAILS_LOAD'
export const EMPLOYEES_WITHOUT_EMAILS_LOAD_PENDING = 'EMPLOYEES_WITHOUT_EMAILS_LOAD_PENDING'
export const EMPLOYEES_WITHOUT_EMAILS_LOAD_FULFILLED = 'EMPLOYEES_WITHOUT_EMAILS_LOAD_FULFILLED'
export const EMPLOYEES_WITHOUT_EMAILS_LOAD_REJECTED = 'EMPLOYEES_WITHOUT_EMAILS_LOAD_REJECTED'
