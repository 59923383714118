import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
  exitReentryVisaRequest: {},
}

export default function exitReentryVisaRequestReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.EXIT_REENTRY_VISA_REQUEST_LOAD_PENDING:
    case actions.CREATE_EXIT_REENTRY_VISA_REQUEST_PENDING:
    case actions.UPDATE_EXIT_REENTRY_VISA_REQUEST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EXIT_REENTRY_VISA_REQUEST_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        delegation: action.payload.data.data.ExitReentryVisaRequest,
      }

    case actions.CREATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED:
    case actions.UPDATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    case actions.CREATE_EXIT_REENTRY_VISA_REQUEST_REJECTED:
    case actions.UPDATE_EXIT_REENTRY_VISA_REQUEST_REJECTED:
    case actions.EXIT_REENTRY_VISA_REQUEST_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
