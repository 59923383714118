import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Employee } from 'redux/authUser/types'
import * as actions from './actions'

export type TOrganizationConfiguration = {
  id?: number
  name?: string
  value?: null | boolean
  is_applicable?: boolean
  is_all_employee_selected?: boolean
  number_of_employees?: number
  employees?: Employee[]
}

type TInitialState = {
  leave_resumption: TOrganizationConfiguration
  apply_in_negative: TOrganizationConfiguration
  fetching: boolean
}

export const initialState: TInitialState = {
  fetching: false,
  apply_in_negative: {},
  leave_resumption: {},
}

export default function reducer(
  state = initialState,
  { type, payload }: Action,
): typeof initialState {
  switch (type) {
    case actions.ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_PENDING:
    case actions.ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_PENDING:
    case actions.ONBOARDING_APPLYING_LEAVES_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_FULFILLED:
      return {
        ...state,
        apply_in_negative: payload.data.data?.organization_configuration,
        fetching: false,
      }

    case actions.ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_FULFILLED:
      return {
        ...state,
        leave_resumption: payload.data.data?.organization_configuration,
        fetching: false,
      }

    case actions.ONBOARDING_APPLYING_LEAVES_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_APPLYING_LEAVES_EGATIVE_LOAD_REJECTED:
    case actions.ONBOARDING_APPLYING_LEAVES_RESUMPTION_LOAD_REJECTED:
    case actions.ONBOARDING_APPLYING_LEAVES_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
