export const ADDITION_DEDUCTION_LOAD = 'ADDITION_DEDUCTION_LOAD'
export const ADDITION_DEDUCTION_LOAD_FULFILLED = 'ADDITION_DEDUCTION_LOAD_FULFILLED'
export const ADDITION_DEDUCTION_LOAD_REJECTED = 'ADDITION_DEDUCTION_LOAD_REJECTED'

export const ADDITION_CREATE = 'ADDITION_CREATE'
export const ADDITION_CREATE_PENDING = 'ADDITION_CREATE_PENDING'
export const ADDITION_CREATE_FULFILLED = 'ADDITION_CREATE_FULFILLED'
export const ADDITION_CREATE_REJECTED = 'ADDITION_CREATE_REJECTED'

export const DEDUCTION_CREATE = 'DEDUCTION_CREATE'
export const DEDUCTION_CREATE_PENDING = 'DEDUCTION_CREATE_PENDING'
export const DEDUCTION_CREATE_FULFILLED = 'DEDUCTION_CREATE_FULFILLED'
export const DEDUCTION_CREATE_REJECTED = 'DEDUCTION_CREATE_REJECTED'
