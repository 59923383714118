import { useContext, useEffect, useState } from 'react'
import { Select } from '@jisr-hr/design-system'
import { Flex, Spacer, Typography, ButtonGroup } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { required } from 'components/global/form/FormValidations'
import { format } from 'utils/date'
import { addMonths } from 'date-fns'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import {
  payschedulesSelector,
  loadingSelector,
} from 'redux/payrollOnboarding/PaySchedules/selectors'
import { useDispatch, useSelector } from 'utils/hooks'
import { isEmpty } from 'lodash'
import { ReactComponent as QuestionIcon } from 'assets/figma-icons/question-circle.svg'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import {
  paySchedulesLoad,
  paySchedulesUpdate,
} from 'redux/payrollOnboarding/PaySchedules/actionsCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

import styles from './styles.module.css'

type TSelectDate = {
  label: string
  value: string | null
} | null

const PaySchedules = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { require_pincode } = useSelector(({ organization }) => organization.organization)
  const { cut_off_date } = useSelector(payschedulesSelector)
  const loading = useSelector(loadingSelector)
  const { employeeDate } = useSelector((s) => s.organization)
  const { setShowSidebar } = useContext(OnboardingContext)

  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  const currentDate = format(employeeDate, 'MMMM yyyy')
  const nextDate = format(addMonths(employeeDate, 1), 'MMMM yyyy')

  const cutOffMonth = cut_off_date && format(cut_off_date, 'M')
  const currentMonth = format(employeeDate, 'M')

  const activeButton = selectedMonth || +cutOffMonth || +currentMonth

  const selectedDatePiker = selectedDate ?? cut_off_date
  const rangeOfCutoff = Array.from({ length: 28 }).map((_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }))
  const value: TSelectDate = selectedDate
    ? { value: selectedDate, label: selectedDate }
    : rangeOfCutoff.find((i) => i.value === format(selectedDatePiker, 'd')) || null

  const buttonsList = [
    {
      label: I18n.t('this_month_date', { date: currentDate }) || '',
      value: +currentMonth,
    },
    {
      label: I18n.t('next_month_date', { date: nextDate }) || '',
      value: +currentMonth + 1,
    },
  ]

  const handelNext = (): void => {
    dispatch(
      paySchedulesUpdate({
        period: +currentMonth === +activeButton ? 'this_month' : 'next_month',
        cut_off_day: value?.value,
      }),
    ).then(() => {
      goNextStep?.()
      dispatch(organizationInfo())
    })
  }

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'pay_schedules' })

  useEffect(() => {
    dispatch(paySchedulesLoad())
  }, [])

  return (
    <StepTemplate
      goNextStep={handelNext}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNextBtn={!selectedDatePiker || isEmpty(value)}
      fetching={loading}
    >
      <Typography
        variant="heading"
        text={I18n.t('pay_schedules')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t(require_pincode ? 'cutoff_date_desc' : 'pay_schedules_desc')}
      />

      {!require_pincode && (
        <>
          <Spacer height={32} />

          <div className={styles.sectionContainer}>
            <Typography
              variant="interface/default/m"
              text={I18n.t('payroll_month')}
            />

            <Spacer height={8} />
            <Typography
              variant="interface/default/m"
              style={{ color: 'var(--color-base-colors-black-500)' }}
              text={I18n.t('payroll_month_desc', { app_name: I18n.t('jisr_translation_name') })}
            />

            <Spacer height={16} />
            <ButtonGroup
              selected={activeButton}
              list={buttonsList}
              onSelectChange={(val): void => {
                if (typeof val === 'number') {
                  setSelectedMonth(val)
                }
              }}
            />
          </div>
        </>
      )}
      <Spacer height={32} />
      <div className={styles.sectionContainer}>
        <Flex
          itemsCenter
          style={{ gap: 16 }}
        >
          <Typography
            variant="interface/default/m"
            text={I18n.t('cutoff_date')}
          />

          <QuestionIcon
            className={styles.questionIcon}
            onClick={(): void => setShowSidebar((prev) => !prev)}
          />
        </Flex>
        <Spacer height={8} />
        {!require_pincode && (
          <Typography
            variant="subtitle-1"
            style={{ color: 'var(--color-base-colors-black-500)' }}
            text={I18n.t('cutoff_date_desc', { app_name: I18n.t('jisr_translation_name') })}
          />
        )}
        <Spacer height={16} />
        <Select
          options={rangeOfCutoff}
          style={{ maxWidth: 250 }}
          maxMenuHeight={130}
          onChange={(v: TSelectDate): void => {
            if (!v?.value) return
            setSelectedDate(v?.value)
          }}
          placeholder={I18n.t('select_cutoff_date')}
          validate={required}
          value={value}
        />
      </div>
    </StepTemplate>
  )
}
export default PaySchedules
