export const ONBOARDING_EMPLOYEES_LOAD = 'ONBOARDING_EMPLOYEES_LOAD'
export const ONBOARDING_EMPLOYEES_LOAD_PENDING = 'ONBOARDING_EMPLOYEES_LOAD_PENDING'
export const ONBOARDING_EMPLOYEES_LOAD_FULFILLED = 'ONBOARDING_EMPLOYEES_LOAD_FULFILLED'
export const ONBOARDING_EMPLOYEES_LOAD_REJECTED = 'ONBOARDING_EMPLOYEES_LOAD_REJECTED'

export const ONBOARDING_ALL_EMPLOYEES_LOAD = 'ONBOARDING_ALL_EMPLOYEES_LOAD'
export const ONBOARDING_ALL_EMPLOYEES_LOAD_PENDING = 'ONBOARDING_ALL_EMPLOYEES_LOAD_PENDING'
export const ONBOARDING_ALL_EMPLOYEES_LOAD_FULFILLED = 'ONBOARDING_ALL_EMPLOYEES_LOAD_FULFILLED'
export const ONBOARDING_ALL_EMPLOYEES_LOAD_REJECTED = 'ONBOARDING_ALL_EMPLOYEES_LOAD_REJECTED'

export const ONBOARDING_ADMEN_EMPLOYEES_LOAD = 'ONBOARDING_ADMEN_EMPLOYEES_LOAD'
export const ONBOARDING_ADMEN_EMPLOYEES_LOAD_PENDING = 'ONBOARDING_ADMEN_EMPLOYEES_LOAD_PENDING'
export const ONBOARDING_ADMEN_EMPLOYEES_LOAD_FULFILLED = 'ONBOARDING_ADMEN_EMPLOYEES_LOAD_FULFILLED'
export const ONBOARDING_ADMEN_EMPLOYEES_LOAD_REJECTED = 'ONBOARDING_ADMEN_EMPLOYEES_LOAD_REJECTED'

export const ONBOARDING_EMPLOYEES_PUT = 'ONBOARDING_EMPLOYEES_PUT'
export const ONBOARDING_EMPLOYEES_PUT_PENDING = 'ONBOARDING_EMPLOYEES_PUT_PENDING'
export const ONBOARDING_EMPLOYEES_PUT_FULFILLED = 'ONBOARDING_EMPLOYEES_PUT_FULFILLED'
export const ONBOARDING_EMPLOYEES_PUT_REJECTED = 'ONBOARDING_EMPLOYEES_PUT_REJECTED'

export const ONBOARDING_EMPLOYEES_POST = 'ONBOARDING_EMPLOYEES_POST'
export const ONBOARDING_EMPLOYEES_POST_PENDING = 'ONBOARDING_EMPLOYEES_POST_PENDING'
export const ONBOARDING_EMPLOYEES_POST_FULFILLED = 'ONBOARDING_EMPLOYEES_POST_FULFILLED'
export const ONBOARDING_EMPLOYEES_POST_REJECTED = 'ONBOARDING_EMPLOYEES_POST_REJECTED'

export const ONBOARDING_GRADES_GET_ALL_LOAD = 'ONBOARDING_GRADES_GET_ALL_LOAD'
export const ONBOARDING_GRADES_GET_ALL_LOAD_PENDING = 'ONBOARDING_GRADES_GET_ALL_LOAD_PENDING'
export const ONBOARDING_GRADES_GET_ALL_LOAD_FULFILLED = 'ONBOARDING_GRADES_GET_ALL_LOAD_FULFILLED'
export const ONBOARDING_GRADES_GET_ALL_LOAD_REJECTED = 'ONBOARDING_GRADES_GET_ALL_LOAD_REJECTED'

export const ONBOARDING_EMPLOYEES_BULK_DELETE = 'ONBOARDING_EMPLOYEES_BULK_DELETE'
export const ONBOARDING_EMPLOYEES_BULK_DELETE_PENDING = 'ONBOARDING_EMPLOYEES_BULK_DELETE_PENDING'
export const ONBOARDING_EMPLOYEES_BULK_DELETE_FULFILLED =
  'ONBOARDING_EMPLOYEES_BULK_DELETE_FULFILLED'
export const ONBOARDING_EMPLOYEES_BULK_DELETE_REJECTED = 'ONBOARDING_EMPLOYEES_BULK_DELETE_REJECTED'
