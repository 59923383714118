import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function onboardingValidateAdminsInvitationLoad(
  params: Record<string, unknown> = {},
  hideMsg = false,
): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization/validate_admins_invitation`

  return {
    type: hideMsg
      ? actions.ONBOARDING_VALIDATION_ADMIN_INVITED_HIDE_MSG_LOAD
      : actions.ONBOARDING_VALIDATION_ADMIN_INVITED_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function resetStates(): Action {
  return {
    type: actions.RESET_STATES,
  }
}
