import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'

import Frame from '../../ds/Frame'
import Record from './Record'
import Styles from './RecordChanges.module.css'

import { Status, Deductable, AllowedShortageAndDelay } from './customRecords'

const excludeRecords = ['status_i18n', 'deductable', 'allowed_delay', 'allowed_shortage']

const RecordChanges = ({ record_changes }) => (
  <Frame
    title={I18n.t('record_changes')}
    description={I18n.t('changes_on_attendance_record_if_request_got_approved')}
    active={false}
    collapse
  >
    <Flex
      flexCol
      className={Styles.records}
    >
      {record_changes?.status_i18n && <Status data={record_changes.status_i18n} />}
      {record_changes?.deductable && <Deductable data={record_changes.deductable} />}
      {record_changes?.allowed_delay && (
        <AllowedShortageAndDelay
          title="allowed_delay"
          data={record_changes?.allowed_delay}
        />
      )}
      {record_changes?.allowed_shortage && (
        <AllowedShortageAndDelay
          title="allowed_shortage"
          data={record_changes?.allowed_shortage}
        />
      )}

      {Object.keys(record_changes).map((key, _i) => {
        if (!excludeRecords.includes(key) && record_changes[key]?.from !== undefined) {
          return (
            <Record
              key={_i}
              title={key}
              label={record_changes[key]?.from}
              value={record_changes[key]?.to}
            />
          )
        }

        return ''
      })}
    </Flex>
  </Frame>
)

RecordChanges.propTypes = {
  record_changes: PropTypes.shape(),
}

export default RecordChanges
