export const GET_EMPLOYEE_NOTIFICATIONS_SETTING = 'GET_EMPLOYEE_NOTIFICATIONS_SETTING'
export const GET_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING =
  'GET_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING'
export const GET_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED =
  'GET_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED'
export const GET_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED =
  'GET_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED'

export const UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING = 'UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING'
export const UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING =
  'UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING'
export const UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED =
  'UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED'
export const UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED =
  'UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED'
