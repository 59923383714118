import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { Tree } from 'react-organizational-chart'
import { FeaturedIcon, Flex, Spacer, Typography } from '@jisr-hr/ds'
import DSIcon from 'components/DSIcon'
import { Department } from 'redux/newDepartments/types'
import { useSelector } from 'utils/hooks'

import styles from '../style.module.css'
import Node from './Node'
import { scrollOptions } from './helper'
import { useDepartmentsContext } from './context'
import OrgCard from './OrgCard'

type PropsRoot = {
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

type Props = {
  departments: Department[]
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

const Root = ({ setIsOpen }: PropsRoot): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const { hierarchical_departments, organization } = useSelector((s) => s.newDepartments)
  const { organization: onboardingOrg } = useSelector((s) => s.organization)
  const { isInOnboarding, search, isNewOnboarding } = useDepartmentsContext()
  const orgName = isInOnboarding ? onboardingOrg.name_i18n : organization?.name_i18n

  useEffect(() => {
    if (isEmpty(search)) {
      ref.current?.scrollIntoView(scrollOptions)
    }
  }, [hierarchical_departments])

  return isNewOnboarding ? (
    <OrgCard
      ref={ref}
      setIsOpen={setIsOpen}
      orgName={orgName}
    />
  ) : (
    <div
      className={styles.card}
      style={{
        height: 'fit-content',
        paddingBottom: '12px',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
      ref={ref}
    >
      <div className={styles.root_card_header}>
        <Flex style={{ padding: 15 }}>
          <FeaturedIcon
            colorType="brand"
            iconName="building-04"
            size="md"
          />
        </Flex>
        <Spacer height={4} />
        <Flex
          flexCol
          itemsStart
          justifyCenter
          style={{ padding: '0 20px' }}
        >
          <Typography
            text={orgName}
            textColor="color/fg/light"
            variant="title-1"
          />
        </Flex>
      </div>
      <Spacer height={8} />
      {!isInOnboarding && (
        <Flex
          itemsCenter
          style={{ padding: '6px 21px' }}
        >
          <DSIcon
            name="users-02"
            styles={{ color: 'var(--ds-base-color-content-on-neutral-fg)' }}
          />
          <Spacer width={4} />
          <Typography
            text={organization?.active_employees_count}
            textColor="color/fg/light"
            variant="body-new/regular"
          />
          <Spacer width={15} />
          <DSIcon
            name="department"
            styles={{ color: 'var(--ds-base-color-content-on-neutral-fg)' }}
          />
          <Spacer width={4} />
          <Typography
            text={organization?.departments_count}
            textColor="color/fg/light"
            variant="body-new/regular"
          />
        </Flex>
      )}
    </div>
  )
}

const Chart = ({ departments, setIsOpen }: Props): JSX.Element => (
  <Tree
    lineHeight="50px"
    lineWidth="1px"
    lineColor="rgb(8 15 54 / 24%);"
    lineBorderRadius="30px"
    label={<Root setIsOpen={setIsOpen && setIsOpen} />}
  >
    {departments.map((d: Department, i: number) => (
      <Node
        data={d}
        key={i}
      />
    ))}
  </Tree>
)

export default Chart
