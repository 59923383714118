import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
// import { filter } from './helper';

export type Employee = {
  id: number
  code: string
  name: string
  job_title_i18n: string
  avatar_thumb: string
  department: {
    id: number
    name: string
    name_ar: string
    name_i18n: string
    department_path: number[]
    subtree_ids: number[]
  }
  is_deleted: boolean
  isSelected: boolean
}

type EligibleEmployeesState = {
  fetching: boolean
  employee_all: Employee[]
  employee_ids: number[]
  eligible_employees: Employee[]
  employees_count: null | number
  errMsg: null | string
}

const initialState: EligibleEmployeesState = {
  fetching: false,
  employee_all: [],
  employee_ids: [],
  eligible_employees: [],
  employees_count: null,
  errMsg: null,
}

export default function reducer(state = initialState, action: Action): EligibleEmployeesState {
  const { payload } = action
  switch (action.type) {
    case actions.ELIGIBLE_EMPLOYESS_LOAD_PENDING:
    case actions.FILTER_ELIGIBLE_EMPLOYESS_LOAD_PENDING:
    case actions.GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_FULFILLED:
      const employee_ids = state.employee_all.filter((i) => i.isSelected).map((i) => i.id)

      return {
        ...state,
        employee_all: action.payload.data.data.employees?.map((i: Employee) => {
          return {
            ...i,
            isSelected: employee_ids.includes(i.id),
          }
        }),
        fetching: false,
      }

    case actions.ELIGIBLE_EMPLOYESS_LOAD_FULFILLED:
      const employeeIds = state.eligible_employees.filter((i) => i.isSelected).map((i) => i.id)
      return {
        ...state,
        eligible_employees: action.payload.data.data.employees?.map((i: Employee) => {
          return {
            ...i,
            isSelected: employeeIds.includes(i.id),
          }
        }),
        employees_count: action.payload.data.data.employees_count,
        fetching: false,
      }

    case actions.FILTER_ELIGIBLE_EMPLOYESS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ELIGIBLE_EMPLOYESS_LOAD_REJECTED:
    case actions.FILTER_ELIGIBLE_EMPLOYESS_LOAD_REJECTED:
    case actions.GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case actions.SELECT_USER:
      return {
        ...state,
        employee_all: state.employee_all,
        employee_ids: [...state.employee_ids, payload.employee.id],
        eligible_employees: state.eligible_employees,
      }

    case actions.UNSELECT_USER:
      return {
        ...state,
        employee_all: state.employee_all,
        employee_ids: state.employee_ids.filter((i) => i !== payload.employee.id),
        eligible_employees: state.eligible_employees,
      }

    case actions.RESET_USERS:
      return {
        ...state,
        employee_ids: [],
      }

    case actions.SET_SELECTED:
      const employee_all = state.employee_all.map((i) => {
        const data = payload.employees as Employee[]
        const employee = data.find((emp) => emp.id === i.id)
        return {
          ...i,
          isSelected: !!employee?.id,
        }
      })

      const eligible_employees = state.eligible_employees.map((i) => {
        const { id } = payload.employees.find((e: Employee) => e.id === i.id) || {}
        return {
          ...i,
          isSelected: !!id,
        }
      })

      return {
        ...state,
        employee_all,
        employee_ids: payload.employees.map((i: Employee) => i.id),
        eligible_employees,
      }

    default:
      return state
  }
}
