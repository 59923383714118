import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'
import classNames from 'classnames'
import JisrIcon from 'assets/images/jisr-icon-logo-new.svg'
import I18n from 'translations/i18n'

import DSLoadingIndicator from 'components/DSLoadingIndicator'
import styles from './styles.module.scss'

export const Loader = ({ absolute = false, fixed = false }) => (
  <div
    className={classNames(
      styles.root,
      absolute && styles.absoluteContainer,
      fixed && styles.fixedContainer,
    )}
  >
    <div className={styles.content}>
      <DSLoadingIndicator
        size="lg"
        data-testid="jisr-Loader"
      />
    </div>
  </div>
)

const JisrLoader = ({ title, absolute, fixed }) => (
  <div
    data-testid="jisr-Loader"
    className={classNames(
      'jisr-loader',
      'animated',
      absolute && 'jisr-loader--absolute',
      fixed && 'jisr-loader--fixed',
    )}
  >
    <div className="d-flex align-items-center flex-column">
      <div className="icon-loader">
        <i className="loader" />

        <img
          src={JisrIcon}
          alt="Jisr"
        />
      </div>
      <Typography
        text={title || I18n.t('loading')}
        variant="title-1"
      />
    </div>
  </div>
)

JisrLoader.propTypes = {
  title: PropTypes.node,
  absolute: PropTypes.bool,
  fixed: PropTypes.bool,
}

export default JisrLoader
