import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Popper } from '@jisr-hr/design-system'
import { Button } from '@jisr-hr/ds'

import Logs from './Logs'

import Styles from '../ProposedCorrectionTime.module.css'

const ExistingPunch = ({ handleChange, isInNotRecorded, ...rest }) => {
  const { attendance_logs } = useSelector(({ requestDetails }) => requestDetails)
  const { attendance_record } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )

  const record = attendance_record?.[0]

  const punchList = _.groupBy(attendance_logs, 'date')

  return attendance_logs?.length > 0 ? (
    <Popper
      className={classNames(
        Styles.existingPopper,
        record?.employee_shifts?.length > 1 ? Styles.popperWidth : undefined,
      )}
      placement="bottom-end"
      trigger={
        <Button
          variant="outlined"
          color="neutral"
          label={I18n.t('select_punch')}
          size="medium"
          leadingIcon="fingerprint-03"
          trailingIcon="chevron-right"
          disabled={isInNotRecorded}
        />
      }
    >
      {Object.entries(punchList).map(([date, logs]) => (
        <Logs
          {...rest}
          key={date}
          date={date}
          punchList={logs}
          onClick={handleChange}
          isMoreShift={record?.employee_shifts?.length > 1}
        />
      ))}
    </Popper>
  ) : (
    <></>
  )
}

ExistingPunch.propTypes = {
  isInNotRecorded: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default ExistingPunch
