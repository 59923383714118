const Auth = {
  // A
  automate_your_hr_operations_and_focus_on_your_business:
    'أتمتة عمليات الموارد البشرية لتركزّ على<br /> نمو شركتك',
  attendance: 'الحضور و الغياب',
  activate_modules: 'تفعيل وحدات {{name}}',
  activate_the_features: 'قم بتفعيل الميزات التي ترغب في تمكينها في تجربة {{name}} الخاصة بك',
  // B
  back_to_login: 'العودة لصفحة التسجيل',

  brand_name_en_new_onboarding: 'اسم العلامة التجارية (الإنجليزية)',
  brand_name_ar_new_onboarding: 'اسم العلامة التجارية (عربي)',
  building_workspace: 'بناء مساحة عمل {{orgName}}',
  // C
  continue_new_onboarding: 'متابعة',
  code_valid_until: '<b>رمز التحقق</b> صالح لمدة <b>{{time}}</b> دقيقة.',
  confirm_password: 'تأكيد كلمة المرور الجديدة',
  continue_slug: 'متابعة',
  current_password: 'كلمة المرور الحالية',
  password_complexity_note:
    'يجب أن تستخدم كلمات المرور ثلاثة على الأقل من أنواع الأحرف الأربعة المتاحة ، وهي <b>أحرف صغيرة</b> و<b>أحرف كبيرة</b> و<b>أرقام</b> و<b>رموز</b>. <br /><br /> لايمكن أن تحتوي كلمة المرور على <b>الاسم الأول</b> أو <b>اسم العائلة</b> أو <b>عنوان البريد الإلكتروني للمستخدم</b>.',
  minimum_password_length_note: 'كلمة المرور يجب ان تكون ٨ أحرف كحد أدنى.',
  create_your_account: 'إنشاء حسابك',
  title_create_workspace: 'إنشاء مساحة عمل',
  choose_organization_workspace_name: 'اختر اسم مساحة العمل',
  create_profile: 'Create Profile :AR',
  create_workspace: 'إنشاء مساحة العمل',
  complete_company_profile: 'استكمال الملف التعريفي للشركة',
  create_workspace_with_slug: 'انشئ مساحة عمل {{slug}}',
  completing_first_step:
    'تهانينا على إكمال خطوتك الأولى نحو تحويل شركتك لتصبح ذات طابع  رقمي أكثر. ,أنت الآن جاهز لإكمال بيانات شركتك للبدء في استخدام {{name}}',
  ceo_new_onboarding: 'محمد عكار، الرئيس التنفيذي لشركة {{name}}',
  // D
  didn_recieve_an_email: 'لم يصلك البريد الإلكتروني؟',
  // E
  enter_six_digit_code: 'أدخل رمز التحقق المكون من ٦ أرقام',
  email: 'البريد الإلكتروني',
  ex_name: 'مثال: أحمد محمد علي',
  error_message_new_onboarding: 'يجب أن يكون حجم الملف 10 ميغابايت أو أقل',
  enter_work_email_address: 'أدخل عنوان البريد الإلكتروني للموظف',
  // F
  forgot_password: ' هل نسيت كلمة المرور؟',
  // G
  google: 'Google',
  get_started_new_onboarding: 'ابدأ مرحلة الإعدادات',
  // H
  hr_management: 'إدارة الموارد البشرية',
  // I
  industry_new_onboarding: 'الصناعة',
  import_your_employee_records: 'قم بإدخال سجلات موظفيك إلى النظام لعرضها على {{name}}',
  invite_more_admins: 'دعوة المزيد من المشرفين',
  invite_more_admins_des:
    'يمكن للمشرفين مساعدتك في إعداد مساحة العمل الخاصة بك. ,يمكنك تغيير أذونات المشرفين لاحقًا',
  invited_admins: 'المشرفون المدعوون',
  invite_more_admins_to_help_you_with_completing:
    'قم بدعوة المزيد من المشرفين لمساعدتك في استكمال بيانات مساحة عمل {{slug}}',
  // J
  jisr_login_authentication: 'مصادقة تسجيل الدخول لنظام {{name}}',
  job_role: 'الدور الوظيفي',
  jisrhr_en: 'Ex: {{slug}}',
  jisrhr_ar: 'مثال: {{slug}} ',
  onboarding_list: 'قائمة اعدادت {{name}}',
  // K
  keep_me_logged_in_for_30_days: 'حفظ الدخول لمدة ٣٠ يوم',
  // L
  login: 'تسجيل الدخول',
  login_using_email_address: 'تسجيل الدخول بعنوان البريد الإلكتروني',
  login_using_organization_name: 'تسجيل الدخول بإسم المؤسسة',
  login_six_digit_code_note:
    'تم إرسال  رمز التحقق المكون من 6 أرقام على بريدك الإلكتروني. من فضلك ، تحقق منه وأدخل رمزًا مكونًا من ستة أرقام أدناه',
  login_with_office365: 'Microsoft',
  leaves_and_attendance: 'الإجازات والحضور',
  leaves_and_attendance_des: 'إدارة بصمات دخول وخروج الموظفين ومتابعة الإجازات',
  least_characters: 'ما لا يقل عن ٨ أحرف',
  least_one_number: 'رقم واحد',
  loading_message: 'جاري رفع الشعار الآن',
  // M
  map_out_your_company_is_structure:
    'قم بإنشاء الهيكل التنظيمي لشركتك من خلال تحديد مواقع المؤسسة وأقسامها',
  modules_will_appear_here_gradually: 'سوف تظهر  وحدات {{app_name}} تدريجيا هنا',
  modules_will_appear_here_once_you_finish_entering:
    'ستظهر وحدات {{app_name}} هنا بمجرد الانتهاء من إدخال البيانات المطلوبة',
  // N
  new_password: 'كلمة المرور الجديدة',
  // O
  organization_name_en_new_onboarding: 'اسم المؤسسة (بالانجليزية)',
  organization_name_ar_new_onboarding: 'اسم المؤسسة (بالعربي)',
  OR: 'أو',
  organization_logo_new_onboarding: ' شعار المؤسسة (اختياري)',
  or_drag_and_drop_new_onboarding: 'أو السحب والإفلات',
  organization_workspace_name: 'اسم مساحة عمل المؤسسة',
  // P
  password: 'كلمة المرور',
  provide_essential_details: 'املأ التفاصيل الأساسية عن شركتك لتخصيص تجربتك',
  payroll_new_onboarding: 'كشوف المرتبات',
  payroll_new_onboarding_des: 'إدارة مرتبات الموظفين',
  // Q
  // R
  reports_and_more: 'تقارير مفصلة وأكثر',
  resend_new_password_creation_link: 'إعادة إرسال رابط إنشاء كلمة المرور',
  resend_new_code: 'إعادة إرسال رمز جديد',
  replace: 'استبدال',
  required_data: 'أكمل البيانات المطلوبة',
  requests_and_automation: 'الطلبات والأتمتة',
  requests_and_automation_des:
    'أتمتة المهام الروتينية، خصص وقتك وجهدك للعمليات الاستراتيجية وحقق أفضل النتائج',
  reinvite: 'إعادة ارسال الدعوة',
  // S
  submit: 'إرسال',
  slug: 'إسم الشركة',
  select_organization: 'اختر المنشأة',
  sso_login: 'تسجيل الدخول الأحادي {{name}}',
  sso_disabled_err: 'لم تتمكن من تسجيل الدخول، مؤسستك لم تقم بتفعيل تسجيل الدخول الموحد (SSO).',
  select_which_service: 'اختر اي الخدمات تريد ان تستخدمها في {{app_name}}',
  success_message: 'تم رفع الشعار بنجاح',
  // T
  tour_step: '{{step}} من {{steps}}',
  two_factor_verification: 'تأكيد المصادقة الثنائية',
  time_session_out: 'تحديد وقت إنتهاء الجلسة',
  time_session_out_description:
    'سيتم تسجيل الخروج من الحساب  بعد 4 ساعات في حالة عدم وجود أي إجراء في الصفحة.',
  try_again: 'أرسل مرة أخرى',
  tell_usa_bit_about_yourself: 'أخبرنا قليلاً عن نفسك',
  type_image:
    'يجب ان تكون صيغة الملف  SVG أو PNG أوJPG و يصل حجمها بحد أقصى إلى 10 ميجا بايت و على الأقل 200 × 200 بكسل',
  this_will_be_your_url_workspace_within_jisr: 'ستكون هذه رابط مساحة العمل الخاص بك داخل {{name}}',
  // U
  user_name_login: 'اسم المستخدم',
  upload_organization_logo: ' تحميل شعار المؤسسة ',
  upload_employees_data: 'تحميل بيانات الموظفين',
  update_structure: 'تعديل الهيكل التنظيمي',
  // V
  visit_our_knowledgebase: 'قم بزيارة قاعدة المعرفة الخاصة بنا',
  // W
  welcome_onboard: 'مرحبًا بك يا {{clintName}}، لنقم بإنشاء مساحة عمل الممؤسسة',
  what_would_you_like_to_jisr: 'ما هي شركة التأمين الطبي التي تعمل معها؟',
  welcome_to: 'مرحبًا بك في {{name}} 👋',
  // X
  // Y
  your_account_is_connected_with_two_distinct_organizations:
    'اتضح أن حسابك متصل بمنشأتين مختلفتين، من فضلك اختر المنشأة التي تود الدخول إليها',
  exceeded_tallowed_attempts:
    'استنفذت عدد المحاولات المسموحة للدخول لحسابك، يمكنك تغيير كلمة المرور لتنشيط الحساب.',
  your_email: 'بريدك الإلكتروني',
  your_password_must_contain: 'يجب أن تحتوي كلمة المرور الخاصة بك على',
  // Z

  application: {
    login: 'Login for {{name}}',
  },
  date: {
    long: 'MMMM Do, YYYY',
  },
  two_lines: 'Line 1<br />Line 2',
}

export default Auth
