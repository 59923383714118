import React from 'react'
import { SelectField, TextInputField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import { Spacer } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { letterTypesLoad } from 'redux/actions/letterTypesActions'
import { useDispatch, useSelector } from 'react-redux'

import Frame from '../../../ds/Frame'

const LetterDetails = () => {
  const dispatch = useDispatch()
  const { letter_types } = useSelector(({ letterTypes }) => letterTypes)

  React.useEffect(() => {
    dispatch(letterTypesLoad())
  }, [])

  return (
    <Frame title={I18n.t('letter_details')}>
      <SelectField
        name="letter_type_id"
        testId="letter-type-id"
        valueKey="id"
        labelKey="name_i18n"
        label={I18n.t('type')}
        validate={required}
        options={letter_types}
      />
      <Spacer height={16} />
      <TextInputField
        name="directed_to_en"
        testId="directed-to-en"
        label={I18n.t('directed_to_en')}
      />
      <Spacer height={16} />
      <TextInputField
        name="directed_to_ar"
        testId="directed-to-ar"
        label={I18n.t('directed_to_ar')}
      />
    </Frame>
  )
}

export default LetterDetails
