import { FC } from 'react'
import { Button } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'

type CloneOrgDetailPropsT = {
  handleClick: () => void
  fetching: boolean
}

const CloneOrgDetail: FC<CloneOrgDetailPropsT> = (props) => {
  const { fetching } = props

  const isSubOrg = useSelector(
    ({ organization }) => organization?.organization?.is_organization_subsidiary,
  )

  if (!isSubOrg) return null

  return (
    <Button
      label={i18n.t('label.import_from_main_organization')}
      onClick={props.handleClick}
      variant="filled"
      size="small"
      leadingIcon="download-01"
      disabled={fetching}
    />
  )
}

export default CloneOrgDetail
