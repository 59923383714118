import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const InfoText = ({ label, color }) => (
  <Typography
    text={label}
    variant="body-new/medium"
    style={{ color }}
  />
)

InfoText.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
}

export default InfoText
