import React, { useState } from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import WorkflowDetails from './WorkflowDetails'
import WorkflowEmployees from './WorkflowEmployees'

const styles = {
  btn: {
    color: 'var(--color-brand-primary-default-new)',
    cursor: 'pointer',
    marginTop: '10px',
    display: 'block',
    background: '#fff',
    padding: '20px',
    borderTop: '1px solid #ededed',
  },
}

const MenuList = ({ onSelected }) => {
  const [panel, setPanel] = useState(1)
  return (
    <div style={{ width: '200px' }}>
      {panel === 1 && <WorkflowDetails onSelected={onSelected} />}
      {panel === 2 && <WorkflowEmployees onSelected={onSelected} />}

      {panel === 1 && (
        <span
          data-testid="show-workflow-employee"
          onClick={() => setPanel(2)}
          style={styles.btn}
        >
          {I18n.t('add_approver_employee')}
        </span>
      )}

      {panel === 2 && (
        <span
          data-testid="show-workflow-details"
          onClick={() => setPanel(1)}
          style={styles.btn}
        >
          {I18n.t('back')}
        </span>
      )}
    </div>
  )
}

MenuList.propTypes = {
  onSelected: PropTypes.func,
}

export default MenuList
