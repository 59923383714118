import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Flex, Typography } from '@jisr-hr/ds'

import Styles from './AttendanceRecord.module.css'

const colors = {
  red: 'var(--color-base-colors-red-800)',
  green: 'var(--color-base-colors-blue-800)',
  black: 'var(--color-base-colors-black-500)',
}

const Record = ({ label, value, description, color, badge, badgeType }) => (
  <div className={Styles.record}>
    <Flex style={{ gap: '10px' }}>
      <Typography
        text={value || '--'}
        variant="interface/strong/m"
        style={{ color: colors[color] || colors.blac }}
      />
      {badge && (
        <Badge
          label={badge}
          size="medium"
          color={badgeType}
          variant="tinted"
        />
      )}
    </Flex>

    {description && (
      <Typography
        text={description}
        variant="body-new/regular"
        textColor="color/fg/danger/bold"
      />
    )}
    <Typography
      text={label}
      variant="body-new/regular"
      textColor="color/fg/light"
    />
  </div>
)

Record.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  description: PropTypes.string,
  color: PropTypes.string,
  badge: PropTypes.string,
  badgeType: PropTypes.string,
}

export default Record
