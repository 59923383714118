import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { HTTPValidationError } from 'types/httpTypes'
import {
  PayGroupParams,
  PayGroupResponse,
  PayGroupsResponse,
  PayGroupUpdatePayloadT,
  PayGroupUpdateResponse,
  PayGroupCreatePayloadT,
  PaygroupsLoadParamsT,
} from './usePaygroups.type'

// ? functions
const payGroupsLoad = (params?: PaygroupsLoadParamsT): Promise<PayGroupsResponse> => {
  return api({
    url: `/${API_VERSION_TWO}/finance/paygroups`,
    method: 'GET',
    params,
  })
}

const payGroupLoad = (id: number): Promise<PayGroupResponse> => {
  return api({
    url: `/${API_VERSION_TWO}/finance/paygroups/${id}`,
    method: 'GET',
  })
}

const createPayGroup = (data: PayGroupCreatePayloadT): Promise<PayGroupResponse> => {
  return api({
    url: `/${API_VERSION_TWO}/finance/paygroups/`,
    method: 'POST',
    data,
  })
}

const updatePaygroup = (payload: PayGroupUpdatePayloadT): Promise<PayGroupUpdateResponse> => {
  return api.put(`/${API_VERSION_TWO}/finance/paygroups/${payload.pay_group_id}`, payload.data)
}

export const payGroupsKey = (params?: PaygroupsLoadParamsT): QueryKey => ['pay_groups_list', params]
export const payGroupKey = (id?: number): QueryKey => ['pay_group_detail', id]

// ? hooks
/**
 * @endpoint: GET: /v2/finance/paygroups
 * @summary This hook used to load all available pay groups
 */
export const usePayGroupsLoad = <TData = PayGroupsResponse, TError = HTTPValidationError>(opts?: {
  query?: UseQueryOptions<PayGroupsResponse, TError, TData>
  params?: PaygroupsLoadParamsT
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? payGroupsKey(opts?.params),
    queryFn: () => payGroupsLoad(opts?.params),
  })
}

/**
 * @endpoint: POST: /v2/finance/paygroups
 * @summary This hook used to create new pay group
 */
export const useCreatePayGroup = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<PayGroupUpdateResponse, TError, PayGroupCreatePayloadT>
}): UseMutationResult<PayGroupUpdateResponse, TError, PayGroupCreatePayloadT> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (data) => createPayGroup(data),
    onSuccess: (data, variables, context) => {
      if (data.data.message) Toastr.success(data.data.message)
      opts?.mutation?.onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      if (error.response?.data.error) Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}

/**
 * @endpoint: GET: /v2/finance/paygroups/:id
 * @summary This hook used to load details of specific pay group
 */
export const usePayGroupLoad = <TData = PayGroupResponse, TError = HTTPValidationError>(
  params: PayGroupParams,
  opts?: {
    query?: UseQueryOptions<PayGroupResponse, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? payGroupKey(params.id),
    queryFn: () => payGroupLoad(params.id),
  })
}

/**
 * @endpoint: PUT: /v2/finance/paygroups/:id
 *
 * @summary This hook used to update payrun configurations
 */
export const useUpdatePaygroup = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<PayGroupUpdateResponse, TError, PayGroupUpdatePayloadT>
}): UseMutationResult<PayGroupUpdateResponse, TError, PayGroupUpdatePayloadT> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (data) => updatePaygroup(data),
    onSuccess: (data, variables, context) => {
      if (data.data.message) Toastr.success(data.data.message)
      opts?.mutation?.onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      if (error.response?.data.error) Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
