import { useEffect, useState } from 'react'
import { ScrollBarContainer, SideDrawer } from '@jisr-hr/ds-beta'

import i18n from 'translations/i18n'
import { omitBy } from 'lodash'
import { useDispatch, useSelector } from 'utils/hooks'
import { notificationsLoad, toggleNotificationsPanel } from 'redux/appNotifications/actionCreators'
import {
  markAllAsRead,
  unreadNotificationsLoad,
} from 'redux/appNotifications/markAsRead/actionCreators'

import NotificationsPanel from './NotificationsPanel'
import Notifications from './Notifications'
import { TTab } from './types'

export default function AppNotifications(): JSX.Element {
  const dispatch = useDispatch()
  const isNotificationsOpen = useSelector(({ appNotifications }) => appNotifications.isOpen)
  const { unread: isUnread, fetching: fetchingUnread } = useSelector(({ markAsRead }) => markAsRead)

  const [activeTab, setActiveTab] = useState<TTab>({
    label: i18n.t('all'),
    key: 'all',
    value: 0,
    testId: 'all',
  })

  const [filters, setFilters] = useState<{ page: number; rpp: number; old_notifications: boolean }>(
    {
      page: 1,
      rpp: 10,
      old_notifications: false,
    },
  )

  const fetchData = (): void => {
    const pureFilters = omitBy(filters, (v) => v === false)
    dispatch(
      notificationsLoad({
        all: true,
        category: activeTab?.category,
        ...pureFilters,
      }),
    )
    dispatch(unreadNotificationsLoad())
  }
  useEffect(() => {
    dispatch(unreadNotificationsLoad())
  }, [])

  return (
    <>
      <SideDrawer
        active={isNotificationsOpen}
        header={{
          withClose: true,
          title: i18n.t('notifications'),
          actions: [
            {
              label: i18n.t('mark_all_as_read'),
              onClick: () => dispatch(markAllAsRead()).then(fetchData),
              disabled: !isUnread || fetchingUnread,
            },
          ],
        }}
        onClose={(): void => {
          dispatch(toggleNotificationsPanel(false))
        }}
        zIndex={1000}
        size="wide"
      >
        <ScrollBarContainer
          height="100vh"
          className="px-6 py-3"
        >
          <NotificationsPanel
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            filters={filters}
            setFilters={setFilters}
          />
        </ScrollBarContainer>
      </SideDrawer>
      <Notifications />
    </>
  )
}
