export const GET_API_PERMISSIONS_STATUS = 'GET_API_PERMISSIONS_STATUS'
export const GET_API_PERMISSIONS_STATUS_PENDING = 'GET_API_PERMISSIONS_STATUS_PENDING'
export const GET_API_PERMISSIONS_STATUS_FULFILLED = 'GET_API_PERMISSIONS_STATUS_FULFILLED'
export const GET_API_PERMISSIONS_STATUS_REJECTED = 'GET_API_PERMISSIONS_STATUS_REJECTED'

export const ALLOW_APP_API_INTEGRATION = 'ALLOW_APP_API_INTEGRATION'
export const ALLOW_APP_API_INTEGRATION_PENDING = 'ALLOW_APP_API_INTEGRATION_PENDING'
export const ALLOW_APP_API_INTEGRATION_FULFILLED = 'ALLOW_APP_API_INTEGRATION_FULFILLED'
export const ALLOW_APP_API_INTEGRATION_REJECTED = 'ALLOW_APP_API_INTEGRATION_REJECTED'

export const DENY_APP_API_INTEGRATION = 'DENY_APP_API_INTEGRATION'
export const DENY_APP_API_INTEGRATION_PENDING = 'DENY_APP_API_INTEGRATION_PENDING'
export const DENY_APP_API_INTEGRATION_FULFILLED = 'DENY_APP_API_INTEGRATION_FULFILLED'
export const DENY_APP_API_INTEGRATION_REJECTED = 'DENY_APP_API_INTEGRATION_REJECTED'
