import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { isRtl } from 'utils/uiHelpers'

const theme = createMuiTheme({
  direction: isRtl ? 'rtl' : 'ltr',
  typography: {
    fontFamily: isRtl ? 'Poppins' : 'SST Arabic',
  },
})

type MuiThemeProviderProps = {
  children: JSX.Element
}

const MuiThemeProvider = ({ children }: MuiThemeProviderProps): JSX.Element | null => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

MuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MuiThemeProvider
