import React from 'react'
import ContentLoader from 'react-content-loader'
import { isRtl } from 'utils/uiHelpers'

const NavigationLoader = () => (
  <div className="loaderContainer">
    <div className="level1Container">
      <ContentLoader
        rtl={isRtl}
        width={64}
        height={400}
        viewBox="0 0 87 400"
        speed={1}
        primaryColor="#D1CFCF"
        secondaryColor="#f1f3f5"
      >
        <rect
          x="18"
          y="40"
          rx="6"
          ry="6"
          width="40"
          height="40"
        />
        <circle
          cx="40"
          cy="130"
          r="18"
        />
        <circle
          cx="40"
          cy="190"
          r="18"
        />
        <circle
          cx="40"
          cy="250"
          r="18"
        />
      </ContentLoader>
      <div className="bottomCircle">
        <ContentLoader
          rtl={isRtl}
          width={64}
          height={80}
          viewBox="0 0 87 80"
          speed={1}
          primaryColor="#e6e6e6"
          secondaryColor="#f1f3f5"
        >
          <circle
            cx="40"
            cy="30"
            r="18"
          />
        </ContentLoader>
      </div>
    </div>
    <div className="level2Container">
      <ContentLoader
        rtl={isRtl}
        width={390}
        height={400}
        viewBox="0 0 390 400"
        speed={1}
        primaryColor="#e6e6e6"
        secondaryColor="#f1f3f5"
      >
        <rect
          x="33"
          y="71"
          rx="6"
          ry="6"
          width="40"
          height="40"
        />
        <rect
          x="33"
          y="141"
          rx="6"
          ry="6"
          width="40"
          height="40"
        />

        <rect
          x="96"
          y="83"
          rx="6"
          ry="6"
          width="171"
          height="17"
        />
        <rect
          x="96"
          y="153"
          rx="6"
          ry="6"
          width="171"
          height="17"
        />
      </ContentLoader>
    </div>
  </div>
)

export default NavigationLoader
