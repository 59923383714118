import { Action } from 'types/redux'
import { API_VERSION_TWO } from 'utils/apiUtils'
import { performanceAPI } from 'utils/performanceUtils'
import { TaskSubmitType } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/ReviewForms/types'

import * as actions from './actions'

export function assignEmployeesEvaluation(
  id: number | string,
  params: {
    employee_ids?: number[]
    with_following_form?: boolean
    ineligible_employee_ids?: number[]
  },
): Action {
  const url = `/${API_VERSION_TWO}/forms/${id}/assign_employees`

  return {
    type: actions.ASSIGN_EMPLOYEES_EVALUATION,
    payload: performanceAPI(url, {}, 'PUT', params),
  }
}

export function getReviewers(params?: { filter: { form_id: number } }): Action {
  const url = `/${API_VERSION_TWO}/employee_form_reviews/reviewers`

  return {
    type: actions.EMPLOYEES_REVIEWS,
    payload: performanceAPI(url, params, 'GET'),
  }
}

export function getFormDetails(id: number): Action {
  const url = `/${API_VERSION_TWO}/forms/${id}/details`

  return {
    type: actions.GET_EVALUATION_DETAILS,
    payload: performanceAPI(url, {}, 'GET'),
  }
}

export function removeEmployeesReviewForm(id: number): Action {
  const url = `/${API_VERSION_TWO}/employee_form_reviews/${id}`

  return {
    type: actions.REMOVE_EMPLOYEE_REVIEW_FROM,
    payload: performanceAPI(url, {}, 'DELETE'),
  }
}

export function requestEvaluationReviewForm(id: number): Action {
  const url = `/${API_VERSION_TWO}/employee_form_reviews/${id}/reopen`

  return {
    type: actions.REMOVE_EMPLOYEE_REVIEW_FROM,
    payload: performanceAPI(url, {}, 'PUT'),
  }
}

export function getGroupDetails(id: number): Action {
  const url = `/${API_VERSION_TWO}/groups/${id}`

  return {
    type: actions.GET_GROUP_DETAILS,
    payload: performanceAPI(url, {}, 'GET'),
  }
}

export function saveTaskChanges(data: TaskSubmitType, groupId?: number): Action {
  const url = `/${API_VERSION_TWO}/groups/${groupId}/criterions/bulk_import`

  return {
    type: actions.SAVE_TASK,
    payload: performanceAPI(url, {}, 'POST', data),
  }
}

export function resetTask(): Action {
  return {
    type: actions.RESET_TASK,
  }
}
