import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { workflowLoad } from 'redux/actions/workflowActions'

const HOC = (WrappedComponent) => {
  class WorkflowDetails extends React.Component {
    componentDidMount() {
      this.props.workflowLoad()
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  WorkflowDetails.propTypes = {
    workflowLoad: PropTypes.func,
  }

  const mapStateToProps = ({ workflowDetails }) => workflowDetails

  return connect(mapStateToProps, {
    workflowLoad,
  })(WorkflowDetails)
}

export default HOC
