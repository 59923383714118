import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'

export type FiltersType = {
  search: string
  filter: {
    department_ids?: number[]
    location_id?: number[]
    is_gosi_linked?: boolean[]
  }
}

export type NoAttendancePolicyUpdateType = {
  added_ids: number[]
  removed_ids: (number | undefined)[]
}

type Department = {
  id: number
  name: string
  name_ar: string
  name_i18n: string
  department_path: number[]
  subtree_ids: number[]
}

type Location = {
  id: number
  address_en: string
  address_ar: string
  address_i18n: string
}

type Area = {
  id: number
  name: string
  name_ar: string
  name_i18n: string
}

export type AttendanceExemptionPolicy = {
  id: number
  code: string
  name: string
  name_i18n: string
  avatar_thumb: string
  job_title_i18n: string | null
  department: Department
  location: Location
  area: Area
}

type DefaultStateType = {
  fetching: boolean
  attendance_exemption_policy: { [key: number]: AttendanceExemptionPolicy }
  employee_ids: number[]
}

const DEFAULT_STATE: DefaultStateType = {
  fetching: false,
  attendance_exemption_policy: {},
  employee_ids: [],
}

let changes: Partial<DefaultStateType> = {}
export default function noAttendancePolicyReducer(
  state = DEFAULT_STATE,
  action: Action,
): DefaultStateType {
  const { payload } = action

  switch (action.type) {
    case 'NO_ATTENDANCE_POLICY_LOAD_PENDING':
      return { ...state, ...changes, fetching: true }

    case 'NO_ATTENDANCE_POLICY_LOAD_FULFILLED':
      changes = {
        attendance_exemption_policy: {
          ...payload.data.data,
          employee_ids: payload.data.data.map((em: AttendanceExemptionPolicy) => em.id),
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'NO_ATTENDANCE_POLICY_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'NO_ATTENDANCE_POLICY_UPDATE_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'NO_ATTENDANCE_POLICY_LOAD_REJECTED':
    case 'NO_ATTENDANCE_POLICY_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return { ...state, ...changes, fetching: false }

    default:
      return state
  }
}
