import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@jisr-hr/ds'

const CorrectionBadge = ({ label, type, icon }) => (
  <Badge
    label={label}
    size="medium"
    {...(icon && { leadingIcon: icon })}
    color={type}
    variant="tinted"
  />
)

CorrectionBadge.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
}

export default CorrectionBadge
