import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import { updateOneListOfRecordsByFindIndex } from 'containers/authorised/setting/v2/payroll/Payables/components/helper'
import { AdditionTransactionType } from '../payType/reduecer'
import * as actions from './actions'

type InitStateAction = {
  deduction_types: AdditionTransactionType[]
  fetching: boolean
  loading: boolean
}

const initialState: InitStateAction = {
  fetching: false, // pending for get
  loading: false, // will be for the update and create and delete op
  deduction_types: [],
}

export default function reducer(state = initialState, action: Action): InitStateAction {
  const { payload } = action
  switch (action.type) {
    case actions.DUDCTIONS_TYPES_CREATE_PENDING:
    case actions.DUDCTIONS_TYPES_DELETE_PENDING:
    case actions.DUDCTIONS_TYPES_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.DUDCTIONS_TYPES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.DUDCTIONS_TYPES_LOAD_FULFILLED:
      const { payroll_transaction_types } = payload.data.data
      return {
        ...state,
        deduction_types: payroll_transaction_types,
        fetching: false,
      }

    case actions.DUDCTIONS_TYPES_CREATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      const payrollManualTxnHead = payload.data.data.payroll_transaction_type
      const deductionTypes = [...state.deduction_types, payrollManualTxnHead]
      return {
        ...state,
        loading: false,
        deduction_types: deductionTypes,
      }
    }

    case actions.DUDCTIONS_TYPES_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      const payrollManualTxnHead = payload.data.data
        .payroll_transaction_type as AdditionTransactionType

      updateOneListOfRecordsByFindIndex(state.deduction_types, payrollManualTxnHead)

      return {
        ...state,
        loading: false,
        deduction_types: [...state.deduction_types],
      }
    }

    case actions.DUDCTIONS_TYPES_DELETE_FULFILLED: {
      const removedId = payload.id
      let deductionTypes = [...state.deduction_types]
      deductionTypes = removeOneListOfRecords(deductionTypes, removedId)
      Toastr.success(payload.data.message)
      return {
        ...state,
        loading: false,
        deduction_types: deductionTypes,
      }
    }

    case actions.DUDCTIONS_TYPES_CREATE_REJECTED:
    case actions.DUDCTIONS_TYPES_DELETE_REJECTED:
    case actions.DUDCTIONS_TYPES_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
