import { Action } from 'types/redux'
import { handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export const locationLoad = (params = {}): Action => {
  const url = '/v2/onboarding/locations'

  return {
    type: actions.LOCATIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const locationDetailsShow = (id: number, params = {}): Action => {
  const url = `/v2/location/${id}`

  return {
    type: actions.LOCATIONS_SHOW,
    payload: handleAPI(url, params, 'GET'),
  }
}

export const locationCreate = (body = {}): Action => {
  const url = '/v2/onboarding/locations'

  return {
    type: actions.ONBOARDING_LOCATIONS_POST,
    payload: handleAPI(url, {}, 'POST', body),
  }
}

export const locationUpdate = (id: number, data: any): Action => {
  const url = `/v2/onboarding/locations/${id}`
  return {
    type: actions.ONBOARDING_LOCATIONS_PUT,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export const locationDelete = (id: number): Action => {
  const url = `/v2/onboarding/locations/${id}`

  return {
    type: actions.ONBOARDING_LOCATIONS_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export const cloneParentOrgLocations = (): Action => {
  const url = '/v2/onboarding/locations/clone'

  return {
    type: actions.CLONE_PARENT_ORG_LOCATIONS,
    payload: handleAPI(url, {}, 'POST'),
  }
}
