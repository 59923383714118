import {
  ActivityFeed,
  ActivityFeedDataTypes,
  ActivityItemType,
  FeaturedIconColorType,
  FileInfoBox,
  Flex,
  Spacer,
} from '@jisr-hr/ds-beta'
import { format } from 'utils/date'

import {
  TAnnouncement,
  TAnnouncementAttachment,
} from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg.type'

interface AnnouncementWidgetBodyProps {
  announcement: TAnnouncement
}

const AnnouncementWidgetBody = ({ announcement }: AnnouncementWidgetBodyProps) => {
  const timelineData = announcement?.comments?.map((it) => ({
    date: it.display_time,
    items: [
      {
        time: format(it.created_at, 'dd MMM, yyyy') as string,
        avatarProps: {
          imageSrc: it.employee.avatar_thumb ?? '',
        },
        header: {
          title: it.employee.name,
        },
        comment: {
          text: it.content,
        },
        variant: 'inline' as unknown as ActivityItemType,
        iconProps: {
          iconName: 'message-circle-01',
          colorType: 'success' as FeaturedIconColorType,
        },
      },
    ],
  }))
  return (
    <div>
      {announcement?.attachments?.map((attachment: TAnnouncementAttachment, index: number) => (
        <div key={index}>
          {attachment?.content_type === 'image/png' ? (
            <img
              className="h-[294px] w-[712px] borde-radius-[12px] object-contain"
              src={attachment?.url}
              alt={attachment.name}
            />
          ) : (
            <>
              <FileInfoBox
                key={index}
                file={{
                  status: 'success',
                  type: attachment.content_type,
                  name: attachment.name,
                  preview: attachment.url,
                  url: attachment.url,
                }}
                withPreview
                onHandleDownload={() => {
                  window.open(attachment.url, '_blank')
                }}
              />
              <Spacer height={12} />
            </>
          )}
        </div>
      ))}
      <Flex style={{ display: 'contents' }}>
        <ActivityFeed
          timePosition="start"
          data={timelineData as unknown as ActivityFeedDataTypes[]}
        />
      </Flex>
    </div>
  )
}

export default AnnouncementWidgetBody
