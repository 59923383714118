import axios, { AxiosRequestConfig } from 'axios'
import Qs from 'qs'
import errorResponseHandler from 'utils/errorHandler'
import { PERFORMANCE_ROOT, ORGANISATION_NAME } from '../env'
import { serializerConfig } from './axiosInstance'

const headersInfo: AxiosRequestConfig['headers'] = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  locale: localStorage.getItem('Locale') || 'en',
  slug: ORGANISATION_NAME,
  token: localStorage.getItem('onboarding_token'),
}

export const PerformanceApi = axios.create({
  baseURL: PERFORMANCE_ROOT,
  withCredentials: true,
  headers: headersInfo,
  paramsSerializer: (params) => {
    if (params?.search) params.search = encodeURIComponent(params.search)
    return Qs.stringify(params, serializerConfig)
  },
})

PerformanceApi.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: ORGANISATION_NAME,
  }
  return config
})

PerformanceApi.interceptors?.response?.use(undefined, errorResponseHandler)
