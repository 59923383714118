import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function loadOnboardingNationalities(params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/nationalities`

  return {
    type: actions.LOAD_ONBOARDING_NATIONALITIES,
    payload: handleAPI(url, params, 'GET'),
  }
}
