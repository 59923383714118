import { Typography, Flex, Badge } from '@jisr-hr/ds'
import { AnnualLeaveRequestResponseType } from 'components/global/templates/RequestDrawer/types/flightTicketRequest'
import { format } from 'utils/date'

export const AnnualLeaveListLabel = ({
  sequential_id,
  from,
  to,
  request_type_i18n,
  status,
  status_i18n,
}: AnnualLeaveRequestResponseType): JSX.Element => (
  <Flex
    justifyBetween
    className="p-3 gap-8"
  >
    <Flex
      flexCol
      className="gap-3"
    >
      <Typography
        text={`${sequential_id} - ${request_type_i18n}`}
        variant="body-new/regular"
      />
      <Badge
        label={`${format(from, 'EEEE, d MMM')} - ${format(to, 'EEEE, d MMM')}`}
        color="gray"
        variant="tinted"
        leadingIcon="calendar"
      />
    </Flex>
    <Badge
      label={status_i18n}
      color={status === 'pending' ? 'yellow' : 'green'}
      variant="tinted"
    />
  </Flex>
)
