import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import { TParams } from 'containers/public/Onboarding/types/onboardingTypes'
import * as actions from './actions'
import { TDataSubmit } from './type'

export function onBoardingPaymentDetailsLoad(params?: Partial<TParams>): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payment_details`

  return {
    type: actions.ONBOARDING_PAYMENT_DETAILS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function onBoardingBanksLoad(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/banks`

  return {
    type: actions.ONBOARDING_BANKS_LOAD,
    payload: handleAPI(url, { country_type: 1 }, 'GET'),
  }
}

export function onBoardingPaymentDetailsUpdate(id: number, data: TDataSubmit): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payment_details/${id}`

  return {
    type: actions.ONBOARDING_PAYMENT_DETAILS_UPDATE,
    payload: handleAPI(url, { employee_id: id }, 'PUT', { payment_details: data }),
  }
}
