import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type AddSaudi = {
  employment_type: string
  first_name: string
  last_name: string
  prisoner_status: string
  student_status: string
  worker_company_number: string
  contributor_type: string
  occupation_code: string
  location_code: string
  marital_status: string
  education: string
  contact_type: string
  building_number: string
  unit_number: string
  zip_code: string
  additional_number: string
  street: string
  country_code: string
  city: string
  village_code: string
  po_box: string
  free_text_description2: string
  telephone: string
  extension: string
  fax_number: string
  mobile_number: string
  email: string
  inland_abroad_indicator: string
  insurance_coverage: string
  joining_date: string
}

export type UploadSaudiContract = {
  contract_type: string
  start_date: string
  end_date: string
  working_days: string
  working_hours: string
  resting_days: string
  annual_leave_days: string
  probation_period_days: string
  notice_period: string
  city: string
  transportation_allowence: string
  job_title_eng: string
  job_title_arb: string
  contributor_language: string
  religion: string
  account_iban: string
  account_bank_name_arb: string
  account_bank_name_eng: string
  authorized_signatory_english: string
  authorized_signatory_arabic: string
  capacity_of_english: string
  capacity_of_arabic: string
  optional_clause: Array<string>
  additional_clause: Array<{ english?: string; arabic?: string }>
  employment_type?: string
  inland_abroad_indicator: string
  country_code: string
  working_hours_standard: string
}

type TInitState = {
  fetching: boolean
  errorMessage?: string
}

const initialState: TInitState = {
  fetching: false,
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload, type } = action

  switch (type) {
    case actions.ADD_SAUDI_PENDING:
    case actions.UPLOAD_SAUDI_CONTRACT_PENDING:
      return {
        ...state,
        fetching: true,
        errorMessage: '',
      }

    case actions.ADD_SAUDI_FULFILLED:
    case actions.UPLOAD_SAUDI_CONTRACT_FULFILLED:
      Toastr.success(payload.data?.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
        errorMessage: '',
      }

    case actions.ADD_SAUDI_REJECTED:
    case actions.UPLOAD_SAUDI_CONTRACT_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        errorMessage: handleResponseErr(payload),
      }

    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
      }

    default:
      return state
  }
}
