import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

export type RevenuesReportCellType = {
  code: string
  full_name: string
  no_of_orders: number
  total_revenue: string
}

export type RevenuesReportHeaderType = {
  code: string
  full_name: string
  no_of_orders: string
  total_revenue: string
}
type InitialStateType = {
  fetching: boolean
  header: RevenuesReportHeaderType | null
  records: RevenuesReportCellType[]
  pagination: PaginationTypes | null
}

export const initialState: InitialStateType = {
  records: [],
  header: null,
  pagination: null,
  fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ADMIN_FINANCE_REPORTS_REVENUES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ADMIN_FINANCE_REPORTS_REVENUES_LOAD_FULFILLED: {
      const pagination = payload.data.data?.pagination ?? {}
      return {
        ...state,
        records: [
          ...new Set([
            ...(pagination?.previous_page ? state.records : []),
            ...payload.data.data.records,
          ]),
        ],
        header: payload.data.data.header,
        pagination: payload.data.data.pagination,
        fetching: false,
      }
    }

    case actions.ADMIN_FINANCE_REPORTS_REVENUES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
