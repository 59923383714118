import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { ObjectiveProps } from 'types/talentRetention/objectives'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

type TInitState = {
  fetching: boolean
  fetching_share: boolean
  submitting: boolean
  objectives: ObjectiveProps[]
  objectives_all: ObjectiveProps[]
  objective: Partial<ObjectiveProps>
  stats: Record<string, unknown>
  pagination: Partial<PaginationTypes>
}

export const initialState: TInitState = {
  fetching: false,
  submitting: false,
  fetching_share: false,
  objectives: [],
  objectives_all: [],
  objective: {},
  stats: {},
  pagination: {
    current_page: 1,
  },
}

export default function reducer(state = initialState, action: Action): TInitState {
  switch (action.type) {
    case actions.OBJECTIVES_UPDATE_PROGRESS_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.OBJECTIVES_LOAD_PENDING:
    case actions.OBJECTIVES_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.OBJECTIVES_SHOW_PENDING:
    case actions.OBJECTIVES_UPDATE_PENDING:
    case actions.OBJECTIVES_CREATE_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.OBJECTIVES_LOAD_FULFILLED: {
      const { previous_page } = action.payload.pagination
      return {
        ...state,
        ...action.payload,
        objectives: [...(previous_page ? state.objectives : []), ...action.payload.objectives],
        fetching: false,
      }
    }

    case actions.OBJECTIVES_SHOW_FULFILLED:
      return {
        ...state,
        objective: action.payload.data.data,
        submitting: false,
      }

    case actions.OBJECTIVES_UPDATE_PROGRESS_FULFILLED:
      return {
        ...state,
        objectives: [],
        pagination: { page: 1 },
        submitting: false,
      }

    case actions.OBJECTIVES_CREATE_FULFILLED:
    case actions.OBJECTIVES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        objectives: [],
        pagination: { page: 1 },
        submitting: false,
      }

    case actions.OBJECTIVES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.data.message)
      return {
        ...state,
        objectives: [],
        pagination: { page: 1 },
        submitting: false,
        fetching: false,
      }

    case actions.OBJECTIVES_UPDATE_PROGRESS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
      }

    case actions.OBJECTIVES_LOAD_REJECTED:
    case actions.OBJECTIVES_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        fetching_share: false,
      }

    case actions.OBJECTIVES_SHOW_REJECTED:
    case actions.OBJECTIVES_UPDATE_REJECTED:
    case actions.OBJECTIVES_CREATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
