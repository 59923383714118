import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  fetching: false,
}

let changes = null
export default function excuseRequestReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'EXCUSE_REQUEST_PENDING':
    case 'UPDATE_EXCUSE_REQUEST_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'EXCUSE_REQUEST_FULFILLED':
    case 'UPDATE_EXCUSE_REQUEST_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'EXCUSE_REQUEST_REJECTED':
    case 'UPDATE_EXCUSE_REQUEST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
