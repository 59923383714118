import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'
import { InitialStateType } from './type'

export const initialState: InitialStateType = {
  fetching: false,
  holidays: [],
  default_holidays: [],
  selected_default: false,
}

export default function reducer(state = initialState, { type, payload }: Action): InitialStateType {
  switch (type) {
    case actions.ONBOARDING_HOLIDAYS_LOAD_PENDING:
    case actions.ONBOARDING_HOLIDAYS_CREATE_PENDING:
    case actions.ONBOARDING_HOLIDAYS_UPDATE_PENDING:
    case actions.ONBOARDING_HOLIDAYS_DELETE_PENDING:
    case actions.ONBOARDING_HOLIDAYS_DEFAULT_LOAD_PENDING:
    case actions.ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_HOLIDAYS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_HOLIDAYS_DEFAULT_LOAD_FULFILLED:
      return {
        ...state,
        default_holidays: payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_HOLIDAYS_DELETE_FULFILLED:
    case actions.ONBOARDING_HOLIDAYS_CREATE_FULFILLED:
    case actions.ONBOARDING_HOLIDAYS_UPDATE_FULFILLED:
    case actions.ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_HOLIDAYS_LOAD_REJECTED:
    case actions.ONBOARDING_HOLIDAYS_UPDATE_REJECTED:
    case actions.ONBOARDING_HOLIDAYS_DELETE_REJECTED:
    case actions.ONBOARDING_HOLIDAYS_CREATE_REJECTED:
    case actions.ONBOARDING_HOLIDAYS_DEFAULT_LOAD_REJECTED:
    case actions.ONBOARDING_HOLIDAYS_DEFAULT_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
