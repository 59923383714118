/** **************
 *   Setting Organization Profile
 /*************** */

import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export type PayrollTransactionType = {
  id: number
  default_pay_type: 'out_of_payrun' | 'in_payrun'
  name: string
  name_ar: string
  status: 'active' | 'inactive'
  description: string
  category: 'LoanType'
  pay_type: 'in_payrun' | 'out_of_payrun' | 'both'
  name_i18n: string
  is_default: boolean
}

export function loanTypesLoad(params = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types/`
  const method = 'GET'

  return {
    type: 'LOAN_TYPES_LOAD',
    payload: handleAPI(url, { category: 'LoanType', template: 'public', ...params }, method),
  }
}

export function loanTypeLoad(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types/${id}/`
  const method = 'GET'

  return {
    type: 'LOAN_TYPE_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function loanTypeCreate(data = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types/`
  const method = 'POST'
  return {
    type: 'LOAN_TYPE_CREATE',
    payload: handleAPI(url, {}, method, { payroll_transaction_type: data }),
  }
}

export function loanTypeUpdate(loanTypeId: number, data = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types/${loanTypeId}/`
  const method = 'PUT'
  return {
    type: 'LOAN_TYPE_UPDATE',
    payload: handleAPI(url, {}, method, { payroll_transaction_type: data }),
  }
}

export function loanTypeDelete(id: number): Action {
  const url = `/${API_VERSION_TWO}/finance/payroll_transaction_types/${id}/`
  const method = 'DELETE'

  return {
    type: 'LOAN_TYPE_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, {}, method)
        ?.then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        })
    }),
  }
}
