export const ONBOARDING_JOB_TITLES_POST = 'ONBOARDING_JOB_TITLES_POST'
export const ONBOARDING_JOB_TITLES_POST_PENDING = 'ONBOARDING_JOB_TITLES_POST_PENDING'
export const ONBOARDING_JOB_TITLES_POST_FULFILLED = 'ONBOARDING_JOB_TITLES_POST_FULFILLED'
export const ONBOARDING_JOB_TITLES_POST_REJECTED = 'ONBOARDING_JOB_TITLES_POST_REJECTED'

export const ONBOARDING_JOB_TITLES_LOAD = 'ONBOARDING_JOB_TITLES_LOAD'
export const ONBOARDING_JOB_TITLES_LOAD_PENDING = 'ONBOARDING_JOB_TITLES_LOAD_PENDING'
export const ONBOARDING_JOB_TITLES_LOAD_FULFILLED = 'ONBOARDING_JOB_TITLES_LOAD_FULFILLED'
export const ONBOARDING_JOB_TITLES_LOAD_REJECTED = 'ONBOARDING_JOB_TITLES_LOAD_REJECTED'

export const ONBOARDING_JOB_TITLES_SHOW = 'ONBOARDING_JOB_TITLES_SHOW'
export const ONBOARDING_JOB_TITLES_SHOW_PENDING = 'ONBOARDING_JOB_TITLES_SHOW_PENDING'
export const ONBOARDING_JOB_TITLES_SHOW_FULFILLED = 'ONBOARDING_JOB_TITLES_SHOW_FULFILLED'
export const ONBOARDING_JOB_TITLES_SHOW_REJECTED = 'ONBOARDING_JOB_TITLES_SHOW_REJECTED'

export const ONBOARDING_JOB_TITLE_DELETE = 'ONBOARDING_JOB_TITLE_DELETE'
export const ONBOARDING_JOB_TITLE_DELETE_PENDING = 'ONBOARDING_JOB_TITLE_DELETE_PENDING'
export const ONBOARDING_JOB_TITLE_DELETE_FULFILLED = 'ONBOARDING_JOB_TITLE_DELETE_FULFILLED'
export const ONBOARDING_JOB_TITLE_DELETE_REJECTED = 'ONBOARDING_JOB_TITLE_DELETE_REJECTED'

export const ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD =
  'ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD'
export const ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_PENDING =
  'ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_PENDING'
export const ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_FULFILLED =
  'ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_FULFILLED'
export const ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_REJECTED =
  'ONBOARDING_INDUSTRIES_JOB_TITLE_SUGGESTIONS_LOAD_REJECTED'
