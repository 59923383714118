import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateOvertime } from 'redux/actions/overtimeRequestActions'
import { hasPermission } from 'components/global/HelperFunctions'
import { BetaTextInputField as TextInputField } from 'components/final-form'
import i18n from 'translations/i18n'
import { composeValidators, maximum, minValue } from 'components/global/form/FormValidations'
import { floatNumber } from 'components/global/form/formNormalize'
import { useForm } from 'react-final-form'

import { useRequest } from '../../RequestProvider'

const HourlyPopper = ({ details, reloadRequest }) => {
  const dispatch = useDispatch()
  const { change } = useForm()
  const { actionCallback, setInitialValues } = useRequest()
  const { employee } = useSelector(({ auth }) => auth)

  const updateRateTimeout = React.useRef(null)

  const canChangeRate =
    details?.status === 'pending' &&
    details?.can_take_action &&
    hasPermission(employee, details?.employee?.id, employee?.permission_scopes?.view_attendance) &&
    employee?.permission_scopes?.send_attendance_requests_for_employees

  const handleChangeRate = (value) => {
    // add timeout before hit API
    clearTimeout(updateRateTimeout.current)

    if (+value <= details.overtime_policy && +value >= 1) {
      updateRateTimeout.current = setTimeout(() => {
        dispatch(
          updateOvertime(details?.approval?.request_id, {
            employee_id: details.employee.id,
            overtime_request: {
              hour_rate: value,
            },
          }),
        )
          .then(() => {
            reloadRequest()
            actionCallback(true)
          })
          .catch(() => {
            change('hour_rate', details?.hour_rate)
          })
      }, 800)
    }
  }

  React.useEffect(() => {
    setInitialValues({
      hour_rate: details?.hour_rate,
    })
  }, [details?.hour_rate])

  return (
    <TextInputField
      name="hour_rate"
      size="compact"
      label={i18n.t('hourly_rate_mutiple')}
      onChange={handleChangeRate}
      parse={floatNumber}
      validate={composeValidators(maximum(details.overtime_policy), minValue(1))}
      disabled={!canChangeRate}
      helperText={i18n.t('the_current_policy_allows', { overTimePolicy: details?.hour_rate })}
      leadingIcon="x"
    />
  )
}

HourlyPopper.propTypes = {
  details: PropTypes.shape(),
  reloadRequest: PropTypes.func,
}

export default HourlyPopper
