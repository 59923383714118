export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED'

export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED'
export const ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED =
  'ONBOARDING_VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED'
