import i18n from 'translations/i18n'
import { Department } from 'redux/newDepartments/types'
import GenericPopup from 'containers/authorised/NewOnboarding/components/GenericPopup'

type DeleteDeptPopupPropsType = {
  open: boolean
  fetching?: boolean
  selectedDepartment: Department
  listSuggetionsDepartment: Department[]
  onRemoveDepartment: (newDepartment: Department) => void
  onBack: () => void
}

const DeleteDeptPopup = ({
  open,
  fetching,
  selectedDepartment,
  listSuggetionsDepartment,
  onRemoveDepartment,
  onBack,
}: DeleteDeptPopupPropsType): JSX.Element => {
  return (
    <GenericPopup<Department>
      open={open}
      fetching={fetching}
      listEntities={listSuggetionsDepartment.filter((l) => l.id !== selectedDepartment.id)}
      onRemoveEntity={onRemoveDepartment}
      onBack={onBack}
      title="label.move_employees_before_deleting_department"
      description={i18n.t(
        'description.department_name_department_has_count_employees._please_select_a_new_department_for_these_employees_before_deletion.',
        {
          employee_count: selectedDepartment?.number_of_employees,
          department_name: selectedDepartment?.name_i18n,
        },
      )}
      selectPlaceholder="label.department"
      submitLabel="label.move_employees_and_delete"
      cancelLabel="label.back"
      labelKey="name_i18n"
    />
  )
}

export default DeleteDeptPopup
