import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function letterTemplatesLoad() {
  const url = `/${API_VERSION_TWO}/letter_types`
  const locale = localStorage.getItem('lang')

  return {
    type: actions.LETTER_TEMPLATES_LOAD,
    payload: handleAPI(url, { locale }, 'GET'),
  }
}

export function letterTemplatesGetOne({ id }) {
  const url = `/${API_VERSION_TWO}/letter_types/${id}`

  return {
    type: actions.LETTER_TEMPLATES_GET_ONE,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function letterTemplatesPost({ letter_type }) {
  const url = `/${API_VERSION_TWO}/letter_types`

  return {
    type: actions.LETTER_TEMPLATES_POST,
    payload: handleAPI(url, {}, 'POST', { letter_type }),
  }
}

export function letterTemplatesUpdate(data) {
  const url = `/${API_VERSION_TWO}/letter_types/${data.id}`

  return {
    type: actions.LETTER_TEMPLATES_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { letter_type: data }),
  }
}

export function letterTemplatesOptionsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/letter_types/template_options`

  return {
    type: actions.LETTER_TEMPLATES_OPTIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function letterTemplatesDelete({ id }) {
  const url = `/${API_VERSION_TWO}/letter_types/${id}`

  return {
    type: actions.LETTER_TEMPLATES_DELETE,
    payload: {
      promise: handleAPI(url, {}, 'DELETE'),
      data: { id },
    },
  }
}

export function letterTemplatesPostAttachment({ letter_attachment }) {
  const url = `/${API_VERSION_TWO}/letter_attachments`

  return {
    type: actions.LETTER_TEMPLATES_POST_ATTACHMENT,
    payload: handleAPI(url, {}, 'POST', { letter_attachment }),
  }
}

export function previewLetterTemplate(data) {
  return {
    type: actions.PREVIEW_LETTER_TEMPLATE,
    payload: new Promise((resolve) => resolve(data)),
  }
}

export function printLetterTemplateLoad({ empId, requestId }) {
  const url = `/${API_VERSION_TWO}/employees/${empId}/letter_requests/${requestId}/export.json`

  return {
    type: actions.PRINT_LETTER_TEMPLATE,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function letterTemplatesPdfLoad({ empId, requestId }) {
  return printLetterTemplateLoad({ empId, requestId })
}
