import React from 'react'
import { serverEnvironment } from 'env'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { Link, useLocation } from 'react-router-dom'
import { Button, Typography } from '@jisr-hr/ds'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'

export const getSettingsNavList = ({
  manage_integrations,
  manage_performance,
  payroll_settings,
  manage_shifts_and_working_hours,
  manage_reporting_methods,
  manage_attendance_policies,
  manage_organization_settings,
  manage_company_documents,
  manage_letter_templates,
  manage_request_workflow,
  manage_notification,
  manage_roles_and_permission,
  manage_security_settings,
  manage_billing,
  leave_settings,
  employee_profile_settings,
  bulk_update_employee_information,
  add_new_employees,
  manage_salary_information,
  manage_personal_and_organization_information,
  manage_assets,
  manage_other_financial_information,
  manage_loans,
  bulk_update_leave_balance,
  bulk_edit_attendance_records,
  bulk_edit_approved_overtime_sheet,
  edit_on_payroll_table,
  manage_flight_bookings,
  make_payments,
  isJisrPayEnabled,
  isFlightBookingEnabled,
}) => {
  const canManageBulkImportSheets =
    !!bulk_update_employee_information ||
    !!add_new_employees ||
    !!manage_salary_information ||
    !!manage_personal_and_organization_information ||
    !!manage_assets ||
    !!manage_other_financial_information ||
    !!manage_loans ||
    !!bulk_update_leave_balance ||
    !!bulk_edit_attendance_records ||
    !!bulk_edit_approved_overtime_sheet ||
    !!edit_on_payroll_table ||
    !!manage_shifts_and_working_hours

  return [
    (manage_organization_settings || manage_company_documents || canManageBulkImportSheets) && {
      label: 'organization_settings',
      path: '/setting/organization',
      eventName: 'Organization Settings',
    },
    manage_organization_settings && {
      label: 'organization_structure',
      path: '/setting/org_structure',
    },
    employee_profile_settings && {
      label: 'employee_profile',
      path: '/setting/employee_profile',
      eventName: 'Employee Profile Settings',
    },
    leave_settings && {
      label: 'leave_management',
      path: '/setting/leave_management',
    },
    (!!manage_shifts_and_working_hours ||
      !!manage_reporting_methods ||
      !!manage_attendance_policies ||
      !!manage_request_workflow) && {
      label: 'attendance_management',
      path: '/setting/attendance_management',
      eventName: 'Attendance Management Settings',
    },
    manage_performance && {
      label: 'performance_management',
      path: '/setting/performance_management',
      eventName: 'Performance Management Settings',
    },
    (isJisrPayEnabled || isFlightBookingEnabled) &&
      (manage_flight_bookings || make_payments) && {
        label: 'jisr_wallet',
        path: '/setting/jisr_wallet',
        eventName: 'Jisr Wallet Settings',
      },
    payroll_settings && {
      label: 'payroll_management',
      path: '/setting/payroll_management',
      eventName: 'Payroll Management Settings',
    },
    manage_letter_templates && {
      label: 'letters_management',
      path: '/setting/letters_managment',
      eventName: 'Letters Management Settings',
    },
    ['aws', 'staging', 'oci', 'dev', 'e2e'].includes(serverEnvironment) &&
      manage_integrations && {
        label: 'integrations',
        path: '/setting/integration',
        eventName: 'Integration Settings',
      },
    manage_request_workflow && {
      label: 'request_workflow',
      path: '/setting/request_workflow',
      eventName: 'Request Workflow Settings',
    },
    manage_roles_and_permission && {
      label: 'roles_alt',
      path: '/setting/roles',
      eventName: 'Roles Settings',
    },
    manage_roles_and_permission && {
      label: 'user_permissions',
      path: '/setting/user_permissions',
      eventName: 'User Permissions Settings',
    },
    manage_notification && {
      label: 'notifications',
      path: '/setting/notification_management',
      eventName: 'Notification Management Settings',
    },
    manage_security_settings && {
      label: 'security',
      path: '/setting/security',
      eventName: 'Security Settings',
    },
    manage_billing && {
      label: 'billing',
      path: '/setting/billing',
      eventName: 'Billing Settings',
    },
  ].filter(Boolean)
}

const SettingNavigation = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const permissionScopes = useSelector(({ auth }) => auth.employee.permission_scopes)
  const permission_details = useSelector(({ auth }) => auth.employee.permission_details)
  const company = useSelector(({ auth }) => auth?.company)
  const isGlSupported = company?.subscribed_features?.includes('gl_accounting')

  function isActive(path) {
    return pathname.split('/').includes(path.split('/')[2])
  }

  React.useEffect(() => {
    dispatch(getAllEmployeesLoad())
  }, [])

  return (
    <div className="main-setting--nav_box">
      <div
        style={{
          padding: '0 20px',
        }}
      >
        <Link to="/">
          <Button
            variant="outlined"
            color="neutral"
            size="small"
            leadingIcon="home-01"
            label={I18n.t('main_menu')}
            testId="main-menu"
          />
        </Link>
        <Typography
          variant="heading"
          style={{ marginTop: 60, marginBottom: 16 }}
          text={I18n.t('settings')}
        />
      </div>
      <div className="scroll-y">
        {getSettingsNavList({
          ...permissionScopes,
          edit_on_payroll_table: permission_details?.edit_on_payroll_table,
          payroll_settings: permission_details?.payroll_settings,
          make_payments: permission_details?.make_payments,
          isGlSupported,
        }).map((item, i) => {
          const active = isActive(item.path)
          const isRolesTab = item.path === '/setting/roles'
          return (
            <Link
              key={i}
              to={item.path}
              className={classNames(
                'settting--nav-item',
                active && 'active',
                active && isRolesTab && 'pointerEventsNone',
              )}
              data-testid={`nav-link-setting-${item.label}`}
            >
              <Typography
                variant="interface/default/sm"
                text={I18n.t(item.label)}
              />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default SettingNavigation
