import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  generic_faqs: [],
  custom_faqs: [],
  training_video: null,
  key: '',
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_HELP_CENTER_PENDING:
    case actions.ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_HELP_CENTER_FULFILLED:
      return {
        ...state,
        ...payload?.data?.data,
        fetching: false,
      }

    case actions.ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_FULFILLED:
      return {
        ...state,
        fetching: false,
        training_video: {
          ...state.training_video,
          answer: action.payload?.data?.data,
        },
      } as InitialStateType

    case actions.ONBOARDING_HELP_CENTER_REJECTED:
    case actions.ONBOARDING_HELP_CENTER_VIDEO_RATING_PUT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_HELP_CENTER_KEY:
      return {
        ...state,
        fetching: false,
        key: payload,
      }
    default:
      return state
  }
}
