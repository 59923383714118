export const OFF_CYCLE_PAYRUNS_LOAD = 'OFF_CYCLE_PAYRUNS_LOAD'
export const OFF_CYCLE_PAYRUNS_LOAD_PENDING = 'OFF_CYCLE_PAYRUNS_LOAD_PENDING'
export const OFF_CYCLE_PAYRUNS_LOAD_FULFILLED = 'OFF_CYCLE_PAYRUNS_LOAD_FULFILLED'
export const OFF_CYCLE_PAYRUNS_LOAD_REJECTED = 'OFF_CYCLE_PAYRUNS_LOAD_REJECTED'

export const OFF_CYCLE_PAYRUN_DETAILS = 'OFF_CYCLE_PAYRUN_DETAILS'
export const OFF_CYCLE_PAYRUN_DETAILS_PENDING = 'OFF_CYCLE_PAYRUN_DETAILS_PENDING'
export const OFF_CYCLE_PAYRUN_DETAILS_FULFILLED = 'OFF_CYCLE_PAYRUN_DETAILS_FULFILLED'
export const OFF_CYCLE_PAYRUN_DETAILS_REJECTED = 'OFF_CYCLE_PAYRUN_DETAILS_REJECTED'

export const STATISTICS_OFF_CYCLE_PAYRUN_LOAD = 'STATISTICS_OFF_CYCLE_PAYRUN_LOAD'
export const STATISTICS_OFF_CYCLE_PAYRUN_LOAD_PENDING = 'STATISTICS_OFF_CYCLE_PAYRUN_LOAD_PENDING'
export const STATISTICS_OFF_CYCLE_PAYRUN_LOAD_FULFILLED =
  'STATISTICS_OFF_CYCLE_PAYRUN_LOAD_FULFILLED'
export const STATISTICS_OFF_CYCLE_PAYRUN_LOAD_REJECTED = 'STATISTICS_OFF_CYCLE_PAYRUN_LOAD_REJECTED'

export const STATISTICS_OFF_CYCLE_PAYRUNS_LOAD = 'STATISTICS_OFF_CYCLE_PAYRUNS_LOAD'
export const STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_PENDING = 'STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_PENDING'
export const STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_FULFILLED =
  'STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_FULFILLED'
export const STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_REJECTED =
  'STATISTICS_OFF_CYCLE_PAYRUNS_LOAD_REJECTED'

export const EXPORT_OFF_CYCLE_PAYRUNS = 'EXPORT_OFF_CYCLE_PAYRUNS'
export const EXPORT_OFF_CYCLE_PAYRUNS_PENDING = 'EXPORT_OFF_CYCLE_PAYRUNS_PENDING'
export const EXPORT_OFF_CYCLE_PAYRUNS_FULFILLED = 'EXPORT_OFF_CYCLE_PAYRUNS_FULFILLED'
export const EXPORT_OFF_CYCLE_PAYRUNS_REJECTED = 'EXPORT_OFF_CYCLE_PAYRUNS_REJECTED'

export const OFF_CYCLE_SEND_ATTENDANCE_REVIEW = 'OFF_CYCLE_SEND_ATTENDANCE_REVIEW'
export const OFF_CYCLE_SEND_ATTENDANCE_REVIEW_PENDING = 'OFF_CYCLE_SEND_ATTENDANCE_REVIEW_PENDING'
export const OFF_CYCLE_SEND_ATTENDANCE_REVIEW_FULFILLED =
  'OFF_CYCLE_SEND_ATTENDANCE_REVIEW_FULFILLED'
export const OFF_CYCLE_SEND_ATTENDANCE_REVIEW_REJECTED = 'OFF_CYCLE_SEND_ATTENDANCE_REVIEW_REJECTED'

export const EXPORT_VACATION_PAYRUN = 'EXPORT_VACATION_PAYRUN'
export const EXPORT_VACATION_PAYRUN_PENDING = 'EXPORT_VACATION_PAYRUN_PENDING'
export const EXPORT_VACATION_PAYRUN_FULFILLED = 'EXPORT_VACATION_PAYRUN_FULFILLED'
export const EXPORT_VACATION_PAYRUN_REJECTED = 'EXPORT_VACATION_PAYRUN_REJECTED'

export const OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD = 'OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD'
export const OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_PENDING =
  'OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_PENDING'
export const OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED =
  'OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_FULFILLED'
export const OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_REJECTED =
  'OFF_CYCLE_ADDITIONS_DEDUCTIONS_LOAD_REJECTED'

export const VACATION_DETAILS_RESET = 'VACATION_DETAILS_RESET'

export const PROCESS_TIMELINE_LOAD = 'PROCESS_TIMELINE_LOAD'
export const PROCESS_TIMELINE_LOAD_PENDING = 'PROCESS_TIMELINE_LOAD_PENDING'
export const PROCESS_TIMELINE_LOAD_FULFILLED = 'PROCESS_TIMELINE_LOAD_FULFILLED'
export const PROCESS_TIMELINE_LOAD_REJECTED = 'PROCESS_TIMELINE_LOAD_REJECTED'

export const DOWNLOAD_PAYMENT_FILE = 'DOWNLOAD_PAYMENT_FILE'
export const DOWNLOAD_PAYMENT_FILE_PENDING = 'DOWNLOAD_PAYMENT_FILE_PENDING'
export const DOWNLOAD_PAYMENT_FILE_FULFILLED = 'DOWNLOAD_PAYMENT_FILE_FULFILLED'
export const DOWNLOAD_PAYMENT_FILE_REJECTED = 'DOWNLOAD_PAYMENT_FILE_REJECTED'

export const FINAL_SETTLEMENT_REQUEST_UPDATE = 'FINAL_SETTLEMENT_REQUEST_UPDATE'
export const FINAL_SETTLEMENT_REQUEST_UPDATE_PENDING = 'FINAL_SETTLEMENT_REQUEST_UPDATE_PENDING'
export const FINAL_SETTLEMENT_REQUEST_UPDATE_FULFILLED = 'FINAL_SETTLEMENT_REQUEST_UPDATE_FULFILLED'
export const FINAL_SETTLEMENT_REQUEST_UPDATE_REJECTED = 'FINAL_SETTLEMENT_REQUEST_UPDATE_REJECTED'

export const CHECK_VACATION_LOAD = 'CHECK_VACATION_LOAD'
export const FCHECK_VACATION_LOAD_PENDING = 'FCHECK_VACATION_LOAD_PENDING'
export const CHECK_VACATION_LOAD_FULFILLED = 'CHECK_VACATION_LOAD_FULFILLED'
export const CHECK_VACATION_LOAD_REJECTED = 'CHECK_VACATION_LOAD_REJECTED'
