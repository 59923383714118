// Import translations
import OffCyclePayroll from './modules/en/OffCyclePayroll'
import Analysis from './modules/en/analysis'
import Assets from './modules/en/assets'
import atsRecruitment from './modules/en/ats_recruitment'
import Auth from './modules/en/auth'
import Common from './modules/en/common'
import Constants from './modules/en/constants'
import Elevatus from './modules/en/elevatus'
import Employee from './modules/en/employee'
import Errors from './modules/en/errors'
import gl_mapping from './modules/en/gl_mapping'
import LeaveManagement from './modules/en/leaveManagement'
import muqeem from './modules/en/muqeem'
import onboarding from './modules/en/onboarding'
import Payroll from './modules/en/payroll'
import performance from './modules/en/performance'
import Report from './modules/en/report'
import Request from './modules/en/request'
import Scheduler from './modules/en/scheduler'
import Setting from './modules/en/setting'
import TimeAttendance from './modules/en/timeAttendance'
import Validations from './modules/en/validations'
import Webhooks from './modules/en/webhooks'
import Workable from './modules/en/workable'
import Automations from './modules/en/automations'
import BenefitsManagement from './modules/en/BenefitsManagement'
import Tawuniya from './modules/en/tawuniya'
import Marketplace from './modules/en/marketplace'
import SSO from './modules/en/sso'
import dittoEN from './ditto-en.json'

const EN = {
  ...Auth,
  ...Analysis,
  ...Constants,
  ...Common,
  ...onboarding,
  ...Request,
  ...Employee,
  ...LeaveManagement,
  ...TimeAttendance,
  ...Scheduler,
  ...Payroll,
  ...gl_mapping,
  ...muqeem,
  ...OffCyclePayroll,
  ...Report,
  ...Setting,
  ...performance,
  ...Assets,
  ...Validations,
  ...Errors,
  ...Elevatus,
  ...Workable,
  ...Webhooks,
  ...atsRecruitment,
  ...Automations,
  ...BenefitsManagement,
  ...Tawuniya,
  ...Marketplace,
  ...SSO,
  ...dittoEN,
}

export default EN
