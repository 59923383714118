import React from 'react'
import I18n from 'translations/i18n'
import { exitReentryTables } from './helper'
import Frame from '../../../ds/Frame'
import { useRequest } from '../../../RequestProvider'

const ExitReentryInfo = () => {
  const {
    vars: { request },
  } = useRequest()
  return <Frame title={I18n.t('exit_reentry_info')}>{exitReentryTables(request)}</Frame>
}

export default ExitReentryInfo
