import I18n from 'translations/i18n'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import SearchBar from 'components/global/SearchBar'
import { MultiBadgesCheckList, MultiLevelCheckList } from 'components/global'
import { CheckListDataTypes, ListDataTypes } from 'types/sharedComponents'
import styles from '../styles.module.css'
import RenderCheckbox from '../RenderCheckbox'
import FilterBoxSubObtionsList from './FilterBoxSubObtionsList'
import FilterBoxGroupedList from './FilterBoxGroupedList'

type extraData = {
  children: Array<number>
  isMulti: boolean
  parent: number
}

type flatOptionTypes = {
  name_i18n: string
  name_ar: string
  name: string
}

type FilterBoxListProps = {
  isCollapsed: boolean
  searchKey: string
  filterKey: string
  optionKey: string
  optionFilterKey: string
  subOptionFKey: string
  subOptionKey: string
  subOptionFilterKey: string
  childKey: string
  labelKey: string
  valueKey: string
  groupKey?: string
  componentType: string
  handleOnSearch: (key: string | null) => void
  handleFilter: (key: string, selected: Array<number> | string | number, data?: extraData) => void
  options: Array<ListDataTypes>
  flatOptionList: Array<flatOptionTypes>
  optionList: Array<CheckListDataTypes>
  subOptions: Array<CheckListDataTypes>
  Ids: Array<number>
  selectedIds: Array<number>
  subSelectedIds: Array<number>
  listBoxStyle: Record<string, unknown>
}

const FilterBoxList = (props: FilterBoxListProps): JSX.Element => {
  const {
    isCollapsed,
    options,
    handleOnSearch,
    searchKey,
    flatOptionList,
    Ids,
    filterKey,
    optionKey,
    subOptionFKey,
    subOptionKey,
    optionFilterKey,
    subOptionFilterKey,
    childKey,
    labelKey,
    valueKey,
    groupKey,
    optionList,
    selectedIds,
    subSelectedIds,
    componentType,
    subOptions,
    listBoxStyle,
    handleFilter,
  } = props

  const isMultiLevel = componentType === 'multiLevel'
  const isGroupedList = componentType === 'grouped'

  const renderSearchComponent = (): JSX.Element => {
    return (
      <>
        {options.length > (isMultiLevel ? 5 : 10) && (
          <SearchBar
            onChange={(search: string): void => handleOnSearch(search)}
            placeholder={I18n.t('search')}
            className="w-full"
          />
        )}
      </>
    )
  }

  if (isGroupedList) {
    return (
      <>
        {renderSearchComponent()}
        <div
          className={classNames(styles.checkbox, isCollapsed && styles.hideCheckList)}
          style={listBoxStyle}
        >
          <FilterBoxGroupedList
            list={searchKey ? optionList : options}
            selected={selectedIds}
            valueKey={valueKey}
            labelKey={labelKey}
            groupKey={`${groupKey}`}
            onChange={handleFilter}
            filterKey={filterKey}
          />
        </div>
      </>
    )
  }

  return (
    <>
      {renderSearchComponent()}
      <div
        className={classNames(styles.checkbox, isCollapsed && styles.hideCheckList)}
        style={listBoxStyle}
      >
        {!isMultiLevel ? (
          <>
            {componentType !== 'badges' ? (
              <>
                {!isEmpty(subOptions) ? (
                  <FilterBoxSubObtionsList
                    handleFilter={handleFilter}
                    optionFilterKey={optionFilterKey}
                    optionKey={optionKey}
                    options={options}
                    selectedIds={selectedIds}
                    subOptionFKey={subOptionFKey}
                    subOptionFilterKey={subOptionFilterKey}
                    subOptionKey={subOptionKey}
                    subOptions={subOptions}
                    subSelectedIds={subSelectedIds}
                  />
                ) : (
                  optionList?.map((option, i) => (
                    <RenderCheckbox
                      key={i}
                      data={option}
                      selectedIds={Ids}
                      filterKey={filterKey}
                      handleFilter={handleFilter}
                    />
                  ))
                )}
              </>
            ) : (
              <MultiBadgesCheckList
                options={optionList}
                selected={selectedIds}
                onChange={(value: string | number): void => handleFilter(filterKey, value)}
                labelKey={labelKey}
                valueKey={valueKey}
              />
            )}
          </>
        ) : (
          <>
            {searchKey ? (
              <>
                {!isEmpty(flatOptionList) &&
                  flatOptionList?.map((o, i) => {
                    const name_n = o?.name_i18n?.toLowerCase()?.includes(searchKey.toLowerCase())
                    const name_ar = o?.name_ar?.toLowerCase()?.includes(searchKey.toLowerCase())
                    const name = o?.name?.toLowerCase()?.includes(searchKey.toLowerCase())
                    if (name_n || name_ar || name) {
                      return (
                        <RenderCheckbox
                          key={i}
                          data={o}
                          selectedIds={Ids}
                          filterKey={filterKey}
                          handleFilter={handleFilter}
                        />
                      )
                    }
                    return null
                  })}
              </>
            ) : (
              <MultiLevelCheckList
                childKey={childKey}
                labelKey={labelKey}
                valueKey={valueKey}
                list={optionList}
                selected={selectedIds}
                onChange={(data, data2): void => handleFilter(filterKey, data, data2)}
                singleValue
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default FilterBoxList
