import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import { ListDataTypes } from 'types/sharedComponents'
import { getSortedListByGroup } from './helper'
import GroupCheckList from './GroupCheckList'

type MultiGroupCheckListProps = {
  groupKey: string
  labelKey?: string
  valueKey?: string
  list: Array<ListDataTypes>
  selected: Array<number>
  onChange: (data: { checked: boolean; value: number }) => void
}

const MultiGroupCheckList = (props: MultiGroupCheckListProps): JSX.Element => {
  const { list, groupKey, selected, labelKey, valueKey, onChange } = props

  const groupedList = !isEmpty(list) ? getSortedListByGroup(list, groupKey) : []

  return (
    <>
      {!isEmpty(groupedList) &&
        Object.entries(groupedList).map((e, k) => {
          return (
            <GroupCheckList
              key={k}
              title={i18n.t(e[0] !== 'undefined' ? e[0] : '')}
              list={e[1]}
              onChange={onChange}
              selected={selected}
              labelKey={labelKey}
              valueKey={valueKey}
            />
          )
        })}
    </>
  )
}

export default MultiGroupCheckList
