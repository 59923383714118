import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type TObject = {
  id: number
  name_i18n: string
}

export type TConstantsInitState = {
  fetching: boolean
  message?: string | null
  constants: {
    tier: TObject[]
    status: TObject[]
    organization_type: TObject[]
  }
}

const initialState: TConstantsInitState = {
  fetching: false,
  constants: {
    tier: [],
    status: [],
    organization_type: [],
  },
}

export default function reducer(state = initialState, action: Action): TConstantsInitState {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_CONSTANTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_CONSTANTS_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        constants: payload.data.data,
        fetching: false,
      }

    case actions.ONBOARDING_CONSTANTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
