export default {
  '500_title': 'لم يتم استكمال الإجراء',
  '500_description':
    'لم نتمكن من نقل جميع البيانات بسبب عطل فني. يرجى المحاولة مرة أخرى، وفي حال استمرار المشكلة، يرجى الاتصال بالدعم الفني.',
  '403_title': ' عذرًا، ليس لديك الصلاحية للوصول إلى هذه الصفحة',
  '403_description':
    'ليس لديك الصلاحية للوصول إلى هذه الصفحة. إذا كنت تعتقد أن هنالك خطأ، يُرجى الاتصال بمسؤول النظام للمساعدة.',
  '404_title': 'عذرًا لا يمكن العثور على هذه الصفحة',
  '404_description':
    'لا يمكن العثور على الصفحة التي تبحث عنها. يُرجى التحقق من الرابط أو العودة إلى الصفحة الرئيسية.',
}
