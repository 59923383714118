import { FC, HTMLAttributes } from 'react'
import classnames from 'classnames'
import Style from './Note.module.css'

// Default variant: Red
const variantClasses = {
  pink: Style.pink,
  orange: Style.orange,
  yellow: Style.yellow,
  green: Style.green,
  blue: Style.blue,
  purple: Style.purple,
  brown: Style.brown,
  gray: Style.gray,
  conflict: Style.conflict,
}

export type NoteVariantT = keyof typeof variantClasses

type NoteProps = {
  children?: React.ReactNode
  variant?: NoteVariantT
  isDayOff?: boolean
  status?: 'draft'
} & HTMLAttributes<HTMLDivElement>

const Note: FC<NoteProps> = (props) => {
  const { variant, children, className, status, isDayOff, ...rest } = props

  return (
    <div
      className={classnames(
        Style.note,
        status ? Style[status] : undefined,
        variant ? variantClasses[variant] : undefined,
        isDayOff ? variantClasses.gray : undefined,
        isDayOff ? Style.dayOff : undefined,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Note
