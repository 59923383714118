import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { PendingRequestState } from './pendingRequestTypes'

const initialState: PendingRequestState = {
  fetching: false,
  employees_count: 0,
  requests: [],
  pagination: {},
  stats: {},
}

export default function reducer(state = initialState, action: Action): PendingRequestState {
  switch (action.type) {
    case actions.RESET_PENDING_REQUEST:
      return {
        ...state,
        requests: [],
        pagination: {},
      }

    case actions.PENDING_REQUEST_LISTING_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PENDING_REQUEST_LISTING_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        requests: [...state.requests, ...action.payload.data.data.requests],
        fetching: false,
      }

    case actions.PENDING_REQUEST_LISTING_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
