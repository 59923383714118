export const ONBOARDING_ASSET_TYPES_LOAD = 'ONBOARDING_ASSET_TYPES_LOAD'
export const ONBOARDING_ASSET_TYPES_LOAD_PENDING = 'ONBOARDING_ASSET_TYPES_LOAD_PENDING'
export const ONBOARDING_ASSET_TYPES_LOAD_REJECTED = 'ONBOARDING_ASSET_TYPES_LOAD_REJECTED'
export const ONBOARDING_ASSET_TYPES_LOAD_FULFILLED = 'ONBOARDING_ASSET_TYPES_LOAD_FULFILLED'

export const ONBOARDING_ASSET_TYPE_CREATE = 'ONBOARDING_ASSET_TYPE_CREATE'
export const ONBOARDING_ASSET_TYPE_CREATE_PENDING = 'ONBOARDING_ASSET_TYPE_CREATE_PENDING'
export const ONBOARDING_ASSET_TYPE_CREATE_REJECTED = 'ONBOARDING_ASSET_TYPE_CREATE_REJECTED'
export const ONBOARDING_ASSET_TYPE_CREATE_FULFILLED = 'ONBOARDING_ASSET_TYPE_CREATE_FULFILLED'

export const ONBOARDING_ASSET_TYPE_UPDATE = 'ONBOARDING_ASSET_TYPE_UPDATE'
export const ONBOARDING_ASSET_TYPE_UPDATE_PENDING = 'ONBOARDING_ASSET_TYPE_UPDATE_PENDING'
export const ONBOARDING_ASSET_TYPE_UPDATE_REJECTED = 'ONBOARDING_ASSET_TYPE_UPDATE_REJECTED'
export const ONBOARDING_ASSET_TYPE_UPDATE_FULFILLED = 'ONBOARDING_ASSET_TYPE_UPDATE_FULFILLED'

export const ONBOARDING_ASSET_TYPE_DELETE = 'ONBOARDING_ASSET_TYPE_DELETE'
export const ONBOARDING_ASSET_TYPE_DELETE_PENDING = 'ONBOARDING_ASSET_TYPE_DELETE_PENDING'
export const ONBOARDING_ASSET_TYPE_DELETE_REJECTED = 'ONBOARDING_ASSET_TYPE_DELETE_REJECTED'
export const ONBOARDING_ASSET_TYPE_DELETE_FULFILLED = 'ONBOARDING_ASSET_TYPE_DELETE_FULFILLED'

export const ONBOARDING_ASSET_TYPE_SUGGESTIONS = 'ONBOARDING_ASSET_TYPE_SUGGESTIONS'
export const ONBOARDING_ASSET_TYPE_SUGGESTIONS_PENDING = 'ONBOARDING_ASSET_TYPE_SUGGESTIONS_PENDING'
export const ONBOARDING_ASSET_TYPE_SUGGESTIONS_REJECTED =
  'ONBOARDING_ASSET_TYPE_SUGGESTIONS_REJECTED'
export const ONBOARDING_ASSET_TYPE_SUGGESTIONS_FULFILLED =
  'ONBOARDING_ASSET_TYPE_SUGGESTIONS_FULFILLED'
