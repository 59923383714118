import { combineReducers } from 'redux'

import changePassword from './authUser/changePassword/reducer'
import authReducer from './authUser/reducer'
import constants from './constants/reducer'
import marketplaceReducers from './marketplace/reducer'
import toggleNavigation from './toggleNavigation/reducer'
import undoAlerts from './undoAlert/reducer'

// ======= Requests Start ======== //
import notificationsReducer from './notifications/reducer'
import announcementsReducer from './reducers/announcementsReducer'
import leaveCancellationRequestsReducer from './reducers/leaveCancellationRequestsReducer'
import requestsApproversListReducer from './reducers/requestsApproversListReducer'
import requestsReducer from './requests/reducer'
import requestComments from './requests/requestComments/reducer'
// Requests
import requestsApprovalsReducer from './requests/approvals/reducer'
import assetRequestsReducer from './requests/assetRequests/reducer'
import attendancesOverview from './requests/attendancesOverview/reducer'
import BillingReducer from './requests/billing/reducer'
import customRequests from './requests/customRequests/reducer'
import requestsExpiringDocuments from './requests/expiringDocuments/reducer'
import hiringRequestsReducer from './requests/hiringRequests/reducer'
import leaveExtensionsRequest from './requests/leaveExtensionsRequest/reducer'
import approvals from './requests/newRequestsApproval/reducer'
import newlyJoinedUsers from './requests/newlyJoinedUsers/reducer'
import nextPayroll from './requests/nextPayroll/reducer'
import offlineDevices from './requests/offlineDevices/reducer'
import pendingTasksRequests from './requests/pendingTasksRequests/reducer'
import resignationRequestsReducer from './requests/resignationRequests/reducer'
import employeesWithoutRcs from './requests/withoutRcBankInfo/reducer'
import tasksReducer from './tasks/reducer'
// ======= Requests End======== //

// ======= Onboarding End======== //
import onboardingBankAccounts from './payrollOnboarding/BankAccounts/reducer'
import payschedule from './payrollOnboarding/PaySchedules/reducer'
import onBoardingMudad from './payrollOnboarding/PaymentMethods/mudad/reducer'
import salaryPackages from './payrollOnboarding/SalarySetup/reducer'
import onboarding_transaction_types from './payrollOnboarding/allowances/reducer'
import onboardingPaymentDetails from './payrollOnboarding/paymentReview/reducer'
// ======= Onboarding Start ======== //

// ======= Organization Onborading Start======== //
import onboardingAdmins from './organizationOnborading/admins/reducer'
import onboardingBulkUpload from './organizationOnborading/bulkUpload/reducer'
import businessUnits from './organizationOnborading/businessUnits/reducer'
import commercialRegistrationsOnborading from './organizationOnborading/commercialRegistrations/reducer'
import onboardingConstants from './organizationOnborading/constants/reducer'
import onboradingCountries from './organizationOnborading/countries/reducer'
import employees from './organizationOnborading/employees/reducer'
import employmentTypes from './organizationOnborading/employmentTypes/reducer'
import industries from './organizationOnborading/industries/reducer'
import invite from './organizationOnborading/invite/reducer'
import onboardingInviteAdmins from './organizationOnborading/inviteAdmins/reducer'
import jobTitles from './organizationOnborading/jobTitles/reducer'
import laborLawConfigurations from './organizationOnborading/laborLawConfigurations/reducer'
import locations from './organizationOnborading/locations/reducer'
import onboardingNationalities from './organizationOnborading/nationalities/reducer'
import organization from './organizationOnborading/organization/reducer'
import organizationConfigurations from './organizationOnborading/organizationConfigurations/reducer'
import outsourcingCompanies from './organizationOnborading/outsourcingCompanies/reducer'
import onboardingRoles from './organizationOnborading/roles/reducer'
import onboardingSteps from './organizationOnborading/steps/reducer'
// import hierarchicalDepartment from './organizationOnborading/hierarchicalDepartment/reducer';
import companyQuestion from './NewOnboarding/reducer'
import onboardingLeaveTypes from './leavesOnboarding/leaveTypes/reducer'
import onboardingHeaderSteps from './organizationOnborading/OnboardingHeaderSteps/reducer'
import VacationSalary from './organizationOnborading/VacationSalary/reducer'
import VacationSettlement from './organizationOnborading/VacationSettlement/reducer'
import onboardingAssets from './organizationOnborading/assets/reducer'
import jobTitlesSuggestions from './organizationOnborading/jobTitlesSuggestions/reducer'
import onboardingLocations from './organizationOnborading/newLocations/reducer'
import onboardingHelpCentre from './organizationOnborading/onboardingHelpCentre/reducer'
import onboardingQuestions from './organizationOnborading/onboardingQuestions/reducer'
import payrollCalculationConfiguration from './organizationOnborading/payrollCalculationConfiguration/reducer'
import trackingSteps from './organizationOnborading/trackingSteps/reducer'
import trainingVideos from './organizationOnborading/trainingVideos/reducer'
import onboardingLoans from './payrollOnboarding/Loans/reducer'
// ======= Organization Onborading End======== //

// ======= Analysis Start ======== //
import analysisCost from './analysis//financePayroll/cost/reducer'
import analysisTotalSalariess from './analysis//financePayroll/totalSalariess/reducer'
import assetsUtilization from './analysis/assets/assetUtilization/reducer'
import assetsStatuses from './analysis/assets/assetsStatuses/reducer'
import assetsTypesTotalCost from './analysis/assets/assetsTypesTotalCost/reducer'
import analysisBsenceRate from './analysis/attendanceLeaves/bsenceRate/reducer'
import analysisOvertime from './analysis/attendanceLeaves/overtime/reducer'
import workflowSaudization from './analysis/workflow/saudization/reducer'
import analysisWorkflowTurnover from './analysis/workflow/turnover/reducer'
// ======= Analysis End ======== //

// ======= Leaves Onborading Start ======== //
import onboardingApplyingLeaves from './leavesOnboarding/ApplyingLeaves/reducer'
import onboardingAnnualLeaveEntitlements from './leavesOnboarding/annualLeaveEntitlements/reducer'
import carryForword from './leavesOnboarding/carryForword/reducer'
import onboardingCountingDays from './leavesOnboarding/countingDays/reducer'
import onboardingHolidays from './leavesOnboarding/holidays/reducer'
import inviteEmployees from './leavesOnboarding/inviteEmployees/reducer'
import reviewLeaveDetails from './leavesOnboarding/leave/reducer'
import validationAdminInvited from './leavesOnboarding/validationAdminInvited/reducer'
// ======= Leaves Onborading End ======== //

// ======= Onboarding End ======== //

// =======Employee Module Start======== //
import employeeTasks from './employeeTasks/reducer'
import employeesAuditLog from './employees/AuditLog/reducer'
import addAdditionDeduction from './employees/addAdditionDeduction/reducer'
import addEmployeeValidate from './employees/addEmployeeValidate/reducer'
import employeeDevicesManagement from './employees/devicesManagement/reducer'
import editProfileRequests from './employees/editProfile/reducer'
import editWorkEmail from './employees/editWorkEmail/reducer'
import employeeContracts from './employees/employeeContracts/reducer'
import employeeInformationChanges from './employees/employeeInformationChanges/reducer'
import employeeProbationPeriod from './employees/employeeProbation/reducer'
import employeeExpenseReducer from './employees/employeeSalaryFinancial/employeeExpense/reducer'
import employeeFees from './employees/employeeSalaryFinancial/employeeFees/reducer'
import employeeSalaryFinancial from './employees/employeeSalaryFinancial/reducer'
import inviteAllEmployees from './employees/inviteAllEmployees/reducer'
import notifiers from './employees/notifiers/reducer'
import offboardingSteps from './employees/offboardingSteps/reducer'
import offboardingWorkflow from './employees/offboardingWorkflow/reducer'
import employeeQualifications from './employees/qualifications/reducer'
import employeesReducer from './employees/reducer'
import subordinates from './employees/subordinates/reducer'
import subordinatesOrgnization from './employees/subordinatesOrgnization/reducer'
import superAdmin from './employees/superAdmin/reducer'
import terminationDetailsReasons from './employees/terminationDetailsReasons/reducer'
import withoutGeofencingReducer from './employees/withoutGeofencing/reducer'
import glAccounts from './payroll/glMapping/glAccounts/reducer'
import glEmployee from './payroll/glMapping/glEmployee/reducer'
import glEmployees from './payroll/glMapping/glEmployees/reducer'
import glSetup from './payroll/glMapping/glSetup/reducer'
import journalStructure from './payroll/glMapping/journalStructure/reducer'
import subsidiaries from './payroll/glMapping/subsidiary/reducer'
import trackingDimension from './payroll/glMapping/trackingDimensions/reducer'
import payrollTransaction from './payroll/transactions/reducer'
import applicableRequestsReducer from './reducers/applicableRequestsReducer'
import employeeBenefitReducer from './reducers/employeeBenefitReducer'
import employeeClearanceTaskReducer from './reducers/employeeClearanceTaskReducer'
import employeeContractReducer from './reducers/employeeContractReducer'
import employeeFamilyDetailReducer from './reducers/employeeFamilyDetailReducer'
import employeeFileReducer from './reducers/employeeFileReducer'
import employeeInsuranceReducer from './reducers/employeeInsuranceReducer'
import employeeLeavesReducer from './reducers/employeeLeavesReducer'
import employeeLoanReducer from './reducers/employeeLoanReducer'
import employeeOffboardingReducer from './reducers/employeeOffboardingReducer'
import employeeReducer from './reducers/employeeReducer'
import expenseClaimRequestReducer from './reducers/expenseClaimRequestReducer'
import letterRequestsReducer from './reducers/letterRequestsReducer'
import letterTypesReducer from './reducers/letterTypesReducer'
import loanRequestReducer from './reducers/loanRequestReducer'
import offboardAdditionReducer from './reducers/offboardAdditionReducer'
import offboardDeductionReducer from './reducers/offboardDeductionReducer'
import qualificationReducer from './reducers/qualificationReducer'
import salaryAppraisalsReducer from './reducers/salaryAppraisalsReducer'

// =======Employee Module End======== //

// =======Performance Module======== //
import competencySets from './performance/CompetencySets/reducer'
import competencies from './performance/competencies/reducer'
import competencySetEligibleEmployees from './performance/competencySetEligibleEmployees/reducer'
import employeeProbation from './performance/employeeProbation/reducer'
import markedAsCompleted from './performance/markedAsCompleted/reducer'
import normalReviewCycles from './performance/normalReviewCycles/reducer'
import performanceNotification from './performance/notification/reducer'
import objectiveSets from './performance/objectiveSets/reducer'
import allObjectives from './performance/objectives/allObjectives/reducer'
import objectives from './performance/objectives/reducer'
import performanceAllEmployees from './performance/performanceEmployees/reducer'
import probationReviewCycles from './performance/probationReviewCycles/reducer'
import ratingConfigurations from './performance/ratingConfigurations/reducer'
import reviewCyclesEligibleEmployees from './performance/reviewCyclesEligibleEmployees/reducer'
import reviewRunObjectives from './performance/reviewRunObjectives/reducer'
import organizationRatingConfigurations from './performanceV2/organizationRatingConfigurations/reducer'
import reviewCyclesV2EligibleEmployees from './performanceV2/reviewCyclesEligibleEmployees/reducer'
// =======Performance End======== //

// =======PerformanceV2 Module======== //
import evaluationForms from './performanceV2/evaluationForms/reducer'
import pmNotification from './performanceV2/notification/reducer'
import pmOnboarding from './performanceV2/onboarding/reducer'
// =======PerformanceV2 End======== //

// ======== Assets ========= //
import assetAllocation from './assets/allocations/reducer'
import employeeAssets from './assets/employeeAssets/reducer'
import assetHistory from './assets/history/reducer'
import assets from './assets/reducer'
// ======= Assets End ======== //

// =======Setting Module Start======== //
// organisation setting
import organizationTasksInOffboarding from './employees/organizationTasks/reducer'
import tasksInOffboarding from './employees/tasks/reducer'
import areaReducer from './reducers/areaReducer'
import businessUnitReducer from './reducers/businessUnitReducer'
import companyDocumentReducer from './reducers/companyDocumentReducer'
import departmentReducer from './reducers/departmentReducer'
import jobTitleReducer from './reducers/jobTitleReducer'
import locationReducer from './reducers/locationReducer'
import organizationProfileReducer from './reducers/organizationProfileReducer'
import parentDepartmentReducer from './reducers/parentDepartmentReducer'
import accountSubscriptions from './setting/accountSubscriptions/reducer'
import consants from './setting/constants/reducer'
import countries from './setting/countries/reducer'
import industry from './setting/industries/reducer'
import medicalInsuranceProviders from './setting/medicalInsuranceProviders/reducer'
import subOrgBilling from './setting/orgSubsidiaries/Billing/reducer'
import subOrgRoles from './setting/orgSubsidiaries/Roles/reducer'
import switchableOrganizations from './setting/orgSubsidiaries/SwitchableOrgs/reducer'
import orgSubsidiaries from './setting/orgSubsidiaries/reducer'
import assetmanagerBulk from './setting/organizationSetting/assetmanagerBulkUpload/reducer'
import commercialRegistrationsReducer from './setting/organizationSetting/commercialRegistrations/reducer'
import molRegistrations from './setting/organizationSetting/molRegistrations/reducer'
import outSourcingCompanyReducer from './setting/organizationSetting/outSourcingCompany/reducer'
import organizationBanks from './setting/payrollManagement/organizationBanks/reducer'
import invoiceGenerated from './setting/payrollManagement/paymentMethods/invoiceGenerated/reducer'
import mudadIntegration from './setting/payrollManagement/paymentMethods/mudad/reducer'
import paymentMethods from './setting/payrollManagement/paymentMethods/reducer'
import countriesv2 from './setting/v2/countries/reducer'
import organizationProfilev2 from './setting/v2/organizationProfileReducer'
import parentDepartmentv2 from './setting/v2/parentDepartmentReducer'

// Employee Profile
import newDepartments from './newDepartments/reducer'
import assetTypeReducer from './reducers/assetTypeReducer'
import employmentDocumentTypeReducer from './reducers/employmentDocumentTypeReducer'
import employmentTypeReducer from './reducers/employmentTypeReducer'

// Attendance management
import absenceDeductionPolicyReducer from './reducers/absenceDeductionPolicyReducer'
import eligibleUsersReducer from './reducers/eligibleUsersReducer'
import noAttendancePolicyReducer from './reducers/noAttendancePolicyReducer'
import shiftsReducer from './reducers/shiftsReducer'
import attendancePolicy from './setting/attendanceManagement/attendancePolicy/reducer'
import attendanceReview from './setting/attendanceManagement/attendanceReview/reducer'
import calculateWorkingHours from './setting/attendanceManagement/calculateWorkingHours/reducer'
import comboShiftReducer from './setting/attendanceManagement/comboShift/reducer'
import delayDeductionPolicyConfiguration from './setting/attendanceManagement/delayDeductionPolicyConfiguration/reducer'
import fixedShiftReducer from './setting/attendanceManagement/fixedShift/reducer'
import geofencingReducer from './setting/attendanceManagement/geofencing/reducer'
import overtimePolicies from './setting/attendanceManagement/overtimePolicies/reducer'
import overtimePolicyConfiguration from './setting/attendanceManagement/overtimePolicyConfiguration/reducer'
import standardShiftReducer from './setting/attendanceManagement/standardShift/reducer'

// Leave management
import advanceVacationSalaryConfiguration from './setting/leaveManagement/advanceVacationSalaryConfiguration/reducer'
import entitlementAutoConvert from './setting/leaveManagement/entitlementAutoConvert/reducer'
import resumptionConfigurations from './setting/leaveManagement/resumptionConfigurations/reducer'
// Payroll management
import benefitTypeReducer from './reducers/benefitTypeReducer'
import costCenterReducer from './reducers/costCenterReducer'
import expenseTypeReducer from './reducers/expenseTypeReducer'
import gradeReducer from './reducers/gradeReducer'
import loanTypeReducer from './reducers/loanTypeReducer'
import ticketprovisionpolicies from './setting/leaveManagement/ticketProvisionPolicies/reducer'
import vacationSalaryCalculation from './setting/payrollManagement/VacationSalary/reducer'
import deductionsTypes from './setting/payrollManagement/deductionsTypes/reducer'
import hourluRatePolicies from './setting/payrollManagement/hourlyRate/reduecer'
import payType from './setting/payrollManagement/payType/reduecer'
import calculationConfiguration from './setting/payrollManagement/payrollCalculationConfiguration/reducer'

// Integration
import deviceReducer from './reducers/deviceReducer'
import integrationReducer from './reducers/integrationReducer'
import openApiKeyReducer from './setting/integration/APIKeys/reducer'
import foodics from './setting/integration/Foodics/reducer'
import enablerDataReducer from './setting/integration/IntegrationEnablers/reducer'
import microsoft_accounts from './setting/integration/Microsoft/microsoftAccounts/reducer'
import microsoft_cost_center from './setting/integration/Microsoft/microsoftCostCenter/reducer'
import microsoft_subsidiaries from './setting/integration/Microsoft/microsoftSubsidiaries/reducer'
import microsoft from './setting/integration/Microsoft/reducer'
import microsoft_finOps_accounts from './setting/integration/MicrosoftFinOps/microsoftFinOpsAccounts/reducer'
import microsoft_finOps_cost_center from './setting/integration/MicrosoftFinOps/microsoftFinOpsCostCenter/reducer'
import microsoft_finOps_subsidiaries from './setting/integration/MicrosoftFinOps/microsoftFinOpsSubsidiaries/reducer'
import microsoft_finOps from './setting/integration/MicrosoftFinOps/reducer'
import muqeem from './setting/integration/Muqeem/reducer'
import netsuit_accounts from './setting/integration/Netsuit/netsuitAccounts/reducer'
import netsuit_cost_center from './setting/integration/Netsuit/netsuitCostCenter/reducer'
import netsuitSubsidiaries from './setting/integration/Netsuit/netsuitSubsidiaries/reducer'
import netsuit from './setting/integration/Netsuit/reducer'
import odoo_accounts from './setting/integration/Odoo/odooAccounts/reducer'
import odoo_cost_center from './setting/integration/Odoo/odooCostCenter/reducer'
import odoo_subsidiaries from './setting/integration/Odoo/odooSubsidiaries/reducer'
import odoo from './setting/integration/Odoo/reducer'
import quickbooks_accounts from './setting/integration/QuickBooks/quickbooksAccounts/reducer'
import quickbooks_cost_center from './setting/integration/QuickBooks/quickbooksCostCenter/reducer'
import quickbooksReducer from './setting/integration/QuickBooks/reducer'
import ssoReducer from './setting/integration/SSOConfig/reducer'
import sap_business_one_subsidiaries from './setting/integration/SapBusinessOne/SAPBusinessOneSubsidiaries/reducer'
import sap_business_one_accounts from './setting/integration/SapBusinessOne/SapBusinessOneAccounts/reducer'
import sap_business_one_cost_center from './setting/integration/SapBusinessOne/SapBusinessOneCostCenter/reducer'
import sap_business_one from './setting/integration/SapBusinessOne/reducer'
import webhookReducer from './setting/integration/Webhooks/reducer'
import workableReducer from './setting/integration/Workable/reducer'
import xero from './setting/integration/Xero/reducer'
import xero_accounts from './setting/integration/Xero/xeroAccounts/reducer'
import xero_cost_center from './setting/integration/Xero/xeroCostCenter/reducer'
import zoho_books from './setting/integration/ZohoBooks/reducer'
import zoho_books_accounts from './setting/integration/ZohoBooks/zohoBooksAccounts/reducer'
import zoho_books_cost_center from './setting/integration/ZohoBooks/zohoBooksCostCenter/reducer'
import zoho_books_subsidiaries from './setting/integration/ZohoBooks/zohoBooksSubsidiaries/reducer'
import apiAccessPermissionsReducer from './setting/integration/appAggregators/reducer'
import easyClocking from './setting/integration/easyClocking/reducer'
import easyclockingLogs from './setting/integration/easyclockingLogs/reducer'
import gosiIntegration from './setting/integration/gosiIntegration/reducer'
import payrollElements from './setting/integration/payrollElements/reducer'
import integrations from './setting/v2/integration/reducer'

// organization tasks
import organizationTasksReducer from './setting/organizationTasks/reducer'

// public in project
import eligibleEmployees from './setting/eligibleEmployees/reducer'
import filters from './setting/filters/reducer'

// =======Setting Module End======== //

// =======Time Attendance Module Start======== //
import attendanceDashboardReducer from './attendance/dashboard/reducer'
import employeeAttendanceDetailsReducer from './attendance/employeeDetails/reducer'
import manualDeductionsReducer from './attendance/manualDeductions/reducer'
import manualOvertimeReducer from './attendance/manualOvertime/reducer'
import userShiftsManagementReducer from './attendance/shiftReplacement/reducer'
import overviewTimesheetsReducer from './attendance/timesheets/overview/reducer'
import pendingRequestReducer from './attendance/timesheets/pendingRequest/reducer'
import requestDetailsReducer from './attendance/timesheets/requestDetails/reducer'
import retroactiveReducer from './attendance/timesheets/retroactive/reducer'
import attendanceTimesheetsReducer from './attendance/timesheets/sheet/reducer'
import summarySheetReducer from './attendance/timesheets/summarySheet/reducer'
import attendanceTrackingReducer from './attendance/tracking/reducer'
import editTimeReducer from './reducers/editTimeReducer'
import excuseRequestReducer from './reducers/excuseRequestReducer'
import missingPunchRequestReducer from './reducers/missingPunchRequestReducer'
import overtimeRequestReducer from './reducers/overtimeRequestReducer'
import attendanceDetailsReport from './reports/attendanceDetailsReport/reducer'
import attendanceConfig from './setting/attendanceManagement/attendanceConfig/reducer'
import tawuniyaReducer from './setting/integration/Tawuniya/reducer'
import checkDeviceRecords from './timeAttendance/checkDeviceRecords/reducer'
import timeAttendance from './timeAttendance/reducer'
import unavailableAttendanceRecords from './timeAttendance/unavailableAttendanceRecords/reducer'

// =======Time Attendance Module End======== //

// ======= Schedulers Module start======== //
import addUserShiftReducer from './reducers/addUserShiftReducer'
import schedulerReducer from './scheduler/reducer'
// ======= Schedulers Module End======== //

// ========Leave Management Module Start ====== //
import delegationRequestsReducer from './leaves/DelegationRequest/reducer'
import exitReentryVisaRequestReducer from './leaves/ExitReentryVisaRequest/reducer'
import excuseRequestTypes from './leaves/excuseRequestTypes/reducer'
import jisrPay from './payroll/JisrPay/reducer'
import annualLeaveReducer from './reducers/annualLeaveReducer'
import businessTripCitiesReducer from './reducers/businessTripCitiesReducer'
import businessTripRequestsReducer from './reducers/businessTripRequestsReducer'
import carryForwardReducer from './reducers/carryForwardReducer'
import employeeLeaveTypesReducer from './reducers/employeeLeaveTypesReducer'
import exitReentryRequestReducer from './reducers/exitReentryRequestReducer'
import holidayReducer from './reducers/holidayReducer'
import businessTripPolicyReducer from './reducers/leaveManagementBusinessTripReducer'
import leaveManagementConfigurationReducer from './reducers/leaveManagementConfigurationReducer'
import leaveRequestsDetailReducer from './reducers/leaveRequestsDetailReducer'
import leaveRequestsReducer from './reducers/leaveRequestsReducer'
import leaveTimeLinesReducer from './reducers/leaveTimeLinesReducer'
import leaveTypeReducer from './reducers/leaveTypeReducer'
import ticketRequestReducer from './reducers/ticketRequestReducer'
import vacationSalaryReducer from './reducers/vacationSalaryReducer'
import employeeLeaveDays from './setting/employeeLeaveDays/reducer'

// ========Leave Management Module End ====== //

// Request Workflow Start === //
import businessTripRequestWorkflowReducer from './reducers/businessTripRequestWorkflowReducer'
import excuseRequestWorkflowReducer from './reducers/excuseRequestWorkflowReducer'
import expenseClaimRequestWorkflowReducer from './reducers/expenseClaimRequestWorkflowReducer'
import infoChangeRequestWorkflowReducer from './reducers/infoChangeRequestWorkflowReducer'
import leaveRequestWorkflowReducer from './reducers/leaveRequestWorkflowReducer'
import letterRequestWorkflowReducer from './reducers/letterRequestWorkflowReducer'
import loanRequestWorkflowReducer from './reducers/loanRequestWorkflowReducer'
import missingPunchRequestWorkflowReducer from './reducers/missingPunchRequestWorkflowReducer'
import overtimeRequestWorkflowReducer from './reducers/overtimeRequestWorkflowReducer'
import workflowReducer from './reducers/workflowReducer'
import eligiblePeople from './setting/eligiblePeople/reducer'
import assetsRequestWorkflowReducer from './setting/requestWorkflow/assetsRequest/reducer'
import editProfileWorkflowReducer from './setting/requestWorkflow/editProfileRequest/reducer'
import exitreenteryRequestReducer from './setting/requestWorkflow/exitreenteryvisa/reducer'
import flightTicketsRequestWorkflowReducer from './setting/requestWorkflow/flightTicketsRequest/reducer'
import hiringRequestWorkflowReducer from './setting/requestWorkflow/hiringRequest/reducer'
import leaveWorkflow from './setting/requestWorkflow/leaveRequest/reducer'
import resignationRequestWorkflowReducer from './setting/requestWorkflow/resignationRequest/reducer'
import workflowStepsManagement from './setting/requestWorkflow/workflowStepsManagement/reducer'

// Request Workflow End === //

// ======= Security Policies Mamangement Start =======
import securityReducer from './setting/security/reducer'
// ======= Security Policies Mamangement End =======

// ======= Custom Fields Start =======
import customFieldsReducer from './reducers/customFieldsReducer'
import employeeCustomFieldReducer from './reducers/employeeCustomFieldReducer'
// ======= Custom Fields End =======

// =======Payroll Module Start======== //
import finalSettelementWorkflow from './payroll/FinalSettelementWorkflow/reducer'
import offCyclePayrun from './payroll/OffCyclePayrun/reducer'
import vacationSettelementWorkflow from './payroll/VacationSettelementWorkflow/reducer'
import payrollApprovals from './payroll/approvalCycle/reducer'
import employeeExcluded from './payroll/employeeExcluded/reducer'
import employeeNewlyAdded from './payroll/employeeNewlyAddedd/reducer'
import payrollRemarks from './payroll/payrollRemarks/reducer'
import payrun from './payroll/payrun/reducer'
import payrunAdjustments from './payroll/payrunAdjustments/reducer'
import payrunEvent from './payroll/payrunEvents/reducer'
import payrunTask from './payroll/payrunTasks/reducer'
import runPayrun from './payroll/runPayrun/reducer'
import payrunSummary from './payroll/summaryView/reducer'
import suspendEmployee from './payroll/suspendEmployee/reducer'
// import payroll from './payroll/reducer';

// =======Payroll Module End======== //

// =======Report Module Start======== //
import reportReducer from './reducers/reportReducer'
import offCyclePayRunReport from './reports/OffCyclePayRunReport/reducer'
import attendanceLogsReport from './reports/attendanceLogs/reducer'
import attendanceReport from './reports/attendanceReport/reducer'
import attendanceSummaryReport from './reports/attendanceSummary/reducer'
import attendanceTrackingReport from './reports/attendanceTracking/reducer'
import autocompleteUsers from './reports/autoEmployees/reducer'
import businessTripReport from './reports/businessTrip/reducer'
import customReports from './reports/customReports/reducer'
import employeeInformationChangesReport from './reports/employeeInformationChangesReport/reducer'
import employeeLoanReport from './reports/employeeLoan/reducer'
import empMasterReport from './reports/employeeMaster/reducer'
import eosReport from './reports/eosReport/reducer'
import expenseClaimReport from './reports/expenseClaim/reducer'
import expiringDocuments from './reports/expiringDocuments/reducer'
import exportHistory from './reports/exportHistory/reducer'
import familyDetails from './reports/familyDetails/reducer'
import finalSettlementReport from './reports/finalSettlementReport/reducer'
import gosiReport from './reports/gosiReport/reducer'
import loansReport from './reports/loansReport/reducer'
import outOfPayrollReport from './reports/outOfPayroll/reducer'
import outsourcingEmployees from './reports/outsourcingEmployees/reducer'
import payrunReport from './reports/payroll/reducer'
import payslipReport from './reports/payslip/reducer'
import { performanceReportsReducer } from './reports/performance/reducers'
import revenuesReport from './reports/revenuesReport/reducer'
import ticketReport from './reports/ticketRreport/reducer'

// =======Report Module End======== //

// =======Roles and Permissions Start======== //
import roles from './setting/roles/reducer'
// =======Roles and Permissions End======== //

import bulkImportUploadReducer from './reducers/bulkImportUploadReducer'
import configurationReducer from './reducers/configurationReducer'
import filterReducer from './reducers/filterReducer'
import staticRecordReducer from './reducers/staticRecordReducer'

import leaves from './leaves/reducer'
import letterTemplates from './letterTemplates/reducer'
// ======= notification Management ===========//
import notificationManagementReducer from './setting/notificationManagement/reducer'
import subscription from './setting/subscription/reducer'
import weeklyEmailsNotifications from './setting/weeklyEmailsNotifications/reducer'

// Payment processing
import paymentProcessing from './PaymentProcessing/reducer'
import newPaymentProcessing from './payroll/NewPaymentProcessing/reducer'
// =======Muqeem Services Start======== //
import muqeemServices from './MuqeemServices/reducer'
// =======Muqeem Services End======== //

// ======= Custom requests Start ======== //
import customRequestsRequestTypes from './reports/customRequests/requestTypes/reducer'
import customWorkflows from './reports/customRequests/workflows/reducer'
// ======= Custom requests End ======== //

// ======= Billing End======== //
import mudadSubscriptions from './setting/paymentInfo/addOns/reducer'
import annualCancellation from './setting/paymentInfo/annualCancellation/reducer'
import mudadSubscriptionsHistory from './setting/paymentInfo/history/reducer'
import mudadSubscriptionNotifications from './setting/paymentInfo/notifications/reducer'
// ======= Billing Start ======== //

import publicTemplates from './publicTemplates/reducer'

// ======= App Notifications Start ======== //
import notificationsSetting from './appNotifications/NotificationsSetting/reducer'
import markAsRead from './appNotifications/markAsRead/reducer'
import appNotifications from './appNotifications/reducer'
// ======= App Notifications End ======== //

// ======= GOSI Start ======== //
import gosiEmployeeDetails from './gosi/EmployeeDetails/reducer'
import gosiNonSaudi from './gosi/NonSaudi/reducer'
import gosiSaudi from './gosi/Saudi/reducer'
import gosi from './gosi/reducer'
// ======= GOSI End ======== //

// =======ATS Module Start======== //
import atsPublicTemplate from './ATS/ATSPuplic/reducer'
import CandidateView from './ATS/CandidateView/reducer'
import jobApplicants from './ATS/jobApplicationInfo/jobApplicants/reducer'
// ======= Setting Module End ======== //

// =======Automation Start======== //
import automations from './automationWorkflow/automations/reducer'
import automationsHistory from './automationWorkflow/automationsHistory/reducer'
import automationsTriggers from './automationWorkflow/automationsTriggers/reducer'

// ======= Automation End ======== //

// ======= Benefits Management Module Start ======== //
import endorsements from './benifitsManagement/Insurance/Endorsements/reducer'
import policies from './benifitsManagement/Insurance/Policies/reducer'
import providers from './benifitsManagement/Insurance/Providers/reducer'

// ======= Benefits Management Module End ======== //

// ======= Overview Start ======== //
import attendanceSummary from './Overview/AttendanceSummary/reducer'
import invitationsTracking from './Overview/InvitationsTracking/reducer'
import payslips from './Overview/Payslips/reducer'
// ======= Overview End ======== //

const rootReducer = combineReducers({
  auth: authReducer,
  changePassword,
  toggleNavigation,
  undoAlerts,
  constants,
  marketplace: marketplaceReducers,

  // ======= Analysis Start======== //
  analysisCost,
  analysisTotalSalariess,
  analysisBsenceRate,
  analysisOvertime,
  workflowSaudization,
  analysisWorkflowTurnover,
  assetsStatuses,
  assetsTypesTotalCost,
  assetsUtilization,
  // ======= Analysis End======== //

  // ======= payroll Onboarding ======== //
  payschedule,
  salaryPackages,
  onboardingBankAccounts,
  onboarding_transaction_types,
  onboardingPaymentDetails,
  onBoardingMudad,
  // ======= payroll Onboarding ======== //

  // ======= Onboarding Start ======== //
  // ======= Organization Onborading Start======== //
  onboardingAdmins,
  onboardingInviteAdmins,
  onboardingSteps,
  businessUnits,
  commercialRegistrationsOnborading,
  laborLawConfigurations,
  industries,
  jobTitles,
  locations,
  organization,
  organizationConfigurations,
  onboradingCountries,
  employmentTypes,
  outsourcingCompanies,
  onboardingBulkUpload,
  reviewingEmployee: employees,
  onboardingNationalities,
  onboardingRoles,
  invite,
  onboardingConstants,
  onboardingLeaveTypes,
  VacationSalary,
  payrollCalculationConfiguration,
  VacationSettlement,
  onboardingAssets,
  onboardingLoans,
  trackingSteps,
  trainingVideos,
  onboardingLocations,
  onboardingQuestions,
  companyQuestion,
  onboardingHeaderSteps,
  jobTitlesSuggestions,
  onboardingHelpCentre,
  // ======= Organization Onborading End======== //

  // ======= Leaves Onborading Start ======== //
  onboardingCountingDays,
  carryForword,
  onboardingApplyingLeaves,
  reviewLeaveDetails,
  inviteEmployees,
  validationAdminInvited,
  onboardingHolidays,
  onboardingAnnualLeaveEntitlements,
  // ======= Leaves Onborading End ======== //

  // ======= Onboarding End ======== //

  // ======= Requests Start======== //
  requests: requestsReducer,
  notifications: notificationsReducer,
  requestsApproversList: requestsApproversListReducer,
  announcements: announcementsReducer,
  leaveCancellationRequest: leaveCancellationRequestsReducer,
  requestComments,
  requestsApprovals: requestsApprovalsReducer,
  tasks: tasksReducer,
  assetRequests: assetRequestsReducer,
  resignationRequests: resignationRequestsReducer,
  hiringRequests: hiringRequestsReducer,
  pendingTasksRequests,
  leaveExtensionsRequest,
  newlyJoinedUsers,
  requestsExpiringDocuments,
  nextPayroll,
  attendancesOverview,
  offlineDevices,
  orgnaization_billing: BillingReducer,
  employeesWithoutRcs,
  approvals,
  customRequests,
  // ======= Requests End======== //

  // =======Employee Module Start======== //
  addAdditionDeduction,
  employee: employeeReducer,
  employee_salary_financial: employeeSalaryFinancial,
  employee_expense: employeeExpenseReducer,
  employee_loan: employeeLoanReducer,
  employee_family_detail: employeeFamilyDetailReducer,
  employeeQualifications,
  employee_file: employeeFileReducer,
  employee_leaves: employeeLeavesReducer,
  employee_insurance: employeeInsuranceReducer,
  qualification: qualificationReducer,
  employeeBenefit: employeeBenefitReducer,
  employeeOffboarding: employeeOffboardingReducer,
  offboardDeduction: offboardDeductionReducer,
  offboardAddition: offboardAdditionReducer,
  employeeClearanceTask: employeeClearanceTaskReducer,
  employeeProbationPeriod,
  employeeContract: employeeContractReducer,
  applicableRequests: applicableRequestsReducer,
  salaryAppraisals: salaryAppraisalsReducer,
  loanRequest: loanRequestReducer,
  letterRequest: letterRequestsReducer,
  letterTypes: letterTypesReducer,
  expenseClaimRequest: expenseClaimRequestReducer,
  employeeTasks,
  addEmployeeValidate,
  subordinates,
  offboardingWorkflow,
  subordinatesOrgnization,
  offboardingSteps,
  terminationDetailsReasons,
  payrollTransaction,
  employeeContracts,
  editWorkEmail,
  inviteAllEmployees,
  superAdmin,
  editProfileRequests,
  employeeFees,
  employeesAuditLog,
  // =======Employee Module Start======== //

  // ======= Performance ======== //
  objectiveSets,
  reviewRunObjectives,
  objectives,
  allObjectives,
  competencySets,
  competencySetEligibleEmployees,
  competencies,
  normalReviewCycles,
  probationReviewCycles,
  reviewCyclesEligibleEmployees,
  markedAsCompleted,
  ratingConfigurations,
  performanceNotification,
  performanceAllEmployees,
  employeeProbation,
  // ======= Performance ======== //

  // ======= PerformanceV2 ======== //
  evaluationForms,
  reviewCyclesV2EligibleEmployees,
  organizationRatingConfigurations,
  pmNotification,
  pmOnboarding,
  // ======= PerformanceV2 ======== //

  // ======= Assets ======== //
  assets,
  assetHistory,
  assetAllocation,
  employeeAssets,
  // ======================== //

  // =======Setting Module Start======== //
  employees: employeesReducer,
  organizationProfile: organizationProfileReducer,
  organizationProfilev2,
  businessUnit: businessUnitReducer,
  area: areaReducer,
  parentDepartment: parentDepartmentReducer,
  parentDepartmentv2,
  orgSubsidiaries,
  subOrgBilling,
  subOrgRoles,
  switchableOrganizations,
  accountSubscriptions,
  location: locationReducer,
  department: departmentReducer,
  jobTitle: jobTitleReducer,
  companyDocument: companyDocumentReducer,
  medicalInsuranceProviders,
  outSourcingCompany: outSourcingCompanyReducer,
  commercialRegistrations: commercialRegistrationsReducer,
  geofencing: geofencingReducer,
  organizationBanks,
  paymentMethods,
  invoiceGenerated,
  mudadIntegration,
  countries,
  countriesv2,
  industry,
  standardShift: standardShiftReducer,
  comboShift: comboShiftReducer,
  fixedShift: fixedShiftReducer,

  // Employee Profile
  employmentType: employmentTypeReducer,
  employmentDocumentType: employmentDocumentTypeReducer,
  assetType: assetTypeReducer,
  newDepartments,
  withoutGeofencing: withoutGeofencingReducer,
  notifiers,
  employeeInformationChanges,
  employeeDevicesManagement,
  organizationTasksInOffboarding,
  tasksInOffboarding,
  // Attendance management
  shifts: shiftsReducer,
  eligibleUsers: eligibleUsersReducer,
  absenceDeductionPolicy: absenceDeductionPolicyReducer,
  noAttendancePolicy: noAttendancePolicyReducer,
  // Leave Management - Setting
  resumptionConfigurations,
  advanceVacationSalaryConfiguration,
  entitlementAutoConvert,
  // Payroll Management
  grade: gradeReducer,
  costCenter: costCenterReducer,
  expenseType: expenseTypeReducer,
  loanType: loanTypeReducer,
  benefitType: benefitTypeReducer,
  ticketprovisionpolicies,
  deductionsTypes,
  calculationConfiguration,
  hourluRatePolicies,
  payType,
  vacationSalaryCalculation,
  // Leave Management
  businessTripPolicy: businessTripPolicyReducer,
  holiday: holidayReducer,
  leaveConfiguration: leaveManagementConfigurationReducer,
  leaveType: leaveTypeReducer,
  annualLeave: annualLeaveReducer,
  // Integration
  integration: integrationReducer,
  quickbooks: quickbooksReducer,
  device: deviceReducer,
  easyClocking,
  easyclockingLogs,
  netsuit,
  foodics,
  muqeem,
  netsuit_cost_center,
  netsuit_accounts,
  quickbooks_accounts,
  quickbooks_cost_center,
  gosiIntegration,
  revenuesReport,
  payrollElements,
  integrations,
  netsuitSubsidiaries,
  xero,
  xero_cost_center,
  xero_accounts,
  microsoft,
  microsoft_cost_center,
  microsoft_accounts,
  microsoft_subsidiaries,
  odoo,
  odoo_subsidiaries,
  odoo_cost_center,
  odoo_accounts,
  microsoft_finOps,
  microsoft_finOps_cost_center,
  microsoft_finOps_accounts,
  microsoft_finOps_subsidiaries,
  zoho_books,
  zoho_books_cost_center,
  zoho_books_accounts,
  zoho_books_subsidiaries,
  sap_business_one,
  sap_business_one_cost_center,
  sap_business_one_accounts,
  sap_business_one_subsidiaries,
  // organization tasks
  organizationTasks: organizationTasksReducer,
  // custom fields
  customFields: customFieldsReducer,
  customFieldValues: employeeCustomFieldReducer,
  // Security management
  securityReducer,

  openApiKeyReducer,
  apiAccessPermissionsReducer,
  enablerDataReducer,
  workableReducer,
  webhookReducer,
  tawuniyaReducer,
  ssoReducer,

  // =======public in project======== //
  filters,
  eligibleEmployees,
  // =======Setting Module End======== //

  // Request Workflow
  workflowDetails: workflowReducer,
  infoChangeRequestWorkflow: infoChangeRequestWorkflowReducer,
  loanRequestWorkflow: loanRequestWorkflowReducer,
  expenseClaimRequestWorkflow: expenseClaimRequestWorkflowReducer,
  letterRequestWorkflow: letterRequestWorkflowReducer,
  overtimeRequestWorkflow: overtimeRequestWorkflowReducer,
  missingPunchRequestWorkflow: missingPunchRequestWorkflowReducer,
  excuseRequestWorkflow: excuseRequestWorkflowReducer,
  businessTripRequestWorkflow: businessTripRequestWorkflowReducer,
  flightTicketsRequestWorkflow: flightTicketsRequestWorkflowReducer,
  leaveRequestWorkflow: leaveRequestWorkflowReducer,
  assetsRequestWorkflow: assetsRequestWorkflowReducer,
  resignationRequestWorkflow: resignationRequestWorkflowReducer,
  hiringRequestWorkflow: hiringRequestWorkflowReducer,
  workflowStepsManagement,
  exitReenteryRequestWorkflow: exitreenteryRequestReducer,
  eligiblePeople,
  leaveWorkflow,
  editProfileWorkflow: editProfileWorkflowReducer,
  molRegistrations,
  assetmanagerBulk,
  // =======Time Attendance Module Start======== //
  timeAttendance,
  overtimeRequest: overtimeRequestReducer,
  missingPunchRequest: missingPunchRequestReducer,
  excuseRequest: excuseRequestReducer,
  checkDeviceRecords,
  unavailableAttendanceRecords,
  calculateWorkingHours,
  delayDeductionPolicyConfiguration,
  overtimePolicies,
  overtimePolicyConfiguration,
  attendanceConfig,
  attendancePolicy,
  attendanceDetailsReport,
  editTime: editTimeReducer,
  attendanceReview,
  attendanceTracking: attendanceTrackingReducer,
  attendanceDashboard: attendanceDashboardReducer,
  manualOvertime: manualOvertimeReducer,
  manualDeductions: manualDeductionsReducer,
  attendanceTimesheets: attendanceTimesheetsReducer,
  overviewTimesheet: overviewTimesheetsReducer,
  summarySheet: summarySheetReducer,
  pendingRequest: pendingRequestReducer,
  retroactive: retroactiveReducer,
  requestDetails: requestDetailsReducer,
  employeeAttendanceDetails: employeeAttendanceDetailsReducer,
  attendanceUserShifts: userShiftsManagementReducer,
  // =======Time Attendance Module end======== //

  // ======= Schedulers Module Start======== //
  schedulersUserShift: addUserShiftReducer,
  scheduler: schedulerReducer,
  jisrPay,
  // ======= Schedulers Module end======== //

  // =======Leave Management Module Start======== //
  leaveTimeLines: leaveTimeLinesReducer,
  employeeLeaveTypes: employeeLeaveTypesReducer,
  leaveRequestsDetail: leaveRequestsDetailReducer,
  leaveRequests: leaveRequestsReducer,
  businessTripCities: businessTripCitiesReducer,
  businessTripRequests: businessTripRequestsReducer,
  delegationRequests: delegationRequestsReducer,
  exitReentryVisaRequest: exitReentryVisaRequestReducer,
  carryForwardReducer,
  vacationSalary: vacationSalaryReducer,
  ticketRequest: ticketRequestReducer,
  exitReentryRequest: exitReentryRequestReducer,
  employeeLeaveDays,
  excuseRequestTypes,
  // =======Leave Management Module end======== //

  // =======Payroll Start======== //
  payrunAdjustments,
  runPayrun,
  suspendEmployee,
  payrunSummary,
  payrollNewAdded: employeeNewlyAdded,
  payrunEmployeesExcluded: employeeExcluded,
  subsidiaries,
  trackingDimension,
  offCyclePayrun,
  vacationSettelementWorkflow,
  finalSettelementWorkflow,
  journalStructure,
  glAccounts,
  glEmployees,
  glSetup,
  glEmployee,
  // =======Payroll end======== //

  // =======Payroll Start======== //
  report: reportReducer,
  outOfPayrollReport,
  employeeLoanReport,
  businessTripReport,
  customReports,
  expiringDocuments,
  expenseClaimReport,
  attendanceSummaryReport,
  attendanceLogsReport,
  attendanceTrackingReport,
  attendanceReport,
  eosReport,
  payrunReport,
  autocompleteUsers,
  gosiReport,
  empMasterReport,
  ticketReport,
  finalSettlementReport,
  loansReport,
  outsourcingEmployees,
  employeeInformationChangesReport,
  payrollApprovals,
  payrollRemarks,
  payrun,
  familyDetails,
  payrunEvent,
  payrunTask,
  offCyclePayRunReport,
  payslipReport,

  ...performanceReportsReducer,
  // =======Payroll end======== //
  paymentProcessing,
  newPaymentProcessing,
  // =======Roles and Permissions======== //
  roles,
  // =======Roles and Permissions======== //
  staticRecord: staticRecordReducer,
  configuration: configurationReducer,
  bulkImport: bulkImportUploadReducer,
  filterReducer,
  letterTemplates,
  leaves,
  // ======= notification Management ===========//
  notificationManagement: notificationManagementReducer,
  // ======= Setting ===========//
  consants,
  subscription,
  weeklyEmailsNotifications,
  // ======= Setting ===========//
  // =======Muqeem Services Start======== //
  muqeemServices,
  // =======Muqeem Services End======== //

  // ======= Custom requests Start ======== //
  customRequestsRequestTypes,
  customWorkflows,
  // ======= Custom requests End ======== //

  // ======= App Notifications Start ======== //
  appNotifications,
  markAsRead,
  notificationsSetting,
  // ======= App Notifications End ======== //

  // =======Billing Start======== //
  mudadSubscriptions,
  mudadSubscriptionsHistory,
  mudadSubscriptionNotifications,
  annualCancellation,
  // =======Billing End======== //

  // ======= GOSI Start ======== //
  gosi,
  gosiEmployeeDetails,
  gosiNonSaudi,
  gosiSaudi,
  // ======= GOSI End ======== //

  publicTemplates,

  // =======ATS Module Start======== //
  jobApplicants,
  CandidateView,
  atsPublicTemplate,
  // =======Setting Module End======== //
  // =======Overview Start======== //
  invitationsTracking,
  attendanceSummary,
  payslips,
  // =======Overview End======== //
  automations,
  automationsTriggers,
  automationsHistory,
  // ======= Automation End ======== //

  // ======= Benefits Management Module Start ======== //
  endorsements,
  policies,
  providers,
  // ======= Benefits Management Module End ======== //

  // ======= Eexport History Module Start ======== //
  exportHistory,
  // ======= Eexport History Module End ======== //
})

export default rootReducer
