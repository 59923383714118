import i18n from 'translations/i18n'
import FilterBox from '../FilterBox'
import { returnBadgeList } from '../helper'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type CountryTypes = {
  filterKey: string
  mixPanelEventName?: string
  filters: string[]
}

const Country = (props: CountryTypes): JSX.Element => {
  const { filterKey, mixPanelEventName } = props
  const { country } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(country)

  return (
    <FilterBox
      title={i18n.t('country')}
      handleFilter={handleFilter}
      filterKey={filterKey}
      selectedIds={filter[filterKey]}
      options={optionList}
      mixPanelEventName={mixPanelEventName}
      filters={props.filters}
    />
  )
}

export default Country
