export const ATTENDANCE_CALCULATION_LOAD = 'ATTENDANCE_CALCULATION_LOAD'
export const ATTENDANCE_CALCULATION_LOAD_PENDING = 'ATTENDANCE_CALCULATION_LOAD_PENDING'
export const ATTENDANCE_CALCULATION_LOAD_FULFILLED = 'ATTENDANCE_CALCULATION_LOAD_FULFILLED'
export const ATTENDANCE_CALCULATION_LOAD_REJECTED = 'ATTENDANCE_CALCULATION_LOAD_REJECTED'

export const ATTENDANCE_CALCULATION_UPDATE = 'ATTENDANCE_CALCULATION_UPDATE'
export const ATTENDANCE_CALCULATION_UPDATE_PENDING = 'ATTENDANCE_CALCULATION_UPDATE_PENDING'
export const ATTENDANCE_CALCULATION_UPDATE_FULFILLED = 'ATTENDANCE_CALCULATION_UPDATE_FULFILLED'
export const ATTENDANCE_CALCULATION_UPDATE_REJECTED = 'ATTENDANCE_CALCULATION_UPDATE_REJECTED'

export const VACATION_SETTLEMENT_CALCULATION_LOAD = 'VACATION_SETTLEMENT_CALCULATION_LOAD'
export const VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING =
  'VACATION_SETTLEMENT_CALCULATION_LOAD_PENDING'
export const VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED =
  'VACATION_SETTLEMENT_CALCULATION_LOAD_FULFILLED'
export const VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED =
  'VACATION_SETTLEMENT_CALCULATION_LOAD_REJECTED'

export const VACATION_SETTLEMENT_CALCULATION_UPDATE = 'VACATION_SETTLEMENT_CALCULATION_UPDATE'
export const VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING =
  'VACATION_SETTLEMENT_CALCULATION_UPDATE_PENDING'
export const VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED =
  'VACATION_SETTLEMENT_CALCULATION_UPDATE_FULFILLED'
export const VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED =
  'VACATION_SETTLEMENT_CALCULATION_UPDATE_REJECTED'

export const FINAL_SETTLEMENT_CALCULATION_LOAD = 'FINAL_SETTLEMENT_CALCULATION_LOAD'
export const FINAL_SETTLEMENT_CALCULATION_LOAD_PENDING = 'FINAL_SETTLEMENT_CALCULATION_LOAD_PENDING'
export const FINAL_SETTLEMENT_CALCULATION_LOAD_FULFILLED =
  'FINAL_SETTLEMENT_CALCULATION_LOAD_FULFILLED'
export const FINAL_SETTLEMENT_CALCULATION_LOAD_REJECTED =
  'FINAL_SETTLEMENT_CALCULATION_LOAD_REJECTED'

export const FINAL_SETTLEMENT_CALCULATION_UPDATE = 'FINAL_SETTLEMENT_CALCULATION_UPDATE'
export const FINAL_SETTLEMENT_CALCULATION_UPDATE_PENDING =
  'FINAL_SETTLEMENT_CALCULATION_UPDATE_PENDING'
export const FINAL_SETTLEMENT_CALCULATION_UPDATE_FULFILLED =
  'FINAL_SETTLEMENT_CALCULATION_UPDATE_FULFILLED'
export const FINAL_SETTLEMENT_CALCULATION_UPDATE_REJECTED =
  'FINAL_SETTLEMENT_CALCULATION_UPDATE_REJECTED'
