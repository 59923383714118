import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@jisr-hr/ds'
import { isEmpty } from 'lodash'
import { mixPanelAnalytics } from 'utils/mixpanel'
import i18n from 'translations/i18n'

import { useFilters } from './components/context'
import FilterBoxHeader from './FilterBox/FilterBoxHeader'
import FilterBoxFooter from './FilterBox/FilterBoxFooter'
import FilterBoxList from './FilterBox/FilterBoxList'
import { canShowClearFilter } from './helper'

const FilterBox = ({
  title,
  options,
  filterKey,
  childKey,
  labelKey,
  valueKey,
  optionKey,
  groupKey = '',
  subOptionKey,
  subOptionFKey,
  optionFilterKey,
  subOptionFilterKey,
  handleFilter,
  selectedIds = [],
  subOptions = [],
  subSelectedIds = [],
  componentType,
  listBoxStyle = {},
  isClearable = true,
  mixPanelEventName,
  filters,
}) => {
  const { filter, setFilter } = useFilters()
  const [isCollapsed, setisCollapsed] = useState(false)
  const [optionList, setOptionList] = useState([])
  const [flatOptionList, setFlatOptionList] = useState([])
  const [isShownMore, setIsShownMore] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const Ids = !isEmpty(subOptions) ? subSelectedIds : selectedIds
  const isMultiLevel = componentType === 'multiLevel'
  const isMultiGroup = componentType === 'grouped'

  const handleCollabseFilterBox = () => {
    setisCollapsed(!isCollapsed)
    if (mixPanelEventName) {
      mixPanelAnalytics({
        event_name: `section_arrow_click_inside_the_filter_side_menu_${mixPanelEventName}`,
        properties: {
          section_title: i18n.t(title),
          arrow_action: isCollapsed ? 'Section Open' : 'Section Close',
        },
      })
    }
  }

  let flatOptions = []
  const appendData = (da) => {
    const ids = flatOptions.map((a) => a.id)
    if (!ids.includes(da.id)) {
      flatOptions = flatOptions.concat(da).flat()
    }
  }

  const concatAllDepartments = (i) => {
    appendData(i)
    if (!isEmpty(i.departments)) {
      i.departments.map((j) => {
        appendData(j)
        if (!isEmpty(j.departments)) {
          concatAllDepartments(j)
        }
        return null
      })
    }
  }

  const handleClearFilter = (e) => {
    if (!isEmpty(subOptions)) {
      setFilter((prev) => ({ ...prev, [optionFilterKey]: [] }))
      setFilter((prev) => ({ ...prev, [subOptionFilterKey]: [] }))
    } else {
      const isStatuses = ['statuses', 'status_employee'].includes(filterKey)
      if (isStatuses) {
        setFilter((prev) => ({ ...prev, statuses: [], status_employee: [] }))
      } else {
        setFilter((prev) => ({ ...prev, [filterKey]: [] }))
      }
    }
    e.stopPropagation()
    if (mixPanelEventName) {
      mixPanelAnalytics({
        event_name: `clear_button_click_inside_the_filter_side_menu_${mixPanelEventName}`,
        properties: {
          section_title: i18n.t(title),
        },
      })
    }
  }

  const handleShowMore = () => {
    if (mixPanelEventName) {
      mixPanelAnalytics({
        event_name: `show_less/more_click_inside_the_filter_side_menu_${mixPanelEventName}`,
        properties: {
          Action: isShownMore ? 'show_less' : 'show_more',
          section_title: i18n.t(title),
        },
      })
    }

    if (isShownMore) {
      const sliceOptions = options.slice(0, isMultiLevel ? 5 : 10)
      setOptionList(sliceOptions)
    } else {
      setOptionList(options)
    }
    setIsShownMore(!isShownMore)
  }

  const handleOnSearch = (value) => {
    setSearchKey(value)
    if (!isMultiLevel) {
      if (value) {
        const filteredList = options.filter((opt) => {
          const name1 = opt.name_i18n?.toLowerCase() || ''
          const name2 = opt.name?.toLowerCase() || ''
          const name3 = opt.name_ar?.toLowerCase() || ''
          const searchV = value?.toLowerCase() || ''
          return name1.includes(searchV) || name2.includes(searchV) || name3.includes(searchV)
        })
        setOptionList(filteredList)
      } else if (!isShownMore) {
        const tenOptions = options.slice(0, 10)
        setOptionList(tenOptions)
      } else {
        setOptionList(options)
      }
    } else {
      setOptionList(!isShownMore ? options.slice(0, 5) : options)
    }
  }

  const canShowClear = canShowClearFilter(filter, filterKey, optionFilterKey, subOptionFilterKey)

  useEffect(() => {
    const sliceVal = isMultiLevel ? 5 : 10
    if (options.length <= sliceVal) {
      setOptionList(options)
    } else {
      const sliceOptions = options.slice(0, sliceVal)
      setOptionList(sliceOptions)
      setIsShownMore(false)
    }
  }, [])

  useEffect(() => {
    handleOnSearch(searchKey)
  }, [isShownMore])

  useEffect(() => {
    if (isMultiLevel) {
      options.map((opt) => {
        return concatAllDepartments(opt)
      })
      setFlatOptionList(flatOptions)
    }
  }, [options, isMultiLevel])

  return (
    <div>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={isClearable && canShowClear}
          handleClearFilter={handleClearFilter}
          handleCollabseFilterBox={handleCollabseFilterBox}
          isCollapsed={isCollapsed}
          title={title}
        />
        {!isCollapsed && (
          <FilterBoxList
            Ids={Ids}
            childKey={childKey}
            labelKey={labelKey}
            valueKey={valueKey}
            filterKey={filterKey}
            optionKey={optionKey}
            groupKey={groupKey}
            subOptionKey={subOptionKey}
            subOptionFilterKey={subOptionFilterKey}
            subOptionFKey={subOptionFKey}
            optionFilterKey={optionFilterKey}
            searchKey={searchKey}
            options={options}
            optionList={optionList}
            flatOptionList={flatOptionList}
            subOptions={subOptions}
            handleOnSearch={handleOnSearch}
            handleFilter={handleFilter}
            componentType={componentType}
            isCollapsed={isCollapsed}
            selectedIds={selectedIds}
            subSelectedIds={subSelectedIds}
            listBoxStyle={listBoxStyle}
          />
        )}
        {!isCollapsed && !isMultiGroup && (
          <FilterBoxFooter
            handleShowMore={handleShowMore}
            isMultiLevel={isMultiLevel}
            isShownMore={isShownMore}
            optionListLength={optionList.length}
            searchKey={searchKey}
          />
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

FilterBox.propTypes = {
  title: PropTypes.string,
  mixPanelEventName: PropTypes.string,
  filterKey: PropTypes.string,
  childKey: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  componentType: PropTypes.string,
  optionKey: PropTypes.string,
  subOptionKey: PropTypes.string,
  subOptionFKey: PropTypes.string,
  optionFilterKey: PropTypes.string,
  subOptionFilterKey: PropTypes.string,
  handleFilter: PropTypes.func,
  listBoxStyle: PropTypes.shape(),
  selectedIds: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ),
  options: PropTypes.arrayOf(PropTypes.shape()),
  subOptions: PropTypes.arrayOf(PropTypes.shape()),
  subSelectedIds: PropTypes.arrayOf(PropTypes.shape()),
}

export default FilterBox
