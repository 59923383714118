import { useEffect, useRef, useState } from 'react'
import i18n from 'translations/i18n'
import { CHATBOT_URL } from 'env'
import { useSelector } from 'utils/hooks'
import { Flex } from '@jisr-hr/ds-beta'
import {
  getChatStartTimestamp,
  getCurrentTimestamp,
  getJisriSavedChat,
  getSelectedLanguage,
  isTimestampDifferenceGreaterThan24Hours,
  isValidMessage,
  removeJisriSavedChat,
  saveChatStartTimestamp,
  saveJisriChat,
} from './utils'
import { JISRI_SELECTED_LANGUAGE } from './constants'
import ChatHeader from './components/ChatHeader'
import ChatInput from './components/ChatInput'
import MessageList from './components/MessageList'
import type { ChatbotPopupProps, MessageType, WebSocketMessageType } from './types'

const ChatbotPopup = ({ setIsChatbotVisible }: ChatbotPopupProps) => {
  const [messageList, setMessageList] = useState<MessageType[]>([])
  const [message, setMessage] = useState<string>('')
  const [wsConnection, setWsConnection] = useState<WebSocket | null>(null)
  const [isInputDisabled, setIsInputDisabled] = useState(false)

  const reconnectRef = useRef(0)

  const { employee } = useSelector(({ auth }) => auth)

  const selectedLanguage = localStorage.getItem(JISRI_SELECTED_LANGUAGE)
  const isLanguageSelected = selectedLanguage === 'ar' || selectedLanguage === 'en'

  useEffect(() => {
    if (!isLanguageSelected) {
      setMessageList([
        {
          type: 'bot',
          message: `${i18n.t('communication.hi_welcome_to_jisrai', {
            employee_name: employee?.full_name_i18n ?? '',
          })}  \n ${i18n.t('communication.which_language_do_you_prefer')}`,
          showLoader: false,
          isInitialMessage: true,
        },
        {
          type: 'client',
          message: '',
          showLoader: false,
          showSelectLanguageOption: true,
          isInitialMessage: true,
        },
      ])
    }
  }, [])

  const isTimestampSaved = () => {
    try {
      const savedTimestamp = getChatStartTimestamp()
      if (!savedTimestamp) {
        saveChatStartTimestamp()
      }
    } catch (error) {
      // Error in saving timestamp
    }
  }

  const checkMessageHistoryExpired = async () => {
    try {
      const savedMessages = getJisriSavedChat()
      const isExpired = isTimestampDifferenceGreaterThan24Hours(
        savedMessages?.initialChatTimeStamp,
        getCurrentTimestamp(),
      )
      if (isExpired) {
        removeJisriSavedChat()
        setMessageList((prev) => [
          ...prev,
          {
            type: 'bot',
            message: i18n.t('communication.how_can_i_help_you', {
              lng: getSelectedLanguage(),
            }),
            showHelpOptions: true,
          },
        ])
      } else {
        setTimeout(() => {
          if (savedMessages?.messages) {
            let savedChat = savedMessages?.messages
            if (isLanguageSelected) {
              savedChat = savedChat.filter((item: MessageType) => item?.isInitialMessage !== true)
            }
            setMessageList(savedChat)
          }
        }, 100)
      }
    } catch (error) {
      // Error in getting saved messages
    }
  }

  useEffect(() => {
    isTimestampSaved()
    checkMessageHistoryExpired()
  }, [])

  const handleWebSocketSetup = () => {
    try {
      const formattedUrl = CHATBOT_URL?.replace('https://', '')
      const websocket = new WebSocket(`wss://${formattedUrl}/api/v1/ask/ws`)

      const onOpen = () => {
        // Websocket connected
      }

      const onError = (): void => {
        setMessageList((prev) => [
          ...prev,
          {
            type: 'bot',
            message: i18n.t('empty_state.jisrai_is_having_a_trouble_loading'),
            isErrorMessage: true,
          },
        ])
      }

      const onMessage = (event: MessageEvent): void => {
        setIsInputDisabled(false)
        setMessageList((prev) => {
          if (prev?.length !== 0 && prev[prev.length - 1]?.showLoader) {
            prev.pop()
          }
          const parsedData: WebSocketMessageType = JSON.parse(event?.data)
          const botInfoMessage = parsedData?.info ? `⏹️ ${parsedData?.info ?? ''}` : null
          const botErrorMessage = parsedData?.error ? `📌 ${parsedData?.error ?? ''}` : null
          const botInfoOrErrorReply = botInfoMessage || botErrorMessage || ''
          const botReply = parsedData?.answer ? parsedData?.answer : botInfoOrErrorReply
          const showFeedbackOptions = Boolean(parsedData?.answer)
          const messageId = parsedData?.message_id

          return [
            ...prev,
            { type: 'bot', message: botReply, messageId, showFeedbackOptions, showLoader: false },
          ]
        })
        if (message) {
          setMessage('')
        }
      }

      const onClose = (): void => {
        // Jisri chatbot onClose
      }

      websocket.onopen = onOpen
      websocket.onmessage = onMessage
      websocket.onerror = onError
      websocket.onclose = onClose

      setWsConnection(websocket)
    } catch (error) {
      // Error in setting up websocket
    }
  }

  const sendMessage = (): void => {
    if (
      !isValidMessage(message) ||
      isInputDisabled ||
      !wsConnection ||
      wsConnection?.readyState !== WebSocket.OPEN
    ) {
      return
    }

    const query = {
      user_input: message,
      lang: getSelectedLanguage(),
      source: 'jisr_web',
    }

    try {
      setIsInputDisabled(true)
      wsConnection?.send(JSON.stringify(query))
      setMessageList((prev) => {
        return [...prev, { type: 'client', message }, { type: 'bot', showLoader: true }]
      })
      setMessage('')
    } catch (e) {
      // Error in sending message
    }
  }

  useEffect(() => {
    reconnectRef.current = 0
    handleWebSocketSetup()

    return () => {
      reconnectRef.current = 0
      if (wsConnection && wsConnection?.readyState === 1) {
        wsConnection.close()
      }
    }
  }, [])

  useEffect(() => {
    if (isLanguageSelected) {
      setMessageList((prev) => prev.filter((item) => item?.isInitialMessage !== true))
    }
  }, [isLanguageSelected])

  const onChatbotClose = () => {
    setIsChatbotVisible(false)
    saveJisriChat(
      messageList?.filter((item) => !item?.showLoader && !item?.isErrorMessage),
      getCurrentTimestamp(),
    )
  }

  return (
    <div
      className={`fixed bottom-[calc(4rem+1.5rem)] ${
        i18n.language === 'ar' ? 'left-8' : 'right-4'
      } mr-8 shadow-[0_-1px_2px_0_rgba(0,0,0,0.05),0_1px_12px_0_rgba(0,0,0,0.12)] rounded-xl z-[989] bg-[#FAFAFA] overflow-hidden`}
    >
      <Flex
        flexCol
        className="w-[440px] h-[534px]"
      >
        {/* Header */}
        <ChatHeader onClose={onChatbotClose} />
        {/* Messages List */}
        <MessageList
          isLanguageSelected={isLanguageSelected}
          messageList={messageList}
          setMessageList={setMessageList}
        />

        {/* Input Field */}
        <ChatInput
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          handleWebSocketSetup={handleWebSocketSetup}
          wsConnection={wsConnection}
        />
      </Flex>
    </div>
  )
}

export default ChatbotPopup
