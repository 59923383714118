import { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Typography, Spacer, Button, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import saudiFlag from 'assets/figma-icons/saudiflagIcon.svg'
import moldetail from 'assets/figma-icons/molDetail.png'
import gosi from 'assets/figma-icons/gosi.png'

import { Form, TextInputField, SelectField } from 'components/final-form'
import {
  required,
  composeValidators,
  fixedLength,
  naturalNumber,
} from 'components/global/form/FormValidations'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  commercialUpdate,
  newCommercialCreate,
} from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import {
  CommercialRegistration,
  MolRegistrationType,
} from 'containers/public/Onboarding/types/onboardingTypes'
import StepTemplate from '../../../../components/StepTemplate'
import MolRegistraation from './MolRegistraation'

type CommercialRegistrationProps = {
  checkAddOrEdit: boolean
  checkMultiCRs: boolean
  commercialRegistration: CommercialRegistration
  onClickChangeUi: () => void
}

export type CommercialRegistrationType = {
  id: number | null
  mol_registration_attributes: MolRegistrationType
  name: string
  registration_number: string
}

type ArgType = {
  reset: () => void
}

const CRInfromationAddEdit = ({
  onClickChangeUi,
  commercialRegistration,
  checkAddOrEdit,
  checkMultiCRs,
}: CommercialRegistrationProps): JSX.Element => {
  const dispatch = useDispatch()
  const { fetching } = useSelector(
    ({ commercialRegistrationsOnborading }) => commercialRegistrationsOnborading,
  )
  const [clickAddNew, setClickAddNew] = useState(false)
  let dataMolPrefix: {
    namber: string
  }[] = []
  const nambers = Array.from({ length: 38 }, (_, i) => (i + 1).toString())

  nambers.map((namber) => {
    dataMolPrefix = dataMolPrefix.concat({ namber })
    return dataMolPrefix
  })

  const onSubmit = (values: CommercialRegistrationType, arg: ArgType): void => {
    if (checkAddOrEdit) {
      if (clickAddNew) {
        dispatch(newCommercialCreate(values)).then(() => {
          setClickAddNew(false)
          dispatch(organizationInfo())
          arg.reset()
        })
      } else {
        dispatch(newCommercialCreate(values)).then(() => {
          dispatch(organizationInfo())
          onClickChangeUi()
        })
      }
    } else {
      const data = values
      dispatch(commercialUpdate(data)).then(onClickChangeUi)
    }
  }

  return (
    <StepTemplate hideFooter>
      <Form
        onSubmit={onSubmit}
        initialValues={
          checkAddOrEdit
            ? null
            : {
                mol_registration_attributes: {
                  gosi_number: commercialRegistration.mol_registration.gosi_number,
                  mol_number: commercialRegistration.mol_registration.mol_number,
                  mol_prefix: commercialRegistration.mol_registration.mol_prefix,
                },
                id: commercialRegistration?.id || '',
                main_id: commercialRegistration?.main_commercial_registration?.id || '',
                registration_number: commercialRegistration.registration_number,
                name_ar: commercialRegistration.name_ar,
                name_en: commercialRegistration.name_en,
                unifiedMOL:
                  commercialRegistration?.main_commercial_registration?.mol_registration.mol_number,
              }
        }
        render={({ handleSubmit }): JSX.Element => (
          <>
            <Flex flexCol>
              <Flex
                itemsCenter
                style={{ gap: '8px' }}
              >
                <Avatar
                  src={saudiFlag}
                  style={{
                    width: '54px',
                    height: '53px',
                  }}
                />
                <Typography
                  variant="title-1"
                  text={I18n.t('enter_cr_details')}
                />
              </Flex>
              <Spacer height="20px" />
              <Flex
                flexCol
                style={{ width: '512px', gap: 5 }}
              >
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="registration_number"
                  label={I18n.t('commercial_registration_number')}
                  placeholder={I18n.t('cr_number_example')}
                  autoFocus={!commercialRegistration.id}
                  validate={composeValidators(required, naturalNumber, fixedLength(10))}
                  testId="registration_number"
                />
                <Spacer height={8} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name_ar"
                  label={I18n.t('commercial_registration_name_ar')}
                  placeholder="مثال: شركة Jisr لتكنولوجيا المعلومات"
                  validate={required}
                  testId="cr_name_ar"
                  inputStyle={{ direction: 'rtl' }}
                />
                <Spacer height={8} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="name_en"
                  label={I18n.t('commercial_registration_name_en')}
                  placeholder="Ex: Jisr systems for information technology"
                  testId="cr_name_en"
                  inputStyle={{ direction: 'ltr' }}
                />
              </Flex>
            </Flex>
            <Spacer height="48px" />

            <Flex flexCol>
              <Flex
                itemsCenter
                style={{ gap: '8px' }}
              >
                <Avatar
                  src={moldetail}
                  style={{
                    width: '54px',
                    height: '53px',
                  }}
                />
                <Typography
                  variant="title-1"
                  style={{ paddingLeft: '8px' }}
                  text={I18n.t('enter_mol_details')}
                />
              </Flex>
              <Spacer height="20px" />
              <Flex
                flexCol
                style={{ width: '512px', gap: 5 }}
              >
                <Flex style={{ gap: 10 }}>
                  <SelectField
                    // @ts-expect-error Need to migrate COMPONENT_NAME.js to TypeScript
                    style={{ width: 130 }}
                    label={I18n.t('mol_prefix')}
                    options={dataMolPrefix}
                    labelKey="namber"
                    valueKey="namber"
                    validate={required}
                    name="mol_registration_attributes[mol_prefix]"
                  />
                  {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                  <TextInputField
                    label={I18n.t('mol_number')}
                    name="mol_registration_attributes[mol_number]"
                    validate={required}
                    inputStyle={{ flexGrow: 1 }}
                  />
                </Flex>
              </Flex>
              <MolRegistraation selectedCR={commercialRegistration} />
            </Flex>
            <Spacer height="48px" />

            <Flex flexCol>
              <Flex
                itemsCenter
                style={{ gap: '8px' }}
              >
                <Avatar
                  src={gosi}
                  style={{
                    width: '54px',
                    height: '53px',
                  }}
                />
                <Typography
                  variant="title-1"
                  text={I18n.t('enter_gosi_details')}
                />
              </Flex>
              <Spacer height="24px" />
              <Flex
                flexCol
                style={{ gap: 5, width: '512px' }}
              >
                <Typography
                  variant="interface/default/m"
                  text={I18n.t('general_organization_for_social_insurance')}
                  style={{ color: 'var(--color-base-colors-black-600)' }}
                />

                <Spacer height={16} />
                {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
                <TextInputField
                  name="mol_registration_attributes[gosi_number]"
                  label={I18n.t('gosi_number')}
                  placeholder="Ex: 12345"
                  validate={composeValidators(required, naturalNumber)}
                />
              </Flex>
            </Flex>

            <Spacer height="12px" />

            {checkAddOrEdit && checkMultiCRs && (
              <Button
                label={I18n.t('add_new')}
                size="medium"
                variant="ghost"
                onClick={(): void => {
                  setClickAddNew(true)
                  setTimeout(() => handleSubmit(), 10)
                }}
                disabled={fetching}
              />
            )}
            <Spacer height="60px" />
            <Flex itemsCenter>
              <Button
                variant="outlined"
                color="neutral"
                onClick={onClickChangeUi}
                disabled={fetching}
                size="medium"
                label={I18n.t('back')}
                type="button"
              />
              <Spacer width="8px" />
              <Button
                label={I18n.t('continue')}
                onClick={(): void => {
                  handleSubmit()
                }}
                disabled={fetching}
                size="medium"
              />
            </Flex>
          </>
        )}
      />
    </StepTemplate>
  )
}

export default CRInfromationAddEdit
