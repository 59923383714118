import { FC } from 'react'
import { Field } from 'react-final-form'
import { TimePicker } from 'components/global/ds'
import { TimePickerProps } from 'components/global/ds/TimePicker/TimePicker'

type TimePickerFieldProps = {
  name: string
  value?: string
  testId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (value: any, values: any) => string | undefined
} & Omit<TimePickerProps, 'data-testid'>

const TimePickerField: FC<TimePickerFieldProps> = (props) => {
  const { name, validate, testId, value, ...rest } = props

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => (
        <TimePicker
          {...rest}
          {...input}
          onChange={(v): void => {
            input.onChange(v)
            rest.onChange?.(v)
          }}
          value={value || input.value}
          data-testid={testId}
          status={meta.error && meta.touched && 'error'}
          statusMessage={meta.error}
          onBlur={(v): void => {
            input.onBlur()
            rest.onBlur?.(v)
          }}
        />
      )}
    </Field>
  )
}

export default TimePickerField
