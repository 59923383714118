export const CREATE_WEBHOOK = 'CREATE_WEBHOOK'
export const CREATE_WEBHOOK_PENDING = 'CREATE_WEBHOOK_PENDING'
export const CREATE_WEBHOOK_FULFILLED = 'CREATE_WEBHOOK_FULFILLED'
export const CREATE_WEBHOOK_REJECTED = 'CREATE_WEBHOOK_REJECTED'

export const GET_WEBHOOKS_LIST = 'GET_WEBHOOKS_LIST'
export const GET_WEBHOOKS_LIST_PENDING = 'GET_WEBHOOKS_LIST_PENDING'
export const GET_WEBHOOKS_LIST_FULFILLED = 'GET_WEBHOOKS_LIST_FULFILLED'
export const GET_WEBHOOKS_LIST_REJECTED = 'GET_WEBHOOKS_LIST_REJECTED'

export const VIEW_WEBHOOK = 'VIEW_WEBHOOK'
export const VIEW_WEBHOOK_PENDING = 'VIEW_WEBHOOK_PENDING'
export const VIEW_WEBHOOK_FULFILLED = 'VIEW_WEBHOOK_FULFILLED'
export const VIEW_WEBHOOK_REJECTED = 'VIEW_WEBHOOK_REJECTED'

export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK'
export const UPDATE_WEBHOOK_PENDING = 'UPDATE_WEBHOOK_PENDING'
export const UPDATE_WEBHOOK_FULFILLED = 'UPDATE_WEBHOOK_FULFILLED'
export const UPDATE_WEBHOOK_REJECTED = 'UPDATE_WEBHOOK_REJECTED'

export const DELETE_WEBHOOK = 'DELETE_WEBHOOK'
export const DELETE_WEBHOOK_PENDING = 'DELETE_WEBHOOK_PENDING'
export const DELETE_WEBHOOK_FULFILLED = 'DELETE_WEBHOOK_FULFILLED'
export const DELETE_WEBHOOK_REJECTED = 'DELETE_WEBHOOK_REJECTED'

export const GET_WEBHOOK_LOOK_UPS_LIST = 'GET_WEBHOOK_LOOK_UPS_LIST'
export const GET_WEBHOOK_LOOK_UPS_LIST_PENDING = 'GET_WEBHOOK_LOOK_UPS_LIST_PENDING'
export const GET_WEBHOOK_LOOK_UPS_LIST_FULFILLED = 'GET_WEBHOOK_LOOK_UPS_LIST_FULFILLED'
export const GET_WEBHOOK_LOOK_UPS_LIST_REJECTED = 'GET_WEBHOOK_LOOK_UPS_LIST_REJECTED'

export const TEST_WEBHOOK = 'TEST_WEBHOOK'
export const TEST_WEBHOOK_PENDING = 'TEST_WEBHOOK_PENDING'
export const TEST_WEBHOOK_FULFILLED = 'TEST_WEBHOOK_FULFILLED'
export const TEST_WEBHOOK_REJECTED = 'TEST_WEBHOOK_REJECTED'

export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
