import { Modal } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'

type ConfirmResetLaborLawProps = {
  isOpen: boolean
  leaveType: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmResetLaborLaw = (props: ConfirmResetLaborLawProps) => {
  const { isOpen, leaveType } = props

  return (
    <Modal
      open={isOpen}
      variant="warning"
      onClose={props.onClose}
      size="large"
      header={{
        title: i18n.t('label.modal.resetting_to_default_title'),
        description: i18n.t('label.modal.resetting_to_default_description', {
          Leavename: leaveType,
        }),
        leadingSpace: {
          icon: 'arrows-triangle',
        },
        withClose: true,
      }}
      footer={{
        submit: {
          label: i18n.t('cta.proceed'),
          onClick: props.onConfirm,
        },
        cancel: {
          label: i18n.t('label.cancel'),
          onClick: props.onClose,
        },
      }}
    />
  )
}

export default ConfirmResetLaborLaw
