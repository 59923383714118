import { Typography, Flex } from '@jisr-hr/ds'
import { useEffect } from 'react'
import { leaveRequestsReset } from 'redux/actions/leaveRequestsAction'

import i18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'

type LeaveBalanceTemplateProps = {
  isHalfLeave?: boolean
  applied_days: number
  pending_balance: number
  requested_days: number
}

const LeaveBalanceTemplate = (props: LeaveBalanceTemplateProps): JSX.Element | null => {
  const { isHalfLeave, applied_days, pending_balance, requested_days } = props
  const dispatch = useDispatch()

  const leaveBalance = [
    {
      title: i18n.t('you_will_be_off_for'),
      day: isHalfLeave ? i18n.t('half_day') : i18n.t('days_number', { days: applied_days }),
      color: 'var(--ds-core-color-gray-50)',
    },
    {
      title: i18n.t('days_deducted'),
      day: isHalfLeave ? i18n.t('half_day') : i18n.t('days_number', { days: requested_days }),
      color: 'var(--ds-core-color-error-25)',
    },
    {
      title: i18n.t('remaining_balance'),
      day: i18n.t('days_number', { days: pending_balance }),
      color: 'var(--ds-core-color-warning-25)',
    },
  ]

  useEffect(() => {
    return () => {
      dispatch(leaveRequestsReset())
    }
  }, [])

  if (!applied_days) return null

  return (
    <Flex style={{ gap: 16 }}>
      {leaveBalance.map((leave, i) => (
        <Flex
          key={i}
          flexCol
          style={{
            padding: '16px 20px',
            gap: 8,
            borderRadius: 8,
            background: leave.color,
            width: '100%',
            ...(i === 2 && { minWidth: 160 }),
          }}
        >
          <Typography
            variant="subtitle-1"
            text={leave.title}
          />
          <Typography
            variant="title-1"
            text={leave.day}
          />
        </Flex>
      ))}
    </Flex>
  )
}

export default LeaveBalanceTemplate
