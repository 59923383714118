import { useEffect, useState } from 'react'
import { Drawer } from '@jisr-hr/design-system'
import SearchBar from 'components/global/SearchBar'
import { Select } from '@jisr-hr/ds-beta'
import { Typography, Checkbox, Flex, Spacer, Button } from '@jisr-hr/ds'
import { Department } from 'redux/newDepartments/types'
import EmployeeProfile from 'components/global/templates/RequestDrawer/ds/EmployeeProfile'
import {
  assignEmployeesPut,
  employeesDepartmentLoad,
  hierarchicalDepartmentsLoad,
} from 'redux/newDepartments/actionCreators'
import { useDispatch, useSelector } from 'utils/hooks'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import ConfirmModal from 'containers/authorised/setting/v2/components/ConfirmModal'
import Frame from 'containers/authorised/setting/v2/components/Frame'
import { Loader } from 'components/global/JisrLoader/JisrLoader'
import styles from '../style.module.css'

type DepartmentEmployeesProps = {
  data: Department
  isOpen: boolean
  setIsOpen: (x: boolean) => void
}

type Employee = {
  avatar_thumb: string
  code: string
  id: number
  jobTitle: string
  name: string
}

type AssignedData = {
  department_id?: number
  employee_id: number
}

type AssignBulkType = {
  label?: string
  value?: number
}

const DepartmentEmployees = ({
  isOpen,
  setIsOpen,
  data: department,
}: DepartmentEmployeesProps): JSX.Element => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [selected, setSelected] = useState<number[]>([])
  const [assignBulk, setAssignBulk] = useState<AssignBulkType | null>(null)
  const [assign, setAssign] = useState<AssignedData[]>([])
  const [search, setSearch] = useState('')
  const { fetching, employees_department } = useSelector((s) => s.newDepartments)
  const { list_departments } = useSelector((s) => s.newDepartments)

  const dispatch = useDispatch()
  const filter = {
    filter: {
      department_id: [department.id],
      search,
    },
  }

  const onClose = (): void => {
    setIsOpen(false)
    setAssignBulk(null)
    setAssign([])
    setSelected([])
    setSearch('')
  }

  const handleSubmit = (): void => {
    const selectingMaping =
      assignBulk !== null
        ? selected.map((id: number) => ({
            department_id: assignBulk?.value,
            employee_id: id,
          }))
        : []

    dispatch(
      assignEmployeesPut({
        department: [...selectingMaping, ...assign],
      }),
    ).then(() => {
      setIsOpenConfirm(false)
      onClose()
      dispatch(hierarchicalDepartmentsLoad())
      dispatch(employeesDepartmentLoad(filter))
    })
  }

  const handleSelectAll = (value: boolean): void => {
    if (value) {
      setAssign([])
      setSelected(employees_department.map((em: Employee) => em.id))
    } else {
      setSelected([])
    }
  }

  const removeAssignedData = (id: number | undefined): AssignedData[] =>
    assign.filter((f: { employee_id: number }) => f.employee_id !== id)

  const handleClick = (value: boolean, id: number): void => {
    if (value) {
      setAssign(removeAssignedData(id))
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((item) => item !== id))
    }
  }

  const onAssign = (assignData: AssignedData | null, id?: number): void => {
    const data = removeAssignedData(assignData?.employee_id || id)

    if (assignData && !id) {
      setAssign([assignData, ...data])
    }
    if (id) {
      setAssign(data)
    }
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(employeesDepartmentLoad(filter))
    }
  }, [search, isOpen])

  const isBulkUnValid = !(!isEmpty(selected) && assignBulk !== null)

  return (
    <>
      <Drawer
        active={isOpen}
        onClose={onClose}
        title={i18n.t('employees')}
        content={
          <div className={styles.drawerContent}>
            <Frame>
              {fetching && <Loader />}
              <SearchBar
                placeholder={i18n.t('search_employees')}
                value={filter.filter?.search}
                onChange={(v: string): void => setSearch(v)}
              />
              <Spacer height={32} />
              <Flex
                justifyBetween
                itemsCenter
              >
                <Checkbox
                  active={!isEmpty(selected)}
                  label={i18n.t('employees')}
                  onChange={(e): void => handleSelectAll(e.target.checked)}
                  disabled={isEmpty(employees_department)}
                />
                <Select
                  maxMenuHeight={200}
                  maxWidth={190}
                  placeholder={i18n.t('select_department')}
                  options={list_departments
                    .filter((f) => f.id !== department.id)
                    .map((d: Department) => ({
                      label: d.name_i18n,
                      value: d.id,
                    }))}
                  value={assignBulk}
                  disabled={isEmpty(employees_department)}
                  onChange={(data): void => setAssignBulk(data)}
                  isClearable
                />
              </Flex>
              <Spacer height={20} />
              {employees_department.map((emp: Employee, i: number): JSX.Element => {
                const getData = assign.find((f) => f.employee_id === emp.id)
                const value = list_departments.find((f) => f.id === getData?.department_id)
                return (
                  <div key={i}>
                    <Flex justifyBetween>
                      <Flex
                        itemsCenter
                        style={{ gap: 10 }}
                      >
                        <Checkbox
                          active={selected.includes(emp.id)}
                          onChange={(e): void => handleClick(e.target.checked, emp.id)}
                        />
                        <EmployeeProfile
                          avatar={emp.avatar_thumb}
                          code={emp.code}
                          jobTitle={emp.jobTitle}
                          avatarStyle={{ height: 40, width: 40 }}
                          name={emp.name}
                          typographyTitle="body-new/regular"
                          typographySubTitle="subtitle-2"
                        />
                      </Flex>
                      <Select
                        maxWidth={190}
                        maxMenuHeight={200}
                        placeholder={department.name_i18n}
                        options={list_departments
                          .filter((f) => f.id !== department.id)
                          .map((d: Department) => ({
                            label: d.name_i18n,
                            value: d.id,
                          }))}
                        onChange={(data): void => {
                          if (data?.value) {
                            onAssign({
                              department_id: data.value,
                              employee_id: emp.id,
                            })
                          } else onAssign(null, emp.id)
                        }}
                        value={value?.id ? { label: value?.name_i18n, value: value?.id } : null}
                        disabled={selected.includes(emp.id)}
                        isClearable
                      />
                    </Flex>
                    <Spacer height={32} />
                  </div>
                )
              })}
              {isEmpty(employees_department) && (
                <Typography
                  variant="interface/default/sm"
                  style={{ textAlign: 'center' }}
                  text={i18n.t('no_employees_found')}
                />
              )}
            </Frame>
          </div>
        }
        footer={
          <Flex style={{ gap: 8 }}>
            <Button
              label={i18n.t('save')}
              size="medium"
              onClick={(): void => setIsOpenConfirm(true)}
              disabled={!(!isEmpty(assign) || !isBulkUnValid)}
            />

            <Button
              label={i18n.t('cancel')}
              size="medium"
              variant="outlined"
              color="neutral"
              onClick={onClose}
            />
          </Flex>
        }
      />
      <ConfirmModal
        variant="info"
        isOpen={isOpenConfirm}
        title={i18n.t('confirm_changes')}
        message={i18n.t('are_you_sure_you_move_departments')}
        onCancel={(): void => setIsOpenConfirm(false)}
        onConfirm={handleSubmit}
        fetching={fetching}
      />
    </>
  )
}

export default DepartmentEmployees
