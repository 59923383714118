import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { InitialStateType } from './type'
import * as actions from './actions'

export const initialState: InitialStateType = {
  fetching: false,
  // leave_salary_days_configuration: {},
  enabled_configuration: {},
  min_vacation_salary_days: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action

  switch (action.type) {
    case actions.ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING:
    case actions.ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED:
      return {
        ...state,
        min_vacation_salary_days: {
          ...payload.data.data.organization_configuration,
        },
        fetching: false,
      }

    case actions.ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        min_vacation_salary_days: {
          ...payload.data.data.organization_configuration,
        },
        fetching: false,
      }

    case actions.ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED:
      return {
        ...state,
        enabled_configuration: { ...payload.data.data.organization_configuration },
      }

    case actions.ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        enabled_configuration: { ...payload.data.data.organization_configuration },
        fetching: false,
      }

    case actions.ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_REJECTED:
    case actions.ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED:
    case actions.ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED:
    case actions.ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    default:
      return state
  }
}
