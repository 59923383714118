import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'utils/uiHelpers'

const Location = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
  >
    <path
      fill={color ? getColor(color) : '#FF73B2'}
      fillRule="evenodd"
      d="M5,12 C1.66666667,9.1742825 0,6.84094917 0,5 C0,2.23857625 2.23857625,0 5,
      0 C7.76142375,0 10,2.23857625 10,5 C10,6.84094917 8.33333333,9.1742825 5,12 Z M5,
      7 C6.1045695,7 7,6.1045695 7,5 C7,3.8954305 6.1045695,3 5,3 C3.8954305,3 3,3.8954305 3,
      5 C3,6.1045695 3.8954305,7 5,7 Z"
    />
  </svg>
)

Location.propTypes = {
  color: PropTypes.string,
}

export default Location
