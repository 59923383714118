import { useDispatch } from 'utils/hooks'
import { queryClient } from 'hooks/useReactQuery'
import { getTemplates } from 'apis/OrgManagement/templates/useTemplates'
import { getTemplateDefaultName } from 'apis/Automations/DefaultName/useDefaultName'
import {
  automationCreate,
  automationBulkUpsert,
  automationListLoad,
} from 'redux/automationWorkflow/automations/actionCreator'
import { onboardingChangesOrganizationInfo } from 'redux/authUser/actionCreators'
import {
  listOnboardingStepsFetchGroups,
  onboardingCompleteStep,
} from 'redux/organizationOnborading/steps/actionCreators'
import {
  createAttendanceDashboardSteps,
  loadAttendanceDashboardSteps,
} from 'redux/attendance/dashboard/actionsCreators'
import { TPage } from 'redux/organizationOnborading/steps/type'

type TSurveyStatusKey =
  | 'is_onboarding_survey_appeared'
  | 'is_payroll_module_survey_appeared'
  | 'is_attendance_module_survey_appeared'

type UseCanduCallbacksType = {
  attendanceSurveySubmitted: () => Promise<void>
  activateAutomation: (search: string) => Promise<void>
  completeCanduStep: (stepName: string, surveyStatusKey: TSurveyStatusKey) => Promise<void>
}

const useCanduCallbacks = (): UseCanduCallbacksType => {
  const dispatch = useDispatch()

  const activateAutomation = async (search: string): Promise<void> => {
    const templatesData = await queryClient.fetchQuery({
      queryKey: ['templatesData', { search }],
      queryFn: () => getTemplates({ filter: { search } }),
    })
    const templates = templatesData.data.data.automation_templates
    const templateDefaultNameData = await queryClient.fetchQuery({
      staleTime: 0,
      queryKey: ['templateDefaultNameData', { template_id: templates[0]?.id }],
      queryFn: () => getTemplateDefaultName({ template_id: templates[0]?.id }),
    })
    const templateDefaultName = templateDefaultNameData.data.data
    if (templates?.length && templateDefaultName) {
      const { value } = await dispatch(
        automationBulkUpsert(templates[0].workflow.yes?.automation_actions),
      )
      const ids = value.data.data.automation_action_version_ids
      const values = {
        status: 'enabled',
        name_ar: templateDefaultName?.name_ar,
        name_en: templateDefaultName?.name_en,
        automation_template_id: templates[0].id,
        automation_trigger_id: templates[0].automation_trigger.id,
        workflow: {
          conditions: templates[0].workflow.conditions,
          yes: { automation_action_version_ids: ids },
        },
      }
      dispatch(automationCreate(values)).then(() => {
        dispatch(automationListLoad({ page: 1, rpp: 20, filter: { search: '' } }))
      })
    }
  }

  const completeCanduStep = async (
    stepName: string,
    surveyStatusKey: TSurveyStatusKey,
  ): Promise<void> => {
    const data = await dispatch(listOnboardingStepsFetchGroups(['onboarding_candu_pages']))
    const pageGroups = data?.value?.data?.data?.page_groups as TPage[]

    const step = pageGroups
      ?.find((g) => g.name === 'onboarding_candu_pages')
      ?.organization_steps.find((e) => e.name === stepName)

    if (step?.id) {
      await dispatch(
        onboardingCompleteStep({
          id: step.id,
          page_group: 'onboarding_candu_pages',
        }),
      )
      dispatch(
        onboardingChangesOrganizationInfo({ onboarding_candu_pages: { [surveyStatusKey]: true } }),
      )
    }
  }

  const attendanceSurveySubmitted = async (): Promise<void> => {
    await completeCanduStep('attendance_module_survey', 'is_attendance_module_survey_appeared')
    await dispatch(createAttendanceDashboardSteps('ReportingMethods'))
    dispatch(loadAttendanceDashboardSteps())
  }

  return { activateAutomation, completeCanduStep, attendanceSurveySubmitted }
}

export default useCanduCallbacks
