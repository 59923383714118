import * as actions from './actions'

const DEFAULT_STATE = {
  fetching: false,
  notifications: {},
}

export default function requestsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case actions.NOTIFICATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.NOTIFICATIONS_LOAD_FULFILLED: {
      return {
        ...state,
        notifications: payload?.data?.data?.notifications ?? {},
        fetching: false,
      }
    }
    case actions.NOTIFICATIONS_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
