import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { InitialStateType } from './type'
import * as actions from './actions'

const initialState: InitialStateType = {
  fetching: false,
  payroll_transaction_types: [],
  vacation_salary_calculation: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.VACATION_SALARY_CALCULATION_LOAD_PENDING:
    case actions.VACATION_SALARY_CALCULATION_UPDATE_PENDING:
    case actions.ONBOARDING_BENEFIT_TYPES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_BENEFIT_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }

    case actions.VACATION_SALARY_CALCULATION_UPDATE_FULFILLED:
    case actions.VACATION_SALARY_CALCULATION_LOAD_FULFILLED:
      if (action.payload.data.message) {
        Toastr.success(action.payload.data.message)
      }
      return {
        ...state,
        fetching: false,
        vacation_salary_calculation: action?.payload?.data?.data?.payrun_configuration,
      }

    case actions.VACATION_SALARY_CALCULATION_LOAD_REJECTED:
    case actions.VACATION_SALARY_CALCULATION_UPDATE_REJECTED:
    case actions.ONBOARDING_BENEFIT_TYPES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return {
        ...state,
        fetching: false,
      }
  }
}
