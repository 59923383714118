import { Lens } from '@dhmk/zustand-lens'
import { GroupsPayloadType } from 'apis/Performance/evaluationsEntryForms/useEvaluationsEntryFormsType'
import { DrawerProps } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/ReviewForms/components/steps/GoalsStep/CreateNewGroups'
import { GoalsTypeWithIndex } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/ReviewForms/components/steps/GoalsStep/GoalsForm'
import {
  ReviewDetailsType,
  GoalsType,
} from 'containers/authorised/PerformanceV2/PerformanceEvaluation/EvaluationForms/ReviewForms/types'
import { AlertModalProps } from 'containers/authorised/PerformanceV2/PerformanceEvaluation/modals/AlertModal'

import { Location } from 'history'

export type InitialValuesType = {
  ReviewDetails?: ReviewDetailsType
  intiGoals?: GroupsPayloadType
}

export type GoalsValuesType = GoalsType[]

export type ModalType = AlertModalProps & {
  withFollowing?: boolean
  nextLocation?: Location
  withNavigation?: boolean
  isOverlapped?: boolean
  isDeleted?: boolean
  modalClosed?: boolean
}

export type ModalGroup = {
  index?: number
  isEdit?: boolean
  value?: GoalsTypeWithIndex
  onSubmit: (values: GoalsType['groups']) => void
  close?: () => void
  type?: 'add' | 'edit'
}

export type EvaluationSliceType = {
  activeStep: number
  changeStep: (step: number) => void
  initialValues: InitialValuesType | null
  setInitialValues: (value: InitialValuesType | null) => void
  validClick: boolean
  setValidClick: (val: boolean) => void
  setEmployeesSelection: (employee: Record<string, unknown>) => void
  employeesSelection: Record<string, unknown>
  modalOpen: ModalType
  setModalOpen: (modal: ModalType) => void
  openModalGroup: ModalGroup | null
  setOpenModalGroup: (modal: ModalGroup | null) => void
  openDrawer: DrawerProps | null
  setOpenDrawer: (drawer: DrawerProps | null) => void
  status: string | null
  setStatus: (status: string | null) => void
}

const createEvaluation: EvaluationSliceType = {
  activeStep: 1,
  changeStep: () => null,
  initialValues: {
    ReviewDetails: {
      name_en: '',
      type: '',
      number_of_repetitions: 2,
      frequency: 'one_time_evaluation',
    },
  },
  setInitialValues: () => null,
  validClick: false,
  setValidClick: () => false,
  setEmployeesSelection: () => [],
  employeesSelection: {},
  modalOpen: { show: false },
  setModalOpen: () => null,
  openModalGroup: null,
  setOpenModalGroup: () => null,
  openDrawer: null,
  setOpenDrawer: () => null,
  status: '',
  setStatus: () => null,
}

export const createEvaluationSlice: Lens<EvaluationSliceType> = (set) => {
  return {
    ...createEvaluation,
    changeStep: (step) => set({ activeStep: step }),
    setInitialValues: (initialValues) => set({ initialValues }),
    setValidClick: (validClick) => set({ validClick }),
    setEmployeesSelection: (selection) => set({ employeesSelection: selection }),
    setModalOpen: (modal) => set({ modalOpen: modal }),
    setOpenModalGroup: (openModalGroup) => set({ openModalGroup }),
    setOpenDrawer: (openDrawer) => set({ openDrawer }),
    setStatus: (status) => set({ status }),
  }
}
