import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { removeOneListOfRecords, updateOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  parentDepartments: [],
  // subDepartment: [],
  parentDepartment: { manager: {} },
  departments: [],
  editing: false,
  errMsg: null,
  succMsg: null,
  fetching: false,
  fetchingDepartment: false,
  fetchingParentDepartment: false,
}

let changes = null
export default function parentDepartmentReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let parentDepartments = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'PARENT_DEPARTMENT_CREATE_PENDING':
    case 'PARENT_DEPARTMENT_UPDATE_PENDING':
    case 'PARENT_DEPARTMENT_DELETE_PENDING':
    case 'DEPARTMENTS_v2_LOAD_PENDING':
    case 'DEPARTMENTS_BULK_CREATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'PARENT_DEPARTMENTS_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        fetchingParentDepartment: true,
      }

    case 'ASSIGN_EMPLOYEES_UPDATE_PENDING':
    case 'ASSIGN_PARENT_DEPARTMENTS_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        fetchingDepartment: true,
      }

    case 'PARENT_DEPARTMENT_CREATE_REJECTED':
    case 'PARENT_DEPARTMENT_UPDATE_REJECTED':
    case 'PARENT_DEPARTMENT_DELETE_REJECTED':
    case 'DEPARTMENTS_v2_LOAD_REJECTED':
    case 'DEPARTMENTS_BULK_CREATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENTS_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        fetchingParentDepartment: false,
      }
      return { ...state, ...changes }

    case 'ASSIGN_EMPLOYEES_UPDATE_REJECTED':
    case 'ASSIGN_PARENT_DEPARTMENTS_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        fetchingDepartment: false,
      }
      return { ...state, ...changes }

    case 'DEPARTMENTS_BULK_CREATE_FULFILLED':
      Toastr.success(payload.data.message)
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'DEPARTMENTS_v2_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_LOAD_FULFILLED':
      changes = {
        parentDepartment: payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENTS_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        parentDepartments: payload.data.data.parent_departments.map((i) => {
          const isParentOpen = state.parentDepartments
            .map((j) => ({ id: j.id, isOpen: j.isOpen }))
            .filter((j) => j.isOpen)
          return {
            ...i,
            isOpen: !!isParentOpen.map((s) => s.id).includes(i.id),
            isParent: true,
          }
        }),
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        fetchingParentDepartment: false,
      }
      return { ...state, ...changes }

    case 'ASSIGN_EMPLOYEES_UPDATE_FULFILLED':
      Toastr.success(payload.data.message)
      changes = {
        ...payload.data.data,
        fetching: false,
        fetchingDepartment: false,
      }
      return { ...state, ...changes }

    case 'ASSIGN_PARENT_DEPARTMENTS_UPDATE_FULFILLED':
      Toastr.success(payload.data.message)
      changes = {
        ...payload.data.data,
        fetching: false,
        fetchingDepartment: false,
      }
      return { ...state, ...changes }

    case 'OPEN_SUB_DEPARTMENT':
      return {
        ...state,
        parentDepartments: state.parentDepartments?.map((i) => ({
          ...i,
          isOpen: i.id === payload?.data.id ? !i.isOpen : i.isOpen,
        })),
      }

    case 'PARENT_DEPARTMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      parentDepartments = [...state.parentDepartments]
      newRecord = payload.data.data.parent_department
      changes = {
        parentDepartments: [...parentDepartments, newRecord],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      updatedRecord = payload.data.data.parent_department
      changes = {
        parentDepartments: updateOneListOfRecords(state.parentDepartments, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'PARENT_DEPARTMENT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        parentDepartments: removeOneListOfRecords(state.parentDepartments, payload.id),
        error: null,
      }

    case 'PARENT_DEPARTMENT_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'PARENT_DEPARTMENT_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
