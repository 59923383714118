import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'

import * as actions from './actions'
import { TAccount } from '../types'

type StateType = {
  fetching: boolean
  accounts: Array<TAccount>
}

const initialState: StateType = {
  fetching: false,
  accounts: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action

  switch (action.type) {
    case actions.SWITCHABLE_ORGANIZATIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SWITCHABLE_ORGANIZATIONS_LOAD_FULFILLED:
      return {
        ...state,
        accounts: payload?.data?.data?.organizations,
        fetching: false,
      }

    case actions.SWITCHABLE_ORGANIZATIONS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
