import { handleResponseErr } from 'utils/apiHelperUtils'
import { removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  fetching: false,
  employees: [],
  pagination: {},
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEES_WITHOUT_RC_LOAD_PENDING:
    case actions.EMPLOYEES_WITHOUT_RC_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EMPLOYEES_WITHOUT_RC_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEES_WITHOUT_RC_UPDATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        employees: removeOneListOfRecords(state.employees, payload.data.data.employee.id),
        fetching: false,
      }

    case actions.EMPLOYEES_WITHOUT_RC_UPDATE_REJECTED:
    case actions.EMPLOYEES_WITHOUT_RC_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
