import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { OverviewState } from './overviewTypes'

const initialState: OverviewState = {
  fetching: false,
  loading: false,
  employees: [],
  timesheet: {},
  pagination: {},
  recalculating: false,
}

export default function reducer(state = initialState, action: Action): OverviewState {
  switch (action.type) {
    case actions.RESET_TIMESHHET_OVERVIEW_EMPLOYEES:
      return {
        ...state,
        employees: [],
        pagination: {},
      }

    case actions.RESET_TIMESHHET_OVERVIEW_STATE:
      return {
        ...state,
        timesheet: {},
      }

    case actions.TIMESHHET_OVERVIEW_STATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.TIMESHHET_OVERVIEW_EMPLOYEES_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.ATTENDANCE_RECALCULATE_PENDING:
      return {
        ...state,
        recalculating: true,
      }

    case actions.TIMESHHET_OVERVIEW_STATE_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TIMESHHET_OVERVIEW_EMPLOYEES_FULFILLED:
      return {
        ...state,
        employees: [...state.employees, ...action.payload.data.data.employees],
        pagination: action.payload.data.data.pagination,
        loading: false,
      }
    case actions.ATTENDANCE_RECALCULATE_FULFILLED:
      return {
        ...state,
        recalculating: false,
      }

    case actions.TIMESHHET_OVERVIEW_STATE_REJECTED:
    case actions.TIMESHHET_OVERVIEW_EMPLOYEES_REJECTED:
    case actions.ATTENDANCE_RECALCULATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        recalculating: false,
      }

    default:
      return state
  }
}
