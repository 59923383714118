import i18n from 'translations/i18n'
import {
  ViolationActionsT,
  ViolationListResponse,
  ViolationRuleType,
  ViolationStatusType,
} from 'apis/Attendance/violationRules/useViolationRulesTypes'
import { BadgeProps } from '@jisr-hr/ds'
import { RecordViolationsFormValuesT } from './components'

export type ViolationType = RecordViolationsFormValuesT & { ruleData?: ViolationRuleType }

export const reportedCategoryMapping = {
  0: null,
  1: 'pending',
  2: 'action_taken',
  3: 'dismissed',
}

export const initialSelectedFilters = {
  violation_category: [],
  violation_reporter: [],
  violation_status: [],
  reporting_date: {},
}

const handleCountBrackets = (count?: number): string => (!count ? '' : `(${count})`)

export const reportedViolationSegmentItems = (
  stats: ViolationListResponse['data']['stats'],
): { label: string }[] => [
  {
    label: `${i18n.t('create_label.all')} ${handleCountBrackets(stats?.all)}`,
  },
  {
    label: `${i18n.t('status.open_violations')} ${handleCountBrackets(stats?.pending)}`,
  },
  {
    label: `${i18n.t('status.closed_violations')} ${handleCountBrackets(stats?.action_taken)}`,
  },
  {
    label: `${i18n.t('status.waived_violations')} ${handleCountBrackets(stats?.dismissed)}`,
  },
]

export const violationStatusTranslation: Record<ViolationStatusType, string> = {
  pending: 'status.pending',
  statement_requested: 'status.pending_employee_response',
  employee_responded: 'label.employee_responeded',
  decided: 'label.action_decided',
  applied: 'label.action_applied',
  dismissed: 'status.dismissed',
  voided: 'label.voided',
  disputed: 'status.disputed',
  reverted: 'status.reversed',
  expired: 'status.expired',
  auto_dismissed: 'status.dismiessed_auto',
  dispute_requested: 'status.pending_dispute',
}

export const violationCategoryTranslation = {
  organization_of_work: i18n.t('label.work_organization'),
  working_hours: i18n.t('label.mol_working_hours'),
  worker_behavior: i18n.t('label.worker_behavior'),
  other: i18n.t('label.other_violations'),
  '': '',
}

export const violationActionRuleCategory = [
  { label: i18n.t('label.warning'), key: 'warning' },
  { label: i18n.t('label.action.deduct_percentage'), key: 'deduct_percent' },
  { label: i18n.t('label.action.dedict'), key: 'deduct_days' },
  { label: i18n.t('label.action.termination_with_award'), key: 'termination_with_award' },
  { label: i18n.t('label.action.twa'), key: 'termination_without_award' },
  { label: i18n.t('label.action.prevent_promotion'), key: 'prevent_promotion' },
]

export const violationCategoryBackendTranslation = {
  warning: 'label.warning',
  deduct_percent: 'label.action.deduct_percentage',
  deduct_days: 'label.action.dedict',
  termination_with_award: 'label.action.termination_with_award',
  termination_without_award: 'label.action.twa',
  prevent_promotion: 'label.action.prevent_promotion',
}

export const violationActionTranslate = (
  action: ViolationActionsT | null,
  value: number | string | null,
): string | null => {
  if (!action) return null
  let translatedAction = i18n.t(violationCategoryBackendTranslation[action])

  if (value) {
    translatedAction = `${translatedAction} ${+value}${action === 'deduct_percent' ? '%' : ''}`
  }

  return translatedAction
}

export const violationStatusColors: Record<ViolationStatusType, BadgeProps['color']> = {
  pending: 'orange',
  statement_requested: 'orange',
  employee_responded: 'orange',
  decided: 'primary',
  applied: 'green',
  dismissed: 'gray',
  voided: 'red',
  disputed: 'red',
  reverted: 'red',
  expired: 'gray',
  dispute_requested: 'violet',
  auto_dismissed: 'gray',
}

export const occurrenceTranslations = {
  1: 'first_time',
  2: 'second_time',
  3: 'third_time',
  4: 'fourth_time',
  5: 'fifth_time',
  6: 'sixth_time',
  7: 'seventh_time',
}

export const violationEmployeePermeationType = ({
  appliedViolationActions,
  actionsOnReportedViolations,
  canReportViolation,
}: {
  appliedViolationActions: boolean
  actionsOnReportedViolations: boolean
  canReportViolation: boolean
}) => {
  if (appliedViolationActions) {
    return 'Violation Admin'
  } else if (actionsOnReportedViolations) {
    return 'Violation Manager'
  } else if (canReportViolation) {
    return 'Team Manager'
  }
  return 'Employee'
}
