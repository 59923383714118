import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

const initialState = {
  hiring_request_workflows: [],
  hiring_request_workflow: {},
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  isApplicableToAll: false,
  loading: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  let hiring_request_workflows = []
  let newRecord = {}
  let updatedRecord = {}
  let is_applicable_to_all = ''
  switch (action.type) {
    case actions.HIRING_REQUEST_WORKFLOWS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.hiring_request_workflows.length > 0),
      }

    case actions.HIRING_REQUEST_WORKFLOW_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        loading: true,
        hiring_request_workflow: {},
      }

    case actions.HIRING_REQUEST_WORKFLOW_CREATE_PENDING:
    case actions.HIRING_REQUEST_WORKFLOW_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: true,
      }

    case actions.HIRING_REQUEST_WORKFLOWS_LOAD_FULFILLED:
    case actions.HIRING_REQUEST_WORKFLOW_LOAD_FULFILLED:
    case actions.HIRING_REQUEST_WORKFLOW_UNASSIGNED_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        loading: false,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }

    case actions.HIRING_REQUEST_WORKFLOW_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      hiring_request_workflows = [...state.hiring_request_workflows]
      newRecord = payload.data.data.hiring_request_workflow
      return {
        ...state,
        loading: false,
        hiring_request_workflow: {},
        hiring_request_workflows: [...hiring_request_workflows, newRecord],
        editing: {
          ...state.editing,
        },
      }

    case actions.HIRING_REQUEST_WORKFLOW_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      hiring_request_workflows = [...state.hiring_request_workflows]
      updatedRecord = payload.data.data.hiring_request_workflow
      if (updatedRecord.is_default) {
        is_applicable_to_all = updatedRecord.is_applicable_to_all || false
      }
      return {
        ...state,
        is_applicable_to_all,
        loading: false,
        hiring_request_workflows: updateOneListOfRecords(hiring_request_workflows, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }

    case actions.HIRING_REQUEST_WORKFLOWS_LOAD_REJECTED:
    case actions.HIRING_REQUEST_WORKFLOW_LOAD_REJECTED:
    case actions.HIRING_REQUEST_WORKFLOW_CREATE_REJECTED:
    case actions.HIRING_REQUEST_WORKFLOW_DELETE_REJECTED:
    case actions.HIRING_REQUEST_WORKFLOW_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    case actions.HIRING_REQUEST_WORKFLOW_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      hiring_request_workflows = [...state.hiring_request_workflows]
      return {
        ...state,
        error: null,
        loading: false,
      }

    case actions.HIRING_REQUEST_WORKFLOW_EDIT:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }

    case actions.HIRING_REQUEST_WORKFLOW_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }

    case actions.RESET:
      return {
        ...state,
        hiring_request_workflow: {},
        fetching: false,
      }

    default:
      return state
  }
}
