import { useCallback, useState } from 'react'

type NotificationState = {
  loading: boolean
  permission: NotificationPermission
}

type UseNotificationApiReturn = NotificationState & {
  requestPermission: () => Promise<void>
}

const getPermission = (): NotificationPermission => {
  try {
    if (Notification) {
      return Notification?.permission ?? 'default'
    }
  } catch (e) {
    return 'default'
  }
  return 'default'
}

const INITIAL_STATE: NotificationState = {
  permission: getPermission(),
  loading: false,
}

const useNotificationApi = (): UseNotificationApiReturn => {
  const [state, setState] = useState<NotificationState>(INITIAL_STATE)

  const requestPermission = useCallback(async () => {
    try {
      if (state.permission !== 'granted') {
        setState((prevState) => ({ ...prevState, loading: true }))
        const permission = await Notification.requestPermission()
        setState({ loading: false, permission })
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error)
      setState((prevState) => ({ ...prevState, loading: false }))
    }
  }, [state])

  return {
    permission: state.permission,
    loading: state.loading,
    requestPermission,
  }
}

export default useNotificationApi
