import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type OutOfPayrollCellType = {
  employee_id: number
  key: 'loan' | 'expense_claim' | 'business_trip'
  request_id?: number
  paygroup_id?: number
}

type InitialStateType = {
  fetching: boolean
  loading: boolean
  out_of_payrun_report: {
    data: (OutOfPayrollCellType | string)[][]
    header: string[]
  } | null
}

export const initialState: InitialStateType = {
  fetching: false,
  loading: false,
  out_of_payrun_report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_PENDING:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_PENDING:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_PENDING:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_PENDING:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.OUT_OF_PAYROLL_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_FULFILLED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_FULFILLED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_FULFILLED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_FULFILLED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_FULFILLED: {
      if (action?.payload?.data?.message) Toastr.success(action?.payload?.data?.message)
      return {
        ...state,
        loading: false,
      }
    }

    case actions.OUT_OF_PAYROLL_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        out_of_payrun_report: action.payload.data.data.out_of_payrun_report,
        fetching: false,
      }
    }

    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_REJECTED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_ODD_REJECTED:
    case actions.OUT_OF_PAYROLL_REPORT_LOAD_REJECTED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_XERO_REJECTED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_MS_REJECTED:
    case actions.OUT_OF_PAYROLL_EXPORT_JOURNALS_QUICKBOOKS_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
