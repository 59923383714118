import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function subbordinatesLoad(
  employee_id: number,
  params: Record<string, unknown> = {},
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/subordinates`

  return {
    type: actions.SUBORDINATES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function subbordinatesDelete(employee_id: number, id: number): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/subordinates/${id}`

  return {
    type: actions.SUBORDINATES_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export const subbordinatesChange = (
  employee_id: number,
  data: { subordinate: { line_manager_id: number | null }; subordinates_ids: number[] },
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/subordinates`

  return {
    type: actions.SUBORDINATES_CHANGE,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export const loadEmployeeTeamMembers = (
  employee_id: number,
  params: Record<string, unknown> = {},
): Action => {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/team_members`

  return {
    type: actions.LOAD_EMPLOYEE_TEAM_MEMBERS,
    payload: handleAPI(url, params, 'GET'),
  }
}
