import { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

type FramePropTypes = {
  SectionFrame?: boolean
  className?: string
  style?: CSSProperties
  children: ReactNode
}

const Frame: FC<FramePropTypes> = ({ children, SectionFrame, className, ...props }) => {
  return (
    <div
      className={classNames(className, SectionFrame ? styles.sectionFrame : styles.frame)}
      {...props}
    >
      {children}
    </div>
  )
}

export default Frame
