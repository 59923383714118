import { useEffect } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import useCanduCallbacks from 'hooks/useCanduCallbacks'
// ? redux
import { loadSecurityPolicies } from 'redux/setting/security/actionCreators'
import { banksLoad, nationalitiesLoad } from 'redux/actions/staticRecordActions'
import { checkSetupIsDoneLoad } from 'redux/payroll/glMapping/glSetup/actionsCreator'
import { getEnablerData } from 'redux/setting/integration/IntegrationEnablers/actionCreators'
import { employeeNotificationsSettingLoad } from 'redux/appNotifications/NotificationsSetting/actionCreators'
import { payrunActivationStatus } from 'redux/payroll/payrun/actionsCreator'
import { getSwitchableOrgs } from 'redux/setting/orgSubsidiaries/SwitchableOrgs/actionCreators'
import { segmentIdentify } from 'integration/segment'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { organizationLoad } from 'redux/setting/v2/organizationProfileActions'
import { listOnboardingStepsFetchGroups } from 'redux/organizationOnborading/steps/actionCreators'
import { organizationRequiredGroups } from 'containers/authorised/NewOnboarding/pages/Organization/helper'
import { invitationsStatisticsLoad } from 'redux/Overview/InvitationsTracking/actionCreators'
import { loadOnboardingSteps } from 'redux/performanceV2/onboarding/actionCreators'
import { ReminderStatusKey } from 'apis/Automations/ReminderStatus/helper'
import { Statuses } from 'containers/authorised/NewOnboarding/helper'
import useFirebaseHooks from 'utils/useFirebaseHooks'
import { generatePrefix, handleCanduAccess } from 'utils/mixpanel'
import { useLoadRemindersState } from 'apis/Attendance/Reminders/useAttendanceReminder'
import { useCanduStore } from 'store/global/candu/useCanduStore'

type UseNavigationType = {
  initializeAppCalls: () => Promise<void>
}

const useMainNavigation = (): UseNavigationType => {
  const dispatch = useDispatch()

  const { activateAutomation, completeCanduStep, attendanceSurveySubmitted } = useCanduCallbacks()
  const { setCanduIsEnable } = useCanduStore((state) => state.canduStore)

  const { refetch: loadRemindersStats } = useLoadRemindersState({
    query: { enabled: false },
  })

  const current_user = useSelector((s) => s.auth.employee)
  const company = useSelector((s) => s.auth.company)
  const notificationsSettings = useSelector(
    (s) => s.notificationsSetting.employee_configuration?.options,
  )
  const { initializeFirebase } = useFirebaseHooks()

  const manageNotification = current_user?.permission_scopes?.manage_notification
  const manageIntegrations = current_user?.permission_scopes?.manage_integrations
  const isPerformanceEvaluation = company?.subscribed_features?.includes(
    'new_performance_management',
  )
  const isGlSupported = company?.subscribed_features?.includes('gl_accounting')
  const liveAPIs = async (): Promise<void> => {
    dispatch(banksLoad())
    dispatch(nationalitiesLoad())
    dispatch(loadSecurityPolicies({ template: 'public' }))
    dispatch(getEnablerData())
    dispatch(getSwitchableOrgs())
    if (manageNotification) loadRemindersStats()
    if (isPerformanceEvaluation) dispatch(loadOnboardingSteps())
    await Promise.all([
      dispatch(payrunActivationStatus()),
      manageIntegrations && isGlSupported && dispatch(checkSetupIsDoneLoad()),
      dispatch(employeeNotificationsSettingLoad(Number(current_user?.id))),
    ])
  }

  const initializeAppCalls = async (): Promise<void> => {
    const hybridPhase = current_user?.organization.onboarding_info?.onboarding_steps_completed
    const isNewOnboarding = current_user?.permissions?.find(
      (e) => e.module === 'NewOnboarding',
    )?.value
    if (current_user?.is_super_admin) {
      await dispatch(organizationLoad()).then((data) => {
        const canduIsEnable = handleCanduAccess({
          isSegmentEnable:
            data?.value?.data?.data?.organization?.metadata?.is_segment_enable ?? false,
          accountType: company?.account_type,
        })

        if (canduIsEnable) {
          const userIdVal = generatePrefix(current_user?.id.toString())
          window.Candu.init({
            clientToken: import.meta.env.UkVBQ1RfQVBQX0ZFX0NBTkRVX1RPS0VO,
            traits: { preferred_language: i18n.language },
            userId: userIdVal,
            callbacks: {
              triggerRefresh: () => {
                window.Candu?.refreshMembership()
              },
              automation_companydoc: () => {
                activateAutomation(ReminderStatusKey.company_document)
              },
              automation_properationperiod: () => {
                activateAutomation(ReminderStatusKey.probationPeriods)
              },
              automation_employeebirthday: () => {
                activateAutomation(ReminderStatusKey.employeeBirthday)
              },
              automation_employeeanniversary: () => {
                activateAutomation(ReminderStatusKey.employeeAnniversary)
              },
              onboarding_surveysubmitted: () => {
                completeCanduStep('onboarding_survey', 'is_onboarding_survey_appeared')
              },
              payroll_surveysubmitted: () => {
                completeCanduStep('payroll_module_survey', 'is_payroll_module_survey_appeared')
              },
              attendance_surveysubmitted: () => {
                attendanceSurveySubmitted()
              },
              handleSurveyStep: (e, node, action) => {
                const eventing = window.Candu?.getEventing()
                const [step, value] = action.eventName.split(': ')
                eventing.identify(userIdVal, { [`survey${step}`]: value })
              },
            },
          })
          setCanduIsEnable(canduIsEnable)
        }
      })
    }
    await dispatch(invitationsStatisticsLoad())
    if (isNewOnboarding) {
      if (current_user?.organization.status === Statuses.LIVE) {
        liveAPIs()
      } else {
        await dispatch(organizationInfo({}, !!isNewOnboarding))
        if (hybridPhase) {
          dispatch(nationalitiesLoad())
          await dispatch(payrunActivationStatus())
        }
      }
      await dispatch(listOnboardingStepsFetchGroups(organizationRequiredGroups))
      initializeFirebase()
    } else {
      liveAPIs()
    }

    if (current_user?.organization?.id) {
      segmentIdentify({
        ...current_user,
        account_type: company?.account_type,
      })
    }
  }

  useEffect(() => {
    if (!notificationsSettings) return
    initializeFirebase()
  }, [notificationsSettings])

  return {
    initializeAppCalls,
  }
}

export default useMainNavigation
