export const BRIEF_LIST_EMPLOYEES = 'BRIEF_LIST_EMPLOYEES'
export const BRIEF_LIST_EMPLOYEES_PENDING = 'BRIEF_LIST_EMPLOYEES_PENDING'
export const BRIEF_LIST_EMPLOYEES_FULFILLED = 'BRIEF_LIST_EMPLOYEES_FULFILLED'
export const BRIEF_LIST_EMPLOYEES_REJECTED = 'BRIEF_LIST_EMPLOYEES_REJECTED'

export const ASSIGN_TEMPLATE = 'ASSIGN_TEMPLATE'
export const ASSIGN_TEMPLATE_PENDING = 'ASSIGN_TEMPLATE_PENDING'
export const ASSIGN_TEMPLATE_FULFILLED = 'ASSIGN_TEMPLATE_FULFILLED'
export const ASSIGN_TEMPLATE_REJECTED = 'ASSIGN_TEMPLATE_REJECTED'

export const CONFLICT_EMPLOYEES = 'CONFLICT_EMPLOYEES'
export const CONFLICT_EMPLOYEES_PENDING = 'CONFLICT_EMPLOYEES_PENDING'
export const CONFLICT_EMPLOYEES_FULFILLED = 'CONFLICT_EMPLOYEES_FULFILLED'
export const CONFLICT_EMPLOYEES_REJECTED = 'CONFLICT_EMPLOYEES_REJECTED'

export const ASSIGN_SHIFT_STATE = 'ASSIGN_SHIFT_STATE'
export const ASSIGN_SHIFT_STATE_PENDING = 'ASSIGN_SHIFT_STATE_PENDING'
export const ASSIGN_SHIFT_STATE_FULFILLED = 'ASSIGN_SHIFT_STATE_FULFILLED'
export const ASSIGN_SHIFT_STATE_REJECTED = 'ASSIGN_SHIFT_STATE_REJECTED'

export const RESET_CONFLICT_EMPLOYEES = 'RESET_CONFLICT_EMPLOYEES'

export const RESET_ASSIGN_SHIFT_STATE = 'RESET_ASSIGN_SHIFT_STATE'
