export const TRACKING_DIMENSIONS_LOAD = 'TRACKING_DIMENSIONS_LOAD'
export const TRACKING_DIMENSIONS_LOAD_PENDING = 'TRACKING_DIMENSIONS_LOAD_PENDING'
export const TRACKING_DIMENSIONS_LOAD_FULFILLED = 'TRACKING_DIMENSIONS_LOAD_FULFILLED'
export const TRACKING_DIMENSIONS_LOAD_REJECTED = 'TRACKING_DIMENSIONS_LOAD_REJECTED'

export const TRACKING_DIMENSION_LOAD = 'TRACKING_DIMENSION_LOAD'
export const TRACKING_DIMENSION_LOAD_PENDING = 'TRACKING_DIMENSION_LOAD_PENDING'
export const TRACKING_DIMENSION_LOAD_FULFILLED = 'TRACKING_DIMENSION_LOAD_FULFILLED'
export const TRACKING_DIMENSION_LOAD_REJECTED = 'TRACKING_DIMENSION_LOAD_REJECTED'

export const TRACKING_DIMENSION_DELETE = 'TRACKING_DIMENSION_DELETE'
export const TRACKING_DIMENSION_DELETE_PENDING = 'TRACKING_DIMENSION_DELETE_PENDING'
export const TRACKING_DIMENSION_DELETE_FULFILLED = 'TRACKING_DIMENSION_DELETE_FULFILLED'
export const TRACKING_DIMENSION_DELETE_REJECTED = 'TRACKING_DIMENSION_DELETE_REJECTED'

export const TRACKING_DIMENSION_CREATE = 'TRACKING_DIMENSION_CREATE'
export const TRACKING_DIMENSION_CREATE_PENDING = 'TRACKING_DIMENSION_CREATE_PENDING'
export const TRACKING_DIMENSION_CREATE_FULFILLED = 'TRACKING_DIMENSION_CREATE_FULFILLED'
export const TRACKING_DIMENSION_CREATE_REJECTED = 'TRACKING_DIMENSION_CREATE_REJECTED'

export const TRACKING_DIMENSION_UPDATE = 'TRACKING_DIMENSION_UPDATE'
export const TRACKING_DIMENSION_UPDATE_PENDING = 'TRACKING_DIMENSION_UPDATE_PENDING'
export const TRACKING_DIMENSION_UPDATE_FULFILLED = 'TRACKING_DIMENSION_UPDATE_FULFILLED'
export const TRACKING_DIMENSION_UPDATE_REJECTED = 'TRACKING_DIMENSION_UPDATE_REJECTED'

export const TRACKING_DIMENSION_VALUES_LOAD = 'TRACKING_DIMENSION_VALUES_LOAD'
export const TRACKING_DIMENSION_VALUES_LOAD_PENDING = 'TRACKING_DIMENSION_VALUES_LOAD_PENDING'
export const TRACKING_DIMENSION_VALUES_LOAD_FULFILLED = 'TRACKING_DIMENSION_VALUES_LOAD_FULFILLED'
export const TRACKING_DIMENSION_VALUES_LOAD_REJECTED = 'TRACKING_DIMENSION_VALUES_LOAD_REJECTED'

export const RESET_VALUES = 'RESET_VALUES'
