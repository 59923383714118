import * as actions from './actions'

export function pushUndo(data) {
  return {
    type: actions.PUSH_UNDO,
    payload: { id: data.id || Math.floor(Math.random() * 101 + 1), ...data },
  }
}

export function removeUndo(id) {
  return {
    type: actions.REMOVE_UNDO,
    payload: { id },
  }
}
