const Muqeem = {
  // A
  // B
  border_number: 'رقم الحدود',
  birth_country: 'دولة الميلاد',
  birth_date: 'تاريخ الميلاد',
  // C
  cancel_exitreentryvisa: 'إلغاء تأشيرة خروج وعودة',
  cancel_finalexitvisa: 'إلغاء تأشيرة خروج نهائي',
  // D
  // E
  extend_passport: 'تمديد صلاحية جواز',
  extend_exitreentryvisa: 'تمديد تأشيرة خروج وعودة',
  // F
  // G
  // H
  // I
  issue_iqama: 'إصدار إقامة',
  issue_iqama_desc: 'إصدار إقامة لأحد موظفي المنشأة',
  iqama_duration: 'مدة الإقامة',
  issue_exitreentryvisa: 'إصدار تأشيرة خروج وعودة',
  issue_finalexitvisa: 'إصدار تأشيرة خروج نهائي',
  // J
  // K
  // L
  // M
  // N
  new_sponsor_id: 'رقم صاحب العمل الجديد',
  // O
  organization_number: 'رقم المنشأة',
  // P
  passport_issue_city: 'مكان إصدار الجواز',
  // Q
  // R
  // S
  // T
  transfer_iqama: 'نقل خدمات عامل',
  transfer_iqama_desc: 'نقل خدمات لأحد موظفي المنشأة لكفيل آخر',
  transfer: 'نقل خدمات عامل',
  translate_first_name: 'الاسم الاول (المترجم)',
  translate_father_name: 'اسم الوالد (المترجم)',
  translate_grandfather_name: 'اسم الجد (المترجم)',
  translate_family_name: 'اسم العائلة (المترجم)',
  // U
  // V
  // W
  // X
  // Y
  // Z
  iqama_number: 'رقم الإقامة',
  old_passport_number: 'رقم الجواز القديم',
  new_passport_number: 'رقم الجواز الجديد',
  new_passport_issue_date: 'تاريخ إصدار الجواز الجديد',
  new_passport_expiry_date: 'تاريخ انتهاء الجواز الجديد',
  passport_issue_place_mu: 'مكان إصدار الجواز الجديد',
  passport_number: 'رقم الجواز',
  residentName: 'اسم المقيم',
  iqamaNumber: 'رقم الإقامة',
  passportNumber: 'رقم الجواز',
  nationality: 'الجنسية',
  occupation: 'المهنة',
  visaNumber: 'رقم التأشيرة',
  services: 'الخدمات',
  visas: 'التأشيرات',
  operation_report: 'تقرير العمليات',
  muqeem_services: 'خدمات مقيم',
  muqeem: 'مقيم',
  how_use_muqeem_services_jisr: 'شرح استخدام خدمات مقيم من {{app_name}}',
  resident_id: 'هوية المقيم',
  iqama: 'الإقامات',
  IQAMAS: 'الإقامات',
  renew_iqama: 'تجديد إقامة',
  iqama_description: 'تجديد إقامة لأحد موظفي المنشأة',
  passport: 'الجوازات',
  renew_passport: 'تجديد جواز',
  passport_description: 'تحديث معلومات الجواز الجديد لأحد موظفي المنشأة',
  extend_passport_validity: 'تمديد صلاحية جواز',
  extend_passport_description:
    'تحديث معلومة صلاحية الجواز لأحد موظفي المنشأة بتمديد تاريخ الانتهاء',
  report: 'التقارير',
  muqeem_report: 'تقرير مقيم',
  muqeem_report_description: 'طباعة تقرير مقيم لأحد موظفي المنشأة',
  exit_re_entry_visa: 'تأشيرة خروج وعودة',
  exit_re_entry_visa_bad: 'تأشيرة خروج وعودة',
  issue_new: 'إصدار تأشيرة جديدة',
  issue_new_description: 'إصدار تأشيرة خروج وعودة مفردة أو متعددة',
  extend_mu: 'تمديد تأشيرة',
  extend_description: 'تمديد صلاحية تأشيرة خروج وعودة مصدرة مسبقا',
  print: 'طباعة تأشيرة',
  print_description: 'طباعة تأشيرة خروج وعودة مصدرة مسبقا',
  cancel: 'إلغاء',
  cancel_description: 'إلغاء تأشيرة خروج وعودة مصدرة مسبقا',
  final_exit_visa: 'تأشيرة خروج نهائي',
  final_exit_visa_bad: 'تأشيرة خروج نهائي',
  issue_new_fi: 'إصدار تأشيرة جديدة',
  final_exit_visa_description: 'إصدار تأشيرة خروج نهائي لأحد موظفي المنشأة',
  cancel_fin: 'إلغاء تأشيرة',
  final_exit_visa_description_ca: 'إلغاء تأشيرة خروج نهائي مصدرة مسبقا',
  vew_visa: 'تأشيرة جديدة',
  all_visas: 'كل التأشيرات',
  exit_re_entry: 'خروج وعودة',
  final_exit: 'خروج نهائي',
  iqama_no: 'رقم الإقامة',
  visa_type: ' نوع التأشيرة',
  visa_number: 'رقم التأشيرة',
  duration: 'المدة',
  return_before: 'العودة قبل',
  issue_date: 'تاريخ الإصدار',
  extend_vis: 'تمديد',
  cancel_vis: 'إلغاء',
  muqeem_services_but: 'خدمات مقيم',
  add_authorized_user: 'إضافة مستخدم',
  add_authorized_user_description:
    'يمكنك إضافة المستخدمين بصلاحية إدارة المعلومات الشخصية والوظيفية فقط',
  how_to_connect: 'كيفية الربط',
  muqeem_integration_setup_user_management: ' ربط مقيم وإدارة المستخدمين',
  authorized_users: 'مستخدمين خدمات مقيم',
  authorized_users_who_can_see:
    'يمكن للمستخدمين المضافين استخدام خدمات مقيم المتاحة من خلال {{app_name}}، وسيطلب تسجيل الدخول لحساب مقيم من المستخدمين. تأكد من أن المستخدمين المضافين لديهم اسم المستخدم وكلمة المرور لحساب مقيم.',
  admin_mu: 'مشرف النظام',
  muqeem_integration_card_note:
    'الربط مع مقيم يسمح لك بتنفيذ عدة مهام من خلال {{app_name}} لتوفير الوقت على المسؤولين. وتشمل تجديد الإقامة ، إصدار تأشيرة خروج وعودة وإلغائها وتمديدها، تمديد صلاحية جواز السفر.',
  api_activation: 'تفعيل الربط مع مقيم',
  read_article_setup_jisr_muqeem_integration: 'مقالة شرح إعداد ربط {{app_name}} ومقيم',
  muqeem_integration_requires_subscription_package:
    'يتطلب الربط مع مقيم أن تكون باقة الاشتراك شامل',
  activate_indirect_from_your_muqeem_account: 'تفعيل ربط API غير المباشر من مقيم',
  step_1: 'الخطوة الأولى:',
  from_muqeem_portal: 'أضف اشتراك جديد من خلال موقع مقيم. انتقل إلى:',
  organization_management_information_packages:
    '</strong>إدارة المنشاة ←معلومات المنشاة ← <strong>النقاط والباقات.',
  hen_click_add_new_subscription_button: 'ثم اضغط على زر <strong>"إضافة اشتراك جديد"</strong>',
  step_2: 'الخطوة الثانية:',
  choose_indirect_API_package: ' اختر "باقة API غير مباشر"',
  indirect_API_package: 'ستجد تفاصيل الاشتراك والربط من مقيم عن طريق باقة API غير مباشر.',
  step_3: 'الخطوة الثالثة:',
  click_select_integration_partner_button: 'اضغط على زر "اختيار شريك الربط"',
  step_4: 'الخطوة الرابعة:',
  once_the_success_message:
    'بعد ظهور رسالة التأكيد، سيقوم فريق {{app_name}} بمتابعة الطلب وإكمال الربط لحساب مقيم.',
  from_integration_partners_list: 'اختر "{{app_name}}" من القائمة، ثم اضغط على زر "اشتراك"',
  step_5: 'الخطوة الخامسة:',
  allow_working_days: 'انتظر لمدة يوم أو يوميّ عمل لاكتمال الربط وتفعيله.',
  please_login_to_continue: 'الرجاء تسجيل الدخول للمتابعة',
  continue_mu: 'استمرار',
  select_employee: 'اختر الموظف',
  iqama_renewal_duration: 'مدة التجديد',
  filled_from_employee_data: 'من ملف الموظف',
  report_language: 'لغة التقرير',
  service_details: 'تفاصيل الخدمة',
  duration_type: 'نوع المدة',
  english: 'إنجليزي',
  arabic: 'عربي',
  search_by_id_iqama: ' البحث بالإقامة او بالرقم الوظيفي او الاسم',
  search_by_id_name: 'البحث  بالرقم الوظيفي او الاسم',
  service: 'الخدمة',
  new_muqeem_service: 'عملية مقيم جديدة',
  submit_to_muqeem: 'إرسال الى مقيم  ',
  cancel_footer: 'إلغاء',
  action_type_mu: 'نوع الإجراء',
  iqamas: 'الإقامات',
  renew: 'تجديد',
  months_3: '3 أشهر',
  months_6: '6 أشهر',
  months_9: '9 أشهر',
  months_12: '12 شهر',
  operation_could_take_some_time_reflect: 'يستغرق ظهور معلومات العملية في مقيم بعض الوقت',
  Iqama: ' إقامة',
  muqeem_response: 'معلومات العملية من مقيم',
  resident_name: 'اسم المقيم',
  version_number: 'رقم النسخة',
  new_iqama_expiry: 'تاريخ الانتهاء الجديد',
  new_iqama_information_mes: 'معلومات الإقامة الجديدة محدثة في ملف الموظف',
  passports: 'الجوازات',
  extend_type: 'تمديد',
  select_city: 'اختر المدينة',
  passport_validity: 'تمديد صلاحية',
  Passport: 'جواز',
  passport_information_mes: 'معلومات الجواز الجديدة محدثة في ملف الموظف',
  issue: 'إصدار',
  single_muq: 'مفردة',
  multiple: 'متعددة',
  duration_in_days: 'المدة بالأيام',
  return_before_specific_date: 'العودة قبل تاريخ محدد',
  visa_duration: 'مدة التأشيرة',
  hijri: 'هجري:',
  visa_extention_duration: 'مدة تمديد التأشيرة',
  last_issued_exit_reentry_visa: 'آخر تأشيرة خروج وعودة مصدرة',
  print_visa: 'طباعة التأشيرة',
  visa_status: 'حالة التأشيرة ',
  iqama_expiry: 'تاريخ انتهاء الإقامة',
  passport_expiry: 'تاريخ انتهاء الجواز',
  return_before_old_date: 'العودة قبل (القديم)',
  return_before_new_date: 'العودة قبل (الجديد)',
  renew_Passport: 'تجديد جواز',
  extend_Passport: 'تمديد صلاحية جواز',
  issue_ExitReEntryVisa: 'إصدار تأشيرة خروج وعودة',
  extend_ExitReEntryVisa: 'تمديد تأشيرة خروج وعودة',
  cancel_ExitReEntryVisa: 'إلغاء تأشيرة خروج وعودة',
  issue_FinalExitVisa: 'إصدار تأشيرة خروج نهائي',
  cancel_FinalExitVisa: 'إلغاء تأشيرة خروج نهائي',
  muqeem_user: 'مستخدم مقيم',
  authentication: 'التحقق',
  muqeem_emarks: 'ملاحظات مقيم',
  result: 'الحاله',
  time: 'الوقت',
  operation_type: 'نوع العملية',
  last_issued_final_exit_visa: 'آخر تأشيرة خروج نهائي مصدرة',
}

export default Muqeem
