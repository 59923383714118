import { Action } from 'types/redux'
import { handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export const countriesLoad = (params = {}): Action => {
  const url = '/v2/onboarding/countries'

  return {
    type: actions.ONBOARDING_COUNTRIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function listRegions(id: number | null): Action {
  const url = `/v2/onboarding/countries/${id}/regions`

  return {
    type: actions.ONBOARDING_RIGIONS_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function getCountryCities(countryId: number): Action {
  const url = `/v2/onboarding/countries/${countryId}/city_list`
  return {
    type: actions.ONBOARDING_COUNTRY_CITIES_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export const citiesLoad = (id: number): Action => {
  const url = `/v2/onboarding/regions/${id}/cities`

  return {
    type: actions.ONBOARDING_REGIONS_CITIES_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}
