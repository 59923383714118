/** **************
 *  overtimeRequestsActions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function overtimeRequestsCreate(employee_id, data) {
  const url = `/${API_VERSION_TWO}/attendance/employees/${employee_id}/overtime_requests`
  const method = 'POST'
  return {
    type: 'OVERTIME_REQUEST',
    payload: handleAPI(url, {}, method, { overtime_request: data }),
  }
}

export function overtimeRequestsUpdate(id, data) {
  const url = `/${API_VERSION_TWO}/attendance/overtime_requests/${id}`
  const method = 'PUT'

  return {
    type: 'UPDATE_OVERTIME_REQUEST',
    payload: handleAPI(url, {}, method, { overtime_request: data }),
  }
}

export function updateOvertime(overtime_request_id, data) {
  const url = `/${API_VERSION_TWO}/attendance/overtime_requests/${overtime_request_id}/hour_rate_update`
  const method = 'PUT'
  return {
    type: 'UPDATE_OVERTIME',
    payload: handleAPI(url, {}, method, data),
  }
}
