import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type NationalitiesTypes = {
  filterKey: string
  filters: string[]
}

const Nationalities = (props: NationalitiesTypes): JSX.Element => {
  const { nationalities } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(nationalities)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default Nationalities
