import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { OnboardingTier, Statuses } from 'containers/authorised/NewOnboarding/helper'
import { useSelector } from 'utils/hooks'

export type SearchTypes = {
  deleted_id: number
  department_path: number[]
  isCreate: boolean
  value: number
  label: string
}

type ContextTypes = {
  isInOnboarding: boolean
  search?: {
    [P in keyof SearchTypes]?: SearchTypes[P]
  }
  setSearch: Dispatch<SetStateAction<Record<string, unknown>>>
  setShowEmployees: Dispatch<SetStateAction<boolean>>
  showEmployees: boolean
  isNewOnboarding?: boolean
  isHighTier?: boolean
}

type Props = {
  children: ReactNode
}

type IsInOnboardingType = {
  organization: {
    organization: {
      metadata: {
        first_admin_details: {
          first_admin_token: string
        }
      }
    }
  }
}

export const Context = createContext<ContextTypes>({
  setSearch: () => null,
  setShowEmployees: () => null,
  showEmployees: false,
  isInOnboarding: false,
  isNewOnboarding: false,
  isHighTier: false,
})

export const useDepartmentsContext = (): ContextTypes => useContext(Context)

const DepartmentsProvider = ({ children }: Props): JSX.Element => {
  const [search, setSearch] = useState({})
  const [showEmployees, setShowEmployees] = useState(false)
  const employee = useSelector(({ auth }) => auth.employee)
  const isInOnboarding = useSelector(
    ({ organization }: IsInOnboardingType) =>
      !!organization.organization.metadata.first_admin_details?.first_admin_token,
  )
  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER || false

  return (
    <Context.Provider
      value={{
        search,
        setSearch,
        showEmployees,
        setShowEmployees,
        isInOnboarding,
        isNewOnboarding:
          employee?.organization?.new_onboarding_enabled &&
          employee?.organization.status === Statuses.ONBOARDING_PHASE,
        isHighTier,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default DepartmentsProvider
