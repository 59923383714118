import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { Grid } from 'components/global/atoms'
import { Button, Typography, Spacer, Flex } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import Avatar from '@material-ui/core/Avatar'
import { commercialLoad } from 'redux/organizationOnborading/commercialRegistrations/actionCreators'
import { isEmpty } from 'lodash'
import styles from '../styles.module.css'
import ViewMoreModal from './ViewMoreModal'
import { THandleEditStep } from '../SummaryReview'

function PayrollBankSummary({ handleEditStep }: THandleEditStep): JSX.Element {
  const { commercial_registrations } = useSelector((s) => s?.commercialRegistrationsOnborading)
  const { require_pincode } = useSelector(({ organization }) => organization?.organization)
  const bankConuts = commercial_registrations?.filter((item) => item.bank_accounts)
  const payroll_bank = useMemo(
    () => commercial_registrations?.slice(0, 2),
    [commercial_registrations],
  )
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    dispatch(commercialLoad({ with_bank_accounts: true }))
  }, [])

  return (
    <>
      <div className={styles.cardContainer}>
        <Flex
          justifyBetween
          itemsCenter
          className={styles.cardHeader}
        >
          <Typography
            variant="subtitle-1"
            text={I18n.t('payroll_bank')}
          />
          <Button
            label={I18n.t('edit')}
            onClick={(): void => handleEditStep(require_pincode ? 6 : 8)}
            size="small"
            variant="outlined"
            color="neutral"
          />
        </Flex>
        <Grid
          container
          spacing={1}
          className={styles.cardBody}
        >
          {payroll_bank?.map((item) => (
            <Grid
              item
              md={6}
              sm={12}
              key={item.id}
            >
              <Grid
                item
                md={6}
                sm={12}
              >
                <Typography
                  variant="subtitle-2"
                  style={{ color: 'var(--color-base-colors-grey-500)' }}
                  text={I18n.t('cr_registration_number')}
                />

                <Spacer height={8} />
                <Typography
                  variant="interface/default/m"
                  text={item.registration_number}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
              >
                <Typography
                  variant="subtitle-2"
                  style={{ color: 'var(--color-base-colors-grey-500)' }}
                  text={I18n.t('cr_name')}
                />

                <Spacer height={8} />
                <Typography
                  variant="interface/default/m"
                  text={item.name}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
              >
                <Typography
                  variant="subtitle-2"
                  style={{ color: 'var(--color-base-colors-grey-500)' }}
                  text={I18n.t('bank_name')}
                />

                <Spacer height={8} />
                <Flex
                  justifyBetween
                  itemsCenter
                  style={{ gap: 8, width: 210 }}
                >
                  <Typography
                    variant="interface/default/sm"
                    text={
                      (!isEmpty(item.bank_accounts) && item?.bank_accounts[0]?.bank_name) || '-'
                    }
                  />
                  {!isEmpty(item.bank_accounts) && item.bank_accounts[0]?.bank_logo && (
                    <Avatar
                      style={{ width: 24, height: 24 }}
                      src={
                        !isEmpty(item.bank_accounts)
                          ? item.bank_accounts[0]?.bank_logo?.logo_thumb
                          : ''
                      }
                      alt={!isEmpty(item.bank_accounts) ? item.bank_accounts[0]?.bank_name : ''}
                    />
                  )}
                </Flex>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
              >
                <Typography
                  variant="subtitle-2"
                  style={{ color: 'var(--color-base-colors-grey-500)' }}
                  text={I18n.t('iban_onboarding')}
                />

                <Spacer height={8} />
                <Typography
                  variant="interface/default/m"
                  text={!isEmpty(item.bank_accounts) ? item.bank_accounts[0]?.iban : '-'}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {bankConuts?.length > 2 && (
          <Flex
            justifyCenter
            itemsCenter
            className={styles.cardFooter}
          >
            <Button
              label={I18n.t('view_more')}
              onClick={(): void => setOpen(true)}
              size="small"
              variant="ghost"
            />
          </Flex>
        )}
      </div>
      <ViewMoreModal
        isOpen={open}
        onClose={(): void => setOpen(false)}
      />
    </>
  )
}

export default PayrollBankSummary
