import { Dispatch, SetStateAction } from 'react'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Flex, Button, Spacer, Modal } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { Form, TextInputField, CheckboxField, DatePickerDSField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import { ReactComponent as InfoIcon } from 'assets/figma-icons/info-blue.svg'
import { THolidaysData } from 'redux/leavesOnboarding/holidays/type'
import styles from './styles.module.css'

type FormCustomLeaveProps = {
  fetching: boolean
  initialValues: THolidaysData | Record<string, never>
  listDisabled: string[]
  onClose: Dispatch<SetStateAction<boolean>>
  open: boolean
  onSubmit: (value: THolidaysData) => void
}

const FormCustomLeave = ({
  open,
  onClose,
  onSubmit,
  fetching,
  initialValues,
  listDisabled,
}: FormCustomLeaveProps): JSX.Element => {
  const isEdit = !!initialValues.id

  return (
    <Modal
      header={{
        title: initialValues.id
          ? I18n.t('update_add_holidays_leave')
          : I18n.t('add_holidays_leave'),
        withClose: true,
      }}
      open={open}
      onClose={(): void => onClose(false)}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }): JSX.Element => (
          <div className={styles.contentModal}>
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              name="name"
              label={I18n.t('name_in_english_onboarding')}
              placeholder={I18n.t('name_in_english_onboarding')}
              disabled={listDisabled.includes(initialValues.name)}
              validate={required}
              autoFocus={!initialValues.id}
            />
            <Spacer height="8px" />
            {/* @ts-expect-error Need to migrate TextInputField.js to TypeScript */}
            <TextInputField
              name="name_ar"
              label={I18n.t('name_in_arabic')}
              placeholder={I18n.t('name_in_arabic')}
              disabled={listDisabled.includes(initialValues.name)}
              validate={required}
            />
            <Spacer height="8px" />
            <Flex
              justifyBetween
              style={{ gap: 8 }}
            >
              <div style={{ width: '100%' }}>
                <DatePickerDSField
                  name="from_date"
                  textFieldProps={{
                    placeholder: I18n.t('from_date'),
                    disabled: listDisabled.includes(initialValues.name),
                  }}
                  label={I18n.t('from_date')}
                  validate={required}
                />
              </div>
              <div style={{ width: '100%' }}>
                <DatePickerDSField
                  name="to_date"
                  textFieldProps={{
                    placeholder: I18n.t('to_date'),
                    disabled: listDisabled.includes(initialValues.name),
                  }}
                  label={I18n.t('to_date')}
                  validate={required}
                />
              </div>
            </Flex>
            <Spacer height="8px" />
            <Flex
              style={{ gap: 10 }}
              itemsCenter
            >
              <CheckboxField
                name="extend_probation"
                label={I18n.t('extend_for_probation')}
              />
              <Tooltip
                description={I18n.t('activate_this_option')}
                placement="top"
                trigger={<InfoIcon />}
              />
            </Flex>
            <Spacer height="16px" />
            <Flex justifyEnd>
              <Button
                label={isEdit ? I18n.t('edit') : I18n.t('add')}
                disabled={fetching || pristine}
                onClick={(): void => {
                  handleSubmit()
                }}
                size="medium"
                style={{ minWidth: 110, justifyContent: 'center' }}
              />
            </Flex>
          </div>
        )}
      />
    </Modal>
  )
}

export default FormCustomLeave
