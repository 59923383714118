import { Fragment, useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { MultiSelect } from '@jisr-hr/design-system'
import { Typography, Spacer, Flex, Radio, Switch, Button, Banner } from '@jisr-hr/ds'
import { isEmpty, isEqual } from 'lodash'
import { useDispatch, useSelector } from 'utils/hooks'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { BenefitTypes } from 'redux/organizationOnborading/VacationSalary/type'
import {
  PayrunConfigsTypes,
  PayrunConfigurationType,
} from 'apis/Finance/payrun_configurations/usePayrunConfigurations.types'
import { useUpdateOnboardingPayrunConfigurations } from 'apis/Finance/payrun_configurations/usePayrunConfigurations'

type SwitchAttendanceReflectionTypes = {
  title: string
  active: boolean
  disabled?: boolean
  hasSwitch?: boolean
  configName: PayrunConfigsTypes
  benefit_type_ids: number[] | undefined
  configurationValue: number | undefined
  onChange?: (v: boolean, t: string) => void
  handleOnRadioChange: (v: number, t: string) => void
}

const options = [
  {
    value: 2,
    label: I18n.t('full_package'),
    testId: 'full-package',
  },
  {
    value: 1,
    label: I18n.t('basic_salary'),
    testId: 'basic-salary',
  },
  {
    value: 3,
    label: I18n.t('basic_selected_allowances'),
    testId: 'basic-selected-allowances',
  },
]

const SwitchAttendanceReflection = ({
  title,
  active,
  disabled,
  onChange,
  hasSwitch,
  configurationValue,
  configName,
  handleOnRadioChange,
  benefit_type_ids,
}: SwitchAttendanceReflectionTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { mutateAsync: payrunConfigUpdate } = useUpdateOnboardingPayrunConfigurations()
  const [confgValue, setconfgValue] = useState(configurationValue)
  const [benefitsSelected, setBenefitsSelected] = useState<BenefitTypes[]>([])
  const benefitTypes = useSelector(({ VacationSalary }) =>
    VacationSalary?.payroll_transaction_types?.map((i) => ({
      ...i,
      label: i.name_i18n,
      value: i.id,
    })),
  )

  const onChange1 = (v: number, t: string): void => {
    handleOnRadioChange(v, t)
    setconfgValue(v)
  }

  const onClickSave = (): void => {
    const data: PayrunConfigurationType[] = [
      {
        name: configName as PayrunConfigsTypes,
        value: 3,
        benefit_type_ids: benefitsSelected?.map((f) => f.id) || [],
      },
    ]
    payrunConfigUpdate({ configurations: data }).then(() => dispatch(organizationInfo()))
  }

  useEffect(() => {
    if (benefit_type_ids?.length) {
      const getSelected = benefitTypes?.filter((i) => benefit_type_ids?.includes(i.id))
      setBenefitsSelected(getSelected)
    }
  }, [benefit_type_ids?.length, benefitTypes?.length])

  return (
    <>
      <Flex justifyBetween>
        <Typography
          variant="title-1"
          text={I18n.t(title)}
        />

        {hasSwitch && (
          <Switch
            onChange={(_, v): void => onChange?.(v, configName)}
            active={active}
            disabled={disabled}
          />
        )}
      </Flex>
      {active && (
        <>
          <Spacer height={8} />
          {options.map((item, i) => (
            <Fragment key={i}>
              <Radio
                label={item.label}
                value={item.value}
                disabled={disabled}
                active={(confgValue || configurationValue) === item.value}
                onChange={(v): void => onChange1(Number(v.target.value), configName)}
              />
              <Spacer height={options.length === i + 1 ? 8 : 16} />
            </Fragment>
          ))}
          {(confgValue || configurationValue) === 3 && (
            <>
              <Spacer height={4} />
              <Flex itemsEnd>
                <MultiSelect
                  label={I18n.t('allowance_types')}
                  options={benefitTypes}
                  value={benefitsSelected}
                  onChange={(data: BenefitTypes[]): void => setBenefitsSelected(data)}
                  maxWidth="277px"
                  isClearable
                  testId="benefit_types"
                />
                <Spacer width={8} />
                <Button
                  label={I18n.t('save')}
                  size="medium"
                  variant="filled"
                  color="primary"
                  onClick={onClickSave}
                  disabled={
                    isEmpty(benefitsSelected) ||
                    isEqual(benefit_type_ids, benefitsSelected?.map((i) => i.id) || [])
                  }
                />
              </Flex>
            </>
          )}
          <Spacer height={16} />
          <Banner
            title={I18n.t('quick_info_to_be_considered')}
            variant="tinted"
            size="compact"
            color="info"
            description={I18n.t('configuration_deduction_note')}
            leadingIcon="info-circle-filled"
          />
        </>
      )}
    </>
  )
}

export default SwitchAttendanceReflection
