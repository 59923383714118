export const RESET_TRACKING = 'RESET_TRACKING'

export const RESET_GROUP_LIST = 'RESET_GROUP_LIST'

export const TRACKING_LIST_LOAD = 'TRACKING_LIST_LOAD'
export const TRACKING_LIST_LOAD_PENDING = 'TRACKING_LIST_LOAD_PENDING'
export const TRACKING_LIST_LOAD_FULFILLED = 'TRACKING_LIST_LOAD_FULFILLED'
export const TRACKING_LIST_LOAD_REJECTED = 'TRACKING_LIST_LOAD_REJECTED'

export const GROUP_BY_LIST_LOAD = 'GROUP_BY_LIST_LOAD'
export const GROUP_BY_LIST_LOAD_PENDING = 'GROUP_BY_LIST_LOAD_PENDING'
export const GROUP_BY_LIST_LOAD_FULFILLED = 'GROUP_BY_LIST_LOAD_FULFILLED'
export const GROUP_BY_LIST_LOAD_REJECTED = 'GROUP_BY_LIST_LOAD_REJECTED'

export const ATTENDANCE_RECORD_DETAIL = 'ATTENDANCE_RECORD_DETAIL'
export const ATTENDANCE_RECORD_DETAIL_PENDING = 'ATTENDANCE_RECORD_DETAIL_PENDING'
export const ATTENDANCE_RECORD_DETAIL_FULFILLED = 'ATTENDANCE_RECORD_DETAIL_FULFILLED'
export const ATTENDANCE_RECORD_DETAIL_REJECTED = 'ATTENDANCE_RECORD_DETAIL_REJECTED'

export const ATTENDANCE_RECORD_DETAIL_POPUP = 'ATTENDANCE_RECORD_DETAIL_POPUP'
export const ATTENDANCE_RECORD_DETAIL_POPUP_PENDING = 'ATTENDANCE_RECORD_DETAIL_POPUP_PENDING'
export const ATTENDANCE_RECORD_DETAIL_POPUP_FULFILLED = 'ATTENDANCE_RECORD_DETAIL_POPUP_FULFILLED'
export const ATTENDANCE_RECORD_DETAIL_POPUP_REJECTED = 'ATTENDANCE_RECORD_DETAIL_POPUP_REJECTED'

export const ATTENDANCE_UPDATE_RECORD = 'ATTENDANCE_UPDATE_RECORD'
export const ATTENDANCE_UPDATE_RECORD_PENDING = 'ATTENDANCE_UPDATE_RECORD_PENDING'
export const ATTENDANCE_UPDATE_RECORD_FULFILLED = 'ATTENDANCE_UPDATE_RECORD_FULFILLED'
export const ATTENDANCE_UPDATE_RECORD_REJECTED = 'ATTENDANCE_UPDATE_RECORD_REJECTED'
