import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  expense_types: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
}

let changes = null
export default function expenseTypeReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let expenseTypes = []
  let newRecord = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'EXPENSE_TYPES_LOAD_PENDING':
    case 'EXPENSE_TYPE_CREATE_PENDING':
    case 'EXPENSE_TYPE_UPDATE_PENDING':
    case 'EXPENSE_TYPE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.expense_types.length > 0),
      }

    case 'EXPENSE_TYPES_LOAD_REJECTED':
    case 'EXPENSE_TYPE_CREATE_REJECTED':
    case 'EXPENSE_TYPE_UPDATE_REJECTED':
    case 'EXPENSE_TYPE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'EXPENSE_TYPES_LOAD_FULFILLED':
      changes = {
        ...payload.data.data,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'EXPENSE_TYPE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      expenseTypes = [...state.expense_types]
      newRecord = payload.data.data.expense_type
      changes = {
        expense_types: [...expenseTypes, newRecord],
        loading: false,
        editing: {
          ...state.editing,
          New: false,
        },
      }
      return { ...state, ...changes }

    case 'EXPENSE_TYPE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      expenseTypes = [...state.expense_types]
      updatedRecord = payload.data.data.expense_type
      changes = {
        loading: false,
        expense_types: updateOneListOfRecords(expenseTypes, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'EXPENSE_TYPE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      expenseTypes = [...state.expense_types]
      return {
        ...state,
        fetching: false,
        loading: false,
        expense_types: removeOneListOfRecords(expenseTypes, payload.id),
        error: null,
      }

    case 'EXPENSE_TYPE_EDIT':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
      return { ...state, ...changes }
    case 'EXPENSE_TYPE_EDIT_CANCEL':
      changes = {
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
