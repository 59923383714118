import { Button, Typography, Flex, Spacer } from '@jisr-hr/ds'
import cns from 'classnames'
import I18n from 'translations/i18n'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import SwitchCard from 'containers/public/Onboarding/components/SwitchCard'
import {
  onboardingBenefitLoad,
  onboardingBenefitDelete,
  onboardingBenefitCreate,
  benefitImportFromMainOrganization,
} from 'redux/payrollOnboarding/allowances/actionsCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'
import { allowancesSelector } from 'redux/payrollOnboarding/allowances/selectors'
import { ReactComponent as Plus } from 'assets/figma-icons/add.svg'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'utils/hooks'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { TAllowances } from 'redux/payrollOnboarding/allowances/reducer'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import styles from './styles.module.css'
import AllowanceForm from './AllowanceForm'

export type TEditValue = {
  id?: number | null
  name?: string
  name_ar?: string
}

const Allowances = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const { allowances, loading } = useSelector(allowancesSelector)
  const { payroll_setup } = useSelector(({ organization }) => organization?.organization?.metadata)
  const isSubOrg = useSelector(
    ({ organization }) => organization?.organization?.is_organization_subsidiary,
  )
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState<TEditValue>({ id: null, name: '', name_ar: '' })
  const [list, setList] = useState([...(allowances || [])])

  const handleChange = (data: TAllowances, index: number): void => {
    const newList = list
    newList[index].enabled = !newList[index]?.enabled
    setList(newList)
    if (data.enabled) {
      dispatch(
        onboardingBenefitCreate({ ...data, category: 'EarningType', is_optional: false }),
      ).then(() => {
        dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
        dispatch(organizationInfo())
      })
    } else {
      dispatch(onboardingBenefitDelete(data.id, { category: 'EarningType' })).finally(() => {
        dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
        dispatch(organizationInfo())
      })
    }
  }

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'allowances' })

  useEffect(() => {
    setList([...(allowances || [])])
  }, [allowances])

  useEffect(() => {
    dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
  }, [])

  return (
    <StepTemplate
      goNextStep={(): void => {
        if (!payroll_setup?.allowances?.is_completed) {
          dispatch(organizationInfo())
        }
        goNextStep?.()
      }}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Flex flexCol>
        <Flex justifyBetween>
          <Typography
            variant="heading"
            text={I18n.t('allowances_onboarding')}
          />
          <Spacer height="8px" />
          {isSubOrg && (
            <Button
              disabled={loading}
              label={I18n.t('label.import_from_main_organization')}
              trailingIcon="download-01"
              iconOnly
              onClick={(): void => {
                dispatch(benefitImportFromMainOrganization()).then(() => {
                  dispatch(onboardingBenefitLoad({ category: 'EarningType' }))
                })
              }}
              size="medium"
              data-testid="save"
            />
          )}
        </Flex>
        <Typography
          variant="subtitle-1"
          style={{ color: 'var(--color-base-colors-black-500)' }}
          text={I18n.t('allowances_desc')}
        />
      </Flex>
      <Spacer height="32px" />
      <Flex
        flexCol
        className={styles.listContainer}
      >
        <Flex
          justifyBetween
          itemsCenter
        >
          <Typography
            variant="title-1"
            text={I18n.t('allowances_list')}
          />

          <Plus
            className={styles.plusIcon}
            onClick={(): void => {
              setOpen(true)
            }}
          />
        </Flex>
        <Spacer height="16px" />
        <Flex
          flexCol
          className={cns(styles.listInnerContainer, 'scroll-y')}
        >
          {list?.map((item, i) => (
            <SwitchCard
              key={i}
              name={item.name_i18n}
              disabled={['Salary'].includes(item.name)}
              isActive={item.enabled}
              unEditable={!item.is_optional}
              handleOnEdit={(): void => {
                setEdit(item)
                setOpen(true)
              }}
              handleOnChange={(): void => {
                handleChange(item, i)
              }}
            />
          ))}
        </Flex>
      </Flex>

      <AllowanceForm
        isOpen={open}
        onClose={(): void => {
          setOpen(false)
          setEdit({})
        }}
        editItem={setEdit}
        initialValues={
          edit.id
            ? {
                id: edit.id,
                name: edit.name,
                name_ar: edit.name_ar,
              }
            : {}
        }
      />
    </StepTemplate>
  )
}

export default Allowances
