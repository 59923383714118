import React from 'react'

const HOC = (WrappedComponent) => {
  class MultipleSelect extends React.Component {
    state = { value: {} }

    handleOnChange = (group, option) => {
      const { value } = this.state
      value[group] = [...(value[group] || []), option]
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          value={this.state.value}
          handleOnChange={this.handleOnChange}
        />
      )
    }
  }

  return MultipleSelect
}

export default HOC
