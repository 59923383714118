const OffCyclePayroll = {
  // A
  approved: 'معتمدة',
  add_addition: 'إضافة مالية',
  add_deduction: 'اضافة خصم',
  advance_salary: 'راتب مقدم؟',
  add_new_addition: 'إضافة مالية',
  add_to_salary: 'إضافة إلى الراتب',
  approve_reason: 'سبب الموافقة ',
  attendance_for_not_yet_pproved: ' سجل حضور والانصراف  {{from}}-{{to}}للموظف لم يتم اعتماده',
  attendance_for_not_yet_reviewed: 'سجل حضور والانصراف {{from}}-{{to}} للموظف لم تتم مراجعته بعد  ',
  attendance_status_pending_reminder_off:
    'المتابعة بدون إعتماد سجل حضور والانصراف للموظف سيلغي كافة الاضافات والخصميات المرتبطة بالحضور والانصراف مثل الغياب والعمل الإضافي',
  attendance_period_to_be_approved_off: 'فترة سجل الحضور والانصراف للمراجعة والتأكيد',
  addition_details: 'تفاصيل الإضافات',
  approval_form_note_offcycle:
    'هذا الاعداد لمرة واحدة بعد انشاء سلسلة الموافقات سيتم تطبيقها بشكل تلقائي  يمكنك تعديل سلسلة الموافقات من',
  approve_final_Settelement_request: 'الموافقة على طلب مراجعة  المخالصة النهائية',
  approve_vacation_Settelement_request: 'الموافقة على طلب مراجعة  مخالصة الإجازة',
  attendance_requested: 'كشف الحضور مطلوب',
  attendance_approved: 'كشف الحضور معتمد',
  attendance_dismissed: 'كشف الحضور لم يحتسب',
  no_attendance_request: 'كشف الحضور لم يرسل',
  // B
  bank_iban: 'رقم الآيبان',
  calculation_basic: 'اساس الإحتساب',
  back_to_vacation_settlement: 'العودة للمخالصات الإجازة',
  back_to_final_settlement: 'العودة للمخالصات النهائية',
  pending_on_approval: 'أرسلت للموافقة',
  // C
  created_at: 'تاريخ الإضافة',
  comments: 'تعليقات',
  created_on_off: 'تاريخ الطلب',
  confirm_final_settlement: 'تأكيد المخالصة النهائية',
  confirm_vacation_settlement: 'تأكيد مخالصة الإجازة',
  create_approval_cycle_offcycle: 'إنشاء سلسة الموافقات',
  continu_without_approval_cycle_offcycle: 'استكمال بدون سلسة موافقات',
  cancel_settlement: 'إلغاء المخالصة',
  // D
  deductions_off: 'الخصميات',
  deduction_types_off: 'نوع الخصم',
  deductionـdetails: 'تفاصيل الخصميات',
  daily_salary_rate: 'معدل الراتب اليومي',
  duration_of_service: 'مدة الخدمة',
  deductions_details: 'تفاصيل الخصميات',
  dismiss_vacation_settlement: 'إلغاء طلب مخالصة الإجازة',
  dismiss_final_settlement: ' إلغاء طلب مخالصة نهاية  الخدمة',
  dismiss_warning: 'إلغاء التسوية سيؤدي إلى إزالتها نهائيًا ، هل أنت متأكد من أنك تريد إلغاءها؟',
  delete_addition: 'حذف الإضافة؟',
  delete_deduction: 'حذف الخصمية؟',
  delete_warning_addition: 'سوف يتم حذف هذه الإضافة بشكل دائم, هل أنت متأكد من عملية الحذف؟',
  delete_warning_deduction: 'سوف يتم حذف هذه الخصمية بشكل دائم, هل أنت متأكد من عملية الحذف؟',
  delete_remarks: 'حذف الملاحظة؟',
  delete_warning_remarks: 'سوف يتم حذف هذه االملاحظة, هل أنت متأكد من عملية الحذف؟',
  days_number: '{{days}} أيام',
  // E
  exit_and_re_entry_visa: 'تأشيرة خروج وعودة',
  earnings: 'الراتب والبدلات',
  exit_re_entry_details: 'تفاصيل تأشيرة الخروج والعودة',
  employees_on_leave_from_the_same_team: 'موظفين من نفس الفريق في إجازة',
  edit_remark: 'تعديل الملاحظات',
  edit_deduction: 'تعديل الخصم ',
  edit_addition_off: 'تعديل الإضافة',
  employee_joining_date: 'تاريخ التحاق الموظف',
  end_of_service_reason: 'سبب انتهاء الخدمة',
  endـofـserviceـreward: 'مكافأة نهاية الخدمة',
  eligible_end_of_service: 'يستحق مكافأة نهاية الخدمة؟',
  eligible_leave_settlement: 'يستحق تسوية رصيد الإجازة؟',
  end_of_service_reward_amount: 'مبلغ مكافأة نهاية الخدمة',
  excluded_service_period: 'Excluded service period AR',
  // F
  final_settlement: 'المخالصة النهائية{{name}}',
  final_Settlement: 'المخالصة النهائية لـ {{name}}',
  flight_ticket: 'تذكرة السفر',
  flight_ticket_info: 'معلومات تذكرة السفر',
  from_cut_off: 'من تاريخ إستقطاع الشهر السابق',
  finalـsettlement_request: 'طلب المخالصة النهائية',
  finalـsettlement_request_tit: 'طلب مخالصة نهاية الخدمة ',
  full_package_salary: 'الراتب كاملاً',
  final_settlement_review_request: 'طلب مراجعة وإعتماد المخالصة النهائية',
  filter_payrun_status: 'الحالة',
  filter_attendance_status: 'حالة كشف الحضور',
  // G
  // H
  // I
  issuing_assignee: 'المسؤول عن استخراج التأشيرة',
  issuing_assignee_ticket: 'المسؤول عن استخراج التذكرة',
  issuing_status: 'الحالة',
  in_order_include_attendance_transactions__description:
    'لاحتساب معاملات الحضور والانصراف، يستلزم تأكيد مراجعة الحضور والانصراف للموظف',
  // J
  // K
  // L
  leave_starts_at: 'بداية الإجازة',
  leave_ends_at: 'نهاية الإجازة',
  leavingـdate_off: 'تاريخ المغادرة',
  last_working_day_off: 'آخر يوم عمل',
  leave_settlement: 'تسوية رصيد الإجازة',
  leave_balance_settlement: 'تسوية رصيد الإجازة',
  loan_settlement: 'تسوية السلف',
  leave_to_date: 'تاريخ العودة',
  // M
  months_offcycle: 'أشهر',
  mark_as_paid_off: 'تحديث حالة الدفع',
  // N
  net_service_experience: 'صافي فترة الخدمة',
  no_addition_found: 'لا توجد إضافات لهذا الموظف',
  no_deductions_found: 'لا توجد استقطاعات لهذا الموظف',
  no_remarks_found: 'لا توجد ملاحظات لهذا الموظف',
  notification_date: 'تاريخ الإشعار',
  number_of_leave_balance_days: 'عدد أيام رصيد الإجازة',
  no_details_available: 'لا يوجد تفاصيل',
  note_confirmation_Vacation_settlement:
    '<b>ملاحظة:</b> عند تأكيد مخالصة الإجازة وإكتمال موافقات مراجعة المخالصة ضمن سلسلة الموافقات ، لا يمكن التراجع عن التغييرات التي تم إجرائها (إعادة فتح المخالصة للتعديل), لذا يرجى التأكد من صحة ومطابقة جميع المعلومات المالية قبل إجراء التأكيد النهائي',
  note_confirmation_final_settlement:
    '<b>ملاحظة:</b> عند تأكيد المخالصة النهائية وإكتمال موافقات مراجعة المخالصة ضمن سلسلة الموافقات ، لا يمكن التراجع عن التغييرات التي تم إجرائها (إعادة فتح المخالصة للتعديل), لذا يرجى التأكد من صحة ومطابقة جميع المعلومات المالية قبل إجراء التأكيد النهائي',
  no_lets_review: 'لا, تراجع',
  no_vacation_here: 'لا يوجد مخالصات اجازة حتى الآن',
  new_remark: 'ملاحظة جديدة',
  no_cancel: 'لا, إلغاء',
  net_service_period: 'Net service period AR',
  // O
  okay_offcycle: 'حسنًا',
  ok_approve: 'حسناً, تأكيد الطلب',
  off_cycle_vacation_Settlement: 'مخالصات الإجازة',
  off_cycle_final_Settlement: 'مخالصات نهاية الخدمة',
  offboard_employee: 'إنهاء خدمة موظف',
  once_you_offboard_employee_the_FC_process_will_start_from_this_page:
    'في حالة انهاء خدمة موظف ,يمكنك البدأ في تسوية المخالصات النهائية لموظفيك',

  // P
  process_again: 'إعادة فتح المخالصة',
  payment_assignee: 'المسؤول عن دفع التأشيرة',
  payment_assignee_ticket: 'المسؤول عن الدفع',
  process_timeline: 'سلسلة التغييرات',
  paid_leave_balance_days: 'عدد أيام رصيد الإجازة المدفوعة',
  // R
  request_attendance_review: 'طلب مراجعة الحضور والانصراف',
  re_request_attendance_review: 'Re-Request Attendance Review',
  re_open_final_settlement_message:
    'إعادة فتح الطلب ستؤثر على بيانات وتفاصيل المخالصة وسيتم حذف معلومات الدفع الخاصه بالطلب',
  remarks_off: 'الملاحظات',
  return_date_off: 'تاريخ العودة',
  remark: 'الملاحظة',
  reject_reason: 'سبب الرفض',
  reject_vacation_Settelement_request: 'رفض طلب مراجعة  مخالصة الإجازة',
  reject_final_Settelement_request: 'رفض طلب مراجعة  المخالصة النهائية',
  rejected_resaon: 'سبب الإلغاء',
  request_annual_leave: 'طلب إجازة سنوية',
  // S
  send_to_approval_cycle: 'إرسال للمراجعة والإعتماد',
  send_for_approval: 'إرسال للاعتماد',
  summary_off: 'ملخص',
  send_for_attendance_review: 'إرسال للمراجعة والتأكيد',
  service_period: 'مدة الخدمة',
  salary_details: 'تفاصيل الراتب',
  salary_up_to_last_working_day: 'الراتب حتى آخر يوم عمل ({{work_days}} أيام)',
  salary_settlement_off: 'تسوية الراتب',
  salary_settlement: 'إجمالي الراتب',
  skipping_loan_deduction_off:
    ' تخطي خصم السلفة, سوف يزيل الخصم من مخالصة الاجازة وإعادة جدولته لفترة مسير الرواتب اللاحقة',
  // T
  total_amount_off: 'المبلغ المستحق',
  total_amount: 'المبلغ الإجمالي',
  total_earnings: 'إجمالي الراتب والبدلات',
  total_deductions: 'اجمالي الخصميات',
  total_service_experience: 'اجمالي فترة الخدمة',
  travelers: 'المسافرين ({{count}})',
  ticket_details: 'تذكرة السفر ',
  tip_unpaid_leave_days:
    'عند تجاوز عدد ايام الاجازة الغير المدفوعة ل20 يوم, يتم حسم هذه الأيام من فترة خدمة الموظف',
  to_complete_clearance_attedance_managerP_required_approve_attendance:
    'لإستكمال مخالصة الإجازة, يجب على مسؤول الحضور والانصراف تأكيد سجل الحضور والانصراف للموظف',
  total_pending_amount: 'إجمالي المبلغ المتبقي',
  types: 'أنواع',
  total_service_period: 'Total service period AR',
  // U
  upload_attachment: 'رفع ملف مرفق',
  unpaid_leave_days: 'عدد ايام الأجازة الغير المدفوعة',
  unused_leave_settlement: 'تسوية رصيد الإجازة الغير مستخدم',

  // V
  vacation_Final_Settlement: 'مخالصات الإجازة ونهاية الخدمة',
  vacation_settelment: 'مخالصة الإجازة {{name}}',
  vacation_Settelment: 'مخالصة الإجازة لـ {{name}}',
  vacation_days_salary: 'راتب الإجازة  ({{leave_days}} ايام)',
  view_request: 'عرض تفاصيل الطلب',
  view_details: 'عرض التفاصيل',
  vacation_days: 'أيام الإجازة',
  vacation_days_salary_rem: 'راتب أيام الإجازة',
  vacation_Settelement_Requst:
    'يبدوا أن الموظف طلب الحصول على راتب الإجازة لفترة تقع ضمن فترة رواتب تم إغلاقها/صرفها, لذلك لن يتم إنشاء مخالصة إجازة لهذا الموظف  ',
  vacation_settlement_review_request: 'طلب مراجعة وإعتماد مخالصة الإجازة',
  // W
  working_days_salary: 'راتب أيام العمل قبل الإجازة ({{work_days}} ايام)',
  // X
  // Y
  years_months_days: ' {{years}} سنوات, {{months}} أشهر, {{days}} أيام',
  you_are_about_to_confirm_final_settlement:
    'انت بصدد تأكيد المخالصة النهائية للموظف {{name}} . هل تريد التأكيد؟',
  you_are_about_to_confirm_vacation_settlement:
    'انت بصدد تأكيد مخالصة الإجازة للموظف {{name}} . هل تريد التأكيد؟',

  you_can_request_vacation_for_employees: 'يمكنك البدأ في تسوية المخالصات لموظفيك',
  yes_dismiss_it: 'نعم, ألغ المخالصة',
  yes_delete_it: 'نعم, حذف',
  // Z
}
export default OffCyclePayroll
