import { Action } from 'types/redux'
import * as actions from './actions'
import { PaymentProcessingStateUpdateType } from './actionCreators'

type InitialStateType = {
  // ? Data

  // ? Locals
  download_bank_file_type: 'wps' | 'direct_transfer' | 'payrun' | 'salary_card' | null
  selectedEmployees: number[]
  // ? Fetching

  // ? Modals
  open_download_bank_file_modal: boolean
}

const initialState: InitialStateType = {
  // ? Data

  // ? Locals
  download_bank_file_type: null,
  selectedEmployees: [],
  // ? Fetching

  // ? Modals
  open_download_bank_file_modal: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    // ? set new data to reducer
    case actions.UPDATE_PAYMENT_PROCESSING_STATE: {
      const data = action.payload as PaymentProcessingStateUpdateType
      return {
        ...state,
        ...data,
      }
    }

    default:
      return state
  }
}
