/** **************
 *   applicableRequestsReducer
 /*************** */
const DEFAULT_STATE = {
  fetching: false,
  request_types: [],
}

export default function applicableRequestsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action

  switch (action.type) {
    case 'APPLICABLE_REQUESTS_LOAD_FULFILLED':
      return {
        ...state,
        request_types: payload.data?.data?.request_types ?? [],
        fetching: false,
      }

    case 'APPLICABLE_REQUESTS_TYPES_LOAD_FULFILLED':
      const list = payload.data?.data?.request_types ?? []
      return {
        ...state,
        request_types: list.map((li) => ({ ...li, id: li.id ? li.id : li.type })),
        fetching: false,
      }

    case 'APPLICABLE_REQUESTS_LOAD_PENDING':
    case 'APPLICABLE_REQUESTS_TYPES_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'APPLICABLE_REQUESTS_LOAD_REJECTED':
    case 'APPLICABLE_REQUESTS_TYPES_LOAD_REJECTED':
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
