export const ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW =
  'ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW'
export const ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_PENDING =
  'ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_PENDING'
export const ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_FULFILLED =
  'ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_FULFILLED'
export const ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_REJECTED =
  'ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_REJECTED'

export const ONBOARDING_TRAINING_VIDEOS_RATING_PUT = 'ONBOARDING_TRAINING_VIDEOS_RATING_PUT'
export const ONBOARDING_TRAINING_VIDEOS_RATING_PUT_PENDING =
  'ONBOARDING_TRAINING_VIDEOS_RATING_PUT_PENDING'
export const ONBOARDING_TRAINING_VIDEOS_RATING_PUT_FULFILLED =
  'ONBOARDING_TRAINING_VIDEOS_RATING_PUT_FULFILLED'
export const ONBOARDING_TRAINING_VIDEOS_RATING_PUT_REJECTED =
  'ONBOARDING_TRAINING_VIDEOS_RATING_PUT_REJECTED'

export const TRAINING_VIDEOS_RESET = 'TRAINING_VIDEOS_RESET'
