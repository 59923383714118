import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'
import { AttendancePolicyState } from './attendancePolicyTypes'

const initialState: AttendancePolicyState = {
  fetching: false,
  attendancePolicyStats: null,
  attendanceEmployeePolicy: null,
}

export default function reducer(state = initialState, action: Action): AttendancePolicyState {
  switch (action.type) {
    case actions.ATTENDANCE_POLICY_STATS_PENDING:
    case actions.ATTENDANCE_EMPLOYEE_POLICES_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_POLICY_STATS_FULFILLED:
      const attendancePolicyStats: AttendancePolicyState['attendancePolicyStats'] =
        action.payload.data.data ?? null

      return {
        ...state,
        fetching: false,
        attendancePolicyStats,
      }

    case actions.ATTENDANCE_EMPLOYEE_POLICES_FULFILLED:
      const attendanceEmployeePolicy: AttendancePolicyState['attendanceEmployeePolicy'] =
        action.payload.data.data ?? null

      return {
        ...state,
        fetching: false,
        attendanceEmployeePolicy,
      }

    case actions.ATTENDANCE_POLICY_STATS_REJECTED:
    case actions.ATTENDANCE_EMPLOYEE_POLICES_REJECTED:
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
