import { useEffect } from 'react'
import { Typography, Switch, Banner } from '@jisr-hr/ds'
import { Grid } from 'components/global/atoms'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import {
  employeeNotificationsSettingLoad,
  employeeNotificationsSettingUpdate,
} from 'redux/appNotifications/NotificationsSetting/actionCreators'
import { SettingsOption } from 'containers/authorised/AppNotifications/types'
import useNotificationApi from 'containers/authorised/AppNotifications/useNotificationAPI'
import JisrLoader from 'components/global/JisrLoader'

export default function AnnouncementSettings(): JSX.Element {
  const dispatch = useDispatch()
  const { permission } = useNotificationApi()

  const currentEmployee = useSelector(({ auth }) => auth.employee)
  const {
    employee_configuration: { options: settings },
    fetching,
  } = useSelector(({ notificationsSetting }) => notificationsSetting)

  const handleUpdateSetting = (key: string, val: boolean): void => {
    if (currentEmployee?.id) {
      dispatch(
        employeeNotificationsSettingUpdate(currentEmployee.id, {
          [key]: String(val),
        }),
      )
    }
  }

  const options: SettingsOption[] = [
    {
      label: 'announcements',
      active: settings?.announcements === 'true',
      onChange: (checked): void => {
        handleUpdateSetting('announcements', checked)
      },
    },
  ]

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(employeeNotificationsSettingLoad(currentEmployee.id))
    }
  }, [])

  return (
    <>
      {fetching && <JisrLoader absolute />}
      <Grid
        container
        direction="column"
        spacing={3}
      >
        {permission !== 'granted' && (
          <Grid item>
            <Banner
              color="danger"
              variant="tinted"
              leadingIcon="x-circle-filled"
              title={I18n.t('browser_notifications_disabled_alert_title')}
            />
          </Grid>
        )}
        {options.map((option) => (
          <Grid
            container
            item
            justify="space-between"
            key={option.label}
          >
            <Typography
              text={I18n.t(option.label)}
              variant="subheading"
            />
            <Switch
              active={option.active}
              onChange={(_, checked): void => option.onChange(checked)}
              disabled={permission === 'denied'}
              testId={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
