export const ONBOARDING_FETCH_BY_GROUPS_LIST = 'ONBOARDING_FETCH_BY_GROUPS_LIST'
export const ONBOARDING_FETCH_BY_GROUPS_LIST_PENDING = 'ONBOARDING_FETCH_BY_GROUPS_LIST_PENDING'
export const ONBOARDING_FETCH_BY_GROUPS_LIST_FULFILLED = 'ONBOARDING_FETCH_BY_GROUPS_LIST_FULFILLED'
export const ONBOARDING_FETCH_BY_GROUPS_LIST_REJECTED = 'ONBOARDING_FETCH_BY_GROUPS_LIST_REJECTED'

export const ONBOARDING_STEPS_LIST = 'ONBOARDING_STEPS_LIST'
export const ONBOARDING_STEPS_LIST_PENDING = 'ONBOARDING_STEPS_LIST_PENDING'
export const ONBOARDING_STEPS_LIST_FULFILLED = 'ONBOARDING_STEPS_LIST_FULFILLED'
export const ONBOARDING_STEPS_LIST_REJECTED = 'ONBOARDING_STEPS_LIST_REJECTED'

export const ONBOARDING_COMPLETE_STEP_POST = 'ONBOARDING_COMPLETE_STEP_POST'
export const ONBOARDING_COMPLETE_STEP_POST_PENDING = 'ONBOARDING_COMPLETE_STEP_POST_PENDING'
export const ONBOARDING_COMPLETE_STEP_POST_FULFILLED = 'ONBOARDING_COMPLETE_STEP_POST_FULFILLED'
export const ONBOARDING_COMPLETE_STEP_POST_REJECTED = 'ONBOARDING_COMPLETE_STEP_POST_REJECTED'

export const ONBOARDING_MARK_AS_VISITED_PUT = 'ONBOARDING_MARK_AS_VISITED_PUT'
export const ONBOARDING_MARK_AS_VISITED_PUT_PENDING = 'ONBOARDING_MARK_AS_VISITED_PUT_PENDING'
export const ONBOARDING_MARK_AS_VISITED_PUT_FULFILLED = 'ONBOARDING_MARK_AS_VISITED_PUT_FULFILLED'
export const ONBOARDING_MARK_AS_VISITED_PUT_REJECTED = 'ONBOARDING_MARK_AS_VISITED_PUT_REJECTED'

export const ONBOARDING_ADMINS_COMPLETED_TOUR_PUT = 'ONBOARDING_ADMINS_COMPLETED_TOUR_PUT'
export const ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_PENDING =
  'ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_PENDING'
export const ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_FULFILLED =
  'ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_FULFILLED'
export const ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_REJECTED =
  'ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_REJECTED'
