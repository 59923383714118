import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { getOnboardingUrl } from 'redux/organizationOnborading/organization/actionCreators'
import { Action } from 'types/redux'
import * as actions from './actions'

export function onboardingCarryForwardTypeLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl(
    'organization_configurations/carry_forward_type',
  )}`

  return {
    type: actions.ONBOARDING_CARRYFORWARDTYPE_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingCarryForwardLoad(): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('organization_configurations/carry_forward')}`

  return {
    type: actions.ONBOARDING_CARRYFORWARD_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function onboardingCarryForwardUpdate(data: string, name = 'carry_forward_type'): Action {
  const url = `/${API_VERSION_TWO}/${getOnboardingUrl('organization_configurations')}/${name}`

  return {
    type: actions.ONBOARDING_CARRYFORWARD_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { organization_configuration: { value: data } }),
  }
}
