import { Toastr } from 'components/global/Toastr'
import { formatCandidateFilter } from 'containers/authorised/ATS/templates/CandidateApplicationFilterTemplate/utils'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationInfoType } from 'redux/payroll/OffCyclePayrun/types'
import * as actions from './actions'

export type actionType = 'createApplicant' | 'getApplicant' | ''

type InitialStateType = {
  candidates_data: actions.CandidatesData[]
  candidateFilter: actions.CandidateFilter
  all_candidate_ids: number[]
  pagination?: PaginationInfoType
  fetching: boolean
  candidateFilterFetching: boolean
  action: actionType
  success: boolean
}

const initialState: InitialStateType = {
  candidates_data: [],
  all_candidate_ids: [],
  candidateFilter: {
    country: [],
    job_application_source: [{ id: 'all', name: 'all' }],
    rating: [{ id: 'all', name: 'all' }],
    current_title: [],
    nationality: [],
    screening_questions: [],
  },
  action: '',
  fetching: false,
  candidateFilterFetching: false,
  success: false,
  pagination: {
    current_page: 1,
    per_page: 1,
    next_page: 2,
    previous_page: 1,
    total_pages: 0,
    total_entries: 0,
  },
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.GET_CANDIDATE_FILTER_PENDING: {
      return {
        ...state,
        candidateFilterFetching: true,
      }
    }
    case actions.CREATE_JOB_APPLICANT_PENDING:
    case actions.GET_JOB_APPLICANTS_PENDING: {
      return {
        ...state,
        action: '',
        fetching: true,
      }
    }

    case actions.GET_CANDIDATE_FILTER_FULFILLED: {
      const {
        country,
        current_title,
        nationality,
        screening_questions,
        job_application_source,
        rating,
      } = action?.payload?.data?.data
      return {
        ...state,
        candidateFilter: {
          country,
          current_title: current_title.map((title: string, index: number) => {
            return {
              name: title,
              id: index + 1,
            }
          }),
          job_application_source: [
            { id: 'all', name: 'all' },
            ...Object.keys(job_application_source)?.map((source) => {
              return {
                id: source,
                name: job_application_source?.[source],
              }
            }),
          ],
          rating: [
            { id: 'all', name: 'all' },
            ...Object.keys(rating)?.map((source) => {
              return {
                id: source,
                name: rating?.[source],
              }
            }),
          ],
          nationality,
          screening_questions: formatCandidateFilter(screening_questions),
        },
        candidateFilterFetching: false,
        success: true,
      }
    }

    case actions.CREATE_JOB_APPLICANT_FULFILLED: {
      const message = action?.payload?.data?.data.message
      Toastr.success(message)
      return {
        ...state,
        action: 'createApplicant',
        fetching: false,
        success: true,
      }
    }
    case actions.GET_JOB_APPLICANTS_FULFILLED: {
      return {
        ...state,
        candidates_data:
          action?.payload?.data?.data?.pagination?.current_page === 1 ||
          action?.payload?.data?.data?.pagination === null
            ? action?.payload?.data?.data?.candidates_data
            : [...state.candidates_data, ...action?.payload?.data?.data?.candidates_data],
        pagination: action?.payload?.data?.data?.pagination,
        all_candidate_ids: action?.payload?.data?.data?.all_candidate_ids,
        fetching: false,
        success: true,
        action: 'getApplicant',
      }
    }

    case actions.CREATE_JOB_APPLICANT_REJECTED:
    case actions.GET_CANDIDATE_FILTER_REJECTED:
    case actions.GET_JOB_APPLICANTS_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        candidateFilterFetching: false,
        action: '',
      }
    }
    default:
      return state
  }
}
