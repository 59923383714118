import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import {
  CRVerificationBodyType,
  CommercialRegistrationOwnerType,
  CommercialRegistrationWithWalletType,
} from './reducer'
import * as actions from './actions'

type LoadCRsParamsType = {
  template?: 'public' | 'with_info_and_owners'
  payment_mode?: 'mudad'
  hierarchical?: boolean
}

export type JisrPayStateUpdateType = Partial<{
  selected_cr: CommercialRegistrationWithWalletType | null
  selected_owner: CommercialRegistrationOwnerType | null
  current_form_step: 'owners_list' | 'CRs_list' | 'registration_form'
  errorMsg: string | null
  open_register_modal: boolean
  open_otp_modal: boolean
  open_wallet_details_modal: boolean
  open_download_bank_file_modal: boolean
  download_bank_file_type: 'wps' | 'direct_transfer' | 'payrun' | 'salary_card' | null
}>

export function jisrPayStateUpdate(payload: JisrPayStateUpdateType): Action {
  return {
    type: actions.SELECT_CURRENT_ACTIVE_CR,
    payload,
  }
}

export function jisrPayCommercialRegistrationsLoad(params: LoadCRsParamsType = {}): Action {
  const url = `/${API_VERSION_TWO}/finance/commercial_registrations/`

  return {
    type: actions.JISR_PAY_COMMERCIAL_REGISTRATIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function verifyCommercialRegistration(payload: CRVerificationBodyType): Action {
  const url = `/${API_VERSION_TWO}/finance/commercial_registrations/verify/`

  return {
    type: actions.VERIFY_COMMERCIAL_REGISTRATIONS,
    payload: handleAPI(url, {}, 'POST', payload),
  }
}

export function sendOTPtoCROwner(params: { cr_id: number; owner_id: number }): Action {
  const { cr_id, owner_id } = params
  const url = `/${API_VERSION_TWO}/finance/commercial_registrations/${cr_id}/owners/${owner_id}/send_owner_otp`

  return {
    type: actions.SEND_OWNER_OTP,
    payload: handleAPI(url, params, 'POST'),
  }
}
export function verifyCROwnerOTP(params: { cr_id: number; owner_id: number }, data = {}): Action {
  const { cr_id, owner_id } = params
  const url = `/${API_VERSION_TWO}/finance/commercial_registrations/${cr_id}/owners/${owner_id}/verify_owner_otp`

  return {
    type: actions.VERIFY_OWNER_OTP,
    payload: handleAPI(url, params, 'POST', data),
  }
}

export function walletsLoad(): Action {
  const url = `/${API_VERSION_TWO}/finance/payment/wallets`

  return {
    type: actions.WALLET_LOAD,
    payload: handleAPI(url, {}, 'GET'),
  }
}
