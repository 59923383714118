export const OBJECTIVES_LOAD = 'OBJECTIVES_LOAD'
export const OBJECTIVES_LOAD_PENDING = 'OBJECTIVES_LOAD_PENDING'
export const OBJECTIVES_LOAD_FULFILLED = 'OBJECTIVES_LOAD_FULFILLED'
export const OBJECTIVES_LOAD_REJECTED = 'OBJECTIVES_LOAD_REJECTED'

export const OBJECTIVES_CREATE = 'OBJECTIVES_CREATE'
export const OBJECTIVES_CREATE_PENDING = 'OBJECTIVES_CREATE_PENDING'
export const OBJECTIVES_CREATE_FULFILLED = 'OBJECTIVES_CREATE_FULFILLED'
export const OBJECTIVES_CREATE_REJECTED = 'OBJECTIVES_CREATE_REJECTED'

export const OBJECTIVES_SHOW = 'OBJECTIVES_SHOW'
export const OBJECTIVES_SHOW_PENDING = 'OBJECTIVES_SHOW_PENDING'
export const OBJECTIVES_SHOW_FULFILLED = 'OBJECTIVES_SHOW_FULFILLED'
export const OBJECTIVES_SHOW_REJECTED = 'OBJECTIVES_SHOW_REJECTED'

export const OBJECTIVES_UPDATE = 'OBJECTIVES_UPDATE'
export const OBJECTIVES_UPDATE_PENDING = 'OBJECTIVES_UPDATE_PENDING'
export const OBJECTIVES_UPDATE_FULFILLED = 'OBJECTIVES_UPDATE_FULFILLED'
export const OBJECTIVES_UPDATE_REJECTED = 'OBJECTIVES_UPDATE_REJECTED'

export const OBJECTIVES_UPDATE_PROGRESS = 'OBJECTIVES_UPDATE_PROGRESS'
export const OBJECTIVES_UPDATE_PROGRESS_PENDING = 'OBJECTIVES_UPDATE_PROGRESS_PENDING'
export const OBJECTIVES_UPDATE_PROGRESS_FULFILLED = 'OBJECTIVES_UPDATE_PROGRESS_FULFILLED'
export const OBJECTIVES_UPDATE_PROGRESS_REJECTED = 'OBJECTIVES_UPDATE_PROGRESS_REJECTED'

export const OBJECTIVES_DELETE = 'OBJECTIVES_DELETE'
export const OBJECTIVES_DELETE_PENDING = 'OBJECTIVES_DELETE_PENDING'
export const OBJECTIVES_DELETE_FULFILLED = 'OBJECTIVES_DELETE_FULFILLED'
export const OBJECTIVES_DELETE_REJECTED = 'OBJECTIVES_DELETE_REJECTED'
