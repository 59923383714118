import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { PaginationTypes } from 'types/sharedResponse'
import { Action } from 'types/redux'
import * as actions from './actions'

type payrollTransactionsHistory = {
  amount: string
  created_by: {
    id: 7
    code: string
    name_i18n: string
    full_name_i18n: string
    avatar_thumb: string
    is_deleted: boolean
  }
  additional_data: {
    body: string
  }
  created_at: string
}

export type PayrollTransaction = {
  id: number
  created_at: string
  effective_date: string
  status: string
  status_i18n: string
  pay_type: string
  pay_type_i18n: string
  attachment: {
    data: string
    filename: string
  }[]
  amount: string
  payroll_transaction_type: {
    id: number
    name_i18n: string
  }
  payrun_id: number | null
  is_retro: boolean
  paid_at: string | Date
  is_addition: boolean
  description: string | null
  created_by: {
    id: number
    code: string
    name_i18n: string
    full_name_i18n: string
    avatar_thumb: string
    is_deleted: boolean
  }
  source_type: 'Loan' | null
  source_id: number | null
}

export type InitialStateType = {
  fetching: boolean
  loading: boolean
  payroll_transactions: PayrollTransaction[]
  payroll_transactions_history: payrollTransactionsHistory[]
  pagination: PaginationTypes
}

const initialState: InitialStateType = {
  fetching: false,
  loading: false,
  payroll_transactions: [],
  payroll_transactions_history: [],
  pagination: {} as PaginationTypes,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  let payroll_transactions = []
  let new_payroll_transactions = []
  switch (action.type) {
    case actions.PAYROLL_TRANSACTIONS_CREATE_PENDING:
    case actions.PAYROLL_TRANSACTIONS_UPDATE_PENDING:
    case actions.EXPORT_PAYROLL_TRANSACTIONS_PENDING:
    case actions.BULK_UPDATE_TRANSACTIONS_PENDING:
    case actions.PAYROLL_TRANSACTIONS_DELETE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.PAYROLL_TRANSACTIONS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYROLL_TRANSACTIONS_LOAD_FULFILLED: {
      const previous_page = action.payload?.data?.data?.pagination?.previous_page
      return {
        ...state,
        payroll_transactions_history: action.payload.data?.data,
        payroll_transactions: [
          ...new Set([
            ...(previous_page ? state?.payroll_transactions : []),
            ...(action.payload?.data?.data?.payroll_transactions || []),
          ]),
        ],
        pagination: action.payload.data?.data?.pagination,
        fetching: false,
      }
    }

    case actions.PAYROLL_TRANSACTIONS_CREATE_FULFILLED: {
      payroll_transactions = [...state.payroll_transactions]
      new_payroll_transactions = action.payload.data.data
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        payroll_transactions: [new_payroll_transactions, ...payroll_transactions],
        ...action.payload.data.data,
        loading: false,
      }
    }
    case actions.PAYROLL_TRANSACTIONS_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      payroll_transactions = [...state.payroll_transactions]
      new_payroll_transactions = payroll_transactions.map((record) =>
        record.id === action.payload.data.data.id
          ? { ...record, ...action.payload.data.data }
          : record,
      )

      return {
        ...state,
        payroll_transactions: new_payroll_transactions,
        loading: false,
      }
    }
    case actions.EXPORT_PAYROLL_TRANSACTIONS_FULFILLED: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.PAYROLL_TRANSACTIONS_DELETE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      const removedId = action.payload?.payroll_transactions?.id
      payroll_transactions = [...state.payroll_transactions]
      payroll_transactions = payroll_transactions.filter((item) => item.id !== removedId)
      new_payroll_transactions = payroll_transactions
      return {
        ...state,
        payroll_transactions: new_payroll_transactions,
        loading: false,
      }
    }

    case actions.PAYROLL_TRANSACTIONS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.EXPORT_PAYROLL_TRANSACTIONS_REJECTED:
    case actions.BULK_UPDATE_TRANSACTIONS_REJECTED:
    case actions.PAYROLL_TRANSACTIONS_CREATE_REJECTED:
    case actions.PAYROLL_TRANSACTIONS_UPDATE_REJECTED:
    case actions.PAYROLL_TRANSACTIONS_DELETE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    case actions.RESET_PAYROLL_TRANSACTIONS: {
      return initialState
    }

    case actions.BULK_UPDATE_TRANSACTIONS_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
