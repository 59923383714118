import { useEffect, useState } from 'react'
import I18n from 'translations/i18n'

import { ReactComponent as Brush } from './brush.svg'
import UnSelectColor from './unSelect-color.png'
import openColor from './openColor.png'
import Styles from './ColorPicker.module.css'

const colors = [
  { name: I18n.t('pink'), hex: '#F084AC' },
  { name: I18n.t('red'), hex: '#FB9CA2' },
  { name: I18n.t('orange'), hex: '#FFCB9D' },
  { name: I18n.t('yellow'), hex: '#FCE292' },
  { name: I18n.t('green'), hex: '#A6D98D' },
  { name: I18n.t('blue'), hex: '#B8DACF' },
  { name: I18n.t('purple'), hex: '#D9B1D2' },
  { name: I18n.t('brown'), hex: '#D6C3AB' },
] as const

export type ColorsHex = (typeof colors)[number]['hex']

type ColorPickerProps = {
  disabled?: boolean
  value?: ColorsHex
  onChange: (e: string) => void
}

const ColorPicker = ({ onChange, disabled, value }: ColorPickerProps): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [selected, setSelected] = useState<ColorsHex | ''>('')

  const handleValue = (): void => {
    onChange(selected)
  }

  const dropdownToggle = (): void => {
    if (disabled) return
    document.querySelector('body')?.click()

    // open dropdown
    setOpenDropdown(!openDropdown)

    // close dropdown listner
    const listner = (): void => {
      setOpenDropdown(false)
      document.removeEventListener('click', listner)
    }

    // close dropdown when click outside
    setTimeout(() => {
      document.addEventListener('click', listner)
    }, 100)
  }

  useEffect(() => {
    handleValue()
  }, [selected])

  useEffect(() => {
    setSelected(value || '')
  }, [value])

  return (
    <div className={Styles.colorPicker}>
      <div onClick={dropdownToggle}>
        {!openDropdown && !selected && (
          <img
            src={UnSelectColor}
            alt=""
          />
        )}
        {openDropdown && (
          <img
            src={openColor}
            alt=""
          />
        )}
        {!openDropdown && selected && (
          <div
            className={Styles.colorBrush}
            style={{ background: selected }}
          >
            <Brush />
          </div>
        )}
      </div>

      {openDropdown && (
        <div className={Styles.dropdown}>
          {colors.map((color) => (
            <div
              key={color.hex}
              onClick={(): void => setSelected(color.hex)}
            >
              <div style={{ backgroundColor: color.hex, flexShrink: 0 }} />
              <div className={color.hex === selected ? Styles.selectedColor : undefined}>
                {color.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ColorPicker
