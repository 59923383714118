import { Action } from 'types/redux'
import * as actions from './actions'

type UndoList = {
  undo_list: Array<{ id: string }>
}

export const initialState: UndoList = {
  undo_list: [],
}

export default function reducer(state = initialState, action: Action): UndoList {
  switch (action.type) {
    case actions.PUSH_UNDO:
      return {
        ...state,
        undo_list: [...state.undo_list, action.payload],
      }

    case actions.REMOVE_UNDO: {
      return {
        ...state,
        undo_list: state.undo_list.filter((e) => e.id !== action.payload.id),
      }
    }

    default:
      return state
  }
}
