import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  errMsg: null,
  succMsg: null,
  fetching: false,
  errors: [],
  mapping: {},
}

let changes = null
export default function payrollReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'BULK_IMPORT_PENDING':
    case 'GOSI_MAPPING_PENDING':
    case 'BUPA_INSURANCE_MAPPING_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }
    case 'BULK_IMPORT_REJECTED':
    case 'GOSI_MAPPING_REJECTED':
    case 'BUPA_INSURANCE_MAPPING_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }
    case 'BULK_IMPORT_FULFILLED':
      if (
        payload.data.data &&
        payload.data.data.failed &&
        payload.data.data.errors &&
        payload.data.data.errors.length
      ) {
        Toastr.error(payload.data.data.errors[0].error)
      } else if (payload.data && payload.data.message) {
        Toastr.success(payload.data.message)
      }
      changes = {
        ...payload.data.data,
        fetching: false,
        succMsg: payload.data.message,
      }

      return { ...state, ...changes }

    case 'GOSI_MAPPING_FULFILLED':
    case 'BUPA_INSURANCE_MAPPING_FULFILLED':
      changes = {
        mapping: payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'BULK_IMPORT_RESET':
      return { ...DEFAULT_STATE }
    default:
      return state
  }
}
