import { Action } from 'types/redux'

import * as actions from './actions'

type SalaryType = {
  fetching: boolean
  payslip_report: {
    total: {
      deductions: number
      earnings: number
      net_salary: number
    }
    month_year: string
  } | null
  salary_setting: [
    {
      amount: number
      icon_type: number
      name: string
    },
  ]
}

const initialState: SalaryType = {
  fetching: false,
  payslip_report: null,
  salary_setting: [
    {
      amount: 0,
      icon_type: 0,
      name: '',
    },
  ],
}

export default function payslipsReducer(state = initialState, action: Action): SalaryType {
  const { payload } = action
  switch (action.type) {
    case actions.PAYSLIPS_LOAD_PENDING:
    case actions.COMPENSATION_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYSLIPS_LOAD_REJECTED:
    case actions.COMPENSATION_LOAD_REJECTED:
      return {
        ...state,
        fetching: false,
      }

    case actions.PAYSLIPS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        payslip_report: payload.data.data.payslip_report,
      }
    case actions.COMPENSATION_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        salary_setting: payload.data?.data?.salary_setting,
      }
    default:
      return state
  }
}
