import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type TBenefitCreate = {
  category: string
  enabled?: boolean
  id: number
  is_optional?: boolean
  name: string
  name_ar: string
  name_i18n?: string
}

type TBenefitUpdate = Omit<TBenefitCreate, 'enabled' | 'is_optional' | 'name_i18n'>

type TBenefitDelete = Pick<TBenefitCreate, 'category'>

export function onboardingBenefitLoad(params: { category: string }): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types`

  return {
    type: actions.ALLOWANCES_LOAD,
    payload: handleAPI(url, { ...params, template: 'public' }, 'GET'),
  }
}

export function onboardingBenefitCreate(data: TBenefitCreate): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types`

  return {
    type: actions.ALLOWANCES_CREATE,
    payload: handleAPI(url, {}, 'POST', { payroll_transaction_type: data }),
  }
}

export function onboardingBenefitUpdate(data: TBenefitUpdate): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/${data.id}`

  return {
    type: actions.ALLOWANCES_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { payroll_transaction_type: data }),
  }
}

export function onboardingBenefitDelete(id: number, params: TBenefitDelete): Action {
  const url = `/${API_VERSION_TWO}/onboarding/payroll/payroll_transaction_types/${id}`

  return {
    type: actions.ALLOWANCES_DELETE,
    payload: handleAPI(url, params, 'DELETE'),
  }
}

export function benefitImportFromMainOrganization(): Action {
  const url = `/${API_VERSION_TWO}/onboarding/organization/clone`

  return {
    type: actions.ALLOWANCES_IMPORT,
    payload: handleAPI(url, { type: 'allowances' }, 'POST'),
  }
}
