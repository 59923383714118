import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { withLenses, lens } from '@dhmk/zustand-lens'
import {
  createEmployeesDataTableSlice,
  EmployeesDataTableSliceType,
} from './createEmployeesDataTableSlice'
import { createOnboardingEventState, OnboardingEventState } from './createOnboardingEventState'

export type NewOnboardingStoreType = {
  onboardingEventState: OnboardingEventState
  employeesDataTable: EmployeesDataTableSliceType
}

export const useNewOnboardingStore = create<NewOnboardingStoreType>()(
  devtools(
    withLenses(() => ({
      onboardingEventState: lens(createOnboardingEventState),
      employeesDataTable: lens(createEmployeesDataTableSlice),
    })),
    { name: 'NewOnboardingStore', enabled: process.env.NODE_ENV === 'development' },
  ),
)
