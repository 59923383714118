import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { returnBadgeList } from '../helper'
import FilterBox from '../FilterBox'
import { useFilters } from './context'
import staticRecords from '../staticRecords'

const StatusEmployee = (props) => {
  const { filter, handleFilter, setFilter } = useFilters()
  const [optionList, setOptionList] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [show, setShow] = useState([])

  const onHandleFilter = (__, id) => {
    if (['all'].includes(id)) {
      setFilter({
        ...filter,
        statuses: [],
        status_employee: [],
      })
    } else if ([1, 2].includes(id)) {
      handleFilter('statuses', id)
    } else {
      handleFilter('status_employee', id)
    }
  }

  const setFilterList = (data) => {
    const list = returnBadgeList(data)
    setOptionList(list)
  }

  const setListSelected = (list) => {
    const lists = isEmpty(list) ? ['all'] : list
    setSelectedIds(lists)
  }

  useEffect(() => {
    if (!props.statusOnly && !props.emplyeeStatusOnly) {
      setShow(props.filterKey === 'statuses')
      setFilterList([...staticRecords.status_employee, ...staticRecords.statuses])
    } else if (props.statusOnly) {
      setShow(true)
      setFilterList([{ name: 'all', id: 'all' }].concat(staticRecords.statuses))
    } else if (props.emplyeeStatusOnly) {
      setShow(true)
      const list = staticRecords.status_employee.filter((se) => se.id !== 'all')
      setFilterList(list)
    }
  }, [])

  useEffect(() => {
    if (!props.statusOnly && !props.emplyeeStatusOnly) {
      setListSelected([...filter.statuses, ...filter.status_employee])
    } else if (props.statusOnly) {
      setListSelected(filter.statuses)
    } else if (props.emplyeeStatusOnly) {
      setListSelected(filter.status_employee)
    }
  }, [filter.statuses, filter.status_employee])

  if (isEmpty(optionList) || !show) return <span />

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds}
      componentType="badges"
      valueKey="id"
      {...props}
    />
  )
}

StatusEmployee.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  statusOnly: PropTypes.bool,
  emplyeeStatusOnly: PropTypes.bool,
  list: PropTypes.arrayOf(Object),
}

export default StatusEmployee
