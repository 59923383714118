const Report = {
  // A
  after: 'بعد',
  adding_custom_report: 'إضافة تقرير مخصص',
  add_new_filter: 'إضافة مرشح جديد',
  add_customized_reports: 'تقرير مخصص جديد',
  assets_report: 'تقرير عهد الموظفين',
  area: 'المنطقة',
  attendance_logs: 'تقرير سجلات الحضور والغياب',
  attendance_details: 'تفاصيل الحضور والانصراف',
  attendance_fields: 'تفاصيل الحضور و الانصراف',
  attendance_report: 'تقرير حضور وانصراف الموظفين',
  attendance_reports: 'تقرير حضور وانصراف الموظفين',
  attendance_details_report: 'تقرير تفاصيل الحضور والانصراف',
  attendance_tracking_report: 'تقرير متابعة الحضور والانصراف',
  absence: 'تقرير الغياب',
  absences_report: 'تقرير الغياب',
  add_information: 'إضافة معلومات',
  add_delay: 'إضافة تأخير',
  ajeer_fees_payable: 'قيد رسوم العمالة المؤجرة',
  add_working_hours: 'إضافة ساعات العمل',
  add_break_time: 'إضافة وقت الراحة',
  add_extra_time: 'إضافة الوقت الإضافي',
  add_excused_time: 'إضافة التأخير المسموح',
  add_approved_overtime: 'إضافة الأوفر تايم',
  add_user_shift: 'اضافة دوام للموظف',
  add_employee_shift: 'اضافة دوام للموظف',
  attendance_summary: 'ملخص الحضور',
  attendance_summary_report: 'تقرير ملخص الحضور',
  add_manual_additions_deductions: ' إضافة او خصم يدوي',
  all_reports: ' <<  كل التقارير',
  All_reports: 'كل التقارير',
  attendance_reports_here: 'يمكنك التحقق من جميع تقارير الحضور هنا',
  add_company_details: 'اضف معلومات الشركة',
  add_edit_report_columns: 'إضافة و تعديل أعمدة التقرير',
  approved_overtime_report: 'تقرير الأوفر تايم المؤكد',
  attendance_summary_columns: 'أعمدة ملخص الحضور والانصراف',
  asset_details: 'تفاصيل العهد',
  assets_allocation_report: 'تقرير إسناد العُهد',
  allocated: 'في عهدة الموظف',
  accruals_journalVacation_end_of_service: 'قيد مخصصات الإجازة ومكافأة نهاية الخدمة',
  ajeer_fees_journal: 'قيود رسوم أجير',
  // B
  before: 'قبل',
  back: 'Back',
  bank_name: 'إسم البنك',
  base_details: 'التفاصيل الأساسية',
  basic_information: 'المعلومات الاساسية',
  business_trip_report: 'تقرير مهمة عمل',
  base_salary: 'الراتب الأساسي',
  biometric: 'البصمات',
  businiess_trip_days: 'ايام رحلات العمل',
  bulk_employees_attendance_details_report: 'تفاصيل حضور الموظفين',
  // C
  calendar_view: 'عرض التقويم',
  cancelled: 'ملغية',
  canceled: 'ملغية',
  cost_center: 'مركز التكلفة',
  column_selection: 'أختيار الحقول',
  custom_reports: 'التقارير المخصصة',
  choose_from_templates: 'أختر من القوالب (أختياري )',
  count: 'العدد',
  customize_columns: 'تخصيص الاعمدة',
  choose_an_employee: 'اختر موظف للمشاركة معه',
  completed_time: 'وقت الانتهاء',
  classification_columns: 'تصنيف الأعمدة',
  close: 'إغلاق',
  there_is_no_coordinates: 'لا توجد احداثيات لهذا السجل على الخارطة',
  cleared: 'تم الاسترجاع',
  // D
  delays: 'التأخير',
  delete_report: 'حذف التقرير',
  department: 'القسم',
  delays_report: 'تقرير التأخير',
  deductions: 'الخصومات',
  document_types: 'انواع المستندات',
  data_concern: 'البيانات المعنية',
  date_range: 'الفترة الزمنية',
  download_bulk_pdf: 'تحميل مجموعة قسائم الراتب PDF',
  download_bulk_pdf_att: 'تحميل مجموعة ملف PDF',
  download_report: 'تحميل التقرير',
  download: 'تحميل',
  download_xls: 'تحميل ملف إكسل',
  download_pdf: 'تحميل ملف PDF',
  download_started: 'بدء التحميل',
  document_id: 'رقم المستند',
  document_name: 'اسم المستند',
  document_owner: 'مالك المستند',
  download_payroll_report: 'تحميل ملف مسير الرواتب',
  download_post: 'تحميل وارسال',
  day_and_date: 'اليوم والتاريخ',
  data_is_too_large: 'البيانات الُمتطلب عرضها كبيرة لحد ما',
  // E
  editing_custom_report: 'تعديل التقرير المخصص',
  employee_selection: 'أختيار الموظفين',
  everyone: 'الكل',
  end_of_service_report: 'تقرير نهاية الخدمة',
  eos: 'تقرير نهاية الخدمة',
  export_account_system: 'تصدير الى برنامج محاسبي',
  export_netsuite: 'تصدير netsuite',
  employee_without_cr: 'الموظفين بدون سجلات تجارية',
  export_xero: 'تصدير xero',
  export_quickbooks: 'تصدير quickbboks',
  employees_reports: 'تقرير قائمة الموظفين',
  employee_master: 'قائمة الموظفين',
  employee_master_report: 'تقرير قائمة الموظفين',
  employee_records: 'تقرير تفاصيل ملف موظف',
  expiring_documents: 'تقرير انتهاء الملفات',
  expiring_documents_report: 'تقرير انتهاء الملفات',
  employee_name_ar: 'اسم الموظف (عربي)',
  employee_name_en: 'اسم الموظف (إنجليزي)',
  employee_records_report: 'تقرير تفاصيل ملف موظف',
  earnings: 'الراتب',
  excuse: 'إستئذان',
  expired: 'منتهي',
  expiry_status: 'حالة المستند',
  export_sheet: 'تصدير التقرير',
  export_as_pdf: 'تصدير{{title}} كـ PDF',
  export_as_excel: 'تصدير{{title}} كـ EXCEL',
  export_remarks_as_pdf: 'تصدير الملاحظات كـ PDF',
  export_remarks_as_excel: 'تصدير الملاحظات كـ EXCEL',
  employee_code: 'رقم الموظف',
  employee_info: 'معلومات الموظف',
  employee_loan_report: 'تقرير سلف الموظفين',
  employees_reports_here: 'يمكنك التحقق من جميع تقارير الموظفين هنا',
  email_sent_successfully: 'تم ارسال الايميل بنجاح',
  employee_attendance_details_report: 'تقرير تفاصيل حضور وانصراف الموظف',
  excuse_early_departure: 'المغادرة المبكره باذن',
  excuse_late_arrival: 'وصول متأخر بعذر',
  employees_performance: 'تقرير أداء الموظفين',
  employee_review_cycles_report: 'تقرير دورات اهداف الموظفين',
  event_type: 'إجراء',

  // Femployee_attendance_report
  financial_details: 'التفاصيل المالية',
  finalizing_report: 'إستكمال متطلبات إنشاء التقرير',
  final_settlement_report: 'تقرير إنهاء عقد موظف',
  finance_reports: 'تقارير مالية',
  finance_report: 'تقارير مالية',
  finance_payroll: 'تقرير مسير الرواتب للمالية',
  finance_reports_here: 'يمكنك التحقق من جميع التقارير المالية هنا',
  from_to: 'من - الى',
  file_status: 'حالة الملف',
  full_day_excuses: 'عذر يوم كامل',
  ajeer_company: 'شركة اجير',
  header_family_details_report: 'تقرير تفاصيل أفراد العائلة للموظفين',
  family_details_report: 'تقرير بيانات العائلة للموظفين',

  // G
  greater_than: 'أكثر من أو يساوى',
  gosi: 'التأمينات الإجتماعية',
  gosi_report: 'تقرير التأمينات الإجتماعية',
  group_by: 'مجمع على أساس',
  generating: 'جاري الإنشاء',
  generate: 'إنشاء',
  generating_report: 'جاري إنشاء التقرير',
  generate_report: 'إنشاء التقرير',
  generater_payroll_report: 'إنشاء تقرير مسير الرواتب',
  generater_eos_report: 'إنشاء تقرير نهاية الخدمة',
  generater_ticket_provision_report: 'إنشاء تقرير مخصصات التذاكر',
  generater_attendance_details_report: 'إنشاء تقرير تفاصيل الحضور والانصراف',
  generater_outsourcing_employees_report: 'إنشاء تقرير موظفو شركات العمالة',
  generater_outsourcing_employees_report_description:
    'يعرض هذا التقرير العمليات المالية خارج الرواتب للموظفين من نوع اجير',
  generater_employee_master_report: 'إنشاء تقرير قائمة الموظفين',
  generate_gosi_report: 'إنشاء تقرير التأمينات الاجتماعية',
  generated_on: 'تمت معالجتة في ',
  generated_by: 'تم إنشاء التقرير  بواسطة',
  generated_by_custom_reports: 'بواسطة',
  generate_updated_report: 'تحديث التقرير',
  generate_employee_attendance_details_report: 'إنشاء تقرير الحضور والانصراف بالتفصيل للموظف',
  geofencing: 'النطاق الجغرافي',
  geofencing_method: 'النطاق الجغرافي',
  GOSI_journal: 'قيد مستحقات التأمينات الإجتماعية',
  generate_revenue_report: 'إنشاء تقرير العوائد',
  generate_revenue_report_details:
    'يعرض هذا التقرير إجمالي العوائد لكل موظف, حسب الطلبات التي قام بإنهاءها كل موظف في حسابكم لدى فودكس',
  generate_employees_performance_report: 'إنشاء تقرير أداء الموظفين',

  // H
  head_count: 'التوظيف',
  headcount: 'التوظيف',
  headcount_report: 'تقرير التوظيف',
  // I
  info_change_request: 'طلب تغيير المعلومات',
  inactive: 'غير نشط',
  in_progress: 'قيد المعالجة',
  imported_by: 'بواسطة',
  iqama: 'الإقامة',
  id_constants: 'الهوية',
  // J
  joining_date_from: 'تاريخ الإلتحاق من',
  joining_date_to: 'تاريخ الإلتحاق إلى',
  from_offboarded: 'تاريخ إنهاء الخدمة من',
  download_journal_file: 'تحميل ملف القيد المحاسبي',
  post_journal_to_netsuite: 'ارسال القيد المحاسبي الى نت سويت',
  journal_sheet: 'ملف القيود',
  post_journal_to_quickbooks: 'ارسال القيد المحاسبي الى كويك بوكس',
  post_journal_to_xero: 'ارسال القيد المحاسبي الى زيرو',
  post_journal_to_qoyod: 'ارسال القيد المحاسبي الى قيود',
  post_journal_to_oracle_fusion: 'ارسال القيد المحاسبي الى اوراكل',
  post_journal_to_microsoft: 'ارسال القيد المحاسبي الى مايكروسوفت',
  post_journal_to_odoo: 'ارسال القيد المحاسبي الى اودو',
  post_journal_to_finops: 'ارسال القيد المحاسبي الى MS Dynamics Finance & Operation',
  performance_reports: 'تقارير الاداء',
  post_journal_to_zoho_books: 'ارسال القيد المحاسبي الى Zoho Books',
  post_journal_to_sap: 'ارسال القيد المحاسبي الى Sap Business One',
  // K
  // L
  loans_report: 'تقرير السلف',
  location: 'الموقع',
  leave_details: 'تفاصيل الإجازات',
  less_than: 'أقل من أو يساوى',
  leaves_reports: 'تقارير الإجازات',
  leaves_report: 'تقرير الإجازات',
  leave_balance_report: 'تقرير رصيد الإجازات',
  loan: 'سلفة',
  letter: 'خطاب',
  leaving_early: 'المغادرة مبكراً',
  leaves_reports_here: 'يمكنك التحقق من جميع تقارير الاجازات هنا',
  last_month: 'الشهر السابق',
  last_quarter: 'الربع السابق',
  last_year: 'السنة السابقة',
  log_day: 'يوم السجل',
  log_time: 'وقت السجل',
  log_location: 'موقع السجل',
  // M
  make_precise_selection: 'إجراءاختيار دقيق بإضافة المرشحات',
  market_place: 'المتجر',
  // N
  net_eos: 'صافي نهاية الخدمة',
  net_salary: 'صافي الراتب',
  never_generated: 'لم تتم معالجتة',
  not_expired: 'غير منتهي الصلاحية',
  netsuit: 'نت سويت',
  no_ticket_plicy_msg: 'يجب عليك تعريف سياسة التذاكر',
  no_data_founded: 'لاتوجد بيانات',
  no_records: 'ﻻتوجد سجلات',
  // O
  organization_details: ' المعلومات الوظيفية',
  offboarded_to: 'تاريخ إنهاء الخدمة إلى',
  out_of_payroll_transactions: 'عمليات خارج الرواتب',
  out_of_payroll_types: 'نوع عملية خارج الرواتب',
  offline_journals: 'القيود المحاسبية بشكل غير مباشر',
  out_of_payroll_journals: 'القيود المحاسبية',
  outsourcing_employees: 'موظفو شركات العمالة',
  outsourcing_employees_low_tiers: 'العمالة الخارجية',
  outsourcing_employees_report: 'تقرير موظفو شركات العمالة',
  out_of_payroll_report: ' تقرير عمليات خارج الرواتب',
  off_days: 'ايام راحة',
  off_cycle_payruns: 'تقرير المخالصات',
  other_reports: 'OTHER REPORTS :AR',
  journal: 'القيد المحاسبي',
  // P
  passed: 'أجتاز فترة التجربة',
  parent_department: 'القطاع',
  personal_details: 'المعلومات الشخصية',
  payment_type: 'طريقة الدفع',
  payroll: 'تقرير مسير الرواتب',
  payroll_report: 'تفاصيل مسير الرواتب',
  payslip: 'تقرير راتب موظف',
  pay_slip: 'قسيمة الراتب للموظف',
  payslip_report: 'تفاصيل راتب موظف',
  payslip_report_conf: 'تفاصيل راتب الموظف سيتم ارسالها بعد',
  payroll_group: 'تقرير مقسم لمسير الرواتب',
  payroll_group_report: 'تقرير مقسم لمسير الرواتب',
  payroll_is_not_selected: 'لم يتم اختيار شهر مسير الرواتب',
  please_select_at_least_one_employee: 'يرجى اختيار موظف واحد على الأقل',
  please_select_pdf_or_excel: 'يرجى اختيار PDF او EXCEL',
  provision_reports: 'تقارير المخصصات',
  payrun_status: 'حالة المخالصة',
  please_go_to: 'الرجاء الذهاب الى :',
  payment_employees_without_cr: 'الموظفين الغير مرتبطين بسجل تجاري',
  passport: 'جواز السفر',
  payroll_journal: 'القيود المحاسبية',
  post_to: 'ارسال الى',
  payroll_journals: 'قيود مسير الرواتب',
  payroll_journals_file_name: 'قيود-مسير-الرواتب',

  // Q
  quickbooks: 'كويك بوكس',
  // R
  report: 'تقرير',
  report_summary: 'ملخص التقرير',
  report_name_in_english: 'أسم التقرير باللغة الانجليزية',
  report_name_in_arabic: 'أسم التقرير باللغة العربية (إختياري)',
  report_description: 'وصف التقرير (إختياري)',
  report_basic_information: 'معلومات التقرير الاساسية',
  reports_family_details: 'تفاصيل أفراد العائلة',
  reports: 'تقارير',
  requests: 'الطلبات',
  request_report: 'تقرير الطلبات',
  request_status: 'حالة الطلب',
  request_type: 'نوع الطلب',
  rejected: 'مرفوض',
  presence: 'الحضور',
  request_info: 'معلومات الطلب',
  report_created_on: 'تم إنشاء التقرير في',
  report_provides_overview: 'يعرض هذا التقرير تفاصيل مسير الرواتب لفترة زمنية محددة',
  reopen_payroll_report_note:
    'هذا الأمر سيلغي تأكيد مسير الرواتب لهذا الشهر, وسيتم حذف تفاصيل الموافقات على المسير, وسيتم إلغاء تأكيد المسير, هل انت متأكد من الإستمرار ؟ ',
  reporting_method: 'طريقة الإبلاغ',
  record_location: 'موقع التسجيل',
  regenerated_by: 'تم تحديثة بواسطة',
  revenue_report: 'تقرير العوائد',
  payruns_remarks_report: 'ملاحظات دفع الرواتب',
  // S
  status_export_history: 'الحالة',
  select_user: 'اختر المستخدم',
  save_this_as_template: 'حفظ هذا التقرير كقالب',
  search_employee: 'ابحث عن موظف',
  select_employee: 'اختر الموظف',
  segment_by: 'ترتيب حسب',
  sponsor: 'التأمينات الاجتماعية',
  show_report: 'رؤية التقرير',
  suggest_new_column: 'أقتراح تفاصيل او مرشحات جديدة',
  share_settings: 'اعدادات المشاركة',
  share_with: 'مشاركة مع',
  send_to_someone: 'مشاركة مع موظف',
  sendـtoـmyـemail: 'إرسال الى بريدي الإلكتروني',
  shift_working_hours: 'ساعات العمل للدوام',
  select_employee_by_type: 'أختر الموظفين بالنوع',
  // T
  the_name_already_defined: 'تم تعريف الاسم مسبقا',
  time_sheet: 'الحضور والانصراف',
  timesheet_report: 'تقرير الحضور والانصراف',
  total_deductions: 'إجمالي الخصومات',
  total_earnings: 'إجمالي الراتب',
  total_salaries: 'إجمالي الرواتب',
  total_additions: 'إجمالي الإضافات',
  total_net_salaries_low_tiers: 'صافي الرواتب',
  this_month: 'هذا الشهر',
  this_quarter: 'هذا الربع',
  this_year: 'هذه السنة',
  total_liabilities: 'إجمالي الإستحقاق',
  this_report_end_of_service: 'يعرض هذا التقرير تفاصيل نهاية الخدمة لفترة زمنية محددة',
  this_report_ticket_provision: 'يعرض هذا التقرير تفاصيل مخصصات التذاكر لفترة زمنية محددة',
  ticket_provision_report: 'تقرير مخصصات التذاكر',
  this_report_attendance_details:
    'يعرض هذا التقرير تفاصيل الحضور والانصراف للموظفين لفترة زمنية محددة',
  total_cost: 'إجمالي التكلفة',
  total_fees: 'إجمالي الرسوم',
  total_employees: 'عدد الموظفين',
  total_per_diem_hint: 'سيتم حساب إجمالي الإقامة اليومية {{value}} كما هو معرف في إعدادات السياسة.',
  this_report_shows_the_attendance_details: 'يعرض هذا التقرير تفاصيل الحضور للموظف ',
  total_logs: 'إجمالي البصمات',
  there_no_offboarded_employees_so_far: 'لا يوجد موظفين تم إنهاء خدماتهم',
  the_report_generating_too_large: 'التقرير المُتطلب عرضه يحتوى على الكثير من البيانات.',
  payroll_transaction_status: 'حالة العملية',
  // U
  urgent_loan: 'سلفة عاجلة',
  uploaded_time: 'وقت الرفع',
  under_probation: 'تحت التجربة ',
  // V
  advance_vacation: 'راتب الإجازة مقدما',
  // W
  which_period_of_time: 'اي فترة زمنية؟',
  which_view: 'أي عرض ؟',
  // X
  // Y
  you_can_change_the_order: 'بأمكانك تغيير ترتيب الاعمدة',
  you_can_export_to_view: 'لتجربة عرض بيانات أفضل يمكنك تصدير ملف او تعديل التقرير المطلوب.',
  // Z
  generater_attendance_summary_report: 'إنشاء  ملخص تقرير الحضور والانصراف',
  this_report_shows_the_attendance_status:
    'يعرض هذا التقرير حالة الحضور لكل موظف في نطاق بيانات معين',
  which_employee: 'أي موظف',

  generater_approved_overtime_report: 'إنشاء تقرير الموافقه على الإضافي المؤكد',
  this_report_approved_overtime: 'يعرض هذا التقرير العمل الإضافي المؤكد لكل موظف لنطاق بيانات معين',
  generate_attendance_logs_report: 'إنشاء تقرير سجلات الحضور والغياب',
  generate_attendance_tracking_report: 'إنشاء تقرير متابعة الحضور والانصراف',
  generate_out_of_payroll_report: 'إنشاء تقرير عمليات خارج الرواتب  ',
  this_report_shows_the_attendance_logs: 'يعرض هذا التقرير سجلات الحضور لكل موظف لنطاق بيانات معين',
  this_report_shows_the_attendance_tracking:
    'يعرض هذا التقرير متابعة الحضور والآنصراف لكل موظف لنطاق بيانات معين',
  generater_attendance_report: 'إنشاء تقرير الحضور والانصراف',
  this_report_shows_the_total_attendance_calculations:
    'يعرض هذا التقرير إجمالي حسابات الحضور لكل موظف لنطاق بيانات معين',
  this_report_shows_the_out_of_paroll_transaction:
    'يعرض هذا التقرير عمليات خارج الرواتب لكل موظف لنطاق بيانات معين',
  this_report_shows_the_off_cycle_payrun_transaction:
    'يعرض لك هذا التقرير مخالصات الإجازة ونهاية الخدمة لكل موظف لنطاق بيانات معين',
}

export default Report
