import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { useHistory } from 'react-router-dom'

import InviteEmployeesStep from './steps/InviteEmployees'
import AdminUser from './steps/AdminUser'
import Sidebar from '../components/Sidebar'
import VerticalStepper from '../components/VerticalStepper'
import SharedStep from '../components/SharedStep'
import { OnboardingContext } from '../context'
import { customArray, handleUrl, isPreviousStepsComplete } from '../helper'
import { microActivateSteps } from '../microHelper'

export const steps = [
  {
    stepNo: 0,
    title: 'invite_employees',
    component: SharedStep,
    sidebar: null,
  },
  {
    stepNo: 1,
    title: 'admin_users',
    component: AdminUser,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('add_admins_onb'),
            description: I18n.t('Make_sure_that_have_admin'),
          },
        ]}
      />
    ),
  },
  {
    stepNo: 2,
    title: 'invite_admins',
    component: InviteEmployeesStep,
    sidebar: (
      <Sidebar
        infoSet={[
          {
            title: I18n.t('invite_admins'),
            description: I18n.t('add_only_the_admins', {
              app_name: I18n.t('jisr_translation_name'),
            }),
          },
        ]}
      />
    ),
  },
]

const microSteps = [
  {
    stepNo: 0,
    title: 'invite_employees',
    component: SharedStep,
    sidebar: null,
  },
  ...microActivateSteps,
]

const InviteEmployees = ({ initStep }: { initStep: number }): JSX.Element => {
  const history = useHistory()
  const { setSidebar, setInvCurrentStep, org } = useContext(OnboardingContext)
  const { invite_employees } = useSelector(
    ({ organization }) => organization?.organization?.metadata,
  )

  const { require_pincode } = useSelector(({ organization }) => organization?.organization)
  const { organization: orgInfo } = useSelector(({ organization }) => organization)

  const key = require_pincode ? 'microActivateSteps' : 'InviteSteps'
  const variantSteps = require_pincode ? microSteps : steps

  const [currentStep, setCurrentStep] = useState(initStep)
  const { component: CurrentComponent, sidebar } = variantSteps[currentStep]

  const customInviteSteps = customArray(invite_employees, key)?.filter((i) => i.stepNo)

  useEffect(() => {
    setSidebar(sidebar)
  }, [sidebar])

  const goNextStep = (): void => {
    const toStep = currentStep + 1
    const path = handleUrl(toStep, key, orgInfo.require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`invite_employees_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setInvCurrentStep(toStep)
  }

  const goPreviousStep = (): void => {
    const toStep = currentStep - 1
    const path = handleUrl(toStep, key, orgInfo.require_pincode)
    history.push(`?${path}`)
    localStorage.setItem(`invite_employees_step_${org.id}`, toStep.toString())
    setCurrentStep(toStep)
    setInvCurrentStep(toStep)
  }

  useEffect(() => {
    const onboardingStep = Number(localStorage.getItem(`invite_employees_step_${org.id}`)) || 0
    if (initStep === onboardingStep) {
      setCurrentStep(initStep)
      setInvCurrentStep(initStep)
    } else {
      setCurrentStep(onboardingStep)
      setInvCurrentStep(onboardingStep)
    }
  }, [localStorage.getItem(`invite_employees_step_${org.id}`)])

  const goToStep = (toStep: number): void => {
    const isComplete = isPreviousStepsComplete(toStep, customInviteSteps)

    if (isComplete) {
      const path = handleUrl(toStep, key, orgInfo.require_pincode)
      history.push(`?${path}`)
      localStorage.setItem(`invite_employees_step_${org.id}`, toStep.toString())
      setCurrentStep(toStep)
      setInvCurrentStep(toStep)
    }
  }

  return (
    <VerticalStepper
      steps={customInviteSteps}
      currentStep={currentStep}
      setCurrentStep={goToStep}
    >
      {CurrentComponent && (
        <CurrentComponent
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          nextStep={steps[currentStep + 1]}
          previousStep={steps[currentStep - 1]}
        />
      )}
    </VerticalStepper>
  )
}

InviteEmployees.propTypes = {
  initStep: PropTypes.number,
}

export default InviteEmployees
