import { FeaturedIcon, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'

export const OnboardingFormSuccess = () => {
  return (
    <div className="flex justify-center items-center h-screen fixed top-10 left-0 right-0 bottom-10">
      <div className="flex justify-center items-center flex-col gap-4">
        <FeaturedIcon
          iconName="check-circle-filled"
          variant="tinted"
          colorType="success"
          size="lg"
        />
        <Typography
          text={i18n.t('communication.info_submitted')}
          variant="title-1"
        />
      </div>
    </div>
  )
}
