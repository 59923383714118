import { Action } from 'types/redux'
import { API_VERSION_TWO, handleAPI } from 'utils/apiUtils'
import * as actions from './actions'

export function employeeNotificationsSettingLoad(
  employee_id: number,
  params: Record<string, unknown> = {},
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/configurations/web_push_notification`

  return {
    type: actions.GET_EMPLOYEE_NOTIFICATIONS_SETTING,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function employeeNotificationsSettingUpdate(
  employee_id: number,
  data: Record<string, string>,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/configurations/web_push_notification`

  return {
    type: actions.UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING,
    payload: handleAPI(url, {}, 'PUT', {
      employee_configuration: {
        options: data,
      },
    }),
  }
}
