import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'
import { search, filter, isExisting } from './helper'

const initialState = {
  fetching: false,
  selected: [],
  un_selected: [],
  archive_unselected: [],
  archive_selected: [],
  employees: [],
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.ELIGIBLE_PEOPLE_LOAD_PENDING:
    case actions.FILTER_ELIGIBLE_PEOPLE_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CLEAR_ELIGIBLE_DATA:
      return {
        ...state,
        selected: [],
        archive_selected: [],
      }

    case actions.SET_SELECTED_ALL_USERS:
      return {
        ...state,
        selected: [...state.un_selected, ...state.selected],
        archive_selected: [...state.archive_unselected, ...state.archive_selected],
        un_selected: [],
        archive_unselected: [],
      }

    case actions.SET_UNSELECTED_ALL_USERS:
      return {
        ...state,
        un_selected: [...state.selected, ...state.un_selected],
        archive_unselected: [...state.archive_selected, ...state.archive_unselected],
        selected: [],
        archive_selected: [],
      }

    case actions.SELECT_USER:
      return {
        ...state,
        un_selected: filter(payload.employee.id, state.un_selected),
        archive_unselected: filter(payload.employee.id, state.archive_unselected),
        selected: [payload.employee, ...state.selected],
        archive_selected: [payload.employee, ...state.archive_selected],
      }

    case actions.SET_SELECTED:
      return {
        ...state,
        selected: payload.employees,
        archive_selected: payload.employees,
      }

    case actions.UNSELECT_USER:
      return {
        ...state,
        selected: filter(payload.employee.id, state.selected),
        archive_selected: filter(payload.employee.id, state.archive_selected),
        un_selected: [payload.employee, ...state.un_selected],
        archive_unselected: [payload.employee, ...state.archive_unselected],
      }

    case actions.ELIGIBLE_PEOPLE_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        employees: payload.data.data.employees,
        un_selected: payload.data.data.employees,
        archive_unselected: payload.data.data.employees,
        fetching: false,
      }

    case actions.FILTER_ELIGIBLE_PEOPLE_LOAD_FULFILLED:
      return {
        ...state,
        un_selected: isExisting(state.selected, payload.data.data.employees),
        archive_unselected: isExisting(state.selected, payload.data.data.employees),
        fetching: false,
      }

    case actions.SEARCH_UN_SELECTED:
      return {
        ...state,
        un_selected: search(payload.search, state.archive_unselected),
      }

    case actions.SEARCH_SELECTED:
      return {
        ...state,
        selected: search(payload.search, state.archive_selected),
      }

    case actions.ELIGIBLE_PEOPLE_LOAD_REJECTED:
    case actions.FILTER_ELIGIBLE_PEOPLE_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
