import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export function indexCompanies(params = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/outsourcing_companies`

  return {
    type: actions.ONBOARDING_OUTSOURCING_COMPANIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function showCompanies(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/outsourcing_companies/${id}`

  return {
    type: actions.ONBOARDING_OUTSOURCING_COMPANIES_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function createNewCompanies(body = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/outsourcing_companies`

  return {
    type: actions.ONBOARDING_OUTSOURCING_COMPANIES_POST,
    payload: handleAPI(url, {}, 'POST', { outsourcing_company: body }),
  }
}

export function updateCompanies(id: number, body = {}): Action {
  const url = `/${API_VERSION_TWO}/onboarding/outsourcing_companies/${id}`

  return {
    type: actions.ONBOARDING_OUTSOURCING_COMPANIES_PUT,
    payload: handleAPI(url, {}, 'PUT', body),
  }
}

export function destroyCompanies(id: number): Action {
  const url = `/${API_VERSION_TWO}/onboarding/outsourcing_companies/${id}`

  return {
    type: actions.ONBOARDING_OUTSOURCING_COMPANIES_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}
