import { BadgeProps, Level2NavItem, NavDataType } from '@jisr-hr/ds'
import usePermissions from 'components/Navigation/usePermissions'
import {
  showATSStatistics,
  showAssets,
  showAttendanceLeaves,
  showFinancePayroll,
  showWorkforce,
} from 'containers/authorised/Analysis/helper/permission'
import { serverEnvironment } from 'env'
import { OnboardingTier } from 'containers/authorised/NewOnboarding/helper'
import { Employee } from 'redux/authUser/types'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { menuList } from './helper'

type ModuleType = {
  module: string
  value: number
}

export type menuType = {
  label: string
  badge?: BadgeProps
  icon?: string
  groupTitle?: string
  path?: string
  testId?: string
  group?: {
    title: string
    path: string
    testId?: string
    menu: Level2NavItem[]
    hideDivider?: boolean
  }
  nextDivider?: boolean
  event_name?: string
}

export const useOnlyOnboardingMappingNavigation = (): NavDataType => {
  const settingPermissions = usePermissions()
  const employee = useSelector((s) => s.auth.employee)
  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER
  const onboardingPath = isHighTier ? '/new_onboarding/highTier' : '/new_onboarding/home/org_info'
  const isEmployeeDataCompleted = employee?.organization.onboarding_info?.onboarding_steps_completed

  const hasOnboardingPermission = (name: string): boolean => {
    const module = employee?.permissions?.find(
      (permission) => permission.module === name,
    ) as ModuleType
    return module?.value > 0
  }

  const navigationData = {
    ...(hasOnboardingPermission('NewOnboarding') && {
      onboarding: {
        tooltip: i18n.t('label.onboarding'),
        icon: 'activity',
        path: onboardingPath,
        tab: ['new_onboarding'],
        testId: 'onboarding',
        event_name: 'new_onboarding',
        menu: [],
      },
    }),

    ...(isEmployeeDataCompleted && {
      employee: {
        tooltip: i18n.t('employees'),
        icon: 'user-01',
        path: '/employees',
        testId: 'employee',
        tab: [
          'employees',
          'onboarding_employees',
          'organization_chart',
          'muqeem_services',
          'hired_candidates',
          'assets',
        ], // to handle the active state of the tab, because we have different main routes in the same tab
        menu: [
          hasOnboardingPermission('Employee profile') && {
            label: i18n.t('view_employees'),
            path: '/employees',
            icon: 'users-01',
            testId: 'view_employees',
            event_name: 'EmployeeList_Viewed',
          },
          hasOnboardingPermission('Onboarding Employees') && {
            label: i18n.t('onboarding'),
            path: '/onboarding_employees',
            icon: 'users-right',
            testId: 'onboarding_employees',
            // event_name: 'onboarding_employees',
          },
          settingPermissions.isMultiSubSupported &&
            settingPermissions.view_organization_chart?.[0] === 'all' && {
              label: i18n.t('label.organization_chart'),
              path: '/organization_chart',
              icon: 'dataflow-04',
            },
          hasOnboardingPermission('Muqeem Services') && {
            label: i18n.t('muqeem_services'),
            path: '/muqeem_services',
            icon: 'file-attachment-04',
            nextDivider: true,
            testId: 'muqeem_services',
            event_name: 'ServicesPage_Viewed',
          },
          hasOnboardingPermission('Hired Candidates') && {
            label: i18n.t('hired_candidates'),
            path: '/hired_candidates',
            icon: 'users-plus',
            testId: 'hired_candidates',
          },
          hasOnboardingPermission('Assets') && {
            label: i18n.t('assets'),
            path: '/assets',
            icon: 'laptop-01',
            testId: 'assets',
            event_name: 'AssetsList_Viewed',
          },
        ].filter(Boolean),
      },
    }),
  }

  return navigationData as unknown as NavDataType
}

export const useDisabledMappingNavigation = (): NavDataType => {
  const employee = useSelector((s) => s.auth.employee)

  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER
  const onboardingPath = isHighTier ? '/new_onboarding/highTier' : '/new_onboarding/home/org_info'

  return {
    general: {
      disabled: true,
      tooltip: i18n.t('home'),
      icon: 'home-02',
      path: '/',
      tab: ['/'],
      testId: 'home',
      event_name: 'Dashboard_Viewed',
      menu: [],
    },
    requests: {
      disabled: true,
      tooltip: i18n.t('requests'),
      icon: 'inbox-01',
      path: '/requests',
      tab: ['requests'],
      testId: 'requests',
      menu: [],
    },
    employee: {
      disabled: true,
      tooltip: i18n.t('employees'),
      icon: 'user-01',
      path: '/employees',
      testId: 'employee',
      tab: ['employees', 'onboarding_employees', 'muqeem_services', 'hired_candidates', 'assets'], // to handle the active state of the tab, because we have different main routes in the same tab
      menu: [],
    },
    attendance: {
      disabled: true,
      tooltip: i18n.t('attendance_leaves'),
      icon: 'clock',
      path: '/attendance',
      tab: ['attendance', 'leave_management', 'scheduler', 'dashboard'],
      testId: 'time_attendance',
      menu: [],
    },
    payroll: {
      disabled: true,
      tooltip: i18n.t('payroll'),
      icon: 'coins-stacked-01',
      path: '/payroll',
      testId: 'payroll',
      tab: ['payroll', 'off_cycle', 'payment_processing', 'gosi_sync'],
      menu: [],
    },
    performance: {
      disabled: true,
      tooltip: i18n.t('performance'),
      icon: 'rocket-01',
      path: '/performance',
      tab: ['performance'],
      testId: 'performance',
      menu: [],
    },
    statistics: {
      disabled: true,
      tooltip: i18n.t('reports_statistics'),
      icon: 'bar-chart-01',
      tab: ['data_analysis', 'reports'],
      dot: false,
      testId: 'reports',
      menu: [],
      path: '',
    },
    onboarding: {
      tooltip: i18n.t('label.onboarding'),
      icon: 'activity',
      path: onboardingPath,
      tab: ['new_onboarding'],
      testId: 'onboarding',
      event_name: 'new_onboarding',
      menu: [],
    },
  }
}

export const useMappingNavigation = (): NavDataType => {
  const { showLumofy } = useSelector((s) => s.enablerDataReducer)
  const { employee, company } = useSelector(({ auth }) => auth)
  const payrunActivationStatusState = useSelector(({ payrun }) => payrun.payrun_activation_status)
  const onboardingSteps = useSelector((s) => s.pmOnboarding.onboardingSteps)
  const pmOnboadringNotCompleted = !onboardingSteps?.length || !onboardingSteps[0].is_completed
  const invitationsStatistics = useSelector((s) => s.invitationsTracking?.invitationsStatistics)
  const { is_employees_invited } = invitationsStatistics
  const settingPermissions = usePermissions()
  const settingNavList = menuList(settingPermissions)
  const { permission_scopes, permission_details } = employee!
  const { permissions } = employee as Employee
  const activatedModules = company?.activated_modules ?? []
  const isHybridPhase =
    employee?.organization.status === 'live' &&
    employee?.organization?.new_onboarding_enabled &&
    activatedModules.length < 3
  const isPayrunCompleted = payrunActivationStatusState?.status === 'completed'

  const isLeavesActivated = isHybridPhase ? activatedModules.includes('leaves') : true
  const isAttendanceActivated = isHybridPhase ? activatedModules.includes('attendance') : true
  const isPayrollActivated = isHybridPhase ? activatedModules.includes('payroll') : true
  const isEmployeeInvited =
    employee?.organization?.new_onboarding_enabled && activatedModules.length < 3
      ? is_employees_invited
      : true

  const isHealthInsuranceEnabled =
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.health_insurances_enabled && permission_scopes?.manage_health_insurance

  const isAnnualFlightTicketsEnabled =
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.flight_tickets_enabled && permission_scopes?.manage_annual_flight_tickets

  const isRequestForQuotationsEnabled =
    employee?.organization?.benefits_management_enablement?.sub_modules_enablement
      ?.request_for_quotation_enabled && permission_scopes?.manage_health_insurance

  const isHighTier = employee?.organization.onboarding_flow === OnboardingTier.HIGH_TIER

  const isFlightBookingEnabled =
    employee?.organization.benefits_management_enablement?.sub_modules_enablement
      ?.flight_ticket_bookings_enabled && permission_scopes?.view_flight_bookings

  const onboardingPath = isHighTier ? '/new_onboarding/highTier' : '/new_onboarding/home/org_info'
  const hasViewPaymentPermission = permission_details.view_payment_processing

  const hasModulePermission = (name: string): boolean => {
    const module = permissions?.find((permission) => permission.module === name) as ModuleType
    return module?.value > 0
  }

  // const performanceNavPaywall = (settingPermissions.isSuperAdmin ||
  //   employee?.roles_list?.some((role) => ['Admin'].includes(role))) && {
  //   performance: {
  //     disabled: !isEmployeeInvited,
  //     tooltip: isEmployeeInvited
  //       ? i18n.t('performance')
  //       : i18n.t('Invite employees to unlock Performance'),
  //     icon: 'rocket-01',
  //     path: '/performance_v2',
  //     tab: ['performance_v2'],
  //     testId: 'performance',
  //     menu: [],
  //   },
  // }

  const atsPages: menuType[] = []
  if (
    permission_scopes?.view_jobs_and_its_candidates ||
    permission_scopes?.manage_applicant_tracking_system
  ) {
    atsPages.push({
      label: i18n.t('jobs'),
      path: '/ats/recruitment/job_openings',
      icon: 'briefcase-02',
      testId: 'jobs',
    })
  }
  if (
    permission_scopes?.view_jobs_and_its_candidates ||
    permission_scopes?.view_all_candidates ||
    permission_scopes?.manage_applicant_tracking_system
  ) {
    atsPages.push({
      label: i18n.t('the_candidates'),
      path: '/ats/recruitment/candidates',
      icon: 'users-01',
      testId: 'candidates',
    })
  }
  if (
    (permission_scopes?.view_jobs_and_its_candidates ||
      permission_scopes?.manage_applicant_tracking_system) &&
    permission_scopes?.create_email_templates
  ) {
    atsPages.push({
      label: i18n.t('label.templates'),
      path: '/ats/recruitment/email_templates',
      icon: 'file-06',
      testId: 'email_templates',
      badge: {
        styles: {
          minWidth: 'auto',
        },
        label: i18n.t('label.new'),
        variant: 'tinted',
        color: 'raspberry',
        size: 'small',
        leadingIcon: 'star-07',
      },
    })
  }

  const showStatistics = (): boolean => {
    if (
      (settingPermissions.isAnalysis && showFinancePayroll(permission_details)) ||
      showAttendanceLeaves(permission_scopes) ||
      showWorkforce(permission_scopes) ||
      showAssets(permission_scopes)
    ) {
      return true
    }
    return false
  }

  return {
    general: {
      disabled: !isEmployeeInvited,
      tooltip: isEmployeeInvited
        ? i18n.t('home')
        : i18n.t('communication.invite_employees_to_unlock_home'),
      icon: 'home-02',
      path: '/',
      tab: ['/'],
      testId: 'home',
      event_name: 'Dashboard_Viewed',
    },
    ...(company?.subscribed_features?.includes('employee_engagement') &&
    employee?.permission_scopes?.post_announcements
      ? {
          announcements: {
            tooltip: i18n.t('announcements'),
            icon: 'announcement-02',
            path: '/announcement/listing',
            tab: ['announcements'],
            testId: 'announcements',
            event_name: 'Announcements_Viewed',
          },
        }
      : {}),
    requests: {
      disabled: !isEmployeeInvited,
      tooltip: isEmployeeInvited
        ? i18n.t('requests')
        : i18n.t('communication.invite_employees_to_unlock_requests'),
      icon: 'inbox-01',
      path: '/requests',
      tab: ['requests'],
      testId: 'requests',
      menu: [
        {
          label: i18n.t('your_requests'),
          path: '/requests/employee_requests',
          icon: 'send-01',
          testId: 'your_requests',
          event_name: 'YourRequests_Viewed',
        },
        {
          label: i18n.t('tasks'),
          path: '/requests/tasks',
          icon: 'check-square',
          testId: 'tasks',
          event_name: 'TasksList_Viewed',
        },
        employee?.can_view_my_approvals && {
          label: i18n.t('module.requests.my_approvals'),
          path: '/requests/assigned_requests',
          icon: 'clock-refresh',
          testId: 'assigned_request',
          event_name: 'assigned_request',
        },
        (employee?.is_super_admin ||
          employee?.permission_scopes?.view_employees_requests ||
          employee?.permission_scopes?.view_employees_leave_requests ||
          employee?.permission_scopes?.view_employees_attendance_requests) && {
          label: i18n.t('team_requests'),
          path: '/requests/permitted_requests',
          icon: 'inbox-01',
          testId: 'permitted_requests',
          event_name: 'TeamRequests_Viewed',
        },
      ].filter(Boolean),
    },
    ...((hasModulePermission('Employee profile') ||
      hasModulePermission('Muqeem Services') ||
      hasModulePermission('Hired Candidates') ||
      hasModulePermission('Assets')) && {
      employee: {
        tooltip: i18n.t('employees'),
        icon: 'user-01',
        path: '/employees',
        testId: 'employee',
        tab: [
          'employees',
          'onboarding_employees',
          'organization_chart',
          'reported_violations',
          'muqeem_services',
          'hired_candidates',
          'assets',
        ], // to handle the active state of the tab, because we have different main routes in the same tab
        menu: [
          hasModulePermission('Employee profile') && {
            label: i18n.t('view_employees'),
            path: '/employees',
            icon: 'users-01',
            testId: 'view_employees',
            event_name: 'EmployeeList_Viewed',
          },
          hasModulePermission('Onboarding Employees') && {
            label: i18n.t('module.onboarding_employee'),
            path: '/onboarding_employees',
            icon: 'users-right',
            testId: 'onboarding_employees',
            badge: {
              label: i18n.t('label.beta'),
              color: 'cyan',
              className: 'shrink-0',
            },
            // event_name: 'onboarding_employees',
          },
          settingPermissions.isMultiSubSupported &&
            settingPermissions.view_organization_chart?.[0] === 'all' && {
              label: i18n.t('label.organization_chart'),
              path: '/organization_chart',
              icon: 'dataflow-04',
            },
          hasModulePermission('ViolationList') && {
            label: i18n.t('create_label.sidemenurv'),
            path: '/reported_violations',
            icon: 'user-x-01',
            testId: 'reported_violations',
          },
          hasModulePermission('Muqeem Services') && {
            label: i18n.t('muqeem_services'),
            path: '/muqeem_services',
            icon: 'file-attachment-04',
            nextDivider: true,
            testId: 'muqeem_services',
            event_name: 'ServicesPage_Viewed',
          },
          hasModulePermission('Hired Candidates') && {
            label: i18n.t('hired_candidates'),
            path: '/hired_candidates',
            icon: 'users-plus',
            testId: 'hired_candidates',
          },
          hasModulePermission('Assets') && {
            label: i18n.t('assets'),
            path: '/assets',
            icon: 'laptop-01',
            testId: 'assets',
            event_name: 'AssetsList_Viewed',
          },
        ].filter(Boolean),
      },
    }),
    ...((hasModulePermission('AttendanceDashboard') ||
      hasModulePermission('Leave') ||
      hasModulePermission('Attendance') ||
      hasModulePermission('Scheduler')) && {
      attendance: {
        disabled: !isLeavesActivated && !isAttendanceActivated,
        tooltip:
          isLeavesActivated || isAttendanceActivated
            ? i18n.t('attendance_leaves')
            : i18n.t('communication.activate_leaves_or_attendance_to_unlock'),
        icon: 'clock',
        path: '/attendance',
        tab: ['attendance', 'leave_management', 'scheduler', 'dashboard'],
        testId: 'time_attendance',
        menu: [
          hasModulePermission('AttendanceDashboard') && {
            label: i18n.t('attendance_setup'),
            path: '/dashboard',
            icon: 'bar-chart-square-01',
            testId: 'dashboard',
          },
          hasModulePermission('Leave') &&
            isLeavesActivated && {
              label: i18n.t('leave_balance'),
              path: '/leave_management',
              icon: 'percent-03',
              testId: 'leave_balance_tab',
            },
          hasModulePermission('Attendance') && {
            label: i18n.t('attendance'),
            path: '/attendance',
            icon: 'check-square-broken',
            testId: 'attendance',
          },
          hasModulePermission('Scheduler') && {
            label: i18n.t('shift_scheduler'),
            path: '/scheduler',
            icon: 'calendar',
            testId: 'shift_scheduler',
          },
        ].filter(Boolean),
      },
    }),
    ...((hasModulePermission('Payroll') ||
      hasModulePermission('FinalSettlement') ||
      hasModulePermission('VacationSettlement') ||
      hasModulePermission('PaymentProcessing')) && {
      payroll: {
        disabled: !isPayrollActivated && !isPayrunCompleted,
        tooltip: isPayrollActivated
          ? i18n.t('payroll')
          : i18n.t('communication.activate_payroll_to_unlock_this'),
        icon: 'coins-stacked-01',
        path: '/payroll',
        testId: 'payroll',
        tab: ['payroll', 'off_cycle', 'transfers', 'gosi_sync'],
        menu: [
          hasModulePermission('Payroll') && {
            label: i18n.t('payroll'),
            path: '/payroll',
            icon: 'table',
            testId: 'payroll_table',
          },
          hasModulePermission('Payroll') &&
            employee?.permission_scopes?.manage_other_financial_information && {
              label: i18n.t('gosi_sync'),
              path: '/gosi_sync',
              icon: 'refresh-ccw-02',
              testId: 'gosi_sync',
              event_name: 'gosi_screen_gosi_web',
            },
          hasModulePermission('VacationSettlement') && {
            label: i18n.t('vacation_settlement'),
            path: '/off_cycle/vacation_settlement',
            icon: 'sun-setting-01',
            testId: 'vacation_settlement',
          },
          hasModulePermission('FinalSettlement') && {
            label: i18n.t('final_settlement'),
            path: '/off_cycle/final_settlement',
            icon: 'log-out-03',
            testId: 'final_settlement',
          },
          hasModulePermission('Payroll') &&
            hasViewPaymentPermission && {
              label: i18n.t('label.transfers'),
              path: '/transfers',
              icon: 'switch-horizontal-02',
              testId: 'transfers',
            },
        ].filter(Boolean),
      },
    }),
    ...((hasModulePermission('performanceCyclesCompetencies') ||
      hasModulePermission('PerformanceObjectives') ||
      (settingPermissions.isPerformanceEvaluation &&
        hasModulePermission('PerformanceEvaluation'))) && {
      performance: {
        disabled: !isEmployeeInvited,
        tooltip: isEmployeeInvited
          ? i18n.t('performance')
          : i18n.t('Invite employees to unlock Performance'),
        icon: 'rocket-01',
        ...(settingPermissions.isPerformanceEvaluation
          ? {
              path: pmOnboadringNotCompleted
                ? '/performance_v2/onboarding'
                : '/performance_v2/evaluation_forms',
            }
          : {
              path: '/performance',
            }),
        tab: ['performance', 'performance_v2'],
        testId: 'performance',
        menu: settingPermissions.isPerformanceEvaluation
          ? []
          : [
              settingPermissions.isPerformanceSupport &&
                hasModulePermission('performanceCyclesCompetencies') && {
                  label: i18n.t('performance_cycles'),
                  path: '/performance/cycles',
                  icon: 'intersect-circle',
                  testId: 'performance_cycles',
                },
              settingPermissions.isPerformanceSupport &&
                hasModulePermission('PerformanceObjectives') && {
                  label: i18n.t('objectives'),
                  path: '/performance/objectives',
                  icon: 'target-04',
                  testId: 'objectives',
                },
              settingPermissions.isPerformanceSupport &&
                hasModulePermission('performanceCyclesCompetencies') && {
                  label: i18n.t('competencies'),
                  path: '/performance/competencies',
                  icon: 'check-heart',
                  testId: 'competencies',
                },
            ].filter(Boolean),
      },
    }),
    ...(((hasModulePermission('ATSRecruitment') && atsPages?.length > 0) ||
      settingPermissions.isSuperAdmin) && {
      ats: {
        tooltip: i18n.t('applicant_tracking'),
        icon: 'briefcase-02',
        path: '/ats',
        tab: ['ats'],
        testId: 'ats',
        menu: atsPages,
      },
    }),
    ...((hasModulePermission('Reports') || showStatistics()) && {
      statistics: {
        disabled: !isEmployeeInvited,
        tooltip: isEmployeeInvited
          ? i18n.t('reports_statistics')
          : i18n.t('communication.invite_employees_to_unlock_reports'),
        icon: 'bar-chart-01',
        tab: ['data_analysis', 'reports', 'export_history'],
        dot: false,
        testId: 'reports',
        menu: [
          settingPermissions.isAnalysis && {
            group: {
              title: i18n.t('statistics'),
              menu: [
                showFinancePayroll(permission_details) && {
                  groupTitle: i18n.t('statistics'),
                  label: i18n.t('payroll_statistics'),
                  path: '/data_analysis/finance_payroll',
                  icon: 'coins-stacked-01',
                  testId: 'payroll_statistics',
                },
                showAttendanceLeaves(permission_scopes) && {
                  label: i18n.t('attendance_statistics'),
                  path: '/data_analysis/attendance_leaves',
                  icon: 'clock',
                  testId: 'attendance_statistics',
                },
                showWorkforce(permission_scopes) && {
                  label: i18n.t('employees_statistics'),
                  path: '/data_analysis/workforce',
                  icon: 'calendar',
                  testId: 'employees_statistics',
                },
                showAssets(permission_scopes) && {
                  label: i18n.t('assets_statistics'),
                  path: '/data_analysis/assets',
                  icon: 'hard-drive',
                  testId: 'assets_statistics',
                },
                showATSStatistics(permission_scopes, company?.subscribed_features ?? []) && {
                  label: i18n.t('ats_statistics'),
                  path: '/data_analysis/ats_statistics',
                  icon: 'briefcase-02',
                  testId: 'ats_statistics',
                },
              ].filter(Boolean),
            },
          },
          hasModulePermission('Reports') && {
            group: {
              title: i18n.t('reports'),
              hideDivider: true,
              menu: [
                {
                  label: i18n.t('All_reports'),
                  path: '/reports',
                  icon: 'file-07',
                  testId: 'all_reports',
                },
                {
                  label: i18n.t('label.export_history'),
                  path: '/export_history',
                  icon: 'clock-rewind',
                  testId: 'export_history',
                },
              ],
            },
          },
        ].filter(Boolean),
      },
    }),
    ...(hasModulePermission('BenefitsManagement') && {
      benefitsManagement: {
        disabled: !isEmployeeInvited,
        tooltip: isEmployeeInvited
          ? i18n.t('benefits_management')
          : i18n.t('communication.invite_employees_to_unlock_benefits'),
        icon: 'heart-hand',
        tab: ['benefits_management'],
        dot: false,
        testId: 'benefits_management',
        menu: [
          (isHealthInsuranceEnabled || isRequestForQuotationsEnabled) && {
            group: {
              title: i18n.t('benefits_management_health_insurance'),
              hideDivider: false,
              menu: [
                ...(isHealthInsuranceEnabled
                  ? [
                      {
                        label: i18n.t('benefits_management_providers'),
                        path: '/benefits_management/insurance/providers',
                        testId: 'providers',
                      },
                      {
                        label: i18n.t('benefits_management_policies'),
                        path: '/benefits_management/insurance/policies',
                        testId: 'policies',
                      },
                      {
                        label: i18n.t('endorsements'),
                        path: '/benefits_management/insurance/endorsements',
                        testId: 'endorsements',
                      },
                    ]
                  : []),
                isRequestForQuotationsEnabled && {
                  label: i18n.t('label.quotation_requests'),
                  path: '/benefits_management/insurance/quotations_requests',
                  testId: 'quotations_requests',
                  badge: {
                    label: i18n.t('label.new'),
                    color: 'raspberry',
                    className: 'flex-shrink-0',
                  },
                },
              ].filter(Boolean),
            },
          },
          (isAnnualFlightTicketsEnabled || isFlightBookingEnabled) && {
            group: {
              title: i18n.t('annual_flight_tickets'),
              hideDivider: true,
              menu: [
                isAnnualFlightTicketsEnabled && {
                  label: i18n.t('module.benefits_management.policies_as_terms'),
                  path: '/benefits_management/annualFlightTicket/policies',
                  testId: 'alft_policies',
                },
                isFlightBookingEnabled && {
                  label: i18n.t('label.flights_bookings'),
                  path: '/benefits_management/annualFlightTicket/flight_bookings',
                  testId: 'alft_flight_bookings',
                  size: 'small',
                  badge: {
                    label: i18n.t('label.new'),
                    color: 'raspberry',
                    className: 'flex-shrink-0',
                    leadingIcon: 'star-07',
                  },
                },
              ].filter(Boolean),
            },
          },
        ].filter(Boolean),
      },
    }),
    ...(['aws', 'staging', 'oci', 'dev', 'e2e'].includes(serverEnvironment) &&
      settingPermissions?.manage_integrations && {
        marketplace: {
          disabled: !isEmployeeInvited,
          tooltip: isEmployeeInvited
            ? i18n.t('market_place')
            : i18n.t('communication.invite_employees_to_unlock_marketplace'),
          icon: 'building-02',
          label: i18n.t('apps'),
          dot: false,
          path: `/marketplace`,
          tab: ['marketplace'],
          testId: 'integration_apps',
        },
      }),
    ...(hasModulePermission('Automations') &&
      settingPermissions?.isAutomationSupported && {
        automations: {
          disabled: !isEmployeeInvited,
          tooltip: isEmployeeInvited
            ? i18n.t('automation')
            : i18n.t('communication.invite_employees_to_unlock_automation'),
          icon: 'lightning-01',
          path: '/automations',
          tab: ['automations'],
          testId: 'automations',
          menu: [
            {
              label: i18n.t('automation'),
              path: '/automations/list',
              icon: 'zap-fast',
              testId: 'automations_list',
              event_name: 'Automations_Viewed',
            },
            {
              label: i18n.t('templates'),
              path: '/automations/templates/all',
              icon: 'zap-square',
              testId: 'templates',
            },
            {
              label: i18n.t('history_automation'),
              path: '/automations/history',
              icon: 'clock',
              testId: 'history_automation',
              event_name: 'AutomationsHistory_Viewed',
            },
          ],
        },
      }),
    ...(hasModulePermission('NewOnboarding') && {
      onboarding: {
        tooltip: i18n.t('label.onboarding'),
        icon: 'activity',
        path: onboardingPath,
        tab: ['new_onboarding'],
        testId: 'onboarding',
        event_name: 'new_onboarding',
        menu: [],
      },
    }),

    ...(employee?.can_access_lms &&
      showLumofy && {
        learning_development: {
          tooltip: i18n.t('label.learning_and_development'),
          path: `/learning_and_development`,
          icon: 'book-open-01',
          tab: ['learning_and_development'],
          testId: 'learning_and_development',
          dot: false,
        },
      }),

    ...((hasModulePermission('Settings') || employee?.roles_list?.includes('Group Manager')) && {
      settings: {
        tooltip: i18n.t('settings'),
        icon: 'settings-02',
        dot: false,
        path: '/setting_v2',
        tab: ['setting_v2'],
        testId: 'settings',
        menu: settingNavList,
      },
    }),
  } as NavDataType
}
