import { FC, Fragment } from 'react'
import { LazyAvatar } from 'components/global/atoms'
import { AuthorizedEmployeeType } from 'redux/setting/payrollManagement/paymentMethods/mudad/reducer'
import PaymentConfigMenu from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/PaymentConfigMenu'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'
import { Typography, Flex } from '@jisr-hr/ds'

type AuthorizerAdminBoxType = {
  authorizer: AuthorizedEmployeeType
  onReVerify: () => void
  onDeleteClick: () => void
}

const AuthorizerAdminBox: FC<AuthorizerAdminBoxType> = (props) => {
  const { authorizer } = props
  const { status } = authorizer

  return (
    <Fragment>
      <Flex
        itemsCenter
        justifyBetween
      >
        <Flex
          itemsCenter
          style={{ gap: 16, flex: '0.4 1 0' }}
        >
          <LazyAvatar
            containerStyle={{
              height: 40,
              width: 40,
              margin: 0,
            }}
            src={authorizer?.avatar}
            alt={authorizer?.full_name_i18n}
          />
          <div>
            <Typography
              variant="interface/default/sm"
              style={{ lineHeight: '20px' }}
              text={authorizer?.full_name_i18n}
            />
            <Typography
              variant="caption"
              style={{ color: 'var(--color-base-colors-grey-800)' }}
              text={`${i18n.t('emp_id')}: ${authorizer?.employee_id}`}
            />
          </div>
        </Flex>

        <Flex
          itemsCenter
          justifyBetween
          style={{ flex: '0.6 1 0' }}
        >
          <Flex
            itemsCenter
            style={{ gap: 8 }}
          >
            <LazyAvatar
              containerStyle={{ height: 24, width: 24, margin: 0, borderRadius: 6 }}
              style={{ borderRadius: 6, height: 24, width: 24 }}
              src={authorizer?.bank_info?.bank_logo}
              alt={authorizer?.full_name_i18n}
            />
            <Typography
              variant="caption"
              style={{ color: 'var(--color-base-colors-grey-800)', lineHeight: '20px' }}
              text={`${authorizer?.bank_info?.bank_name} - ${authorizer?.bank_info?.iban?.replace(
                'SA',
                '',
              )}`}
            />
          </Flex>

          <Flex
            itemsCenter
            style={{ gap: 8 }}
          >
            {status === 'authorized' && (
              <Flex
                itemsCenter
                style={{ gap: 8 }}
              >
                <DSIcon
                  name="check-circle"
                  size="md"
                  color="#009950"
                />
                <Typography
                  variant="interface/default/sm"
                  style={{ lineHeight: '20px', color: 'var(--color-base-colors-green-800)' }}
                  text={authorizer.status_body.status}
                />
              </Flex>
            )}

            <PaymentConfigMenu
              disabled={false}
              btnVariant="ghost"
              options={[
                ...(status !== 'authorized'
                  ? [
                      {
                        name: i18n.t('edit_authorization'),
                        onClick: props.onReVerify,
                      },
                    ]
                  : []),
                {
                  name: i18n.t('delete_authorization'),
                  onClick: props.onDeleteClick,
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
      {status !== 'authorized' && (
        <Typography
          variant="caption"
          style={{
            color:
              status === 'error'
                ? 'var(--color-base-colors-red-700)'
                : 'var(--color-base-colors-yellow-700)',
            marginTop: 8,
          }}
          text={authorizer.status_body.status}
        />
      )}
    </Fragment>
  )
}

export default AuthorizerAdminBox
