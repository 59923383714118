import { Containers, Tooltip, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'
import { isRtl } from 'utils/uiHelpers'
import { differenceInYears } from 'date-fns'
import Frame from '../../../ds/Frame'

export type FlightTicketRequestDependentsType = {
  name_i18n: string
  relation_i18n: string
  date_of_birth: string
  passport_number: string
  coverage_percentage: number
}

const Dependents = (props: {
  dependents: FlightTicketRequestDependentsType[]
  exceptionNoOfDependents: boolean | null
  numberOfDependentCovered: number
}): JSX.Element => {
  const { dependents, exceptionNoOfDependents, numberOfDependentCovered } = props
  return (
    <Frame
      title={i18n.t('label.dependants')}
      {...(exceptionNoOfDependents && {
        titleSuffix: (
          <Tooltip
            supportingText={i18n.t('communication.user_policy_only_supports_count_dependents', {
              count: numberOfDependentCovered,
            })}
            arrow={isRtl ? 'topLeft' : 'topRight'}
          >
            <DSIcon
              name="alert-triangle"
              style={{ color: 'gray' }}
            />
          </Tooltip>
        ),
      })}
    >
      <div className="flex flex-col gap-[16px]">
        {dependents.map((dependent, i) => (
          <Containers
            className="p-[16px] flex flex-col gap-[4px]"
            borderRadius="sm"
            color="gray"
            border
            key={i}
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[4px] items-center">
                <DSIcon name="user-circle" />
                <Typography
                  text={dependent.name_i18n}
                  variant="body-new/medium"
                />
              </div>
              <Typography
                text={i18n.t('label.age_years_old', {
                  Age: differenceInYears(new Date(), new Date(dependent?.date_of_birth)),
                })}
                variant="body-new/regular"
                textColor="color/fg/light"
              />
            </div>
            <Typography
              text={dependent.passport_number}
              variant="body-new/regular"
              textColor="color/fg/light"
            />
            <Typography
              text={i18n.t('communication.of_ticket_covered', {
                percentage: dependent.coverage_percentage,
              })}
              variant="body-new/regular"
              textColor="color/fg/light"
            />
          </Containers>
        ))}
      </div>
    </Frame>
  )
}

export default Dependents
