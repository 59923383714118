import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Qs from 'qs'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'utils/hooks'
import I18n from 'translations/i18n'
import JisrLoader from 'components/global/JisrLoader'
import ExpiryTemplate from 'containers/public/ChangePassword/components/ExpiryTemplate'

import styles from './OnboardingWrapper.module.css'
import { OnboardingContext } from '../context'
import ActivateAccount from './ActivateAccount'
import MicroTemplete from './MicroTemplete'

function OnboardingWrapper({ children }: { children: JSX.Element }): JSX.Element {
  const { fetching, organization, error } = useSelector((s) => s.organization)
  const lan = organization.metadata
  const showPinCode = btoa(sessionStorage.getItem(atob('pincode')) || '')

  const { location, push } = useHistory()
  const { token, slug } = Qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const { showSidebar, org, sidebar, showActivateAccount } = useContext(OnboardingContext)

  useEffect(() => {
    if (
      token === localStorage.getItem('onboarding_token') &&
      slug === localStorage.getItem('slug')
    ) {
      push('/onboarding')
    }
  }, [])

  if (showActivateAccount) {
    return <ActivateAccount />
  }

  if (token) {
    localStorage.setItem('onboarding_token', token.toString())
  }

  if (slug) {
    localStorage.setItem('slug', slug.toString())
  }

  if (!localStorage.getItem('onboarding_token')) {
    return <ExpiryTemplate title={I18n.t('onboarding_invalid_or_expired_token')} />
  }

  if (fetching && !org.id) {
    return <JisrLoader absolute />
  }

  if (
    organization.require_pincode &&
    (showPinCode?.length !== 4 || !lan.organization_info.language_selection.is_completed)
  ) {
    return <MicroTemplete language={!lan.organization_info.language_selection.is_completed} />
  }

  if (error && !org.id) {
    return <ExpiryTemplate title={I18n.t('onboarding_invalid_or_expired_token')} />
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.pageContent}
        style={{ width: showSidebar ? '75%' : '100%' }}
      >
        {org.id ? children : null}
      </div>
      <div
        className={styles.infoSidebar}
        style={{ width: showSidebar ? '25%' : '0' }}
      >
        {sidebar}
      </div>
    </div>
  )
}

OnboardingWrapper.propTypes = {
  children: PropTypes.node,
}

export default OnboardingWrapper
