// vacation salary payment task
export const VACATION_SALARY_EMPLOYEES_LOAD = 'VACATION_SALARY_EMPLOYEES_LOAD'
export const VACATION_SALARY_EMPLOYEES_LOAD_PENDING = 'VACATION_SALARY_EMPLOYEES_LOAD_PENDING'
export const VACATION_SALARY_EMPLOYEES_LOAD_FULFILLED = 'VACATION_SALARY_EMPLOYEES_LOAD_FULFILLED'
export const VACATION_SALARY_EMPLOYEES_LOAD_REJECTED = 'VACATION_SALARY_EMPLOYEES_LOAD_REJECTED'

export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD = 'ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_PENDING =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_PENDING'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_FULFILLED =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_FULFILLED'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_REJECTED =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_LOAD_REJECTED'

export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_PENDING =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_PENDING'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_FULFILLED =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_FULFILLED'
export const ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_REJECTED =
  'ADVANCE_VACATION_SALARY_PAYMENT_TASK_UPDATE_REJECTED'

// issuing ticket task
export const ISSUING_TICKET_TASK_LOAD = 'ISSUING_TICKET_TASK_LOAD'
export const ISSUING_TICKET_TASK_LOAD_PENDING = 'ISSUING_TICKET_TASK_LOAD_PENDING'
export const ISSUING_TICKET_TASK_LOAD_FULFILLED = 'ISSUING_TICKET_TASK_LOAD_FULFILLED'
export const ISSUING_TICKET_TASK_LOAD_REJECTED = 'ISSUING_TICKET_TASK_LOAD_REJECTED'

export const ISSUING_TICKET_TASK_UPDATE = 'ISSUING_TICKET_TASK_UPDATE'
export const ISSUING_TICKET_TASK_UPDATE_PENDING = 'ISSUING_TICKET_TASK_UPDATE_PENDING'
export const ISSUING_TICKET_TASK_UPDATE_FULFILLED = 'ISSUING_TICKET_TASK_UPDATE_FULFILLED'
export const ISSUING_TICKET_TASK_UPDATE_REJECTED = 'ISSUING_TICKET_TASK_UPDATE_REJECTED'

// ticket payment task
export const TICKET_PAYMENT_TASK_LOAD = 'TICKET_PAYMENT_TASK_LOAD'
export const TICKET_PAYMENT_TASK_LOAD_PENDING = 'TICKET_PAYMENT_TASK_LOAD_PENDING'
export const TICKET_PAYMENT_TASK_LOAD_FULFILLED = 'TICKET_PAYMENT_TASK_LOAD_FULFILLED'
export const TICKET_PAYMENT_TASK_LOAD_REJECTED = 'TICKET_PAYMENT_TASK_LOAD_REJECTED'

export const TICKET_PAYMENT_TASK_UPDATE = 'TICKET_PAYMENT_TASK_UPDATE'
export const TICKET_PAYMENT_TASK_UPDATE_PENDING = 'TICKET_PAYMENT_TASK_UPDATE_PENDING'
export const TICKET_PAYMENT_TASK_UPDATE_FULFILLED = 'TICKET_PAYMENT_TASK_UPDATE_FULFILLED'
export const TICKET_PAYMENT_TASK_UPDATE_REJECTED = 'TICKET_PAYMENT_TASK_UPDATE_REJECTED'

// Exit Re-entry payment task
export const EXIT_REENTRY_PAYMENT_TASK_LOAD = 'EXIT_REENTRY_PAYMENT_TASK_LOAD'
export const EXIT_REENTRY_PAYMENT_TASK_LOAD_PENDING = 'EXIT_REENTRY_PAYMENT_TASK_LOAD_PENDING'
export const EXIT_REENTRY_PAYMENT_TASK_LOAD_FULFILLED = 'EXIT_REENTRY_PAYMENT_TASK_LOAD_FULFILLED'
export const EXIT_REENTRY_PAYMENT_TASK_LOAD_REJECTED = 'EXIT_REENTRY_PAYMENT_TASK_LOAD_REJECTED'

export const EXIT_REENTRY_PAYMENT_TASK_UPDATE = 'EXIT_REENTRY_PAYMENT_TASK_UPDATE'
export const EXIT_REENTRY_PAYMENT_TASK_UPDATE_PENDING = 'EXIT_REENTRY_PAYMENT_TASK_UPDATE_PENDING'
export const EXIT_REENTRY_PAYMENT_TASK_UPDATE_FULFILLED =
  'EXIT_REENTRY_PAYMENT_TASK_UPDATE_FULFILLED'
export const EXIT_REENTRY_PAYMENT_TASK_UPDATE_REJECTED = 'EXIT_REENTRY_PAYMENT_TASK_UPDATE_REJECTED'

// Issuing Exit Re-entry task
export const ISSUING_EXIT_REENTRY_TASK_LOAD = 'ISSUING_EXIT_REENTRY_TASK_LOAD'
export const ISSUING_EXIT_REENTRY_TASK_LOAD_PENDING = 'ISSUING_EXIT_REENTRY_TASK_LOAD_PENDING'
export const ISSUING_EXIT_REENTRY_TASK_LOAD_FULFILLED = 'ISSUING_EXIT_REENTRY_TASK_LOAD_FULFILLED'
export const ISSUING_EXIT_REENTRY_TASK_LOAD_REJECTED = 'ISSUING_EXIT_REENTRY_TASK_LOAD_REJECTED'

export const ISSUING_EXIT_REENTRY_TASK_UPDATE = 'ISSUING_EXIT_REENTRY_TASK_UPDATE'
export const ISSUING_EXIT_REENTRY_TASK_UPDATE_PENDING = 'ISSUING_EXIT_REENTRY_TASK_UPDATE_PENDING'
export const ISSUING_EXIT_REENTRY_TASK_UPDATE_FULFILLED =
  'ISSUING_EXIT_REENTRY_TASK_UPDATE_FULFILLED'
export const ISSUING_EXIT_REENTRY_TASK_UPDATE_REJECTED = 'ISSUING_EXIT_REENTRY_TASK_UPDATE_REJECTED'

// loan payment task
export const LOAN_PAYMENT_TASK_LOAD = 'LOAN_PAYMENT_TASK_LOAD'
export const LOAN_PAYMENT_TASK_LOAD_PENDING = 'LOAN_PAYMENT_TASK_LOAD_PENDING'
export const LOAN_PAYMENT_TASK_LOAD_FULFILLED = 'LOAN_PAYMENT_TASK_LOAD_FULFILLED'
export const LOAN_PAYMENT_TASK_LOAD_REJECTED = 'LOAN_PAYMENT_TASK_LOAD_REJECTED'

export const LOAN_PAYMENT_TASK_UPDATE = 'LOAN_PAYMENT_TASK_UPDATE'
export const LOAN_PAYMENT_TASK_UPDATE_PENDING = 'LOAN_PAYMENT_TASK_UPDATE_PENDING'
export const LOAN_PAYMENT_TASK_UPDATE_FULFILLED = 'LOAN_PAYMENT_TASK_UPDATE_FULFILLED'
export const LOAN_PAYMENT_TASK_UPDATE_REJECTED = 'LOAN_PAYMENT_TASK_UPDATE_REJECTED'

// final settlement payment task
export const FINAL_SETTLEMENT_EMPLOYEES_LOAD = 'FINAL_SETTLEMENT_EMPLOYEES_LOAD'
export const FINAL_SETTLEMENT_EMPLOYEES_LOAD_PENDING = 'FINAL_SETTLEMENT_EMPLOYEES_LOAD_PENDING'
export const FINAL_SETTLEMENT_EMPLOYEES_LOAD_FULFILLED = 'FINAL_SETTLEMENT_EMPLOYEES_LOAD_FULFILLED'
export const FINAL_SETTLEMENT_EMPLOYEES_LOAD_REJECTED = 'FINAL_SETTLEMENT_EMPLOYEES_LOAD_REJECTED'

export const FINAL_SETTLEMENT_PAYMENT_TASK_LOAD = 'FINAL_SETTLEMENT_PAYMENT_TASK_LOAD'
export const FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_PENDING =
  'FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_PENDING'
export const FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_FULFILLED =
  'FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_FULFILLED'
export const FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_REJECTED =
  'FINAL_SETTLEMENT_PAYMENT_TASK_LOAD_REJECTED'

export const FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE = 'FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE'
export const FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_PENDING =
  'FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_PENDING'
export const FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_FULFILLED =
  'FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_FULFILLED'
export const FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_REJECTED =
  'FINAL_SETTLEMENT_PAYMENT_TASK_UPDATE_REJECTED'

export const FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD = 'FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_PENDING =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_PENDING'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_FULFILLED =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_FULFILLED'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_REJECTED =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_LOAD_REJECTED'

export const FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE = 'FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_PENDING =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_PENDING'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_FULFILLED =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_FULFILLED'
export const FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_REJECTED =
  'FINAL_SETTLEMENT_PROCCESSING_TASK_UPDATE_REJECTED'

// business trip payment task
export const BUSINESS_TRIP_PAYMENT_TASK_LOAD = 'BUSINESS_TRIP_PAYMENT_TASK_LOAD'
export const BUSINESS_TRIP_PAYMENT_TASK_LOAD_PENDING = 'BUSINESS_TRIP_PAYMENT_TASK_LOAD_PENDING'
export const BUSINESS_TRIP_PAYMENT_TASK_LOAD_FULFILLED = 'BUSINESS_TRIP_PAYMENT_TASK_LOAD_FULFILLED'
export const BUSINESS_TRIP_PAYMENT_TASK_LOAD_REJECTED = 'BUSINESS_TRIP_PAYMENT_TASK_LOAD_REJECTED'

export const BUSINESS_TRIP_PAYMENT_TASK_UPDATE = 'BUSINESS_TRIP_PAYMENT_TASK_UPDATE'
export const BUSINESS_TRIP_PAYMENT_TASK_UPDATE_PENDING = 'BUSINESS_TRIP_PAYMENT_TASK_UPDATE_PENDING'
export const BUSINESS_TRIP_PAYMENT_TASK_UPDATE_FULFILLED =
  'BUSINESS_TRIP_PAYMENT_TASK_UPDATE_FULFILLED'
export const BUSINESS_TRIP_PAYMENT_TASK_UPDATE_REJECTED =
  'BUSINESS_TRIP_PAYMENT_TASK_UPDATE_REJECTED'

// expense claim payment task
export const EXPENSE_CLAIM_PAYMENT_TASK_LOAD = 'EXPENSE_CLAIM_PAYMENT_TASK_LOAD'
export const EXPENSE_CLAIM_PAYMENT_TASK_LOAD_PENDING = 'EXPENSE_CLAIM_PAYMENT_TASK_LOAD_PENDING'
export const EXPENSE_CLAIM_PAYMENT_TASK_LOAD_FULFILLED = 'EXPENSE_CLAIM_PAYMENT_TASK_LOAD_FULFILLED'
export const EXPENSE_CLAIM_PAYMENT_TASK_LOAD_REJECTED = 'EXPENSE_CLAIM_PAYMENT_TASK_LOAD_REJECTED'

export const EXPENSE_CLAIM_PAYMENT_TASK_UPDATE = 'EXPENSE_CLAIM_PAYMENT_TASK_UPDATE'
export const EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_PENDING = 'EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_PENDING'
export const EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_FULFILLED =
  'EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_FULFILLED'
export const EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_REJECTED =
  'EXPENSE_CLAIM_PAYMENT_TASK_UPDATE_REJECTED'

// payroll payment task
export const PAYRUN_EMPLOYEES_LOAD = 'PAYRUN_EMPLOYEES_LOAD'
export const PAYRUN_EMPLOYEES_LOAD_PENDING = 'PAYRUN_EMPLOYEES_LOAD_PENDING'
export const PAYRUN_EMPLOYEES_LOAD_FULFILLED = 'PAYRUN_EMPLOYEES_LOAD_FULFILLED'
export const PAYRUN_EMPLOYEES_LOAD_REJECTED = 'PAYRUN_EMPLOYEES_LOAD_REJECTED'

export const PAYRUN_PAYMENT_TASK_LOAD = 'PAYRUN_PAYMENT_TASK_LOAD'
export const PAYRUN_PAYMENT_TASK_LOAD_PENDING = 'PAYRUN_PAYMENT_TASK_LOAD_PENDING'
export const PAYRUN_PAYMENT_TASK_LOAD_FULFILLED = 'PAYRUN_PAYMENT_TASK_LOAD_FULFILLED'
export const PAYRUN_PAYMENT_TASK_LOAD_REJECTED = 'PAYRUN_PAYMENT_TASK_LOAD_REJECTED'

export const PAYRUN_PAYMENT_TASK_UPDATE = 'PAYRUN_PAYMENT_TASK_UPDATE'
export const PAYRUN_PAYMENT_TASK_UPDATE_PENDING = 'PAYRUN_PAYMENT_TASK_UPDATE_PENDING'
export const PAYRUN_PAYMENT_TASK_UPDATE_FULFILLED = 'PAYRUN_PAYMENT_TASK_UPDATE_FULFILLED'
export const PAYRUN_PAYMENT_TASK_UPDATE_REJECTED = 'PAYRUN_PAYMENT_TASK_UPDATE_REJECTED'

// gosi salary updation task
export const GOSI_SALARY_UPDATION_TASK_LOAD = 'GOSI_SALARY_UPDATION_TASK_LOAD'
export const GOSI_SALARY_UPDATION_TASK_LOAD_PENDING = 'GOSI_SALARY_UPDATION_TASK_LOAD_PENDING'
export const GOSI_SALARY_UPDATION_TASK_LOAD_FULFILLED = 'GOSI_SALARY_UPDATION_TASK_LOAD_FULFILLED'
export const GOSI_SALARY_UPDATION_TASK_LOAD_REJECTED = 'GOSI_SALARY_UPDATION_TASK_LOAD_REJECTED'

export const GOSI_SALARY_UPDATION_TASK_UPDATE = 'GOSI_SALARY_UPDATION_TASK_UPDATE'
export const GOSI_SALARY_UPDATION_TASK_UPDATE_PENDING = 'GOSI_SALARY_UPDATION_TASK_UPDATE_PENDING'
export const GOSI_SALARY_UPDATION_TASK_UPDATE_FULFILLED =
  'GOSI_SALARY_UPDATION_TASK_UPDATE_FULFILLED'
export const GOSI_SALARY_UPDATION_TASK_UPDATE_REJECTED = 'GOSI_SALARY_UPDATION_TASK_UPDATE_REJECTED'

// employee gosi registration task
export const EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD = 'EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_PENDING =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_PENDING'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_FULFILLED =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_FULFILLED'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_REJECTED =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_LOAD_REJECTED'

export const EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE = 'EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_PENDING =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_PENDING'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_FULFILLED =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_FULFILLED'
export const EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_REJECTED =
  'EMPLOYEE_GOSI_REGISTRATION_TASK_UPDATE_REJECTED'

// letter issuing task
export const LETTER_ISSUING_TASK_LOAD = 'LETTER_ISSUING_TASK_LOAD'
export const LETTER_ISSUING_TASK_LOAD_PENDING = 'LETTER_ISSUING_TASK_LOAD_PENDING'
export const LETTER_ISSUING_TASK_LOAD_FULFILLED = 'LETTER_ISSUING_TASK_LOAD_FULFILLED'
export const LETTER_ISSUING_TASK_LOAD_REJECTED = 'LETTER_ISSUING_TASK_LOAD_REJECTED'

export const LETTER_ISSUING_TASK_UPDATE = 'LETTER_ISSUING_TASK_UPDATE'
export const LETTER_ISSUING_TASK_UPDATE_PENDING = 'LETTER_ISSUING_TASK_UPDATE_PENDING'
export const LETTER_ISSUING_TASK_UPDATE_FULFILLED = 'LETTER_ISSUING_TASK_UPDATE_FULFILLED'
export const LETTER_ISSUING_TASK_UPDATE_REJECTED = 'LETTER_ISSUING_TASK_UPDATE_REJECTED'

export const VACATION_SETTLEMENT_PROCESSING_TASK_LOAD = 'VACATION_SETTLEMENT_PROCESSING_TASK_LOAD'
export const VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_PENDING =
  'VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_PENDING'
export const VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_FULFILLED =
  'VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_FULFILLED'
export const VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_REJECTED =
  'VACATION_SETTLEMENT_PROCESSING_TASK_LOAD_REJECTED'

export const VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE =
  'VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE'
export const VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_PENDING =
  'VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_PENDING'
export const VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_FULFILLED =
  'VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_FULFILLED'
export const VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_REJECTED =
  'VACATION_SETTLEMENT_PROCESSING_TASK_UPDATE_REJECTED'

// asset allocation/deallocation tasks
export const ASSET_ALLOCATION_TASK_LOAD = 'ASSET_ALLOCATION_TASK_LOAD'
export const ASSET_ALLOCATION_TASK_LOAD_PENDING = 'ASSET_ALLOCATION_TASK_LOAD_PENDING'
export const ASSET_ALLOCATION_TASK_LOAD_FULFILLED = 'ASSET_ALLOCATION_TASK_LOAD_FULFILLED'
export const ASSET_ALLOCATION_TASK_LOAD_REJECTED = 'ASSET_ALLOCATION_TASK_LOAD_REJECTED'

export const ASSET_DEALLOCATION_TASK_LOAD = 'ASSET_DEALLOCATION_TASK_LOAD'
export const ASSET_DEALLOCATION_TASK_LOAD_PENDING = 'ASSET_DEALLOCATION_TASK_LOAD_PENDING'
export const ASSET_DEALLOCATION_TASK_LOAD_FULFILLED = 'ASSET_DEALLOCATION_TASK_LOAD_FULFILLED'
export const ASSET_DEALLOCATION_TASK_LOAD_REJECTED = 'ASSET_DEALLOCATION_TASK_LOAD_REJECTED'

export const ASSET_ALLOCATION_TASK_UPDATE = 'ASSET_ALLOCATION_TASK_UPDATE'
export const ASSET_ALLOCATION_TASK_UPDATE_PENDING = 'ASSET_ALLOCATION_TASK_UPDATE_PENDING'
export const ASSET_ALLOCATION_TASK_UPDATE_FULFILLED = 'ASSET_ALLOCATION_TASK_UPDATE_FULFILLED'
export const ASSET_ALLOCATION_TASK_UPDATE_REJECTED = 'ASSET_ALLOCATION_TASK_UPDATE_REJECTED'

export const ASSET_DEALLOCATION_TASK_UPDATE = 'ASSET_DEALLOCATION_TASK_UPDATE'
export const ASSET_DEALLOCATION_TASK_UPDATE_PENDING = 'ASSET_DEALLOCATION_TASK_UPDATE_PENDING'
export const ASSET_DEALLOCATION_TASK_UPDATE_FULFILLED = 'ASSET_DEALLOCATION_TASK_UPDATE_FULFILLED'
export const ASSET_DEALLOCATION_TASK_UPDATE_REJECTED = 'ASSET_DEALLOCATION_TASK_UPDATE_REJECTED'

export const BOOK_FLIGHT_TICKET_TASK_UPDATE = 'BOOK_FLIGHT_TICKET_TASK_UPDATE'
export const BOOK_FLIGHT_TICKET_TASK_UPDATE_PENDING = 'BOOK_FLIGHT_TICKET_TASK_UPDATE_PENDING'
export const BOOK_FLIGHT_TICKET_TASK_UPDATE_FULFILLED = 'BOOK_FLIGHT_TICKET_TASK_UPDATE_FULFILLED'
export const BOOK_FLIGHT_TICKET_TASK_UPDATE_REJECTED = 'BOOK_FLIGHT_TICKET_TASK_UPDATE_REJECTED'

// book flight ticket task

export const BOOK_FLIGHT_TICKET_TASKS_LOAD = 'BOOK_FLIGHT_TICKET_TASKS_LOAD'
export const BOOK_FLIGHT_TICKET_TASKS_LOAD_PENDING = 'BOOK_FLIGHT_TICKET_TASKS_LOAD_PENDING'
export const BOOK_FLIGHT_TICKET_TASKS_LOAD_FULFILLED = 'BOOK_FLIGHT_TICKET_TASKS_LOAD_FULFILLED'
export const BOOK_FLIGHT_TICKET_TASKS_LOAD_REJECTED = 'BOOK_FLIGHT_TICKET_TASKS_LOAD_REJECTED'

// ORGANIZATION TASKS
export const ORGANIZATION_TASKS_LOAD = 'ORGANIZATION_TASKS_LOAD'
export const ORGANIZATION_TASKS_LOAD_PENDING = 'ORGANIZATION_TASKS_LOAD_PENDING'
export const ORGANIZATION_TASKS_LOAD_FULFILLED = 'ORGANIZATION_TASKS_LOAD_FULFILLED'
export const ORGANIZATION_TASKS_LOAD_REJECTED = 'ORGANIZATION_TASKS_LOAD_REJECTED'

export const ORGANIZATION_TASKS_CREATE = 'ORGANIZATION_TASKS_CREATE'
export const ORGANIZATION_TASKS_CREATE_PENDING = 'ORGANIZATION_TASKS_CREATE_PENDING'
export const ORGANIZATION_TASKS_CREATE_FULFILLED = 'ORGANIZATION_TASKS_CREATE_FULFILLED'
export const ORGANIZATION_TASKS_CREATE_REJECTED = 'ORGANIZATION_TASKS_CREATE_REJECTED'

export const ORGANIZATION_TASKS_PUT = 'ORGANIZATION_TASKS_PUT'
export const ORGANIZATION_TASKS_PUT_PENDING = 'ORGANIZATION_TASKS_PUT_PENDING'
export const ORGANIZATION_TASKS_PUT_FULFILLED = 'ORGANIZATION_TASKS_PUT_FULFILLED'
export const ORGANIZATION_TASKS_PUT_REJECTED = 'ORGANIZATION_TASKS_PUT_REJECTED'

export const ORGANIZATION_TASKS_DELETE = 'ORGANIZATION_TASKS_DELETE'
export const ORGANIZATION_TASKS_DELETE_PENDING = 'ORGANIZATION_TASKS_DELETE_PENDING'
export const ORGANIZATION_TASKS_DELETE_FULFILLED = 'ORGANIZATION_TASKS_DELETE_FULFILLED'
export const ORGANIZATION_TASKS_DELETE_REJECTED = 'ORGANIZATION_TASKS_DELETE_REJECTED'

export const ORGANIZATION_TASKS_SHOW = 'ORGANIZATION_TASKS_SHOW'
export const ORGANIZATION_TASKS_SHOW_PENDING = 'ORGANIZATION_TASKS_SHOW_PENDING'
export const ORGANIZATION_TASKS_SHOW_FULFILLED = 'ORGANIZATION_TASKS_SHOW_FULFILLED'
export const ORGANIZATION_TASKS_SHOW_REJECTED = 'ORGANIZATION_TASKS_SHOW_REJECTED'
