import { Typography, Spacer } from '@jisr-hr/ds'
import { Trans } from 'react-i18next'
import { useDispatch } from 'utils/hooks'
import I18n from 'translations/i18n'
import { exportOnboardingOrganizationData } from 'redux/organizationOnborading/bulkUpload/actionCreators'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import {
  DownloadBox,
  UploadingBox,
} from 'containers/public/Onboarding/components/DownloadUploadBoxes'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

const SalariesPackages = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()

  const handelDownload = (): void => {
    dispatch(
      exportOnboardingOrganizationData({
        type: 'onboarding_salaries_packages',
      }),
    )
  }

  useTrackingSteps({ step_name: 'payroll_setup', level_name: 'salaries_packages' })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('salaries_packages')}
      />

      <Spacer height="8px" />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('salaries_packages_desc')}
      />

      <Spacer height="32px" />

      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={
          <Trans
            i18nKey="upload_employees_salaries_data_desc"
            components={{ br: <br /> }}
            values={{ appName: I18n.t('jisr_translation_name') }}
          />
        }
      />

      <Spacer height="32px" />

      <DownloadBox
        handelDownload={handelDownload}
        title={I18n.t('download_salaries_details_sheet')}
      />
      <Spacer height="16px" />
      {/* @ts-expect-error need to migrate SelectField to TS */}
      <UploadingBox type="onboarding_salaries_packages" />
    </StepTemplate>
  )
}

export default SalariesPackages
