import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  training_video: {},
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_TRAINING_VIDEOS_RATING_PUT_PENDING:
    case actions.ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_FULFILLED:
      return {
        ...state,
        fetching: false,
        training_video: payload.data.data?.training_video,
      }

    case actions.ONBOARDING_TRAINING_VIDEOS_RATING_PUT_FULFILLED:
      return {
        ...state,
        fetching: false,
        training_video: {
          ...state.training_video,
          answer: action.payload?.data?.data,
        },
      } as InitialStateType

    case actions.ONBOARDING_TRAINING_VIDEOS_RATING_PUT_REJECTED:
    case actions.ONBOARDING_TRAINING_VIDEOS_BY_STEP_NAME_SHOW_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.TRAINING_VIDEOS_RESET:
      return {
        ...state,
        fetching: false,
        training_video: {},
      }
    default:
      return state
  }
}
