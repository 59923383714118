import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type TInitState = {
  fetching: boolean
  reasons: { code: string; title: string; title_ar: string }[]
}

const initialState: TInitState = {
  fetching: false,
  reasons: [],
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.GET_GOSI_TERMINATION_REASONS_PENDING:
    case actions.TERMINATE_EMPLOYEE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_GOSI_TERMINATION_REASONS_FULFILLED:
      return {
        ...state,
        reasons: payload.data.data,
        fetching: false,
      }

    case actions.TERMINATE_EMPLOYEE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.GET_GOSI_TERMINATION_REASONS_REJECTED:
    case actions.TERMINATE_EMPLOYEE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
