import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type InvoicesProps = {
  filterKey: string
  filters: string[]
}

const InvoicesStatus = (props: InvoicesProps): JSX.Element => {
  const { invoices_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const onHandleFilter = (_: string, val: string): void => {
    handleFilter('invoices_status', val)
  }

  useEffect(() => {
    if (!isEmpty(filter.invoices_status)) {
      setSelectedIds(filter.invoices_status)
    } else {
      setSelectedIds([])
    }
  }, [filter.invoices_status])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={invoices_status}
      selectedIds={selectedIds as never[]}
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default InvoicesStatus
