import { useEffect, useState } from 'react'
import i18n from 'translations/i18n'

import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type WalletServicesTypesType = {
  filterKey: string
  filters: string[]
}

const WalletServicesTypes = (props: WalletServicesTypesType): JSX.Element => {
  const { wallet_services_types } = useFilterAPI()
  const { filter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])
  const optionList = returnBadgeList(wallet_services_types)

  const onHandleFilter = (_: string, val: string): void => {
    setFilter({
      ...filter,
      wallet_services_types: val,
    })
  }

  useEffect(() => {
    if (filter.wallet_services_types) {
      setSelectedIds(filter.wallet_services_types)
    }
  }, [filter.wallet_services_types])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={optionList}
      selectedIds={selectedIds}
      valueKey="id"
      labelKey="name"
      componentType="badges"
      {...props}
      title={i18n.t('label.service')}
    />
  )
}

export default WalletServicesTypes
