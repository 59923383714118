import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@jisr-hr/ds'

function Checkboxes({ options, onChange, values = [], testId }) {
  const [selected, setSelected] = React.useState()

  function handleChange(checked, option) {
    if (checked) {
      // setSelected([...selected, option]);
      onChange([...selected, option.value])
    } else {
      const result = selected.filter((id) => id !== option.value)
      // setSelected(result);
      onChange(result)
    }
  }

  React.useEffect(() => {
    setSelected(values)
  }, [values])

  return (
    <div
      data-testid={testId}
      className="jisr-groupselect__options"
    >
      {options.map((option, oi) => (
        <React.Fragment key={`options-${oi}`}>
          <Checkbox
            onChange={(e) => handleChange(e.target.checked, option)}
            label={option.label}
            testId={option.testId}
            active={values.includes(option.value)}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

Checkboxes.propTypes = {
  options: PropTypes.arrayOf(Object),
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  testId: PropTypes.string,
}

export default Checkboxes
