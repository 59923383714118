import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

const Lazyload = lazy(() => import('./Lazyload'))

const LazyContainer = ({ fallback, children }) => (
  <Suspense fallback={fallback}>
    <Lazyload>{children}</Lazyload>
  </Suspense>
)

LazyContainer.propTypes = {
  fallback: PropTypes.node,
  children: PropTypes.node,
}

export default LazyContainer
