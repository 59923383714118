export const SESSIONS_VALIDATE_LINK_SHOW = 'SESSIONS_VALIDATE_LINK_SHOW'
export const SESSIONS_VALIDATE_LINK_SHOW_PENDING = 'SESSIONS_VALIDATE_LINK_SHOW_PENDING'
export const SESSIONS_VALIDATE_LINK_SHOW_FULFILLED = 'SESSIONS_VALIDATE_LINK_SHOW_FULFILLED'
export const SESSIONS_VALIDATE_LINK_SHOW_REJECTED = 'SESSIONS_VALIDATE_LINK_SHOW_REJECTED'

export const SESSIONS_REQUEST_NEW_LINK_POT = 'SESSIONS_REQUEST_NEW_LINK_POT'
export const SESSIONS_REQUEST_NEW_LINK_POT_PENDING = 'SESSIONS_REQUEST_NEW_LINK_POT_PENDING'
export const SESSIONS_REQUEST_NEW_LINK_POT_FULFILLED = 'SESSIONS_REQUEST_NEW_LINK_POT_FULFILLED'
export const SESSIONS_REQUEST_NEW_LINK_POT_REJECTED = 'SESSIONS_REQUEST_NEW_LINK_POT_REJECTED'

export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD'
export const REQUEST_CHANGE_PASSWORD_PENDING = 'REQUEST_CHANGE_PASSWORD_PENDING'
export const REQUEST_CHANGE_PASSWORD_FULFILLED = 'REQUEST_CHANGE_PASSWORD_FULFILLED'
export const REQUEST_CHANGE_PASSWORD_REJECTED = 'REQUEST_CHANGE_PASSWORD_REJECTED'
