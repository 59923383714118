import React, { useRef } from 'react'
import { Banner, Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useRequest } from 'components/global/templates/RequestDrawer/RequestProvider'
import { employeeLeaveTypesLoad } from 'redux/actions/employeeLeaveTypesAction'
import { familyDetailsLoad } from 'redux/actions/employeeFamilyDetailActions'
import { isAdvanceVacationSalaryEnabledLoad } from 'redux/setting/leaveManagement/advanceVacationSalaryConfiguration/actionCreators'
import { useDispatch, useSelector } from 'react-redux'
import { useFormState, useForm } from 'react-final-form'
import {
  createLeaveRequest,
  leaveRequestsDetail,
  leaveRequestsReset,
  updateLeaveRequest,
} from 'redux/actions/leaveRequestsAction'
import { enableHalfDayLeaveLoad } from 'redux/actions/leaveManagementConfigurationActions'
import { format } from 'utils/date'
import i18n from 'translations/i18n'
import { useHistory } from 'react-router-dom'
import { omit } from 'lodash'
import {
  LeaveDetails,
  Profile,
  AdvancedVacationSalary,
  Reason,
  NewFlightTicket,
  ExitReEntryVisa,
  AnnualLeave,
} from '../../components/forms'
import {
  LeaveDuration,
  // Balance,
} from '../../components'
import { useEmployeeAttendanceShifts, useRequestDetail } from '../../hooks'

export const formatLeaveRequestSubmissionPayload = (val) => {
  return {
    leave_type_id: val?.leave_type_id,
    from: val?.from,
    ...(!val?.half_day_leave && { to: val?.to }),
    reason: val?.reason,
    is_require_vacation_salary: val?.is_require_vacation_salary,
    is_re_entry_exit: val?.is_re_entry_exit,
    attachments: val?.attachments?.[0]?.data
      ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
      : val?.deletedAttachments ?? null,
    ticket_info_attributes: val?.ticket_info_attributes,
    ...(val?.half_day_leave?.time && {
      half_day_leave: val?.half_day_leave,
    }),
    ...(val?.exit_reentry_info_attributes && {
      exit_reentry_info_attributes: val?.exit_reentry_info_attributes,
    }),
  }
}

const LeaveRequest = () => {
  const { change } = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const messageRef = useRef(null)
  const fetchingEmployee = useSelector(({ employee }) => employee.fetching)
  const {
    fetching: postFetching,
    leave_requests_detail: requests_detail,
    errorMessage,
  } = useSelector(({ leaveRequests }) => leaveRequests)
  const { loading, leave_types } = useSelector(({ employeeLeaveTypes }) => employeeLeaveTypes)
  const is_avs_enabled = useSelector(
    (s) => s.advanceVacationSalaryConfiguration.enabled_configuration?.value,
  )
  const {
    grant_configuration_leave: { value: isHalfDay },
  } = useSelector(({ leaveConfiguration }) => leaveConfiguration)

  const {
    values: {
      leave_type_id,
      from,
      to,
      half_day_leave,
      AnnualLeaveRole,
      is_require_vacation_salary,
    },
  } = useFormState()
  const { fetching } = useEmployeeAttendanceShifts()
  const permission_scopes = useSelector((s) => s?.auth.employee?.permission_scopes)

  const {
    drawerToggle,
    setOnSubmit,
    actionCallback,
    setInitialValues,
    vars,
    editRequest,
    setEditRequest,
    setRequestType,
  } = useRequest()
  const { request } = useRequestDetail()

  const selectedTypeDetail = React.useMemo(
    () => leave_types.find((item) => item.id === leave_type_id) ?? {},
    [leave_types, leave_type_id],
  )

  const isAnnual = selectedTypeDetail.category === 'annual'
  const isHalfLeave = AnnualLeaveRole === 'halfDayLeave'

  const empId = vars.id ?? request?.employee?.id

  const onSubmit = (val) => {
    if (val?.require_flight_ticket) {
      // if the request linked with flight ticket request
      setRequestType('FlightTicketRequest')
      return
    }
    change('disableSubmit', true)
    const data = formatLeaveRequestSubmissionPayload(val)
    if (val.id) {
      dispatch(
        updateLeaveRequest(
          empId,
          {
            ...data,
            attachments: data.attachments?.map((it) => {
              if (typeof it.id === 'string') return omit(it, 'id')
              return it
            }),
          },
          val.id,
        ),
      )
        .then(() => {
          change('disableSubmit', false)
          setEditRequest(false)
        })
        .catch(() => change('disableSubmit', false))
      return
    }
    dispatch(
      createLeaveRequest(vars.id, {
        ...data,
        attachments: data?.attachments?.map((it) => omit(it, 'id')),
      }),
    )
      .then(() => {
        change('disableSubmit', false)
        drawerToggle()
        if (actionCallback) {
          actionCallback()
        }
      })
      .catch(() => change('disableSubmit', false))
  }

  const checkLeaveAvailability = () => {
    if (!leave_type_id || !from || (!to && !half_day_leave?.time)) return false

    const data = {
      leave_type_id,
      is_require_vacation_salary,
      from: format(new Date(from), 'yyyy-MM-dd'),
      ...(half_day_leave ? { half_day_leave } : { to: format(new Date(to), 'yyyy-MM-dd') }),
    }
    return dispatch(leaveRequestsDetail(empId, data))
      .then(() => change('disableSubmit', false))
      .catch(() => change('disableSubmit', true))
  }

  React.useEffect(() => {
    setOnSubmit(onSubmit)

    dispatch(employeeLeaveTypesLoad(empId))
    dispatch(familyDetailsLoad(empId, { template: 'public' }))
    dispatch(isAdvanceVacationSalaryEnabledLoad({ employee_id: empId }))
    dispatch(enableHalfDayLeaveLoad())
    return () => {
      dispatch(leaveRequestsReset())
    }
  }, [])

  React.useEffect(() => {
    if (!editRequest) {
      checkLeaveAvailability()
    }
  }, [leave_type_id, to, half_day_leave?.time, is_require_vacation_salary])

  React.useEffect(() => {
    messageRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messageRef, requests_detail?.overlapped_payrun_id])

  React.useEffect(() => {
    if (!editRequest) change('half_day_leave', null)
    if (isHalfLeave) {
      change('is_require_vacation_salary', false)
      change('is_re_entry_exit', false)
      change('require_ticket', false)
    }
  }, [leave_type_id, from, AnnualLeaveRole])

  React.useEffect(() => {
    if (editRequest) {
      setInitialValues({
        ...request,
        leave_type_id: request?.leave_type?.id,
        ...(request?.is_re_entry_exit && {
          exit_reentry_info_attributes: request?.exit_reentry_info,
        }),
        ...(request?.require_ticket && {
          ticket_info_attributes: {
            ...request?.ticket_info,
            family_detail_ids: request?.ticket_info?.family_details?.map(({ id: fId }) => fId),
          },
        }),
        AnnualLeaveRole: request?.half_day_leave ? 'halfDayLeave' : 'selectRange',
        ...(request?.half_day_leave && { half_day_leave: { time: request?.half_day_leave } }),
        deletedAttachments: [],
      })
    } else {
      setInitialValues({
        ...(vars?.date && { from: vars?.date }),
        require_ticket: false,
        is_re_entry_exit: false,
        is_require_vacation_salary: false,
        exit_reentry_info_attributes: {
          entry_type: 'Single',
          period: 3,
        },
        deletedAttachments: [],
      })
    }
  }, [editRequest])

  const { applied_days, pending_balance, requested_days, deducted_balance, reserved_balance } =
    requests_detail || {}

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {(loading || fetchingEmployee || postFetching || fetching) && <JisrLoader absolute />}
      {requests_detail?.overlapped_payrun_id && from && to && (
        <div ref={messageRef}>
          <Banner
            color="warning"
            variant="tinted"
            leadingIcon="alert-triangle-filled"
            title={i18n.t('overlapped_period')}
            {...(permission_scopes?.view_vacation_settlement && {
              learnMore: i18n.t('view_details_over'),
            })}
            onClickLearnMore={() =>
              history.push(
                `/off_cycle/vacation_settlement/${requests_detail?.overlapped_payrun_id}`,
              )
            }
            description={i18n.t(errorMessage)}
          />
        </div>
      )}
      <Profile employee={vars?.employee} />

      <LeaveDetails
        isAnnual={isAnnual}
        isHalfDay={isHalfDay}
      />

      {isAnnual && isHalfDay && <AnnualLeave />}

      {!(isHalfDay && isAnnual) && (
        <LeaveDuration
          unscheduled_dates={requests_detail?.unscheduled_dates || 0}
          leave_type_id={leave_type_id}
          from={from}
          to={to}
          isAnnual={isAnnual}
          applicable_days={applied_days || 0}
          deducted_balance={deducted_balance || reserved_balance}
          employeeId={empId}
          balanceVals={{
            applied_days,
            pending_balance,
            requested_days,
          }}
        />
      )}

      {isAnnual && is_avs_enabled && (
        <AdvancedVacationSalary
          {...(isHalfLeave && {
            disabled: true,
            disableMessage: i18n.t('you_cannot_request_type_for_half_leave', {
              type: i18n.t('advanced_vacation_salary_v2').toLowerCase(),
            }),
          })}
        />
      )}

      {!editRequest && (
        <NewFlightTicket
          {...(isHalfLeave && {
            disableMessage: i18n.t('you_cannot_request_type_for_half_leave', {
              type: i18n.t('flight_ticket').toLowerCase(),
            }),
          })}
          from_request="LeaveRequest"
        />
      )}

      <ExitReEntryVisa
        {...(isHalfLeave && {
          disabled: true,
          disableMessage: i18n.t('you_cannot_request_type_for_half_leave', {
            type: i18n.t('exit_re_entry_visa').toLowerCase(),
          }),
        })}
        withEmployeeDetail
      />

      <Reason minAttachments={selectedTypeDetail?.leave_type?.is_attachment_required ? 1 : 0} />
    </Flex>
  )
}

export default LeaveRequest
