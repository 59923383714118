export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_PENDING =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_PENDING'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_FULFILLED'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_LOAD_REJECTED'

export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_PENDING'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_FULFILLED'
export const ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED =
  'ONBOARDING_IS_ADVANCE_VACATION_SALARY_ENABLED_UPDATE_REJECTED'

export const ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD = 'ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_PENDING =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_PENDING'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_FULFILLED'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_REJECTED =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_LOAD_REJECTED'

export const ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_PENDING =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_PENDING'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_FULFILLED'
export const ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED =
  'ONBOARDING_MIN_VACATION_SALARY_DAYS_UPDATE_REJECTED'
