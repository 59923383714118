import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as DSCheckbox } from '@jisr-hr/ds'

const Checkbox = ({ label, onChange, checked, disabled, testId }) => (
  <div
    data-testid={`${testId}-container`}
    className="flex items-center jisr-checkbox-input"
  >
    <DSCheckbox
      testId={testId}
      active={checked}
      onChange={onChange}
      label={label}
      disabled={disabled}
    />
  </div>
)

Checkbox.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Checkbox
