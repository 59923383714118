import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType, OnboardingAdminType } from './type'

const initialState: InitialStateType = {
  fetching: false,
  first_fetching: false,
  onboarding_admins: [],
  completed_data: true,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.ONBOARDING_ADMINS_LIST_PENDING:
    case actions.ONBOARDING_ADMINS_DELETE_PENDING:
    case actions.ONBOARDING_ADMINS_INVITE_POST_PENDING:
    case actions.ONBOARDING_ADMINS_UNDO_INVITE_PUT_PENDING:
    case actions.ONBOARDING_VERIFY_ADMINS_LIST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_ADMINS_LIST_FULFILLED:
      const onboarding_admins: OnboardingAdminType[] = action.payload.data.data?.onboarding_admins
      return {
        ...state,
        fetching: false,
        first_fetching: true,
        onboarding_admins: onboarding_admins?.map((admin) => ({
          ...admin,
          isDisabled: !!state.onboarding_admins.find((i) => i.id === admin.id)?.isDisabled,
        })),
      }

    case actions.COMPLETE_ONBOARDING_VERIFY_ADMINS_LIST:
      return {
        ...state,
        completed_data: true,
      }

    case actions.ONBOARDING_VERIFY_ADMINS_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        completed_data: action.payload.data.data?.completed_data,
      }

    case actions.ONBOARDING_ADMINS_DELETE_FULFILLED:
    case actions.ONBOARDING_ADMINS_INVITE_POST_FULFILLED:
    case actions.ONBOARDING_ADMINS_UNDO_INVITE_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ADMINS_LIST_REJECTED:
    case actions.ONBOARDING_ADMINS_DELETE_REJECTED:
    case actions.ONBOARDING_ADMINS_INVITE_POST_REJECTED:
    case actions.ONBOARDING_ADMINS_UNDO_INVITE_PUT_REJECTED:
    case actions.ONBOARDING_VERIFY_ADMINS_LIST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.DELETE_USER_LOCALY:
      const s = state.onboarding_admins
      return {
        ...state,
        onboarding_admins: s.map((i) =>
          i.id === action.payload.id ? { ...i, isDisabled: !i.isDisabled } : i,
        ),
      }

    case actions.CHANGE_ANY_USER_DELETED_TO_FALSE:
      return {
        ...state,
        onboarding_admins: state.onboarding_admins.map((i) => ({ ...i, isDisabled: false })),
      }

    default:
      return state
  }
}
