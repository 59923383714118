export const ONBOARDING_BANK_ACCOUNTS_CREATE = 'ONBOARDING_BANK_ACCOUNTS_CREATE'
export const ONBOARDING_BANK_ACCOUNTS_CREATE_PENDING = 'ONBOARDING_BANK_ACCOUNTS_CREATE_PENDING'
export const ONBOARDING_BANK_ACCOUNTS_CREATE_FULFILLED = 'ONBOARDING_BANK_ACCOUNTS_CREATE_FULFILLED'
export const ONBOARDING_BANK_ACCOUNTS_CREATE_REJECTED = 'ONBOARDING_BANK_ACCOUNTS_CREATE_REJECTED'

export const ONBOARDING_BANK_ACCOUNTS_DELETE = 'ONBOARDING_BANK_ACCOUNTS_DELETE'
export const ONBOARDING_BANK_ACCOUNTS_DELETE_PENDING = 'ONBOARDING_BANK_ACCOUNTS_DELETE_PENDING'
export const ONBOARDING_BANK_ACCOUNTS_DELETE_FULFILLED = 'ONBOARDING_BANK_ACCOUNTS_DELETE_FULFILLED'
export const ONBOARDING_BANK_ACCOUNTS_DELETE_REJECTED = 'ONBOARDING_BANK_ACCOUNTS_DELETE_REJECTED'

export const ONBOARDING_BANK_ACCOUNTS_UPDATE = 'ONBOARDING_BANK_ACCOUNTS_UPDATE'
export const ONBOARDING_BANK_ACCOUNTS_UPDATE_PENDING = 'ONBOARDING_BANK_ACCOUNTS_UPDATE_PENDING'
export const ONBOARDING_BANK_ACCOUNTS_UPDATE_FULFILLED = 'ONBOARDING_BANK_ACCOUNTS_UPDATE_FULFILLED'
export const ONBOARDING_BANK_ACCOUNTS_UPDATE_REJECTED = 'ONBOARDING_BANK_ACCOUNTS_UPDATE_REJECTED'
