import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type LoansReport = {
  header: { key: string; label: string; sort: string | null }[]
  records: string[][]
  total: string[]
}

type InitialStateType = {
  fetching: boolean
  report: LoansReport | null
}

export const initialState: InitialStateType = {
  fetching: false,
  report: null,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.LOANS_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.LOANS_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        report: action.payload.data.data as InitialStateType['report'],
        fetching: false,
      }
    }

    case actions.LOANS_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
