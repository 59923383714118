import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function assetTypesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/asset_types/`
  const method = 'GET'

  return {
    type: 'ASSET_TYPES_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function assetTypeLoad(id) {
  const url = `/${API_VERSION_TWO}/asset_types/${id}/`
  const method = 'GET'

  return {
    type: 'ASSET_TYPE_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function assetTypeCreate(data) {
  const url = `/${API_VERSION_TWO}/asset_types/`
  const method = 'POST'
  return {
    type: 'ASSET_TYPE_CREATE',
    payload: handleAPI(url, {}, method, { asset_type: data }),
  }
}

export function assetTypeUpdate(data) {
  const url = `/${API_VERSION_TWO}/asset_types/${data.id}/`
  const method = 'PUT'
  return {
    type: 'ASSET_TYPE_UPDATE',
    payload: handleAPI(url, {}, method, { asset_type: data }),
  }
}

export function assetTypeDelete(id) {
  const url = `/${API_VERSION_TWO}/asset_types/${id}/`
  const method = 'DELETE'

  return {
    type: 'ASSET_TYPE_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, {}, method)
        .then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        })
    }),
  }
}

export function assetTypeEdit(id) {
  return {
    type: 'ASSET_TYPE_EDIT',
    id,
  }
}

export function assetTypeEditCancel(id) {
  return {
    type: 'ASSET_TYPE_EDIT_CANCEL',
    id,
  }
}
