import { connect } from 'react-redux'

import PrintLetterTemplate from './PrintLetterTemplate'

import './style.css'

const mapStateToProps = ({ letterTemplates: { printTemplate, fetching } }) => ({
  ...printTemplate,
  fetching,
})

export default connect(mapStateToProps)(PrintLetterTemplate)
