import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function listCountries(params = {}) {
  const url = `/${API_VERSION_TWO}/hr/countries`
  return {
    type: actions.HR_COUNTRIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function countriesLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/organizations/countries`
  return {
    type: actions.COUNTRIES_v2_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function listRegions(id, params = {}) {
  const url = `/${API_VERSION_TWO}/hr/countries/${id}/regions`
  return {
    type: actions.REGIONS_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function listCities(id, params = {}) {
  const url = `/${API_VERSION_TWO}/hr/regions/${id}/cities`
  return {
    type: actions.CITIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function getCountryCities(countryId, params = {}) {
  const url = `/${API_VERSION_TWO}/hr/countries/${countryId}/city_list`
  return {
    type: actions.COUNTRY_CITIES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}

export function locationsLoad(params = {}) {
  const url = `/${API_VERSION_TWO}/locations/`
  const method = 'GET'

  return {
    type: actions.LOCATIONS_LOAD,
    payload: handleAPI(url, params, method),
  }
}

export function areaUpdate(data) {
  const url = `/${API_VERSION_TWO}/areas/${data.id}/`
  return {
    type: actions.AREA_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { area: data }),
  }
}

export function locationUpdate(data) {
  const url = `/${API_VERSION_TWO}/locations/${data.id}/`
  return {
    type: actions.LOCATION_UPDATE,
    payload: handleAPI(url, {}, 'PUT', { location: data }),
  }
}

export function areasLoad() {
  const url = `/${API_VERSION_TWO}/areas/`
  const method = 'GET'

  return {
    type: actions.AREAS_LOAD,
    payload: handleAPI(url, {}, method),
  }
}

export function openArea(data) {
  return {
    type: actions.OPEN_AREA,
    payload: { data },
  }
}

export function openLocation(data) {
  return {
    type: actions.OPEN_LOCATION,
    payload: { data },
  }
}

export function areaBulkCreate(data) {
  const url = `/${API_VERSION_TWO}/areas/bulk_create`
  const method = 'POST'
  return {
    type: actions.AREA_BULK_CREATE,
    payload: handleAPI(url, {}, method, { area: data }),
  }
}

export function locationBulkCreate(data) {
  const url = `/${API_VERSION_TWO}/locations/bulk_create`
  const method = 'POST'
  return {
    type: actions.LOCATION_BULK_CREATE,
    payload: handleAPI(url, {}, method, { location: data }),
  }
}

export function locationCreate(data) {
  const url = `/${API_VERSION_TWO}/locations/`
  const method = 'POST'
  return {
    type: actions.LOCATION_CREATE,
    payload: handleAPI(url, {}, method, { location: data }),
  }
}

export function assignLocationsEmployees(location) {
  const url = `/${API_VERSION_TWO}/locations/assign_employees`

  return {
    type: actions.ASSIGN_LOCATIONS_EMPLOYEES,
    payload: handleAPI(url, {}, 'PUT', { location }),
  }
}

export function countryDelete(id) {
  const url = `/${API_VERSION_TWO}/organizations/countries/${id}`

  return {
    type: actions.COUNTRY_DELETE,
    payload: handleAPI(url, {}, 'DELETE'),
  }
}

export function deleteArea(id) {
  return {
    type: actions.DELETE_AREA,
    payload: { id },
  }
}

export function deleteLocation(id) {
  return {
    type: actions.DELETE_LOCATION,
    payload: { id },
  }
}
