import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'

export function createExitReentryVisaRequests(employee_id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/exit_reentry_visa_requests`
  const method = 'POST'
  return {
    type: actions.CREATE_EXIT_REENTRY_VISA_REQUEST,
    payload: handleAPI(url, {}, method, { exit_reentry_visa_request: data }),
  }
}

export function updateExitReentryVisaRequests(employee_id, id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/exit_reentry_visa_requests/${id}`
  const method = 'PUT'
  return {
    type: actions.UPDATE_EXIT_REENTRY_VISA_REQUEST,
    payload: handleAPI(url, {}, method, { exit_reentry_visa_request: data }),
  }
}

export function ExitReentryVisaRequestLoad(employee_id, req_id) {
  const url = `/${API_VERSION_TWO}/finance/employees/${employee_id}/exit_reentry_visa_requests/${req_id}`
  const method = 'GET'
  return {
    type: actions.EXIT_REENTRY_VISA_REQUEST_LOAD,
    payload: handleAPI(url, {}, method),
  }
}
