import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { addMonths } from 'date-fns'
import { format } from 'utils/date'
import { getMonthlyDeduction } from 'utils/string'
import { Typography, Spacer } from '@jisr-hr/ds'
import { SelectField, BetaTextInputField as TextInputField } from 'components/final-form'
import { useFormState, useForm } from 'react-final-form'
import { floatNumber } from 'components/global/form/formNormalize'
import { required, composeValidators, number } from 'components/global/form/FormValidations'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loanTypesLoad } from 'redux/actions/loanTypeActions'
import I18n from 'translations/i18n'
import Frame from '../../../ds/Frame'

const dFormat = 'MMMM, yyyy'

const getDateString = (months, startDate, endDate) =>
  months === 1
    ? `${startDate} (${months} ${I18n.t('month')})`
    : `${startDate} - ${endDate} (${months} ${I18n.t('months')})`

const monthlyDeductionSpecifiedData = (issueDate, amount, deduction) => {
  const newDeduction = Math.min(amount, deduction)
  const date = new Date(issueDate)
  const startDate = format(date, dFormat)
  const months = Math.ceil(amount / newDeduction)
  const endDate = format(addMonths(date, months - 1), dFormat)
  return { months, deduction: newDeduction, dates: getDateString(months, startDate, endDate) }
}
const noOfMonthsSpecifiedData = (issueDate, amount, months) => {
  const date = new Date(issueDate)
  const startDate = format(date, dFormat)
  const deduction = getMonthlyDeduction(amount, months)
  const endDate = format(addMonths(date, months - 1), dFormat)
  return { months, deduction, dates: getDateString(months, startDate, endDate) }
}

const InstallmentCalculation = ({ stopReloadTypes }) => {
  const dispatch = useDispatch()
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency?.currency_type_i18n,
  )
  const options = [
    {
      label: I18n.t('specified_monthly_amount'),
      key: 'monthly_deduction',
      value: 'monthly_deduction',
    },
    { label: I18n.t('specified_number_of_months'), key: 'no_of_months', value: 'no_of_months' },
  ]
  const {
    values: { installment_type, amount, no_of_months, monthly_deduction, issue_date },
  } = useFormState()
  const isMonthsSpecified = installment_type === 'no_of_months'
  const showCalculations = amount && issue_date && (no_of_months || monthly_deduction)
  let months = no_of_months
  let deduction = monthly_deduction
  let dates = ''
  if (showCalculations) {
    ;({ months, deduction, dates } = isMonthsSpecified
      ? noOfMonthsSpecifiedData(issue_date, amount, months)
      : monthlyDeductionSpecifiedData(issue_date, amount, monthly_deduction))
  }
  const form = useForm()
  const { change } = form
  let fieldName = 'monthly_deduction'
  let suffix = currency
  if (isMonthsSpecified) {
    fieldName = 'no_of_months'
    suffix = I18n.t('months')
  }

  const onInstallmentTypeChange = () => {
    change('no_of_months', '')
    change('monthly_deduction', '')
  }
  React.useEffect(() => {
    if (!stopReloadTypes) {
      dispatch(
        loanTypesLoad({
          filters: {
            status: 'active',
          },
        }),
      )
    }
  }, [])

  return (
    <Frame title={I18n.t('installment_calculation')}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={8}
        >
          <SelectField
            name="installment_type"
            options={options}
            onChange={onInstallmentTypeChange}
            validate={required}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextInputField
            suffix={suffix}
            data-private
            name={fieldName}
            validate={composeValidators(required, number)}
            parse={floatNumber}
            testId="loan-monthly-deduction"
          />
        </Grid>
      </Grid>
      {showCalculations && (
        <>
          <Spacer height={16} />
          <div>
            <Typography
              text={`${currency} ${deduction}/${I18n.t('month')} `}
              style={{ color: 'var(--color-base-colors-grey-600)' }}
              variant="heading"
            />
            <Typography
              text={dates}
              style={{ color: 'var(--color-base-colors-grey-600)' }}
              variant="body-new/regular"
            />
          </div>
        </>
      )}
    </Frame>
  )
}

InstallmentCalculation.propTypes = {
  stopReloadTypes: PropTypes.bool,
}

export default InstallmentCalculation
