import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const DataTextField = ({ title, text, style }) => (
  <div style={style}>
    <Typography
      text={title}
      variant="subtitle-2"
      style={{ color: 'var(--color-base-colors-black-600)' }}
    />
    <Typography
      text={text}
      variant="body-new/regular"
      style={{ color: 'var(--color-base-colors-black-600)' }}
    />
  </div>
)

DataTextField.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape(),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
}

export default DataTextField
