import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type RecurrenceProps = {
  filterKey: string
  filters: Array<string>
}

const Recurrence = (props: RecurrenceProps): JSX.Element => {
  const { frequency } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={frequency}
      selectedIds={filter[props.filterKey]}
      {...props}
      title="label.recurrence"
    />
  )
}

export default Recurrence
