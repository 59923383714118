import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'

import FilterBox from '../FilterBox'
import { useFilters } from './context'

type TimesheetAssignedReviewerProps = {
  title: string
  filterKey: string
  extra_title: string
  filters: string[]
}

const TimesheetAssignedReviewer = (props: TimesheetAssignedReviewerProps) => {
  const { filter, setFilter } = useFilters()

  const employees = useSelector(({ employee }) => employee?.employee_all ?? [], shallowEqual)

  const onHandleFilter = (_: string, id: string | number) => {
    let ids = [...filter.timesheet_reviewer_ids, id]

    const isIncluded = filter.timesheet_reviewer_ids.includes(id)

    if (isIncluded) {
      ids = filter.timesheet_reviewer_ids.filter((item: string | number) => item !== id)
    }

    setFilter({ ...filter, timesheet_reviewer_ids: ids })
  }

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      valueKey="id"
      labelKey="name"
      options={[{ id: 'unassign', name: i18n.t('unassigned') }, ...employees]}
      selectedIds={filter.timesheet_reviewer_ids}
      {...props}
      title={i18n.t('reviewer')}
    />
  )
}

export default TimesheetAssignedReviewer
