import React from 'react'
import { Tooltip } from '@jisr-hr/ds-beta'
import { Flex, FeaturedIcon, Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import { getRequestTranslationKey, isRtl } from 'utils/uiHelpers'
import { LazyAvatar } from 'components/global/atoms'
import stoc from 'string-to-color'
import { EmployeeJobIdAvatar } from 'components/global/molecules'
import { ToolTipHandler } from 'components/authorised/Performace'
import { BaseBadge } from '../Overview/components/components/Badges'

export const statusBadge = (state) => {
  const badge = {
    pending: (
      <BaseBadge
        color="yellow"
        label={i18n.t('pending')}
      />
    ),
    pending_for_correction: (
      <BaseBadge
        color="yellow"
        label={i18n.t('status.pending_correction')}
      />
    ),
    approved: (
      <ToolTipHandler
        isShown={state.isAutomated}
        title={i18n.t('approved_by_automation')}
      >
        <BaseBadge
          color="green"
          label={i18n.t('approved_request')}
          {...(state.isAutomated && { trailingIcon: 'lightning-01' })}
        />
      </ToolTipHandler>
    ),
    completed: (
      <BaseBadge
        color="green"
        label={i18n.t('completed')}
      />
    ),
    rejected: (
      <ToolTipHandler
        isShown={state.isAutomated}
        title={i18n.t('rejected_by_automation')}
      >
        <BaseBadge
          color="red"
          label={i18n.t('rejected_request')}
          {...(state.isAutomated && { trailingIcon: 'lightning-01' })}
        />
      </ToolTipHandler>
    ),
    cancelled: (
      <BaseBadge
        color="gray"
        label={i18n.t('cancelled_request')}
      />
    ),
    expired: (
      <BaseBadge
        color="gray"
        label={i18n.t('expired_request')}
      />
    ),
  }
  return badge[state.key]
}

export const taskStatusBadge = (state) => {
  const badge = {
    Pending: (
      <BaseBadge
        color="yellow"
        label={i18n.t('pending')}
      />
    ),
    Completed: (
      <BaseBadge
        color="green"
        label={i18n.t('completed')}
      />
    ),
    Cancelled: (
      <BaseBadge
        color="gray"
        label={i18n.t('cancelled')}
      />
    ),
    Rejected: (
      <BaseBadge
        color="red"
        label={i18n.t('rejected')}
      />
    ),
  }
  return badge[state.key]
}

export const taskStatusTypes = [
  {
    label: i18n.t('pending'),
    key: 'Pending',
    filterType: 'status',
    color: 'red',
  },
  {
    label: i18n.t('completed'),
    key: 'Completed',
    filterType: 'status',
    color: 'green',
  },
  {
    label: i18n.t('cancelled'),
    key: 'Cancelled',
    filterType: 'status',
    color: 'gray',
  },
]

export const statusTypes = [
  {
    label: i18n.t('pending'),
    key: 'Pending',
    color: 'red',
    filterType: 'status',
  },
  {
    label: i18n.t('approved'),
    key: 'Approved',
    filterType: 'status',
    color: 'green',
  },
  {
    label: i18n.t('rejected'),
    key: 'Rejected',
    filterType: 'status',
    color: 'red',
  },
  {
    label: i18n.t('expired'),
    key: 'Expired',
    filterType: 'status',
    color: 'gray',
  },
  {
    label: i18n.t('cancelled'),
    key: 'Cancelled',
    filterType: 'status',
    color: 'red',
  },
]

const requestNames = [
  'ExcuseRequest',
  'ExcuseCancellationRequest',
  'OvertimeRequest',
  'OvertimeCancellationRequest',
  'MissingPunchRequest',
  'MissingPunchCancellationRequest',
  'LeaveRequest',
  'LeaveCancellationRequest',
  'LeaveResumptionRequest',
  'LeaveResumptionCancellationRequest',
  'AssetRequest',
  'AssetCancellationRequest',
  'AssetClearRequest',
  'AssetClearCancellationRequest',
  'ResignationRequest',
  'ResignationCancellationRequest',
  'ExitReentryVisaRequest',
  'ExitReentryVisaCancellationRequest',
  'HiringRequest',
  'HiringCancellationRequest',
  'BusinessTripRequest',
  'BusinessTripCancellationRequest',
  'LetterRequest',
  'DocumentRequest',
  'InfoChangeRequest',
  'DelegationRequest',
  'LoanRequest',
  'LoanCancellationRequest',
  'ExpenseClaimRequest',
  'ExpenseClaimCancellationRequest',
]

export const requestsTypes = requestNames
  .sort((a, b) => a.localeCompare(b))
  .map((name) => ({
    key: name,
    label: i18n.t(getRequestTranslationKey(name)),
  }))

const tastKeys = [
  'loan_payment_task',
  'payroll_payment_task',
  'employee_gosi_registration_task',
  'gosi_salary_updation_task',
  'expense_claim_payment_task',
  'advance_vacation_salary_payment_task',
  'final_settlement_payment_task',
  'exit_reentry_payment_task',
  'ticket_payment_task',
  'issuing_ticket_task',
  'business_trip_payment_task',
  'letter_issuing_task',
  'asset_allocation_task',
  'asset_deallocation_task',
  'hiring_request_task',
]

export const taskTypes = tastKeys
  .sort((a, b) => a.localeCompare(b))
  .map((key) => ({
    key,
    label: i18n.t(key),
  }))

export const pendingOnOptions = [
  { label: i18n.t('from_you'), key: 'self' },
  { label: i18n.t('from_other_approvals'), key: 'others' },
]
export const pendingOnTasks = [
  { label: i18n.t('you'), key: 'self' },
  { label: i18n.t('others_2'), key: 'others' },
]

export const request_types = ['finance_filter', 'attendance', 'others', 'deleted_requests']

export const tasks_types = ['tasks']

export const formatDateRequestOn = (employeeDate, receivedAt) => {
  if (!receivedAt) {
    return null
  }

  const dateReceivedAt = new Date(receivedAt)
  if (format(employeeDate, 'P') === format(dateReceivedAt, 'P')) {
    return `${i18n.t('today')}`
  }
  if (employeeDate?.getFullYear() === dateReceivedAt.getFullYear()) {
    return format(dateReceivedAt, `${isRtl ? 'EEEE' : 'EEE'} dd MMMM`)
  }
  return format(dateReceivedAt, 'EEE dd MMMM yyyy')
}

export const renderTaskEmpAvatar = (data) => {
  if (data.is_created_by_automation) {
    if (!data.employee) {
      return (
        <Flex
          itemsCenter
          style={{ gap: 12 }}
        >
          <Tooltip
            description={i18n.t('created_by_automation')}
            placement="top"
            trigger={
              <div>
                <FeaturedIcon
                  iconName="lightning-01"
                  colorType="brand"
                  size="xs"
                />
              </div>
            }
          />
          <Typography
            variant="body-new/regular"
            text={i18n.t('automated')}
          />
        </Flex>
      )
    }

    let additionalInfo = ''
    if (data.employee?.job_title) additionalInfo = data.employee?.job_title
    if (data.employee?.job_title && data.employee?.code) additionalInfo += ' - '
    if (data.employee?.code) additionalInfo += data.employee?.code

    return (
      <Flex
        itemsCenter
        style={{ gap: 12 }}
      >
        <div style={{ position: 'relative' }}>
          <LazyAvatar
            containerStyle={{ width: 34, height: 34 }}
            style={{
              width: 34,
              height: 34,
              backgroundColor: stoc(data.employee?.name),
            }}
            src={data.employee?.avatar_thumb}
            alt={data.employee?.name}
          />
          <Tooltip
            description={i18n.t('created_by_automation')}
            placement="top"
            trigger={
              <div style={{ position: 'absolute', insetInlineEnd: -4, bottom: -4 }}>
                <FeaturedIcon
                  iconName="lightning-01"
                  colorType="brand"
                  size="xs"
                />
              </div>
            }
          />
        </div>
        <div>
          <Typography
            variant="body-new/regular"
            text={data.employee?.name}
          />
          <Typography
            variant="caption"
            textColor="color/fg/lighter"
            text={additionalInfo}
          />
        </div>
      </Flex>
    )
  }

  return (
    <EmployeeJobIdAvatar
      id={data.employee?.code}
      name={data.employee?.name}
      avatar={data.employee?.avatar_thumb}
      jobTitle={data.employee?.job_title}
    />
  )
}
