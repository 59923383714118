import React from 'react'
import { Typography, Flex, Containers } from '@jisr-hr/ds'
import PropTypes from 'prop-types'

import { ReactComponent as DownArrowIcon } from './icons/down-arrow.svg'

import Styles from './Frame.module.css'

const Frame = ({
  children,
  header,
  title,
  icon,
  description,
  titleSuffix,
  collapse,
  showHeaderLine,
  active,
  style,
}) => {
  const [isOpen, setIsOpen] = React.useState(active)
  const childRef = React.useRef()

  const openToggle = () => {
    setIsOpen(!isOpen)
  }

  const showSuffix = collapse || !!titleSuffix
  const showLine = typeof showHeaderLine === 'boolean' ? showHeaderLine : showSuffix

  const handleValidChild = () => {
    if (Array.isArray(children)) {
      let isValidArray = false
      // eslint-disable-next-line array-callback-return
      children.map((child) => {
        if (child) {
          isValidArray = true
        }
      })

      return isValidArray
    }

    if (!children) return false

    return true
  }

  React.useEffect(() => {
    if (active !== isOpen) {
      setIsOpen(active)
    }
  }, [active])

  React.useEffect(() => {
    if (active !== isOpen) {
      setIsOpen(active)
    }
  }, [active])

  const isValidChild = handleValidChild()

  return (
    <Containers
      data-open={isOpen}
      color="white"
      border
      borderRadius="md"
      zIndex={1}
    >
      {(header || title) && (
        <div
          data-underline={showLine}
          className={Styles.header}
        >
          <Flex
            justifyBetween
            itemsCenter
          >
            {!header && title !== 'Information changes' && (
              <Flex
                itemsCenter
                style={{ gap: '10px' }}
              >
                {icon && icon}
                <Typography
                  text={title}
                  variant="subheading"
                />
              </Flex>
            )}

            {showSuffix && (
              <div className={Styles.titleSuffix}>
                {collapse && (
                  <DownArrowIcon
                    className={Styles.suffixArrow}
                    onClick={openToggle}
                  />
                )}

                {titleSuffix}
              </div>
            )}
          </Flex>

          {!header && description && (
            <Typography
              text={description}
              variant="body-new/regular"
              style={{ color: 'var(--color-base-colors-black-600)' }}
            />
          )}

          {header && header}
        </div>
      )}

      {children && isValidChild && (
        <div
          className={style || Styles.content}
          ref={childRef}
        >
          {children}
        </div>
      )}
    </Containers>
  )
}

Frame.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.node,
  description: PropTypes.string,
  collapse: PropTypes.bool,
  active: PropTypes.bool,
  showHeaderLine: PropTypes.bool,
  titleSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.string,
}

Frame.defaultProps = {
  active: true,
}

export default Frame
