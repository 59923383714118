import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export type EmployeeExcludedStateT = {
  avatar: string
  code: string
  id: string
  job_title_i18n: string
  name_i18n: string
  reason_text: string
  remarks: {
    date: Date
    from: Date
    joining_date: Date
    to: Date
  }
  type: number
  type_i18n: string
  type_text: string
}

type StateType = {
  count: number
  employees: EmployeeExcludedStateT[]
  exclusion_types: {
    count: number
    type: number
    type_i18n: string
    type_text: string
  }[]
  fetching: boolean
}

const initialState: StateType = {
  fetching: false,
  count: 0,
  employees: [],
  exclusion_types: [],
}

export default function reducer(state = initialState, action: Action): StateType {
  switch (action.type) {
    case actions.PAYRUN_LOAD_EXCLUDED_EMPLOYEES_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYRUN_LOAD_EXCLUDED_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.PAYRUN_LOAD_EXCLUDED_EMPLOYEES_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    default:
      return state
  }
}
