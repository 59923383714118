const Tawuniya = {
  // A
  add_new_policy: 'إضافة وثيقة جديدة',

  // C
  connect_your_tawuniya_account: 'ربط حساب التعاونية',
  connect_your_policies: 'قم بربط وثائقك',

  // D
  delete_policy: 'حذف الوثيقة',
  delete_policy_text: 'هل أنت متأكد من حذف هذه الوثيقة “<bold>{{name}}</bold>”',

  // H
  how_to_connect_tawuniya_to_jisr: 'خطوات تفعيل الربط ما بين التعاونية و {{app_name}}',

  // P
  policy_num: 'رقم الوثيقة',

  // T
  tawuniya: 'التعاونية',
  tawuniya_info_note: 'يمكنك معرفة المزيد حول الربط مع التعاونية من خلال هذا الرابط.',
  tawuniya_integration_card_note: 'أمن في دقائق!',
  tawuniya_permission_text:
    'من خلال ربط حسابك في التعاونية مع {{app_name}}, أنت تسمح {{app_name}} بالقيام بالعمليات التالية:',
  tawuniya_view_action: 'رؤية الطلبات السابقة',
  tawuniya_add_action: ' إنشاء طلبات للتأمين',
  tawuniya_sharing_data_confirmation_msg:
    'مع تفعيل الربط، توافق على مشاركة بيانات وثيقتك مع شركة {{app_name}}',
  // Tawuniya Legal Notice content start
  tawuniya_legal_notice_acceptance_title:
    'إشعار من التعاونية بخصوص مسؤوليات المتحكم بالبيانات واتفاقية معالجة البيانات',
  tawuniya_legal_notice_acceptance_accept_title:
    'أوافق على إشعار من التعاونية بخصوص مسؤوليات المتحكم بالبيانات واتفاقية معالجة البيانات',
  tawuniya_legal_notice_acceptance_paragraph1: 'عزيزي عميل التعاونية،',
  tawuniya_legal_notice_acceptance_paragraph2:
    'يسر التعاونية أن توفر لك إمكانية الوصول إلى خدمات الإدارة الصحية الشاملة المقدمة بالتعاون مع جسر. من المهم ملاحظة أنه من خلال استخدام هذه الخدمات، كعميل لدينا، يتم تعريفك كمتحكم للبيانات بموجب قانون حماية البيانات الشخصية (PDPL) والتشريعات الأخرى ذات الصلة بحماية البيانات مثل اللائحة العامة لحماية البيانات (GDPR).',
  tawuniya_legal_notice_acceptance_paragraph3:
    'باعتبارك متحكم بالبيانات، فإنك تلعب دورًا شديد الأهمية في ضمان المعالجة القانونية للبيانات الشخصية لموظفيك. ويشمل ذلك، على سبيل المثال لا الحصر، إضافة الأعضاء وحذفهم وتعديلاتهم الأخرى المتعلقة بخدماتهم الصحية التي تتم إدارتها من خلال منصة جسر التي تقدمها شركة التعاونية للتأمين.',
  tawuniya_legal_notice_acceptance_paragraph4:
    'من خلال قبول واستخدام خدماتنا لإدارة الصحة عبر الموقع اللإلكتروني، فإنك تقر وتتحمل مسؤولية الحصول على الموافقة اللازمة من موظفيك لمعالجة بياناتهم الشخصية بشكل قانوني. يعد هذا الإقرار متطلبًا أساسيًا يسمح لشركة التعاونية للتأمين، بصفتها معالج البيانات، لدعم المعالجة القانونية للبيانات الشخصية لموظفيك وفقًا للوائح PDPL/GDPR.',
  tawuniya_legal_notice_acceptance_paragraph5:
    'امتثالاً لمتطلبات PDPL/GDPR، من الضروري أن يتم تنفيذ اتفاقية معالجة البيانات بينك بصفتك المتحكم بالبيانات وشركة التعاونية للتأمين، بصفتها معالج البيانات. تُضفي هذه الاتفاقية طابعًا رسميًا على الالتزامات والمسؤوليات وشروط معالجة البيانات بين الطرفين، مما يضمن إجراء معالجة البيانات الشخصية بطريقة متوافقة وآمنة.',
  tawuniya_legal_notice_acceptance_paragraph6:
    'تقع على عاتقك، بصفتك متحكم لبيانات، مسؤولية التأكد من أن البيانات الشخصية التي تتم مشاركتها مع شركة التعاونية للتأمين من خلال منصة جسر يتم الحصول عليها ومعالجتها بشكل قانوني. يعد التزامك بالحصول على الموافقة المناسبة من موظفيك لمعالجة بياناتهم الشخصية أمرًا ضروريًا للامتثال للوائح PDPL/GDPR',
  tawuniya_legal_notice_acceptance_paragraph7:
    'تحرص شركة التعاونية للتأمين على الحفاظ على أعلى معايير خصوصية البيانات وأمنها. نحن ملتزمون بدعمكم في هذه المسؤولية المتبادلة من خلال توفير منصة آمنة ومتوافقة من خلال جسر لإدارة الخدمات الصحية لموظفيكم.',
  tawuniya_legal_notice_acceptance_paragraph8:
    'إذا كانت لديك أي أسئلة أو كنت بحاجة إلى مزيد من المعلومات فيما يتعلق بدورك كمتحكم بالبيانات، أو ضرورة وجود اتفاقية معالجة البيانات، فلا تتردد في التواصل مع   Privacy@tawuniya.com',
  tawuniya_legal_notice_acceptance_paragraph9: 'نشكرك على التزامك بخصوصية البيانات والإمتثال لها .',
  // Tawuniya Legal Notice content end
  // U
  username_or_policy_no: 'اسم المستخدم أو رقم الوثيقة',
}

export default Tawuniya
