import { FC, useMemo, useState } from 'react'
import { Button } from '@jisr-hr/ds'
import { ScrollbarContainer, SideDrawer } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { useDispatch, useSelector } from 'utils/hooks'
import { shallowEqual } from 'react-redux'
import { BankPayload, OrgBankAccountType } from 'types/PaymentMethods/banks'
import { organizationBanksCreate } from 'redux/setting/payrollManagement/organizationBanks/actionsCreator'
import { PaymentConfigurationType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import {
  paymentMethodsLoad,
  updateSinglePaymentOfList,
} from 'redux/setting/payrollManagement/paymentMethods/actionCreators'
import PaymentAccountsList from './PaymentAccountsList'
import { usePaymentMethod } from '../../components/usePaymentMethods'
import AddAccountForm from '../../components/AddAccountForm'

type ResType = { value: { data: { data: { bank_account: OrgBankAccountType } } } }

const ManagePaymentBanks: FC = () => {
  const dispatch = useDispatch()
  const paymentCtx = usePaymentMethod()
  const { selectedPaymentMethod, openMangeAccount } = paymentCtx

  const linked_list = useSelector((s) => s.paymentMethods.configured_payment_methods, shallowEqual)

  const [openForm, setOpenForm] = useState(false)

  const originalData = useMemo(
    () => linked_list.find((item) => item.id === selectedPaymentMethod?.id),
    [selectedPaymentMethod?.id, linked_list],
  )

  const handleBankAdd = async (data: BankPayload): Promise<void> => {
    if (!selectedPaymentMethod) return
    const res = (await dispatch(
      organizationBanksCreate({
        commercial_registration_id: selectedPaymentMethod.id,
        iban: `SA${data.iban}`,
      }),
    )) as unknown as ResType

    const { bank_account } = res.value.data.data
    const account: PaymentConfigurationType['bank_accounts'][0] = {
      ...bank_account,
      authorization_status: '',
      is_active: false,
      authorized_employees: [],
    }
    await dispatch(paymentMethodsLoad())
    setOpenForm(false)
    paymentCtx.setSelectedPaymentMethod({
      ...selectedPaymentMethod,
      bank_accounts: [...selectedPaymentMethod.bank_accounts, account],
    })
  }

  const handleDrawerClose = (): void => {
    // ? update the list in case there changes on the banks
    if (
      selectedPaymentMethod &&
      JSON.stringify(selectedPaymentMethod?.bank_accounts) !==
        JSON.stringify(originalData?.bank_accounts)
    ) {
      dispatch(updateSinglePaymentOfList(selectedPaymentMethod))
    }
    // ? then close it
    setOpenForm(false)
    paymentCtx.setOpenMangeAccount(false)
  }

  return (
    <SideDrawer
      active={openMangeAccount}
      onClose={handleDrawerClose}
      header={{
        title: i18n.t('payment_method_account_list', {
          name: selectedPaymentMethod?.name,
        }),
        withClose: true,
      }}
    >
      <ScrollbarContainer height="85vh">
        <div className="p-6">
          <PaymentAccountsList />
          {openForm && (
            <AddAccountForm
              handleSubmit={handleBankAdd}
              hideCommercialList
            />
          )}
          {!openForm && (
            <Button
              label={i18n.t('add_another_bank_account')}
              size="medium"
              variant="ghost"
              color="neutral"
              onClick={(): void => setOpenForm(true)}
              style={{ margin: 'auto' }}
            />
          )}
        </div>
      </ScrollbarContainer>
    </SideDrawer>
  )
}

export default ManagePaymentBanks
