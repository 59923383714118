import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Employee } from 'redux/authUser/types'
import * as actions from './actions'
import { TOrdSubsidiaryRole } from '../types'

type StateType = {
  fetching: boolean
  submitting: boolean
  employees: Employee[]
  subsidiary_roles: TOrdSubsidiaryRole[]
}

const initialState: StateType = {
  fetching: false,
  submitting: false,
  employees: [],
  subsidiary_roles: [],
}

const successMsg = (msg: string): void => {
  if (msg) Toastr.success(msg)
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.ORG_SUBSIDIARY_ROLES_LOAD_PENDING:
    case actions.ORG_SUBSIDIARY_EMPLOYEES_LOAD_PENDING:
    case actions.REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.ORG_SUBSIDIARY_ROLES_LOAD_FULFILLED:
    case actions.ORG_SUBSIDIARY_EMPLOYEES_LOAD_FULFILLED:
    case actions.ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED:
    case actions.REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_FULFILLED:
      successMsg(payload.data.message ?? undefined)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
        submitting: false,
      }

    case actions.ORG_SUBSIDIARY_ROLES_LOAD_REJECTED:
    case actions.ORG_SUBSIDIARY_EMPLOYEES_LOAD_REJECTED:
    case actions.ASSIGN_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED:
    case actions.REVOKE_SUBSIDIARY_EMPLOYEE_ROLE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
        submitting: false,
      }

    default:
      return state
  }
}
