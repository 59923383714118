import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAllEmployeesLoad } from 'redux/actions/employeeActions'

const HOC = (WrappedComponent) => {
  class WorkflowDetails extends React.Component {
    state = { search: null }
    componentDidMount() {
      this.props.getAllEmployeesLoad()
    }

    handleOnSearch = (e) => {
      const { value } = e.target
      this.setState({ search: value })
    }

    handleEmployeesList = () => {
      const { employee_all } = this.props
      const { search } = this.state
      let result = employee_all

      if (search) {
        result = employee_all.filter((emp) => emp.name.toLowerCase().includes(search.toLowerCase()))
      }
      return result
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          employees={this.handleEmployeesList()}
          handleOnSearch={this.handleOnSearch}
        />
      )
    }
  }

  WorkflowDetails.propTypes = {
    getAllEmployeesLoad: PropTypes.func,
    employee_all: PropTypes.arrayOf(Object),
  }

  const mapStateToProps = ({ employee }) => employee

  return connect(mapStateToProps, {
    getAllEmployeesLoad,
  })(WorkflowDetails)
}

export default HOC
