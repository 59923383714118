export const LETTER_TEMPLATES_LOAD = 'LETTER_TEMPLATES_LOAD'
export const LETTER_TEMPLATES_LOAD_FULFILLED = 'LETTER_TEMPLATES_LOAD_FULFILLED'
export const LETTER_TEMPLATES_LOAD_PENDING = 'LETTER_TEMPLATES_LOAD_PENDING'
export const LETTER_TEMPLATES_LOAD_REJECTED = 'LETTER_TEMPLATES_LOAD_REJECTED'

export const LETTER_TEMPLATES_GET_ONE = 'LETTER_TEMPLATES_GET_ONE'
export const LETTER_TEMPLATES_GET_ONE_FULFILLED = 'LETTER_TEMPLATES_GET_ONE_FULFILLED'
export const LETTER_TEMPLATES_GET_ONE_PENDING = 'LETTER_TEMPLATES_GET_ONE_PENDING'
export const LETTER_TEMPLATES_GET_ONE_REJECTED = 'LETTER_TEMPLATES_GET_ONE_REJECTED'

export const LETTER_TEMPLATES_POST = 'LETTER_TEMPLATES_POST'
export const LETTER_TEMPLATES_POST_FULFILLED = 'LETTER_TEMPLATES_POST_FULFILLED'
export const LETTER_TEMPLATES_POST_PENDING = 'LETTER_TEMPLATES_POST_PENDING'
export const LETTER_TEMPLATES_POST_REJECTED = 'LETTER_TEMPLATES_POST_REJECTED'

export const LETTER_TEMPLATES_UPDATE = 'LETTER_TEMPLATES_UPDATE'
export const LETTER_TEMPLATES_UPDATE_FULFILLED = 'LETTER_TEMPLATES_UPDATE_FULFILLED'
export const LETTER_TEMPLATES_UPDATE_PENDING = 'LETTER_TEMPLATES_UPDATE_PENDING'
export const LETTER_TEMPLATES_UPDATE_REJECTED = 'LETTER_TEMPLATES_UPDATE_REJECTED'

export const LETTER_TEMPLATES_OPTIONS_LOAD = 'LETTER_TEMPLATES_OPTIONS_LOAD'
export const LETTER_TEMPLATES_OPTIONS_LOAD_FULFILLED = 'LETTER_TEMPLATES_OPTIONS_LOAD_FULFILLED'
export const LETTER_TEMPLATES_OPTIONS_LOAD_PENDING = 'LETTER_TEMPLATES_OPTIONS_LOAD_PENDING'
export const LETTER_TEMPLATES_OPTIONS_LOAD_REJECTED = 'LETTER_TEMPLATES_OPTIONS_LOAD_REJECTED'

export const LETTER_TEMPLATES_PDF_LOAD = 'LETTER_TEMPLATES_PDF_LOAD'
export const LETTER_TEMPLATES_PDF_LOAD_FULFILLED = 'LETTER_TEMPLATES_PDF_LOAD_FULFILLED'
export const LETTER_TEMPLATES_PDF_LOAD_PENDING = 'LETTER_TEMPLATES_PDF_LOAD_PENDING'
export const LETTER_TEMPLATES_PDF_LOAD_REJECTED = 'LETTER_TEMPLATES_PDF_LOAD_REJECTED'

export const LETTER_TEMPLATES_DELETE = 'LETTER_TEMPLATES_DELETE'
export const LETTER_TEMPLATES_DELETE_FULFILLED = 'LETTER_TEMPLATES_DELETE_FULFILLED'
export const LETTER_TEMPLATES_DELETE_PENDING = 'LETTER_TEMPLATES_DELETE_PENDING'
export const LETTER_TEMPLATES_DELETE_REJECTED = 'LETTER_TEMPLATES_DELETE_REJECTED'

export const LETTER_TEMPLATES_POST_ATTACHMENT = 'LETTER_TEMPLATES_POST_ATTACHMENT'
export const LETTER_TEMPLATES_POST_ATTACHMENT_FULFILLED =
  'LETTER_TEMPLATES_POST_ATTACHMENT_FULFILLED'
export const LETTER_TEMPLATES_POST_ATTACHMENT_PENDING = 'LETTER_TEMPLATES_POST_ATTACHMENT_PENDING'
export const LETTER_TEMPLATES_POST_ATTACHMENT_REJECTED = 'LETTER_TEMPLATES_POST_ATTACHMENT_REJECTED'

export const PREVIEW_LETTER_TEMPLATE = 'PREVIEW_LETTER_TEMPLATE'
export const PREVIEW_LETTER_TEMPLATE_PENDING = 'PREVIEW_LETTER_TEMPLATE_PENDING'
export const PREVIEW_LETTER_TEMPLATE_FULFILLED = 'PREVIEW_LETTER_TEMPLATE_FULFILLED'

export const PRINT_LETTER_TEMPLATE = 'PRINT_LETTER_TEMPLATE'
export const PRINT_LETTER_TEMPLATE_PENDING = 'PRINT_LETTER_TEMPLATE_PENDING'
export const PRINT_LETTER_TEMPLATE_FULFILLED = 'PRINT_LETTER_TEMPLATE_FULFILLED'
export const PRINT_LETTER_TEMPLATE_REJECTED = 'PRINT_LETTER_TEMPLATE_REJECTED'
