import { createContext, useContext, useEffect, useState } from 'react'
import { serverEnvironment } from 'env'

const apiKey = 'd28f22eb-fc21-4889-b230-672b7ee4f533'

type UsersnapProviderProps = {
  initParams?: Record<string, unknown>
  children: React.ReactNode
}

type UsersnapApi = {
  init: (initParams: Record<string, unknown>) => void
  destroy: () => void
  logEvent: (eventName: string) => void
}

export const UsersnapContext = createContext<UsersnapApi | undefined>(undefined)

export const UsersnapProvider = ({ initParams = {}, children }: UsersnapProviderProps) => {
  const [usersnapApi, setUsersnapApi] = useState<UsersnapApi>()

  useEffect(() => {
    if (['aws', 'oci'].includes(serverEnvironment || '')) {
      window.onUsersnapCXLoad = (api) => {
        api.init(initParams)
        setUsersnapApi(api)
        window.usersnapApi = api
      }
      const script = document.createElement('script')
      script.src = `https://widget.usersnap.com/global/load/${apiKey}?onload=onUsersnapCXLoad`
      document.head.appendChild(script)

      return () => {
        if (usersnapApi) {
          usersnapApi.destroy()
        }
        script.remove()
      }
    }
    return undefined
  }, [])

  return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>
}

export const useUsersnapApi = () => useContext(UsersnapContext)
