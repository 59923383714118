import React from 'react'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import { SideDrawer } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import { useForm, useFormState } from 'react-final-form'
import { getRequestTranslationKey } from 'utils/uiHelpers'
import { useSelector } from 'utils/hooks'

import AssignEmployee from './AssignEmployee'

import {
  OvertimeRequest,
  ExcuseRequest,
  CorrectionRequest,
  LeaveRequest,
  BusinessTripRequest,
  HiringRequest,
  ExitReentryVisaRequest,
  AssetRequest,
  LetterRequest,
  ResignationRequest,
  ExpenseClaimRequest,
  DelegationRequest,
  LoanRequest,
  DocumentRequest,
  InfoChangeRequest,
  ExitReentryVisaRequestTask,
  IdentificationInfoTask,
  SalarySettingTask,
  TicketInfoTask,
  BusinessTripRequestTask,
  LoanRequestTask,
  LetterRequestTask,
  LeaveRequestTask,
  EosCalculationTask,
  PayrunTask,
  ExpenseClaimRequestTask,
  AssetAllocationDeallocationTask,
  CustomRequest,
  HiringRequestTask,
  CustomTask,
  PerformanceGroupTask,
  FlightTicketRequest,
  FlightTicketRequestTask,
  ViolationRequestTask,
} from './requestsTypes/approvals'

import {
  ExcuseRequest as ExcuseRequestForm,
  OvertimeRequest as OvertimeRequestForm,
  CorrectionRequest as CorrectionRequestForm,
  LeaveRequest as LeaveRequestForm,
  BusinessTripRequest as BusinessTripRequestForm,
  AssetRequest as AssetRequestForm,
  LetterRequest as LetterRequestForm,
  HiringRequest as HiringRequestForm,
  ResignationRequest as ResignationRequestForm,
  DelegationRequest as DelegationRequestForm,
  LoanRequest as LoanRequestForm,
  ExitReentryVisaRequest as ExitReentryVisaRequestForm,
  ExpenseClaimRequest as ExpenseClaimRequestForm,
  CustomRequest as CustomRequestForm,
  DocumentRequest as DocumentRequestForm,
  LeaveResumptionRequest as LeaveResumptionRequestForm,
  InfoChangeRequest as InfoChangeRequestForm,
  FlightTicketRequest as FlightTicketRequestForm,
} from './requestsTypes/forms'
import { useRequest } from './RequestProvider'
import { CorrectionAlert } from './components'

import Styles from './Request.module.css'
import RejectReason from './RejectReason'

// Asset
const AssetCancellationRequest = AssetRequest

// Asset clear
const AssetClearRequest = AssetRequest
const AssetClearRequestForm = AssetRequestForm
const AssetClearCancellationRequest = AssetRequest

// correction
const MissingPunchRequest = CorrectionRequest
const MissingPunchCancellationRequest = CorrectionRequest
const MissingPunchRequestForm = CorrectionRequestForm

// excuse
const ExcuseCancellationRequest = ExcuseRequest

// Delegation
const DelegationCancellationRequest = DelegationRequest

// overtime
const OvertimeCancellationRequest = OvertimeRequest

// BusinessTrip
const BusinessTripCancellationRequest = BusinessTripRequest

const LoanCancellationRequest = LoanRequest

// leave
const LeaveCancellationRequest = LeaveRequest
const LeaveResumptionRequest = LeaveRequest
const LeaveResumptionCancellationRequest = LeaveRequest

// Letter
const LetterCancellationRequest = LetterRequest

// Hiring request
const HiringCancellationRequest = HiringRequest

// Resignation request
const ResignationCancellationRequest = ResignationRequest

// expense claim request
const ExpenseClaimCancellationRequest = ExpenseClaimRequest

// exit reentry visa request
const ExitReentryVisaCancellationRequest = ExitReentryVisaRequest

// flight ticket request
const FlightTicketCancellationRequest = FlightTicketRequest

const Components = {
  // Asset requests
  AssetRequest,
  AssetRequestForm,
  AssetCancellationRequest,

  // excuse requests
  ExcuseRequest,
  ExcuseRequestForm,
  ExcuseCancellationRequest,

  // Delegation requests
  DelegationRequest,
  DelegationRequestForm,
  DelegationCancellationRequest,

  // overtime requests
  OvertimeRequest,
  OvertimeRequestForm,
  OvertimeCancellationRequest,

  // Leave requests
  LeaveRequest,
  LeaveRequestForm,
  LeaveCancellationRequest,
  LeaveResumptionRequest,
  LeaveResumptionRequestForm,
  LeaveResumptionCancellationRequest,

  // Letter reques
  LetterRequest,
  LetterRequestForm,
  LetterCancellationRequest,

  // Loan request
  LoanRequest,
  LoanRequestForm,
  LoanCancellationRequest,

  // BusinessTrip requests
  BusinessTripRequest,
  BusinessTripRequestForm,
  BusinessTripCancellationRequest,

  // correction requests
  CorrectionRequest,
  CorrectionRequestForm,
  MissingPunchRequest,
  MissingPunchCancellationRequest,
  MissingPunchRequestForm,

  // hiring requests
  HiringRequest,
  HiringCancellationRequest,
  HiringRequestForm,

  // Flight Ticket request
  FlightTicketRequest,
  FlightTicketRequestForm,
  FlightTicketCancellationRequest,

  // resignation
  ResignationRequest,
  ResignationCancellationRequest,
  ResignationRequestForm,

  // expense claim
  ExpenseClaimRequest,
  ExpenseClaimRequestForm,
  ExpenseClaimCancellationRequest,

  // exit reentry visa request
  ExitReentryVisaRequest,
  ExitReentryVisaRequestForm,
  ExitReentryVisaCancellationRequest,

  // Asset clear requests
  AssetClearRequest,
  AssetClearRequestForm,
  AssetClearCancellationRequest,

  // document
  DocumentRequest,
  DocumentRequestForm,

  // info change
  InfoChangeRequest,
  InfoChangeRequestForm,

  // tasks
  LoanRequestTask,
  LetterRequestTask,
  LeaveRequestTask,
  ExitReentryVisaRequestTask,
  IdentificationInfoTask,
  SalarySettingTask,
  TicketInfoTask,
  BusinessTripRequestTask,
  EosCalculationTask,
  PayrunTask,
  ExpenseClaimRequestTask,
  AssetRequestTask: AssetAllocationDeallocationTask,
  AssetClearRequestTask: AssetAllocationDeallocationTask,
  HiringRequestTask,
  PerformanceGroupTask,
  ViolationTask: ViolationRequestTask,
  // Custom Requests
  CustomRequest,
  CustomRequestForm,
  FlightTicketRequestTask,

  // Custom Tasks
  CustomTask,
}

const Request = React.forwardRef(
  ({ requestType, customTypeId, readOnly, employeeId, onClose }, ref) => {
    const {
      vars: { request },
      vars,
      editRequest,
      setEditRequest,
      setRequestType,
      drawerToggle,
      openNext,
      setOpenNext,
    } = useRequest()
    const { request_types } = useSelector(({ applicableRequests }) => applicableRequests)
    const { submit } = useForm()
    const { invalid, submitting, values } = useFormState()
    const typeName = customTypeId || requestType === 'Request' ? 'CustomRequest' : requestType
    const hasNextRequest = values?.show_flight_ticket_request
    const currentType =
      requestType === 'Request' || isNumber(requestType)
        ? request_types?.find(
            (t) => t.id === (isNumber(requestType) ? requestType : vars?.request_type_id),
          )
        : requestType
    const getComponentName = () => {
      if (editRequest) {
        return `${typeName}Form`
      } else if (readOnly) {
        return typeName
      }
      return `${typeName}Form`
    }
    const headerTitle =
      requestType === 'Request' || isNumber(requestType)
        ? currentType?.name_i18n || vars?.request_type_name
        : i18n.t(getRequestTranslationKey(currentType))

    const componentName = getComponentName()

    const RequestTypeComponent = Components[componentName]

    React.useEffect(() => {
      return () => {
        if (editRequest) {
          setEditRequest(false)
        }
      }
    }, [editRequest])
    return (
      <div
        className={Styles.request}
        ref={ref}
      >
        {request?.status === 'Rejected' && <RejectReason task={request} />}
        {employeeId || requestType ? (
          !!RequestTypeComponent && (
            <>
              {editRequest && <CorrectionAlert />}
              {!openNext && <RequestTypeComponent customTypeId={customTypeId} />}
              {openNext && (
                <SideDrawer
                  active={openNext}
                  onClose={() => {
                    drawerToggle(false)
                    onClose()
                    setOpenNext(false)
                  }}
                  header={{ title: headerTitle }}
                  footer={{
                    submit: {
                      label: hasNextRequest ? i18n.t('next') : i18n.t('submit'),
                      type: 'submit',
                      leadingIcon: 'check',
                      disabled: submitting || invalid,
                      onClick: () => {
                        if (hasNextRequest) {
                          setRequestType('FlightTicketRequest')
                        } else {
                          submit()
                          onClose()
                          setOpenNext(false)
                        }
                      },
                    },
                    cancel: {
                      label: i18n.t('back'),
                      onClick: () => {
                        setOpenNext(false)
                        setRequestType(null)
                      },
                    },
                    link: {
                      label: i18n.t('discard'),
                      variant: 'neutral',
                      onClick: () => {
                        setRequestType(null)
                        onClose()
                        setOpenNext(false)
                      },
                    },
                  }}
                >
                  <div className="h-full overflow-y-scroll p-4">
                    <RequestTypeComponent customTypeId={customTypeId} />
                  </div>
                </SideDrawer>
              )}
            </>
          )
        ) : (
          <AssignEmployee />
        )}
      </div>
    )
  },
)

Request.propTypes = {
  requestType: PropTypes.string,
  customTypeId: PropTypes.number,
  readOnly: PropTypes.bool,
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Request
