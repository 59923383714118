import { FC, useState } from 'react'
import { animated, config, useTransition } from 'react-spring'
import { Typography, Flex, Badge, Avatars } from '@jisr-hr/ds'
import mainStyles from 'containers/authorised/setting/v2/payroll/PaymentProcess/payment.module.css'
import { BankAccountType } from 'redux/setting/payrollManagement/paymentMethods/reducer'
import i18n from 'translations/i18n'
import { ReactComponent as OwnerIcon } from 'containers/authorised/setting/v2/payroll/PaymentProcess/components/icons/help-circle.svg'
import { ToolTipHandler } from 'components/authorised/Performace'
import DSIcon from 'components/DSIcon'

import styles from '../styles.module.css'

type PaymentBankDetailsPropsType = {
  bank_accounts: BankAccountType[]
  activeMethod: 'mudad' | 'offline' | null
}

const PaymentBankDetails: FC<PaymentBankDetailsPropsType> = (props) => {
  const { bank_accounts, activeMethod } = props

  const [opened, setOpened] = useState(false)

  const animatedList = useTransition(opened ? bank_accounts : [...bank_accounts.slice(0, 2)], {
    keys: (item) => item.id,
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    config: !opened ? config.default : config.wobbly,
    trail: 200,
  })

  return (
    <>
      <animated.div className={mainStyles.gray_widget}>
        {animatedList((style, account) => {
          return (
            <animated.div
              key={account.id}
              style={style}
              className={styles.nested_banks__container}
            >
              <Avatars
                imageSrc={account.bank_logo}
                imageAlt={account.bank_name}
                size="xl"
              />
              <div style={{ flex: 1 }}>
                <Flex
                  itemsCenter
                  style={{ gap: 8, marginBottom: 4 }}
                >
                  <Typography
                    variant="interface/default/sm"
                    style={{ color: 'var(--color-base-colors-grey-700', lineHeight: '20px' }}
                    text={account.bank_name}
                  />
                  {activeMethod === 'mudad' &&
                    account.authorization_status === 'not_authorized' && (
                      <Badge
                        label={i18n.t(account.authorization_status)}
                        color="red"
                        size="small"
                        variant="tinted"
                      />
                    )}
                  {account.is_direct_bank_integrated && (
                    <Badge
                      label={i18n.t('direct_bank_integration')}
                      color="cyan"
                      size="small"
                      variant="tinted"
                      leadingIcon="zap"
                    />
                  )}
                </Flex>
                <Typography
                  variant="subtitle-1"
                  style={{
                    color: 'var(--color-base-colors-grey-1000)',
                    marginBottom: 12,
                    lineHeight: '20px',
                  }}
                  text={account.iban}
                />
                {!!account?.authorized_employees && account?.authorized_employees?.length > 0 && (
                  <>
                    <Typography
                      variant="interface/default/sm"
                      style={{
                        color: 'var(--color-base-colors-grey-800)',
                        marginBottom: 4,
                        lineHeight: '20px',
                      }}
                      text={i18n.t('authorized_employees')}
                    />
                    <animated.div className={styles.authorized_employees_box}>
                      {account.authorized_employees.map((employee) => (
                        <animated.div
                          key={employee.id}
                          className={styles.authorized_employees_content_box}
                        >
                          <Avatars
                            imageSrc={employee.avatar_thumb}
                            imageAlt={employee.full_name_i18n}
                            size="sm"
                          />
                          <Typography
                            variant="interface/default/sm"
                            style={{
                              color: 'var(--color-base-colors-grey-900)',
                              lineHeight: '20px',
                            }}
                            text={employee.full_name_i18n}
                          />
                          {employee.is_owner && (
                            <ToolTipHandler
                              title={i18n.t('gosi_owner_number_info')}
                              placement="top"
                            >
                              <OwnerIcon />
                            </ToolTipHandler>
                          )}
                        </animated.div>
                      ))}
                    </animated.div>
                  </>
                )}
              </div>
            </animated.div>
          )
        })}
      </animated.div>

      {bank_accounts?.length > 2 && (
        <Flex
          itemsCenter
          style={{ gap: 8, marginTop: 10, width: 'fit-content', cursor: 'pointer' }}
          onClick={(): void => setOpened(!opened)}
        >
          <Typography
            variant="caption"
            style={{ color: 'var(--color-base-colors-blue-700)' }}
            text={i18n.t(opened ? 'show_less' : 'show_number_more', {
              number: bank_accounts.length - 2,
            })}
          />
          <DSIcon
            name="chevron-down"
            size="md"
            color="var(--color-brand-primary-default-new)"
            styles={{ transform: opened ? 'rotate(180deg)' : 'none' }}
          />
        </Flex>
      )}
    </>
  )
}

export default PaymentBankDetails
