import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const CorrectionText = ({ label, icon }) => (
  <Typography
    text={
      <>
        {icon && icon} {icon && ' '} {label}
      </>
    }
    style={{ color: 'var(--color-base-colors-grey-600)' }}
    variant="subtitle-2"
  />
)

CorrectionText.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.shape(),
}

export default CorrectionText
