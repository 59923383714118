import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import JisrLoader from 'components/global/JisrLoader'
import { getFormattedTemplate } from 'containers/authorised/setting/v2/WorkflowNew/Requests/components/helper'
import PDFPage from 'components/global/templates/PrintLetterTemplate/PDFPage'

const FilePreview = ({ url, handlePreviewLetter }) => {
  const { template, downloading } = useSelector(({ letterTemplates }) => letterTemplates)

  const imageExtensions = ['gif', 'jpeg', 'jpg', 'svg', 'png', 'webp', 'tiff', 'tif']

  React.useEffect(() => {
    handlePreviewLetter?.()
  }, [])

  if (url) {
    const fileExtension = url.split('.').pop()?.split('?')?.shift() || ''

    if (imageExtensions.includes(fileExtension)) {
      return (
        <img
          src={url}
          alt={url}
        />
      )
    }
    return (
      <object
        data={url}
        aria-label="url"
        width="100%"
        height="100%"
      />
    )
  } else if (handlePreviewLetter) {
    const templates = getFormattedTemplate(template)
    const pages = templates.template?.split('<!-- pagebreak -->') || []
    return (
      <div style={{ position: 'relative' }}>
        {downloading && <JisrLoader />}
        <div style={{ maxWidth: 1040, margin: 'auto' }}>
          {pages.map((page, i) => (
            <PDFPage
              key={`page-${i}`}
              header={templates.header}
              footer={templates.footer}
              content={page}
            />
          ))}
        </div>
      </div>
    )
  }
  return null
}

FilePreview.propTypes = {
  url: PropTypes.string,
  handlePreviewLetter: PropTypes.func,
}

export default FilePreview
