import PropTypes from 'prop-types'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type PunchOutTypes = {
  filterKey: string
  filters: string[]
}

const PunchOut = (props: PunchOutTypes): JSX.Element => {
  const { punch_out } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(punch_out)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      componentType="badges"
      valueKey="id"
      labelKey="name"
      {...props}
    />
  )
}

PunchOut.propTypes = {
  title: PropTypes.string,
  filterKey: PropTypes.string,
}

export default PunchOut
