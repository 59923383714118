import { ReactNode, useEffect } from 'react'
import { Widget } from 'components/global/atoms'
import { Typography, Spacer, Flex, Button, Switch } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { Form, SelectField } from 'components/final-form'
import { minimumDays } from 'components/authorised/setting/leaveManagement/annualLeave/VacationSalaryConfiguration/data'
import {
  minVacationSalaryDaysUpdate,
  isAdvanceVacationSalaryEnabledLoad,
  isAdvanceVacationSalaryEnabledUpdate,
  minVacationSalaryDaysLoad,
} from 'redux/organizationOnborading/VacationSettlement/actionCreators'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'

import { useDispatch, useSelector } from 'utils/hooks'
import { ReactComponent as InfoIcon } from '@jisr-hr/design-system/dist/assets/icons/Info.svg'
import styles from './../style.module.css'

const AdvancedConfiguration = (): JSX.Element => {
  const dispatch = useDispatch()
  const { min_vacation_salary_days, enabled_configuration, fetching } = useSelector(
    ({ VacationSettlement }) => VacationSettlement,
  )

  const onSubmit = (v: { value: null | number }): void => {
    dispatch(minVacationSalaryDaysUpdate({ value: v.value }))
  }

  const handleIsEnabled = (v: boolean): void => {
    dispatch(isAdvanceVacationSalaryEnabledUpdate({ value: v })).then(() =>
      dispatch(organizationInfo()),
    )
  }

  useEffect(() => {
    dispatch(isAdvanceVacationSalaryEnabledLoad())
    dispatch(minVacationSalaryDaysLoad())
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        value: min_vacation_salary_days?.value,
      }}
    >
      {({ pristine, handleSubmit }): ReactNode => (
        <form onSubmit={handleSubmit}>
          <Widget
            fetching={fetching}
            style={{ padding: 24 }}
          >
            <div>
              <Flex justifyBetween>
                <Flex itemsCenter>
                  <Typography
                    variant="title-1"
                    style={{ color: 'var(--color-base-colors-black-1000)' }}
                    text={I18n.t('advanced_vacation_salary_days_configuration')}
                  />
                </Flex>
                <Switch
                  active={enabled_configuration?.value}
                  onChange={(_, v): void => handleIsEnabled(v)}
                />
              </Flex>
              <Typography
                variant="interface/default/m"
                style={{ color: 'var(--color-base-colors-black-500)' }}
                text={I18n.t('advanced_configuration_description')}
              />
            </div>
            {enabled_configuration?.value && (
              <>
                <div
                  style={{ marginBottom: 24 }}
                  className={styles.divider}
                />
                <Typography
                  variant="subtitle-2"
                  text={I18n.t('minimum_days_to_apply')}
                />
                <Spacer height={4} />
                <SelectField
                  // @ts-expect-error need to migrate SelectField to TS
                  options={minimumDays()}
                  labelKey="name"
                  valueKey="id"
                  name="value"
                  style={{ width: 167 }}
                  isClearable
                  testId="minimum_days_to_apply"
                />
                <Spacer height={8} />
                <Flex>
                  <InfoIcon />
                  <Typography
                    style={{ color: 'var(--color-base-colors-black-400)', maxWidth: 400 }}
                    variant="subtitle-2"
                    text={I18n.t('the_number_of_minimum_days')}
                  />
                </Flex>
                <div className={styles.divider} />
                <Spacer height={20} />
                <Flex justifyEnd>
                  <Button
                    label={I18n.t('save')}
                    size="medium"
                    type="submit"
                    variant="filled"
                    color="primary"
                    disabled={pristine}
                    data-testId="save_minimum_days_to_apply"
                  />
                </Flex>
              </>
            )}
          </Widget>
        </form>
      )}
    </Form>
  )
}

export default AdvancedConfiguration
