import {
  QueryKey,
  MutationKey,
  UseQueryOptions,
  UseMutationOptions,
  useMutation,
  UseQueryResult,
  useQuery,
  UseMutationResult,
} from '@tanstack/react-query'
import { HTTPValidationError } from 'types/httpTypes'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'
import { SubmitFlightTicketRequestPayloadType } from 'components/global/templates/RequestDrawer/types/flightTicketRequest'
import {
  AnnualLeaveReauestResT,
  InitiateFlightTicketRequestResType,
  SubmitFlightTicketRequestResT,
} from './FlightTicketRequest.type'

// ? query keys

export const initiateFlightTicketRequestDataLoadKey = (employeeId: number | string): QueryKey => [
  `initiate_flight_ticket_requests-${employeeId}`,
]

export const annualLeaveRequestDataLoadKey = (employeeId: number | string): QueryKey => [
  `load_annual_leave_requests-${employeeId}`,
]
// ? mutation keys

export const CREATE_FLIGHT_TICKET_REQUEST_KEY: MutationKey = ['create_flight_ticket_request']

// ? functions

// * query functions

const initiateFlightTicketRequestDataLoad = (
  employeeId: number | string,
): Promise<InitiateFlightTicketRequestResType> => {
  return api.get(
    `/${API_VERSION_TWO}/benefits_management/flight_tickets/employees/${employeeId}/flight_ticket_requests/new`,
  )
}

const annualLeaveRequestDataLoad = (
  employeeId: number | string,
): Promise<AnnualLeaveReauestResT> => {
  return api.get(
    `/${API_VERSION_TWO}/benefits_management/flight_tickets/employees/${employeeId}/requests/applicable_to_link`,
  )
}

// * mutation functions

const createFlightTicketRequest = (
  employeeId: number | string,
  data: SubmitFlightTicketRequestPayloadType,
): Promise<SubmitFlightTicketRequestResT> => {
  return api.post(
    `/${API_VERSION_TWO}/benefits_management/flight_tickets/employees/${employeeId}/flight_ticket_requests`,
    data,
  )
}

// ? hooks

// * query hooks

export const useInitiateFlightTicketRequestDataLoad = <
  TData = InitiateFlightTicketRequestResType,
  TError = HTTPValidationError,
>(
  employeeId: number | string,
  opts?: {
    query?: UseQueryOptions<InitiateFlightTicketRequestResType, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? initiateFlightTicketRequestDataLoadKey(employeeId),
    queryFn: () => initiateFlightTicketRequestDataLoad(employeeId),
    retry: 1,
    cacheTime: 0,
  })
}

export const useAnnualLeaveRequestDataLoad = <
  TData = AnnualLeaveReauestResT,
  TError = HTTPValidationError,
>(
  employeeId: number | string,
  opts?: {
    query?: UseQueryOptions<AnnualLeaveReauestResT, TError, TData>
  },
): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    queryKey: opts?.query?.queryKey ?? annualLeaveRequestDataLoadKey(employeeId),
    queryFn: () => annualLeaveRequestDataLoad(employeeId),
    retry: 1,
    cacheTime: 0,
  })
}

// * mutation hooks

export const useCreateFlightTicketRequest = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<
    SubmitFlightTicketRequestResT,
    TError,
    { employeeId: number | string; data: SubmitFlightTicketRequestPayloadType }
  >
}): UseMutationResult<
  SubmitFlightTicketRequestResT,
  TError,
  { employeeId: number | string; data: SubmitFlightTicketRequestPayloadType }
> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: (payload) => createFlightTicketRequest(payload.employeeId, payload.data),
    mutationKey: CREATE_FLIGHT_TICKET_REQUEST_KEY,
    retry: 0,
    onSuccess: (res, variables, context) => {
      if (res.data.message) Toastr.success(res.data.message)
      opts?.mutation?.onSuccess?.(res, variables, context)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
