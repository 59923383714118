import React from 'react'
import { required } from 'components/global/form/FormValidations'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import { useForm, useFormState } from 'react-final-form'
import I18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import { subDays } from 'date-fns'
import { Grid } from 'components/global/atoms'
import { DatePickerDSField } from 'components/final-form'

import { useRequest } from '../../../RequestProvider'
import SelectShifts from '../../../ds/SelectShifts'

const CorrectionDateAndType = () => {
  const { vars, handleDisableSubmit } = useRequest()
  const { change } = useForm()
  const { values } = useFormState()
  const { attendance_record } = useSelector(
    ({ employeeAttendanceDetails }) => employeeAttendanceDetails,
  )
  const record = attendance_record?.[0]

  React.useEffect(() => {
    if (!values?.in_punch && !values?.out_punch) {
      handleDisableSubmit(true)
    } else {
      handleDisableSubmit(false)
    }
  }, [record, values])

  React.useEffect(() => {
    return () => {
      handleDisableSubmit(false)
    }
  }, [])

  return (
    <Frame title={I18n.t('correction_date_and_type')}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={record?.employee_shifts?.length > 0 ? 6 : 12}
        >
          <DatePickerDSField
            label={I18n.t('date')}
            validate={required}
            textFieldProps={{
              disabled: vars.shiftView,
            }}
            name="correction_date"
            calenderProps={{
              minDate: subDays(new Date(), 59),
              maxDate: new Date(),
            }}
            onChange={() => change('initialShiftId', undefined)}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <SelectShifts />
        </Grid>
      </Grid>
    </Frame>
  )
}

export default CorrectionDateAndType
