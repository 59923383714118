import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type AssetTypesTypes = {
  filterKey: string
  filters: string[]
}

const AssetTypes = (props: AssetTypesTypes): JSX.Element => {
  const { assetTypes } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={assetTypes}
      labelKey="name_i18n"
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default AssetTypes
