import { Typography, Flex } from '@jisr-hr/ds'
import jisrLogo from 'assets/images/JisrLogo.svg'
import { ReactComponent as JisrEmployeeStopIcon } from './icons/JisrEmployeeStop.svg'

type TExpiryTemplate = {
  title: string
  subTitle?: string | JSX.Element
  children?: JSX.Element
}

const ExpiryTemplate = ({ title, subTitle, children }: TExpiryTemplate): JSX.Element => {
  return (
    <Flex
      flexCol
      itemsCenter
      style={{ height: '100%', padding: '32px 16px' }}
    >
      <img
        alt="Jisr"
        src={jisrLogo}
        style={{ height: 32, width: 62 }}
      />
      <Flex
        flexCol
        itemsCenter
        justifyCenter
        style={{ gap: 24, height: '100%' }}
      >
        <JisrEmployeeStopIcon />
        <Flex
          flexCol
          itemsCenter
        >
          <Typography
            variant="title-1"
            text={title}
          />
          {subTitle && (
            <Typography
              variant="subheading"
              style={{
                color: 'var(--ds-base-color-actionable-secondary-fg-light)',
                textAlign: 'center',
              }}
              text={subTitle}
            />
          )}
        </Flex>
        {children}
      </Flex>
    </Flex>
  )
}

export default ExpiryTemplate
