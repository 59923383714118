export const HIERARCHICAL_DEPARTMENTS_NEW_LOAD = 'HIERARCHICAL_DEPARTMENTS_NEW_LOAD'
export const HIERARCHICAL_DEPARTMENTS_NEW_LOAD_PENDING = 'HIERARCHICAL_DEPARTMENTS_NEW_LOAD_PENDING'
export const HIERARCHICAL_DEPARTMENTS_NEW_LOAD_FULFILLED =
  'HIERARCHICAL_DEPARTMENTS_NEW_LOAD_FULFILLED'
export const HIERARCHICAL_DEPARTMENTS_NEW_LOAD_REJECTED =
  'HIERARCHICAL_DEPARTMENTS_NEW_LOAD_REJECTED'

export const BASIC_LIST_DEPARTMENTS_LOAD = 'BASIC_LIST_DEPARTMENTS_LOAD'
export const BASIC_LIST_DEPARTMENTS_LOAD_PENDING = 'BASIC_LIST_DEPARTMENTS_LOAD_PENDING'
export const BASIC_LIST_DEPARTMENTS_LOAD_FULFILLED = 'BASIC_LIST_DEPARTMENTS_LOAD_FULFILLED'
export const BASIC_LIST_DEPARTMENTS_LOAD_REJECTED = 'BASIC_LIST_DEPARTMENTS_LOAD_REJECTED'

export const LIST_DEPARTMENTS_LOAD = 'LIST_DEPARTMENTS_LOAD'
export const LIST_DEPARTMENTS_LOAD_PENDING = 'LIST_DEPARTMENTS_LOAD_PENDING'
export const LIST_DEPARTMENTS_LOAD_FULFILLED = 'LIST_DEPARTMENTS_LOAD_FULFILLED'
export const LIST_DEPARTMENTS_LOAD_REJECTED = 'LIST_DEPARTMENTS_LOAD_REJECTED'

export const DEPARTMENTS_POST = 'DEPARTMENTS_POST'
export const DEPARTMENTS_POST_PENDING = 'DEPARTMENTS_POST_PENDING'
export const DEPARTMENTS_POST_FULFILLED = 'DEPARTMENTS_POST_FULFILLED'
export const DEPARTMENTS_POST_REJECTED = 'DEPARTMENTS_POST_REJECTED'

export const DEPARTMENTS_PUT = 'DEPARTMENTS_PUT'
export const DEPARTMENTS_PUT_PENDING = 'DEPARTMENTS_PUT_PENDING'
export const DEPARTMENTS_PUT_FULFILLED = 'DEPARTMENTS_PUT_FULFILLED'
export const DEPARTMENTS_PUT_REJECTED = 'DEPARTMENTS_PUT_REJECTED'

export const DEPARTMENTS_DELETE = 'DEPARTMENTS_DELETE'
export const DEPARTMENTS_DELETE_PENDING = 'DEPARTMENTS_DELETE_PENDING'
export const DEPARTMENTS_DELETE_FULFILLED = 'DEPARTMENTS_DELETE_FULFILLED'
export const DEPARTMENTS_DELETE_REJECTED = 'DEPARTMENTS_DELETE_REJECTED'

export const EMPLOYEES_DEPARTMENT_LOAD = 'EMPLOYEES_DEPARTMENT_LOAD'
export const EMPLOYEES_DEPARTMENT_LOAD_PENDING = 'EMPLOYEES_DEPARTMENT_LOAD_PENDING'
export const EMPLOYEES_DEPARTMENT_LOAD_FULFILLED = 'EMPLOYEES_DEPARTMENT_LOAD_FULFILLED'
export const EMPLOYEES_DEPARTMENT_LOAD_REJECTED = 'EMPLOYEES_DEPARTMENT_LOAD_REJECTED'

export const ASSIGN_EMPLOYEES_PUT = 'ASSIGN_EMPLOYEES_PUT'
export const ASSIGN_EMPLOYEES_PUT_PENDING = 'ASSIGN_EMPLOYEES_PUT_PENDING'
export const ASSIGN_EMPLOYEES_PUT_FULFILLED = 'ASSIGN_EMPLOYEES_PUT_FULFILLED'
export const ASSIGN_EMPLOYEES_PUT_REJECTED = 'ASSIGN_EMPLOYEES_PUT_REJECTED'

export const CLONE_PARENT_ORG_DEPARTMENTS = 'CLONE_PARENT_ORG_DEPARTMENTS'
export const CLONE_PARENT_ORG_DEPARTMENTS_PENDING = 'CLONE_PARENT_ORG_DEPARTMENTS_PENDING'
export const CLONE_PARENT_ORG_DEPARTMENTS_FULFILLED = 'CLONE_PARENT_ORG_DEPARTMENTS_FULFILLED'
export const CLONE_PARENT_ORG_DEPARTMENTS_REJECTED = 'CLONE_PARENT_ORG_DEPARTMENTS_REJECTED'

export const BUILD_AUTOMATICALLY = 'BUILD_AUTOMATICALLY'
export const BUILD_AUTOMATICALLY_PENDING = 'BUILD_AUTOMATICALLY_PENDING'
export const BUILD_AUTOMATICALLY_FULFILLED = 'BUILD_AUTOMATICALLY_FULFILLED'
export const BUILD_AUTOMATICALLY_REJECTED = 'BUILD_AUTOMATICALLY_REJECTED'

export const RESET_ALL_DEPARTMENT = 'RESET_ALL_DEPARTMENT'
export const RESET_ALL_DEPARTMENT_PENDING = 'RESET_ALL_DEPARTMENT_PENDING'
export const RESET_ALL_DEPARTMENT_FULFILLED = 'RESET_ALL_DEPARTMENT_FULFILLED'
export const RESET_ALL_DEPARTMENT_REJECTED = 'RESET_ALL_DEPARTMENT_REJECTED'

export const SUGGESTIONS_DEPARTMENT_LOAD = 'SUGGESTIONS_DEPARTMENT_LOAD'
export const SUGGESTIONS_DEPARTMENT_LOAD_PENDING = 'SUGGESTIONS_DEPARTMENT_LOAD_PENDING'
export const SUGGESTIONS_DEPARTMENT_LOAD_FULFILLED = 'SUGGESTIONS_DEPARTMENT_LOAD_FULFILLED'
export const SUGGESTIONS_DEPARTMENT_LOAD_REJECTED = 'SUGGESTIONS_DEPARTMENT_LOAD_REJECTED'

export const BUILD_STATUS_LOAD = 'BUILD_STATUS_LOAD'
export const BUILD_STATUS_LOAD_PENDING = 'BUILD_STATUS_LOAD_PENDING'
export const BUILD_STATUS_LOAD_FULFILLED = 'BUILD_STATUS_LOAD_FULFILLED'
export const BUILD_STATUS_LOAD_REJECTED = 'BUILD_STATUS_LOAD_REJECTED'

export const SET_INITIAL_VALUES = 'SET_INITIAL_VALUES'
