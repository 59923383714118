export const EMPLOYEE_LEAVE_DAYS_LOAD = 'EMPLOYEE_LEAVE_DAYS_LOAD'
export const EMPLOYEE_LEAVE_DAYS_LOAD_FULFILLED = 'EMPLOYEE_LEAVE_DAYS_LOAD_FULFILLED'
export const EMPLOYEE_LEAVE_DAYS_LOAD_REJECTED = 'EMPLOYEE_LEAVE_DAYS_LOAD_REJECTED'

export const EMPLOYEE_LEAVE_DAYS_CREATE = 'EMPLOYEE_LEAVE_DAYS_CREATE'
export const EMPLOYEE_LEAVE_DAYS_CREATE_PENDING = 'EMPLOYEE_LEAVE_DAYS_CREATE_PENDING'
export const EMPLOYEE_LEAVE_DAYS_CREATE_FULFILLED = 'EMPLOYEE_LEAVE_DAYS_CREATE_FULFILLED'
export const EMPLOYEE_LEAVE_DAYS_CREATE_REJECTED = 'EMPLOYEE_LEAVE_DAYS_CREATE_REJECTED'

export const EMPLOYEE_LEAVE_DAYS_UPDATE = 'EMPLOYEE_LEAVE_DAYS_UPDATE'
export const EMPLOYEE_LEAVE_DAYS_UPDATE_FULFILLED = 'EMPLOYEE_LEAVE_DAYS_UPDATE_FULFILLED'
export const EMPLOYEE_LEAVE_DAYS_UPDATE_REJECTED = 'EMPLOYEE_LEAVE_DAYS_UPDATE_REJECTED'

export const EMPLOYEE_LEAVE_DAYS_DELETE = 'EMPLOYEE_LEAVE_DAYS_DELETE'
export const EMPLOYEE_LEAVE_DAYS_DELETE_FULFILLED = 'EMPLOYEE_LEAVE_DAYS_DELETE_FULFILLED'
export const EMPLOYEE_LEAVE_DAYS_DELETE_REJECTED = 'EMPLOYEE_LEAVE_DAYS_DELETE_REJECTED'
