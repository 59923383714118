import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import * as actions from './actions'
import { AssignEmployees, Form, TSourceType, TSuggetionsDepartment } from './types'

const isOnboarding = window.location.hash.includes('/onboarding')
const path = isOnboarding ? '/v2/onboarding/departments' : '/v2/departments'
export function hierarchicalDepartmentsLoad(params = {}): Action {
  return {
    type: actions.HIERARCHICAL_DEPARTMENTS_NEW_LOAD,
    payload: handleAPI(
      path,
      {
        ...params,
        hierarchical: true,
      },
      'GET',
    ),
  }
}

export function listDepartmentsLoad(params = {}, generateBasicData = false): Action {
  const is_origin = generateBasicData
    ? actions.BASIC_LIST_DEPARTMENTS_LOAD
    : actions.LIST_DEPARTMENTS_LOAD
  return {
    type: is_origin,
    payload: handleAPI(
      path,
      {
        ...params,
        hierarchical: false,
      },
      'GET',
    ),
  }
}

export function departmentsPost(department: Form): Action {
  return {
    type: actions.DEPARTMENTS_POST,
    payload: handleAPI(path, {}, 'POST', {
      department,
    }),
  }
}

export function departmentsUpdate(id: number, department: Form): Action {
  return {
    type: actions.DEPARTMENTS_PUT,
    payload: handleAPI(`${path}/${id}`, {}, 'PUT', { department }),
  }
}

export function departmentsDelete(id: number): Action {
  return {
    type: actions.DEPARTMENTS_DELETE,
    payload: handleAPI(`${path}/${id}`, {}, 'DELETE'),
  }
}

export function employeesDepartmentLoad(params: {
  filter: {
    department_id: number[]
    search?: string
  }
}): Action {
  return {
    type: actions.EMPLOYEES_DEPARTMENT_LOAD,
    payload: handleAPI('/v2/employees/get_all', params, 'GET'),
  }
}

export function assignEmployeesPut(data: AssignEmployees): Action {
  return {
    type: actions.ASSIGN_EMPLOYEES_PUT,
    payload: handleAPI('/v2/departments/assign_employees', {}, 'PUT', data),
  }
}

export function cloneParentOrgDepartments(): Action {
  return {
    type: actions.CLONE_PARENT_ORG_DEPARTMENTS,
    payload: handleAPI('/v2/onboarding/departments/clone', {}, 'POST'),
  }
}

export function resetAllDepartment(): Action {
  return {
    type: actions.RESET_ALL_DEPARTMENT,
    payload: handleAPI('/v2/departments/reset', {}, 'DELETE'),
  }
}

export function buildAutomatically(params: TSourceType = 'auto'): Action {
  return {
    type: actions.BUILD_AUTOMATICALLY,
    payload: handleAPI('/v2/departments/build_auto', { source: params }, 'POST'),
  }
}

export function suggestionsDepartmentLoad(params = {}): Action {
  const url = 'hr/industry_templates?template_for=department&page=1&per_page=100'
  return {
    type: actions.SUGGESTIONS_DEPARTMENT_LOAD,
    payload: handleAPI(`/${API_VERSION_TWO}/${url}`, params, 'GET'),
  }
}

export function buildStatusLoad(params = {}): Action {
  const url = 'departments/build_status'
  return {
    type: actions.BUILD_STATUS_LOAD,
    payload: handleAPI(`/${API_VERSION_TWO}/${url}`, params, 'GET'),
  }
}

export function setInitialValues(data: TSuggetionsDepartment): Action {
  return {
    type: actions.SET_INITIAL_VALUES,
    payload: data,
  }
}
