import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import i18n from 'translations/i18n'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'

type EmployeeInvitationProps = {
  filterKey: string
  filters: string[]
}

const EmployeeInvitationsStatus = (props: EmployeeInvitationProps): JSX.Element => {
  const { invitations_statuses } = useFilterAPI()
  const { filter, handleFilter, setFilter } = useFilters()
  const [selectedIds, setSelectedIds] = useState<'all'[]>([])

  const onHandleFilter = (_: string, val: string): void => {
    if (val === 'all') {
      setFilter({
        ...filter,
        invitations_statuses: [],
      })
    } else {
      handleFilter('invitations_statuses', val)
    }
  }

  useEffect(() => {
    if (!isEmpty(filter.invitations_statuses)) {
      setSelectedIds(filter.invitations_statuses)
    } else {
      setSelectedIds(['all'])
    }
  }, [filter.invitations_statuses])

  return (
    <FilterBox
      handleFilter={onHandleFilter}
      options={invitations_statuses}
      selectedIds={selectedIds as never[]}
      {...props}
      title={i18n.t('status')}
    />
  )
}

export default EmployeeInvitationsStatus
