export const CREATE_DELEGATION_REQUEST = 'CREATE_DELEGATION_REQUEST'
export const CREATE_DELEGATION_REQUEST_PENDING = 'CREATE_DELEGATION_REQUEST_PENDING'
export const CREATE_DELEGATION_REQUEST_FULFILLED = 'CREATE_DELEGATION_REQUEST_FULFILLED'
export const CREATE_DELEGATION_REQUEST_REJECTED = 'CREATE_DELEGATION_REQUEST_REJECTED'

export const UPDATE_DELEGATION_REQUEST = 'UPDATE_DELEGATION_REQUEST'
export const UPDATE_DELEGATION_REQUEST_PENDING = 'UPDATE_DELEGATION_REQUEST_PENDING'
export const UPDATE_DELEGATION_REQUEST_FULFILLED = 'UPDATE_DELEGATION_REQUEST_FULFILLED'
export const UPDATE_DELEGATION_REQUEST_REJECTED = 'UPDATE_DELEGATION_REQUEST_REJECTED'

export const DELEGATION_REQUEST_LOAD = 'DELEGATION_REQUEST_LOAD'
export const DELEGATION_REQUEST_LOAD_PENDING = 'DELEGATION_REQUEST_LOAD_PENDING'
export const DELEGATION_REQUEST_LOAD_FULFILLED = 'DELEGATION_REQUEST_LOAD_FULFILLED'
export const DELEGATION_REQUEST_LOAD_REJECTED = 'DELEGATION_REQUEST_LOAD_REJECTED'

export const APPLICABLE_DELEGATORS_REQUEST_LOAD = 'APPLICABLE_DELEGATORS_REQUEST_LOAD'
export const APPLICABLE_DELEGATORS_REQUEST_LOAD_PENDING =
  'APPLICABLE_DELEGATORS_REQUEST_LOAD_PENDING'
export const APPLICABLE_DELEGATORS_REQUEST_LOAD_FULFILLED =
  'APPLICABLE_DELEGATORS_REQUEST_LOAD_FULFILLED'
export const APPLICABLE_DELEGATORS_REQUEST_LOAD_REJECTED =
  'APPLICABLE_DELEGATORS_REQUEST_LOAD_REJECTED'
