import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { addHours, format } from 'date-fns'
import { Action } from 'types/redux'
import { updateOneListOfRecords } from 'components/global/HelperFunctions'
import * as actions from './actions'

export type LastImportDetailType = {
  id: number
  employee_id: number
  name: string
  s3_url: string
  error_file_url: string | null
  category: string
  category_value:
    | 'wps_gosi_infos'
    | 'payroll_startup_payment_details'
    | 'payroll_adjustments'
    | 'salaries_packages'
  status: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  completed_time: string
  uploaded_time: string
  updated_at: string
  metadata: {
    filename: string
    errors_list?: [] | null
    failed_count?: number
    success_count?: number
  }
  details: string
  scope_type?: string
  scope_id?: string
}

export type PayrunType = {
  attendance_review_status: string
  attendance_review_request_id: number
  attendance_reviewer_name?: string
  attendance_reviewer_url?: string
  cut_off_from_date: string
  status: 'confirmed' | 'draft' | 'initiated' | 'under_review' | 'changes_required' | 'skipped'
  cut_off_to_date: string
  employees_count: number
  id: number | null
  month_year_i18n: string
  pay_period_end: string
  pay_period_start: string
  remaining_days: number
  status_i18n: string
  closed_by: string
  closing_date: string
  total_net_salaries: number
  is_overdue: boolean
  default_fund_date: string
}

export type PreviousPayrunType = {
  closed_by: {
    full_name_i18n: string
  }
  attendance_review_status: string
  cut_off_from_date: string
  cut_off_to_date: string
  closing_date: string
  id: number | null
  month_year_i18n: string
  pay_period_end: string
  pay_period_start: string
  status: string
  status_i18n: string
  total_net_salaries: string
  employees_count: number
  is_overdue: boolean
}

type StepType = {
  order: number
  status: 'pending' | 'completed' | 'skipped'
  required: boolean
  can_set_as_completed: boolean
}

export type PayrollActivationStepNameType =
  | 'attendance'
  | 'gosi'
  | 'employee_payment_methods'
  | 'activate_payment_method'
  | 'addition_types'
  | 'deductions'
  | 'collaborator'
  | 'workflow_approvals'
  | 'payslip_sharing'
  | 'employees_salaries'

type ConfigurationStepsType = Record<PayrollActivationStepNameType, StepType>

export type PayrunActivationStatusType = {
  id: number
  status: 'pending' | 'completed'
  steps: ConfigurationStepsType | null
  setup_version: 'version_one' | 'version_two' | 'version_three'
}

export type PayschedulesBodyType = {
  cut_off_day?: number
  cut_off_start_date?: string
  fund_day?: number
  start_date?: string
}

export type PayschedulesType = {
  id: number
  cut_off_day: number
  cut_off_start_date: string
  fund_day: number
  start_date: string
  cycle: 'monthly' | 'weekly' | 'biweekly' | 'custom'
  status: 'active' | 'inactive'
}

export type InitialStateType = {
  // ? temp
  current_payrun: PayrunType | null
  fetching: boolean
  payrunMonth: string
  payrun_approval_cycle: {
    employee_id: number
    followup_needed: boolean
    review_request_id: number
    approval_id: number
  }
  payruns: (PayrunType & {
    date: string
    id: number
    month_year_i18n: string
  })[]
  previous_payruns: PreviousPayrunType[]
  reminder_last_updated: string | null
  sending: boolean
  payrun_activation_status: PayrunActivationStatusType | null
  payrun_experience_type: 'new' | 'old' | null
  payrun_activation_fetching: boolean
  payrun_set_activation_completed_fetching: boolean
  bulk_import: Record<LastImportDetailType['category_value'], LastImportDetailType[] | null>
  bulk_import_fetching: boolean
  payschedules: PayschedulesType[]
  payschedules_fetching: boolean
}

const initialState: InitialStateType = {
  current_payrun: null,
  previous_payruns: [],
  payrun_approval_cycle: {
    employee_id: 0,
    followup_needed: false,
    review_request_id: 0,
    approval_id: 0,
  },
  fetching: false,
  sending: false,
  reminder_last_updated: localStorage.getItem('payrun-reminder') || null,
  payruns: [],
  payrunMonth: '',
  payrun_activation_status: null,
  payrun_experience_type: null,
  payrun_activation_fetching: false,
  payrun_set_activation_completed_fetching: false,
  bulk_import: {
    wps_gosi_infos: null,
    salaries_packages: null,
    payroll_startup_payment_details: null,
    payroll_adjustments: null,
  },
  bulk_import_fetching: false,
  payschedules: [],
  payschedules_fetching: false,
}

export default function reducer(state = initialState, action: Action): InitialStateType {
  switch (action.type) {
    case actions.PAYROLL_PAYRUN_CANCEL_PENDING:
    case actions.PAYRUNS_LOAD_PENDING:
    case actions.PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_PENDING:
    case actions.PREVIOUS_PAYRUNS_PENDING:
    case actions.SKIP_PAYRUNS_PENDING:
    case actions.START_PAYRUNS_PENDING:
    case actions.PREVIOUS_PERIODS_PAYRUNS_PENDING:
    case actions.REOPEN_PAYRUNS_PENDING:
    case actions.EMPLOYEES_WITHOUT_CR_UPDATE_PENDING:
    case actions.PAYROLL_PAYRUN_CONFIRM_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.PAYRUN_DETAIL_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.PAYRUN_DETAIL_LOAD_WITH_RESET_PENDING: {
      return {
        ...state,
        fetching: true,
        current_payrun: null,
      }
    }
    case actions.PAYSCHEDULES_LOAD_PENDING:
    case actions.PAYSCHEDULES_CREATE_PENDING:
    case actions.EMPLOYEES_WITHOUT_CR_UPDATE_FULFILLED:
    case actions.PAYSCHEDULES_UPDATE_PENDING: {
      return {
        ...state,
        payschedules_fetching: true,
      }
    }

    case actions.PAYROLL_PAYRUN_CONFIRM_FULFILLED:
    case actions.PAYROLL_PAYRUN_CANCEL_FULFILLED:
    case actions.PREVIOUS_PERIODS_PAYRUNS_FULFILLED:
    case actions.SKIP_PAYRUNS_FULFILLED:
    case actions.START_PAYRUNS_FULFILLED:
    case actions.REOPEN_PAYRUNS_FULFILLED:
    case actions.PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    case actions.PAYROLL_PAYRUN_CONFIRM_REJECTED:
    case actions.PAYROLL_PAYRUN_CANCEL_REJECTED:
    case actions.PAYRUNS_LOAD_REJECTED:
    case actions.PAYRUN_DETAIL_LOAD_REJECTED:
    case actions.PAYRUN_DETAIL_LOAD_WITH_RESET_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    case actions.PAYRUN_SEND_REMINDER_PENDING: {
      return {
        ...state,
        sending: true,
      }
    }
    case actions.PAYSCHEDULES_LOAD_REJECTED:
    case actions.PAYSCHEDULES_CREATE_REJECTED:
    case actions.PAYSCHEDULES_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        payschedules_fetching: false,
      }
    }
    case actions.PAYRUN_SEND_REMINDER_FULFILLED: {
      const last_updated = addHours(new Date(), 2).toString()
      localStorage.setItem('payrun-reminder', last_updated)
      return {
        ...state,
        sending: false,
        reminder_last_updated: last_updated,
      }
    }
    case actions.PAYRUNS_LOAD_FULFILLED:
      return {
        ...state,
        payruns: action.payload.data.data.payruns,
        fetching: false,
      }

    case actions.PREVIOUS_PAYRUNS_FULFILLED:
      return {
        ...state,
        previous_payruns: action.payload.data.data?.splice(1, 6),
        current_payrun: action.payload.data.data[0],
        fetching: false,
      }
    case actions.PAYRUN_DETAIL_LOAD_FULFILLED:
    case actions.PAYRUN_DETAIL_LOAD_WITH_RESET_FULFILLED:
      return {
        ...state,
        current_payrun: action.payload.data.data.payrun,
        payrun_approval_cycle: action.payload.data.data.payrun.payroll_approval_cycle,
        fetching: false,
        payrunMonth: format(
          new Date(
            action.payload?.data?.data?.payrun?.year,
            action.payload?.data?.data?.payrun?.month - 1,
            1,
          ),
          'MMMM yyyy',
        ),
      }
    case actions.PAYSCHEDULES_LOAD_FULFILLED: {
      return {
        ...state,
        payschedules_fetching: false,
        payschedules: action.payload.data.data.payschedules,
      }
    }
    case actions.PAYSCHEDULES_CREATE_FULFILLED:
    case actions.PAYSCHEDULES_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        payschedules_fetching: false,
        payschedules: updateOneListOfRecords(
          state.payschedules,
          action.payload.data.data.payschedule,
        ),
      }
    }
    case actions.PREVIOUS_PAYRUNS_REJECTED:
    case actions.PAYRUN_SEND_REMINDER_REJECTED:
    case actions.PREVIOUS_PERIODS_PAYRUNS_REJECTED:
    case actions.REOPEN_PAYRUNS_REJECTED:
    case actions.PAYRUN_SEND_ATTENDANCE_REVIW_REQUESTS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.SKIP_PAYRUNS_REJECTED:
    case actions.START_PAYRUNS_REJECTED: {
      if (action.payload?.response?.data?.code !== 'previous_unclosed')
        Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.PAYRUN_SET_ACTIVATION_COMPLETED_PENDING:
      return {
        ...state,
        payrun_set_activation_completed_fetching: true,
      }
    case actions.PAYRUN_ACTIVATION_STATUS_PENDING:
    case actions.PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_PENDING:
      return {
        ...state,
        payrun_activation_fetching: true,
      }
    case actions.PAYRUN_SET_ACTIVATION_COMPLETED_FULFILLED:
    case actions.PAYRUN_ACTIVATION_STATUS_FULFILLED:
    case actions.PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_FULFILLED: {
      const config = (action.payload.data.data?.payroll_startup_configuration ||
        null) as InitialStateType['payrun_activation_status']
      return {
        ...state,
        payrun_activation_status: config,
        payrun_experience_type: config ? 'new' : 'old',
        payrun_activation_fetching: false,
        payrun_set_activation_completed_fetching: false,
      }
    }
    case actions.PAYRUN_SET_ACTIVATION_COMPLETED_REJECTED:
    case actions.PAYRUN_ACTIVATION_STATUS_REJECTED:
    case actions.EMPLOYEES_WITHOUT_CR_UPDATE_REJECTED:
    case actions.PAYRUN_UPDATE_ACTIVATION_STEP_STATUS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        payrun_activation_fetching: false,
        payrun_set_activation_completed_fetching: false,
      }
    case actions.LAST_IMPORT_LOAD_PENDING:
      return {
        ...state,
        bulk_import_fetching: true,
      }
    case actions.LAST_IMPORT_LOAD_FULFILLED:
      const bulk_data = action.payload.data.data.bulk_import as Record<string, string> | null
      let data = null
      if (bulk_data !== null) {
        data = Array.isArray(bulk_data) ? bulk_data : [bulk_data]
      }
      return {
        ...state,
        bulk_import_fetching: false,
        ...(!!data?.length && {
          bulk_import: {
            ...state.bulk_import,
            [data[0].category_value]: data,
          },
        }),
      }
    case actions.RESET_SPECIFIC_LAST_IMPORT:
      return {
        ...state,
        bulk_import: {
          ...state.bulk_import,
          [action.payload.key]: [],
        },
      }
    case actions.LAST_IMPORT_LOAD_REJECTED:
      return {
        ...state,
        bulk_import_fetching: false,
      }

    default:
      return state
  }
}
