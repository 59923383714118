export const EXIT_REENTRY_VISA_REQUEST_LOAD = 'EXIT_REENTRY_VISA_REQUEST_LOAD'
export const EXIT_REENTRY_VISA_REQUEST_LOAD_PENDING = 'EXIT_REENTRY_VISA_REQUEST_LOAD_PENDING'
export const EXIT_REENTRY_VISA_REQUEST_LOAD_FULFILLED = 'EXIT_REENTRY_VISA_REQUEST_LOAD_FULFILLED'
export const EXIT_REENTRY_VISA_REQUEST_LOAD_REJECTED = 'EXIT_REENTRY_VISA_REQUEST_LOAD_REJECTED'

export const CREATE_EXIT_REENTRY_VISA_REQUEST = 'CREATE_EXIT_REENTRY_VISA_REQUEST'
export const CREATE_EXIT_REENTRY_VISA_REQUEST_PENDING = 'CREATE_EXIT_REENTRY_VISA_REQUEST_PENDING'
export const CREATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED =
  'CREATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED'
export const CREATE_EXIT_REENTRY_VISA_REQUEST_REJECTED = 'CREATE_EXIT_REENTRY_VISA_REQUEST_REJECTED'

export const UPDATE_EXIT_REENTRY_VISA_REQUEST = 'UPDATE_EXIT_REENTRY_VISA_REQUEST'
export const UPDATE_EXIT_REENTRY_VISA_REQUEST_PENDING = 'UPDATE_EXIT_REENTRY_VISA_REQUEST_PENDING'
export const UPDATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED =
  'UPDATE_EXIT_REENTRY_VISA_REQUEST_FULFILLED'
export const UPDATE_EXIT_REENTRY_VISA_REQUEST_REJECTED = 'UPDATE_EXIT_REENTRY_VISA_REQUEST_REJECTED'
