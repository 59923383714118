import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { SSOReduxType } from './types'
import * as actions from './actions'

const initialState: SSOReduxType = {
  fetching: false,
  saml_accounts: [],
  static_data: {
    certificate: '',
    entity_id: '',
    callback_url: '',
  },
}

export default function marketplaceReducers(state = initialState, action: Action): SSOReduxType {
  switch (action.type) {
    case actions.ACCOUNTS_LOAD_PENDING:
    case actions.CREATE_ACCOUNT_PENDING:
    case actions.DELETE_ACCOUNT_PENDING:
    case actions.ACCOUNT_DATA_UPDATE_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ACCOUNTS_LOAD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        saml_accounts: action.payload.data?.data?.saml_accounts,
        static_data: action.payload.data?.data?.static_data,
      }
    }

    case actions.CREATE_ACCOUNT_FULFILLED:
    case actions.ACCOUNT_DATA_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
        saml_accounts: [action.payload.data?.data?.saml_account],
      }
    }

    case actions.DELETE_ACCOUNT_FULFILLED: {
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.CREATE_ACCOUNT_REJECTED:
    case actions.ACCOUNTS_LOAD_REJECTED:
    case actions.DELETE_ACCOUNT_REJECTED:
    case actions.ACCOUNT_DATA_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))

      return {
        ...state,
        fetching: false,
      }
    }
    default:
      return state
  }
}
