import { useEffect, useState } from 'react'
import I18n from 'translations/i18n'
import { useDispatch } from 'utils/hooks'
import {
  configurationsLoad,
  configurationsUpdate,
} from 'redux/organizationOnborading/organizationConfigurations/actionCreators'
import { Typography, Spacer, Radio, Flex } from '@jisr-hr/ds'
import { AxiosResponse } from 'axios'
import StepTemplate from '../../components/StepTemplate'
import { StepsTypes } from '../../types/onboardingTypes'
import styles from '../../styles.module.css'
import { useTrackingSteps } from '../../helper'

const Language = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  const dispatch = useDispatch()
  const [locale, setLocale] = useState<string | null>(localStorage.getItem('Locale'))

  const handleChange = (newLocale: string): void => {
    setLocale(newLocale)
    localStorage.setItem('Locale', newLocale)
    dispatch(
      configurationsUpdate({
        organization_configuration: { value: newLocale },
      }),
    ).then(() => window.location.reload())
  }

  type ConfigurationType = {
    data: {
      organization_configuration: {
        value: string
      }
    }
  }

  useTrackingSteps({ step_name: 'organization_info', level_name: 'language_selection' })
  useEffect(() => {
    setLocale(locale)
    dispatch(configurationsLoad()).then(
      ({ value }: { value: AxiosResponse<ConfigurationType> }) => {
        localStorage.setItem('Locale', value.data.data.organization_configuration.value)
      },
    )
  }, [])

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="title-1"
        text={I18n.t('whats_your_preferred_language')}
      />

      <Spacer height={16} />
      <Flex
        itemsCenter
        className={styles.radioBoxBordered}
      >
        <div style={{ cursor: 'pointer' }}>
          <Radio
            label="English"
            active={locale === 'en'}
            onChange={(): void => handleChange('en')}
          />
        </div>
      </Flex>
      <Spacer height={16} />
      <Flex
        itemsCenter
        className={styles.radioBoxBordered}
      >
        <div style={{ cursor: 'pointer' }}>
          <Radio
            label="عربي"
            active={locale === 'ar'}
            onChange={(): void => handleChange('ar')}
          />
        </div>
      </Flex>
    </StepTemplate>
  )
}

export default Language
