import { CSSProperties } from 'react'
import cns from 'classnames'
import MuiStepper from '@material-ui/core/Stepper'
import { Spacer, Flex, Typography } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { ReactComponent as CrossIcon } from '@jisr-hr/design-system/dist/assets/icons/CrossIcon.svg'
import { ReactComponent as CheckOutlineIcon } from '@jisr-hr/design-system/dist/assets/icons/CheckOutline.svg'
import styles from './styles.module.css'
import { StepsTypes } from '../../helper'

type TVerticalStepper = {
  steps: StepsTypes[]
  currentStep: number
  setCurrentStep: (t: number, s: StepsTypes) => void
  children?: JSX.Element
  stepperContainerStyle?: CSSProperties
}

type TRenderIcon = {
  isPassed: boolean
  isNotCompleted: boolean
}

function VerticalStepper({
  steps,
  currentStep,
  setCurrentStep,
  children,
  stepperContainerStyle,
}: TVerticalStepper): JSX.Element {
  const renderIcon = ({ isPassed, isNotCompleted }: TRenderIcon): JSX.Element => {
    if (isPassed) {
      if (isNotCompleted) {
        return <CrossIcon />
      }
      return <CheckOutlineIcon className={styles.checkIcon} />
    }
    return <Spacer width={15} />
  }

  return (
    <div className={styles.stepperWithContnetContainer}>
      <div
        className={cns(styles.stepperContainer, 'scroll-y')}
        style={stepperContainerStyle}
      >
        {currentStep > 0 && (
          <ul
            className={styles.stepper}
            style={{ paddingInline: 8 }}
          >
            <MuiStepper
              connector={<div style={{ paddingTop: 24, paddingInline: 0 }} />}
              orientation="vertical"
              activeStep={currentStep - 1}
              style={{ paddingInline: 0 }}
            >
              {steps.map((step) => {
                if (step.sectionTitle) {
                  return (
                    <>
                      <Typography
                        variant="body-new/regular"
                        text={step.sectionTitle}
                        style={{
                          marginBottom: 16,
                          ...(step.stepNo !== 1 && { marginTop: 40 }),
                        }}
                      />

                      <Flex
                        key={step.title}
                        onClick={(): void => setCurrentStep?.(step.stepNo || 0, step)}
                        itemsCenter
                        className={cns(styles.step, {
                          [styles.currentStep]: currentStep === step.stepNo,
                        })}
                      >
                        {renderIcon({
                          isPassed: currentStep > (step.stepNo || 0),
                          isNotCompleted: !step.is_completed,
                        })}
                        <Spacer width={6} />
                        <Typography
                          style={{ color: 'var(--color-base-colors-grey-600)' }}
                          variant="interface/default/sm"
                          text={I18n.t(step.title)}
                        />
                      </Flex>
                    </>
                  )
                }
                return (
                  <Flex
                    key={step.title}
                    onClick={(): void => setCurrentStep?.(step.stepNo || 0, step)}
                    itemsCenter
                    className={cns(styles.step, {
                      [styles.currentStep]: currentStep === step.stepNo,
                    })}
                  >
                    {renderIcon({
                      isPassed: currentStep > (step.stepNo || 0),
                      isNotCompleted: !step.is_completed,
                    })}
                    <Spacer width={6} />
                    <Typography
                      style={{ color: 'var(--color-base-colors-grey-600)' }}
                      variant="interface/default/sm"
                      text={I18n.t(step.title)}
                    />
                  </Flex>
                )
              })}
            </MuiStepper>
          </ul>
        )}
      </div>
      <div className={cns(styles.stepperContentContainer)}>{children}</div>
    </div>
  )
}

export default VerticalStepper
