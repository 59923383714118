import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

type TNotificationSettings = {
  id: number
  is_applicable: true | false
  name: string
  options: {
    announcements: string
    updates: string
    requests_and_tasks: string
  }
}

type TInitState = {
  employee_configuration: TNotificationSettings | Record<string, never>
  fetching: boolean
}

export const initialState = {
  fetching: false,
  employee_configuration: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { type, payload } = action

  switch (type) {
    case actions.GET_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING:
    case actions.UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.GET_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED:
    case actions.UPDATE_EMPLOYEE_NOTIFICATIONS_SETTING_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
