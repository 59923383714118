import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'

const DEFAULT_STATE = {
  fetching: false,
  shifts: [],
  shift: {},
  calculate_working_hours: '00',
  calculate_half_day_working_hours: '00',
  loading: false,
}

let changes = null
export default function shiftsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  let newRecord = {}

  switch (action.type) {
    case 'SHIFTS_LOAD_PENDING': {
      return {
        ...state,
        loading: !(state.shifts.length > 0),
      }
    }

    case 'SHIFTS_UPDATE_PENDING':
    case 'SHIFT_LOAD_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'SHIFTS_RESET':
      changes = {
        shifts: [],
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SHIFT_RESET':
      changes = {
        shift: {},
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SHIFTS_LOAD_FULFILLED':
      changes = {
        shifts: payload.data.data.shifts,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'CALCULATE_WORKING_HOURS_FULFILLED':
      changes = {
        calculate_working_hours: payload.data.data.working_hours,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'CALCULATE_HALF_DAY_WORKING_HOURS_FULFILLED':
      changes = {
        calculate_half_day_working_hours: payload.data.data.working_hours,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SHIFT_LOAD_FULFILLED':
      changes = {
        shift: payload.data.data.shift,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SHIFTS_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      newRecord = payload.data.data.shift
      changes = {
        shifts: [...state.shifts, newRecord],
        fetching: false,
      }
      return { ...state, ...changes }

    case 'SHIFTS_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
        shifts: updateOneListOfRecords(state.shifts, payload.data.data.shift),
        editing: {
          ...state.editing,
          [payload.data.data.shift.id]: false,
        },
      }
      return { ...state, ...changes }

    case 'SHIFTS_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        shifts: removeOneListOfRecords(state.shifts, payload.id),
        error: handleResponseErr(action.payload),
      }

    case 'SHIFTS_LOAD_REJECTED':
    case 'SHIFT_LOAD_REJECTED':
    case 'SHIFTS_DELETE_REJECTED':
    case 'SHIFTS_UPDATE_REJECTED':
    case 'SHIFTS_CREATE_REJECTED':
    case 'CALCULATE_WORKING_HOURS_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        ...changes,
        loading: false,
        fetching: false,
      }

    default:
      return state
  }
}
