import axios, { AxiosPromise } from 'axios'
import { ATS_ROOT, ORGANISATION_NAME } from 'env'
import Qs, { IStringifyOptions } from 'qs'
import { serializerConfig } from './apiUtils'
import errorResponseHandler from './errorHandler'

const instance = axios.create({
  withCredentials: true,
})

instance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: ORGANISATION_NAME,
  }
  return config
})

if (instance.interceptors) {
  instance.interceptors.response.use(undefined, errorResponseHandler)
}
type MethodType = 'POST' | 'DELETE' | 'PUT' | 'GET'

export type CandidateProfileField = {
  name_en?: boolean
  name_ar?: boolean
  phone_number?: boolean
  email?: boolean
  city?: boolean
  current_role?: boolean
  nationality?: boolean
  resume?: boolean
  links?: boolean
  address?: boolean
}

export type FieldType =
  | 'short_text'
  | 'paragraph'
  | 'multiple_choice'
  | 'attachments'
  | 'checkbox'
  | 'yes_no'

export type CandidateLinkType = {
  text: string
}

export type ScreeningQuestionType = {
  title: string
  question_type: FieldType
  is_required: boolean
  position: number
  options?: string[]
}

export type JobForm = {
  locale: 'en' | 'ar'
  candidate_profile_fields: CandidateProfileField
  screening_question?: ScreeningQuestionType[]
  candidate_link_types?: CandidateLinkType[]
}

export type AddExistingCandidateBody = {
  job_id?: number
  candidate_id?: number
}

export type GetAllJobFitler = {
  status: string[] | string // 'open' | 'closed' | 'draft'
  business_unit_id: number[]
  department_id: number[]
  employment_type_id: number[]
  location_id: number[]
  search: string
  hiring_managers?: number[]
  recruiters?: number[]
}

export type GetAllJobRequest = {
  filter?: GetAllJobFitler
  from?: string
  to?: string
  page?: number
  per_page?: number
  template?: 'public'
}

function atsAPI<P, D, R>(
  url: string,
  params: P,
  method: MethodType,
  data?: D,
  locale?: string,
  baseURL?: string,
): AxiosPromise<R> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: locale ?? localStorage.getItem('Locale') ?? 'en',
    slug: ORGANISATION_NAME,
  }

  const urlPath = baseURL ? baseURL + url : ATS_ROOT + url

  return instance({
    method,
    url: urlPath,
    params,
    paramsSerializer: (paramObject) =>
      Qs.stringify(paramObject, serializerConfig as Partial<IStringifyOptions>),
    data,
    headers,
  })
}

export { atsAPI }
