import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Modal } from '@jisr-hr/ds'
import { Loader } from 'components/global/JisrLoader/JisrLoader'
import i18n from 'translations/i18n'

// available variants: danger,warning,info
// hAlign => left, center, right
const ConfirmModal = ({
  fetching,
  showLoader = true,
  fullBtnWidth,
  subMessage,
  loadingButton,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  data,
  message,
  title,
  labelConfirm,
  labelCancel,
  hideConfirm,
  variant,
  withoutClose,
  customContent,
  disableSubmit,
  hideCancel,
  confirmStartIcon,
  showCloseButton = true,
  modalClassName,
  icon,
  withIcon = true,
  fancy = true,
  size,
}) => {
  const renderIcon = () => {
    switch (variant) {
      case 'danger':
        return 'alert-triangle'
      case 'warning':
        return 'alert-circle'
      case 'info':
        return 'info-circle'
      case 'success':
        return 'check-circle'
      default:
        return null
    }
  }

  const handleOnConfirm = (e) => onConfirm(e, data)

  return (
    <Modal
      disableCloseOut
      open={isOpen}
      variant={variant}
      size={size}
      fancy={fancy}
      onClose={onClose || onCancel}
      showCloseButton={showCloseButton}
      modalPaperClassName={modalClassName}
      header={{
        title: i18n.t(title),
        description: message,
        leadingSpace: withIcon && { icon: icon || renderIcon() },
        withClose: !withoutClose,
        disableSubmit: fetching || disableSubmit,
        descriptionTestId: 'message',
      }}
      // modalHeaderClassName={styles.confirmModalHeader}
      footer={{
        ...(!hideConfirm && {
          submit: {
            label: i18n.t(labelConfirm),
            leadingIcon: confirmStartIcon && confirmStartIcon,
            onClick: !loadingButton ? handleOnConfirm : () => {},
            disabled: fetching || disableSubmit,
            testId: 'confirm',
          },
        }),
        ...(!hideCancel && {
          cancel: {
            label: i18n.t(labelCancel ?? 'cancel'),
            onClick: onCancel,
            testId: 'cancel',
          },
        }),
        fullWidth: fullBtnWidth,
      }}
    >
      <div>
        {subMessage && (
          <Typography
            style={{
              color: 'var(--color-base-colors-grey-700)',
              marginTop: 4,
            }}
            variant="body-new/regular"
            text={subMessage}
          />
        )}
        {fetching && showLoader && <Loader fixed />}
        {customContent && <>{customContent}</>}
      </div>
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  labelConfirm: 'confirm',
  variant: 'danger',
}

ConfirmModal.propTypes = {
  fetching: PropTypes.bool,
  loadingButton: PropTypes.bool,
  hideCancel: PropTypes.bool,
  hideConfirm: PropTypes.bool,
  isOpen: PropTypes.bool,
  withoutClose: PropTypes.bool,
  showLoader: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.node,
  variant: PropTypes.string,
  title: PropTypes.string,
  labelCancel: PropTypes.string,
  subMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelConfirm: PropTypes.string,
  disableSubmit: PropTypes.bool,
  fullBtnWidth: PropTypes.bool,
  customContent: PropTypes.node,
  data: PropTypes.shape(),
  confirmStartIcon: PropTypes.node,
  showCloseButton: PropTypes.bool,
  modalClassName: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  withIcon: PropTypes.bool,
}

export default ConfirmModal
