import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { handleResponce } from './helper'
import * as actions from './actions'

const initialState = {
  report: {
    records: [],
    header: {},
    headers: {},
    main_header: {},
  },
  pagination: {},
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.FAMILY_DETAILS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.FAMILY_DETAILS_LOAD_FULFILLED:
      return {
        ...state,
        report: handleResponce({ ...payload.data.data }),
        pagination: payload.data.data?.pagination,
        fetching: false,
      }

    case actions.FAMILY_DETAILS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
