import { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { FileUpload } from '@jisr-hr/ds'
import { FieldValidator } from 'final-form'
import { FileType } from 'containers/authorised/employee/EmployeeDetails/Tabs/Personal/components/types'
import { AttachmentType } from 'types/sharedResponse'
import { Toastr } from 'components/global/Toastr'
import I18n from 'translations/i18n'

type MultibleCheckTypes =
  | {
      multiple: true
      onLoad?: (file: FileType[]) => void
    }
  | {
      multiple?: false
      onLoad?: (file: FileType) => void
    }

type FileUploadFieldType = MultibleCheckTypes & {
  accept?: string
  className?: string
  classNameFiles?: string
  customKeys?: {
    file?: string
    id?: string
    name?: string
  }
  errorMessage?: string
  fileList?: never[]
  fileType?: string
  hasDeleteButton?: boolean
  hideUploadBox?: boolean
  initFiles?: never[] | AttachmentType[]
  keepPrevious?: boolean
  label?: string
  loadingMessage?: string
  message?: string | JSX.Element
  name: string
  status?: 'loading' | 'success' | 'error' | 'init'
  successMessage?: string
  testId?: string
  disabled?: boolean
  useBase64?: boolean
  validate?: FieldValidator<unknown>
  variant?: string
  onClear?: () => void
  onRemove?: (val: never) => void
  uploadingMessage?: string
}

type ErrorsType = Array<{ errors: Array<{ code: string; message: string }> }>

const FileUploadField = ({
  name,
  validate,
  testId,
  onLoad,
  onClear,
  useBase64,
  hasDeleteButton = true,
  accept,
  ...props
}: FileUploadFieldType): JSX.Element => {
  const [showErrors, setErrors] = useState<ErrorsType>()
  const handleError = (errorsList: ErrorsType): void => {
    errorsList?.forEach(({ errors }) => {
      errors?.forEach((e) =>
        e?.code === 'file-too-small'
          ? (Toastr.error(I18n.t('empty_file_error_msg')) as unknown)
          : (Toastr.error(e?.message) as unknown),
      )
    })
  }

  useEffect(() => {
    if (showErrors) {
      handleError(showErrors)
    }
  }, [showErrors])

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ input, meta }): JSX.Element => (
        <FileUpload
          {...props}
          {...input}
          accept={{
            acceptedFiles: accept ? accept?.split(', ').map((type) => type?.trim()) : [],
          }}
          errorMessage={meta.error}
          status={meta.error && meta.touched && 'error'}
          onLoad={(file: FileType[] & FileType): void => {
            if (file) {
              if (onLoad) {
                onLoad(file)
              }
              if (props.multiple) {
                const files = file.map((f: FileType) => {
                  if (props.customKeys) {
                    return {
                      ...(!!props.customKeys?.id && {
                        [props.customKeys.id]: f.id,
                      }),
                      ...(!!props.customKeys?.file && {
                        [props.customKeys.file]: f.file,
                      }),
                      ...(!!props.customKeys?.name && {
                        [props.customKeys.name]: f.name,
                      }),
                    }
                  }
                  return {
                    file: f.file,
                    file_file_name: f.name,
                    size: f.size,
                    type: f.type,
                  }
                })
                return input.onChange(files)
              }
              if (useBase64) {
                return input.onChange(file.file)
              }
              return input.onChange({
                file: file.file,
                file_file_name: file.name,
                size: file.size,
                type: file.type,
              })
            }
            if (onClear) {
              return onClear()
            }
            return input.onChange('')
          }}
          data-testid={testId}
          hasDeleteButton={hasDeleteButton}
          onError={(errors: ErrorsType): void => setErrors(errors)}
        />
      )}
    </Field>
  )
}

export default FileUploadField
