export const ACCOUNTS_LOAD = 'ACCOUNTS_LOAD'
export const ACCOUNTS_LOAD_FULFILLED = 'ACCOUNTS_LOAD_FULFILLED'
export const ACCOUNTS_LOAD_PENDING = 'ACCOUNTS_LOAD_PENDING'
export const ACCOUNTS_LOAD_REJECTED = 'ACCOUNTS_LOAD_REJECTED'

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const CREATE_ACCOUNT_FULFILLED = 'CREATE_ACCOUNT_FULFILLED'
export const CREATE_ACCOUNT_PENDING = 'ACREATE_ACCOUNT_PENDING'
export const CREATE_ACCOUNT_REJECTED = 'CREATE_ACCOUNT_REJECTED'

export const ACCOUNT_DATA_UPDATE = 'ACCOUNT_DATA_UPDATE'
export const ACCOUNT_DATA_UPDATE_FULFILLED = 'ACCOUNT_DATA_UPDATE_FULFILLED'
export const ACCOUNT_DATA_UPDATE_PENDING = 'AACCOUNT_DATA_UPDATE_PENDING'
export const ACCOUNT_DATA_UPDATE_REJECTED = 'ACCOUNT_DATA_UPDATE_REJECTED'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_FULFILLED = 'DELETE_ACCOUNT_FULFILLED'
export const DELETE_ACCOUNT_PENDING = 'DELETE_ACCOUNT_PENDING'
export const DELETE_ACCOUNT_REJECTED = 'DELETE_ACCOUNT_REJECTED'
