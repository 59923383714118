import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  letter_requests: [],
  letter_request: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

let changes = null
export default function letterRequestsReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'LETTER_REQUEST_CREATE_PENDING':
    case 'LETTER_REQUEST_UPDATE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'LETTER_REQUEST_CREATE_REJECTED':
    case 'LETTER_REQUEST_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LETTER_REQUEST_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        letter_request: payload.data.data.letter_requests,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LETTER_REQUEST_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        ...payload.data.data,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
