import { ReactNode } from 'react'
import { Typography, Spacer, PopoverProps } from '@jisr-hr/ds'
import { Tooltip } from '@jisr-hr/ds-beta'
import BlockScroll from 'containers/authorised/employee/components/BlockScroll'
import styles from './styles.module.css'

export type TOnboardingTourProps = Pick<PopoverProps, 'position' | 'showArrow'> & {
  open: boolean
  text: string
  trigger: ReactNode
  content?: ReactNode
  footer?: ReactNode
  highlightPosition?: {
    top: string
    left: string
    width: string
    height: string
  }
  version?: 'v1' | 'v2'
}
const OnboardingTour = (props: TOnboardingTourProps): JSX.Element => {
  const {
    open,
    text,
    trigger,
    content,
    footer,
    position = 'bottom-start',
    highlightPosition,
    version = 'v1',
  } = props

  return (
    <>
      <BlockScroll
        open={open}
        className={styles.disableClickOutBackground}
        highlightPosition={highlightPosition}
      />
      <Tooltip
        behavior="popover"
        open={open}
        onClose={(): void => undefined}
        disableCloseOut
        placement={position}
        trigger={trigger}
      >
        <div className={styles.onboardingTour}>
          <Typography
            variant={version === 'v2' ? 'body-new/semibold' : 'body-new/medium'}
            text={text}
          />
          {content && (
            <>
              <Spacer height={8} />
              {content}
            </>
          )}
          {footer && (
            <>
              <Spacer height={8} />
              {footer}
            </>
          )}
        </div>
      </Tooltip>
    </>
  )
}

export default OnboardingTour
