import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@jisr-hr/ds'
import { format } from 'utils/date'
import { useDispatch } from 'react-redux'
import { useSelector } from 'utils/hooks'
import { requestDetailsLoad } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import { updateTask } from 'redux/requests/newRequestsApproval/actionCreators'
import RequestedBy from './components/RequestedBy'
import Frame from '../../ds/Frame'

import Styles from './Info.module.css'
import InfoList from '../../ds/InfoList'
import { items } from './helper'
import ProfileAndTime from '../../ds/ProfileAndTime'
import { useRequest } from '../../RequestProvider'

const Info = ({ details, customItems, showColumns = [] }) => {
  const dispatch = useDispatch()
  const requestHook = useRequest()
  const is_multi_payroll_enabled = useSelector(
    (s) => !!s.auth.employee?.organization?.multi_payroll_enabled,
  )

  const isDiffEmp = details.created_by?.id !== details?.employee?.id
  const isTask = requestHook?.vars?.request?.isTask

  const isCancellationRequestLinked =
    details?.is_cancellation_request || !!details?.cancellation_request_info

  const withCancellationRequest = isCancellationRequestLinked
    ? ['cancellation_request_linking', ...showColumns]
    : [...showColumns]

  const isPayrollPayment = details?.category === 'payroll_payment_task'

  const handleRequestDetail = (request) => {
    requestHook?.setRequestType(
      details?.is_cancellation_request
        ? request?.request_name
        : request?.cancellation_request_info?.type,
    )
    dispatch(
      requestDetailsLoad(
        request?.employee?.id,
        details?.is_cancellation_request
          ? { request_id: request?.request?.id }
          : { request_id: request?.cancellation_request_info?.id },
      ),
    )
  }

  const detailsInfo = {
    ...details,
    ...(isCancellationRequestLinked && {
      onClick: () => handleRequestDetail(details),
    }),
    ...(isTask && {
      handelDueDate: (e) => {
        dispatch(
          updateTask(details.id, {
            task: {
              due_date: new Date(e),
            },
          }),
        )
      },
    }),
  }

  return (
    <Frame>
      <Flex
        flexCol
        className={Styles.info}
      >
        {details?.created_by?.name_i18n && isDiffEmp && (
          <RequestedBy
            name={details?.created_by?.name_i18n}
            jobtitle={details?.created_by?.job_title}
          />
        )}
        <ProfileAndTime
          name={details?.employee?.full_name_i18n}
          code={details?.employee?.code}
          jobTitle={details?.employee?.job_title}
          avatar={details?.employee?.avatar_thumb}
          time={format(details?.created_at, 'hh:mm a')}
          date={format(details?.created_at, 'dd MMM, yyyy')}
          is_created_by_automation={details?.is_created_by_automation}
        />
        <InfoList
          items={
            customItems ||
            items(detailsInfo)
              ?.filter(({ key }) =>
                [
                  'sequential_id',
                  'type',
                  'status',
                  'payment_date',
                  isTask && 'due_date',
                  isPayrollPayment && is_multi_payroll_enabled && 'paygroup_name',
                  isPayrollPayment && is_multi_payroll_enabled && 'payrun_month',
                  ...withCancellationRequest,
                ]?.includes(key),
              )
              .concat(details?.items || [])
          }
        />
        {details?.alert}
      </Flex>
    </Frame>
  )
}

Info.propTypes = {
  details: PropTypes.shape(),
  customItems: PropTypes.arrayOf(PropTypes.shape()),
  showColumns: PropTypes.arrayOf(PropTypes.string),
}

export default Info
