import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FileInput from 'components/global/form/FileInput'
import I18n from 'translations/i18n'
import attachIcone from 'assets/images/attachment.svg'
import { Flex } from 'components/global/atoms'
import './style.css'

class AttachmentComponent extends Component {
  state = {
    file_name: null,
    files: [],
  }

  onAttachFile = (file) => {
    const { onDone } = this.props
    if (this.props.multiple) {
      this.setState({
        files: file,
      })
      this.props.input.onChange(file)
    } else {
      this.setState({
        file_name: file.file_file_name,
      })
      this.props.input.onChange(file.file)
    }
    if (onDone) {
      onDone(file)
    }
  }

  renderContent = () => {
    const { file_name, files } = this.state
    const { multiple, attachmentName } = this.props

    if (files.length || file_name || attachmentName) {
      const fileName = file_name || attachmentName
      return (
        <span className="attach-name">
          {multiple ? files.map((file) => `${file.file_file_name} ,`) : fileName}
        </span>
      )
    }
    return (
      <Flex className="add-statment">
        <img
          src={attachIcone}
          alt=""
        />
        <p>{I18n.t('add_attachment')}</p>
      </Flex>
    )
  }

  render() {
    const {
      // label,
      meta: { touched, error },
      message,
      testId,
      disabled,
    } = this.props

    return (
      <div className="uploadBox">
        <label
          className="upload"
          htmlFor="attachmentBrowse"
        >
          {this.renderContent()}
          <FileInput
            data-testid={testId}
            onDone={this.onAttachFile}
            type="file"
            className="hidden"
            disabled={disabled}
            multiple={this.props.multiple || false}
            id="attachmentBrowse"
            style={{
              display: 'none',
            }}
          />
        </label>
        {touched && error && (
          <small
            className="form-text"
            style={{
              color: '#f4506c',
            }}
          >
            {error}
          </small>
        )}
        {message && <small>{message}</small>}
      </div>
    )
  }
}

AttachmentComponent.propTypes = {
  // label: PropTypes.string,
  input: PropTypes.shape(),
  onDone: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape(),
  message: PropTypes.string,
  attachmentName: PropTypes.string,
  testId: PropTypes.string,
}

export default AttachmentComponent
