import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { Action } from 'types/redux'
import {
  EmploymentFileApprove,
  EmploymentFileType,
} from 'containers/authorised/employee/EmployeeDetails/Tabs/EmployeeFiles/EmployeeDocuments/EmployeeDocuments'

export function filesLoad(employee_id: number): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employment_files/`
  const method = 'GET'

  return {
    type: 'FILES_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function fileLoad(employee_id: number, id: number): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employment_files/${id}`
  const method = 'GET'

  return {
    type: 'FILE_LOAD',
    payload: handleAPI(url, {}, method),
  }
}

export function fileCreate(
  employee_id: number,
  data: EmploymentFileType & EmploymentFileApprove,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employment_files/`
  const method = 'POST'
  return {
    type: 'FILE_CREATE',
    payload: handleAPI(url, {}, method, { employment_file: data }),
  }
}

export function fileUpdate(
  employee_id: number,
  data: EmploymentFileType & EmploymentFileApprove,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employment_files/${data.id}/`
  const method = 'PUT'
  return {
    type: 'FILE_UPDATE',
    payload: handleAPI(url, {}, method, { employment_file: data }),
  }
}

export function fileDelete(employee_id: number, id: number): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/employment_files/${id}/`
  const method = 'DELETE'

  return {
    type: 'FILE_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, {}, method)
        ?.then((response) => {
          resolve({ id, ...response })
        })
        .catch((err) => {
          reject(err)
        })
    }),
  }
}

// export function fileEdit(file): Action {
//   return {
//     type: 'FILE_EDIT',
//     file,
//   };
// }

// export function fileEditCancel(id): Action {
//   return {
//     type: 'FILE_EDIT_CANCEL',
//     id,
//   };
// }

// export function resetForm(): Action {
//   return {
//     type: 'RESET_FORM',
//     employment_file: {},
//   };
// }

export function fileCreateDocumentRequest(employee_id: number, data: EmploymentFileType): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/document_requests/`
  const method = 'POST'
  return {
    type: 'DOCUMENT_REQUEST_CREATE',
    payload: handleAPI(url, {}, method, { document_request: data }),
  }
}

export function documentRequestsLoad(
  employee_id: number,
  params: Record<string, unknown> = {},
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/document_requests/`
  const method = 'GET'

  return {
    type: 'DOCUMENT_REQUESTS_LOAD',
    payload: handleAPI(url, params, method),
  }
}

export function documentRequestsUpdate(
  employee_id: number,
  id: number,
  data: Record<string, unknown>,
): Action {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/document_requests/${id}`
  return {
    type: 'DOCUMENT_REQUEST_UPDATE',
    payload: handleAPI(url, {}, 'PUT', { document_request: data }),
  }
}

export function reset(): Action {
  return {
    type: 'RESET',
  }
}
