import React from 'react'
import PropTypes from 'prop-types'

const Space = ({ size }) => (
  <div
    style={{ height: size }}
    className="jisr-space"
  />
)

Space.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default Space
