import {
  LEAVE_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_STATUSES,
  PAYMENT_MODES,
} from 'components/global/constants'
import I18n from 'translations/i18n'

const staticRecords = {
  record_types: [
    { name: 'delay', id: 'employee_with_delay' },
    { name: 'absence', id: 'employee_with_absence' },
    { name: 'missing_punch', id: 'employee_with_missing_punch' },
  ],
  uncorrect_data: [
    { name: 'all', id: 'all' },
    { name: 'uncorrect_data', id: 'uncorrect_data' },
    { name: 'correct_data', id: 'correct_data' },
  ],
  gosi_linked_data: [
    { name: 'all', id: 'all' },
    { name: 'not_registered_gosi', id: false },
    { name: 'registered_gosi', id: true },
  ],
  shift_types: [
    { name: 'all', id: 'all' },
    { name: 'normal', id: 'Normal' },
    { name: 'split', id: 'Split' },
    { name: 'flexible', id: 'Flexible' },
  ],
  shift_type: [
    { name: 'all', id: 'all' },
    { name: 'fixed', id: 'fixed' },
    { name: 'rotated', id: 'rotated' },
  ],
  punch_in: [
    { name: 'all', id: 'all' },
    { name: 'no_record', id: 'no_record' },
    { name: 'late_in', id: 'late_in' },
  ],
  punch_out: [
    { name: 'all', id: 'all' },
    { name: 'no_record', id: 'no_record' },
    { name: 'early_out', id: 'early_out' },
  ],
  device_status: [
    { name: 'geofencing', id: 4 },
    { name: 'device_online', id: 1 },
    { name: 'device_pending', id: 3 },
    { name: 'device_offline', id: 2 },
  ],
  leave_statuses: LEAVE_STATUSES,
  request_type: [
    // Finance group
    { name: 'expense_claim_request', id: 'ExpenseClaimRequest', group: 'finance' },
    { name: 'loan_cancellation_request', id: 'LoanCancellationRequest', group: 'finance' },
    { name: 'loan_request', id: 'LoanRequest', group: 'finance' },
    {
      name: 'expense_claim_cancellation_request',
      id: 'ExpenseClaimCancellationRequest',
      group: 'finance',
    },
    {
      name: 'final_settlement_review_request',
      id: 'FinalSettlementRequest',
      group: 'finance',
    },
    {
      name: 'vacation_settlement_review_request',
      id: 'VacationSettlementRequest',
      group: 'finance',
    },
    { name: 'payroll_review_request', id: 'RegularReviewRequest', group: 'finance' },

    // Attendance group
    { name: 'excuse_cancellation_request', id: 'ExcuseCancellationRequest', group: 'attendance' },
    { name: 'excuse_request', id: 'ExcuseRequest', group: 'attendance' },
    {
      name: 'missing_punch_cancellation_request',
      id: 'MissingPunchCancellationRequest',
      group: 'attendance',
    },
    { name: 'missing_punch_request', id: 'MissingPunchRequest', group: 'attendance' },
    {
      name: 'overtime_cancellation_request',
      id: 'OvertimeCancellationRequest',
      group: 'attendance',
    },
    { name: 'overtime_request', id: 'OvertimeRequest', group: 'attendance' },

    // Other group
    { name: 'leave_cancellation_request', id: 'LeaveCancellationRequest', group: 'others' },
    { name: 'leave_request', id: 'LeaveRequest', group: 'others' },
    {
      name: 'leave_resumption_cancellation_request',
      id: 'LeaveResumptionCancellationRequest',
      group: 'others',
    },
    { name: 'leave_resumption_request', id: 'LeaveResumptionRequest', group: 'others' },
    {
      name: 'business_trip_cancellation_request',
      id: 'BusinessTripCancellationRequest',
      group: 'others',
    },
    { name: 'business_trip_request', id: 'BusinessTripRequest', group: 'others' },
    {
      name: 'exit_reentry_visa_cancellation_request',
      id: 'ExitReentryVisaCancellationRequest',
      group: 'others',
    },
    { name: 'exit_reentry_visa_request', id: 'ExitReentryVisaRequest', group: 'others' },
    { name: 'asset_cancellation_request', id: 'AssetCancellationRequest', group: 'others' },
    { name: 'asset_request', id: 'AssetRequest', group: 'others' },
    {
      name: 'asset_clear_cancellation_request',
      id: 'AssetClearCancellationRequest',
      group: 'others',
    },
    { name: 'asset_clear_request', id: 'AssetClearRequest', group: 'others' },
    { name: 'hiring_cancellation_request', id: 'HiringCancellationRequest', group: 'others' },
    { name: 'hiring_request', id: 'HiringRequest', group: 'others' },
    {
      name: 'resignation_cancellation_request',
      id: 'ResignationCancellationRequest',
      group: 'others',
    },
    { name: 'resignation_request', id: 'ResignationRequest', group: 'others' },
    { name: 'delegation_request', id: 'DelegationRequest', group: 'others' },
    { name: 'document_request', id: 'DocumentRequest', group: 'others' },
    { name: 'info_change_request', id: 'InfoChangeRequest', group: 'others' },
    { name: 'letter_request', id: 'LetterRequest', group: 'others' },
    { name: 'flight_ticket_request', id: 'FlightTicketRequest', group: 'others' },
    {
      name: 'flight_ticket_cancellation_request',
      id: 'FlightTicketCancellationRequest',
      group: 'others',
    },
  ],
  tasks_request_type: [
    { name: 'loan_payment_task', id: 'loan_payment_task' },
    { name: 'payroll_payment_task', id: 'payroll_payment_task' },
    { name: 'employee_gosi_registration_task', id: 'employee_gosi_registration_task' },
    { name: 'gosi_salary_updation_task', id: 'gosi_salary_updation_task' },
    { name: 'expense_claim_payment_task', id: 'expense_claim_payment_task' },
    { name: 'advance_vacation_salary_payment_task', id: 'advance_vacation_salary_payment_task' },
    { name: 'final_settlement_payment_task', id: 'final_settlement_payment_task' },
    { name: 'exit_reentry_payment_task', id: 'exit_reentry_payment_task' },
    { name: 'ticket_payment_task', id: 'ticket_payment_task' },
    { name: 'issuing_ticket_task', id: 'issuing_ticket_task' },
    { name: 'business_trip_payment_task', id: 'business_trip_payment_task' },
    { name: 'letter_issuing_task', id: 'letter_issuing_task' },
    { name: 'asset_allocation_task', id: 'asset_allocation_task' },
    { name: 'asset_deallocation_task', id: 'asset_deallocation_task' },
    { name: 'label.hiring_request_task', id: 'hiring_request_task' },
    { name: 'action.book_new_flight_ticket', id: 'book_flight_ticket_task' },
  ],
  request_statuses: [
    { name: 'all', id: 'all' },
    { name: 'pending', id: 'Pending' },
    { name: 'status.pending_correction', id: 'Pending for Correction' },
    { name: 'approved', id: 'Approved' },
    { name: 'rejected', id: 'Rejected' },
    { name: 'expired', id: 'Expired' },
    { name: 'canceled', id: 'Cancelled' },
  ],
  ats_job_opening_statuses: [
    { name: 'all', id: 'all' },
    { name: 'open', id: 'open' },
    { name: 'draft', id: 'draft' },
    { name: 'closed', id: 'closed' },
  ],
  candidate_statuses: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('assigned'), id: 'assigned' },
    { name: I18n.t('not_assigned'), id: 'unAssigned' },
  ],
  candidate_resume: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('label.attached'), id: 'attached' },
    { name: I18n.t('label.no_resume'), id: 'no_resume' },
  ],
  tasks_request_statuses: [
    { name: 'all', id: 'all' },
    { name: 'pending', id: 'Pending' },
    { name: 'completed', id: 'Completed' },
    { name: 'cancelled', id: 'Cancelled' },
    { name: 'rejected', id: 'Rejected' },
  ],
  task_statuses: [
    { name: 'completed', id: 'Completed' },
    { name: 'pending', id: 'Pending' },
  ],
  tasks_categories: [
    { name: 'exit_reentry_payment_task', id: 'exit_reentry_payment_task' },
    { name: 'ticket_payment_task', id: 'ticket_payment_task' },
    { name: 'issuing_ticket_task', id: 'issuing_ticket_task' },
    { name: 'loan_payment_task', id: 'loan_payment_task' },
    { name: 'advance_vacation_salary_payment_task', id: 'advance_vacation_salary_payment_task' },
    { name: 'final_settlement_payment_task', id: 'final_settlement_payment_task' },
    { name: 'business_trip_payment_task', id: 'business_trip_payment_task' },
    { name: 'expense_claim_payment_task', id: 'expense_claim_payment_task' },
    { name: 'payroll_payment_task', id: 'payroll_payment_task' },
    { name: 'employee_gosi_registration_task', id: 'employee_gosi_registration_task' },
    { name: 'gosi_salary_updation_task', id: 'gosi_salary_updation_task' },
    { name: 'letter_issuing_task', id: 'letter_issuing_task' },
  ],
  leave_types: [{ name: 'business_trip', id: 'Business trip' }],
  statuses: [
    { name: 'active', id: 1 },
    { name: 'inactive', id: 2 },
  ],

  document_type_status: [
    { name: 'expired', id: 'expired' },
    { name: 'not_expired', id: 'not expired' },
  ],
  payment_statuses: PAYMENT_STATUSES,
  payment_methods: PAYMENT_METHODS,
  payment_modes: PAYMENT_MODES,
  status_employee: [
    { name: 'all', id: 'all' },
    { name: 'under_probation', id: 'Under_probation' },
  ],

  employment_document_type: [
    { id: 'ID', name: 'ID', name_i18n: I18n.t('id_constants') },
    { id: 'Contract', name: 'Contract', name_i18n: I18n.t('contract') },
    { id: 'Iqama', name: 'Iqama', name_i18n: I18n.t('iqama') },
    { id: 'Passport', name: 'Passport', name_i18n: I18n.t('passport') },
  ],
  payrun_types: [
    { name: 'vacation_settlement', id: 'vs' },
    { name: 'final_settlement', id: 'eos' },
  ],
  payrun_status: [
    { name: 'paid', id: 'paid' },
    { name: 'confirmed', id: 'confirmed' },
  ],
  payroll_transaction_status: [
    { name: 'open', id: 'initiated' },
    { name: 'cancelled', id: 'cancelled' },
    { name: 'paid', id: 'paid' },
  ],
  assetStatuses: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('available'), id: 'Available' },
    { name: I18n.t('allocated'), id: 'Allocated' },
    { name: I18n.t('inrepair'), id: 'In-Repair' },
    { name: I18n.t('damaged'), id: 'Damaged' },
    { name: I18n.t('archived'), id: 'Archived' },
  ],
  pending_on: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('from_you'), id: 'self' },
    { name: I18n.t('from_other_approvals'), id: 'others' },
  ],
  tasks_pending_on: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('you'), id: 'self' },
    { name: I18n.t('others_2'), id: 'others' },
  ],
  filter_payrun_status: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('open'), id: 'draft' },
    { name: I18n.t('pending_on_approval'), id: 'under_review' },
    { name: I18n.t('approved'), id: 'confirmed' },
    { name: I18n.t('rejected'), id: 'changes_required' },
    { name: I18n.t('paid'), id: 'paid' },
    { name: I18n.t('cancelled'), id: 'cancelled' },
  ],
  filter_attendance_status: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('attendance_requested'), id: 'pending' },
    { name: I18n.t('attendance_approved'), id: 'confirmed' },
    { name: I18n.t('attendance_dismissed'), id: 'dismissed' },
    { name: I18n.t('no_attendance_request'), id: 'no_attendance' },
  ],
  payment_processing_methods: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('Cash'), id: 'Cash' },
    { name: I18n.t('International Transfer'), id: 'International Transfer' },
    { name: I18n.t('Cheque'), id: 'Cheque' },
    { name: I18n.t('Bank'), id: 'Bank' },
  ],
  insurance_statuses: [
    { name: I18n.t('insurance_statuse_draft'), id: 'draft' },
    { name: I18n.t('insurance_statuse_rejected'), id: 'rejected' },
    { name: I18n.t('pending_payment'), id: 'pending_payment' },
    { name: I18n.t('action_required'), id: 'action_required' },
    { name: I18n.t('insurance_statuse_pending_approval'), id: 'pending_approval' },
    { name: I18n.t('under_review'), id: 'under_review' },
    { name: I18n.t('insurance_statuse_completed'), id: 'completed' },
  ],
  member_linked_data: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('linked'), id: true },
    { name: I18n.t('not_linked'), id: false },
  ],
  enrollment_eligibility_data: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('label.eligible'), id: true },
    { name: I18n.t('label.not_eligible'), id: false },
  ],
  endorsement_member_type_data: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('the_employee'), id: 'employee' },
    { name: I18n.t('dependent'), id: 'dependent' },
  ],
  action_type: [
    { name: I18n.t('all'), id: 'all' },
    { name: I18n.t('add_employee'), id: 'create' },
    { name: I18n.t('update_employee'), id: 'update' },
    { name: I18n.t('delete_employee'), id: 'delete' },
    { name: I18n.t('offboard_employee'), id: 'offboard' },
    { name: I18n.t('undo_offboarding'), id: 'undo_offboard' },
    { name: I18n.t('label.contracts'), id: 'contracts' },
    { name: I18n.t('action.delete_from_group'), id: 'delete_from_group' },
    { name: I18n.t('action.add_to_group_in_search'), id: 'add_to_group' },
    { name: I18n.t('action.update_group'), id: 'update_group' },
  ],
  duration: [
    { name: I18n.t('from'), id: 'from' },
    { name: I18n.t('to'), id: 'to' },
  ],
  invitations_statuses: [
    { name: 'label.all', id: 'all' },
    { name: 'status.expired', id: 'expired' },
    { name: 'status.failed', id: 'not_delivered' },
    { name: 'status.pending', id: 'pending' },
    { name: 'status.accepted', id: 'accepted' },
  ],
  invoices_status: [
    { name: 'label.paid', id: 'paid' },
    { name: 'create_label.not_paid', id: 'not_paid' },
    { name: 'create_label.payment_due', id: 'payment_due' },
  ],
  payments: [
    { name: 'create_labe.subscription_plan', id: 'subscription_plan' },
    { name: 'label.addon', id: 'addon' },
    { name: 'label.apps', id: 'apps' },
  ],
  error_status: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: I18n.t('label.errors_only'), id: 'errors_only' },
  ],
  contract_duration: [
    { name: I18n.t('label.count_years'), id: '1 year' },
    { name: I18n.t('label.count_years', { count: 2 }), id: '2 years' },
    { name: I18n.t('label.count_years', { count: 3 }), id: '3 years' },
    { name: I18n.t('label.count_years', { count: 4 }), id: '4 years' },
    { name: I18n.t('label.count_years', { count: 5 }), id: '5 years' },
    { name: I18n.t('label.other'), id: 'Other' },
    { name: I18n.t('label.not_specified'), id: 'Not Specified' },
  ],
  form_type: [
    { name: I18n.t('label.calendar_year-1'), id: 'CalendarYearForm' },
    { name: I18n.t('probation_period'), id: 'ProbationPeriodForm' },
    { name: I18n.t('label.end_of_contract'), id: 'ContractForm' },
    { name: I18n.t('label.fixed_duration'), id: 'FixedDurationForm' },
  ],
  form_status: [
    { name: I18n.t('label.status.no_action_required'), id: 'no_action_required' },
    { name: I18n.t('label.status.pending_evaluation'), id: 'pending_evaluation' },
    { name: I18n.t('status.in_progress'), id: 'in_progress' },
    { name: I18n.t('status.submitted'), id: 'submitted' },
  ],
  frequency: [
    { name: I18n.t('one_time_evaluation'), id: 'one_time_evaluation' },
    { name: I18n.t('monthly_op'), id: 'monthly' },
    { name: I18n.t('quarterly'), id: 'quarterly' },
    { name: I18n.t('semi_annual_op'), id: 'semi_annual' },
    { name: I18n.t('annually'), id: 'annually' },
  ],
  event_type_history: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: I18n.t('label.export'), id: 'export' },
    { name: I18n.t('label.share'), id: 'share' },
  ],
  file_type_export_history: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: 'PDF', id: 'pdf' },
    { name: 'XLS', id: 'xls' },
  ],
  export_type_export_history: [
    { name: I18n.t('attendance_details_report'), id: 'attendance_details' },
    { name: I18n.t('attendance_logs'), id: 'attendance_logs' },
    { name: I18n.t('attendance_report'), id: 'attendance_report' },
    { name: I18n.t('attendance_summary_report'), id: 'attendance_summary' },
    { name: I18n.t('attendance_tracking_report'), id: 'attendance_tracking' },
    {
      name: I18n.t('bulk_employees_attendance_details_report'),
      id: 'bulk_employees_attendance_details',
    },
    { name: I18n.t('edit_employee_profile_report'), id: 'employee_information_changes' },
    { name: I18n.t('employee_attendance_details_report'), id: 'employee_attendance_details' },
    { name: I18n.t('employee_master_report'), id: 'employees' },
    { name: I18n.t('end_of_service_report'), id: 'eos_report' },
    { name: I18n.t('company_documents'), id: 'expiring_document_organizations' },
    { name: I18n.t('employee_documents'), id: 'expiring_document_employees' },
    { name: I18n.t('final_settlement_report'), id: 'final_settlement' },
    { name: I18n.t('off_cycle_payruns'), id: 'off_cycle_payruns' },
    { name: I18n.t('out_of_payroll_report'), id: 'out_of_payroll_report' },
    { name: I18n.t('outsourcing_employees_report'), id: 'outsourcing_employees_report' },
    { name: I18n.t('payroll_report'), id: 'payrun' },
    { name: I18n.t('employees_performance'), id: 'pm_employee_reviews' },
    { name: I18n.t('revenue_report'), id: 'revenue' },
    { name: I18n.t('pay_slip'), id: 'payslips' },
    { name: I18n.t('payruns_remarks_report'), id: 'remarks' },
    { name: I18n.t('gosi_report'), id: 'gosi' },
    { name: I18n.t('loans_report'), id: 'loans' },
    { name: I18n.t('out_of_payroll_transactions'), id: 'out_of_payroll_transactions' },
    { name: I18n.t('ticket_provision_report'), id: 'ticket_provisions' },
    { name: I18n.t('assets_allocation_report'), id: 'employee_assets' },
    { name: I18n.t('family_details_report'), id: 'family_details' },
    { name: I18n.t('approved_overtime_report'), id: 'overtime_report' },
    { name: I18n.t('job_reports'), id: 'ats_jobs' },
    { name: I18n.t('candidate_reports'), id: 'ats_candidates' },
    { name: I18n.t('leaves_report'), id: 'leaves' },
    { name: I18n.t('leave_balance_report'), id: 'leave_balances' },
    { name: I18n.t('journal'), id: 'journal' },
  ],
  status_export_history: [
    { name: I18n.t('label.processing'), id: 'in_progress' },
    { name: I18n.t('label.success'), id: 'success' },
    { name: I18n.t('label.error'), id: 'failed' },
    { name: I18n.t('archived'), id: 'archived' },
  ],
  violation_status: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: I18n.t('label.pending'), id: 'pending' },
    { name: I18n.t('status.pending_employee_response'), id: 'statement_requested' },
    { name: I18n.t('label.employee_responeded'), id: 'employee_responded' },
    { name: I18n.t('label.action_decided'), id: 'decided' },
    { name: I18n.t('label.action_applied'), id: 'applied' },
    { name: I18n.t('label.action_applied_and_expried'), id: 'expired' },
    { name: I18n.t('status.pending_dispute'), id: 'dispute_requested' },
    { name: I18n.t('status.disputed'), id: 'disputed' },
    { name: I18n.t('status.reversed'), id: 'reverted' },
    { name: I18n.t('label.voided'), id: 'voided' },
  ],
  timesheet_settlement: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: I18n.t('label.settlements.leaves'), id: 'vacation_settlement' },
    { name: I18n.t('label.settlements.final'), id: 'final_settlement' },
  ],
  violation_category: [
    { name: I18n.t('label.all'), id: 'all' },
    { name: I18n.t('label.mol_working_hours'), id: 'working_hours' },
    { name: I18n.t('label.work_organization'), id: 'organization_of_work' },
    { name: I18n.t('label.worker_behavior'), id: 'worker_behavior' },
    { name: I18n.t('label.other_violations'), id: 'other' },
  ],
  last_active_contract_type: [
    { name: I18n.t('label.indefinite'), id: 'Indefinite' },
    { name: I18n.t('label.fixed_duration'), id: 'Fixed term' },
  ],
  timesheet_status: [
    { name: I18n.t('label.all'), id: 'all' },
    {
      name: I18n.t('pending'),
      id: 'pending',
    },
    {
      name: I18n.t('processing'),
      id: 'processing',
    },
    {
      name: I18n.t('confirmed'),
      id: 'confirmed',
    },
    {
      name: I18n.t('dismissed'),
      id: 'dismissed',
    },
    {
      name: I18n.t('cancelled'),
      id: 'cancelled',
    },
  ],
  status_flight_booking: [
    { name: I18n.t('status.pending_booking'), id: 'pending_booking' },
    { name: I18n.t('status.pending_payment'), id: 'pending_payment' },
    { name: I18n.t('status.processing'), id: 'processing' },
    { name: I18n.t('status.completed'), id: 'completed' },
    { name: I18n.t('status.expired'), id: 'expired' },
    { name: I18n.t('status.cancelled'), id: 'cancelled' },
    { name: I18n.t('status.amendment_requested'), id: 'amendment_requested' },
    { name: I18n.t('status.cancellation_requested'), id: 'cancellation_requested' },
  ],
  transaction_types: [
    { name: I18n.t('label.credit'), id: 'credit' },
    { name: I18n.t('label.debit'), id: 'debit' },
  ],
  wallet_services_types: [
    { name: I18n.t('label.payroll_payment'), id: 'payroll_payment' },
    { name: I18n.t('label.almosafer_ticket_'), id: 'almosafer_flight_booking' },
  ],
  wallet_transactions_statuses: [
    { name: I18n.t('label.on_hold'), id: 'pending' },
    { name: I18n.t('status.completed'), id: 'completed' },
    { name: I18n.t('label.refunded'), id: 'failed' },
  ],
}

export default staticRecords
