import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'

import * as actions from './actions'
import { TOrgSubsidiary } from './types'

type StateType = {
  fetching: boolean
  organizations: TOrgSubsidiary[]
  organization_subsidiary: Partial<TOrgSubsidiary>
}

const initialState: StateType = {
  fetching: false,
  organizations: [],
  organization_subsidiary: {},
}

export default function reducer(state = initialState, action: Action): StateType {
  const { payload } = action
  switch (action.type) {
    case actions.ORG_SUBSIDIARIES_LOAD_PENDING:
    case actions.ORG_SUBSIDIARY_LOAD_PENDING:
    case actions.ORG_SUBSIDIARY_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ORG_SUBSIDIARIES_LOAD_FULFILLED:
    case actions.ORG_SUBSIDIARY_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ORG_SUBSIDIARY_CREATE_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ORG_SUBSIDIARIES_LOAD_REJECTED:
    case actions.ORG_SUBSIDIARY_LOAD_REJECTED:
    case actions.ORG_SUBSIDIARY_CREATE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
