import { useState, MouseEvent } from 'react'
import { DatePicker } from '@jisr-hr/ds-beta'
import { Flex, Divider } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { useFilters } from './context'
import FilterBoxHeader from '../FilterBox/FilterBoxHeader'

type PaymentDatesType = {
  filterKey: string
  filters: string[]
  title: string
}

const PaymentDates = (props: PaymentDatesType): JSX.Element => {
  const { filters, title, filterKey } = props
  const { filter, setFilter } = useFilters()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onChange = (val: string, field: string): void => {
    setFilter({
      ...filter,
      payment_dates: {
        ...filter?.payment_dates,
        [field]: val,
      },
    })
  }

  const handleClear = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setFilter({
      ...filter,
      payment_dates: undefined,
    })
  }

  return (
    <div>
      <div className="flex flex-col px-6 py-4 gap-4">
        <FilterBoxHeader
          canShowClear={filter?.payment_dates?.from || filter?.payment_dates?.to}
          handleClearFilter={handleClear}
          handleCollabseFilterBox={(): void => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
          title="date"
        />
        {!isCollapsed && (
          <Flex
            style={{
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DatePicker
              textFieldProps={{
                testId: 'from-date',
                showClearIcon: false,
              }}
              triggerType="input"
              label={i18n.t('from')}
              value={
                filter?.payment_dates?.from ? new Date(filter?.payment_dates?.from) : undefined
              }
              onChange={(val: string | string[]): void => onChange(val as string, 'from')}
              calenderProps={{
                type: 'single',
                maxDate: filter?.payment_dates?.to
                  ? new Date(filter?.payment_dates?.to)
                  : undefined,
              }}
            />
            <DatePicker
              textFieldProps={{
                testId: 'to-date',
                showClearIcon: false,
              }}
              label={i18n.t('to')}
              value={filter?.payment_dates?.to ? new Date(filter?.payment_dates?.to) : undefined}
              onChange={(val: string | string[]): void => onChange(val as string, 'to')}
              triggerType="input"
              calenderProps={{
                type: 'single',
                minDate: filter?.payment_dates?.from
                  ? new Date(filter?.payment_dates?.from)
                  : undefined,
              }}
            />
          </Flex>
        )}
      </div>
      {![filterKey, title].includes(filters[filters.length - 1]) && <Divider />}
    </div>
  )
}

export default PaymentDates
