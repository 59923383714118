import React, { useState } from 'react'
import { Flex } from '@jisr-hr/ds'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useRequest } from './RequestProvider'
import { RequestFor, RequestType } from './components/forms'

const AssignEmployee = () => {
  const {
    auth: { employee },
    subordinates: { team_members: teamMembers },
  } = useSelector(({ auth, subordinates }) => ({
    auth,
    subordinates,
  }))
  const [employeeList, setEmployeeList] = useState([
    ...(teamMembers?.length ? teamMembers : []),
    employee,
  ])
  const { setEmployeeId, setEmployee, setRequestType, setOnSubmit, handleDisableSubmit } =
    useRequest()
  const formState = useFormState()
  const { employee_id } = formState.values

  const openRequestForm = (values) => {
    const emp = employeeList.filter((e) => e.id === values.employee_id)?.[0]
    setEmployee(emp)
    setEmployeeId(values.employee_id)
    setRequestType(values.request_type)
  }

  React.useEffect(() => {
    setEmployeeList([...teamMembers, employee])
  }, [employee, teamMembers])

  React.useEffect(() => {
    setOnSubmit(openRequestForm)
    handleDisableSubmit(false)
  }, [])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      <RequestFor />
      <RequestType employee_id={employee_id} />
    </Flex>
  )
}

export default AssignEmployee
