import React from 'react'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Typography, Avatars, Flex, Spacer, Button } from '@jisr-hr/ds'
import { requestCommentsPost } from 'redux/requests/requestComments/actionCreators'
import { resetRequestDetail } from 'redux/attendance/timesheets/requestDetails/actionCreators'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import JisrLoader from 'components/global/JisrLoader'
import { required } from 'components/global/form/FormValidations'
import { Form, NewFileUploadField, TextInputField } from 'components/final-form'
import { omitBy } from 'lodash'
import Comment from './Comment'
import styles from './Comments.module.css'

const Comments = ({ comments, reloadRequest, approval, can_add_comment }) => {
  const dispatch = useDispatch()
  const { employee } = useSelector(({ auth }) => auth)
  const { fetching } = useSelector(({ requestComments }) => requestComments)

  const validationSchema = (values) => {
    if (!values.file && !values.comment) {
      return {
        comment: required(),
      }
    }
    return {}
  }

  const handleSendComment = async (vals) => {
    await dispatch(
      requestCommentsPost(
        omitBy(
          {
            commentable_id: approval?.request_id,
            commentable_type: approval?.request_type,
            content: vals?.comment,
            attachment: vals?.file,
            attachment_file_name: vals?.file_file_name,
          },
          (v) => v === null,
        ),
      ),
    ).then(() => {
      reloadRequest()
      if (vals?.file_file_name) dispatch(resetRequestDetail())
    })
  }

  return (
    <Frame
      title={`${I18n.t('comments')} (${comments?.length})`}
      collapse
    >
      <Form
        onSubmit={handleSendComment}
        validate={validationSchema}
      >
        {({ handleSubmit, invalid, form, values }) => {
          return (
            <form
              onSubmit={(v) => {
                handleSubmit(v)?.then(form.restart)
              }}
            >
              {!comments.length && !can_add_comment && (
                <Typography
                  text={I18n.t('no_comments')}
                  style={{ textAlign: 'center', padding: '10px' }}
                  variant="subheading"
                />
              )}
              {fetching && <JisrLoader absolute />}

              {can_add_comment && (
                <Flex
                  flexCol
                  style={{ gap: 16 }}
                >
                  <Flex style={{ gap: 16 }}>
                    <Avatars
                      imageSrc={employee?.avatar_thumb}
                      imageAlt={employee?.full_name_i18n}
                    />
                    <TextInputField
                      placeholder={`${I18n.t('type_your_comment')}...`}
                      name="comment"
                    />
                  </Flex>
                  <NewFileUploadField
                    name="file"
                    compact
                    hideUploadBox={values?.file?.name}
                    onLoad={(val) => {
                      form.change('file_file_name', val[0]?.name)
                    }}
                    accept="image/*, .pdf"
                    errorMessage={I18n.t('unable_to_upload_file')}
                    loadingMessage={I18n.t('uploading_file')}
                    message={I18n.t('add_attatchment')}
                    successMessage={I18n.t('file_selected')}
                    uploadingMessage={I18n.t('uploading_in_progress')}
                    className={styles.fileUpload}
                    onRemove={() => form.change('file_file_name', null)}
                    useBase64
                  />
                  <Flex justifyEnd>
                    <Button
                      type="submit"
                      disabled={invalid}
                      label={I18n.t('submit')}
                      size="small"
                    />
                  </Flex>
                  <Spacer height={24} />
                </Flex>
              )}
            </form>
          )
        }}
      </Form>
      {comments?.map((comment, i) => (
        <Comment
          key={i}
          comment={comment}
        />
      ))}
    </Frame>
  )
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(Object),
  reloadRequest: PropTypes.func,
  approval: PropTypes.shape(),
  can_add_comment: PropTypes.bool,
}

export default Comments
