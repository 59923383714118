const Common = {
  // A
  attendance: 'حضور وانصراف',
  assign: 'إسناد',
  ascending: 'تصاعدي',
  actions: 'العمليات',
  add: 'إضافة',
  add_new: 'إضافة جديد',
  add_new_object: 'إضافة {{object}} جديد',
  attachment: 'مرفق',
  attachments: 'مرفقات',
  apply_date: 'تاريخ تقديم الطلب',
  apply_month: 'تاريخ التطبيق',
  amount: 'المبلغ',
  announcement: 'إعلان',
  announcements: 'الإعلانات',
  add_attachments: 'إضافة مرفقات',
  add_attachment: 'إضافة مرفق',
  add_new_attachments: 'إضافة مرفقات جديدة',
  add_new_attachment: 'إضافة مرفق جديد',
  attachment_file_name: 'اسم الملف المرفق',
  all: 'الكل',
  added: 'تمت الاضافة',
  arabic_only: 'يرجى إدخال حروف عربية فقط.',
  area: 'المنطقة',
  are_you_sure: 'هل أنت متاكد؟',
  apply_filters: 'تطبيق المرشحات',
  active: 'فعال',
  all_employees: 'كل الموظفين',
  add_new_location_branch: 'إضافة موقع/فرع جديد',
  add_new_region: 'إضافة منطقة جديدة',
  amount_currency: '{{currency}} {{amount}}',
  and: 'و',
  ahad: 'عهد',
  ahad_HR: 'عهد',
  assigned_tasks: 'المهام المسندة',
  assing_attendance_reviewer: 'Assign attendance reviewer',
  ask_for_cancellation: 'طلب إلغاء',
  // B
  browser_notifications: 'إشعارات المتصفح',
  browser_notifications_disabled_alert_desc:
    'لاستلام الإشعارات على هذا الجهاز، فعّل <span>إشعارات المتصفح</span>.',
  browser_notifications_disabled_alert_title: 'إشعارات المتصفح معطلة',
  bulk_import_sheets: 'قوالب تحديث البيانات',
  business_unit: 'وحدة العمل',
  beta: 'تجريبي',
  enable_notifications: 'تمكين الإشعارات',
  back_to_home: 'العودة للصفحة الرئيسية',
  // C
  cancellation: 'Cancellation',
  company: 'الشركة',
  company_assets: 'أصول الشركة',
  clear: 'مسح',
  create: 'إضافة',
  cancel: 'إلغاء',
  correct_data: 'معلومات صحيحة',
  coming_late: 'الوصول متأخراً',
  create_new: 'إضافة جديد',
  clear_all: 'مسح الكل',
  clickordrag: 'اسحب الملف هنا',
  choose_file: 'اختر ملف',
  confirmed_successfully: 'تم التاكيد بنجاح',
  coming_soon: 'قريباً',
  soon: 'قريباً',
  details: 'التفاصيل',
  clear_filters: 'مسح المرشحات',
  clear_all_filters: 'تصفية',
  contact_support_intercom: 'التواصل مع الدعم الفني',
  cancel_excuse_request: 'إلغاء طلب الإستئذان',
  custom_range: 'مدة محددة',
  cancel_immediately: 'إلغاء فوري',
  cancel_request_immediately: 'إلغاء الطلب فوراً دون المرور بسلسلة الموفقات',
  chrome_notifications_guide:
    '1. من الزاوية العلوية اليسرى، انقر على رمز <b>  <Dots /> المزيد -> الإعدادات</b><br> 2. انقر على <b>الخصوصية والأمان -> إعدادات الموقع الإلكتروني -> الإشعارات </b> <br>3. من الزاوية العلوية اليسرى ابحث عن<b> <whiteLabel /> </b> <br>4. انقر على <b>رمز المزيد</b><br>5. ثم غيّر نوع الصلاحية إلى <b>«السماح»</b>',
  check_search_keywords: 'قم بالتحقق من كلمات البحث والمحاولة مرة أخرى',
  // D
  description: 'الوصف',
  date_allocated: 'تاريخ الإستلام (ميلادي)',
  department: 'القسم',
  date: 'التاريخ (ميلادي)',
  DATE: 'التاريخ',
  delete: 'حذف',
  descending: 'تنازلي',
  complete: 'اكمل فترة التجربة',
  divisions: 'القطاعات',
  download_template_file: 'تنزيل القالب',
  due_requests: 'طلبات مستعجلة',
  CancelltionRequest: 'طلب الغاء إجازة',
  discard: 'إلغاء',
  document_added: 'إضافة مستند',
  document_updated: 'تعديل مستند',

  // E
  earlier: 'سابقًا',
  end_date: 'تاريخ النهاية (ميلادي)',
  end_date_hijri: 'تاريخ النهاية بالهجري',
  english_only: 'يرجى إدخال حروف إنجليزية فقط.',
  error: 'الخطاء',
  errors: 'أخطاء',
  employee_avatar_size_should_be_less_than_or_equal_to_megabyte:
    'يجب ان لا يزيد حجم الصورة الشخصية للموظف عن 2 ميغا بايت',
  menu__expenses: 'المصروفات',
  error_attachment: 'ملف الخطاء',
  export_journal: 'Export journal',
  enter_your_description: 'اضف الوصف',
  edge_notifications_guide:
    '1. من الزاوية العلوية اليسرى، انقر فوق  <Dots />  <b>المزيد -> الإعدادات</b><br> 2. من الشريط الجانبي، اختر ملفات تعريف الارتباط وأذونات الموقع<br> 3. انقر على الإعلامات، <br> 4. ضمن قائمة الحظر، ابحث عن <whiteLabel /> واختر <b>«سماح».</b>',
  events: 'المناسبات',
  empty_file_error_msg: 'الملف خال من المحتوى. أضف الملف الصحيح وحاول مرة أخرى',
  // F
  fetching: 'تنزيل',
  file_type: 'نوع الملف',
  from_date: 'من تاريخ',
  from_month: 'من شهر',
  from_year: 'من سنة',
  from: 'من',
  from_person: 'من الموظف',
  filter_by: 'فرز بواسطة',
  filter: 'فرز بواسطة',
  failed: 'فشل',
  faild: 'فشل',
  field_name: 'الحقل',
  filters: 'المرشحات',
  file_is_required: 'حقل الملف مطلوب',
  food: 'طعام',
  finish: 'تم',
  firefox_notifications_guide:
    '1. من الزاوية العلوية اليسرى، انقر على رمز <Bars />  -> <b>الإعدادات.</b> <br>2. من الشريط الجانبي، اختر الخصوصية والأمان. <br>3. ضمن <b>الصلاحيات</b>، ابحث عن <b>التنبيهات</b>، ثم انقر فوق <b>الإعدادات</b>. <br>4. ابحث عن<b> <whiteLabel /> </b>ثم قم بتغيير الحالة إلى <b>«اسمح»</b>.',
  // G
  grade: 'الدرجة',
  gender: 'الجنس',
  group: 'المجموعة',
  // H
  help: 'المساعدة',
  help_support: 'المساعدة والدعم',
  home: 'الرئيسية',
  hiring_employees_details: 'Hiring employees details',
  help_center: 'مركز المساعدة',
  help__support: 'الدعم والمساعدة',
  how_to_receive_notifications: 'السماح بإشعارات {{app_name}}',
  // I
  importing: 'جاري الرفع ...',
  import: 'رفع الملف',
  it_will_take_few_seconds: 'ستأخذ بعض الدقائق لعرض التقرير',
  in_active: 'غير فعال',
  internet_error_message: 'خطأ في الإتصال، الرجاء التحقق من اتصالك بالإنترنت!',
  invite: 'دعوة',
  inbox: 'الوارد',
  must_equal: 'يجب ان يكون {{val}}',
  include_in_employees_profile: 'إظهار في ملف الموظف',
  item_name: 'اسم العنصر',
  inbox_and_reminders: 'الوارد والتذكيرات',
  // J
  jisr_academynav: 'أكاديمية جسر',
  jisr_translation_name: 'جسر',
  jisr_translation_app_name: 'جسر',
  // K
  // L
  location: 'الموقع',
  locations: 'المواقع / الفروع',
  line_manager: 'المدير المباشر',
  leaves: 'الاجازات',
  leave_types: 'نوع الاجازة',
  letters_managment: 'صيغ وأنواع الخطابات',
  leave_status: 'حالة الاجازة',
  last_updated: 'آخر تحديث',
  leave_resumption: 'عودة من الإجازة',
  leave_planning: 'جدولة الإجازات',
  location_branch_name_in_english: 'اسم الموقع/الفرع بالانجليزية',
  location_branch_name_in_arabic: 'اسم الموقع/الفرع بالعربية',
  last_payslips: 'اخر كشف راتب',
  lets_be_productive_today: 'نتمنى لك يومًا مثمرًا 🚀',
  // M
  mark_all_as_read: 'علّم الكل كمقروء',
  message: 'النص',
  month: 'الشهر',
  MONTHLY: 'شهري',
  more_filters: 'مرشحات أخرى',
  misc: 'أخرى',
  minutes: 'الدقائق',
  // N
  no: 'لا',
  no_new_notifications: 'لاتوجد إشعارات جديدة!',
  next_day: 'اليوم التالي',
  notes: 'الملاحظات',
  no_data: 'لا توجد بيانات',
  number: 'الرقم',
  name: 'الاسم',
  name_in_arabic: 'الاسم باللغة العربية',
  name_in_english: 'الاسم باللغة الانجليزية',
  no_image_note_drag: 'لاتوجد صورة, انقر او اسحب الصورة للرفع',
  nationality: 'الجنسية',
  nationalities: 'الجنسيات',
  no_employee_selected: 'لم يتم اختاير اي موظف',
  no_education: 'لا توجد مؤهلات',
  no_promotion_history: 'لايوجد سجل للترقيات',
  no_benefits: 'لا توجد بدلات',
  no_salary_appraisals_history: 'لا توجد علاوات',
  no_loans: 'لا توجد سلف',
  no_expenses: 'لا توجد مصاريف',
  no_employee_files: 'لا توجد ملفات للموظف',
  no_assets: 'لا توجد عهد',
  not_updated: 'لم يتم التحديث',
  no_warning_noticies: 'لا توجد خطابات إنذار',
  no_employees: 'لا يوجد موظفين',
  no_leaves: 'لا توجد إجازات',
  no_attendance: 'لا توجد سجلات حضور',
  no_attachment: 'لا توجد مرفقات',
  no_scheduler: 'لا توجد دوامات',
  no_manual_additions: 'لا توجد إضافات يدوية',
  no_manual_deductions: 'لا توجد خصومات يدوية',
  no_company_documents: 'لا توجد مستندات',
  not_registered_gosi: 'غير مسجل في التأمينات',
  no_result_found: 'لا توجد نتيجة مطابقة للبحث',
  next_month: 'الشهر القادم',
  n_days: '{{days}} يوم',
  n_months: '{{months}} شهر',
  n_years: '{{years}} سنة',
  n_months_days: '{{months}} شهر، {{days}} يوم',
  no_requests_are_pending_on_you: 'لا توجد طلبات معلقة عليك',
  no_documents_are_expiring_in_this_period: 'لا توجد مستندات تنتهي صلاحيتها في هذه الفترة',
  no_payment: ' لا يوجد اي طرق دفع مُعرفه على هذه المنشآه او على سجل تجاري تابع لها',
  no_cr: 'لا يوجد اي سجلات تجاريه معرفه على الهيكل القانوني للمؤسسة',
  // O
  of: 'من',
  ok: 'موافق',
  okay: 'تم',
  others_2: 'الآخرين',
  overview: 'الرئيسية',
  organisation_chart: 'الهيكل التنظيمي للشركة',
  off_cycle_payroll: 'المخالصات',
  overtime_alt_translation: 'عدد الساعات',
  code_validation_msg: 'يُسمح فقط بالأرقام والحروف والشرطة (-)!',
  // P
  please_confirm_form_first: 'قم بتاكيد معلومات النموذج اولاً',
  payroll: 'مسيير الرواتب',
  pending_from_person: 'بانتظار التاكيد من',
  post_type: 'نوع المنشور',
  payment_outside_payroll: 'عمليات خارج مسير الرواتب',
  payroll_table: 'جدول مسير الرواتب',
  process_payments: 'تحويل الرواتب',
  payroll_has_successfully_been_confirmed: 'تم تأكيد مسير رواتب {{date}} بنجاح.',
  payment_processing: 'تحويل الرواتب',
  pay: 'دفع',
  processing_these_requests: 'معالجتك لهذه الطلبات سيساهم في أن يكون التأثير في وقته المطلوب',
  previous_day: 'اليوم السابق',
  // Q
  // R
  region: 'المنطقة',
  resumed_late: 'إستؤنفت متأخراً',
  return_date: 'تاريخ العودة (ميلادي)',
  reports: 'التقارير',
  record_types: 'انواع',
  requests_and_comments: 'الطلبات والتعليقات',
  requests_and_tasks: 'الطلبات والمهام',
  request_cancel_note_single: 'هذا الاجراء سيقوم بإلغاء طلب الاجازة ( {{type}} ) لتاريخ {{from}}',
  request_cancel_note_multi:
    'هذا الاجراء سيقوم بإلغاء طلب الاجازة ( {{type}} ) للفترة من تاريخ {{from}} الى تاريخ {{to}}',
  rs: '񥀠',
  Required: 'الحقل مطلوب',
  registered_gosi: 'مسجل في التأمينات',
  reminders: 'التذكيرات',
  renew: 'تجديد',
  requested_on: 'Requested On',
  request_on: 'وقت الطلب',
  resignation_details: 'تفاصيل الإستقالة',
  run: 'بدء',
  review: 'Review',
  // S
  start_date: 'تاريخ البداية (ميلادي)',
  start_date_hijri: 'تاريخ البداية بالهجري',
  status: 'الحالة',
  sar: '񥀠',
  showing: 'اظهار',
  save: 'حفظ',
  save_change: 'حفظ التعديلات',
  save_next: 'حفظ والتالي',
  save_workflow: 'حفظ سلسلة الموافقات',
  sponsor_id: 'رقم الممول',
  submit: 'ارسال',
  scheduler: 'الدوامات',
  setting: 'الاعدادات',
  settings: 'الاعدادات',
  select_all: 'اختيار الكل',
  statuses: 'حالة الموظف ',
  shift_types: 'انواع الدوام',
  search: 'بحث',
  shifts: 'الدوامات',
  size: 'الحجم',
  sponsor_note: 'الرجاء اختيار الممول للموظفين المقيمين',
  select: 'اختر',
  selected_employees: 'الموظفين المختارين',
  success: 'تم',
  successfully: 'تم بنجاح',
  search_placeholder: 'رقم الموظف ,الاسم , رقم الهوية , المسمى الوظيفي  ',
  search_placeholder2: 'رقم الموظف ,الاسم',
  search_placeholder3: 'اسم الموظف',
  shift_scheduler: 'جدول الدوامات',
  search_placeholder4: 'ابحث باسم الموظف، رقم الموظف, رقم الهوية',
  search_placeholder5: 'ابحث بالرقم الوظيفي ,الاسم , رقم الهوية',
  show_documents_that_will_expire: 'إظهار الملفات التي ستنتهي في',
  search_for_requests: 'بحث عن طلبات',
  search_for_tasks: 'بحث عن مهام',
  specified_monthly_amount: 'تحديد مبلغ شهري معيّن',
  specified_number_of_months: 'التقسيم على عدد أشهر',
  share_your_idea: 'مشاركة الاقتراحات',
  send_cancellation_request: 'سيرسل الطلب لسلسة الموافقات لإلغاء الطلب',
  show_more: 'عرض المزيد',
  show_less: 'عرض اقل',
  safari_notifications_guide:
    '1. من الزاوية العلوية اليمنى، بجانب شعار <Apple /> ، اختر <b>سفاري</b> > <b>الإعدادات</b> > <b>مواقع الويب</b>.<br>2. من الشريط الجانبي، مرر للأسفل ثم انقر على <b>الإشعارات</b>.<br>3. ابحث عن <b><whiteLabel /></b>، ثم غيّر نوع الصلاحية إلى <b>«السماح»</b>.',
  scheduled_interview_on: 'موعد محدد يوم {{date_time}}',

  // T
  title: 'العنوان',
  to: 'الى',
  type: 'النوع',
  TYPE: 'النوع',
  type_to_start_searching: 'اكتب لبدء البحث...',
  total: 'الاجمالي',
  total_salary: 'إجمالي الراتب',
  to_date: 'الى تاريخ',
  to_month: 'الى شهر',
  to_year: 'الى سنة',
  transactions: 'المعاملات المالية',
  the_locations: 'المواقع',
  the_departments: 'القسم',
  this_week: 'هذا الأسبوع',
  transportation: 'مواصلات',
  tomorrow: 'الغد',
  // U
  username: 'إسم المستخدم',
  upload_your_attachment: 'ارفع الملف المرفق',
  upload_template_file: 'ارفع القالب',
  updated: 'تم التحديث',
  updates: 'التحديثات',
  used_leaves_this_year: 'الإجازات المستخدمة في السنة الحالية',
  uncorrect_data: 'معلومات غير صحيحة',
  untitled: 'بدون عنوان',
  // V
  // W
  want_to_delete: 'هل تريد الحذف؟',
  want_to_remove: 'هل تريد الإزالة',
  warning: 'تنبيه',
  where_it_would_be_paid: 'أين سيتم دفع هذا العنصر؟',
  what_new: 'التحديثات',
  welcome_back: 'اهلا بعودتك يا',
  // X
  xls: 'اكسل',
  // Y
  year: 'السنة',
  year_is_so_big_as_hijri: 'السنة كبيرة جداً بالنسبة للتاريخ الهجري',
  yes: 'نعم',
  you_may_now_process_the_payments: 'بإمكانك البدء في تحويل الرواتب',
  you: 'أنت',
  you_did_not_send_any_requests_yet: 'لم ترسل أي طلبات حتى الآن',
  you_need_enable_notifications: 'لمعرفة حالة الرفع للملف، يتطلب تمكين الإشعارات الخاصة بالمتصفح',

  // Z
}

export default Common
