export const PAYMENT_METHODS_LOAD = 'PAYMENT_METHODS_LOAD'
export const PAYMENT_METHODS_LOAD_PENDING = 'PAYMENT_METHODS_LOAD_PENDING'
export const PAYMENT_METHODS_LOAD_FULFILLED = 'PAYMENT_METHODS_LOAD_FULFILLED'
export const PAYMENT_METHODS_LOAD_REJECTED = 'PAYMENT_METHODS_LOAD_REJECTED'

export const PAYMENT_METHOD_LOAD = 'PAYMENT_METHOD_LOAD'
export const PAYMENT_METHOD_LOAD_PENDING = 'PAYMENT_METHOD_LOAD_PENDING'
export const PAYMENT_METHOD_LOAD_FULFILLED = 'PAYMENT_METHOD_LOAD_FULFILLED'
export const PAYMENT_METHOD_LOAD_REJECTED = 'PAYMENT_METHOD_LOAD_REJECTED'

export const PAYMENT_METHODS_UPDATE_ITEM_OF_LIST = 'PAYMENT_METHODS_UPDATE_ITEM_OF_LIST'

export const PAYMENT_METHOD_CHANGE_DEFAULT_BANK = 'PAYMENT_METHOD_CHANGE_DEFAULT_BANK'
export const PAYMENT_METHOD_CHANGE_DEFAULT_BANK_PENDING =
  'PAYMENT_METHOD_CHANGE_DEFAULT_BANK_PENDING'
export const PAYMENT_METHOD_CHANGE_DEFAULT_BANK_FULFILLED =
  'PAYMENT_METHOD_CHANGE_DEFAULT_BANK_FULFILLED'
export const PAYMENT_METHOD_CHANGE_DEFAULT_BANK_REJECTED =
  'PAYMENT_METHOD_CHANGE_DEFAULT_BANK_REJECTED'

export const PAYMENT_METHOD_SET_DEFAULT = 'PAYMENT_METHOD_SET_DEFAULT'
export const PAYMENT_METHOD_SET_DEFAULT_PENDING = 'PAYMENT_METHOD_SET_DEFAULT_PENDING'
export const PAYMENT_METHOD_SET_DEFAULT_FULFILLED = 'PAYMENT_METHOD_SET_DEFAULT_FULFILLED'
export const PAYMENT_METHOD_SET_DEFAULT_REJECTED = 'PAYMENT_METHOD_SET_DEFAULT_REJECTED'

export const PAYMENT_METHOD_CREATE = 'PAYMENT_METHOD_CREATE'
export const PAYMENT_METHOD_CREATE_PENDING = 'PAYMENT_METHOD_CREATE_PENDING'
export const PAYMENT_METHOD_CREATE_FULFILLED = 'PAYMENT_METHOD_CREATE_FULFILLED'
export const PAYMENT_METHOD_CREATE_REJECTED = 'PAYMENT_METHOD_CREATE_REJECTED'

export const MUDAD_INTEGRATION_ENABLED = 'MUDAD_INTEGRATION_ENABLED'
export const MUDAD_INTEGRATION_ENABLED_PENDING = 'MUDAD_INTEGRATION_ENABLED_PENDING'
export const MUDAD_INTEGRATION_ENABLED_FULFILLED = 'MUDAD_INTEGRATION_ENABLED_FULFILLED'
export const MUDAD_INTEGRATION_ENABLED_REJECTED = 'MUDAD_INTEGRATION_ENABLED_REJECTED'
