import { Typography, Spacer } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { StepsTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import I18n from 'translations/i18n'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'
import Holidays from './components/Holidays'
import LeavesTypes from './components/LeavesTypes'
import styles from '../Holidays/styles.module.css'

const LaborLawLeavesPublicHolidays = ({
  goNextStep,
  goPreviousStep,
  nextStep,
  previousStep,
}: StepsTypes): JSX.Element => {
  useTrackingSteps({
    step_name: 'leaves_setup',
    level_name: 'labor_law_leaves_and_public_holidays',
  })

  return (
    <StepTemplate
      goNextStep={goNextStep}
      goPreviousStep={goPreviousStep}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <Typography
        variant="heading"
        text={I18n.t('labor_law')}
      />

      <Spacer height={8} />
      <Typography
        variant="subtitle-1"
        style={{ color: 'var(--color-base-colors-black-500)' }}
        text={I18n.t('labor_law_leaves_description')}
      />

      <Spacer height={24} />
      <div className={styles.content}>
        <Holidays />
        <Spacer height={16} />
        <LeavesTypes />
      </div>
    </StepTemplate>
  )
}

export default LaborLawLeavesPublicHolidays
