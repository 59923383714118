import { Action } from 'types/redux'
import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'
import { TAnswers } from 'components/GetFields/type'
import * as actions from './actions'

export const showOnboardingQuestions = (step_name = 'org_structure_and_logo'): Action => {
  const url = `/${API_VERSION_TWO}/onboarding/page_group_steps/${step_name}/questions`

  return {
    type: actions.ONBOARDING_PAGE_GROUP_STEPS_QUESTIONS_SHOW,
    payload: handleAPI(url, {}, 'GET'),
  }
}

export function answerOnboardingQuestions(
  answers: TAnswers | TAnswers[],
  step_name = 'org_structure_and_logo',
): Action {
  const url = `/${API_VERSION_TWO}/onboarding/page_group_steps/${step_name}/answers`
  return {
    type: actions.ONBOARDING_PAGE_GROUP_STEPS_ANSWERS_POST,
    payload: handleAPI(url, {}, 'POST', { answers }),
  }
}

export function onboardingQuestionsReset(): Action {
  return {
    type: actions.ONBOARDING_QUESTIONS_RESET,
  }
}
