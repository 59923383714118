import { Lens } from '@dhmk/zustand-lens'
import { TemplateType } from 'apis/Performance/templates/useTemplatesType'

export type TemplateSliceType = {
  templateDetails: TemplateType | null
  setTemplateDetails: (templateDetails: TemplateType | null) => void
}

const createTemplate: TemplateSliceType = {
  templateDetails: null,
  setTemplateDetails: () => null,
}

export const createTemplateSlice: Lens<TemplateSliceType> = (set) => {
  return {
    ...createTemplate,
    setTemplateDetails: (templateDetails) => set({ templateDetails }),
  }
}
