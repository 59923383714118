const atsRecruitment = {
  application_tracking_system: 'Application Tracking System',
  applicant_tracking: 'Applicant Tracking',
  job_openings: 'Job Openings',
  job_openings_title: 'Job Openings',
  job_opening_filter_title: 'Job Opening',
  add_comments: 'Add Comment',
  all_comments: 'All Comments',
  activity: 'Activity',
  see_more: 'see more...',
  see_less: 'see less...',
  candidates: 'Candidates',
  download_file: 'Download File',
  moved_to: 'moved to',
  applicants_tracking_system_questionnaire: 'Applicants Tracking System Questionnaire',
  onboarding_subheading_text:
    'Complete this short questionnaire to help us tailor our system to better cater to your business needs.',
  assigned_to: 'assigned to',
  cannot_exceed_more_than_1000_word: 'Cannot exceed more than 1000 word',
  no_candidates_have_been_found: 'No candidates have been found',
  no_candidates_created_yet: 'No candidates created yet',
  input_candidates_contact_information:
    'Input candidates contact information, resume, and any relevant documents.',
  try_changing_filters: 'Try changing filters',
  learn_more_bulk_upload: 'Learn More',
  step_2_prepare_the_file_for_upload: 'Step 2: Prepare the file for upload',
  use_this_template_to_avoid_difficulties_importing_ur_candidates_information:
    'Use this template to avoid difficulties in importing your candidates information.',
  fill_the_required_information_as_per_the_template_:
    'Fill the required information as per the template, click on learn more to read the full article on how to bulk upload step by step .',
  step_1_download_template_file: 'Step 1: Download template File',
  save_the_file_as_csv_xlsx_xls_format:
    'Save the file as (csv), (xlsx) or (xls) format then upload it here',
  supported_file_formats: 'Supported file formats: .xls, .xlsx, .cvs',
  bulk_upload_candidates: 'Bulk upload candidates',
  import_candidates: 'Import Candidates',
  upload_candidates: 'Upload Candidates',
  search_by_candidate_name: 'Search by candidate name',
  discard_job: 'Discard Job',
  job_draft_modal_caption: 'This will result in losing all job information.',
  save_as_draft: 'Save as Draft',
  pick_a_date: 'Pick a Date',
  re_upload_file: 'Re-Upload file',
  upload_file: 'Upload File',
  active_jobs: 'Open Jobs',
  jobs: 'Jobs',
  job: 'Job',
  job_openings_subTitle: 'Manage all your job openings in one place.',
  candidates_subTitle: 'Manage all your candidates in one place.',
  new_job: 'New Job',
  search_jobs_by_title_or_id: 'Search jobs by title or ID',
  search_by_candidates_name: 'Search by Candidates name',
  add_job: 'Add Job',
  cancel: 'Cancel',
  delete_job: 'Delete Job',
  delete_comment: 'Delete Comment',
  delete_comment_message: 'This will result in deleting this comment permanently.',
  reassign: 'Reassign',
  move: 'Move',
  select_the_stage_to_move_the_candidates: 'Select the stage to move the Candidates.',
  select_the_new_job_to_reassign_the_candidates: 'Select the new job to reassign the candidates.',
  this_will_result_in_losing_all_job_and_candidates_data:
    'This will result in losing all job and Candidates data.',
  next: 'Next',
  job_details: 'Job details',
  workflow: 'Workflow',
  no_job_added_title: 'No jobs have been added yet',
  edit_job: 'Edit Job',
  add_job_caption:
    'Track your hiring process from application to hiring, while keeping all Candidate information in one place.',
  total_candidate: 'Total Candidate',
  created: 'Created',
  add_stage: 'Add Stage',
  hiring_stages: 'Hiring Stages',
  applied: 'Applied',
  hired: 'Hired',
  job_title_english: 'Job Title (English)',
  job_title_arabic: 'Job Title (Arabic)',
  job_description: 'Job description (Optional)',
  job_type: 'Job Type',
  attachment_optional: 'Attachment (optional)',
  click_to_upload: 'Click to upload',
  supported_file_formats_png_pdf_word: 'Supported file formats: PNG, JPG, JPEG, PDF, Word',
  drag_and_drop: ' or drag and drop',
  file_format: 'PDF, DOC, PNG (maximum upload size: 5 MB)',
  file_format_10: 'PDF, DOC, PNG, JPG, JPEG (maximum upload size: 10 MB)',
  file_format_2: 'PDF, DOC, PNG, XLS (maximum upload size: 2 MB)',
  file_formated: 'PDF, DOC, PNG, JPG, JPEG (maximum upload size: 5 MB)',
  select_job_type: 'Select Job Type',
  select_location: 'Select Location',
  select_business_unit: 'Select Business unit',
  select_department: 'Select Department',
  business_unit_ats: 'Business unit',
  Statuses: 'Statuses',
  select_candidate_add_job: 'Select a candidate to add to this job.',
  add_candidate: 'Add Candidate',
  delete_stage: 'Delete Stage',
  select_candidate_msg: 'Please select a candidate',
  select_candidate: 'Select candidate',
  create_candidate: 'Create Candidate',
  new_candidate: 'New Candidate',
  create_candidate_btn: 'Create',
  bulk_upload: 'Bulk Upload',
  bulk_upload_resumes: 'Bulk Upload Resumes',
  candidate: 'Candidate',
  back_to_candidates: 'Back to Candidates',
  basic_information: 'Basic information',
  phone_Number: 'Phone',
  links: 'Links:',
  error_failed_to_load: 'Error: Failed to load',
  delete_candidate: 'Delete Candidate',
  reassign_to_a_new_job: 'Reassign to a new job',
  remove_from_job: 'Remove from job',
  step: 'Step',
  of: 'of',
  no_job_message: 'No jobs have been added yet',
  track_job_message:
    'Track your hiring process from application to hiring, while keeping all Candidates information in one place.',
  resume_heading: 'Resume',
  job_search_result: 'No jobs have been found',
  create_job: 'Create Job',
  back_to_Jobs: 'Back to Jobs',
  remove_to_another_job: 'Reassign to another Job',
  select_job: 'Select job',
  selecter: 'selected',
  selecters: 'selected',
  delete_job_message: 'This will result in losing all job and Candidates data.',
  reassign_to_another_job: 'Re-Assign to another Job',
  same_stage_name_is_not_allowed: 'Same Stage name is not allowed',
  enter_stage_name: 'Enter the Stage name',
  enter_valid_phone_number: 'Enter valid Phone Number',
  the_candidates: 'Candidates',
  candidate_name: 'Candidate Name',
  assigned_job: 'Assigned Job',
  stage: 'Stage',
  date_added: 'Date Added',
  delete_candidate_permanently: 'This will result in deleting this candidate data permanently.',
  delete_candidate_from_job: 'This will result in removing them from this job.',
  edit_candidate: 'Edit Candidate',
  designation: 'Designation',
  more_information: 'More information',
  upload_resume: 'Upload Resume',
  link: 'Links',
  add_more: 'Add more',
  candidate_comments: 'Comments',
  step_3_upload_the_file: 'Step 3: Upload the file',
  step_4_upload_all_candidates_resumes_optional: 'Step 4: Upload all candidates resumes (optional)',
  resumes_that_do_not_match:
    'Resumes that do not match the uploaded excel file will require manual addition after upload.',
  profile: 'Profile',
  candidate_out_of: ' out of ',
  personal_email: 'Personal Email',
  success_upload_candidate:
    'Candidate information for {{success_count}} Out of {{all_candidate}} candidates uploaded successfully.',
  assigned: 'Assigned',
  not_assigned: 'Not Assigned',
  unassigned: 'Unassigned',
  detele: 'Delete',
  ats_full_name_english: 'Full name (English)',
  ats_full_name_arabic: 'Full name (Arabic)',
  english: 'English',
  pdf_load_err: 'This url is invalid',
  create_new: 'Create new',
  add_existing: 'Add existing',
  download_error_report: 'Download Error Report',
  invalid_file_data: 'Invalid file data',
  are_you_currently_using_any_applications_tracking_system:
    '1. Are you currently using any applications tracking system?',
  which_industry_does_your_company_work_in: '2. Which industry does your company work in?',
  who_will_be_using_applicants_tracking_system_in_your_company:
    '3. Who will be using the applicants tracking system in your company?',
  how_many_new_hires_are_you_planning_for_this_year:
    '4. How many new hires are you planning for this year?',
  what_are_the_biggest_challenges_you_experience_in_recruitment_and_hiring:
    '5. What are the biggest challenges you experience in recruitment and hiring?',
  select_range: 'Select Range',
  select_industry: 'Select Industry',
  select_sector: 'Select Sector',
  add_another_industry: 'Add another Industry',
  proceed_to_ats: 'Proceed to the ATS',
  enter_the_value: 'Enter the value',
  CEO: 'CEO',
  HR_manager: 'HR Manager',
  recruitment_team_member: 'Recruitment Team member',
  hiring_line_manager: 'Hiring/line Manager',
  recruitment_manager: 'Recruitment Manager',
  business_owner: 'Business owner',
  ats_others: 'Others',
  ats_onboard_optional: '(Optional)',
  application_form: 'Application Form',
  candidate_profile_questions: 'Candidate profile questions',
  fullName_english: 'Full name (English)',
  fullName_arabic: 'Full name (Arabic)',
  telephone: 'Telephone',
  profile_address: 'Address',
  profile_city: 'City',
  profile_designation: 'Designation',
  profile_nationality: 'Nationality',
  profile_upload_resume: 'Upload resume',
  profile_links: 'Links',
  show_field: 'Show field',
  hide_field: 'Hide field',
  add_more_links: 'Add more links',
  add_more_links_placeholder: 'e.g. website, social media links',
  language_options: 'Language Options',
  language_option_description:
    'Choose the language in which you want the candidates to view the form.',
  application_for: 'Application for',
  candidate_application: 'Application',
  response: 'Response:',
  the_candidate: 'Candidate',
  add_question: 'Add Question',
  screening_questions: 'Screening questions',
  short_text: 'Short answer',
  paragraph: 'Paragraph',
  multiple_choice: 'Multiple choice',
  file_upload: 'File upload',
  checkboxes: 'Checkboxes',
  add_options: 'Add option',
  screen_text_field_placeholder: 'Write your question here...',
  no_questions_added_yet: 'No questions added yet',
  empty_questions_description:
    'Save time by adding screening questions to ensure applicants meet your requirements.',
  add_questions: 'Add Questions',
  thank_you: 'Thank you for submitting your application.',
  suitable_candidate: 'If you are a suitable candidate, the hiring team will reach out shortly.',
  save_as_job: 'Save as Job',
  job_overview: 'Job overview:',
  responsibilities: 'Responsibilities:',
  requirements: 'Requirements:',
  job_application_overview: 'Overview',
  job_application: 'Application',
  apply_now: 'Apply now',
  powered_by: 'Powered by',
  job_application_submit: 'Submit',
  no_longer_accepting_applicants: 'No longer accepting applicants',
  facebook: 'Facebook',
  x: 'X',
  whatsapp: 'WhatsApp',
  linkedin: 'Linkedin',
  copy_to_clipboard: 'Copy to clipboard',
  share_job: 'Share job',
  via_form: 'Via form',
  card_days: 'd',
  card_week: 'w',
  card_weeks: 'w',
  card_years: 'yr',
  about_the_company_optional: 'About the company (Optional)',
  about_the_company: 'About the company',
  use_for_all_jobs: 'Use for all new jobs',
  displayed_to_candidates_who_apply_via_form: 'Displayed to candidates who apply via form',
  no_duplicates: 'Duplicate link names are not allowed.',
  all_fields_must_be_filled: 'Fields must be filled',
  update_job: 'Update Job',
  please_fill_all_the_fields: 'Please fill all the fields',
  screen_answer_field_placeholder: 'Write your answer here...',
  skip_and_update: 'Skip & Update',
  this_job_was_deleted: 'This job was deleted',
  skip_and_create: 'Skip & Create',
  no_response_to_show: 'No response to show',
  include_screening_questions: `Job application didn't include screening questions`,
  no_resume_attached: 'No resume attached',
  company_logo: 'Company logo',
  change_status: 'Change Status',
  resumes_found_with_no_match: '{{unmatched_resumes_count}} resumes found with no match',
  candidate_resumes_for_candidates_matched_successfully:
    'Candidate resumes for {{countOfSuccess}} out of {{countOfUpload}} candidates matched successfully.',
  follow_these_simple_steps_to_bulk_upload_candidate_data:
    'Follow these simple steps to bulk upload candidate data.',
  file_upload_limit: 'File upload limit',
  you_can_only_upload_a_maximum_of_200_candidate_resumes_at_once:
    'You can only upload a maximum of 200 candidates resumes at once.',
  file_upload_complete: 'File upload complete!',
  upload_without_resumes: 'Upload without resumes',
  Duplicated_field: 'Duplicate values are not allowed.',
  add_employee_title: 'Would you like to add this employee to your company?',
  add_as_employee: 'Add as employee',
  fill_employee_info:
    'You will need to fill some mandatory information to add this employee to your organization.',
  additional_employee_fees: 'Additional employees fees',
  additional_employee_fees_message:
    'New employees will result in additional fees until the subscription ends.',
  congratulations_found_Your_stars: `Congratulations, You've Found Your Stars!`,
  add_hired_candidates: `You can make them official employees by adding them to your organization. Easily manage and add all your hired candidates on the 'Hired Candidates' page.`,
  go_to_hired_candidates: 'Go to Hired Candidates',
  employees_cannot_be_moved_through_stages: 'Employees cannot be moved through stages',
  reinstate_as_candidate: 'Reinstate as candidate',

  recruiter_placeholder: 'Select a recruiter',
  recruiter_label: 'Recruiter (Optional)',
  recruiter_tooltip: 'Cannot delete the job or reassign candidates.',
  hiring_manager_label: 'Hiring manager (Optional)',
  hiring_manager_tooltip:
    'Cannot edit or delete jobs, modify candidate details, assign candidates to other positions, or manage job listing status.',
  hiring_manager_placeholder: 'Select a hiring manager',
  assigned_to_this_job: '{{ others }} assigned to this job',
  recruiter: 'Recruiter',
  hiring_manager: 'Hiring manager',

  jobs_breakdown: 'Jobs breakdown',
  generate_candidates_report: 'Generate Candidates Report',
  generate_candidates_report_details:
    'This report provides a detailed overview of all candidates data on the applicant tracking system.',
  generate_jobs_report: 'Generate Jobs Report',
  generate_jobs_report_details:
    'This report provides a detailed overview of all jobs data on the applicant tracking system.',
  stage_name: 'Stage Name',
  ats_reports: 'ATS Reports',
  job_reports: 'Job Reports',
  candidate_reports: 'Candidate Reports',
  ats_statistics: 'ATS Statistics',
  ats_all_filters: 'All Filters',
  total_candidates: 'Total Candidates',
  total_jobs: 'Total Jobs',
  jobs_in_the_last_30_days: 'new jobs in the last 30 days',
  hired_candidates: 'Hired Candidates',
  closed_with_no_hires: 'Closed with no hires',
  open_with_no_hires: 'Open with no hires',
  active_jobs_reports: 'Active Jobs',
  job_status: 'Job status',
  no_of_days: 'No of Days',
  use_mention: `Use @ before a name to mention someone and we'll notify them.`,
  receive_job_notifications: 'Receive job notifications',
  receive_job_notifications_detail:
    'Enable to receive notifications with new job updates and applicant activities.',
  watcher_label: 'Select other users you would like to notify',
  watcher_placeholder: 'Select users',
  users_receiving_job_notifications: 'Users receiving job notifications',
  access_denied_comment_section:
    "Access denied: You can't view this comment without access to the job",
  comment_edited_on: 'Edited on',

  yes_no: 'Yes/No',
  auto_rejection_answer: 'Auto-rejection answer',
  set_this_response_to_auto_reject: 'Set this response to auto-reject',
  unable_to_set_all_responses_as_auto_reject: 'Unable to set all responses as auto-reject',
  candidate_was_auto_rejected: 'Candidate was auto-rejected',
  sorting_old_to_new: 'Oldest to Newest',
}

export default atsRecruitment
