import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import { returnBadgeList } from '../helper'

type NationalityTypes = {
  filterKey: string
  filters: string[]
}

const Nationality = (props: NationalityTypes): JSX.Element => {
  const { nationality } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = returnBadgeList(nationality)

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default Nationality
