export const SCHEDULER_LAST_IMPORT = 'SCHEDULER_LAST_IMPORT'
export const SCHEDULER_LAST_IMPORT_PENDING = 'SCHEDULER_LAST_IMPORT_PENDING'
export const SCHEDULER_LAST_IMPORT_FULFILLED = 'SCHEDULER_LAST_IMPORT_FULFILLED'
export const SCHEDULER_LAST_IMPORT_REJECTED = 'SCHEDULER_LAST_IMPORT_REJECTED'

export const MARK_IMPORT_COMPLETE = 'MARK_IMPORT_COMPLETE'
export const MARK_IMPORT_COMPLETE_PENDING = 'MARK_IMPORT_COMPLETE_PENDING'
export const MARK_IMPORT_COMPLETE_FULFILLED = 'MARK_IMPORT_COMPLETE_FULFILLED'
export const MARK_IMPORT_COMPLETE_REJECTED = 'MARK_IMPORT_COMPLETE_REJECTED'

export const DOWNLOAD_SCHEDULING_TEMPLATE = 'DOWNLOAD_SCHEDULING_TEMPLATE'
export const DOWNLOAD_SCHEDULING_TEMPLATE_PENDING = 'DOWNLOAD_SCHEDULING_TEMPLATE_PENDING'
export const DOWNLOAD_SCHEDULING_TEMPLATE_FULFILLED = 'DOWNLOAD_SCHEDULING_TEMPLATE_FULFILLED'
export const DOWNLOAD_SCHEDULING_TEMPLATE_REJECTED = 'DOWNLOAD_SCHEDULING_TEMPLATE_REJECTED'
