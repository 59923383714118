const Payroll = {
  _face1: 'غير راضي أبداً',
  _face2: 'غير راضي',
  _face3: 'محايد',
  _face4: 'راضي',
  _face5: 'راضي جداً',

  // A
  add_task: 'إضافة مهمة',
  add_bank_account: 'إضافة حساب بنكي',
  add_step: 'إضافة خطوة',
  add_a_comment: 'أضف تعليق',
  addition: 'إضافة',
  additions: 'الإضافات',
  exclude: 'استثناء',
  authorized_employees: 'الموظفون المفوضون',
  show_number_more: 'عرض {{number}} اكثر',
  not_authorized: 'غير مفوض',
  ex_vat: 'غير شامل الضريبة',
  authorized: 'تم التفويض',
  'Payroll Accountant setup note': '{{ name }} يمكنه عرض المسير وتحميل/إسال القيد المحاسبي.',
  'Payroll Admin setup note':
    '{{ name }} يمكنه عرض كشف الرواتب والتعديل عليه، وكذلك تغيير إعدادات المسير.',
  'Payroll Executor setup note': '{{ name }} يمكنه التعديل على كشف الرواتب، وإرساله للإعتماد.',
  'Payroll Viewer setup note': '{{ name }} يمكنه عرض كشف الرواتب، ولكن لا يمكنه التعديل على المسير',
  add_addition_deduction_in_bulk: 'رفع ملف إضافات وخصومات',
  show_details: 'عرض التفاصيل',
  amount_sar: 'المبلغ (񥀠)',
  apprised_this_month: 'موظفين تم عمل علاوة لهم هذا الشهر',
  areas: 'المناطق',
  are_you_sure_send_attendance_manager:
    'هل تريد إرسال تذكير إلى الشخص المسئول عن تأكيد الحضور والإنصراف للموظفين؟',
  alinma_bank: 'بنك الإنماء',
  additional_info: 'معلومات اضافية',
  add_new_addition_or_deduction_type: '+إضافة نوع إضافة أو خصم جديد',
  additions__eductions: 'اﻹضافات والخصومات',
  add_new_type: 'إضافة نوع جديد',
  approval_form_note:
    'هذا الاعداد لمرة واحدة بعد انشاء سلسلة الموافقات سيتم تطبيقها بشكل تلقائي في مسير الرواتب الحالي وعند تأكيد أي مسير رواتب لاحق يمكنك تعديل سلسلة الموافقات من',
  approvals: 'سلسلة الموافقات',
  ajeer: 'التكلفة الاجمالية لموظفين اجير',
  agency_fees: 'رسوم اجير',
  agency_fees_low_tiers: 'رسوم الشركة المؤجرة',
  ajeer_company_name: 'إسم شركة تأجير العمالة',
  add_remarks: 'إضافة ملاحظات',
  absence_addition_rate: 'معدل اضافة الغياب',
  absence_addition_base: 'أساس احتساب اضافة الغياب',
  absence_deduction_rate: 'معدل خصم الغياب لكل يوم',
  absence_deduction_base: 'أساس احتساب خصم الغياب',
  absence_deduction_retro_rate: 'معدل خصم الغياب لكل يوم - أثر رجعي',
  absence_deduction_retro_base: 'أساس احتساب خصم الغياب - أثر رجعي',
  addition_amount: 'مبلغ الاضافة',
  deduction_amount: 'مبلغ الخصم',
  deduction_amount_gosi: 'مبلغ خصم التأمينات الاجتماعية',
  add_remark: 'إضافة ملاحظة',
  absent_day: 'يوم الغياب',
  added_by: 'تمت إضافتها بواسطة',
  actual_resumption_date: 'تاريخ المباشرة',
  activate_mudad_now: 'قم بتفعيل مدد الآن',
  attendance_status_approved_title: 'تم تأكيد سجل الحضور والإنصراف لشهر {{date}} ',
  attendance_status_approved_discprition:
    'قم بالبدء بإحتساب مسير الرواتب من أجل رؤية المتغيرات الخاصة بسجل الحضور والانصراف',
  attendance_status_not_yet_approved_title: 'لم يتم تأكيد سجل الحضور والإنصراف بعد لشهر {{date}} ',
  attendance_status_not_yet_approved_discprition:
    'من أجل تأكيد مسير الرواتب يجب على مدير الحضور والانصراف التأكيد على سجل الحضور والإنصراف للموظفين.',
  attendance_status_pending_title: 'يمكنك البدء بإحتساب مسير الرواتب ولكن ',
  attendance_status_pending_discprition: ' لن تنعكس في مسير الرواتب.',
  attendance_status_dismissed_title: 'تم تجاهل سجل الحضور والإنصراف لشهر {{date}} ',
  attendance_status_dismissed_sup_title: ' لن يتم تضمينها ضمن مسير الرواتب',
  attendance_status_pending_reminder:
    'سيتحصل الموظفون فقط على الراتب الأساسي والبدلات والإضافات المالية الأخرى',
  attendance_status_dismissed_discprition:
    'سوف يتحصل الموظفون فقط على الراتب الأساسي والبدلات والإضافات المالية الأخرى.',
  add_new_transaction: 'أضف معاملة جديدة',
  add_transaction: 'أضف معاملة',
  approve_reason: 'Reason of Approve Ar',
  approved: 'مؤكد',
  attendance_manager_is_required_to_approve_attendance_to_complete_clearance:
    'To complete the clearance, attendance manager is required to approve the attendance AR.',
  add_working_days_salary: 'Add working days salary ar',
  annual_leave_details: 'تفاصيل الإجازة السنوية',
  attendancereview: 'مراجعة الحضور',
  annual_leave_detials: 'بيانات الإجازة السنوية',
  applicable_amount: 'الأجر الخاضع للأشتراك',
  activity_log_pay: 'سجل التغييرات',
  attendance_sheet_review: 'كشف الحضور',
  attendance_sheet_review_tip:
    'يؤثر كشف الحضور والانصراف بعد اعتماده على رواتب الموظفين بالزيادة أو النقص بحسب السياسة المعرفة للمنشأة.',
  active_pay: 'فعّال',
  any_modifications:
    'تخطي المسيرات السابقة سيلغي جميع التعديلات، ولن تظهر المسيرات في صفحة تحويل الرواتب ولا التقارير.',
  add_at_least_one_cr_details: 'عليك إضافة التفاصيل القانونية لسجل تجاري واحد على الأقل',
  add_at_least_one_cr_details_note:
    'أضف رقم السجل التجاري، ورقم المنشأة في وزارة الموارد البشرية، ورقم الاشتراك في التأمينات الإجتماعية',
  add_approvers: 'إضافة مراجع',
  automate_your_payroll: 'أتمت حسابات مسير الرواتب وادفع لموظفيك خلال دقائق',
  access_full_course: 'شاهد دورة المسير كاملة',
  add_employees_payment_methods: 'أضف طرق الدفع للموظفين',
  attendance_deductions: 'خصومات الحضور والإنصراف',
  add_hings_that_Jjsr_doesnt_automate:
    'أضف العناصر التي لا يؤتمتها {{app_name}}. {{app_name}} يمكنه احتساب الخصومات التالية بشكل تلقائي خصومات سلف الموظفين، خصومات التأمينات الاجتماعية (GOSI)، خصومات الحضور، وخصومات الإجازات غير المدفوعة.',
  add_workflow_approvals: 'حدد سلسلة المراجعة والإعتماد للمسير',
  add_collaborators: 'أضف مسئولين آخرين',
  add_collaborators_desc:
    'تعاون بسهولة مع فريقك. اختر المستخدمين من القائمة الذين يمكنهم العمل معك لإعداد ومعالجة الرواتب',
  add_salary_info: 'إضافة معلومات الراتب',
  app_also_auto_calculate: 'يساعدك {{app_name}} على التحكم وإحتساب التالي ',
  any_other_additions_or_deductions: 'إحتساب أية إضافات او خصميات أخرى',
  absence_deduction: 'إستقطاع ايام الغياب',
  all_issues: 'كل الملاحظات ({{number}})',
  accepted_files_format: 'صيغة الملف المدعومة هي xls, xlsx',

  // B
  bank_report: 'انشاء ملف البنك',
  business_unit: 'وحدة عمل',
  bulk_upload: 'رفع ملف',
  by_payment_method: 'بواسطة طريقة الدفع',
  back: 'عودة',
  bad_experience_payroll: 'نعتذر على تجربتك السلبية, شاركنا بما يمكن تحسينه في مسيّر الرواتب',
  back_pay: 'رجوع',
  become_fimliar_jisr: 'تعرف أكثر على النظام',
  business_trips_payment: 'رحلات العمل والإنتدابات',
  back_to_payroll_landing: 'الرجوع الى صفحة المسير',
  business_trip_periderm: ' بدل رحلات العمل',
  // C
  cut_off_date_for_last_month: 'تاريخ استقطاع الرواتب للشهر الماضي',
  calculate_status: 'إحتساب',
  configure: 'تهيئة',
  change_period_date: 'تغيير تاريخ الفترة',
  check_the_final_settlement: 'التسوية النهائية',
  congras_reminder_sent_note: 'رائع!<br /> لقد قمت بإرسال التذكير بنجاح',
  comment: 'التعليق',
  create_approval_settings: 'إعداد سلسة موافقات مسير الرواتب',
  create_approval_cycle: 'إنشاء سلسة موافقات مسير الرواتب',
  confirm_next: 'تأكيد و التالي',
  confirm_payroll: 'تأكيد مسير الرواتب',
  choose_a_bank: 'اختر بنك',
  continu_without_approval_cycle: 'استكمال تأكيد مسير الرواتب بدون سلسة موافقات',
  compensation_sar: 'تفاصيل الراتب',
  click_here_to_file_upload: 'اضغط هنا لرفع الملف',
  click_here: 'أنقر هنا',
  confirmed: 'مؤكدة',
  continue: 'إستمرار',
  continue_pay: 'استكمال',
  customize_column: 'معلومات إضافية',
  customize_payroll_table_column: 'تخصيص الأعمدة',
  confirm_payroll_note_reminder:
    'تأكد من مراجعة كشف الرواتب بشكل صحيح. لن تتمكن من إجراء أي تغييرات على المسير عندما يتم إرساله إلى سلسلة الموافقات',
  confirm_payroll_note:
    '<b>ملاحظة:</b> عند تأكيد مسير الرواتب وإكتمال موافقات مراجعة مسير الرواتب ضمن سلسلة الموافقات الخاصة بالمسير ، لا يمكن التراجع عن التغييرات التي تم إجرائها (إعادة فتح المسير), لذا يرجى التأكد من صحة ومطابقة جميع المعلومات المالية قبل إجراء التأكيد النهائي',
  confirmation_export_bank_report: 'تصدير تقرير البنك',
  confirmation_export_bank_report_secondary: 'تصدير ملف إكسل وارساله الى البنك',
  confirmation_payslip_message:
    'كشوف الرواتب سيتم ارسالها للبريد الالكتروني للموظفين في {{date}} تستطيع',
  confirmation_payslip_message_edit_that: 'تعديل ذلك',
  confirmation_quick_overiew: 'لمحة سريعة',
  confirmation_success_message: 'اتممت بنجاح إنشاء مسير الرواتب لشهر {{date}}',
  confirmation_sync_with_accountant: 'مزامنة مع النظام المحاسبي',
  confirmation_total_amount: 'إجمالي مسير الرواتب',
  confirmation_total_employees: 'إجمالي عدد الموظفين في مسير الرواتب',
  confirmation_your_current_accountant: 'النظام الحالي {{name}}',
  confirmation_bank_report_was_sent: 'تم إرسال تقرير البنك لبريدك الالكتروني',
  confirmation_check_your_inbox: 'تحقق من صندوق الوارد او غير المرغوب الخاص بك',
  congratulations: 'تهانينا !',
  compliance_system_status: 'حالة نظام الإلتزام (حماية الإجور)',
  comparing_payroll: 'مقارنة بمسير {{date}}',
  couldnt_receive_the_bank_file: 'لم تتمكن من استلام ملفات البنك؟',
  cutOff_period_message_tip:
    'فترة الإستقطاع هي الفترة الزمنية التي يتم فيها تجميع المعاملات المالية من أجل عكسها على مسير الرواتب, تتشكل فترة الإستقطاع من اليوم التالي لتاريخ إستقطاع الشهر الماضي حتى تاريخ إستقطاع الشهر الحالي',
  cutOff_period_message_tip_permission:
    'ليس لديك الصلاحية لتغيير تاريخ الفترة, يرجى التواصل مع المشرف',

  cut_off_period: 'فترة الإستقطاع',
  created_at: 'تاريخ الإدخال',
  changes_history: 'سجل التغيرات ',
  closing_date_pay: 'تاريخ الإجراء',
  confirm_operation_cut_off_period_for:
    'تحديد فترة المتغيرات لمسير {{month}} سيحدد فترة سجلات الحضور المنعكسة.',
  confirm_cut_off_period: 'تأكيد',
  clear_search: 'مسح النتائج',
  commercial_registration_ids: 'تم التصفيه بهذه السجلات التجاريه',
  configure_workflow_approvals:
    'فعل سلسة المراجعة والإعتماد وفقًا لاحتياجاتك. حدد الموافقات والأشخاص المعنيين لضمان ان عملية معالجة الرواتب سلسة وفعالة وقليلة الأخطاء',
  check_issues: 'عرض السجلات',
  completed_pay: 'مكتملة',
  changing_the_last_working_day_note:
    'يرجى ملاحظة أن تغيير آخر يوم عمل سيتطلب منك إعادة تقديم كشف الحضور للمراجعة. سيتم إزالة أي كشف حضور سابق يتعلق بهذه المخالصة  ',
  complete_it: 'التعيين كمكتملة',
  check_mismatches: 'بدء المطابقة',
  activate_payroll_payment_method: 'فعِل طريقة دفع الرواتب المناسبة',
  activate_payroll_payment_method_desc:
    'أضف حساب الرواتب الخاص بك واربطه بشكل مباشر مع مدد الرواتب أو مع البنك الأول لدفع مسير الرواتب مباشرة من جسر وتحديث حالة الالتزام بشكل تلقائي.',
  add_your_payroll_bank_account:
    'أضف حساب الرواتب الخاص بك واربطه بشكل مباشر مع مدد الرواتب أو مع البنك الأول لدفع مسير الرواتب مباشرة من {{app_name}} وتحديث حالة الالتزام بشكل تلقائي.',
  add_your_payroll_pay_bank_account:
    'قم بتفعيل خدمة الدفع عن طريق {{app_name}} وتكامل مع نظام حماية الأجور (WPS) مباشرة، لدفع الرواتب دون أي متاعب  ',
  book_a_session: 'إحجز جلستك',
  customize_overtime_policy:
    'قم بتخصيص سياسة العمل الإضافي على مستوى الشركة أو القسم أو الموقع. قم بتعيين الحد الشهري لساعات العمل الإضافية والمزيد...',
  control_the_calculation:
    'تحكم في طريقة حساب خصومات الغياب، الخروج المبكر، التأخير، والسجلات الغير مكتملة',
  customize_your_addition_types: 'خصص أنواع الإضافات',
  customize_addition_types:
    'قم بتخصيص أنواع الإضافات لموظفيك، مثل مطالبات المصاريف، العمولات، العلاوات، والمزيد. قم بتعريفها في خطوات بسيطة',
  customize_your_loan_policy:
    'قم بتخصيص سياسة سلف الموظفين الخاصة بك، وادفع طلبات السلف، وجدول الأقساط، وخصمها من الرواتب بسهولة',
  customize_your_business_trips:
    'قم بإعداد سياسة رحلات العمل، وحدد البدل اليومي، واسمح لموظفيك بتقديم طلباتهم بسهولة',
  customize_your_expense_claims:
    'قم بتفعيل مطالبات المصاريف المالية واسمح للموظفين بتقديم المصاريف وإرفاق الفواتير لدفعها داخل آو خارج المسير',
  customize_your_deductions_types: 'خصص أنواع الخصومات',
  customize_your_additions_types: 'خصص أنواع الإضافات',
  confirm_and_next: 'تأكيد',
  confirm_and_continue: 'تأكيد والتالي',

  confirm_the_operation: 'أكد فترة المتغيرات',
  could_not_upload_payment_file_title: 'لا يمكن رفع الملف “{{file_name}}" ',
  could_not_upload_payment_file_desc:
    'يمكن رفع ملف القالب المحدد مسبقًا فقط ، تأكد من رفع الملف الصحيح.',
  calculate_employees_payroll: 'بدء إحتساب المسير',
  congrats_ending_payroll: 'تهانينا! لقد اتممت إعدادات مسير الرواتب بنجاح',
  congrats_ending_payroll_note: 'يمكنك تغيير هذه الإعدادات من صفحة الإعدادات',
  choose_employee: 'اختر الموظف',
  choose_role: 'الصلاحية',
  click_to_upload_or_drop: '<click>اضغط هنا لرفع الملف</click> او قم بالسحب والإفلات هنا',

  // D
  deduction: 'خصم',
  deductions: 'الخصومات',
  deduction_types: 'Deduction type ar',
  Review_records_low_tiers: 'المراجعة والتعديل',
  low_tiers_rejected_records: 'اعادة رفع السجلات المرفوضة',
  detail: 'التفاصيل',
  download_additions_deductions_sample_file: 'تحميل قالب الإضافات والخصومات',
  download_bank_file: 'تحميل ملف الرواتب',
  download_xls: 'تصدير ملف Excel',
  download_pdf: 'تحميل ملف PDF',
  download_template_file: 'تحميل القالب',
  download_app_template_file: 'تحميل القالب',
  download_app_template_file_note: 'استخدم القالب لتجنب الأخطاء والمشاكل عند رفع الجداول.',

  download_template_file_low_des: ' استخدم القالب لتجنب الأخطاء والمشاكل عند رفع الجداول.',
  discard: 'إلغاء',
  draft: 'تحت الإنشاء',
  download_now: 'تنزيل الآن',
  download_sample_file: 'تحميل القالب',
  drag__drop:
    '<strong>اسحب وافلت</strong> الملفات هنا<br /> او <span class="browse">تصفح</span> الملفات',
  'Detail View': 'عرض التفاصيل',
  daily_rate: 'المعدل اليومي',
  delay_deduction_rate: 'معدل خصم التأخير  لكل ساعة',
  delay_deduction_base: 'أساس احتساب خصم التأخير',
  download_payment_file: 'تحميل ملف الدفع',
  delay_deduction_hour: 'معدل خصم التأخير  لكل ساعة',
  late_arrival_base: 'أساس احتساب خصم التأخير',
  late_arrival_retro: 'معدل خصم التأخير  لكل ساعة - أثر رجعي',
  late_arrival_base_retro: 'أساس احتساب خصم التأخير - أثر رجعي',
  delay_addition_rate: 'معدل إضافة التأخير  لكل ساعة',
  delay_addition_base: 'أساس احتساب إضافة التأخير',
  direct_transfer_file: 'ملف التحويل المباشر',
  deduction_rate: 'قيمة خصم اليوم الواحد',
  default_fund_date: 'تاريخ صرف الراتب',
  fund_dates: 'تاريخ صرف الرواتب',
  date_ef: 'تاريخ الإستحقاق',
  dismiss: 'الغاء',
  difference: 'الفرق',
  direct_bank_integration: 'ربط مباشر مع البنك',
  download_WPS_file: 'تحميل ملف حماية الأجور',
  days_remaining: '{{day}} يوم لحماية الاجور',
  wps_file_is_available: 'سيكون الملف متاحًا بعد 3 أيام عمل من تحويل الرواتب.',
  days_pay: 'يوم',
  download_wages_template: ' تحميل قالب الأجور',
  download_gosi_wages: 'قم بتحميل ملف الرواتب والأجور',
  download_gosi_wages_note:
    'حمل ملف الأجور من موقع الهيئة العامة للتأمينات الاجتماعية "أعمالي". أو قم بتحميل وتعبئة قالب الأجور الخاص بنا مباشرة',

  define_your_employees_payment_methods: 'حدد طريقة الدفع لكل موظف',
  define_how_each_employee:
    'حدد كيف يتلقى موظفوك رواتبهم، مثال: عن طريق تحويل بنكي، نقداً أو تحويل دولي.',
  check_issues_and_take_action: 'تحقق من القائمة واتخذ القرار المناسب',
  define_payroll_schedule: 'حدد فترة الرواتب',
  days_spent_the_payroll: ' تحضير ودفع الرواتب في دقائق مع {{app_name}}!',
  discover_more_dots: 'إستكشف المزيد...',
  donot_show_again: 'لا تظهر هذه الرسالة مرة أخرى',
  download_addition_deduction_sheet: 'حمل ملف الإضافات والخصومات',
  download_addition_deduction_sheet_note: 'استخدم هذا الملف لتجنب المشاكل والأخطاء اثناء الرفع',
  download_the_file: 'تحميل الملف',
  download_failed_entries: 'تحميل الإدخالات المرفوضة',
  // E
  edit_payslip_send_date: 'تعديل تاريخ ارسال راتب الموظف',
  employees_have_wrong_english_name: 'الموظفين لديهم أسماء إنجليزية خاطئة',
  employee_count_total: 'إجمالي عدد الموظفين ({{count}})',
  employees_with_salary_card: 'الموظفين على بطاقة الرواتب.',
  employee_gosi_registration_task: 'مهمة تسجيل موظف في التأمينات الإجتماعية ',
  employee_will_paid: 'موظف(ين) سيتم الدفع لهم',
  employee_will_excluded: 'موظف(ين) سيتم استثناءهم',
  employee_will_included: 'موظف(ين) سيتم تضمينهم',
  export_to_xero: 'ترحيل إلى نظام XERO',
  employee__id: 'اسم الموظف & الرقم الوظيفي',
  employees_paid_in_this_payroll: 'عدد الموظفين في مسير الرواتب',
  employees_paid_in_previous_payroll: 'عدد الموظفين في مسير الرواتب السابق',
  employees_on_annual_leave: 'عدد الموظفين في إجازة',
  employee_name: 'إسم الموظف',
  employees_list: 'قائمة الموظفين',
  employee_has_been_suspended: 'تم ايقاف الراتب للموظف',
  exit_reentry_info: 'معلومات الخروج والعودة',
  earnings_deductions: 'الإيرادات والخصومات',
  employee_details: 'معلومات الموظف',
  export_excel: 'تصدير ملف إكسل',
  export_xls: 'تصدير ملف XLS',
  export_pdf: 'تصدير ملف PDF',
  export_as_pdf: 'تصدير كــ PDF',
  export_pdf_with_company_details: 'تصدير كــ PDF + معلومات الشركه',
  extra_cols: 'الأعمدة اضافية',
  excluded_list: 'قائمة المستثنين',
  excluded_reason: 'سبب الاستثناء',
  export: 'تصدير',
  events_notification_note:
    'حدثت بعض التغييرات في معلومات مسير الرواتب، حدث الصفحة لمراجعة هذه التغييرات',
  exit_wizard: 'خروج',
  employees_included: 'عدد الموظفين',
  employees_included_low_tiers: 'عدد الموظفين في المسير',
  employees_excluded_low_tiers: 'عدد المستبعدين',
  employees_excluded: ' {{num}} عدد المستبعدين',
  export_settings: 'اعدادات تصدير التقرير',
  edit_employee_profile_report: 'تقرير تعديلات ملف تعريف الموظف',
  early_resumption_base: 'إجمالي قيمة إضافة المباشرة المبكرة',
  early_resumption_rate: 'قيمة إضافة اليوم الواحد للمباشرة المبكرة',
  excuse_hour_rate: 'معدل ساعات الإستئذان',
  effective_month: 'أثر رجعي من شهر',
  early_resumption_days: ' أيام المباشرة المبكرة',
  employees_included_message_tip: 'عدد الموظفين المشمولين في مسير الرواتب للشهر الحالي.',
  employees_excluded_message_tip: 'عدد الموظفين المستبعدين من مسير الرواتب للشهر الحالي.',
  effective_on: ' تاريخ الإستحقاق',
  xls_format: 'ملف XLS',
  employee_contribution: 'نسبة اشتراك الموظف',
  export_report: 'تصدير التقرير',
  edit_cut_off_period: 'تعديل فترة الإستقطاع',
  edit_workflow_approvals: 'تعديل مراجع سلسله الموافقات',
  payrun_employee: 'You have {{number}} employee didn’t have a payment method',
  employee_without_salary:
    'You`ve started the payroll for {{start_number}} out of {{total_number}} employees, with {{last_number}} employees currently suspended',
  employee_without_salary_title:
    'يشمل هذا المسير {{number}} موظف من إجمالي {{total}} موظف مضافين في النظام',
  employee_without_salary_note:
    'لضمان احتساب مسير الرواتب بشكل صحيح، يرجى التأكد من تعريف الرواتب للموظفين الغير مشمولين',
  employee_without_payment_title: 'لديك {{number}} موظف لم يتم تعريف طريقة الدفع الخاصة بهم',
  employee_without_payment_note:
    'لن تتمكن من الدفع لهم حتى يتم تعريف طريقة الدفع المناسبة لكل موظف',
  explore_iisr_payroll: 'إنشئ مسير رواتب تجريبي',
  enable_attendance_sheet_reflection: 'تضمين سجل الحضور والانصراف في المسير ',
  expense_claims_payment: 'مطالبات المصاريف المالية',
  easily_add_define_new_addition: 'أضف وحدد أنواع الإضافات الخاصة بمنشئتك',
  enable_payslip_sharing_with_employees: 'شارك مع موظفيك قسيمة رواتبهم',
  ensure_compliance_with_gosi: 'ابقى على تزامن مع التأمينات الإجتماعية',
  employees_upload_files: '{{number}} موظفين',
  employees_attendance_records:
    'ستعتمد سجلات حضور الموظفين، بما في ذلك الغياب والتأخير، على هذه الفترة [بشرط تمكينها]',
  easily_manage_payments: 'دفع رواتب الموظفين صار أسهل مع {{app_name}} !',
  edit_salary: 'تعديل الراتب',
  edit_cut_off_date: 'تغيير تاريخ الإستقطاع',
  employees_without_salary: 'موظفين بدون راتب',
  employees_without_salary_note: 'لم يتم تعريف معلومات الراتب للموظفين التاليين',
  employees_didnot_have_payment_method: 'موظفين لا توجد لديهم طريقة دفع',
  expense_claims: 'المطالبات المالية ',
  employees_loans: 'سلف الموظفين',
  early_out_deduction: ' إستقطاع التأخيرات',
  end_testing_payroll_alert: 'إنهاء مسير الرواتب التجريبي',
  end_testing_payroll_alert_desc:
    'انت بصدد إنهاء مسير الرواتب التجريبي، سوف يتم إزالة جميع الإضافات والخصومات التي قمت بعملها على هذا المسير  ',
  // F
  fsv_big_text:
    'يقوم {{app_name}} بالتحقق من مدى دقة معلومات الموظفين مقارنة بالمعلومات المتوفرة في التأمينات الاجتماعية. تساعدك هذه الخطوة على تجنب مشاكل عدم تطابق الرواتب والتأكد من تسجيل اشتراكات الموظفين والمزيد...',
  payroll_final_settlement: 'المخالصة النهائية',
  filter_employees: 'فرز الموظفين',
  fees_changes: 'تغير رسوم الأجير',
  fees_vat_included: 'الرسوم (شامل الضريبه)',
  for_employees_who_are_registered_with_gosi:
    'للموظفين المسجلين في التأمينات الاجتماعية (حماية الأجور)',
  for_employees_who_are_not_registered_with_gosi:
    'للموظفين الغير مسجلين في التأمينات الاجتماعية (حماية الأجور)',
  for_all_of_the_employees: 'لكل الموظفين',
  final_settelement: 'Final Settlement ar',
  final_settlement_list: 'مخالصات نهاية الخدمة',
  from_cut_off: 'فترة إستقطاع',
  flight_ticket: 'تذاكر السفر',
  final_settlement_review_request: 'طلب مراجعة وإعتماد المخالصة النهائية',
  from_pay: 'بداية الفترة',
  from_to_total_days: '{{from}} - {{to}} ({{total}}يوم)',
  full_day: ' خصم يوم كامل ',
  for_seamless_payroll_run: 'لضمان عملية سلسة لمسير الرواتب لديك، يرجى إكمال بعض الإعدادات أولاً',
  facilitate_your_hr_tasks:
    'سهل مهام قسم الموارد البشرية الخاص بك، بسط عملية إنشاء التقارير المحاسبية والمالية، وقم بإدارة المدفوعات - كل ذلك على منصة واحدة متكاملة.',
  failed_records: 'سجلات مرفوضة',
  full_report: 'ملخص المسير',
  full_report_hide: 'اخفاء ملخص المسير',
  full_day_payroll: ' خصم يوم كامل ',
  file_ready_to_download: 'الملف جاهز للتحميل',
  failed_entries: 'إدخالات فاشلة',
  file_is_downloading_hint: 'جاري تحميل الملف، قد يتطلب ذلك بعض الوقت',
  // G
  gosi_enable_calculation: 'تفعيل إحتساب التأمينات الإجتماعية',
  gosi_disable_calculation: 'تعطيل إحتساب التأمينات الإجتماعية',
  gosi_file_sample: 'نموذج ملف التأمينات',
  gosi_applicable_amount: 'الأجر الخاضع للأشتراك',
  gosi_file_name: 'اسم ملف التأمينات الإجتماعية',
  gosi_last_update: 'آخر تحديث للتأمينات الإجتماعية',
  gosi_salary_updation_task: 'مهمة تحديث الأجر المسجل في التأمينات الإجتماعية',
  grand_total: 'المجموع الكلي',
  payment_details_changes: 'تغيير تفاصيل الدفع',
  generater_edit_employee_profile_report: 'إنشاء تقرير تعديلات ملف تعريف الموظف',
  got_it: 'اعي ذلك',
  gosi_company_name: 'إسم شركة التأمينات الإجتماعية',
  gosi_company_contribution: 'مساهمة الشركة في التأمينات الإجتماعية',
  gosi_company_contribution_low_tires: 'مساهمة المنشأة',
  gosi_payable: 'إجمالي مستحقات التأمينات الإجتماعية',
  gosi_employee_contribution_percentage: 'نسبة خصم التأمينات على الموظف',
  gosi_applicable_applicable_amount: 'الراتب المسجل في التأمينات للموظف',
  go_to_gosi_website: 'الذهاب لموقع التأمينات الإجتماعية "تأميناتي - أعمال"',
  go_to_gosi_website_note: 'قم بتسجيل الدخول على حساب المنشأة في تأميناتي - أعمال',
  go_to_gosi_web_link: 'الذهاب الى تأميناتي - أعمال',
  gosi_success_upload_message:
    '{{number_of_success}} من أصل {{total}} موظف معلومات الراتب واشتراك التأمينات لديهم متطابقة',
  gosi_issue_upload_message:
    'تم العثور على {{number}} سجلات غير متطابقة، يرجى المراجعة لإتخاذ الإجراء المناسب',
  get_notified_about_all_GOSI:
    'الحصول على ملاحظات قيمة بشأن المشاكل الممكنة المتعلقة بنظام العمل وحماية الأجور',
  get_personalized_assistance:
    'انضم إلى جلساتنا التعليمية المباشرة للحصول على المساعدة المطلوبة. احجز جلستك الآن من خلال هذا الرابط',
  gosi_sync: 'التحقق من الإمتثال',
  gosi_deductions: ' إستقطاعات التأمينات الإجتماعية ',
  generate_new_file: 'إنشاء ملف جديد',
  // H
  how_to_export_gosi_file: 'كيفية تصدير ملف التأمينات الإجتماعية ؟',
  have_you_already_paid_this_to_the_employee: 'هل سبق و دفعت هذا للموظف ؟',
  hours_minutes: 'ساعات/دقائق',
  how_to_get_this_gosi_info: 'كيفية الحصول على معلومات التسجيل لمشترك في التأمينات الإجتماعية؟',
  half_day: 'خصم نصف يوم',
  how_to_run_payroll: 'كيفية إحتساب مسير الرواتب',
  have_sabb_bank_account_check_integration_steps:
    'لديك حساب بنكي في  Saab البنك الأول؟ قم باستعراض خطوات التفعيل',
  hide_empty_columns: 'إخفاء الأعمدة الفارغة',

  half_day_payroll: 'خصم نصف يوم',
  // I
  id: 'الرقم',
  import_gosi_file_wages_form: 'رفع ملف التأمينات - ملف الأجور',
  it_takes_some_time_to_calculate: 'الرجاء الإنتظار بينما يتم حساب مسير الرواتب',
  importing: 'جاري الرفع',
  insurance_changes: 'تغيير التأمين',
  i_will_do_that_later: 'القيام بذلك لاحقاً',
  import: 'رفع',
  is_retro: 'أثر رجعي',
  payroll_new_employees: 'موظفين جدد',
  payroll_emp_excluded: 'مستبعدين',
  it_is_payroll_time_now: 'رائع! يمكنك بدء مسير الرواتب الآن',
  in_complete_records_deduction: ' إستقطاع خصم البصمة المفقودة ',
  // J
  jisr_knowledge_base: 'مركز المعرفة',
  jisr_academy: 'أكاديمية {{app_name}}',
  joined_this_month: 'تم التحاقهم خلال فترة مسير الرواتب',
  joining__date: 'تاريخ الانضمام',
  jisr_handles_everything:
    'يحتسب {{app_name}} كل شيء بشكل تلقائي بدءًا من مرتبات الموظفين الجدد، وانعكاس سجلات الحضور والانصراف، وخصومات سلف الموظفين، والتأمينات الاجتماعية، والمزيد.',
  jisr_verifies_your_data:
    'يقوم {{app_name}} بالتحقق من مدى دقة معلومات الموظفين مقارنة بالمعلومات المتوفرة في التأمينات الاجتماعية. تساعدك هذه الخطوة على تجنب مشاكل عدم تطابق الرواتب والتأكد من تسجيل اشتراكات الموظفين والمزيد...',
  // K
  kindly_hold_on: '...جاري المعالجة, يرجى الإنتظار',
  know_more: 'اعرف اكثر',
  // L
  last_updated: 'آخر تحديث',
  latest_request: 'آخر طلب',
  leaving_date: 'تاريخ الذهاب',
  last_update: 'اخر تحديث:',
  learn_about_jisr_payroll: 'تعرف على مسير رواتب جسر',
  learn_more: 'معرفة المزيد',
  leave___attedance: 'الإجازات والحضور',
  learn_more_about_mudad_integration: 'تعرّف على خاصية الربط مع مدد..',
  last_updated_head: ': آخر تحديث في {{date}}',
  last_month_number_of_employees_in_the_payroll: 'عدد الموظفين في كشوف المرتبات الشهر الماضي',
  last_month_total_amount_of_payroll: 'المبلغ الإجمالي للرواتب الشهر الماضي',
  loans_deduction_amount: 'قيمة خصم السلفة:',
  loans_remaining_balance_before_deduction: 'القيمة المتبقية من السلفة قبل الخصم',
  learn_more_about_payroll: 'معرفة المزيد حول مسير الرواتب',
  leave_balance_amount: 'قيمة رصيد الإجازة',
  less: 'أقل',

  // M
  missing_punch_retro_amount: 'إجمالي قيمة خصم السجل الغير مكتمل',
  manual_addition_amount: 'قيمة الإضافة اليدوية',
  manual_addition_count: 'عدد الاضافات اليدويه',
  mark_as_paid: 'إتمام الدفع',
  mark_transaction_as_paid: 'إتمام الدفع للمعاملة المالية',
  missing_punch_rate: 'معدل خصم السجل الغير مكتمل لكل مرة',
  missing_punch_base: 'أساس احتساب خصم السجل الغير مكتمل',
  missing_punch_retro_rate: 'معدل خصم السجل الغير مكتمل لكل مرة - أثر رجعي',
  missing_punch_retro_base: 'أساس احتساب خصم السجل الغير مكتمل- أثر رجعي',
  missing_punch_deduction_rate: 'معدل خصم نسيان البصمة لكل مرة',
  missing_punch_deduction_base: 'أساس احتساب خصم السجل الغير مكتمل',
  more_optional_information: ' {{ num }} مزيد من المعلومات',
  maximum_deduction_exceeded:
    'تجاوز الراتب الحد الأقصى وهو 45000 ريال سعودي ، سيتم احتساب مبلغ الخصم على أساس 45000 ريال سعودي',
  match_employee_information: 'طابق معلومات الموظفين مع التأمينات الإجتماعية',
  match_employee_information_description:
    'قم برفع ملف الأجور من التأمينات الإجتماعية ليتمكن {{appName}} من التحقق من اشتراكات الموظفين وفروقات الرواتب لتجنب مشاكل الامتثال بنظام التأمينات الاجتماعية وحماية الأجور',
  more: 'أكثر',
  make_sure_using_supported_template: 'يرجى التأكد من رفع ملف القالب المدعوم',
  // N
  national_id_iqama_number: 'رقم الهوية/الإقامة',
  id_iqama: 'الهوية/الإقامة',
  number_of_employees: 'عدد الموظفين',
  ncb_misk: 'البنك الاهلي للجمعيات الخيرية',
  albilad: 'بنك البلاد',
  news: 'جديد',
  number_employees: '{{number}} موظف',
  new_joined_employees: 'موظفين تم التحاقهم خلال فترة مسير الرواتب',
  new_joined_employees_subtitle: 'الموظفين الملتحقين خلال هذا الشهر وتاريخ التحاقهم',
  newly_added_employees_title: '{{num}}  منهم جدد',
  not_sure_about_format: 'لست متأكدا من تنسيق الملف ؟',
  number_of_people: 'عدد الأشخاص',
  number_of_days_deducted: 'عدد أيام الخصم',
  new_full_package: 'قيمة الراتب الجديد',
  non_wps_employees: 'غير مسجلين في التأمينات',
  no_Lets_review_again: 'لا. دعنا نراجع كشف الراتب مرة أخرى',
  no_Do_it_later: 'لا. القيام بذلك لاحقاً',
  notifications_tasks: 'Notifications & TasksAR',
  new_transaction: 'معاملة جديدة',
  no_of_days_gosi: 'عدد أيام العمل',
  net_total: 'الإجمالي',
  not_required: 'غير مطلوب',
  no_final_settlements_requested: 'لا يوجد مخالصات نهائية حتى الآن',
  new_collaborator: 'إضافة مسئول آخر',
  // O
  or: 'او',
  on_annual_leave: 'في الإجازة السنوية',
  on_leave_this_month: 'موظفين لم يأكدوا عودتهم من الإجازة بعد',
  on_vacation_this_month: 'موظفين تم انشاء مخالصة إجازة لهم خلال فترة الرواتب',
  off_boarded_this_month: 'موظفين تم انهاء خدمتهم خلال فترة الرواتب',
  no_remarks_have_been_add: 'لا يوجد ملاحظات تم إضافتها لهذا الموظف',
  on_leave_this_month_description: 'الموظفين المعفيين من الحضور ولم يتم تأكيد رجوعهم من الإجازة',
  overtime_absence_automated:
    'هنا سترى الإضافات والخصومات التلقائية (التي تم تفعيلها في الاعدادات). كما يمكنك إضافة الإضافات والخصومات يدويا',
  overtime_hour_rate_single: ' قيمة ساعة الاوفرتايم بمعدل',
  overtime_hour_rate_double: 'قيمة ساعة الاوفرتايم المضاعف بمعدل',
  overtime_hour_single: ' قيمة ساعة الاوفرتايم بمعدل فردي',
  overtime_hour_retro_single: ' قيمة ساعة الاوفرتايم بمعدل فردي - أثر رجعي',
  overtime_hour_retro_double: 'قيمة ساعة الاوفرتايم بمعدل مضاعف - أثر رجعي',
  overtime_base: 'أساس احتساب ساعة الوقت الاضافي',
  overtime_base_retro: 'أساس احتساب ساعة الوقت الاضافي - أثر رجعي',
  open: 'مفتوحة',
  operations_cut_off_period: 'فترة المتغيرات',
  operations_cut_off_period_tip:
    'فترة الاستقطاع هي فترة احتساب المعاملات والمتغيرات المالية لمسير الرواتب ويتأثر بها توقيت معاملات الأثر الرجعي مابين الشهور مثل الغياب والإجازات.',
  overdue_payroll: 'مسير رواتب متأخر',
  overdue_payroll_mse:
    'عند تخطّي مسير رواتب سابق فلن تظهر بياناته في التقارير. ابدأ بمعالجته لحفظه في التقارير.',
  operation_cut_off_period: 'فترة المتغيرات',
  organizations: 'المنشآت',
  out_of_steps: '{{step}} من {{length}} خطوات مكتملة',
  overtime_calculation: 'احتساب العمل الإضافي',
  other_addition_types: 'إضافات أخرى',
  outsourcing_costs: 'رسوم العمالة المستأجرة',
  of_current_month: '{{number}} من الشهر الحالي',
  of_next_month: '{{number}} من الشهر التالي',
  // P
  payroll_is_sent_for_approval: 'مسيّر الرواتب أرسل للإعتماد',
  payroll_is_confirmed: 'تم إعتماد مسير الرواتب',
  payrunPeriodLabel: '{{first_date}} الى {{sec_date}}',
  payroll_summary_landpage: 'التفاصيل',
  payroll_summary_status: 'ملخص المسير',
  payment_status: 'حالة الدفع',
  paid: 'مدفوعة',
  paid_at: 'تاريخ الدفع',
  paid_on: 'دفع في {{date}}',
  payment_confirmation_for_employees: 'تاكيد اتمام الدفع لموظفين {{name}}',
  payment_confirmed_for_employees: 'سوف يتم اتمام الدفع لعدد {{number}} من الموظفين',
  payment_date: 'تاريخ الدفع',
  payment_types: 'طريقة الدفع',
  payment_methods: 'طريقة الدفع',
  payment_modes: 'طريقة الدفع',
  payroll: 'مسير الرواتب',
  PAYROLL: 'مسير الرواتب',
  pending: 'في الإنتظار',
  payroll_failure_gosi_note:
    'يمكن فقط رفع قالب ملف الأجور من التأمينات الإجتماعية، اذا مازلت تواجه  هذه المشكلة باستمرار، قم بتحويل صيغة الملف الى أكسل، او قم بتعبئة <thisTemplate>هذا الملف</thisTemplate> وإعادة رفعه.  <learnMore>معرفة المزيد</learnMore>',
  payroll_failure_file_note:
    'القالب والتنسيق الخاصين بنا هما الوحيدان المقبولان. إذا واجهتك هذه المشكلة مرة أخرى، جرب حفظ الملف بامتداد .xlsx وحاول مرة أخرى',
  play_video: 'شغل الفيديو',
  please_choose_bank_before_conformation: 'من فضلك إختر المصرف قبل إتمام مسير الرواتب',
  payroll_history: 'قائمة مسيرات الرواتب',
  Add_Payment_Methods_low_tries: 'أضف طرق الدفع للموظفين',
  Add_Payment_Methods_low_tries_des: 'حدد طريقة إستلام الراتب لكل موظف',
  payroll_manual_adjustment_addition: 'إضافات مسير الرواتب',
  payroll_manual_adjustment_deduction: 'خصومات مسير الرواتب',
  payroll_month: 'مسير الرواتب لشهر',
  payroll_months: 'مسير الرواتب لشهر',
  day_starting_from_date: '{{count}} أيام تبدا من <span>{{date_day_month_from}}</span>',
  payrun_date: '{{ date }} مسير الرواتب لشهر',
  payroll_attendance_popp_title: 'العناصر التالية هي المتغيرات الخاصة بالحضور والإنصراف',
  payroll_attendance_overtime: 'عمل إضافي',
  payroll_attendance_delay: 'تأخير',
  payroll_attendance_shortage: 'خروج مبكر',
  payroll_attendance_missing_punches: 'نسيان بصمة',
  payroll_attendance_absents: 'غياب',
  period: 'الفترة',
  payment_sent_successfully_note:
    'تم إرسال الرواتب بنجاح للبنك , وسيتم التحويل للموظفين في اليوم المحدد للصرف {{ date }}',
  payment_send_warning_note:
    'إذا كان تاريخ صرف الرواتب يوم العمل التالي فستكون رسوم التحويل 3.5 ريال / موظف, عدا ذلك فستكون الرسوم 3 ريال / موظف , علماً ان يوم العمل ينتهي الساعة الثانية ظهراً.',
  payment_send_warning_note_wallet:
    'إذا كان تاريخ صرف الرواتب يوم العمل الحالي فستكون رسوم التحويل 10 ريال / موظف, عدا ذلك فستكون الرسوم 3 ريال / موظف , علماً ان يوم العمل ينتهي الساعة الثانية ظهراً',
  pending_balance: 'المبلغ المتبقي',
  pay_period: 'فترة مسير الرواتب',
  previous_pay_period: 'فترة مسير الرواتب السابقة',
  previous_month_payroll_amount_sar: 'مبلغ مسير الرواتب السابق',
  processing_file_low_tiers: 'جارِ معالجة الملف...',
  processing_file_low_tiers_des: 'قد يستغرق رفع الملف بعض الوقت، وذلك اعتماداً على حجم الملف',
  payroll_summary: 'تقرير خلاصة مسير الرواتب',
  payroll_suspened_employees: 'الموظفين الموقوفين من مسير الرواتب',
  payroll_elements_distributions: 'تفصيل مكونات مسير الرواتب',
  payment: 'طريقة الدفع',
  payroll_duration: 'فترة مسير الرواتب الحالي',
  pending_requests: 'الطلبات المعلقة',
  payroll_confirming: 'يتم الآن تأكيد مسير الرواتب...',
  payroll_confirmed_successfully: 'تم تأكيد مسير الرواتب بنجاح',
  payroll_confirmation_message_1:
    'بمجرد تأكيد مسير الرواتب لن تكون هناك طريقة للتعديل عليه أو إعادة فتحة من جديد',
  payroll_confirmation_message_2: 'تفاصيل رواتب الموظفين سيتم ارسالها مباشرة الى بريدهم الإلكتروني',
  payroll_confirmation_message_3: 'سيتم ارسال تفاصيل تحويل الرواتب للبنك الى بريدك الالكتروني',
  payroll_confirmation_message_4: 'سيتم إسناد مهمة دفع مسير الرواتب للموظفين',
  prev: 'السابق',
  print_report: 'طباعة',
  proceed_anyways: ' المتابعة على أية حال',
  proceed_anyways_discprition:
    'المتغيرات الخاصة بسجل الحضور والإنصراف سوف يتم تجاهلها.  سيتحصل الموظفون فقط على الراتب الأساسي والبدلات والإضافات المالية الأخرى',
  payroll_period: 'فترة مسير الرواتب',
  please_update_the_salary: 'تحديث الراتب',
  payroll_card_header: 'مسير رواتب {{date}}',
  payroll_wizerd: 'مسير رواتب {{date}}',
  paid_to_employee: 'المدفوع للموظف',
  paid_to_provider: 'المدفوع للشركة المشغلة',
  paid_to_provider_low_tiers: 'المدفوع للشركة المؤجرة',
  pending_request_payroll_note:
    'هذه الطلبات لها تأثير مباشر على مسير الرواتب. فضلاً قم باتخاذ اجراء لكل طلب قبل تأكيد مسير الرواتب.',
  prevuous_full_package: 'قيمة الراتب السابق',
  please_proceed: 'متابعة',
  payroll_timeline: 'التفصيل الزمني',
  payroll_timeline_message_tip: 'التحديثات الزمنية التي حصلت أثناء فترة الرواتب',
  pdf_format: 'ملف PDF',
  previous_pay_puns: 'مسيرات الرواتب السابقة',
  payroll_pending_approval: 'مسير رواتب {{month}} في انتظار الاعتماد',
  pay_run_still_pending_approval: 'مسير رواتب <strong>{{month}}</strong>مازال بانتظار الاعتماد',
  pending_approval_payroll_skipped: 'لا يمكن تخطي المسير بعد ارساله للاعتماد',
  period_payment: 'فترة الدفع',
  possible_if_period_confirmed_after_this: 'يسمح به فقط إذا لم يتم تأكيد فترة بعد ذلك',
  possible_if_period_drft_after_this: 'مسموح بالتعديل فقط لفترة الرواتب المفتوح',
  payroll_approval: 'سلسلة المراجعة والإعتماد',
  payroll_approval_switch: 'حدد الموظفين المعنيين بمراجعة وإعتماد مسير الرواتب',
  loans_payment: 'سلف الموظفين',
  payroll_summary_low: 'التفاصيل',
  prepare_file_for_upload: 'قم بتعبئة المعلومات المطلوبة في الملف',
  prepare_file_for_upload_note: 'أضف المعلومات المطلوبة الى الملف',
  pay_via_app: 'ادفع رواتب موظفيك عن طريق {{app_name}} 💸',
  pay_via_app_desc:
    'قم بدفع رواتب موظفيك وحدث حالة الإلتزام من خلال نقرات فقط، وتخلص من متاعب التحويل اليدوي عبر منصة البنك والتحديث اليدوي لحالة الالتزام في حماية الأجور',
  payment_authriz_required: 'ليس لديك التفويض لمعالجة دفع مسير الرواتب',
  payment_authriz_required_note:
    'صلاحية دفع الرواتب مسندة للأشخاص {أسماء الموظفين}. إذا كنت تعتقد أن هناك مشكلة، يُرجى الاتصال بمسؤول النظام.',
  proceed_to_payment: 'الإنتقال للدفع',
  payroll_details: 'تفاصيل',
  payroll_schedule: 'تغيير فترة الرواتب',
  payroll_summary_total_package_desc: '{{percentage}}٪ {{comparison}} من رواتب شهر {{month}}',
  payroll_review_badge: '{{employee_name}}: استلم طلب المراجعة في {{date}}',
  payroll_review_badge_pending: '{{employee_name}}: لم يستلم طلب المراجعة',
  fetched_of_total_employees: 'تم عرض <span>{{fetched}}</span> من <span>{{total}}</span> موظف',
  payroll_approved_on: 'قام بتأكيد الطلب في {{date}}',
  payroll_rejected_on: 'قام برفض الطلب في {{date}}',
  payment_information: 'معلومات الدفع',
  filtered_by: 'تصفية',
  please_note_draft_transactions:
    'الرجاء الانتباه، لتطبيق هذا التغير سيتم ازاله المعاملات الموجوده حالياً بمسار الرواتب المبدئي',
  partially_uploaded: 'تم التحميل جزئياً',
  uploaded: 'تم التحميل ',

  // Q
  quick_actions: 'Quick Actions',
  // R
  run_payroll: 'احتساب مسير الرواتب',
  run_payroll_anyway: 'احتساب المسير على أية حال',
  request_from_date: 'من تاريخ',
  request_to_date: 'إلى تاريخ',
  requester_name: 'اسم مقدم الطلب',
  requester_type: 'نوع الطلب',
  requester_from_date: 'من تاريخ',
  requester_to_date: 'إلى تاريخ',
  reminder: ' دقائق {{time}} إرسال تذكير  في غضون',
  reminder_sent_successfully: 'جيد! لقد قمت بإرسال التذكير بنجاح',
  reminder_sent_successfully_discprition: 'التغييرات التي أجريتها على مسير الراتب محفوظة',
  run_new_payroll: 'إحتساب مسير الرواتب من جديد',
  retroed_requests: 'طلبات مرحلة للشهر المقبل',
  remarks: 'ملاحظات',
  riyadh_bank: 'بنك الرياض',
  reminder_time_minutes_seconds: 'د{{m}} ث {{s}}',
  reminder_time_hours_minutes: '{{h}} س {{m}} د',
  review_rage: 'صفحة المراجعة',
  view_list: 'عرض الموظفين',
  remind_attendance_manager: 'تذكير مدير الحضور والإنصراف',
  review_payroll_status: 'حالة مراجعة مسير الرواتب',
  review_payroll_request: 'طلب مراجعة مسير الرواتب',
  recently_added_employees: 'الموظفون المضافون حديثاً •  {{num}}',
  run_your_payroll: 'احتساب مسير الرواتب',
  reminder_sent: 'تم إرسال التذكير',
  run_payroll_note:
    'يرجى احتساب مسير الرواتب في {{day}} , {{date}} حتى تتمكن من دفع مستحقات الموظفين نظير جهودهم المبذوله , سوف يتمكن الموظفين من استلام تفاصيل رواتبهم في {{dayOfPayslip} , {{dateOfPayslip} .',
  review_payroll: 'مراجعة مسير الرواتب ({{date}})',
  rate: 'المعدل',
  re_open_payroll: 'أعد فتح مسير الرواتب',
  reschedule: 'إعادة جدولة',
  reschedule_payment: ' جدولة العملية لوقت لاحق',
  reference: 'مرجع',
  // reason: 'Reason ar',
  // remark: 'Remark ar',
  rejection_reason: 'سبب الرفض',
  return_date: 'تاريخ العودة',
  request_attendance_review: 'طلب مراجعة الحضور',
  rejected: 'مرفوض',
  regular_payroll: 'مسير الرواتب',
  required_pay: 'مطلوب',
  required_pay_lpw: 'مطلوبة',
  run_a_testing_payroll: 'إبدأ مسير الرواتب التجريبي',
  continue_testing_payroll: ' مواصلة مسير الرواتب التجريبي ',
  re_upload: 'إعادة الرفع',
  reprocess_payroll: 'إعادة معالجة المسير',
  run_testing_payroll: 'بدء المسير التجريبي',
  run_testing_payroll_alert:
    'عند بدء مسير الرواتب التجريبي فأنه سوف تفقد جميع الإضافات والخصومات التي تم إضافتها للمسير الحالي',
  re_upload_failed_entries: 'إعادة رفع الإدخالات المرفوضة',
  // S
  start_processing_payments: 'البدء بدفع الرواتب',
  salary_type: 'نوع الحساب',
  send_for_approval_cycle: 'إرسال إلى سلسلة الموافقات',
  send_reminder_to_attendance_manager: 'إرسال تذكير إلى مدير الحضور والانصراف',
  send_reminder_to_attendance_manager_discprition:
    'سوف يتم إرسال تذكير إلى مدير الحضور والإنصراف من أجل تأكيد سجلات الحضور والإنصراف للموظفين.لتغييرات التي أجريتها على مسير الرواتب ستكون محفوظة',
  suspend: 'ايقاف الراتب',
  successful_low_tires_upload: 'سجلات ناجحة',
  success_entries: 'إدخالات ناجحة',

  // suspend_list: 'قائمة الموقوفين',
  suspended_btn: 'موظفين موقوفين ({{number}}) ',
  set_payment_method_low_tries: 'حدد طريقة الدفع لكل موظف',
  upload_payment_method_low_tries:
    ' أضف حساب الرواتب الخاص بك واربطه بشكل مباشر مع مدد الرواتب أو مع البنك الأول لدفع مسير الرواتب مباشرة من {{app_name}} وتحديث حالة الالتزام بشكل تلقائي.',
  set_payment_method_low_tiers_des:
    '٣ خطوات تفصلك عن تبسيط وأتمتة عمليات مسير الرواتب، قم بإنهائها لتتمتع بتجربة مسير رواتب رائعة',
  suspended: 'موقوف',
  suspended_bang: 'تم ايقاف مسير الرواتب',
  suspended_month: 'عدد شهور الإيقاف',
  suspended_subtitle:
    'قائمة الموظفين الموقوفين من مسير الرواتب، قم بإلغاء ايقاف الراتب لإدراجهم في مسير الرواتب الحالي',
  summary: 'الملخص',
  suspended_employeess: 'الموظفين الموقوفين من مسير الرواتب • {{number}}',
  send_payslip: 'ارسال تفاصيل الراتب للموظفين',
  status: 'الحالة',
  last_uploaded_transactions: 'آخر المعاملات المرفوعة',
  uploading_transaction: 'جاري رفع المعاملة',
  payroll_name_already_exist: 'اسم الرواتب موجود بالفعل',
  STATUS: 'الحالة',
  size: 'الحجم',
  social_insurance: 'معلومات التأمينات الإجتماعية',
  social_insurance_subscription: 'إشتراك التأمينات الإجتماعية',
  social_insurance_payable: 'إجمالي مساهمة الموظف والمنشأة',
  sick_leave_payroll_tooltip: '{percentage}% خصمية عن {{days}} يوم',
  show_full_name: 'عرض الاسم كاملاً',
  show_department: 'عرض القسم',
  show_location: 'عرض الفرع',
  show_parent_department: 'عرض القطاع',
  show_area: 'عرض المنطقة',
  show_business_unit: 'عرض وحدة العمل',
  show_sponsor: 'عرض السجل التجاري',
  show_remarks: 'عرض الملاحظات',
  show_payment_type: 'عرض طريقة الدفع',
  show_bank: 'عرض البنك',
  show_iban: 'عرض رقم الآيبان',
  show_employment_type: 'عرض نوع التوظيف',
  show_work_email: 'عرض البريد الإلكتروني',
  sabb_bank: 'البنك السعودي البريطاني (ساب)',
  saudi_fransi_bank: 'البنك السعودي الفرنسي',
  suspension_reason: 'سبب الايقاف',
  sync: 'مزامنة',
  share_pdf: 'مشاركة كـ PDF',
  share_excel: 'مشاركة كـ EXCEL',
  suspend__month: 'شهر الإيقاف',
  summary_view: 'عرض الملخص',
  setting_page: ' صفحة الاعدادات ',
  shortage_deduction_base: 'معدل الخروج المبكر لكل ساعة',
  early_departure_addition_rate: 'معدل خصم الخروج المبكر لكل ساعة',
  early_departure_deduction_rate: 'أساس احتساب خصم الخروخ المبكر',
  early_departure_deduction_base_retro: 'معدل خصم الخروج المبكر لكل ساعة - أثر رجعي',
  early_departure_deduction_rate_retro: 'أساس احتساب خصم الخروخ المبكر - أثر رجعي',
  shortage_addition_base: 'أساس احتساب اضافة الخروج المبكر',
  salary_difference: 'فارق الراتب',
  salary_up_To_last_working_day: 'الراتب حتى آخر يوم عمل',
  show: 'عرض',
  skipping_loan_deduction:
    ' تخطي خصم السلفة, سوف يزيل الخصم من مسير الرواتب الحالي وإعادة جدولته لفترة مسير الرواتب اللاحق',
  start_payroll: 'بدء مسير الرواتب',
  send_reminder_pay: 'تذكير المسؤول',
  start_run: 'بدء مسير رواتب  {{month}}',
  skip_overdue_payroll: 'مسيرات رواتب لم تعتمد',
  skip_unclosed_periods_payroll:
    'تخطي مسيرات الرواتب السابقة والبدء بمسير رواتب  <strong>{{month}}</strong>؟',
  skip_unclosed_periods_payroll_skip:
    'تخطي مسيرات الرواتب السابقة وتخطي مسير رواتب  <strong>{{month}}</strong>؟',
  skip_this_payroll: 'تخطي مسير رواتب شهر  <strong>{{month}}</strong> ؟',
  skip_this_payroll_mse:
    'تخطي مسير الرواتب سيلغي جميع التعديلات، ولن يظهر المسير في صفحة تحويل الرواتب ولا التقارير.',
  unskip_this_payroll: ' إلغاء تخطي مسير رواتب شهر {{month}} ؟',
  unskip_this_payroll_mse:
    'سيتم تجاهل أي تعديلات تم إجراؤها على مسير الرواتب <strong>{{month}}</strong> ',
  skip_unclosed_periods: 'تخطي المسيرات السابقة',
  skip_payroll: 'تخطي مسير الرواتب',
  unskip_payrun_but: 'إلغاء تخطي مسير الرواتب',
  unskip_payroll: 'إلغاء تخطي مسير الرواتب',
  skip: 'تخطي',
  skip_by: 'بواسطة {{name}}',
  unskip_payrun: 'إلغاء التخطي',
  sync_with_jisr: 'جارِ المزامنة مع جسر',
  sync_with_jisr_note: 'قد يستغرق رفع الملف بعض الوقت، وذلك اعتماداً على حجم الملف',
  save_now_through_app:
    'ادفع عبر {{app_name}} ووفر ما يعادل <span>٢٥٪</span> من قيمة اشتراكك في جسر',
  stay_informed_compliant_Jisr: 'انقل نسبة إمتثال المنشأة الى مستوى أفضل مع {{app_name}}',
  stay_top_attendance_records:
    'كن على إطلاع دائم بسجلات الحضور. قم بتمكين تتبع الحضور لمراقبة نشاط موظفيك اليومي. سيقوم {{app_name}} تلقائيًا بإحتساب أي غياب، تأخير، الخروج المبكر، وساعات العمل الإضافية وعكسها على مسير الرواتب ',
  setup_manual_deductions: 'تخصيص أنواع الخصومات',
  share_payslips_with_employees: 'مشاركة قسيمة الراتب مع الموظفين',
  sync_issue_found_in_cr: 'تم العثور على {{number}} سجلات غير متطابقة في “{{CR_name}}”',
  gosi_bulk_import_title:
    'يقوم {{appName}} بالتحقق من مدى دقة معلومات الموظفين مقارنة بالمعلومات المتوفرة في التأمينات الاجتماعية. تساعدك هذه الخطوة على تجنب مشاكل عدم تطابق الرواتب والتأكد من تسجيل اشتراكات الموظفين والمزيد...(لن يتم مشاركة المعلومات مع التأمينات الإجتماعية)',
  select_the_starting_month: 'اختر الشهر الذي ستبدأ فيه الرواتب',
  start_your_first_payroll: 'إبدأ أول مسير رواتب',
  start_the_payroll_take: 'ابدأ معالجة اول مسير رواتب مع جسر. وتحكم في طريقة مدفوعاتك  بسهولة ودقة',
  send_to_review: 'إرسال للمراجعة والإعتماد',
  show_employee_profile: 'عرض ملف الموظف',
  save_and_close: 'حفظ وإغلاق',
  start_the_payoll: 'بدء مسير الرواتب',
  sand_confirm: 'تأكيد وحفظ',
  // T
  task_added: 'تم إضافة مهمة',
  ticket_info: 'معلومات التذكرة',
  time_related_transactions: ' المتغيرات الخاصة بسجل الحضور والانصراف',
  total_amount_of_payroll_sar: 'القيمة الإجمالية لمسير الرواتب',
  totalearnings: 'مجموع الراتب',
  table_view: 'عرض الجدول',
  this_month_salary: 'راتب هذا الشهر',
  total__earnings: 'مجموع الراتب',
  total_unpaid_leave_deduction: 'إجمالي خصم الإجازة غير المدفوعة',

  total_overtime_hours: 'اجمالي ساعات الاوفرتايم',
  total_overtime_amount: 'اجمالي قيمة الاوفرتايم',

  total_sick_leave_deduction: 'اجمالي قيمة مبلغ خصم الاجازة المرضية',
  total_sick_leave_days: 'إجمالي عدد أيام الإجازة المرضية المخصومة',
  total_unpaid_leave_days: 'إجمالي أيام الإجازة الغير مدفوعة',
  transaction_create_history_creator: '{{ name }} قام بإنشاء المعاملة المالية',
  total_settlement_deduction_days: 'إجمالي عدد أيام المخالصة المدفوعة مقدما',
  total_settlement_amount: 'اجمالي مبلغ الاجازة المدفوعة',
  total_missing_punch_retro_times: 'اجمالي عدد مرات الاثر الرجعي للسجل الغير مكتمل',
  total_unpaid_leave_retroactive_days: 'اجمالي عدد ايام الاثر الرجعي للاجازة الغير مدفوعة',
  total_amount: 'اجمالي المبلغ',
  total_excuse_amount: 'اجمالي مبلغ الإستئذان',
  total_retroactive_abcence_days: 'إجمالي عدد أيام الغياب',
  total_absence_deduction_days: 'إجمالي عدد أيام الغياب',
  total_excuse_hours: 'اجمالي ساعات الإستئذان',
  total_early_resumption_days: 'إجمالي عدد أيام المباشرة المبكرة للإجازة غير المدفوعة',
  total_absence_deduction: 'اجمالي خصم الغياب',
  total_early_resumption_amount: 'اجمالي مبلغ العودة من الاجازة مبكرا',
  total_delay_hours: 'اجمالي ساعات التأخير',
  total_delay_amount: 'اجمالي مبلغ التأخير',
  total_missing_punch_times: 'إجمالي مرات السجل الغير مكتمل',
  total_missing_punch_deduction_amount: 'اجمالي قيمة مبلغ خصم السجل الغير مكتمل',
  total_early_departure_hours: 'إجمالي عدد ساعات الخروج المبكر',
  total_shortage_amount: 'اجمالي قيمة خصم الخروج المبكر',
  total_hours: 'اجمالي الساعات',
  total_absence_days: 'إجمالي عدد أيام الغياب',
  total_net_salary: 'إجمالي صافي الراتب',
  total_amount_for: ': إجمالي الرواتب {{date}}',
  tool_tip_pay_type_in_payroll: 'يمكن استخدام نوع الدفع هذا داخل كشف الرواتب',
  tool_tip_pay_type_out_payroll: 'يمكن استخدام نوع الدفع هذا لدفع موظفيك خارج الرواتب',
  transactions_list: ' قائمة المعاملات المالية',
  tool_tip_transactions: 'تعرض هذه الشاشة كافة العمليات المالية التي تم إجراءها للموظف',
  tool_tip_paid_in_payroll: 'تم تعيين هذه المعاملة ليتم دفعها داخل كشف الرواتب الخاصة بك',
  tool_tip_paid_out_payrol: 'تم تعيين هذه المعاملة ليتم دفعها خارج كشف الرواتب',
  tool_tip_effective_date:
    'هو التاريخ الذي تعتبر فيه المعاملة مستحقة ، والذي قد يكون تاريخًا ماضيًا أو حاليًا أو في المستقبل',
  tool_tip_reschedule_date: 'إنه التاريخ الذي تعتبر فيه المعاملة مستحقة ، اختر تاريخًا في المستقبل',
  transaction_history: 'تفصيل زمني',
  edit_transaction: 'تعديل',
  select_specific_employees: 'اختيار موظفين محددين',
  cancel_transaction: 'الغاء المعاملة',
  transfer_fees: ' رسوم التحويل',
  type_of_transaction: ' نوع المعاملة',
  to: 'إلى',
  total_end_of_service: 'مكافأة نهاية الخدمة',
  total_leave_balance_settlement: 'تسوية رصيد الاجازة',
  total_loan_settlement: 'إجمالي تسوية السلف',
  total_working_days: 'إجمالي الراتب',
  till_us_the_reson: 'شاركنا بما يمكن تحسينه في مسيّر الرواتب',
  this_months_pay_run: 'مسير الرواتب للشهر الحالي',
  to_pay: 'نهاية الفترة',
  payroll_period_tip: 'فترة استحقاق الرواتب لكل شهر تحدد بمدة الشهر التقويمية.',
  ticket: 'مخصص التذاكر',
  try_testing_payroll:
    'قم بتجربة مسير الرواتب في {{app_name}} الآن لإستكشاف كيف يعمل دون التأثير على بياناتك الفعلية.',
  try_full_experience: 'تجربة المسير كاملاً',
  track_your_employees_attendance: 'تتبع حضور وانصراف الموظفين',
  to_ensure_correct_reflection: 'لضمان احتساب صحيح، يتطلب مراجعة سجل الحضور وتأكيده',
  show_the_ayslip:
    'عرض قسيمة الراتب للموظفين في تطبيق الخدمة الذاتية بعد تأكيد دفع الرواتب بعد عدد من الأيام',
  send_payslips: 'إرسال قسيمة الراتب الى البريد الإلكتروني الخاص بالموظف',
  track_and_integrate_employees: 'حضور وإنصراف الموظفين أصبح متزامن بشكل دقيق مع مسير الرواتب',
  testing_payroll: 'مسير تجريبي',
  testing_payroll_note:
    'يرجى ملاحظة أن هذا المسير لغرض التجربة وفهم كيفية العمل على مسير الرواتب في {{app_name}}. مع ملاحظة ان جميع القيم المحتسبة في هذا المسير بناءاً على المعلومات والتفاصيل المالية المتوفرة في ملف الموظف.',
  end_testing_payroll: 'إنهاء التجربة',
  track_employees_attendance: 'تتبع حضور وانصراف الموظفين',
  transactions_number: '{{count}} عمليات',

  // U
  upload_document: 'رفع المستند',
  upload_files: 'رفع الملفات',
  upload_template_file: '2- ارفع القالب للتحديث',
  upload_your_gosi: 'يرجى تحميل ( ملف التأمينات الإجتماعية)',
  unsuspend: 'إلغاء ايقاف الراتب',
  uploading: 'يتم الرفع',
  upload_gosi: 'رفع ملف التأمينات الإجتماعية',
  upload_gosi_file: 'رفع ملف التأمينات الإجتماعية',
  unpaid_leave_deduction_rate: 'معدل خصم الاجازة الغير مدفوعة',
  unpaid_leave_deduction_base: 'أساس احتساب اليوم الواحد للإجازة غير المدفوعة',
  unpaid_leave_deduction_rate_retro: 'معدل خصم الإجازة غير المدفوعة - أثر رجعي',
  unpaid_leave_deduction_base_retro: 'أساس خصم الإجازة غير المدفوعة - أثر رجعي ',
  update_remark: 'تعديل الملاحظة',
  update_from: 'Update from ar',
  upload_wages_for_cr: 'قم برفع ملف الأجور لكل سجل تجاري على حدة',
  upload_wages_for_cr_note: 'تأكد من رفع الملف كما هو دون إجراء أي تغييرات',
  upload_wages_file: 'رفع ملف الأجور',
  reupload_wages_file: 'إعادة رفع ملف الأجور',
  reupload_the_wage: 'إعادة رفع ملف الأجور',
  unlock_savings: 'وفر تكاليف رسوم التحويل وادفع رواتب موظفيك اونلاين عبر {{app_name}}',
  upload_the_file: 'رفع الملف',
  upload_file_we_will_do: 'قم برفع الملف، سوف نقوم بتحديث المعلومات وإبلاغك',
  upload_file_we_will_do_note: 'احفظ ملف القالب ثم ارفعه',
  unpaid_leaves: 'إستقطاع الإجازات الغير مدفوعة',
  upload_transactions: 'رفع إضافات/ خصومات',
  upload_bulk_transaction_title: 'رفع ملف الإضافات والخصومات',
  upload_bulk_transaction_subtitle:
    'استخدم هذا الملف لأدخال الاضافات والخصومات لمجموعة من الموظفين',

  // V
  vacation_starts_on: 'بداية الاجازة',
  very_hard: 'صعب جدا',
  very_easy: 'سهل جدا',
  view_payroll_details: ' تفاصيل',
  view_payroll_summary: 'ملخص ',
  view_requests: 'عرض الطلبات',
  view_details: 'عرض التفاصيل',
  vacation_settelement: 'تسوية اجازة {{name}}',
  vacation_settlement_list: 'مخالصات الإجازة',
  vacation_salary_calculation: 'Vacation Salary calculation Ar',
  vacation_settlement_review_request: 'طلب مراجعة وإعتماد مخالصة الإجازة',
  view_sheet: 'عرض كشف الحضور',
  view_payroll: 'عرض مسير رواتب  {{month}}',
  validation_message_pay: 'مدة الاستقطاع يجب ان تكون ٣٠/٣١ يوم بحد اقصى',
  view_employees_salaries: 'عرض الموظفين',
  view_these_employees: 'عرض الموظفين',

  // W
  will_apply_next_month: 'سيتم تطبيقه الشهر التالي',
  why_do_i_need_to_upload_gosi_file: ' لماذا نحتاج الى رفع ملف التأمينات الاجتماعية ؟',
  upload_new_gosi_file: 'رفع ملف تأمينات اجتماعية جديد',
  wage_type: 'نوع الاجر',
  wps_employees: 'مسجلين في التأمينات',
  WPS_employee_file: 'ملف البنك',
  waiting_exporting_payroll_file:
    'جاري تصدير الملف, يمكنك متابعة عملك سوف نقوم بإبلاغك حال الإكتمال 🙂',
  waiting_review: 'تحت المراجعة',
  were_happy_to_here_that:
    'يسعدنا أن التجربة كانت مرضية لك، شاركنا بما يمكن تحسينه في مسيّر الرواتب',
  wps_status: 'سيكون الملف جاهز للتحميل بعد معالجة الدفع من قبل البنك',
  view_more_in_payroll_reports: 'عرض المزيد في تقارير مسير الرواتب',
  welcome_jisr_payroll: 'أهلاً بك في مسير رواتب {{app_name}} 💸',
  watch_short_intro_video: 'فيديو تعريفي لكيفية احتساب ودفع مسير الرواتب في {{app_name}}',
  watch_video_get_started:
    'شاهد هذا الفيديو للبدء وتعلم كيفية إحتساب ودفع الرواتب على {{app_name}}',
  welcome_to_app: 'مرحبًا بك في نظام الرواتب المؤتمت من {{app_name}}',
  select_the_payroll_payment_day: 'إختر يوم الدفع للمسير',
  select_the_payroll_payment_day_note: 'يمكنك دوماً تغيير يوم الدفع لاحقاً',
  // X
  // Y
  you_can_revert_later_from_suspended_list: 'يمكنك استعادته لاحقا من قائمة الموقوفين',
  you_need_to_top_up: 'تحتاج إلى تعبئة  {{ number }} {{ currency }}',
  submit_payment_for_n_employees: 'تقديم الدفع لـ {{number}} موظفين',
  how_top_up: 'كيف يمكنني تعبئة الرصيد؟',
  fees_are_higher_in_same_fund_date: 'الرسوم أعلى في نفس تاريخ الصندوق',
  free_first_6_months: 'مجانًا - أول 6 أشهر',
  your_gosi_summary: 'ملخص مستندات التأمينات الإجتماعية',
  sab_payment_service: 'خدمة الدفع SAB',
  n_payment_service: 'خدمة الدفع عبر {{ name }} ',
  you_are_going_to_upload_GOSI_file_for_desc:
    'انت بصدد رفع ملف التأمينات للمنشأة يرجى التأكد من رفع الملف الصحيح',
  you_can_send_new_reminder_in: 'تم إرسال تذكير ، يمكنك إرسال تذكير آخر في غضون',
  your_payroll_was_rejected_by: 'تم رفض طلبك لمراجعة مسير الرواتب من قبل:',
  You_are_about_to_confirm: 'انت بصدد تأكيد مسير رواتب {{date}}. هل تريد التأكيد؟',
  You_ve_successfully_confirmed: 'تم تأكيد مسير رواتب {{date}} بنجاح',
  you_are_about_to_send_approval: 'أنت بصدد إرسال مسير رواتب {{date}} للإعتماد هل تريد التأكد؟',
  Yes_Lets_send_it: ' نعم. قم بالإرسال',
  you_dont_have_permission_approval_cycle:
    'ليس لديك الصلاحية لإنشاء سلسلة موافقات, يرجى التواصل مع المشرف',
  your_previous_listed_here: 'سيتم عرض مسيرات الرواتب السابقة هنا',
  youll_be_able_to_start_payroll:
    'لبدء مسير رواتب  {{next_month}}، يتطلب اعتماد أو رفض مسير  {{month}}.',
  you_dont_have_permission_collaborators: 'لا توجد لديك الصلاحية لأضافة مسئولين آخرين',
  you_dont_have_permission_manage_workflow: 'لا توجد لديك الصلاحية لإدارة سلسلة الموافقات',
  // Z

  // extra
  parent_departments_with_br: 'الإدارات',
  business_unit_with_br: 'وحدات  <br/> العمل ',
  payment_methods_with_br: 'طريقة  <br/> الدفع',
  payment_method: 'طريقة الدفع',
  pay_method: 'اسلوب الدفع',
  payments_bank_account: 'بالامكان الدفع مباشرة عبر {{app_name}} بهذا الحساب البنكي',
  REMARK: 'ملاحظات',
  approve_payroll_review_request: 'الموافقة على طلب مراجعة مسير الرواتب',
  reject_payroll_review_request: 'رفض طلب مراجعة مسير الرواتب',
  'outsourcing provider costs': 'التكلفة الاجمالية لموظفين اجير',
  'pre extra cols': 'معلومات الدفع',
  'extra columns': 'معلومات أُخرى',
  'employee details': 'معلومات الموظف',
  'International Transfer': 'حوالة دولية',
  Cheque: 'شيك',
  Bank: 'بنك',
  Cash: 'نقداً',
  // Status
  pending_approval: 'في انتظار الاعتماد',
  overdue: 'متأخر',
  skipped: 'متخطّى',
  must_include_a_letter: 'يجب أن يتضمن حرفًا واحدًا على الأقل',
  initiated: 'مفتوحة',
}

export default Payroll
