import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

const list = [
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
  {
    id: 'There are 2 employees joined after CUT OFF',
    name: 'Process them',
  },
]

const initialState = {
  payrun_tasks: list,
  fetching: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PAYRUN_TASKS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYRUN_TASKS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYRUN_TASKS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: true,
      }

    default:
      return state
  }
}
