import { ChangeEventHandler, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'utils/hooks'
import { SearchBar } from '@jisr-hr/design-system'
import { Table } from '@jisr-hr/ds-beta'
import { Spacer, Typography, Flex, Button } from '@jisr-hr/ds'
import StepTemplate from 'containers/public/Onboarding/components/StepTemplate'
import { TExtendsEmployee } from 'redux/organizationOnborading/employees/employees.type'
import { onboardingEmployeesWithEmailsLoad } from 'redux/leavesOnboarding/inviteEmployees/actionCreators'
import { organizationConfirmUpdate } from 'redux/organizationOnborading/organization/actionCreators'
import { employeesInviteLoad } from 'redux/organizationOnborading/invite/actionCreators'
import { onboardingValidateAdminsInvitationLoad } from 'redux/leavesOnboarding/validationAdminInvited/actionCreators'
import I18n from 'translations/i18n'
import { useUsersnapApi } from 'components/UserSnap/UsersnapContext'
import { SetupStepTypes } from 'containers/public/Onboarding/types/onboardingTypes'
import JisrLoader from 'components/global/JisrLoader'
import { useTrackingSteps } from 'containers/public/Onboarding/helper'

type RowDataType = { row: { original: TExtendsEmployee } }

type InviteEmployeesProps = {
  goPreviousStep: () => void
  previousStep: SetupStepTypes
}

const InviteEmployees = ({ goPreviousStep, previousStep }: InviteEmployeesProps): JSX.Element => {
  const dispatch = useDispatch()
  const { fetching } = useSelector(({ validationAdminInvited }) => validationAdminInvited)

  const {
    employees,
    pagination,
    fetching: fetchingEmployee,
  } = useSelector(({ inviteEmployees }) => inviteEmployees)

  const data = useSelector(({ organization }) => organization)
  const usersnapApi = useUsersnapApi()

  const [params, setParams] = useState({
    page: 1,
    rpp: 20,
    search: '',
  })

  const handleFiltering = (key: 'page' | 'rpp', val: number): void => {
    setParams((prevState) => ({ ...prevState, [key]: val }))
  }

  const validationInviting = (): void => {
    dispatch(onboardingValidateAdminsInvitationLoad()).then(() => {
      let url = window.location.href.toString()
      dispatch(organizationConfirmUpdate()).then(() => {
        url = url.replace('onboarding.', `${data?.organization?.slug}.`)
        url = url.replace('/#/onboarding', '/')
        window.location.href = url
      })
    })
  }

  useTrackingSteps({ step_name: 'invite_employees', level_name: 'invite_admins' })

  useEffect(() => {
    dispatch(onboardingEmployeesWithEmailsLoad(params))
  }, [params])

  useEffect(() => {
    if (usersnapApi) {
      usersnapApi?.logEvent('Onboarding_CSAT_Last')
    }
  }, [usersnapApi])

  const columns = [
    {
      id: 'emp_id',
      cell: (rowData: RowDataType) => {
        const row = rowData?.row?.original
        return (
          <Flex flexCol>
            <Typography
              variant="subtitle-2"
              text={row.full_name_i18n}
            />
            <Typography
              variant="subtitle-2"
              text={row.code}
            />{' '}
          </Flex>
        )
      },
      header: I18n.t('emp_id'),
    },
    { id: 'email', accessorKey: 'email', header: I18n.t('email') },
    {
      cell: (rowData: RowDataType) => {
        const row = rowData?.row?.original
        return (
          <Button
            label={I18n.t('invite')}
            disabled={row.is_invited}
            size="small"
            color="neutral"
            variant="outlined"
            style={{ width: 118, height: 32, justifyContent: 'center' }}
            onClick={(): void => {
              dispatch(employeesInviteLoad(row.id))
            }}
          />
        )
      },
      header: I18n.t('action'),
      width: 150,
      id: 'actions',
    },
  ]

  return (
    <StepTemplate
      goNextStep={validationInviting}
      goPreviousStep={goPreviousStep}
      nextStep={{ title: I18n.t('login') }}
      previousStep={previousStep}
      fetching={fetching || fetchingEmployee}
    >
      {(fetching || fetchingEmployee) && <JisrLoader absolute />}

      <Typography
        variant="heading"
        text={I18n.t('invite_admins')}
      />

      <Spacer height="8px" />
      <SearchBar
        placeholder={I18n.t('search_employees_by_id_or_name')}
        style={{ width: 340 }}
        onChange={(_: ChangeEventHandler<HTMLInputElement>, search: string): void =>
          setParams((prevState) => ({
            ...prevState,
            search: search.trim(),
          }))
        }
        value={params.search}
      />
      <Spacer height="24px" />
      <Table
        columns={columns}
        data={employees}
        tableSize={{ maxHeight: 445 }}
        pagination={{
          total_entries: pagination.total_entries,
          currentPage: params.page,
          per_page: params.rpp,
          onNext: (): void => {
            if (params.page < pagination.total_pages) handleFiltering('page', params.page + 1)
          },
          onJump: (val: number): void => handleFiltering('page', val),
          onPrev: (): void => {
            if (params.page > 0) handleFiltering('page', params.page - 1)
          },
        }}
      />
    </StepTemplate>
  )
}

export default InviteEmployees
