import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

export type TAllowances = {
  enabled: boolean
  id: number
  is_optional: boolean
  name: string
  name_ar: string
  name_i18n: string
}

export type TAllowancesState = {
  fetching: boolean
  loading: boolean
  allowances: TAllowances[]
  status: boolean
}

const initialState: TAllowancesState = {
  fetching: false,
  loading: false,
  allowances: [],
  status: false,
}

export default function reducer(state = initialState, action: Action): TAllowancesState {
  switch (action.type) {
    case actions.ALLOWANCES_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }

    case actions.ALLOWANCES_DELETE_PENDING:
    case actions.ALLOWANCES_IMPORT_PENDING:
    case actions.ALLOWANCES_UPDATE_PENDING:
    case actions.ALLOWANCES_CREATE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.ALLOWANCES_LOAD_FULFILLED: {
      const allowances = action.payload.data?.data?.payroll_transaction_types || []
      return {
        ...state,
        allowances: allowances?.sort(
          (item1: TAllowances, item2: TAllowances) => item1?.id - item2?.id,
        ),
        fetching: false,
      }
    }
    case actions.ALLOWANCES_IMPORT_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        loading: false,
      }
    }
    case actions.ALLOWANCES_CREATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        allowances: action.payload.data?.data?.payroll_transaction_types,
        loading: false,
      }
    }

    case actions.ALLOWANCES_UPDATE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        allowances: action.payload.data?.data?.payroll_transaction_types,
        loading: false,
      }
    }
    case actions.ALLOWANCES_DELETE_FULFILLED: {
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        allowances: action.payload.data?.data?.payroll_transaction_types,
        loading: false,
      }
    }
    case actions.ALLOWANCES_IMPORT_REJECTED:
    case actions.ALLOWANCES_DELETE_REJECTED:
    case actions.ALLOWANCES_UPDATE_REJECTED:
    case actions.ALLOWANCES_CREATE_REJECTED:
    case actions.ALLOWANCES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading: false,
        status: true,
      }
    }

    default:
      return state
  }
}
