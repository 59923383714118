import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function missingPunchRequestCreate(employee_id, params = {}) {
  const url = `/${API_VERSION_TWO}/attendance/employees/${employee_id}/missing_punch_requests`
  const method = 'POST'

  return {
    type: 'MISSING_PUNCH_REQUEST',
    payload: handleAPI(url, {}, method, { missing_punch_request: params }),
  }
}

export function missingPunchRequestUpdate(employee_id, id, params = {}) {
  const url = `/${API_VERSION_TWO}/attendance/employees/${employee_id}/missing_punch_requests/${id}`
  const method = 'PUT'

  return {
    type: 'MISSING_PUNCH_REQUEST_UPDATE',
    payload: handleAPI(url, {}, method, { missing_punch_request: params }),
  }
}

export function missingPunchRequestDateLoad(employee_id, params = {}) {
  const url = `/${API_VERSION_TWO}/employees/${employee_id}/missing_punch_requests/new`
  const method = 'GET'

  return {
    type: 'MISSING_PUNCH_REQUEST_DATE_LOAD',
    payload: handleAPI(url, params, method),
  }
}
