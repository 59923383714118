import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  account_manager_id: null,
  subscription_notice: null,
  loading: false,
  submitting: false,
  account_manager: {},
  cancellation_info: {},
  renewal_info: {},
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    // billing manger and base api
    case actions.BILLING_NEW_OR_CANCEL_REQUEST_LOAD_PENDING:
    case actions.BILLING_MANGER_INFO_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.BILLING_NOTICES_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
      }

    case actions.BILLING_MANGER_INFO_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        loading: false,
      }

    case actions.BILLING_MANGER_INFO_LOAD_REJECTED:
      return {
        ...state,
        loading: false,
      }
    // posting
    case actions.BILLING_NEW_OR_CANCEL_REQUEST_POST_PENDING:
      return {
        ...state,
        submitting: true,
      }

    case actions.BILLING_NEW_OR_CANCEL_REQUEST_POST_FULFILLED:
      Toastr.success(payload.data.message)
      return {
        ...state,
        submitting: false,
      }

    case actions.BILLING_NEW_OR_CANCEL_REQUEST_POST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        submitting: false,
      }

    // new or cancel request
    case actions.BILLING_NEW_OR_CANCEL_REQUEST_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        loading: false,
      }

    case actions.BILLING_NEW_OR_CANCEL_REQUEST_LOAD_REJECTED:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
