import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

type StateType = {
  fetching: boolean
  payrun_events: {
    created_at: Date
    created_by: {
      avatar_thumb: string
      code: string
      full_name_i18n: string
      id: string
      is_exempted: boolean
      job_title: string
      name: string
      name_i18n: string
    }
    description: string
  }[]
}

const initialState: StateType = {
  payrun_events: [],
  fetching: false,
}

export default function reducer(state = initialState, action: Action): StateType {
  switch (action.type) {
    case actions.PAYRUN_EVENTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYRUN_EVENTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: true,
      }

    case actions.PAYRUN_EVENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: true,
      }

    default:
      return state
  }
}
