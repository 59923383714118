import ReactDOM from 'react-dom'

type BlockScrollType = {
  open: boolean
  className?: string
  highlightPosition?: {
    top: string
    left: string
    width: string
    height: string
    borderRadius?: string
  }
}

const BlockScroll = ({ open, className, highlightPosition }: BlockScrollType): JSX.Element => {
  const borderRadius = highlightPosition?.borderRadius || '10px'
  const shadowColor = 'rgba(0,0,0, 0.5)'

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <div className={`fixed inset-0 z-10 ${highlightPosition ? '' : className}`}>
            {highlightPosition && (
              <div
                className="absolute bg-transparent pointer-events-none"
                style={{
                  top: highlightPosition.top,
                  left: highlightPosition.left,
                  width: highlightPosition.width,
                  height: highlightPosition.height,
                  borderRadius,
                  boxShadow: `0 0 0 9999px ${shadowColor}`,
                }}
              />
            )}
          </div>,
          document.body,
        )}
    </>
  )
}

export default BlockScroll
