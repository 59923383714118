import React, { useState } from 'react'
import I18n from 'translations/i18n'
import { useDispatch, useSelector } from 'react-redux'

import { NewSelectField } from 'components/final-form'
import { required } from 'components/global/form/FormValidations'
import { loadEmployeeTeamMembers } from 'redux/employees/subordinates/actionCreators'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'
import useIsEmployeeOrg from 'hooks/useIsEmployeeOrg'

const RequestFor = () => {
  const dispatch = useDispatch()
  const {
    auth: { employee },
    subordinates: { team_members: teamMembers },
  } = useSelector(({ auth, subordinates }) => ({
    auth,
    subordinates,
  }))

  const [options, setOptions] = useState([])

  const isCurrentEmpOrg = useIsEmployeeOrg()

  const selfOption = React.useMemo(
    () => ({ ...employee, name: `${employee?.name_i18n} (${I18n.t('you')})` }),
    [employee.name_i18n],
  )

  React.useEffect(() => {
    if (!teamMembers.length) {
      dispatch(loadEmployeeTeamMembers(employee.id, { rpp: 100000 }))
    }
  }, [])

  React.useEffect(() => {
    setOptions([isCurrentEmpOrg && selfOption, ...teamMembers].filter(Boolean))
  }, [selfOption, teamMembers.length])

  return (
    <Frame title={I18n.t('label.submit_for')}>
      <NewSelectField
        name="employee_id"
        placeholder={I18n.t('select_employee')}
        options={options.map((option) => ({
          label: option?.name,
          value: option?.id,
          subLabel: option?.job_title_i18n,
          avatarSrc: option?.avatar_thumb,
        }))}
        maxMenuHeight={256}
        validate={required}
        testId="select-employee"
      />
    </Frame>
  )
}

export default RequestFor
