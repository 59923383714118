import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  attendance_records: [],
  totals: {},
  fetching: false,
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EMPLOYEE_ATTENDANCES_OVERVIEW_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EMPLOYEE_ATTENDANCES_OVERVIEW_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
        attendance_records: [],
      }

    case actions.EMPLOYEE_ATTENDANCES_OVERVIEW_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
