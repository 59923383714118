import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { HTTPValidationError } from 'types/httpTypes'
import { api, API_VERSION_TWO } from 'apis/axiosInstance'
import { Toastr } from 'components/global/Toastr'

import { queryClient } from 'hooks/useReactQuery'

import {
  AttendanceReminderAxiosResponseT,
  MarkAttendanceReminderType,
  AttendanceRemindersStatsT,
} from './useAttendanceReminder.type'

const loadReminderStateQueryKey = ['attendance_reminders']

const initialReminderState: AttendanceRemindersStatsT = {
  missing_punch_request_count: 0,
  attendance_reminder_banner_viewed: true,
  attendance_reminder_modal_viewed: true,
  attendance_reminders_enabled: false,
  attendance_reminders_enabled_by_default: false,
  violation_reminder_modal_viewed: true,
  violation_learn_more_reminder_banner_viewed: true,
  timesheet_reminder_modal_viewed: true,
  paygroup_settings_reminder_banner_viewed: true,
  redesign_reporting_methods_modal_viewed: true,
}

const loadRemindersState = async (): Promise<AttendanceReminderAxiosResponseT['data']> => {
  const res: AttendanceReminderAxiosResponseT = await api({
    method: 'GET',
    url: `/${API_VERSION_TWO}/attendance/reminders/stats`,
  })

  return {
    ...res.data,
    data: {
      ...initialReminderState,
      ...res.data.data,
    },
  }
}

const markRemindersAsView = (
  data: MarkAttendanceReminderType,
): Promise<AttendanceReminderAxiosResponseT> => {
  return api({
    method: 'PUT',
    data,
    url: `/${API_VERSION_TWO}/attendance/reminders/mark_as_viewed`,
  })
}

export const useLoadRemindersState = <
  TData = AttendanceReminderAxiosResponseT['data'],
  TError = HTTPValidationError,
>(opts?: {
  query?: UseQueryOptions<AttendanceReminderAxiosResponseT['data'], TError, TData>
}): UseQueryResult<TData, TError> => {
  return useQuery({
    ...(opts?.query || {}),
    initialData: {
      data: initialReminderState,
      message: '',
      success: true,
    },
    queryKey: opts?.query?.queryKey ?? loadReminderStateQueryKey,
    queryFn: () => loadRemindersState(),
  })
}

export const useMarkRemindersAsView = <TError extends HTTPValidationError>(opts?: {
  mutation?: UseMutationOptions<
    AttendanceReminderAxiosResponseT,
    TError,
    MarkAttendanceReminderType
  >
}): UseMutationResult<AttendanceReminderAxiosResponseT, TError, MarkAttendanceReminderType> => {
  return useMutation({
    ...(opts?.mutation || {}),
    mutationFn: markRemindersAsView,
    onSuccess: (res, variables, context) => {
      opts?.mutation?.onSuccess?.(res, variables, context)
      Toastr.success(res.data.message)
      queryClient.invalidateQueries(loadReminderStateQueryKey)
      queryClient.fetchQuery(loadReminderStateQueryKey)
    },
    onError: (error, variables, context) => {
      Toastr.error(error.response?.data?.error)
      opts?.mutation?.onError?.(error, variables, context)
    },
  })
}
