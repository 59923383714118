import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  fetchingConstants: false,
  header: {},
  records: [],
  constants: {
    fields: {},
  },
  extra_col_labels: [],
  filters: {
    business_unit_id: [],
    location_id: [],
    department_id: [],
    employment_type_id: [],
    job_title_id: [],
    grade_id: [],
    gosi_subscriptions: [],
    status: [1],
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_MASTER_REPORT_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      }
    }
    case actions.EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_PENDING: {
      return {
        ...state,
        fetchingConstants: true,
      }
    }

    case actions.EMPLOYEE_MASTER_REPORT_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    }

    case actions.EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.data,
        fetchingConstants: false,
      }
    }
    case actions.EMPLOYEE_MASTER_REPORT_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.EMPLOYEE_MASTER_REPORT_CONSTANTS_LOAD_REJECTED: {
      return {
        ...state,
        fetchingConstants: false,
      }
    }

    case actions.SET_FILTERS_EMPLOYEE_MASTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }
    }

    default:
      return state
  }
}
