import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import { useSelector } from 'react-redux'
import Frame from '../../ds/Frame'
import InfoList from '../../ds/InfoList'
import { requestedList } from './helper'

const RequestedOvertime = ({ exclude, details, overtimeDays, reloadRequest }) => {
  const { currency_type_i18n } = useSelector(
    ({ organizationProfile }) => organizationProfile.organization_currency,
  )

  const items = requestedList(
    {
      ...details,
      currency: currency_type_i18n,
    },
    overtimeDays,
    reloadRequest,
  )?.filter(({ key }) => !exclude?.includes(key))
  return (
    <Frame title={I18n.t('requested_overtime')}>
      <InfoList items={items} />
    </Frame>
  )
}

RequestedOvertime.propTypes = {
  details: PropTypes.shape(),
  exclude: PropTypes.arrayOf(PropTypes.string),
  overtimeDays: PropTypes.arrayOf(Object),
  reloadRequest: PropTypes.func,
}

export default RequestedOvertime
