import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { Toastr } from 'components/global/Toastr'
import { SubsidiaryType } from 'types/Accounts/subsidiary'
import * as actions from './actions'

export type ZohoBooksCompany = {
  internal_id: string
  name: string
}

type InitStateType = {
  errMsg: string
  fetching: boolean
  mapped_subsidiaries: SubsidiaryType[]
  zoho_books_subsidiaries: ZohoBooksCompany[]
}

const initialState: InitStateType = {
  zoho_books_subsidiaries: [],
  mapped_subsidiaries: [],
  fetching: false,
  errMsg: '',
}

export default function reducer(state = initialState, action: Action): InitStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_PENDING:
    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_PENDING:
    case actions.ZOHO_BOOKS_SUBSIDIARY_DELETE_PENDING:
    case actions.ZOHO_BOOKS_SUBSIDIARY_UPDATE_PENDING:
    case actions.ZOHO_BOOKS_MAPPED_SUBSIDIARIES_PENDING:
    case actions.ZOHO_BOOKS_SUBSIDIARY_CREATE_PENDING:
    case actions.ZOHO_BOOKS_SUBSIDIARIES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ZOHO_BOOKS_SUBSIDIARIES_LOAD_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      const list = payload.data.data as ZohoBooksCompany[]
      return {
        ...state,
        zoho_books_subsidiaries: list,
        fetching: false,
      }
    }
    case actions.ZOHO_BOOKS_MAPPED_SUBSIDIARIES_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        mapped_subsidiaries: payload.data.data.subsidiaries,
        fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_SUBSIDIARY_CREATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_SUBSIDIARY_DELETE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.ZOHO_BOOKS_SUBSIDIARY_UPDATE_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_FULFILLED:
    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_FULFILLED: {
      if (payload.data.message) Toastr.success(payload.data.message)
      return {
        ...state,
        fetching: false,
      }
    }
    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_UNASSIGN_REJECTED:
    case actions.ZOHO_BOOKS_SUBSIDIARY_EMPLOYEES_ASSIGN_REJECTED:
    case actions.ZOHO_BOOKS_SUBSIDIARY_UPDATE_REJECTED:
    case actions.ZOHO_BOOKS_SUBSIDIARY_DELETE_REJECTED:
    case actions.ZOHO_BOOKS_SUBSIDIARY_CREATE_REJECTED:
    case actions.ZOHO_BOOKS_MAPPED_SUBSIDIARIES_REJECTED:
    case actions.ZOHO_BOOKS_SUBSIDIARIES_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
    }
    default:
      return state
  }
}
