import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { SimpleObject } from 'types/sharedComponents'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'
import FilterBox from '../FilterBox'
import { getTranslatedList } from '../helper'

type RequestTypesProps = {
  filterKey: string
  filters: Array<string>
}

const RequestTypes = (props: RequestTypesProps): JSX.Element => {
  const { company } = useSelector(({ auth }) => auth)
  const { request_type, tasks_request_type, request_type_list, archived_request_type_list } =
    useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const isSupportCustomRequests = company?.subscribed_features?.includes('custom_requests')
  const isTasks = props.filters.includes('tasks_request_type')
  const withPendingOn = props.filters.includes('pending_on')

  let minH = withPendingOn ? '34vh' : '48vh'
  let maxSpace = '533px'
  if (isTasks) {
    maxSpace = '700px'
    minH = '38vh'
  } else if (!isTasks && !withPendingOn) {
    maxSpace = '500px'
  }

  let optionList = []
  if (isTasks) {
    optionList = getTranslatedList(tasks_request_type, 'name')
  } else {
    optionList = getTranslatedList(request_type, 'name')
  }

  if (isSupportCustomRequests && !isTasks) {
    const resList = [...request_type_list, ...archived_request_type_list].map((r) => ({
      ...r,
      group: 'custom_requests',
      testId: `cusom_request_${r.id}`,
    }))
    optionList = [...optionList, ...resList]
  }

  if (isTasks) {
    optionList = optionList.map((o: SimpleObject) =>
      Number(o.id)
        ? {
            ...o,
            group: 'custom_tasks',
            testId: `custom_task_${o.id}`,
          }
        : o,
    )
  }

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      valueKey="id"
      labelKey="name_i18n"
      groupKey="group"
      selectedIds={filter[props.filterKey]}
      componentType="grouped"
      listBoxStyle={{
        maxHeight: `calc(100vh - ${maxSpace})`,
        minHeight: minH,
      }}
      {...props}
      title={i18n.t(isTasks ? 'task_type' : 'request_type')}
    />
  )
}

export default RequestTypes
