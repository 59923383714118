import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'
import { LoanType } from 'types/payrunTransactions'

type InitStateType = {
  loan_types: LoanType[]
  errMsg: string | null
  succMsg: string | null
  fetching: boolean
  loading: boolean
  error?: string | null
}

const DEFAULT_STATE: InitStateType = {
  loan_types: [],
  errMsg: null,
  succMsg: null,
  fetching: false,
  loading: false,
  error: null,
}

let changes = null
export default function loanTypeReducer(state = DEFAULT_STATE, action: Action): InitStateType {
  const { payload } = action
  let loanTypes = []
  let newRecord: LoanType | null
  let updatedRecord: LoanType | null
  switch (action.type) {
    case 'LOAN_TYPES_LOAD_PENDING':
    case 'LOAN_TYPE_CREATE_PENDING':
    case 'LOAN_TYPE_UPDATE_PENDING':
    case 'LOAN_TYPE_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
        loading: !(state.loan_types.length > 0),
      }

    case 'LOAN_TYPES_LOAD_REJECTED':
    case 'LOAN_TYPE_CREATE_REJECTED':
    case 'LOAN_TYPE_UPDATE_REJECTED':
    case 'LOAN_TYPE_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LOAN_TYPES_LOAD_FULFILLED':
      changes = {
        loan_types: payload?.data?.data?.payroll_transaction_types,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
        loading: false,
      }
      return { ...state, ...changes }

    case 'LOAN_TYPE_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loanTypes = [...state.loan_types]
      newRecord = payload.data.data.payroll_transaction_type
      changes = {
        loan_types: newRecord ? [...loanTypes, newRecord] : loanTypes,
        loading: false,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'LOAN_TYPE_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loanTypes = [...state.loan_types]
      updatedRecord = payload.data.data.payroll_transaction_type
      changes = {
        loading: false,
        fetching: false,
        loan_types: updateOneListOfRecords(loanTypes, updatedRecord),
      }
      return { ...state, ...changes }

    case 'LOAN_TYPE_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      loanTypes = [...state.loan_types]
      return {
        ...state,
        loading: false,
        fetching: false,
        loan_types: removeOneListOfRecords(loanTypes, payload.id),
        error: null,
      }

    case 'LOAN_TYPE_EDIT':
      changes = {
        loading: false,
        fetching: false,
      }
      return { ...state, ...changes }
    case 'LOAN_TYPE_EDIT_CANCEL':
      changes = {
        loading: false,
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
