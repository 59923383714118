const Auth = {
  // A
  automate_your_hr_operations_and_focus_on_your_business:
    'Automate your HR operations and focus on <br /> your business',
  attendance: 'Attendance',
  activate_modules: 'Activate {{name}} Modules',
  activate_the_features: "Activate the features you'd like to enable in your {{name}} experience",
  // B
  back_to_login: 'Back to login',
  brand_name_en_new_onboarding: 'Brand name (English)',
  brand_name_ar_new_onboarding: 'Brand name (Arabic)',
  building_workspace: 'Building {{orgName}} workspace',
  // C
  continue_new_onboarding: 'Continue',
  code_valid_until: 'Your current <b>code</b> is valid for only <b>{{time}}</b> minutes.',
  confirm_password: 'Confirm Password',
  continue_slug: 'Continue',
  current_password: 'Current Password',
  password_complexity_note:
    'It should have at least <b>one number</b>, <b>one uppercase letter</b>, and <b>one symbol</b>. <br /><br /> Password can’t contain <b>user’s first name</b>, <b>last name</b>, or <b>email address</b>.',
  minimum_password_length_note: 'Password must be 8 characters at least.',
  create_your_account: 'Create your account',
  title_create_workspace: 'Create workspace',
  create_workspace: 'Create workspace',
  choose_organization_workspace_name: 'Choose organization workspace name',
  organization_workspace_name: 'Organization workspace name',
  create_profile: 'Create Profile',
  complete_company_profile: 'Complete company profile',
  create_workspace_with_slug: 'Create {{slug}} workspace',
  completing_first_step:
    'Congratulations on completing your first step toward turning your business to be more digitalized. Now you are ready to complete your company data to start using {{name}} solutions',
  ceo_new_onboarding: "Mohammed Akkar, {{name}}'s CEO",
  // D
  didn_recieve_an_email: 'Didn’t recieve an email?',
  // E
  enter_six_digit_code: 'Enter six digit code',
  email: 'Email',
  ex_name: 'Ex: Ahmed Mohamed Ali',
  error_message_new_onboarding: 'File size must be under or 10MB',
  enter_work_email_address: 'Enter email address',
  // F
  forgot_password: 'Forgot password?',
  // G
  google: 'Google',
  get_started_new_onboarding: 'Let’s get started',
  // H
  hr_management: 'HR management',
  // I
  industry_new_onboarding: 'Industry',
  invite_zain_employees: 'Invite {{slug}} employees',
  import_your_employee_records:
    'Import your employee records to the system to view them on {{name}}',
  invite_more_admins: 'Invite more admins',
  invite_more_admins_des:
    'Admins can help you with setting up your workspace. You can change admins permissions later',
  invited_admins: 'Invited admins',
  invite_more_admins_to_help_you_with_completing:
    'Invite more admins to help you with completing {{slug}} workspace data',
  // J
  jisr_login_authentication: '{{name}} login authentication',
  job_role: 'Job role',
  jisrhr_en: 'Ex: {{slug}}',
  jisrhr_ar: 'مثال: {{slug}} ',
  onboarding_list: '{{name}} onboarding list',
  // K
  keep_me_logged_in_for_30_days: 'Keep me logged in for 30 days',
  // L
  login: 'Login',
  login_using_email_address: 'login using email address',
  login_using_organization_name: 'login using organization name',
  login_six_digit_code_note:
    'We’ve sent you a 6 digit code on your email. Please, check it and enter a six-digit code below',
  login_with_office365: 'Microsoft',
  leaves_and_attendance: 'Leaves',
  leaves_and_attendance_des:
    'Define the vacation balances, and allow your employees to request leaves.',
  least_characters: 'At least 8 characters',
  least_one_number: 'At least one number',
  loading_message: 'The logo is being uploaded ',
  // M
  map_out_your_company_is_structure: "Map out your company's structure",
  modules_will_appear_here_gradually: '{{app_name}} modules will appear here gradually',
  modules_will_appear_here_once_you_finish_entering:
    '{{app_name}} modules will appear here once you finish entering the required data',
  // N
  new_password: 'New Password',
  // O
  organization_name_en_new_onboarding: 'Organization name (English)',
  organization_name_ar_new_onboarding: 'Organization name (Arabic)',
  organization_logo_new_onboarding: 'Organization logo (optional)',
  or_drag_and_drop_new_onboarding: 'or drag and drop',
  // P
  password: 'Password',
  provide_essential_details:
    'Provide essential details about your company to personalize your experience',
  payroll_new_onboarding: 'Payroll',
  payroll_new_onboarding_des: 'Manage, review and run your employees Payroll.',
  // Q
  // R
  reports_and_more: 'Reports and more',
  resend_new_password_creation_link: 'Resend new password creation link',
  resend_new_code: 'Resend new code',
  replace: 'Replace',
  required_data: 'Complete the following required data',
  requests_and_automation: 'Requests and Automation',
  requests_and_automation_des:
    'Import your employee records to the system to view them on {{name}}',
  reinvite: 'Re-invite',
  // S
  submit: 'Submit',
  slug: 'Organization name',
  select_organization: 'Select Organization',
  sso_login: 'SSO Login',
  sso_disabled_err: 'Unable to login, your organization has not configured SSO.',
  select_which_service: 'Select which service or services you want to use on {{app_name}}',
  success_message: 'Logo is uploaded successfully',
  // T
  tour_step: '{{step}} of {{steps}}',
  two_factor_verification: 'Two factor verification',
  time_session_out: 'Session Time out',
  time_session_out_description:
    'The account will be logout after 4 hours in case of no action in the page.',
  try_again: 'Try again',
  tell_usa_bit_about_yourself: 'Tell us a bit about yourself',
  type_image: 'SVG, PNG, JPG files up to 10MB at least 200px by 200px',
  this_will_be_your_url_workspace_within_jisr: 'This will be your URL workspace within {{name}}',
  // U
  user_name_login: 'User name',
  upload_organization_logo: 'Upload organization logo',
  upload_employees_data: 'Upload employees data',
  update_structure: 'Update org structure',
  // V
  visit_our_knowledgebase: 'Visit our knowledgebase',
  // W
  welcome_onboard: 'Welcome onboard {{clintName}}, Now let’s create your organization workspace',
  what_would_you_like_to_jisr: 'What would you like to use Jisr for?',
  welcome_to: 'Welcome to {{name}} 👋',
  // X
  // Y
  your_account_is_connected_with_two_distinct_organizations:
    'Your account is connected with two distinct organizations. Please choose one',
  exceeded_tallowed_attempts:
    'Your account is locked due to multiple wrong attempts. you can unlock it by changing your password.',
  your_email: 'Your Email',
  your_password_must_contain: 'Your password must contain',
  // Z

  application: {
    login: 'Login for {{name}}',
  },
  date: {
    long: 'MMMM Do, YYYY',
  },
  two_lines: 'Line 1<br />Line 2',
}

export default Auth
