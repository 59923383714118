export const LOAD_ASSIGNABLE_SHIFTS = 'LOAD_ASSIGNABLE_SHIFTS'
export const LOAD_ASSIGNABLE_SHIFTS_PENDING = 'LOAD_ASSIGNABLE_SHIFTS_PENDING'
export const LOAD_ASSIGNABLE_SHIFTS_FULFILLED = 'LOAD_ASSIGNABLE_SHIFTS_FULFILLED'
export const LOAD_ASSIGNABLE_SHIFTS_REJECTED = 'LOAD_ASSIGNABLE_SHIFTS_REJECTED'

export const ASSIGN_REPLACE_SHIFT = 'ASSIGN_REPLACE_SHIFT'
export const ASSIGN_REPLACE_SHIFT_PENDING = 'ASSIGN_REPLACE_SHIFT_PENDING'
export const ASSIGN_REPLACE_SHIFT_FULFILLED = 'ASSIGN_REPLACE_SHIFT_FULFILLED'
export const ASSIGN_REPLACE_SHIFT_REJECTED = 'ASSIGN_REPLACE_SHIFT_REJECTED'

export const POPUP_ASSIGN_REPLACE_SHIFT = 'POPUP_ASSIGN_REPLACE_SHIFT'
export const POPUP_ASSIGN_REPLACE_SHIFT_PENDING = 'POPUP_ASSIGN_REPLACE_SHIFT_PENDING'
export const POPUP_ASSIGN_REPLACE_SHIFT_FULFILLED = 'POPUP_ASSIGN_REPLACE_SHIFT_FULFILLED'
export const POPUP_ASSIGN_REPLACE_SHIFT_REJECTED = 'POPUP_ASSIGN_REPLACE_SHIFT_REJECTED'
