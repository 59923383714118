import { useState } from 'react'
import { Flex, Icon } from '@jisr-hr/ds-beta'
import i18n from 'translations/i18n'
import ChatbotPopup from './ChatbotPopup'

const JisriChatbotLauncher = (): JSX.Element => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false)

  return (
    <Flex>
      <Flex
        itemsCenter
        justifyCenter
        className={`${isChatbotVisible ? 'hidden' : 'block'} ${
          i18n.language === 'ar' ? 'left-11' : 'right-11'
        }
         absolute bottom-14  flex h-16 w-16 rounded-full z-[200] 
        bg-radial-[at_25%_25%] from-white to-zinc-900 to-75%
        `}
        onClick={() => setIsChatbotVisible((prev) => !prev)}
        style={{
          background: `linear-gradient(90deg, var(--color-base-colors-blue-700), #9447FF)`,
        }}
        data-testid="chatbot-launcher"
      >
        <Icon
          color="var(--color-base-colors-white-1000)"
          name="star-06"
          size="lg"
        />
      </Flex>
      {isChatbotVisible && <ChatbotPopup setIsChatbotVisible={setIsChatbotVisible} />}
    </Flex>
  )
}

export default JisriChatbotLauncher
