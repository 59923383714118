import { useEffect } from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import { useDispatch } from 'utils/hooks'
import { FileType } from 'containers/authorised/employee/EmployeeDetails/types'
import {
  EmploymentFileApprove,
  EmploymentFileType,
} from 'containers/authorised/employee/EmployeeDetails/Tabs/EmployeeFiles/EmployeeDocuments/EmployeeDocuments'
import { omit } from 'lodash'
import { documentRequestsUpdate } from 'redux/actions/employeeFileActions'
import { Reason, Profile } from '../../components/forms'
import { useRequest } from '../../RequestProvider'
import { useRequestDetail } from '../../hooks'

type DocReqValsType = EmploymentFileType &
  EmploymentFileApprove & { id: number; reason: string; deletedAttachments: FileType[] }

const DocumentRequest = (): JSX.Element => {
  const dispatch = useDispatch()
  const {
    setOnSubmit,
    setInitialValues,
    vars: { employee },
    editRequest,
    setEditRequest,
  } = useRequest()
  const { loading, request } = useRequestDetail()

  const empId = request?.employee?.id

  const onSubmit = async (val: DocReqValsType): Promise<void> => {
    const requiredData = {
      category: val.category,
      description: val.description,
      reason: val.reason,
      attachments: val?.attachments?.[0]?.data
        ? [...val?.attachments, ...(val?.deletedAttachments ?? [])]
        : val?.deletedAttachments ?? null,
    }
    let empFileData
    if (val.category === 'other') {
      empFileData = {
        ...requiredData,
        attachments: requiredData.attachments.map((it) => {
          if ('id' in it && typeof it.id === 'string') return omit(it, 'id')
          return it
        }),
        employment_file_type_id: val.employment_file_type_id,
      }
    }
    if (val.category === 'id_iqama') {
      empFileData = {
        ...requiredData,
        attachments: requiredData.attachments.map((it) => {
          if ('id' in it && typeof it.id === 'string') return omit(it, 'id')
          return it
        }),
        document_number: val.document_number,
        iqama_expiry_date: val.iqama_expiry_date,
        iqama_expiry_date_ar: val.iqama_expiry_date_ar,
        iqama_issue_date: val.iqama_issue_date,
        iqama_issue_date_hijri: val.iqama_issue_date_hijri,
        iqama_issue_place: val.iqama_issue_place,
      }
    }
    if (val.category === 'passport') {
      empFileData = {
        ...requiredData,
        attachments: requiredData.attachments.map((it) => {
          if ('id' in it && typeof it.id === 'string') return omit(it, 'id')
          return it
        }),
        passport_expiry_date: val.passport_expiry_date,
        passport_issue_date: val.passport_issue_date,
        passport_issue_place: val.passport_issue_place,
        passport_number: val.passport_number,
      }
    }
    await dispatch(documentRequestsUpdate(empId, val.id, empFileData ?? {}))
    setEditRequest(false)
  }

  useEffect(() => {
    if (editRequest) {
      setInitialValues({ ...request, deletedAttachments: [] })
    }
    setOnSubmit(onSubmit)

    return () => {
      setOnSubmit(undefined)
      setInitialValues({})
    }
  }, [editRequest])

  return (
    <Flex
      flexCol
      style={{ gap: '16px' }}
    >
      {loading && <JisrLoader absolute />}
      <Profile employee={employee} />
      <Reason />
    </Flex>
  )
}

export default DocumentRequest
