const Elevatus = {
  // E
  elevatus: 'إليفاتوس',
  elevatus_integration_card_note: 'إدارة بيانات الموظفين بسلاسة وتبسيط عملية التوظيف.',
  elevatus_activation: 'Elevatus تفعيل',
  elevatus_how_to_title: 'كيفية التكامل على مستوى الحساب مع {{app_name}}:',
  elevatus_step_1:
    ' في الشريط الجانبي للوحة المعلومات الخاصة بك في إليفاتوس، انقر على صفحة "الروابط".',
  elevatus_step_2:
    'في صفحة "الارتباطات"، ابحث عن بطاقة "التكامل مع {{app_name}}" وانقر على زر "الاتصال" لتفعيل خاصية التكامل.',
  elevatus_step_3:
    'عند الطلب، قم بتزويد "مُعرّف المنشأة" (Organization Slug)<copyButton/> و"مفتاح واجهة برمجة التطبيقات" <a>(API)</a>.',
  elevatus_step_4:
    'اضغط على زر "الحفظ"، وفي حال عدم ظهور أي مشاكل، ستظهر لك رسالة تشير بإتمام العملية بنجاح.',
  elevatus_step_5:
    'في النهاية، سيُطلب منك "مزامنة البحث مع {{app_name}}"، اضغط على زر "التأكيد" لإعداد التكامل بصورة كاملة.',
  elevatus_note_org_slug:
    '"مُعرّف المنشأة" (Organization Slug) هو مُعرّفًا فريدًا لمنشأتك في نظام {{app_name}}. يُمكنك إعداده بسهولة من خلال لوحة المعلومات في حساب {{app_name}} الخاص بك أو عن طريق التواصل مع فريق دعم {{app_name}} للموارد البشرية.',
  elevatus_note_api_token:
    'مفتاح واجهة برمجة التطبيقات (API Key) هو رمز سري يتيح للتكامل الخاص بك الوصول إلى بيانات حساب {{app_name}} الخاص بك. يُمكنك إنشاء مفتاح API جديد بسهولة من خلال إعدادات حسابك في {{app_name}} أو عن طريق التواصل مع فريق دعم {{app_name}} للموارد البشرية.',
  elevatus_note_help_contact:
    'بمجرد توفيرك لـمُعَرِّفُ المنشأة ومفتاح واجهة برمجة التطبيقات، سيتم إعداد التكامل الخاص بك وسيكون جاهزًا للإستخدام. إذا كنت بحاجة لمزيد من المعلومات أو المساعدة، يمكنك التواصل مع فريق الدعم الخاص بـ إليفاتوس أو {{app_name}} للموارد البشرية.',
  enable_elevatus: 'تفعيل إليفاتوس',
  disable_elevatus: 'تعطيل إليفاتوس',
}

export default Elevatus
