import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

const Switcher = ({ options }) => (
  <div className="switcher">
    {options &&
      options.map((option, i) => (
        <span
          data-testid={option.testId}
          key={i}
          onClick={option.onClick && option.onClick}
          className={className(option.isActive ? 'active' : 'inactive')}
        >
          {option.title}
        </span>
      ))}
  </div>
)

Switcher.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
}

export default Switcher
