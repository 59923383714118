import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import { TAssetWithOpts, TAttachment } from 'containers/authorised/Assets/types'
import { PaginationTypes } from 'types/sharedResponse'
import * as actions from './actions'

type AssetHistory = {
  action_by: {
    full_name_i18n: string
  }
  action_type: string
  attachments_info?: TAttachment[]
  changeset: TAssetWithOpts
  created_at: string
  id: number
  reason?: string
}

type TInitState = {
  asset_history: AssetHistory[]
  fetching: boolean
  pagination: PaginationTypes | Record<string, never>
}

const initialState: TInitState = {
  fetching: false,
  asset_history: [],
  pagination: {},
}

export default function reducer(state = initialState, action: Action): TInitState {
  const { payload } = action

  switch (action.type) {
    case actions.GET_ASSET_HISTORY_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GET_ASSET_HISTORY_FULFILLED:
      return {
        ...state,
        asset_history: [
          ...new Set([
            ...(payload.data.data.pagination?.previous_page ? state.asset_history : []),
            ...payload.data.data.asset_history,
          ]),
        ],
        pagination: payload.data.data.pagination,
        fetching: false,
      }

    case actions.GET_ASSET_HISTORY_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}
