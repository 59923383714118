import React from 'react'
import { Flex } from '@jisr-hr/ds'
import JisrLoader from 'components/global/JisrLoader'
import I18n from 'translations/i18n'
import { letterTemplatesPdfLoad, letterTemplatesGetOne } from 'redux/letterTemplates/actionCreators'
import { useDispatch, useSelector } from 'react-redux'
import { FilePreviewModal } from 'components/global/molecules'
import DSIcon from 'components/DSIcon'

import { ReactComponent as FileIcon } from '../../icons/file-text.svg'
import { Comments, Info, Reason, Tabs, Workflow } from '../../components'
import { useRequestDetail } from '../../hooks'
import LinkedTaskWithReq from '../../components/LinkedTaskWithReq/LinkedTaskWithReq'

const LetterRequest = () => {
  const { loading, request, reloadRequest } = useRequestDetail()
  const { downloading } = useSelector(({ letterTemplates }) => letterTemplates)
  const [activeTab, setActiveTab] = React.useState('request_details')
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()

  const handleDownloadLetter = () => {
    dispatch(
      letterTemplatesPdfLoad({
        empId: request.employee.id,
        requestId: request.id,
      }),
    )
  }

  const handlePreviewLetter = () => {
    dispatch(
      letterTemplatesGetOne({
        id: request.letter_type_id,
      }),
    )
  }

  const items = [
    {
      label: I18n.t('letter_type'),
      icon: <FileIcon />,
      value: request.letter_type?.name,
    },
    {
      label: I18n.t('directed_to_en'),
      icon: <FileIcon />,
      value: request.directed_to_en,
    },
    {
      label: I18n.t('directed_to_ar'),
      icon: <FileIcon />,
      value: request.directed_to_ar,
    },
    request.status === 'approved' && {
      label: I18n.t('attachment'),
      value: (
        <Flex
          itemsCenter
          style={{ gap: 12 }}
        >
          <DSIcon
            name="eye"
            size="lg"
            fill="var(--ds-core-color-base-black)"
            color="var(--ds-core-color-base-white)"
            styles={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
          <DSIcon
            name="download-01"
            styles={{ cursor: 'pointer' }}
            onClick={handleDownloadLetter}
          />
        </Flex>
      ),
    },
  ].filter(Boolean)

  return (
    <>
      <Flex
        flexCol
        style={{ gap: '16px' }}
      >
        {(loading || downloading) && <JisrLoader absolute />}

        <Tabs
          active={activeTab}
          onChange={(tab) => setActiveTab(tab)}
        />
        {activeTab === 'request_details' && (
          <>
            <Info
              details={{
                ...request,
                created_by: request.created_by,
                type_i18n: request?.request_name_i18n,
                created_at: request.created_at,
                requested_on: request.created_at,
                employee: request?.employee,
                items,
              }}
            />

            {(request.reason || request.attachments?.length > 0) && (
              <Reason
                details={{
                  ...request,
                  attachments: request.attachments,
                }}
              />
            )}
            {['pending', 'approved'].includes(request.status) &&
              !['AssetClearRequest', 'LeaveResumptionRequest'].includes(request.request_type) &&
              !request?.is_cancellation_request && <LinkedTaskWithReq />}
            <Workflow
              lessDetail
              onClick={() => setActiveTab('workflow_and_comments')}
              approvals={request?.approvals}
            />
          </>
        )}

        {activeTab === 'workflow_and_comments' && (
          <>
            <Comments
              comments={request?.comments || []}
              reloadRequest={reloadRequest}
              approval={{
                request_id: request.id,
                request_type: request?.request_type,
              }}
              can_add_comment={request?.can_add_comment}
            />
            <Workflow approvals={request?.approvals} />
          </>
        )}
      </Flex>

      <FilePreviewModal
        title={request.letter_type?.name}
        open={open}
        onClose={() => setOpen(false)}
        handleDownload={handleDownloadLetter}
        handlePreviewLetter={handlePreviewLetter}
      />
    </>
  )
}

export default LetterRequest
