export const ONBOARDING_CARRYFORWARDTYPE_LOAD = 'ONBOARDING_CARRYFORWARDTYPE_LOAD'
export const ONBOARDING_CARRYFORWARDTYPE_LOAD_PENDING = 'ONBOARDING_CARRYFORWARDTYPE_LOAD_PENDING'
export const ONBOARDING_CARRYFORWARDTYPE_LOAD_FULFILLED =
  'ONBOARDING_CARRYFORWARDTYPE_LOAD_FULFILLED'
export const ONBOARDING_CARRYFORWARDTYPE_LOAD_REJECTED = 'ONBOARDING_CARRYFORWARDTYPE_LOAD_REJECTED'

export const ONBOARDING_CARRYFORWARD_LOAD = 'ONBOARDING_CARRYFORWARD_LOAD'
export const ONBOARDING_CARRYFORWARD_LOAD_PENDING = 'ONBOARDING_CARRYFORWARD_LOAD_PENDING'
export const ONBOARDING_CARRYFORWARD_LOAD_FULFILLED = 'ONBOARDING_CARRYFORWARD_LOAD_FULFILLED'
export const ONBOARDING_CARRYFORWARD_LOAD_REJECTED = 'ONBOARDING_CARRYFORWARD_LOAD_REJECTED'

export const ONBOARDING_CARRYFORWARD_UPDATE = 'ONBOARDING_CARRYFORWARD_UPDATE'
export const ONBOARDING_CARRYFORWARD_UPDATE_PENDING = 'ONBOARDING_CARRYFORWARD_UPDATE_PENDING'
export const ONBOARDING_CARRYFORWARD_UPDATE_FULFILLED = 'ONBOARDING_CARRYFORWARD_UPDATE_FULFILLED'
export const ONBOARDING_CARRYFORWARD_UPDATE_REJECTED = 'ONBOARDING_CARRYFORWARD_UPDATE_REJECTED'
