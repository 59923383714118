import { useEffect, useState, ReactElement, memo } from 'react'
import { Button } from '@jisr-hr/ds'
import { Dropdown, ListItem, ListItemProps } from '@jisr-hr/ds-beta'
import { Employee } from 'redux/authUser/types'
import i18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { CannyApi, IsCannyEnable } from 'components/UserSnap/canny'
import { useUsersnapApi } from 'components/UserSnap/UsersnapContext'
import { serverEnvironment } from 'env'

const defaultUrl = 'https://intercom.help/jisr/en'

type Props = {
  target?: ReactElement
}

const HelpCenter = ({ target }: Props): JSX.Element => {
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)
  const { employee, company } = useSelector((state) => state.auth)
  const usersnap = useUsersnapApi()
  const { chat_support_config } = employee as Employee
  const isJisrUser = employee?.email?.includes('@jisr.net')
  const allowedEnvs = ['aws', 'oci'].includes(serverEnvironment)
  const authenticatedLink = CannyApi({
    name: 'authenticateCannyLink',
  })

  const options: ListItemProps[] = [
    {
      text: i18n.t('jisr_academynav'),
      leadingIcon: 'graduation-hat-01',
      onClick: () => window.open(`https://academy.jisr.net/dashboard/`),
    },
    {
      text: i18n.t('help_center'),
      leadingIcon: 'help-circle',
      onClick: () => window.open(chat_support_config?.url || defaultUrl, '_blank'),
    },
    ...(chat_support_config?.is_enabled
      ? [
          {
            text: i18n.t('contact_support_intercom'),
            leadingIcon: 'message-chat-circle',
            trailingBadge: {
              label: unreadMessageCount.toString(), // Convert to string
              variant: 'error',
            },
            onClick: (): void => {
              if (chat_support_config?.provider === 'zendesk') {
                window?.zE('messenger', 'loginUser', (callback: (s: string) => void) => {
                  callback(chat_support_config.token)
                  window?.zE('messenger', 'open')
                })
              } else {
                window?.Intercom('show')
              }
            },
          },
        ]
      : []),
    IsCannyEnable && {
      text: i18n.t('share_your_idea'),
      leadingIcon: 'lightbulb-02',
      onClick: (): void => {
        if (authenticatedLink) {
          window.open(authenticatedLink, '_blank', 'noopener')
        }
      },
    },
    {
      text: i18n.t('what_new'),
      leadingIcon: 'rocket-01',
      onClick: () => window.open(`https://updates.jisr.net/${i18n.language}`, '_blank'),
    },
    isJisrUser &&
      allowedEnvs && {
        text: i18n.t('label.report_an_issue'),
        leadingIcon: 'alert-octagon',
        onClick: (): void => {
          usersnap?.logEvent('advanced_bug_tracking')
        },
      },
    ...(company?.account_type === 'testing'
      ? [
          {
            text: i18n.t('enablement_hub'),
            leadingIcon: 'link-04',
            onClick: () => window.open(`https://jisrlms.com/`, '_blank'),
          },
        ]
      : []),
  ].filter(Boolean)

  useEffect(() => {
    try {
      if (chat_support_config?.provider === 'zendesk') {
        window?.zE('messenger', 'loginUser', (callback: (s: string) => void) => {
          callback(chat_support_config.token)
          window?.zE('messenger:set', 'locale', employee?.language)
          window?.zE('messenger:on', 'unreadMessages', (count: number) => {
            if (count) {
              setUnreadMessageCount(count)
              // window?.zE('messenger', 'open')
            } else {
              setUnreadMessageCount(0)
            }
          })
        })
      }
    } catch (error) {
      console.error('Error in HelpCenter useEffect:', error)
    }
  }, [chat_support_config])

  return (
    <Dropdown
      withPortal
      style={{ marginTop: '1em', zIndex: 999 }}
      dropDownElement={
        target || (
          <Button
            size="medium"
            variant="ghost"
            color="neutral"
            leadingIcon="help-circle"
            {...(unreadMessageCount && { className: 'helpDot' })}
          />
        )
      }
    >
      {options.map((listItem, index) => {
        return (
          <ListItem
            {...listItem}
            key={index}
          />
        )
      })}
    </Dropdown>
  )
}

export default memo(HelpCenter)
