import PropTypes from 'prop-types'
import { Flex, Modal } from '@jisr-hr/ds'
import I18n from 'translations/i18n'
import { useSelector } from 'utils/hooks'
import { ReactNode } from 'react'
import PayrollBankCard from '../../../PayrollBank/components/PayrollBankCard'
import { ViewMoreModalProps } from '../../SummaryReview'

const ViewMoreModal = ({ isOpen, onClose }: ViewMoreModalProps): JSX.Element => {
  const { commercial_registrations } = useSelector((s) => s?.commercialRegistrationsOnborading)
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="x-large"
      header={{ title: I18n.t('payroll_bank'), withClose: true }}
    >
      <div className="mb-[24px] max-h-[600px] overflow-y-auto">
        <Flex
          flexWrap
          style={{ gap: 24 }}
        >
          {commercial_registrations?.map(
            (item, i): ReactNode => (
              <PayrollBankCard
                key={i}
                commercialRegistration={item}
              />
            ),
          )}
        </Flex>
      </div>
    </Modal>
  )
}

ViewMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ViewMoreModal
