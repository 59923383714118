import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  fetching: false,
  offline_devices_count: 0,
  offline_devices: [],
}

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case actions.EASY_CLOCKING_DEVICES_OFFLINE_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.EASY_CLOCKING_DEVICES_OFFLINE_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.EASY_CLOCKING_DEVICES_OFFLINE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
      }

    default:
      return state
  }
}
