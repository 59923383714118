export const REMOVE_AUTH_USER = 'REMOVE_AUTH_USER'
export const UPDATE_EMPLOYEE_PERMISSION = 'UPDATE_EMPLOYEE_PERMISSION'
export const CHANGES_ORGNIATION_STATUS = 'CHANGES_ORGNIATION_STATUS'
export const UPDATE_ACCEPTED_TERMS_AND_CONDITIONS = 'UPDATE_ACCEPTED_TERMS_AND_CONDITIONS'
export const ONBOARDING_CHANGES_ORGANIZATION_INFO = 'ONBOARDING_CHANGES_ORGANIZATION_INFO'

export const LOGIN = 'LOGIN'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_PENDING = 'LOGOUT_PENDING'
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING'
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED'
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING'
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED'
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING'
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED'
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED'

export const VALIDATE_COMPANY = 'VALIDATE_COMPANY'
export const VALIDATE_COMPANY_PENDING = 'VALIDATE_COMPANY_PENDING'
export const VALIDATE_COMPANY_FULFILLED = 'VALIDATE_COMPANY_FULFILLED'
export const VALIDATE_COMPANY_REJECTED = 'VALIDATE_COMPANY_REJECTED'

export const VALIDATE_COMPANY_KSA = 'VALIDATE_COMPANY_KSA'
export const VALIDATE_COMPANY_KSA_PENDING = 'VALIDATE_COMPANY_KSA_PENDING'
export const VALIDATE_COMPANY_KSA_FULFILLED = 'VALIDATE_COMPANY_KSA_FULFILLED'
export const VALIDATE_COMPANY_KSA_REJECTED = 'VALIDATE_COMPANY_KSA_REJECTED'

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_PENDING = 'GET_CURRENT_USER_PENDING'
export const GET_CURRENT_USER_FULFILLED = 'GET_CURRENT_USER_FULFILLED'
export const GET_CURRENT_USER_REJECTED = 'GET_CURRENT_USER_REJECTED'

export const CURRENT_EMPLOYEE_CHANGE_LANGUAGE = 'CURRENT_EMPLOYEE_CHANGE_LANGUAGE'
export const CURRENT_EMPLOYEE_CHANGE_LANGUAGE_PENDING = 'CURRENT_EMPLOYEE_CHANGE_LANGUAGE_PENDING'
export const CURRENT_EMPLOYEE_CHANGE_LANGUAGE_FULFILLED =
  'CURRENT_EMPLOYEE_CHANGE_LANGUAGE_FULFILLED'
export const CURRENT_EMPLOYEE_CHANGE_LANGUAGE_REJECTED = 'CURRENT_EMPLOYEE_CHANGE_LANGUAGE_REJECTED'

export const LAST_CONFIRMED_PAYROLL = 'LAST_CONFIRMED_PAYROLL'
export const LAST_CONFIRMED_PAYROLL_PENDING = 'LAST_CONFIRMED_PAYROLL_PENDING'
export const LAST_CONFIRMED_PAYROLL_FULFILLED = 'LAST_CONFIRMED_PAYROLL_FULFILLED'
export const LAST_CONFIRMED_PAYROLL_REJECTED = 'LAST_CONFIRMED_PAYROLL_REJECTED'

export const EXPERIMENT_VALUES_CREATE = 'EXPERIMENT_VALUES_CREATE'
export const EXPERIMENT_VALUES_CREATE_PENDING = 'EXPERIMENT_VALUES_CREATE_PENDING'
export const EXPERIMENT_VALUES_CREATE_FULFILLED = 'EXPERIMENT_VALUES_CREATE_FULFILLED'
export const EXPERIMENT_VALUES_CREATE_REJECTED = 'EXPERIMENT_VALUES_CREATE_REJECTED'

export const EXPERIMENT_VALUES_UPDATE = 'EXPERIMENT_VALUES_UPDATE'
export const EXPERIMENT_VALUES_UPDATE_PENDING = 'EXPERIMENT_VALUES_UPDATE_PENDING'
export const EXPERIMENT_VALUES_UPDATE_FULFILLED = 'EXPERIMENT_VALUES_UPDATE_FULFILLED'
export const EXPERIMENT_VALUES_UPDATE_REJECTED = 'EXPERIMENT_VALUES_UPDATE_REJECTED'

export const COMPANY_SUBDOMAINS = 'COMPANY_SUBDOMAINS'
export const COMPANY_SUBDOMAINS_PENDING = 'COMPANY_SUBDOMAINS_PENDING'
export const COMPANY_SUBDOMAINS_FULFILLED = 'COMPANY_SUBDOMAINS_FULFILLED'
export const COMPANY_SUBDOMAINS_REJECTED = 'COMPANY_SUBDOMAINS_REJECTED'

export const ONBOARDING_CHANGE_TOUR_STEP = 'ONBOARDING_CHANGE_TOUR_STEP'

export const MICROSOFT_VERIFY_OTP = 'MICROSOFT_VERIFY_OTP'
export const MICROSOFT_VERIFY_OTP_PENDING = 'MICROSOFT_VERIFY_OTP_PENDING '
export const MICROSOFT_VERIFY_OTP_FULFILLED = 'MICROSOFT_VERIFY_OTP_FULFILLED'
export const MICROSOFT_VERIFY_OTP_REJECTED = 'MICROSOFT_VERIFY_OTP_REJECTED'
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER'
export const VERIFY_PHONE_NUMBER_PENDING = 'VERIFY_PHONE_NUMBER_PENDING'
export const VERIFY_PHONE_NUMBER_FULFILLED = 'VERIFY_PHONE_NUMBER_FULFILLED'
export const VERIFY_PHONE_NUMBER_REJECTED = 'VERIFY_PHONE_NUMBER_REJECTED'

export const VERIFY_OTP_DIGITS = 'VERIFY_OTP_DIGITS'
export const VERIFY_OTP_DIGITS_PENDING = 'VERIFY_OTP_DIGITS_PENDING'
export const VERIFY_OTP_DIGITS_FULFILLED = 'VERIFY_OTP_DIGITS_FULFILLED'
export const VERIFY_OTP_DIGITS_REJECTED = 'VERIFY_OTP_DIGITS_REJECTED'

export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN'
