export const INVOICES_LOAD = 'INVOICES_LOAD'
export const INVOICES_LOAD_PENDING = 'INVOICES_LOAD_PENDING'
export const INVOICES_LOAD_FULFILLED = 'INVOICES_LOAD_FULFILLED'
export const INVOICES_LOAD_REJECTED = 'INVOICES_LOAD_REJECTED'

export const INVOICE_DOWNLOAD = 'INVOICE_DOWNLOAD'
export const INVOICE_DOWNLOAD_PENDING = 'INVOICE_DOWNLOAD_PENDING'
export const INVOICE_DOWNLOAD_FULFILLED = 'INVOICE_DOWNLOAD_FULFILLED'
export const INVOICE_DOWNLOAD_REJECTED = 'INVOICE_DOWNLOAD_REJECTED'

export const SUBSCRIPTIONS_LOAD = 'SUBSCRIPTIONS_LOAD'
export const SUBSCRIPTIONS_LOAD_PENDING = 'SUBSCRIPTIONS_LOAD_PENDING'
export const SUBSCRIPTIONS_LOAD_FULFILLED = 'SUBSCRIPTIONS_LOAD_FULFILLED'
export const SUBSCRIPTIONS_LOAD_REJECTED = 'SUBSCRIPTIONS_LOAD_REJECTED'

export const BILLING_INFO_LOAD = 'BILLING_INFO_LOAD'
export const BILLING_INFO_LOAD_PENDING = 'BILLING_INFO_LOAD_PENDING'
export const BILLING_INFO_LOAD_FULFILLED = 'BILLING_INFO_LOAD_FULFILLED'
export const BILLING_INFO_LOAD_REJECTED = 'BILLING_INFO_LOAD_REJECTED'

export const ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD = 'ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD'
export const ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_PENDING =
  'ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_PENDING'
export const ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_FULFILLED =
  'ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_FULFILLED'
export const ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_REJECTED =
  'ORGANIZATIONS_SHOW_VIRTUAL_IBAN_LOAD_REJECTED'

export const BILLING_INFO_PUT = 'BILLING_INFO_PUT'
export const BILLING_INFO_PUT_PENDING = 'BILLING_INFO_PUT_PENDING'
export const BILLING_INFO_PUT_FULFILLED = 'BILLING_INFO_PUT_FULFILLED'
export const BILLING_INFO_PUT_REJECTED = 'BILLING_INFO_PUT_REJECTED'

export const EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD = 'EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD'
export const EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_PENDING =
  'EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_PENDING'
export const EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_FULFILLED =
  'EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_FULFILLED'
export const EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_REJECTED =
  'EMPLOYEES_NOTIFICATIONS_DUNNING_LOAD_REJECTED'

export const CLOSE_ALERT = 'CLOSE_ALERT'
