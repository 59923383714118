import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'

const DEFAULT_STATE = {
  fetching: false,
  attendance_record: {},
}

let changes = null
export default function missingPunchRequestReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'MISSING_PUNCH_REQUEST_PENDING':
    case 'MISSING_PUNCH_REQUEST_UPDATE_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_FULFILLED':
    case 'MISSING_PUNCH_REQUEST_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_DATE_LOAD_FULFILLED':
      changes = {
        fetching: false,
        attendance_record: action.payload.data.data.attendance_record,
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_DATE_LOAD_REJECTED':
      changes = {
        fetching: false,
        attendance_record: {},
      }
      return { ...state, ...changes }

    case 'MISSING_PUNCH_REQUEST_REJECTED':
    case 'MISSING_PUNCH_REQUEST_UPDATE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
