import React from 'react'
import PropTypes from 'prop-types'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Avatars } from '@jisr-hr/ds'

const LazyAvatar = ({ src, alt, style, scrollPosition, testId, containerStyle }) => {
  const firstLetter = typeof alt === 'string' ? `${alt[0]?.toUpperCase()}` : ''

  return (
    <div
      className="jisr-lazy-avatar"
      style={containerStyle}
    >
      <LazyLoadComponent scrollPosition={scrollPosition}>
        <Avatars
          size="sm"
          data-testid={testId}
          className="jisr-lazy-avatar-img"
          style={{ ...style }}
          initialsText={firstLetter}
          showAvatarCompanyIcon={false}
          {...(src && {
            type: 'image',
            imageSrc: src,
            imageAlt: alt,
            style: { ...style, backgroundColor: 'transparent' },
          })}
          {...(!src &&
            !alt && {
              type: 'icon',
              iconName: 'user-02',
              style: { backgroundColor: '' },
              iconProps: { style: { fill: 'var(--ds-core-color-gray-400)' } },
            })}
        />
      </LazyLoadComponent>
    </div>
  )
}

LazyAvatar.propTypes = {
  testId: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.shape(),
  containerStyle: PropTypes.shape(),
  scrollPosition: PropTypes.shape(),
}

export default LazyAvatar
