/** **************
 *   loanRequestActions
 /*************** */

import { handleAPI, API_VERSION_TWO } from 'utils/apiUtils'

export function loanRequestCreate(empId, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${empId}/loan_requests/`
  const method = 'POST'
  return {
    type: 'LOAN_REQUEST_CREATE',
    payload: handleAPI(url, {}, method, { loan_request: data }),
  }
}

export function loanRequestUpdate(empId, id, data) {
  const url = `/${API_VERSION_TWO}/finance/employees/${empId}/loan_requests/${id}`
  const method = 'PUT'
  return {
    type: 'LOAN_REQUEST_UPDATE',
    payload: handleAPI(url, {}, method, { loan_request: data }),
  }
}
