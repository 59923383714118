import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type IDoc = {
  name: string
}

type DocumentTypesProps = {
  filterKey: string
  filters: string[]
}

const DocumentTypes = (props: DocumentTypesProps): JSX.Element => {
  const { document_types } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const optionList = document_types.map((doc: IDoc) => ({ ...doc, id: doc.name }))

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={optionList}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default DocumentTypes
