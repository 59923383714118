import { RootState } from 'types/redux'
import { TAllowancesState } from './reducer'

type AllowancesSelector = Omit<TAllowancesState, 'fetching'>

export const allowancesSelector = (state: RootState): AllowancesSelector => ({
  allowances: state.onboarding_transaction_types.allowances || [],
  status: state.onboarding_transaction_types.status,
  loading: state.onboarding_transaction_types.loading,
})

export const fetchingSelector = (state: RootState): boolean =>
  state.onboarding_transaction_types.fetching
export const loadingSelector = (state: RootState): boolean =>
  state.onboarding_transaction_types.loading
