import React from 'react'
import PropTypes from 'prop-types'

const TextButton = ({ title, description, Icon, ...rest }) => (
  <button
    {...rest}
    className="jisr-text-btn"
  >
    <div className="jisr-text-btn__container">
      <div style={{ margin: '0px 10px' }}>
        <Icon />
      </div>
      <div>
        <span className="jisr-text-btn__title">{title}</span>
        {description && <span className="jisr-text-btn__desc">{description}</span>}
      </div>
    </div>
  </button>
)

TextButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.node,
}

export default TextButton
