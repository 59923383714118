import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as EditIcon } from '../img/edit.svg'
import Styles from '../Table.module.css'

const ActionsButtons = ({ onClickEdit }) => (
  <>
    <EditIcon
      onClick={onClickEdit}
      className={Styles.editIcon}
    />
  </>
)

ActionsButtons.propTypes = {
  onClickEdit: PropTypes.func,
}

export default ActionsButtons
