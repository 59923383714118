import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'translations/i18n'
import Frame from 'components/global/templates/RequestDrawer/ds/Frame'

import { ReactComponent as CalendarIcon } from 'components/global/templates/RequestDrawer/components/Info/icons/calendar.svg'
import { ReactComponent as SmallExitVisaIcon } from 'assets/images/SmallExitVisaIcon.svg'
import { useSelector } from 'utils/hooks'

import InfoList from '../../ds/InfoList'

const Policy = ({ details }) => {
  const currency = useSelector(
    (s) => s.organizationProfile.organization_currency?.currency_type_i18n,
  )
  const isSingleDay = details?.travel_days === 1
  const tavelDays = `${details?.travel_days || 0} ${I18n.t(isSingleDay ? 'day' : 'days')}`

  const items = [
    {
      icon: <CalendarIcon />,
      value: details?.calculation_basis_i18n || '--',
      label: I18n.t('policy_configuration'),
    },
    {
      icon: <CalendarIcon />,
      value: `${currency} ${details?.per_diem_total}`,
      label: I18n.t('daily_allowance'),
    },
    { icon: <SmallExitVisaIcon />, value: tavelDays, label: I18n.t('travel_days') },
  ]

  return (
    <Frame title={I18n.t('business_trip_policy')}>
      <InfoList items={items} />
    </Frame>
  )
}

Policy.propTypes = {
  details: PropTypes.shape(),
}

export default Policy
