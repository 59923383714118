import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import * as actions from './actions'

const initialState = {
  easy_clocking_login: {},
  fetching: false,
  deviceDetails: null,
  devices: [],
  locations: [],
  isDevicesFetching: false,
  syncStep: 0, // 0 - default, 1 - checking connection, 2 - connected, 3 - syncing
  editId: null,
  users: [],
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.EASY_CLOCKING_LOGIN_LOAD_FULFILLED:
      return {
        ...state,
        ...payload.data.data,
      }

    case actions.EASY_CLOCKING_LOGIN_POST_FULFILLED:
    case actions.EASY_CLOCKING_LOGIN_POST_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...payload.data.data,
      }

    case actions.EASY_CLOCKING_CHECK_CONNECTION_PENDING:
      return {
        ...state,
        syncStep: 1,
      }

    case actions.EASY_CLOCKING_CHECK_CONNECTION_FULFILLED:
      if (!payload.data.success) {
        Toastr.error(handleResponseErr(payload))
      }

      return {
        ...state,
        syncStep: payload.data.success ? 2 : 0,
      }

    case actions.EASY_CLOCKING_SYNC_PENDING: {
      return {
        ...state,
        syncStep: 3,
      }
    }

    case actions.EASY_CLOCKING_SYNC_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        syncStep: 4,
        job: payload.data.data.job,
      }
    }

    case actions.EASY_CLOCKING_GET_DEVICES_PENDING:
    case actions.EASY_CLOCKING_ASSIGN_USERS_PENDING:
    case actions.EASY_CLOCKING_UNASSIGN_USER_PENDING: {
      return {
        ...state,
        isDevicesFetching: true,
      }
    }

    case actions.EASY_CLOCKING_GET_DEVICES_FULFILLED: {
      return {
        ...state,
        devices: payload.data.data.devices,
        isDevicesFetching: false,
      }
    }

    case actions.EASY_CLOCKING_ASSIGN_USERS_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        isDevicesFetching: false,
      }
    }

    case actions.EASY_CLOCKING_UNASSIGN_USER_FULFILLED: {
      Toastr.success(payload.data.message)
      return {
        ...state,
        isDevicesFetching: false,
      }
    }

    case actions.EASY_CLOCKING_GET_DEVICE_DETAILS_PENDING: {
      return {
        ...state,
        deviceDetails: null,
      }
    }

    case actions.EASY_CLOCKING_GET_DEVICE_DETAILS_FULFILLED: {
      return {
        ...state,
        deviceDetails: {
          users: payload.data.data.easy_clocking_data,
        },
      }
    }

    case actions.EASY_CLOCKING_GET_LOCATIONS_FULFILLED: {
      return {
        ...state,
        locations: payload.data.data,
      }
    }

    case actions.EASY_CLOCKING_GET_USERS_FULFILLED: {
      return {
        ...state,
        users: payload.data.data,
      }
    }

    case actions.EASY_CLOCKING_SET_EDIT_ID: {
      return {
        ...state,
        editId: payload.editId,
      }
    }

    case actions.EASY_CLOCKING_UPDATE_DEVICE_FULFILLED: {
      Toastr.success(payload.data.data.device)
      return {
        ...state,
        editId: null,
      }
    }

    case actions.EASY_CLOCKING_LOGIN_LOAD_REJECTED:
    case actions.EASY_CLOCKING_LOGIN_POST_REJECTED:
    case actions.EASY_CLOCKING_LOGIN_POST_UPDATE_REJECTED:
    case actions.EASY_CLOCKING_CHECK_CONNECTION_REJECTED:
    case actions.EASY_CLOCKING_SYNC_REJECTED:
    case actions.EASY_CLOCKING_GET_DEVICES_REJECTED:
    case actions.EASY_CLOCKING_GET_DEVICE_DETAILS_REJECTED:
    case actions.EASY_CLOCKING_ASSIGN_USERS_REJECTED:
    case actions.EASY_CLOCKING_UNASSIGN_USER_REJECTED:
    case actions.EASY_CLOCKING_GET_LOCATIONS_REJECTED:
    case actions.EASY_CLOCKING_UPDATE_DEVICE_REJECTED:
      Toastr.error(handleResponseErr(payload))
      return {
        ...state,
        errMsg: handleResponseErr(payload),
        fetching: false,
        isDevicesFetching: false,
        syncStep: 0,
      }

    default:
      return state
  }
}
