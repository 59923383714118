import { Badge, BadgeProps } from '@jisr-hr/ds'

type InfoBadgePRops = {
  label: BadgeProps['label']
  color: BadgeProps['color']
  icon?: BadgeProps['leadingIcon']
}

const InfoBadge = ({ label, color, icon }: InfoBadgePRops): JSX.Element => (
  <Badge
    label={label || ''}
    leadingIcon={icon}
    size="small"
    color={color}
    variant="tinted"
  />
)

export default InfoBadge
