import { ChangeEvent } from 'react'
import { Checkbox, Flex } from '@jisr-hr/ds'
import Styles from './EmploymentType.module.css'

type TProps = {
  label: string
  active: boolean
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const CheckboxItem = (props: TProps): JSX.Element => {
  const { label, active, disabled } = props

  return (
    <Flex
      itemsCenter
      style={{ cursor: 'unset' }}
      className={Styles.buttonNewEmployment}
    >
      <div style={{ cursor: 'pointer' }}>
        <Checkbox
          label={label}
          active={active}
          disabled={disabled}
          onChange={props.onChange}
        />
      </div>
    </Flex>
  )
}

export default CheckboxItem
