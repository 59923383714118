const Constants = {
  // Payment types
  cash: 'نقداً',
  bank: 'بنك',
  cheque: 'شيك',
  international_transfer: 'حوالة دولية',
  direct_transfer: 'حوالة مباشرة',
  // Bank Types
  sabb_bamk: 'بنك ساب',
  riadh_bank: 'بنك الرياض',
  saudi_fransi_ban: 'البنك السعودي الفرنسي',
  ncb_bank: 'البنك الأهلي التجاري',
  rajhi_bank: 'بنك الراجحي',
  anp_bank: 'البنك العربي الوطني',
  aljazeera: 'بنك الجزيرة',
  investment: 'بنك الاستثمار',
  alawwal: 'بنك الاول',
  // Gender
  male: 'ذكر',
  female: 'أنثى',
  // Marital status
  married: 'متزوج',
  single: 'أعزب',
  divorced: 'مطلق',
  // Religion
  muslim: 'مسلم',
  non_muslim: 'غير مسلم',
  // Ammount type
  percentage: 'نسبة المئوية',
  value: 'قيمة',
  // Letter types
  commitment_letter: 'خطاب تعهد',
  clearance_letter: 'خطاب تخليص',
  // Relationships
  spouse: 'زوج',
  son: 'إبن',
  daughter: 'إبنة',
  // Assets status
  allocated: 'في عهدة الموظف',
  cleared: 'تم الاسترجاع',
  // Contract types
  fixed_term: 'مدة محددة',
  indefinite: 'غير محدد',
  // Contrct periods
  one_year: 'سنة',
  two_years: 'سنتان',
  years: 'سنين',
  custom: 'مخصص',
  // Loan category
  normal: 'عادي',
  urgent: 'عاجل',
  // Payroll calculation base
  basic_salary: 'الراتب الأساسي',
  full_package: 'كامل الراتب',
  basic_plus_selected_benefits_types: 'الراتب الأساسي + البدلات المحددة',
  // Leaving reaso:arns
  leaving_reason_1: 'إنتهاء العقد أو باتفاق الطرفين على إنهاء العقد',
  leaving_reason_2: 'فسخ العقد من قبل صاحب العمل',
  leaving_reason_3: 'فسخ العقد من قبل صاحب العمل لحالة في المادة 80',
  leaving_reason_4: 'ترك العامل العمل نتيجة لقوة قاهرة',
  leaving_reason_5: 'إنهاء العاملة عقد العمل خلال 6 أشهر من الزواج أو خلال 3 أشهر من الوضع',
  leaving_reason_6: 'ترك العامل العمل لأحد الأسباب الواردة في المادة 81',
  leaving_reason_7: 'فسخ العقد من قبل العامل لغير الحالات الواردة في المادة 81',
  leaving_reason_8: 'إستقالة الموظف',
  // Short week offs
  sun: 'الأحد',
  mon: 'الإثنين',
  tue: 'الثلاثاء',
  wed: 'الاربعاء',
  thu: 'الخميس',
  fri: 'الجمعة',
  sat: 'السبت',
  // Shift types list
  split: 'مقسمة',
  flexible: 'مرنة',
  one_punch: 'بصمة واحدة',
  // Full week days
  sunday: 'الأحد',
  monday: 'الإثنين',
  tuesday: 'الثلاثاء',
  wednesday: 'الاربعاء',
  thursday: 'الخميس',
  friday: 'الجمعة',
  saturday: 'السبت',
  // Months Name
  january: 'يناير',
  february: 'فبراير',
  march: 'مارس',
  april: 'ابريل',
  may: 'مايو',
  june: 'يونيو',
  july: 'يوليو',
  august: 'أغسطس',
  september: 'سبتمبر',
  october: 'أكتوبر',
  november: 'نوفمبر',
  december: 'ديسمبر',
  // Cary forward types
  no_carry: 'لا يوجد ترحيل',
  limited_carry: 'ترحيل رصيد محدد',
  carry_all: 'ترحيل كل الرصيد',
  // Segment by
  department: 'القسم',
  location: 'الموقع',
  job_title: 'المسمى الوظيفي',
  gender: 'الجنس',
  nationality: 'الجنسية',
  // view by
  stack: 'عدد الموظفين',

  // Xero Configurations
  bill_per_employee: 'فاتورة لكل موظف',
  grouped_bill: 'فاتورة واحدة مجمعة',

  // Insurance Category
  gold: 'ذهبي',
  silver: 'فضي',
  bronze: 'برونزي',
  staff: 'موظف',
  vip: 'كبار الشخصيات',
}

export default Constants
