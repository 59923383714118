import { Action } from 'types/redux'
import { unionBy } from 'lodash'
import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'
import { InitialStateType, TPage } from './type'

const initialState: InitialStateType = {
  fetching: false,
  organization_page_steps: [],
  page_groups: [],
}

const updatePageGroupData = (oldData: TPage[], newData: TPage[]): TPage[] =>
  unionBy(newData, oldData, 'name')

export default function reducer(state = initialState, action: Action): InitialStateType {
  const { payload } = action
  switch (action.type) {
    case actions.ONBOARDING_STEPS_LIST_PENDING:
    case actions.ONBOARDING_COMPLETE_STEP_POST_PENDING:
    case actions.ONBOARDING_MARK_AS_VISITED_PUT_PENDING:
    case actions.ONBOARDING_FETCH_BY_GROUPS_LIST_PENDING:
    case actions.ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_FETCH_BY_GROUPS_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        page_groups: updatePageGroupData(state.page_groups, payload.data.data?.page_groups ?? []),
        current_page_group: payload.data.data?.current_page_group,
      }

    case actions.ONBOARDING_STEPS_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        pagination: payload.data.data?.pagination,
        organization_page_steps: payload.data.data?.organization_page_steps ?? [],
      }

    case actions.ONBOARDING_MARK_AS_VISITED_PUT_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_FULFILLED:
      return {
        ...state,
        fetching: false,
        completed_tour: payload.data.data?.completed_tour,
      }

    case actions.ONBOARDING_COMPLETE_STEP_POST_FULFILLED: {
      const pageGroup = state.page_groups.find(
        (p) => p.name === payload.data?.data?.page_group_name,
      )
      const updatedSteps = pageGroup?.organization_steps.map((step) =>
        step.id === payload.data?.data?.id
          ? {
              ...step,
              name: payload.data?.data?.name,
              completed: payload.data?.data?.completed,
              priority: payload.data?.data?.priority,
              first_visit_at: payload.data?.data?.first_visit_at,
            }
          : step,
      )

      return {
        ...state,
        fetching: false,
        ...(pageGroup && {
          page_groups: updatePageGroupData(state.page_groups, [
            { ...pageGroup, organization_steps: updatedSteps } as TPage,
          ]),
        }),
        ...(payload.data?.data?.page_group_name === 'initial_onboarding' && {
          organization_page_steps: [
            ...state.organization_page_steps?.filter((e) => e.id !== payload.data?.data.id),
            {
              id: payload.data?.data?.id,
              name: payload.data?.data?.name,
              completed: payload.data?.data?.completed,
              priority: payload.data?.data?.priority,
            },
          ],
        }),
      }
    }

    case actions.ONBOARDING_STEPS_LIST_REJECTED:
    case actions.ONBOARDING_COMPLETE_STEP_POST_REJECTED:
    case actions.ONBOARDING_MARK_AS_VISITED_PUT_REJECTED:
    case actions.ONBOARDING_FETCH_BY_GROUPS_LIST_REJECTED:
    case actions.ONBOARDING_ADMINS_COMPLETED_TOUR_PUT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
