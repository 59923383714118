import { useState } from 'react'
import i18n from 'translations/i18n'
import { format } from 'utils/date'
import {
  useAddComment,
  useMarkAnnouncementAsRead,
} from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg'
import { TAnnouncementRecipients } from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg.type'
import { Badge, Textarea } from '@jisr-hr/ds-beta'
import DSIcon from 'components/DSIcon'
import { Button, Flex, Typography } from '@jisr-hr/ds'
import styles from '../../announcements/style.module.css'
import SingleAnnouncementModal from './singleAnnouncementModal'

interface AnnouncementWidgetProps {
  item: TAnnouncementRecipients
  ind: number
  refetch: () => void
  sideDrawer?: boolean
}

const stripHtml = (html: string): string =>
  html
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<style[^>]*>[\s\S]*?<\/style>/g, '')
    .replace(/<\/?\w+/g, '')

const AnnouncementWidget = ({ item, ind, refetch, sideDrawer }: AnnouncementWidgetProps) => {
  const [showWidgetModal, setShowWidgetModal] = useState(false)
  const [showLeaveComment, setShowLeaveComment] = useState(false)
  const [commentText, setCommentText] = useState<string | null>(null)
  const { mutate: markAsRead } = useMarkAnnouncementAsRead()
  const { mutate: addComment } = useAddComment()

  const handleMarkAsRead = () => {
    markAsRead(
      { id: item.id },
      {
        onSuccess: () => {
          refetch()
        },
      },
    )
  }
  const handleLeaveComment = () => {
    addComment(
      {
        comment: {
          commentable_type: 'Announcement',
          commentable_id: item.announcement.id,
          content: commentText,
        },
      },
      {
        onSuccess: () => {
          setShowLeaveComment(false)
          setCommentText('')
        },
      },
    )
  }
  return (
    <>
      <Flex
        key={ind}
        flexCol
        className={styles.containerAnnouncement}
      >
        <div onClick={() => setShowWidgetModal(true)}>
          <Flex
            itemsCenter
            className={styles.colorOfIcon}
            style={{ gap: 12 }}
          >
            <DSIcon name="calendar" />
            <Typography
              text={format(item?.created_at, 'dd MMM, yyyy')}
              variant="caption"
              textColor="color/fg/light"
            />
          </Flex>
          <Flex justifyBetween>
            <Flex>
              <img
                src={item?.announcement?.icon_url}
                alt={item?.announcement?.title}
                width={20}
                height={20}
                className="m-1"
              />
              <Typography
                text={item?.announcement?.title}
                variant="body-new/semibold"
              />
            </Flex>

            <Flex style={{ gap: 12 }}>
              {sideDrawer && item.is_read && (
                <Flex itemsCenter>
                  <DSIcon
                    name="check"
                    size="xs"
                  />
                  <Typography
                    text={i18n.t('title.read')}
                    variant="subtitle-2"
                    textColor="color/fg/lighter"
                  />
                </Flex>
              )}
              <Badge
                label={i18n.t(item?.announcement?.priority_level_i18n)}
                color={item?.announcement?.priority_level_i18n !== 'Normal' ? 'orange' : 'gray'}
                size="small"
              />
            </Flex>
          </Flex>
          <Typography
            text={stripHtml(item?.announcement?.body)}
            variant="subtitle-2"
            textColor="color/fg/light"
            className={styles.bodyText}
          />
        </div>
        <Flex
          justifyBetween
          style={{ gap: 12 }}
        >
          {!item.is_read && (
            <Button
              label={i18n.t('action.mark_as_read')}
              leadingIcon="check"
              variant="tinted"
              fullWidth
              size="small"
              color="primary"
              onClick={() => {
                handleMarkAsRead()
              }}
            />
          )}
          {item.announcement?.allow_comment && (
            <Button
              label={i18n.t('action.leave_comment')}
              leadingIcon="message-square-02"
              variant="tinted"
              color="neutral"
              fullWidth
              size="small"
              onClick={() => {
                setShowLeaveComment(true)
              }}
            />
          )}
        </Flex>
        {showLeaveComment && (
          <Flex
            flexCol
            style={{ gap: 12 }}
          >
            <Textarea
              onChange={(value) => setCommentText(value)}
              value={commentText || ''}
              height={38}
              resizable
            />
            <Flex
              justifyBetween
              style={{ gap: 12 }}
            >
              <Button
                label={i18n.t('cancel')}
                color="neutral"
                variant="outlined"
                size="small"
                onClick={() => setShowLeaveComment(false)}
                fullWidth
              />
              <Button
                label={i18n.t('action.publish_comment')}
                color="primary"
                variant="filled"
                size="small"
                fullWidth
                disabled={!commentText || commentText.replace(/ /g, '') === ''}
                onClick={() => handleLeaveComment()}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      {showWidgetModal && (
        <SingleAnnouncementModal
          announcementId={item.id}
          isOpen={showWidgetModal}
          onClose={() => setShowWidgetModal(false)}
        />
      )}
    </>
  )
}
export default AnnouncementWidget
