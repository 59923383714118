import { Typography, Button, Flex } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import DSIcon from 'components/DSIcon'
import { OnboardingAdminType } from 'redux/organizationOnborading/inviteAdmins/type'
import styles from '../styles.module.css'

type TProps = {
  fetching: boolean
  list: OnboardingAdminType[]
  onClick: (values: OnboardingAdminType) => void
}

const InvitationsFailed = (props: TProps): JSX.Element => {
  const { list, fetching } = props
  return (
    <Flex
      flexCol
      style={{ gap: 10, maxHeight: 140, overflowY: 'auto' }}
    >
      {list.map((failed, key) => (
        <Flex
          key={key}
          itemsCenter
          justifyBetween
          className={styles.invitationFailed}
        >
          <Flex style={{ gap: 8 }}>
            <DSIcon
              size="md"
              name="x-circle"
              color="var(--ds-core-color-error-500)"
            />
            <Flex style={{ gap: 2 }}>
              <Typography
                text={failed.email}
                variant="body-new/semibold"
              />
              <Typography
                text={i18n.t('label.employee_email_invitation_failed', {
                  emloyee_email: failed.email,
                })}
                variant="body-new/semibold"
                textColor="color/fg/light"
              />
            </Flex>
          </Flex>
          <Button
            size="small"
            type="button"
            variant="ghost"
            disabled={fetching}
            label={i18n.t('label.re-invite')}
            onClick={(): void => props.onClick(failed)}
          />
        </Flex>
      ))}
    </Flex>
  )
}

export default InvitationsFailed
