import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type StatusTypes = {
  filterKey: string
  filters: string[]
}

const ErrorStatus = (props: StatusTypes): JSX.Element => {
  const { error_status } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      componentType="badges"
      handleFilter={handleFilter}
      options={error_status}
      labelKey="name"
      valueKey="id"
      selectedIds={filter[props.filterKey]}
      {...props}
      title="label.status"
    />
  )
}

export default ErrorStatus
