import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ReviewerProps = {
  filterKey: string
  filters: Array<string>
}

const Reviewer = (props: ReviewerProps): JSX.Element => {
  const { reviewer_ids } = useFilterAPI()
  const { filter, handleFilter } = useFilters()

  return (
    <FilterBox
      handleFilter={handleFilter}
      options={reviewer_ids}
      selectedIds={filter[props.filterKey]}
      {...props}
    />
  )
}

export default Reviewer
