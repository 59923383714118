import React from 'react'
import I18n from 'translations/i18n'
import { Flex } from '@jisr-hr/ds'
import { DatePickerDSField, SelectField, TextInputField } from 'components/final-form'
import { composeValidators, required, time } from 'components/global/form/FormValidations'
import { useSelector } from 'react-redux'
import { addDays, eachDayOfInterval, format } from 'date-fns'
import { useForm, useFormState } from 'react-final-form'
import { timeFormat } from 'components/global/form/formNormalize'
import IncreaseDecreaseField from 'components/global/templates/RequestDrawer/ds/IncreaseDecreaseField'

import Frame from '../../../ds/Frame'

const overtimeFor = [
  { value: 1, label: I18n.t('single_day') },
  { value: 2, label: I18n.t('multiple_days') },
]

const hourRate = [
  { value: 1.5, label: I18n.t('single_overtime') },
  { value: 2, label: I18n.t('double_overtime') },
]

const OvertimeRequestForm = () => {
  const { change } = useForm()
  const { values } = useFormState()
  const { employee_record } = useSelector(({ timeAttendance }) => timeAttendance)
  const showHourRate = employee_record?.overtime_policy?.is_double_overtime

  const handleRangeDate = (from, to) => {
    if (from && to) {
      const range = eachDayOfInterval({
        start: new Date(from),
        end: new Date(to),
      })
      const days = range.map((day) => ({ date: format(day, 'yyyy-MM-dd') }))
      change('overtime_logs_attributes', days)
    }
  }

  return (
    <Frame title={I18n.t('overtime_duration')}>
      <div className="grid grid-cols-12 gap-4">
        <div className={showHourRate ? 'col-span-6' : 'col-span-12'}>
          <SelectField
            name="overtime_for"
            label={I18n.t('type')}
            options={overtimeFor}
            validate={required}
          />
        </div>
        {showHourRate && (
          <div className="col-span-6">
            <SelectField
              name="hour_rate"
              label={I18n.t('hourly_rate')}
              options={hourRate}
              validate={required}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <DatePickerDSField
            name="from_date"
            label={values?.overtime_for === 1 ? I18n.t('date') : I18n.t('from_date')}
            validate={required}
            calenderProps={{
              minDate: addDays(new Date(), -59),
            }}
            textFieldProps={{
              labelIconTooltip: I18n.t('overtime_date_cant_be_before_days'),
            }}
            onChange={() => {
              change('overtime_logs_attributes', [])
              change('to_date', null)
            }}
          />
        </div>
        <div className="col-span-6">
          {values?.overtime_for === 1 ? (
            <IncreaseDecreaseField
              placeholder="HH:MM"
              label={I18n.t('overtime_alt_translation')}
              validate={composeValidators(required, time)}
              minValue={1}
              name="expected_hours"
              testId="expected-hours"
            />
          ) : (
            <DatePickerDSField
              label={I18n.t('to_date')}
              name="to_date"
              validate={required}
              onChange={(val) => handleRangeDate(values.from_date, val)}
              textFieldProps={{
                disabled: !values?.from_date,
              }}
              calenderProps={{
                minDate: values?.from_date ? addDays(new Date(values?.from_date), 1) : undefined,
              }}
              value={values?.to_date ? new Date(values?.to_date) : undefined}
            />
          )}
        </div>
      </div>
      {values?.overtime_for === 2 &&
        values?.overtime_logs_attributes?.map((log, i) => (
          <div
            className="grid grid-cols-12 gap-4"
            key={i}
          >
            <div className="col-span-6">
              <Flex
                style={{
                  alignItems: 'flex-end',
                  height: '100%',
                }}
              >
                <span
                  style={{
                    padding: '10px 0',
                    fontWeight: 500,
                  }}
                >
                  {log.date}
                </span>
              </Flex>
            </div>
            <div className="col-span-6">
              <TextInputField
                placeholder="HH:MM"
                label={I18n.t('expected_hours')}
                name={`overtime_logs_attributes[${i}].expected_hours`}
                parse={timeFormat}
                validate={required}
              />
            </div>
          </div>
        ))}
    </Frame>
  )
}

export default OvertimeRequestForm
