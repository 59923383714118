import { useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { useDispatch, useSelector } from 'utils/hooks'
import { Modal } from '@jisr-hr/design-system'
import { Typography, Button, Spacer, Flex } from '@jisr-hr/ds'
import { OnboardingContext } from 'containers/public/Onboarding/context'
import { ratingHelpCenterVideos } from 'redux/organizationOnborading/onboardingHelpCentre/actionCreators'
import { TRating } from 'redux/organizationOnborading/trainingVideos/type'
import styles from './styles.module.css'

type TProps = {
  step_name: string
}

const PlayVideo = ({ step_name }: TProps): JSX.Element => {
  const dispatch = useDispatch()
  const { org } = useContext(OnboardingContext)
  const [openVideo, setOpenVideo] = useState(false)
  const [listViewed, setListViewed] = useState<string[]>([])
  const { training_video, fetching } = useSelector((state) => state.onboardingHelpCentre)

  const handleOnClick = (values: TRating): void => {
    dispatch(ratingHelpCenterVideos(step_name, values))
  }

  useEffect(() => {
    const viewed = localStorage.getItem(`videos_have_viewed_${org.id}`)
    if (viewed) {
      setListViewed(JSON.parse(viewed))
    }
  }, [])

  return (
    <>
      <Flex
        itemsCenter
        justifyCenter
        className={styles.videoContent}
        onClick={(): void => {
          const newList = uniq([...listViewed, step_name])
          setOpenVideo(true)
          setTimeout(() => {
            setListViewed(newList)
            localStorage.setItem(`videos_have_viewed_${org.id}`, JSON.stringify(newList))
          }, 3000)
        }}
      />
      <Spacer height={16} />

      <Typography
        textColor="color/fg/bold"
        text={training_video?.name_i18n}
        variant="interface/medium/sm"
      />

      <Spacer height={8} />
      <Modal
        isOpen={openVideo}
        height="fit-content"
        variant="centerDialog"
        modalContentClassName={styles.modalContent}
        onClose={(): void => setOpenVideo(false)}
        content={
          <>
            <video
              controls
              autoPlay
              className={styles.videoPlayer}
            >
              <source
                type="video/mp4"
                src={training_video?.s3_url}
              />
              <track
                src=""
                kind="captions"
              />
            </video>
          </>
        }
      />
      {(training_video?.answer?.option?.id || listViewed?.includes(step_name)) && (
        <>
          <Typography
            variant="body-new/medium"
            text={training_video?.question?.name_i18n}
            style={{ color: 'var(--ds-base-color-actionable-secondary-fg)' }}
          />
          <Spacer height={8} />
          <Flex style={{ gap: '8px' }}>
            {training_video?.question?.options?.map((e, i) => (
              <Button
                key={i}
                size="medium"
                label={e.name_i18n}
                disabled={fetching}
                onClick={(): void =>
                  handleOnClick({
                    option_id: e.id,
                    question_id: training_video?.question?.id,
                  })
                }
                style={{ padding: 4 }}
                color={training_video?.answer?.option?.id === e.id ? 'primary' : 'neutral'}
                variant="ghost"
                leadingIcon={e.name_en === 'Yes' ? 'thumbs-up' : 'thumbs-down'}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  )
}

export default PlayVideo
