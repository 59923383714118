export const HOURLY_RATE_POLICIES_LOAD = 'HOURLY_RATE_POLICIES_LOAD'
export const HOURLY_RATE_POLICIES_LOAD_PENDING = 'HOURLY_RATE_POLICIES_LOAD_PENDING'
export const HOURLY_RATE_POLICIES_LOAD_FULFILLED = 'HOURLY_RATE_POLICIES_LOAD_FULFILLED'
export const HOURLY_RATE_POLICIES_LOAD_REJECTED = 'HOURLY_RATE_POLICIES_LOAD_REJECTED'

export const HOURLY_RATE_POLICY_LOAD = 'HOURLY_RATE_POLICY_LOAD'
export const HOURLY_RATE_POLICY_LOAD_PENDING = 'HOURLY_RATE_POLICY_LOAD_PENDING'
export const HOURLY_RATE_POLICY_LOAD_FULFILLED = 'HOURLY_RATE_POLICY_LOAD_FULFILLED'
export const HOURLY_RATE_POLICY_LOAD_REJECTED = 'HOURLY_RATE_POLICY_LOAD_REJECTED'

export const HOURLY_RATE_POLICY_CREATE = 'HOURLY_RATE_POLICY_CREATE'
export const HOURLY_RATE_POLICY_CREATE_PENDING = 'HOURLY_RATE_POLICY_CREATE_PENDING'
export const HOURLY_RATE_POLICY_CREATE_FULFILLED = 'HOURLY_RATE_POLICY_CREATE_FULFILLED'
export const HOURLY_RATE_POLICY_CREATE_REJECTED = 'HOURLY_RATE_POLICY_CREATE_REJECTED'

export const HOURLY_RATE_POLICY_UPDATE = 'HOURLY_RATE_POLICY_UPDATE'
export const HOURLY_RATE_POLICY_UPDATE_PENDING = 'HOURLY_RATE_POLICY_UPDATE_PENDING'
export const HOURLY_RATE_POLICY_UPDATE_FULFILLED = 'HOURLY_RATE_POLICY_UPDATE_FULFILLED'
export const HOURLY_RATE_POLICY_UPDATE_REJECTED = 'HOURLY_RATE_POLICY_UPDATE_REJECTED'

export const HOURLY_RATE_POLICY_DELETE = 'HOURLY_RATE_POLICY_DELETE'
export const HOURLY_RATE_POLICY_DELETE_FULFILLED = 'HOURLY_RATE_POLICY_DELETE_FULFILLED'
export const HOURLY_RATE_POLICY_DELETE_REJECTED = 'HOURLY_RATE_POLICY_DELETE_REJECTED'
