import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'

const DEFAULT_STATE = {
  fetching: false,
  requests_approvers_list: [],
}

let changes = null
export default function requestsApproversListReducer(state = DEFAULT_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case 'REQUESTS_APPROVERS_LIST_RESET':
      changes = {
        requests_approvers_list: [],
      }
      return { ...state, ...changes }

    case 'REQUESTS_APPROVERS_LIST_LOAD_PENDING':
      changes = {
        fetching: true,
      }
      return { ...state, ...changes }

    case 'REQUESTS_APPROVERS_LIST_LOAD_FULFILLED':
      changes = {
        requests_approvers_list: payload.data.data.approvers,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'REQUESTS_APPROVERS_LIST_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        fetching: false,
      }
      return { ...state, ...changes }

    default:
      return state
  }
}
