import I18n from 'translations/i18n'

export const reportMethodIcons = {
  biometric: 'fingerprint-03',
  geofencing: 'phone-02',
  corrected: 'edit-02',
  manual: 'edit-02',
  bulk_upload: 'edit-02',
}

export const correctTypes = [
  { value: 'existing', label: I18n.t('select_from_existing_punch') },
  { value: 'manually', label: I18n.t('enter_time_manually') },
]
