import { FC } from 'react'
import { Form, NewSelectField } from 'components/final-form'
// import { required, composeValidators, number } from 'components/global/form/FormValidations'
import { Button, Flex } from '@jisr-hr/ds'
import { useSelector } from 'utils/hooks'
import i18n from 'translations/i18n'
import { usePaymentMethod } from '../../../components/usePaymentMethods'

type VerifyAdminFormType = {
  onSubmit: (arg: { id: number }) => void
  initialValues: { id?: number }
}

const VerifyAdminForm: FC<VerifyAdminFormType> = (props) => {
  const { initialValues } = props
  const paymentCtx = usePaymentMethod()
  const employees = useSelector((s) => s.mudadIntegration.verification_employees)
  const fetching = useSelector((s) => s.mudadIntegration.fetching)

  const { currentSignatory } = paymentCtx

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, valid, pristine }): JSX.Element => {
        return (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ maxWidth: 600 }}
          >
            <Flex
              itemsStart
              style={{ gap: 24 }}
            >
              <NewSelectField
                name="id"
                label={i18n.t('mudad_authorized_employee')}
                valueKey="id"
                labelKey="full_name_i18n"
                maxMenuHeight={300}
                options={currentSignatory ? [currentSignatory.employee, ...employees] : employees}
              />
              <Button
                label={i18n.t('verify')}
                type="submit"
                size="medium"
                disabled={fetching || pristine || !valid}
                style={{ marginTop: 24, flexShrink: 0 }}
              />
            </Flex>
          </form>
        )
      }}
    </Form>
  )
}

export default VerifyAdminForm
