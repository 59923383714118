import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'
import * as actions from './actions'

const initialState = {
  fetching: false,
  organization_structure_subordinates: [],
}

type OrgStructureSubordinatesState = typeof initialState

export default function reducer(
  state = initialState,
  action: Action,
): OrgStructureSubordinatesState {
  const { payload } = action
  switch (action.type) {
    case actions.ORGNIZTION_STRUCTURE_LOAD_FULFILLED:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...payload.data.data,
        fetching: false,
      }

    case actions.ORGNIZTION_STRUCTURE_CHANGE_FULFILLED:
    case actions.ORGNIZTION_STRUCTURE_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ORGNIZTION_STRUCTURE_LOAD_REJECTED:
    case actions.ORGNIZTION_STRUCTURE_DELETE_REJECTED:
    case actions.ORGNIZTION_STRUCTURE_CHANGE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
