import { Grid } from '@jisr-hr/ds-beta'
import { Typography } from '@jisr-hr/ds'
import i18n from 'translations/i18n'
import { BetaTextInputField as TextInputField } from 'components/final-form'
import { Field, useForm, useFormState } from 'react-final-form'
import {
  composeValidators,
  maxLength,
  minLength,
  naturalNumber,
} from 'components/global/form/FormValidations'
import { renderFieldMessages } from 'containers/authorised/employee/EmployeeDetails/helper'
import { useSelector } from 'utils/hooks'

export const EmergencyContact = () => {
  const { values } = useFormState()
  const form = useForm()

  const gridItemProps = {
    item: true,
    columnSpacing: 3,
  }
  const {
    employee: { pending_changes },
  } = useSelector(({ employee }) => employee)
  const countries = useSelector((s) => s.countriesv2.countries)

  const pendingChanges = pending_changes?.changeset?.emergency_contact ?? {}
  const emergencyKeys = 'emergency_contact'

  return (
    <div className="flex flex-col gap-8">
      <Typography
        text={i18n.t('emergency_contact')}
        variant="title-1"
      />
      <Grid
        container
        rowSpacing={20}
      >
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${emergencyKeys}.name`}
            label={i18n.t('name')}
            testId="name-in-english-field"
            helperText={renderFieldMessages('name', pendingChanges) || undefined}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${emergencyKeys}.relationship`}
            label={i18n.t('relationship')}
            testId="relationship-field"
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${emergencyKeys}.address`}
            label={i18n.t('address')}
            testId="address-field"
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextInputField
            name={`${emergencyKeys}.telephone`}
            label={i18n.t('telephone')}
            validate={composeValidators(naturalNumber, minLength(7), maxLength(12))}
            testId="telephone-field"
            value={values?.[emergencyKeys]?.telephone}
            as="dropdown"
            listItems={countries}
            dropdownValue={values?.[emergencyKeys]?.country_phone_code}
            dropdownValueKey="phone_code"
            dropdownLabelKey="phone_code"
            dropdownSublabelKey="name_i18n"
            onDropdownChange={(value: string, dropdownValue?: string): void => {
              form.change(`${emergencyKeys}.telephone`, value)
              form.change(`${emergencyKeys}.country_phone_code`, dropdownValue)
            }}
          />
          <Field
            component={() => null}
            name={`${emergencyKeys}.country_phone_code`}
          />
        </Grid>
      </Grid>
    </div>
  )
}
