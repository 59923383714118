import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type NextPayroll = {
  next_payrun: {
    cut_off_from_date: string
    cut_off_to_date: string
    id: number
    pay_period_end: string
    pay_period_start: string
  } | null
}

const initialState = {
  data: {} as NextPayroll,
  fetching: false,
}
type RequestsState = typeof initialState

export default function reducer(state = initialState, action: Action): RequestsState {
  const { payload } = action
  switch (action.type) {
    case actions.NEXT_PAYROLL_LOAD_FULFILLED:
      return {
        ...state,
        data: payload.data.data,
        fetching: false,
      }

    case actions.NEXT_PAYROLL_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.NEXT_PAYROLL_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
