export const ELIGIBLE_EMPLOYESS_LOAD = 'ELIGIBLE_EMPLOYESS_LOAD'
export const ELIGIBLE_EMPLOYESS_LOAD_PENDING = 'ELIGIBLE_EMPLOYESS_LOAD_PENDING'
export const ELIGIBLE_EMPLOYESS_LOAD_FULFILLED = 'ELIGIBLE_EMPLOYESS_LOAD_FULFILLED'
export const ELIGIBLE_EMPLOYESS_LOAD_REJECTED = 'ELIGIBLE_EMPLOYESS_LOAD_REJECTED'

export const FILTER_ELIGIBLE_EMPLOYESS_LOAD = 'FILTER_ELIGIBLE_EMPLOYESS_LOAD'
export const FILTER_ELIGIBLE_EMPLOYESS_LOAD_PENDING = 'FILTER_ELIGIBLE_EMPLOYESS_LOAD_PENDING'
export const FILTER_ELIGIBLE_EMPLOYESS_LOAD_FULFILLED = 'FILTER_ELIGIBLE_EMPLOYESS_LOAD_FULFILLED'
export const FILTER_ELIGIBLE_EMPLOYESS_LOAD_REJECTED = 'FILTER_ELIGIBLE_EMPLOYESS_LOAD_REJECTED'

export const GET_ELIGIBLE_ALL_EMPLOYEES_LOAD = 'GET_ELIGIBLE_ALL_EMPLOYEES_LOAD'
export const GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_PENDING = 'GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_PENDING'
export const GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_FULFILLED = 'GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_FULFILLED'
export const GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_REJECTED = 'GET_ELIGIBLE_ALL_EMPLOYEES_LOAD_REJECTED'

export const SELECT_USER = 'SELECT_USER'
export const UNSELECT_USER = 'UNSELECT_USER'
export const CLEAR_ELIGIBLE_DATA = 'CLEAR_ELIGIBLE_DATA'
export const SET_SELECTED = 'SET_SELECTED'
export const RESET_USERS = 'RESET_USERS'
