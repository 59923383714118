import { Toastr } from 'components/global/Toastr'
import { handleResponseErr } from 'utils/apiHelperUtils'
import { Action } from 'types/redux'

import * as actions from './actions'
import { TInitialStatePaymentReview } from './type'

const initialState: TInitialStatePaymentReview = {
  fetching: false,
  payment_details: [],
  statistics: {},
  pagination: {},
  banks: [],
}

export default function reducer(state = initialState, action: Action): TInitialStatePaymentReview {
  const { payload, type } = action

  switch (type) {
    case actions.ONBOARDING_PAYMENT_DETAILS_LOAD_PENDING:
    case actions.ONBOARDING_BANKS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ONBOARDING_PAYMENT_DETAILS_LOAD_FULFILLED: {
      const { payment_details, pagination, statistics } = payload.data.data
      return {
        ...state,
        fetching: false,
        statistics,
        payment_details: [
          ...new Set([
            ...(pagination?.previous_page ? Array.from(state?.payment_details) : []),
            ...payment_details,
          ]),
        ],
        pagination,
      }
    }

    case actions.ONBOARDING_BANKS_LOAD_FULFILLED:
      return {
        ...state,
        fetching: false,
        banks: payload.data.data.banks,
      }

    case actions.ONBOARDING_BANKS_LOAD_REJECTED:
    case actions.ONBOARDING_PAYMENT_DETAILS_LOAD_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }
    }

    case actions.ONBOARDING_PAYMENT_DETAILS_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.ONBOARDING_PAYMENT_DETAILS_UPDATE_FULFILLED:
      return {
        ...state,
        loading: false,
      }

    case actions.ONBOARDING_PAYMENT_DETAILS_UPDATE_REJECTED: {
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
