import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { updateOneListOfRecords, removeOneListOfRecords } from 'components/global/HelperFunctions'
import { Action } from 'types/redux'

export type CompanyDocumentData = {
  description: string
  document_number: string
  expiry_date: string
  file: string
  file_file_name: {
    file_file_name: string
  }
  file_file_size?: number
  folder?: string
  id: number
  include_in_employees_profile: boolean
  name: string
}

type State = {
  company_documents: CompanyDocumentData[]
  editing:
    | {
        id: string | number
        New: boolean
      }
    | Record<string, unknown>
  errMsg: null | string
  succMsg: null | string
  fetching: boolean
  error?: null | string
}

const DEFAULT_STATE: State = {
  company_documents: [],
  editing: {},
  errMsg: null,
  succMsg: null,
  fetching: false,
}

type ActionType = Action & {
  id: string
}

export type CompanyDocumentType = Omit<CompanyDocumentData, 'file' | 'file_file_name'> & {
  file: {
    data: string
    filename: string
  }
}

type RequestsState = typeof DEFAULT_STATE

let changes = null
export default function jobTitleReducer(state = DEFAULT_STATE, action: ActionType): RequestsState {
  const { payload } = action
  let jobTitles = []
  let newRecord = {}
  let updatedRecord: { id: string } = {
    id: '',
  }
  switch (action.type) {
    case 'COMPANY_DOCUMENTS_LOAD_PENDING':
    case 'COMPANY_DOCUMENT_CREATE_PENDING':
    case 'COMPANY_DOCUMENT_UPDATE_PENDING':
    case 'COMPANY_DOCUMENT_DELETE_PENDING':
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case 'COMPANY_DOCUMENTS_LOAD_REJECTED':
    case 'COMPANY_DOCUMENT_CREATE_REJECTED':
    case 'COMPANY_DOCUMENT_UPDATE_REJECTED':
    case 'COMPANY_DOCUMENT_DELETE_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      changes = {
        errMsg: handleResponseErr(payload),
        fetching: false,
      }
      return { ...state, ...changes }

    case 'COMPANY_DOCUMENTS_LOAD_FULFILLED':
      changes = {
        company_documents: payload.data.data.company_attachments,
        succMsg: payload.data.message,
        errMsg: null,
        fetching: false,
      }
      return { ...state, ...changes }

    case 'COMPANY_DOCUMENT_CREATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      newRecord = payload.data.data.company_attachment
      changes = {
        company_documents: [...state.company_documents, newRecord] as CompanyDocumentData[],
        editing: {
          ...state.editing,
          New: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'COMPANY_DOCUMENT_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      jobTitles = [...state.company_documents]
      updatedRecord = payload.data.data.company_attachment
      changes = {
        company_documents: updateOneListOfRecords(jobTitles, updatedRecord),
        editing: {
          ...state.editing,
          [updatedRecord.id]: false,
        },
        fetching: false,
      }
      return { ...state, ...changes }

    case 'COMPANY_DOCUMENT_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      jobTitles = [...state.company_documents]
      return {
        ...state,
        company_documents: removeOneListOfRecords(jobTitles, payload.id),
        error: null,
        fetching: false,
      }

    default:
      return state
  }
}
