export const ONBOARDING_COUNTING_DAYS_LOAD = 'ONBOARDING_COUNTING_DAYS_LOAD'
export const ONBOARDING_COUNTING_DAYS_LOAD_PENDING = 'ONBOARDING_COUNTING_DAYS_LOAD_PENDING'
export const ONBOARDING_COUNTING_DAYS_LOAD_FULFILLED = 'ONBOARDING_COUNTING_DAYS_LOAD_FULFILLED'
export const ONBOARDING_COUNTING_DAYS_LOAD_REJECTED = 'ONBOARDING_COUNTING_DAYS_LOAD_REJECTED'

export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_PENDING =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_PENDING'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_FULFILLED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_FULFILLED'
export const ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_REJECTED =
  'ONBOARDING_ANNUAL_LEAVE_ENTITLEMENT_AUTO_CONVERT_LOAD_REJECTED'

export const ONBOARDING_COUNTING_DAYS_UPDATE = 'ONBOARDING_COUNTING_DAYS_UPDATE'
export const ONBOARDING_COUNTING_DAYS_UPDATE_PENDING = 'ONBOARDING_COUNTING_DAYS_UPDATE_PENDING'
export const ONBOARDING_COUNTING_DAYS_UPDATE_FULFILLED = 'ONBOARDING_COUNTING_DAYS_UPDATE_FULFILLED'
export const ONBOARDING_COUNTING_DAYS_UPDATE_REJECTED = 'ONBOARDING_COUNTING_DAYS_UPDATE_REJECTED'
