export const NETSUIT_CONNECT_ACCOUNT = 'NETSUIT_CONNECT_ACCOUNT'
export const NETSUIT_CONNECT_ACCOUNT_FULFILLED = 'NETSUIT_CONNECT_ACCOUNT_FULFILLED'
export const NETSUIT_CONNECT_ACCOUNT_REJECTED = 'NETSUIT_CONNECT_ACCOUNT_REJECTED'
export const NETSUIT_CONNECT_ACCOUNT_PENDING = 'NETSUIT_CONNECT_ACCOUNT_PENDING'

export const NETSUIT_DISCONNECT_ACCOUNT = 'NETSUIT_DISCONNECT_ACCOUNT'
export const NETSUIT_DISCONNECT_ACCOUNT_FULFILLED = 'NETSUIT_DISCONNECT_ACCOUNT_FULFILLED'
export const NETSUIT_DISCONNECT_ACCOUNT_REJECTED = 'NETSUIT_DISCONNECT_ACCOUNT_REJECTED'
export const NETSUIT_DISCONNECT_ACCOUNT_PENDING = 'NETSUIT_DISCONNECT_ACCOUNT_PENDING'

export const NETSUIT_LOAD_ACCOUNT_INFO = 'NETSUIT_LOAD_ACCOUNT_INFO'
export const NETSUIT_LOAD_ACCOUNT_INFO_FULFILLED = 'NETSUIT_LOAD_ACCOUNT_INFO_FULFILLED'
export const NETSUIT_LOAD_ACCOUNT_INFO_REJECTED = 'NETSUIT_LOAD_ACCOUNT_INFO_REJECTED'
export const NETSUIT_LOAD_ACCOUNT_INFO_PENDING = 'NETSUIT_LOAD_ACCOUNT_INFO_PENDING'

export const NETSUIT_LOAD_ACCOUNT_STATUS = 'NETSUIT_LOAD_ACCOUNT_STATUS'
export const NETSUIT_LOAD_ACCOUNT_STATUS_FULFILLED = 'NETSUIT_LOAD_ACCOUNT_STATUS_FULFILLED'
export const NETSUIT_LOAD_ACCOUNT_STATUS_REJECTED = 'NETSUIT_LOAD_ACCOUNT_STATUS_REJECTED'
export const NETSUIT_LOAD_ACCOUNT_STATUS_PENDING = 'NETSUIT_LOAD_ACCOUNT_STATUS_PENDING'

export const EXPORT_NETSUITE_JOURNALS = 'EXPORT_NETSUITE_JOURNALS'
export const EXPORT_NETSUITE_JOURNALS_PENDING = 'EXPORT_NETSUITE_JOURNALS_PENDING'
export const EXPORT_NETSUITE_JOURNALS_FULFILLED = 'EXPORT_NETSUITE_JOURNALS_FULFILLED'
export const EXPORT_NETSUITE_JOURNALS_REJECTED = 'EXPORT_NETSUITE_JOURNALS_REJECTED'

export const NETSUITE_ONBOARDING_STATUS = 'NETSUITE_ONBOARDING_STATUS'
export const NETSUITE_ONBOARDING_STATUS_PENDING = 'NETSUITE_ONBOARDING_STATUS_PENDING'
export const NETSUITE_ONBOARDING_STATUS_FULFILLED = 'NETSUITE_ONBOARDING_STATUS_FULFILLED'
export const NETSUITE_ONBOARDING_STATUS_REJECTED = 'NETSUITE_ONBOARDING_STATUS_REJECTED'

export const ACTIVATE_JOURNAL_ACCOUNT = 'ACTIVATE_JOURNAL_ACCOUNT'
export const ACTIVATE_JOURNAL_ACCOUNT_PENDING = 'ACTIVATE_JOURNAL_ACCOUNT_PENDING'
export const ACTIVATE_JOURNAL_ACCOUNT_FULFILLED = 'ACTIVATE_JOURNAL_ACCOUNT_FULFILLED'
export const ACTIVATE_JOURNAL_ACCOUNT_REJECTED = 'ACTIVATE_JOURNAL_ACCOUNT_REJECTED'

export const SYNC_NETSUITE_EMPLOYEE = 'SYNC_NETSUITE_EMPLOYEE'
export const SYNC_NETSUITE_EMPLOYEE_PENDING = 'SYNC_NETSUITE_EMPLOYEE_PENDING'
export const SYNC_NETSUITE_EMPLOYEE_FULFILLED = 'SYNC_NETSUITE_EMPLOYEE_FULFILLED'
export const SYNC_NETSUITE_EMPLOYEE_REJECTED = 'SYNC_NETSUITE_EMPLOYEE_REJECTED'
