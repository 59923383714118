import i18n from 'translations/i18n'
import { mixPanelAnalytics } from 'utils/mixpanel'

/* eslint-disable no-shadow */
export enum OnboardingTier {
  HIGH_TIER = 'high_tier',
  LOW_TIER = 'low_tier',
}

export enum Statuses {
  LIVE = 'live',
  NEW_ACCOUNT = 'new_account',
  ONBOARDING_PHASE = 'onboarding_phase',
}

export enum OnboardingSteps {
  INITIAL_ONBOARDING = 'initial_onboarding',
  EMPLOYEE_DATA = 'onboarding_employees_data',
  ORGANIZATION_STRUCTURE = 'onboarding_organization_structure',
}

export enum OrganizationSteps {
  UPLOADER_OWN_YOUR_SHEET = 'uploader_own_your_sheet',
  MAP_MATCHING_FILE = 'map_matching_file',
  EMPLOYEES_DATA = 'employees_data',
}

export const onboardingEventClickBack = (destination: string) => {
  mixPanelAnalytics({
    event_name: 'Onboarding Back',
    properties: {
      language: i18n.language,
      destination_page_name: destination,
      source_page_name: window.location.hash,
    },
  })
}
