import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import I18n from 'translations/i18n'
import { Button } from '@jisr-hr/ds'
import { isRtl } from 'utils/uiHelpers'

const Paginate = ({ currentPage, totalEntries, rowsPerPage, handleChange, fetching, testId }) => {
  const range = _.range(1, totalEntries / rowsPerPage + 1)
  const visiblePages = [0, range.length - 1]

  const candidates = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ]

  candidates.forEach((index) => {
    if (visiblePages.length < 5 && visiblePages.indexOf(index) === -1 && range.includes(index)) {
      visiblePages.push(index)
    }
  })
  const isLastStep = currentPage === Math.ceil(totalEntries / rowsPerPage)
  return (
    <div className="tbl--pagination">
      <ul>
        <Button
          onClick={() => currentPage > 1 && handleChange(currentPage - 1, 'backBtn')}
          size="small"
          className={classNames(
            currentPage <= 1 && 'disabled',
            isRtl ? 'borderBack' : 'borderNext',
          )}
          disabled={currentPage <= 1 || fetching}
          label={I18n.t('back')}
          testId={`${testId}-back`}
          variant="outlined"
          color="neutral"
          leadingIcon={isRtl ? 'arrow-right' : 'arrow-left'}
        />
        {range
          .filter((v, i) => visiblePages.includes(i))
          .map((number) => (
            <Button
              color={currentPage === number ? 'primary' : 'neutral'}
              variant={currentPage === number ? 'filled' : 'outlined'}
              onClick={() => handleChange(number, 'pageBtn')}
              key={number}
              size="small"
              label={number}
              className={classNames('paginate-item', currentPage === number && 'active')}
              styles={{
                borderRadius: 0,
                backgroundColor:
                  currentPage === number && 'var(--ds-comp-button-primary-color-default-bg)',
              }}
              disabled={currentPage === number || fetching}
              testId={`${testId}-${number}`}
            />
          ))}
        <Button
          onClick={() =>
            currentPage !== Math.ceil(totalEntries / rowsPerPage) &&
            handleChange(currentPage + 1, 'nextBtn')
          }
          size="small"
          className={classNames(isLastStep && 'disabled', isRtl ? 'borderNext' : 'borderBack')}
          disabled={isLastStep || fetching}
          label={I18n.t('next')}
          testId={`${testId}-next`}
          variant="outlined"
          color="neutral"
          trailingIcon={isRtl ? 'arrow-left' : 'arrow-right'}
        />
      </ul>
    </div>
  )
}

Paginate.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  testId: PropTypes.string,
}

export default Paginate
