export const ONBOARDING_COMMERCIAL_REGISTRATIONS_POST = 'ONBOARDING_COMMERCIAL_REGISTRATIONS_POST'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_PENDING =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_PENDING'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_FULFILLED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_FULFILLED'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_REJECTED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_POST_REJECTED'

export const ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT = 'ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_PENDING =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_PENDING'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_FULFILLED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_FULFILLED'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_REJECTED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_PUT_REJECTED'

export const ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_PENDING =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_PENDING'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_FULFILLED'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_REJECTED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_DELETE_REJECTED'

export const ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW = 'ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_PENDING =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_PENDING'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_FULFILLED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_FULFILLED'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_REJECTED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_SHOW_REJECTED'

export const ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD = 'ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_PENDING =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_PENDING'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_FULFILLED'
export const ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED =
  'ONBOARDING_COMMERCIAL_REGISTRATIONS_LOAD_REJECTED'
