import { Flex } from '@jisr-hr/ds'
import { SelectField } from 'components/final-form'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'utils/hooks'

import {
  createOnboardingProvider,
  providersLoad,
} from 'redux/organizationOnborading/industries/actionCreators'

import I18n from 'translations/i18n'
import {
  organizationInfo,
  updateOrganization,
} from 'redux/organizationOnborading/organization/actionCreators'
import TextInputOther from './TextInputOther'

type providers = {
  accounting_system_provider_id?: number
  point_of_sale_provider_id?: number
  organization_medical_insurance_provider_attributes?: {
    medical_insurance_provider_id: number
  }
}

const other = {
  id: 'other',
  name: 'other',
  name_i18n: I18n.t('other'),
}

const Industries = (): JSX.Element => {
  const dispatch = useDispatch()
  const { industry } = useSelector(({ organization }) => organization?.organization)
  // industries
  const {
    fetching,
    point_of_sale_providers,
    accounting_system_providers,
    medical_insurance_providers,
  } = useSelector(({ industries }) => industries)
  const { point_of_sale_provider, accounting_system_provider, medical_insurance_provider } =
    useSelector(({ organization }) => organization?.organization)

  const newOnboardingProvider = (
    providersType: string,
    data: { name: string },
    form: { change: (name: string, value: string) => void },
  ): void => {
    dispatch(createOnboardingProvider(providersType, data)).then(() => {
      form.change('accounting_system_name', '')
      form.change('point_of_sale_name', '')
      form.change('medical_insurance_provider_name', '')
      dispatch(providersLoad(providersType))
      dispatch(organizationInfo())
    })
  }
  const handleOnChange = (v: providers): void => {
    dispatch(updateOrganization({ ...v }))
  }

  return (
    <Form
      onSubmit={(): void => {
        //
      }}
      initialValues={{
        point_of_sale_id: point_of_sale_provider?.id,
        accounting_system_id: accounting_system_provider?.id,
        medical_insurance_provider_id: medical_insurance_provider?.id,
      }}
    >
      {({ values, form }): JSX.Element => (
        <form>
          <Flex
            flexCol
            style={{ gap: 16, width: 320 }}
          >
            {/* medical */}
            <SelectField
              // @ts-expect-error // select field needs to be migrated to TS
              valueKey="id"
              labelKey="name_i18n"
              label={I18n.t('which_medical_insurance')}
              name="medical_insurance_provider_id"
              options={[...medical_insurance_providers, other]}
              onChange={(id: number | 'other'): void => {
                if (id !== 'other') {
                  handleOnChange({
                    organization_medical_insurance_provider_attributes: {
                      medical_insurance_provider_id: id,
                    },
                  })
                }
              }}
            />
            {values?.medical_insurance_provider_id === 'other' && (
              <TextInputOther
                disabled={fetching}
                name="medical_insurance_provider_name"
                showButton={values?.medical_insurance_provider_name}
                onClick={(): void =>
                  newOnboardingProvider(
                    'medical_insurance_providers',
                    {
                      name: values?.medical_insurance_provider_name,
                    },
                    form,
                  )
                }
              />
            )}
            {/* accounting_system_name */}
            <SelectField
              // @ts-expect-error // select field needs to be migrated to TS
              valueKey="id"
              labelKey="name_i18n"
              name="accounting_system_id"
              label={I18n.t('which_accounting_system')}
              options={[...accounting_system_providers, other]}
              menuPlacement="top"
              onChange={(id: number | 'other'): void => {
                if (id !== 'other') {
                  handleOnChange({ accounting_system_provider_id: id })
                }
              }}
            />
            {values?.accounting_system_id === 'other' && (
              <TextInputOther
                disabled={fetching}
                name="accounting_system_name"
                showButton={values?.accounting_system_name}
                onClick={(): void =>
                  newOnboardingProvider(
                    'accounting_system_providers',
                    {
                      name: values?.accounting_system_name,
                    },
                    form,
                  )
                }
              />
            )}
            {/* point_of_sale */}
            {['Food and beverages', 'Retail, Wholesale and Distribution'].includes(
              industry?.name_en,
            ) && (
              <>
                <SelectField
                  // @ts-expect-error // select field needs to be migrated to TS
                  valueKey="id"
                  labelKey="name_i18n"
                  name="point_of_sale_id"
                  label={I18n.t('which_pos_system')}
                  options={[...point_of_sale_providers, other]}
                  menuPlacement="top"
                  onChange={(id: number | 'other'): void => {
                    if (id !== 'other') {
                      handleOnChange({ point_of_sale_provider_id: id })
                    }
                  }}
                />
                {values?.point_of_sale_id === 'other' && (
                  <TextInputOther
                    disabled={fetching}
                    name="point_of_sale_name"
                    showButton={values?.point_of_sale_name}
                    onClick={(): void =>
                      newOnboardingProvider(
                        'point_of_sale_providers',
                        {
                          name: values?.point_of_sale_name,
                        },
                        form,
                      )
                    }
                  />
                )}
              </>
            )}
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default Industries
