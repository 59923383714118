export const RESIGNATION_REQUESTS_LOAD = 'RESIGNATION_REQUESTS_LOAD'
export const RESIGNATION_REQUESTS_LOAD_FULFILLED = 'RESIGNATION_REQUESTS_LOAD_FULFILLED'
export const RESIGNATION_REQUESTS_LOAD_REJECTED = 'RESIGNATION_REQUESTS_LOAD_REJECTED'

export const RESIGNATION_REQUEST_CREATE = 'RESIGNATION_REQUEST_CREATE'
export const RESIGNATION_REQUEST_CREATE_PENDING = 'RESIGNATION_REQUEST_CREATE_PENDING'
export const RESIGNATION_REQUEST_CREATE_FULFILLED = 'RESIGNATION_REQUEST_CREATE_FULFILLED'
export const RESIGNATION_REQUEST_CREATE_REJECTED = 'RESIGNATION_REQUEST_CREATE_REJECTED'

export const RESIGNATION_REQUEST_UPDATE = 'RESIGNATION_REQUEST_UPDATE'
export const RESIGNATION_REQUEST_UPDATE_PENDING = 'RESIGNATION_REQUEST_UPDATE_PENDING'
export const RESIGNATION_REQUEST_UPDATE_FULFILLED = 'RESIGNATION_REQUEST_UPDATE_FULFILLED'
export const RESIGNATION_REQUEST_UPDATE_REJECTED = 'RESIGNATION_REQUEST_UPDATE_REJECTED'

export const RESIGNATION_REASONS_LOAD = 'RESIGNATION_REASONS_LOAD'
export const RESIGNATION_REASONS_LOAD_FULFILLED = 'RESIGNATION_REASONS_LOAD_FULFILLED'
