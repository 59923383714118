export function search(text, list) {
  if (text !== '') {
    const newList = list.filter((item) => {
      // searchable keys
      const lc =
        `${item.code} ${item.name_i18n} ${item.document_id} ${item.job_title}`.toLowerCase()

      return lc.includes(text.toLowerCase())
    })
    return newList
  }

  return list
}

export function filter(id, list) {
  return list.filter((rc) => rc.id !== id)
}

export function isExisting(selected = [], users = []) {
  const ids = selected.map((employee) => employee.id)
  return users.filter((employee) => !ids.includes(employee.id))
}
