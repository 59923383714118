import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@jisr-hr/ds'

const TextCaption = ({ label, color, weight, ...props }) => (
  <Typography
    text={label}
    style={{ color }}
    variant="subtitle-2"
    {...props}
  />
)

TextCaption.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
}

export default TextCaption
