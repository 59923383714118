export const REQUESTS_RESET = 'REQUESTS_RESET'
export const REQUESTS_ALL_RESET = 'REQUESTS_ALL_RESET'

export const REQUESTS_LOAD = 'REQUESTS_LOAD'
export const REQUESTS_LOAD_PENDING = 'REQUESTS_LOAD_PENDING'
export const REQUESTS_LOAD_FULFILLED = 'REQUESTS_LOAD_FULFILLED'
export const REQUESTS_LOAD_REJECTED = 'REQUESTS_LOAD_REJECTED'

export const REQUESTS_APPROVE_REQUEST = 'REQUESTS_APPROVE_REQUEST'
export const REQUESTS_APPROVE_REQUEST_PENDING = 'REQUESTS_APPROVE_REQUEST_PENDING'
export const REQUESTS_APPROVE_REQUEST_FULFILLED = 'REQUESTS_APPROVE_REQUEST_FULFILLED'
export const REQUESTS_APPROVE_REQUEST_REJECTED = 'REQUESTS_APPROVE_REQUEST_REJECTED'
export const CLEAR_APPROVED_REQUEST_RESPONSE = 'CLEAR_APPROVED_REQUEST_RESPONSE'

export const REQUESTS_REJECT_REQUEST = 'REQUESTS_REJECT_REQUEST'
export const REQUESTS_REJECT_REQUEST_PENDING = 'REQUESTS_REJECT_REQUEST_PENDING'
export const REQUESTS_REJECT_REQUEST_FULFILLED = 'REQUESTS_REJECT_REQUEST_FULFILLED'
export const REQUESTS_REJECT_REQUEST_REJECTED = 'REQUESTS_REJECT_REQUEST_REJECTED'

export const REQUESTS_CANCEL_REQUEST = 'REQUESTS_CANCEL_REQUEST'
export const REQUESTS_CANCEL_REQUEST_PENDING = 'REQUESTS_CANCEL_REQUEST_PENDING'
export const REQUESTS_CANCEL_REQUEST_FULFILLED = 'REQUESTS_CANCEL_REQUEST_FULFILLED'
export const REQUESTS_CANCEL_REQUEST_REJECTED = 'REQUESTS_CANCEL_REQUEST_REJECTED'

export const SUPER_ADMIN_CANCELING_REQUEST = 'SUPER_ADMIN_CANCELING_REQUEST'
export const SUPER_ADMIN_CANCELING_REQUEST_PENDING = 'SUPER_ADMIN_CANCELING_REQUEST_PENDING'
export const SUPER_ADMIN_CANCELING_REQUEST_FULFILLED = 'SUPER_ADMIN_CANCELING_REQUEST_FULFILLED'
export const SUPER_ADMIN_CANCELING_REQUEST_REJECTED = 'SUPER_ADMIN_CANCELING_REQUEST_REJECTED'

export const RESUMPTION_REQUEST = 'RESUMPTION_REQUEST'
export const RESUMPTION_REQUEST_PENDING = 'RESUMPTION_REQUEST_PENDING'
export const RESUMPTION_REQUEST_FULFILLED = 'RESUMPTION_REQUEST_FULFILLED'
export const RESUMPTION_REQUEST_REJECTED = 'RESUMPTION_REQUEST_REJECTED'

export const UPDATE_RESUMPTION_REQUEST = 'UPDATE_RESUMPTION_REQUEST'
export const UPDATE_RESUMPTION_REQUEST_PENDING = 'UPDATE_RESUMPTION_REQUEST_PENDING'
export const UPDATE_RESUMPTION_REQUEST_FULFILLED = 'UPDATE_RESUMPTION_REQUEST_FULFILLED'
export const UPDATE_RESUMPTION_REQUEST_REJECTED = 'UPDATE_RESUMPTION_REQUEST_REJECTED'

export const CANCELLATION_REQUESTS_CREATE = 'CANCELLATION_REQUESTS_CREATE'
export const CANCELLATION_REQUESTS_CREATE_PENDING = 'CANCELLATION_REQUESTS_CREATE_PENDING'
export const CANCELLATION_REQUESTS_CREATE_FULFILLED = 'CANCELLATION_REQUESTS_CREATE_FULFILLED'
export const CANCELLATION_REQUESTS_CREATE_REJECTED = 'CANCELLATION_REQUESTS_CREATE_REJECTED'

export const REQUESTS_CARD_OPTIONS = 'REQUESTS_CARD_OPTIONS'
export const UPDATE_REQUESTS_PARAMS = 'UPDATE_REQUESTS_PARAMS'
export const REQUESTS_NAVIGATION = 'REQUESTS_NAVIGATION'
export const ADD_NEW_COMMENT_REQUEST = 'ADD_NEW_COMMENT_REQUEST'

export const REQUESTS_APPROVALS_LOAD = 'REQUESTS_APPROVALS_LOAD'
export const REQUESTS_APPROVALS_LOAD_PENDING = 'REQUESTS_APPROVALS_LOAD_PENDING'
export const REQUESTS_APPROVALS_LOAD_FULFILLED = 'REQUESTS_APPROVALS_LOAD_FULFILLED'
export const REQUESTS_APPROVALS_LOAD_REJECTED = 'REQUESTS_APPROVALS_LOAD_REJECTED'

export const SINGLE_REQUEST_LOAD = 'SINGLE_REQUEST_LOAD'
export const SINGLE_REQUEST_LOAD_PENDING = 'SINGLE_REQUEST_LOAD_PENDING'
export const SINGLE_REQUEST_LOAD_FULFILLED = 'SINGLE_REQUEST_LOAD_FULFILLED'
export const SINGLE_REQUEST_LOAD_REJECTED = 'SINGLE_REQUEST_LOAD_REJECTED'

// :::::  Bulk Request Actions  ::::: //
export const BULK_REQUESTS_APPROVE_SINGLE = 'BULK_REQUESTS_APPROVE_SINGLE'
export const BULK_REQUESTS_APPROVE_SINGLE_PENDING = 'BULK_REQUESTS_APPROVE_SINGLE_PENDING'
export const BULK_REQUESTS_APPROVE_SINGLE_FULFILLED = 'BULK_REQUESTS_APPROVE_SINGLE_FULFILLED'
export const BULK_REQUESTS_APPROVE_SINGLE_REJECTED = 'BULK_REQUESTS_APPROVE_SINGLE_REJECTED'

export const BULK_PAYRUN_APPROVALS_APPROVE = 'BULK_PAYRUN_APPROVALS_APPROVE'
export const BULK_PAYRUN_APPROVALS_APPROVE_PENDING = 'BULK_PAYRUN_APPROVALS_APPROVE_PENDING'
export const BULK_PAYRUN_APPROVALS_APPROVE_FULFILLED = 'BULK_PAYRUN_APPROVALS_APPROVE_FULFILLED'
export const BULK_PAYRUN_APPROVALS_APPROVE_REJECTED = 'BULK_PAYRUN_APPROVALS_APPROVE_REJECTED'

export const BULK_PAYRUN_APPROVALS_REJECT = 'BULK_PAYRUN_APPROVALS_REJECT'
export const BULK_PAYRUN_APPROVALS_REJECT_PENDING = 'BULK_PAYRUN_APPROVALS_REJECT_PENDING'
export const BULK_PAYRUN_APPROVALS_REJECT_FULFILLED = 'BULK_PAYRUN_APPROVALS_REJECT_FULFILLED'
export const BULK_PAYRUN_APPROVALS_REJECT_REJECTED = 'BULK_PAYRUN_APPROVALS_REJECT_REJECTED'

export const BULK_REQUESTS_REJECT_BULK = 'BULK_REQUESTS_REJECT_BULK'
export const BULK_REQUESTS_REJECT_BULK_PENDING = 'BULK_REQUESTS_REJECT_BULK_PENDING'
export const BULK_REQUESTS_REJECT_BULK_FULFILLED = 'BULK_REQUESTS_REJECT_BULK_FULFILLED'
export const BULK_REQUESTS_REJECT_BULK_REJECTED = 'BULK_REQUESTS_REJECT_BULK_REJECTED'

export const RETURN_FOR_CORRECTION = 'RETURN_FOR_CORRECTION'
export const RETURN_FOR_CORRECTION_PENDING = 'RETURN_FOR_CORRECTION_PENDING'
export const RETURN_FOR_CORRECTION_FULFILLED = 'RETURN_FOR_CORRECTION_FULFILLED'
export const RETURN_FOR_CORRECTION_REJECTED = 'RETURN_FOR_CORRECTION_REJECTED'

export const BULK_REQUESTS_CLEAR_SUCCESS_RESULTS = 'BULK_REQUESTS_CLEAR_SUCCESS_RESULTS'
