import { isEmpty } from 'lodash'
import FilterBox from '../FilterBox'
import useFilterAPI from '../useFilterAPI'
import { useFilters } from './context'

type ReportingMethodsTypes = {
  filterKey: string
  filters: Array<string>
}

const ReportingMethods = (props: ReportingMethodsTypes): JSX.Element => {
  const { biometrics, reporting_method } = useFilterAPI()
  const { filter, handleFilter } = useFilters()
  const fltrs = props.filters
  const methods = fltrs.includes('reporting_method') ? reporting_method : []
  const biomets = fltrs.includes('biometrics') ? biometrics : []

  const onHandleFilter = (key: string, id: number): void => {
    if (key === 'reporting_method_ids' && !filter.reporting_method_ids.includes(id)) {
      const biosList = biomets.filter((bio) => bio.reporting_method_id === id)
      if (!isEmpty(biosList)) {
        const ids = biosList
          .filter((bio) => !filter.biometric_ids.includes(bio.id))
          .map((bio) => bio.id)
        handleFilter('reporting_method_ids', id, { key: 'biometric_ids', ids })
      }
    } else {
      handleFilter(key, id)
    }
  }

  if (!isEmpty(biomets) && !isEmpty(methods)) {
    return (
      <FilterBox
        handleFilter={onHandleFilter}
        options={methods}
        subOptions={biomets}
        selectedIds={filter.reporting_method_ids}
        subSelectedIds={filter.biometric_ids}
        optionKey="id"
        subOptionFKey="reporting_method_id"
        subOptionKey="id"
        optionFilterKey="reporting_method_ids"
        subOptionFilterKey="biometric_ids"
        {...props}
      />
    )
  }

  if (!isEmpty(biomets) && isEmpty(methods)) {
    return (
      <FilterBox
        handleFilter={(val): void => onHandleFilter('biometric_ids', val)}
        options={biomets}
        selectedIds={filter.biometric_ids}
        {...props}
      />
    )
  }

  if (!isEmpty(methods) && isEmpty(biomets)) {
    return (
      <FilterBox
        handleFilter={(val): void => onHandleFilter('reporting_method_ids', val)}
        options={methods}
        selectedIds={filter.reporting_method_ids}
        {...props}
      />
    )
  }
  return <span />
}

export default ReportingMethods
