import { useEffect, useState } from 'react'
import { Drawer, SearchBar } from '@jisr-hr/design-system'
import { Table, CustomColumnDef } from '@jisr-hr/ds-beta'
import { Flex, Button } from '@jisr-hr/ds'
import { useSelector, useDispatch } from 'utils/hooks'
import { EmployeeJobIdAvatar } from 'components/global/molecules'
import { addAdminUser, removeAdminUser } from 'redux/organizationOnborading/roles/actionCreators'
import { TEmployees } from 'redux/organizationOnborading/employees/employees.type'
import { admenEmployeesLoad } from 'redux/organizationOnborading/employees/actionCreators'

import I18n from 'translations/i18n'
import { organizationInfo } from 'redux/organizationOnborading/organization/actionCreators'

import Styles from './style.module.css'

type OnboardingRolesType = {
  onboardingRoles: {
    fetching: boolean
  }
}

type ParamsType = {
  search: string
}

type RowDataType = { row: { original: TEmployees } }

const Content = (): JSX.Element => {
  const dispatch = useDispatch()
  const { adminEmployees } = useSelector((s) => s.reviewingEmployee)
  const { fetching } = useSelector((s: OnboardingRolesType) => s.onboardingRoles)

  const [filters, setFilters] = useState<ParamsType>({ search: '' })

  const handleSearch = (search: string): void => {
    setFilters({
      ...filters,
      search,
    })
  }

  const onAddUser = (id: number): void => {
    dispatch(
      addAdminUser({
        employee_role: [{ employee_id: id }],
      }),
    ).then(() => {
      dispatch(admenEmployeesLoad(filters))
      dispatch(organizationInfo())
    })
  }

  const onRemoveUser = (id: number): void => {
    dispatch(
      removeAdminUser({
        employee_role: [{ employee_id: id }],
      }),
    ).then(() => {
      dispatch(admenEmployeesLoad(filters))
      dispatch(organizationInfo())
    })
  }

  useEffect(() => {
    dispatch(admenEmployeesLoad(filters))
  }, [filters?.search])

  const columns: CustomColumnDef[] = [
    {
      id: 'name',
      header: '',
      cell: (rowData: RowDataType): JSX.Element => {
        const data = rowData.row.original
        return (
          <>
            {/* @ts-expect-error Need to migrate EmployeeJobIdAvatar.js to TypeScript */}
            <EmployeeJobIdAvatar
              id={data.code}
              name={data.full_name_i18n}
              avatar={data.avatar_thumb}
              jobTitle={data.job_title?.name_i18n}
            />
          </>
        )
      },
    },
    {
      id: 'actions',
      header: '',
      allignement: 'right',
      cell: (rowData: RowDataType): JSX.Element => {
        const data = rowData.row.original
        return (
          <div className="w-full flex justify-end">
            {data?.roles?.length === 0 || !data?.roles?.find?.((v) => v?.is_super_admin) ? (
              <Button
                variant="outlined"
                color="neutral"
                size="small"
                label={I18n.t('add')}
                leadingIcon="plus"
                onClick={(): void => onAddUser(data.id)}
                disabled={fetching}
              />
            ) : (
              <Button
                variant="outlined"
                color="neutral"
                size="small"
                leadingIcon="x"
                iconOnly
                onClick={(): void => onRemoveUser(data?.id)}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Flex
        style={{ padding: '16px 24px' }}
        justifyBetween
      >
        <SearchBar
          style={{ width: 340 }}
          placeholder={I18n.t('search_employees_by_id_or_name')}
          onChange={(_: string, v: string): void => handleSearch(v)}
          value={filters?.search || ''}
        />
      </Flex>
      <Table
        data={adminEmployees}
        columns={columns}
        tableSize={{ maxHeight: 'calc(100vh - 128px)' }}
      />
    </div>
  )
}

type AddUsersType = {
  open: boolean
  onClose: () => void
}

const AddUsers = ({ open, onClose }: AddUsersType): JSX.Element => {
  return (
    <Drawer
      className={Styles.addUserDrawer}
      active={open}
      onClose={onClose}
      title={I18n.t('add_admins_onb')}
      content={<Content />}
    />
  )
}

export default AddUsers
